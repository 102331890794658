import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import { HelpOutlineOutlined, InfoOutlined } from "@mui/icons-material";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import DeleteDialog from "../../../Components/DeleteDialog";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useIsFirst24Hours } from "../hooks";
import { type ClusterConfig, type ClusterInfo } from "../types";
import { AwsSplitDialog } from "./AwsSplitDialog";
import { ClusterDialog } from "./ClusterDialog";
import { ClusterRow } from "./ClusterRow";
import { deleteClusterInfo } from "./db";
import { TierDialog } from "./TierDialog";

type Props = {
  clusters: ClusterConfig[];
};

const helpURL = "https://help.doit.com/docs/dashboards/eks-lens";

export const ClusterTable = ({ clusters }: Props) => {
  const history = useHistory();
  const customerId = useCustomerId();

  const first24HoursDeployment = useIsFirst24Hours(clusters);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [clusterDialogOpen, setClusterDialogOpen] = useState(false);
  const [awsSplitDialog, setAwsSplitDialog] = useState<{ region: string | null | undefined; open: boolean }>({
    open: false,
    region: null,
  });
  const [tierDialog, setTierDialog] = useState<{ open: boolean }>({
    open: false,
  });

  const [clusterToEdit, setClusterToEdit] = useState<ClusterInfo | undefined>(undefined);
  const [clusterToDelete, setClusterToDelete] = useState<ClusterInfo | undefined>(undefined);

  const handleCreateClusterClicked = () => {
    setClusterToEdit(undefined);
    setClusterDialogOpen(true);
  };

  const handleClusterDeleteClicked = () => {
    if (clusterToDelete) {
      return deleteClusterInfo(customerId, clusterToDelete);
    }
  };

  const RowWrapper = useCallback(({ row }: { row: ClusterConfig }) => {
    const handleEditClusterClicked = (config: ClusterConfig) => {
      setClusterToEdit(config);
      setClusterDialogOpen(true);
    };

    const handleRemoveClusterClicked = (config: ClusterConfig) => {
      setClusterToDelete(config);
      setConfirmDeleteDialogOpen(true);
    };

    return (
      <TableRow>
        <ClusterRow
          row={row}
          onEditClusterClicked={handleEditClusterClicked}
          onRemoveClusterClicked={handleRemoveClusterClicked}
          awsOpenSplitDialog={setAwsSplitDialog}
          tierOpenDialog={setTierDialog}
        />
      </TableRow>
    );
  }, []);

  return (
    <Container sx={{ pt: 2, pb: 8 }}>
      {clusterDialogOpen && (
        <ClusterDialog value={clusterToEdit} open={clusterDialogOpen} setOpen={setClusterDialogOpen} />
      )}
      {awsSplitDialog.open && <AwsSplitDialog state={awsSplitDialog} setOpen={setAwsSplitDialog} />}
      {tierDialog.open && <TierDialog state={tierDialog} setOpen={setTierDialog} />}
      {first24HoursDeployment && (
        <Alert severity="info" sx={{ mb: 2 }}>
          It can take up to 24 hours to start seeing data after connecting a cluster
        </Alert>
      )}
      <DeleteDialog
        open={confirmDeleteDialogOpen}
        title="Remove cluster"
        message="This cluster will no longer appear in this list or appear in EKS reports. You can re-add this cluster at a later point if needed. "
        deleteButtonText="Remove cluster"
        onDelete={handleClusterDeleteClicked}
        onClose={() => {
          setConfirmDeleteDialogOpen(false);
        }}
      />
      <Stack direction="row" alignItems="center">
        <IconButton aria-label="Back" onClick={history.goBack} size="large">
          <BackIcon color="primary" />
        </IconButton>
        <Typography variant="h1" display="inline">
          EKS clusters
        </Typography>
        <Tooltip title="More Info">
          <Link href={helpURL} target="_blank" underline="none">
            {clusters.length === 0 && <span>Please enable cost allocation tags to view EKS clusters.</span>}
            <IconButton color="inherit">
              <HelpOutlineOutlined />
            </IconButton>
          </Link>
        </Tooltip>
        <Box display="flex" gap={2} flex={1} justifyContent="end">
          <Button color="primary" variant="contained" onClick={handleCreateClusterClicked}>
            Add new EC2 cluster
          </Button>
        </Box>
      </Stack>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Cluster</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>
              Split cost
              <Tooltip title="Enable split cost allocation data in AWS Console to easily get namespace, node, cluster as well as CPU and memory requests for the pods (except labels).">
                <IconButton size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>
              Agent
              <Tooltip title="Install an agent on each of your K8S cluster in order to get POD label granularity.">
                <IconButton size="small">
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{clusters.map((cluster) => RowWrapper({ row: cluster }))}</TableBody>
      </Table>
    </Container>
  );
};
