import { useCallback, useMemo, useState } from "react";

import { AppModel, type MSTeamsChannel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import type { AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import type { TeamsChannelRow } from "./MsTeamsTableRow";

interface TeamsBotClient {
  /**
   * Completes the setup of a team.
   * @returns either an error string to display on the calling component, or null if everything's OK.
   */
  completeTeamSetup: (payload: string) => Promise<string | null>;
  /**
   * Adds a channel to a team.
   * The channel being added MUST belong to a team that has been given a prior configuration by way of {@link completeTeamSetup}.
   * @returns either an error string to display on the calling component, or null if everything's OK.
   */
  addTeamChannel: (channelUrl: string) => Promise<string | null>;
}

/**
 * Contains calls for the MS Teams app API.
 */
export const useTeamsBot = (): TeamsBotClient => {
  const { tokenValue } = useAuthContext({ mustHaveUser: true });

  const headers = useMemo(
    () => ({
      Authorization: `Bearer ${tokenValue}`,
      "Content-Type": "application/json",
    }),
    [tokenValue]
  );

  const baseUrl = "/api/msteams-bot";

  const completeTeamSetup = useCallback(
    async (payload: string) => {
      let response: Response;
      try {
        response = await fetch(`${baseUrl}/complete-team-setup`, {
          method: "POST",
          headers,
          body: JSON.stringify({ payload }),
        });
      } catch (error) {
        consoleErrorWithSentry(error, { message: "Failed to complete team setup" });
        return "Failed to complete team setup";
      }

      if (!response.ok) {
        return "Failed to complete team setup";
      }

      return null;
    },
    [headers]
  );

  const addTeamChannel = useCallback(
    async (channelUrl: string): Promise<string | null> => {
      let response: Response;
      try {
        response = await fetch(`${baseUrl}/add-team-channel`, {
          method: "POST",
          headers,
          body: JSON.stringify({ channelUrl }),
        });
      } catch (error) {
        consoleErrorWithSentry(error, { message: "Failed to add team channel" });
        return "Failed to submit request to add channel to team";
      }

      if (response.status === 404) {
        return "The team with the given channel URL was not found";
      } else if (response.status === 400) {
        return "Bad URL provided";
      } else if (!response.ok) {
        return "Failed to add channel to team";
      }

      return null;
    },
    [headers]
  );

  return {
    completeTeamSetup,
    addTeamChannel,
  };
};

type CustomerTeamsChannelsHandler = {
  channels: MSTeamsChannel[] | undefined;
  channelsLoading: boolean;
  channelsError: Error | undefined;
  deleteChannel: (id: string) => Promise<string | null>;
  deleteLoading: boolean;
};

export const useCustomerTeamsChannels = (): CustomerTeamsChannelsHandler => {
  const { customer } = useCustomerContext();

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const customerChannelsQuery = useMemo(
    () => getCollection(AppModel).doc("msteams-app").collection("channels").where("customerId", "==", customer.id),
    [customer.id]
  );
  const [channels, channelsLoading, channelsError] = useCollectionData(customerChannelsQuery);

  const deleteChannel = useCallback(async (id: string) => {
    setDeleteLoading(true);
    try {
      await getCollection(AppModel).doc("msteams-app").collection("channels").doc(id).delete();
    } catch (error) {
      consoleErrorWithSentry(error);
      setDeleteLoading(false);
      return "Failed to delete channel";
    }
    setDeleteLoading(false);

    return null;
  }, []);

  return { channels, channelsLoading, channelsError, deleteChannel, deleteLoading };
};

/**
 * Handles the sending of a test notification and its associated loading state.
 * @param row The channel row to which this function is attached.
 * @returns A promise that resolves to an error message if the notification fails to send, or null if it succeeds.
 */
export const useTestNotification = (
  row: TeamsChannelRow
): { sendTestNotification: () => Promise<string | null>; testNotificationLoading: boolean } => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  const [testNotificationLoading, setTestNotificationLoading] = useState<boolean>(false);
  const sendTestNotification = useCallback<() => Promise<string | null>>(async () => {
    setTestNotificationLoading(true);
    let response: AxiosResponse;
    try {
      response = await api.request({
        method: "post",
        url: `/v1/customers/${customer.id}/msteams/send-test-message/${row.id}`,
        data: {},
      });
    } catch (error) {
      consoleErrorWithSentry(error);
      setTestNotificationLoading(false);
      return "Failed to send test notification";
    }
    setTestNotificationLoading(false);

    if (response.status >= 400) {
      return "Failed to send test notification";
    }

    return null;
  }, [api, customer.id, row.id]);

  return { sendTestNotification, testNotificationLoading };
};
