import { Redirect } from "react-router-dom";

import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import GCPPricebooks from "../../Components/Pricebooks/GCPPricebooks/GCPPricebooks";
import { type PriceBooksPageId } from "../../Navigation/config/pages";
import AmazonWebServicesTab from "./AmazonWebServicesTab";

type Props = {
  pageId: PriceBooksPageId;
};

const Pricebooks = ({ pageId }: Props) => {
  const routeMatchURL = useRouteMatchURL();

  if (pageId === "price-books") {
    return <Redirect to={`${routeMatchURL}/amazon-web-services`} />;
  }

  return (
    <>
      {(pageId === "price-books:aws" || pageId === "price-books:aws:details") && <AmazonWebServicesTab />}
      {pageId === "price-books:gcp" && <GCPPricebooks />}
    </>
  );
};

export default Pricebooks;
