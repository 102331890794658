import { useEffect } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Button, List, ListItem, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CopyCodeBlock } from "../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import LoadingButton from "../../../../../Components/LoadingButton";
import { useCloudPermissions } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const PERMISSION_ERROR_KEY = "permission_error";

const PermissionsTab = () => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { requiredPermissions, command, loading, updatePermissions, isValid } = useCloudPermissions(nodeConfig);

  const getConsoleLink = (): string => {
    if (nodeConfig.parameters?.provider === "AWS" && nodeConfig.parameters?.configurationValues?.accountId) {
      return `https://${nodeConfig.parameters?.configurationValues.accountId}.signin.aws.amazon.com/console`;
    }
    if (nodeConfig.parameters?.provider === "GCP" && nodeConfig.parameters?.configurationValues?.organization) {
      return `https://console.cloud.google.com/iam-admin/iam?organizationId=${nodeConfig.parameters?.configurationValues.organization}`;
    }

    return "#";
  };

  useEffect(() => {
    if (!loading) {
      updateNode((prevNode) => {
        const errors = { ...prevNode.errors };
        if (isValid) {
          delete errors[PERMISSION_ERROR_KEY];
        } else {
          errors[PERMISSION_ERROR_KEY] = "incorrect form parameters";
        }

        return { errors };
      });
    }
  }, [isValid, loading, updateNode]);

  if (isValid) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ p: 1 }}>
        <Alert severity="success">
          <Typography variant="body2">Permissions granted</Typography>
        </Alert>
      </Box>
    );
  }

  if (requiredPermissions.length === 0) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ p: 1 }}>
        <LoadingButton
          variant="outlined"
          sx={{ px: 2, py: 0.875 }}
          onClick={updatePermissions}
          loading={loading}
          mixpanelEventId="cloudflow.check-permissions"
        >
          Check for required permissions
        </LoadingButton>
      </Box>
    );
  }
  return (
    <Stack alignContent="center" p={2} gap={3}>
      <Alert severity="warning">
        <Typography variant="body2">We require the following permissions in order to run this node: </Typography>
        <List sx={{ listStyleType: "disc", pl: 2 }}>
          {requiredPermissions?.map((permission) => (
            <ListItem key={permission} sx={{ display: "list-item", px: 0.5 }}>
              {permission}
            </ListItem>
          ))}
        </List>
      </Alert>
      <Stack gap={1} alignItems="flex-start">
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          Run the following command to grant the permission:
        </Typography>
        <CopyCodeBlock base={command} />
        <Button variant="outlined" component="a" href={getConsoleLink()} target="_blank">
          <OpenInNewIcon
            sx={{
              width: 20,
              height: 20,
              mr: 1,
            }}
          />
          Go to {nodeConfig.parameters?.provider} Console
        </Button>
        <LoadingButton
          mixpanelEventId="cloudflow.check-permissions"
          variant="outlined"
          sx={{ px: 2, py: 0.875 }}
          onClick={updatePermissions}
          loading={loading}
        >
          Check for required permissions
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default PermissionsTab;
