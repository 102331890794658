import { type HeaderColumn } from "../../../../types/FilterTable";

export const headers: HeaderColumn[] = [
  {
    value: "serviceAccountEmail",
    label: "Service Account",
    tooltip: "Service Account",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "role",
    label: "Role",
    tooltip: "Role",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "features",
    label: "Enabled Features",
    tooltip: "Enabled Features",
    style: { align: "left", padding: "normal" },
  },
];
