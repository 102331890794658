import { useState } from "react";

import { KnownIssueDialog, type KnownIssueDialogProps } from "../KnownIssueDialog";
import { type KnownIssue } from "../types";
import { EditKnownIssueButton } from "./EditKnownIssueButton";

export type EditKnownIssueProps = {
  knownIssueData: KnownIssueDialogProps["knownIssueData"];
  updateKnownIssue: (knownIssueData: KnownIssue) => Promise<void>;
  productsSuggestions: KnownIssueDialogProps["productsSuggestions"];
};

export const EditKnownIssue = ({ knownIssueData, updateKnownIssue, productsSuggestions }: EditKnownIssueProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const toggleKnownIssueDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleSubmitUpdateKnownIssue = async (knownIssueData: KnownIssue) => {
    await updateKnownIssue(knownIssueData);
    setOpenDialog(false);
  };

  return (
    <>
      <EditKnownIssueButton onClick={toggleKnownIssueDialog} />
      <KnownIssueDialog
        open={openDialog}
        productsSuggestions={productsSuggestions}
        title="Update Known Issue"
        onClose={toggleKnownIssueDialog}
        onKnownIssueSubmit={handleSubmitUpdateKnownIssue}
        submitButtonText="Update"
        knownIssueData={knownIssueData}
      />
    </>
  );
};
