import { Avatar, Badge, Box, Card, CardContent, Chip, Link, Tooltip, Typography } from "@mui/material";
import { type Hit } from "instantsearch.js";

import SlackIcon from "../../../assets/slack-mark.svg";
import { useFilter } from "../FilterContext"; // Import the useFilter hook
import { timezoneToCountry } from "../utils/helpers";
import { useStyles } from "../utils/styles";
import { Person, type PersonData } from "./Person";
import {
  type CategorizedSkills,
  categorizeSkill,
  categorizeSkills,
  HighlightedText,
  type HighlightResult,
} from "./PersoncardUtils";

type PersonHit = Hit<PersonData>;

type PersonCardProps = {
  hit: PersonHit;
};

export const PersonCard = ({ hit }: PersonCardProps) => {
  const classes = useStyles();
  const person = new Person(hit);
  const { isFiltersExpanded } = useFilter(); // Use the FilterContext

  const renderPersonInfo = () => {
    const highlightedLanguages = Array.isArray(hit._highlightResult?.languages)
      ? (hit._highlightResult.languages as HighlightResult[])
      : [];

    const hasHighlightedLanguage = highlightedLanguages.some((lang) => lang.matchLevel !== "none");

    const highlightedSkills = Array.isArray(hit._highlightResult?.all_skills)
      ? (hit._highlightResult.all_skills as HighlightResult[]).filter((skill) => skill.matchLevel !== "none")
      : [];

    const hasHighlightedSkill = highlightedSkills.length > 0;

    const renderChips = (
      items: HighlightResult[],
      colorScheme: (item: string) => "default" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
    ) =>
      items.slice(0, 3).map((item, index) => {
        const plainText = item.value.replace(/<\/?mark>/g, "");
        return (
          <Chip
            key={index}
            label={<HighlightedText text={plainText} highlightedValue={item.value} />}
            color={colorScheme(plainText)}
            size="small"
            style={{ margin: "0 4px 4px 0" }}
          />
        );
      });

    const renderSkills = (skills: HighlightResult[]) =>
      renderChips(skills, (skill) => {
        const categorizedSkill = categorizeSkill(skill);
        switch (categorizedSkill.type) {
          case "platform":
            return "primary";
          case "category":
            return "secondary";
          case "service":
            return "info";
          default:
            return "default";
        }
      });

    const renderLanguages = (languages: HighlightResult[]) => renderChips(languages, () => "success");

    const categorizedSkills = categorizeSkills(highlightedSkills);

    const renderCategorizedSkills = (categorizedSkills: CategorizedSkills) => (
      <Box>
        <Typography variant="body2" component="span" fontWeight="regular">
          Skills:
        </Typography>
        <Box display="flex" flexWrap="wrap" mt={1}>
          {Object.entries(categorizedSkills).map(([, skills]) => skills.length > 0 && renderSkills(skills))}
        </Box>
      </Box>
    );

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box display="flex" alignItems="flex-start">
          <Box position="relative">
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              className={`${classes.badge} ${person.isAvailable ? classes.available : classes.unavailable}`}
            >
              <Avatar sx={{ height: 96, width: 96 }} src={person.avatar} />
            </Badge>
            <Tooltip title={timezoneToCountry[person.timezone]} arrow>
              <Box
                position="absolute"
                top={0}
                left={0}
                sx={{
                  bgcolor: "white",
                  borderRadius: "50%",
                  width: 24,
                  height: 24,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">{person.countryFlagEmoji}</Typography>
              </Box>
            </Tooltip>
          </Box>
          <Box ml={3} flex={1}>
            <Typography variant="h6" component="div" noWrap>
              <Link href={`https://doitintl.zendesk.com/agent/users/${person.zendeskId}/`} underline="hover">
                <HighlightedText
                  text={person.name}
                  highlightedValue={(hit._highlightResult?.name as HighlightResult | undefined)?.value}
                />
              </Link>
            </Typography>
            <Typography variant="subtitle1" noWrap>
              {person.title}
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <Link href={`slack://user?team=T2TG0KM5E&id=${person.slack}`} target="_blank">
                <Box component="img" src={SlackIcon} sx={{ height: 20, width: 20, mr: 1 }} />
              </Link>
            </Box>
          </Box>
        </Box>
        <Box mt={2} overflow="auto" flexGrow={1}>
          {hasHighlightedLanguage && (
            <Box mb={1}>
              <Typography variant="body2" component="span" fontWeight="regular">
                Languages:
              </Typography>
              <Box display="flex" flexWrap="wrap" mt={1}>
                {renderLanguages(highlightedLanguages)}
              </Box>
            </Box>
          )}
          {hasHighlightedSkill && renderCategorizedSkills(categorizedSkills)}
        </Box>
      </Box>
    );
  };

  return (
    <Card
      className={classes.card}
      sx={{
        width: 298,
        height: isFiltersExpanded ? 150 : 280,
        transition: "height 0.3s ease-in-out",
      }}
    >
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 2,
          overflow: "auto",
        }}
      >
        {renderPersonInfo()}
      </CardContent>
    </Card>
  );
};
