import { useEffect } from "react";

function useMountEffect(fn: () => void | Promise<void>) {
  useEffect(() => {
    void fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useMountEffect;
