import { DateTime } from "luxon";

import { TimestampFromDateTime } from "../../../../../utils/common";
import { getPlanActuals } from "../../../RampList/getPlanActuals";
import { type CommitmentPeriod, type RampPlanModel } from "../../../types";
import { type PeriodObj, type UpdatePlanDataType } from "./PeriodList";

const reduceSum = (acc: number, val: number | null) => acc + (val ?? 0);
const periodsNum = (commitPeriods: CommitmentPeriod[] | null): number => commitPeriods?.length ?? 0;
const periodActualsArr = (periods: CommitmentPeriod[], i: number) =>
  periods[i].actuals || Array(periods[i].planned.length).fill(0);

const calculateRollover = (
  prevPeriod: PeriodObj | undefined,
  periodNum: number,
  totalSpend: number,
  totalPlanned: number
) => {
  if (prevPeriod?.periodNum === periodNum) {
    // one period, spread across more than one table -> same rollover values for all tables in the period
    return prevPeriod.rollover;
  } else {
    return totalSpend + (prevPeriod?.rollover ?? 0) - totalPlanned;
  }
};

const checkIsLastTableOfPeriod = (
  periodLength: number,
  currentPeriodIndex: number,
  periodTableCount: number
): boolean => currentPeriodIndex === periodTableCount || periodLength - currentPeriodIndex * 12 === 1;

const calcTableEndPos = (
  periodLength: number,
  currentPeriodIndex: number,
  periodTableCount: number,
  tableStartPos: number
): number =>
  !checkIsLastTableOfPeriod(periodLength, currentPeriodIndex, periodTableCount) ? tableStartPos + 12 : periodLength + 1;

const getAllPeriodsArr = (planData: RampPlanModel, updatePlanData: UpdatePlanDataType) => {
  let periodPlanData: Array<number>;
  let periodActualData: Array<number>;
  const allPlanActuals = getPlanActuals(planData);
  const periodMonthsArr: Array<Array<number>> = [];
  const allPeriods: PeriodObj[] = [];
  const numOfPeriods: number = periodsNum(planData.commitmentPeriods);

  for (let i = 0; i < numOfPeriods; i++) {
    if (planData.commitmentPeriods?.[i]) {
      const periodNum: number = i + 1;
      const periodLength: number = planData.commitmentPeriods[i]?.planned.length;
      const periodTableCount: number = Math.ceil(periodLength / 12);
      const periodActuals: number[] = periodActualsArr(planData.commitmentPeriods, i);
      periodPlanData = planData.commitmentPeriods[i].planned.map((value) => value ?? 0);
      periodActualData = periodActuals;
      periodMonthsArr.push([]);

      // creating a place for each with base data
      for (let x = 0; x < periodLength; x++) {
        const monthNum: number = x + i * periodLength + 1;
        periodMonthsArr[i].push(monthNum);
      }

      const utcStartDate = DateTime.fromSeconds(planData.commitmentPeriods[i]?.startDate.seconds).toUTC();

      // building tables with relevant data
      for (let n = 1; n <= periodTableCount; n++) {
        const tableStartPos: number = (n - 1) * 12;
        const isThirteenBillingMonthsLeft = periodLength - n * 12 === 1;
        const tableEndPos = calcTableEndPos(periodLength, n, periodTableCount, tableStartPos);
        const totalSpend = periodActualData.reduce(reduceSum, 0);
        const totalPlanned = periodPlanData.reduce(reduceSum, 0);
        const periodDataObj: PeriodObj = {
          periodNum,
          key: n + i * periodNum * periodPlanData.length,
          periodMonthsArr,
          periodPlanData,
          periodActualData,
          updatePlanData,
          periodStart: TimestampFromDateTime(utcStartDate),
          allPlanActuals,
          tableNum: n,
          tableStartPos,
          tableEndPos,
          isLastTableOfPeriod: checkIsLastTableOfPeriod(periodLength, n, periodTableCount),
          planStart: planData.startDate,
          totalCommitment: planData.targetAmount,
          totalPlanned,
          totalSpend,
          rollover: calculateRollover(allPeriods[allPeriods.length - 1], periodNum, totalSpend, totalPlanned),
          commitmentPeriod: planData.commitmentPeriods[i],
        };

        allPeriods.push(periodDataObj);

        // skip last iteration if the actual last table is 13
        if (isThirteenBillingMonthsLeft) {
          break;
        }
      }
    }
  }
  return allPeriods;
};
export default getAllPeriodsArr;
