import { type Cluster } from "@doitintl/cmp-models";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { useClusters } from "../hooks";
import ClusterTableRow from "./ClusterRow";

const itemDescriptions: FilterTableItemDescriptor<Cluster>[] = [
  {
    headerText: "Name",
    value: "name",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Project ID",
    value: "projectId",
    filterType: "string",
  },
  {
    headerText: "Version",
    value: "kubernetesControlPlaneVersion",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "connectionStatus",
    filterType: "string",
  },
];

const { headerColumns, filters } = generateHeadersAndFilters(itemDescriptions);

const ClusterTable = () => {
  const clusters = useClusters();

  return (
    <FilterTable<Cluster>
      filterColumns={filters}
      headerColumns={headerColumns}
      rowComponent={ClusterTableRow}
      tableItems={clusters}
    />
  );
};
export default ClusterTable;
