import { useCallback, useState } from "react";

import { Button, Tooltip } from "@mui/material";
import { type TextFieldProps } from "@mui/material/TextField/TextField";

import { globalText } from "../../assets/texts";
import { useHotkeyContext } from "../../Context/HotkeyContext";
import { consoleErrorWithSentry } from "../../utils";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import SaveDialog from "./SaveDialog";

type Props = {
  disabled: boolean;
  tooltip?: string;
  onSaveAs: (name: string) => Promise<void>;
  saveAsTitle?: string;
  textFieldProps?: TextFieldProps;
  variant?: "contained" | "outlined";
  successMessage?: string;
};

export const SaveAsComponent = ({
  disabled,
  tooltip,
  onSaveAs,
  saveAsTitle,
  textFieldProps,
  variant = "contained",
  successMessage,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [saveAsModalOpen, setSaveAsModalOpen] = useState(false);
  const showErrorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();

  const openSaveAsModal = useCallback(() => {
    setSaveAsModalOpen(true);
    setHotKeysDisabled();
  }, [setHotKeysDisabled]);

  const closeSaveAsModal = useCallback(() => {
    setSaveAsModalOpen(false);
    setHotKeysEnabled();
  }, [setHotKeysEnabled]);

  const handleSaveAs = useCallback(
    async (name) => {
      if (!name) {
        return;
      }
      setLoading(true);
      try {
        await onSaveAs(name);
        if (successMessage) {
          successSnackbar(successMessage);
        }
        closeSaveAsModal();
      } catch (error) {
        consoleErrorWithSentry(error);
        showErrorSnackbar(globalText.SAVE_FAILED);
      } finally {
        setLoading(false);
      }
    },
    [closeSaveAsModal, onSaveAs, showErrorSnackbar, successMessage, successSnackbar]
  );

  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <Button
            sx={{ p: "6px 15px" }}
            variant={variant}
            color="primary"
            disabled={disabled ?? loading}
            onClick={openSaveAsModal}
            data-cy="save-as-button"
            id="save-as-button"
          >
            {globalText.SAVE_AS_ELLIPSIS}
          </Button>
        </span>
      </Tooltip>
      <SaveDialog
        title={saveAsTitle ?? globalText.SAVE_AS}
        open={saveAsModalOpen}
        onClose={closeSaveAsModal}
        onSave={handleSaveAs}
        textFieldProps={textFieldProps}
      />
    </>
  );
};

export default SaveAsComponent;
