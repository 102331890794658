import findKey from "lodash/findKey";
import { DateTime } from "luxon";

import { defaultHeaderStyle, standardToOption } from "../../../Components/FilterTable/Table/FilterTableHeader";
import {
  Comparators,
  type DataColumn,
  type HeaderColumn,
  numberComparators,
  stringComparators,
} from "../../../types/FilterTable";
import { sanitizeDate } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { mayNotBeApplicable, mayNotBeAvailableNumeric, threeDotHeaderSection } from "../../Assets/assetUtils";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";
import { mpaStatusOrdinals } from "./MpaTableRow";

/*
 * Columns
 */

const domainCol: DataColumn = {
  label: "Domain",
  path: "domain",
  comparators: stringComparators,
  toOption: (value) => ({ label: value, value }),
};
const nameCol: DataColumn = {
  label: "Name",
  path: "payerNumber",
  comparators: stringComparators,
  toOption: (value) => ({ label: `doitintl-payer-${value}`, value }),
};

const billingStatusCol: DataColumn = {
  label: "Billing status",
  path: "billingStatus",
  comparators: stringComparators,
  toOption: standardToOption,
};

const ppaMarginCol: DataColumn = {
  label: "PPA margin",
  path: "ppa",
  comparators: numberComparators,
  toOption: mayNotBeAvailableNumeric,
};

const edpMarginCol: DataColumn = {
  label: "EDP margin",
  path: "edp",
  comparators: numberComparators,
  toOption: mayNotBeAvailableNumeric,
};

const supportMarginCol: DataColumn = {
  label: "Support margin",
  path: "support",
  comparators: numberComparators,
  toOption: mayNotBeAvailableNumeric,
};

const totalMarginCol: DataColumn = {
  label: "Total margin",
  path: "total",
  comparators: numberComparators,
  toOption: mayNotBeAvailableNumeric,
};

const lastRefreshedCol: DataColumn = {
  label: "Last refreshed",
  path: "lastRefreshedMillis",
  comparators: numberComparators,
  toOption: (value: number) => ({
    label: value > 0 ? DateTime.fromMillis(value).toLocaleString(DateTime.DATETIME_MED) : NotAvailable,
    value,
  }),
};

// AWS Account number
const numberCol: DataColumn = {
  label: "Number",
  path: "number",
  comparators: stringComparators,
  toOption: (value) => ({ label: value, value }),
};
const rootCol: DataColumn = {
  label: "Root email address",
  path: "root",
  comparators: stringComparators,
  toOption: (value) => ({ label: value, value }),
};
const supportModelCol: DataColumn = {
  label: "Support model",
  path: "supportModel",
  comparators: stringComparators,
  toOption: (value) => ({ label: value, value }),
};
const supportTierCol: DataColumn = {
  label: "Support tier",
  path: "supportTier",
  comparators: stringComparators,
  toOption: (value) => ({ label: value, value }),
};
const statusCol: DataColumn = {
  label: "Status",
  path: "statusOrdinal",
  comparators: stringComparators,
  toOption: (value) => ({ label: findKey(mpaStatusOrdinals, (ordinal) => ordinal === value), value }),
};
const billingStartMonthCol: DataColumn = {
  label: "Billing start month",
  path: "billingStartMonth",
  comparators: numberComparators,
  toOption: (value: FirestoreTimestamp) => ({
    label: sanitizeDate(DateTime.fromJSDate(value.toDate()).setZone("utc")).toFormat("MMM yy"),
    value: value.nanoseconds,
  }),
};
const orgImportCol: DataColumn = {
  label: "Org\u00A0import",
  path: "orgImport",
  comparators: [Comparators.EQUAL_TO],
  toOption: (value) => ({ label: value.toString(), value }),
};
const nraCol: DataColumn = {
  label: "NRA",
  path: "nra",
  comparators: [Comparators.EQUAL_TO],
  toOption: (value) => ({ label: value.toString(), value }),
};
const requesterCol: DataColumn = {
  label: "Requester",
  path: "requester",
};
const payerTypeCol: DataColumn = {
  label: "MPA Type",
  path: "payerType",
  toOption: mayNotBeApplicable,
};
const ticketIdCol: DataColumn = {
  label: "Ticket",
  path: "ticketId",
  comparators: numberComparators,
  toOption: mayNotBeAvailableNumeric,
};
const ticketAssigneeCol: DataColumn = {
  label: "Assignee",
  path: "ticketAssignee",
};

/*
 * Headers
 */

const domainHead: HeaderColumn = {
  value: domainCol.path,
  label: domainCol.label,
  style: defaultHeaderStyle,
};
const nameHead: HeaderColumn = {
  value: nameCol.path,
  label: nameCol.label,
  style: defaultHeaderStyle,
};
export const numberHead: HeaderColumn = {
  value: numberCol.path,
  label: numberCol.label,
  style: defaultHeaderStyle,
};
export const billingStatusHead: HeaderColumn = {
  value: billingStatusCol.path,
  label: billingStatusCol.label,
  style: defaultHeaderStyle,
};
export const ppaRevenueHead: HeaderColumn = {
  value: ppaMarginCol.path,
  label: ppaMarginCol.label,
  style: defaultHeaderStyle,
  hiddenByDefault: true,
};
export const edpRevenueHead: HeaderColumn = {
  value: edpMarginCol.path,
  label: edpMarginCol.label,
  style: defaultHeaderStyle,
  hiddenByDefault: true,
};
export const supportRevenueHead: HeaderColumn = {
  value: supportMarginCol.path,
  label: supportMarginCol.label,
  style: defaultHeaderStyle,
  hiddenByDefault: true,
};
export const totalRevenueHead: HeaderColumn = {
  value: totalMarginCol.path,
  label: totalMarginCol.label,
  style: defaultHeaderStyle,
  hiddenByDefault: true,
};
export const lastRefreshedHead: HeaderColumn = {
  value: lastRefreshedCol.path,
  label: lastRefreshedCol.label,
  style: defaultHeaderStyle,
};
export const rootHead: HeaderColumn = {
  value: rootCol.path,
  label: rootCol.label,
  style: defaultHeaderStyle,
};
export const supportModelHead: HeaderColumn = {
  value: supportModelCol.path,
  label: supportModelCol.label,
  style: defaultHeaderStyle,
};
export const supportTierHead: HeaderColumn = {
  value: supportTierCol.path,
  label: supportTierCol.label,
  style: defaultHeaderStyle,
};
export const statusHead: HeaderColumn = {
  value: statusCol.path,
  label: statusCol.label,
  style: defaultHeaderStyle,
};
export const billingStartMonthHead: HeaderColumn = {
  value: billingStartMonthCol.path,
  label: billingStartMonthCol.label,
  style: defaultHeaderStyle,
  hiddenByDefault: true,
};
export const orgImportHead: HeaderColumn = {
  value: orgImportCol.path,
  label: orgImportCol.label,
  style: defaultHeaderStyle,
};
export const nraHead: HeaderColumn = {
  value: nraCol.path,
  label: "NRA",
  tooltip: "No root access",
  style: defaultHeaderStyle,
};
export const requesterHead: HeaderColumn = {
  value: requesterCol.path,
  label: requesterCol.label,
  style: defaultHeaderStyle,
};
export const payerTypeHead: HeaderColumn = {
  value: payerTypeCol.path,
  label: payerTypeCol.label,
  style: defaultHeaderStyle,
};
export const ticketIdHead: HeaderColumn = {
  value: ticketIdCol.path,
  label: ticketIdCol.label,
  style: defaultHeaderStyle,
};
export const ticketAssigneeHead: HeaderColumn = {
  value: ticketAssigneeCol.path,
  label: ticketAssigneeCol.label,
  style: defaultHeaderStyle,
};

export const mpaColumns: DataColumn[] = [
  domainCol,
  nameCol,
  billingStatusCol,
  ppaMarginCol,
  edpMarginCol,
  supportMarginCol,
  totalMarginCol,
  lastRefreshedCol,
  numberCol,
  supportModelCol,
  supportTierCol,
  statusCol,
  billingStartMonthCol,
  orgImportCol,
  nraCol,
  requesterCol,
  payerTypeCol,
  ticketIdCol,
  ticketAssigneeCol,
  rootCol,
];
export const mpaHeaders: HeaderColumn[] = [
  domainHead,
  nameHead,
  billingStatusHead,
  ppaRevenueHead,
  edpRevenueHead,
  supportRevenueHead,
  totalRevenueHead,
  lastRefreshedHead,
  numberHead,
  supportModelHead,
  supportTierHead,
  statusHead,
  billingStartMonthHead,
  orgImportHead,
  nraHead,
  requesterHead,
  payerTypeHead,
  ticketIdHead,
  ticketAssigneeHead,
  threeDotHeaderSection,
];
