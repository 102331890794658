import { Chip, type ChipProps, Stack } from "@mui/material";

import AWSIconWhite from "../../../assets/amazon-web-services-logo-white.png";
import AWSIcon from "../../../assets/amazon-web-services-new-square.png";
import GCPIcon from "../../../assets/google-cloud.png";
import SnowflakeIcon from "../../../assets/snowflake-logo.svg";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { type Insight } from "../types";

export function CloudProvidersIcons({ cloudTags }: { readonly cloudTags: Insight["cloudTags"] }) {
  const isDarkMode = useDarkThemeCheck();

  const commonProps: ChipProps = {
    variant: isDarkMode ? "outlined" : "filled",
    size: "small",
    sx: {
      backgroundColor: isDarkMode ? "transparent" : "#F3F3F3",
      borderColor: "general.outlineBorder",
      mr: 1,
      height: "28px",
      width: "40px",
    },
  };

  return (
    <Stack direction="row" spacing={1}>
      {cloudTags.includes("aws") && (
        <Chip
          {...commonProps}
          icon={
            <img
              src={isDarkMode ? AWSIconWhite : AWSIcon}
              alt="AWS Icon"
              style={{ width: "22px", marginRight: "-12px" }}
            />
          }
        />
      )}
      {cloudTags.includes("gcp") && (
        <Chip
          {...commonProps}
          icon={<img src={GCPIcon} alt="GCP Icon" style={{ width: "18px", marginRight: "-12px" }} />}
        />
      )}
      {cloudTags.includes("snowflake") && (
        <Chip
          {...commonProps}
          icon={<img src={SnowflakeIcon} alt="Snowflake Icon" style={{ width: "18px", marginRight: "-12px" }} />}
        />
      )}
    </Stack>
  );
}
