import { useMemo } from "react";

import { CloudAnalyticsModel, type ReportConfig } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";

export const useDefaultReportsConfig = (): {
  handleSaveDefaultReportConfig: (config: ReportConfig) => Promise<void>;
  defaultConfig: ReportConfig | null;
  loading: boolean;
} => {
  const { userId } = useAuthContext({ mustHaveUser: true });

  const defaultConfigRef = useMemo(
    () =>
      getCollection(CloudAnalyticsModel).doc("reports").collection("cloudAnalyticsReportsDefaultConfigs").doc(userId),
    [userId]
  );

  const [defaultConfigDoc, loading] = useDocumentData(defaultConfigRef);

  const handleSaveDefaultReportConfig = async (config: ReportConfig) => {
    await defaultConfigRef.set({ config });
  };

  return {
    handleSaveDefaultReportConfig,
    defaultConfig: defaultConfigDoc?.config ?? null,
    loading,
  };
};
