import React, { useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { type DateTime } from "luxon";

import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";

interface MonthsNextPeriodPullOverProps {
  startDate: DateTime;
  currency: CurrencyCode;
  disabled: boolean;
}

const MonthsNextPeriodPullOver: React.FC<MonthsNextPeriodPullOverProps> = ({ startDate, currency, disabled }) => {
  const initialMonths = Array.from({ length: 12 }, (_, i) => {
    const monthName = startDate.plus({ months: i + 1 }).toFormat("MMMM");
    return { [monthName]: monthName };
  }).reduce((acc, month) => ({ ...acc, ...month }), {});

  const [months, setMonths] = useState<{ [key: string]: string }>(initialMonths);

  const handleMonthChange = (monthKey: string, value: string) => {
    setMonths((prev) => ({ ...prev, [monthKey]: value }));
  };

  return (
    <Stack gap={3}>
      {Object.keys(months).map((monthKey, index) => (
        <TextField
          key={`MonthsNextPeriodPullOver${index}`}
          variant="outlined"
          type="number"
          fullWidth
          required
          disabled={!disabled}
          label={monthKey}
          value={months[monthKey]}
          onChange={(e) => {
            handleMonthChange(monthKey, e.target.value);
          }}
          onKeyUp={onKeyPressPreventNonNumeric}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
          }}
        />
      ))}
    </Stack>
  );
};

export default MonthsNextPeriodPullOver;
