import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  AnalyticsDataSource,
  type BudgetConfig,
  type CloudAnalyticsModelBudgetModel,
  Metric,
  type SlackChannel,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { Box } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

import { globalText } from "../../../../assets/texts";
import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepState } from "../../../../Components/Stepper";
import { useCloudAnalyticsContext } from "../../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import {
  BudgetConfigurations,
  BudgetDynamicConfigurations,
  BudgetTypes,
  extractTypeAndFrequencyOptionsFromBudget,
  isEditor,
} from "../../../../Pages/CloudAnalytics/utilities";
import { getAttributionScope } from "../../../../Pages/CloudAnalytics/utils/getScope";
import { type Budget as BudgetType, type BudgetInfo, type DraftBudget } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { sanitizeDate, TimestampFromDateTime } from "../../../../utils/common";
import mixpanel from "../../../../utils/mixpanel";
import { type CloudAnalyticsHistoryState } from "../../types";
import { createNewBudget, updateBudget } from "../db";
import { getAlertAmountFromPercentage } from "../shared";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

type Props =
  | {
      budget: DraftBudget;
      isNewBudget: true;
    }
  | { isNewBudget: false; budget: BudgetType };
const CreateBudgetStepper = ({ budget, isNewBudget }: Props) => {
  const [stepperState, setStepperState] = useState<StepState[]>(["incomplete", "incomplete", "incomplete"]);
  const [currentStep, getCurrentStep] = useState(0);
  const [overrideStep, setOverrideStep] = useState<number>(-1);
  const [loading, setLoading] = useState(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { filteredAttributions: attributions } = useAttributionsContext();

  const [budgetInfo, setBudgetInfo] = useState<BudgetInfo>({
    growthPerPeriod: budget.data.config.growthPerPeriod || 0,
    configurationOption: {
      type: BudgetConfigurations.SINGLE_PERIOD,
    },
    name: budget.data.name || "",
    description: budget.data.description || "",
    currentTypeAndFrequency: extractTypeAndFrequencyOptionsFromBudget(
      budget.data.config.type,
      budget.data.config.timeInterval
    ),
    filters: budget.data.config.filters || [],
    dimensionOptions: [],
    startPeriod: budget.data.config.startPeriod
      ? DateTime.fromMillis(budget.data.config.startPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc()),
    endPeriod: budget.data.config.endPeriod
      ? DateTime.fromMillis(budget.data.config.endPeriod.seconds * 1000).toUTC()
      : sanitizeDate(DateTime.utc()),
    currency: budget.data.config.currency,
    budgetAmount: budget.data.config.amount || 1000,
    alerts: budget.data.config.alerts.map((a) => ({
      ...a,
      amount: getAlertAmountFromPercentage(a.percentage, budget.data.config.amount || 1000),
    })),
    scope: [],
    dataSource: budget.data.config.dataSource ?? AnalyticsDataSource.BILLING,
  });

  useEffect(() => {
    setBudgetInfo((prev) => ({
      ...prev,
      scope: getAttributionScope(budget.data.config.scope, attributions),
    }));
  }, [budget.data.config.scope, attributions]);

  const [recipients, setRecipients] = useState<string[]>(cloneDeep(budget.data.recipients));
  const [recipientsSlackChannels, setRecipientsSlackChannels] = useState<SlackChannel[]>(
    cloneDeep(budget.data.recipientsSlackChannels) || []
  );

  const { fetchMetadata } = useCloudAnalyticsContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const isCurrentUserEditor = isEditor(currentUser.email, budget.data);

  const { customerOrPresentationModeCustomer: customer, customer: genuineCustomer } = useCustomerContext();
  const history = useHistory<CloudAnalyticsHistoryState>();
  const handleClose = useCallback(() => {
    history.push(history.location?.state?.prevPage ?? `/customers/${genuineCustomer.id}/analytics/budgets`);
  }, [history, genuineCustomer.id]);

  const stepComponents = [
    <Step1
      key="budget-scope"
      budgetInfo={budgetInfo}
      setBudgetInfo={setBudgetInfo}
      isCurrentUserEditor={isCurrentUserEditor}
      budget={budget}
    />,
    <Step2
      key="budget-configuration"
      isCurrentUserEditor={isCurrentUserEditor}
      setOverrideStep={setOverrideStep}
      dimensionOptions={[]}
      budgetInfo={budgetInfo}
      setBudgetInfo={setBudgetInfo}
      lastPeriodCost={budget.data.utilization?.lastPeriod || 0}
    />,
    <Step3
      key="budget-notification"
      budgetInfo={budgetInfo}
      budget={budget}
      setBudgetInfo={setBudgetInfo}
      isCurrentUserEditor={isCurrentUserEditor}
      shouldRefreshData={false}
      setRecipients={setRecipients}
      setRecipientsSlackChannels={setRecipientsSlackChannels}
      recipients={recipients}
      recipientsSlackChannels={recipientsSlackChannels}
    />,
  ];

  const steps: Step[] = [
    {
      children: stepComponents[0],
      label: budgetTxt.CREATE_BUDGET.STEP_1.TITLE,
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: stepComponents[1],
      label: budgetTxt.CREATE_BUDGET.STEP_2.TITLE,
      order: 1,
      required: true,
      state: stepperState[1],
    },
    {
      children: stepComponents[2],
      label: budgetTxt.CREATE_BUDGET.STEP_3.TITLE,
      order: 2,
      required: true,
      state: stepperState[2],
    },
  ];

  useEffect(() => {
    const step1State =
      budgetInfo.name !== "" && (budgetInfo.scope.length > 0 || (budgetInfo.filters?.length ?? 0) > 0)
        ? "complete"
        : "incomplete";
    const step2State = currentStep > 0 && budgetInfo.budgetAmount ? "complete" : "incomplete";
    const step3State = currentStep > 1 && step2State === "complete" ? "complete" : "incomplete";
    setStepperState([step1State, step2State, step3State]);
  }, [budgetInfo, currentStep]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  const prepareConfig = () => {
    const originalAmount = () => {
      if (isNaN(budgetInfo.budgetAmount)) {
        return 0;
      }
      return budgetInfo.budgetAmount !== budget.data.config.amount
        ? budgetInfo.budgetAmount
        : budget.data.config.originalAmount;
    };

    const allowGrowth =
      budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH ||
      budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH;
    const usePrevSpend =
      budgetInfo.configurationOption.type === BudgetConfigurations.DYNAMIC &&
      (budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD ||
        budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH);

    const config: BudgetConfig = {
      allowGrowth,
      growthPerPeriod: budgetInfo.growthPerPeriod,
      alerts: budgetInfo.alerts.map((a, i) => ({
        percentage: a.percentage,
        triggered: a.triggered,
        forecastedDate: budget.data.config.alerts[i].forecastedDate,
      })),
      amount: budgetInfo.budgetAmount,
      currency: budgetInfo.currency,
      startPeriod: TimestampFromDateTime(budgetInfo.startPeriod),
      timeInterval: budgetInfo.currentTypeAndFrequency.period,
      metric: Metric.COST,
      originalAmount: originalAmount(),
      usePrevSpend,
      dataSource: budget.data.config.dataSource,
      scope: budgetInfo.scope.map((s) => s.ref),
      type: budgetInfo.currentTypeAndFrequency.type,
      filters: budgetInfo.filters,
    };

    if (budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED) {
      config.endPeriod = TimestampFromDateTime(budgetInfo.endPeriod);
    }
    return config;
  };

  const editBudget = async (ref: ModelReference<CloudAnalyticsModelBudgetModel>) => {
    await updateBudget({
      name: budgetInfo.name,
      description: budgetInfo.description,
      config: prepareConfig(),
      ref,
    });
    const viewBudgetUrl = `/customers/${customer.id}/analytics/view-budget/${ref.id}`;
    history.push(viewBudgetUrl);
  };

  const createBudget = async () => {
    const newBudgetRef = await createNewBudget({
      name: budgetInfo.name,
      description: budgetInfo.description,
      config: prepareConfig(),
      customer,
      email: currentUser.email,
    });
    mixpanel.track("analytics.budgets.create", { budgetId: newBudgetRef.id });
    const viewBudgetUrl = `/customers/${customer.id}/analytics/view-budget/${newBudgetRef.id}`;
    history.push(viewBudgetUrl);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      if (isNewBudget) {
        await createBudget();
      } else {
        await editBudget(budget.ref);
      }
      successSnackbar(budgetTxt.SUCCESSFULLY_SAVED);
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar(budgetTxt.FAILED_TO_UPDATE);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ pt: 3, pb: 7 }}>
      <Stepper
        maxWidth={600}
        loading={loading}
        onCancel={handleClose}
        onSubmit={onSubmit}
        steps={steps}
        footerMaxWidth={800}
        contentSx={{
          px: 3,
          mb: 3,
          pt: 5,
        }}
        submitButtonLabel={isNewBudget ? budgetTxt.CREATE_BUDGET.SUBMIT_BTN : globalText.SAVE}
        getCurrentStep={getCurrentStep}
        overrideStep={overrideStep}
        backButtonLabel={isNewBudget ? budgetTxt.NEW_BUDGET : budgetTxt.EDIT_BUDGET}
        disableSubmit={!isCurrentUserEditor}
      />
    </Box>
  );
};

export default CreateBudgetStepper;
