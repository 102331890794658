import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { jsUcfirst, formatDecimalNumber } from "../../../utils/common";
import ProgressWithLabel from "./ProgressWithLabel";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  title: {
    padding: 16,
    paddingBottom: 4,
  },
  listItem: {
    "& span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "80%",
    },
  },
  formControl: {
    maxWidth: "100%",
  },
  overflowText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

export default function RollUpsMenu({ selectedSeries, onSelected, filters, selectedItem }) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let sum = 0;
    selectedSeries.forEach((item, index) => {
      sum += item.y;
      if (item.name === selectedItem.point.point.name) {
        setSelectedIndex(index);
      }
    });
    setTotal(sum);
  }, [selectedItem, selectedSeries]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onSelected(selectedSeries[index]);
  };

  const sign = filters[1]?.toLowerCase().includes("price") ? "$" : "TB";

  const addSpace = (val) => val?.replace(/([A-Z])/g, " $1")?.replace(/^./, (str) => str.toUpperCase());

  return (
    <div className={classes.root}>
      <FormControl margin="dense" className={classes.formControl}>
        <InputLabel id="project">{filters ? addSpace(jsUcfirst(filters[0])) : ""}</InputLabel>
        <Select
          labelId="project-select"
          value={selectedSeries[selectedIndex].name}
          label={filters ? addSpace(jsUcfirst(filters[0])) : ""}
        >
          {selectedSeries.map((item, i) => (
            <MenuItem
              selected={selectedIndex === i}
              onClick={(event) => handleListItemClick(event, i)}
              key={i}
              value={item.name}
            >
              <div className={classes.overflowText}>
                {filters[0] === "table" ? (item?.label?.tableId ?? item.name) : item.name}
                <ProgressWithLabel
                  label={sign === "$" ? sign + formatDecimalNumber(item.y, 0) : formatDecimalNumber(item.y, 0) + sign}
                  progress={parseInt((item.y * 100) / total)}
                />
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
