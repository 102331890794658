import OpenInNew from "@mui/icons-material/OpenInNew";
import { Alert, Button, Container, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { domainConfig } from "../../../domainConfig";
import FeatureList from "../FeatureList";

const TrialOverviewStep = () => {
  const { customer } = useCustomerContext();
  return (
    <Container maxWidth="md">
      <Stack spacing={3} pb={5} width="fit-content" alignItems="start">
        <Typography variant="h1">DoiT Cloud Navigator trial overview</Typography>
        <Stack spacing={1}>
          <Typography variant="h3">Get a free premium trial of DoiT Cloud Navigator</Typography>
          <Typography variant="body1" color="text.secondary" sx={{ display: "inline" }}>
            {`After your ${customer.tiers?.navigator?.trialCustomLength || 45} day trial, you can continue using DoiT Cloud Navigator with a subscription`}
          </Typography>
        </Stack>
        <FeatureList />
        <Button
          variant="text"
          href={domainConfig.urls.navigatorSubscriptionURL}
          startIcon={<OpenInNew />}
          target="_blank"
        >
          View full feature list
        </Button>
        <Alert severity="info" sx={{ width: "100%" }}>
          Your trial will begin once your data has been imported
        </Alert>
      </Stack>
    </Container>
  );
};

export default TrialOverviewStep;
