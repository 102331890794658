import { createContext, type ReactNode, useContext, useMemo } from "react";

import { type ReportWSnap } from "../../../types";

type ReportContextType = {
  report: ReportWSnap;
  rawReportData: any[][];
};

export const ReportContext = createContext<ReportContextType>({} as ReportContextType);

export const ReportContextProvider = ({
  children,
  report,
  rawReportData,
}: {
  children?: ReactNode;
  report: ReportWSnap;
  rawReportData: any[][];
}) => {
  const reportVal = useMemo(() => ({ report, rawReportData }), [report, rawReportData]);
  return <ReportContext.Provider value={reportVal}>{children}</ReportContext.Provider>;
};

export const useReportContext = (): ReportContextType => useContext(ReportContext);
