import { Typography } from "@mui/material";

import { type PackageType } from "../types";

type Props = {
  packageType: PackageType;
};

export const PackageName = ({ packageType }: Props) => {
  let planColor = "#E7DFFF";
  let planName = "Standard";
  switch (packageType) {
    case "standard":
      planColor = "#E7DFFF";
      planName = "Standard";
      break;
    case "enhanced":
      planColor = "#FEFFDF";
      planName = "Enhanced";
      break;
    case "premium":
      planColor = "#DFFFDF";
      planName = "Premium";
      break;
    case "accelerator":
      planColor = "#FFDFDF";
      planName = "Accelerator";
      break;
    case "single-ticket":
      planColor = "#DFFBFF";
      planName = "Single Ticket";
      break;
  }

  return (
    <Typography variant="h3" sx={{ backgroundColor: planColor, width: "fit-content", color: "black" }} px={2}>
      {planName}
    </Typography>
  );
};
