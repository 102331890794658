import { type Dispatch, type SetStateAction } from "react";

import { AssetTypeLooker } from "@doitintl/cmp-models";

import { type ContractStateType } from "../../types";
import { ifProductWithoutEntity, productAllowsNoEntity } from "../../utils";

export const validateGeneralStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, boolean> = {};

  if (!state.type) {
    errors.type = true;
  }

  if (!state.entity && !ifProductWithoutEntity(state.type, state.pointOfSale) && !productAllowsNoEntity(state.type)) {
    errors.entity = true;
  }

  if (!state.accountManager) {
    errors.accountManager = true;
  }

  if (state.type === AssetTypeLooker) {
    if (!state.lookerSalesProcess) {
      errors.lookerSalesProcess = true;
    }

    if (state.assets.length === 0 && !state.isEditForbidden) {
      errors.assets = true;
    }
  }

  setState({ ...state, errors });

  return !Object.values(errors).some((error) => error);
};
