import { type DateTime } from "luxon";

export type SubscriptionTerm = {
  startDate: DateTime;
  endDate: DateTime;
  amount: number;
};

export type Sku = {
  pricePerMonth: number;
  durationMonths: number;
  quantity: number;
};

/**
 * calculates the looker subsciption-terms dates and amounts
 *
 * the length each term is the invoicing frequency, minus 1 day
 * the term amount is the sum of the SKU prices that are active during that term
 *
 * @param contractStartDate - the date the contract starts
 * @param contractDurationMonths - the number of months the contract lasts
 * @param invoicingFrequency - the number of months between invoicing
 * @param skus - the SKUs that are part of the contract
 */
export function calculateLookerSubscription(
  contractStartDate: DateTime,
  contractDurationMonths: number,
  invoicingFrequency: number,
  skus: Sku[]
): SubscriptionTerm[] {
  const terms: SubscriptionTerm[] = [];
  for (let i = 0; i < contractDurationMonths; i += invoicingFrequency) {
    const startDate = contractStartDate.plus({ months: i });
    const endDate = startDate.plus({ months: invoicingFrequency });
    const amount = termAmount(skus, startDate, endDate, contractStartDate, contractDurationMonths);
    terms.push({
      startDate,
      endDate: endDate.minus({ days: 1 }),
      amount,
    });
  }
  return terms;
}

/**
 * SKU durations run BACKWARDS from the end of the contract,
 * for example, a SKU with a duration of 3 months is active for the last 3 months of the contract
 */
export function skuStartDate(
  contractStartDate: DateTime,
  contractDurationMonths: number,
  skuDurationMonths: number
): DateTime {
  const contractEndDate = contractStartDate.plus({ months: contractDurationMonths });
  return contractEndDate.minus({ months: skuDurationMonths });
}

/**
 * term amount is the sum of the SKU prices that are active during that term
 */
function termAmount(
  skus: Sku[],
  termStartDate: DateTime,
  termEndDate: DateTime,
  contractStartDate: DateTime,
  contractDurationMonths: number
): number {
  return skus.reduce((amount, sku) => {
    const months = skuActiveMonthsInTerm(
      termStartDate,
      termEndDate,
      skuStartDate(contractStartDate, contractDurationMonths, sku.durationMonths)
    );
    return amount + sku.pricePerMonth * sku.quantity * months;
  }, 0);
}

function skuActiveMonthsInTerm(termStartDate: DateTime, termEndDate: DateTime, skuStartDate: DateTime): number {
  const activeMonths = Math.min(
    termEndDate.diff(skuStartDate, "months").months,
    termEndDate.diff(termStartDate, "months").months
  );
  if (activeMonths < 0) {
    return 0;
  }
  return activeMonths;
}
