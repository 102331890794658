import { useCallback, useMemo } from "react";

import {
  CustomerModel,
  type CustomerModelCloudServices,
  KnownIssueModel,
  type KnownIssuePlatform,
  KnownIssuePlatforms,
} from "@doitintl/cmp-models";
import { getCollection, type TransformMethod, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type KnownIssue } from "../../../Pages/KnownIssues/types";

export const useGetActiveCloudIncidents = ():
  | { loading: true; cloudIncidents: undefined }
  | { loading: false; cloudIncidents: KnownIssue[] } => {
  const { customer } = useCustomerContext();

  const queryRelevantServices = getCollection(CustomerModel).doc(customer.id).collection("cloudServices");

  const transform: TransformMethod<CustomerModelCloudServices, KnownIssuePlatform> = useCallback(
    (service) => service.type,
    []
  );

  const [customerServices, customerServicesLoading] = useCollectionData(queryRelevantServices, { transform });

  const relevantServices: KnownIssuePlatform[] | undefined = useMemo(() => {
    if (customerServicesLoading) {
      return;
    }

    if (!customerServices) {
      return [];
    }

    return Array.from(
      new Set(
        customerServices.flatMap((serviceType) => {
          if (serviceType === "google-cloud") {
            return [serviceType, KnownIssuePlatforms.GCP];
          }

          return [serviceType];
        })
      )
    );
  }, [customerServices, customerServicesLoading]);

  const cloudIncidentsQuery = useMemo(() => {
    if (relevantServices === undefined) {
      return;
    }

    if (relevantServices.length === 0) {
      return null;
    }

    return getCollection(KnownIssueModel).where("status", "==", "ongoing").where("platform", "in", relevantServices);
  }, [relevantServices]);

  const [cloudIncidents, cloudIncidentsLoading] = useCollectionData(cloudIncidentsQuery ?? undefined, {
    idField: "id",
  });

  if (cloudIncidentsQuery === null) {
    return { loading: false, cloudIncidents: [] };
  }

  if (cloudIncidentsLoading) {
    return { loading: true, cloudIncidents: undefined };
  }

  return { loading: false, cloudIncidents: cloudIncidents ?? [] };
};
