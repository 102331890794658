import { Link as RouterLink } from "react-router-dom";
import { type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";

type MPARetireDialogProps = {
  onCancel: () => void;
  onRetire: () => Promise<void>;
  masterPayerAccount: MasterPayerAccountsModel;
};

export const MPARetireDialog = ({ onCancel, onRetire, masterPayerAccount }: MPARetireDialogProps) => {
  const [isRetiring, onRetireHandler] = useAsyncLoadingFunction(() => onRetire().then(onCancel));
  const linkedAccounts = (
    <Typography
      component={RouterLink}
      to={`/customers/${masterPayerAccount.customerId}/assets`}
      color="primary.main"
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      linked accounts
    </Typography>
  );

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>
        <Box sx={{ typography: "h3" }}>Retire MPA</Box>
        <Box sx={{ typography: "body2", color: "text.secondary" }}>
          {masterPayerAccount.name} {masterPayerAccount.domain ? "|" : ""} {masterPayerAccount.domain}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <DialogContentText>
            You’re about to permanently retire the MPA {masterPayerAccount.name}
            {masterPayerAccount.domain ? " used by " : ""}
            {masterPayerAccount.domain} and delete all its {linkedAccounts}. This action cannot be reversed.
          </DialogContentText>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton onClick={onRetireHandler} autoFocus loading={isRetiring} variant="contained">
          Retire MPA
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
