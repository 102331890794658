import { useCallback, useMemo, useState } from "react";

type DispatchTrue = () => void;

type DispatchFalse = () => void;

type Toggle = () => void;

/**
 * Hook that toggles value of boolean flag.
 *
 * @param initialValue [boolean] an initial state value
 * @returns [currentState, turnOnFunction, turnOffFunction, toggleFunction] an array with current state value and methods to toggle the state
 */
export function useToggle(initialValue = false): [boolean, DispatchTrue, DispatchFalse, Toggle] {
  const [state, setState] = useState(initialValue);
  const turnOn = useCallback(() => {
    setState(true);
  }, []);
  const turnOff = useCallback(() => {
    setState(false);
  }, []);
  const toggle = useCallback(() => {
    setState((current) => !current);
  }, []);
  return useMemo(() => [state, turnOn, turnOff, toggle], [state, toggle, turnOff, turnOn]);
}
