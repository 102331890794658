import { type JSX, useCallback, useState } from "react";

import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";

import { globalText } from "../../assets/texts";
import { reportTxt } from "../../assets/texts/CloudAnalytics";
import { useHotkeyContext } from "../../Context/HotkeyContext";
import { consoleErrorWithSentry } from "../../utils";
import LoadingButton from "../LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import SaveDialog from "./SaveDialog";

type Props = {
  disabled: boolean;
  onSave: (saveName?: string) => Promise<void>;
  showDialog?: boolean;
  successMessage?: string | JSX.Element;
  tooltip?: string;
  saveDialogLabel?: string;
};

const SaveComponent = ({ disabled, onSave, showDialog, successMessage, tooltip, saveDialogLabel }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const showErrorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();
  const { disable: setHotKeysDisabled, enable: setHotKeysEnabled } = useHotkeyContext();

  const save = useCallback(
    async (name?: string) => {
      setLoading(true);
      try {
        await onSave(name);
        if (successMessage) {
          successSnackbar(successMessage);
        }
      } catch (error) {
        consoleErrorWithSentry(error);
        showErrorSnackbar(globalText.SAVE_FAILED);
      } finally {
        setHotKeysDisabled();
        setLoading(false);
      }
    },
    [onSave, successMessage, successSnackbar, showErrorSnackbar, setHotKeysDisabled]
  );

  const handleClick = useCallback(async () => {
    if (!showDialog) {
      await save();
    } else {
      setHotKeysDisabled();
      setOpen(true);
    }
  }, [save, setHotKeysDisabled, showDialog]);

  const handleSave = useCallback(
    async (name: string) => {
      await save(name);
      setOpen(false);
    },
    [save]
  );

  const handleClose = useCallback(() => {
    setHotKeysEnabled();
    setOpen(false);
  }, [setHotKeysEnabled]);

  return (
    <>
      <Tooltip title={tooltip}>
        {/* Added Box for the tooltip, otherwise it doesn't work properly */}
        <Box display="inline-flex">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleClick}
            disabled={disabled || loading}
            loading={loading}
            data-cy="save-button"
            id="save-button"
            mixpanelEventId="component.save"
          >
            {globalText.SAVE}
          </LoadingButton>
        </Box>
      </Tooltip>
      {showDialog && open && (
        <SaveDialog
          title={reportTxt.NAME_BEFORE_SAVING}
          open={open}
          onClose={handleClose}
          onSave={handleSave}
          textFieldProps={{ label: saveDialogLabel }}
        />
      )}
    </>
  );
};

export default SaveComponent;
