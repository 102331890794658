import { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import find from "lodash/find";
import { formatDecimalNumber } from "../../../utils/common";
import { addSpaceAndToLower } from "../../../utils/string";
import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { useFullScreen } from "../../../utils/dialog";
import BasicTable from "./BasicTable";
import { slots, units } from "./constants";
import RollUpsMenu from "./RollUpsMenu";

import { QueryViewerDialog } from "./queryViewerDialog";

const getRowsFromObject = (obj, filter) =>
  Object.keys(obj)
    .map((key) => {
      if (obj[key].avgSlots && filter === slots) {
        return { name: key, value: obj[key].avgSlots, unit: "", query: true, data: obj[key] };
      }

      const currentUnit = find(units, { value: filter }).sign;

      if (obj[key].totalScanTB) {
        return { name: key, value: obj[key].totalScanTB, unit: currentUnit, query: true, data: obj[key] };
      }

      if (obj[key].totalScanPrice || obj[key].avgScanPrice || obj[key].jobRef) {
        const scan = filter === "scanPrice" ? obj[key].totalScanPrice : obj[key].scanTB;
        return { name: key, value: scan, unit: currentUnit, query: true, data: obj[key] };
      }

      return { name: key, value: obj[key], unit: currentUnit, query: false };
    })
    .sort((a, b) => {
      const keyA = a.value;
      const keyB = b.value;
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    })
    .reverse();

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  editor: {
    height: "600px !important",
  },
  cell: {
    backgroundColor: theme.palette.background.default,
    padding: 4,
  },
  code: {
    marginTop: 8,
    padding: 6,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  tableTitle: {
    marginBottom: 4,
    marginTop: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function QueryDialog({ open, onClose, point, selectedSeries }) {
  const { selectedMetric, selectedMeasurement } = useCurrentDashboardContext();
  const filters = [selectedMetric, selectedMeasurement];
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [selectedQueryValue, setSelectedQueryValue] = useState(null);
  const [openQueryViewer, setOpenQueryViewer] = useState(false);
  const { fullScreen } = useFullScreen();
  const filter = selectedMeasurement ?? "";

  useEffect(() => {
    setData(point);
  }, [point]);

  const handleClickOpen = (obj) => {
    setOpenQueryViewer(true);
    setSelectedQueryValue(obj);
  };

  const handleCloseQueryViewer = () => {
    setOpenQueryViewer(false);
    setSelectedQueryValue(null);
  };

  const renderStorage = () => (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <RollUpsMenu
            selectedSeries={selectedSeries}
            filters={filters}
            selectedItem={data}
            onSelected={(selected) =>
              setData({
                point: { point: { options: selected, label: selected.label } },
                filters,
              })
            }
          />
        </Grid>
        <Grid sm={8} xs={12} item>
          <Grid container spacing={1}>
            {data.point.point.options.label.projectId && filters[0] !== "project" && (
              <Grid item>
                <div className={classes.cell}>
                  <Typography color="textSecondary" variant="caption">
                    Project ID
                  </Typography>
                  <Typography>{data.point.point.options.label.projectId}</Typography>
                </div>
              </Grid>
            )}

            {data.point.point.options.label.datasetId && filters[0] !== "dataset" && (
              <Grid item>
                <div className={classes.cell}>
                  <Typography color="textSecondary" variant="caption">
                    Dataset ID
                  </Typography>
                  <Typography>{data.point.point.options.label.datasetId}</Typography>
                </div>
              </Grid>
            )}

            {(data.point.point.options.label.longTermStoragePrice ||
              data.point.point.options.label.longTermStoragePrice === 0) && (
              <Grid item xs={12}>
                <div className={classes.cell}>
                  <Typography color="textSecondary" variant="caption">
                    Long Term Storage Price
                  </Typography>
                  <Typography>
                    ${formatDecimalNumber(data.point.point.options.label.longTermStoragePrice, 2)}
                  </Typography>
                </div>
              </Grid>
            )}

            {data.point.point.options.label.shortTermStoragePrice >= 0 && (
              <Grid item xs={12}>
                <div className={classes.cell}>
                  <Typography color="textSecondary" variant="caption">
                    Short Term Storage Price
                  </Typography>
                  <Typography>
                    ${formatDecimalNumber(data.point.point.options.label.shortTermStoragePrice, 2)}
                  </Typography>
                </div>
              </Grid>
            )}

            {data.point.point.options.label.longTermStorageTB >= 0 && (
              <Grid item xs={12}>
                <div className={classes.cell}>
                  <Typography color="textSecondary" variant="caption">
                    Long Term Storage TB
                  </Typography>
                  <Typography>{data.point.point.options.label.longTermStorageTB}TB</Typography>
                </div>
              </Grid>
            )}
            {data.point.point.options.label.shortTermStorageTB >= 0 && (
              <Grid item xs={12}>
                <div className={classes.cell}>
                  <Typography color="textSecondary" variant="caption">
                    Short Term Storage TB
                  </Typography>
                  <Typography>{data.point.point.options.label.shortTermStorageTB}TB</Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ marginTop: 6 }} container spacing={1}>
        {data.point.point.options.label.topProjects && (
          <Grid item sm={6} xs={12}>
            <Typography color="textSecondary" className={classes.tableTitle} variant="subtitle2">
              Top projects scanned {filters[0] === "user" ? "by" : "in"} {data.point.point.options.name} {filters[0]}:
            </Typography>
            <BasicTable
              headerTitles={["Project ID", find(units, { value: filter }).title]}
              rows={getRowsFromObject(data.point.point.options.label.topProjects, filter)}
            />
          </Grid>
        )}
        {data.point.point.options.label.topDatasets && (
          <Grid item sm={6} xs={12}>
            <Typography color="textSecondary" className={classes.tableTitle} variant="subtitle2">
              Top datasets scanned {filters[0] === "user" ? "by" : "in"} {data.point.point.options.name} {filters[0]}:
            </Typography>
            <BasicTable
              headerTitles={["Dataset ID", find(units, { value: filter }).title]}
              rows={getRowsFromObject(data.point.point.options.label.topDatasets, filter)}
            />
          </Grid>
        )}
        {data.point.point.options.label.topUsers && (
          <Grid item sm={6} xs={12}>
            <Typography color="textSecondary" className={classes.tableTitle} variant="subtitle2">
              Top users executing queries in {addSpaceAndToLower(filters[0])} {data.point.point.options.name}:
            </Typography>
            <BasicTable
              headerTitles={["User", find(units, { value: filter }).title]}
              rows={getRowsFromObject(data.point.point.options.label.topUsers, filter)}
            />
          </Grid>
        )}

        {data.point.point.options.label.topTables && (
          <Grid item sm={6} xs={12}>
            <Typography color="textSecondary" className={classes.tableTitle} variant="subtitle2">
              Top tables scanned {filters[0] === "user" ? "by" : "in"} {data.point.point.options.name} {filters[0]}:
            </Typography>
            <BasicTable
              headerTitles={["Table", find(units, { value: filter }).title]}
              rows={getRowsFromObject(data.point.point.options.label.topTables, filter)}
              data={data.point.point.options.label}
            />
          </Grid>
        )}

        {data.point.point.options.label.topQueries && (
          <Grid item sm={6} xs={12}>
            <Typography color="textSecondary" className={classes.tableTitle} variant="subtitle2">
              Top queries {filters[0] === "user" ? "ran by" : "running in"} {addSpaceAndToLower(filters[0])}{" "}
              {data.point.point.options.name}:
            </Typography>

            <BasicTable
              headerTitles={["Query", "Execution Time(sec)", find(units, { value: selectedMeasurement }).title]}
              rows={getRowsFromObject(data.point.point.options.label.topQueries, filter)}
              handleQueryClick={handleClickOpen}
            />
          </Grid>
        )}
      </Grid>
    </>
  );

  if (!data) {
    return null;
  }

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={filter.indexOf("storage") > -1 ? "md" : "xl"}
        fullWidth={true}
        fullScreen={fullScreen}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogContent>
          <div className={classes.content}>{renderStorage()}</div>
          {selectedQueryValue && (
            <QueryViewerDialog
              selectedValue={selectedQueryValue}
              open={openQueryViewer}
              onClose={handleCloseQueryViewer}
            />
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button autoFocus onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
