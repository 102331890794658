import { type ReactElement, useCallback, useState } from "react";

import { type GoogleCloudAssetModel } from "@doitintl/cmp-models";
import { Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useApiContext } from "../../../../../api/context";
import { SafeHtml } from "../../../../../Components/SafeHtml";
import { useSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type Asset } from "../../../../../types";
import { consoleErrorWithSentry } from "../../../../../utils";
import { cleanEmail } from "../../../../../utils/email";
import AckDialog from "../../../Dialogs/AckDialog";
import { patchGCBillingAccountAdminMembers } from "../../../Forms/api";
import { handleSendInstructions } from "../../utils";
import { AssetPageAccordion } from "./AssetPageAccordion";

type Props = {
  admins: Asset<GoogleCloudAssetModel>["data"]["properties"]["admins"];
  entityId: string | null;
  billingAccountId: string;
  displayName: string;
  defaultExpanded?: boolean;
};

export const BillingAdminsAccordion = (props: Props) => {
  const { admins, entityId, billingAccountId, displayName, defaultExpanded } = props;
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const snackBar = useSnackbar();
  const theme = useTheme();
  const { currentUser, isDoitEmployee } = useAuthContext();
  const [emailSendLoading, setEmailSendLoading] = useState<boolean>(false);
  const [emailAddressLoading, setEmailAddressLoading] = useState<string>("");
  const [removeAdminUser, setRemoveAdminUser] = useState<string | null>();
  const [removalLoading, setRemovalLoading] = useState<boolean>(false);
  const [accordionAdmins, setAccordionAdmins] = useState<string[]>(admins);

  const handleEmailToAdmin = useCallback(
    async (emailAddress) => {
      setEmailSendLoading(true);
      try {
        setEmailAddressLoading(emailAddress);
        const cleanUserEmail = cleanEmail(emailAddress);
        const res =
          entityId &&
          (await handleSendInstructions(cleanUserEmail, api, customer.id, entityId, billingAccountId, displayName));
        res &&
          snackBar.onOpen({
            message:
              res === "success"
                ? `Instructions sent to ${cleanUserEmail}`
                : `Error: Instructions email not sent to ${cleanUserEmail}`,
            variant: res === "success" ? "success" : "error",
            autoHideDuration: 5000,
            action: [
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  snackBar.onClose();
                }}
                size="large"
              >
                <Close />
              </IconButton>,
            ],
          });
      } finally {
        setEmailSendLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, billingAccountId, customer.id, displayName, entityId]
  );

  const handleRemoveUser = useCallback(async () => {
    if (!removeAdminUser) {
      return;
    }

    setRemovalLoading(true);
    try {
      if (!entityId) {
        throw new Error("Entity ID is missing");
      }
      if (!removeAdminUser) {
        throw new Error("Admin user is missing");
      }

      await patchGCBillingAccountAdminMembers({
        api,
        billingAccountId,
        customerId: customer.id,
        entityId,
        membersToRemove: [removeAdminUser],
        mixpanelRemoveDisplayName: displayName,
      });

      snackBar.onOpen({
        message: `Admin ${removeAdminUser} successfully removed`,
        variant: "success",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={snackBar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      setRemovalLoading(false);
      setAccordionAdmins((prevAccordionAdmins) => prevAccordionAdmins.filter((admin) => admin !== removeAdminUser));
    } catch (error: any) {
      if (error.response) {
        snackBar.onOpen({
          message: error.response.data ? (
            <SafeHtml html={error.response.data} />
          ) : (
            "Failed to remove billing account admins"
          ),
          variant: "error",
          autoHideDuration: 5000,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={snackBar.onClose} size="large">
              <CloseIcon />
            </IconButton>,
          ],
        });
      }
      consoleErrorWithSentry(error);
      setRemovalLoading(false);
    }
    setRemoveAdminUser(null);
  }, [api, billingAccountId, customer.id, displayName, entityId, removeAdminUser, snackBar]);

  const rowContent = useCallback(
    (admin: string): ReactElement => {
      const height = "20px";
      const sendEmail = (
        <Button
          variant="text"
          onClick={() => handleEmailToAdmin(admin)}
          disabled={removalLoading}
          sx={{ textTransform: "unset", height, my: 0, color: theme.palette.text.secondary }}
          data-cy={`sendEmailButton${admin}`}
        >
          <Typography variant="body2" sx={{ height, my: 0, textDecoration: "underline" }} my={1} noWrap={true}>
            {emailSendLoading && emailAddressLoading === admin ? "Sending..." : "Send instructions"}
          </Typography>
        </Button>
      );
      const removeAdminButton = (
        <Button
          variant="text"
          onClick={() => {
            setRemoveAdminUser(admin);
          }}
          disabled={removalLoading}
          sx={{
            "&:hover": { backgroundColor: "rgba(211, 47, 47, 0.08)" },
            textTransform: "unset",
            height,
            my: 0,
          }}
          data-cy={`removeAdminButton${admin}`}
        >
          <Typography variant="body2" my={1} color="error" sx={{ cursor: "pointer" }} noWrap={true}>
            Remove admin
          </Typography>
        </Button>
      );
      const email = currentUser?.email ?? (null as unknown as string);
      const buttons = (
        <Stack
          direction="row"
          justifyContent="flex-start"
          gap={1}
          divider={<Divider orientation="vertical" flexItem />}
          ml="auto"
        >
          {sendEmail}
          {(admins.includes(`user:${email}`) || isDoitEmployee) && removeAdminButton}
        </Stack>
      );

      return (
        <Box sx={{ my: 1 }}>
          {removeAdminUser === admin && (
            <AckDialog
              open={removeAdminUser === admin}
              onCancel={() => {
                setRemoveAdminUser(null);
              }}
              onAccept={handleRemoveUser}
              title="Remove billing account admin"
              text={
                !!removeAdminUser && (
                  <>
                    Are you sure you want to remove the billing account administrator{" "}
                    <strong>{cleanEmail(removeAdminUser)}</strong> from <strong>{displayName}</strong>?
                  </>
                )
              }
              cyDataPrefix="removeAdmin"
            />
          )}
          <Stack direction="row" justifyContent="flex-start">
            <Typography variant="body2" sx={{ height, my: 0 }} data-cy={`adminEmail${admin}`} noWrap={true}>
              {cleanEmail(admin)}
            </Typography>
            {buttons}
          </Stack>
        </Box>
      );
    },
    [
      admins,
      currentUser?.email,
      displayName,
      emailAddressLoading,
      emailSendLoading,
      handleEmailToAdmin,
      handleRemoveUser,
      isDoitEmployee,
      removalLoading,
      removeAdminUser,
      theme.palette.text.secondary,
    ]
  );

  const title = "Billing administrators";

  return (
    <AssetPageAccordion
      items={accordionAdmins}
      admins={true}
      rowContent={rowContent}
      title={title}
      entityId={entityId}
      billingAccountId={billingAccountId}
      displayName={displayName}
      setAccordionAdmins={setAccordionAdmins}
      defaultExpanded={defaultExpanded}
    />
  );
};
