import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { Typography, Link } from "@mui/material";
import { DateTime } from "luxon";
import sortBy from "lodash/sortBy";
import CloudDownload from "@mui/icons-material/CloudDownload";
import { green } from "@mui/material/colors";
import { exportCSVFile } from "../../../utils/csv";
import { formatDecimalNumber } from "../../../utils/common";
import { helpURLs } from "../../../assets/urls";
import { useFullScreen } from "../../../utils/dialog";
import Slider from "./Slider";
import ScrollTable from "./ScrollTable";
import { QueryViewerDialog } from "./queryViewerDialog";
import { Recommendation } from "./suggestion";
import { recommendationKey } from "./constants";

const instructionsText = [
  { text: "Upload Google Cloud service account key to CMP", isDone: true },
  {
    text: "Set up Team Caching in superQuery",
    isDone: false,
    url: helpURLs.SQ_TEAM_CACHING,
  },
  { text: "Run all query traffic via superQuery", isDone: false },
];

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  cell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 200,
    transition: theme.transitions.create(["background", "opacity"], {
      duration: theme.transitions.duration.complex,
    }),
  },
  cellActive: {
    background: "green",
    opacity: 1,
  },
}));

export default function RecommendationsDetailsView({ open, onClose, data }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sliderVal, setSliderVal] = useState(null);
  const [openQueryViewer, setOpenQueryViewer] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [querySavings, setQuerySavings] = useState(null);
  const [queryPercent, setQueryPercent] = useState(null);
  const { isMobile: matches } = useFullScreen("sm");

  const getFormat = (colValue, key, colProps) => {
    if (!isNaN(colValue)) {
      if (colProps && colProps.sign) {
        if (colProps.sign === "$") {
          return (val) => `$${formatDecimalNumber(val, 2)}`;
        } else {
          return (val) => formatDecimalNumber(val, 2) + colProps.sign;
        }
      }
      if (!colProps) {
        return (val) => `$${formatDecimalNumber(val, 2)}`;
      }
      return (val) => formatDecimalNumber(val, 0);
    }
    if (!DateTime.fromISO(colValue).invalid) {
      if (key !== "scheduledTime") {
        return (val) => (val ? DateTime.fromISO(val).toFormat("dd LLL yy hh:mm") : "");
      } else {
        return (val) => (val ? DateTime.fromISO(val).toFormat("hh:mm") : "");
      }
    }

    return (val) => val;
  };

  useEffect(() => {
    if (data && data.detailedTable) {
      const cols = [];
      Object.keys(data.detailedTable[0])
        .sort()
        .forEach((key) => {
          if (key.indexOf("reducingBy") === -1 && key.indexOf("jobRef") === -1) {
            cols.push({
              id: key,
              label: key,
              isPartition: data.detailedTableFieldsMapping[key]?.isPartition,
              visible: data.detailedTableFieldsMapping[key] ? data.detailedTableFieldsMapping[key].visible : true,
              sign: data.detailedTableFieldsMapping[key]?.sign,
              order: data.detailedTableFieldsMapping[key]
                ? data.detailedTableFieldsMapping[key].order
                : data.detailedTableFieldsMapping.reducingBy50.order,

              title: data.detailedTableFieldsMapping[key]
                ? data.detailedTableFieldsMapping[key].title
                : data.detailedTableFieldsMapping.reducingBy50.title,
              minWidth: 80,
              maxWidth: 200,
              format: getFormat(data.detailedTable[0][key], key, data.detailedTableFieldsMapping[key]),
            });
          } else if (
            key.indexOf("reducingBy10") > -1 &&
            key.indexOf("jobRef") === -1 &&
            !data.detailedTable[0].savingByReducingJob
          ) {
            cols.push({
              id: "savingByReducingJob",
              label: "savingByReducingJob",
              visible: true,
              order: data.detailedTableFieldsMapping.reducingBy50.order,
              title: data.detailedTableFieldsMapping.reducingBy50.title,
              minWidth: 80,
              sign: "$",
              maxWidth: 200,
              format: getFormat(data.detailedTable[0][key], "savingByReducingJob", null),
            });
            setSliderVal(20);
          }
        });
      setColumns(sortBy(cols, ["order"]));
      setRows(data.detailedTable);
    }

    return () => {
      setRows([]);
      setColumns([]);
      setSliderVal(20);
      setQuerySavings(null);
      setQueryPercent(null);
    };
  }, [data, open]);

  useEffect(() => {
    setRows((rows) => {
      if (rows && rows[0] && rows[0].reducingBy20) {
        return rows.map((row) => {
          row.savingByReducingJob = row[`reducingBy${sliderVal}`];
          return row;
        });
      } else {
        return rows;
      }
    });
  }, [sliderVal, open]);

  useEffect(() => {
    if (data) {
      setQuerySavings(data[`sumSavingsReducingBy${sliderVal}`]);
      setQueryPercent((data[`sumSavingsReducingBy${sliderVal}`] * 100) / data.actualCost);
    }
  }, [sliderVal, data]);

  const exportCSV = () => {
    const headers = {};
    columns.forEach((column) => {
      headers[column.id] = column.title;
    });

    const fileTitle = `${data.recommendation}_csv`;
    const tmpRows = rows.map((row) => {
      const r = {};
      columns.forEach((column) => {
        r[column.id] = row[column.id];
        if (typeof r[column.id] === "string" && r[column.id].indexOf(",") > -1) {
          r[column.id] = r[column.id].replace(/,/g, "|");
        }
        if (column.isPartition) {
          r[column.id] = row[column.id].length;
        }
      });
      return r;
    });
    exportCSVFile(headers, tmpRows, fileTitle);
  };

  const handleClose = () => {
    onClose();
  };

  const handleChangeSlider = (val) => {
    setSliderVal(val);
  };

  const onCloseQueryViewr = () => {
    setOpenQueryViewer(false);
  };

  const handleClickQueryJob = (data) => {
    setOpenQueryViewer(true);
    setJobId(data);
  };

  const getIntegrationText = () => (
    <div>
      <List>
        {instructionsText.map((item, i) => (
          <ListItem key={i}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: item.isDone ? green[300] : "" }}>
                {item.isDone ? <CheckIcon /> : i + 1}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={item.text}
              secondary={
                item.url && (
                  <Link target="_blank" href={item.url}>
                    Read more...
                  </Link>
                )
              }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  if (!data) {
    return null;
  }

  return (
    <div>
      <div className={classes.dialog}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          fullScreen={matches}
          maxWidth={data.superQuery ? "md" : "lg"}
        >
          <DialogTitle id="alert-dialog-title" style={{ paddingBottom: 0 }}>
            {data.recommendation}
          </DialogTitle>
          <DialogContent style={{ paddingTop: 0 }}>
            {data.superQuery ? (
              getIntegrationText()
            ) : (
              <>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div>
                    <Typography component="span" variant="subtitle2">
                      Savings:{" "}
                    </Typography>

                    {data.detailedTable[0]?.reducingBy10 ? (
                      <>
                        <Typography style={{ fontWeight: 400 }} variant="subtitle2" component="span">
                          {formatDecimalNumber(queryPercent, 2)}% / ${formatDecimalNumber(querySavings, 2)}
                        </Typography>
                        <Slider handleChangeValue={handleChangeSlider} />
                      </>
                    ) : (
                      <Typography style={{ fontWeight: 400 }} variant="subtitle2" component="span">
                        {formatDecimalNumber(data.savingsPercentage, 2)}% / ${formatDecimalNumber(data.savingsPrice, 2)}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <IconButton aria-label="Download as CSV" onClick={exportCSV} size="large">
                      <CloudDownload />
                    </IconButton>
                  </div>
                </div>

                <Recommendation recommendationKey={data[recommendationKey]} />

                <div style={{ height: 20 }} />
                <ScrollTable handleClick={handleClickQueryJob} sliderVal={sliderVal} rows={rows} columns={columns} />
              </>
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {jobId && <QueryViewerDialog onClose={onCloseQueryViewr} selectedValue={jobId} open={openQueryViewer} />}
    </div>
  );
}
