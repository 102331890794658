import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { type accountsTableRowType } from "../types";
import { AccountRow } from "./AccountRow";
import { filterColumns, headerColumns } from "./columns";
import { useAccountsTableData } from "./hooks/useAccountsTableData";

export function AccountsTable() {
  const accountsTableData = useAccountsTableData();

  return (
    <FilterTable<accountsTableRowType>
      tableItems={accountsTableData}
      rowComponent={AccountRow}
      headerColumns={headerColumns}
      filterColumns={filterColumns}
      itemUniqIdentifierField="accountId"
      persistenceKey="account-aws-marketplace"
      defaultSortingColumnValue="updatedAt"
      defaultSortDirection="desc"
    />
  );
}
