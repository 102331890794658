import { useCallback } from "react";

import { ManagedType, Status } from "@doitintl/cmp-models";

import { useApiContext } from "../../../api/context";
import { httpMethods } from "../../../assets/texts";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { type PayerConfigDTO } from "../../Flexsave/Operations/AWS/AWSPayerConfigForm/types";
import { useUpdatePayer } from "../../Flexsave/Operations/AWS/hooks";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";

export function useMPARetireRequest() {
  const { onOpen } = useSnackbar();
  const disablePayer = useDisablePayer();
  const api = useApiContext();

  return useCallback(
    async (masterPayerAccount: MasterPayerAccountsModelSnapshot) => {
      try {
        await api.request({
          method: httpMethods.POST,
          url: `/v1/amazon-web-services/master-payer-accounts/retire/${masterPayerAccount.accountNumber}`,
        });
      } catch (exception) {
        onOpen({
          message: `Failed to retire MPA ${masterPayerAccount.name}`,
          variant: "error",
          autoHideDuration: 5000,
          withClose: true,
        });
        consoleErrorWithSentry(exception);
        return;
      }

      await disablePayer(masterPayerAccount);

      onOpen({
        message: `MPA ${masterPayerAccount.name} successfully retired`,
        variant: "success",
        autoHideDuration: 5000,
        withClose: true,
      });
    },
    [onOpen, disablePayer, api]
  );
}

function useDisablePayer() {
  const { onOpen } = useSnackbar();
  const handleDisable = useUpdatePayer();

  return useCallback(
    async (masterPayerAccount: MasterPayerAccountsModelSnapshot) => {
      try {
        const payerConfig: PayerConfigDTO = {
          status: Status.disabled,
          sagemakerStatus: Status.disabled,
          rdsStatus: Status.disabled,
          statusChangeReason: "MPA retirement",
          managed: ManagedType.auto,
        };

        await handleDisable(masterPayerAccount.accountNumber, payerConfig);
      } catch (exception: any) {
        onOpen({
          message: `Failed disable MPA ${masterPayerAccount.name}, ${masterPayerAccount.accountNumber}, err: ${exception.message}`,
          variant: "error",
          autoHideDuration: 5000,
          withClose: true,
        });
        consoleErrorWithSentry(exception);
      }
    },
    [handleDisable, onOpen]
  );
}
