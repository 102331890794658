import { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import { EntityModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import { type LocationDescriptor, type LocationDescriptorObject } from "history";

import { useAuthContext } from "../../Context/AuthContext";
import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";

export type ReferrerHistoryState = {
  referrer: string;
};

function isLocationDescriptorObject(
  maybeLocationDescriptor: LocationDescriptor<ReferrerHistoryState>
): maybeLocationDescriptor is LocationDescriptorObject<ReferrerHistoryState> {
  return typeof maybeLocationDescriptor === "object";
}

export function createLocationDescriptorWithReferrer(
  pathnameOrLocationDescriptor: LocationDescriptor<ReferrerHistoryState>
): LocationDescriptor<ReferrerHistoryState> {
  const stateWithReferrer = {
    referrer: location.pathname,
  };

  return isLocationDescriptorObject(pathnameOrLocationDescriptor)
    ? { ...pathnameOrLocationDescriptor, state: { ...pathnameOrLocationDescriptor.state, ...stateWithReferrer } }
    : {
        pathname: pathnameOrLocationDescriptor,
        state: stateWithReferrer,
      };
}

const referrerStorageKey = "go-back-to-referrer";

export function useGoToURLWithReferrer(url?: string): () => void {
  const history = useHistory<ReferrerHistoryState>();
  return useCallback(() => {
    history.push(url || "", { referrer: history.location.pathname });
  }, [history, url]);
}

export function useGoBackToReferrer(fallbackURL: string): () => void {
  const history = useHistory<ReferrerHistoryState>();
  const [referrer] = useState(
    history.location.state?.referrer || sessionStorage.getItem(referrerStorageKey) || fallbackURL
  );

  useEffect(() => {
    sessionStorage.setItem(referrerStorageKey, referrer);

    return () => {
      sessionStorage.removeItem(referrerStorageKey);
    };
  }, [referrer]);

  return useCallback(() => {
    history.push(referrer);
  }, [history, referrer]);
}

export const useEntityData = () => {
  const { entities, customer, init } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();

  const [entity, setEntity] = useState<Entity>();
  const routerHistory = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const params = useParams<{ entityId: string }>();

  const ref = getCollection(EntityModel).doc(params.entityId);
  const [entityDoc, loading] = useDocumentData(ref, { idField: "id", refField: "ref" });

  useEffect(() => {
    // need to wait until customer context is loaded and customer ref in context matches customerId from URL
    // as for a brief moment there may be a mismatch when navigating to a billing profile from search bar
    if (init && customerId === customer.id && !loading) {
      const entity = entities.find(({ id }) => id === params.entityId);
      if ((entity === undefined && !isDoitEmployee) || entityDoc === undefined) {
        routerHistory.push("/not-found");
      } else {
        setEntity(entityDoc);
      }
    }
  }, [entities, routerHistory, params.entityId, init, customerId, customer.id, isDoitEmployee, entityDoc, loading]);

  return entity;
};
