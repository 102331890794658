import { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { useAssetsContext } from "../../../Context/customer/AssetContext";
import { type Asset } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import { maxAssetTagLength } from "../assetUtils";

type Props = {
  onClose: () => void;
  onSave: (tags: string[]) => void;
  selectedAsset: Asset;
};

export default function AddTagDialog({ onClose, onSave, selectedAsset }: Props) {
  const { assets } = useAssetsContext();
  const { isMobile: matches } = useFullScreen();
  const [tags, setTags] = useState(selectedAsset?.data.tags ?? []);
  const [suggestionTags, setSuggestionTags] = useState<string[]>([]);

  useEffect(() => {
    const arr: string[] = [];
    Object.keys(assets).forEach((key) => {
      const entity = assets[key];
      entity.forEach((asset) => {
        if (asset.data.tags) {
          arr.push(...asset.data.tags);
        }
      });
    });
    setSuggestionTags((suggestedTags) => [...new Set([...arr, ...suggestedTags])]);
  }, [assets]);

  const handleSave = () => {
    onSave(tags);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        fullScreen={matches}
        aria-labelledby="tags-dialog-title"
        aria-describedby="tags-dialog-description"
        data-cy="dialogAddTag"
      >
        <DialogTitle id="tags-dialog-title" sx={{ pb: 0 }}>
          Tag asset
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="tags-dialog-description" sx={{ mb: 3 }}>
            <Typography variant="body2">Asset tags appear on invoices, e.g. cost-centers or team names</Typography>
          </DialogContentText>
          <Autocomplete
            id="tags-input"
            freeSolo
            autoSelect
            filterSelectedOptions
            options={suggestionTags}
            value={tags[0]}
            onChange={(_event, newValue) => {
              if (newValue) {
                setTags([newValue]);
              } else {
                setTags([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Asset tag"
                helperText="Maximum one tag per asset is allowed"
                data-cy="tagsInputs"
                inputProps={{ ...params.inputProps, maxLength: maxAssetTagLength }}
              />
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} color="primary" data-cy="cancelBtn">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} color="primary" autoFocus data-cy="saveBtn">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
