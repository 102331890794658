import { type ChangeEvent, Fragment, useState } from "react";

import {
  Box,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { InnerScreenHeader } from "../InnerScreenHeader";
import { AddArn } from "./AddARN";
import { FeaturesSelection } from "./FeaturesSelection/FeaturesSelection";

enum Mode {
  CloudFormation = "cf",
  ARN = "arn",
}

type LinkAccountProps = {
  backPageCallback: () => void;
};

/**
 * Screen to link new account to CMP
 */
export const LinkAccount = ({ backPageCallback }: LinkAccountProps) => {
  const { customer } = useCustomerContext();
  const [mode, setMode] = useState<string>(Mode.CloudFormation);

  const handleModeSelection = (event: ChangeEvent<HTMLInputElement>) => {
    setMode((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Card>
        <InnerScreenHeader title="Link account" subtitle={customer.name} backPageCallback={backPageCallback} />
        <CardContent>
          <Grid container>
            <Grid item md={3} />
            <Grid item xs={12} md={6}>
              <Box pb={3}>
                <FormControl component="fieldset">
                  <Box pb={1}>
                    <Typography variant="subtitle1">
                      Select one of the following options to link your AWS account
                    </Typography>
                  </Box>
                  <RadioGroup value={mode} onChange={handleModeSelection}>
                    <FormControlLabel
                      value={Mode.CloudFormation}
                      control={<Radio color="primary" />}
                      label="Create a role automatically."
                    />
                    <FormControlLabel
                      value={Mode.ARN}
                      control={<Radio color="primary" />}
                      label="Create a role manually."
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Divider />

              <Box pt={3} hidden={mode === Mode.ARN}>
                <Typography variant="subtitle1">Select features you'd like to enable on your account</Typography>

                <FeaturesSelection onUpdate={backPageCallback} />
              </Box>

              <Box pt={3} hidden={mode === Mode.CloudFormation}>
                <AddArn />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
