import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootSagas from "../sagas";
import { isLocalhost, isNordia } from "../../constants";
import rootReducer from "./reducers";

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  let store;

  if (process.env.LOGGER && (isLocalhost || isNordia)) {
    store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware, logger));
  } else {
    store = createStore(rootReducer, initialState, applyMiddleware(sagaMiddleware));
  }

  store.runSaga = sagaMiddleware.run(rootSagas);
  return store;
}
