import { type Dispatch, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { Metadata, Renderer, Roles, Sort, TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { Add, Addchart, CheckCircle } from "@mui/icons-material";
import { Button, Paper, Stack, type SvgIconTypeMap, Typography } from "@mui/material";
import { type OverridableComponent } from "@mui/material/OverridableComponent";

import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import useGenerateReport from "../../../../Components/hooks/useGenerateReport";
import { useAuthContext } from "../../../../Context/AuthContext";
import { TimestampFromDate } from "../../../../utils/firebase";
import { type CostAllocationAction, type CostAllocationState } from "../costAllocationReducer";
import { NUM_OF_MONTHS, sendStepMixpanelEvent } from "../utils";

const STEP_NUMBER = 6;

type CustomButtonProps = {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  title: string;
  onClick: () => void;
};

const CustomButton = ({ title, onClick, IconComponent }: CustomButtonProps) => (
  <Paper component={Button} variant="outlined" onClick={onClick}>
    <Stack alignItems="center" justifyContent="center" gap={1} p={2} width={155}>
      <IconComponent sx={{ color: "action.active" }} />
      <Typography variant="body2" color="textPrimary" textAlign="center">
        {title}
      </Typography>
    </Stack>
  </Paper>
);

type FinalStepProps = {
  costAllocationState: CostAllocationState;
  dispatchCostAllocationState: Dispatch<CostAllocationAction>;
  handleGoToStep: (step: number) => void;
  origin: string;
};

const FinalStep = ({ costAllocationState, dispatchCostAllocationState, handleGoToStep, origin }: FinalStepProps) => {
  const generateReport = useGenerateReport();
  const history = useHistory();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const customerId = useCustomerId();

  useEffect(() => {
    sendStepMixpanelEvent(STEP_NUMBER, "final", origin);
  }, [origin]);

  const handleAllocateMore = () => {
    dispatchCostAllocationState({ type: "RESET", payload: {} });
    handleGoToStep(0);
  };

  const createNewReport = async (config, newName?: string): Promise<string> => {
    const draft = newName === "";
    const expireBy = draft ? TimestampFromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)) : null;
    const newReportId = await generateReport({
      name: newName ?? `Copy of `,
      description: "",
      collaborators: [{ email: currentUser.email, role: Roles.OWNER }],
      config,
      draft: true,
      expireBy,
    });

    return newReportId;
  };

  const handleNewReport = async () => {
    const timeInterval = TimeInterval.MONTH;
    const timeSettings = {
      amount: NUM_OF_MONTHS,
      mode: TimeSettingsMode.Last,
      unit: timeInterval,
      includeCurrent: false,
    };
    const newReportId = await createNewReport(
      {
        fields: [
          {
            type: Metadata.ATTRIBUTION_GROUP,
            id: costAllocationState.AGId,
            values: [],
            groupBy: true,
          },
        ],
        renderer: Renderer.TABLE,
        rowOrder: Sort.A_TO_Z,
        timeInterval,
        timeSettings,
      },
      ""
    );
    history.push(`/customers/${customerId}/analytics/reports/${newReportId}`);
  };

  return (
    <>
      <Stack alignItems="center" gap={1} mb={7}>
        <CheckCircle sx={{ color: "#4CAF50", mb: 2, width: 54, height: 54 }} />
        <Typography variant="h1" color="textPrimary">
          {guidedExperienceText.finalStep.title}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          {guidedExperienceText.finalStep.description}
        </Typography>
      </Stack>
      <Stack alignItems="center" gap={4}>
        <Typography variant="body1" color="textSecondary">
          {guidedExperienceText.finalStep.nextDescription}
        </Typography>
        <Stack direction="row" gap={3}>
          <CustomButton
            title={guidedExperienceText.finalStep.allocateMore}
            onClick={handleAllocateMore}
            IconComponent={Add}
          />
          <CustomButton
            title={`${guidedExperienceText.finalStep.createReportText} ${costAllocationState.selectedAG.name}`}
            onClick={handleNewReport}
            IconComponent={Addchart}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default FinalStep;
