import { type ReactNode } from "react";

import { Box, Stack, Typography } from "@mui/material";

type Props = {
  list: ReactNode[];
};

export const NumberedList = ({ list }: Props) => (
  <Box>
    {list.map((item, index) => (
      <Stack key={index} direction="row" spacing={0.5}>
        <Typography>{index + 1}.</Typography>
        <Typography style={{ flex: 1 }}>{item}</Typography>
      </Stack>
    ))}
  </Box>
);
