import { type CustomerModel, type TierPackageType, TierPackageTypes } from "@doitintl/cmp-models";
import { DateTime, Interval } from "luxon";

import { type Customer } from "../types";
import { type FirestoreTimestamp } from "./firebase";

export type TierTrialDates<T> = {
  start: T | undefined;
  end: T | undefined;
};

export function getCustomerTierTrialDatesAsTimestamp(
  customer: CustomerModel | Customer | null | undefined,
  tierType: TierPackageType
): TierTrialDates<FirestoreTimestamp> {
  if (tierType === TierPackageTypes.NAVIGATOR) {
    return {
      start: customer?.tiers?.navigator?.trialStartDate as FirestoreTimestamp,
      end: customer?.tiers?.navigator?.trialEndDate as FirestoreTimestamp,
    };
  } else if (tierType === TierPackageTypes.SOLVE) {
    return {
      start: customer?.tiers?.solve?.trialStartDate as FirestoreTimestamp,
      end: customer?.tiers?.solve?.trialEndDate as FirestoreTimestamp,
    };
  }

  return { start: undefined, end: undefined };
}

const convertToDateTime = (date?: FirestoreTimestamp | undefined | null) =>
  date ? DateTime.fromJSDate(date.toDate()) : undefined;

export function getCustomerTierTrialDates(
  customer: CustomerModel | Customer | null | undefined,
  tierType: TierPackageType
): TierTrialDates<DateTime> {
  const { start, end } = getCustomerTierTrialDatesAsTimestamp(customer, tierType);
  return {
    start: convertToDateTime(start),
    end: convertToDateTime(end),
  };
}

export function getTierTrialDates(dates: TierTrialDates<FirestoreTimestamp> | undefined): TierTrialDates<DateTime> {
  return {
    start: convertToDateTime(dates?.start),
    end: convertToDateTime(dates?.end),
  };
}

export function isTierTrialUser(
  customer: CustomerModel | Customer | null | undefined,
  tierType: TierPackageType
): boolean {
  if (!customer) {
    return false;
  }

  const { start, end } = getCustomerTierTrialDates(customer, tierType);
  if (!start || !end) {
    return false;
  }

  return Interval.fromDateTimes(start, end).contains(DateTime.now());
}
