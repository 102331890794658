import { useEffect } from "react";

import { TierPackageTypes } from "@doitintl/cmp-models";

import { useTier } from "../Context/TierProvider";
import mixpanel from "./mixpanel";

export const MixpanelTiers = () => {
  const { getCustomerTier } = useTier();
  const navigatorTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const solveTier = getCustomerTier(TierPackageTypes.SOLVE);

  useEffect(() => {
    const tiers = {
      "Navigator tier": navigatorTier?.displayName,
      "Solve tier": solveTier?.displayName,
    };
    mixpanel.people.set(tiers);
  }, [navigatorTier, solveTier]);

  return null;
};
