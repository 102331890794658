import { useCallback } from "react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";

export function useQuickLinkPathnameInterpolationFn() {
  const { customer } = useCustomerContext({ allowNull: true });
  const { userModel } = useUserContext();

  return useCallback(
    (target: string) =>
      target.replaceAll(/(:[^:]+:)/g, (token) => {
        const variableName = token.replaceAll(":", "");

        switch (variableName) {
          case "customerId":
            return customer?.id ?? "";
          case "userId":
            return userModel?.id ?? "";
          default:
            return token;
        }
      }),
    [customer?.id, userModel?.id]
  );
}
