import {
  type DocumentData,
  type FirestoreError,
  type SnapshotListenOptions,
  type SnapshotOptions,
} from "firebase/firestore";

import {
  type FirebaseDocumentSnapshotModel,
  type FirebaseModelReference,
  type FirebaseQueryDocumentSnapshotModel,
  type FirebaseQueryModel,
  type FirebaseQuerySnapshotModel,
  type GetSnapshotOptions,
} from "../../../core";
import { type UseFirestoreHookOptions } from "../../../react-query";
import { type RefHook, useComparatorRef } from "../../util";
import { type TransformMethod, type TypeOrUndefined } from "../types";

export const snapshotToData = <T extends DocumentData, TTransformModel>(
  snapshot: FirebaseDocumentSnapshotModel<T> | FirebaseQueryDocumentSnapshotModel<T>,
  idField: string | undefined,
  refField: string | undefined,
  transform?: TransformMethod<T, TTransformModel>,
  snapshotOptions?: SnapshotOptions
): TTransformModel | null | undefined => {
  const data = snapshot.asModelData(snapshotOptions);

  if (!data) {
    return;
  }

  if (transform) {
    return transform(data, snapshot) as TTransformModel | null | undefined;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    ...data,
    ...(idField ? { [idField]: snapshot.id } : {}),
    ...(refField ? { [refField]: snapshot.ref } : {}),
  } as TTransformModel | null | undefined;
};

interface HasIsEqual {
  isEqual(other: HasIsEqual): boolean;
}

export const isDocRefOrQueryEqual = <T extends HasIsEqual>(v1: TypeOrUndefined<T>, v2: TypeOrUndefined<T>): boolean => {
  const bothNull = !v1 && !v2;

  if (bothNull) {
    return true;
  }

  return !!v1 && !!v2 && v1.isEqual(v2);
};

export const useIsFirestoreRefEqual = <T extends FirebaseModelReference<any>>(
  value: TypeOrUndefined<T>,
  onChange?: (value: TypeOrUndefined<T>) => void
): RefHook<TypeOrUndefined<T>> => useComparatorRef(value, isDocRefOrQueryEqual, onChange);

export const useIsFirestoreQueryEqual = <T extends FirebaseQueryModel<any>>(
  value: TypeOrUndefined<T>,
  onChange?: (value: TypeOrUndefined<T>) => void
): RefHook<TypeOrUndefined<T>> => useComparatorRef(value, isDocRefOrQueryEqual, onChange);

type HandleRefListenBase<TRef, TSnapshot> = {
  ref: TRef | undefined;
  setValue: (snapshot: TSnapshot | undefined) => void;
  setError: (error: FirestoreError) => void;
};

export function handleRefListen<TRef, TSnapshot>(
  { ref, setValue, setError }: HandleRefListenBase<TRef, TSnapshot>,
  getSnapshot: (ref: TRef, options?: UseFirestoreHookOptions) => Promise<TSnapshot>,
  onSnapshotWithOptions: (
    ref: TRef,
    options: UseFirestoreHookOptions,
    setValue: (snapshot: TSnapshot) => void,
    setError: (error: FirestoreError) => void
  ) => () => void,
  options?: UseFirestoreHookOptions
) {
  return () => {
    if (!ref) {
      return;
    }

    if (options?.subscribe) {
      const listener = onSnapshotWithOptions(ref, options, setValue, setError);
      return () => {
        listener();
      };
    } else {
      getSnapshot(ref, options)
        .then((snapshot) => {
          setValue(snapshot);
        })
        .catch((err: unknown) => {
          setError(err as FirestoreError);
        });
    }
  };
}

// Specific implementations for FirebaseModelReference
export function handleRefListenDoc<TModel extends DocumentData>(
  params: HandleRefListenBase<FirebaseModelReference<TModel>, FirebaseDocumentSnapshotModel<TModel>>,
  options?: UseFirestoreHookOptions
) {
  return handleRefListen(
    params,
    (ref, options) => ref.get(options as GetSnapshotOptions),
    (ref, options, setValue, setError) =>
      ref.onSnapshotWithOptions(options as SnapshotListenOptions, setValue, setError),
    options
  );
}

// Specific implementations for FirebaseQueryModel
export function handleRefListenQuery<TModel extends DocumentData>(
  params: HandleRefListenBase<FirebaseQueryModel<TModel>, FirebaseQuerySnapshotModel<TModel>>,
  options?: UseFirestoreHookOptions
) {
  return handleRefListen(
    params,
    (ref, options) => ref.get(options as GetSnapshotOptions),
    (ref, options, setValue, setError) =>
      ref.onSnapshotWithOptions(options as SnapshotListenOptions, setValue, setError),
    options
  );
}
