import * as FullStory from "@fullstory/browser";

import { isFullstoryEnabled } from "../constants";

export function fullStoryInit() {
  if (!isFullstoryEnabled) {
    return;
  }

  FullStory.init({ orgId: "HDA50" });
}
