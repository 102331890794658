import { collection, doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type SlackChannel } from "./CloudAnalytics";
import { type CustomerModel } from "./Customer";
import { type UserModel } from "./User";

export type ObjectValues<T> = T[keyof T];

export const ActionTypes = {
  TRIGGER: "trigger",
  ACTION: "action",
} as const;

export type ActionType = ObjectValues<typeof ActionTypes>;

export const ActionParamTypes = {
  SELECT: "select",
  NUMBER: "number",
  TEXT: "text",
  DATE: "date",
} as const;

export type ActionParamType = ObjectValues<typeof ActionParamTypes>;

export type ActionSimpleValueType = string | number | boolean | Timestamp;

export type ActionValueType =
  | ActionSimpleValueType
  | Record<string, ActionSimpleValueType>
  | Record<string, ActionSimpleValueType>[];

export type ActionParameters = {
  key: string;
  type: ActionParamType;
  options?: unknown[];
  default?: ActionValueType;
  label: string;
  required?: boolean;
};

export type NodeDisplayPosition = {
  x: number;
  y: number;
};

export type NodeDisplayAttributes = {
  position: NodeDisplayPosition;
};

export const NODE_STATUS = {
  VALIDATED: "validated",
  PENDING: "pending",
  ERROR: "error",
  NEW: "new",
} as const;

export type NodeStatus = ObjectValues<typeof NODE_STATUS>;

export type NodeTransition = {
  value?: unknown;
  targetNodeId: string;
  label?: string;
};

export type NodeTransitionList = NodeTransition[] | null;

export const CLOUD_FLOW_CREATION_STATUS = {
  DRAFT: "draft",
  PENDING: "pending",
  PUBLISHED: "published",
  INACTIVE: "inactive",
  NEW: "new",
} as const;

export type CloudflowCreationStatus = ObjectValues<typeof CLOUD_FLOW_CREATION_STATUS>;

export const CLOUD_FLOW_EXECUTION_STATUS = {
  PENDING: "pending",
  RUNNING: "running",
  COMPLETED: "completed",
  AWAITING_APPROVAL: "awaiting-approval",
  ERROR: "error",
  REJECTED: "rejected",
} as const;

export type CloudflowExecutionStatus = ObjectValues<typeof CLOUD_FLOW_EXECUTION_STATUS>;

export type FlowCollaborator = {
  email: string;
  role: "owner" | "editor" | "viewer";
};

export type CloudflowType = "preset" | "custom";

export type FlowCollaborators = FlowCollaborator[];

export const APP_KEY = {
  AWS: "aws",
  GCP: "gcp",
  DOIT: "doit",
  INTERNAL: "internal",
} as const;

export type AppKey = ObjectValues<typeof APP_KEY>;

/**
 * @deprecated
 */
export const CLOUD_FLOW_CONDITION = {
  CONTAINS: "contains",
  NOT_CONTAINS: "!contains",
  EQUALS: "==",
  NOT_EQUALS: "!=",
  GREATER_THAN: ">",
  LESS_THAN: "<",
  GREATER_THAN_OR_EQUAL: ">=",
  LESS_THAN_OR_EQUAL: "<=",
  IS_TRUE: "true",
  IS_FALSE: "false",
  BEFORE: "before",
  AFTER: "after",
} as const;

/**
 * @deprecated
 */
// eslint-disable-next-line deprecation/deprecation
export type CloudflowCondition = ObjectValues<typeof CLOUD_FLOW_CONDITION>;

/**
 * @deprecated
 */
export const LOGICAL_OPERATORS = {
  AND: "AND",
  OR: "OR",
} as const;

/**
 * @deprecated
 */
// eslint-disable-next-line deprecation/deprecation
export type LogicalOperators = ObjectValues<typeof LOGICAL_OPERATORS>;

/**
 * @deprecated
 */
export type ConditionParameter = {
  field: string;
  // eslint-disable-next-line deprecation/deprecation
  operator: CloudflowCondition;
  value: unknown;
  // eslint-disable-next-line deprecation/deprecation
  logicalOperator?: LogicalOperators;
};

export enum ComparisonOperator {
  CONTAINS = "contains",
  NOT_CONTAINS = "!contains",
  EQUALS = "==",
  NOT_EQUALS = "!=",
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL = ">=",
  LESS_THAN_OR_EQUAL = "<=",
  IN = "in",
  NOT_IN = "!in",
}

export enum NotificationProviders {
  SLACK = "slack",
  EMAIL = "email",
}

export type ReferencedNodeValue = {
  referencedNodeId: string;
  referencedField: string[];
};

export enum ConditionExpressionType {
  STATIC = "STATIC",
  REFERENCED = "REFERENCED",
}

type ConditionExpressionBase = {
  type: ConditionExpressionType;
  field: string[];
  comparisonOperator: ComparisonOperator;
};

export type ConditionStaticValueExpression = ConditionExpressionBase & {
  type: ConditionExpressionType.STATIC;
  value: unknown;
};

export type ConditionReferencedValueExpression = ConditionExpressionBase & {
  type: ConditionExpressionType.REFERENCED;
  value: ReferencedNodeValue;
};

export function isConditionStaticValueExpression(
  condition: ConditionExpression
): condition is ConditionStaticValueExpression {
  return condition.type === ConditionExpressionType.STATIC;
}

export function isConditionReferencedValueExpression(
  condition: ConditionExpression
): condition is ConditionReferencedValueExpression {
  return condition.type === ConditionExpressionType.REFERENCED;
}

export type ConditionExpression = ConditionStaticValueExpression | ConditionReferencedValueExpression;

@subCollection("actionEntities")
export class ActionEntityModel {
  name!: string;

  key!: string;

  description!: string;

  type!: ActionType;

  appKey!: AppKey;

  parameters!: ActionParameters[];

  createdAt!: Timestamp;

  updatedAt!: Timestamp;
}

export enum CloudFlowProvider {
  AWS = "AWS",
  GCP = "GCP",
  DoiT = "DoiT",
}

export type OperationPointer = { provider: CloudFlowProvider; service: string; version: string; id: string };

export type NodeConfigApiParametersBase = {
  provider: CloudFlowProvider;
  operation: OperationPointer;
  formValues: object;
  configurationValues: Record<string | number, unknown>;
};

export type AWSNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.AWS;
  configurationValues: { accountId: string; regions?: string[] };
};

export type GCPNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.GCP;
  configurationValues: { organization: string; serviceAccount: string };
};

export type DoiTNodeConfigApiParameters = NodeConfigApiParametersBase & {
  provider: CloudFlowProvider.DoiT;
  configurationValues: { customerId: string };
};

export type NodeConfigApiParameters =
  | AWSNodeConfigApiParameters
  | GCPNodeConfigApiParameters
  | DoiTNodeConfigApiParameters;

export type ConditionExpressionsGroup = {
  conditions: ConditionExpression[];
};

export type NodeConfigConditionalParameters = {
  referencedNodeId: string;
  referencedField: string[];
  conditionGroups: ConditionExpressionsGroup[];
};

export enum Frequencies {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Custom = "Custom",
}

export enum CustomFrequencies {
  Day = "Day",
  Week = "Week",
  Month = "Month",
}

export type NodeConfigScheduleTriggerParameters = {
  timeZone: string;
  startDate: Timestamp;
  time: Timestamp;
  frequency: Frequencies;
  customFrequency: CustomFrequencies;
  customFrequencyAmount: number;
};

export enum NodeTransformationType {
  CONCATENATION = "concatenation",
  LEFT_JOIN = "left-join",
  FIRST_ITEM = "first-item",
  LAST_ITEM = "last-item",
}

export type NodeTransformationConcatenation = {
  type: NodeTransformationType.CONCATENATION;
  newFieldName: string;
  payload: (string | ReferencedNodeValue)[];
};

export type NodeTransformationFirstItem = {
  type: NodeTransformationType.FIRST_ITEM;
  newFieldName: string;
  payload: string[];
};

export type NodeTransformationLastItem = {
  type: NodeTransformationType.LAST_ITEM;
  newFieldName: string;
  payload: string[];
};

export type NodeTransformationLeftJoin = {
  type: NodeTransformationType.LEFT_JOIN;
  newFieldName: string;
  dataToJoin: ReferencedNodeValue;
  primaryKey: ReferencedNodeValue;
  foreignKey: ReferencedNodeValue;
};

export type NodeTransformation =
  | NodeTransformationConcatenation
  | NodeTransformationLeftJoin
  | NodeTransformationFirstItem
  | NodeTransformationLastItem;

export type NodeConfigTransformParameters = {
  referencedNodeId: string;
  referencedField: string[];
  transformations: NodeTransformation[];
};

export enum CloudFlowNodeType {
  START_STEP = "startStep",
  GHOST = "ghost",
  TRIGGER = "triggerNode",
  ACTION = "actionNode",
  CONDITION = "conditionNode",
  FILTER = "filterNode",
  MANUAL_TRIGGER = "manualTrigger",
  LOOP = "loop",
  TRANSFORMATION = "transformation",
}

export type NodeParameters<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> =
  TNodeType extends CloudFlowNodeType.ACTION
    ? NodeConfigApiParameters
    : TNodeType extends CloudFlowNodeType.CONDITION | CloudFlowNodeType.FILTER
      ? NodeConfigConditionalParameters
      : TNodeType extends CloudFlowNodeType.TRIGGER
        ? NodeConfigScheduleTriggerParameters
        : TNodeType extends CloudFlowNodeType.TRANSFORMATION
          ? NodeConfigTransformParameters
          : never;

export function isNodeConfigApiParameters(
  params: NodeConfigScheduleTriggerParameters | NodeConfigApiParameters | NodeConfigConditionalParameters
): params is NodeConfigApiParameters {
  return "operation" in params && "provider" in params;
}

export enum TimeUnits {
  Hours = "Hours",
  Days = "Days",
  Weeks = "Weeks",
  Months = "Months",
}

export type SlackRecipient = {
  notificationProvider: NotificationProviders.SLACK;
  slackChannels: SlackChannel[];
};

export type EmailRecipient = {
  notificationProvider: NotificationProviders.EMAIL;
  emails: string[];
};

export type Recipient = SlackRecipient | EmailRecipient;

export type ApprovalConfig = {
  required: boolean;
  recipient: Recipient;
  message: string;
  rejectApprovalAfterTime: boolean;
  rejectTimeValue?: number;
  rejectTimeUnit?: TimeUnits;
};
export interface INodeModel<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> {
  name: string;
  display: NodeDisplayAttributes;
  type: TNodeType;
  status: NodeStatus;
  parameters?: NodeParameters<TNodeType>;
  approval?: ApprovalConfig;
  transitions: NodeTransitionList;
  appKey: AppKey;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  createdBy?: Reference<UserModel>;
  statusMessage?: string;
}

@subCollection("nodes")
export class NodeModel<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> implements INodeModel {
  name!: string;

  display!: NodeDisplayAttributes;

  type!: TNodeType;

  status!: NodeStatus;

  approval?: ApprovalConfig;

  parameters!: NodeParameters<TNodeType>;

  statusMessage?: string;

  transitions!: NodeTransitionList;

  appKey!: AppKey;

  createdAt!: Timestamp;

  updatedAt!: Timestamp;

  createdBy?: Reference<UserModel>;
}

@subCollection("cloudflowEntities")
export class CloudflowEntityModel {
  name!: string;

  description!: string;

  customer?: Reference<CustomerModel>;

  status!: CloudflowCreationStatus;

  createdAt!: Timestamp;

  createdBy!: Reference<UserModel>;

  updatedAt?: Timestamp;

  collaborators!: FlowCollaborators;

  firstNode!: Reference<NodeModel>;

  schedule?: {
    scheduled: boolean;
    nextRun: Timestamp;
    cron: string;
  };

  subCollections?: {
    nodes: NodeModel;
  };

  type!: CloudflowType;

  tags?: Record<string, string[]>;
}

@subCollection("cloudflowExecutions")
export class CloudflowExecutionModel {
  cloudflowId!: string;

  customer!: Reference<CustomerModel>;

  status!: CloudflowExecutionStatus;

  triggerTime!: Timestamp;

  triggeredBy!: string; // "scheduler" | user email

  startTime?: Timestamp;

  endTime?: Timestamp;

  error?: string;

  failedNode?: Reference<NodeModel>;

  lastExecutedNode?: Reference<NodeModel>;

  runAttempts!: number;
}

@doc("actions")
export class ActionDoc {
  subCollections?: {
    actionEntities: ActionEntityModel;
  };
}

@doc("cloudflows")
export class CloudflowDoc {
  subCollections?: {
    cloudflowEntities: CloudflowEntityModel;
    cloudflowExecutions: CloudflowExecutionModel;
  };
}

@collection("cloudflowEngine")
export class CloudflowEngineModel {
  docs?: {
    actions: ActionDoc;
    cloudflows: CloudflowDoc;
  };
}
