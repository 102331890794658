import { createContext } from "react";

import { type AwsAccount, createAwsAccount, submitCaptchaSolution } from "./aws-account-api";

export type AwsAccountContext = {
  awsAccounts: AwsAccount[];
  createAwsAccount: typeof createAwsAccount;
  submitCaptchaSolution: typeof submitCaptchaSolution;
};

export const INITIAL_AWS_ACCOUNT_CONTEXT: AwsAccountContext = {
  awsAccounts: [],
  createAwsAccount,
  submitCaptchaSolution,
};

export const AwsAccountContext = createContext(INITIAL_AWS_ACCOUNT_CONTEXT);
