import { type OverridableComponent } from "@mui/material/OverridableComponent";
import { type SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";

export enum TrendType {
  Up = "up",
  Down = "down",
}

export type TrendConfig = {
  label: string;
  value: TrendType;
  sign: string;
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  getColor: (inverted: boolean) => string;
};
