import { useEffect, useState } from "react";

import { consoleErrorWithSentry } from "../../../../utils";
import { type accountAndCustomerType } from "../types";
import { useAccountData, useAwsCustomersData } from "./db";

export const useMarketplaceAccounts = () => {
  const [accountsAndCustomers, setAccountsAndCustomers] = useState<accountAndCustomerType[]>([]);
  const [loading, setLoading] = useState(true);

  // getting all accounts
  const [accountsData, accountsDataLoading, error] = useAccountData();
  const [customersData, customersDataLoading, customersError] = useAwsCustomersData();

  useEffect(() => {
    if (!accountsData || accountsDataLoading || !customersData || customersDataLoading || !loading) {
      return;
    }

    const data = accountsData.map((el) => ({
      ...el,
      customer: customersData.find((customer) => customer.id === el.account.customer.id),
    }));

    setAccountsAndCustomers(data);
    setLoading(false);
  }, [accountsData, accountsDataLoading, customersData, customersDataLoading, loading]);

  useEffect(() => {
    if (error || customersError) {
      consoleErrorWithSentry(error || customersError);
      setLoading(false);
    }
  }, [customersError, error]);

  return { accountsAndCustomers, loading };
};
