import { useEffect, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Typography } from "@mui/material";

import { useDebounce } from "../../../utils/useDebounce";
import BlackDot from "./BlackDot";
import GreenCheck from "./GreenCheck";
import { caseIQTrpc, stripHtmlTags } from "./utils";

const desktopSize = 1200;
const minWords = 10;

type Props = {
  description: string;
  subject: string;
  product: string;
  platform: string;
  setMinHeight: (height: number) => boolean;
};

export default function CaseIQ({ description, platform, product, subject, setMinHeight }: Props) {
  const { t } = useTranslation("services");
  const ref = useRef<HTMLElement>(null);
  const [state, setState] = useState<Array<{ name: string; value: string | null }>>([]);

  const payload = useMemo(
    () => ({
      description: stripHtmlTags(description),
      subject,
      product,
      platform,
    }),
    [description, platform, product, subject]
  );

  const debouncedValue = useDebounce(payload, 2300);

  const hasEnoughWords = debouncedValue.description.split(" ").length >= minWords;

  const { data } = caseIQTrpc.detectTechnicalDetails.useQuery(debouncedValue, {
    enabled: hasEnoughWords,
    retry: false,
  });

  useEffect(() => {
    if (data?.detectedDetails.length) {
      setState(data.detectedDetails);
    }
  }, [data?.detectedDetails]);

  useEffect(() => {
    if (ref.current && window.innerWidth >= desktopSize) {
      setMinHeight(ref.current.offsetHeight);
    } else {
      setMinHeight(0);
    }
  }, [setMinHeight, state]);

  if (state.length === 0 || !hasEnoughWords) {
    return null;
  }

  return (
    <Box
      ref={ref}
      data-cy="case-iq"
      sx={{
        backgroundColor: "general.backgroundDark",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        borderRadius: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        paddingTop: 1,
        paddingBottom: 1,
        [`@media (min-width: ${desktopSize}px)`]: {
          position: "absolute",
          width: 245,
          top: 0,
          right: -272,
          minHeight: 265,
        },
        [`@media (max-width: ${desktopSize - 1}px)`]: {
          position: "relative",
          width: "100%",
          marginTop: 1,
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <AutoAwesomeIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
          <Typography fontWeight="500" fontSize={14}>
            {t("CaseIQ.description")}
          </Typography>
        </Box>

        <Box sx={{ mt: 1, display: "flex", gap: 1, flexDirection: "column" }}>
          {state.map((line) => {
            if (line.value) {
              return <GreenCheck content={line.name} key={line.name} />;
            }

            return <BlackDot content={line.name} key={line.name} />;
          })}
        </Box>
      </Box>

      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          marginTop: 2,
        }}
      >
        Powered by AI
      </Typography>
    </Box>
  );
}
