import { useEffect, useMemo, useState } from "react";

import { type DocumentData, type FirestoreError, type SnapshotListenOptions } from "firebase/firestore";

import { type FirebaseDocumentSnapshotModel, type FirebaseModelReference, type GetSnapshotOptions } from "../../core";
import { useFirestoreDocument, type UseFirestoreHookOptions } from "../../react-query";
import { useLoadingValue } from "../util";
import { useIsFirestoreDocRefWithKeysEqual } from "./compares";
import { handleRefListenDoc, snapshotToData, useIsFirestoreRefEqual } from "./helpers";
import {
  type CachingEnabledOptions,
  type CachingOptions,
  type DataOptions,
  type DocumentDataHook,
  type DocumentHook,
  type IDOptionsWithTransform,
  type OnceDataOptions,
  type TReturnData,
  type TypeFromTransform,
  type TypeOrUndefined,
} from "./types";
import { shouldEnabledCaching } from "./utils";

export const useDocument = <T extends DocumentData>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: SnapshotListenOptions
): DocumentHook<T> => useDocumentInternal<T>(docRef, { subscribe: true, ...options });

export const useDocumentOnce = <T extends DocumentData>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: GetSnapshotOptions
): DocumentHook<T> => useDocumentInternal<T>(docRef, options);

export const useDocumentData = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: DataOptions<T, IDField, RefField, TTransformModel>
): DocumentDataHook<TReturnData<T, IDField, RefField, TTransformModel>> =>
  useDocumentDataInternal<T, IDField, RefField, TTransformModel>(docRef, { subscribe: true, ...options });

export const useDocumentDataOnce = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: OnceDataOptions<T, IDField, RefField, TTransformModel>
) => useDocumentDataInternal<T, IDField, RefField, TTransformModel>(docRef, options ?? {});

export const useDocumentReactQuery = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: UseFirestoreHookOptions &
    IDOptionsWithTransform<T, IDField, RefField, TTransformModel> &
    CachingEnabledOptions
): DocumentHook<T> => {
  const [docState, setDocState] = useState(docRef);
  const [cachingState, setCachingState] = useState(options?.cachingKeys);
  useIsFirestoreDocRefWithKeysEqual(docRef ? { docRef, cachingKeys: options?.cachingKeys } : undefined, (value) => {
    setDocState(value?.docRef);
    setCachingState(value?.cachingKeys);
  });

  const queryKeys = docState
    ? [...(cachingState ?? []), "doc", options?.subscribe ? "listen" : "data", docState.path]
    : undefined;

  const docDataResult = useFirestoreDocument(queryKeys, docState, options);

  return useMemo(
    () => [docDataResult.data, docDataResult.isLoading, docDataResult.error ?? undefined] as DocumentHook<T>,
    [docDataResult.data, docDataResult.isLoading, docDataResult.error]
  );
};

const useDocumentDataDirect = <T extends DocumentData>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: UseFirestoreHookOptions
): DocumentHook<T> => {
  const { error, loading, reset, setError, setValue, value } = useLoadingValue<
    FirebaseDocumentSnapshotModel<T>,
    FirestoreError
  >();
  const ref = useIsFirestoreRefEqual<FirebaseModelReference<T>>(docRef, reset);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleRefListenDoc({ ref: ref.current, setValue, setError }, options), [ref.current]);

  useEffect(() => {}, [value, loading, error]);

  return useMemo(
    () => [value as FirebaseDocumentSnapshotModel<T>, loading, error] as DocumentHook<T>,
    [value, loading, error]
  );
};

const useDocumentInternal = <T extends DocumentData>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options?: UseFirestoreHookOptions & CachingOptions
): DocumentHook<T> =>
  shouldEnabledCaching(options)
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useDocumentReactQuery(docRef, options)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useDocumentDataDirect(docRef, options);

const useDocumentDataInternal = <
  T extends DocumentData,
  IDField extends TypeFromTransform<TTransformModel>,
  RefField extends TypeFromTransform<TTransformModel>,
  TTransformModel = undefined,
>(
  docRef: TypeOrUndefined<FirebaseModelReference<T>>,
  options: UseFirestoreHookOptions & IDOptionsWithTransform<T, IDField, RefField, TTransformModel>
): DocumentDataHook<TReturnData<T, IDField, RefField, TTransformModel>> => {
  const { idField, refField, snapshotOptions, transform, ...restOptions } = options;

  const [snapshot, loading, error] = useDocumentInternal<T>(docRef, restOptions);

  const [values, setValues] = useState<TReturnData<T, IDField, RefField, TTransformModel>[] | null | undefined>();
  const isLoading = useMemo(() => loading || (values === undefined && !error), [loading, values, error]);

  const { serverTimestamps } = snapshotOptions ?? {};

  useEffect(() => {
    if (!snapshot) {
      setValues(undefined);
      return;
    }

    const v = snapshotToData(snapshot, idField, refField, transform, { serverTimestamps });

    if (!v) {
      setValues(null);
      return;
    }

    if (v instanceof Promise) {
      void Promise.all([v]).then((results) => {
        setValues(results[0] as TReturnData<T, IDField, RefField, TTransformModel>[]);
      });
    } else {
      setValues(v as TReturnData<T, IDField, RefField, TTransformModel>[]);
    }
  }, [snapshot, serverTimestamps, transform, idField, refField]);

  return useMemo(
    () =>
      [values ?? undefined, isLoading, error] as DocumentDataHook<TReturnData<T, IDField, RefField, TTransformModel>>,
    [values, isLoading, error]
  );
};
