import { type ReactNode } from "react";

import { PaymentMethodType } from "@doitintl/cmp-models";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Avatar, Box, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import Amex from "../../../../../assets/brands/amex.png";
import BACS from "../../../../../assets/brands/bacs.jpg";
import Diners from "../../../../../assets/brands/diners.png";
import MasterCard from "../../../../../assets/brands/mastercard.png";
import SEPA from "../../../../../assets/brands/sepa.jpg";
import Visa from "../../../../../assets/brands/visa.png";
import { PaymentMethodDisabledReason, type SavedPaymentMethod } from "../../api";

const Img = (props: { alt: string; src: string }) => (
  <Box component="img" {...props} sx={{ maxWidth: 80, maxHeight: 50 }} />
);

const iconStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "purple",
  borderRadius: "5%",
  width: 80,
  height: 50,
  color: "white",
};

export const Icon = ({ text }) => (
  <Box style={iconStyle}>
    <Typography variant="h4">{text}</Typography>
  </Box>
);

const brandToImg: Record<SavedPaymentMethod["brand"], string | null> = {
  amex: Amex,
  diners: Diners,
  discover: null,
  jcb: null,
  mastercard: MasterCard,
  unionpay: null,
  unknown: null,
  visa: Visa,
};

const displayDisabledReason = (disabledReason: PaymentMethodDisabledReason): string | null => {
  switch (disabledReason) {
    case PaymentMethodDisabledReason.EXPIRED:
      return "Expired";
    case PaymentMethodDisabledReason.MANDATE_INACTIVE:
      return "Mandate cancelled";
    case PaymentMethodDisabledReason.PROCCESSING:
      return "Pending validation";
    case PaymentMethodDisabledReason.REQUIRES_ACTION:
      return "Verification required";
  }
};

export const getPaymentMethodDescriptor = ({
  bankName,
  brand,
  last4,
  name,
  type,
  disabledReason,
}: SavedPaymentMethod): {
  icon: ReactNode;
  primary: string;
  secondary: string;
} => {
  let icon: ReactNode;
  let primary: string;
  const secondary = last4 ? `ending in ${last4}` : name || bankName || brand;
  switch (type) {
    case PaymentMethodType.CreditCard: {
      const imgSrc = brandToImg[brand];
      icon = imgSrc ? <Img alt={brand} src={imgSrc} /> : <CreditCardIcon />;
      primary = "Credit card";
      break;
    }
    case PaymentMethodType.SepaDebit: {
      icon = <Img alt="SEPA" src={SEPA} />;
      primary = "SEPA Direct Debit";
      break;
    }
    case PaymentMethodType.BacsDebit: {
      icon = <Img alt="Bacs" src={BACS} />;
      primary = "Bacs Direct Debit";
      break;
    }
    case PaymentMethodType.AcssDebit: {
      icon = <Icon text="ACSS" />;
      primary = "ACSS";
      break;
    }
    case PaymentMethodType.USBankAccount: {
      icon = <Icon text="ACH" />;
      primary = "ACH";
      break;
    }
    case PaymentMethodType.BankAccount:
      icon = <AccountBalanceIcon />;
      primary = "Bank account";
      break;
    default:
      icon = <Avatar>{type[0]?.toLocaleUpperCase()}</Avatar>;
      primary = type.split("_").map(capitalize).join(" ");
  }

  if (disabledReason) {
    primary = `${primary} (${displayDisabledReason(disabledReason)})`;
  }

  return { icon, primary, secondary };
};
