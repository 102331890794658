export const bQEmptyStateDashboardTexts = {
  HEADING: "Identify & optimize BigQuery \n inefficiencies with the BQ Lens",
  TESTIMONIAL:
    '"Thanks to the BigQuery Lens\' recommendations, we invested time in partitioning our key tables and were able to reduce our BigQuery expenses by 25% a month."',
  QUOTEE: "Daniel Rimon, Head of Data Engineering, Resident",
  CHECKMARKS: [
    "Fine-tune query performance with actionable recommendations, e.g.",
    "Understand your team's BigQuery usage in three clicks or less.",
    "Ensure that your data is organized in the most cost-efficient way.",
  ],
  CHECKMARKSEG: ["Cluster these tables", "switch to flat-rate plan"],
  ENABLE_BQ_BUTTON: "Enable BigQuery Lens",
  FOOTER_INFO_1: "Get started by updating your BigQuery Lens permissions on the settings page.",
  LINK_TXT: "Learn more",
  FOOTER_INFO_2: "about BigQuery Lens.",
  LINK_PATH_TO_BQ_HELP: "https://help.doit.com/docs/dashboards/bigquery-lens#set-up-bigquery-lens",
  RECOMMENDATIONS_TITLE: "Recommendations",
  SPEND_AND_USAGE_INSIGHTS_TITLE: "Spend and usage insights",
  BREAKDOWN_INSIGHTS_TITLE: "Breakdown insights",
};
