import { Sort } from "@doitintl/cmp-models";
import SortAZIcon from "@mui/icons-material/SortByAlphaRounded";
import DescIcon from "@mui/icons-material/TrendingDownRounded";
import AscIcon from "@mui/icons-material/TrendingUpRounded";
import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";

import { tooltipText } from "../../../../assets/texts";
import { reportTxt } from "../../../../assets/texts/CloudAnalytics";

const sortToggleStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  padding: "8px 0 8px 8px",
  alignItems: "center",
};

type SortToggleGroupProps = {
  disabled: boolean;
  title: string;
  value: any;
  onChange: (event, newOrder: any) => void;
  tooltipPrefix?: string;
  showSubtotalsText?: boolean;
};
const SortToggleGroup = ({
  disabled,
  title,
  value,
  onChange,
  tooltipPrefix = tooltipText.ROW,
  showSubtotalsText,
}: SortToggleGroupProps) => {
  const titleASC = showSubtotalsText ? reportTxt.SUBTOTALS_ASC : tooltipPrefix + tooltipText.ASC_SORT;
  const titleDESC = showSubtotalsText ? reportTxt.SUBTOTALS_DESC : tooltipPrefix + tooltipText.DES_SORT;
  return (
    <Box sx={sortToggleStyle}>
      <Typography variant="body2">{title}</Typography>
      <ToggleButtonGroup
        size="small"
        exclusive
        value={value}
        onChange={onChange}
        sx={{
          margin: 0.5,
          ".MuiToggleButton-root": {
            padding: 0.25,
          },
        }}
      >
        <ToggleButton value={Sort.A_TO_Z} disabled={disabled}>
          <Tooltip title={tooltipText.ALPHABETICAL_SORT}>
            <SortAZIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={Sort.ASC} disabled={disabled}>
          <Tooltip title={titleASC}>
            <AscIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value={Sort.DESC} disabled={disabled}>
          <Tooltip title={titleDESC}>
            <DescIcon fontSize="small" />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default SortToggleGroup;
