import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DoitRole, PerkTypes } from "@doitintl/cmp-models";
import { Box, Button, CardHeader, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { CircularProgressLoader } from "../../Components/Loader";
import { navigationCategories } from "../../Navigation/config/navigationCategory";
import { type PerksWData } from "../../types";
import { useFullScreen } from "../../utils/dialog";
import { useInfoText, usePerks } from "./hooks";
import { PerkFilter } from "./PerkFilter/PerkFilter";
import PerksSection from "./PerksSection";

const Perks = () => {
  const history = useHistory();
  const isPerksAdmin = useDoitRoleCheck(DoitRole.PerksAdmin);
  const [filteredPerks, setFilteredPerks] = useState<PerksWData[]>([]);
  const [perksForThisPage, setPerksForThisPage] = useState<PerksWData[]>([]);
  const pathname = history.location.pathname?.replace(/\/+$/, "");
  const pageId = pathname.substring(pathname.lastIndexOf("/") + 1);
  const { t } = useTranslation("navigation");
  const catTitle = navigationCategories(t)
    .find((cat) => cat.displayName === "Services")
    ?.items.find((item) => item.pageId === pageId)?.displayName;
  const { loading, perks } = usePerks();
  const { isMobile } = useFullScreen("md");
  const { infoText: workshopInfoText } = useInfoText(PerkTypes.Workshop);
  const { infoText: trainingInfoText } = useInfoText(PerkTypes.Training);
  const { infoText: acceleratorInfoText } = useInfoText(PerkTypes.Accelerator);

  useEffect(() => {
    switch (pageId) {
      case "professional-services":
        setPerksForThisPage(
          perks.filter((perk) =>
            [PerkTypes.Accelerator, PerkTypes.Training, PerkTypes.Workshop].some(
              (perkType) => perkType === perk.data.fields.type
            )
          )
        );
        break;
      case "isv-solutions":
        setPerksForThisPage(perks.filter((perk) => perk.data.fields.type === PerkTypes.IsvSolution));
        break;
      default:
        setPerksForThisPage([]);
    }
  }, [pageId, perks]);

  const openCreatePerk = () => {
    history.push(`${history.location.pathname?.replace(/\/+$/, "")}/create`);
  };

  if (loading) {
    return <CircularProgressLoader />;
  }

  return (
    <Box py={5} px={{ lg: 0, xl: 12 }}>
      {isMobile ? (
        <Stack gap={2} pl={{ md: 0, lg: 2 }}>
          <Typography variant="h1">{catTitle}</Typography>
          {pageId === "isv-solutions" && (
            <Typography variant="subtitle1">
              Enjoy exclusive offers from other members of the DoiT community"
            </Typography>
          )}
          {isPerksAdmin && (
            <Button onClick={openCreatePerk} color="primary" variant="contained">
              Create new {pageId === "isv-solutions" ? "ISV Solution" : "professional service"}
            </Button>
          )}
        </Stack>
      ) : (
        <Grid item>
          <CardHeader
            title={catTitle}
            subheader={
              pageId === "isv-solutions" ? "Enjoy exclusive offers from other members of the DoiT community" : ""
            }
            titleTypographyProps={{ variant: "h1", paddingBottom: 1 }}
            action={
              isPerksAdmin && (
                <Button onClick={openCreatePerk} color="primary" variant="contained" sx={{ mr: 1 }}>
                  Create new {pageId === "isv-solutions" ? "ISV Solution" : "professional service"}
                </Button>
              )
            }
            sx={{ padding: "inherit" }}
          />
        </Grid>
      )}
      <br />
      <Stack direction={{ md: "column", lg: "row" }} spacing={2}>
        <PerkFilter perks={perksForThisPage} setFilteredPerks={setFilteredPerks} pageId={pageId} />
        <Stack direction="column" spacing={2} width="100%">
          {filteredPerks.length > 0 ? (
            <>
              <PerksSection perks={filteredPerks} types={[PerkTypes.IsvSolution]} />
              <PerksSection
                perks={filteredPerks}
                title="Workshops"
                subtitle={workshopInfoText ?? ""}
                types={[PerkTypes.Workshop]}
              />
              <PerksSection
                perks={filteredPerks}
                title="Trainings"
                subtitle={trainingInfoText ?? ""}
                types={[PerkTypes.Training]}
              />
              <PerksSection
                perks={filteredPerks}
                title="Accelerators"
                subtitle={acceleratorInfoText ?? ""}
                types={[PerkTypes.Accelerator]}
              />
            </>
          ) : (
            <Stack direction="column" alignItems="center" justifyContent="center" spacing={2.5} height="50vh">
              <Typography variant="h1">No results</Typography>
              <Typography textAlign="center" color="textSecondary">
                No services match the criteria, try different filters.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Perks;
