import CloseIcon from "@mui/icons-material/CloseRounded";
import { Button, IconButton } from "@mui/material";

import {
  type OnCloseSharedSnackbar,
  type OnOpenSharedSnackbar,
} from "../../Components/SharedSnackbar/SharedSnackbar.context";

type Props = {
  showSnackbar: OnOpenSharedSnackbar;
  hideSnackbar: OnCloseSharedSnackbar;
  onSendEmailVerification: () => Promise<void>;
};

export function emailNotVerifiedMessage({ showSnackbar, hideSnackbar, onSendEmailVerification }: Props) {
  showSnackbar({
    message: "Your account is not verified. Please check your inbox for a verification email.",
    variant: "warning",
    autoHideDuration: 60000,
    action: [
      <Button
        key="link"
        aria-label="Resend email"
        color="inherit"
        variant="outlined"
        onClick={async () => {
          await onSendEmailVerification();
          hideSnackbar();
        }}
      >
        Resend email
      </Button>,
      <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
        <CloseIcon />
      </IconButton>,
    ],
  });
}
