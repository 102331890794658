import { type AwsFeaturePermission } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

export enum AWSFeatureName {
  core = "core",
  spotScaling = "spot-scaling",
  quotas = "quotas",
  fsk8s = "fsk8s",
}

export enum AccountStatus {
  healthy = "Healthy",
  notConfigured = "Not configured",
  unhealthy = "Incomplete",
}

export const FeatureDetails: Record<string, FeatureDescription> = {
  [AWSFeatureName.core]: {
    displayName: "Core",
    mandatory: true,
    description:
      "The minimum set of read-only permissions we need as a foundation for many features in the DoiT Console.",
  },
  [AWSFeatureName.quotas]: {
    displayName: "Quotas",
    mandatory: false,
    description: "Monitor your AWS service limits.",
  },
  [AWSFeatureName.spotScaling]: {
    displayName: "Spot Scaling",
    mandatory: false,
    description:
      "Analyzes your Auto Scaling Groups based on cost and usage and get recommendations to replace on-demand EC2 instances with Spot instances.",
  },
  [AWSFeatureName.fsk8s]: {
    displayName: "FSK8s",
    mandatory: false,
    description: "Reduces costs and improves efficiency for EKS clusters",
  },
};

// presentation type that will be used in the table
export type AWSAccount = {
  id: string;
  accountId: string;
  roleName: string;
  featureNames: string[];
  status: AccountStatus;
  timeLinked: DateTime | null;
  error?: string;
};

// presentation type to hold extended information about feature
export type FeatureDescription = {
  displayName: string;
  description: string;
  mandatory: boolean;
};

export type Feature = FeatureDescription & AwsFeaturePermission;
