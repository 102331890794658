import { Tooltip, type TooltipProps } from "@mui/material";

/**
 * @description Wraps a component with a tooltip if a title is provided, otherwise returns the component as is.
 * useful in case you want to show a tooltip on disabled component.
 * see: https://mui.com/material-ui/react-tooltip/#disabled-elements
 */
export function ToolTipWrapper({ children: componentToWrap, title, ...tooltipProps }: TooltipProps) {
  return title ? (
    <Tooltip title={title} {...tooltipProps}>
      <span>{componentToWrap}</span>
    </Tooltip>
  ) : (
    <>{componentToWrap}</>
  );
}
