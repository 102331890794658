import { createContext, type ReactNode, useContext } from "react";

import { type RawEventsReq, useDataHubData } from "../Components/hooks/useDataHubData";
import { type DatasetItem } from "../Pages/DataHub/DataHubSources";
import { type BatchItem } from "../Pages/DataHub/DatasetDetails/DatasetDetails";
import { type Customer } from "../types";

export type DataHubContextType = {
  datahubItems: DatasetItem[];
  loading: boolean;
  fetchDataHubItems: (forceRefresh: boolean) => Promise<void>;
  deleteDataHubDatasets: (datasets: string[]) => Promise<any>;
  createNewDataset: (Name: string, Description: string) => Promise<void>;
  uploadCSV: (data: Omit<RawEventsReq, "Dataset">, datasetName: string) => Promise<any>;
  datasetBatches: BatchItem[];
  loadingDatasetBatches: boolean;
  fetchDatasetBatches: (datasetName: string, forceRefresh: boolean) => Promise<void>;
  deleteDatasetBatches: (datasetName: string, batchNames: string[]) => Promise<any>;
};

const DataHubContext = createContext<DataHubContextType | undefined>(undefined);

export const useDataHubContext = () => {
  const context = useContext(DataHubContext);
  if (!context) {
    throw new Error("useDataHubContext must be used within a DataHubProvider");
  }

  return context;
};

export const DataHubProvider = ({
  children,
  customer,
}: Readonly<{ children?: ReactNode; customer: Customer | undefined | null }>) => {
  const {
    datahubItems,
    loading,
    fetchDataHubItems,
    deleteDataHubDatasets,
    createNewDataset,
    createRawEvents,
    datasetBatches,
    loadingDatasetBatches,
    fetchDatasetBatches,
    deleteDatasetBatches,
  } = useDataHubData(customer);
  const uploadCSV = async (data: Omit<RawEventsReq, "Dataset">, datasetName: string): Promise<any> => {
    const payload: RawEventsReq = {
      Dataset: datasetName,
      ...data,
    };
    return createRawEvents(payload);
  };

  return (
    <DataHubContext.Provider
      value={{
        datahubItems,
        loading,
        fetchDataHubItems,
        deleteDataHubDatasets,
        createNewDataset,
        uploadCSV,
        datasetBatches,
        loadingDatasetBatches,
        fetchDatasetBatches,
        deleteDatasetBatches,
      }}
    >
      {children}
    </DataHubContext.Provider>
  );
};
