import { Box, Typography } from "@mui/material";

type Props = {
  title?: string;
  description?: React.ReactNode;
};

export const WizardTitle = ({ title, description }: Props) => (
  <Box sx={{ mt: 6, mb: 3 }}>
    <Typography component="div" variant="h3">
      {title}
    </Typography>
    <Typography component="div" color="textSecondary" variant="body1">
      {description}
    </Typography>
  </Box>
);
