import { type EarlyAccessFeature } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../Context/CustomerContext";
import { type ValueOf } from "../../types";

export const useFeatureFlag = (flag: ValueOf<typeof EarlyAccessFeature>): boolean => {
  const { customer } = useCustomerContext();

  if (!customer || !Array.isArray(customer.earlyAccessFeatures)) {
    return false;
  }

  return customer.earlyAccessFeatures.includes(flag);
};
