import { type RouteConfig } from "react-router-config";

import { type PageId } from "../config/pages";
import {
  type Categories,
  type CategoryContext,
  type CategoryItemContext,
  type CategorySubItemContext,
  type LoginContext,
  type NavigationCategory,
} from "../types";
import { customerRouteParamName, customerRoutePrefix } from "./consts";

const getFullRoute = (pageIdsToRouteMap: Map<PageId, RouteConfig>, pageId: PageId, customerId?: string) => {
  const routeConfig = pageIdsToRouteMap.get(pageId);
  const route = routeConfig?.path as string;

  if (!route) {
    return;
  }
  if (customerId && route.startsWith(customerRoutePrefix)) {
    return route.replace(customerRouteParamName, customerId);
  }
  return route;
};

const buildCategories = (
  navigationCategory: NavigationCategory,
  pageIdsToRouteConfig: Map<PageId, RouteConfig>,
  pageId?: PageId,
  customerId?: string
) => {
  const items: CategoryItemContext[] = [];
  let isCategorySelected = !!(navigationCategory.pageId && pageId === navigationCategory.pageId);

  for (const item of navigationCategory.items) {
    const fullRoute = getFullRoute(pageIdsToRouteConfig, item.pageId, customerId);
    if (!fullRoute) {
      continue;
    }

    const subItems: CategorySubItemContext[] = [];
    const isItemSelected = pageId && (pageId === item.pageId || item.subPages?.includes(pageId));
    const isFeatureEntitled = pageIdsToRouteConfig.get(item.pageId)?.isFeatureEntitled ?? null;

    if (item.subItems) {
      for (const subItem of item.subItems) {
        const subItemFullRoute = getFullRoute(pageIdsToRouteConfig, subItem.pageId, customerId);
        if (!subItemFullRoute) {
          continue;
        }
        subItems.push({ ...subItem, fullRoute: subItemFullRoute });
      }
    }

    items.push({
      isSelected: isItemSelected,
      displayName: item.displayName,
      pageId: item.pageId,
      icon: item.icon,
      subItems,
      fullRoute,
      isFeatureEntitled,
    });

    if (isItemSelected) {
      isCategorySelected = true;
    }
  }

  const categoryContext: CategoryContext = {
    isSecondLevel: navigationCategory.isSecondLevel,
    isSelected: isCategorySelected,
    displayName: navigationCategory.displayName,
    items,
  };

  // Add route to first level menu item if needed
  if (navigationCategory.pageId) {
    const routeConfig = pageIdsToRouteConfig.get(navigationCategory.pageId);
    const route = routeConfig?.path;

    if (typeof route === "string") {
      categoryContext.fullRoute =
        customerId && route.startsWith(customerRoutePrefix) ? route.replace(customerRouteParamName, customerId) : route;
      categoryContext.fullRoute = categoryContext.fullRoute.replace(/\/:\w+/, "");
    }
  }

  return categoryContext;
};

/**
 * Build categories and items that are available for the current user
 * @param routesConfig
 * @param navigationCategories
 * @param customerId
 * @param pageId
 */
export function buildCategoriesContext(
  routesConfig: RouteConfig[],
  navigationCategories: NavigationCategory[],
  customerId: string | undefined,
  pageId?: PageId
): CategoryContext[] {
  const pageIdsToRouteConfig = new Map<PageId, RouteConfig>();
  for (const routeConfig of routesConfig) {
    pageIdsToRouteConfig.set(routeConfig.pageId, routeConfig);
  }
  const categories = navigationCategories.map((navigationCategory: NavigationCategory) =>
    buildCategories(navigationCategory, pageIdsToRouteConfig, pageId, customerId)
  );

  // filter empty categories
  return categories.filter((categoryContext) => categoryContext.items?.length > 0 || categoryContext.fullRoute);
}

export function getCurrentCategories(
  routesConfig: RouteConfig[],
  navigationCategories: NavigationCategory[],
  loginContext: LoginContext | undefined,
  pageId?: PageId
): Categories {
  const categoriesContext = buildCategoriesContext(
    routesConfig,
    navigationCategories,
    loginContext?.customerId,
    pageId
  );

  const firstLevelCategories = categoriesContext.filter((category) => !category.isSecondLevel);
  const secondLevelCategory = categoriesContext.find((category) => category.isSecondLevel && category.isSelected);

  if (secondLevelCategory) {
    // select the category of second level in first level
    categoriesContext.forEach((category) => {
      // if category has item with the display name of the second level category, then mark it as selected
      for (const item of category.items) {
        if (item.displayName === secondLevelCategory.displayName) {
          item.isSelected = true;
          category.isSelected = true;
          break;
        }
      }
    });
  }

  return {
    firstLevel: firstLevelCategories,
    secondLevel: secondLevelCategory,
  };
}

export function getCategoryByPageId(navigationCategories: NavigationCategory[], pageId: PageId) {
  for (const category of navigationCategories) {
    const foundPageIdIndex = category.items.findIndex(
      (item) => item.pageId === pageId || item.subPages?.includes(pageId)
    );

    if (foundPageIdIndex > -1) {
      return category.displayName;
    }
  }
}
