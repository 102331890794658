import { setFirestoreSingleton } from "@doitintl/models-firestore";
import { type FirebaseApp, getApp, initializeApp } from "firebase/app";
import { getAuth, type IdTokenResult } from "firebase/auth";
import { type Firestore, type FirestoreSettings, initializeFirestore, setLogLevel } from "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const customAuthConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

setLogLevel("error");
initializeApp(config);

let firestore: Firestore;
export let customAuthApp: FirebaseApp | undefined;
if (customAuthConfig.authDomain) {
  customAuthApp = initializeApp(customAuthConfig, "customAuth");
}

export function slimInitFirestore({
  settings,
  idToken,
}: { settings?: FirestoreSettings & { useFetchStreams?: false }; idToken?: IdTokenResult } = {}) {
  if (firestore) {
    return firestore; // single time init
  }

  const enforcedLongPollingCustomersIds = ["ntYC2YHl6abEcZse51E2", "0C86ZKxe6mthbTmCbQgV", "orQOv5NZI37QP3fbqDpg"];
  let actualSettings: FirestoreSettings = { ...(settings ?? {}) };
  if (idToken?.claims?.customerId && enforcedLongPollingCustomersIds.includes(idToken.claims.customerId as string)) {
    actualSettings = {
      ...actualSettings,
      experimentalForceLongPolling: true,
      experimentalAutoDetectLongPolling: false,
    };
  }

  if (customAuthApp && !getAuth()?.currentUser) {
    firestore = initializeFirestore(customAuthApp, actualSettings);
  } else {
    firestore = initializeFirestore(getApp(), actualSettings);
  }

  let params = {};
  if (process.env.NODE_ENV === "development") {
    const listenErrorCallback = (path: string, err: Error, marker: Error) => {
      // eslint-disable-next-line no-console
      console.warn("Firestore error at", path, err, marker.stack);
    };

    params = {
      listenErrorCallback,
    };
  }

  setFirestoreSingleton(firestore, params);

  return firestore;
}
