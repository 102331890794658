import { ManagedType, Status } from "@doitintl/cmp-models";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormikContext } from "formik";

import { useNoDialStyle } from "../../../../../Navigation/themes";
import { fieldNames } from "./constants";
import { type EditFormValues } from "./types";

export default function ComputeTab() {
  const { values, setFieldValue, isSubmitting, initialValues, errors, handleBlur, handleChange, submitCount } =
    useFormikContext<EditFormValues>();
  const classes = useNoDialStyle();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" alignItems="start">
          <FormControlLabel
            control={
              <Switch
                checked={values.keepActiveEvenWhenOnCredits}
                onChange={(value) => {
                  setFieldValue(fieldNames.keepActiveEvenWhenOnCredits, value.target.checked);
                }}
              />
            }
            label="Keep active even when on credits"
            disabled={isSubmitting}
          />

          <Box m={2} />

          <Typography fontWeight={500}>Status</Typography>

          <RadioGroup
            sx={{ paddingLeft: 1 }}
            value={values.status}
            onChange={(_ignored, value) => {
              setFieldValue(fieldNames.status, value);
            }}
          >
            <FormControlLabel
              disabled={isSubmitting}
              value={Status.active}
              control={<Radio color="primary" />}
              label="Active"
            />
            <FormControlLabel
              disabled={isSubmitting}
              value={Status.pending}
              control={<Radio color="primary" />}
              label="Pending"
            />
            <FormControlLabel
              disabled={isSubmitting}
              value={Status.disabled}
              control={<Radio color="primary" />}
              label="Disabled"
            />
          </RadioGroup>

          <Box m={2} />

          <FormControl fullWidth>
            <TextField
              name={fieldNames.statusChangeReason}
              label="Reason for disabling"
              variant="outlined"
              disabled={isSubmitting || values.status !== "disabled" || values.status === initialValues.status}
              error={!!errors.statusChangeReason && submitCount > 0}
              value={values.statusChangeReason}
              onBlur={handleBlur}
              onChange={handleChange}
              multiline
              rows={4}
            />
          </FormControl>

          <Box m={2} />

          <FormControlLabel
            control={
              <Switch
                checked={values.managed !== ManagedType.manual}
                onChange={(value) => {
                  setFieldValue("managed", value.target.checked ? ManagedType.auto : ManagedType.manual);
                }}
              />
            }
            label={values.managed === ManagedType.manual ? "Manually managed" : "Automatically managed"}
            disabled={isSubmitting}
          />

          <Box m={2} />

          <FormControlLabel
            control={
              <Switch
                checked={values.seasonal === true}
                onChange={(value) => {
                  setFieldValue(fieldNames.seasonal, value.target.checked);
                }}
              />
            }
            label="Seasonal"
            disabled={isSubmitting}
          />
        </Box>

        <Box m={4} />

        <FormControl fullWidth>
          <TextField
            inputProps={{ className: classes.noDial }}
            type="number"
            name={fieldNames.targetPercentage}
            label="Target percentage"
            variant="outlined"
            disabled={isSubmitting}
            error={!!errors.targetPercentage}
            value={values.targetPercentage}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <Box m={2} />

          <TextField
            inputProps={{ className: classes.noDial }}
            type="number"
            name={fieldNames.computeDiscount}
            label="Discount override"
            variant="outlined"
            disabled={isSubmitting}
            error={!!errors.computeDiscount && submitCount > 1}
            value={values.computeDiscount}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />

          <Box m={2} />
          <DatePicker
            label="Discount effective date"
            value={values.computeEffectiveDate ?? ""}
            onChange={(e) => {
              setFieldValue(fieldNames.computeEffectiveDate, e);
            }}
            inputFormat="dd LLL, yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isSubmitting}
                value={params.value}
                name={fieldNames.computeEffectiveDate}
                error={!!errors.computeEffectiveDate && submitCount > 0}
              />
            )}
          />
          <Box m={2} />

          <TextField
            inputProps={{ className: classes.noDial }}
            type="number"
            name={fieldNames.minSpend}
            label="Minimum spend"
            variant="outlined"
            disabled={isSubmitting}
            error={!!errors.minSpend}
            value={values.minSpend}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box m={2} />

          <TextField
            inputProps={{ className: classes.noDial }}
            type="number"
            name={fieldNames.maxSpend}
            label="Maximum spend"
            variant="outlined"
            disabled={isSubmitting}
            error={!!errors.maxSpend && submitCount > 0}
            value={values.maxSpend}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
