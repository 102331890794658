import { type AxiosInstance, type AxiosResponse } from "axios";

export class SalesforceApi {
  private readonly apiPrefix: string;

  constructor(private readonly api: AxiosInstance) {
    this.apiPrefix = `/v1/salesforce`;
  }

  public async compositeRequest(
    compositeRequest: Request[],
    allOrNone: boolean
  ): Promise<AxiosResponse<CompositeResponse>> {
    return this.api.post<CompositeResponse>(`${this.apiPrefix}/composite`, {
      allOrNone,
      compositeRequest,
    });
  }
}

export const salesforceBaseUrl = "/services/data/v55.0";

export enum SalesforceRequestMethod {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  PATCH = "PATCH",
}

export type Request = {
  method: SalesforceRequestMethod;
  url: string;
  referenceId: string;
  body?: object;
};

export type CompositeResponse = {
  compositeResponse: {
    body: any;
    httpHeaders: any;
    httpStatusCode: number;
    referenceId: string;
  }[];
};
