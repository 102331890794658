import { Box, Typography } from "@mui/material";

import GCPLogo from "../../../assets/gcp-logo.svg";

export const FlexsaveGCPPricelistTitle = () => (
  <Typography variant="h1" data-cy="title">
    <Box component="img" src={GCPLogo} sx={{ height: "1.2em", width: "auto", mb: -1, mr: 1 }} />
    Flexsave GCP Pricelist
  </Typography>
);
