import { useCallback, useEffect, useMemo } from "react";

import { AssetTypeAmazonWebServices, type MasterPayerAccountsModel, ProductEnum } from "@doitintl/cmp-models";
import { Box, Button } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { type FilterTableToolbarProps } from "../../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AmazonWebServicesAssetsType, type AnyAsset } from "../../../../types";
import { type Handshake } from "../../../../types/Handshake";
import { mapEntitiesByKey } from "../../GCTab/utils";
import { awsHeadedFilterColumns, defaultFilters } from "../AwsAssetColumns";
import AwsAssetRow, { type AWSAssetRowData, transformToRowData } from "./AwsAssetRow";
import NoAssets from "./NoAssets";

type Props = {
  items: (AnyAsset<AmazonWebServicesAssetsType> | Handshake)[];
  onRemoveAsset: (asset: AnyAsset<AmazonWebServicesAssetsType>) => () => void;
  toolbar: FilterTableToolbarProps;
  mpaAccounts?: MasterPayerAccountsModel[] | null;
  tableData: AWSAssetRowData[];
  setTableData: (value: React.SetStateAction<AWSAssetRowData[]>) => void;
};

const AwsAssetTable = ({ items, onRemoveAsset, toolbar, mpaAccounts, tableData, setTableData }: Props) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { entities } = useCustomerContext();
  const { headers, columns } = awsHeadedFilterColumns(isDoitEmployee);
  const { customer } = useCustomerContext();
  const showNoAssets = useMemo<boolean>(
    () =>
      !(
        customer.assets?.includes(ProductEnum.AmazonWebServices) ??
        customer.assets?.includes(ProductEnum.AmazonWebServicesStandalone)
      ) && !items.length,
    [customer.assets, items.length]
  );

  const entitiesByKey = useMemo(() => mapEntitiesByKey(entities), [entities]);
  const customerMPAIds = useMemo(() => mpaAccounts?.map((mpa) => mpa.accountNumber) ?? [], [mpaAccounts]);

  useEffect(() => {
    setTableData(items.map((item) => transformToRowData(item, entitiesByKey, customerMPAIds)));
  }, [entitiesByKey, items, customerMPAIds, setTableData]);

  const AssetRowWrapper = useCallback(
    (props: { row: AWSAssetRowData }) => <AwsAssetRow row={props.row} onRemoveAsset={onRemoveAsset} />,
    [onRemoveAsset]
  );

  toolbar.allowToEditColumns = !showNoAssets;

  const tableContent = (
    <FilterTable<AWSAssetRowData>
      toolbarProps={toolbar}
      rowComponent={AssetRowWrapper}
      emptyTableComponent={
        <NoAssets assetType={AssetTypeAmazonWebServices}>
          <Button variant="contained" onClick={toolbar.primaryButton?.onClick}>
            New subscription
          </Button>
        </NoAssets>
      }
      headerColumns={headers}
      filterColumns={columns}
      tableItems={showNoAssets ? [] : tableData}
      defaultSortingColumnValue="assetName"
      defaultSortDirection="asc"
      filterBarPlaceholder="Filter assets"
      defaultFilters={defaultFilters}
      persistenceKey="aws_assets"
      data-cy="aws-assets-table"
      showFilterBar={!showNoAssets}
      showHeader={!showNoAssets}
    />
  );

  return <Box mb={4}>{tableContent}</Box>;
};

export default AwsAssetTable;
