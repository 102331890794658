import { type Label } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { cloudAnalyticsText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import { type DataColumns, type HeaderColumn } from "../../../types/FilterTable";
import { type LabelWithRef } from "../labels/types";
import { cloudAnalyticsResourceTypes } from "./utils";

export const buildFilterColumns = (labels: LabelWithRef[] | undefined): DataColumns<any> => [
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.ID,
    path: "id",
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.NAME,
    path: "data.name",
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.CONTENT_TYPE,
    path: "contentType",
    toOption: (value) => ({ value, label: cloudAnalyticsResourceTypes[value]?.label }),
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TYPE,
    path: "type",
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.OWNER,
    path: "owner",
  },
  {
    label: "Label",
    path: "data.labels",
    toOption: (labelRefs: ModelReference<Label>[]) => {
      if (!labelRefs) {
        return [];
      }

      return labelRefs.reduce(
        (arr, labelRef) => {
          const label = labels?.find((l) => l.ref.id === labelRef.id);
          if (label) {
            arr.push({
              value: labelRef.id,
              label: label.data.name,
            });
          }

          return arr;
        },
        [] as { value: string; label: string }[]
      );
    },
    transformData: (data: ModelReference<Label>[] | undefined) => (data ? data.map((d) => d.id) : []),
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.LAST_MODIFIED,
    path: "data.timeModified",
    comparators: ["<", "<=", ">", ">="],
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TIME_CREATED,
    path: "data.timeCreated",
    comparators: ["<", "<=", ">", ">="],
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
];

export const buildSideFilterColumns = (labels: LabelWithRef[] | undefined): DataColumns<any> => [
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.CONTENT_TYPE,
    path: "contentType",
    toOption: (value) => ({ value, label: cloudAnalyticsResourceTypes[value]?.label }),
  },
  {
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.OWNER,
    path: "owner",
  },
  {
    label: "Label",
    path: "data.labels",
    toOption: (labelRefs: ModelReference<Label>[]) => {
      if (!labelRefs) {
        return [];
      }

      return labelRefs.reduce(
        (arr, labelRef) => {
          const label = labels?.find((l) => l.ref.id === labelRef.id);
          if (label) {
            arr.push({
              value: labelRef.id,
              label: label.data.name,
            });
          }

          return arr;
        },
        [] as { value: string; label: string }[]
      );
    },
    transformData: (data: ModelReference<Label>[] | undefined) => (data ? data.map((d) => d.id) : []),
  },
];

// Name, type, owner, last modified
export const headers: HeaderColumn[] = [
  {
    value: "data.name",
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.NAME,
    tooltip: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "contentType",
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.CONTENT_TYPE,
    tooltip: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.CONTENT_TYPE,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "type",
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TYPE,
    tooltip: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TYPE,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "owner",
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.OWNER,
    tooltip: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.OWNER,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "data.timeModified",
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.LAST_MODIFIED,
    tooltip: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "data.timeCreated",
    label: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TIME_CREATED,
    tooltip: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TIME_CREATED,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "",
    label: "",
    style: { align: "left", padding: "normal" },
  },
];
