import { useMemo } from "react";

import { Redirect, useParams } from "react-router-dom";
import { EarlyAccessFeature } from "@doitintl/cmp-models";

import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { useHasCustomerMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import { Budget } from "./Budget";
import CreateBudgetStepper from "./CreateBudgetSteper/CreateBudgetStepper";

export const BudgetWrapper = () => {
  const { budgetId } = useParams<{ budgetId: string }>();
  const isNewBudgetDesign = useFeatureFlag(EarlyAccessFeature.BUDGET_NEW_DESIGN);

  const [hasMetadata] = useHasCustomerMetadata();
  const [_allBudgets, budgets, budgetsLoading] = useBudgets();
  return useMemo(() => {
    if (hasMetadata === undefined || budgetsLoading) {
      return null;
    }

    const budget = budgets.find((b) => b.snapshot.id === budgetId);

    if (!budget || !hasMetadata) {
      return <Redirect to="." />;
    }

    if (isNewBudgetDesign) {
      return <CreateBudgetStepper budget={budget} isNewBudget={false} />;
    }

    return <Budget budget={budget} isNewBudget={false} />;
  }, [hasMetadata, budgetsLoading, budgets, budgetId, isNewBudgetDesign]);
};
