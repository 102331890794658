import { Box, Typography } from "@mui/material";

import snowflakeLogo from "../../../../../assets/snowflake-logo.svg";

export const cyIds = {
  header: "snowflake-header",
  logo: "snowflake-logo",
};

const SnowflakeAssetsTabHeader = () => (
  <Typography variant="h1" data-cy="snowflake-header" sx={{ pt: 2, pb: 2.5 }}>
    <Box
      component="img"
      src={snowflakeLogo}
      sx={{ height: "0.8em", width: "auto", mr: 1 }}
      data-cy="snowflake-logo"
      aria-hidden
      alt-text="Snowflake logo"
    />
    Snowflake
  </Typography>
);

export default SnowflakeAssetsTabHeader;
