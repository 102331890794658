export const globalText = {
  LOGOUT: "Sign out",
  COPY_ID: "Copy ID",
  COPIED_TO_CLIPBOARD: "Copied to clipboard",
  APPROVE: "Approve",
  REJECT: "Reject",
  NO_DATA_AVAILABLE: "No data available",
  EDIT: "Edit",
  INSERT: "Insert",
  DONE: "Done",
  OTHER: "Other",
  DEFAULT: "Default",
  CLOSE: "Close",
  HOME: "Home",
  ACCOUNT: "Account",
  AUTH_TOKEN_EXPIRED: "You have been logged out of the DoiT Console as your session has expired",
  SAVE: "Save",
  SAVE_CHANGES: "Save changes",
  SAVE_AS_ELLIPSIS: "Save as...",
  SAVE_AS: "Save as",
  SAVE_FAILED: "Save failed",
  UPDATE: "Update",
  CHANGE: "Change",
  CONFIGURE: "Configure",
  POLICY: "Policy",
  ACCEPT: "Accept",
  RUN: "Run",
  CREATE: "Create",
  SAVE_MY_PREFERENCES: "Save my preferences",
  SUBMIT: "Submit",
  CANCEL: "Cancel",
  DISCARD_CHANGES: "Discard changes",
  UPLOAD: "Upload",
  RESET: "Reset",
  VIEW: "View",
  OPEN: "Open",
  BACK: "Back",
  CONFIRM: "Confirm",
  DELETE: "Delete",
  GET: "GET",
  NA: "N/A",
  PRIVATE: "Private",
  LOADING: "Loading...",
  REFRESH: "Refresh",
  CONNECTIVITY_ERROR: "Oops! there seems to be a connection issue your changes were not saved",
  GENERIC_ERROR: "Oops! something went wrong",
  ORG_NAME: "Organization name",
  LAST_MODIFIED: "Last modified",
  CREATED: "Created",
  NAME: "Name",
  NONE: "None",
  NEW: "New",
  ADD: "Add",
  NEW_VERSION_AVAILABLE: "There is a new version available",
  NEXT: "Next",
  NO: "No",
  OPTIONAL: "Optional",
  YES: "Yes",
  SEND: "Send",
  SUBSCRIBE: "Subscribe",
  UNSUBSCRIBE: "Unsubscribe",
  BASIC_AWS_SUPPORT: "Basic AWS support",
  DESCRIPTION: "Description",
  ADD_DESCRIPTION: "Add description",
  UPDATE_DESCRIPTION: "Update description",
  REMOVE: "Remove",
  PRESETS: "Presets",
  OKAY: "Okay",
  EDIT_PERMISSIONS: "Edit permissions",
  SELECT: "Select",
  NO_CHANGES: "No changes were made",
};

export const unsavedChangesPromptText = {
  DIALOG_TITLE: "Unsaved changes",
  DIALOG_CONTENT: "The changes you have made will be lost if you leave this page without saving.",
};

export const widgetsText = {
  SWITCH_TEXT: "Show in widget library",
  YOUR_DASHBOARDS: "Your dashboards",
  NEW_DASHBOARD: "New dashboard",
  DASHBOARD_INPUT: "Dashboard name",
};

export const stepperText = {
  OPTIONAL: "Optional",
  SUBMIT: "Save and continue",
  NEXT: "Next",
  BACK: "Back",
  CANCEL: "Cancel",
};

export const httpMethods = {
  DELETE: "DELETE",
  POST: "POST",
  PUT: "PUT",
  GET: "GET",
  PATCH: "PATCH",
};

export const anomaliesText = {
  INVESTIGATE: "Investigate",
};

export const marketplaceText = {
  TYPE: "Type",
  CUSTOMER: "Customer",
  BILLING_ACCOUNT_TYPE: "Billing account type",
  BILLING_ACCOUNT: "Billing account",
  AMOUNT: "Amount",
  TARGET_TIME: "Target time",
  TRANSACTION_FEE: "Transaction fee",
  STATUS: "Status",
  STATE: "State",
  ACCOUNT_ID: "Account ID",
  ENTITLEMENT_ID: "Entitlement ID",
  CREATE_TIME: "Create time",
  TIME_ENABLED: "Time enabled",
  LAST_UPDATE: "Last update",
  PRODUCT: "Product",
  FILTER_ADJUSTMENTS: "Filter adjustments",
  FILTER_ACCOUNTS: "Filter accounts",
  FILTER_ENTITLEMENTS: "Filter entitlements",
  ADJUSTMENT_EDITED_SUCCESSFULLY: "Adjustment edited successfully",
  ADJUSTMENT_EDIT_FAILED: "Adjustment edit failed",
  ADJUSTMENT_CREATED_SUCCESSFULLY: "Adjustment created successfully",
  ADJUSTMENT_CREATE_FAILED: "Adjustment create failed",
  EDIT_ADJUSTMENT: "Edit adjustment",
  NEW_ADJUSTMENT: "New adjustment",
  CREATE_ADJUSTMENT: "Add new adjustment",
  DELETE_ADJUSTMENT: "Delete adjustment",
  ADJUSTMENT_DELETED_SUCCESSFULLY: "Adjustment deleted successfully",
  POPULATE_BILLING_ACCOUNT: "Populate billing account",
  POPULATE_BILLING_ACCOUNT_ERROR: "Error populating billing account",
  POPULATE_BILLING_ACCOUNT_SUCCESSFULLY: "Successfully populated billing account",
  POPULATE_BILLING_ACCOUNT_FAILED: "Failed to populate billing account",
  ACCOUNT_APPROVED_SUCCESSFULLY: "Account approved successfully",
  ACCOUNT_REJECTED_SUCCESSFULLY: "Account rejected successfully",
  ACCOUNT_APPROVE_ERROR: "Failed to approve account",
  ACCOUNT_REJECT_ERROR: "Failed to reject account",
  ENTITLEMENT_APPROVED_SUCCESSFULLY: "Entitlement approved successfully",
  ENTITLEMENT_REJECTED_SUCCESSFULLY: "Entitlement rejected successfully",
  ENTITLEMENT_APPROVE_ERROR: "Failed to approve entitlement",
  ENTITLEMENT_REJECT_ERROR: "Failed to reject entitlement",
  ADJUSTMENT_DELETE_ERROR: "Error deleting adjustment",
  ADJUSTMENT_DELETE_MESSAGE: "Please note: the adjustments you selected will be permanently deleted.",
  COPY_ACCOUNT_ID: "Copy account ID",
  COPY_ENTITLEMENT_ID: "Copy entitlement ID",
  APPROVE_ENTITLEMENT: "Are you sure you want to approve this entitlement?",
  REJECT_ENTITLEMENT: "Are you sure you want to reject this entitlement?",
  APPROVE_ACCOUNT: "Are you sure you want to approve this account?",
  REJECT_ACCOUNT: "Are you sure you want to reject this account?",
  APPROVE_AWS_ENTITLEMENT: "Approve AWS entitlement?",
  REJECT_AWS_ENTITLEMENT: "Reject AWS entitlement?",
  NOT_ELIGIBLE_FLEXSAVE_GCP_ERROR:
    "You are not eligible for Flexsave. Your account manager will reach out to discuss next steps.",
  SUBSCRIBE_GENERAL_GCP_ERROR: "Failed to subscribe to GCP marketplace",
  PERIODS_NUMBER: "Number of Periods after start time, must be between 1 and 12",
  CREATE_INVOICE_ADJUSTMENTS: "Create invoice adjustments",
  DESCRIPTION: "Description",
  MUST_BE_BIGGER_THAN_ZERO: "Must be bigger than zero",
  PERIODS: "Periods",
  ACTION: "Action",
  OFFER: "Offer",
  UPDATE_TIME: "Update Time",
  AWS_ACCOUNT_ID: "AWS account",
  DOMAIN: "Domain",
};

export const cloudAnalyticsText = {
  ANALYTICS_TABS_ALERT: "We are still collecting your billing data. Typically, it takes less than 24 hours.",
  ANALYTICS_TABS_ALERT_NO_ASSETS: "There are no Google Cloud or AWS accounts in your organization yet.",
  ANALYTICS_TABS_ALERT_STANDALONE: "We are optimizing your Flexsave savings. Please check back later.",
  ANALYTICS_TABS_ALERT_SAAS_CONSOLE: "We are importing your billing data. Please check back later.",
  REPORTS: "reports",
  ATTRIBUTIONS: "attributions",
  BUDGETS: "budgets",
  TITLE: "Cloud Analytics",
  GKE_COST_ALLOCATION_ALERT_TEXT: "Understand your GKE cluster costs using GKE cost allocation",
  GKE_COST_ALLOCATION_ALERT_HREF: "https://help.doit.com/docs/cloud-analytics/gke-cost-analytics",
  GKE_COST_ALLOCATION_ENABLE_ALERT_HREF:
    "https://help.doit.com/docs/cloud-analytics/kubernetes-cost-analysis/gke-cost-analytics#enable-gke-cost-allocation",
  GKE_COST_ALLOCATION_ALERT_BTN_TEXT: "View disabled clusters",
  METRICS: "metrics",
  GKE_COST_ALLOCATION_PARTIALLY_ENABLED_TEXT: "Some of your GKE clusters don't have GKE Cost allocation enabled.",
  GKE_COST_ALLOCATION_PARTIALLY_ENABLED_HREF: "https://help.doit.com/docs/cloud-analytics/gke-cost-analytics",
  GKE_COST_ALLOCATION_PARTIALLY_ENABLED_TITLE: "Google GKE Clusters without Cost Allocation",
  GKE_COST_ALLOCATION_PARTIALLY_ENABLED_SUBTITLE: "Cost allocation is currently disabled for the following clusters:",
  LIMITS: {
    TITLE: "Limit by top/bottom",
    DIALOG_CONFIRM: "Apply",
    LIMIT_TO_LABEL: "Limit to",
    SHOW_LABEL: "Number",
    METRIC_LABEL: "Metric",
    OUT_OF: "of",
    BY: "by",
    TOP: "Top",
    BOTTOM: "Bottom",
    ALERT_TEXT: "Your filters are using different metrics than your report",
  },
  PREVIEW: {
    FETCHING_PREVIEW: "Fetching preview...",
    PREVIEW_TITLE: "Preview",
    CHANGE_ATTRIBUTIONS: "Please try changing the scope attributions",
    PREVIEW_DISABLED: "Preview is disabled",
    NO_PREVIEW:
      "We could not preview your results due to a problem with our infrastructure. We are sorry for the inconvenience!",
    ERR_UKNOWN: "unknown_error",
    ERR_CANCELED: "canceled",
    ERR_INVALID_TIME_SETTINGS: "invalid_time_settings",
    ATTRIBUTION_LABEL: "Attribution",
    ATTRIBUTION_GROUP_LABEL: "Attribution group",
  },
  CLOUD_ANALYTICS_RESOURCES_TABLE: {
    ID: "ID",
    NAME: "Name",
    TYPE: "Type",
    CONTENT_TYPE: "Content type",
    OWNER: "Owner",
    LAST_MODIFIED: "Last modified",
    TIME_CREATED: "Time created",
    FILTER: "Filter",
  },
};

export const sandboxText = {
  FILTER_SANDBOXES: "Filter sandboxes",
  CREATE_SANDBOX: "Create sandbox",
  MAX_CAPACITY_REACHED: "You have reached the maximum amount of sandboxes allowed per user",
  EMPLOYEE_CANT_CREATE_SANDBOXES: "Doit Employees can't create sandboxes",
  DELETE_SANDBOX_MESSAGE:
    "Deleting sandbox policies doesn't affect your sandbox project in Google Cloud. To delete the project itself, see ",
  DELETE_SANDBOX_TITLE: "Delete sandbox policies",
  TABLE_HEADERS: {
    PROJECT: "Project",
    OWNER: "Owner",
    TYPE: "Type",
    ACTION: "Action",
    SPEND: "Spend",
    STATUS: "Status",
  },
};

export const attributionText = {
  ATTRIBUTION_PRESET_NOT_DELETE: "Preset attributions are non-deletable",
  ATTRIBUTION_USED_BY_BUDGET: "Cannot delete attribution used by the budget",
  ATTRIBUTION_USED_BY_DAILY_DIGEST: "Cannot delete attribution used by daily digest",
  ATTRIBUTION_USED_BY_METRIC: "Cannot delete attribution used by the metric",
  ATTRIBUTION_USED_BY_ATTRIBUTION_GROUP: "Cannot delete attribution used by the attribution group",
  ATTRIBUTION_USED_BY_ALERT: "Cannot delete attribution used by the alert",
  ATTRIBUTION_USED_BY_ORG: "Cannot delete attribution used by the organization",
  ATTRIBUTION_USED_BY_REPORT: "Cannot delete attribution used by the report",
  ATTRIBUTION_NAME: "Attribution name",
  CANNOT_DELETE: "Only attribution owners can delete attributions",
  CREATE_NEW_ATTRIBUTION: "Create new attribution",
  CREATE_NEW_ATTRIBUTION_STEP: (step, totalSteps) => `Create new attribution (${step}/${totalSteps})`,
  COPY_OF: "Copy of",
  DELETE: "Delete",
  ANOMALY_DETECTION: "Anomaly detection",
  DELETE_ATTR_ERROR: "Failed to delete attribution(s)",
  DELETE_ATTR_SUCCESS: "Attribution(s) successfully deleted",
  DELETE_MESSAGE: "Please note: the attributions you selected will be permanently deleted.",
  DELETE_SELECTED: "Delete Selected Attribution(s)",
  DIALOG_ACTION_ADD: "Add",
  DIALOG_ACTION_BACK: "Back",
  DIALOG_ACTION_CANCEL: "Cancel",
  DIALOG_ACTION_NEXT: "Next",
  DIALOG_SUB_TITLE: "Create basic attributions. Advanced options are available in Analytics > Attributions",
  DIMENSION_OPTIONS: "Dimension options",
  DIMENSION_TYPE: "Dimension type",
  DUPLICATE: "Duplicate attribution",
  EDIT: "Edit settings",
  FILTER_ATTRIBUTIONS: "Filter attributions",
  FOLLOWING_CONDITIONS: "of the following conditions:",
  IS: "is ",
  IS_NOT: "is not ",
  LEARN_MORE_ALERT:
    "Attributions are a flexible way to group resources and their associated costs, helping you understand the cloud costs and relate them to the business in your Reports",
  MEETS: "Meets",
  NEW_ATTRIBUTION: "New attribution",
  NO_ATTRIBUTION_NAME: "Please enter an attribution name",
  NON_EDITOR_ALERT:
    "You are not an editor for this attribution - please contact the attribution owner to be added as a collaborator.",
  NON_EDITOR_ALERT_BUTTON_TEXT: "Open docs",
  REGEX_MATCH: "matches regular expression ",
  REGEX_NO_MATCH: "does not match regular expression ",
  SHARE: "Share attribution",
  SHARE_FAILED: "Share attribution failed",
  SHARE_SUCCESS: "Permissions updated successfully",
  SUBSCRIBE_TO_DAILY_DIGEST: "Subscribe to daily digest",
  SUCCESSFULLY_CREATED: "Successfully created attribution",
  SUCCESSFULLY_UPDATED: "Attribution saved successfully",
  SUCCESSFULLY_SUBSCRIBED: "Successfully subscribed to daily digest",
  SUCCESSFULLY_UNSUBSCRIBED: "Successfully unsubscribed from daily digest",
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "Attribution name",
    OWNER: "Owner",
    TYPE: "Type",
    ANOMALY_DETECTION: "Anomaly detection",
    SUBSCRIBED: "Subscribed",
  },
  UNSUBSCRIBE_FROM_DAILY_DIGEST: "Unsubscribe from daily digest",
  UNTITLED_ATTRIBUTION: "Untitled attribution",
  SCOPE: "Scope",
  SELECT_ATTRIBUTION: "Select Attribution",
  CHANGES_SAVED: "All changes are saved",
  INVALID_FORMULA: "Formula entered is invalid",
  SAVE_AS_TITLE: "Save new attribution as",
  SAVE_AS_HELPER_TEXT: "This will create a new attribution with you as owner",
  PREVIEW: "Attribution Preview",
  SHARE_ATTRIBUTION: "Share attribution",
  SHARE_ATTRIBUTIONS: "Share attributions",
};

export const attributionGroupsText = {
  TABLE_HEADERS: {
    ID: "ID",
    ATTRIBUTION_GROUP_NAME: "Attribution group name",
    ATTRIBUTIONS: "Attributions",
    OWNER: "Owner",
    LAST_MODIFIED: "Last modified",
    TYPE: "Type",
  },
  DELETE_ATTRIBUTION_GROUPS_SUCCESS: "Attribution group(s) successfully deleted",
  DELETE_ATTRIBUTION_GROUP_SUCCESS: "Attribution group successfully deleted",
  DELETE_ATTRIBUTION_GROUPS_ERROR: "Failed to delete attribution group(s)",
  CLONE_ATTRIBUTION_GROUP_SUCCESS: "Attribution group successfully cloned",
  SHARE_SUCCESS: "Share attribution group was successful",
  SHARE_FAILED: "Share attribution group failed",
  FILTER_ATTRIBUTION_GROUPS: "Filter attribution groups",
  NEW_GROUP: "New group",
  CREATE_NEW_GROUP: "Create new group",
  DELETE_SELECTED: "Delete Selected Attribution Group(s)",
  DELETE_MESSAGE: "Please note: the attribution groups you selected will be permanently deleted.",
  SHARE_ATTRIBUTION_GROUP: "Share attribution group",
  SHARE_ATTRIBUTION_GROUPS: "Share attribution groups",
  DELETE_ATTRIBUTION_GROUP: "Delete attribution group",
  CLONE_ATTRIBUTION_GROUP: "Clone attribution group",
  ATTRIBUTION_GROUP_NAME: "Attribution group name",
  ADD_ATTRIBUTION: "Add attribution",
  UNALLOCATED: "Unallocated",
  OPEN_ATTRIBUTION_NEW_TAB: "Open attribution in new tab",
  CREATE_ATTRIBUTION_GROUP_SUCCESS: "Successfully created attribution group",
  UPDATE_ATTRIBUTION_GROUP_SUCCESS: "Successfully updated attribution group",
  CREATE_ATTRIBUTION_GROUP_ERROR: "Failed to create attribution group",
  SAVE_AS_HELPER_TEXT: "This will create a new attribution group with you as owner",
  SAVE_AS_TITLE: "Save new attribution group as",
  UNALLOCATED_TOOLTIP: "Items not covered by any attribution",
};

export const analyticsAlertText = {
  LOWER_BOUND: "Lower bound",
  UPPER_BOUND: "Upper bound",
  DELETE_ALERT_ERROR: "Failed to delete alert(s)",
  DELETE_ALERT_SUCCESS: "Alert(s) successfully deleted",
  DELETE_MESSAGE: "Please note: the alerts you selected will be permanently deleted.",
  DELETE_SELECTED: "Delete Selected Alert(s)",
  EDIT_ALERT: "Edit alert",
  EDIT_ALERT_SCOPE: "Edit alert scope",
  EDIT_CONDITIONS: "Edit conditions",
  EXIT_DIALOG_CONTENT: "Changes you made will not be saved",
  EXIT_DIALOG_TITLE: "Leave without saving?",
  FILTER_ALERTS: "Filter alerts",
  NEW_ALERT: "New Alert",
  CREATE_NEW_ALERT: "Create new alert",
  SHARE_ALERT: "Share alert",
  SHARE_ALERTS: "Share alerts",
  SHARE_SUCCESS: "Alert sharing saved successfully",
  STEPPER: {
    ALERT_ME_WHEN: "Alert me when:",
    IGNORE_VALUES_BETWEEN: "Ignore values between:",
    ALERT_SAVED_SUCCESSFULLY: "Alert saved successfully",
    ALERT_SCOPE: "Alert Scope",
    CONDITION: "Condition",
    CURRENCY: "Currency",
    Conditions: "Conditions",
    DAILY_ALERT_WARNING: "We receive data with a delay up to 24 hours. Daily alerts will trigger with a delay.",
    EDIT_VALUE: "Edit Value",
    NEW_VALUE: "New Value",
    Notifications: "Notifications",
    OPERATOR: "Operator",
    SCOPE: "Scope",
    SELECT_CURRENCY: "Select currency",
    TIME_INTERVAL: "Time Interval",
    VALUE: "Value",
  },
  UNTITLED_ALERT: "Untitled alert",
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "Alert name",
    CONDITION: "Condition",
    SCOPE: "Scope",
    LAST_SENT: "Last sent",
    LAST_MODIFIED: "Last modified",
    OWNER: "Owner",
  },
  NO_PERMISSIONS: "You do not have permissions to view this page",
};

export const budgetText = {
  BUDGET_UNSUBSCRIBE: "Unsubscribe from this budget's alerts",
  BUDGET_SUBSCRIBE: "Subscribe to this budget's alerts",
  AMOUNT_COL_HEADER: "Amount",
  PERIOD_COL_HEADER: "Period",
  NEW_BUDGET: "New budget",
  CREATE_NEW_BUDGET: "Create new budget",
  ALERTS_CARD_TITLE: "Alert thresholds",
  ALERT_AMOUNT: "Amount",
  ALERT_PERCENTAGE: "Percentage",
  BUDGET_AMOUNT: "Budget amount",
  BUDGET_ALERTS: "Budget alerts",
  BUDGET_METRIC: "Metric",
  BUDGET_TIME_INTERVAL: "Time Interval",
  LAST_PERIOD_COST: "Last period Cost",
  BUDGET_SCOPE: "Budget scope",
  SELECT_ATTRIBUTION: "Select Attribution",
  GROWTH_PER_BUDGET_PERIOD: "Growth per budget period",
  REFRESH_BUDGET_ALERT: "Click save to refresh the budget utilization and forecast data",
  ALERT_THRESHOLD_NO_REACH: "This threshold is not likely to be reached within the budget period",
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "Budget Name",
    OWNER: "Owner",
    BUDGET_AMOUNT: "Budget amount",
    ACTUALS_TO_DATE: "Actuals to date",
    BUDGET_AMOUNT_TO_DATE: "Budget amount to date",
    VARIANCE_TO_DATE: "Variance to date",
    PERIOD: "Period",
    CURRENT_PCT: "Budget progress",
    MAX_UTIL: "Max Utilization",
    SUBSCRIBE: "Subscribe",
    DESCRIPTION: "Description",
    TIME_INTERVAL: "Time interval",
  },
  ATTRIBUTION_LABEL: "Attribution",
  FILTER_BUDGETS: "Filter budgets",
  USE_LAST_SPEND_LABEL: "Set recurring period budget based on last period cost",
  ALLOW_GROWTH_LABEL: "Allow growth in recurring budget periods",
  DELETE_SELECTED: "Delete Selected Budget(s)",
  DELETE_MESSAGE: "Please note: the budgets you selected will be permanently deleted.",
  DELETE_BUDGET_SUCCESS: "Budget(s) successfully deleted",
  DELETE_BUDGET_ERROR: "Failed to delete budget(s)",
  FETCHING_PREVIEW: "Fetching Budget Preview...",
  BUDGET_PREVIEW_TITLE: "Budget Preview",
  CHANGE_ATTRIBUTIONS: "Please try changing the budget scope attributions",
  NO_PREVIEW:
    "We could not preview your budget results due to a problem with our infrastructure. We are sorry for the inconvenience!",
  BUDGET_UPDATE: "Update budget",
  BUDGET_UPDATE_LOADING: "Updating budget",
  BUDGET_UPDATE_TEXT: "The scope has changed, please update the budget.",
  SHARE_BUDGET: "Share budget",
  SHARE_BUDGETS: "Share budgets",
  SHARE_ERROR: "Share budget failed",
  SUBSCRIBE_ERROR: "Subscribe to budget failed",
};

export const appcuesText = {
  FILTER_RESULTS: "filter-results",
  LIMIT_RESULTS: "limit-results",
  DIMENSIONS: "dimensions",
};

export const reportText = {
  SAVE_AS_LABEL: "Name",
  SAVE_AS_HELPER_TEXT: "This will create a new report with you as the owner",
  SAVE_NEW: "Save new report as",
  SUCCESSFULLY_SAVED: "Report saved successfully",
  NAME_THE_REPORT: "Please name the report before saving",
  ALL_CHANGES_ARE_SAVED: "All changes are saved",
  ONLY_OWNER: "Only report owners and editors can save changes",
  ONLY_OWNER_TITLE: "Only report owners and editors can edit this title",
  PRESET_NOEDIT: "Preset reports can not be edited",
  CSV_COPIED_TO_CLIPBOARD_SUCCESS: "CSV was copied to clipboard",
  CSV_COPIED_TO_CLIPBOARD_FAIL: "Failed to copy CSV to clipboard",
  REPORT_SHARE_SUCCESS: "Permissions updated",
  REPORT_NAME: "Report name",
  MAKE_OWNER: "Make owner",
  NEW_REPORT: "New report",
  CREATE_NEW_REPORT: "Create new report",
  SHARE_PERMISSIONS: "Share permissions",
  USER_NOT_IN_ORG: (orgName) => `Some of the users are not members of the "${orgName}" organization`,
  BASIC_METRICS: "Basic metrics",
  CALCULATED_METRICS: "Custom metrics",
  EXTENDED_METRICS: "Extended metrics",
  DATAHUB_METRICS: "DataHub metrics",
  NO_ACCESS_METRIC: "You do not have access to this metric",
  DISABLED_CHIP_CALC_METRIC: "Attribution filter cannot be edited while using a custom metric",
  REPORT_PRESET_NOT_DEL: "Preset reports are non-deletable",
  REPORT_NOT_OWNER: "Cannot delete a report that you are not the owner",
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "Report name",
    DESCRIPTION: "Description",
    PUBLIC: "Public",
    OWNER: "Owner",
    TYPE: "Type",
    SHARE: "Share",
    SCHEDULE: "Schedule",
    LAST_MODIFIED: "Last modified",
    LINK: "Link",
  },
  SUBSCRIBED: "Subscribed",
  UNSUBSCRIBED: "Unsubscribed",
  NOT_SCHEDULED: "Not scheduled",
  CLIPBOARD_COPY: "Copied to clipboard",
  DUPLICATE_SUCCESSFUL: "Report was duplicated successfully",
  DELETE_ERROR: "Failed to delete report(s)",
  DELETE_SELECTED: "Delete Selected Report(s)",
  DELETE_MESSAGE: "Please note: the reports you selected will be permanently deleted.",
  DELETE_REPORT_SUCCESS: "Report(s) successfully deleted",
  SAVE_DEFAULT_CONFIG_SUCCESS: "New reports will start with this configuration",
  DELETE_REPORT: "Delete report",
  DELETE_CURRENT_MESSAGE: "This report will be permanently deleted.",
  LIMITS_FILTER_RESET: "The Top/bottom results filter was reset",
  FILTER_REPORTS: "Filter reports",
  COMPARATIVE_LABEL: "View data as",
  EXCLUDE_PARTIAL_LABEL: "Exclude partial intervals",
  EXCLUDE_PARTIAL_TOOLTIP: "Automatically exclude partial intervals of data from graphs and tables",
  INCLUDE_PROMOTIONAL_CREDITS_LABEL: "Include promotional credits",
  INCLUDE_PROMOTIONAL_CREDITS_TOOLTIP:
    "Promotional Credits are added to your monthly invoices (e.g. migration credits, commitment related credits, vendor program credits).\n" +
    "They are available only in reports with monthly or greater time interval resolution.",
  TIMEZONE: "Timezone",
  CURRENCY: "Currency",
  EXPORT_TO_GOOGLE_SHEETS: "Export to Google Sheets",
  EXPORT_TO_CSV: "Export to CSV",
  RAW_EXPORT_TO_CSV: "Export raw data to CSV",
  COPY_CSV_TO_CLIPBOARD: "Copy CSV to clipboard",
  MENU: {
    COPY_LINK: "Copy link",
    SHARE: "Share report",
    DUPLICATE: "Duplicate",
    DELETE: "Delete",
  },
  DUPLICATE_REPORT: "Duplicate report",
  ADD_TO_DASHBOARD: "Add to dashboard",
  REPORT_CONFIG: "Report configuration",
  SET_AS_DEFAULT: "Set as default view for new reports",
  REVERT_ALL_CHANGES: "Revert all changes",
  GROUP_BY_SECTION_LABEL: "Group by",
  ADVANCED_OPTIONS_SECTION_LABEL: "Advanced options",
  FILTERֹֹ_RESULTS_SECTION_LABEL: "Filter results",
  DIMENSIONS_SECTION_LABEL: "Dimensions",
  LIMIT_RESULTS_SECTION_LABEL: "Limit results",
  SORTֹֹ_DIMENSIONS: "Sort dimensions",
  SORTֹ_GROUPS: "Sort groups",
  COUNT_SECTION_LABEL: "Count",
  ERR_MISSING_COUNT_FIELD_TITLE: "Add count",
  ERR_MISSING_COUNT_FIELD_PREFIX: "To use ",
  ERR_MISSING_COUNT_FIELD_VALUE: "Aggregation - count",
  ERR_MISSING_COUNT_FIELD_SUFFIX: " , select the items you want to count",
  INCLUDE_CURRENT: "Include current ",
  RUN_REPORT: "Run report",
  RUN_REPORT_MESSAGE: "Run report to see changes",
  REPORT_DETAILS: "Report details",
  REPORT_DESCRIPTION: "Report description",
  FILTERS: "Filters:",
  GROUPINGS: "Groupings:",
  DIMENSIONS: "Dimensions:",
  UNDERSTAND_THIS_REPORT: "Understand this report",
  SHARE_FAILED: "Share report failed",
  EXPORTED_REPORT: "exported report",
};

export const invoicesText = {
  LEARN_MORE: "Learn more about invoices",
  INVOICE_HELP_URL: "https://help.doit.com/docs/billing/invoices-and-payments",
  CREDIT_CARD_FEES: "Credit card fees",
  TAX: "Tax",
  TOTAL_PAYMENT_AMOUNT: "Total payment amount",
  TOTAL_PAYMENT_AMOUNT_NOT_INCLUDING_TAX: "Total payment amount (not including tax)",
};

export const contractsText = {
  COMMITMENT_ROLLOVER_LABEL: "Commitment rollover",
  ADD_COMMITMENT_PERIOD_BUTTON_TEXT: "Add new commitment period",
  DISCOUNT_EXPIRATION_LABEL: "Discount expiration date",
  COMMITMENT_PERIOD_TITLE: "Commitment period",
  DELETE_COMMITMENT_PERIOD_BUTTON_TEXT: "Delete period",
  START_DATE_LABEL: "Start Date",
  END_DATE_LABEL: "End Date",
  COMMITMENT_PERIOD_AMOUNT_LABEL: "Commitment Amount",
  COMMITMENT_PERIOD_DISCOUNT_LABEL: "Period Discount",
};

export const metricText = {
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "Metric name",
    DESCRIPTION: "Description",
    OWNER: "Owner",
    TYPE: "Type",
  },
  UNTITLED_METRIC: "Untitled metric",
  NEW_METRIC: "New metric",
  CREATE_NEW_METRIC: "Create new metric",
  VARIABLE_METRIC: "Base metric",
  VARIABLE_ATTRIBUTION: "Attribution",
  FORMULA: "Formula",
  FORMAT: "Format",
  DELETE_SELECTED_METRICS: "Delete selected metric(s)",
  DELETE_SELECTED_BODY: "Please note: the metrics you selected will be permanently deleted.",
  DISABLED_DELETE_PRESET_TOOLTIP: "Preset metrics are non-deletable",
  METRIC: "Metric",
  REPORT: "report",
  IT_IS: "it is",
  THEY_ARE: "they are",
  PLURAL: "s",
  DISABLED_DELETE_USED_TOOLTIP: (who, metricNames, what, reportNames, byWhom) =>
    `${who} ${metricNames} cannot be deleted since ${what} used by ${reportNames} ${byWhom}`,
  INVALID_FORMULA: "Invalid formula",
  FORMULA_MISSING_ATTR: (vars) => `Formula is missing variable ${vars}`,
  FORMULA_MISSING_ATTRS: (vars) => `Formula is missing variables ${vars}`,
  PREVIEW_RESULT_EMPTY: "This metric didn't produce any meaningful results, please try changing the definition",
  PREVIEW_ERROR:
    "We could not preview your metric results due to a problem with our infrastructure. We are sorry for the inconvenience!",
  UNDEFINED_VALUE: "",
  METRIC_DIALOG_TITLE: (name) => `Show ${name}`,
  METRIC_DIALOG_CONTENT:
    "Custom Metrics you select will show the data according to the formula you set up in the Metrics section. To enable it, we’ll make an adjustment to your report:",
  METRIC_DIALOG_RESTRICTIONS: "- Apply the attribution filter with the attributions used in the formula only",
  METRIC_DIALOG_CONFIRM: "Show metric",
  LEARN_MORE_ALERT:
    "Metrics are a flexible way to create additional calculations based on reports data, according to your own needs. Metrics you create will be available in reports.",
  FILTER_METRICS: "Filter metrics",
  DELETE_METRIC_SUCCESS: "Metric(s) successfully deleted",
  DELETE_METRIC_ERROR: "Failed to delete metric(s)",
};

export const treemapText = {
  TREEMAP_CONFIRM_DIALOG:
    "Treemaps are helpful with visualizing hierarchical information. To enable the treemap, we’ll make a few adjustments to your report:",
};

export const comparativeDialogText = {
  COMPARATIVE_DIALOG_HEADER: "Show comparative report",
  COMPARATIVE_DIALOG_BODY: "To enable Comparative Report mode, we’ll make a few adjustments to your report:",
  COMPARATIVE_DIALOG_BULLETS: ["- Set report as a valid time series report.", "- Apply total aggregation."],
  DIALOG_ACTION_BUTTON: "Show report",
};

export const tooltipText = {
  // Reports
  REPORT_EXPORT_BUTTON: "Export report",
  REPORT_SHARE_DISABLED_PRESET: "Preset reports cannot be shared. Clone the report to share.",
  ALPHABETICAL_SORT: "Alphabetical Sort",
  ASC_SORT: " total ascending",
  DES_SORT: " total descending",
  ROW: "Row",
  COLUMN: "Column",
  METRIC_FILTER_DISABLED: "Metric is disabled when selecting Count Aggregation",

  // Flex RI
  FLEX_RI_ORDER_LOCK_DUE_TO_DATE: "You may not submit new orders on the last 24 hours before the end of the month",
  FLEX_RI_FEATURE_NOT_AVAILABLE:
    "This feature is not available on your account please contact your account manager for further information",

  // Billing assets
  BILLING_ACCOUNT_AWS_CREATE: [
    "When you create a new account in an organization, AWS automatically creates an IAM role named OrganizationAccountAccessRole.\n\n",
    "This role has full administrative permissions in the new account, and the organization can assume this role to access your account.\n\n",
    "Keep this checkbox selected if you wish to delete this role and prevent access from the organization's account.",
  ],
};

export const dashboardsText = {
  exists: "This name already exisits",
  chars: "You've entered a special character that isn't allowed",
  empty: "Name can't be empty",
};

export const organizationsText = {
  NEW_ORG: "New organization",
  CREATE_NEW_ORG: "Create new organization",
  DELETE_ORG: "Delete",
  ROOT: "Root",
  ORGS: "Organizations",
  ORG_NAME: "Organization name",
  AVAILABLE_ATTRS: "Available attributions",
  SELECTED_ATTRS: "Selected attributions",
  AVAILABLE_USERS: "Available users",
  ATTRIBUTIONS: "Attributions",
  ATTRIBUTIONS_TOOLTIP: "Attributions defining scope for this organization",
  SELECTED_USERS: "Selected users",
  USERS_IN_ORG: "Members",
  USERS_IN_ORG_VERBOSE: "Users assigned to this organization",
  UNTITLED_ORG: "Untitled organization",
  ORGANIZATION_EXISTS: "This organization name already exists",
  DELETE_ROOT: "Cannot delete organization root",
  ORGANIZATION_DASHBOARDS: "Select global dashboards for this organization",
  ADVANCED: "Advanced options",
  DISABLE_CUSTOM_DASH: "Disable custom dashboards",
  DISABLE_ACCOUNT_DASH: "Disable account dashboard",
  DISABLE_INFO:
    "Selecting this option will prevent users within this Organization from creating custom dashboards. \n By disallowing this, you can prevent access to dashboad widgets with a global scope",
  DELETE_SUBTITLE: (name) => `You're going to delete ${name} organization(s). This action will:`,
  DELETE_POINT_1: "Reassign members to the root organization",
  DELETE_POINT_2: "Members won't be restricted to their previous organization's scope anymore",
  DELETE_POINT_3: "Members won't be restricted to their previous organization's dashboards settings",
  SUCCESSFULLY_DELETED: (name) => ` ${name} was removed successfully`,
  MULTIPLE_DELETED: (num) => `${num} organizations were removed successfully`,
};

export const googleSheetsExportDialogText = {
  DIALOG_TITLE: "Export to Google Sheets",
  DIALOG_ALLOW: "Allow",
  DIALOG_EXPORT: "Export",
  SELECT_ACCOUNT: "Please allow access to your Google Drive files, so the report can be exported to Google Sheets.",
  DIALOG_SAVE: "Save to Google Drive.",
  DIALOG_CANCEL: "Cancel",
  DIALOG_OPEN_SHEET: "Open spreadsheet.",
};

export const trialContactUsDialogTexts = {
  TITLE: "Ready for a conversation?",
  SUB_TITLE: [
    "Learn how to unlock the full power of DoiT Console & analyze your data.",
    "We’d be happy to talk about next steps!",
  ],
  EMAIL: "Email",
  FIRST_NAME_LABEL: "First name",
  LAST_NAME_LABEL: "Last name",
  SUBMIT_BUTTON_TEXT: "Submit",
  CANCEL_BUTTON_TEXT: "Cancel",
  CONFIRM_SUBMIT_TITLE: "Awesome!",
  CONFIRM_SUBMIT_SUB_TITLE: "We got your back, and we’ll be in touch soon.",
  THANKS_BUTTON_TEXT: "Cool, thanks!",
};

export const alertTexts = {
  LEARN_MORE: "Learn more",
  VIEW_DOCS: "View docs",
  ORGANIZATIONS_OVERVIEW: "Organizations help you create segments of your data to increase user productivity",
  ASGS_OVERVIEW:
    "Spot Scaling cleverly replaces some or all of on-demand EC2 instances with Spot instances using sophisticated AI-assisted analysis",
  CLOUD_CONNECT_AWS: "By providing access to AWS account/s, you can enable additional DoiT Platform features",
  FLEXSAVE_OVERVIEW:
    "Flexsave analyzes your usage to find the optimal coverage for your on-demand workload and applies discounts automatically, always optimizing for cost.",
};

export const cloudReportCardText = {
  EDIT_REPORT: "Edit report",
  TABLE_ERROR_LIMIT:
    "Error: Table cannot be displayed due to number of data points.\nPlease Limit Group By or Filter results",
  OPEN_REPORT: "Open full report",
  REFRESH_DATA: "Refresh",
  OPEN_HELP: "Learn more about this widget",
};

export const transferListText = {
  NO_RESULTS_TITLE: (type) => `No ${type} found`,
  NO_RESULTS_TEXT: (type) => `Please retype or clear the filter to see all ${type}.`,
};

export const customerConvertDialogText = {
  TITLE: "Convert prospect to customer",
  CONFIRM: "Convert",
  ALERT_TITLE: "Converting new client?",
  ALERT_CONTENT: [
    [["Completing this action will start the process to move a company from Test Drive to a full DoiT client."], []],
    [
      ["Clicking CONVERT below will do 2 things:"],
      [
        "Send an email to the Admin above with billing profile instructions",
        "Grant the company full access to the DoiT Console and all its features immediately.",
      ],
    ],
    [["Proceed with caution."], []],
  ],
  SELECT_ADMIN_LABEL: "Choose primary Admin",
  CONVERT_SUCCESS: "Ask the Admin to check their email and follow the instructions.",
  EMAIL_SENT_ERROR: "Couldn't send an instruction email",
};

export const successDialogTexts = {
  SUCCESS_TITLE: "Awesome!",
  THANKS_BUTTON_TEXT: "Cool, thanks!",
};

export const userNotificationTexts = {
  NOTIFICATION_PREFERENCES_SAVED: "Notification preferences saved",
  NO_CLOUD_ANALYTICS_PERMISSION: "Missing Cloud Analytics permission",
  AUTOCOMPLETE_TEXT: "Daily cost digest attributions",
  AUTOCOMPLETE_PLACEHOLDER_TEXT: "Select attributions",
};

export const slackText = {
  UPDATED_SUCCESSFULLY: "Settings saved successfully",
  CREATED_SUCCESSFULLY: "Your Slack shared channel was successfully created",
  INSTALLED_SUCCESSFULLY: "DoiT Slack App was successfully installed",
  CANNOT_CREATE: "Cannot create Slack shared channel",
  CANNOT_JOIN_CHANNEL: "Cannot join shared channel",
  JOIN_CHANNEL: "Join slack shared channel",
  COPY_LINK: "Copy Slack shared channel invitation link",
  LINK_COPIED: "Link copied",
  ERROR: "Error",
  SHARED_CHANNEL_SETTINGS_TITLE: "Slack shared channel settings",
  SHARED_CHANNEL: "Your shared channel",
  PLEASE_NOTIFY: "Notify the shared channel on new:",
  CREATE_CHANNEL: "Create your shared Slack channel",
  CREATE_TEXT:
    "If your company is using Slack Standard plan (or higher), please create your shared channel with DoiT International today!",
  YOU_CAN_SHARE: " You can now share links and get a preview on your Slack workspace.",
  USE_LINK: "To join please use this link",
  SLACK_INTEGRATIONS: "Slack integrations",
  SHARED_BUTTON_UNAVAILABLE: "This feature is only available to DoiT Cloud Advantage customers",
};

export const pricebooksText = {
  COMMENT: "Comment",
  DELETE_ERROR: "Failed to delete pricebook(s)",
  DELETE_MESSAGE: " will be permanently deleted and won't be able to be recovered.",
  EFFECT_MESSAGE: "This will effect ",
  DELETE_SELECTED: "Delete selected pricebook(s)",
  DELETE_PRICEBOOK: "Delete pricebook",
  DELETE_PRICEBOOK_SUCCESS: "Pricebook(s) successfully deleted",
  DUPLICATE_PRICEBOOK: "Duplicate pricebook",
  EDIT_PRICEBOOK: "Edit pricebook",
  EDIT_SINGLE_PRICEBOOK: "Select single pricebook to edit",
  EDIT_SPEC: "Edit pricebook specification",
  FILTER_PRICEBOOKS: "Filter pricebooks",
  ID: "Pricebook ID",
  NEW_PRICEBOOK: "New pricebook",
  TABLE_HEADERS: {
    ASSIGN: "Assign",
    ASSIGNED_TO_CUSTOMER_TENANTS: "Assigned to customer tenants",
    COPY: "Clone",
    CUSTOMER: "Customer",
    CUSTOMER_TENANTS: "Assigned to customer tenants",
    ENDDATE: "End date",
    LINK: "Link",
    NAME: "Pricebook name",
    STARTDATE: "Start date",
    TABLE: "Table",
    UPDATED: "updated",
  },
  UPDATED_BY: "Updated by",
};

export const settingsGeneralTexts = {
  SAVE_BUTTON_TEXT: "Save changes",
  CANCEL_BUTTON_TEXT: "Cancel",
  UPDATED_SUCCESSFULLY: "Settings saved successfully",
  TRIAL_ENDED_SUCCESSFULLY: "Trial successfully ended",
  TRIAL_ENDED_ERROR: "Failed to end trial",
  TRIAL_ENDED_TIER_ERROR: "Failed to get the tier",
  TRIAL_EXTENDED_SUCCESSFULLY: "Trial successfully extended",
  TRIAL_EXTENDED_ERROR: "Failed to extend trial",
  FAILED_TO_UPDATE: "Failed to update settings",
  FAILED_TO_GET_CURRENT_CONTRACT: "Failed to get current contract",
  CARD_TITLE: "Settings",
  TIMEZONE: "Timezone",
  CURRENCY: "Default currency",
  SAVE_SETTINGS: "Save settings",
};

export const settingsGoogleCloudTexts = {
  GKE_CONFIG_TEXTS: {
    MISSING_DATA_VIEWER_ROLE_ON_DATASET: "Missing role:",
  },
};

export const metricFiltersText = {
  TITLE: "Limit by value",
  DIALOG_CONFIRM: "Apply",
  DIALOG_SUB_TITLE: "Select the metric value and query type below and apply it to your report:",
  METRIC_VALUE_LABEL: "Metric",
  OPERATOR_LABEL: "Operator",
  VALUE_LABEL: "Value",
};

export const highchartsText = {
  LINEAR_SCALE: "Linear scale",
  LOG_SCALE: "Logarithmic scale",
};

export const loginTexts = {
  INVALID_SIGN_IN_MAGIC_LINK: "The link has already been used or expired. Please request a new one.",
  CMP: "Cloud Management Platform",
  DOIT_INTL: "DoiT International",
  SIGN_UP: "Sign up",
  SIGN_IN: "Sign in",
  CONTINUE: "Continue",
  SIGNUP_FAILED_TRY_AGAIN: "Sign up failed; please try again later",
  SIGNUP_FAILED_NO_INVITE: (email) => `Sign up failed; No invite found for ${email}`,
  SIGNIN_FAILED_INCORRECT_CREDS: "The password entered is incorrect or you do not have an account",
  SIGNIN_FAILED_ACCOUNT_DISABLED:
    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  USER_NOT_FOUND: "User not found. Create an account or use another email",
  CHECK_INBOX_VERIFICATION: "Please check your inbox for a verification email",
  ENTER_VERIFICATION_EMAIL: "To verify it's you, enter your email address",
  INVALID_EMAIL: "Please enter a valid email address",
  REQUIRED_EMAIL: "Email is required",
  REQUIRED_PASSWORD: "Password is required",
  MISSING_PASSWORD: "Missing password",
  PASSWORD_NOT_MATCH: "Passwords do not match",
  EMAIL_RESET_PASSWORD: (email) => `We've sent you an email to ${email} with instructions to reset your password`,
  SIGNIN_GOOGLE: "Sign in with Google",
  SIGNUP_GOOGLE: "Sign up with Google",
  SIGNIN_MICROSOFT: "Sign in with Microsoft",
  SIGNUP_MICROSOFT: "Sign up with Microsoft",
  TRIAL_SIGNUP: "Test drive the DoiT Console",
  CHECK_INBOX_TITLE: "Check your email inbox",
  FORGOT_PASSWORD_ENTER_EMAIL: "Please enter your email address",
  LOGIN_EMAIL_LABEL: "Email address",
  LOGIN_PASSWORD_LABEL: "Password",
  FIRSTNAME_LABEL: "First name",
  LASTNAME_LABEL: "Last name",
  LOGIN_EMAIL_PLACEHOLDER: "name@example.com",
  DONT_HAVE_ACCOUNT: "Don't have an account?",
  ALREADY_HAVE_ACCOUNT: "Already have a DoiT account?",
  NO_SSO_FOR_ACCOUNT: "Unable to sign in using SSO. Please contact your administrator or our support team",
  NO_MAGICLINK_FOR_ACCOUNT: "You can't sign in using magic link. Please contact your administrator or our support team",
  CREATE_AN_ACCOUNT: "Create an account",
  COMPLETE_SIGNUP: "Complete your sign up",
  PASSWORD_VALIDATION_MESSAGE: "Must have at least 6 characters and a mix of letters and numbers",
  PASSWORD_VALIDATION_LENGTH: "Password must be at least 6 characters long",
  SSO_LOGIN: "Single sign-on (SSO)",
  SIGN_IN_WITH_MAGIC_LINK: "Sign-in with magic link",
  FORGOT_PASSWORD: "Forgot you password?",
  CAPTION_SIGN_IN: "or sign in with email and password",
  CAPTION_SIGN_UP: "or sign up with email and password",
  CREATE_ACCOUNT: "Create account",
  REQUIRED_FIRSTNAME: "First name is required",
  REQUIRED_LASTNAME: "Last name is required",
  FLEXSAVE_SIGNUP: "Sign up for your estimate",
  ACCEPT_POLICY: "Please agree to the policy agreement before signing up",
  GO_BACK_TO_EMAIL_ADDRESS: "Go back to email address",
  SIGNING_IN_AS: "Signing in as",
  AGREEMENT_POLICY_PREAMBLE: "I agree to the",
  AGREEMENT_POLICY_LINK: "terms of service",
  NAVIGATOR_AGREEMENT_POLICY_URL: "https://help.doit.com/docs/doit-terms/doit-cloud-navigator-terms",
  AUTH_PROVIDERS_DIALOG_TITLE: "Please agree to the terms of service",
  AUTH_PROVIDERS_DIALOG_CONTENT:
    "Before signing up you need to agree to our terms of service. Click the checkbox below to agree.",
};

export const changeGVoiceDialogTexts = {
  TITLE: "Change Google Voice Edition",
  COMPARE_EDITIONS: "Compare Google Voice editions",
  COMMITMENT_SUB_TITLE: "Changing Edition will renew the contract to 12 months from today.",
  CHANGE_EDITION_SUCCESS: "Google Voice editon was successfully changed",
  CHANGE_EDITION_ERROR: "Failed changing Google Voice editon",
};

export const accountsTexts = {
  AWS: {
    MENU: {
      EDIT: "Edit account",
      UNLINK: "Unlink account",
    },
  },
};

export const billingProfilesTexts = {
  AUTO_ASSIGN_NEW_GCP_PROJECTS_LABEL:
    "Auto-assign any newly created Google Cloud project to the bucket that holds its linked billing account",
  DIALOG_BUCKET_NAME: "Bucket Name",
  DIALOG_CREATE_TITLE: "Create a bucket",
  DIALOG_EDIT_TITLE: "Edit bucket",
  DIALOG_ERROR_CREATE: "Failed to create bucket",
  DIALOG_ERROR_EDIT: "Failed to edit bucket",
  DIALOG_SUCCESS_CREATE: "Successfully created bucket",
  DIALOG_SUCCESS_EDIT: "Successfully edited bucket",
  DIALOG_BUCKET_NAME_MIN: "Must be between 1 to 28 characters",
  DIALOG_BUCKET_NAME_MAX: "Must be between 1 to 28 characters",
};

export const googleCloudBillingAccountAdminTexts = {
  ADD_ADMIN_INPUT_LABEL: "Billing account administrators email addresses",
  ADD_ADMIN_DIALOG_TITLE: "Add billing account admins",
  REMOVE_NON_CUSTOMER_ADMINS_TITLE: "Remove non-customer admins",
  REMOVE_NON_CUSTOMER_ADMINS_SUBTITLE: "Following admins will be removed from this billing profile:",
  CONTRACT_NOT_SIGNED_ADD_ADMIN_ERR: "Only doit domain is allowed before the contract is finalized",
  CONTRACT_SIGNED_ADD_ADMIN_ERR: "Admins added by Doers need to have an alias from the customer's domain list. ",
  MANAGE_DOMAINS: "Manage domains",
  ADD_ADMIN_SUCCESS_SNACKBAR_MESSAGE: "Admin(s) successfully added",
  ADD_ADMIN_FAILURE_SNACKBAR_MESSAGE: "Failed to add billing account admins",
  ADD_ADMIN_BUTTON_DISABLED_TOOLTIP_NO_CONTRACT: "Finalize your contract with DoiT to add admins",
  ADD_ADMIN_BUTTON_DISABLED_TOOLTIP_NOT_AN_ADMIN: "You must be an admin to add new admins",
};

export const customizeTableViewTexts = {
  TITLE: "Customize table columns",
  UPDATE: "Update",
  COLUMNS_TITLE: {
    BQ_LENS: "BigQuery lens",
    GKE_COST_ALLOCATION: "GKE Cost Allocation",
    FLEXSAVE_AWS: "Flexsave Amazon Web Services",
    FLEXSAVE_GCP: "Flexsave Google Cloud",
    AWS_RAMP_PLAN: "Amazon Web Services Ramps",
    GCP_RAMP_PLAN: "Google Cloud Ramps",
    REVENUE_EST_GCP: "FSGCP Net ARR",
    ACCOUNT_MANAGER: "Assigned Account Managers",
    TRIAL_STATUS: "Trial Status",
  },
};

export const myCustomersListTexts = {
  TITLE: "My Customers",
  SWITCH_VIEW: "View my team’s customers",
  SUBHEADER: "List of customers you manage or follow",
  NO_CUSTOMERS: "There are no customers assigned to you. Please speak to your contact person at DoiT International.",
  EMPTY_STATE_TITLE: "Get quick access to your customers",
  SUBTITLE: `It’s feeling quite empty in here. Search for a customer using the search bar above and begin following them`,
  SUBTITLE2: ", or create a new customer below.",
  PARTNER_SUBTITLE: `It’s feeling quite empty in here. Get in touch with your DoiT contact to request customer access.`,
  CREATE_NEW_CUSTOMER: "Create new customer",
};

export const myTeamsCustomersListTexts = {
  TITLE: "My Team's Customers",
  SWITCH_VIEW: "View my customers",
  SUBHEADER: "A list of customers belonging to my team of Account managers.",
  SUBTITLE:
    "To view the customers of your Account managers in this list, edit their profile and assign yourself as their line manager.",
  EMPTY_STATE_TITLE: "View my team’s customers",
  GO_TO_AM: "Go to Account Managers",
};

export const updateAccountManagerTexts = {
  TITLE: "Please update your account manager details",
  NOT_NOW: "Not now",
  SAVE: "save",
  NAME_REQUIRED: "Name is required",
  NAME: "Name",
  PHOTO_URL: "Photo (url)",
  PHONE_SEARCH_PLACEHOLDER: "Search...",
};

export const setupBillingProfileTexts = {
  TITLE: "Complete your company profile",
  SUB_TITLE: `Set up your company profile to discover Flexsave and advanced Cloud Cost and Usage Analytics. `,
  ACCOUNT_MANAGER: (fsrName) => `If you require any assistance, don't hesitate to get in touch with ${fsrName} at `,
  SUBMIT_BUTTON_TEXT: "Complete setup",
};

export const setupBillingProfileNoPermissionsTexts = {
  TITLE: "Your account is currently being set up",
  SUB_TITLE: "Your company administrator is currently setting up your account. Check back again, soon.",
};

export const dashboardEmptyStateTexts = {
  TITLE: "Using dashboards",
  SUB_TITLE: "Your company profile requires a billing profile before dashboards can be used.",
  GET_STARTED_LINK: "Go to Get Started",
  BILLING_PROFILE_LINK: "Create Billing Profile",
};

export const dashboardEmptyWidgetsTexts = {
  TITLE: "Using widgets",
  SUB_TITLE: "This dashboard has no widgets.",
  ACTION: "Add widgets",
  NOT_ALLOWED: "You are not allowed to add widgets to this dashboard.",
};

export const ssoLoginTexts = {
  ENTER_EMAIL_ADDRESS: "Enter your email address below and we’ll identify your organization/domain for SSO access:",
  SSO_LEARN_MORE: "Learn more about SSO",
};

export const statusesTexts = {
  aws: "AWS Cost and Usage Report",
  gcp: "GCP Billing Export Data",
  lastUpdate: "Data last updated:",
  buttonText: "View details",
  title: "Data status",
  status: "Status:",
  dataLastUpdate: "Data last updated on:",
  content:
    " The time indicated is the last time data was updated in our systems. To learn more, please visit our DoiT Platform documentation.",
  loading: "Loading...",
};

export const ssoTexts = {
  idpEntityId: {
    LABEL: "Entity ID",
    HELPER_TEXT: " ",
    REQUIRED: "Idp entity id is required",
  },
  ssoUrl: {
    LABEL: "SSO URL",
    HELPER_TEXT: " ",
    REQUIRED: "SSO URL is required",
  },
  certificate: {
    LABEL: "Certificate",
    HELPER_TEXT: "Must start with “-----BEGIN CERTIFICATE-----”, and end with “-----END CERTIFICATE-----”",
    REQUIRED: "Certificate is required",
  },
  clientId: {
    LABEL: "Client ID",
    HELPER_TEXT: " ",
    REQUIRED: "Client ID is required",
  },
  issuerUrl: {
    LABEL: "Issuer URL",
    HELPER_TEXT: " ",
    REQUIRED: "Issuer URL is required",
  },
  clientSecret: {
    LABEL: "Client secret",
    HELPER_TEXT: " ",
    REQUIRED: "Client secret is required",
  },

  CERTIFICATE_START_WITH: "-----BEGIN CERTIFICATE-----",
  CERTIFICATE_END_WITH: "-----END CERTIFICATE-----",
  INVALID_URL: "URL is not valid",
  DESCRIPTION: (ssoType) => `Configure using the ${ssoType} protocol`,

  DIALOG_SELECT_TITLE: "SSO protocol change",
  DIALOG_SELECT_DESCRIPTION: (samlEnabled) =>
    `You are about to change from ${samlEnabled ? "SAML to OIDC" : "OIDC to SAML"}. Are you sure you want to do this?`,

  DIALOG_SAVE_CONFIG_TITLE: (type) => `${type.toUpperCase()} configuration`,
  DIALOG_SAVE_CONFIG_DESCRIPTION: (type) =>
    `You are about to create or change the ${type.toUpperCase()} configuration for your account.\n\nSSO will be automatically enabled when you press CONFIRM.`,
  CONFIGURE: "Configure",
  EDIT_CONFIGURATION: "Edit configuration",
  ENABLE_SSO: "Enable SSO",
  SECURITY: "Security",
  SSO_AUTHENTICATION: "Single sign-on",
  ERROR_MESSAGE: (type) =>
    `We were unable to configure and save your ${type.toUpperCase()} protocol. Please try again, or contact support`,
  SUCCESS_SNACKBAR_TEXT: (type) => `SSO protocol changed to ${type.toUpperCase()}`,
  FAILURE_SNACKBAR_TEXT: (type) => `Unable to switch to ${type.toUpperCase()}`,
};

export const ActiveWidgetsListText = {
  serviceAccountButton: "Connect a new organization",
  updateRoleButton: "Update role",
  LearnMore: "Learn more in our troubleshooting guides",
  NewServiceDisableTooltip: "You're unable to create a new service account when testing feature connections",
};

export const authSettingsTexts = {
  TITLE: "Settings",
  SAVE_BUTTON: "Save",
};

export const authProviderTexts = {
  TITLE: "Select allowed auth provider",
  TOOLTIP: "Control which auth providers have access to your tenant",
};

export const authAutoProvisionTexts = {
  TITLE: "Auto provisioning",
  SWITCH_LABEL: "Allow auto provisioning of users",
  SUBTITLE: {
    PART1: "Auto provisioned users will be assigned the",
    DEFAULT_ROLE: "default role.",
    PART2: "You can update your default role in",
    IDENTITY_AND_ACCESS: "Identity & access",
  },
  DOMAINS_SELECTION_TITLE: "Select domains to add to your tenant allowlist",
  DEFAULT_ROLE_TOOLTIP_PREAMBLE: "Default role is",
  TOOLTIP:
    "By allowing auto provisioning of users, you are allowing any domain listed on the allowlist below, to be granted access to your tenant.",
};

export const CloudConnectStatusText = {
  NotConfigured: "Not configured",
  Healthy: "Healthy",
  Unhealthy: "Incomplete",
  Critical: "Critical",
  Partial: "Partial",
};

export const CloudConnectCardText = {
  disableAddServiceTooltip: "You're unable to create a new service account when testing feature connections",
};

export const GCTabAssetsHeaderText = {
  LINK_DIRECT_ACCOUNT: "Link direct account",
  NEW_BILLING_ACCOUNT: "New billing account",
  CONNECT_NEW_ACCOUNT: "Connect new account",
};

export const MPAText = {
  NRA_COMMENT: (
    requestedBy,
    domain,
    features
  ) => `Giri, ${requestedBy} is requesting No Root Access for customer ${domain}.
  The expected MRR is ${features?.["nra-expected-mrr"]} and supporting materials are located at this link: ${features?.["nra-link-to-materials"]}`,
};

export const formulaFieldText = {
  FORMULA_MISSING_VAR: (vars) => `Formula is missing variable(s) ${vars}`,
  INVALID_FORMULA: "Invalid formula",
  NO_OPENING_PARENTHESIS: "Opening parenthesis is missing",
  NO_CLOSING_PARENTHESIS: "Closing parenthesis is missing",
};

export const PlatformText = {
  "google-cloud": "Google Cloud",
  "amazon-web-services": "AWS",
  "g-suite": "Google Workspace",
  "microsoft-azure": "Microsoft Azure",
};

export const PerkTypeText = {
  "customer-success": "Customer success",
  "isv-solution": "ISV solution",
  "pro-serv": "Professional service",
  promotion: "Promotion",
  training: "Training",
  workshop: "Workshop",
  accelerator: "Accelerator",
};

export const PerkSolutionTypeText = {
  analytics: "Analytics",
  "big-data": "Big data",
  "developer-tools": "Developer tools",
  "security-and-monitoring": "Security and monitoring",
  networking: "Networking",
  "ml-and-ai": "ML and AI",
  database: "Database",
  compute: "Compute",
  "blog-and-cms": "Blog and CMS",
  "data-sources": "Data sources",
  "operating-systems": "Operating systems",
  storage: "Storage",
  maps: "Maps",
  "advertising-and-gtm": "Advertising and GTM",
  other: "Other",
};

export const timeText = {
  NEVER: "Never",
};

export const SavePerkText = {
  SAVE_DRAFT_SUCCESS: (type) => `${type} saved as draft.`,
  PUBLISH_SUCCESS: (type) => `${type} successfully published.`,
  UPDATE_SUCCESS: (type) => `${type} successfully updated.`,
};

export const SupportTicketRequirementText = [
  {
    ID: "general",
    TITLE: "Please write in English and make sure to include all relevant technical information, for example:",
    DESCRIPTION: ["Start times and end times", "Observed behaviour", "Error messages, error logs, etc."],
  },
  {
    ID: "gcp/google-kubernetes-engine-gke",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "GKE cluster name",
      "Namespace / workload name",
      "Relevant configuration details  (e.g., Autopilot or Standard, VPC ID, Deployments, Services, Ingresses, etc.)",
      "Error messages and log entries",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/cloud-sql",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for the Cloud SQL instance experiencing an outage, such as:",
    DESCRIPTION: ["Instance ID", "Database / schema / table name", "Failing SQL", "Impacted resource metrics"],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/bigquery",
    TITLE: "Please provide all of the following information relevant to your BigQuery outage:",
    DESCRIPTION: ["Dataset/Table/View resource ID", "Job ID", "Failing SQL", "Error messages and log entries"],
    FOOTER:
      "<strong style='font-weight:500'>Providing access to BigQuery will facilitate faster debugging.</strong><br />" +
      "<strong style='font-weight:500'>On slot reservations</strong>: Please also run grant access to your BigQuery management project to enable reservation structure visibility.<br />" +
      "<strong style='font-weight:500'>On queries that hit multiple projects</strong>: Please also grant access to all projects hit by the queries.<br /><br />" +
      "Our analytics indicate that customers who provide these details and project access will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/compute-engine",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: ["Instance and Disk ID(s)", "OS and OS Version", "Error messages and log entries"],
    FOOTER:
      "Our analytics indicate that customers who provide these details and project access will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "aws/amazon-ec2",
    TITLE:
      "Please provide all resource IDs, AWS URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: ["Instance ID(s) and region", "Auto-scaling group ID", "Recent changes made"],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "aws/amazon-rds",
    TITLE:
      "Please provide all resource IDs, AWS URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "Cluster / instance ID and region",
      "Database / schema / table name",
      "Failing query",
      "Impacted resource metrics",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/cloud-load-balancing",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "Load balancer type, ID, IP, and DNS",
      "Application URL",
      "Error messages, tcpdump, and log entries (upload as files)",
      "Steps/commands to recreate issue",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/cloud-composer",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "Instance name",
      "DAG(s) / Task ID(s) and failed execution timestamp(s)",
      "Errors messages and log entries",
      "Connected VPC ID if relevant",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/identity-and-access-management",
    TITLE:
      "Please provide all of the following information and GCP URLs for IAM resources experiencing issues, such as:",
    DESCRIPTION: [
      "Principal ID",
      "Associated GCP resource ID",
      "Intended vs. actual permissioned access",
      "Error messages and log entries",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details and project access will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "aws/amazon-elastic-kubernetes-service-eks",
    TITLE:
      "Please provide all resource IDs, AWS URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "EKS cluster ID",
      "Namespace / workload name",
      "Relevant configuration details  (e.g., VPC ID, Deployments, Services, Ingresses, etc.)",
      "Error messages and log entries",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/cloud-storage",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: ["Bucket name", "Relevant object path(s)", "Error messages and log entries"],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/app-engine",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "App engine service name + version",
      "Error messages and log entries",
      "Relevant pieces of app.yaml or appengine-web.xml configuration file",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/cloud-functions",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "Function name and runtime version",
      "Error messages and log entries",
      "Intended vs. actual behavior (i.e., premature termination, excessive runtime, etc.)",
      "Connected VPC ID if relevant",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
  {
    ID: "gcp/cloud-run",
    TITLE:
      "Please provide all resource IDs, GCP URLs, and requested details for resources experiencing an outage, such as:",
    DESCRIPTION: [
      "Service name",
      "Error messages and log entries",
      "Container image details",
      "Connected VPC ID if relevant",
    ],
    FOOTER:
      "Our analytics indicate that customers who provide these details will, on average, have their issue resolved in less than half the time when compared to requests that are missing details.",
  },
];

export const labelsText = {
  CREATE_LABEL_SUCCESS: "Label created successfully",
  CREATE_LABEL_ERROR: "Failed to create label",
  UPDATE_LABEL_SUCCESS: "Label updated successfully",
  UPDATE_LABEL_ERROR: "Failed to update label",
  DELETE_LABEL_SUCCESS: "Label deleted successfully",
  DELETE_LABEL_ERROR: "Failed to delete label",
};

export const trialPremiumFeatureCardText = {
  REPORTS_TITLE: "Dive deeper into your cloud costs and usage",
  REPORTS_DETAILS:
    "Reports give you detailed insights and analytics about your cloud usage, costs, and performance to help optimize your resources and make informed decisions about your cloud infrastructure.",

  ATTRIBUTIONS_TITLE: "Track and assign cloud resources",
  ATTRIBUTIONS_DETAIL:
    "Attributions allow you to track and assign cloud resource usage and costs to specific teams, projects, or individuals within your organization, allowing you to perform chargeback and showback for your cloud services.",

  ATTRIBUTION_GROUPS_TITLE: "Organize and categorize cloud resources",
  ATTRIBUTION_GROUPS_DETAIL:
    "Attribution groups allow you to organize and categorize cloud resources based on specific criteria such as project, team, or environment, which helps in tracking usage and costs more effectively.",

  METRICS_TITLE: "Build tailored performance indicators",
  METRICS_DETAIL:
    "When reporting on cost and usage of cloud environments, you may need custom metrics to measure key performance indicators in your organization or business.",
  FLEX_SAVE_TITLE: "Optimize your cloud savings with Flexsave",
  FLEX_SAVE_DETAIL:
    "Flexsave optimizes cloud costs by automatically applying Savings Plans to eligible compute instances, ensuring you maximize savings without the need for upfront commitments or manual configuration.",

  BUDGETS_TITLE: "Monitor your cloud spend and costs",
  BUDGETS_DETAIL:
    "Budgets allow you to monitor your cloud spend and manage costs by setting up custom thresholds and alerts.",

  ALERTS_TITLE: "Get notified about important events",
  ALERTS_DETAIL:
    "Alerts notify you about cost spikes or potential issues within your cloud environment, such as cost overruns, security vulnerabilities, or service disruptions, enabling you to take timely action.",
  SPOT_SCALING_TITLE: "Spot usage without the risk",
  SPOT_SCALING_DETAIL:
    "With DoiT Spot Scaling, automate your AWS Spot Instances to save up to 90% on compute spend without compromising reliability.",
  COST_ANOMALIES_TITLE: "Notifications about anomalous spend",
  COST_ANOMALIES_DETAIL:
    "Cost anomalies identify and alert you about unusual or unexpected spikes in your cloud service usage costs, helping you manage your budget more effectively and avoid overspending.",
};

export const tiersText = {
  UPGRADE_SUBSCRIPTION: (featureName) => `Upgrade your subscription to enable ${featureName}`,
};

const access = (featureName) => (featureName.includes("access") ? featureName : `access ${featureName}`);
export const NoEntitlementsText = {
  TITLE: (inPresentationMode, featureName) =>
    inPresentationMode
      ? "Start your free trial to gain access to this feature"
      : `Upgrade your subscription to ${access(featureName)}`,
  SUBTITLE: (inPresentationMode) =>
    inPresentationMode
      ? "This feature is not available as part of presentation mode. Start a free trial to gain access."
      : "This feature is not available as part of your subscription, upgrade now to gain access.",
  FEATURE_DETAILS: "If you think you should have access to this feature please contact support",
  CONTACT_SUPPORT: "Contact support",
};

export const homeTexts = {
  CLOUD_CONNECT_TITLE: "Connect your cloud",
  CLOUD_CONNECT_CARD_CONTENT: (text) => ` Start using DoiT Cloud Navigator with your ${text} billing data`,
  CLOUD_CONNECT_CARD_TITLE: (text) => `Connect your ${text} account`,
  QUICK_LINKS_TITLE: "Navigate the cloud with confidence",
  FEATURED_CARD_TITLE: "Dive deeper into your cloud costs and usage",
  FEATURED_CARD_TITLE_NO_PERMISSION: "Cloud computing case study collection",
  FEATURED_CARD_CONTENT:
    "Reports give you detailed insights and analytics about your cloud usage, costs, and performance to help optimize your resources and make informed decisions about your cloud infrastructure.",
  FEATURED_CARD_CONTENT_NO_PERMISSION:
    "Discover the possibilities from our collection of cloud case studies that have helped organizations large and small. From cloud migration to cloud cost optimization, DoiT has evolved into a leading cloud solutions provider worldwide.",
  FEATURED_CARD_BUTTON_TEXT: "Create new report",
  FEATURED_CARD_BUTTON_TEXT_NO_PERMISSION: "Discover our collection",
  PACKAGE_CARD_SECTION_TITLE: "DoiT packages available for you",
  CLOUD_INCIDENTS_TITLE: "Stay on top of cloud issues",
  FAQ: "Frequently asked questions",
  ASK_AVA: "Ask Ava",
  FEATURED_CARD_CONTENT_AVA:
    "Reports give you detailed analytics about your cloud usage costs and performance. Try it now or use our AI assistant, Ava, for real-time cost insights, helping you optimize cloud expenses and efficiency.",
};

export const lensEnticementTexts = {
  BQ_LENS_TITLE: "Identify & optimize BigQuery inefficiencies with BigQuery Lens",
  BQ_LENS_LIST: [
    "Fine-tune query performance with actionable recommendations, e.g. Cluster these tables, or switch to flat-rate plan",
    "Understand your team's BigQuery usage in three clicks or less",
    "Ensure that your data is organized in the most cost-efficient way",
  ],
  BQ_LENS_QUOTE:
    '"Thanks to the BigQuery Lens recommendations, we invested time in partitioning our key tables and were able to reduce our BigQuery expenses by 25% a month.”',
  BQ_LENS_QUOTE_AUTHOR: "Daniel Rimon, Head of Data Engineering, Resident",

  EKS_LENS_TITLE: "Get real-time visibility into your EKS spend",
  EKS_LENS_LIST: [
    "Breakdown your EKS spend",
    "View historical cost trends and patterns",
    "Identify underutilized or over provisioned resources to optimize spending",
    "Highlight the top cost drivers",
  ],
  GKE_LENS_TITLE: "Get real-time visibility into your GKE spend",
  GKE_LENS_LIST: [
    "Accurately track the total cost of ownership (TCO) of Kubernetes-based workloads across teams and product lines.",
    "Plan better with realistic Kubernetes-based budgets.",
    "Forecast and identify trends across Kubernetes primitives.",
    "Drill down from clusters to namespaces and apps.",
  ],
  SNOWFLAKE_LENS_TITLE: "Get real-time visibility into your Snowflake usage",
  SNOWFLAKE_LENS_LIST: [
    "Analyze your Snowflake usage patterns",
    "Monitor your historical data trends and cost implications",
    "Identify underutilized resources to optimize performance and cost",
    "Highlight the major cost drivers within your Snowflake environment",
  ],
};
export const cloudflowTexts = {
  CONFIRM_EDIT_TITLE: "Unpublish CloudFlow to edit",
  CONFIRM_EDIT_CONTENT:
    "In order to edit this CloudFlow it will need to be unpublished. Do you want to edit this CloudFlow and unpublish?",
  CONFIRM_UNPUBLISH_TITLE: "Unpublish CloudFlow?",
  CONFIRM_UNPUBLISH_CONTENT:
    "Your CloudFlow will no longer be active and will move back into draft state. Do you want to unpublish this CloudFlow?",
  REGISTER_YOUR_INTEREST: "Register your interest",
  INTEREST_REGISTERED: "Interest registered",
  SUCCESS_REGISTER: "Interest in CloudFlow registered",
  ERROR_REGISTER: "Failed to register interest in CloudFlow",
  COMING_SOON: "Coming in late 2024",
  TITLE: "DoiT CloudFlow",
  ALREADY_REGISTERED: "Your organization has registered interest.",
  FILTER_TABLE: "Filter table",
  YOUR_CLOUDFLOWS: "Your CloudFlows",
  NO_CLOUDFLOWS_FOUND: "No CloudFlows found",
  VIEW_RUN_HISTORY: "View run history",
  UNPUBLISH: "Unpublish",
  PUBLISH: "Publish",
  SAVE: "Save",
  CANCEL: "Cancel",
  CLOSE: "Close",
  EDIT: "Edit",
  EDIT_CLOUDFLOW: "Edit CloudFlow",
  RUN: "Run",
  DELETE_CLOUDFLOW: "Delete CloudFlow",
  CREATE_CLOUDFLOW: "Create CloudFlow",
  CLOUDFLOW_DELETE_DIALOG_TEXT: "This CloudFlow will be permanently deleted and won’t be able to be recovered.",
  CLOUDFLOW: "CloudFlow",
  DASHBOARD_SUBHEADER: "Build automated cloud processes",
  TEMPLATES_ROW_TITLE: "Get started with a CloudFlow template",
  VIEW_ALL_TEMPLATES: "View all templates",
  TABLE_HEADERS: {
    ID: "ID",
    NAME: "CloudFlow name",
    PUBLISHED_STATUS: "Published status",
    OWNER: "Owner",
    LAST_COMPLETED: "Last completed",
  },
  HISTORY: {
    NOT_FOUND: "No CloudFlow run history found",
    COMPLETED: "CloudFlow run history",
    TABLE_HEADERS: {
      INSTANCE: "CloudFlow name",
      STATUS: "Status",
      TRIGGERED_BY: "Trigger",
      TIME_COMPLETED: "Time completed",
    },
  },
  TEMPLATES: {
    PAGE_TITLE: "CloudFlow templates",
  },
  CREATE_NEW_CLOUDFLOW: "Create new CloudFlow",
  CLOUDFLOW_NAME: "CloudFlow name",
  WHAT_KIND_OF_CLOUDFLOW: "What kind of CloudFlow do you want to create?",
  LEARN_HOW_TO_BUILD: "Learn how to build a CloudFlow / Examples",
  SELECT_YOUR_TEMPLATE: "Select your template",
  SEARCH_TEMPLATES: "Search templates",
  CREATE_CLOUDFLOW_FAILED: "Failed to create CloudFlow",
  CLOUDFLOW_NAME_REQUIRED: "Enter a name to create a CloudFlow",
  TEMPLATE_REQUIRED: "Select a template to create a CloudFlow",
  CLOUDFLOW_NAME_REQUIRED_AND_TEMPLATE_REQUIRED: "Enter a name and select a template to create a CloudFlow",
  CUSTOM_SCHEDULE: "Custom schedule",
  MANUALLY_START: "Manually start",

  PERFORM_ACTION: "Perform an action",
  PERFORM_ACTION_DESCRIPTION: "Search for actions from AWS, Google Cloud, and DoiT",
  FILTER_RESULTS: "Filter results",
  FILTER_RESULTS_DESCRIPTION: "Control the data that moves through a CloudFlow",
  IF_STATEMENT: "IF statement",
  IF_STATEMENT_DESCRIPTION: "Funnel data down a defined path",
  TRANSFORM_DATA: "Transform data",
  TRANSFORM_DATA_DESCRIPTION: "Change or manipulate data passing through a CloudFlow",

  CREATE_NEW_NODE: {
    SELECT_PRODUCT: "Select a product",
    SELECT_PRODUCT_LABEL: "Product",
    SELECT_SERVICE: "Select a service",
    SELECT_SERVICE_LABEL: "Service",
    SELECT_ACTION: "Select an action",
    SELECT_ACTION_LABEL: "Action",
  },
  DELETE_IF_STATEMENT: "Delete IF statement",
  DELETE_IF_NODE_DIALOG_CONTENT:
    "Deleting an IF statement will remove all actions which follow in the True and False paths. Do you still want to delete?",
  MANAGE_IF_NODE_DIALOG_TITLE: "Manage existing actions",
  MANAGE_IF_NODE_DIALOG_CONTENT:
    "As you already have actions added to your CloudFlow you need to define where to move these after you add an IF statement",
  SWITCH_DATA_SOURCE_TITLE: "Change data source",
  SWITCH_DATA_SOURCE_CONTENT:
    "Changing your data source will remove any configuration or filters you have assigned. Do you want to change your data source?",
  SWITCH_DATA_SOURCE_CANCEL: "Cancel",
  SWITCH_DATA_SOURCE_CONFIRM: "Change data source",
  SELECT_DATA_SOURCE: "Select the data source",
  FROM_CHOOSEN_FIELD_AND_FILTER: "From the chosen field add filter criteria",
  DEFINE_FILTER_CRITERIA: "Define the filter criteria",
  PICK_STEP_TO_FILTER: "Control which data moves through the flow",
  PICK_STEP_TO_USE: "Pick which step you want to use data from ",
  DATA_SOURCES: "Data source",
  FILTER_GROUP: "Filter group",
  CRITERIA_GROUP: "Criteria group",
  ADD_NEW_FILTER: "Add new filter",
  ADD_NEW_FILTER_GROUP: "Add new filter group",
  ADD_NEW_CRITERIA: "Add new criteria",
  ADD_NEW_CRITERIA_GROUP: "Add new criteria group",
  DEFINE_TRUE_PATH_CRITERIA: "Define the 'true' path criteria",
  DEFINE_TRUE_PATH_CRITERIA_DESCRIPTION:
    "Any data which does not meet the ‘True’ criteria will move down the ‘False’ path",
  SELECT_FIELD_TO_FILTER: "Select which field you want to filter",
  SELECT_FIELD_TO_DEFINE_CRITERIA: "Select which field you want to define criteria for",
  FROM_CHOSEN_STEP: "From the chosen step pick which field you want to filter",
  DEFINE_FILTER_INPUT: "Define the filter using an input or a value",

  TRANSFORM_TITLE: "Transform",
  TRANSFORM_DESCRIPTION: "Change the structure of some data",
  PICK_STEP_TO_TRANSFORM: "Pick which step you want to transform data from",
  SELECT_FIELD_TO_TRANSFORM: "Select which field you want to transform",
  FROM_CHOSEN_STEP_TO_TRANSFORM: "From the chosen step pick which field you want to transform",
  DEFINE_TRANSFORM_ACTION: "Define the transform action",
  ADD_TRANSFORM_ACTION: "From the chosen field add the action you want to take place",
  TRANSFORM_ACTION_LABEL: "Transform action",

  OPERATOR: "Operator",
  VALUE: "Value",
  ADD_FILTER: "Add filter",
  EDIT_FILTER: "Edit filter",
  FIELD_REFERENCE_REQUIRED: "Field reference is required",
  DATA_SOURCE_REQUIRED: "Data source is required",
  REFERENCED_FIELD: "Referenced field",
  FIELD: "Field",
  OPERATOR_REQUIRED: "Operator is required",
  SELECT_VALUE_FROM_REFERENCE_FIELD: 'Select a value from the "Referenced field" input first',
  ADD_ACTION_NODES: "To select a data source add an action node before the filter node",
  SELECT_VALUE_FROM_DATA_SOURCE: 'Select a value from the "Data source" input first',
  OR: "OR",
  AND: "AND",
  ADD_NEW_STEP: "Add new step",
};
