import { useState } from "react";

import { type CloudFlowNodeType, NodeTransformationType } from "@doitintl/cmp-models";
import TransformIcon from "@mui/icons-material/Transform";
import {
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../../../../assets/texts";
import FieldReferenceForm from "../../FilterAction/FieldReferenceForm";
import SwitchDataSourceDialog from "../../FilterAction/SwitchDataSourceDialog";
import { useNodeDataSourceUpdate } from "../hooks";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const TransformParametersTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.TRANSFORMATION>();

  const {
    selectedNode,
    setSelectedNode,
    referenceableNodes,
    switchDataSourceOpen,
    handleConfirm,
    handleClose,
    confirmSwitchDataSource,
  } = useNodeDataSourceUpdate({
    nodeId: nodeConfig.id,
    referencedNodeId: nodeConfig.parameters.referencedNodeId,
  });

  const [selectedType, setSelectedType] = useState<NodeTransformationType | "">("");

  const handleChange = (event: SelectChangeEvent<NodeTransformationType>) => {
    setSelectedType(event.target.value as NodeTransformationType);
  };

  return (
    <Stack px={2} justifyContent="center" gap={2} mt={1}>
      <Card>
        <CardHeader
          avatar={<TransformIcon color="primary" fontSize="small" />}
          subheader={cloudflowTexts.TRANSFORM_DESCRIPTION}
          title={cloudflowTexts.TRANSFORM_TITLE}
          titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
          subheaderTypographyProps={{ variant: "caption" }}
          sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
        />
      </Card>
      <FieldReferenceForm
        selectedNode={selectedNode}
        confirmSwitchDataSource={confirmSwitchDataSource}
        referenceableNodes={referenceableNodes}
        setSelectedNode={setSelectedNode}
        texts={{
          datasource: {
            title: cloudflowTexts.SELECT_DATA_SOURCE,
            subtitle: cloudflowTexts.PICK_STEP_TO_TRANSFORM,
            label: cloudflowTexts.DATA_SOURCES,
          },
          referenceField: {
            title: cloudflowTexts.SELECT_FIELD_TO_TRANSFORM,
            subtitle: cloudflowTexts.FROM_CHOSEN_STEP_TO_TRANSFORM,
            label: cloudflowTexts.REFERENCED_FIELD,
            placeholderTooltip: cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE,
          },
        }}
      />
      <Stack mb={3}>
        <Stack mb={1.5}>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {cloudflowTexts.DEFINE_TRANSFORM_ACTION}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cloudflowTexts.ADD_TRANSFORM_ACTION}
          </Typography>
        </Stack>
        <FormControl sx={{ minWidth: 300 }} required>
          <InputLabel size="small" id="transformActionLabel">
            {cloudflowTexts.TRANSFORM_ACTION_LABEL}
          </InputLabel>
          {/* TODO: implement the inputs depending on the selected action */}
          <Select
            input={<OutlinedInput size="small" label={cloudflowTexts.TRANSFORM_ACTION_LABEL} />}
            labelId="transformActionLabel"
            required
            disabled={referenceableNodes.length === 0}
            name={cloudflowTexts.TRANSFORM_ACTION_LABEL}
            value={selectedType}
            onChange={handleChange}
            onBlur={noop}
          >
            {Object.values(NodeTransformationType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <SwitchDataSourceDialog
        isDialogOpened={switchDataSourceOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Stack>
  );
};

export default TransformParametersTab;
