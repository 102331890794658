import { useCallback } from "react";

import { useApiContext } from "../../api/context";
import { uploadPLEsHandler } from "./api";
import { type UploadPLESRequest } from "./types";

export const useUploadPLES = () => {
  const api = useApiContext();

  return useCallback(
    async (req: UploadPLESRequest, onSuccess: () => void, onError: (error: any) => void) => {
      try {
        await uploadPLEsHandler({ api, req });
        onSuccess();
      } catch (error) {
        onError(error);
      }
    },
    [api]
  );
};
