import { makeStyles } from "@mui/styles";

export const ActiveWidgetsListStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    alignItems: "center",
    flex: 1,
  },
  circle: {
    padding: theme.spacing(2),
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 3,
    backgroundColor: "gray",
    marginLeft: 6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  summary: {
    alignItems: "center",
    display: "flex",
  },
  accordionSummary: {
    margin: 0,
  },
  accordionSummaryCategory: {
    margin: 0,
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "flex-start",
  },
  accordionSummaryBadge: {
    display: "flex",
    flexDirection: "row",
    width: "160px",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "flex-start",
    padding: 0,
  },
  checkboxCategory: {
    display: "flex",
    flexDirection: "row",
    flex: 7,
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "flex-start",
  },
  expandIconBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    alignContent: "center",
  },
  accordionSummaryReadOnly: {
    userSelect: "auto",
    cursor: "auto",
    margin: 0,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  accordionSumRoot: {
    userSelect: "auto",
    cursor: "auto",
    margin: 0,
  },
  accordionSumContent: {
    display: "flex",
    justifyContent: "flex-start",
    flex: 1,
  },
  statusBadgeText: {
    color: "white",
    fontSize: "0.62rem",
  },
  cardHeader: {
    paddingBottom: theme.spacing(2),
    height: theme.spacing(8),
  },
  testConnectionButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
}));
