import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { ReportDimensionsContextProvider } from "../Context";
import ReportTemplatesBrowser from "./ReportTemplatesBrowser";
import { type ReportTemplateCard } from "./types";

type Props = {
  reportTemplateCards: ReportTemplateCard[];
};

const ReportTemplateWrapper = ({ reportTemplateCards }: Props) => {
  const { attributions } = useAttributionsContext();

  return (
    <ReportDimensionsContextProvider attributions={attributions}>
      <ReportTemplatesBrowser reportTemplateCards={reportTemplateCards} />
    </ReportDimensionsContextProvider>
  );
};

export default ReportTemplateWrapper;
