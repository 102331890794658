import { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";

import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import { formatDecimalNumber } from "../../../utils/common";
import { type SavingsPeriod } from "../types";

export const PotentialSavingsPreview = ({
  savings,
  savingsPeriod,
}: {
  savings: number;
  savingsPeriod: SavingsPeriod;
}) => {
  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();

  const [convertedAndFormatted, setConvertedAndFormatted] = useState<string>();

  useEffect(() => {
    asyncConvertCurrency(savings).then((convertedSavings) => {
      setConvertedAndFormatted(`${customerCurrencySymbol}${formatDecimalNumber(convertedSavings, 0)}`);
    });
  }, [asyncConvertCurrency, customerCurrencySymbol, savings]);

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Typography variant="h1" color="success.main" fontSize={36} lineHeight={44 / 36}>
        {convertedAndFormatted}
      </Typography>
      <Typography variant="subtitle2" maxWidth={130} fontSize={12} lineHeight={16 / 12}>
        Estimated potential {savingsPeriod} savings
      </Typography>
    </Stack>
  );
};
