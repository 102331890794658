import { type ReactNode, useMemo, useState } from "react";

import { type QuickLinkDescriptorModel } from "@doitintl/cmp-models";
import { Grid, Skeleton } from "@mui/material";

import { homeTexts } from "../../assets/texts";
import { QuickLinkCard } from "../../Components/Dashboard/QuickLinks/QuickLinkCard";
import GuidedExperienceDialog from "../../Pages/CloudAnalytics/guidedExperience/GuidedExperienceDialog";
import SectionHeader from "./SectionHeader";

type QuickLinksProps = {
  quickLinks?: Readonly<Array<Omit<QuickLinkDescriptorModel, "permissions" | "sortOrder">>>;
  quickLinksLoaded: boolean;
  children?: ReactNode;
};

export const QuickLinks = ({ quickLinks = [], quickLinksLoaded = false, children }: QuickLinksProps) => {
  const [isGuidedExperienceDialogOpened, setIsGuidedExperienceDialogOpened] = useState(false);
  const gridItemSize = useMemo(() => {
    if (!children)
      return {
        quickLinksSectionSize: 12,
        featureCardSize: 0,
        quickLinksSize: 3,
      };
    if (quickLinks?.length && quickLinks?.length < 3)
      return {
        quickLinksSectionSize: 4,
        featureCardSize: 8,
        quickLinksSize: 12,
      };
    return {
      quickLinksSectionSize: 6,
      featureCardSize: 6,
      quickLinksSize: 6,
    };
  }, [children, quickLinks?.length]);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <SectionHeader text={homeTexts.QUICK_LINKS_TITLE} />
      </Grid>
      <Grid item xs={12} container spacing={2} direction="row-reverse">
        <Grid item container xs={12} md={gridItemSize.featureCardSize}>
          {children}
        </Grid>
        <Grid item container spacing={2} xs={12} md={gridItemSize.quickLinksSectionSize}>
          {quickLinksLoaded ? (
            quickLinks.map((quickLink) => (
              <Grid item xs={12} md={gridItemSize.quickLinksSize} key={quickLink.name}>
                <QuickLinkCard
                  quickLink={quickLink}
                  setIsGuidedExperienceDialogOpened={setIsGuidedExperienceDialogOpened}
                />
              </Grid>
            ))
          ) : (
            <Grid item height="100%" width="100%">
              <Skeleton component="div" variant="rectangular" height="100%" width="100%" />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isGuidedExperienceDialogOpened && (
        <GuidedExperienceDialog
          onClose={() => {
            setIsGuidedExperienceDialogOpened(false);
          }}
          origin="quicklinks"
        />
      )}
    </Grid>
  );
};
