import round from "lodash/round";
import sumBy from "lodash/sumBy";
import { DateTime } from "luxon";

import {
  type AsgActualAndPotentialCosts,
  type AsgInstancesUsage,
  type AsgTableItem,
  SavingsTimeFilter,
  type SpendingSummary,
} from "../../types";
import { getUsageKeyByIndex } from "../../Utils/datesUtils";

export type SavingsSummary = {
  savings: number;
  savingsRatio: number;
};

/**
 * Get all costs of Asg by specific time filter
 * @param costsSavingsHistory - all savings
 * @param timeFilter
 */
function getSavingsByTimeFilter(
  costsSavingsHistory: Record<string, AsgActualAndPotentialCosts>,
  timeFilter: SavingsTimeFilter
) {
  let costSavings: AsgActualAndPotentialCosts[] = [];
  const startDate = DateTime.local();
  const history = (monthIndex: number) => costsSavingsHistory[getUsageKeyByIndex(startDate, monthIndex)];
  switch (timeFilter) {
    case SavingsTimeFilter.CurrentMonth:
      costSavings = [history(0)];
      break;
    case SavingsTimeFilter.LastMonth:
      costSavings = [history(1)];
      break;
    case SavingsTimeFilter.LastThreeMonths:
      costSavings = [history(1), history(2), history(3)];
      break;
    case SavingsTimeFilter.AllTime:
      costSavings = Object.values(costsSavingsHistory);
      break;
  }

  return costSavings;
}

/**
 * Get all costs of Asg by specific time filter
 * @param spotSpendingHistory - spotSpendingHistory
 * @param odSpendingHistory - odSpendingHistory
 * @param timeFilter
 */
function getSpendingByTimeFilter(
  spotSpendingHistory: Record<string, AsgInstancesUsage>,
  odSpendingHistory: Record<string, AsgInstancesUsage>,
  timeFilter: SavingsTimeFilter
) {
  let odSpending: AsgInstancesUsage[] = [];
  let spotSpending: AsgInstancesUsage[] = [];
  const startDate = DateTime.local();
  const spotHistory = (monthIndex: number) => spotSpendingHistory[getUsageKeyByIndex(startDate, monthIndex)];
  const odHistory = (monthIndex: number) => odSpendingHistory[getUsageKeyByIndex(startDate, monthIndex)];
  switch (timeFilter) {
    case SavingsTimeFilter.CurrentMonth:
      odSpending = [odHistory(0)];
      spotSpending = [spotHistory(0)];
      break;
    case SavingsTimeFilter.LastMonth:
      odSpending = [odHistory(1)];
      spotSpending = [spotHistory(1)];
      break;
    case SavingsTimeFilter.LastThreeMonths:
      odSpending = [odHistory(1), odHistory(2), odHistory(3)];
      spotSpending = [spotHistory(1), spotHistory(2), spotHistory(3)];
      break;
    case SavingsTimeFilter.AllTime:
      odSpending = Object.values(odSpendingHistory);
      spotSpending = Object.values(spotSpendingHistory);
      break;
  }

  return { odSpending, spotSpending };
}

/**
 * Calculate ration and savings from costs
 * @param costsHistory
 */
export function getSavingsSummary(costsHistory: AsgActualAndPotentialCosts[]): SavingsSummary {
  const totalCostIfUsingOnlyOnDemand = sumBy(costsHistory, "costIfUsingOnlyOnDemand");
  const totalActualCost = sumBy(costsHistory, "actualCost");
  const savings = totalCostIfUsingOnlyOnDemand > totalActualCost ? totalCostIfUsingOnlyOnDemand - totalActualCost : 0;
  const savingsRatio =
    totalCostIfUsingOnlyOnDemand && totalActualCost < totalCostIfUsingOnlyOnDemand
      ? round(100 - (totalActualCost / totalCostIfUsingOnlyOnDemand) * 100, 2)
      : 0;
  return {
    savings,
    savingsRatio,
  };
}

export function getSpendingSummary(spendingHistory: AsgInstancesUsage[]): number {
  return sumBy(spendingHistory, "totalCost");
}

/**
 * Calculate the savings of ASGs
 * @param asgs
 * @param timeFilter
 */
export function calculateSavings(asgs: AsgTableItem[], timeFilter: SavingsTimeFilter): SavingsSummary {
  const allSavings = asgs.flatMap((asg) => getSavingsByTimeFilter(asg.asgItem.costsSavingsHistory, timeFilter));
  return getSavingsSummary(allSavings);
}

export function calculateSpending(asgs: AsgTableItem[], timeFilter: SavingsTimeFilter): SpendingSummary {
  let totalSpotSpending = 0;
  let totalSpotHours = 0;
  let totalOdSpending = 0;
  let totalOdHours = 0;
  asgs.forEach((asg) => {
    const { odSpending, spotSpending } = getSpendingByTimeFilter(
      asg.asgItem.spotSpendingHistory,
      asg.asgItem.odSpendingHistory,
      timeFilter
    );
    const spotSubTotalCost = sumBy(spotSpending, "totalCost");
    const spotSubTotalHours = sumBy(spotSpending, "totalHours");
    const odSubTotalCost = sumBy(odSpending, "totalCost");
    const odSubTotalHours = sumBy(odSpending, "totalHours");

    totalSpotSpending += spotSubTotalCost ?? 0;
    totalSpotHours += spotSubTotalHours ?? 0;
    totalOdSpending += odSubTotalCost ?? 0;
    totalOdHours += odSubTotalHours ?? 0;
  });
  return { totalSpotSpending, totalSpotHours, totalOdSpending, totalOdHours };
}

export function groupCostsSavingsHistoryByTimeFilter(
  costsSavingsHistory: Record<string, AsgActualAndPotentialCosts>
): Record<SavingsTimeFilter, number> {
  const savings = {};
  for (const filterValue of Object.values(SavingsTimeFilter)) {
    const allSavings = getSavingsByTimeFilter(costsSavingsHistory, filterValue);
    savings[filterValue] = round(getSavingsSummary(allSavings).savings, 2);
  }

  return savings as Record<SavingsTimeFilter, number>;
}

export function groupSpendingHistoryByTimeFilter(
  spotSpendingHistory: Record<string, AsgInstancesUsage>,
  odSpendingHistory: Record<string, AsgInstancesUsage>
) {
  const spotSpendingByTimeFilter = {};
  const odSpendingByTimeFilter = {};
  for (const filterValue of Object.values(SavingsTimeFilter)) {
    const { spotSpending, odSpending } = getSpendingByTimeFilter(spotSpendingHistory, odSpendingHistory, filterValue);
    spotSpendingByTimeFilter[filterValue] = round(getSpendingSummary(spotSpending), 2);
    odSpendingByTimeFilter[filterValue] = round(getSpendingSummary(odSpending), 2);
  }

  return { spotSpendingByTimeFilter, odSpendingByTimeFilter };
}

export function verifyNumber(val: number) {
  return isNaN(val) ? 0 : val;
}
