import { type DataColumn, type DefaultFilterColumn } from "../../../types/FilterTable";
import { platformsToPlatformsLabelMap } from "../consts";
import { type KnownIssue, type KnownIssuePlatform, KnownIssueStatus, KnownIssueStatusLabel } from "../types";

export const statusColumn: DataColumn<KnownIssue> = {
  label: "Status",
  path: "status",
  toOption: (status: KnownIssueStatus) => ({
    label: status === KnownIssueStatus.Ongoing ? KnownIssueStatusLabel.Ongoing : KnownIssueStatusLabel.Archived,
    value: status,
  }),
} as const;

export const platformColumn: DataColumn<KnownIssue> = {
  label: "Platform",
  path: "platform",
  toOption: (platform: KnownIssuePlatform) => ({
    label: platformsToPlatformsLabelMap[platform],
    value: platform,
  }),
} as const;

export const productColumn: DataColumn<KnownIssue> = {
  label: "Product",
  path: "products",
  toOption: (products: string[]) =>
    products?.map((p) => ({
      label: p,
      value: p,
    })),
} as const;

export const titleColumn: DataColumn<KnownIssue> = {
  label: "Title",
  path: "title",
} as const;

export const onlyOngoingKnownIssuesFilter: DefaultFilterColumn<KnownIssue> = {
  column: statusColumn,
  label: KnownIssueStatusLabel.Ongoing,
  value: KnownIssueStatus.Ongoing,
} as const;
