import { Fragment, type JSX } from "react";

import { Box, Icon, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

import { visuallyHiddenStyle } from "../../../../constants/common";
import { ThemeModes } from "../../../../muiThemeTypes";

type Props = {
  keys: JSX.Element[];
};

const ShortcutKeySet = ({ keys }: Props) => {
  const theme = useTheme();
  const keyStyle = {
    backgroundColor: theme.palette.mode === ThemeModes.DARK ? grey[600] : grey[300],
    m: 1,
    borderRadius: 2,
    boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} -${theme.spacing(0.5)} ${theme.palette.grey[400]}`,
  };
  const longKeyStyle = {
    ...keyStyle,
    width: "auto",
  };

  /*
   * Provides a word-based description of the key.
   *
   * Accessibility note:
   * If the key is an icon, it is essential that the key have an aria-label attribute otherwise the description
   * will return "undefined", resulting in an unpleasant experience for visually impaired users.
   */
  const keyDescription = (key: JSX.Element): string => {
    const { children } = key.props;
    return typeof children === "string" ? children : key.props["aria-label"];
  };

  /*
   * Keyboard keys that involve more than one character such as "shift" or "return" will need different styling.
   */
  const isVerbose = (key: JSX.Element): boolean => keyDescription(key).length > 1;

  const description = keys
    .map((key) => keyDescription(key))
    .reduce((descString, keyDesc) => `${descString}, ${keyDesc}`);

  return (
    <Box>
      <Typography sx={visuallyHiddenStyle}>{description}</Typography>
      {keys.map((key, i) => (
        <Fragment key={i}>
          <Icon fontSize="small" sx={isVerbose(key) ? longKeyStyle : keyStyle} key={key.key}>
            {key}
          </Icon>
        </Fragment>
      ))}
    </Box>
  );
};

export default ShortcutKeySet;
