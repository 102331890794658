import { defaultHeaderStyle } from "../../../../../Components/FilterTable/Table/FilterTableHeader";
import { type DataColumn, type HeaderColumn } from "../../../../../types/FilterTable";
import { threeDotHeaderSection } from "../../../assetUtils";

const idColumn = {
  label: "ID",
  path: "id",
  comparators: ["==", "!="],
  toOption: (value) => ({ value, label: value }),
} as const;
const projectColumn = {
  label: "Project",
  path: "project",
  comparators: ["==", "!="],
  toOption: (value) => ({ value, label: value }),
} as const;
const datasetColumn = {
  label: "Dataset",
  path: "dataset",
  comparators: ["==", "!="],
  toOption: (value) => ({ value, label: value }),
} as const;
const statusColumn = {
  label: "Status",
  path: "status",
  comparators: ["==", "!="],
  toOption: (value) => ({ value, label: value }),
} as const;

const idHeader = {
  value: idColumn.path,
  label: idColumn.label,
  style: defaultHeaderStyle,
};
const projectHeader = {
  value: projectColumn.path,
  label: projectColumn.label,
  style: defaultHeaderStyle,
};
const datasetHeader = {
  value: datasetColumn.path,
  label: datasetColumn.label,
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};
const statusHeader = {
  value: statusColumn.path,
  label: statusColumn.label,
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};

export const gcpDirectColumns: readonly DataColumn[] = [idColumn, projectColumn, datasetColumn, statusColumn];

export const gcpDirectHeaders: readonly HeaderColumn[] = [
  idHeader,
  projectHeader,
  datasetHeader,
  statusHeader,
  threeDotHeaderSection,
];
