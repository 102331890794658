import { useEffect } from "react";

import { type NotificationSettings } from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";
import { useFormikContext } from "formik";

type Props = {
  onNotificationSettingsChange: (notificationSettings: ModelData<NotificationSettings>) => void;
};

export const NotificationsFormObserver = ({ onNotificationSettingsChange }: Props) => {
  const { values } = useFormikContext<ModelData<NotificationSettings>>();

  useEffect(() => {
    onNotificationSettingsChange?.(values);
  }, [onNotificationSettingsChange, values]);

  return null;
};
