import { type Dispatch } from "react";

import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Checkbox, Divider, FormControlLabel, Link, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";

import { loginTexts } from "../../../assets/texts";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import LoadingButton from "../../../Components/LoadingButton";
import mixpanel from "../../../utils/mixpanel";
import { useConsoleSignupSchema } from "../helpers";
import { useConsoleSignUpForm } from "../hooks";
import { type LoginHookAction, type LoginState } from "../types";
import AuthProviders from "./AuthProviders";
import { AuthProvidersPolicyDialog } from "./AuthProvidersPolicyDialog";
import Disclosure from "./Disclosure";

const ConsoleSignupForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const {
    title,
    policyAgreementActionRef,
    policyAgreementRef,
    signupCallback: submitHandler,
    policyAgreementCallback,
    isAuthProvidersPolicyDialogOpen,
    setIsAuthProvidersPolicyDialogOpen,
    clickedAuthProvider,
    setClickedAuthProvider,
  } = useConsoleSignUpForm({ dispatch });
  useMountEffect(() => {
    mixpanel.track("signup.view");
  });
  const { t } = useTranslation("login");
  const ConsoleSignupSchema = useConsoleSignupSchema();

  return (
    <>
      <Typography variant="h1" alignSelf="flex-start">
        {title}
      </Typography>
      <Formik
        validateOnMount
        validationSchema={ConsoleSignupSchema}
        onSubmit={submitHandler}
        initialValues={{
          email: "",
          lastName: "",
          firstName: "",
          policyAgreement: false,
        }}
      >
        {({ isValid, errors, isSubmitting, values, handleChange, handleBlur, touched }) => (
          <Form>
            <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
              <AuthProviders
                loading={state.isLoading}
                customHandler={policyAgreementCallback(!values.policyAgreement)}
                type="signup"
                setClickedAuthProvider={setClickedAuthProvider}
              />
              <Divider>
                <Typography variant="caption" align="center">
                  {t("caption.signup")}
                </Typography>
              </Divider>
            </Stack>

            <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
              <Stack direction="row" spacing={2}>
                <TextField
                  data-cy="firstName-input"
                  autoFocus
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  error={!clickedAuthProvider && touched.firstName && Boolean(errors.firstName)}
                  onBlur={handleBlur}
                  helperText={touched.firstName && errors.firstName ? errors.firstName : " "}
                  label={t("signup.firstName.label")}
                  type="text"
                  name="firstName"
                  autoComplete="firstName"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  disabled={state.isLoading}
                />
                <TextField
                  data-cy="lastName-input"
                  required
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName ? errors.lastName : " "}
                  label={t("signup.lastName.label")}
                  type="text"
                  name="lastName"
                  autoComplete="lastName"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  disabled={state.isLoading}
                />
              </Stack>

              <TextField
                data-cy="email-input"
                required
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email ? errors.email : " "}
                label={t("login.email.label")}
                type="email"
                name="email"
                autoComplete="email"
                margin="none"
                variant="outlined"
                fullWidth
                disabled={state.isLoading}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!values.policyAgreement}
                    value={values.policyAgreement}
                    onChange={(e, checked) => {
                      setIsAuthProvidersPolicyDialogOpen(!checked);
                      handleChange(e);
                    }}
                    name="policyAgreement"
                    size="small"
                    sx={{ ml: -1.5 }}
                    action={policyAgreementActionRef}
                    inputRef={policyAgreementRef}
                  />
                }
                label={
                  <Typography>
                    {`${t("signup.agree")} `}
                    <Link
                      display="inline-flex"
                      alignItems="center"
                      type="button"
                      underline="none"
                      href={loginTexts.NAVIGATOR_AGREEMENT_POLICY_URL}
                      target="_blank"
                    >
                      {t("signup.terms")}
                      <OpenInNewIcon sx={{ fontSize: 16, marginLeft: 1 }} />
                    </Link>
                  </Typography>
                }
              />
              <LoadingButton
                data-cy="login-cta"
                variant="contained"
                fullWidth
                type="submit"
                loading={state.isLoading}
                disabled={isSubmitting || state.isLoading || !isValid}
                mixpanelEventId="login.sass-console-signup.create"
              >
                {t("signup.createAccount")}
              </LoadingButton>
            </Stack>
            {isAuthProvidersPolicyDialogOpen && clickedAuthProvider && (
              <AuthProvidersPolicyDialog
                clickedAuthProvider={clickedAuthProvider}
                isAuthProvidersPolicyDialogOpen={isAuthProvidersPolicyDialogOpen}
                setIsAuthProvidersPolicyDialogOpen={setIsAuthProvidersPolicyDialogOpen}
                onAuthProvidersPolicyCheckboxChange={handleChange}
                policyAgreementActionRef={policyAgreementActionRef}
                policyAgreementRef={policyAgreementRef}
                isPolicyAgreed={!!values.policyAgreement}
              />
            )}
          </Form>
        )}
      </Formik>
      <Stack spacing={2}>
        <Disclosure />
      </Stack>
    </>
  );
};

export default ConsoleSignupForm;
