import { type CustomerModelOrganizationModel as Organization } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { httpMethods } from "../../../assets/texts";
import { serverTimestamp } from "../../../utils/firebase";

export const updateOrgLastAccessed = (orgRef: ModelReference<Organization>) =>
  orgRef.update({
    lastAccessed: serverTimestamp(),
  });

export const updateDashboardWidgets = (api: AxiosInstance, customerId: string, orgId?: string): Promise<any> => {
  let uri = `/v1/customers/${customerId}/analytics/widgets`;
  if (orgId) {
    uri += `?orgId=${orgId}`;
  }
  return api.request({
    method: httpMethods.POST,
    url: uri,
  });
};

export const deleteOrgs = async (api: AxiosInstance, customerId: string, orgIds: string[]) => {
  const payload = {
    orgIds,
  };
  await api.request({
    method: "DELETE",
    url: `/v1/customers/${customerId}/iam/orgs`,
    data: payload,
  });
};
