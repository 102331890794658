import { useCallback, useMemo, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";
import sumBy from "lodash/sumBy";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { Loader } from "../../../../../Components/Loader";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useFlexsaveOpsApi } from "../../../GCP/hooks";
import { PurchaseConfirmationDialog } from "../Common/PurchaseConfirmationDialog";
import { RejectPurchaseConfirmationDialog } from "../Common/RejectPurchaseConfirmationDialog";
import { approvePurchasePlan, rejectPurchasePlan } from "../db";
import { type ApprovalsItem } from "../types";
import { groupWorkloadsByCustomer } from "../utils/workloadUtils";
import { ApprovalsRow } from "./AprovalsRow";
import { defaultFilters, filters, headerColumns } from "./columns";

type Props = {
  approvalItems: ApprovalsItem[];
  isBulkUpdateRequired: boolean;
};

const Approvals = ({ approvalItems, isBulkUpdateRequired }: Props) => {
  const isFlexsaveAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);
  const flexSaveOpsApi = useFlexsaveOpsApi("v2");
  const [selectedPurchaseItems, setSelectedPurchaseItems] = useState<ApprovalsItem[]>([]);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState<boolean>(false);
  const [individualPurchaseSelect, setIndividualPurchaseSelect] = useState<boolean>(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const handleApproveDialogClose = useCallback(() => {
    setApproveDialogOpen(false);
    if (individualPurchaseSelect) {
      setSelectedPurchaseItems([]);
    }
  }, [individualPurchaseSelect]);

  const handleRejectDialogClose = useCallback(() => {
    setRejectDialogOpen(false);
    if (individualPurchaseSelect) {
      setSelectedPurchaseItems([]);
    }
  }, [individualPurchaseSelect]);

  const handlePurchaseButtonClick = useCallback(() => {
    setApproveDialogOpen(true);
  }, []);

  const handlePurchaseOptionClick = useCallback((selectedItem: ApprovalsItem) => {
    setIndividualPurchaseSelect(true);
    setSelectedPurchaseItems([selectedItem]);
    setApproveDialogOpen(true);
  }, []);

  const handleRejectOptionClick = useCallback((selectedItem: ApprovalsItem) => {
    setIndividualPurchaseSelect(true);
    setSelectedPurchaseItems([selectedItem]);
    setRejectDialogOpen(true);
  }, []);

  const ApprovalsRowWrapper = useCallback(
    (props: { row: ApprovalsItem }) => (
      <ApprovalsRow
        row={props.row}
        onPurchaseButtonClick={handlePurchaseOptionClick}
        onRejectButtonClick={handleRejectOptionClick}
        allowPurchase={!isBulkUpdateRequired}
      />
    ),
    [isBulkUpdateRequired, handlePurchaseOptionClick, handleRejectOptionClick]
  );

  const canApproveAllSelectedItems = useMemo(() => {
    if (selectedPurchaseItems.length === 0) {
      return false;
    }
    return selectedPurchaseItems.every((selectedItem) => selectedItem.canBeApproved);
  }, [selectedPurchaseItems]);

  const groupedSelectedWorkloads = useMemo(
    () => groupWorkloadsByCustomer(selectedPurchaseItems),
    [selectedPurchaseItems]
  );

  const onDialogApproveClicked = useCallback(async () => {
    if (selectedPurchaseItems.length > 0) {
      const payloadForPurchase = Object.entries(groupedSelectedWorkloads).map(([customer, workloads]) => {
        const workloadsPayload = workloads.map((item) => ({
          billing_account_id: item.billingAccountId,
          region: item.region,
          hardware: item.hardware,
          type: item.type,
          usage: item.purchaseRecommendations,
        }));
        return {
          customer_id: customer,
          workloads: workloadsPayload,
        };
      });
      try {
        await Promise.all(
          selectedPurchaseItems.map(async (purchaseItem) => approvePurchasePlan(purchaseItem.id, currentUser.email))
        );
        await flexSaveOpsApi.approveRisks(payloadForPurchase);
        setSelectedPurchaseItems([]);
        successSnackbar("Purchase initiated");
      } catch (e) {
        errorSnackbar("Purchase failed to initiate. Please try again.");
      }
    }
  }, [currentUser, selectedPurchaseItems, groupedSelectedWorkloads, flexSaveOpsApi, successSnackbar, errorSnackbar]);

  const onDialogRejectClicked = useCallback(async () => {
    if (selectedPurchaseItems.length > 0) {
      await rejectPurchasePlan(selectedPurchaseItems[0].id, currentUser.email);
    }
  }, [selectedPurchaseItems, currentUser]);

  const customersCountToPurchase = Object.keys(groupedSelectedWorkloads).length;

  return (
    <>
      <PurchaseConfirmationDialog
        open={approveDialogOpen}
        handleClose={handleApproveDialogClose}
        handleApprove={onDialogApproveClicked}
        totalCost={sumBy(selectedPurchaseItems, "purchasePlanPrice")}
        customerCount={customersCountToPurchase}
        cudCount={sumBy(selectedPurchaseItems, "purchaseRecommendations")}
        customerName={customersCountToPurchase === 1 ? selectedPurchaseItems[0].primaryDomain : undefined}
      />
      <RejectPurchaseConfirmationDialog
        open={rejectDialogOpen}
        handleClose={handleRejectDialogClose}
        handleReject={onDialogRejectClicked}
      />
      <Loader loading={!approvalItems}>
        <FilterTable<ApprovalsItem>
          showRowsSelection={isFlexsaveAdmin}
          onRowsSelected={setSelectedPurchaseItems}
          tableItems={approvalItems}
          rowComponent={ApprovalsRowWrapper}
          headerColumns={headerColumns}
          filterColumns={filters}
          defaultFilters={defaultFilters}
          defaultSortingColumnValue="purchasePlanPrice"
          persistenceKey="flexsave_gcp_approval_purchase_prices"
          toolbarProps={{
            title: "Approvals",
            allowToEditColumns: true,
            primaryButton: {
              text: "Purchase",
              onClick: handlePurchaseButtonClick,
              color: "warning",
              disabled: !isFlexsaveAdmin || !canApproveAllSelectedItems || rejectDialogOpen || isBulkUpdateRequired,
            },
          }}
        />
      </Loader>
    </>
  );
};

export default Approvals;
