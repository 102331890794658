export const summaryDescriptor = {
  common: {
    usage: "On-Demand charges",
    details: {
      credits:
        "Credits are automatically applied by AWS to cover your costs associated with eligible services. These credits are passed onto your DoiT Cloud Analytics.",
      discounts: {
        privateRate: "An agreement which provides discounted prices for specific services",
        bundled:
          "Usage-based discount with a conditional logic associated with usage. This discount is passed onto your DoiT invoice.",
        edp: "It's a contractual agreement which offers cross-service discount in return for an annual or multi-year commitment. You're rewarded with higher discounts for additional contract years you commit to. EDP is mainly targeted to customers with a high annual usage spend.",
      },
      otherCharges: {
        reservationRecurringFee:
          "Monthly fee for Reserved Instances that are purchased partially upfront or no upfront",
        ocb: "Separate line items for one-time purchases, such as the Registrar fee, or APN fees billed upfront or following a private offer schedule",
      },
      refunds: "Refund applied to the invoice",
      savings: {
        reservationAppliedUsage:
          "Covered usage by Reserved Instances. It's always represented as 0, since AWS represents the coverage cost as 0.",
      },
      supportChargesTotal:
        "If you have a paid support level, the costs may differ from AWS to DoiT. If you use Flexsave, in AWS, these fees are calculated factoring in a Saving Plans 3-year discount rate, whereas in DoiT, they're based on a Saving Plans 1-year discount rate.",
      tax: "Dynamically updates based on your billing profiles in the DoiT Console",
    },
  },
  aws: {
    details: {
      discounts: {
        spp: "These discounts are given to DoiT by AWS, as we're an authorized reseller of their services",
      },
      savings: {
        planNegation:
          "Savings provided by DoiT Savings Plans. If you've purchased SPs directly from AWS, this is a combination of eligible savings from both your own Saving Plans, and DoiT Saving Plans.",
      },
      otherCharges: {
        savingsPlanRecurringFee:
          "Recurring charges related to DoiT Savings Plans. If you've purchased SPs directly from AWS, these fees are a combination of both DoiT and AWS SPs. DoiT Saving plans recurring fees are removed when we pull your monthly details through the Monthly Cost and Usage Report (CUR).",
      },
      serviceCharges: {
        savingsPlanUsage:
          "On-Demand costs covered by DoiT Savings Plans. If you've purchased SPs directly from AWS, this is a combination of eligible On-Demand costs covered by both your own Saving Plans, and DoiT Saving Plans.",
      },
    },
  },
  doit: {
    details: {
      savings: {
        flexsave: "Savings provided by Flexsave",
        planNegation: "Savings provided by your own Savings Plans",
      },
      otherCharges: {
        flexsave: "The charge for the Flexsave service",
        savingsPlanRecurringFee: "Recurring charges related to your own Savings Plans",
      },
      serviceCharges: {
        flexsaveCoveredUsage: "On-Demand costs covered by Flexsave",
        savingsPlanUsage: "On-Demand costs covered by your own Savings Plans",
      },
    },
  },
};
