import { AuthenticationProvider } from "@doitintl/cmp-models";
import { object as YupObject, string as YupString } from "yup";

import { ssoTexts } from "../../../assets/texts";
import { routes } from "../../../constants/navigation";
import {
  type AllowedProvider,
  type AuthState,
  type OIDCForm,
  type ProviderConfig,
  type SAMLForm,
  type SSOProviders,
  type SsoUiSelectedIdp,
} from "./types";

export const providers: SSOProviders[] = ["saml", "oidc"];

export const getEnabledConfig = (state: AuthState, samlEnabled, oidcEnabled): ProviderConfig => ({
  saml: { id: state.saml.id, enabled: samlEnabled },
  oidc: { id: state.oidc.id, enabled: oidcEnabled },
});

// SAML

const SamlFormSchema = YupObject().shape({
  idpEntityId: YupString().required(ssoTexts.idpEntityId.REQUIRED),
  ssoUrl: YupString().url(ssoTexts.INVALID_URL).required(ssoTexts.ssoUrl.REQUIRED),
  certificate: YupString()
    .test(
      "Check certificate prefix/suffix",
      ssoTexts.certificate.HELPER_TEXT,
      (certificate) =>
        !!certificate?.startsWith(ssoTexts.CERTIFICATE_START_WITH) &&
        !!certificate?.endsWith(ssoTexts.CERTIFICATE_END_WITH)
    )
    .required(ssoTexts.ssoUrl.REQUIRED),
});

// OIDC
const OidcFormSchema = YupObject().shape({
  clientId: YupString().required(ssoTexts.clientId.REQUIRED),
  issuerUrl: YupString().url(ssoTexts.INVALID_URL).required(ssoTexts.issuerUrl.REQUIRED),
  clientSecret: YupString().required(ssoTexts.clientSecret.REQUIRED),
});

export const SSOValidationSchema = {
  saml: SamlFormSchema,
  oidc: OidcFormSchema,
};

export const getInitialValues = (state: AuthState, ssoType: SSOProviders): SAMLForm | OIDCForm => {
  if (ssoType === "saml") {
    return {
      idpEntityId: state.saml.idpEntityId || "",
      ssoUrl: state.saml.ssoUrl || "",
      certificate: state.saml.certificate || "",
    };
  }
  return {
    clientId: state.oidc.clientId || "",
    issuerUrl: state.oidc.issuerUrl || "",
    clientSecret: state.oidc.clientSecret || "",
  };
};

export const getCallbackUrl = (useSsoWithoutProxy: boolean) => {
  if (!process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN || useSsoWithoutProxy) {
    return `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com/__/auth/handler`;
  }
  return `https://${process.env.REACT_APP_FIREBASE_CUSTOM_AUTH_DOMAIN}/__/auth/handler`;
};

export const getSpProviderId = (primaryDomain: string) => `saml.cmp.${primaryDomain}`;

export const isAuthProviderType = (str: string | null | undefined): str is AuthenticationProvider =>
  Object.values(AuthenticationProvider).some((provider) => provider === str);

export const AuthProviderTypeLabels: Record<AllowedProvider, string> = {
  google: "Google",
  microsoft: "Microsoft",
  password: "Password or Magic Link",
  all: "Allow All",
};

export function constructIdpLoginUrl(tenantId: string) {
  if (tenantId) {
    return `${location.protocol}//${location.host}${routes.signin}?idp_tenant_id=${tenantId}`;
  }
  return "";
}

/** @description: arrange the domains list such that the primary domain is first and all the rest are sorted alphabetically */
export function arrangeAutoProvisionDomains({
  domains,
  primaryDomain,
  allowedDomains,
}: {
  domains: string[];
  primaryDomain: string;
  allowedDomains: string[];
}) {
  const asSet = new Set<string>(domains.concat(allowedDomains).filter((domain) => domain !== primaryDomain));
  return [primaryDomain].concat(Array.from(asSet).sort());
}

export function interpetateIdpFromUrl(url: string): SsoUiSelectedIdp {
  try {
    if (url.trim() === "") {
      return "default";
    }
    const parsedUrl = new URL(url);
    if (parsedUrl.hostname.endsWith("okta.com")) {
      return "Okta";
    } else if (parsedUrl.hostname.endsWith("jumpcloud.com")) {
      return "JumpCloud";
    }
  } catch (e) {
    // do nothing, the url is user input - just use the fallback value
  }
  return "default";
}
