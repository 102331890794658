import {
  type AnalyticsDataSource,
  type DashboardModelSavedReportsModel,
  type Metric,
  type Positions,
  type ReportOrgMetadataModel,
} from "@doitintl/cmp-models";
import { type QueryDocumentSnapshotModel, type WithFirebaseModel } from "@doitintl/models-firestore";
import { type DateTime } from "luxon";

import { type FirestoreTimestamp } from "../utils/firebase";
import { type WithRequiredProperty } from "../utils/WithRequiredProperty";

export type ReportWSnap = {
  data: WithFirebaseModel<WithRequiredProperty<DashboardModelSavedReportsModel, "customer">> & { owner: string };
  snapshot: QueryDocumentSnapshotModel<DashboardModelSavedReportsModel>;
  _filters?: Record<string, unknown>;
};

export type CustomTimeRange = {
  to: FirestoreTimestamp | DateTime;
  from: FirestoreTimestamp | DateTime;
};

export type ReportCustomTimeRange = {
  to: DateTime;
  from: DateTime;
} | null;

export type MetadataOptionData = Omit<WithFirebaseModel<ReportOrgMetadataModel>, "organization"> & {
  organization: WithFirebaseModel<ReportOrgMetadataModel>["organization"] | null;
};

export type MetadataOptionFilter = {
  _filter: string[] | null;
  _regexp: string | null;
  _inverse: boolean;
  _limit: number | null;
  _limitOrder: string | null;
  _limitMetric: Metric | null;
};

export type MetadataOption = MetadataOptionFilter & {
  id: string;
  _disabled: boolean;
  _isDragging: boolean;
  _position: Positions;
  _values: { awsValues: string[]; gcpValues: string[]; azureValues: string[]; datahub: string[]; snowflake: string[] };
  _visible: boolean;
  _available?: boolean;
  _metricFilter?: boolean;
  typeLabel?: string;
  dataSource?: AnalyticsDataSource;
  data: MetadataOptionData;
  selectedValue?: string;
};

export type AttributionMetadataOption = Pick<
  MetadataOption,
  "id" | "typeLabel" | "data" | "_filter" | "_values" | "_visible" | "_disabled"
>;

export enum TemplateVisibilityRoles {
  PRIVATE = "Private",
  DOIT = "DoiT",
  PUBLIC = "Public",
}
