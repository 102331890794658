import { Box } from "@mui/material";

import { Loader } from "../../Components/Loader";
import { type EKSPageId } from "../../Navigation/config/pages";
import { useEKSClusters, useSetDeploymentMethod } from "./hooks";
import { Onboarding } from "./Onboarding";
import { ClusterTable } from "./Table/ClusterTable";

type Props = {
  pageId: EKSPageId;
};

export const Dashboard = ({ pageId }: Props) => {
  const [rawClusters] = useEKSClusters();
  const [clusters, isLoading] = useSetDeploymentMethod(rawClusters);

  return (
    <Loader loading={isLoading}>
      <Box>
        {pageId === "eks-onboarding-v2" && <ClusterTable clusters={clusters} />}
        {pageId === "eks-onboarding-v2:connect" && <Onboarding clusters={clusters} />}
      </Box>
    </Loader>
  );
};
