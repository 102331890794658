import {
  Aggregator,
  AlertCondition,
  AnalyticsDataSource,
  CloudAnalyticsModel,
  type Collaborators,
  CurrencyCodes,
  Metric,
  MetricFilterOperator,
  type PublicAccess,
  Roles,
  TimeInterval,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type AxiosInstance, type Method } from "axios";
import { v4 as uuidv4 } from "uuid";

import { httpMethods } from "../../../assets/texts";
import { type Customer, type CustomerRef } from "../../../types";
import { serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { type OrganizationWSnap } from "../../IAM/Organizations/Types";
import { getOrganizationRef } from "../utilities";
import { type AnalyticsAlertWithRef } from "./types";

export const copyAlert = async (customerRef: CustomerRef, currentUserEmail: string, alert: AnalyticsAlertWithRef) => {
  const docRef = getCollection(CloudAnalyticsModel).doc("alerts").collection("cloudAnalyticsAlerts").newDoc();
  mixpanel.track("analytics.alerts.new", { alertId: docRef.id });
  await docRef.set({
    collaborators: [{ email: currentUserEmail, role: Roles.OWNER }],
    config: { ...alert.data.config, filters: alert.data.config.filters ?? [] },
    customer: customerRef,
    etag: alert.data.etag,
    isValid: alert.data.isValid,
    name: `Copy of ${alert.data.name}`,
    organization: alert.data.organization,
    public: null,
    recipients: [currentUserEmail],
    timeCreated: serverTimestamp(),
    timeLastAlerted: null,
    timeModified: serverTimestamp(),
  });
  mixpanel.track("analytics.alerts.save", { alertId: docRef.id });
  return docRef.id;
};

export const shareAlert = async ({
  alertId,
  api,
  collaborators,
  customer,
  publicAccess,
}: {
  alertId: string;
  api: AxiosInstance;
  collaborators: Collaborators;
  customer: Customer;
  publicAccess: PublicAccess;
}) => {
  await api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customer.id}/analytics/alerts/${alertId}/share`,
    data: {
      public: publicAccess,
      collaborators,
    },
  });
};

export const createAlert = async ({
  userOrg,
  customerId,
  email,
  recipient,
  customerRef,
}: {
  userOrg: OrganizationWSnap | null | undefined;
  customerId: string;
  email: string;
  recipient: string;
  customerRef: CustomerRef;
}) => {
  const docRef = getCollection(CloudAnalyticsModel).doc("alerts").collection("cloudAnalyticsAlerts").newDoc();
  const organization = getOrganizationRef(false, userOrg, customerId);

  if (!organization) {
    return;
  }

  await docRef.set({
    config: {
      aggregator: Aggregator.TOTAL,
      calculatedMetric: null,
      condition: AlertCondition.VALUE,
      currency: CurrencyCodes.USD,
      dataSource: AnalyticsDataSource.BILLING,
      extendedMetric: null,
      metric: Metric.COST,
      operator: MetricFilterOperator.GREATER_THAN,
      rows: [],
      scope: [],
      timeInterval: TimeInterval.MONTH,
      values: [],
      ignoreValuesRange: null,
      filters: [],
    },
    collaborators: [
      {
        email,
        role: Roles.OWNER,
      },
    ],
    customer: customerRef,
    etag: uuidv4(),
    isValid: false,
    name: "",
    organization,
    public: null,
    recipients: [recipient],
    timeCreated: serverTimestamp(),
    timeLastAlerted: null,
    timeModified: serverTimestamp(),
  });

  return docRef;
};

export const deleteAlert = async (api: AxiosInstance, customerId: string, alertId: string) => {
  await api.delete(`/v1/customers/${customerId}/analytics/alerts/${alertId}`);
};

export const deleteManyAlerts = async (api: AxiosInstance, customerId: string, alertIds: string[]) => {
  await api.delete(`/v1/customers/${customerId}/analytics/alerts/deleteMany`, { data: { ids: alertIds } });
};
