import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CurrencyCode } from "./Currency";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";

export enum ProductEnum {
  GSuite = "g-suite",
  GoogleCloud = "google-cloud",
  GoogleCloudProject = "google-cloud-project",
  GoogleCloudDirect = "google-cloud-direct",
  GoogleCloudStandalone = "google-cloud-standalone",
  GoogleCloudProjectStandalone = "google-cloud-project-standalone",
  AmazonWebServices = "amazon-web-services",
  AmazonWebServicesStandalone = "amazon-web-services-standalone",
  MicrosoftAzure = "microsoft-azure",
  Office365 = "office-365",
  Other = "other",
}

export class InvoiceData {
  payDate!: Timestamp;

  debit!: number;

  code!: string;

  id!: string;

  url!: string;
}

@subCollection("invoiceReminders")
export class CollectionModelInvoiceReminderModel {
  customer!: Reference<CustomerModel>;

  date!: Timestamp;

  entity!: Reference<EntityModel>;

  contact!: string;

  reminderNumber?: number;

  invoices!: InvoiceData[];
}

type BillingAddress = {
  address: null | string;
  address2: null | string;
  address3?: null | string;
  countryname: string;
  state: null | string;
  statea: null | string;
  statecode?: null | string;
  statename?: null | string;
  zip: null | string;
};

type Contact = {
  email: string;
  firstName: null | string;
  lastName: null | string;
  name: string;
  phone: null | string;
};

type Invoicing = {
  attributionGroup?: null;
  autoAssignGCP: boolean | null;
  default: null | string;
  mode: "CUSTOM" | "GROUP";
};

type Debit = {
  bankCode?: string;
  email: string;
  id: string;
  last4: string;
  name: string;
};

type BankAccount = {
  bankName: string;
  id: string;
  last4: string;
};

enum CrediCardBrands {
  Amex = "amex",
  Empty = "",
  Mastercard = "mastercard",
  Visa = "visa",
}

type CrediCardBrand = `${CrediCardBrands}`;

type CreditCard = {
  brand?: CrediCardBrand;
  expMonth: number;
  expYear: number;
  id: string;
  last4: string;
};

enum TransformTypes {
  AcssDebit = "acss_debit",
  BacsDebit = "bacs_debit",
  BankAccount = "bank_account",
  BillCOM = "bill_com",
  CreditCard = "credit_card",
  SepaDebit = "sepa_debit",
  UsBankAccount = "us_bank_account",
  WireTransfer = "wire_transfer",
}

type TransformType = `${TransformTypes}`;

type Payment = {
  accountId?: string;
  acssDebit?: Debit | null;
  bacsDebit?: Debit | null;
  bankAccount: BankAccount | null;
  card: CreditCard | null;
  sepaDebit?: Debit | null;
  type: TransformType;
};

enum PriorityCompanies {
  Doit = "doit",
  Doitaus = "doitaus",
  Doitca = "doitca",
  Doitch = "doitch",
  Doitde = "doitde",
  Doitee = "doitee",
  Doites = "doites",
  Doitfr = "doitfr",
  Doitid = "doitid",
  Doitie = "doitie",
  Doitint = "doitint",
  Doitnl = "doitnl",
  Doitse = "doitse",
  Doitsg = "doitsg",
  Doituk = "doituk",
}

type PriorityCompany = `${PriorityCompanies}`;

type EntityData = {
  _name: string;
  active: boolean;
  billingAddress?: BillingAddress;
  contact: Contact | null;
  country: string;
  currency: CurrencyCode;
  customer: string;
  invoicing: Invoicing;
  name: string;
  payment: Payment;
  priorityCompany: PriorityCompany;
  priorityId: string;
};

@subCollection("debtAnalytics")
export class CollectionModelDebtAnalyticsModel {
  customer!: Reference<CustomerModel>;

  date!: Timestamp;

  entity!: Reference<EntityModel>;

  entityData!: EntityData;

  priorityId!: string;

  priorityName!: string;

  products!: ProductEnum[];

  severity!: number;

  strategic!: boolean;

  timestamp!: Timestamp;

  totals!: { [CurrencyCode: string]: number };

  weight!: number;
}

type DebtAnalyticsGraph = {
  date: Timestamp;
  severity?: { [key: number]: DebtAnalyticsGraphSeverity };
};

type DebtAnalyticsGraphSeverity = {
  currency: { [key: string]: number };
  value: number | null;
};

class DebtAnalyticsDoc {
  subCollections?: {
    debtAnalytics: CollectionModelDebtAnalyticsModel;
    debtAnalyticsGraph: Record<string, DebtAnalyticsGraph>;
  };
}

class InvoiceReminderDoc {
  subCollections?: {
    invoiceReminders: CollectionModelInvoiceReminderModel;
  };
}

export type DailyCollectionModel = {
  timestamp: Timestamp;
  totals: { [key: string]: number };
  weight: number | null;
};

type DailyCollectionDoc = {
  subCollections: {
    dailyCollection: DailyCollectionModel;
  };
};

type DailyAccountReceivablesModel = {
  month: Timestamp;
  receivables?: { [key: string]: number };
  receivablesExpected?: { [key: string]: number };
  timestamp?: Timestamp;
};

type AccountReceivablesDoc = {
  subCollections: {
    dailyAccountReceivables: DailyAccountReceivablesModel;
  };
};

@collection("collection")
export class CollectionModel {
  docs?: {
    "debt-analytics": DebtAnalyticsDoc;
    "invoice-reminders": InvoiceReminderDoc;
    "daily-collection": DailyCollectionDoc;
    "account-receivables": AccountReceivablesDoc;
  };
}
