import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import DefaultPreviewChart from "../../../assets/default-preview.svg";

const DefaultPreviewContent = () => {
  const { t } = useTranslation("login");
  return (
    <>
      <Typography variant="h1" fontSize={36} lineHeight={1.2} fontWeight={600} color="white" textAlign="center">
        {t("defaultContent.title")}
      </Typography>
      <Typography variant="body1" py={2} textAlign="center" color="white">
        {t("defaultContent.description")}
      </Typography>
      <img src={DefaultPreviewChart} alt="Flexsave Chart" width="576px" height="auto" />
    </>
  );
};

export default DefaultPreviewContent;
