import { type CurrencyCode } from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { formatCurrency } from "../../../../../../../utils/common";
import { type SupportCharge } from "../../../../types";

type Props = {
  items: SupportCharge[];
  reduced?: boolean;
  currency: CurrencyCode;
};

export function SupportChargesTable({ items, reduced = false, currency }: Props) {
  return (
    <Box border={1} borderTop={0} borderColor="lightgrey">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Account ID</TableCell>
              <TableCell>Support Level</TableCell>
              {!reduced && <TableCell>Base cost</TableCell>}
              {!reduced && <TableCell>Description</TableCell>}
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <TableRow key={`${row.accountId}-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.accountId}
                </TableCell>
                <TableCell>{row.supportLevel}</TableCell>
                {!reduced && <TableCell>{row.baseCost ? formatCurrency(row.baseCost, currency) : ""}</TableCell>}
                {!reduced && <TableCell>{row.description}</TableCell>}
                <TableCell>{formatCurrency(row.total, currency)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
