import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  type AmazonWebServicesAssetModel,
  type AmazonWebServicesAssetModelProperties,
  type AmazonWebServicesStandaloneAssetModel,
  AssetModel,
  DoitRole,
  EntityModel,
} from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Autocomplete, Box, MenuItem, Stack, TextField } from "@mui/material";
import isEqual from "lodash/isEqual";

import { Guard } from "../../../Components/Guard";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { StickyFooter } from "../../../Components/StickyFooter";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type CustomerRef } from "../../../types";
import { CloudHealthStatus, getStatusLabel } from "../../../utils/common";
import { serverTimestamp } from "../../../utils/firebase";
import {
  buildBillingProfileString,
  doesAssetBelongToPayerAccountOne,
  maxAssetTagLength,
  permitUserToViewAsset,
} from "../assetUtils";
import { getAssetName } from "../Tabs/components/AwsAssetRow";
import { updateAssetAndSettings } from "../utils";
import AssetsSupportTier from "./components/AssetsSupportTier";
import AwsAssetHeader from "./components/AwsAssetHeader";

type Props = {
  suggestedTags: string[];
};

type Params = {
  assetId: string;
  customerId: string;
};

const getStatus = (statusString: string | undefined): CloudHealthStatus => {
  if (!statusString) {
    return CloudHealthStatus.UNKNOWN;
  }
  const keyString: string | undefined = Object.entries(CloudHealthStatus).find(
    ([_key, val]) => val === statusString
  )?.[0];
  return keyString ? CloudHealthStatus[keyString] : CloudHealthStatus.UNKNOWN;
};

const AwsAssetPage = ({ suggestedTags }: Props) => {
  const history = useHistory();
  const { assetId, customerId } = useParams<Params>();
  const [assetData] = useDocumentDataOnce(
    getCollection(AssetModel).doc(assetId).narrow<AmazonWebServicesAssetModel | AmazonWebServicesStandaloneAssetModel>()
  );
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer, isProductOnlyCustomer } = useCustomerContext();

  const [tags, setTags] = useState(assetData?.tags ?? []);
  const [originalTags, setOriginalTags] = useState([] as string[]);
  const [entityId, setEntityId] = useState("");
  const [originalEntityId, setOriginalEntityId] = useState("");
  const [billingProfiles, setBillingProfiles] = useState<JSX.Element[]>([]);
  const [pageHasChanges, setPageHasChanges] = useState(false);
  const [supportTierValue, setSupportTierValue] = useState<string>("");
  const [supportTierChanged, setSupportTierChanged] = useState<boolean>(false);
  const [showSupportTierAlert, setShowSupportTierAlert] = useState<boolean>(false);
  const [overrideReason, setOverrideReason] = useState<string>("");
  const [overrideRemoved, setOverrideRemoved] = useState<boolean>(false);
  const [showReasonError, setShowReasonError] = useState<boolean>(false);
  const [disabledTierField, setDisabledTierField] = useState<boolean>(false);

  const isMasterPayerAccountOpsAdmin = useDoitRoleCheck(DoitRole.MasterPayerAccountOpsAdmin);
  const name = getAssetName(assetData?.properties);
  const accountId = assetData?.properties.accountId;
  const rootEmail = assetData?.properties.organization?.email ?? "No email provided";
  const originalSupportTier = assetData?.properties?.support?.originalSupportTier ?? "";
  const overridingSupportTierCurrentlyInFS = assetData?.properties?.support?.overridingSupportTier ?? "";
  const overriddenOn = assetData?.properties?.support?.overriddenOn;
  const isOverridable = assetData?.properties?.support?.isOverridable;
  const assetAccountIsntPayerOne = useMemo(
    () =>
      assetData?.properties?.organization &&
      !doesAssetBelongToPayerAccountOne(assetData?.properties.organization?.payerAccount?.id),
    [assetData?.properties?.organization]
  );
  const assetIsntRootAsset = assetData?.properties?.organization?.payerAccount?.id !== assetData?.properties?.accountId;
  const assetIsntPayerOneOrRootAsset = assetAccountIsntPayerOne && assetIsntRootAsset;
  const cloudHealthStatus =
    assetData?.type === "amazon-web-services" && getStatusLabel(getStatus(assetData.properties.cloudhealth?.status));

  const proceed = useMemo<boolean>(
    () => permitUserToViewAsset(customer.id, isDoitEmployee, assetData?.customer.id),
    [assetData, customer.id, isDoitEmployee]
  );

  useEffect(() => {
    const getBillingProfile = async (): Promise<void> => {
      const entityIdFromAsset = assetData?.entity?.id ?? "";
      setEntityId(entityIdFromAsset);
      setOriginalEntityId(entityIdFromAsset);
    };

    const buildBillingProfileDropdown = async (customerRef: CustomerRef | undefined): Promise<void> => {
      if (!customerRef) {
        return;
      }

      const querySnapshot = await getCollection(EntityModel).where("customer", "==", customerRef).get();
      const entities = querySnapshot.docs;
      setBillingProfiles(
        entities.map((snapshotModel) => {
          const modelData = snapshotModel.asModelData();
          return (
            <MenuItem key={snapshotModel.id} value={snapshotModel.id} disabled={!modelData.active}>
              {buildBillingProfileString(modelData)}
            </MenuItem>
          );
        })
      );
    };

    buildBillingProfileDropdown(assetData?.customer);
    setTags(assetData?.tags ?? []);
    setOriginalTags(assetData?.tags ?? []);
    getBillingProfile();
  }, [assetData]);

  const initialSupportTierValue = useMemo(() => {
    if (overridingSupportTierCurrentlyInFS || originalSupportTier) {
      return overridingSupportTierCurrentlyInFS || originalSupportTier;
    }
  }, [overridingSupportTierCurrentlyInFS, originalSupportTier]);

  useEffect(() => {
    if (initialSupportTierValue !== undefined) {
      setSupportTierValue(initialSupportTierValue);
    }
  }, [initialSupportTierValue]);

  useEffect(() => {
    if (initialSupportTierValue && supportTierValue) {
      setSupportTierChanged(initialSupportTierValue !== supportTierValue);
    }
  }, [initialSupportTierValue, supportTierValue]);

  useEffect(() => {
    if (
      overriddenOn &&
      overridingSupportTierCurrentlyInFS &&
      originalSupportTier &&
      originalSupportTier !== overridingSupportTierCurrentlyInFS
    ) {
      setShowSupportTierAlert(true);
      assetData?.properties?.support?.overrideReason && setOverrideReason(assetData.properties.support.overrideReason);
      setDisabledTierField(true);
    }
  }, [
    overriddenOn,
    assetData?.properties?.support?.overrideReason,
    originalSupportTier,
    overridingSupportTierCurrentlyInFS,
  ]);

  const changeSupportTierValue = (value: string) => {
    if (initialSupportTierValue && value) {
      setSupportTierValue(value.toLowerCase());
      setShowSupportTierAlert(true);
    }
  };

  const saveSupportType = useCallback(async (): Promise<void> => {
    if (assetData?.properties?.support && !showReasonError && isOverridable) {
      const updateData = {
        overridingSupportTier: supportTierValue,
        overriddenOn: serverTimestamp(),
        overrideReason,
      };

      const propertiesCopy: AmazonWebServicesAssetModelProperties = { ...assetData.properties };
      const updatedSupport = { ...propertiesCopy.support, ...updateData };

      await updateAssetAndSettings(
        assetId,
        { properties: { ...propertiesCopy, support: updatedSupport } },
        isProductOnlyCustomer
      );
    }
  }, [
    assetData?.properties,
    showReasonError,
    isOverridable,
    supportTierValue,
    overrideReason,
    assetId,
    isProductOnlyCustomer,
  ]);

  const onRemoveOverride = useCallback(() => {
    if (initialSupportTierValue) {
      setShowSupportTierAlert(false);
      setDisabledTierField(false);
      setSupportTierValue(originalSupportTier);
      setOverrideReason("");
      setOverrideRemoved(true);
    }
  }, [initialSupportTierValue, originalSupportTier]);

  const updateOverrideReason = useCallback((reason: string) => {
    setOverrideReason(reason);
    reason && setShowReasonError(false);
  }, []);

  useEffect(() => {
    if (supportTierChanged && !overrideRemoved && !overrideReason) {
      setShowReasonError(true);
    }
    setPageHasChanges(
      entityId !== originalEntityId ||
        tags[0] !== originalTags[0] ||
        (supportTierChanged && !showReasonError) ||
        overrideRemoved
    );
  }, [
    entityId,
    originalEntityId,
    tags,
    originalTags,
    initialSupportTierValue,
    supportTierValue,
    overrideReason,
    showReasonError,
    supportTierChanged,
    overrideRemoved,
  ]);

  const returnToAssets = (): void => {
    history.push(`/customers/${customerId}/assets/amazon-web-services`);
  };

  const saveTags = async (newTags: string[]): Promise<void> => {
    if (!isEqual(tags[0], originalTags[0])) {
      const updateData = { tags: newTags };
      await updateAssetAndSettings(assetId, updateData, isProductOnlyCustomer);
    }
  };

  const saveBillingProfile = async (): Promise<void> => {
    const newEntity = getCollection(EntityModel).doc(entityId);
    const updateData = { entity: newEntity, bucket: null, contract: null };

    await updateAssetAndSettings(assetId, updateData, isProductOnlyCustomer);
  };

  const handleSave = async () => {
    tags !== originalTags && saveTags(tags);
    entityId !== originalEntityId && saveBillingProfile();
    (initialSupportTierValue !== supportTierValue || overrideRemoved) && (await saveSupportType());
    returnToAssets();
  };
  const assetTierEditable =
    !!supportTierValue && isMasterPayerAccountOpsAdmin && assetIsntPayerOneOrRootAsset && isOverridable;
  return (
    <Guard isAllowed={proceed}>
      <Box display="flex" flexDirection="column" sx={{ ml: 2 }} data-cy="assetPage">
        <AwsAssetHeader assetData={assetData} returnToAssets={returnToAssets} />
        <Stack sx={{ maxWidth: "600px", pb: 9 }} gap={5} component="form">
          <TextField label="Asset name" value={name} InputLabelProps={{ shrink: true }} size="medium" disabled />
          <TextField
            disabled={!assetData}
            label="Billing profile"
            InputLabelProps={{ shrink: true }}
            value={entityId}
            onChange={(event) => {
              setEntityId(event.target.value);
            }}
            select
            size="medium"
          >
            {billingProfiles}
          </TextField>
          {assetTierEditable && (
            <AssetsSupportTier
              supportTierValue={supportTierValue}
              originalSupportTier={originalSupportTier}
              overridingSupportTierCurrentlyInFS={overridingSupportTierCurrentlyInFS}
              onTypeChange={changeSupportTierValue}
              disabled={disabledTierField}
              showAlert={showSupportTierAlert}
              onRemoveOverride={onRemoveOverride}
              overrideReason={overrideReason}
              updateOverrideReason={updateOverrideReason}
              showReasonError={showReasonError}
              supportTierChanged={supportTierChanged}
              overriddenOn={overriddenOn}
              overrideRemoved={overrideRemoved}
            />
          )}
          <Autocomplete
            id="tags-input"
            freeSolo
            autoSelect
            filterSelectedOptions
            options={suggestedTags}
            value={tags?.length > 0 ? tags[0] : ""}
            disabled={!assetData}
            onChange={(_event, newValue) => {
              newValue && newValue.length > 0 ? setTags([newValue]) : setTags([]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, maxLength: maxAssetTagLength }}
                label="Asset tag"
                helperText="A maximum of one tag per asset is allowed. They appear on invoices, e.g. cost-centres or team names."
                InputLabelProps={{ shrink: true }}
                size="medium"
              />
            )}
          />
          <TextField
            label="Account ID"
            InputLabelProps={{ shrink: true }}
            value={accountId ?? ""}
            disabled
            helperText="Changes to Account ID can only be made within AWS"
            size="medium"
            sx={{ mt: "-23px" }}
          />
          <TextField
            label="Root email address"
            InputLabelProps={{ shrink: true }}
            value={rootEmail}
            helperText="Changes to root email address can only be made within AWS"
            size="medium"
            disabled
            sx={{ mt: "-23px" }}
          />
          <TextField
            label="CloudHealth Status"
            InputLabelProps={{ shrink: true }}
            value={cloudHealthStatus}
            size="medium"
            disabled
            sx={{ mt: "-23px" }}
          />
        </Stack>
        <StickyFooter
          disabled={!pageHasChanges}
          onClick={handleSave}
          primaryButtonStyle={{}}
          onCancel={returnToAssets}
          buttonText="Save and close"
          buttonTextLoading="Saving..."
          cancelButtonText="Cancel"
          cancelButtonStyle={{ color: "primary" }}
          sx={{ pr: 3.81 }}
        />
      </Box>
    </Guard>
  );
};

export default AwsAssetPage;
