import { type CloudConnectModelGoogleCloudStandalone } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { Card, CardContent } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { headers } from "./columns";
import { StandaloneAccountsRow } from "./StandaloneAccountsRow";

type Props = {
  accounts: WithFirebaseModel<CloudConnectModelGoogleCloudStandalone>[];
};

export const StandaloneAccounts = ({ accounts }: Props) => (
  <Card>
    <CardHeader title="Configured accounts" titleTypographyProps={{ variant: "h6" }} />
    <CardContent>
      <FilterTable<(typeof accounts)[number]>
        rowComponent={StandaloneAccountsRow}
        showRowsSelection={false}
        headerColumns={headers}
        filterColumns={[]}
        tableItems={accounts}
        defaultSortingColumnValue="serviceAccountEmail"
        persistenceKey="gcp_standalone_accounts"
      />
    </CardContent>
  </Card>
);
