import { Box, Tooltip, Typography } from "@mui/material";

import { type BucketStatus, type Status } from "../types";

export const StatusBox = ({
  cloudFormationStatus,
  bucketStatus,
  bucketLastMetricsData,
}: {
  cloudFormationStatus: Status;
  bucketStatus: BucketStatus;
  bucketLastMetricsData?: Date;
}) => {
  let statusColor = "#E0E0E0";
  let statusText = "Not started";
  let fontColor = "#000000";
  let tooltipText = "";

  if (cloudFormationStatus === "active") {
    statusColor = "#508B46";
    statusText = "Active";
    fontColor = "#FFFFFF";

    if (bucketStatus === "stopped" || bucketStatus === "unknown") {
      statusColor = "#E53935";
      statusText = "Unhealthy";
      fontColor = "#FFFFFF";
      tooltipText = `Your data is not fresh; the latest metric data is from ${bucketLastMetricsData ? bucketLastMetricsData.toString() : "unknown"}`;
    }
  } else if (cloudFormationStatus === "in-progress") {
    statusColor = "#F7D859";
    statusText = "In progress";
  } else if (cloudFormationStatus === "deleted") {
    statusColor = "#E53935";
    statusText = "Deleted";
    fontColor = "#FFFFFF";
  } else if (cloudFormationStatus === "pending-update") {
    statusColor = "#D2A73A";
    statusText = "Update required";
    fontColor = "#000000";
  }

  return (
    <Tooltip title={tooltipText}>
      <Box
        sx={{
          backgroundColor: statusColor,
          width: "fit-content",
          px: 1,
          borderRadius: 5,
          color: fontColor,
        }}
      >
        <Typography variant="caption">{statusText}</Typography>
      </Box>
    </Tooltip>
  );
};
