import { type ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { type CustomerModelAccountAssumption } from "@doitintl/cmp-models";
import { useDocumentData, type WithFirebaseModel } from "@doitintl/models-firestore";
import { Box, Checkbox, FormControlLabel, Link, Stack, Switch, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";

import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { StickyFooter } from "../../Components/StickyFooter";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUnsavedChanges } from "../../Context/UnsavedChangesContext";
import { TimestampFromDate } from "../../utils/firebase";

// exported for testing
export const accountAssumptionCyPrefix = "account-assumption-";

type MaybeEmptyDate = Date | undefined | null;
const defaultDuration = "day";

const AccountAssumption = () => {
  // dropdown
  const [duration, setDuration] = useState(defaultDuration);

  // settings & changes from existing settings
  const [enableAccountAssumption, setEnableAccountAssumption] = useState<boolean>();
  const [expireDate, setExpireDate] = useState<Date | null>(null);
  const [changeDetected, setChangeDetected] = useState(false);
  const [allowIndefiniteAccess, setAllowIndefiniteAccess] = useState<boolean>(false);

  const [presetEnable, setPresetEnable] = useState(false);
  const [presetExpireDate, setPresetExpireDate] = useState<Date | null>(null);

  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  const snackbar = useSnackbar();

  // Concern: is there anyone besides Do'ers and customer users with this level of access?
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const accountAssumptionDocRef = useMemo(
    () => customer.ref.collection("accountAssumption").doc("accountAssumptionSettings"),
    [customer.ref]
  );
  const [accountAssumptionDocData, accountAssumptionDocDataLoading] = useDocumentData(accountAssumptionDocRef);

  // check for preset data
  useEffect(() => {
    if (accountAssumptionDocDataLoading) {
      return;
    }

    if (accountAssumptionDocData) {
      setPresetEnable(true);
      setEnableAccountAssumption(true);

      if (accountAssumptionDocData.accountAssumptionUntil) {
        const savedExpireDate = DateTime.fromMillis(accountAssumptionDocData.accountAssumptionUntil.seconds * 1000)
          .toUTC()
          .toJSDate();
        setExpireDate(savedExpireDate);
        setPresetExpireDate(savedExpireDate);
      } else {
        setAllowIndefiniteAccess(true);
      }
    } else {
      setEnableAccountAssumption(false);
    }
  }, [accountAssumptionDocData, accountAssumptionDocDataLoading]);

  // callbacks for the event listeners
  const handleAccountAssumptionToggle = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    setEnableAccountAssumption(e.target.checked);
  }, []);

  const handleSave = useCallback(async () => {
    if (enableAccountAssumption === undefined) {
      return;
    }

    // DB submit (within save)
    const saveToDb = async (saveObject: WithFirebaseModel<CustomerModelAccountAssumption>) => {
      if (enableAccountAssumption) {
        await accountAssumptionDocRef.set(saveObject);
      } else {
        await accountAssumptionDocRef.delete();
      }
    };

    const customerIdHolder = customer.ref;

    const saveObject = {
      customer: customerIdHolder,
      accountAssumptionUntil: allowIndefiniteAccess || !expireDate ? null : TimestampFromDate(new Date(expireDate)),
      requestedBy: currentUser.email,
    } as const;

    try {
      await saveToDb(saveObject);
      setPresetEnable(enableAccountAssumption);

      if (enableAccountAssumption) {
        if (!allowIndefiniteAccess && saveObject?.accountAssumptionUntil?.seconds) {
          const newDate = DateTime.fromMillis(saveObject.accountAssumptionUntil.seconds * 1000)
            .toUTC()
            .toJSDate();
          setPresetExpireDate(newDate);
          setExpireDate(newDate);
        } else if (!allowIndefiniteAccess) {
          snackbar.onOpen({
            autoHideDuration: 3000,
            message: "Date saved, but failure occurred when reseting the interface.",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            withClose: true,
            variant: "error",
          });
        } else {
          setPresetExpireDate(null);
          setExpireDate(null);
        }
      } else {
        setPresetExpireDate(null);
        setExpireDate(null);
        setAllowIndefiniteAccess(false);
      }

      snackbar.onOpen({
        autoHideDuration: 3000,
        message: "Account assumption settings updated.",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        withClose: true,
        variant: "success",
      });
    } catch (e: any) {
      snackbar.onOpen({
        autoHideDuration: 3000,
        message: "Failed to update account assumption settings.",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        withClose: true,
        variant: "error",
      });
    }
  }, [
    enableAccountAssumption,
    customer.ref,
    allowIndefiniteAccess,
    expireDate,
    currentUser.email,
    accountAssumptionDocRef,
    snackbar,
  ]);

  // default dropdown value when account assumption is initially enabled.
  useEffect(() => {
    if (enableAccountAssumption && !duration && !presetExpireDate) {
      setDuration(defaultDuration);
    }
  }, [duration, enableAccountAssumption, presetExpireDate]);

  // detect changes (or manual undoing of changes)
  const isDateDifferent = useCallback((presetDate: MaybeEmptyDate, interfaceDate: MaybeEmptyDate) => {
    if (!presetDate && !interfaceDate) {
      return false;
    }
    if (!presetDate || !interfaceDate) {
      return true;
    }
    return !isEqual(new Date(interfaceDate).toUTCString(), new Date(presetDate).toUTCString());
  }, []);

  useEffect(() => {
    // date changed
    // (check enableAccountAssumption -- prevents false positive on initial page load, and we don't care about the date if it's disabled anyway)
    const dateChanged =
      enableAccountAssumption && isDateDifferent(presetExpireDate, expireDate) && !allowIndefiniteAccess;

    const enabledChanged = (enableAccountAssumption && !presetEnable) || (!enableAccountAssumption && presetEnable);

    const indefiniteChanged =
      (presetExpireDate !== null && allowIndefiniteAccess) ||
      (presetExpireDate === null && !allowIndefiniteAccess && enableAccountAssumption);

    // either doesn't match the preset or it doesn't match the default
    if (dateChanged || enabledChanged || indefiniteChanged) {
      setChangeDetected(true);
    } else {
      setChangeDetected(false);
    }
  }, [allowIndefiniteAccess, enableAccountAssumption, expireDate, presetEnable, presetExpireDate, isDateDifferent]);

  // prompt if unsaved changes detected.
  useEffect(() => {
    if (changeDetected) {
      activatePendingPrompt();
    } else {
      clearPendingPrompt();
    }
  }, [changeDetected, activatePendingPrompt, clearPendingPrompt]);

  if (enableAccountAssumption === undefined) {
    return null;
  }

  return (
    <Box sx={{ margin: "inherit", pl: 4 }}>
      <Stack>
        <Typography variant="h1">Account assumption</Typography>
        <Box sx={{ maxWidth: 706 }}>
          <FormControlLabel
            control={
              <Switch
                checked={enableAccountAssumption}
                onChange={handleAccountAssumptionToggle}
                data-cy={`${accountAssumptionCyPrefix}toggle`}
              />
            }
            label={
              <>
                <Typography sx={{ mt: 4 }}>Enable account assumption</Typography>
                <Typography variant="body2" color="text.secondary">
                  You can allow DoiT to assume the role of a user from your organization for a specific amount of time.
                  This will allow DoiT to access your DoiT account.
                </Typography>
              </>
            }
          />
        </Box>
        <Stack direction="row" sx={{ alignItems: "center", mt: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputFormat="DD MMM YYYY"
              disabled={!enableAccountAssumption || allowIndefiniteAccess}
              renderInput={(params) => (
                <TextField
                  data-cy={`${accountAssumptionCyPrefix}select-interval`}
                  required={true}
                  sx={{ minWidth: 220 }}
                  {...params}
                />
              )}
              label="Access end date"
              value={expireDate}
              onChange={(newDate) => {
                setExpireDate(newDate);
              }}
              componentsProps={{
                actionBar: {
                  actions: ["clear"],
                },
              }}
            />
          </LocalizationProvider>
          <FormControlLabel
            data-cy={`${accountAssumptionCyPrefix}indefinite-checkbox`}
            sx={{ ml: 2 }}
            control={
              <Checkbox
                onChange={(e) => {
                  setAllowIndefiniteAccess(e.target.checked);
                }}
                disabled={!enableAccountAssumption}
                checked={enableAccountAssumption && allowIndefiniteAccess}
              />
            }
            label="Allow indefinite access"
          />
        </Stack>
        <Box sx={{ mt: 4, maxWidth: 706 }}>
          <Typography variant="caption" color="text.secondary">
            DoiT reserves the right to access your account without prior notice in certain situations. These include
            emergency situations to prevent harm to you, other customers, or situations where we suspect that your use
            or access to our Services is violating the Terms and Conditions.
            <Link
              href="https://help.doit.com/docs/general/account-assumption"
              sx={{ textDecoration: "none", pl: 0.25 }}
            >
              Learn more
            </Link>
          </Typography>
        </Box>
      </Stack>
      <StickyFooter buttonText="Save" disabled={!changeDetected} onClick={handleSave} sx={{ pr: 20 }} />
    </Box>
  );
};

export default AccountAssumption;
