import { useCallback } from "react";

import { AccountManagerModel } from "@doitintl/cmp-models";
import {
  getCollection,
  type ModelIdRef,
  type ModelReference,
  type QueryDocumentSnapshotModel,
} from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";

/** useAccountMangersTeam hook:
Easily fetch list of Account Managers which are managed by the logged in Doer/Partner
* Term "Managed" = given AM is the direct manager of the returned Account Managers

** Functionality:
*  @function getAccountMangersHierarchy - get subordinate Account Managers and also their subordinates (entire tree of management)
*/
const useAccountMangersTeam = (): {
  getAccountMangersHierarchy: () => Promise<ModelReference<AccountManagerModel>[] | undefined>;
} => {
  const { partnerCompany, isDoitPartner } = useAuthContext({ mustHaveUser: true });
  const { accountManager } = AccountManagersHooks.useAccountManager();
  const [allAccountManagers] = AccountManagersHooks.useAllAccountManagers();

  const getRefs = (docs: ModelIdRef<AccountManagerModel>[]): ModelReference<AccountManagerModel>[] =>
    docs.map((doc) => doc.ref);

  const getAccountMangersModels = useCallback(
    (snaps: QueryDocumentSnapshotModel<AccountManagerModel>[]): ModelIdRef<AccountManagerModel>[] =>
      snaps.map((snap) => ({
        ...snap.asModelData(),
        id: snap.id,
        ref: snap.modelRef,
      })),
    []
  );

  // getSubordinateAccountMangersTeam - get direct subordinate Account Managers given a Doer/Partner email
  const getSubordinateAccountMangersTeam = useCallback(
    async (email: string): Promise<ModelIdRef<AccountManagerModel>[] | undefined> => {
      if (allAccountManagers === undefined) {
        return undefined;
      }

      if (isDoitPartner) {
        const accountManagersRes = await getCollection(AccountManagerModel)
          .where("company", "==", partnerCompany)
          .where("lineManager", "==", email)
          .get();
        return getAccountMangersModels(accountManagersRes.docs);
      }

      const parentAccountManager = allAccountManagers.find((am) => am.email === email);
      if (!parentAccountManager) {
        return [];
      }
      return allAccountManagers.filter((am) => am.manager?.id === parentAccountManager.ref.id) ?? [];
    },
    [allAccountManagers, getAccountMangersModels, isDoitPartner, partnerCompany]
  );

  /** getAccountMangersHierarchyRecursive - get subordinate Account Managers and also their subordinates (entire tree of management)
   * @param email - account manager to get subordinates for
   * @param alreadyFetchedIds - reference ids to not include in recursive aggregation
   */
  const getAccountMangersHierarchyRecursive = useCallback(
    async (email: string, alreadyFetchedIds: string[]): Promise<ModelReference<AccountManagerModel>[] | undefined> => {
      const subordinatesAMs = await getSubordinateAccountMangersTeam(email);

      if (subordinatesAMs === undefined) {
        return undefined;
      }

      const uniqueSubordinatesAMs = subordinatesAMs.filter((am) => !alreadyFetchedIds.includes(am.ref.id));

      // first - init array of references for given AM direct subordinates (given email). do not include alreadyFetchedIds
      const accountManagerRefs = getRefs(uniqueSubordinatesAMs);
      alreadyFetchedIds.push(...accountManagerRefs.map((amRef) => amRef.id));

      // second - if exist, populate array with references of sub-subordinates account managers (team lead by subordinate of the given AM)
      for (const { email } of uniqueSubordinatesAMs) {
        const result = await getAccountMangersHierarchyRecursive(email, alreadyFetchedIds);
        if (result === undefined) {
          continue;
        }
        accountManagerRefs.push(...result);
      }

      return accountManagerRefs;
    },
    [getSubordinateAccountMangersTeam]
  );

  /** getAccountMangersHierarchy - get subordinate Account Managers and also their subordinates (entire tree of management)
   *   Works recursively to get entire tree of management.
   *   Aggregates Account Managers references and returns all the data.
   *   Does not include parent account manager
   */
  const getAccountMangersHierarchy = useCallback(async (): Promise<
    ModelReference<AccountManagerModel>[] | undefined
  > => {
    if (accountManager === undefined) {
      return undefined;
    }

    if (!accountManager?.id) {
      return [];
    }

    const alreadyFetchedIds = [accountManager.id];
    return getAccountMangersHierarchyRecursive(accountManager.email, alreadyFetchedIds);
  }, [accountManager, getAccountMangersHierarchyRecursive]);

  return {
    getAccountMangersHierarchy,
  };
};

export default useAccountMangersTeam;
