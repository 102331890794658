import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";

export const validateLookerContractSKUs = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, any> = {};
  let isValid = true;

  if (!state.startDate?.isValid) {
    errors.startDate = true;
    isValid = false;
  }

  const minContractDuration = Math.max(...state.lookerSkus.map((sku) => sku.months));
  const invalidContractDuration = !state.lookerContractDuration || state.lookerContractDuration < minContractDuration;
  if (invalidContractDuration) {
    errors.lookerContractDuration = true;
    isValid = false;
  }
  const lookerSkus = structuredClone(state.lookerSkus);
  const requiredFields = ["quantity", "monthlySalesPrice", "months"];
  errors.lookerSkus = lookerSkus.map((lookerSku) => {
    const skuErrors = {} as any;
    if (lookerSku.skuName.label === null || lookerSku.skuName.label === "") {
      skuErrors.skuName = true;
      isValid = false;
    }

    requiredFields.forEach((field) => {
      if (!lookerSku[field]) {
        skuErrors[field] = true;
        isValid = false;
      }
    });
    if (lookerSku.monthlySalesPrice < 0) {
      skuErrors.monthlySalesPrice = true;
      isValid = false;
    }
    return skuErrors;
  });

  setState({ ...state, errors });

  return isValid;
};
