import {
  type CloudAnalyticsModelWidgetModel,
  type ContractModel,
  type CustomersColumnId,
  type CustomersColumnsID,
  type CustomerSettings,
} from "@doitintl/cmp-models";
import { type ModelId, type WithFirebaseModel } from "@doitintl/models-firestore";

import { type CountUpContentProps } from "../../Components/CountUp/CountUpContent";
import { type CountUpTextProps } from "../../Components/CountUp/CountUpText";
import { type Customer, type TrendType } from "../../types";
import { type FirestoreTimestamp } from "../../utils/firebase";

export enum DoerType {
  Partner = "partner",
  Fsr = "fsr",
  Regular = "regular",
}

export type PageViewMode = PageViewModes;
export enum PageViewModes {
  myCustomers = "customers",
  myTeamsCustomers = "my-teams-customers",
}

export type ColumnsActions =
  | CustomersColumnsID.ThisMonth
  | CustomersColumnsID.AwsRampPlanAttainment
  | CustomersColumnsID.GcpRampPlanAttainment
  | CustomersColumnsID.FlexsaveAWS
  | CustomersColumnsID.BigqueryLens
  | CustomersColumnsID.GkeCostAllocation
  | CustomersColumnsID.GcpContracts
  | CustomersColumnsID.AwsContracts
  | CustomersColumnsID.AccountManagers;

export enum Features {
  Flexsave = "flexsave",
  CloudConnect = "cloudConnect",
  RampPlan = "rampPlan",
}

export enum Status {
  Active = "ACTIVE",
  InActive = "INACTIVE",
  Na = "N/A",
  OnTime = "ON-TIME",
  Lagging = "LAGGING",
  NotEligible = "Not eligible",
}

export type FlexsavePlatformItem = number | null | undefined | FlexsaveStatus | string;

export type CountUpCardProps = CountUpContentProps & CountUpTextProps;

export enum FlexsaveStatus {
  Na = -2,
  InActive,
  Active,
}

export enum RampPlanStatus {
  Na = -2,
  InActive,
  Lagging,
  OnTime,
}

export enum CloudConnectAccessStatus {
  Full = "Full",
  Partial = "Partial",
  Na = "N/A",
}

export enum TrialStatusValue {
  NotStarted = "NOT STARTED",
  Active = "ACTIVE",
  Ended = "ENDED",
  Na = "N/A",
}

type BaseCustomerRowProps = {
  columnsConfig: Readonly<Array<Column>>;
};

type EnrichedCustomerRowProps = {
  thisMonth: number;
  lastMonth: number;
  trend: number;
  trendDirection: TrendType;
  flexsaveAWS: number | string;
  flexsaveGCP: number;
  bigqueryLens: Status;
  gkeCostAllocation: Status;
  awsRampPlanAttainment: RampPlanAttainment | null;
  gcpRampPlanAttainment: RampPlanAttainment | null;
  awsContracts: ContractModel | null;
  gcpContracts: ContractModel | null;
  settings: CustomerSettings | null;
  revenueEstGCP: string;
  accountManagers: string[];
  trialStatus: TrialStatusDetails | null;
};

export type FlexsaveSavings = {
  aws: FlexsavePlatformItem;
};

export type CloudConnectStatus = {
  bigQueryLens: Status;
  gkeMetering: Status;
};

export type GkeCostAllocationStatus = {
  gkeCostAllocation: Status;
};

export type TrialStatus = {
  trialStatus: TrialStatusDetails;
};

export type TrialStatusDetails = {
  status: TrialStatusValue;
  date: string;
};

export type AccountManagers = {
  accountManagers: string[];
};

export type RampPlanAttainment = {
  total?: number;
  onTrack?: boolean;
  status?: string;
  planName?: string;
  planId?: string;
};

export type AccountHealthModel = {
  activeUsers: number | null;
  loading: boolean;
  lastLogin?: FirestoreTimestamp | null;
  gcpAccess?: CloudConnectGCPAccess;
  awsAccess?: CloudConnectGCPAccess;
  activeTickets: number | null;
};

export type CloudConnectGCPAccess = {
  status: string;
  permissions: string[];
};

export type Column = {
  id: CustomersColumnId;
  hidden?: boolean;
  customizable: boolean;
  title?: string;
};

export type CustomerListItem = Omit<Customer, "ref" | "accountManagers">;

export type BaseCustomerRowListItem = CustomerListItem & BaseCustomerRowProps & Partial<EnrichedCustomerRowProps>;

type ColumnAction =
  | ((ids: string[]) => Promise<any[]>)
  | ((ids: string[], partnerCompany: string | undefined) => Promise<any[]>)
  | ((ids: string[], partnerCompany: string | undefined, isDoer: boolean) => Promise<any[]>);
export type ColumnPromise = Record<ColumnsActions, ColumnAction>;

export type ColumnTypeGkeCostAllocation = {
  columnId: CustomersColumnsID.GkeCostAllocation;
  gkeCostAllocation: Status;
};

export type ColumnTypeAccountManagers = {
  columnId: CustomersColumnsID.AccountManagers;
  accountManagers: string[];
};

export type ColumnTypeBigQueryLens = {
  columnId: CustomersColumnsID.BigqueryLens;
  bigQueryLens: Status;
};

export type ColumnTypeMonthReport = {
  columnId: CustomersColumnsID.ThisMonth;
  report: ModelId<CloudAnalyticsModelWidgetModel>;
};

export type ColumnTypeContract = {
  columnId: CustomersColumnsID.GcpContracts | CustomersColumnsID.AwsContracts;
  contract: WithFirebaseModel<ContractModel>;
};

export type ColumnTypeFlexsave = {
  columnId: CustomersColumnsID.FlexsaveAWS;
  aws: number | string;
};

export type ColumnActionType =
  | (RampPlanAttainment & {
      columnId: CustomersColumnsID.GcpRampPlanAttainment | CustomersColumnsID.AwsRampPlanAttainment;
    })
  | (FlexsaveSavings & { columnId: CustomersColumnsID.FlexsaveAWS })
  | (CloudConnectStatus & { columnId: CustomersColumnsID.BigqueryLens })
  | ColumnTypeContract
  | ColumnTypeGkeCostAllocation
  | ColumnTypeMonthReport
  | ColumnTypeBigQueryLens
  | ColumnTypeAccountManagers;
