import { Redirect } from "react-router-dom";
import Card from "@mui/material/Card";

import { CustomerCacheProvider } from "../../Components/CustomerCache";
import { type PageId } from "../../Navigation/config/pages";
import { CollectionCash } from "./CollectionCash";
import { CollectionDebt } from "./CollectionDebt";
import { useStyles } from "./styles";

const Collection = ({ pageId }: { pageId: PageId }) => {
  const classes = useStyles();

  if (pageId === "collection") {
    return <Redirect to="/debt" />;
  }

  return (
    <Card className={classes.cardBox} style={{ height: "auto" }}>
      {pageId === "collection:debt" && (
        <CustomerCacheProvider>
          <CollectionDebt />
        </CustomerCacheProvider>
      )}
      {pageId === "collection:cash" && <CollectionCash />}
    </Card>
  );
};

export default Collection;
