import { useCallback, useMemo } from "react";

import { Box, Stack } from "@mui/system";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";

import { DraggableContainer } from "../../../DragAndDrop/DraggableContainer";
import { DraggableGroup } from "../../../DragAndDrop/DraggableGroup";
import { type ColumnChange } from "../../types";
import { ColumnDraggableItem } from "./ColumnDraggableItem";

type Props = {
  initialOrder: ColumnChange[];
  onOrderChanged: (changes: ColumnChange[]) => void;
};

export const ColumnsMenuContent = ({ initialOrder, onOrderChanged }: Props) => {
  const handleOrderChanged = useCallback(
    (change: Record<string, string[]>) => {
      const changes: string[] = change.main;
      const newColumnsOrder = initialOrder.map((column) => {
        const clonedColumn = { ...column };
        if (!column.value) {
          return clonedColumn;
        }
        clonedColumn.newIndex = changes.indexOf(column.value);
        return clonedColumn;
      });
      onOrderChanged(newColumnsOrder);
    },
    [initialOrder, onOrderChanged]
  );

  const handleVisibilityChanged = useCallback(
    (index: number, visible: boolean) => {
      const newColumnsOrder = cloneDeep(initialOrder);
      const originalIndex = findIndex(initialOrder, (column) => column.newIndex === index);
      newColumnsOrder[originalIndex].visible = visible;
      onOrderChanged(newColumnsOrder);
    },
    [initialOrder, onOrderChanged]
  );

  const visibilityById = useCallback(
    (id: string): boolean => {
      const index = findIndex(initialOrder, (column) => column.value === id);

      return initialOrder[index].visible ?? false;
    },
    [initialOrder]
  );

  const hideCheckboxById = useCallback(
    (id: string): boolean => {
      const index = findIndex(initialOrder, (column) => column.value === id);

      return initialOrder[index].hideCheckbox ?? false;
    },
    [initialOrder]
  );

  const initialColumnsOrder = useMemo(
    () =>
      orderBy(initialOrder, "newIndex")
        .map((column) => column.value)
        .filter((value) => value !== ""),
    [initialOrder]
  );

  return (
    <DraggableContainer
      values={{
        main: initialColumnsOrder,
      }}
      onItemsOrderChanged={handleOrderChanged}
      renderDraggingItem={(id: string) => (
        <ColumnDraggableItem id={id} visible={visibilityById(id)} grabbing hideCheckbox={hideCheckboxById(id)} />
      )}
    >
      <Stack direction="column" width={350}>
        <DraggableGroup
          id="main"
          renderContent={(items) => (
            <Box px={2} py={1}>
              {items.map(
                (id, index) =>
                  index > 0 && (
                    <ColumnDraggableItem
                      key={id}
                      id={id}
                      visible={visibilityById(id)}
                      hideCheckbox={hideCheckboxById(id)}
                      onVisibilityChange={(visible) => {
                        handleVisibilityChanged(index, visible);
                      }}
                    />
                  )
              )}
            </Box>
          )}
        />
      </Stack>
    </DraggableContainer>
  );
};
