import { useEffect } from "react";

import { type ExtendedMetric } from "@doitintl/cmp-models";

import { AnalyticsAlertCondition } from "../../../../Pages/CloudAnalytics/alerts/AnalyticsAlertCondition";
import { getCalcMetricFromAlert } from "../../../../Pages/CloudAnalytics/alerts/shared";
import { type AnalyticsAlertWithRef } from "../../../../Pages/CloudAnalytics/alerts/types";
import { createDefaultMDOption } from "../../../../Pages/CloudAnalytics/utilities";
import { type MetadataOption, type MetricWSnap } from "../../../../types";
import { useCustomMetrics } from "../useCustomMetrics";

const initAlertCondition = (
  alert: AnalyticsAlertWithRef,
  dimensions: MetadataOption[],
  extendedMetrics: ExtendedMetric[],
  calculatedMetrics: MetricWSnap[]
) => {
  [alert.transform.conditionText, alert.transform.conditionString] = AnalyticsAlertCondition({
    condition: alert.data.config.condition,
    currency: alert.data.config.currency,
    calculatedMetric: getCalcMetricFromAlert(alert.data.config.calculatedMetric, calculatedMetrics),
    selectedExtendedMetric: alert.data.config.extendedMetric,
    extendedMetricOptions: extendedMetrics,
    metric: alert.data.config.metric,
    operator: alert.data.config.operator,
    timeInterval: alert.data.config.timeInterval,
    alertValue: alert.data.config.values[0],
    evaluateByDimension: dimensions.find((d) => d.id === alert.data.config.rows[0]) ?? createDefaultMDOption(),
  });
};

export const useInitializeAlertsConditions = (
  alerts: AnalyticsAlertWithRef[],
  dimensions: MetadataOption[],
  extendedMetrics: ExtendedMetric[]
) => {
  const [metrics] = useCustomMetrics();

  useEffect(() => {
    alerts.forEach((alert) => {
      initAlertCondition(alert, dimensions, extendedMetrics, metrics);
    });
  }, [alerts, dimensions, extendedMetrics, metrics]);
};
