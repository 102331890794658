import { type JSX, useState } from "react";

import { SyncLoader } from "react-spinners";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useGKEAdoption } from "../../hooks/cloudAnalytics/useGKEAdoption";
import useGKECostAllocation from "../../hooks/cloudAnalytics/useGKECostAllocation";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { GKELensEnticement } from "./GKELensEnticement";

type GKELensGuardProps = {
  children: JSX.Element;
};

const GKELensGuard = ({ children }: GKELensGuardProps) => {
  const [gkeCostAllocation] = useGKECostAllocation();
  const [gkeAdoption] = useGKEAdoption();
  const [showEmptyState, setShowEmptyState] = useState(true);
  const isEntitled = useIsFeatureEntitled("lens:gke");

  const theme = useTheme();

  useUpdateEffect(() => {
    if (gkeCostAllocation?.enabled || gkeAdoption?.completedSuccesfully) {
      setShowEmptyState(false);
    }
  }, [gkeAdoption, gkeCostAllocation]);

  if (gkeCostAllocation === undefined || gkeAdoption === undefined) {
    return (
      <Box display="flex" justifyContent="center" flex="1" flexDirection="column" height="70vh" alignItems="center">
        <SyncLoader size={10} color={theme.palette.primary.main} loading />
      </Box>
    );
  }

  if (showEmptyState || !isEntitled) {
    return <GKELensEnticement state={isEntitled ? "activate" : "upgrade"} />;
  }

  return children;
};

export default GKELensGuard;
