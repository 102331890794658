import { Dialog } from "@mui/material";

import { useFullScreen } from "../../utils/dialog";
import { IntroducingThreadsContent } from "./IntroducingThreadsContent";

type Props = {
  onClose: () => void;
};

export const IntroducingThreadsModal = ({ onClose }: Props) => {
  const { isMobile: isSmallScreen } = useFullScreen("sm");

  return (
    <Dialog open={true} fullScreen={isSmallScreen} fullWidth maxWidth="sm" onClose={onClose}>
      <IntroducingThreadsContent isModal={true} onClose={onClose} />
    </Dialog>
  );
};
