import { blue, green, orange, pink } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  tablePaginationContainer: {
    width: "100%",
    textAlign: "right",
  },
  tablePagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  pageSizeSelectContainer: {
    display: "inline-block",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  pageSizeSelectMenu: {
    paddingLeft: theme.spacing(1),
  },
  pageSizeSelectLabel: {
    display: "inline-block",
  },
  navigateButtons: {
    display: "inline-block",
    paddingRight: theme.spacing(2),
  },
  chip: {
    marginLeft: 8,
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
  },
  chipUSD: {
    marginLeft: 8,
    backgroundColor: green[200],
    color: theme.palette.getContrastText(green[200]),
  },
  chipEUR: {
    marginLeft: 8,
    backgroundColor: orange[200],
    color: theme.palette.getContrastText(orange[200]),
  },
  chipAUD: {
    marginLeft: 8,
    backgroundColor: pink[200],
    color: theme.palette.getContrastText(orange[200]),
  },
  chipILS: {
    marginLeft: 8,
    backgroundColor: blue[200],
    color: theme.palette.getContrastText(blue[200]),
  },
  toggleButtonGroup: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  toggleButton: {
    "&:hover": {
      backgroundColor: blue[400],
      color: theme.palette.getContrastText(blue[400]),
    },
    "&$selected": {
      backgroundColor: blue[200],
      color: theme.palette.getContrastText(blue[200]),
    },
    "&$selected:after": {
      backgroundColor: "transparent",
    },
  },
  selected: {},
  cardBox: {
    marginBottom: theme.spacing(1),
  },
  cardTable: {
    marginTop: theme.spacing(1),
  },
}));
