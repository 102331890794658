import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";

import AdviceDark from "../../assets/algolia/advice-darkmode.svg";
import AdviceLight from "../../assets/algolia/advice-lightmode.svg";
import AnalyticsDark from "../../assets/algolia/analytics-darkmode.svg";
import AnalyticsLight from "../../assets/algolia/analytics-lightmode.svg";
import AnomaliesDark from "../../assets/algolia/anomalies-darkmode.svg";
import AnomaliesLight from "../../assets/algolia/anomalies-lightmode.svg";
import InvoicesDark from "../../assets/algolia/invoices-darkmode.svg";
import InvoicesLight from "../../assets/algolia/invoices-lightmode.svg";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";
import type Permissions from "../../utils/permissions";

type CustomLinkProps = {
  avatarLogo: any;
  text: string;
  url: string;
  onClick: () => void;
};

const CustomLink = ({ avatarLogo, text, url, onClick }: CustomLinkProps) => (
  <Button
    component={RouterLink}
    variant="outlined"
    startIcon={<img src={avatarLogo} style={{ maxHeight: "28px", maxWidth: "28px" }} />}
    sx={{ borderColor: "general.outlineBorder", width: "100%", justifyContent: "flex-start", height: "44px" }}
    to={url}
    onClick={onClick}
  >
    <Typography color="text.primary">{text}</Typography>
  </Button>
);

type Link = {
  avatarLogo: any;
  text: string;
  hasPermission: (userRoles: Permissions | null | undefined) => boolean;
  path: string;
};

const links: Link[] = [
  {
    avatarLogo: (isDarkMode) => (isDarkMode ? AnalyticsDark : AnalyticsLight),
    text: "Analytics",
    hasPermission: (userRoles) => !!userRoles?.cloudAnalytics,
    path: "analytics",
  },
  {
    avatarLogo: (isDarkMode) => (isDarkMode ? InvoicesDark : InvoicesLight),
    text: "Invoices",
    hasPermission: (userRoles) => !!userRoles?.invoicesViewer,
    path: "invoices",
  },
  {
    avatarLogo: (isDarkMode) => (isDarkMode ? AnomaliesDark : AnomaliesLight),
    text: "Anomalies",
    hasPermission: (userRoles) => !!userRoles?.anomaliesViewer,
    path: "anomaly",
  },
  {
    avatarLogo: (isDarkMode) => (isDarkMode ? AdviceDark : AdviceLight),
    text: "Support",
    hasPermission: (userRoles) => !!userRoles?.supportRequester,
    path: "support",
  },
].sort((a, b) => a.text.localeCompare(b.text));

type QuickLinksProps = {
  handleClose: () => void;
};

export const QuickLinks = ({ handleClose }: QuickLinksProps) => {
  const { userRoles } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext({ allowNull: true });
  const isDarkMode = useDarkThemeCheck();

  const canSeeLinks = useMemo(
    () =>
      userRoles?.cloudAnalytics ||
      userRoles?.invoicesViewer ||
      userRoles?.anomaliesViewer ||
      userRoles?.supportRequester ||
      isDoitEmployee,
    [isDoitEmployee, userRoles]
  );

  if (!customer || !canSeeLinks) {
    return <></>;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography color="text.primary" fontWeight={500}>
        Quick links
      </Typography>
      <Grid container spacing={1} marginTop={0.5} columns={{ xs: 2, sm: 12 }}>
        {links.map((link, i) => {
          if (isDoitEmployee || link.hasPermission(userRoles)) {
            return (
              <Grid item xs={1} sm={3} key={i}>
                <CustomLink
                  avatarLogo={link.avatarLogo(isDarkMode)}
                  text={link.text}
                  url={`/customers/${customer.id}/${link.path}`}
                  onClick={handleClose}
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
};
