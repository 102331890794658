import { useEffect, useMemo, useState } from "react";

import { Box, Dialog, Typography } from "@mui/material";
import map from "lodash/map";

import { metricSplittingDataCy, metricSplittingTxt } from "../../assets/texts/CloudAnalytics";
import { useMetricSplitsContext } from "../../Pages/CloudAnalytics/Context";
import { type Step, Stepper, type StepState } from "../Stepper";
import ItemToSplit from "./ItemToSplit";
import SplitCostTargets from "./SplitCostTargets";
import SplitDataStore from "./SplitDataStore";
import { type Split, type SplitMode, type SplitTarget } from "./types";
import { updateSplitDataWithInvalidTargetIDs } from "./utils";

const SplitCostModal = () => {
  const {
    onMetricSplitDataSubmit,
    getInvalidTargetsIDs,
    metricSplitModalData,
    metricSplitLabel,
    metricSplits,
    onClose,
  } = useMetricSplitsContext();
  const selectedOrigins = useMemo(() => new Set(map(metricSplits, "origin.id")), [metricSplits]);
  const [stepperState, setStepperState] = useState<StepState[]>(["incomplete", "incomplete"]);
  const [splitData, setSplitData] = useState<Split | undefined>();
  const [step, setStep] = useState(0);
  const originalTargets = metricSplitModalData?.targets ?? [];
  const [shouldRecalculate, setShouldRecalculate] = useState<boolean>(false);
  const [currentStep, getCurrentStep] = useState(0);

  const checkIfShouldRecalculate = (mode: string, targets: SplitTarget[]) =>
    mode === "custom" ? Math.abs(SplitDataStore.sumTargetsValues(targets) - 1) >= 0.01 : false;

  useEffect(() => {
    setShouldRecalculate(checkIfShouldRecalculate(splitData?.mode ?? "", splitData?.targets ?? []));
  }, [splitData?.targets, splitData?.mode]);

  useEffect(() => {
    // check if the split already exists -
    // then, shows the second step
    if (metricSplitModalData?.origin && metricSplitModalData?.mode) {
      setStep(1);
    }

    setSplitData(metricSplitModalData);
  }, [metricSplitModalData]);

  const [origin, setOrigin] = useState<SplitTarget>();

  const handleNewSplitData = (newSplitData: Split): void => {
    setSplitData(newSplitData);
  };

  useEffect(() => {
    if (splitData?.origin && splitData.origin !== origin) {
      setOrigin(splitData.origin);
      updateSplitDataWithInvalidTargetIDs({ splitData, handleNewSplitData, getInvalidTargetsIDs });
    }
  }, [splitData, origin, getInvalidTargetsIDs]);

  const disableSubmit = !splitData?.targets.some((target) => target.checked);

  const onSplitModeChanged = (mode: SplitMode) => {
    setSplitData(SplitDataStore.modeChange(mode)(splitData));
  };

  const onSelectOrigin = (target?: SplitTarget) => {
    setSplitData(SplitDataStore.selectOrigin(target, originalTargets));
  };

  const onCheckedTarget = (label: string) => {
    setSplitData(SplitDataStore.checkedTarget(label));
  };

  const handleCheckAll = (checked: boolean) => {
    setSplitData(SplitDataStore.checkAllTargets(checked));
  };

  const onSubmit = async () => {
    setSplitData((prev) => {
      const data = SplitDataStore.adjustCustomValues()(prev);
      setShouldRecalculate(checkIfShouldRecalculate(data?.mode ?? "", data?.targets ?? []));
      if (!shouldRecalculate) {
        onMetricSplitDataSubmit(data);
      }
      return data;
    });
  };

  const onChangePercentage = (id: string, value: string) => {
    setSplitData((prev) => {
      if (!prev) {
        return prev;
      }
      const temp = { ...prev };
      SplitDataStore.updatePercentage(temp.targets, id, `${+value / 100}`);
      setShouldRecalculate(checkIfShouldRecalculate(temp.mode, temp.targets));
      return temp;
    });
  };

  useEffect(() => {
    const firstStepState = splitData?.origin ? "complete" : "incomplete";
    const secondStepState = currentStep === 1 && !disableSubmit ? "complete" : "incomplete";
    setStepperState([firstStepState, secondStepState]);
  }, [currentStep, disableSubmit, splitData?.origin]);

  const stepComponents = [
    <ItemToSplit
      key="itemToDistribute"
      options={originalTargets}
      isOptionDisabled={(target) => target.id !== origin?.id && selectedOrigins.has(target.id)}
      onSelectOrigin={onSelectOrigin}
      origin={splitData?.origin}
    />,
    <SplitCostTargets
      key="splitCostTargets"
      checkAll={handleCheckAll}
      onCheckedTarget={onCheckedTarget}
      splitData={splitData}
      origin={splitData?.origin}
      splitMode={splitData?.mode}
      onSplitModeChanged={onSplitModeChanged}
      onChangePercentage={onChangePercentage}
    />,
  ];

  const steps: Step[] = [
    {
      children: stepComponents[0],
      label: metricSplittingTxt.ITEM_TO_SPLIT.TITLE,
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: stepComponents[1],
      label: metricSplittingTxt.SPLIT_COST_TARGETS.TITLE,
      order: 1,
      required: true,
      state: stepperState[1],
    },
  ];

  return (
    <Dialog
      open={!!metricSplitModalData}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-cy={metricSplittingDataCy.MODAL}
    >
      <Box>
        <Typography
          variant="h3"
          sx={{
            px: 3,
            pt: 2,
            pb: 3,
          }}
        >
          {metricSplittingTxt.SPLIT} {metricSplitLabel}
        </Typography>

        <Stepper
          loading={false}
          onCancel={onClose}
          onSubmit={onSubmit}
          steps={steps}
          submitButtonLabel={
            shouldRecalculate
              ? metricSplittingTxt.RECALCULATE_BTN_LABEL
              : `${metricSplittingTxt.SPLIT} ${metricSplitLabel}`
          }
          relativeFooter={true}
          disableSubmit={disableSubmit}
          contentSx={{
            px: 3,
            mb: 3,
          }}
          overrideStep={step}
          getCurrentStep={getCurrentStep}
        />
      </Box>
    </Dialog>
  );
};

export default SplitCostModal;
