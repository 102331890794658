import { useContext } from "react";

import { Box } from "@mui/system";

import { ConfirmMenu } from "../../../Menu/ConfirmMenu/ConfirmMenu";
import { ColumnsOrderContext } from "../../Context";
import { ColumnsMenuContent } from "./ColumnsMenuContent";

export const ColumnOrderMenu = () => {
  const [order, updateOrder] = useContext(ColumnsOrderContext);

  return (
    <ConfirmMenu title="Edit columns" buttonText="Columns">
      <Box maxHeight={436} overflow="scroll">
        <ColumnsMenuContent initialOrder={order} onOrderChanged={updateOrder} />
      </Box>
    </ConfirmMenu>
  );
};
