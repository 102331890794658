import { collection, type Reference } from "@doitintl/models-types";

import {
  type AccountManagersCompany,
  type AccountManagersRole,
  type AccountManagersStatus,
  type CustomerModel,
} from "./Customer";

@collection("accountManagers")
export class AccountManagerModel {
  name!: string;

  customer?: {
    name: string;
    _name: string;
    ref: Reference<CustomerModel>;
  };

  company!: AccountManagersCompany;

  email!: string;

  phone?: null | string;

  photoURL?: null | string;

  role!: AccountManagersRole;

  manager?: Reference<AccountManagerModel>;

  status?: AccountManagersStatus;

  /**
   * @deprecated use `manager` information instead.
   */
  lineManager?: string;
}
