import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { CardHeader, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";

type InnerScreenHeaderProps = {
  title: string;
  subtitle: string;
  backPageCallback: () => void;
};

export const InnerScreenHeader = ({ title, subtitle, backPageCallback }: InnerScreenHeaderProps) => (
  <>
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      <CardHeader
        avatar={
          <IconButton
            aria-label="Back"
            onClick={() => {
              backPageCallback();
            }}
            size="large"
          >
            <BackIcon color="primary" />
          </IconButton>
        }
        title={title}
        subheader={subtitle}
      />
    </Grid>
  </>
);
