import { useCallback, useState } from "react";

import {
  type AffectedResources,
  type AnalyticsResources,
  DeleteValidationDialog,
} from "./DeleteAttributionGroupValidationDialog";

export const useDeleteAttributionGroupValidation = (resources: AnalyticsResources) => {
  const [deleteValidationResponse, setDeleteValidationResponse] = useState<AffectedResources[] | null>(null);
  const handleDeleteAttributionGroupValidationResponse = (data: AffectedResources[]) => {
    setDeleteValidationResponse(data);
  };
  const DeleteAttributionGroupValidation = useCallback(() => {
    if (!deleteValidationResponse) {
      return null;
    }

    return (
      <DeleteValidationDialog
        resources={resources}
        deleteValidationResponse={deleteValidationResponse}
        onClose={() => {
          setDeleteValidationResponse(null);
        }}
      />
    );
  }, [deleteValidationResponse, resources]);

  return [DeleteAttributionGroupValidation, handleDeleteAttributionGroupValidationResponse];
};
