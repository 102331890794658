import { type SaaSConsoleType } from "@doitintl/cmp-models";

import { onboardingDocRef } from "../utils";

export async function updateStackCreationState(accountId: string, customerId: string, type: SaaSConsoleType) {
  const updateData = {
    [`accounts.${accountId}.stackCreationState`]: "loading",
  };
  await onboardingDocRef(customerId, type).update(updateData);
}
