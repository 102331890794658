import { amber, green, grey, red } from "@mui/material/colors";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  progressCell: {
    minWidth: 100,
  },
  linearProgressRoot: {
    height: 16,
    borderRadius: theme.spacing(1),
  },
  linearProgressBar: {
    borderRadius: 20,
  },
  greyProgressRoot: {
    backgroundColor: lighten(grey[500], 0.5),
  },
  greyProgressBar: {
    backgroundColor: grey[500],
  },
  greenProgressRoot: {
    backgroundColor: lighten(green[500], 0.5),
  },
  greenProgressBar: {
    backgroundColor: green[500],
  },
  amberProgressRoot: {
    backgroundColor: lighten(amber[500], 0.5),
  },
  amberProgressBar: {
    backgroundColor: amber[500],
  },
  redProgressRoot: {
    backgroundColor: lighten(red[500], 0.5),
  },
  redProgressBar: {
    backgroundColor: red[500],
  },
}));

export default useStyles;
