import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { green, red } from "@mui/material/colors";

import { TrendType } from "../../types";

type Props = {
  type: TrendType;
};

const TrendIcon = ({ type }: Props) => {
  if (type === TrendType.Up) {
    return <ArrowUpwardIcon aria-label="Trend up" style={{ color: green[500], fontSize: 18 }} />;
  }

  return <ArrowDownwardIcon aria-label="Trend down" style={{ color: red[500], fontSize: 18 }} />;
};

export default TrendIcon;
