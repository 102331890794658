import { analyticsAlertText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import { type HeaderColumn } from "../../../types/FilterTable";

export const filterColumns = [
  {
    label: analyticsAlertText.TABLE_HEADERS.ID,
    path: "ref.id",
    requireExactMatch: true,
  },
  {
    label: analyticsAlertText.TABLE_HEADERS.NAME,
    path: "data.name",
  },
  {
    label: analyticsAlertText.TABLE_HEADERS.OWNER,
    path: "transform.owner",
  },
  {
    label: analyticsAlertText.TABLE_HEADERS.LAST_MODIFIED,
    path: "data.timeModified",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: analyticsAlertText.TABLE_HEADERS.LAST_SENT,
    path: "data.timeLastAlerted",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
] as const;

export const headers: readonly HeaderColumn[] = [
  {
    value: "data.name",
    label: analyticsAlertText.TABLE_HEADERS.NAME,
    tooltip: analyticsAlertText.TABLE_HEADERS.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "transform.conditionString",
    label: analyticsAlertText.TABLE_HEADERS.CONDITION,
    tooltip: analyticsAlertText.TABLE_HEADERS.CONDITION,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "",
    label: analyticsAlertText.TABLE_HEADERS.SCOPE,
    tooltip: analyticsAlertText.TABLE_HEADERS.SCOPE,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.timeLastAlerted",
    label: analyticsAlertText.TABLE_HEADERS.LAST_SENT,
    tooltip: analyticsAlertText.TABLE_HEADERS.LAST_SENT,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.timeModified",
    label: analyticsAlertText.TABLE_HEADERS.LAST_MODIFIED,
    tooltip: analyticsAlertText.TABLE_HEADERS.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "transform.owner",
    label: analyticsAlertText.TABLE_HEADERS.OWNER,
    tooltip: analyticsAlertText.TABLE_HEADERS.OWNER,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  // Investigate button
  {
    value: "",
    label: "",
    style: { align: "left", padding: "none" },
  },
  // 3 dots menu - please do not remove
  {
    value: "",
    label: "",
    style: { align: "left", padding: "none" },
  },
];
