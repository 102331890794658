import capitalize from "lodash/capitalize";

import { attributionText, globalText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";

export const filterColumns = [
  {
    label: attributionText.TABLE_HEADERS.ID,
    path: "ref.id",
    requireExactMatch: true,
  },
  {
    label: attributionText.TABLE_HEADERS.NAME,
    path: "data.name",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: attributionText.TABLE_HEADERS.OWNER,
    path: "owner",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: attributionText.TABLE_HEADERS.TYPE,
    path: "data.type",
    toOption: (value) => ({ value, label: capitalize(value) }),
  },
  {
    label: attributionText.TABLE_HEADERS.ANOMALY_DETECTION,
    path: "anomalyDetectionText",
  },
  {
    label: globalText.LAST_MODIFIED,
    path: "data.timeModified",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: attributionText.TABLE_HEADERS.SUBSCRIBED,
    path: "subscribed",
    toOption: (value) => {
      const strValue = value ? "Yes" : "No";
      return {
        value,
        label: strValue,
      };
    },
  },
] as const;

export const headers = [
  {
    value: "data.name",
    label: attributionText.TABLE_HEADERS.NAME,
    tooltip: attributionText.TABLE_HEADERS.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "owner",
    label: attributionText.TABLE_HEADERS.OWNER,
    tooltip: attributionText.TABLE_HEADERS.OWNER,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.type",
    label: attributionText.TABLE_HEADERS.TYPE,
    tooltip: attributionText.TABLE_HEADERS.TYPE,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.anomalyDetection",
    label: attributionText.TABLE_HEADERS.ANOMALY_DETECTION,
    tooltip: attributionText.TABLE_HEADERS.ANOMALY_DETECTION,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "data.timeModified",
    label: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "@",
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
] as const;
