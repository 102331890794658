import { useCallback, useEffect, useState } from "react";

import { MenuItem, TextField } from "@mui/material";
import { useFormikContext } from "formik";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useAsyncLoadingFunction } from "../../../hooks/useAsyncLoadingFunction";
import { type EditBillingProfileData } from "../../BillingProfileForm.models";
import { type PaymentTerm } from "../api";
import { usePriorityAPI } from "../hooks";

export type PaymentTermsFieldProps = {
  priorityCompany: string;
};

export const PaymentTermsField = ({ priorityCompany }: PaymentTermsFieldProps) => {
  const { isDoitEmployee, isDoitOwner } = useAuthContext();
  const priorityApi = usePriorityAPI();
  const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([]);

  const { values, handleChange } = useFormikContext<EditBillingProfileData>();

  const [isLoading, loadPaymentTerms] = useAsyncLoadingFunction(
    useCallback(() => priorityApi.getPaymentTerms(priorityCompany), [priorityApi, priorityCompany])
  );

  useEffect(() => {
    if (isDoitEmployee) {
      (async () => {
        setPaymentTerms(await loadPaymentTerms());
      })();
    }
  }, [isDoitEmployee, loadPaymentTerms]);

  return isDoitEmployee ? (
    <TextField
      label="Payment Terms"
      value={values.payCode}
      name="payCode"
      onChange={handleChange}
      margin="normal"
      variant="outlined"
      disabled={isLoading || !isDoitOwner}
      select
      fullWidth
      size="medium"
    >
      {paymentTerms.map((option) => (
        <MenuItem key={option.PAYCODE} value={option.PAYCODE}>
          {option.PAYDES}
        </MenuItem>
      ))}
    </TextField>
  ) : null;
};
