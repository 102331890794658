import { type JSX } from "react";

import { type ReasonCantEnableAWS } from "@doitintl/cmp-models";

export function never(_: never) {
  return "";
}

export function getMessage(reason: ReasonCantEnableAWS): JSX.Element | string {
  switch (reason) {
    case "":
      return "";
    case "cloudhealth not configured":
      return (
        <>
          We have not been able to detect a valid CloudHealth configuration. Please get in touch with{" "}
          <a href="../support/new">our support team</a> if you need assistance.
        </>
      );
    case "no spend in over thirty days":
      return (
        <>
          We have not been able to detect any eligible AWS on-demand usage. If you think this is incorrect, please get
          get in touch with <a href="../support/new">our support team</a>.
        </>
      );
    case "no contract":
      return "You have no active AWS contracts attached to your account. Please contact your DoiT account manager to upload your AWS contract.";
    case "no spend":
      return "We are collecting and processing your billing data. This will take up to 30 days, after which you will start seeing savings automatically.";
    case "low spend":
      return (
        <>
          Based on your low On-Demand spend, no savings opportunities are currently identified. You will start saving
          when Flexsave is available for you.
        </>
      );
    case "no assets":
      return "You have no consolidated AWS accounts configured. Please add an AWS account to continue.";
    case "no billing profile":
      return "You have no active billing profiles. Please set up a billing profile to continue.";
    case "aws activate credits":
      return (
        <>
          We have detected credits on your account. Flexsave discount results in charges not covered by credits. Contact{" "}
          <a href="../support/new">support</a> if you would still like to activate.
        </>
      );
    case "other":
      return (
        <>
          We have encountered an unexpected error while trying to determine whether Flexsave is available for your
          organization. Please <a href="../support/new">contact our support team</a> for assistance.
        </>
      );
    default:
      return never(reason);
  }
}
