import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

const ClusterPageHeader = () => (
  <Stack direction="row" alignItems="center">
    <Typography variant="h1" display="inline">
      Clusters
    </Typography>
  </Stack>
);

export default ClusterPageHeader;
