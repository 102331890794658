import { useCallback } from "react";

import { useHistory } from "react-router-dom";

import { customersRoute } from "../../Navigation/core/utils";
import { useCustomerId } from "./useCustomerId";

export function useCustomerRouteNavigation() {
  const customerId = useCustomerId();
  const history = useHistory();
  return useCallback(
    (route: string) => {
      if (route) {
        history.push(customersRoute(customerId, route));
      }
    },
    [customerId, history]
  );
}
