import { AccountsTable } from "./AccountsTable/AccountsTable";
import { MarketplaceContext } from "./Context";
import { AWSEntitlementsTable } from "./EntitlementsTable/AWSEntitlementsTable";
import { useMarketplaceAccounts } from "./hooks/useMarketplaceAccounts";

export function AwsMarketplace({ pageId }) {
  const { accountsAndCustomers, loading } = useMarketplaceAccounts();

  return loading ? null : (
    <MarketplaceContext.Provider value={{ accountsAndCustomers }}>
      {pageId === "marketplace:aws:accounts" && <AccountsTable />}
      {pageId === "marketplace:aws:entitlements" && <AWSEntitlementsTable />}
    </MarketplaceContext.Provider>
  );
}
