import { updateReportStatsFirestore } from "../api";

const originClient = "ui";

const updateReportStats = (
  reportId: string,
  serverDurationMs: number | undefined,
  totalBytesProcessed: number | undefined,
  totalDurationMs: number,
  inPresentationMode?: boolean
) => {
  if (serverDurationMs && totalBytesProcessed && totalBytesProcessed > 0 && !inPresentationMode) {
    return updateReportStatsFirestore(reportId, originClient, serverDurationMs, totalBytesProcessed, totalDurationMs);
  }
};

export { updateReportStats };
