import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEKSApi } from "../hooks";
import { type ClusterInfo, type ClusterType } from "../types";
import { addClusterInfo, deleteClusterInfo, editClusterInfo } from "./db";
import { TextFieldWithValidation } from "./TextFieldWithValidation";

const regions = [
  "us-east-1",
  "us-east-2",
  "us-west-1",
  "us-west-2",
  "af-south-1",
  "ap-east-1",
  "ap-south-1",
  "ap-northeast-2",
  "ap-southeast-1",
  "ap-southeast-2",
  "ap-northeast-1",
  "ca-central-1",
  "eu-central-1",
  "eu-west-1",
  "eu-west-2",
  "eu-west-3",
  "eu-north-1",
  "me-south-1",
  "sa-east-1",
];

type Props = {
  value?: ClusterInfo;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function ClusterDialog({ value, open, setOpen }: Props) {
  const customerId = useCustomerId();
  const eksApi = useEKSApi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [accountId, setAccountId] = useState(value?.accountId);
  const [clusterName, setClusterName] = useState(value?.clusterName);
  const [region, setRegion] = useState<string | null>(value?.region || null);
  const [clusterType, setClusterType] = useState<ClusterType>("ec2");

  const editMode = Boolean(value);

  const title = editMode ? "Edit cluster" : "Add cluster";
  const actionButtonText = editMode ? "Save" : "Add cluster";
  const actionButtonDisable = !accountId || !clusterName || !region;

  const { isDoitEmployee } = useAuthContext();
  const handleClose = () => {
    setOpen(false);
  };

  const handleActionButtonClicked = async () => {
    if (!actionButtonDisable) {
      const newClusterInfo: ClusterInfo = {
        accountId,
        clusterName,
        region,
        isEc2Cluster: clusterType === "ec2",
      };

      setLoading(true);
      setError(false);
      if (editMode && value) {
        await editClusterInfo(customerId, value, newClusterInfo);
      } else {
        await addClusterInfo(customerId, newClusterInfo);
      }

      const success = await eksApi.syncCluster(newClusterInfo);
      setLoading(false);

      if (success) {
        setOpen(false);
      } else {
        setError(true);
        deleteClusterInfo(customerId, newClusterInfo);
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box width={400}>
          <Stack spacing={2} py={1}>
            <TextFieldWithValidation
              fullWidth
              inputProps={{ maxLength: 12 }}
              validationRegex={/^\d{12}$/}
              validationErrorMessage="Account ID must be 12 digits"
              required
              label="Account ID"
              value={accountId}
              onChange={(e) => {
                setAccountId(e.target.value);
              }}
            />
            <TextFieldWithValidation
              required
              fullWidth
              validationRegex={/^[a-zA-Z0-9-]+$/}
              validationErrorMessage="Only characters, digits, and hyphens are allowed."
              label="Cluster name"
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
            <Autocomplete
              options={regions}
              value={region}
              onChange={(event, newValue) => {
                setRegion(newValue);
              }}
              renderInput={(params) => <TextField required {...params} label="Region" />}
            />
            <Stack>
              <Typography variant="subtitle1" fontWeight="500">
                Cluster type
              </Typography>
              <RadioGroup
                sx={{ pl: 2 }}
                defaultValue="ec2"
                value={clusterType}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setClusterType(e.target.value as ClusterType);
                }}
              >
                <FormControlLabel value="ec2" control={<Radio />} label="EC2 (not EKS)" />
                {isDoitEmployee && <FormControlLabel value="eks" control={<Radio />} label="EKS" />}
              </RadioGroup>
            </Stack>
          </Stack>
          {error && (
            <Alert severity="error">
              We were unable to find a cluster with these details. Ensure all details are correct and try again .
            </Alert>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={handleActionButtonClicked}
          disabled={actionButtonDisable}
        >
          {actionButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
