import React, { useState } from "react";

import { Zoom } from "react-awesome-reveal";
import { type AvaFeedback } from "@doitintl/cmp-models";
import ThumbDownAltFilledIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import ThumbUpAltFilledIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import { IconButton, Stack, Tooltip } from "@mui/material";

type Props = {
  feedback?: AvaFeedback;
  disabled: boolean;
  onClick: (positive: boolean) => void;
  disableAnimation?: boolean;
};

export const ToggleLikeDislikeButton = ({ feedback, onClick, disabled, disableAnimation = false }: Props) => {
  const [feedbackType, setFeedbackType] = useState<"positive" | "negative" | undefined>(
    feedback === undefined ? undefined : feedback.positive ? "positive" : "negative"
  );

  const handlePositiveFeedback = () => {
    setFeedbackType("positive");
    onClick(true);
  };

  const handleNegativeFeedback = () => {
    setFeedbackType("negative");
    onClick(false);
  };

  return (
    <Tooltip title={disabled ? "Feedback already submitted" : ""} arrow placement="top">
      <Stack direction="row">
        <Zoom
          cascade
          duration={disableAnimation ? 0 : 400}
          delay={disableAnimation ? 0 : 300}
          damping={0.8}
          triggerOnce
        >
          {feedbackType !== "negative" && (
            <Tooltip title={feedback?.text}>
              <span>
                <IconButton
                  onClick={handlePositiveFeedback}
                  disabled={disabled || Boolean(feedback)}
                  sx={{ color: "primary.main" }}
                  data-cy="positive-feedback"
                >
                  {feedbackType === "positive" ? (
                    <ThumbUpAltFilledIcon fontSize="small" />
                  ) : (
                    <ThumbUpAltOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}

          {feedbackType !== "positive" && (
            <Tooltip title={feedback?.text}>
              <span>
                <IconButton
                  onClick={handleNegativeFeedback}
                  disabled={disabled || Boolean(feedback)}
                  sx={{ color: "primary.main" }}
                  data-cy="negative-feedback"
                >
                  {feedbackType === "negative" ? (
                    <ThumbDownAltFilledIcon fontSize="small" />
                  ) : (
                    <ThumbDownAltOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Zoom>
      </Stack>
    </Tooltip>
  );
};
