import { useCallback, useState } from "react";

import { DialogContent, TextField } from "@mui/material";

import { globalText } from "../../assets/texts";
import SimpleDialog from "../SimpleDialog";

type DescriptionDialogProps = {
  open: boolean;
  description: string;
  onClose: () => void;
  onConfirm: ({ target: { name, value } }: any) => void;
  maxLength: number;
};

const DescriptionDialog = ({ open, description, onClose, onConfirm, maxLength }: DescriptionDialogProps) => {
  const [value, setValue] = useState(description ?? "");

  const handleClose = useCallback(() => {
    setValue(description);
    onClose();
  }, [description, onClose]);

  const handleChange = useCallback(({ target: { value } }) => {
    setValue(value);
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm({ target: { value, name: "description" } });
  }, [onConfirm, value]);

  return (
    <SimpleDialog
      open={open}
      title={globalText.DESCRIPTION}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      dialogProps={{ fullWidth: true }}
      titleCy="description-dialog-title"
      confirmButtonCy="description-dialog-confirm-button"
    >
      <DialogContent sx={{ mt: 2 }} data-cy="description-dialog-content">
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={value}
          onChange={handleChange}
          inputProps={{ maxLength }}
          multiline={true}
          minRows={1}
          maxRows={5}
        />
      </DialogContent>
    </SimpleDialog>
  );
};

export default DescriptionDialog;
