import isEqual from "lodash/isEqual";

import { type AsgConfigChange, type AsgConfigurationSummary } from "../types";

const sortArrayFields = (change: AsgConfigChange) => {
  change.excludedInstanceTypes = change.excludedInstanceTypes?.sort() ?? [];
  change.excludedSubnets = change.excludedSubnets?.sort() ?? [];
  change.includedInstanceTypes = change.includedInstanceTypes?.sort() ?? [];
  change.includedSubnets = change.includedSubnets?.sort() ?? [];
  return change;
};

export function hasChangeInConfiguration(config: AsgConfigurationSummary, change?: AsgConfigChange) {
  if (!change) {
    return false;
  }

  const originalConfig: AsgConfigChange = {
    excludedInstanceTypes: config.excludedInstanceTypes,
    excludedSubnets: config.excludedSubnets,
    includedInstanceTypes: config.instanceTypes,
    includedSubnets: config.subnets,
    onDemandBaseCapacity: config.onDemandBaseCapacity,
    onDemandPercentageAboveBaseCapacity: config.onDemandPercentageAboveBaseCapacity,
  };

  return !isEqual(sortArrayFields(originalConfig), sortArrayFields({ ...change }));
}
