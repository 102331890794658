import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeGSuite, AssetTypeMicrosoftAzure } from "./Asset";

export type PerkModule = {
  description: string;
  title: string;
};

export const perkPlatformList = [
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
] as const;
export type PerkPlatform = (typeof perkPlatformList)[number];
export const isPerkPlatform = (value: string): value is PerkPlatform =>
  new Set(perkPlatformList).has(value as PerkPlatform);

export const perkPlatformListShortened: Record<PerkPlatform, string> = {
  [AssetTypeAmazonWebServices]: "aws/platform",
  [AssetTypeGoogleCloud]: "gcp/platform",
  [AssetTypeGSuite]: "gsuite/platform",
  [AssetTypeMicrosoftAzure]: "azure/platform",
};
export type PerkPlatformUrl = Partial<Record<PerkPlatform, string>>;

export enum PerkTypes {
  Accelerator = "accelerator",

  Training = "training",

  /**
   * @deprecated use {@link Training} instead.
   */
  Promotion = "promotion",

  /**
   * @deprecated use {@link Workshop} instead.
   */
  ProServ = "pro-serv",

  IsvSolution = "isv-solution",

  Workshop = "workshop",
}
export type PerkType = `${PerkTypes}`;
export const isPerkType = (value: string): value is PerkType =>
  new Set(Object.values(PerkTypes)).has(value as PerkTypes);

export const professionalServicePerkTypes: PerkType[] = [PerkTypes.Accelerator, PerkTypes.Training, PerkTypes.Workshop];

export const perkSolutionTypeList = [
  "analytics",
  "big-data",
  "developer-tools",
  "security-and-monitoring",
  "networking",
  "ml-and-ai",
  "database",
  "compute",
  "blog-and-cms",
  "data-sources",
  "operating-systems",
  "storage",
  "maps",
  "advertising-and-gtm",
  "other",
] as const;

export type PerkSolutionType = (typeof perkSolutionTypeList)[number];
export const isPerkSolutionType = (value: string): value is PerkSolutionType =>
  new Set(perkSolutionTypeList).has(value as PerkSolutionType);

export type PerkEnrichment = {
  logoUrl?: string;
  providerIsDoitCustomer?: boolean;
};

export type PerkField = {
  active: boolean;
  checklist: string;
  description: string;
  shortDescription?: string;
  title: string;
  name: string;
  platform: PerkPlatform[];
  platformUrl?: PerkPlatformUrl;
  solutionType?: PerkSolutionType[];
  type: PerkType;
  videoUrl?: string;
  deleted: boolean;
  zendeskTags: string[];
  /**
   * @deprecated if the professional service is an accelerator set {@link type} to {@link PerkTypes.Accelerator} instead.
   */
  acceleratorProgram?: boolean;
};

type CustomerId = string;

type TicketId = number;

@subCollection("requestedUsers")
export class PerkRequestedUsersModel {
  ticketId?: number;

  ticketsOnBehalfOfCustomers?: Record<CustomerId, TicketId>;
}

@collection("perks")
export class PerkModel {
  agenda!: string;

  enrichment?: PerkEnrichment;

  fields!: PerkField;

  modules?: PerkModule[];

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  timePublished!: Timestamp | null;

  subCollections?: {
    requestedUsers: PerkRequestedUsersModel;
  };
}
