import { Metadata } from "@doitintl/cmp-models";

import { parseNullFallbackId } from "../../Pages/CloudAnalytics/utilities";
import { type AttributionWRef, type MetadataOption } from "../../types";
import { DEFAULT_SPLIT_MODE, type Split, type SplitTarget } from "./types";

export const checkedTargetsLength = (targets: SplitTarget[]) => targets.filter((target) => target.checked).length;
export const roundToDecimalPercentage = (num: number) => `${Math.round(num * 10000) / 100}`;

export const updateTargetsValues = (targets: SplitTarget[]) => {
  const value = 1 / checkedTargetsLength(targets || []);
  return targets?.map((target) => ({
    ...target,
    value: target.checked ? `${value}` : "0",
  }));
};

export const createTargetsFromAttributions = (
  md: MetadataOption | undefined,
  attributions: AttributionWRef[],
  targetsObj?: { [key: string]: SplitTarget }
): SplitTarget[] => {
  const targets = (md?.data.values || []).map((id) => {
    const attribution = attributions.find((a) => a.ref.id === id);
    const splitID = `${Metadata.ATTRIBUTION}:${attribution?.ref.id}`;
    let value = "0";
    let checked = true;
    if (targetsObj) {
      checked = !!targetsObj[splitID];
      value = targetsObj[splitID]?.value || "0";
    }
    const target: SplitTarget = {
      id: splitID,
      label: attribution?.data?.name ?? "",
      checked,
      value,
    };

    return target;
  });
  if (md?.data.nullFallback) {
    const nullFallback: SplitTarget = {
      id: `${md.data.nullFallback}-${md.id}`,
      label: md.data.nullFallback,
      checked: !!targetsObj?.[md.data.nullFallback],
      value: targetsObj?.[md.data.nullFallback]?.value ?? "0",
      isNullFallback: true,
    };
    targets.unshift(nullFallback);
  }

  return targets;
};

export const createTargetsFromDimensions = (
  md: MetadataOption | undefined,
  transform: any,
  targetsObj?: {
    [key: string]: SplitTarget;
  }
) => {
  const targets = (md?.data.values || []).map((valueLabel) => {
    const splitID = valueLabel;
    let value = "0";
    let checked = true;

    if (targetsObj) {
      checked = !!targetsObj[splitID];
      value = targetsObj[splitID]?.value || "0";
    }

    const target: SplitTarget = {
      id: splitID,
      label: transform?.(valueLabel, md?.data.nullFallback) ?? valueLabel,
      checked,
      value,
    };

    return target;
  });

  if (md?.data.nullFallback) {
    const nullFallback: SplitTarget = {
      id: `${md.data.nullFallback}-${md.id}`,
      label: md.data.nullFallback,
      checked: !!targetsObj?.[md.data.nullFallback],
      value: targetsObj?.[md.data.nullFallback]?.value ?? "0",
      isNullFallback: true,
    };
    targets.unshift(nullFallback);
  }

  return targets;
};

export const createMetricSplits = (
  md: MetadataOption,
  attributions: AttributionWRef[],
  transforms: Record<string, any>
) => {
  let targets: SplitTarget[];

  if (md.data.type === Metadata.ATTRIBUTION_GROUP) {
    targets = createTargetsFromAttributions(md, attributions);
  } else {
    const transform = transforms?.[md.id] ?? null;
    targets = createTargetsFromDimensions(md, transform);
  }

  const split: Split = {
    id: md.id,
    mode: DEFAULT_SPLIT_MODE,
    targets: updateTargetsValues(targets),
    type: md.data.type,
    includeOrigin: true,
    nullFallbackLabel: md.data.nullFallback,
  };

  return split;
};

export const updateSplitDataWithInvalidTargetIDs = async ({
  splitData,
  handleNewSplitData,
  getInvalidTargetsIDs,
}: {
  splitData: Split;
  handleNewSplitData: (split: Split) => void;
  getInvalidTargetsIDs: (split: Split) => Promise<Set<string>>;
}): Promise<void> => {
  const invalidTargetIDs = await getInvalidTargetsIDs(splitData);

  const newSplitData = { ...splitData };

  newSplitData.targets.forEach((target) => {
    const parsedId = parseNullFallbackId(target.id, target.isNullFallback, target.label, newSplitData.type);
    const isDisabled = invalidTargetIDs.has(parsedId !== undefined ? parsedId : target.id);
    target.disabled = isDisabled;
    target.checked = target.checked && !isDisabled;
  });

  newSplitData.targets = updateTargetsValues(newSplitData.targets);

  handleNewSplitData(newSplitData);
};
