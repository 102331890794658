import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TableCell, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { assetTypeName } from "../../../../utils/common";
import { type OnHoldRowData } from "./types";

const OnHoldRow = ({ row, onDelete }: { row: OnHoldRowData; onDelete: () => void }) => (
  <>
    <TableCell data-cy={`${row.customerId}-${row.cloud}-customer`}>
      <Typography component={Link} to={`/customers/${row.customerId}`} target="_blank" variant="body2" color="inherit">
        {row.primaryDomain}
      </Typography>
    </TableCell>
    <TableCell data-cy={`${row.customerId}-${row.cloud}-cloud`}>{assetTypeName(row.cloud)}</TableCell>
    <TableCell data-cy={`${row.customerId}-${row.cloud}-create-time`}>
      {row.createTime.toLocaleString(DateTime.DATE_MED)}
    </TableCell>
    <TableCell data-cy={`${row.customerId}-${row.cloud}-email`}>{row.email}</TableCell>
    <TableCell data-cy={`${row.customerId}-${row.cloud}-note`} sx={{ maxWidth: "200px", wordWrap: "break-word" }}>
      {row.note}
    </TableCell>
    <TableCell>
      <IconButton
        aria-label="Remove hold"
        onClick={onDelete}
        size="large"
        data-cy={`${row.customerId}-${row.cloud}-remove-hold`}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </TableCell>
  </>
);
export default OnHoldRow;
