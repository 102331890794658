import { useEffect } from "react";

import { Link } from "react-router-dom";
import { Box, Card, CardContent, Link as MUILink, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import mixpanel from "../../../utils/mixpanel";
import ThreadThreeDotsMenu from "../ThreeDotsMenu/ThreadThreeDotsMenu";
import { type EnrichedThread } from "../types";
import { ErroredLinkedThread } from "./ErroredLinkedThread";
import { JiraStatusChip } from "./JiraStatusChip";
import { SkeletonLinkedThread } from "./SkeletonLinkedThread";

interface LinkedThreadProps {
  thread: EnrichedThread;
}

const LinkedThread: React.FunctionComponent<LinkedThreadProps> = ({ thread }) => {
  const { customer } = useCustomerContext();

  useEffect(() => {
    mixpanel.track("threads.insight-linked.view");
  }, []);

  if (thread?.jiraIssue?.isError) {
    return <ErroredLinkedThread threadJiraUrl={thread.targetData.issueURL} />;
  }

  if (!thread?.jiraIssue?.data) {
    return <SkeletonLinkedThread />;
  }

  const date = new Date(thread.jiraIssue?.data?.createdDate).toLocaleDateString(navigator.language);

  return (
    <Card>
      <CardContent sx={{ p: 2 }}>
        <Box display="flex">
          <Stack spacing={1} flexGrow={1}>
            <MUILink component={Link} to={`/customers/${customer.id}/threads/${thread.id}`}>
              {thread.jiraIssue?.data?.summary}
            </MUILink>
            <Box display="flex" gap={1}>
              <Typography variant="body2" color="textSecondary">
                {date}
              </Typography>
              <JiraStatusChip
                status={thread.jiraIssue?.data?.status}
                statusCategory={thread.jiraIssue?.data?.statusCategory}
              />
            </Box>
          </Stack>
          <ThreadThreeDotsMenu threadId={thread.id} targetUrl={thread.targetData.issueURL} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LinkedThread;
