import { useCallback, useState } from "react";

import { type Option, type PackageOptions } from "./type";

export const usePackageSelection = (initialOptions: PackageOptions) => {
  const [packageOptions, setPackageOptions] = useState<PackageOptions>(initialOptions);

  const handlePackageSelection = useCallback((group: keyof PackageOptions, option: Option) => {
    setPackageOptions((prevState) => ({
      ...prevState,
      [group]: prevState[group].includes(option.value)
        ? prevState[group].filter((item) => item !== option.value)
        : [...prevState[group], option.value],
    }));
  }, []);

  return {
    packageOptions,
    handlePackageSelection,
  };
};
