import { useState, useMemo, useEffect } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import find from "lodash/find";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SolvedIcon from "@mui/icons-material/Check";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import EscalateIcon from "@mui/icons-material/NotificationsActiveRounded";
import Alert from "@mui/material/Alert";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import SurveyDialog from "../Components/SurveyDialog";
import ConfirmDialog from "../Components/ConfirmDialog";
import EscalateDialog from "../Components/EscalateDialog";
import CommentCard from "../Components/CommentCard";
import NewCommentCard from "../Components/NewCommentCard";
import MobileFab from "../Components/MobileFab";
import { useAuthContext } from "../../Context/AuthContext";

import {
  changeCategoryInfo,
  changeTicketCategory,
  doneSurvey,
  getComments,
  getTicketDetails,
  handleChangeSeverity,
  initTicketDetails,
  saveCategory,
  savePriority,
  sendComment,
  sendEscalate,
  sendSurvey,
  sendTagUpdate,
  subscribeComments,
  toggleLoading,
  unsubscribeComments,
} from "../Actions/TicketAction";
import TicketTimeLine from "../Components/TicketTimeLineNew";
import TicketDetailsExpansionPanels from "../Components/TicketDetailsExpansionPanels";
import TicketDetailsTable from "../Components/TicketDetailsTable";
import SnackbarLoader from "../Components/SnackbarLoader";
import mixpanel from "../../utils/mixpanel";
import { useCustomerContext } from "../../Context/CustomerContext";
import { copyToClipboard, noAccess } from "../../utils/common";
import { helpURLs } from "../../assets/urls";
import Hide from "../../Components/HideChildren/Hide";
import { useFullScreen } from "../../utils/dialog";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useTranslation } from "react-i18next";

const drawerWidth = 380;
const noAccessText = [""];

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  backCard: {
    marginTop: -20,
    marginLeft: -10,
  },
  progressContainer: {
    marginTop: theme.spacing(1),
    marginBottom: 60,
  },
  contentDetails: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    marginLeft: drawerWidth,
    marginTop: 45,
    height: 105,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: "auto",
    zIndex: 0,
  },
  ticketHeader: {
    flexGrow: 1,
    marginTop: theme.spacing(-1),
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(-0.5),
    paddingLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    zIndex: theme.zIndex.appBar + 1,
  },
  ticketHeaderFull: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  toolbar: theme.mixins.toolbar,
  ticketTitleToolbar: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-start",
    paddingTop: 18,
    paddingLeft: 30,
    alignItems: "flex-start",
  },
  rightActionButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardActions: {
    justifyContent: "flex-end",
    minHeight: 50,
    [theme.breakpoints.down("md")]: {
      minHeight: 0,
      padding: 2,
    },
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  ticketActionsBox: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginBottom: 10,
    width: "100%",
    marginTop: 10,
  },
  conversationTitle: {
    marginTop: theme.spacing(2),
  },
  shrink: {
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "1em",
      // lineHeight: '1.2em',
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
  ticketCardHeader: {
    paddingBottom: 0,
  },
  heading: {
    marginLeft: theme.spacing(2),
  },
  openDrawer: {
    paddingTop: 8,
    paddingLeft: 4,
  },
  alert: {
    marginTop: 5,
    marginBottom: 7,
    "&.MuiAlert-root": {
      "& .MuiAlert-action": {
        marginLeft: -10,
        "& .MuiButton-startIcon": {
          marginRight: 0,
        },
      },
    },
  },
  alertBtn: {
    paddingRight: "10px",
    marginLeft: "10px",
  },
  center: {
    display: "flex",
    alignItems: "center",
  },
  copyIcon: {
    margin: "0 5px",
  },
  code: {
    padding: 24,
    backgroundColor: theme.palette.background.default,
    border: 0,
    overflow: "auto",
    font: "500 90%/1 Roboto Mono,monospace",
    fontSize: 14,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    margin: "15px 0",
  },
  dialogBodyCopyIcon: {
    marginRight: 16,
  },
}));

const buildEscalateButton = (isEscalated, setIsEscalateOpen, isLoading, t) => (
  <Button
    disabled={isEscalated || isLoading}
    color="primary"
    variant={isEscalated ? "text" : "outlined"}
    size="small"
    onClick={() => setIsEscalateOpen(true)}
    endIcon={isEscalated ? <EscalateIcon /> : undefined}
  >
    {isEscalated ? t("TicketDetailsContainer.escalated") : t("TicketDetailsContainer.escalate")}
  </Button>
);

const TicketDetailsContainer = (props) => {
  const { toggleLoading, initTicketDetails, getTicketDetails, getComments, subscribeComments, unsubscribeComments } =
    props;
  const { id } = useParams();
  const { onOpen: showSnackbar } = useSnackbar();
  const history = useHistory();
  const { isMobile } = useFullScreen();
  const classes = useStyles({ isMobile });
  const [isEscalateOpen, setIsEscalateOpen] = useState(false);
  const [isMarkSolvedOpen, setIsMarkSolvedOpen] = useState(false);
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);
  const { customer } = useCustomerContext();
  const { isMobile: smDown } = useFullScreen("sm");
  const { isMobile: xsDown } = useFullScreen("xs");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [concedefyEmail, setConcedefyEmail] = useState("");
  const [grantAccessDialogOpen, setGrantAccessDialogOpen] = useState(false);
  const { isDoitEmployee, currentUser, impersonate } = useAuthContext();
  const { t } = useTranslation("services");

  const actions = [
    { icon: <SolvedIcon />, name: t("TicketDetailsContainer.solved"), id: "solved", show: false },
    { icon: <EscalateIcon />, name: t("TicketDetailsContainer.escalate"), id: "esclate", show: false },
  ];

  const isRequester = useMemo(() => {
    const requesterId = props.ticketDetails?.requester_id;
    if (!requesterId) {
      return false;
    }
    const email = props.users.find((u) => requesterId === u.id)?.email;
    return currentUser.email === email;
  }, [currentUser.email, props.ticketDetails, props.users]);

  useEffect(() => {
    toggleLoading(true);
    initTicketDetails();
    getTicketDetails(props.match.params.id);
    getComments(props.match.params.id);
    subscribeComments(props.match.params.id, isDoitEmployee, customer.id);
    mixpanel.track("support.ticket.open", { ticketID: props.match.params.id });
    return () => {
      unsubscribeComments(props.match.params.id);
    };
  }, [
    getComments,
    subscribeComments,
    unsubscribeComments,
    getTicketDetails,
    initTicketDetails,
    props.match.params.id,
    toggleLoading,
    isDoitEmployee,
    customer.id,
  ]);

  useEffect(() => {
    if (!projectId && props.ticketDetails?.categoryObject?.categoryInfo) {
      setProjectId(props.ticketDetails?.categoryObject?.categoryInfo);
    }
    if (props.ticketDetails?.fields) {
      const concedefyEmailObj = props.ticketDetails?.fields.filter((obj) => obj.id === 360034001171)[0];
      if (concedefyEmailObj?.value) {
        setConcedefyEmail(concedefyEmailObj.value);
      }
    }
  }, [props.ticketDetails?.categoryObject?.categoryInfo, props.ticketDetails?.fields, projectId]);

  useEffect(() => {
    if (props.match.params.rate && props.match.params.rate.indexOf("rate") > -1) {
      setIsSurveyOpen(true);
    }
  }, [isRequester, props.match.params]);

  useEffect(() => {
    if (props.isDoneSurvey) {
      props.doneSurvey(false);
      props.history.push(`/customers/${props.match.params.customerId}/support`);
    }
  });
  useEffect(() => {
    if (props.ticketDetails?.error) {
      noAccess(history, noAccessText, helpURLs.GENERAL_HELP);
    }
  }, [history, props.ticketDetails]);

  const submitComment = (body, solved, attachments, collaborators) => {
    const commentObj = {
      body,
      solved,
      attachments,
      collaborators,
      id: props.match.params.id,
    };
    props.sendComment(commentObj);
    mixpanel.track("support.ticket.comment.new", { comment: commentObj });
    if (solved) {
      mixpanel.track("support.ticket.resolve", { id: props.match.params.id });
      if (isRequester) {
        setIsSurveyOpen(true);
      }
    }
  };
  const submitEscalate = (requestID, reason, comment) => {
    const escalate = {
      id: requestID,
      reason,
      comment,
    };
    props.sendEscalate(escalate);
  };

  const submitMarkAsSolved = () => {
    const solved = {
      solved: true,
      id: props.match.params.id,
    };
    props.sendComment(solved);
    mixpanel.track("support.ticket.resolve", { id: props.match.params.id });
    setIsMarkSolvedOpen(false);
    if (isRequester) {
      setIsSurveyOpen(true);
    }
  };

  /**
   * @param score {"good" | "bad"} Feedback score
   * @param comment {string} Feedback comment
   * @param charityTag {string | null} Tag for charitable donation
   * @param vendorScore {"good" | "bad" | ""} Score for the vendor
   * @param vendorScoreChanged {boolean} Whether the vendor score was changed
   * @param charityChanged {boolean} Whether the charity tag was changed
   */
  const submitSurvey = ({ score, comment, vendorScore, charityTag = null, vendorScoreChanged, charityChanged }) => {
    const obj = {
      id: props.match.params.id,
      score,
      comment,
    };

    const newTags = [];
    charityChanged && newTags.push(charityTag);
    vendorScoreChanged && newTags.push(vendorScore);

    if (charityChanged || vendorScoreChanged) {
      props.sendTagUpdate({ id: props.match.params.id, tags: newTags, charityChanged, vendorScoreChanged });
    }

    props.sendSurvey(obj);
  };

  const handleCloseDialogs = () => {
    setIsEscalateOpen(false);
    setIsSurveyOpen(false);
  };

  const handleActionClick = (id) => {
    switch (id) {
      case "solved":
        setIsMarkSolvedOpen(true);
        break;
      case "esclate":
        setIsEscalateOpen(true);
        break;

      default:
        break;
    }
  };
  // Ticket is considered escalated when the satisfaction group is on CC.
  // 360694055446 is the satisfaction group user id.
  const isEscalated = props?.ticketDetails?.email_cc_ids?.includes(360694055446);
  const isShowMarkSolved = !["new", "closed", "solved"].includes(props.ticketDetails.status);
  const isShowEscalate = !["solved", "closed"].includes(props.ticketDetails.status);
  actions[0].show = isShowMarkSolved;
  actions[1].show = isShowEscalate;

  const platform = find(props.ticketDetails?.fields, { id: 360000148226 })?.value;

  const grantAccessDialogBody = (platform) => {
    if (platform === "google_cloud_platform") {
      return (
        <>
          <Typography component="span">
            {t("TicketDetailsContainer.grantGCPAccess")}{" "}
            <Link target="_blank" href={helpURLs.GRANT_GCP_ACCESS}>
              {t("TicketDetailsContainer.learnMore")}
            </Link>
          </Typography>
          <span onClick={(e) => copyToClipboard(e, showSnackbar)} className={classes.code}>
            <FileCopyOutlinedIcon id="icon" className={classes.dialogBodyCopyIcon} fontSize="small" />
            gcloud projects add-iam-policy-binding {`${projectId}`} \
            <br />
            --member="group:{`${concedefyEmail}`}" \
            <br />
            --role=roles/viewer --condition=None
          </span>
        </>
      );
    }
    if (platform === "amazon_web_services") {
      return (
        <>
          <Typography component="span">
            {t("TicketDetailsContainer.grantAWSAccess")}{" "}
            <Link target="_blank" href={helpURLs.GRANT_AWS_ACCESS}>
              {" "}
              {t("TicketDetailsContainer.learnMore")}
            </Link>
          </Typography>
        </>
      );
    }
  };

  const grantAccessAlertContent = (
    <Button
      onClick={() => setGrantAccessDialogOpen(true)}
      startIcon={<OpenNewIcon className={classes.copyIcon} fontSize="small" />}
      color="inherit"
      size="small"
    >
      {" "}
      {t("TicketDetailsContainer.learnMore")}
    </Button>
  );

  const installSupportRole = () => {
    setGrantAccessDialogOpen(false);
    window.open("https://go.doit.com/aws-ops-cfn", "_blank");
  };

  const notClosed = props.ticketDetails.status !== "closed";
  const showGCAlert = platform === "google_cloud_platform" && concedefyEmail && notClosed;
  const showAWSAlert = platform === "amazon_web_services" && notClosed;

  return (
    <>
      <Hide mdDown>
        <Drawer
          variant="persistent"
          open={openSidebar}
          anchor="right"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.contentDetails}>
            <TicketDetailsTable users={props.users} ticketDetails={props.ticketDetails} />
            <TicketDetailsExpansionPanels
              handleChangeSeverity={props.handleChangeSeverity}
              data={props.ticketDetails}
              savePriority={props.savePriority}
              categories={props.categories}
              platforms={props.platforms}
              handleChangeTicketCategory={props.changeTicketCategory}
              saveCategory={props.saveCategory}
              clientProductsProperties={props.clientProductsProperties}
              changeCategoryInfo={props.changeCategoryInfo}
            />
            <div className={classes.progressContainer}>
              <TicketTimeLine ticketTimelineData={props.ticketTimelineData} />
            </div>
          </div>
        </Drawer>
      </Hide>
      <EscalateDialog
        requestID={props.ticketDetails.id}
        open={isEscalateOpen}
        onClose={handleCloseDialogs}
        onSubmit={submitEscalate}
        loading={props.isLoading}
      />
      <ConfirmDialog
        open={isMarkSolvedOpen}
        title={t("TicketDetailsContainer.confirmDialog")}
        text={t("TicketDetailsContainer.undoComment")}
        acceptTitle={t("TicketDetailsContainer.solved")}
        cancelTitle={t("TicketDetailsContainer.cancel")}
        onCancel={() => setIsMarkSolvedOpen(false)}
        onAccept={submitMarkAsSolved}
        loading={props.isLoading}
      />
      <SurveyDialog
        requestID={props.ticketDetails.id}
        open={isSurveyOpen}
        handleSubmit={submitSurvey}
        onClose={handleCloseDialogs}
        isRequester={isRequester}
        requester={props.users.find((u) => props.ticketDetails?.requester_id === u.id)?.email}
        impersonateFeatureActive={impersonate !== undefined}
        requestSummary={props.ticketDetails.subject}
        tags={props.ticketDetails.tags}
        satisfaction={props.ticketDetails.satisfaction_rating}
      />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: openSidebar,
          [classes.ticketHeader]: true,
          [classes.ticketHeaderFull]: openSidebar,
        })}
      >
        <Card>
          <CardHeader
            avatar={
              <IconButton
                aria-label="Back"
                component={RouterLink}
                to={`/customers/${props.match.params.customerId}/support`}
                size="large"
              >
                <BackIcon color="primary" />
              </IconButton>
            }
            title={
              props.ticketDetails.id ? (
                `#${props.ticketDetails.id || ""} | ${props.ticketDetails.subject || ""}`
              ) : (
                <br />
              )
            }
            subheader={customer.name}
            className={classes.ticketCardHeader}
            action={
              <Hide mdDown>
                <Button
                  color="primary"
                  onClick={() => {
                    setOpenSidebar((sideDrawerOpen) => !sideDrawerOpen);
                  }}
                >
                  {openSidebar ? t("TicketDetailsContainer.hideSideBar") : t("TicketDetailsContainer.showSideBar")}
                </Button>
              </Hide>
            }
          />
          <CardActions className={classes.cardActions}>
            <div>&nbsp;</div>
            <Hide mdDown>
              {isShowMarkSolved && props.ticketComments.comments && (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => setIsMarkSolvedOpen(true)}
                  disabled={props.isLoading}
                >
                  {t("TicketDetailsContainer.solved")}
                </Button>
              )}
              {isShowEscalate &&
                props.ticketComments.comments &&
                buildEscalateButton(isEscalated, setIsEscalateOpen, props.isLoading, t)}
            </Hide>
            {isRequester && props.ticketDetails.status === "closed" && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                disabled={
                  props.isLoading ||
                  (props.organization.organization_fields &&
                    props.organization.organization_fields.classification === "blocked")
                }
                onClick={() => props.history.push(`../followUp/${props.ticketDetails.id}`)}
              >
                {t("TicketDetailsContainer.followUp")}
              </Button>
            )}
            {isRequester && props.ticketDetails.status === "solved" && (
              <Button
                disabled={props.isLoading}
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => setIsSurveyOpen(true)}
              >
                {t("TicketDetailsContainer.satisfaction")}
              </Button>
            )}
          </CardActions>
        </Card>
      </div>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: openSidebar && !isMobile,
        })}
      >
        {props.ticketDetails.status !== "closed" &&
          (platform === "amazon_web_services" || (platform === "google_cloud_platform" && concedefyEmail)) && (
            <Alert action={grantAccessAlertContent} severity="info" className={classes.alert}>
              {t("TicketDetailsContainer.alert")}
            </Alert>
          )}
        {props.ticketDetails.status !== "closed" &&
          props.ticketDetails.is_public &&
          find(props.ticketList, { id: parseInt(id) })?.status !== "closed" && (
            <NewCommentCard
              open
              onSubmit={submitComment}
              loading={props.isLoading}
              status={props.ticketDetails.status}
              username={currentUser.displayName}
              ticket={props.ticketDetails}
              ticketID={id}
              smDown={smDown}
              xsDown={xsDown}
              comments={props.ticketComments?.comments || false}
            />
          )}

        {(props.ticketComments.comments || []).map((comment, index) => {
          const author = find(props.ticketComments.users, { id: comment.author_id });
          return (
            <CommentCard
              commentsCount={props.ticketComments.comments.length}
              newComment={index}
              key={`comment_${index}`}
              comment={comment}
              author={author}
            />
          );
        })}
        {showGCAlert && (
          <ConfirmDialog
            title="Grant read-only access"
            text={grantAccessDialogBody(platform)}
            acceptTitle="CLOSE"
            onAccept={() => setGrantAccessDialogOpen(false)}
            open={grantAccessDialogOpen}
          />
        )}
        {showAWSAlert && (
          <ConfirmDialog
            title="Grant Read-Only Access"
            text={grantAccessDialogBody(platform)}
            acceptTitle="Deploy"
            cancelTitle="Close"
            onCancel={() => setGrantAccessDialogOpen(false)}
            onAccept={installSupportRole}
            open={grantAccessDialogOpen}
          />
        )}
      </div>
      <SnackbarLoader text="Loading ticket comments" open={!props.ticketComments.comments} />
      <Hide smUp>
        <MobileFab actions={actions} onAction={handleActionClick} />
      </Hide>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTicketDetails: (id) => dispatch(getTicketDetails(id)),
  initTicketDetails: () => dispatch(initTicketDetails()),
  handleChangeSeverity: (event) => dispatch(handleChangeSeverity(event)),
  savePriority: (id) => dispatch(savePriority(id)),
  saveCategory: (id) => dispatch(saveCategory(id)),
  changeTicketCategory: (obj) => dispatch(changeTicketCategory(obj)),
  changeCategoryInfo: (title) => dispatch(changeCategoryInfo(title)),
  getComments: (id) => dispatch(getComments(id)),
  subscribeComments: (id, isDoitEmployee, customerId) => dispatch(subscribeComments(id, isDoitEmployee, customerId)),
  unsubscribeComments: (id) => dispatch(unsubscribeComments(id)),
  sendTagUpdate: (obj) => dispatch(sendTagUpdate(obj)),
  sendComment: (comment) => dispatch(sendComment(comment)),
  sendEscalate: (obj) => dispatch(sendEscalate(obj)),
  sendSurvey: (obj) => dispatch(sendSurvey(obj)),
  doneSurvey: (isDone) => dispatch(doneSurvey(isDone)),
  toggleLoading: (toggle) => dispatch(toggleLoading(toggle)),
});
const mapStateToProps = (state) => ({
  ticketDetails: state.tickets.ticketDetails,
  ticketTimelineData: state.tickets.timelineEvents,
  priorities: state.tickets.priorities,
  categories: state.tickets.categories,
  clientProductsProperties: state.tickets.clientProductsProperties,
  ticketComments: state.tickets.ticketComments,
  users: state.tickets.userList,
  isLoading: state.tickets.isLoading,
  isDoneSurvey: state.tickets.isDoneSurvey,
  isLoadingTicketDetails: state.tickets.isLoadingTicketDetails,
  platforms: state.tickets.platforms,
  organization: state.tickets.organization,
  ticketList: state.tickets.ticketList,
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetailsContainer);
