import { useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { urlify } from "../../../utils/common";

export default function ErrorDialog({ open, title, content, handleClose, missingPermissions, enableServiceError }) {
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            {content}
          </DialogContentText>
          {missingPermissions &&
            missingPermissions.map((permission) => (
              <Typography variant="caption" component="div" key={permission} color="error">
                - {permission}
              </Typography>
            ))}
          {missingPermissions && (
            <>
              <br />
              <DialogContentText>
                Please wait few minutes and try again. Still not working? Please recheck the assigned permissions to the
                doit_cmp_role
              </DialogContentText>
            </>
          )}
          {enableServiceError && (
            <>
              <Typography component="span" variant="body2">
                {enableServiceError.substring(0, enableServiceError.indexOf("has"))} is disabled,&nbsp;
              </Typography>
              <Link target="_blank" variant="body2" href={urlify(enableServiceError)}>
                click here to enable the service
              </Link>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
