import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";

import { FlexsaveWidgetTitle } from "./types";

export function getFlexsaveWidgetTitle(type: FlexsaveType): FlexsaveWidgetTitle {
  if (type === FlexsaveTypes.GCP) {
    return FlexsaveWidgetTitle.GCP;
  }
  return FlexsaveWidgetTitle.AWS;
}
