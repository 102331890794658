import { type ReactElement } from "react";

import Tooltip, { type TooltipProps } from "@mui/material/Tooltip";

interface NoMaxWidthTooltipProps extends TooltipProps {
  children: ReactElement<any, any>;
  maxWidth?: string | number;
}

const TooltipWithMaxWidth = ({ children, maxWidth = "none", ...props }: NoMaxWidthTooltipProps): ReactElement => (
  <Tooltip
    {...props}
    slotProps={{
      tooltip: {
        sx: {
          maxWidth,
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

export default TooltipWithMaxWidth;
