import { createContext, useContext } from "react";

import { type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";

export type NodeWitOutputModel = {
  id: string;
  name: string;
  outputModel: UnwrappedApiServiceModelDescriptor;
};

export type ReferencedFieldContextValue = {
  referenceableNodes: NodeWitOutputModel[];
  values: unknown;
};

export const ReferencedFieldContext = createContext<ReferencedFieldContextValue>({
  referenceableNodes: [],
  values: undefined,
});

export function useReferencedFieldContext() {
  return useContext(ReferencedFieldContext);
}
