import { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import { getCollection } from "@doitintl/models-firestore";
import { DashboardModel } from "@doitintl/cmp-models";

import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";

import DownloadIcon from "@mui/icons-material/GetAppRounded";
import { DateTime } from "luxon";
import orderBy from "lodash/orderBy";
import { formatCurrency, getCurrencyLabelByCode } from "../../utils/common";
import IconButton from "@mui/material/IconButton";
import { WidgetCardContentWithTable } from "./WidgetCards/Common/WidgetCardContentWithTable";
import { Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.getContrastText(theme.palette.error.light),
    marginLeft: theme.spacing(0.5),
  },
}));

const TYPE_LATEST = 1;
const TYPE_OVERDUE = 2;

export default function InvoicesCard({ raised, fallbackComponent, widgetHeight = 200 }) {
  const classes = useStyles();
  const { entities } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const [invoices, setInvoices] = useState([]);
  const [invoicesOverdue, setInvoicesOverdue] = useState([]);
  const [type, setType] = useState(TYPE_LATEST);
  const [totals, setTotals] = useState({});

  useEffect(() => {
    if (!userRoles.invoicesViewer) {
      setInvoices([]);
      setInvoicesOverdue([]);
      setTotals({});
      return;
    }

    const promises = [
      ...entities.map((e) =>
        getCollection(DashboardModel).doc("invoices-latest").collection("invoicesLatest").doc(e.id).get()
      ),
      ...entities.map((e) =>
        getCollection(DashboardModel).doc("invoices-overdue").collection("invoicesOverdue").doc(e.id).get()
      ),
    ];

    Promise.all(promises).then((snapshots) => {
      let invoices = [];
      let invoicesOverdue = [];

      for (const docSnapshot of snapshots) {
        const customer = docSnapshot.get("customer");
        const entity = docSnapshot.get("entity");
        if (docSnapshot.exists()) {
          const entityInvoices = (docSnapshot.get("invoices") || []).map((d) => ({
            ...d,
            customer,
            entity,
          }));

          if (docSnapshot.ref.path.includes("invoicesLatest")) {
            invoices = invoices.concat(entityInvoices);
          } else {
            invoicesOverdue = invoicesOverdue.concat(entityInvoices);
          }
        }
      }

      invoices = orderBy(invoices, ["date", "id"], ["desc", "desc"]);
      invoicesOverdue = orderBy(invoicesOverdue, ["date", "id"], ["desc", "desc"]);

      const totals = invoicesOverdue.reduce((memo, invoice) => {
        // eslint-disable-next-line no-prototype-builtins
        if (memo.hasOwnProperty(invoice.code)) {
          memo[invoice.code] += invoice.debit;
        } else {
          memo[invoice.code] = invoice.debit;
        }
        return memo;
      }, {});

      setInvoices(invoices);
      setInvoicesOverdue(invoicesOverdue);
      setTotals(totals);
      setType(invoicesOverdue.length > 0 ? TYPE_OVERDUE : TYPE_LATEST);
    });
  }, [entities, userRoles.invoicesViewer]);

  const title = useMemo(
    () => (
      <div>
        {invoicesOverdue && invoicesOverdue.length > 0 && (
          <span>
            <Typography
              fontSize="inherit"
              fontWeight={type === TYPE_OVERDUE ? "inherit" : undefined}
              display="inline"
              sx={{
                textDecoration: type === TYPE_LATEST ? "underline" : undefined,
                cursor: type === TYPE_LATEST ? "pointer" : undefined,
              }}
              onClick={() => setType(TYPE_OVERDUE)}
            >
              Overdue Payments
            </Typography>
            <span> | </span>
          </span>
        )}
        <Typography
          fontSize="inherit"
          display="inline"
          fontWeight={type === TYPE_LATEST ? "inherit" : undefined}
          sx={{
            textDecoration: type === TYPE_OVERDUE ? "underline" : undefined,
            cursor: type === TYPE_OVERDUE ? "pointer" : undefined,
          }}
          onClick={() => setType(TYPE_LATEST)}
        >
          Latest Invoices
        </Typography>
        {type === TYPE_OVERDUE &&
          Object.keys(totals).map((key, i) => (
            <Tooltip key={i} title={`Total ${getCurrencyLabelByCode(key)} overdue payments`}>
              <Chip size="small" className={classes.chip} label={formatCurrency(totals[key], key)} sx={{ ml: 1 }} />
            </Tooltip>
          ))}
      </div>
    ),
    [classes.chip, invoicesOverdue, totals, type]
  );

  const data = type === TYPE_LATEST ? invoices : invoicesOverdue;

  const columns = [
    {
      field: "download",
      headerName: "",
      width: 50,
      renderCell: (params) =>
        params.row.url ? (
          <IconButton size="small" component="a" href={params.row.url} target="_blank" rel="noopener noreferrer">
            <DownloadIcon fontSize="small" />
          </IconButton>
        ) : null,
    },
    {
      field: "date",
      headerName: "Invoice Date",
      flex: 1,
      renderCell: (params) => DateTime.fromISO(params.row.date).toLocaleString(DateTime.DATE_MED),
    },
    {
      field: "id",
      headerName: "Invoice",
      flex: 1,
    },
    {
      field: "amount",
      headerName: type === TYPE_LATEST ? "Total" : "Balance",
      flex: 1,
      renderCell: (params) => formatCurrency(type === TYPE_LATEST ? params.row.sum : params.row.debit, params.row.code),
    },
  ];

  if (!data) {
    return fallbackComponent;
  }

  return (
    <Card raised={raised}>
      <WidgetCardHeader
        title={title}
        subheader={type === TYPE_LATEST ? "Last 12 invoices" : "Invoices past their pay date"}
      />
      <WidgetCardContentWithTable height={widgetHeight} rows={data} columns={columns} />
    </Card>
  );
}
