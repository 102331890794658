import { useMemo } from "react";

import { type LookerSku } from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import { DateTime } from "luxon";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../../Components/FilterTable/FilterTableSkeleton";
import { type Contracts } from "../../../../../types";
import { filters, headerColumns } from "./LookerAssetColumns";
import LookerAssetRow, { type LookerAssetRowData } from "./LookerAssetRow";

const LookerAssetTable = ({ contracts }: { contracts: Readonly<Contracts> | undefined }) => {
  const data = useMemo<LookerAssetRowData[]>(() => {
    const data: LookerAssetRowData[] = [];

    if (contracts === undefined) {
      return data;
    }

    contracts.forEach((contract) => {
      if (!contract.properties?.skus) {
        return;
      }

      contract.properties.skus.forEach((sku: LookerSku) => {
        data.push({
          id: contract.id,
          sku: sku.skuName.label,
          serviceStartDate: DateTime.fromMillis(contract.startDate.toMillis()),
          quantity: sku.quantity,
          status: contract.active ? "Active" : "Inactive",
        });
      });
    });

    return data;
  }, [contracts]);

  const rowWrapper = ({ row }: { row: LookerAssetRowData }) => <LookerAssetRow data={row} />;

  return (
    <Box mb={4}>
      {contracts === undefined ? (
        <FilterTableSkeleton />
      ) : (
        <FilterTable<LookerAssetRowData>
          tableItems={data}
          rowComponent={rowWrapper}
          headerColumns={headerColumns}
          filterColumns={filters}
          persistenceKey="LookerAssetTable"
          data-cy="LookerAssetTable"
        />
      )}
    </Box>
  );
};

export default LookerAssetTable;
