import { DashboardModel, type Metadata, type ReportConfig, ReportOrgMetadataModel } from "@doitintl/cmp-models";
import { getCollection, getCollectionGroup } from "@doitintl/models-firestore";

import { type Customer } from "../../types";
import { serverTimestamp } from "../../utils/firebase";
import { type organizationRef } from "./utilities";

export type KeyTypeValues = { key: string; type: Metadata | "" };

export const getMetadataByTypeKey = (values: KeyTypeValues[], organization: organizationRef, customer: Customer) => {
  const queries = values.map((v) =>
    getCollectionGroup(ReportOrgMetadataModel)
      .where("customer", "==", customer.ref)
      .where("organization", "==", organization)
      .where("type", "==", v.type)
      .where("key", "==", v.key)
      .get()
  );

  return Promise.all(queries);
};

export const updateReportConfigRequest = (
  config: ReportConfig,
  name: string,
  snapshotId: string,
  description: string
) =>
  getCollection(DashboardModel).doc("google-cloud-reports").collection("savedReports").doc(snapshotId).set(
    {
      timeModified: serverTimestamp(),
      config,
      draft: false,
      name,
      description,
      expireBy: null,
    },
    { merge: true }
  );

const fields = {
  stats: "stats",
  serverDurationMs: "serverDurationMs",
  totalDurationMs: "totalDurationMs",
  totalBytesProcessed: "totalBytesProcessed",
};

export const updateReportStatsFirestore = async (
  reportId: string,
  origin: string,
  serverDurationMs: number,
  totalBytesProcessed: number,
  totalDurationMs: number
) => {
  await getCollection(DashboardModel)
    .doc("google-cloud-reports")
    .collection("savedReports")
    .doc(reportId)
    .update({
      [`${fields.stats}.${origin}.${fields.serverDurationMs}`]: serverDurationMs,
      [`${fields.stats}.${origin}.${fields.totalDurationMs}`]: totalDurationMs,
      [`${fields.stats}.${origin}.${fields.totalBytesProcessed}`]: totalBytesProcessed,
    });
};
