import { useEffect, useState } from "react";

export const useDocumentElement = ({ scriptSrc, linkHref }: { scriptSrc?: string; linkHref?: string }) => {
  const [scriptsLoaded, setScriptsLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (scriptSrc && !document.querySelector(`[src="${scriptSrc}"]`)) {
      const script = document.createElement("script");
      script.src = scriptSrc;
      script.type = "module";
      document.body.appendChild(script);
    }
    if (linkHref && !document.querySelector(`[href="${linkHref}"]`)) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = linkHref;
      document.body.appendChild(link);
    }
    setScriptsLoaded(true);
  }, [linkHref, scriptSrc]);
  return [scriptsLoaded];
};
