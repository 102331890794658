import { useCallback, useEffect, useState } from "react";

import { AppModel, type Country } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import values from "lodash/values";

/**
 * This hook returns an array of all Countries in Firestore and a status boolean to indicate when the retrieval is in progress.
 * The list of all countries is stored in a single document in Firebase, as a map between the two-letter codes and
 * an object containing the strings "name" and "region"
 * @returns { Country[], boolean }
 */
export const useCountries = (): { countries: Country[]; countriesLoading: boolean } => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesLoading, setCountriesLoading] = useState<boolean>(true);

  const countriesCallback = useCallback(async () => {
    const countriesQuery = await getCollection(AppModel).doc("countries").get();
    const countriesQueryData = countriesQuery.asModelData();
    const codedCountries = countriesQueryData?.code ?? {};

    const countriesArr = values(codedCountries);

    setCountriesLoading(false);
    setCountries(countriesArr);
  }, []);

  useEffect(() => {
    countriesCallback();
  }, [countriesCallback]);

  return { countries, countriesLoading };
};
