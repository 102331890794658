import { type MutableRefObject, useEffect, useMemo, useRef } from "react";

import { useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { getColors } from "../utilities";
import useTreemapChartRenderer from "./useTreeMapChartRenderer";
import type ReportData from "../ReportData";

type TreemapRendererTypes = {
  data: ReportData | null;
  formatter;
  isWidget: boolean;
  forceRender: boolean;
  isExact?: boolean;
  highchartRef?: MutableRefObject<any | null>;
};

export default function TreemapRenderer({
  data,
  formatter,
  isWidget,
  isExact,
  forceRender,
  highchartRef,
}: TreemapRendererTypes) {
  const theme = useTheme();

  const localChartRef = useRef<any | null>(null);
  const colors = getColors(theme.palette.mode);

  const options: any = useTreemapChartRenderer(data, colors, theme, formatter, isWidget, isExact) as Highcharts.Options;

  const ref = highchartRef ?? localChartRef;

  useEffect(() => {
    if (ref.current?.chart) {
      ref.current.chart.reflow();
    }
  }, [ref, theme.palette.mode]);

  const allowRender = useMemo(() => !(isWidget && !forceRender), [forceRender, isWidget]);

  return (
    <div style={{ height: "100%" }}>
      <HighchartsReact
        ref={ref}
        containerProps={{ style: { height: "100%" } }}
        highcharts={Highcharts}
        allowChartUpdate={allowRender}
        options={options}
      />
    </div>
  );
}
