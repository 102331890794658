import { GoogleAddressParser } from "./Components/GoogleAddressParser";

export const fillAddressFromGMaps =
  (setFieldValue: (field: string, value: string) => void, fillCountry: boolean) => (place) => {
    const address = new GoogleAddressParser().parseNewAddress(place.address_components);
    if (fillCountry) {
      setFieldValue("country", address.country || "");
    }
    setFieldValue("state", address.state || "");
    setFieldValue("stateCode", address.stateCode || "");
    setFieldValue("address", `${address.streetName || ""} ${address.streetNumber || ""}`.trimEnd());
    setFieldValue("city", address.city || "");
    setFieldValue("zip", address.postalCode || "");
  };
