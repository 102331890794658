import { useCallback } from "react";

import { DashboardModel, type DashboardsAccessMetadataModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type FirestoreTimestamp, serverTimestamp } from "../../../utils/firebase";

export const Metadata = "metadata";
export const DashboardsAccessMetadataCollection = "dashboardsAccessMetadata";

export const getDashboardMetadataId = (customerId: string, organizationId: string, dashboardId: string) =>
  `${customerId}_${organizationId}_${dashboardId}`;

export const getDashboardMetadataRef = (customerId: string, organizationId: string, dashboardId: string) => {
  const id = getDashboardMetadataId(customerId, organizationId, dashboardId);
  return getCollection(DashboardModel).doc("metadata").collection(DashboardsAccessMetadataCollection).doc(id);
};

export const useDashboardsMetadata = () => {
  const handleDashboardMetadataUpdate = useCallback(
    (customerId: string, organizationId: string, dashboardId: string) => {
      const metadataRef = getDashboardMetadataRef(customerId, organizationId, dashboardId);
      const timeLastAccessed = serverTimestamp() as FirestoreTimestamp;

      const metadata: DashboardsAccessMetadataModel = {
        customerId,
        organizationId,
        dashboardId,
        timeLastAccessed,
      };

      metadataRef.set(metadata, { merge: true });
    },
    []
  );

  return [handleDashboardMetadataUpdate];
};
