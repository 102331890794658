import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type NotificationSettings, type UserPersonalNotificationModel } from "@doitintl/cmp-models";
import { Stack, TableCell, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import { globalText } from "../../../assets/texts";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { type LabelWithRef } from "../labels/types";
import { formatFirestoreDate } from "../utilities";
import { useFormatLink, useThreeDotsMenusByType } from "./hooks";
import { type AnalyticsResources } from "./types";
import { cloudAnalyticsResourceTypes } from "./utils";

type AnalyticsAllRowProps = {
  row: AnalyticsResources;
  labels: LabelWithRef[] | undefined;
  userEmailNotification?: UserPersonalNotificationModel;
  updateUserEmailNotification: (selectedNotifications: NotificationSettings) => Promise<void>;
  budgetsTierLimitReached: boolean;
  agTierLimitReached: boolean;
};

export const AnalyticsResourcesRow = ({
  row,
  labels,
  userEmailNotification,
  updateUserEmailNotification,
  budgetsTierLimitReached,
  agTierLimitReached,
}: AnalyticsAllRowProps) => {
  const analyticsResourceLink = useFormatLink({ type: row.contentType, id: row.id });
  const routeMatchURL = useRouteMatchURL();
  const threeDotsMenuByType = useThreeDotsMenusByType(
    { row },
    userEmailNotification,
    updateUserEmailNotification,
    budgetsTierLimitReached,
    agTierLimitReached
  );
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  return (
    <>
      <CellsWrapper>
        <TableCell data-cy="name" sx={{ maxWidth: "50vw", overflowWrap: "break-word" }}>
          <Stack spacing={1}>
            <Typography
              component={RouterLink}
              to={{
                pathname: analyticsResourceLink,
                state: { prevPage: routeMatchURL },
              }}
              color="inherit"
              variant="body2"
            >
              {row.data.name}
            </Typography>
            <LabelList labels={rowLabels} />
          </Stack>
        </TableCell>
        <TableCell data-cy="contentType">{cloudAnalyticsResourceTypes[row.contentType]?.label}</TableCell>
        <Hide mdDown>
          <TableCell data-cy="type">{capitalize(row.type)}</TableCell>
        </Hide>
        <Hide mdDown>
          <TableCell data-cy="owner">{row.owner}</TableCell>
        </Hide>
        <Hide mdDown>
          <TableCell data-cy="lastModified">{formatFirestoreDate(row.data?.timeModified) || globalText.NA}</TableCell>
        </Hide>
        <Hide mdDown>
          <TableCell data-cy="timeCreated">{formatFirestoreDate(row.data?.timeCreated) || globalText.NA}</TableCell>
        </Hide>
      </CellsWrapper>
      {threeDotsMenuByType[row.contentType] ? (
        <TableCell data-cy="three-dots" padding="none">
          {threeDotsMenuByType[row.contentType]}
        </TableCell>
      ) : null}
    </>
  );
};
