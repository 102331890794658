import { useMemo } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";

import useMountEffect from "../../../Components/hooks/useMountEffect";
import { useSingleAsg } from "../hooks";
import { type SpotScalingApi } from "../spotScalingApi";
import { type AsgItem } from "../types";
import { ApplyContent } from "./ApplyContent";

type ApplyProps = {
  spotScalingApi: SpotScalingApi;
};

export const Apply = ({ spotScalingApi }: ApplyProps) => {
  const { asgName } = useParams<{ asgName: string }>();
  const location = useLocation<{ state: any }>();
  const history = useHistory();

  useMountEffect(() => {
    window.scrollTo(0, 0);
  });

  const cachedAsg = location?.state ? (location?.state as any).asg : undefined;
  const asgFromFirestore = useSingleAsg(decodeURIComponent(asgName));

  const asg = useMemo((): AsgItem | undefined => {
    if (asgFromFirestore) {
      return asgFromFirestore;
    }
    if (cachedAsg) {
      // before return the cached ASG clean the history state as the state is saved during refresh
      history.replace(history.location.pathname, {});
      return cachedAsg;
    }
  }, [cachedAsg, asgFromFirestore, history]);

  return <Box>{asg && <ApplyContent asg={asg} spotScalingApi={spotScalingApi} />}</Box>;
};
