import { type StepState } from "../../../../Components/Stepper";

export const isValidAzureSubscriptionId = (subscriptionId: string) => {
  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return guidRegex.test(subscriptionId);
};

export const resourceGroupName = "doit-billing-data-export";
export const mspOfferName = "Doit billing data export";
export const containerName = "exportcontainer";
export const directoryName = "exportfiles";

const managedByTenantId = "5c0d2242-8740-49a1-9284-8dbb6365df6e";

export const getTemplate = (storageAccountName: string) =>
  JSON.stringify(
    {
      $schema: "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
      contentVersion: "1.0.0.0",
      variables: {
        mspRegistrationName: "[guid(variables('mspOfferName'))]",
        mspAssignmentName: "[guid(variables('mspOfferName'))]",
        managedByTenantId,
        storageAccountName,
        resourceGroupName,
        mspOfferName,
      },
      resources: [
        {
          type: "Microsoft.Resources/resourceGroups",
          apiVersion: "2021-04-01",
          name: "[variables('resourceGroupName')]",
          location: "[deployment().location]",
        },
        {
          type: "Microsoft.ManagedServices/registrationDefinitions",
          apiVersion: "2019-06-01",
          name: "[variables('mspRegistrationName')]",
          properties: {
            registrationDefinitionName: "[variables('mspOfferName')]",
            managedByTenantId: "[variables('managedByTenantId')]",
            authorizations: [
              {
                principalId: "d9dad54d-c5fe-444e-84e7-0a29db9c857d",
                roleDefinitionId: "b24988ac-6180-42a0-ab88-20f7382dd24c",
                principalIdDisplayName: "Contributor role",
              },
              {
                principalId: "d9dad54d-c5fe-444e-84e7-0a29db9c857d",
                roleDefinitionId: "91c1777a-f3dc-4fae-b103-61d183457e46",
                principalIdDisplayName: "Managed Services Registration assignment Delete Role",
              },
            ],
          },
        },
        {
          type: "Microsoft.Resources/deployments",
          apiVersion: "2018-05-01",
          name: "doitbillingdata",
          resourceGroup: "[variables('resourceGroupName')]",
          dependsOn: [
            "[resourceId('Microsoft.ManagedServices/registrationDefinitions/', variables('mspRegistrationName'))]",
          ],
          properties: {
            mode: "Incremental",
            template: {
              $schema: "https://schema.management.azure.com/schemas/2015-01-01/deploymentTemplate.json#",
              contentVersion: "1.0.0.0",
              parameters: {},
              resources: [
                {
                  type: "Microsoft.Storage/storageAccounts",
                  apiVersion: "2021-04-01",
                  name: "[variables('storageAccountName')]",
                  location: "[deployment().location]",
                  sku: {
                    name: "Standard_LRS",
                  },
                  kind: "StorageV2",
                  properties: {
                    accessTier: "Hot",
                  },
                },
                {
                  type: "Microsoft.Storage/storageAccounts/managementPolicies",
                  apiVersion: "2021-02-01",
                  name: "[concat(variables('storageAccountName'), '/default')]",
                  dependsOn: ["[variables('storageAccountName')]"],
                  properties: {
                    policy: {
                      rules: [
                        {
                          name: "deleteAfterTwoDays",
                          type: "Lifecycle",
                          enabled: true,
                          definition: {
                            filters: {
                              blobTypes: ["blockBlob"],
                            },
                            actions: {
                              baseBlob: {
                                delete: {
                                  daysAfterModificationGreaterThan: 2,
                                },
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
                {
                  type: "Microsoft.ManagedServices/registrationAssignments",
                  apiVersion: "2019-06-01",
                  name: "[variables('mspAssignmentName')]",
                  properties: {
                    registrationDefinitionId:
                      "[resourceId('Microsoft.ManagedServices/registrationDefinitions/', variables('mspRegistrationName'))]",
                  },
                },
              ],
            },
          },
        },
      ],
      outputs: {
        mspOfferName: {
          type: "string",
          value: "[concat('Managed by', ' ', variables('mspOfferName'))]",
        },
      },
    },
    null,
    2
  );

export const getStepStatus = (step: number, currentStep: number): StepState => {
  if (step < currentStep) {
    return "complete";
  } else if (step === currentStep) {
    return "complete";
  }

  return "incomplete";
};
