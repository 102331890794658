import { type ChangeEvent, Fragment } from "react";

import { Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { DraggableItemHolder } from "../../../DragAndDrop/DraggableItemHolder";

type Props = {
  id: string;
  grabbing?: boolean;
  visible: boolean;
  onVisibilityChange?: (visible: boolean) => void;
  hideCheckbox?: boolean;
};

export const ColumnDraggableItem = ({
  id,
  grabbing = false,
  visible,
  onVisibilityChange,
  hideCheckbox = false,
}: Props) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onVisibilityChange?.(event.target.checked);
  };

  return (
    <Box py={0.5}>
      <DraggableItemHolder key={id} id={id} grabbing={Boolean(grabbing)} rightHolder>
        <>
          {!hideCheckbox ? (
            <>
              <Checkbox checked={visible} onChange={handleChange} data-cy={`check-${id}`} /> {id}
            </>
          ) : (
            <Typography pl={1.5}>{id}</Typography>
          )}
        </>
      </DraggableItemHolder>
    </Box>
  );
};
