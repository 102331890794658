import { Loader } from "../../../../Components/Loader";
import Approvals from "./Approvals/Approvals";
import { useApprovals } from "./hooks/approvalHooks";
import { type CustomerInfo, type GroupedWorkloads } from "./types";

type Props = {
  workloads?: GroupedWorkloads;
  configs?: Map<string, CustomerInfo>;
  isBulkUpdateRequired: boolean;
};

export const FlexsaveGCPApprovalPurchases = ({ workloads, configs, isBulkUpdateRequired }: Props) => {
  const approvalItems = useApprovals(workloads, configs) || [];
  return (
    <Loader loading={approvalItems.length === 0}>
      <Approvals approvalItems={approvalItems} isBulkUpdateRequired={isBulkUpdateRequired} />
    </Loader>
  );
};
