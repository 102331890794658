import { useHistory } from "react-router-dom";
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";

import doitLogo from "../../assets/doit-logo-hero-square.svg";
import { useCustomerId } from "../../Components/hooks/useCustomerId";

const CreateBillingProfileCard = () => {
  const history = useHistory();
  const customerId = useCustomerId();

  return (
    <Card sx={{ height: "100%" }} data-testid="billing-profile-card">
      <CardHeader
        avatar={<Avatar alt="Doit Logo" src={doitLogo} sx={{ width: 32, height: 32 }} />}
        title={<Typography variant="h4">Create a billing profile</Typography>}
        sx={(theme) => ({
          height: "58px",
          borderBottom: "1px solid",
          borderBottomColor: theme.palette.divider,
          "& .MuiCardHeader-avatar": {
            marginRight: 1,
          },
        })}
      />
      <CardContent sx={{ p: 0, pt: 2.5, pl: 2.5, pr: 2.5 }}>
        <Typography>A billing profile is required for invoicing and your contract.</Typography>
      </CardContent>
      <CardActions sx={{ padding: 2.5 }}>
        <Button
          variant="contained"
          onClick={() => {
            history.push(`/customers/${customerId}/entities`);
          }}
        >
          Create billing profile
        </Button>
      </CardActions>
    </Card>
  );
};

export default CreateBillingProfileCard;
