import { UserNotification } from "@doitintl/cmp-models";
import { useField } from "formik";

import DeltaOptions from "../../../Pages/UserView/UserViewTabs/AnomaliesNotifications/DeltaOptions";
import SeverityOptions from "../../../Pages/UserView/UserViewTabs/AnomaliesNotifications/SeverityOptions";
import { type NotificationWithGroupDescriptor } from "../useNotificationDescriptors";

export function isCostAnomaliesNotification(
  notification: NotificationWithGroupDescriptor
): notification is NotificationWithGroupDescriptor<UserNotification.CostAnomalies> {
  return notification.value === UserNotification.CostAnomalies;
}

type CostAnomaliesFiltersProps = {
  notification: NotificationWithGroupDescriptor<UserNotification.CostAnomalies>;
};

export const CostAnomaliesFilters = ({ notification }: CostAnomaliesFiltersProps) => {
  const [{ value: costAnomalies }] = useField<object | undefined>({
    name: notification.value.toString(),
  });

  const [anomalyAlertsProps, , { setValue: setAnomalyAlertsValue }] = useField<number | undefined>({
    name: `${notification.value}.anomalyAlerts`,
  });

  const [anomalyAbsoluteProps, , { setValue: setAnomalyAbsoluteValue }] = useField<number | undefined>({
    name: `${notification.value}.anomalyAbsolute`,
  });

  const updateAnomalyAbsolute = (checked: boolean, value: number = 0) =>
    setAnomalyAbsoluteValue(checked ? value : undefined);

  const updateAnomalyAlerts = (checked: boolean, alertLevel: number = 3) =>
    setAnomalyAlertsValue(checked ? alertLevel : undefined);

  const disabled = !(notification.enabled && costAnomalies !== undefined);

  return (
    <>
      <SeverityOptions
        isThresholdOptionChecked={anomalyAlertsProps.value !== undefined}
        setThresholdOptionChecked={(checked) => updateAnomalyAlerts(checked, anomalyAlertsProps.value)}
        disabled={disabled}
        updateSeverityNotif={updateAnomalyAlerts}
        severityInput={anomalyAlertsProps.value ?? 3}
        userSettingsAnomalyAlerts={anomalyAlertsProps.value}
        onThresholdOptionsChange={(value) => updateAnomalyAlerts(true, value)}
      />
      <DeltaOptions
        isDeltaOptionChecked={anomalyAbsoluteProps.value !== undefined}
        setDeltaOptionChecked={(checked) => updateAnomalyAbsolute(checked, anomalyAbsoluteProps.value)}
        disabled={disabled}
        updateDeltaNotif={updateAnomalyAbsolute}
        anomalyAbsoluteInput={anomalyAbsoluteProps.value ?? 0}
        onDeltaInputChange={(value) => updateAnomalyAbsolute(true, value)}
      />
    </>
  );
};
