import Close from "@mui/icons-material/Close";
import File from "@mui/icons-material/InsertDriveFile";
import { Button, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { UploadImitationButton } from "../../../../Components/UploadImitationButton/UploadImitationButton";
import { type CSVData } from "./types";
import { parseData, validateEstimationsData } from "./utils";

type Props = {
  loadedCSVData: CSVData | null;
  CSVFileName: string | null;
  onChange: (data: { filename: string; data: CSVData | null }) => void;
};

export const UploadEstimationsCSV = ({ CSVFileName, loadedCSVData, onChange }: Props) => {
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackBar = useErrorSnackbar();

  return (
    <Stack direction="column" component="div">
      <Stack direction="row" spacing={1} component="div">
        <UploadImitationButton
          uploadName="estimations"
          onDataReceived={({ data, filename }) => {
            const parsedData = parseData(data);

            if (validateEstimationsData(parsedData)) {
              onChange({ data: parsedData, filename });

              showSuccessSnackbar("File successfully uploaded.");
              return;
            }
            showErrorSnackBar("File failed to upload");
          }}
        />

        {loadedCSVData && (
          <Button
            variant="outlined"
            color="error"
            startIcon={<Close />}
            onClick={() => {
              onChange({ data: null, filename: "" });
            }}
            data-cy="delete-estimation-file"
          >
            Remove
          </Button>
        )}
      </Stack>

      {!!CSVFileName && (
        <Stack component="div" mt={1}>
          <Paper variant="outlined">
            <Stack component="div" p={2} direction="row" alignItems="center">
              <File sx={{ color: "#757575" }} />
              <Typography ml={1} data-cy="upload-estimations-file-name">
                {CSVFileName}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      )}
    </Stack>
  );
};
