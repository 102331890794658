import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Button, Card, CardContent, Link, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const ErroredLinkedThread = ({ threadJiraUrl }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent sx={{ p: 2 }}>
        <Box sx={{ textAlign: "center" }}>
          <WarningAmberIcon sx={{ color: theme.palette.error.main }} />
          <Typography sx={{ fontSize: 14 }}>
            Something went wrong and the thread could not be loaded. Please try again later or manage it directly in
            Jira.
          </Typography>
          <Button
            component={Link}
            href={threadJiraUrl}
            target="_blank"
            rel="noopener"
            variant="outlined"
            size="medium"
            color="primary"
            sx={{ mt: 2 }}
          >
            Manage in Jira
            <OpenInNewIcon sx={{ ml: 1 }} />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
