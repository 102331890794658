export type GcDirectErrorLinkAction = {
  actionType: "link";
  label: string;
  linkTemplate: string;
};

export type GcDirectErrorModalAction = {
  actionType: "modal";
  label: string;
};

export type GcDirectError = {
  code: string;
  message: string;
  action: GcDirectErrorLinkAction | GcDirectErrorModalAction;
};

export const CUSTOMER_ID_PLACEHOLDER = "{customerId}";
export const gcDirectErrorMessages: GcDirectError[] = [
  {
    code: "E0001",
    message: "Failed to get service account details. Please verify that your service account status is healthy.",
    action: {
      actionType: "modal",
      label: "Manage connection",
    },
  },
  {
    code: "E0002",
    message:
      "Failed to get service account details. Please verify that your service account key file is formatted correctly.",
    action: {
      actionType: "link",
      label: "Configure account",
      linkTemplate: `/customers/${CUSTOMER_ID_PLACEHOLDER}/settings/gcp`,
    },
  },
  {
    code: "E0003",
    message: "Failed to copy the data. Please verify that your project ID and dataset ID are correct.",
    action: {
      actionType: "modal",
      label: "Manage connection",
    },
  },
  {
    code: "E0004",
    message: "The copy job failed. Please verify that your service account has BigQuery Viewer permissions.",
    action: {
      actionType: "link",
      label: "Configure account",
      linkTemplate: `/customers/${CUSTOMER_ID_PLACEHOLDER}/settings/gcp`,
    },
  },
  {
    code: "E0005",
    message:
      "Your service account does not have permission to access the billing table at the provided location. Please verify that you have added the BigQuery Data Viewer role to your service account.",
    action: {
      actionType: "link",
      label: "Configure account",
      linkTemplate: `/customers/${CUSTOMER_ID_PLACEHOLDER}/settings/gcp`,
    },
  },
  {
    code: "E0006",
    message: "Failed to copy the data. Please verify the provided table exists.",
    action: {
      actionType: "modal",
      label: "Manage connection",
    },
  },
  {
    code: "E0007",
    message:
      "We were unable to copy your billing data due to a system error. Our engineering team has been alerted and will investigate the issue. We are sorry for the inconvenience!",
    action: {
      actionType: "link",
      label: "Contact support",
      linkTemplate: `/customers/${CUSTOMER_ID_PLACEHOLDER}/support`,
    },
  },
];
