import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Typography } from "@mui/material";

type Props = {
  open: boolean;
  handleClose: () => void;
};

export const DetailedUsageCostSetupDialog = ({ open, handleClose }: Props) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>How to enable detailed usage cost</DialogTitle>
    <DialogContent>
      <Stack spacing={0.5}>
        <Typography>
          1. Log in to the Google Cloud console, go to the{" "}
          <Link href="https://console.cloud.google.com/billing/export" target="_blank" underline="none">
            Billing export page.
          </Link>
        </Typography>
        <Typography>
          2. At the prompt, select Go To Linked Billing Account, which should be your Cloud Billing account you want to
          connect.
        </Typography>
        <Typography>3. On the BigQuery export tab, go to the Detailed usage cost and select Edit settings.</Typography>
        <Typography>
          4. From the Projects list, select a project to store the exported Cloud Billing data, which includes
          usage/cost data for all the Cloud projects paid for by the chosen Cloud Billing account.
        </Typography>
        <Typography>
          5. In the Dataset field, select the dataset that you set up to contain your exported Cloud Billing data.
        </Typography>
        <Typography>
          6. Save your configurations. If configured correctly, the Detailed usage cost will show Enabled.
        </Typography>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
