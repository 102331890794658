import { useMemo } from "react";

import { type IntegrationModelCloudhealthPricebooksModel } from "@doitintl/cmp-models";
import { Autocomplete, TextField } from "@mui/material";

import { usePricebooks } from "../hooks";
import { type SelectedPricebooks } from "../types";

type Props = {
  payerAccount: string | null;
  onChangeSelectedPricebooks: (p: IntegrationModelCloudhealthPricebooksModel | null, mpaId: string) => void;
  selectedPricebooks: SelectedPricebooks;
  disabled: boolean;
};
export const PricebookField = ({ onChangeSelectedPricebooks, selectedPricebooks, payerAccount, disabled }: Props) => {
  const pricebookList = usePricebooks();
  const selectedPricebook = useMemo(
    () =>
      (payerAccount &&
        selectedPricebooks[payerAccount] &&
        pricebookList.find(({ id }) => id === selectedPricebooks[payerAccount])) ||
      null,
    [payerAccount, pricebookList, selectedPricebooks]
  );

  return (
    <Autocomplete
      id="awsPricebookSelection"
      options={pricebookList}
      getOptionLabel={(option) => option.bookName}
      fullWidth
      disabled={disabled}
      onChange={(event, value) => {
        if (payerAccount) {
          onChangeSelectedPricebooks(value, payerAccount);
        }
      }}
      value={selectedPricebook}
      data-cy="aws-pricebook-select"
      renderInput={(params) => (
        <TextField
          {...params}
          id="payerInContractSupport"
          name="payerSelection"
          label="Pricebook"
          fullWidth
          variant="outlined"
        />
      )}
    />
  );
};
