import { globalText, marketplaceText } from "../../../../assets/texts";

type EntitlementMenuOptionProps = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
  status?: string[];
};
// TO DO: add suitable status after integrating with API in order to enable these actions
export const entitlementOptions: EntitlementMenuOptionProps[] = [
  {
    label: globalText.APPROVE,
    value: globalText.APPROVE,
    status: [],
  },
  {
    label: globalText.REJECT,
    value: globalText.REJECT,
    color: "error",
    status: [],
  },
  {
    label: "Suspend",
    value: "Suspend",
    color: "error",
    status: [],
  },
  {
    label: marketplaceText.COPY_ENTITLEMENT_ID,
    value: marketplaceText.COPY_ENTITLEMENT_ID,
  },
];
