import { type AxiosInstance } from "axios/index";

import { httpMethods } from "../../assets/texts";
import { consoleErrorWithSentry } from "../../utils";
import { type SplitForServer } from "./types";

const getValidateSplitURL = (customerID: string) => `/v1/customers/${customerID}/analytics/splitting/validate`;

export type splitErrorType = "circular_dependency_error" | "origin_is_target_error";

export type ValidateSplitError = {
  errorType: splitErrorType;
  attributionId: string;
  attributionGroupId: string;
};

export const validateSplit = async ({
  api,
  customerId,
  data,
}: {
  api: AxiosInstance;
  customerId: string;
  data: SplitForServer[];
}): Promise<ValidateSplitError[]> => {
  const url = getValidateSplitURL(customerId);
  try {
    const res = await api.request({
      method: httpMethods.POST,
      url,
      data,
    });

    return res.data;
  } catch (err) {
    consoleErrorWithSentry(err);
    return [];
  }
};
