import { Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Form, Formik } from "formik";
import styled from "styled-components";

import LoadingButton from "../../../Components/LoadingButton";

const AttributionLabel = styled(FormControlLabel)`
  color: ${(props) => props.theme?.palette.text.secondary};
  font-size: 16px;
  font-weight: 400;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
  margin-left: 6px;
`;

type FormValues = {
  anomalyDetection: boolean;
};

type Props = {
  onSubmit: (data: FormValues) => Promise<void>;
  onCancel: () => void;
  initialValues: FormValues;
};

export const AttributionSettingsForm = ({ initialValues, onCancel, onSubmit }: Props) => {
  const theme = useTheme();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (data, { setSubmitting, resetForm }) => {
        try {
          await onSubmit(data);
        } catch (e: any) {
          setSubmitting(false);
          resetForm();
        }
      }}
    >
      {({ values, isSubmitting, handleChange }) => (
        <Form>
          <Box sx={{ mx: 2 }}>
            <AttributionLabel
              value={values.anomalyDetection}
              checked={values.anomalyDetection}
              control={<StyledSwitch name="anomalyDetection" onChange={handleChange} color="primary" size="small" />}
              label="Monitor for Cost Anomalies"
              theme={theme}
            />
          </Box>

          <Divider sx={{ width: "100%", mt: 2 }} />

          <DialogActions>
            <Button variant="text" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>

            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
              mixpanelEventId="analytics.attribution-settings.save"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
