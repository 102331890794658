import { useHistory, useRouteMatch } from "react-router-dom";

import { useApiContext } from "../../api/context";
import useMountEffect from "../../Components/hooks/useMountEffect";

const CustomerTicket = () => {
  const history = useHistory();
  const match = useRouteMatch<any>("/ticket/:ticketId");
  const api = useApiContext();
  useMountEffect(() => {
    api
      .get(`support/ticket/${match?.params.ticketId}`)
      .then((res) => {
        history.push(`/customers/${res.data}/support/tickets/${match?.params.ticketId}`);
      })
      .catch(() => {
        history.push("/customers");
      });
  });
  return null;
};

export default CustomerTicket;
