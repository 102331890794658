import { type FunctionComponent, useCallback } from "react";

import { Redirect, type RouteProps, useLocation } from "react-router-dom";

import { routes } from "../../constants/navigation";
import { useAuthContext } from "../../Context/AuthContext";
import { useRestorePathProvider } from "../../Context/RestorePathProvider";
import { useQuery } from "../../utils/useQuery";
import { NoTraceRoute } from "./TraceRoutes";

export const HomeRoute: FunctionComponent<RouteProps> = ({ ...rest }) => {
  const { customerId, isDoitEmployee, isDoitPartner } = useAuthContext();
  const location = useLocation();
  const { popStoredPath } = useRestorePathProvider();
  const query = useQuery();

  const redirect = query.get("redirect");

  const getRedirectPath = useCallback(
    (defaultRedirectPath: string) => {
      if (redirect && customerId && !redirect.startsWith("/customers")) {
        return `/customers/${customerId}${redirect}`;
      }
      if (redirect) {
        return redirect;
      }

      const restoredPath = popStoredPath();
      // the restore from storage happens only once
      if (restoredPath) {
        return restoredPath;
      }

      return defaultRedirectPath;
    },
    [popStoredPath, redirect, customerId]
  );

  const customerRedirect = useCallback(
    (props) => {
      const redirectPath = `/customers/${customerId}`;
      const actualRedirectPath = location.pathname.startsWith("/customers")
        ? redirectPath
        : `${redirectPath}${location.pathname}`;

      return <Redirect to={getRedirectPath(actualRedirectPath)} {...props} />;
    },
    [customerId, getRedirectPath, location.pathname]
  );

  if (location.pathname === routes.developerDocs) {
    return null;
  }

  let homeRoute;

  if (customerId) {
    homeRoute = <NoTraceRoute {...rest} exact path="/" render={customerRedirect} />;
  } else if (isDoitEmployee || isDoitPartner) {
    homeRoute = (
      <NoTraceRoute {...rest} exact path="/">
        <Redirect to={getRedirectPath("/customers")} />
      </NoTraceRoute>
    );
  } else {
    homeRoute = (
      <NoTraceRoute {...rest} exact path="/">
        <Redirect to={routes.signin} />
      </NoTraceRoute>
    );
  }

  return homeRoute;
};
