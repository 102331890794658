import { type Dispatch, type SetStateAction } from "react";

import isEmpty from "lodash/isEmpty";

import { type ContractStateType } from "../../types";
import { ifContractDatesValid } from "../../utils";

export const validateVendorContract = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, any> = {};

  if (!state.vendorContract?.commitmentType) {
    return true;
  }

  if (!state.vendorContract?.startDate?.isValid) {
    errors.startDate = true;
  }

  if (!state.vendorContract?.endDate?.isValid) {
    errors.endDate = true;
  }

  if (!ifContractDatesValid(state.vendorContract.startDate, state.vendorContract.endDate)) {
    errors.endDate = true;
  }

  for (const [creditName, credit] of Object.entries(state.vendorContract.credits)) {
    if (credit.amount === "" || parseFloat(credit.amount) === 0 || credit.description.trim() === "") {
      errors.credits = errors.credits ?? {};
      errors.credits[creditName] = {
        amount: credit.amount === "" || parseFloat(credit.amount) === 0,
        description: credit.description.trim() === "",
      };
    }
  }

  setState({ ...state, vendorContract: { ...state.vendorContract, errors } });

  return isEmpty(errors);
};
