import { type JSX } from "react";

export enum TrendType {
  Up = "up",
  Down = "down",
}

export enum CardID {
  MonthSpend,
  Forecast,
  Anomaly,
}

export type CardConfig = {
  trend: number | null;
  trendDirection?: TrendType;
  number: number;
  text: string;
  suffix?: string;
  prefix?: string;
  decimals?: number;
  childBeforeContent?: JSX.Element;
  monthOffset?: number;
  id?: CardID;
};

export enum CardTitle {
  MonthSpend = "This month spend",
  Forecast = "This month forecast",
  Anomaly = "30-day anomaly cost",
}
