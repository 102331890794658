import { Status } from "@doitintl/cmp-models";
import { Box, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormikContext } from "formik";

import { useNoDialStyle } from "../../../../../Navigation/themes";
import { fieldNames } from "./constants";
import { type EditFormValues } from "./types";

export default function SagemakerTab() {
  const { values, setFieldValue, isSubmitting, errors, handleBlur, handleChange, submitCount } =
    useFormikContext<EditFormValues>();
  const classes = useNoDialStyle();

  return (
    <Grid container spacing={2} minHeight={790}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" alignItems="start">
          <Typography fontWeight={500}>Status</Typography>

          <RadioGroup
            sx={{ paddingLeft: 1 }}
            value={values.sagemakerStatus}
            onChange={(_ignored, value) => {
              setFieldValue(fieldNames.sagemakerStatus, value);
            }}
          >
            <FormControlLabel
              disabled={isSubmitting}
              value={Status.active}
              control={<Radio color="primary" />}
              label="Active"
            />

            <FormControlLabel
              disabled={isSubmitting}
              value={Status.pending}
              control={<Radio color="primary" />}
              label="Pending"
            />

            <FormControlLabel
              disabled={isSubmitting}
              value={Status.disabled}
              control={<Radio color="primary" />}
              label="Disabled"
            />
          </RadioGroup>
        </Box>

        <Box m={4} />

        <FormControl fullWidth>
          <TextField
            inputProps={{ className: classes.noDial }}
            type="number"
            name={fieldNames.sagemakerDiscount}
            label="Discount override"
            variant="outlined"
            disabled={isSubmitting}
            error={!!errors.sagemakerDiscount && submitCount > 1}
            value={values.sagemakerDiscount}
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />

          <Box m={2} />
          <DatePicker
            label="Discount effective date"
            value={values.sagemakerEffectiveDate ?? ""}
            onChange={(e) => {
              setFieldValue(fieldNames.sagemakerEffectiveDate, e);
            }}
            inputFormat="dd LLL, yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isSubmitting}
                value={params.value}
                name={fieldNames.sagemakerEffectiveDate}
                error={!!errors.sagemakerEffectiveDate && submitCount > 0}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
}
