import { type JSX, useCallback, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";

import { preventOnCloseWhile, useFullScreen } from "../utils/dialog";
import LoadingButton from "./LoadingButton";

type Props = {
  open: boolean;
  title: string;
  message: string | JSX.Element | null;
  onDelete: (() => Promise<void>) | (() => void);
  onClose: () => void;
  deleteButtonText?: string;
  harmless?: boolean;
  dataCy?: string;
  confirmBtnDataCy?: string;
  mixpanelEventId?: string;
};

export const DeleteDialog = ({
  open,
  title = "Delete",
  message,
  onDelete,
  onClose,
  harmless,
  deleteButtonText = "Delete",
  dataCy = "deleteAssetDialog",
  confirmBtnDataCy = "confirmDeleteAssetBtn",
  mixpanelEventId = "dialog.delete",
}: Props) => {
  const { isMobile: xsDown } = useFullScreen("sm");
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await onDelete();
    } finally {
      setLoading(false);
    }
    onClose();
  }, [onClose, onDelete]);

  const onKeyDown = async (event) => {
    if (event.key === "Enter") {
      onDelete();
    }
  };

  return (
    <Dialog
      onKeyDown={harmless ? onKeyDown : undefined}
      fullScreen={xsDown}
      open={open}
      onClose={preventOnCloseWhile(loading, onClose)}
      data-cy={dataCy}
    >
      <DialogTitle>{title}</DialogTitle>
      {!!message && (
        <DialogContent>
          <DialogContentText component="div">{message}</DialogContentText>
        </DialogContent>
      )}
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="text" data-cy="cancelBtn" disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color={harmless ? "primary" : "error"}
          variant="contained"
          onClick={handleDelete}
          disabled={loading}
          loading={loading}
          data-cy={confirmBtnDataCy}
          mixpanelEventId={mixpanelEventId}
        >
          {deleteButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
