import { Check, InfoOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";

export const IncludedWithPlanInfo = ({ isIncluded }: { isIncluded: boolean }) => {
  const iconStyle = { width: "0.9em", height: "0.9em" };

  return (
    <Typography
      variant="caption"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        color: isIncluded ? "success.main" : "warning.main",
        my: 2,
      }}
    >
      {isIncluded ? <Check sx={iconStyle} /> : <InfoOutlined sx={iconStyle} />}
      {isIncluded ? "Included with your plan" : "Not included with your plan"}
    </Typography>
  );
};
