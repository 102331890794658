import { type AppUsersCsvColumns } from "@doitintl/cmp-models";
import isEmail from "is-email";
import Papa from "papaparse";

import { type Customer, type InviteWithRole, type UserWithRole } from "../../../types";
import { anomalyAlerts, capitalizeStartCase, jobFunction as jobFunctionOptions } from "../../../utils/common";
import { serverTimestamp } from "../../../utils/firebase";
import { getColumnsData, getEntities, getUserNotifications } from "../db";

const errorMessages = {
  INVALID_USER_EMAIL: "invalid user email",
  INVALID_ROLE: "invalid user role",
  INVALID_JOB_FUNCTION: "invalid user job function",
  INVALID_BILLING_PROFILE_ASSIGNMENT: "invalid user billing profile assignment",
  INVALID_ANOMALY_THRESHOLD: "invalid user anomaly threshold",
  INVALID_PHONE_EXTENSION: "invalid phone extension. no non numeric characters are allowed",
  INVALID_FILE: "file isn't a .csv file",
};

const specialColumnsFields = ["jobFunction", "entities", "roles", "userSettings"];

const getColumn = (columns: AppUsersCsvColumns["columns"], columnField: string) => {
  const result = columns.find((column) => column.field === columnField);

  if (!result) {
    throw new Error(`column ${columnField} not found`);
  }

  return result.column;
};

const convertUserToCsvData = ({
  user,
  entitiesOptions,
  columns,
  notificationCsvLabelsMap,
  userNotificationsOptions,
}) => {
  const { entities, jobFunction, roles, userNotifications: notifications, userSettings } = user;

  const jobFunctionColumnValue = jobFunctionOptions.find(
    (jobFunctionOption) => jobFunctionOption.value === jobFunction
  )?.name;

  const entitiesColumnValue = entitiesOptions
    .filter((entityOption) => entities.some((entity) => entity.id === entityOption.id))
    .map((entityOption) => entityOption.name)
    .join(",");

  const rolesColumnValue = roles?.name;

  const userSettingsColumnValue = anomalyAlerts.find((anomaly) => userSettings?.anomalyAlerts === anomaly.value)?.name;

  const columnsToGenerate = columns.filter((column) => !specialColumnsFields.includes(column.field));

  return {
    ...columnsToGenerate.reduce(
      (out, column) => ({
        ...out,
        [column.column]: user[column.field],
      }),
      {}
    ),
    [getColumn(columns, "jobFunction")]: jobFunctionColumnValue,
    [getColumn(columns, "entities")]: entitiesColumnValue,
    [getColumn(columns, "roles")]: rolesColumnValue,
    ...userNotificationsOptions.reduce(
      (out, notification) => ({
        ...out,
        [notificationCsvLabelsMap[notification.name]]: Boolean(notifications?.includes(notification.value)),
      }),
      {}
    ),
    [getColumn(columns, "userSettings")]: userSettingsColumnValue,
  };
};

export const convertUsersToCsvData = async (customer: Customer, users: (UserWithRole | InviteWithRole)[]) => {
  const result = await getColumnsData();

  if (!result) {
    throw new Error("columns data not found");
  }

  const { columns, notificationCsvLabelsMap } = result;
  const entitiesOptions = await getEntities(customer);
  const userNotificationsOptions = await getUserNotifications();

  const usersCsvData = users.map((user) =>
    convertUserToCsvData({
      user,
      entitiesOptions,
      columns,
      notificationCsvLabelsMap,
      userNotificationsOptions,
    })
  );
  return Papa.unparse(usersCsvData);
};

const userExistValidator = async ({ email, api, customerId }) => {
  try {
    await api.request({
      method: "post",
      url: `/v1/customers/${customerId}/users/exists`,
      data: { email },
    });
    return false;
  } catch (e: any) {
    if (e.request?.status === 409) {
      return true;
    }

    throw new Error("couldn't validate if user exist");
  }
};

const extractUsersCsvData = (csvFile: string) =>
  new Promise<any>((resolve, reject) => {
    Papa.parse(csvFile, {
      header: true,
      complete: (results) => {
        if (results.errors.length === 0) {
          resolve(results.data);
        } else {
          reject(new Error(results.errors.map((error) => error.message).join("\n")));
        }
      },
    });
  });

const userEmailValidator = ({ user: { userEmail } }) => {
  if (!isEmail(userEmail)) {
    throw new Error(errorMessages.INVALID_USER_EMAIL);
  }
};

const userRoleVlidator = ({ user: { userEmail, userRole }, roles }) => {
  if (!userRole || !roles.some((role) => role.name === userRole)) {
    throw new Error(`${userEmail} - ${errorMessages.INVALID_ROLE}`);
  }
};

const userJobFunctionValidator = ({ user: { userEmail, jobFunction } }) => {
  if (jobFunction && !jobFunctionOptions.some((jobFunctionOption) => jobFunction === jobFunctionOption.name)) {
    throw new Error(`${userEmail} - ${errorMessages.INVALID_JOB_FUNCTION}`);
  }
};

const userBillingProfilesAssignmentsValidator = ({
  user: { userEmail, billingProfilesAssignments: billingProfilesAssignmentsList },
  entitiesOptions,
}) => {
  const billingProfilesAssignments = billingProfilesAssignmentsList.split(",");

  if (
    billingProfilesAssignmentsList &&
    billingProfilesAssignments.some(
      (billingProfilesAssignment) =>
        !entitiesOptions.some((entitiesOption) => entitiesOption.name === billingProfilesAssignment)
    )
  ) {
    throw new Error(`${userEmail} - ${errorMessages.INVALID_BILLING_PROFILE_ASSIGNMENT}`);
  }
};

const userAnomalyThresholdValidator = ({ user: { userEmail, anomalyThreshold } }) => {
  if (anomalyThreshold && !anomalyAlerts.some((anomalyAlert) => anomalyAlert.name === anomalyThreshold)) {
    throw new Error(`${userEmail} - ${errorMessages.INVALID_ANOMALY_THRESHOLD}`);
  }
};

const userPhoneExtensionValidator = ({ user: { userEmail, phoneExtension } }) => {
  if (phoneExtension && !/^\d+$/.test(phoneExtension)) {
    throw new Error(`${userEmail} - ${errorMessages.INVALID_PHONE_EXTENSION}`);
  }
};

const userValidators = [
  userEmailValidator,
  userRoleVlidator,
  userJobFunctionValidator,
  userBillingProfilesAssignmentsValidator,
  userAnomalyThresholdValidator,
  userPhoneExtensionValidator,
];

const validateUser = ({ user, roles, entitiesOptions }) => {
  userValidators.forEach((userValidator) => {
    userValidator({ user, roles, entitiesOptions });
  });
};

const parseUserFromCsvData = ({
  user,
  customer,
  currentUser,
  roles,
  entitiesOptions,
  columns,
  userNotificationsOptions,
  notificationCsvLabelsMap,
}) => {
  const {
    firstName,
    lastName,
    jobFunction: userJobFunction,
    billingProfilesAssignments: billingProfilesAssignmentsList = "",
    userRole,
    anomalyThreshold,
  } = user;

  const jobFunction =
    jobFunctionOptions.find((jobFunctionOption) => jobFunctionOption.name === userJobFunction)?.value ?? "";

  const notifications = userNotificationsOptions
    .filter((userNotification) => user[notificationCsvLabelsMap[userNotification.name]]?.toLowerCase() === "true")
    .map((userNotification) => userNotification.value);

  const userAnomaly = anomalyAlerts.find((anomalyAlert) => anomalyAlert.name === anomalyThreshold)?.value;

  const billingProfilesAssignments = billingProfilesAssignmentsList.split(",");
  const userEntitiesRefs = entitiesOptions
    .filter((entityOption) => billingProfilesAssignments.includes(entityOption.name))
    .map((entityOption) => entityOption.ref);

  const ColumnsToExtractFrom = columns.filter((column) => !specialColumnsFields.includes(column.field));

  return {
    customer: {
      ref: customer.ref,
      name: customer.name,
      _name: customer._name,
    },
    timestamp: serverTimestamp(),
    ...ColumnsToExtractFrom.reduce(
      (out, column) => ({
        ...out,
        [column.field]: user[column.column],
      }),
      {}
    ),
    displayName: `${capitalizeStartCase(firstName)} ${capitalizeStartCase(lastName)}`,
    jobFunction,
    entities: userEntitiesRefs,
    enrichment: {},
    permissions: [],
    entityCreateTemplate: false,
    roles: roles.filter((role) => role.name === userRole).map((role) => role.ref),
    sendEmail: true,
    invitedBy: { email: currentUser?.email, name: currentUser?.displayName },
    userNotifications: notifications,
    userSettings: userAnomaly ? { anomalyAlerts: userAnomaly } : {},
  };
};

const validateFile = (file) => {
  if (file.type !== "text/csv") {
    throw new Error(errorMessages.INVALID_FILE);
  }
};

export const convertCsvToUsers = async ({ customer, api, currentUser, csvFile, roles }) => {
  validateFile(csvFile);

  const result = await getColumnsData();

  if (!result) {
    throw new Error("columns data not found");
  }

  const { columns, notificationCsvLabelsMap } = result;
  const entitiesOptions = await getEntities(customer);
  const userNotificationsOptions = await getUserNotifications();
  const usersCsvData = await extractUsersCsvData(csvFile);

  let errors: any[] = [];
  const users = usersCsvData
    .filter((userCsvData: any) => {
      try {
        validateUser({ user: userCsvData, roles, entitiesOptions });
      } catch (e: any) {
        errors = errors.concat(e);
      }

      return errors.length === 0;
    })
    .map((userCsvData) =>
      parseUserFromCsvData({
        user: userCsvData,
        customer,
        currentUser,
        roles,
        entitiesOptions,
        columns,
        userNotificationsOptions,
        notificationCsvLabelsMap,
      })
    );

  const customerId = customer.id;
  const existingUsersValidations = await Promise.all(
    users.map(async (user) => userExistValidator({ email: user.email, api, customerId }))
  );
  const existingUsersEmails = users
    .filter((_, userIndex) => existingUsersValidations[userIndex])
    .map((user) => user.email);

  const usersToUpdate = users
    .filter((user) => existingUsersEmails.includes(user.email))
    .map(
      ({
        email,
        customer,
        firstName,
        lastName,
        displayName,
        entities,
        phone,
        phoneExtension,
        roles,
        jobFunction,
        userNotifications,
        userSettings,
      }) => ({
        email,
        customer,
        firstName,
        lastName,
        displayName,
        entities,
        phone,
        phoneExtension,
        permissions: [],
        roles,
        jobFunction,
        userNotifications,
        userSettings,
      })
    );
  const usersToCreate = users.filter((user) => !existingUsersEmails.includes(user.email));

  return { usersToCreate, usersToUpdate, errors };
};
