import { type PurchaseStatus, type TotalAverageUsageModal, type TotalUsageStatistics } from "@doitintl/cmp-models";
import countBy from "lodash/countBy";
import groupBy from "lodash/groupBy";
import { type DateTime } from "luxon";

import { typeMapping } from "../constants";
import {
  type AccumulatedWorkloadData,
  type ApprovalsItem,
  type BulkPurchaseItem,
  type CustomerAggregatedPurchaseItem,
  type CustomerAggregatedWorkloadInfo,
  type CustomerInfo,
  type Workload,
  type WorkloadDetailsItem,
} from "../types";

export const getWorkloadsByCustomerId = (workloads: Workload[], customerId: string) =>
  workloads.filter((workload) => workload.customerId === customerId);

export const canPurchaseBeApproved = (status: PurchaseStatus) => status === "purchase-required";

export const getWorkloadsByStatus = (workloads: WorkloadDetailsItem[], status: PurchaseStatus) =>
  workloads.filter((workload) => workload.status === status);

export const getWorkloadCountByStatus = (workloads: WorkloadDetailsItem[], status: PurchaseStatus) =>
  getWorkloadsByStatus(workloads, status).length;

export const getStatusForWorkloads = (workloads: Workload[]): PurchaseStatus => {
  if (!workloads.length) {
    return "not-enough-data";
  }

  const countedWorkloads = countBy(workloads, (workload) => workload.status);

  if (countedWorkloads.refreshing > 0) {
    return "refreshing";
  }
  if (countedWorkloads["in-progress"] > 0) {
    return "in-progress";
  }
  if (countedWorkloads.error > 0) {
    return "error";
  }
  if (countedWorkloads.covered === workloads.length) {
    return "covered";
  }
  if (countedWorkloads.purchased > 0) {
    return "purchased";
  }
  if (countedWorkloads["purchase-required"] > 0) {
    return "purchase-required";
  }
  if (countedWorkloads["waiting-for-optimizer"] > 0) {
    return "waiting-for-optimizer";
  }
  if (countedWorkloads["waiting-for-recommendations"] > 0) {
    return "waiting-for-recommendations";
  }
  return "in-progress";
};

export const getStatusForRiskyWorkload = (workload: Workload): PurchaseStatus => {
  if (workload.status === "purchase-required" && workload.purchaseRisk?.rejectedBy) {
    return "rejected";
  }
  return workload.status;
};

export const usageStatisticsForPurchasePlan = (
  workload: Workload,
  totalAverageUsagesMap: Map<string, TotalAverageUsageModal>
): TotalUsageStatistics => {
  const usageIdToFind = `${workload.region}#${workload.hardware}#${workload.type}`;
  const foundUsage = totalAverageUsagesMap.get(usageIdToFind);
  if (foundUsage) {
    return {
      coverage: foundUsage.coverage,
      doitCuds: foundUsage.doitCuds,
      totalCuds: foundUsage.totalCuds,
      customerShare:
        (workload.purchaseRecommendations + workload.currentDoitCoverage * workload.usageOnDemand) /
        (foundUsage.doitCuds + workload.purchaseRecommendations),
    };
  }
  return {
    coverage: -1,
    doitCuds: -1,
    totalCuds: -1,
    customerShare: -1,
  };
};

export const groupWorkloadItems = (workloads: WorkloadDetailsItem[]) => {
  const groupedWorkloads = groupBy(workloads, (workload) => workload.status === "purchase-required");
  return {
    pending: groupedWorkloads.true || [],
    all: groupedWorkloads.false || [],
  };
};

export const createWorkloadItems = (
  customerWorkloads: Workload[],
  aggregatedPurchaseInfo: CustomerAggregatedWorkloadInfo,
  totalAverageUsagesMap: Map<string, TotalAverageUsageModal>,
  customerInfo?: CustomerInfo
) =>
  customerWorkloads.map((workload) => ({
    ...workload,
    ...usageStatisticsForPurchasePlan(workload, totalAverageUsagesMap),
    primaryDomain: customerInfo?.primaryDomain,
    currentOverallCoverage: aggregatedPurchaseInfo.currentOverallCoverage,
    targetCoverage: aggregatedPurchaseInfo.targetCoverage,
    excludeCustomerFromBulk: aggregatedPurchaseInfo.excludeCustomerFromBulk,
    customerType: customerInfo?.customerType,
    displayTargetCoverage:
      workload.workloadTargetCoverage === undefined
        ? aggregatedPurchaseInfo.targetCoverage
        : workload.workloadTargetCoverage,
  }));

const getLatestTimeStamp = (timeStamp1?: DateTime, timeStamp2?: DateTime) => {
  if (timeStamp1 && timeStamp2) {
    return timeStamp1 > timeStamp2 ? timeStamp1 : timeStamp2;
  } else if (timeStamp1) {
    return timeStamp1;
  } else {
    return timeStamp2;
  }
};

export const getWorkloadsAggregated = (workloads: Workload[]): AccumulatedWorkloadData =>
  workloads.length > 0
    ? workloads.reduce<AccumulatedWorkloadData>(
        (acc, current) => ({
          purchasePlanPrice:
            current.status === "purchase-required"
              ? acc.purchasePlanPrice + current.purchasePlanPrice
              : acc.purchasePlanPrice,
          timeRecommendationUpdated: getLatestTimeStamp(
            acc.timeRecommendationUpdated,
            current.timeRecommendationUpdated
          ),
          timePurchaseDone: getLatestTimeStamp(acc.timePurchaseDone, current.timePurchaseDone),
          purchaseRecommendations:
            current.status === "purchase-required"
              ? acc.purchaseRecommendations + current.purchaseRecommendations
              : acc.purchaseRecommendations,
          totalAmount: ["purchase-required", "in-progress", "purchased"].includes(current.status)
            ? acc.purchaseRecommendations + current.purchaseRecommendations
            : acc.purchaseRecommendations,
        }),
        {
          purchasePlanPrice: 0,
          timeRecommendationUpdated: undefined,
          timePurchaseDone: undefined,
          purchaseRecommendations: 0,
          totalAmount: 0,
        }
      )
    : {
        purchasePlanPrice: 0,
        timeRecommendationUpdated: undefined,
        timePurchaseDone: undefined,
        purchaseRecommendations: 0,
        totalAmount: 0,
      };

export const createAggregatedPurchase = (
  customerPurchase: CustomerAggregatedWorkloadInfo,
  customerWorkloads: Workload[],
  totalAverageUsagesMap: Map<string, TotalAverageUsageModal>,
  customerInfo?: CustomerInfo
): CustomerAggregatedPurchaseItem => {
  const purchaseStatus = getStatusForWorkloads(customerWorkloads);
  const workloadItems = groupWorkloadItems(
    createWorkloadItems(customerWorkloads, customerPurchase, totalAverageUsagesMap, customerInfo)
  );
  return {
    canBeApproved: canPurchaseBeApproved(purchaseStatus),
    primaryDomain: customerInfo?.primaryDomain ?? "",
    customerType: customerInfo?.customerType ?? "",
    workloads: { ...workloadItems },
    status: purchaseStatus,
    ...getWorkloadsAggregated(customerWorkloads),
    ...customerPurchase,
  };
};

export const groupWorkloadsByCustomer = (workloads: Workload[]) =>
  groupBy(workloads, (workload) => workload.customerId);

export const getCustomersCountToPurchase = (workloads: Workload[]) => {
  const workloadsWithPurchasePlan = workloads.filter((workload) => workload.status === "purchase-required");
  return Object.values(groupWorkloadsByCustomer(workloadsWithPurchasePlan)).reduce(
    (acc, current) => acc + current.length,
    0
  );
};

export const createAggregatedBulkPurchase = (
  workloadSku: string,
  workloads: Workload[],
  totalAverageUsages: Map<string, TotalAverageUsageModal>
): BulkPurchaseItem => {
  const purchaseStatus = getStatusForWorkloads(workloads);
  const foundUsage = totalAverageUsages.get(workloadSku);
  if (!foundUsage) {
    throw new Error(`Workload with id ${workloadSku} not found`);
  }
  const aggregatedWorkloads = getWorkloadsAggregated(workloads);
  const globalCoverageAfterPurchase =
    (foundUsage.totalCuds + aggregatedWorkloads.totalAmount) / foundUsage.totalOnDemand;
  return {
    canBeApproved: canPurchaseBeApproved(purchaseStatus),
    workloads,
    status: purchaseStatus,
    customersCount: getCustomersCountToPurchase(workloads),
    globalCoverageAfterPurchase,
    ...aggregatedWorkloads,
    ...foundUsage,
  };
};

export const groupWorkloadsbyRisk = (workloads: Workload[]) => {
  const groupedWorkloads = groupBy(
    workloads,
    (workload) => !!workload.purchaseRisk && !("excludeCustomerFromBulk" in workload.purchaseRisk.causes)
  );
  return {
    workloadsWithRisk: groupedWorkloads.true || [],
    workloadsWithoutRisk: groupedWorkloads.false || [],
  };
};

export const createRiskApprovalPurchase = (
  customerWorkloads: Workload[],
  customerInfo?: CustomerInfo
): ApprovalsItem[] => {
  const workloadItems = groupWorkloadsbyRisk(customerWorkloads);
  const { purchasePlanPrice: customerTotalCost } = getWorkloadsAggregated(customerWorkloads);
  return workloadItems.workloadsWithRisk.flatMap((riskyWorkload) => {
    const workloadStatus = getStatusForRiskyWorkload(riskyWorkload);
    return {
      ...riskyWorkload,
      status: workloadStatus,
      customerShareWithPurchasePlan:
        riskyWorkload.purchaseRisk?.causes?.customerShare || riskyWorkload.customerShareWithPurchasePlan,
      globalCoverageWithPurchasePlan:
        riskyWorkload.purchaseRisk?.causes?.newTotalCoverage || riskyWorkload.globalCoverageWithPurchasePlan,
      customerTotalCost:
        riskyWorkload.status !== "purchase-required"
          ? 0
          : riskyWorkload.purchaseRisk?.causes?.customerTotalCost || customerTotalCost,
      primaryDomain: customerInfo?.primaryDomain ?? "",
      canBeApproved: canPurchaseBeApproved(workloadStatus),
    };
  });
};

export function typeMappingConverter(fullTypeName: string) {
  return typeMapping[fullTypeName] ?? fullTypeName;
}
