import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";

const useGetWidgetSavedRampPlan = () => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();

  const userRampPlanWidgetDocId = `${customer.id}_${currentUser.uid}`;

  const [widgetRampPlanConfig, loading] = useDocumentData(
    getCollection(DashboardModel).doc("widgets").collection("rampPlan").doc(userRampPlanWidgetDocId)
  );

  const saveWidgetRampPlan = (rampPlanId: string) => {
    const widgetSettings = {
      rampPlanId,
      customer: customer.ref,
    };

    try {
      getCollection(DashboardModel)
        .doc("widgets")
        .collection("rampPlan")
        .doc(userRampPlanWidgetDocId)
        .set(widgetSettings);
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  };

  return { loading, rampPlanId: widgetRampPlanConfig?.rampPlanId, saveWidgetRampPlan };
};

export default useGetWidgetSavedRampPlan;
