import { useCallback, useEffect, useRef } from "react";

import Link from "@mui/material/Link";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type RampPlanModel } from "../types";
import { CsvImportErrorDocumentation, CsvImportMessages, updatePlanDataFromCsv } from "../utils";

function createFileInputElement() {
  const uploadField: HTMLInputElement = document.createElement("input");
  uploadField.setAttribute("type", "file");
  uploadField.setAttribute("accept", ".csv,text/csv");
  return uploadField;
}

export function useRampPlanCsvImport(planData: RampPlanModel) {
  const fileInputElement = useRef(createFileInputElement());
  const snackbar = useSnackbar();
  const isInDarkMode = useDarkThemeCheck();
  // on hook teardown, remove input element
  useEffect(() => () => {
    fileInputElement.current.remove();
  });

  useEffect(() => {
    const fileInputElementRef = fileInputElement.current;
    async function onFileUpload(this: HTMLInputElement) {
      if (planData.commitmentPeriods && this.files?.[0]) {
        const csvUploadResult = await updatePlanDataFromCsv({
          periods: planData.commitmentPeriods,
          csvFile: this.files[0],
          rampId: planData.id,
        });

        switch (csvUploadResult) {
          case CsvImportMessages.IMPORT_SUCCESS:
            snackbar.onOpen({
              variant: "success",
              message: csvUploadResult,
              autoHideDuration: 3000,
            });
            break;
          case CsvImportMessages.NO_PLAN_STR_CELL:
            snackbar.onOpen({
              variant: "error",
              autoHideDuration: null,
              withClose: true,
              message: (
                <>
                  {csvUploadResult}
                  <Link
                    target="_blank"
                    rel="noopener"
                    href={CsvImportErrorDocumentation.NO_PLAN_STR_CELL}
                    sx={{
                      marginLeft: 1,
                      textDecoration: "none",
                      color: isInDarkMode ? cmpBaseColors.primary : cmpBaseColors.primaryDark,
                    }}
                  >
                    See Documentation
                  </Link>
                </>
              ),
            });
            break;
          default:
            snackbar.onOpen({
              variant: "error",
              autoHideDuration: null,
              withClose: true,
              message: csvUploadResult,
            });
        }
        // clear file list after CSV file upload
        this.value = "";
      }
    }
    fileInputElementRef.addEventListener("change", onFileUpload);

    return () => {
      fileInputElementRef.removeEventListener("change", onFileUpload);
    };
  }, [isInDarkMode, planData.commitmentPeriods, planData.id, snackbar]);

  return useCallback(() => {
    fileInputElement.current.click();
  }, []);
}
