import { type EntitlementLimitType, type EntitlementModel } from "@doitintl/cmp-models";

import { type TierFormData } from "./types";

export const validateTierForm = (formData: TierFormData) => {
  if (
    !formData.name ||
    !formData.displayName ||
    !formData.description ||
    !formData.packageType ||
    !formData.sku ||
    !formData.type
  ) {
    return false;
  }
  return true;
};

export const validateEntitlementkey = (key: string) => {
  const pattern = /^(?:[\w-]+(?::[\w-]+)*(?:\/[\w-]+)?|\w+)$/;
  return pattern.test(key);
};

export const validateSKU = (sku: string) => {
  const pattern = /^(P|S)-[A-Z]+-(M|A)-(D|R)-\d+$/;
  return pattern.test(sku);
};

export const validateEntitlementForm = (formData: EntitlementModel) => {
  if (!formData.displayName || !formData.description || !formData.type) {
    return false;
  }
  return true;
};

export const getLimit = (limit?: string): EntitlementLimitType | undefined => {
  if (!limit) {
    return "";
  }
  if (limit.includes(",")) {
    // If the limit is separated by commas, store it as an array of strings
    return limit.split(",").map((item) => item.trim());
  } else {
    const parsedNumber = parseFloat(limit);
    return !isNaN(parsedNumber) ? parsedNumber : limit;
  }
};
