import { DateTime, type DurationUnits } from "luxon";

import { useAuthContext } from "../../../Context/AuthContext";

export function useUserMaturity(unit: DurationUnits = "days") {
  const authCtx = useAuthContext();
  return Math.floor(
    DateTime.fromJSDate(new Date(authCtx.currentUser?.metadata.creationTime ?? "")).diffNow(unit)[unit as string] * -1
  );
}
