export type AttributeOptions = "timezone" | "all_skills" | "languages";

export type Item = {
  value: string;
  count: number;
  isRefined: boolean;
};

export type RefinementState = {
  searchTerm: string;
  anchorEl: HTMLElement | null;
  visibleItems: Item[];
  overflowItems: Item[];
  filteredItems: Item[];
  showEmojis: boolean;
  showDropdown: boolean;
  selectedOverflowCount: number;
};

export const initialRefinementState: RefinementState = {
  searchTerm: "",
  anchorEl: null,
  visibleItems: [],
  overflowItems: [],
  filteredItems: [],
  showEmojis: true,
  showDropdown: false,
  selectedOverflowCount: 0,
};
