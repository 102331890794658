import { type Dispatch, type SetStateAction, useCallback } from "react";

import { type CurrencyCode, Metric } from "@doitintl/cmp-models";
import { Alert, Card, CardContent, CardHeader, CircularProgress, Grid, InputAdornment, TextField } from "@mui/material";
import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

import { budgetText, globalText } from "../../../assets/texts";
import { type BudgetAlert, type DraftBudget } from "../../../types";
import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../utils/common";
import { dateFormatFullDateWithDay } from "../../../utils/dateTimeFormats";
import { getAlertAmountFromPercentage, textFieldBaseProps } from "./shared";

export type BudgetAlertsProps = {
  classes;
  alerts: BudgetAlert[];
  amount: number | "";
  setAlerts: Dispatch<SetStateAction<BudgetAlert[]>>;
  budget: DraftBudget;
  loading: boolean;
  currency: CurrencyCode;
  metric: number;
  shouldRefreshData: boolean;
  isCurrentUserEditor: boolean;
};

const BudgetAlerts = ({
  classes,
  alerts,
  setAlerts,
  budget,
  amount,
  loading,
  metric,
  currency,
  shouldRefreshData,
  isCurrentUserEditor,
}: BudgetAlertsProps) => {
  const getAlertPercentageFromAmount = useCallback(
    (alertAmount: number) => {
      if (!alertAmount) {
        return 0;
      }
      return (alertAmount / (amount as number)) * 100;
    },
    [amount]
  );
  const handleAlertChange = useCallback(
    (fieldName: string, value, index: number) => {
      setAlerts((prevState) => {
        const newAlert: BudgetAlert = {
          amount: 0,
          percentage: 0,
          triggered: false,
        };
        switch (fieldName) {
          case "percentage":
            newAlert.percentage = value ? parseInt(value) : 0;
            newAlert.amount = getAlertAmountFromPercentage(value ? parseInt(value) : 0, amount as number);
            break;
          case "amount":
            newAlert.amount = value ? parseInt(value) : 0;
            newAlert.percentage = getAlertPercentageFromAmount(value ? parseInt(value) : 0);
        }
        const newAlerts = cloneDeep(prevState);
        newAlerts[index] = newAlert;
        return newAlerts;
      });
    },
    [getAlertPercentageFromAmount, setAlerts, amount]
  );

  const handleOnBlur = useCallback(() => {
    setAlerts((prevAlerts) => {
      const zeros = prevAlerts.filter((a) => a.percentage === 0);
      const sortedNonZeros = prevAlerts.filter((a) => a.percentage !== 0).sort((a, b) => a.percentage - b.percentage);
      return [...sortedNonZeros, ...zeros];
    });
  }, [setAlerts]);

  const renderForecastedDate = useCallback(
    (alert, index) => {
      if (alert.amount && loading) {
        return globalText.LOADING;
      }
      const forecastedDate = budget.data.config.alerts[index]?.forecastedDate;
      if (forecastedDate) {
        return `Forecasted Date: ${DateTime.fromMillis(forecastedDate.seconds * 1000).toFormat(
          dateFormatFullDateWithDay
        )}`;
      } else if (shouldRefreshData) {
        return globalText.NA;
      } else if (alert.amount) {
        return budgetText.ALERT_THRESHOLD_NO_REACH;
      }
      return globalText.NA;
    },
    [loading, shouldRefreshData, budget]
  );

  const alertItem = (alert: BudgetAlert, i: number) => (
    <Grid container item spacing={1} alignItems="center" key={i}>
      <Grid item xs={6} sm={2}>
        <TextField
          label={budgetText.ALERT_PERCENTAGE}
          className={classes.fitMargin}
          type="number"
          onKeyUp={onKeyPressPreventNonNumeric}
          onBlur={handleOnBlur}
          inputProps={{ min: 0 }}
          {...textFieldBaseProps}
          value={alert.percentage || ""}
          onChange={(event) => {
            handleAlertChange("percentage", event.target.value, i);
          }}
          disabled={amount === "" || !isCurrentUserEditor}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
          data-testid="percentage"
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label={budgetText.ALERT_AMOUNT}
          type="number"
          onKeyPress={onKeyPressPreventNonNumeric}
          inputProps={{ min: 0 }}
          onBlur={handleOnBlur}
          className={classes.fitMargin}
          {...textFieldBaseProps}
          value={alert.amount || ""}
          onChange={(event) => {
            handleAlertChange("amount", event.target.value, i);
          }}
          disabled={amount === "" || !isCurrentUserEditor}
          InputProps={{
            startAdornment:
              metric === Metric.COST ? (
                <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
              ) : null,
          }}
          data-testid="amount"
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <Alert
          severity="info"
          className={clsx(classes.fitMargin, classes.fitAlertPadding)}
          icon={
            alert.percentage && amount && loading ? (
              <CircularProgress size={20} thickness={5} color="inherit" />
            ) : undefined
          }
        >
          {renderForecastedDate(alert, i)}
        </Alert>
      </Grid>
    </Grid>
  );

  return (
    <Card>
      <CardHeader title={budgetText.ALERTS_CARD_TITLE} titleTypographyProps={{ variant: "subtitle2" }} />
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          {alerts.map((alert, i) => alertItem(alert, i))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BudgetAlerts;
