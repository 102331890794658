import { type ComponentType } from "react";

import { type Breakpoint } from "@mui/material";
import { type AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete/Autocomplete";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const withWidthDown =
  <T,>(breakpoint: number | Breakpoint) =>
  (WrappedComponent: ComponentType<T>) => {
    function useWidthDown(props: T) {
      const theme = useTheme();
      const widthDown = useMediaQuery(theme.breakpoints.down(breakpoint));
      const enhancedProps = { [`${breakpoint}Down`]: widthDown, ...props };
      return <WrappedComponent {...enhancedProps} />;
    }
    return useWidthDown;
  };

export const withWidthUp =
  <T,>(breakpoint: number | Breakpoint) =>
  (WrappedComponent: ComponentType<T>) => {
    function useWidthUp(props: T) {
      const theme = useTheme();
      const widthUp = useMediaQuery(theme.breakpoints.up(breakpoint));
      const enhancedProps = { [`${breakpoint}Up`]: widthUp, ...props };
      return <WrappedComponent {...enhancedProps} />;
    }
    return useWidthUp;
  };

export type WithWidthUp = {
  smUp: boolean;
};

export const getPropsWrapper = (func: AutocompleteRenderGetTagProps, index: number) => {
  const params = func({ index });

  // under cypress there is a weird bug that during the test sometimes the delete button is clicked
  // this make the test flaky, we render the chip under cypress without the delete button
  if (process.env.CYPRESS === "true") {
    params.onDelete = undefined as any;
  }

  return params;
};
