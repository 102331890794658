export const AUTH_DATA_TEST_IDS = {
  AUTO_PROVISION: {
    SWITCH: "auto-provision-switch",
    DOMAINS_CHECKBOX: "auto-provision-domains-checkbox",
    LINK: "auto-provision-link",
    INFO_ICON_TOOLTIP: "auto-provision-info-icon-tooltip",
  },
  AUTH_PROVIDER: {
    RADIO_BUTTON: "auth-provider-radio-button",
  },
  SETTINGS: {
    SAVE_BUTTON: "auth-settings-save-button",
  },
};
