import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource } from "@algolia/autocomplete-js";
import { getAlgoliaResults, parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";

import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { type AlgoliaCustomContext, type AlgoliaIndexType, type AlgoliaPlugin } from "../types";
import { filterByCustomer, getAlgoliaAnalyticsProps, isSearchForCustomer } from "../utils";

type Entity = Hit<{
  objectID: string;
  name?: string;
  priorityId: string;
  active: boolean;
  customerId: string;
}>;

const createEntitiesPlugin: AlgoliaPlugin<Entity> = () => ({
  getSources({ query, state }) {
    const context = state.context as AlgoliaCustomContext;

    if (!query || isSearchForCustomer(query, context)) {
      return [];
    }

    const entitySource: AutocompleteSource<Entity> = {
      sourceId: "entities",
      templates: {
        item({ item }) {
          const titleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["name"],
          });
          const subtitleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["priorityId"],
          });
          if (!item.active) {
            subtitleParts.push({ value: " (inactive)", isHighlighted: false });
          }
          return (
            <AlgoliaResultListItem
              highlightedTitle={titleParts}
              highlightedSubtitle={subtitleParts}
              avatarFallback={item.name?.substring(0, 2).toUpperCase()}
            />
          );
        },
      },
      onSelect({ item, event }) {
        const url = `/customers/${item.customerId}/entities/${item.objectID}/general`;
        context.onSelectRow({
          id: item.objectID,
          label: item.name || "",
          avatarFallback: item.name?.substring(0, 2).toUpperCase(),
          highlightedTitle: [{ value: item.name || "", isHighlighted: false }],
          highlightedSubtitle: [{ value: item.priorityId, isHighlighted: false }],
          url,
          withMetaKey: event.metaKey,
        });
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              indexName: "entities",
              params: {
                query,
                hitsPerPage: 10,
                filters: filterByCustomer(context),
                ...getAlgoliaAnalyticsProps(context),
              },
            },
          ],
          transformResponse({ hits }: { hits: Hit<Entity>[][] }) {
            return hits.map((indexResults) => indexResults.filter((hit) => hit.customerId !== undefined));
          },
        });
      },
      getItemUrl({ item }) {
        return `/customers/${item.customerId}/entities/${item.objectID}/edit`;
      },
    };
    return [entitySource];
  },
});

export const useEntitiesPlugin = (
  restrictedIndices?: AlgoliaIndexType[]
): AutocompletePlugin<Entity, undefined> | null =>
  useMemo(() => {
    if (restrictedIndices?.includes("entities")) {
      return null;
    }

    return createEntitiesPlugin();
  }, [restrictedIndices]);
