import { Alert, Box, Button, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import capitalize from "lodash/capitalize";

import { type FirestoreTimestamp } from "../../../../utils/firebase";
import { changedSupportTypeAlert, impactBillingAlertText } from "../../assetUtils";

type Props = {
  onRemoveOverride: () => void;
  originalSupportTier: string;
  overridingSupportTierCurrentlyInFS: string;
  overrideReason: string;
  overriddenOn?: FirestoreTimestamp | null;
  overrideRemoved: boolean;
};

const SupportTierAlert = (props: Props) => {
  const {
    onRemoveOverride,
    originalSupportTier,
    overridingSupportTierCurrentlyInFS,
    overrideReason,
    overriddenOn,
    overrideRemoved,
  } = props;

  const capitalizedOriginalTierValue = originalSupportTier && capitalize(originalSupportTier);

  const impactBillingAlert = (
    <Alert
      data-cy="supportTypeField_impactBillingAlert"
      sx={{ mt: -4, display: "flex", flexDirection: "row", alignItems: "center" }}
      severity="warning"
    >
      {impactBillingAlertText}
    </Alert>
  );

  const alreadyOverriddenAlert = (
    <Alert
      severity="warning"
      data-cy="supportTypeField_overriddenAlert"
      sx={{ mt: -4, display: "flex", flexDirection: "row", alignItems: "center" }}
      action={
        <Button
          onClick={onRemoveOverride}
          sx={(theme) => ({
            color: "inherit",
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light"
                  ? alpha(theme.palette.warning.light, 0.15)
                  : alpha(theme.palette.warning.dark, 0.2),
            },
          })}
          data-cy="remove_alert_btn"
        >
          <Typography variant="button">{changedSupportTypeAlert.BUTTON_TEXT}</Typography>
        </Button>
      }
    >
      <Stack sx={{ display: "flex", flexDirection: "column" }} data-cy="alert_messages">
        <Box sx={{ display: "flex", flexDirection: "row" }} data-cy="alert_overriden_from">
          <Typography variant="body2">{changedSupportTypeAlert.OVERRIDDEN_FROM}</Typography>&nbsp;
          <Typography variant="subtitle2">{capitalizedOriginalTierValue}</Typography>
        </Box>
        {!!overrideReason && (
          <Box sx={{ display: "flex", flexDirection: "row" }} data-cy="alert_override_reason">
            <Typography variant="body2">{changedSupportTypeAlert.OVERRIDE_REASON_TITLE} </Typography>&nbsp;
            <Typography variant="subtitle2">{overrideReason}</Typography>
          </Box>
        )}
      </Stack>
    </Alert>
  );
  const showOverriddenAlert =
    overriddenOn && !overrideRemoved && overridingSupportTierCurrentlyInFS !== originalSupportTier;
  return showOverriddenAlert ? alreadyOverriddenAlert : impactBillingAlert;
};
export default SupportTierAlert;
