import { Aggregator, Metadata, Positions, type ReportFilter, Sort, type TimeInterval } from "@doitintl/cmp-models";

import { attributionGroupsText, cloudAnalyticsText } from "../../../assets/texts";
import { type AnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { type AttributionWRef } from "../../../types/Attribution";
import { consoleErrorWithSentry } from "../../../utils";
import { type AttributionGroupsPayload } from "../attributionGroups/attributionGroupsPayload";
import { type Transforms } from "../CloudAnalyticsContext";
import ReportData, { type DataRecord } from "../ReportData";
import { timeIntervalOptions } from "../utilities";
import { type AttributionGroupData } from "./PreviewReport";
import { type previewResult } from "./runPreviewReport";

export type AttributionsPayload = Omit<
  ReportFilter,
  "values" | "field" | "allowNull" | "inverse" | "regexp" | "limit"
> & { formula?: string };

export type getAttributionsForPayloadProps = {
  attributionIds: string[];
  attributions: AttributionWRef[];
};

export const getFilteredAttributions = ({
  attributionIds,
  attributions,
}: getAttributionsForPayloadProps): AttributionsPayload[] =>
  attributionIds.flatMap((id) => {
    const attribution = attributions.find((a) => a.ref.id === id);

    if (!attribution?.ref.id) {
      consoleErrorWithSentry("attribution group wasn't found");
      return [];
    }

    if (!attribution.data.filters) {
      return [];
    }

    const { filters, formula, name } = attribution.data;

    return {
      id: `${Metadata.ATTRIBUTION}:${attribution.ref.id}`,
      type: Metadata.ATTRIBUTION,
      key: name,
      includeInFilter: true,
      composite: filters,
      formula,
    };
  });

export const getFilteredAttributionGroups = (
  attributionGroups: AttributionGroupData[],
  attributions: AttributionWRef[]
): AttributionGroupsPayload[] => {
  const filteredAttributionGroups: AttributionGroupsPayload[] = [];
  attributionGroups.forEach((attributionGroup, i) => {
    const attributionIds = attributionGroup.attributions;
    const filteredAttributions = getFilteredAttributions({ attributionIds, attributions });
    const attributionGroupFilter: AttributionGroupsPayload = {
      attributions: filteredAttributions,
      id: `${Metadata.ATTRIBUTION_GROUP}:preview_${i}`,
      includeInFilter: true,
      key: attributionGroup.name ?? `preview_${i}`,
      type: Metadata.ATTRIBUTION_GROUP,
      ...(attributionGroup.nullFallback && { nullFallback: attributionGroup.nullFallback }),
    };
    filteredAttributionGroups.push(attributionGroupFilter);
  });

  return filteredAttributionGroups;
};

export const getFilters = (scope: string[]): ReportFilter[] => {
  if (scope.length) {
    return [
      {
        id: `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`,
        type: Metadata.ATTRIBUTION,
        field: "",
        key: Metadata.ATTRIBUTION,
        position: Positions.ROW,
        allowNull: false,
        values: scope,
        regexp: "",
        inverse: false,
        limit: 0,
        includeInFilter: true,
        composite: [],
      },
    ];
  }
  return [];
};

export const getReportDataWForecasts = (response: previewResult, transforms: Transforms): ReportData | null => {
  if (response.data && response.rows && response.cols) {
    const metricOffset = response.rows.length + response.cols.length;
    const vals = [metricOffset, metricOffset + 1];
    const numMetrics = 1;

    const resRows = response?.data?.rows ?? [];
    const resForecastRows = response?.data?.forecastRows ?? [];
    const reportData = new ReportData({
      data: resRows,
      forecastRows: resForecastRows,
      rows: response.rows,
      cols: response.cols,
      vals,
      numMetrics,
    });

    const startIndex = reportData.rowStartIndex;
    const forecastRows = response?.data?.forecastRows ? resForecastRows.slice(startIndex) : [];
    const fullData = [...resRows, ...forecastRows];

    return new ReportData({
      data: fullData,
      forecastRows: response.data.forecastRows || [],
      aggregator: Aggregator.TOTAL,
      rows: response.rows,
      cols: response.cols,
      rowOrder: Sort.A_TO_Z,
      colOrder: Sort.A_TO_Z,
      vals,
      transforms,
      features: ["forecast"],
      numMetrics,
    });
  }
  return null;
};

export const getCols = (metadataSnapshots: AnalyticsMetadata, timeInterval: TimeInterval): DataRecord[] => {
  const timeIntervalOption = timeIntervalOptions.find((t) => t.value === timeInterval);
  const timeDimensionsMap = new Map();
  metadataSnapshots
    .filter((md) => md.get("type") === Metadata.DATETIME && timeIntervalOption?.visible.includes(md.get("key")))
    .forEach((md) => {
      const k = md.get("key");
      if (!timeDimensionsMap.has(k)) {
        timeDimensionsMap.set(k, md);
      }
    });
  return Array.from(timeDimensionsMap.values())
    .sort((md1, md2) => md1.get("order") - md2.get("order"))
    .map((md) => ({
      field: md.get("field"),
      id: md.id,
      key: md.get("key"),
      label: md.get("label"),
      position: Positions.COL,
      type: md.get("type"),
      nullFallback: "[N/A]",
    }));
};

export const getRows = (attributionsLen: number, attributionGroups: AttributionGroupsPayload[]): DataRecord[] => {
  const rows: DataRecord[] = [];

  if (attributionsLen > 0) {
    rows.push({
      field: "",
      id: `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`,
      key: Metadata.ATTRIBUTION,
      label: cloudAnalyticsText.PREVIEW.ATTRIBUTION_LABEL,
      nullFallback: "[Attribution N/A]",
      position: Positions.ROW,
      type: Metadata.ATTRIBUTION,
    });
  }

  attributionGroups.forEach((ag, i) => {
    rows.push({
      field: "",
      id: `${Metadata.ATTRIBUTION_GROUP}:preview_${i}`,
      key: ag.key,
      label: cloudAnalyticsText.PREVIEW.ATTRIBUTION_GROUP_LABEL,
      nullFallback: ag.nullFallback ?? attributionGroupsText.UNALLOCATED,
      position: Positions.ROW,
      type: Metadata.ATTRIBUTION_GROUP,
    });
  });

  return rows;
};
