import { useMemo } from "react";

import { Box, Card, Radio, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { type ProviderOptionProps } from "../types";

export const ProviderOption = ({ title, renderIcon, selected, disabled, onClick }: ProviderOptionProps) => {
  const theme = useTheme();

  const icon = useMemo(() => {
    if (renderIcon) {
      return renderIcon(disabled ? "disabled" : "inherit");
    }

    return null;
  }, [disabled, renderIcon]);
  return (
    <Card
      onClick={onClick}
      sx={{
        display: "flex",
        width: "100%",
        borderColor: selected ? "primary.main" : theme.palette.general.outlineBorder,
        borderWidth: 1,
        borderRadius: 2,
        cursor: "pointer",
      }}
    >
      <Stack flexDirection="row" sx={{ mt: 1, mb: 1.1, width: "100%" }}>
        <Box sx={{ ml: 1 }}>
          <Radio size="small" color="primary" disabled={!!disabled} checked={selected} />
        </Box>
        <Stack flex={1} direction="row" justifyContent="center" alignItems="center" sx={{ mr: "50px", width: "100%" }}>
          {icon}
          <Typography
            sx={{ ml: 0.8, fontWeight: selected ? 500 : "auto" }}
            color={disabled ? "textSecondary" : "inherit"}
            component="div"
            variant="body1"
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
