import { useCallback, useMemo } from "react";

import { RampModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useToggle } from "../../../../utils/useToggle";
import { useErrorSnackbar } from "../../../Integrations/Slack/utils";
import { type RampPlanModel } from "../../types";
import { type RampPlanFormData, type RampPlanNameEditorProps } from "./RampPlanNameEditor";

export function useRampPlanNameEditorFacade(rampPlan: RampPlanModel): [RampPlanNameEditorProps, () => void] {
  const [open, openEditor, onClose] = useToggle(false);
  const { customer } = useCustomerContext();
  const showErrorSnackbar = useErrorSnackbar();

  const updateRampPlanName = useCallback(
    async (rampPlanId: string, name: string) => {
      try {
        await getCollection(RampModel).doc(rampPlanId).update({
          name,
        });
        onClose();
      } catch (exception) {
        if (exception instanceof Error) {
          showErrorSnackbar(exception.toString());
        }
      }
    },
    [onClose, showErrorSnackbar]
  );

  const editorProps = useMemo(
    (): RampPlanNameEditorProps => ({
      rampPlanFormData: rampPlan,
      open,
      onClose,
      rampPlanId: rampPlan.id,
      customerRef: customer.ref,
      onSubmit: ({ name }: RampPlanFormData) => updateRampPlanName(rampPlan.id, name),
    }),
    [customer.ref, onClose, open, rampPlan, updateRampPlanName]
  );

  return [editorProps, openEditor];
}
