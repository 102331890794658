import { useCallback } from "react";

import { MenuItem, TextField } from "@mui/material";

import { isNext10Contract } from "../../../../utils";
import { paymentTermOptions } from "../../../const";
import { useContractFormContext } from "../../../ContractsFormContext";
import { calculateChargePerTerm } from "../../../utils";

export const PaymentTermSelector = () => {
  const { state, setState } = useContractFormContext();

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const paymentTerm = value;
      const commitmentMonths = paymentTerm === "annual" ? 12 : state.commitmentMonths;
      const chargePerTerm = calculateChargePerTerm(
        paymentTerm,
        commitmentMonths,
        state.tier?.name === "enterprise" ? state.chargePerTerm : state.tier?.price?.[state.currency]
      );

      setState({
        ...state,
        paymentTerm,
        commitmentMonths,
        chargePerTerm,
      });
    },
    [setState, state]
  );

  return (
    <TextField
      label="Payment term"
      data-cy="payment-term"
      value={state.paymentTerm}
      onChange={handleChange}
      error={state.errors.paymentTerm}
      disabled={isNext10Contract(state.type) ? state.isEditForbidden : state.isEditForbidden || !state.isCommitment}
      select
      fullWidth
      margin="normal"
      variant="outlined"
    >
      {paymentTermOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
