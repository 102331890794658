import { type EKSMetrics } from "@doitintl/cmp-models";
import { type TransformMethod } from "@doitintl/models-firestore";
import { type AxiosResponse } from "axios";

import { isProduction } from "../../constants";
import { CONFIGURATION_STEP, CONNECTION_STEP, DEPLOYMENT_STEP, PERMISSION_STEP } from "./Steps/consts";
import { type ClusterConfig, type OnboardingStep, type Status } from "./types";

export const transformData: TransformMethod<EKSMetrics, ClusterConfig> = (data, snapshot): ClusterConfig => {
  let status: Status = "not-connected";

  const currentDeploymentNotExist = data.currentDeploymentId === undefined || data.currentDeploymentId === "";
  const hasUpdate = data.currentDeploymentId !== data.desiredDeploymentId;

  if (data.cloudFormationStatus === "success") {
    if (currentDeploymentNotExist) {
      status = "in-progress";
    } else if (hasUpdate) {
      status = "pending-update";
    } else {
      status = "active";
    }
  } else if (data.cloudFormationStatus === "deleted") {
    status = "deleted";
  } else {
    status = "not-connected";
  }

  const clusterNameWithoutDots = data.clusterName.replaceAll(".", "-");

  return {
    clusterNameWithoutDots,
    deploymentFileName: `deployment_${data.accountId}_${data.region}_${clusterNameWithoutDots}.yaml`,
    ...data,
    status,
    id: snapshot.id,
    deployedAt: data?.deployedAt?.toDate(),
    bucketLastMetricsData: data?.bucketLastMetricsData?.toDate(),
    clusterConfigRef: snapshot.ref,
    deploymentRef: snapshot.modelRef.collection("deployments").doc(data.desiredDeploymentId),
  };
};

export const getTemplateUrl = (clusterConfig: ClusterConfig): string => {
  const ec2Suffix = clusterConfig.isEc2Cluster ? "-ec2" : "";
  const envSuffix = isProduction ? "" : "-dev";

  const templateURL = `https://doit-cmp-ops-pub.s3.us-east-1.amazonaws.com/eks-metrics/onboarding${ec2Suffix}${envSuffix}.yml`;

  return templateURL;
};

export const getCloudFormationLink = (
  clusterConfig: ClusterConfig,
  firstDeployInAccount: boolean,
  firstDeployInRegion: boolean
): string => {
  const { region, clusterName, bucketName } = clusterConfig;

  const templateURL = getTemplateUrl(clusterConfig);

  return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/create/review?templateURL=${templateURL}&stackName=doit-eks-${region}-${clusterConfig.clusterNameWithoutDots}&param_EKSClusterName=${clusterName}&param_S3Bucket=${bucketName}&param_RoleName=doit_eks_${region}_${clusterName}&param_CreateIAMPolicy=${firstDeployInAccount}&param_CreateBucket=${firstDeployInRegion}`;
};

export const getCurrentStepIndex = (step: OnboardingStep): number => {
  switch (step) {
    case DEPLOYMENT_STEP:
      return 0;
    case PERMISSION_STEP:
      return 1;
    case CONNECTION_STEP:
      return 2;
    case CONFIGURATION_STEP:
      return 1;
  }
};

export const hasUnconfirmedClusters = (clusters: ClusterConfig[]): boolean =>
  clusters.some((cluster) => !cluster.cloudFormationStatus);

export const extractFilenameFromContentDisposition = (response: AxiosResponse<any>): string | null => {
  const contentDisposition = response.headers["content-disposition"] as string;
  if (!contentDisposition) {
    return null;
  }

  const filenameIndex = contentDisposition.indexOf("filename=");
  if (filenameIndex !== -1) {
    let filename = contentDisposition.substring(filenameIndex + 9).trim();
    filename = `deployment_${filename}`;
    return filename;
  }
  return null;
};

export const setDeploymentMethodForCluster = (cluster: ClusterConfig, clusters: ClusterConfig[]): ClusterConfig => {
  if (cluster.cloudFormationStatus !== "success" || !cluster.deploymentMethod) {
    const clusterWithEqualAccountRegion = clusters.find(
      (c) =>
        c.accountId === cluster.accountId &&
        c.region === cluster.region &&
        c.cloudFormationStatus === "success" &&
        c.deploymentMethod
    );

    const requiredDeploymentMethod = clusterWithEqualAccountRegion
      ? clusterWithEqualAccountRegion.deploymentMethod
      : null;

    if (requiredDeploymentMethod) {
      return { ...cluster, deploymentMethod: requiredDeploymentMethod };
    }
  }
  return cluster;
};

export const isThereTerraformDeploymentInRegion = (
  clusters: ClusterConfig[] | undefined,
  accountId: string,
  region: string
): boolean => {
  if (!clusters) {
    return false;
  }

  return clusters.some(
    (cluster) =>
      cluster.accountId === accountId &&
      cluster.region === region &&
      cluster.deploymentMethod === "terraform" &&
      cluster.cloudFormationStatus === "success"
  );
};
