const OPERANDS = "+-*/";
const FIRST_VAR_NAME = "A";

type variableCounter = {
  hasVariable: boolean;
};

export const getVariableName = (idx: number) => String.fromCharCode(FIRST_VAR_NAME.charCodeAt(0) + idx);

const validVarName = (v: string, varNum: number) => {
  const A_ascii = FIRST_VAR_NAME.charCodeAt(0);
  return v.charCodeAt(0) - A_ascii >= 0 && v.charCodeAt(0) - A_ascii < varNum;
};

const validDigit = (v: string) => {
  if (v === ".") {
    return true;
  }
  const one = "0";
  const One_ascii = one.charCodeAt(0);
  return v.charCodeAt(0) - One_ascii >= 0 && v.charCodeAt(0) - One_ascii <= 9;
};

const validNumber = (v: string) => {
  const number = parseFloat(v);
  if (number) {
    let nDigits = number > 0 ? 0 : 1;
    let i = nDigits;
    while (validDigit(v.charAt(i))) {
      nDigits++;
      i++;
    }
    return nDigits;
  }
  return -1;
};

const nextValidVarOrNum = (v: string, varNum: number, varCounter: variableCounter): number => {
  if (validVarName(v, varNum)) {
    varCounter.hasVariable = true;
    return 1;
  }
  return validNumber(v);
};

const validateFormula = (f: string, nVars: number, stack: string[], varCounter: variableCounter) => {
  if (f.length === 0) {
    return false;
  }
  if (f.length === 1) {
    if (nextValidVarOrNum(f, nVars, varCounter) > 0) {
      if (stack.length === 0) {
        return true;
      }
    }
    return false;
  }
  while (f.charAt(0) === "(") {
    stack.push("(");
    f = f.slice(1);
  }
  const next = nextValidVarOrNum(f, nVars, varCounter);
  if (next > 0) {
    f = f.slice(next);
    while (f.charAt(0) === ")") {
      if (stack.pop() !== "(") {
        return false;
      }
      f = f.slice(1);
    }
    if (f.length === 0 && stack.length === 0) {
      return true;
    }
    if (OPERANDS.includes(f.charAt(0))) {
      return validateFormula(f.slice(1), nVars, stack, varCounter);
    }
  }
  return false;
};

export const ValidateFormula = (f: string, nVars: number) => {
  const stack: string[] = [];
  const varCounter: variableCounter = { hasVariable: false };

  return validateFormula(f.replace(/ /g, ""), nVars, stack, varCounter) && varCounter.hasVariable;
};
