import { getCustomerProspect } from "../../Components/Dashboard/CloudConnection/utils";
import { useCustomerContext } from "../../Context/CustomerContext";
import NavigatorTrialStepper from "./NavigatorTrialStepper";
import SolveOnlyCloudOnboardingStepper from "./SolveOnlyStepper/SolveOnlyStepper";

const StartTrialStepper = () => {
  const { customer } = useCustomerContext();

  if (["navigator", "navigatorSolve"].includes(getCustomerProspect(customer))) {
    return <NavigatorTrialStepper />;
  }

  if (getCustomerProspect(customer) === "solve") {
    return <SolveOnlyCloudOnboardingStepper />;
  }
  return <></>;
};

export default StartTrialStepper;
