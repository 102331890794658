import { type JSX, useState } from "react";

import { type FlexsaveType } from "@doitintl/cmp-models";

import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { Loader } from "../../../../Components/Loader";
import { useStandaloneAPI } from "../hooks";

type Props = {
  type: FlexsaveType;
  children: JSX.Element;
};

export const InitOnboardingWrapper = ({ type, children }: Props) => {
  const [initCompleted, setInitCompleted] = useState<boolean>(false);

  const standaloneAPI = useStandaloneAPI(type);

  useMountEffect(() => {
    standaloneAPI.initOnboarding().then(() => {
      setInitCompleted(true);
    });
  });

  return <Loader loading={!initCompleted}>{children}</Loader>;
};
