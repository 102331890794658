import { type JSX } from "react";

import { Box, Skeleton } from "@mui/material";

export const CardsSkeleton = () => {
  const cards: JSX.Element[] = [];
  for (let i = 0; i < 2; ++i) {
    cards.push(
      <Box key={i} width="49.5%">
        <Skeleton variant="rectangular" height={110} />
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        {cards}
      </Box>
    </>
  );
};
