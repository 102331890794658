import { useMemo } from "react";

import { type StandaloneOnboardingAWS } from "@doitintl/cmp-models";

import { useAWSStandaloneOnboarding } from "../../../../Standalone/hooks";

export type ActiveAccountItem = {
  accountId: string;
  estimation: string | number;
  enabledDate: string;
  completed: boolean;
};

function getEstimation(account: StandaloneOnboardingAWS) {
  if (account.completed) {
    return "Active";
  }
  return account.savings?.estimatedSavings ?? "No savings";
}

function getDateEnabled(account: StandaloneOnboardingAWS) {
  if (account.completed && account.timeActivated) {
    return account.timeActivated.toDate().toLocaleDateString();
  } else {
    return "-";
  }
}

export function useAccounts() {
  const [accounts] = useAWSStandaloneOnboarding(false);

  return useMemo(
    () =>
      accounts?.map((account) => ({
        accountId: account.accountId,
        estimation: getEstimation(account),
        enabledDate: getDateEnabled(account),
        completed: Boolean(account.completed),
      })),
    [accounts]
  );
}
