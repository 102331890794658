import {
  AccountManagerCompanies,
  type AccountManagerModel,
  type AccountManagersCompany,
  type AccountManagersRole,
  type AccountTeamMember,
} from "@doitintl/cmp-models";
import { type ModelBatch, type ModelIdRef, type WithFirebaseModel } from "@doitintl/models-firestore";

import { type PartialProperties } from "../../utils/RequiredProperties";
import type { Customer } from "../../types";

export type OptionalAm = WithFirebaseModel<PartialProperties<AccountTeamMember, "ref" | "supportNotificationLevel">>;
export type OptionalAmWithIndex = OptionalAm & { index: number };

export const companiesToList: AccountManagersCompany[] = [
  AccountManagerCompanies.DOIT,
  AccountManagerCompanies.AWS,
  AccountManagerCompanies.GCP,
];
export const noRole = { name: "None", value: "", vendors: companiesToList };
const rolesToUpdate = ["account_manager", "customer_engineer", "strategic_accounts_manager"] as AccountManagersRole[];

export const getLegacyRole = (role: AccountManagersRole): AccountManagersRole => {
  if (role === "strategic_accounts_manager") {
    return "customer_engineer";
  }
  return role;
};

export const removeFromLegacyAccountManagerStructure = (
  accountManagersToSave: AccountTeamMember[],
  batch: ModelBatch,
  customer: Customer
) => {
  const accountManagersRefs = accountManagersToSave.map((v) => v.ref);
  companiesToList.forEach((company) => {
    rolesToUpdate.forEach((role) => {
      const ams = customer.accountManagers[company];
      if (!ams?.[role]) {
        return;
      }

      const customerAM = ams?.[role];
      const isInAmList =
        customerAM?.ref && !!accountManagersRefs.filter((amRef) => amRef.path === customerAM.ref.path).length;

      if (isInAmList) {
        return;
      }

      batch.update(customer.ref, `accountManagers.${company}.${role}`, null);
    });
  });
};

export const addToLegacyAccountManagerStructure = async (
  accountManagersToSave: AccountTeamMember[],
  batch: ModelBatch,
  customer: Customer,
  allAccountManagers: ModelIdRef<AccountManagerModel>[]
): Promise<void> => {
  accountManagersToSave.forEach((am) => {
    const data = allAccountManagers.find((currentAm) => currentAm.id === am.ref.id);
    if (!data) {
      return;
    }

    batch
      .update(customer.ref, `accountManagers.${data.company}.${getLegacyRole(data.role)}.ref`, am.ref)
      .update(
        customer.ref,
        `accountManagers.${am.company}.${getLegacyRole(data.role)}.notification`,
        am.supportNotificationLevel
      );
  });
};

export function amNamesComparator(
  input: string
): (am1: ModelIdRef<AccountManagerModel>, am2: ModelIdRef<AccountManagerModel>) => number {
  const upperInput = input.toUpperCase(); // Convert the input to uppercase once

  return (am1: ModelIdRef<AccountManagerModel>, am2: ModelIdRef<AccountManagerModel>): number => {
    const a = am1?.name?.toUpperCase() ?? "";
    const b = am2?.name?.toUpperCase() ?? "";

    // Check for empty strings
    if (a === "" && b !== "") {
      return 1; // a is empty, so it should appear after b
    } else if (a !== "" && b === "") {
      return -1; // b is empty, so a should appear before b
    } else if (a === "" && b === "") {
      return 0; // both are empty, so their order doesn't matter
    }

    const exactMatchA = a === upperInput;
    const exactMatchB = b === upperInput;

    if (exactMatchA && !exactMatchB) {
      return -1;
    } else if (!exactMatchA && exactMatchB) {
      return 1;
    }

    // Check if names start with the given input
    const startsWithA = a.startsWith(upperInput);
    const startsWithB = b.startsWith(upperInput);

    if (startsWithA && !startsWithB) {
      return -1;
    } else if (!startsWithA && startsWithB) {
      return 1;
    }

    return a.localeCompare(b);
  };
}

export const addNewAM = (
  accountTeam: AccountTeamMember[],
  allAccountManagers: ModelIdRef<AccountManagerModel>[],
  id: string
) => {
  const am = allAccountManagers.find((am) => am.id === id);
  if (!am) {
    return;
  }

  accountTeam.push({
    company: AccountManagerCompanies.DOIT,
    ref: am.ref,
    supportNotificationLevel: 0,
  });
};
