import { useEffect, useMemo, useState } from "react";

import { Link, useLocation, useParams } from "react-router-dom";
import { type AwsKnownIssueModel, type GcpKnownIssueModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import ArrowBackRounded from "@mui/icons-material/ArrowBackRounded";
import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import { Guard } from "../../Components/Guard";
import { Loader } from "../../Components/Loader";
import { platformsToPlatformsLabelMap } from "../KnownIssues/consts";
import { type KnownIssue, KnownIssueStatus, KnownIssueStatusLabel } from "../KnownIssues/types";
import { getProducts } from "../KnownIssues/utils";
import { NOT_APPLICABLE } from "./consts";
import { observeKnownIssueData } from "./db";

const useKnownIssueFieldStyle = makeStyles((theme) => ({
  cellBox: {
    padding: theme.spacing(1.4),
  },
}));

const KnownIssueField = ({ label, value }: { label: string; value?: string }) => {
  const classes = useKnownIssueFieldStyle();
  if (!value) {
    return null;
  }

  return (
    <Grid item sm={12} xs={12}>
      <div className={classes.cellBox}>
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
        <Typography>{value}</Typography>
      </div>
    </Grid>
  );
};

const useKnownIssueViewStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0.5em",
  },
  title: {
    padding: theme.spacing(1.25),
  },
  grid: {
    padding: theme.spacing(1),
    marginBottom: "0.5em",
  },
  description: {
    padding: theme.spacing(1.25),
  },
}));

export const KnownIssueView = () => {
  const classes = useKnownIssueViewStyles();
  const location = useLocation();
  const { knownIssueId } = useParams<{
    knownIssueId: KnownIssue["id"];
  }>();
  const [knownIssue, setKnownIssue] = useState<KnownIssue | null>();
  const [loading, setLoading] = useState(true);

  const previousLink = location.pathname.replace(`/${knownIssueId}`, "");

  const knownIssueStatus = useMemo(() => {
    if (!knownIssue) {
      return NOT_APPLICABLE;
    }
    return knownIssue.status === KnownIssueStatus.Ongoing
      ? KnownIssueStatusLabel.Ongoing
      : KnownIssueStatusLabel.Archived;
  }, [knownIssue]);

  const knownIssueDate = useMemo(
    () =>
      knownIssue
        ? DateTime.fromJSDate(knownIssue.dateTime.toDate()).toLocaleString(DateTime.DATETIME_MED)
        : NOT_APPLICABLE,
    [knownIssue]
  );

  const knownIssuePlatform = useMemo(() => {
    const platform = Object.keys(platformsToPlatformsLabelMap).find((platform) => platform === knownIssue?.platform);
    return platform ? platformsToPlatformsLabelMap[platform] : NOT_APPLICABLE;
  }, [knownIssue]);

  const knownIssueDescriptions = useMemo(() => {
    if (!knownIssue?.outageDescription) {
      return [];
    }

    const breakableDescriptionToUpdates = knownIssue.outageDescription.replace(/[[]/g, "*break*[");
    return breakableDescriptionToUpdates.split("*break*");
  }, [knownIssue]);

  useEffect(
    () =>
      observeKnownIssueData(knownIssueId, (knownIssueSnapshot) => {
        const data = knownIssueSnapshot.asModelData();
        if (data) {
          const issuesDocumentsData = {
            ...data,
            id: knownIssueSnapshot.id,
          };
          setKnownIssue(issuesDocumentsData);
        } else {
          setKnownIssue(null);
        }

        setLoading(false);
      }),
    [knownIssueId]
  );

  return (
    <Guard isAllowed={loading || Boolean(knownIssue)}>
      <Card className={classes.root}>
        <Loader loading={loading}>
          {knownIssue && (
            <>
              <CardHeader
                avatar={
                  <IconButton aria-label="Back" component={Link} to={previousLink} size="large">
                    <ArrowBackRounded color="primary" />
                  </IconButton>
                }
                title={knownIssue?.title}
                subheader="Known Issues"
              />
              <Grid className={classes.grid} container>
                <KnownIssueField label="Status" value={knownIssueStatus} />
                <KnownIssueField label="Platform" value={knownIssuePlatform} />
                <KnownIssueField label="Product" value={getProducts(knownIssue)} />
                <KnownIssueField label="Start Date" value={knownIssueDate} />
                <KnownIssueField label="Region" value={knownIssue.region} />
                <KnownIssueField
                  label="Availability Zone"
                  value={(knownIssue as ModelId<AwsKnownIssueModel>).availabilityZone}
                />
                {!!knownIssue?.exposureLevel && (
                  <KnownIssueField label="Severity Level" value={knownIssue?.exposureLevel} />
                )}
                <KnownIssueField label="Summary" value={knownIssue.summary} />
                <KnownIssueField label="Symptoms" value={(knownIssue as ModelId<GcpKnownIssueModel>).symptoms} />
                <KnownIssueField label="Workaround" value={knownIssue.workaround} />
              </Grid>
              <Typography style={{ maxWidth: 800 }} className={classes.description} variant="body1" paragraph>
                {knownIssueDescriptions.map((description, index) => (
                  <p key={index}>{description}</p>
                ))}
              </Typography>
            </>
          )}
        </Loader>
      </Card>
    </Guard>
  );
};
