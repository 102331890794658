import { useCallback, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import LoadingButton from "@mui/lab/LoadingButton";

type Props = {
  downloadOperation: () => Promise<boolean>;
};

export const DownloadEstimateButton = ({ downloadOperation }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload = useCallback(async () => {
    setLoading(true);
    await downloadOperation();
    setLoading(false);
  }, [downloadOperation]);

  return (
    <LoadingButton variant="outlined" loading={loading} startIcon={<DownloadIcon />} onClick={handleDownload}>
      Download estimate as PDF
    </LoadingButton>
  );
};
