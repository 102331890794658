import { useCallback } from "react";

import { Formik, type FormikHelpers } from "formik";

import { useApiContext } from "../../../../api/context";
import { googleCloudBillingAccountAdminTexts } from "../../../../assets/texts";
import { chipSeparators } from "../../../../Components/MultiEmailsInputText";
import { SafeHtml } from "../../../../Components/SafeHtml";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../../../utils/dialog";
import { patchGCBillingAccountAdminMembers } from "../api";
import { getDoitAdminUsers, validateEmails } from "../utils";
import { AddEmailsDialog } from "./AddAdminsEmailsDialog";
import { RemoveDoitAdminsDialog } from "./RemoveDoitAdminsDialog";
import { type ValidatedEmailType } from "./types";

type Values = {
  emails: string[];
  validatedEmails: ValidatedEmailType;
  newEmailValue: string;
  showDoitEmailsRemovalDialog: boolean;
};

export type AddBillingAccountAdminsProps = {
  onClose: () => void;
  entityId: string | null;
  billingAccountId: string;
  adminUsers: string[];
  displayName: string;
  isLoadingContracts: boolean;
  isThereSignedContract: boolean;
  updateUiAdmins: ({ emailsToAdd, membersToRemove }: { emailsToAdd?: string[]; membersToRemove?: string[] }) => void;
};

const AddBillingAccountAdmins = ({
  onClose,
  entityId,
  billingAccountId,
  updateUiAdmins,
  adminUsers,
  displayName,
  isLoadingContracts,
  isThereSignedContract,
}: AddBillingAccountAdminsProps) => {
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { isMobile: matches } = useFullScreen("sm");
  const doitAdminsUsers = getDoitAdminUsers(adminUsers);
  const promptDoitEmailRemoval = doitAdminsUsers.length > 0 && isThereSignedContract && isDoitEmployee;

  async function onSubmit(values: Values, formikHelpers: FormikHelpers<Values>) {
    try {
      if (!entityId) {
        throw new Error("Entity id is not defined");
      }

      const emailsToAdd = Object.keys(values.validatedEmails);
      const membersToRemove = promptDoitEmailRemoval ? doitAdminsUsers : undefined;
      await patchGCBillingAccountAdminMembers({
        api,
        billingAccountId,
        customerId: customer.id,
        entityId,
        emailsToAdd,
        mixpanelAddDisplayName: "AddBillingAccountAdminsForm",
        membersToRemove,
        mixpanelRemoveDisplayName: displayName,
      });

      successSnackbar(googleCloudBillingAccountAdminTexts.ADD_ADMIN_SUCCESS_SNACKBAR_MESSAGE);

      formikHelpers.resetForm();
      updateUiAdmins({
        emailsToAdd,
        membersToRemove,
      });

      onClose();
    } catch (error: any) {
      if (error.response) {
        const errMsg = error.response.data ? (
          <SafeHtml html={error.response.data} />
        ) : (
          googleCloudBillingAccountAdminTexts.ADD_ADMIN_FAILURE_SNACKBAR_MESSAGE
        );
        errorSnackbar(errMsg);
      }
      consoleErrorWithSentry(error);
    }
    formikHelpers.setSubmitting(false);
  }

  function getErrors(values: Values) {
    let errors: object | undefined = undefined;
    if (Object.keys(values.validatedEmails).length === 0) {
      errors = {
        validatedEmails: "At least one email is required",
      };
    }
    const emailsWithError = Object.keys(values.validatedEmails).filter((email) => values.validatedEmails[email].error);
    if (emailsWithError.length > 0) {
      errors = {
        validatedEmails: emailsWithError.join(", "),
      };
    }
    return errors;
  }

  const handleEmailValueChange = useCallback(
    (newEmailValue, values, setFieldValue) => {
      setFieldValue("newEmailValue", newEmailValue);
      if (!newEmailValue.match(new RegExp(chipSeparators.join("|"))) && newEmailValue !== "") {
        setFieldValue(
          "validatedEmails",
          validateEmails({
            emails: [...values.emails, newEmailValue],
            isThereSignedContract,
            customerDomains: customer.domains,
          })
        );
      }
    },
    [customer.domains, isThereSignedContract]
  );

  return (
    <Formik<Values>
      initialValues={{
        emails: [],
        validatedEmails: {},
        newEmailValue: "",
        showDoitEmailsRemovalDialog: false,
      }}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      validate={getErrors}
      onSubmit={onSubmit}
    >
      {({ isValid, isSubmitting, values, setFieldValue, handleSubmit, resetForm }) => {
        if (!values.showDoitEmailsRemovalDialog) {
          return (
            <AddEmailsDialog
              isThereSignedContract={isThereSignedContract}
              isValid={isValid}
              newEmailValue={values.newEmailValue}
              onCancel={() => {
                resetForm();
                onClose();
              }}
              isFullScreen={matches}
              isLoading={isSubmitting || isLoadingContracts}
              emails={values.emails}
              onClose={preventOnCloseWhile(isSubmitting, onClose)}
              onNewEmailValueChange={(email) => {
                handleEmailValueChange(email, values, setFieldValue);
              }}
              onAdd={() => {
                if (promptDoitEmailRemoval) {
                  setFieldValue("showDoitEmailsRemovalDialog", true);
                } else {
                  handleSubmit();
                }
              }}
              onEmailsChange={(newEmails) => {
                setFieldValue("emails", newEmails);
                setFieldValue(
                  "validatedEmails",
                  validateEmails({ emails: newEmails, isThereSignedContract, customerDomains: customer.domains })
                );
              }}
              validatedEmails={values.validatedEmails}
            />
          );
        } else {
          return (
            <RemoveDoitAdminsDialog
              doitAdminsUsers={doitAdminsUsers}
              isFullScreen={matches}
              onClose={preventOnCloseWhile(isSubmitting, onClose)}
              isLoading={isSubmitting}
              isValid={isValid}
              onRemove={() => {
                handleSubmit();
              }}
            />
          );
        }
      }}
    </Formik>
  );
};

export default AddBillingAccountAdmins;
