import { type JSX, type ReactNode } from "react";

import { CardHeader } from "@mui/material";

type Props = {
  title: ReactNode;
  subheader?: ReactNode;
  backButtonRoute?: string;
  action?: JSX.Element;
  children?: JSX.Element;
};

export const PageHeader = ({ action, children }: Props) => (
  <>
    <CardHeader
      sx={{ width: "100%" }}
      titleTypographyProps={{ variant: "h5" }}
      subheaderTypographyProps={{ variant: "body2" }}
      action={action}
    />
    {children}
  </>
);
