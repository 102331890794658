// noinspection JSUnusedGlobalSymbols

import { type Timestamp } from "@doitintl/models-types";

export enum IndustryGroup {
  AutomobilesComponents = "Automobiles & Components",
  Banks = "Banks",
  CapitalGoods = "Capital Goods",
  CommercialProfessionalServices = "Commercial & Professional Services",
  ConstructionMaterials = "Construction Materials",
  ConsumerDiscretionary = "Consumer Discretionary",
  ConsumerDurablesApparel = "Consumer Durables & Apparel",
  ConsumerServices = "Consumer Services",
  ConsumerStaples = "Consumer Staples",
  DiversifiedConsumerServices = "Diversified Consumer Services",
  DiversifiedFinancialServices = "Diversified Financial Services",
  DiversifiedFinancials = "Diversified Financials",
  EnergyEquipmentServices = "Energy Equipment & Services",
  FoodBeverageTobacco = "Food, Beverage & Tobacco",
  HealthCareEquipmentServices = "Health Care Equipment & Services",
  HouseholdPersonalProducts = "Household & Personal Products",
  IndependentPowerAndRenewableElectricityProducers = "Independent Power and Renewable Electricity Producers",
  Insurance = "Insurance",
  Materials = "Materials",
  Media = "Media",
  PharmaceuticalsBiotechnologyLifeSciences = "Pharmaceuticals, Biotechnology & Life Sciences",
  RealEstate = "Real Estate",
  Retailing = "Retailing",
  SoftwareServices = "Software & Services",
  TechnologyHardwareEquipment = "Technology Hardware & Equipment",
  TelecommunicationServices = "Telecommunication Services",
  Transportation = "Transportation",
  Utilities = "Utilities",
}

export enum Sector {
  ConsumerDiscretionary = "Consumer Discretionary",
  ConsumerStaples = "Consumer Staples",
  Energy = "Energy",
  Financials = "Financials",
  HealthCare = "Health Care",
  Industrials = "Industrials",
  InformationTechnology = "Information Technology",
  Materials = "Materials",
  TelecommunicationServices = "Telecommunication Services",
  Utilities = "Utilities",
}

interface Category {
  industry: Industry | null;
  industryGroup: IndustryGroup | null;
  naicsCode: null | string;
  sector: Sector | null;
  sicCode: null | string;
  subIndustry: null | string;
}

export enum Industry {
  AerospaceDefense = "Aerospace & Defense",
  AirFreightLogistics = "Air Freight & Logistics",
  Airlines = "Airlines",
  Automotive = "Automotive",
  Banks = "Banks",
  Biotechnology = "Biotechnology",
  BuildingMaterials = "Building Materials",
  CapitalMarkets = "Capital Markets",
  Chemicals = "Chemicals",
  CommercialServicesSupplies = "Commercial Services & Supplies",
  CommunicationsEquipment = "Communications Equipment",
  ConstructionEngineering = "Construction & Engineering",
  ConsumerDiscretionary = "Consumer Discretionary",
  ConsumerGoods = "Consumer Goods",
  ConsumerStaples = "Consumer Staples",
  Distributors = "Distributors",
  DiversifiedConsumerServices = "Diversified Consumer Services",
  DiversifiedFinancialServices = "Diversified Financial Services",
  DiversifiedTelecommunicationServices = "Diversified Telecommunication Services",
  EducationServices = "Education Services",
  ElectricUtilities = "Electric Utilities",
  ElectricalEquipment = "Electrical Equipment",
  ElectronicEquipmentInstrumentsComponents = "Electronic Equipment, Instruments & Components",
  FamilyServices = "Family Services",
  FoodProducts = "Food Products",
  GasUtilities = "Gas Utilities",
  HealthCareProvidersServices = "Health Care Providers & Services",
  HotelsRestaurantsLeisure = "Hotels, Restaurants & Leisure",
  HouseholdDurables = "Household Durables",
  Insurance = "Insurance",
  InternetSoftwareServices = "Internet Software & Services",
  LeisureProducts = "Leisure Products",
  Machinery = "Machinery",
  Marine = "Marine",
  Media = "Media",
  MetalsMining = "Metals & Mining",
  PersonalProducts = "Personal Products",
  Pharmaceuticals = "Pharmaceuticals",
  ProfessionalServices = "Professional Services",
  RealEstate = "Real Estate",
  RenewableElectricity = "Renewable Electricity",
  Retailing = "Retailing",
  SpecializedConsumerServices = "Specialized Consumer Services",
  SpecialtyRetail = "Specialty Retail",
  TechnologyHardwareStoragePeripherals = "Technology Hardware, Storage & Peripherals",
  TextilesApparelLuxuryGoods = "Textiles, Apparel & Luxury Goods",
  TradingCompaniesDistributors = "Trading Companies & Distributors",
  Transportation = "Transportation",
}

interface Crunchbase {
  handle: null | string;
}

interface Facebook {
  handle: null | string;
  likes: number | null;
}

interface Geo {
  city: null | string;
  country: null | string;
  countryCode: null | string;
  lat: number | null;
  lng: number | null;
  postalCode: null | string;
  state: null | string;
  stateCode: null | string;
  streetName: null | string;
  streetNumber: null | string;
  subPremise: null | string;
}

interface Github {
  avatar: null | string;
  blog: null | string;
  company: null | string;
  followers: number | null;
  following: number | null;
  handle: null | string;
  id: number | null;
}

interface Gravatar {
  avatar: null | string;
  avatars: Avatar[];
  handle: null | string;
  urls: URL[] | null;
}

interface Avatar {
  type: Type | null;
  url: string;
}

enum Role {
  Ceo = "ceo",
  Communications = "communications",
  CustomerService = "customer_service",
  Education = "education",
  Engineering = "engineering",
  Finance = "finance",
  Founder = "founder",
  HealthProfessional = "health_professional",
  HumanResources = "human_resources",
  InformationTechnology = "information_technology",
  Leadership = "leadership",
  Legal = "legal",
  Marketing = "marketing",
  Operations = "operations",
  Product = "product",
  RealEstate = "real_estate",
  Recruiting = "recruiting",
  Research = "research",
  Sales = "sales",
}

enum Seniority {
  Director = "director",
  Executive = "executive",
  Manager = "manager",
}

enum SubRole {
  AccountExecutive = "account_executive",
  Accounting = "accounting",
  ArchitectIt = "architect_it",
  Assistant = "assistant",
  BrandMarketing = "brand_marketing",
  BusinessAnalyst = "business_analyst",
  BusinessDevelopment = "business_development",
  Ceo = "ceo",
  ContentMarketing = "content_marketing",
  Creative = "creative",
  CustomerSuccess = "customer_success",
  DataIt = "data_it",
  DataScienceEngineer = "data_science_engineer",
  DatabaseIt = "database_it",
  DevopsEngineer = "devops_engineer",
  DigitalMarketing = "digital_marketing",
  Editorial = "editorial",
  Facilities = "facilities",
  Founder = "founder",
  GeneralCounsel = "general_counsel",
  MedicalDoctor = "medical_doctor",
  NetworkEngineer = "network_engineer",
  OfficeManagement = "office_management",
  Owner = "owner",
  PeopleCulture = "people_culture",
  President = "president",
  ProductDesign = "product_design",
  ProductManager = "product_manager",
  ProductMarketing = "product_marketing",
  ProjectManagement = "project_management",
  QAEngineer = "qa_engineer",
  QAIt = "qa_it",
  Realtor = "realtor",
  RiskCompliance = "risk_compliance",
  SecurityIt = "security_it",
  SocialMarketing = "social_marketing",
  SoftwareEngineer = "software_engineer",
  Strategy = "strategy",
  Support = "support",
  SystemsEngineer = "systems_engineer",
  SystemsIt = "systems_it",
  Talent = "talent",
  Teacher = "teacher",
  Training = "training",
  WebEngineer = "web_engineer",
  Writer = "writer",
}

enum Type {
  Thumbnail = "thumbnail",
}

interface URL {
  title?: string;
  value: string;
}

interface Name {
  familyName: null | string;
  fullName: string;
  givenName: string;
}

interface Twitter {
  avatar?: null | string;
  bio?: null | string;
  favorites?: number | null;
  followers?: number | null;
  following?: number | null;
  handle: null | string;
  id?: number | null;
  location?: null | string;
  site?: null | string;
  statuses?: number | null;
}

interface Employment {
  domain: null | string;
  name: null | string;
  role: Role | null;
  seniority: Seniority | null;
  subRole: SubRole | null;
  title: null | string;
}

interface Identifiers {
  usEIN: null | string;
}

enum EmployeesRange {
  The100K = "100K+",
  The10K50K = "10K-50K",
  The110 = "1-10",
  The1150 = "11-50",
  The1K5K = "1K-5K",
  The2511K = "251-1K",
  The50K100K = "50K-100K",
  The51250 = "51-250",
  The5K10K = "5K-10K",
}

enum EstimatedAnnualRevenue {
  The0$1M = "$0-$1M",
  The100M250M = "$100M-$250M",
  The10B = "$10B+",
  The10M50M = "$10M-$50M",
  The1B10B = "$1B-$10B",
  The1M10M = "$1M-$10M",
  The250M500M = "$250M-$500M",
  The500M1B = "$500M-$1B",
  The50M100M = "$50M-$100M",
}

interface Metrics {
  alexaGlobalRank: number | null;
  alexaUsRank: number | null;
  annualRevenue: number | null;
  employees: number | null;
  employeesRange: EmployeesRange | null;
  estimatedAnnualRevenue: EstimatedAnnualRevenue | null;
  fiscalYearEnd: number | null;
  marketCap: number | null;
  raised: number | null;
}

interface Parent {
  domain: null | string;
}

interface Site {
  emailAddresses: string[];
  phoneNumbers: string[];
}

enum TechCategory {
  AccountBasedMarketing = "account_based_marketing",
  AccountingAndFinance = "accounting_and_finance",
  Advertising = "advertising",
  Analytics = "analytics",
  ApplicantTrackingSystem = "applicant_tracking_system",
  AuthenticationServices = "authentication_services",
  BusinessManagement = "business_management",
  CRM = "crm",
  CloudComputingServices = "cloud_computing_services",
  ContentManagementSystem = "content_management_system",
  ConversionOptimization = "conversion_optimization",
  CustomerDataPlatform = "customer_data_platform",
  CustomerSupport = "customer_support",
  DNS = "dns",
  DataEnrichmentServices = "data_enrichment_services",
  DataManagement = "data_management",
  DataProcessing = "data_processing",
  DataVisualization = "data_visualization",
  Database = "database",
  Ecommerce = "ecommerce",
  EmailDeliveryService = "email_delivery_service",
  EmailHostingService = "email_hosting_service",
  FormBuilder = "form_builder",
  Geolocation = "geolocation",
  HumanCapitalManagement = "human_capital_management",
  ImageVideoServices = "image_video_services",
  LiveChat = "live_chat",
  MarketingAutomation = "marketing_automation",
  Monitoring = "monitoring",
  OperatingSystem = "operating_system",
  Payment = "payment",
  Productivity = "productivity",
  ProgrammingFramework = "programming_framework",
  ProjectManagementSoftware = "project_management_software",
  SalesProductivity = "sales_productivity",
  Security = "security",
  SiteSearch = "site_search",
  SocialSharing = "social_sharing",
  Survey = "survey",
  TagManagement = "tag_management",
  UserEngagement = "user_engagement",
  WebHosting = "web_hosting",
  WebServers = "web_servers",
  WebsiteOptimization = "website_optimization",
}

export interface CustomerEnrichment {
  category?: Category;
  crunchbase?: Crunchbase;
  description?: null | string;
  domain?: string;
  domainAliases?: string[];
  emailProvider?: boolean;
  facebook?: Facebook;
  foundedYear?: number | null;
  geo?: Geo;
  hubspotId?: string;
  id?: string;
  identifiers?: Identifiers;
  indexedAt?: Timestamp;
  legalName?: null | string;
  linkedin?: Crunchbase;
  location?: null | string;
  logo?: null | string;
  metrics?: Metrics;
  name?: null | string;
  parent?: Parent;
  phone?: null | string;
  site?: Site;
  tags?: string[];
  tech?: string[];
  techCategories?: TechCategory[];
  ticker?: null | string;
  timeZone?: null | string;
  twitter?: Twitter;
  type?: Type;
  ultimateParent?: Parent;
  ultimate_parent?: Parent;
  utcOffset?: number | null;
}

export interface UserEnrichment {
  options?: object;
  avatar?: null | string;
  bio?: null | string;
  email?: string;
  emailProvider?: boolean;
  employment?: Employment;
  facebook?: Facebook;
  fuzzy?: boolean;
  geo?: Geo;
  github?: Github;
  googleplus?: Facebook;
  gravatar?: Gravatar;
  id?: string;
  indexedAt?: Timestamp;
  linkedin?: Facebook;
  location?: null | string;
  name?: Name;
  site?: null | string;
  test?: number;
  timeZone?: null | string;
  twitter?: Twitter;
  utcOffset?: number | null;
}
