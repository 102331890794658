import { useEffect, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";
import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionOnce } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { customersRoute } from "../../Navigation/core/utils";

export const CustomerRoot = () => {
  const { currentUser, customerId } = useAuthContext({ mustHaveUser: true });
  const { customerId: customerIdFromParam } = useParams<{ customerId: string }>();
  const history = useHistory();

  const actualCustomerId = customerIdFromParam || customerId;

  const query = useMemo(() => {
    if (!actualCustomerId) {
      return;
    }

    return getCollection(DashboardModel)
      .doc("customization")
      .collection("users")
      .doc(currentUser.uid)
      .collection("duc")
      .doc(actualCustomerId)
      .collection("dashboards")
      .where("defaultDashboard", "==", true)
      .limit(1);
  }, [actualCustomerId, currentUser.uid]);
  const [snap, isLoading] = useCollectionOnce(query);

  useEffect(() => {
    if (!actualCustomerId) {
      return;
    }
    history.replace(customersRoute(actualCustomerId, snap?.size === 1 ? "dashboards" : "home"));
  }, [actualCustomerId, history, snap?.size]);

  if (isLoading) {
    return null;
  }

  return null;
};
