import React, { useState } from "react";

import { Button, ClickAwayListener, Paper, Popper } from "@mui/material";

import { SubscriptionsPanel } from "./SubscriptionsPanel";
import { type SubscriptionByType, type SubscriptionParentByType, type SubscriptionType } from "./types";

export function SubscribeButton<ST extends SubscriptionType>({
  subscriptionParent,
  subscriptions,
  subscriptionType,
}: {
  subscriptionType: ST;
  subscriptions: SubscriptionByType<ST>[];
  subscriptionParent: SubscriptionParentByType<ST> | null | undefined;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!subscriptionParent) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button sx={{ p: "6px 15px" }} variant="outlined" onClick={handleClick}>
        Subscribe
      </Button>

      <Popper open={open} anchorEl={anchorEl} placement="bottom" disablePortal sx={{ zIndex: 1300 }}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3}>
            <SubscriptionsPanel
              subscriptions={subscriptions}
              handleClose={handleClose}
              subscriptionParent={subscriptionParent}
              subscriptionType={subscriptionType}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
