import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik, type FormikProps } from "formik";
import capitalize from "lodash/capitalize";
import * as yup from "yup";

import { handleResponseError, ResponseErrorCode } from "../../../api/axiosClient";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { autoHideDuration } from "../../../constants";
import { preventEnterEvent } from "../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import { isBillingAccountIdValid, isDatasetValid, isProjectIdValid } from "./utils";

const schema = yup.object().shape({
  project: yup.string().required().test("project", "Please check the format", isProjectIdValid),
  dataset: yup.string().required().test("project", "Please check the format", isDatasetValid),
  billingAccountId: yup.string().required().test("project", "Please check the format", isBillingAccountIdValid),
});

type Values = {
  project: string;
  dataset: string;
  billingAccountId: string;
};

type Props = {
  onClose: () => void;
  onSubmit: (data: Values) => Promise<void>;
  initialValues: Values;
  mode: "edit" | "create";
};

export default function GoogleDirectAssetForm({ onClose, onSubmit, initialValues, mode }: Props) {
  const { fullScreen: isSmallScreen } = useFullScreen("sm");
  const snackbar = useSnackbar();

  return (
    <Formik
      validationSchema={schema}
      onSubmit={async (data, { setSubmitting, resetForm }) => {
        try {
          await onSubmit(data);

          resetForm();
          onClose();
        } catch (error: any) {
          handleResponseError(error, (errorCode) => {
            let textError;
            if (errorCode === ResponseErrorCode.NETWORK_ERROR) {
              textError = "Network Error";
            } else if (errorCode === ResponseErrorCode.UNKNOWN) {
              textError = mode === "create" ? "Asset creation failed" : "Asset update failed";
            } else {
              textError = capitalize(errorCode);
            }

            snackbar.onOpen({
              message: textError,
              variant: "error",
              autoHideDuration,
              action: [
                <IconButton key="close" aria-label="Close" color="inherit" onClick={snackbar.onClose} size="large">
                  <CloseIcon />
                </IconButton>,
              ],
            });
          });
        }

        setSubmitting(false);
      }}
      initialValues={initialValues}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleBlur,
        resetForm,
        isValid,
      }: FormikProps<Values>) => {
        const lockDueToEditMode = mode === "edit" && !!Object.keys(errors).length;
        const lockDueToCrateMode = mode === "create" && !isValid;

        return (
          <Dialog
            open={true}
            aria-labelledby="credit-google-cloud-asset-title"
            onClose={preventOnCloseWhile(isSubmitting, () => {
              onClose();
              resetForm();
            })}
            fullScreen={isSmallScreen}
            fullWidth
            maxWidth="sm"
            data-cy="gcLinkAssetDialog"
          >
            <DialogTitle id="credit-google-cloud-asset-title">
              Link your Google Cloud direct billing account
            </DialogTitle>
            <Form onKeyDown={preventEnterEvent}>
              <DialogContent>
                <Stack spacing={3}>
                  <TextField
                    name="billingAccountId"
                    label="Billing account ID"
                    helperText='Enter the ID of your direct billing account, in the form of "01C62E-D4E150-74F331"'
                    variant="outlined"
                    disabled={isSubmitting || mode === "edit"}
                    error={touched.billingAccountId && Boolean(errors.billingAccountId)}
                    value={values.billingAccountId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />

                  <TextField
                    name="project"
                    label="Billing export project ID"
                    helperText="Enter the project id containing your BigQuery billing export data"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={touched.project && Boolean(errors.project)}
                    value={values.project}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />

                  <TextField
                    name="dataset"
                    label="Billing export dataset ID"
                    helperText="Enter the dataset id containing your BigQuery billing export data"
                    variant="outlined"
                    disabled={isSubmitting}
                    error={touched.dataset && Boolean(errors.dataset)}
                    value={values.dataset}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />

                  <Alert severity="info">
                    <AlertTitle>Note</AlertTitle>
                    <Typography variant="body2">
                      You must ensure the DoiT Console service account has the BigQuery Data Viewer role for this
                      billing data export dataset.
                      <Box display="inline" ml={0.5}>
                        <Link
                          href="https://help.doit.com/google-cloud/import-historical-billing-data#step-2-grant-the-required-permissions"
                          target="_blank"
                        >
                          See instructions
                        </Link>
                      </Box>
                    </Typography>
                  </Alert>
                </Stack>
              </DialogContent>

              <Divider />

              <DialogActions>
                <Button
                  variant="text"
                  onClick={() => {
                    onClose();
                    resetForm();
                  }}
                  disabled={isSubmitting}
                  data-cy="cancelBtn"
                >
                  Cancel
                </Button>

                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || lockDueToEditMode || lockDueToCrateMode}
                  mixpanelEventId="assets.google-direct-asset.import"
                >
                  Import
                </LoadingButton>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
}
