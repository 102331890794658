import { type AuthenticationProvider, type CustomerAuthAutoProvision } from "@doitintl/cmp-models";

export enum AuthActionKind {
  SET_SSO_PROVIDER_CONFIG = "SET_SSO_PROVIDER_CONFIG",
  SET_AUTH_SETTINGS = "SET_AUTH_SETTINGS",
  PENDING_REQUEST = "PENDING_REQUEST",
  SUCCESSFUL_REQUEST = "SUCCESSFUL_REQUEST",
  FAILED_REQUEST = "FAILED_REQUEST",
  RESET_ERROR_STATE = "RESET_ERROR_STATE",
  TOGGLE_SSO = "TOGGLE_SSO",
}

type PendingRequest = {
  type: AuthActionKind.PENDING_REQUEST;
};

type SuccessRequest = {
  type: AuthActionKind.SUCCESSFUL_REQUEST;
};

type FailedRequest = {
  type: AuthActionKind.FAILED_REQUEST;
  payload: string;
};

type SetSsoProviderConfig = {
  type: AuthActionKind.SET_SSO_PROVIDER_CONFIG;
  payload: ProviderConfig;
};

type SetAuthSettings = {
  type: AuthActionKind.SET_AUTH_SETTINGS;
  payload: { authProvider: AllowedProvider; autoProvision: Required<CustomerAuthAutoProvision> };
};

type ToggleSSO = {
  type: AuthActionKind.TOGGLE_SSO;
  payload: boolean;
};

type ResetErrorState = {
  type: AuthActionKind.RESET_ERROR_STATE;
};

export type AuthAction =
  | SetSsoProviderConfig
  | PendingRequest
  | SuccessRequest
  | FailedRequest
  | SetAuthSettings
  | ToggleSSO
  | ResetErrorState;

export type SSOProviders = "saml" | "oidc";

export type ProviderConfig = {
  saml: SAMLConfig;
  oidc: OIDCConfig;
};

export type AutoProvisionType = Required<Pick<CustomerAuthAutoProvision, "allowedDomains" | "enabled">>; // type for UI

export type AuthSettingsType = { allowedProvider: AllowedProvider; autoProvision: AutoProvisionType };

export type AuthSettingsActionType =
  | { type: "SET_AUTO_PROVISION_ENABLED"; payload: AuthSettingsType["autoProvision"]["enabled"] }
  | { type: "ADD_AUTO_PROVISION_DOMAIN"; payload: string }
  | { type: "REMOVE_AUTO_PROVISION_DOMAIN"; payload: string }
  | { type: "SET_ALLOWED_PROVIDER"; payload: AuthSettingsType["allowedProvider"] };

export interface AuthState extends ProviderConfig {
  ssoEnabled: boolean;
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  authProvider: AllowedProvider;
  autoProvision: Required<AutoProvisionType>;
}

export type SSOConfig = {
  id?: string;
  enabled: boolean;
  callbackUrl?: string;
};

export interface SAMLConfig extends SSOConfig, Partial<SAMLForm> {
  spEntityId?: string;
}

export interface OIDCConfig extends SSOConfig, Partial<OIDCForm> {}

export type SSOForm = SAMLForm | OIDCForm;

export type SAMLForm = {
  idpEntityId: string;
  ssoUrl: string;
  certificate: string;
};

export type OIDCForm = {
  clientId: string;
  issuerUrl: string;
  clientSecret: string;
};

export const allAuthProvidersVal = "all"; // this is a special value that means all providers are allowed it is never saved to firestore and is only used in the UI
export type AllowedProvider = AuthenticationProvider | typeof allAuthProvidersVal;

export type SSOLabels = {
  spEntityId: string;
  callbackUrl: string;
  idpLoginUrl: string;
};

export type SsoUiSelectedIdp = "default" | "Okta" | "JumpCloud";
