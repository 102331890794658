export const MILLIS_IN_A_DAY = 86400000;
export const MILLIS_IN_A_HOUR = 3600000;
export const MILLIS_IN_A_WEEK = 604800000;
export const MILLIS_IN_A_MONTH = 2592000000; // month intervals are not fixed

export const intervalsMap = {
  Hour: MILLIS_IN_A_HOUR,
  Day: MILLIS_IN_A_DAY,
  Week: MILLIS_IN_A_WEEK,
  Month: MILLIS_IN_A_MONTH, // month intervals are not fixed
};

export const defaultResolutionInterval = { hours: 2 };
