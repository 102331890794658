import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

const useStyles = makeStyles((theme) => ({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
    bottom: 0,
    right: 0,
    display: "flex",
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1203,
  },
  staticTooltip: {
    whiteSpace: "nowrap",
  },
  backDrop: {
    zIndex: 1102,
  },
}));

export default function MobileFab({ actions, onAction }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (action) => {
    setOpen(false);
    onAction(action.id);
  };
  const showFab = actions.some((a) => a.show);
  return (
    <>
      <Backdrop className={classes.backDrop} open={open} />
      {showFab && (
        <SpeedDial
          ariaLabel="SpeedDial tooltip example"
          className={classes.speedDial}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {actions.map(
            (action) =>
              action.show && (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  tooltipOpen
                  onClick={() => handleClick(action)}
                  classes={{
                    staticTooltip: classes.staticTooltip,
                  }}
                />
              )
          )}
        </SpeedDial>
      )}
    </>
  );
}
