import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CurrencyCode, type OldCurrency } from "./Currency";

type Category = {
  id: string;
  name: string;
};

enum Platforms {
  AmazonWebServices = "amazon-web-services",
  CloudManagementPlatform = "cloud-management-platform",
  Credits = "credits",
  Finance = "finance",
  GSuite = "g-suite",
  GcpFirebase = "gcp-firebase",
  GoogleCloud = "google-cloud",
  MicrosoftAzure = "microsoft-azure",
  Office365 = "office-365",
  Pss = "pss",
}

type Platform = `${Platforms}`;

@subCollection("services")
export class CatalogModelServiceModel {
  billingCycle?: "ANNUAL" | "FLEXIBLE";

  blacklisted?: boolean;

  categories?: Category[] | null;

  id?: number | string;

  last_update?: Timestamp;

  name?: string;

  payment?: "MONTHLY" | "YEARLY";

  plan?: "ANNUAL" | "FLEXIBLE";

  platform?: Platform;

  prevPrice?: Record<OldCurrency, number> | null;

  prevPriceEndDate?: Timestamp | null;

  price?: { [key: string]: number };

  skuId?: string;

  skuName?: string;

  skuPriority?: string;

  summary?: string;

  tags?: string[];

  type?: "OFFLINE" | "ONLINE";

  url?: string;

  version?: number;

  quantity?: number;
}

type Prices = {
  [K in CurrencyCode]?: number;
} & { USD: number };

export type CatalogOffice365CloudService = {
  id: number;
  payment: "MONTHLY" | "YEARLY";
  plan: "ANNUAL" | "FLEXIBLE";
  price: Prices;
  skuId: string;
  skuName: string;
  skuPriority: string;
  type: "OFFLINE" | "ONLINE";
};

export type CatalogGSuiteCloudService = {
  id: number;
  payment: "MONTHLY" | "YEARLY";
  plan: "ANNUAL" | "FLEXIBLE";
  prevPrice: Prices;
  prevPriceEndDate: Timestamp | null;
  price: Prices;
  skuId: string;
  skuName: string;
  skuPriority: string;
  type: "OFFLINE" | "ONLINE";
};

export type ZendeskOrBetterCloudService = {
  billingCycle: "ANNUAL" | "FLEXIBLE";
  price: Record<CurrencyCode, number>;
  skuId: string;
  skuName: string;
};

@collection("catalog")
export class CatalogModel {
  docs?: {
    zendesk: {
      subCollections: {
        services: ZendeskOrBetterCloudService;
      };
    };
    bettercloud: {
      subCollections: {
        services: ZendeskOrBetterCloudService;
      };
    };

    "g-suite": {
      subCollections: {
        services: CatalogGSuiteCloudService;
      };
    };

    "office-365": {
      subCollections: {
        services: CatalogOffice365CloudService;
      };
    };
  };
}
