import { Box } from "@mui/material";

import { type CategoryContext } from "../../types";
import MobileMenu from "./MobileMenu";
import MobileMenuSlider from "./MobileMenuSlider";
import MobileSubMenu from "./MobileSubMenu";

type MobileMenuWrapperProps = {
  closeMenu: () => void;
  openMenu: () => void;
  selectedCategory: CategoryContext | null;
  openSubMenu: (category: CategoryContext) => void;
  isMenuOpen: boolean;
  isSubMenuOpen: boolean;
  categories: CategoryContext[];
  settings: CategoryContext[];
  showOptions: boolean;
  hideWhatsNewOption: boolean;
};

const MobileMenuWrapper = ({
  categories,
  settings,
  isMenuOpen,
  closeMenu,
  openSubMenu,
  isSubMenuOpen,
  openMenu,
  selectedCategory,
  showOptions,
  hideWhatsNewOption,
}: MobileMenuWrapperProps) => (
  <>
    <MobileMenuSlider isVisible={isMenuOpen} onClose={closeMenu}>
      <Box p={4}>
        {isSubMenuOpen ? (
          <MobileSubMenu openMenu={openMenu} closeMenu={closeMenu} selectedCategory={selectedCategory} />
        ) : (
          <MobileMenu
            openSubMenu={openSubMenu}
            settings={settings}
            categories={categories}
            closeMenu={closeMenu}
            showOptions={showOptions}
            hideWhatsNewOption={hideWhatsNewOption}
          />
        )}
      </Box>
    </MobileMenuSlider>
  </>
);

export default MobileMenuWrapper;
