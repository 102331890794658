import axios from "axios";
import { Md5 } from "ts-md5";

import { handleResponseError } from "../api/axiosClient";

export const getGravatarCall = async (axs, email: string): Promise<string | null | undefined> => {
  try {
    const formattedEmail = email.trim().toLowerCase();
    const encodedEmail = Md5.hashStr(formattedEmail);
    const gravatarUrl = `https://gravatar.com/avatar/${encodedEmail}`;
    const response = await axs.get(`${gravatarUrl}.png?d=blank`);
    return response.status === 200 && response.headers["content-length"] > 256 ? gravatarUrl : null;
  } catch (e: any) {
    handleResponseError(e);
  }
};

export const getGravatar = (email: string) => getGravatarCall(axios, email);
