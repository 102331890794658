import { Spot0Model } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { firestoreTimestamp } from "../../utils/firebase";
import { SpotScalingMode } from "./types";

export const asgsCollection = getCollection(Spot0Model).doc("spotApp").collection("asgs");

/**
 * Update AGS document with 'keep up to date' config (updating the mode)
 * @param docId
 * @param value
 */
export async function updateKeepAsgUpToDate(docId: string, value: boolean) {
  return asgsCollection.doc(docId).update({
    mode: value ? SpotScalingMode.Autopilot : SpotScalingMode.Recommend,
  });
}

/**
 * Update AGS document with fallback on demand
 * @param docId
 * @param enable
 */
export async function updateFallbackOnDemandStatus(docId: string, enable: boolean): Promise<void> {
  return asgsCollection.doc(docId).update("config.fallbackOnDemand", enable);
}

/**
 * Update AGS document with time stamp
 * @param docId
 */
export async function setFirstTimeStartedToUseSpots(docId: string) {
  return asgsCollection.doc(docId).update({
    timeStartedUsingSpots: firestoreTimestamp(),
  });
}
