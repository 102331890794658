import { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type DeleteRoleDialogProps = {
  accountId: string | undefined;
  handleDelete: () => void;
  onClose: () => void;
};

export const DeleteRoleDialog = ({ accountId, handleDelete, onClose }: DeleteRoleDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const onDeleteClicked = () => {
    handleDelete();
    onClose();
  };

  useEffect(() => {
    if (accountId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [accountId]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Unlink account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to unlink the following account {accountId} from the CMP?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onDeleteClicked} color="primary" autoFocus>
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
  );
};
