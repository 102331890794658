import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Stack, TableCell, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";

import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { type LabelWithRef } from "../labels/types";
import { useAttributionGroupThreeDotsMenu } from "./hooks";
import { type AttributionGroupWithRef } from "./types";

type AttributionGroupRowProps = {
  setOpenDeleteDialog: (open: boolean) => void;
  setShareDialogOpen: (open: boolean) => void;
  setClickedRow: (attributionGroups: AttributionGroupWithRef) => void;
  row: AttributionGroupWithRef;
  labels: LabelWithRef[] | undefined;
  tierLimitReached: boolean;
};

export const AttributionGroupRow = ({
  setOpenDeleteDialog,
  setShareDialogOpen,
  setClickedRow,
  row,
  labels,
  tierLimitReached,
}: AttributionGroupRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const threeDotsMenu = useAttributionGroupThreeDotsMenu({
    row,
    handleShare: () => {
      setClickedRow(row);
      setShareDialogOpen(true);
    },
    handleDelete: () => {
      setClickedRow(row);
      setOpenDeleteDialog(true);
    },
    tierLimitReached,
  });
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  const nameAndDescCell = (
    <TableCell style={{ maxWidth: "50vw", overflowWrap: "break-word" }}>
      <Stack spacing={1}>
        <Stack>
          <Typography
            component={RouterLink}
            to={{
              pathname: `${routeMatchURL}/${row.ref.id}`,
              state: {
                prevPage: routeMatchURL,
              },
            }}
            color="inherit"
            variant="body2"
          >
            {row.data.name}
          </Typography>
          {!!row.data.description && (
            <Typography
              color="textSecondary"
              variant="body2"
              sx={{
                whiteSpace: "noWrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "calc(100vw/4)",
              }}
              title={row.data.description}
            >
              {row.data.description}
            </Typography>
          )}
        </Stack>
        <LabelList sx={{ maxWidth: "25vw" }} labels={rowLabels} />
      </Stack>
    </TableCell>
  );

  return (
    <>
      {nameAndDescCell}
      <Hide mdDown>
        <TableCell>{row.data.attributions.length}</TableCell>
        <TableCell>{row.transform.owner}</TableCell>
        <TableCell>{capitalize(row.data.type)}</TableCell>
        <TableCell>{row.transform.timeModified}</TableCell>
      </Hide>
      <TableCell padding="none">{threeDotsMenu}</TableCell>
    </>
  );
};
