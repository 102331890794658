import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo } from "react";

import {
  type CurrencyCodes,
  Metadata,
  Positions,
  Renderer,
  type ReportFilter,
  TimeInterval,
} from "@doitintl/cmp-models";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { analyticsAlertText, globalText } from "../../../../assets/texts";
import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import { useAnalyticsDimensions } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { useCloudAnalyticsContext } from "../../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import { colsMap } from "../../../../Pages/CloudAnalytics/generateReport/utils";
import PreviewReport from "../../../../Pages/CloudAnalytics/previewReport/PreviewReport";
import {
  convertStringToDataRecord,
  extractCloudProviders,
} from "../../../../Pages/CloudAnalytics/report/ReportQueryUtils";
import {
  budgetConfigurationDynamicOptions,
  BudgetConfigurationOptions,
  BudgetConfigurations,
  BudgetDynamicConfigurations,
  BudgetTypes,
  CurrencyOptions,
  getCurrencyOption,
  timeIntervalOptions,
} from "../../../../Pages/CloudAnalytics/utilities";
import { type BudgetInfo, type MetadataOption } from "../../../../types";
import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";
import { getAlertAmountFromPercentage } from "../shared";
import { getFixedTimeInterval, getTimeRangeOption } from "../utils";
import BudgetInfoPanel from "./BudgetInfoPanel";
import DaysNextPeriodPullOver from "./DaysNextPeriodPullOver";
import MonthsNextPeriodPullOver from "./MonthsNextPeriodPullOver";
import QuartersNextPeriodPullOver from "./QuartersNextPeriodPullOver";
import YearsNextPeriodPullOver from "./YearsNextPeriodPullOver";

type Props = {
  setOverrideStep: (step: number) => void;
  dimensionOptions: MetadataOption[];
  budgetInfo: BudgetInfo;
  setBudgetInfo: Dispatch<SetStateAction<BudgetInfo>>;
  isCurrentUserEditor: boolean;
  lastPeriodCost: number;
};

const Step2 = ({ setOverrideStep, budgetInfo, setBudgetInfo, isCurrentUserEditor, lastPeriodCost }: Props) => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const { metadata: metadataSnapshots } = useCloudAnalyticsContext();
  const { dimensions } = useAnalyticsDimensions({
    metadataSnapshots,
    attributions,
  });

  const handleChangeAmount = useCallback(
    (event) => {
      const newAmount = parseFloat(event.target.value);
      setBudgetInfo((prev) => ({ ...prev, budgetAmount: isNaN(newAmount) ? 0 : newAmount }));
    },
    [setBudgetInfo]
  );

  const handleBudgetConfigurationOptionChanged = useCallback(
    (event) => {
      setBudgetInfo((prev) => {
        const temp = { ...prev };
        const type = event.target.value;
        temp.configurationOption = {
          type,
          dynamic: type === BudgetConfigurations.DYNAMIC ? BudgetDynamicConfigurations.LAST_PERIOD : undefined,
        };
        return temp;
      });
    },
    [setBudgetInfo]
  );

  const previewData = useMemo(() => {
    if (!dimensions) {
      return;
    }
    const filters =
      budgetInfo.filters?.flatMap((f) => {
        const md = dimensions?.find((d) => f.id === d.id);

        const filter: ReportFilter = {
          ...f,
          type: (f.type || md?.data.type) ?? "",
          position: md?._position,
          id: f.id,
          field: (f.field || md?.data.field) ?? "",
          key: (f.key || md?.data.key) ?? "",
          includeInFilter: true,
          limit: 0,
          limitMetric: 0,
          composite: [],
          regexp: md?._regexp ?? null,
          values: f.values ?? [],
        };
        return filter;
      }) ?? [];

    const cloudProviders = extractCloudProviders(dimensions);

    const timeIntervalOption = timeIntervalOptions.find((t) => t.value === budgetInfo.currentTypeAndFrequency.period);

    dimensions?.forEach((d) => {
      if (filters.some((f) => f.id === d.id)) {
        d._position = Positions.ROW;
      }

      if (d._position === Positions.COL) {
        if (d.data.key === timeIntervalOption?.value || d.data.key === "year") {
          d._visible = true;
        }
      }
    });

    const rows = convertStringToDataRecord(
      dimensions,
      filters.map((f) => f.id)
    );
    const cols = convertStringToDataRecord(dimensions, colsMap[budgetInfo.currentTypeAndFrequency.period]);
    const attribution = filters.find((f) => f.id === `${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`);
    const attributionIds = attribution ? attribution.values : [];
    const initialScope = budgetInfo.scope.map((f) => f.ref.id);

    return {
      currency: budgetInfo.currency,
      scope: initialScope.length > 0 ? initialScope : (attributionIds ?? []),
      attributionGroups: [],
      timeInterval:
        budgetInfo.currentTypeAndFrequency.type === BudgetTypes.RECURRING
          ? budgetInfo.currentTypeAndFrequency.period
          : getFixedTimeInterval(budgetInfo.startPeriod, budgetInfo.endPeriod),
      timeRangeOptions: getTimeRangeOption(
        budgetInfo.currentTypeAndFrequency.type,
        budgetInfo.currentTypeAndFrequency.period,
        budgetInfo.startPeriod,
        budgetInfo.endPeriod
      ),
      attributionGroupsPayload: [],
      rows,
      filters,
      cols,
      cloudProviders,
      dataSource: budgetInfo.dataSource,
    };
  }, [
    dimensions,
    budgetInfo.filters,
    budgetInfo.currentTypeAndFrequency.period,
    budgetInfo.currentTypeAndFrequency.type,
    budgetInfo.scope,
    budgetInfo.currency,
    budgetInfo.startPeriod,
    budgetInfo.endPeriod,
    budgetInfo.dataSource,
  ]);

  const NextPeriodPullOverInputs = () => {
    switch (budgetInfo.currentTypeAndFrequency.period) {
      case TimeInterval.DAY:
        return <DaysNextPeriodPullOver currency={budgetInfo.currency} disabled={!isCurrentUserEditor} />;
      case TimeInterval.MONTH:
        return (
          <MonthsNextPeriodPullOver
            startDate={budgetInfo.startPeriod}
            currency={budgetInfo.currency}
            disabled={!isCurrentUserEditor}
          />
        );
      case TimeInterval.QUARTER:
        return <QuartersNextPeriodPullOver currency={budgetInfo.currency} disabled={!isCurrentUserEditor} />;
      case TimeInterval.YEAR:
        return (
          <YearsNextPeriodPullOver
            startDate={budgetInfo.startPeriod}
            currency={budgetInfo.currency}
            disabled={!isCurrentUserEditor}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    setBudgetInfo((prev) => {
      const newAlerts = [...prev.alerts];

      if (!budgetInfo.budgetAmount) {
        return { ...prev, alerts: newAlerts.map((alert) => ({ ...alert, amount: 0 })) };
      }

      return {
        ...prev,
        alerts: newAlerts.map((alert) => {
          if (alert.percentage) {
            return {
              amount: getAlertAmountFromPercentage(alert.percentage, budgetInfo.budgetAmount),
              percentage: alert.percentage,
              triggered: alert.triggered,
            };
          }
          return alert;
        }),
      };
    });
  }, [budgetInfo.budgetAmount, setBudgetInfo]);

  return (
    <Stack gap={3}>
      <BudgetInfoPanel
        typeAndFrequency={budgetInfo.currentTypeAndFrequency.label}
        startDate={budgetInfo.startPeriod.toFormat("dd LLL, yyyy")}
        endDate={
          budgetInfo.currentTypeAndFrequency.type === BudgetTypes.FIXED
            ? budgetInfo.endPeriod?.toFormat("dd LLL, yyyy")
            : undefined
        }
        setOverrideStep={setOverrideStep}
        filters={budgetInfo.filters}
        dimensionOptions={dimensions ?? []}
        scope={budgetInfo.scope}
      />

      <Box>
        <Typography variant="subtitle1" fontWeight={500} mb={2}>
          {budgetTxt.CREATE_BUDGET.BUDGET_AMOUNT}
        </Typography>

        <Autocomplete
          sx={{
            mb: 3,
          }}
          disabled={!isCurrentUserEditor}
          onChange={(_, val) => {
            if (val) {
              setBudgetInfo((prev) => ({ ...prev, currency: val.split(" ")[0] as CurrencyCodes }));
            }
          }}
          options={CurrencyOptions.map((c) => getCurrencyOption(c))}
          value={getCurrencyOption(budgetInfo.currency)}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={analyticsAlertText.STEPPER.CURRENCY}
              placeholder={analyticsAlertText.STEPPER.SELECT_CURRENCY}
              fullWidth
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />

        <TextField
          required
          variant="outlined"
          type="number"
          label={budgetTxt.CREATE_BUDGET.BUDGET_AMOUNT}
          fullWidth
          size="small"
          inputProps={{ min: 0 }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(budgetInfo.currency)}</InputAdornment>,
          }}
          value={Math.round(budgetInfo.budgetAmount) || ""}
          onChange={handleChangeAmount}
          onKeyUp={onKeyPressPreventNonNumeric}
          disabled={
            !isCurrentUserEditor ||
            budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH
          } // !isCurrentUserEditor || usePrevSpend
        />
        <Typography variant="caption" color="text.secondary">
          {budgetTxt.CREATE_BUDGET.LAST_PERIOD_ACTUAL_COST}:{" "}
          <b>
            {lastPeriodCost
              ? `${getCurrencySymbol(budgetInfo.currency)}${(Math.round(lastPeriodCost * 100) / 100).toLocaleString()}`
              : globalText.NA}
          </b>
        </Typography>
      </Box>

      {budgetInfo.currentTypeAndFrequency.type !== BudgetTypes.FIXED && (
        <>
          <Box>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              {budgetTxt.CREATE_BUDGET.BUDGET_CONFIGURATION}
            </Typography>

            <RadioGroup
              sx={{
                pl: 1,
              }}
              value={budgetInfo.configurationOption.type}
              onChange={handleBudgetConfigurationOptionChanged}
            >
              {BudgetConfigurationOptions.filter((option) => {
                if (option.value === BudgetConfigurations.SPECIFY) {
                  return budgetInfo.currentTypeAndFrequency.period !== TimeInterval.WEEK;
                }
                return true;
              }).map((option) => (
                <Box key={option.value}>
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    disabled={!isCurrentUserEditor}
                  />
                  {option.value === BudgetConfigurations.DYNAMIC &&
                    budgetInfo.configurationOption.type === BudgetConfigurations.DYNAMIC && (
                      <Box
                        sx={{
                          maxWidth: "552px",
                        }}
                      >
                        <Select
                          fullWidth
                          value={budgetInfo.configurationOption.dynamic}
                          onChange={(e) => {
                            setBudgetInfo((prev) => ({
                              ...prev,
                              configurationOption: {
                                ...prev.configurationOption,
                                dynamic: e.target.value as BudgetDynamicConfigurations,
                              },
                            }));
                          }}
                          size="small"
                        >
                          {budgetConfigurationDynamicOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              disabled={
                                (option.value === BudgetDynamicConfigurations.PERCENTAGE_GROWTH &&
                                  !budgetInfo.budgetAmount) ||
                                (option.value === BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH &&
                                  !budgetInfo.budgetAmount)
                              }
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>

                        {(budgetInfo.configurationOption.dynamic === BudgetDynamicConfigurations.PERCENTAGE_GROWTH ||
                          budgetInfo.configurationOption.dynamic ===
                            BudgetDynamicConfigurations.LAST_PERIOD_AND_PERCENTAGE_GROWTH) && (
                          <TextField
                            value={budgetInfo.growthPerPeriod}
                            onChange={(e) => {
                              setBudgetInfo((prev) => {
                                const val = parseInt(e.target.value);
                                const valNormal = isNaN(val) ? 0 : val;
                                return { ...prev, growthPerPeriod: valNormal };
                              });
                            }}
                            sx={{
                              mt: 2,
                            }}
                            fullWidth
                            required
                            label={budgetTxt.CREATE_BUDGET.GROWTH}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            disabled={!isCurrentUserEditor || !budgetInfo.budgetAmount}
                            inputProps={{ min: 0 }}
                            type="number"
                            onKeyUp={onKeyPressPreventNonNumeric}
                          />
                        )}
                      </Box>
                    )}
                </Box>
              ))}
            </RadioGroup>
          </Box>

          {budgetInfo.configurationOption.type === BudgetConfigurations.SPECIFY && <NextPeriodPullOverInputs />}
          {/* <Box>
            <Typography variant="subtitle1" fontWeight={500} mb={1}>
              {budgetTxt.CREATE_BUDGET.ADVANCED_SETTINGS}
            </Typography>
            <FormGroup
              sx={{
                pl: 1,
              }}
            >
              <FormControlLabel control={<Checkbox />} label={budgetTxt.CREATE_BUDGET.USE_AMORTIZED_COST} />
            </FormGroup>
          </Box> */}
        </>
      )}
      <Box>
        <Typography variant="subtitle1" fontWeight={500} mb={1}>
          {budgetTxt.CREATE_BUDGET.BUDGET_PREVIEW}
        </Typography>
      </Box>

      {previewData && (
        <PreviewReport
          attributions={attributions}
          displayForecast
          previewData={previewData}
          renderer={Renderer.STACKED_COLUMN_CHART}
          runQuery={false}
          setRenderer={() => {}}
          setRunQuery={() => {}}
        />
      )}
    </Stack>
  );
};

export default Step2;
