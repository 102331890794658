import { type Dispatch, type SetStateAction } from "react";

import isEmpty from "lodash/isEmpty";

import { type ContractStateType, type VendorContract } from "../../types";

export const validateDocumentsStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, any> = {};
  const vendorContractErrors: Record<string, any> = {};

  if (state.notes.length > 1000) {
    errors.notes = true;
  }

  if (!state.contractFile) {
    errors.contractFile = true;
  }

  if (state.vendorContract?.commitmentType && !state.vendorContract?.contractFile) {
    vendorContractErrors.contractFile = true;
  }

  setState({
    ...state,
    errors,
    vendorContract: { ...(state.vendorContract as VendorContract), errors: vendorContractErrors },
  });

  return isEmpty(errors) && isEmpty(state.vendorContract?.errors);
};
