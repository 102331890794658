import { cloudflowTexts } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type CloudflowWSnap } from "../../../types/Cloudflow";

export const itemsDescriptions: FilterTableItemDescriptor<CloudflowWSnap>[] = [
  {
    headerText: cloudflowTexts.TABLE_HEADERS.NAME,
    value: "data.name",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.TABLE_HEADERS.OWNER,
    value: "data.owner",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.TABLE_HEADERS.PUBLISHED_STATUS,
    value: "data.status",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.TABLE_HEADERS.LAST_COMPLETED,
    value: "data.updatedAt",
    filterType: "date",
    filterOptions: firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    value: "@",
    headerText: "",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
