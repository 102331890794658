import { Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { type CodeVariable } from "../../../../Pages/Flexsave/Standalone/types";

type Props = {
  codeVariables: CodeVariable;
  setCopyClicked: (ck: boolean) => void;
  serviceAccount?: string;
};

export const getGCPCommandsSet = (serviceAccount?: string) => [
  `gcloud billing accounts add-iam-policy-binding $BILLING_ACCOUNT_ID --member=serviceAccount:${serviceAccount} --role=roles/billing.viewer`,
  "gcloud config set project $PROJECT_ID",
  `bq show --format=prettyjson $DATASET_ID | jq '.access = .access + [{"role": "READER", "userByEmail": "${serviceAccount}"}]' > permissions.json`,
  "bq update --source permissions.json $DATASET_ID",
];

export const BillingCommands = ({ codeVariables, setCopyClicked, serviceAccount }: Props) => {
  const commands = getGCPCommandsSet(serviceAccount);
  return (
    <Stack spacing={2}>
      <Typography color="text.secondary" variant="body2">
        1. Set Billing Account Viewer on the billing account
      </Typography>
      <CopyCodeBlock
        base={commands[0]}
        variables={codeVariables}
        callbackClick={() => {
          setCopyClicked(true);
        }}
      />
      <Typography color="text.secondary" variant="body2">
        2. Set the project property in the core section.
      </Typography>
      <CopyCodeBlock
        base={commands[1]}
        variables={codeVariables}
        callbackClick={() => {
          setCopyClicked(true);
        }}
      />
      <Typography color="text.secondary" variant="body2" pt={1}>
        3. Grant DoiT service account BigQuery Data Viewer role on the dataset that contains your billing data.
      </Typography>
      <CopyCodeBlock
        base={commands[2]}
        variables={codeVariables}
        callbackClick={() => {
          setCopyClicked(true);
        }}
      />
      <Typography color="text.secondary" variant="body2" pt={1}>
        4. Updates the permissions using the updated file from step 3.
      </Typography>
      <CopyCodeBlock
        base={commands[3]}
        variables={codeVariables}
        callbackClick={() => {
          setCopyClicked(true);
        }}
      />
    </Stack>
  );
};
