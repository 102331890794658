import { type SaaSConsoleType } from "@doitintl/cmp-models";

import mixpanel from "../../../../utils/mixpanel";

type EventTrackingParams = Record<string, string | number | boolean>;

export const trackCloudSelectorSelected = () => {
  mixpanel.track("saas.cloud_selector.selected");
};

export const trackConnectInfo = (cloud: SaaSConsoleType) => {
  mixpanel.track("saas.connect.info.view", { cloud });
};
export const trackConnectView = (cloud: SaaSConsoleType) => {
  mixpanel.track("saas.connect.view", { cloud });
};
export const trackConnectClick = (cloud: SaaSConsoleType) => {
  mixpanel.track("saas.connect.click", { cloud });
};

export const trackCurView = (eventParams: EventTrackingParams) => {
  mixpanel.track("saas.CUR.view", eventParams);
};
export const trackCurRefresh = () => {
  mixpanel.track("saas.CUR.refresh");
};
