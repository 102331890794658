import { CustomersColumnsID } from "@doitintl/cmp-models";

import { type DataColumns, type HeaderColumn } from "../../types/FilterTable";
import { assetTypeName } from "../../utils/common";
import {
  awsRampPlanAttainment,
  bqLens,
  flexsave,
  gcpRampPlanAttainment,
  getFlexsaveStatus,
  getRampPlanStatus,
  gkeCostAllocation,
  trialStatus,
} from "./consts";
import {
  type BaseCustomerRowListItem,
  type FlexsavePlatformItem,
  type RampPlanStatus,
  type TrialStatusDetails,
} from "./types";

export const headers: HeaderColumn[] = [
  {
    value: "",
    label: "",
    tooltip: "",
    hidden: { smDown: true },
  },
  {
    value: CustomersColumnsID.PrimaryDomain,
    label: "Domain",
    tooltip: "Primary Domain",
  },
  {
    value: CustomersColumnsID.ThisMonth,
    label: "This Mo.",
    tooltip: "This Month",
  },
  {
    value: CustomersColumnsID.Trend,
    label: "Trend",
    tooltip: "Trend",
  },
  {
    value: CustomersColumnsID.AccountManagers,
    label: "Account managers",
    tooltip: "Assigned Account Managers",
    hidden: { smDown: true },
  },
  {
    value: bqLens.value,
    label: bqLens.label,
    tooltip: bqLens.label,
    hidden: { smDown: true },
  },
  {
    value: gkeCostAllocation.value,
    label: gkeCostAllocation.label,
    tooltip: gkeCostAllocation.label,
    hidden: { smDown: true },
  },
  {
    value: flexsave("AWS").value,
    label: flexsave("AWS").label,
    tooltip: "Flexsave AWS",
    style: { width: "15%" },
    hidden: { smDown: true },
  },
  {
    value: awsRampPlanAttainment.statusValue,
    label: awsRampPlanAttainment.label,
    tooltip: awsRampPlanAttainment.label,
    hidden: { smDown: true },
  },
  {
    value: gcpRampPlanAttainment.statusValue,
    label: gcpRampPlanAttainment.label,
    tooltip: gcpRampPlanAttainment.label,
    hidden: { smDown: true },
  },
  {
    value: trialStatus.value,
    label: trialStatus.label,
    tooltip: trialStatus.label,
    hidden: { smDown: true },
  },
];

export const filterColumns: DataColumns<BaseCustomerRowListItem> = [
  {
    label: "Domain",
    path: "primaryDomain",
    comparators: ["==", "!="],
  },
  {
    label: "Name",
    path: "name",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Trend",
    path: "trend",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
  },
  {
    label: "Account Managers",
    path: "accountManagers",
    toOption: (value) => value?.map((v: string) => ({ value: v, label: v })),
    comparators: ["==", "!="],
  },
  {
    label: "This Month Spend",
    path: "thisMonth",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
  },
  {
    label: "Assets",
    path: "assets",
    toOption: (value) => value?.map((v: string) => ({ value: v, label: assetTypeName(v) })),
    comparators: ["==", "!="],
  },
  {
    label: bqLens.label,
    path: bqLens.value,
    comparators: ["==", "!="],
  },
  {
    label: flexsave("AWS").label,
    path: flexsave("AWS").value,
    toOption: (value: FlexsavePlatformItem) => ({
      value,
      label: getFlexsaveStatus(value),
    }),
    comparators: ["<", "<=", ">", ">=", "==", "!="],
  },
  {
    label: awsRampPlanAttainment.label,
    path: awsRampPlanAttainment.value,
    toOption: (value: RampPlanStatus) => ({
      value,
      label: getRampPlanStatus(value),
    }),
    comparators: ["==", "!="],
  },
  {
    label: gcpRampPlanAttainment.label,
    path: gcpRampPlanAttainment.value,
    toOption: (value: RampPlanStatus) => ({
      value,
      label: getRampPlanStatus(value),
    }),
    comparators: ["==", "!="],
  },
  {
    label: trialStatus.label,
    path: trialStatus.value,
    toOption: (value: TrialStatusDetails) => ({
      value,
      label: value.status,
    }),
    comparators: ["==", "!="],
  },
];
