import { type EntitlementKey, type NavigatorSKUs, TierPackageTypes, TierTypes } from "@doitintl/cmp-models";

export const defaultTierFormData = {
  description: "",
  displayName: "",
  name: "",
  packageType: TierPackageTypes.NAVIGATOR,
  price: {
    AUD: 0,
    CAD: 0,
    EUR: 0,
    USD: 0,
    GBP: 0,
    ILS: 0,
    DKK: 0,
    NOK: 0,
    SEK: 0,
    BRL: 0,
    SGD: 0,
    MXN: 0,
    CHF: 0,
    MYR: 0,
    TWD: 0,
    EGP: 0,
    ZAR: 0,
    JPY: 0,
    IDR: 0,
    AED: 0,
    THB: 0,
    COP: 0,
  },
  sku: "" as NavigatorSKUs,
  type: TierTypes.PRESET,
  entitlements: [],
};

export const defaultEntitlementFormData = {
  description: "",
  displayName: "",
  type: TierPackageTypes.NAVIGATOR,
  key: "" as EntitlementKey,
  limit: undefined,
};
