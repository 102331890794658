import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

import { cloudReportCardText } from "../../../assets/texts";

type Props = {
  hasAccessToReport: boolean;
  trackFullReport: () => void;
  reportPath: string;
};

export const CloudReportCardError = ({ hasAccessToReport, trackFullReport, reportPath }: Props) => (
  <Box
    sx={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "10px 10px 0px 10px",
    }}
  >
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 10, paddingBottom: 20 }}>
      <span>
        <Typography variant="body1" color="textSecondary">
          {cloudReportCardText.TABLE_ERROR_LIMIT}
        </Typography>
      </span>
    </Box>
    {hasAccessToReport && (
      <Button onClick={trackFullReport} color="primary" variant="contained" component={Link} to={reportPath}>
        {cloudReportCardText.EDIT_REPORT}
      </Button>
    )}
  </Box>
);
