import { useHistory } from "react-router";
import { Typography } from "@mui/material";

import { Alert } from "../../Components/Alerts";
import { useLocalStorage } from "../../Components/FilterTable/hook";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useLanguage } from "../../locales/i18n";
import { browserLanguage } from "../../locales/utils";
import { useAvatarLink } from "../../Navigation/Components/Header/AvatarMenu/useAvatarLink";

export const LanguageAlert = () => {
  const [alreadyShown, setAlreadyShown] = useLocalStorage("languageAlert", false);
  const [language, setLanguage] = useLanguage();

  const { customer } = useCustomerContext({ allowNull: true });
  const history = useHistory();
  const { isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });

  const avatarLink = useAvatarLink({ isDoitEmployee, customerId: customer?.id, userId });

  if (!alreadyShown && browserLanguage === "ja" && language === "ja") {
    return (
      <Alert
        buttonProps={{
          text: "Change to English",
          onClick: () => {
            setAlreadyShown(true);
            setLanguage("en");
            history.push(avatarLink);
          },
        }}
        severity="info"
        onClose={() => {
          setAlreadyShown(true);
        }}
      >
        <Typography>Your console is set to Japanese</Typography>
      </Alert>
    );
  }
};
