import { useEffect, useState } from "react";

import { CollectionModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import orderBy from "lodash/orderBy";

import { type ChartPoint } from "./types";

export type DebtGraphData = [Array<ChartPoint>, Array<ChartPoint>, Array<ChartPoint>];

export const useDebtGraphData = () => {
  const [debtGraphData, setDebtGraphData] = useState<DebtGraphData>();

  useEffect(() => {
    getCollection(CollectionModel)
      .doc("debt-analytics")
      .collection("debtAnalyticsGraph")
      .orderBy("timestamp")
      .limitToLast(6)
      .get()
      .then((debtAnalyticsGraph) => {
        const data: DebtGraphData = [[], [], []];
        debtAnalyticsGraph.forEach((docSnap) => {
          const monthData = docSnap.asModelData();
          const days = Object.entries(monthData);
          days.forEach(([day, dayGraph]) => {
            data[0].push({ x: day, y: dayGraph.severity?.["0"].value ?? 0 });
            data[1].push({
              x: day,
              y:
                (dayGraph.severity?.["10"]?.value ?? 0) +
                (dayGraph.severity?.["20"]?.value ?? 0) +
                (dayGraph.severity?.["30"]?.value ?? 0),
            });
            data[2].push({ x: day, y: dayGraph.severity?.["40"].value ?? 0 });
          });
        });

        for (const i in data) {
          data[i] = orderBy(data[i], ["x"], ["asc"]);
        }
        setDebtGraphData(data);
      });
  }, []);

  return debtGraphData;
};
