import { useState } from "react";

import { FlexsaveTypes } from "@doitintl/cmp-models";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Link,
} from "@mui/material";

import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { useStandaloneAPI } from "../hooks";

type Props = {
  accountId: string;
  onSuccess?: () => void;
  underline?: "none" | "hover";
  color?: string;
};

export default function DeleteEstimation({ accountId, onSuccess, underline = "hover", color }: Props) {
  const api = useStandaloneAPI(FlexsaveTypes.AWS);
  const showError = useErrorSnackbar();
  const [modalVisible, setModalVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const onDisableClicked = async () => {
    setLoading(true);
    try {
      await api.deleteAWSEstimation(accountId);
    } catch (e) {
      consoleErrorWithSentry(e);
      showError("Something went wrong");
      setLoading(false);
      return;
    }
    setLoading(false);
    setModalVisible(false);
    if (onSuccess) {
      onSuccess();
    }
  };
  return (
    <>
      {modalVisible && (
        <Dialog
          open={true}
          onClose={() => {
            setModalVisible(false);
          }}
        >
          <DialogTitle>Reset Flexsave estimation</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ maxWidth: 400 }} data-cy="estimation-delete-content">
              The current estimation will be removed and the customer will need to complete a new estimate to see their
              potential savings.
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => {
                setModalVisible(false);
              }}
              variant="text"
              data-cy="cancel-estimation-delete"
            >
              Cancel
            </Button>

            <Button
              sx={{
                ml: 1,
              }}
              onClick={onDisableClicked}
              color="primary"
              variant="contained"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} color="inherit" /> : undefined}
              data-cy="confirm-estimation-delete"
            >
              Reset estimation
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Link
        color="error"
        onClick={() => {
          setModalVisible(true);
        }}
        underline={underline}
        sx={{ cursor: "pointer", color }}
        data-cy="delete-estimation"
      >
        Reset estimation
      </Link>
    </>
  );
}
