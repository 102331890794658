import { type ChangeEvent, type JSX, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { type ZendeskPlatformModel } from "@doitintl/cmp-models";
import { Box, MenuItem, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import uniq from "lodash/uniq";

import { supportPlatformDropdown } from "../../constants/cypressIds";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitledMultiple, useTier } from "../../Context/TierProvider";
import { type Asset } from "../../types";
import { entitlementsToContactSupport } from "../consts";
import { useGetCustomerContractAndAssetDetails } from "../hooks/useGetCustomerContractAndAssetDetails";
import { isDoitConsoleBaseService, platformValue } from "../utils";
import SupportPlatformMenuItemContent from "./SupportPlatformMenuItemContent";
import UnlockSupportOptionsDropdownBox from "./UnlockSupportOptionsDropdownBox";

type Props = {
  platform: string;
  error: boolean;
  platformsList: ZendeskPlatformModel[];
  handleChangePlatformField: (data: ChangeEvent) => void;
};

const SupportPlatformDropdown = ({ platform, error, platformsList, handleChangePlatformField }: Props) => {
  const { assets, isProductOnlyCustomer } = useCustomerContext();
  const { tiers } = useTier();
  const { isStandaloneAppWithoutContractOrAssets } = useGetCustomerContractAndAssetDetails();
  const { t } = useTranslation("services");
  const supportEntitlementsCheck = useIsFeatureEntitledMultiple(entitlementsToContactSupport);

  const [supportedPlatforms, setSupportedPlatforms] = useState<ZendeskPlatformModel[]>([]);
  const [alsoSupportedPlatforms, setAlsoSupportedPlatforms] = useState<ZendeskPlatformModel[]>([]);

  const isAllPlatformsEntitled = !!supportEntitlementsCheck["support:ticket:menu:platform:all"];

  const { headers, items } = supportPlatformDropdown.contents;

  useEffect(() => {
    const allAssets: Asset[] = Object.values(assets).flatMap((a) => a);
    const types: string[] = uniq(allAssets.map((asset) => platformValue(asset.data.type))).sort();
    const supportedZendeskPlatforms = platformsList.filter((p) => types.includes(platformValue(p.asset)));

    // Add DoiT Platform to support list, if it wasn't detected in the asset types.
    const doitPredicate = (p: ZendeskPlatformModel) => p.value === "cloud_management_platform";
    if (!supportedZendeskPlatforms.find(doitPredicate)) {
      const doitPlatform: ZendeskPlatformModel | undefined = platformsList.find(doitPredicate);
      doitPlatform && supportedZendeskPlatforms.unshift(doitPlatform);
    }

    if (!isProductOnlyCustomer) {
      // Add finance platform to supported list, if it wasn't detected in asset types.
      const hasFinancePredicate = (p: ZendeskPlatformModel) => p.value === "finance___billing";
      if (!supportedZendeskPlatforms.find(hasFinancePredicate)) {
        const financePlatform: ZendeskPlatformModel = platformsList.find(hasFinancePredicate) || {
          label: "Account, domain or invoice ID",
          title: "Invoices and Finance",
          order: 20,
          value: "finance___billing",
          helperText: "This will help us to address your request more efficiently",
          asset: "finance-billing",
        };
        supportedZendeskPlatforms.push(financePlatform);
      }

      // Add credits platform to supported list if it wasn't detected in asset types.
      const hasCreditsPredicate = (p: ZendeskPlatformModel) => p.value === "credits___request";
      if (!supportedZendeskPlatforms.find(hasCreditsPredicate)) {
        const creditsPlatform: ZendeskPlatformModel = platformsList.find(hasCreditsPredicate) || {
          label: "Request promotional credits",
          title: "Request promotional credits",
          order: 30,
          value: "credits___request",
          helperText: "",
          asset: "promotional-credits",
        };
        supportedZendeskPlatforms.push(creditsPlatform);
      }
    }
    setSupportedPlatforms(supportedZendeskPlatforms);

    const alsoSupportedZendeskPlatforms = platformsList.filter((p) => !supportedZendeskPlatforms.includes(p));
    setAlsoSupportedPlatforms(alsoSupportedZendeskPlatforms);
  }, [assets, isProductOnlyCustomer, platformsList]);

  const servicesSectionHeader = (
    <Box sx={{ ml: 2, height: "32px", pt: 0.5 }} data-cy={headers.customerServices}>
      <Typography variant="caption">{t("SupportPlatformDropdown.services")}</Typography>
    </Box>
  );
  const alsoSupportedSectionHeader = (
    <Box sx={{ ml: 2, height: "32px" }} data-cy={headers.alsoSupported}>
      <Typography variant="caption">We also support</Typography>
    </Box>
  );

  const makeMenuItem = useCallback<(p: ZendeskPlatformModel, customerService: boolean) => JSX.Element>(
    (p: ZendeskPlatformModel, customerService: boolean): JSX.Element => {
      const hasPlatformSupportEntitlement = (platform: string): boolean => {
        const allPlatform = `support:ticket:menu:platform:${platform}`;
        return isDoitConsoleBaseService(platform) || isAllPlatformsEntitled || supportEntitlementsCheck[allPlatform];
      };
      const featureUnlocked = hasPlatformSupportEntitlement(p.asset.replace("-project", ""));

      const baseMenuItemProps = {
        key: p.value,
        value: p.value,
        "data-cy": items[p.value],
        sx: { height: "40px" },
        "data-section": customerService ? "customer-services" : "also-supported",
      };

      const baseContentProps = {
        key: p.asset,
        zendPlatform: p,
        dataCyItems: items,
      };

      const disabledMenuItem = (disabledByEntitlement: boolean): JSX.Element => {
        const baseDisabledMenuItem = (
          <MenuItem {...baseMenuItemProps} sx={{ color: "text.disabled", pointerEvents: "none" }} aria-disabled>
            <SupportPlatformMenuItemContent {...baseContentProps} />
          </MenuItem>
        );

        if (disabledByEntitlement) {
          const CustomTooltipTitle = () => (
            <Typography sx={{ textAlign: "center", fontSize: "10px", fontWeight: 500, lineHeight: "14px" }}>
              Ask Ava, our AI-powered virtual assistant <br /> or upgrade to the Enhanced tier or above
            </Typography>
          );
          return (
            <Tooltip title={<CustomTooltipTitle />} key={p.value} sx={{ textAlign: "center" }}>
              <Box>{baseDisabledMenuItem}</Box>
            </Tooltip>
          );
        }

        return baseDisabledMenuItem;
      };
      const EnabledMenuItem: JSX.Element = (
        <MenuItem {...baseMenuItemProps}>
          <SupportPlatformMenuItemContent {...baseContentProps} />
        </MenuItem>
      );

      return !!tiers && (!featureUnlocked || isStandaloneAppWithoutContractOrAssets(p.asset))
        ? disabledMenuItem(!featureUnlocked)
        : EnabledMenuItem;
    },
    [isAllPlatformsEntitled, isStandaloneAppWithoutContractOrAssets, items, supportEntitlementsCheck, tiers]
  );

  return (
    <TextField
      select
      name="platform"
      label={t("SupportPlatformDropdown.label")}
      required
      helperText={t("SupportPlatformDropdown.helper")}
      variant="outlined"
      value={platform}
      onChange={handleChangePlatformField}
      error={error && !platform}
      sx={{ mb: 4 }}
      fullWidth
      size="medium"
      data-cy={supportPlatformDropdown.root}
    >
      {servicesSectionHeader}
      {supportedPlatforms.map((platform) => makeMenuItem(platform, true))}
      {!tiers && !!alsoSupportedPlatforms.length && alsoSupportedSectionHeader}
      {alsoSupportedPlatforms.map((platform) => makeMenuItem(platform, false))}
      {!!tiers && !isAllPlatformsEntitled && <UnlockSupportOptionsDropdownBox />}
    </TextField>
  );
};

export default SupportPlatformDropdown;
