import { createContext, type ReactNode, useContext } from "react";

import { useLocalStorage } from "../../Components/hooks/storageHooks";
import { type SavingsPeriod } from "./types";

type InsightsContext = {
  savingsPeriod: SavingsPeriod;
  setSavingsPeriod: (savingsPeriod: SavingsPeriod) => void;
  calculateSavingsForSelectedPeriod: (dailySavings: number) => number;
};

const InsightsContext = createContext({} as InsightsContext);

export function InsightsContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  const [savingsPeriod, setSavingsPeriod] = useLocalStorage<SavingsPeriod>("insights.savingsPeriod", "monthly");

  let calculateSavingsForSelectedPeriod: InsightsContext["calculateSavingsForSelectedPeriod"];

  switch (savingsPeriod) {
    case "yearly":
      calculateSavingsForSelectedPeriod = (savings) => savings * 365.25;
      break;
    case "monthly":
      calculateSavingsForSelectedPeriod = (savings) => savings * 30.4;
      break;
    case "daily":
      calculateSavingsForSelectedPeriod = (savings) => savings;
  }

  return (
    <InsightsContext.Provider value={{ savingsPeriod, setSavingsPeriod, calculateSavingsForSelectedPeriod }}>
      {children}
    </InsightsContext.Provider>
  );
}

export const useInsightsContext = () => useContext(InsightsContext);
