import { InternalTierNames } from "@doitintl/cmp-models";

import { type TierWithRef } from "../../../Context/TierProvider";

export function isPaidTier(tier: TierWithRef | undefined) {
  return tier && !tier.trialTier && tier.type !== "internal";
}

export function isHeritageTier(tier: TierWithRef | undefined) {
  return tier?.name.includes("last10");
}

export function isDCIEssentialsTier(tier: TierWithRef | undefined) {
  return tier?.name === InternalTierNames.ESSENTIALS;
}

/**
 * Check if the tier is a DCI tier
 * Running off the display name until such time as the SKUs are firmed up.
 * @param displayName
 */
export function isDci(displayName: string | undefined): boolean {
  return displayName?.includes("DCI") || false;
}
