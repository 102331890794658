import { useCallback, useMemo } from "react";

import { Link } from "react-router-dom";
import { Button } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";
import { customersRoute } from "../../Navigation/core/utils";
import { getOnboardingUrl } from "../../Pages/Subscription/utils";
import { updateStartTrialInitiated } from "./db";

type Props = { onClick?: () => void };

const StartFreeTrialButton = ({ onClick }: Props) => {
  const { customer, isProductOnlyCustomer } = useCustomerContext({ allowNull: true });

  const route = useMemo(() => {
    if (!customer) {
      return "";
    }

    if (isProductOnlyCustomer) {
      return customersRoute(customer.id, "start-trial");
    }

    return getOnboardingUrl(customer);
  }, [customer, isProductOnlyCustomer]);

  const handleClick = useCallback(async () => {
    if (!customer) {
      return;
    }

    if (!isProductOnlyCustomer) {
      await updateStartTrialInitiated(customer);
    }

    onClick?.();
  }, [isProductOnlyCustomer, customer, onClick]);

  return (
    <Button component={Link} onClick={handleClick} to={route} variant="contained">
      Start free trial
    </Button>
  );
};

export default StartFreeTrialButton;
