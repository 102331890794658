import {
  type AwsKnownIssueModel,
  type CustomerModelCloudServices,
  type GcpKnownIssueModel,
  KnownIssuePlatforms,
} from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { generateHighchartList3 } from "../../../cmpBaseColors";
import { type KnownIssue } from "../../../Pages/KnownIssues/types";
import { defaultResolutionInterval } from "./consts";
import {
  ClassificationEnum,
  type CustomerServicesWithId,
  type highchartsDataParams,
  type IntervalRecord,
  type ProductsFilterMultiSelectProductOption,
  type TimeIntervalName,
} from "./types";

// NOTE - if the interval is months, this may not calculate accurately
export const getIncidentFirstIntervalIndex = (
  incidentStartTime: number,
  firstIntervalStartTime: number,
  intervalLength: number
) => Math.floor((incidentStartTime - firstIntervalStartTime) / intervalLength);

export const getIncidentEndTime = (incident: KnownIssue): Date | undefined => {
  if (incident.status === "archived") {
    return (
      incident.incidentEndDate?.toDate() ??
      incident.nextUpdateTime?.toDate() ??
      new Date(DateTime.fromJSDate(incident.dateTime.toDate()).plus(defaultResolutionInterval).toJSDate())
    );
  }
  return undefined;
};

export const getIncidentClassifications = (incident: KnownIssue): ClassificationEnum => {
  if (incident.platform === KnownIssuePlatforms.GCP) {
    const gcpIncident = incident as ModelId<GcpKnownIssueModel>;
    if (["alert/3.0"].includes(gcpIncident.exposureLevel)) {
      return ClassificationEnum.FullDowntime;
    } else if (
      ["alert/0.0", "alert/1.0", "alert/2.0"].includes(gcpIncident.exposureLevel) &&
      !incident.title.includes("LIMITED_IMPACT")
    ) {
      return ClassificationEnum.PartialDowntime;
    }
  } else if (incident.platform === KnownIssuePlatforms.AWS) {
    return ClassificationEnum.FullDowntime;
  }

  return ClassificationEnum.Available;
};

export const computeAvailability = (interval: IntervalRecord) => {
  const intervalStartMillis = interval.startTime.toUTC().toMillis();
  const intervalEndTimeMillis = interval.endTime.toUTC().toMillis();
  const totalIntervalMillis = intervalEndTimeMillis - intervalStartMillis;

  let fullDownMillis = 0;
  let partialDownMillis = 0;

  interval.incidents.forEach((incident) => {
    // get incident duration
    const incidentStartMillis = DateTime.fromJSDate(incident.dateTime.toDate()).toUTC().toMillis();
    const incidentEndTime = getIncidentEndTime(incident);

    let incidentDuration = 0;
    if (incident.status === "ongoing") {
      // did downtime begin before this interval?
      if (incidentStartMillis < interval.startTime.toUTC().toMillis()) {
        incidentDuration = totalIntervalMillis;
      } else {
        incidentDuration = interval.endTime.toUTC().toMillis() - incidentStartMillis;
      }
    } else {
      // standard duration calculation
      let incidentEndTimeMillis = 0;

      // getIncidentEndTime() can return undefined if the incident is ongoing, so the linter must be appeased
      if (incidentEndTime) {
        incidentEndTimeMillis = DateTime.fromJSDate(incidentEndTime).toUTC().toMillis();
      }
      incidentDuration = incidentEndTimeMillis - incidentStartMillis;
    }

    // classify incident as "partialDown", "fullDown", or do nothing if no downtime.
    const classify = getIncidentClassifications(incident);
    if (classify === ClassificationEnum.FullDowntime) {
      fullDownMillis += incidentDuration;
    } else if (classify === ClassificationEnum.PartialDowntime) {
      partialDownMillis += incidentDuration;
    }
  });

  const fullDownPercent = (fullDownMillis / totalIntervalMillis) * 100;
  const partialDownPercent = (partialDownMillis / totalIntervalMillis) * 100;
  const availablePercent = 100 - (fullDownPercent + partialDownPercent); // - computeIncidentOverlap(interval));

  return { availablePercent, fullDownPercent, partialDownPercent };
};

export const getIncidentEndDate = (incident: KnownIssue) =>
  incident.nextUpdateTime?.toDate() ??
  (incident as ModelId<AwsKnownIssueModel>).lastUpdatedTime?.toDate() ??
  DateTime.fromJSDate(incident.dateTime.toDate()).toUTC().plus({ hours: 2 }).toJSDate();

const tooltipFormatIncident = (incident: KnownIssue) => {
  const incidentStartString =
    `<b>${incident.products?.join("<br/>") ?? incident.affectedProduct}:</b><br/>` +
    `<span>Impacted at ${DateTime.fromJSDate(incident.dateTime.toDate())
      .toUTC()
      .toFormat("hh:mm a")} on ${DateTime.fromJSDate(incident.dateTime.toDate())
      .toUTC()
      .toFormat("dd LLL yy")}</span><br/>`;

  const incidentEndString =
    incident.status === "ongoing"
      ? "<span>Ongoing</span>"
      : `<span>Resolved at ${DateTime.fromJSDate(getIncidentEndDate(incident))
          .toUTC()
          .toFormat("hh:mm a")} on ${DateTime.fromJSDate(getIncidentEndDate(incident))
          .toUTC()
          .toFormat("dd LLL yy")}</span><br/>`;

  return incidentStartString + incidentEndString;
};

const darkModeConditionalValue = (isDarkMode: boolean, ifTrue: string, ifFalse: string): string =>
  isDarkMode ? ifTrue : ifFalse;

export const getHighchartsOptions = (
  isDarkMode: boolean,
  data?: highchartsDataParams,
  timeInterval?: TimeIntervalName
) => {
  if (!data) {
    return null;
  }

  const hourTickInterval = data.availableSeries.length > 120 ? 6 : 3;

  return {
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      backgroundColor: darkModeConditionalValue(isDarkMode, "#353540", "white"),
      color: darkModeConditionalValue(isDarkMode, "white", ""),
      style: {},
      height: 310,
    },
    title: {
      // note: the component title will be a typography above the chart
      text: "",
      align: "left",
    },
    xAxis: [
      {
        categories: data.categoriesTitles.map((title) => title.formattedDate),
        tickInterval: timeInterval === "Hour" ? hourTickInterval : 1,
        lineWidth: 0,
        labels: {
          style: {
            color: darkModeConditionalValue(isDarkMode, "white", "black"),
          },
        },
      },
      {
        categories: data.categoriesTitles.map((title) => `<div style="min-width:40px">${title.formattedTime} </div>`),
        tickInterval: hourTickInterval,
        visible: timeInterval === "Hour",
        lineWidth: 0,
        offset: 15,
        labels: {
          useHTML: true,
          style: {
            color: darkModeConditionalValue(isDarkMode, "white", "black"),
          },
        },
      },
    ],
    yAxis: {
      min: 0,
      max: 100,
      lineColor: "red",
      gridLineColor: "transparent",
      lineWidth: 0,
      title: {
        text: "",
      },
      stackLabels: {
        enabled: false,
      },
      labels: {
        format: "{value}%",
        style: {
          color: darkModeConditionalValue(isDarkMode, "white", "black"),
        },
      },
    },
    legend: {
      floating: false,
      backgroundColor: "transparent", // isDarkMode ? "#353540" : "white", // Highcharts.defaultOptions.legend.backgroundColor ||
      shadow: false,
      itemStyle: {
        color: darkModeConditionalValue(isDarkMode, "white", "black"),
      },
    },
    tooltip: {
      zIndex: 9999,
      outside: true,
      followPointer: true,
      formatter(this: any) {
        const partialDown = data.partialDownSeries[this.point.index];
        const fullDown = data.fullDownSeries[this.point.index];
        const formattedPartial = partialDown.incidents.map((incident) => tooltipFormatIncident(incident));
        const formattedFull = fullDown.incidents.map((incident) => tooltipFormatIncident(incident));

        switch (this.point.series.index) {
          case 0:
            return "Available";
          case 1:
            return formattedPartial.join("<br/>");
          case 2:
            return formattedFull.join("<br/>");
        }
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        pointPadding: data.availableSeries.length < 7 ? 0.1 : 0.07,
        groupPadding: data.availableSeries.length < 7 ? 0.2 : 0,
        borderColor: darkModeConditionalValue(isDarkMode, "#353540", "white"),
        animation: {
          enabled: true,
          duration: 400,
        },
      },
    },
    series: [
      {
        name: "Available",
        xAxis: 1,
        data: data.availableSeries,
        color: generateHighchartList3(isDarkMode ? "dark" : "light")[8], // light: "#c8d148",
      },
      {
        name: "Partial downtime",
        data: data.partialDownSeries.map((rowItem) => rowItem.val),
        color: generateHighchartList3(isDarkMode ? "dark" : "light")[3], // light: "#ee7798",
      },
      {
        name: "Full downtime",
        data: data.fullDownSeries.map((rowItem) => rowItem.val),
        color: generateHighchartList3(isDarkMode ? "dark" : "light")[11], // light: "#931310",
      },
    ],
  };
};

export const compareProductsFilterMultiSelectProductOption = (
  a: ProductsFilterMultiSelectProductOption,
  b: ProductsFilterMultiSelectProductOption
): number => {
  if (a.platform !== b.platform) {
    return a.platform > b.platform ? 1 : -1;
  } else {
    return a.product >= b.product ? 1 : -1;
  }
};

const removePlatformName = (name: string): string => {
  const platforms = ["Google Cloud", "Cloud", "Amazon", "AWS"];
  const regex = new RegExp(platforms.join("|"), "gi");
  return name.replace(regex, "").trim();
};

const getCustomerServicesList = (
  customerServices: CustomerModelCloudServices[],
  platform: KnownIssuePlatforms,
  field: "serviceName" | "id"
) => customerServices.filter((s) => s.type === platform).map((service) => removePlatformName(service[field]));

export const isServiceUsedByCustomer = (
  incidents: KnownIssue[],
  customerServices: CustomerServicesWithId[]
): KnownIssue[] => {
  if (customerServices.length === 0) {
    return incidents;
  }

  return incidents.filter((incident) => {
    if (incident.platform === KnownIssuePlatforms.GCP) {
      return incident.products?.some((product) => {
        const formatProduct = removePlatformName(product);
        const list = getCustomerServicesList(customerServices, KnownIssuePlatforms.GoogleCloud, "serviceName");
        return !!findBestMatch(list, formatProduct);
      });
    }

    // aws
    const formatProduct = removePlatformName(incident.affectedProduct);
    const customerServicesNames = getCustomerServicesList(customerServices, KnownIssuePlatforms.AWS, "serviceName");
    const listIDs = getCustomerServicesList(customerServices, KnownIssuePlatforms.AWS, "id");

    return !!findBestMatch([...customerServicesNames, ...listIDs], formatProduct);
  });
};

function calculateSimilarity(str1: string, str2: string): number {
  const distance = levenshteinDistance(str1, str2);
  const maxLength = Math.max(str1.length, str2.length);
  return 1 - distance / maxLength;
}

function levenshteinDistance(str1: string, str2: string): number {
  const matrix: number[][] = [];
  const len1 = str1.length;
  const len2 = str2.length;

  // Initialize matrix
  for (let i = 0; i <= len1; i++) {
    matrix[i] = [i];
  }
  for (let j = 0; j <= len2; j++) {
    matrix[0][j] = j;
  }

  // Calculate Levenshtein distance
  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // deletion
        matrix[i][j - 1] + 1, // insertion
        matrix[i - 1][j - 1] + cost // substitution
      );
    }
  }

  return matrix[len1][len2];
}

function findBestMatch(list: string[], query: string): string | null {
  let bestMatch: string | null = null;
  let bestSimilarity = 0;

  for (const item of list) {
    const itemLower = item.toLowerCase();
    const queryLower = query.toLowerCase();
    const similarity = calculateSimilarity(itemLower, queryLower);
    if (itemLower.includes(queryLower) || queryLower.includes(itemLower)) {
      return item;
    }

    if (similarity > bestSimilarity) {
      bestSimilarity = similarity;
      bestMatch = item;
    }
  }

  if (bestSimilarity < 0.5) {
    return null;
  }

  return bestMatch;
}
