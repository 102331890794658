import { Card, Stack } from "@mui/material";

import { CardTextPlaceholder } from "./CardTextPlaceholder";

export const SavingCardsPlaceholder = () => (
  <Stack spacing={2} direction="row" width="100%">
    <Card sx={{ width: "100%", p: 5 }}>
      <CardTextPlaceholder title="Savings rate" />
    </Card>
    <Card sx={{ width: "100%", p: 5 }}>
      <CardTextPlaceholder title="Total savings" />
    </Card>
  </Stack>
);
