import { CustomerModel, CustomerNoteModel } from "@doitintl/cmp-models";
import { getBatch, getCollection, type ModelReference } from "@doitintl/models-firestore";

import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { type CustomerNote, type TagKeys } from "./types";
import { generateNotePayload } from "./utils";

export async function generateNotePayloadForCustomer(
  customerId: string,
  message: string,
  tags: TagKeys[],
  currentUser: FirebaseUserWithEmail
) {
  const customerDocRef = getCollection(CustomerModel).doc(customerId);
  const customerData = (await customerDocRef.get()).asModelData();
  if (!customerData) {
    throw new Error("Customer data could not be retrieved");
  }
  return generateNotePayload(
    {
      ...customerData,
      ref: customerDocRef,
      id: customerId,
    },
    message,
    tags,
    currentUser
  );
}

export async function addNote(notePayload: CustomerNoteModel) {
  return getCollection(CustomerNoteModel).add(notePayload);
}

export async function editNote(ref: ModelReference<CustomerNoteModel>, note: Omit<CustomerNote, "ref" | "id">) {
  const batch = getBatch();
  const noteDoc = getCollection(CustomerNoteModel).newDoc();
  batch.set(noteDoc, note);
  batch.update(ref, { display: false });
  await batch.commit();
}

export async function pinNote(note: CustomerNote) {
  await note.ref.update({ pinned: !note.pinned });
}

export async function deleteNote(note: CustomerNote) {
  await note.ref.delete();
}
