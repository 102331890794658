import { useEffect, useState } from "react";

import { AssetModel, type CreditType, type CurrencyCode, type EntityModel, ProductEnum } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, useCollectionData } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import { type DateTime } from "luxon";
import * as yup from "yup";

import { globalText } from "../../assets/texts";
import AssetsChipsSelect from "../../Components/AssetsChipsSelect";
import CustomerSelect from "../../Components/CustomerSelect";
import AutoLocaleDatePicker from "../../Components/DateRange/AutoLocaleDatePicker";
import EntitySelect from "../../Components/EntitySelect";
import { CircularProgressLoader } from "../../Components/Loader";
import LoadingButton from "../../Components/LoadingButton";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { type CreditDataType } from "../../Context/CreditsContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import { sanitizeDate } from "../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import { serverTimestamp } from "../../utils/firebase";
import { type CustomerData, useCustomer } from "./hooks";

const useStyles = makeStyles((theme) => ({
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
  },
  spacer: {
    flex: "1 1 100%",
  },
}));

type Props = {
  onClose: () => void;
  credit: CreditDataType | undefined | null;
  type: CreditType;
};

const googleScopePattern = /^services\/[A-F0-9-]+\/skus\/[A-F0-9-]+$/;

const creditSchema = yup.object().shape({
  name: yup.string().required(),
  entity: yup.object().required(),
  customer: yup.object().nullable().required(),
  assets: yup.array().nullable(),
  scope: yup.array(
    yup.string().matches(googleScopePattern, {
      message: `Scope does not match pattern: ${googleScopePattern}`,
      excludeEmptyString: true,
    })
  ),
  amount: yup.number().typeError("Must be a number").required().moreThan(0, "Must be at least 1"),
});

type Values = {
  name: string;
  amount: number;
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
  scope: string[];
  currency: CurrencyCode;
  customer: CustomerData | undefined | null;
  assets: ModelIdRef<AssetModel>[] | undefined;
  entity: ModelIdRef<EntityModel> | undefined | null;
};

const CreditFormDialog = ({ credit, type, onClose }: Props) => {
  const classes = useStyles();
  const { userModel } = useUserContext({ allowNull: false });
  const sharedSnackbar = useSnackbar();
  const { isMobile: matches } = useFullScreen();
  const { customer, setCustomer, entity, setEntity, entities } = useCustomer(
    credit?.data.customer.id,
    credit?.data.entity?.id,
    { includingDisabled: true }
  );

  const [allAssets, allAssetsLoading] = useCollectionData(
    customer?.ref
      ? getCollection(AssetModel).where("customer", "==", customer.ref).where("type", "==", type)
      : undefined,
    { refField: "ref", idField: "id" }
  );

  const [creditAssets, setCreditAssets] = useState<ModelIdRef<AssetModel>[]>();

  const isEdit = !!credit;

  const submitHandler = async (values: Values, { setFieldError, setSubmitting }: FormikHelpers<Values>) => {
    if (!values.customer || !values.entity || !values.startDate || !values.endDate) {
      return;
    }

    try {
      setSubmitting(true);
      const creditAssetsRefs = values.assets?.map((asset) => asset.ref) ?? null;

      const startDate = sanitizeDate(values.startDate);
      const endDate = sanitizeDate(values.endDate);

      const updateFields = {
        name: trim(values.name),
        amount: values.amount,
        entity: values.entity.ref,
        assets: creditAssetsRefs,
        currency: values.currency,
        endDate: endDate.toJSDate(),
        timestamp: serverTimestamp(),
        updatedBy: { name: userModel.displayName, email: userModel.email },
        metadata: {
          customer: values.customer
            ? {
                primaryDomain: values.customer.primaryDomain,
                name: values.customer.name,
                priorityId: values.entity.priorityId,
              }
            : undefined,
        },
        scope: type === ProductEnum.GoogleCloud ? values.scope : undefined,
        type,
      } as const;

      const message = credit ? "Credit updated successfully" : "Credit created successfully";
      if (credit) {
        if (endDate < credit.data.endDate) {
          setFieldError("endDate", "Must be later than the previous end date");
          return;
        }

        if (values.amount < credit.data.amount) {
          setFieldError("amount", "Must be greater than the previous amount");
          return;
        }

        await credit.ref.update(updateFields);
      } else {
        await values.customer.ref.collection("customerCredits").add({
          ...updateFields,
          startDate: startDate.toJSDate(),
          endDate: endDate.toJSDate(),
          depletionDate: null,
          utilization: {},
          customer: values.customer.ref,
        });
      }

      sharedSnackbar.onOpen({
        message,
        variant: "success",
        autoHideDuration: 3000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });

      onClose();
    } catch (error: any) {
      sharedSnackbar.onOpen({
        message: error.message ? error.message : "Credit creation failed",
        variant: "error",
        autoHideDuration: 10000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (!allAssets || !credit?.ref.id) {
      return;
    }

    const creditAssetsIds = credit?.data.assets?.map((asset) => asset.id) ?? [];

    setCreditAssets(allAssets.filter((asset) => creditAssetsIds.includes(asset.ref.id)) ?? []);
  }, [allAssets, credit?.data.assets, credit?.ref.id]);

  // prevent from rendering before data is loaded
  if (isEdit && (!customer || (credit?.data.entity?.id && !entity) || allAssetsLoading || !creditAssets)) {
    return <CircularProgressLoader />;
  }

  return (
    <Formik<Values>
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={creditSchema}
      onSubmit={submitHandler}
      initialValues={{
        customer,
        assets: creditAssets ?? [],
        scope: credit?.data.scope ?? [],
        entity,
        name: credit?.data.name ?? "",
        amount: credit?.data.amount ?? 0,
        currency: credit?.data.currency ?? "USD",
        startDate: credit ? credit.data.startDate : undefined,
        endDate: credit ? credit.data.endDate : undefined,
      }}
    >
      {({
        setFieldValue,
        resetForm,
        setFieldTouched,
        setSubmitting,
        errors,
        isSubmitting,
        isValid,
        values,
        handleChange,
        handleBlur,
        touched,
      }) => {
        const entityAssets = allAssets?.filter((asset) => entity?.ref.id === asset.entity?.id) ?? [];
        return (
          <Dialog
            open={true}
            aria-labelledby="credit-dialog-title"
            onClose={preventOnCloseWhile(isSubmitting, onClose)}
            fullScreen={matches}
            fullWidth
            maxWidth="lg"
          >
            <Form
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              className={classes.dialogForm}
            >
              <DialogTitle id="credit-dialog-title">{isEdit ? "Edit credit" : "Add credit"}</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <CustomerSelect
                      value={values.customer}
                      helperText={touched.customer && errors.customer ? "Required" : undefined}
                      error={Boolean(touched.customer && errors.customer)}
                      disabled={isEdit || isSubmitting}
                      setFieldValue={(field, customer) => {
                        setCustomer(customer);
                        return setFieldValue(field, customer);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <EntitySelect
                      options={entities}
                      setFieldValue={(field, entity) => {
                        setEntity(entity);
                        return setFieldValue(field, entity);
                      }}
                      value={values.entity}
                      TextFieldProps={{
                        variant: "outlined",
                        margin: "dense",
                        disabled: isSubmitting,
                        helperText: (touched.entity && errors.entity) || "Required",
                        error: touched.entity && Boolean(errors.entity),
                        InputLabelProps: {
                          shrink: true,
                        },
                        SelectProps: {
                          onBlur: handleBlur,
                          MenuProps: {
                            MenuListProps: {
                              dense: true,
                            },
                          },
                        },
                        fullWidth: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <AssetsChipsSelect
                      options={entityAssets}
                      value={values.assets ?? []}
                      setFieldValue={setFieldValue}
                      helperText={(touched.assets && errors.assets) || "Optional. Leave empty to include all assets"}
                      error={touched.assets && Boolean(errors.assets)}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  {type === ProductEnum.GoogleCloud && (
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        freeSolo
                        options={[]}
                        value={values.scope}
                        disabled={isSubmitting}
                        onChange={async (_event, value) => {
                          const scopesArr = value
                            .toString()
                            .split(/[\s,]+/)
                            .filter((scopeStr) => scopeStr !== "");
                          await setFieldValue("scope", scopesArr);
                          await setFieldTouched("scope", true);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip size="small" label={option} {...getTagProps({ index })} key={index} />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="dense"
                            label="Scope"
                            error={Boolean(touched.scope && errors.scope?.length)}
                            helperText={
                              (touched.scope &&
                                (typeof errors.scope === "string" ? errors.scope : errors.scope?.find((v) => !!v))) ||
                              "Optional. Leave empty to include all Google Cloud services"
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <TextField
                      name="name"
                      label="Name"
                      variant="outlined"
                      margin="dense"
                      helperText={(touched.name && errors.name) || "Credit name that will appear on invoice"}
                      error={touched.name && Boolean(errors.name)}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      name="amount"
                      label="Amount"
                      variant="outlined"
                      margin="dense"
                      helperText={(touched.amount && errors.amount) || "Credit total amount in USD"}
                      error={touched.amount && Boolean(errors.amount)}
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      InputProps={{
                        inputProps: {
                          step: "0.01",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutoLocaleDatePicker
                      label="Start Date"
                      TextFieldProps={{
                        name: "startDate",
                        fullWidth: true,
                        margin: "dense",
                        helperText: "Credit start date",
                        onBlur: () => setFieldTouched("startDate", true),
                        "data-cy": "start-date",
                      }}
                      value={values.startDate ?? null}
                      onChange={async (e) => {
                        await setFieldValue("startDate", e);
                      }}
                      disabled={isEdit || isSubmitting}
                      maxDate={values.endDate}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AutoLocaleDatePicker
                      label="End Date"
                      TextFieldProps={{
                        name: "endDate",
                        error: touched.endDate && Boolean(errors.endDate),
                        fullWidth: true,
                        margin: "dense",
                        helperText: (touched.endDate && errors.endDate) || "Credit end date (exclusive)",
                        onBlur: () => setFieldTouched("endDate", true),
                        "data-cy": "end-date",
                      }}
                      value={values.endDate ?? null}
                      onChange={async (e) => {
                        await setFieldValue("endDate", e);
                      }}
                      disabled={isSubmitting}
                      minDate={values.startDate}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <Divider />

              <DialogActions>
                {isEdit && isEmpty(credit.data.utilization) && (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={async () => {
                        if (isEdit && isEmpty(credit.data.utilization)) {
                          try {
                            setSubmitting(true);
                            await credit.ref.delete();
                            setSubmitting(false);
                            resetForm();
                            onClose();
                          } catch (error) {
                            consoleErrorWithSentry(error);
                          }
                        }
                      }}
                    >
                      DELETE
                    </Button>
                    <div className={classes.spacer} />
                  </>
                )}
                <Button color="primary" variant="text" onClick={onClose} disabled={isSubmitting}>
                  {globalText.CANCEL}
                </Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  mixpanelEventId="financials.credit.save"
                >
                  {globalText.SAVE}
                </LoadingButton>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CreditFormDialog;
