import { useMemo } from "react";

import { NavLink } from "react-router-dom";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import Hide from "../../../Components/HideChildren/Hide";
import { useAuthContext } from "../../../Context/AuthContext";
import { SecondaryMenuStyles } from "../../themes";
import { type CategoryContext } from "../../types";
import { OutsideOfTierTooltip } from "./OutsideOfTierTooltip";
import { PresentationModeTooltip } from "./PresentationModeTooltip";

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    textTransform: "initial",
    minWidth: 0,
    padding: theme.spacing(0, 2.5),
  },
  root: {
    opacity: 1,
    color: theme.palette.text.primary,
  },
  indicator: {
    backgroundColor: theme.palette.navigation.accent,
    boxShadow: `inset 0px -2px 0px ${theme.palette.navigation.accent}`,
  },
}));

type Props = {
  isMobile: boolean;
  isTrialMode: boolean;
  categoryContext?: CategoryContext;
  presentationModeActive: boolean;
};

const NavTabs = ({ isMobile, isTrialMode, categoryContext, presentationModeActive }: Props) => {
  const classes = useStyles();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  const selectedTab = useMemo(() => {
    const selectedItem = categoryContext?.items.find((item) => item.isSelected);
    return selectedItem?.pageId;
  }, [categoryContext]);

  const showOutsideOfTierBadge =
    !isTrialMode && !isMobile && isDoitEmployee && !(categoryContext?.isFeatureEntitled ?? true);

  if (isMobile && !selectedTab) {
    return null;
  }
  const tabsVariant = isMobile ? "scrollable" : "standard";

  return (
    <Box display="flex" justifyContent="end" alignItems="center" position="relative" px={2} sx={SecondaryMenuStyles}>
      <Stack spacing={1} direction="row" position="absolute" left={16} sx={{ zIndex: 1 }}>
        <Hide mdDown>{presentationModeActive && <PresentationModeTooltip />}</Hide>
        {showOutsideOfTierBadge && <OutsideOfTierTooltip />}
      </Stack>

      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <Tabs value={selectedTab} variant={tabsVariant} scrollButtons="auto">
          {categoryContext?.items?.map((item) => (
            <Tab
              className={classes.tab}
              classes={{ root: classes.root }}
              key={item.pageId}
              component={NavLink}
              label={item.displayName}
              value={item.pageId}
              to={item.fullRoute}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default NavTabs;
