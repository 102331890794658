import { type ReactNode } from "react";

import { Grid, Link } from "@mui/material";

import { ADDRESS_PANEL_ID } from "../panels/AddressPanel";
import { scrollToFormElement } from "../utils";

export const AnchorLink = ({ id, children }: { id: string; children?: ReactNode }) => (
  <Grid item xs={1} sm="auto" display="flex">
    <Link
      href={`#${id}`}
      variant="body2"
      onClick={(e) => {
        e.preventDefault();

        if (id === ADDRESS_PANEL_ID) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          scrollToFormElement(id);
        }
      }}
      sx={{
        textDecoration: "none",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Link>
  </Grid>
);
