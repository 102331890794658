import { useEffect, useRef } from "react";

import { type TypeOrUndefined } from "../firestore/types";

export type RefHook<T> = {
  current: T;
};

export const useComparatorRef = <T>(
  value: TypeOrUndefined<T>,
  isEqualMethod: (v1: TypeOrUndefined<T>, v2: TypeOrUndefined<T>) => boolean,
  onChange?: (value: TypeOrUndefined<T>) => void
): RefHook<TypeOrUndefined<T>> => {
  const ref = useRef(value);
  useEffect(() => {
    if (!isEqualMethod(value, ref.current)) {
      ref.current = value;
      if (onChange) {
        onChange(value);
      }
    }
  });
  return ref;
};
