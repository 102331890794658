import React, { useState } from "react";

import { Button, Stack, TextField, Typography } from "@mui/material";

type Props = {
  onSubmit: (value: string) => void;
  disabled: boolean;
};

export const InputFeedback = ({ onSubmit, disabled }: Props) => {
  const [text, setText] = useState("");

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" spacing={1} pt={1.5}>
        <TextField
          multiline
          size="small"
          label="Additional feedback (optional)"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
          sx={{ width: 500, color: disabled ? "text.disabled" : "" }}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        <Button
          size="small"
          disabled={disabled}
          onClick={() => {
            onSubmit(text);
          }}
        >
          Send feedback
        </Button>
      </Stack>
      <Typography variant="caption" color={disabled ? "text.disabled" : "text.secondary"} pt={0.5}>
        This will also send your recent conversations to help us improve our chat experience
      </Typography>
    </Stack>
  );
};
