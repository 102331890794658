import { useCallback, useMemo } from "react";

import { DateTime } from "luxon";

import { Loader } from "../../../../Components/Loader";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useSaaSGCPConsoleOnboarding } from "../hooks";
import { BillingPermissions } from "./BillingPermissions";
import { BillingAccountIdRegex, BillingAccountIdRegexError } from "./consts";

type Props = {
  billingAccountId: string;
  projectId: string;
  datasetId: string;
  enabledDetailedCost: boolean;
  setBillingAccountId: (billingAccountId: string) => void;
  setProjectId: (projectId: string) => void;
  setDatasetId: (datasetId: string) => void;
  setEnabledDetailedCost: (enabledDetailedCost: boolean) => void;
  handleBack: () => void;
  setBillingAccountValid: (valid: boolean) => void;
};

const SaaSOnboardingGCPContent = ({
  billingAccountId,
  projectId,
  datasetId,
  enabledDetailedCost,
  setBillingAccountId,
  setProjectId,
  setDatasetId,
  setEnabledDetailedCost,
  handleBack,
  setBillingAccountValid,
}: Props) => {
  const [onboarding, loading] = useSaaSGCPConsoleOnboarding();
  const showErrorSnackbar = useErrorSnackbar();
  const hasInitError = Boolean(onboarding?.accounts?.[billingAccountId]?.errors?.initOnboarding);

  if (hasInitError) {
    showErrorSnackbar("Failed to initialize. Please try again later.");
    handleBack();
  }

  const billingAccountIdError = useCallback(
    (billingAccountId: string): string => {
      let errorText = "";

      if (!billingAccountId) {
        setBillingAccountValid(false);
        return "";
      }

      if (!BillingAccountIdRegex.test(billingAccountId)) {
        errorText = BillingAccountIdRegexError.InvalidFormat;
      } else if (onboarding?.accounts) {
        if (
          Object.values(onboarding?.accounts)?.findIndex(
            (account) => account.completed && account.billingAccountId === billingAccountId
          ) !== -1
        ) {
          errorText = BillingAccountIdRegexError.AlreadyExists;
        }
      }

      if (errorText === "") {
        setBillingAccountValid(true);
      } else {
        setBillingAccountValid(false);
      }

      return errorText;
    },
    [onboarding, setBillingAccountValid]
  );

  const error = useMemo(() => {
    const lastUpdated = onboarding?.accounts?.[billingAccountId]?.lastUpdated;
    if (
      lastUpdated !== undefined &&
      DateTime.now().diff(DateTime.fromJSDate(lastUpdated.toDate()), "minutes").minutes < 30
    ) {
      return onboarding?.accounts?.[billingAccountId]?.errors?.activation?.type;
    }
    return undefined;
  }, [billingAccountId, onboarding?.accounts]);

  return (
    <Loader loading={loading}>
      <BillingPermissions
        error={error}
        serviceAccount={onboarding?.serviceAccountEmail}
        billingAccountId={billingAccountId}
        projectId={projectId}
        datasetId={datasetId}
        enabledDetailedCost={enabledDetailedCost}
        setBillingAccountId={setBillingAccountId}
        setProjectId={setProjectId}
        setDatasetId={setDatasetId}
        setEnabledDetailedCost={setEnabledDetailedCost}
        billingAccountIdError={billingAccountIdError}
      />
    </Loader>
  );
};

export default SaaSOnboardingGCPContent;
