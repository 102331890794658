import { type RouteConfig } from "react-router-config";

import { type TierConfig } from "../../Context/TierProvider";
import {
  type AccessControlConfig,
  type CustomerAccessCheckFunc,
  type CustomerAccessControlDefinition,
  type CustomerAccessControlRoute,
  type DoitAccessControlDefinition,
  type DoitAccessControlRoute,
  type DoitRoleAccessCheckFunc,
  type LoginContext,
  type PageRoute,
  type PageRouteWithAccess,
  type StaticAccessControlDefinition,
  type StaticAccessControlRoute,
} from "../types";
import { customerRoutePrefix } from "./consts";

function createRouteConfig(
  definition: CustomerAccessControlRoute | DoitAccessControlRoute | StaticAccessControlRoute,
  withCustomerPrefix = false
): RouteConfig {
  return {
    key: definition.key,
    pageId: definition.pageId,
    path: withCustomerPrefix ? `${customerRoutePrefix}${definition.route}` : definition.route,
    isCustomerRoute: withCustomerPrefix,
    component: definition.component,
    exact: true,
    featureGate: "featureGate" in definition ? definition.featureGate : undefined,
    hasEntitlement: "hasEntitlement" in definition ? definition.hasEntitlement : null,
    presentationModeSupported: "presentationModeSupported" in definition ? definition.presentationModeSupported : null,
  };
}

const checkCustomerRouteAccess = (loginContext: LoginContext, accessFunc: CustomerAccessCheckFunc | undefined) => {
  if (loginContext.customerState && loginContext.permissions) {
    if (!accessFunc) {
      return true;
    }

    return accessFunc(loginContext.permissions, loginContext.customerState);
  }

  return false;
};

function checkDoerRouteAccess(loginContext: LoginContext, definition: DoitAccessControlDefinition): boolean {
  if (loginContext.isDoitEmployee) {
    const doitRoleCheck = definition.allowAccess?.(loginContext.permissions) ?? true;
    const doitOwnerCheck = !definition.requiresDoitOwner || loginContext.isDoitOwner;
    return doitRoleCheck && doitOwnerCheck;
  }

  if (loginContext.isDoitPartner) {
    return Boolean(definition.allowAccessToDoitPartners);
  }

  return false;
}

const checkDoerSubRouteAccess = (loginContext: LoginContext, accessFunc?: DoitRoleAccessCheckFunc) => {
  if (loginContext.permissions) {
    return accessFunc?.(loginContext.permissions) ?? true;
  }

  return false;
};

function createNoAccessRouteConfig(route: string, isCustomerRoute = false): RouteConfig {
  return {
    path: isCustomerRoute ? `${customerRoutePrefix}${route}` : route,
    exact: true,
    isCustomerRoute,
  };
}

// generate list of routes without considering the access rules (used for no access check)
function generateNoAccessRoutes(
  definition: CustomerAccessControlDefinition | DoitAccessControlDefinition,
  isCustomerRoute = false
): RouteConfig[] {
  if ("routes" in definition) {
    return definition.routes.map((subPageRoute: PageRoute) =>
      createNoAccessRouteConfig(subPageRoute.route, isCustomerRoute)
    );
  }

  return [createNoAccessRouteConfig(definition.route, isCustomerRoute)];
}

// generate static route config depending on the context of the user
function generateStaticConfigRoute(loginContext: LoginContext, definition: StaticAccessControlDefinition): RouteConfig {
  return createRouteConfig(definition, !loginContext.isDoitEmployee);
}

// Generate doer routes
function generateDoerConfigRoutes(loginContext: LoginContext, definition: DoitAccessControlDefinition): RouteConfig[] {
  const hasAccess = checkDoerRouteAccess(loginContext, definition);
  if (!hasAccess) {
    return [];
  }

  if ("routes" in definition) {
    return definition.routes.flatMap((subPageRoute) => {
      const hasSubRouteAccess = checkDoerSubRouteAccess(loginContext, subPageRoute.allowAccess);
      if (!hasSubRouteAccess) {
        return [];
      }

      const subRouteDefinition: CustomerAccessControlDefinition = {
        component: definition.component,
        key: definition.key,
        route: subPageRoute.route,
        pageId: subPageRoute.pageId,
      };
      return createRouteConfig(subRouteDefinition, false);
    });
  }

  return [createRouteConfig(definition, false)];
}

// Generate customer routes that the user can access
function generateCustomerConfigRoutes(
  loginContext: LoginContext,
  definition: CustomerAccessControlDefinition,
  tierConfig: TierConfig
): RouteConfig[] {
  const hasAccess = checkCustomerRouteAccess(loginContext, definition.allowAccess);
  if (!hasAccess) {
    return [];
  }

  definition.hasEntitlement = definition.featureGate
    ? tierConfig.isFeatureEntitled(definition.featureGate, definition.isFeatureGateDisabledForDoer)
    : null;

  if ("routes" in definition) {
    return definition.routes.flatMap((subPageRouteAccess: PageRouteWithAccess) => {
      const hasSubRouteAccess = checkCustomerRouteAccess(loginContext, subPageRouteAccess.allowAccess);
      if (!hasSubRouteAccess) {
        return [];
      }

      const subRouteDefinition: CustomerAccessControlDefinition = {
        component: definition.component,
        key: definition.key,
        route: subPageRouteAccess.route,
        pageId: subPageRouteAccess.pageId,
        featureGate: subPageRouteAccess.featureGate,
        hasEntitlement: subPageRouteAccess.featureGate
          ? tierConfig.isFeatureEntitled(
              subPageRouteAccess.featureGate,
              subPageRouteAccess.isFeatureGateDisabledForDoer
            )
          : null,
        presentationModeSupported: subPageRouteAccess.presentationModeSupported,
      };

      return createRouteConfig(subRouteDefinition, true);
    });
  }

  return [createRouteConfig(definition, true)];
}

// Use the login information to decide what routes are available for access for current user
export function getAllowedRoutesForAccess(
  loginContext: LoginContext,
  accessControlConfig: AccessControlConfig,
  tierConfig: TierConfig
): RouteConfig[] {
  const doerRoutes = accessControlConfig.doers.flatMap((definition) =>
    generateDoerConfigRoutes(loginContext, definition)
  );

  const customerRoutes = accessControlConfig.customer.flatMap((definition) =>
    generateCustomerConfigRoutes(loginContext, definition, tierConfig)
  );

  const staticRoutes = accessControlConfig.static.map((definition) =>
    generateStaticConfigRoute(loginContext, definition)
  );

  return [...doerRoutes, ...customerRoutes, ...staticRoutes];
}

// get all routes from access control config without checking access control
export function getAllRoutes(accessControlConfig: AccessControlConfig): RouteConfig[] {
  const doerRoutes = accessControlConfig.doers.flatMap((definition) => generateNoAccessRoutes(definition));

  const customerRoutes = accessControlConfig.customer.flatMap((definition) => generateNoAccessRoutes(definition, true));

  return [...doerRoutes, ...customerRoutes];
}
