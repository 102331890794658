import { type ReactNode } from "react";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const VERTICAL = "vertical";
const HORIZONTAL = "horizontal";

const useStyles = makeStyles(() => ({
  base: {
    margin: 0,
    "& dd": {
      marginInlineStart: 0,
    },
  },
  [HORIZONTAL]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& dd": {
      marginLeft: 8,
      marginRight: 32,
    },
  },
  [VERTICAL]: {
    display: "grid",
    gridTemplateColumns: "fit-content(0) auto",
    gridGap: 8,
  },
}));

type DefinitionListProps = {
  variant?: typeof HORIZONTAL | typeof VERTICAL;
  children: ReactNode;
};

export const DefinitionList = ({ children, variant = VERTICAL }: DefinitionListProps) => {
  const classes = useStyles();
  return <dl className={clsx(classes.base, classes[variant])}>{children}</dl>;
};

export const DefinitionListTerm = ({ children }: { children: ReactNode }) => (
  <dt aria-label={children?.toString()}>
    <Typography color="text.primary" variant="body2" fontWeight={500} sx={{ whiteSpace: "nowrap" }}>
      {children}
    </Typography>
  </dt>
);

export const DefinitionListDesc = ({ children, color = "text.primary" }: { children: ReactNode; color?: string }) => (
  <dd>
    <Typography color={color} variant="body2">
      {children}
    </Typography>
  </dd>
);
