import { SaaSConsoleType } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { isSaaSConsoleByType } from "../../../utils/common";
import { ServiceAccountsPage } from "./ServiceAccountsPage";
import { useStandaloneAccounts } from "./Standalone/hooks";
import { StandaloneAccounts } from "./Standalone/StandaloneAccounts";

/**
 * Entry point for showing settings for resold customer, it can contain regular accounts or the standalone
 */
export const GCPAccountsResold = () => {
  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const [standaloneAccounts] = useStandaloneAccounts();

  const isGCPSaaSConsoleEnabled = isSaaSConsoleByType(customer, isProductOnlyCustomer, SaaSConsoleType.GCP, true);

  if (!isGCPSaaSConsoleEnabled && standaloneAccounts && standaloneAccounts.length > 0) {
    return <StandaloneAccounts accounts={standaloneAccounts} />;
  }

  return <ServiceAccountsPage />;
};
