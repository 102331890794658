import { type JSX, useMemo, useState } from "react";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { type EntityModel, type GooglePartnerSupportLevel } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import useRouteMatchURL from "../../../../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu } from "../../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { type Asset, type Contract, type GoogleCloudAssetsType } from "../../../../../types";
import { buildBillingProfileString } from "../../../assetUtils";
import { RemoveDialog } from "../../../Cards/components/RemoveDialog";
import { linkLabelWithIcon, NotApplicable, NotAvailable } from "../../../Tabs/components/typographics";
import { contractSupportMap } from "../../utils";

export type GcpPartnerAssetRowData = {
  id: string;
  asset: Asset<GoogleCloudAssetsType>;
  displayName?: string;
  billingAccountId: string;
  billingProfileText: string | JSX.Element;
  admins: number;
  numProjects: number;
  assetTag: string;
  partnerSupportLevel?: GooglePartnerSupportLevel;
  plpsPercent?: number;
};

type Props = {
  row: GcpPartnerAssetRowData;
  onTransferProjectsAction: (assetId: string) => void;
  onRemoveAsset: (asset) => () => void;
};

/**
 * Builds partner asset row data from asset and entities map.
 *
 * @param asset asset to which the row relates
 * @param entities map of entities, keyed by entity ID.
 * @param contractsByAssetId Map of contracts, keyed by asset ID.
 */
export const populateRowFromAsset = (
  asset: Asset<GoogleCloudAssetsType>,
  entities: Record<string, EntityModel>,
  contractsByAssetId: Record<string, Contract | undefined>
): GcpPartnerAssetRowData => {
  const { billingAccountId } = asset.data.properties;
  const entityModel = asset.data.entity?.id ? entities[asset.data.entity.id] : null;

  const numProjects = "numProjects" in asset.data.properties ? asset.data.properties.numProjects : 0;
  const displayName = "displayName" in asset.data.properties ? asset.data.properties.displayName : undefined;
  const admins = "admins" in asset.data.properties ? asset.data.properties.admins : [];
  const assetTag = asset.data.tags?.length ? asset.data.tags[0] : NotAvailable;

  let partnerSupportLevel: GooglePartnerSupportLevel | undefined;
  let plpsPercent: number | undefined;

  const contract = contractsByAssetId[asset.id];

  if (contract) {
    const { type: contractType } = contract;
    partnerSupportLevel = contractSupportMap[contractType];
    plpsPercent = contract?.plpsPercent;
  }

  return {
    id: asset.id,
    asset,
    displayName,
    billingAccountId,
    billingProfileText: (entityModel && buildBillingProfileString(entityModel)) || NotAvailable,
    admins: admins?.length ?? 0,
    numProjects,
    assetTag,
    partnerSupportLevel,
    plpsPercent,
  };
};

export const GcAssetRowPartner = (props: Props) => {
  const { row, onTransferProjectsAction, onRemoveAsset } = props;
  const { id, displayName, billingAccountId, billingProfileText, admins, partnerSupportLevel, numProjects, assetTag } =
    row;

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const url = useRouteMatchURL();
  const history = useHistory();
  const { isDoitOwner } = useAuthContext();
  const [ackAssetRemoveDialog, setAckAssetRemoveDialog] = useState<boolean>(false);

  const plpsPercent = useMemo<string>(
    () => (row?.plpsPercent !== undefined ? `${row.plpsPercent.toString()}%` : NotApplicable), // not checking for mere truthiness, as 0 is a valid value
    [row.plpsPercent]
  );

  const nameLink = displayName ? (
    <Typography component={RouterLink} to={`${url}/${id}`} color="inherit" variant="body2">
      {displayName}
    </Typography>
  ) : (
    NotApplicable
  );

  const transferThisAsset = () => {
    onTransferProjectsAction(id);
  };

  const editAssetAction = () => {
    history.push(`${url}/${id}`);
  };

  const manageBALinkAction = () =>
    row.billingAccountId && window.open(`https://console.cloud.google.com/billing/${billingAccountId}`);

  const menuOptions = [
    {
      key: `gcp-partner-edit-asset-${id}`,
      label: <Typography variant="body1">Edit asset</Typography>,
      action: editAssetAction,
    },
    {
      key: `gcp-partner-transfer-project-${id}`,
      label: <Typography variant="body1">Transfer projects</Typography>,
      action: transferThisAsset,
    },
    {
      key: `gcp-partner-manage-billing-account-${id}`,
      label: linkLabelWithIcon("Manage billing account"),
      action: manageBALinkAction,
    },
  ];
  isDoitOwner &&
    menuOptions.push({
      key: `gcp-partner-manage-remove-asset-${id}`,
      label: <Typography color="error">Remove asset</Typography>,
      action: () => {
        setAckAssetRemoveDialog(true);
      },
    });
  const menuCell = <ThreeDotsMenu data-cy="threeDotMenu" size="small" options={menuOptions} />;

  return (
    <>
      {isDoitOwner && ackAssetRemoveDialog && (
        <RemoveDialog
          open={ackAssetRemoveDialog}
          onCancel={() => {
            setAckAssetRemoveDialog(false);
          }}
          onAccept={() => {
            onRemoveAsset(row)();
            setAckAssetRemoveDialog(false);
          }}
          name={displayName ?? ""}
        />
      )}
      <CellsWrapper>
        <TableCell data-cy="gcAssetPartnerCell-nameLink">
          <>
            {nameLink}
            {partnerSupportLevel && (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography color="textSecondary" noWrap={mdUp} variant="caption">
                  {partnerSupportLevel}
                </Typography>
              </Box>
            )}
          </>
        </TableCell>
        <TableCell data-cy="gcAssetPartnerCell-billingAccountId">{billingAccountId}</TableCell>
        <Hidden smDown>
          <TableCell data-cy="gcAssetPartnerCell-billingProfileText">{billingProfileText}</TableCell>
        </Hidden>
        <Hidden smDown>
          <TableCell data-cy="gcAssetPartnerCell-admins">{admins}</TableCell>
        </Hidden>
        <Hidden mdDown>
          <TableCell data-cy="gcAssetPartnerCell-plpsPercent">{plpsPercent}</TableCell>
        </Hidden>
        <Hidden mdDown>
          <TableCell data-cy="gcAssetPartnerCell-numProjects">{numProjects}</TableCell>
        </Hidden>
        <Hidden mdDown>
          <TableCell data-cy="gcAssetPartnerCell-assetTag">{assetTag}</TableCell>
        </Hidden>
        <TableCell padding="checkbox" data-cy="gcAssetPartnerCell-3dot">
          {menuCell}
        </TableCell>
      </CellsWrapper>
    </>
  );
};
