import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

@collection("labels")
export class Label {
  name!: string;

  color!: string;

  timeModified!: Timestamp;

  timeCreated!: Timestamp;

  createdBy!: string;

  customer!: Reference<CustomerModel>;

  objects?: Reference<never>[];
}
