import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { type SelectChangeEvent } from "@mui/material/Select";

import { CustomerNoteTagsOptions } from "./constants";
import { type CustomerNote, type TagKeys } from "./types";
import { getNoteLabelByKey, getNoteTagKeys } from "./utils";

type Props = {
  editMode?: boolean;
  defaultTag?: TagKeys;
  selectedNote?: CustomerNote;
  handleClose: () => void;
  open: boolean;
  handleSaveNote: (note: string, tag: TagKeys[]) => void;
};

export const NoteDialog = ({ editMode, defaultTag, open, selectedNote, handleClose, handleSaveNote }: Props) => {
  const [selectedTags, setSelectedTags] = useState<TagKeys[]>(() => {
    if (editMode && selectedNote) {
      return getNoteTagKeys(selectedNote);
    } else if (defaultTag) {
      return [defaultTag];
    }
    return [];
  });

  const [note, setNote] = useState(editMode ? (selectedNote?.body ?? "") : "");

  const handleChange = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;
    if (typeof value !== "string") {
      setSelectedTags(value);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{editMode ? "Edit note" : "Add note"}</DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: "100%", mt: 1 }} required>
          <InputLabel id="select-tag-label">Tags</InputLabel>
          <Select
            id="select-tag"
            multiple
            value={selectedTags}
            onChange={handleChange}
            input={<OutlinedInput label="Tags" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={getNoteLabelByKey(value)}
                    onDelete={() => {
                      setSelectedTags(selectedTags.filter((item) => item !== value));
                    }}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                      />
                    }
                    data-cy={`note-tag-${value}`}
                  />
                ))}
              </Box>
            )}
            data-cy="note-tags"
          >
            {CustomerNoteTagsOptions.slice(0, 5).map((tag) => (
              <MenuItem key={tag.key} value={tag.key} data-cy={tag.key}>
                {tag.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          name="note"
          variant="outlined"
          margin="normal"
          label="Note"
          onChange={(e) => {
            setNote(e.target.value);
          }}
          value={note}
          fullWidth
          required
          multiline
          rows={8}
          sx={{ mt: 4 }}
          data-cy="note-input"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} data-cy="cancel-button">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleSaveNote(note, selectedTags);
          }}
          disabled={selectedTags.length === 0 || !note}
          data-cy="dialog-add-note-button"
        >
          {editMode ? "Edit note" : "Add note"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
