import Grid from "@mui/material/Grid";

import { KeyContact } from "../../../Components/KeyContact/KeyContact";
import SectionHeader from "../SectionHeader";
import { useKeyContacts } from "./useKeyContacts";

export const KeyContactsSection = () => {
  const keyContacts = useKeyContacts();

  if (!keyContacts.length) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionHeader text="Key contacts" />
      </Grid>
      {keyContacts.map((keyContactDetails, idx) => (
        <Grid item xs={12} sm={6} md={4} key={keyContactDetails?.email ?? idx}>
          <KeyContact details={keyContactDetails} sx={{ height: "100%" }} />
        </Grid>
      ))}
    </Grid>
  );
};
