import { LinearProgress, Tooltip } from "@mui/material";
import { amber, green, grey, red } from "@mui/material/colors";
import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { useFullScreen } from "../../../utils/dialog";

const useStyles = makeStyles((theme) => ({
  progressCell: {
    minWidth: 100,
  },
  linearProgressRoot: {
    height: 16,
    borderRadius: theme.spacing(1),
  },
  linearProgressBar: {
    borderRadius: 20,
  },
  greyProgressRoot: {
    backgroundColor: lighten(grey[500], 0.5),
  },
  greyProgressBar: {
    backgroundColor: grey[500],
  },
  greenProgressRoot: {
    backgroundColor: lighten(green[500], 0.5),
  },
  greenProgressBar: {
    backgroundColor: green[500],
  },
  amberProgressRoot: {
    backgroundColor: lighten(amber[500], 0.5),
  },
  amberProgressBar: {
    backgroundColor: amber[500],
  },
  redProgressRoot: {
    backgroundColor: lighten(red[500], 0.5),
  },
  redProgressBar: {
    backgroundColor: red[500],
  },
}));

const RampProgressBar = (props: { onClick: () => void; progressVal: number; onTrack: boolean }) => {
  const { progressVal, onTrack, onClick } = props;
  const { isMobile: smDown } = useFullScreen();
  const classes = useStyles({ smDown });

  return (
    <Tooltip title="View ramp plans">
      <LinearProgress
        onClick={onClick}
        color={onTrack ? "primary" : "secondary"}
        value={progressVal}
        variant="determinate"
        style={{ cursor: "pointer" }}
        classes={{
          root: clsx(classes.linearProgressRoot, {
            [classes.greenProgressRoot]: onTrack,
            [classes.redProgressRoot]: !onTrack,
          }),
          bar: clsx(classes.linearProgressBar, {
            [classes.greenProgressBar]: onTrack,
            [classes.redProgressBar]: !onTrack,
          }),
        }}
      />
    </Tooltip>
  );
};

export default RampProgressBar;
