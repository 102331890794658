import { type ReactElement } from "react";

import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

type Props = {
  backTo: string;
};

export const AssetTypeTitle = ({ backTo }: Props): ReactElement => {
  const theme = useTheme();
  return (
    <Stack direction="row" sx={{ my: 1 }} data-testid="gc-assets-headline">
      <IconButton aria-label="Back to assets list" component={Link} to={backTo}>
        <ArrowBackIcon sx={{ color: theme.palette.text.primary }} />
      </IconButton>
      <Typography variant="h3" data-testid="gc-asset-title" sx={{ pt: 1, fontWeight: "fontWeightMedium" }}>
        Google Cloud asset
      </Typography>
    </Stack>
  );
};
