import { type AssetType, type ReportConfig, type TemplateVisibility } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

import { httpMethods } from "../../../assets/texts";

export type TemplateApprovalPayload = {
  categories: string[];
  cloud: AssetType[];
  config: ReportConfig;
  description: string;
  name: string;
  visibility: TemplateVisibility;
};

type TemplateRejectPayload = {
  comment: string;
};

export const submitTemplateForApproval = (api: AxiosInstance, request: TemplateApprovalPayload) =>
  api.request({
    method: httpMethods.POST,
    url: "/v1/analytics/template-library/report-templates",
    data: request,
  });

export const updateReportTemplate = (api: AxiosInstance, id: string, request: TemplateApprovalPayload) =>
  api.request({
    method: httpMethods.PUT,
    url: `/v1/analytics/template-library/report-templates/${id}`,
    data: request,
  });

export const approveReportTemplate = (api: AxiosInstance, id: string) =>
  api.request({
    method: httpMethods.PUT,
    url: `/v1/analytics/template-library/report-templates/${id}/approve`,
  });

export const rejectReportTemplate = (api: AxiosInstance, id: string, request: TemplateRejectPayload) =>
  api.request({
    method: httpMethods.PUT,
    url: `/v1/analytics/template-library/report-templates/${id}/reject`,
    data: request,
  });

export const deleteReportTemplate = (api: AxiosInstance, id: string) =>
  api.request({
    method: httpMethods.DELETE,
    url: `/v1/analytics/template-library/report-templates/${id}`,
  });

export const fetchReportTemplatesData = async (api: AxiosInstance) =>
  api.request({
    method: httpMethods.POST,
    url: `/v1/analytics/template-library/report-templates/template-data`,
  });
