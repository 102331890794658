import { useCallback, useMemo } from "react";

import {
  ContractModel,
  CustomerModel,
  EntityModel,
  type FlexsaveStandAloneOnBoardingAWSModel,
  type FlexsaveStandaloneOnboardingGCPModel,
  type FlexsaveType,
  FlexsaveTypes,
  IntegrationModel,
  PaymentMethodType,
  type StandaloneOnboardingAWS,
} from "@doitintl/cmp-models";
import {
  getCollection,
  type TransformMethod,
  useCollectionData,
  useDocumentData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import sortBy from "lodash/sortBy";

import { useApiContext } from "../../../api/context";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { type BillingProfile, StandaloneType } from "../types";
import { getFlexsaveTypeFullName } from "../utils/typeUtils";
import { StandaloneApi } from "./api";

function useStandaloneOnboarding(type: FlexsaveType) {
  const customerId = useCustomerId();

  return useMemo(
    () =>
      getCollection(IntegrationModel)
        .doc("flexsave-standalone")
        .collection("fs-onboarding")
        .doc(`${getFlexsaveTypeFullName(type)}-${customerId}`),
    [customerId, type]
  );
}

export function useStandaloneGCPOnboarding() {
  const docRef = useStandaloneOnboarding(FlexsaveTypes.GCP).narrow<FlexsaveStandaloneOnboardingGCPModel>();

  return useDocumentData(docRef);
}

export function useAWSStandaloneOnboarding(filterCompletedAccounts: boolean) {
  const customerId = useCustomerId();
  const docRef = useMemo(
    () =>
      getCollection(IntegrationModel)
        .doc("flexsave-standalone")
        .collection("fs-onboarding")
        .doc(`${getFlexsaveTypeFullName(FlexsaveTypes.AWS)}-${customerId}`)
        .narrow<FlexsaveStandAloneOnBoardingAWSModel>(),
    [customerId]
  );

  const transform = useCallback(
    (data: WithFirebaseModel<FlexsaveStandAloneOnBoardingAWSModel>): StandaloneOnboardingAWS[] => {
      if (data.accounts) {
        const accounts = sortBy(Object.values(data.accounts), "accountId");

        if (filterCompletedAccounts) {
          return accounts.filter((account) => !account.completed);
        }
        return accounts;
      }

      return [];
    },
    [filterCompletedAccounts]
  );

  return useDocumentData(docRef, {
    transform,
  });
}

export function useBillingProfiles(customerId: string) {
  const query = useMemo(() => {
    const customerRef = getCollection(CustomerModel).doc(customerId);

    return getCollection(EntityModel).where("customer", "==", customerRef);
  }, [customerId]);

  const transform: TransformMethod<EntityModel, BillingProfile> = useCallback(
    (entity, snapshot) => ({
      id: snapshot.id,
      priorityId: entity.priorityId ?? "",
      name: entity.name,
      paymentMethod: entity.payment?.type ?? PaymentMethodType.WireTransfer,
      currency: entity.currency ?? "",
    }),
    []
  );

  return useCollectionData(query, {
    transform,
  });
}

export function useBillingProfileName(
  selectedPriorityId: string | undefined,
  customerId: string
): [string | undefined, boolean] {
  const [billingProfiles, loading] = useBillingProfiles(customerId);
  const billingProfileName = useMemo(() => {
    if (selectedPriorityId) {
      const selectedBillingProfile = billingProfiles?.find((profile) => profile.priorityId === selectedPriorityId);
      if (selectedBillingProfile) {
        return selectedBillingProfile.name;
      }
    }
    if (billingProfiles && billingProfiles.length > 0) {
      return billingProfiles[0].name;
    }
  }, [billingProfiles, selectedPriorityId]);
  return [billingProfileName, loading];
}

export function useStandaloneAPI(type: FlexsaveType) {
  const customerId = useCustomerId();
  const api = useApiContext();
  return useMemo(() => new StandaloneApi(api, customerId, type), [api, customerId, type]);
}

export function useHasManuallySignedContract(customerId: string, type: FlexsaveType) {
  const query = useMemo(() => {
    const customerRef = getCollection(CustomerModel).doc(customerId);

    return getCollection(ContractModel)
      .where("customer", "==", customerRef)
      .where("type", "==", type === FlexsaveTypes.AWS ? StandaloneType.AWS : StandaloneType.GCP);
  }, [customerId, type]);

  const [contracts] = useCollectionData(query);
  return contracts && contracts.length > 0;
}
