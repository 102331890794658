import { useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { Box } from "@mui/material";
import isEqual from "lodash/isEqual";

import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { updateStartTrialInitiated } from "../../../Components/StartTrialButton/db";
import { type Step, Stepper, type StepState } from "../../../Components/Stepper";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { customersRoute } from "../../../Navigation/core/utils";
import useCloudSelection from "../../../Pages/Customer/SaaSConsoleOnboarding/Common/CloudSelector/hooks";
import {
  ProductCloudToSaaSType,
  saasToProductCloudType,
} from "../../../Pages/Customer/SaaSConsoleOnboarding/Common/CloudSelector/utils";
import { updateCloudProviders } from "../../../Pages/Customer/SaaSConsoleOnboarding/Common/db";
import { useFullScreen } from "../../../utils/dialog";
import { getOnboardingUrl } from "../utils";
import CloudSelector from "./CloudSelector";
import SolveOverviewStep from "./Overview";

const SolveOnlyCloudOnboardingStepper = () => {
  const { customer } = useCustomerContext();
  const { onboarding } = customer;
  const history = useHistory();
  const { isMobile } = useFullScreen();
  const showError = useErrorSnackbar();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const initialCloudOptions = useMemo(
    () => onboarding?.solve?.providers?.map((provider) => ProductCloudToSaaSType(provider)),
    [onboarding]
  );

  const { selectedCloudProviders, handleCloudSelection } = useCloudSelection(initialCloudOptions ?? []);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const cloudProviders = selectedCloudProviders.map((cloud) => saasToProductCloudType(cloud));

      if (!isEqual(selectedCloudProviders, initialCloudOptions)) {
        await updateCloudProviders(customer.id, cloudProviders, "solve");
      }
      await updateStartTrialInitiated(customer);

      history.push(getOnboardingUrl(customer, cloudProviders));
    } catch (e) {
      showError(
        "Failed to update cloud providers details. Please try again. If the problem persists, contact support."
      );
    } finally {
      setLoading(false);
    }
  }, [customer, selectedCloudProviders, initialCloudOptions, history, showError]);

  const stepperState: StepState[] =
    currentStep > 0 && selectedCloudProviders.length > 0 ? ["complete", "complete"] : ["complete", "incomplete"];

  const customerPage = customersRoute(customer.id);

  const steps: Step[] = [
    {
      children: <SolveOverviewStep />,
      label: "Overview",
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: (
        <CloudSelector selectedCloudProviders={selectedCloudProviders} handleCloudSelection={handleCloudSelection} />
      ),
      label: "Cloud",
      order: 1,
      required: true,
      state: stepperState[1],
    },
  ];

  return (
    <Box sx={{ mt: 4 }}>
      <Stepper
        getCurrentStep={setCurrentStep}
        loading={loading}
        onCancel={() => {
          history.push(customerPage);
        }}
        onSubmit={onSubmit}
        steps={steps}
        submitButtonLabel="Continue"
        footerMaxWidth={isMobile ? 500 : 800}
        maxWidth={1500}
        contentSx={{
          my: 4,
        }}
      />
    </Box>
  );
};

export default SolveOnlyCloudOnboardingStepper;
