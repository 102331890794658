import { type CostDifferencesData, type ExplainerCostDifference } from "../../types";

export const discrepancyAbbreviations = {
  tax: {
    label: "Tax",
    tooltip:
      "If applicable. It's not passed from AWS, and it's always recalculated in the DoiT Console based on your billing profile.",
  },
  usage: { label: "Usage", tooltip: "On-Demand charges." },
  savingsPlanCoveredUsage: {
    label: "Savings Plan covered usage",
    tooltip:
      "On-Demand costs covered by DoiT Savings Plans. If you've purchased SPs directly from AWS, this is a combination of eligible On-Demand costs covered by both your own Saving Plans, and DoiT Saving Plans.",
  },
  flexsaveCoveredUsage: { label: "Flexsave covered usage", tooltip: "On-Demand costs covered by Flexsave." },
  support: {
    label: "Support cost",
    tooltip:
      "If you have a paid support level, the costs may differ from AWS to DoiT. If you use Flexsave, in AWS, these fees are calculated factoring in a Saving Plans 3-year discount rate, whereas in DoiT, they're based on a Saving Plans 1-year discount rate.",
  },
  credits: {
    label: "Credits",
    tooltip:
      "Credits are automatically applied by AWS to cover your costs associated with eligible services. These credits are passed onto your DoiT Cloud Analytics.",
  },
  privateRateDiscount: {
    label: "Private Rate Card discount",
    tooltip: "An agreement which provides discounted prices for specific services.",
  },
  bundledDiscount: {
    label: "Bundled discount",
    tooltip:
      "Usage-based discount with a conditional logic associated with usage. This discount is passed onto your DoiT invoice.",
  },
  edpDiscount: {
    label: "Enterprise Discount Program discount",
    tooltip:
      "It's a contractual agreement which offers cross-service discount in return for an annual or multi-year commitment. You're rewarded with higher discounts for additional contract years you commit to. EDP is mainly targeted to customers with a high annual usage spend.",
  },
  sppDiscount: {
    label: "Solution Provider Program discount",
    tooltip: "These discounts are given to DoiT by AWS, as we're an authorized reseller of their services.",
  },
  savingsPlanNegation: {
    label: "Savings Plan negation",
    tooltip:
      "Savings provided by DoiT Savings Plans. If you've purchased SPs directly from AWS, this is a combination of eligible savings from both your own Saving Plans, and DoiT Saving Plans.",
  },
  flexsaveSavings: { label: "Flexsave savings", tooltip: "Savings provided by Flexsave." },
  reservationAppliedUsage: {
    label: "Reservation applied usage",
    tooltip:
      "Covered usage by Reserved Instances. It's always represented as 0, since AWS represents the covered cost as 0.",
  },
  refund: { label: "Refunds", tooltip: "Refund applied to the invoice." },
  ocbCharges: {
    label: "Other out-of-cycle charges",
    tooltip:
      "Separate line items for one-time purchases, such as the Registrar fee, or APN fees billed upfront or following a private offer schedule.",
  },
  savingsPlanRecurringFee: {
    label: "Savings Plan recurring fee",
    tooltip:
      "Recurring charges related to DoiT Savings Plans. If you've purchased SPs directly from AWS, these fees are a combination of both DoiT and AWS SPs. DoiT Saving plans recurring fees are removed when we pull your monthly details through the Monthly Cost and Usage Report (CUR).",
  },
  flexsaveCharges: { label: "Flexsave charges", tooltip: "The charge for the Flexsave service." },
  reservationRecurringFee: {
    label: "Recurring reservation fee",
    tooltip: "Monthly fee for Reserved Instances that are purchased partially upfront or no upfront.",
  },
  fee: { label: "Fee", tooltip: "Enterprise Discount Program on upfront charges and exchange charges." },
  savingsPlanUpfrontFee: {
    label: "Savings Plan upfront fee",
    tooltip: "This is is added to your bill when you purchase an All Upfront or Partial Upfront Savings Plan.",
  },
  flexsaveAdjustment: {
    label: "Flexsave adjustment",
    tooltip: "Financial adjustment based on the difference in costs between one-year and three-year commitments.",
  },
};

const getDiscrepancies = (
  doitCosts: Record<string, number>,
  awsCosts: Record<string, number>
): Record<string, { doitCost: number; awsCost: number; totalDifference: number }> => {
  const doitCostKeys = Object.keys(doitCosts);
  const awsCostKeys = Object.keys(awsCosts);
  const allKeys = Array.from(new Set([...doitCostKeys, ...awsCostKeys]));

  return allKeys.reduce((acc, key) => {
    acc[key] = {
      doitCost: doitCosts[key] ?? 0,
      awsCost: awsCosts[key] ?? 0,
      totalDifference: (doitCosts[key] ?? 0) - (awsCosts[key] ?? 0),
    };
    return acc;
  }, {});
};

export const parseCostDifferenceData = (costDifferences: ExplainerCostDifference): CostDifferencesData[] =>
  Object.entries(costDifferences).map(([costName, values]) => {
    const doitCost = Object.values(values.doit).reduce((acc, curr) => acc + curr, 0);
    const awsCost = Object.values(values.aws).reduce((acc, curr) => acc + curr, 0);
    const costDifference = Math.abs(doitCost - awsCost);

    return {
      costName,
      doitCost,
      awsCost,
      costDifference,
      discrepancies: getDiscrepancies(values.doit, values.aws),
    };
  });
