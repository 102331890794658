import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import LaunchIcon from "@mui/icons-material/Launch";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  type Appearance,
  loadStripe,
  type Stripe,
  type StripeError,
  type StripePaymentElementChangeEvent,
} from "@stripe/stripe-js";

import { useApiContext } from "../../../api/context";
import { invoicesTxt } from "../../../assets/texts/Billing/invoices";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";

const stripeClients = {
  acct_1HTM1zLxqYtCDmwM: loadStripe(process.env.REACT_APP_STRIPE_UK_AND_I_PUBLISHABLE_KEY as string), // UK and Ireland
  acct_1A5pxKKD42kkxF15: loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string), // US
  acct_1Myzw1JCzGwjua24: loadStripe(process.env.REACT_APP_STRIPE_DE_PUBLISHABLE_KEY as string), // DE
};

const getStripeClient = (accountID: string) => {
  const stripeClient = stripeClients[accountID];
  if (!stripeClient) {
    throw new Error("Stripe account not found");
  }
  return stripeClient;
};

const lightModeColors = {
  colorPrimary: "#3B40B5",
  colorBackground: "#ffffff",
  colorText: "#212121",
  colorTextPlaceholder: "#CBCBCB",
  colorDanger: "#D32F2F",
};

const darkModeColors = {
  colorPrimary: "#B3BEFF",
  colorBackground: "#353540",
  colorText: "#FFFFFF",
  colorTextPlaceholder: "#CBCBCB",
  colorDanger: "#FF8A80",
};

const fonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700",
  },
];

export const GetStripeCreditCardFeeAlert = (currency: CurrencyCode) =>
  invoicesTxt.PAYMENT_CHARGE_FEE(currency === "EGP" ? 4.9 : 2.9);

export type StripeFromHandle = {
  handleSubmit: () => Promise<StripeError | undefined>;
};

const SetupForm = forwardRef<StripeFromHandle, { clientSecret: string; returnUrl: string; currency: CurrencyCode }>(
  ({ clientSecret, returnUrl, currency }, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [showCCFeeWarning, setShowCCFeeWarning] = useState(false);

    const handleSubmit = async () => {
      if (!stripe || !elements) {
        return;
      }

      // Trigger form validation
      const { error: validationError } = await elements.submit();
      if (validationError) {
        return validationError;
      }

      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) {
        setErrorMessage(error.message);
        return error;
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };

    const handleChange = (event: StripePaymentElementChangeEvent) => {
      setShowCCFeeWarning(event.value.type === "card");
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <>
        {showCCFeeWarning && <Alert severity="warning">{GetStripeCreditCardFeeAlert(currency)}</Alert>}
        <PaymentElement onChange={handleChange} />
        {!!errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </>
    );
  }
);
SetupForm.displayName = "SetupForm";

export const StripePaymentMethods = forwardRef<
  StripeFromHandle,
  {
    setLoadSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    entityId: string;
    customerId: string;
    returnUrl: string;
    currency: CurrencyCode;
    newEntity: boolean;
  }
>(({ setLoadSuccess, entityId, customerId, returnUrl, currency, newEntity }, ref) => {
  const api = useApiContext();
  const theme = useTheme();
  const [clientSecret, setClientSecret] = useState();
  const [stripeClient, setStripeClient] = useState<Promise<Stripe | null>>();
  const [loading, setLoading] = useState(true);
  const errorSnackbar = useErrorSnackbar();
  const appearance: Appearance = {
    theme: "stripe",
    variables: {
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      spacingUnit: "4px",
      borderRadius: "4px",
      ...(theme.palette.mode === "dark" ? darkModeColors : lightModeColors),
    },
  };

  useEffect(() => {
    const setupIntent = async () => {
      setLoadSuccess(false);
      setLoading(true);
      try {
        const res = await api.request({
          method: "post",
          url: `/v1/customers/${customerId}/entities/${entityId}/setup-intents`, // This is sent when you click the "add new payment method" button to open the dialog in the first place.
          data: { newEntity },
        });
        setClientSecret(res.data.Secret);
        const stripeClient = getStripeClient(res.data.AccountID);
        setStripeClient(stripeClient);
      } catch (e) {
        setLoading(false);
        errorSnackbar("Failed to load, please try again later");
        throw e;
      }
      setLoading(false);
      setLoadSuccess(true);
    };
    setupIntent();
  }, [api, customerId, entityId, errorSnackbar, setLoading, setLoadSuccess, newEntity]);

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!!clientSecret && stripeClient && (
        <Elements
          stripe={stripeClient}
          options={{
            appearance,
            clientSecret,
            fonts,
          }}
        >
          <SetupForm clientSecret={clientSecret} returnUrl={returnUrl} ref={ref} currency={currency} />
        </Elements>
      )}
    </>
  );
});
StripePaymentMethods.displayName = "StripePaymentMethods";

export default StripePaymentMethods;

const paymentMethodDisplayNames = {
  bacs_debit: "Bacs Direct Debit",
  acss_debit: "ACSS Debit",
};

export const StripeSetupSession = ({ entityId, customerId, successUrl, cancelUrl, newEntity }) => {
  const api = useApiContext();
  const [sessionUrl, setSessionUrl] = useState();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const errorSnackbar = useErrorSnackbar();

  useEffect(() => {
    api
      .request({
        method: "post",
        url: `/v1/customers/${customerId}/entities/${entityId}/setup-session`,
        data: {
          successUrl,
          cancelUrl,
          newEntity,
        },
      })
      .then((res) => {
        setSessionUrl(res.data.url);
        setPaymentMethod(paymentMethodDisplayNames[res.data.pm_type]);
      })
      .catch((e) => {
        errorSnackbar("Some payment methods are not available at this time. Please try again later.");
        throw e;
      });
  }, [api, customerId, entityId, successUrl, cancelUrl, errorSnackbar, newEntity]);

  return (
    <Stack spacing={2}>
      {sessionUrl && !!paymentMethod && (
        <>
          <Typography variant="body1" color="textPrimary">
            {`Set up ${paymentMethod}`}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {`Clicking the button below will take you to a page where you can set up ${paymentMethod} as the payment method for this billing profile. Once completed, you will return to this page.`}
          </Typography>
          <Button variant="outlined" startIcon={<LaunchIcon />} href={sessionUrl} style={{ width: "220px" }}>
            {`Set up ${paymentMethod}`}
          </Button>
        </>
      )}
    </Stack>
  );
};
