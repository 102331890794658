import { Fragment, type JSX, type ReactNode } from "react";

import { Tab, type TabProps, Tabs } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type TabsProps } from "@mui/material/Tabs/Tabs";
import { makeStyles } from "@mui/styles";
import { type SxProps } from "@mui/system";

const useStyles = makeStyles(() => ({
  tab: {
    textTransform: "initial",
    minHeight: "40px",
    alignItems: "flex-start",
  },
  tabs: {
    borderLeft: `1px solid #E4E4E4`,
  },
  indicator: {
    left: "0px",
    // borderRight: `2px solid ${theme.palette.text.primary}`,
  },
}));

type SideTabProps = TabProps & {
  tabChildren?: ReactNode;
};

export const SideTab = (props: SideTabProps) => {
  const classes = useStyles();
  const { tabChildren, ...resetProps } = props;
  return (
    <>
      <Tab classes={{ root: classes.tab }} {...resetProps} />
      {tabChildren}
    </>
  );
};

type Props = {
  value: string | number | false;
  onChange?: TabsProps["onChange"];
  children: JSX.Element[];
  sx?: SxProps<Theme>;
};

export const SideTabs = ({ value, children, onChange, sx }: Props) => {
  const classes = useStyles();

  return (
    <Tabs
      style={{ minWidth: "215px" }}
      centered={false}
      className={classes.tabs}
      orientation="vertical"
      value={value}
      onChange={onChange}
      classes={{
        indicator: classes.indicator,
      }}
      sx={sx}
    >
      {children}
    </Tabs>
  );
};
