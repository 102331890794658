import { type FocusEvent, useState } from "react";

import Box from "@mui/material/Box";
import TextField, { type TextFieldProps } from "@mui/material/TextField";

type Props = TextFieldProps & {
  validationRegex: RegExp;
  validationErrorMessage: string;
};

export function TextFieldWithValidation({ validationRegex, validationErrorMessage, ...props }: Props) {
  const [error, setError] = useState(false);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (!validationRegex.test(event.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <Box>
      <TextField {...props} onBlur={handleBlur} error={error} helperText={error ? validationErrorMessage : ""} />
    </Box>
  );
}
