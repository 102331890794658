import { type FC, useEffect, useLayoutEffect, useMemo, useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, type SxProps } from "@mui/material";

import { cmpBaseColors } from "../../../../../../../cmpBaseColors";
import { colors } from "../../../../../../../muiTheme";
import { ThemeModes } from "../../../../../../../muiThemeTypes";
import { useReferencedFieldContext } from "./useReferencedFieldContext";
import { useResizeObserverSignal } from "./useResizeObserverSignal";

export const ellipsisSx: SxProps = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

const Ellipsis: FC<{ tokens: string[] }> = ({ tokens }) => {
  const [boxEl, setBoxEl] = useState<HTMLDivElement | null>(null);
  const resizeSignal = useResizeObserverSignal(boxEl);
  const label = useMemo(() => tokens.join("."), [tokens]);

  useLayoutEffect(() => {
    if (!boxEl) {
      return;
    }
    boxEl.dataset.tail = "";
    if (boxEl.offsetWidth < boxEl.scrollWidth) {
      const text = boxEl.textContent;

      boxEl.dataset.tail = text?.slice(text?.length - (tokens[tokens.length - 1]?.length ?? 0)) ?? "";
    }
  }, [label, tokens, boxEl, resizeSignal]);

  return (
    <Box
      ref={setBoxEl}
      sx={{
        ...ellipsisSx,
        "&:before": {
          maxWidth: "75%",
          float: "right",
          content: "attr(data-tail)",
          ...ellipsisSx,
        },
      }}
    >
      {label}
    </Box>
  );
};

interface FieldChipProps {
  tokens: string[];
  onDelete?: () => void;
}

export const FieldChip: FC<FieldChipProps> = ({ tokens, onDelete }) => {
  if (tokens.length === 0) {
    return null;
  }

  return (
    <Chip
      label={<Ellipsis tokens={tokens} />}
      size="small"
      sx={({ palette }) => ({
        borderRadius: 1,
        borderColor: palette.mode === ThemeModes.DARK ? palette.primary.light : cmpBaseColors.bluishBackground,
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: colors.light.primary.hoverBackground,
        color: palette.primary.main,
      })}
      onDelete={onDelete}
      deleteIcon={<CloseIcon sx={({ palette }) => ({ fill: palette.action.active })} />}
    />
  );
};

interface ReferencedFieldChipProps {
  value: unknown;
  onDelete?: () => void;
}

export const ReferencedFieldChip: FC<ReferencedFieldChipProps> = ({ value, onDelete }) => {
  const { referenceableNodes } = useReferencedFieldContext();
  const [tokens, setTokens] = useState<string[]>([]);

  useEffect(() => {
    let tokens: string[] = [];
    if (isReferencedNodeValue(value)) {
      const referencedNode = referenceableNodes.find(({ id }) => id === value.referencedNodeId);
      if (referencedNode) {
        tokens = [referencedNode.name, ...value.referencedField];
      }
    }
    setTokens(tokens);
  }, [referenceableNodes, value]);

  if (!isReferencedNodeValue(value) || tokens.length === 0) {
    return null;
  }

  return <FieldChip tokens={tokens} onDelete={onDelete} />;
};
