import * as React from "react";

import Lottie from "react-lottie-player";

import loadingAnimationDark from "../../assets/ava/ava-loading-animation-dark.json";
import loadingAnimationLight from "../../assets/ava/ava-loading-animation-light.json";

type AvaLoadingAnimationProps = {
  isDarkMode: boolean;
};

export const AvaLoadingAnimation = ({ isDarkMode }: AvaLoadingAnimationProps) => (
  <Lottie
    style={{ width: 40 }}
    loop
    play
    animationData={isDarkMode ? loadingAnimationDark : loadingAnimationLight}
    rendererSettings={{
      preserveAspectRatio: "xMidYMid slice",
    }}
    speed={1}
  />
);
