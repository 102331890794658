import { type JSX, useCallback } from "react";

import { Stack, TextField } from "@mui/material";

import { roundWithCommas } from "../../../utils/common";
import { licensePurchaseCap } from "../assetUtils";
import { cyIds } from "./LicenseChangeDialog";

type LicenseQuantityProps = {
  adding: boolean;
  delta: number;
  setDelta: (number) => void;
  error: boolean;
  errorMessage?: string;
  maxNegativeDelta: number;
  usageSummary: JSX.Element;
};

/**
 * Second step of the {@link LicenseChangeDialog} process, wherein the user enters how many licenses they wish to add/remove.
 * @param adding whether the process is to add or to remove licenses
 * @param delta the number of licenses being added or removed
 * @param setDelta function to inform parent dialog of changes to delta
 * @param error whether the input has a validation error
 * @param errorMessage useful message in the event of an error
 * @param maxNegativeDelta The largest number of licenses that can be removed from this asset
 * @param usageSummary An element detailing the use of licenses on this asset
 * @see tests for LicenseChangeDialog for tests for this component's rendering and behaviour in context.
 */
const LicenseQuantity = ({
  adding,
  delta,
  setDelta,
  error,
  errorMessage,
  maxNegativeDelta,
  usageSummary,
}: LicenseQuantityProps) => {
  const handleChange = useCallback(
    (event) => {
      setDelta(Number(event.target.value));
    },
    [setDelta]
  );
  const inputProps = { min: 0, max: adding ? 9999 : maxNegativeDelta };
  const generalHelpMessage = adding
    ? `Enter the number of licenses you would like to add to this asset, up to and including ${roundWithCommas(
        licensePurchaseCap
      )}`
    : `You can only remove seats that are not in use, which is a maximum of ${maxNegativeDelta} in this instance`;
  return (
    <Stack data-cy={cyIds.content.steps[1]} spacing={4}>
      {usageSummary}
      <TextField
        required
        type="number"
        label={`${adding ? "Add" : "Remove"} licenses`}
        value={delta === 0 ? null : delta}
        placeholder="Licenses"
        InputLabelProps={{ shrink: true }}
        inputProps={{ ...inputProps, "data-cy": cyIds.content.step1.input }}
        size="medium"
        onChange={handleChange}
        error={error}
        helperText={(error && errorMessage) || generalHelpMessage}
      />
    </Stack>
  );
};

export default LicenseQuantity;
