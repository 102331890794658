import { AssetTypeGoogleCloud, AssetTypeGoogleCloudDirect, AssetTypeGoogleCloudProject } from "@doitintl/cmp-models";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { useFullScreen } from "../../../../utils/dialog";
import { type GCPTableMode } from "./GCAssetSubmenu";

type GoogleAssetSelectorProps = {
  data: Record<string, number>;
  handleSelect: (value: GCPTableMode) => void;
  tableMode: GCPTableMode;
};

/**
 * For use in Google Cloud asset tab to allow the customer to select the asset type when in mobile mode.
 * @param props
 * @param props.data Object detailing the number of assets per Google cloud asset type.
 * @param props.handleSelect What to do when the user makes a choice. This should be setting the table mode in the Assets component.
 * @param props.tableMode The current tableMode from Assets component.
 * @constructor
 */
const GoogleAssetSelector = ({ data, handleSelect, tableMode }: GoogleAssetSelectorProps) => {
  const { isMobile } = useFullScreen();

  const selector = (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel>Google Cloud Asset Type</InputLabel>
      <Select
        label="Google cloud asset type"
        value={tableMode}
        onChange={(event) => {
          handleSelect(event.target.value as GCPTableMode);
        }}
      >
        <MenuItem value={AssetTypeGoogleCloud}>{`Partner - ${data[AssetTypeGoogleCloud]}`}</MenuItem>
        <MenuItem value={AssetTypeGoogleCloudDirect}>{`Direct - ${data[AssetTypeGoogleCloudDirect]}`}</MenuItem>
        <MenuItem value={AssetTypeGoogleCloudProject}>{`Projects - ${data[AssetTypeGoogleCloudProject]}`}</MenuItem>
      </Select>
    </FormControl>
  );

  return isMobile ? selector : <></>;
};

export default GoogleAssetSelector;
