import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertTitle } from "@mui/material";

import useUpdateEffect from "./hooks/useUpdateEffect";

interface Props {
  error: Error;
  shouldShowError: boolean;
}

export default function ErrorBoundaryFallback({ error, shouldShowError }: Props) {
  const location: Pick<Location, "pathname"> = useLocation();
  const history = useHistory();

  // on first run of effect don't refresh app
  useUpdateEffect(() => {
    history.push(location.pathname);
    history.go(0);
  }, [location]);

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <p>Oops! Something went wrong. We are looking into it!</p>
      {shouldShowError && `Error Message: "${error.message}"`}
    </Alert>
  );
}
