import { useUserContext } from "../../../../../Context/UserContext";
import { AssetHeaderButtons } from "./AssetHeaderButtons";
import { AssetTypeTitle } from "./AssetTypeTitle";

type Props = {
  transferProjectsAction: () => void;
  manageAccountAction: () => void;
  backTo: string;
  showTransfer: boolean;
};

export const AssetHeader = (props: Props) => {
  const { transferProjectsAction, manageAccountAction, backTo, showTransfer } = props;
  const { userRoles } = useUserContext();

  return (
    <>
      <AssetTypeTitle backTo={backTo} />
      {userRoles?.assetsManager && (
        <AssetHeaderButtons
          transferProjectsAction={transferProjectsAction}
          manageAccountAction={manageAccountAction}
          showTransfer={showTransfer}
        />
      )}
    </>
  );
};
