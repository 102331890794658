import { TextField } from "@mui/material";

type Props = {
  label: string;
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const PriceInput = ({ label, value, onChange }: Props) => (
  <TextField
    label={label}
    value={value}
    onChange={onChange}
    fullWidth
    margin="normal"
    type="number"
    inputProps={{ min: 0 }}
  />
);

export default PriceInput;
