import { ChannelModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export type OnChangeDetectedCallback = (error: string | undefined) => void;

/**
 * Helper inner snapshot listener for changes in customers AWS accounts.
 * will listen on the "channels" collection for specific customer change that will happen in the future
 * @param customerId - customer ID
 * @param userUid
 * @param stackName - uniq stack that to listen for status
 * @param onChangeDetected - callback to call
 */
export function listenForStackCreationUpdate(
  customerId: string,
  userUid: string,
  stackName: string,
  onChangeDetected: OnChangeDetectedCallback
) {
  const query = getCollection(ChannelModel)
    .where("customer", "==", customerId)
    .where("stackName", "==", stackName)
    .where("type", "==", "aws.manage.account")
    .where("uid", "==", userUid)
    .limit(1);

  return query.onSnapshot((itemsSnapshots) => {
    if (itemsSnapshots.size > 0) {
      const connectStatus = itemsSnapshots.docs[0].asModelData();
      if (connectStatus.complete) {
        onChangeDetected(connectStatus.error);
      }
    }
  });
}
