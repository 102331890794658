import { useState, useCallback, useEffect, useMemo } from "react";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";

import { Alert, AlertTitle, Link, Container } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import LoadingButton from "../LoadingButton";
import { globalURLs, helpURLs } from "../../assets/urls";
import { useFullScreen } from "../../utils/dialog";
import { useSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import TransferList from "./TransferList";
import GcloudPanel from "./GcloudPanel";
import CopyToClipboard from "./CopyToClipboard";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(1, 2),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: 7,
    minHeight: 300,
  },
  stepperBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(1),
  },
  saBox: {
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
    borderRadius: 3,
    padding: 7,
    backgroundColor: theme.palette.background.paper,
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginRight: theme.spacing(0.5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  alert: {
    paddingBottom: 0,
    marginBottom: theme.spacing(1),
  },
  formControlLabel: {
    font: "inherit",
  },
}));

function getSteps() {
  return ["Generate Service Account", "Edit your IAM", "Transfer Projects"];
}

export default function TransferProjectsDialog({
  open,
  handleClose,
  createSA,
  transferProjects,
  serviceAccountEmail,
  customerProjects,
  loading,
  assetData,
  isServiceAccountValid,
  reset,
  isTransferDone,
  testServiceAccountPermission,
  transferredProjects,
}) {
  const classes = useStyles();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const { isMobile: matches } = useFullScreen();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [confirmNoMarketplace, setConfirmNoMarketplace] = useState(false);
  const [confirmSA, setConfirmSA] = useState(false);
  const [isTransferProject, setIsTransferProject] = useState(null);
  let steps = getSteps();
  if (matches) {
    steps = steps.map(() => "");
  }
  const handleNext = useCallback(() => {
    if (activeStep === 1 && (isServiceAccountValid === null || !isServiceAccountValid)) {
      testServiceAccountPermission();
      return;
    }
    if (activeStep === steps.length - 1 && !isTransferDone) {
      setIsTransferProject(true);
      transferProjects(selectedProjects);
      return;
    }
    if (activeStep === steps.length) {
      handleClose();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [
    activeStep,
    handleClose,
    isServiceAccountValid,
    isTransferDone,
    selectedProjects,
    steps.length,
    testServiceAccountPermission,
    transferProjects,
  ]);

  const openSnackbar = useCallback(() => {
    showSnackbar({
      message: "Service Account Not Found in Organization IAM",
      variant: "error",
      autoHideDuration: 5000,
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
  }, [hideSnackbar, showSnackbar]);

  useEffect(() => {
    if (serviceAccountEmail && activeStep === 0) {
      handleNext();
    }
    if (activeStep === steps.length - 1 && isTransferDone) {
      setIsTransferProject(null);
      handleNext();
    }
  }, [activeStep, serviceAccountEmail, isTransferDone, steps.length, handleNext]);

  useEffect(() => {
    if (activeStep === 1 && isServiceAccountValid !== null) {
      if (isServiceAccountValid) {
        handleNext();
      } else {
        openSnackbar();
      }
    }
  }, [activeStep, handleNext, isServiceAccountValid, openSnackbar]);

  const handleChange = (event) => {
    setConfirmSA(event.target.checked);
  };
  const projectsStatus = useMemo(() => {
    if (transferredProjects) {
      const successProjects = transferredProjects.transferredProjects
        ? transferredProjects.transferredProjects.map((project) => `${project}, success`)
        : [];
      const errorProjects = transferredProjects.blockedProjects
        ? transferredProjects.blockedProjects.map((project) => `${project}, error-code`)
        : [];
      return [...successProjects, ...errorProjects].join("\n");
    }
  }, [transferredProjects]);
  const onSelectedProjects = (projects) => {
    setSelectedProjects(projects);
  };
  const getStatusText = () => {
    let totalProjects = transferredProjects.transferredProjects ? transferredProjects.transferredProjects.length : 0;
    totalProjects = transferredProjects.blockedProjects
      ? totalProjects + transferredProjects.blockedProjects.length
      : totalProjects;
    if (!transferredProjects.transferredProjects || transferredProjects.transferredProjects.length === 0) {
      return (
        <>
          <Typography className={classes.title} variant="h6">
            We Couldn't Transfer Your Projects
          </Typography>
          <br />
          <Typography variant="subtitle2" color="textSecondary" align="center">
            We weren't able to transfer your projects. To help you troubleshoot the cause, read more about the{" "}
            <Link target="_blank" href={helpURLs.TRANSFER_PROJECTS}>
              two possible reasons
            </Link>{" "}
            this occurred.
          </Typography>
        </>
      );
    }
    if (transferredProjects.transferredProjects.length !== totalProjects) {
      return (
        <>
          <Typography className={classes.title} variant="h6">
            Projects Partially Transferred
          </Typography>
          <br />
          <Typography variant="subtitle2" color="textSecondary" align="center">
            You’ve successfully transferred{" "}
            {transferredProjects.transferredProjects ? transferredProjects.transferredProjects.length : 0} projects (out
            of {totalProjects}) to your new billing account with DoiT International! <br />
            <br />
            Some of your projects weren't transferred for{" "}
            <Link target="_blank" href={helpURLs.TRANSFER_PROJECTS}>
              two possible reasons.
            </Link>{" "}
            <br />
            <br />
            <div style={{ display: "flex" }}>
              <CopyToClipboard type="text" spacer={true} showSnackbar={showSnackbar} textToCopy={projectsStatus} />
              &nbsp;to identify which projects weren't transferred successfully for further troubleshooting.
            </div>
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography className={classes.title} variant="h6">
          Transfer Completed Successfully!
        </Typography>
        <br />
        <Typography variant="subtitle2" color="textSecondary" align="center">
          Congrats, You’ve successfully transferred{" "}
          {transferredProjects.transferredProjects ? transferredProjects.transferredProjects.length : 0} projects (out
          of {totalProjects}) to your new billing account with DoiT International! <br />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CopyToClipboard type="text" spacer={true} showSnackbar={showSnackbar} textToCopy={projectsStatus} />
            &nbsp; to get the full list of transferred projects.
          </div>
        </Typography>
      </>
    );
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className={classes.stepperBox}>
            <Typography className={classes.title} variant="h6" color="textPrimary">
              Generate Service Account
            </Typography>
            <Container maxWidth="md">
              <Alert severity="warning" className={classes.alert}>
                <AlertTitle>WARNING</AlertTitle>
                <span>
                  Changing the billing account linked to a project could disable and result in data loss for any
                  partner-managed services purchased through GCP Marketplace.{" "}
                  <Link
                    href={globalURLs.BILLING_CHANGE_PROJECT}
                    target="_blank"
                    rel="noopener noreferrer"
                    display="inline"
                  >
                    Learn more
                  </Link>
                </span>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={confirmNoMarketplace}
                      onChange={(event) => setConfirmNoMarketplace(event.target.checked)}
                      color="default"
                    />
                  }
                  label="My organization does not use any services purchased through GCP Marketplace"
                  classes={{
                    label: classes.formControlLabel,
                  }}
                />
              </Alert>
              <Typography align="center" variant="body1" color="textSecondary" paragraph>
                Click "START" to generate a unique service account dedicated to your organization. The wizard will use
                this service account to securely transfer projects to your new billing account with DoiT International.
              </Typography>
            </Container>
            {loading && (
              <Typography variant="caption" color="textSecondary" align="center">
                Generating Service Account...
              </Typography>
            )}
          </div>
        );
      case 1:
        return (
          <div className={classes.stepperBox}>
            <Typography className={classes.title} variant="h6" color="textPrimary">
              Update your Google Cloud IAM
            </Typography>

            <Typography style={{ textAlign: "center" }} variant="subtitle2" color="textSecondary">
              Add the following service account to your Google Cloud Organization IAM Policy with the “Billing Account
              Administrator” role.
            </Typography>
            <div
              style={{
                display: "flex",
                marginTop: 30,
                width: "100%",
                overflow: "hidden",
                justifyContent: "center",
              }}
            >
              <Typography className={classes.saBox} variant="subtitle2">
                {serviceAccountEmail}
              </Typography>
              <CopyToClipboard
                type="button"
                spacer={true}
                showSnackbar={showSnackbar}
                textToCopy={serviceAccountEmail}
              />
            </div>
            <FormControlLabel
              style={{ marginTop: 20 }}
              control={<Checkbox checked={confirmSA} onChange={handleChange} color="primary" />}
              label='I have granted the service account the "Billing Account Administrator" role on my Organization IAM'
            />
            <GcloudPanel showSnackbar={showSnackbar} serviceAccount={serviceAccountEmail} />
          </div>
        );
      case 2:
        return (
          <div style={{ paddingTop: 8 }}>
            <TransferList
              onSelectedProjects={onSelectedProjects}
              createSA={createSA}
              customerProjects={customerProjects}
              loadingProjects={loading}
              assetData={assetData}
              isTransferProject={isTransferProject}
            />
          </div>
        );
      case 3:
        return <div className={classes.stepperBox}>{getStatusText()}</div>;
      default:
        return "Unknown step";
    }
  };
  const nextBtnText = activeStep === steps.length - 1 ? "FINISH" : activeStep === steps.length ? "DONE" : "NEXT";

  return (
    <div data-testid="transfer-gc-projects-wizard">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="transfer-projects-dialog-title-and-content"
        maxWidth="md"
        fullWidth
        fullScreen={matches}
        TransitionProps={{
          onExited: () => {
            setActiveStep(0);
            setConfirmSA(false);
            setConfirmNoMarketplace(false);
            reset();
          },
        }}
      >
        <DialogTitle id="transfer-projects-dialog-title">
          Transfer projects to "{assetData.properties.displayName}"
        </DialogTitle>
        <DialogContent className={classes.dialogContent} data-testid="transfer-projects-wizard-dialog-content">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div className={classes.instructions}>{getStepContent(activeStep)}</div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Button onClick={handleClose} className={classes.button} data-cy="transferCancelButton">
              Cancel
            </Button>
            <div>
              {activeStep === 0 ? (
                <LoadingButton
                  onClick={createSA}
                  color="primary"
                  variant="contained"
                  disabled={!confirmNoMarketplace || loading}
                  loading={loading}
                  data-cy="transferStartButton"
                  mixpanelEventId="transfer-projects.start"
                >
                  START
                </LoadingButton>
              ) : (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={activeStep === 0 ? createSA : handleNext}
                  className={classes.button}
                  loading={loading}
                  disabled={
                    (activeStep === 1 && !confirmSA) ||
                    loading ||
                    (activeStep === steps.length - 1 && selectedProjects.length === 0)
                  }
                  mixpanelEventId={`transfer-projects.${nextBtnText}`}
                >
                  {nextBtnText}
                </LoadingButton>
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
