import { useCallback, useMemo, useState } from "react";

import { AnalyticsDataSource, type AttributionFilter, Metadata } from "@doitintl/cmp-models";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import assign from "lodash/assign";

import { AlertsTxt } from "../../../assets/texts/CloudAnalytics";
import DataHubCheckbox from "../../../Components/DataHubCheckbox/DataHubCheckbox";
import FilterDialog from "../../../Components/Dialogs/CloudAnalytics/FilterDialog";
import { useAnalyticsDimensions } from "../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions";
import { DimensionSelector } from "../../../Components/Selects/CloudAnalytics/DimensionSelector";
import { useCloudAnalyticsContext } from "../../../Context/AnalyticsContext";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { type MetadataOption } from "../../../types";
import { getFilteredDimension } from "../alerts/shared";
import AttributionBuilderChips from "../attributions/attributionBuilder/AttributionBuilderChips";
import { useCreateAttributionHandler } from "../attributions/hooks";
import { FixedFilters } from "../utilities";

type Props = {
  dataSource: AnalyticsDataSource;
  defaultFilters?: AttributionFilter[];
  showCreateNewAttributionOnDropDown?: boolean;
  onFiltersChanged: (filters: AttributionFilter[]) => void;
  isCurrentUserEditor: boolean;
  onDataSourceChanged: (dataSource: AnalyticsDataSource) => void;
};

export const DimensionsDropdown = ({
  dataSource,
  defaultFilters,
  showCreateNewAttributionOnDropDown,
  onFiltersChanged,
  isCurrentUserEditor,
  onDataSourceChanged,
}: Props) => {
  const { customer } = useCustomerContext();
  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<AttributionFilter[]>(defaultFilters ?? []);
  const [dimension, setDimension] = useState<MetadataOption | null>(null);
  const { metadata: metadataSnapshots, hasDataHub, hasEKSData } = useCloudAnalyticsContext();
  const { filteredAttributions: filteredAttributionsByTier } = useAttributionsContext();
  const { getFeatureKey } = useTier();

  const attributions = useMemo(
    () =>
      filteredAttributionsByTier.filter((attr) => {
        const entitlementsKeys = attr.data.entitlements?.map((entitlement) => getFeatureKey(entitlement)) ?? [];
        const isEntitlementAttribution = attr.data.entitlements && attr.data.entitlements.length > 0;
        const isEKSAttribution = entitlementsKeys.includes("pdi:eks");
        return (hasEKSData && isEKSAttribution) || !isEntitlementAttribution;
      }),
    [filteredAttributionsByTier, getFeatureKey, hasEKSData]
  );

  const { dimensions, getLabelsDimensions } = useAnalyticsDimensions({
    metadataSnapshots,
    attributions,
    defaultDataSource: dataSource,
  });

  const filteredDimensions = useMemo(() => dimensions?.filter((d) => d.data.type !== Metadata.DATETIME), [dimensions]);

  const cloudProviders = useMemo(() => {
    const clouds = dimensions?.find((d) => d.id === `${Metadata.FIXED}:${FixedFilters.CLOUD}`);
    return clouds?._values.datahub ?? [];
  }, [dimensions]);

  const handleNewAttribution = useCreateAttributionHandler({
    baseUrl: `/customers/${customer.id}/analytics/attributions`,
    mixpanelEventName: "analytics.attributions.new",
    newTab: true,
  });

  const onSave = useCallback(
    (valuesOrRegexp: "values" | "regexp", filtersOrRegex: string[] | string, inverse: boolean) => {
      if (!dimension?.id) {
        return;
      }

      let regexp: string | null = null;
      let values: string[] | null = null;
      if (typeof filtersOrRegex === "string" && valuesOrRegexp === "regexp") {
        regexp = filtersOrRegex;
      } else if (Array.isArray(filtersOrRegex) && valuesOrRegexp === "values") {
        values = [...filtersOrRegex];
      }

      const filter: AttributionFilter = {
        id: dimension.id,
        values,
        regexp,
        inverse,
        allowNull: false,
        field: dimension.data.field,
        key: dimension.data.key,
        type: dimension.data.type,
      };

      if (dimension.data.nullFallback) {
        const nullIndex = filter.values?.findIndex((v) => v === dimension.data.nullFallback);
        if (nullIndex !== undefined && nullIndex !== -1) {
          filter.allowNull = true;
          filter.values?.splice(nullIndex, 1);
        }
      }
      setDimension(getFilteredDimension(dimension, filter));
      setFilters([filter]);
      onFiltersChanged([filter]);
      setFilterDialogOpen(false);
    },
    [dimension, onFiltersChanged]
  );

  const handleCancelFilter = useCallback(
    (_: MetadataOption[]) => {
      setFilterDialogOpen(false);
      if (!filters.length) {
        setDimension(null);
      }
    },
    [filters.length, setDimension, setFilterDialogOpen]
  );

  const handleSelectOption = useCallback(
    async (option: MetadataOption | null, filter?: AttributionFilter) => {
      if (!filter) {
        // when the user selects a new dimension, we reset the filters
        setDimension(option);
        setFilters([]);
        onFiltersChanged([]);
      }
      if (!option) {
        return;
      }

      const resultOption = await getLabelsDimensions([{ key: option.data.key, type: option.data.type }]);
      const updatedOption = assign(option, resultOption[0]);

      setDimension(updatedOption);
      setFilterDialogOpen(true);
    },
    [getLabelsDimensions, setDimension, setFilterDialogOpen, setFilters, onFiltersChanged]
  );

  const onEditDimension = useCallback(() => {
    if (filters.length === 0) {
      return;
    }
    setFilterDialogOpen(true);
  }, [filters.length, setFilterDialogOpen]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <DimensionSelector
            handleSelectOption={handleSelectOption}
            selectedDimension={dimension}
            dimensions={filteredDimensions ?? []}
            disabled={!isCurrentUserEditor}
            textFieldProps={{
              label: AlertsTxt.DIMENSION,
            }}
            onEdit={onEditDimension}
            showCreateNewAttributionOnDropDown={showCreateNewAttributionOnDropDown}
            handleNewAttribution={handleNewAttribution}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <DataHubCheckbox
            checked={dataSource === AnalyticsDataSource.BILLING_DATAHUB}
            cloudProviders={cloudProviders}
            customerId={customer.id}
            dataSource={dataSource}
            disabled={!isCurrentUserEditor || !hasDataHub}
            onChange={(_, checked) => {
              onDataSourceChanged(checked ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING);
            }}
          />
        </Grid>
        {filters.length > 0 && (
          <Box mt={3} width="100%">
            {filters.map((f) => (
              <AttributionBuilderChips
                key={f.id}
                filter={f}
                nullFallback={dimension?.data.nullFallback ?? null}
                lightStyle={true}
              />
            ))}
          </Box>
        )}
        {!showCreateNewAttributionOnDropDown && (
          <Stack color="text.secondary" direction="row" spacing={0.5} mt={3}>
            <Typography variant="body1" color="inherit">
              {AlertsTxt.NEED_ADVANCED_LOGIC}{" "}
            </Typography>
            <Link color="inherit" variant="body1" underline="always" href="#" onClick={handleNewAttribution}>
              {AlertsTxt.CREATE_NEW_ATTRIBUTION}
            </Link>
          </Stack>
        )}
      </Grid>
      {dimension && filterDialogOpen && (
        <FilterDialog
          disableEscapeKeyDown={false}
          handleCancelFilter={handleCancelFilter}
          isOptionDisabled={() => false}
          onClose={() => {
            setFilterDialogOpen(false);
          }}
          onSave={onSave}
          open={filterDialogOpen}
          selected={dimension}
        />
      )}
    </Box>
  );
};
