import { makeStyles } from "@mui/styles";

export const stickyFooterStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    left: 0,
    zIndex: 100,
    width: "100%",
    marginTop: 0,
    position: "fixed",
    border: `1px solid ${theme.palette.action.disabledBackground}`,
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: "#DF732C",
    marginRight: theme.spacing(1.5),
    display: "inherit",
    [theme.breakpoints.down("sm")]: {
      //  mobile layout
      display: "none",
    },
  },
  text: {
    marginRight: theme.spacing(3),
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      //  mobile layout
      flex: 1,
    },
  },
  button: {
    marginTop: "0",
  },
}));
