import { type SyntheticEvent, useMemo, useState } from "react";

import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";

import { useCustomerContext } from "../../Context/CustomerContext";

type BillingAccount = {
  id: string;
  name: string;
};

type Props = TextFieldProps & {
  onChange: (values: string[]) => void;
  size?: string;
};

const BillingAccountSelect = (props: Props) => {
  const initialValues: string[] = [];
  const { onChange, ...otherProps } = props;
  const { assets } = useCustomerContext();
  const [selectedValues, setSelectedValues] = useState(initialValues);

  const billingAccounts = useMemo(() => {
    let billingAccounts: BillingAccount[] = [];
    if (assets === undefined) {
      return billingAccounts;
    }

    for (const [, asset] of Object.entries(assets)) {
      const gcpAssets = asset
        .filter((item) => item.data.type === "google-cloud")
        .map((ba) => {
          const { billingAccountId: id, displayName: name } = ba.data.properties;
          return { id, name };
        });

      billingAccounts = [...billingAccounts, ...gcpAssets];
    }
    if (billingAccounts.length === 1) {
      setSelectedValues([billingAccounts[0].name]);
      onChange([billingAccounts[0].name]);
    }

    return billingAccounts;
  }, [assets, onChange]);

  const handleBillingAccountChanged = (_: SyntheticEvent<Element, Event>, values: string[]) => {
    setSelectedValues(values);
    onChange(values);
  };

  return (
    <Autocomplete
      multiple
      data-cy="billing-account-select"
      value={selectedValues}
      disabled={billingAccounts.length === 1}
      options={billingAccounts.map((billingAccount) => billingAccount.name)}
      disableCloseOnSelect
      onChange={handleBillingAccountChanged}
      size="small"
      limitTags={3}
      renderInput={(params) => <TextField {...params} {...otherProps} variant="outlined" />}
    />
  );
};

export default BillingAccountSelect;
