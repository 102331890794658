import { type ComponentProps, type JSX, type ReactNode, useCallback, useRef, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { type SxProps, type Theme, Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import noop from "lodash/noop";

export const cyIds = {
  iconButton: "threeDotsButton",
  menu: "threeDotsMenuPane",
};

export const menuItemKey = (key: string, rowNumber: string): string => `${rowNumber}-menuItem${key}`;

export type ThreeDotsMenuOptionType = "default" | "input" | "link";

export type ThreeDotsMenuOption = {
  color?: string;
  label?: ReactNode;
  action?: () => Promise<void> | void;
  type?: ThreeDotsMenuOptionType;
  href?: string;
  newTab?: boolean;
  divider?: boolean;
  key?: string;
  // Use this to override the json input by passing a pre-constructed <MenuItem>.
  menuItem?: JSX.Element;
  dataCy?: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
};

export type ThreeDotsMenuProps = {
  options: ThreeDotsMenuOption[];
  size?: "large" | "medium" | "small";
  dense?: boolean;
  closeAfterSelect?: boolean;
  menuSx?: SxProps<Theme>;
  onClick?: () => void;
  disabled?: boolean;
};

type ItemComponents = "li" | "label" | "a";

const getComponent = (type: ThreeDotsMenuOptionType | undefined): ItemComponents => {
  switch (type) {
    case "input":
      return "label";
    case "link":
      return "a";
    default:
      return "li";
  }
};

export const ThreeDotsMenu = ({
  options,
  size = "large",
  dense,
  closeAfterSelect = false,
  menuSx,
  onClick,
  disabled = false,
}: ThreeDotsMenuProps) => {
  const anchorEl = useRef<any>();
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
    onClick?.();
  }, [onClick]);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const menuItemProps = (option: ThreeDotsMenuOption) => {
    const props: ComponentProps<typeof MenuItem> & { component: ItemComponents } = {
      onClick: option.action ?? noop,
      component: getComponent(option.type),
      disabled: option?.disabled ?? false,
      divider: option.divider,
    };

    if (option.color) {
      props.sx = { color: option.color };
    }

    if (option.dataCy) {
      props["data-cy"] = option.dataCy;
    }

    const linkProps = option.newTab
      ? { ...props, href: option.href, target: "_blank" }
      : { ...props, href: option.href };

    return option.href ? linkProps : props;
  };

  const buildMenuItem = (option: ThreeDotsMenuOption) => {
    if (option.menuItem) {
      return option.menuItem;
    }

    const menuItem = (
      <MenuItem key={option.key} {...menuItemProps(option)}>
        {option.label}
      </MenuItem>
    );

    if (option.tooltip) {
      // The menuItem is not directly wrapped by the tooltip but instead by a span _and_ the tooltip because the
      // menuItem may be disabled, which would prevent events that trigger the tooltip from firing.
      // See MUI docs https://mui.com/material-ui/react-tooltip/#disabled-elements.
      return (
        <Tooltip
          title={option.tooltip}
          data-cy={`tooltip-${option.key}`}
          placement={option.tooltipPlacement}
          key={`tooltip-key-${option.key}`}
        >
          <span>{menuItem}</span>
        </Tooltip>
      );
    }

    return menuItem;
  };

  return (
    <>
      <div ref={anchorEl} data-testid="3-dots-menu">
        <IconButton
          aria-label="3-dots-menu"
          onClick={handleClick}
          size={size}
          sx={dense ? { p: 0 } : {}}
          data-cy={cyIds.iconButton}
          disabled={disabled}
        >
          <MoreVertIcon />
        </IconButton>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu
            open={open}
            anchorEl={anchorEl.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleCloseMenu}
            onClick={closeAfterSelect ? handleCloseMenu : undefined}
            data-cy={cyIds.menu}
            sx={menuSx}
          >
            {options.map((option) => buildMenuItem(option))}
          </Menu>
        </ClickAwayListener>
      )}
    </>
  );
};
