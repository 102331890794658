import { DateTime } from "luxon";

import { formatNumber } from "../../../utils/common";
import SparkLine from "./SparkLine";

type Props = {
  miniGraph: { x: number; y: number }[];
};

export default function SparkLineTable({ miniGraph }: Props) {
  const options = {
    series: [
      {
        data: miniGraph,
        pointStart: 1,
      },
    ],
    exporting: {
      enabled: false,
    },
    tooltip: {
      style: {
        width: 130,
        zIndex: 900,
      },
      formatter() {
        // @ts-expect-error
        return `<div><div style="text-align:center">${DateTime.fromMillis(this.point.category)
          .toUTC()
          .toFormat("dd LLLL yyyy hh:mm")}</div><div style="text-align:center"><b>$${formatNumber(
          // @ts-expect-error
          this.point.y
        )}</b></div></div>`;
      },
    },
    chart: {
      type: "area",
      width: 110,
    },
  };

  return (
    <table>
      <tbody>
        <SparkLine options={options} />
      </tbody>
    </table>
  );
}
