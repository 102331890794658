import { type CSSProperties } from "react";

import { Alert } from "@mui/material";

import { errorMessages } from "./consts";

export type InviteUserDialogHelper = {
  style?: CSSProperties;
  emails: string[];
};

export const UserExistsDialogHelper = ({ style, emails }: InviteUserDialogHelper) => (
  <Alert severity="info" style={style}>
    <div>{errorMessages.USER_ALREADY_EXIST}</div>
    <ul style={{ marginTop: 0, marginBottom: 0 }}>
      {emails.map((email, index) => (
        <li key={`${email}${index}`}>{email}</li>
      ))}
    </ul>
  </Alert>
);
