import { type Dispatch, useCallback, useRef } from "react";

export function usePromiseStateSetter() {
  const resolveSentinelRef = useRef<symbol>();

  return useCallback(<T>(promise: Promise<T>, dispatchFn: Dispatch<T>) => {
    const resolveSentinel = Symbol();
    resolveSentinelRef.current = resolveSentinel;
    promise.then((result) => {
      if (resolveSentinel === resolveSentinelRef.current) {
        dispatchFn(result);
      }
    });

    return () => {
      resolveSentinelRef.current = undefined;
    };
  }, []);
}
