import { useMemo, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { AssetTypeOffice365, type Office365AssetModel } from "@doitintl/cmp-models";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Form, Formik } from "formik";
import uniqBy from "lodash/uniqBy";
import { number, object, string } from "yup";

import { CatalogHooks } from "../../../../Components/Catalog/Catalog.context";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAssetsContext } from "../../../../Context/customer/AssetContext";
import { useContractsContext } from "../../../../Context/customer/ContractsContext";
import { useEntitiesContext } from "../../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type Asset, type TAssetType } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { assetTypeName } from "../../../../utils/common";
import { useSubmitOrder } from "./api";
import Steps from "./Steps";
import { type Values } from "./types";

const Schema = object().shape({
  tenant: string().required(),
  plan: string().required(),
  service: object().required(),
  quantity: number()
    .typeError("Must be a number")
    .moreThan(0, "Must be at least 1")
    .lessThan(5000, "Must be less than 5000")
    .integer("Must be an integer")
    .required(),
});

const CreateOffice365Asset = () => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const { assets } = useAssetsContext();
  const { entities } = useEntitiesContext();
  const { contracts } = useContractsContext();
  const [activeStep, setActiveStep] = useState(0);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const submitOrder = useSubmitOrder();
  const [catalog] = CatalogHooks.useOffice365CatalogContext();

  const services = useMemo(() => (catalog ?? []).filter((item) => item.data.type === "ONLINE"), [catalog]);

  const handleClose = () => {
    history.push(`/customers/${customer.id}/assets/office-365`);
  };

  const getSteps = () => ["Select your Office 365 Domain", "What would you like to order?", "Order Summary"];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const submitHandler = (values: Values, setSubmitting: (isSubmitting: boolean) => void, total: number) => async () => {
    setSubmitting(true);

    try {
      let customerDomain = "";
      let entity: string | undefined = "";
      let reseller = "";
      for (const entityId in assets) {
        const o365Assets = assets[entityId].filter(
          (o365Asset) => o365Asset.data.type === AssetTypeOffice365
        ) as Asset<Office365AssetModel>[];
        const asset = o365Assets.find(
          (a) => a.data.properties.customerId === values.tenant && a.data.customer && a.data.entity
        );
        if (asset) {
          entity = asset.data.entity?.id;
          reseller = asset.data.properties.reseller;
          customerDomain = asset.data.properties.customerDomain;
          break;
        }
      }

      await submitOrder({ customerDomain, values, customerId: customer.id, total, reseller, entity });
      successSnackbar("Order completed successfully");
      setSubmitting(false);
      handleClose();
    } catch (error: any) {
      consoleErrorWithSentry(error);
      errorSnackbar(error.response.data?.error || "Order failed");
      setSubmitting(false);
    }
  };

  const getTenants = (tenants) => (tenants.length === 1 ? tenants[0].customerId : "");

  if (!customer) {
    return null;
  }

  const steps = getSteps();
  let assetsArray: Asset<TAssetType>[] = [];
  for (const entityId in assets) {
    assetsArray = assetsArray.concat(assets[entityId]);
  }
  const officeAssets = assetsArray.filter(
    (asset) => asset.data.type === AssetTypeOffice365
  ) as Asset<Office365AssetModel>[];
  const microsoftTenants = uniqBy(officeAssets, (asset) => asset.data.properties.customerId).map((asset) => ({
    customerId: asset.data.properties.customerId,
    customerDomain: asset.data.properties.customerDomain,
  }));
  const initialValues: Values = {
    tenant: getTenants(microsoftTenants),
    plan: "FLEXIBLE",
    service: null,
    quantity: 0,
  };

  return (
    <Card>
      <CardHeader
        avatar={
          <IconButton
            aria-label="Back"
            component={Link}
            to={`/customers/${customer.id}/assets/office-365`}
            size="large"
          >
            <BackIcon color="primary" />
          </IconButton>
        }
        title={`Add ${assetTypeName(AssetTypeOffice365)} Subscription`}
        subheader={customer.name}
      />

      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Formik initialValues={initialValues} enableReinitialize validationSchema={Schema} onSubmit={() => {}}>
        {(props) => (
          <Form>
            <Steps
              {...props}
              activeStep={activeStep}
              steps={steps}
              contracts={contracts}
              assets={officeAssets}
              entities={entities}
              handleNext={handleNext}
              handleBack={handleBack}
              rawServices={services}
              submitHandler={submitHandler}
            />
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default CreateOffice365Asset;
