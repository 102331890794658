import { useCallback } from "react";

import { useApiContext } from "../../../api/context";
import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";
import { consoleErrorWithSentry } from "../../Integrations/Slack/utils";
import { type GCPCloudConnectData } from "./useCloudConnectData";

export type WorkloadIdentityFederationConnectionCheckResult =
  | {
      isConnectionEstablished: true;
    }
  | {
      isConnectionEstablished: false;
      error: string;
      errorDescription: string;
    };

export function useWorkloadIdentityFederationConnectionCheck(serviceAccount: GCPCloudConnectData) {
  const api = useApiContext();
  return useAsyncLoadingFunction(
    useCallback(async (): Promise<WorkloadIdentityFederationConnectionCheckResult> => {
      try {
        const response = await api.post<WorkloadIdentityFederationConnectionCheckResult>(
          `/v1/customers/${serviceAccount.data.customer.id}/cloudconnect/google-cloud/workload-identity-federation-check`,
          {
            cloudConnectId: serviceAccount.id,
          }
        );
        return response.data;
      } catch (exception) {
        consoleErrorWithSentry(exception);
        throw exception;
      }
    }, [api, serviceAccount.data.customer.id, serviceAccount.id])
  );
}
