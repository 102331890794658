import { type JSX } from "react";

import { Card, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { type DateTime } from "luxon";

import { EstimatedDate } from "./EstimatedDate";
import { StatusIcon } from "./StatusIcon";
import { type InfoBoxStatus } from "./types";

type Props = {
  currentState: InfoBoxStatus;
  title: string;
  subtitle: string | JSX.Element;
  estimatedDate?: DateTime;
  dateText?: string;
};

export const StateInfoBox = ({ currentState, title, subtitle, estimatedDate, dateText }: Props) => (
  <Card sx={{ width: "fit-content" }} data-cy="state-info-box">
    <Stack direction="row">
      <StatusIcon currentState={currentState} />
      <Stack p={3}>
        <Typography variant="h3" pb={1} data-cy="title">
          {title}
        </Typography>
        <Typography color="text.secondary" pb={2}>
          {subtitle}
        </Typography>
        {estimatedDate && <EstimatedDate estimatedDate={estimatedDate} text={dateText} />}
      </Stack>
    </Stack>
  </Card>
);
