import { useCallback } from "react";

import { handleResponseError } from "../../../api/axiosClient";
import { useApiContext } from "../../../api/context";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";

type LinkMpaProps = {
  accountNumber: string;
  name: string;
  rootEmail?: string;
};

export const linkMpaUrl = "/v1/amazon-web-services/master-payer-accounts/link-mpa";

/**
 * Links a master payer account to sauron.
 */
export const useLinkMpa = ({ onError }: { onError?: (error: any) => void } = {}) => {
  const api = useApiContext();
  const snackbar = useSnackbar();

  return useCallback(
    async ({ accountNumber, name, rootEmail }: LinkMpaProps): Promise<void> => {
      if (!rootEmail) {
        const message = `Unable to link MPA to sauron as the customer has no root email configured.`;
        (onError ?? consoleErrorWithSentry)(new Error(`${message} Account number: ${accountNumber}, name: ${name}`));
        snackbar.onOpen({
          message,
          variant: "error",
          autoHideDuration: 8000,
          withClose: true,
        });
        return;
      }

      try {
        await api.post(linkMpaUrl, {
          account_number: accountNumber,
          name,
          email: rootEmail,
        });
        snackbar.onOpen({
          message: "Successfully linked MPA to Sauron",
          variant: "success",
          autoHideDuration: 5000,
          withClose: true,
        });
      } catch (error: any) {
        handleResponseError(error, () => {
          snackbar.onOpen({
            message: "Failed to link MPA to Sauron",
            variant: "error",
            autoHideDuration: 5000,
            withClose: true,
          });
        });
      }
    },
    [api, onError, snackbar]
  );
};
