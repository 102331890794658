import { Stack, Typography } from "@mui/material";

export const ThreadsOverviewNoFilterResults = () => (
  <Stack alignItems="center" my="70px" spacing={2}>
    <Typography fontWeight={500} fontSize="24px" lineHeight="32px">
      No threads found
    </Typography>
    <Typography fontSize="16px" lineHeight="24px">
      Your filter(s) did not return any results. Please try changing the filter criteria.
    </Typography>
  </Stack>
);
