import { useCallback } from "react";

import { AssetModel, CustomerModel, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { consoleErrorWithSentry } from "../../../utils";
import { serverTimestamp, TimestampFromDate } from "../../../utils/firebase";
import accountNumberExists from "../accountNumberExists";
import useGoogleGroup from "../hooks/useGoogleGroup";
import usePortfolioShare from "../hooks/usePortfolioShare";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { type MasterPayerAccountActivationModel } from "./types";
import { useLinkMpa } from "./useLinkMpa";
import { useMPAActivationSnackbar } from "./useMPAActivationSnackbar";
import { useUpdateAssets } from "./useMPAUpdateAssets";

async function getCustomerPrimaryDomain(customerId: string): Promise<string | null> {
  try {
    const customer = await getCollection(CustomerModel).doc(customerId).get();
    return customer.asModelData()?.primaryDomain ?? null;
  } catch (ex) {
    consoleErrorWithSentry(ex);
    return null;
  }
}

async function updateMasterPayerAccount(
  masterPayerAccount: MasterPayerAccountsModelSnapshot,
  mpaUpdateData: Partial<MasterPayerAccountsModel>
): Promise<boolean> {
  try {
    if (
      mpaUpdateData.accountNumber &&
      (await accountNumberExists(mpaUpdateData.accountNumber, masterPayerAccount.id))
    ) {
      return false;
    }
    await masterPayerAccount.ref.update({ ...mpaUpdateData, lastUpdated: serverTimestamp() });

    const batch = getBatch();

    if (mpaUpdateData.accountNumber) {
      // clear all previous AWS standalone assets for this MPA [CMP-16883]
      const assetsSnapshot = await getCollection(AssetModel)
        .where("type", "==", "amazon-web-services-standalone")
        .where("properties.organization.payerAccount.id", "==", mpaUpdateData.accountNumber)
        .get();
      assetsSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // clear all previous AWS standalone cloud connects for this customer and MPA [CMP-16883]
      const cloudConnectSnapshot = await getCollection(CustomerModel)
        .doc(masterPayerAccount.customerId)
        .collection("cloudConnect")
        .where("type", "==", "amazon-web-services-standalone")
        .where("accountId", "==", mpaUpdateData.accountNumber)
        .get();
      cloudConnectSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }

    await batch.commit();
  } catch (ex) {
    consoleErrorWithSentry(ex);
    return false;
  }

  return true;
}

async function activateMasterPayerAccount(
  masterPayerAccount: MasterPayerAccountsModelSnapshot,
  mpaActivationData: Partial<MasterPayerAccountActivationModel>
): Promise<boolean> {
  const mpaActivateUpdateData: Partial<MasterPayerAccountsModel> = {
    ...mpaActivationData,
    status: "active",
  };
  if (masterPayerAccount.status === "pending") {
    mpaActivateUpdateData.expectedOnboardingDate = TimestampFromDate(DateTime.utc().toJSDate());
  }

  const customerPrimaryDomain = await getCustomerPrimaryDomain(masterPayerAccount.customerId);
  if (customerPrimaryDomain !== null) {
    mpaActivateUpdateData.domain = customerPrimaryDomain;
  }

  return updateMasterPayerAccount(masterPayerAccount, mpaActivateUpdateData);
}

/** if the MPA belongs to a customer that already has flexsave AWS enabled,
 *  we need to set the payer config for it, and send a notification */
async function sendMPAActivatedEvent(api: AxiosInstance, masterPayerAccountNumber: string) {
  try {
    await api.request({
      method: "post",
      url: `/v1/flexsave/payers/mpaActivated/${masterPayerAccountNumber}`,
    });
  } catch (ex) {
    consoleErrorWithSentry(ex);
  }
}

export function usePartialMPAActivationRequest(masterPayerAccount: MasterPayerAccountsModelSnapshot) {
  return useCallback(
    async (mpaActivationData: Partial<MasterPayerAccountActivationModel>): Promise<boolean> => {
      if (masterPayerAccount.status === "active") {
        return true; // DO NOT partial update if the MPA is active
      }
      return updateMasterPayerAccount(masterPayerAccount, mpaActivationData);
    },
    [masterPayerAccount]
  );
}

export function useMPAActivationRequest(masterPayerAccount: MasterPayerAccountsModelSnapshot) {
  const { showActivationSuccessfulSnackbar, showActivationFailedSnackbar } = useMPAActivationSnackbar();
  const api = useApiContext();
  const linkMpa = useLinkMpa();
  const updateAssets = useUpdateAssets();
  const { updateGoogleGroup } = useGoogleGroup();
  const { shareAWSPortfolio } = usePortfolioShare();

  return useCallback(
    async (mpaActivationData: MasterPayerAccountActivationModel): Promise<boolean> => {
      const success = await activateMasterPayerAccount(masterPayerAccount, mpaActivationData);
      if (success) {
        linkMpa({
          accountNumber: mpaActivationData.accountNumber,
          name: masterPayerAccount.name,
          rootEmail: mpaActivationData.rootEmail,
        });
        updateAssets(mpaActivationData.accountNumber);
        showActivationSuccessfulSnackbar();
        sendMPAActivatedEvent(api, mpaActivationData.accountNumber);

        // update google group if root email has changed
        if (
          masterPayerAccount?.rootEmail &&
          mpaActivationData.rootEmail &&
          masterPayerAccount?.rootEmail !== mpaActivationData.rootEmail
        ) {
          updateGoogleGroup(masterPayerAccount?.rootEmail, {
            domain: masterPayerAccount.domain,
            rootEmail: mpaActivationData.rootEmail,
          });
        }

        // update portfolio share if account number has changed
        if (mpaActivationData.accountNumber && masterPayerAccount?.accountNumber !== mpaActivationData.accountNumber) {
          shareAWSPortfolio(mpaActivationData.accountNumber);
        }
      } else {
        showActivationFailedSnackbar();
      }
      return success;
    },
    [
      masterPayerAccount,
      linkMpa,
      updateAssets,
      showActivationSuccessfulSnackbar,
      api,
      updateGoogleGroup,
      shareAWSPortfolio,
      showActivationFailedSnackbar,
    ]
  );
}
