import { useEffect, useState } from "react";

export const useStateWithStorage = <T>(key: string, storage: Storage, initialValue?: T): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    try {
      const sessionStorageValue = storage.getItem(key);
      if (typeof sessionStorageValue !== "string") {
        storage.setItem(key, JSON.stringify(initialValue));
        return initialValue;
      } else {
        return JSON.parse(sessionStorageValue || "null");
      }
    } catch {
      return initialValue;
    }
  });

  useEffect(() => {
    const serializedState = JSON.stringify(state);
    storage.setItem(key, serializedState);
  });

  return [state, setState];
};

export const useSessionStorage = <T>(key: string, initialValue?: T): [T, (value: T) => void] =>
  useStateWithStorage(key, sessionStorage, initialValue);

export const useLocalStorage = <T>(key: string, initialValue?: T): [T, (value: T) => void] =>
  useStateWithStorage(key, localStorage, initialValue);
