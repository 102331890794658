import { useMemo, useState } from "react";

import { Alert, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { type DateTime } from "luxon";

import { alertTexts } from "../../../../assets/texts";
import { TrialContactUsDialog } from "../../../../Components/Trial/TrialContactUsDialog/TrialContactUsDialog";
import { trialEndText } from "./trilaEndUtils";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

type Props = {
  trialEndDate: DateTime;
  showShortMessage: boolean;
};

export const TrialEndAlert = ({ trialEndDate, showShortMessage }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const [learnMoreDialogOpen, setLearnMoreDialogOpen] = useState<boolean>(false);

  const text = useMemo(() => trialEndText(trialEndDate, showShortMessage), [showShortMessage, trialEndDate]);

  return (
    <>
      {open && (
        <Alert
          classes={{
            root: classes.root,
          }}
          onClose={() => {
            setOpen(false);
          }}
          severity="info"
        >
          {text}
          <Button
            classes={{
              root: classes.root,
            }}
            color="inherit"
            variant="text"
            onClick={() => {
              setLearnMoreDialogOpen(true);
            }}
          >
            {alertTexts.LEARN_MORE}
          </Button>
        </Alert>
      )}
      <TrialContactUsDialog
        open={learnMoreDialogOpen}
        onConfirm={() => {
          setLearnMoreDialogOpen(false);
        }}
        onCancel={() => {
          setLearnMoreDialogOpen(false);
        }}
      />
    </>
  );
};
