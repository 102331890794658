import { type MouseEventHandler } from "react";

import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export type EditKnownIssueButtonProps = {
  onClick: MouseEventHandler;
};

export const EditKnownIssueButton = ({ onClick }: EditKnownIssueButtonProps) => (
  <Tooltip title="Edit Issue">
    <IconButton onClick={onClick} aria-label="Edit Issue" size="large">
      <EditIcon />
    </IconButton>
  </Tooltip>
);
