import { useHistory } from "react-router-dom";

import useMountEffect from "../../Components/hooks/useMountEffect";

const ProductWorkshop = () => {
  const history = useHistory();
  useMountEffect(() => {
    window.open("https://app.livestorm.co/doit", "_blank", "noopener");
  });
  history.goBack();
  return null;
};
export default ProductWorkshop;
