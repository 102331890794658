export const texts = {
  VENDOR_COMMITMENT: "Vendor commitment",
  NO_VENDOR_COMMITMENT: "No vendor commitment",
  NO_VENDOR_COMMITMENT_DATA: "Vendor contract details needed",
  HARD_COMMIT: "Hard Commit",
  SOFT_COMMIT: "Soft Commit",
  ON_DEMAND: "On-demand",
  COMMITMENT: "Commitment",
  DOIT_ONLY: "DoiT only",
  DOWNLOAD_CONTRACT: "Download signed contract",
  VIEW_CONTRACT: "View contract",
  EDIT_CONTRACT: "Edit contract",
  DOWNLOAD_VENDOR_CONTRACT: "Download vendor contract",
  VIEW_VENDOR_CONTRACT: "View vendor contract",
  UNASSIGNED: "Unassigned",
  UNKNOWN: "Unknown",
  NA: "N/A",
  CANCEL_CONTRACT: "Cancel contract",
};
