import { firestoreTimestampFilterProps } from "../../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import { assetTypeName } from "../../../../utils/common";
import { type InvoiceTableRow } from "./types";

const itemsDescriptions: FilterTableItemDescriptor<InvoiceTableRow>[] = [
  {
    headerText: "Cloud",
    value: "type",
    filterType: "string",
    filterOptions: {
      toOption: (value) => ({
        value,
        label: assetTypeName(value),
      }),
    },
  },
  {
    headerText: "Billing profile",
    value: "entity.name",
    filterType: "string",
  },
  {
    headerText: "Details",
    value: "details",
    filterType: "string",
  },
  {
    headerText: "Total",
    value: "total",
    filterType: "number",
  },
  {
    headerText: "Status",
    value: "status",
    filterType: "string",
  },
  {
    headerText: "Created at",
    value: "timestamp",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    headerText: "Issued at",
    value: "issuedAt",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    headerText: "Canceled at",
    value: "canceledAt",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
