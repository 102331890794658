import { BudgetNotificationModel } from "@doitintl/cmp-models";
import { getCollectionGroup } from "@doitintl/models-firestore";

import { InAppNotificationType, type Mapper } from "../types";

export class Budget implements Mapper<BudgetNotificationModel> {
  readonly type = InAppNotificationType.Budget;

  static color = "#FDD5E0";

  getCollection: Mapper<BudgetNotificationModel>["getCollection"] = (
    customerRef,
    startTime,
    userEmail,
    isDoitEmployee
  ) => {
    const col = getCollectionGroup(BudgetNotificationModel)
      .where("customer", "==", customerRef)
      .where("alertDate", ">", startTime)
      .orderBy("alertDate", "desc");

    if (!isDoitEmployee) {
      return col.where("recipients", "array-contains", userEmail);
    }

    return col;
  };

  userHasPermission: Mapper<BudgetNotificationModel>["userHasPermission"] = (userRoles) =>
    userRoles?.budgetsManager ?? false;

  toNotification: Mapper<BudgetNotificationModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    let title = "";
    let description = "";

    const budgetType: string = docData.type;

    if (budgetType === "threshold") {
      title = `${docData.name} ${docData.alertPercentage}% threshold met`;
      description = `You've met the ${docData.currencySymbol}${docData.alertAmount} threshold.`;
    } else if (budgetType === "forecast") {
      if (!docData.forecastedDate) {
        throw new Error(`forecastedDate is required for forecasted budget notifications doc: ${docSnap.ref.path}`);
      }
      title = `${docData.name} forecast updated`;
      description = `We estimate you'll hit 100% of this budget on ${docData.forecastedDate.toDate().toLocaleDateString()}.`;
    } else {
      throw new Error(`Unknown budget notification type: ${budgetType} doc: ${docSnap.ref.path}`);
    }

    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.Budget,
      created: docData.created,
      updated: docData.alertDate.seconds === docData.created.seconds ? undefined : docData.alertDate,
      title,
      description,
      urlPath: `/customers/${docData.customer.id}/analytics/budgets/${docData.budgetId}`,
      color: Budget.color,
    };
  };
}
