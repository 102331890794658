import { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";

import { compose } from "recompose";

/**
 * This is the old "Edit Billing Profile" component.
 *  Everything is moved to EditEntityPage and EditBillingProfileForm
 *
 * This page exists so people with old bookmarks will have correct redirects and no broken links.
 */
class Entity extends Component {
  render() {
    return (
      <Redirect
        to={`/customers/${this.props.match.params.customerId}/entities/${this.props.match.params.entityId}/edit`}
      />
    );
  }
}
export default compose(withRouter)(Entity);
