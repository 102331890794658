import { type CurrencyCode } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { type ExplainerRowType, type ExplainerSummary, type ExplainerType } from "../../../../types";
import { ExplainerRow } from "./ExplainerRow";
import { summaryDescriptor } from "./SummaryDescriptor";
import { SupportChargesTable } from "./SupportChargesTable";

type Props = {
  type: ExplainerType;
  details: ExplainerSummary;
  currency: CurrencyCode;
};

const getRowsData: (type: string) => Record<string, ExplainerRowType> = (type) => ({
  servicesTotal: {
    label: "Service charges",
    childrenProp: "serviceCharges",
    children: {
      usage: { label: "Usage", tooltip: summaryDescriptor.common.usage },
      savingsPlanUsage: {
        label: "Savings Plans covered usage",
        tooltip: summaryDescriptor[type].details.serviceCharges.savingsPlanUsage,
      },
      flexsaveCoveredUsage: {
        label: "Flexsave covered usage",
        tooltip: summaryDescriptor.doit.details.serviceCharges.flexsaveCoveredUsage,
      },
      savingsPlanUpfrontFee: {
        label: "Savings Plan upfront fee",
        tooltip: summaryDescriptor[type].details.savings.planNegation,
      },
    },
  },
  supportChargesTotal: {
    label: "Support charges",
    tooltip: summaryDescriptor.common.details.supportChargesTotal,
  },
  credits: {
    label: "Credits",
    tooltip: summaryDescriptor.common.details.credits,
  },
  discountTotal: {
    label: "Discounts",
    childrenProp: "discounts",
    children: {
      privateRate: {
        label: "Private Rate Card discount",
        tooltip: summaryDescriptor.common.details.discounts.privateRate,
      },
      bundled: { label: "Bundled discount", tooltip: summaryDescriptor.common.details.discounts.bundled },
      edp: { label: "Enterprise Discount Program discount", tooltip: summaryDescriptor.common.details.discounts.edp },
      spp: { label: "Solution Provider Program discount", tooltip: summaryDescriptor.aws.details.discounts.spp },
    },
  },
  savingsTotal: {
    label: "Savings",
    childrenProp: "savings",
    children: {
      flexsave: { label: "Flexsave Savings", tooltip: summaryDescriptor.doit.details.savings.flexsave },
      planNegation: { label: "Savings plans negation", tooltip: summaryDescriptor[type].details.savings.planNegation },
      reservationAppliedUsage: {
        label: "Reservation applied usage",
        tooltip: summaryDescriptor.common.details.savings.reservationAppliedUsage,
      },
    },
  },
  refunds: {
    label: "Refunds",
    tooltip: summaryDescriptor.common.details.refunds,
  },
  otherChargesTotal: {
    label: "Other charges",
    childrenProp: "otherCharges",
    children: {
      ocb: { label: "Other out-of-cycle charges", tooltip: summaryDescriptor.common.details.otherCharges.ocb },
      savingsPlanRecurringFee: {
        label: "Savings Plans recurring fee",
        tooltip: summaryDescriptor[type].details.otherCharges.savingsPlanRecurringFee,
      },
      flexsave: { label: "Flexsave charges", tooltip: summaryDescriptor.doit.details.otherCharges.flexsave },
      reservationRecurringFee: {
        label: "Recurring reservation fee",
        tooltip: summaryDescriptor.common.details.otherCharges.reservationRecurringFee,
      },
    },
  },
  tax: {
    label: "Tax",
    tooltip: summaryDescriptor.common.details.tax,
  },
  total: {
    label: "Total",
  },
});

export function InvoiceExplainer({ type, details, currency }: Readonly<Props>) {
  const rows = getRowsData(type);
  return (
    <Box>
      {Object.entries(rows).map(([key, { label, childrenProp, children, tooltip }]) => (
        <ExplainerRow
          key={key}
          title={label}
          value={details[key]}
          isLastRow={key === "total"}
          variant={key === "total" ? "subtitle1" : "body1"}
          fontWeight={key === "total" ? 500 : 400}
          tooltip={tooltip}
          currency={currency}
          type={type}
        >
          {(children &&
            Object.entries(children).map(([childKey, { label, tooltip }]) => (
              <ExplainerRow
                key={childKey}
                title={label}
                value={details[childrenProp!][childKey]}
                isChild={true}
                tooltip={tooltip}
                currency={currency}
                type={type}
              />
            ))) ||
            (key === "supportChargesTotal" && details.supportChargesTotal > 0 && (
              <SupportChargesTable items={details.supportCharges} reduced={type === "aws"} currency={currency} />
            ))}
        </ExplainerRow>
      ))}
    </Box>
  );
}
