import { useCallback } from "react";

import {
  type NotificationAudienceType,
  NotificationProviderType,
  type NotificationSettings,
} from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";
import { Box } from "@mui/material";

import { NotificationsForm } from "../../../../Components/Notifications/NotificationsForm";
import { useNotificationDescriptors } from "../../../../Components/Notifications/useNotificationDescriptors";
import { useUserContext } from "../../../../Context/UserContext";
import { WizardTitle } from "./components/WizardTitle";
import { NotificationsFormObserver } from "./NotificationsFormObserver";
import { type ChannelConfig } from "./types";

type Props = {
  updateChannelConfig: <K extends keyof ChannelConfig>(key: K, value: ChannelConfig[K]) => void;
  channelConfig?: ChannelConfig;
  forAudience: NotificationAudienceType;
  handleValidationStatus: (isValid: boolean) => void;
};

export const NotificationsFormStep = ({
  channelConfig,
  updateChannelConfig,
  forAudience,
  handleValidationStatus,
}: Props) => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const providerType = channelConfig?.providerType || NotificationProviderType.SLACK;

  const [notificationDescriptors] = useNotificationDescriptors(forAudience, providerType, userRoles.permissions);

  const handleNotificationSettingsChange = useCallback(
    (notificationSettings: ModelData<NotificationSettings>) => {
      updateChannelConfig("selectedNotifications", notificationSettings);
    },
    [updateChannelConfig]
  );

  return (
    <Box width="100%">
      <WizardTitle title="Select notifications" description="Choose which notifications you want to receive" />
      <NotificationsForm
        key="notification_form"
        notificationDescriptors={notificationDescriptors}
        selectedNotifications={channelConfig?.selectedNotifications || {}}
        disableTargetUpdate={channelConfig?.disableTargetUpdate}
        onSubmit={async () => {}}
        handleValidationStatus={handleValidationStatus}
      >
        <NotificationsFormObserver onNotificationSettingsChange={handleNotificationSettingsChange} />
      </NotificationsForm>
    </Box>
  );
};
