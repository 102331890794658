import { type TagOptions } from "./types";

export const CustomerNoteTagsOptions: TagOptions[] = [
  {
    key: "collection",
    label: "Collection",
  },
  {
    key: "sales",
    label: "Sales",
  },
  {
    key: "engineering",
    label: "Engineering",
  },
  {
    key: "legal",
    label: "Legal",
  },
  {
    key: "flexsave",
    label: "Flexsave",
  },
  {
    key: "payment",
    label: "Payments",
  },
  {
    key: "reminder",
    label: "Reminder",
  },
];
