import { type CloudSelectorType, ProductEnum, SaaSConsoleType } from "@doitintl/cmp-models";

export const saasToProductCloudType = (cloudType: CloudSelectorType): ProductEnum => {
  switch (cloudType) {
    case SaaSConsoleType.GCP:
      return ProductEnum.GoogleCloud;
    case SaaSConsoleType.AWS:
      return ProductEnum.AmazonWebServices;
    case SaaSConsoleType.AZURE:
      return ProductEnum.MicrosoftAzure;
    default:
      return ProductEnum.Other;
  }
};

export const ProductCloudToSaaSType = (provider: ProductEnum): CloudSelectorType => {
  switch (provider) {
    case ProductEnum.GoogleCloud:
      return SaaSConsoleType.GCP;
    case ProductEnum.AmazonWebServices:
      return SaaSConsoleType.AWS;
    case ProductEnum.MicrosoftAzure:
    default:
      return SaaSConsoleType.AZURE;
  }
};
