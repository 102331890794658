import { jobFunction } from "../../../utils/common";

export const text = {
  TITLE: "Bulk update users",
  CANCEL_ACTION: "Cancel",
  UPDATE_ACTION: "Update",
  JOB_FUNCTION_LABEL: "Job Function",
  ROLE_LABEL: "Role",
  NOTIFICATIONS_SETTINGS_LABEL: "Notifications",
  DEFAULT_DASHBOARD_LABEL: "Default Dashboard",
  ALL_USERS_MUST_HAVE_ROLE_TOOLTIP:
    "Some of the users that have been selected haven't been assigned a role yet. therefore, you must assign a role to proceed.",
  CONFIRM_DELETE_BUTTON_TEXT: "Confirm",
  DELETE_BUTTON_TEXT: "Delete",
};

export const testIds = {
  JOB_FUNCTION: "job_function",
};

export const DONT_UPDATE_OPTION_LABEL = "Don't update";

export const jobFunctionsOptions = [
  ...jobFunction,
  {
    value: 0,
    name: DONT_UPDATE_OPTION_LABEL,
  },
] as const;
