import { useEffect, useState } from "react";

import { useMarketplaceContext } from "../../Context";
import { type entitlementsTableRowType } from "../../types";
import { constructTableData } from "./constructTableData";

export function useAWSEntitlementsTableData() {
  const { accountsAndCustomers } = useMarketplaceContext();
  const [AWSEntitlementsTableData, setAWSEntitlementsTableData] = useState<entitlementsTableRowType[]>([]);

  useEffect(() => {
    setAWSEntitlementsTableData(constructTableData(accountsAndCustomers)); // for now taking the same data as for the accounts
  }, [accountsAndCustomers]);

  return AWSEntitlementsTableData;
}
