import {
  type ComponentType,
  createContext,
  isValidElement,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { type AlertColor } from "@mui/material/Alert";
import noop from "lodash/noop";
import startCase from "lodash/startCase";
import { getDisplayName } from "recompose";

import { autoHideDuration as defaultAutoHideDuration } from "../../constants";
import SharedSnackbar from "./SharedSnackbar";
import { appSnackbarDefaults, type SharedSnackbarProps } from "./types";

export type OnOpenSharedSnackbar = (data: SharedSnackbarProps) => void;
export type OnOpenTemplateSnackbar = (message: string | ReactNode) => void;

export type OnCloseSharedSnackbar = (event?: any, reason?: string) => void;

export type SharedSnackbarContextProps = {
  onOpen: OnOpenSharedSnackbar;
  onClose: OnCloseSharedSnackbar;
};

const SharedSnackbarContext = createContext<SharedSnackbarContextProps>({
  onOpen: noop,
  onClose: noop,
});

export const useSnackbar = () => useContext(SharedSnackbarContext);

const useSnackbarTemplate = (
  variant: AlertColor,
  autoHideDuration = defaultAutoHideDuration
): OnOpenTemplateSnackbar => {
  const snackbar = useSnackbar();
  return useCallback(
    (message: ReactNode) => {
      snackbar.onOpen({
        message,
        variant,
        autoHideDuration,
        withClose: true,
      });
    },
    [snackbar, variant, autoHideDuration]
  );
};

const secondsToMilliseconds = (seconds?: number) => (seconds ? seconds * 1000 : undefined);

export const useErrorSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("error", secondsToMilliseconds(autoHideAfterSeconds));

export const useSuccessSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("success", secondsToMilliseconds(autoHideAfterSeconds));

export const useInfoSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("info", secondsToMilliseconds(autoHideAfterSeconds));

export const useWarningSnackbar = (autoHideAfterSeconds?: number) =>
  useSnackbarTemplate("warning", secondsToMilliseconds(autoHideAfterSeconds));

type SnackbarProviderProps = {
  children?: ReactNode;
};

const useSharedSnackbar = () => {
  const [queue, setQueue] = useState<Array<SharedSnackbarProps>>([]);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState<undefined | SharedSnackbarProps>(undefined);

  useEffect(() => {
    if (!queue.length) {
      return;
    }

    if (!snackbarData) {
      // Set a new snack when we don't have an active one
      setSnackbarData({ ...queue[0] });
      setQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [queue, snackbarData, open]);

  const handleOpen = useCallback((options: SharedSnackbarProps) => {
    if (!options?.message || (typeof options.message === "object" && !isValidElement(options.message))) {
      options.message = startCase(options.variant || "");
    }

    setQueue((prev) => [...prev, { ...appSnackbarDefaults, ...options, key: new Date().getTime() }]);
  }, []);

  const handleClose = useCallback((_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  }, []);

  const handleExited = () => {
    setSnackbarData(undefined);
  };

  const sharedSnackbar = useMemo(
    () => (
      <SharedSnackbar
        TransitionProps={{ onExited: handleExited }}
        open={open}
        {...snackbarData}
        key={snackbarData ? snackbarData.key : undefined}
      />
    ),
    [open, snackbarData]
  );

  return useMemo(
    () => ({
      handleOpen,
      handleClose,
      sharedSnackbar,
    }),
    [handleClose, handleOpen, sharedSnackbar]
  );
};
export const SharedSnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const { handleOpen, handleClose, sharedSnackbar } = useSharedSnackbar();
  const value = useMemo(() => ({ onOpen: handleOpen, onClose: handleClose }), [handleClose, handleOpen]);
  return (
    <SharedSnackbarContext.Provider value={value}>
      {children}
      {sharedSnackbar}
    </SharedSnackbarContext.Provider>
  );
};

export const SharedSnackbarProviderForTesting = ({
  children,
  value,
}: SnackbarProviderProps & { value?: Partial<SharedSnackbarContextProps> }) => {
  const { handleOpen, handleClose, sharedSnackbar } = useSharedSnackbar();
  const { onClose, onOpen } = value ?? {};
  const valueMemo = useMemo(
    () => ({ onOpen: onOpen ?? handleOpen, onClose: onClose ?? handleClose }),
    [handleClose, handleOpen, onClose, onOpen]
  );
  return (
    <SharedSnackbarContext.Provider value={valueMemo}>
      {children}
      {sharedSnackbar}
    </SharedSnackbarContext.Provider>
  );
};

export const SharedSnackbarConsumer = SharedSnackbarContext.Consumer;

export type WithSnackbar = {
  showSnackbar: OnOpenSharedSnackbar;
  hideSnackbar: OnCloseSharedSnackbar;
};

type Props = SharedSnackbarContextProps & WithSnackbar;

export const withSnackbar = <P extends object>(Component: ComponentType<P & Props>) => {
  const WrappedComponent = (props: P) => (
    <SharedSnackbarConsumer>
      {(context) => <Component {...context} showSnackbar={context.onOpen} hideSnackbar={context.onClose} {...props} />}
    </SharedSnackbarConsumer>
  );

  WrappedComponent.displayName = `withSnackbar(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
};
