import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import { MPAActivationDialog } from "./MPAActivationDialog";
import { useMPAActivationRequest, usePartialMPAActivationRequest } from "./useMPAActivationRequest";
import { useMPAPrePopulate } from "./useMPAPrePopulate";
import { useMPAValidationRequest } from "./useMPAValidationRequest";

type MPAActivationDialogHOCProps = {
  masterPayerAccount: MasterPayerAccountsModelSnapshot;
  onClose: () => void;
};

export const MPAActivationDialogHOC = ({ masterPayerAccount, onClose }: MPAActivationDialogHOCProps) => {
  const onPartialSubmit = usePartialMPAActivationRequest(masterPayerAccount);
  const onSubmit = useMPAActivationRequest(masterPayerAccount);
  const onValidate = useMPAValidationRequest();

  const prePopulatedMasterPayerAccount = useMPAPrePopulate(masterPayerAccount, onPartialSubmit);

  return (
    <MPAActivationDialog
      masterPayerAccount={prePopulatedMasterPayerAccount}
      masterPayerAccountId={masterPayerAccount.id}
      onClose={onClose}
      onPartialSubmit={onPartialSubmit}
      onSubmit={onSubmit}
      onValidate={onValidate}
    />
  );
};
