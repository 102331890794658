import { CustomerModel, IntegrationModel, SaaSConsoleType } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export function getSaaSConsoleTypeFullName(type: SaaSConsoleType) {
  return type === SaaSConsoleType.AWS ? "amazon-web-services" : "google-cloud";
}

export const onboardingDocRef = (customerId: string, type: SaaSConsoleType) =>
  getCollection(IntegrationModel)
    .doc("billing-standalone")
    .collection("standaloneOnboarding")
    .doc(`${getSaaSConsoleTypeFullName(type)}-${customerId}`);

export const customerDocRef = (customerId: string) => getCollection(CustomerModel).doc(customerId);
