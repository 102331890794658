import { Badge, type BadgeProps, Tooltip } from "@mui/material";

import { type AwsAccount, AwsAccountStatusType } from "../../../data/aws-account-api";

const badgeColor: Record<AwsAccountStatusType, BadgeProps["color"]> = {
  [AwsAccountStatusType.SCHEDULED]: "primary",
  [AwsAccountStatusType.IN_PROGRESS]: "info",
  [AwsAccountStatusType.INCOMPLETE]: "warning",
  [AwsAccountStatusType.COMPLETE]: "success",
  [AwsAccountStatusType.FAILED]: "error",
};

const badgeStyles = {
  whiteSpace: "nowrap",
  ".MuiBadge-standard": {
    transform: "scale(1) translate(100%, -50%)",
  },
};

const statusesWithProgress = [
  AwsAccountStatusType.FAILED,
  AwsAccountStatusType.IN_PROGRESS,
  AwsAccountStatusType.INCOMPLETE,
];

export const StatusCell = ({
  row: {
    statusDescriptor: { status, completion },
    activeCommand,
  },
}: {
  row: AwsAccount;
}) => {
  let label: string = status;

  if (statusesWithProgress.includes(status)) {
    label += ` (${Math.round(completion * 100)}%)`;
  }

  const badge = <Badge badgeContent={label} color={badgeColor[status]} sx={badgeStyles} />;

  if (status === AwsAccountStatusType.FAILED) {
    return <Tooltip title={activeCommand?.errorMessage}>{badge}</Tooltip>;
  }
  return badge;
};
