import { useEffect, useState } from "react";

import { AccountManagerCompanies } from "@doitintl/cmp-models";

import { type KeyContactDetails } from "../../../Components/KeyContact/KeyContact";
import { AccountManagersHooks } from "../../../Context/customer/AccountManagers";
import { getAccountTeamRole } from "../../../utils/common";

const AccountManagerCompaniesEnum = {
  [AccountManagerCompanies.GCP]: "Google",
  [AccountManagerCompanies.AWS]: "Amazon",
};

export function useKeyContacts() {
  const accountManagers = AccountManagersHooks.useAccountManagersForCustomer();
  const [roles] = AccountManagersHooks.useAccountManagersRoles();
  const [keyContacts, setKeyContacts] = useState<(KeyContactDetails | null)[]>([null, null, null]);

  useEffect(() => {
    if (accountManagers && roles?.length) {
      setKeyContacts(
        accountManagers.map((accountManager): KeyContactDetails => {
          const { company, email, name, photoURL } = accountManager;
          const companyName = AccountManagerCompaniesEnum[company];

          let job = getAccountTeamRole(roles, accountManager);

          if (companyName) {
            job += ` at ${companyName}`;
          }
          return {
            email,
            job,
            name,
            avatarURL: photoURL ?? undefined,
          };
        })
      );
    }
  }, [accountManagers, roles]);

  return keyContacts;
}
