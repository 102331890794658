import { type TimeInterval } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

export const INVALID_DATE_ERROR_MSG = "Invalid Date";
export const DATE_RANGE_FORMAT = "LLL d, yyyy";
export interface IDateRange {
  start: DateTime | null;
  end: DateTime | null;
}

export const validateDate = (strDate: string) => {
  if (!strDate) {
    return { date: null, isValid: false };
  }

  try {
    const date = DateTime.fromFormat(strDate, DATE_RANGE_FORMAT);
    return { date, isValid: date.isValid && date < DateTime.utc() };
  } catch (e) {
    return { date: null, isValid: false };
  }
};

export const isDateBetween = (start: DateTime, end: DateTime, date: DateTime) => start < date && date < end;
export const isDateBefore = (start: DateTime | null, end: DateTime | null) => (start && end ? start <= end : false);
export const isSameDate = (date1: DateTime, date2?: DateTime | null) =>
  date1.day === date2?.day && date1.month === date2?.month && date1.year === date2?.year;

export enum DateRangePhase {
  START,
  END,
  NONE,
}

const handleStartAndEndChange = (start: DateTime, end: DateTime, date: DateTime, phase: DateRangePhase) => {
  let updatedState;
  const isBetween = isDateBetween(start, end, date);
  switch (phase) {
    case DateRangePhase.NONE:
    case DateRangePhase.END:
      phase = DateRangePhase.START;
      if (isBetween || date < start) {
        updatedState = { end, start: date };
      } else if (date > end) {
        updatedState = { start: date, end: null };
      }
      break;
    case DateRangePhase.START:
      phase = DateRangePhase.END;
      if (isBetween || date > end) {
        updatedState = { start, end: date };
      } else if (date < start) {
        updatedState = { start: date, end: null };
      }
      break;
  }
  return {
    updatedState,
    phase,
  };
};

export const handleDateRangeChange = (
  dateRange: IDateRange,
  date: DateTime,
  dateRangePhase: DateRangePhase
): { updatedState: IDateRange; phase: DateRangePhase } => {
  const { start, end } = dateRange;
  let updatedState;
  let phase = dateRangePhase;
  if (!start) {
    phase = DateRangePhase.START;
    updatedState = { start: date, end: null };
  }
  if (start) {
    if (end) {
      if (isSameDate(start, date) || isSameDate(end, date)) {
        phase = DateRangePhase.START;
        updatedState = { start: date, end: null };
      } else {
        const change = handleStartAndEndChange(start, end, date, phase);
        updatedState = change.updatedState;
        phase = change.phase;
      }
    } else {
      if (start <= date) {
        updatedState = { start, end: date };
        phase = DateRangePhase.END;
      } else {
        updatedState = { start: date, end: null };
        phase = DateRangePhase.START;
      }
    }
  }

  return {
    updatedState,
    phase,
  };
};

const getRangeOfTimeInterval = (timeInterval?: TimeInterval) => {
  if (!timeInterval) {
    return null;
  }
  const start = DateTime.utc().startOf(timeInterval);
  const end = DateTime.utc().endOf(timeInterval);
  return {
    start,
    end,
  };
};

export const isDateInRangeOfTimeInterval = (day: DateTime, timeInterval?: TimeInterval) => {
  const range = getRangeOfTimeInterval(timeInterval);
  if (range?.start && range?.end) {
    const { start, end } = range;
    return isDateBetween(start, end, day) || isSameDate(day, start) || isSameDate(day, end);
  } else {
    return undefined;
  }
};
