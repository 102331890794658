import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize-module-react";
import cloneDeep from "lodash/cloneDeep";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";

window.Quill = Quill;
Quill.debug("error");
Quill.register("modules/imageUploader", ImageUploader);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);

const BlockEmbed = Quill.import("blots/block/embed");
const CodeBlock = Quill.import("formats/code-block");
const Link = Quill.import("formats/link");
const Parchment = Quill.import("parchment");

// disable colored text in Quill because it breaks dark mode
const ColorAttributor = Quill.import("attributors/style/color");
const BackgroundStyle = Quill.import("attributors/style/background");
ColorAttributor.whitelist = [];
BackgroundStyle.whitelist = [];
Quill.register(ColorAttributor);
Quill.register(BackgroundStyle);

const block = Parchment.query("block");
block.tagName = "DIV";
Quill.register(block, true);

const modulesTemplate = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: {
    container: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block", "image", "link"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ direction: "rtl" }], // text direction
      [{ align: [] }],
      ["clean"],
      ["fullscreen"],
    ],
    handlers: {
      fullscreen: () => {},
    },
  },

  imageResize: {
    parchment: Quill.import("parchment"),
    // modules: ["Resize", "DisplaySize", "Toolbar"]
  },
  imageDropAndPaste: {
    // handler added in updateEditor
    handler: null,
  },
};

export function getModules({ uploadImgHandler, fullscreenEvent }) {
  const modules = cloneDeep(modulesTemplate);
  modules.toolbar.handlers.fullscreen = fullscreenEvent;
  // if bucketRef is supplied, use it for images. otherwise, use embedded images.
  if (uploadImgHandler) {
    modules.imageUploader = {
      upload: async (file) => await uploadImgHandler(file),
    };
  }
  return modules;
}

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create();
    node.setAttribute("src", value.url ? value.url : value);
    if (!node.getAttribute("width")) {
      node.setAttribute("width", value.width ? value.width : "500");
    }
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute("alt"),
      url: node.getAttribute("src"),
      width: node.getAttribute("width"),
    };
  }
}
ImageBlot.blotName = "image";
ImageBlot.tagName = "img";
Quill.register(ImageBlot);

class CustomLink extends Link {
  static create(value) {
    const node = super.create(value);
    node.setAttribute(
      "class",
      "MuiTypography-root MuiLink-root MuiLink-underlineAlways MuiTypography-colorInherit linkColor"
    );
    return node;
  }
}

Quill.register(CustomLink);

class CodeBlockBlot extends CodeBlock {
  static create() {
    const node = super.create();
    node.setAttribute("style", "white-space:pre-wrap");
    return node;
  }
}

CodeBlockBlot.blotName = "code-block";
CodeBlockBlot.tagName = "pre";

Quill.register(CodeBlockBlot);
