import { type JSX } from "react";

import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Container, MobileStepper, Stack, Step, StepLabel, Stepper } from "@mui/material";

import { useFullScreen } from "../../utils/dialog";
import { ButtonsFooter } from "../EKS/Footer/ButtonsFooter";

type Props = {
  stepsTitles: string[];
  activeStepIndex: number;
  step: JSX.Element;
  back: () => void;
  next: () => void;
  allowNext: boolean;
  isLastStep: boolean;
  formId?: string;
  loading?: boolean;
};

export const CustomStepper = ({
  stepsTitles,
  activeStepIndex,
  step,
  back,
  next,
  allowNext,
  isLastStep,
  formId,
  loading,
}: Props) => {
  const { isMobile } = useFullScreen();

  if (isMobile) {
    return (
      <Stack sx={{ pt: 4 }}>
        {step}
        <MobileStepper
          steps={stepsTitles.length}
          position="bottom"
          activeStep={activeStepIndex}
          variant="progress"
          nextButton={
            <LoadingButton
              color="primary"
              size="small"
              data-cy="next-button"
              onClick={() => {
                if (!formId) {
                  next();
                }
              }}
              key={formId}
              disabled={!allowNext}
              form={formId}
              loading={loading}
              type={formId ? "submit" : "button"}
            >
              {isLastStep ? "Finish" : "Next"}
              <KeyboardArrowRight />
            </LoadingButton>
          }
          backButton={
            <Button color="primary" size="small" data-cy="back-button" onClick={back} disabled={activeStepIndex === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Stack>
    );
  }
  return (
    <Stack sx={{ pt: 4 }}>
      <Container maxWidth="lg" sx={{ mt: 4, pb: 14 }}>
        <Stepper activeStep={activeStepIndex} alternativeLabel>
          {stepsTitles.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {step}
      </Container>

      <ButtonsFooter
        isLastStep={isLastStep}
        onBackClicked={back}
        onNextClicked={next}
        allowNext={allowNext}
        formId={formId}
        loading={loading}
      />
    </Stack>
  );
};
