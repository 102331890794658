import { type Dispatch } from "react";

import BackIcon from "@mui/icons-material/ArrowBackSharp";
import { Divider, Link, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Form, Formik } from "formik";

import { loginTexts } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useEmailAndPasswordSchema } from "../helpers";
import { useHandleSignIn } from "../hooks";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import AuthProviders from "./AuthProviders";

const EnterPasswordForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const handleSignIn = useHandleSignIn({ dispatch, state });
  const theme = useTheme();
  const EmailAndPasswordSchema = useEmailAndPasswordSchema();

  return (
    <Formik
      initialValues={{ email: state.email || "", password: state.password || "" }}
      validationSchema={EmailAndPasswordSchema}
      onSubmit={handleSignIn}
    >
      {({ errors, isSubmitting, values, handleChange, handleBlur, touched }) => (
        <Form>
          <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
            <AuthProviders loading={state.isLoading} />
            <Divider>
              <Typography variant="caption" align="center">
                {loginTexts.CAPTION_SIGN_IN}
              </Typography>
            </Divider>
            <Typography variant="body1">
              {`${loginTexts.SIGNING_IN_AS} `} <strong>{values.email}</strong>
            </Typography>
            <Link
              variant="body1"
              underline="hover"
              style={{
                cursor: "pointer",
                display: "flex",
                marginLeft: "-1%",
                marginBottom: theme.spacing(2),
              }}
              onClick={() => {
                dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmail" });
              }}
            >
              <BackIcon sx={{ mr: 1 }} />
              {loginTexts.GO_BACK_TO_EMAIL_ADDRESS}
            </Link>
            <TextField
              data-cy="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Password"
              label="Password"
              type="password"
              autoComplete={state.formType === "signup" ? "new-password" : "current-password"}
              margin="none"
              variant="outlined"
              inputRef={(input) => input?.focus()}
              fullWidth
              disabled={state.isLoading}
              error={touched.password && Boolean(errors.password)}
              helperText={
                <Typography
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  variant="caption"
                  onClick={() => {
                    dispatch({ type: LoginHookActionType.setFormType, formType: "forgotPassword" });
                  }}
                >
                  Forgot password?
                </Typography>
              }
            />
            <LoadingButton
              data-cy="login-cta"
              variant="contained"
              fullWidth
              type="submit"
              loading={state.isLoading}
              disabled={
                isSubmitting ||
                state.isLoading ||
                !values.email ||
                !values.password ||
                Boolean(errors.email) ||
                Boolean(errors.password)
              }
              mixpanelEventId="login.with-password"
            >
              {loginTexts.SIGN_IN}
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default EnterPasswordForm;
