import { type JSX } from "react";

import { type CloudFlowProvider } from "@doitintl/cmp-models";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Card, CardHeader, Chip, Stack, Typography } from "@mui/material";
import { type SanitizeElementHookEvent } from "dompurify";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { SafeHtml } from "../../../../Components/SafeHtml";
import { useApiProviderLogo } from "../Common/utils";

type Props = {
  highlightedTitle: string | JSX.Element;
  highlightedServiceName: string | JSX.Element;
  highlightedVersion: string | JSX.Element;
  provider: CloudFlowProvider;
  doc?: string;
  onClick: () => void;
};

const transformHtml = (node: Element, data: SanitizeElementHookEvent) => {
  if (data.tagName === "p") {
    node.setAttribute("style", "margin: 0");
  }
  if (data.tagName === "a") {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
  return node;
};

export const ClouflowAlgoliaSearchResultItem = ({
  highlightedTitle,
  doc,
  highlightedServiceName,
  highlightedVersion,
  provider,
  onClick,
}: Props) => {
  const isDarkTheme = useDarkThemeCheck();
  const providerLogo = useApiProviderLogo();
  const chipColor = isDarkTheme ? cmpBaseColors.backgroundDark : cmpBaseColors.lightBackground;

  return (
    <Card
      sx={(theme) => ({
        "& .MuiCardHeader-action": {
          opacity: 0,
        },
        "&:hover": {
          borderColor: theme.palette.primary.main,
          "& .MuiCardHeader-action": {
            opacity: 1,
          },
        },
      })}
      onClick={onClick}
    >
      <CardHeader
        sx={{
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
        avatar={<Avatar src={providerLogo(provider)} />}
        title={highlightedTitle}
        titleTypographyProps={{ variant: "body1", color: "text.primary" }}
        action={<ArrowForwardIcon />}
        subheader={
          <Stack mt={1} spacing={1}>
            <SafeHtml html={doc} transform={transformHtml} />
            <Stack direction="row" spacing={1}>
              <Chip
                size="small"
                sx={{ backgroundColor: chipColor, color: "text.primary" }}
                label={
                  <Typography variant="caption" noWrap display="flex">
                    Service:&nbsp;
                    {highlightedServiceName}
                  </Typography>
                }
              />
              <Chip
                size="small"
                sx={{ backgroundColor: chipColor, color: "text.primary" }}
                label={
                  <Typography variant="caption" noWrap display="flex">
                    Version:&nbsp;
                    {highlightedVersion}
                  </Typography>
                }
              />
            </Stack>
          </Stack>
        }
      />
    </Card>
  );
};
