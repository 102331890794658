import {
  type AssetModel,
  type CustomerModelCloudConnectModel,
  type TierPackageType,
  type UserModel,
} from "@doitintl/cmp-models";
import {
  type QueryDocumentSnapshotModel,
  type QuerySnapshotModel,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { CSPCustomerID } from "../../utils/common";
import { type FirestoreTimestamp } from "../../utils/firebase";
import {
  CloudConnectAccessStatus,
  type CloudConnectGCPAccess,
  type CustomerListItem,
  type TrialStatusDetails,
  TrialStatusValue,
} from "./types";

type UserLastLogin = (UserModel & { id: string }) | null;

export const cloudConnectPermissionsModel = (
  status: CloudConnectAccessStatus | string,
  permissions: Array<string>
) => ({
  status,
  permissions,
});

export const getGcpPermissions = (cloudConnectGCP: CustomerModelCloudConnectModel): CloudConnectGCPAccess => {
  if (!cloudConnectGCP?.categoriesStatus) {
    return cloudConnectPermissionsModel(CloudConnectAccessStatus.Na, []);
  }
  const keys = Object.keys(cloudConnectGCP.categoriesStatus);
  const status = keys.every((key) => cloudConnectGCP?.categoriesStatus && cloudConnectGCP?.categoriesStatus[key] === 1);

  return cloudConnectPermissionsModel(
    status ? CloudConnectAccessStatus.Full : CloudConnectAccessStatus.Partial,
    keys.filter((key) => cloudConnectGCP?.categoriesStatus && cloudConnectGCP?.categoriesStatus[key] === 1)
  );
};

export const getAWSPermissions = (
  cloudConnectAWS: QuerySnapshotModel<CustomerModelCloudConnectModel>,
  awsAssets: QuerySnapshotModel<AssetModel>
): CloudConnectGCPAccess => {
  if (!cloudConnectAWS || cloudConnectAWS?.empty) {
    return cloudConnectPermissionsModel(awsAssets.size > 0 ? `0/${awsAssets.size}` : CloudConnectAccessStatus.Na, []);
  }
  return cloudConnectPermissionsModel(`${cloudConnectAWS.size}/${awsAssets.size}`, []);
};

export const findLastLoginUser = (docs: QueryDocumentSnapshotModel<UserModel>[]): WithFirebaseModel<UserLastLogin> => {
  let lastLogin: UserLastLogin = null;
  docs.forEach((doc) => {
    const user = doc.asModelData();
    if (user.lastLogin && (!lastLogin || user.lastLogin.seconds > (lastLogin.lastLogin?.seconds ?? 0))) {
      lastLogin = { ...user, id: doc.id };
    }
  });
  return lastLogin;
};

const convertToDateTime = (date?: FirestoreTimestamp | undefined | null) =>
  date ? DateTime.fromJSDate(date.toDate()) : undefined;

export const getCustomerTierTrialStatus = (
  customer: CustomerListItem,
  packageType: TierPackageType
): TrialStatusDetails => {
  const trialStatus: TrialStatusDetails = {
    status: TrialStatusValue.Na,
    date: "",
  };

  const tier = customer.tiers?.[packageType];

  if (customer.id === CSPCustomerID || tier === undefined) {
    return trialStatus;
  }

  const now = DateTime.now();
  const startDate = convertToDateTime(tier.trialStartDate as FirestoreTimestamp);

  if (!startDate || startDate.diff(now).milliseconds > 0) {
    trialStatus.status = TrialStatusValue.NotStarted;
  } else {
    const endDate = convertToDateTime(tier.trialEndDate as FirestoreTimestamp);
    if (endDate) {
      if (endDate.diff(now).milliseconds > 0) {
        trialStatus.status = TrialStatusValue.Active;
      } else {
        trialStatus.status = TrialStatusValue.Ended;
      }
      trialStatus.date = `End date: ${endDate.toLocaleString(DateTime.DATE_FULL)}`;
    }
  }

  return trialStatus;
};
