import { Grid } from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { renderPackageCloudConnectCards } from "../SaaSDashboard/WelcomeBlockWrapper";
import CloudConnectionStartCard from "./CloudConnectionStartCard";

const SolveOnlyWelcomeBlock = () => {
  const { customer } = useCustomerContext();
  const { onboarding } = customer;
  const showStartConnectionCard = !onboarding?.startTrialInitiated;

  return showStartConnectionCard ? (
    <Grid item xs={12} sm={12} md={6}>
      <CloudConnectionStartCard />
    </Grid>
  ) : (
    <>{renderPackageCloudConnectCards(customer, "solve")}</>
  );
};

export default SolveOnlyWelcomeBlock;
