import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type CurrencyCode } from "./Currency";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";
import { type InvoiceModel } from "./Invoice";

type Extfile = {
  key: string;
  url: string;
};

type Invoicespaid = {
  CREDIT: number;
  IVNUM: string;
};

type Metadata = {
  customer: Customer;
  entity: Entity;
};

type Customer = {
  name: string;
  primaryDomain: string;
};

type Entity = {
  name: string;
};

@collection("receipts")
export class ReceiptModel {
  CANCELED!: boolean;

  CODE!: CurrencyCode;

  COMPANY!: "doit";

  CUSTNAME!: string;

  EXTFILES!: Extfile[];

  INVOICES!: Reference<InvoiceModel>[] | null;

  INVOICESPAID!: Invoicespaid[] | null;

  IVDATE!: Timestamp;

  IVDATE_STRING!: Timestamp;

  IVNUM!: string;

  PAYDATE!: Timestamp;

  PAYDATE_STRING!: Timestamp;

  STATDES!: string;

  SYMBOL!: CurrencyCode;

  TOTPRICE!: number;

  USDEXCH!: number;

  USDTOTAL!: number;

  customer!: Reference<CustomerModel>;

  entity!: Reference<EntityModel>;

  metadata!: Metadata;
}
