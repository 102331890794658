import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

import { type ProductEnum } from "./Collection";

@collection("insights")
export class InsightModel {
  name!: string;

  shortDescription!: string;

  type!: string;

  effort!: string;

  cloudTags!: ProductEnum[];

  owner!: string;

  detailKey!: string;

  executionKey!: string;

  async!: boolean;

  subCollections?: {
    results: InsightModelResult;
  };
}

@subCollection("insightResults")
export class InsightModelResult {
  customerId!: string;

  lastUpdated!: Timestamp;

  status!: "failed" | "optimized" | "refine" | "running";

  implementationMdx?: string;

  tagData?: InsightTagData;
}

export type InsightTagData = {
  [key: string]: {
    value: never;
    description: string;
    type: "number" | "string" | "currency" | "percentage" | "date" | "props";
  };
};

export type ProjectSavingsProps = { projectId: string; saving: number; service?: string }[];
