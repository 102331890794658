import { useMemo } from "react";

import { useUserContext } from "../Context/UserContext";
import { isAdmin } from "./permissions";

export const useHasAdminRole = () => {
  const { userModel } = useUserContext();

  return useMemo(() => isAdmin(userModel?.roles), [userModel?.roles]);
};

export const useHasAdminOrSaasAdminRole = () => {
  const { userModel } = useUserContext();

  return useMemo(() => isAdmin(userModel?.roles), [userModel?.roles]);
};
