import { type IntegrationModelFlexibleReservedInstancesModel } from "@doitintl/cmp-models";

export const autopilotVal = "autopilot-v1";

type IntegrationModelFlexibleReservedInstancesModelModeAutoPilot = Omit<
  IntegrationModelFlexibleReservedInstancesModel,
  "execution" | "autopilot"
> & {
  execution: "autopilot-v1";
  autopilot: Exclude<IntegrationModelFlexibleReservedInstancesModel["autopilot"], undefined>;
};

type IntegrationModelFlexibleReservedInstancesModelModeAutoNormalizedUnits = Omit<
  IntegrationModelFlexibleReservedInstancesModel,
  "execution"
> & {
  execution: "" | "unmanaged" | undefined;
};

// type IntegrationModelFlexibleReservedInstancesModelMode =

export type FlexsaveOrderShowPricingInfo = (
  | IntegrationModelFlexibleReservedInstancesModelModeAutoPilot
  | IntegrationModelFlexibleReservedInstancesModelModeAutoNormalizedUnits
) & {
  normalizedUnits: Exclude<IntegrationModelFlexibleReservedInstancesModel["normalizedUnits"], null>;
  _id: string;
  savings: number;
  showPricingInfo: true;
};

type FlexsaveOrderNoPricingInfo = (
  | IntegrationModelFlexibleReservedInstancesModelModeAutoPilot
  | IntegrationModelFlexibleReservedInstancesModelModeAutoNormalizedUnits
) & {
  _id: string;
  showPricingInfo: false;
  savings: number;
};

export type FlexsaveOrder = FlexsaveOrderNoPricingInfo | FlexsaveOrderShowPricingInfo;
