export const applyRecommendationsStrings = {
  SuccessfullyApplied: "Recommendations successfully applied",
  FailedApply: "Failed to apply recommendation",
  ChangesApplied: "Changes applied successfully",
};

export const checkFBODStrings = {
  Enabled: "Fallback to On-Demand preference updated",
  Disabled: "Fallback to On-Demand preference updated",
  Failed: "Failed setting configuration",
};

export const intro = {
  text: "View our documentation to learn how to apply tags to your AWS ASGs to enable Spot Scaling recommendations with the most commonly used IaC tools.",
  url: "https://help.doit.com/docs/spot-scaling/setup#spot-scaling-auto-enrollment",
};

export const errors = {
  generalError: "Error: ",
  incompatible: "compatible launch template",
  updateError: "Can't update ASG: ",
  incompatibleDocUrl:
    "https://help.doit.com/docs/spot-scaling/faq#incompatible-launch-template-already-requesting-for-spot-instances",
  tagsErrorUrl: "https://help.doit.com/docs/spot-scaling/setup#use-asg-tags-to-configure-auto-enrollment",
};

export const FbodDisabledReason = {
  tags: "This setting is disabled when using AWS tags",
  lc: "Using launch configuration is not supported",
  noMip: "To use Fallback to On-Demand, Auto Scaling Groups (ASGs) ",
  noMip2: ". By applying our recommendations, your ASG is automatically updated to work in a MIP",
  awsNoMipDocs: "need a mixed instance policy (MIP)",
  awsNoMipUrl: "https://help.doit.com/docs/spot-scaling/overview#how-does-it-work",
};
