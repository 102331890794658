import { useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { Alert, Autocomplete, Chip, MenuItem, Stack, TextField, Typography } from "@mui/material";

import useDomains from "../../../../Components/hooks/useDomains";
import { ToolTipWrapper } from "../../../../Components/TooltipWrapper";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useErrorSnackbar } from "../../../Integrations/Slack/utils";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { text } from "../text";
import { type SettingsValuesType } from "../types";

export function Domains({
  domains,
  onAddDomain,
  onRemoveDomain,
  onSetPrimaryDomain,
  errMsg,
  isPrimaryDomainChanged,
}: {
  domains: SettingsValuesType["domains"];
  onAddDomain: (domain: string) => void;
  onRemoveDomain: (domain: string) => void;
  onSetPrimaryDomain: (domain: string) => void;
  errMsg: string | undefined;
  isPrimaryDomainChanged: boolean;
}) {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const mode: "edit" | "view" = isDoitEmployee ? "edit" : "view";
  const primaryDomain = domains.find((domain) => domain.isPrimary)?.name;
  const domainNames = domains.map((domain) => domain.name);
  const { domainAlreadyExists } = useDomains();
  const errorSnackbar = useErrorSnackbar(15);
  const chipSeparators = [",", "\n", "\t"];
  const [inputValue, setInputValue] = useState("");

  return (
    <Stack gap={2}>
      <Typography variant="subtitle1" fontWeight={500} sx={{ cursor: "auto", mb: 2 }}>
        {isDoitEmployee ? "Customer domains" : "Domains"}
      </Typography>
      <TextField
        label={isDoitEmployee ? "Customer primary domain" : "Primary domain"}
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={primaryDomain}
        onChange={(e) => {
          onSetPrimaryDomain(e.target.value);
        }}
        select
        size="small"
        helperText={mode === "edit" ? "How the customer will show in the Console and on invoices" : undefined}
        disabled={mode !== "edit"}
        data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.PRIMARY_DOMAIN}
      >
        {domains.map((domain) => (
          <MenuItem key={domain.name} value={domain.name} disabled={domain.isPrimary}>
            {domain.name}
          </MenuItem>
        ))}
      </TextField>
      {isPrimaryDomainChanged && (
        <Alert severity="warning" data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.PRIMARY_DOMAIN_CHANGED_ALERT}>
          {text.PRIMARY_DOMAIN_CHANGED_ALERT}
        </Alert>
      )}

      <Autocomplete
        multiple
        freeSolo
        filterSelectedOptions
        size="small"
        autoSelect
        disableClearable
        options={domainNames}
        value={domainNames}
        data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.DOMAINS}
        disabled={mode !== "edit"}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          if (newInputValue.trim() !== newInputValue) {
            const trimmedInputValue = newInputValue.toLowerCase().trim();
            if (trimmedInputValue) {
              onAddDomain(trimmedInputValue);
            }
            setInputValue("");
          } else {
            setInputValue(newInputValue);
          }
        }}
        onChange={async (_, newValue, reason) => {
          if (reason === "createOption" || reason === "blur") {
            const newValues = newValue[newValue.length - 1]
              .split(new RegExp(chipSeparators.join("|")))
              .map((value) => value.toLowerCase())
              .filter((value) => value !== "");

            newValues.forEach(async (newDomain) => {
              if (newDomain.length > 128) {
                errorSnackbar("Invalid domain");
              } else if (await domainAlreadyExists(newDomain)) {
                errorSnackbar("Domain already exists");
              } else {
                onAddDomain(newDomain);
              }
            });
          }
        }}
        limitTags={20}
        renderTags={(tags) =>
          tags.map((domainName) => {
            const isPrimary = domainName === primaryDomain;
            const isChipDisabled = isPrimary || mode !== "edit";
            return (
              <ToolTipWrapper
                title={mode === "edit" && isPrimary ? "Cannot remove primary domain" : undefined}
                key={domainName}
                placement="bottom-start"
              >
                <Chip
                  size="small"
                  key={domainName}
                  variant="filled"
                  label={domainName}
                  disabled={isChipDisabled}
                  deleteIcon={
                    <CancelIcon
                      data-cy={`${DATA_CY_GENERAL_SETTINGS_SELECTORS.DOMAIN_CHIP_DELETE_ICON}-${domainName}`}
                    />
                  }
                  onDelete={() => {
                    onRemoveDomain(domainName);
                  }}
                  sx={{ my: 1.5, mx: 1 }}
                  data-cy={`${DATA_CY_GENERAL_SETTINGS_SELECTORS.DOMAIN_CHIP}-${domainName}`}
                />
              </ToolTipWrapper>
            );
          })
        }
        renderInput={(params) => {
          let helperText: string | undefined = errMsg;
          if (!errMsg && mode === "edit") {
            helperText = text.DOMAINS_HELPER_TEXT;
          }

          return (
            <TextField
              {...params}
              margin="normal"
              variant="outlined"
              label={isDoitEmployee ? "Customer domains" : "Domains"}
              error={!!errMsg}
              helperText={helperText}
              disabled={mode !== "edit"}
              inputProps={{
                ...params.inputProps,
                "data-cy": DATA_CY_GENERAL_SETTINGS_SELECTORS.DOMAINS_INPUT,
              }}
            />
          );
        }}
      />
    </Stack>
  );
}
