import { Hidden, Link, TableCell, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { globalText } from "../../../assets/texts";
import { useAuthContext } from "../../../Context/AuthContext";
import { dateFormatDayMonthYear } from "../../../utils/dateTimeFormats";
import { ThreeDotsMenu } from "../../ThreeDotsMenu";
import { type GCPPricebook } from "../Types";

export type Props = {
  row: GCPPricebook;
  handleOpenDialog: (row: GCPPricebook | null) => () => void;
};

export const GCPPricebookRow = ({ row, handleOpenDialog }: Props) => {
  const { isDoitDeveloper } = useAuthContext();

  const threeDotsMenuOptions = [
    {
      label: <Typography>{globalText.EDIT}</Typography>,
      action: handleOpenDialog(row),
      key: globalText.EDIT,
    },
  ];

  return (
    <>
      <TableCell data-cy="customerName">
        <Link href={`/customers/${row.customer.id}`} color="inherit" target="_blank">
          {row?.metadata?.customer.name}
        </Link>
      </TableCell>
      <Hidden mdDown>
        <TableCell data-cy="startDate">
          {DateTime.fromSeconds(row.startDate.seconds).toFormat(dateFormatDayMonthYear)}
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell data-cy="endDate">
          {DateTime.fromSeconds(row.endDate.seconds).toFormat(dateFormatDayMonthYear)}
        </TableCell>
      </Hidden>
      <TableCell align="left" data-cy="tableName">
        {row.tableRef}
      </TableCell>
      {isDoitDeveloper && (
        <TableCell padding="none" data-cy="threeDots">
          <ThreeDotsMenu size="small" options={threeDotsMenuOptions} closeAfterSelect />
        </TableCell>
      )}
    </>
  );
};
