import { type CurrencyCode } from "@doitintl/cmp-models";
import { loadStripe } from "@stripe/stripe-js";

export const useStripeClient = (currency: CurrencyCode | "" | undefined) => {
  const stripeUSPromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    : loadStripe("");
  const stripeUKandIPromise = process.env.REACT_APP_STRIPE_UK_AND_I_PUBLISHABLE_KEY
    ? loadStripe(process.env.REACT_APP_STRIPE_UK_AND_I_PUBLISHABLE_KEY)
    : loadStripe("");
  const stripeDEPromise = process.env.REACT_APP_STRIPE_DE_PUBLISHABLE_KEY
    ? loadStripe(process.env.REACT_APP_STRIPE_DE_PUBLISHABLE_KEY)
    : loadStripe("");
  const getStripeClient = () => {
    if (currency === "GBP") {
      return stripeUKandIPromise;
    } else if (currency === "EUR") {
      return stripeDEPromise;
    }
    return stripeUSPromise;
  };

  const stripeClient = getStripeClient();

  return { stripeClient };
};
