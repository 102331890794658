import { type JSX } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Paper } from "@mui/material";
import { Box, Stack } from "@mui/system";

type Props = {
  id: string;
  rightHolder?: boolean;
  height?: number | string;
  width?: number | string;
  grabbing?: boolean;
  children: JSX.Element;
};
/**
 * A holder for a content that we want to allow dragging
 * @param id - uniq id (one of the ids from the DraggableContainer state)
 * @param rightHolder - show the drag indicator on the right
 * @param children - the content to display
 * @param grabbing - control the cursor of the item
 * @param height
 * @param width
 */
export const DraggableItemHolder = ({
  id,
  rightHolder = false,
  children,
  grabbing = false,
  height = 45,
  width = "100%",
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: grabbing ? "grabbing" : "grab",
  };

  let delay;

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      onMouseUp={() => {
        clearTimeout(delay);
      }}
      onMouseDown={(event) => {
        const { target } = event;
        const type = (target as Element).getAttribute("type");
        const desiredAction = () => listeners?.onMouseDown?.(event);

        if (type) {
          // this will prevent dragging in case the event has type (like button)
          return;
        }

        if (window.getComputedStyle(target as Element).cursor === "grab") {
          desiredAction();
          return;
        }

        delay = setTimeout(desiredAction, 100);
      }}
    >
      {isDragging ? (
        <Box
          sx={({ palette }) => ({
            border: "2px dashed",
            borderColor: palette.divider,
            borderRadius: 1,
            backgroundColor: palette.mode === "dark" ? palette.general.backgroundDark : palette.background.default,
            width,
            height,
          })}
        />
      ) : (
        <Paper
          elevation={grabbing ? 2 : 0}
          variant={!grabbing ? "outlined" : undefined}
          sx={{
            width,
            height,
            "&:hover": {
              bgcolor: !grabbing ? "action.hover" : undefined,
            },
          }}
        >
          <Stack
            px={1}
            direction={rightHolder ? "row-reverse" : "row"}
            justifyContent="space-between"
            alignItems="center"
            width={width}
            height={height}
          >
            <DragIndicatorIcon color="action" />
            <Box width="calc(100% - 24px)" data-cy={`title-${id}`}>
              {children}
            </Box>
          </Stack>
        </Paper>
      )}
    </div>
  );
};
