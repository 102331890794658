import React, { type ReactNode, useCallback, useMemo, useState } from "react";

import { type CaseIQBaseURLType } from "@doitintl/case-iq/src/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import type { CaseIQRouter } from "@doitintl/case-iq/src/router";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCurrentLanguage } from "../../../utils/useCurrentLanguage";
import { SeverityLevels } from "../../types";

const caseIQBaseUrl: CaseIQBaseURLType = "/api/caseiq/v1/trpc";

export const caseIQTrpc = createTRPCReact<CaseIQRouter>();

export const CaseIQClientProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuthContext();

  const getToken = useCallback(
    async (forceRefresh?: boolean) => currentUser?.getIdTokenResult(forceRefresh),
    [currentUser]
  );
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    caseIQTrpc.createClient({
      links: [
        httpBatchLink({
          url: `${window.location.origin}${caseIQBaseUrl}`,
          async headers() {
            const currentToken = await getToken();
            return {
              Authorization: currentToken ? `Bearer ${currentToken.token}` : undefined,
            };
          },
        }),
      ],
    })
  );

  return (
    <caseIQTrpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </caseIQTrpc.Provider>
  );
};

const supportedProducts = [
  "gcp/compute-engine",
  "gcp/bigquery",
  "aws/amazon-ec2",
  "aws/amazon-rds",
  "gcp/cloud-load-balancing",
  "gcp/cloud-composer",
  "gcp/identity-and-access-management-iam",
  "aws/amazon-elastic-kubernetes-service-eks",
  "gcp/cloud-storage",
  "gcp/app-engine",
  "gcp/cloud-functions",
  "gcp/cloud-run",
  "gcp/cloud-sql",
  "gcp/google-kubernetes-engine-gke",
];

type Props = {
  severity: SeverityLevels | undefined;
  cloudProduct: string | undefined;
};

export const useABTestingCaseIQ = ({ severity, cloudProduct }: Props): boolean => {
  const lang = useCurrentLanguage();
  const { isDoitEmployee } = useAuthContext();

  return useMemo(() => {
    if (!severity || !cloudProduct) {
      return false;
    }

    if (![SeverityLevels.high, SeverityLevels.urgent].includes(severity)) {
      return false;
    }

    if (!supportedProducts.includes(cloudProduct)) {
      return false;
    }

    if (process.env.NODE_ENV === "development" || isDoitEmployee) {
      return true;
    }

    if (lang !== "en") {
      return false;
    }

    const key = `case-iq-ab-testing`;
    const existingValue = sessionStorage.getItem(key);

    const isValidValue = (value: string): value is "A" | "B" => value === "A" || value === "B";

    if (existingValue && isValidValue(existingValue)) {
      return existingValue === "A";
    }

    const newValue = Math.random() < 0.25 ? "A" : "B";

    sessionStorage.setItem(key, newValue);

    return newValue === "A";
  }, [isDoitEmployee, lang, severity, cloudProduct]);
};

export const stripHtmlTags = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, "");
