import { Fragment, useCallback } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Autocomplete, Button, Container, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";

import { roundWithCommas } from "../../../../../utils/common";
import { lookerSkuDefaultValues, useContractFormContext } from "../../ContractsFormContext";
import { getLookerSkuStartDates } from "../../utils";
import { skus } from "./skuData";

const dateFormat = "dd MMM yyyy";

export const LookerContractSKUsStep = () => {
  const { state, handleChange, handleChangeDate, setState } = useContractFormContext();
  const { lookerSkus, startDate, lookerContractDuration, isEditForbidden, discount } = state;
  const lookerSkusError = state.errors.lookerSkus;
  const lookerSkuStartDates = getLookerSkuStartDates(state);
  const lookerContractDurationError = state.errors.lookerContractDuration;

  const handleAddSku = useCallback(() => {
    const sku = { ...lookerSkuDefaultValues };
    setState({ ...state, lookerSkus: [...state.lookerSkus, sku] });
  }, [setState, state]);

  const handleDeleteSku = useCallback(
    (i) => {
      const lookerSkus = [...state.lookerSkus];
      lookerSkus.splice(i, 1);

      const errors = { ...state.errors };
      if (errors.lookerSkus) {
        errors.lookerSkus.splice(i, 1);
      }

      setState({ ...state, lookerSkus, errors });
    },
    [setState, state]
  );

  const handleSkusChange = useCallback(
    (index, event) => {
      const skus = [...state.lookerSkus];
      skus[index][event.target.name] = event.target.value;
      const skusErrors = [...state.errors.lookerSkus];
      skusErrors[index][event.target.name] = false;
      setState((prevState) => ({
        ...prevState,
        lookerSkus: skus,
        errors: { ...state.errors, lookerSkus: skusErrors },
      }));
    },
    [setState, state]
  );

  const handleSkusChangeName = useCallback(
    (index, value) => {
      const skus = [...state.lookerSkus];
      skus[index].skuName = value;
      const skusErrors = [...state.errors.lookerSkus];
      skusErrors[index].skuName = false;
      setState((prevState) => ({ ...prevState, lookerSkus: skus }));
    },
    [setState, state]
  );

  let contractEndDate = startDate;
  if (startDate?.isValid && lookerContractDuration > 0) {
    contractEndDate = startDate.plus({ months: lookerContractDuration }).minus({ days: 1 });
  }
  const totalSalesPrices = lookerSkus.map((sku) => {
    if (sku.monthlySalesPrice > 0 && sku.months > 0 && sku.quantity > 0) {
      return sku.monthlySalesPrice * sku.months * sku.quantity;
    }
    return 0;
  });
  const annualizedValue = lookerSkus.reduce(
    (total, sku) =>
      sku.monthlySalesPrice > 0 && sku.months > 0 && sku.quantity > 0
        ? total + sku.monthlySalesPrice * sku.months * sku.quantity
        : total,
    0
  );

  const minContractDuration = Math.max(...lookerSkus.map((sku) => sku.months));
  const invalidContractDuration = lookerContractDuration < minContractDuration;
  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            renderInput={(params) => <TextField data-cy="contractStartDate" margin="dense" fullWidth {...params} />}
            label="Overall subscription start date"
            onChange={handleChangeDate("startDate")}
            value={startDate}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat={dateFormat}
            disabled={isEditForbidden}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            data-cy="contractDuration"
            required={true}
            name="contractDuration"
            label="Months"
            onChange={handleChange("lookerContractDuration")}
            margin="dense"
            value={lookerContractDuration}
            error={lookerContractDurationError || invalidContractDuration}
            helperText={invalidContractDuration && "Months must be a positive number and greater than the longest SKU"}
            type="number"
            InputProps={{ inputProps: { min: minContractDuration } }}
            disabled={isEditForbidden}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Overall subscription end date:</Typography>
          <Typography variant="body2" display="block" data-cy="contractEndDate">
            {contractEndDate?.isValid && contractEndDate.toFormat(dateFormat)}
          </Typography>
        </Grid>
        {lookerSkus.map((sku, i) => (
          <Fragment key={sku.skuName.googleSku}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" component="span" fontWeight="medium">
                  SKU {i + 1}
                </Typography>
                {lookerSkus.length > 1 && (
                  <Button
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleDeleteSku(i);
                    }}
                    data-cy="deleteSkuButton"
                  >
                    Delete SKU
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="skuName"
                data-cy="skuName"
                disableClearable
                value={sku.skuName}
                onChange={(_, value) => {
                  handleSkusChangeName(i, value);
                }}
                options={skus}
                disabled={isEditForbidden}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="SKU name"
                    error={lookerSkusError?.[i]?.skuName}
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="googleSku"
                label="Google SKU"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                value={sku.skuName.googleSku}
                margin="dense"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                data-cy="months"
                name="months"
                label="Months"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                value={sku.months}
                error={lookerSkusError?.[i]?.months}
                helperText={
                  "SKU months run backward from the end of the contract. " +
                  "Selecting fewer months than the overall subscription will delay the start date"
                }
                type="number"
                InputProps={{ inputProps: { min: 1, max: lookerContractDuration } }}
                disabled={isEditForbidden}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                renderInput={(params) => <TextField margin="dense" fullWidth {...params} />}
                label="Service start date"
                onChange={() => 0}
                value={lookerSkuStartDates[i]}
                inputFormat={dateFormat}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required={true}
                name="quantity"
                label="Quantity"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                value={sku.quantity}
                error={lookerSkusError?.[i]?.quantity}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                disabled={isEditForbidden}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                name="monthlyListPrice"
                label="Monthly list price"
                value={sku.skuName.monthlyListPrice}
                disabled={true}
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                required={true}
                name="monthlySalesPrice"
                label="Monthly sales price"
                value={sku.monthlySalesPrice}
                error={lookerSkusError?.[i]?.monthlySalesPrice}
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                disabled={isEditForbidden}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Total sales price:</Typography>
              <Typography variant="body2" display="block" data-cy="totalPrice">
                ${roundWithCommas(totalSalesPrices[i])}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddSku}
        data-cy="addSkuButton"
        disabled={isEditForbidden}
      >
        Add an additional SKU
      </Button>
      {discount > 0 && (
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <Divider sx={{ mt: 3 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Annualized value</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" display="block" data-cy="lookerAnnualizedValue">
              ${roundWithCommas(annualizedValue)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">{`One-time discount (${discount}%)`}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" display="block" data-cy="lookerDiscount">
              ${roundWithCommas(annualizedValue * (discount / 100))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Total annualized value</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" display="block" data-cy="lookerTotalAnnualizedValue">
              ${roundWithCommas(annualizedValue - annualizedValue * (discount / 100))}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
