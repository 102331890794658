export const generalSettingsValidationErrors = {
  ENTER_NAKED_DOMAIN_WITHOUT_PROTOCOL: "Please enter a naked domain name, without http:// or https://",
  INVALID_CURRENCY: "Invalid currency",
  INVALID_CLOUD_PROVIDER: "Invalid cloud provider",
  INVALID_CUSTOMER_CLASSIFICATION: "Invalid customer classification",
  INVALID_CUSTOMER_NAME: "Invalid customer name",
  NO_PRIMARY_DOMAIN_CHOSEN: "No primary domain has chosen",
  CANT_REMOVE_ALL_DOMAINS: "Cannot remove all domains",
  ENTER_NAKED_DOMAIN: "Please enter a naked domain without subdomains",
  INVALID_SKIP_REMEDY_BREACH: "Invalid skip remedy breach",
  INVALID_CUSTOMER_SEGMENT: "Invalid skip remedy breach",
  INVALID_EARLY_ACCESS_FEATURES: "Invalid early access features",
  INVALID_LINES_PER_INVOICE: ({ min, max }: { min: number; max: number }) =>
    `Lines per invoice must be between ${min} to ${max}`,
  DOMAIN_ALREADY_EXISTS: (domain: string) => `${domain} is already exists`,
  DOMAIN_ALREADY_TAKEN: (domain: string) => `${domain} is already taken by another customer`,
  INVALID_DOMAIN: (domain: string) => `${domain} is invalid domain name`,
  DOMAIN_IN_USE_BY_AUTO_PROVISION: (domain: string) =>
    `${domain} is used for auto-provisioning, please deattach it first`,
};
