import { Box, CardHeader, Grid, Typography } from "@mui/material";

import ButtonCreateRampPlan from "../../../../Pages/RampPlans/components/ButtonCreateRampPlan";
import { type RampPlanModel } from "../../types";
import { isRampPlanComplete } from "../../utils";
import RampPlanCard from "./RampPlanCard";

type RampListNewProps = {
  rampPlans: RampPlanModel[];
  takenNamesArr: string[]; // names of existing ramp plans, passed from query in parent component, to prevent duplication
};

const RampListNew = ({ rampPlans, takenNamesArr }: RampListNewProps) => {
  const activeRampPlans: RampPlanModel[] = [];
  const inactiveRampPlans: RampPlanModel[] = [];

  rampPlans.forEach((rampPlan) => {
    if (isRampPlanComplete(rampPlan)) {
      inactiveRampPlans.push(rampPlan);
    } else {
      activeRampPlans.push(rampPlan);
    }
  });

  return (
    <Grid container xl={8} md={10} sm={12} alignItems="center" margin="auto" data-cy="rampplanslist">
      <Grid item xs={12}>
        <Box display="flex" flexDirection="row" alignItems="flex-end" sx={{ justifyContent: "space-between" }}>
          <Box>
            <CardHeader title="Ramp plans" titleTypographyProps={{ variant: "h1" }} sx={{ mb: -1 }} />
            <Typography variant="body1" color="textSecondary" sx={{ ml: 2 }}>
              Track your progress towards your committed spend
            </Typography>
          </Box>
          <Box>
            <ButtonCreateRampPlan takenNamesArr={takenNamesArr} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} alignItems="column" sx={{ ml: 2 }} data-cy="activeRampPlans">
        {activeRampPlans.map((plan, index) => (
          <RampPlanCard key={plan.id} rampPlan={plan} index={index} />
        ))}
      </Grid>
      {inactiveRampPlans.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ ml: 2, mt: 6, mb: 3 }} data-cy="inactiveRampPlansHeader">
              Inactive ramp plans
            </Typography>
          </Grid>
          <Grid item xs={12} alignItems="column" sx={{ ml: 2 }} data-cy="inactiveRampPlans">
            {inactiveRampPlans.map((plan, index) => (
              <RampPlanCard key={plan.id} rampPlan={plan} index={index} />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RampListNew;
