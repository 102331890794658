import { EDGE_TYPE, type EdgeType } from "../../types";

const createBaseEdge = ({
  source,
  target,
  edgeType = EDGE_TYPE.CUSTOM,
  sourceHandleSuffix = "",
  targetHandleSuffix = "",
  data = {},
}: {
  source: string;
  target: string;
  edgeType?: EdgeType;
  sourceHandleSuffix?: string;
  targetHandleSuffix?: string;
  data?: Record<string, any>;
}) => ({
  id: `e-${source}-${target}`,
  source,
  target,
  sourceHandle: edgeType === EDGE_TYPE.GHOST ? undefined : `source-${source}${sourceHandleSuffix}`,
  targetHandle: edgeType === EDGE_TYPE.GHOST ? undefined : `target-${target}${targetHandleSuffix}`,
  type: edgeType,
  data,
});

export const createEdge = (source: string, target: string, edgeType: EdgeType = EDGE_TYPE.CUSTOM) =>
  createBaseEdge({ source, target, edgeType });

export const createTruePathEdge = (source: string, target: string) =>
  createBaseEdge({
    source,
    target,
    edgeType: EDGE_TYPE.CONDITION,
    sourceHandleSuffix: "-true",
    data: { label: "True" },
  });

export const createFalsePathEdge = (source: string, target: string) =>
  createBaseEdge({
    source,
    target,
    edgeType: EDGE_TYPE.CONDITION,
    sourceHandleSuffix: "-false",
    data: { label: "False" },
  });
