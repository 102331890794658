import { useCallback, useEffect, useState } from "react";

import { ref, type StorageReference, uploadString } from "firebase/storage";

import { useAuthContext } from "../../../Context/AuthContext";
import { bucket as initFirebaseStorageBucket } from "../../../utils/firebase";

async function isBucketExists(bucket: StorageReference) {
  // Check if bucket exists by putting a small string to it
  const writePromise = uploadString(ref(bucket, "/exists"), "true");

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error("Operation timed out"));
    }, 4000);
  });

  await Promise.race([writePromise, timeoutPromise]);
}

async function initBucketAndGetRef() {
  try {
    const bucketName = `${process.env.REACT_APP_FIREBASE_PROJECT_ID}-customer-perks`;
    const candidateBucketRef = initFirebaseStorageBucket(`gs://${bucketName}`);
    await isBucketExists(candidateBucketRef);
    return candidateBucketRef;
  } catch (e: any) {
    // bucket doesn't exist or no access
  }
  return null;
}

export function useCustomerPerksBucket() {
  const { currentUser } = useAuthContext();
  const [bucketRef, setBucketRef] = useState<StorageReference | null>(null);
  const [isLoadingBucket, setIsLoadingBucket] = useState<boolean>(true);

  const initBucketHandler = useCallback(async () => {
    // only authenticated users can access the app media bucket
    if (currentUser) {
      const bucketToSet = await initBucketAndGetRef();
      setBucketRef(bucketToSet);
    }

    setIsLoadingBucket(false);
  }, [currentUser]);

  useEffect(() => {
    initBucketHandler();
  }, [initBucketHandler]);

  return { bucketRef, isLoadingBucket };
}
