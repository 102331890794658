import i18next, { type TFunction } from "i18next";

export type I18NextNamespace = "navigation" | "services" | "login";

export function getTFromNamespace<T extends I18NextNamespace>(ns: T): TFunction<T> {
  const tFunction = (key: string) => i18next.t(`${ns}:${key}`);
  return tFunction as TFunction<T>;
}

export const browserLanguage = navigator.language.split("-")[0]; // Get the language code (e.g., 'en' from 'en-US')
