export enum StoryblokContentType {
  "content" = "content",
  "title" = "title",
  "agreement" = "agreement",
}

type StoryblokStoryContent = {
  type: StoryblokContentType.content;
  env: string;
  _uid: string;
  content: string;
  component: string;
  backgroundColor: string;
};

type StoryblokSignUpTitleStoryContent = {
  type: StoryblokContentType.title;
  env: string;
  title: string;
};

type StoryblokSignUpAgreement = {
  type: StoryblokContentType.agreement;
  env: string;
  content: string;
  title: string;
};

type StoryblokContent = StoryblokStoryContent | StoryblokSignUpTitleStoryContent | StoryblokSignUpAgreement;

export type StoryblokStory = {
  uuid: string;
  id: number;
  parent_id: number;
  name: string;
  slug: string;
  full_slug: string;
  created_at: string;
  published_at: string;
  first_published_at: string;
  content: StoryblokContent;
};

export type StoryblokStoriesResponse = {
  stories: StoryblokStory[];
  cv: number;
};

export type StoryblokSpacesResponse = {
  space: {
    id: number;
    name: string;
    domain: string;
    version: number;
  };
};
