import { type ManualPurchaseItem } from "../types";

export const purchaseFormFieldLabels = {
  billingAccountId: "Billing Account",
  region: "Region",
  type: "Type",
  hardware: "Hardware",
  amount: "Amount",
  cost: "Cost",
};

export const hasZeroCost = (manualPurchaseItems: ManualPurchaseItem[]) =>
  manualPurchaseItems.some((item) => item.cost === 0);

export const hasInvalidAmount = (manualPurchaseItems: ManualPurchaseItem[]) =>
  manualPurchaseItems.some((item) => !item.isValid);

export const getUsageUnits = (hardwareType: string) => (hardwareType === "Memory" ? "GigaByte" : "VCPU");

export const generatePurchasePlanPricePayload = (manualPurchaseItem: ManualPurchaseItem) => [
  {
    billing_account_id: manualPurchaseItem.billingAccountId,
    region: manualPurchaseItem.region,
    type: manualPurchaseItem.type,
    hardware: manualPurchaseItem.hardware,
    usage: manualPurchaseItem.amount,
    plan: "THIRTY_SIX_MONTH",
    usage_units: getUsageUnits(manualPurchaseItem.hardware),
  },
];

export const generateManualPurchasePayload = (manualPurchaseItems: ManualPurchaseItem[]) =>
  manualPurchaseItems.map((sku: ManualPurchaseItem) => ({
    billing_account_id: sku.billingAccountId,
    region: sku.region,
    hardware: sku.hardware,
    type: sku.type,
    amount: sku.amount,
  }));
