import { type Collaborators, type PublicAccess, type SlackChannel } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

import { httpMethods } from "../../../assets/texts";
import { type AnalyticsMetadata } from "../../../Components/hooks/cloudAnalytics/useAnalyticsMetadata";
import { consoleErrorWithSentry } from "../../../utils";
import { type AnalyticsResourcesAttributionGroups } from "../analyticsResources/types";
import { type AttributionGroupWithRef } from "./types";

const getBaseURL = (customerID: string) => `/v1/customers/${customerID}/analytics/attribution-groups`;

const getAttributionsIDsFromAG = (
  ag: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups | undefined
): string[] => ag?.data.attributions.map((attribution) => attribution.id) ?? [];

export const shareAttributionGroups = async ({
  attributionGroupId,
  api,
  collaborators,
  customerId,
  publicAccess,
}: {
  attributionGroupId: string;
  api: AxiosInstance;
  collaborators: Collaborators;
  customerId: string;
  publicAccess: PublicAccess;
  recipientsSlackChannels?: SlackChannel[];
}) => {
  const baseURL = getBaseURL(customerId);
  await api.request({
    method: httpMethods.PATCH,
    url: `${baseURL}/${attributionGroupId}/share`,
    data: {
      public: publicAccess,
      collaborators,
    },
  });
};

export const deleteAttributionGroup = async ({
  attributionGroupId,
  api,
  customerId,
}: {
  attributionGroupId: string;
  api: AxiosInstance;
  customerId: string;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.request({
    method: httpMethods.DELETE,
    url: `${baseURL}/${attributionGroupId}`,
  });
};

export const deleteAttributionGroups = async ({
  attributionGroupIds,
  api,
  customerId,
}: {
  attributionGroupIds: string[];
  api: AxiosInstance;
  customerId: string;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.request({
    method: httpMethods.DELETE,
    url: baseURL,
    data: {
      attributionGroupIDs: attributionGroupIds,
    },
  });
};

export const createAttributionGroup = async ({
  api,
  customerId,
  attributions,
  name,
  description,
  nullFallback,
  fromDraftAttributions = false,
}: {
  api: AxiosInstance;
  customerId: string;
  attributions: string[];
  name: string;
  description: string;
  nullFallback?: string;
  fromDraftAttributions?: boolean;
}) => {
  const baseURL = getBaseURL(customerId);
  const response = await api.request({
    method: httpMethods.POST,
    url: fromDraftAttributions ? `${baseURL}?fromDraftAttributions=true` : baseURL,
    data: {
      attributions,
      name,
      description,
      ...(nullFallback && { nullFallback }),
    },
  });
  return response.data.id;
};

export const editAttributionGroup = async ({
  api,
  attributionGroupId,
  customerId,
  attributions,
  name,
  description,
  nullFallback,
}: {
  api: AxiosInstance;
  attributionGroupId: string;
  customerId: string;
  attributions: string[];
  name: string;
  description: string;
  nullFallback?: string;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.request({
    method: httpMethods.PUT,
    url: `${baseURL}/${attributionGroupId}`,
    data: {
      attributions,
      name,
      description,
      ...(nullFallback && { nullFallback }),
    },
  });
};

export const copyAttributionGroup = (
  api: AxiosInstance,
  ag: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups,
  customerId: string
) =>
  createAttributionGroup({
    api,
    customerId,
    attributions: getAttributionsIDsFromAG(ag),
    name: `Copy of ${ag.data.name}`,
    description: ag.data.description,
  });

export const getAttributionGroupMetadata = async (
  api: AxiosInstance,
  customerId: string
): Promise<AnalyticsMetadata> => {
  if (!customerId) {
    return [];
  }
  const request = {
    method: "GET",
    url: `/v1/customers/${customerId}/analytics/attribution-groups/metadata`,
  };

  try {
    const response = await api.request(request);
    if (response?.status === 200) {
      const data = response.data;
      // we are "faking" a firestore docsnap in order to align with the rest of metadata but in reality this is not on firestore and does not contain properties such as "snapshot". the raw data arrives from api and here we make an artificial docsnap
      return data.map((attrGroup) => ({
        get: (field) => attrGroup[field],
        snapshot: {
          id: attrGroup.id,
        },
        id: attrGroup.id,
        data: () => attrGroup,
      })) as AnalyticsMetadata;
    }
  } catch (error) {
    consoleErrorWithSentry(error);
  }

  return [];
};
