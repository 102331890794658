import CircleIcon from "@mui/icons-material/Circle";
import { ListItem, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { type InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";
import { type FirestoreTimestamp } from "../../../../utils/firebase";

export const NotificationsListItem = ({
  n,
  markAsRead,
}: {
  n: InAppNotificationWithRead;
  markAsRead: () => Promise<void>;
}) => {
  const dateString = (date: FirestoreTimestamp) =>
    DateTime.fromJSDate(date.toDate()).toLocal().toLocaleString(DateTime.DATE_MED);
  const date = n.updated ? `Last updated: ${dateString(n.updated)}` : dateString(n.created);
  const unReadBGColor = (theme) => (theme.palette.mode === "dark" ? "#fafaff1a" : "#fafaff");

  return (
    <ListItem divider disablePadding sx={{ backgroundColor: n.read ? "" : unReadBGColor }}>
      <ListItemButton onClick={() => markAsRead()} sx={{ padding: "16px" }}>
        <ListItemText
          primary={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                marginBottom: 2,
              }}
            >
              <Typography
                sx={{
                  display: "inline-block",
                  padding: "0px 8px",
                  borderRadius: 4,
                  backgroundColor: n.color,
                  color: "#000",
                  fontWeight: 400,
                  font: "roboto",
                  letterSpacing: 1.6,
                  fontSize: 12,
                }}
              >
                {n.type}
              </Typography>

              {!n.read && (
                <CircleIcon
                  sx={{
                    color: "primary.main",
                    marginRight: 2,
                    marginLeft: 2,
                    fontSize: 10,
                  }}
                />
              )}
            </Stack>
          }
          secondary={
            <Stack gap={1}>
              <Typography variant="subtitle2" color="text.primary">
                {n.title}
              </Typography>
              {typeof n.description === "string" ? (
                <Typography variant="body2" color="text.secondary">
                  {n.description}
                </Typography>
              ) : (
                n.description
              )}
              <Typography variant="caption" color="text.secondary" marginTop={1}>
                {date}
              </Typography>
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
