import { createContext, type ReactNode, useEffect, useMemo } from "react";

import * as FullStory from "@fullstory/browser";
import markerSDK from "@marker.io/browser";
import * as Sentry from "@sentry/react";
import axios from "axios";

import ErrorBoundaryFallback from "../Components/ErrorBoundaryFallback";
import { isFullstoryEnabled } from "../constants";
import { getCustomerSegment } from "../Navigation/Components/Header/AvatarMenu/CustomerInfo/CustomerInfo";
import { consoleErrorWithSentry } from "../utils";
import { getPermissionsNames } from "../utils/common";
import mixpanel from "../utils/mixpanel";
import { UpdateSalesforceAfterSignup } from "../utils/salesforce/updateSalesforceAfterSignup";
import { SegmentIdentifyAndGroupApi } from "../utils/segment";
import { jobFunctionMap } from "../utils/userUtils";
import { useAuthContext } from "./AuthContext";
import { convertAssetsToMixpanelType, useAssetsContext } from "./customer/AssetContext";
import { useCustomerContext } from "./CustomerContext";
import { useUserContext } from "./UserContext";

const sentryContext = createContext({});

export const ClientSdkContextProvider = ({ children }: { children?: ReactNode }) => {
  const { partnerCompany, impersonate, currentUser, isDoitEmployee, isDoitPartner, tokenValue, customerId, userId } =
    useAuthContext();
  const { userModel: user, userRoles } = useUserContext();
  const { customer, isNavigatorTierTrialUser, isSolveTierTrialUser, isProductOnlyCustomer } = useCustomerContext({
    allowNull: true,
  });
  const { assets } = useAssetsContext();

  useEffect(() => {
    if (currentUser?.uid === undefined) {
      return;
    }

    if (userId) {
      Sentry.setUser({
        email: currentUser?.email,
        id: userId,
        displayName: currentUser?.displayName,
        emailVerified: currentUser?.emailVerified ?? false,
        uid: currentUser?.uid,
        customerId,
        isDoitEmployee: isDoitEmployee ?? false,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [
    isDoitEmployee,
    userId,
    customerId,
    currentUser?.uid,
    currentUser?.email,
    currentUser?.displayName,
    currentUser?.emailVerified,
  ]);

  useEffect(() => {
    if (currentUser?.uid && tokenValue) {
      axios
        .request({
          method: "get",
          url: "/auth/fullstory",
          headers: {
            Authorization: `Bearer ${tokenValue}`,
          },
        })
        .then((response) => {
          let displayName = currentUser.email;
          if (impersonate?.email) {
            displayName = `${impersonate.email} as ${displayName}`;
          }
          if (!isFullstoryEnabled || !response.data.token) {
            return;
          }

          // disable data capture for doers
          if (isDoitEmployee) {
            FullStory.shutdown();
            return;
          }

          FullStory.identify(response.data.token, {
            displayName,
            email: currentUser.email,
            doitEmployee_bool: isDoitEmployee,
            doitPartner_bool: isDoitPartner,
            project_str: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          });
        })
        .catch(consoleErrorWithSentry);
    }
  }, [currentUser?.email, currentUser?.uid, isDoitEmployee, isDoitPartner, impersonate?.email, tokenValue]);

  useEffect(() => {
    if (currentUser?.email && isDoitEmployee) {
      markerSDK.loadWidget({
        destination: "62385341a524b67a431e9db3",
        reporter: {
          email: currentUser.email,
          fullName: currentUser.displayName ?? currentUser.email,
        },
      });
    }
  }, [currentUser?.displayName, currentUser?.email, isDoitEmployee]);

  const commonMixPanelProps = useMemo(
    () => ({
      Project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      Email: currentUser?.email,
      Name: currentUser?.displayName,
      "DoiT Employee?": isDoitEmployee,
      "DoiT Partner?": isDoitPartner,
      "Customer ID": customer?.id,
      "Customer Name": customer?.name,
      "Customer Segment": getCustomerSegment(customer?.customerSegment),
      "Primary Domain": customer?.primaryDomain,
      isPresentationMode: customer?.presentationMode?.enabled || false,
      isOnTrial: isNavigatorTierTrialUser,
      isOnSolveTrial: isSolveTierTrialUser,
      assets: convertAssetsToMixpanelType(assets),
    }),
    [
      currentUser?.email,
      currentUser?.displayName,
      isDoitEmployee,
      isDoitPartner,
      customer?.id,
      customer?.name,
      customer?.customerSegment,
      customer?.primaryDomain,
      customer?.presentationMode?.enabled,
      isNavigatorTierTrialUser,
      isSolveTierTrialUser,
      assets,
    ]
  );

  useEffect(() => {
    if (!currentUser?.uid || !tokenValue) {
      return;
    }

    if (userRoles && customer?.id && !isDoitEmployee && !isDoitPartner) {
      const trialUser = !!customer.trialEndDate;

      mixpanel.people.set({
        $email: currentUser.email,
        $name: currentUser.displayName,
        "Customer ID": customerId,
        "Customer Name": customer.name,
        "First Name": user?.firstName,
        "Last Name": user?.lastName,
        "Trial User": trialUser,
        "Standalone User": !!isProductOnlyCustomer,
        "Role Name": userRoles?.roleName(),
        Permissions: getPermissionsNames(Array.from(userRoles.permissions)),
        "Job Function": jobFunctionMap.get(user?.jobFunction) ?? "",
        isPresentationMode: customer?.presentationMode?.enabled || false,
      });

      mixpanel.register(commonMixPanelProps);
    }
  }, [
    customer?.customerSegment,
    commonMixPanelProps,
    currentUser?.displayName,
    currentUser?.email,
    currentUser?.uid,
    customer?.id,
    customer?.name,
    customer?.primaryDomain,
    customer?.trialEndDate,
    isProductOnlyCustomer,
    customerId,
    isDoitEmployee,
    tokenValue,
    user?.firstName,
    user?.jobFunction,
    user?.lastName,
    userRoles,
    isDoitPartner,
    customer?.presentationMode?.enabled,
  ]);

  useEffect(() => {
    if (isDoitEmployee) {
      mixpanel.register(commonMixPanelProps);

      mixpanel.people.set({
        $email: currentUser?.email,
        $name: currentUser?.displayName,
        isPresentationMode: customer?.presentationMode?.enabled || false,
      });
    }
  }, [
    commonMixPanelProps,
    currentUser?.displayName,
    currentUser?.email,
    customer?.presentationMode?.enabled,
    isDoitEmployee,
  ]);

  useEffect(() => {
    if (!currentUser?.uid || !tokenValue) {
      return;
    }

    if (isDoitPartner && user?.id) {
      mixpanel.register(commonMixPanelProps);

      mixpanel.people.set({
        $email: currentUser.email,
        $name: currentUser.displayName,
        "First Name": user.firstName,
        "Last Name": user.lastName,
        "Partner Company": partnerCompany,
        isPresentationMode: customer?.presentationMode?.enabled || false,
      });
    }
  }, [
    commonMixPanelProps,
    currentUser?.displayName,
    currentUser?.email,
    currentUser?.uid,
    customer?.presentationMode?.enabled,
    isDoitPartner,
    partnerCompany,
    tokenValue,
    user?.firstName,
    user?.id,
    user?.lastName,
  ]);

  useEffect(() => {
    if (!currentUser?.uid || !tokenValue) {
      return;
    }

    mixpanel.identify(currentUser.uid);
  }, [currentUser?.uid, tokenValue]);

  return (
    <sentryContext.Provider value={{}}>
      <Sentry.ErrorBoundary
        fallback={({ error }: { error: any }) => {
          const shouldShowError = isDoitEmployee || !process.env.REACT_APP_SENTRY_RELEASE;
          return <ErrorBoundaryFallback error={error} shouldShowError={shouldShowError} />;
        }}
      >
        <SegmentIdentifyAndGroupApi />
        <UpdateSalesforceAfterSignup />
        {children}
      </Sentry.ErrorBoundary>
    </sentryContext.Provider>
  );
};
