import { type ChangeEvent, type MouseEvent, useState } from "react";

import { type AnalyticsDataSource } from "@doitintl/cmp-models";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  type SxProps,
  type Theme,
  Typography,
} from "@mui/material";

import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import HtmlTooltip from "../Tooltip/HtmlTooltip";
import DataHubTooltip from "./DataHubTooltip";

type Props = {
  checked: boolean;
  cloudProviders?: string[];
  customerId: string;
  dataSource: AnalyticsDataSource;
  disabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  showClouds?: boolean;
  sx?: SxProps<Theme>;
};

const DataHubCheckbox = ({
  checked,
  cloudProviders,
  dataSource,
  disabled,
  onChange,
  customerId,
  showClouds = true,
  sx,
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <FormGroup sx={sx}>
      <FormControlLabel
        disabled={disabled}
        control={<Checkbox size="small" checked={checked} onChange={onChange} sx={{ py: 0, pr: 0.25 }} />}
        label={
          <Typography variant="caption">
            {datahubTxt.INCLUDE}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <span>
                <HtmlTooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  open={isTooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <DataHubTooltip
                      clouds={cloudProviders}
                      customerId={customerId}
                      dataSource={dataSource}
                      showClouds={showClouds}
                    />
                  }
                >
                  <Box
                    sx={{ borderBottom: "1px dotted ", borderColor: "text.primary" }}
                    component="span"
                    onClick={handleTooltipOpen}
                  >
                    {datahubTxt.DATA_HUB_DATA}
                  </Box>
                </HtmlTooltip>
              </span>
            </ClickAwayListener>
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default DataHubCheckbox;
