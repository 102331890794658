import { collection } from "@doitintl/models-types";

@collection("permissions")
export class PermissionModel {
  desc?: string;

  order!: number;

  title!: string;
}
