import { Container, Stack, Typography } from "@mui/material";

import { companyGoalsOptions, companySizeOptions } from "../consts";
import CompanyGoalsSelector from "./CompanyGoalsSelector";
import CompanySizeSelector from "./CompanySizeSelector";

type Props = {
  companySize: string;
  selectedGoals: string[];
  setSelectedGoals: (selectedGoals: string[]) => void;
  setCompanySize: (companySize: string) => void;
};

const CompanyProfileStep = ({ companySize, selectedGoals, setCompanySize, setSelectedGoals }: Props) => (
  <Container maxWidth="md" sx={{ pb: 10 }}>
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography variant="h1">Let us get to know you</Typography>
        <Typography variant="body1" color="text.secondary">
          We can tailor your experience based on your profile and goals
        </Typography>
      </Stack>

      <CompanySizeSelector value={companySize} onChange={setCompanySize} options={companySizeOptions} />
      <CompanyGoalsSelector
        selectedGoals={selectedGoals}
        options={companyGoalsOptions}
        setSelectedGoals={setSelectedGoals}
      />
    </Stack>
  </Container>
);

export default CompanyProfileStep;
