export const USERS_CSV_FILE_NAME = "users.csv";

export const optionsLabels = {
  DOWNLOAD_USERS_FILE: "Download Users",
  UPLOAD_USERS_FILE: "Upload Users",
  ACTIVATE_SELECTED_USER: "Activate User",
  DEACTIVATE_SELECTED_USER: "Deactivate User",
  VIEW_AS_SELECTED_USER: "View As",
  REMOVE_USER_FROM_FIREBASE: "Remove user from Firebase",
};

export const errorMessages = {
  CANNOT_DEACTIVATE_USER: "Arghh, there was a problem with deactivating this user",
  CANNOT_ACTIVATE_USER: "Arghh, there was a problem with activating this user",
  INVALID_EMAIL_ERROR: "Invalid email",
  USER_EXISTS: "Remove user from users list",
  USER_INVITED: "Remove user from invites list",
  SUCCESS_SNACKBAR_MESSAGE: "User removed from Firebase",
  FAILURE_SNACKBAR_MESSAGE: "Unable to remove email from Firebase",
};
