import { Box } from "@mui/material";

import { type EKSPageId } from "../../Navigation/config/pages";
import { useEKSClusters, useSetDeploymentMethod } from "./hooks";
import { Onboarding } from "./Onboarding";
import { ClusterTable } from "./Table/ClusterTable";

type Props = {
  pageId: EKSPageId;
};

export const Dashboard = ({ pageId }: Props) => {
  const [rawClusters] = useEKSClusters();
  const [clusters, loading] = useSetDeploymentMethod(rawClusters);
  if (loading) {
    return null;
  }
  return (
    <Box>
      {pageId === "eks-onboarding" && <ClusterTable clusters={clusters} />}
      {pageId === "eks-onboarding:connect" && <Onboarding clusters={clusters} />}
    </Box>
  );
};
