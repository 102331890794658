import { type JSX, useEffect, useMemo, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  type FilterOptionsState,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { OperationButton } from "./OperationButton";

type Props = {
  title: string;
  optionsItems: string[];
  selectedItems: string[];
  groupBy?: (option: string) => string;
  filterOptions?: (options: string[], state: FilterOptionsState<string>) => string[];
  renderOptionDetails?: (option: string) => JSX.Element | undefined;
  renderOptionString?: (option: string) => string;
  allowEdit: boolean;
  getTooltipString?: (selectedItems: string[]) => string;
  startEachItemInNewLine?: boolean;
  onEditModeChange: (selectedItems?: string[]) => void;
  onChange: () => void; // called when user make any change in selection in the list of items
};

export const EditList = ({
  title,
  optionsItems,
  selectedItems,
  groupBy,
  filterOptions,
  renderOptionDetails,
  renderOptionString,
  allowEdit,
  getTooltipString,
  onChange,
  onEditModeChange,
  startEachItemInNewLine = false,
}: Props) => {
  const [updateMode, setUpdateMode] = useState<boolean>(false);
  const [value, setValue] = useState<string[]>(selectedItems);
  const [hasChange, setHasChange] = useState<boolean>(false);

  const onSelectionChange = (event, items: string[]) => {
    if (items.length > 0) {
      setValue(items);
      onChange();
      setHasChange(true);
    }
  };

  const onUpdateClick = () => {
    setUpdateMode(false);
    onEditModeChange(value);
    setHasChange(false);
  };

  const onCancelClick = () => {
    setUpdateMode(false);
    onEditModeChange(value);
    setHasChange(false);
  };

  const onEditClick = () => {
    onEditModeChange();
    setUpdateMode(true);
  };

  useEffect(() => {
    onChange();
  }, [onChange, value, updateMode]);

  const renderOption = (props, option, selected) => {
    if (renderOptionDetails) {
      return (
        <MenuItem {...props}>
          <Box display="flex" alignItems="start" width="100%">
            <Checkbox checked={selected} size="small" color="primary" />
            <Box pt={0.5} width="100%">
              {renderOptionDetails(option)}
            </Box>
          </Box>
        </MenuItem>
      );
    } else {
      return (
        <MenuItem {...props}>
          <Box display="flex" alignItems="center">
            <Checkbox checked={selected} size="small" color="primary" />
            {option}
          </Box>
        </MenuItem>
      );
    }
  };

  const selectedItemsList = useMemo(() => {
    let items = selectedItems;
    if (renderOptionString) {
      items = items.map((item) => renderOptionString(item));
    }
    if (startEachItemInNewLine) {
      return items.join(",\n");
    } else {
      return items.join(", ");
    }
  }, [selectedItems, renderOptionString, startEachItemInNewLine]);

  const tooltipString = useMemo(() => getTooltipString?.(value), [value, getTooltipString]);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      {updateMode && allowEdit ? (
        <Box pt={1} width="100%">
          <Autocomplete
            multiple
            value={value}
            groupBy={groupBy}
            filterOptions={filterOptions}
            options={optionsItems}
            disableCloseOnSelect
            getOptionDisabled={(option) => value.length === 1 && option === value[0]}
            clearIcon={null}
            renderOption={(props, option, { selected }) => renderOption(props, option, selected)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  color="primary"
                  size="small"
                  label={renderOptionString ? renderOptionString(option) : option}
                  {...getTagProps({ index })}
                  disabled={value.length === 1 && option === value[0]}
                  key={index}
                />
              ))
            }
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" label={title} />}
            onChange={onSelectionChange}
          />
        </Box>
      ) : (
        <Box width="100%" whiteSpace="pre-wrap">
          {selectedItemsList}
        </Box>
      )}

      {allowEdit && (
        <Box display="flex" pl={1}>
          {!!tooltipString && (
            <Box mr={1} display="flex" flexDirection="row" alignItems="center">
              <Tooltip title={<div style={{ whiteSpace: "pre-line" }}>{tooltipString}</div>} placement="top" arrow>
                <InfoOutlinedIcon htmlColor={grey[600]} fontSize="small" />
              </Tooltip>
            </Box>
          )}
          <OperationButton
            onEditClick={onEditClick}
            onUpdateClick={onUpdateClick}
            showCancel={!hasChange}
            onCancelClick={onCancelClick}
          />
        </Box>
      )}
    </Box>
  );
};
