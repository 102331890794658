import { Box, Paper, Stack, Typography } from "@mui/material";

import { cmpBaseColors } from "../../cmpBaseColors";
import { CopyToClipboardButton } from "../../Components/CopyToClipboardButton";
import { ThemeModes } from "../../muiThemeTypes";

type Props = {
  texts: string[];
  hideCopy?: boolean;
  title?: string;
};

const CopyPasteBlock = ({ texts, hideCopy, title }: Props) => (
  <Paper
    elevation={0}
    sx={(theme) => ({
      bgcolor: theme.palette.mode === ThemeModes.LIGHT ? "rgba(0, 0, 0, 0.04)" : cmpBaseColors.backgroundDark,
      p: 1.5,
      width: "100%",
    })}
  >
    <Stack direction="row" alignItems="flex-start">
      <Box sx={{ mt: -0.5, visibility: hideCopy ? "hidden" : "visible" }}>
        <CopyToClipboardButton text={texts.join("")} />
      </Box>

      <Box>
        {!!title && (
          <Typography variant="body2" fontFamily="monospace" color="grayText">
            {title}
          </Typography>
        )}

        {texts.map((b) => (
          <Typography variant="body2" fontFamily="monospace" key={b}>
            {b}
          </Typography>
        ))}
      </Box>
    </Stack>
  </Paper>
);

export default CopyPasteBlock;
