import { Stack, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";

import { type PlatformFilterInfo } from "../types";
import SupportItemIcon from "./SupportItemIcon";

type Props<T> = {
  onClickFilter: (filter: T[]) => void;
  selectedFilter: T[];
  platformFilters: PlatformFilterInfo[];
};

export const PlatformFilters = <T,>({ onClickFilter, selectedFilter, platformFilters }: Props<T>) => (
  <ToggleButtonGroup
    value={selectedFilter}
    onChange={(_, value: T[]) => {
      onClickFilter(value);
    }}
    arial-label="platform filter"
    size="small"
  >
    {platformFilters.map((p) => (
      <ToggleButton value={p.value} aria-label={p.title} key={p.value}>
        <Tooltip title={p.title}>
          {/* Without this box it wont show the tooltip */}
          <Stack>
            <SupportItemIcon platformValue={p.value} />
          </Stack>
        </Tooltip>
      </ToggleButton>
    ))}
  </ToggleButtonGroup>
);
