import { useCallback } from "react";

import { useSauronAPI, useSauronStaffApi } from "../api/endpoints";

export function useGetLoginUrl() {
  const api = useSauronAPI();

  return useCallback(
    async (accountId: string, isStaff: boolean) => {
      const accountDetails = await api.account(accountId, isStaff);
      if (accountDetails.data?.account_number) {
        const supportRes = await api.supportConsole(accountId, isStaff);
        return supportRes.data?.url;
      }
    },
    [api]
  );
}

export function useCredentials(accountId: string) {
  const api = useSauronAPI();
  const staffApi = useSauronStaffApi(accountId);

  return useCallback(async () => {
    const accountDetails = await api.account(accountId, true);
    if (accountDetails.data?.has_role) {
      const supportRes = await staffApi.getSupportCredentials();
      return supportRes.data?.credentials;
    }
  }, [api, staffApi, accountId]);
}
