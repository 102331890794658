import { type TotalAverageUsageModal } from "@doitintl/cmp-models";

import { Loader } from "../../../../Components/Loader";
import BulkPurchases from "./BulkPurchases/BulkPurchases";
import { useBulkPurchases } from "./hooks/bulkPurchasesHooks";
import { type CustomerAggregatedWorkloadInfo, type GroupedWorkloads } from "./types";

type Props = {
  workloads?: GroupedWorkloads;
  totalAverageUsages?: Map<string, TotalAverageUsageModal>;
  customersAgg?: Map<string, CustomerAggregatedWorkloadInfo>;
  isBulkUpdateRequired: boolean;
};

export const FlexsaveGCPBulkPurchases = ({
  workloads,
  totalAverageUsages,
  customersAgg,
  isBulkUpdateRequired,
}: Props) => {
  const bulkPurchaseItems = useBulkPurchases(customersAgg, workloads, totalAverageUsages) || [];
  return (
    <Loader loading={bulkPurchaseItems.length === 0}>
      <BulkPurchases bulkPurchaseItems={bulkPurchaseItems} isBulkUpdateRequired={isBulkUpdateRequired} />
    </Loader>
  );
};
