import { useMemo } from "react";

import {
  type AmazonWebServicesAssetModel,
  AssetModel,
  type CloudConnectAmazonWebServices,
  type CloudConnectModelAWSStandalone,
  CustomerModel,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import findIndex from "lodash/findIndex";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { AccountStatus, type AWSAccount, FeatureDetails } from "./types";

/**
 * hook to get info of single account
 * @param accountId
 */
export const useAWSAccountInfo = (accountId: string): [accounts: string[], roleName: string, isLoading: boolean] => {
  const { customer } = useCustomerContext();

  const query = getCollection(CustomerModel)
    .doc(customer.id)
    .collection("cloudConnect")
    .where("cloudPlatform", "==", "amazon-web-services")
    .where("accountId", "==", accountId)
    .narrow<CloudConnectAmazonWebServices>();

  const [accounts, isLoading] = useCollectionData(query);

  return useMemo(() => {
    if (accounts?.length) {
      const account = accounts[0];

      const supportedFeaturesName = account.supportedFeatures
        ?.filter((feature) => feature.hasRequiredPermissions)
        .map((feature) => feature.name);

      return [supportedFeaturesName ?? [], account.roleName, isLoading];
    }
    return [[], "", isLoading];
  }, [accounts, isLoading]);
};

const getEnabledFeaturesNames = (account: CloudConnectAmazonWebServices): string[] => {
  const supported = account.supportedFeatures?.filter((feature) => feature.hasRequiredPermissions);
  return supported?.map((feature) => FeatureDetails[feature.name].displayName) ?? [];
};

/**
 * hook to get all aws accounts, it will bring join list of accounts from cloudConnect and assets collections
 * also return number of accounts that are not configured yet
 */
export function useAWSAccounts(): [AWSAccount[], number, boolean] {
  const { customer } = useCustomerContext();

  const queryCloudConnect = getCollection(CustomerModel)
    .doc(customer.id)
    .collection("cloudConnect")
    .where("cloudPlatform", "==", "amazon-web-services")
    .narrow<CloudConnectAmazonWebServices>();

  const queryAssets = getCollection(AssetModel)
    .where("customer", "==", customer.ref)
    .where("type", "in", ["amazon-web-services", "amazon-web-services-standalone"])
    .narrow<AmazonWebServicesAssetModel>();

  const [linkedAccounts, isLoadingAccounts] = useCollectionData(queryCloudConnect, { idField: "id" });
  const [assetsAccounts, isLoadingAssetsAccounts] = useCollectionData(queryAssets, { idField: "id" });

  // return all accounts and the number of accounts that are not configured yet
  return useMemo<[AWSAccount[], number, boolean]>(() => {
    let accounts: AWSAccount[] = [];
    let numNotConfiguredAccounts = 0;

    if (linkedAccounts && assetsAccounts) {
      accounts = linkedAccounts.map((connectedAccount) => ({
        id: connectedAccount.id,
        accountId: connectedAccount.accountId,
        roleName: connectedAccount.roleName,
        featureNames: getEnabledFeaturesNames(connectedAccount),
        status: connectedAccount?.error ? AccountStatus.unhealthy : AccountStatus.healthy,
        error: connectedAccount.error,
        timeLinked: connectedAccount.timeLinked ? DateTime.fromJSDate(connectedAccount.timeLinked.toDate()) : null,
      }));

      // add accounts form assets that are not connected
      assetsAccounts.forEach((assetsAccount) => {
        const accountId = assetsAccount.properties.accountId;

        // add only if the same account is not linked yet
        if (
          assetsAccount?.properties?.organization?.payerAccount &&
          findIndex(linkedAccounts, ["accountId", accountId]) === -1
        ) {
          numNotConfiguredAccounts++;
          accounts.push({
            id: assetsAccount.id,
            accountId,
            roleName: "",
            featureNames: [],
            timeLinked: null,
            status: AccountStatus.notConfigured,
          } as AWSAccount);
        }
      });
    }
    return [accounts, numNotConfiguredAccounts, isLoadingAccounts || isLoadingAssetsAccounts];
  }, [linkedAccounts, assetsAccounts, isLoadingAccounts, isLoadingAssetsAccounts]);
}

export const useStandaloneAccounts = () => {
  const { customer } = useCustomerContext();
  const ref = useMemo(
    () =>
      getCollection(CustomerModel)
        .doc(customer.id)
        .collection("cloudConnect")
        .where("type", "==", "amazon-web-services-standalone")
        .narrow<CloudConnectModelAWSStandalone>(),
    [customer.id]
  );

  return useCollectionData(ref);
};
