import { useCallback, useEffect } from "react";

import { useHistory } from "react-router";
import { Aggregator, type ReportConfig, Roles, TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import debounce from "lodash/debounce";

import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { updateReportConfigRequest } from "../../../../Pages/CloudAnalytics/api";
import { useAnalyticsContext } from "../../../../Pages/CloudAnalytics/CloudAnalyticsContext";
import {
  useMetricSplitsContext,
  useReportConfig,
  useReportContext,
  useReportDimensionsContext,
  useReportSaverContext,
} from "../../../../Pages/CloudAnalytics/Context";
import { buildConfig, removeIrrelevantFilters } from "../../../../Pages/CloudAnalytics/report/utils";
import { isComparative, isEditor, parseMetricSplitsForReport } from "../../../../Pages/CloudAnalytics/utilities";
import { type MetadataOption, type MetricWSnap } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import { sanitizeDate } from "../../../../utils/common";
import { TimestampFromDate } from "../../../../utils/firebase";
import mixpanel from "../../../../utils/mixpanel";
import useGenerateReport from "../../useGenerateReport";
import useRouteMatchURL from "../../useRouteMatchURL";
import useEqualityCheck from "./useEqualityCheck";

export enum NewReportType {
  Duplicate = "duplicate",
  SaveAs = "save-as",
  NewDataSource = "new-data-source",
}

const useReportSaver = () => {
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { metricSplits } = useMetricSplitsContext();
  const { attributions } = useAttributionsContext();
  const { dimensions } = useReportDimensionsContext();
  const { report } = useReportContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { defaultReportConfig } = useAnalyticsContext();

  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();
  const generateReport = useGenerateReport();
  const { customer } = useCustomerContext();
  const { reportSaver, dispatchReportSaver } = useReportSaverContext();
  const { initialConfig } = reportSaver;
  const { name, description } = reportConfig;
  const isCurrentUserEditor = report ? isEditor(currentUser.email, report.data) : false;

  const prepareConfig = useCallback(
    (newMetadata: MetadataOption[]) => {
      const {
        metricFilters,
        aggregator,
        metric,
        calculatedMetric,
        currency,
        renderer,
        timeInterval,
        customTimeRange,
        rowOrder,
        colOrder,
        features,
        comparative,
        logScale,
        timeRangeOption,
        includeCredits,
        excludePartialData,
        extendedMetric,
        limitAggregation,
        includeSubtotals,
        dataSource,
        forecastSettings,
      } = reportConfig;
      const config = newMetadata.reduce(buildConfig, {
        metricFilters,
        aggregator,
        metric,
        dataSource,
        calculatedMetric: calculatedMetric?.snapshot.ref ?? null,
        currency,
        renderer,
        timeInterval,
        customTimeRange,
        rowOrder,
        colOrder,
        features,
        rows: [],
        cols: [],
        count: null,
        filters: [],
        optional: [],
        comparative: comparative && isComparative(comparative) ? comparative : null,
        logScale,
        timeSettings: {
          mode: timeRangeOption.mode,
          amount: timeRangeOption.amount,
          unit: timeRangeOption.mode === TimeSettingsMode.Fixed ? timeInterval : timeRangeOption.time,
          includeCurrent: timeRangeOption.includeCurrent,
        },
        includeCredits,
        excludePartialData,
        extendedMetric,
        splits: parseMetricSplitsForReport(metricSplits),
        limitAggregation,
        includeSubtotals,
        forecastSettings,
      });

      config.filters = removeIrrelevantFilters(
        config.filters,
        attributions.map((attr) => attr.ref.id)
      );

      if (config.customTimeRange?.from?.isValid && config.customTimeRange?.to?.isValid) {
        config.customTimeRange = {
          from: TimestampFromDate(sanitizeDate(config.customTimeRange.from).toJSDate()),
          to: TimestampFromDate(sanitizeDate(config.customTimeRange.to).toJSDate()),
        };
      }
      return config;
    },
    [attributions, reportConfig, metricSplits]
  );

  const prepareConfigFromDimension = useCallback(() => prepareConfig(dimensions ?? []), [dimensions, prepareConfig]);

  const updateReportConfig = useCallback(
    async (config: ReportConfig, newName?: string) => {
      await updateReportConfigRequest(config, newName ?? name, report?.snapshot?.id ?? "", description);
      const eventName = report?.data.draft ? "save" : "update";
      mixpanel.track(`analytics.reports.${eventName}`, { reportId: report?.snapshot?.id, draft: false });
    },
    [description, name, report?.data.draft, report?.snapshot.id]
  );

  const commitConfigChanges = useCallback(
    (config: ReportConfig, newName?: string) => {
      const debouncedFunction = debounce(
        async () => {
          if (config?.aggregator === Aggregator.COUNT && !config.count) {
            return;
          }
          await updateReportConfig(config, newName);
        },
        1500,
        {
          trailing: true,
          leading: true,
        }
      );

      return debouncedFunction();
    },
    [updateReportConfig]
  );

  const handleSave = useCallback(
    async (newName?: string) => {
      try {
        const config = prepareConfig(dimensions ?? []);
        await commitConfigChanges(config, newName);
        dispatchReportSaver({
          payload: {
            hasUnsavedChanges: false,
            saved: true,
            initialConfig: config,
          },
        });
        if (newName) {
          dispatchReportConfig({ payload: { name: newName } });
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [prepareConfig, dimensions, commitConfigChanges, dispatchReportSaver, dispatchReportConfig]
  );

  const handleResetReport = useCallback(
    async (newConfig: ReportConfig) => {
      const { timeSettings, calculatedMetric } = newConfig;
      dispatchReportConfig({
        payload: {
          ...newConfig,
          name: "",
          description: "",
          customTimeRange: null,
          calculatedMetric: calculatedMetric as MetricWSnap | null,
          timeRangeOption: {
            label: "",
            mode: timeSettings?.mode || TimeSettingsMode.Current,
            time: timeSettings?.unit || TimeInterval.DAY,
            amount: timeSettings?.amount,
            includeCurrent: timeSettings?.includeCurrent,
          },
        },
      });
      dispatchReportSaver({
        payload: {
          hasUnsavedChanges: false,
          saved: true,
          initialConfig: newConfig,
        },
      });
    },
    [dispatchReportSaver, dispatchReportConfig]
  );

  const createNewReport = useCallback(
    async (config, newReportType: NewReportType, newName?: string): Promise<string> => {
      const draft = newName === "";
      const expireBy = draft ? TimestampFromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)) : null;
      const newReportId = await generateReport(
        {
          name: newName ?? `Copy of ${name}`,
          description,
          collaborators: [{ email: currentUser.email, role: Roles.OWNER }],
          config,
          draft,
          expireBy,
        },
        true
      );

      if (newReportType !== NewReportType.NewDataSource) {
        mixpanel.track(`analytics.reports.${newReportType}`, { reportId: newReportId, draft: false });
        dispatchReportConfig({ payload: { name: newName ?? `Copy of ${name}` } });
      }

      return newReportId;
    },
    [generateReport, name, description, currentUser.email, dispatchReportConfig]
  );

  const getNewReportURL = useCallback(
    (newReportId: string) => `${routeMatchURL.slice(0, routeMatchURL.lastIndexOf("/"))}/${newReportId}`,
    [routeMatchURL]
  );

  const handleSaveAs = useCallback(
    async (name) => {
      const config = prepareConfig(dimensions ?? []);
      dispatchReportSaver({ payload: { hasUnsavedChanges: false, saved: true, initialConfig: config } });
      const newReportId = await createNewReport(config, NewReportType.SaveAs, name);
      setTimeout(() => {
        history.push(getNewReportURL(newReportId));
      }, 0);
      dispatchReportSaver({ payload: { saved: true } });

      return newReportId;
    },
    [createNewReport, dimensions, dispatchReportSaver, getNewReportURL, history, prepareConfig]
  );

  const goBackToReports = useCallback(() => {
    history.push(`/customers/${customer.id}/analytics/reports`);
  }, [history, customer.id]);

  const handleSaveAsComponentSave = useCallback(
    async (newName: string, onClose: () => void) => {
      if (!newName) {
        return;
      }
      dispatchReportSaver({ payload: { hasUnsavedChanges: false } });
      try {
        if (isCurrentUserEditor) {
          dispatchReportConfig({ payload: { name: newName } });
          await handleSave(newName);
        } else {
          await handleSaveAs(newName);
        }
        onClose();
        goBackToReports();
      } catch (e) {
        dispatchReportSaver({ payload: { hasUnsavedChanges: true } });
      }
    },
    [goBackToReports, handleSave, handleSaveAs, isCurrentUserEditor, dispatchReportConfig, dispatchReportSaver]
  );

  const handleSaveName = useCallback(
    async (newName: string, onFinish: () => void) => {
      try {
        if (isCurrentUserEditor) {
          dispatchReportConfig({ payload: { name: newName } });
          await handleSave(newName);
        } else {
          await handleSaveAs(newName);
        }
      } catch (e) {
        dispatchReportSaver({ payload: { hasUnsavedChanges: true } });
      } finally {
        onFinish();
      }
    },
    [handleSave, handleSaveAs, isCurrentUserEditor, dispatchReportConfig, dispatchReportSaver]
  );

  const onSaveReportHeader = useCallback(
    async (name: string | undefined, saveWithNewId: boolean) => {
      let reportId = report?.snapshot.id;
      if (saveWithNewId) {
        reportId = await handleSaveAs(name);
      } else {
        await handleSave(name);
      }
      dispatchReportSaver({ payload: { hasUnsavedChanges: false } });
      return reportId;
    },
    [report, dispatchReportSaver, handleSaveAs, handleSave]
  );

  const equalityCheck = useEqualityCheck({
    name,
    description,
    newConfig: dimensions ? prepareConfig(dimensions) : null,
    reportName: report?.data.name || "",
    reportDescription: report?.data.description || "",
    initialConfig,
    dimensions,
  });

  // Load report config to state once
  useEffect(() => {
    if (!dimensions || !!initialConfig) {
      return;
    }
    dispatchReportSaver({ payload: { initialConfig: prepareConfig(dimensions) } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions, dispatchReportSaver]);

  useEffect(() => {
    // check deep equality between original and changed config, name, description in order to indicate if draft report has changed
    // prepareConfig added to dependencies in order to avoid cases in which Save button won't be active when saving changes in existing report and then make more changes
    dispatchReportSaver({
      payload: {
        hasUnsavedChanges:
          report?.data.draft && report?.data.config !== null && !defaultReportConfig ? true : !equalityCheck,
      },
    });
  }, [equalityCheck, prepareConfig, report?.data.config, report?.data.draft, dispatchReportSaver, defaultReportConfig]);

  return {
    handleResetReport,
    createNewReport,
    onSaveReportHeader,
    prepareConfigFromDimension,
    handleSave,
    handleSaveName,
    handleSaveAsComponentSave,
    getNewReportURL,
  };
};

export default useReportSaver;
