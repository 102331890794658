import { type Theme } from "@mui/material/styles";
import type * as Highcharts from "highcharts";

import { type DataRecord } from "../ReportData";
import { getColors } from "../utilities";

type Options = Omit<Highcharts.Options, "xAxis" | "yAxis"> & {
  xAxis: Highcharts.XAxisOptions;
  yAxis: Highcharts.YAxisOptions;
};

export function getWidgetFormatter(cols?: DataRecord[]) {
  const isDate = cols?.every((c) => c?.type === "datetime") ?? false;
  const weekColIndex = cols?.findIndex((c) => c.id === "datetime:week") ?? -1;
  const weekDayColIndex = cols?.findIndex((c) => c.id === "datetime:week_day") ?? -1;
  const colsLength = cols?.length ?? 0;

  return (value: string | number): string => {
    const valueStr = value.toString();
    const colParts = valueStr.split("-");

    if (colParts.length !== colsLength) {
      return valueStr;
    }

    if (weekDayColIndex !== -1 || weekColIndex !== -1) {
      // Use abbreviated weekday names e.g. "Sun" for "Sunday"
      if (weekDayColIndex !== -1) {
        colParts[weekDayColIndex] = colParts[weekDayColIndex].slice(0, 3);
      }

      // Change "W17 (Apr 25)" to "Apr 25"
      if (weekColIndex !== -1) {
        colParts[weekColIndex] = colParts[weekColIndex].slice(5, 11);
      }

      return colParts.join("-");
    }

    // validating if is date and if is valid date
    if (isDate && colParts.length > 2 && !isNaN(Date.parse(valueStr))) {
      if (colParts.length > 3) {
        value = valueStr.slice(0, 10);
      }
      return `${new Date(value).toLocaleString("en", { month: "short" })} ${new Date(value).toLocaleString("en", {
        day: "numeric",
      })}`;
    }

    return valueStr;
  };
}

export const getWidgetChartOptions = (theme: Theme, options: Options, enableLegend: boolean, cols?: DataRecord[]) => {
  const widgetFormatter = getWidgetFormatter(cols);

  const numCategories = options.xAxis?.categories?.length ?? 0;
  if (numCategories > 0) {
    let tickInterval = 1;
    if (numCategories > 10) {
      tickInterval = Math.floor(numCategories / 10) + 1;
    }
    const style = {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(11),
    };
    options.xAxis = {
      ...options.xAxis,
      labels: {
        ...options.xAxis.labels,
        style,
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          return widgetFormatter(this.value);
        },
      },
      tickInterval,
    };
    options.yAxis = {
      ...options.yAxis,
      labels: {
        ...options.yAxis.labels,
        style,
      },
    };
  }

  if (!enableLegend) {
    options.legend = { enabled: false };
  }

  options.exporting = { enabled: false };
  options.chart = {
    ...options.chart,
    animation: false,
  };
  options.colors = getColors(theme.palette.mode);
  options.tooltip = {
    ...options.tooltip,
    outside: true,
  };
  options.plotOptions = {
    ...options.plotOptions,
    series: {
      ...(options.plotOptions?.series ?? {}),
      animation: false,
    },
  };

  return options;
};
