import { useCallback } from "react";

import { ContractModel, type TierPackageType, TierPackageTypes } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import { type FormikHelpers } from "formik";
import { type DateTime } from "luxon";

import { settingsGeneralTexts } from "../../../assets/texts";
import { useCurrency } from "../../../Components/hooks/useCurrency";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import {
  useCancelTrialContract,
  useCreateTrialContract,
  useEditCustomerNotifications,
  useSetCustomerTiers,
  useUpdateCustomerWidgets,
  useUpdateTrialContract,
} from "./api";
import { resetCustomerTrialCustomLength, updateCustomerTrialCustomLength, updateDirtyFieldsOnDb } from "./db";
import { getAddedDomains, getDirtyFields, getRemovedDomains } from "./helpers";
import { type SettingsValuesType } from "./types";
import { isPaidTier } from "./utils";

export const useGetCurrentActiveContractByType = () => {
  const { customer } = useCustomerContext();
  const [contracts, contractsLoading] = useCollectionData(
    getCollection(ContractModel)
      .where("customer", "==", customer.ref)
      .where("type", "in", [TierPackageTypes.NAVIGATOR, TierPackageTypes.SOLVE])
      .orderBy("timestamp", "desc"),
    {
      idField: "id",
    }
  );

  return useCallback(
    (type) => {
      if (contractsLoading || !contracts) {
        return null;
      }

      return contracts.find((contract) => contract?.type === type);
    },
    [contracts, contractsLoading]
  );
};

export const useEndTrial = () => {
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const cancelTrialContract = useCancelTrialContract();
  const getCurrentActiveContractByType = useGetCurrentActiveContractByType();

  return useCallback(
    async (type: TierPackageType) => {
      try {
        const currentActiveContractByType = getCurrentActiveContractByType(type);

        if (!currentActiveContractByType && customer.tiers?.navigator?.trialCustomLength) {
          await resetCustomerTrialCustomLength(customer.id);
        } else if (currentActiveContractByType) {
          await cancelTrialContract(currentActiveContractByType.id);
        }
        successSnackbar(settingsGeneralTexts.TRIAL_ENDED_SUCCESSFULLY);

        mixpanel.track(`${type}.trial.end`, {
          user: currentUser.email,
          customerId: customer.id,
        });
      } catch (error: any) {
        consoleErrorWithSentry(error);
        errorSnackbar(settingsGeneralTexts.TRIAL_ENDED_ERROR);
      }
    },
    [
      getCurrentActiveContractByType,
      cancelTrialContract,
      successSnackbar,
      errorSnackbar,
      currentUser.email,
      customer.id,
      customer.tiers?.navigator?.trialCustomLength,
    ]
  );
};

export const useExtendTrial = () => {
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const updateTrialContract = useUpdateTrialContract();
  const getCurrentActiveContractByType = useGetCurrentActiveContractByType();

  return useCallback(
    (type: TierPackageType, tierId: string | undefined) => async (endDate: DateTime) => {
      try {
        if (!tierId) {
          errorSnackbar(settingsGeneralTexts.TRIAL_ENDED_TIER_ERROR);
          return;
        }

        const currentActiveContractByType = getCurrentActiveContractByType(type);
        if (!currentActiveContractByType) {
          errorSnackbar(settingsGeneralTexts.FAILED_TO_GET_CURRENT_CONTRACT);
          return;
        }

        await updateTrialContract(currentActiveContractByType.id, {
          endDate: endDate.toJSDate().toISOString(),
        });
        successSnackbar(settingsGeneralTexts.TRIAL_EXTENDED_SUCCESSFULLY);

        mixpanel.track(`${type}.trial.extend`, {
          user: currentUser.email,
          customerId: customer.id,
        });
      } catch (error: any) {
        consoleErrorWithSentry(error);
        errorSnackbar(settingsGeneralTexts.TRIAL_EXTENDED_ERROR);
      }
    },
    [
      getCurrentActiveContractByType,
      updateTrialContract,
      successSnackbar,
      errorSnackbar,
      currentUser.email,
      customer.id,
    ]
  );
};

export const useExtendTrialCustomLength = () => {
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  return useCallback(
    async (extendedLength: number) => {
      try {
        await updateCustomerTrialCustomLength(customer.id, extendedLength);
        successSnackbar(settingsGeneralTexts.TRIAL_EXTENDED_SUCCESSFULLY);

        mixpanel.track(`navigator.trial.extend`, {
          user: currentUser.email,
          customerId: customer.id,
        });
      } catch (error: any) {
        consoleErrorWithSentry(error);
        errorSnackbar(settingsGeneralTexts.TRIAL_EXTENDED_ERROR);
      }
    },
    [successSnackbar, errorSnackbar, currentUser.email, customer.id]
  );
};

export const useSubmit = (initialValues) => {
  const { customer } = useCustomerContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { getTrialTierRef, allTiers } = useTier();
  const createTrialContract = useCreateTrialContract();
  const updateCustomerWidgets = useUpdateCustomerWidgets();
  const editCustomerNotifications = useEditCustomerNotifications();
  const setCustomerTiers = useSetCustomerTiers();
  const { customerCurrency } = useCurrency();

  return useCallback(
    async (values: SettingsValuesType, formikHelpers: FormikHelpers<SettingsValuesType>) => {
      try {
        const dirtyFields = getDirtyFields(initialValues, values);
        const navigatorTierTrialStartDate = dirtyFields?.navigatorTierTrialStartDate
          ? values.navigatorTierTrialStartDate
          : undefined;
        const navigatorTierTrialEndDate = dirtyFields?.navigatorTierTrialEndDate
          ? values.navigatorTierTrialEndDate
          : undefined;
        const navigatorTrialTier = getTrialTierRef(TierPackageTypes.NAVIGATOR);
        const solveTierTrialStartDate = dirtyFields?.solveTierTrialStartDate
          ? values.solveTierTrialStartDate
          : undefined;
        const solveTierTrialEndDate = dirtyFields?.solveTierTrialEndDate ? values.solveTierTrialEndDate : undefined;
        const solveTrialTier = getTrialTierRef(TierPackageTypes.SOLVE);
        const navigatorTierId = dirtyFields?.navigatorTierId ? values.navigatorTierId : undefined;
        const solveTierId = dirtyFields?.solveTierId ? values.solveTierId : undefined;
        const navigatorTier = allTiers?.find((tier) => tier.ref.id === navigatorTierId);
        const solveTier = allTiers?.find((tier) => tier.ref.id === solveTierId);

        await updateDirtyFieldsOnDb({
          customerId: customer.id,
          addedDomains: getAddedDomains({ domains: values.domains, initialDomains: initialValues.domains }),
          removedDomains: getRemovedDomains({ domains: values.domains, initialDomains: initialValues.domains }),
          currency: dirtyFields?.currency ? values.currency : undefined,
          primaryDomain: dirtyFields?.domains ? values.domains.find((domain) => domain.isPrimary)?.name : undefined,
          cloudProvider: dirtyFields?.cloudProvider ? values.cloudProvider : undefined,
          customerName: dirtyFields?.customerName ? values.customerName : undefined,
          linesPerInvoice: dirtyFields?.linesPerInvoice ? values.linesPerInvoice : undefined,
          customerClassification: dirtyFields?.customerClassification ? values.customerClassification : undefined,
          earlyAccessFeatures: dirtyFields?.earlyAccessFeatures ? values.earlyAccessFeatures : undefined,
          skipRemedyBreach: dirtyFields?.skipRemedyBreach ? values.skipRemedyBreach : undefined,
          customerSegment: dirtyFields?.customerSegment ? values.customerSegment : undefined,
          navigatorTrialCustomLength: dirtyFields?.navigatorTrialCustomLength
            ? values.navigatorTrialCustomLength
            : undefined,
        });

        if (dirtyFields?.currency) {
          // update customer dashboard widgets for all orgs to reflect currency change
          await updateCustomerWidgets(customer.id, true);
        }
        if (
          dirtyFields?.customerClassification &&
          initialValues.customerClassification &&
          values.customerClassification
        ) {
          // update customer notifications to reflect classification change
          await editCustomerNotifications({
            oldClassification: initialValues.customerClassification,
            newClassification: values.customerClassification,
            customerId: customer.id,
          });
        }
        if (dirtyFields?.earlyAccessFeatures) {
          // track feature flag change
          mixpanel.track("feature-flag-change", {
            user: currentUser.email,
            flags: values.earlyAccessFeatures,
            customerId: customer.id,
          });
        }

        if (navigatorTierId || solveTierId) {
          await setCustomerTiers(customer.id, {
            navigatorTierId,
            solveTierId,
          });
        }
        if (navigatorTierTrialStartDate && navigatorTrialTier && !isPaidTier(navigatorTier)) {
          mixpanel.track("navigator.trial.start", {
            user: currentUser.email,
            customerId: customer.id,
          });

          await createTrialContract({
            customerID: customer.id,
            type: TierPackageTypes.NAVIGATOR,
            tier: navigatorTrialTier.id,
            startDate: navigatorTierTrialStartDate.toDate().toISOString(),
            endDate: navigatorTierTrialEndDate?.toDate().toISOString(),
            currency: customerCurrency,
          });
        }

        if (solveTierTrialStartDate && solveTrialTier && !isPaidTier(solveTier)) {
          mixpanel.track("solve.trial.start", {
            user: currentUser.email,
            customerId: customer.id,
          });

          await createTrialContract({
            customerID: customer.id,
            type: TierPackageTypes.SOLVE,
            tier: solveTrialTier.id,
            startDate: solveTierTrialStartDate.toDate().toISOString(),
            endDate: solveTierTrialEndDate?.toDate().toISOString(),
            currency: customerCurrency,
          });
        }

        successSnackbar(settingsGeneralTexts.UPDATED_SUCCESSFULLY);
      } catch (error: any) {
        consoleErrorWithSentry(error);
        errorSnackbar(error.message ?? "error");
      }
      formikHelpers.setSubmitting(false);
    },
    [
      initialValues,
      getTrialTierRef,
      allTiers,
      customer.id,
      successSnackbar,
      updateCustomerWidgets,
      editCustomerNotifications,
      currentUser.email,
      setCustomerTiers,
      createTrialContract,
      errorSnackbar,
      customerCurrency,
    ]
  );
};
