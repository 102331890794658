import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type FormattedContract } from "./types";

const itemsDescriptions: FilterTableItemDescriptor<FormattedContract>[] = [
  {
    value: "data._product",
    headerText: "Product",
    filterType: "string",
  },
  {
    value: "data._status",
    headerText: "Status",
    filterType: "string",
  },
  {
    value: "data._customerCommitmentType",
    headerText: "Type",
    filterType: "string",
  },
  {
    value: "data.startDate",
    headerText: "Start Date",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    value: "data.endDate",
    headerText: "End Date",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  // discount should be hidden for non-doit employees
  {
    value: "data.discount",
    headerText: "Discount",
    filterType: "number",
  },
  {
    value: "data._entity",
    headerText: "Billing Profile",
    filterType: "string",
  },
  {
    value: "data._commitmentValue",
    headerText: "Commitment Value",
    filterType: "number",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
