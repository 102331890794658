import { emphasize } from "@mui/material";
import { deepOrange, green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

import { ThemeModes } from "../../muiThemeTypes";

export const useStyles = makeStyles((theme) => ({
  cardHeader: {
    height: 60,
    "& .MuiCardHeader-content": {
      minWidth: 0,
    },
  },
  leftPanel: {
    height: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      background: "#C5C5C5",
      "-webkit-border-radius": "1ex",
    },
  },
  middlePanelHeight: {
    height: "100%",
    minHeight: "500px",
  },
  input: {
    ...theme.typography.body2,
    padding: theme.spacing(0.75, 1.75),
    height: "auto",
    minHeight: "1.1876em",
  },
  autocompleteInputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      paddingTop: 3,
      paddingBottom: 3,
    },
  },
  autocompleteInput: {
    ...theme.typography.body2,
  },
  selectOutlined: {
    "&$outlined": {
      ...theme.typography.body2,
      padding: theme.spacing(0.75, 1.75),
    },
  },
  timeRangeRoot: {
    paddingRight: 0,
    "& >input": {
      ...theme.typography.body2,
      padding: theme.spacing(0.875, 1.75),
      paddingRight: 0,
    },
  },
  timeRangeEndAdornment: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  outlined: {},
  alert: {},
  hideAlertIcon: {
    display: "none",
  },
  colTargetBox: {
    marginBottom: theme.spacing(1),
  },
  rowTargetBox: {
    border: 0,
  },
  accordionTarget: {
    border: 0,
    padding: 0,
    marginBottom: "8px",
  },
  leftTargetBoxWrapper: {
    marginBottom: "1.5rem",
  },
  rightPanelGrid: {
    display: "flex",
    flexDirection: "column",
  },
  rightPanelPaper: {
    height: "100%",
    padding: theme.spacing(1),
  },
  rightPanelGridContainer: {
    height: "100%",
  },
  autocompleteInputAdornment: {
    paddingRight: theme.spacing(1),
  },
  colSortToggleGroup: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  rowTargetBoxTitle: {
    marginLeft: "8px",
  },
  colTargetBoxTitle: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  labelsChip: {
    backgroundColor: theme.palette.mode === ThemeModes.LIGHT ? deepOrange[100] : deepOrange[800],
    color: theme.palette.text.primary,
    "&:focus": {
      backgroundColor: theme.palette.mode === ThemeModes.LIGHT ? deepOrange[100] : deepOrange[800],
    },
    "&:hover": {
      backgroundColor:
        theme.palette.mode === ThemeModes.LIGHT ? emphasize(deepOrange[200], 0.1) : emphasize(deepOrange[800], 0.1),
    },
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  extendedIcon: {
    margin: theme.spacing(0, 1, 0, -0.25),
  },
  filtersSectionGridContainer: {
    height: "100%",
    overflow: "hidden",
  },
  filtersSection: {
    height: "100%",
    overflow: "auto",
    width: "100%",
  },
  descriptionSection: {},
  list: {},
  listItem: {
    fontSize: "0.875rem",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
  },
  chip: {
    maxWidth: 240,
    margin: theme.spacing(0.4, 0.4, 0, 0),
  },
  chipGreen: {
    borderColor: green[500],
    color: green[500],
  },
  customMenu: {
    // !important is applied because the default z-index is applied inline
    zIndex: "1501 !important",
  },
  buttonMargin: {
    margin: theme.spacing(0.5),
  },
  topPanelConfig: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
    padding: theme.spacing(0.5, 0),
    marginBottom: theme.spacing(0),
    flex: 0,
  },
  flexLeft: {
    flex: "1 1 auto",
  },
  topPanelConfigOption: {
    flex: "0 0 auto",
    width: theme.spacing(21),
    margin: theme.spacing(0, 1),
  },
  comparativeToggle: {
    marginTop: theme.spacing(0.5),
  },
  excludePartialForm: {
    marginRight: theme.spacing(0.25),
  },
  excludePartialCheckbox: {
    padding: theme.spacing(0, 0.25),
  },
  excludePartialRoot: {
    marginLeft: theme.spacing(-0.5),
    alignItems: "flex-start",
  },
  excludePartialLabel: {
    lineHeight: 1,
    marginTop: theme.spacing(0.5),
  },
  includeCreditsForm: {
    marginRight: theme.spacing(0.25),
  },
  includeCreditsCheckbox: {
    padding: theme.spacing(0, 0.25),
  },
  includeCreditsRoot: {
    marginLeft: theme.spacing(-0.5),
    alignItems: "flex-start",
  },
  includeCreditsLabel: {
    lineHeight: 1,
    marginTop: theme.spacing(0.5),
  },
  timezone: {
    width: theme.spacing(24),
  },
  paper: {
    width: theme.spacing(36),
  },
  currencySymbol: {
    marginRight: theme.spacing(1.5),
  },
}));
