import { Stack, Typography } from "@mui/material";

export const CardTextPlaceholder = ({ title }: { title: string }) => (
  <Stack alignItems="center">
    <Typography variant="body2" fontWeight="600" textAlign="center">
      {title}
    </Typography>
    <Typography data-cy="flexsave-activation" variant="body2" textAlign="center">
      will start showing here 7 days after Flexsave activation
    </Typography>
  </Stack>
);
