import { useContext } from "react";

import { Box, CardHeader, type CardHeaderProps, Stack } from "@mui/material";

import { ThreeDotsMenu } from "../../../../../Components/ThreeDotsMenu";
import { WidgetHeaderWrapperContext } from "./widgetHeaderContext";
import { WidgetMenuWrapperContext } from "./widgetMenuContext";

export function WidgetCardHeader(props: CardHeaderProps) {
  const dragComponent = useContext(WidgetHeaderWrapperContext);
  const [deleteWidgetOperation] = useContext(WidgetMenuWrapperContext);

  return (
    <Stack
      direction="row"
      width="100%"
      alignItems={"center"}
      justifyContent="space-between"
      sx={{
        borderBottomColor: "general.divider",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
      }}
    >
      <CardHeader
        {...props}
        data-cy="widget-title"
        avatar={dragComponent ? <Box m={2}>{dragComponent}</Box> : props.avatar}
        titleTypographyProps={{ variant: "subtitle2", textOverflow: "ellipsis", noWrap: true }}
        subheaderTypographyProps={{ variant: "caption", textOverflow: "ellipsis", noWrap: true }}
        sx={{
          ".MuiCardHeader-avatar": {
            pr: 0,
            pl: 1,
          },
          ".MuiCardHeader-avatar:hover": {
            cursor: dragComponent ? "grab" : undefined,
          },
          ".MuiCardHeader-content": {
            maxWidth: `calc(100% - ${dragComponent ? "120px" : "40px"})`,
          },
          width: "100%",
          maxWidth: "100%",
          pl: dragComponent ? 0 : 2.5,
          py: 2.5,
          pr: 2,
          height: 84,
        }}
        action={
          props.action ||
          (deleteWidgetOperation && (
            <ThreeDotsMenu
              options={[
                {
                  label: "Remove widget",
                  color: "error.main",
                  key: "delete",
                  action: () => {
                    deleteWidgetOperation();
                  },
                },
              ]}
            />
          ))
        }
      />
    </Stack>
  );
}
