import { type JSX } from "react";

import { Alert, AlertTitle, CircularProgress, Stack } from "@mui/material";

type Props = {
  title: string;
  body: string | JSX.Element;
  type: "info" | "error";
};

export const OnBoardingAlert = ({ title, body, type }: Props) => (
  <Alert severity={type}>
    <Stack direction="row" spacing={1}>
      <AlertTitle>{title}</AlertTitle>
      {type === "info" && <CircularProgress size={18} />}
    </Stack>
    {body}
  </Alert>
);
