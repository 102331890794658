import { useMemo } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { LinearProgressWithLabel } from "../../ProgressWithLabel";
import { AvaIcon } from "./AvaIcon";

type Props = {
  customerId: string;
};
export default function LoadingCustomData({ customerId }: Props) {
  const [customerMetadata] = useDocumentData(
    getCollection(AppModel).doc("ava").collection("customersMetadata").doc(customerId)
  );

  const { customer } = useCustomerContext({ allowNull: true });

  const progress = useMemo<number | null>(() => {
    if (!customer) {
      return null;
    }

    const isPresentationMode = isCustomerInPresentationMode(customer);

    if (!customerMetadata || isPresentationMode) {
      return null;
    }

    if (typeof customerMetadata?.progress === "undefined") {
      return null;
    }

    if (!isFinite(customerMetadata.progress)) {
      return null;
    }

    if (customerMetadata.progress >= 99.1) {
      return null;
    }

    return Math.round(customerMetadata.progress);
  }, [customer, customerMetadata]);

  if (progress === null) {
    return null;
  }

  return (
    <Box display="flex" gap={2} px={15} pb={2}>
      <AvaIcon />
      <Box pr={4} display="flex" flexDirection="column" flexGrow={1}>
        <Typography variant="caption">Fetching your data, you can still interact with Ava</Typography>
        <LinearProgressWithLabel value={progress} />
      </Box>
    </Box>
  );
}
