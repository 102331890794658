export const doitDomain = "doit.com";
export const doitPreviousDomain = "doit-intl.com";

export const isLocalNetwork = (hostname: string) =>
  ["localhost", "127.0.0.1", "", "::1", "0.0.0.0"].includes(hostname) ||
  hostname.startsWith("192.168.") ||
  hostname.startsWith("10.0.") ||
  hostname.endsWith(".local");
export const isPartnerDomain = (hostname = window.location.hostname) =>
  !isLocalNetwork(hostname) &&
  !hostname.endsWith("doit.com") &&
  !hostname.endsWith("d0it.com") &&
  hostname !== `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`;
