import axios from "axios";
import {
  type ActionCodeSettings,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  signInWithEmailLink as firebaseSignInWithEmailLink,
  signInWithRedirect as firebaseSignInWithRedirect,
  type User,
  type UserCredential,
} from "firebase/auth";

import { routes } from "../../constants/navigation";
import { auth, customAuth, rootAuth } from "../../utils/firebase";
import { getSsoInstance } from "./helpers";
import { type UserAuthConfig } from "./types";

// wrapper for firebase auth actions that require a whitelisted domain. by wrapping the URL, we are able to conduct those actions on white-labeled domains that are not whitelisted by firebase.
async function getAuthUrlActionWrapper(finalUrl: string) {
  // if origin is localhost, we don't need to wrap the url
  if (window.location.hostname === "localhost") {
    return finalUrl;
  }
  const fetchUrl = new URL(`/auth/url-action-wrapper`, window.location.origin);
  fetchUrl.searchParams.set("finalUrl", finalUrl);
  const fetchUrlString = `${fetchUrl.pathname}${fetchUrl.search}`; // relative url
  return (await axios.get(fetchUrlString)).data.url;
}

export const sendPasswordResetEmail = async (email: string, tenantId: string | null): Promise<void> => {
  auth.tenantId = tenantId;
  const url = await getAuthUrlActionWrapper(`${window.location.origin}${routes.signin}`);
  return firebaseSendPasswordResetEmail(auth, email, {
    url,
  });
};

export const signInWithEmailAndPassword = (
  email: string,
  password: string,
  tenantId: string | null
): Promise<UserCredential> => {
  auth.tenantId = tenantId;
  return firebaseSignInWithEmailAndPassword(auth, email, password);
};

export const sendEmailVerification = async (user: User | null, onlyIfNotSentAlready?: boolean): Promise<void> => {
  if (user) {
    const localStorageKey = `email_ver_sent_${user.uid}`;
    if (onlyIfNotSentAlready && localStorage.getItem(localStorageKey)) {
      return Promise.resolve();
    }
    const url = await getAuthUrlActionWrapper(window.location.origin);
    localStorage.setItem(localStorageKey, "true");
    return firebaseSendEmailVerification(user, {
      url,
    });
  }
  return Promise.reject(new Error("no user cred"));
};

export const sendSignInLinkToEmail = (email: string, tenantId: string): Promise<void> => {
  const actionCodeSettings: ActionCodeSettings = {
    handleCodeInApp: true,
    url: window.location.origin + routes.signin,
  };
  auth.tenantId = tenantId;
  return firebaseSendSignInLinkToEmail(auth, email, actionCodeSettings);
};

export const isSignInWithEmailLink = (): boolean => firebaseIsSignInWithEmailLink(auth, window.location.href);

export const signInWithEmailLink = (email: string, tenantId: string): Promise<UserCredential> => {
  auth.tenantId = tenantId;
  return firebaseSignInWithEmailLink(auth, email, window.location.href);
};

export const signInWithRedirect = (authConfig: UserAuthConfig): Promise<void> => {
  const { providerId, tenantId, provider, useSsoWithoutProxy } = authConfig;
  const providerInstance = getSsoInstance(providerId, provider);
  if (!useSsoWithoutProxy && customAuth) {
    customAuth.tenantId = tenantId;
    return firebaseSignInWithRedirect(customAuth, providerInstance);
  }
  window.localStorage.setItem("use-sso-without-proxy", "true");
  rootAuth().tenantId = tenantId;
  return firebaseSignInWithRedirect(rootAuth(), providerInstance);
};
