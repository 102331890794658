import { type DashboardModelAttributionModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { type AttributionWRef } from "../../../types";

export const getAttributionScope = (
  scopeRefs: ModelReference<DashboardModelAttributionModel>[],
  attributions: AttributionWRef[]
): AttributionWRef[] => {
  const scope: AttributionWRef[] = [];
  scopeRefs.forEach((element) => {
    const attr = attributions.find((a) => a.ref.id === element.id);
    if (attr) {
      scope.push(attr);
    }
  });
  return scope;
};
