import { Tooltip, type TooltipProps } from "@mui/material";

const HtmlTooltip = ({ children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    slotProps={{
      tooltip: {
        sx: {
          backgroundColor: "background.paper",
          color: "text.primary",
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
        },
      },
    }}
  >
    {children}
  </Tooltip>
);

export default HtmlTooltip;
