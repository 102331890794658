import { DashboardModel, type ReportConfig } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { serverTimestamp, TimestampFromDate } from "../../../utils/firebase";
import { type createReportPayload } from "./types";
import { emptyReportValues } from "./utils";

export const createReport = async (payload: createReportPayload) => {
  const docRef = getCollection(DashboardModel).doc("google-cloud-reports").collection("savedReports").newDoc();

  const draft = payload.draft ?? true;
  const expireBy = draft ? TimestampFromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)) : null;
  const emptyReport = {
    ...emptyReportValues,
    customer: payload.customer,
    collaborators: payload.collaborators,
    draft,
    organization: payload.organization,
    expireBy,
  };

  await docRef.set(emptyReport as any);
  // Avoid extra writes for New unconfigured report
  if (!payload.config) {
    return docRef.id;
  }
  const promises: Promise<void>[] = [
    docRef.update({
      config: payload.config as ReportConfig,
      timeModified: serverTimestamp(),
    }),
  ];
  if (payload.name !== "") {
    promises.push(
      docRef.update({
        name: payload.name ?? emptyReport.name,
        description: payload.description ?? emptyReport.description,
        timeModified: serverTimestamp(),
      })
    );
  }

  await Promise.all(promises);
  return docRef.id;
};
