import Button from "@mui/material/Button";

import { type Entity } from "../../../../Context/customer/EntitiesContext";
import { useToggle } from "../../../../utils/useToggle";
import EntityStatusDialog from "../../../EntityStatusDialog";

export const ChangeBillingProfileStatusButton = ({
  entity,
  onStatusChanged,
}: {
  entity: Entity;
  onStatusChanged: () => unknown;
}) => {
  const [opened, open, close] = useToggle(false);

  return (
    <>
      <Button color={entity.active ? "error" : "primary"} onClick={open} size="small">
        {entity.active ? "Deactivate" : "Activate"} billing profile
      </Button>
      {opened && (
        <EntityStatusDialog
          onClose={close}
          entity={entity}
          onRequestRefresh={onStatusChanged}
          newStatus={entity.active ? "Inactive" : "Active"}
        />
      )}
    </>
  );
};
