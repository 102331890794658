import {
  type BaseSyntheticEvent,
  type Dispatch,
  type KeyboardEvent,
  type MouseEvent,
  type RefObject,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";
import {
  type AutocompleteApi,
  type AutocompleteReshapeSource,
  type AutocompleteState,
  createAutocomplete,
  type Reshape,
} from "@algolia/autocomplete-core";
import { type AutocompletePlugin } from "@algolia/autocomplete-js";
import { type Highlighted, type RecentSearchesItem } from "@algolia/autocomplete-plugin-recent-searches/dist/esm/types";
import { type LiteClient as SearchClient } from "algoliasearch/lite";
import isEqual from "lodash/isEqual";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { useRoutesConfig } from "../../Navigation/Components/hooks";
import { useMemoCompare } from "../../utils/useMemoCompare";
import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";
import { AlgoliaIndex, CloudAnalyticsIndex } from "./consts";
import { useAssetsPlugin } from "./plugins/assets";
import { useCloudAnalyticsPlugin } from "./plugins/cloudAnalytics";
import { useCustomersPlugin } from "./plugins/customers";
import { useEntitiesPlugin } from "./plugins/entities";
import { useInvoicesPlugin } from "./plugins/invoices";
import { ALGOLIA_MAX_RECENT_ITEMS, RECENT_SEARCH_KEY, useRecentSearchPlugin } from "./plugins/recentSearches";
import { useStaticLinksPlugin } from "./plugins/staticLinks";
import { useUsersPlugin } from "./plugins/users";
import {
  type AlgoliaFilters,
  type AlgoliaIndexType,
  type AlgoliaItem,
  type AlgoliaRecentSearchedItem,
  type CustomerFromQuery,
} from "./types";
import { shouldUpdateState } from "./utils";

/*
This hook is a fix for using cmd + click to open results or open in a new tab.
The moment a user opens a new tab, userContext will change, more specifically lastLogin.
This will trigger a re-render and the autocomplete input will be erased. Using a memoized version of permissions will prevent this.
*/
export const usePermissionsMemoized = () => {
  const { userRoles: nextRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  return useMemoCompare(nextRoles.permissions, isEqual);
};

export const useAllowedRoutesMemoized = () => {
  const allowedRoutes = useRoutesConfig();
  return useMemoCompare(allowedRoutes, isEqual);
};

export const useAlgoliaAutocomplete = (
  searchClient: SearchClient,
  setSearchModalVisible: Dispatch<SetStateAction<boolean>>,
  customerFromQuery: CustomerFromQuery,
  restrictedIndices?: AlgoliaIndexType[]
): [AutocompleteApi<AlgoliaItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>, AutocompleteState<AlgoliaItem>] => {
  const history = useHistory();
  const darkMode = useDarkThemeCheck();
  const { isDoitEmployee, userId } = useAuthContext();
  const { customer } = useCustomerContext({ allowNull: true });

  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<AlgoliaItem>>({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: "",
    activeItemId: 0,
    status: "idle",
  });

  const staticLinksPlugin = useStaticLinksPlugin();
  const cloudAnalyticsPlugin = useCloudAnalyticsPlugin(restrictedIndices);
  const assetsPlugin = useAssetsPlugin(restrictedIndices);
  const customersPlugin = useCustomersPlugin(restrictedIndices);
  const entitiesPlugin = useEntitiesPlugin(restrictedIndices);
  const invoicesPlugin = useInvoicesPlugin(restrictedIndices);
  const usersPlugin = useUsersPlugin(restrictedIndices);
  const recentSearchPlugin = useRecentSearchPlugin(setSearchModalVisible, customerFromQuery, userId);

  const redirectTo = useCallback(
    (url: string) => {
      history.push(url);
      setSearchModalVisible(false);
    },
    [history, setSearchModalVisible]
  );

  // Algolia recent searches plugin does not know how to limit how many items will be saved in the local storage and thus will keep saving items until the local storage is full.
  // This is a workaround to limit the number of items saved in the local storage.
  const appendToLocalStorage = useCallback(
    (item: AlgoliaRecentSearchedItem) => {
      recentSearchPlugin?.data?.addItem(item);
      const key = `AUTOCOMPLETE_RECENT_SEARCHES:${RECENT_SEARCH_KEY(userId)}`;
      const algoliaLs = window.localStorage.getItem(key);
      if (algoliaLs) {
        const lsArray = JSON.parse(algoliaLs) as [];

        if (lsArray.length > ALGOLIA_MAX_RECENT_ITEMS) {
          localStorage.setItem(key, JSON.stringify(lsArray.slice(0, ALGOLIA_MAX_RECENT_ITEMS)));
        }
      }
    },
    [recentSearchPlugin?.data, userId]
  );

  const onSelectRow = useCallback(
    (item: AlgoliaRecentSearchedItem) => {
      appendToLocalStorage(item);
      recentSearchPlugin?.data?.addItem(item);
      if (!item.withMetaKey) {
        setSearchModalVisible(false);
      }
    },
    [appendToLocalStorage, recentSearchPlugin?.data, setSearchModalVisible]
  );

  const reshape = useCallback<Reshape<AlgoliaItem>>(({ state, sourcesBySourceId }) => {
    const filters = state.context.filters as AlgoliaFilters;
    const activeShapes: AutocompleteReshapeSource<AlgoliaItem>[] = [];
    const inactiveShapes: AutocompleteReshapeSource<AlgoliaItem>[] = [];
    if (!filters || filters.allResults) {
      return Object.values(sourcesBySourceId);
    }

    [...AlgoliaIndex, ...CloudAnalyticsIndex].forEach((index) => {
      if (filters[index]) {
        activeShapes.push(sourcesBySourceId[index]);
      }
    });

    Object.entries(sourcesBySourceId).forEach(([index, source]) => {
      if (!filters[index]) {
        inactiveShapes.push(source);
      }
    });
    return [...activeShapes, ...inactiveShapes];
  }, []);

  const plugins = useMemo(
    () =>
      [
        staticLinksPlugin,
        recentSearchPlugin,
        customersPlugin,
        usersPlugin,
        entitiesPlugin,
        invoicesPlugin,
        assetsPlugin,
        cloudAnalyticsPlugin,
      ].filter((p) => p) as Array<AutocompletePlugin<any, any>>,
    [
      staticLinksPlugin,
      recentSearchPlugin,
      customersPlugin,
      usersPlugin,
      entitiesPlugin,
      invoicesPlugin,
      assetsPlugin,
      cloudAnalyticsPlugin,
    ]
  );

  const autocomplete = useMemo(
    () =>
      createAutocomplete<AlgoliaItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>({
        onStateChange({ prevState, state }) {
          if (shouldUpdateState(state, prevState)) {
            setAutocompleteState(state);
          }
        },
        navigator: {
          navigate({ itemUrl, state }) {
            if (isDoitEmployee && state.query.startsWith("@") && !customerFromQuery) {
              return;
            }
            redirectTo(itemUrl);
          },
        },
        insights: true,
        plugins,
        defaultActiveItemId: 0,
        placeholder: "Search",
        openOnFocus: true,
        initialState: {
          context: {
            searchClient,
            restrictedIndices,
            inCustomerContext: Boolean(customer || customerFromQuery),
            isDarkMode: darkMode,
            onSelectRow,
            customerName: customer?.name,
            customerFromQuery,
            isDoitEmployee,
          },
        },
        reshape,
      }),
    [
      plugins,
      searchClient,
      restrictedIndices,
      customer,
      customerFromQuery,
      darkMode,
      onSelectRow,
      reshape,
      isDoitEmployee,
      redirectTo,
    ]
  );

  useEffect(() => {
    const recentSearched = recentSearchPlugin?.data?.getAll() as Highlighted<RecentSearchesItem>[];
    if (
      autocompleteState.query === "" &&
      recentSearched?.length >= 0 &&
      !autocompleteState.collections.find((c) => c.source.sourceId === "recentSearches")
    ) {
      autocomplete.refresh();
    }
  }, [autocomplete, autocompleteState.collections, autocompleteState.query, recentSearchPlugin?.data]);

  return [autocomplete, autocompleteState];
};

export const useBindAlgoliaEventListeners = ({
  inputRef,
  formRef,
  panelRef,
  autocomplete,
  autocompleteState,
}: {
  inputRef: RefObject<HTMLInputElement>;
  formRef: RefObject<HTMLFormElement>;
  panelRef: RefObject<HTMLDivElement>;
  autocomplete: AutocompleteApi<AlgoliaItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>;
  autocompleteState: AutocompleteState<AlgoliaItem>;
}) => {
  useEffect(() => {
    if (!formRef.current || !panelRef.current || !inputRef.current) {
      return;
    }
    const { getEnvironmentProps } = autocomplete;

    const { onTouchStart, onTouchMove, onMouseDown } = getEnvironmentProps({
      formElement: formRef.current,
      inputElement: inputRef.current,
      panelElement: panelRef.current,
    });
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("touchstart", onTouchStart);
    window.addEventListener("touchmove", onTouchMove);

    return () => {
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("touchstart", onTouchStart);
      window.removeEventListener("touchmove", onTouchMove);
    };
  }, [formRef, panelRef, inputRef, autocompleteState.isOpen, autocomplete]);
};
