import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { type GSuiteAssetModel } from "@doitintl/cmp-models";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { stepperText } from "../../../assets/texts";
import { type LegacyDataFormat } from "../../../Components/Catalog/Catalog.context";
import LoadingButton from "../../../Components/LoadingButton";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Asset, type Contract } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { formatNumber, roundWithCommas } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { licensePurchaseCap, sPluralise } from "../assetUtils";
import AddOrRemoveLicenses from "./AddOrRemoveLicenses";
import LicenseChangeConfirmSummary, { main } from "./LicenseChangeConfirmSummary";
import LicenseQuantity from "./LicenseQuantity";

export const cyIds = {
  dialog: "dialog",
  sections: {
    title: "dialogTitle",
    content: "dialogContent",
    actions: "dialogActions",
  },
  buttons: {
    back: "backButton",
    cancel: "cancelButton",
    next: "nextButton",
    confirm: "confirmButton",
  },
  content: {
    main: "mainContent",
    steps: ["firstStep", "secondStep", "thirdStep"],
    stepper: "stepper",
    step0: {
      adding: "addLicensesButton",
      removing: "removeLicensesButton",
    },
    step1: {
      input: "licensesInput",
    },
    step2: {
      summary: main,
      alertBody: "alertBody",
    },
    textMaxSeats: "maxSeats",
    textUsedSeats: "usedSeats",
    textUsageSummary: "usageSummary",
  },
};

export type LicenseChangeProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (currentMaxSeats: number, newMaxSeats: number) => void;
  asset: Asset<GSuiteAssetModel>;
  seats: { max: number; used: number };
  loading: boolean;
  contract?: Contract;
  catalogItem?: LegacyDataFormat;
};

const LicenseChangeDialog = ({
  open,
  onClose,
  onConfirm,
  asset,
  contract,
  catalogItem,
  seats,
  loading,
}: LicenseChangeProps) => {
  const maxNegativeDelta = seats.max - seats.used;
  const { isMobile } = useFullScreen();
  const { isDoitEmployee } = useAuthContext();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [adding, setAdding] = useState<boolean>(true);
  const [delta, setDelta] = useState<number>(0);
  const [error, setError] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const confirmButtonText = useMemo<string>(() => (adding ? "Confirm purchase" : "Update licenses"), [adding]);
  const lastStep = useMemo<boolean>(() => activeStep === 2, [activeStep]);
  const newMaxSeats = useMemo<number>(
    () => (adding ? seats.max + delta : seats.max - delta),
    [adding, delta, seats.max]
  );
  const removeDisabled = useMemo<boolean>(
    () => asset.data.properties.subscription.plan.isCommitmentPlan || maxNegativeDelta === 0,
    [asset.data.properties.subscription.plan.isCommitmentPlan, maxNegativeDelta]
  );

  const handleForward = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);
  const handleBack = useCallback(() => {
    if (error) {
      setDelta(0);
    }
    setActiveStep(activeStep - 1);
  }, [activeStep, error]);

  const onLicenseChange = useCallback(() => {
    onConfirm(seats.max, newMaxSeats);
  }, [newMaxSeats, onConfirm, seats.max]);

  useEffect(() => {
    if (delta < 0) {
      setError(true);
      setErrorMessage(`You cannot ${adding ? "add" : "remove"} a negative number of seats`);
      return;
    }

    if (adding && delta > licensePurchaseCap) {
      setError(true);
      setErrorMessage(
        `Cannot add ${formatNumber(delta, 0)} licenses as this exceeds the limit of ${roundWithCommas(
          licensePurchaseCap
        )} for license addition`
      );
      return;
    }

    if (!adding && delta > maxNegativeDelta) {
      setError(true);
      setErrorMessage(
        `You can only remove seats that are not in use, which is a maximum of ${maxNegativeDelta} in this instance`
      );
      return;
    }

    setError(false);
    setErrorMessage(undefined);
  }, [adding, delta, maxNegativeDelta]);

  const maxSeatsText = (
    <Typography variant="body1" fontWeight="bold" component="strong" data-cy={cyIds.content.textMaxSeats}>
      {`${seats.max} ${sPluralise("license", seats.max)}`}
    </Typography>
  );
  const usedSeatsText = (
    <Typography variant="body1" fontWeight="bold" component="strong" data-cy={cyIds.content.textUsedSeats}>
      {`${seats.used} ${sPluralise("seat", seats.used)}`}
    </Typography>
  );
  const usageSummary = (
    <Typography variant="body1" component="span" data-cy={cyIds.content.textUsageSummary}>
      You have {maxSeatsText} and {usedSeatsText} are being used.
    </Typography>
  );

  const steps = ["Add or remove", "Amend license quantity", "License summary"];
  const getStepContent = (step: number): JSX.Element => {
    switch (step) {
      case 0:
        return (
          <AddOrRemoveLicenses
            adding={adding}
            setAdding={setAdding}
            maxNegativeDelta={maxNegativeDelta}
            usageSummary={usageSummary}
            setDelta={setDelta}
            delta={delta}
            setError={setError}
            removeDisabled={removeDisabled}
            commitment={asset.data.properties.subscription.plan.isCommitmentPlan}
          />
        );
      case 1:
        return (
          <LicenseQuantity
            adding={adding}
            delta={delta}
            setDelta={setDelta}
            error={error}
            errorMessage={errorMessage}
            maxNegativeDelta={maxNegativeDelta}
            usageSummary={usageSummary}
          />
        );
      case 2: {
        const licensesQty = (
          <Typography variant="body2" fontWeight="bold" component="strong">
            {delta} {sPluralise("license", delta)}
          </Typography>
        );
        const highQuantityAlert = (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <AlertTitle>High quantity</AlertTitle>
            <Typography variant="body2" component="span" data-cy={cyIds.content.step2.alertBody}>
              This purchase is for {licensesQty} Are you sure you want to proceed?
            </Typography>
          </Alert>
        );
        return (
          <Stack gap={2} data-cy={cyIds.content.steps[2]}>
            {adding && delta >= 50 && highQuantityAlert}
            <LicenseChangeConfirmSummary
              asset={asset}
              maxSeats={newMaxSeats}
              currentMaxSeats={seats.max}
              contract={contract}
              catalogItem={catalogItem}
            />
          </Stack>
        );
      }
      default: {
        consoleErrorWithSentry(
          new Error(`Attempted to render step outside of stepper bounds. The step number passed was ${step}.`)
        );
        // This should never be reachable but typescript required its presence.
        // A message useful for finding the problem has been placed here FOR DOERS instead of a blank step.
        return isDoitEmployee ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>Attempted to step out of Stepper bounds. The active attempted step is {step}.
          </Alert>
        ) : (
          <Box height="75px" />
        );
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile} data-cy={cyIds.dialog}>
      <DialogTitle data-cy={cyIds.sections.title}>Edit Licenses</DialogTitle>
      <DialogContent
        data-cy={cyIds.sections.content}
        sx={{ minWidth: isMobile ? "100%" : 600, minHeight: 325, maxHeight: 464 }}
      >
        <Stepper alternativeLabel activeStep={activeStep} data-cy={cyIds.content.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box data-cy={cyIds.content.main} sx={{ mt: 2 }}>
          {getStepContent(activeStep)}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions data-cy={cyIds.sections.actions}>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ width: "100%" }}>
          {activeStep !== 0 && (
            <Button variant="text" onClick={handleBack} sx={{ mr: "auto !important" }} data-cy={cyIds.buttons.back}>
              {stepperText.BACK}
            </Button>
          )}
          <Button variant="text" onClick={onClose} data-cy={cyIds.buttons.cancel}>
            {stepperText.CANCEL}
          </Button>
          {lastStep ? (
            <LoadingButton
              variant="contained"
              onClick={onLicenseChange}
              loading={loading}
              data-cy={cyIds.buttons.confirm}
              disabled={loading}
              mixpanelEventId={`assets.license-change.${confirmButtonText}`}
            >
              {confirmButtonText}
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              onClick={handleForward}
              disabled={error || (activeStep === 1 && delta === 0)}
              data-cy={cyIds.buttons.next}
            >
              {stepperText.NEXT}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LicenseChangeDialog;
