import { useCallback, useMemo } from "react";

import { AnalyticsDataSource, type AttributionFilter, Metadata } from "@doitintl/cmp-models";
import { Box, Grid, Link, Stack, TextField, Typography } from "@mui/material";
import assign from "lodash/assign";

import { globalText } from "../../../../assets/texts";
import { AlertsTxt } from "../../../../assets/texts/CloudAnalytics";
import DataHubCheckbox from "../../../../Components/DataHubCheckbox/DataHubCheckbox";
import FilterDialog from "../../../../Components/Dialogs/CloudAnalytics/FilterDialog";
import { DimensionSelector } from "../../../../Components/Selects/CloudAnalytics/DimensionSelector";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type MetadataOption } from "../../../../types";
import { type KeyTypeValues } from "../../api";
import AttributionBuilderChips from "../../attributions/attributionBuilder/AttributionBuilderChips";
import { useCreateAttributionHandler } from "../../attributions/hooks";
import { FixedFilters } from "../../utilities";
import { getFilteredDimension } from "../shared";

type Props = {
  alertName: string;
  dataSource: AnalyticsDataSource;
  dimension: MetadataOption | null;
  dimensions: MetadataOption[];
  filterDialogOpen: boolean;
  filters: AttributionFilter[];
  hasDataHub: boolean;
  isCurrentUserEditor: boolean;
  setAlertEdited: (alertEdited: boolean) => void;
  setAlertName: (alertName: string) => void;
  setDataSource: (dataSource: AnalyticsDataSource) => void;
  setDimension: (dimension: MetadataOption | null) => void;
  setFilterDialogOpen: (filterDialogOpen: boolean) => void;
  setFilters: (filters: AttributionFilter[]) => void;
  getLabelsDimensions: (v: KeyTypeValues[]) => Promise<MetadataOption[]>;
};

export const DimensionStep = ({
  alertName,
  dataSource,
  dimension,
  dimensions,
  filterDialogOpen,
  filters,
  hasDataHub,
  isCurrentUserEditor,
  setAlertEdited,
  setAlertName,
  setDataSource,
  setDimension,
  setFilterDialogOpen,
  setFilters,
  getLabelsDimensions,
}: Props) => {
  const { customer } = useCustomerContext();

  const cloudProviders = useMemo(() => {
    const clouds = dimensions?.find((d) => d.id === `${Metadata.FIXED}:${FixedFilters.CLOUD}`);
    return clouds?._values.datahub ?? [];
  }, [dimensions]);

  const handleNewAttribution = useCreateAttributionHandler({
    baseUrl: `/customers/${customer.id}/analytics/attributions`,
    mixpanelEventName: "analytics.attributions.new",
    newTab: true,
  });

  const onSave = useCallback(
    (valuesOrRegexp: "values" | "regexp", filtersOrRegex: string[] | string, inverse: boolean) => {
      if (!dimension?.id) {
        return;
      }

      let regexp: string | null = null;
      let values: string[] | null = null;
      if (typeof filtersOrRegex === "string" && valuesOrRegexp === "regexp") {
        regexp = filtersOrRegex;
      } else if (Array.isArray(filtersOrRegex) && valuesOrRegexp === "values") {
        values = [...filtersOrRegex];
      }

      const filter: AttributionFilter = {
        id: dimension.id,
        values,
        regexp,
        inverse,
        allowNull: false,
        field: dimension.data.field,
        key: dimension.data.key,
        type: dimension.data.type,
      };

      if (dimension.data.nullFallback) {
        const nullIndex = filter.values?.findIndex((v) => v === dimension.data.nullFallback);
        if (nullIndex !== undefined && nullIndex !== -1) {
          filter.allowNull = true;
          filter.values?.splice(nullIndex, 1);
        }
      }
      setDimension(getFilteredDimension(dimension, filter));
      setFilters([filter]);
      setFilterDialogOpen(false);
    },
    [dimension, setDimension, setFilterDialogOpen, setFilters]
  );

  const handleCancelFilter = useCallback(
    (_: MetadataOption[]) => {
      setFilterDialogOpen(false);
      if (!filters.length) {
        setDimension(null);
      }
    },
    [filters.length, setDimension, setFilterDialogOpen]
  );

  const handleSelectOption = useCallback(
    async (option: MetadataOption | null, filter?: AttributionFilter) => {
      setAlertEdited(true);
      if (!filter) {
        // when the user selects a new dimension, we reset the filters
        setDimension(option);
        setFilters([]);
      }
      if (!option) {
        return;
      }

      const resultOption = await getLabelsDimensions([{ key: option.data.key, type: option.data.type }]);
      const updatedOption = assign(option, resultOption[0]);

      setDimension(updatedOption);
      setFilterDialogOpen(true);
    },
    [getLabelsDimensions, setAlertEdited, setDimension, setFilterDialogOpen, setFilters]
  );

  const onEditDimension = useCallback(() => {
    if (filters.length === 0) {
      return;
    }
    setFilterDialogOpen(true);
  }, [filters.length, setFilterDialogOpen]);

  return (
    <Box key="stepScope">
      <Grid
        container
        key="grid-key"
        sx={{
          mt: "2.5rem",
        }}
      >
        <Grid item xs={12} sx={{ mb: "2.5rem" }}>
          <TextField
            required
            fullWidth
            name="name"
            label={globalText.NAME}
            value={alertName}
            onChange={(event) => {
              setAlertEdited(true);
              setAlertName(event.target.value);
            }}
            variant="outlined"
            margin="dense"
            type="text"
            disabled={!isCurrentUserEditor}
            size="medium"
          />
        </Grid>
        <Grid item xs={12}>
          <DimensionSelector
            handleSelectOption={handleSelectOption}
            selectedDimension={dimension}
            dimensions={dimensions}
            disabled={!isCurrentUserEditor}
            textFieldProps={{
              label: AlertsTxt.DIMENSION,
            }}
            onEdit={onEditDimension}
          />
        </Grid>
        <Grid item xs={12} mt={1}>
          <DataHubCheckbox
            checked={dataSource === AnalyticsDataSource.BILLING_DATAHUB}
            cloudProviders={cloudProviders}
            customerId={customer.id}
            dataSource={dataSource}
            disabled={!isCurrentUserEditor || !hasDataHub}
            onChange={(_, checked) => {
              setDataSource(checked ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING);
            }}
          />
        </Grid>
        {filters.length > 0 && (
          <Box mt={3} width="100%">
            {filters.map((f) => (
              <AttributionBuilderChips
                key={f.id}
                filter={f}
                nullFallback={dimension?.data.nullFallback ?? null}
                lightStyle={true}
              />
            ))}
          </Box>
        )}
        <Stack color="text.secondary" direction="row" spacing={0.5} mt={3}>
          <Typography variant="body1" color="inherit">
            {AlertsTxt.NEED_ADVANCED_LOGIC}
          </Typography>
          <Link color="inherit" variant="body1" underline="always" href="#" onClick={handleNewAttribution}>
            {AlertsTxt.CREATE_NEW_ATTRIBUTION}
          </Link>
        </Stack>
      </Grid>
      {dimension && filterDialogOpen && (
        <FilterDialog
          disableEscapeKeyDown={false}
          handleCancelFilter={handleCancelFilter}
          isOptionDisabled={() => false}
          onClose={() => {
            setFilterDialogOpen(false);
          }}
          onSave={onSave}
          open={filterDialogOpen}
          selected={dimension}
        />
      )}
    </Box>
  );
};
