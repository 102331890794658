import { useCallback, useState } from "react";

import { useApiContext } from "../../../../api/context";
import { marketplaceText } from "../../../../assets/texts";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import ApproveOrRejectDialog, { type DialogMode } from "../components/ApproveOrRejectDialog";
import { type AccountConfig } from "../hooks/useMarketplaceAccounts";
import { useMarketplaceContext } from "../MarketplaceContext";
import { AccountRow } from "./AccountRow";
import { filterColumns, headerColumns } from "./columns";

const accountsUrl = "/v1/marketplace/gcp/accounts";

const MarketplaceGCPAccounts = () => {
  const { accounts } = useMarketplaceContext();
  const [clickedRow, setClickedRow] = useState<AccountConfig | null>(null);
  const [dialogMode, setDialogMode] = useState<DialogMode>(null);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const api = useApiContext();

  const handleApproveAccount = useCallback(
    (row: AccountConfig | null) => async () => {
      if (row === null) {
        return;
      }

      try {
        await api.request({
          method: "POST",
          url: `${accountsUrl}/${row.ref.id}/approve`,
        });
        successSnackbar(marketplaceText.ACCOUNT_APPROVED_SUCCESSFULLY);
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(marketplaceText.ACCOUNT_APPROVE_ERROR);
      }
    },
    [api, errorSnackbar, successSnackbar]
  );

  const handleRejectAccount = useCallback(
    (row: AccountConfig | null) => async () => {
      if (row === null) {
        return;
      }

      try {
        await api.request({
          method: "POST",
          url: `${accountsUrl}/${row.ref.id}/reject`,
        });
        successSnackbar(marketplaceText.ACCOUNT_REJECTED_SUCCESSFULLY);
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(marketplaceText.ACCOUNT_REJECT_ERROR);
      }
    },
    [api, errorSnackbar, successSnackbar]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogMode(null);
    setClickedRow(null);
  }, []);

  const accountRowWrapper = useCallback(
    ({ row }: { row: AccountConfig }) => (
      <AccountRow row={row} setDialogMode={setDialogMode} setClickedRow={setClickedRow} />
    ),
    []
  );

  return (
    <>
      <FilterTable<AccountConfig>
        tableItems={accounts}
        rowComponent={accountRowWrapper}
        headerColumns={headerColumns}
        filterBarPlaceholder={marketplaceText.FILTER_ACCOUNTS}
        filterColumns={filterColumns}
        itemUniqIdentifierField="ref.id"
        persistenceKey="account-gcp-marketplace"
        defaultSortingColumnValue="transform.updateTime"
        toolbarProps={{ allowToEditColumns: true }}
      />
      <ApproveOrRejectDialog
        resourceType="account"
        dialogMode={dialogMode}
        handleApprove={handleApproveAccount(clickedRow)}
        handleReject={handleRejectAccount(clickedRow)}
        handleClose={handleCloseDialog}
      />
    </>
  );
};

export default MarketplaceGCPAccounts;
