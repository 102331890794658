import { useCallback, useMemo } from "react";

import { Box } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { Loader } from "../../../../Components/Loader";
import { filters, headerColumns } from "./columns";
import InvoiceRow from "./InvoiceListRow";
import { type Invoice, type InvoiceTableRow } from "./types";
import { formatInvoice } from "./utils";

const InvoiceList = ({
  loading,
  invoices,
  billingMonth,
}: {
  loading: boolean;
  invoices: Invoice[];
  billingMonth: string;
}) => {
  const rows = useMemo(() => invoices.map(formatInvoice), [invoices]);

  const navigateToView = useCallback(
    (viewInvoice) => {
      window.open(`/billing/draft-invoices/${viewInvoice.entity.id}/${billingMonth}/${viewInvoice.id}/view`, "_blank");
    },
    [billingMonth]
  );

  const rowWrapper = ({ row }) => (
    <InvoiceRow
      row={row}
      navigateToView={() => {
        navigateToView(row);
      }}
    />
  );

  return (
    <Box>
      <Loader loading={loading}>
        <FilterTable<InvoiceTableRow>
          showRowsSelection={false}
          tableItems={rows}
          headerColumns={headerColumns}
          rowComponent={rowWrapper}
          filterColumns={filters}
          showFilterBar
        />
      </Loader>
    </Box>
  );
};

export default InvoiceList;
