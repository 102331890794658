import axiosBetterStacktrace from "axios-better-stacktrace";
import { type IdTokenResult } from "firebase/auth";
import isEqual from "lodash/isEqual";
import type * as axios from "axios";

import { type ErrorCode } from "../Pages/CloudAnalytics/utilities";
import { consoleErrorWithSentry } from "../utils";

type OnTokenExpired = () => void;

type GetToken = (forceRefresh?: boolean) => Promise<IdTokenResult | null | undefined>;

type Options = {
  getToken: GetToken;
  onTokenExpired: OnTokenExpired;
  disableBetterStacktrace?: boolean;
};

export class ResponseErrorCode {
  static UNKNOWN = "unknown_error";

  static NETWORK_ERROR = "network_error";

  static QUERY_TIMEOUT_524 = "query_timeout_524";
}

type ResponseErrorCallback = (err: ErrorCode, details: any) => void;

export function handleResponseError(
  error: axios.AxiosError<any>,
  handleError?: ResponseErrorCallback,
  expectedErrorCode?: number[]
) {
  let err: string;
  let details: any;

  if (error.response) {
    // we don't use sentry under cypress
    if (!expectedErrorCode?.includes(error.response.status) && process.env.CYPRESS !== "true") {
      consoleErrorWithSentry(error);
    }

    const responseError = error.response.data?.error;
    if (!responseError) {
      err = ResponseErrorCode.UNKNOWN;
    } else if (responseError.code !== undefined) {
      err = responseError.code;
    } else {
      err = responseError;
    }
    if (error.response.status === 524) {
      err = ResponseErrorCode.QUERY_TIMEOUT_524;
    }

    details = error.response.data?.details;
  } else {
    err = ResponseErrorCode.NETWORK_ERROR;
  }

  if (handleError) {
    handleError(err as ErrorCode, details || {});
  }
}

const interceptRequest = (instance: axios.AxiosInstance, options: Options) =>
  instance.interceptors.request.use(async (config) => {
    const setBearer = (newToken: IdTokenResult) => {
      config.headers.Authorization = `Bearer ${newToken.token}`;
    };

    try {
      const currentToken = await options.getToken();
      if (currentToken) {
        setBearer(currentToken);
      }
      (config as any).__currentToken = currentToken;
    } catch (error: any) {
      if (error?.code === "auth/user-token-expired") {
        options.onTokenExpired();
      } else {
        consoleErrorWithSentry(error);
      }

      return Promise.reject(error);
    }
    return config;
  });

const handle401 = async (error: any, options: Options) => {
  try {
    // detect 401s due to revoked tokens
    await options.getToken(true);

    // this 401 is not a revoked token error
    // this should not happen, but it depends on response codes we return from the server.
    consoleErrorWithSentry(error);
  } catch (e: any) {
    if (e?.code === "auth/user-token-expired") {
      options.onTokenExpired();
    } else {
      consoleErrorWithSentry(e);
    }
  }
};

const handle403 = async (error: any, instance: axios.AxiosInstance, options: Options) => {
  const requestToken = error.config.__currentToken;
  const newToken = await options.getToken(true);

  if (!error.config.__retryWithNewToken && newToken?.claims && !isEqual(newToken?.claims, requestToken?.claims)) {
    error.config.__retryWithNewToken = true;
    return instance.request(error.config);
  }
};

const interceptResponse = (instance: axios.AxiosInstance, options: Options) =>
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response) {
        const { status } = error.response;

        if (status === 401) {
          await handle401(error, options);
        } else if (status === 403) {
          await handle403(error, instance, options);
        } else if (status === 400 || status >= 500) {
          consoleErrorWithSentry(error);
        }
      }
      return Promise.reject(error);
    }
  );

export function initAxiosClient(instance: axios.AxiosInstance, options: Options) {
  const unmount = !options.disableBetterStacktrace && axiosBetterStacktrace(instance);

  const requestId = interceptRequest(instance, options);
  const responseId = interceptResponse(instance, options);

  return () => {
    instance.interceptors.request.eject(requestId);
    instance.interceptors.response.eject(responseId);
    if (unmount) {
      unmount();
    }
  };
}
