import { useCallback } from "react";

import { useHistory, useLocation } from "react-router-dom";

export const useGotoParentUrl = (backUrl: string) => {
  const location = useLocation<{ prevPage?: string }>();
  const history = useHistory();

  return useCallback(() => {
    if (location.state?.prevPage === backUrl) {
      // If the previous page was the reports page, use the browser back functionality
      history.goBack();
    } else {
      // Otherwise, navigate to the reports page directly
      history.push(backUrl);
    }
  }, [location.state?.prevPage, backUrl, history]);
};
