import { type CloudflowTemplate } from "../../types";

export const createTemplateFilters = (templates: CloudflowTemplate[] | undefined) => {
  const groupedFilter: Record<string, Record<string, number>> = {};

  templates?.forEach((template) => {
    if (!template.tags) {
      return;
    }

    // For each tag group, count the occurrences of each tag
    Object.entries(template.tags).forEach(([tagGroup, tagValues]) => {
      tagGroup = tagGroup.toLowerCase();
      tagValues.forEach((tag) => {
        groupedFilter[tagGroup] = groupedFilter[tagGroup] || {};
        groupedFilter[tagGroup][tag] = (groupedFilter[tagGroup][tag] || 0) + 1;
      });
    });
  });

  const sortedGroupedFilter: Record<string, { tagValue: string; count: number }[]> = {};

  Object.keys(groupedFilter)
    .sort()
    .forEach((tagGroup) => {
      sortedGroupedFilter[tagGroup] = Object.keys(groupedFilter[tagGroup])
        .sort()
        .map((tag) => ({
          tagValue: tag,
          count: groupedFilter[tagGroup][tag],
        }));
    });

  return sortedGroupedFilter;
};
