import React from "react";

import StopIcon from "@mui/icons-material/Stop";
import { IconButton } from "@mui/material";

type Props = {
  handleStopGeneration: () => void;
};
export const StopGenerationButton = ({ handleStopGeneration }: Props) => (
  <IconButton
    onClick={handleStopGeneration}
    sx={{
      justifyContent: "center",
    }}
    size="medium"
    color="primary"
  >
    <StopIcon fontSize="small" />
  </IconButton>
);
