import { useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { type ContractModel } from "@doitintl/cmp-models";

import RampProgressBar from "../RampPlans/RampList/RampProgressBar";
import { type RampPlanAttainment, Status } from "./types";

export default function RampPlanBar({
  customerId,
  rampPlanAttainment,
  contracts,
}: {
  customerId: string;
  rampPlanAttainment: RampPlanAttainment | null;
  contracts: ContractModel | null;
}) {
  const history = useHistory();

  const handleNavigate = useCallback(
    (customerId: string | undefined) => {
      if (customerId && rampPlanAttainment?.planId) {
        history.push(`/customers/${customerId}/contracts/ramps/${rampPlanAttainment?.planId}`);
      }
    },
    [history, rampPlanAttainment?.planId]
  );

  return useMemo(() => {
    if (!contracts) {
      return <span>{Status.Na}</span>;
    }
    return rampPlanAttainment?.status && typeof rampPlanAttainment?.onTrack !== "undefined" ? (
      <RampProgressBar
        onClick={() => {
          handleNavigate(customerId);
        }}
        progressVal={rampPlanAttainment.total ?? 0}
        onTrack={rampPlanAttainment?.onTrack}
      />
    ) : (
      <span>{Status.InActive}</span>
    );
  }, [
    contracts,
    customerId,
    handleNavigate,
    rampPlanAttainment?.onTrack,
    rampPlanAttainment?.status,
    rampPlanAttainment?.total,
  ]);
}
