import { useCallback, useEffect, useState } from "react";

import axios, { type AxiosError, type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { consoleErrorWithSentry } from "../../../utils";
import { type MasterPayerAccountActivationModel } from "./types";

const validationErrors = {
  ["no valid CUR was found"]: "Cost & Usage report not found.",
  ["no valid S3 Bucket was found"]: "CUR S3 bucket access denied.",
  ["no valid Role was found"]: "AWS role not found.",
  ["no valid Policy was found"]: "AWS policy doesn't match the blueprint.",
};

type ValidateRequestPayload = {
  roleArn: string;
  accountId: string;
  curBucket: string;
  curPath: string;
};

type MPAValidationErrorResponse = {
  error: string;
};

const FAILED_VALIDATION_RESPONSE_STATUS = 500;
const DEFAULT_ERROR_MESSAGE = "Error in accessing the CUR and required permissions to activate this MPA.";

function isMPAValidationError(error: unknown): error is AxiosError<MPAValidationErrorResponse> {
  return axios.isAxiosError(error) && error.response?.status === FAILED_VALIDATION_RESPONSE_STATUS;
}

function mapErrorCodeToMessage(error: AxiosError<MPAValidationErrorResponse>): { message: string; details?: string } {
  const errorCode = error.response?.data.error;
  if (errorCode === undefined || !(errorCode in validationErrors)) {
    return {
      message: DEFAULT_ERROR_MESSAGE,
      details: errorCode,
    };
  }
  return {
    message: validationErrors[errorCode],
  };
}

export type MPAValidationResult =
  | {
      valid: true;
    }
  | { valid: false; message: string; details?: string };

export type RequestMPAValidation = (
  customerId: string,
  mpaActivationData: MasterPayerAccountActivationModel
) => Promise<MPAValidationResult>;

export function useMPAValidationRequest() {
  const api = useApiContext();
  const [abortController, setAbortController] = useState<AbortController>();

  useEffect(() => () => abortController?.abort(), [abortController]);

  return useCallback<RequestMPAValidation>(
    async (customerId: string, mpaActivationData: MasterPayerAccountActivationModel) => {
      const abortController = new AbortController();
      setAbortController(abortController);

      try {
        await api.post<void, AxiosResponse, ValidateRequestPayload>(
          `v1/amazon-web-services/master-payer-accounts/validate`,
          {
            accountId: mpaActivationData.accountNumber,
            roleArn: mpaActivationData.roleARN,
            curBucket: mpaActivationData.cur_bucket,
            curPath: mpaActivationData.cur_path,
          },
          {
            signal: abortController.signal,
          }
        );
        return { valid: true };
      } catch (error) {
        if (isMPAValidationError(error)) {
          return { valid: false, ...mapErrorCodeToMessage(error) };
        }
        if (!axios.isCancel(error)) {
          consoleErrorWithSentry(error);
        }
        return { valid: false, message: DEFAULT_ERROR_MESSAGE };
      }
    },
    [api]
  );
}
