import { useCallback } from "react";

import { DialogContent } from "@mui/material";

import { globalText, marketplaceText } from "../../../../assets/texts";
import SimpleDialog from "../../../../Components/SimpleDialog";

export type ResourceType = "entitlement" | "account";
export type DialogMode = null | "approve" | "reject";

export type ApproveOrRejectDialogProps = {
  resourceType: ResourceType;
  dialogMode: DialogMode;
  handleApprove: () => Promise<void>;
  handleReject: () => Promise<void>;
  handleClose: () => void;
};

const ApproveOrRejectDialog = ({
  dialogMode,
  resourceType,
  handleApprove,
  handleReject,
  handleClose,
}: ApproveOrRejectDialogProps) => {
  let approveContent;
  let rejectContent;

  if (resourceType === "entitlement") {
    approveContent = marketplaceText.APPROVE_ENTITLEMENT;
    rejectContent = marketplaceText.REJECT_ENTITLEMENT;
  } else {
    approveContent = marketplaceText.APPROVE_ACCOUNT;
    rejectContent = marketplaceText.REJECT_ACCOUNT;
  }

  const handleConfirmApprove = useCallback(async () => {
    await handleApprove();
    handleClose();
  }, [handleApprove, handleClose]);

  const handleConfirmReject = useCallback(async () => {
    await handleReject();
    handleClose();
  }, [handleReject, handleClose]);

  return (
    <>
      <SimpleDialog
        open={dialogMode === "approve"}
        title={globalText.APPROVE}
        onConfirm={handleConfirmApprove}
        onCancel={handleClose}
        confirmButtonText={globalText.APPROVE}
        titleCy="dialog-title"
        confirmButtonCy="dialog-confirm-button"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent sx={{ mt: 2 }} data-cy="dialog-content">
          {approveContent}
        </DialogContent>
      </SimpleDialog>
      <SimpleDialog
        open={dialogMode === "reject"}
        title={globalText.REJECT}
        onConfirm={handleConfirmReject}
        onCancel={handleClose}
        confirmButtonText={globalText.REJECT}
        titleCy="dialog-title"
        confirmButtonCy="dialog-confirm-button"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogContent sx={{ mt: 2 }} data-cy="dialog-content">
          {rejectContent}
        </DialogContent>
      </SimpleDialog>
    </>
  );
};

export default ApproveOrRejectDialog;
