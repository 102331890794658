import { type RefHook, useComparatorRef } from "../util";
import { isDocRefOrQueryEqual } from "./helpers";
import { type TypeOrUndefined } from "./types";
import type { FirebaseModelReference, FirebaseQueryModel } from "../../core";

type QueryWithKeys = {
  query: FirebaseQueryModel<any>;
  cachingKeys: string[] | undefined;
};

export type DocRefWithKeys = {
  docRef: FirebaseModelReference<any>;
  cachingKeys: string[] | undefined;
};

type HasQueryOrDocRef = Partial<QueryWithKeys & DocRefWithKeys>;

const isCachingKeysEqual = (keys1: string[] | undefined, keys2: string[] | undefined): boolean => {
  const bothNull = !keys1 && !keys2;
  if (bothNull) {
    return true;
  }
  return !!keys1 && !!keys2 && keys1.length === keys2.length && keys1.every((key, index) => key === keys2[index]);
};

const isWithKeysEqual = <T extends HasQueryOrDocRef>(
  v1: TypeOrUndefined<T>,
  v2: TypeOrUndefined<T>,
  getRef: (item: T) => any
): boolean => {
  const bothNull = !v1 && !v2;
  if (bothNull) {
    return true;
  }

  if (!!v1 && !!v2) {
    if (!isDocRefOrQueryEqual(getRef(v1), getRef(v2))) {
      return false;
    }

    return isCachingKeysEqual(v1.cachingKeys, v2.cachingKeys);
  }

  return false;
};

export const isQueryWithKeysEqual = (v1: TypeOrUndefined<QueryWithKeys>, v2: TypeOrUndefined<QueryWithKeys>): boolean =>
  isWithKeysEqual(v1, v2, (item) => item.query);

export const isDocRefWithKeysEqual = (
  v1: TypeOrUndefined<DocRefWithKeys>,
  v2: TypeOrUndefined<DocRefWithKeys>
): boolean => isWithKeysEqual(v1, v2, (item) => item.docRef);

export const useIsFirestoreDocRefWithKeysEqual = (
  value: TypeOrUndefined<DocRefWithKeys>,
  onChange?: (value: TypeOrUndefined<DocRefWithKeys>) => void
): RefHook<TypeOrUndefined<DocRefWithKeys>> => useComparatorRef(value, isDocRefWithKeysEqual, onChange);

export const useIsFirestoreQueryWithKeysEqual = (
  value: TypeOrUndefined<QueryWithKeys>,
  onChange?: (value: TypeOrUndefined<QueryWithKeys>) => void
): RefHook<TypeOrUndefined<QueryWithKeys>> => useComparatorRef(value, isQueryWithKeysEqual, onChange);
