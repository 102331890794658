import { type BudgetAlert, type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import { Card, darken, lighten } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { generateHighchartList3 } from "../../../../src/cmpBaseColors";
import { ThemeModes } from "../../../muiThemeTypes";
import { getCurrencySymbol } from "../../../utils/common";
import { formatGraphCurrencyValue } from "../../../utils/formatUSDollars";
import { nearestValue } from "./utils";

type ChartData = {
  x?: number;
  y?: number | null;
};

type Props = {
  actual: ChartData[];
  budget: ChartData[];
  forecast: ChartData[] | null;
  alerts: BudgetAlert[];
  amount: ChartData[] | null;
  currency?: CurrencyCode | null;
};

const PerformanceChart = ({ actual, budget, forecast, alerts, amount, currency }: Props) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === ThemeModes.LIGHT;

  const forecastColor = isLightMode
    ? lighten(theme.palette.text.disabled, 0.2)
    : darken(theme.palette.text.disabled, 0.4);

  const budgetAmountColor = theme.palette.success.main;
  const budgetAmountToDateColor = generateHighchartList3(isLightMode ? "light" : "dark")[3];
  const actualColor = generateHighchartList3(isLightMode ? "light" : "dark")[4];

  const variance = budget.map((budgetItem) => {
    const actualItem = actual.find((j) => j.x === budgetItem.x);
    return { y: budgetItem.y, x: (budgetItem.y || 0) - (actualItem?.y || 0) };
  });

  const alertTriggers = alerts
    .filter((i) => i.percentage !== 100)
    .map((alert) => {
      const budgetAmountForTrigger = nearestValue(
        budget.map((b) => b.y),
        alert.amount
      );
      const budgetDate = budget.find((b) => b.y === budgetAmountForTrigger)?.x;
      const budgetDateIndex = budget.findIndex((b) => b.y === budgetAmountForTrigger);
      if (budget.length - 1 === budgetDateIndex) {
        return;
      }

      return {
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: budgetDate,
              y: budgetAmountForTrigger,
            },
            y: -15,
            text: "Threshold trigger",
          },
        ],
      };
    })
    .filter((i) => i);

  return (
    <Card sx={{ pt: 6.6, pb: 2.8, px: 2 }}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate
        options={{
          chart: {
            height: 320,
            backgroundColor: theme?.palette?.background.paper,
            style: {
              fontFamily: "Roboto",
            },
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
            labels: {
              style: {
                fontFamily: "Roboto",
                fontWeight: 500,
                color: theme?.palette?.text?.primary,
              },
            },
            accessibility: {
              rangeDescription: "",
            },
            tickLength: 0,
            gridLineWidth: 0,
          },
          yAxis: {
            title: {
              text: "",
            },
            left: 58,
            labels: {
              x: -10,
              valuePrefix: getCurrencySymbol(currency || CurrencyCodes.USD),
              formatter() {
                return formatGraphCurrencyValue((this as any).value, currency || CurrencyCodes.USD);
              },
              style: {
                fontSize: 14,
                fontWeight: 500,
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                color: theme?.palette?.text?.primary,
              },
            },
          },
          tooltip: {
            shared: true,
            xDateFormat: "",
            useHTML: true,
            formatter() {
              const self = this as any;
              const presentPoints = self.points.map((i) => i.series.name);

              const actual = self.points.find((i) => i.series.name === "Actual")?.y;
              const forecast = self.points.find((i) => i.series.name === "Forecast")?.y;
              const budget = self.points.find((i) => i.series.name === "Budget amount to date")?.y;

              const showForecast = !presentPoints.includes("Actual");

              const currentVariance = showForecast
                ? self.points[0]?.y - self.points[1]?.y
                : variance.find((varianceItem) => varianceItem.y === self.points[2]?.y)?.x || 0;

              const firstLineColor = showForecast ? forecastColor : actualColor;

              const firstPointName = showForecast ? "Forecasted" : "Actuals";
              const firstPointValue = showForecast ? forecast : actual;

              return `
                <span style="color:${firstLineColor};">
                  <b> ${firstPointName}:</b>
                </span>
                <span style="color:rgba(0, 0, 0, 0.60)">${getCurrencySymbol(currency || CurrencyCodes.USD)}${Highcharts.numberFormat(firstPointValue, 0)}</span><br/>

                <span style="color:${budgetAmountToDateColor}">
                  <b> Budget amount to date:</b>
                </span>
                <span style="color:rgba(0, 0, 0, 0.60)">${getCurrencySymbol(currency || CurrencyCodes.USD)}${Highcharts.numberFormat(budget, 0)}</span><br/>

                <span><b> Variance:</b></span>
                <span style="color:rgba(0, 0, 0, 0.60)">${getCurrencySymbol(currency || CurrencyCodes.USD)}${Highcharts.numberFormat(currentVariance, 0)}</span>
              `;
            },
            valuePrefix: "$",
            valueDecimals: 0,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: actualColor,
            backgroundColor: "rgb(247, 247, 247)",
            shadow: {
              color: "rgba(0, 0, 0, 0.15)",
              offsetX: 1,
              offsetY: 1,
              width: 0,
            },
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            series: {
              animation: false,
            },
            area: {
              fillOpacity: 0.35,
            },
          },
          annotations: alertTriggers,
          series: [
            {
              name: "Actual",
              data: actual,
              color: actualColor,
              type: "area",
              fillOpacity: 0.1,
            },
            {
              name: "Budget amount",
              data: amount,
              type: "line",
              dashStyle: "Dash",
              color: budgetAmountColor,
              lineWidth: 1,
            },
            {
              name: "Budget amount to date",
              data: budget,
              type: "line",
              color: budgetAmountToDateColor,
              dashStyle: "ShortDash",
              lineWidth: 1,
              marker: {
                symbol: "circle",
              },
            },
            {
              name: "Forecast",
              data: forecast,
              type: "line",
              color: forecastColor,
              lineWidth: 1,
              dashStyle: "ShortDash",
              marker: {
                symbol: "circle",
              },
            },
          ],
          legend: {
            layout: "horizontal",
            enabled: true,
            itemStyle: {
              color: theme.palette.text.secondary,
              fontWeight: 400,
            },
          },
        }}
      />
    </Card>
  );
};

export default PerformanceChart;
