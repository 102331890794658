import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "@mui/styles";

import LoadingButton from "../../../../../Components/LoadingButton";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

type RampDialogFooter = {
  onSubmit: () => void;
  onClose: () => void;
  submitButtonText: string;
  isSubmitAllowed: boolean;
  loading: boolean;
};

export const RampPlanDialogFooter = (props: RampDialogFooter) => {
  const classes = useStyle();
  const { onSubmit, onClose, submitButtonText, isSubmitAllowed, loading } = props;

  const isSubmitDisabled: boolean = loading || !isSubmitAllowed;

  return (
    <DialogActions className={classes.root} data-testid="ramp-dialog-footer" data-cy="ramp-dialog-footer">
      <Button onClick={onClose} color="primary" data-testid="new-ramp-dialog-cancel-btn">
        Cancel
      </Button>
      <LoadingButton
        onClick={onSubmit}
        loading={loading}
        disabled={isSubmitDisabled}
        variant="contained"
        color="primary"
        data-testid="new-ramp-dialog-btn"
        mixpanelEventId="ramp-plans.submit"
      >
        {submitButtonText}
      </LoadingButton>
    </DialogActions>
  );
};
