import { type ReactNode } from "react";

import { Button, Stack, type SxProps, type Theme, Typography } from "@mui/material";

type Props = {
  marketplaceSuccessImg: string | ReactNode;
  subscriptionSuccessMsgSubtitle: string;
  subscriptionSuccessMsgTitle?: string;
  linkText: string;
  onLinkClick: () => void;
  sx?: SxProps<Theme>;
};

export const SubscriptionSuccessMsg = ({
  marketplaceSuccessImg,
  linkText,
  onLinkClick,
  subscriptionSuccessMsgSubtitle,
  subscriptionSuccessMsgTitle,
  sx,
}: Props) => (
  <Stack alignItems="center" spacing={1} sx={sx ?? { pt: 16 }}>
    {typeof marketplaceSuccessImg === "string" ? (
      <img src={marketplaceSuccessImg} alt="Marketplace Success" />
    ) : (
      marketplaceSuccessImg
    )}
    <Typography variant="h1">{subscriptionSuccessMsgTitle ?? "Your subscription was successful"}</Typography>
    <Typography variant="subtitle1">{subscriptionSuccessMsgSubtitle}</Typography>
    <Button variant="text" color="primary" onClick={onLinkClick} data-cy="success-cta">
      {linkText}
    </Button>
  </Stack>
);
