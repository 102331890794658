import { useState, Fragment, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@mui/material/TableRow";
import clsx from "clsx";
import Link from "@mui/material/Link";
import Collapse from "@mui/material/Collapse";

import { green, red } from "@mui/material/colors";
import { getSorting, stableSort } from "../../../utils/sorting";
import { ThemeModes } from "../../../muiThemeTypes";

const getTableUrl = (project, dataset, table) =>
  `https://console.cloud.google.com/bigquery?project=${project}&p=${project}&d=${dataset}&t=${table}&page=table`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  cell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 200,
    transition: theme.transitions.create(["background", "opacity"], {
      duration: theme.transitions.duration.complex,
    }),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  cellActiveGreen: {
    background: green[300],
    opacity: 0.7,
  },
  cellActiveRed: {
    background: red[300],
    opacity: 0.7,
  },
  link: {
    cursor: "pointer",
  },
}));
let timeOutInterval = null;

export default function StickyHeadTable({ rows, columns, sliderVal, handleClick }) {
  const classes = useStyles();
  const theme = useTheme();
  const [page] = useState(0);
  const [rowsPerPage] = useState(100);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(columns[columns.length - 1].id);
  const [cellClass, setCellClass] = useState("");
  const [openPartitionPanel, setOpenPartitionPanel] = useState([]);
  const [partitionData, setPartitionData] = useState([]);
  const [, setSliderValueState] = useState(0);

  useEffect(() => {
    const tmp = [];
    rows.forEach(() => {
      tmp.push(false);
    });
    setOpenPartitionPanel(tmp);
  }, [rows]);

  useEffect(() => {
    clearTimeout(timeOutInterval);
    let classColor = classes.cellActiveGreen;
    setSliderValueState((v) => {
      if (sliderVal > 0 && v > sliderVal) {
        classColor = classes.cellActiveRed;
      }
      return sliderVal;
    });
    setCellClass(() => classColor);
    timeOutInterval = setTimeout(() => {
      setCellClass(() => classes.cell);
    }, 500);
  }, [classes.cell, classes.cellActiveGreen, classes.cellActiveRed, sliderVal]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const preventDefault = (row) => {
    const selectedObj = { data: {} };
    selectedObj.data = row;
    selectedObj.name = row.jobId;
    handleClick(selectedObj);
  };
  const openPanel = (row, index) => {
    setPartitionData(row.partitionsAvailable);
    setOpenPartitionPanel((p) => {
      p[index] = !p[index];
      p = p.map((item, i) => {
        if (index !== i) {
          return false;
        } else {
          return p[index];
        }
      });
      return p;
    });
  };
  if (!rows) {
    return null;
  }
  const getTableLink = (val, row) => {
    let projectId;
    let dataset;
    let table;
    if (row.tableFullId) {
      const resource = row.tableFullId.split(":");
      projectId = resource[0];
      dataset = resource[1].split(".")[0];
      table = resource[1].split(".")[1];
    } else {
      projectId = row.projectId ? row.projectId : "";
      dataset = row.datasetId ? row.datasetId : "";
      table = row.tableId ? row.tableId : row.tableIdBaseName;
    }
    return (
      <Link target="_blank" href={getTableUrl(projectId, dataset, table)}>
        {val}
      </Link>
    );
  };
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(
                (column, i) =>
                  column.visible && (
                    <TableCell
                      key={`${column.id}/${i}`}
                      sortDirection={orderBy === column.id ? order : false}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={createSortHandler(column.id)}
                      >
                        {column.title}
                        {orderBy === column.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, rowIndex) => (
                <Fragment key={`row${rowIndex}`}>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    {columns.map((column, i) => {
                      const value = column.isPartition ? row[column.id].length : row[column.id];
                      return (
                        column.id.indexOf("reducingBy") === -1 &&
                        column.visible &&
                        column.id.indexOf("jobRef") === -1 && (
                          <TableCell
                            className={
                              column.id === "savingByReducingJob" ? clsx(classes.cell, cellClass) : classes.cell
                            }
                            key={`${column.id}-${i}`}
                          >
                            {column.id !== "jobId" && !column.isPartition ? (
                              <Tooltip
                                disableHoverListener={column.format(value).length <= 20}
                                title={column.format ? column.format(value) : value}
                              >
                                {column.title.toLowerCase() === "table" ? (
                                  getTableLink(value, row)
                                ) : (
                                  <span> {column.format ? column.format(value) : value}</span>
                                )}
                              </Tooltip>
                            ) : (
                              <Link
                                name={i}
                                id={rowIndex}
                                className={classes.link}
                                color="primary"
                                onClick={
                                  column.isPartition ? () => openPanel(row, rowIndex) : () => preventDefault(row)
                                }
                              >
                                {column.format ? column.format(value) : value}
                              </Link>
                            )}
                          </TableCell>
                        )
                      );
                    })}
                  </TableRow>
                  {openPartitionPanel[rowIndex] && (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Collapse in={openPartitionPanel[rowIndex]} unmountOnExit>
                          <TableContainer>
                            <Table
                              size="small"
                              style={{
                                padding: 0,
                                backgroundColor: theme.palette.mode === ThemeModes.DARK ? "#000" : "#fdfdde",
                              }}
                            >
                              <TableBody>
                                {partitionData.map((row, pIndex) => (
                                  <TableRow hover key={pIndex}>
                                    {columns.map((col, i) => {
                                      const value = row[col.id];
                                      return (
                                        col.visible && (
                                          <TableCell
                                            className={classes.cell}
                                            key={`${col.id}--${i}`}
                                            style={{
                                              minWidth: col.minWidth,
                                              maxWidth: col.maxWidth,
                                              width: col.isPartition ? 300 : 200,
                                            }}
                                          >
                                            {col.format ? col.format(value) : value}
                                          </TableCell>
                                        )
                                      );
                                    })}
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
