import { Paper, Radio, Stack, type SxProps } from "@mui/material";

type Props = {
  active: boolean;
  onClick: () => void;
  children: React.ReactNode;
  sx?: SxProps;
};

export const CustomCard = ({ active, onClick, children, sx }: Props) => (
  <Paper
    variant="outlined"
    sx={{
      borderColor: active ? "primary.main" : "",
      backgroundColor: active ? "rgba(179, 190, 255, 0.1)" : "",
      px: 3,
      pb: 2,
      pt: 1,
      cursor: "pointer",
      ...sx,
    }}
    onClick={onClick}
  >
    <Stack alignItems="center" height="100%">
      <Radio checked={active} disableRipple />
      {children}
    </Stack>
  </Paper>
);
