import { useState } from "react";

import { type BillingStandaloneOnboardingAWSModel, SaaSConsoleType } from "@doitintl/cmp-models";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";

import useMountEffect from "../../../../../Components/hooks/useMountEffect";
import LoadingButton from "../../../../../Components/LoadingButton";
import { trackCurRefresh, trackCurView } from "../../Common/mixpanelEvents";
import { UsefulLinks } from "../../Common/UsefulLinks";
import { useSaaSConsoleAPI } from "../../hooks";
import CurDiscoveryUI, { CreateReportInstructions } from "./CurDiscoveryUI";

type Props = {
  accountId: string;
  onboarding?: BillingStandaloneOnboardingAWSModel;
  bucket: string;
  selectedCur?: string;
  setSelectedCur: (cur?: string) => void;
};

const CurVerification = ({ accountId, onboarding, bucket, selectedCur, setSelectedCur }: Props) => {
  const saasConsoleAPI = useSaaSConsoleAPI(SaaSConsoleType.AWS);
  const curPath = onboarding?.accounts?.[accountId]?.curPaths;
  const [loading, setLoading] = useState(false);

  useMountEffect(() => {
    trackCurView({
      availableCURs: curPath?.paths?.length || 0,
    });
  });

  const onRefresh = async () => {
    trackCurRefresh();
    setLoading(true);
    await saasConsoleAPI.refreshAWSCurPath(accountId);
    setLoading(false);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h1" fontWeight="500" sx={{ pb: 1 }}>
        Confirm Cost and Usage Report
      </Typography>
      <Alert severity="success">
        <AlertTitle>AWS account successfully connected</AlertTitle>
        <Typography>Provide the correct Cost and Usage Report to complete onboarding.</Typography>
      </Alert>

      <CurDiscoveryUI curPath={curPath} bucket={bucket} selectedCur={selectedCur} setSelectedCur={setSelectedCur} />
      <LoadingButton
        color="primary"
        startIcon={<RefreshIcon fontSize="small" />}
        onClick={onRefresh}
        loading={loading}
        data-cy="refresh-button"
        sx={{ py: 1, alignSelf: "flex-start" }}
        mixpanelEventId="customer.cur-verification.refresh"
      >
        Refresh
      </LoadingButton>

      {curPath?.state === "NAN" && <CreateReportInstructions />}

      <Stack alignItems="flex-start" spacing={2} pt={3} pb={5}>
        <Typography>For additional support or guidance please contact our dedicated support team</Typography>
        <Button variant="outlined" onClick={() => window.open(`/support/new`, "_blank")}>
          Contact support
        </Button>
        <UsefulLinks cloud={SaaSConsoleType.AWS} showContactSupportButton={false} />
      </Stack>
    </Stack>
  );
};

export default CurVerification;
