import { Link as RouteLink } from "react-router-dom";
import { DoitRole } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { DateTime } from "luxon";

import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import { dateFormatCompleteDateWithTime } from "../../../../utils/dateTimeFormats";
import { EditAWSPayerConfigButton } from "./AWSPayerConfigForm";
import { type ExtendedFlexsavePayerConfigs } from "./AWSPayerConfigForm/types";
import Chip from "./PayerChip";
import { getFlexsavePayerType, getManagedType } from "./utils";

type Props = {
  row: ExtendedFlexsavePayerConfigs;
};

export const AWSOpsRows = ({ row }: Props) => {
  const isFlexsaveAdmin = useDoitRoleCheck(DoitRole.FlexsaveAdmin);

  return (
    <CellsWrapper>
      <TableCell>
        <Typography
          color="inherit"
          style={{ textDecoration: "underline" }}
          component={RouteLink}
          to={`/customers/${row.customerId}`}
        >
          {row.primaryDomain}
        </Typography>
      </TableCell>
      <TableCell>{row.accountId}</TableCell>
      <TableCell>
        <Chip
          status={row.status}
          lastStatusTransition={row.lastStatusTransition.find((value) => value.service === "compute")}
        />
      </TableCell>
      <TableCell>
        <Chip
          status={row.rdsStatus}
          lastStatusTransition={row.lastStatusTransition.find((value) => value.service === "rds")}
        />
      </TableCell>
      <TableCell>
        <Chip
          status={row.sagemakerStatus}
          lastStatusTransition={row.lastStatusTransition.find((value) => value.service === "sagemaker")}
        />
      </TableCell>
      <TableCell>{getFlexsavePayerType(row.type)}</TableCell>
      <TableCell>{getManagedType(row.managed)}</TableCell>
      <TableCell>{row.computeDiscount ?? "-"}</TableCell>
      <TableCell>
        {row.computeEffectiveDate
          ? DateTime.fromJSDate(new Date(row.computeEffectiveDate)).toFormat(dateFormatCompleteDateWithTime)
          : "-"}
      </TableCell>
      <TableCell>{row.sagemakerDiscount ?? "-"}</TableCell>
      <TableCell>
        {row.sagemakerEffectiveDate
          ? DateTime.fromJSDate(new Date(row.sagemakerEffectiveDate)).toFormat(dateFormatCompleteDateWithTime)
          : "-"}
      </TableCell>
      <TableCell>{row.rdsDiscount ?? "-"}</TableCell>
      <TableCell>
        {row.rdsEffectiveDate
          ? DateTime.fromJSDate(new Date(row.rdsEffectiveDate)).toFormat(dateFormatCompleteDateWithTime)
          : "-"}
      </TableCell>
      <TableCell>{row.targetPercentage ?? "-"}</TableCell>
      <TableCell>{row.rdsTargetPercentage ?? "-"}</TableCell>
      <TableCell>{row.minSpend ?? "-"}</TableCell>
      <TableCell>{row.maxSpend ?? "-"}</TableCell>
      <TableCell>
        {row.timeDisabled
          ? DateTime.fromJSDate(new Date(row.timeDisabled)).toFormat(dateFormatCompleteDateWithTime)
          : "-"}
      </TableCell>
      <TableCell>{isFlexsaveAdmin ? <EditAWSPayerConfigButton payerConfig={row} /> : ""}</TableCell>
    </CellsWrapper>
  );
};
