import { useEffect } from "react";

import { AssetTypeDoiTCloudIntelligence, AssetTypeLooker, DoitRole } from "@doitintl/cmp-models";
import { Container, Grid } from "@mui/material";

import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import EntitySelect from "../../../../../Components/Selects/EntitySelect";
import { isProduction } from "../../../../../constants";
import { isNext10Contract } from "../../../utils";
import { useContractFormContext } from "../../ContractsFormContext";
import { ifProductWithoutEntity } from "../../utils";
import { AssetsAutocomplete } from "../components/AssetsAutocomplete";
import PointOfSaleSelect from "./../components/PointOfSaleSelect";
import { AccountManagerSelector } from "./components/AccountManagerSelector";
import { CancelContractButton } from "./components/CancelContractButton";
import { ContractTypeSelector } from "./components/ContractTypeSelector";
import { DeleteContractButton } from "./components/DeleteContractButton";
import { LookerSalesProcessField } from "./components/LookerSalesProcessField";
import { TerminateContractButton } from "./components/TerminateContractButton";
import { UndoCancellationButton } from "./components/UndoCancellationButton";
import { UndoContractTerminationButton } from "./components/UndoContractTerminationButton";

export const GeneralStep = () => {
  const { state, handleChange } = useContractFormContext();
  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);

  const showCancelContractButton =
    state.editMode &&
    state.active &&
    ((isNext10Contract(state.type) && (isDoitContractAdmin || !isProduction)) ||
      (!isNext10Contract(state.type) && isDoitContractAdmin) ||
      isDoitContractOwner);

  const showDeleteContractButton = state.editMode && isDoitContractOwner;

  const showUndoContractCancellationButton =
    state.editMode &&
    !state.active &&
    !state.terminated &&
    !state.isEditForbidden &&
    !isNext10Contract(state.type) &&
    state.type !== AssetTypeDoiTCloudIntelligence;

  const showTerminateContractButton =
    state.editMode &&
    state.active &&
    (isDoitContractAdmin || isDoitContractOwner) &&
    !isNext10Contract(state.type) &&
    state.type !== AssetTypeDoiTCloudIntelligence;

  const showUndoContractTerminationButton =
    state.editMode &&
    !state.active &&
    state.terminated &&
    (isDoitContractAdmin || isDoitContractOwner) &&
    !isNext10Contract(state.type);

  useEffect(() => {
    if (state.type === AssetTypeDoiTCloudIntelligence && state.pointOfSale !== "doit") {
      handleChange("entity")({ target: { value: undefined } });
    }
  }, [handleChange, state.pointOfSale, state.type]);

  return (
    <Container maxWidth="sm" data-cy="general-step">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ContractTypeSelector />
        </Grid>
        {state.type === AssetTypeDoiTCloudIntelligence && (
          <Grid item xs={12}>
            <PointOfSaleSelect
              value={state.pointOfSale}
              onChange={handleChange("pointOfSale")}
              margin="dense"
              fullWidth
            />
          </Grid>
        )}
        {(!ifProductWithoutEntity(state.type, state.pointOfSale) || state.type === AssetTypeDoiTCloudIntelligence) && (
          <Grid item xs={12}>
            <EntitySelect
              fullWidth
              margin="dense"
              value={state.entity}
              onChange={handleChange("entity")}
              helperText="Select billing profile"
              error={state.errors.entity}
              disabled={
                (state.type === AssetTypeDoiTCloudIntelligence && state.pointOfSale !== "doit") || state.isEditForbidden
              }
            />
          </Grid>
        )}
        {state.type === AssetTypeLooker && (
          <Grid item xs={12}>
            <AssetsAutocomplete
              disabled={state.isEditForbidden}
              label="Google Cloud billing account"
              helperText="Restrict contract to an asset"
              textFieldProps={{
                error: state.errors?.assets,
                required: true,
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <AccountManagerSelector />
        </Grid>

        {state.type === AssetTypeLooker && (
          <Grid item xs={12}>
            <LookerSalesProcessField />
          </Grid>
        )}

        {showCancelContractButton && (
          <Grid item>
            <CancelContractButton />
          </Grid>
        )}

        {showUndoContractCancellationButton && (
          <Grid item>
            <UndoCancellationButton />
          </Grid>
        )}

        {showTerminateContractButton && (
          <Grid item>
            <TerminateContractButton />
          </Grid>
        )}

        {showUndoContractTerminationButton && (
          <Grid item>
            <UndoContractTerminationButton />
          </Grid>
        )}

        {showDeleteContractButton && (
          <Grid item>
            <DeleteContractButton />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
