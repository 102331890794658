export const addSpaceAndToCapital = (val = "") =>
  val.replace(/([A-Z])/g, " $1")?.replace(/^./, (str) => str.toUpperCase());

export const toCapitalCase = (value: string) => value.toLowerCase().replace(/^\w/, (char) => char.toUpperCase());

export const addSpaceAndToLower = (val = "") => val.replace(/([A-Z])/g, " $1")?.toLowerCase();

export const getLetterForIndex = (idx: number) => {
  if (idx < 0 || idx > 25) {
    throw new Error("Incorrect index");
  }
  return String.fromCharCode(65 + idx);
};

export const reactRouterAllowedURLChars = (text: string) => {
  const disallowed = /[#/?()*]/g;
  return !text.match(disallowed);
};

export const shortenString = (inputString: string, limit: number): string => {
  const items: string[] = inputString.split(", ");

  if (items.length <= limit) {
    return inputString;
  }

  const remainingCount: number = items.length - limit;
  const limitedItems: string = items.slice(0, limit).join(", ");
  const remainingText = `and ${remainingCount} more`;

  return `${limitedItems} ${remainingText}`;
};
