import { useEffect } from "react";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCreateAlertHandler } from "./hooks";

const AlertCreate = () => {
  const { customer, init } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const createAlert = useCreateAlertHandler({
    mixpanelEventName: "analytics.resources.alerts.new",
    baseUrl: `/customers/${customer.id}/analytics/alerts`,
    prevPageUrl: `/customers/${customer.id}/analytics/alerts`,
  });

  useEffect(() => {
    if (init && currentUser.email) {
      void createAlert();
    }
  }, [init, currentUser.email, createAlert]);

  return null;
};

export default AlertCreate;
