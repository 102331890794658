import { type Dispatch, type SetStateAction, useCallback, useMemo } from "react";

import { CloseRounded as CloseIcon, HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Box, Drawer, IconButton, Stack, Tooltip, Typography } from "@mui/material";

import { reportText } from "../../../../assets/texts";
import useReportDocumentation from "../../../../Components/hooks/cloudAnalytics/reports/useReportDocumentation";
import { SafeHtml } from "../../../../Components/SafeHtml";

type Props = {
  documentationId: string;
  helpWidgetOpen: boolean;
  setHelpWidgetOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
};

const HelpIconButton = ({ documentationId, helpWidgetOpen, setHelpWidgetOpen, onClose }: Props) => {
  const data = useReportDocumentation(documentationId);
  const htmlContent = useMemo(() => <SafeHtml html={data?.html} />, [data?.html]);

  const onHelpClick = useCallback(() => {
    setHelpWidgetOpen(true);
  }, [setHelpWidgetOpen]);

  if (!htmlContent) {
    return null;
  }

  return (
    <>
      <Tooltip title={htmlContent ? reportText.UNDERSTAND_THIS_REPORT : ""}>
        <span>
          <IconButton size="small" onClick={onHelpClick}>
            <HelpOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Drawer
        anchor="right"
        open={helpWidgetOpen}
        onClose={onClose}
        sx={{
          ".MuiDrawer-paper": {
            margin: 1,
            borderRadius: 0.5,
            height: `calc(100% - 16px)`,
            overflow: "hidden",
          },
        }}
      >
        <Stack sx={{ minWidth: 250, maxWidth: 450, px: 2, pb: 2, height: "100%" }}>
          <Stack direction="row" sx={{ mt: 2, alignItems: "center" }}>
            <Typography variant="h3" sx={{ mr: 1 }}>
              {reportText.UNDERSTAND_THIS_REPORT}
            </Typography>
            <IconButton data-cy="close-drawer" onClick={onClose} sx={{ ml: "auto" }} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>

          <Box
            sx={(theme) => ({
              overflow: "auto",
              h4: {
                fontWeight: 500,
              },
              a: {
                color: theme.palette.primary.main,
                textDecoration: "none",
              },
            })}
          >
            <div>{htmlContent}</div>
          </Box>
        </Stack>
      </Drawer>
    </>
  );
};

export default HelpIconButton;
