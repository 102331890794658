export default [
  {
    ianaName: "Pacific/Pago_Pago",
    offset: "-11:00",
    value: "American Samoa",
  },
  {
    ianaName: "Etc/GMT+12",
    offset: "-11:00",
    value: "International Date Line West",
  },
  {
    ianaName: "Pacific/Midway",
    offset: "-11:00",
    value: "Midway Island",
  },
  {
    ianaName: "Pacific/Honolulu",
    offset: "-10:00",
    value: "Hawaii",
  },
  {
    ianaName: "America/Juneau",
    offset: "-09:00",
    value: "Alaska",
  },
  {
    ianaName: "America/Los_Angeles",
    offset: "-08:00",
    value: "Pacific Time (US & Canada)",
  },
  {
    ianaName: "America/Tijuana",
    offset: "-08:00",
    value: "Tijuana",
  },
  {
    ianaName: "America/Phoenix",
    offset: "-07:00",
    value: "Arizona",
  },
  {
    ianaName: "America/Chihuahua",
    offset: "-07:00",
    value: "Chihuahua",
  },
  {
    ianaName: "America/Mazatlan",
    offset: "-07:00",
    value: "Mazatlan",
  },
  {
    ianaName: "America/Denver",
    offset: "-07:00",
    value: "Mountain Time (US & Canada)",
  },
  {
    ianaName: "America/Guatemala",
    offset: "-06:00",
    value: "Central America",
  },
  {
    ianaName: "America/Chicago",
    offset: "-06:00",
    value: "Central Time (US & Canada)",
  },
  {
    ianaName: "America/Mexico_City",
    offset: "-06:00",
    value: "Guadalajara",
  },
  {
    ianaName: "America/Mexico_City",
    offset: "-06:00",
    value: "Mexico City",
  },
  {
    ianaName: "America/Monterrey",
    offset: "-06:00",
    value: "Monterrey",
  },
  {
    ianaName: "America/Regina",
    offset: "-06:00",
    value: "Saskatchewan",
  },
  {
    ianaName: "America/Bogota",
    offset: "-05:00",
    value: "Bogota",
  },
  {
    ianaName: "America/New_York",
    offset: "-05:00",
    value: "Eastern Time (US & Canada)",
  },
  {
    ianaName: "America/Indiana/Indianapolis",
    offset: "-05:00",
    value: "Indiana (East)",
  },
  {
    ianaName: "America/Lima",
    offset: "-05:00",
    value: "Lima",
  },
  {
    ianaName: "America/Lima",
    offset: "-05:00",
    value: "Quito",
  },
  {
    ianaName: "America/Halifax",
    offset: "-04:00",
    value: "Atlantic Time (Canada)",
  },
  {
    ianaName: "America/Caracas",
    offset: "-04:00",
    value: "Caracas",
  },
  {
    ianaName: "America/Guyana",
    offset: "-04:00",
    value: "Georgetown",
  },
  {
    ianaName: "America/La_Paz",
    offset: "-04:00",
    value: "La Paz",
  },
  {
    ianaName: "America/Puerto_Rico",
    offset: "-04:00",
    value: "Puerto Rico",
  },
  {
    ianaName: "America/St_Johns",
    offset: "-03:30",
    value: "Newfoundland",
  },
  {
    ianaName: "America/Sao_Paulo",
    offset: "-03:00",
    value: "Brasilia",
  },
  {
    ianaName: "America/Argentina/Buenos_Aires",
    offset: "-03:00",
    value: "Buenos Aires",
  },
  {
    ianaName: "America/Godthab",
    offset: "-03:00",
    value: "Greenland",
  },
  {
    ianaName: "America/Montevideo",
    offset: "-03:00",
    value: "Montevideo",
  },
  {
    ianaName: "America/Santiago",
    offset: "-03:00",
    value: "Santiago",
  },
  {
    ianaName: "Atlantic/South_Georgia",
    offset: "-02:00",
    value: "Mid-Atlantic",
  },
  {
    ianaName: "Atlantic/Azores",
    offset: "-01:00",
    value: "Azores",
  },
  {
    ianaName: "Atlantic/Cape_Verde",
    offset: "-01:00",
    value: "Cape Verde Is.",
  },
  {
    ianaName: "Europe/Dublin",
    offset: "+00:00",
    value: "Dublin",
  },
  {
    ianaName: "Europe/London",
    offset: "+00:00",
    value: "Edinburgh",
  },
  {
    ianaName: "Europe/Lisbon",
    offset: "+00:00",
    value: "Lisbon",
  },
  {
    ianaName: "Europe/London",
    offset: "+00:00",
    value: "London",
  },
  {
    ianaName: "Africa/Monrovia",
    offset: "+00:00",
    value: "Monrovia",
  },
  {
    ianaName: "Etc/UTC",
    offset: "+00:00",
    value: "UTC",
  },
  {
    ianaName: "Europe/Amsterdam",
    offset: "+01:00",
    value: "Amsterdam",
  },
  {
    ianaName: "Europe/Belgrade",
    offset: "+01:00",
    value: "Belgrade",
  },
  {
    ianaName: "Europe/Berlin",
    offset: "+01:00",
    value: "Berlin",
  },
  {
    ianaName: "Europe/Zurich",
    offset: "+01:00",
    value: "Bern",
  },
  {
    ianaName: "Europe/Bratislava",
    offset: "+01:00",
    value: "Bratislava",
  },
  {
    ianaName: "Europe/Brussels",
    offset: "+01:00",
    value: "Brussels",
  },
  {
    ianaName: "Europe/Budapest",
    offset: "+01:00",
    value: "Budapest",
  },
  {
    ianaName: "Africa/Casablanca",
    offset: "+01:00",
    value: "Casablanca",
  },
  {
    ianaName: "Europe/Copenhagen",
    offset: "+01:00",
    value: "Copenhagen",
  },
  {
    ianaName: "Europe/Ljubljana",
    offset: "+01:00",
    value: "Ljubljana",
  },
  {
    ianaName: "Europe/Madrid",
    offset: "+01:00",
    value: "Madrid",
  },
  {
    ianaName: " Europe/Oslo",
    offset: "+01:00",
    value: "Oslo",
  },
  {
    ianaName: "Europe/Paris",
    offset: "+01:00",
    value: "Paris",
  },
  {
    ianaName: "Europe/Prague",
    offset: "+01:00",
    value: "Prague",
  },
  {
    ianaName: "Europe/Rome",
    offset: "+01:00",
    value: "Rome",
  },
  {
    ianaName: "Europe/Sarajevo",
    offset: "+01:00",
    value: "Sarajevo",
  },
  {
    ianaName: "Europe/Skopje",
    offset: "+01:00",
    value: "Skopje",
  },
  {
    ianaName: "Europe/Stockholm",
    offset: "+01:00",
    value: "Stockholm",
  },
  {
    ianaName: "Europe/Vienna",
    offset: "+01:00",
    value: "Vienna",
  },
  {
    ianaName: "Europe/Warsaw",
    offset: "+01:00",
    value: "Warsaw",
  },
  {
    ianaName: "Africa/Algiers",
    offset: "+01:00",
    value: "West Central Africa",
  },
  {
    ianaName: "Europe/Zagreb",
    offset: "+01:00",
    value: "Zagreb",
  },
  {
    ianaName: "Europe/Athens",
    offset: "+02:00",
    value: "Athens",
  },
  {
    ianaName: "Europe/Bucharest",
    offset: "+02:00",
    value: "Bucharest",
  },
  {
    ianaName: "Africa/Cairo",
    offset: "+02:00",
    value: "Cairo",
  },
  {
    ianaName: "Africa/Harare",
    offset: "+02:00",
    value: "Harare",
  },
  {
    ianaName: "Europe/Helsinki",
    offset: "+02:00",
    value: "Helsinki",
  },
  {
    ianaName: "Asia/Jerusalem",
    offset: "+02:00",
    value: "Jerusalem",
  },
  {
    ianaName: "Europe/Kaliningrad",
    offset: "+02:00",
    value: "Kaliningrad",
  },
  {
    ianaName: "Europe/Kiev",
    offset: "+02:00",
    value: "Kyiv",
  },
  {
    ianaName: "Africa/Johannesburg",
    offset: "+02:00",
    value: "Pretoria",
  },
  {
    ianaName: "Europe/Riga",
    offset: "+02:00",
    value: "Riga",
  },
  {
    ianaName: "Europe/Sofia",
    offset: "+02:00",
    value: "Sofia",
  },
  {
    ianaName: "Europe/Tallinn",
    offset: "+02:00",
    value: "Tallinn",
  },
  {
    ianaName: "Europe/Vilnius",
    offset: "+02:00",
    value: "Vilnius",
  },
  {
    ianaName: "Asia/Baghdad",
    offset: "+03:00",
    value: "Baghdad",
  },
  {
    ianaName: "Europe/Istanbul",
    offset: "+03:00",
    value: "Istanbul",
  },
  {
    ianaName: "Asia/Kuwait",
    offset: "+03:00",
    value: "Kuwait",
  },
  {
    ianaName: "Europe/Minsk",
    offset: "+03:00",
    value: "Minsk",
  },
  {
    ianaName: "Europe/Moscow",
    offset: "+03:00",
    value: "Moscow",
  },
  {
    ianaName: "Africa/Nairobi",
    offset: "+03:00",
    value: "Nairobi",
  },
  {
    ianaName: "Asia/Riyadh",
    offset: "+03:00",
    value: "Riyadh",
  },
  {
    ianaName: "Europe/Moscow",
    offset: "+03:00",
    value: "St. Petersburg",
  },
  {
    ianaName: "Asia/Tehran",
    offset: "+03:30",
    value: "Tehran",
  },
  {
    ianaName: "Asia/Muscat",
    offset: "+04:00",
    value: "Abu Dhabi",
  },
  {
    ianaName: "Asia/Baku",
    offset: "+04:00",
    value: "Baku",
  },
  {
    ianaName: "Asia/Muscat",
    offset: "+04:00",
    value: "Muscat",
  },
  {
    ianaName: "Europe/Samara",
    offset: "+04:00",
    value: "Samara",
  },
  {
    ianaName: "Asia/Tbilisi",
    offset: "+04:00",
    value: "Tbilisi",
  },
  {
    ianaName: "Europe/Volgograd",
    offset: "+04:00",
    value: "Volgograd",
  },
  {
    ianaName: "Asia/Yerevan",
    offset: "+04:00",
    value: "Yerevan",
  },
  {
    ianaName: "Asia/Kabul",
    offset: "+04:30",
    value: "Kabul",
  },
  {
    ianaName: "Asia/Yekaterinburg",
    offset: "+05:00",
    value: "Ekaterinburg",
  },
  {
    ianaName: "Asia/Karachi",
    offset: "+05:00",
    value: "Islamabad",
  },
  {
    ianaName: "Asia/Karachi",
    offset: "+05:00",
    value: "Karachi",
  },
  {
    ianaName: "Asia/Tashkent",
    offset: "+05:00",
    value: "Tashkent",
  },
  {
    ianaName: "Asia/Kolkata",
    offset: "+05:30",
    value: "Chennai",
  },
  {
    ianaName: "Asia/Kolkata",
    offset: "+05:30",
    value: "Kolkata",
  },
  {
    ianaName: "Asia/Kolkata",
    offset: "+05:30",
    value: "Mumbai",
  },
  {
    ianaName: "Asia/Kolkata",
    offset: "+05:30",
    value: "New Delhi",
  },
  {
    ianaName: "Asia/Colombo",
    offset: "+05:30",
    value: "Sri Jayawardenepura",
  },
  {
    ianaName: "Asia/Kathmandu",
    offset: "+05:45",
    value: "Kathmandu",
  },
  {
    ianaName: "Asia/Almaty",
    offset: "+06:00",
    value: "Almaty",
  },
  {
    ianaName: "Asia/Dhaka",
    offset: "+06:00",
    value: "Astana",
  },
  {
    ianaName: "Asia/Dhaka",
    offset: "+06:00",
    value: "Dhaka",
  },
  {
    ianaName: "Asia/Urumqi",
    offset: "+06:00",
    value: "Urumqi",
  },
  {
    ianaName: "Asia/Rangoon",
    offset: "+06:30",
    value: "Rangoon",
  },
  {
    ianaName: "Asia/Bangkok",
    offset: "+07:00",
    value: "Bangkok",
  },
  {
    ianaName: "Asia/Bangkok",
    offset: "+07:00",
    value: "Hanoi",
  },
  {
    ianaName: "Asia/Jakarta",
    offset: "+07:00",
    value: "Jakarta",
  },
  {
    ianaName: "Asia/Krasnoyarsk",
    offset: "+07:00",
    value: "Krasnoyarsk",
  },
  {
    ianaName: "Asia/Novosibirsk",
    offset: "+07:00",
    value: "Novosibirsk",
  },
  {
    ianaName: "Asia/Shanghai",
    offset: "+08:00",
    value: "Beijing",
  },
  {
    ianaName: "Asia/Chongqing",
    offset: "+08:00",
    value: "Chongqing",
  },
  {
    ianaName: "Asia/Hong_Kong",
    offset: "+08:00",
    value: "Hong Kong",
  },
  {
    ianaName: "Asia/Irkutsk",
    offset: "+08:00",
    value: "Irkutsk",
  },
  {
    ianaName: "Asia/Kuala_Lumpur",
    offset: "+08:00",
    value: "Kuala Lumpur",
  },
  {
    ianaName: "Australia/Perth",
    offset: "+08:00",
    value: "Perth",
  },
  {
    ianaName: "Asia/Singapore",
    offset: "+08:00",
    value: "Singapore",
  },
  {
    ianaName: "Asia/Taipei",
    offset: "+08:00",
    value: "Taipei",
  },
  {
    ianaName: "Asia/Ulaanbaatar",
    offset: "+08:00",
    value: "Ulaanbaatar",
  },
  {
    ianaName: "Asia/Tokyo",
    offset: "+09:00",
    value: "Osaka",
  },
  {
    ianaName: "Asia/Tokyo",
    offset: "+09:00",
    value: "Sapporo",
  },
  {
    ianaName: "Asia/Seoul",
    offset: "+09:00",
    value: "Seoul",
  },
  {
    ianaName: "Asia/Tokyo",
    offset: "+09:00",
    value: "Tokyo",
  },
  {
    ianaName: "Asia/Yakutsk",
    offset: "+09:00",
    value: "Yakutsk",
  },
  {
    ianaName: "Australia/Darwin",
    offset: "+09:30",
    value: "Darwin",
  },
  {
    ianaName: "Australia/Brisbane",
    offset: "+10:00",
    value: "Brisbane",
  },
  {
    ianaName: "Pacific/Guam",
    offset: "+10:00",
    value: "Guam",
  },
  {
    ianaName: "Pacific/Port_Moresby",
    offset: "+10:00",
    value: "Port Moresby",
  },
  {
    ianaName: "Asia/Vladivostok",
    offset: "+10:00",
    value: "Vladivostok",
  },
  {
    ianaName: "Australia/Adelaide",
    offset: "+10:30",
    value: "Adelaide",
  },
  {
    ianaName: "Australia/Melbourne",
    offset: "+11:00",
    value: "Canberra",
  },
  {
    ianaName: "Australia/Hobart",
    offset: "+11:00",
    value: "Hobart",
  },
  {
    ianaName: "Asia/Magadan",
    offset: "+11:00",
    value: "Magadan",
  },
  {
    ianaName: "Australia/Melbourne",
    offset: "+11:00",
    value: "Melbourne",
  },
  {
    ianaName: "Pacific/Noumea",
    offset: "+11:00",
    value: "New Caledonia",
  },
  {
    ianaName: "Pacific/Guadalcanal",
    offset: "+11:00",
    value: "Solomon Is.",
  },
  {
    ianaName: "Asia/Srednekolymsk",
    offset: "+11:00",
    value: "Srednekolymsk",
  },
  {
    ianaName: "Australia/Sydney",
    offset: "+11:00",
    value: "Sydney",
  },
  {
    ianaName: "Pacific/Fiji",
    offset: "+12:00",
    value: "Fiji",
  },
  {
    ianaName: "Asia/Kamchatka",
    offset: "+12:00",
    value: "Kamchatka",
  },
  {
    ianaName: "Pacific/Majuro",
    offset: "+12:00",
    value: "Marshall Is.",
  },
  {
    ianaName: "Pacific/Auckland",
    offset: "+13:00",
    value: "Auckland",
  },
  {
    ianaName: "Pacific/Tongatapu",
    offset: "+13:00",
    value: "Nuku'alofa",
  },
  {
    ianaName: "Pacific/Fakaofo",
    offset: "+13:00",
    value: "Tokelau Is.",
  },
  {
    ianaName: "Pacific/Auckland",
    offset: "+13:00",
    value: "Wellington",
  },
  {
    ianaName: "Pacific/Chatham",
    offset: "+13:45",
    value: "Chatham Is.",
  },
  {
    ianaName: "Pacific/Apia",
    offset: "+14:00",
    value: "Samoa",
  },
];
