import { useCallback, useEffect, useState } from "react";

import { AppModel, type GcpLocation } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  cardHeader: {
    marginBottom: 26,
  },
}));

export default function LocationSelector({ onSelectLocation, onConfirm }) {
  const classes = useStyles();
  const [locations, setLocations] = useState<GcpLocation[]>([]);

  useEffect(() => {
    getCollection(AppModel)
      .doc("cloud-connect")
      .get()
      .then((regionalLocations) => {
        const data = regionalLocations.asModelData();

        if (!data) {
          return;
        }

        setLocations(data.datasetLocations.gcp);
      });
  }, []);

  const getOptionLabel = useCallback((option) => `${option.title} (${option.value})`, []);
  const renderInput = useCallback((params) => <TextField {...params} label="Select Location" variant="outlined" />, []);

  return (
    <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Select dataset location</DialogTitle>
      <DialogContent>
        <p className={classes.cardHeader}>
          <Typography>
            Please specify a location for storing your BigQuery Lens dataset. After you create the dataset, the location
            cannot be changed.{" "}
            <Link href="https://cloud.google.com/bigquery/docs/locations#supported_regions" target="_blank">
              Read more.
            </Link>
          </Typography>
        </p>
        <Autocomplete
          id="grouped-demo"
          size="small"
          options={locations}
          defaultValue={locations[0]}
          getOptionLabel={getOptionLabel}
          onChange={onSelectLocation}
          renderInput={renderInput}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
