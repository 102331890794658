import { CopyToClipboardButton } from "../../../../Components/CopyToClipboardButton";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";

type Props = {
  textToCopy: string;
  label: string;
};

export const CopyLinkWithSnackBar = ({ textToCopy, label }: Props) => {
  const snackbar = useSnackbar();

  const handleClick = () => {
    const props = {
      message: "Link successfully copied",
      withIcon: false,
      autoHideDuration: 3000,
    };
    snackbar.onOpen(props);
  };

  return (
    <CopyToClipboardButton color="primary" text={textToCopy} label={label} onClick={handleClick} underline={false} />
  );
};
