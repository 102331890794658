// Set user asset properties in Mixpanel
import { useCallback, useEffect } from "react";

import {
  type AssetModel,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGcpPartnerLedPremiumSupport,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudStandalone,
  AssetTypeMicrosoftAzure,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import debounce from "lodash/debounce";

import { consoleErrorWithSentry } from "./errorHandling";
import mixpanel from "./mixpanel";

type Assets = Record<string, { data: WithFirebaseModel<AssetModel> }[]>;

export const MixpanelAssets = ({ assets }: { assets?: Assets }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mixpanelPeopleSet = useCallback(
    debounce((assetsToDebounce: Assets) => {
      try {
        const result = {
          "amazon-web-services": false,
          "google-cloud": false,
          "g-suite": false,
          "office-365": false,
        };

        for (const [entityId, entityAssets] of Object.entries(assetsToDebounce)) {
          if (entityId === "_unassigned") {
            continue;
          }
          for (const asset of entityAssets) {
            result[asset.data.type] = true;
          }
        }

        const mixPanelProperties = Object.fromEntries(
          Object.entries(result).map(([key, value]) =>
            // Modify key here
            [`assets.has.${key}`, value]
          )
        );
        mixpanel.people.set(mixPanelProperties);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    }, 15000),
    []
  );

  useEffect(() => {
    if (assets) {
      mixpanelPeopleSet(assets);
    }
  }, [assets, mixpanelPeopleSet]);

  return null;
};

export const mixpanelAssetMap = (assetType: AssetType | string) => {
  switch (assetType) {
    case AssetTypeGoogleCloud:
    case AssetTypeGoogleCloudDirect:
      return {
        assetType: "gcp",
        status: "resold",
      };
    case AssetTypeGoogleCloudStandalone:
      return {
        assetType: "gcp",
        status: "product-only",
      };

    case AssetTypeAmazonWebServices:
      return {
        assetType: "aws",
        status: "resold",
      };
    case AssetTypeAwsStandalone:
      return {
        assetType: "aws",
        status: "product-only",
      };
    case AssetTypeGcpPartnerLedPremiumSupport:
      return {
        assetType: "google premium support",
        status: "resold",
      };
    case AssetTypeMicrosoftAzure:
      return {
        assetType: "azure",
        status: "resold",
      };
    default:
      break;
  }
};
