import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { Paper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { CloudLogo } from "./CloudLogo";

type Props = {
  type: FlexsaveType;
  onClick: () => void;
};

export const CloudBox = ({ type, onClick }: Props) => {
  const theme = useTheme();
  return (
    <Paper
      variant="outlined"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        p: { md: 9, sm: 7, xs: 5 },
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: { md: "48%", sm: "100%" },
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
      }}
    >
      <CloudLogo type={type} size={100} />
      <Typography variant="h4" pt={2} sx={{ textAlign: "center" }}>
        {type === FlexsaveTypes.AWS ? "Amazon Web Services" : "Google Cloud Platform"}
      </Typography>
    </Paper>
  );
};
