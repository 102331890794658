import { useCallback, useEffect, useState } from "react";

import { AssetModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { DialogContent, Typography } from "@mui/material";
import Link from "@mui/material/Link";

import { useApiContext } from "../../../../../api/context";
import SimpleDialog from "../../../../../Components/SimpleDialog";
import TransferProjectsDialog from "../../../../../Components/TransferProjects/TransferProjectsDialog";
import { consoleErrorWithSentry } from "../../../../../utils";
import { createServiceAccount, gcProjectsTransfer, testServiceAccount } from "../../utils";

type TransferGCProjectsProps = {
  assetId: string;
  firstDialogOpen: boolean;
  onTransferProjectsClick: () => void;
  onTransferProjectsCancel: () => void;
};

const dialogContent = (
  <DialogContent data-testid="transfer-gc-projects-simple-dialog">
    <Typography>
      The Project Transfer Wizard can help when transferring billing accounts for many Google Cloud projects (e.g., 20
      or more). You may find the{" "}
      <Link
        target="_blank"
        rel="noopener noreferrer"
        href="https://help.doit.com/google-cloud/transfer-google-cloud-projects/transfer-manually"
        data-testid="transfer-gc-projects-dialog-link"
      >
        manual transfer approach
      </Link>{" "}
      more suitable for smaller tasks.
    </Typography>
  </DialogContent>
);
const requisiteResponseData = (res) => res?.data?.sa && res?.data?.projectList;

const TransferGCProjects = (props: TransferGCProjectsProps) => {
  const { firstDialogOpen, onTransferProjectsClick, onTransferProjectsCancel, assetId } = props;
  const [assetData] = useDocumentDataOnce(getCollection(AssetModel).doc(assetId));
  const api = useApiContext();
  const [transferProjectsDialogOpen, setTransferProjectsDialogOpen] = useState<boolean>(false);
  const [confirmTransferWizardDialog, setConfirmTransferWizardDialog] = useState<boolean>(false);
  const [serviceAccountEmail, setServiceAccountEmail] = useState<string | null>(null);
  const [customerProjects, setCustomerProjects] = useState<string[]>([]);
  const [loadingForServiceAccount, setLoadingForServiceAccount] = useState<boolean>(false);
  const [isServiceAccountValid, setIsServiceAccountValid] = useState<boolean | null>(null);
  const [transferredProjects, setTransferredProjects] = useState<string[] | null>(null);
  const [isTransferDone, setIsTransferDone] = useState<boolean>(true);

  useEffect(() => {
    setConfirmTransferWizardDialog(firstDialogOpen);
  }, [firstDialogOpen]);

  const onSimpleDialogConfirm = () => {
    setTransferProjectsDialogOpen(true);
    setConfirmTransferWizardDialog(true);
    onTransferProjectsClick();
  };

  const onSimpleDialogCancel = () => {
    onTransferProjectsCancel();
    setConfirmTransferWizardDialog(false);
  };

  const closeTransfer = () => {
    onSimpleDialogCancel();
  };

  const resetTransferBilling = () => {
    setServiceAccountEmail(null);
    setCustomerProjects([]);
  };

  const createSA = useCallback(async () => {
    setLoadingForServiceAccount(true);
    setCustomerProjects([]);
    setIsServiceAccountValid(null);
    setIsTransferDone(false);
    if (assetData?.customer) {
      const res = await createServiceAccount(api, assetData.customer.id, assetData.properties.billingAccountId);
      if (requisiteResponseData(res)) {
        setServiceAccountEmail(res.data.sa);
        setCustomerProjects(res.data.projectList);
      }
    }
    setLoadingForServiceAccount(false);
  }, [api, assetData?.customer, assetData?.properties.billingAccountId]);

  const transferProjects = useCallback(
    async (projects: string[]) => {
      if (!assetData?.customer?.id) {
        return;
      }

      setLoadingForServiceAccount(true);
      setIsTransferDone(false);
      const transferRes = await gcProjectsTransfer(
        assetData.properties.billingAccountId,
        customerProjects,
        projects,
        api,
        assetData.customer.id
      );
      if (transferRes?.data) {
        setLoadingForServiceAccount(false);
        setIsTransferDone(true);
        setTransferredProjects(transferRes.data);
        return;
      }
      setLoadingForServiceAccount(false);
      setIsTransferDone(false);
    },
    [api, customerProjects, assetData?.customer, assetData?.properties?.billingAccountId]
  );

  const testServiceAccountPermission = useCallback(async () => {
    if (!assetData?.customer?.id) {
      return;
    }

    setLoadingForServiceAccount(true);
    setIsServiceAccountValid(null);
    try {
      const res = await testServiceAccount(api, assetData?.customer?.id, assetData.properties.billingAccountId);
      setIsServiceAccountValid(!!res.data.isValid);
    } catch (error: any) {
      consoleErrorWithSentry(error);
    }

    setLoadingForServiceAccount(false);
  }, [api, assetData?.customer?.id, assetData?.properties?.billingAccountId]);

  if (!assetData) {
    return null;
  }
  return (
    <div data-testid="transfer-projects-dialogs">
      <SimpleDialog
        open={confirmTransferWizardDialog}
        title="Project Transfer Wizard"
        confirmButtonText="Start Transfer Wizard"
        cancelButtonText="Cancel"
        cancelButtonColor="secondary"
        onConfirm={onSimpleDialogConfirm}
        onCancel={onSimpleDialogCancel}
      >
        {dialogContent}
      </SimpleDialog>
      <TransferProjectsDialog
        handleClose={closeTransfer}
        open={transferProjectsDialogOpen}
        createSA={createSA}
        transferProjects={transferProjects}
        serviceAccountEmail={serviceAccountEmail}
        loading={loadingForServiceAccount}
        customerProjects={customerProjects}
        assetData={assetData}
        isServiceAccountValid={isServiceAccountValid}
        reset={resetTransferBilling}
        isTransferDone={isTransferDone}
        testServiceAccountPermission={testServiceAccountPermission}
        transferredProjects={transferredProjects}
      />
    </div>
  );
};
export default TransferGCProjects;
