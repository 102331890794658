import { type ReactNode } from "react";

import NotFound from "../../Pages/NotFound/NotFound";

export type GuardProps = {
  isAllowed: boolean;
  fallback?: ReactNode;
  children: ReactNode;
};

export const Guard = ({ isAllowed, fallback = <NotFound />, children }) => <>{isAllowed ? children : fallback}</>;
