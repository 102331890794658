import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import { jsUcfirst } from "../../../utils/common";
import { addSpaceAndToCapital } from "../../../utils/string";
import { mainFilterIndex, units } from "./constants";
import { useSelectStyles } from "./utils";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

type Props = {
  items: Readonly<Array<string>>;
  onSelected: (string) => Promise<void> | void;
  chipIndex: number;
  filters: Readonly<Array<string>>;
};

export default function ChipMenu({ items, onSelected, chipIndex, filters }: Props) {
  const classes = useStyles();
  const selectClasses = useSelectStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        size="small"
        value={chipIndex === mainFilterIndex ? filters[0] : filters[1]}
        onChange={(e) => onSelected(e.target.value)}
        className={selectClasses.select}
      >
        {items.map((item) => (
          <MenuItem value={item} key={item} className={selectClasses.select}>
            {chipIndex <= 1 ? addSpaceAndToCapital(jsUcfirst(item)) : units.find((v) => v.value === item)?.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
