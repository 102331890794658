import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import DialogContent from "@mui/material/DialogContent";
import { type FilledInputProps } from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { DateTimePicker } from "@mui/x-date-pickers";

import Autosuggest from "../../../Components/KnownIssues/Autosuggest";
import { platformsToPlatformsLabelMap } from "../consts";
import { type KnownIssue, KnownIssueStatus, type Product } from "../types";

const useKnownIssueDialogContentStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
}));

export type KnownIssueDialogContentProps = {
  productsSuggestions: Product[];
  knownIssueFormData: Partial<KnownIssue>;
  onFormInputChange: FilledInputProps["onChange"];
  onProductChange: any;
  onDateChange: any;
};

export const KnownIssueDialogContent = ({
  productsSuggestions,
  knownIssueFormData,
  onFormInputChange,
  onProductChange,
  onDateChange,
}: KnownIssueDialogContentProps) => {
  const classes = useKnownIssueDialogContentStyle();

  const dateTimeView = knownIssueFormData.dateTime?.toDate();

  return (
    <DialogContent className={classes.root}>
      <TextField
        id="platformId"
        name="platform"
        select
        label="Platform"
        value={knownIssueFormData.platform}
        onChange={onFormInputChange}
        variant="outlined"
        margin="normal"
      >
        {Object.keys(platformsToPlatformsLabelMap).map((platform, index) => (
          <MenuItem key={index} value={platform}>
            {platformsToPlatformsLabelMap[platform]}
          </MenuItem>
        ))}
      </TextField>
      <Autosuggest
        value={knownIssueFormData.affectedProduct ?? ""}
        onChange={onProductChange}
        suggestions={productsSuggestions}
      />
      <TextField
        name="title"
        label="Title"
        variant="outlined"
        value={knownIssueFormData.title ?? ""}
        onChange={onFormInputChange}
        margin="normal"
        sx={{ mb: 3 }}
      />

      <TextField
        name="outageDescription"
        label="Issue description"
        multiline
        rows="4"
        variant="outlined"
        value={knownIssueFormData.outageDescription ?? ""}
        onChange={onFormInputChange}
        sx={{ mb: 1 }}
      />
      <DateTimePicker
        label="Start date"
        renderInput={(props) => <TextField label="Start Date" name="dateTime" margin="normal" {...props} />}
        onChange={onDateChange}
        value={dateTimeView}
        disableFuture
        components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
      />
      <FormControl margin="normal" component="fieldset">
        <FormLabel style={{ fontSize: "0.8rem" }} component="label">
          Issue status
        </FormLabel>
        <RadioGroup
          aria-label="status"
          name="status"
          color="primary"
          value={knownIssueFormData.status}
          onChange={onFormInputChange}
          row
        >
          <FormControlLabel value={KnownIssueStatus.Ongoing} control={<Radio color="primary" />} label="Ongoing" />
          <FormControlLabel value={KnownIssueStatus.Archived} control={<Radio color="primary" />} label="Archived" />
        </RadioGroup>
      </FormControl>
    </DialogContent>
  );
};
