import { type MouseEventHandler } from "react";

import AddBox from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export type AddKnownIssueButtonProps = {
  onClick: MouseEventHandler;
};

export const AddKnownIssueButton = ({ onClick }: AddKnownIssueButtonProps) => (
  <Tooltip title="Add Issue">
    <IconButton onClick={onClick} aria-label="Add Issue" size="large">
      <AddBox />
    </IconButton>
  </Tooltip>
);
