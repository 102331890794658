import { useCallback, useMemo } from "react";

import { type GSuiteAssetModel } from "@doitintl/cmp-models";

import { CatalogHooks } from "../../../../../Components/Catalog/Catalog.context";
import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../../Components/FilterTable/FilterTableSkeleton";
import { type Entity } from "../../../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type AnyAsset, type Contract, type Contracts } from "../../../../../types";
import { mapEntitiesByKey } from "../../../GCTab/utils";
import { GSuiteAssetColumns, GSuiteAssetHeaders } from "./GSuiteAssetColumns";
import GSuiteAssetRow, { buildGSuiteAssetRowData, type GsuiteAssetRowData } from "./GSuiteAssetRow";

type GSuiteAssetTableProps = {
  assets: AnyAsset<GSuiteAssetModel>[];
  loading: boolean;
  contracts: Readonly<Contracts> | undefined;
  onRemoveAsset: (asset: AnyAsset<GSuiteAssetModel>) => void;
};

export const cyIds = {
  table: "gsuiteAssetTable",
};

const GSuiteAssetTable = ({ assets, loading, contracts, onRemoveAsset }: GSuiteAssetTableProps) => {
  const [catalog, gsuiteCatalogLoading] = CatalogHooks.useGSuiteCatalogContext();
  const { entities } = useCustomerContext();
  const entitiesByKey = useMemo<Record<string, Entity>>(() => mapEntitiesByKey(entities), [entities]);
  const items = useMemo<GsuiteAssetRowData[]>(
    () => assets.map((asset) => buildGSuiteAssetRowData(asset, entitiesByKey)),
    [assets, entitiesByKey]
  );

  const getContract = useCallback(
    (asset: AnyAsset<GSuiteAssetModel>): Contract | undefined =>
      contracts ? contracts.find((c) => asset.data.contract?.id === c.id) : contracts,
    [contracts]
  );

  const rowComponent = useCallback(
    ({ row }) => (
      <GSuiteAssetRow
        data={row}
        contract={getContract(row.asset)}
        catalog={catalog ?? []}
        onRemoveAsset={onRemoveAsset}
      />
    ),
    [catalog, getContract, onRemoveAsset]
  );

  return loading || gsuiteCatalogLoading ? (
    <FilterTableSkeleton />
  ) : (
    <FilterTable
      tableItems={items}
      rowComponent={rowComponent}
      headerColumns={GSuiteAssetHeaders}
      filterColumns={GSuiteAssetColumns}
      persistenceKey="GSuiteAssetTable"
      data-cy={cyIds.table}
    />
  );
};

export default GSuiteAssetTable;
