import { type CloudSelectorType, SaaSConsoleType } from "@doitintl/cmp-models";
import { useTheme } from "@mui/material/styles";

import AWSLogo from "../../../../../assets/aws-logo.svg";
import AWSLogoDark from "../../../../../assets/aws-logo-dark-mode.svg";
import AzureLogo from "../../../../../assets/azure-logo.svg";
import GCPLogo from "../../../../../assets/gcp-logo.svg";

type Props = {
  type: CloudSelectorType;
  size: number;
};

export const CloudLogo = ({ type, size }: Props) => {
  const theme = useTheme();

  let logo = GCPLogo;
  if (type === SaaSConsoleType.AWS) {
    logo = theme.palette.mode === "dark" ? AWSLogoDark : AWSLogo;
  } else if (type === SaaSConsoleType.AZURE) {
    logo = AzureLogo;
  }

  return <img src={logo} width={size} alt={type} />;
};
