import { Fragment, type JSX } from "react";

import AckDialog from "../../Dialogs/AckDialog";

type RemoveDialogProps = {
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
  name: string | JSX.Element;
};

export const RemoveDialog = ({ open, onCancel, onAccept, name }: RemoveDialogProps) => (
  <AckDialog
    open={open}
    onCancel={onCancel}
    onAccept={onAccept}
    title="Remove asset"
    text={
      <Fragment>
        Are you sure you want to remove <b>{name}</b>?
      </Fragment>
    }
    acceptText="Yes"
  />
);
