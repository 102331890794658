import { type JSX, useCallback } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  Checkbox,
  TextField,
  type TextFieldProps,
  Typography,
} from "@mui/material";

type Props = {
  label: string;
  options: string[];
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  textFieldProps?: TextFieldProps;
  handleRenderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
  handleRenderTags?: (value: string[]) => JSX.Element;
  handleRenderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: string, { selected }) => JSX.Element;
  "data-cy"?: string;
  sx?: Record<string, unknown>;
};

const handleRenderTags = (value: string[]) => (
  <Typography sx={{ flexShrink: 0 }} pl={1}>
    {value[0] + (value.length > 1 ? ` +${value.length - 1}` : "")}
  </Typography>
);

const handleRenderOption = (dataCy: string) => {
  const handleRenderOptionFunc = (props: React.HTMLAttributes<HTMLLIElement>, option: string, { selected }) => (
    <li {...props} key={option}>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        checked={selected}
        data-cy={`${dataCy}-option-${option.toLowerCase().replace(" ", "-")}`}
      />
      {option}
    </li>
  );

  return handleRenderOptionFunc;
};

const handleRenderInput = (props: Props) => {
  const handleRenderInputFunc = (params: AutocompleteRenderInputParams) => (
    <TextField {...params} variant="outlined" label={props.label} fullWidth {...props.textFieldProps} />
  );
  return handleRenderInputFunc;
};

export const MultiSelect = (props: Props) => {
  const handleChange = useCallback(
    (_, value: string[]) => {
      props.onChange(value);
    },
    [props]
  );

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      onChange={handleChange}
      options={props.options}
      value={props.selectedOptions}
      renderTags={props.handleRenderTags ?? handleRenderTags}
      renderOption={props.handleRenderOption ?? handleRenderOption(props["data-cy"] ?? "")}
      renderInput={props.handleRenderInput ?? handleRenderInput(props)}
      data-cy={props["data-cy"]}
      sx={{
        ...props.sx,
        ".MuiAutocomplete-inputRoot": { flexWrap: "nowrap", textOverflow: "ellipsis" },
      }}
    />
  );
};
