import { useState } from "react";

import { useHistory } from "react-router-dom";
import { DoitRole, TemplateVisibility } from "@doitintl/cmp-models";
import { Box, Tooltip } from "@mui/material";
import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../../../api/context";
import { templateCreateText, templateLibraryTxt } from "../../../../../assets/texts/CloudAnalytics";
import useReportSaver from "../../../../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import LoadingButton from "../../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { useUnsavedChanges } from "../../../../../Context/UnsavedChangesContext";
import { consoleErrorWithSentry } from "../../../../../utils";
import { useCreateTemplateContext, useReportSaverContext, useReportTemplateContext } from "../../../Context";
import { submitTemplateForApproval, updateReportTemplate } from "../../../templateLibrary/api";
import { type TemplateWithLastVersionFromBackend } from "../../../templateLibrary/types";
import { getErrorMessage } from "../../utils";

type Props = {
  disabled: boolean;
  tooltip: string;
};

const SubmitForApproval = ({ disabled, tooltip }: Props) => {
  const history = useHistory();
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { createTemplateData } = useCreateTemplateContext();
  const { dispatchReportSaver } = useReportSaverContext();
  const { prepareConfigFromDimension } = useReportSaver();
  const { clearPendingPrompt } = useUnsavedChanges();
  const { reportTemplateId, dispatchReportTemplates } = useReportTemplateContext();
  const isTemplateLibraryAdmin = useDoitRoleCheck(DoitRole.TemplateLibraryAdmin);
  const [loading, setLoading] = useState(false);
  const isPrivateTemplateOrUserAdmin =
    createTemplateData.visibility === TemplateVisibility.PRIVATE || isTemplateLibraryAdmin;

  const onClick = async () => {
    setLoading(true);
    try {
      const payload = {
        name: createTemplateData.name,
        description: createTemplateData.description,
        visibility: createTemplateData.visibility,
        categories: createTemplateData.categories.flatMap((c) => (c.selected ? c.label : [])),
        cloud: createTemplateData.clouds.flatMap((c) => (c.selected ? c.key : [])),
        config: prepareConfigFromDimension(),
      };
      let response: AxiosResponse<TemplateWithLastVersionFromBackend>;

      if (reportTemplateId) {
        response = await updateReportTemplate(api, reportTemplateId, payload);
        dispatchReportTemplates({ type: "UPDATE_REPORT_TEMPLATE", payload: { reportTemplateItem: response.data } });
      } else {
        response = await submitTemplateForApproval(api, payload);
        dispatchReportTemplates({ type: "ADD_REPORT_TEMPLATE", payload: { reportTemplateItem: response.data } });
      }
      clearPendingPrompt();
      dispatchReportSaver({ payload: { hasUnsavedChanges: false } });
      successSnackbar(
        isPrivateTemplateOrUserAdmin
          ? templateLibraryTxt.TEMPLATE_SAVED_SUCCESSFULLY
          : templateLibraryTxt.TEMPLATE_SUBMITTED_FOR_APPROVAL
      );
      history.push(`/customers/${customer.id}/analytics/report-templates`);
    } catch (error) {
      const errMsg = getErrorMessage(error);
      const errPrefix = isPrivateTemplateOrUserAdmin
        ? templateLibraryTxt.TEMPLATE_SAVE_ERROR
        : templateLibraryTxt.TEMPLATE_SUBMIT_ERROR;
      errorSnackbar(errMsg === "" ? errPrefix : `${errPrefix}: ${errMsg}`);

      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip title={tooltip}>
      <Box>
        <LoadingButton
          data-cy="submit-template-for-approval"
          disabled={disabled}
          loading={loading}
          onClick={onClick}
          variant="contained"
          mixpanelEventId="analytics.template.submit"
        >
          {isPrivateTemplateOrUserAdmin ? templateCreateText.SAVE_TEMPLATE : templateCreateText.SUBMIT_FOR_APPROVAL}
        </LoadingButton>
      </Box>
    </Tooltip>
  );
};

export default SubmitForApproval;
