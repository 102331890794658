import { firestoreTimestampFilterProps } from "../../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import { assetTypeName } from "../../../../utils/common";
import { type Job } from "./types";

const itemsDescriptions: FilterTableItemDescriptor<Job>[] = [
  {
    headerText: "Customer",
    value: "customerName",
    filterType: "string",
  },
  {
    headerText: "Action",
    value: "action",
    filterType: "string",
  },
  {
    headerText: "Cloud",
    value: "type",
    filterType: "string",
    filterOptions: {
      toOption: (value) => ({
        value,
        label: assetTypeName(value),
      }),
    },
  },
  {
    headerText: "Invoice month",
    value: "invoiceMonth",
    filterType: "string",
  },

  {
    headerText: "Reason",
    value: "reason",
    filterType: "string",
  },
  {
    headerText: "Email",
    value: "email",
    filterType: "string",
  },
  {
    headerText: "Cloud task ID",
    value: "cloudTaskID",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterType: "string",
  },
  {
    headerText: "Comment",
    value: "comment",
    filterType: "string",
  },
  {
    headerText: "Created at",
    value: "createdAt",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    headerText: "Updated at",
    value: "updatedAt",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
