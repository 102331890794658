import { type FirestoreTimestamp } from "../../utils/firebase";

export enum SpotScalingMode {
  Recommend = "recommended",
  Autopilot = "managed",
}

export enum OptimizationStatus {
  Optimized = "Optimized",
  NewRecommendations = "New recommendations",
  NotOptimized = "Not optimized",
  Error = "Error",
}

export enum OptimizationStatusColor {
  Optimized = "success",
  NewRecommendations = "info",
  NotOptimized = "warning",
  Error = "error",
}

export enum SavingsTimeFilter {
  CurrentMonth = "Current Month",
  LastMonth = "Last Month",
  LastThreeMonths = "Last 3 Months",
  AllTime = "All Time",
}

export type AsgBasicInfo = {
  scope?: string;
  accountId: string;
  region: string;
  asgName: string;
};

export type InstanceTypeDetails = {
  compute: number;
  memory: number;
};

export type SubnetDetails = {
  availabilityZone: string;
  cidr: string;
  isDefault: boolean;
};

export type MonthlyAsgUsage = {
  onDemandInstances: UsageInstancesDetails;
  spotInstances: UsageInstancesDetails;
  totalSavings: number;
};

export type UsageInstancesDetails = AsgInstancesUsage & {
  instances?: unknown;
};

export type AsgInstancesUsage = {
  totalCost: number;
  totalHours: number;
};

export type AsgItem = AsgBasicInfo & {
  id: string;
  accountName: string;
  config: AsgItemConfig;
  optimizationStatus: OptimizationStatus;
  configurations: {
    current: AsgConfigurationSummary;
    recommended?: AsgConfigurationSummary;
  };
  costsSavingsHistory: Record<string, AsgActualAndPotentialCosts>; // holds costs and savings for current and all previous months
  costsSavingsHistoryByTimeFilter: Record<string, number>;
  odSpendingHistory: Record<string, AsgInstancesUsage>;
  odSpendingByTimeFilter: Record<string, number>;
  spotSpendingHistory: Record<string, AsgInstancesUsage>;
  spotSpendingByTimeFilter: Record<string, number>;
  isApplyChangesSupported: boolean;
  spotisizeError?: string;
  updateError?: string;
  lastMonthUsage?: MonthlyAsgUsage;
  currentMonthUsage?: MonthlyAsgUsage;
  recommendationMonthlySaving: number; // amount of savings that recommendation can add
  currentMonthActualSavingsDays: number;
  timeStartedUsingSpots?: Date;
  timeModified: FirestoreTimestamp;
};

export type AsgTableItem = {
  id: string;
  asgName: string;
  optimizationStatus: string;
  optimisationStatusColor: OptimizationStatusColor;
  desiredCapacity: number;
  minCapacity: number;
  maxCapacity: number;
  desiredMinMax: string;
  onDemandRatio: number;
  spotRatio: number;
  baseCapacity: number;
  onDemandSpotBaseCapacity: string;
  region: string;
  accountName: string;
  accountId: string;
  spotSpending: number;
  odSpending: number;
  savings: number;
  asgItem: AsgItem;
};
export type AsgCapacityDetails = {
  desiredCapacity: number;
  minCapacity: number;
  maxCapacity: number;
};

type AsgConfigurationBase = {
  onDemandBaseCapacity: number;
  onDemandPercentageAboveBaseCapacity: number;
  excludedInstanceTypes?: string[];
  excludedSubnets?: string[];
};

export type AsgConfigChange = AsgConfigurationBase & {
  includedInstanceTypes: string[];
  includedSubnets: string[];
};

export type AsgConfigurationSummary = AsgCapacityDetails &
  AsgConfigurationBase & {
    instanceTypes: string[];
    instanceTypesDetails: Record<string, InstanceTypeDetails>;
    subnets: string[];
    subnetsDetails: Record<string, SubnetDetails>;
    averageDesiredCapacity: number;
    averageOnDemandCapacity: number; // holds the average capacity of the on-demand instances only
    instancesCosts: AsgInstancesCosts;
    isMixedTypesInstances: boolean;
    isLaunchConfiguration: boolean;
    monthlyCost: number;
    dailyCost: number;
    monthlyCostIFusingOnlyOnDemand: number; // will allow to calculate the savings of spot vs on-demand
    dailyCostIfUsingOnlyOnDemand: number;
    tags: AsgTag[];
  };

export type AsgActualAndPotentialCosts = {
  costIfUsingOnlyOnDemand: number;
  actualCost: number;
};

export type AsgItemConfig = {
  fallbackOnDemand: boolean;
  keepUpToDate: boolean;
  excludedInstanceTypes?: string[];
  excludedAvailabilityZones?: string[];
  excludedSubnets?: string[];
};

export type AsgAdditionalDetails = {
  allOnDemand: boolean;
  instances: string[];
};

export type AsgInstancesCosts = {
  spotHourCost: number;
  onDemandHourCost: number;
};

export type AsgTag = {
  Key: string;
  Value: string;
  PropagateAtLaunch?: boolean;
};

export type KeepUptoDate = {
  valueDirty: boolean;
  value: boolean;
  origValue: boolean;
};

export enum AsgTagKeys {
  AutoEnroll = "doitintl:spotscaling:auto-enroll",
  FBOD = "doitintl:fallback-to-on-demand",
}

export type SpendingSummary = {
  totalSpotSpending: number;
  totalSpotHours: number;
  totalOdSpending: number;
  totalOdHours: number;
};
