import { useCallback, useState } from "react";

import { type CategoryContext } from "../../types";
import MobileMenuWrapper from "./MobileMenuWrapper";

export const useMobileMenu = (
  categories: CategoryContext[],
  settings: CategoryContext[],
  showOptions: boolean,
  hideWhatsNewOption: boolean
) => {
  const [selectedCategory, setSelectedCategory] = useState<null | CategoryContext>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    setSelectedCategory(null);
  }, []);
  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
    setSelectedCategory(null);
  }, []);
  const openSubMenu = useCallback((category: CategoryContext) => {
    setSelectedCategory(category);
  }, []);

  return {
    openMenu,
    menu: (
      <MobileMenuWrapper
        categories={categories}
        settings={settings}
        closeMenu={closeMenu}
        openMenu={openMenu}
        openSubMenu={openSubMenu}
        isSubMenuOpen={!!selectedCategory}
        isMenuOpen={isMenuOpen}
        selectedCategory={selectedCategory}
        showOptions={showOptions}
        hideWhatsNewOption={hideWhatsNewOption}
      />
    ),
  };
};
