import { useState } from "react";

import { Button } from "@mui/material";
import type { CustomerFeedback } from "@doitintl/cmp-models";

import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import AnomalyFeedbackDialog from "./AnomalyFeedbackDialog";

type Props = {
  customerFeedback?: CustomerFeedback;
};

export const AnomalyFeedbackButton = ({ customerFeedback }: Props) => {
  const [open, setOpen] = useState(false);
  const isEntitledAcknowledgement = useIsFeatureEntitled("governance:costAnomalies:acknowledgement");

  if (!isEntitledAcknowledgement) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
      >
        Acknowledge anomaly
      </Button>
      <AnomalyFeedbackDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        customerFeedback={customerFeedback}
      />
    </>
  );
};
