import { useCallback } from "react";

import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";

export function useMPAActivationSnackbar() {
  const { onOpen } = useSnackbar();

  const showActivationSuccessfulSnackbar = useCallback(() => {
    onOpen({
      message: "MPA successfully activated",
      variant: "success",
      autoHideDuration: 5000,
      withClose: true,
    });
  }, [onOpen]);
  const showActivationFailedSnackbar = useCallback(() => {
    onOpen({
      message: "Failed to activate MPA",
      variant: "error",
      autoHideDuration: 5000,
      withClose: true,
    });
  }, [onOpen]);

  return {
    showActivationSuccessfulSnackbar,
    showActivationFailedSnackbar,
  };
}
