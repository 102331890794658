import { type ReactNode } from "react";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";

type Props = {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  onConfirm: () => void;
  disabled?: boolean;
  buttonText?: string;
  children: ReactNode;
};

export const PopoverDialog = ({ anchorEl, onClose, onConfirm, children, disabled, buttonText }: Props) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
  >
    {children}
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={onConfirm}
      disabled={disabled}
      fullWidth
      sx={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
    >
      {buttonText ?? "Update"}
    </Button>
  </Popover>
);

export default PopoverDialog;
