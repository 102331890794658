import { type TemplateVisibility } from "@doitintl/cmp-models";
import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";

import { type VisibilityLabel } from "./utils";

type VisibilityRadioBtnProps = {
  value: TemplateVisibility;
  description: string;
  label: VisibilityLabel;
};

const VisibilityRadioBtn = ({ value, description, label }: VisibilityRadioBtnProps) => (
  <FormControlLabel
    sx={{
      mb: 1,
    }}
    value={value}
    control={<Radio />}
    label={
      <Stack>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Stack>
    }
  />
);

export default VisibilityRadioBtn;
