import { type JSX, useState } from "react";

import { DashboardType } from "@doitintl/cmp-models";
import { Grid } from "@mui/material";

import { useCurrentDashboardContext } from "../../Context/CurrentDashboardContext";
import GkeUsageAlert from "../Alerts/GkeUsageAlert";
import BigQueryLensGuard from "./BigQueryLens/BigQueryLensGuard";
import { isHomeDashboard } from "./DashboardNavigartionContext";
import { EKSLensGuard } from "./EKSLens/EKSLensGuard";
import GKELensGuard from "./GKELens/GKELensGuard";
import { QuickLinkList } from "./QuickLinks/QuickLinkList";
import { SnowflakeLensGuard } from "./SnowflakeLens/SnowflakeLensGuard";

type Props = {
  children: JSX.Element;
};

export function DashboardGuard({ children }: Props) {
  const { dashboardType, currentDashboard } = useCurrentDashboardContext();
  const [noBQUsage] = useState(false);

  switch (true) {
    case dashboardType === DashboardType.EKSLens:
      return <EKSLensGuard>{children}</EKSLensGuard>;
    case dashboardType === DashboardType.SnowflakeLens:
      return <SnowflakeLensGuard>{children}</SnowflakeLensGuard>;
    case dashboardType === DashboardType.GkeLensV2:
      return <>{children && <GKELensGuard>{children}</GKELensGuard>}</>;
    case dashboardType === DashboardType.BqLens:
      return <>{children && <BigQueryLensGuard noBQUsage={noBQUsage} widgets={children} />}</>;
    case dashboardType === DashboardType.GcpLens:
      return (
        <>
          <GkeUsageAlert />
          {children}
        </>
      );
    case isHomeDashboard(currentDashboard?.name):
      return (
        <>
          <Grid item ml={-1} mb={1}>
            <QuickLinkList />
          </Grid>
          {children}
        </>
      );
    default:
      return <>{children}</>;
  }
}
