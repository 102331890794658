import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.core.css";
import "./comments.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AttachmentIcon from "@mui/icons-material/AttachmentRounded";
import { Divider, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { orange, purple } from "@mui/material/colors";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import has from "lodash/has";
import map from "lodash/map";
import toUpper from "lodash/toUpper";
import { DateTime } from "luxon";

import { AvaIcon } from "../../Components/Ava/Messenger/AvaIcon";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { SafeHtml } from "../../Components/SafeHtml";
import { avaAgentIdDev, avaAgentIdProd } from "../../constants";
import { ThemeModes } from "../../muiThemeTypes";
import { type Author, type Comment } from "../types";
import { addTheme, removeTheme } from "./Editor/Editor";

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
  },
  internalComment: {
    borderLeftStyle: "solid",
    borderLeftWidth: 4,
    borderLeftColor: purple[500],
  },
  header: {
    padding: theme.spacing(2),
  },
  actions: {
    display: "flex",
    padding: theme.spacing(2),
  },
  content: {
    overflow: "auto",
    padding: theme.spacing(1, 3, 3),
  },
  avatar: {
    backgroundColor: "transparent",
  },
  avaAvatar: {
    backgroundColor: "#f4f4f4",
  },
  avatarInitials: {
    backgroundColor: orange[500],
  },
  rtl: {
    direction: "rtl",
  },
  imgClass: {
    maxWidth: "100%",
  },
  newComment: {
    animation: `$myEffect 8s ${theme.transitions.easing.easeOut}`,
    animationDelay: "0.1s",
  },
  highlight: {
    transition: theme.transitions.create(["background", "opacity"], {
      duration: theme.transitions.duration.complex,
    }),
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      backgroundColor: "#fff9b09e",
    },
    "10%": {
      opacity: 0.9,
    },
    "40%": {
      opacity: 1,
      backgroundColor: "#fff9b09e",
    },
    "100%": {
      opacity: 1,
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

type Props = {
  author?: Author;
  comment: Comment;
  newComment: number;
  commentsCount: boolean;
};

const CommentCard = ({ author, comment, newComment, commentsCount }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = useDarkThemeCheck();
  const created = DateTime.fromISO(comment.created_at);
  const [isNewCommentCard, setIsNewCommentCard] = useState(false);

  const isAvaAgent = useMemo(() => !!author && [avaAgentIdDev, avaAgentIdProd].includes(author.id), [author]);

  const avatar = useMemo(() => {
    if (!author) {
      return null;
    }

    if (isAvaAgent) {
      return <AvaIcon />;
    }

    if (author.photo.content_url) {
      return (
        <Avatar
          aria-label="Avatar"
          alt=""
          src={
            author.photo ? author.photo.content_url : "https://cdn-images-1.medium.com/max/1600/0*WK_vAxJo4O7Kdq3j.png"
          }
          className={classes.avatar}
        />
      );
    } else {
      const initialsArray = author?.name.match(/\b\w/g) ?? [];
      const initials = initialsArray ? toUpper((initialsArray.shift() || "") + (initialsArray.pop() || "")) : "";
      return (
        <Avatar aria-label="Avatar" alt={initials} className={classes.avatarInitials}>
          {initials}
        </Avatar>
      );
    }
  }, [author, classes.avatar, classes.avatarInitials, isAvaAgent]);

  const authorName = useMemo(() => {
    if (!author) {
      return "";
    }

    if (isAvaAgent) {
      return "Ava (AI assistant)";
    }

    return author.name;
  }, [author, isAvaAgent]);

  const authorSuffix = useMemo(() => {
    if (isAvaAgent || comment.public) {
      return "";
    }

    return "(Internal Note)";
  }, [comment.public, isAvaAgent]);

  const prevCount = useRef(commentsCount);
  useEffect(() => {
    if (commentsCount > prevCount.current && newComment === 0) {
      setIsNewCommentCard(true);
      const t = setTimeout(() => {
        setIsNewCommentCard(false);
      }, 8000);

      return () => {
        clearTimeout(t);
      };
    }
  }, [author?.role, comment, commentsCount, newComment]);

  useEffect(() => {
    if (theme.palette.mode === ThemeModes.DARK) {
      addTheme("dark-mode");
    } else {
      removeTheme("dark-mode");
    }
  }, [theme.palette.mode]);

  const transform = useCallback(
    (node: Element, data) => {
      if (data.tagName === "a") {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener");
        node.setAttribute("style", "color: inherit;");
      }

      if (node.getAttribute) {
        const styleString = node.getAttribute("style");
        if (styleString) {
          // all "color" styles revert to theme
          // 4-25 allows for "rgba(255, 255, 255, 0.9)"
          const newStyle = styleString.replaceAll(/color: [^;]{4,25}/g, "color: inherit");
          node.setAttribute("style", newStyle);
        }
        const colorString = node.getAttribute("color");
        if (isDarkMode && colorString) {
          // <font> tags
          node.setAttribute("color", "inherit");
        }
      }
      return node;
    },
    [isDarkMode]
  );

  const htmlContent = useMemo(
    () => <SafeHtml html={comment.html_body} transform={transform} />,
    [comment.html_body, transform]
  );

  return (
    <Card
      className={clsx(classes.card, {
        [classes.internalComment]: !comment.public,
        [classes.highlight]: true,
        [classes.newComment]: isNewCommentCard,
      })}
    >
      <CardHeader
        avatar={avatar}
        title={[authorName, authorSuffix].filter(Boolean).join(" ")}
        subheader={created.toLocaleString(DateTime.DATETIME_MED)}
        className={classes.header}
      />
      <CardContent className={classes.content}>
        <div>{htmlContent}</div>
      </CardContent>

      <Divider />

      {has(comment, "attachments") && comment.attachments.length > 0 && (
        <CardActions className={classes.actions}>
          {map(comment.attachments, (attachment) => (
            <Tooltip title={attachment.file_name} key={attachment.id}>
              <Button
                color="primary"
                component={Link}
                href={attachment.content_url}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                size="small"
                startIcon={<AttachmentIcon />}
              >
                {attachment.file_name}
              </Button>
            </Tooltip>
          ))}
        </CardActions>
      )}
    </Card>
  );
};

export default CommentCard;
