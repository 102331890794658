import { useHistory } from "react-router-dom";
import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Card, CardContent, Grid, Typography } from "@mui/material";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../Navigation/core/utils";
import mixpanel from "../../../utils/mixpanel";
import { CloudBoxContent } from "../../Customer/SaaSConsoleOnboarding/Common/CloudSelector/CloudBoxContent";

export const StandaloneCloudSelector = () => {
  const history = useHistory();
  const customerId = useCustomerId();

  const renderCard = (type: SaaSConsoleType) => (
    <Card
      sx={(theme) => ({
        cursor: "pointer",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
      })}
      onClick={() => {
        mixpanel.track("flexsaveSA.cloudselected", { type });
        history.push(customersRoute(customerId, `flexsave-${type.toLowerCase()}`));
      }}
    >
      <CardContent>
        <CloudBoxContent type={type} />
      </CardContent>
    </Card>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3" fontWeight="500" pb={1}>
          Select a cloud provider for your first Flexsave estimate
        </Typography>
        <Typography color="text.secondary">
          You will be able to complete the estimate for the other cloud provider later
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {renderCard(SaaSConsoleType.AWS)}
      </Grid>
      <Grid item xs={6}>
        {renderCard(SaaSConsoleType.GCP)}
      </Grid>
    </Grid>
  );
};
