import { createContext, type ReactNode, useContext, useState } from "react";

type FilterContextType = {
  isFilterActive: boolean;
  setIsFilterActive: (active: boolean) => void;
  isFiltersExpanded: boolean;
  setIsFiltersExpanded: (expanded: boolean) => void;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);

  return (
    <FilterContext.Provider value={{ isFilterActive, setIsFilterActive, isFiltersExpanded, setIsFiltersExpanded }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};
