import { useCallback, useMemo, useState } from "react";

import { type CommonHeights } from "./AsgConfiguration";
import { DEFAULT_HEIGHT, PADDING } from "./consts";

export function useCommonHeights(): [CommonHeights, (newHeights: CommonHeights) => void] {
  const [commonHeights, setCommonHeights] = useState<CommonHeights>({
    current: DEFAULT_HEIGHT,
    recommended: DEFAULT_HEIGHT,
  });

  return useMemo(
    () => [
      commonHeights,
      (newHeights) => {
        setCommonHeights(newHeights);
      },
    ],
    [commonHeights]
  );
}
const getOffsetHeight = (ref) => ref.current.offsetHeight;

export const useCalculateCommonHeights = (
  ref,
  confKey: string,
  commonHeights: CommonHeights,
  setCommonHeights: (CommonHeights) => void
) =>
  useCallback(() => {
    const height = getOffsetHeight(ref);
    if (commonHeights[confKey] !== height) {
      const newHeights: CommonHeights = {
        current: commonHeights.current,
        recommended: commonHeights.recommended,
      };
      newHeights[confKey] = height;
      setCommonHeights(newHeights);
    }
  }, [ref, confKey, commonHeights, setCommonHeights]);

export const useMaxHeight = (commonHeights: CommonHeights) =>
  useMemo(() => Math.max(commonHeights.current, commonHeights.recommended) + 2 * PADDING, [commonHeights]);
