import { type ReactElement } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import breakdownChartImg from "../../../../assets/bqEmptyState/breakdown-insights.svg";
import breakdownChartDMImg from "../../../../assets/bqEmptyState/breakdown-insights-dm.svg";
import recommendationsChartImg from "../../../../assets/bqEmptyState/recommendations.svg";
import recommendationsChartDMImg from "../../../../assets/bqEmptyState/recommendations-dm.svg";
import spendChartImg from "../../../../assets/bqEmptyState/spend.svg";
import spendChartDMImg from "../../../../assets/bqEmptyState/spend-dm.svg";
import { bQEmptyStateDashboardTexts } from "./texts";

// Styles per screen size
const chartTitlesStyle = { variant: "body1", fontWeight: 500, mb: 1 };

const nonMdTopChartWidth = (isMobile: boolean): string => (isMobile ? "150px" : "200px");

const topChartsWidth = (isMdScreen: boolean, isMobile: boolean): string =>
  isMdScreen ? "300px" : nonMdTopChartWidth(isMobile);

const nonMdBottomChartWidth = (isMobile: boolean): string => (isMobile ? "340px" : "440px");

const bottomChartWidth = (isMdScreen: boolean, isMobile: boolean): string =>
  isMdScreen ? "660px" : nonMdBottomChartWidth(isMobile);

const nonMdTopChartsBoxMaxWidth = (isMobile: boolean): string => (isMobile ? "380px" : "440px");

// main component
const ChartsArea = (): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery(theme.breakpoints.only("md"));

  const recommendationsChart: ReactElement = (
    <Box data-cy="bqEmptyStateDashboard_recommendationsChart">
      <Typography sx={chartTitlesStyle}>{bQEmptyStateDashboardTexts.RECOMMENDATIONS_TITLE}</Typography>
      <img
        src={theme.palette.mode === "dark" ? recommendationsChartDMImg : recommendationsChartImg}
        alt="big-query-recommendations-chart"
        style={{ width: topChartsWidth(isMdScreen, isMobile), maxWidth: topChartsWidth(isMdScreen, isMobile) }}
      />
    </Box>
  );

  const spendAndUsageChart: ReactElement = (
    <Box data-cy="bqEmptyStateDashboard_spendAndUsageChart">
      <Typography sx={chartTitlesStyle}>{bQEmptyStateDashboardTexts.SPEND_AND_USAGE_INSIGHTS_TITLE}</Typography>
      <img
        src={theme.palette.mode === "dark" ? spendChartDMImg : spendChartImg}
        alt="spend-chart"
        style={{ width: topChartsWidth(isMdScreen, isMobile), maxWidth: topChartsWidth(isMdScreen, isMobile) }}
      />
    </Box>
  );
  const breakdownChart: ReactElement = (
    <Box data-cy="bqEmptyStateDashboard_breakdownChart">
      <Typography sx={chartTitlesStyle}>{bQEmptyStateDashboardTexts.BREAKDOWN_INSIGHTS_TITLE}</Typography>
      <img
        src={theme.palette.mode === "dark" ? breakdownChartDMImg : breakdownChartImg}
        alt="breakdown-chart"
        style={{ width: bottomChartWidth(isMdScreen, isMobile), maxWidth: bottomChartWidth(isMdScreen, isMobile) }}
      />
    </Box>
  );

  return (
    <Box data-cy="bqEmptyStateDashboard_chartsArea">
      <Box
        data-cy="bqEmptyStateDashboard_topCharts"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pr: 0,
          maxWidth: isMdScreen ? "660px" : nonMdTopChartsBoxMaxWidth(isMobile),
        }}
      >
        {recommendationsChart}
        {spendAndUsageChart}
      </Box>
      <Box mt={7}>{breakdownChart}</Box>
    </Box>
  );
};
export default ChartsArea;
