import { type Metadata } from "@doitintl/cmp-models";

export const DEFAULT_SPLIT_MODE = "even";
export type SplitMode = "even" | "custom" | "proportional";

export type SplitTarget = {
  id: string;
  label: string;
  value: string;
  checked: boolean;
  disabled?: boolean;
  isNullFallback?: boolean;
};

export type Split = {
  id: string;
  origin?: SplitTarget;
  mode: SplitMode;
  targets: SplitTarget[];
  type: Metadata | "";
  includeOrigin: boolean;
  nullFallbackLabel: string | null;
};

export type SplitForServer = Omit<Split, "origin" | "targets"> & {
  origin?: string;
  targets: { id: string; value: number }[];
};

export type ItemToSplitProps = {
  options: SplitTarget[];
  isOptionDisabled: (target: SplitTarget) => boolean;
  origin?: SplitTarget | null;
  onSelectOrigin: (target?: SplitTarget) => void;
};

export type SplitCostTargetsProps = {
  origin?: SplitTarget | null;
  splitData: Split | undefined;
  onCheckedTarget: (label: string) => void;
  checkAll: (checked: boolean) => void;
  splitMode: SplitMode | undefined;
  onSplitModeChanged: (mode: SplitMode) => void;
  onChangePercentage: (id: string, value: string) => void;
};

export type SplitOption = { title: string; desc: string; mode: SplitMode };
