import { useState } from "react";

import { Button } from "@mui/material";

import { ConnectTeamsChannelDialog } from "../../../Integrations/MsTeams/ConnectTeamsChannelDialog";

export const ConnectMSTeamsButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        sx={{ width: "fit-content" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Connect channel
      </Button>
      <ConnectTeamsChannelDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
