import { useEffect, useState } from "react";

import { getModelByPath, isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import {
  type BooleanApiServiceModelDescriptor,
  type FloatApiServiceModelDescriptor,
  type IntegerApiServiceModelDescriptor,
  type ListApiServiceModelDescriptor,
  type MapApiServiceModelDescriptor,
  type Member,
  ModelType,
  type StringApiServiceModelDescriptor,
  type StructureApiServiceModelDescriptor,
  type TimestampApiServiceModelDescriptor,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import * as yup from "yup";

import {
  type NodeWitOutputModel,
  useReferencedFieldContext,
} from "./parameters/wrappers/ReferencedField/useReferencedFieldContext";

type SchemaContext = {
  referenceableNodes: NodeWitOutputModel[];
  model: UnwrappedApiServiceModelDescriptor;
};

export function useApiActionParametersSchema(inputModel: UnwrappedApiServiceModelDescriptor) {
  const { referenceableNodes } = useReferencedFieldContext();
  const [validationSchema, setValidationSchema] = useState(
    generateApiActionParametersSchema(inputModel, {
      referenceableNodes,
      model: inputModel,
    })
  );

  useEffect(() => {
    setValidationSchema(
      generateApiActionParametersSchema(inputModel, {
        referenceableNodes,
        model: inputModel,
      })
    );
  }, [inputModel, referenceableNodes]);

  return validationSchema;
}

export type ApiActionParametersSchemaContext = { castingPhase?: "outgoing" };

export type ApiActionParametersSchema = yup.Schema<unknown, ApiActionParametersSchemaContext, any, "" | "d">;

export function getInitialValueForModel(model: UnwrappedApiServiceModelDescriptor) {
  switch (model.type) {
    case ModelType.BOOLEAN:
      return true;
    case ModelType.FLOAT:
    case ModelType.INTEGER:
      return 0;
    case ModelType.LIST:
      return [];
    case ModelType.MAP:
      return [];
    case ModelType.STRING:
      return "";
    case ModelType.STRUCTURE:
      return {};
    case ModelType.TIMESTAMP:
      return ["x", "X", undefined].includes(model.timestampFormat) ? null : "";
    case ModelType.UNION:
      return void 0; // TODO: implement "union" type handlers
  }
}

function isArrayOfStrings(collection: (string | undefined)[]): collection is string[] {
  return Array.isArray(collection) && collection.every((item) => typeof item === "string");
}

function wrapWithReferencedFieldSchema(
  schemaToWrap: ApiActionParametersSchema,
  context: SchemaContext,
  fieldPath?: string
): yup.Lazy<unknown, ApiActionParametersSchemaContext, "" | "d"> {
  return yup.lazy((value) => {
    if (isReferencedNodeValue(value)) {
      const wrappedSchema = yup.object().shape({
        referencedNodeId: yup.string().required(),
        referencedField: yup.array().of(yup.string()).required(),
      });

      if (context.referenceableNodes.length === 0) {
        return wrappedSchema;
      }

      return wrappedSchema.test(
        "referenced-field-type",
        "${path} references an incorrect field type",
        (value, { path, createError }) => {
          const effectiveFieldPath = fieldPath || path;
          const expectedModel = getModelByPath(context.model, effectiveFieldPath.replaceAll(/\[\d+\]/g, "").split("."));
          const referencedNodeModel = context.referenceableNodes.find(
            ({ id }) => id === value.referencedNodeId
          )?.outputModel;
          if (!referencedNodeModel || !isArrayOfStrings(value.referencedField)) {
            return false;
          }
          const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);

          if (referencedModel.type !== expectedModel.type) {
            return createError({
              message: `${schemaToWrap.describe().label} must reference a field with type ${expectedModel.type}`,
            });
          }
          if (
            expectedModel.type === ModelType.LIST &&
            referencedModel.type === ModelType.LIST &&
            expectedModel.member.model.type === referencedModel.member.model.type
          ) {
            return createError({
              message: `${schemaToWrap.describe().label} must reference a field with type ${expectedModel.type} of ${expectedModel.member.model.type}s`,
            });
          }
          return true;
        }
      );
    }
    return schemaToWrap;
  });
}

function wrapWithRequiredSchema(schemaToWrap: ApiActionParametersSchema, model: UnwrappedApiServiceModelDescriptor) {
  return schemaToWrap.required().default(getInitialValueForModel(model));
}

function getStringSchema({
  model,
  label,
  isRequired,
}: {
  model: StringApiServiceModelDescriptor;
  label: string | undefined;
  isRequired?: boolean;
}) {
  let schema: yup.StringSchema = yup.string();
  if (label) {
    schema = schema.label(label);
  }
  if (model.minLength !== undefined) {
    schema = schema.max(model.minLength);
  }
  if (model.maxLength !== undefined) {
    schema = schema.max(model.maxLength);
  }
  if (model.pattern !== undefined) {
    schema = schema.matches(new RegExp(model.pattern));
  }
  if (model.enum !== undefined) {
    schema = schema.oneOf(model.enum);
  }
  if (isRequired) {
    return wrapWithRequiredSchema(schema, model);
  }
  return schema;
}

function getBooleanSchema({
  model,
  label,
  isRequired,
}: {
  model: BooleanApiServiceModelDescriptor;
  label: string | undefined;
  isRequired?: boolean;
}) {
  let schema: yup.BooleanSchema = yup.boolean();
  if (label) {
    schema = schema.label(label);
  }
  if (isRequired) {
    return wrapWithRequiredSchema(schema, model);
  }
  return schema;
}

function getFloatSchema({
  model,
  label,
  isRequired,
}: {
  model: FloatApiServiceModelDescriptor;
  label: string | undefined;
  isRequired?: boolean;
}) {
  let schema: yup.NumberSchema<yup.Maybe<number>> = yup.number().nullable();
  if (label) {
    schema = schema.label(label);
  }
  if (model.min !== undefined) {
    schema = schema.min(model.min);
  }
  if (model.max !== undefined) {
    schema = schema.max(model.max);
  }
  if (isRequired) {
    return wrapWithRequiredSchema(schema, model);
  }
  return schema;
}

function getIntegerSchema({
  model,
  label,
  isRequired,
}: {
  model: IntegerApiServiceModelDescriptor;
  label: string | undefined;
  isRequired?: boolean;
}) {
  let schema: yup.NumberSchema<yup.Maybe<number>> = yup.number().integer().nullable();
  if (label) {
    schema = schema.label(label);
  }
  if (model.min !== undefined) {
    schema = schema.min(model.min);
  }
  if (model.max !== undefined) {
    schema = schema.max(model.max);
  }
  if (isRequired) {
    return wrapWithRequiredSchema(schema, model);
  }
  return schema;
}

function getTimestampSchema({
  model,
  label,
  isRequired,
}: {
  model: TimestampApiServiceModelDescriptor;
  label: string | undefined;
  isRequired?: boolean;
}) {
  let schema: yup.Schema;

  if ([undefined, "X", "x"].includes(model.timestampFormat)) {
    schema = yup.number().integer().nullable();
    if (isRequired) {
      schema = wrapWithRequiredSchema(schema, { type: ModelType.INTEGER });
    }
  } else {
    schema = yup.string().nullable();
    if (isRequired) {
      schema = wrapWithRequiredSchema(schema, { type: ModelType.STRING });
    }
  }

  if (label) {
    schema = schema.label(label);
  }
  return schema;
}

function getListSchema({
  model,
  label,
  context,
  isRequired,
}: {
  model: ListApiServiceModelDescriptor<Member>;
  label: string | undefined;
  context?: SchemaContext;
  isRequired?: boolean;
}) {
  let schema = yup.array().of(generateApiActionParametersSchema(model.member.model, context, model.memberName, false));
  if (label) {
    schema = schema.label(label);
  }
  if (model.min !== undefined) {
    schema = schema.min(model.min);
  }
  if (model.max !== undefined) {
    schema = schema.min(model.max);
  }

  if (isRequired) {
    schema = wrapWithRequiredSchema(schema, model);
  }

  return schema;
}

function getStructureSchema({
  model,
  label,
  context,
  isRequired,
}: {
  model: StructureApiServiceModelDescriptor<Member>;
  label: string | undefined;
  context?: SchemaContext;
  isRequired?: boolean;
}) {
  let schema: yup.ObjectSchema<yup.Maybe<yup.AnyObject>> = yup
    .object(
      Object.fromEntries(
        Object.entries(model.members).map(([memberName, member]) => [
          memberName,
          generateApiActionParametersSchema(
            member.model,
            context,
            memberName,
            model.requiredMembers?.includes(memberName)
          ),
        ])
      )
    )
    .noUnknown();
  if (label) {
    schema = schema.label(label);
  }
  if (isRequired) {
    return wrapWithRequiredSchema(schema, model);
  }
  return schema;
}

function getMapSchema({
  model,
  label,
  context,
  isRequired,
}: {
  model: MapApiServiceModelDescriptor<Member>;
  label: string | undefined;
  context?: SchemaContext;
  isRequired?: boolean;
}) {
  let schema: yup.ArraySchema<any, any, any, any> = yup
    .array()
    .of(
      yup.object({
        [model.keyMemberName]: generateApiActionParametersSchema(
          model.keyMember.model,
          context,
          model.keyMemberName,
          true
        ),
        [model.valueMemberName]: generateApiActionParametersSchema(
          model.valueMember.model,
          context,
          model.valueMemberName,
          true
        ),
      })
    )
    .transform((value) => {
      if (!value) {
        return value;
      }
      if (!Array.isArray(value)) {
        return Object.entries(value).map(([key, value]) => ({
          [model.keyMemberName]: key,
          [model.valueMemberName]: value,
        }));
      }
      return value;
    })
    .when("$castingPhase", ([castingPhase], schema) =>
      castingPhase !== "outgoing"
        ? schema
        : yup
            .object()
            .transform((value) =>
              Object.fromEntries(
                value.map((valueItem) => [valueItem[model.keyMemberName], valueItem[model.valueMemberName]])
              )
            )
    );

  if (model.min !== undefined) {
    schema = schema.min(model.min);
  }
  if (model.max !== undefined) {
    schema = schema.min(model.max);
  }
  if (label) {
    schema = schema.label(label);
  }
  if (isRequired) {
    return wrapWithRequiredSchema(schema, model);
  }

  return schema;
}

export function generateApiActionParametersSchema(model: UnwrappedApiServiceModelDescriptor): ApiActionParametersSchema;
export function generateApiActionParametersSchema(
  model: UnwrappedApiServiceModelDescriptor,
  context?: SchemaContext,
  label?: string,
  isRequired?: boolean,
  fieldPath?: string
): ReturnType<typeof wrapWithReferencedFieldSchema>;
export function generateApiActionParametersSchema(
  model: UnwrappedApiServiceModelDescriptor,
  context?: SchemaContext,
  label?: string,
  isRequired?: boolean,
  fieldPath?: string
): ReturnType<typeof wrapWithReferencedFieldSchema> | ApiActionParametersSchema {
  let schema: ApiActionParametersSchema;

  switch (model.type) {
    case ModelType.STRING: {
      schema = getStringSchema({ model, label, isRequired });
      break;
    }
    case ModelType.BOOLEAN: {
      schema = getBooleanSchema({ model, label, isRequired });
      break;
    }
    case ModelType.INTEGER: {
      schema = getIntegerSchema({ model, label, isRequired });
      break;
    }
    case ModelType.FLOAT: {
      schema = getFloatSchema({ model, label, isRequired });
      break;
    }
    case ModelType.TIMESTAMP: {
      schema = getTimestampSchema({ model, label, isRequired });
      break;
    }
    case ModelType.LIST: {
      schema = getListSchema({ model, label, context, isRequired });
      break;
    }
    case ModelType.STRUCTURE: {
      schema = getStructureSchema({ model, label, context, isRequired });
      break;
    }
    case ModelType.MAP: {
      schema = getMapSchema({ model, label, context, isRequired });
      break;
    }
    default:
      throw new Error(`Schema generation for model type ${model.type} is not implemented yet.`);
  }

  if (!context) {
    return schema;
  }

  return wrapWithReferencedFieldSchema(schema, context, fieldPath);
}
