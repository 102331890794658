import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { SaaSConsoleType } from "@doitintl/cmp-models";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../Navigation/core/utils";
import GCPStepper from "./GCP/GCPStepper";
import { InitOnboardingWrapper } from "./InitOnboardingWrapper";

const SaaSOnboardingGCP = () => {
  const history = useHistory();
  const customerId = useCustomerId();

  const backToDashboard = useCallback(() => {
    history.push(customersRoute(customerId));
  }, [history, customerId]);

  return (
    <InitOnboardingWrapper type={SaaSConsoleType.GCP}>
      <GCPStepper handleBack={backToDashboard} />
    </InitOnboardingWrapper>
  );
};

export default SaaSOnboardingGCP;
