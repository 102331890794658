import { useCallback } from "react";

import {
  Aggregator,
  CalcMetricFormat,
  type CloudAnalyticsModelMetricModel,
  ComparativeFeature,
  type CurrencyCode,
  Metric,
} from "@doitintl/cmp-models";

import { formatNumber } from "../../../utils/common";
import { formatValueWithCurrency, formatValueWithSuffix } from "../useFormatter";

const isUsageMetric = (metric: any): boolean => metric.variables.findIndex((v) => v.metric === Metric.USAGE) !== -1;

export const useMetricFormatter = (
  aggregator: Aggregator,
  currency: CurrencyCode,
  metric: CloudAnalyticsModelMetricModel | null,
  maxDigits = 2
) =>
  useCallback(
    (value, short = false, comparative = ComparativeFeature.NONE) => {
      if (!metric || aggregator === Aggregator.TOTAL_OVER_TOTAL) {
        return "";
      }
      if (isNaN(value ?? undefined) || !isFinite(value)) {
        return "";
      }

      if (aggregator === Aggregator.TOTAL) {
        const percentComparative = comparative === ComparativeFeature.PERCENT;

        if (metric.format === CalcMetricFormat.PRECENTAGE) {
          if (!percentComparative) {
            value *= 100;
          }
          return formatValueWithSuffix(value, maxDigits, "%", short);
        } else {
          if (percentComparative) {
            return formatValueWithSuffix(value, maxDigits, "%", short);
          } else if (metric.variables && !isUsageMetric(metric) && metric.format === CalcMetricFormat.CURRENCY) {
            return formatValueWithCurrency(value, maxDigits, currency, short);
          }
          return formatValueWithSuffix(value, maxDigits, "", short);
        }
      } else if (aggregator === Aggregator.COUNT) {
        return formatNumber(value, maxDigits);
      } else {
        return formatValueWithSuffix(value * 100, maxDigits, "%", false);
      }
    },
    [currency, metric, maxDigits, aggregator]
  );
