import { Link as RouterLink, useLocation } from "react-router-dom";
import { DoitRole, type PerkModel, PerkTypes } from "@doitintl/cmp-models";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import ZendeskIcon from "../../../assets/zendeskIcon.png";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Ticket } from "../../../Support/types";
import { useFullScreen } from "../../../utils/dialog";
import { perkTypeNames } from "../helpers";
import { useInfoText, useIsProfessionalService, useMarketplaceListingHandler } from "../hooks";
import { RegisterInterestFromType, type SectionTitles } from "../types";
import { MarketplaceListingButton } from "./MarketplaceListingButton";
import { PerkNavBar } from "./PerkNavBar";
import { RegisterInterestButton } from "./RegisterInterestButton";

type Props = {
  disabledPerk: boolean;
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
  perk: PerkModel;
  sectionTitles: SectionTitles;
  backTo: string;
  ticket?: Ticket;
};

export const PerkHeader = ({ disabledPerk, registerInterest, perk, sectionTitles, backTo, ticket }: Props) => {
  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isPerksAdmin = useDoitRoleCheck(DoitRole.PerksAdmin);
  const pathname = useLocation().pathname?.replace(/\/+$/, "");
  const perkId = pathname.substring(pathname.lastIndexOf("/") + 1);
  const editUrl = `${pathname.substring(0, pathname.lastIndexOf("/"))}/edit/${perkId}`;
  const theme = useTheme();
  const onRegisterInterest = useMarketplaceListingHandler(registerInterest);
  const { isMobile } = useFullScreen();
  const isProfessionalService = useIsProfessionalService();
  const { infoText } = useInfoText(perk.fields.type, true);

  return (
    <Box
      sx={{
        width: "100%",
        zIndex: 1014,
        paddingTop: "24px",
      }}
    >
      <Container
        sx={{
          position: "relative",
          px: 0,
          width: "100%",
        }}
        disableGutters={isMobile}
      >
        {!isMobile && (
          <IconButton
            sx={{ position: "absolute", ml: "-55px", mt: "7px" }}
            aria-label="Back"
            component={RouterLink}
            to={backTo}
            size="large"
          >
            <BackIcon sx={{ color: "text.primary" }} />
          </IconButton>
        )}

        {!isProfessionalService(perk.fields.type) && (
          <Box display="inline-flex" alignItems="center" sx={{ height: 64 }}>
            {!!perk.enrichment?.logoUrl && (
              <Avatar
                alt="logo"
                src={perk.enrichment?.logoUrl}
                imgProps={{ sx: { objectFit: "contain", bgcolor: "white" } }}
                sx={{ width: 64, height: 64, border: `0.5px solid ${theme.palette.grey[300]}` }}
              />
            )}
            <Typography variant="body1" sx={{ ml: 1 }}>
              {perk.fields?.title || perk.fields?.name}
            </Typography>
          </Box>
        )}

        <Stack direction="row" alignItems="center">
          {isMobile && (
            <IconButton sx={{ mt: "7px" }} aria-label="Back" component={RouterLink} to={backTo} size="large">
              <BackIcon sx={{ color: "text.primary" }} />
            </IconButton>
          )}

          <Typography variant="h1" sx={{ mt: "18px", mb: "8px" }}>
            {perk.fields?.name}
          </Typography>

          {isPerksAdmin && (
            <Button component={RouterLink} to={editUrl} color="primary" sx={{ marginTop: "8px", ml: "auto" }}>
              <EditIcon color="primary" sx={{ width: "20px", height: "20px" }} />
              <span style={{ marginLeft: "5px", marginTop: "2px" }}>Edit</span>
            </Button>
          )}
        </Stack>

        {perk.fields?.shortDescription ? (
          <Typography variant="body2" component="div" sx={{ color: "text.secondary" }}>
            {perk.fields?.shortDescription}
          </Typography>
        ) : (
          <Box height="28px" />
        )}

        {!!infoText && (
          <Stack direction="row" component={Typography} variant="body2" pt={3} color="text.secondary" fontSize="14pt">
            <InfoOutlinedIcon fontSize="inherit" sx={{ mr: 1, mt: 0.5 }} />
            <Typography component="span">{infoText}</Typography>
          </Stack>
        )}

        <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center" pt={1} pb={3}>
          <RegisterInterestButton
            disabledPerk={disabledPerk}
            registerInterest={() => registerInterest(RegisterInterestFromType.InterestButton)}
            perkType={perk.fields.type}
            ticket={ticket}
          />
          {Object.entries(perk.fields?.platformUrl || {}).map(([platform, url]) => (
            <MarketplaceListingButton
              key={platform}
              platform={platform}
              handler={() => onRegisterInterest(RegisterInterestFromType[platform], url)}
            />
          ))}

          {ticket && disabledPerk && (
            <Box>
              {isDoitEmployee && (
                <Typography variant="body1">
                  {`The customer has already requested this ${perk.fields.type === PerkTypes.Accelerator ? perkTypeNames[perk.fields.type] : perkTypeNames[perk.fields.type].toLowerCase()}`}
                </Typography>
              )}
              <Box component="img" alt="zendesk icon" src={ZendeskIcon} width="1.2rem" mr="0.25ch" mt="-0.25rem" />
              <Link
                component={RouterLink}
                mr="0.5ch"
                variant="body1"
                color="inherit"
                to={`/customers/${customer.id}/support/tickets/${ticket.id}`}
              >
                {`#${ticket.id}`}
              </Link>
            </Box>
          )}
        </Stack>
      </Container>
      <PerkNavBar sectionTitles={sectionTitles} />
    </Box>
  );
};
