import { DoitRole, TemplateApprovalStatus } from "@doitintl/cmp-models";
import { Alert, type AlertColor, AlertTitle } from "@mui/material";

import { templateCreateText, templateLibraryTxt } from "../../assets/texts/CloudAnalytics";
import { useAuthContext } from "../../Context/AuthContext";
import { type ReportTemplateCard } from "../../Pages/CloudAnalytics/templateLibrary/types";
import { useDoitRoleCheck } from "../hooks/useDoitRoles";

type Props = {
  isVersionOwner: boolean;
  reportTemplate: ReportTemplateCard;
};

const TemplateAlert = ({ isVersionOwner, reportTemplate }: Props) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isTemplateLibraryAdmin = useDoitRoleCheck(DoitRole.TemplateLibraryAdmin);
  let title = "";
  let description = "";
  let severity: AlertColor = "info";

  const {
    visibleVersion: { createdBy, rejectMessage, status, timeModified },
  } = reportTemplate;

  if (isTemplateLibraryAdmin) {
    switch (status) {
      case TemplateApprovalStatus.PENDING:
        title = templateLibraryTxt.PENDING_APPROVAL;
        description = templateCreateText.REVIEWER_PENDING_ALERT_DESC(createdBy, timeModified);
        break;
      case TemplateApprovalStatus.REJECTED:
        title = templateCreateText.REVIEWER_REJECTED_ALERT_TITLE;
        description = rejectMessage;
        break;
    }
  } else if (isDoitEmployee && isVersionOwner) {
    switch (status) {
      case TemplateApprovalStatus.PENDING:
        title = templateLibraryTxt.PENDING_APPROVAL;
        description = templateCreateText.DOER_PENDING_ALERT_DESC;
        break;
      case TemplateApprovalStatus.REJECTED:
        title = templateCreateText.DOER_REJECTED_ALERT_TITLE;
        description = rejectMessage;
        severity = "warning";
        break;
    }
  }

  if (title && description && severity) {
    return (
      <Alert data-cy="template-alert" severity={severity}>
        <AlertTitle data-cy="template-alert-title">{title}</AlertTitle>
        {description}
      </Alert>
    );
  }

  return <></>;
};

export default TemplateAlert;
