import { NotificationWizard } from "./CreateNotificationWizard/index";
import { NotificationSettings } from "./NotificationSettings";

const NotificationCenter = ({ pageId }) => (
  <>
    {pageId === "notifications" && <NotificationSettings />}
    {(pageId === "notifications:create" || pageId === "notifications:edit") && <NotificationWizard />}
  </>
);

export default NotificationCenter;
