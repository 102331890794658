import { useEffect, useMemo } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, Checkbox, Stack, TextField, Typography } from "@mui/material";

import { useSessionStorage } from "../../../../../Components/hooks/storageHooks";
import { MultiSelect } from "../../../../../Components/MultiSelect";
import { type CostDifferencesData } from "../../types";
import { discrepancyAbbreviations } from "../Pages/utils";
import { CurrencySelector } from "./CurrencySelector";

type Props = {
  data: CostDifferencesData[];
  onFilterChange: (filteredData: CostDifferencesData[]) => void;
  onCurrencyChange: (currency: CurrencyCode) => void;
  storageKey: string;
};

const getUniqueDiscrepancies = (data: CostDifferencesData[]) => {
  const allDiscrepancies = data.flatMap((item) => Object.keys(item.discrepancies));
  return Array.from(new Set(allDiscrepancies));
};

const handleRenderDiscrepancyOption = (props: React.HTMLAttributes<HTMLLIElement>, option: string, { selected }) => (
  <li {...props} key={option}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      checked={selected}
    />
    {discrepancyAbbreviations[option].label}
  </li>
);

const handleRenderDiscrepancyTags = (value: string[]) => (
  <>
    <Typography sx={{ textOverflow: "ellipsis", textWrap: "nowrap", overflow: "hidden" }} pl={1}>
      {discrepancyAbbreviations[value[0]].label}
    </Typography>
    {value.length > 1 && <Typography ml="4px">+{value.length - 1}</Typography>}
  </>
);

const ExplainerCostDifferencesFiltersComponent = ({ data, onFilterChange, onCurrencyChange, storageKey }: Props) => {
  const [search, setSearch] = useSessionStorage<string>(`${storageKey}_search`, "");
  const [discrepancyFilter, setDiscrepancyFilter] = useSessionStorage<string[]>(`${storageKey}_discrepancy_filter`, []);

  const uniqueDiscrepancies = useMemo(() => getUniqueDiscrepancies(data), [data]);

  const handleDiscrepancyFilterChange = (value: string[]) => {
    setDiscrepancyFilter(value);
  };

  useEffect(() => {
    const filteredData = data.filter((item) => {
      const matchesSearch = search.length === 0 || item.costName.toLowerCase().includes(search.toLowerCase());
      const matchesDiscrepancy =
        discrepancyFilter.length === 0 || discrepancyFilter.some((x) => item.discrepancies[x] !== undefined);
      return matchesDiscrepancy && matchesSearch;
    });

    onFilterChange(filteredData);
  }, [data, discrepancyFilter, onFilterChange, search]);

  return (
    <Stack direction="row" spacing={1}>
      <TextField
        placeholder="Filter"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        sx={{ flex: 1 }}
      />
      <Box sx={{ width: "210px" }}>
        <MultiSelect
          label="Reasons for difference"
          selectedOptions={discrepancyFilter}
          options={uniqueDiscrepancies}
          onChange={handleDiscrepancyFilterChange}
          handleRenderOption={handleRenderDiscrepancyOption}
          handleRenderTags={handleRenderDiscrepancyTags}
          textFieldProps={{ size: "small" }}
        />
      </Box>

      <Box sx={{ width: "150px" }}>
        <CurrencySelector onCurrencyChange={onCurrencyChange} />
      </Box>
    </Stack>
  );
};

export default ExplainerCostDifferencesFiltersComponent;
