import { useCallback, useState } from "react";

import ExpandMore from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { type AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import MuiFormControlLabel, { type FormControlLabelProps } from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useDebouncedMixpanelEventHandler } from "../../../Components/hooks/useDebouncedMixpanelEventHandler";
import { type DataColumns, type SideFiltersData } from "../../../types/FilterTable";
import { useSideFilters } from "../hook";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMore />} {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-content": {
    marginBottom: 0,
    marginTop: 0,
  },
  paddingLeft: 0,
  paddingRight: theme.spacing(4),
  minHeight: 0,
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion defaultExpanded disableGutters square elevation={0} {...props} />
))(({ theme }) => ({
  "&.MuiAccordion-root": {
    marginBottom: theme.spacing(3),
    "&:before": {
      display: "none",
    },
  },
}));

const FormControlLabel = styled((props: FormControlLabelProps) => <MuiFormControlLabel {...props} />)(() => ({
  "& .MuiFormControlLabel-label": {
    overflow: "hidden",
  },
}));

export function SideFilter<T>({
  filters,
  mixpanelEventName,
  onFilter,
  columns,
  reverseOperatorPrecedence,
}: {
  filters: SideFiltersData<T>[];
  mixpanelEventName?: string;
  onFilter: (data: any) => void;
  columns: DataColumns<any>;
  reverseOperatorPrecedence?: boolean;
}) {
  const theme = useTheme();
  const isDarkMode = useDarkThemeCheck();
  const customerId = useCustomerId();

  const { selectedByValue, checkBoxHandler } = useSideFilters({
    mixpanelEventName,
    onFilter,
    columns,
    reverseOperatorPrecedence,
  });
  const [openTooltipId, setOpenTooltipId] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Record<string, string>>({});
  const trackColumnFilter = useDebouncedMixpanelEventHandler({
    mixpanelEventName: mixpanelEventName?.concat(".filterInput"),
  });

  const renderOptionList = (filter: SideFiltersData<T>, filterQuery?: string) => {
    let items = filter.items;
    if (filterQuery) {
      items = items.filter((item) => item.value?.includes(filterQuery));
    }

    if (items.length === 0) {
      return (
        <Typography
          variant="body2"
          pl={1}
          sx={{ opacity: isDarkMode ? 0.7 : 0.6 }}
        >{`No ${filter.title.toLowerCase()}s found`}</Typography>
      );
    }

    return items.map((item) => (
      <FormControlLabel
        key={item.value}
        sx={{ m: 0 }}
        control={<Checkbox value={item.value} onChange={checkBoxHandler({ column: filter.column, option: item })} />}
        label={
          <Tooltip
            title={`${item.label} (${item.count})`}
            open={openTooltipId === item.value}
            onOpen={() => {
              setOpenTooltipId(item.value);
            }}
            onClose={() => {
              setOpenTooltipId(null);
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography noWrap variant="body2">
                {item.label}
              </Typography>
              <Typography sx={{ pl: 1 }} variant="body2">
                ({item.count})
              </Typography>
            </Box>
          </Tooltip>
        }
        checked={selectedByValue[item.value] || false}
      />
    ));
  };

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, filterName: string) => {
      trackColumnFilter({ filterName, customerId });
      setColumnFilters((current) => ({
        ...current,
        [filterName]: event.target.value,
      }));
    },
    [customerId, trackColumnFilter, setColumnFilters]
  );

  return (
    <Stack>
      {filters.map((f) => (
        <Accordion key={f.title} TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {f.title}
            </Typography>
          </AccordionSummary>
          {f.showInputFilter && (
            <TextField
              sx={{ pt: 1, pb: 1 }}
              variant="outlined"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeHandler(e, f.title);
              }}
              value={columnFilters[f.title] ?? ""}
              inputProps={{ maxLength: 30 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          <AccordionDetails sx={{ p: 0 }}>
            <Paper
              elevation={0}
              onScroll={() => {
                setOpenTooltipId(null);
              }}
              sx={{
                maxHeight: 260,
                paddingRight: theme.spacing(6),
                display: "flex",
                overflowY: "auto",
                flexWrap: "nowrap",
                flexDirection: "column",
              }}
            >
              {renderOptionList(f, columnFilters[f.title])}
            </Paper>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}
