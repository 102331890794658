import { doitCustomerId } from "../../../../utils/customers";
import { customersRoute } from "../../../core/utils";

export const useAvatarLink = ({
  isDoitEmployee,
  customerId,
  userId,
}: {
  isDoitEmployee: boolean;
  customerId?: string;
  userId: string;
}) => {
  if (isDoitEmployee) {
    const prefix = customersRoute(doitCustomerId);

    return `${prefix}/profile/${userId}`;
  }

  if (!customerId) {
    return "/customers";
  }

  const prefix = customersRoute(customerId);
  return `${prefix}/profile/${userId}`;
};
