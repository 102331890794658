import { Box, StyledEngineProvider } from "@mui/material";

type Props = {
  title: string;
  subtitle: string;
};
export const SettingsHeader = ({ title, subtitle }: Props) => (
  <StyledEngineProvider injectFirst>
    <Box px={2} pt={0.5} pb={1}>
      <Box fontSize={16} fontWeight="bold" color="text.primary">
        {title}
      </Box>
      <Box fontSize={12} color="text.secondary">
        {subtitle}
      </Box>
    </Box>
  </StyledEngineProvider>
);
