export enum configurationPageTabName {
  pricebook = "pricebook",
  partnerBillingRules = "partner-billing-rules",
}

export const routes = {
  developerDocs: "/developer-docs",
  signin: "/sign-in",
  signinError: "/sign-in/error",
  configuration: "/configuration/:tab?/:id?",
  configurationPricebookListing: `/configuration/${configurationPageTabName.pricebook}`,
  configurationPricebookCreate: `/configuration/${configurationPageTabName.pricebook}/create`,
  configurationPricebookItem: (id: string | undefined = ":id?") =>
    `/configuration/${configurationPageTabName.pricebook}/${id}`,
  configurationPartnerBillingRules: `/configuration/${configurationPageTabName.partnerBillingRules}`,
};
