import { Classification } from "@doitintl/cmp-models";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { SettingsAdminItemWrapper } from "../SettingsAdminItemWrapper";
import { text } from "../text";
import { type SettingsValuesType } from "../types";

export function CustomerClassification({
  value,
  onChange,
  errMsg,
}: {
  value?: SettingsValuesType["customerClassification"];
  onChange: (newVal: SettingsValuesType["customerClassification"]) => void;
  errMsg?: string;
}) {
  return (
    <SettingsAdminItemWrapper disabledForTrial={true}>
      {(isDisabled) => (
        <FormControl fullWidth disabled={isDisabled}>
          <InputLabel>{text.CUSTOMER_CLASSIFICATION_SELECT_LABEL}</InputLabel>
          <Select
            label={text.CUSTOMER_CLASSIFICATION_SELECT_LABEL}
            sx={{ height: 45 }}
            value={value}
            data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.CUSTOMER_CLASSIFICATION}
            onChange={(e) => {
              const val = e.target.value;
              if (!val) {
                onChange(null);
              } else {
                onChange(val as Classification);
              }
            }}
            inputProps={{
              "aria-label": text.CUSTOMER_CLASSIFICATION_SELECT_LABEL,
            }}
          >
            <MenuItem key={Classification.Business} value={Classification.Business}>
              {text.CUSTOMER_CLASSIFICATION_BUSINESS_LABEL}
            </MenuItem>
            <MenuItem key={Classification.Terminated} value={Classification.Terminated}>
              {text.CUSTOMER_CLASSIFICATION_TERMINATED_LABEL}
            </MenuItem>
            <MenuItem key={Classification.SuspendedForNonPayment} value={Classification.SuspendedForNonPayment}>
              {text.CUSTOMER_CLASSIFICATION_SUSPENDED_FOR_NON_PAYMENT_LABEL}
            </MenuItem>
            <MenuItem key={Classification.Inactive} value={Classification.Inactive}>
              {text.CUSTOMER_CLASSIFICATION_INACTIVE_LABEL}
            </MenuItem>
          </Select>
          {!!errMsg && <FormHelperText sx={{ display: "flex", color: "error.main" }}>{errMsg}</FormHelperText>}
        </FormControl>
      )}
    </SettingsAdminItemWrapper>
  );
}
