import AwsResourceDetails from "./AwsResourceDetails";
import GcpGkeResourceDetails from "./GcpGkeResourceDetails";
import GcpSqlResourceDetails from "./GcpSqlResourceDetails";

export const resourceDetailsPriorityTrigger = ["urgent", "high"];
export enum ResourceDetailsProductTrigger {
  AWS_EC2 = "aws/amazon-ec2",
  AWS_RDS = "aws/amazon-rds",
  GCP_GKE = "gcp/google-kubernetes-engine-gke",
  GCP_SQL = "gcp/cloud-sql",
}

export const resourceDetailsComponentTypeMap = {
  [ResourceDetailsProductTrigger.AWS_EC2]: AwsResourceDetails,
  [ResourceDetailsProductTrigger.AWS_RDS]: AwsResourceDetails,
  [ResourceDetailsProductTrigger.GCP_GKE]: GcpGkeResourceDetails,
  [ResourceDetailsProductTrigger.GCP_SQL]: GcpSqlResourceDetails,
};

const getResourceDetailsComponent = (componentType: string) => resourceDetailsComponentTypeMap[componentType] ?? null;
export default getResourceDetailsComponent;
