// goes nowhere, does nothing
import { useMemo } from "react";

import { AccountManagerCompanies } from "@doitintl/cmp-models";
import { Hidden, TableCell, Typography } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { menuItemKey, ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { type AccountRole, companiesLookup, getAccountTeamRole } from "../../../utils/common";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";
import { cyIds } from "../../MPA/table/MpaTableRow";
import { type AccountManagerWithId } from "../types";

export type AccountManagersRowData = AccountManagerWithId & {
  reportsTo?: AccountManagerWithId;
};

type Props = {
  rowData: AccountManagersRowData;
  roles: AccountRole[];
  isFsm: boolean;
  onEditAccountManager: () => void;
  onDeleteAccountManager: () => void;
  onImpersonating: () => void;
};

const AccountManagersFilterTableRow = ({
  rowData: accountManager,
  roles,
  onEditAccountManager,
  onDeleteAccountManager,
  onImpersonating,
  isFsm,
}: Props) => {
  const { isDoitOwner } = useAuthContext();
  const menuOptions: ThreeDotsMenuOption[] = useMemo(
    () => [
      {
        label: <Typography variant="body2">Edit Account manager</Typography>,
        key: menuItemKey("EditAccountManager", accountManager.id),
        action: onEditAccountManager,
        dataCy: cyIds.menu.edit,
      },
      {
        label: (
          <Typography variant="body2" color="error">
            Delete Account manager
          </Typography>
        ),
        key: menuItemKey("DeleteAccountManager", accountManager.id),
        action: onDeleteAccountManager,
        dataCy: cyIds.menu.delete,
      },
    ],
    [accountManager.id, onDeleteAccountManager, onEditAccountManager]
  );

  const ownerMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () => [
      ...menuOptions,
      {
        label: <Typography variant="body2">View As</Typography>,
        action: onImpersonating,
        key: "VIEW_AS_SELECTED_USER",
      },
    ],
    [menuOptions, onImpersonating]
  );

  const threeDotsMenu = useMemo(() => {
    if (isDoitOwner) {
      return <ThreeDotsMenu options={ownerMenuOptions} size="small" />;
    } else {
      if (isFsm && accountManager.company !== AccountManagerCompanies.DOIT) {
        return <ThreeDotsMenu options={menuOptions} size="small" />;
      }
    }
    return null;
  }, [accountManager.company, isDoitOwner, isFsm, menuOptions, ownerMenuOptions]);

  // many Account Managers have blank names
  const displayName = !accountManager.name?.trim().length ? NotAvailable : accountManager.name;
  return (
    <CellsWrapper>
      <TableCell>
        <Typography variant="body2" component="span" noWrap>
          {displayName}
        </Typography>
      </TableCell>
      <Hidden smDown>
        <TableCell>
          <Typography variant="body2" component="span" noWrap>
            {accountManager.email}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden lgDown>
        <TableCell>
          <Typography variant="body2" component="span" noWrap>
            {accountManager.company === "doit" && accountManager.role === "customer_engineer"
              ? "SAM"
              : (getAccountTeamRole(roles, accountManager) ?? accountManager.role)}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        <TableCell>
          <Typography variant="body2" component="span" noWrap>
            {companiesLookup[accountManager.company]?.name}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden lgDown>
        <TableCell>
          <Typography variant="body2" component="span" noWrap>
            {accountManager?.reportsTo?.name ?? accountManager?.reportsTo?.email ?? NotAvailable}
          </Typography>
        </TableCell>
      </Hidden>
      <TableCell padding="checkbox" data-cy={cyIds.menu}>
        {threeDotsMenu}
      </TableCell>
    </CellsWrapper>
  );
};

export default AccountManagersFilterTableRow;
