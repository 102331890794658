import { Drawer, Paper } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: "80%",
  },
  drawerPaper: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.navigation.background,
  },
}));

const MobileMenuSlider = ({ children, isVisible, onClose }) => {
  const classes = useStyles();

  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor="left"
      open={isVisible}
      onClose={onClose}
      PaperProps={{ square: true }}
    >
      <Paper className={classes.drawerPaper} square>
        {children}
      </Paper>
    </Drawer>
  );
};

export default MobileMenuSlider;
