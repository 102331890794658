import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  type ContractModelType,
  type CustomerModel,
  RampModel,
  type RampPlanCommitmentPeriods,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { type Contracts } from "../../types";
import { getDateTimeFromFirestoreTimestamp } from "../../utils/common";
import { type FirestoreTimestamp } from "../../utils/firebase";
import { type AggregatedDataObjType } from "./RampPlan/getRampDataArrays";
import { getInitPeriodsDataFromContract } from "./RampPlan/PeriodTables/Tables/getInitPeriodsDataFromContract";
import { type CommitmentPeriod, type CommitmentPeriodsFromContract, type RampPlanModel } from "./types";

export enum CsvImportMessages {
  FILE_FORMAT_NOT_CSV = 'Upload from file failed: Only csv file types can be imported. Make sure your file name ends with ".csv"',
  NO_PLAN_STR_CELL = 'Upload from file failed: You must upload a CSV file with a row starting with the keyword "Plan".',
  PLAN_DATA_CELLS_WRONG_LENGTH = "Upload file failed: Plan row must include data for all period months.",
  NUMBERS_FORMAT_ONLY = 'Upload from file failed: In Plan row, data cells following "Plan" cell must include numbers only',
  IMPORT_SUCCESS = "Success! Ramp plan updated from CSV file",
}

export enum CsvImportErrorDocumentation {
  NO_PLAN_STR_CELL = "https://help.doit.com/docs/billing/ramp-plan#create-a-ramp-plan",
}

const isCloudAllowed = (cloud: ContractModelType) =>
  cloud === AssetTypeGoogleCloud || cloud === AssetTypeAmazonWebServices;

export const getEligibleContracts = (contracts: Contracts): Contracts => {
  if (!contracts?.length) {
    return [];
  }
  return contracts.filter((contract) => {
    const contractSum = contract.commitmentPeriods?.reduce((sum, x) => sum + x.value, 0);
    if (!contract?.endDate?.toDate) {
      return false;
    }

    const contractEndDateTime = getDateTimeFromFirestoreTimestamp(contract.endDate);
    const contractEnded = contractEndDateTime < DateTime.now().toUTC();

    return (
      contract.isCommitment &&
      (contract.commitmentPeriods?.length || contract.estimatedValue) &&
      contract.active &&
      !contractEnded &&
      typeof contractSum === "number" &&
      contractSum !== 0 &&
      isCloudAllowed(contract.type)
    );
  });
};

export const findRelevantContractPeriods = (
  contracts: Contracts,
  planStartSec: number,
  planOrigEstEndDateSec: number
): CommitmentPeriodsFromContract[] => {
  const relevantContracts = getEligibleContracts(contracts);
  if (!relevantContracts.length) {
    return [];
  }
  const relatedContract = relevantContracts.find(
    (contract) =>
      contract.commitmentPeriods &&
      contract.startDate?.seconds === planStartSec &&
      contract.endDate?.seconds === planOrigEstEndDateSec
  );

  if (!relatedContract) {
    return [];
  }
  return getInitPeriodsDataFromContract(relatedContract);
};

const fixPlannedGraphData = (
  initValue: number,
  addAdjustedValue: number,
  isNotTheFirstRampPlanPeriod: boolean,
  isPlannedValueInNonLegacyPlan: boolean,
  hasSplitFirstMonthOfPeriod: boolean
): number =>
  isNotTheFirstRampPlanPeriod && isPlannedValueInNonLegacyPlan && hasSplitFirstMonthOfPeriod
    ? initValue + addAdjustedValue
    : initValue;

const enforceValidCommitment = (data: Array<number>, startDate: DateTime, index: number) => {
  const s = startDate.plus({ months: index }).set({ day: 1 }).toMillis();
  if (!data) {
    return [s, 0];
  }
  return [s, data[index]];
};

const reduceDataTotal = (i: number, allPeriodIndex: number, sum: number, x: number[]) =>
  i <= allPeriodIndex ? sum + x[1] : sum;

const idFromDataFlag = (
  dataFlag: string | null,
  dataTotal: number,
  planTotal: number,
  areAnnotationsInDataSums: AggregatedDataObjType[]
) => (dataFlag !== null && (dataTotal < planTotal || areAnnotationsInDataSums.length > 0) ? null : dataFlag);

// sometimes a rounding error of +/- 1 second that isn't a forgotten ".toUTC()" can occur
// if a customer needed their ramp plan to be manually adjusted.
const correctRareTimeError = (utcTimeInMillis: number): number => Math.floor(utcTimeInMillis / 1000) * 1000;

const makeTempDatum = (
  monthStartInMillis: number,
  dataName: "actuals" | "planned",
  isFutureMonth: boolean,
  dataTotal: number,
  dataFlag: string | null,
  planTotal: number,
  areAnnotationsInDataSums: AggregatedDataObjType[]
): AggregatedDataObjType => ({
  x: correctRareTimeError(monthStartInMillis),
  y: dataName === "actuals" && isFutureMonth ? undefined : dataTotal,
  id: idFromDataFlag(dataFlag, dataTotal, planTotal, areAnnotationsInDataSums),
});

export const periodsDataForPlannedChart = (
  periods: Pick<CommitmentPeriod | CommitmentPeriodsFromContract, "startDate" | "planned">[]
): AggregatedDataObjType[] =>
  periods.flatMap((period) => {
    const startDate = getDateTimeFromFirestoreTimestamp(period.startDate).toUTC();

    return period.planned.map(
      (_plannedMonth, pm_idx): AggregatedDataObjType => ({
        x: startDate.plus({ months: pm_idx }).set({ day: 1 }).toMillis(),
        y: undefined,
        id: null,
      })
    );
  });

export const periodsDataForChart = (
  periods: Pick<
    CommitmentPeriod | (CommitmentPeriodsFromContract & { actuals: undefined }),
    "endDate" | "startDate" | "planned" | "actuals"
  >[],
  getData: (period: Pick<CommitmentPeriod, "planned" | "actuals">) => {
    data: Array<number> | null | undefined;
    dataName: "actuals" | "planned";
  },
  dataFlag: string | null,
  planTotal: number
): AggregatedDataObjType[] => {
  let allPeriodIndex = 0;
  const dataArr: number[][] = [];
  const dataSumUpToIncThisMonth: AggregatedDataObjType[] = [];

  let adjustmentForSplitMonths = 0;
  periods.forEach((period, p_idx) => {
    const startDate = getDateTimeFromFirestoreTimestamp(period.startDate).toUTC();

    const { data, dataName } = getData(period);
    // no "actuals" data in periods that haven't started -> fill with undefined
    if (!data) {
      dataSumUpToIncThisMonth.push(...periodsDataForPlannedChart(periods));
      return;
    }

    data.forEach((_spend: number, monthOfPeriod) => {
      const firstMonthStartInMillis = startDate.toUTC().toMillis();
      const firstMonthFirstDayStartInMillis = startDate.toUTC().set({ day: 1 }).toMillis();
      const monthStartInMillis = startDate.toUTC().plus({ months: monthOfPeriod }).set({ day: 1 }).toMillis();

      // there is no "actual" data for months that haven't happened yet
      const isFutureMonth = monthStartInMillis > DateTime.now().toUTC().toMillis();

      dataArr[allPeriodIndex] = enforceValidCommitment(data, startDate, monthOfPeriod);

      // readable boolean definitions for the humans
      const hasSplitFirstMonthOfPeriod = firstMonthStartInMillis !== firstMonthFirstDayStartInMillis;
      const isLegacyRampPlan = period.planned.length !== period.actuals?.length;
      const isNotTheFirstRampPlanPeriod = p_idx > 0;
      const isNonEmptyActualValue = !isFutureMonth && dataName === "actuals";
      const isPlannedValueInNonLegacyPlan = dataName === "planned" && !isLegacyRampPlan;
      const isNonEmptyActualValueOrIsPlannedValueInNonLegacyPlan =
        isNonEmptyActualValue || isPlannedValueInNonLegacyPlan;

      // summing the planned/actual spends over time is what makes it a "ramp" plan
      let dataTotal = dataArr.reduce((sum, spendArray, i) => reduceDataTotal(i, allPeriodIndex, sum, spendArray), 0);

      if (isNotTheFirstRampPlanPeriod && monthOfPeriod === 0 && hasSplitFirstMonthOfPeriod) {
        adjustmentForSplitMonths += data[0];
      }

      // the months array loses the second half of split months and needs to be compensated
      // this fixes "actual" values.
      if (isNonEmptyActualValue && isNotTheFirstRampPlanPeriod && monthOfPeriod > 0 && hasSplitFirstMonthOfPeriod) {
        dataTotal += adjustmentForSplitMonths;
      }

      const areAnnotationsInDataSums = dataSumUpToIncThisMonth.filter((a) => a?.id);

      const tempDatum = makeTempDatum(
        monthStartInMillis,
        dataName,
        isFutureMonth,
        dataTotal,
        dataFlag,
        planTotal,
        areAnnotationsInDataSums
      );

      // this couldn't go with the other booleans
      const isSecondHalfOfSplitMonth =
        monthOfPeriod === 0 && allPeriodIndex > 0 && tempDatum.x === dataSumUpToIncThisMonth[allPeriodIndex - 1].x;

      // if first month of not-first period, check if needs to be merged with last month of prev period
      if (
        isNonEmptyActualValueOrIsPlannedValueInNonLegacyPlan &&
        isNotTheFirstRampPlanPeriod &&
        isSecondHalfOfSplitMonth
      ) {
        dataSumUpToIncThisMonth[allPeriodIndex - 1].y! += data[0];
      } else {
        // the months array loses the second half of split months and needs to be compensated
        // this fixes "planned" values.
        tempDatum.y = fixPlannedGraphData(
          tempDatum.y as number,
          adjustmentForSplitMonths,
          isNotTheFirstRampPlanPeriod,
          isPlannedValueInNonLegacyPlan,
          hasSplitFirstMonthOfPeriod
        );

        dataSumUpToIncThisMonth[allPeriodIndex] = tempDatum;
        allPeriodIndex++;
      }
    });
  });

  return dataSumUpToIncThisMonth;
};

export const isPlanNameUnique = async (
  name: string,
  rampPlanId: string,
  customerRef: ModelReference<CustomerModel>
) => {
  const query = await getCollection(RampModel).where("name", "==", name).where("customerRef", "==", customerRef).get();
  return query.docs.some((doc) => doc.id !== rampPlanId);
};

export const rampsObjToCsvFormat = (objArrayAsStr: string): string => {
  const parsedData = JSON.parse(objArrayAsStr);
  let rampCsvData = "\r\n";
  parsedData.forEach((period) => {
    let periodData = "";
    for (const key in period) {
      periodData += `${period[key]}\r\n`;
    }
    rampCsvData += `${periodData}\r\n`;
  });
  return rampCsvData;
};

const extractRampCsvData = (csvFile: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(csvFile);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });

const parseFromCsvFormats = (row: string) => {
  // Parse a CSV row, accounting for commas inside quotesparse(row){
  let insideQuote = false;
  const entries: string[] = [];
  let entry: string[] = [];
  row
    .replaceAll("\r\n", ",")
    .split("")
    .forEach((character) => {
      if (character === '"') {
        insideQuote = !insideQuote;
      } else {
        if (character === "," && !insideQuote) {
          entries.push(entry.join("").trim().replaceAll(",", ""));
          entry = [];
        } else {
          entry.push(character);
        }
      }
    });
  entries.push(entry.join(""));
  return entries.filter((x: string) => x?.length);
};
const csvMimeTypes = [
  "text/csv",
  "application/csv",
  "text/comma-separated-values",
  "application/excel",
  "application/vnd.ms-excel",
  "application/vnd.msexcel",
  "application/csv",
  "application/excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // For Excel files, 2010 (.xlsx) ?
];
export const importRampCsvToPlanArr = async (csvFile: File) => {
  // validate file
  const isAllowedFileType = (fileType: string) => typeof csvMimeTypes.find((c) => c === fileType) !== "undefined";
  if (!isAllowedFileType(csvFile.type)) {
    throw new Error(CsvImportMessages.FILE_FORMAT_NOT_CSV);
  }
  const rampCsvData = await extractRampCsvData(csvFile);
  const parsedData: string[] = parseFromCsvFormats(rampCsvData as string);
  // finding periods start points by index of "Plan" and ending points by index of "Actual"
  const planStringIndexes: number[] = [];
  const actualStringIndexes: number[] = [];
  const arrFromParsed = parsedData
    .map((v) => v.replaceAll("$", ""))
    .flat()
    .filter((x) => x?.length);

  arrFromParsed.forEach((item, k) => {
    if (item.includes("Plan")) {
      planStringIndexes.push(k);
    }
    if (item.includes("Actual")) {
      actualStringIndexes.push(k);
    }
  });
  if (planStringIndexes?.length === 0) {
    throw new Error(CsvImportMessages.NO_PLAN_STR_CELL);
  }
  return planStringIndexes.map((planStrIndex, i) => {
    // slicing the array from the "Plan" string cell to the following (corresponding in actualStringIndexes array) "Actual" string cell - representing "planned" row
    const stopAt = actualStringIndexes[i] || arrFromParsed?.length - 1;
    // +1 to remove the cell that contains the "Plan" string,
    // -1 to remove the cell that contains the "Total" value from last plan row cell
    return arrFromParsed.slice(planStrIndex + 1, stopAt - 1);
  });
};

const newPlan = (periods: { planned: number[] }[] | CommitmentPeriod[], planDataFromCsv: string[][]) =>
  periods.map((period, index: number) => {
    if (typeof planDataFromCsv !== "undefined" && planDataFromCsv[index]?.length !== period.planned.length) {
      throw new Error(CsvImportMessages.PLAN_DATA_CELLS_WRONG_LENGTH);
    } else {
      period.planned = planDataFromCsv[index].map((str) => {
        const strToNum = parseFloat(str);
        if (isNaN(strToNum)) {
          throw new Error(CsvImportMessages.NUMBERS_FORMAT_ONLY);
        }
        return parseInt(str);
      });
      return period;
    }
  });
type csvAndPeriods = {
  csvFile: File;
  periods: { planned: number[] }[] | CommitmentPeriod[];
  rampId?: string;
};
export const csvIntoPlan = async ({ csvFile, periods }: csvAndPeriods): Promise<CommitmentPeriod[]> => {
  const planDataFromCsv = await importRampCsvToPlanArr(csvFile);
  return newPlan(periods, planDataFromCsv);
};

export const checkCsvAgainstPeriods = async ({ csvFile, periods }: csvAndPeriods): Promise<string | undefined> => {
  let res: string | undefined = undefined;
  try {
    const planFromPeriod = await csvIntoPlan({ csvFile, periods });
    if (planFromPeriod.length && typeof planFromPeriod !== "undefined") {
      res = CsvImportMessages.IMPORT_SUCCESS;
    }
  } catch (err: any) {
    res = err.message;
  }
  return res;
};

export const updatePlanDataFromCsv = async ({
  periods,
  csvFile,
  rampId,
}: csvAndPeriods): Promise<string | undefined> => {
  let res: string | undefined;
  try {
    const isValidFile = await checkCsvAgainstPeriods({ csvFile, periods });
    res = isValidFile;
    if (isValidFile === CsvImportMessages.IMPORT_SUCCESS && rampId) {
      const updatedPlan = await csvIntoPlan({ csvFile, periods });
      await getCollection(RampModel).doc(rampId).update("commitmentPeriods", updatedPlan);
      res = CsvImportMessages.IMPORT_SUCCESS;
    }
  } catch (err: any) {
    res = err.message;
  }
  return res;
};

export const zeroToHundredDigitsOnly = {
  DIGITS: "Digits only",
  RANGE: "Percents must be between 0 and 100",
};

export const isRampPlanComplete = (rampPlan: RampPlanModel): boolean => {
  const timeDiffObject = DateTime.fromJSDate(rampPlan.origEstEndDate.toDate())
    .toUTC()
    .diff(DateTime.now().toUTC(), "months")
    .toObject();

  return timeDiffObject.months !== undefined && timeDiffObject.months < 0;
};

export function timestampToFormat(timestamp: FirestoreTimestamp, format = "MMM yy"): string {
  return DateTime.fromSeconds(timestamp.seconds).toUTC().toFormat(format);
}

// two very similar data types, one from the DB models fields, and one for ramp plans frontend use
export const commitmentPeriodToRampPlanCommitmentPeriods = (
  commitment: CommitmentPeriod
): RampPlanCommitmentPeriods => ({
  actuals: commitment.actuals ?? [],
  planned: commitment.planned,
  startDate: commitment.startDate,
  endDate: commitment.endDate ?? undefined,
  value: commitment.value,
  actualsBreakdown: commitment.actualsBreakdown ?? undefined,
});
