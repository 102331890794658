import { AlgoliaAutocomplete } from "../Components/AlgoliaSearch/AlgoliaAutocomplete";
import { CloudflowSearchIndex } from "../Components/AlgoliaSearch/consts";
import { useAlgoliaSearchContext } from "../Context/algoliaContext";

type Props = {
  searchWidth?: number;
};

export default function MenuBarSearch({ searchWidth }: Props) {
  const algoliaContext = useAlgoliaSearchContext();

  if (!algoliaContext.searchClient) {
    return null;
  }

  return (
    <AlgoliaAutocomplete
      searchWidth={searchWidth}
      searchClient={algoliaContext.searchClient}
      restrictedIndices={[...(algoliaContext.restrictedIndices ?? []), ...CloudflowSearchIndex]}
    />
  );
}
