import DialogContent from "@mui/material/DialogContent";
import { makeStyles } from "@mui/styles";

import { type Contract } from "../../../../../types";
import { type AttributionGroupWithRef } from "../../../../CloudAnalytics/attributionGroups/types";
import AttributionGroupSelectionField from "./AttributionGroupSelectionField";
import ContractSelectionField from "./ContractSelectionField";
import NewPlanNameField from "./NewPlanNameField";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  newFromCsvWrapper: {
    marginTop: 20,
  },
  uploadTextField: {
    marginTop: 20,
  },
  nameInput: {
    marginBottom: 32,
  },
  InputAndIcon: {
    display: "flex",
    alignItems: "end",
    width: "100%",
    padding: 14,
    paddingLeft: 0,
  },
  fileInputTxt: {
    marginLeft: 10,
  },
}));

export type RampDialogContentProps = {
  contractList: Contract[];
  selectedContract: Contract | null;
  saveSelectedContract: (string) => void;
  name: string;
  onPlanNameChange: (string) => void;
  attributionGroupsList: AttributionGroupWithRef[];
  onAttributionGroupChange: (string) => void;
  selectedAttributionGroup: AttributionGroupWithRef | null;
  showNameError: boolean;
  nameErrorMessage?: string;
  customerId: string;
};
export const RampDialogContent = ({
  contractList,
  selectedContract,
  saveSelectedContract,
  name,
  onPlanNameChange,
  attributionGroupsList,
  onAttributionGroupChange,
  selectedAttributionGroup,
  showNameError,
  nameErrorMessage,
  customerId,
}: RampDialogContentProps) => {
  const classes = useStyle();

  return (
    <DialogContent className={classes.root} data-cy="dialog-content" data-testid="dialog-content">
      {contractList.length ? (
        <>
          <NewPlanNameField
            name={name}
            showNameError={showNameError}
            nameErrorMessage={nameErrorMessage}
            onPlanNameChange={onPlanNameChange}
          />
          <ContractSelectionField contractList={contractList} saveSelectedContract={saveSelectedContract} />
          <AttributionGroupSelectionField
            attributionGroupsList={attributionGroupsList}
            onChange={onAttributionGroupChange}
            selectedAttributionGroup={selectedAttributionGroup}
            customerId={customerId}
            cloudProvider={selectedContract?.type}
          />
        </>
      ) : (
        "No matching contracts available."
      )}
    </DialogContent>
  );
};
