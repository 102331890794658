import { useCallback, useEffect, useState } from "react";

import { CustomerModel, type EntityModel } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, type ModelRef, type QueryModel } from "@doitintl/models-firestore";

import { useCustomerEntities } from "../../Context/customer/EntitiesContext";
import { consoleErrorWithSentry } from "../../utils";

export type CustomerData = ModelRef<CustomerModel>;

export const useCustomer = (
  customerId: string | undefined | null,
  entityId: string | undefined | null,
  { includingDisabled }: { includingDisabled?: boolean } = {}
) => {
  const [customer, setCustomer] = useState<CustomerData | null>(null);
  const refineQuery = useCallback(
    (query: QueryModel<EntityModel>) => (includingDisabled ? query : query.where("active", "==", true)),
    [includingDisabled]
  );
  const { entities } = useCustomerEntities(customer?.ref.id, refineQuery);
  const [entity, setEntity] = useState<ModelIdRef<EntityModel> | null>();

  useEffect(() => {
    if (!customerId) {
      return;
    }
    getCollection(CustomerModel)
      .doc(customerId)
      .get()
      .then((docSnap) => {
        const data = docSnap.asModelData();
        if (data) {
          setCustomer({
            ref: docSnap.modelRef,
            ...data,
          });
        } else {
          setCustomer(null);
        }
      })
      .catch(consoleErrorWithSentry);
  }, [customerId]);

  useEffect(() => {
    if (entityId) {
      for (const entity of entities) {
        if (entity.id === entityId) {
          setEntity(entity);
          return;
        }
      }
      setEntity(null);
    } else if (entities.length === 1) {
      setEntity(entities[0]);
    }
  }, [entities, entityId]);

  return { customer, setCustomer, entities, entity, setEntity };
};
