import { useEffect, useMemo } from "react";

import { useApiContext } from "../../api/context";
import { Guard } from "../../Components/Guard";
import { PremiumFeatureCard } from "../../Components/Trial/TrialPremiumFeatureCard";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { type SpotScalingPageId } from "../../Navigation/config/pages";
import mixpanel from "../../utils/mixpanel";
import { getPremiumFeatureCardText } from "../CloudAnalytics/utilities";
import { Apply } from "./Apply/Apply";
import { AsgsOverview } from "./AsgsOverview/AsgsOverview";
import { useCountConnectedAccounts } from "./hooks";
import { NoAccess } from "./NoAccess";
import { SpotScalingApi } from "./spotScalingApi";

type SpotScalingProps = {
  pageId: SpotScalingPageId;
};

export const SpotScaling = ({ pageId }: SpotScalingProps) => {
  const api = useApiContext();
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext();
  const [numConnectedAccounts, numAccountsWithSpotScaling] = useCountConnectedAccounts();
  const spotScalingApi: SpotScalingApi = useMemo(
    () => new SpotScalingApi(api, customerOrPresentationModeCustomer.id),
    [api, customerOrPresentationModeCustomer]
  );

  const premiumFeatureCardText = useMemo(() => getPremiumFeatureCardText("spotScaling"), []);

  useEffect(() => {
    mixpanel.track("console.savings.spot-scaling");
    return () => {};
  }, []);

  return (
    <Guard isAllowed={numConnectedAccounts > 0 && numAccountsWithSpotScaling > 0} fallback={<NoAccess />}>
      {isCustomerInPresentationMode(customer) && premiumFeatureCardText && (
        <PremiumFeatureCard
          title={premiumFeatureCardText.title}
          detail={premiumFeatureCardText.details}
          tryFor={premiumFeatureCardText.tryFor}
        />
      )}
      {pageId === "spot-scaling" && <AsgsOverview spotScalingApi={spotScalingApi} />}
      {pageId === "spot-scaling:apply" && <Apply spotScalingApi={spotScalingApi} />}
    </Guard>
  );
};
