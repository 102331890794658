import { useCallback } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

import { consoleErrorWithSentry } from "../../utils";

function useTroubleshootRedirect(troubleshootUrl: string | null) {
  const [whitelists, whitelistsLoading, whitelistsError] = useDocumentDataOnce(
    getCollection(AppModel).doc("whitelists")
  );

  return useCallback<() => void>(() => {
    if (whitelistsError) {
      consoleErrorWithSentry(whitelistsError);
      return;
    }

    if (!troubleshootUrl || whitelistsLoading || !whitelists?.troubleshootRedirects) {
      return;
    }

    const redirectDomain = new URL(troubleshootUrl).hostname;
    if (!whitelists.troubleshootRedirects.domains.includes(redirectDomain)) {
      return;
    }

    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: troubleshootUrl,
    }).click();
  }, [troubleshootUrl, whitelists?.troubleshootRedirects, whitelistsError, whitelistsLoading]);
}

export default useTroubleshootRedirect;
