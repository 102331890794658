import { type SkuData } from "@doitintl/cmp-models";

export const skus: SkuData[] = [
  {
    label: "Add-On Instance - Customer Hosted",
    monthlyListPrice: 2000,
    googleSku: "14EB-3C03-C96B",
  },
  {
    label: "Add-On Instance - Looker Hosted",
    monthlyListPrice: 2000,
    googleSku: "95C4-0DFE-56A9",
  },
  {
    label: "Add-On White Label - Customer Hosted",
    monthlyListPrice: 2000,
    googleSku: "",
  },
  {
    label: "Add-On White Label - Looker Hosted",
    monthlyListPrice: 2000,
    googleSku: "CAC5-9A71-76DA",
  },
  {
    label: "Client License (Powered by Looker) - Customer Hosted",
    monthlyListPrice: 6000,
    googleSku: "",
  },
  {
    label: "Client License (Powered by Looker) - Looker Hosted",
    monthlyListPrice: 6000,
    googleSku: "2259-242C-29B2",
  },
  {
    label: "Developer User - Customer Hosted",
    monthlyListPrice: 125,
    googleSku: "6345-F712-53F4",
  },
  {
    label: "Developer User - Looker Hosted",
    monthlyListPrice: 125,
    googleSku: "574C-D74A-812D",
  },
  {
    label: "Platform Advanced (Powered by Looker) - Customer Hosted",
    monthlyListPrice: 10000,
    googleSku: "",
  },
  {
    label: "Platform Advanced (Powered by Looker) - Looker Hosted",
    monthlyListPrice: 10000,
    googleSku: "9B9F-E196-B115",
  },
  {
    label: "Platform Advanced - Customer Hosted",
    monthlyListPrice: 10000,
    googleSku: "0451-099B-B7A3",
  },
  {
    label: "Platform Advanced - Looker Hosted",
    monthlyListPrice: 10000,
    googleSku: "05E6-5EE9-8BB9",
  },
  {
    label: "Platform Elite (Powered by Looker) - Customer Hosted",
    monthlyListPrice: 15000,
    googleSku: "",
  },
  {
    label: "Platform Elite (Powered by Looker) - Looker Hosted",
    monthlyListPrice: 15000,
    googleSku: "2507-C42C-125D",
  },
  {
    label: "Platform Elite - Customer Hosted",
    monthlyListPrice: 15000,
    googleSku: "6302-6177-4056",
  },
  {
    label: "Platform Elite - Looker Hosted",
    monthlyListPrice: 15000,
    googleSku: "C1EA-A227-751B",
  },
  {
    label: "Platform Standard (Powered by Looker) - Customer Hosted",
    monthlyListPrice: 5000,
    googleSku: "",
  },
  {
    label: "Platform Standard (Powered by Looker) - Looker Hosted",
    monthlyListPrice: 5000,
    googleSku: "3B83-547A-2FEA",
  },
  {
    label: "Platform Standard - Customer Hosted",
    monthlyListPrice: 5000,
    googleSku: "3EC9-91FD-A743",
  },
  {
    label: "Platform Standard - Looker Hosted",
    monthlyListPrice: 5000,
    googleSku: "CCFD-3FC9-1D60",
  },
  {
    label: "Standard User (Powered by Looker) - Customer Hosted",
    monthlyListPrice: 60,
    googleSku: "",
  },
  {
    label: "Standard User (Powered by Looker) - Looker Hosted",
    monthlyListPrice: 60,
    googleSku: "6F10-64A7-583E",
  },
  {
    label: "Standard User - Customer Hosted",
    monthlyListPrice: 60,
    googleSku: "A48D-574D-5119",
  },
  {
    label: "Standard User - Looker Hosted",
    monthlyListPrice: 60,
    googleSku: "CD68-9FF4-598D",
  },
  {
    label: "Viewer User (Powered by Looker) - Customer Hosted",
    monthlyListPrice: 30,
    googleSku: "",
  },
  {
    label: "Viewer User (Powered by Looker) - Looker Hosted",
    monthlyListPrice: 30,
    googleSku: "D84A-602A-0BA6",
  },
  {
    label: "Viewer User - Customer Hosted",
    monthlyListPrice: 30,
    googleSku: "1E42-2823-09F0",
  },
  {
    label: "Viewer User - Looker Hosted",
    monthlyListPrice: 30,
    googleSku: "B175-F8E5-3B5C",
  },
  {
    label: "Elite System Activity",
    monthlyListPrice: 30,
    googleSku: "231E-9A10-1FBA",
  },
  {
    label: "Looker (Google Cloud core) - Developer User",
    monthlyListPrice: 125,
    googleSku: "7CE2-9C5D-E9F4",
  },
  {
    label: "Looker (Google Cloud core) - Standard User",
    monthlyListPrice: 60,
    googleSku: "25F9-B190-39DB",
  },
  {
    label: "Looker (Google Cloud core) - Viewer User",
    monthlyListPrice: 30,
    googleSku: "DB8F-28B5-83A0",
  },
];
