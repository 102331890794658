import { type AxiosInstance } from "axios";

import { globalURLs } from "../../assets/urls";
import mixpanel from "../../utils/mixpanel";
import { perkPathByType, perkTitleByType } from "./helpers";
import { type RegisterInterestEmail, type RegisterInterestTicket } from "./types";

export class PerksApi {
  private readonly apiPrefix: string;

  private readonly ticketsApi: string;

  constructor(
    private readonly api: AxiosInstance,
    private readonly customerId: string
  ) {
    this.api = api;
    this.customerId = customerId;
    this.apiPrefix = `/v1/customers/${this.customerId}`;
    this.ticketsApi = `/support/customers/${this.customerId}/tickets`;
  }

  public async registerInterestEmail(data: RegisterInterestEmail) {
    mixpanel.track("perks.request", { name: data.perkName });

    return this.api.post(`${this.apiPrefix}/perks/register-interest-email`, data);
  }

  public async registerInterestTicket(data: RegisterInterestTicket) {
    mixpanel.track("perks.request", { name: data.perkName });

    const description = data.isDoitEmployee
      ? `DoiT employee requesting ${perkTitleByType[data.perkType]} ${data.perkName} on behalf of ${data.domain}.`
      : `${data.domain} requesting ${perkTitleByType[data.perkType]} ${data.perkName}.`;

    const perkLink = `${globalURLs.CMP_HOME}/customers/${this.customerId}/${perkPathByType[data.perkType]}/${
      data.perkId
    }`;

    const formData = new FormData();
    formData.append("perkType", data.perkType);
    formData.append("perkName", data.perkName ?? "");
    formData.append("subject", description);
    formData.append("description", `${description}\nMore information is available at ${perkLink}.`);
    formData.append("platformField", data.platform);
    formData.append("productField", `cmp__perks`);
    data.tags.forEach((tag) => {
      formData.append("tags", tag);
    });
    formData.append("priority", "low");
    formData.append("userEmail", data.userEmail);
    formData.append("accountManagersEmail", data.accountManager ?? "");
    formData.append("solutionType", data.solutionType ?? "");
    formData.append("solutionOffer", data.solutionOffer ?? "");
    formData.append("customerSolvePlan", data.customerSolvePlan ?? "");
    formData.append("actionRequired", data.actionRequired ?? "");
    formData.append("dateResponseRequired", data.dateResponseRequired ?? "");

    return this.api.request({
      method: "post",
      url: this.ticketsApi,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  public async getTicketById(perkName: string | undefined, ticketId: number) {
    mixpanel.track("perks.open", {
      name: perkName,
    });
    return this.api.get(`${this.ticketsApi}/${ticketId}`);
  }
}
