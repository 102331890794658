import { highchartColors } from "../../../../../cmpBaseColors";

// Configuration of one type of column data
export type DisplayConfig = {
  colors: {
    light: {
      totalOnDemandSpend: string;
      onDemandSpend: string;
    };
    dark: {
      totalOnDemandSpend: string;
      onDemandSpend: string;
    };
  };
  legends: {
    totalOnDemandSpend: string;
    onDemandSpend: string;
  };
};

export const chartSavingColors = {
  light: {
    totalOnDemandSpend: highchartColors.light[0],
    onDemandSpend: highchartColors.light[1],
  },
  dark: {
    totalOnDemandSpend: highchartColors.dark[0],
    onDemandSpend: highchartColors.dark[1],
  },
};

// Mapping between the Flexsave cloud type and the chart colors and texts
export const displayConfigMapping: DisplayConfig = {
  colors: chartSavingColors,
  legends: {
    totalOnDemandSpend: "On-Demand cost without Flexsave",
    onDemandSpend: "Cost after Flexsave",
  },
};
