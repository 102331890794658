import { type BillingStandaloneOnboardingAWSModel, SaaSConsoleType } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { UsefulLinks } from "../Common/UsefulLinks";
import { BillingPermissions } from "./BillingPermissions";

type Props = {
  accountId: string;
  onboarding?: BillingStandaloneOnboardingAWSModel;
  bucket: string;
  setBucket: (s3Bucket: string) => void;
};

const SaaSOnboardingAWSContent = ({ onboarding, bucket, setBucket, accountId }: Props) => (
  <Stack spacing={4} pb={8}>
    <BillingPermissions accountId={accountId} bucket={bucket} setBucket={setBucket} onboarding={onboarding} />
    <UsefulLinks cloud={SaaSConsoleType.AWS} />
  </Stack>
);

export default SaaSOnboardingAWSContent;
