import { useCallback, useEffect, useMemo, useState } from "react";

import { type ContractSupport, type IntegrationModelCloudhealthPricebooksModel } from "@doitintl/cmp-models";
import { Box, Button, Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import noop from "lodash/noop";

import { useContractFormContext } from "../../ContractsFormContext";
import AccountsList from "./AccountsList";
import { type supportMapObj } from "./types";
import payerAccountsHooks from "./usePayerAccountsList";
import { newAccountBox, payerAccountComparator } from "./utils";

const AwsSupportStep = () => {
  const { state, setState } = useContractFormContext();
  const theme = useTheme();
  const [contractSupportCopy, setContractSupportCopy] = useState<supportMapObj>({});
  const allPayerAccounts = payerAccountsHooks.usePayerAccountsList();

  useEffect(() => {
    setContractSupportCopy(
      state.support === null || Object.keys(state.support).length === 0 ? { ...newAccountBox } : { ...state.support }
    );
  }, [state.support]);

  const handleChangeSupport = (supportAccounts) => {
    setState((prevState) => ({ ...prevState, support: supportAccounts }));
  };

  const updateStepSupportObj = (updatedSupportObj: supportMapObj, indexToUpdate: number): void => {
    const [mpaId, payerAccount] = Object.entries(updatedSupportObj).flat(1) as [string, ContractSupport];

    handleChangeSupport(
      Object.fromEntries(
        Object.entries(contractSupportCopy).map((entry, index) =>
          index === indexToUpdate ? [mpaId, payerAccount] : entry
        )
      )
    );
  };

  const onAddPayerAccount = () => {
    handleChangeSupport({ ...state.support, ...newAccountBox });
  };

  const onChangeSelectedPricebooks = useCallback(
    (selectedPricebooks) => {
      setState({ ...state, selectedPricebooks });
    },
    [setState, state]
  );

  const handleDelete = (indexToDelete: number): void => {
    handleChangeSupport(
      Object.entries(contractSupportCopy).reduce((newSupport, [mpaId, payerAccount], index) => {
        if (index !== indexToDelete) {
          newSupport[mpaId] = payerAccount;
        } else {
          onChangeSelectedPricebooks({ ...state.selectedPricebooks, [mpaId]: null });
        }
        return newSupport;
      }, {})
    );
  };

  const changeSelectedPricebooks = useCallback(
    (selectedPricebook: IntegrationModelCloudhealthPricebooksModel | null, mpaId) => {
      onChangeSelectedPricebooks({ ...state.selectedPricebooks, [mpaId]: selectedPricebook?.id ?? null });
    },
    [onChangeSelectedPricebooks, state.selectedPricebooks]
  );

  const existsInSupport = useCallback(
    (mpaId: string): boolean => contractSupportCopy[mpaId] !== undefined,
    [contractSupportCopy]
  );

  const sortedPayerList = useMemo(() => Array.from(allPayerAccounts).sort(payerAccountComparator), [allPayerAccounts]);

  const disableAddPayer = Object.keys(contractSupportCopy).some((key) => key === "");

  const disableNext = useCallback(() => {
    setState((prevState) => ({ ...prevState, disableNextFromAwsSupport: true }));
  }, [setState]);

  return (
    allPayerAccounts.length !== 0 && (
      <Container maxWidth="sm">
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Box data-testid="aws-contract-support-step" data-cy="aws-contract-support-step" sx={{ width: "100%" }}>
            <AccountsList
              support={contractSupportCopy}
              payerList={sortedPayerList}
              updateStepSupportObj={updateStepSupportObj}
              handleDelete={handleDelete}
              contractSupportErrorBool={state.errors.support}
              onEditDone={noop}
              inEdit={disableNext}
              existsInSupport={existsInSupport}
              onChangeSelectedPricebooks={changeSelectedPricebooks}
              selectedPricebooks={state.selectedPricebooks}
              disabled={state.isEditForbidden}
            />
            <Button
              onClick={onAddPayerAccount}
              sx={{ marginTop: theme.spacing(3) }}
              data-testid="aws-contract-support-add-payer-btn"
              disabled={disableAddPayer || state.isEditForbidden}
            >
              Add payer account
            </Button>
          </Box>
        </Grid>
      </Container>
    )
  );
};

export default AwsSupportStep;
