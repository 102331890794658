import { useHistory } from "react-router";
import { Button } from "@mui/material";

import { useCustomerContext } from "../../../../../Context/CustomerContext";

export const DetailedReportButton = () => {
  const { customer } = useCustomerContext();
  const history = useHistory();
  const onButtonClick = () => {
    history.push(`./analytics/reports/wFnSgar3lERovMD6FBBp`);
  };

  // Check if customer is Saas GCP
  if (customer.enabledFlexsave?.GCP) {
    return (
      <Button variant="text" color="primary" sx={{ mr: 4 }} onClick={onButtonClick}>
        View detailed report
      </Button>
    );
  }
  return null;
};
