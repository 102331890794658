import { useCallback, useMemo } from "react";

import { type CustomerModelPublicDashboardsModel } from "@doitintl/cmp-models";

import { CACHE_DASHBOARD_ID } from "../../../Components/hooks/useReportCache";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { type Dashboard, useDashboardsContext } from "../../../Context/DashboardContext";
import { useUserContext } from "../../../Context/UserContext";

export type DashboardOperations = {
  allowToEdit?: boolean;
  allowToCustomizeLayout?: boolean;
  allowToDelete?: boolean;
  allowToRemove?: boolean;
  allowToAddWidgets?: boolean;
};

export function useDashboardTypes() {
  const { token } = useAuthContext({ mustHaveUser: true });
  const { currentDashboard } = useCurrentDashboardContext();
  const { userRoles } = useUserContext();

  const { dashboards, publicDashboards, attachDashboard, detachDashboardByName } = useDashboardsContext();

  // Filter out the cache dashboard or hidden dashboards
  const dashboardsWithoutHidden = useMemo(
    () => dashboards.filter((dashboard) => !dashboard.hidden && dashboard.id !== CACHE_DASHBOARD_ID),
    [dashboards]
  );

  const allowedPublicOrganizationDashboard = useMemo(() => {
    const isUserHasPermissionsToDashboard = (dashboard: CustomerModelPublicDashboardsModel) => {
      if (dashboard.requiredPermissions) {
        return dashboard.requiredPermissions.every((permission) => userRoles?.permissions.has(permission));
      }
      return true;
    };

    return publicDashboards
      .filter(isUserHasPermissionsToDashboard)
      .filter((dashboard) => dashboard.id !== CACHE_DASHBOARD_ID);
  }, [publicDashboards, userRoles?.permissions]);

  const dashboardsByTypes = useMemo(() => {
    const preset = dashboardsWithoutHidden.filter((dashboard) => dashboard.dashboardType);
    const user = dashboardsWithoutHidden.filter(
      (dashboard) => !dashboard.isPublic || (dashboard?.isPublic && dashboard.ownerId === token.claims.user_id)
    );

    const unselectedOrganization: Dashboard[] = [];
    const selectedOrganization: Dashboard[] = [];

    allowedPublicOrganizationDashboard.forEach((publicDashboard) => {
      if (publicDashboard.dashboardType || user.some((dashboard) => dashboard.name === publicDashboard.name)) {
        return;
      }
      const userHasDashboard = dashboardsWithoutHidden.some((dashboard) => dashboard.name === publicDashboard.name);
      if (userHasDashboard) {
        selectedOrganization.push(publicDashboard as Dashboard);
      } else {
        unselectedOrganization.push(publicDashboard as Dashboard);
      }
    });

    return {
      preset,
      user,
      unselectedOrganization,
      selectedOrganization,
      allOrganizations: [...selectedOrganization, ...unselectedOrganization],
    };
  }, [dashboardsWithoutHidden, allowedPublicOrganizationDashboard, token.claims.user_id]);

  const { preset, user, allOrganizations, selectedOrganization, unselectedOrganization } = dashboardsByTypes;

  const updateOrganizations = useCallback(
    async (newSelectedDashboards: Dashboard[]) => {
      const dashboardsArrayContainsId = (dashboards: Dashboard[], id: string) =>
        dashboards.some((dashboard) => dashboard.id === id);

      const promises: Promise<void | boolean>[] = [];
      allOrganizations.forEach((dashboard) => {
        if (dashboardsArrayContainsId(newSelectedDashboards, dashboard.id)) {
          if (dashboardsArrayContainsId(selectedOrganization, dashboard.id)) {
            return;
          }
          promises.push(attachDashboard(dashboard.id));
        } else {
          if (dashboardsArrayContainsId(unselectedOrganization, dashboard.id)) {
            return;
          }
          promises.push(detachDashboardByName(dashboard.id));
        }
      });
      return Promise.all(promises);
    },
    [allOrganizations, attachDashboard, detachDashboardByName, selectedOrganization, unselectedOrganization]
  );

  const currentDashboardOperations = useMemo(() => {
    const currentDashboardIn = (dashboards: Dashboard[]) =>
      dashboards.some((dashboard) => dashboard.id === currentDashboard?.id);
    if (currentDashboardIn(preset)) {
      return {};
    } else if (currentDashboardIn(user)) {
      return {
        allowToEdit: true,
        allowToCustomizeLayout: true,
        allowToDelete: true,
        allowToAddWidgets: true,
      };
    } else {
      return {
        allowToCustomizeLayout: currentDashboard?.allowToEdit,
        allowToAddWidgets: currentDashboard?.allowToEdit,
        allowToRemove: true,
      };
    }
  }, [currentDashboard, preset, user]);

  return { ...dashboardsByTypes, currentDashboardOperations, updateOrganizations, currentDashboard };
}
