import { type BasicFilter, type ReportOptionalField } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { type CustomTimeRange } from "../../../types";
import { optionalMetadataTypes } from "../utilities";
import { type ConfigField } from "./types";
import { defaultFilter } from "./utils";

export const createRowsFilters = (
  fields: ConfigField[]
): { rows: string[] | null; filters: BasicFilter[]; optional: ReportOptionalField[] | null } => {
  const rows: string[] = [];
  const filters: BasicFilter[] = [];
  const optional: ReportOptionalField[] = [];
  fields.forEach((field) => {
    let row;
    const { groupBy, ...configField } = field;
    const { type, ...config } = configField;
    config.id = `${type}:${field.id}`;
    if (groupBy) {
      row = config.id;
    }
    if (configField.regexp) {
      configField.values = [];
    }
    if (row) {
      rows.push(row);
    }
    const filter = { ...defaultFilter, ...config };
    filters.push(filter);
    if (field.key && type && optionalMetadataTypes.includes(type)) {
      optional.push({
        key: field.key,
        type,
      });
    }
  });
  return {
    rows,
    filters,
    optional,
  };
};

export const handleCustomTimeRange = (customTimeRange, minYear = 2018): CustomTimeRange => {
  const isValidDates = customTimeRange?.from?.isValid && customTimeRange?.to?.isValid;
  if (!isValidDates) {
    throw new Error("Invalid custom time range dates");
  }
  const isMinDate = customTimeRange.from > DateTime.utc(minYear, 1, 1);
  if (isMinDate && customTimeRange.from < customTimeRange.to) {
    customTimeRange = {
      from: customTimeRange.from.toJSDate(),
      to: customTimeRange.to.toJSDate(),
    };
    return customTimeRange;
  }
  throw new Error("Invalid custom time range dates");
};
