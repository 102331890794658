import React from "react";

import { deliveryFrequencies } from "@doitintl/cmp-models/src/Notifications";
import { Autocomplete, TextField } from "@mui/material";
import { useField } from "formik";

import { RequiredLabel, useSetPartialValues } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function DeliveryFrequencyInput({ disabled }: SubscriptionInputProps) {
  const [selectedFrequency, meta] = useField<SubscriptionFormValues["deliveryFrequency"]>("deliveryFrequency");
  const setPartialValues = useSetPartialValues();

  return (
    <Autocomplete
      options={deliveryFrequencies}
      disabled={disabled}
      disableClearable={true}
      value={selectedFrequency.value}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <li {...props} key={option}>
          {option}
        </li>
      )}
      onChange={(_event, newValue) => {
        if (!newValue) {
          return;
        }

        // Set the custom values to default on change
        if (newValue === "Custom") {
          setPartialValues({
            deliveryFrequency: newValue,
            customPeriodAmount: 1,
            customPeriodUnit: "Month",
          });
        } else {
          setPartialValues({
            deliveryFrequency: newValue,
            customPeriodAmount: null,
            customPeriodUnit: null,
          });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={RequiredLabel("Delivery frequency")}
          variant="outlined"
          error={!!meta.error}
          fullWidth
        />
      )}
      fullWidth
    />
  );
}
