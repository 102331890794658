import React, { useCallback, useContext, useMemo, useState } from "react";

import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { Box, Card, Typography } from "@mui/material";

import { useCurrentDashboardContext } from "../../../../../Context/CurrentDashboardContext";
import { Rows } from "../Rows";
import { useWidgetItemsWithRows, type WidgetItem, type WidgetWidth } from "../useWidgetItemsWithRows";
import { WidgetsContext } from "../WidgetsGrid";
import { normalizeRowWidths } from "../widgetUtils";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation = {
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
  sideEffects: defaultDropAnimationSideEffects({
    className: {
      active: "dragging",
    },
  }),
};

export type HoverItem = {
  id: string;
  position: "start" | "end";
};

export function DraggableWidgetsGrid() {
  const [widgets, totalWidth, disableEditing] = useContext(WidgetsContext);

  const { updateWidgets, updateHeights, currentDashboard } = useCurrentDashboardContext();
  const heights = useMemo(() => currentDashboard?.heights ?? [], [currentDashboard?.heights]);

  const [activeId, setActiveId] = useState(null);
  const [hoverItem, setHoverItem] = useState<HoverItem | undefined>(undefined);

  const widgetsWithNewWidth = useMemo(
    () =>
      widgets.map((item) => {
        if (item.width === undefined) {
          return {
            ...item,
            width: item.cardWidth && item.cardWidth !== 3 ? ((item.cardWidth / 4) as WidgetWidth) : 1,
          } satisfies WidgetItem;
        }

        if (item.width === 0) {
          return {
            ...item,
            width: 1,
          } satisfies WidgetItem;
        }
        return item as WidgetItem;
      }),
    [widgets]
  );

  const [rows, getDropLocation, getNewOrder] = useWidgetItemsWithRows(widgetsWithNewWidth);

  const normalizedRows = useMemo(() => {
    const newRows = structuredClone(rows);
    newRows.forEach(normalizeRowWidths);
    return newRows;
  }, [rows]);

  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragMove(event) {
    const droppableRect = event.over?.rect;
    const activeRect = event.active.rect.current.translated;

    if (droppableRect && activeRect) {
      // calculate the relative mouse position by subtracting the droppable area's top-left corner
      const relativeX = activeRect.left - droppableRect.left;

      // determine if the mouse is in the first or second half of the droppable
      const isInFirstHalfHorizontal = relativeX < droppableRect.width / 2;
      const hoverPosition = isInFirstHalfHorizontal ? "start" : "end";

      const dropLocation = getDropLocation(activeId, event.over?.id, hoverPosition);
      if (!dropLocation) {
        setHoverItem(undefined);
        return;
      }

      setHoverItem({
        id: event.over?.id,
        position: hoverPosition,
      });
    }
  }

  const handleVerticalResize = useCallback(
    (rowIndex: number, height: number) => {
      const newHeights = [...heights];
      newHeights[rowIndex] = height;
      updateHeights(newHeights);
    },
    [heights, updateHeights]
  );

  const handleHorizontalResize = useCallback(
    (rowIndex: number, width: [WidgetWidth, WidgetWidth]) => {
      const newRows = structuredClone(rows);

      newRows[rowIndex].items[0].width = width[0];
      newRows[rowIndex].items[1].width = width[1];

      updateWidgets(newRows.flatMap((row) => row.items));
    },
    [rows, updateWidgets]
  );

  const handleDragEnd = useCallback(() => {
    const overId = hoverItem?.id;
    document.documentElement.style.cursor = "auto";

    if (overId) {
      const dropLocation = getDropLocation(activeId, overId, hoverItem?.position);

      // need to adjust all the row heights in case of a new row
      if (dropLocation?.newRow) {
        const newHeights = [...heights];
        newHeights.splice(dropLocation.rowIndex, 0, null);
        updateHeights(newHeights);
      }

      if (dropLocation) {
        const newOrder = getNewOrder(activeId, dropLocation);
        setHoverItem(undefined);
        updateWidgets(newOrder);
      }
    }
    setActiveId(null);
  }, [activeId, getDropLocation, getNewOrder, heights, hoverItem, updateHeights, updateWidgets]);

  function handleDragStart({ active }) {
    setActiveId(active.id);
    document.documentElement.style.cursor = "grabbing";
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      onDragMove={handleDragMove}
      sensors={sensors}
      collisionDetection={pointerWithin}
      measuring={measuring}
    >
      <Rows
        disableEditing={disableEditing}
        dragging={Boolean(activeId)}
        rows={normalizedRows}
        heights={heights}
        rowWidth={totalWidth}
        hoverItem={hoverItem}
        onHorizontalResized={handleHorizontalResize}
        onVerticalResized={handleVerticalResize}
      />

      <DragOverlay dropAnimation={dropAnimation}>{activeId ? <CardOverlay /> : null}</DragOverlay>
    </DndContext>
  );
}

function CardOverlay() {
  return (
    <Card>
      <Box p={2}>
        <Typography variant="subtitle1">Moving widget</Typography>
      </Box>
    </Card>
  );
}
