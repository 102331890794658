import { type JSX } from "react";

import { Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";

import { useFullScreen } from "../../../utils/dialog";

type Props = {
  menu: JSX.Element;
  title: JSX.Element;
  widgetActions: JSX.Element;
  operations: JSX.Element;
  children: JSX.Element;
};

export function DashboardLayout({ menu, title, widgetActions, operations, children }: Props) {
  const { isMobile } = useFullScreen();

  const heightWithoutHeader = "calc(100vh - 105px)";

  if (isMobile) {
    return (
      <Paper elevation={0}>
        <Stack
          direction="column"
          justifyContent={{
            sm: "center",
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" pt={2} px={2} mb={2}>
            {menu}
            {operations}
          </Stack>

          <Box
            p={{
              xs: 0,
              sm: 2,
            }}
          >
            {children}
          </Box>
        </Stack>
      </Paper>
    );
  }

  return (
    <Stack direction="row">
      <Box width={208} maxHeight={heightWithoutHeader} sx={{ overflowY: "auto" }}>
        {menu}
      </Box>
      <Paper
        variant="outlined"
        sx={{ borderRadius: 0, borderTop: 0, borderRight: 0, borderBottom: 0, minHeight: heightWithoutHeader }}
      >
        <Stack
          direction="column"
          px={2}
          maxHeight={heightWithoutHeader}
          width="calc(100vw - 208px)"
          sx={{ overflowY: "scroll" }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" py={2}>
            {title}
            <Stack direction="row" alignItems="center">
              {widgetActions}
              {operations}
            </Stack>
          </Stack>
          {children}
        </Stack>
      </Paper>
    </Stack>
  );
}
