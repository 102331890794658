import { DateTime } from "luxon";

import { validateDatetime } from "../../../utils/dateType";
import { type ActiveFlexsaveSavings } from "../types";

const getDateTimeByFormat = (dateString: string, formats: string[]) => {
  for (const format of formats) {
    const date = DateTime.fromFormat(dateString, format, { zone: "utc" });
    if (date.isValid) {
      return validateDatetime(date);
    }
  }

  throw new Error(`Date ${dateString} is not valid for ${formats.join(",")}`);
};

// Usage examples:

// 11_2021
export const getDateTimeByMM_YYYY = (monthYear: string) =>
  getDateTimeByFormat(monthYear, ["M_yyyy", "MM_yyyy"]).startOf("day");

// November 2021
export const getDateTimeByMMMMSpaceYYYY = (dateLabel: string) =>
  getDateTimeByFormat(dateLabel, ["MMMM yyyy"]).startOf("day");

// 2021-08-01
export const getDateTimeByYYYYDashMMDashDD = (dayMonthYear: string) =>
  getDateTimeByFormat(dayMonthYear, ["yyyy-MM-dd"]).startOf("day");

// 11_2021
export const getDateByMM_YYYY = (monthYear: string) => getDateTimeByMM_YYYY(monthYear).toJSDate();

// August 2021
export const getDateByMMMMSpaceYYYY = (dateLabel: string) => getDateTimeByMMMMSpaceYYYY(dateLabel).toJSDate();

// 2021-08-01
export const getDateByYYYYDashMMDashDD = (dayMonthYear: string) =>
  getDateTimeByYYYYDashMMDashDD(dayMonthYear).toJSDate();

export function getMonthLabelFromDate(jsDate: Date): string {
  const dateTime = DateTime.fromJSDate(jsDate, { zone: "utc" });

  return validateDatetime(dateTime).toFormat("MMMM yyyy");
}

export const getLastMonthDateFromMonthLabel = (dateLabel: string) => {
  const datetime = getDateTimeByMMMMSpaceYYYY(dateLabel);

  const lastDayOfNextMonth = datetime.endOf("month").startOf("day");

  const now = DateTime.utc();

  if (lastDayOfNextMonth > now) {
    return now.toJSDate();
  }

  return lastDayOfNextMonth.toJSDate();
};

export function getMonthLabelFromMonthId(monthId: string) {
  const date = getDateByMM_YYYY(monthId);
  return getMonthLabelFromDate(date);
}

export function getMonthName(monthId: string) {
  const date = getDateTimeByMM_YYYY(monthId);
  return date.toFormat("MMMM");
}

export function getPreviousHistoryMonthLabel(monthId: string, offset: number) {
  const date = getDateTimeByMM_YYYY(monthId);
  const historyDate = date.minus({ months: offset }).toJSDate();
  return getMonthLabelFromDate(historyDate);
}

export function getPreviousMonthsStrings(
  currentMonthId: string,
  numberOfMonths: number,
  savings?: ActiveFlexsaveSavings[]
) {
  const allMonths: string[] = [];

  // create history months string for the last n months
  let hasNonZeroValue = false;
  for (let i = numberOfMonths - 1; i >= 0; i--) {
    const currentMonthLabel = getPreviousHistoryMonthLabel(currentMonthId, i);
    if (savings && !hasNonZeroValue) {
      hasNonZeroValue =
        !!savings[0]?.history.get(currentMonthLabel)?.onDemandSpend ||
        !!savings[0]?.history.get(currentMonthLabel)?.savings;
      if (savings.length === 2) {
        hasNonZeroValue =
          !!savings[1]?.history.get(currentMonthLabel)?.onDemandSpend ||
          !!savings[1]?.history.get(currentMonthLabel)?.savings;
      }
    }
    if (!savings || hasNonZeroValue) {
      allMonths.push(currentMonthLabel);
    }
  }
  return allMonths;
}

export const getInvoiceDate = (timeOptIn: DateTime): DateTime => {
  // get the 5th day of the next month
  // get the next business day after the 5th day of the next month
  let nextBusinessDay = timeOptIn.plus({ months: 1 }).set({ day: 5 });
  while (nextBusinessDay.weekday >= 6) {
    // 6 and 7 are Saturday and Sunday
    nextBusinessDay = nextBusinessDay.plus({ days: 1 });
  }
  return nextBusinessDay;
};

export function getWeekdayAndDate(dateString: string): string {
  const date = new Date(dateString);

  const day = date.toLocaleDateString("en-GB", { weekday: "short" });
  const dayOfMonth = date.toLocaleDateString("en-GB", { day: "numeric" });
  const suffix = getNumberSuffix(Number(dayOfMonth));
  return `${day} ${dayOfMonth}${suffix}`;
}

export const getLatestMonths = (currentMonth: string, numberOfLookbackMonths: number): string[] => {
  let currentMonthDate = getDateTimeByMM_YYYY(currentMonth);

  const dates: string[] = [];
  for (let i = 0; i < numberOfLookbackMonths; i++) {
    dates.push(currentMonthDate.toFormat("M_yyyy"));
    currentMonthDate = currentMonthDate.minus({ months: 1 });
  }

  return dates.reverse();
};

export function getLatestDays(numberOfLookBackDays: number): string[] {
  return Array.from({ length: numberOfLookBackDays }, (_, i) => i)
    .reverse()
    .map((i) => DateTime.now().minus({ days: i }).toFormat("yyyy-MM-dd"));
}

export function getNumberSuffix(num: number): string {
  if (num >= 11 && num <= 13) {
    return "th";
  }
  const lastDigit = num % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const getPreviousMonthString = (currentMonth: string): string => {
  const currentMonthDate = getDateByMM_YYYY(currentMonth);
  const previousMonthDate = new Date(currentMonthDate.getFullYear(), currentMonthDate.getMonth() - 1, 1);
  return `${previousMonthDate.getMonth() + 1}_${previousMonthDate.getFullYear()}`;
};

export const getLastHourOfMonthDateByMMMMSpaceYYYY = (dateString: string) => {
  const date = getDateTimeByMMMMSpaceYYYY(dateString);

  try {
    return validateDatetime(date).plus({ months: 1 }).startOf("month").minus({ hours: 1 }).toJSDate();
  } catch (e) {
    throw new Error(`Date ${dateString} is not valid for .fromISO()`);
  }
};

export const checkIfMonthIsAfterFlexsaveEnablement = (monthString: string, timeEnabled: Date): boolean =>
  getLastHourOfMonthDateByMMMMSpaceYYYY(monthString) >= timeEnabled;
