import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip, Typography } from "@mui/material";

type Props = {
  title: string;
};

export default function PayerTooltip({ title }: Props) {
  return (
    <Tooltip title={title} placement="right">
      <Typography component="span">
        <InfoOutlinedIcon color="action" fontSize="small" />
      </Typography>
    </Tooltip>
  );
}
