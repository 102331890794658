import { useMemo } from "react";

import { Alert, Box, Button, Card, CardContent, Portal } from "@mui/material";

import { errors } from "../../strings";
import { type AsgItem } from "../../types";
import { AsgConfiguration } from "../AsgConfiguration/AsgConfiguration";
import { AsgsConfigurationTitles } from "../AsgConfiguration/AsgsConfigurationTitles";
import { useCommonHeights } from "../AsgConfiguration/hooks";

type AsgWithErrorProps = {
  asg: AsgItem;
  notificationContainer: any;
};

type AlertData = {
  message: string;
  docUrl: string;
};

export const AsgWithError = ({ asg, notificationContainer }: AsgWithErrorProps) => {
  const [instanceTypeHeights, setInstanceTypeHeights] = useCommonHeights();
  const [azHeights, setAzHeights] = useCommonHeights();
  const alertData: AlertData = useMemo(() => {
    let message = "";
    let docUrl = "";
    if (asg.spotisizeError) {
      if (!asg.isApplyChangesSupported && asg.spotisizeError.includes(errors.incompatible)) {
        message = asg.spotisizeError.charAt(0).toUpperCase() + asg.spotisizeError.slice(1);
        docUrl = errors.incompatibleDocUrl;
      } else if (asg.spotisizeError.toLowerCase().includes("tag")) {
        message = errors.generalError + asg.spotisizeError.charAt(0).toUpperCase() + asg.spotisizeError.slice(1);
        docUrl = errors.tagsErrorUrl;
      } else {
        message = errors.generalError + asg.spotisizeError.charAt(0).toUpperCase() + asg.spotisizeError.slice(1);
      }
    } else if (asg.updateError) {
      message = errors.updateError + asg.updateError.charAt(0).toUpperCase() + asg.updateError.slice(1);
    }
    return {
      message,
      docUrl,
    };
  }, [asg]);

  return (
    <>
      <Portal container={notificationContainer.current}>
        <Alert
          severity="error"
          action={
            alertData.docUrl ? (
              <Button size="small" color="inherit" target="_blank" href={alertData.docUrl}>
                View docs
              </Button>
            ) : undefined
          }
        >
          {alertData.message}
        </Alert>
      </Portal>
      <Card>
        <CardContent>
          <Box display="flex">
            <AsgsConfigurationTitles instanceTypeHeights={instanceTypeHeights} azHeights={azHeights} />
            <AsgConfiguration
              title="Current Values"
              confKey="current"
              instanceTypeHeights={instanceTypeHeights}
              setInstanceTypeHeights={setInstanceTypeHeights}
              azHeights={azHeights}
              setAzHeights={setAzHeights}
              allowEdit={false}
              configuration={asg.configurations.current}
              asgId={asg.id}
              isApplyChangesSupported={asg.isApplyChangesSupported}
              isCurrent={true}
              isOptimized={false}
              keepUptoDate={{ valueDirty: false, value: false, origValue: false }}
              isError={true}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
