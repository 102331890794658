import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Divider, InputAdornment, List, ListItem, ListItemText, ListSubheader, TextField } from "@mui/material";
import difference from "lodash/difference";
import union from "lodash/union";

import LoadingButton from "../../../../Components/LoadingButton";
import { type SpotScalingApi } from "../../spotScalingApi";
import { type AsgBasicInfo, type AsgConfigChange, type AsgConfigurationSummary, type KeepUptoDate } from "../../types";
import { hasChangeInConfiguration } from "../../Utils/compareUtils";
import { KeepUpToDate } from "../GeneralSettings/KeepUpToDate";
import { DEFAULT_HEIGHT } from "./consts";
import { EditAzAndSubnets } from "./Edit/EditAzAndSubnets";
import { EditInstanceTypes } from "./Edit/EditInstanceTypes";
import { useCalculateCommonHeights, useMaxHeight } from "./hooks";

export type CommonHeights = {
  current: number;
  recommended: number;
};

type AsgConfigurationProps = {
  title: string;
  confKey: string;
  instanceTypeHeights: CommonHeights;
  setInstanceTypeHeights: (instanceTypeHeights: CommonHeights) => void;
  azHeights: CommonHeights;
  setAzHeights: (instanceTypeHeights: CommonHeights) => void;
  configuration: AsgConfigurationSummary;
  allowEdit: boolean;
  onCostChanged?: (cost: number) => void;
  onConfigurationChanged?: (change?: AsgConfigChange) => void;
  spotScalingApi?: SpotScalingApi;
  asgInfo?: AsgBasicInfo;
  keepUptoDate: KeepUptoDate;
  keepUptoDateCallback?: (keepUptoDate: KeepUptoDate) => void;
  actionButtonProps?: {
    text: string;
    loading: boolean;
    disabled: boolean;
    onClick: () => void;
  };
  asgId: string;
  isCurrent: boolean;
  isOptimized: boolean;
  isApplyChangesSupported: boolean;
  isError?: boolean;
};

export const AsgConfiguration = ({
  title,
  confKey,
  instanceTypeHeights,
  setInstanceTypeHeights,
  azHeights,
  setAzHeights,
  configuration,
  allowEdit,
  onCostChanged,
  onConfigurationChanged,
  actionButtonProps,
  isCurrent,
  isOptimized,
  isApplyChangesSupported,
  keepUptoDate,
  keepUptoDateCallback,
  isError,
}: AsgConfigurationProps) => {
  const instanceTypesRef = useRef(null);
  const availabilityZonesRef = useRef(null);

  const [editingInstanceTypes, setEditingInstanceTypes] = useState<boolean>(false);
  const [editingSubnets, setEditingSubnets] = useState<boolean>(false);

  const [onDemandPercent, setOnDemandPercent] = useState<number>(configuration.onDemandPercentageAboveBaseCapacity);
  const [base, setBase] = useState<number>(configuration.onDemandBaseCapacity);

  const [selectedInstanceTypes, setSelectedInstanceTypes] = useState<string[]>(configuration.instanceTypes);
  const allInstanceTypes = useMemo(
    () => union(configuration.excludedInstanceTypes, configuration.instanceTypes),
    [configuration.instanceTypes, configuration.excludedInstanceTypes]
  );

  const [selectedSubnets, setSelectedSubnets] = useState<string[]>(configuration.subnets);
  const allSubnets = useMemo(
    () => union(configuration.excludedSubnets, configuration.subnets),
    [configuration.excludedSubnets, configuration.subnets]
  );

  const handleInstancesChange = useCallback(async (instances?: string[]) => {
    const duringEdit = !instances;
    setEditingInstanceTypes(duringEdit);
    if (!duringEdit) {
      setSelectedInstanceTypes(instances);
    }
  }, []);

  const handleSubnetsChange = useCallback(async (subnets?: string[]) => {
    const duringEdit = !subnets;
    setEditingSubnets(duringEdit);
    if (!duringEdit) {
      setSelectedSubnets(subnets);
    }
  }, []);

  const calculateInstanceHeightsAfterChange = useCalculateCommonHeights(
    instanceTypesRef,
    confKey,
    instanceTypeHeights,
    setInstanceTypeHeights
  );
  const calculateAzHeightsAfterChange = useCalculateCommonHeights(
    availabilityZonesRef,
    confKey,
    azHeights,
    setAzHeights
  );
  const maxInstanceTypeHeight = useMaxHeight(instanceTypeHeights);
  const maxAzHeight = useMaxHeight(azHeights);

  const onChangeSpot = (event) => {
    const value = event.target.value;
    if (value === "" || (value >= 0 && value <= 100)) {
      setOnDemandPercent(100 - event.target.value);
    }
  };

  const onChangeOnDemand = (event) => {
    const value = event.target.value;
    if (value === "" || (value >= 0 && value <= 100)) {
      setOnDemandPercent(parseInt(value));
    }
  };

  const onChangeBase = (event) => {
    const value = parseInt(event.target.value);
    if (value >= 0 && value <= configuration.maxCapacity) {
      setBase(value);
    }
  };

  useEffect(() => {
    const change: AsgConfigChange = {
      onDemandBaseCapacity: base,
      onDemandPercentageAboveBaseCapacity: onDemandPercent,
      includedInstanceTypes: selectedInstanceTypes,
      excludedInstanceTypes: difference(allInstanceTypes, selectedInstanceTypes),
      includedSubnets: selectedSubnets,
      excludedSubnets: difference(allSubnets, selectedSubnets),
    };

    const hasChange = hasChangeInConfiguration(configuration, change);
    onConfigurationChanged?.(hasChange ? change : undefined);
  }, [
    base,
    configuration,
    onConfigurationChanged,
    onCostChanged,
    onDemandPercent,
    selectedInstanceTypes,
    allInstanceTypes,
    selectedSubnets,
    allSubnets,
  ]);

  const ListItemWithText = ({ text }) => (
    <ListItem style={{ height: DEFAULT_HEIGHT }}>
      <ListItemText primary={text} />
    </ListItem>
  );

  const keepUpToDateVisible = useMemo(
    () => isOptimized || !(isOptimized || isCurrent) || isError,
    [isOptimized, isCurrent, isError]
  );

  return (
    <Box display="flex">
      <Box width={500}>
        <List disablePadding dense subheader={<ListSubheader component="div">{title}</ListSubheader>}>
          {allowEdit ? (
            <>
              <ListItem style={{ height: DEFAULT_HEIGHT }}>
                <TextField
                  variant="standard"
                  onChange={onChangeBase}
                  InputProps={{ inputProps: { min: 0, max: configuration.maxCapacity } }}
                  value={base}
                  style={{ width: 66 }}
                  type="number"
                  data-cy="base-on-demand-input"
                />
              </ListItem>
              <ListItem style={{ height: DEFAULT_HEIGHT }}>
                <TextField
                  variant="standard"
                  style={{ width: 66 }}
                  onChange={onChangeOnDemand}
                  value={onDemandPercent}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    inputProps: { min: 0, max: 100 },
                  }}
                  data-cy="on-demand-input"
                />
              </ListItem>
              <ListItem style={{ height: DEFAULT_HEIGHT }}>
                <TextField
                  variant="standard"
                  style={{ width: 66 }}
                  onChange={onChangeSpot}
                  type="number"
                  value={100 - onDemandPercent}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    inputProps: { min: 0, max: 100 },
                  }}
                  data-cy="spot-input"
                />
              </ListItem>
            </>
          ) : (
            <>
              <ListItemWithText text={base > -1 ? base : "N/A"} />
              <ListItemWithText text={`${onDemandPercent}%`} />
              <ListItemWithText text={`${100 - onDemandPercent}%`} />
            </>
          )}
          <ListItem
            alignItems={maxInstanceTypeHeight > DEFAULT_HEIGHT ? "flex-start" : "center"}
            style={{ minHeight: maxInstanceTypeHeight }}
          >
            <Box ref={instanceTypesRef} sx={{ width: "100%" }}>
              <EditInstanceTypes
                allowEdit={allowEdit}
                selectedInstanceTypes={selectedInstanceTypes}
                allInstanceTypes={allInstanceTypes}
                instanceTypesDetails={configuration.instanceTypesDetails}
                onSelectionChange={handleInstancesChange}
                onHeightChange={calculateInstanceHeightsAfterChange}
              />
            </Box>
          </ListItem>

          <ListItem
            alignItems={maxAzHeight > DEFAULT_HEIGHT ? "flex-start" : "center"}
            style={{ minHeight: maxAzHeight }}
          >
            <Box ref={availabilityZonesRef} sx={{ width: "100%" }}>
              <EditAzAndSubnets
                allowEdit={allowEdit}
                selectedSubnets={selectedSubnets}
                allSubnets={allSubnets}
                subnetsDetails={configuration.subnetsDetails}
                onSelectionChange={handleSubnetsChange}
                onHeightChange={calculateAzHeightsAfterChange}
              />
            </Box>
          </ListItem>
          <ListItemWithText text={configuration.desiredCapacity} />
          <ListItemWithText text={configuration.minCapacity} />
          <ListItemWithText text={configuration.maxCapacity} />
          {keepUpToDateVisible && (
            <KeepUpToDate
              configuration={configuration}
              keepUptoDate={keepUptoDate}
              keepUptoDateCallback={keepUptoDateCallback}
              isApplyChangesSupported={isApplyChangesSupported}
            />
          )}
        </List>
        {actionButtonProps && (
          <Box pt={2} display="flex" justifyContent="center">
            <LoadingButton
              loading={actionButtonProps.loading}
              variant="contained"
              color="primary"
              disabled={actionButtonProps.disabled || editingSubnets || editingInstanceTypes}
              onClick={actionButtonProps.onClick}
              mixpanelEventId="spot-scaling.asg-configuration"
            >
              {actionButtonProps.text}
            </LoadingButton>
          </Box>
        )}
      </Box>
      <Box pb={6} pl={1}>
        <Divider orientation="vertical" />
      </Box>
    </Box>
  );
};
