import { Metadata } from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";

import { type AttributionWRef, type MetadataOption } from "../../../types";
import { type AttributionsPayload, getFilteredAttributions } from "../previewReport/utils";
import { type AttributionGroupWithRef } from "./types";

export type filterAttributionGroupsProps = {
  attributions: AttributionWRef[];
  dimensions: MetadataOption[];
  attributionGroups: AttributionGroupWithRef[];
};

export type AttributionGroupsPayload = {
  id: string;
  type: Metadata.ATTRIBUTION_GROUP;
  key: string;
  includeInFilter: boolean;
  attributions: AttributionsPayload[];
  nullFallback?: string;
};

export const buildAttributionGroup = (md: MetadataOption, attributions: AttributionWRef[]) => ({
  id: md.id,
  type: md.data.type,
  key: md.data.key,
  includeInFilter: true,
  attributions: getFilteredAttributions({
    attributionIds: md?.data.values || [],
    attributions,
  }),
});

export const buildAttributionGroupsPayload = ({
  attributions,
  dimensions,
  attributionGroups,
}: filterAttributionGroupsProps): AttributionGroupsPayload[] => {
  let filteredAttributionGroups: AttributionGroupsPayload[] = [];
  const mdAttributionGroups =
    dimensions?.filter((md) => md.data.type === Metadata.ATTRIBUTION_GROUP && md._visible).map((md) => cloneDeep(md)) ||
    [];
  if (mdAttributionGroups.length > 0) {
    attributionGroups.forEach((attrGroup) => {
      const mdAttributionGroup = mdAttributionGroups.find((md) => md.data.key === attrGroup.ref.id);
      if (mdAttributionGroup) {
        mdAttributionGroup.data.values = attrGroup.data.attributions.map((attr) => attr.id);
      }
    });

    filteredAttributionGroups = mdAttributionGroups
      .map((md) => {
        if (!md) {
          return;
        }

        return buildAttributionGroup(md, attributions);
      })
      .filter((attrGroup): attrGroup is AttributionGroupsPayload => !!attrGroup);
  }

  return filteredAttributionGroups || [];
};
