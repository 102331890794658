import { useEffect, useMemo } from "react";

import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  DashboardModel,
  DashboardType,
  type Widget,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import find from "lodash/find";
import findIndex from "lodash/findIndex";

import { getCachingKeys } from "../../utils/cachingKeys";
import { useCustomerContext } from "../CustomerContext";
import { type Dashboard } from "../DashboardContext";

const useCommittedContacts = (enabled: boolean = true) => {
  const { customer } = useCustomerContext();

  const query = useMemo(
    () =>
      enabled
        ? getCollection(DashboardModel)
            .doc("commitment-contracts")
            .collection("commitmentContracts")
            .where("customer", "==", customer.ref)
        : undefined,
    [customer.ref, enabled]
  );

  const [data] = useCollectionData(query, { idField: "id", caching: true, cachingKeys: getCachingKeys(customer.id) });

  return useMemo(() => {
    if (!data) {
      return [[], []];
    }

    const hasActiveCommitments = (c: (typeof data)[number]) => findIndex(c.commitmentPeriods, { ended: false }) > -1;
    const isUpdatedCommitment = (currentCommitment: (typeof data)[number]): boolean =>
      data
        .filter((c) => c.type === currentCommitment.type)
        .every((commitment) => {
          if (!currentCommitment.commitmentPeriods) {
            return false;
          }
          if (!commitment.commitmentPeriods || commitment.id === currentCommitment.id) {
            return true;
          }
          return (
            currentCommitment.commitmentPeriods[0].startDate > commitment.commitmentPeriods[0]?.startDate ||
            commitment.commitmentPeriods[0]?.startDate.toMillis() > Date.now()
          );
        });

    return data.reduce(
      (acc, c) => {
        if (!hasActiveCommitments(c) || !isUpdatedCommitment(c)) {
          return acc;
        }

        if (c.type === AssetTypeAmazonWebServices) {
          acc[0].push(c.id);
        } else if (c.type === AssetTypeGoogleCloud) {
          acc[1].push(c.id);
        }

        return acc;
      },
      [[] as string[], [] as string[]]
    );
  }, [data]);
};

export const useInitGCPLensOrAWSLens = (dashboard: Dashboard | undefined) => {
  const enabled = useMemo(
    () =>
      Boolean(
        dashboard?.dashboardType && [DashboardType.GcpLens, DashboardType.AwsLens].includes(dashboard.dashboardType)
      ),
    [dashboard?.dashboardType]
  );
  const [awsContracts, gcpContacts] = useCommittedContacts(enabled);

  const commitmentContracts = useMemo(() => {
    if (!dashboard?.dashboardType) {
      return undefined;
    }
    return dashboard.dashboardType === DashboardType.AwsLens ? awsContracts : gcpContacts;
  }, [awsContracts, dashboard?.dashboardType, gcpContacts]);

  useEffect(() => {
    if (!enabled || !dashboard) {
      return;
    }

    const add = (commitmentContractId: string) => {
      const name = `commitmentContracts::${commitmentContractId}`;
      const hasItem = find(dashboard.widgets, { name: `commitmentContracts::${commitmentContractId}` });
      if (!hasItem) {
        const commitmentContracts: Widget = {
          name,
        };

        if (dashboard.widgets) {
          dashboard.widgets = [...dashboard.widgets, commitmentContracts];
        } else {
          dashboard.widgets = [commitmentContracts];
        }
      }
    };

    commitmentContracts?.forEach((commitmentContract) => {
      add(commitmentContract);
    });
  }, [commitmentContracts, dashboard, enabled]);
};
