import { useMemo } from "react";

import { AssetTypeDoiTCloudSolveSingleAccelerator, type ContractModel } from "@doitintl/cmp-models";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import DataCouplet from "../../../Components/DataCouplet";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";
import { useContractTypesContext } from "../../ContractsTabs/ContractsTypesContext";
import AcceleratorDetails from "./AcceleratorDetails";
import SubscriptionSection from "./SubscriptionSection";

export type ContractPageSubscriptionProps = {
  contract: ContractModel;
  accountManagerName?: string;
};

export const cyIds = {
  text: {
    title: "title",
  },
  sections: {
    general: "general-section",
  },
};

const ContractPageSubscription = ({ contract, accountManagerName }: ContractPageSubscriptionProps) => {
  const { entities, entitiesLoading } = useCustomerContext();
  const { getContractTypeLabel } = useContractTypesContext();
  const pageTitle = getContractTypeLabel(contract.type);

  const entity = useMemo<Entity | undefined>(
    () => entities.find((ent) => ent.id === contract.entity?.id),
    [contract.entity?.id, entities]
  );

  return (
    <Stack spacing={3}>
      <Typography variant="h1" data-cy={cyIds.text.title}>
        {pageTitle}
      </Typography>
      <Box component="section" data-cy={cyIds.sections.general}>
        <Typography variant="h2">General</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet
            key="billing-profile"
            field="Billing profile"
            value={entity ? `${entity.priorityId} - ${entity.name}` : "Unassigned"}
            loading={entitiesLoading}
          />
          <DataCouplet key="account-manager" field="Account manager" value={accountManagerName ?? NotAvailable} />
        </Stack>
      </Box>
      {contract.type === AssetTypeDoiTCloudSolveSingleAccelerator ? (
        <AcceleratorDetails contract={contract} />
      ) : (
        <SubscriptionSection contract={contract} />
      )}
    </Stack>
  );
};

export default ContractPageSubscription;
