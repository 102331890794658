import { Helmet } from "react-helmet-async";

import { useAuthContext } from "../Context/AuthContext";
import { useCustomerContext } from "../Context/CustomerContext";
import { domainConfig } from "../domainConfig";

export const DoitConsoleTitle = ({ pageName, pageLevel1 }: { pageName: string; pageLevel1?: string | null }) => {
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext({ allowNull: true });

  const parts = [domainConfig.title];
  if (isDoitEmployee && customer) {
    parts.push(customer.name);
  }

  if (pageName) {
    parts.push(pageName);
  }

  if (pageLevel1) {
    parts.push(pageLevel1);
  }

  return (
    <Helmet>
      <title>{parts.join(" - ")}</title>
    </Helmet>
  );
};
