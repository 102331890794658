import { useCallback, useMemo } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  type AutocompleteRenderGetTagProps,
  type AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  type SxProps,
  TextField,
  type TextFieldProps,
  type Theme,
} from "@mui/material";
import startCase from "lodash/startCase";

import { attributionText } from "../../../assets/texts";
import { createFilterOptions } from "../../../Pages/CloudAnalytics/utilities";
import { type AttributionWRef } from "../../../types";
import { sortAttributions } from "./sortAttributions";

type Props = {
  attributions: AttributionWRef[];
  onBlur?: () => void;
  onClose?: () => void;
  onChange: (scope: AttributionWRef[]) => void;
  scope: AttributionWRef[];
  sx?: SxProps<Theme>;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  dataCy?: string;
};

const getOptionLabel = (option: AttributionWRef) => option.data.name;

const handleRenderTags = (value: AttributionWRef[], getTagProps: AutocompleteRenderGetTagProps) =>
  value.map((option, index) => (
    <Chip
      size="small"
      label={option.data.name}
      {...getTagProps({ index })}
      key={option.ref.id}
      deleteIcon={<CancelIcon />}
    />
  ));

const handleRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: AttributionWRef, { selected }) => (
  <li {...props} key={option.ref.id}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" />}
      checked={selected}
    />
    {option.data.name}
  </li>
);

const handleRenderInput = (textFieldProps: TextFieldProps) => {
  const handleRenderInputFunc = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      required
      variant="outlined"
      label={attributionText.SCOPE}
      placeholder={attributionText.SELECT_ATTRIBUTION}
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      {...textFieldProps}
    />
  );
  return handleRenderInputFunc;
};

const filterOptions = createFilterOptions<AttributionWRef>({
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
});

export const AttributionSelectMulti = ({
  attributions,
  disabled,
  onBlur,
  onClose,
  onChange,
  scope,
  sx,
  textFieldProps = { size: "small" },
  dataCy = "attributions-select",
}: Props) => {
  const sortedAttributions = useMemo(() => sortAttributions(attributions), [attributions]);

  const handleChange = useCallback(
    (_, value: AttributionWRef[]) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Autocomplete
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      groupBy={(option) => startCase(option.data.type)}
      filterOptions={filterOptions}
      limitTags={3}
      multiple
      onBlur={onBlur}
      onChange={handleChange}
      onClose={onClose}
      options={sortedAttributions}
      sx={sx}
      value={scope}
      data-cy={dataCy}
      renderTags={handleRenderTags}
      renderOption={handleRenderOption}
      renderInput={handleRenderInput(textFieldProps)}
    />
  );
};
