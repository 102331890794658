import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SortableContext } from "@dnd-kit/sortable";
import { type DashboardRowHeight } from "@doitintl/cmp-models";
import { Box, Stack } from "@mui/material";

import { DotMarks } from "../../../../Components/Dashboard/DotMarks";
import { DropLine } from "./DropLine";
import { DroppableSpaceBetween } from "./DroppableSpaceBetween";
import { type HoverItem } from "./Grid/DraggableWidgetsGrid";
import { ResizingHandle } from "./ResizingHandle";
import { SortableWidget } from "./SortableWidget";
import { type GridRow, type WidgetItem, type WidgetWidth } from "./useWidgetItemsWithRows";
import { calculateWidthWithDelta, getWidth } from "./widgetUtils";

type Props = {
  rows: GridRow[];
  disableEditing: boolean;
  rowWidth: number;
  heights: DashboardRowHeight[];
  dragging: boolean;
  onHorizontalResized: (rowIndex: number, width: [WidgetWidth, WidgetWidth]) => void;
  onVerticalResized: (rowIndex: number, height: number) => void;
  hoverItem?: HoverItem;
};

type ResizeValue = {
  rowIndex: number;
  delta: number;
};

type ResizeLastValue = {
  rowIndex: number;
  height?: number;
  width?: [WidgetWidth, WidgetWidth];
};

const betweenRowsSpace = "20px";
const rowMinHeight = 380;
const rowMaxHeight = 1200;

export function Rows({
  rows,
  disableEditing,
  heights,
  rowWidth,
  hoverItem,
  dragging,
  onHorizontalResized,
  onVerticalResized,
}: Props) {
  const isHovering = useCallback(
    (item: WidgetItem | undefined, position: HoverItem["position"]) => {
      if (!item) {
        return false;
      }
      return hoverItem?.id === item.name && hoverItem?.position === position;
    },
    [hoverItem]
  );

  const [horizontalResizing, setHorizontalResizing] = useState<ResizeValue | false>(false);
  const [verticalResizing, setVerticalResizing] = useState<ResizeValue | false>(false);

  const resizing = Boolean(horizontalResizing || verticalResizing);
  const [latestResizing, setLatestResizing] = useState<ResizeLastValue | undefined>(undefined);

  useEffect(() => {
    setLatestResizing(undefined);
  }, [heights, rows]);

  const itemsWithRowsInfo = useMemo(
    () =>
      rows.flatMap((row, rowIndex) => [
        {
          item: undefined,
          index: undefined,
          isFirst: false,
          isLast: false,
          nextItem: undefined,
          newRow: true,
          rowIndex,
          itemsInRow: 0,
        },
        ...row.items.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === row.items.length - 1;
          const nextItem = !isLast ? row.items[index + 1] : undefined;

          return {
            item,
            index,
            isFirst,
            isLast,
            nextItem,
            newRow: false,
            rowIndex,
            itemsInRow: row.items.length,
          };
        }),
      ]),
    [rows]
  );

  const ids = useMemo(() => rows.flatMap((rows) => rows.items.map((item) => item.name)), [rows]);

  const calcHeight = useCallback(
    (rowIndex: number) => {
      if (latestResizing?.height && latestResizing.rowIndex === rowIndex) {
        return latestResizing.height;
      }

      const currentHeight = heights.at(rowIndex) ?? rowMinHeight;

      if (verticalResizing && verticalResizing.rowIndex === rowIndex) {
        const newHeight = currentHeight + verticalResizing.delta;
        if (newHeight < rowMinHeight) {
          return rowMinHeight;
        } else if (newHeight > rowMaxHeight) {
          return rowMaxHeight;
        } else {
          return newHeight;
        }
      } else {
        return currentHeight;
      }
    },
    [heights, latestResizing?.height, latestResizing?.rowIndex, verticalResizing]
  );

  const calcWidth = useCallback(
    (item: WidgetItem, rowIndex: number, itemsInRow: number, index: number) => {
      let result = getWidth(item.width) * rowWidth;

      if (latestResizing?.width && latestResizing.rowIndex === rowIndex) {
        result = getWidth(latestResizing.width[index]) * rowWidth;
      } else if (horizontalResizing && horizontalResizing.rowIndex === rowIndex) {
        if (index === 0) {
          result = calculateWidthWithDelta(item, rowWidth, horizontalResizing.delta);
        } else if (index === 1) {
          result = calculateWidthWithDelta(item, rowWidth, -horizontalResizing.delta);
        }
      }

      let extra = 10;

      if (itemsInRow === 1) {
        extra = 0;
      }

      if (itemsInRow === 2 && index === 1) {
        extra = -10;
      }

      if (itemsInRow === 3) {
        if (index === 1) {
          extra = 0;
        } else if (index === 2) {
          extra = -10;
        }
      }

      return `${result + extra}px`;
    },
    [horizontalResizing, latestResizing?.rowIndex, latestResizing?.width, rowWidth]
  );

  const handleVerticalResizingEnd = useCallback(() => {
    if (!verticalResizing) {
      return;
    }

    const height = calcHeight(verticalResizing.rowIndex);
    const rowIndex = verticalResizing.rowIndex;
    const newHeight: ResizeLastValue = {
      rowIndex,
      height,
    };
    setLatestResizing(newHeight);
    onVerticalResized(rowIndex, height);
    setVerticalResizing(false);
  }, [calcHeight, onVerticalResized, verticalResizing]);

  const handleHorizontalResizingEnd = useCallback(() => {
    if (!horizontalResizing) {
      return;
    }

    const items = rows[horizontalResizing.rowIndex].items;
    const widthWithDelta = calculateWidthWithDelta(items[0], rowWidth, horizontalResizing.delta);

    const allowedWidths = [0.333 * rowWidth, 0.5 * rowWidth, 0.666 * rowWidth];

    const getNearestAllowedWidth = (currentWidth: number) =>
      allowedWidths.reduce(
        (prev, curr) => (Math.abs(curr - currentWidth) < Math.abs(prev - currentWidth) ? curr : prev),
        allowedWidths[0]
      );

    // Adjust the width to the nearest allowed value
    const adjustedWidth = getNearestAllowedWidth(widthWithDelta);

    let widths: [WidgetWidth, WidgetWidth] = [1, 1];
    if (adjustedWidth === allowedWidths[0]) {
      widths = [1, 2];
    } else if (adjustedWidth === allowedWidths[1]) {
      widths = [1.5, 1.5];
    } else if (adjustedWidth === allowedWidths[2]) {
      widths = [2, 1];
    }

    onHorizontalResized(horizontalResizing.rowIndex, widths);
    setLatestResizing({
      rowIndex: horizontalResizing.rowIndex,
      width: widths,
    });

    setHorizontalResizing(false);
  }, [onHorizontalResized, horizontalResizing, rows, rowWidth]);

  return (
    <SortableContext items={ids}>
      {itemsWithRowsInfo.map(({ item, index, isFirst, isLast, nextItem, newRow, rowIndex, itemsInRow }) => {
        if (newRow) {
          return (
            <Stack key={`${rowIndex}_horizontal`} width="100%" height={betweenRowsSpace}>
              {horizontalResizing && horizontalResizing.rowIndex === rowIndex ? (
                <Box pt={1} width="100%">
                  <DotMarks />
                </Box>
              ) : dragging ? (
                <DroppableSpaceBetween id={`${rowIndex}_horizontal`} />
              ) : (
                <ResizingHandle
                  disabled={disableEditing || rowIndex === 0 || Boolean(horizontalResizing)}
                  onResizeStart={() => {
                    setLatestResizing(undefined);
                    setVerticalResizing({ rowIndex: rowIndex - 1, delta: 0 });
                  }}
                  onResizeEnd={handleVerticalResizingEnd}
                  onResize={(delta) => {
                    setVerticalResizing({ rowIndex: rowIndex - 1, delta });
                  }}
                />
              )}
            </Stack>
          );
        } else if (item && index !== undefined) {
          return (
            <Stack
              key={item.name}
              direction="row"
              width={calcWidth(item, rowIndex, itemsInRow, index)}
              height={calcHeight(rowIndex)}
            >
              {isFirst && <Box minWidth="5px">{isHovering(item, "start") && <DropLine rightSpacing vertical />}</Box>}

              <Box width={`calc(100% - ${isLast ? "5px" : isFirst ? "25px" : "20px"})`}>
                <SortableWidget
                  key={item.name}
                  disableEvents={resizing}
                  id={item.name}
                  width="100%"
                  height={calcHeight(rowIndex)}
                />
              </Box>

              {!isLast && (
                <Box width={betweenRowsSpace} height="100%">
                  {dragging ? (
                    <DroppableSpaceBetween
                      disabled={disableEditing || !hoverItem}
                      hover={isHovering(item, "end") || isHovering(nextItem, "start")}
                      vertical
                      id={`${rowIndex}_vertical_${index}`}
                    />
                  ) : (
                    <ResizingHandle
                      vertical
                      disabled={disableEditing || itemsInRow !== 2 || Boolean(verticalResizing)}
                      onResizeStart={() => {
                        setLatestResizing(undefined);
                        setHorizontalResizing({ rowIndex, delta: 0 });
                      }}
                      onResizeEnd={handleHorizontalResizingEnd}
                      onResize={(delta) => {
                        setHorizontalResizing({ rowIndex, delta });
                      }}
                    />
                  )}
                </Box>
              )}

              {isLast && <Box minWidth="5px">{isHovering(item, "end") && <DropLine leftSpacing vertical />}</Box>}
            </Stack>
          );
        }
      })}
      <Stack key={`${rows.length}_horizontal`} width={"100%"} height={betweenRowsSpace}>
        {dragging ? (
          <DroppableSpaceBetween id={`${rows.length}_horizontal`} />
        ) : (
          <ResizingHandle
            disabled={disableEditing}
            onResizeStart={() => {
              setLatestResizing(undefined);
              setVerticalResizing({ rowIndex: rows.length - 1, delta: 0 });
            }}
            onResizeEnd={handleVerticalResizingEnd}
            onResize={(delta) => {
              setVerticalResizing({ rowIndex: rows.length - 1, delta });
            }}
          />
        )}
      </Stack>
      <Box height={70} width="100%" />
    </SortableContext>
  );
}
