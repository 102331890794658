import { useRef } from "react";

import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/material";

import { DropLine } from "./DropLine";

type Props = {
  id: string;
  vertical?: boolean;
  hover?: boolean;
  disabled?: boolean;
};

export function DroppableSpaceBetween({ id, disabled, vertical, hover }: Props) {
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  const mouseOverRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={setNodeRef} minHeight="20px" height={vertical ? "100%" : "20px"} minWidth={vertical ? "20px" : "100%"}>
      <Box ref={mouseOverRef} height={"100%"} width={"100%"}>
        {(isOver || hover) && <DropLine disabled={disabled} leftSpacing rightSpacing vertical={vertical} />}
      </Box>
    </Box>
  );
}
