import { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { zeroToHundredDigitsOnly } from "../../../utils";
import RampCellDialogContent from "./RampCellDialogContent";

export type RampCellDialogProps = {
  isDialogOpen: boolean;
  onClose: () => void;
  closeWithoutSaving: () => void;
  classes: Record<string, string>;
  onCheckboxClick: (e) => void;
  onGrowthPerMonthChange: (percent: number) => void;
  inputError: string | null;
};
export enum FocusedOn {
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
}

export const RampCellDialog = (props: RampCellDialogProps) => {
  const { isDialogOpen, closeWithoutSaving, onClose, classes, onCheckboxClick, onGrowthPerMonthChange, inputError } =
    props;

  const [focusOn, setFocusOn] = useState<FocusedOn | null>(null);
  const [isCheckboxTicked, setIsCheckboxTicked] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const onCBClick = (e) => {
    onCheckboxClick(e);
    setIsCheckboxTicked(e.target.checked);
    setInputValue("");
  };

  const onDialogClose = () => {
    onClose();
    setFocusOn(null);
    setIsCheckboxTicked(false);
    setInputValue("");
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    const parsedVal = parseInt(e.target.value);
    !isNaN(parsedVal) && onGrowthPerMonthChange(parsedVal);
  };

  const closeDialogWithoutSaving = () => {
    setFocusOn(null);
    setIsCheckboxTicked(false);
    setInputValue("");
    closeWithoutSaving();
  };

  useEffect(
    () => () => {
      setIsCheckboxTicked(false);
      setFocusOn(null);
      setInputValue("");
    },
    []
  );

  const changeFocusTo = (el: FocusedOn | null) => {
    setFocusOn(el);
  };
  return (
    <Dialog
      maxWidth="sm"
      open={isDialogOpen}
      onClose={onClose}
      aria-labelledby="ramp-cell-dialog"
      data-testid="ramp-cell-dialog"
    >
      <DialogTitle id="ramp-cell-dialog">Set ramp growth per month</DialogTitle>
      <DialogContent
        className={classes.dialogRoot}
        onKeyPress={(e) => {
          if (e.key === "Enter" && e.currentTarget?.parentElement !== null) {
            onClose();
          }
        }}
      >
        <RampCellDialogContent
          onInputChange={onInputChange}
          isCheckboxTicked={isCheckboxTicked}
          changeFocusTo={changeFocusTo}
          inputValue={inputValue}
          classes={classes}
          inputError={inputError}
          inputErrorString={zeroToHundredDigitsOnly}
          closeDialogWithoutSaving={closeDialogWithoutSaving}
          onCBClick={onCBClick}
          focusOn={focusOn}
          onDialogClose={onDialogClose}
          isDialogOpen={isDialogOpen}
          onCheckboxClick={onCheckboxClick}
          onClose={onClose}
          onGrowthPerMonthChange={onGrowthPerMonthChange}
        />
      </DialogContent>
    </Dialog>
  );
};
