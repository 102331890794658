import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  cardHeader: {
    height: 80,
  },
  stretch: { height: "100%" },
  accordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummary: {
    display: "inline-flex",
    paddingLeft: 0,
  },
  accordionDetails: {
    padding: 0,
  },
  iconButton: {
    padding: 6,
  },
}));

export const listClass = (theme) => ({
  [theme.breakpoints.down(1551)]: {
    height: 370,
  },
  [theme.breakpoints.between(1551, 1200)]: {
    height: 300,
  },
  [theme.breakpoints.up(1200)]: {
    height: 200,
  },
});
