import {
  Box,
  Container,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { roundWithCommas } from "../../../../../utils/common";
import { useContractFormContext } from "../../ContractsFormContext";
import { lookerEstimatedAnnualizedValue } from "../../utils";
import { calculateLookerSubscription } from "./LookerCalculator";

export type LookerSkuError = {
  skuName: boolean;
  months: boolean;
  quantity: boolean;
  monthlySalesPrice: boolean;
};

const dateFormat = "dd MMM yyyy";

export const LookerSubscriptionStep = () => {
  const { customer } = useCustomerContext();
  const { state, handleChange } = useContractFormContext();
  const { lookerSkus, startDate, lookerContractDuration, lookerInvoiceFrequency, isEditForbidden, discount } = state;
  const subscriptionTerms = calculateLookerSubscription(
    startDate,
    lookerContractDuration,
    lookerInvoiceFrequency,
    lookerSkus.map((sku) => ({
      pricePerMonth: sku.monthlySalesPrice,
      durationMonths: sku.months,
      quantity: sku.quantity,
    }))
  );
  const estimatedAnnualizedValue = lookerEstimatedAnnualizedValue(state);
  const formatTermDates = (term) => `${term.startDate.toFormat(dateFormat)} - ${term.endDate.toFormat(dateFormat)}`;
  const getInvoiceFrequencyString = (months) => {
    switch (months) {
      case 1:
        return "Monthly";
      case 3:
        return "Quarterly";
      case 6:
        return "Semi-annual";
      case 12:
        return "Annually";
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Subscription term</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Invoice frequency"
            value={lookerInvoiceFrequency}
            margin="dense"
            variant="outlined"
            onChange={handleChange("lookerInvoiceFrequency")}
            error={state.errors.lookerInvoiceFrequency}
            disabled={isEditForbidden}
          >
            {[
              { label: "Monthly", value: 1 },
              { label: "Quarterly", value: 3 },
              { label: "Semi-annual", value: 6 },
              { label: "Annually", value: 12 },
            ].map((item) => (
              <MenuItem key={item.label} value={item.value} dense>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Confirmation of{" "}
            <Box component="span" fontWeight="fontWeightBold">
              {lookerSkus.length} Looker SKUs
            </Box>{" "}
            for the domain{" "}
            <Box component="span" fontWeight="fontWeightBold">
              {customer.primaryDomain}
            </Box>
            {discount > 0 && (
              <>
                <Box component="span"> with an additional </Box>
                <Box component="span" fontWeight="fontWeightBold">
                  {discount}% discount
                </Box>
                <Box component="span"> from DoiT margin</Box>
              </>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                padding: "16px 8px 8px 0px",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Subscription term</TableCell>
                <TableCell align="center">Invoice frequency</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionTerms.map((term) => (
                <TableRow key={formatTermDates(term)}>
                  <TableCell align="left">{formatTermDates(term)}</TableCell>
                  <TableCell align="center">{getInvoiceFrequencyString(lookerInvoiceFrequency)} </TableCell>
                  <TableCell align="right">${roundWithCommas(term.amount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Annualized value</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" display="block" data-cy="lookerAnnualizedValue">
            ${roundWithCommas(estimatedAnnualizedValue)}
          </Typography>
        </Grid>
        {discount > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">{`One-time discount (${discount}%)`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" display="block" data-cy="lookerDiscount">
                ${roundWithCommas(estimatedAnnualizedValue * (discount / 100))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Total annualized value</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" display="block" data-cy="lookerTotalAnnualizedValue">
                ${roundWithCommas(estimatedAnnualizedValue - estimatedAnnualizedValue * (discount / 100))}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
