import { Container } from "@mui/material";

import ClusterPageHeader from "./Header";
import ClusterTable from "./Table/ClusterTable";

const ClustersPage = () => (
  <Container sx={{ pt: 2, pb: 8 }}>
    <ClusterPageHeader />
    <ClusterTable />
  </Container>
);

export default ClustersPage;
