import { type GCPWorkloadModel, IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useCollection, useCollectionData } from "@doitintl/models-firestore";
import { DateTime } from "luxon";
import memoizeOne from "memoize-one";

import { TimestampFromDate } from "../../../../../utils/firebase";
import { type Workload } from "../types";

export function parseGCPWorkload(gcpWorkload: GCPWorkloadModel): Workload {
  const {
    id,
    billingAccountId,
    customerId,
    familyType,
    hardware,
    doitRecommendation,
    optimizerStats,
    lastPurchase,
    purchasePlan,
    purchaseStats,
    region,
    status,
    targetCoverage,
    timeUpdated,
  } = gcpWorkload;
  return {
    id,
    billingAccountId,
    customerId,
    hardware,
    type: familyType,
    timeRecommendationUpdated: doitRecommendation?.timeUpdated
      ? DateTime.fromJSDate(doitRecommendation?.timeUpdated?.toDate())
      : undefined,
    timePurchaseDone: lastPurchase?.timeExecuted
      ? DateTime.fromJSDate(lastPurchase?.timeExecuted?.toDate())
      : undefined,
    status,
    region,
    optimizerCoverage: optimizerStats?.currentCudCoverage || 0,
    optimizerBaseline: optimizerStats?.onDemandBaseline || 0,
    currentDoitCoverage: purchaseStats?.currentDoitCudCoverage,
    currentCustomerCoverage: purchaseStats?.currentCustomerCudCoverage,
    usageOnDemand: purchaseStats?.onDemandBaseline,
    currentCudCoverage: purchaseStats?.currentCudCoverage,
    purchasePlanPrice: purchasePlan?.cost || 0,
    purchaseRisk: purchasePlan?.risks,
    purchasePlanTargetCoverage: purchasePlan?.targetCoverage,
    purchaseRecommendations: purchasePlan?.amount || 0,
    workloadTargetCoverage: targetCoverage,
    statusTimeUpdated: timeUpdated && DateTime.fromJSDate(timeUpdated.toDate()),
    customerShareWithPurchasePlan: purchasePlan?.newCustomerShare,
    globalCoverageWithPurchasePlan: purchasePlan?.newGlobalTotalCoverage,
  };
}

const gcpWorkloadsQuery = memoizeOne(() =>
  getCollection(IntegrationModel)
    .doc("flexsave")
    .collection("gcp-ops-workloads")
    .where("timeUpdated", ">", TimestampFromDate(new Date(Date.now() - 24 * 60 * 60 * 1000)))
);

const customerWorkloadsQuery = (customerId: string) =>
  getCollection(IntegrationModel).doc("flexsave").collection("gcp-ops-workloads").where("customerId", "==", customerId);

export function useGCPWorkloads() {
  return useCollection(gcpWorkloadsQuery());
}

export function useCustomerWorkloads(customerId: string) {
  const query = customerWorkloadsQuery(customerId);
  return useCollectionData(query, {
    transform: parseGCPWorkload,
  });
}
