import { type ReactNode } from "react";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  type TypographyProps,
} from "@mui/material";

import { cmpBaseColors } from "../../cmpBaseColors";
import useFetchAmTamEmail from "../NoEntitlement/hooks";
import { ContactSalesButton } from "../NoEntitlement/NoEntitlement";

export enum TiersType {
  STANDARD = "Standard",
  ENHANCED = "Enhanced",
  PREMIUM = "Premium",
  ENTERPRISE = "Enterprise",
}

export type Tier = {
  title?: string;
  tierType: TiersType;
};

export const tierColors = {
  [TiersType.STANDARD]: cmpBaseColors.standardLabel,
  [TiersType.ENHANCED]: cmpBaseColors.enhancedLabel,
  [TiersType.PREMIUM]: cmpBaseColors.premiumLabel,
  [TiersType.ENTERPRISE]: cmpBaseColors.enterpriseLabel,
};

type CardText = {
  title: string;
  titleTypographyProps?: TypographyProps;
  description: string;
  descriptionTypographyProps?: TypographyProps;
};

type NoEntitlementProps = {
  cards?: ReactNode;
  title: string;
  listItems?: string[];
  description: string;
};

const PADDING = 6;

export const CardsComponent = ({
  cards,
  title,
  lastChecked,
  bgcolor,
}: {
  cards: CardText[];
  title: string;
  bgcolor?: string;
  lastChecked?: string;
}) => (
  <Box bgcolor={bgcolor} py={PADDING}>
    <Container maxWidth="lg">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">{title}</Typography>
        {!!lastChecked && (
          <Typography variant="body2" color="text.secondary">
            {lastChecked}
          </Typography>
        )}
      </Stack>
      <Box mb={2} />
      <Grid container spacing={2} sx={{ alignItems: "stretch" }}>
        {cards.map((card, index) => (
          <Grid key={index} item xs={12} md={Math.floor(12 / cards.length)}>
            <Card sx={{ flex: 1, height: "100%" }}>
              <CardHeader
                sx={{
                  bgcolor: "general.backgroundDark",
                  height: "56px",
                }}
                title={card.title}
                titleTypographyProps={{
                  variant: "subtitle1",
                  fontWeight: 500,
                  ...(card?.titleTypographyProps || {}),
                }}
              />
              <Divider />
              <CardContent>
                <Typography variant="body1" {...(card?.descriptionTypographyProps || {})}>
                  {card.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);

const NoEntitlement = ({ cards, title, listItems, description }: NoEntitlementProps) => {
  const { accountManagers, loading } = useFetchAmTamEmail();

  return (
    <Box mt={-1} mx={-2}>
      <Stack>
        <Box pt={3} bgcolor="general.backgroundDark" py={PADDING}>
          <Container maxWidth="lg">
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="h1">{title}</Typography>
                  <Box mb={1} />
                  <Typography variant="body1" color="text.secondary">
                    {description}
                  </Typography>
                  {listItems && (
                    <List sx={{ pt: 0, pb: 0, listStyle: "disc", pl: 4, mt: 0 }}>
                      {listItems?.map((item, index) => (
                        <ListItem key={index} sx={{ p: 0, gap: 1, display: "list-item" }}>
                          <ListItemText primary={item} primaryTypographyProps={{ color: "text.secondary" }} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  <Box mb={2} />

                  {/* <TierAvailability tiers={tiers} /> */}

                  <Box mb={4} />
                  <ContactSalesButton sendTo={accountManagers} loading={loading} />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        {cards}
      </Stack>
    </Box>
  );
};

export default NoEntitlement;
