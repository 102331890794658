import { useCallback } from "react";

import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { getMessaging, getToken } from "firebase/messaging";

import { useAuthContext } from "../Context/AuthContext";
import { isMessagingSupported, serverTimestamp } from "./firebase";
import mixpanel from "./mixpanel";

const getNotificationToken = async () => {
  const messaging = getMessaging();

  let token: string | null = null;
  try {
    await Notification.requestPermission();
    token = await getToken(messaging);
  } catch (e) {
    mixpanel.people.set({
      notifications: "blocked",
    });
  }

  if (token) {
    mixpanel.people.set({
      notifications: "allowed",
    });
  }

  return token;
};

export const useNotificationsPermission = () => {
  const { currentUser } = useAuthContext();

  const requestNotificationPermission = useCallback(async () => {
    isMessagingSupported().then(async (isSupported) => {
      if (currentUser?.uid && isSupported && Notification.permission === "default") {
        const token = await getNotificationToken();
        if (token) {
          await getCollection(IntegrationModel)
            .doc("fcm")
            .collection("tokens")
            .doc(currentUser.uid)
            .set({
              displayName: currentUser.displayName ?? undefined,
              email: currentUser.email,
              token,
              topics: [],
              timestamp: serverTimestamp(),
            });
        }
      }
    });
  }, [currentUser?.displayName, currentUser?.email, currentUser?.uid]);

  return { requestNotificationPermission };
};
