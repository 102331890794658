import { Button, Stack } from "@mui/material";
import { type Dict } from "mixpanel-browser";

import { globalText } from "../../assets/texts";
import LoadingButton from "../LoadingButton";

type NextButtonLoadingProps = {
  loading: boolean;
  mixpanelEventId: string;
  eventProperties?: Dict;
};

export type WorkflowFooterProps = {
  disableNext?: boolean[];
  hideNext?: boolean;
  nextBtnText?: string[];
  hideBackOnLastStep?: boolean;
  nextButtonLoadingCheck?: (step: number) => NextButtonLoadingProps | undefined;
};

type Props = WorkflowFooterProps & {
  hideBack?: boolean;
  onPreviousStepClicked: () => void;
  onNextStepClicked: () => void;
  onCancelClicked: () => void;
  step: number;
};

export function WorkflowFooter({
  disableNext,
  hideBack = false,
  hideNext = false,
  nextBtnText,
  nextButtonLoadingCheck,
  onCancelClicked,
  onNextStepClicked,
  onPreviousStepClicked,
  step,
}: Props) {
  const nextText = nextBtnText?.[step] || globalText.NEXT;
  const isNextDisabled = disableNext?.[step] ?? false;
  const nextBtnLoadingProps = nextButtonLoadingCheck?.(step);
  const numOfSteps = disableNext?.length ?? 0;
  const isLastStep = step === numOfSteps - 1;
  return (
    <Stack p={2} direction="row" justifyContent="space-between" width="100%">
      <Button sx={{ visibility: hideBack ? "hidden" : undefined }} variant="outlined" onClick={onPreviousStepClicked}>
        {globalText.BACK}
      </Button>
      <Stack direction="row" spacing={2}>
        <Button variant="text" onClick={onCancelClicked}>
          {isLastStep ? globalText.CLOSE : globalText.CANCEL}
        </Button>
        {!hideNext && nextBtnLoadingProps ? (
          <LoadingButton
            disabled={isNextDisabled}
            onClick={onNextStepClicked}
            variant="contained"
            {...nextBtnLoadingProps}
          >
            {nextText}
          </LoadingButton>
        ) : !hideNext ? (
          <Button disabled={isNextDisabled} variant="contained" onClick={onNextStepClicked}>
            {nextText}
          </Button>
        ) : null}
      </Stack>
    </Stack>
  );
}
