import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { RampModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useToggle } from "../../../../utils/useToggle";
import { useErrorSnackbar } from "../../../Integrations/Slack/utils";
import { type RampPlanDeletePromptProps } from "./RampPlanDeletePrompt";

export const useRampPlanDeleteFacade = (rampPlanId: string): [RampPlanDeletePromptProps, () => void] => {
  const [open, openPrompt, closePrompt] = useToggle(false);
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();
  const history = useHistory();
  const { customer } = useCustomerContext();

  const deleteRampPlan = useCallback(async () => {
    try {
      await getCollection(RampModel).doc(rampPlanId).delete();
      closePrompt();
      history.push(`/customers/${customer.id}/contracts/ramps`);
      showSuccessSnackbar("Ramp plan successfully deleted.");
    } catch (exception) {
      if (exception instanceof Error) {
        showErrorSnackbar(exception.toString());
      }
    }
  }, [closePrompt, customer.id, history, rampPlanId, showErrorSnackbar, showSuccessSnackbar]);

  return [
    {
      open,
      onClose: closePrompt,
      onDelete: deleteRampPlan,
    },
    openPrompt,
  ];
};
