import { useApiContext } from "../../../../api/context";
import { useOneTimeCopyRequest } from "../../../../api/endpoints";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import GoogleDirectAssetForm from "../../Forms/GoogleDirectAssetForm";

type Props = {
  onClose: () => void;
};

export default function CreateGoogleCloudDirectAssetForm({ onClose }: Props) {
  const api = useApiContext();
  const oneTimeCopyRequest = useOneTimeCopyRequest();
  const { customer } = useCustomerContext();

  if (!customer) {
    return null;
  }

  const customerId = customer.id;

  return (
    <GoogleDirectAssetForm
      mode="create"
      initialValues={{
        project: "",
        dataset: "",
        billingAccountId: "",
      }}
      onClose={onClose}
      onSubmit={async (data) => {
        await api.request({
          method: "post",
          url: `/v1/customers/${customerId}/assets/google-cloud-direct`,
          data,
        });

        try {
          await oneTimeCopyRequest({ billingAccountId: data.billingAccountId, customerId });
        } catch (e) {
          consoleErrorWithSentry(e);
        }
      }}
    />
  );
}
