import { useEffect, useState } from "react";

import { RampModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { partnerAssetsMap } from "../../../utils/common";
import { type RampPlanModel } from "../types";

const useGetRampPlans = () => {
  const [rampPlans, setRampPlans] = useState<RampPlanModel[]>();
  const [takenNamesArr, setTakenNamesArr] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { customer } = useCustomerContext();
  const { isDoitPartner, partnerCompany } = useAuthContext({ mustHaveUser: true });

  useEffect(() => {
    let query = getCollection(RampModel).where("customerRef", "==", customer.ref);
    if (isDoitPartner) {
      // in case of no partnerCompany will return no documents
      query = query.where("platform", "==", partnerAssetsMap[partnerCompany]);
    }
    return query.onSnapshot((itemsSnapshot) => {
      const itemsData = itemsSnapshot.docs.map((itemDocument) => {
        const itemData = itemDocument.asModelData({ serverTimestamps: "estimate" });
        return {
          ...itemData,
          id: itemDocument.id,
          ref: itemDocument.modelRef,
        };
      });
      if (itemsData.length >= 0) {
        const names: string[] = itemsData.map((each) => each.name);
        setRampPlans(itemsData);
        setTakenNamesArr(names);
        setLoading(false);
      }
    });
  }, [customer.ref, customer.id, isDoitPartner, partnerCompany]);

  return { rampPlans, takenNamesArr, loading };
};

export default useGetRampPlans;
