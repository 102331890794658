import { type AttributionFilter } from "@doitintl/cmp-models";

export const isValidFiltersAndFormula = (filters: AttributionFilter[], isFormulaValid: boolean) => {
  if (filters.length === 0 || !isFormulaValid) {
    return false;
  }
  for (const filter of filters) {
    if (!(filter.values?.length || filter.allowNull || filter?.regexp)) {
      return false;
    }
  }
  return true;
};
