import { Children, type ReactNode, useContext } from "react";

import { ColumnsOrderContext } from "../Context";
import { applyColumnsChangeToArray } from "../filterTableUtils";

type Props = {
  children: ReactNode;
};

export const CellsWrapper = ({ children }: Props) => {
  const [columnsOrder] = useContext(ColumnsOrderContext);
  const originalChildren = Children.toArray(children);
  if (columnsOrder.length === 0) {
    return <>{children}</>;
  }
  return <>{applyColumnsChangeToArray(originalChildren, columnsOrder)}</>;
};
