import { type CustomerModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { type Customer } from "../types";

export const doitCustomerId = "EE8CtpzYiKp0dVAESVrB";

export function getCustomerRefList(...customers: (Customer | null | undefined)[]) {
  return customers.reduce((refList, customer) => {
    if (customer?.ref && !refList.includes(customer.ref)) {
      refList.push(customer.ref);
    }
    return refList;
  }, [] as ModelReference<CustomerModel>[]);
}
