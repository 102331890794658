import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Box } from "@mui/material";

import AWSLogo from "../../assets/aws-logo.svg";
import AWSDarkLogo from "../../assets/aws-logo-dark-mode.svg";
import azureLogo from "../../assets/azure-logo.svg";
import doitLogo from "../../assets/doit-logo-hero-square.svg";
import GCPLogo from "../../assets/gcp-logo.svg";
import googleLogo from "../../assets/google.svg";
import ms365 from "../../assets/office-365.png";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { supportPlatformDropdown } from "../../constants/cypressIds";

type Props = {
  platformValue: string;
  disabled?: boolean;
  onClick?: () => void;
};

const SupportItemIcon = ({ platformValue, disabled, onClick }: Props) => {
  const darkMode = useDarkThemeCheck();
  const { icons: cyIcons } = supportPlatformDropdown.contents;

  const imgSize = { height: "24px", width: "24px" };
  const sxDisabled = disabled ? { filter: "grayscale(100%)" } : {};
  const sxStyle = { ...imgSize, ...sxDisabled };

  const platformIcons = {
    cloud_management_platform: (
      <Box component="img" src={doitLogo} sx={sxStyle} aria-hidden data-cy={cyIcons.cmp} onClick={onClick} />
    ),
    google_cloud_platform: (
      <Box component="img" src={GCPLogo} sx={sxStyle} aria-hidden data-cy={cyIcons.gcp} onClick={onClick} />
    ),
    amazon_web_services: (
      <Box
        component="img"
        src={darkMode ? AWSDarkLogo : AWSLogo}
        sx={{ ...sxStyle, height: "auto" }}
        data-cy={cyIcons.aws}
        onClick={onClick}
      />
    ),
    microsoft_azure: (
      <Box component="img" src={azureLogo} sx={{ ...sxStyle }} aria-hidden data-cy={cyIcons.azure} onClick={onClick} />
    ),
    google_g_suite: <Box component="img" src={googleLogo} sx={sxStyle} aria-hidden data-cy={cyIcons.gSuite} />,
    microsoft_office_365: <Box component="img" src={ms365} sx={sxStyle} aria-hidden data-cy={cyIcons.ms365} />,
    finance___billing: <ReceiptLongIcon fontSize="small" data-cy={cyIcons.finance} sx={sxStyle} onClick={onClick} />,
    credits___request: <MonetizationOnIcon fontSize="small" data-cy={cyIcons.credits} sx={sxStyle} onClick={onClick} />,
  };

  return platformIcons[platformValue] ?? null;
};

export default SupportItemIcon;
