import { createTheme } from "@mui/material/styles";

const mainColor = "#fc3165";

export const theme = createTheme({
  typography: {
    allVariants: {
      color: "#1E1E1E",
    },
  },
});

export const styles = {
  inputLabel: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "19.5px",
    marginBottom: "10px !important",
    fontFamily: "matter",
  },
  emptyStateInputLabel: {
    fontFamily: "matter-bold",
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "27px",
    marginBottom: "10px !important",
  },
  radioButton: {
    "&.Mui-checked": {
      color: mainColor,
    },
  },
  radioButtonLabel: (checked) => ({
    fontSize: 16,
    lineHeight: "19.5px",
    fontWeight: checked ? 500 : 400,
    fontFamily: "matter-regular",
  }),
  radioButtonControl: (checked, disabled = false) => ({
    border: 1,
    borderColor: checked ? mainColor : "#DADADA",
    background: checked ? "rgba(255, 132, 71, 0.05)" : "#FFFFFF",
    height: 50,
    maxWidth: { md: 180 },
    width: "calc(100% / 2 - (11px / 2))",
    borderRadius: "4px",
    margin: 0,
    ":hover": {
      borderColor: disabled ? "#DADADA" : checked ? mainColor : "#000",
    },
  }),
  currencySelector: {
    paddingLeft: "10px",
    fontFamily: "matter-regular",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "40px",
    "& .MuiSelect-icon": {
      fontSize: "24px",
      color: "text.primary",
    },
    "& #currency-selector": {
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  currencyOption: {
    fontFamily: "matter-regular",
    fontWeight: 400,
    fontSize: "16px",
  },
  monthlySpendControl: (isFocused) => ({
    maxWidth: { md: 371 },
    width: "100%",
    height: 50,
    borderRadius: "4px",
    border: 1,
    display: "flex",
    borderColor: isFocused ? mainColor : "#DADADA",
    ":hover": {
      borderColor: isFocused ? mainColor : "#000",
    },
  }),
  monthlySpendInput: {
    fontFamily: "matter-regular",
    marginLeft: "19px",
    width: "80%",
    fontSize: "16px",
    fontWeight: 400,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  annualGrowthSlider: {
    maxWidth: { md: 286 },
    width: "calc(100% - 85px)",
    height: 8,
    color: mainColor,
    "& .MuiSlider-rail": {
      color: "#D7D7D7",
      opacity: 1,
    },
    "& .MuiSlider-thumb:hover": {
      boxShadow: "0 0 0 10px rgba(255, 132, 71, 0.3) !important",
    },
  },
  annualGrowthInput: {
    width: 70,
    height: 33,
    fontFamily: "matter-regular",
    fontSize: "16px",
    lineHeight: "19.5px",
    marginLeft: "15px",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "&.MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 1,
        borderColor: mainColor,
      },
    },
  },
  savingsBox: {
    backgroundColor: "#02052C",
    maxWidth: { md: "606px" },
    maxHeight: "388px",
    borderRadius: "25px",
    display: "flex",
    flexDirection: "column",
  },
  savingBoxTextContainer: {
    display: "flex",
  },
  savingsResult: {
    fontFamily: "matter-bold",
    color: "white",
    fontWeight: 600,
    fontSize: { xs: 48 },
    lineHeight: { xs: "58.51px", sm: "78.02px" },
    mb: "0px !important",
  },
  savingsSubtitle: {
    fontFamily: "matter-regular",
    color: "white",
    fontWeight: 400,
    fontSize: { xs: 16, sm: 18 },
    lineHeight: "22px",
    textAlign: "center",
  },
  savingsSubtitleEmptyState: {
    fontFamily: "matter-regular",
    color: "white",
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "22px",
    textAlign: "center",
    margin: "auto",
  },
  bookDemoForm: {
    bgcolor: "white",
    padding: "20px 24px",
    border: "1px solid #02052C",
    borderRadius: "25px",
    lineHeight: "0px",
  },
  emailInput: {
    width: "100%",
    margin: 0,
    "& .MuiInputBase-root": {
      height: 50,
      fontFamily: "matter-regular",
      fontSize: "16px",
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: 1,
        borderColor: mainColor,
      },
    },
  },
  demoButton: {
    backgroundColor: mainColor,
    height: 48,
    width: "100%",
    color: "white !important",
    borderRadius: "25px",
    border: "none",
    fontFamily: "matter-regular",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    textTransform: "none",
    ":hover": {
      backgroundColor: mainColor,
      border: "none",
    },
    "&.Mui-disabled": {
      backgroundColor: "#D7D7D7",
      color: "#9F9F9F !important",
      border: "none",
    },
  },
};
