import { useCallback } from "react";

import { type AssetDescriptor, type BillingProfileInvoiceBucket } from "../../../BillingProfileForm.models";

export function useBucketsCRUD(
  buckets: BillingProfileInvoiceBucket[],
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) {
  return {
    setBucketAsDefault: useCallback(
      (newDefaultBucket: BillingProfileInvoiceBucket) => {
        setFieldValue(
          "buckets",
          buckets.map((bucket) => ({ ...bucket, isDefault: bucket === newDefaultBucket }))
        );
      },
      [buckets, setFieldValue]
    ),

    setBucketName: useCallback(
      (bucketToRename: BillingProfileInvoiceBucket, name: string) => {
        setFieldValue(
          "buckets",
          buckets.map((bucket) => (bucket === bucketToRename ? { ...bucketToRename, name } : bucket))
        );
      },
      [buckets, setFieldValue]
    ),

    createBucket: useCallback(
      ({ name }: { name: string }) => {
        setFieldValue("buckets", [
          ...buckets,
          {
            name,
            assets: [],
            isDefault: buckets.length === 0,
          },
        ]);
      },
      [buckets, setFieldValue]
    ),

    deleteBucket: useCallback(
      (bucketToDelete: BillingProfileInvoiceBucket) => {
        setFieldValue(
          "buckets",
          buckets.filter((bucket) => bucket !== bucketToDelete)
        );
      },
      [buckets, setFieldValue]
    ),

    assignAssetsToBucket: useCallback(
      (bucketToAssignTo: BillingProfileInvoiceBucket, assets: AssetDescriptor[]) => {
        setFieldValue(
          "buckets",
          buckets.map((bucket) => (bucket === bucketToAssignTo ? { ...bucket, assets } : bucket))
        );
      },
      [buckets, setFieldValue]
    ),
  };
}
