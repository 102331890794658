import { useMemo } from "react";

import { type DoitRole } from "@doitintl/cmp-models";

import { useUserContext } from "../../Context/UserContext";

export function useDoitRoleCheck(roleName: DoitRole): boolean {
  const { userRoles } = useUserContext();

  return useMemo(() => userRoles?.check(roleName) ?? false, [userRoles, roleName]);
}
