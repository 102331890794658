import { useCallback, useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, CardContent, IconButton, Link, Menu, MenuItem, Typography } from "@mui/material";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetMenuWrapperContext } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/widgetMenuContext";
import DialogSelectRampPlan from "../../Pages/RampPlans/components/DialogSelectRampPlan";
import PerformanceChart from "../../Pages/RampPlans/components/PerformanceChart";
import useGetRampPlanChartData from "../../Pages/RampPlans/hooks/useGetRampPlanChartData";
import useGetRampPlans from "../../Pages/RampPlans/hooks/useGetRampPlans";
import useGetWidgetSavedRampPlan from "../../Pages/RampPlans/hooks/useGetWidgetSavedRampPlan";
import { type RampPlanModel } from "../../Pages/RampPlans/types";
import { consoleErrorWithSentry } from "../../utils";
import EmptyWidgetCard from "./EmptyWidgetCard";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemProps } from "./types";

export default function RampPlansWidgetCard({ widgetHeight = 200 }: WidgetItemProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openThreeDots, setOpenThreeDots] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { rampPlans, loading: loadingRampPlans } = useGetRampPlans();
  const { rampPlanId, loading: loadingRampPlanId, saveWidgetRampPlan } = useGetWidgetSavedRampPlan();
  const [activeRampPlanId, setActiveRampPlanId] = useState<string>(rampPlanId ?? "");
  const [activeRampPlan, setActiveRampPlan] = useState<RampPlanModel>();

  const [deleteWidgetOperation] = useContext(WidgetMenuWrapperContext);

  useEffect(() => {
    if (loadingRampPlanId || loadingRampPlans || !rampPlans) {
      return;
    }

    if (rampPlanId && !activeRampPlanId) {
      setActiveRampPlanId(rampPlanId);
      return;
    }

    if (activeRampPlanId) {
      const rampPlan = rampPlans.find((rampPlan) => rampPlan.id === activeRampPlanId);
      if (rampPlan) {
        setActiveRampPlan(rampPlan);
      } else {
        setActiveRampPlan(undefined);
      }
    } else if (rampPlans?.length) {
      setActiveRampPlanId(rampPlans[0].id);
    } else {
      setActiveRampPlan(undefined);
    }
  }, [activeRampPlan, activeRampPlanId, loadingRampPlanId, loadingRampPlans, rampPlanId, rampPlans]);

  const api = useApiContext();
  const history = useHistory();
  const { customer } = useCustomerContext();

  // bad/empty inputs (e.g. while waiting for load) return empty arrays. No errors here.
  const { actualDataForChart, planDataForChart, originalPlanForChart } = useGetRampPlanChartData(activeRampPlan);

  const updateAttainment = useCallback(
    async (cId: string, planId: string) => {
      try {
        await api.request({
          method: "post",
          url: `/v1/customers/${cId}/ramp-plan`,
          data: {
            planId,
          },
        });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [api]
  );

  const clickLinkToRampPlan = () => {
    if (activeRampPlan) {
      history.push(`/customers/${activeRampPlan.customerRef?.id}/contracts/ramps/${activeRampPlan.id}`);
    }
  };

  if (rampPlans === undefined || loadingRampPlans || loadingRampPlanId) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (!activeRampPlan) {
    return (
      <EmptyWidgetCard
        name="rampPlans"
        widgetHeight={widgetHeight}
        customMessageSettings={{ text: "There are no ramp plans.", url: "" }}
      />
    );
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setOpenThreeDots(!openThreeDots);
  }

  function handleCloseThreeDots() {
    setAnchorEl(null);
    setOpenThreeDots(false);
  }

  const threeDotsMenu = (
    <>
      <IconButton
        id="widget-menu-button"
        aria-controls="basic-menu"
        onClick={(e) => {
          handleClick(e);
          e.stopPropagation();
        }}
        aria-haspopup="true"
        size="large"
        data-cy="widgetThreeDots"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={openThreeDots}
        onClose={handleCloseThreeDots}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disabled={loadingRampPlans || loadingRampPlanId}
          onClick={() => {
            if (rampPlanId) {
              updateAttainment(customer.id, rampPlanId);
            }
            handleCloseThreeDots();
          }}
        >
          <Typography>Refresh</Typography>
        </MenuItem>
        <MenuItem onClick={clickLinkToRampPlan}>
          <Typography data-cy="openRampPlan">Open ramp plan</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          <Typography data-cy="selectRampPlan">Select ramp plan</Typography>
        </MenuItem>
        <MenuItem>
          <Typography
            component={Link}
            href="https://help.doit.com/docs/dashboards/widgets-overview#widgets-1"
            color="inherit"
            sx={{ textDecoration: "none" }}
            target="_blank"
          >
            Learn more about this widget
          </Typography>
        </MenuItem>
        {deleteWidgetOperation && (
          <MenuItem onClick={deleteWidgetOperation}>
            <Typography color="error.main">Remove widget</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );

  return (
    <>
      <Card data-cy="rampPlansWidgetCard">
        <WidgetCardHeader
          title="Ramp plans"
          subheader={activeRampPlan ? activeRampPlan.name : "&nbsp;"}
          action={threeDotsMenu}
          onClick={clickLinkToRampPlan}
        />
        <CardContent sx={{ height: widgetHeight }} data-cy={`ramp-chart-${activeRampPlanId}`}>
          <PerformanceChart
            actualDataForChart={actualDataForChart}
            planDataForChart={planDataForChart}
            originalPlanDataForChart={originalPlanForChart ?? null}
            performanceChartProps={{
              cardProps: { sx: { border: "none" } },
              options: { chart: { height: widgetHeight } },
            }}
          />
        </CardContent>
      </Card>
      <DialogSelectRampPlan
        open={dialogOpen}
        onOpen={handleCloseThreeDots}
        onSave={(rampPlanId: string) => {
          setActiveRampPlanId(rampPlanId);
          saveWidgetRampPlan(rampPlanId);
        }}
        onClose={() => {
          setDialogOpen(false);
        }}
        rampPlansList={rampPlans}
        value={activeRampPlanId}
      />
    </>
  );
}
