import { type ChangeEvent, useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from "@mui/material";

import { cloudflowTexts } from "../../../assets/texts";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { CircularProgressLoader } from "../../../Components/Loader";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { useCloudflowTemplates, useCreateCloudflowFromTemplate } from "../hooks";
import Template from "./Template";
import { TemplateFilters } from "./TemplateFilters";
import type { CloudflowTemplate } from "../types";

const CloudflowTemplates = () => {
  const { customer } = useCustomerContext();
  const theme = useTheme();
  const history = useHistory();
  const { templates, templatesLoading } = useCloudflowTemplates();
  const [createCloudflowFromTemplate, creatingCloudflowFromTemplate] = useCreateCloudflowFromTemplate();
  const [searchQuery, setSearchQuery] = useState("");
  const customerId = useCustomerId();
  const [selectedFilters, setSelectedFilters] = useState<Record<string, boolean>>({});
  const showErrorSnackbar = useErrorSnackbar();

  const onFiltersChange = (filter: object) => {
    setSelectedFilters({ ...selectedFilters, ...filter });
  };

  const returnToDashboard = (): void => {
    history.push(`/customers/${customer.id}/cloudflow`);
  };

  const onTemplateClick = useCallback(
    async (template: CloudflowTemplate) => {
      try {
        const res = await createCloudflowFromTemplate(customer.id, template.id);

        if (res) {
          history.push(`/customers/${customerId}/cloudflow/edit/${res.id}`);
        }
      } catch (e) {
        showErrorSnackbar("Could not create cloudflow from template");
        consoleErrorWithSentry(e);
      }
    },
    [createCloudflowFromTemplate, customer.id, history, customerId, showErrorSnackbar]
  );

  const handleSearchChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredTemplates = useMemo(
    () =>
      templates?.filter((template) => {
        const inSearchQuery = searchQuery
          ? template.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            template.description.toLowerCase().includes(searchQuery.toLowerCase())
          : true;

        const checkboxFiltersActive = selectedFilters && Object.values(selectedFilters).some((filter) => filter);
        if (!checkboxFiltersActive) return inSearchQuery;

        if (!template.tags) return false;

        const inFilters = Object.entries(selectedFilters).every(([filter, isActive]) => {
          if (!isActive) return true;
          return Object.entries(template.tags!).some(([tagGroup, tagValues]) =>
            tagValues.some((tag) => filter === `${tagGroup}:${tag}`)
          );
        });

        return inSearchQuery && inFilters;
      }),
    [searchQuery, selectedFilters, templates]
  );

  if (templatesLoading) {
    return <CircularProgressLoader />;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" alignItems={"center"} sx={{ pt: 1 }}>
        <IconButton aria-label="Back to templates list" onClick={returnToDashboard}>
          <ArrowBackIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Typography variant="h1" data-cy="title" sx={{ fontWeight: "fontWeightMedium" }}>
          {cloudflowTexts.TEMPLATES.PAGE_TITLE}
        </Typography>
      </Stack>

      <Stack direction={{ md: "column", lg: "row" }} spacing={2}>
        <TemplateFilters templates={templates} onFiltersChange={onFiltersChange} selectedFilters={selectedFilters} />
        <Stack direction="column" width="100%">
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search templates"
            autoFocus
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" component="div">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          {filteredTemplates && filteredTemplates.length > 0 ? (
            <Grid
              container
              spacing={2}
              alignContent="flex-start"
              width={`calc(100% + ${theme.spacing(2)})`}
              sx={{ "&.MuiGrid-container": { ml: -2 } }}
            >
              {filteredTemplates.map((template) => (
                <Grid item key={template.id} lg={4} md={6} sm={12} sx={{ pl: 0 }}>
                  <Template
                    key={template.id}
                    template={template}
                    onTemplateClick={onTemplateClick}
                    clouds={template.tags?.cloud || []}
                    disabled={creatingCloudflowFromTemplate}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Stack
              direction="column"
              sx={{ justifyContent: "center", alignItems: "center" }}
              spacing={2.5}
              height="50vh"
            >
              <Typography variant="h1">No results</Typography>
              <Typography textAlign="center" color="textSecondary">
                No templates match the search criteria, try different filters.
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CloudflowTemplates;
