import { useCallback, useState } from "react";

import { type CloudSelectorType } from "@doitintl/cmp-models";

const useCloudSelection = (initialSelectedProviders: CloudSelectorType[]) => {
  const [selectedCloudProviders, setSelectedCloudProviders] = useState<CloudSelectorType[]>(initialSelectedProviders);

  const handleCloudSelection = useCallback((provider: CloudSelectorType) => {
    setSelectedCloudProviders((prevSelected) => {
      if (prevSelected.includes(provider)) {
        return prevSelected.filter((selectedProvider) => selectedProvider !== provider);
      } else {
        return [...prevSelected, provider];
      }
    });
  }, []);

  return {
    selectedCloudProviders,
    handleCloudSelection,
  };
};

export default useCloudSelection;
