import { type JSX, useState } from "react";

import { type SaaSConsoleType } from "@doitintl/cmp-models";

import useMountEffect from "../../../Components/hooks/useMountEffect";
import { Loader } from "../../../Components/Loader";
import { useSaaSConsoleAPI } from "./hooks";

type Props = {
  type: SaaSConsoleType;
  children: JSX.Element;
};

export const InitOnboardingWrapper = ({ type, children }: Props) => {
  const [initCompleted, setInitCompleted] = useState<boolean>(false);
  const saasConsoleAPI = useSaaSConsoleAPI(type);

  useMountEffect(() => {
    saasConsoleAPI.initOnboarding().then(() => {
      setInitCompleted(true);
    });
  });

  return <Loader loading={!initCompleted}>{children}</Loader>;
};
