import { type FC, useEffect, useState } from "react";

import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";

import { ReferencedFieldContextProvider } from "./ReferencedFieldContextProvider";
import { ReferencedFieldWrapper, type ReferencedFieldWrapperFieldProps } from "./ReferencedFieldWrapper";
import { type NodeWitOutputModel } from "./useReferencedFieldContext";

const model: UnwrappedApiServiceModelDescriptor = { type: ModelType.STRUCTURE, members: {} };

export const ReferencedFieldStandalone: FC<
  ReferencedFieldWrapperFieldProps & { referenceableNodes: NodeWitOutputModel[] }
> = ({ error, helperText, label, name, onBlur, onChange, required, value, referenceableNodes }) => {
  const [commonFieldProps, setCommonFieldProps] = useState({
    error,
    helperText,
    label,
    name,
    onBlur,
    onChange,
    required,
    value,
  });
  useEffect(() => {
    setCommonFieldProps({ error, helperText, label, name, onBlur, onChange, required, value });
  }, [error, helperText, label, name, onBlur, onChange, required, value]);

  return (
    <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
      <ReferencedFieldWrapper model={model} commonFieldProps={commonFieldProps} />
    </ReferencedFieldContextProvider>
  );
};
