import { useCallback } from "react";

import { type Classification, type Industry, type IndustryGroup, type Sector } from "@doitintl/cmp-models";

import { useAlgoliaSearchContext } from "../../Context/algoliaContext";
import { useAsyncLoadingFunction } from "../hooks/useAsyncLoadingFunction";

export type CustomerDetails = {
  classification: Classification | null;
  description: string | null;
  domains: string[];
  industry: Industry | null;
  industryGroup: IndustryGroup | null;
  logo: string | null;
  name: string;
  objectID: string;
  primaryDomain: string;
  sector: Sector | null;
  subIndustry: string | null;
  tags: string[] | null;
};

export function useAlgoliaCustomerSearch(): [boolean, (query: string) => Promise<CustomerDetails[]>] {
  const algoliaContext = useAlgoliaSearchContext();

  return useAsyncLoadingFunction(
    useCallback(
      async (query: string): Promise<CustomerDetails[]> => {
        if (!algoliaContext.searchClient) {
          return [];
        }

        const searchResult = await algoliaContext.searchClient.searchForHits<CustomerDetails>({
          requests: [{ indexName: "customers", query, hitsPerPage: 10 }],
        });
        const { results } = searchResult;

        if ("hits" in results[0]) {
          return results[0].hits;
        }

        return [];
      },
      [algoliaContext.searchClient]
    )
  );
}
