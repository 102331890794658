import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type AxiosResponse } from "axios";

import { consoleErrorWithSentry } from "../errorHandling";
import { type CompositeResponse, type SalesforceApi, salesforceBaseUrl, SalesforceRequestMethod } from "./salesforce";
import { UpdateSalesforce } from "./updateSalesforce";
import { type SalesforceCustomer } from "./updateSalesforceAfterEstimation";

const callSalesforceApi = async (
  salesforceApi: SalesforceApi,
  customer: SalesforceCustomer
): Promise<AxiosResponse<CompositeResponse>> =>
  salesforceApi.compositeRequest(
    [
      {
        method: SalesforceRequestMethod.PATCH,
        url: `${salesforceBaseUrl}/sobjects/Account/Firestore_Id__c/${customer.id}`,
        referenceId: "AccountInfo",
        body: {
          Name: customer.name,
          Type: "Prospect",
          RecordTypeId: "0128c000001JaRqAAK",
          Lifecycle_Stage__c: "Opportunity",
          OwnerId: "0058c00000AnNcLAAV",
        },
      },
    ],
    true
  );

const saveCustomerAccountIdInFirestore = async (customerId: string, accountId: string) => {
  try {
    await getCollection(CustomerModel).doc(customerId).update("salesforce.id", accountId);
  } catch (error) {
    consoleErrorWithSentry(error);
  }
};

const callSalesforceAndUpdateFirestore = async (salesforceApi: SalesforceApi, customer: SalesforceCustomer) => {
  if (customer.salesforce?.id) {
    return;
  }

  const response = await callSalesforceApi(salesforceApi, customer);
  const responseStatus = response?.data.compositeResponse[0].httpStatusCode;
  if (responseStatus === 201 || responseStatus === 200) {
    return saveCustomerAccountIdInFirestore(customer.id, response.data.compositeResponse[0].body.id);
  }
};

export const UpdateSalesforceAfterSignup = () => <UpdateSalesforce updateFunction={callSalesforceAndUpdateFirestore} />;
