import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource } from "@algolia/autocomplete-js";
import { getAlgoliaResults, parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";
import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeMicrosoftAzure } from "@doitintl/cmp-models";

import AwsIcon from "../../../assets/amazon-web-services-new.png";
import BettercloudIcon from "../../../assets/bettercloud.png";
import GSuiteIcon from "../../../assets/g-suite.png";
import GCPIcon from "../../../assets/google-cloud.png";
import AzureIcon from "../../../assets/microsoft-azure.png";
import office365Icon from "../../../assets/office-365.png";
import ZendeskIcon from "../../../assets/zendesk.png";
import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { type AlgoliaCustomContext, type AlgoliaIndexType, type AlgoliaPlugin } from "../types";
import { filterByCustomer, getAlgoliaAnalyticsProps, isSearchForCustomer } from "../utils";

type Asset = Hit<{
  objectID: string;
  type: string;
  customerId: string;
  properties: {
    name?: string;
    friendlyName?: string;
    accountId?: string;
    customerDomain?: string;
    displayName?: string;
    billingAccountId?: string;
    projectId?: string;
    subscription?: {
      offerName?: string;
      skuDescription?: string;
      skuName?: string;
    };
  };
  subscription?: {
    skuName?: string;
  };
}>;

const checkStandalonePlatform = (str: string): string => {
  if (str.includes("-standalone")) {
    switch (true) {
      case str.includes(AssetTypeGoogleCloud):
        return AssetTypeGoogleCloud;
      case str.includes(AssetTypeAmazonWebServices):
        return AssetTypeAmazonWebServices;
      case str.includes(AssetTypeMicrosoftAzure):
        return AssetTypeMicrosoftAzure;
      default:
        return str;
    }
  }
  return str;
};

const getAssetUrl = (item: Asset): string => {
  if (item.type !== "google-cloud-project") {
    return `/customers/${item.customerId}/assets/${checkStandalonePlatform(item.type)}/${item.objectID}`;
  } else {
    return `/customers/${item.customerId}/assets/google-cloud`;
  }
};

const getAssetPropertiesByType = (
  item: Asset
): {
  icon: any;
  alt: string;
  titleParts: string[];
  defaultTitle: string;
  defaultSubtitle: string;
  subtitleParts: string[];
} => {
  switch (item.type) {
    case "amazon-web-services-standalone":
    case "amazon-web-services": {
      let titleParts = ["properties", "accountId"];
      if (item.properties.name) {
        titleParts = ["properties", "name"];
      }
      if (item.properties.friendlyName) {
        titleParts = ["properties", "friendlyName"];
      }
      return {
        icon: AwsIcon,
        alt: "Amazon Web Services",
        titleParts,
        defaultTitle: item.properties?.name || "",
        subtitleParts: ["properties", "accountId"],
        defaultSubtitle: item.properties?.accountId || "",
      };
    }
    case "g-suite":
      return {
        icon: GSuiteIcon,
        alt: "G-suite",
        titleParts: ["properties", "subscription", "skuName"],
        defaultTitle: item.properties.subscription?.skuName || "",
        subtitleParts: ["properties", "customerDomain"],
        defaultSubtitle: item.properties?.customerDomain || "",
      };
    case "google-cloud-standalone":
    case "google-cloud-direct":
    case "google-cloud":
      return {
        icon: GCPIcon,
        alt: "Google Cloud",
        titleParts: ["properties", "displayName"],
        defaultTitle: item.properties?.displayName || "",
        subtitleParts: ["properties", "billingAccountId"],
        defaultSubtitle: item.properties?.billingAccountId || "",
      };
    case "google-cloud-project-standalone":
    case "google-cloud-project":
      return {
        icon: GCPIcon,
        alt: "Google Cloud Project",
        titleParts: ["properties", "projectId"],
        defaultTitle: item.properties?.projectId || "",
        subtitleParts: ["properties", "billingAccountId"],
        defaultSubtitle: item.properties?.billingAccountId || "",
      };
    case "office-365":
      return {
        icon: office365Icon,
        alt: "Office 365",
        titleParts: ["properties", "subscription", "offerName"],
        defaultTitle: item.properties?.subscription?.offerName || "",
        subtitleParts: ["properties", "customerDomain"],
        defaultSubtitle: item.properties?.customerDomain || "",
      };
    case "microsoft-azure-standalone":
    case "microsoft-azure":
      return {
        icon: AzureIcon,
        alt: "Microsoft Azure",
        defaultTitle: item.properties?.subscription?.skuDescription || "",
        titleParts: ["properties", "subscription", "skuDescription"],
        subtitleParts: ["properties", "customerDomain"],
        defaultSubtitle: item.properties?.customerDomain || "",
      };
    case "zendesk":
      return {
        icon: ZendeskIcon,
        alt: "Microsoft Azure",
        titleParts: ["properties", "subscription", "skuName"],
        defaultTitle: item.properties?.subscription?.skuName || "",
        subtitleParts: ["properties", "customerDomain"],
        defaultSubtitle: item.properties?.customerDomain || "",
      };
    case "bettercloud":
      return {
        icon: BettercloudIcon,
        alt: "Bettercloud",
        titleParts: ["properties", "subscription", "skuName"],
        defaultTitle: item.properties?.subscription?.skuName || "",
        subtitleParts: ["properties", "customerDomain"],
        defaultSubtitle: item.properties?.customerDomain || "",
      };
    default:
      //  TODO improve this
      return {
        icon: getAssetUrl(item),
        alt: "Default",
        titleParts: ["Unsuported asset type"],
        defaultTitle: "Unsuported asset type",
        subtitleParts: ["Unsuported asset type"],
        defaultSubtitle: "Unsuported asset type",
      };
  }
};

const createAssetsPlugin: AlgoliaPlugin<Asset> = () => ({
  getSources({ query, state }) {
    const context = state.context as AlgoliaCustomContext;

    if (!query || isSearchForCustomer(query, context)) {
      return [];
    }

    const assetsSource: AutocompleteSource<Asset> = {
      sourceId: "assets",
      templates: {
        item({ item }) {
          const properties = getAssetPropertiesByType(item);
          const titleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: properties.titleParts,
          });
          const subtitleParts = parseAlgoliaHitHighlight({
            hit: item,
            attribute: properties.subtitleParts,
          });

          return (
            <AlgoliaResultListItem
              highlightedTitle={titleParts}
              highlightedSubtitle={subtitleParts}
              avatarLogo={properties.icon}
            />
          );
        },
      },
      onSelect({ item, event }) {
        const url = getAssetUrl(item);
        const properties = getAssetPropertiesByType(item);

        context.onSelectRow({
          id: item.objectID,
          label: item.objectID,
          avatarLogo: properties.icon,
          highlightedTitle: [{ value: properties.defaultTitle, isHighlighted: false }],
          highlightedSubtitle: [{ value: properties.defaultSubtitle, isHighlighted: false }],
          url,
          withMetaKey: event.metaKey,
        });
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              indexName: "assets",
              params: {
                query,
                hitsPerPage: 10,
                filters: filterByCustomer(context),
                ...getAlgoliaAnalyticsProps(context),
              },
            },
          ],
          transformResponse({ hits }: { hits: Hit<Asset>[][] }) {
            return hits.map((indexResults) => indexResults.filter((hit) => hit.customerId !== undefined));
          },
        });
      },
      getItemUrl({ item }) {
        return getAssetUrl(item);
      },
    };
    return [assetsSource];
  },
});

export const useAssetsPlugin = (restrictedIndices?: AlgoliaIndexType[]): AutocompletePlugin<Asset, undefined> | null =>
  useMemo(() => {
    if (restrictedIndices?.includes("assets")) {
      return null;
    }
    return createAssetsPlugin();
  }, [restrictedIndices]);
