import { useMemo } from "react";

import { AppModel, type ContractModel, type CurrencyCode } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { Box, Stack, Typography } from "@mui/material";

import DataCouplet from "../../../Components/DataCouplet";
import { getCurrencySymbol, numberWithCommas } from "../../../utils/common";
import { contractDate } from "../utils";

const AcceleratorDetails = ({ contract }: { contract: ContractModel }) => {
  const currency = contract.currency as CurrencyCode;

  const [acceleratorTypeOptions] = useCollectionDataOnce(
    useMemo(() => getCollection(AppModel).doc("contracts").collection("acceleratorTypes"), []),
    {
      idField: "id",
    }
  );

  const acceleratorType = useMemo(
    () => acceleratorTypeOptions?.find((type) => type.id === contract.properties?.typeContext?.id)?.label,
    [acceleratorTypeOptions, contract.properties?.typeContext]
  );

  return (
    <Box component="section" data-cy="accelerator-details">
      <Typography variant="h2">Contract details</Typography>
      <Stack component="dl" spacing={2}>
        <DataCouplet field="Start date" value={contractDate(contract.startDate)} />
        <DataCouplet field="End date" value={contractDate(contract.endDate)} />
        <DataCouplet field="Accelerator type" value={acceleratorType ?? "-"} />
        <DataCouplet
          field="Charge"
          value={
            contract.chargePerTerm ? `${getCurrencySymbol(currency)} ${numberWithCommas(contract.chargePerTerm)}` : "-"
          }
        />

        <DataCouplet
          field="Anticipated Partner Funding"
          value={
            contract.properties?.estimatedFunding
              ? `${getCurrencySymbol(currency)} ${numberWithCommas(contract.properties?.estimatedFunding)}`
              : "-"
          }
        />
      </Stack>
    </Box>
  );
};

export default AcceleratorDetails;
