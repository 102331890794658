import unionBy from "lodash/unionBy";

import {
  LOAD_TICKETS,
  INIT_TICKETS_LIST,
  UPDATE_TICKETS_LIST,
  UPDATE_USERS_LIST,
  UPDATE_TICKET_DETAILS,
  UPDATE_TIMELINE_EVENTS,
  INIT_TICKET_DETAILS,
  CHANGE_SEVERITY,
  UPDATE_CATEGORIES,
  UPDATE_CATEGORY_OBJECT,
  UPDATE_PRODUCT_PROPS,
  CHANGE_CATEGORY_INFO,
  UPDATE_USER_DETAILS,
  SAVE_NEW_TICKET,
  DONE_LOADING,
  UPDATE_COMMENTS,
  SEND_COMMENT,
  SEND_ESCALATE,
  SEND_TAG_UPDATE,
  DONE_TAG_UPDATE,
  DONE_SURVEY,
  UPDATE_USER_CONTEXT,
  UPDATE_KNOWN_ISSUE,
  UPDATE_BUTTON_MODE,
  UPDATE_CUSTOMER_USERS,
  UPDATE_ORGANIZATION,
  UPDATE_TEMPLATE,
  TOGGLE_DOWNLOAD_CSV_ALERT,
  CONCEDEFY_READ_ONLY_ACCESS_EMAIL,
  UPDATE_DEFAULT_EMAILS_LIST,
} from "../../Actions/TicketAction";

const newTicket = {
  platform: "",
  category: "",
  categoryInfo: "",
};

const routeInitialState = {
  ticketList: [],
  isFetchingTicketList: false,
  isLoading: false,
  isLoadingTicketDetails: false,
  userList: [],
  ticketDetails: {},
  timelineEvents: [],
  categories: [],
  platforms: [],
  clientProductsProperties: [],
  createTicket: newTicket,
  userDetails: {},
  ticketComments: {},
  isDoneSurvey: false,
  slas: [],
  organization: {},
  contextUser: {},
  knownIssues: null,
  buttonMode: "new ticket",
  customerUsers: [],
  concedefyReadOnlyEmail: "",
  defaultEmailsList: [],
  errorMsg: null,
};

export default function ticketsReducer(state = routeInitialState, action) {
  switch (action.type) {
    case LOAD_TICKETS:
      return {
        ...state,
        isFetchingTicketList: true,
      };
    case TOGGLE_DOWNLOAD_CSV_ALERT:
      return { ...state, isDownloadingCSV: !state.isDownloadingCSV };
    case INIT_TICKETS_LIST:
      return {
        ...state,
        ticketList: [],
        userList: [],
        ticketDetails: {},
        isFetchingTicketList: true,
        defaultEmailsList: [],
      };
    case UPDATE_TICKETS_LIST:
      return {
        ...state,
        ticketList: unionBy(action.payload, state?.ticketList ?? [], "id"),
        isFetchingTicketList: action.loading,
      };
    case UPDATE_USERS_LIST:
      return {
        ...state,
        userList: unionBy(action.payload, state?.userList ?? [], "id"),
      };
    case UPDATE_TICKET_DETAILS:
      return {
        ...state,
        ticketDetails: action.payload,
        ticketList: unionBy([action.payload], state?.ticketList ?? [], "id"),
        isLoadingTicketDetails: false,
        isLoading: false,
      };
    case UPDATE_TIMELINE_EVENTS:
      return {
        ...state,
        timelineEvents: action.payload,
      };
    case INIT_TICKET_DETAILS:
      return {
        ...state,
        timelineEvents: [],
        ticketDetails: {},
        ticketComments: {},
      };
    case CHANGE_SEVERITY:
      return {
        ...state,
        ticketDetails: { ...state.ticketDetails, priority: action.payload },
      };
    case UPDATE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        platforms: action.payloadPlatforms,
        slas: action.payloadSlas,
      };
    case UPDATE_CATEGORY_OBJECT:
      return {
        ...state,
        ticketDetails: { ...state.ticketDetails, categoryObject: action.payload },
      };
    case UPDATE_PRODUCT_PROPS:
      return {
        ...state,
        clientProductsProperties: action.payload,
      };
    case CHANGE_CATEGORY_INFO:
      return {
        ...state,
        ticketDetails: {
          ...state.ticketDetails,
          categoryObject: { ...state.ticketDetails.categoryObject, categoryInfo: action.payload },
        },
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        organization: action.organization,
      };
    case SAVE_NEW_TICKET:
      return {
        ...state,
        isLoading: true,
        errorMsg: null,
      };
    case DONE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        isLoadingTicketDetails: action.payload,
        errorMsg: action.error,
      };
    case UPDATE_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
      };
    case SEND_ESCALATE:
    case SEND_TAG_UPDATE:
    case SEND_COMMENT:
      return {
        ...state,
        isLoading: true,
      };
    case DONE_TAG_UPDATE:
      return {
        ...state,
        isLoading: action.payload,
      };
    case DONE_SURVEY:
      return {
        ...state,
        isDoneSurvey: action.payload,
      };
    case UPDATE_USER_CONTEXT:
      return {
        ...state,
        contextUser: action.payload,
      };
    case UPDATE_KNOWN_ISSUE:
      return {
        ...state,
        knownIssues: action.payload,
      };
    case UPDATE_BUTTON_MODE:
      return {
        ...state,
        buttonMode: action.payload,
      };
    case UPDATE_CUSTOMER_USERS:
      return {
        ...state,
        customerUsers: action.payload,
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
      };
    case UPDATE_TEMPLATE:
      return {
        ...state,
        createTicket: action.payload,
      };
    case CONCEDEFY_READ_ONLY_ACCESS_EMAIL:
      return {
        ...state,
        concedefyReadOnlyEmail: action.payload,
      };
    case UPDATE_DEFAULT_EMAILS_LIST:
      return {
        ...state,
        defaultEmailsList: action.payload,
      };

    default:
      return state;
  }
}
