import { type ReactNode } from "react";

import { Box, Card, Radio, Stack, type SxProps, type Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { type TypographyProps } from "@mui/material/Typography/Typography";

import { useFullScreen } from "../../utils/dialog";

type Props = {
  title: string;
  titleCaption?: string;
  description?: ReactNode;
  descriptionTypographyProps?: TypographyProps;
  disabled?: boolean;
  selected: boolean;
  color?: string;
  onClick: () => void;
  cardSx?: SxProps<Theme>;
};

export const RadioCard = ({
  title,
  titleCaption,
  description = "",
  descriptionTypographyProps,
  disabled = false,
  selected,
  color,
  onClick,
  cardSx,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useFullScreen("sm");
  const colors = selected
    ? { text: "text.primary", card: "primary.main", borderColor: "primary.main", borderWidth: 2 }
    : { text: "text.primary", card: color, borderColor: theme.palette.general.outlineBorder, borderWidth: 1 };

  const hasDescription = typeof description === "string";

  return (
    <Card
      onClick={onClick}
      sx={{
        maxWidth: theme.breakpoints.values.sm,
        minWidth: isMobile ? 0 : theme.breakpoints.values.sm,
        borderColor: colors.borderColor,
        borderLeftColor: colors.card,
        borderWidth: colors.borderWidth,
        borderLeftWidth: 4,
        mb: 1,
        cursor: "pointer",
        ...cardSx,
      }}
    >
      <Stack direction="row" sx={{ mb: hasDescription ? 2 : 1.1 }}>
        <Box sx={{ mt: 1, ml: 1 }}>
          <Radio size="small" color="primary" checked={selected} disabled={disabled} />
        </Box>
        <Stack spacing={1} sx={{ margin: theme.spacing(2, 2, 0, 1) }}>
          <Typography variant="body1" sx={{ color: disabled ? "text.disabled" : colors.text, fontWeight: 500 }}>
            {title}
            {!!titleCaption && (
              <Typography component="span" variant="caption" color="warning.dark" ml={1}>
                {titleCaption}
              </Typography>
            )}
          </Typography>
          {hasDescription ? (
            <Typography variant={descriptionTypographyProps?.variant ?? "body1"} color="text.secondary">
              {description}
            </Typography>
          ) : (
            description
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
