import { type PurchaseStatus } from "@doitintl/cmp-models";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { type BulkPurchaseItem } from "../types";

const itemsDescriptions: FilterTableItemDescriptor<BulkPurchaseItem>[] = [
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "familyType",
    filterType: "string",
  },
  {
    headerText: "Hardware",
    value: "hardware",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterOptions: {
      toOption: (value: PurchaseStatus) => ({
        value,
        label: value.toUpperCase(),
      }),
    },
  },
  {
    headerText: "Global customers",
    value: "totalCustomers",
  },
  {
    headerText: "Global usage",
    value: "totalOnDemand",
  },
  {
    headerText: "Global coverage",
    value: "coverage",
  },
  {
    headerText: "Global coverage after purchase",
    value: "globalCoverageAfterPurchase",
  },
  {
    headerText: "Global total CUDs",
    value: "totalCuds",
  },
  {
    headerText: "Global DoiT CUDs",
    value: "doitCuds",
  },
  {
    headerText: "CUDs to purchase",
    value: "purchaseRecommendations",
  },
  {
    headerText: "Customers to purchase",
    value: "customersCount",
  },
  {
    headerText: "Commitment cost",
    value: "purchasePlanPrice",
  },
  {
    value: "@",
    headerText: "",
  },
];

export const defaultFilters = [
  {
    column: {
      label: "Status",
      path: "status",
    },
    comparator: "==",
    value: "purchase-required",
    label: "Purchase required",
  },
] as const;

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
