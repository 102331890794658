import { TimeInterval } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

export const getDiffWeek = (data: string) => {
  let lastUpdateDate = DateTime.fromISO(data);

  const arr: string[] = [];
  while (DateTime.local() > lastUpdateDate.toLocal().plus({ days: 7 })) {
    lastUpdateDate = lastUpdateDate.toLocal().plus({ days: 7 });
    const dt = lastUpdateDate.toISODate();
    if (dt) {
      arr.push(dt);
    }
  }
  return arr;
};

/**
 * Given a date, works out how much of the month containing that date remains un-elapsed after that date.
 * The passed date is NOT counted as having elapsed, so passing the first day of the month will return 0.
 * @param date JS milliseconds timestamp
 */
export const fractionOfMonthRemaining = (date = Date.now()) => {
  const currentDate = DateTime.fromMillis(date).toUTC();
  const daysInMonth = currentDate.daysInMonth;

  if (!daysInMonth) {
    return 0;
  }
  return 1 - (currentDate.day - 1) / daysInMonth;
};

/**
 * Given a date, works out how much of the year containing that date remains un-elapsed after that date.
 * The passed date is NOT counted as having elapsed, so running this on the first day of the year will return 0.
 * @param date JS milliseconds timestamp
 */
export const fractionOfYearRemaining = (date = Date.now()) => {
  const currentDate = DateTime.fromMillis(date).toUTC();
  return 1 - (currentDate.ordinal - 1) / currentDate.daysInYear;
};

export const dateDiff = (date1: Date, date2: Date, interval: TimeInterval) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const diffTime = Math.abs(d2.getTime() - d1.getTime());
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();

  switch (interval) {
    case TimeInterval.HOUR:
      return Math.trunc(diffTime / (1000 * 60 * 60));
    case TimeInterval.DAY:
      return Math.trunc(diffTime / (1000 * 60 * 60 * 24));
    case TimeInterval.WEEK:
      return Math.trunc(diffTime / (1000 * 60 * 60 * 24 * 7));
    case TimeInterval.MONTH:
      return months <= 0 ? 0 : months;
    case TimeInterval.QUARTER:
      return months <= 0 ? 0 : Math.floor(months / 3);
    case TimeInterval.YEAR:
      return months <= 0 ? 0 : Math.floor(months / 12);
  }
};
