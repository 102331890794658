import { type JSX, useMemo } from "react";

import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";

type Props = {
  title: string;
  subtitle: JSX.Element | string;
  secondaryDisableText?: string;
  defaultButtonText: string;
  defaultButtonIcon?: JSX.Element;
  onClick: () => void;
  onClickDone: () => void;
  done?: boolean;
  doneWithoutAction?: boolean;
  error?: boolean;
  info?: boolean;
  disabled?: boolean;
};

export const OnboardingTask = ({
  title,
  subtitle,
  defaultButtonText,
  secondaryDisableText,
  onClick,
  done = false,
  error = false,
  info = false,
  doneWithoutAction = false,
  disabled = false,
  defaultButtonIcon,
  onClickDone,
}: Props) => {
  const [markColor, markIcon, buttonTextToShow] = useMemo(() => {
    if (info) {
      return [orange[600], <InfoIcon key="icon" sx={{ color: "white" }} fontSize="small" />, undefined];
    } else if (error) {
      return [red[600], <WarningIcon key="icon" sx={{ color: "white" }} fontSize="small" />, "Fix issue"];
    } else if (done) {
      return [green[500], <CheckIcon key="icon" sx={{ color: "white" }} fontSize="small" />, "View"];
    } else if (doneWithoutAction) {
      return [green[500], <CheckIcon key="icon" sx={{ color: "white" }} fontSize="small" />, undefined];
    } else {
      return [undefined, undefined, defaultButtonText];
    }
  }, [info, error, done, doneWithoutAction, defaultButtonText]);

  let dataCyBtn = "btn-set-flexsave";
  if (title === "Provide payment information") {
    dataCyBtn = "btn-payment";
  } else if (title === "Savings estimate") {
    dataCyBtn = "btn-estimation";
  }

  return (
    <Paper variant="outlined">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" sx={{ flexGrow: 1 }}>
          {(done || error || info || doneWithoutAction) && (
            <Box
              borderRadius="3px 0px 0px 3px"
              sx={{
                backgroundColor: markColor,
                width: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {markIcon}
            </Box>
          )}
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            sx={{ flexGrow: 1, pb: { xs: 3, sm: 0 } }}
          >
            <Stack p={3}>
              <Stack direction="row" alignItems="center">
                <Typography fontWeight={500} color={disabled ? "text.disabled" : "text.primary"} mr={2.2}>
                  {title}
                </Typography>
                {disabled && (
                  <Typography color="text.secondary" variant="body2" ml={0.7} display="inline-flex" alignItems="center">
                    {!!secondaryDisableText && (
                      <BlockIcon sx={{ color: "text.disabled", opacity: 0.4, mr: "5px" }} fontSize="small" />
                    )}
                    {secondaryDisableText}
                  </Typography>
                )}
              </Stack>
              <Typography color={disabled ? "text.disabled" : "text.secondary"} pt={1.2}>
                {subtitle}
              </Typography>
            </Stack>
            {!!buttonTextToShow && (
              <Button
                data-cy={dataCyBtn}
                disabled={disabled}
                variant="contained"
                startIcon={defaultButtonIcon}
                sx={{ mr: 4, whiteSpace: "nowrap", minWidth: "unset", ml: { xs: 3, sm: 0 } }}
                onClick={done ? onClickDone : onClick}
              >
                {buttonTextToShow}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
