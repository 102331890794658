export type GoogleAddressComponent = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type GoogleAddress = {
  country?: string;
  state?: string;
  stateCode?: string;
  city?: string;
  streetName?: string;
  streetNumber?: string;
  postalCode?: string;
};

export class GoogleAddressParser {
  private readonly address: GoogleAddress;

  constructor() {
    this.address = {};
  }

  parseNewAddress(addressComponents: GoogleAddressComponent[]): GoogleAddress {
    for (const element of addressComponents) {
      const component: GoogleAddressComponent = element;

      if (this.isStreetNumber(component)) {
        this.address.streetNumber = component.long_name;
      }

      if (this.isStreetName(component)) {
        this.address.streetName = component.long_name;
      }

      if (this.isCity(component)) {
        this.address.city = component.long_name;
      }

      if (this.isCountry(component)) {
        this.address.country = component.long_name;
      }

      if (this.isState(component)) {
        this.address.state = component.long_name;
        this.address.stateCode = component.short_name;
      }

      if (this.isPostalCode(component)) {
        this.address.postalCode = component.long_name;
      }
    }
    return this.address;
  }

  getAddress(): GoogleAddress {
    return this.address;
  }

  private isStreetNumber(component: GoogleAddressComponent): boolean {
    return component.types.includes("street_number");
  }

  private isStreetName(component: GoogleAddressComponent): boolean {
    return component.types.includes("route");
  }

  private isCity(component): boolean {
    return component.types.includes("locality");
  }

  private isState(component): boolean {
    return component.types.includes("administrative_area_level_1");
  }

  private isCountry(component): boolean {
    return component.types.includes("country");
  }

  private isPostalCode(component): boolean {
    return component.types.includes("postal_code");
  }
}
