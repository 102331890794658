import { type Dispatch, type SetStateAction } from "react";

import { AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import isEmpty from "lodash/isEmpty";

import { type ContractStateType } from "../../types";
import { ifContractDatesValid } from "../../utils";

export const validateContractStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, any> = {};

  if (!state.startDate?.isValid) {
    errors.startDate = true;
  }

  if ((state.isCommitment || state.isSoftCommitment) && !ifContractDatesValid(state.startDate, state.endDate)) {
    errors.endDate = true;
  }

  if (
    state.isCommitment &&
    state.type === AssetTypeGoogleCloud &&
    !state.rebaseModifier &&
    state.rebaseModifier !== 0
  ) {
    errors.rebaseModifier = true;
  }

  if (state.discountEndDate && (!state.discountEndDate.isValid || state.discountEndDate < state.startDate)) {
    errors.discountEndDate = true;
  }

  if (!state.partnerMargin) {
    errors.partnerMargin = true;
  }

  for (const [creditName, credit] of Object.entries(state.credits)) {
    if (credit.amount === "" || parseFloat(credit.amount) === 0 || credit.description.trim() === "") {
      errors.credits = errors.credits ?? {};
      errors.credits[creditName] = {
        amount: credit.amount === "" || parseFloat(credit.amount) === 0,
        description: credit.description.trim() === "",
      };
    }
  }

  setState({ ...state, errors });

  return isEmpty(errors);
};
