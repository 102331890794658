import { globalText, organizationsText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import { type HeaderColumn } from "../../../types/FilterTable";

const nameColumn = {
  label: globalText.NAME,
  path: "data.name",
  comparators: ["contains"],
} as const;

const lastModifiedColumn = {
  label: globalText.LAST_MODIFIED,
  path: "data.timeModified",
  comparators: ["<", "<=", ">", ">="],
  placeholder: "YYYY-MM-DD",
  ...firestoreTimestampFilterProps("yyyy-LL-dd"),
} as const;

export const filterColumns = [nameColumn, lastModifiedColumn] as const;

export const headers: HeaderColumn[] = [
  {
    value: "data.name",
    label: organizationsText.ORG_NAME,
    tooltip: organizationsText.ORG_NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.timeModified",
    label: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "users",
    label: organizationsText.USERS_IN_ORG,
    tooltip: organizationsText.USERS_IN_ORG_VERBOSE,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.scope",
    label: organizationsText.ATTRIBUTIONS,
    tooltip: organizationsText.ATTRIBUTIONS_TOOLTIP,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
];
