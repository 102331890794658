import { type AwsMarketplaceAccountState, AwsMarketplaceAccountStatus } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { stringifyTimestamp } from "../../../../../utils/common";
import { type FirestoreTimestamp } from "../../../../../utils/firebase";
import { type accountAndCustomerType, type accountsTableRowType } from "../../types";

const emptyChar = "-";
export function constructTableData(data: accountAndCustomerType[] | undefined): accountsTableRowType[] {
  if (!data?.length) {
    return [];
  }
  return data.map((item) => ({
    accountId: item.accountRef.id || emptyChar,
    customerId: item.account.customer?.id || emptyChar,
    customerPrimaryDomain: item.customer?.primaryDomain || emptyChar,
    customerName: item.customer?.primaryDomain || item.customer?.name || item.customer?._name || emptyChar,
    state: stringifyAccountStatus(item.account.status),
    createdAt: stringifyTimestamp({ timeStamp: item.account.createdAt, fallback: emptyChar }),
    updatedAt: stringifyTimestamp({
      timeStamp: item.account.updatedAt || item.account.createdAt,
      fallback: emptyChar,
    }),
    updatedAtDate: dateTimeFromTimestamp(item.account.updatedAt || item.account.createdAt),
    createdAtDate: dateTimeFromTimestamp(item.account.createdAt),
    awsAccountId: item.account.awsAccountData?.CustomerAWSAccountId ?? "unknown",
  }));
}

function stringifyAccountStatus(status: AwsMarketplaceAccountState) {
  switch (status) {
    case AwsMarketplaceAccountStatus.ACTIVE:
      return "Active";
    case AwsMarketplaceAccountStatus.PENDING:
      return "Active (sign up pending)";
    case AwsMarketplaceAccountStatus.INACTIVE:
      return "Deleted";
    default:
      return emptyChar;
  }
}

function dateTimeFromTimestamp(timestamp: Pick<FirestoreTimestamp, "toDate"> | null | undefined) {
  if (!timestamp) {
    return DateTime.now();
  }
  const date = DateTime.fromJSDate(timestamp.toDate());
  return date.isValid ? date : DateTime.now();
}
