import { useCallback, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { RadioCard } from "../../Components/RadioCard/RadioCard";
import { usePriorities } from "../consts";
import { Platform, type Severity, type SeverityLevels } from "../types";
import { ContentHeader } from "./Stepper/ContentHeader";

type props = {
  seveirty: Severity | null;
  platform?: string;
  onChange: (severity: Severity) => void;
};

export const SeveirtyRadioSelect = ({ seveirty, platform, onChange }: props) => {
  const theme = useTheme();
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityLevels>();
  const hideSeveirtyDescription = Platform.finance === platform || Platform.cmp === platform;
  const priorities = usePriorities();

  const bulletList = useCallback(
    (list: string[]) => (
      <ul style={{ color: theme.palette.text.secondary, paddingLeft: 24 }}>
        {list.map((item: string, index: number) => (
          <li key={index}>
            <Typography variant="body1" color="text.secondary">
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    ),
    [theme.palette.text.secondary]
  );

  const formatDescription = useCallback(
    (val: React.ReactNode) => (Array.isArray(val) ? bulletList(val) : val),
    [bulletList]
  );

  const onChangeSeverity = useCallback(
    (severity: Severity) => {
      setSelectedSeverity(severity.value);
      onChange(severity);
    },
    [onChange]
  );

  const isSeveritySelected = useCallback(
    (severityLevel) =>
      severityLevel.value === selectedSeverity || (!selectedSeverity && severityLevel.value === seveirty?.value),
    [selectedSeverity, seveirty?.value]
  );

  return (
    <Box>
      <ContentHeader title="Issue severity" />
      {priorities.map((severityLevel) => (
        <RadioCard
          key={severityLevel.value}
          title={severityLevel.title ?? ""}
          description={!hideSeveirtyDescription && formatDescription(severityLevel.description)}
          color={severityLevel.color}
          selected={isSeveritySelected(severityLevel)}
          onClick={() => {
            onChangeSeverity(severityLevel);
          }}
        />
      ))}
    </Box>
  );
};
