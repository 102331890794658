import { type Spot0ModelAsgsModel } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import isEqual from "lodash/isEqual";
import orderBy from "lodash/orderBy";
import round from "lodash/round";

import { type AsgInstancesCosts, type AsgItem, OptimizationStatus } from "../types";

// noinspection JSUnusedGlobalSymbols
/**
 * Calculate how much money customer saved using current usage and cost data
 * @param spotRunningHours
 * @param onDemandRunningHours
 * @param instancesCosts
 */
export function calculateSpotVsOnDemandSavings(
  spotRunningHours: number,
  onDemandRunningHours: number,
  instancesCosts: AsgInstancesCosts
): { ratio: number; saving: number } {
  const actualTotalCost =
    onDemandRunningHours * instancesCosts.onDemandHourCost + spotRunningHours * instancesCosts.spotHourCost;

  const costIfUsingOnlyOnDemand = (spotRunningHours + onDemandRunningHours) * instancesCosts.onDemandHourCost;

  return {
    saving: costIfUsingOnlyOnDemand - actualTotalCost,
    ratio: round((actualTotalCost / costIfUsingOnlyOnDemand) * 100, 2),
  };
}

/**
 * Calculate the price of one hour of the current configuration
 */
export function calculateHourlyCost(numSpot: number, numOnDemand: number, instancesCosts: AsgInstancesCosts) {
  return numSpot * instancesCosts.spotHourCost + numOnDemand * instancesCosts.onDemandHourCost;
}

/**
 * Calculate the price of one month of the current configuration
 */
export function calculateMonthlyCost(numSpot: number, numOnDemand: number, instancesCosts: AsgInstancesCosts) {
  return calculateHourlyCost(numSpot, numOnDemand, instancesCosts) * 24 * 30;
}

/**
 * returns optimisation status for given ASG
 * @param data
 */
export function getOptimizationStatus(data: WithFirebaseModel<Spot0ModelAsgsModel>): OptimizationStatus {
  if (data.spotisizeErrorDesc || data.error) {
    return OptimizationStatus.Error;
  }
  const currentRecommendation = data.spotisize.curAsg?.MixedInstancesPolicy?.LaunchTemplate?.Overrides;
  const newRecommendation = data.spotisize.recAsg?.MixedInstancesPolicy?.LaunchTemplate?.Overrides;
  if (
    currentRecommendation &&
    isEqual(orderBy(currentRecommendation, ["InstanceType"]), orderBy(newRecommendation, ["InstanceType"]))
  ) {
    return OptimizationStatus.Optimized;
  } else if (currentRecommendation && newRecommendation) {
    return OptimizationStatus.NewRecommendations;
  }
  return OptimizationStatus.NotOptimized;
}

/**
 * returns potential savings for given ASG
 * @param asg
 */
export function getPotentialSavings(asg: AsgItem): number {
  const desired = asg.configurations.current.desiredCapacity;
  const spotPrice = asg.configurations.current.instancesCosts.spotHourCost;
  const odPrice = asg.configurations.current.instancesCosts.onDemandHourCost;

  const hours = 700; // full month

  const odSpending = desired * odPrice * hours;
  const potentialSpotSpending = desired * spotPrice * hours;
  const odPercentage =
    asg.configurations.recommended === undefined
      ? 0.2
      : asg.configurations.recommended.onDemandPercentageAboveBaseCapacity / 100;
  const alternativeSpending = odPercentage * odSpending + (1 - odPercentage) * potentialSpotSpending;

  return Math.max(odSpending - alternativeSpending, 0);
}
