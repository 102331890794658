import { type AxiosInstance } from "axios";

import { httpMethods } from "../../assets/texts";
import { consoleErrorWithSentry } from "../../utils";

const getBaseURL = (customerID: string, entityID: string) => `/v1/customers/${customerID}/entities/${entityID}`;

export const syncEntityInvoiceAttributions = async ({
  api,
  customerId,
  entityId,
}: {
  api: AxiosInstance;
  customerId: string;
  entityId: string;
}) => {
  try {
    await api.request({
      method: httpMethods.POST,
      url: `${getBaseURL(customerId, entityId)}/sync-invoice-attributions`,
    });
  } catch (error) {
    consoleErrorWithSentry(error);
  }
};
