import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { Stack, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { type ObjectSchema } from "yup";

import { loginTexts } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { type EmailPasswordSubmit } from "../types";

type propsType = {
  enableReinitialize?: boolean;
  email: string | null;
  isLoading: boolean;
  handleSignUp: EmailPasswordSubmit;
  isSignup?: boolean;
  isEmailDisabled?: boolean;
  password?: string;
  focusOnPassword?: boolean;
  validationSchema: ObjectSchema<{
    email: string;
    password: string;
  }>;
};

const EmailAndPasswordFormTemplate = ({
  enableReinitialize,
  email,
  password,
  isLoading,
  handleSignUp,
  validationSchema,
  focusOnPassword,
  isSignup,
  isEmailDisabled,
}: propsType) => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("login");
  useEffect(() => {
    if (focusOnPassword) {
      passwordRef.current?.focus();
    }
  }, [focusOnPassword]);

  return (
    <Formik
      initialValues={{
        email: email || "",
        password: password || "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSignUp}
      enableReinitialize={enableReinitialize}
    >
      {({ errors, isSubmitting, values, handleBlur, handleChange, touched }) => (
        <Form>
          <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
            <TextField
              data-cy="email-input"
              autoFocus
              required
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email ? errors.email : " "}
              label={t("login.email.label")}
              type="email"
              name="email"
              autoComplete="email"
              margin="none"
              variant="outlined"
              fullWidth
              disabled={isLoading || isEmailDisabled}
            />
            <>
              <TextField
                inputRef={passwordRef}
                required
                data-cy="password"
                name="password"
                value={values.password}
                onBlur={handleBlur}
                onChange={handleChange}
                label={loginTexts.LOGIN_PASSWORD_LABEL}
                type="password"
                autoComplete={isSignup ? "new-password" : "current-password"}
                margin="none"
                variant="outlined"
                fullWidth
                disabled={isLoading}
                error={touched.password && Boolean(errors.password)}
                helperText={
                  touched.password && errors.password ? errors.password : loginTexts.PASSWORD_VALIDATION_MESSAGE
                }
              />
            </>
            <LoadingButton
              data-cy="login-cta"
              variant="contained"
              fullWidth
              type="submit"
              loading={isLoading}
              disabled={
                isSubmitting ||
                isLoading ||
                !values.email ||
                Boolean(errors.email) ||
                !values.password ||
                Boolean(errors.password)
              }
              mixpanelEventId="login.email-and-password.signup"
            >
              {t("signup.label")}
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default EmailAndPasswordFormTemplate;
