import { type Hit as AlgoliaHit } from "instantsearch.js/es/types";

import { countryToFlagEmoji, getLanguageEmoji, timezoneToCountry } from "../utils/helpers";

export type PersonData = AlgoliaHit<{
  name: string;
  email: string;
  primary_skills?: string[];
  secondary_skills?: string[];
  all_skills?: string[];
  is_available?: boolean;
  is_focus_time?: boolean;
  is_on_vacation?: boolean;
  is_busy?: boolean;
  is_out_of_office?: boolean;
  is_working_hours?: boolean;
  title?: string;
  avatar?: string;
  timezone?: string;
  slack?: string;
  zendesk_id?: string;
  languages?: string[];
}>;

export class Person {
  readonly name: string;

  readonly email: string;

  readonly primarySkills: string[];

  readonly secondarySkills: string[];

  readonly allSkills: string[];

  readonly isAvailable: boolean;

  readonly isFocusTime: boolean;

  readonly isOnVacation: boolean;

  readonly isBusy: boolean;

  readonly isOutOfOffice: boolean;

  readonly isWorkingHours: boolean;

  readonly title: string;

  readonly avatar: string;

  readonly timezone: string;

  readonly slack: string;

  readonly zendeskId: string;

  readonly languages: string[];

  constructor(hit: PersonData) {
    this.name = hit.name || "";
    this.email = hit.email || "";
    this.primarySkills = hit.primary_skills || [];
    this.secondarySkills = hit.secondary_skills || [];
    this.allSkills = hit.all_skills || [];
    this.isAvailable = hit.is_available || false;
    this.isFocusTime = hit.is_focus_time || false;
    this.isOnVacation = hit.is_on_vacation || false;
    this.isBusy = hit.is_busy || false;
    this.isOutOfOffice = hit.is_out_of_office || false;
    this.isWorkingHours = hit.is_working_hours || false;
    this.title = hit.title || "";
    this.avatar = hit.avatar || "";
    this.timezone = hit.timezone || "";
    this.slack = hit.slack || "";
    this.zendeskId = hit.zendesk_id || "";
    this.languages = hit.languages || [];
  }

  get titleEmoji(): string {
    if (this.isFocusTime) return "🎧";
    if (this.isOutOfOffice) return "🚙";
    if (this.isBusy) return "📅";
    if (!this.isWorkingHours) return "🔴";
    if (this.isOnVacation) return "🌴";
    return "";
  }

  get languageEmojis(): Array<{ emoji: string; name: string }> {
    return this.languages.map((language) => ({
      emoji: getLanguageEmoji(language),
      name: language,
    }));
  }

  get country(): string {
    return this.timezone && timezoneToCountry[this.timezone] ? timezoneToCountry[this.timezone] : "Unknown";
  }

  get countryFlagEmoji(): string {
    return this.country && countryToFlagEmoji[this.country] ? countryToFlagEmoji[this.country] : "🌍";
  }

  get city(): string {
    if (!this.timezone) return "Unknown";
    const parts = this.timezone.split("/");
    return parts.length > 1 ? parts[1].replace(/_/g, " ") : "Unknown";
  }

  get formattedLanguages(): string {
    return this.languages.join(", ");
  }

  get hasPrimarySkills(): boolean {
    return Array.isArray(this.primarySkills) && this.primarySkills.length > 0;
  }

  get hasSecondarySkills(): boolean {
    return Array.isArray(this.secondarySkills) && this.secondarySkills.length > 0;
  }

  get hasAnySkills(): boolean {
    return this.hasPrimarySkills || this.hasSecondarySkills;
  }
}
