import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import { useCloudSpendSummaryData } from "../../../Pages/Customer/db";
import CountUpCard from "../../CountUp/CountUpCard";
import { CardHeaderTitle } from "./CardHeader";
import { type CardID } from "./types";
import { useCloudSummaryReportLinks } from "./useCloudSummaryReportLinks";

export const CloudSpendSummaryCards = () => {
  const history = useHistory();
  const { doneLoading, loadLinks } = useCloudSummaryReportLinks();
  const cards = useCloudSpendSummaryData();

  const onNumberClick = useCallback(
    async (cardId: CardID | undefined) => {
      if (cardId === undefined) {
        return;
      }
      const links = await loadLinks(cardId);
      if (links[cardId]) {
        history.push(links[cardId]);
      }
    },
    [history, loadLinks]
  );

  return (
    <Grid spacing={"20px"} container px={1}>
      {cards?.map((card, i) => (
        <Grid key={`card_${i}`} item sm={4} xs={12}>
          <CountUpCard
            contentPadding={2}
            number={doneLoading ? card.number : 0}
            suffix={card?.suffix}
            prefix={card?.prefix}
            duration={1}
            decimals={card?.decimals}
            text={card.text}
            childBeforeContent={<CardHeaderTitle {...card} />}
            appendChildrenDirection="column"
            numberOnClick={() => onNumberClick(card.id)}
            numberColor="inherit"
          />
        </Grid>
      ))}
    </Grid>
  );
};
