import { Link, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { NumberedList } from "../../../Flexsave/Common/NumberedList";
import { UsefulSnowflakeLinks } from "./UsefulSnowflakeLinks";
import { getSqlScript, type SnowflakeOnboardingRequest } from "./utils";

type Props = {
  setSnowflakeOnboardingRequest: (snowflakeOnboardingRequest: any) => void;
  snowflakeOnboardingRequest: SnowflakeOnboardingRequest;
  attemptedStep2?: boolean;
};

export default function SnowflakeStepConnection({
  setSnowflakeOnboardingRequest,
  snowflakeOnboardingRequest,
  attemptedStep2 = false,
}: Props) {
  return (
    <Stack spacing={4} pb={5} data-cy="template-step">
      <Stack spacing={4}>
        <Typography variant="h1">Set up connection</Typography>
        <NumberedList
          list={[
            <Typography key="0" mb={2}>
              Sign in to the{" "}
              <Link href="https://app.snowflake.com/" target="_blank">
                Snowflake console
              </Link>{" "}
              using an account with administrator permissions.
            </Typography>,
            <Typography key="1" mb={2}>
              Select PROJECTS and WORKSHEETS, and open a worksheet.
            </Typography>,
            <Typography key="2" mb={2}>
              Copy the script below, and paste it in the Snowflake worksheet.
              <Box mt={2}>
                <CopyCodeBlock base={getSqlScript(snowflakeOnboardingRequest.generatedPassword)} maxHeight={200} />
              </Box>
            </Typography>,
            <Typography key="3" mb={2}>
              At the top of the worksheet, select a warehouse to run the script.
            </Typography>,
            <Typography key="4" mb={2}>
              Select a role to run the worksheet as, and expand the RUN button to select RUN ALL.
            </Typography>,
            <Typography key="5" mb={2}>
              Enter your Snowflake account ID
              <Box mt={2}>
                <TextField
                  required
                  error={attemptedStep2 && snowflakeOnboardingRequest.accountId === ""}
                  label="Account ID"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setSnowflakeOnboardingRequest({
                      ...snowflakeOnboardingRequest,
                      accountId: event.target.value,
                    });
                  }}
                  defaultValue={snowflakeOnboardingRequest.accountId}
                />
                <Typography variant="caption" color="text.secondary">
                  To find your account ID, see{" "}
                  <Link
                    href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#finding-the-organization-and-account-name-for-an-account"
                    target="_blank"
                    underline="none"
                  >
                    Snowflake's documentation
                  </Link>
                </Typography>
              </Box>
            </Typography>,
            <Typography key="6" mb={2}>
              Enter your Snowflake organization ID
              <Box mt={2}>
                <TextField
                  error={attemptedStep2 && snowflakeOnboardingRequest.orgId === ""}
                  required
                  label="Organization ID"
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    setSnowflakeOnboardingRequest({
                      ...snowflakeOnboardingRequest,
                      orgId: event.target.value,
                    });
                  }}
                  defaultValue={snowflakeOnboardingRequest.orgId}
                />
                <Typography variant="caption" color="text.secondary">
                  To find your organization ID, see{" "}
                  <Link
                    href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#finding-the-organization-and-account-name-for-an-account"
                    target="_blank"
                    underline="none"
                  >
                    Snowflake's documentation
                  </Link>
                </Typography>
              </Box>
            </Typography>,
          ]}
        />
      </Stack>
      <UsefulSnowflakeLinks snowflakeLink="https://docs.snowflake.com/en/developer" />
    </Stack>
  );
}
