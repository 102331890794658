import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { useApiContext } from "../../api/context";
import { queryKeys } from "../../constants";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type Insight, type InsightResultsResponse, type NonEntitledInsightsResults } from "./types";

type InsightsState = {
  insights: Insight[] | undefined;
  nonEntitledSummary: NonEntitledInsightsResults | undefined;
  isFetching: boolean;
  error: Error | null;
};

export const useInsights = (): InsightsState => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const api = useApiContext();
  const { isDoitEmployee } = useAuthContext();

  const { isFetching, data, error } = useQuery<InsightResultsResponse, AxiosError>({
    queryKey: [customer.id, queryKeys.insights],
    queryFn: async (): Promise<InsightResultsResponse> => {
      const response = await api.get<InsightResultsResponse>("insights/results", {
        params: { customerID: customer.id },
      });

      return response.data;
    },

    // Keep the data for an hour for now, as it's unlikely to change often - this way,
    // the user can navigate between insights without getting a loading indicator
    staleTime: 60 * 60 * 1000,
  });

  // Double safety - the API does not return internal insights for non-DoiT employees,
  // but just in case something goes wrong there, filter them again
  return useMemo(() => {
    if (data === undefined) {
      return { insights: undefined, nonEntitledSummary: undefined, isFetching, error };
    }

    if (!isDoitEmployee) {
      const nonInternalInsights = data.results ? data.results.filter((insight) => !insight.isInternal) : data.results;

      return { insights: nonInternalInsights, nonEntitledSummary: data.nonEntitledResultsSummary, isFetching, error };
    }

    return { insights: data.results, nonEntitledSummary: data.nonEntitledResultsSummary, isFetching, error };
  }, [data, error, isDoitEmployee, isFetching]);
};

export const createInsight = async ({ api, insight }) => api.post(`/insights/results`, insight);
