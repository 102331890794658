import { useCallback, useState } from "react";

export function useAsyncLoadingFunction<Fn extends (...args: any[]) => Promise<any>>(
  functionToWrap: Fn
): [boolean, (...args: Parameters<Fn>) => ReturnType<Fn>] {
  const [isLoading, setLoading] = useState(false);

  const wrappedFunction = useCallback(
    (...args: Parameters<Fn>): ReturnType<Fn> => {
      setLoading(true);
      return functionToWrap(...args).finally(() => {
        setLoading(false);
      }) as ReturnType<Fn>;
    },
    [functionToWrap]
  );

  return [isLoading, wrappedFunction];
}
