import { type TimeInterval } from "@doitintl/cmp-models";
import { Box, Stack, TextField } from "@mui/material";
import { StaticDatePicker, type StaticDatePickerProps } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { type IDateRange, isDateInRangeOfTimeInterval } from "./dateRangeUtils";
import WeekPickerDay from "./WeekPickerDay";

const Empty = () => <></>;

type CustomStaticDateRangePickerProps = {
  dateRange: IDateRange;
  isViewOnly: boolean;
  calendar1CurrentMonth: DateTime;
  calendar2CurrentMonth: DateTime;
  onChange: (date: DateTime | null) => void;
  disableCurrent?: TimeInterval;
  disableFuture?: boolean;
};

const CustomStaticDateRangePicker = ({
  dateRange,
  isViewOnly,
  calendar1CurrentMonth,
  calendar2CurrentMonth,
  onChange,
  disableCurrent,
  disableFuture = true,
}: CustomStaticDateRangePickerProps) => {
  const defaultCalendarProps = (dateRange: IDateRange, key: string, value: DateTime) => {
    const props: StaticDatePickerProps<DateTime, DateTime> = {
      displayStaticWrapperAs: "desktop",
      renderDay: (day, selectedDates, pickersDayProps) => {
        const isBetween = isDateInRangeOfTimeInterval(day, disableCurrent);
        return (
          <WeekPickerDay
            date={day}
            endDate={dateRange.end}
            key={key + day.toISO()}
            onClick={onChange}
            pickersDayProps={pickersDayProps}
            selectedDates={selectedDates}
            startDate={dateRange.start}
            disabled={isBetween}
          />
        );
      },
      renderInput: (params) => <TextField {...params} />,
      value,
      onChange: () => {},
    };

    if (isViewOnly) {
      props.views = ["day"];
      props.components = {
        LeftArrowButton: Empty,
        RightArrowButton: Empty,
        SwitchViewButton: Empty,
      };
    }
    return props;
  };
  return (
    <Stack
      data-cy="custom-static-date-range-picker"
      direction="row"
      spacing={2}
      sx={{ pointerEvents: isViewOnly ? "none" : "initial" }}
    >
      <Box data-cy="date-picker-1">
        <StaticDatePicker
          {...defaultCalendarProps(dateRange, "1", calendar1CurrentMonth)}
          minDate={DateTime.utc(2019)}
          maxDate={DateTime.utc().minus({ month: 1 }).endOf("month")}
        />
      </Box>
      <Box data-cy="date-picker-2">
        <StaticDatePicker
          {...defaultCalendarProps(dateRange, "2", calendar2CurrentMonth)}
          disableFuture={disableFuture}
          minDate={DateTime.utc(2019)}
          value={calendar2CurrentMonth}
        />
      </Box>
    </Stack>
  );
};

export default CustomStaticDateRangePicker;
