import { type PerkModule } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { type StorageReference } from "firebase/storage";

import Editor from "../../Support/Components/Editor/Editor";

export default function ModuleList({
  modulesData,
  onUpdate,
  sectionTitle,
  perkId,
  bucketRef,
  isLoadingBucket,
}: {
  modulesData: PerkModule[];
  onUpdate: (a: PerkModule[]) => void;
  sectionTitle: string;
  perkId: string;
  bucketRef: StorageReference | null;
  isLoadingBucket: boolean;
}) {
  const onUpdateModule = (index, field, html, source?) => {
    // Blocking first api change
    if (source === "api") {
      return;
    }

    modulesData[index] = { ...modulesData[index], [field]: html };

    onUpdate([...modulesData]);
  };

  const deleteModule = (index) => {
    const tmpArr = [...modulesData];
    tmpArr.splice(index, 1);
    onUpdate(tmpArr);
  };

  const addModule = () => {
    onUpdate([...modulesData, { title: "", description: "" }]);
  };

  return (
    <div>
      {modulesData.map((module, index) => (
        <Box key={index} mb={5}>
          <Stack direction="row" mb={3}>
            <TextField
              label={`${sectionTitle.replace(/s$/, "")} name`}
              value={module.title}
              onChange={({ target }) => {
                onUpdateModule(index, "title", target.value);
              }}
              inputProps={{
                "data-cy": `module${index}-title-textfield`,
              }}
            />
            <Button
              onClick={() => {
                deleteModule(index);
              }}
              color="error"
              data-cy={`module${index}-delete-button`}
            >
              <DeleteIcon />
              Remove {sectionTitle.replace(/s$/, "").toLowerCase()}
            </Button>
          </Stack>
          <Typography variant="body1" data-cy={`module${index}-description-label`}>
            Description
          </Typography>
          <Editor
            bucketRef={bucketRef}
            isLoadingBucket={isLoadingBucket}
            resourceName={`perks/${perkId}/${sectionTitle.replace(/s$/, "").toLowerCase()}/description`}
            value={module.description}
            onChange={(html, _, source) => {
              onUpdateModule(index, "description", html, source);
            }}
          />
        </Box>
      ))}

      <Button onClick={addModule} sx={{ mb: 3 }} data-cy="add-module-button">
        <AddIcon />
        Add new {sectionTitle.replace(/s$/, "").toLowerCase()}
      </Button>
    </div>
  );
}
