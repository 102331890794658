import { type GridRow, type WidgetItem, type WidgetWidth } from "./useWidgetItemsWithRows";

export function extractNumbers(id: string): number[] {
  const split = id.split("_").map(Number);
  return [split[0], split[2]];
}

export function getRowIdByItemId(rows: GridRow[], itemId: string): [number, number] {
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];

    for (let itemIndex = 0; itemIndex < row.items.length; itemIndex++) {
      const item = row.items[itemIndex];

      if (item.name === itemId) {
        return [rowIndex, itemIndex];
      }
    }
  }

  return [-1, -1];
}

export function normalizeRowWidths(row: GridRow) {
  const totalWidth = row.items.reduce((sum, item) => sum + item.width, 0);

  // If total width is already 3, no need to modify this row
  if (totalWidth === 3) {
    return;
  }

  if (row.items.length === 1) {
    row.items[0].width = 3;
  } else if (row.items.length === 2) {
    row.items[0].width = 1.5;
    row.items[1].width = 1.5;
  } else {
    row.items[0].width = 1;
    row.items[1].width = 1;
    row.items[2].width = 1;
  }
}

export function getWidth(width: WidgetWidth) {
  switch (width) {
    case 1:
      return 0.333;
    case 1.5:
      return 0.5;
    case 2:
      return 0.666;
    case 3:
      return 1;
  }
}

export function calculateWidthWithDelta(item: WidgetItem, total: number, delta: number) {
  const minWidth = total * 0.333;
  const maxWidth = total * 0.666;

  let result = getWidth(item.width) * total + delta;

  if (result > maxWidth) {
    result = maxWidth;
  } else if (result < minWidth) {
    result = minWidth;
  }

  return result;
}
