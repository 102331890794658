import { useCallback, useMemo } from "react";

import { Grid, Link, Tooltip, Typography } from "@mui/material";

import { budgetTxt } from "../../../../assets/texts/CloudAnalytics/budget";
import { InfoPanel } from "../../../../Components/Alerts/InfoPanel";
import { getScopeDescription } from "../../../../Pages/CloudAnalytics/alerts/shared";
import { useAnalyticsContext } from "../../../../Pages/CloudAnalytics/CloudAnalyticsContext";
import { type AttributionFilter, type AttributionWRef, type MetadataOption } from "../../../../types";
import { assetTypeName } from "../../../../utils/common";

type Props = {
  setOverrideStep: (step: number) => void;
  filters?: AttributionFilter[];
  dimensionOptions: MetadataOption[];
  typeAndFrequency: string;
  startDate: string;
  endDate?: string;
  scope: AttributionWRef[];
};

const BudgetInfoPanel = ({
  typeAndFrequency,
  startDate,
  endDate,
  setOverrideStep,
  filters,
  dimensionOptions,
  scope,
}: Props) => {
  const { transforms } = useAnalyticsContext();

  const getDimensionLabelFromId = useCallback(
    (id: string) => {
      const dimension = dimensionOptions.find((d) => d.id === id);
      return dimension?.data?.label ?? id;
    },
    [dimensionOptions]
  );

  const scopeTooltips = useMemo(
    () =>
      scope.map((s, index) => (
        <Tooltip
          title={
            <>
              {s.data.filters?.map((f) => (
                <Typography variant="body2" key={f.id}>
                  <b>{getDimensionLabelFromId(f.id)}</b> {f.values ? "is " : "matches regexp "}
                  <b>{f.values?.map((value) => assetTypeName(value)).join(", ") ?? `"${f.regexp}"`}</b>
                </Typography>
              ))}
            </>
          }
          placement="top"
          arrow
          key={s.ref.id}
        >
          <Typography variant="body2" display="inline" component="span">
            <Typography
              variant="body2"
              display="inline"
              sx={{ textDecorationLine: "underline", textDecorationStyle: "dotted" }}
            >
              {s.data.name}
            </Typography>
            {index === scope.length - 1 ? "" : ","}{" "}
          </Typography>
        </Tooltip>
      )),
    [getDimensionLabelFromId, scope]
  );

  const dimensionsTooltip = useMemo(() => {
    const { label, values, desc } = getScopeDescription(filters, dimensionOptions, transforms);
    return (
      <Tooltip
        title={
          <Typography variant="body2">
            <b>{label}</b> {desc}
            <b>{values}</b>
          </Typography>
        }
        placement="top"
        arrow
      >
        <Typography
          variant="body2"
          display="inline"
          sx={{ textDecorationLine: "underline", textDecorationStyle: "dotted" }}
        >
          {label}
        </Typography>
      </Tooltip>
    );
  }, [filters, dimensionOptions, transforms]);

  return (
    <InfoPanel>
      <Grid container item xs={12}>
        <Grid item container xs={8} sx={{ variant: "outlined" }}>
          <Typography variant="body2" sx={{ alignSelf: "right" }}>
            <b>{budgetTxt.CREATE_BUDGET.INFO_PANEL.TYPE_AND_FREQUENCY}:</b> {typeAndFrequency}
          </Typography>
        </Grid>
        <Grid item container xs={4} justifyContent="flex-end">
          <Link
            color="inherit"
            variant="body2"
            underline="always"
            href="#"
            onClick={() => {
              setOverrideStep(0);
            }}
          >
            {budgetTxt.CREATE_BUDGET.INFO_PANEL.EDIT_BUDGET_SCOPE}
          </Link>
        </Grid>
        <Grid item display="inline-flex" xs={9.3} sx={{ variant: "outlined" }}>
          <Typography variant="body2" sx={{ alignSelf: "right" }}>
            <b>{budgetTxt.CREATE_BUDGET.INFO_PANEL.SCOPE}:</b>{" "}
            {scopeTooltips.length > 0 ? scopeTooltips : dimensionsTooltip}
          </Typography>
        </Grid>

        <Grid item display="inline-flex" xs={9.3} sx={{ variant: "outlined" }}>
          <Typography variant="body2" sx={{ alignSelf: "right" }}>
            <b>{budgetTxt.CREATE_BUDGET.INFO_PANEL.START_DATE}:</b> {startDate}
          </Typography>
        </Grid>

        {!!endDate && (
          <Grid item display="inline-flex" xs={9.3} sx={{ variant: "outlined" }}>
            <Typography variant="body2" sx={{ alignSelf: "right" }}>
              <b>{budgetTxt.CREATE_BUDGET.INFO_PANEL.END_DATE}:</b> {endDate}
            </Typography>
          </Grid>
        )}
      </Grid>
    </InfoPanel>
  );
};

export default BudgetInfoPanel;
