import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import { CreditsContextProvider } from "../../Context/CreditsContext";
import { InvoiceAdjustmentsContextProvider } from "../../Context/InvoiceAdjustmentsContext";
import { type CreditsAndAdjustments } from "../../Navigation/config/pages";
import AdjustmentsTab from "./AdjustmentsTab";
import CreditsTab from "./CreditsTab";

export default function CreditsAdjustments({ pageId }: { pageId: CreditsAndAdjustments }) {
  const withCustomer = pageId.endsWith(":customer");
  const pagePrefix = pageId.split(":")[0];

  switch (pagePrefix) {
    case "credits":
      return (
        <CreditsContextProvider withCustomer={withCustomer}>
          <DoitConsoleTitle pageName="Credits" />
          <CreditsTab withCustomer={withCustomer} />
        </CreditsContextProvider>
      );
    case "adjustments":
      return (
        <InvoiceAdjustmentsContextProvider withCustomer={withCustomer}>
          <DoitConsoleTitle pageName="Adjustments" />
          <AdjustmentsTab withCustomer={withCustomer} />
        </InvoiceAdjustmentsContextProvider>
      );
    default:
      return null;
  }
}
