import { useCallback, useEffect, useState } from "react";

import {
  AppModel,
  type CompanyInfo,
  CustomerModel,
  EntityModel,
  type MasterPayerAccountsModel,
} from "@doitintl/cmp-models";
import {
  getCollection,
  type ModelIdRef,
  type ModelReference,
  type QueryDocumentSnapshotModel,
} from "@doitintl/models-firestore";
import sortBy from "lodash/sortBy";
import { DateTime } from "luxon";

import { MPAText } from "../../../assets/texts";
import { useCompanies } from "../../../Components/CreateBillingProfile/BillingForm/hooks";
import { useOnePassword } from "../../../Components/hooks/useOnePassword";
import { isProduction } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import { arrayFromDocChange } from "../../../Context/customer/arrayFromDocChange";
import { consoleErrorWithSentry } from "../../../utils";
import { serverTimestamp } from "../../../utils/firebase";
import { mpaVaultId } from "../consts";
import { getDefaultCURBucket, getDefaultCURPath } from "../MPAActivationDialog/useMPAPrePopulate";
import useCreateTicket, { type CreFields } from "./useCreateTicket";
import useGetUserLocation from "./useGetUserLocation";
import useGoogleGroup from "./useGoogleGroup";
import usePortfolioShare from "./usePortfolioShare";

const financeContact = ["giridhar@doit.com"];

export type MasterPayerAccountsModelSnapshot = ModelIdRef<MasterPayerAccountsModel> & {
  snapshot: QueryDocumentSnapshotModel<MasterPayerAccountsModel>;
};

export type CreateNewMpaResult = {
  newMpa: ModelReference<MasterPayerAccountsModel>;
  subprocessFailure: string;
};

const useSubscribedMasterPayerAccounts = (): {
  masterPayerAccounts: MasterPayerAccountsModelSnapshot[];
  masterPayerAccountsLoading: boolean;
  createNewMpa: (
    newMpaFromModal: Pick<
      MasterPayerAccountsModel,
      | "customerId"
      | "support"
      | "features"
      | "status"
      | "requestedBy"
      | "rootEmail"
      | "expectedOnboardingDate"
      | "domain"
      | "accountNumber"
    >
  ) => Promise<CreateNewMpaResult>;
} => {
  const { createCreTicket, postTicketComment } = useCreateTicket();
  const { currentUser: user } = useAuthContext();
  const [masterPayerAccounts, setMasterPayerAccounts] = useState<MasterPayerAccountsModelSnapshot[]>([]);
  const [masterPayerAccountsLoading, setMasterPayerAccountsLoading] = useState<boolean>(true);
  const onePassword = useOnePassword();
  const getUserLocation = useGetUserLocation();
  const companies = useCompanies();

  const findCompanyById = (companies: CompanyInfo[] | undefined, companyId: string): CompanyInfo | undefined =>
    companies?.find((company) => company.companyId === companyId);

  const fetchUserLocation = useCallback(async () => {
    if (user) {
      const location = await getUserLocation(user.email);
      return location.country;
    }
  }, [user, getUserLocation]);

  const { createGoogleGroup } = useGoogleGroup();
  const { shareAWSPortfolio } = usePortfolioShare();

  const getCustomerDetails = useCallback(async (customerId: string) => {
    const customerRef = getCollection(CustomerModel).doc(customerId);
    const customer = (await customerRef.get()).asModelData();
    if (!customer) {
      throw new Error(`Customer ${customerId} not found in getCustomerDetails`);
    }

    const enrichment = customer.enrichment;
    const entities = await Promise.all(
      customer.entities.map(async (entRef) => {
        const entDoc = getCollection(EntityModel).doc(entRef.id);
        return (await entDoc.get()).data();
      })
    ).then((ents) => ents.filter((entity) => entity?.active));

    return {
      name: customer._name,
      domain: customer.primaryDomain,
      country: enrichment?.geo?.country ?? "United States",
      region: enrichment?.timeZone ?? "America/Los_Angeles",
      entities,
    };
  }, []);

  const getTicketFields = useCallback(
    async (
      nraName: string,
      mpaId: string,
      {
        customerId,
        support,
        features,
        rootEmail,
        tags,
        existingCustomer,
        existingCustomerTier,
        services,
      }: Partial<MasterPayerAccountsModel>
    ): Promise<CreFields> => {
      const customerDetails = await getCustomerDetails(customerId || "");
      const requesterLocation = await fetchUserLocation();

      const billingProfileLocations = customerDetails.entities.reduce((acc, ent) => {
        const entityCountry = ent?.billingAddress?.countryname;
        const billingCompany = ent?.priorityCompany
          ? findCompanyById(companies, ent?.priorityCompany ?? "")
          : undefined;
        const billingCompanyCountries = billingCompany?.countries ?? [""];
        const billingCompanyCountriesString = billingCompany?.companyId
          ? `${billingCompany?.companyId} - ${billingCompanyCountries.join(",")}`
          : "";
        acc.push(...[entityCountry ?? "", billingCompanyCountriesString]);
        return acc;
      }, [] as string[]);

      const billingProfileCountries = customerDetails.entities.reduce((acc, ent) => {
        if (ent?.active && ent?.country) {
          acc.push(ent?.country);
        }
        return acc;
      }, [] as string[]);

      const servicesInfo = services
        ?.map((service) => `Service type: ${service.type}, Service tier: ${service.tier} `)
        .join("\n");

      const additionalDetails = `\n Existing customer: ${existingCustomer} \n ${existingCustomerTier && `Existing customer tier: ${existingCustomerTier}`} \n Services: \n ${servicesInfo} `;

      return {
        customerCountry: billingProfileCountries.find((x) => x !== undefined) || "",
        requesterLocation,
        additionalDetails: additionalDetails ?? "",
        customerDomain: customerDetails.domain ?? "N/A",
        description: "New MPA",
        mpaName: nraName,
        nra: features?.["no-root-access"],
        nraExpectedMRR: features?.["nra-expected-mrr"],
        nraMaterial: features?.["nra-link-to-materials"] ?? "",
        orgImport: features?.["import-org"],
        supportModel: support?.model,
        supportTier: support?.tier,
        requesterEmail: user?.email ?? "N/A",
        noOrgImportReason: features?.["no-import-org-reason"],
        edpppa: features?.["edp-ppa"],
        mpaId,
        rootEmail: rootEmail ?? "",
        billingProfileLocations: [...new Set(billingProfileLocations)].filter((country) => !!country).join(","),
        tags: tags ?? [],
        existingCustomer: existingCustomer ?? false,
        existingCustomerTier: existingCustomerTier ?? "",
        services: services ?? [],
      };
    },
    [companies, fetchUserLocation, getCustomerDetails, user?.email]
  );

  useEffect(() => {
    const masterPayerAccountsUnsubscribe = getCollection(AppModel)
      .doc("master-payer-accounts")
      .collection("mpaAccounts")
      .onSnapshot((querySnapshot) => {
        setMasterPayerAccounts((previousMasterPayerAccounts) => {
          const updatedMasterPayerAccounts = [...(previousMasterPayerAccounts ?? [])];

          arrayFromDocChange(updatedMasterPayerAccounts, querySnapshot, (doc) => ({
            ...doc.asModelData(),
            id: doc.id,
            snapshot: doc,
            ref: doc.modelRef,
          }));

          setMasterPayerAccountsLoading(false);
          return updatedMasterPayerAccounts;
        });
      });

    return () => {
      masterPayerAccountsUnsubscribe();
    };
  }, []);

  const createNewMpa = useCallback(
    async (
      newMpaObjectParam: Pick<
        MasterPayerAccountsModel,
        | "customerId"
        | "support"
        | "features"
        | "status"
        | "requestedBy"
        | "rootEmail"
        | "expectedOnboardingDate"
        | "domain"
        | "accountNumber"
        | "tags"
        | "existingCustomer"
        | "existingCustomerTier"
        | "services"
      >
    ): Promise<CreateNewMpaResult> => {
      // generate a "name" field that follows the convention "doitintl-payer-98"
      // NOTE: The regex removes non-numbers. Firebase handles more-specific
      // validation processes before allowing an INSERT.

      const sortedMpaIds = sortBy(
        masterPayerAccounts.map((account) => account?.name?.replace(/\D/g, "")),
        (accountId) => (accountId ? parseInt(accountId) : 0)
      );

      // protect against errors from an empty array
      const lastId = parseInt(sortedMpaIds[sortedMpaIds.length - 1] ?? "0");
      const nextId = lastId + 1;
      const nraName = `doitintl-payer-${nextId}`;

      const {
        customerId,
        support,
        features,
        status,
        requestedBy,
        rootEmail,
        expectedOnboardingDate,
        domain,
        accountNumber,
        tags,
        existingCustomer,
        existingCustomerTier,
        services,
      } = newMpaObjectParam;

      const newMpa = await getCollection(AppModel)
        .doc("master-payer-accounts")
        .collection("mpaAccounts")
        .add({
          customerId,
          domain,
          support,
          features,
          status,
          requestedBy,
          rootEmail,
          expectedOnboardingDate,
          accountNumber,
          name: nraName,
          friendlyName: `DoiT Reseller Account #${nextId}`,
          timeCreated: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          roleARN: "",
          tenancyType: "dedicated",
          defaultAwsFlexSaveDiscountRate: 0,
          cur_bucket: getDefaultCURBucket(nextId),
          cur_path: getDefaultCURPath(nextId),
          flexSaveAllowed: false,
          flexSaveRecalculationStartDate: DateTime.utc().startOf("month").toJSDate(),
          tags,
          existingCustomer,
          existingCustomerTier,
          services,
        });

      let subprocessFailure = "Failed to create 1password login item, Google Group & AWS portfolio"; //  unless those subprocesses succeed, return failure string
      try {
        const ticketFields = await getTicketFields(nraName, newMpa.id, {
          customerId,
          support,
          features,
          rootEmail,
          tags,
          existingCustomer,
          existingCustomerTier,
          services,
        });

        const {
          data: { ticketId: onboardingTicketId },
        } = await createCreTicket(ticketFields);

        const onePasswordItemId = await onePassword.createLoginItem({
          vaultId: mpaVaultId,
          title: `${domain} | ${nraName}`,
          email: rootEmail,
          url: "https://console.aws.amazon.com/console/home",
        });
        subprocessFailure = "Failed to create a Google Group & AWS portfolio"; // 1password call has succeeded at this point

        const requesterLocation = await fetchUserLocation();
        newMpa.update({
          onboardingTicketId: onboardingTicketId.toString(),
          onePasswordItemId,
          lastUpdated: serverTimestamp(),
          requesterLocation,
        });

        if (features?.["no-root-access"] && isProduction) {
          const comment = MPAText.NRA_COMMENT(requestedBy, domain, features);
          await postTicketComment(onboardingTicketId, customerId, comment, true, financeContact);
        }

        if (domain && rootEmail) {
          await createGoogleGroup({ domain, rootEmail });
        }

        subprocessFailure = "Failed to share AWS portfolio"; //  Google Group call has succeeded at this point

        if (accountNumber) {
          await shareAWSPortfolio(accountNumber);
        }

        subprocessFailure = ""; //  all subprocesses have succeeded at this point
      } catch (error) {
        consoleErrorWithSentry(error);
      }

      return { newMpa, subprocessFailure };
    },
    [
      masterPayerAccounts,
      createGoogleGroup,
      shareAWSPortfolio,
      getTicketFields,
      createCreTicket,
      onePassword,
      postTicketComment,
      fetchUserLocation,
    ]
  );

  return {
    masterPayerAccounts,
    masterPayerAccountsLoading,
    createNewMpa,
  };
};

export default useSubscribedMasterPayerAccounts;
