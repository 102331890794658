import { useEffect } from "react";

import { CustomerModel, UserModel } from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";

import { type PackageOptions } from "../../../Components/CreateCustomer/type";
import { transformOnboardingData } from "../../../Components/CreateCustomer/utils";
import { type Customer, type CustomerRef } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { arrayRemove, arrayUnion } from "../../../utils/firebase";
import { type SettingsValuesType } from "./types";

export async function isDomainAlreadyTaken(domain: string) {
  const querySnapshot = await getCollection(CustomerModel).where("domains", "array-contains", domain).limit(1).get();
  return !querySnapshot.empty;
}

type TieringFields =
  | "navigatorTierTrialStartDate"
  | "navigatorTierTrialEndDate"
  | "navigatorTier"
  | "navigatorTrialTier"
  | "solveTierTrialStartDate"
  | "solveTierTrialEndDate"
  | "solveTier"
  | "solveTrialTier";

type RelevantFields = Omit<SettingsValuesType, "domains" | TieringFields> & {
  removedDomains: string[];
  addedDomains: string[];
  primaryDomain: string;
};

type UpdateDirtyFieldsOnDbArg = { customerId: string } & {
  [K in keyof RelevantFields]: RelevantFields[K] | undefined;
};

export async function updateDirtyFieldsOnDb({
  customerId,
  currency,
  removedDomains,
  addedDomains,
  primaryDomain,
  linesPerInvoice,
  customerName,
  cloudProvider,
  customerClassification,
  earlyAccessFeatures,
  skipRemedyBreach,
  customerSegment,
  navigatorTrialCustomLength,
}: UpdateDirtyFieldsOnDbArg) {
  const batch = getBatch();
  const customerRef = getCollection(CustomerModel).doc(customerId);
  if (removedDomains?.length) {
    batch.update(customerRef, "domains", arrayRemove(...removedDomains));
    batch.update(customerRef, "auth.autoProvision.allowedDomains", arrayRemove(...removedDomains));
  }
  if (customerSegment) {
    batch.update(customerRef, "customerSegment", customerSegment);
  }
  if (addedDomains?.length) {
    batch.update(customerRef, "domains", arrayUnion(...addedDomains));
  }
  if (currency) {
    batch.update(customerRef, "settings.currency", currency);
  }
  if (linesPerInvoice) {
    batch.update(customerRef, "settings.invoicing.maxLineItems", linesPerInvoice);
  }
  if (cloudProvider) {
    batch.update(customerRef, "onboarding.advantage.providers", [cloudProvider]);
  }
  if (navigatorTrialCustomLength) {
    batch.update(customerRef, "tiers.navigator.trialCustomLength", navigatorTrialCustomLength);
  }

  const flatUpdate: Partial<CustomerModel> = {};
  if (primaryDomain) {
    flatUpdate.primaryDomain = primaryDomain;
  }
  if (customerName) {
    flatUpdate.name = customerName;
    flatUpdate._name = customerName.toLowerCase();
  }
  if (customerClassification) {
    flatUpdate.classification = customerClassification;
  }
  if (earlyAccessFeatures) {
    flatUpdate.earlyAccessFeatures = earlyAccessFeatures;
  }
  if (skipRemedyBreach !== undefined) {
    flatUpdate.skipRemedyBreach = skipRemedyBreach;
  }

  if (Object.keys(flatUpdate).length) {
    batch.update(customerRef, flatUpdate);
  }

  await batch.commit();
}

export function useCustomerUsersOnSnapshot({
  customerRef,
  onSnapshot,
}: {
  customerRef: CustomerRef;
  onSnapshot: (users: UserModel[]) => void;
}) {
  useEffect(
    () =>
      getCollection(UserModel)
        .where("customer.ref", "==", customerRef)
        .onSnapshot(
          (snapshot) => {
            const users = snapshot.docs.map((doc) => doc.data());
            onSnapshot(users);
          },
          (error) => {
            consoleErrorWithSentry(error);
          }
        ),
    [customerRef, onSnapshot]
  );
}

export async function resetCustomerTrialCustomLength(customerId: string) {
  const customerRef = getCollection(CustomerModel).doc(customerId);
  return customerRef.update("tiers.navigator.trialCustomLength", null);
}

export async function updateCustomerTrialCustomLength(customerId: string, navigatorTrialCustomLength: number) {
  const customerRef = getCollection(CustomerModel).doc(customerId);
  return customerRef.update("tiers.navigator.trialCustomLength", navigatorTrialCustomLength);
}

export async function updateCustomerPackagePreferences(customer: Customer, packageOptions: PackageOptions) {
  const customerRef = getCollection(CustomerModel).doc(customer.id);
  const onboardingData = transformOnboardingData(packageOptions);
  const batch = getBatch();

  batch.update(customerRef, "onboarding.advantage.providers", onboardingData.advantage?.providers ?? []);
  batch.update(customerRef, "onboarding.navigator.providers", onboardingData.navigator?.providers ?? []);
  batch.update(customerRef, "onboarding.solve.providers", onboardingData.solve?.providers ?? []);

  await batch.commit();
}
