import DeleteIcon from "@mui/icons-material/Delete";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { formatAsDollars } from "../../../utils/costUtils";
import { type ManualPurchaseItem, type UniqueCustomerValues } from "../types";
import { DebouncedAmount } from "./DebouncedAmount";
import { SkuDropdown } from "./SkuDropdown";

type Props = {
  purchaseItem: ManualPurchaseItem;
  customerSku: UniqueCustomerValues;
  onChange: (purchaseItem: ManualPurchaseItem) => void;
  skuNum: number;
  onDelete: (id: string) => void;
  disableDelete: boolean;
  loading: boolean;
};

export const PurchaseForm = ({
  customerSku,
  skuNum,
  purchaseItem,
  onChange,
  onDelete,
  disableDelete,
  loading,
}: Props) => {
  const { id, amount, billingAccountId, cost, isValid, region, type, hardware } = purchaseItem;

  const onFieldValueChange = (value: string | number, fieldName: string) => {
    if (purchaseItem[fieldName] !== value) {
      const updatedSku = { ...purchaseItem, [fieldName]: value };
      onChange(updatedSku);
    }
  };

  return (
    <Stack spacing={2} pt={3}>
      <Stack direction="row" justifyContent="space-between" mb={2}>
        <Typography variant="h4">SKU {skuNum}</Typography>
        <Button
          startIcon={<DeleteIcon />}
          color="error"
          onClick={() => {
            onDelete(id);
          }}
          disabled={disableDelete}
        >
          Delete SKU
        </Button>
      </Stack>
      <Stack direction="row" spacing={2}>
        <SkuDropdown
          customerSku={customerSku}
          onChange={onFieldValueChange}
          fieldName="billingAccountId"
          value={billingAccountId}
          loading={loading}
        />
        <DebouncedAmount value={amount} onChange={onFieldValueChange} error={!isValid} loading={loading} />
      </Stack>
      <Stack direction="row" spacing={2}>
        <SkuDropdown
          customerSku={customerSku}
          onChange={onFieldValueChange}
          fieldName="region"
          value={region}
          loading={loading}
        />
        <SkuDropdown
          customerSku={customerSku}
          onChange={onFieldValueChange}
          fieldName="type"
          value={type}
          loading={loading}
        />
        <SkuDropdown
          customerSku={customerSku}
          onChange={onFieldValueChange}
          fieldName="hardware"
          value={hardware}
          loading={loading}
        />
      </Stack>
      <Stack pt={1} direction="row" alignItems="center" spacing={1}>
        <Typography>Commitment cost:</Typography>
        {loading ? (
          <CircularProgress disableShrink={true} color="primary" size={16} />
        ) : (
          <Typography fontWeight="medium" display="inline-block">
            {formatAsDollars(cost)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
