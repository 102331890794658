import { useCallback, useMemo, useReducer } from "react";

export type LoadingValueHook<T, E> =
  | { value: T | undefined; loading: false; error: undefined }
  | { value: undefined; loading: true; error: undefined }
  | { value: undefined; loading: false; error: E };

export type LoadingValue<T, E> = LoadingValueHook<T, E> & {
  reset: () => void;
  setError: (error: E) => void;
  setValue: (value: T | undefined) => void;
};

type ReducerState<T, E> = {
  error?: E;
  loading: boolean;
  value?: T;
};

type ErrorAction<E> = { type: "error"; error: E };

type ResetAction<T> = { type: "reset"; value?: T };

type ValueAction<T> = { type: "value"; value: T | null };

type ReducerAction<T, E> = ErrorAction<E> | ResetAction<T> | ValueAction<T>;

const reducer =
  <T, E>() =>
  (state: ReducerState<T | null, E>, action: ReducerAction<T | null, E>): ReducerState<T | null, E> => {
    switch (action.type) {
      case "error":
        return {
          ...state,
          error: action.error,
          loading: false,
          value: undefined,
        };
      case "reset": {
        return { loading: true, value: undefined };
      }
      case "value":
        return {
          ...state,
          error: undefined,
          loading: false,
          value: action.value,
        };
    }
  };

export default <T, E>(): LoadingValue<T, E> => {
  const [state, dispatch] = useReducer(reducer<T, E>(), { loading: true, value: undefined });

  const reset = useCallback(() => {
    dispatch({ type: "reset", value: undefined });
  }, []);

  const setError = useCallback((error: E) => {
    dispatch({ type: "error", error });
  }, []);

  const setValue = useCallback((value: T) => {
    dispatch({ type: "value", value });
  }, []);

  return useMemo(
    () => ({
      error: state.error,
      loading: state.loading,
      reset,
      setError,
      setValue,
      value: state.value,
    }),
    [state.error, state.loading, reset, setError, setValue, state.value]
  ) as LoadingValue<T, E>;
};
