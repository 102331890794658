import { getUserOrgID } from "../../../utils/common";
import { type OrganizationWSnap, type OrgUser } from "./Types";

export const createUserOrgMap = (users: OrgUser[]) => {
  const usersOrgMap: { [key: string]: number } = {};
  users?.forEach((user: OrgUser) => {
    if (user?.organizations && user.organizations?.length > 0) {
      user.organizations.forEach((uOrg) => {
        const orgId = uOrg.id;
        // If org exists in map add 1 else create that key at value 1
        usersOrgMap[orgId] = (usersOrgMap[orgId] || 0) + 1;
      });
    }
  });
  return usersOrgMap;
};

export const getCurrentOrg = (orgs: OrganizationWSnap[], orgId: string) => orgs.find((o) => o.snapshot.id === orgId);

/**
 * Provides a list of the entities that are associated with the organization.
 * This is currently only used for customer uevCQmbX63lM1qckXMds (SaasGroup).
 * If it is rolled out more widely, a different approach must be considered such as putting a list of entity refs on the org firestore document.
 * AT THE MOMENT the orgs and entities are mapped 1:1 but the design is built to allow for org:entities to handle 1:many.
 * Users outside the listed orgs may see all entity data, in which case this will return undefined.
 *
 * @param customerId Current customer ID from context
 * @param isDoitEmployee Is the current user a Doit employee
 * @param userOrganization Current user organization object from customer context
 * @returns Associated entity ids or undefined if supplied orgId is not listed
 */
export const getOrgEntities = (
  customerId: string,
  isDoitEmployee: boolean,
  userOrganization: OrganizationWSnap | null | undefined
): string[] | undefined => {
  if (customerId !== "uevCQmbX63lM1qckXMds") {
    return undefined;
  }

  const orgId = getUserOrgID(isDoitEmployee, userOrganization);

  const orgsByEntity: Record<string, string[]> = {
    PttRHU2aFVqjpUeW7y80: ["kHSGxP4dlcVtiDd9HFQP"], // Keyword - US101710
    "0BVQohYMlBB57grIEgRi": ["PYNWxq3GXpmJ3oFybXPT"], // DeployBot - US101106
    "8BSP46mcz2YrmliLEpyu": ["PpBcLK1qJhNwyIRZRtms"], // Zenloop - DE100230 8BSP46mcz2YrmliLEpyu
    cpgYYmkDZYdI4OVQvltG: ["cyKsif1D96VivUqv3CEM"], // Seobility - DE100381
    qL8KIB1zhk7vLChIHH0z: ["7rm0CHSMXi8PvpdNUdlE"], // PipelineCRM - US101608
    "9E3T5w0BaqFY25xxtJKr": ["kKWgToNb2uuooFcVUSHw"], // MyWorks - US101672
    aQe9piw0x8b77CMR95eV: ["kC2Y4rZSVkTqOHivPMeb"], // AddSearch - SE100129
    Kl3kWog35u08eV2pDuzI: ["xGkhAznQ6MATHKI0hkRl"], // Usersnap - DE100341
    WjaaRlSk7auiKcjeHo1u: ["qymadNEVbbdPZHLBG1DN"], // Beekast - FR100322
  };

  return orgsByEntity[orgId];
};
