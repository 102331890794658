import { type JSX, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";

import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import BigQueryLensAlert from "./BigQueryLensAlert";
import BQInactiveStateDashboard from "./BQInactiveStateDashboard/BQInactiveStateDashboard";

type Props = {
  widgets: JSX.Element;
  noBQUsage: boolean;
};

const BigQueryLensGuard = ({ widgets }: Props) => {
  const [hideWidgets, setHideWidgets] = useState(false);
  const isEntitled = useIsFeatureEntitled("lens:bigquery");
  const { spendProfile } = useCurrentDashboardContext();

  const hasActiveBQSpend = Object.values(spendProfile).includes(true);

  const hideWidgetsDisplay = useCallback(() => {
    setHideWidgets(true);
  }, []);
  const showWidgetsDisplay = useCallback(() => {
    setHideWidgets(false);
  }, []);

  if (!isEntitled) {
    return (
      <Grid item xs={12} ml={-1} mb={1}>
        <BQInactiveStateDashboard enticementState="upgrade" />
      </Grid>
    );
  }

  if (!hasActiveBQSpend) {
    return (
      <Grid item xs={12} ml={-1} mb={1}>
        <BQInactiveStateDashboard enticementState="activate" />
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12} ml={-1} mb={1}>
        <BigQueryLensAlert hideWidgetsDisplay={hideWidgetsDisplay} showWidgetsDisplay={showWidgetsDisplay} />
      </Grid>
      {!hideWidgets && widgets}
    </>
  );
};
export default BigQueryLensGuard;
