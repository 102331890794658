import { type DataColumn, type HeaderColumn } from "../../types/FilterTable";

const SKUColumn: DataColumn = {
  label: "SKU",
  path: "sku",
};
const resourceColumn: DataColumn = {
  label: "Resource",
  path: "resource",
};
const operationColumn: DataColumn = {
  label: "Operation",
  path: "operation",
};
const costColumn: DataColumn = {
  label: "Cost",
  path: "cost",
};

const SKUHeader: HeaderColumn = {
  label: SKUColumn.label,
  value: SKUColumn.path,
};
const resourceHeader: HeaderColumn = {
  label: resourceColumn.label,
  value: resourceColumn.path,
};
const operationHeader: HeaderColumn = {
  label: operationColumn.label,
  value: operationColumn.path,
};
const costHeader: HeaderColumn = {
  label: costColumn.label,
  value: costColumn.path,
  style: { align: "right" },
};
export const anomalyPreviewColumns: DataColumn[] = [SKUColumn, resourceColumn, costColumn];
export const anomalyPreviewColumnsWithOperation: DataColumn[] = [
  SKUColumn,
  resourceColumn,
  operationColumn,
  costColumn,
];
export const anomalyPreviewHeaders: HeaderColumn[] = [SKUHeader, resourceHeader, costHeader];

export const anomalyPreviewHeadersWithOperation: HeaderColumn[] = [
  SKUHeader,
  resourceHeader,
  operationHeader,
  costHeader,
];
