import { useEffect, useState } from "react";

import find from "lodash/find";
import transform from "lodash/transform";

import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Asset, type Contract } from "../../../types";

type AssetDataProps = {
  entity: Entity;
  asset: Asset;
  contract?: Contract;
};

export const useAssetDataProps = (assetId: string) => {
  const { entities, assets, contracts } = useCustomerContext();
  const [data, setData] = useState<AssetDataProps | null>();

  useEffect(() => {
    const { dataProps } = transform(
      entities,
      (acc, entity) => {
        if (!assets[entity.id]) {
          return true;
        }

        const asset = find([...assets[entity.id]], { id: assetId });

        if (!asset) {
          return true;
        }

        const contract = asset.data.contract ? find(contracts, { id: asset.data.contract.id }) : undefined;

        acc.dataProps = { entity, asset, contract };
        return false;
      },
      {} as { dataProps?: AssetDataProps }
    );

    setData(dataProps ?? null);
  }, [assets, entities, contracts, assetId]);

  return data;
};
