import { useMemo } from "react";

import { CustomerNoteModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";
import { type Customer } from "../../../types";
import { type CustomerNote, type TagKeys } from "./types";
import { doesNoteHasTag, getAllNotes, isUserNote } from "./utils";

function parseCustomerNote(
  customerNote: WithFirebaseModel<CustomerNoteModel>,
  snapshot: DocumentSnapshotModel<CustomerNoteModel>
) {
  return {
    body: customerNote.body,
    timestamp: customerNote.timestamp,
    customer: customerNote.customer,
    customerName: customerNote.customerName,
    email: customerNote.email,
    name: customerNote.email,
    photoURL: customerNote.photoURL,
    pinned: customerNote.pinned,
    subscribers: customerNote.subscribers,
    tags: customerNote.tags,
    edited: customerNote.edited,
    display: customerNote.display,
    ref: snapshot.modelRef,
    id: snapshot.id,
  };
}

export function useCustomerNotes(customer: Customer): CustomerNote[] {
  const query = getCollection(CustomerNoteModel)
    .where("customer", "==", customer.ref)
    .where("display", "==", true)
    .orderBy("timestamp", "desc")
    .limit(25);
  const [customerNotes] = useCollectionData(query, {
    transform: parseCustomerNote,
  });

  return customerNotes ?? [];
}

export function usePinnedNotes(customer: Customer): CustomerNote[] {
  const query = getCollection(CustomerNoteModel)
    .where("customer", "==", customer.ref)
    .where("display", "==", true)
    .where("pinned", "==", true)
    .orderBy("timestamp", "desc");
  const [pinnedNotes] = useCollectionData(query, {
    transform: parseCustomerNote,
  });
  return pinnedNotes ?? [];
}

export function useNotesToDisplay(
  customerNotes: CustomerNote[],
  pinnedNotes: CustomerNote[],
  selectedTag?: TagKeys,
  showMyNotes?: boolean
): CustomerNote[] {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  return useMemo(() => {
    const allNotes = getAllNotes(customerNotes, pinnedNotes);
    if (selectedTag && showMyNotes) {
      return allNotes.filter((note) => doesNoteHasTag(note, selectedTag) && isUserNote(note, currentUser.email));
    } else if (selectedTag) {
      return allNotes.filter((note) => doesNoteHasTag(note, selectedTag));
    } else if (showMyNotes) {
      return allNotes.filter((note) => isUserNote(note, currentUser.email));
    } else {
      return allNotes;
    }
  }, [customerNotes, pinnedNotes, selectedTag, showMyNotes, currentUser.email]);
}
