import { Stack, Typography } from "@mui/material";

import NoDataIcon from "../../../assets/no_data.png";

export function NotFound() {
  return (
    <Stack display="flex" alignItems="center" spacing={4} mt={6}>
      <img src={NoDataIcon} alt="No Data Icon" style={{ width: "15vw" }} />
      <Typography variant="h1" mb={3} fontWeight={500}>
        No insights found
      </Typography>
      <Typography variant="subtitle1" mb={3} fontWeight={400} maxWidth="70%">
        We're sorry, but we couldn't find any relevant insights with the selected filters.
      </Typography>
    </Stack>
  );
}
