import { useMemo } from "react";
import { Card, Typography } from "@mui/material";
import { PaymentMethodType } from "@doitintl/cmp-models";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/EditRounded";

import { getCurrencyByCode } from "../../utils/common";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useAuthContext } from "../../Context/AuthContext";
import { useUserContext } from "../../Context/UserContext";
import { createLocationDescriptorWithReferrer } from "../../Pages/Entity/hooks";
import { useWidgetCustomer } from "./utils/useWidgetCustomer";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetCardContentWithTable } from "./WidgetCards/Common/WidgetCardContentWithTable";
import sortBy from "lodash/sortBy";

export default function EntitiesCard({ widgetHeight }) {
  const { entities } = useCustomerContext();
  const customer = useWidgetCustomer();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: "edit",
        headerName: "",
        width: 50,
        renderCell: (params) => {
          const { disabled, entityId } = params.row;
          return (
            <Tooltip title={!disabled ? "Update billing profile" : "Missing permissions to update billing profile"}>
              <span>
                <IconButton
                  aria-label="Update billing profile"
                  disabled={disabled}
                  size="small"
                  component={Link}
                  to={createLocationDescriptorWithReferrer(`/customers/${customer.id}/entities/${entityId}/edit`)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="14px" color={!params.row.active ? "text.disabled" : undefined}>
            {params.row.name}
          </Typography>
        ),
      },
    ];

    if (isDoitEmployee) {
      baseColumns.push({
        field: "priorityId",
        headerName: "ID",
        flex: 1,
        renderCell: (params) => (
          <Typography fontSize="14px" color={!params.row.active ? "text.disabled" : undefined}>
            {params.row.priorityId}
          </Typography>
        ),
      });
    }

    baseColumns.push({
      field: "paymentMethod",
      headerName: "Payment Method",
      flex: 1,
      renderCell: (params) => (
        <Typography fontSize="14px" color={!params.row.active ? "text.disabled" : undefined}>
          {params.row.paymentMethod}
        </Typography>
      ),
    });

    return baseColumns;
  }, [customer.id, isDoitEmployee]);

  const rows = useMemo(() => {
    const result = entities.map((entity) => {
      const disabled = !(isDoitEmployee || (entity.active && userRoles.billingProfilesAdmin));
      let paymentMethod = "";

      if (entity.payment) {
        switch (entity.payment.type) {
          case PaymentMethodType.CreditCard:
            paymentMethod = `Credit Card${entity.payment.card ? ` (${entity.payment.card.last4})` : ""}`;
            break;
          case PaymentMethodType.BankAccount:
            paymentMethod = `ACH${entity.payment.bankAccount ? ` (${entity.payment.bankAccount.last4})` : ""}`;
            break;
          case PaymentMethodType.WireTransfer:
            paymentMethod = "Wire Transfer";
            break;
          default:
            paymentMethod = entity.payment.type;
        }
      }

      return {
        id: entity.id,
        name: entity.name,
        entityId: entity.id,
        disabled,
        active: entity.active,
        priorityId: entity.priorityId,
        paymentMethod: `${getCurrencyByCode(entity.currency)} | ${paymentMethod}`,
      };
    });

    return sortBy(result, "active").reverse();
  }, [entities, isDoitEmployee, userRoles.billingProfilesAdmin]);

  return (
    <Card>
      <WidgetCardHeader
        title="Billing Profiles"
        subheader="Customize the assets on your invoice and your payment method"
      />
      <WidgetCardContentWithTable height={widgetHeight} rows={rows} columns={columns} />
    </Card>
  );
}
