import { Box, Typography } from "@mui/material";

import { useAppVersion } from "./Components/VersionUpdater";
import { useAuthContext } from "./Context/AuthContext";

const AppVersion = () => {
  const { isDoitEmployee } = useAuthContext();
  const versionData = useAppVersion();

  if (!isDoitEmployee || !versionData) {
    return null;
  }

  const { branch, shortCommitSha } = versionData;

  return (
    <Box
      sx={{
        pointerEvents: "none",
        maxWidth: 350,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        position: "fixed",
        bottom: 0,
        right: 0,
        opacity: 0.5,
        px: 1,
        py: 0,
        zIndex: 2,
      }}
    >
      <Typography variant="caption">
        {branch ?? "N/A"} ({shortCommitSha ?? "N/A"})
      </Typography>
    </Box>
  );
};

export default AppVersion;
