import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

import { domainConfig } from "../../../domainConfig";

const Disclosure = () => {
  const { t } = useTranslation("login");
  return (
    <Typography variant="body2" data-cy="signup-disclosure">
      {domainConfig.companyName} {t("disclosure1")}{" "}
      <Link target="_blank" underline="hover" href={domainConfig.urls.preferenceCenterURL}>
        {t("disclosure2")}
      </Link>
      {t("disclosure3")}{" "}
      <Link target="_blank" underline="hover" href={domainConfig.urls.privacyPolicyURL}>
        {t("disclosure4")}
      </Link>
      {t("disclosure5")}
    </Typography>
  );
};

export default Disclosure;
