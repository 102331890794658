import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";

import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import AddIcon from "@mui/icons-material/PlaylistAddRounded";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import memoizeOne from "memoize-one";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { getCollection } from "@doitintl/models-firestore";
import { BillingModel } from "@doitintl/cmp-models";
import { assetTypeName, formatCurrency } from "../../../utils/common";
import { globalText } from "../../../assets/texts";
import CogsDialog from "./CogsDialog";

const useStyles = makeStyles((theme) => ({
  cardActions: {
    padding: theme.spacing(2, 1, 1),
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

const maxDate = DateTime.utc().endOf("month");
const minDate = DateTime.fromObject({ year: 2019, month: 3, day: 1 });
const cogsRef = memoizeOne(() => getCollection(BillingModel).doc("cogs").collection("cogsLines"));

const Cogs = ({ date, onChangeDate }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [sorting, setSorting] = useState({ active: "type", direction: "asc" });

  useEffect(() => {
    const listener = cogsRef()
      .orderBy("timestamp", "desc")
      .where("date", "==", date.toJSDate())
      .onSnapshot((querySnapshot) => {
        setRows((rows) => {
          const newRows = rows.slice();
          querySnapshot.docChanges().forEach((change) => {
            const r = {
              data: change.doc.data(),
              snapshot: change.doc,
            };

            if (change.type === "added") {
              newRows.splice(change.newIndex, 0, r);
            }
            if (change.type === "modified") {
              newRows.splice(change.newIndex, 1, r);
            }
            if (change.type === "removed") {
              newRows.splice(change.oldIndex, 1);
            }
          });
          return newRows;
        });
      });

    return () => {
      setRows([]);
      if (listener) {
        listener();
      }
    };
  }, [date]);

  const handleRequestSort = (active) => () => {
    let direction = "desc";
    if (sorting.active === active && sorting.direction === "desc") {
      direction = "asc";
    }
    setSorting({ active, direction });
  };

  const handleDeleteRow = (row) => () => {
    row.snapshot.ref.delete();
  };

  return (
    <>
      <CardActions className={classes.cardActions}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item>
            <DatePicker
              label="Billing Month"
              renderInput={(params) => <TextField margin="none" {...params} />}
              value={date}
              onChange={onChangeDate}
              inputFormat="LLLL, yyyy"
              views={["year", "month"]}
              openTo="month"
              minDate={minDate}
              maxDate={maxDate}
            />
          </Grid>

          <Grid item>
            <Button
              aria-label="Insert COGS line"
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(true);
              }}
              startIcon={<AddIcon />}
            >
              {globalText.INSERT}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
      <CardContent>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small">
                  <TableSortLabel
                    active={sorting.active === "type"}
                    direction={sorting.direction}
                    onClick={handleRequestSort("type")}
                  >
                    Vendor
                  </TableSortLabel>
                </TableCell>
                <TableCell size="small">Description</TableCell>
                <TableCell size="small">
                  <TableSortLabel
                    active={sorting.active === "value"}
                    direction={sorting.direction}
                    onClick={handleRequestSort("value")}
                  >
                    Amount
                  </TableSortLabel>
                </TableCell>

                <TableCell padding="none" />
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(rows, [`data.${sorting.active}`], [sorting.direction]).map((row) => (
                <TableRow key={row.snapshot.id} hover>
                  <TableCell size="small">{assetTypeName(row.data.type)}</TableCell>
                  <TableCell size="small">{row.data.description}</TableCell>
                  <TableCell size="small">{formatCurrency(row.data.value, "USD", 2)}</TableCell>
                  <TableCell padding="none">
                    <IconButton aria-label="Delete line" onClick={handleDeleteRow(row)} size="large">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>

      <CogsDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        date={date}
      />
    </>
  );
};

export default Cogs;
