import { globalText, marketplaceText } from "../../../../assets/texts";
import { marketplaceTxt } from "../../../../assets/texts/Marketplace/marketplace";
import { firestoreTimestampFilterProps } from "../../../../Components/EnhancedTableFilterUtils";
import { type HeaderColumn } from "../../../../types/FilterTable";

export const headerColumns: HeaderColumn[] = [
  {
    label: marketplaceText.CUSTOMER,
    value: "customer.data.primaryDomain",
    tooltip: marketplaceText.CUSTOMER,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.AMOUNT,
    value: "transform.value",
    tooltip: marketplaceText.AMOUNT,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.TARGET_TIME,
    value: "data.targetTime",
    tooltip: marketplaceText.TARGET_TIME,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceTxt.TIME_CREATED,
    value: "data.timeCreated",
    tooltip: marketplaceTxt.TIME_CREATED,
    style: { align: "left", padding: "normal" },
    hiddenByDefault: true,
  },
  {
    label: marketplaceTxt.LAST_MODIFIED,
    value: "data.timeUpdated",
    tooltip: marketplaceTxt.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hiddenByDefault: true,
  },
  {
    label: marketplaceTxt.TIME_REPORTED,
    value: "data.timeReported",
    tooltip: marketplaceTxt.TIME_REPORTED,
    style: { align: "left", padding: "normal" },
    hiddenByDefault: true,
  },
  {
    label: marketplaceTxt.CREATED_BY,
    value: "data.createdBy",
    tooltip: marketplaceTxt.CREATED_BY,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.TRANSACTION_FEE,
    value: "data.transactionFee",
    tooltip: marketplaceText.TRANSACTION_FEE,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.STATUS,
    value: "data.status",
    tooltip: marketplaceText.STATUS,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "@",
    style: { align: "left", padding: "normal" },
  },
];

export const filterColumns = [
  {
    label: marketplaceText.CUSTOMER,
    path: "customer.data.primaryDomain",
    comparators: ["==", "!="],
    toOption: (value) => ({
      value,
      label: value ?? globalText.NA,
    }),
  },
  {
    label: marketplaceText.STATUS,
    path: "data.status",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.TARGET_TIME,
    path: "data.targetTime",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: marketplaceTxt.TIME_CREATED,
    path: "data.timeCreated",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: marketplaceTxt.LAST_MODIFIED,
    path: "data.timeUpdated",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: marketplaceTxt.TIME_REPORTED,
    path: "data.timeReported",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
] as const;
