import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

type Deserialize<TValue, TResult> = (value: TResult) => TValue;

type Serialize<TValue, TResult> = (value: TValue) => TResult;

export default function usePersistedState<TValue, TResult>(
  persistenceKey: string | null,
  persistInSession: boolean,
  defaultValue: TValue,
  serialize?: Serialize<TValue, TResult>,
  deserialize?: Deserialize<TValue, TResult>
): [TValue, Dispatch<SetStateAction<TValue>>] {
  // Try to initialize the state from localStorage, otherwise use the default value
  const storage = persistInSession ? sessionStorage : localStorage;

  const [state, setState] = useState<TValue>((): TValue => {
    if (!persistenceKey) {
      return defaultValue;
    }

    const value = storage.getItem(persistenceKey);
    if (value) {
      const storedItem = JSON.parse(value);
      if (storedItem) {
        return deserialize?.(storedItem) ?? storedItem;
      }
    }

    return defaultValue;
  });

  // Persist to localStorage on state changes
  useEffect(() => {
    if (persistenceKey) {
      storage.setItem(persistenceKey, JSON.stringify(serialize?.(state) ?? state));
    }
  }, [persistenceKey, serialize, state, storage]);

  return [state, setState];
}
