import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Alert, Stack, Typography } from "@mui/material";

import { useMixpanelTrackOnMount } from "../../../../utils/useMixpanelTrackOnMount";

const Permission = ({ text }: { text: string }) => (
  <Typography fontFamily="monospace" pl={2} color="text.secondary">
    • {text}
  </Typography>
);

export default function StepPermissions() {
  useMixpanelTrackOnMount("saas.permissions.view", { cloud: SaaSConsoleType.AZURE });

  return (
    <Stack spacing={4} pb={5} data-cy="step-permissions">
      <Stack spacing={4}>
        <Typography variant="h1">Required permissions to connect your Azure account</Typography>
        <Alert severity="info">
          If you don't want to create a custom role in your Azure account with these permissions, you need at least the
          preset Owner role to perform any actions at subscription level
        </Alert>
        <Stack spacing={2}>
          <Typography variant="body1">
            You need the following permissions to connect your Azure account with DoiT:
          </Typography>
          <Stack spacing={1}>
            <Permission text="Microsoft.Authorization/roleAssignments/write" />
            <Permission text="Microsoft.Authorization/roleAssignments/delete" />
            <Permission text="Microsoft.Resources/subscriptions/resourceGroups/write" />
            <Permission text="Microsoft.Storage/storageAccounts/write" />
            <Permission text="Microsoft.CostManagement/exports/write" />
            <Permission text="Microsoft.Storage/storageAccounts/blobServices/containers/write" />
            <Permission text="Microsoft.ManagedServices/registrationAssignments/write" />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
