import { useCallback, useState } from "react";

import { type EntityInvoicesModel } from "@doitintl/cmp-models";
import { type FirebaseDocumentSnapshotModel } from "@doitintl/models-firestore/src/core";
import { Button } from "@mui/material";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../../utils/errorHandling";
import { firestoreTimestamp } from "../../../../../utils/firebase";
import { IssuedAtDialog } from "./IssuedAtDialog";

const IssuedAtButton = ({
  invoiceSnap,
  fetchInvoiceData,
}: {
  invoiceSnap?: FirebaseDocumentSnapshotModel<EntityInvoicesModel>;
  fetchInvoiceData: () => Promise<void>;
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const showSuccessSnackbar = useSuccessSnackbar();
  const showErrorSnackbar = useErrorSnackbar();

  const handleIssuedAtDialog = useCallback(
    async (note: string) => {
      setLoading(true);
      try {
        if (!invoiceSnap) {
          return;
        }
        await invoiceSnap.ref.update({ issuedAt: firestoreTimestamp(), note: `Manual issue: ${note}` });
        await fetchInvoiceData();
        showSuccessSnackbar("Issued at successfully updated.");
      } catch (error) {
        consoleErrorWithSentry(error);
        showErrorSnackbar("Unable to set issued at, please try again...");
      }
      setOpenDialog(false);
      setLoading(false);
    },
    [invoiceSnap, fetchInvoiceData, showSuccessSnackbar, showErrorSnackbar]
  );

  return (
    <>
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        Set issued
      </Button>
      {openDialog && (
        <IssuedAtDialog
          open={openDialog}
          loading={loading}
          handleSave={handleIssuedAtDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
        />
      )}
    </>
  );
};

export default IssuedAtButton;
