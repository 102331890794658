import { FlexsaveTypes } from "@doitintl/cmp-models";

import { Loader } from "../../Components/Loader";
import { useFlexsaveEnableCheckWithExistingSavingsData } from "./AWS/hooks";
import { OnboardingSteps } from "./Standalone/AWS/OnboardingSteps";
import { StandaloneAWS } from "./StandaloneAWS";

export const FlexsaveStandaloneAWS = () => {
  const [enabled, loading] = useFlexsaveEnableCheckWithExistingSavingsData(FlexsaveTypes.AWS);
  return <Loader loading={loading}>{enabled ? <StandaloneAWS /> : <OnboardingSteps />}</Loader>;
};
