import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type AssetModel } from "./Asset";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";

type LogLine = {
  domain: string;
  quantity: number;
  skuId: string;
  skuName: string;
};

enum Payment {
  Empty = "",
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
}

enum GSuiteReseller {
  AdminMspDoitIntlCOM = "admin@msp.doit-intl.com",
  AdminNaDoitIntlCOM = "admin@na.doit-intl.com",
  AdminPremierDoitIntlCOM = "admin@premier.doit-intl.com",
}

enum GSuiteSubscriptionsType {
  SubscriptionsChangeSeats = "subscriptions.changeSeats",
  SubscriptionsChangeSeatsAutomation = "subscriptions.changeSeats.automation",
  SubscriptionsInsert = "subscriptions.insert",
}

enum RenewalType {
  AutoRenew = "AUTO_RENEW",
  Cancel = "CANCEL",
  RenewCurrentUsers = "RENEW_CURRENT_USERS",
  SwitchToPayAsYouGo = "SWITCH_TO_PAY_AS_YOU_GO",
}

type GSuiteRenewalSettings = {
  kind: "subscriptions#renewalSettings";
  renewalType: RenewalType;
};

enum PlanName {
  Annual = "ANNUAL",
  AnnualYearlyPay = "ANNUAL_YEARLY_PAY",
  Flexible = "FLEXIBLE",
  Free = "FREE",
}

export type GSuiteLicenseState = {
  billingMethod: "ONLINE";
  creationTime: string;
  customerDomain: string;
  customerId: string;
  kind: "reseller#subscription";
  plan: {
    planName: PlanName;
  } & (
    | {
        commitmentInterval: {
          endTime: string;
          startTime: string;
        };
        isCommitmentPlan: true;
      }
    | { commitmentInterval: undefined; isCommitmentPlan: false }
  );
  purchaseOrderId?: string;
  renewalSettings?: GSuiteRenewalSettings;
  resourceUiUrl: string;
  seats?: {
    kind: "subscriptions#seats";
    licensedNumberOfSeats: number;
    maximumNumberOfSeats: number;
    numberOfSeats: number;
  };
  skuId: string;
  skuName: string;
  status: "ACTIVE" | "SUSPENDED";
  subscriptionId: string;
  suspensionReasons?: string[];
  trialSettings: {
    isInTrial?: boolean;
    trialEndTime?: string;
  };
};

class AuditLogApiModel {
  email!: string;

  isDoitEmployee!: boolean;

  logLine!: LogLine | null;

  success!: boolean;

  timestamp!: Timestamp;
}

type AuditLogApiRequest = {
  customerId: string;
  subscriptionId: null | string;
};

@subCollection("api")
export class AuditLogModelGSuiteApiModel extends AuditLogApiModel {
  request!: AuditLogApiRequest & {
    body: {
      gsuite?: string;
      item?: string;
      payment?: Payment;
      quantity?: number;
      reseller?: GSuiteReseller;
      seats?: {
        LicensedNumberOfSeats?: number;
        MaximumNumberOfSeats?: number;
        NumberOfSeats?: number;
        licensedNumberOfSeats?: number;
        maximumNumberOfSeats?: number;
        numberOfSeats?: number;
      };
      total?: string;
    };
  };

  response!: {
    asset: Reference<AssetModel>;
    customer: Reference<CustomerModel>;
    entity: Reference<EntityModel>;
    subscription: {
      after: GSuiteLicenseState | null;
      before: GSuiteLicenseState | null;
    };
  };

  type!: GSuiteSubscriptionsType;
}

enum SuspensionReason {
  CustomerCancellation = "CustomerCancellation",
}

type Office365SubscriptionsState = {
  autoRenewEnabled: boolean;
  commitmentEndDate: string;
  creationDate: string;
  effectiveStartDate: string;
  friendlyName: string;
  id: string;
  isTrial: boolean;
  offerId: string;
  offerName: string;
  orderId: string;
  quantity: number;
  status: "" | "active" | "suspended";
  suspensionReasons: SuspensionReason[] | null;
};

enum Office365Reseller {
  DoitintlOnmicrosoftCom = "doitintl.onmicrosoft.com",
  DoitintleuOnmicrosoftCom = "doitintleu.onmicrosoft.com",
  DoitintleuropeOnmicrosoftCom = "doitintleurope.onmicrosoft.com",
  DoitintlusOnmicrosoftCom = "doitintlus.onmicrosoft.com",
  DoitintlauOnmicrosoftCom = "doitintlau.onmicrosoft.com",
}

@subCollection("api")
export class AuditLogModelOffice365ApiModel extends AuditLogApiModel {
  request!: AuditLogApiRequest & {
    body: {
      customer?: string;
      entity?: string;
      item?: string;
      payment?: Payment;
      quantity: number;
      reseller?: Office365Reseller;
      total?: string;
      type?: "office-365";
    } | null;
  };

  response!: {
    asset: null | Reference<AssetModel>;
    customer: null | Reference<CustomerModel>;
    entity: null | Reference<EntityModel>;
    subscription: {
      after: Office365SubscriptionsState | null;
      before: Office365SubscriptionsState | null;
    };
  };

  type!: "subscriptions.changeQuantity" | "subscriptions.order";
}

class GSuiteDoc {
  subCollections?: {
    api: AuditLogModelGSuiteApiModel;
  };
}

class Office365Doc {
  subCollections?: {
    api: AuditLogModelOffice365ApiModel;
  };
}
@collection("auditLogs")
export class AuditLogModel {
  docs?: {
    "g-suite": GSuiteDoc;
    "office-365": Office365Doc;
  };
}
