import { type JSX } from "react";

import { Card, CardContent, CardHeader } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { type ChartCardType, useChartCardOptions } from "./useChartCardOptions";

type Props = {
  type: ChartCardType;
  title: JSX.Element | string;
  selected?: boolean;
  onClick?: () => void;
};

export function ChartCard({ title, type, selected, onClick = () => {} }: Props) {
  const options = useChartCardOptions(type);
  const additionalSxFields = selected ? { borderColor: "primary.main" } : {};

  return (
    <Card sx={{ "&:hover": { borderColor: "primary.main" }, ...additionalSxFields }} onClick={onClick}>
      <CardHeader title={title} titleTypographyProps={{ variant: "subtitle1", fontWeight: 500 }} />
      <CardContent>
        <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
      </CardContent>
    </Card>
  );
}
