import { useMemo } from "react";

import { ProductEnum } from "@doitintl/cmp-models";

import { useAssetsContext } from "../../Context/customer/AssetContext";
import { useCustomerContext } from "../../Context/CustomerContext";

export function useAssetsExistCheck() {
  const { customer } = useCustomerContext({ allowNull: true });
  const { hasAWSAssets, hasGCPAssets, assetsLoading, hasAWSStandaloneAssets } = useAssetsContext();

  return useMemo(() => {
    if (!customer) {
      return {
        hasAWSAssets: false,
        hasAWSStandaloneAssets: false,
        hasGCPAssets: false,
      };
    }

    if (assetsLoading) {
      return {
        hasAWSAssets: customer?.assets?.includes(ProductEnum.AmazonWebServices) ?? false,
        hasAWSStandaloneAssets: customer?.assets?.includes(ProductEnum.AmazonWebServicesStandalone) ?? false,
        hasGCPAssets: customer?.assets?.includes(ProductEnum.GoogleCloud) ?? false,
      };
    }
    return {
      hasAWSAssets,
      hasAWSStandaloneAssets,
      hasGCPAssets,
    };
  }, [customer, assetsLoading, hasAWSAssets, hasGCPAssets, hasAWSStandaloneAssets]);
}
