import { useEffect, useState } from "react";

import { type CloudAnalyticsModelGkeCostAllocationModel } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { getCustomerGKECostAllocation } from "../../../Pages/Customers/db";
import { consoleErrorWithSentry } from "../../../utils";

const useGKECostAllocation = (): [CloudAnalyticsModelGkeCostAllocationModel | undefined | null, boolean] => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [loading, setLoading] = useState(true);

  const [gkeCostAllocation, setGkeCostAllocation] = useState<CloudAnalyticsModelGkeCostAllocationModel | null>();

  useEffect(() => {
    setLoading(true);

    getCustomerGKECostAllocation(customer.id)
      .get()
      .then((customersGkeCostAllocation) => {
        const data = customersGkeCostAllocation?.asModelData() ?? null;
        setGkeCostAllocation(data);
      })
      .catch((error) => {
        setGkeCostAllocation(null);
        consoleErrorWithSentry(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customer.id]);

  return [gkeCostAllocation, loading];
};

export default useGKECostAllocation;
