import { DEFAULT_SPLIT_MODE, type Split, type SplitMode, type SplitTarget } from "./types";
import { updateTargetsValues } from "./utils";

const sumTargetsValues = (targets: SplitTarget[]) =>
  targets.reduce((accumulator, currentTarget) => accumulator + (currentTarget.checked ? +currentTarget.value : 0), 0);

const selectOrigin = (target?: SplitTarget, targets?: SplitTarget[]) => (prev: Split | undefined) => {
  if (!prev) {
    return prev;
  }

  const temp = { ...prev };
  temp.origin = target;
  temp.mode = DEFAULT_SPLIT_MODE;
  temp.targets = updateTargetsValues(targets?.filter((ot) => ot.id !== target?.id) || []);
  return temp;
};

const modeChange = (mode: SplitMode) => (prev: Split | undefined) => {
  if (!prev) {
    return prev;
  }
  const temp = { ...prev };
  temp.mode = mode;
  // for proportional mode values are calculated on the BE, here just set default non-zero values
  if (mode === "even" || mode === "proportional") {
    temp.targets = updateTargetsValues(temp.targets);
  }
  return temp;
};

const checkedTarget = (label: string) => (prev: Split | undefined) => {
  if (!prev) {
    return prev;
  }
  const temp = { ...prev };
  const currTarget = temp.targets.find((target) => target.label === label);
  if (currTarget) {
    currTarget.checked = !currTarget.checked;
    return modeChange(temp.mode)(temp);
  }
  return prev;
};

const checkAllTargets = (checked: boolean) => (prev: Split | undefined) => {
  if (!prev) {
    return prev;
  }

  const tempTargets = prev.targets.map((target) => {
    if (target.disabled && !target.checked) {
      return target;
    }

    return {
      ...target,
      checked,
    };
  });

  const modified = { ...prev, targets: tempTargets };
  return modeChange(modified.mode)(modified);
};

const updatePercentage = (targets: SplitTarget[], id: string, value: string) => {
  const target = targets.find((target) => target.id === id);
  if (target) {
    target.value = value;
  }
  return targets;
};

const adjustCustomValues = () => (prev: Split | undefined) => {
  if (!prev) {
    return prev;
  }
  const temp = { ...prev };
  const valuesSum = sumTargetsValues(temp.targets);
  temp.targets.forEach((target) => {
    target.value = target.checked ? `${+target.value / valuesSum}` : "0";
  });
  return temp;
};

export default {
  sumTargetsValues,
  selectOrigin,
  modeChange,
  checkedTarget,
  checkAllTargets,
  updatePercentage,
  adjustCustomValues,
};
