import { useEffect, useMemo, useState } from "react";

import { Classification } from "@doitintl/cmp-models";
import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useCustomerContext } from "../../../Context/CustomerContext";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export const ClientSuspendedOrTerminatedAlert = () => {
  const classes = useStyles();
  const { customer } = useCustomerContext({ allowNull: true });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(
      customer?.classification === Classification.SuspendedForNonPayment ||
        customer?.classification === Classification.Terminated ||
        customer?.classification === Classification.Inactive
    );
  }, [customer?.classification]);

  const textMessage = useMemo(() => {
    if (customer?.classification === Classification.Terminated) {
      return "Customer account has been terminated.";
    }

    if (customer?.classification === Classification.SuspendedForNonPayment) {
      return "Customer account has been suspended for non-payment.";
    }

    if (customer?.classification === Classification.Inactive) {
      return "The customer's account has been set to INACTIVE status.";
    }
  }, [customer?.classification]);

  if (!open) {
    return null;
  }

  return (
    <Alert
      classes={{
        root: classes.root,
      }}
      severity="error"
    >
      {textMessage}
    </Alert>
  );
};
