import { type AxiosInstance } from "axios";

import { consoleErrorWithSentry } from "../../utils";
import { type CloudFormationDeploymentMethod, type ClusterConfig, type ClusterInfo } from "./types";
import { extractFilenameFromContentDisposition } from "./utils";

export class EKSApi {
  private readonly apiPrefix: string;

  constructor(private readonly api: AxiosInstance) {
    this.api = api;
    this.apiPrefix = `/v1/eks-metrics`;
  }

  public async downloadDeploymentFile(clusterInfo: ClusterConfig) {
    const response = await this.api.post(`${this.apiPrefix}/eks-deployment-file`, this.buildBody(clusterInfo), {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const filename = extractFilenameFromContentDisposition(response) ?? clusterInfo.deploymentFileName;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }

  public async downloadClusterFile(clusterInfo: ClusterConfig, openIdUrl: string) {
    const base = this.buildBody(clusterInfo);
    const body = { ...base, cluster_oidc_issuer_url: openIdUrl };
    const response = await this.api.post(`${this.apiPrefix}/terraform-cluster-file`, body, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const filename = extractFilenameFromContentDisposition(response) ?? clusterInfo.deploymentFileName;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }

  public async downloadRegionFile(clusterInfo: ClusterConfig) {
    const base = this.buildBody(clusterInfo);
    const body = base;
    const response = await this.api.post(`${this.apiPrefix}/terraform-region-file`, body, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    const filename = extractFilenameFromContentDisposition(response) ?? clusterInfo.deploymentFileName;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }

  public verifyConnection(clusterInfo: ClusterInfo) {
    return this.api.post(`${this.apiPrefix}/validate-deployment`, this.buildBody(clusterInfo));
  }

  public async syncCluster(clusterInfo: ClusterInfo) {
    try {
      await this.api.post(`${this.apiPrefix}/sync-cluster`, this.buildBody(clusterInfo));
      return true;
    } catch (e) {
      return false;
    }
  }

  private buildBody(clusterInfo: ClusterInfo) {
    const { accountId, region, clusterName } = clusterInfo;
    return {
      account_id: accountId,
      region,
      cluster_name: clusterName,
    };
  }
}

export const updateDeploymentMethodFirestore = async (
  clusterConfig: ClusterConfig,
  deploymentMethod: CloudFormationDeploymentMethod
) => {
  try {
    await clusterConfig.clusterConfigRef.update({ deploymentMethod });
  } catch (error) {
    consoleErrorWithSentry(error);
  }
};
