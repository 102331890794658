import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

export const usePaymentsStripeStatus = () => {
  const [stripe, loading] = useDocumentData(getCollection(AppModel).doc("stripe"));

  let paymentsDisabled = false;

  if (!loading && stripe) {
    paymentsDisabled = stripe.disable_stripe_payments;
  }

  return { paymentsDisabled };
};
