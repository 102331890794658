import type { Dict } from "mixpanel-browser";

import useMountEffect from "../Components/hooks/useMountEffect";
import { mixpanelTrack } from "./mixpanel";

export const useMixpanelTrackOnMount = (event: string, data?: Dict) => {
  useMountEffect(() => {
    mixpanelTrack(event, data);
  });
};
