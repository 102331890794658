import { CurrencyCodes } from "@doitintl/cmp-models";

export type Cloud = "amazon-web-services" | "google-cloud" | "microsoft-azure";

export const defaultCurrencySymbol = CurrencyCodes.USD;

export const visuallyHiddenStyle = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: 1,
  margin: -1,
  overflow: "hidden",
  padding: 0,
  position: "absolute",
  top: -1,
  left: -1,
  width: 1,
};
