import { subCollection, type Timestamp } from "@doitintl/models-types";

enum States {
  Finalize = "finalize",
  Initializing = "initializing",
  Progress = "progress",
}

type State = `${States}`;

enum ChannelTypes {
  AwsManageAccount = "aws.manage.account",
  BillingInvoicing = "billing.invoicing",
  CustomerInvoicesSync = "customer.invoices.sync",
}

type ChannelType = `${ChannelTypes}`;

@subCollection("channels")
export class ChannelModel {
  complete!: boolean;

  customer?: string; // customerId

  error?: string;

  progress?: number;

  stackName?: string;

  state?: State;

  timestamp?: Timestamp;

  type!: ChannelType;

  uid!: string;
}
