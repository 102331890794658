import { type HeaderColumn } from "../../../types/FilterTable";

export const headerColumns: HeaderColumn[] = [
  {
    label: "Region",
    value: "region",
    tooltip: "Region",
  },
  {
    label: "Hardware",
    value: "hardware",
    tooltip: "Hardware",
  },
  {
    label: "Type",
    value: "type",
    tooltip: "Type",
  },
  {
    label: "SKU",
    value: "sku",
    tooltip: "SKU",
  },
  {
    label: "Description",
    value: "description",
    tooltip: "Description",
  },

  {
    label: "Flexsave Charge",
    value: "flexsaveCharge",
    tooltip: "Flexsave Charge",
  },
  {
    label: "GCP Price",
    value: "GCPPrice",
    tooltip: "GCP Price",
  },
  {
    label: "Savings Rate",
    value: "savingsRate",
    tooltip: "Savings Rate",
  },
];

export const filterColumns = [
  {
    label: "Region",
    path: "region",
  },
  {
    label: "Hardware",
    path: "hardware",
  },
  {
    label: "Type",
    path: "type",
  },
] as const;
