import { useEffect, useState } from "react";

import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type StatusWRef } from "../../../../types/Status";

export const useCloudAnalyticsStatus = (): [StatusWRef | null] => {
  const { customer } = useCustomerContext();
  const [status, setStatus] = useState<StatusWRef | null>(null);

  useEffect(
    () =>
      getCollection(CloudAnalyticsModel)
        .doc("statuses")
        .collection("cloudAnalyticsStatuses")
        .doc(customer.ref.id)
        .onSnapshot((statusDoc) => {
          const data = statusDoc.asModelData();
          if (!data) {
            setStatus(null);
            return;
          }

          setStatus({
            data,
            ref: statusDoc.modelRef,
          });
        }),
    [customer.ref.id]
  );

  return [status];
};
