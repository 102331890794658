import { type ChangeEvent, Fragment } from "react";

import { Box, Checkbox, FormControlLabel, FormHelperText, ListItem } from "@mui/material";

import { type AsgConfigurationSummary, type KeepUptoDate } from "../../types";
import { DEFAULT_HEIGHT } from "../AsgConfiguration/consts";

type KeepUpToDateProps = {
  configuration: AsgConfigurationSummary;
  keepUptoDate: KeepUptoDate;
  keepUptoDateCallback?: (keepUptoDate: KeepUptoDate) => void;
  isApplyChangesSupported: boolean;
};

export const KeepUpToDate = ({
  configuration,
  keepUptoDate,
  keepUptoDateCallback,
  isApplyChangesSupported,
}: KeepUpToDateProps) => {
  const tags = configuration.tags.filter((tag) => tag.Key.indexOf("doitintl:spotscaling:auto-enroll") >= 0);
  const always = !!tags.find((tag) => tag.Value === "always");
  const disabled = tags.length > 0 || !isApplyChangesSupported;
  const disabledReason = tags.length > 0 ? "This setting is disabled when using AWS tags" : "";
  const value = always || keepUptoDate.value;

  const handleKeepUpToDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    keepUptoDateCallback?.({
      valueDirty: !disabled && event.target.checked !== keepUptoDate.origValue,
      value: event.target.checked,
      origValue: keepUptoDate.origValue,
    });
  };

  return (
    <>
      <ListItem style={{ height: DEFAULT_HEIGHT }}>
        <Box display="flex" alignItems="center" justifyContent="start">
          <FormControlLabel
            control={
              <Checkbox disabled={disabled} color="primary" checked={value} onChange={handleKeepUpToDateChange} />
            }
            label="Automatically update"
          />
        </Box>
      </ListItem>
      {disabledReason !== "" && (
        <>
          <ListItem style={{ height: 20 }}>
            <Box display="flex" alignItems="center" justifyContent="start" style={{ height: 25, width: 30 }} />
            <FormHelperText>{disabledReason} </FormHelperText>
          </ListItem>
        </>
      )}
    </>
  );
};
