export enum FlexsaveWidgetKey {
  AWS = "flexSaveAws",
  GCP = "flexSaveGcp",
}

export enum FlexsaveWidgetTitle {
  AWS = "Flexsave AWS",
  GCP = "Flexsave GCP",
  BOTH = "Flexsave",
}
