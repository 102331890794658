import { Box } from "@mui/material";

type Props = {
  vertical?: boolean;
  leftSpacing?: boolean;
  rightSpacing?: boolean;
  disabled?: boolean;
};

export function DropLine({ vertical, leftSpacing, rightSpacing, disabled }: Props) {
  return (
    <Box
      sx={{
        ml: vertical && leftSpacing ? 1 : 0,
        mr: vertical && rightSpacing ? 1 : 0,
        mt: !vertical && leftSpacing ? 1 : 0,
        mb: !vertical && rightSpacing ? 1 : 0,
        backgroundColor: disabled ? "divider" : "primary.main",
        width: vertical ? "2px" : "100%",
        height: vertical ? "100%" : "2px",
      }}
    />
  );
}
