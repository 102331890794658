import { createContext, type Dispatch, useContext, useReducer } from "react";

import { type DialogAction, type DialogState } from "./types";

export const defaultState: DialogState = {
  dialogType: null,
  dialogTitle: null,
  cloudResourceType: null,
  clickedRow: null,
  isLoading: false,
  additionalDialogProps: {},
};

export const dialogsReducer = (state: DialogState, action: DialogAction): DialogState => {
  switch (action.type) {
    case "open":
      return {
        ...state,
        dialogType: action.payload.dialogType,
        dialogTitle: action.payload.dialogTitle,
        clickedRow: action.payload.clickedRow,
        cloudResourceType: action.payload.cloudResourceType,
      };
    case "close":
      return defaultState;
    case "changeLoading":
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    default:
      return state;
  }
};

const DialogStateContext = createContext<DialogState>(defaultState);
const DialogDispatchContext = createContext<Dispatch<DialogAction>>(() => defaultState);

export const DialogContextProvider = ({ children }) => {
  const [state, dispatch]: [DialogState, Dispatch<DialogAction>] = useReducer(dialogsReducer, defaultState);

  return (
    <DialogStateContext.Provider value={state}>
      <DialogDispatchContext.Provider value={dispatch}>{children}</DialogDispatchContext.Provider>
    </DialogStateContext.Provider>
  );
};

export const useDialogStateContext = () => useContext(DialogStateContext);
export const useDialogDispatchContext = () => useContext(DialogDispatchContext);
