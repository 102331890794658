import { useCallback } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { type payerAccountObj } from "../types";
import { getMPANumberFromName, getPayerAccountById } from "../utils";

type Props = {
  selectedMpaId: string | null;
  payerList: payerAccountObj[];
  onPayerSelection: (mpaId: string) => void;
  existsInSupport: (mpaId: string) => boolean;
  doesNotHaveEditPermission: boolean;
};
const PayerAccountField = ({
  selectedMpaId,
  payerList,
  onPayerSelection,
  existsInSupport,
  doesNotHaveEditPermission,
}: Props) => {
  const getMPAOptionText = useCallback(
    (mpaId) => {
      const mpa = getPayerAccountById(payerList, mpaId);
      if (!mpa) {
        return mpaId;
      }
      return `${mpa.data.domain || "SHARED"} (MPA #${getMPANumberFromName(mpa.data.name)})`;
    },
    [payerList]
  );

  return (
    <Autocomplete
      id="awsPayerSelection"
      options={payerList.map(({ ref: { id } }) => id)}
      getOptionLabel={getMPAOptionText}
      getOptionDisabled={existsInSupport}
      fullWidth
      onChange={(_event, value) => {
        if (value) {
          onPayerSelection(value);
        }
      }}
      value={selectedMpaId}
      data-testid="aws-contract-support-payer-select"
      renderInput={(params) => (
        <TextField
          {...params}
          id="payerInContractSupport"
          name="payerSelection"
          label="Payer account"
          fullWidth
          required
          variant="outlined"
          data-testid="aws-contract-support-payer-option"
        />
      )}
      disabled={doesNotHaveEditPermission}
    />
  );
};
export default PayerAccountField;
