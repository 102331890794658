import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Stack } from "@mui/material";

type Props = {
  transferProjectsAction: () => void;
  manageAccountAction: () => void;
  showTransfer: boolean;
};

export const AssetHeaderButtons = ({ transferProjectsAction, manageAccountAction, showTransfer }: Props) => (
  <Stack direction="row" spacing={2} sx={{ pb: 4 }} data-testid="gc-asset-page-header-buttons">
    {showTransfer && (
      <Button variant="outlined" onClick={transferProjectsAction} color="primary" data-testid="gc-asset-page-1st-btn">
        Transfer projects
      </Button>
    )}
    <Button variant="outlined" onClick={manageAccountAction} color="primary" data-testid="gc-asset-page-2nd-btn">
      Manage account <OpenInNewIcon sx={{ ml: 1 }} fontSize="small" />
    </Button>
  </Stack>
);
