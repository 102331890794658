import { useCallback } from "react";

import { useApiContext } from "../../../api/context";
import { consoleErrorWithSentry } from "../../../utils";

export const updateAssetsUrl = "/v1/amazon-web-services/master-payer-accounts/update-assets";

/**
 * trigger assets discovery process for a master payer account
 */
export const useUpdateAssets = () => {
  const api = useApiContext();

  return useCallback(
    async (accountNumber): Promise<void> => {
      try {
        await api.post(`${updateAssetsUrl}/${accountNumber}`);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [api]
  );
};
