import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  slackLogo: {
    width: 16,
    height: 16,
  },
  card: {
    width: "350px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  cardContent: {
    paddingBottom: 0,
    height: "300px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "300px",
    },
  },
  clickableChip: {
    cursor: "pointer",
  },
  skillAvatar: {
    backgroundColor: "inherit!important",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "hidden",
  },
  availableContainer: {},
  contentBox: {
    flexGrow: 1,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.2)",
      borderRadius: "3px",
    },
  },
  availableContentBox: {},

  expandedFiltersContainer: {
    width: "100%",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
  },
  hitsContainer: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(2),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: theme.spacing(0.5),
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  chip: {
    margin: theme.spacing(0.25),
    flexShrink: 0,
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  select: {
    width: "10%",
    "& .MuiSelect-select": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  refinementListContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    gap: theme.spacing(1),
    width: "100%",
    flexWrap: "nowrap",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  searchField: {
    flexGrow: 1,
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px",
      padding: "4px 8px",
      borderColor: "rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      "&.Mui-focused": {
        borderColor: theme.palette.primary.main,
      },
      "& input": {
        padding: "4px 0",
        fontSize: "12px",
      },
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.action.active,
  },
  refinementChip: {
    fontWeight: "bold",
    flexShrink: 0,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dropdownContainer: {
    position: "relative",
  },
  dropdownButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: "inherit",
    color: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  activeDropdownButton: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  selectedCount: {
    position: "absolute",
    top: -8,
    right: -8,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  menuItem: {
    fontWeight: "normal",
    backgroundColor: "inherit",
  },
  selectedMenuItem: {
    fontWeight: "bold",
    backgroundColor: theme.palette.action.selected,
  },
  dropdownContent: {
    width: "100%",
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
  },
  dropdownChipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  dropdownChip: {
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedDropdownChip: {
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  refinementTextField: {
    flexGrow: 1,
    "& .MuiOutlinedInput-root": {
      borderRadius: "16px",
      padding: "4px 8px",
      borderColor: "rgba(0, 0, 0, 0.23)",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.87)",
      },
      "&.Mui-focused": {
        borderColor: theme.palette.primary.main,
      },
      "& input": {
        padding: "4px 0",
        fontSize: "12px",
      },
    },
  },
  selectedOverflowCountBox: {
    position: "absolute",
    top: -8,
    right: -8,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.75rem",
    fontWeight: "bold",
  },
  visibleItemChip: {
    fontWeight: "bold",
    flexShrink: 0,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  dropdownIconButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: "inherit",
    color: "inherit",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  refinementRootBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  refinementSecondLevelBox: {
    display: "flex",
    alignItems: "center",

    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,

    width: "100%",
    flexWrap: "nowrap",
    overflow: "hidden",
    justifyContent: "space-between",
  },
  refinementMenuItem: {
    "&.Mui-selected": {
      fontWeight: "bold",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    "&:not(.Mui-selected)": {
      fontWeight: "normal",
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },

    transition: theme.transitions.create(["background-color", "color"], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  topBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
  searchBoxWrapper: {
    flexShrink: 0,
    width: "40%",
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  filtersButtonWrapper: {
    flexShrink: 0,
  },
  badge: {
    "& .MuiBadge-badge": {
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      width: 16,
      height: 16,
      borderRadius: "50%",
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  },
  available: {
    "& .MuiBadge-badge": {
      backgroundColor: "#8fea5e",
      color: "#8fea5e",
      "&::after": {
        animation: "$ripple 2s infinite ease-in-out",
      },
    },
  },
  unavailable: {
    "& .MuiBadge-badge": {
      backgroundColor: "#fa1955",
      color: "#fa1955",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
