import { Alert, Snackbar } from "@mui/material";

import { randomLoadingMessage } from "../utils/common";

const RandomLoadingMessage = () => (
  <Snackbar open autoHideDuration={null}>
    <Alert variant="standard" severity="info">
      {randomLoadingMessage()}
    </Alert>
  </Snackbar>
);

export default RandomLoadingMessage;
