import * as Sentry from "@sentry/react";
import { compatibleMessage } from "check-error";

function shouldFilterException(exception: any) {
  if (typeof exception.message !== "string") {
    return false;
  }

  return (
    compatibleMessage(exception, "Failed to get document because the client is offline.") ||
    compatibleMessage(exception, "Network Error")
  );
}

const errorEventTarget = new EventTarget();

export const errorMonitor = {
  addEventListener: errorEventTarget.addEventListener.bind(errorEventTarget),
  removeEventListener: errorEventTarget.removeEventListener.bind(errorEventTarget),
};

export function consoleErrorWithSentry(capturedError: any, extra?: Record<string, any>) {
  // we don't allow to send errors to sentry if we are in development mode
  if (process.env.CYPRESS === "true" && !capturedError.isAxiosError) {
    throw capturedError;
  }

  const actualExtra = extra || {};

  if (capturedError.isAxiosError) {
    actualExtra.response = capturedError.response && {
      data: capturedError.response.data,
      status: capturedError.response.status,
      headers: capturedError.response.headers,
    };

    // we do not need to log error 401 as this means that the token has expired (most of the time)
    if (actualExtra?.response?.status === 401) {
      return;
    }
  }

  // eslint-disable-next-line no-console
  console.error(capturedError);
  errorEventTarget.dispatchEvent(new CustomEvent("error", { detail: capturedError }));

  if (shouldFilterException(capturedError)) {
    return;
  }

  Sentry.captureException(capturedError, { extra: actualExtra });
}
