import { DateTime } from "luxon";

import { type HeaderColumn } from "../../../../types/FilterTable";
import { sanitizeDate } from "../../../../utils/common";

const accountColumn = {
  label: "Account",
  path: "accountId",
} as const;

const roleColumn = {
  label: "Role",
  path: "roleName",
} as const;

const statusColumn = {
  label: "Status",
  path: "status",
} as const;

const linkTimeColumn = {
  label: "Link Time",
  path: "timeLinked",
  type: "DateTime",
  comparators: ["<", "<=", ">", ">="],
  placeholder: "YYYY-MM-DD",
  transform: (value: string) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL-dd", { zone: "utc" })),
  validate: (value: string) => DateTime.fromFormat(value, "yyyy-LL-dd")?.isValid,
  toOption: (value: DateTime) => {
    if (!value) {
      return;
    }
    const formattedDate = value.toFormat("yyyy-LL-dd");
    return {
      value: formattedDate,
      label: formattedDate,
    };
  },
} as const;

export const filterColumns = [accountColumn, roleColumn, statusColumn, linkTimeColumn] as const;

export const headers: HeaderColumn[] = [
  {
    value: "accountId",
    label: "Account",
    tooltip: "Account",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "roleName",
    label: "Role",
    tooltip: "Role",
    style: { align: "left", padding: "normal" },
    hidden: { xsDown: true },
  },
  {
    value: "features",
    label: "Enabled Features",
    tooltip: "Enabled Features",
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "status",
    label: "Status",
    tooltip: "Status",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "timeLinked",
    label: "Link Time",
    tooltip: "Link Time",
    style: { align: "left", padding: "normal" },
    hidden: { xsDown: true },
  },
  {
    value: "@",
    style: { align: "right", padding: "none" },
  },
];
