import { useState } from "react";

import { MenuItem, TextField } from "@mui/material";

import { CustomerNoteTagsOptions } from "./constants";
import { type TagKeys } from "./types";

type Props = {
  defaultTag?: TagKeys;
  handleTagSelect: (tag: TagKeys) => void;
};

export const FilterTagDropdown = ({ defaultTag, handleTagSelect }: Props) => {
  const [selectedTag, setSelectedTag] = useState<string>(defaultTag ?? "");
  const onTagSelect = (tag: TagKeys) => {
    setSelectedTag(tag);
    handleTagSelect(tag);
  };

  return (
    <TextField
      select
      label="Filter by tag"
      value={selectedTag}
      onChange={(e) => {
        onTagSelect(e.target.value as TagKeys);
      }}
      margin="none"
      variant="outlined"
      SelectProps={{
        displayEmpty: true,
        MenuProps: {
          MenuListProps: {
            dense: true,
          },
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{ width: "50%" }}
    >
      <MenuItem value="" divider>
        Show All
      </MenuItem>
      {CustomerNoteTagsOptions.map((tag) => (
        <MenuItem key={tag.key} value={tag.key}>
          {tag.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
