import { type AWSFlexsaveConfigurationModel } from "@doitintl/cmp-models";
import { Container, Grid, Stack, Typography } from "@mui/material";

import awsLogoDark from "../../../assets/amazon-web-services-logo-white.png";
import awsLogo from "../../../assets/amazon-web-services-new-square.png";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { type JourneyStep } from "../AWSOnboarding/constants";
import { useJourneyStepToGetHeader } from "../AWSOnboarding/hooks";
import { StateInfoBox } from "../Common/Onboarding/StateInfoBox";
import { UsefulLinks } from "../Common/Onboarding/UsefulLinks";
import { usefulLinksAWS } from "./consts";

type Props = {
  journeyStep: JourneyStep;
  data: AWSFlexsaveConfigurationModel | undefined;
};
export default function StateInfoHeader({ journeyStep, data }: Props) {
  const darkMode = useDarkThemeCheck();
  const stateHeaderDetails = useJourneyStepToGetHeader(journeyStep, data);

  return (
    <Stack sx={{ backgroundColor: "general.backgroundDark" }}>
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Stack direction="row" alignItems="center" pb={2} spacing={1.5}>
          <Typography variant="h1">Flexsave for AWS</Typography>
          <img src={darkMode ? awsLogoDark : awsLogo} width={36} alt="aws logo" />
        </Stack>

        <Grid container spacing={4} sx={{ alignItems: "flex-start" }}>
          <Grid item xs={12} sm={9}>
            <StateInfoBox
              currentState={stateHeaderDetails.infoBoxStatus}
              title={stateHeaderDetails.title}
              subtitle={stateHeaderDetails.subtitle}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <UsefulLinks links={usefulLinksAWS} />
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
}
