import { type JSX, useContext } from "react";

import { useDroppable } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { DraggableItemsContext } from "./Context";

type Props = {
  id: string;
  disabled?: boolean;
  renderContent: (items: string[], draggingOver: boolean) => JSX.Element;
  isHidden?: boolean;
};

/**
 * Contains a single dragging group area.
 * @param id - unique id for the group (should be one of the ids that DraggableContainer accepts as state)
 * @param disabled - to disable group
 * @param renderContent - will be called everytime a state of dragging is change (with new ids to render). should
 * render dragging items using the DraggableItemHolder component
 * @param isHidden
 */
export const DraggableGroup = ({ id, disabled = false, renderContent, isHidden = false }: Props) => {
  const items = useContext(DraggableItemsContext);
  const { setNodeRef, active } = useDroppable({
    id,
    disabled,
  });

  const isOver = active?.data?.current?.sortable?.containerId === id;

  const innerContent = () => {
    // need to have ref to droppable only in case of no items, so it will be possible to drop to an empty area
    // in other cases having ref will create inconsistent behaviour
    if (isHidden || items[id].length === 0) {
      return <div ref={setNodeRef} />;
    } else {
      return <div>{renderContent(items[id], isOver)}</div>;
    }
  };

  return (
    <SortableContext key={id} id={id} items={items[id]} strategy={verticalListSortingStrategy} disabled={disabled}>
      {innerContent()}
    </SortableContext>
  );
};
