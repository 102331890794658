import { FlexsaveTypes } from "@doitintl/cmp-models";

import { InitOnboardingWrapper } from "../Common/InitOnboardingWrapper";
import { Standalone } from "./Standalone";

export const OnboardingSteps = () => (
  <InitOnboardingWrapper type={FlexsaveTypes.AWS}>
    <Standalone />
  </InitOnboardingWrapper>
);
