import { Box, Typography } from "@mui/material";
import { type TypographyProps } from "@mui/material/Typography/Typography";

import { type ParsedAttributeWithStyle } from "./types";

type Props = {
  parts: ParsedAttributeWithStyle[];
  typographyProps?: TypographyProps;
};
export const Highlight = ({ parts, typographyProps }: Props) => (
  <Box display="flex" flexDirection="row">
    {parts?.map((part, index) => (
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        key={`${index}${part.value}`}
        sx={{
          whiteSpace: "pre",
          overflow: "hidden",
          display: "inline-block",
          ...(part.isHighlighted && {
            fontWeight: 700,
            color: "primary.main",
          }),
          ...(part.bold && {
            fontWeight: 700,
          }),
        }}
        {...typographyProps}
      >
        {part.value}
      </Typography>
    ))}
  </Box>
);
