import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type AssetModel } from "./Asset";
import { type CloudAnalyticsModelAttributionGroupsModel } from "./CloudAnalytics";
import { type ContractModelType } from "./Contract";
import { type CustomerModel } from "./Customer";
import { type EntityModel } from "./Entity";

type DateElement = {
  Month: number;
  Year: number;
};

export type RampPlanCommitmentPeriods = {
  actuals?: Array<number> | null;
  actualsBreakdown?: { [key: string]: number }[] | null;
  dates?: DateElement[] | null;
  endDate: Timestamp;
  periodActualsBreakdown?: { [key: string]: number } | null;
  planned: Array<number>;
  startDate: Timestamp;
  value?: number;
};

export type RampPlanCommitmentPeriodsFromContract = {
  endDate: Timestamp;
  planned: number[];
  startDate: Timestamp;
  value: number;
};

@collection("rampPlans")
export class RampModel {
  active?: boolean;

  assets?: Reference<AssetModel>[];

  attainment?: number;

  attributionGroupRef?: Reference<CloudAnalyticsModelAttributionGroupsModel>;

  commitmentPeriods!: null | Array<RampPlanCommitmentPeriods>;

  contractEntityRef!: null | Reference<EntityModel>;

  contractId?: string;

  createdBy?: string;

  creationDate?: Timestamp;

  customerRef!: Reference<CustomerModel>;

  estEndDate!: Timestamp;

  name!: string;

  onTrack?: boolean;

  origCommitmentPeriods?: Array<RampPlanCommitmentPeriodsFromContract>;

  origEstEndDate!: Timestamp;

  platform!: ContractModelType;

  startDate!: Timestamp;

  targetAmount!: number;
}
