import { type EntitlementKey } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

export const isAllowedToOnboardStandaloneAsset = (
  permissions: { doitEmployee: boolean; billingProfilesAdmin: boolean },
  state: {
    startTrialInitiated?: boolean;
    isStandalone: boolean;
    navigatorTierTrialStartDate?: DateTime;
    isFeatureEntitled: (featureKey: EntitlementKey) => boolean;
  },
  featureKey: EntitlementKey
) =>
  (permissions.doitEmployee || permissions.billingProfilesAdmin) &&
  (!state.isStandalone ||
    (state.startTrialInitiated && !state.navigatorTierTrialStartDate) ||
    state.isFeatureEntitled(featureKey));
