import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";

import { type TestNodeErrorResponse, type TestNodeResponse } from "../../types";
import { getInitialValueForModel } from "../ApiActionParametersForm/useApiActionParametersSchema";

export class TestNodeError extends Error {
  status: string | number;

  constructor(msg: string, status: number | string) {
    super(msg);
    this.name = "TestNodeError";
    this.status = status;
  }
}

export function isTestNodeErrorResponse(res: any): res is TestNodeErrorResponse {
  return (res as TestNodeErrorResponse).error !== undefined;
}

export const parseResponse = (data: TestNodeResponse) => {
  let msg: { message: string } | { error: string };

  try {
    msg = JSON.parse(data.message);
  } catch (e) {
    throw new TestNodeError("Unable to parse response", data.status);
  }

  if ("error" in msg) {
    throw new TestNodeError(msg.error, data.status);
  }

  return {
    isError: false,
    status: data.status,
    responseMessage: JSON.stringify(msg.message, null, 2),
  };
};

/**
 * Recursively removes reference fields from object, if a model is provided the reference fields keys will be retained
 * and their values will be defaulted based on the model otherwise the key will be set to undefined.
 */
export function removeReferenceFields(
  model: WithFirebaseModel<UnwrappedApiServiceModelDescriptor> | null,
  input: object
) {
  return Object.entries(input).reduce((p, [k, v]) => {
    switch (true) {
      case typeof v === "object" && !Array.isArray(v) && v !== null:
        if (isReferencedNodeValue(v)) {
          p[k] = getDefaultReferenceFieldValue(model, k);
          break;
        }

        p[k] = removeReferenceFields(model, v);
        break;
      case Array.isArray(v): {
        p[k] = v.reduce((arr, el) => {
          if (isReferencedNodeValue(el)) return arr;

          arr.push(removeReferenceFields(model, el));
          return arr;
        }, []);
        break;
      }
      default:
        p[k] = v;
    }

    return p;
  }, {});
}

function getDefaultReferenceFieldValue(
  model: WithFirebaseModel<UnwrappedApiServiceModelDescriptor> | null,
  fieldName: string
) {
  if (model?.type === ModelType.STRUCTURE) {
    const fieldModel = model?.members[fieldName];
    return fieldModel ? getInitialValueForModel(model.members[fieldName].model) : undefined;
  }

  return undefined;
}
