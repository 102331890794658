import { type costMode } from "../types";
import CostInputField from "./CostInputField";
import CostModeRadioSelection from "./CostModeRadioSelection";

type Props = {
  costNum: string;
  costNumError: boolean;
  chargeMode: costMode;
  costInputLabel: string;
  changeRadioBtn: (s: string) => void;
  onCostNumChange: (s: string) => void;
  contractSupportErrorBool: boolean;
  modeSelectionDisabled: boolean;
};

const SupportCostFields = (props: Props) => {
  const { costNum, costNumError, chargeMode, costInputLabel, changeRadioBtn, onCostNumChange, modeSelectionDisabled } =
    props;
  return (
    <>
      <CostModeRadioSelection
        chargeMode={chargeMode}
        changeRadioBtn={changeRadioBtn}
        modeSelectionDisabled={modeSelectionDisabled}
      />
      <CostInputField
        costNum={costNum}
        costNumError={costNumError}
        costInputLabel={costInputLabel}
        onCostNumChange={onCostNumChange}
        disabled={modeSelectionDisabled}
      />
    </>
  );
};

export default SupportCostFields;
