import { type JSX } from "react";

import { Box, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
  children: string;
  tooltip: string;
  wordToUnderline: string;
};

export function TitleWithTooltip({ children, tooltip, wordToUnderline }: Props): JSX.Element {
  const words = children.split(/\s+/);

  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="h4">
        <Stack direction="row" spacing={0.5}>
          {words.map((word, index) => {
            if (word === wordToUnderline) {
              return (
                <Tooltip key={word + index.toString()} title={tooltip} arrow placement="top">
                  <Box sx={{ textDecoration: "underline dotted", textUnderlineOffset: "5px" }}>{word}</Box>
                </Tooltip>
              );
            }
            return <Box key={word + index.toString()}>{word}</Box>;
          })}
        </Stack>
      </Typography>
    </Stack>
  );
}
