import { useMemo } from "react";

import { useHistory, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DateTime } from "luxon";

import Hide from "../../../Components/HideChildren/Hide";
import { useFullScreen } from "../../../utils/dialog";
import { platformsToPlatformsLabelMap } from "../consts";
import { type KnownIssue, type KnownIssuePlatform, KnownIssueStatus, KnownIssueStatusLabel } from "../types";
import { getProducts } from "../utils";

export type KnownIssuesTableRowProps = {
  knownIssue: KnownIssue;
  selectedKnownIssuesIds: KnownIssue["id"][];
  onKnownIssueSelect: (knownIssueId: KnownIssue["id"], checked: boolean) => void;
  isAllowedToModify?: boolean;
};

export const KnownIssuesTableRow = ({
  knownIssue,
  selectedKnownIssuesIds,
  onKnownIssueSelect,
  isAllowedToModify,
}: KnownIssuesTableRowProps) => {
  const { isMobile: isSmallScreen } = useFullScreen();
  const location = useLocation();
  const history = useHistory();

  const redirectToKnownIssueView = () => {
    history.push(`${location.pathname}/${knownIssue.id}`);
  };

  const isRowSelected = useMemo(
    () => selectedKnownIssuesIds.includes(knownIssue.id),
    [selectedKnownIssuesIds, knownIssue.id]
  );

  const handleKnownIssueSelect = (e) => {
    e.stopPropagation();
    onKnownIssueSelect(knownIssue.id, !isRowSelected);
  };

  const handleRowClick = (e) => {
    if (isSmallScreen) {
      redirectToKnownIssueView();
      return;
    }
    handleKnownIssueSelect(e);
  };

  const knownIssuePlatformLabel = useMemo(() => {
    const platform = Object.keys(platformsToPlatformsLabelMap).find(
      (platform) => platform === knownIssue.platform
    ) as KnownIssuePlatform;
    return platformsToPlatformsLabelMap[platform];
  }, [knownIssue]);

  const knownIssueDate = useMemo(() => {
    const dateFormat = isSmallScreen ? DateTime.DATE_SHORT : DateTime.DATETIME_MED;
    return DateTime.fromJSDate(knownIssue.dateTime.toDate()).toLocaleString(dateFormat);
  }, [isSmallScreen, knownIssue.dateTime]);

  const knownIssueStatus = useMemo(
    () =>
      knownIssue.status === KnownIssueStatus.Ongoing ? KnownIssueStatusLabel.Ongoing : KnownIssueStatusLabel.Archived,
    [knownIssue.status]
  );

  return (
    <TableRow
      style={{ cursor: isAllowedToModify ? "pointer" : "default" }}
      hover={!isSmallScreen}
      onClick={handleRowClick}
      aria-checked={isRowSelected}
      selected={isRowSelected}
    >
      <TableCell padding="checkbox">
        {isAllowedToModify && (
          <Checkbox
            color="primary"
            checked={isRowSelected}
            onClick={handleKnownIssueSelect}
            inputProps={{ "aria-labelledby": `knownIssue-${knownIssue.id}-checkbox` }}
          />
        )}
      </TableCell>
      <Hide mdDown>
        <TableCell align="left" padding="none">
          {knownIssuePlatformLabel}
        </TableCell>
      </Hide>
      <TableCell align="left" padding="none">
        {getProducts(knownIssue)}
      </TableCell>
      <Hide mdDown>
        <TableCell align="left" padding="none">
          {knownIssue.title}
        </TableCell>
        <TableCell align="left">{knownIssueStatus}</TableCell>
      </Hide>
      <TableCell align="left">{knownIssueDate}</TableCell>
      <Hide mdDown>
        <TableCell>
          <Button size="small" variant="text" color="primary" onClick={redirectToKnownIssueView}>
            View incident
          </Button>
        </TableCell>
      </Hide>
    </TableRow>
  );
};
