import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import { type HeaderColumn } from "../../types/FilterTable";

export const filterColumns = [
  {
    label: datahubTxt.TABLE_HEADERS.NAME,
    path: "dataset",
    comparators: ["==", "!=", "contains"],
  },
  {
    path: "updatedBy",
    label: datahubTxt.TABLE_HEADERS.UPDATED_BY,
    comparators: ["contains"],
  },
  {
    label: datahubTxt.TABLE_HEADERS.RECORDS,
    path: "records",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
  },
  {
    label: datahubTxt.TABLE_HEADERS.LAST_UPDATED,
    path: "lastUpdated",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
  },
] as const;

export const headers: HeaderColumn[] = [
  {
    value: "dataset",
    label: datahubTxt.TABLE_HEADERS.NAME,
    tooltip: datahubTxt.TABLE_HEADERS.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "updatedBy",
    label: datahubTxt.TABLE_HEADERS.UPDATED_BY,
    tooltip: datahubTxt.TABLE_HEADERS.UPDATED_BY,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "records",
    label: datahubTxt.TABLE_HEADERS.RECORDS,
    tooltip: datahubTxt.TABLE_HEADERS.RECORDS,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "lastUpdated",
    label: datahubTxt.TABLE_HEADERS.LAST_UPDATED,
    tooltip: datahubTxt.TABLE_HEADERS.LAST_UPDATED,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "@",
    style: { align: "left", padding: "checkbox" },
    hidden: { smDown: true },
  },
];
