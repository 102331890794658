import { createContext, useContext } from "react";

import { type GcpMarketplacePlanHistory } from "@doitintl/cmp-models";

import { type AccountConfig, type AccountsNameMap } from "./hooks/useMarketplaceAccounts";
import { type AdjustmentConfig } from "./hooks/useMarketplaceAdjustments";
import { type CustomersIdsMap } from "./hooks/useMarketplaceCustomers";
import { type EntitlementConfig } from "./hooks/useMarketplaceEntitlements";

type MarketplaceContextProps = {
  accounts?: AccountConfig[];
  accountsNameMap: AccountsNameMap;
  customersIdsMap: CustomersIdsMap;
  entitlements?: EntitlementConfig[];
  adjustments?: AdjustmentConfig[];
  planHistory: Map<string, GcpMarketplacePlanHistory[]>;
};

export const MarketplaceContext = createContext<MarketplaceContextProps>({
  accounts: [],
  accountsNameMap: new Map(),
  customersIdsMap: new Map(),
  entitlements: [],
  adjustments: [],
  planHistory: new Map(),
});

export const useMarketplaceContext = () => useContext(MarketplaceContext);
