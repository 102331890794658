import { useMemo } from "react";

import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, type ModelId, useCollectionDataOnce, useDocumentData } from "@doitintl/models-firestore";
import memoizeOne from "memoize-one";

import { StandaloneType } from "../../../types";
import { type CustomerInfo, type FlexsaveConfigurationWithGCP } from "../types";

function parseCustomerInfo(configuration: ModelId<FlexsaveConfigurationWithGCP>): CustomerInfo {
  const customerType = configuration.GCP.operations?.customerType === StandaloneType.GCP ? "Standalone" : "Resold";
  let commitType;
  if (configuration.GCP?.commitType === "commited") {
    commitType = "Committed";
  } else if (configuration.GCP?.commitType === "on-demand") {
    commitType = "On Demand";
  }
  const customerTypeWithCommit = customerType === "Resold" && commitType ? commitType : customerType;
  return {
    customerType: customerTypeWithCommit,
    primaryDomain: configuration.GCP.operations?.primaryDomain,
    id: configuration.id,
  };
}

const customerGCPConfiguration = (customerId: string) =>
  getCollection(IntegrationModel)
    .doc("flexsave")
    .collection("configuration")
    .doc(customerId)
    .narrow<FlexsaveConfigurationWithGCP>();

const getCustomersConfigQuery = memoizeOne(() =>
  getCollection(IntegrationModel)
    .doc("flexsave")
    .collection("configuration")
    .where("GCP.enabled", "==", true)
    .narrow<FlexsaveConfigurationWithGCP>()
);

export function useConfigurations() {
  const [items] = useCollectionDataOnce(getCustomersConfigQuery(), {
    transform: parseCustomerInfo,
  });

  return useMemo(() => {
    if (!items) {
      return;
    }
    const map = new Map<string, CustomerInfo>();
    for (const item of items) {
      map.set(item.id, item);
    }
    return map;
  }, [items]);
}

export function useCustomerInfoById(customerId: string) {
  const query = customerGCPConfiguration(customerId);
  const [item] = useDocumentData(query, {
    transform: parseCustomerInfo,
  });
  return item;
}
