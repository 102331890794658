import { useMemo, useState } from "react";

import { type Collaborators, type PublicAccess, Roles } from "@doitintl/cmp-models";

import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import { CloudAnalyticsEntities } from "../utilities";
import { useShareAttributionHandler } from "./hooks";

export type AttributionShareDialogProps = {
  shareDialogOpen: boolean;
  closeDialog: () => void;
  title: string;
  attributions: {
    name: string;
    collaborators: Collaborators;
    public: PublicAccess;
    id: string;
    type: string;
  }[];
};

const AttributionShareDialog = ({ shareDialogOpen, closeDialog, attributions, title }: AttributionShareDialogProps) => {
  const [shareLoading, setShareLoading] = useState<boolean>(false);
  const attributionsWithoutPresets = useMemo(() => attributions.filter((x) => x.type !== "preset"), [attributions]);
  const handleChangeSharing = useShareAttributionHandler({ attributions, closeDialog, setShareLoading });
  return (
    <ShareDialog
      key="share-dialog"
      open={shareDialogOpen}
      onClose={closeDialog}
      title={title}
      entity={CloudAnalyticsEntities.ATTRIBUTION}
      handleChangeSharing={handleChangeSharing}
      loading={shareLoading}
      availableRoles={[Roles.EDITOR, Roles.VIEWER]}
      defaultRole={Roles.EDITOR}
      shareEntities={attributionsWithoutPresets}
      showPresetExcludedAlert={attributions.length !== attributionsWithoutPresets.length}
    />
  );
};

export default AttributionShareDialog;
