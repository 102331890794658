import {
  isPerkPlatform,
  isPerkSolutionType,
  isPerkType,
  type PerkModel,
  type PerkPlatform,
  type PerkSolutionType,
  type PerkType,
} from "@doitintl/cmp-models";

import { type PerksWData } from "../../types";
import type { Ticket } from "../../Support/types";

export type GroupedPerks = {
  solution: Record<PerkSolutionType, PerksWData[]>;
  platform: Record<PerkPlatform, PerksWData[]>;
  type: Record<PerkType, PerksWData[]>;
};

export enum RegisterInterestFromType {
  "amazon-web-services" = "AWS Marketplace link",
  "google-cloud" = "GCP Marketplace link",
  InterestButton = "Register interest button",
}

export type RegisterInterestEmail = {
  userEmail: string;
  perkName: string;
  clickedOn: RegisterInterestFromType;
};

export type RegisterInterestTicket = {
  domain: string;
  perkId: string;
  perkName: string | undefined;
  perkType: string;
  tags: string[];
  platform: string;
  userEmail: string;
  accountManager: string | undefined;
  isDoitEmployee: boolean;

  solutionType?: PerkType;
  solutionOffer?: string;
  customerSolvePlan?: string;
  actionRequired?: string;
  dateRequested?: string;
  dateResponseRequired?: string;
};

export type FilterablePerkType = PerkSolutionType | PerkPlatform | PerkType;

export const isFilterablePerkType = (type: unknown): type is FilterablePerkType =>
  typeof type === "string" && (isPerkSolutionType(type) || isPerkPlatform(type) || isPerkType(type));

export type SectionTitles = {
  overview: string;
  checklist: string;
  agenda: string;
  modules: string;
};

export type PerkViewFields = {
  disabledPerk: boolean;
  perkLoading: boolean;
  perk?: PerkModel;
  sectionTitles?: SectionTitles;
  requestTicket?: Ticket;
  setRequestTicket: (ticket: Ticket) => void;
  setDisabledPerk: (disabled: boolean) => void;
};

export type RegisterInterestFields = {
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
  registerInterestProServ: () => Promise<void>;
  processing: boolean;
};
