import { type TotalSavings } from "@doitintl/cmp-models";
import { Grid } from "@mui/material";

import CountUpCard from "../../../../../Components/CountUp/CountUpCard";
import { useCurrency } from "../../../../../Components/hooks/useCurrency";

type Props = {
  totalSavings: TotalSavings;
};

export const OverviewCards = ({ totalSavings }: Props) => {
  const { customerCurrencySymbol } = useCurrency();

  return (
    <Grid container spacing={2}>
      <Grid item alignItems="center" xs={12} md={6}>
        <CountUpCard
          number={totalSavings.savingsRate}
          duration={1}
          decimals={0}
          text="All-time effective savings rate"
          suffix="%"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CountUpCard
          number={totalSavings.savings}
          duration={1}
          decimals={0}
          text="Lifetime savings total to date"
          prefix={customerCurrencySymbol}
        />
      </Grid>
    </Grid>
  );
};
