export const companySizeOptions = ["1-9", "10-49", "50-249", "250-500", "500+"];
export const companyGoalsOptions = [
  "Create customizable reports",
  "Group cloud costs",
  "Configure alerts & budgets",
  "Receive cost anomalies",
  "Split and allocate shared costs",
  "Understand my invoice",
  "Cost reporting (Lens & Dashboards)",
];
