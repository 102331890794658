import Button from "@mui/material/Button";

import { type Entity } from "../../../../Context/customer/EntitiesContext";
import { useToggle } from "../../../../utils/useToggle";
import EntityDetachDialog from "../../../EntityDetachDialog";

export const DetachBillingProfileButton = ({ entity }: { entity: Entity }) => {
  const [opened, open, close] = useToggle(false);
  return (
    <>
      <Button color="error" variant="contained" onClick={open} size="small">
        Detach billing profile
      </Button>
      {opened && <EntityDetachDialog onClose={close} entity={entity} />}
    </>
  );
};
