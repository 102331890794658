import { useCallback, useMemo } from "react";

import { Label } from "@doitintl/cmp-models";
import {
  type CollectionDataHook,
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { getCachingKeys } from "../../../utils/cachingKeys";
import mixpanel from "../../../utils/mixpanel";
import {
  assignLabels,
  type AssignLabelsRequest,
  createLabelHandler,
  deleteLabelHandler,
  updateLabelHandler,
} from "./api";
import { type LabelWithRef } from "./types";

const labelsTransform = (
  data: WithFirebaseModel<Label>,
  snapshot: QueryDocumentSnapshotModel<Label> | DocumentSnapshotModel<Label>
): LabelWithRef => ({
  data,
  ref: snapshot.ref,
});

export const useLabels = (): CollectionDataHook<LabelWithRef> => {
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const labelsQuery = useMemo(() => {
    if (!userRoles.labelsManager) {
      return;
    }
    return getCollection(Label).where("customer", "==", customer.ref);
  }, [customer.ref, userRoles.labelsManager]);

  const [data, loading, error] = useCollectionData(labelsQuery, {
    transform: labelsTransform,
    caching: true,
    cachingKeys: getCachingKeys(customer.id),
  });

  const actualData = useMemo(() => {
    if (!userRoles.labelsManager) {
      return [];
    }

    if (loading || error) {
      return undefined;
    }

    return data ?? [];
  }, [data, error, loading, userRoles.labelsManager]);

  return [actualData, !userRoles.labelsManager ? false : loading, error] as CollectionDataHook<LabelWithRef>;
};

export const useCreateLabel = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useCallback(
    async (label: Label, onSuccess: () => void, onError: () => void) => {
      try {
        await createLabelHandler({ api, customerId: customer.id, label });
        mixpanel.track("label.settings.updated", {
          action: "created",
        });
        onSuccess();
      } catch {
        onError();
      }
    },
    [api, customer.id]
  );
};

export const useUpdateLabel = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useCallback(
    async (labelId: string, label: Label, onSuccess: () => void, onError: () => void) => {
      try {
        await updateLabelHandler({ api, customerId: customer.id, labelId, label });
        mixpanel.track("label.settings.updated", {
          action: "edited",
          labelId,
        });
        onSuccess();
      } catch {
        onError();
      }
    },
    [api, customer.id]
  );
};

export const useDeleteLabel = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useCallback(
    async (labelId: string, onSuccess: () => void, onError: () => void) => {
      try {
        await deleteLabelHandler({ api, customerId: customer.id, labelId });
        mixpanel.track("label.settings.updated", {
          action: "deleted",
          labelId,
        });
        onSuccess();
      } catch {
        onError();
      }
    },
    [api, customer.id]
  );
};

const updateLabelAssignmentMixpanelEvent = (req: AssignLabelsRequest) => {
  for (const obj of req.objects) {
    for (const addedLabel of req.addLabels) {
      mixpanel.track("label.assignment.updated", {
        action: "added",
        objectId: obj.objectId,
        labelId: addedLabel,
      });
    }

    for (const removedLabel of req.removeLabels) {
      mixpanel.track("label.assignment.updated", {
        action: "added",
        objectId: obj.objectId,
        labelId: removedLabel,
      });
    }
  }
};

export const useAssignLabels = () => {
  const api = useApiContext();
  const customerId = useCustomerId();

  return useCallback(
    async (req: AssignLabelsRequest, onSuccess: () => void, onError: (error: any) => void) => {
      try {
        await assignLabels(api, customerId, req);
        updateLabelAssignmentMixpanelEvent(req);
        onSuccess();
      } catch (e: any) {
        onError(e);
      }
    },
    [api, customerId]
  );
};
