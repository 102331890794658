import { createParser, type EventSourceParser, type ParsedEvent } from "eventsource-parser";

import { type AnswerExtras } from "./useAva";

const defaultErrorMessage = "Failed to get answer";

class EventSourceParserStream extends TransformStream<string, ParsedEvent> {
  constructor() {
    let parser!: EventSourceParser;

    super({
      start(controller) {
        parser = createParser((event) => {
          if (event.type === "event") {
            controller.enqueue(event);
          }
        });
      },
      transform(chunk) {
        parser.feed(chunk);
      },
    });
  }
}

const createEventHandler = ({
  answerCallback,
  conversationCallback,
  conversationId,
}: {
  answerCallback: (chunk: string, answerId?: string) => boolean;
  conversationCallback: (conversationId: string) => void;
  conversationId: string | undefined;
}) => {
  let chunkConversationId = conversationId;
  let chunkAnswerId: string | undefined;
  let shouldStop = false;

  return (event: ParsedEvent) => {
    if (event.type === "event" && !event.event) {
      const data = JSON.parse(event.data);

      if (!conversationId && "conversationId" in data) {
        chunkConversationId = data.conversationId;
        conversationCallback(data.conversationId);
      }

      if ("answerId" in data) {
        chunkAnswerId = data.answerId;
      }

      if ("answer" in data) {
        shouldStop = answerCallback(data.answer, chunkAnswerId);
      }

      if ("error" in data) {
        const errorToShow = typeof data.error === "boolean" ? defaultErrorMessage : data.error;
        throw new Error(errorToShow);
      }
    }

    return { chunkConversationId, chunkAnswerId, shouldStop };
  };
};

export const fetchQuestion = async ({
  fetcher,
  question,
  answerCallback,
  conversationCallback,
  conversationId,
  clientName = "console",
  abortStatusCallback,
}: {
  fetcher: (data: any) => Promise<Response>;
  question: string;
  answerCallback: (chunk: string, answerId?: string) => boolean;
  conversationCallback: (conversationId: string) => void;
  conversationId: string | undefined;
  clientName?: string;
  abortStatusCallback?: () => boolean;
}): Promise<AnswerExtras & { conversationId: string }> => {
  const data = {
    clientName,
    question,
    conversationId,
  } as const;
  const response = await fetcher(data);

  if (!response.body) {
    throw new Error(`${defaultErrorMessage} (no response)`);
  }

  const reader = response.body
    .pipeThrough(new TextDecoderStream())
    .pipeThrough(new EventSourceParserStream())
    .getReader();

  const onEvent = createEventHandler({ answerCallback, conversationCallback, conversationId });

  let chunkConversationId = conversationId;
  let chunkAnswerId: string | undefined;
  let shouldStop = false;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (abortStatusCallback?.()) {
      await reader.cancel("client closed connection");
      break;
    }
    if (value) {
      ({ chunkConversationId, chunkAnswerId, shouldStop } = onEvent(value));
    }
    if (done || shouldStop) {
      break;
    }
  }

  if (chunkConversationId && chunkAnswerId) {
    return {
      conversationId: chunkConversationId,
      answerId: chunkAnswerId,
      done: true,
    };
  }

  throw new Error(defaultErrorMessage);
};
