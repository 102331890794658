import {
  type AssetModelBillingAnomaly,
  type AssetModelBillingAnomalyModel,
  type CurrencyCode,
} from "@doitintl/cmp-models";
import { type Theme } from "@mui/material";
import { type SeriesOptionsType } from "highcharts";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";

import { highchartColors } from "../../../cmpBaseColors";
import { defaultCurrencySymbol } from "../../../constants/common";
import { asyncConvertCurrencyTo } from "../../../Context/AsyncCurrencyConverterContext";
import { getAnomalyStartTime } from "../utils";
import { dataSeriesLabels } from "./consts";

type OldConvertedData = AssetModelBillingAnomaly.ChartEntry & {
  actual_cost_converted: number;
  high_converted: number;
  low_converted: number;
  sku_costs: number[];
};

const asyncCreateOldChartData = async (
  rawData: AssetModelBillingAnomalyModel,
  now: Date,
  customerCurrency: CurrencyCode
) => {
  const data = Object.entries(rawData.chart_data);

  const chartDataEntries = await Promise.all(
    data.map(async ([key, value]): Promise<[string, OldConvertedData]> => {
      const actualCostConverted = await asyncConvertCurrencyTo(
        value.actual_cost ?? 0,
        now,
        defaultCurrencySymbol,
        customerCurrency
      );
      const highConverted = await asyncConvertCurrencyTo(
        Math.max(value.high, 0),
        now,
        defaultCurrencySymbol,
        customerCurrency
      );
      const lowConverted = await asyncConvertCurrencyTo(
        Math.max(value.low, 0),
        now,
        defaultCurrencySymbol,
        customerCurrency
      );
      const skuCostsConverted = await Promise.all(
        value.sku_costs?.map((item) => asyncConvertCurrencyTo(item, now, defaultCurrencySymbol, customerCurrency)) ?? []
      );

      return [
        key,
        {
          ...value,
          actual_cost_converted: actualCostConverted,
          high_converted: highConverted,
          low_converted: lowConverted,
          sku_costs: skuCostsConverted,
        },
      ];
    })
  );

  return Object.fromEntries(chartDataEntries);
};

function createZones(chartData, startDate, color4, color5) {
  const zones: { value: number; color: string }[] = [];
  Object.keys(chartData)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      const d = new Date(key.replace(/-/g, "/")).getTime();
      zones.push({
        value: d,
        color: startDate <= d ? color4 : color5,
      });
    });
  return zones;
}

function createSkuData(chartData, skuNames: string[], shouldSkuGraph: boolean) {
  const skuData: (Highcharts.SeriesOptionsType & {
    data: [date: number, value: number | null][];
    color: string | undefined;
  })[] = [];
  Object.keys(chartData)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      const d = new Date(key.replace(/-/g, "/")).getTime();
      shouldSkuGraph &&
        skuNames.forEach((name, index) => {
          const validSku = chartData[key]?.sku_costs[index] && chartData[key]?.sku_costs[index] > 0;
          const val = validSku ? chartData[key]?.sku_costs[index] : 0;
          if (skuData[index]) {
            skuData[index].data.push([d, val]);
          } else {
            skuData.push({
              name: name === "Other" ? "Others" : name,
              data: [[d, val]],
              zIndex: 1,
              type: "column",
              linkedTo: ":previous",
              showInLegend: true,
              color: undefined,
            });
          }
        });
    });
  return skuData;
}

function createActualCostAndRange(chartData) {
  let actualCost: ([date: number, value: number | null] | { x: number; y: number; id: string })[] = [];
  let range: [date: number, low: number | null, high: number | null][] = [];
  Object.keys(chartData)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      const d = new Date(key.replace(/-/g, "/")).getTime();
      actualCost.push([d, chartData[key].actual_cost_converted]);
      range.push([d, chartData[key].low_converted, chartData[key].high_converted]);
    });
  actualCost = sortBy(actualCost, [0]);
  range = sortBy(range, [0]);
  return { actualCost, range };
}

export const asyncGetOldAnomalyDataSeries = async (
  rawData: AssetModelBillingAnomalyModel,
  customerCurrency: CurrencyCode,
  setAlertData,
  theme: Theme
): Promise<SeriesOptionsType[]> => {
  const [, , , color3, color4, color5, color6, color7, , color9, color10, color11] =
    highchartColors[theme.palette.mode];

  const now = new Date();
  const data = {
    ...rawData,
    chart_data: await asyncCreateOldChartData(rawData, now, customerCurrency),
  };

  setAlertData(data);

  const chartData = data.chart_data;
  const startDate = getAnomalyStartTime(data.metadata).toMillis();

  const skuNames: string[] = uniq(
    Object.values(chartData)
      .map((item) => item.sku_names ?? [])
      .flat()
  );
  const shouldSkuGraph = Boolean(skuNames[0] ?? false) && skuNames?.length < 5; // displaying SKU graph only if there are max. 4 skus

  const zones = createZones(chartData, startDate, color4, color5);
  const skuData = createSkuData(chartData, skuNames, shouldSkuGraph);
  const { actualCost, range } = createActualCostAndRange(chartData);

  const colors = [color9, color10, color11, color3];
  skuData.forEach((i, k) => {
    i.color = colors[k];
  });

  const lastActualCost = actualCost[actualCost.length - 1];
  actualCost[actualCost.length - 1] = {
    x: lastActualCost[0],
    y: lastActualCost[1],
    id: "anomalyPoint",
  };
  const lastDate = lastActualCost[0] as number;
  const minus = actualCost.length > 2 ? 2 : 1;
  const step = lastDate - actualCost[actualCost.length - minus][0];
  const periodPart = Math.round(actualCost.length / 3);
  for (let i = 0; i < periodPart; i++) {
    shouldSkuGraph &&
      skuData.forEach((skuType) => {
        skuType.data.push([lastDate + step * (i + 1), null]);
      });
    actualCost.push([lastDate + step * (i + 1), null]);
    range.push([lastDate + step * (i + 1), null, null]);
  }

  const allDataSeries: Highcharts.SeriesOptionsType[] = [
    {
      name: "Actual Cost",
      data: actualCost,
      type: "spline",
      zIndex: 2,
      zoneAxis: "x",
      zones,
      color: color7,
    },
    {
      name: "Normal Range",
      data: range,
      type: "arearange",
      lineWidth: 0,
      linkedTo: ":previous",
      color: color6,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
      },
    },
    ...skuData,
  ];
  return allDataSeries;
};

type NewConvertedData = AssetModelBillingAnomaly.ChartEntry & {
  updated_value_converted: number | undefined;
  snapshot_converted: number;
  high_converted: number;
  low_converted: number;
};

const asyncCreateNewChartData = async (
  rawData: AssetModelBillingAnomalyModel,
  now: Date,
  customerCurrency: CurrencyCode
) => {
  const chartDataEntries = await Promise.all(
    Object.entries(rawData.chart_data).map(async ([key, value]): Promise<[string, NewConvertedData]> => {
      const updatedValueConverted = value.updated_value
        ? await asyncConvertCurrencyTo(value.updated_value, now, defaultCurrencySymbol, customerCurrency)
        : undefined;

      const snapshotConverted = await asyncConvertCurrencyTo(
        value.snapshot_value ?? 0,
        now,
        defaultCurrencySymbol,
        customerCurrency
      );
      const highConverted = await asyncConvertCurrencyTo(
        Math.max(value.high, 0),
        now,
        defaultCurrencySymbol,
        customerCurrency
      );
      const lowConverted = await asyncConvertCurrencyTo(
        Math.max(value.low, 0),
        now,
        defaultCurrencySymbol,
        customerCurrency
      );

      return [
        key,
        {
          ...value,
          updated_value_converted: updatedValueConverted,
          snapshot_converted: snapshotConverted,
          high_converted: highConverted,
          low_converted: lowConverted,
        },
      ];
    })
  );

  return Object.fromEntries(chartDataEntries);
};

function createNewDataSeries(chartData: any): {
  costAtTimeOfDetection: any[];
  costSinceDetection: any[];
  adjustmentSinceDetection: any[];
  range: any[];
} {
  const costAtTimeOfDetection: ([date: number, value: number | null] | { x: number; y: number; id: string })[] = [];
  const costSinceDetection: ([date: number, value: number | null] | { x: number; y: number; id: string })[] = [];
  const adjustmentSinceDetection: ([date: number, value: number | null] | { x: number; y: number; id: string })[] = [];
  const range: [date: number, low: number | null, high: number | null][] = [];

  Object.keys(chartData)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      const d = new Date(key.replace(/-/g, "/")).getTime();

      costAtTimeOfDetection.push([
        d,
        Math.min(chartData[key].snapshot_converted, chartData[key].updated_value_converted ?? Number.MAX_VALUE) || null,
      ]);
      adjustmentSinceDetection.push([
        d,
        Math.max(chartData[key].snapshot_converted - (chartData[key].updated_value_converted ?? Number.MAX_VALUE), 0) ||
          null,
      ]);
      costSinceDetection.push([
        d,
        Math.max((chartData[key].updated_value_converted ?? 0) - chartData[key].snapshot_converted, 0) || null,
      ]);
      range.push([d, chartData[key].low_converted, chartData[key].high_converted]);
    });

  return {
    costAtTimeOfDetection,
    costSinceDetection,
    adjustmentSinceDetection: adjustmentSinceDetection.some((x) => x[1] > 0) ? adjustmentSinceDetection : [],
    range: sortBy(range, [0]),
  };
}

function getAnomalyInactiveDate(chartData: any) {
  let dateAnomalyInactive = 0;

  Object.keys(chartData)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      const d = new Date(key.replace(/-/g, "/")).getTime();

      if (!dateAnomalyInactive && chartData[key].status === "INACTIVE") {
        dateAnomalyInactive = d;
      }
    });
  return dateAnomalyInactive;
}

function addAnomalyInactivePoint(costAtTimeOfDetection: any[], costSinceDetection: any[], dateAnomalyInactive: number) {
  const anomalyInactivePointIndex = costAtTimeOfDetection.findIndex((x) => x[0] === dateAnomalyInactive);
  const anomalyInactivePointDataSeries =
    costSinceDetection[anomalyInactivePointIndex][1] > 0 ? costSinceDetection : costAtTimeOfDetection;
  const anomalyInactivePointData = anomalyInactivePointDataSeries[anomalyInactivePointIndex];

  anomalyInactivePointDataSeries[anomalyInactivePointIndex] = {
    x: anomalyInactivePointData[0],
    y: anomalyInactivePointData[1],
    id: "inactivePoint",
  };
}

function addAnomalyDetectionPoint(costAtTimeOfDetection: any[], adjustmentSinceDetection: any[], startDate: number) {
  const anomalyDetectionPointIndex = costAtTimeOfDetection.findIndex((x) => x[0] === startDate);
  const anomalyDetectionPointDataSeries =
    adjustmentSinceDetection[anomalyDetectionPointIndex]?.[1] > 0 ? adjustmentSinceDetection : costAtTimeOfDetection;
  const anomalyDetectionPointData = anomalyDetectionPointDataSeries?.[anomalyDetectionPointIndex];

  anomalyDetectionPointDataSeries[anomalyDetectionPointIndex] = {
    x: anomalyDetectionPointData[0],
    y: anomalyDetectionPointData[1],
    id: "anomalyPoint",
  };
}

export const asyncGetNewAnomalyDataSeries = async (
  rawData: AssetModelBillingAnomalyModel,
  customerCurrency: CurrencyCode,
  setAlertData,
  theme: Theme
): Promise<SeriesOptionsType[]> => {
  const [color0, color1, color2] = highchartColors[theme.palette.mode];

  const now = new Date();
  const data = {
    ...rawData,
    chart_data: await asyncCreateNewChartData(rawData, now, customerCurrency),
  };

  setAlertData(data);
  const chartData = data.chart_data;
  const startDate = getAnomalyStartTime(data.metadata).toMillis();
  const dateAnomalyInactive = getAnomalyInactiveDate(chartData);

  const { costAtTimeOfDetection, costSinceDetection, adjustmentSinceDetection, range } = createNewDataSeries(chartData);

  addAnomalyDetectionPoint(costAtTimeOfDetection, adjustmentSinceDetection, startDate);

  if (dateAnomalyInactive) {
    addAnomalyInactivePoint(costAtTimeOfDetection, costSinceDetection, dateAnomalyInactive);
  }

  const lastActualCost = costSinceDetection[costSinceDetection.length - 1];
  const lastDate = (lastActualCost[0] as number) ?? (lastActualCost.x as number);
  const minus = costSinceDetection.length > 2 ? 2 : 1;
  const step = lastDate - costSinceDetection[costSinceDetection.length - minus][0];
  const periodPart = Math.round(costSinceDetection.length / 3);
  for (let i = 0; i < periodPart; i++) {
    costAtTimeOfDetection.push([lastDate + step * (i + 1), null]);
    costSinceDetection.push([lastDate + step * (i + 1), null]);
    range.push([lastDate + step * (i + 1), null, null]);
  }

  const allDataSeries: Highcharts.SeriesOptionsType[] = [
    {
      name: dataSeriesLabels.costAtTimeOfDetection,
      data: costAtTimeOfDetection,
      type: "column",
      zIndex: 1,
      zoneAxis: "x",
      color: color0,
    },
    {
      name: dataSeriesLabels.costSinceTimeOfDetection,
      data: costSinceDetection,
      type: "column",
      zIndex: 1,
      zoneAxis: "x",
      color: color1,
    },
    adjustmentSinceDetection.length > 0 && {
      name: dataSeriesLabels.adjustmentSinceTimeOfDetection,
      data: adjustmentSinceDetection,
      type: "column",
      zIndex: 1,
      zoneAxis: "x",
      color: {
        pattern: {
          path: {
            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
            strokeWidth: 3,
          },
          width: 10,
          height: 10,
          backgroundColor: color0,
          color: color1,
          opacity: 1,
        },
      },
    },
    {
      name: dataSeriesLabels.normalRange,
      data: range,
      type: "arearange",
      lineWidth: 0,
      color: color2,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
      },
    },
  ].filter((x) => x) as Highcharts.SeriesOptionsType[];
  return allDataSeries;
};

export function isNewAnomaly(rawData: AssetModelBillingAnomalyModel): boolean {
  if (rawData.status) {
    return true;
  }
  return false;
}

export function getChartPointValue(
  points: Highcharts.TooltipFormatterContextObject[],
  currentPoint: Highcharts.TooltipFormatterContextObject
) {
  if (currentPoint.series.name === dataSeriesLabels.costAtTimeOfDetection) {
    const adjustmentSinceDetection = points.find(
      (x) => x.series.name === dataSeriesLabels.adjustmentSinceTimeOfDetection
    );
    return (currentPoint.y! + (adjustmentSinceDetection?.y ?? 0)).toFixed(2);
  }

  if (currentPoint.series.name === dataSeriesLabels.normalRange) {
    return `${currentPoint.point.options.low?.toFixed(2)} - ${currentPoint.point.options.high?.toFixed(2)}`;
  }

  if (currentPoint.series.name === dataSeriesLabels.adjustmentSinceTimeOfDetection) {
    return `-${currentPoint.y!.toFixed(2)}`;
  }

  return currentPoint.y!.toFixed(2);
}
