import { type FlexsaveType } from "@doitintl/cmp-models";
import { type AxiosError, type AxiosInstance } from "axios";

import { type SavingsReport } from "./types";

export type TestConnectionResponse = true | false | "timeout";

export class StandaloneApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    private readonly customerId: string,
    private readonly type: FlexsaveType
  ) {
    this.api = api;
    this.customerId = customerId;
    this.apiPrefix = `/v1/flexsave-standalone-${type.toLowerCase()}`;
    this.type = type;
  }

  public refreshEstimations() {
    return this.api.get(`${this.apiPrefix}/refresh-estimations/${this.customerId}`);
  }

  public deleteAWSEstimation(accountId: string) {
    return this.api.delete(`${this.apiPrefix}/estimation/${this.customerId}/${accountId}`);
  }

  savingsRecommendations(accountId: string, payload: Record<string, string>) {
    return this.api.post(`${this.apiPrefix}/savings-recommendations/${this.customerId}/${accountId}`, payload);
  }

  public agreeContract(email: string, additionalParams?: Record<string, unknown>) {
    return this.api.post(`${this.apiPrefix}/contract-agreed/`, {
      ...additionalParams,
      customerId: this.customerId,
      email,
    });
  }

  public async testEstimationsConnectionGCP(organizationId: string, billingAccountId: string, dryRun: boolean) {
    try {
      const response = await this.api.post(`${this.apiPrefix}/test-estimations-connection/`, {
        orgId: organizationId,
        billingAccountId,
        customerId: this.customerId,
        dryRun,
      });

      return response.data?.success ?? false;
    } catch {
      return false;
    }
  }

  public async testBillingConnectionGCP(
    projectId: string,
    datasetId: string,
    tableId: string,
    dryRun: boolean,
    organizationId?: string
  ): Promise<Promise<TestConnectionResponse>> {
    try {
      const response = await this.api.post(
        `${this.apiPrefix}/activate/`,
        {
          customerId: this.customerId,
          orgId: organizationId,
          projectId,
          datasetId,
          tableId,
          dryRun,
        },
        {
          timeout: 1000 * 60 * 4,
        }
      );
      return response.data?.success ?? false;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.response?.status === 524) {
        return "timeout";
      }
      return false;
    }
  }

  public initOnboarding() {
    return this.api.get(`${this.apiPrefix}/init-onboarding/${this.customerId}`);
  }

  public async downloadSavingsReport(type: FlexsaveType, savingsReport: SavingsReport): Promise<boolean> {
    try {
      const response = await this.api.get(`${this.apiPrefix}/savings-report/${this.customerId}`, {
        responseType: "blob",
        params: savingsReport,
      });

      const downloadFileName = `${type} Estimation.pdf`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadFileName);
      link.click();
      link.remove();
      return true;
    } catch {
      return false;
    }
  }
}
