import { navigationId } from "../../../constants";

export const scrollToId = (id: string) => {
  const el = document.getElementById(id);
  const nav = document.getElementById(navigationId);

  if (!el) {
    return;
  }

  const navOffset = nav?.offsetHeight || 0;

  window.scrollTo({ top: el.offsetTop - navOffset, behavior: "smooth" });
};
