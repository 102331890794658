import { type TimeInterval, type TimeSettingsMode } from "@doitintl/cmp-models";
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";

import {
  convertTimeSettingsModeToIndex,
  CurrentTimeRangeCheckBoxOptions,
  TimeRangeTabsLabel,
} from "../../../../utilities";
import TabPanel from "./TabPanel";

type CurrentProps = {
  mode: TimeSettingsMode;
  time: TimeInterval;
  onChangeTimeInterval: (time: TimeInterval) => void;
};

const Current = ({ mode, time, onChangeTimeInterval }: CurrentProps) => (
  <TabPanel index={1} value={convertTimeSettingsModeToIndex(mode)} data-cy="tab-current-container">
    <Stack alignItems="center" direction="row" spacing={2}>
      <Typography variant="subtitle1" data-cy="tab-current-title">
        {TimeRangeTabsLabel.Current}
      </Typography>

      <RadioGroup row value={time}>
        {CurrentTimeRangeCheckBoxOptions.map(({ value, label }) => (
          <FormControlLabel
            control={<Radio />}
            data-cy={`tab-current-${value}`}
            key={value}
            label={label}
            onClick={() => {
              onChangeTimeInterval(value);
            }}
            value={value}
          />
        ))}
      </RadioGroup>
    </Stack>
  </TabPanel>
);

export default Current;
