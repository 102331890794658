import { Criteria, type DiscountDetails, ManagedType, Status } from "@doitintl/cmp-models";

import { type DiscountDTO, type DiscountValues, type EditFormValues, type PayerConfigDTO } from "./types";

export const convertToDateTime = (dateTime) => (dateTime?.seconds ? new Date(dateTime.seconds * 1000) : dateTime);

export const isStatus = (status?: string): status is Status => Object.values(Status).includes(status as Status);

export const makeFormDiscountValues = (values: DiscountDetails[]) => {
  const latestDiscounts: { [key in Criteria]?: DiscountValues } = {};

  values?.forEach((item) => {
    const criteria = item.criteria;
    const effectiveDate = convertToDateTime(item.effectiveDate);

    if (
      criteria &&
      (!latestDiscounts[criteria] || convertToDateTime(latestDiscounts[criteria]?.effectiveDate) < effectiveDate)
    ) {
      latestDiscounts[criteria] = {
        discount: String(item.discount),
        effectiveDate,
      };
    }
  });

  return latestDiscounts;
};

export function createDiscountDTO(
  discountValue: string | undefined,
  effectiveDate: string | undefined,
  initialDiscountValue: string | undefined,
  initialEffectiveDate: string | undefined,
  criteria: Criteria
): DiscountDTO | null {
  if (
    discountValue !== "" &&
    effectiveDate !== "" &&
    (discountValue !== initialDiscountValue || effectiveDate !== initialEffectiveDate)
  ) {
    return {
      discount: discountValue === "" ? null : Number(discountValue),
      effectiveDate: effectiveDate ? effectiveDate : null,
      criteria,
    };
  }
  return null;
}

export const convertServiceDiscounts = (initialValues: EditFormValues, formVals: EditFormValues): DiscountDTO[] =>
  [
    createDiscountDTO(
      formVals.computeDiscount,
      formVals.computeEffectiveDate,
      initialValues.computeDiscount,
      initialValues.computeEffectiveDate,
      Criteria.flexsave
    ),
    createDiscountDTO(
      formVals.sagemakerDiscount,
      formVals.sagemakerEffectiveDate,
      initialValues.sagemakerDiscount,
      initialValues.sagemakerEffectiveDate,
      Criteria.sagemaker
    ),
    createDiscountDTO(
      formVals.rdsDiscount,
      formVals.rdsEffectiveDate,
      initialValues.rdsDiscount,
      initialValues.rdsEffectiveDate,
      Criteria.rds
    ),
  ].filter((dto): dto is DiscountDTO => dto !== null);

export const convertToConfigRequest = (formVals: EditFormValues, initialValues: EditFormValues): PayerConfigDTO => {
  const payload: PayerConfigDTO = {
    status: formVals.status,
    managed: formVals.managed === ManagedType.manual ? ManagedType.manual : ManagedType.auto,
    minSpend: Number.isFinite(formVals.minSpend) ? Number(formVals.minSpend) : undefined,
    maxSpend: Number.isFinite(formVals.maxSpend) ? Number(formVals.maxSpend) : undefined,
    seasonal: typeof formVals.seasonal === "boolean" ? formVals.seasonal : undefined,
    targetPercentage: Number.isFinite(formVals.targetPercentage) ? Number(formVals.targetPercentage) : undefined,
    rdsStatus: isStatus(formVals.rdsStatus) ? formVals.rdsStatus : undefined,
    sagemakerStatus: isStatus(formVals.sagemakerStatus) ? formVals.sagemakerStatus : undefined,
    statusChangeReason: formVals.statusChangeReason,
    keepActiveEvenWhenOnCredits: formVals.keepActiveEvenWhenOnCredits,
    rdsTargetPercentage: Number.isFinite(formVals.rdsTargetPercentage)
      ? Number(formVals.rdsTargetPercentage)
      : undefined,
  };

  payload.discount = convertServiceDiscounts(initialValues, formVals);

  return payload;
};
