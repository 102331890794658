import {
  AnalyticsDataSource,
  DatahubFixedKeys,
  type Key,
  Keys,
  Metadata,
  Renderer,
  TimeInterval,
  TimeSettingsMode,
} from "@doitintl/cmp-models";

import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import { createRowsFilters } from "../CloudAnalytics/generateReport/generateReport";
import { colsMap, defaultConfig } from "../CloudAnalytics/generateReport/utils";
import { FixedFilters } from "../CloudAnalytics/utilities";
import { type CSVDataType } from "./CSVImport/ImportCSV";

const NUM_OF_MONTHS = 3;

export const isFixedDatahubKey = (field: string) => {
  const parts = field.split("fixed.");

  if (parts.length === 2 && parts[1]) {
    const key = parts[1];

    return DatahubFixedKeys.includes(key as Key);
  }

  return false;
};

export const buildConfig = (values: string[]) => {
  const timeInterval = TimeInterval.MONTH;
  const timeSettings = {
    amount: NUM_OF_MONTHS,
    mode: TimeSettingsMode.Last,
    unit: timeInterval,
    includeCurrent: true,
  };

  const { rows, filters } = createRowsFilters([
    {
      type: Metadata.FIXED,
      id: FixedFilters.CLOUD,
      values,
      groupBy: true,
    },
  ]);

  const cols = colsMap[TimeInterval.MONTH];

  return {
    ...defaultConfig,
    rows,
    cols,
    filters,
    dataSource: AnalyticsDataSource.BILLING_DATAHUB,
    renderer: Renderer.TABLE,
    timeInterval,
    timeSettings,
  };
};

const transformCSVHeaders = (headers) =>
  headers.map((header) => {
    const headerLowerCase = header.toLowerCase();

    if (isFixedDatahubKey(headerLowerCase)) {
      return headerLowerCase;
    }

    const metricStandardTypes = ["metric.cost", "metric.usage", "metric.savings"];
    if (metricStandardTypes.includes(headerLowerCase)) {
      return headerLowerCase;
    }

    return header;
  });

const transformCSVData = (data, originalHeaders, newHeaders) => {
  data.forEach((row, rowIndex) => {
    const updatedRow = {};

    newHeaders.forEach((newHeader, index) => {
      const originalHeader = originalHeaders[index];

      updatedRow[newHeader] = row[originalHeader];
    });

    data[rowIndex] = updatedRow;
  });
};

export const transformCSV = (results) => {
  const originalHeaders = results.meta.fields;
  const newHeaders = transformCSVHeaders(originalHeaders);

  transformCSVData(results.data, originalHeaders, newHeaders);

  results.meta.fields = newHeaders;
};

export const validateCSV = (results: Papa.ParseResult<CSVDataType>) => {
  if (!results?.meta?.fields) {
    throw datahubTxt.NO_DATA_FOUND;
  }
  const errorMessages: string[] = [];

  const requiredHeaders = ["usage_date"];
  const optionalHeaders = ["id"];

  for (const header of requiredHeaders) {
    if (!results.meta.fields.includes(header)) {
      errorMessages.push(`${datahubTxt.MISSING_REQUIRED_HEADER} ${header}`);
    }
  }

  const labelsAndProjectLabels = results.meta.fields.filter(
    (field) => field.startsWith("label.") || field.startsWith("project_label.")
  );
  const metrics = results.meta.fields.filter((field) => field.startsWith("metric."));
  const keys = results.meta.fields.filter((field) => [...Object.values(Keys)].includes(field as Keys));
  const fixedKeys = results.meta.fields.filter((field) => isFixedDatahubKey(field));

  if (fixedKeys.length === 0 && labelsAndProjectLabels.length === 0) {
    errorMessages.push(datahubTxt.DIMENSION_OR_LABEL_REQUIRED);
  }

  if (metrics.length === 0) {
    errorMessages.push(datahubTxt.AT_LEAST_ONE_METRIC_REQUIRED);
  }

  const validHeaders = [
    ...requiredHeaders,
    ...optionalHeaders,
    ...keys,
    ...labelsAndProjectLabels,
    ...metrics,
    ...fixedKeys,
  ];

  for (const header of results.meta.fields) {
    if (!validHeaders.includes(header)) {
      errorMessages.push(`${datahubTxt.INVALID_HEADER} ${header}`);
    }
  }

  if (errorMessages.length > 0) {
    throw errorMessages;
  }
};

/**
 * Checks if any item in the list was updated within the specified number of minutes.
 * @param {Array<Object>} items - Array of objects.
 * @param {string} propertyName - The name of the property to check.
 * @param {number} minutes - The number of minutes to check.
 * @returns {boolean} True if any item was updated within the specified number of minutes, false otherwise.
 */
export const isAnySelectedUpdatedWithinLastXMinutes = (
  items: Array<{ [key: string]: any }>,
  propertyName: string,
  minutes: number
): boolean => {
  const currentDate = new Date();
  return items.some((item) => {
    const dateToCheck = new Date(item[propertyName]);
    const diffInMinutes = (currentDate.getTime() - dateToCheck.getTime()) / (1000 * 60);
    return diffInMinutes <= minutes;
  });
};

export const deleteProcessingMinutes = 90;
export const metadataProcessingMinutes = 10;

export type CSVError = string | { field: string; message: string };

export const formatCSVError = (error: CSVError) =>
  typeof error === "string" ? error : `${error.field} - ${error.message}`;
