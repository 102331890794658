import { DateTime } from "luxon";

import { defaultHeaderStyle, standardToOption } from "../../../../../Components/FilterTable/Table/FilterTableHeader";
import {
  type DataColumn,
  type HeaderColumn,
  numberComparators,
  stringComparators,
} from "../../../../../types/FilterTable";
import { threeDotHeaderSection } from "../../../assetUtils";

/*
 * Columns
 */

const assetTypeCol: DataColumn = {
  label: "Asset type",
  path: "assetType",
  comparators: stringComparators,
  toOption: standardToOption,
};
const domainCol: DataColumn = {
  label: "Domain",
  path: "domain",
  comparators: stringComparators,
  toOption: standardToOption,
};
const licensesCol: DataColumn = {
  label: "Licenses",
  path: "licenses",
  comparators: numberComparators,
  toOption: standardToOption,
};
const seatsUsedCol: DataColumn = {
  label: "Seats used",
  path: "seatsUsed",
  comparators: numberComparators,
  toOption: standardToOption,
};
const expiryDateCol: DataColumn = {
  label: "Expiry date",
  path: "expiryDate",
  comparators: numberComparators,
  toOption: (value: DateTime) => ({
    value,
    label: value.equals(DateTime.fromMillis(0)) ? "Never" : value.toLocaleString(),
  }),
};
const renewalCol: DataColumn = {
  label: "Renewal status",
  path: "renewalStatus",
  comparators: stringComparators,
  toOption: standardToOption,
};
const resellerCol: DataColumn = {
  label: "Reseller console",
  path: "reseller",
  comparators: stringComparators,
  toOption: standardToOption,
};
const billingProfileCol: DataColumn = {
  label: "Billing profile",
  path: "billingProfile",
  comparators: stringComparators,
  toOption: standardToOption,
};

/*
 * Headers
 */

const assetTypeHead: HeaderColumn = {
  label: assetTypeCol.label,
  value: assetTypeCol.path,
  style: defaultHeaderStyle,
};
const domainHead: HeaderColumn = {
  label: domainCol.label,
  value: domainCol.path,
  style: defaultHeaderStyle,
};
const licensesHead: HeaderColumn = {
  label: "License quantity",
  value: licensesCol.path, // Sort according to total licenses, even though we're displaying both licences and seats used.
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};
const expiryDateHead: HeaderColumn = {
  label: expiryDateCol.label,
  value: expiryDateCol.path,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};
const renewalHead: HeaderColumn = {
  label: renewalCol.label,
  value: renewalCol.path,
  style: defaultHeaderStyle,
  hidden: { lgDown: true },
};
const resellerHead: HeaderColumn = {
  label: resellerCol.label,
  value: resellerCol.path,
  style: defaultHeaderStyle,
  hidden: { xlDown: true },
};
const billingProfileHead: HeaderColumn = {
  label: billingProfileCol.label,
  value: billingProfileCol.path,
  style: defaultHeaderStyle,
  hidden: { xlDown: true },
};

export const GSuiteAssetColumns: DataColumn[] = [
  assetTypeCol,
  domainCol,
  licensesCol,
  seatsUsedCol,
  expiryDateCol,
  renewalCol,
  resellerCol,
  billingProfileCol,
];

export const GSuiteAssetHeaders: HeaderColumn[] = [
  assetTypeHead,
  domainHead,
  licensesHead,
  expiryDateHead,
  renewalHead,
  resellerHead,
  billingProfileHead,
  threeDotHeaderSection,
];
