import { useEffect, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { CloudAnalyticsModel, Roles } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, Card, Link } from "@mui/material";
import uniqBy from "lodash/uniqBy";
import type { GridColDef } from "@mui/x-data-grid";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import BudgetUtilizationProgressBar from "../../../Pages/CloudAnalytics/budgets/BudgetUtilizationProgressBar";
import { getUtilizationProgressBarColor } from "../../../Pages/CloudAnalytics/budgets/shared";
import { BudgetTypes } from "../../../Pages/CloudAnalytics/utilities";
import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { getDateTimeFromFirestoreTimestamp } from "../../../utils/common";
import { TimestampNow } from "../../../utils/firebase";
import { WidgetCardContentWithTable } from "../WidgetCards/Common/WidgetCardContentWithTable";
import type { WidgetItemProps } from "../types";

type BudgetRow = {
  id: string;
  name: string;
  utilization: any;
  utilizationValue: number;
  config: any;
  projectedDate: string;
};

export default function BudgetsCard({ widgetHeight = 200 }: WidgetItemProps) {
  const { isDoitEmployee, currentUser } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer, customer: genuineCustomer } = useCustomerContext();
  const [data, setData] = useState<BudgetRow[]>([]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "Budget Name",
        flex: 1,
        renderCell: (params) => (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/customers/${genuineCustomer.id}/analytics/budgets/${params.row.id}`}
          >
            {params.row.name}
          </Link>
        ),
      },
      {
        field: "utilization",
        headerName: "Budget Status",
        flex: 1,
        renderCell: (params: { row: BudgetRow }) => (
          <Box width="100%" px={1}>
            <BudgetUtilizationProgressBar
              value={params.row.utilizationValue}
              color={getUtilizationProgressBarColor(params.row)}
            />
          </Box>
        ),
      },
      { field: "projectedDate", headerName: "Max Utilization", flex: 1, align: "right" },
    ],
    [genuineCustomer.id]
  );

  useEffect(() => {
    const collectionRef = getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets");
    const getQuery = () => {
      if (isDoitEmployee) {
        return collectionRef.where("customer", "==", customer.ref).get();
      }
      return collectionRef
        .where("customer", "==", customer.ref)
        .where("public", "in", [Roles.VIEWER, Roles.EDITOR])
        .get();
    };

    const getShardQuery = () =>
      collectionRef
        .where("customer", "==", customer.ref)
        .where("public", "==", null)
        .where("collaborators", "array-contains-any", [
          { email: currentUser.email, role: Roles.OWNER },
          { email: currentUser.email, role: Roles.VIEWER },
          { email: currentUser.email, role: Roles.EDITOR },
        ])
        .get();

    Promise.all([getQuery(), getShardQuery()]).then((res) => {
      const budgetData = res.flatMap((queryResult) =>
        queryResult.docs.map((doc) => ({ data: doc.asModelData(), id: doc.id }))
      );

      const now = TimestampNow();
      const filteredBudgets = uniqBy(budgetData, "id").filter(
        (b) =>
          b.data.config.type !== BudgetTypes.FIXED ||
          (!!b.data.config?.endPeriod && b.data.config.startPeriod <= now && b.data.config.endPeriod >= now)
      );

      const formattedData = filteredBudgets.map((budget) => ({
        id: budget.id,
        name: budget.data.name,
        config: budget.data.config,
        utilizationValue:
          budget.data.config.amount && budget.data.utilization?.current
            ? (budget.data.utilization.current / budget.data.config.amount) * 100
            : 0,
        utilization: budget.data.utilization,
        projectedDate: budget?.data?.utilization?.forecastedTotalAmountDate
          ? getDateTimeFromFirestoreTimestamp(budget.data.utilization?.forecastedTotalAmountDate)?.toFormat(
              "LLL dd, yyyy"
            )
          : "N/A",
      }));

      setData(formattedData);
    });
  }, [currentUser.email, customer.ref, isDoitEmployee]);

  return (
    <Card>
      <WidgetCardHeader title="Budget Utilization" subheader="List of active budgets and their utilization" />
      <WidgetCardContentWithTable height={widgetHeight} rows={data} columns={columns} />
    </Card>
  );
}
