import { type MouseEvent, type MouseEventHandler } from "react";

import { trackAuthByProvider, trackConsoleAuthSignup } from "../helpers";
import { type LoginType, SupportedAuthProviders } from "../types";
import { AuthProviderButton } from "./AuthProvidersButton";

type props = {
  loading: boolean;
  type?: LoginType;
  customHandler?: MouseEventHandler;
  setClickedAuthProvider?: (provider: SupportedAuthProviders) => void;
  isCompleteSignup?: boolean;
};

const AuthProviders = ({ loading, type = "login", customHandler, setClickedAuthProvider, isCompleteSignup }: props) => {
  const handleClick = (e: MouseEvent, provider: SupportedAuthProviders) => {
    if (type === "signup") {
      trackConsoleAuthSignup(provider);
    }
    trackAuthByProvider(provider);
    setClickedAuthProvider?.(provider);
    customHandler?.(e);
  };

  return (
    <>
      <AuthProviderButton
        key="Google"
        provider={SupportedAuthProviders.Google}
        loginType={type}
        handleClick={handleClick}
        disabled={loading}
        isCompleteSignup={isCompleteSignup}
      />
      <AuthProviderButton
        key="Microsoft"
        provider={SupportedAuthProviders.Microsoft}
        loginType={type}
        handleClick={handleClick}
        disabled={loading}
        isCompleteSignup={isCompleteSignup}
      />
    </>
  );
};

export default AuthProviders;
