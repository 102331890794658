export const assets = {
  googleCloud: {
    modalButton: "google-cloud-asset-modal-button",
    linkButton: "google-cloud-asset-link-button",
  },
};

export const analytics = {
  includeCreditsTooltip: "analytics-include-credits-tooltip",
};
