import {
  Aggregator,
  AnalyticsDataSource,
  AnalyticsResourceType,
  ComparativeFeature,
  CurrencyCodes,
  IDs,
  LimitAggregation,
  Metric,
  Renderer,
  type ReportConfig,
  Sort,
  TimeInterval,
  type TimeSettingsConfig,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";

import { serverTimestamp } from "../../../utils/firebase";

export const emptyReportValues = {
  // for firestore rules, creates empty report
  public: null,
  type: AnalyticsResourceType.CUSTOM,
  name: "",
  description: "",
  timeCreated: serverTimestamp(),
  timeModified: serverTimestamp(),
  draft: true,
  config: null,
  widgetEnabled: false,
  hidden: false,
};

export const defaultTimeSettingsConfig: TimeSettingsConfig = {
  amount: 7,
  mode: TimeSettingsMode.Last,
  unit: TimeInterval.DAY,
  includeCurrent: true,
};

export const defaultConfig: WithFirebaseModel<ReportConfig> = {
  calculatedMetric: null,
  extendedMetric: null,
  logScale: false,
  colOrder: Sort.A_TO_Z, // not configurable
  rowOrder: Sort.ASC, // not configurable
  cols: [TimeInterval.DAY], // based on time intervals not user selected
  aggregator: Aggregator.TOTAL,
  currency: CurrencyCodes.USD, // defaults to customer currency
  timezone: null, // defaults to customer timezone
  metric: Metric.COST,
  renderer: Renderer.STACKED_COLUMN_CHART,
  timeInterval: TimeInterval.DAY,
  customTimeRange: null, // validate if selected
  filters: [],
  metricFilters: [],
  features: [],
  optional: [],
  rows: [],
  count: null,
  excludePartialData: false,
  includeCredits: false,
  comparative: ComparativeFeature.NONE,
  timeSettings: defaultTimeSettingsConfig,
  dataSource: AnalyticsDataSource.BILLING,
  includeSubtotals: false,
  limitAggregation: LimitAggregation.TOP,
};

export const defaultFilter = {
  limit: null,
  regexp: null,
  inverse: false,
  values: [] as string[],
};

export const colsMap = {
  [TimeInterval.HOUR]: [IDs.DatetimeYear, IDs.DatetimeMonth, IDs.DatetimeDay, IDs.DatetimeHour],
  [TimeInterval.DAY]: [IDs.DatetimeYear, IDs.DatetimeMonth, IDs.DatetimeDay],
  [TimeInterval.WEEK]: [IDs.DatetimeYear, IDs.DatetimeWeek],
  [TimeInterval.MONTH]: [IDs.DatetimeYear, IDs.DatetimeMonth],
  [TimeInterval.QUARTER]: [IDs.DatetimeYear, IDs.DatetimeQuarter],
  [TimeInterval.YEAR]: [IDs.DatetimeYear],
};
