import { useCallback } from "react";

import { Link } from "react-router-dom";
import { Box, CircularProgress, Link as MUILink, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import DateType from "../../../utils/dateType";
import { type AccountHealthModel, Status } from "../types";

const useStyles = makeStyles((theme) => ({
  textLoading: {
    marginTop: 4,
    marginRight: 4,
    color: theme.palette.text.secondary,
  },
}));

export const AccountHealth = ({
  accountHealth,
  customerId,
}: {
  accountHealth: AccountHealthModel & {
    lastLoginUserId: string;
  };
  customerId: string;
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const getLastLogin = useCallback(
    () =>
      accountHealth?.lastLogin ? (
        <MUILink
          component={Link}
          color="inherit"
          to={`/customers/${customerId}/iam/users/${accountHealth?.lastLoginUserId}/profile`}
          variant="body2"
        >
          {DateType.getFormattedStringDate(accountHealth?.lastLogin, "dd LLL yyyy")}
        </MUILink>
      ) : (
        <Typography variant="body2">{Status.Na}</Typography>
      ),
    [accountHealth?.lastLogin, accountHealth?.lastLoginUserId, customerId]
  );

  return (
    <Box ml={2} margin={1}>
      <Typography variant="h6" component="div">
        Account Health
      </Typography>
      <Box mt={1} style={{ maxWidth: 250 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Active users
        </Typography>
        <MUILink component={Link} to={`/customers/${customerId}/iam/users`} color="inherit" variant="body2">
          {accountHealth?.activeUsers ?? 0}
        </MUILink>
      </Box>
      <Box style={{ maxWidth: 250 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Last login
        </Typography>
        {getLastLogin()}
      </Box>
      <Box style={{ maxWidth: 250 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          AWS access
        </Typography>
        <MUILink component={Link} to={`/customers/${customerId}/settings/aws`} color="inherit" variant="body2">
          {accountHealth?.awsAccess?.status ?? Status.Na}
        </MUILink>
      </Box>
      <Box style={{ maxWidth: 250 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          GCP access
        </Typography>
        <Tooltip
          disableHoverListener={accountHealth?.gcpAccess?.status === Status.Na}
          placement="right"
          title={
            accountHealth?.gcpAccess?.permissions.map((p, k) => (
              <Typography component="div" variant="caption" key={k}>
                {p.replace(/-/g, " ").replace(/_/g, " ")}
              </Typography>
            )) ?? ""
          }
        >
          <MUILink component={Link} to={`/customers/${customerId}/settings/gcp`} color="inherit" variant="body2">
            {accountHealth?.gcpAccess?.status ?? Status.Na}
          </MUILink>
        </Tooltip>
      </Box>
      <Box style={{ maxWidth: 250 }} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Active Tickets
        </Typography>
        {accountHealth.activeTickets !== null ? (
          <MUILink component={Link} to={`/customers/${customerId}/support`} color="inherit" variant="body2">
            {accountHealth.activeTickets}
          </MUILink>
        ) : (
          <CircularProgress className={classes.textLoading} size={12} />
        )}
      </Box>
    </Box>
  );
};
