import { type FC, useMemo } from "react";

import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, TextField, Tooltip } from "@mui/material";

export const PlaceholderParam: FC<{
  label: string;
  required: boolean;
  placeholderTooltip?: string;
}> = ({ label, required = false, placeholderTooltip }) => {
  const content = useMemo(
    () => (
      <Stack
        sx={() => ({
          display: "flex",
          flexFlow: "row nowrap",
          flex: 1,
          ".MuiFormControl-root:not(:last-child) .MuiOutlinedInput-root": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        })}
      >
        <TextField type="text" label={label} fullWidth disabled required={required} />
        <Button
          variant="contained"
          color="inherit"
          sx={({ palette }) => ({
            minWidth: "unset",
            width: 40,
            height: 40,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderStyle: "solid",
            borderWidth: "1px 1px 1px 0",
            borderColor: palette.general.outlineBorder,
            "&.Mui-disabled": {
              backgroundColor: "transparent",
            },
          })}
          disableElevation
          disabled
        >
          <AddIcon sx={({ palette }) => ({ fill: palette.grey[400] })} />
        </Button>
      </Stack>
    ),
    [label, required]
  );

  return placeholderTooltip ? (
    <Tooltip title={placeholderTooltip} placement="top-start">
      {content}
    </Tooltip>
  ) : (
    content
  );
};
