import { useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useGetSigninIconColors } from "../hooks";

type props = {
  handleEmailLinkLogin: () => void;
  disabled?: boolean;
};

export function EmailLinkLoginButton({ handleEmailLinkLogin, disabled }: props) {
  const theme = useTheme();
  const iconColor = useGetSigninIconColors();
  const { t } = useTranslation("login");
  return (
    <Button
      color="primary"
      data-cy="use-login-email-link-button"
      variant="outlined"
      fullWidth
      sx={{
        color: theme.palette.text.primary,
        borderColor: theme.palette.grey.A400,
        textTransform: "none",
        height: 41,
      }}
      startIcon={<EmailIcon style={{ fontSize: 28, color: iconColor }} />}
      disabled={disabled}
      onClick={handleEmailLinkLogin}
    >
      {t("magic.link.signin")}
    </Button>
  );
}
