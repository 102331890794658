import { createContext, type ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { slimInitFirestore } from "../utils/initFirestore";
import { usePrevious } from "../utils/usePrevious";
import { useAuthContext } from "./AuthContext";

type FirestoreContextType = {
  firestoreInitialized: boolean;
};

const firestoreContext = createContext<FirestoreContextType>({ firestoreInitialized: false });

export const useFirestoreContext = () => useContext(firestoreContext);

export const FirestoreContextProvider = ({ children }: { children?: ReactNode }) => {
  const { currentUser } = useAuthContext();
  const prevCurrentUser = usePrevious(currentUser);
  const [firestoreInitialized, setFirestoreInitialized] = useState(false);

  useEffect(() => {
    if (currentUser === prevCurrentUser) {
      return;
    }

    if (currentUser) {
      currentUser.getIdTokenResult(false).then((idTokenResult) => {
        slimInitFirestore({ idToken: idTokenResult });
        setFirestoreInitialized(true);
      });
    } else {
      setFirestoreInitialized(false);
    }
  }, [currentUser, prevCurrentUser]);

  return <firestoreContext.Provider value={{ firestoreInitialized }}>{children}</firestoreContext.Provider>;
};

export const FirestoreGuard = ({ children }: { children: ReactNode }) => {
  const { firestoreInitialized } = useFirestoreContext();
  const memoValue = useMemo(() => ({ firestoreInitialized }), [firestoreInitialized]);

  if (!firestoreInitialized) {
    return null;
  }

  return <firestoreContext.Provider value={memoValue}>{children}</firestoreContext.Provider>;
};
