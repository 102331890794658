import { type JSX } from "react";

import { Divider, Stack } from "@mui/material";

import { useFullScreen } from "../../../utils/dialog";
import { MenuSection } from "./MenuSection";
import { MenuSectionsMobile } from "./MenuSectionsMobile";

export type Item = {
  name: string;
  selected?: boolean;
  onClick: () => void;
};

export type SectionItem = {
  name: string;
  items: Item[];
  emptyItemsLabel?: string;
  sideIcon?: JSX.Element;
  sideIconOnClick?: () => void;
  sideIconDisabled?: boolean;
};

type Props = {
  sections: SectionItem[];
};

export function SideNavigationMenu({ sections }: Props) {
  const { isMobile } = useFullScreen();

  if (isMobile) {
    return <MenuSectionsMobile sections={sections} />;
  }

  return (
    <Stack divider={<Divider />} data-cy="side-navigation-sections">
      {sections.map((section) => (
        <MenuSection key={section.name} section={section} />
      ))}
    </Stack>
  );
}
