import { Typography } from "@mui/material";

type props = {
  title: string;
};

export const ContentHeader = ({ title }: props) => (
  <Typography sx={{ mb: 2, mt: 3 }} variant="h4" color="text.primary">
    {title}
  </Typography>
);
