import { doitDomain, doitPreviousDomain } from "./domains";

export const cleanEmail = (email: string) => email.split(":").pop();

// isDoitEmployeeEmail returns true if the email is a doit employee email.
// If includeAliases is true, the function will return true for aliases of doit employee emails.
export const isDoitEmployeeEmail = (email: string, includeAliases = false) => {
  const [handle, domain] = email.trim().toLowerCase().split("@");

  if (!handle || !domain) {
    return false;
  }

  if (domain !== doitDomain && domain !== doitPreviousDomain) {
    return false;
  }

  return includeAliases || !handle.includes("+");
};

export const isTestEmail = (email: string) => {
  const testEmails = ["e2e@doit.com", "e2e1@doit.com", "e2e2@doit.com"];
  return testEmails.includes(email.trim().toLowerCase());
};
