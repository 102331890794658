import * as FullStory from "@fullstory/browser";
import { fullStoryIntegration } from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";

import { firebaseProjectId } from "../constants";

export const browserHistory = createBrowserHistory();

export function sentryInit() {
  if (!process.env.REACT_APP_SENTRY_RELEASE) {
    return;
  }

  Sentry.init({
    dsn: "https://88f9b0da19e24cbb9c76ad2f5238f522@o926763.ingest.sentry.io/5876569",
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/(?!api\.aws-ops\.doit-intl\.com).*\.com\/api/,
      /^https:\/\/(?!api\.aws-ops\.doit-intl\.com).*\.com\/auth/,
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      fullStoryIntegration("doit-intl", { client: FullStory }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.reactRouterV5BrowserTracingIntegration({ history: browserHistory }),
      Sentry.browserProfilingIntegration(),
    ],
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "0.1"),
    profilesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || "0.1"),
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: firebaseProjectId,
    attachStacktrace: true,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || "0.1"),
    beforeSendSpan: (span) => {
      // Modify or drop the span here
      if (span.data?.["http.url"]?.startsWith("https://firestore.googleapis.com/google.firestore.v1.Firestore/")) {
        // Don't send the span to Sentry
        return null;
      }
      return span;
    },
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    beforeSend: (event, _hint) => {
      // filter out UnhandledRejection errors that have no information
      if (event.exception?.values?.length === 1) {
        const [firstException] = event.exception.values;
        if (
          firstException.type === "UnhandledRejection" &&
          firstException.value?.indexOf(
            "Non-Error promise rejection captured with value: Object Not Found Matching Id:"
          ) !== -1
        ) {
          return null;
        }
      }

      return event;
    },
  });
}
