import { useMemo } from "react";

import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Alert, Button } from "@mui/material";

import { alertTexts } from "../../../../../assets/texts";
import { updateAccountAllErrors, type UpdateAccountError } from "../errors";

type UpdateErrorAlertProps = {
  error: string;
  onClose: () => void;
};

/**
 * Alert that will show display error by the error code from server
 * @param error - error code
 * @param onClose - close callback
 * @constructor
 */
export const UpdateErrorAlert = ({ error, onClose }: UpdateErrorAlertProps) => {
  const errorDetails: UpdateAccountError = useMemo(() => {
    if (error in updateAccountAllErrors) {
      return updateAccountAllErrors[error];
    } else {
      return {
        message: "Unexpected error.",
      };
    }
  }, [error]);

  return (
    <Alert onClose={onClose} severity="error">
      {errorDetails.message}{" "}
      {!!errorDetails.learnMoreUrl && (
        <Button
          key="info"
          color="inherit"
          size="small"
          href={errorDetails.learnMoreUrl}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Learn more"
          startIcon={<OpenNewIcon />}
        >
          {alertTexts.LEARN_MORE}
        </Button>
      )}
    </Alert>
  );
};
