export const errorsText = {
  OUTSIDE_ORGANIZATION: {
    DESCRIPTION: "The report you are trying to run is outside of your organization (%s)",
    LIST: ["contact your admin to gain access"],
  },
  EMPTY: {
    DESCRIPTION:
      "Hmm, this report configuration did not return any results. <br />Try changing some of the filters/groupings or adjust the time range.",
  },
  SERIES_TOO_LARGE: {
    DESCRIPTION:
      "Uh, oh... This report created too many series and cannot be displayed properly.<br /><br />You can try these suggestions:",
    LIST: [
      "Add a filter to reduce the amount of data in your report",
      "Use a metric filter",
      'Use less fields within the "Group by" section',
    ],
  },
  TOO_LARGE: {
    DESCRIPTION:
      "Uh, oh.. This report exceeds data point limit for browser display.<br /><br />To resolve, consider the following actions:",
    LIST: ["Apply filters to dimensions", "Select fewer dimensions", "Choose a more limited time period"],
    LINK: {
      DESC: "Retrieve report via ",
      TITLE: "Reports API",
    },
  },
  QUERY_TIMEOUT: {
    DESCRIPTION:
      "Well, looks like this report is taking too long to compute.<br />Please try again later or contact support.",
  },
  EMPTY_DUE_TO_TREND: {
    DESCRIPTION:
      "This report doesn't have any trending dimensions.<br />Try removing Trending Up/Down checkbox, please.",
  },
  QUERY_TIMEOUT_524: {
    DESCRIPTION:
      "The report is too large to run in real time, if you would like to cache the report and return later click the button below",
  },
  EXCEEDED_FIRESTORE_LIMIT: {
    DESCRIPTION:
      "The report is too large to cache, please try removing some dimensions or filters to reduce the size of the report",
  },
  DEFAULT: {
    DESCRIPTION:
      "Err.. we could not build your report due to a problem with our infrastructure.<br />Our technical staff have been notified and will be looking into this with the utmost urgency.<br /><br />We are sorry for the inconvenience!",
  },
  CACHE_REPORT_BTN: "Cache report",
  NOT_EDITOR_TOOLTIP: "Please contact the report owner to be given permission to cache this report",
  NOT_SAVED_TOOLTIP: "Please save the report before caching",
  ENTITLEMENT_NOT_ENABLED: {
    DESCRIPTION: "You do not have access to the entitlement to run this report",
  },
  LEARN_MORE: "Learn more",
  NO_ACCESS_METRIC: "You no longer have access to this metric, change the metric to see report results",
};
