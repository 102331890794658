import { useCallback } from "react";

import {
  type DiscountDetails,
  type FlexsavePayerConfigs,
  type FlexsavePayerType,
  type ManagedType,
  type Status,
} from "@doitintl/cmp-models";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type AxiosError } from "axios/index";

import { useApiContext } from "../../../../api/context";
import { queryKeys } from "../../../../constants";
import { type PayerConfigDTO } from "./AWSPayerConfigForm/types";

export type UpdateFlexsavePayerConfig = {
  customerId: string;
  accountId: string;
  type: FlexsavePayerType;
  managed?: ManagedType;
  discountDetails?: DiscountDetails[];
  lastUpdated: Date;
  status: Status;
  timeEnabled?: Date;
  timeDisabled?: Date;
  sagemakerStatus: Status | "";
  rdsStatus: Status | "";
  maxSpend?: number;
  minSpend?: number;
  targetPercentage?: number;
  primaryDomain?: string;
  name?: string;
  friendlyName?: string;
};

export const useUpdatePayer = () => {
  const api = useApiContext();
  const queryClient = useQueryClient();

  return useCallback(
    async (payerId: string, data: PayerConfigDTO) => {
      await api.request({
        method: "put",
        url: `/v1/flexsave/payers/${payerId}/ops-update`,
        data,
      });
      await queryClient.refetchQueries({ queryKey: [queryKeys.payerConfigs] });
    },
    [api, queryClient]
  );
};

export const useFlexsavePayerConfigs = () => {
  const api = useApiContext();

  return useQuery<FlexsavePayerConfigs[], AxiosError>({
    queryKey: [queryKeys.payerConfigs],
    queryFn: async () => {
      const { data: apiResponse } = await api.request<FlexsavePayerConfigs[]>({
        method: "get",
        url: `/v1/flexapi/payers/all`,
      });

      return apiResponse;
    },
  });
};
