import { useCallback } from "react";

import { type CustomerModelOrganizationModel, Roles } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { createReport } from "../../Pages/CloudAnalytics/generateReport/db";
import { createRowsFilters, handleCustomTimeRange } from "../../Pages/CloudAnalytics/generateReport/generateReport";
import { type createReportParams, type createReportPayload } from "../../Pages/CloudAnalytics/generateReport/types";
import { colsMap, defaultConfig } from "../../Pages/CloudAnalytics/generateReport/utils";
import { type CustomerRef } from "../../types";

export const useGenerateReportWithCustomer = (
  customerRef: CustomerRef | null,
  userOrganizationRef: ModelReference<CustomerModelOrganizationModel> | null
) => {
  const { currentUser } = useAuthContext();
  /*
   two scenarios:
       1. we provide the payload to create report in full, then we do not need to process, this case is payloadReady = true.
       2. we provide the payload to create report in part, then we need to process, this case is payloadReady = false.
  */
  return useCallback(
    (requestReport: createReportParams | createReportPayload, payloadReady?: boolean) => {
      if (!customerRef) {
        return "";
      }

      const isPayloadReady = (
        paramRequestReport: createReportParams | createReportPayload
      ): paramRequestReport is createReportPayload => !!payloadReady;
      const email = currentUser?.email ? currentUser.email : "test@test.com";
      if (!isPayloadReady(requestReport)) {
        const { fields, ...config } = requestReport.config;
        const { rows, filters, optional } = createRowsFilters(fields);
        if (config.customTimeRange) {
          config.customTimeRange = handleCustomTimeRange(config.customTimeRange);
        }
        const fullRequestConfig = { ...config, rows, filters };
        const fullConfig = {
          ...defaultConfig,
          ...fullRequestConfig,
          optional,
        };
        fullConfig.cols = colsMap[fullConfig.timeInterval];
        const reportPayloadFromParams = {
          ...requestReport,
          customer: customerRef,
          config: fullConfig,
          collaborators: [{ email, role: Roles.OWNER }],
          organization: userOrganizationRef ?? null,
        };

        return createReport(reportPayloadFromParams);
      }

      const payload = {
        ...requestReport,
        customer: customerRef,
        organization: userOrganizationRef ?? null,
        collaborators: [{ email, role: Roles.OWNER }],
      };

      return createReport(payload);
    },
    [customerRef, currentUser?.email, userOrganizationRef]
  );
};

const useGenerateReport = () => {
  const { userOrganization, customer } = useCustomerContext();
  const ref = userOrganization?.snapshot.ref;

  return useGenerateReportWithCustomer(customer.ref, ref ? ref : null);
};

export default useGenerateReport;
