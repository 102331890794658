import { useCallback, useEffect, useState } from "react";

import { type ModelReference, type QueryModel, type WithFirebaseModel } from "@doitintl/models-firestore";
import { type DocumentData, type OrderByDirection } from "firebase/firestore";

export type Item<T extends DocumentData> = {
  data: WithFirebaseModel<T>;
  ref: ModelReference<T>;
};

export const usePaginateQuery = <TModel extends DocumentData>(
  query: QueryModel<TModel>,
  {
    defaultOrderBy,
    defaultRowsPerPage,
  }: { defaultOrderBy: string & keyof TModel; defaultFilter?: any; defaultRowsPerPage?: number }
) => {
  const [orderByField, setOrderByField] = useState(defaultOrderBy);
  const [orderDirection, setOrderDirection] = useState<OrderByDirection>("desc");
  const [hasStartPrev, setHasStartPrev] = useState<{ hasPrev?: boolean; hasNext?: boolean }>({});
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage ?? 10);
  const [items, setItems] = useState<Item<TModel>[] | null>();

  const [startAtOrEndAt, setStartAtOrEndAt] = useState<{
    values?: any[];
    direction?: "prev" | "next";
  }>({});

  const initPagination = () => {
    setStartAtOrEndAt({});
    setHasStartPrev({});
  };

  useEffect(() => {
    let q = query.orderBy(orderByField, orderDirection);

    if (!startAtOrEndAt.direction || startAtOrEndAt.direction === "next") {
      q = q.limit(rowsPerPage + 1);
      if (startAtOrEndAt.values) {
        q = q.startAfter(...startAtOrEndAt.values);
      }
    } else if (startAtOrEndAt.direction === "prev") {
      q = q.limitToLast(rowsPerPage + 1);
      if (startAtOrEndAt.values) {
        q = q.endBefore(...startAtOrEndAt.values);
      }
    }

    q.get().then((querySnapshot) => {
      const newDebtItems = querySnapshot.docs.map(
        (docSnap): Item<TModel> => ({
          data: docSnap.asModelData(),
          ref: docSnap.modelRef,
        })
      );

      setHasStartPrev((prevHasStartPrev) => {
        const newHasStartPrev = { ...prevHasStartPrev };

        if (!startAtOrEndAt.direction || startAtOrEndAt.direction === "next") {
          newHasStartPrev.hasNext = newDebtItems.length > rowsPerPage;
          newHasStartPrev.hasPrev = !!startAtOrEndAt.direction;
          setItems(newDebtItems.slice(0, rowsPerPage));
        } else {
          newHasStartPrev.hasPrev = newDebtItems.length > rowsPerPage;
          newHasStartPrev.hasNext = true;

          const sliceIndex = newDebtItems.length === rowsPerPage ? 0 : 1;
          setItems(newDebtItems.slice(sliceIndex, rowsPerPage));
        }

        return newHasStartPrev;
      });
    });
  }, [orderByField, orderDirection, query, rowsPerPage, startAtOrEndAt]);

  const setOrderByFieldAndDirection = useCallback(
    (newOrderByField: string & keyof TModel, direction: "asc" | "desc") => {
      initPagination();
      setOrderDirection(direction);
      setOrderByField(newOrderByField);
    },
    []
  );

  const createSortHandler = useCallback(
    (property: string & keyof TModel) => () => {
      const isAsc = orderByField === property && orderDirection === "asc";
      setOrderByFieldAndDirection(property, isAsc ? "desc" : "asc");
    },
    [orderByField, orderDirection, setOrderByFieldAndDirection]
  );

  const handlePrevPage = () => {
    setStartAtOrEndAt((prevStartAtOrEndAt) => {
      if (!items) {
        return prevStartAtOrEndAt;
      }

      const nextPrevStartAtOrEndAt = { ...prevStartAtOrEndAt };

      nextPrevStartAtOrEndAt.direction = "prev";
      const item = items[0];
      nextPrevStartAtOrEndAt.values = [(item.data as TModel)[orderByField]];
      return nextPrevStartAtOrEndAt;
    });
  };

  const handleNextPage = () => {
    setStartAtOrEndAt((prevStartAtOrEndAt) => {
      if (!items) {
        return prevStartAtOrEndAt;
      }

      const nextPrevStartAtOrEndAt = { ...prevStartAtOrEndAt };

      nextPrevStartAtOrEndAt.direction = "next";
      const item = items[items.length - 1];
      nextPrevStartAtOrEndAt.values = [(item.data as TModel)[orderByField]];
      return nextPrevStartAtOrEndAt;
    });
  };

  const setRowsPerPageHandler = useCallback((newRowsPerPage: number) => {
    initPagination();
    setRowsPerPage(newRowsPerPage);
  }, []);

  return {
    handlePrevPage,
    handleNextPage,
    createSortHandler,
    items,
    orderBy: orderByField,
    orderDirection,
    rowsPerPage,
    setRowsPerPage: setRowsPerPageHandler,
    hasNextPage: hasStartPrev.hasNext,
    hasPrevPage: hasStartPrev.hasPrev,
    setOrderByFieldAndDirection,
  };
};
