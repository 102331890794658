import { useCallback, useEffect, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { CustomerModel, PermissionModel, type UserPermission } from "@doitintl/cmp-models";
import { getCollection, type ModelReference, type QueryDocumentSnapshotModel } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { CopyToClipboardButton } from "../../Components/CopyToClipboardButton";
import EditableCardTitle from "../../Components/EditableCardTitle";
import { Loader } from "../../Components/Loader";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../Components/SimpleDialog";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { type Role, RoleCustomerType } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { permissions } from "../../utils/common";
import { serverTimestamp } from "../../utils/firebase";
import { PRESET, UNTITLED_ROLE } from "./consts";

const useStyles = makeStyles({
  cardHeaderAction: {
    margin: "auto",
  },
});

type Props = {
  roleId: string;
  roles: Role[];
};

export default function RoleView({ roleId, roles }: Props) {
  const [permissionsDocs, setPermissionsDocs] = useState<QueryDocumentSnapshotModel<PermissionModel>[]>([]);
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext();
  const { onOpen: openSnackbar, onClose: closeSnackbar } = useSnackbar();
  const [openMakeDefaultRoleDialog, setMakeDefaultRoleDialog] = useState(false);
  const [isCurrentDefaultRole, setIsCurrentDefaultRole] = useState(false);
  const classes = useStyles();

  const setMakeDefaultRoleDialogTrue = () => {
    setMakeDefaultRoleDialog(true);
  };

  const setMakeDefaultRoleDialogFalse = () => {
    setMakeDefaultRoleDialog(false);
  };

  const setIsCurrentDefaultRoleTrue = () => {
    setIsCurrentDefaultRole(true);
  };

  const role = useMemo(() => roles.find((r) => r.id === roleId), [roles, roleId]);

  const rolePermissionsRefs = useMemo(
    () =>
      (
        role?.permissions?.map(
          (rolePermission) =>
            permissionsDocs.find((permissionDoc) => permissionDoc.ref.id === rolePermission.id)?.modelRef
        ) ?? []
      ).filter((rolePermissionRef): rolePermissionRef is ModelReference<PermissionModel> => !!rolePermissionRef),
    [permissionsDocs, role]
  );

  const getPermissions = useCallback(async () => {
    const perms = await getCollection(PermissionModel).orderBy("title").get();
    setPermissionsDocs(perms.docs);
  }, []);

  const checkCurrentDefaultRole = useCallback(() => {
    if (typeof customer.defaultRole !== "undefined" && customer.defaultRole.id === role?.id) {
      setIsCurrentDefaultRoleTrue();
    }
  }, [customer, role]);

  useEffect(() => {
    getPermissions();
    checkCurrentDefaultRole();
  }, [getPermissions, checkCurrentDefaultRole]);

  async function changePermissions(checked, changedPermissionsDocs) {
    let permissionsRefsToSubmit;
    if (checked) {
      const newPermissionsDocs = changedPermissionsDocs.filter(
        (changedPermission) => !rolePermissionsRefs.includes(changedPermission.ref)
      );
      const newPermissionsRefs = newPermissionsDocs.map((newPermissionDoc) => newPermissionDoc.ref);
      permissionsRefsToSubmit = rolePermissionsRefs.concat(newPermissionsRefs);
    } else {
      permissionsRefsToSubmit = rolePermissionsRefs.filter((rolePermissionRef) =>
        changedPermissionsDocs.every((changedPermissionDoc) => changedPermissionDoc.id !== rolePermissionRef.id)
      );
    }

    await role?.ref.update({
      permissions: permissionsRefsToSubmit,
      timeModified: serverTimestamp(),
    });
    openSnackbar({
      message: "The role has been modified",
      variant: "success",
      autoHideDuration: 3000,
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
  }

  const isChecked = (permissionId) =>
    Boolean(
      role?.permissions?.some(
        (rolePermission) =>
          permissions.find((permission) => permission.value === rolePermission.id)?.value === permissionId
      )
    );

  const isPermissionVisible = (permissionId) =>
    Boolean(
      permissions.find((permission) => permission.value === permissionId)?.visible &&
        !(role?.customerType === RoleCustomerType.STANDALONE && !isChecked(permissionId))
    );

  const isPermissionInvalid = (permission: QueryDocumentSnapshotModel<PermissionModel>) =>
    role?.type === PRESET || (!userRoles.permissions.has(permission.id as UserPermission) && !isDoitEmployee);

  const handleSetDefaultRole = async () => {
    try {
      if (!role) {
        return;
      }
      await getCollection(CustomerModel).doc(customer.id).update({
        defaultRole: role.ref,
      });

      openSnackbar({
        message: "The default role has been updated",
        variant: "success",
        autoHideDuration: 3000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
    setMakeDefaultRoleDialogFalse();
  };

  return (
    <Loader loading={!role}>
      <Card>
        <CardHeader
          avatar={
            <IconButton aria-label="Back" component={Link} to={`/customers/${customer.id}/iam/roles`} size="large">
              <BackIcon color="primary" />
            </IconButton>
          }
          title={
            <EditableCardTitle
              onUpdate={async (key, value) => {
                await role?.ref.update({
                  [key]: value,
                  timeModified: serverTimestamp(),
                });
              }}
              includesDefaultValue
              defaultValue={UNTITLED_ROLE}
              name={role?.name ?? ""}
              description={role?.description}
              disabled={role?.type === PRESET}
              invalidValuesList={roles.map((r) => r.name)}
              errorMessage="Role name is already taken"
            />
          }
          subheader={customer.name}
          classes={{ action: classes.cardHeaderAction }}
          action={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Copy Role ID">
                <Box sx={{ pr: 2 }}>
                  <CopyToClipboardButton text={roleId} />
                </Box>
              </Tooltip>
              <Box>
                <Tooltip
                  title={
                    isCurrentDefaultRole
                      ? "This already is your organization's default role"
                      : "Make this the default role for your organization"
                  }
                >
                  <span>
                    <Button
                      data-cy="makeDefaultButton"
                      variant="contained"
                      color="primary"
                      startIcon={<PersonAddIcon />}
                      onClick={setMakeDefaultRoleDialogTrue}
                      disabled={isCurrentDefaultRole}
                    >
                      MAKE DEFAULT
                    </Button>
                  </span>
                </Tooltip>
                {openMakeDefaultRoleDialog && (
                  <SimpleDialog
                    open={true}
                    title="Set default role"
                    onConfirm={handleSetDefaultRole}
                    onCancel={setMakeDefaultRoleDialogFalse}
                    confirmButtonCy="confirmMakeDefaultButton"
                  >
                    <DialogContent>
                      <DialogContentText>
                        <Typography component="span">
                          {`You're going to make ${role?.name} the default role for ${customer.primaryDomain}. All new users created from ${customer.primaryDomain} will receive the permissions listed in ${role?.name}.`}
                        </Typography>
                      </DialogContentText>
                    </DialogContent>
                  </SimpleDialog>
                )}
              </Box>
            </Box>
          }
        />

        <Grid item sm={12} xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <Tooltip title={role?.type === PRESET ? `Preset role permissions cannot be edited` : ""}>
                  <TableCell align="left" padding="checkbox">
                    <Checkbox
                      disabled={role?.type === PRESET}
                      onChange={(event) =>
                        changePermissions(
                          event.target.checked,
                          permissionsDocs.filter(
                            (permission) => !isPermissionInvalid(permission) && isPermissionVisible(permission.id)
                          )
                        )
                      }
                      checked={permissionsDocs
                        .filter((permission) => !isPermissionInvalid(permission) && isPermissionVisible(permission.id))
                        .every((permission) => isChecked(permission.id))}
                    />
                  </TableCell>
                </Tooltip>
                <TableCell align="left" padding="normal">
                  Permission
                </TableCell>
                <TableCell align="left" padding="normal">
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissionsDocs
                .filter((permission) => isPermissionVisible(permission.id))
                .map((permission) => (
                  <TableRow key={permission.id}>
                    <Tooltip title={role?.type === PRESET ? `Preset role permissions cannot be edited` : ""}>
                      <TableCell align="left" padding="checkbox">
                        <Checkbox
                          disabled={isPermissionInvalid(permission)}
                          onChange={(event) => changePermissions(event.target.checked, [permission])}
                          checked={isChecked(permission.id)}
                        />
                      </TableCell>
                    </Tooltip>
                    <TableCell align="left" padding="normal">
                      {permission.data().title}
                    </TableCell>
                    <TableCell align="left" padding="normal">
                      {permission.data().desc}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Card>
    </Loader>
  );
}
