export const parseData = (data: string): Record<string, string>[] => {
  const lines = data.split("\n");
  const linesWithColumns = lines.map((item) =>
    item.split(",").map((item) => item.replaceAll('"', "").replaceAll("\\", ""))
  );

  const parsed: Record<string, string>[] = [];

  for (let i = 0; i < linesWithColumns.length; i++) {
    if (i === 0) {
      continue;
    }

    const item = {};

    for (let j = 0; j < linesWithColumns[i].length; j++) {
      const transformedKey = linesWithColumns[0][j].toLowerCase().replaceAll(" ", "_").replaceAll("-", "_");
      item[transformedKey] = linesWithColumns[i][j];
    }

    parsed.push(item);
  }

  return parsed;
};

export const validateEstimationsData = (data: Record<string, string>[]): boolean => {
  if (data.length !== 1) {
    return false;
  }

  const expectedKeys = [
    "offering_id",
    "hourly_commitment_to_purchase",
    "estimated_on_demand_cost",
    "current_average_hourly_on_demand_spend",
    "current_minimum_hourly_on_demand_spend",
    "current_maximum_hourly_on_demand_spend",
    "estimated_average_utilization",
    "estimated_monthly_savings_amount",
    "estimated_savings_percentage",
    "estimated_roi",
  ];

  const dataKeys = Object.keys(data[0]);

  for (const key of expectedKeys) {
    if (!dataKeys.includes(key)) {
      return false;
    }
  }
  return true;
};
