import { useState } from "react";

import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../../../api/context";
import { reportTxt, templateLibraryTxt } from "../../../../../assets/texts/CloudAnalytics";
import useReportSaver from "../../../../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import LoadingButton from "../../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../../utils";
import { useCreateTemplateContext, useReportSaverContext, useReportTemplateContext } from "../../../Context";
import { approveReportTemplate, updateReportTemplate } from "../../../templateLibrary/api";
import { type TemplateWithLastVersionFromBackend } from "../../../templateLibrary/types";
import { getErrorMessage } from "../../utils";

type Props = {
  disabled: boolean;
  saveOnly: boolean;
  templateHasChanges: boolean;
};

const SaveAndApprove = ({ disabled, saveOnly, templateHasChanges }: Props) => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { prepareConfigFromDimension } = useReportSaver();
  const { reportTemplateId, dispatchReportTemplates } = useReportTemplateContext();
  const { createTemplateData } = useCreateTemplateContext();
  const { dispatchReportSaver } = useReportSaverContext();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);
      let response: AxiosResponse<TemplateWithLastVersionFromBackend>;

      if (templateHasChanges) {
        response = await updateReportTemplate(api, reportTemplateId, {
          name: createTemplateData.name,
          description: createTemplateData.description,
          visibility: createTemplateData.visibility,
          categories: createTemplateData.categories.flatMap((c) => (c.selected ? c.label : [])),
          cloud: createTemplateData.clouds.flatMap((c) => (c.selected ? c.key : [])),
          config: prepareConfigFromDimension(),
        });
      } else {
        response = await approveReportTemplate(api, reportTemplateId);
      }

      dispatchReportTemplates({ type: "UPDATE_REPORT_TEMPLATE", payload: { reportTemplateItem: response.data } });
      dispatchReportSaver({ payload: { hasUnsavedChanges: false } });
      successSnackbar(
        saveOnly ? templateLibraryTxt.TEMPLATE_SAVED_SUCCESSFULLY : templateLibraryTxt.TEMPLATE_APPROVED_SUCCESSFULLY
      );
    } catch (error) {
      const errMsg = getErrorMessage(error);
      const errPrefix = saveOnly ? templateLibraryTxt.TEMPLATE_SAVE_ERROR : templateLibraryTxt.TEMPLATE_APPROVE_ERROR;
      errorSnackbar(errMsg === "" ? errPrefix : `${errPrefix}: ${errMsg}`);
      consoleErrorWithSentry(error);
    }
    setLoading(false);
  };

  return (
    <LoadingButton
      data-cy="save-and-approve-template"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      variant="contained"
      mixpanelEventId="analytics.template.save"
    >
      {saveOnly ? reportTxt.SAVE_CHANGES : reportTxt.APPROVE_AND_PUBLISH}
    </LoadingButton>
  );
};

export default SaveAndApprove;
