import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";

import { exportCSVFile } from "../../../utils/csv";
import mixpanel from "../../../utils/mixpanel";

const ITEM_HEIGHT = 48;

export default function ThreeDotsMenu({ headers, rows, fileName }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadCsv = () => {
    exportCSVFile(headers, rows, fileName);
    mixpanel.track("flexsave-aws.orders.download");
  };

  const downloadOrders = () => {
    downloadCsv();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            downloadOrders();
          }}
        >
          Download Orders
        </MenuItem>
      </Menu>
    </div>
  );
}
