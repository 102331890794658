import { type ReactNode } from "react";

import { useAuthContext } from "../../Context/AuthContext";
import { Guard } from "./Guard";

type DoitEmployeeGuardProps = {
  children: ReactNode;
};

export const DoitEmployeeGuard = ({ children }: DoitEmployeeGuardProps) => {
  const { isDoitEmployee } = useAuthContext({
    mustHaveUser: true,
  });

  return <Guard isAllowed={isDoitEmployee}>{children}</Guard>;
};
