import { type PropsWithChildren, useEffect } from "react";

import { useFormikContext } from "formik";

import { consoleErrorWithSentry } from "../../../utils";

export type PartialSubmitFormProps = PropsWithChildren<{
  onPartialSubmit: <FormModel>(partialValues: Partial<FormModel>) => Promise<boolean>;
}>;

export function PartialSubmitForm<FormModel extends Record<string, unknown>>({
  children,
  onPartialSubmit,
}: PartialSubmitFormProps) {
  const { initialValues, touched, values, validateForm } = useFormikContext<FormModel>();

  useEffect(() => {
    const changedFields = Object.entries(touched)
      .filter(([fieldName, touched]) => touched && initialValues[fieldName] !== values[fieldName])
      .map<keyof FormModel>(([fieldName]) => fieldName);

    (async () => {
      const errors = await validateForm();
      const fieldsToSubmit = changedFields.filter((fieldName) => !errors[fieldName]);
      if (fieldsToSubmit.length === 0) {
        return;
      }

      const partialUpdate = Object.fromEntries(
        fieldsToSubmit.map((fieldName) => [fieldName, values[fieldName]])
      ) as Partial<FormModel>;

      try {
        const success = await onPartialSubmit(partialUpdate);
        if (success) {
          Object.assign(initialValues, partialUpdate);
        }
      } catch (exception) {
        consoleErrorWithSentry(exception);
      }
    })();
  }, [initialValues, onPartialSubmit, touched, validateForm, values]);

  return <>{children}</>;
}
