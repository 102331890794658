import { KeyboardCommandKey } from "@mui/icons-material";
import { Box, Divider, Hidden, Typography } from "@mui/material";

import { SideDrawer } from "../../../../Components/SideDrawer";
import { visuallyHiddenStyle } from "../../../../constants/common";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useKeyboardShortcutsOpen } from "../../../../Context/keyboardShortcutsOpenProvider";
import ShortcutKeySet from "./ShortcutKeySet";
import ShortcutsTable, { shortcutsTableId } from "./ShortcutsTable";

// Accessibility note: icon keys must have a text description in their aria-label attribute.
const commandKey = <KeyboardCommandKey aria-label="Command" fontSize="small" sx={{ pb: 0.5 }} key={0} />;
const shiftKey = (
  <Typography key={0} sx={{ px: 1 }}>
    Shift
  </Typography>
);

const cmpShortcuts = [
  {
    description: "Quick search",
    keySet: <ShortcutKeySet keys={[commandKey, <Typography key={1}>K</Typography>]} />,
  },
  {
    description: "Navigate to 'My customers'",
    keySet: <ShortcutKeySet keys={[commandKey, <Typography key={1}>/</Typography>]} />,
  },
];

const reportsShortcuts = [
  {
    description: "Run a report",
    keySet: <ShortcutKeySet keys={[shiftKey, <Typography key={1}>R</Typography>]} />,
  },
  {
    description: "Add a dimension to a report",
    keySet: <ShortcutKeySet keys={[shiftKey, <Typography key={1}>D</Typography>]} />,
  },
  {
    description: "Add a filter to a report",
    keySet: <ShortcutKeySet keys={[shiftKey, <Typography key={1}>F</Typography>]} />,
  },
  {
    description: "Add a group by to a report",
    keySet: <ShortcutKeySet keys={[shiftKey, <Typography key={1}>G</Typography>]} />,
  },
  {
    description: "Switch to table view",
    keySet: <ShortcutKeySet keys={[<Typography key={0}>T</Typography>]} />,
  },
  {
    description: "Switch to heatmap view",
    keySet: <ShortcutKeySet keys={[<Typography key={0}>H</Typography>]} />,
  },
];

const KeyboardShortcuts = () => {
  const { isDoitEmployee } = useAuthContext();
  const cmpTableTitle = "DoiT Console actions";
  const analyticsTableTitle = "Cloud analytics report actions";

  const { open, setShortcutsOpen } = useKeyboardShortcutsOpen();

  return (
    <Hidden mdDown>
      <SideDrawer
        open={open}
        title="Keyboard Shortcuts"
        onClose={() => {
          setShortcutsOpen(false);
        }}
        dataCy="keyboard-drawer"
      >
        <>
          <Box sx={visuallyHiddenStyle}>
            <a href={`#${shortcutsTableId(cmpTableTitle)}`}>Skip to DoiT Console actions shortcuts</a>
            <a href={`#${shortcutsTableId(analyticsTableTitle)}`}>Skip to cloud analytics report actions shortcuts</a>
          </Box>
          {isDoitEmployee && (
            <>
              <ShortcutsTable shortcutsTitle={cmpTableTitle} shortcuts={cmpShortcuts} />
              <Divider sx={{ my: 1 }} />
            </>
          )}
          <ShortcutsTable shortcutsTitle={analyticsTableTitle} shortcuts={reportsShortcuts} />
        </>
      </SideDrawer>
    </Hidden>
  );
};

export default KeyboardShortcuts;
