import { Box, CircularProgress, Grid, Typography } from "@mui/material";

export const CircularProgressWithLabel = ({ value }) => (
  <Grid item sm={4}>
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} color="primary" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {value}%
        </Typography>
      </Box>
    </Box>
  </Grid>
);
