import PushPinIcon from "@mui/icons-material/PushPin";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { type CustomerNote } from "../types";

type Props = {
  noteItem: CustomerNote;
};
export const NoteTitle = ({ noteItem }: Props) => (
  <Stack direction="row" spacing={1} data-cy="note-name">
    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
      {noteItem.name ?? noteItem.customerName}
    </Typography>
    {noteItem.pinned && <PushPinIcon sx={{ color: "action.active" }} />}
  </Stack>
);
