import { TemplateVisibility } from "@doitintl/cmp-models";

export type VisibilityLabel = "Public" | "DoiT" | "Private";

export const getTemplateVisibilityLabel = (visibility: TemplateVisibility): VisibilityLabel => {
  switch (visibility) {
    case TemplateVisibility.GLOBAL:
      return "Public";
    case TemplateVisibility.INTERNAL:
      return "DoiT";
    case TemplateVisibility.PRIVATE:
      return "Private";
  }
};
