import { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  CustomerModel,
  Metadata,
  Renderer,
  TimeInterval,
  type TimeSettingsConfig,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Tooltip } from "@mui/material";
import { DateTime } from "luxon";

import { useGenerateReportWithCustomer } from "../../Components/hooks/useGenerateReport";
import TrendChip from "../../Components/TrendChip/TrendChip";
import { TrendType } from "../../types";
import { FixedFilters } from "../CloudAnalytics/utilities";

interface TrendWrapperProps {
  trendDirection: TrendType | undefined;
  amount: string;
  customerId: string;
  sign: string;
}

export const TrendWrapper = ({ trendDirection, amount, customerId, sign }: TrendWrapperProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const generateReport = useGenerateReportWithCustomer(getCollection(CustomerModel).doc(customerId), null);

  const handleCreateReport = useCallback(async () => {
    setLoading(true);

    const startTime = DateTime.utc().startOf("day").minus({ days: 2 });
    const timeInterval = TimeInterval.DAY;

    const customTimeRange = {
      to: startTime,
      from: startTime.minus({ days: 30 }).startOf("month"),
    };

    const timeSettings: TimeSettingsConfig = {
      unit: timeInterval,
      mode: TimeSettingsMode.Fixed,
      amount: 0,
      includeCurrent: false,
    };

    const titleAmount = `${trendDirection === TrendType.Down ? -amount : amount}% `;
    const createReportPayload = {
      name: `${titleAmount}Trend at ${startTime.toFormat("dd LLLL yyyy hh:mm")}`,
      description: "",
      config: {
        fields: [
          {
            type: Metadata.FIXED,
            id: FixedFilters.SERVICE,
            groupBy: true,
            limit: 20,
          },
        ],
        timeInterval,
        customTimeRange,
        renderer: Renderer.STACKED_COLUMN_CHART,
        timeSettings,
      },
    };

    const newReportId = await generateReport(createReportPayload, false);
    const reportUrl = `/customers/${customerId}/analytics/reports/${newReportId}`;
    history.push(reportUrl);
  }, [customerId, generateReport, history, amount, trendDirection]);

  if (amount === "N/A") {
    return <>N/A</>;
  }

  return (
    <>
      <Tooltip placement="right" arrow title={amount !== "N/A" ? "View Report" : ""}>
        <span>
          {trendDirection && (
            <TrendChip
              disabled={isNaN(parseFloat(amount))}
              onClick={handleCreateReport}
              type={trendDirection}
              amount={amount}
              sign={sign}
              loading={loading}
            />
          )}
        </span>
      </Tooltip>
    </>
  );
};

export default TrendWrapper;
