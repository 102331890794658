import { getDownloadURL, ref } from "firebase/storage";

import { consoleErrorWithSentry } from "./errorHandling";
import { storage } from "./firebase";

type DownloadFromBucketProps = {
  fileName: string | null;
  path: string | null;
  bucketName: string;
};

export const downloadFromBucket = async ({ bucketName, path, fileName }: DownloadFromBucketProps) => {
  try {
    if (!bucketName || !path || !fileName) {
      throw new Error("Missing required parameters");
    }

    const objectUrl = `gs://${bucketName}/${path}`;
    const gsRef = ref(storage, objectUrl);
    const downloadUrl = await getDownloadURL(gsRef);
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const a = document.createElement("a");
        a.setAttribute("style", "display: none");
        document.body.appendChild(a);
        const anchorUrl = window.URL.createObjectURL(blob);
        a.href = anchorUrl;
        a.download = fileName || "";
        a.click();
        window.URL.revokeObjectURL(anchorUrl);
      } else {
        // deal with your error state here
        // eslint-disable-next-line no-console
        console.log(xhr.status);
      }
    };
    xhr.open("GET", downloadUrl);
    xhr.send();
  } catch (error: any) {
    consoleErrorWithSentry(error);

    switch (error.code) {
      case "storage/object-not-found":
        return "File not found";

      case "storage/unauthorized":
        return "You do not have permission to access this file";

      case "storage/canceled":
        return "User canceled the upload";

      default:
        return "Failed to download file";
    }
  }
};
