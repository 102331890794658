import { useCallback, useRef, useState } from "react";

import {
  type AmazonWebServicesAssetModel,
  type AmazonWebServicesStandaloneAssetModel,
  AssetTypeAwsStandalone,
} from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useGetLoginUrl } from "../../../../utils/sauron";
import { PLESAssetIndicator } from "../../assetUtils";
import { SauronStaffDialog, type SauronStaffDialogHandle } from "../../Cards/components/SauronStaffDialog";
import { SauronFailureDialog } from "../../Dialogs/SauronFailureDialog";

type Props = {
  assetData?: ModelData<AmazonWebServicesAssetModel> | ModelData<AmazonWebServicesStandaloneAssetModel>;
  returnToAssets: () => void;
};

const AwsAssetHeader = ({ assetData, returnToAssets }: Props) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const sauronRef = useRef<SauronStaffDialogHandle>(null);
  const theme = useTheme();
  const isPLES = assetData?.properties?.support?.isPLESAsset || false;
  const [openSauronFailure, setOpenSauronFailure] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSauronOnboarding = (): void => {
    sauronRef.current?.openDialog();
  };
  const getLoginUrl = useGetLoginUrl();

  const openAWSSupport = async () => {
    if (assetData?.properties.accountId) {
      try {
        const url = await getLoginUrl(assetData.properties.accountId, isDoitEmployee);
        window.open(url, "_blank");
      } catch (error: any) {
        setErrorMessage(error.message);
        setOpenSauronFailure(true);
      }
    }
  };

  const awsSupportButton =
    assetData?.type === "amazon-web-services" && assetData.properties.sauronRole ? (
      <Button
        variant="outlined"
        component="a"
        target="_blank"
        onClick={openAWSSupport}
        disabled={!assetData}
        data-cy="awsSupportButton"
      >
        AWS Support
        <OpenInNewIcon aria-hidden fontSize="small" sx={{ ml: 1 }} />
      </Button>
    ) : (
      <></>
    );

  const notStandalone = assetData?.type !== AssetTypeAwsStandalone;

  const handleCloseSauronFailure = useCallback(() => {
    setOpenSauronFailure(false);
    setErrorMessage("");
  }, []);

  return (
    <>
      {assetData && <SauronStaffDialog ref={sauronRef} accountId={assetData.properties.accountId} />}
      <Stack direction="row" sx={{ my: 1 }}>
        <IconButton aria-label="Back to assets list" onClick={returnToAssets} data-cy="backToAssetsButton">
          <ArrowBackIcon sx={{ color: theme.palette.text.primary }} />
        </IconButton>
        <Typography variant="h3" data-cy="title" sx={{ pt: 1, fontWeight: "fontWeightMedium" }}>
          Amazon Web Services asset
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ pb: 4, alignItems: "center" }}>
        {notStandalone && isDoitEmployee && (
          <Button variant="outlined" onClick={handleSauronOnboarding} data-cy="sauronButton">
            Sauron Onboarding
          </Button>
        )}
        <Button
          variant="outlined"
          component="a"
          href={assetData ? `https://${assetData.properties.accountId}.signin.aws.amazon.com/console` : "#"}
          target="_blank"
          disabled={!assetData}
          data-cy="awsConsoleButton"
        >
          AWS Console
          <OpenInNewIcon aria-hidden fontSize="small" sx={{ ml: 1 }} />
        </Button>
        {notStandalone && awsSupportButton}
        {isPLES && <Typography variant="body2">{PLESAssetIndicator}</Typography>}
        <SauronFailureDialog error={errorMessage} open={openSauronFailure} onClose={handleCloseSauronFailure} />
      </Stack>
    </>
  );
};

export default AwsAssetHeader;
