import { useMemo, useState } from "react";

import { useHistory, useParams } from "react-router";
import { DoitRole, TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, useDocumentData } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../Components/FilterTable/FilterTableSkeleton";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import LoadingButton from "../../../../Components/LoadingButton";
import { type EntitlementWithId } from "../../types";
import EntitlementRowItem from "./EditTierRowItem";
import { useEntitlementsQuery, useUpdateTier } from "./hooks";

export type EntitlementRow = EntitlementWithId & {
  disabled: boolean;
};

const itemsDescriptions: FilterTableItemDescriptor<EntitlementRow>[] = [
  {
    headerText: "Name",
    value: "displayName",
    filterType: "string",
  },
  {
    headerText: "Description",
    value: "description",
  },
  {
    headerText: "Limit",
    value: "limit",
  },
  {
    headerText: "Key",
    value: "key",
    filterType: "string",
  },
];

const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);

const EditTierEntitlements = () => {
  const { tierId } = useParams<{ tierId: string }>();
  const history = useHistory<any>();
  const isDoitOwnerRole = useDoitRoleCheck(DoitRole.Owners);
  const [tier, tierLoading] = useDocumentData(getCollection(TierModel).doc(tierId), { idField: "id" });
  const [entitlementData, entitlementsLoading] = useCollectionData(useEntitlementsQuery(), {
    idField: "id",
  });

  const [selected, setSelected] = useState<EntitlementRow[]>([]);
  const [updateTier, loading] = useUpdateTier(tierId);

  const tierEntitlements = useMemo(() => tier?.entitlements.map((e) => e.id), [tier]);

  const entitlements = useMemo(
    () =>
      entitlementData?.map((entitlement) => ({
        ...entitlement,
        disabled:
          !isDoitOwnerRole || selected?.find((v) => v.key === entitlement.key && v.id !== entitlement.id) !== undefined,
      })) ?? [],
    [entitlementData, selected, isDoitOwnerRole]
  );

  const isSaveDisabled = useMemo(
    () =>
      isDoitOwnerRole && tier && selected
        ? tier.entitlements.length === selected.length &&
          tier.entitlements.every((e1) => selected.some((e2) => e2.id === e1.id))
        : true,
    [isDoitOwnerRole, selected, tier]
  );

  if (tierLoading || entitlementsLoading) {
    return <FilterTableSkeleton />;
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="start">
      <IconButton
        aria-label="Back"
        size="large"
        onClick={() => {
          history.push(history.location?.state?.prevPage ?? "/tiers");
        }}
        data-cy="back-icon"
      >
        <BackIcon fontSize="medium" color="action" />
      </IconButton>
      <FilterTable<EntitlementRow>
        toolbarProps={{
          title: tier?.displayName,

          customSlot: (
            <LoadingButton
              variant="contained"
              mixpanelEventId="update-tier-entitlements"
              disabled={isSaveDisabled}
              loading={loading}
              onClick={async () => {
                await updateTier(selected.map((s) => s.id));
              }}
            >
              Save
            </LoadingButton>
          ),
        }}
        showRowsSelection
        tableItems={entitlements}
        rowComponent={EntitlementRowItem}
        itemUniqIdentifierField="id"
        headerColumns={headerColumns}
        filterColumns={filters}
        defaultRowsSelected={tierEntitlements}
        defaultSortingColumnValue="key"
        defaultSortDirection="asc"
        onRowsSelected={setSelected}
      />
    </Box>
  );
};

export default EditTierEntitlements;
