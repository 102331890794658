import { ContractModel, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type MasterPayerAccountsModelSnapshot } from "./hooks/useSubscribedMasterPayerAccounts";

export default async function getIsMPAAssignedToContract(
  masterPayerAccount: MasterPayerAccountsModelSnapshot
): Promise<boolean> {
  const customerRef = getCollection(CustomerModel).doc(masterPayerAccount.customerId);
  const customerAWSContracts = await getCollection(ContractModel)
    .where("customer", "==", customerRef)
    .where("type", "==", "amazon-web-services")
    .get();

  return customerAWSContracts.docs.some((contract) =>
    Object.values(contract.data().properties?.support ?? {}).some(
      ({ mpaRef }) => mpaRef !== null && masterPayerAccount.ref.id === mpaRef.id
    )
  );
}
