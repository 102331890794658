import { useMemo, useState } from "react";

import { useHistory, useParams } from "react-router";
import { Box } from "@mui/material";
import sumBy from "lodash/sumBy";
import { v4 as uuid } from "uuid";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepState } from "../../../../../Components/Stepper";
import { useFlexsaveOpsApi } from "../../../GCP/hooks";
import { PurchaseConfirmationDialog } from "../Common/PurchaseConfirmationDialog";
import { useDefaultPurchaseItem, useUniqueCustomerValues } from "../hooks/manualPurchaseHooks";
import { type ManualPurchaseItem } from "../types";
import {
  generateManualPurchasePayload,
  generatePurchasePlanPricePayload,
  hasInvalidAmount,
  hasZeroCost,
} from "../utils/manualPurchaseUtils";
import { ManualPurchaseWrapper } from "./ManualPurchaseWrapper";
import { PurchaseSummary } from "./PurchaseSummary";

export const ManualPurchaseStepper = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const flexSaveOpsApi = useFlexsaveOpsApi("v2");
  const history = useHistory();
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();
  const customerSkus = useUniqueCustomerValues(customerId);
  const defaultPurchaseItem = useDefaultPurchaseItem(customerSkus);

  const [selectedItems, setSelectedItems] = useState<ManualPurchaseItem[]>([]);
  const [currentStep, getCurrentStep] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);
  const backToCustomer = `/flexsave-gcp-operations/customers/${customerId}`;

  const stepperState: StepState[] = useMemo(() => {
    if (hasZeroCost(selectedItems) || hasInvalidAmount(selectedItems)) {
      return ["editing", "editing"];
    } else if (currentStep === 0) {
      return ["complete", "incomplete"];
    } else {
      return ["complete", "complete"];
    }
  }, [selectedItems, currentStep]);

  if (defaultPurchaseItem && selectedItems.length === 0) {
    setSelectedItems([defaultPurchaseItem]);
  }

  const updateSelectedItemList = (purchaseItem: ManualPurchaseItem) => {
    const updatedList = selectedItems.map((sku) => {
      if (sku.id === purchaseItem.id) {
        return purchaseItem;
      }
      return sku;
    });
    setSelectedItems(updatedList);
  };

  const fetchPurchasePlanPrice = async (purchaseItem: ManualPurchaseItem) => {
    try {
      const response = await flexSaveOpsApi.calculatePurchasePlanPrice(generatePurchasePlanPricePayload(purchaseItem));
      return response?.purchases[0]?.price;
    } catch (err) {
      errorSnackbar("Failed to calculate SKU prices");
    }
    return 0;
  };

  const onAdd = () => {
    defaultPurchaseItem && setSelectedItems([...selectedItems, { ...defaultPurchaseItem, id: uuid() }]);
  };

  const onDelete = (id: string) => {
    const filteredSku = selectedItems.filter((sku: ManualPurchaseItem) => sku.id !== id);
    setSelectedItems(filteredSku);
  };

  const onChange = async (purchaseItem: ManualPurchaseItem) => {
    setLoading(true);
    const price = await fetchPurchasePlanPrice(purchaseItem);
    updateSelectedItemList({ ...purchaseItem, cost: price, isValid: purchaseItem.amount % 2 === 0 });
    setLoading(false);
  };

  const onSubmit = async () => {
    setApproveDialogOpen(true);
  };

  const onClose = () => {
    history.push(backToCustomer);
  };

  const onDialogApproveClicked = async () => {
    try {
      setLoading(true);
      const cuds = generateManualPurchasePayload(selectedItems);
      await flexSaveOpsApi.manualPurchase(cuds);
      setLoading(false);
      successSnackbar("SKUs purchased successfully");
      history.push(backToCustomer);
    } catch {
      errorSnackbar("Failed to purchase SKUs");
    }
  };

  const handleApproveDialogClose = () => {
    setApproveDialogOpen(false);
  };

  const steps: Step[] = [
    {
      children: (
        <ManualPurchaseWrapper
          selectedItems={selectedItems}
          customerSkus={customerSkus}
          onAdd={onAdd}
          onDelete={onDelete}
          onChange={onChange}
          loading={loading}
        />
      ),
      label: "SKUs to purchase",
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: <PurchaseSummary purchaseItems={selectedItems} />,
      label: "Purchase summary",
      order: 1,
      required: true,
      state: stepperState[1],
    },
  ];
  return (
    <Box mt={3}>
      <PurchaseConfirmationDialog
        open={approveDialogOpen}
        handleClose={handleApproveDialogClose}
        handleApprove={onDialogApproveClicked}
        totalCost={sumBy(selectedItems, "cost")}
        customerCount={1}
        cudCount={sumBy(selectedItems, "amount")}
        purchaseType="BulkPurchase"
      />
      <Stepper
        backButtonLabel="Back"
        loading={loading}
        onCancel={onClose}
        onSubmit={onSubmit}
        steps={steps}
        submitButtonLabel="Purchase"
        footerMaxWidth={1000}
        getCurrentStep={getCurrentStep}
        contentSx={{
          mt: 5,
          mb: 3,
        }}
      />
    </Box>
  );
};
