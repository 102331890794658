import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

export type SnowflakeError = {
  message: string | null;
  ts: Timestamp;
};
export enum OnboardingIssueType {
  TableNotFound = "Table Not Found",
  PermissionNotFound = "Permission Not Found",
}
export type OnboardingIssue = {
  Type: OnboardingIssueType;
  Detail: string;
};

@subCollection("snowflake-configuration")
export class DataConnectorSnowflakeModel {
  accountId!: string;

  customerId!: string;

  error!: SnowflakeError;

  lastUpdated!: Timestamp;

  orgId!: string;

  status!: string;

  timeCreated!: Timestamp;

  user!: string;

  OnboardingIssues?: OnboardingIssue[];
}

@collection("dataConnectors")
export class DataConnectorModel {
  subCollections!: {
    "snowflake-configuration": DataConnectorSnowflakeModel;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}
