import { type PurchaseStatus } from "@doitintl/cmp-models";
import { type Theme } from "@mui/material";
import { deepPurple, green, grey, lightBlue, red, yellow } from "@mui/material/colors";
import capitalize from "lodash/capitalize";

import { type StatusChipConfig } from "../types";

export const getStatusText = (status: PurchaseStatus) => {
  switch (status) {
    case "covered":
      return "Covered";
    case "error":
      return "Error";
    case "in-progress":
      return "In progress";
    case "purchased":
      return "Purchased today";
    case "purchase-required":
      return "Purchase required";
    case "waiting-for-optimizer":
      return "Waiting for Optimizer";
    case "waiting-for-recommendations":
      return "Waiting for recommendations";
    case "not-enough-data":
      return "Not enough data";
    case "refreshing":
      return "Refreshing";
    default:
      return capitalize(status?.replaceAll("-", " "));
  }
};

const getChipColorConfig = (theme: Theme, status: PurchaseStatus) => {
  const isDarkMode = theme.palette.mode === "dark";
  const contrastColor = theme.palette.primary.contrastText;
  const { primary: primaryColor, secondary: secondaryColor } = theme.palette.text;
  switch (status) {
    case "covered":
      return {
        bgColor: isDarkMode ? green[400] : green[700],
        textColor: contrastColor,
      };
    case "error":
    case "rejected":
      return {
        bgColor: isDarkMode ? red[500] : red[600],
        textColor: isDarkMode ? primaryColor : contrastColor,
      };
    case "in-progress":
    case "purchased":
      return {
        bgColor: isDarkMode ? lightBlue[400] : lightBlue[800],
        textColor: contrastColor,
      };
    case "purchase-required":
      return {
        bgColor: isDarkMode ? yellow[400] : yellow[500],
        textColor: isDarkMode ? contrastColor : primaryColor,
      };
    case "waiting-for-optimizer":
    case "waiting-for-recommendations":
      return {
        bgColor: isDarkMode ? deepPurple[200] : deepPurple[300],
        textColor: contrastColor,
      };
    case "not-enough-data":
      return {
        bgColor: isDarkMode ? grey[500] : grey[300],
        textColor: isDarkMode ? contrastColor : primaryColor,
      };
    case "refreshing":
      return {
        textColor: secondaryColor,
      };
    default:
      return {};
  }
};

export const getChipConfigByPurchaseStatus = (theme: Theme, status: PurchaseStatus): StatusChipConfig => ({
  ...getChipColorConfig(theme, status),
  text: getStatusText(status),
});
