import TableCell from "@mui/material/TableCell";

import { type AsgTag } from "../types";

type Props = {
  row: AsgTag;
};

export const AwsTagTableRow = ({ row }: Props) => (
  <>
    <TableCell>{row.Key}</TableCell>
    <TableCell>{row.Value}</TableCell>
  </>
);
