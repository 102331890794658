import { Container } from "@mui/material";

import NoEntitlement from "../../../../Components/NoEntitlement/NoEntitlement";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../../Context/TierProvider";
import { UserApiForm, type UserApiFormProps } from "./UserApiForm";

export const UserApiTab = ({ user }: UserApiFormProps) => {
  const { customer } = useCustomerContext();
  return useIsFeatureEntitled("platform:externalApi", true) ? (
    <Container maxWidth="sm" disableGutters>
      <UserApiForm user={user} />
    </Container>
  ) : (
    <NoEntitlement
      feature="gain API access"
      contactSales
      inPresentationMode={customer?.presentationMode?.enabled}
      contactSalesButtonText="Contact sales"
    />
  );
};
