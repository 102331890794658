import { memo } from "react";

import { keyframes } from "@emotion/react";
import { Stack, ThemeProvider, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import { formatAsDollars } from "../../utils/costUtils";

type Props = {
  spend: number;
  text: string;
  fillAmount: number;
};

type LinearProgressProps = {
  theme?: ThemeProps;
};

type ThemeProps = {
  isGreen: boolean;
};

export const SpendBar = memo(({ spend, text, fillAmount }: Props) => {
  SpendBar.displayName = "SpendBar";
  const indeterminate1Keyframes = keyframes({
    "0%": {
      left: "0%",
      right: "100%",
    },
    "100%": {
      left: "0%",
      right: `${100 - fillAmount}%`,
    },
  });

  const BorderLinearProgress = styled(LinearProgress)(({ theme }: LinearProgressProps) => ({
    height: 48,
    borderRadius: 4,
    backgroundColor: grey[200],
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: theme?.isGreen && green[100],
    },
    "& .MuiLinearProgress-bar1Indeterminate": {
      width: "auto",
      animation: `${indeterminate1Keyframes} 0.7s linear forwards`,
    },
    "& .MuiLinearProgress-bar2Indeterminate": {
      display: "none",
    },
  }));

  return (
    <Stack>
      <Typography variant="h1" fontWeight="700" letterSpacing={2}>
        {formatAsDollars(spend)}
        <Typography display="inline" fontSize="400" color="text.secondary" ml={1}>
          {text}
        </Typography>
      </Typography>

      <ThemeProvider theme={{ isGreen: fillAmount === 100 }}>
        <BorderLinearProgress color="success" variant="indeterminate" />
      </ThemeProvider>
    </Stack>
  );
});
