import { useMemo } from "react";

import { Stack, Typography } from "@mui/material";
import sumBy from "lodash/sumBy";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { formatAsDollars } from "../../../utils/costUtils";
import { purchaseSummaryColumns } from "../PurchaseDetails/columns";
import { type ManualPurchaseItem } from "../types";
import { PurchaseSummaryItem } from "./PurchaseSummaryItem";

type Props = {
  purchaseItems: ManualPurchaseItem[];
};

export const PurchaseSummary = ({ purchaseItems }: Props) => {
  const commitmentCost: number = useMemo(() => sumBy(purchaseItems, "cost"), [purchaseItems]);

  return (
    <>
      <FilterTable<ManualPurchaseItem>
        rowComponent={PurchaseSummaryItem}
        tableItems={purchaseItems}
        headerColumns={purchaseSummaryColumns}
        showFilterBar={false}
        filterColumns={[]}
        toolbarProps={{ title: "Purchase summary" }}
        fillEmptySpace={false}
      />
      <Stack spacing={1} mt={6}>
        <Typography fontWeight="medium">Total commitment cost:</Typography>
        <Typography>{formatAsDollars(commitmentCost)}</Typography>
      </Stack>
    </>
  );
};
