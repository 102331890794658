import { type ComponentProps } from "react";

import { DoitRole } from "@doitintl/cmp-models";

import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { ToolTipWrapper } from "../../../Components/TooltipWrapper";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { text } from "./text";

export function SettingsAdminItemWrapper({
  children,
  disabledForTrial,
}: {
  children: (isDisabled: boolean) => ComponentProps<typeof ToolTipWrapper>["children"];
  disabledForTrial?: boolean;
}) {
  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isTrialCustomer = !!customer?.trialEndDate;
  const isDisabled = !useDoitRoleCheck(DoitRole.CustomerSettingsAdmin) || (!!disabledForTrial && isTrialCustomer);

  if (!isDoitEmployee) {
    return null;
  }

  let tooltipTitle: string | undefined = undefined;

  if (isTrialCustomer && disabledForTrial) {
    tooltipTitle = text.THIS_OPTION_NOT_FOR_TRIAL_TOOLTIP;
  } else if (isDisabled) {
    tooltipTitle = text.NO_PERMISSION_TOOLTIP;
  }

  return (
    <ToolTipWrapper title={isDisabled ? tooltipTitle : undefined} placement="top-start">
      {children(isDisabled)}
    </ToolTipWrapper>
  );
}
