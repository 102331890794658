import { type GoogleCloudDirectAssetModel } from "@doitintl/cmp-models";

import { useApiContext } from "../../../../api/context";
import { useOneTimeCopyRequest } from "../../../../api/endpoints";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type Asset } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import GoogleDirectAssetForm from "../../Forms/GoogleDirectAssetForm";

type Props = {
  onClose: () => void;
  asset: Asset<GoogleCloudDirectAssetModel>;
};

export default function UpdateGoogleCloudDirectAssetForm({ onClose, asset }: Props) {
  const api = useApiContext();
  const oneTimeCopyRequest = useOneTimeCopyRequest();
  const { customer } = useCustomerContext();

  if (!customer) {
    return null;
  }

  const customerId = customer.id;

  const table = asset.data.tables?.[0];

  if (!table) {
    return null;
  }

  return (
    <GoogleDirectAssetForm
      mode="edit"
      initialValues={{
        project: table.project,
        dataset: table.dataset,
        billingAccountId: asset.data.properties.billingAccountId,
      }}
      onClose={onClose}
      onSubmit={async ({ project, dataset, billingAccountId }) => {
        await api.request({
          method: "patch",
          url: `/v1/customers/${customerId}/assets/google-cloud-direct/${asset.id}`,
          data: { project, dataset },
        });

        try {
          await oneTimeCopyRequest({ billingAccountId, customerId });
        } catch (e) {
          consoleErrorWithSentry(e);
        }
      }}
    />
  );
}
