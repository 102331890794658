import { useMemo } from "react";

import { type GCPCustomersAggregationModel, IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

import { type CustomerAggregatedWorkloadInfo } from "../types";

const customerPurchaseAggregation = (customerId: string) =>
  getCollection(IntegrationModel).doc("flexsave").collection("gcp-ops-customers-aggr").doc(customerId);

function parseGCPCustomersAggregation(
  gcpFlexsaveCustomers: GCPCustomersAggregationModel
): CustomerAggregatedWorkloadInfo {
  const {
    customerId,
    flexCuds,
    targetCoverage = 0,
    purchaseStats,
    optimizerStats,
    forceTargetCoverage,
    bulkPurchase,
  } = gcpFlexsaveCustomers;
  return {
    customerId,
    targetCoverage,
    currentOverallCoverage: purchaseStats?.currentCudCoverage || 0,
    optimizerCoverage: optimizerStats?.currentCudCoverage || 0,
    flexCud: flexCuds?.reduce((acc, current) => acc + Math.abs(current.activeFlexcuds), 0),
    forceTargetCoverage: !!forceTargetCoverage,
    excludeCustomerFromBulk: bulkPurchase?.excludeCustomerFromBulk || false,
  };
}

export function useCustomerPurchaseAggregation(customerId: string) {
  const query = customerPurchaseAggregation(customerId);
  const [item] = useDocumentData(query, {
    transform: parseGCPCustomersAggregation,
  });
  return item;
}

export function useGCPCustomerAggregation() {
  const [items] = useCollectionData(
    getCollection(IntegrationModel).doc("flexsave").collection("gcp-ops-customers-aggr"),
    {
      transform: parseGCPCustomersAggregation,
    }
  );
  return useMemo(() => {
    if (!items) {
      return;
    }

    const map = new Map<string, CustomerAggregatedWorkloadInfo>();
    for (const item of items) {
      map.set(item.customerId, item);
    }
    return map;
  }, [items]);
}
