import isEmail from "is-email";
import type * as axios from "axios";

import { errorMessages } from "./consts";

type EmailValidatorProps = {
  api: axios.AxiosInstance;
  customerId: string;
};

export type ValidatedEmail = { email: string } & (
  | {
      validated?: boolean;
      validating?: false;
      error?: string;
      exists?: boolean;
      invited?: boolean;
    }
  | { validating: true; validated?: false; exists?: false; invited?: false; error?: false }
);

const emailFormatValidator = (email: string) => {
  if (!isEmail(email)) {
    throw new Error(errorMessages.INVALID_EMAIL_ERROR);
  }
};

const emailAlreadyExistValidator = async (email, index, { api, customerId }: EmailValidatorProps) => {
  try {
    await api.request({
      method: "post",
      url: `/v1/customers/${customerId}/users/exists`,
      data: {
        email,
      },
      params: {
        c: index,
      },
    });
  } catch (e: any) {
    if (e?.response.status === 400) {
      throw new Error(errorMessages.NO_EMAIL_RECORD(email));
    }
    if (e?.response.status === 409) {
      throw new Error(errorMessages.USER_ALREADY_EXIST);
    }
    if (e?.response.status === 404) {
      throw new Error(errorMessages.USER_ALREADY_INVITED);
    }
    throw e;
  }
};

export const validateEmail = async (
  email: string,
  index: number,
  { api, customerId }: EmailValidatorProps
): Promise<ValidatedEmail> => {
  try {
    emailFormatValidator(email);
    await emailAlreadyExistValidator(email, index, { api, customerId });
    return {
      email,
      validated: true,
    };
  } catch (e: any) {
    switch (e.message) {
      case errorMessages.NO_EMAIL_RECORD(email):
      case errorMessages.INVALID_EMAIL_ERROR:
        return {
          email,
          validated: true,
          error: e.message,
        };
      case errorMessages.USER_ALREADY_EXIST:
        return {
          email,
          validated: true,
          exists: true,
        };
      case errorMessages.USER_ALREADY_INVITED:
        return {
          email,
          validated: true,
          invited: true,
        };
      default:
        throw e;
    }
  }
};
