import { type JSX } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type Shortcut = {
  description: string;
  keySet: JSX.Element;
};

type Props = {
  shortcutsTitle: string;
  shortcuts: Shortcut[];
};

const useStyles = makeStyles((theme) => ({
  tableCellRoot: {
    color: theme.palette.text.primary,
    padding: `${theme.spacing(0.5)} 0`,
    border: "none",
  },
  tableContainerRoot: {
    boxShadow: "none",
    border: "none",
  },
}));

export const shortcutsTableId = (title: string) => `table-${title}`.replace(" ", "-");

const ShortcutsTable = ({ shortcutsTitle, shortcuts }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const firstColStyle = { width: 275 };

  return (
    <TableContainer component={Paper} classes={{ root: classes.tableContainerRoot }}>
      <Table id={shortcutsTableId(shortcutsTitle)} aria-label={shortcutsTitle}>
        <TableHead>
          <TableRow>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              <Typography sx={{ fontWeight: theme.typography.fontWeightMedium }}>{shortcutsTitle}</Typography>
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }}>
              <Typography sx={{ fontWeight: theme.typography.fontWeightMedium, pl: 1 }}>Shortcut</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shortcuts.map((row) => (
            <TableRow key={row.description}>
              <TableCell component="th" scope="row" classes={{ root: classes.tableCellRoot }} sx={firstColStyle}>
                <Typography>{row.description}</Typography>
              </TableCell>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                <Typography>{row.keySet}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShortcutsTable;
