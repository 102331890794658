import React, { useCallback, useMemo, useState } from "react";

import { type CommonSubscriptionNotificationSettings } from "@doitintl/cmp-models";
import { Box, Typography, useTheme } from "@mui/material";
import { useField } from "formik";
import uniq from "lodash/uniq";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { MultiEmailsInputText } from "../../MultiEmailsInputText";
import { validateEmail } from "../helpers";
import {
  type SubscriptionFormValues,
  type SubscriptionInputProps,
  type ValidatedEmail,
  type ValidatedEmails,
} from "../types";

type Props = SubscriptionInputProps & {
  preventSubmit: boolean;
  setPreventSubmit: (value: boolean) => void;
  subscriptionOrg: CommonSubscriptionNotificationSettings["orgRef"];
  ownerEmail: string;
  initialEmails: string[];
};

export function EmailInput({ preventSubmit, setPreventSubmit, subscriptionOrg, ownerEmail, initialEmails }: Props) {
  const [emails, meta, formikHelpers] = useField<SubscriptionFormValues["emails"]>("emails");
  const theme = useTheme();

  const [typingInProgressEmail, setTypingInProgressEmail] = useState("");

  const [isValidating, setIsValidating] = useState(false);
  const [validatedEmails, setValidatedEmails] = useState<ValidatedEmails>({});
  const validatedEmailsList = useMemo(() => Object.values(validatedEmails), [validatedEmails]);

  const validationErrors = uniq(
    validatedEmailsList.filter((x) => emails.value.includes(x.email) && x.error).map((x) => x.error)
  );

  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();

  const shouldDisableSubmit = !!typingInProgressEmail || isValidating || validationErrors.length > 0;

  if (shouldDisableSubmit !== preventSubmit) {
    setPreventSubmit(shouldDisableSubmit);
  }

  const validateEmails = useCallback(
    async (newEmails: string[]): Promise<{ [key: string]: ValidatedEmail }> => {
      setIsValidating(true);

      const newValidatedEmailsObj: { [key: string]: ValidatedEmail } = {};
      for (const email of newEmails) {
        if (validatedEmails[email]) {
          newValidatedEmailsObj[email] = validatedEmails[email];
        }
      }

      const emailsToValidate = newEmails.filter((email) => !validatedEmails[email]);

      const allowedDomains = customer.domains.concat("slack.com", "teams.ms");
      if (isDoitEmployee) {
        allowedDomains.push("doit-intl.com", "doit.com");
      }

      const newValidatedEmailsArr = await Promise.all(
        emailsToValidate.map((email) =>
          validateEmail({
            initialEmails,
            ownerEmail,
            email,
            customerRef: customer.ref,
            requiredOrg: subscriptionOrg,
            allowedDomains,
          })
        )
      );

      for (const validatedEmail of newValidatedEmailsArr) {
        newValidatedEmailsObj[validatedEmail.email] = validatedEmail;
      }

      setIsValidating(false);

      return newValidatedEmailsObj;
    },
    [customer.domains, customer.ref, isDoitEmployee, subscriptionOrg, validatedEmails, ownerEmail, initialEmails]
  );

  const handleEmailsChange = useCallback(
    async (newEmails: string[]) => {
      formikHelpers.setValue(newEmails);
      setValidatedEmails((await validateEmails(newEmails)) ?? {});
    },
    [formikHelpers, validateEmails]
  );

  return (
    <>
      <MultiEmailsInputText
        emails={emails.value}
        validatedEmails={validatedEmails}
        onEmailsChange={handleEmailsChange}
        emailValue={typingInProgressEmail}
        onEmailValueChange={setTypingInProgressEmail}
        invalidEmailsHelperComponent={meta.error ? <span>Invalid input</span> : null}
        inputLabel="Send to the following email addresses"
        chipsVariant="filled"
        chipsColor="default"
      />

      {validationErrors.length > 0 && (
        <Box>
          {validationErrors.map((error, index) => (
            <Typography variant="body2" color={theme.palette.error.main} key={index}>
              {error}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
}
