import axios, { type AxiosResponse } from "axios";

import { paths } from "./helpers";
import { type HandleEmailResponse, type SignUpMode, type UserAuthConfig } from "./types";

export async function handleEmailSignup({
  email,
  mode,
  fssaFirstName,
  fssaLastName,
}: {
  mode?: SignUpMode;
  email: string;
  fssaFirstName?: string;
  fssaLastName?: string;
}) {
  return axios.post(paths.authRegisterWithEmail, { email, mode, fssaFirstName, fssaLastName });
}

export const handleEmailCompleteSignup: ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => Promise<AxiosResponse<HandleEmailResponse>> = ({ email, password }) =>
  axios.post(
    paths.authEmail,
    {},
    {
      auth: {
        username: email,
        password,
      },
    }
  );

export const getAuthConfig: (
  email: string,
  isSso: boolean,
  idpTenantId?: string
) => Promise<AxiosResponse<UserAuthConfig>> = (email, isSso, idpTenantId) =>
  axios.post(paths.authConfig, { isSso, email, idpTenantId });
