import { useCallback, useMemo, useState } from "react";

import { type AssetType } from "@doitintl/cmp-models";
import { Box, MenuItem, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { CustomerPicker } from "../../../../Components/CustomerPicker/CustomerPicker";
import LoadingButton from "../../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { assetTypeName } from "../../../../utils/common";
import { ConfirmationDialog } from "./ConfirmationDialog";

export const apiPathByAction = {
  issue: "issue-customer-invoices",
  recalculate: "recalculate-customer",
};

const cloudOptions: AssetType[] = [
  "navigator",
  "solve",
  "solve-accelerator",
  "amazon-web-services",
  "google-cloud",
  "looker",
];

const maxDate = DateTime.utc().endOf("month");
const minDate = DateTime.fromObject({ year: 2019, month: 3, day: 1 });
const lastMonth = DateTime.utc().startOf("month").minus({ months: 1 });

const IssueForm = ({ loadJobs }: { loadJobs: () => Promise<void> }) => {
  const api = useApiContext();
  const showError = useErrorSnackbar(7);
  const showSuccessSnackbar = useSuccessSnackbar();

  const [selectedCustomer, setSelectedCustomer] = useState<{ id: string; name: string } | null>(null);
  const [formData, setFormData] = useState<{ invoiceMonth: DateTime<true>; cloud?: AssetType; reason?: string }>({
    invoiceMonth: lastMonth,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [action, setAction] = useState<"recalculate" | "issue" | undefined>();

  const handleAction = useCallback(
    (action: "recalculate" | "issue") => {
      const payload = {
        customerId: selectedCustomer?.id,
        invoiceMonth: formData.invoiceMonth.toFormat("yyyy-MM-01"),
        reason: formData.reason,
        assetType: formData.cloud,
      };

      setIsSubmitting(true);
      api
        .post(`/v1/invoicing/${apiPathByAction[action]}`, payload)
        .then(() => {
          showSuccessSnackbar("Success");
        })
        .catch((err) => {
          showError(`Error: ${err.response?.data?.error}`);
        })
        .finally(() => {
          setIsSubmitting(false);
          setOpenConfirmationDialog(false);
          loadJobs();
        });
    },
    [
      api,
      formData.cloud,
      formData.invoiceMonth,
      formData.reason,
      loadJobs,
      selectedCustomer?.id,
      showError,
      showSuccessSnackbar,
    ]
  );

  const formIncomplete = useMemo(
    () => !selectedCustomer || !formData.cloud || !formData.reason || isSubmitting,
    [formData.cloud, formData.reason, isSubmitting, selectedCustomer]
  );

  const openDialog = (action: "recalculate" | "issue") => {
    setAction(action);
    setOpenConfirmationDialog(true);
  };

  return (
    <Stack width="350px" spacing={3} mt={3}>
      <CustomerPicker
        disabled={isSubmitting}
        value={selectedCustomer?.id}
        onChange={(_event, newCustomer) => {
          setSelectedCustomer(newCustomer ? { id: newCustomer?.objectID, name: newCustomer?.primaryDomain } : null);
        }}
        getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
          hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
        }
        getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
        TextFieldProps={{
          label: "Customer domain",
          variant: "outlined",
          required: true,
        }}
        data-cy="customer-select-textfield"
      />
      <Box sx={{ width: "100%" }}>
        <DatePicker
          renderInput={(params) => <TextField required data-cy="invoice-month" margin="none" {...params} fullWidth />}
          label="Billing Month"
          disabled={isSubmitting}
          value={formData.invoiceMonth}
          onChange={(value) => {
            setFormData((formData) => ({ ...formData, invoiceMonth: value as DateTime<true> }));
          }}
          views={["year", "month"]}
          openTo="month"
          minDate={minDate}
          maxDate={maxDate}
        />
      </Box>
      <TextField
        select
        data-cy="cloud"
        fullWidth
        required
        label="Cloud"
        margin="normal"
        variant="outlined"
        value={formData.cloud}
        disabled={isSubmitting}
        onChange={(event) => {
          setFormData((formData) => ({ ...formData, cloud: event.target.value as AssetType }));
        }}
        SelectProps={{
          MenuProps: {
            MenuListProps: {
              dense: true,
            },
          },
        }}
      >
        {cloudOptions.map((cloud) => (
          <MenuItem key={cloud} value={cloud}>
            {assetTypeName(cloud)}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        data-cy="reason"
        label="Reason"
        variant="outlined"
        fullWidth
        required
        disabled={isSubmitting}
        value={formData?.reason}
        onChange={(event) => {
          setFormData((formData) => ({ ...formData, reason: event.target.value }));
        }}
      />

      <Box display="flex" justifyContent="end" sx={{ width: "100%" }}>
        <LoadingButton
          variant="outlined"
          onClick={() => {
            openDialog("recalculate");
          }}
          disabled={formIncomplete || formData.cloud !== "amazon-web-services"}
          loading={isSubmitting}
          data-cy="recalculate-btn"
          mixpanelEventId="billing-tools.recalculate"
        >
          Recalculate
        </LoadingButton>
        <LoadingButton
          sx={{ ml: 2 }}
          variant="contained"
          onClick={() => {
            openDialog("issue");
          }}
          disabled={formIncomplete}
          loading={isSubmitting}
          data-cy="issue-btn"
          mixpanelEventId="billing-tools.issue"
        >
          Issue invoice
        </LoadingButton>
      </Box>
      <ConfirmationDialog
        open={openConfirmationDialog}
        handleClose={() => {
          setOpenConfirmationDialog(false);
        }}
        handleAction={handleAction}
        isSubmitting={isSubmitting}
        action={action}
        selectedCustomer={selectedCustomer}
        formData={formData}
      />
    </Stack>
  );
};

export default IssueForm;
