import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from "react";

import { useLocation } from "react-router-dom";
import noop from "lodash/noop";

import { routes } from "../constants/navigation";
import { paths } from "../Pages/Login/helpers";
import { useAuthContext } from "./AuthContext";

type RestorePathContextType = {
  popStoredPath: () => string | undefined;
};

const noopRedirect = noop as () => string | undefined;
const homeContext = createContext<RestorePathContextType>({ popStoredPath: noopRedirect });

type Props = {
  children: ReactNode;
};

const redirectRouteKeyName = "redirectRoute";

export const RestorePathProvider = ({ children }: Props) => {
  const { tokenValue, isDoitEmployee, userId } = useAuthContext();

  const location = useLocation();
  const restoreFromStorage = useRef(false);

  const isLoggedIn = useMemo(() => !!tokenValue, [tokenValue]);

  const popStoredPath = useCallback((): string | undefined => {
    // we set the path after login
    if (!isLoggedIn) {
      return;
    }

    if (restoreFromStorage.current) {
      return;
    }

    restoreFromStorage.current = true;

    const redirectRouteParamsValue = window.sessionStorage.getItem(redirectRouteKeyName);

    let redirectRoute: string | undefined;
    if (redirectRouteParamsValue) {
      window.sessionStorage.removeItem(redirectRouteKeyName);
      try {
        const redirectRouteParams = JSON.parse(redirectRouteParamsValue);

        if (redirectRouteParams.user !== userId) {
          return;
        }

        redirectRoute = redirectRouteParams.path;
      } catch (e) {
        return;
      }
    }

    if (!redirectRoute) {
      return;
    }

    function validateRedirectRoute(redirectRoute: string) {
      if (isDoitEmployee) {
        return true;
      }

      const validateRoutesForCustomers = /\/customers\/.*/;
      return validateRoutesForCustomers.test(redirectRoute);
    }

    if (redirectRoute && !validateRedirectRoute(redirectRoute)) {
      return undefined;
    }

    return redirectRoute ?? undefined;
  }, [isDoitEmployee, isLoggedIn, restoreFromStorage, userId]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const storeItem = (pathname: string) => {
      if (
        !pathname.startsWith(paths.completeSignup) &&
        !pathname.startsWith(routes.signin) &&
        !pathname.startsWith("/login") &&
        !pathname.startsWith("/signup") &&
        pathname !== "/"
      ) {
        window.sessionStorage.setItem(redirectRouteKeyName, JSON.stringify({ path: pathname, user: userId }));
      }
    };

    storeItem(`${location.pathname}${location.search}`);
  }, [location.pathname, location.search, isLoggedIn, userId]);

  const value = useMemo(() => ({ popStoredPath }), [popStoredPath]);

  return <homeContext.Provider value={value}>{children}</homeContext.Provider>;
};

export const useRestorePathProvider = () => useContext(homeContext);
