import { useMemo } from "react";

import { type MasterPayerAccountsModel } from "@doitintl/cmp-models";

import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";

function getPayerNumberFromMPAName(mpaName: string): number {
  const payerNumber = parseInt(mpaName.replace(/[^\d]*/, ""));
  if (Number.isNaN(payerNumber)) {
    return 0;
  }
  return payerNumber;
}

export function getDefaultCURBucket(payerNumber: number): string {
  return `doitintl-awsops-${payerNumber}`;
}

export function getDefaultCURPath(payerNumber: number): string {
  return `CUR/doitintl-awsops-${payerNumber}`;
}

export function useMPAPrePopulate(
  masterPayerAccount: MasterPayerAccountsModelSnapshot,
  mpaPartialUpdate: (mpaActivationData: Partial<MasterPayerAccountsModel>) => Promise<boolean>
) {
  return useMemo(() => {
    const prePopulatedFields: Partial<MasterPayerAccountsModel> = {};
    const payerNumber = getPayerNumberFromMPAName(masterPayerAccount.name);

    if (masterPayerAccount.cur_bucket === "") {
      prePopulatedFields.cur_bucket = getDefaultCURBucket(payerNumber);
    }
    if (masterPayerAccount.cur_path === "") {
      prePopulatedFields.cur_path = getDefaultCURPath(payerNumber);
    }

    if (Object.keys(prePopulatedFields).length > 0) {
      mpaPartialUpdate(prePopulatedFields);
      return { ...masterPayerAccount, ...prePopulatedFields };
    }
    return masterPayerAccount;
  }, [masterPayerAccount, mpaPartialUpdate]);
}
