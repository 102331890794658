import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button, Stack, Typography } from "@mui/material";

import illustration from "../../../assets/GKE-empty-illustration.svg";
import { cloudAnalyticsText, lensEnticementTexts } from "../../../assets/texts";
import { LensEnticement } from "../../LensEnticement/LensEnticement";
import { type EnticementState } from "../../LensEnticement/types";
import useFetchAmTamEmail from "../../NoEntitlement/hooks";
import { ContactSalesButton } from "../../NoEntitlement/NoEntitlement";

export const GKELensEnticement = ({ state }: { state: EnticementState }) => {
  const { accountManagers, loading } = useFetchAmTamEmail();

  return (
    <LensEnticement
      illustration={<img src={illustration} alt="GKE Lens empty illustration" width="auto" height="250" />}
      title={lensEnticementTexts.GKE_LENS_TITLE}
      listItems={lensEnticementTexts.GKE_LENS_LIST}
    >
      {state === "activate" && (
        <Stack direction="column" spacing={2}>
          <Typography variant="body1">Get started by enabling GKE Cost Allocation.</Typography>
          <Button
            href={cloudAnalyticsText.GKE_COST_ALLOCATION_ENABLE_ALERT_HREF}
            sx={{ width: "fit-content" }}
            target="_blank"
            variant="contained"
          >
            Learn more
            <OpenNewIcon fontSize="small" sx={{ ml: 1 }} />
          </Button>
        </Stack>
      )}

      {state === "upgrade" && (
        <Stack
          direction="column"
          spacing={4}
          sx={{
            "&&": {
              mt: 2,
            },
          }}
        >
          <ContactSalesButton sendTo={accountManagers} loading={loading} />
        </Stack>
      )}
    </LensEnticement>
  );
};
