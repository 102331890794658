import EmailIcon from "@mui/icons-material/Email";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Link,
  Skeleton,
  type SxProps,
  type Theme,
  Typography,
} from "@mui/material";

import mixpanel from "../../utils/mixpanel";

export type KeyContactDetails = {
  email: string;
  avatarURL?: string;
  name: string;
  job: string;
};

type Props = {
  details: KeyContactDetails | null;
  sx?: SxProps<Theme>;
};

function hasDetails(details: KeyContactDetails | null): details is KeyContactDetails {
  return details !== null;
}

function extractInitials(name: string) {
  return name
    .split(/\s+/)
    .map((n) => n[0])
    .join("")
    .substring(0, 2);
}

export const KeyContact = ({ details, sx }: Props) => {
  const isLoading = !hasDetails(details);

  return (
    <Card sx={{ display: "flex", flexDirection: "column", ...sx }}>
      <CardHeader
        avatar={
          isLoading ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar src={details.avatarURL}>{extractInitials(details.name)}</Avatar>
          )
        }
        title={isLoading ? <Skeleton /> : details.name}
        titleTypographyProps={{ variant: "subtitle1", fontWeight: 500 }}
        subheader={isLoading ? <Skeleton /> : details.job}
        sx={{ flex: 1, alignItems: "flex-start" }}
      />
      <CardContent sx={{ pt: 0, pl: 9 }}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <Link
            display="flex"
            alignItems="center"
            href={`mailto:${details.email}`}
            color="inherit"
            sx={{ textDecoration: "none" }}
            target="_blank"
            onClick={() => {
              mixpanel.track("home.contacts.email.click", { $referrer: location.href });
            }}
          >
            <EmailIcon color="primary" sx={{ mr: 0.5 }} fontSize="small" />
            <Typography
              color="primary"
              variant="body2"
              sx={{ overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }}
            >
              {details.email}
            </Typography>
          </Link>
        )}
      </CardContent>
    </Card>
  );
};
