import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { useTheme } from "@mui/material/styles";

import AWSLogo from "../../../../assets/aws-logo.svg";
import AWSLogoDark from "../../../../assets/aws-logo-dark-mode.svg";
import GCPLogo from "../../../../assets/gcp-logo.svg";

export const CloudLogo = ({ type, size }: { type: FlexsaveType; size: number }) => {
  const theme = useTheme();

  let logo = GCPLogo;
  if (type === FlexsaveTypes.AWS) {
    logo = theme.palette.mode === "dark" ? AWSLogoDark : AWSLogo;
  }

  return <img src={logo} width={size} />;
};
