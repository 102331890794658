import { type ReactNode, useCallback, useMemo } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerTeamsChannels, useTestNotification } from "./hooks";

export const cyIds = {
  row: (id: string) => `row-${id}`,
  team: (id: string) => `teamsChannelRow-team-${id}`,
  channel: (id: string) => `teamsChannelRow-channel-${id}`,
  dateConnected: (id: string) => `teamsChannelRow-dateConnected-${id}`,
  testNotification: (id: string) => `teamsChannelRow-testNotification-${id}`,
  delete: (id: string) => `teamsChannelRow-delete-${id}`,
};

export type TeamsChannelRow = {
  id: string;
  team: string;
  channel: string;
  dateConnected: DateTime<true | false>;
};

export const MsTeamsTableRow = ({ row }) => {
  const { isDoitEmployee } = useAuthContext();
  const { sendTestNotification, testNotificationLoading } = useTestNotification(row);
  const { deleteChannel, deleteLoading } = useCustomerTeamsChannels();
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const dateConnected = useMemo<ReactNode>(() => {
    if (!row.dateConnected.isValid) {
      return <Typography variant="body2">No date provided</Typography>;
    }

    return <Typography variant="body2">{row.dateConnected.toLocaleString(DateTime.DATETIME_MED)}</Typography>;
  }, [row.dateConnected]);

  const handleDelete = useCallback<() => Promise<void>>(async () => {
    const delErrorMessage = await deleteChannel(row.id);
    if (delErrorMessage) {
      errorSnackbar(delErrorMessage);
      return;
    }
    successSnackbar("Channel deleted");
  }, [deleteChannel, errorSnackbar, row.id, successSnackbar]);

  const handleTestNotification = useCallback<() => Promise<void>>(async () => {
    const errorMessage = await sendTestNotification();
    if (errorMessage) {
      errorSnackbar(errorMessage);
      return;
    }
    successSnackbar("Test notification sent");
  }, [errorSnackbar, sendTestNotification, successSnackbar]);

  return (
    <>
      <TableCell data-cy={cyIds.team(row.id)}>
        <Typography variant="body2">{row.team}</Typography>
      </TableCell>
      <TableCell data-cy={cyIds.channel(row.id)}>
        <Typography variant="body2">{row.channel}</Typography>
      </TableCell>
      <TableCell data-cy={cyIds.dateConnected(row.id)}>{dateConnected}</TableCell>
      <TableCell>
        <LoadingButton
          variant="text"
          onClick={handleTestNotification}
          loading={testNotificationLoading}
          disabled={isDoitEmployee}
          mixpanelEventId="integrations.teams.test-notification"
          data-cy={cyIds.testNotification(row.id)}
        >
          Send test notification
        </LoadingButton>
      </TableCell>
      <TableCell>
        <LoadingButton
          variant="text"
          onClick={handleDelete}
          loading={deleteLoading}
          disabled={isDoitEmployee}
          mixpanelEventId="integrations.teams.delete-channel"
          data-cy={cyIds.delete(row.id)}
        >
          <DeleteIcon fontSize="small" color={isDoitEmployee ? "disabled" : "error"} />
        </LoadingButton>
      </TableCell>
    </>
  );
};
