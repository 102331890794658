import { useCallback, useMemo, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import { globalText } from "../../../../../assets/texts";
import { useFullScreen } from "../../../../../utils/dialog";
import { type BillingProfileInvoiceBucket } from "../../../BillingProfileForm.models";

type FormData = {
  name: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(1).max(28).label("Bucket name").trim(),
});

export type BucketDialogProps = {
  initialValues?: FormData;
  open?: boolean;
  title?: string;
  ctaText?: string;
  onClose?: () => void;
  onSubmit?: (formData: FormData) => void;
};

const DEFAULT_PROPS: Required<BucketDialogProps> = {
  initialValues: { name: "" },
  open: false,
  title: "",
  ctaText: "",
  onClose: () => {},
  onSubmit: () => {},
};

export const BucketDialog = (props: BucketDialogProps) => {
  const { ctaText, initialValues, open, onClose, onSubmit, title } = { ...DEFAULT_PROPS, ...props };
  const { fullScreen } = useFullScreen("sm");
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
    >
      <Formik<FormData>
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(formData) => {
          onClose();
          onSubmit(formData);
        }}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <TextField
                name="name"
                helperText={errors.name || undefined}
                error={Boolean(errors.name)}
                label="Invoice bucket name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                sx={{ marginTop: 1 }}
              />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="text" onClick={onClose}>
                {globalText.CANCEL}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {ctaText}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export function useBucketDialogPropsGenerator(
  createBucket: (formData: FormData) => void,
  setBucketName: (bucket: BillingProfileInvoiceBucket, name: string) => void
) {
  const [bucketDialogProps, setBucketDialogProps] = useState<BucketDialogProps>({
    open: false,
    onClose: () => {
      setBucketDialogProps((current) => ({ ...current, open: false }));
    },
  });

  const createBucketDialogProps = useMemo(
    (): BucketDialogProps => ({
      ctaText: "Create new invoice bucket",
      initialValues: {
        name: "",
      },
      onSubmit: createBucket,
      open: true,
      title: "Create new invoice bucket",
    }),
    [createBucket]
  );

  const editBucketDialogProps = useMemo(
    (): BucketDialogProps => ({
      ctaText: "Edit name",
      title: "Edit invoice bucket name",
      open: true,
    }),
    []
  );

  const openBucketDialogInCreationMode = useCallback(() => {
    setBucketDialogProps((current) => ({ ...current, ...createBucketDialogProps }));
  }, [createBucketDialogProps]);

  const openBucketDialogInEditionMode = useCallback(
    (bucket: BillingProfileInvoiceBucket) => {
      setBucketDialogProps((current) => ({
        ...current,
        ...editBucketDialogProps,
        initialValues: { name: bucket.name },
        onSubmit: ({ name }) => {
          setBucketName(bucket, name);
        },
      }));
    },
    [editBucketDialogProps, setBucketName]
  );

  return {
    bucketDialogProps,
    openBucketDialogInCreationMode,
    openBucketDialogInEditionMode,
  };
}
