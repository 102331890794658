import {
  type ForecastMode,
  type ForecastSettings,
  type TimeInterval,
  TimeInterval as TimeIntervalEnum,
} from "@doitintl/cmp-models";
import capitalize from "lodash/capitalize";

import { colsMap } from "../../../../CloudAnalytics/generateReport/utils";
import { getTimeSettings, type TimeRangeOption } from "../../../../CloudAnalytics/utilities";

export const forecastSettingsLimits: Record<
  "historicalTimeIntervals" | "futureTimeIntervals",
  Record<TimeInterval, Record<"min" | "max", number>>
> = {
  historicalTimeIntervals: {
    [TimeIntervalEnum.HOUR]: {
      min: 2,
      max: 1000,
    },
    [TimeIntervalEnum.DAY]: {
      min: 2,
      max: 500,
    },
    [TimeIntervalEnum.WEEK]: {
      min: 2,
      max: 100,
    },
    [TimeIntervalEnum.MONTH]: {
      min: 2,
      max: 36,
    },
    [TimeIntervalEnum.QUARTER]: {
      min: 2,
      max: 12,
    },
    [TimeIntervalEnum.YEAR]: {
      min: 2,
      max: 6,
    },
  },
  futureTimeIntervals: {
    [TimeIntervalEnum.HOUR]: {
      min: 1,
      max: 1000,
    },
    [TimeIntervalEnum.DAY]: {
      min: 1,
      max: 100,
    },
    [TimeIntervalEnum.WEEK]: {
      min: 1,
      max: 52,
    },
    [TimeIntervalEnum.MONTH]: {
      min: 1,
      max: 12,
    },
    [TimeIntervalEnum.QUARTER]: {
      min: 1,
      max: 4,
    },
    [TimeIntervalEnum.YEAR]: {
      min: 1,
      max: 3,
    },
  },
};

export const getValidatedForecastIntervals = (
  name: "historicalTimeIntervals" | "futureTimeIntervals",
  timeInterval: TimeInterval,
  value: number
) => {
  if (value < forecastSettingsLimits[name][timeInterval].min) {
    value = forecastSettingsLimits[name][timeInterval].min;
  } else if (value > forecastSettingsLimits[name][timeInterval].max) {
    value = forecastSettingsLimits[name][timeInterval].max;
  }
  return value;
};

export const getHistoricalIntervalsFromTimeSettings = (
  timeRangeOption: TimeRangeOption,
  timeInterval: TimeInterval
) => {
  const { from, to } = getTimeSettings(timeRangeOption);
  const diff = to.diff(from, timeInterval).toObject();
  const units = `${timeInterval}s`;
  return Math.round(diff[units]);
};

export const getDefaultForecastSettings = (
  timeRangeOption: TimeRangeOption,
  timeInterval: TimeInterval,
  mode?: ForecastMode
): ForecastSettings => {
  const historicalIntervals = getHistoricalIntervalsFromTimeSettings(timeRangeOption, timeInterval);
  return {
    historicalTimeIntervals: getValidatedForecastIntervals(
      "historicalTimeIntervals",
      timeInterval,
      historicalIntervals
    ),
    futureTimeIntervals: getValidatedForecastIntervals("futureTimeIntervals", timeInterval, historicalIntervals),
    mode: mode ?? "totals",
  };
};

export const getHistoricalIntervalsLabel = (timeInterval: TimeInterval) =>
  `Number of historical ${timeInterval}s used to produce the forecast`;
export const getFutureIntervalsLabel = (timeInterval: TimeInterval) =>
  `Number of future ${timeInterval}s you'd like to forecast`;

export const getTimeDimensionsTooltipText = (timeInterval: TimeInterval) => {
  const defaultTimeDimensions = colsMap[timeInterval].map((dims) => dims.split(":")[1]);
  return `${capitalize(timeInterval)} forecast is only available with time dimensions: ${defaultTimeDimensions.join(", ")}`;
};
