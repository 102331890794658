import { useCallback } from "react";

import noop from "lodash/noop";
import { DateTime } from "luxon";

import { exportCSVFile } from "../../../utils/csv";
import { type RampPlanModel } from "../types";
import { rampsObjToCsvFormat } from "../utils";
import getAllPeriodsArr from "./PeriodTables/Tables/getAllPeriodsArr";
import { type PeriodObj } from "./PeriodTables/Tables/PeriodList";

type periodForCsvObj = {
  headers: string[];
  plannedRow: string[];
  actualRow: string[];
};

const decimalOrZero = (num: number): number | string => (num === 0 ? 0 : num.toFixed(2));

const totalOf = (data: number[]) =>
  data
    .reduce((sum, val) => {
      if (!isNaN(val)) {
        return sum + val;
      } else {
        return sum;
      }
    }, 0)
    .toFixed(0);

export const rampPeriodsDataForCSV = (periods: PeriodObj[]) => {
  const periodsForCsv: periodForCsvObj[] = [];
  const periodsArr: PeriodObj[] = [...periods];
  const periodTablesToCreate = periodsArr[0].periodMonthsArr.length > 1 ? periodsArr : [periodsArr[0]];

  [...periodTablesToCreate].forEach((table: PeriodObj) => {
    const { periodStart, periodMonthsArr, periodPlanData, periodActualData } = table;
    // creating period table rows to be filled below, if not already created
    if (!periodsForCsv[table.periodNum - 1]) {
      periodsForCsv[table.periodNum - 1] = {
        headers: [`Period ${table.periodNum}`],
        plannedRow: ["Plan"],
        actualRow: ["Actual"],
      };
      if (
        typeof periodMonthsArr?.[table.periodNum - 1] !== "undefined" &&
        typeof periodStart?.seconds !== "undefined"
      ) {
        periodMonthsArr[table.periodNum - 1].forEach((_item, index) => {
          const planVal = periodPlanData[index] && decimalOrZero(periodPlanData[index]);
          const actualVal = periodActualData[index] && decimalOrZero(periodActualData[index]);
          // into header row
          const headerText = DateTime.fromSeconds(periodStart?.seconds)
            .plus({ months: index })
            .toFormat("d MMM yy")
            .toString();
          // add the data:
          // into headers row
          periodsForCsv[table.periodNum - 1]?.headers.push(headerText);
          // into planned row
          periodsForCsv[table.periodNum - 1]?.plannedRow.push(`$${planVal}`);
          // into actuals row
          periodsForCsv[table.periodNum - 1]?.actualRow.push(`$${actualVal}`);
        });

        periodsForCsv[table.periodNum - 1]?.headers.push("Totals");
        periodsForCsv[table.periodNum - 1]?.plannedRow.push(`$${totalOf(periodPlanData).toString()}`);
        periodsForCsv[table.periodNum - 1]?.actualRow.push(`$${totalOf(periodActualData).toString()}`);
        // done creating period table
      }
    }
  });
  return periodsForCsv;
};

export function useRampPlanCsvExport(planData: RampPlanModel) {
  return useCallback(() => {
    const periods: PeriodObj[] = getAllPeriodsArr(planData, noop);
    const todayDate: string = DateTime.utc().toFormat("dd-MM-yyyy");
    const fileName = `ramp-plan-${planData.name}-${todayDate}`;
    const periodsForCsv = rampPeriodsDataForCSV(periods);
    exportCSVFile(null, periodsForCsv, fileName, rampsObjToCsvFormat);
  }, [planData]);
}
