import React from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

import { allTimezones } from "../allTimeZones";
import { RequiredLabel } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function TimeZoneInput({ disabled }: SubscriptionInputProps) {
  const [timeZone, meta] = useField<SubscriptionFormValues["timeZone"]>("timeZone");
  const { setFieldValue, values } = useFormikContext<SubscriptionFormValues>();

  return (
    <Autocomplete
      options={allTimezones}
      value={timeZone.value}
      onChange={(_event, newValue) => {
        if (!newValue) {
          return;
        }
        setFieldValue("timeZone", newValue);
        setFieldValue("time", values.time.setZone(newValue, { keepLocalTime: true }));
        setFieldValue("startDate", values.startDate.setZone(newValue, { keepLocalTime: true }));
      }}
      renderInput={(params) => (
        <TextField {...params} label={RequiredLabel("Time zone")} variant="outlined" error={!!meta.error} fullWidth />
      )}
      fullWidth
      disabled={disabled}
    />
  );
}
