import { useCallback, useEffect, useMemo, useState } from "react";

import {
  AssetModel,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudProject,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
  CustomerSecurityMode,
} from "@doitintl/cmp-models";
import { type DocumentSnapshotModel, getCollection, useCollectionData } from "@doitintl/models-firestore";

import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type AssetDescriptor } from "./BillingProfileForm.models";

const assetTypesForRestrictedCustomer: AssetType[] = [
  AssetTypeAmazonWebServices,
  AssetTypeGSuite,
  AssetTypeGoogleCloud,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
];

const assetTypes = assetTypesForRestrictedCustomer.concat([AssetTypeGoogleCloudProject]);

const EMPTY_ASSETS_COLLECTION: AssetDescriptor[] = [];

function useEntityAssetsQueryAndTransform(entity: Entity) {
  const { customer } = useCustomerContext();

  const query = useMemo(
    () =>
      getCollection(AssetModel)
        .where("entity", "==", entity.ref)
        .where("customer", "==", customer.ref)
        .where(
          "type",
          "in",
          customer.securityMode === CustomerSecurityMode.RESTRICTED ? assetTypesForRestrictedCustomer : assetTypes
        ),
    [customer.ref, customer.securityMode, entity.ref]
  );

  const transform = useCallback(
    (asset: AssetModel, snap: DocumentSnapshotModel<AssetModel>): AssetDescriptor => ({
      data: {
        type: asset.type,
        properties: asset.properties,
      },
      bucketId: asset.bucket?.id ?? null,
      id: snap.id,
    }),
    []
  );

  return [query, transform] as const;
}

export function useEntityAllowedAssetsCollection(entity: Entity): [AssetDescriptor[], boolean] {
  const [query, transform] = useEntityAssetsQueryAndTransform(entity);
  const [assetDescriptors, isLoading] = useCollectionData(query, {
    transform,
  });

  return [assetDescriptors ?? EMPTY_ASSETS_COLLECTION, isLoading];
}

export function useEntityAllowedAssetsCollectionOnce(entity: Entity): [AssetDescriptor[], boolean] {
  const [assetDescriptors, setAssetDescriptors] = useState(EMPTY_ASSETS_COLLECTION);
  const [query, transform] = useEntityAssetsQueryAndTransform(entity);
  const [hasBeenLoaded, setHasBeenLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setHasBeenLoaded(false);
      const data = await query.get();
      setAssetDescriptors(
        data.size === 0 ? EMPTY_ASSETS_COLLECTION : data.docs.map((doc) => transform(doc.asModelData(), doc))
      );
      setHasBeenLoaded(true);
    })();
  }, [query, transform]);

  return [assetDescriptors, hasBeenLoaded];
}
