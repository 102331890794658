import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { globalText, googleCloudBillingAccountAdminTexts } from "../../../../assets/texts";
import LoadingButton from "../../../../Components/LoadingButton";

type RemoveDoitAdminsDialogProps = {
  doitAdminsUsers: string[];
  isFullScreen: boolean;
  isLoading: boolean;
  isValid: boolean;
  onClose: () => void;
  onRemove: () => void;
};

export function RemoveDoitAdminsDialog({
  doitAdminsUsers,
  isFullScreen,
  onRemove,
  onClose,
  isValid,
  isLoading,
}: RemoveDoitAdminsDialogProps) {
  return (
    <Dialog
      open={true}
      aria-labelledby="remove-non-customer-admins-title"
      onClose={onClose}
      fullScreen={isFullScreen}
      fullWidth
      maxWidth={isFullScreen ? "xs" : "sm"}
    >
      <DialogTitle data-cy="removeDoitEmailsDialogTitle" id="remove-non-customer-admins-title">
        {googleCloudBillingAccountAdminTexts.REMOVE_NON_CUSTOMER_ADMINS_TITLE}
      </DialogTitle>
      <DialogContent>
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
          }}
          subheader={googleCloudBillingAccountAdminTexts.REMOVE_NON_CUSTOMER_ADMINS_SUBTITLE}
        >
          {doitAdminsUsers.map((user) => (
            <ListItem
              key={user}
              sx={{
                display: "list-item",
                mb: -2,
              }}
            >
              <ListItemText primary={user.replace("user:", "")} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoadingButton
          data-cy="removeDoitEmailsDialogOkay"
          color="primary"
          variant="contained"
          onClick={onRemove}
          loading={isLoading}
          disabled={isLoading || !isValid}
          sx={{ textTransform: "unset" }}
          mixpanelEventId="assets.remove-doit-emails.ok"
        >
          {globalText.OKAY}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
