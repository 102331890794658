import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

import { type ManualPurchaseItem, type UniqueCustomerValues } from "../types";
import { hasZeroCost } from "../utils/manualPurchaseUtils";
import { PurchaseForm } from "./PurchaseForm";

type Props = {
  selectedItems: ManualPurchaseItem[];
  customerSkus?: UniqueCustomerValues;
  onAdd: () => void;
  onDelete: (id: string) => void;
  onChange: (purchaseItem: ManualPurchaseItem) => void;
  loading: boolean;
};
export const ManualPurchaseWrapper = ({ customerSkus, selectedItems, onChange, onAdd, onDelete, loading }: Props) => (
  <>
    {customerSkus &&
      selectedItems.map((item: ManualPurchaseItem, i: number) => (
        <PurchaseForm
          customerSku={customerSkus}
          skuNum={i + 1}
          purchaseItem={item}
          key={item.id}
          onChange={onChange}
          onDelete={onDelete}
          disableDelete={selectedItems.length <= 1}
          loading={loading}
        />
      ))}
    <Button
      color="primary"
      startIcon={<AddIcon />}
      onClick={onAdd}
      sx={{ mt: 4, mb: 10 }}
      disabled={hasZeroCost(selectedItems) || loading}
    >
      Add another SKU
    </Button>
  </>
);
