import { useMemo } from "react";

import { Error } from "@mui/icons-material";
import { Stack, TableCell, Tooltip, Typography } from "@mui/material";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { formatNumber, formatStringDate } from "../../../utils/common";
import { deleteProcessingMinutes, isAnySelectedUpdatedWithinLastXMinutes } from "../utils";
import { type BatchItem } from "./DatasetDetails";

interface Props {
  data: BatchItem;
  handleDelete: (dataset: BatchItem) => void;
}

const DatasetDetailsRow = ({ data, handleDelete }: Props) => {
  const deleteDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes([data], "createdAt", deleteProcessingMinutes),
    [data]
  );

  const threeDotsMenuOptions = useMemo(() => {
    const options = [
      {
        label: <Typography>{datahubTxt.DELETE}</Typography>,
        action: () => {
          handleDelete(data);
        },
        key: datahubTxt.DELETE,
        disabled: deleteDisabled,
        tooltip: deleteDisabled ? datahubTxt.DATASET_RECENTLY_INGESTED : undefined,
      },
    ];
    return options.filter((item) => !!item);
  }, [data, deleteDisabled, handleDelete]);

  const recordsValue = () => {
    if (data.createdAt) {
      const { createdAt, eventsSubmitted, eventsProcessed, eventsDeleted } = data;
      const batchCreateAtDate = new Date(createdAt);
      const batchProcessingTime = new Date(Date.now() - 15 * 60 * 1000);

      if (eventsDeleted) {
        return (
          <Typography variant="body2" component="span" sx={{ borderBottom: "1px dashed" }}>
            {datahubTxt.PARTIALLY_DELETED}
          </Typography>
        );
      } else if (eventsProcessed + eventsDeleted !== eventsSubmitted) {
        // the data is not updated from big query in the BE
        if (batchProcessingTime < batchCreateAtDate && batchCreateAtDate <= new Date()) {
          return (
            <Typography variant="body2" component="span" sx={{ borderBottom: "1px dashed" }}>
              {datahubTxt.PROCESSING}
            </Typography>
          );
        } else {
          return (
            <Stack flexDirection="row" alignItems="center" gap={0.5}>
              <Tooltip
                title={datahubTxt.DATA_SET_UPLOAD_ERROR_TOOLTIP}
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: 200,
                    },
                  },
                }}
              >
                <Error
                  color="error"
                  sx={{
                    width: 16,
                    height: 16,
                  }}
                />
              </Tooltip>
              <Typography variant="body2" color="error">
                {datahubTxt.ERROR}
              </Typography>
            </Stack>
          );
        }
      } else {
        return formatNumber(data.eventsSubmitted);
      }
    }
  };

  return (
    <>
      <TableCell>{data.batch}</TableCell>
      <TableCell>{data.origin.toUpperCase()}</TableCell>
      <TableCell>{recordsValue()}</TableCell>
      <TableCell>{data.createdBy}</TableCell>
      <TableCell>{formatStringDate(data.createdAt)}</TableCell>
      <TableCell padding="none">
        <ThreeDotsMenu options={threeDotsMenuOptions} disabled={false} />
      </TableCell>
    </>
  );
};

export default DatasetDetailsRow;
