import { useCallback } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

const useDomains = (): {
  domainAlreadyExists: (domain: string) => Promise<boolean>;
} => {
  const domainAlreadyExists = useCallback<(domain: string) => Promise<boolean>>(async (domain) => {
    const querySnapshot = await getCollection(CustomerModel).where("domains", "array-contains", domain).limit(1).get();

    return !querySnapshot.empty;
  }, []);

  return { domainAlreadyExists };
};

export default useDomains;
