import { useEffect, useMemo } from "react";

import { useLocation, useParams } from "react-router-dom";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { getOrgEntities } from "../IAM/Organizations/Utils";
import { InvoiceDetails } from "./InvoiceDetails/InvoiceDetails";
import { InvoicesList } from "./InvoicesList/InvoicesList";
import { type Invoice, type InvoiceFilters } from "./types";
import { useInvoices } from "./useInvoices";

export const Invoices = ({ pageId }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customer, userOrganization } = useCustomerContext();

  const params = useParams<{ ivnum: string; eid: string }>();

  const showOverdue = query.get("overdue") !== null;
  const initialFilters: Partial<InvoiceFilters> | undefined = showOverdue
    ? {
        showPaid: false,
        pastPayDate: true,
      }
    : undefined;
  const { invoices, filters, setFilters, isFetching, invoicesProducts } = useInvoices(initialFilters);

  const orgEntities = useMemo<string[] | undefined>(
    () => getOrgEntities(customer.id, isDoitEmployee, userOrganization),
    [customer.id, isDoitEmployee, userOrganization]
  );

  const filteredInvoices = useMemo<Invoice[]>(() => {
    if (orgEntities?.length) {
      return invoices.filter((invoice) => invoice.entity.id === orgEntities[0]);
    } else {
      return invoices;
    }
  }, [invoices, orgEntities]);

  useEffect(() => {
    const orgEntities = getOrgEntities(customer?.id, isDoitEmployee, userOrganization);
    if (orgEntities?.length) {
      setFilters({
        products: [],
        entityId: orgEntities[0],
      });
    }
  }, [customer?.id, isDoitEmployee, setFilters, userOrganization]);

  return (
    <>
      {pageId === "invoices" && (
        <InvoicesList
          invoices={filteredInvoices}
          setFilters={setFilters}
          filters={filters}
          isFetching={isFetching}
          invoicesProducts={invoicesProducts}
          orgEntities={orgEntities}
        />
      )}
      {pageId === "invoices:details" && (
        <InvoiceDetails invoices={invoices} entityId={params.eid} ivnum={params.ivnum} />
      )}
    </>
  );
};
