import { useMemo } from "react";

import { type ApprovalsItem, type CustomerInfo, type GroupedWorkloads, type Workload } from "../types";
import { createRiskApprovalPurchase } from "../utils/workloadUtils";
import { useDebounce } from "./useDebounce";

export function useApprovals(
  groupedWorkloadsMap?: GroupedWorkloads,
  enabledCustomerConfigsMap?: Map<string, CustomerInfo>
) {
  const debouncedWorkloads = useDebounce(groupedWorkloadsMap);
  return useMemo<ApprovalsItem[] | undefined>(() => {
    if (debouncedWorkloads && enabledCustomerConfigsMap) {
      const approvalItems: ApprovalsItem[] = [];
      for (const [customerId] of debouncedWorkloads.entries()) {
        const customerWorkloads = Array.from(
          debouncedWorkloads.get(customerId)?.workloadsMap.values() ?? ([] as Workload[])
        );
        const customerInfo = enabledCustomerConfigsMap.get(customerId);
        approvalItems.push(...createRiskApprovalPurchase(customerWorkloads, customerInfo));
      }
      return approvalItems;
    }
  }, [debouncedWorkloads, enabledCustomerConfigsMap]);
}
