import { useParams } from "react-router-dom";

import { default as costAnomalySubscriptionSuccessImg } from "../../../assets/flexsave/marketplace-cost-anomaly-success.svg";
import { default as saasConsoleMarketPlaceSuccessDark } from "../../../assets/standalone/gcp-mp-success-dark.svg";
import { default as saasConsoleMarketPlaceSuccessLight } from "../../../assets/standalone/gcp-mp-success-light.svg";
import { useCustomerRouteNavigation } from "../../../Components/hooks/useCustomerRouteNavigation";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { getMarketplaceSuccessImage } from "../../../utils/assetResolver";
import { SubscriptionSuccessMsg } from "./SubscriptionSuccessMsg";
import { CustomerPageUrl, MarketplaceProduct } from "./type";

type MarketplaceSuccessParams = {
  product: MarketplaceProduct;
};

export const MarketplaceSuccessGCP = () => {
  const { product } = useParams<MarketplaceSuccessParams>();
  const navigateToCustomerRoute = useCustomerRouteNavigation();
  const isDark = useDarkThemeCheck();

  const { customer, isProductOnlyCustomer } = useCustomerContext();
  const isSaaSConsoleGCPEnabled = !!customer.enabledSaaSConsole?.GCP;

  const SuccessMessage = () => {
    switch (product) {
      case MarketplaceProduct.FLEXSAVE:
        return (
          <SubscriptionSuccessMsg
            marketplaceSuccessImg={getMarketplaceSuccessImage()}
            subscriptionSuccessMsgSubtitle="You have subscribed to Flexsave via the Google Cloud Marketplace"
            linkText={isProductOnlyCustomer ? "Continue setting up Flexsave" : "Go to Flexsave GCP"}
            onLinkClick={() => {
              navigateToCustomerRoute(CustomerPageUrl.FLEXSAVE);
            }}
          />
        );
      case MarketplaceProduct.COST_ANOMALY_DETECTION:
        return (
          <SubscriptionSuccessMsg
            marketplaceSuccessImg={costAnomalySubscriptionSuccessImg}
            subscriptionSuccessMsgSubtitle="You have subscribed to Cost Anomalies via the Google Cloud Marketplace"
            linkText="Go to GCP Lens"
            onLinkClick={() => {
              navigateToCustomerRoute(CustomerPageUrl.GCP_LENS);
            }}
          />
        );
      case MarketplaceProduct.SAAS_CONSOLE:
        return (
          <SubscriptionSuccessMsg
            marketplaceSuccessImg={isDark ? saasConsoleMarketPlaceSuccessDark : saasConsoleMarketPlaceSuccessLight}
            subscriptionSuccessMsgSubtitle="You have subscribed to DoiT Console via the Google Cloud Marketplace"
            linkText={isSaaSConsoleGCPEnabled ? "" : "Connect your cloud"}
            onLinkClick={
              isSaaSConsoleGCPEnabled
                ? () => {}
                : () => {
                    navigateToCustomerRoute(CustomerPageUrl.SAAS_CONSOLE_GCP);
                  }
            }
          />
        );
      default:
        return null;
    }
  };

  return <SuccessMessage />;
};
