export const getStackedAreaChartOptions = (theme, reversed, formatter) => ({
  chart: {
    type: "area",
    zoomType: "xy",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: true,
    maxHeight: 66,
    itemMarginTop: 4,
  },
  tooltip: {
    valueDecimals: 2,
  },
  yAxis: {
    title: {
      enabled: false,
    },
    stackLabels: {
      enabled: !reversed,
      style: {
        ...theme.typography.caption,
        color: theme.palette.text.primary,
        textOutline: 0,
      },
      formatter() {
        return formatter(this.total, true);
      },
    },
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  plotOptions: {
    area: {
      stacking: "normal",
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [],
});
