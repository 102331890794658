import { type AWSFlexsaveConfigurationModel, type FlexsaveRDS, type FlexsaveSageMaker } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { Container } from "@mui/material";
import { DateTime } from "luxon";

import { type FAQ } from "../../../Components/FAQ/FAQAccordion";
import { never } from "../../../utils";
import {
  applyingSavingsPlansSteps,
  checkingForOpportunitiesSteps,
  daysAfterEndOfMonthWhenWeShowInvoicingState,
  daysBeforeEndOfMonthWhenWeShowInvoicingState,
  generatingSavingsSteps,
  invoicingSteps,
  type JourneyStep,
  noSavingsOpportunitiesSteps,
} from "./constants";
import { DailySavingsChart } from "./DailySavingsChart";
import { MonthlySavingsChart } from "./MonthlySavingsChart";

export const getCustomerState = (
  data: WithFirebaseModel<AWSFlexsaveConfigurationModel> | undefined,
  isDisabledByFeatureFlag: boolean,
  now: Date = new Date()
): JourneyStep => {
  if (!data) {
    return "checkingForOpportunities";
  }

  if (isDisabledByFeatureFlag || data.timeDisabled) {
    return "disabled";
  }

  if (data.reasonCantEnable) {
    switch (data.reasonCantEnable) {
      case "no spend":
      case "low spend":
        return "noSavingsOpportunities";
      case "aws activate credits":
        return "hasActivateCredits";
      case "no contract":
        return "noContract";
      case "other":
        return "unknown";
      default:
        return "disabled";
    }
  }

  if (data.enabled && !data.timeEnabled) {
    // customers preceding existence of timeEnabled flag are fully onboarded
    return "onboarded";
  }

  if (!data.timeEnabled || !data.enabled) {
    return "checkingForOpportunities";
  }

  const hasSavings =
    Object.values(data?.dailySavingsHistory ?? {}).some((item) => item.savings > 0) ||
    Object.values(data?.savingsHistory ?? {}).some((item) => item.savings > 0);

  if (!hasSavings) {
    return "applyingSavingsPlans";
  }

  const endOfMonth = DateTime.fromJSDate(data.timeEnabled.toDate()).endOf("month");

  if (DateTime.fromJSDate(now) < endOfMonth.minus({ days: daysBeforeEndOfMonthWhenWeShowInvoicingState })) {
    return "generatingSavings";
  }

  if (DateTime.fromJSDate(now) < endOfMonth.plus({ days: daysAfterEndOfMonthWhenWeShowInvoicingState })) {
    return "invoicing";
  }

  return "onboarded";
};

export const getStep = (step: JourneyStep): number | null => {
  switch (step) {
    case "unknown":
    case "disabled":
    case "hasActivateCredits":
      return null;
    case "noContract":
    case "noSavingsOpportunities":
    case "checkingForOpportunities":
      return 0;
    case "applyingSavingsPlans":
      return 1;
    case "generatingSavings":
      return 2;
    case "invoicing":
    case "onboarded":
      return 3;
    default:
      return never(step, 0);
  }
};

interface SavingsChartProps {
  journeyStep: JourneyStep;
  computeData: AWSFlexsaveConfigurationModel | undefined;
  rdsData: FlexsaveRDS | undefined;
  sageMakerData: FlexsaveSageMaker | undefined;
}

export const SavingsChart = ({ journeyStep, computeData, rdsData, sageMakerData }: SavingsChartProps) => {
  if (computeData) {
    if (["invoicing", "generatingSavings"].includes(journeyStep)) {
      return (
        <Container maxWidth="lg" sx={{ pt: 3 }}>
          <DailySavingsChart data={computeData} />
        </Container>
      );
    }

    if (journeyStep === "onboarded") {
      return (
        <Container maxWidth="lg" sx={{ pt: 3 }}>
          <MonthlySavingsChart computeData={computeData} rdsData={rdsData} sageMakerData={sageMakerData} />
        </Container>
      );
    }
  }

  return <Container />;
};

export const getFAQStep = (step: JourneyStep): FAQ[] => {
  switch (step) {
    case "unknown":
    case "disabled":
    case "noContract":
    case "hasActivateCredits":
    case "noSavingsOpportunities":
      return noSavingsOpportunitiesSteps;
    case "checkingForOpportunities":
      return checkingForOpportunitiesSteps;
    case "applyingSavingsPlans":
      return applyingSavingsPlansSteps;
    case "generatingSavings":
    case "onboarded":
      return generatingSavingsSteps;
    case "invoicing":
      return invoicingSteps;
    default:
      return never(step, []);
  }
};
