import { useLayoutEffect, useState } from "react";

const MIN_COLUMN_WIDTH = 350;

const events: (keyof WindowEventMap)[] = ["resize", "orientationchange"];

const getColumnTemplate = () => {
  const columnCount = Math.floor(window.innerWidth / MIN_COLUMN_WIDTH);
  return `repeat(${columnCount}, 1fr)`;
};

export function useGridColumnTemplate() {
  const [columnTemplate, setColumnTemplate] = useState(getColumnTemplate());

  useLayoutEffect(() => {
    const resizeListener = () => {
      setColumnTemplate(() => getColumnTemplate());
    };

    events.forEach((event) => {
      window.addEventListener(event, resizeListener);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resizeListener);
      });
    };
  }, []);

  return columnTemplate;
}
