import { useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { AvaOpeningSource, useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Stack, Typography } from "@mui/material";

import { homeTexts } from "../../../assets/texts";
import { AvaMixpanelCallbackFunc } from "../../../Components/Ava/utils";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../Navigation/core/utils";
import { FeaturedCard } from "./FeaturedCard";

type FeatureCardContentProps = {
  hasCloudAnalyticsPermission: boolean;
  hasAvaReportsGenerationPermission?: boolean;
};

export const FeatureCardContent = ({
  hasCloudAnalyticsPermission,
  hasAvaReportsGenerationPermission,
}: FeatureCardContentProps) => {
  const customerId = useCustomerId();
  const history = useHistory();
  const { handleOpenAvaDialog } = useAvaDialogContext();

  const reportsLink = customersRoute(customerId, "analytics/reports/create");
  const discoverLink = "https://www.doit.com/cloud-services-case-study-collection/";

  const link = useMemo(
    () => (hasCloudAnalyticsPermission ? reportsLink : discoverLink),
    [reportsLink, hasCloudAnalyticsPermission]
  );

  const title = useMemo(
    () => (hasCloudAnalyticsPermission ? homeTexts.FEATURED_CARD_TITLE : homeTexts.FEATURED_CARD_TITLE_NO_PERMISSION),
    [hasCloudAnalyticsPermission]
  );

  const buttonText = useMemo(
    () =>
      hasCloudAnalyticsPermission
        ? homeTexts.FEATURED_CARD_BUTTON_TEXT
        : homeTexts.FEATURED_CARD_BUTTON_TEXT_NO_PERMISSION,
    [hasCloudAnalyticsPermission]
  );

  const content = useMemo(() => {
    let text = homeTexts.FEATURED_CARD_CONTENT_NO_PERMISSION;
    if (hasCloudAnalyticsPermission) {
      if (hasAvaReportsGenerationPermission) {
        text = homeTexts.FEATURED_CARD_CONTENT_AVA;
      } else {
        text = homeTexts.FEATURED_CARD_CONTENT;
      }
    }

    return (
      <Typography variant="body1" lineHeight={2}>
        {text}
      </Typography>
    );
  }, [hasAvaReportsGenerationPermission, hasCloudAnalyticsPermission]);

  const handleClick = useCallback(() => {
    history.push(link);
  }, [history, link]);

  const handleAvaClick = useCallback(() => {
    handleOpenAvaDialog(AvaOpeningSource.NAV, AvaMixpanelCallbackFunc);
  }, [handleOpenAvaDialog]);

  const button = useMemo(
    () =>
      hasCloudAnalyticsPermission ? (
        <Stack spacing={1} direction="row">
          <Button variant="contained" color="primary" onClick={handleClick}>
            {buttonText}
          </Button>
          {hasAvaReportsGenerationPermission && (
            <Button variant="outlined" color="primary" onClick={handleAvaClick}>
              {homeTexts.ASK_AVA}
            </Button>
          )}
        </Stack>
      ) : (
        <Button variant="contained" color="primary" href={link} target="_blank" endIcon={<OpenInNewIcon />}>
          {buttonText}
        </Button>
      ),
    [hasCloudAnalyticsPermission, handleClick, buttonText, hasAvaReportsGenerationPermission, handleAvaClick, link]
  );

  return <FeaturedCard title={title} content={content} button={button} />;
};
