import { useEffect } from "react";

import { AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeMicrosoftAzure } from "@doitintl/cmp-models";

import { useGKEAdoption } from "../Components/hooks/cloudAnalytics/useGKEAdoption";
import { useIsCustomerUsingBQ } from "../Components/hooks/useBqLens";
import { useAuthContext } from "../Context/AuthContext";
import { useEntitiesContext } from "../Context/customer/EntitiesContext";
import { useCustomerContext } from "../Context/CustomerContext";
import { useUserContext } from "../Context/UserContext";
import { useFlexsaveAWSEnabled } from "../Pages/Flexsave/AWS/hooks";
import { getPermissionsNames } from "./common";
import { consoleErrorWithSentry } from "./errorHandling";
import { getUtmParams } from "./utmParams/utils";

const getCloudPlatforms = (assets?: string[], trialUser?: boolean) => {
  if (trialUser || !assets) {
    return [];
  }
  return assets.filter(
    (a) => a === AssetTypeGoogleCloud || a === AssetTypeAmazonWebServices || a === AssetTypeMicrosoftAzure
  );
};

export const SegmentIdentifyAndGroupApi = () => {
  const { partnerCompany, userId, domain, currentUser, isDoitPartner, isDoitEmployee } = useAuthContext();
  const { customer, trialUser, freeTrialActive, awsFeatures, contracts, isProductOnlyCustomer } = useCustomerContext({
    allowNull: true,
  });
  const { userModel: user, userRoles } = useUserContext();
  const { entities } = useEntitiesContext();
  const { isCustomerUsingBQ } = useIsCustomerUsingBQ();
  const [isFlexsaveEnabled, isFlexsaveLoading] = useFlexsaveAWSEnabled();
  const [gkeAdoption] = useGKEAdoption({ allowNullCustomer: true });

  useEffect(() => {
    if (!userId || !currentUser?.uid || !isDoitPartner) {
      return;
    }

    globalThis.analytics.identify(userId, {
      fullName: currentUser?.displayName,
      email: currentUser?.email,
      domain,
      partnerCompany,
    });
    globalThis.analytics.group(partnerCompany);
  }, [userId, isDoitPartner, domain, partnerCompany, currentUser?.displayName, currentUser?.email, currentUser?.uid]);

  useEffect(() => {
    if (!userId || !currentUser?.uid || !user?.id || !customer?.id || !userRoles || isFlexsaveLoading) {
      return;
    }

    if (awsFeatures === undefined) {
      return;
    }

    if (freeTrialActive === undefined) {
      return;
    }

    if (isCustomerUsingBQ === undefined) {
      return;
    }

    const cloudPlatforms = getCloudPlatforms(customer.assets, trialUser);

    const userPermissions = getPermissionsNames(Array.from(userRoles.permissions ?? [])).join(", ");
    const creditCardEntity = entities.find((entity) => entity.payment?.type === "credit_card");
    const sepaEntity = entities.find((entity) => entity.payment?.type === "sepa_debit");
    const { creationTime } = currentUser?.metadata ?? {};
    const userRegistrationDate = creationTime && new Date(creationTime);
    const createdAt = creationTime && new Date(creationTime).toISOString();

    const { utm_campaign, utm_source, utm_medium, utm_term, utm_content } = getUtmParams();
    globalThis.analytics.identify(
      userId,
      {
        firestoreId: user?.id,
        fullName: user?.displayName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        createdAt,
        isDoitEmployee,
        demographics: user?.enrichment,
        trialUser,
        product_subscribed: trialUser ? "FT" : "CMP",
        free_trial_active: freeTrialActive,
        flexAutoPilotEnabled: isFlexsaveEnabled,
        cloudPlatform: cloudPlatforms.join(", "),
        userPermissions,
        awsFeaturedEnabled: awsFeatures.join(", "),
        userSessionCount: user?.sessionCount ?? 1,
        userRegistrationDate,
        utm_campaign,
        utm_source,
        utm_medium,
        utm_term,
        utm_content,
        gcpCustomerSegment: customer.gcpCustomerSegment,
        multipleBillingProfiles: entities.length > 1,
        useSEPA: !!sepaEntity,
        useCC: !!creditCardEntity,
        creditCardEntityId: creditCardEntity?.id,
        customerId: customer.id,
        isCustomerUsingBQ,
        gkeMetering: gkeAdoption?.completedSuccesfully ?? false,
        isCommitted: !!contracts?.some((contract) => contract.isCommitment),
      },
      {
        integrations: {
          // Identify users in HubSpot only if they are trial or saas users
          HubSpot: trialUser || !!isProductOnlyCustomer,
        },
      }
    );

    globalThis.analytics.group(customer.id, {
      customerName: customer.name,
      domains: customer.domains,
      primaryDomain: customer.primaryDomain,
      classification: customer.classification,
      firmographics: customer.enrichment,
      trialEndDate: customer.trialEndDate,
    });
  }, [
    freeTrialActive,
    trialUser,
    userRoles,
    awsFeatures,
    entities,
    isFlexsaveLoading,
    isFlexsaveEnabled,
    isDoitEmployee,
    userId,
    currentUser?.displayName,
    currentUser?.metadata,
    user?.id,
    user?.displayName,
    user?.firstName,
    user?.lastName,
    user?.email,
    user?.enrichment,
    user?.sessionCount,
    customer?.id,
    customer?.assets,
    customer?.name,
    customer?.domains,
    customer?.primaryDomain,
    customer?.classification,
    customer?.enrichment,
    customer?.trialEndDate,
    currentUser?.uid,
    customer?.gcpCustomerSegment,
    isCustomerUsingBQ,
    gkeAdoption?.completedSuccesfully,
    contracts,
    isProductOnlyCustomer,
  ]);

  return null;
};

export enum SegmentEvents {
  SignUp = "signup",
  StandaloneSignUp = "standaloneSignup",
  RejectedRegistration = "rejectedRegistration",
  TalkToSales = "talkToSales",
}

// track
export const segmentTrack = (eventName: SegmentEvents, props?: any) => {
  try {
    globalThis.analytics.track(eventName, props);
  } catch (e) {
    consoleErrorWithSentry(e);
  }
};

export const segmentIdentify = (uid: string, props?: any) => {
  try {
    globalThis.analytics.identify(uid, props || {});
  } catch (e) {
    consoleErrorWithSentry(e);
  }
};
