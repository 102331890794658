import {
  createContext,
  type Dispatch,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";

import { TemplateVisibility } from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";

import { useAuthContext } from "../../../Context/AuthContext";
import { type IAsset } from "../../../types";
import { useQuery } from "../../../utils/useQuery";
import { TemplateCloudDefaultOptions, TemplatesCategories } from "../templateLibrary/utils";
import { useReportTemplateContext } from "./ReportTemplateContext";

// reducer
type Category = {
  label: string;
  selected: boolean;
};

export type CreateTemplateData = {
  categories: Category[];
  clouds: IAsset[];
  description: string;
  hasUnsavedChanges: boolean;
  isCreateTemplateOpen: boolean;
  isValid: boolean;
  name: string;
  visibility: TemplateVisibility;
};

export enum CreateTemplateDataKind {
  UPDATE_CATEGORIES = "update_categories",
  UPDATE_CLOUDS = "update_clouds",
  ON_CANCEL = "on_cancel",
  ON_SUBMIT = "on_submit",
}

export type CreateTemplateDataAction = {
  payload: Partial<CreateTemplateData>;
  type?: CreateTemplateDataKind;
};

const DEFAULT_CATEGORIES = TemplatesCategories.map((category) => ({ label: category, selected: false }));

const initial = (): CreateTemplateData => ({
  categories: DEFAULT_CATEGORIES,
  clouds: TemplateCloudDefaultOptions,
  description: "",
  hasUnsavedChanges: false,
  isCreateTemplateOpen: false,
  isValid: false,
  name: "",
  visibility: TemplateVisibility.PRIVATE,
});

const createTemplateReducer = (state: CreateTemplateData, action: CreateTemplateDataAction): CreateTemplateData => {
  switch (action.type) {
    case CreateTemplateDataKind.UPDATE_CATEGORIES: {
      const prevCategories = state.categories.map((item) => ({ ...item }));
      prevCategories.forEach((t) => {
        t.selected = false;
      });
      action.payload.categories?.forEach((v) => {
        const curr = prevCategories.find((t) => t.label === v.label);
        if (curr) {
          curr.selected = true;
        }
      });
      return { ...state, categories: prevCategories };
    }
    case CreateTemplateDataKind.UPDATE_CLOUDS: {
      const prevClouds = state.clouds.map((item) => ({ ...item }));
      prevClouds.forEach((t) => {
        t.selected = false;
      });
      action.payload.clouds?.forEach((c) => {
        const curr = prevClouds.find((t) => t.key === c.key);
        if (curr) {
          curr.selected = true;
        }
      });
      return { ...state, clouds: prevClouds };
    }
    case CreateTemplateDataKind.ON_CANCEL: {
      return initial();
    }
    case CreateTemplateDataKind.ON_SUBMIT: {
      return { ...state, hasUnsavedChanges: false };
    }
    default:
      return {
        ...state,
        ...action.payload,
      };
  }
};

const checkCreateTemplateDataEquality = (data: CreateTemplateData, newData: CreateTemplateData) => {
  if (
    data.name.trim() !== newData.name.trim() ||
    data.description.trim() !== newData.description.trim() ||
    !isEqual(data.clouds, newData.clouds) ||
    !isEqual(data.categories, newData.categories) ||
    data.visibility !== newData.visibility
  ) {
    return false;
  }
  return true;
};

type CreateTemplateContextType = {
  createTemplateData: CreateTemplateData;
  dispatchCreateTemplateData: Dispatch<CreateTemplateDataAction>;
};

export const CreateTemplateContext = createContext<CreateTemplateContextType>({
  createTemplateData: initial(),
  dispatchCreateTemplateData: (_: CreateTemplateDataAction) => {},
});

export const CreateTemplateContextProvider = ({ children }: { children?: ReactNode }) => {
  const [createTemplateData, dispatchCreateTemplateData] = useReducer(createTemplateReducer, initial());
  const { reportTemplateCards, reportTemplateId } = useReportTemplateContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const prevCreateTemplateData = useRef(createTemplateData);
  const query = useQuery();
  const editMode = query.get("edit");
  const createTemplateDataProviderValue = useMemo(
    () => ({ createTemplateData, dispatchCreateTemplateData }),
    [createTemplateData, dispatchCreateTemplateData]
  );

  useEffect(() => {
    const { hasUnsavedChanges } = createTemplateData;
    const createTemplateDataIsEqual = checkCreateTemplateDataEquality(
      prevCreateTemplateData.current,
      createTemplateData
    );
    const updatedHasChangesValue = hasUnsavedChanges === createTemplateDataIsEqual ? !hasUnsavedChanges : null;
    if (updatedHasChangesValue !== null) {
      dispatchCreateTemplateData({
        payload: {
          hasUnsavedChanges: updatedHasChangesValue,
        },
      });
    }
  }, [createTemplateData]);

  useEffect(() => {
    const isValid =
      !!createTemplateData.name &&
      !!createTemplateData.description &&
      createTemplateData.categories.some((c) => c.selected) &&
      createTemplateData.clouds.some((c) => c.selected);

    if (createTemplateData.isValid !== isValid) {
      dispatchCreateTemplateData({ payload: { isValid } });
    }
  }, [createTemplateData]);

  useEffect(() => {
    if (editMode && reportTemplateId) {
      const reportTemplate = reportTemplateCards.find((rt) => rt.templateRefId === reportTemplateId);
      if (reportTemplate) {
        const {
          visibleVersion: { name, description, cloud, categories, visibility },
        } = reportTemplate;
        const payload = {
          categories: DEFAULT_CATEGORIES.map((dc) => ({
            label: dc.label,
            selected: categories.includes(dc.label),
          })),
          clouds: TemplateCloudDefaultOptions.map((at) => ({
            ...at,
            selected: cloud.includes(at.key),
          })),
          description,
          hasUnsavedChanges: false,
          isCreateTemplateOpen: true,
          isValid: true,
          name,
          visibility,
        };
        prevCreateTemplateData.current = payload;
        dispatchCreateTemplateData({ payload });
      }
    }
  }, [editMode, reportTemplateCards, reportTemplateId, currentUser.email]);

  return (
    <CreateTemplateContext.Provider value={createTemplateDataProviderValue}>{children}</CreateTemplateContext.Provider>
  );
};

export const useCreateTemplateContext = (): CreateTemplateContextType => useContext(CreateTemplateContext);
