import {
  type AWSFlexsaveConfigurationModel,
  type FlexsaveRDS,
  type FlexsaveSageMaker,
  type MonthSavings,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";

import { formatDecimalNumber } from "../../../utils/common";
import { type ActiveFlexsaveSavings, type EnabledSavings, type FlexsaveData } from "../types";
import {
  getDateByMM_YYYY,
  getDateByYYYYDashMMDashDD,
  getLastMonthDateFromMonthLabel,
  getMonthLabelFromMonthId,
} from "./dateUtils";

export const calculateSavingsRate = (savings: number, onDemandSpend: number): number =>
  onDemandSpend < 0.1 ? 0 : (savings / (onDemandSpend + savings)) * 100;

/**
 * Calculate savings only for months that has actual savings
 */
export const calculateSavingsRateForActiveMonths = (monthSavings: MonthSavings[]) => {
  let totalSavings = 0;
  let totalSpend = 0;

  monthSavings.forEach((monthSave) => {
    if (monthSave.savings > 0.1) {
      totalSavings += monthSave.savings;
      totalSpend += monthSave.onDemandSpend;
    }
  });
  return calculateSavingsRate(totalSavings, totalSpend);
};

function hasValidSavings(savings: ActiveFlexsaveSavings[], currentMonth: string): boolean {
  return !!(savings.length && savings[0].history.get(getMonthLabelFromMonthId(currentMonth)));
}

export const asyncConvertDataToCurrency = async (
  data: FlexsaveData,
  asyncCurrencyConvert: (cost: number, date?: Date) => Promise<number>
): Promise<FlexsaveData> => {
  const asyncConvertMonth = async (month: MonthSavings, date?: Date): Promise<MonthSavings> => ({
    onDemandSpend: await asyncCurrencyConvert(month.onDemandSpend, date),
    savings: await asyncCurrencyConvert(month.savings, date),
    totalOnDemandSpend: month.totalOnDemandSpend
      ? await asyncCurrencyConvert(month.totalOnDemandSpend, date)
      : undefined,
    savingsRate: month.savingsRate,
  });

  let canEnable: { type: "AWS" | "GCP"; nextMonth: MonthSavings } | undefined = undefined;
  if (data.potentialFlexSaveSavings) {
    canEnable = {
      type: data.potentialFlexSaveSavings.type,
      nextMonth: await asyncConvertMonth(data.potentialFlexSaveSavings.nextMonth),
    };
  }

  let enabled: EnabledSavings | undefined;

  if (data.enabled && hasValidSavings(data.enabled.savings, data.enabled.currentMonthId)) {
    const previousMonth = getDateByMM_YYYY(data.enabled.currentMonthId);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    const savings: ActiveFlexsaveSavings[] = await Promise.all(
      data.enabled.savings.map(async (currentSavings): Promise<ActiveFlexsaveSavings> => {
        const newHistory: [string, MonthSavings][] = await Promise.all(
          Array.from(currentSavings.history, async ([key, value]) => [
            key,
            await asyncConvertMonth(value, getLastMonthDateFromMonthLabel(key)),
          ])
        );
        return {
          currentMonth: await asyncConvertMonth(currentSavings.currentMonth),
          previousMonth:
            currentSavings.previousMonth && (await asyncConvertMonth(currentSavings.previousMonth, previousMonth)),
          history: new Map(newHistory),
          nextMonth: await asyncConvertMonth(currentSavings.nextMonth),
          type: currentSavings.type,
        };
      })
    );
    enabled = {
      currentMonthId: data.enabled.currentMonthId,
      months: data.enabled.months,
      savings,
      timeOptIn: data.enabled.timeOptIn,
      disableNotified: data.enabled.disableNotified,
    };
  }

  return {
    potentialFlexSaveSavings: canEnable,
    info: data.info,
    enabled,
  };
};

export const formatAsDollars = (cost: number, maxFracDigits = 0) => `$${formatDecimalNumber(cost, maxFracDigits)}`;

export const asyncApplyCurrencyConvertToMonthSavings = async (
  monthSavings: MonthSavings,
  asyncCurrencyConvert: (cost: number, date: Date) => Promise<number>,
  date?: Date
): Promise<MonthSavings> => ({
  ...monthSavings,
  savings: await asyncCurrencyConvert(monthSavings.savings, date || new Date()),
  onDemandSpend: await asyncCurrencyConvert(monthSavings.onDemandSpend, date || new Date()),
  totalOnDemandSpend: monthSavings.totalOnDemandSpend
    ? await asyncCurrencyConvert(monthSavings.totalOnDemandSpend, date || new Date())
    : 0,
});

const asyncConvertMonthSavings = async <TEmptyValue extends null | undefined>(
  savingsHistory: Record<string, MonthSavings> | TEmptyValue,
  asyncCurrencyConvert: (cost: number, date: Date) => Promise<number>,
  emptyValue: TEmptyValue,
  convertKeyToDate: (key: string) => Date
): Promise<Record<string, MonthSavings> | TEmptyValue> => {
  if (!savingsHistory) {
    return emptyValue;
  }

  const dataConverted = await Promise.all(
    Object.entries(savingsHistory).map(async ([key, value]) => [
      key,
      await asyncApplyCurrencyConvertToMonthSavings(value, asyncCurrencyConvert, convertKeyToDate(key)),
    ])
  );
  return Object.fromEntries(dataConverted);
};

export const asyncConvertDataToCurrencyAWS = async (
  data: WithFirebaseModel<AWSFlexsaveConfigurationModel>,
  asyncCurrencyConvert: (cost: number, date: Date) => Promise<number>
): Promise<WithFirebaseModel<AWSFlexsaveConfigurationModel>> => ({
  ...data,
  savingsHistory: await asyncConvertMonthSavings(data.savingsHistory, asyncCurrencyConvert, null, getDateByMM_YYYY),
  dailySavingsHistory: await asyncConvertMonthSavings(
    data.dailySavingsHistory,
    asyncCurrencyConvert,
    undefined,
    getDateByYYYYDashMMDashDD
  ),
  savingsSummary: data.savingsSummary?.nextMonth
    ? {
        ...data.savingsSummary,
        nextMonth: await asyncApplyCurrencyConvertToMonthSavings(data.savingsSummary.nextMonth, asyncCurrencyConvert),
      }
    : null,
});

export const asyncConvertData = async <T extends WithFirebaseModel<FlexsaveSageMaker> | WithFirebaseModel<FlexsaveRDS>>(
  data: T,
  asyncCurrencyConvert: (cost: number, date: Date) => Promise<number>
): Promise<T> => {
  const nextMonthSavings = data.savingsSummary?.nextMonthSavings
    ? await asyncCurrencyConvert(data.savingsSummary.nextMonthSavings, new Date())
    : 0;
  return {
    ...data,
    savingsHistory: await asyncConvertMonthSavings(data.savingsHistory, asyncCurrencyConvert, null, getDateByMM_YYYY),
    dailySavingsHistory: await asyncConvertMonthSavings(
      data.dailySavingsHistory,
      asyncCurrencyConvert,
      null,
      getDateByYYYYDashMMDashDD
    ),
    savingsSummary: {
      ...data.savingsSummary,
      nextMonthSavings,
    },
  };
};

export const getYearsToLoadAWS = (savings: Record<string, MonthSavings>, now: Date): number => {
  const currentYear = now.getFullYear();
  let latestDate = currentYear;

  Object.keys(savings).forEach((key) => {
    const [, currentDate] = key.split("_");

    if (Number(currentDate) < latestDate) {
      latestDate = Number(currentDate);
    }
  });

  return currentYear - latestDate + 1;
};
