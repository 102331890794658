import TableCell from "@mui/material/TableCell";
import { type SxProps, type Theme } from "@mui/system";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";

type Props = {
  options: ThreeDotsMenuOption[];
  style?: SxProps<Theme>;
  disabled?: boolean;
};

export const ThreeDotsMenuCell = ({ options, style, disabled = false }: Props) => (
  <TableCell sx={{ ...style }}>
    <ThreeDotsMenu options={options} disabled={disabled} />
  </TableCell>
);
