import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import { common } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";

type Props = {
  active: boolean;
  completed: boolean;
  last: boolean;
};

const activeColor = "#0288D1";
const transparent = "transparent";
const inactiveColor = "#D9D9D9";

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== "isActive" && prop !== "isCompleted" })<{
  isActive: boolean;
  isCompleted: boolean;
}>(({ isActive, isCompleted }) => ({
  backgroundColor: isCompleted ? activeColor : transparent,
  width: 24,
  height: 24,
  borderRadius: "50%",
  border: isActive ? `7px solid ${activeColor}` : isCompleted ? "none" : `1px solid ${inactiveColor}`,
  zIndex: 2,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  boxSizing: "border-box",
}));

export const FlexsaveJourneyIcon = ({ active, completed, last }: Props) => (
  <Stack sx={{ position: "relative" }}>
    {/* Check or circle component */}
    <StyledBox isActive={active} isCompleted={completed}>
      {completed && <DoneSharpIcon sx={{ color: common.white, fontSize: 18 }} />}
    </StyledBox>
    {/* Connector component */}
    {!last && (
      <Box
        sx={{
          position: "absolute",
          width: 4,
          height: "calc(100% - 22px)",
          backgroundColor: completed ? activeColor : inactiveColor,
          top: 23,
          left: 10,
          zIndex: 1,
        }}
      />
    )}
  </Stack>
);
