import { useEffect } from "react";

export default function useResizeEffect(fn: () => void) {
  useEffect(() => {
    fn();
    window.addEventListener("resize", fn);
    return () => {
      window.removeEventListener("resize", fn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
