import { useCallback, useEffect, useMemo, useRef } from "react";

import { Stack } from "@mui/material";

import { type FirestoreTimestamp } from "../../../../../utils/firebase";
import { type CommitmentPeriod, type RampPlanModel } from "../../../types";
import getAllPeriodsArr from "./getAllPeriodsArr";
import { PeriodTable } from "./PeriodTable";

export type UpdatePlanDataType = (
  periodIndex: number,
  baselinePercentage: number | null,
  i: number,
  cellBaselineCheckbox: boolean,
  isFromDialog: boolean
) => void;

export type PeriodObj = {
  allPlanActuals: number[];
  key: number;
  periodActualData: number[];
  periodMonthsArr: Array<Array<number>>;
  periodPlanData: number[];
  periodStart: FirestoreTimestamp;
  planStart: FirestoreTimestamp;
  tableEndPos: number;
  tableNum: number;
  tableStartPos: number;
  totalCommitment: number;
  updatePlanData: UpdatePlanDataType;
  periodNum: number;
  totalSpend: number;
  totalPlanned: number;
  rollover: number;
  commitmentPeriod: CommitmentPeriod;
  isLastTableOfPeriod: boolean;
};

type PeriodListProps = {
  hasEditPermission: boolean;
  planData: RampPlanModel;
  showRollover?: boolean;
  updatePlanData: (
    periodIndex: number,
    baselinePercentage: number | null,
    i: number,
    cellBaselineCheckbox: boolean,
    isFromDialog: boolean
  ) => void;
};

const PeriodList = ({ hasEditPermission, planData, updatePlanData, showRollover }: PeriodListProps) => {
  const periodsObjArr = useMemo(() => getAllPeriodsArr(planData, updatePlanData), [planData, updatePlanData]);
  const currentPeriodNumber = useRef(0);
  const showEditPlannedSpendButton = useCallback((periodObject: PeriodObj): boolean => {
    if (periodObject.periodNum === currentPeriodNumber.current) {
      return false;
    } else {
      currentPeriodNumber.current = periodObject.periodNum;
      return true;
    }
  }, []);

  useEffect(() => {
    currentPeriodNumber.current = 0;
  }, []);

  return (
    <Stack direction="column" spacing={6}>
      {periodsObjArr.map((periodObject: PeriodObj) => (
        <PeriodTable
          {...periodObject}
          key={`period-${periodObject.key}`}
          hasEditPermission={hasEditPermission}
          commitmentPeriodsArray={planData.commitmentPeriods ?? []}
          showEditPlannedSpendButton={showEditPlannedSpendButton(periodObject)}
          showRollover={!!planData.commitmentPeriods?.length && (showRollover ?? false)}
        />
      ))}
    </Stack>
  );
};
export default PeriodList;
