import { useEffect, useState } from "react";

import {
  AppModel,
  type NotificationAudienceType,
  type NotificationCommonFilterDescriptor,
  type NotificationGroupDescriptor,
  type NotificationProviderType,
  type NotificationsDescriptorDoc,
  PermissionModel,
  type UserNotification,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

export type NotificationWithGroupDescriptor<TNotificationType extends UserNotification = UserNotification> = {
  audience: NotificationAudienceType[];
  description: string;
  name: string;
  group: NotificationGroupDescriptor | undefined;
  startOfGroup: boolean;
  endOfGroup: boolean;
  order: number;
  value: TNotificationType;
  supportedProviders: NotificationProviderType[];
  enabled: boolean;
  commonFilters?: NotificationCommonFilterDescriptor[];
  missingPermissionsTooltip: string;
};

function getMissingPermissionsTooltip(
  requiredPermissions: ModelReference<PermissionModel>[],
  allPermissions: (PermissionModel & { id: string })[] = []
): string {
  if (requiredPermissions.length === 0) {
    return "";
  }
  const missingPermissions = requiredPermissions
    .map(({ id: requiredId }) => allPermissions.find(({ id }) => id === requiredId)?.title)
    .join(" and ");

  if (requiredPermissions.length > 1) {
    return `You need ${missingPermissions} permissions`;
  }
  return `You need the ${missingPermissions} permission`;
}

export function useNotificationDescriptors(
  forAudience: NotificationAudienceType,
  forProvider: NotificationProviderType,
  permissions: Set<string> | undefined
): [NotificationWithGroupDescriptor[] | undefined, boolean] {
  const [notificationWithGroupDescriptors, setNotificationWithGroupDescriptors] =
    useState<NotificationWithGroupDescriptor[]>();
  const [notificationDescriptors, loadingNotificationDescriptors] = useDocumentData(
    getCollection(AppModel).doc("notifications").narrow<NotificationsDescriptorDoc>()
  );
  const [permissionDetails] = useCollectionData(getCollection(PermissionModel), { idField: "id" });

  useEffect(() => {
    setNotificationWithGroupDescriptors(
      notificationDescriptors?.notifications
        .filter(
          ({ audience, supportedProviders }) =>
            audience.includes(forAudience) && supportedProviders.includes(forProvider)
        )
        .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
        .map((notification, idx, allNotifications) => ({
          ...notification,
          group: notificationDescriptors.notificationGroups.find(({ name }) => notification.group === name),
          startOfGroup: notification.group !== undefined && notification.group !== allNotifications[idx - 1]?.group,
          endOfGroup:
            notification.group !== undefined &&
            idx !== allNotifications.length - 1 &&
            notification.group !== allNotifications[idx + 1]?.group,
          enabled: notificationDescriptors.notificationPermissions[notification.value][forAudience]?.every(({ id }) =>
            permissions?.has(id)
          ),
          missingPermissionsTooltip: getMissingPermissionsTooltip(
            notificationDescriptors.notificationPermissions[notification.value][forAudience] ?? [],
            permissionDetails
          ),
        }))
    );
  }, [permissionDetails, forAudience, forProvider, notificationDescriptors, permissions]);

  return [notificationWithGroupDescriptors, loadingNotificationDescriptors];
}
