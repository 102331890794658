import { useEffect, useRef } from "react";

interface UseIntersectionObserverArgs {
  onEnter: () => void;
  onExit: () => void;
}

export default function useIntersectionObserver({ onEnter, onExit }: UseIntersectionObserverArgs) {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onEnter();
          } else {
            onExit();
          }
        });
      },
      {
        rootMargin: "100px",
      }
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [onEnter, onExit]);

  return elementRef;
}
