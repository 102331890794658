import {
  Classification,
  type CurrencyCode,
  CurrencyCodes,
  type CustomerModel,
  type CustomerSegment,
  CustomerSegmentValues,
  type CustomerSettingsInvoicing,
  ProductEnum,
} from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";

import { type Customer } from "../../../types";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { isDomainAlreadyTaken } from "./db";
import { generalSettingsValidationErrors } from "./errors";
import { type SettingsValuesType } from "./types";

export function getDirtyFields<T>(initialValues: Partial<T>, values: Partial<T>) {
  const allKeys = Object.keys(initialValues).concat(Object.keys(values));
  const dirtyFields: { [k in keyof T]?: boolean } = {};
  for (const key of allKeys) {
    dirtyFields[key] = !isEqual(initialValues[key], values[key]);
  }
  return dirtyFields;
}

export function getRemovedDomains({
  initialDomains,
  domains,
}: {
  initialDomains: SettingsValuesType["domains"];
  domains: SettingsValuesType["domains"];
}) {
  const removedDomains: string[] = [];
  for (const domain of initialDomains) {
    if (!domains?.some((d) => d.name === domain.name)) {
      removedDomains.push(domain.name);
    }
  }
  return removedDomains;
}

export function getAddedDomains({
  initialDomains,
  domains,
}: {
  initialDomains: SettingsValuesType["domains"];
  domains: SettingsValuesType["domains"];
}) {
  const addedDomains: string[] = [];
  for (const domain of domains) {
    if (!initialDomains?.some((d) => d.name === domain.name)) {
      addedDomains.push(domain.name);
    }
  }
  return addedDomains;
}

export async function currencyValidator(dirtyValue: SettingsValuesType["currency"]) {
  return Object.values<CurrencyCode>(CurrencyCodes).includes(dirtyValue)
    ? undefined
    : generalSettingsValidationErrors.INVALID_CURRENCY;
}

export function linesPerInvoiceValidator(dirtyValue: SettingsValuesType["linesPerInvoice"]) {
  const min = 1;
  const max = 250;
  if (dirtyValue && dirtyValue >= min && dirtyValue <= max) {
    return undefined;
  }
  return generalSettingsValidationErrors.INVALID_LINES_PER_INVOICE({ min, max });
}

export function customerNameValidator(dirtyValue: SettingsValuesType["customerName"]) {
  if (dirtyValue && dirtyValue.trim().length >= 2 && dirtyValue.length <= 128) {
    return undefined;
  }
  return generalSettingsValidationErrors.INVALID_CUSTOMER_NAME;
}

export function cloudProviderValidator(dirtyValue: SettingsValuesType["cloudProvider"]) {
  switch (dirtyValue) {
    case ProductEnum.AmazonWebServices:
    case ProductEnum.GoogleCloud:
    case ProductEnum.Other:
      return undefined;
    default: {
      return generalSettingsValidationErrors.INVALID_CLOUD_PROVIDER;
    }
  }
}

export function customerClassificationValidator(dirtyValue: SettingsValuesType["customerClassification"]) {
  if (dirtyValue && Object.values(Classification).includes(dirtyValue)) {
    return undefined;
  }
  return generalSettingsValidationErrors.INVALID_CUSTOMER_CLASSIFICATION;
}

export function earlyAccessFeaturesValidator(dirtyValue: SettingsValuesType["earlyAccessFeatures"]) {
  if (Array.isArray(dirtyValue)) {
    return undefined;
  }
  return generalSettingsValidationErrors.INVALID_EARLY_ACCESS_FEATURES;
}

export function skipRemedyBreachValidator(dirtyValue: SettingsValuesType["skipRemedyBreach"]) {
  if (typeof dirtyValue === "boolean") {
    return undefined;
  }
  return generalSettingsValidationErrors.INVALID_SKIP_REMEDY_BREACH;
}

// doesn't use type from SettingsValuesType as this can be used for more than one field
export function endDateValidator(dirtyValue: FirestoreTimestamp | null) {
  if (dirtyValue && dirtyValue.toMillis() <= Date.now()) {
    return "Trial end date must be in the future";
  }
}

/**
 * @param newDomain: domain to validate
 * @param existingDomains: if provided, will check if the domain has already been added
 * @returns error message or undefined
 */
export async function addedDomainValidator({
  newDomain,
  existingDomains,
}: {
  newDomain: string;
  existingDomains?: SettingsValuesType["domains"];
}) {
  const correctCharsRegex = new RegExp("^([a-z0-9-_]+\\.)*[a-z0-9][a-z0-9-_]+\\.[a-z]{2,12}?$");
  const unallowedDomains = ["gmail.com", "outlook.com", "yahoo.com"];

  if (newDomain.startsWith("http://") || newDomain.startsWith("https://")) {
    return generalSettingsValidationErrors.ENTER_NAKED_DOMAIN_WITHOUT_PROTOCOL;
  }
  if (!correctCharsRegex.test(newDomain)) {
    return generalSettingsValidationErrors.INVALID_DOMAIN(newDomain);
  }
  if (unallowedDomains.includes(newDomain)) {
    return generalSettingsValidationErrors.INVALID_DOMAIN(newDomain);
  }
  if (existingDomains?.some((item) => item.name === newDomain)) {
    return generalSettingsValidationErrors.DOMAIN_ALREADY_EXISTS(newDomain);
  }
  if (await isDomainAlreadyTaken(newDomain)) {
    return generalSettingsValidationErrors.DOMAIN_ALREADY_TAKEN(newDomain);
  }
}

export async function domainsValidator({
  value,
  initialValue,
  customer,
}: {
  value: SettingsValuesType["domains"];
  initialValue: SettingsValuesType["domains"];
  customer: Pick<Customer, "id" | "auth" | "domains">;
}) {
  const addedDomains = getAddedDomains({
    initialDomains: initialValue,
    domains: value,
  });
  const removedDomains = getRemovedDomains({
    initialDomains: initialValue,
    domains: value,
  });

  const addedDomainsErrs = await Promise.all(addedDomains.map((d) => addedDomainValidator({ newDomain: d })));
  for (const errMsg of addedDomainsErrs) {
    if (errMsg) {
      return errMsg;
    }
  }

  if (customer.auth?.autoProvision?.enabled) {
    for (const domain of removedDomains) {
      if (customer.auth?.autoProvision?.allowedDomains?.includes(domain)) {
        return generalSettingsValidationErrors.DOMAIN_IN_USE_BY_AUTO_PROVISION(domain);
      }
    }
  }

  if (value.length === 0 && removedDomains.length > 0) {
    return generalSettingsValidationErrors.CANT_REMOVE_ALL_DOMAINS;
  }

  const primaryDomains = value.filter((d) => d.isPrimary);
  if (value.length > 0 && primaryDomains.length === 0) {
    return generalSettingsValidationErrors.NO_PRIMARY_DOMAIN_CHOSEN;
  }
}
function sortDomainsFunc({ a, b, primaryDomain }: { a: string; b: string; primaryDomain: string }) {
  // first line is primary domain, then sort by length
  if (a === primaryDomain) {
    return -1;
  }
  if (b === primaryDomain) {
    return 1;
  }

  return b.length - a.length;
}

export function constructInitialValues({
  currency,
  domains,
  primaryDomain,
  customerName,
  linesPerInvoice,
  cloudProvider,
  customerClassification,
  earlyAccessFeatures,
  skipRemedyBreach,
  customerSegment,
  navigatorTierTrialStartDate,
  navigatorTierTrialEndDate,
  navigatorTrialCustomLength,
  navigatorTierId,
  solveTierTrialStartDate,
  solveTierTrialEndDate,
  solveTierId,
}: {
  currency: CurrencyCode | undefined;
  domains: CustomerModel["domains"];
  primaryDomain: CustomerModel["primaryDomain"];
  customerName: CustomerModel["name"];
  linesPerInvoice: CustomerSettingsInvoicing["maxLineItems"] | undefined;
  cloudProvider: ProductEnum | undefined;
  customerClassification: CustomerModel["classification"];
  earlyAccessFeatures: CustomerModel["earlyAccessFeatures"];
  skipRemedyBreach: CustomerModel["skipRemedyBreach"];
  customerSegment: CustomerSegment | undefined;
  navigatorTierTrialStartDate?: FirestoreTimestamp | null;
  navigatorTierTrialEndDate?: FirestoreTimestamp | null;
  navigatorTrialCustomLength?: number | null;
  navigatorTierId?: string;
  solveTierTrialStartDate?: FirestoreTimestamp | null;
  solveTierTrialEndDate?: FirestoreTimestamp | null;
  solveTierId?: string;
}): SettingsValuesType {
  return {
    skipRemedyBreach: skipRemedyBreach || false,
    customerSegment: customerSegment || { currentSegment: CustomerSegmentValues.NA, overrideSegment: null },
    customerName,
    currency: currency || CurrencyCodes.USD,
    linesPerInvoice: linesPerInvoice || 10,
    cloudProvider: cloudProvider || null,
    customerClassification: customerClassification || null,
    earlyAccessFeatures: earlyAccessFeatures || [],
    domains: (domains || [])
      .map((domain) => ({
        name: domain,
        isPrimary: domain === primaryDomain,
      }))
      .sort((a, b) => sortDomainsFunc({ a: a.name, b: b.name, primaryDomain })),
    // set these to null specifically or dirty field checking doesnt work
    navigatorTierTrialStartDate: navigatorTierTrialStartDate ?? null,
    navigatorTierTrialEndDate: navigatorTierTrialEndDate ?? null,
    navigatorTrialCustomLength,
    navigatorTierId,
    solveTierTrialStartDate: solveTierTrialStartDate ?? null,
    solveTierTrialEndDate: solveTierTrialEndDate ?? null,
    solveTierId,
  };
}

export function isSubmitDisabled({
  dirty,
  isValid,
  isSubmitting,
  isValidating,
}: {
  dirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  isValidating: boolean;
}) {
  return !dirty || !isValid || isSubmitting || isValidating;
}

// isTrialActive checks whether the current date is before the end date
// note: trial can be in the future and still be active
export function isTrialActive(end?: DateTime) {
  if (!end) {
    return false;
  }

  return DateTime.now() < end;
}

export const DATA_CY_GENERAL_SETTINGS_SELECTORS = {
  CANCEL_BUTTON: "btn-appbar-secondary",
  CLOUD_PROVIDER: "cloud-provider",
  CUSTOMER_NAME: "customer-name",
  CUSTOMER_CLASSIFICATION: "customer-classification",
  CURRENCY_ITEM: "currency-item",
  CURRENCY_SELECT: "currency-select",
  DOMAIN_CHIP: "domain-chip",
  DOMAIN_CHIP_DELETE_ICON: "domain-chip-delete-icon",
  DOMAINS: "domains",
  DOMAINS_INPUT: "domains-input",
  EARLY_ACCESS_FEATURES: "early-access-features",
  GENERAL_SETTINGS_FORM: "general-settings-form",
  LINES_PER_INVOICE: "lines-per-invoice",
  PRIMARY_DOMAIN: "primary-domain",
  PRIMARY_DOMAIN_CHANGED_ALERT: "primary-domain-changed-alert",
  SET_PRESENTATION_MODE_BTN: "set-presentation-mode-button",
  SAVE_BTN: "btn-appbar-primary",
  SKIP_REMEDY_BREACH: "skip-remedy-breach",
  OVERRIDE_CUSTOMER_SEGMENT: "override-customer-segment",
  CONVERT_TO_SAAS_CUSTOMER_BTN: "convert-to-saas-button",
  EDIT_INTERESTED_PACKAGES_BTN: "edit-interested-packages-button",
  NAV_TIER_DROPDOWN: "nav-tier-dropdown",
  SOLVE_TIER_DROPDOWN: "solve-tier-dropdown",
};
