import { type CustomerModelCloudServices, type KnownIssuePlatform } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

import { type KnownIssue } from "../../../Pages/KnownIssues/types";

export enum ClassificationEnum {
  Available = "available",
  FullDowntime = "fullDowntime",
  PartialDowntime = "partialDowntime",
}

export type TimeIntervalName = "Hour" | "Day" | "Week" | "Month" | "Quarter" | "Year";

export type IntervalRecord = {
  startTime: DateTime;
  endTime: DateTime;
  available?: number;
  partialDown?: number;
  fullDown?: number;
  incidents: KnownIssue[];
};

export type IntervalMap = Record<string, IntervalRecord>;

export type CustomerServicesWithId = CustomerModelCloudServices & { id: string };

export type CategoriesTitlesItem = {
  formattedDate: string;
  formattedTime: string;
};

export type DowntimeItem = {
  val: number;
  incidents: KnownIssue[];
};

export type highchartsDataParams = {
  categoriesTitles: CategoriesTitlesItem[];
  availableSeries: number[];
  partialDownSeries: DowntimeItem[];
  fullDownSeries: DowntimeItem[];
};

export type ProductsFilterMultiSelectProductOption = {
  product: string;
  platform: KnownIssuePlatform;
};
