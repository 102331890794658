import { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { AppModelSlack, type ChannelNotificationsProps, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type SlackDataProps } from "./utils";

export type AuthTestResponse = {
  ok: boolean;
  private_channels_scopes: boolean;
};

export const useSlackApi = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const baseURI = `/api/v1/customers`;
  const history = useHistory();
  const [availableNotification, setAvailableNotification] = useState<ChannelNotificationsProps[] | null>(null);
  const [slackData, setSlackData] = useState<SlackDataProps>(null);
  const [isDocEmpty, setIsDocEmpty] = useState<boolean>();

  const notifySupportForFailure = useCallback(
    (error) => {
      history.push(`/customers/${customer.id}/support/new/5tzlIAHll2GsItZ7JdsE?error="${error}"`);
    },
    [history, customer.id]
  );

  const getChannelInvitation = useCallback(
    () =>
      api.request({
        method: "get",
        url: `/${customer.id}/slack/get-channel-invitation`,
        baseURL: baseURI,
      }),
    [api, baseURI, customer.id]
  );

  const createSharedChannel = useCallback(
    () =>
      api.request({
        method: "get",
        url: `/${customer.id}/slack/create-shared-channel`,
        baseURL: baseURI,
      }),
    [api, baseURI, customer.id]
  );

  const authTest = useCallback(
    () =>
      api.request<AuthTestResponse>({
        method: "get",
        url: `/${customer.id}/slack/auth-test`,
        baseURL: baseURI,
      }),
    [api, baseURI, customer.id]
  );

  useEffect(() => {
    getCollection(AppModelSlack)
      .doc("slack")
      .get()
      .then((notificationDocs) => {
        const newAvailableNotification =
          notificationDocs.asModelData()?.channels?.filter((channel) => channel.customersNotifications) || [];
        setAvailableNotification(newAvailableNotification);
      });
  }, []);

  useEffect(
    () =>
      getCollection(CustomerModel)
        .doc(customer.id)
        .collection("slackChannel")
        .limit(1)
        .onSnapshot((snapDoc) => {
          if (snapDoc.empty) {
            setIsDocEmpty(true);
            return;
          }
          setIsDocEmpty(false);
          snapDoc.docs.forEach((doc) => {
            const data = doc.asModelData();
            setSlackData({
              ref: doc.modelRef,
              data,
            });
          });
        }),
    [customer.id]
  );

  return {
    availableNotification,
    slackData,
    isDocEmpty,
    notifySupportForFailure,
    getChannelInvitation,
    createSharedChannel,
    authTest,
  };
};
