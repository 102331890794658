import { useMemo } from "react";

import { CloudAnalyticsModel, type CloudAnalyticsModelAlertModel, Roles } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AnalyticsAlertWithRef } from "../../../../Pages/CloudAnalytics/alerts";
import { formatFirestoreDate, getOrganizationRef } from "../../../../Pages/CloudAnalytics/utilities";

export const alertsTransform = (
  data: WithFirebaseModel<CloudAnalyticsModelAlertModel>,
  snapshot: DocumentSnapshotModel<CloudAnalyticsModelAlertModel>
): AnalyticsAlertWithRef => ({
  data,
  ref: snapshot.ref,
  transform: {
    owner: data.collaborators.find((c) => c.role === Roles.OWNER)?.email,
    lastSent: formatFirestoreDate(data.timeLastAlerted) || null,
    timeCreated: formatFirestoreDate(data.timeCreated),
    timeModified: formatFirestoreDate(data.timeModified),
  },
});

export const useAlerts = (): [AnalyticsAlertWithRef[], boolean] => {
  const { isDoitEmployee, currentUser } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer, userOrganization } = useCustomerContext();

  const fetchPublicAlerts = useMemo(() => {
    let query = getCollection(CloudAnalyticsModel)
      .doc("alerts")
      .collection("cloudAnalyticsAlerts")
      .where("customer", "==", customer.ref)
      .where("public", "in", [Roles.VIEWER, Roles.EDITOR]);

    // DoiT Employees can see all public alerts from all orgs.
    if (!isDoitEmployee) {
      const orgRef = getOrganizationRef(false, userOrganization, customer.id);
      query = query.where("organization", "==", orgRef);
    }

    return query;
  }, [customer.id, customer.ref, isDoitEmployee, userOrganization]);

  const fetchSharedAlerts = useMemo(() => {
    let query = getCollection(CloudAnalyticsModel)
      .doc("alerts")
      .collection("cloudAnalyticsAlerts")
      .where("customer", "==", customer.ref)
      .where("public", "==", null);

    // DoiT Employees can see all private alerts from all orgs.
    if (!isDoitEmployee) {
      const orgRef = getOrganizationRef(false, userOrganization, customer.id);
      query = query
        .where("collaborators", "array-contains-any", [
          { email: currentUser.email, role: Roles.OWNER },
          { email: currentUser.email, role: Roles.VIEWER },
          { email: currentUser.email, role: Roles.EDITOR },
        ])
        .where("organization", "==", orgRef);
    }

    return query;
  }, [userOrganization, customer.id, customer.ref, currentUser.email, isDoitEmployee]);

  const [publicAlerts, loadingPublicAlerts] = useCollectionData(fetchPublicAlerts, {
    transform: alertsTransform,
  });

  const [sharedAlerts, loadingSharedAlerts] = useCollectionData(fetchSharedAlerts, {
    transform: alertsTransform,
  });

  const alertList = useMemo(() => [...(publicAlerts ?? []), ...(sharedAlerts ?? [])], [publicAlerts, sharedAlerts]);
  const alertsLoading = loadingPublicAlerts || loadingSharedAlerts;

  return [alertList, alertsLoading];
};
