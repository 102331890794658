import { useCallback, useMemo, useState } from "react";

import {
  type CurrencyCode,
  LimitAggregation,
  Metric,
  Positions,
  type ReportFilter,
  Sort,
  TimeInterval,
} from "@doitintl/cmp-models";
import InfoIcon from "@mui/icons-material/Info";
import { Alert, AlertTitle, Grid, Link, List, ListItem, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { attributionTxt } from "../../../../assets/texts/CloudAnalytics";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import ReportData from "../../../../Pages/CloudAnalytics/ReportData";
import { type AttributionFilter, type MetadataOption } from "../../../../types";
import { CSPCustomerID } from "../../../../utils/common";
import { getLetterForIndex } from "../../../../utils/string";
import { useAnalyticsContext } from "../../CloudAnalyticsContext";
import { executeQueryRequest, type QueryRequest, QueryType } from "../../report/ReportQuery";
import { accountsParam, attrModelToQueryRequest, positionsParams } from "../../report/ReportQueryUtils";
import { MetricOptions } from "../../utilities";
import { FormulaField, type FormulaState } from "../FormulaField";
import { isValidFiltersAndFormula } from "../utils";

const LOGIC_PART_WIDTH = 600;
const DEFAULT_LIMIT = 25;
const DEFAULT_TIME_SETTINGS = {
  interval: TimeInterval.DAY,
  from: DateTime.utc()
    .minus({ [TimeInterval.MONTH]: 2 })
    .startOf(TimeInterval.MONTH),
  to: DateTime.utc(),
};

type Props = {
  formula?: string;
  filters: AttributionFilter[];
  guidedExperienceMode?: boolean;
};

const useAttributionCreateFormula = ({ formula, filters, guidedExperienceMode = false }: Props) => {
  const [logicAlertOpen, setLogicAlertOpen] = useState(true);

  const [formulaState, setFormulaState] = useState<FormulaState>({
    value: formula ?? "",
    valid: !!formula,
    autocompleteOn: !formula,
    shouldRun: false,
  });
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const { transforms } = useAnalyticsContext();

  const variablesArray = useMemo(() => filters.map((_, idx) => getLetterForIndex(idx)), [filters]);
  const hasValidFiltersAndFormula = useMemo(
    () => isValidFiltersAndFormula(filters, formulaState.valid),
    [filters, formulaState.valid]
  );
  const isCSP = useMemo(() => customer.id === CSPCustomerID, [customer]);

  useUpdateEffect(() => {
    if (formulaState.shouldRun) {
      setFormulaState((prevState) => ({ ...prevState, shouldRun: false }));
    }
  }, [formulaState.shouldRun, hasValidFiltersAndFormula]);

  const attributionQueryRun = useCallback(
    async (
      name: string,
      currency: CurrencyCode,
      filteredDimensions: MetadataOption[],
      attributionId: string,
      rowOrder: Sort,
      timeSettings: { interval: TimeInterval; from: DateTime; to: DateTime } = DEFAULT_TIME_SETTINGS
    ) => {
      const accounts = accountsParam(filteredDimensions);
      const { rows, cols } = positionsParams(filteredDimensions, true, filters, guidedExperienceMode);
      const attr = attrModelToQueryRequest({ name, filters, formula: formulaState.value, id: attributionId }, true);

      const limitFilters: ReportFilter[] = filters.map((filter) => ({
        ...filter,
        allowNull: false,
        values: [],
        regexp: null,
        inverse: false,
        includeInFilter: true,
        position: Positions.ROW,
        limit: DEFAULT_LIMIT,
        limitMetric: Metric.COST,
        limitOrder: Sort.DESC,
        composite: [],
      }));

      const request: QueryRequest = {
        type: QueryType.ATTRIBUTION,
        id: attributionId,
        accounts,
        timeSettings,
        rows,
        cols,
        attributions: [attr],
        filters: limitFilters,
        currency,
        limitAggregation: LimitAggregation.TOP,
        calculatedMetric: null,
        cloudProviders: null,
      };

      const response = await executeQueryRequest(api, customer.id, request);
      const metricOffset = rows.length + cols.length;
      const vals = [metricOffset, metricOffset + 1];
      const numMetrics = isCSP ? MetricOptions.length + 1 : MetricOptions.length;

      return new ReportData({
        data: response.data.rows,
        rows,
        cols,
        rowOrder,
        vals,
        transforms,
        numMetrics,
      });
    },
    [api, customer.id, filters, formulaState.value, guidedExperienceMode, isCSP, transforms]
  );

  const AttributionCreateFormula = (
    <Grid container spacing={1} sx={{ mt: guidedExperienceMode ? 4 : 6, flexDirection: "column" }}>
      {!guidedExperienceMode && (
        <Typography variant="h4" sx={{ pl: 1 }}>
          {attributionTxt.DEFINE_LOGIC_TITLE}
        </Typography>
      )}
      <Grid item sx={{ width: { md: LOGIC_PART_WIDTH + 8 } }}>
        <FormulaField
          formulaState={formulaState}
          setFormulaState={setFormulaState}
          variables={variablesArray}
          disabled={false}
          label={attributionTxt.DEFINE_LOGIC}
        />
      </Grid>
      {guidedExperienceMode && (
        <Typography variant="caption" color="textSecondary" pl={1}>
          {attributionTxt.OPERATORS_BETWEEN_RULES}
          <Link
            color="inherit"
            href="https://help.doit.com/docs/cloud-analytics/attributing-cloud-spend/create-attribution#create-an-attribution-from-scratch"
            pl={0.5}
            target="_blank"
            variant="inherit"
          >
            {attributionTxt.LEARN_MORE}
          </Link>
        </Typography>
      )}
      {!guidedExperienceMode && logicAlertOpen && (
        <Grid item xs={12} md="auto">
          <Alert
            variant="standard"
            severity="info"
            onClose={() => {
              setLogicAlertOpen(false);
            }}
            icon={<InfoIcon fontSize="inherit" />}
            sx={{ width: { md: LOGIC_PART_WIDTH } }}
          >
            <AlertTitle>{attributionTxt.LOGIC_ALERT_TITLE}</AlertTitle>
            <List dense disablePadding sx={{ listStyleType: "disc", pl: 2 }}>
              {attributionTxt.LOGIC_ALERT_RULES.map((val) => (
                <ListItem key={val} disablePadding sx={{ display: "list-item" }}>
                  {val}
                </ListItem>
              ))}
            </List>
          </Alert>
        </Grid>
      )}
    </Grid>
  );

  return { AttributionCreateFormula, formulaState, attributionQueryRun };
};

export default useAttributionCreateFormula;
