import { useHistory } from "react-router";
import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { getSaaSConsoleOnboardingRoute } from "../../../../utils/common";
import mixpanel from "../../../../utils/mixpanel";
import { CloudConnectCard } from "../CloudConnectCard";

const listedCloudFeatures = ["Cloud analytics", "Attributions", "Anomaly detection", "Budgets"];

export const SaaSCloudConnectCard = ({ cloudType }: { cloudType: SaaSConsoleType }) => {
  const history = useHistory();
  const { customer } = useCustomerContext();

  const onClick = () => {
    mixpanel.track("saas.connect.card.click", {
      cloud: cloudType,
    });
    history.push(getSaaSConsoleOnboardingRoute(customer, cloudType));
  };

  const cardContent = (
    <Stack ml={3} mt={3}>
      <Typography mb={1}>Connect your cloud to the DoiT Console and enjoy features such as</Typography>

      {listedCloudFeatures.map((feature) => (
        <Typography key={feature} ml={2}>
          • {feature}
        </Typography>
      ))}
    </Stack>
  );

  return <CloudConnectCard cloudType={cloudType} cardContent={cardContent} onGetStarted={onClick} />;
};
