import { type Extfile } from "@doitintl/cmp-models";

import { downloadFromBucket } from "../../utils/firebaseStorage";

const firebaseStorage = `${process.env.REACT_APP_FIREBASE_PROJECT_ID}-priority`;

export const handleDownloadInvoice = (extFile: Extfile, onError?: (error: string) => void) => async () => {
  const error = await downloadFromBucket({
    bucketName: firebaseStorage,
    path: extFile.storage,
    fileName: extFile.key,
  });
  if (error && onError) {
    onError(error);
  }
};
