export const EXPECTED_ACCOUNT_ID_LENGTH = 12;
export const AWS_BILLING_REPORT_URL = "https://us-east-1.console.aws.amazon.com/billing/home?region=us-east-1#/reports";
export const AWS_STACK_CREATION_URL =
  "https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?";
export const STACK_NAME = "DoiTSaaSAddBillingOrg";
export const S3BucketNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9-.]{1,61}[a-zA-Z0-9]$/;

export const S3BucketNameRegexErrorPrefix = `Invalid S3 Bucket name.`;
export const S3BucketNameRegexError = {
  InvalidLength: `${S3BucketNameRegexErrorPrefix} Bucket names must be between 3 (min) and 63 (max) characters long.`,
  InvalidCharacters: `${S3BucketNameRegexErrorPrefix} Bucket names must start and end with a letter or number and can consist only of lowercase letters, numbers, dots (.), and hyphens (-).`,
};
