import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";

export type Props = {
  title: string;
  display: boolean;
};

const WidgetSectionHeader = ({ title, display }: Props) => {
  const sectionHeaderContent = (
    <>
      <ListSubheader disableSticky={true} component="div" data-testid="WidgetSectionHeader-title">
        {title}
      </ListSubheader>
      <Divider />
    </>
  );

  return (
    <Box data-cy="WidgetSectionHeader-container" data-testid="WidgetSectionHeader-container">
      {display ? sectionHeaderContent : ""}
    </Box>
  );
};

export default WidgetSectionHeader;
