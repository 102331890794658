import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useReducer, useState } from "react";

import { useParams } from "react-router-dom";
import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type Method } from "axios";

import { useApiContext } from "../../../api/context";
import {
  useErrorSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { type Customer } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { setAuthSettings } from "./api/setAuthSettings";
import { getInitialState } from "./reducer";
import {
  type AuthAction,
  AuthActionKind,
  type AuthSettingsType,
  type AuthState,
  type OIDCForm,
  type ProviderConfig,
  type SAMLForm,
  type SSOProviders,
} from "./types";

export const useAsyncActions = (dispatch: Dispatch<AuthAction>) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  const createSSORequest = useCallback(
    (method: Method) => async (reqData?: Partial<ProviderConfig>) => {
      dispatch({ type: AuthActionKind.PENDING_REQUEST });

      try {
        const { data } = await api.request({
          method,
          url: `/v1/customers/${customer.id}/ssoproviders`,
          data: reqData,
        });
        dispatch({ type: AuthActionKind.SET_SSO_PROVIDER_CONFIG, payload: data });
        dispatch({ type: AuthActionKind.SUCCESSFUL_REQUEST });
      } catch (error: any) {
        consoleErrorWithSentry(error);
        dispatch({ type: AuthActionKind.FAILED_REQUEST, payload: error.message });
      }
    },
    [api, customer.id, dispatch]
  );

  return useMemo(
    () => ({
      getAllProviderConfigs: createSSORequest("GET"),
      updateProviderConfigs: createSSORequest("PATCH"),
      createProviderConfigs: createSSORequest("POST"),
    }),
    [createSSORequest]
  );
};

export const useReducerWithSideEffects = (
  reducer: (state: AuthState, action: AuthAction) => AuthState,
  customer: Customer
): [AuthState, Dispatch<AuthAction>] => {
  const [state, dispatch]: [AuthState, Dispatch<AuthAction>] = useReducer(
    reducer,
    getInitialState(customer.primaryDomain, customer.allowAuthProvider, customer.auth)
  );
  const asyncActions = useAsyncActions(dispatch);
  const { getAllProviderConfigs } = asyncActions;
  useEffect(() => {
    getAllProviderConfigs();
  }, [customer.id, getAllProviderConfigs]);

  return [state, dispatch];
};

export const useSSOConfiguration = (
  state: AuthState,
  dispatch: Dispatch<AuthAction>,
  backPageCallback: () => void
): [boolean, Dispatch<SetStateAction<boolean>>, (form: SAMLForm | OIDCForm) => Promise<any>] => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const { ssoType } = useParams<{ ssoType: SSOProviders }>();
  const asyncAction = useAsyncActions(dispatch);
  const { customer } = useCustomerContext();

  const handleSubmit = async (form: SAMLForm | OIDCForm) => {
    const reqData = { [ssoType]: { id: state[ssoType].id, enabled: true, ...form } };
    const otherSsoType = ssoType === "saml" ? "oidc" : "saml";

    await getCollection(CustomerModel)
      .doc(customer.id)
      .update("auth.sso", {
        [ssoType]: "enabled",
        [otherSsoType]: customer.auth?.sso?.[otherSsoType] ? "configured" : customer.auth?.sso?.[otherSsoType],
      });

    if (state[ssoType].id) {
      await asyncAction.updateProviderConfigs(reqData);
    } else {
      await asyncAction.createProviderConfigs(reqData);
    }
    if (customer.auth?.sso?.[otherSsoType]) {
      await asyncAction.updateProviderConfigs({
        [otherSsoType]: { id: state[otherSsoType].id, enabled: false, ...form },
      });
    }
    setSubmitted(true);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (submitted && !state.error) {
      backPageCallback();
    }
    setSubmitted(false);
  }, [backPageCallback, state, submitted]);

  return [openDialog, setOpenDialog, handleSubmit];
};

export const useAuthSettings = (
  state: AuthState,
  dispatch: Dispatch<AuthAction>,
  customerId: string
): [AuthSettingsType, (newAuthSettings: AuthSettingsType) => Promise<void>] => {
  const sharedSnackbar = useSnackbar();

  const setNewAuthSettings = useCallback(
    async (newAuthSettings: AuthSettingsType) => {
      dispatch({ type: AuthActionKind.PENDING_REQUEST });
      try {
        await setAuthSettings({ customerId, newAuthSettings });
        dispatch({
          type: AuthActionKind.SET_AUTH_SETTINGS,
          payload: { authProvider: newAuthSettings.allowedProvider, autoProvision: newAuthSettings.autoProvision },
        });
        dispatch({ type: AuthActionKind.SUCCESSFUL_REQUEST });
        sharedSnackbar.onOpen({
          message: "Authentication settings saved",
          variant: "success",
          autoHideDuration: 5000,
        });
      } catch (error: any) {
        consoleErrorWithSentry(error);
        dispatch({ type: AuthActionKind.FAILED_REQUEST, payload: error.message });
        sharedSnackbar.onOpen({
          message: "Failed to save authentication settings",
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    },
    [customerId, dispatch, sharedSnackbar]
  );

  return [{ allowedProvider: state.authProvider, autoProvision: state.autoProvision }, setNewAuthSettings];
};

export const useIsLegacySso = (ssoType: SSOProviders) => {
  const { customer } = useCustomerContext({ allowNull: true });
  const [isLegacySso, setIsLegacySso] = useState(false);

  useEffect(() => {
    if (!customer?.auth?.sso) {
      return;
    }
    setIsLegacySso(
      !!customer.auth?.sso?.useSsoWithoutProxy &&
        ((ssoType === "saml" && customer.auth?.sso?.saml === "enabled") ||
          (ssoType === "oidc" && customer.auth?.sso?.oidc === "enabled"))
    );
  }, [customer?.auth?.sso, ssoType]);

  return isLegacySso;
};

export const useConvertLegacySso = () => {
  const { customer } = useCustomerContext();
  const { userModel } = useUserContext();
  const errSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const convertLegacySso = useCallback(async () => {
    try {
      await getCollection(CustomerModel).doc(customer.id).update("auth.sso.useSsoWithoutProxy", false);
      successSnackbar("SSO configuration updated successfully");
      mixpanel.track("iam.sso.convert-legacy", {
        customer: customer.id,
        customerName: customer.name,
        primaryDomain: customer.primaryDomain,
        convertedBy: userModel?.email,
      });
    } catch (error: any) {
      consoleErrorWithSentry(error);
      errSnackbar(`Failed to update: ${error?.message}`);
    }
  }, [customer.id, errSnackbar, successSnackbar, userModel?.email, customer.name, customer.primaryDomain]);

  return convertLegacySso;
};
