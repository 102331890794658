import { createContext, type Dispatch, type ReactNode, useContext, useMemo, useReducer } from "react";

import { type ReportConfig } from "@doitintl/cmp-models";

// reducer
type ReportSaver = {
  saved: boolean;
  hasUnsavedChanges: boolean;
  initialConfig?: ReportConfig;
};

export type ReportSaverAction = {
  payload: Partial<ReportSaver>;
};

const reportSaverReducer = (state: ReportSaver, action: ReportSaverAction): ReportSaver => ({
  ...state,
  ...action.payload,
});

// context
const initial = () => ({ saved: false, hasUnsavedChanges: false });

type ReportSaverContextType = {
  reportSaver: ReportSaver;
  dispatchReportSaver: Dispatch<ReportSaverAction>;
};

const ReportSaverContext = createContext({
  reportSaver: initial(),
  dispatchReportSaver: (_: ReportSaverAction) => {},
});

export const ReportSaverContextProvider = ({ children }: { children?: ReactNode }) => {
  const [reportSaver, dispatchReportSaver] = useReducer(reportSaverReducer, initial());

  const reportSaverProviderValue = useMemo(
    () => ({ reportSaver, dispatchReportSaver }),
    [reportSaver, dispatchReportSaver]
  );
  return <ReportSaverContext.Provider value={reportSaverProviderValue}>{children}</ReportSaverContext.Provider>;
};

export const useReportSaverContext = (): ReportSaverContextType => useContext(ReportSaverContext);
