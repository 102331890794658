import { useEffect, useState } from "react";

import { type AccountTeamMember } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";

import { type Customer } from "../../types";

type Props = {
  customer?: Customer;
};

/**
 * Provision for working with the accountTeam property within customer docs.
 */
export const useAccountTeam = ({ customer }: Props): { accountTeam: AccountTeamMember[] } => {
  const [accountTeam, setAccountTeam] = useState<WithFirebaseModel<AccountTeamMember>[]>(customer?.accountTeam ?? []);

  useEffect(() => {
    setAccountTeam(customer?.accountTeam ?? []);
  }, [customer?.accountTeam]);

  /**
   * Returns model data for an account manager.
   * @param am Account team member
   */

  return {
    accountTeam,
  };
};
