import { useEffect, useState } from "react";

import { AppModel, type CloudConnectCategory } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

const EMPTY_COLLECTION = [];

export function usePermissionCategories() {
  const [permissionsByCategory, setPermissionsByCategory] = useState<CloudConnectCategory[]>(EMPTY_COLLECTION);

  useEffect(() => {
    (async () => {
      const cloudConnectSnap = await getCollection(AppModel).doc("cloud-connect").get();
      const data = cloudConnectSnap.asModelData();
      setPermissionsByCategory(data?.categories ?? EMPTY_COLLECTION);
    })();
  }, []);

  return permissionsByCategory;
}
