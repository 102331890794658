import { type RampListItemData, type RampPlanModel } from "../types";

export const getPlanActuals = (itemData: RampPlanModel | RampListItemData) => {
  const allPlanActualsFlatArr: Array<number> = [];
  let indexOfTotal = 0;
  itemData.commitmentPeriods?.forEach((period) => {
    period?.planned.forEach((x, i) => {
      allPlanActualsFlatArr[indexOfTotal] = period?.actuals?.[i] ? period?.actuals[i] : 0;
      indexOfTotal++;
    });
  });
  return allPlanActualsFlatArr;
};
