import { useCallback, useState } from "react";

import { Positions, Sort } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { appcuesText, reportText, tooltipText } from "../../../../assets/texts";
import { reportTxt } from "../../../../assets/texts/CloudAnalytics";
import { DraggableGroup } from "../../../../Components/DragAndDrop/DraggableGroup";
import { type KeyTypeValues } from "../../../../Pages/CloudAnalytics/api";
import { useReportConfig, useReportDimensionsContext } from "../../Context";
import { FilterPopover } from "../../dialogs/metadataFilter/FilterContent";
import { type RenderDraggableContent, type ReportAddDimension } from "../../utilities";
import { type PopoverAnchorEl } from "../types";
import ReportsAccordion from "./ReportsAccordion";
import SortToggleGroup from "./SortToggleGroup";

export type DimensionsProps = {
  popoverAnchorEl: PopoverAnchorEl;
  setPopoverAnchorEl: (anchorEl: PopoverAnchorEl | null) => void;
  handleChangeLabels: (values: KeyTypeValues[], position: Positions) => void;
  handleAddDimension: ReportAddDimension;
  colSortDisabled: boolean;
  numOfItems: number;
  renderDraggableContent: RenderDraggableContent;
};

const position = Positions.COL;

const Dimensions = ({
  handleAddDimension,
  handleChangeLabels,
  popoverAnchorEl,
  setPopoverAnchorEl,
  colSortDisabled,
  numOfItems,
  renderDraggableContent,
}: DimensionsProps) => {
  const { dimensions = [] } = useReportDimensionsContext();
  const { reportConfig, dispatchReportConfig } = useReportConfig();
  const { colOrder, excludeSelectMetadataIds } = reportConfig ?? {};
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const onExpand = useCallback((state: boolean) => {
    setAccordionExpanded(state);
  }, []);

  const onChangeOrder = useCallback(
    (_, newOrder) => {
      dispatchReportConfig({ payload: { colOrder: newOrder ?? Sort.A_TO_Z } });
    },
    [dispatchReportConfig]
  );

  const popoverComponent = (
    <FilterPopover
      anchorEl={popoverAnchorEl?.mdCol}
      onClose={() => {
        setPopoverAnchorEl(null);
      }}
      metadata={dimensions}
      excludeSelectMetadataIds={excludeSelectMetadataIds}
      position={position}
      handleAddDimension={handleAddDimension}
      handleChangeLabels={handleChangeLabels}
    />
  );

  return (
    <ReportsAccordion
      title={reportText.DIMENSIONS_SECTION_LABEL}
      appCues={appcuesText.DIMENSIONS}
      numOfItems={numOfItems}
      popoverComponent={popoverComponent}
      popoverAnchorEl={popoverAnchorEl?.mdCol}
      setPopoverAnchorEl={setPopoverAnchorEl}
      position={position}
      disableAdd={colSortDisabled}
      onExpand={onExpand}
      addIconTooltip={reportTxt.ADD_DIMENSION_TOOLTIP}
    >
      <Box data-cy="dimensions">
        <DraggableGroup
          id={position}
          disabled={colSortDisabled}
          renderContent={renderDraggableContent(position, colSortDisabled, !accordionExpanded)}
          isHidden={!accordionExpanded}
        />
      </Box>
      {numOfItems > 0 ? (
        <SortToggleGroup
          title={reportText.SORTֹֹ_DIMENSIONS}
          disabled={colSortDisabled}
          value={colOrder}
          onChange={onChangeOrder}
          tooltipPrefix={tooltipText.COLUMN}
        />
      ) : (
        <></>
      )}
    </ReportsAccordion>
  );
};

export default Dimensions;
