import { type AutocompleteCollection, type AutocompleteState } from "@algolia/autocomplete-core";
import { parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type SearchOptions } from "instantsearch.js";
import isEqualWith from "lodash/isEqualWith";
import { DateTime } from "luxon";

import AlertsDark from "../../assets/algolia/alerts-darkmode.svg";
import AlertsLight from "../../assets/algolia/alerts-lightmode.svg";
import AttributionGroupsDark from "../../assets/algolia/attributiongroups-darkmode.svg";
import AttributionGroupsLight from "../../assets/algolia/attributiongroups-lightmode.svg";
import AttributionsDark from "../../assets/algolia/attributions-darkmode.svg";
import AttributionsLight from "../../assets/algolia/attributions-lightmode.svg";
import BudgetsDark from "../../assets/algolia/budgets-darkmode.svg";
import BudgetsLight from "../../assets/algolia/budgets-lightmode.svg";
import InvoicesDark from "../../assets/algolia/invoices-darkmode.svg";
import InvoicesLight from "../../assets/algolia/invoices-lightmode.svg";
import MetricsDark from "../../assets/algolia/metrics-darkmode.svg";
import MetricsLight from "../../assets/algolia/metrics-lightmode.svg";
import ReportsDark from "../../assets/algolia/reports-darkmode.svg";
import ReportsLight from "../../assets/algolia/reports-lightmode.svg";
import AwsIcon from "../../assets/amazon-web-services-new.png";
import BettercloudIcon from "../../assets/bettercloud.png";
import GSuiteIcon from "../../assets/g-suite.png";
import GCPIcon from "../../assets/google-cloud.png";
import AzureIcon from "../../assets/microsoft-azure.png";
import office365Icon from "../../assets/office-365.png";
import ZendeskIcon from "../../assets/zendesk.png";
import {
  type AlgoliaCustomContext,
  type AlgoliaFilters,
  type AlgoliaIndexType,
  type AlgoliaItem,
  type CloudAnalyticsHit,
  type HighlightMatch,
  type InvoiceHit,
  type ParsedAttributeWithStyle,
} from "./types";

export const searchItemLogo: Record<AlgoliaIndexType, { light: any; dark: any }> = {
  assets: { dark: undefined, light: undefined },
  routes: { dark: undefined, light: undefined },
  customers: { dark: undefined, light: undefined },
  entities: { dark: undefined, light: undefined },
  users: { dark: undefined, light: undefined },
  invoices: { dark: InvoicesDark, light: InvoicesLight },
  alerts: { dark: AlertsDark, light: AlertsLight },
  attributions: { light: AttributionsLight, dark: AttributionsDark },
  attributionGroups: { dark: AttributionGroupsDark, light: AttributionGroupsLight },
  budgets: { dark: BudgetsDark, light: BudgetsLight },
  metrics: { dark: MetricsDark, light: MetricsLight },
  reports: { dark: ReportsDark, light: ReportsLight },
  cloudflow_aws_api: { dark: undefined, light: undefined },
  cloudflow_gcp_api: { dark: undefined, light: undefined },
  cloudflow_doit_api: { dark: undefined, light: undefined },
};

export const countCollections = (
  collections: Array<AutocompleteCollection<AlgoliaItem>>
): Record<AlgoliaIndexType, number> => {
  const count: Record<AlgoliaIndexType, number> = {
    assets: 0,
    customers: 0,
    entities: 0,
    users: 0,
    invoices: 0,
    alerts: 0,
    attributions: 0,
    attributionGroups: 0,
    budgets: 0,
    metrics: 0,
    reports: 0,
    routes: 0,
    cloudflow_aws_api: 0,
    cloudflow_gcp_api: 0,
    cloudflow_doit_api: 0,
  };

  collections.forEach((collection) => {
    if (collection.items.length > 0) {
      count[collection.source.sourceId as AlgoliaIndexType] = collection.items.length;
    }
  });
  return count;
};

export const shouldUpdateState = (state: AutocompleteState<AlgoliaItem>, prevState: AutocompleteState<AlgoliaItem>) => {
  if (
    state.collections.length !== prevState.collections.length ||
    state.activeItemId !== prevState.activeItemId ||
    state.query !== prevState.query ||
    state.collections.length !== prevState.collections.length
  ) {
    return true;
  }

  return !isEqualWith(prevState.collections, state.collections, (curr, prev, key) =>
    key === "source" ? true : undefined
  );
};

export const shouldReturnResults = (
  filters: AlgoliaFilters,
  collection: AutocompleteCollection<AlgoliaItem>,
  query: string,
  isSearchForCustomer: boolean
) => {
  if (collection.items.length === 0) {
    return false;
  }
  if (filters.allResults || query === "" || isSearchForCustomer) {
    return true;
  }

  const index = collection.source.sourceId as AlgoliaIndexType;
  return !!filters[index];
};

export const filterByPermission = (context: AlgoliaCustomContext, permissions?: Set<string>) => {
  if (!permissions) {
    return "";
  }

  if (context.isDoitEmployee) {
    return `permissions:doitEmployee AND inCustomerContext:${context.inCustomerContext}`;
  }

  return `inCustomerContext:true AND (permissions:${[...permissions].join(
    " OR permissions:"
  )} OR hasPermissions:false)`;
};

export const filterByCustomer = (context: AlgoliaCustomContext): string =>
  context.customerFromQuery ? `customerId:${context.customerFromQuery?.id}` : "";

export const findHighlightedKey = (item: CloudAnalyticsHit): string => {
  let highlightedKey = "description";

  if (item._highlightResult) {
    Object.entries(item._highlightResult)
      .filter(([key]) => key !== "name" && key !== "customerName")
      .forEach(([key, value]) => {
        const highlightedValue = value as HighlightMatch;
        if (highlightedValue.matchLevel === "full") {
          highlightedKey = key;
          return;
        }
        if (highlightedValue.matchLevel === "partial") {
          highlightedKey = key;
        }
      });
  }

  return highlightedKey;
};

export const getIconByProducts = (isDarkMode: boolean, products?: string[]): string => {
  if (!products) {
    return isDarkMode ? InvoicesDark : InvoicesLight;
  }
  let found: boolean;
  found = products.some((ai) => ["amazon-web-services-standalone", "amazon-web-services"].includes(ai));
  if (found) {
    return AwsIcon;
  }
  found = products.includes("g-suite");
  if (found) {
    return GSuiteIcon;
  }
  found = products.some((ai) =>
    [
      "google-cloud-project-standalone",
      "google-cloud-project",
      "google-cloud-standalone",
      "google-cloud-direct",
      "google-cloud",
    ].includes(ai)
  );
  if (found) {
    return GCPIcon;
  }
  found = products.includes("office-365");
  if (found) {
    return office365Icon;
  }
  found = products.includes("microsoft-azure");
  if (found) {
    return AzureIcon;
  }
  found = products.includes("zendesk");
  if (found) {
    return ZendeskIcon;
  }
  found = products.includes("bettercloud");
  if (found) {
    return BettercloudIcon;
  }
  return isDarkMode ? InvoicesDark : InvoicesLight;
};

export const composeCloudAnalyticsSubtitle = (
  item: CloudAnalyticsHit,
  isDoitEmployee: boolean
): ParsedAttributeWithStyle[] => {
  const customerParts: ParsedAttributeWithStyle[] = [];
  const ownerParts: ParsedAttributeWithStyle[] = [];
  const highlightedParts: ParsedAttributeWithStyle[] = [];

  const subtitleParts: ParsedAttributeWithStyle[] = [];

  if (isDoitEmployee) {
    const temporaryCustomerParts = parseAlgoliaHitHighlight({
      hit: item,
      attribute: ["customerName"],
    });
    if (temporaryCustomerParts.length > 0) {
      customerParts.push({ value: "Customer: ", isHighlighted: false, bold: true });
      customerParts.push(...temporaryCustomerParts);
    }
  }

  const owner = item.collaborators?.find((collaborator) => collaborator.role === "owner");

  if (owner) {
    ownerParts.push(
      { value: "Owner: ", isHighlighted: false, bold: true },
      { value: owner.email ?? "", isHighlighted: false }
    );
  }

  const subtitleKey = findHighlightedKey(item);
  highlightedParts.push(
    ...parseAlgoliaHitHighlight({
      hit: item,
      attribute: [subtitleKey],
    })
  );

  // add customer, visible for doit employees only
  if (customerParts.length > 0) {
    subtitleParts.push(...customerParts);
  }

  // separator
  if (subtitleParts.length > 0 && ownerParts.length > 0) {
    subtitleParts.push({ value: "  |  ", isHighlighted: false });
  }

  // add owner, visible for everyone
  if (ownerParts.length > 0) {
    subtitleParts.push(...ownerParts);
  }

  // separator
  if (subtitleParts.length > 0 && highlightedParts.length > 0) {
    subtitleParts.push({ value: "  |  ", isHighlighted: false });
  }

  // add highlighted part, different from name
  if (highlightedParts.length > 0) {
    subtitleParts.push(...highlightedParts);
  }

  return subtitleParts;
};

export const composeInvoicesSubtitle = (item: InvoiceHit): ParsedAttributeWithStyle[] => {
  const customerName = item.metadata?.customer?.name;
  const date = item.IVDATE && DateTime.fromJSDate(new Date(item.IVDATE)).toFormat("dd MMM yyyy").toString();

  const subtitleParts: ParsedAttributeWithStyle[] = [];

  if (customerName) {
    subtitleParts.push({ value: "Customer: ", isHighlighted: false, bold: true });
    subtitleParts.push({ value: customerName, isHighlighted: false });
  }

  if (subtitleParts.length > 0 && date) {
    subtitleParts.push({ value: "  |  ", isHighlighted: false });
  }

  if (date) {
    subtitleParts.push({ value: "Date: ", isHighlighted: false, bold: true });
    subtitleParts.push({ value: date, isHighlighted: false });
  }
  return subtitleParts;
};

export const partsToString = (parts: ParsedAttributeWithStyle[]): string => parts.map((part) => part.value).join("");

export const isSearchForCustomer = (query: string, context: AlgoliaCustomContext): boolean =>
  context.isDoitEmployee && query.startsWith("@") && !context.customerFromQuery;

export const getAlgoliaAnalyticsProps = (context: AlgoliaCustomContext): Partial<SearchOptions> => ({
  analytics: true,
  clickAnalytics: true,
  analyticsTags: [
    context.customerName ? context.customerName : "noCustomerContext",
    context.isDoitEmployee ? "doitSearch" : "customerSearch",
  ],
});
