import { type EditFormValues } from "./types";

export const fieldNames: Record<keyof EditFormValues, keyof EditFormValues> = {
  status: "status",
  computeDiscount: "computeDiscount",
  computeEffectiveDate: "computeEffectiveDate",
  rdsStatus: "rdsStatus",
  rdsDiscount: "rdsDiscount",
  rdsEffectiveDate: "rdsEffectiveDate",
  rdsTargetPercentage: "rdsTargetPercentage",
  sagemakerStatus: "sagemakerStatus",
  sagemakerDiscount: "sagemakerDiscount",
  sagemakerEffectiveDate: "sagemakerEffectiveDate",
  minSpend: "minSpend",
  maxSpend: "maxSpend",
  managed: "managed",
  seasonal: "seasonal",
  targetPercentage: "targetPercentage",
  statusChangeReason: "statusChangeReason",
  keepActiveEvenWhenOnCredits: "keepActiveEvenWhenOnCredits",
};
