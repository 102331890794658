import { type ElementType, type ReactNode } from "react";

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { globalText } from "../../assets/texts";
import LoadingButton from "../LoadingButton";
import { useFormDialog } from "./hooks";

type FormDialogProps = {
  title: string;
  subTitle?: string;
  anchorContent: ReactNode;
  FormComponent: ElementType;
  formProps: Record<string, any>;
};

const FormDialog = ({ title, subTitle, anchorContent, FormComponent, formProps }: FormDialogProps) => {
  const { setIsOpen, isOpen, submitDialog, formRef, isLoading } = useFormDialog();

  return (
    <>
      <Box
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {anchorContent}
      </Box>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ pb: 0 }}>
          <Stack spacing={1} sx={{ pb: 2 }}>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body1" color="textSecondary">
              {subTitle}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <FormComponent
            ref={formRef}
            postSubmitHandler={() => {
              setIsOpen(false);
            }}
            {...formProps}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            variant="text"
          >
            {globalText.CANCEL}
          </Button>

          <LoadingButton
            onClick={submitDialog}
            loading={isLoading}
            variant="contained"
            mixpanelEventId="component.form-dialog.send"
          >
            {globalText.SEND}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FormDialog;
