import { useMemo } from "react";

import uniq from "lodash/uniq";
import { v4 as uuid } from "uuid";

import { type ManualPurchaseItem, type UniqueCustomerValues } from "../types";
import { useCustomerWorkloads } from "./workloadsHooks";

export function useUniqueCustomerValues(customerId: string): UniqueCustomerValues | undefined {
  const [customerWorkloads] = useCustomerWorkloads(customerId);
  return useMemo(() => {
    if (customerWorkloads) {
      return {
        billingAccountId: uniq(customerWorkloads.map((workload) => workload.billingAccountId)),
        hardware: uniq(customerWorkloads.map((workload) => workload.hardware)),
        region: uniq(customerWorkloads.map((workload) => workload.region)),
        type: uniq(customerWorkloads.map((workload) => workload.type)),
      };
    }
  }, [customerWorkloads]);
}

export function useDefaultPurchaseItem(customerSku?: UniqueCustomerValues): ManualPurchaseItem | undefined {
  return useMemo(() => {
    if (customerSku) {
      return {
        billingAccountId: customerSku.billingAccountId[0],
        region: customerSku.region[0],
        type: customerSku.type[0],
        hardware: customerSku.hardware[0],
        amount: 0,
        cost: 0,
        id: uuid(),
        isValid: false,
      };
    }
  }, [customerSku]);
}
