import { type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import { Autocomplete, TextField } from "@mui/material";

import { settingsGeneralTexts } from "../../../../assets/texts";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";

export function Currency({ value, onChange }: { value: CurrencyCode; onChange: (newValue: CurrencyCode) => void }) {
  return (
    <Autocomplete
      disableClearable
      id="Currency-autocomplete"
      options={Object.values<CurrencyCode>(CurrencyCodes)}
      value={value}
      data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.CURRENCY_SELECT}
      onChange={(event, newValue: CurrencyCode | null) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={settingsGeneralTexts.CURRENCY}
          variant="outlined"
          margin="normal"
          data-cy={`${DATA_CY_GENERAL_SETTINGS_SELECTORS.CURRENCY_ITEM}-${value}`}
        />
      )}
    />
  );
}
