import { useCallback, useRef } from "react";

import Upload from "@mui/icons-material/Upload";
import { Button } from "@mui/material";

type Props = {
  uploadName?: string;
  onDataReceived: (data: { data: string; filename: string }) => void;
};

export const UploadImitationButton = ({ uploadName, onDataReceived }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const upload = useCallback(
    (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event) => {
        onDataReceived({ data: String(event.target?.result).trim(), filename: file.name });
      };

      reader.readAsText(file);
    },
    [onDataReceived]
  );

  const triggerUpload = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <>
      <Button variant="outlined" color="primary" startIcon={<Upload />} onClick={triggerUpload}>
        Upload
      </Button>

      <form onSubmit={upload} key={Math.random()} style={{ display: "none" }}>
        <input
          ref={inputRef}
          type="file"
          name="file"
          onChange={upload}
          accept=".csv"
          data-cy={`upload-input-${uploadName}`}
        />
      </form>
    </>
  );
};
