import { getPlanActuals } from "../RampList/getPlanActuals";
import { type RampListItemData, type RampPlanModel } from "../types";
import { periodsDataForChart } from "../utils";

export type AggregatedDataObjType = {
  x?: number;
  y?: number | null;
  id?: null | string;
  currentMonthActual?: number;
};

export const getRampDataArrays = (planData: RampPlanModel | RampListItemData) => {
  const plannedForChart = periodsDataForChart(
    planData.commitmentPeriods ?? [],
    (period) => ({ data: period.planned, dataName: "planned" }),
    "planTargetReached",
    planData.targetAmount
  );
  const actualsForChart = periodsDataForChart(
    planData.commitmentPeriods ?? [],
    (period) => ({ data: period.actuals, dataName: "actuals" }),
    "actualTargetReached",
    planData.targetAmount
  );

  // Corrects for rounding errors in planned spend. Planned spend should not be below the original commitment
  // Rounding errors already should never exceed 0.1% anyway, but it looks strange in the tooltip
  if (
    plannedForChart[plannedForChart.length - 1].y! < planData.targetAmount ||
    Math.abs(planData.targetAmount - plannedForChart[plannedForChart.length - 1].y!) <=
      0.001 * plannedForChart[plannedForChart.length - 1].y!
  ) {
    plannedForChart[plannedForChart.length - 1].y = planData.targetAmount;
  }

  // updating new goal reach date if total actuals already reached plan target
  for (let i = 0; i < plannedForChart.length; i++) {
    // addition for actuals
    const planActualsFlatArr = getPlanActuals(planData);
    const currentActual: number = planActualsFlatArr.length ? planActualsFlatArr[i] : 0;
    actualsForChart[i] = { ...actualsForChart[i], currentMonthActual: currentActual };

    // fix occasional missing "x" in actuals
    if (!actualsForChart[i].x) {
      actualsForChart[i].x = plannedForChart[i].x;
    }
  }
  return { plannedForChart, actualsForChart };
};
