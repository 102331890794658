import { TableCell, Typography } from "@mui/material";
import { red } from "@mui/material/colors";

type Props = {
  text: string;
};

export const WarningCell = ({ text }: Props) => (
  <TableCell>
    <Typography
      variant="body2"
      fontWeight={600}
      padding={0.5}
      borderRadius={1}
      color={red[700]}
      width="fit-content"
      sx={{
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #D32F2F;",
      }}
    >
      {" "}
      {text}{" "}
    </Typography>
  </TableCell>
);
