import { SuperQueryModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";

type Props = {
  recommendationKey: string;
};
export const Recommendation = ({ recommendationKey }: Props) => {
  const selectedPeriod = "last 30 days";

  const [data, , error] = useDocumentDataOnce(getCollection(SuperQueryModel).doc("display-messages"));

  if (error) {
    return null;
  }

  const recommendation = data?.[recommendationKey];

  if (!recommendation) {
    return null;
  }

  return <Typography variant="caption">{recommendation.replaceAll("{{time-frame}}", selectedPeriod)}</Typography>;
};
