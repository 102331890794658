import { type ReactNode } from "react";

import { Grid } from "@mui/material";

import SectionHeader from "../SectionHeader";

export const WelcomeBlockWrapper = ({ children }: { children: ReactNode }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <SectionHeader text="Welcome to the DoiT Console" />
    </Grid>
    {children}
  </Grid>
);
