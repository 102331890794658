import { useMemo } from "react";

import { AccountManagerCompanies } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import AccountManagersFilterTable from "./table/AccountManagersFilterTable";

export const restrictedCompaniesKeys = [
  AccountManagerCompanies.DOIT as string,
  AccountManagerCompanies.MicrosoftAzure as string,
];

const AccountManagers = () => {
  const [allAccountManagers] = AccountManagersHooks.useAllAccountManagers();
  const allAccountManagersSortedByName = useMemo(
    () => allAccountManagers?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [],
    [allAccountManagers]
  );

  return (
    <Box sx={{ mx: 2 }}>
      <AccountManagersFilterTable accountManagers={allAccountManagersSortedByName} />
    </Box>
  );
};

export default AccountManagers;
