import { createContext, useContext } from "react";

import noop from "lodash/noop";

type ContextProps = {
  fetcher: (data: any) => Promise<Response>;
};

const defaultValue: ContextProps = {
  fetcher: noop as any,
};

const avaContext = createContext(defaultValue);
export const AvaContextProvider = avaContext.Provider;
export const useAvaContext = () => useContext(avaContext);
