import { useMemo } from "react";

import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import Hide from "../../../Components/HideChildren/Hide";
import { type KnownIssue } from "../types";

type Column = {
  label: string;
  value: keyof KnownIssue;
  isMobileHidden?: boolean;
  disablePadding?: boolean;
};

const columns: Column[] = [
  { label: "Platform", value: "platform", isMobileHidden: true, disablePadding: true },
  { label: "Product", value: "affectedProduct", disablePadding: true },
  { label: "Title", value: "title", isMobileHidden: true, disablePadding: true },
  { label: "Status", value: "status", isMobileHidden: true },
  { label: "Date", value: "dateTime" },
];

type ColumnCellProps = {
  column: Column;
  orderBy: keyof KnownIssue;
  orderDirection: "asc" | "desc";
  onColumnSelect: (columnValue: Column["value"]) => void;
};

const ColumnCell = ({ column, orderBy, orderDirection, onColumnSelect }: ColumnCellProps) => (
  <Hide mdDown={column.isMobileHidden}>
    <TableCell
      align="left"
      padding={column.disablePadding ? "none" : "normal"}
      sortDirection={orderBy === column.value && orderDirection}
      onClick={() => {
        onColumnSelect(column.value);
      }}
    >
      <TableSortLabel active={orderBy === column.value} direction={orderDirection}>
        {column.label}
      </TableSortLabel>
    </TableCell>
  </Hide>
);

export type KnownIssuesTableHeaderProps = Omit<ColumnCellProps, "column"> & {
  onAllKnownIssuesSelect: (checked: boolean) => void;
  isSomeKnownIssuesSelected?: boolean;
  isAllKnownIssuesSelected?: boolean;
  isAllowedToModify?: boolean;
};

export const KnownIssuesTableHeader = ({
  onAllKnownIssuesSelect,
  orderBy,
  orderDirection,
  onColumnSelect,
  isSomeKnownIssuesSelected,
  isAllKnownIssuesSelected,
  isAllowedToModify,
}: KnownIssuesTableHeaderProps) => {
  const columnCells = useMemo(
    () =>
      columns.map((column, index) => (
        <ColumnCell
          key={index}
          column={column}
          orderBy={orderBy}
          orderDirection={orderDirection}
          onColumnSelect={onColumnSelect}
        />
      )),
    [orderBy, orderDirection, onColumnSelect]
  );

  const handleChange = (_, checked) => {
    onAllKnownIssuesSelect(checked);
  };

  const isOnlySomeKnownIssuesSelected = isSomeKnownIssuesSelected && !isAllKnownIssuesSelected;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {isAllowedToModify && (
            <Checkbox
              color="primary"
              indeterminate={isOnlySomeKnownIssuesSelected}
              checked={isAllKnownIssuesSelected}
              onChange={handleChange}
              inputProps={{ "aria-label": "Select all known issues" }}
            />
          )}
        </TableCell>
        {columnCells}
        <Hide mdDown>
          <TableCell />
        </Hide>
      </TableRow>
    </TableHead>
  );
};
