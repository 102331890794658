import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Link, Stack, Typography } from "@mui/material";

import { ContactSupportButton } from "../../../Flexsave/Standalone/GCP/Steps/Common/ContactSupportButton";

type Props = {
  cloud: SaaSConsoleType;
  showContactSupportButton?: boolean;
};

export const UsefulLinks = ({ cloud, showContactSupportButton = true }: Props) => (
  <Stack direction="row" alignItems="center" spacing={2} pb={10} pt={2}>
    <Typography variant="subtitle1" fontWeight="500">
      Useful links:
    </Typography>

    {showContactSupportButton && <ContactSupportButton />}

    <Link
      href={`https://help.doit.com/docs/general/saas/console-${cloud.toLocaleLowerCase()}`}
      target="_blank"
      underline="hover"
    >
      Help documentation
    </Link>
  </Stack>
);
