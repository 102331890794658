import { type JSX, useCallback, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import {
  AssetTypeGoogleCloudStandalone,
  type BillingStandaloneModel,
  type GoogleCloudDirectAssetModel,
  type GoogleCloudStandaloneAssetModel,
} from "@doitintl/cmp-models";
import WarningIcon from "@mui/icons-material/Warning";
import { Alert, Chip, Hidden, TableCell, TableRow, Typography } from "@mui/material";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type Asset } from "../../../../../types";
import DeleteGoogleCloudDirectAssetDialog from "../../../Cards/components/DeleteGoogleCloudDirectAssetDialog";
import { linkLabelWithIcon, NotApplicable, NotAvailable } from "../../../Tabs/components/typographics";
import UpdateGoogleCloudDirectAssetForm from "../../../Tabs/components/UpdateGoogleDirectAssetForm";
import { CUSTOMER_ID_PLACEHOLDER, type GcDirectErrorLinkAction, gcDirectErrorMessages } from "../../errors";

export type GcpDirectAssetRowData = {
  assetId: string;
  asset: any;
  id: string;
  project: string | JSX.Element;
  dataset: string | JSX.Element;
  status: string;
  statusChip: string;
  reason?: string;
};

type Props = { row: GcpDirectAssetRowData };

type StatusChipColor = "success" | "error" | "primary" | "default" | "secondary" | "info" | "warning" | undefined;

type StatusChipInfo = { label: string; color: StatusChipColor };

type StandaloneStatusMap = Record<string, BillingStandaloneModel>;

const getStatus = (asset, standaloneStatusMap: StandaloneStatusMap): string => {
  if (asset.id in standaloneStatusMap) {
    return standaloneStatusMap[asset.id].billingImportStatus.status;
  }

  return asset.data.copyJobMetadata?.status ?? "error";
};
const statusChipInfo: Record<string, StatusChipInfo> = {
  /*
   * GCP direct asset status options
   * "idle" is unpopulated because a design/product decision has been made
   * that it should be displayed as "N/A" instead of a chip.
   */
  done: { label: "Success", color: "success" },
  error: { label: "Error", color: "error" },
  processing: { label: "Processing", color: "primary" },
  idle: { label: "", color: undefined },

  // GCP Flexsave Standalone status options
  pending: { label: "processing", color: "primary" },
  started: { label: "processing", color: "primary" },
  completed: { label: "Success", color: "success" },
  "customer-enabled": { label: "Success", color: "success" },
  failed: { label: "Error", color: "error" },
};

const getReason = (
  asset: Asset<GoogleCloudDirectAssetModel | GoogleCloudStandaloneAssetModel>,
  standaloneStatusMap: StandaloneStatusMap
): string | undefined => {
  if (asset.data.type === AssetTypeGoogleCloudStandalone) {
    return standaloneStatusMap[asset.id]?.billingImportStatus?.error;
  }

  return asset.data.copyJobMetadata.reason;
};

/**
 * Builds asset row data from asset and standalone status map.
 * @param asset asset to which the row relates
 * @param standaloneStatusMap object keyed by asset IDs, and valued with the corresponding status from /customers/{customerId}/flexsaveStandalone/{assetId}
 */
export const populateRowFromAsset = (
  asset: Asset<GoogleCloudDirectAssetModel | GoogleCloudStandaloneAssetModel>,
  standaloneStatusMap: Record<string, BillingStandaloneModel>
): GcpDirectAssetRowData => {
  const getTable = () => ("tables" in asset.data ? asset.data.tables?.[0] : undefined);
  const status = getStatus(asset, standaloneStatusMap);
  const { label } = statusChipInfo[status];
  const statusChip = status === "idle" ? NotApplicable : label;

  return {
    assetId: asset.id,
    asset,
    id: asset.data.properties.billingAccountId,
    project: getTable()?.project ?? NotAvailable,
    dataset: getTable()?.dataset ?? NotAvailable,
    status,
    statusChip,
    reason: getReason(asset, standaloneStatusMap),
  };
};

const GcAssetRowDirect = ({ row }: Props) => {
  const { customer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);

  const closeEdit = useCallback(() => {
    setIsEditOpen(false);
  }, []);
  const closeDelete = useCallback(() => {
    setIsDeleteOpen(false);
  }, []);

  const menuOptions: ThreeDotsMenuOption[] = [];
  menuOptions.push({
    key: `gcp-direct-billing-account-${row.id}`,
    label: linkLabelWithIcon("Manage billing account"),
    type: "link",
    href: `https://console.cloud.google.com/billing/${row.id}`,
    newTab: true,
  });
  const buildMenuFromErrorMessage = (): void => {
    if (!["error", "failed"].includes(row.status) || !row.reason) {
      return;
    }

    const cmpErrorCandidates = gcDirectErrorMessages.filter(
      (directError) => directError.message.trim() === row.reason?.trim()
    );

    if (!cmpErrorCandidates.length) {
      return;
    }

    const cmpError = cmpErrorCandidates[0];

    const getUrlFromError = () =>
      (cmpError.action as GcDirectErrorLinkAction).linkTemplate.replace(CUSTOMER_ID_PLACEHOLDER, customer.id);

    // Only one modal is currently used in the list of errors -- Manage connection.
    cmpError.action.actionType === "modal" &&
      menuOptions.push({
        key: `gcp-direct-billing-connection-${row.id}`,
        label: <Typography variant="body1">Manage connection</Typography>,
        action: () => {
          setIsEditOpen(true);
        },
      });

    cmpError.action.actionType === "link" &&
      menuOptions.push({
        key: `gcp-direct-link-${row.id}`,
        label: (
          <Typography variant="body1" color="inherit" component={RouterLink} to={getUrlFromError()}>
            {cmpError.action.label}
          </Typography>
        ),
      });

    isDoitEmployee &&
      menuOptions.push({
        key: `gcp-direct-delete-connection-${row.id}`,
        label: (
          <Typography color="error" variant="body1">
            Delete connection
          </Typography>
        ),
        action: () => {
          setIsDeleteOpen(true);
        },
      });
  };
  buildMenuFromErrorMessage();

  return (
    <>
      {isEditOpen && <UpdateGoogleCloudDirectAssetForm asset={row.asset} onClose={closeEdit} />}
      {isDeleteOpen && (
        <DeleteGoogleCloudDirectAssetDialog onClose={closeDelete} id={row.assetId} customerId={customer.id} />
      )}
      <TableRow>
        <CellsWrapper>
          <TableCell sx={{ borderBottom: "none" }}>
            <Typography variant="body2" component="span">
              {row.id}
            </Typography>
          </TableCell>
          <TableCell sx={{ borderBottom: "none" }}>
            <Typography variant="body2" component="span">
              {row.project}
            </Typography>
          </TableCell>
          <Hidden smDown>
            <TableCell sx={{ borderBottom: "none" }}>
              <Typography variant="body2" component="span">
                {row.dataset}
              </Typography>
            </TableCell>
          </Hidden>
          <Hidden smDown>
            <TableCell sx={{ borderBottom: "none" }}>
              <Chip label={row.statusChip} color={statusChipInfo[row?.status]?.color} sx={{ height: "20px" }} />
            </TableCell>
          </Hidden>
          <TableCell sx={{ borderBottom: "none" }} padding="checkbox">
            {menuOptions.length > 0 && <ThreeDotsMenu options={menuOptions} size="small" />}
          </TableCell>
        </CellsWrapper>
      </TableRow>
      {(row.reason ?? "").length > 0 && (
        <TableRow>
          <TableCell colSpan={5}>
            <Alert severity="error" icon={<WarningIcon aria-hidden color="inherit" fontSize="small" />}>
              {row.reason}
            </Alert>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default GcAssetRowDirect;
