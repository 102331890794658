import { useState } from "react";

import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const AWSContractVersion = 2;
export const GCPContractVersion = 2;

export const Contract = ({ type }: { type: FlexsaveType }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const url =
    type === FlexsaveTypes.AWS
      ? "https://docs.google.com/document/d/e/2PACX-1vTdufS2ff8qgxehd_JRKnfn01T8RNtGkioH3mwmsTu0v9hXDZD06NPhq5dj5Pz5PEROzbMbuKHWChJk/pub?embedded=true"
      : "https://docs.google.com/document/d/e/2PACX-1vQTNr6QdMAGq88xtd0v-vzFXttiLUZz1VVcvkpKkfANen4i_yrQfBKsG1exQyPbctA8gIWN9sEl3P3U/pub?embedded=true";

  return (
    <Stack sx={{ height: 400, alignItems: "center", justifyContent: "center" }}>
      {loading && <CircularProgress />}
      {/* eslint-disable-next-line @eslint-react/dom/no-missing-iframe-sandbox */}
      <iframe
        src={url}
        width="128%"
        height="100%"
        style={{ display: loading ? "none" : "block", border: "none", padding: 0 }}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </Stack>
  );
};
