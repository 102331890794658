import { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { getTotalActuals } from "../RampList/itemDetails";
import { type AggregatedDataObjType, getRampDataArrays } from "../RampPlan/getRampDataArrays";
import { type RampListItemData, type RampPlanModel } from "../types";
import { findRelevantContractPeriods, periodsDataForChart } from "../utils";

const useGetRampPlanChartData = (planData: RampPlanModel | RampListItemData | null | undefined) => {
  const [actualDataForChart, setActualDataForChart] = useState<AggregatedDataObjType[]>([]);
  const [planDataForChart, setPlanDataForChart] = useState<AggregatedDataObjType[]>([]);

  const { contracts } = useCustomerContext();

  const dateTimeEstEndDate: DateTime | undefined = useMemo(() => {
    if (!planData) {
      return;
    }

    const actualSpendToDate = getTotalActuals(planData);
    const dateTimeStartDate = DateTime.fromSeconds(planData.startDate.seconds).toUTC();
    const daysSincePlanStart = DateTime.now().toUTC().diff(dateTimeStartDate, "days").days;
    const avgDailySpend = actualSpendToDate / daysSincePlanStart;

    // ramp plan spend not started yet
    if (avgDailySpend === 0) {
      return DateTime.fromSeconds(planData.origEstEndDate.seconds).toUTC();
    }
    const daysRemaining = Math.ceil((planData.targetAmount - actualSpendToDate) / avgDailySpend);

    return DateTime.now().toUTC().plus({ days: daysRemaining }).toUTC();
  }, [planData]);

  const originalPlanForChart: AggregatedDataObjType[] | null = useMemo(() => {
    if (!planData) {
      return [];
    }

    const getOrigPeriods = () => {
      // get original periods from Firebase, if exists
      if (planData.origCommitmentPeriods) {
        return planData.origCommitmentPeriods;
      }
      // if not in Firebase, calculate original periods from contract and update Firebase
      return findRelevantContractPeriods(contracts, planData?.startDate?.seconds, planData?.origEstEndDate?.seconds);
    };

    const origPeriods = getOrigPeriods();

    const tempOrigPlan = periodsDataForChart(
      origPeriods,
      (period) => ({ data: period.planned, dataName: "planned" }),
      null,
      planData.targetAmount
    );

    // Corrects for rounding errors in the original spend commitment, likely due to split months in the ramp plan.
    // Rounding errors already should never exceed 0.1% anyway, but it looks strange in the tooltip
    // periodsDataForChart() returns an empty array for malformed ramp plans that have no origCommitmentPeriods or corresponding contracts
    if (tempOrigPlan.length) {
      tempOrigPlan[tempOrigPlan.length - 1].y = planData.targetAmount;
    }
    return tempOrigPlan;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planData?.commitmentPeriods, planData?.origCommitmentPeriods, contracts]);

  useEffect(() => {
    if (!planData) {
      return;
    }
    const { plannedForChart, actualsForChart } = getRampDataArrays(planData);

    plannedForChart.forEach((month, idx) => {
      // catch rounding errors where planned amount is less than the original planned amount
      if (plannedForChart && originalPlanForChart?.length && plannedForChart[idx].y! < originalPlanForChart[idx].y!) {
        plannedForChart[idx].y = originalPlanForChart[idx].y!;
      }

      if (dateTimeEstEndDate && month.x === dateTimeEstEndDate.startOf("month").toMillis()) {
        plannedForChart[idx].id = "planTargetReached";
      }
    });

    setActualDataForChart(actualsForChart);
    setPlanDataForChart(plannedForChart);
  }, [dateTimeEstEndDate, originalPlanForChart, planData]);

  return { actualDataForChart, planDataForChart, originalPlanForChart, dateTimeEstEndDate };
};

export default useGetRampPlanChartData;
