import { useCallback, useEffect, useMemo, useState } from "react";

import { Alert, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { googleSheetsExportDialogText } from "../../../assets/texts";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import LoadingButton from "../../../Components/LoadingButton";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { Scopes, useGapiContext } from "../../../Context/GapiContext";
import { consoleErrorWithSentry } from "../../../utils";
import type { AWSAssetExportData } from "./components/AwsAssetRow"; // Assuming you have this type defined elsewhere

type Props = {
  onClose: () => void;
  isDialogOpen?: boolean;
  isDialog?: boolean;
  headers: string[];
  rowData: AWSAssetExportData[];
};

const ExportToGoogleSheetsDialog = ({ headers, rowData, isDialog, isDialogOpen, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const [lastExportFailedReason, setLastExportFailedReason] = useState<string>();
  const { getToken, isAuthenticated } = useGapiContext();
  const { customer } = useCustomerContext();

  useMountEffect(() => {
    getToken(Scopes.DriveFile);
  });

  useEffect(() => {
    setLoading(false);
  }, [isDialogOpen]);

  const setHeader = useCallback(
    (result: gapi.client.sheets.RowData[]) => {
      const header: gapi.client.sheets.CellData[] = headers.map((header) => ({
        userEnteredValue: {
          stringValue: header,
        },
      }));

      result.push({
        values: header,
      });
    },
    [headers]
  );

  const setRows = useCallback(
    (result: gapi.client.sheets.RowData[]) => {
      for (const row of rowData) {
        const cells: gapi.client.sheets.CellData[] = Object.values(row).map((value) => {
          if (typeof value === "number") {
            return {
              userEnteredValue: { numberValue: value },
            } satisfies gapi.client.sheets.CellData;
          }
          return {
            userEnteredValue: { stringValue: value as string },
          } satisfies gapi.client.sheets.CellData;
        });
        result.push({
          values: cells,
        });
      }
    },
    [rowData]
  );

  const getDataValues = useCallback((): gapi.client.sheets.RowData[] => {
    if (!headers.length || !rowData.length) {
      return [];
    }

    const result: gapi.client.sheets.RowData[] = [];
    setHeader(result);
    setRows(result);

    return result;
  }, [headers, rowData, setHeader, setRows]);

  const handleExport = useCallback(async () => {
    setLoading(true);

    await getToken(Scopes.DriveFile);
    const value = getDataValues();

    const timestamp = DateTime.local().toISO({ includeOffset: false });
    const spreadsheetBody: gapi.client.sheets.Spreadsheet = {
      properties: {
        title: `${customer.name.replace(/\s/g, "-")}-aws-assets-${timestamp}`,
      },
      sheets: [
        {
          data: [
            {
              startRow: 0,
              startColumn: 0,
              rowData: value,
            },
          ],
        },
      ],
    };

    try {
      const response = await gapi.client.sheets.spreadsheets.create({}, spreadsheetBody);
      if (response.result.spreadsheetUrl) {
        setLastExportFailedReason(undefined);
        const win = window.open(response.result.spreadsheetUrl, "_blank");
        if (win !== null) {
          win.focus();
        }
      }
      onClose();
    } catch (err: any) {
      setLastExportFailedReason(err.result.error.message);
      consoleErrorWithSentry(err.result.error);
    } finally {
      setLoading(false);
    }
  }, [customer.name, getDataValues, getToken, onClose]);

  const dialogData = useMemo(() => {
    if (isAuthenticated(Scopes.DriveFile)) {
      const saveContent = googleSheetsExportDialogText.DIALOG_SAVE;
      return {
        content: saveContent,
        actionButtonText: googleSheetsExportDialogText.DIALOG_EXPORT,
        actionCallback: handleExport,
      };
    }

    return {
      content: googleSheetsExportDialogText.SELECT_ACCOUNT,
      actionButtonText: googleSheetsExportDialogText.DIALOG_ALLOW,
      actionCallback: getToken,
    };
  }, [getToken, handleExport, isAuthenticated]);

  const alertUI = useCallback(
    ({ content, actionButtonText, actionCallback }) => {
      const getSeverity = () => {
        if (lastExportFailedReason === undefined) {
          return "info";
        }

        return lastExportFailedReason ? "success" : "error";
      };
      return (
        <Alert
          variant="standard"
          severity={getSeverity()}
          action={
            <>
              <LoadingButton
                color="inherit"
                size="small"
                variant="outlined"
                onClick={actionCallback}
                loading={loading}
                disabled={loading}
                mixpanelEventId={`analytics.renderer.google-sheets.${actionButtonText}`}
              >
                {actionButtonText}
              </LoadingButton>
            </>
          }
        >
          {content}
        </Alert>
      );
    },
    [lastExportFailedReason, loading]
  );

  const dialogUI = useCallback(
    ({ content, actionButtonText, actionCallback }) => (
      <Dialog open={isDialogOpen ?? false} onClose={onClose}>
        <DialogTitle>{googleSheetsExportDialogText.DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          {!!lastExportFailedReason && (
            <Alert variant="standard" severity="error">
              {lastExportFailedReason}
            </Alert>
          )}
          <DialogContentText>
            <Typography component="span">{content}</Typography>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose} variant="text">
            {googleSheetsExportDialogText.DIALOG_CANCEL}
          </Button>
          <LoadingButton
            color="primary"
            onClick={actionCallback}
            disabled={loading}
            loading={loading}
            variant="contained"
            mixpanelEventId={`analytics.renderer.google-sheets.${actionButtonText}`}
          >
            {actionButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    ),
    [isDialogOpen, lastExportFailedReason, loading, onClose]
  );
  if (!headers.length || !rowData.length || !isAuthenticated(Scopes.DriveFile)) {
    return null;
  }

  return isDialog ? dialogUI(dialogData) : alertUI(dialogData);
};

export default ExportToGoogleSheetsDialog;
