import { modelFromPath } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import type { ReportTemplateWithVersion, TemplateFromBackend, VersionFromBackend } from "./types";

const formatStringDate = (date: string) => DateTime.fromISO(date).toFormat("dd LLL yyyy, HH:mm");

export const transformToTemplateWithVersion = (
  template: TemplateFromBackend,
  version: VersionFromBackend
): ReportTemplateWithVersion => ({
  template: {
    ...template,
    activeReport: template.activeReport?.Path ? modelFromPath(template.activeReport.Path) : undefined,
    activeVersion: template.activeVersion?.Path ? modelFromPath(template.activeVersion.Path) : undefined,
    hidden: false,
    lastVersion: modelFromPath(template.lastVersion.Path),
    timeCreated: formatStringDate(template.timeCreated),
  },
  version: {
    ...version,
    approval: { ...version.approval, timeApproved: version.approval.timeApproved },
    previousVersion: version.previousVersion?.Path ? modelFromPath(version.previousVersion.Path) : undefined,
    report: modelFromPath(version.report.Path),
    template: modelFromPath(version.template.Path),
    timeCreated: formatStringDate(version.timeCreated),
    timeModified: formatStringDate(version.timeModified),
  },
});
