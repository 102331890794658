import { useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { DateTime } from "luxon";

import Cogs from "./Tabs/Cogs";
import Invoicing from "./Tabs/Invoicing";
import BillingTools from "./Tabs/BillingTools/BillingTools";
import OnHold from "./Tabs/OnHold/OnHold";
import DraftInvoices from "./Tabs/DraftInvoices/DraftInvoices";
import InvoiceView from "./Tabs/DraftInvoices/InvoiceView/InvoiceView";

function useDate() {
  const [date, setDate] = useState(DateTime.utc().startOf("month").minus({ months: 1 }));
  const memoizedSetDate = useCallback((value) => {
    setDate(value);
  }, []);

  return [date, memoizedSetDate];
}

const Billing = ({ pageId }) => {
  const [date, setDate] = useDate();

  return (
    <>
      {pageId === "billing" && <Redirect to="/billing/invoicing" />}
      {pageId === "billing:invoicing" && <Invoicing date={date} onChangeDate={setDate} />}
      {pageId === "billing:cogs" && <Cogs date={date} onChangeDate={setDate} />}
      {pageId === "billing:billing-tools" && <BillingTools />}
      {pageId === "billing:on-hold" && <OnHold />}
      {pageId === "billing:draft-invoices" && <DraftInvoices />}
      {pageId === "billing:draft-invoices:view" && <InvoiceView />}
    </>
  );
};

export default Billing;
