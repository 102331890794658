import { type SxProps, type Theme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import noop from "lodash/noop";

import { type HeaderColumn, type HeaderColumns, type HeaderColumnStyle } from "../../../types/FilterTable";
import { type NestedObjectLeaves } from "../../../utils/NestedKeyOf";
import Hide from "../../HideChildren/Hide";

export type FilterTableHeaderProps<T> = {
  columns: HeaderColumns<T>;
  selectedColumnValue?: NestedObjectLeaves<T>;
  sortDirection?: "asc" | "desc";
  columnSortCreator: (column: NestedObjectLeaves<T>) => () => void;
  handleAllItemsSelect: (_: unknown, checked: boolean) => void;
  isAllSelected?: boolean;
  isItemSelected?: boolean;
  showRowsSelection: boolean;
  tableName?: string;
  headerStyle?: SxProps<Theme>;
};

export const defaultHeaderStyle: HeaderColumnStyle = { align: "left", padding: "normal" };
export const standardToOption = (value: any) => ({ value, label: value.toString() });

export const FilterTableHeader = <T,>({
  selectedColumnValue,
  sortDirection,
  columnSortCreator,
  handleAllItemsSelect,
  isAllSelected,
  isItemSelected,
  columns,
  showRowsSelection,
  tableName,
  headerStyle,
}: FilterTableHeaderProps<T>) => {
  const columnHeader = (column?: HeaderColumn<T>) => (
    <TableCell
      padding={column?.style?.padding ?? defaultHeaderStyle.padding}
      align={column?.style?.align ?? defaultHeaderStyle.align}
      width={column?.style?.width}
      data-testid={`table-head-${column?.label}`}
      sx={headerStyle}
    >
      <Tooltip title={column?.tooltip ?? ""}>
        {column?.disableSort || !column?.value ? (
          <>{column?.label ?? ""}</>
        ) : (
          <TableSortLabel
            data-cy={`table-head-${column?.label}`}
            active={selectedColumnValue === column?.value}
            direction={sortDirection}
            onClick={column?.value !== "@" ? columnSortCreator(column?.value) : noop}
          >
            {column?.label ?? ""}
          </TableSortLabel>
        )}
      </Tooltip>
    </TableCell>
  );
  return (
    <TableHead data-testid={`table-head-${tableName}`}>
      <TableRow>
        {showRowsSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isAllSelected}
              indeterminate={isItemSelected && !isAllSelected}
              onChange={handleAllItemsSelect}
              inputProps={{ "aria-label": "select-all-rows-checkbox" }}
            />
          </TableCell>
        )}
        {columns.map((column, i) => (
          <Hide {...column.hidden} key={column.label || `empty-label-column${i}`}>
            {columnHeader(column)}
          </Hide>
        ))}
      </TableRow>
    </TableHead>
  );
};
