import { type JSX, useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { AssetModelBillingAnomalyModel, AssetTypeAmazonWebServices, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { getCollectionGroup } from "@doitintl/models-firestore";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/PlaylistAddCheck";
import WarningIcon from "@mui/icons-material/ReportProblem";
import { Card, Link } from "@mui/material";
import { orange as Orange } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import { type GridColDef, type GridRowsProp } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { type DateTimeMaybeValid } from "luxon/src/datetime";

import { useCustomerContext } from "../../Context/CustomerContext";
import { getGraphLength } from "../../Pages/AnomalyDetection/AlertTools";
import SparkLineTable from "../../Pages/AnomalyDetection/SparkLine/SparkLineTable";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { assetTypeName } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import { SkeletonCard } from "./SkeletonCard";
import { WidgetCardContentWithTable } from "./WidgetCards/Common/WidgetCardContentWithTable";

type CostAnomaliesWidgetCardProps = {
  fallbackComponent: JSX.Element;
  widgetHeight?: number;
};

const levelText = ["No alert", "Information", "Warning", "Critical"];
const levelIcons = [
  <CheckIcon key={0} color="primary" style={{ marginLeft: 10 }} />,
  <InfoIcon key={1} color="primary" style={{ marginLeft: 10 }} />,
  <WarningIcon key={2} style={{ color: Orange[300], marginLeft: 10 }} />,
  <ErrorIcon key={3} color="error" style={{ marginLeft: 10 }} />,
];

type AnomalyRow = {
  id: string;
  cloud: string;
  anomaly: any;
  severity: number;
  date: DateTimeMaybeValid;
  link: string;
};

export default function CostAnomaliesWidgetCard({
  fallbackComponent,
  widgetHeight = 200,
}: CostAnomaliesWidgetCardProps) {
  const { customer } = useCustomerContext();
  const [data, setData] = useState<any[] | null>();

  const { isMobile } = useFullScreen();

  const getDate = useCallback(
    (date: DateTimeMaybeValid) => (isMobile ? date.toFormat("yyyy-MM-dd") : date.toFormat("MMM dd, yyyy")),
    [isMobile]
  );

  useEffect(() => {
    getCollectionGroup(AssetModelBillingAnomalyModel)
      .where("customer", "==", customer.ref)
      .where("metadata.severity", "in", [2, 3])
      .orderBy("metadata.timestamp", "desc")
      .limit(18)
      .onSnapshot((docSnap) => {
        if (docSnap.empty) {
          setData(null);
          return;
        }

        setData(
          docSnap.docs.flatMap((item) => {
            if (!item.exists()) return [];
            return [
              {
                data: item.asModelData(),
                id: item.id,
              },
            ];
          })
        );
      });
  }, [customer.ref]);

  useMemo(() => {
    const timeFrame = [
      { id: "RISING_DAILY_COSTS", title: "Daily" },
      { id: "MULTIPLE_ALERTS_IN_A_DAY", title: "Hourly" },
    ];
    if (data) {
      data.forEach((element) => {
        const miniGraph: { x: number; y: number }[] = [];
        const gLength = getGraphLength(element.data.chart_data);
        Object.keys(element.data.chart_data)
          .sort()
          .forEach((key, i) => {
            if (i > gLength - 30) {
              const d = new Date(key.replace(/-/g, "/")).getTime();
              miniGraph.push({ y: element.data.chart_data[key].snapshot_value, x: d });
            }
          });

        element.data.metadata.miniGraph = miniGraph;
        element.data.metadata._ts = DateTime.fromSQL(element.data.metadata.timestamp);
        if (!element.data.metadata.context) {
          element.data.metadata.context = timeFrame[1].id;
        }
      });
    }
  }, [data]);

  const columns: GridColDef<AnomalyRow>[] = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        renderCell: (params) => (
          <Link
            color="inherit"
            component={RouterLink}
            to={`/customers/${customer.id}/anomaly/${params.row.link}/${params.row.id}`}
          >
            {getDate(params.row.date)}
          </Link>
        ),
      },
      {
        field: "cloud",
        headerName: "Cloud",
        flex: 1,
        renderCell: (params) => assetTypeName(params.row.cloud),
      },
      {
        field: "anomaly",
        headerName: "Anomaly",
        flex: 1,
        renderCell: (params) => <SparkLineTable miniGraph={params.row.anomaly} />,
      },
      {
        field: "severity",
        headerName: "Severity",
        renderCell: (params) => (
          <Tooltip title={levelText[params.row.severity]}>{levelIcons[params.row.severity]}</Tooltip>
        ),
      },
    ],
    [customer.id, getDate]
  );

  const rows: GridRowsProp<AnomalyRow> = useMemo(
    () =>
      data
        ? data.map((row) => ({
            id: row.id,
            cloud: row.data.metadata.platform,
            anomaly: row.data.metadata.miniGraph,
            severity: row.data.metadata.severity,
            date: DateTime.fromJSDate(row.data.metadata.timestamp.toDate()),
            link: row.data.metadata.platform.includes(AssetTypeAmazonWebServices)
              ? `${AssetTypeAmazonWebServices}-${row.data.metadata.project_id}`
              : `${AssetTypeGoogleCloud}-${row.data.metadata.billing_account_id}`,
          }))
        : [],
    [data]
  );

  if (data === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (data === null) {
    return fallbackComponent;
  }

  return (
    <Card>
      <WidgetCardHeader
        title="Cost Anomalies"
        subheader="Sudden spikes in your Google Cloud or Amazon Web Services usage"
      />
      <WidgetCardContentWithTable height={widgetHeight} rows={rows} columns={columns} />
    </Card>
  );
}
