import { useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";

import { useApiContext } from "../../../api/context";
import LoadingButton from "../../../Components/LoadingButton";
import { MultiEmailsInputText } from "../../../Components/MultiEmailsInputText";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Role } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import { getDefaultRoleRefByCustomer } from "../../../utils/permissions";
import { errorMessages, inviteUserDialogTestIds, text } from "./consts";
import Invites from "./handleInvite";
import { InviteUserDialogHelper } from "./InviteUserDialogHelper";
import RoleSelection from "./RoleSelection";
import { UserExistsDialogHelper } from "./UserExistsDialogHelper";
import { type ValidatedEmail, validateEmail } from "./validateEmail";

export type InviteUserDialogProps = {
  open: boolean;
  onClose: () => void;
  roleList: Role[];
};

export const InviteUserDialog = ({ open, onClose, roleList }: InviteUserDialogProps) => {
  const { customer } = useCustomerContext();
  const defaultRoleRef = getDefaultRoleRefByCustomer(customer);
  const { entities } = useEntitiesContext();
  const api = useApiContext();
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { onOpen: openSnackbar, onClose: closeSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [typingInProgressEmail, setTypingInProgressEmail] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [validatedEmails, setValidatedEmails] = useState<{ [key: string]: ValidatedEmail }>({});
  const [selectedRoleId, setSelectedRoleId] = useState<string>(defaultRoleRef.id);
  const [requireBillingProfile, setRequireBillingProfile] = useState(false);
  const [editUser, setEditUser] = useState(true);

  const { isMobile } = useFullScreen();
  const canSelectEditUser = useMemo(() => emails.length <= 1, [emails.length]);
  const validatedEmailsList = useMemo(() => Object.values(validatedEmails), [validatedEmails]);

  const validateEmails = useCallback(
    async (newEmails: string[]): Promise<{ [key: string]: ValidatedEmail } | undefined> => {
      setLoading(true);
      try {
        const newValidatedEmails = await Promise.all(
          newEmails.map((email, index) => validateEmail(email, index, { api, customerId: customer.id }))
        );
        const newValidatedEmailsObject: { [key: string]: ValidatedEmail } = {};
        newValidatedEmails.forEach((validatedEmail) => {
          newValidatedEmailsObject[validatedEmail.email] = validatedEmail;
        });
        return newValidatedEmailsObject;
      } catch (e: any) {
        consoleErrorWithSentry(e);
        openSnackbar({
          message: `couldn't validate emails: ${e?.message}`,
          autoHideDuration: 5000,
          variant: "error",
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
              <CloseIcon />
            </IconButton>,
          ],
        });
      } finally {
        setLoading(false);
      }
    },
    [api, closeSnackbar, customer.id, openSnackbar]
  );

  const inviteUsers = useCallback(
    async (emails: string[]) => {
      setLoading(true);
      const newEmails = emails.filter((email) => !validatedEmails[email]?.exists && !validatedEmails[email]?.invited);
      const existingEmails = emails.filter(
        (email) => !validatedEmails[email]?.exists && validatedEmails[email]?.invited
      );

      try {
        const newUsersIds = await Invites.handleInvite({
          newEmails,
          existingEmails,
          api,
          customer,
          requireBillingProfile,
          currentUser,
          selectedRoleId,
          roleList: [...roleList],
          entities,
        });

        if (canSelectEditUser && editUser && newUsersIds.length > 0) {
          const [createdUserId] = newUsersIds;
          history.push(`/customers/${customer.id}/iam/users/${createdUserId}`);
          return;
        }
        onClose();
        setEmails([]);
        setValidatedEmails({});
      } finally {
        setLoading(false);
      }
    },
    [
      api,
      canSelectEditUser,
      currentUser,
      customer,
      editUser,
      entities,
      history,
      onClose,
      requireBillingProfile,
      roleList,
      selectedRoleId,
      validatedEmails,
    ]
  );

  const handleEmailsChange = useCallback(
    async (newEmails: string[]) => {
      setEmails(newEmails);
      setValidatedEmails((await validateEmails(newEmails)) ?? {});
    },
    [validateEmails]
  );

  const onChangeSetEditUser = useCallback(() => {
    setEditUser((prevState) => !prevState);
  }, []);

  const onChangeSetRequireBillingProfile = useCallback(() => {
    setRequireBillingProfile((prevState) => !prevState);
  }, []);

  const handleInviteClick = useCallback(() => {
    if (validatedEmailsList.some((x) => !x.validated || x.error)) {
      return;
    }

    return inviteUsers(emails);
  }, [emails, inviteUsers, validatedEmailsList]);

  const emailsHaveErrors = useMemo(() => validatedEmailsList.some((x) => x.error), [validatedEmailsList]);
  const existingEmailsCount = useMemo(() => validatedEmailsList.filter((x) => x.exists).length, [validatedEmailsList]);

  return (
    <Dialog
      open={open}
      onClose={preventOnCloseWhile(loading, onClose)}
      scroll="paper"
      aria-labelledby="invite-user-dialog"
      fullScreen={isMobile}
    >
      <DialogTitle>{text.DIALOG_HEADER}</DialogTitle>
      <DialogContent style={{ minWidth: isMobile ? "100%" : 600 }}>
        <MultiEmailsInputText
          emails={emails}
          validatedEmails={validatedEmails}
          onEmailsChange={handleEmailsChange}
          emailValue={typingInProgressEmail}
          onEmailValueChange={setTypingInProgressEmail}
          invalidEmailsHelperComponent={
            <span data-testid={inviteUserDialogTestIds.EMAILS_INPUT_ERROR}>{errorMessages.INVALID_EMAIL_ERROR}</span>
          }
          autoCompleteComponentDataTestId={inviteUserDialogTestIds.EMAILS_INPUT_TEXT_BAR}
        />
        {validatedEmailsList.some((x) => x.exists) && (
          <UserExistsDialogHelper
            emails={validatedEmailsList.filter((x) => x.exists).map((x) => x.email)}
            style={{ marginTop: "16px", marginBottom: "24px" }}
          />
        )}
        {!!roleList.length && (
          <RoleSelection defaultRoleId={defaultRoleRef.id} roles={[...roleList]} handleRoleChange={setSelectedRoleId} />
        )}
        <FormControlLabel
          style={{ marginTop: 16 }}
          control={
            <Checkbox
              checked={canSelectEditUser && editUser}
              onChange={onChangeSetEditUser}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              disabled={!canSelectEditUser}
              inputProps={
                {
                  "data-testid": inviteUserDialogTestIds.CONTINUE_EDIT_USER_CHECKBOX,
                } as any
              }
            />
          }
          label={<Typography variant="body2">{text.CONTINUE_EDITING_USER}</Typography>}
        />
        {isDoitEmployee && (
          <InviteUserDialogHelper
            style={{ marginTop: 15 }}
            requireBillingProfile={requireBillingProfile}
            onSelectRequireBillingProfile={onChangeSetRequireBillingProfile}
          />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="text" data-testid={inviteUserDialogTestIds.CLOSE_BUTTON}>
          {text.CANCEL_BUTTON_TEXT}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          disabled={
            loading ||
            (emails.length >= 1 && emailsHaveErrors) ||
            !!typingInProgressEmail ||
            emails.length === 0 ||
            existingEmailsCount === emails.length
          }
          onClick={handleInviteClick}
          data-testid={inviteUserDialogTestIds.SUBMIT_BUTTON}
          mixpanelEventId="iam.invite-user.submit"
        >
          {text.SUBMIT_BUTTON_TEXT}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
