import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { DateTime } from "luxon";
import { getCollection } from "@doitintl/models-firestore";
import { DashboardModel } from "@doitintl/cmp-models";
import { useCustomerContext } from "../../Context/CustomerContext";
import { SkeletonCard } from "./SkeletonCard";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { WidgetCardContentWithTable } from "./WidgetCards/Common/WidgetCardContentWithTable";
import { arrayFromDocChange } from "../../Context/customer/arrayFromDocChange";

export default function RenewalsCard({ raised, fallbackComponent, widgetHeight = 200 }) {
  const [renewals, setRenewals] = useState();
  const { customer } = useCustomerContext();

  useEffect(
    () =>
      getCollection(DashboardModel)
        .doc("assetsRenewals")
        .collection("ids")
        .where("customer", "==", customer.ref)
        .orderBy("endTime", "asc")
        .onSnapshot((querySnapshot) => {
          setRenewals((prevRenewals) => {
            const newRenewals = [...(prevRenewals ?? [])];
            arrayFromDocChange(newRenewals, querySnapshot, (doc) => ({
              id: doc.id,
              ref: doc.ref,
              data: doc.data(),
            }));

            return newRenewals;
          });
        }),
    [customer.ref]
  );

  const columns = [
    {
      field: "renewalDate",
      headerName: "Renewal Date",
      width: 120,
      renderCell: (params) => DateTime.fromJSDate(params.row.endTime.toDate()).toLocaleString(DateTime.DATE_MED),
    },
    {
      field: "subscription",
      headerName: "Subscription",
      flex: 1,
      renderCell: (params) => `${params.row.name} (${params.row.domain})`,
    },
  ];

  if (renewals && renewals.length === 0) {
    return fallbackComponent;
  }

  if (renewals === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  const rows = renewals.map((renewal) => ({
    id: renewal.ref.path,
    endTime: renewal.data.endTime,
    name: renewal.data.name,
    domain: renewal.data.domain,
  }));

  return (
    <Card raised={raised}>
      <WidgetCardHeader title="Renewals" subheader="Your G Suite and Office 365 renewals within the next 3 months" />
      <WidgetCardContentWithTable height={widgetHeight} rows={rows} columns={columns} />
    </Card>
  );
}
