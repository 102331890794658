import { type Dispatch } from "react";

import { Button, Stack, Typography } from "@mui/material";

import { globalText, loginTexts } from "../../../assets/texts";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import CheckEmailForm from "./CheckEmailForm";

export function VerifyEmailForMagiclink({
  state,
  dispatch,
}: {
  state: LoginState;
  dispatch: Dispatch<LoginHookAction>;
}) {
  return (
    <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
      <Typography variant="h1" alignSelf="flex-start">
        {loginTexts.ENTER_VERIFICATION_EMAIL}
      </Typography>
      <CheckEmailForm state={state} dispatch={dispatch} />
      <Button
        fullWidth={false}
        color="primary"
        variant="text"
        onClick={() => {
          dispatch({ type: LoginHookActionType.setFormType, formType: "checkEmail" });
        }}
      >
        {globalText.BACK}
      </Button>
    </Stack>
  );
}
