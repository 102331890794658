import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { type Customer } from "../../../types";
import { type FirestoreTimestamp, serverTimestamp } from "../../../utils/firebase";
import { CustomerNoteTagsOptions } from "./constants";
import { type CustomerNote, type TagKeys, type Tags } from "./types";

export const getAllNotes = (customerNotes: CustomerNote[], pinnedNotes: CustomerNote[]) =>
  pinnedNotes.length > 0
    ? [
        ...pinnedNotes,
        ...customerNotes.filter((customerNote) => !pinnedNotes.find((pinnedNote) => pinnedNote.id === customerNote.id)),
      ]
    : [...customerNotes];

export const doesNoteHasTag = (note: CustomerNote, tag: TagKeys) => note.tags?.[tag];

export const isUserNote = (note: CustomerNote, currentUserEmail: string) => note.email === currentUserEmail;

export const getNoteLabelByKey = (key: TagKeys) => CustomerNoteTagsOptions.find((tag) => tag.key === key)?.label;

export const getNoteTagKeys = (note: CustomerNote): TagKeys[] => {
  if (!note.tags) {
    return [];
  }
  const tags = Object.entries(note.tags).reduce<TagKeys[]>(
    (acc, [key, value]) =>
      value ? [...acc, CustomerNoteTagsOptions.find((tag) => tag.key === key)?.key as TagKeys] : [...acc],
    []
  );
  return tags;
};

export const getNoteTags = (noteTags: TagKeys[]) =>
  CustomerNoteTagsOptions.reduce<Tags>((memo, tag) => {
    memo[tag.key] = !!noteTags.find((noteTag) => noteTag === tag.key);
    return memo;
  }, {} as Tags);

export function generateNotePayload(
  customerData: Customer,
  note: string,
  noteTags: TagKeys[],
  currentUser: FirebaseUserWithEmail
): Omit<CustomerNote, "ref" | "id"> {
  const tags = getNoteTags(noteTags);
  const subscribers = customerData.subscribers ?? [];
  return {
    body: note,
    timestamp: serverTimestamp() as FirestoreTimestamp,
    customer: customerData.ref,
    customerName: customerData.name ?? customerData.primaryDomain ?? "",
    email: currentUser.email,
    name: currentUser.displayName,
    photoURL: currentUser.photoURL,
    pinned: false,
    tags,
    edited: null,
    display: true,
    subscribers: [...subscribers, currentUser.email],
  };
}

export function generateNoteEditPayload(
  customerData: Customer,
  note: CustomerNote,
  notetext: string,
  noteTags: TagKeys[],
  currentUser: FirebaseUserWithEmail
): Omit<CustomerNote, "ref" | "id"> {
  const tags = getNoteTags(noteTags);
  return {
    body: notetext,
    timestamp: note.timestamp,
    customer: note.customer,
    customerName: customerData.name ?? customerData.primaryDomain ?? "",
    email: currentUser.email,
    name: currentUser.displayName,
    photoURL: currentUser.photoURL,
    pinned: note.pinned,
    tags,
    edited: note.ref,
    display: true,
    subscribers: note.subscribers,
  };
}
