import { Typography } from "@mui/material";

import { handleResponseError, ResponseErrorCode } from "../../../../api/axiosClient";
import { useDeleteGoogleCloudDirectAsset } from "../../../../api/endpoints";
import DeleteDialog from "../../../../Components/DeleteDialog";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { autoHideDuration } from "../../../../constants";

type Props = {
  onClose: () => void;
  customerId: string;
  id: string;
};

export default function DeleteGoogleCloudDirectAssetDialog({ onClose, customerId, id }: Props) {
  const action = useDeleteGoogleCloudDirectAsset();
  const snackbar = useSnackbar();

  return (
    <DeleteDialog
      open={true}
      title="Delete connection"
      message={
        <>
          <Typography>This will disconnect your Direct Billing Account from the CMP.</Typography>
          <Typography>Are you sure you want to proceed?</Typography>
        </>
      }
      onDelete={async () => {
        try {
          await action({ id, customerId });
        } catch (e: any) {
          handleResponseError(e, (errorCode) => {
            let textError;

            if (errorCode === ResponseErrorCode.NETWORK_ERROR) {
              textError = "Network Error";
            } else if (errorCode === ResponseErrorCode.UNKNOWN) {
              textError = "Asset deletion failed";
            } else {
              textError = errorCode;
            }

            snackbar.onOpen({
              message: textError,
              variant: "error",
              autoHideDuration,
            });
          });
        }
      }}
      onClose={onClose}
    />
  );
}
