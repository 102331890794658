import { useEffect } from "react";

import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";

export function UnsavedChangesPrompt({ dirty }: { dirty: boolean }) {
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  useEffect(() => {
    if (dirty) {
      activatePendingPrompt();
    } else {
      clearPendingPrompt();
    }
  }, [dirty, activatePendingPrompt, clearPendingPrompt]);
  return null;
}
