import { useEffect } from "react";

import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";

import { useUserContext } from "../Context/UserContext";
import en from "./en/resource";
import ja from "./ja/resource";
import { browserLanguage } from "./utils";

export const resources = {
  en,
  ja,
} as const;

i18n.use(initReactI18next).init({
  supportedLngs: Object.keys(resources),
  resources,
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: "en",
  lng: browserLanguage === "ja" ? "ja" : "en",
});

export const useI18NUserLanguage = () => {
  const { i18n } = useTranslation();
  const storedLng = localStorage.getItem("i18nLng");

  const { userModel: user } = useUserContext({ allowNull: false });

  useEffect(() => {
    const language = user.language ?? storedLng ?? browserLanguage;
    if (language !== i18n.language) {
      void i18n.changeLanguage(language);
    }
  }, [user.language, i18n, storedLng]);
};

export const useLanguage = () => {
  const { userModel: user } = useUserContext({ allowNull: false });

  const setLanguage = (language: string) => {
    void user.ref.update({ language });
  };
  return [user.language, setLanguage] as const;
};

export default i18n;
