import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { type TableNotification } from "./types";
import { editUserNotification, getProviderIcon } from "./utils";

type Props = {
  row: TableNotification;
};

export const UserNotificationRow = ({ row }: Props) => {
  const customerId = useCustomerId();
  return (
    <CellsWrapper>
      <TableCell data-cy={`createdBy-${row.id}`}>
        <Link component={RouterLink} to={editUserNotification(customerId, row.id)} color="inherit">
          {row.createdBy}
        </Link>
      </TableCell>
      <TableCell data-cy={`providerType-${row.id}`}>{getProviderIcon(row.providerType)}</TableCell>

      <TableCell padding="none" align="right" data-cy={`threeDots-${row.id}`}>
        <ThreeDotsMenu
          closeAfterSelect
          options={[
            {
              key: "1",
              label: (
                <Link
                  component={RouterLink}
                  to={editUserNotification(customerId, row.id)}
                  color="inherit"
                  sx={{ textDecoration: "none" }}
                >
                  Edit notification
                </Link>
              ),
            },
          ]}
        />
      </TableCell>
    </CellsWrapper>
  );
};
