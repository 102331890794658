import { type AxiosInstance } from "axios";

import { type AsgBasicInfo, type AsgConfigChange, type AsgInstancesCosts } from "./types";

/**
 * SpotScaling related API calls
 */
export class SpotScalingApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    private readonly customerId: string
  ) {
    this.api = api;
    this.customerId = customerId;
    this.apiPrefix = `/v1/spot-scaling/${this.customerId}`;
  }

  public async averagePrices(
    info: AsgBasicInfo,
    instanceTypes: string[],
    subnets: string[]
  ): Promise<AsgInstancesCosts | undefined> {
    try {
      const response = await this.api.post(`${this.apiPrefix}/average-prices/`, {
        ...info,
        instanceTypes,
        subnets,
      });

      return response.data;
    } catch (error) {
      return undefined;
    }
  }

  public refreshRecommendations(params?: AsgBasicInfo): Promise<boolean> {
    return this.executeAsgOperation("refresh-asgs", params ?? {});
  }

  public applyRecommendation(info: AsgBasicInfo, change?: AsgConfigChange): Promise<boolean> {
    return this.executeAsgOperation("apply-recommendation", {
      ...info,
      configuration: {
        ...(change ?? {}),
      },
    });
  }

  public updateConfiguration(info: AsgBasicInfo, change: AsgConfigChange): Promise<boolean> {
    return this.executeAsgOperation("update-configuration", {
      ...info,
      configuration: {
        ...change,
      },
    });
  }

  public async addFallbackOnDemandConfig(
    accountId: string,
    region: string
  ): Promise<{ done: boolean; error?: string }> {
    try {
      const response = await this.api.post(`${this.apiPrefix}/fallback-on-demand/add-config`, {
        accountId,
        region,
      });
      return response.data;
    } catch (error) {
      return {
        done: false,
      };
    }
  }

  public async checkFallbackOnDemandConfig(accountId: string, region: string): Promise<boolean> {
    try {
      const response = await this.api.get(`${this.apiPrefix}/fallback-on-demand/check-config/${accountId}/${region}`);
      return response.data?.done;
    } catch (error) {
      return false;
    }
  }

  private async executeAsgOperation(operationName: string, params?: unknown): Promise<boolean> {
    try {
      const response = await this.api.post(`${this.apiPrefix}/${operationName}/`, params);
      return response.data?.done;
    } catch (error) {
      return false;
    }
  }
}
