import React, { useMemo } from "react";

import { AssetTypeLooker, type LookerAssetModel } from "@doitintl/cmp-models";

import { type AssetsTabPropsOuterProps } from "../types";
import LookerAssetsTabHeader from "./components/looker/LookerAssetsTabHeader";
import LookerAssetTable from "./components/looker/LookerAssetTable";
import NoAssets from "./components/NoAssets";

const LookerAssetsTab = ({ contracts }: AssetsTabPropsOuterProps<LookerAssetModel>) => {
  const showNoAssets = useMemo<boolean>(() => Boolean(contracts && contracts?.length === 0), [contracts]);

  if (showNoAssets) {
    return <NoAssets assetType={AssetTypeLooker} />;
  }

  return (
    <>
      <LookerAssetsTabHeader />
      <LookerAssetTable contracts={contracts} />
    </>
  );
};

export default LookerAssetsTab;
