import { type ReactNode } from "react";

import { Box } from "@mui/material";
import { type BoxProps } from "@mui/material/Box/Box";
import { useTheme } from "@mui/material/styles";

type Props = {
  children: ReactNode;
} & BoxProps;

export default function BorderedBox({ children, ...rest }: Props) {
  const theme = useTheme();

  return (
    <Box sx={{ border: `1px solid ${theme.palette.general.divider}`, borderRadius: 1 }} {...rest}>
      {children}
    </Box>
  );
}
