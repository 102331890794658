import { Alert } from "@mui/material";
import { DateTime } from "luxon";

const FutureRampPlanAlert = ({ startDateTime }: { startDateTime: DateTime }) => (
  <Alert severity="info" data-cy="notStartedAlert">
    {`You can start tracking your cloud spend to your committed spend when your contract begins on ${startDateTime.toLocaleString(
      DateTime.DATE_MED
    )}`}
  </Alert>
);

export default FutureRampPlanAlert;
