import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import APIParametersTab from "./APIParametersTab";
import { ConditionParametersTab } from "./ConditionParametersTab";
import FilterParametersTab from "./FilterParametersTab";
import { ManualTriggerTab } from "./ManualTriggerTab";
import PermissionsTab from "./PermissionsTab";
import ScheduleTab from "./ScheduleTab";
import TestsTab from "./TestsTab";
import TransformParametersTab from "./TransformParametersTab";
import type { NodeConfigs } from "../../../types";

const parameterTabContent = {
  [CloudFlowNodeType.ACTION]: APIParametersTab,
  [CloudFlowNodeType.CONDITION]: ConditionParametersTab,
};

export const TabContent = () => {
  const { activeTab, nodeConfig } = useNodeConfigurationContext();
  switch (activeTab) {
    case "FilterParameters":
      return <FilterParametersTab />;
    case "TransformParameters":
      return <TransformParametersTab />;
    case "Parameters": {
      const ParameterComponentByType = parameterTabContent[nodeConfig.type];

      if (!ParameterComponentByType) return null;

      return <ParameterComponentByType />;
    }
    case "ManualTrigger":
      return <ManualTriggerTab nodeConfig={nodeConfig as NodeConfigs<CloudFlowNodeType.MANUAL_TRIGGER>} />;
    case "Permissions":
      return <PermissionsTab />;
    case "Schedule":
      return <ScheduleTab nodeConfig={nodeConfig as NodeConfigs<CloudFlowNodeType.TRIGGER>} />;
    case "Test":
      return <TestsTab />;
    default:
      return null;
  }
};
