import { firestoreTimestampFilterProps } from "../../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import { assetTypeName } from "../../../../utils/common";
import { type OnHoldRowData } from "./types";

const itemsDescriptions: FilterTableItemDescriptor<OnHoldRowData>[] = [
  {
    value: "primaryDomain",
    headerText: "Customer",
    filterType: "string",
  },
  {
    value: "cloud",
    headerText: "Cloud",
    filterType: "string",
    filterOptions: {
      toOption: (value) => ({
        value,
        label: assetTypeName(value),
      }),
    },
  },
  {
    value: "createTime",
    headerText: "Create time",
    filterType: "date",
    filterOptions: {
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    value: "email",
    headerText: "Email",
    filterType: "string",
  },
  {
    value: "note",
    headerText: "Note",
    filterType: "string",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
