import { DateTime } from "luxon";

import { sanitizeDate } from "../utils/common";
import { type FirestoreTimestamp, TimestampFromDate } from "../utils/firebase";

export enum ColumnFilterType {
  Number = "Number",
  FirestoreTimestamp = "FirestoreTimestamp",
  DateTime = "DateTime",
}

export const firestoreTimestampFilterProps = (format: string) => ({
  type: ColumnFilterType.FirestoreTimestamp,
  transform: (value: string): FirestoreTimestamp =>
    TimestampFromDate(sanitizeDate(DateTime.fromFormat(value, format, { zone: "utc" })).toJSDate()),
  validate: (value: string): boolean => DateTime.fromFormat(value, format)?.isValid ?? false,
  toOption: (value: FirestoreTimestamp) => {
    if (!value) {
      return;
    }
    const strValue = DateTime.fromJSDate(value.toDate()).toFormat(format);
    return {
      value: strValue,
      label: strValue,
    };
  },
});

export const luxonDateTimeFilterProps = (format: string) => ({
  type: ColumnFilterType.DateTime,
  transform: (value: string): DateTime => sanitizeDate(DateTime.fromFormat(value, format, { zone: "utc" })),
  validate: (value: string): boolean => DateTime.fromFormat(value, format)?.isValid ?? false,
  toOption: (value: DateTime) => {
    const strValue = value.toFormat(format);
    return {
      value: strValue,
      label: strValue,
    };
  },
});
