import { useCallback, useMemo, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddBox";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { makeStyles } from "@mui/styles";
import sortBy from "lodash/sortBy";

import { useAuthContext } from "../../Context/AuthContext";
import { useCurrentDashboardContext } from "../../Context/CurrentDashboardContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext, type WidgetDescription } from "../../Context/DashboardContext";
import { useAssetsWidgets } from "../../Context/useAssetsWidgets";
import mixpanel from "../../utils/mixpanel";
import { filterExcludeWidgetsIfStandaloneCustomer } from "../../utils/widgets";
import { useSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import { SearchTextField } from "./SearchTextField";
import WidgetSectionHeader from "./WidgetSectionHeader";

const NonManualWidget = new Set(["cloudReports", "gsuiteAssetCard", "office365AssetCard"]);

const useStyles = makeStyles((theme) => ({
  drawer: {
    margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    borderRadius: theme.spacing(1),
  },
  list: {
    width: 450,
  },
  fullList: {
    width: "auto",
  },
  stickyFooter: {
    position: "fixed",
    right: "0",
    bottom: "0",
    height: 80,
  },
  fab: {
    margin: theme.spacing(2),
  },
  badge: {
    top: "28%",
    right: "28%",
  },
  loadingCircle: {
    marginTop: 2,
  },
  listItemIcon: {
    cursor: "pointer",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
}));

type Props = {
  onClose: () => void;
};

export function WidgetsList({ onClose }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const { widgetsConfig } = useDashboardsContext();
  const { onOpen: showSnackbar } = useSnackbar();
  const { addWidgetToDashboard, widgets } = useCurrentDashboardContext();
  const [searchQuery, setSearchQuery] = useState(null);
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization, isProductOnlyCustomer } = useCustomerContext();
  const { assetsWidgets } = useAssetsWidgets();

  const allDashboardWidgets = useMemo(
    () =>
      widgets.reduce<Record<string, (typeof widgets)[number]>>((acc, widget) => {
        acc[widget.name] = widget;
        return acc;
      }, {}),
    [widgets]
  );

  const addWidget = useCallback(
    async (name: string) => {
      await addWidgetToDashboard(name);
      const mixpanelKey = name.includes("cloudReports::") ? "report-based" : name;

      mixpanel.track("dashboard.widget.add", {
        type: mixpanelKey,
      });

      showSnackbar({
        message: "Widget has been added",
        variant: "success",
        autoHideDuration: 2000,
      });
    },
    [addWidgetToDashboard, showSnackbar]
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value?.toLowerCase());
  };

  const filterSearchString = useCallback(
    (sortedWidgets: WidgetDescription[]) =>
      sortedWidgets.filter((widget) => {
        const name = widget.name.toLowerCase();
        const description = widget.description.toLowerCase();
        const title = widget.title.toLowerCase();
        return searchQuery
          ? name.includes(searchQuery) || description.includes(searchQuery) || title.includes(searchQuery)
          : true;
      }),
    [searchQuery]
  );

  const sortWidgetsList = useCallback(
    (widgets: WidgetDescription[]) =>
      sortBy(widgets, [
        (widget) => {
          const isInList = allDashboardWidgets[widget.name];
          return [isInList, widget.name.toLowerCase()];
        },
      ]),
    [allDashboardWidgets]
  );

  const filterList = useMemo(
    () =>
      filterSearchString(
        sortWidgetsList(
          Object.values(widgetsConfig).map((config) => ({
            name: config.key,
            description: config.cardSubheader,
            title: config.cardTitle,
          }))
        )
      ).filter((widget) => filterExcludeWidgetsIfStandaloneCustomer(widget.name, isProductOnlyCustomer)),
    [isProductOnlyCustomer, filterSearchString, sortWidgetsList, widgetsConfig]
  );

  const filterAssetsList = useMemo(() => {
    const sortedWidgets = sortWidgetsList(assetsWidgets);

    return filterSearchString(sortedWidgets);
  }, [sortWidgetsList, assetsWidgets, filterSearchString]);

  const noItemsMessage = (
    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
      <Typography variant="h6">No widgets found</Typography>
    </Box>
  );

  const widgetListSubheader = (
    <ListSubheader sx={{ px: 1 }} disableSticky={false}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <IconButton
          sx={{ display: "flex", ml: "auto", pt: 1, px: 0, pb: 0 }}
          key="close"
          aria-label="Close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <SearchTextField onChange={handleSearchChange} />
    </ListSubheader>
  );

  const createWidgetItems = useCallback(
    (widgets: WidgetDescription[]) =>
      widgets.flatMap((widget) => {
        if (NonManualWidget.has(widget.name)) {
          return [];
        }

        const disabled = allDashboardWidgets[widget.name];
        return [
          <ListItem key={widget.name}>
            <ListItemIcon
              className={disabled ? undefined : classes.listItemIcon}
              onClick={disabled ? undefined : () => addWidget(widget.name)}
            >
              <AddCircleIcon color={disabled ? "disabled" : "action"} />
            </ListItemIcon>
            <ListItemText
              sx={{ color: disabled ? theme.palette.text.disabled : theme.palette.text.primary }}
              secondaryTypographyProps={{
                sx: { color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary },
              }}
              primary={widget.title}
              secondary={widget.description}
              color="red"
            />
          </ListItem>,
        ];
      }),
    [
      addWidget,
      allDashboardWidgets,
      classes.listItemIcon,
      theme.palette.text.disabled,
      theme.palette.text.primary,
      theme.palette.text.secondary,
    ]
  );

  const widgetsItems = useMemo(() => createWidgetItems(filterList), [createWidgetItems, filterList]);
  const assetsItems = useMemo(() => createWidgetItems(filterAssetsList), [createWidgetItems, filterAssetsList]);

  const sideList = () => (
    <div className={classes.list} role="presentation">
      <List dense subheader={widgetListSubheader}>
        <Box sx={{ ml: 3, mr: 5 }}>
          {filterList.length + filterAssetsList.length === 0 && noItemsMessage}
          <WidgetSectionHeader title="Widgets" display={filterList.length > 0} />
          {widgetsItems}
          <WidgetSectionHeader title="Assets" display={filterAssetsList.length > 0} />
          {(userOrganization?.data.allowCustomDashboards || isDoitEmployee) && assetsItems}
        </Box>
      </List>
    </div>
  );

  return (
    <Drawer anchor="right" open={true} onClose={onClose} classes={{ paper: classes.drawer }} transitionDuration={100}>
      {sideList()}
    </Drawer>
  );
}
