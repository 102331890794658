import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  CustomerModel,
  type CustomerModelOrganizationModel as Organization,
  type DashboardModelAttributionModel,
  type InviteModel,
  type UserModel,
} from "@doitintl/cmp-models";
import { getBatch, getCollection, type Model, type ModelReference } from "@doitintl/models-firestore";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import get from "lodash/get";

import { useApiContext } from "../../../api/context";
import { organizationsText } from "../../../assets/texts";
import { organizationsTxt } from "../../../assets/texts/IAM/organizations";
import { CopyToClipboardButton } from "../../../Components/CopyToClipboardButton";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { useGotoParentUrl } from "../../../Components/hooks/useGotoParentUrl";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import useUpdateEffect from "../../../Components/hooks/useUpdateEffect";
import SaveComponent from "../../../Components/SaveComponents/SaveComponent";
import SaveDialog from "../../../Components/SaveComponents/SaveDialog";
import { AttributionSelectMulti } from "../../../Components/Selects/CloudAnalytics/AttributionSelectMulti";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import Title from "../../../Components/Title/Title";
import TransferList, { type Direction } from "../../../Components/TransferList/TransferList";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useDashboardsContext } from "../../../Context/DashboardContext";
import { useUnsavedChanges } from "../../../Context/UnsavedChangesContext";
import { type AttributionWRef } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { getDashboardNameById, RootOrgId } from "../../../utils/common";
import { arrayRemove, arrayUnion, type FirestoreTimestamp, serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { deleteOrgs, updateDashboardWidgets } from "./db";
import { listClass, useStyles } from "./OrgsStyles";
import { type OrganizationWSnap, type OrgUser } from "./Types";
import { getCurrentOrg } from "./Utils";

const maxAttributionsAllowed = 3;
const maxDescriptionLength = 100;
const maxTitleLength = 64;

export type EditOrgProps = {
  orgId: string;
  users: OrgUser[];
  attributions: AttributionWRef[];
};

const EditOrg = ({ orgId, users, attributions }: EditOrgProps) => {
  const history = useHistory<any>();
  const routeMatchURL = useRouteMatchURL();
  const { customer, organizations } = useCustomerContext();
  const api = useApiContext();
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const list = listClass(theme);
  const [isInit, setIsInit] = useState(false);
  const [currentOrg, setCurrentOrg] = useState<OrganizationWSnap>();
  const [availableUsers, setAvailableUsers] = useState<OrgUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<OrgUser[]>([]);
  const { publicDashboards } = useDashboardsContext();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [activeDashboards, setActiveDashboards] = useState<string[]>([]);
  const showErrorSnackbar = useErrorSnackbar();
  const dashboardsUpdated = useRef(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [draft] = useState(!orgId);
  const [saveComponentKey, setSaveComponentKey] = useState(0);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [name, setName] = useState(orgId ? (getCurrentOrg(organizations, orgId)?.data.name ?? "") : "");
  const [description, setDescription] = useState(
    orgId ? (getCurrentOrg(organizations, orgId)?.data.description ?? "") : ""
  );
  const [scope, setScope] = useState<ModelReference<DashboardModelAttributionModel>[]>(
    getCurrentOrg(organizations, orgId)?.data.scope ?? []
  );
  const [allowCustomDashboards, setAllowCustomDashboards] = useState(
    getCurrentOrg(organizations, orgId)?.data.allowCustomDashboards ?? false
  );
  const [disableAccountDashboard, setDisableAccountDashboard] = useState(
    getCurrentOrg(organizations, orgId)?.data.disableAccountDashboard ?? false
  );
  const [openNameDialog, setOpenNameDialog] = useState(false);

  const orgsCollection = (customerId: string) =>
    getCollection(CustomerModel).doc(customerId).collection("customerOrgs");
  const rootOrg = useMemo(() => organizations.find((o) => !o.data.parent), [organizations]);

  const isValidOrganization = useMemo(
    () => orgId === RootOrgId || (scope.length > 0 && selectedUsers.length > 0),
    [orgId, scope.length, selectedUsers.length]
  );

  const saveUserOrgs = useCallback(
    (orgId: string) => {
      const orgRef = orgsCollection(customer.id).doc(orgId);
      const batch = getBatch();
      [...selectedUsers, ...availableUsers].forEach((user) => {
        if (user.orgAdded) {
          batch.update(user.ref as ModelReference<UserModel | InviteModel>, {
            organizations: arrayUnion(orgRef),
          });
        } else if (user.orgRemoved) {
          batch.update(user.ref as ModelReference<UserModel | InviteModel>, {
            organizations: arrayRemove(orgRef),
          });
        }
      });
      return batch.commit();
    },
    [availableUsers, customer.id, selectedUsers]
  );

  const saveOrgData = useCallback(
    async (saveName: string): Promise<string> => {
      if (!rootOrg) {
        throw new Error("Root organization not found");
      }

      const org: Model<Organization> = {
        customer: customer.ref,
        description,
        name: saveName ?? name,
        parent: orgId !== RootOrgId ? rootOrg.ref : null,
        scope,
        dashboards: activeDashboards,
        allowCustomDashboards,
        disableAccountDashboard,
        timeModified: serverTimestamp() as FirestoreTimestamp,
        timeCreated: currentOrg ? currentOrg.data.timeCreated : (serverTimestamp() as FirestoreTimestamp),
      };

      let orgRef: ModelReference<Organization>;
      if (!orgId) {
        orgRef = orgsCollection(customer.ref.id).newDoc();
      } else {
        orgRef = currentOrg?.ref as ModelReference<Organization>;
      }
      const updatedOrgId = orgRef.id;
      await saveUserOrgs(updatedOrgId);
      await orgRef.set(org);
      return updatedOrgId;
    },
    [
      activeDashboards,
      allowCustomDashboards,
      currentOrg,
      customer.ref,
      description,
      disableAccountDashboard,
      name,
      orgId,
      rootOrg,
      saveUserOrgs,
      scope,
    ]
  );

  const handleSave = useCallback(
    async (saveName?: string): Promise<void> => {
      if (!name && !saveName) {
        setOpenNameDialog(true);
        return;
      }
      try {
        const updatedOrgId = await saveOrgData(saveName ?? name);
        setHasUnsavedChanges(false);
        setOpenNameDialog(false);
        setSaveComponentKey(saveComponentKey + 1);
        successSnackbar(organizationsTxt.ORGANIZATION_SAVE_SUCCESS);
        if (updatedOrgId !== orgId) {
          history.push(routeMatchURL.replace("create", updatedOrgId));
        }
        if (!dashboardsUpdated.current) {
          await updateDashboardWidgets(api, customer.id, updatedOrgId);
          dashboardsUpdated.current = true;
        }
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar(organizationsTxt.ORGANIZATION_SAVE_ERROR);
      }
    },
    [
      api,
      customer.id,
      errorSnackbar,
      history,
      name,
      orgId,
      routeMatchURL,
      saveComponentKey,
      saveOrgData,
      successSnackbar,
    ]
  );

  const parentUrl = useMemo(() => `/customers/${customer.id}/iam/orgs`, [customer.id]);
  const handleBack = useGotoParentUrl(parentUrl);

  const onChangeTitleValues = (values: { title?: string; description?: string }) => {
    if (values.title !== undefined) {
      setName(values.title);
      setHasUnsavedChanges(true);
    } else if (values.description !== undefined) {
      setDescription(values.description);
      setHasUnsavedChanges(true);
    }
  };

  const selectedAttrs = useMemo(() => {
    const selected: AttributionWRef[] = [];
    const currentOrgAttrIds = scope.map((ref) => ref.id) || [];
    attributions.forEach((attr) => {
      if (!currentOrgAttrIds.includes(attr.ref.id)) {
        return true;
      }
      selected.push(attr);
    });

    return selected;
  }, [attributions, scope]);

  const updateOrgAttributions = useCallback(
    async (newScope: AttributionWRef[]) => {
      if (!attributions.length) {
        return;
      }

      if (newScope.length > maxAttributionsAllowed && newScope.length >= selectedAttrs.length) {
        showErrorSnackbar(organizationsTxt.LIMIT_ATTRIBUTION_WARNING);
        return;
      }

      setScope(newScope.map((a) => a.ref));
      setHasUnsavedChanges(true);
    },
    [attributions.length, selectedAttrs.length, showErrorSnackbar]
  );

  const updateUserOrgs = useCallback(
    (updateType: Direction, userRefs: (ModelReference<UserModel> | ModelReference<InviteModel>)[]) => {
      const newSelectedUsers = selectedUsers.slice();
      const newAvailableUsers = availableUsers.slice();
      userRefs.forEach((userRef) => {
        if (updateType === "right") {
          const user = newAvailableUsers.find((user) => user.ref.id === userRef.id);
          if (user) {
            newSelectedUsers.unshift({ ...user, orgAdded: true });
            newAvailableUsers.splice(newAvailableUsers.indexOf(user), 1);
          }
        } else {
          const user = newSelectedUsers.find((user) => user.ref.id === userRef.id);
          if (user) {
            newAvailableUsers.unshift({ ...user, orgRemoved: true });
            newSelectedUsers.splice(newSelectedUsers.indexOf(user), 1);
          }
        }
      });

      setAvailableUsers(newAvailableUsers);
      setSelectedUsers(newSelectedUsers);
    },
    [availableUsers, selectedUsers]
  );

  // This will trigger the updateUsers/Attributions functions
  useEffect(() => {
    const org = getCurrentOrg(organizations, orgId);
    setCurrentOrg(org);
    setActiveDashboards(org?.data?.dashboards ?? []);
    setIsInit(true);
    mixpanel.track("iam.organizations.view", { orgId });
  }, [organizations, orgId]);

  // Handle users, search on all users if current Organization exists (orgId prop), if it does then user is "selected"
  const updateUsers = useCallback(() => {
    const selected: OrgUser[] = [];
    const available: OrgUser[] = [];

    users.forEach((user) => {
      if (!user.organizations?.length) {
        available.push(user);
      }

      const userInOrg = user.organizations?.some((o) => o.id === orgId) ?? false;
      if (userInOrg) {
        selected.push(user);
      }
    });

    available.sort((a, b) => a?.email.toLowerCase().localeCompare(b?.email.toLowerCase()));
    selected.sort((a, b) => a?.email.toLowerCase().localeCompare(b?.email.toLowerCase()));

    setAvailableUsers(available);
    setSelectedUsers(selected);
  }, [orgId, users]);

  //  Listen on changes of users 1. They are loaded gradually (current user in cache). 2. Updates lists based on updating user
  useUpdateEffect(() => {
    updateUsers();
  }, [users, isInit]);

  const handleDashSelect = useCallback(
    async (add: boolean, globalDashId: string) => {
      if (add) {
        setActiveDashboards([...activeDashboards, globalDashId]);
      } else {
        setActiveDashboards(activeDashboards.filter((d) => d !== globalDashId));
      }
      setHasUnsavedChanges(true);
    },
    [activeDashboards]
  );

  const handleSelectedUsers = useCallback(
    (changedUsers: OrgUser[], direction: Direction) => {
      updateUserOrgs(
        direction,
        changedUsers.map((user) => user.ref)
      );
      setHasUnsavedChanges(true);
    },
    [updateUserOrgs]
  );

  const removeOrgs = useCallback(async () => {
    await deleteOrgs(api, customer.id, [orgId]);
    mixpanel.track("iam.organizations.delete", { orgIds: [orgId] });
  }, [api, customer.id, orgId]);

  const handleDelete = async () => {
    if (orgId === RootOrgId) {
      return;
    }
    try {
      setIsDeleting(true);
      await removeOrgs();
      clearPendingPrompt();
      successSnackbar(organizationsTxt.SUCCESSFULLY_DELETED_ORGANIZATION);
    } catch (e) {
      consoleErrorWithSentry(e);
      errorSnackbar(organizationsTxt.DELETE_ORGANIZATION_ERROR);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSaveName = useCallback(
    async (newName: string) => {
      try {
        setName(newName);
        await handleSave(newName);
        setHasUnsavedChanges(false);
      } catch (e) {
        setHasUnsavedChanges(true);
      } finally {
        setOpenNameDialog(false);
      }
    },
    [handleSave]
  );

  useEffect(() => {
    if (hasUnsavedChanges) {
      activatePendingPrompt({
        exceptionsPath: [new RegExp(`^${routeMatchURL.replace("create", "")}(\\w+)$`)],
      });
    } else {
      clearPendingPrompt();
    }
  }, [hasUnsavedChanges, activatePendingPrompt, clearPendingPrompt, routeMatchURL]);

  return (
    <Grid container spacing={2}>
      <DoitConsoleTitle pageName="Organizations" pageLevel1={name || "New organization"} />
      <Grid item xs={12}>
        <Card>
          <CardHeader
            data-cy="organization-header"
            disableTypography={true}
            title={
              <Title
                data-cy="attributionGroups-name"
                values={{ title: name, description }}
                onChange={onChangeTitleValues}
                handleBack={handleBack}
                placeholder={organizationsTxt.ORGANIZATION_NAME}
                maxTitleLength={maxTitleLength}
                maxDescriptionLength={maxDescriptionLength}
              />
            }
            action={
              <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
                {!draft && [
                  <Tooltip title={organizationsTxt.DELETE_ORGANIZATION} key="delete">
                    <Box sx={{ pr: 1, pt: 0.5 }}>
                      <IconButton
                        size="small"
                        disabled={!orgId || orgId === RootOrgId}
                        onClick={handleDelete}
                        data-cy="delete-icon"
                      >
                        {isDeleting ? (
                          <CircularProgress disableShrink={true} color="inherit" size={24} thickness={4.5} />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </Box>
                  </Tooltip>,
                  <Tooltip key="copy-id" title="Copy organization ID">
                    <Box sx={{ pr: 1 }}>
                      <CopyToClipboardButton text={orgId} />
                    </Box>
                  </Tooltip>,
                ]}
                <Fragment key={saveComponentKey}>
                  <SaveComponent
                    disabled={!isValidOrganization || (!hasUnsavedChanges && !!name)}
                    onSave={handleSave}
                    tooltip={
                      isValidOrganization
                        ? organizationsTxt.SAVE_ORGANIZATION
                        : organizationsTxt.MISSING_ATTRIBUTION_OR_USER
                    }
                  />
                </Fragment>
              </Box>
            }
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.stretch}>
          <CardContent>
            <AttributionSelectMulti
              scope={selectedAttrs}
              attributions={attributions}
              textFieldProps={{
                size: "medium",
                helperText: organizationsTxt.HINT_TEXT,
                placeholder: organizationsTxt.SELECT_ATTRIBUTIONS,
              }}
              onChange={updateOrgAttributions}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.stretch}>
          <CardContent>
            <TransferList<OrgUser>
              left={availableUsers}
              right={selectedUsers}
              handleTransfer={handleSelectedUsers}
              leftTitle={organizationsText.AVAILABLE_USERS}
              rightTitle={organizationsText.SELECTED_USERS}
              textDisplay={(user) => user.email}
              subTextDisplay={(user) => user.displayName || ""}
              itemPropGetter={(user: OrgUser, prop: string) => get(user, prop, "")}
              listClass={list}
              listType="users"
              filterKeys={["displayName", "firstName", "lastName", "email"]}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            subheaderTypographyProps={{ variant: "body2" }}
            subheader={organizationsText.ORGANIZATION_DASHBOARDS}
          />
          <CardContent>
            <FormGroup row>
              {publicDashboards.map((dash, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      color="primary"
                      disabled={!allowCustomDashboards}
                      name={dash.name}
                      checked={activeDashboards.includes(dash.id)}
                      onChange={(_, checked: boolean) => handleDashSelect(checked, dash.id)}
                    />
                  }
                  label={getDashboardNameById(dash.id, dash.name)}
                />
              ))}
            </FormGroup>
            <Accordion
              elevation={0}
              classes={{
                root: classes.accordionRoot,
              }}
              expanded={expanded}
              onChange={() => {
                setExpanded(!expanded);
              }}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                classes={{
                  root: classes.accordionSummary,
                }}
                expandIcon={
                  <IconButton
                    classes={{
                      root: classes.iconButton,
                    }}
                    aria-label="Show more"
                    size="large"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                }
                aria-controls="advanced-content"
              >
                <Typography color="textSecondary" variant="body2">
                  {organizationsText.ADVANCED}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.accordionDetails,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="allowCustomDashboards"
                        checked={!allowCustomDashboards}
                        onChange={(_, checked: boolean) => {
                          setAllowCustomDashboards(!checked);
                          setHasUnsavedChanges(true);
                        }}
                      />
                    }
                    label={
                      <>
                        <Box component="span" mr={1}>
                          {organizationsText.DISABLE_CUSTOM_DASH}
                        </Box>
                        <Tooltip
                          title={<span style={{ whiteSpace: "pre-line" }}>{organizationsText.DISABLE_INFO}</span>}
                          placement="bottom-start"
                        >
                          <InfoIcon color="action" fontSize="small" />
                        </Tooltip>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="disableAccountDashboard"
                        checked={disableAccountDashboard}
                        onChange={(_, checked: boolean) => {
                          setDisableAccountDashboard(checked);
                          setHasUnsavedChanges(true);
                        }}
                      />
                    }
                    label={<>{organizationsText.DISABLE_ACCOUNT_DASH}</>}
                  />
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </Grid>
      {openNameDialog && (
        <SaveDialog
          title={organizationsTxt.NAME_BEFORE_SAVING}
          open={openNameDialog}
          onClose={() => {
            setOpenNameDialog(false);
          }}
          onSave={handleSaveName}
          textFieldProps={{ label: organizationsTxt.ORGANIZATION_NAME }}
        />
      )}
    </Grid>
  );
};

export default EditOrg;
