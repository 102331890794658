import { type CloudSelectorType, type ProductEnum } from "@doitintl/cmp-models";
import { getBatch } from "@doitintl/models-firestore";

import { type ProductOnlyCustomerProspects } from "../../../../Components/Dashboard/CloudConnection/type";
import { customerDocRef } from "../utils";
import { saasToProductCloudType } from "./CloudSelector/utils";

export async function updateCustomerCloudProviders(customerId: string, type: CloudSelectorType[]) {
  const cloudProviders = type.map((cloud) => saasToProductCloudType(cloud));
  const batch = getBatch();

  batch.update(customerDocRef(customerId), "onboarding.navigator.providers", cloudProviders);

  await batch.commit();
}

export async function updateCloudProviders(
  customerId: string,
  cloudProviders: ProductEnum[],
  prospect: ProductOnlyCustomerProspects
) {
  const fieldPath = `onboarding.${prospect}.providers`;
  await customerDocRef(customerId).update({ [fieldPath]: cloudProviders });
}

export async function updateCustomerWelcomeScreenState(customerId: string, seen = true) {
  await customerDocRef(customerId).update("onboarding.navigator.hasSeenWelcomeDialog", seen);
}
