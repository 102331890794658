import React, { type ChangeEvent } from "react";

import { Alert, MenuItem, TextField } from "@mui/material";

import { type JiraIssueType, type JiraProject } from "../types";
import { LoadingBox } from "./LoadingBox";

type Props = {
  isLoading: boolean;
  isError: boolean;
  projects?: JiraProject[];
  selectedProject: JiraProject | null;
  setSelectedProject: React.Dispatch<React.SetStateAction<JiraProject | null>>;
  setSelectedIssueType: React.Dispatch<React.SetStateAction<JiraIssueType | null>>;
};

export const ProjectsSelect = ({
  isLoading,
  isError,
  projects,
  selectedProject,
  setSelectedProject,
  setSelectedIssueType,
}: Props) => {
  const handleProjectChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // This must exist, as we're rendering the select based on the project list
    const changedProject = projects!.find((project) => project.id === event.target.value)!;
    setSelectedProject(changedProject);

    // If we change the project, also start over with selecting the issue type
    setSelectedIssueType(null);
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        An error occurred while fetching projects from your Jira instance. Please try again later.
      </Alert>
    );
  }

  if (projects!.length === 0) {
    return (
      <Alert severity="warning">
        We didn't find any projects in your Jira instance. To create a DoiT Thread, create a project in your Jira
        instance first.
      </Alert>
    );
  }

  return (
    <TextField
      label="Project"
      select
      variant="outlined"
      fullWidth
      value={selectedProject?.id || ""}
      onChange={handleProjectChange}
      sx={{ mb: 2, mt: 1 }}
    >
      {projects!.map((project) => (
        <MenuItem key={project.id} value={project.id}>
          {project.key} — {project.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
