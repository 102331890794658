const selectedStyle = {
  backgroundColor: "primary.main",
  color: "primary.contrastText",
};

const hoverBackground = {
  backgroundColor: "primary.hoverBackground",
};

export const containerStyle = (isStartDay: boolean, isEndDay: boolean, dayIsBetween: boolean) => ({
  ...(dayIsBetween && hoverBackground),
  ...(isStartDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    ...hoverBackground,
  }),
  ...(isEndDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    ...hoverBackground,
  }),
  ":has(.MuiPickersDay-hiddenDaySpacingFiller)": {
    backgroundColor: "transparent",
  },
});

export const pickersDayStyle = (isFirstDay: boolean, isLastDay: boolean, dayIsBetween: boolean) => ({
  ...(dayIsBetween && {
    backgroundColor: "transparent",
    color: "text.primary",
    "&:hover, &:focus": {
      backgroundColor: "primary.background",
    },
  }),
  ...((isFirstDay || isLastDay) && {
    ...selectedStyle,
    "&:hover, &:focus": selectedStyle,
  }),
  "&.Mui-disabled": {
    color: isLastDay ? "primary.contrastText" : "",
  },
});
