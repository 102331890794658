import { Link as BaseLink, type LinkProps } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { type FAQ } from "../../../Components/FAQ/FAQAccordion";

const Link = (props: LinkProps) => (
  <BaseLink sx={{ pr: 0.7 }} {...props} underline="hover" target="_blank" rel="noreferrer" />
);

export const AWSFaq: FAQ[] = [
  {
    question: "Why are my invoices different from what I see in AWS Cost Explorer?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          In many cases when customers review their AWS Cost Explorer, AWS Billing, or AWS Cost & Usage Report, they
          will see costs that are different from the invoices that DoiT sends them. These differences boil down to a
          couple of main categories:
        </Typography>
        <ul>
          <li>
            <Typography>
              <Link href="https://help.doit.com/docs/flexsave/aws#what-changes-should-i-expect-in-aws-console">
                Flexsave:
              </Link>
              The most common reason for a difference in invoicing is DoiT's Flexsave product. Flexsave delivers the
              equivalent savings of applicable AWS 1-year No-Upfront Savings Plans. The mechanisms behind Flexsave
              involve DoiT purchasing a large inventory of 3-year No-Upfront Savings Plans and assigning these to
              customers to generate savings. The costs in AWS are based on these 3-year Savings Plans. The difference
              between these 2 plans provides us a development and risk budget.
            </Typography>
          </li>

          <li>
            <Typography>
              <b>AWS Support Costs</b>: Partner-led support is billed by AWS differently from AWS-direct support. In
              most cases, DoiT is billed by a separate AWS organization and then re-assigns this cost to customers via
              our own billing system. Partner-Led support is lower cost than AWS-direct support as DoiT provides the
              initial triage and oftentimes resolution to issues, reducing load on AWS Support.
            </Typography>
          </li>

          <li>
            <Typography>
              <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#promotional-credits">
                DoiT-Provided Discounts:
              </Link>
              In some cases, DoiT provides customer-specific discounts that AWS' billing systems will not reflect.
            </Typography>
          </li>

          <li>
            <Typography>
              <Link href="https://help.doit.com/docs/cloud-analytics/reports/report-dimensions-groupings-and-filters#credits-and-discounts">
                Credits:
              </Link>
              When AWS supplies you with credits, we pass them on in full. They do, however, require extra processing on
              the DoiT side. To ensure that you receive them, please log a ticket with us or let your DoiT Account
              Manager know so we can flag it internally.
            </Typography>
          </li>
          <Typography>
            Scale: Since DoiT has thousands of AWS customers, economies of scale and tiered pricing from AWS affect our
            costs. Services such as AWS CloudFront or Simple Storage Service (S3) have a pricing model where the unit
            price becomes cheaper as usage increases.
          </Typography>
        </ul>

        <Typography>
          For more information on understanding the differences between your DoiT invoice and the AWS Billing Console,
          please see{" "}
          <Link href="https://help.doit.com/docs/billing/invoices-and-payments/understand-your-invoice">here.</Link>
        </Typography>
      </Stack>
    ),
  },
  {
    question: "I joined DoiT in the middle of the month. Am I going to receive two invoices?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          If you have a shared payer account and onboarded with DoiT in the middle of the month, you will receive two
          invoices for this month: one from DoiT, covering the period starting from the onboarding date, and a second
          from AWS, covering from the first of the month to the onboarding date.
        </Typography>
        <Typography>If you have a dedicated payer account, you will receive one invoice from DoiT</Typography>
      </Stack>
    ),
  },
];

export const GCPFaq: FAQ[] = [
  {
    question: "Why are my invoices different from what I see in GCP Console?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          In many cases when customers review their GCP Console or Billing, they will see costs that are different from
          the invoices that DoiT sends them. These differences boil down to a couple of main categories:
        </Typography>

        <ul>
          <li>
            <Typography>
              <Link href="https://help.doit.com/docs/flexsave/gcp#what-changes-should-i-expect-in-google-cloud-console">
                Flexsave:
              </Link>
              If you have Flexsave enabled, these discrepancies are fully expected. Flexsave is reflected with 3-year
              CUDs in GCP console while CMP reports/Invoice reflects the actual 1-year CUD applied.
            </Typography>
          </li>

          <li>
            <Typography>
              <Link href="https://help.doit.com/docs/billing/invoices-and-payments/understand-your-invoice#credits-adjustments">
                Promotions and Credits:
              </Link>
              These credits are not automatically passed to the customer. Credit assignment to a customer is governed by
              their contract.
            </Typography>
          </li>
          <Typography>
            <b>Custom Pricebook:</b> Some SKUs may have different pricing/discounts applied. Some SKUs may have
            different pricing/discounts applied, and this may vary per customer. Pricebook changes are processed in raw
            billing data before the data arrives in DoiT Reports. DoiT Reports reflect final pricebook prices, but the
            custom pricebook is not visible in the DoiT Console.
          </Typography>

          <li>
            <Typography>
              <b>DoiT-Provided</b>{" "}
              <Link href="https://help.doit.com/docs/billing/invoices-and-payments/understand-your-invoice#credits-adjustments">
                Discounts:
              </Link>
              If you received a specific % discount through your contract with DoiT, this price will not be reflected in
              the GCP Console/GCP Billing. The discount will be applied automatically to all eligible Services including
              those with custom pricing. Because{" "}
              <Link href="https://help.doit.com/docs/cloud-analytics/reports/report-dimensions-groupings-and-filters#credits-and-discounts">
                not all services are eligible
              </Link>
              for this % discount, the difference between your invoice and GCP Billing Console may not nicely equal the
              agreed upon percentage.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Looker:</b> Looker is billed on a separate invoice from DoiT.
            </Typography>
          </li>
          <li>
            <Typography>
              <b>Multiple Billing Accounts:</b> GCP Billing Console only shows one Billing Account at a time. DoiT
              Invoices display all your consolidated Billing Accounts at once.
            </Typography>
          </li>
        </ul>

        <Typography>
          You can use DoiT’s Report function to help you break down your cloud spend to understand your cost and usage
          data in a more granular way. More information{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/report-dimensions-groupings-and-filters#overview">
            here.
          </Link>
        </Typography>
      </Stack>
    ),
  },
  {
    question: "Why are my GKE costs not what I would have expected?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          Not all Google Kubernetes Engine (GKE) costs are categorized under the Service "GKE" in your billing data.
          DoiT's GKE Lens measures Namespace costs based on the resource requests of workloads running within that
          Namespace. This means that certain costs categorized as "Namespace N/A" in the DoiT Console may be from
          services that are not GKE. However, costs under a "named" (non-N/A) Namespace in the DoiT Console will always
          only be GKE costs.
        </Typography>
        <Typography>For example:</Typography>

        <ul>
          <li>
            <Typography>
              Autopilot costs are categorized under the Service "GKE" because Autopilot is priced per pod.
            </Typography>
          </li>
          <li>
            <Typography>
              GKE Standard costs are categorized under the Service "Google Compute Engine" because Standard is priced
              per node, and nodes are GCE resources.
            </Typography>
          </li>
        </ul>

        <Typography>
          <b>Additional Details: GKE Cost Categorization</b>
        </Typography>
        <Typography>GKE costs are categorized into two Services:</Typography>
        <Typography>
          1. Google Kubernetes Engine (GKE) – includes the{" "}
          <Link href="https://cloud.google.com/kubernetes-engine/pricing#cluster_management_fee_and_free_tier">
            cluster management fees
          </Link>
          and any GKE Autopilot (pod) costs.
        </Typography>
        <Typography>
          2. Google Compute Engine (GCE) – includes all underlying compute capacity cost (GKE nodes) generated by
          Standard GKE clusters.
        </Typography>
      </Stack>
    ),
  },
  {
    question: "How can I understand my GKE costs in more detail?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          You can use the DoiT Console to uncover how different categorization may be impacting your GKE costs. Details
          about categorization are explained in the "Why are my GKE costs not what I would have expected?" question.
        </Typography>
        <Typography>
          By following the below steps, you can see why the cost of "Namespace N/A" is so high compared to the other
          Namespaces.
        </Typography>
        <Typography>Steps:</Typography>
        <Typography>
          1. Activate GKE Cost Allocation, so that the DoiT Console can display your GKE costs in more detail. You will
          need to enable access{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/gke-cost-analytics#enable-gke-cost-allocation">
            cluster by cluster.
          </Link>
        </Typography>
        <Typography>
          2. Once GKE Cost Allocation is enabled, you should be able to see a{" "}
          <Link href="https://help.doit.com/docs/dashboards/gke-lens">GKE Lens</Link>dashboard in your DoiT Console
          Dashboards page. You can now build reports to analyze your GKE costs by{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/gke-cost-analytics#google-kubernetes-engine-dimensions">
            Namespace and Cluster.
          </Link>
        </Typography>
        <Typography>
          3. Build a custom report to dive into your costs in more detail. To compare the namespace costs against
          non-namespace costs within GKE, we need to add the GKE Cluster as a filter when we remove the GKE Namespace
          filter. In the Console, select "Analytics" → "Reports" → "New Report". Now select "Table" under "View As".
          Group by "GKE Namespace". Run the report.
        </Typography>
        <Typography>
          - If you notice a lot of costs under the "Namespace N/A", that is because when looking at the "N/A" namespace
          without any other filters, you will see other cloud costs not related to GKE inside of this "N/A" category.
        </Typography>
        <Typography>
          4. Add a Filter for "GKE Namespace N/A" by clicking on the "GKE Namespace" tile under Group by. Select only
          "[GKE Namespace N/A]". Click "Save". Add a Group by for "Service" and one for "SKU". Re-run the report.
        </Typography>
        <Typography>
          - Now it is possible to see that many "Namespace N/A" costs are not related to GKE at all, but are from other
          Services.
        </Typography>
        <Typography>
          Moving forward, you can accurately show the fractional cost of the resources your pods are consuming from the
          underlying GCE instances by excluding "[GKE Cluster N/A]" from the "GKE Cluster" Group by or Filter option in
          Reports.
        </Typography>
      </Stack>
    ),
  },
  {
    question:
      "If we create an additional Google Cloud billing account in the DoiT Console, will we receive separate invoices for each billing account?",
    answer: (
      <Stack spacing={1}>
        <Typography>No, you will not receive separate invoices for each billing account.</Typography>
        <Typography>
          However, you can use{" "}
          <Link href="https://help.doit.com/docs/billing/billing-profiles/changing-invoice-settings#invoice-bucketing">
            invoice bucketing
          </Link>
          to separate your invoices if desired. For more details, please see the FAQ for “How can I see my invoice
          broken down by something other than by project or account?”.
        </Typography>
      </Stack>
    ),
  },
];

export const DoiTFaq: FAQ[] = [
  {
    question: "How can I see how my credits were applied to my invoice?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          You can use the DoiT Console{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/create-report">Report Builder</Link>to create a
          Report that provides a breakdown of Credits applied. To build this, simply add the{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/report-dimensions-groupings-and-filters#credits-and-discounts">
            "Credit"
          </Link>
          dimension in the
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#filter-results-group-by-and-dimensions">
            "Group by"
          </Link>
          field. Tip: we recommend selecting the "Table" or "Heatmap" format option under the{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#view-as">
            "View As"
          </Link>
          dropdown.
        </Typography>

        <Typography>
          You can then modify other report settings as needed e.g. Time Range, Time Interval, etc. You can also add
          layers of granularity in the
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#filter-results-group-by-and-dimensions">
            "Group by"
          </Link>
          section. For example, you could add ‘Project ID’ to view credits applied to each Project.
        </Typography>

        <Typography>
          <b>Promotional Credits</b>
        </Typography>

        <Typography>
          Not all potential credits are included in DoiT Reports by default! (see{" "}
          <Link
            href="https://help.doit.com/docs/billing/invoices-and-payments/understand-your-invoice#promotional-credits-in-reports"
            sx={{ pr: 0 }}
          >
            here
          </Link>
          )
        </Typography>

        <Typography>
          When creating your Report, be sure to check the box “Include promotional credits” to also view the Promotional
          Credits are added to your monthly invoices.{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#promotional-credits">
            Promotional credits
          </Link>
          in the DoiT Console include migration credits, commitment credits, vendor program credits, and other credits
          you receive from DoiT. They are not credits from cloud providers.
        </Typography>

        <Typography>
          Please note that the Promotional Credits checkbox is available only in reports with monthly or greater{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#time-range-and-time-interval">
            time interval.
          </Link>
        </Typography>

        <Typography>
          If you would like to view a summary of Credits and Status, please add our ‘Credits’ Widget to your Dashboard.
          Additional details can be found{" "}
          <Link href="https://help.doit.com/docs/billing/invoices-and-payments/understand-your-invoice#credits-widget">
            here.
          </Link>
        </Typography>
      </Stack>
    ),
  },
  {
    question: "How can I see my invoice broken down by something other than by project or account?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          Custom{" "}
          <Link href="https://help.doit.com/docs/billing/billing-profiles/changing-invoice-settings#invoice-bucketing">
            "invoice bucketing"
          </Link>
          allows you to generate DoiT invoices with flexibility.
        </Typography>
        <Typography>
          Typical use cases for Invoice Bucketing include:
          <ul>
            <li>
              <Typography>
                You have many projects or accounts in the same billing profile, and you want separate invoices for
                different groups of projects/accounts.
              </Typography>
            </li>

            <li>
              <Typography>
                You have several cost centers in the same billing profile, and you want separate invoices for each cost
                center.
              </Typography>
            </li>

            <li>
              <Typography>
                Cost centers are entities (e.g., products, environments, teams, etc.) in your organization to which
                costs can be allocated.
              </Typography>
            </li>
          </ul>
        </Typography>

        <Typography>
          Setting up customized DoiT invoices is easy - please follow the instructions here to create groups of
          projects/accounts and label them however you'd like. You will then receive separate invoices for each bucket,
          billed to your billing profile.
        </Typography>
      </Stack>
    ),
  },
  {
    question: "How can I figure out what I’m spending money on?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          DoiT created our <Link href="https://help.doit.com/docs/cloud-analytics/reports">Report</Link>capabilities to
          enable customers to see in detail what is contributing to their cloud costs.
        </Typography>
        <Typography>
          You can find{" "}
          <Link href="https://help.doit.com/docs/dashboards/accessing-a-public-dashboard">pre-set Dashboards</Link>and{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/preset-reports">pre-set Reports</Link>in the
          DoiT Console that do not require any set up. Reports in these pre-set Dashboards can be{" "}
          <Link href="https://help.doit.com/docs/dashboards/add-reports-to-dashboards#open-full-report">opened</Link>
          and{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/create-report#clonecustomize-from-the-report-page">
            customized
          </Link>
          in our Report builder view. The pre-set{" "}
          <Link href="https://help.doit.com/docs/dashboards/pulse">Pulse Dashboard</Link>under your Dashboard tab is a
          good place to start when initially diving into your cloud costs.
        </Typography>
        <Typography>
          Alternatively, a new Report can be created from scratch following{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/create-report">these instructions.</Link>
          Information about the "dimensions" that can be used to analyze your cost and usage data can be found{" "}
          <Link href="https://help.doit.com/docs/cloud-analytics/reports/report-dimensions-groupings-and-filters">
            here.
          </Link>
        </Typography>
        <Typography>
          Dashboards can also be fully{" "}
          <Link href="https://help.doit.com/docs/dashboards/customizing-dashboards" sx={{ pr: 0 }}>
            customized
          </Link>
          , and any customized Reports that you build yourself{" "}
          <Link href="https://help.doit.com/docs/dashboards/add-reports-to-dashboards" sx={{ pr: 0 }}>
            can be added to them
          </Link>
          .
        </Typography>
        <Typography>
          Some helpful tips when creating a new Report or editing a Report:
          <ul>
            <li>
              To dive into costs in a more granular way, select "Table" or "Heatmap" under the "View as" dropdown. With
              this view, you can easily view multiple{" "}
              <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#limit-results">
                "Group by"
              </Link>
              dimensions.
              <br />
              <em>Hint: Try selecting "Service" and then "SKU" under "Service" to start with.</em>
            </li>
            <li>
              If too many results populate in a report, you may want to limit the number of results by using our{" "}
              <Link href="https://help.doit.com/docs/cloud-analytics/reports/editing-your-cloud-report#limit-results">
                Limit
              </Link>
              capabilities.
            </li>
            <li>
              Your organization’s AWS/GCP{" "}
              <Link href="https://help.doit.com/docs/cloud-analytics/reports/report-dimensions-groupings-and-filters#labels">
                Tags and Labels
              </Link>{" "}
              should appear as an option under the Group by/Filter selection dropdown if you scroll down. If they do
              not, you can file a request that they be added. For additional information on adding AWS Tags to the
              Console, please see{" "}
              <Link href="https://help.doit.com/docs/amazon-web-services/supported-aws-cost-allocation-tags#custom-allocation-tags">
                here.
              </Link>
            </li>
          </ul>
        </Typography>
      </Stack>
    ),
  },
  {
    question: "I already paid my invoice, but its status is still outstanding. Why?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          Since the payment needs to go through the banks and our reconciliations, it will take 3-4 days until the
          status is updated. Then the invoice will be marked as Paid.
        </Typography>
      </Stack>
    ),
  },
  {
    question: "Do we have a way to identify unused resources in the Console?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          No. The Console surfaces your billing data. In order to identify unused resources for you, we would need
          monitoring data in addition to the billing data we have access to to surface which resources are unused by
          your organization. There are a few exceptions to this that we can identify (eg. for{" "}
          <Link
            href="https://cloud.google.com/bigquery/docs/reservations-monitoring#reservation_cost_attribution"
            sx={{ pr: 0 }}
          >
            BigQuery
          </Link>
          ), but they rely on SKUs that are not well defined.
        </Typography>

        <Typography>
          If you would like to uncover unnecessary spending specific to your situation, please request a cost
          analysis/cost optimization review with our in-house experts{" "}
          <Link href="https://help.doit.com/docs/services/training#register-interest">via the Console.</Link>
        </Typography>
      </Stack>
    ),
  },
  {
    question: "How will setting up Flexsave with Google Marketplace affect the invoice that I receive from DoiT?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          If you are a resold customer with DoiT, and have signed up via{" "}
          <Link href="https://help.doit.com/docs/flexsave/gcp/gcp-marketplace" sx={{ pr: 0 }}>
            Google Marketplace
          </Link>
          , you will not have any changes in the way you are invoiced by DoiT, other than a refunded 1 cent charge for
          Flexsave on your DoiT invoice.
        </Typography>

        <Typography>
          If you are a Flexsave SaaS customer, you will be invoiced through the GCP Marketplace rather than DoiT.
        </Typography>
      </Stack>
    ),
  },
  {
    question: "Why does the Flexsave for Google Marketplace listing cost $0.01 per unit?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          Marketplace consumption must have a cost attached to it, so we charge 1 cent per Flexsave unit, which will be
          reflected in the GCP Marketplace console. Regardless of how many units the customer utilizes, the end total
          will be credited back to them when they receive their invoice, thus maintaining Flexsave's zero-cost pricing
          model.
        </Typography>
      </Stack>
    ),
  },
  {
    question:
      "I set up Flexsave with Google Marketplace. How will this affect what I see in my Google Console, and why does it seem like Flexsave is costing me money?",
    answer: (
      <Stack spacing={1}>
        <Typography>
          Flexsave works by attaching 3-year CUDs to your workloads. DoiT then recalculates those savings to the 1-year
          CUD savings rate. This 1-year savings rate is displayed in your DoiT invoice and in the DoiT Console.
        </Typography>

        <Typography>
          Unfortunately, Google does not have a mechanism for doing this recalculation. This means that your Google
          Console may display in inaccurate savings amount, as Google Console assumes you applied your own 3-year CUDs
          and the 'Use of doit-flexsave endpoints'. As a result, the numbers in your GCP Console should not be used as a
          source of truth. Instead, you can rely on the data in your{" "}
          <Link href="https://app.doit-intl.com/login">DoiT Console</Link>to reflect these savings accurately.
        </Typography>

        <Typography>
          You will never be charged in an invoice for the 'Use of doit-flexsave endpoints' as a DoiT customer.
        </Typography>
      </Stack>
    ),
  },
];
