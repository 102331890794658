import { type ThemeMode, ThemeModes } from "../muiThemeTypes";
import { isPartnerDomain } from "./domains";

const themeTypeKey = "themeType";

export const getCurrentTheme = (): ThemeMode | null => {
  // Partners domains have only light mode
  if (isPartnerDomain()) {
    return ThemeModes.LIGHT;
  }

  // Check if theme type is already defined
  const theme = window.localStorage.getItem(themeTypeKey) as ThemeMode | null;

  if (theme) {
    return theme;
  }

  // If no theme, check if user have preferences to use dark theme
  const { matches } = window.matchMedia("(prefers-color-scheme: dark)");

  return matches ? ThemeModes.DARK : ThemeModes.LIGHT;
};

export const setCurrentTheme = (theme: ThemeMode | null): ThemeMode => {
  const nextThemeType = theme === ThemeModes.LIGHT ? ThemeModes.DARK : ThemeModes.LIGHT;
  window.localStorage.setItem(themeTypeKey, nextThemeType);
  return nextThemeType;
};
