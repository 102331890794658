import { createContext, useCallback, useContext, useMemo, useState } from "react";

type NotesData = {
  pinned?: number;
  unpinned?: number;
};

export type NotesSidePanel = {
  open: boolean;
  toggle: () => void;
  data: NotesData;
  set: (data: any) => void;
};

export const NotesPanelContext = createContext<NotesSidePanel>({
  open: true,
  toggle: () => {},
  data: {},
  set: () => {},
});

export const NotesContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<NotesData>({});

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const contextValue = useMemo(
    () => ({
      open,
      toggle: toggleOpen,
      data,
      set: setData,
    }),
    [data, open, toggleOpen]
  );

  return <NotesPanelContext.Provider value={contextValue}>{children}</NotesPanelContext.Provider>;
};

export const useNotesContext = () => useContext(NotesPanelContext);
