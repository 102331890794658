import startCase from "lodash/startCase";

import { globalText, sandboxText } from "../../assets/texts";
import {
  type DataColumn,
  type DataColumns,
  type DefaultFilterColumns,
  type HeaderColumn,
} from "../../types/FilterTable";
import { formatCurrency } from "../../utils/common";
import { type SandboxTableRow } from "./types";

const ownerColumn: DataColumn<SandboxTableRow> = {
  label: sandboxText.TABLE_HEADERS.OWNER,
  path: "data.email",
};

const statusColumn: DataColumn<SandboxTableRow> = {
  label: sandboxText.TABLE_HEADERS.STATUS,
  path: "data.status",
  toOption: (value: string) => ({ value, label: startCase(value) }),
};

export const filterColumns: DataColumns<SandboxTableRow> = [
  {
    label: sandboxText.TABLE_HEADERS.PROJECT,
    path: "data.projectId",
  },
  ownerColumn,
  statusColumn,
  {
    label: sandboxText.TABLE_HEADERS.TYPE,
    path: "_policy.data.interval",
    toOption: (value: string) => ({ value, label: startCase(value) }),
  },
  {
    label: sandboxText.TABLE_HEADERS.ACTION,
    path: "_policy.data.action",
    toOption: (value: string) => ({ value, label: startCase(value) }),
  },
  {
    label: sandboxText.TABLE_HEADERS.SPEND,
    path: "spend",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
    toOption: (value: number) => ({ value, label: formatCurrency(value, "USD", 2) }),
  },
];

export const headers: readonly HeaderColumn[] = [
  {
    value: "data.projectId",
    label: sandboxText.TABLE_HEADERS.PROJECT,
    tooltip: sandboxText.TABLE_HEADERS.PROJECT,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.email",
    label: sandboxText.TABLE_HEADERS.OWNER,
    tooltip: sandboxText.TABLE_HEADERS.OWNER,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.status",
    label: sandboxText.TABLE_HEADERS.STATUS,
    tooltip: sandboxText.TABLE_HEADERS.STATUS,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.createdAt",
    label: globalText.CREATED,
    tooltip: globalText.CREATED,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.interval",
    label: sandboxText.TABLE_HEADERS.TYPE,
    tooltip: sandboxText.TABLE_HEADERS.TYPE,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.action",
    label: sandboxText.TABLE_HEADERS.ACTION,
    tooltip: sandboxText.TABLE_HEADERS.ACTION,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "spend",
    label: sandboxText.TABLE_HEADERS.SPEND,
    tooltip: sandboxText.TABLE_HEADERS.SPEND,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "progressVal",
    label: "",
    tooltip: "",
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
];

export const defaultFilters = (email: string, isDoitEmployee: boolean): DefaultFilterColumns<SandboxTableRow> => {
  const filters = [
    {
      column: statusColumn,
      comparator: "==",
      value: "active",
      label: "Active",
    } as const,
  ];

  if (!isDoitEmployee) {
    return [
      ...filters,
      {
        column: ownerColumn,
        comparator: "==",
        value: email,
        label: email,
      } as const,
    ];
  }

  return filters;
};
