import { createContext, type ReactNode, useContext } from "react";

import { type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";

import { useCloudConnect } from "../../Components/hooks/useCloudConnect";
import { type Customer } from "../../types";

export type CloudConnectType = ModelIdRef<CustomerModelCloudConnectModel>[] | undefined;
const cloudConnectContext = createContext<CloudConnectType>([]);

export const CloudConnectProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const cloudConnect = useCloudConnect(customer);

  return <cloudConnectContext.Provider value={cloudConnect}>{children}</cloudConnectContext.Provider>;
};

export const useCloudConnectContext = () => useContext(cloudConnectContext);
