// import { Timeline, TimelineEvent } from 'react-event-timeline';
import { makeStyles } from "@mui/styles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import Paper from "@mui/material/Paper";

import CreatedRequestIcon from "@mui/icons-material/StarBorder";
import AssignedAgentIcon from "@mui/icons-material/AssignmentInd";
import SolvedRequestIcon from "@mui/icons-material/Check";
import ReopenRequestIcon from "@mui/icons-material/Replay";
import ClosedRequestIcon from "@mui/icons-material/Star";
import EscalateRequestIcon from "@mui/icons-material/NotificationsActive";

const iconArr = [
  <CreatedRequestIcon key={0} />,
  <AssignedAgentIcon key={1} />,
  <SolvedRequestIcon key={2} />,
  <ReopenRequestIcon key={3} />,
  <ClosedRequestIcon key={4} />,
  <EscalateRequestIcon key={5} />,
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
    width: "100%",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  missingContent: {
    "&::before": {
      flex: 0,
      padding: 0,
    },
  },
  root: {
    // padding: "6px 8px",
  },
}));

export default function CustomizedTimeline(props) {
  const classes = useStyles();

  if (props.ticketTimelineData.length === 0) {
    return "";
  }

  return (
    <Timeline align="left">
      {props.ticketTimelineData.map((event, i) => (
        <TimelineItem key={i} classes={{ missingOppositeContent: classes.missingContent, content: classes.root }}>
          <TimelineSeparator>
            <TimelineDot variant={props.ticketTimelineData.length - 1 <= i ? "outlined" : "default"} color="primary">
              {iconArr[event.type]}
            </TimelineDot>
            {props.ticketTimelineData.length - 1 > i && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={2} className={classes.paper}>
              <Typography variant="subtitle2">{event.title}</Typography>
              <Typography variant="body2" color="textSecondary">
                {DateTime.fromISO(event.created).toLocaleString(DateTime.DATETIME_SHORT)}
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
