import { TableCell } from "@mui/material";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { FormattedCostCell } from "../../../Common/TableCell/FormattedCostCell";
import { FamilyTypeTableCell } from "../Common/commonTableCells";
import { type ManualPurchaseItem } from "../types";

type Props = {
  row: ManualPurchaseItem;
};
export const PurchaseSummaryItem = ({ row }: Props) => (
  <CellsWrapper>
    <TableCell>{row.billingAccountId}</TableCell>
    <TableCell>{row.region}</TableCell>
    <FamilyTypeTableCell type={row.type} />
    <TableCell>{row.hardware}</TableCell>
    <TableCell>{row.amount}</TableCell>
    <FormattedCostCell cost={row.cost} />
  </CellsWrapper>
);
