import { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";

import { cloudAnalyticsText } from "../../assets/texts";
import useGKECostAllocation from "../../Components/hooks/cloudAnalytics/useGKECostAllocation";
import { useFullScreen } from "../../utils/dialog";
import { LearnMoreAlert } from "./LearnMoreAlert";

const GkeUsageAlert = () => {
  const [showGkeCostAllocationAlert, setShowGkeCostAllocationAlert] = useState(false);
  const { isMobile } = useFullScreen("sm");
  const [gkeCostAllocation] = useGKECostAllocation();

  useEffect(() => {
    const showAlert = !isMobile && !!gkeCostAllocation && !gkeCostAllocation.enabled;
    setShowGkeCostAllocationAlert(showAlert);
  }, [isMobile, gkeCostAllocation]);

  if (showGkeCostAllocationAlert) {
    return (
      <Grid item sm={12}>
        <LearnMoreAlert
          text={cloudAnalyticsText.GKE_COST_ALLOCATION_ALERT_TEXT}
          url={cloudAnalyticsText.GKE_COST_ALLOCATION_ALERT_HREF}
        />
      </Grid>
    );
  }

  return null;
};

export default GkeUsageAlert;
