import { useCallback } from "react";

import { TextField } from "@mui/material";

import { isNext10Contract } from "../../../../utils";
import { chargePerTermLabelMap } from "../../../const";
import { useContractFormContext } from "../../../ContractsFormContext";

export const ChargePerTermInput = () => {
  const { state, setState } = useContractFormContext();

  const handleChange = useCallback(
    ({ target: { value } }) => {
      const price = value !== "" && Number(value) > 0 ? Number(value) : 0;
      const chargePerTerm = price;

      setState({
        ...state,
        chargePerTerm,
        errors: {
          ...state.errors,
          chargePerTerm: false,
        },
      });
    },
    [setState, state]
  );

  return (
    <TextField
      label={chargePerTermLabelMap[state.paymentTerm ?? "monthly"].label}
      data-cy="payment-amount"
      value={state.chargePerTerm === 0 ? "" : state.chargePerTerm}
      error={state.tier?.name === "enterprise" && state.errors.chargePerTerm}
      onChange={handleChange}
      disabled={isNext10Contract(state.type) ? state.isEditForbidden : state.tier?.name !== "enterprise"}
      type="number"
      margin="normal"
      variant="outlined"
      fullWidth
    />
  );
};
