import TableBody from "@mui/material/TableBody";

import { type KnownIssue } from "../types";
import { KnownIssuesTableRow, type KnownIssuesTableRowProps } from "./KnownIssuesTableRow";

export type KnownIssuesTableContentProps = {
  knownIssues: KnownIssue[];
  selectedKnownIssuesIds: KnownIssuesTableRowProps["selectedKnownIssuesIds"];
  onKnownIssueSelect: KnownIssuesTableRowProps["onKnownIssueSelect"];
  isAllowedToModify?: boolean;
};

export const KnownIssuesTableContent = ({
  knownIssues,
  selectedKnownIssuesIds,
  onKnownIssueSelect,
  isAllowedToModify,
}: KnownIssuesTableContentProps) => (
  <TableBody>
    {knownIssues.map((knownIssue) => (
      <KnownIssuesTableRow
        key={knownIssue.id}
        knownIssue={knownIssue}
        selectedKnownIssuesIds={selectedKnownIssuesIds}
        onKnownIssueSelect={onKnownIssueSelect}
        isAllowedToModify={isAllowedToModify}
      />
    ))}
  </TableBody>
);
