import { useCallback } from "react";

import { type AxiosInstance } from "axios";

export const cancelContract = async ({ api, contractId }: { api: AxiosInstance; contractId: string }) => {
  await api.post(`/v1/contract/cancel/${contractId}/`);
};

export const deleteContract = async ({ api, contractId }: { api: AxiosInstance; contractId: string }) => {
  await api.post(`/v1/contract/delete/${contractId}/`);
};

export function useTurnOffPresentationMode(api: AxiosInstance) {
  return useCallback(
    async (
      customerId: string,
      payload: {
        navigatorTierId?: string;
        solveTierId?: string;
      }
    ) => {
      const response = await api.patch(`/v1/customers/${customerId}/presentation-mode/turn-off`, payload);
      return response.data;
    },
    [api]
  );
}
