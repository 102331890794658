import { useEffect, useMemo, useState } from "react";

import { useParams } from "react-router";
import { getNodeOutputModel, type NodeModelWithId } from "@doitintl/cloudflow-commons";
import { CloudFlowNodeType, type NodeModel, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";

import { useCloudflowNodes } from "../../hooks/useCloudflowNodes";
import { usePromiseStateSetter } from "./usePromiseStateSetter";
import { getOutputModelByOperationPointer } from "./useUnwrappedApiActionModel";

type NodeWitOutputModel = {
  id: string;
  name: string;
  outputModel: UnwrappedApiServiceModelDescriptor;
};

function getPreviousNodes(allNodes: NodeModelWithId[], currentNodeId: string) {
  const previousNodes: NodeModelWithId[] = [];
  let parentNode: NodeModelWithId | undefined = allNodes.find(({ id }) => currentNodeId === id);

  while (
    (parentNode = allNodes.find(({ transitions }) =>
      transitions?.some(({ targetNodeId }) => targetNodeId === parentNode?.id)
    ))
  ) {
    if ([CloudFlowNodeType.ACTION, CloudFlowNodeType.FILTER, CloudFlowNodeType.CONDITION].includes(parentNode.type)) {
      previousNodes.unshift(parentNode);
    }
  }

  return previousNodes;
}

function isNodeWithOutputModel(node: {
  id: string;
  name: string;
  outputModel: UnwrappedApiServiceModelDescriptor | null;
}): node is NodeWitOutputModel {
  return node.outputModel !== null;
}

function getActionNodeModel(
  node: NodeModelWithId<CloudFlowNodeType.ACTION>
): Promise<UnwrappedApiServiceModelDescriptor | null> {
  return getOutputModelByOperationPointer(node.parameters.operation);
}

export function useReferenceableNodes(currentNodeId: string): NodeWitOutputModel[] {
  // FIXME: is there a better way of accessing current cloudflow nodes
  const { flowId } = useParams<{ flowId: string }>();
  const { cloudflowNodes } = useCloudflowNodes(flowId);
  const allNodes = useMemo(
    () =>
      cloudflowNodes?.map<NodeModelWithId>((node) => ({
        id: node.id,
        ...(node.data.nodeData as any as NodeModel),
      })) ?? [],
    [cloudflowNodes]
  );
  // end of FIXME

  const setPromiseState = usePromiseStateSetter();
  const [referenceableNodes, setReferenceableNodes] = useState<NodeWitOutputModel[]>([]);

  useEffect(() => {
    const previousNodes = getPreviousNodes(allNodes, currentNodeId);

    const referenceableNodes = (async () =>
      (
        await Promise.all(
          previousNodes.map(async (node) => ({
            id: node.id,
            name: node.name,
            outputModel: await getNodeOutputModel(getActionNodeModel, allNodes, node.id),
          }))
        )
      ).filter(isNodeWithOutputModel))();

    return setPromiseState(referenceableNodes, setReferenceableNodes);
  }, [allNodes, currentNodeId, setPromiseState]);

  return referenceableNodes;
}
