import avaLogoDark from "../../../assets/ava/ava-logo-dark.svg";
import avaLogoLight from "../../../assets/ava/ava-logo-light.svg";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";

type Props = {
  large?: boolean;
};
export const AvaIcon = ({ large }: Props) => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <img
      width={large ? "92px" : "25px"}
      height={large ? "71px" : "25px"}
      src={isDarkMode ? avaLogoDark : avaLogoLight}
      alt="ava-icon"
      aria-label="Ava icon"
    />
  );
};
