import { DateTime } from "luxon";
import { type SetRequired } from "type-fest";

import { type Contract } from "../../../../../types";
import { type CommitmentPeriodsFromContract } from "../../../types";

/**
 * A twelve-month ramp plan might have 13 billing periods in it, e.g. if it starts and ends in the middle of a month.
 * We need to know both the actual number of months in the ramp plan, and the number of billing periods in order to
 * accurately calculate the planned average monthly expense.
 *
 * @param start
 * @param end
 * @param adjustForDays // true: the number of billing periods; false: number of months.
 * @returns number
 */
export const monthDiff = (start: DateTime, end: DateTime, adjustForDays: boolean): number => {
  // get the basic time-length in months.
  const dateDiff = end.toUTC().startOf("day").diff(start.toUTC().startOf("day"), "months");
  let monthDiff = Math.ceil(dateDiff.months);

  // end.diff(start, "months") will return a fraction of months. If the start day is ahead of the end day, then we will
  // need to add one to the month count in order to reflect the number of months in which the ramp plan has periods.
  if (start.day >= end.day && adjustForDays) {
    monthDiff += 1;
  }
  return monthDiff;
};

// if end date is first day of last month, then change it to the last day of the previous
export const computeTrueEndDate = (periodEndDate: DateTime): DateTime => periodEndDate.minus({ days: 1 }).toUTC();

const computeBillableDaysInMonth = (
  startDateObj: DateTime,
  endDateObj: DateTime,
  monthIndex: number,
  totalBillableMonths: number
) => {
  if (monthIndex === 0) {
    const firstMonthTotalDays = startDateObj.endOf("month").day;
    return firstMonthTotalDays - startDateObj.day + 1;
  } else if (monthIndex === totalBillableMonths - 1) {
    return endDateObj.day;
  } else {
    return startDateObj.plus({ months: monthIndex }).endOf("month").day;
  }
};

const periodPlannedSpends = (periodInit: CommitmentPeriodsFromContract): CommitmentPeriodsFromContract => {
  if (!periodInit.endDate) {
    return periodInit;
  }

  const startDateObject = DateTime.fromSeconds(periodInit.startDate.seconds).toUTC();
  const endDateObject = computeTrueEndDate(DateTime.fromSeconds(periodInit.endDate.seconds).toUTC());

  // how many billing periods?
  const billingPeriodDurationInMonths = monthDiff(startDateObject, endDateObject, true);

  // how many days in the period (use average daily spend for accurate monthly projections)
  const totalBillableDaysInPeriod = endDateObject.diff(startDateObject, "days").days;

  for (let x = 0; x < billingPeriodDurationInMonths; x++) {
    const billableDaysInThisMonth = computeBillableDaysInMonth(
      startDateObject,
      endDateObject,
      x,
      billingPeriodDurationInMonths
    );
    periodInit.planned.push((periodInit.value * billableDaysInThisMonth) / totalBillableDaysInPeriod);
  }

  // checksum, make sure all months add up to expected total, and adjust first and last as needed
  // if total planned spend is not equal to periodInit.value (by more than a rounding error),
  // divide the difference in half and add it to the first and last months.
  const estPlannedSpend = Math.floor(periodInit.planned.reduce((sum, monthSpend) => sum + monthSpend, 0));
  const plannedPeriodSpend = Math.floor(periodInit.value);

  if (estPlannedSpend !== plannedPeriodSpend) {
    const roundingMissSplit = (plannedPeriodSpend - estPlannedSpend) / 2;
    periodInit.planned[0] += roundingMissSplit;
    periodInit.planned[periodInit.planned.length - 1] += roundingMissSplit;
  }

  return periodInit;
};

export const getInitPeriodsDataFromContract = (
  contract: SetRequired<Contract, "endDate" | "startDate" | "estimatedValue">
): CommitmentPeriodsFromContract[] => {
  if (!contract.commitmentPeriods?.length) {
    return [];
  }

  const periodsCount = contract.commitmentPeriods.length ?? 1;
  const allPeriods: CommitmentPeriodsFromContract[] = [];

  for (let i = 0; i < periodsCount; i++) {
    const periodInit: CommitmentPeriodsFromContract = {
      startDate: contract.commitmentPeriods?.[i]?.startDate || contract.startDate,
      endDate: contract.commitmentPeriods?.[i]?.endDate || contract.endDate,
      value: contract.commitmentPeriods?.[i]?.value || contract.estimatedValue,
      planned: [],
    };

    allPeriods.push(periodPlannedSpends(periodInit));
  }
  return allPeriods;
};
