import { type FC, useCallback, useEffect, useState } from "react";

import { type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import { type FieldInputProps, useFormikContext } from "formik";
import * as yup from "yup";

import { AddParametersButton } from "../AddParametersButton";
import { GenericForm } from "../ApiActionParametersForm";
import { generateApiActionParametersSchema, getInitialValueForModel } from "../useApiActionParametersSchema";
import { Fieldset } from "./wrappers/Fieldset";

export const StructureParam: FC<{
  fieldPath: string;
  fieldProps: FieldInputProps<string>;
  label: string;
  onRemove?: () => void;
  inputModel: StructureApiServiceModelDescriptor<Member>;
}> = ({ fieldPath, fieldProps, label, onRemove, inputModel }) => {
  const formikProps = useFormikContext();
  const [membersSortOrder, setMembersSortOrder] = useState<string[]>([]);
  const [membersToRender, setMembersToRender] = useState<string[]>([]);

  const membersSortOrderComparator = useCallback(
    (a, b) => membersSortOrder.indexOf(a) - membersSortOrder.indexOf(b),
    [membersSortOrder]
  );

  useEffect(() => {
    setMembersToRender(
      Object.entries(fieldProps.value)
        .filter(([, value]) => value !== undefined)
        .map(([memberName]) => memberName)
        .sort(membersSortOrderComparator)
    );
  }, [fieldProps.value, membersSortOrderComparator]);

  const getMemberPath = (memberName: string) => (fieldPath ? `${fieldPath}.${memberName}` : memberName);

  const createRemoveFn = (memberName: string) => () => {
    setMembersSortOrder((currentSortOrder) => currentSortOrder.filter((sortedMember) => sortedMember !== memberName));
    formikProps.setFieldValue(getMemberPath(memberName), undefined);
  };

  return (
    <Fieldset label={label} onRemove={onRemove}>
      <Stack spacing={2}>
        {membersToRender.map((memberName) => {
          const memberPath = getMemberPath(memberName);
          const onRemove = inputModel.requiredMembers?.includes(memberName) ? undefined : createRemoveFn(memberName);
          return (
            <GenericForm
              key={memberName}
              inputModel={inputModel.members[memberName].model}
              fieldPath={memberPath}
              label={memberName}
              onRemove={onRemove}
            />
          );
        })}
        <AddParametersButton
          alreadyAdded={membersToRender}
          label={label}
          members={inputModel.members}
          onMembersAdd={(membersToAdd) => {
            setMembersSortOrder((currentSortOrder) => currentSortOrder.concat(membersToAdd));

            const schema = generateApiActionParametersSchema(inputModel);
            membersToAdd.forEach((memberNameToAdd) => {
              const memberToAddPath = getMemberPath(memberNameToAdd);
              const memberToAddDefaultValue = yup
                .reach(schema, memberNameToAdd)
                .cast(getInitialValueForModel(inputModel.members[memberNameToAdd].model));
              formikProps.setFieldValue(memberToAddPath, memberToAddDefaultValue);
            });
          }}
        />
      </Stack>
    </Fieldset>
  );
};
