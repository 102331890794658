import { useEffect, useRef, useState } from "react";

import { Alert, AlertTitle, Box, Button, Dialog, List, ListItem, ListItemText, type SxProps } from "@mui/material";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { type CSVError, formatCSVError } from "../utils";

const MAX_ERRORS_TO_DISPLAY = 5;

type Props = {
  errors: CSVError[];
  sx?: SxProps;
};

const ErrorsAlert = ({ errors, sx }: Props) => {
  const [showErrorsDialog, setShowErrorsDialog] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errors.length > 0) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors.length]);

  const renderErrors = (errorsToRender: CSVError[]) => (
    <List sx={{ listStyleType: "disc", pl: 2 }}>
      {errorsToRender.map((e, i) => (
        <ListItem key={i} disablePadding>
          <ListItemText
            sx={{ display: "list-item", m: 0 }}
            primary={formatCSVError(e)}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={sx} ref={ref}>
      {errors.length > 0 && (
        <Alert severity="error">
          <AlertTitle>{datahubTxt.INVALID_FORMAT}</AlertTitle>
          {renderErrors(errors.slice(0, MAX_ERRORS_TO_DISPLAY))}
          {errors.length > MAX_ERRORS_TO_DISPLAY && (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                setShowErrorsDialog(true);
              }}
            >
              {datahubTxt.SHOW_MORE}
            </Button>
          )}
        </Alert>
      )}

      <Dialog
        open={showErrorsDialog}
        onClose={() => {
          setShowErrorsDialog(false);
        }}
        fullWidth
      >
        <Alert severity="error">
          <AlertTitle>{datahubTxt.INVALID_FORMAT}</AlertTitle>
          {renderErrors(errors)}
        </Alert>
      </Dialog>
    </Box>
  );
};

export default ErrorsAlert;
