import { type PurchaseStatus } from "@doitintl/cmp-models";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { type CustomerAggregatedPurchaseItem } from "../types";

const itemsDescriptions: FilterTableItemDescriptor<CustomerAggregatedPurchaseItem>[] = [
  {
    headerText: "Customer",
    value: "primaryDomain",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "customerType",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterOptions: {
      toOption: (value: PurchaseStatus) => ({
        value,
        label: value.toUpperCase(),
      }),
    },
    disableSorting: true,
  },
  {
    headerText: "30d coverage",
    value: "currentOverallCoverage",
    filterType: "number",
    filterOptions: {
      transform: (value) => value / 100,
    },
    tooltip: "Last 30 days demand baseline with current inventory",
  },
  {
    headerText: "24h coverage",
    value: "optimizerCoverage",
    tooltip: "24-48 hour demand baseline with current inventory",
  },
  {
    headerText: "Target coverage",
    value: "targetCoverage",
  },
  {
    headerText: "Force target coverage",
    value: "forceTargetCoverage",
    filterType: "string",
    filterOptions: {
      toOption: (value) => ({
        value,
        label: value ? "True" : "False",
      }),
    },
  },
  {
    headerText: "Show recommendations in bulk",
    value: "excludeCustomerFromBulk",
    filterType: "string",
    filterOptions: {
      toOption: (value) => ({
        value,
        label: value ? "False" : "True",
      }),
    },
  },
  {
    headerText: "Last purchase",
    value: "timePurchaseDone",
  },
  {
    headerText: "DoiT recs updated",
    value: "timeRecommendationUpdated",
  },
  {
    headerText: "Commitment cost",
    value: "purchasePlanPrice",
    filterType: "number",
  },
  {
    value: "@",
    headerText: "",
  },
  {
    value: "@",
    headerText: "",
  },
];

export const defaultFilters = [
  {
    column: {
      label: "Status",
      path: "status",
    },
    comparator: "==",
    value: "in-progress",
    label: "In Progress",
  },
] as const;

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
