import { useEffect, useState } from "react";

export const useKeyPress = (
  targetKey: string,
  options?: { shiftKey?: boolean; crtlKey?: boolean; altKey?: boolean; metaKey?: boolean }
) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    const keyDownHandler = (e) => {
      if (e.target.tagName.toLowerCase() === "input" && e.target.type === "text") {
        return;
      }

      if (e.target.tagName.toLowerCase() === "textarea") {
        return;
      }

      if (e.key.toLowerCase() === targetKey.toLowerCase()) {
        let oKey: string;
        if (options) {
          for (const [key, value] of Object.entries(options)) {
            oKey = value ? key : "";
            if (oKey && !e[oKey]) {
              return;
            }
          }
        }
        setKeyPressed(true);
        setTimeout(() => {
          setKeyPressed(false);
        }, 500);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // Event listener cleanup
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return keyPressed;
};
