import { useMemo } from "react";

import { type ContractModel } from "@doitintl/cmp-models";
import { Box, List, ListItem, ListItemText, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import values from "lodash/values";

import DataCouplet from "../../../Components/DataCouplet";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";
import { getContractLabel } from "../ContractForm/utils";
import { useCustomerContracts } from "../hooks";
import { getAssetLabel } from "../utils";

export type ContractPageGcpProps = {
  contract: ContractModel;
  accountManagerName?: string;
};

export const ContractPagePlps = ({ contract, accountManagerName }: ContractPageGcpProps) => {
  const { contracts } = useCustomerContracts();
  const { entities, entitiesLoading, assets, assetsLoading } = useCustomerContext();

  const relatedContracts = useMemo<string[]>(
    () =>
      contracts
        .filter((option) => contract.gcpContract && contract.gcpContract.findIndex((v) => v.id === option.id) > -1)
        .map((option) => getContractLabel(option)),
    [contract.gcpContract, contracts]
  );

  const entity = useMemo<Entity | undefined>(
    () => entities.find((ent) => ent.id === contract.entity?.id),
    [contract.entity?.id, entities]
  );

  const asset = values(assets)
    .flatMap((assetList) => assetList)
    .find((asset) => contract.assets && asset.id === contract.assets[0].id);

  const contractAsset = asset ? getAssetLabel(asset) : "";

  const contractFields = useMemo(
    () => (
      <List>
        {relatedContracts.map((ba) => (
          <ListItem disablePadding disableGutters key={ba}>
            <ListItemText>{ba}</ListItemText>
          </ListItem>
        ))}
      </List>
    ),
    [relatedContracts]
  );

  return (
    <Stack spacing={3}>
      <Typography variant="h1">Google Cloud Partner Led Premium Support</Typography>
      <Box component="section">
        <Typography variant="h2">General</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet
            key="billing-profile"
            field="Billing profile"
            value={entity ? `${entity.priorityId} - ${entity.name}` : "Unassigned"}
            loading={entitiesLoading}
          />
          <DataCouplet key="account-manager" field="Account manager" value={accountManagerName ?? NotAvailable} />
        </Stack>
      </Box>
      <Box component="section">
        <Typography variant="h2">Contract details</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet
            key="related-google-cloud-contract"
            field="Related Google Cloud contract"
            value={contractFields}
          />
          <DataCouplet
            field="Billing account"
            value={contractAsset}
            key="contract-billing-accounts"
            loading={entitiesLoading || assetsLoading}
          />
          <DataCouplet key="customer-charg" field="Customer charge" value={`${contract.plpsPercent}%`} />
        </Stack>
      </Box>
    </Stack>
  );
};
