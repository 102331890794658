export const LABEL_COLORS = [
  {
    name: "Light blue",
    color: "#BEE1F5",
  },
  {
    name: "Teal",
    color: "#9DD9D9",
  },
  {
    name: "Salmon",
    color: "#F2C7CF",
  },
  {
    name: "Light grey",
    color: "#CECEDB",
  },
  {
    name: "Green",
    color: "#A2B3AC",
  },
  {
    name: "Grey",
    color: "#A3A7C1",
  },
  {
    name: "Grey blue",
    color: "#A9C8DF",
  },
  {
    name: "Silver pink",
    color: "#CCA8B2",
  },
  {
    name: "Columbia blue",
    color: "#C5DEE3",
  },
  {
    name: "Pink",
    color: "#E6ABBB",
  },
  {
    name: "Slate grey",
    color: "#6A7F9F",
  },
] as const;
