import { useEffect, useState } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";

export const useIsABTesting = () => {
  const [supportData] = useDocumentDataOnce(getCollection(AppModel).doc("support"));

  return (supportData?.abTestingCounter ?? 0) % 2 === 0;
};

export const useIsPLPSCustomer = () => {
  const [exists, setExists] = useState<boolean>(false);

  const customerId = useCustomerId();
  useEffect(() => {
    (async () => {
      const ref = getCollection(AppModel).doc("support").collection("plpsCustomers").doc(customerId);

      const doc = await ref.get();

      setExists(doc.exists());
    })();
  }, [customerId]);

  return exists;
};
