const countries = [
  { COUNTRYNAME: "Afghanistan", COUNTRYCODE: "AF" },
  { COUNTRYNAME: "Albania", COUNTRYCODE: "AL" },
  { COUNTRYNAME: "Algeria", COUNTRYCODE: "DZ" },
  { COUNTRYNAME: "Andorra", COUNTRYCODE: "AD" },
  { COUNTRYNAME: "Angola", COUNTRYCODE: "AO" },
  { COUNTRYNAME: "Argentina", COUNTRYCODE: "AR" },
  { COUNTRYNAME: "Armenia", COUNTRYCODE: "AM" },
  { COUNTRYNAME: "Aruba", COUNTRYCODE: "AW" },
  { COUNTRYNAME: "Australia", COUNTRYCODE: "AU" },
  { COUNTRYNAME: "Austria", COUNTRYCODE: "AT" },
  { COUNTRYNAME: "Azerbaijan", COUNTRYCODE: "AZ" },
  { COUNTRYNAME: "Bahamas", COUNTRYCODE: "BS" },
  { COUNTRYNAME: "Bahrain", COUNTRYCODE: "BH" },
  { COUNTRYNAME: "Bangladesh", COUNTRYCODE: "BD" },
  { COUNTRYNAME: "Barbados", COUNTRYCODE: "BB" },
  { COUNTRYNAME: "Belarus", COUNTRYCODE: "BY" },
  { COUNTRYNAME: "Belgium", COUNTRYCODE: "BE" },
  { COUNTRYNAME: "Belize", COUNTRYCODE: "BZ" },
  { COUNTRYNAME: "Benin", COUNTRYCODE: "BJ" },
  { COUNTRYNAME: "Bermuda", COUNTRYCODE: "BM" },
  { COUNTRYNAME: "Bhutan", COUNTRYCODE: "BT" },
  { COUNTRYNAME: "Bolivia", COUNTRYCODE: "BO" },
  { COUNTRYNAME: "Bosnia Herzegovina", COUNTRYCODE: "BA" },
  { COUNTRYNAME: "Botswana", COUNTRYCODE: "BW" },
  { COUNTRYNAME: "Brazil", COUNTRYCODE: "BR" },
  { COUNTRYNAME: "Brunei", COUNTRYCODE: "BN" },
  { COUNTRYNAME: "Bulgaria", COUNTRYCODE: "BG" },
  { COUNTRYNAME: "Burkina Faso", COUNTRYCODE: "BF" },
  { COUNTRYNAME: "Burundi", COUNTRYCODE: "BI" },
  { COUNTRYNAME: "Cambodia", COUNTRYCODE: "KH" },
  { COUNTRYNAME: "Cameroon", COUNTRYCODE: "CM" },
  { COUNTRYNAME: "Canada", COUNTRYCODE: "CA" },
  { COUNTRYNAME: "Canary Islands", COUNTRYCODE: "IC" },
  { COUNTRYNAME: "Cape Verde", COUNTRYCODE: "CV" },
  { COUNTRYNAME: "Cayman Islands", COUNTRYCODE: "KY" },
  { COUNTRYNAME: "Cen. Afr. Rep.", COUNTRYCODE: "CF" },
  { COUNTRYNAME: "Chad", COUNTRYCODE: "TD" },
  { COUNTRYNAME: "Channel Islands", COUNTRYCODE: "GG" },
  { COUNTRYNAME: "Chile", COUNTRYCODE: "CL" },
  { COUNTRYNAME: "China", COUNTRYCODE: "CN" },
  { COUNTRYNAME: "Colombia", COUNTRYCODE: "CO" },
  { COUNTRYNAME: "Comoros", COUNTRYCODE: "KM" },
  { COUNTRYNAME: "Congo", COUNTRYCODE: "CG" },
  { COUNTRYNAME: "Costa Rica", COUNTRYCODE: "CR" },
  { COUNTRYNAME: "Cote d'Ivoire", COUNTRYCODE: "CI" },
  { COUNTRYNAME: "Croatia", COUNTRYCODE: "HR" },
  { COUNTRYNAME: "Cuba", COUNTRYCODE: "CU" },
  { COUNTRYNAME: "Curacao", COUNTRYCODE: "CW" },
  { COUNTRYNAME: "Cyprus", COUNTRYCODE: "CY" },
  { COUNTRYNAME: "Czech Republic", COUNTRYCODE: "CZ" },
  { COUNTRYNAME: "Denmark", COUNTRYCODE: "DK" },
  { COUNTRYNAME: "Djibouti", COUNTRYCODE: "DJ" },
  { COUNTRYNAME: "Dominica", COUNTRYCODE: "DM" },
  { COUNTRYNAME: "Dominican Repub", COUNTRYCODE: "DO" },
  { COUNTRYNAME: "Ecuador", COUNTRYCODE: "EC" },
  { COUNTRYNAME: "Egypt", COUNTRYCODE: "EG" },
  { COUNTRYNAME: "El Salvador", COUNTRYCODE: "SV" },
  { COUNTRYNAME: "Equat. Guinea", COUNTRYCODE: "GQ" },
  { COUNTRYNAME: "Eritrea", COUNTRYCODE: "ER" },
  { COUNTRYNAME: "Estonia", COUNTRYCODE: "EE" },
  { COUNTRYNAME: "Ethiopia", COUNTRYCODE: "ET" },
  { COUNTRYNAME: "Fiji", COUNTRYCODE: "FJ" },
  { COUNTRYNAME: "Finland", COUNTRYCODE: "FI" },
  { COUNTRYNAME: "France", COUNTRYCODE: "FR" },
  { COUNTRYNAME: "Gabon", COUNTRYCODE: "GA" },
  { COUNTRYNAME: "Gambia", COUNTRYCODE: "GM" },
  { COUNTRYNAME: "Georgia Republic", COUNTRYCODE: "GE" },
  { COUNTRYNAME: "Germany", COUNTRYCODE: "DE" },
  { COUNTRYNAME: "Ghana", COUNTRYCODE: "GH" },
  { COUNTRYNAME: "Gibraltar", COUNTRYCODE: "GI" },
  { COUNTRYNAME: "Greece", COUNTRYCODE: "GR" },
  { COUNTRYNAME: "Guadeloupe", COUNTRYCODE: "GP" },
  { COUNTRYNAME: "Guam", COUNTRYCODE: "GU" },
  { COUNTRYNAME: "Guatamala", COUNTRYCODE: "GT" },
  { COUNTRYNAME: "Guinea", COUNTRYCODE: "GN" },
  { COUNTRYNAME: "Guinea-Bissau", COUNTRYCODE: "GW" },
  { COUNTRYNAME: "Guyana", COUNTRYCODE: "GY" },
  { COUNTRYNAME: "Haiti", COUNTRYCODE: "HT" },
  { COUNTRYNAME: "Honduras", COUNTRYCODE: "HN" },
  { COUNTRYNAME: "Hong Kong", COUNTRYCODE: "HK" },
  { COUNTRYNAME: "Hungary", COUNTRYCODE: "HU" },
  { COUNTRYNAME: "Iceland", COUNTRYCODE: "IS" },
  { COUNTRYNAME: "India", COUNTRYCODE: "IN" },
  { COUNTRYNAME: "Indonesia", COUNTRYCODE: "ID" },
  { COUNTRYNAME: "Iran", COUNTRYCODE: "IR" },
  { COUNTRYNAME: "Iraq", COUNTRYCODE: "IQ" },
  { COUNTRYNAME: "Ireland", COUNTRYCODE: "IE" },
  { COUNTRYNAME: "Isle of Man", COUNTRYCODE: "IM" },
  { COUNTRYNAME: "Israel", COUNTRYCODE: "IL" },
  { COUNTRYNAME: "Italy", COUNTRYCODE: "IT" },
  { COUNTRYNAME: "Jamaica", COUNTRYCODE: "JM" },
  { COUNTRYNAME: "Japan", COUNTRYCODE: "JP" },
  { COUNTRYNAME: "Jordan", COUNTRYCODE: "JO" },
  { COUNTRYNAME: "Kazakhstan", COUNTRYCODE: "KZ" },
  { COUNTRYNAME: "Kenya", COUNTRYCODE: "KE" },
  { COUNTRYNAME: "Kiribati", COUNTRYCODE: "KI" },
  { COUNTRYNAME: "Korea (North)", COUNTRYCODE: "KP" },
  { COUNTRYNAME: "Korea (South)", COUNTRYCODE: "KR" },
  { COUNTRYNAME: "Kuwait", COUNTRYCODE: "KW" },
  { COUNTRYNAME: "Kyrgystan", COUNTRYCODE: "KG" },
  { COUNTRYNAME: "Laos", COUNTRYCODE: "LA" },
  { COUNTRYNAME: "Latvia", COUNTRYCODE: "LV" },
  { COUNTRYNAME: "Lebanon", COUNTRYCODE: "LB" },
  { COUNTRYNAME: "Lesotho", COUNTRYCODE: "LS" },
  { COUNTRYNAME: "Liberia", COUNTRYCODE: "LR" },
  { COUNTRYNAME: "Libya", COUNTRYCODE: "LY" },
  { COUNTRYNAME: "Liechtenstein", COUNTRYCODE: "LI" },
  { COUNTRYNAME: "Lithuania", COUNTRYCODE: "LT" },
  { COUNTRYNAME: "Luxembourg", COUNTRYCODE: "LU" },
  { COUNTRYNAME: "Macau", COUNTRYCODE: "MO" },
  { COUNTRYNAME: "Macedonia", COUNTRYCODE: "MK" },
  { COUNTRYNAME: "Madagascar", COUNTRYCODE: "MG" },
  { COUNTRYNAME: "Malawi", COUNTRYCODE: "MW" },
  { COUNTRYNAME: "Malaysia", COUNTRYCODE: "MY" },
  { COUNTRYNAME: "Maldives", COUNTRYCODE: "MV" },
  { COUNTRYNAME: "Mali", COUNTRYCODE: "ML" },
  { COUNTRYNAME: "Malta", COUNTRYCODE: "MT" },
  { COUNTRYNAME: "Marshal Islands", COUNTRYCODE: "MH" },
  { COUNTRYNAME: "Mauritania", COUNTRYCODE: "MR" },
  { COUNTRYNAME: "Mauritius", COUNTRYCODE: "MU" },
  { COUNTRYNAME: "Mexico", COUNTRYCODE: "MX" },
  { COUNTRYNAME: "Micronesia", COUNTRYCODE: "FM" },
  { COUNTRYNAME: "Moldova", COUNTRYCODE: "MD" },
  { COUNTRYNAME: "Monaco", COUNTRYCODE: "MC" },
  { COUNTRYNAME: "Mongolia", COUNTRYCODE: "MN" },
  { COUNTRYNAME: "Morocco", COUNTRYCODE: "MA" },
  { COUNTRYNAME: "Mozambique", COUNTRYCODE: "MZ" },
  { COUNTRYNAME: "Myanmar", COUNTRYCODE: "MM" },
  { COUNTRYNAME: "Namibia", COUNTRYCODE: "NA" },
  { COUNTRYNAME: "Nauru", COUNTRYCODE: "NR" },
  { COUNTRYNAME: "Nepal", COUNTRYCODE: "NP" },
  { COUNTRYNAME: "Netherlands", COUNTRYCODE: "NL" },
  { COUNTRYNAME: "New Caledonia", COUNTRYCODE: "NC" },
  { COUNTRYNAME: "New Zealand", COUNTRYCODE: "NZ" },
  { COUNTRYNAME: "Nicaragua", COUNTRYCODE: "NI" },
  { COUNTRYNAME: "Niger", COUNTRYCODE: "NE" },
  { COUNTRYNAME: "Nigeria", COUNTRYCODE: "NG" },
  { COUNTRYNAME: "Northrn Mariana", COUNTRYCODE: "MP" },
  { COUNTRYNAME: "Norway", COUNTRYCODE: "NO" },
  { COUNTRYNAME: "Oman", COUNTRYCODE: "OM" },
  { COUNTRYNAME: "Pakistan", COUNTRYCODE: "PK" },
  { COUNTRYNAME: "Palau", COUNTRYCODE: "PW" },
  { COUNTRYNAME: "Panama", COUNTRYCODE: "PA" },
  { COUNTRYNAME: "PapuaNewGuinea", COUNTRYCODE: "PG" },
  { COUNTRYNAME: "Paraguay", COUNTRYCODE: "PY" },
  { COUNTRYNAME: "Peru", COUNTRYCODE: "PE" },
  { COUNTRYNAME: "Philippines", COUNTRYCODE: "PH" },
  { COUNTRYNAME: "Pitcairn", COUNTRYCODE: "PN" },
  { COUNTRYNAME: "Poland", COUNTRYCODE: "PL" },
  { COUNTRYNAME: "Portugal", COUNTRYCODE: "PT" },
  { COUNTRYNAME: "Puerto Rico", COUNTRYCODE: "PR" },
  { COUNTRYNAME: "Qatar", COUNTRYCODE: "QA" },
  { COUNTRYNAME: "Romania", COUNTRYCODE: "RO" },
  { COUNTRYNAME: "Russian Federat", COUNTRYCODE: "RU" },
  { COUNTRYNAME: "Rwanda", COUNTRYCODE: "RW" },
  { COUNTRYNAME: "Saint Vincent", COUNTRYCODE: "VC" },
  { COUNTRYNAME: "Samoa", COUNTRYCODE: "WS" },
  { COUNTRYNAME: "San Marino", COUNTRYCODE: "SM" },
  { COUNTRYNAME: "Sao Tome", COUNTRYCODE: "ST" },
  { COUNTRYNAME: "Saudi Arabia", COUNTRYCODE: "SA" },
  { COUNTRYNAME: "Senegal", COUNTRYCODE: "SN" },
  { COUNTRYNAME: "Seychelles", COUNTRYCODE: "SC" },
  { COUNTRYNAME: "Sierra Leone", COUNTRYCODE: "SL" },
  { COUNTRYNAME: "Singapore", COUNTRYCODE: "SG" },
  { COUNTRYNAME: "Slovakia", COUNTRYCODE: "SK" },
  { COUNTRYNAME: "Slovenia", COUNTRYCODE: "SI" },
  { COUNTRYNAME: "Solomon Islands", COUNTRYCODE: "SB" },
  { COUNTRYNAME: "Somalia", COUNTRYCODE: "SO" },
  { COUNTRYNAME: "South Africa", COUNTRYCODE: "ZA" },
  { COUNTRYNAME: "Spain", COUNTRYCODE: "ES" },
  { COUNTRYNAME: "Sri Lanka", COUNTRYCODE: "LK" },
  { COUNTRYNAME: "St. Lucia", COUNTRYCODE: "LC" },
  { COUNTRYNAME: "Sudan", COUNTRYCODE: "SD" },
  { COUNTRYNAME: "Suriname", COUNTRYCODE: "SR" },
  { COUNTRYNAME: "Swaziland", COUNTRYCODE: "SZ" },
  { COUNTRYNAME: "Sweden", COUNTRYCODE: "SE" },
  { COUNTRYNAME: "Switzerland", COUNTRYCODE: "CH" },
  { COUNTRYNAME: "Syria", COUNTRYCODE: "SY" },
  { COUNTRYNAME: "Taiwan", COUNTRYCODE: "TW" },
  { COUNTRYNAME: "Tajikistan", COUNTRYCODE: "TJ" },
  { COUNTRYNAME: "Tanzania", COUNTRYCODE: "TZ" },
  { COUNTRYNAME: "Thailand", COUNTRYCODE: "TH" },
  { COUNTRYNAME: "Togo", COUNTRYCODE: "TG" },
  { COUNTRYNAME: "Tonga", COUNTRYCODE: "TO" },
  { COUNTRYNAME: "Trinidad&Tobago", COUNTRYCODE: "TT" },
  { COUNTRYNAME: "Tunisia", COUNTRYCODE: "TN" },
  { COUNTRYNAME: "Turkey", COUNTRYCODE: "TR" },
  { COUNTRYNAME: "Turkmenistan", COUNTRYCODE: "TM" },
  { COUNTRYNAME: "Turks Island", COUNTRYCODE: "TC" },
  { COUNTRYNAME: "Tuvalu", COUNTRYCODE: "TV" },
  { COUNTRYNAME: "U. Arab Emirate", COUNTRYCODE: "AE" },
  { COUNTRYNAME: "Uganda", COUNTRYCODE: "UG" },
  { COUNTRYNAME: "Ukraine", COUNTRYCODE: "UA" },
  { COUNTRYNAME: "United Kingdom", COUNTRYCODE: "GB" },
  { COUNTRYNAME: "United States", COUNTRYCODE: "US" },
  { COUNTRYNAME: "Uruguay", COUNTRYCODE: "UY" },
  { COUNTRYNAME: "Uzbekistan", COUNTRYCODE: "UZ" },
  { COUNTRYNAME: "Vanuatu", COUNTRYCODE: "VU" },
  { COUNTRYNAME: "Venezuela", COUNTRYCODE: "VE" },
  { COUNTRYNAME: "Vietnam", COUNTRYCODE: "VN" },
  { COUNTRYNAME: "Virgin Islands US", COUNTRYCODE: "VI" },
  { COUNTRYNAME: "Virgin Islands GB", COUNTRYCODE: "VG" },
  { COUNTRYNAME: "Yemen", COUNTRYCODE: "YE" },
  { COUNTRYNAME: "Yugoslavia", COUNTRYCODE: "YU" },
  { COUNTRYNAME: "Zaire", COUNTRYCODE: "ZR" },
  { COUNTRYNAME: "Zambia", COUNTRYCODE: "ZM" },
  { COUNTRYNAME: "Zimbabwe", COUNTRYCODE: "ZW" },
];

export default countries;
