import { createContext, type ReactNode, useContext, useMemo } from "react";

import { CustomerModel, EntityModel } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, type QueryModel, useCollectionData } from "@doitintl/models-firestore";

import { type Customer } from "../../types";

export type Entity = ModelIdRef<EntityModel>;

export type EntitiesContextType = {
  entities: Entity[];
  entitiesLoading: boolean;
};

const entitiesContext = createContext<EntitiesContextType | undefined>(undefined);

export const useCustomerEntities = (
  customerId: string | undefined | null,
  refineQuery?: (query: QueryModel<EntityModel>) => QueryModel<EntityModel>
) => {
  const customerRef = useMemo(
    () => (customerId ? getCollection(CustomerModel).doc(customerId) : undefined),
    [customerId]
  );

  const customerEntitiesQuery = useMemo(() => {
    if (!customerId) {
      return;
    }

    const baseQuery = getCollection(EntityModel).where("customer", "==", customerRef);

    return refineQuery?.(baseQuery) ?? baseQuery;
  }, [customerId, customerRef, refineQuery]);

  const [entities, loading] = useCollectionData(customerEntitiesQuery, { idField: "id", refField: "ref" });

  return useMemo(() => ({ entities: entities ?? [], loading }), [entities, loading]);
};

export const EntitiesContextProvider = ({
  children,
  customer,
}: {
  children: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const { entities, loading: entitiesLoading } = useCustomerEntities(customer?.id);

  return <entitiesContext.Provider value={{ entities, entitiesLoading }}>{children}</entitiesContext.Provider>;
};

export const useEntitiesContext = (): EntitiesContextType => {
  const context = useContext(entitiesContext);
  if (!context) {
    throw new Error("useEntitiesContext must be used within a EntitiesContextProvider");
  }
  return context;
};

export const EntitiesContextProviderForTesting = ({
  children,
  value,
}: {
  children: ReactNode;
  value?: Partial<Omit<EntitiesContextType, "entities">> & { entities?: Partial<Entity>[] };
}) => {
  const actualValue = value ?? {};

  actualValue.entities = value?.entities ?? [];
  actualValue.entitiesLoading = value?.entitiesLoading ?? false;

  return <entitiesContext.Provider value={actualValue as any}>{children}</entitiesContext.Provider>;
};
