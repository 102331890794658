import { useEffect, useState } from "react";

import { AccountManagerCompanies } from "@doitintl/cmp-models";

import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { consoleErrorWithSentry } from "../../utils";

function useFetchAmTamEmail() {
  const [accountManagers, setAccountManagers] = useState<string[]>(["team-saas@doit.com"]);
  const accountManagersForCustomer = AccountManagersHooks.useAccountManagersForCustomer();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAccountManagers = async () => {
      try {
        // if an account_manager is TERMINATED, it will be replaced with the strategic_accounts_manager
        if (accountManagersForCustomer) {
          setAccountManagers(
            accountManagersForCustomer
              .filter(
                (am) =>
                  am.company === AccountManagerCompanies.DOIT &&
                  ["account_manager", "technical_account_manager", "strategic_accounts_manager"].includes(am.role)
              )
              .map((am) => am.email)
          );
        }
      } catch (err) {
        consoleErrorWithSentry(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountManagers();
  }, [accountManagersForCustomer]);

  return { accountManagers, loading };
}

export default useFetchAmTamEmail;
