import { Box, Typography } from "@mui/material";

import maintenanceDark from "../../../assets/maintenance-dark.svg";
import maintenanceLight from "../../../assets/maintenance-light.svg";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";

export const PriorityMaintenance = () => {
  const isDarkMode = useDarkThemeCheck();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "60vh",
      }}
    >
      {isDarkMode ? (
        <img src={maintenanceDark} width="100" alt="maintenance" aria-hidden />
      ) : (
        <img src={maintenanceLight} width="100" alt="maintenance" aria-hidden />
      )}
      <Typography variant="h4" m={2}>
        This page is temporarily unavailable
      </Typography>
      <Typography variant="body1" color="text.secondary">
        Due to a system update, this page will be unavailable for up to 24 hours.
      </Typography>
      <Typography variant="body1" color="text.secondary">
        We apologize for any inconvenience.
      </Typography>
    </Box>
  );
};
