import { Link } from "react-router-dom";
import { type PerkModel } from "@doitintl/cmp-models";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { useFullScreen } from "../../../utils/dialog";
import { useIsProfessionalService } from "../hooks";
import { RegisterInterestFromType, type SectionTitles } from "../types";
import { PerkNavBar } from "./PerkNavBar";
import { RegisterInterestButton } from "./RegisterInterestButton";
import type { Ticket } from "../../../Support/types";

type Props = {
  disabledPerk: boolean;
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
  perk: PerkModel;
  sectionTitles: SectionTitles;
  backTo: string;
  ticket?: Ticket;
};

export const PerkStickyHeader = ({ disabledPerk, registerInterest, perk, sectionTitles, backTo, ticket }: Props) => {
  const theme = useTheme();
  const { isMobile } = useFullScreen();
  const isProfessionalService = useIsProfessionalService();

  return (
    <Box
      sx={{
        position: "sticky",
        top: "100px",
        right: 0,
        left: 0,
        width: "100%",
        pt: 2,
        backgroundColor: "background.default",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          height: "52px",
          alignItems: "center",
          padding: "0px !important",
          position: "relative",
          my: 2,
        }}
        disableGutters={isMobile}
      >
        {!isMobile && (
          <IconButton
            sx={{ position: "absolute", marginLeft: "-50px" }}
            aria-label="Back"
            component={Link}
            to={backTo}
            size="large"
          >
            <BackIcon sx={{ color: "text.primary" }} />
          </IconButton>
        )}

        <Box display="inline-flex" alignItems="center" mt={1}>
          {isMobile && (
            <IconButton aria-label="Back" component={Link} to={backTo} size="large">
              <BackIcon sx={{ color: "text.primary" }} />
            </IconButton>
          )}
          {!isProfessionalService(perk.fields.type) && !!perk.enrichment?.logoUrl && (
            <Avatar
              alt="logo"
              src={perk.enrichment?.logoUrl}
              imgProps={{ sx: { objectFit: "contain", bgcolor: "white" } }}
              sx={{ width: 34, height: 34, border: `0.5px solid ${theme.palette.grey[300]}` }}
            />
          )}
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {perk.fields?.name}
          </Typography>
        </Box>
        <RegisterInterestButton
          disabledPerk={disabledPerk}
          registerInterest={() => registerInterest(RegisterInterestFromType.InterestButton)}
          perkType={perk.fields.type}
          ticket={ticket}
        />
      </Container>
      <Divider />
      <PerkNavBar sectionTitles={sectionTitles} />
    </Box>
  );
};
