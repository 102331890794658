import { useCallback } from "react";

import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";

const googleGroupUrl = "v1/amazon-web-services/master-payer-accounts/google-group";

type googleGroupProps = {
  domain: string;
  rootEmail: string;
};

const useGoogleGroup = (): {
  createGoogleGroup: (googleGroupProps) => Promise<AxiosResponse>;
  updateGoogleGroup: (currentRootEmail: string, googleGroupProps) => Promise<AxiosResponse>;
  deleteGoogleGroup: (googleGroupProps) => Promise<AxiosResponse>;
} => {
  const api = useApiContext();

  const createGoogleGroup = useCallback(
    async ({ domain, rootEmail }: googleGroupProps): Promise<AxiosResponse> =>
      api.post<void, AxiosResponse>(`${googleGroupUrl}/init-creation-cloud-task`, {
        domain,
        rootEmail,
      }),
    [api]
  );

  const updateGoogleGroup = useCallback(
    async (currentRootEmail: string, { domain, rootEmail }: googleGroupProps): Promise<AxiosResponse> =>
      api.post<void, AxiosResponse>(`${googleGroupUrl}/update`, {
        currentRootEmail,
        domain,
        rootEmail,
      }),
    [api]
  );

  const deleteGoogleGroup = useCallback(
    async ({ domain, rootEmail }: googleGroupProps): Promise<AxiosResponse> =>
      api.post<void, AxiosResponse>(`${googleGroupUrl}/delete`, {
        domain,
        rootEmail,
      }),
    [api]
  );

  return {
    createGoogleGroup,
    updateGoogleGroup,
    deleteGoogleGroup,
  };
};

export default useGoogleGroup;
