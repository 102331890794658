import { Box, Skeleton } from "@mui/material";

export const TableSkeleton = () => (
  <>
    <Box display="flex">
      <Box width="88%" pr={2}>
        <Skeleton variant="rectangular" height={40} />
      </Box>
      <Box width="12%">
        <Skeleton variant="rectangular" height={40} />
      </Box>
    </Box>
    <Box pt={2} pb={2}>
      <Skeleton variant="rectangular" width="100%" height={35} />
    </Box>
    <Skeleton variant="rectangular" width="100%" height={250} />
  </>
);
