import { useMemo } from "react";

import { AssetModel, AssetTypeAmazonWebServices, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Container, Grid, InputAdornment, MenuItem, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { sanitizeKeepingLocalDate } from "../../../../../utils/common";
import { useContractFormContext } from "../../ContractsFormContext";
import { type ContractCredits, type VendorContract } from "../../types";
import { Credits } from "../components/Credits";
import { VendorAssetsAutocomplete } from "./components/VendorAssetsAutocomplete";

const commitmentOptionsByCloud = {
  [AssetTypeAmazonWebServices]: ["PPA"],
  [AssetTypeGoogleCloud]: ["B2B", "B2B lite"],
};

export const VendorContractStep = () => {
  const { state, setState } = useContractFormContext();
  const { assets } = useCustomerContext();
  const assetsForTypeGoogleCloud = useMemo(
    () =>
      assets[state.entity] && state.type === AssetTypeGoogleCloud
        ? assets[state.entity].filter((asset) => asset.data.type === AssetTypeGoogleCloud)
        : [],
    [assets, state.entity, state.type]
  );

  const commitmentOptions = useMemo(() => {
    const options: { label: string; value: string }[] = commitmentOptionsByCloud[state.type].map((option) => ({
      label: option,
      value: option,
    }));
    // if vendor contract was created before, deleting it is not allowed
    if (!state.vendorContract?.id) {
      options.unshift({ label: "No vendor commitment", value: "no" });
    }
    return options;
  }, [state.type, state.vendorContract?.id]);

  const handleVendorCommitmentTypeChange = (e) => {
    const vendorContract =
      e.target.value === "no"
        ? ({ credits: {}, errors: {}, commitmentType: null, commitmentPeriods: [] } as any)
        : { ...state.vendorContract, commitmentType: e.target.value, errors: { commitmentType: false } };

    setState((prevState) => ({
      ...prevState,
      vendorContract,
    }));
  };

  const handleAssetsChange = (_event, value) => {
    setState((prevState) => ({
      ...prevState,
      vendorContract: {
        ...(state.vendorContract as VendorContract),
        assets: value.filter((v) => !!v).map((v) => getCollection(AssetModel).doc(v.id)),
        errors: { ...prevState.vendorContract?.errors, assets: false },
      },
    }));
  };

  const handleChangeDate = (name) => (date) => {
    setState((prevState: any) => ({
      ...prevState,
      vendorContract: {
        ...state.vendorContract,
        [name]: date ? sanitizeKeepingLocalDate(date) : null,
        errors: { ...prevState.vendorContract?.errors, [name]: false },
      },
    }));
  };

  const handleDiscountChange = ({ target }) => {
    setState((prevState: any) => {
      let val;
      if (target.value !== "") {
        val = Number(target.value);
        if (val < 0) {
          val = 0;
        } else if (val > 100) {
          val = 100;
        }
      } else {
        val = "";
      }

      return {
        ...prevState,
        vendorContract: {
          ...state.vendorContract,
          discount: val,
          errors: { ...prevState.vendorContract?.errors, discount: false },
        },
      };
    });
  };

  const handleChangeCredits = (credits) => {
    setState((prevState: any) => ({
      ...prevState,
      vendorContract: {
        ...state.vendorContract,
        credits,
        errors: { ...prevState.vendorContract?.errors, credits: false },
      },
    }));
  };

  return (
    <Container maxWidth="sm" data-cy="vendor-contract-step">
      <Grid container item xs={12} mt={2}>
        <TextField
          select
          label="Vendor commitment"
          variant="outlined"
          data-cy="vendor-commitment-type"
          required
          value={state.vendorContract?.commitmentType === null ? "no" : state.vendorContract?.commitmentType}
          error={state.vendorContract?.errors?.commitmentType}
          fullWidth
          onChange={handleVendorCommitmentTypeChange}
          SelectProps={{
            MenuProps: {
              MenuListProps: {
                dense: true,
              },
            },
          }}
        >
          {commitmentOptions.map(({ label, value }) => (
            <MenuItem key={label} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {Boolean(state.vendorContract?.commitmentType) && (
        <Grid container spacing={1} mt={2} data-cy="contract-fields">
          {state.type === AssetTypeGoogleCloud && (
            <Grid item xs={12}>
              <VendorAssetsAutocomplete
                assets={assetsForTypeGoogleCloud}
                value={state.vendorContract?.assets ?? []}
                onChange={handleAssetsChange}
                disabled={state.isEditForbidden}
                label="Billing Accounts"
                helperText="Restrict contract to assets or leave empty to include all assets"
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <DatePicker
              renderInput={(params) => (
                <TextField
                  data-cy="vendor-commitment-period-start-date"
                  required
                  helperText="Select the contract start date"
                  margin="dense"
                  fullWidth
                  {...params}
                  error={state.vendorContract?.errors?.startDate}
                />
              )}
              label="Start Date"
              value={state.vendorContract?.startDate ?? null}
              onChange={handleChangeDate("startDate")}
              components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
              inputFormat="dd LLL, yyyy"
              disabled={state.isEditForbidden}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <DatePicker
              renderInput={(params) => (
                <TextField
                  data-cy="vendor-commitment-period-end-date"
                  required
                  margin="dense"
                  helperText="Select the contract end date (exclusive)"
                  fullWidth
                  {...params}
                  error={state.vendorContract?.errors?.endDate}
                />
              )}
              label="End Date"
              value={state.vendorContract?.endDate ?? null}
              minDate={state.vendorContract?.startDate}
              onChange={handleChangeDate("endDate")}
              components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
              inputFormat="dd LLL, yyyy"
              disabled={state.isEditForbidden}
            />
          </Grid>

          {state.type === AssetTypeAmazonWebServices && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={state.vendorContract?.discount}
                onChange={handleDiscountChange}
                data-cy="discount-textfield"
                label="Discount"
                margin="dense"
                variant="outlined"
                type="number"
                helperText="Enter the contract discount percentage"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={state.isEditForbidden}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Credits
              isEditForbidden={state.isEditForbidden}
              values={state.vendorContract?.credits as ContractCredits}
              vendor={state.type}
              handleChangeCredits={handleChangeCredits}
              type="vendorContract"
              errors={state.vendorContract?.errors.credits}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
