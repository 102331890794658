import {
  type DataColumn,
  type HeaderColumn,
  numberComparators,
  stringComparators,
} from "../../../../types/FilterTable";

const accountNameColumn: DataColumn = {
  label: "Account name",
  path: "accountName",
  comparators: stringComparators,
};
const emailColumn: DataColumn = {
  label: "Account email",
  path: "email",
  comparators: stringComparators,
};

const genuineAwsAccountIdColumn: DataColumn = {
  label: "AWS account ID",
  path: "genuineAwsAccountId",
  comparators: stringComparators,
};

const statusDescriptorColumn: DataColumn = {
  label: "Status",
  path: "statusDescriptor.status",
};

const completionColumn: DataColumn = {
  label: "Completion",
  path: "statusDescriptor.completion",
  comparators: numberComparators,
  toOption: (value) => ({
    label: `${Math.round(value * 100)}%`,
    value,
  }),
};

export const awsGeneratedAccountsColumns = [
  accountNameColumn,
  emailColumn,
  genuineAwsAccountIdColumn,
  statusDescriptorColumn,
  completionColumn,
];

export const accountNameHeader: HeaderColumn = {
  value: accountNameColumn.path,
  label: accountNameColumn.label,
  hidden: { smDown: true },
};

export const emailHeader: HeaderColumn = {
  value: emailColumn.path,
  label: emailColumn.label,
  hidden: { smDown: true },
};

export const genuineAwsAccountIdHeader: HeaderColumn = {
  value: genuineAwsAccountIdColumn.path,
  label: genuineAwsAccountIdColumn.label,
  hidden: { smDown: true },
};

export const statusDescriptorHeader: HeaderColumn = {
  value: statusDescriptorColumn.path,
  label: statusDescriptorColumn.label,
  hidden: { smDown: true },
};

export const actionsHeader: HeaderColumn = {
  value: "",
  label: "",
  style: { align: "right", padding: "none" },
};

export const awsGeneratedAccountsHeaders = [
  accountNameHeader,
  emailHeader,
  genuineAwsAccountIdHeader,
  statusDescriptorHeader,
  actionsHeader,
];
