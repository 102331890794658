import { useEffect, useState } from "react";

import { CustomerModel, type CustomerModelJiraInstancesModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useApiContextOrMockAPIContext } from "../../../api/context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";

export const useJiraApi = () => {
  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  const [jiraInstanceData, setJiraInstanceData] = useState<CustomerModelJiraInstancesModel | null | undefined>(
    undefined
  );

  const createJiraInstance = async (url: string) =>
    api.request({
      method: "POST",
      url: `/v1/customers/${customer.id}/jira/instances`,
      data: {
        url,
        deleteIfExistingPendingInstance: true,
      },
    });

  useEffect(
    () =>
      getCollection(CustomerModel)
        .doc(customer.id)
        .collection("jiraInstances")
        .limit(1)
        .onSnapshot((snapDoc) => {
          if (snapDoc.empty) {
            setJiraInstanceData(null);
            return;
          }

          snapDoc.docs.forEach((doc) => {
            const data = doc.asModelData();
            if (!isPresentationMode) {
              setJiraInstanceData(data);
            } else {
              setJiraInstanceData({
                ...data,
                url: data.url?.replace("fake-instance", originalCustomer.primaryDomain.split(".")[0]),
              });
            }
          });
        }),
    [customer.id, originalCustomer.primaryDomain, isPresentationMode]
  );

  return {
    jiraInstanceData,
    createJiraInstance,
  };
};
