import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { costMode } from "../types";

type Props = {
  chargeMode: costMode;
  changeRadioBtn: (s: string) => void;
  modeSelectionDisabled: boolean;
};

const CostModeRadioSelection = (props: Props) => {
  const theme = useTheme();
  const { chargeMode, changeRadioBtn, modeSelectionDisabled } = props;

  const radioOptions = (
    <RadioGroup
      value={null}
      onChange={(e) => {
        changeRadioBtn(e.target.value);
      }}
    >
      {["percentage", "flatRate"].map((itemKey) => (
        <FormControlLabel
          key={itemKey}
          control={
            <Radio
              color="primary"
              value={costMode[itemKey]}
              checked={chargeMode === costMode[itemKey]}
              id={`aws-contract-support-${itemKey}-radio`}
              disabled={modeSelectionDisabled}
            />
          }
          data-testid={`aws-contract-support-${itemKey}-btn`}
          label={`Charge at a ${itemKey === "flatRate" ? "flat-rate" : "percentage discount"}`}
          sx={{ margin: 0 }}
        />
      ))}
    </RadioGroup>
  );

  return (
    <FormControl
      component="fieldset"
      sx={{ width: "100%", paddingBottom: theme.spacing(2) }}
      data-testid="aws-contract-support-radio-btns"
    >
      <Box pb={1}>
        <Typography variant="subtitle1">Support costs</Typography>
      </Box>
      {radioOptions}
    </FormControl>
  );
};

export default CostModeRadioSelection;
