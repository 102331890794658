import { useState } from "react";

import { Button } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { NewRampPlanDialog } from "../RampList/FilterAndActionsHeader/NewPlanDialog/NewRampPlanDialog";
import { getEligibleContracts } from "../utils";

type RampListProps = {
  takenNamesArr: string[];
};

const ButtonCreateRampPlan = ({ takenNamesArr }: RampListProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { contracts } = useCustomerContext();
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        disabled={getEligibleContracts(contracts).length === 0}
        data-cy="btnCreateRampPlan"
        onClick={() => {
          setOpenDialog(true);
        }}
      >
        Create ramp plan
      </Button>
      <NewRampPlanDialog
        open={openDialog}
        takenNamesArr={takenNamesArr}
        onClose={() => {
          setOpenDialog(false);
        }}
      />
    </>
  );
};

export default ButtonCreateRampPlan;
