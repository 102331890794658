export enum MarketplaceProduct {
  FLEXSAVE = "doit-flexsave",
  COST_ANOMALY_DETECTION = "doit-cloud-cost-anomaly-detection",
  SAAS_CONSOLE = "doit-console",
}

export enum CustomerPageUrl {
  FLEXSAVE = "flexsave-gcp",
  GCP_LENS = "dashboards/GCP Lens",
  DEFAULT = "/",
  SAAS_CONSOLE_AWS = "cloud-onboarding-aws",
  SAAS_CONSOLE_GCP = "cloud-onboarding-gcp",
  DASHBOARDS = "dashboards",
}
