import { type Cluster, ClusterConnectionStatus, ClusterDiscoveryStatus, ClusterType } from "@doitintl/cmp-models";

import { useEKSClusters } from "../EKS/hooks";
import { type ClusterConfig } from "../EKS/types";

function eksClusterToUnifiedModel(eksCluster: ClusterConfig): Cluster {
  return {
    type: ClusterType.EKS,
    name: eksCluster.clusterName,
    projectId: eksCluster.payerAccountId,
    region: eksCluster.region,
    connectionStatus: ClusterConnectionStatus.UNKNOWN,
    connectionStatusUpdated: "N/A",
    cloudConnectRef: "N/A",
    caCertBase64: "N/A",
    host: "N/A",
    customerRef: eksCluster.customerId,
    discoveryStatus: ClusterDiscoveryStatus.NONE,
    discoveryStatusUpdated: "N/A",
    kubernetesControlPlaneVersion: "Unknown",
    serviceAccountSecretId: "N/A",
  };
}

export function useClusters() {
  const [eksClusters = []] = useEKSClusters();

  // TODO: add the remaining types once they are in firestore

  return [...eksClusters.map(eksClusterToUnifiedModel)];
}
