import { useEffect, useMemo, useState } from "react";

import { type FieldInputProps, getIn, useFormikContext } from "formik";

export type FieldCommonProps = ReturnType<typeof useFieldCommonProps>;

export function useFieldCommonProps({ value, name }: FieldInputProps<any>, label: string, required: boolean) {
  const formikProps = useFormikContext();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const isTouched = getIn(formikProps.touched, name) === true;
    const errorMessage = getIn(formikProps.errors, name);
    setErrorMessage(isTouched && typeof errorMessage === "string" ? errorMessage : undefined);
    setShowError(errorMessage !== undefined && isTouched);
  }, [name, formikProps.touched, formikProps.errors, errorMessage]);

  return useMemo(
    () => ({
      value,
      label,
      name,
      onChange: formikProps.handleChange,
      onBlur: formikProps.handleBlur,
      required,
      helperText: errorMessage,
      error: showError,
      autoComplete: "off",
    }),
    [errorMessage, formikProps.handleBlur, formikProps.handleChange, label, name, required, showError, value]
  );
}
