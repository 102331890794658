import { useMemo } from "react";

import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { Card, CardHeader, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { ColumnsChart } from "../../../../../Components/Charts/ColumnsChat/ColumnsChart";
import { useCurrency } from "../../../../../Components/hooks/useCurrency";
import { type FlexsaveData } from "../../../types";
import { DetailedReportButton } from "./DetailedReportButton";
import { generateOptionsForChart } from "./SavingsChartUtils";

type Props = {
  flexsaveData: FlexsaveData;
  savingType?: FlexsaveType;
};

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    borderBottom: `1px solid ${theme.palette.general.divider}`,
  },
}));

export const SavingsChart = ({ flexsaveData, savingType }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { customerCurrencySymbol } = useCurrency();

  const options = useMemo(() => {
    if (!flexsaveData) {
      return null;
    }

    return generateOptionsForChart(flexsaveData, theme, customerCurrencySymbol);
  }, [flexsaveData, theme, customerCurrencySymbol]);

  if (!options) {
    return null;
  }

  return (
    <Card>
      <Stack direction="row" alignItems="center" justifyContent="space-between" className={classes.cardHeader}>
        <CardHeader
          titleTypographyProps={{ variant: "h4" }}
          title="Flexsave summary"
          subheader="The data in this graph is subject to a delay of between 1-32 days"
          subheaderTypographyProps={{ variant: "body2" }}
        />
        {savingType === FlexsaveTypes.GCP && <DetailedReportButton />}
      </Stack>
      <ColumnsChart options={options} />
    </Card>
  );
};
