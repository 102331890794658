import { useCallback, useEffect, useState } from "react";

import { type InvoiceNotificationSettings, UserNotification } from "@doitintl/cmp-models";
import { useField } from "formik";

import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type NotificationWithGroupDescriptor } from "../useNotificationDescriptors";
import { EntitiesSelectMulti } from "./EntitiesSelectMulti";

type invoiceNotificationType =
  | UserNotification.NewInvoice
  | UserNotification.PaymentOverdue
  | UserNotification.NewProformaInvoice;
const invoiceNotifications = [
  UserNotification.PaymentOverdue,
  UserNotification.NewInvoice,
  UserNotification.NewProformaInvoice,
] as const;

export function isInvoiceNotification(
  notification: NotificationWithGroupDescriptor
): notification is NotificationWithGroupDescriptor<invoiceNotificationType> {
  return (invoiceNotifications as readonly UserNotification[]).includes(notification.value);
}

export function NotificationEntitiesFilter({
  notification,
  isOfBillingProfile,
}: {
  notification: NotificationWithGroupDescriptor<
    UserNotification.NewInvoice | UserNotification.PaymentOverdue | UserNotification.NewProformaInvoice
  >;
  isOfBillingProfile: boolean;
}) {
  const [selectedScope, setSelectedScope] = useState<Entity[]>([]);
  const { entities: customerEntities } = useCustomerContext();

  const [{ value: entitiesFormValue }, { error }, { setValue: setEntitiesFormValue }] = useField<
    InvoiceNotificationSettings["entities"]
  >({
    name: `${notification.value}.entities`,
  });

  const [{ value: notificationSettingsFormValue }] = useField<InvoiceNotificationSettings | undefined>({
    name: notification.value.toString(),
  });

  useEffect(() => {
    const selectedEntities = customerEntities.filter((customerEntity) =>
      entitiesFormValue?.find((elemRef) => elemRef?.id === customerEntity.id)
    );
    setSelectedScope(selectedEntities);
  }, [entitiesFormValue, customerEntities, notification.value]);

  const onChange = useCallback(
    (entities: Entity[]) => setEntitiesFormValue(entities.map((entity) => entity.ref)),
    [setEntitiesFormValue]
  );

  const helperText = isOfBillingProfile
    ? "Can't update notifications related to billing profiles"
    : "Leave blank to receive notifications for all your billing profiles";

  return (
    <EntitiesSelectMulti
      disabled={isOfBillingProfile || !notificationSettingsFormValue}
      entities={customerEntities}
      scope={selectedScope}
      onChange={onChange}
      textFieldProps={{
        helperText: error || helperText,
        error: !!error,
        required: false,
      }}
    />
  );
}
