import { countryToFlagEmoji, timezoneToCountry } from "../utils/helpers";
import { type AttributeOptions, type Item } from "./RefinementListState";

export const getPlaceholderText = (attribute: AttributeOptions): string => {
  switch (attribute) {
    case "languages":
      return "Search languages...";
    case "timezone":
      return "Search locations...";
    case "all_skills":
      return "Search skills...";
    default:
      return "Search...";
  }
};

export const sortItemsByCount = (items: Item[]): Item[] => [...items].sort((a, b) => b.count - a.count);

export const calculateVisibleItems = (
  items: Item[],
  showEmojis: boolean,
  formatLabel: (value: string, showEmojis: boolean) => string
): {
  visibleItems: Item[];
  overflowItems: Item[];
  showEmojis: boolean;
  selectedOverflowCount: number;
} => {
  const containerWidth = 300;
  let accumulatedWidth = 0;
  const tempVisibleItems: Item[] = [];
  const tempOverflowItems: Item[] = [];

  const tempDiv = document.createElement("div");
  tempDiv.style.position = "absolute";
  tempDiv.style.visibility = "hidden";
  tempDiv.style.whiteSpace = "nowrap";
  tempDiv.style.fontSize = "14px";
  document.body.appendChild(tempDiv);

  const sortedItems = sortItemsByCount(items);
  const refinedItems = sortedItems.filter((item) => item.isRefined);

  refinedItems.forEach((item) => {
    tempDiv.textContent = formatLabel(item.value, showEmojis);
    const itemWidth = tempDiv.offsetWidth + 16;
    if (accumulatedWidth + itemWidth <= containerWidth) {
      tempVisibleItems.push(item);
      accumulatedWidth += itemWidth;
    } else {
      tempOverflowItems.push(item);
    }
  });

  sortedItems
    .filter((item) => !item.isRefined)
    .forEach((item) => {
      tempOverflowItems.push(item);
    });

  document.body.removeChild(tempDiv);

  return {
    visibleItems: tempVisibleItems,
    overflowItems: tempOverflowItems,
    showEmojis: accumulatedWidth <= containerWidth,
    selectedOverflowCount: tempOverflowItems.filter((item) => item.isRefined).length,
  };
};

export const formatLabel = (value: string, showEmojis: boolean, attribute: AttributeOptions): string => {
  const isLanguageAttribute = attribute === "languages";
  if (isLanguageAttribute) {
    return value;
  }
  const isTimezoneAttribute = attribute === "timezone";
  if (isTimezoneAttribute) {
    const country = timezoneToCountry[value] || value;
    const flag = showEmojis ? countryToFlagEmoji[country] || "🌍" : "";
    const parts = value.split("/");
    const stateOrCity = parts.length > 1 ? parts[1].replace(/_/g, " ") : "";
    if (country === "United States" || country === "Australia" || country === "Canada") {
      return `${flag} ${country} - ${stateOrCity}`.trim();
    } else {
      return `${flag} ${country}`.trim();
    }
  }
  return value;
};

export const getPlainTextLabel = (value: string, attribute: string): string => {
  if (attribute === "languages") {
    return value;
  }
  if (attribute === "timezone") {
    return timezoneToCountry[value] || value;
  }
  return value;
};
