import { Component } from "react";
// Recompose
import { compose } from "recompose";

import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import LoadingButton from "../../Components/LoadingButton";
import { withFullScreen } from "../../utils/dialog";

const styles = () => ({
  root: {},
});

class ConfirmDialog extends Component {
  render() {
    const { fullScreen, open, title, text, acceptTitle, cancelTitle, onAccept, onCancel } = this.props;
    const includeCancelBtn = cancelTitle && onCancel;
    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">{text}</DialogContentText>
        </DialogContent>

        <Divider />

        <DialogActions style={{ display: "flex", justifyContent: includeCancelBtn ? "space-between" : "" }}>
          {includeCancelBtn && (
            <Button onClick={onCancel} color="primary">
              {cancelTitle}
            </Button>
          )}

          <LoadingButton
            loading={this.props.loading}
            disabled={this.props.loading}
            onClick={onAccept}
            variant="contained"
            color="primary"
            mixpanelEventId={`support.confirm.dialog.${acceptTitle}`}
          >
            {acceptTitle}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(withFullScreen, withStyles(styles))(ConfirmDialog);
