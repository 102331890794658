import { type ReactNode, useEffect, useState } from "react";

import { useAwsAccountsSubscription } from "./aws-account-api";
import { AwsAccountContext, INITIAL_AWS_ACCOUNT_CONTEXT } from "./AwsAccountContext";

export const AwsAccountContextProvider = ({ children }: { children: ReactNode }) => {
  const [context, setContext] = useState(INITIAL_AWS_ACCOUNT_CONTEXT);
  const awsAccounts = useAwsAccountsSubscription();

  useEffect(() => {
    setContext((previousContext) => ({ ...previousContext, awsAccounts }));
  }, [awsAccounts]);

  return <AwsAccountContext.Provider value={context}>{children}</AwsAccountContext.Provider>;
};
