import { TierPackageTypes } from "@doitintl/cmp-models";

import { useTier } from "../../Context/TierProvider";
import { isDCIEssentialsTier } from "../../Pages/Settings/General/utils";

export const useIsDCIEssentialsTier = () => {
  const { getCustomerTier } = useTier();
  const navigatorTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  return isDCIEssentialsTier(navigatorTier);
};
