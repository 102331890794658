import { useCallback, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

import { useApiContext } from "../api/context";
import { type Entity } from "../Context/customer/EntitiesContext";
import { preventOnCloseWhile } from "../utils/dialog";
import LoadingButton from "./LoadingButton";

type Props = {
  onClose: () => void;
  onRequestRefresh: () => void;
  entity: Entity;
  newStatus: "Inactive" | "Active";
};

const EntityStatusDialog = ({ onClose, entity, onRequestRefresh, newStatus }: Props) => {
  const [loading, setLoading] = useState(false);
  const api = useApiContext();
  const status = entity.active ? "Deactivate" : "Activate";

  const handleUpdateStatus = useCallback(async () => {
    if (!entity.customer?.id) {
      return;
    }
    setLoading(true);
    try {
      const response = await api.request({
        method: "patch",
        url: `/priority/customers/${entity.customer.id}/entities/${entity.id}/status`,
        data: {
          STATDES: newStatus,
        },
      });
      if (response.status === 200) {
        onRequestRefresh();
        onClose();
      }
    } finally {
      setLoading(false);
    }
  }, [api, entity.customer?.id, entity.id, newStatus, onClose, onRequestRefresh]);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      data-cy={`entityStatusDialog-${entity.active ? "DEACTIVATE" : "ACTIVATE"}`}
    >
      <DialogTitle id="form-dialog-title">
        Are you sure you want to {status.toLocaleLowerCase()} this entity?
      </DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <DialogContentText id="alert-dialog-description">
          This action will {status.toLocaleLowerCase()} the customer in Priority.
        </DialogContentText>
        <DialogContentText>Are you sure you want to proceed?</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color={entity.active ? "error" : "primary"}
          variant="contained"
          onClick={handleUpdateStatus}
          disabled={loading}
          loading={loading}
          mixpanelEventId="entity.status"
        >
          {status}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EntityStatusDialog;
