import { useCallback } from "react";

import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";

export const usePortfolioShare = (): {
  shareAWSPortfolio: (accountNumber: string) => Promise<AxiosResponse>;
} => {
  const api = useApiContext();

  const shareAWSPortfolio = useCallback(
    async (accountNumber: string): Promise<AxiosResponse> =>
      api.request<AxiosResponse>({
        method: "post",
        url: `v1/amazon-web-services/master-payer-accounts/create-portfolio-share/${accountNumber}`,
      }),
    [api]
  );

  return { shareAWSPortfolio };
};

export default usePortfolioShare;
