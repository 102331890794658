import { useMemo } from "react";

import { arrayMove } from "@dnd-kit/sortable";
import { type Widget } from "@doitintl/cmp-models";

import { type HoverItem } from "./Grid/DraggableWidgetsGrid";
import { extractNumbers, getRowIdByItemId, normalizeRowWidths } from "./widgetUtils";

export type WidgetWidth = 1 | 1.5 | 2 | 3;

export type WidgetItem = Omit<Widget, "state" | "cardWidth"> & { width: WidgetWidth };

export type WidgetItemWithPosition = WidgetItem & {
  absoluteIndex: number;
};

export type GridRow = {
  items: WidgetItemWithPosition[];
};

export type DropLocation = {
  rowIndex: number;
  itemIndex: number;
  newRow?: boolean;
};

export function splitItemsToRows(items: WidgetItem[]): GridRow[] {
  const rows: GridRow[] = [];
  let currentRow: WidgetItemWithPosition[] = [];
  let currentRowWidth = 0;

  const itemsWithOrder = items.map((item, index) => ({ ...item, absoluteIndex: index }));

  itemsWithOrder.forEach((item) => {
    currentRow.push(item);
    currentRowWidth += item.width;

    if (currentRowWidth >= 3) {
      rows.push({
        items: currentRow,
      });
      currentRow = [];
      currentRowWidth = 0;
    }
  });

  // Add the last row if it's not empty
  if (currentRow.length > 0) {
    rows.push({
      items: currentRow,
    });
  }

  return rows;
}

export function useWidgetItemsWithRows(
  allItems: WidgetItem[]
): [GridRow[], (...any) => DropLocation | undefined, (...any) => Widget[]] {
  const rows = useMemo(() => splitItemsToRows(allItems), [allItems]);

  function getNewOrder(dragItemId: string, dropLocation: DropLocation): Widget[] {
    let newRows = structuredClone(rows);

    const [dragRowIndex, dragItemIndex] = getRowIdByItemId(rows, dragItemId);
    const dragItem = newRows[dragRowIndex].items[dragItemIndex];

    if (dropLocation.newRow) {
      if (rows[dragRowIndex].items.length === 1) {
        // replace the rows with the single item
        newRows = arrayMove(newRows, dragRowIndex, dropLocation.rowIndex);
      } else {
        // remove from original location
        newRows[dragRowIndex].items.splice(dragItemIndex, 1);

        newRows.splice(dropLocation.rowIndex, 0, {
          items: [dragItem],
        });
      }
    } else {
      if (dragRowIndex === dropLocation.rowIndex) {
        if (dragItemIndex >= dropLocation.itemIndex) {
          newRows[dragRowIndex].items = arrayMove(newRows[dragRowIndex].items, dragItemIndex, dropLocation.itemIndex);
        } else {
          newRows[dragRowIndex].items = arrayMove(
            newRows[dragRowIndex].items,
            dragItemIndex,
            dropLocation.itemIndex - 1
          );
        }
      } else {
        const dragItem = newRows[dragRowIndex].items[dragItemIndex];

        // remove from original location
        newRows[dragRowIndex].items.splice(dragItemIndex, 1);

        // add to new position
        newRows[dropLocation.rowIndex].items.splice(dropLocation.itemIndex, 0, dragItem);

        // remove empty row
        if (newRows[dragRowIndex].items.length === 0) {
          newRows.splice(dragRowIndex, 1);
        }
      }
    }

    newRows.forEach(normalizeRowWidths);
    return newRows.flatMap((row) => row.items);
  }

  function getDropLocation(
    dragItemId: string,
    hoverItemId: string,
    position?: HoverItem["position"]
  ): DropLocation | undefined {
    if (hoverItemId.includes("vertical")) {
      const [dragRowIndex] = getRowIdByItemId(rows, dragItemId);
      const [rowIndex, indexInRow] = extractNumbers(hoverItemId);

      // prevent dragging into a new row with 3 items
      if (dragRowIndex !== rowIndex && rows[rowIndex].items.length === 3) {
        return;
      }

      return {
        rowIndex,
        itemIndex: indexInRow + 1,
      };
    } else if (hoverItemId.includes("horizontal")) {
      const [rowIndex] = extractNumbers(hoverItemId);

      return {
        rowIndex,
        itemIndex: 0,
        newRow: true,
      };
    } else {
      // info about the hover row
      const [hoverRowIndex, hoverItemIndex] = getRowIdByItemId(rows, hoverItemId);
      const [dragRowIndex] = getRowIdByItemId(rows, dragItemId);

      // prevent dragging into new row with 3 items
      if (hoverRowIndex !== dragRowIndex && rows[hoverRowIndex].items.length === 3) {
        return;
      }

      return {
        rowIndex: hoverRowIndex,
        itemIndex: position === "start" ? hoverItemIndex : hoverItemIndex + 1,
      };
    }
  }

  return [rows, getDropLocation, getNewOrder];
}
