import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { type SlackChannel } from "@doitintl/cmp-models";

import { useSlackChannelsApi } from "../Components/Slack/ChannelSelection/useSlackChannelApi";
import { type Customer } from "../types";

export type CustomerSlackChannelsContextType = {
  getSlackChannels: (forceRefresh?: boolean) => Promise<SlackChannel[]>;
  slackChannels: SlackChannel[];
};

const customerSlackChannelsContext = createContext<CustomerSlackChannelsContextType>({
  getSlackChannels: async () => [],
  slackChannels: [],
});

export const CustomerSlackChannelsProvider = ({
  customer,
  children,
}: {
  customer: Customer | null | undefined;
  children: ReactNode;
}) => {
  const { getCustomerSlackChannels } = useSlackChannelsApi(customer?.id || "");
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const [invoked, setInvoked] = useState(false);

  const getSlackChannels = useCallback(
    async (forceRefresh = false) => {
      if (!customer?.id) {
        return [];
      }
      if (forceRefresh || !invoked) {
        const channels = await getCustomerSlackChannels();
        setInvoked(true);
        setSlackChannels(channels);
        return channels;
      }
      return slackChannels;
    },
    [slackChannels, getCustomerSlackChannels, invoked, customer?.id]
  );

  useEffect(() => {
    // reset slack channels when customer changes
    if (customer?.id) {
      setSlackChannels([]);
      setInvoked(false);
    }
  }, [customer?.id]);

  const value = useMemo(() => ({ getSlackChannels, slackChannels }), [getSlackChannels, slackChannels]);

  return <customerSlackChannelsContext.Provider value={value}>{children}</customerSlackChannelsContext.Provider>;
};

export const useCustomerSlackChannelsContext = (): CustomerSlackChannelsContextType =>
  useContext(customerSlackChannelsContext);
