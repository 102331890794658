import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type ClusterConfig } from "../types";

const itemsDescriptions: FilterTableItemDescriptor<ClusterConfig>[] = [
  {
    headerText: "Cluster",
    value: "clusterName",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Account",
    value: "accountId",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterType: "string",
  },
  {
    value: "@",
    headerText: "",
  },
  {
    value: "@",
    headerText: "",
    headerStyle: {
      width: "15px",
      align: "right",
    },
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
