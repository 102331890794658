import { defaultHeaderStyle } from "../../../Components/FilterTable/Table/FilterTableHeader";
import { threeDotHeaderSection } from "../../Assets/assetUtils";
import type { DataColumns, HeaderColumns } from "../../../types/FilterTable";
import type { TeamsChannelRow } from "./MsTeamsTableRow";

export const teamsChannelsHeaderColumns: HeaderColumns<TeamsChannelRow> = [
  {
    label: "Team",
    value: "team",
    style: defaultHeaderStyle,
  },
  {
    label: "Channel",
    value: "channel",
    style: defaultHeaderStyle,
  },
  {
    label: "Date connected",
    value: "dateConnected",
    style: defaultHeaderStyle,
  },
  {
    label: "Test",
    value: "@",
    style: defaultHeaderStyle,
  },
  threeDotHeaderSection,
];

/**
 * There is no filter in use here, so unless that changes this array remains empty and used only to satisfy table props.
 */
export const teamsChannelsFilterColumns: DataColumns<TeamsChannelRow> = [];
