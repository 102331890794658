import {
  Feature,
  Metadata,
  Renderer,
  TimeInterval,
  type TimeSettingsConfig,
  TimeSettingsMode,
} from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { type createReportParams } from "../../../Pages/CloudAnalytics/generateReport/types";
import { FixedFilters } from "../../../Pages/CloudAnalytics/utilities";
import { CardID } from ".";

function getForecastLinkReport(): createReportParams {
  const startTime = DateTime.utc().startOf("day").minus({ days: 2 });
  const timeInterval = TimeInterval.MONTH;
  const customTimeRange = {
    to: startTime,
    from: startTime.minus({ months: 12 }).startOf("month"),
  };

  const timeSettings: TimeSettingsConfig = {
    mode: TimeSettingsMode.Fixed,
    unit: timeInterval,
    includeCurrent: false,
    amount: 0,
  };

  return {
    name: `Forecast ${startTime.toFormat("dd LLLL yyyy hh:mm")}`,
    description: "",
    config: {
      fields: [
        {
          type: Metadata.FIXED,
          id: FixedFilters.CLOUD,
          groupBy: true,
        },
      ],
      timeInterval,
      customTimeRange,
      features: [Feature.FORECAST],
      renderer: Renderer.STACKED_COLUMN_CHART,
      timeSettings,
    },
  };
}

function getMonthSpendLinkReport(): createReportParams {
  const startTime = DateTime.utc().startOf("day").minus({ days: 2 });
  const timeInterval = TimeInterval.DAY;

  const customTimeRange = {
    to: startTime,
    from: startTime.minus({ days: 30 }).startOf("day"),
  };

  const timeSettings: TimeSettingsConfig = {
    mode: TimeSettingsMode.Fixed,
    unit: timeInterval,
    amount: 0,
    includeCurrent: false,
  };

  return {
    name: `This month spend at ${startTime.toFormat("dd LLLL yyyy hh:mm")}`,
    description: "",
    config: {
      fields: [
        {
          type: Metadata.FIXED,
          id: FixedFilters.SERVICE,
          groupBy: true,
          limit: 20,
        },
      ],
      timeInterval,
      customTimeRange,
      renderer: Renderer.STACKED_COLUMN_CHART,
      timeSettings,
    },
  };
}

/**
 * Get report link by card ID
 * @param generateReport
 * @param customerId
 * @param cardId - CardID type
 */
export const getCloudSummaryLink = async (
  generateReport: (requestReport: createReportParams, payloadReady: boolean) => string | Promise<string>,
  customerId: string | undefined,
  cardId: CardID
): Promise<string> => {
  let payload: createReportParams;
  switch (cardId) {
    case CardID.Forecast:
      payload = getForecastLinkReport();
      break;
    case CardID.MonthSpend:
      payload = getMonthSpendLinkReport();
      break;
    case CardID.Anomaly:
      return `/customers/${customerId}/anomaly`;

    default:
      throw new Error("Card ID not found");
  }

  const newReportId = await generateReport(payload, false);
  return `/customers/${customerId}/analytics/reports/${newReportId}`;
};
