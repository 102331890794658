import { useMemo } from "react";

import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

export const useEmptyReportWidgetCustomMessages = () => {
  const [data] = useCollectionData(getCollection(DashboardModel).doc("widgets").collection("widgetsEmptyState"), {
    caching: true,
    idField: "id",
  });

  const emptyReportWidgetSettingsMap = useMemo(() => {
    if (!data) {
      return {};
    }

    return data.reduce((acc, data) => {
      acc[data.id] = data;
      return acc;
    }, {});
  }, [data]);

  return { emptyReportWidgetSettingsMap };
};
