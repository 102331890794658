import { type ThreadModel } from "@doitintl/cmp-models";

import { type JiraField, type JiraIssue, type JiraIssueType, type JiraProject } from "./types";

const mockIssueTypes: JiraIssueType[] = [
  { id: "0", name: "Bug" },
  { id: "1", name: "Story" },
  { id: "2", name: "Task" },
];

const mockFields: JiraField[] = [];

const mockProjects: JiraProject[] = [
  {
    id: "1001",
    name: "ENG",
    key: "Platform Engineering",
  },
  {
    id: "1002",
    name: "FINOPS",
    key: "Financial Operations",
  },
];

interface JiraConfig {
  summary: string;
  description: string;
  assignee: string;
  priority: string;
  status: string;
  statusCategory: string;
  project: string;
  issueType: string;
  dueDateOffsetDays: number;
  createdAtOffsetDays: number;
}

const insightConfigs: Record<string, JiraConfig[]> = {
  "gce-balanced-ssds": [
    {
      summary: "Setup and Snapshot for SSD Migration",
      description:
        "Stop the instances to ensure data consistency, snapshot the existing disks, and prepare for migration to Balanced GCE SSDs.",
      assignee: "James Maddison",
      priority: "High",
      status: "In Progress",
      statusCategory: "In Progress",
      project: "Engineering",
      issueType: "Task",
      dueDateOffsetDays: 3,
      createdAtOffsetDays: 3,
    },
    {
      summary: "Migrate and Validate Balanced SSD",
      description: `Create new disks from snapshots, replace the standard SSD with the balanced SSD, boot the instances, and perform initial system validation.`,
      assignee: "Mikey Moore",
      priority: "High",
      status: "To Do",
      statusCategory: "To Do",
      project: "Engineering",
      issueType: "Task",
      dueDateOffsetDays: 10,
      createdAtOffsetDays: 3,
    },
  ],
};

function createDefaultConfig(insightKey: string): JiraConfig {
  return {
    summary: insightKey
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
    description: "Default description pending further details.",
    assignee: "Unassigned",
    priority: "Medium",
    status: "To Do",
    statusCategory: "To Do",
    project: "FinOps",
    issueType: "Task",
    dueDateOffsetDays: 7,
    createdAtOffsetDays: 0,
  };
}

export function mockJiraIssue(issueID: string, threads: ThreadModel[]): JiraIssue | null {
  const thread = threads.filter((t) => t.targetData && t.targetData.issueKey === issueID)?.[0];

  if (!thread) {
    return null;
  }

  const insightIssueMap: Record<string, string[]> = {};

  for (const thread of threads) {
    const { insightKey } = thread.sourceData;
    const { issueKey } = thread.targetData;
    insightIssueMap[insightKey] = [...(insightIssueMap[insightKey] || []), issueKey].sort();
  }

  const insightKey = thread.sourceData.insightKey;
  const config = insightConfigs[insightKey]
    ? insightConfigs[insightKey][insightIssueMap[insightKey].indexOf(thread.targetData.issueKey)]
    : createDefaultConfig(insightKey);

  return {
    summary: config.summary,
    description: config.description,
    status: config.status,
    statusCategory: config.statusCategory,
    assignee: config.assignee,
    priority: config.priority,
    project: config.project,
    issueType: config.issueType,
    dueDate: new Date(new Date().getTime() + config.dueDateOffsetDays * 24 * 60 * 60 * 1000).toISOString(),
    createdDate: new Date(new Date().getTime() - config.createdAtOffsetDays * 24 * 60 * 60 * 1000).toISOString(),
    reporter: "",
    updatedDate: "",
    updatedName: "",
  };
}

export function getIssueTypes(): { data: JiraIssueType[] } {
  return { data: mockIssueTypes };
}

export function getFields(): { data: JiraField[] } {
  return { data: mockFields };
}

export function getIssueByID(url: string, threads: ThreadModel[]): { data: JiraIssue | null } {
  const params = new URLSearchParams(new URL(url, "https://example.com").search);
  const issueID = params.get("issueID");
  return { data: mockJiraIssue(issueID || "", threads) };
}

export function getProjects(): { data: JiraProject[] } {
  return { data: mockProjects };
}

export function getMockApi(threads: ThreadModel[]) {
  const endpointHandlers = {
    "jira/projects/issueTypes": getIssueTypes,
    "jira/projects/fields": getFields,
    "threads/jira/issue": (url) => getIssueByID(url, threads),
    "jira/projects": getProjects,
  };

  return {
    request: async (_: any) => ({ status: 200, data: "Resource created" }),
    get: async (url: string, ..._) => {
      const urlPath = new URL(url, "https://example.com").pathname;
      for (const path in endpointHandlers) {
        if (urlPath.includes(path)) {
          return endpointHandlers[path](url);
        }
      }

      return { data: null };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    post: async (url: string, payload: any) => ({ status: 200, data: "Resource created" }),
  };
}
