import { type EKSMetrics, type EKSMetricsDeployments } from "@doitintl/cmp-models";
import { type ModelId, type ModelReference } from "@doitintl/models-firestore";

import { CONFIGURATION_STEP, CONNECTION_STEP, DEPLOYMENT_STEP, PERMISSION_STEP } from "./Steps/consts";

export type ClusterInfo = {
  clusterName: string;
  region: string;
  accountId: string;
  isManual?: boolean;
  isEc2Cluster?: boolean;
};

type ClusterConfigConvertedFields =
  | "region"
  | "bucketName"
  | "payerAccountId"
  | "awsSplitEnabled"
  | "bucketRegion"
  | "desiredDeploymentId"
  | "deployedAt"
  | "bucketLastMetricsData";

export type ClusterConfig = Omit<ModelId<EKSMetrics>, ClusterConfigConvertedFields> &
  ClusterInfo & {
    // switch to required
    awsSplitEnabled?: boolean;
    bucketName: string;
    payerAccountId: string;
    bucketRegion: string;
    desiredDeploymentId: string;

    // convert from model timestamp to date
    deployedAt?: Date;
    bucketLastMetricsData?: Date;

    // those fields are not in the model
    status: Status;
    clusterNameWithoutDots: string;
    deploymentFileName: string;
    clusterConfigRef: ModelReference<EKSMetrics>;
    deploymentRef: ModelReference<EKSMetricsDeployments>;
  };

export type Status = "active" | "in-progress" | "not-connected" | "deleted" | "pending-update";

export type BucketStatus = "unknown" | "stopped" | "running" | undefined;

export type OnboardingStep =
  | typeof CONNECTION_STEP
  | typeof PERMISSION_STEP
  | typeof DEPLOYMENT_STEP
  | typeof CONFIGURATION_STEP;

export type ClusterType = "eks" | "ec2";

export type CloudFormationDeploymentMethod = "kubectl" | "helm";

export type DeploymentMethod = "terraform" | "cloudFormation";

export const DeploymentMethodTerraform: DeploymentMethod = "terraform";
export const DeploymentMethodCloudFormation: DeploymentMethod = "cloudFormation";

export const onboardingStepLabels: Record<OnboardingStep, string> = {
  [CONNECTION_STEP]: "Connect and Validate",
  [PERMISSION_STEP]: "Permission",
  [DEPLOYMENT_STEP]: "Method",
  [CONFIGURATION_STEP]: "Configure and Apply",
};
