import { useEffect, useState } from "react";

import { UserModel } from "@doitintl/cmp-models/src";
import { getCollection } from "@doitintl/models-firestore/src";
import { Box, Stack, Typography } from "@mui/material";

import DoitLogo from "../../../assets/doit-logo-hero-square.svg";
import HtmlTooltip from "../../../Components/Tooltip/HtmlTooltip";
import type { Insight } from "../types";

type Props = {
  insight: Insight;
};

const getPublishingUser = async (email: string): Promise<UserModel | null> => {
  const userQuery = getCollection(UserModel).where("email", "==", email.trim().toLowerCase());

  const user = (await userQuery.get()).docs?.[0]?.asModelData();
  if (user) {
    return user as UserModel;
  }

  return null;
};

const CustomInsightSourceLabel = ({ insight }: Props) => {
  const publishingUserEmail = insight.customInsightAttributes?.publishingUserEmail ?? "";

  const [user, setUser] = useState<UserModel | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getPublishingUser(publishingUserEmail);
      setUser(user);
    };

    if (publishingUserEmail) {
      fetchUser();
    }
  }, [publishingUserEmail]);

  return (
    <>
      <Typography
        sx={{
          mt: 1.5,
          fontSize: "14px",
          fontWeight: "500",
        }}
        color="text.secondary"
        variant="body1"
      >
        Recommended to you by{" "}
        {insight.customInsightAttributes?.publishingUserEmail &&
        user &&
        insight.customInsightAttributes?.showPublishingUser
          ? user.displayName
          : "DoiT experts"}
      </Typography>
    </>
  );
};

const AutomatedInsightSourceLabel = () => (
  <Box sx={{ display: "flex", alignItems: "center", mt: 1.5 }}>
    <Typography sx={{ fontSize: "14px", fontWeight: "500" }} color="text.secondary" variant="body1">
      Monitored by
    </Typography>
    <HtmlTooltip
      title={
        <Box p={1.5}>
          <Typography mb={2} sx={{ fontSize: "14px" }}>
            DoiT Cloud Intelligence™ checks every day for any potential savings associated with this insight based on
            your recent billing data, excluding the last three days.
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            If no potential savings are found, this insight is automatically marked as optimized.
          </Typography>
        </Box>
      }
      placement="right"
    >
      <Typography
        sx={{
          ml: 0.5,
          fontSize: "14px",
          fontWeight: "500",
          borderBottom: "1px dotted",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          lineHeight: 1.25,
        }}
        color="text.secondary"
        variant="body1"
      >
        DoiT Cloud Intelligence™
      </Typography>
    </HtmlTooltip>
  </Box>
);

export const InsightSourceLabel = ({ insight }: Props) => (
  <Stack direction="row" spacing={0.5} alignItems="center" mb={1}>
    <Box component="img" src={DoitLogo} sx={{ height: "20px", width: "20px" }} />
    {insight.providerId === "custom" ? <CustomInsightSourceLabel insight={insight} /> : <AutomatedInsightSourceLabel />}
  </Stack>
);
