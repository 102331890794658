import { type JSX, useCallback } from "react";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { type WorkloadDetailsItem } from "../types";
import { pendingWorkloadsFilters, pendingWorkloadsHeaderColumns } from "./columns";
import { PendingWorkloadDetailsRow } from "./PendingWorkloadDetailsRow";

type Props = {
  workloadItems?: WorkloadDetailsItem[];
  onEditClick: (workloadDetailsItem: WorkloadDetailsItem) => void;
  onWorkloadSelect: (workloadDetailsItems: WorkloadDetailsItem[]) => void;
  purchaseButton: JSX.Element;
  title: JSX.Element;
};

export const PendingWorkloadsDetailsTable = ({
  workloadItems,
  onEditClick,
  onWorkloadSelect,
  purchaseButton,
  title,
}: Props) => {
  const PendingWorkloadsRowWrapper = useCallback(
    (props: { row: WorkloadDetailsItem }) => <PendingWorkloadDetailsRow row={props.row} onEditClick={onEditClick} />,
    [onEditClick]
  );

  return (
    <>
      <FilterTable<WorkloadDetailsItem>
        tableItems={workloadItems}
        rowComponent={PendingWorkloadsRowWrapper}
        headerColumns={pendingWorkloadsHeaderColumns}
        filterColumns={pendingWorkloadsFilters}
        persistenceKey="flexsave_opts_plans_details"
        onRowsSelected={onWorkloadSelect}
        showRowsSelection={true}
        toolbarProps={{ customSlot: purchaseButton, allowToEditColumns: true, title }}
      />
    </>
  );
};
