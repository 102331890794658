import { DateTime } from "luxon";

import { type AsgActualAndPotentialCosts, type AsgInstancesUsage, type MonthlyAsgUsage } from "../types";

export const usageKeyFormat = "yyyy_M";

/**
 * Get the usage key for the given month index going back from the given date
 */
export function getUsageKeyByIndex(startDate: DateTime, index: number) {
  return startDate.minus({ months: index }).toFormat(usageKeyFormat);
}

const asyncConvertMonthUsage = async (
  month: MonthlyAsgUsage,
  monthHistory: string,
  asyncCurrencyConvert: (cost: number, date?: Date) => Promise<number>
): Promise<MonthlyAsgUsage> => {
  const date = DateTime.fromFormat(monthHistory, usageKeyFormat).toJSDate();

  return {
    onDemandInstances: {
      totalHours: month.onDemandInstances.totalHours,
      totalCost: await asyncCurrencyConvert(month.onDemandInstances.totalCost, date),
    },
    spotInstances: {
      totalHours: month.spotInstances.totalHours,
      totalCost: await asyncCurrencyConvert(month.spotInstances.totalCost, date),
    },
    totalSavings: await asyncCurrencyConvert(month.totalSavings, date),
  };
};

export const asyncGetUsageHistory = async (
  usage: Record<string, MonthlyAsgUsage>,
  monthHistory: string,
  asyncCurrencyConvert: (cost: number, date?: Date) => Promise<number>
): Promise<MonthlyAsgUsage | undefined> => {
  if (!usage) {
    return;
  }
  const monthUsage = usage[monthHistory];
  if (monthUsage) {
    return asyncConvertMonthUsage(monthUsage, monthHistory, asyncCurrencyConvert);
  }
};

export const asyncGetSavingsHistory = async (
  usage: Record<string, MonthlyAsgUsage>,
  asyncCurrencyConvert: (cost: number, date?: Date) => Promise<number>
): Promise<Record<string, AsgActualAndPotentialCosts>> => {
  if (!usage) {
    return {};
  }
  const allSavings: Record<string, AsgActualAndPotentialCosts> = {};
  for (const [key, monthUsage] of Object.entries(usage)) {
    const monthUsageConverted = await asyncConvertMonthUsage(monthUsage, key, asyncCurrencyConvert);
    const cost = monthUsageConverted.onDemandInstances.totalCost + monthUsageConverted.spotInstances.totalCost;
    allSavings[key] = {
      actualCost: cost,
      costIfUsingOnlyOnDemand: cost + monthUsageConverted.totalSavings,
    };
  }

  return allSavings;
};

export const asyncGetSpendingHistory = async (
  usage: Record<string, MonthlyAsgUsage>,
  asyncCurrencyConvert: (cost: number, date?: Date) => Promise<number>
): Promise<{
  odSpendingHistory: Record<string, AsgInstancesUsage>;
  spotSpendingHistory: Record<string, AsgInstancesUsage>;
}> => {
  if (!usage) {
    return { odSpendingHistory: {}, spotSpendingHistory: {} };
  }
  const odSpendingHistory: Record<string, AsgInstancesUsage> = {};
  const spotSpendingHistory: Record<string, AsgInstancesUsage> = {};
  for (const [key, monthUsage] of Object.entries(usage)) {
    const monthUsageConverted = await asyncConvertMonthUsage(monthUsage, key, asyncCurrencyConvert);
    odSpendingHistory[key] = monthUsageConverted.onDemandInstances;
    spotSpendingHistory[key] = monthUsageConverted.spotInstances;
  }
  return { odSpendingHistory, spotSpendingHistory };
};

/**
 * Format data in Jun 10, 1:30 AM format
 * @param dateObject
 */
export function formatDate(dateObject: Date): string {
  const date = dateObject.toLocaleDateString("en-US", { day: "numeric", month: "short" });
  const time = dateObject.toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit", hour12: true });
  return `${date}, ${time}`;
}
