import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";

import { useAsyncLoadingFunction } from "../../../../Components/hooks/useAsyncLoadingFunction";

export type RampPlanDeletePromptProps = {
  open: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
};

export const RampPlanDeletePrompt = ({ open, onClose, onDelete }: RampPlanDeletePromptProps) => {
  const [isDeleting, doDelete] = useAsyncLoadingFunction(onDelete);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="ramp-plan-delete-prompt-title"
      aria-describedby="ramp-plan-delete-prompt-description"
    >
      <DialogTitle id="ramp-plan-delete-prompt-title">Delete ramp plan</DialogTitle>
      <DialogContent sx={{ width: 444 }}>
        <DialogContentText id="ramp-plan-delete-prompt-description">
          This ramp plan will be permanently deleted and won’t be able to be recovered.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          onClick={doDelete}
          autoFocus
          color="error"
          variant="contained"
          loading={isDeleting}
          disabled={isDeleting}
        >
          Delete ramp plan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
