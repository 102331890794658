import { type TimeInterval } from "@doitintl/cmp-models";
import { Checkbox, FormControlLabel, FormGroup, MenuItem, Stack, TextField, Typography } from "@mui/material";

import { reportText } from "../../../../../../assets/texts";
import {
  convertTimeSettingsModeToIndex,
  lastPeriodOptions,
  type TimeRangeOption,
  TimeRangeTabsLabel,
} from "../../../../utilities";
import TabPanel from "./TabPanel";

type LastProps = {
  timeRangeOption: TimeRangeOption;
  amount: string;
  onPeriodChanged: (value: TimeInterval) => void;
  onChangeAmount: (amount: string) => void;
  onIncludeCurrentChanged: (checked: boolean) => void;
};

const Last = ({ onPeriodChanged, amount, onChangeAmount, timeRangeOption, onIncludeCurrentChanged }: LastProps) => (
  <TabPanel index={0} value={convertTimeSettingsModeToIndex(timeRangeOption.mode)} data-cy="tab-last-container">
    <Stack alignItems="center" direction="row" justifyContent="space-between">
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography variant="subtitle1" data-cy="tab-last-title">
          {TimeRangeTabsLabel.Last}
        </Typography>
        <TextField
          InputProps={{
            inputProps: { min: 1, "data-cy": "tab-amount-input" },
          }}
          margin="dense"
          onChange={(e) => {
            onChangeAmount(e.target.value);
          }}
          sx={{
            width: 70,
          }}
          type="number"
          value={amount}
          variant="outlined"
        />
        <TextField
          margin="dense"
          select
          value={timeRangeOption.time}
          variant="outlined"
          data-cy="time-interval-button"
          InputProps={{
            inputProps: { "data-cy": "time-interval-select" },
          }}
        >
          {lastPeriodOptions.map(({ label, value }) => (
            <MenuItem
              key={label}
              onClick={() => {
                onPeriodChanged(value);
              }}
              value={value}
              data-cy={value}
            >
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!timeRangeOption?.includeCurrent}
              onChange={(_, checked: boolean) => {
                onIncludeCurrentChanged(checked);
              }}
              data-cy="include-current-checkbox"
            />
          }
          label={reportText.INCLUDE_CURRENT + timeRangeOption.time}
          sx={{ mr: 0 }}
        />
      </FormGroup>
    </Stack>
  </TabPanel>
);

export default Last;
