import { Chip, Hidden, TableCell, Typography } from "@mui/material";

export const snowflakeAssetRowCyIds = {
  accountId: "snowflake-asset-account-id",
  orgName: "snowflake-asset-org-name",
  statusActive: "snowflake-asset-status-active",
  statusInactive: "snowflake-asset-status-inactive",
};
export type SnowflakeAssetRowData = {
  accountId: string;
  orgName: string;
  status: "Active" | "Inactive";
};

export type SnowflakeAssetRowProps = { data: SnowflakeAssetRowData };

const SnowflakeAssetRow = ({ data }: SnowflakeAssetRowProps) => (
  <>
    <TableCell>
      <Typography variant="body2" component="span" data-cy="snowflake-asset-org-name">
        {data.orgName}
      </Typography>
    </TableCell>
    <Hidden smDown>
      <TableCell>
        <Typography data-cy="snowflake-asset-account-id" variant="body2">
          {data.accountId}
        </Typography>
      </TableCell>
    </Hidden>
    <Hidden mdDown>
      <TableCell>
        {data.status === "Active" ? (
          <Chip size="small" label="Active" color="success" data-cy="snowflake-asset-status-active" />
        ) : (
          <Chip size="small" label="Inactive" color="error" data-cy="snowflake-asset-status-inactive" />
        )}
      </TableCell>
    </Hidden>
  </>
);
export default SnowflakeAssetRow;
