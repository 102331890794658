import { type MouseEventHandler } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

const useKnownIssueDialogHeaderStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export type KnownIssueDialogHeaderProps = {
  title: string;
  onClose: MouseEventHandler;
};

export const KnownIssueDialogHeader = ({ title }: KnownIssueDialogHeaderProps) => {
  const classes = useKnownIssueDialogHeaderStyle();
  return (
    <DialogTitle className={classes.root}>
      <Typography variant="h6">{title}</Typography>
    </DialogTitle>
  );
};
