import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { type HeaderColumn } from "../../../types/FilterTable";

export const filterColumns = [
  {
    label: datahubTxt.DATASET_DETAILS_HEADERS.BATCH,
    path: "batch",
    comparators: ["==", "!=", "contains"],
  },
  {
    path: "origin",
    label: datahubTxt.DATASET_DETAILS_HEADERS.ORIGIN,
    comparators: ["==", "!=", "contains"],
  },
  {
    label: datahubTxt.TABLE_HEADERS.RECORDS,
    path: "eventsProcessed",
    type: "Number",
    comparators: ["<", "<=", ">", ">=", "==", "!="],
  },
  {
    path: "createdBy",
    label: datahubTxt.DATASET_DETAILS_HEADERS.SUBMITTED_BY,
    comparators: ["==", "!=", "contains"],
  },
  {
    path: "createdAt",
    label: datahubTxt.DATASET_DETAILS_HEADERS.SUBMITTED_ON,
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
  },
] as const;

export const headers: HeaderColumn[] = [
  {
    value: "batch",
    label: datahubTxt.DATASET_DETAILS_HEADERS.BATCH,
    tooltip: datahubTxt.DATASET_DETAILS_HEADERS.BATCH,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "origin",
    label: datahubTxt.DATASET_DETAILS_HEADERS.ORIGIN,
    tooltip: datahubTxt.DATASET_DETAILS_HEADERS.ORIGIN,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "eventsProcessed",
    label: datahubTxt.DATASET_DETAILS_HEADERS.RECORDS,
    tooltip: datahubTxt.DATASET_DETAILS_HEADERS.RECORDS,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "createdBy",
    label: datahubTxt.DATASET_DETAILS_HEADERS.SUBMITTED_BY,
    tooltip: datahubTxt.DATASET_DETAILS_HEADERS.SUBMITTED_BY,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "createdAt",
    label: datahubTxt.DATASET_DETAILS_HEADERS.SUBMITTED_ON,
    tooltip: datahubTxt.DATASET_DETAILS_HEADERS.SUBMITTED_ON,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "@",
    style: { align: "left", padding: "checkbox" },
    hidden: { smDown: true },
  },
];
