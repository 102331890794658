export * from "./dateUtils";
export * from "./errorHandling";

export function never<T>(_: never, value: T) {
  return value;
}

export function isJapanese(text) {
  // Regular expression to match Japanese characters
  const japaneseRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;

  return japaneseRegex.test(text);
}
