import { type CurrencyCode } from "@doitintl/cmp-models";

import { formatCurrency } from "../../../utils/common";

export const invoicesTxt = {
  PAY_NOW: "Pay now",
  CREATE_NEW: "Create new",
  PAYMENT_FAILED: "Payment failed, please try again later or contact support.",
  SAVED_PAYMENT_METHODS_ERROR: "Could not load saved payment methods, please try again or contact support",
  SUCCESSFUL_PAYMENT: (amount: number, currency: CurrencyCode, ivnum) =>
    `Payment of ${formatCurrency(amount, currency)} for ${ivnum} was successful`,
  SELECT_A_PAYMENT_METHOD: "Select a payment method",
  NO_PAYMENT_METHODS_FOUND:
    "You will need to create a new payment method in order to pay the invoice. Refresh this dialog once you have created the new payment method.",
  NEW_PAYMENT_METHOD: "Pay with a new payment method",
  CREATE_NEW_METHOD: "Create a new payment method from your billing profile:",
  PAYMENT_CHARGE_FEE: (pct: number) => `Payments with a credit card are charged a ${pct}% processing fee.`,
  MINIMUM_AMOUNT_BALANCE: (minAmount: number, currency: CurrencyCode = "USD") =>
    `Minimum amount is ${formatCurrency(minAmount, currency)}`,
  MAXIMUM_AMOUNT_BALANCE: (maxAmount: number, currency: CurrencyCode = "USD") =>
    `Your open balance is ` +
    `${formatCurrency(maxAmount, currency)}.` +
    ` Adjust the amount to make payments with multiple cards.`,
  OPEN_IN_REPORTS: "Open in Reports",
  OPEN_INVOICE_IN_REPORTS: "Open invoice in Reports",
  EXPLORE_COMPREHENSIVE: "Explore a comprehensive breakdown of your cloud invoice",
  TIME_FRAME: "Time frame",
  INVOICE_BUCKETS: "Invoice bucket(s)",
  LAST_MONTH: "Last month",
  LAST_THREE_MONTHS: "Last 3 months",
  LAST_SIX_MONTHS: "Last 6 months",
  INVOICE_BREAKDOWN_PRESET_REPORT: "Invoice breakdown preset report",
  ALL: "All",
  SUPPORTED_ASSETS: "Supported assets",
  NOT_SUPPORTED_ASSETS: "Assets not supported in Reports",
};
