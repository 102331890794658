import { type EntitlementModel } from "@doitintl/cmp-models";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";

const itemsDescriptions: FilterTableItemDescriptor<EntitlementModel>[] = [
  {
    headerText: "Entitlement name",
    value: "displayName",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Entitlement key",
    value: "key",
    filterType: "string",
  },
  {
    headerText: "Limit",
    value: "limit",
    filterType: "string",
  },
  {
    value: "@",
    headerText: "",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
