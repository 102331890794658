import { useCallback } from "react";

import { CloudFlowNodeType, CustomFrequencies, Frequencies } from "@doitintl/cmp-models";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Autocomplete, Box, Button, Card, CardHeader, Grid, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { type DateTime } from "luxon";

import { allTimezones } from "../../../../../Components/NotificationSubscription/allTimeZones";
import { RequiredLabel } from "../../../../../Components/NotificationSubscription/helpers";
import { type NodeConfigs } from "../../../types";
import { useNodeEdgeManager } from "../../Common/NodeEdgeManagerProvider";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const ScheduleTab = ({ nodeConfig }: { nodeConfig: NodeConfigs<CloudFlowNodeType.TRIGGER> }) => {
  const { timeZone, startDate, time, frequency, customFrequency, customFrequencyAmount } = nodeConfig.parameters || {};
  const {
    palette: { primary },
  } = useTheme();
  const { updateNode } = useNodeConfigurationContext<CloudFlowNodeType.TRIGGER>();

  const updateTrigger = useCallback(
    // TODO: use trigger param type for parameter
    (updatedTriggerParams) => {
      updateNode((prevNode) => ({
        parameters: {
          ...prevNode.parameters,
          ...updatedTriggerParams,
        },
      }));
    },
    [updateNode]
  );
  const { onChangeActiveNode } = useNodeEdgeManager();

  return (
    <Box p={2}>
      <Stack alignContent="center">
        <Card sx={{ "&:hover": { borderColor: primary.main } }}>
          <CardHeader
            subheader="Trigger set on a custom schedule"
            title="Scheduled"
            titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
            subheaderTypographyProps={{ variant: "caption" }}
            action={
              <Button
                onClick={() => {
                  onChangeActiveNode(CloudFlowNodeType.START_STEP, nodeConfig.id);
                }}
              >
                Change
              </Button>
            }
            sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
            avatar={<TimerOutlinedIcon color="primary" />}
          />
        </Card>
        <Box mb={2} />
        <Typography variant="subtitle2">Parameters</Typography>
        <Box mb={2} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={allTimezones}
              value={timeZone}
              onChange={(_event, newValue) => {
                if (!newValue) {
                  return;
                }

                updateTrigger({
                  timeZone: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={RequiredLabel("Time zone")} variant="outlined" fullWidth />
              )}
              fullWidth
              aria-required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              disableMaskedInput
              label={RequiredLabel("Start date")}
              value={startDate}
              minDate={new Date()}
              inputFormat="dd MMMM yyyy"
              onChange={(newValue) => {
                if (!newValue) {
                  return;
                }
                updateTrigger({
                  startDate: (newValue as unknown as DateTime).toISO(),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                ampm={false}
                label={RequiredLabel("Time")}
                value={time}
                onChange={(value) => {
                  updateTrigger({
                    time: (value as unknown as DateTime)?.toISO(),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={Object.values(Frequencies)}
              value={frequency}
              onChange={(_event, newValue) => {
                if (!newValue) {
                  return;
                }
                updateTrigger({
                  frequency: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label={RequiredLabel("Frequency")} variant="outlined" fullWidth />
              )}
              fullWidth
              aria-required={true}
            />
          </Grid>
          <Grid item xs={12}>
            {frequency === "Custom" && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label={RequiredLabel("Repeats every")}
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{ min: 1 }}
                    value={customFrequencyAmount}
                    onChange={(event) => {
                      updateTrigger({
                        customFrequencyAmount: parseInt(event.target.value),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={Object.values(CustomFrequencies)}
                    value={customFrequency}
                    onChange={(_event, newValue) => {
                      if (!newValue) {
                        return;
                      }
                      updateTrigger({
                        customFrequency: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={RequiredLabel("Frequency")} variant="outlined" fullWidth />
                    )}
                    fullWidth
                    aria-required={true}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ScheduleTab;
