import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

export function useGCPOpsState() {
  const gcpOpsPageStateQuery = getCollection(IntegrationModel)
    .doc("flexsave")
    .collection("gcp-operations")
    .doc("opsPageState");
  const [data] = useDocumentData(gcpOpsPageStateQuery);
  return data;
}

export function useBillingProcessState() {
  const gcpOpsPageStateQuery = getCollection(IntegrationModel)
    .doc("flexsave")
    .collection("gcp-operations")
    .doc("billingProcessState");
  const [data] = useDocumentData(gcpOpsPageStateQuery);
  return data;
}
