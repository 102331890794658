import { type EntityPayment, PaymentMethodType } from "@doitintl/cmp-models";
import capitalize from "lodash/capitalize";

import { Comparators, type DataColumn, stringComparators } from "../../types/FilterTable";

export const paymentTypeColumnText = (entityPayment: EntityPayment | undefined): string => {
  if (entityPayment === undefined) {
    return "";
  }

  switch (entityPayment.type) {
    case PaymentMethodType.CreditCard:
      return `Credit card ${entityPayment.card?.last4 ? `(${entityPayment.card?.last4})` : ""}`;
    case PaymentMethodType.BankAccount:
    case PaymentMethodType.USBankAccount:
      return `ACH Debit (${entityPayment.bankAccount?.last4})`;
    case PaymentMethodType.AcssDebit:
      return `ACSS Debit (${entityPayment.acssDebit?.last4})`;
    case PaymentMethodType.BacsDebit:
      return `BACS Debit (${entityPayment.bacsDebit?.last4})`;
    case PaymentMethodType.SepaDebit:
      return `SEPA Debit (${entityPayment.sepaDebit?.last4})`;
    default:
      return capitalize(entityPayment.type.replaceAll("_", " "));
  }
};

export const billingProfilesColumnHeaderHiddenMap = {
  priorityId: { smDown: true },
  currency: { smDown: true },
  "payment.type": { mdDown: true },
  active: { lgDown: true },
  "contact.email": { xlDown: true },
};

export const billingProfilesFilterColumns: DataColumn[] = [
  {
    path: "name",
    label: "Name",
    comparators: stringComparators,
  },
  {
    path: "priorityId",
    label: "ID",
    comparators: stringComparators,
  },
  {
    path: "currency",
    label: "Currency",
    comparators: stringComparators,
  },
  {
    path: "payment",
    label: "Payment method",
    comparators: stringComparators,
    toOption: (value) => {
      value = value ? value : "";
      return {
        value,
        label: paymentTypeColumnText(value),
      };
    },
  },
  {
    path: "active",
    label: "Status",
    comparators: [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO],
    toOption: (value: boolean | undefined) => ({
      value,
      label: value ? "Active" : "Inactive",
    }),
  },
  {
    path: "contact.email",
    label: "Invoice email",
    comparators: stringComparators,
  },
];
