import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { type EntitlementKey, type SupportServiceModel } from "@doitintl/cmp-models";
import { Autocomplete, MenuItem, Paper, Stack, TextField, Typography } from "@mui/material";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";

import { useIsFeatureEntitled, useIsFeatureEntitledMultiple, useTier } from "../../Context/TierProvider";
import { entitlementsToContactSupport, mapEntitlementsToSupportMenu } from "../consts";
import { isOtherProduct } from "../utils";
import UnlockSupportOptionsDropdownBox from "./UnlockSupportOptionsDropdownBox";

type Props = {
  categories: SupportServiceModel[];
  categoryTitle: string;
  onChange: (data: any) => void;
  error: boolean;
};

export const selectors = {
  root: "category-suggest",
};

const CategorySuggest = ({ categories, categoryTitle, onChange, error }: Props) => {
  const catVal = find(categories, { name: categoryTitle }) || null;
  const { tiers } = useTier();
  const { t } = useTranslation("services");

  const supportEntitlementsCheck = useIsFeatureEntitledMultiple(entitlementsToContactSupport);
  const isAllProductsEntitled = useIsFeatureEntitled("support:ticket:menu:product:all");

  const isAllProductsByPlatformEntitled = useIsFeatureEntitledMultiple(
    uniq(categories.map((cat) => cat.platform)).map(
      (platform) => `support:ticket:menu:product:all:platform:${platform}` as EntitlementKey
    )
  );

  const hasProductSupportEntitlement = useCallback(
    (platform: string, productId: string): boolean => {
      const allPlatformKey = `support:ticket:menu:product:all:platform:${platform}`;
      let productKey = `support:ticket:menu:product:${productId}`;
      if (platform === "cloud-management-platform") {
        productKey = mapEntitlementsToSupportMenu[productKey];
      }
      return (
        isAllProductsEntitled ||
        isAllProductsByPlatformEntitled[allPlatformKey] ||
        (supportEntitlementsCheck && (!!supportEntitlementsCheck[productKey] || !productKey))
      );
    },
    [isAllProductsByPlatformEntitled, isAllProductsEntitled, supportEntitlementsCheck]
  );

  const renderOption = (props, option: SupportServiceModel) => (
    <MenuItem
      {...props}
      key={(props.key as string) + (props["data-option-index"] as string)}
      disabled={!!tiers && !hasProductSupportEntitlement(option.platform, option.id)}
    >
      <Stack direction="column">
        <Typography sx={{ fontStyle: isOtherProduct(option.name) ? "italic" : "inherit" }} variant="body1">
          {option.name}
        </Typography>
        {option.platform === "cloud-management-platform" && (
          <Typography color="text.secondary" variant="caption">
            {option.summary}
          </Typography>
        )}
      </Stack>
    </MenuItem>
  );
  const renderInput = (params) => (
    <TextField
      {...params}
      variant="outlined"
      size="medium"
      label={t("CategorySuggest.label")}
      error={error}
      required
      InputLabelProps={{ shrink: true }}
    />
  );

  const sortCategories = (a: SupportServiceModel, b: SupportServiceModel) => {
    const nameA = a?.categories?.[0]?.name?.toLowerCase();
    const nameB = b?.categories?.[0]?.name?.toLowerCase();

    if (b.name.toLowerCase().includes("other") && nameA === nameB) {
      return -1;
    }

    if (nameA === "" || nameB === "") {
      return 0;
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };

  const sortedServices = useMemo(() => {
    if (categories.length === 0) {
      return [];
    }

    if (
      !tiers?.find((tier) => tier.description === "Solve Standard") ||
      ["cloud-management-platform", "finance", "credits"].includes(categories[0].platform)
    ) {
      return sortBy(categories, (c) => c.categories?.[0]?.name).sort(sortCategories);
    }

    const availableProducts: SupportServiceModel[] = [];
    const notAvailableProducts: SupportServiceModel[] = [];

    categories
      .filter((c) => c.categories?.[0]?.id !== "popular")
      .forEach((category) => {
        if (hasProductSupportEntitlement(category.platform, category.id)) {
          category.categories = [{ id: "", name: "Core Services Support" }];
          availableProducts.push(category);
        } else {
          category.categories = [{ id: "", name: "Services not included in your Solve tier" }];
          notAvailableProducts.push(category);
        }
      });

    return [...availableProducts, ...notAvailableProducts];
  }, [categories, hasProductSupportEntitlement, tiers]);

  return (
    <Autocomplete
      id="category-autocomplete"
      options={sortedServices ?? []}
      getOptionLabel={(option) => option.name}
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (option) =>
            option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.categories?.[0]?.name?.toLowerCase()?.includes(inputValue.toLowerCase())
        )
      }
      PaperComponent={(props) => {
        const { children, ...otherProps } = props;
        return (
          <Paper {...otherProps}>
            {children}
            {!!tiers && !isAllProductsEntitled && <UnlockSupportOptionsDropdownBox sx={{ mb: 1, pt: 1 }} />}
          </Paper>
        );
      }}
      groupBy={(option) => option.categories?.[0]?.name}
      value={catVal}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={renderInput}
      renderOption={renderOption}
      data-cy={selectors.root}
    />
  );
};

export default CategorySuggest;
