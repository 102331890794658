import { Box, FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { createStyles, makeStyles } from "@mui/styles";

import { useCurrentDashboardContext } from "../../../Context/CurrentDashboardContext";
import { timeframeOptions } from "./constants";
import { useSelectStyles } from "./utils";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 150,
    },
  })
);

export const TimeRangeChanger = () => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();

  const { selectedTimeframe, setDashboardWidgetContextValue } = useCurrentDashboardContext();

  return (
    <Box width={150}>
      <FormControl margin="dense" className={classes.formControl}>
        <InputLabel>Time range</InputLabel>
        <Select
          size="small"
          value={selectedTimeframe}
          onChange={({ target: { value } }: any) => {
            setDashboardWidgetContextValue("selectedTimeframe", value);
          }}
          className={selectClasses.select}
          label="Time range"
        >
          {timeframeOptions.map((item) => (
            <MenuItem key={item.value} value={item.value} className={selectClasses.select}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
