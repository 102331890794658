import { type ChangeEventHandler } from "react";

import TablePagination from "@mui/material/TablePagination";

export type FilterTableFooterProps = {
  rowsCount: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (event: unknown, page: number) => void;
  onRowsPerPageChange: ChangeEventHandler;
  tableName?: string;
};

export const FilterTableFooter = ({
  rowsCount,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  tableName,
}: FilterTableFooterProps) => (
  <TablePagination
    component="div"
    data-testid={`table-footer-${tableName}`}
    count={rowsCount}
    rowsPerPage={rowsPerPage}
    labelRowsPerPage="Per page"
    labelDisplayedRows={({ from, to, count }) => `${from}-${to > -1 ? to : count} of ${count}`}
    page={page}
    backIconButtonProps={{
      "aria-label": "Previous Page",
    }}
    nextIconButtonProps={{
      "aria-label": "Next Page",
    }}
    onPageChange={onPageChange}
    onRowsPerPageChange={onRowsPerPageChange}
  />
);
