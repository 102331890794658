import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../../assets/texts";

type ConfirmEditDialogProps = {
  isDialogOpened: boolean;
  handleCloseDialog: () => void;
  handleEdit: () => void;
};

const ConfirmEditDialog = ({ isDialogOpened, handleCloseDialog, handleEdit }: ConfirmEditDialogProps) => (
  <Dialog open={isDialogOpened} onClose={handleCloseDialog} fullWidth maxWidth="xs">
    <DialogTitle>{cloudflowTexts.CONFIRM_EDIT_TITLE}</DialogTitle>
    <DialogContent>
      <Typography>{cloudflowTexts.CONFIRM_EDIT_CONTENT}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseDialog} color="primary">
        {cloudflowTexts.CANCEL}
      </Button>
      <Button onClick={handleEdit} color="primary" variant="contained">
        {cloudflowTexts.UNPUBLISH}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmEditDialog;
