import { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { EntityModel } from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

import { useCustomerContext } from "../Context/CustomerContext";
import { preventOnCloseWhile, useFullScreen } from "../utils/dialog";
import { arrayUnion } from "../utils/firebase";
import LoadingButton from "./LoadingButton";

const useStyles = makeStyles(() => ({
  root: {},
  textField: {
    width: 552,
  },
}));

const EntityAttachDialog = ({ onClose }: { onClose: () => void }) => {
  const [loading, setLoading] = useState(false);
  const [CUSTNAME, setCUSTNAME] = useState("");
  const { customer } = useCustomerContext();
  const history = useHistory();
  const { fullScreen } = useFullScreen();
  const classes = useStyles();

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      const querySnap = await getCollection(EntityModel).where("priorityId", "==", CUSTNAME).limit(1).get();

      if (!querySnap.empty) {
        return;
      }

      const entityRef = getCollection(EntityModel).newDoc();
      const batch = getBatch();
      batch.set(entityRef, {
        priorityId: CUSTNAME,
        customer: customer.ref,
        name: "",
        _name: "",
        active: false,
        contact: null,
        currency: "",
        country: "",
        invoicing: {
          mode: "GROUP",
          default: null,
        },
        payment: {
          type: "wire_transfer",
        },
      });
      batch.update(customer.ref, {
        entities: arrayUnion(entityRef),
      });
      await batch.commit();
      history.push(`/customers/${customer.ref.id}/entities/${entityRef.id}`);
    } finally {
      setLoading(false);
    }
  }, [CUSTNAME, customer.ref, history]);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">Attach existing billing profile</DialogTitle>
      <DialogContent>
        <TextField
          name="CUSTNAME"
          variant="outlined"
          margin="dense"
          label="Priority ID"
          type="text"
          value={CUSTNAME}
          onChange={(event) => {
            setCUSTNAME(event.target.value);
          }}
          disabled={loading}
          className={classes.textField}
          fullWidth
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={loading}
          loading={loading}
          mixpanelEventId="entity.attach"
        >
          Attach
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EntityAttachDialog;
