import { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import noop from "lodash/noop";

import { useAuthContext } from "./AuthContext";

type HotkeyContextType = {
  state: boolean;
  disable: () => void;
  enable: () => void;
};

const hotkeyContext = createContext<HotkeyContextType>({
  state: true,
  disable: noop,
  enable: noop,
});

export const HotkeyContextProvider = ({ children }: { children?: ReactNode }) => {
  const { isDoitEmployee } = useAuthContext();
  const history = useHistory();
  const [state, setState] = useState(true);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const { key, metaKey, repeat } = event;

      if (repeat) {
        return;
      }

      if (metaKey && key === "/" && location.pathname !== "/customers" && isDoitEmployee) {
        history.push("/customers");
      }
    },
    [history, isDoitEmployee]
  );

  useEffect(() => {
    if (!state) {
      document.removeEventListener("keydown", handleKeyDown);
      return;
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, state]);

  const enableHotkey = useCallback(() => {
    setState(true);
  }, []);

  const disableHotkey = useCallback(() => {
    setState(false);
  }, []);

  return (
    <hotkeyContext.Provider value={{ enable: enableHotkey, disable: disableHotkey, state }}>
      {children}
    </hotkeyContext.Provider>
  );
};

export const HotkeyContextConsumer = hotkeyContext.Consumer;

export function useHotkeyContext(): HotkeyContextType {
  return useContext(hotkeyContext);
}
