import { type AssetType } from "@doitintl/cmp-models";

export type TemplateConfiguration = {
  grouping: string[];
  filters: { desc: string; label: string; values: string }[];
  configDimensions: string[];
};

export const CREATE_TEMPLATE_QUERY_PARAM = "createTemplate";
export const EDIT_TEMPLATE_QUERY_PARAM = "edit";

export interface IAsset {
  key: AssetType;
  label: string;
  selected: boolean;
}
