import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  icons: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  copy: {
    marginRight: theme.spacing(1),
  },
  fitMargin: {
    marginTop: 0,
    marginBottom: "11px",
  },
  fitAlertPadding: {
    padding: "2px 16px",
  },
  sizeSmall: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  chartCardContent: {
    padding: theme.spacing(0, 1, 0),
  },
  headerCardContent: {
    padding: theme.spacing(1, 2, 1),
  },
  skeleton: {
    position: "relative",
    padding: theme.spacing(2),
  },
  skeletonText: {
    height: 32,
    width: "100%",
    position: "absolute",
    top: "40%",
    left: "50%",
    marginLeft: -86,
  },
}));
