import { type ChangeEvent, useCallback, useEffect, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";
import EventIcon from "@mui/icons-material/Event";
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";

import DeleteDialog from "../../../../Components/DeleteDialog";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type TierWithRef } from "../../../../Context/TierProvider";
import { isTrialActive } from "../helpers";
import { text } from "../text";
import { isPaidTier } from "../utils";

const DATE_FORMAT = "dd MMM yyyy";

type Props = {
  title: string;
  tier: TierWithRef | undefined;
  trialStartDate?: Date;
  trialEndDate?: Date;
  trialCustomLength?: number | null;
  currentTrialEndDate?: Date;
  errMsg?: string;
  onChangeDate: (start: DateTime, end: DateTime) => void;
  onChangeTrialCustomLength?: (trialLength: number) => void;
  onResetChanges: () => void;
  onEndTrial: () => Promise<void>;
  onExtendTrial: (endDate: DateTime) => Promise<void>;
  onExtendTrialCustomLength?: (extendedLength: number) => Promise<void>;
};

export function Trials({
  title,
  tier,
  trialStartDate,
  trialEndDate,
  trialCustomLength,
  currentTrialEndDate,
  onChangeDate,
  onResetChanges,
  onEndTrial,
  onExtendTrial,
  errMsg,
  onChangeTrialCustomLength,
  onExtendTrialCustomLength,
}: Props) {
  const { isProductOnlyCustomer } = useCustomerContext();
  const startDate = trialStartDate ? DateTime.fromJSDate(trialStartDate) : DateTime.now();
  const endDate: DateTime | undefined = trialEndDate ? DateTime.fromJSDate(trialEndDate) : undefined;
  const trialActive = isTrialActive(endDate);
  const isTieringAdmin = useDoitRoleCheck(DoitRole.CustomerTieringAdmin);

  const [selectedDays, setSelectedDays] = useState<number | undefined>(() => {
    const days = trialActive && endDate ? endDate.diff(startDate, "days").toObject().days : (trialCustomLength ?? 45);
    return days ? Math.trunc(days) : days;
  });

  useEffect(() => {
    if (!trialActive && trialCustomLength) {
      setSelectedDays(trialCustomLength);
    }
  }, [trialCustomLength, trialActive]);

  const [enabled, setEnabled] = useState<boolean>(false);
  const [endModalOpen, setEndModalOpen] = useState<boolean>(false);
  const [extendTrialOpen, setExtendTrialOpen] = useState<boolean>(false);
  const [customTrialLengthEnabled, setCustomTrialLengthEnabled] = useState<boolean>(false);

  const onDeleteTrial = useCallback(async () => {
    await onEndTrial();
    setEnabled(false);
  }, [onEndTrial]);

  const handleAutoStartTrialChange = (isChecked: boolean) => {
    if (isChecked) {
      onResetChanges();
    } else {
      if (enabled) {
        onChangeDate(startDate, startDate.plus({ days: selectedDays }));
      }
    }
    setCustomTrialLengthEnabled(isChecked); // Set the 'enabled' state
  };

  const showPreviousTrialDate = !trialActive && trialEndDate && trialStartDate;
  const showTrialPendingAlert = !!trialCustomLength && !trialActive && isProductOnlyCustomer && !showPreviousTrialDate;
  const showAutomaticallyStartTrial =
    isProductOnlyCustomer && title === text.NAVIGATOR_TRIAL_SETTINGS_LABEL && !showPreviousTrialDate;
  const showUnknownTrialStartDate =
    !trialActive && !showPreviousTrialDate && (customTrialLengthEnabled || trialCustomLength) && isProductOnlyCustomer;

  let trialEndString: string | undefined;
  if (trialActive) {
    trialEndString = endDate?.toFormat(DATE_FORMAT);
  } else if ((enabled && customTrialLengthEnabled) || (!!trialCustomLength && !showPreviousTrialDate)) {
    trialEndString = "N/A";
  } else if (enabled && currentTrialEndDate) {
    trialEndString = DateTime.fromJSDate(currentTrialEndDate).toFormat(DATE_FORMAT);
  }

  const paidTier = isPaidTier(tier);
  const previousTrialLength = dateDiff(trialStartDate, trialEndDate);

  return (
    <Stack gap={2.5}>
      <Typography variant="subtitle1" fontWeight={500} sx={{ cursor: "auto" }}>
        {title}
      </Typography>
      {showPreviousTrialDate && (
        <Typography>
          Previous trial end date:{" "}
          <Box component="span" fontWeight={500}>
            {DateTime.fromJSDate(trialEndDate).toFormat(DATE_FORMAT)}
          </Box>{" "}
          {previousTrialLength !== undefined && (
            <Box component="span" fontWeight={500}>
              ({previousTrialLength} days)
            </Box>
          )}
        </Typography>
      )}
      {showTrialPendingAlert && <Alert severity="info">This trial is pending customer connecting billing data</Alert>}
      <Tooltip
        title={paidTier ? "Customer is on a paid tier" : undefined}
        placement="top-start"
        data-testid="trial-tooltip"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={trialActive || enabled || (!showPreviousTrialDate && !!trialCustomLength)}
              disabled={trialActive || paidTier || (!showPreviousTrialDate && !!trialCustomLength) || !isTieringAdmin}
              onChange={(e) => {
                e.target.checked
                  ? onChangeDate(
                      // adding the current milliseconds here so that startDate field will be dirtied
                      // when the new start date == old trial start date
                      startDate.set({ millisecond: DateTime.now().millisecond }),
                      startDate.plus({ days: selectedDays })
                    )
                  : onResetChanges();
                setEnabled(!enabled);
                setCustomTrialLengthEnabled(false);
              }}
            />
          }
          label="Enable trial"
        />
      </Tooltip>
      {showAutomaticallyStartTrial && (
        <FormControlLabel
          control={
            <Checkbox
              checked={(enabled && customTrialLengthEnabled) || (!!trialCustomLength && !trialActive)}
              disabled={!enabled || trialActive || paidTier || !!trialCustomLength}
              onChange={(e) => {
                setCustomTrialLengthEnabled(e.target.checked);
                handleAutoStartTrialChange(e.target.checked);
                onChangeTrialCustomLength?.(selectedDays || 45);
              }}
            />
          }
          label="Automatically start trial"
          sx={{ mt: -1 }}
        />
      )}
      <Box display="flex" flexDirection="row" gap={2}>
        {showUnknownTrialStartDate ? (
          <TextField
            label="Trial start date"
            value="N/A"
            disabled
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <IconButton disabled>
                  <EventIcon />
                </IconButton>
              ),
            }}
          />
        ) : (
          <DatePicker
            label="Trial start date"
            disableMaskedInput={true}
            renderInput={(params) => <TextField size="small" fullWidth {...params} />}
            value={startDate}
            onChange={(newDate) => {
              if (newDate) {
                const now = DateTime.now();
                newDate = newDate.set({ hour: now.hour, minute: now.minute });
                onChangeDate(newDate, newDate.plus({ days: selectedDays }));
              }
            }}
            inputFormat={DATE_FORMAT}
            disabled={trialActive || !enabled || customTrialLengthEnabled}
          />
        )}
        <TextField
          label="Trial length (days)"
          type="text"
          inputMode="numeric"
          inputProps={{ pattern: "[0-9]*" }}
          fullWidth
          value={selectedDays}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            let val = parseInt(e.target.value);
            if (!isNaN(val)) {
              if (val < 0 || val > 365) {
                return;
              }
            } else {
              val = 1;
            }

            setSelectedDays(val);
            if (customTrialLengthEnabled && onChangeTrialCustomLength) {
              onChangeTrialCustomLength(val);
            } else {
              onChangeDate(startDate, startDate.plus({ days: val }));
            }
          }}
          size="small"
          disabled={trialActive || !enabled || !!trialCustomLength}
          error={!!errMsg}
          helperText={errMsg}
        />
      </Box>
      <Typography variant="body1">
        Trial end date:{" "}
        <Box component="span" fontWeight={500}>
          {trialEndString}
        </Box>
      </Typography>
      {((trialActive && endDate) || (!trialActive && !!trialCustomLength && !showPreviousTrialDate)) && (
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            color="error"
            fullWidth={false}
            onClick={() => {
              setEndModalOpen(true);
            }}
          >
            End trial
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth={false}
            onClick={() => {
              setExtendTrialOpen(true);
            }}
          >
            Extend trial
          </Button>
          <DeleteDialog
            title="End trial?"
            message={
              <Typography variant="body1" color="text.primary">
                This action will immediately end the customers trial period of the DoiT Console. You can enable another
                trial anytime.
              </Typography>
            }
            deleteButtonText="End trial"
            open={endModalOpen}
            onClose={() => {
              setEndModalOpen(false);
            }}
            onDelete={onDeleteTrial}
          />
          {extendTrialOpen && (
            <ExtendTrialDialog
              open={extendTrialOpen}
              endDate={endDate}
              onClose={() => {
                setExtendTrialOpen(false);
              }}
              onExtendTrial={onExtendTrial}
              onExtendTrialCustomLength={onExtendTrialCustomLength}
              navigatorTrialCustomLength={trialCustomLength}
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}

type TrialDialogProps = {
  open: boolean;
  endDate?: DateTime;
  navigatorTrialCustomLength?: number | null;
  onClose: () => void;
  onExtendTrial: (endDate: DateTime) => void;
  onExtendTrialCustomLength?: (extendedLength: number) => void;
};

function ExtendTrialDialog({
  open,
  endDate,
  navigatorTrialCustomLength,
  onClose,
  onExtendTrial,
  onExtendTrialCustomLength,
}: TrialDialogProps) {
  const [days, setDays] = useState(7);
  const daysIsValid = days > 0 && days <= 365;

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>Extend trial</DialogTitle>
      <DialogContent>
        <Stack pt={1} spacing={2}>
          <TextField
            label="Number of days extension"
            type="text"
            inputMode="numeric"
            inputProps={{ pattern: "[0-9]*" }}
            fullWidth
            value={days}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const val = parseInt(e.target.value);
              !isNaN(val) ? setDays(val) : setDays(0);
            }}
            size="small"
            error={!daysIsValid}
            helperText={!daysIsValid ? "Value must be between 1 and 365" : undefined}
          />
          <Typography variant="body1">
            New trial end date:{" "}
            <Box component="span" fontWeight={500}>
              {endDate ? endDate.plus({ days }).toFormat(DATE_FORMAT) : "N/A"}
            </Box>
          </Typography>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!daysIsValid}
          onClick={() => {
            onClose();
            if (endDate) {
              onExtendTrial(endDate.plus({ days }));
            } else if (navigatorTrialCustomLength && onExtendTrialCustomLength) {
              onExtendTrialCustomLength(days + navigatorTrialCustomLength);
            }
          }}
        >
          Extend trial
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function dateDiff(start: Date | undefined, end: Date | undefined) {
  if (!start || !end) {
    return undefined;
  }

  const diff = DateTime.fromJSDate(end).diff(DateTime.fromJSDate(start), "days").toObject().days;
  return diff ? Math.trunc(diff) : undefined;
}
