import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";

export const validateGcpPLPSStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, boolean> = {};

  errors.assets = !state.assets.length;
  errors.gcpContract = !state.gcpContract.length;

  setState({ ...state, errors });

  return !Object.values(errors).some((error) => error);
};
