import { useState } from "react";

import { NotificationProviderType } from "@doitintl/cmp-models";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { DateTime } from "luxon";

import { useSubscribeSelf, useUnsubscribeSelf } from "./db";
import { getScheduleText, useSubscriptionPermissions } from "./helpers";
import { SubscriptionForm } from "./SubscriptionForm";
import { type SubscriptionByType, type SubscriptionParentByType, type SubscriptionType } from "./types";

export function SubscriptionPanelItem<ST extends SubscriptionType>({
  subscription,
  subscriptionType,
  subscriptionParent,
}: {
  subscription: SubscriptionByType<ST>;
  subscriptionType: ST;
  subscriptionParent: SubscriptionParentByType<ST>;
}) {
  const [isSubscriptionFormOpen, setIsSubscriptionFormOpen] = useState(false);

  const { allowedToEdit, canSubscribe, isSubscribed } = useSubscriptionPermissions({
    subscriptionType,
    subscriptionParent,
    subscription,
  });

  const subscribeSelf = useSubscribeSelf({ _subscriptionType: subscriptionType, subscription });
  const unsubscribeSelf = useUnsubscribeSelf({ _subscriptionType: subscriptionType, subscription });

  const onEdit = () => {
    setIsSubscriptionFormOpen(true);
  };

  const onClose = () => {
    setIsSubscriptionFormOpen(false);
  };

  const emailTargetsCount = subscription.providerTarget?.[NotificationProviderType.EMAIL]?.length || 0;
  const slackChannelsCount = subscription.providerTarget?.[NotificationProviderType.SLACK]?.length || 0;

  const noRecipients = !emailTargetsCount && !slackChannelsCount;
  const notificationSettings = subscription.selectedNotifications[subscriptionType as number];
  const scheduleText = getScheduleText({
    customPeriodAmount: notificationSettings.customPeriodAmount,
    customPeriodUnit: notificationSettings.customPeriodUnit,
    deliveryFrequency: notificationSettings.frequency,
    startDate: DateTime.fromMillis(notificationSettings.startDate.toMillis(), { zone: notificationSettings.timeZone }),
    time: DateTime.fromMillis(notificationSettings.time.toMillis(), { zone: notificationSettings.timeZone }),
    format: "short",
  });

  let sendToText: string;
  if (noRecipients) {
    sendToText = "No recipients";
  } else if (emailTargetsCount && slackChannelsCount) {
    sendToText = `${emailTargetsCount} email${emailTargetsCount > 1 ? "s" : ""}, ${slackChannelsCount} Slack channel${slackChannelsCount > 1 ? "s" : ""}`;
  } else if (emailTargetsCount) {
    sendToText = `${emailTargetsCount} email${emailTargetsCount > 1 ? "s" : ""}`;
  } else {
    sendToText = `${slackChannelsCount} Slack channel${slackChannelsCount > 1 ? "s" : ""}`;
  }

  const typographyMy = 0.5;
  return (
    <Card variant="outlined" sx={{ my: 2 }}>
      <CardContent>
        <Typography sx={{ my: typographyMy, fontWeight: 500, fontSize: "14px" }}>{scheduleText}</Typography>
        <Typography variant="caption" sx={{ my: typographyMy }}>
          {notificationSettings.timeZone}
          <br />
        </Typography>

        {noRecipients ? (
          <Typography variant="caption" sx={{ my: typographyMy }}>
            {sendToText}
          </Typography>
        ) : (
          <Typography variant="caption" sx={{ my: typographyMy }}>
            {"Sends to: "}
            <Typography component="span" variant="caption" color="textSecondary">
              {sendToText}
            </Typography>
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Stack direction="row" spacing={1} sx={{ ml: 1, mr: 1, mb: 2 }}>
          {(canSubscribe || isSubscribed) && (
            <Button
              variant="outlined"
              endIcon={isSubscribed ? <CheckCircleOutlineIcon /> : undefined}
              onClick={() => {
                isSubscribed ? unsubscribeSelf() : subscribeSelf();
              }}
            >
              {isSubscribed ? "Subscribed" : "Subscribe"}
            </Button>
          )}
          {allowedToEdit && (
            <Button variant="text" endIcon={<EditIcon />} onClick={onEdit}>
              Edit
            </Button>
          )}
        </Stack>
      </CardActions>
      {isSubscriptionFormOpen && (
        <SubscriptionForm
          isOpen={isSubscriptionFormOpen}
          onClose={onClose}
          closeFormDialog={onClose}
          subscriptionParent={subscriptionParent}
          subscriptionType={subscriptionType}
          mode="edit"
          subscription={subscription}
        />
      )}
    </Card>
  );
}
