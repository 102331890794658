import { Card, CardContent } from "@mui/material";

import { UpsellPreview } from "../Preview/UpsellPreview";

interface InsightsListRowUpsellProps {
  totalInsights: number;
  savings: number;
}

export function InsightsListRowUpsell({ totalInsights, savings }: InsightsListRowUpsellProps) {
  return (
    <Card variant="outlined">
      <CardContent sx={{ p: 1.5 }}>
        <UpsellPreview totalInsights={totalInsights} savings={savings} />
      </CardContent>
    </Card>
  );
}
