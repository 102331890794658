import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";

type Props = {
  value: string;
  onChange: (value: string) => void;
  options: string[];
};

const CompanySizeSelector = ({ value, onChange, options }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl component="fieldset">
      <Typography variant="h4" gutterBottom>
        How many people work in your organization?
      </Typography>
      <RadioGroup aria-label="company-size" name="company-size" value={value} onChange={handleChange} sx={{ pl: 1 }}>
        {options.map((option) => (
          <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CompanySizeSelector;
