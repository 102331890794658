import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 30,
  },
  {
    value: 40,
  },
  {
    value: 50,
  },
];

export default function DiscreteSlider({ handleChangeValue }) {
  const classes = useStyles();
  const [value, setValue] = useState(20);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    handleChangeValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" component="span">
        Reducing By:{" "}
      </Typography>
      <Typography
        style={{ fontWeight: 400 }}
        component="span"
        variant="subtitle2"
        id="discrete-slider-always"
        gutterBottom
      >
        {value}%
      </Typography>
      <Slider
        defaultValue={20}
        getAriaValueText={(value) => `${value}°C`}
        aria-labelledby="discrete-slider-always"
        step={10}
        min={10}
        max={50}
        marks={marks}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </div>
  );
}
