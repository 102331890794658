import { Box, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material";

import { type PurchaseViewType } from "../types";

type Props = {
  selectedView: PurchaseViewType;
  handleViewChange: (event: SelectChangeEvent) => void;
  disabled: boolean;
};

const PurchaseViewDropdown = ({ disabled, handleViewChange, selectedView }: Props) => (
  <Box>
    <FormControl disabled={disabled} sx={{ minWidth: "20em" }} size="small">
      <InputLabel htmlFor="select-multiple-native">View</InputLabel>
      <Select value={selectedView} onChange={handleViewChange} label="View">
        <MenuItem value="PurchasePlans">Purchase Plans</MenuItem>
        <MenuItem value="Workloads">All Workloads</MenuItem>
      </Select>
    </FormControl>
  </Box>
);

export default PurchaseViewDropdown;
