import "./reactRefresh"; // must be the first file

import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import AppIndex from "./AppIndex";
import { fullStoryInit } from "./utils/fullStoryInit";
import { sentryInit } from "./utils/sentryInit";
import { windowInit } from "./utils/windowInit";

fullStoryInit();
sentryInit();
windowInit();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <StrictMode>
    <AppIndex />
  </StrictMode>
);
