import { type CloudFlowNode } from "./nodeTransformUtils";

export function getPreviousNodesPath(currentNodeId: string, fetchedNodes: CloudFlowNode[]): CloudFlowNode[] {
  if (!fetchedNodes || fetchedNodes.length === 0) return [];

  const childToParentMap: Record<string, CloudFlowNode> = {};
  fetchedNodes.forEach((node) => {
    node.data?.transitions?.forEach((transition) => {
      childToParentMap[transition.targetNodeId] = node;
    });
  });

  const path: CloudFlowNode[] = [];
  let nodeId = currentNodeId;

  while (nodeId && childToParentMap[nodeId]) {
    const parentNode = childToParentMap[nodeId];
    path.unshift(parentNode);
    nodeId = parentNode.id;

    if (!parentNode) {
      break;
    }
  }

  return path;
}
