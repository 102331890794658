import { useCallback, useEffect, useMemo, useState } from "react";

import { type FlexsavePayerType, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { AlgoliaSearchClientContextProvider } from "../../Context/algoliaContext";
import { useFlexsavePayerConfigs } from "../Flexsave/Operations/AWS/hooks";
import NewMpaDialog from "./Components/NewMpaDialog";
import useSubscribedMasterPayerAccounts, { type CreateNewMpaResult } from "./hooks/useSubscribedMasterPayerAccounts";
import MpaHeaderCards from "./MPAHeaderCards/MpaHeaderCards";
import MpaTable from "./table/MpaTable";
import { buildMpaRowData, type MpaTableRowData } from "./table/MpaTableRow";

const MasterPayerAccount = () => {
  const [newMpaDialog, setNewMpaDialog] = useState<boolean>(false);
  const [availableMonths, setAvailableMonths] = useState<string[]>(() => {
    const now = DateTime.now().toUTC();
    const startOfMonth = now.day < 15 ? now.minus({ months: 1 }).startOf("month") : now.startOf("month");
    const months: string[] = [];
    months.push(startOfMonth.toFormat("yyyy-MM-dd"));
    return months;
  });
  const [selectedMonth, setSelectedMonth] = useState<string>(() => {
    const now = DateTime.now().toUTC();
    const startOfMonth = now.day < 15 ? now.minus({ months: 1 }).startOf("month") : now.startOf("month");
    return startOfMonth.toFormat("yyyy-MM-dd");
  });
  const showError = useErrorSnackbar();
  const { masterPayerAccounts, masterPayerAccountsLoading, createNewMpa } = useSubscribedMasterPayerAccounts();
  const { data: flexsaveConfigs, error } = useFlexsavePayerConfigs();

  useEffect(() => {
    if (!error) {
      return;
    }
    showError("Failed to fetch payer configs");
  }, [error, showError]);

  useEffect(() => {
    setAvailableMonths((prevAvailableMonths) => {
      const allMonths: string[] = masterPayerAccounts
        .map((mpa) => mpa.revenues)
        .flat(1)
        .reduce((acc: string[], revenue) => {
          const month = revenue?.month;
          if (month !== undefined) {
            acc.push(month);
          }
          return acc;
        }, []);

      return uniq([...prevAvailableMonths, ...allMonths])
        .sort()
        .reverse();
    });
  }, [masterPayerAccounts]);

  const handleShowHideDialog = (): void => {
    setNewMpaDialog(!newMpaDialog);
  };

  const submitAndClose = async (
    submitObject: Pick<
      MasterPayerAccountsModel,
      | "customerId"
      | "support"
      | "features"
      | "status"
      | "requestedBy"
      | "expectedOnboardingDate"
      | "domain"
      | "accountNumber"
    >
  ): Promise<CreateNewMpaResult> => {
    const response = await createNewMpa(submitObject);
    setNewMpaDialog(false);
    return response;
  };

  const successMessageRenderer = useCallback(
    ({ subprocessFailure }: CreateNewMpaResult) => subprocessFailure || "New MPA successfully created",
    []
  );

  const successVariantRenderer = useCallback(
    ({ subprocessFailure }: CreateNewMpaResult) => (subprocessFailure ? "warning" : "success"),
    []
  );

  const payerTypes: Record<string, FlexsavePayerType> = useMemo(() => {
    if (!flexsaveConfigs) {
      return {};
    }
    return flexsaveConfigs.reduce((acc, config) => {
      acc[config.accountId] = config.type;
      return acc;
    }, {});
  }, [flexsaveConfigs]);

  const items: MpaTableRowData[] = useMemo(
    () => masterPayerAccounts.map((mpaSnapshot) => buildMpaRowData(mpaSnapshot, payerTypes, selectedMonth)),
    [masterPayerAccounts, payerTypes, selectedMonth]
  );

  return (
    <AlgoliaSearchClientContextProvider>
      <Box>
        <MpaHeaderCards
          masterPayerAccounts={items}
          selectedMonth={selectedMonth}
          masterPayerAccountsLoading={masterPayerAccountsLoading}
        />
        <Box mt={2}>
          <MpaTable
            masterPayerAccounts={items}
            masterPayerAccountsLoading={masterPayerAccountsLoading}
            onNewMpaClicked={handleShowHideDialog}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            availableMonths={availableMonths}
          />
        </Box>
        <NewMpaDialog
          open={newMpaDialog}
          onCancel={handleShowHideDialog}
          onSubmit={submitAndClose}
          masterPayerAccounts={masterPayerAccounts}
          successMessage={successMessageRenderer}
          successVariant={successVariantRenderer}
          failMessage="Failed to create new MPA"
        />
      </Box>
    </AlgoliaSearchClientContextProvider>
  );
};

export default MasterPayerAccount;
