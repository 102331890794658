import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

const accountNumberExists = (function memoizedAccountNumberExists() {
  let lastAccountNumber: string;
  let lastExcludeId: string | null;
  let lastResult: boolean;
  return async (accountNumber: string, excludeId: string | null): Promise<boolean> => {
    if (accountNumber === lastAccountNumber && excludeId === lastExcludeId) {
      return lastResult;
    }
    const mpa = await getCollection(AppModel)
      .doc("master-payer-accounts")
      .collection("mpaAccounts")
      .where("accountNumber", "==", accountNumber)
      .get();
    lastAccountNumber = accountNumber;
    lastExcludeId = excludeId;
    lastResult = mpa.docs.some((doc) => doc.id !== excludeId);
    return lastResult;
  };
})();

export default accountNumberExists;
