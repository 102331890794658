import { type CurrencySymbol } from "@doitintl/cmp-models";
import type Highcharts from "highcharts";

import { type ThemeMode } from "../../../muiThemeTypes";
import { getColors } from "../../../Pages/CloudAnalytics/utilities";
import { roundWithCommas } from "../../../utils/common";
import { type ColumnsChartSeries, type ColumnValues } from "./ColumnsChart";

export function labelFormatter(value: string) {
  return value.length > 3 ? `${value.substring(0, 3)} ${value.split(" ")[1]}` : value;
}

export const currencyLabelFormatter = (value: number, currencySymbol: CurrencySymbol) => {
  const absVal = Math.abs(value);
  const oneMillion = 1e6;
  const oneThousand = 1e3;
  if (absVal >= oneMillion) {
    const num = Math.round((value / oneMillion) * 10) / 10;
    return `${currencySymbol}${num}M`;
  }
  if (absVal >= oneThousand) {
    const num = Math.round((value / oneThousand) * 10) / 10;
    return `${currencySymbol}${num}k`;
  }

  return `${currencySymbol}${Math.round(absVal * 10) / 10}`;
};

const tooltipFormatter = (contextObject: Highcharts.TooltipFormatterContextObject, currencySymbol: string) => {
  const parsePoint = (point: Highcharts.TooltipFormatterContextObject) =>
    `<span style="color:${point.color as string}">\u25CF</span> ${point.series.name}: <b>${currencySymbol}${roundWithCommas(
      point.y!
    )}</b><br/>`;

  if (contextObject.points?.length) {
    if (contextObject.points[0].y === 0 && (!contextObject.points[1] || contextObject.points[1].y === 0)) {
      return false;
    }
    let tooltipText = `<b> ${labelFormatter(contextObject.points[0].x!.toString())}</b><br/>`;

    tooltipText += parsePoint(contextObject.points[0]);

    if (contextObject.points.length < 2 || contextObject.points[1].y! <= 0) {
      return tooltipText;
    }

    for (let i = 1; i < contextObject.points.length; i++) {
      tooltipText += parsePoint(contextObject.points[i]);
    }

    const savings = contextObject.points[0].y! - contextObject.points[1].y!;

    if (savings !== 0) {
      tooltipText += `<span style="opacity: 0">\u2800</span> Savings: <b>${currencySymbol}${roundWithCommas(
        savings
      )}</b><br/>`;
    }
    return tooltipText;
  }
};

export function generateDefaultOptions(
  fontWeight: string,
  color: string,
  categories: string[],
  series: Highcharts.SeriesColumnOptions[],
  currencySymbol: CurrencySymbol,
  mode: ThemeMode
) {
  return {
    colors: getColors(mode),
    chart: {
      type: "column",
      backgroundColor: "transparent",
      maxHeight: `${(9 / 16) * 50}%`,
      spacingTop: 20,
      spacingLeft: 20,
      style: {
        fontWeight,
        fontFamily: "inherit",
      },
    },
    title: {
      text: "",
    },
    exporting: { enabled: false },
    xAxis: {
      labels: {
        style: {
          color,
        },
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          if (typeof this.value === "string") {
            return labelFormatter(this.value);
          }
        },
      },
      categories,
    },
    yAxis: {
      labels: {
        style: {
          color,
        },
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          if (typeof this.value === "number") {
            return currencyLabelFormatter(this.value, currencySymbol);
          }
        },
      },
      min: 0,
      title: {
        style: {
          color,
        },
        text: "",
      },
      stackLabels: {
        enabled: false,
        style: {
          color,
        },
      },
      style: {
        color,
      },
    },
    legend: {
      itemHoverStyle: {
        color: mode === "dark" ? "#E3E3E4" : color,
      },
      itemStyle: {
        color,
        fontWeight: "normal",
      },
    },
    tooltip: {
      outside: false,
      formatter: function formatter(this: Highcharts.TooltipFormatterContextObject) {
        return tooltipFormatter(this, currencySymbol);
      },
      shared: true,
      borderRadius: 10,
      borderWidth: 0,
      backgroundColor: "white",
      valueDecimals: 0,
    },
    plotOptions: {
      states: {
        inactive: {
          opacity: 1,
        },
      },
      series: {
        events: {
          legendItemClick: function legendItemClick() {
            return false;
          },
        },
        grouping: false,
        borderColor: "transparent",
        pointPadding: 0.1,
        pointPlacement: -0.2,
      },
      column: {
        shadow: false,
      },
    },
    series,
    credits: {
      enabled: false,
    },
  } as any;
}

function getColor(
  color: string,
  applyLinesPattern: boolean | undefined
): Highcharts.ColorType | Highcharts.PatternObject {
  if (applyLinesPattern) {
    return {
      color,
      pattern: {
        path: {
          d: "M 0 0 L 6 6 M 5 -1 L 7 1 M -1 5 L 1 7",
          stroke: color,
          strokeWidth: 2,
        },
        height: 6,
        width: 6,
        r: 1,
      },
    } as any;
  }

  return color;
}

/**
 * Generate Highcharts series
 * @param series
 */
export function groupDataToSeries(series: ColumnsChartSeries[][]): Highcharts.SeriesColumnOptions[] {
  const groupedSeries = series
    .map((groupSeries: ColumnsChartSeries[]) =>
      groupSeries.flatMap((singleSeries): Highcharts.SeriesColumnOptions[] => {
        const groupPadding = singleSeries.singleColumn ? 0.43 : 0.1;
        const grouping = true;

        return [
          {
            pointPadding: 0.2,
            pointPlacement: 0.1,
            grouping,
            name: singleSeries.legends[0],
            data: singleSeries.data.map((data: ColumnValues) => data[0]),
            color: getColor(singleSeries.colors[0], singleSeries.applyLinesPattern),
            groupPadding,
            type: "column",
          },
          {
            zIndex: 1,
            pointPadding: 0.2,
            pointPlacement: -0.2,
            grouping,
            name: singleSeries.legends[1],
            data: singleSeries.data.map((data: ColumnValues) => data[1]),
            color: getColor(singleSeries.colors[1], singleSeries.applyLinesPattern),
            groupPadding,
            type: "column",
          },
        ];
      })
    )
    .flat();

  return groupedSeries.flat();
}
