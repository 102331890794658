import { type MouseEvent } from "react";

import { ListItemButton, ListItemText } from "@mui/material";

type Props = {
  name: string;
  handleSelectSection: (name: string) => (event: MouseEvent<HTMLElement>) => void;
  bold?: boolean;
};

export const FilterSectionItem = ({ name, handleSelectSection, bold }: Props) => (
  <ListItemButton
    dense
    disableRipple
    onClick={handleSelectSection(name)}
    sx={(theme) => ({
      justifyContent: "space-between",
      pr: 1.5,
      background: bold ? theme.palette.action.hover : undefined,
    })}
  >
    <ListItemText
      primary={name}
      primaryTypographyProps={{ variant: "body2", fontWeight: bold ? "500" : "400", title: name }}
    />
  </ListItemButton>
);
