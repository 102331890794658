import { collection } from "@doitintl/models-types";

// TODO: check if this can be removed
@collection("gSlack")
export class GSlackModel {
  disabled!: boolean;

  test!: string;

  message!: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachments!: any[] | null;

  channel!: string;
}
