import { type FC, useEffect, useLayoutEffect, useRef, useState } from "react";

import { type Member } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { SafeHtml } from "../../../../Components/SafeHtml";
import { useToggle } from "../../../../utils/useToggle";

function toggleSetItem<T>(set: Set<T>, item: T): Set<T> {
  if (set.has(item)) {
    set.delete(item);
  } else {
    set.add(item);
  }
  return new Set(set);
}

const transformHtml = (node: Element, data: any) => {
  if (data.tagName === "p") {
    node.setAttribute("style", "margin: 0 0 8px");
  }
  if (data.tagName === "a") {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
  return node;
};

const ParameterDescription: FC<{ name: string; documentation?: string }> = ({ name, documentation }) => {
  const [expanded, setExpanded] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useLayoutEffect(() => {
    if (descriptionRef.current) {
      setIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
    }
  }, [documentation]);

  return (
    <Box flex={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" flex={1}>
          {name}
        </Typography>
        {isOverflowing && (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              setExpanded(!expanded);
            }}
            size="small"
            sx={(theme) => ({
              transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
              transition: theme.transitions.create("transform", {
                duration: theme.transitions.duration.shortest,
              }),
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Stack>
      {documentation !== undefined && (
        <Collapse in={expanded} collapsedSize="1.7em" ref={descriptionRef}>
          <Typography variant="caption" color="text.secondary">
            <SafeHtml html={documentation} transform={transformHtml} />
          </Typography>
        </Collapse>
      )}
    </Box>
  );
};

const AddParametersDialog: FC<{
  onClose: () => void;
  onMembersAdd: (membersToAdd: string[]) => void;
  members: Record<string, Member>;
  membersToAdd: string[];
}> = ({ onClose, onMembersAdd, members, membersToAdd }) => {
  const [filterValue, setFilterValue] = useState("");
  const [selectedMembers, setSelectedMembers] = useState(new Set<string>());
  const [filteredMembersToAdd, setFilteredMembersToAdd] = useState<string[]>([]);

  useEffect(() => {
    setFilteredMembersToAdd(membersToAdd.filter((memberName) => new RegExp(filterValue.trim(), "i").test(memberName)));
  }, [filterValue, membersToAdd]);

  return (
    <Dialog open fullWidth={true} maxWidth="sm">
      <DialogTitle>Add additional parameters</DialogTitle>
      <Divider />
      <DialogContent sx={{ display: "flex", flexDirection: "column", height: "70vh" }}>
        <Box mt={1} mb={2}>
          <TextField
            label="Filter available parameters"
            value={filterValue}
            onChange={({ target: { value } }) => {
              setFilterValue(value);
            }}
            fullWidth
          />
        </Box>
        <Stack spacing={1} sx={{ flex: 1, overflow: "hidden auto" }}>
          {filteredMembersToAdd.map((memberName) => (
            <Card key={memberName} variant="outlined" sx={{ flexShrink: 0 }}>
              <CardActionArea
                onClick={() => {
                  setSelectedMembers(toggleSetItem(selectedMembers, memberName));
                }}
              >
                <CardContent sx={{ padding: 1 }}>
                  <Stack direction="row" alignItems="flex-start" spacing={2}>
                    <Checkbox
                      inputProps={{ "aria-label": memberName }}
                      checked={selectedMembers.has(memberName)}
                      sx={{ marginTop: 1 }}
                    />
                    <ParameterDescription
                      name={memberName}
                      documentation={members[memberName].documentation || members[memberName].model.documentation}
                    />
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!selectedMembers.size}
          onClick={() => {
            onClose();
            onMembersAdd(Array.from(selectedMembers));
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const AddParametersButton: FC<{
  alreadyAdded: string[];
  label: string;
  members: Record<string, Member>;
  onMembersAdd: (membersToAdd: string[]) => void;
}> = ({ alreadyAdded, label, members, onMembersAdd }) => {
  const [membersToAdd, setMembersToAdd] = useState<string[]>([]);
  const [isOpened, open, close] = useToggle(false);

  useEffect(() => {
    setMembersToAdd(Object.keys(members).filter((memberToAdd) => !alreadyAdded.includes(memberToAdd)));
  }, [alreadyAdded, members]);

  return (
    <>
      {isOpened && (
        <AddParametersDialog
          members={members}
          membersToAdd={membersToAdd}
          onClose={close}
          onMembersAdd={onMembersAdd}
        />
      )}
      {membersToAdd.length > 0 && (
        <Button size="small" onClick={open} sx={{ alignSelf: "flex-start" }} startIcon={<AddIcon />}>
          Add additional {label} parameters
        </Button>
      )}
    </>
  );
};
