import { type JSX } from "react";

import CloseIcon from "@mui/icons-material/CloseRounded";
import { Alert, Grid, IconButton } from "@mui/material";

type Props = {
  setHidden: (hidden: boolean) => void;
  text: string;
  actions?: JSX.Element;
};

const Info = ({ setHidden, text, actions }: Props) => (
  <Grid container spacing={1} alignItems="flex-start" sx={{ pb: 1 }} data-testid="info" data-cy="info">
    <Grid item xs={12}>
      <Alert
        severity="info"
        action={[
          actions ? actions : <></>,
          <IconButton
            key="dismiss"
            aria-label="Dismiss"
            size="small"
            sx={{ ml: 1 }}
            onClick={() => {
              setHidden(true);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>,
        ]}
      >
        {text}
      </Alert>
    </Grid>
  </Grid>
);

export default Info;
