import {
  type CurrencyCode,
  CurrencyCodes,
  type CustomerModel,
  type EntityInvoicesModel,
  type EntityInvoicesModelRow,
  type EntityModel,
  type InvoiceItem,
  type InvoiceModel,
  type InvoiceProduct,
} from "@doitintl/cmp-models";
import { type ModelId, type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";
import { type DateTime } from "luxon";

import { asyncConvertCurrencyTo } from "../../Context/AsyncCurrencyConverterContext";
import { fnv1aHash } from "./utils";

export type Invoice = Partial<ModelId<InvoiceModel>> &
  Pick<
    InvoiceModel,
    | "CANCELED"
    | "CDES"
    | "CODE"
    | "CUSTNAME"
    | "DETAILS"
    | "INVOICEITEMS"
    | "IVDATE"
    | "IVDATE_STRING"
    | "IVNUM"
    | "PAID"
    | "PRODUCTS"
    | "QPRICE"
    | "TOTPRICE"
    | "SYMBOL"
    | "USDEXCH"
    | "customer"
    | "entity"
    | "PAYDATE"
  > & {
    isDraft: boolean;
  };

export type InvoiceFilters = {
  entityId?: string;
  products: InvoiceProduct[];
  startDate: DateTime;
  endDate: DateTime;
  showPaid?: boolean;
  pastPayDate?: boolean;
  status: InvoiceStatus[];
};

export type SetFilters = (filter: Partial<InvoiceFilters> | null) => void;

const getInvoiceQPrice = (invoice: EntityInvoicesModel, exchangeRate: number): number =>
  invoice.rows.reduce((acc, row) => acc + row.total, 0) * exchangeRate;

const mapEntityInvoicesModelRowToInvoiceItem = (row: EntityInvoicesModelRow, exchangeRate: number): InvoiceItem => ({
  DETAILS: row.details,
  EXCH: exchangeRate,
  ICODE: row.currency,
  PARTNAME: row.SKU,
  PDES: row.description,
  PERCENT: row.discount,
  PRICE: row.unitPrice,
  QPRICE: row.total * exchangeRate,
  QUANT: row.quantity,
  DISPRICE: (row.unitPrice * row.discount) / 100,
  SYMBOL: row.currency,
  TYPE: row.type as InvoiceProduct,
  USDEXCH: exchangeRate,
  DETAILSSUFFIX: row.detailsSuffix ?? "",
});

export const generateInvoiceNumber = (invoice: EntityInvoicesModel, entityId: string): string => {
  let bucketName = "";
  if (invoice.rows[0]?.description === "Invoice Bucket") {
    bucketName = invoice.rows[0].details;
  }

  let category = "";
  if (invoice.rows[0]?.category) {
    category = invoice.rows[0].category;
  }

  const hash = fnv1aHash(entityId + invoice.date.toString() + invoice.type + bucketName + category);

  const uniqueId = hash % 1000000000;

  return `PF${uniqueId}`;
};

export const asyncMapEntityInvoicesToInvoice = async (
  invoice: WithFirebaseModel<EntityInvoicesModel>,
  entityRef: ModelReference<EntityModel>,
  entity: WithFirebaseModel<EntityModel>
): Promise<Invoice> => {
  let currentExchangeRate = 1;

  try {
    currentExchangeRate = parseFloat(
      (await asyncConvertCurrencyTo(1, new Date(), invoice.currency, entity.currency as CurrencyCode)).toFixed(3)
    );
  } catch (e: any) {
    if (!/Rates of .+ for .+ not found, can't convert from .+/.test(e?.message)) {
      throw e;
    }

    entity.currency = CurrencyCodes.USD;
  }

  const invoiceNumber = generateInvoiceNumber(invoice, entityRef.id);

  return {
    id: invoiceNumber,
    CANCELED: Boolean(invoice.canceledAt),
    CDES: entity.name,
    CODE: entity.currency as CurrencyCode,
    CUSTNAME: entity.priorityId ?? "",
    DETAILS: invoice.details,
    INVOICEITEMS: invoice.rows.map((item) => mapEntityInvoicesModelRowToInvoiceItem(item, currentExchangeRate)),
    IVDATE: invoice.date,
    IVDATE_STRING: invoice.date.toString(),
    IVNUM: invoiceNumber,
    PAID: false,
    PRODUCTS: [invoice.type as InvoiceProduct],
    QPRICE: getInvoiceQPrice(invoice, currentExchangeRate),
    TOTPRICE: getInvoiceQPrice(invoice, currentExchangeRate),
    SYMBOL: entity.currency as CurrencyCode,
    USDEXCH: currentExchangeRate,
    customer: entity.customer as ModelReference<CustomerModel>,
    entity: entityRef,
    PAYDATE: invoice.date,
    isDraft: true,
  };
};

export type InvoiceStatus = "Paid" | "Partially Paid" | "Open" | "Past Due" | "Processing" | "Proforma" | "Canceled";
