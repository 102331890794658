import { useCallback } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type TypeOrUndefined } from "../../utils/typeOrUndefined";
import { useAsyncLoadingFunction } from "../hooks/useAsyncLoadingFunction";
import { type CustomerData } from "./CustomerPicker";

const getCustomerById = (customerId: string) => getCollection(CustomerModel).doc(customerId).get();

export function useCustomerFetch(): [boolean, (customerId: TypeOrUndefined<string>) => Promise<CustomerData | null>] {
  return useAsyncLoadingFunction(
    useCallback(async (customerId: TypeOrUndefined<string>) => {
      if (!customerId) {
        return null;
      }

      const customerRef = await getCustomerById(customerId);
      const customer = customerRef.asModelData();

      if (customer === undefined) {
        return null;
      }

      return {
        classification: customer.classification ?? null,
        description: customer.name,
        domains: customer.domains,
        industry: customer.enrichment.category?.industry ?? null,
        industryGroup: customer.enrichment.category?.industryGroup ?? null,
        logo: customer.enrichment.logo ?? null,
        name: customer.name,
        objectID: customerRef.id,
        primaryDomain: customer.primaryDomain,
        sector: customer.enrichment.category?.sector ?? null,
        subIndustry: customer.enrichment.category?.subIndustry ?? null,
        tags: customer.enrichment.tags ?? null,
        hasActiveBillingProfile: true,
      };
    }, [])
  );
}
