import { CustomStepper } from "./CustomStepper";
import { useMultistep } from "./hooks";

export const SolveOnboarding = () => {
  const { step, back, next, allowNext, isLastStep, stepsTitles, activeIndex, formId, loading } = useMultistep();

  return (
    <CustomStepper
      stepsTitles={stepsTitles}
      activeStepIndex={activeIndex}
      step={step}
      back={back}
      next={next}
      allowNext={allowNext}
      isLastStep={isLastStep}
      formId={formId}
      loading={loading}
    />
  );
};
