import { useCallback, useMemo } from "react";

import { useLocation } from "react-router-dom";
import { type CloudflowEntityModel } from "@doitintl/cmp-models";
import { Box, Typography } from "@mui/material";

import { cloudflowTexts } from "../../../assets/texts";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { type CloudflowExecutionSnap } from "../../../types/Cloudflow";
import { Comparators, type DefaultFilterColumns } from "../../../types/FilterTable";
import CloudflowHistoryRow from "./CloudflowHistoryRow";
import { filters, headerColumns } from "./HistoryColumns";

type CloudflowHistoryBrowserProps = {
  cloudflowExecutions?: CloudflowExecutionSnap[];
  loading: boolean;
  actions: {
    onNameClick: (link: string) => void;
  };
};

const CloudflowHistoryBrowser = ({
  cloudflowExecutions,
  loading,
  actions: { onNameClick },
}: CloudflowHistoryBrowserProps) => {
  const location = useLocation();

  const CloudflowHistoryRowWrapper = useCallback(
    ({ row }: { row: CloudflowExecutionSnap }) => (
      <CloudflowHistoryRow
        row={row}
        actions={{
          onNameClick,
        }}
      />
    ),
    [onNameClick]
  );

  const persistenceKey = location.state ? undefined : "cloudflows_v1_history";

  const defaultFilters: DefaultFilterColumns<CloudflowExecutionSnap> = useMemo(() => {
    try {
      if (!location.state) {
        return [];
      }

      const { state } = location;

      const data = state as Pick<CloudflowEntityModel, "name">;

      return [
        {
          column: filters[0],
          comparator: Comparators.EQUAL_TO,
          value: data.name,
          label: data.name,
        },
      ];
    } catch (e) {
      return [];
    }
  }, [location]);

  return loading ? (
    <Box p={1} mt={6}>
      <FilterTableSkeleton />
    </Box>
  ) : (
    <FilterTable<CloudflowExecutionSnap>
      tableItems={cloudflowExecutions}
      rowComponent={CloudflowHistoryRowWrapper}
      defaultFilters={defaultFilters}
      headerColumns={headerColumns}
      filterColumns={filters}
      filterBarPlaceholder={cloudflowTexts.FILTER_TABLE}
      persistenceKey={persistenceKey}
      emptyTableComponent={<>{cloudflowTexts.HISTORY.NOT_FOUND}</>}
      defaultSortDirection="desc"
      itemUniqIdentifierField="ref.id"
      defaultSortingColumnValue="data.startTime"
      toolbarProps={{
        title: <Typography variant="h4">{cloudflowTexts.CLOUDFLOW_HISTORY_COMPLETED}</Typography>,
      }}
    />
  );
};

export default CloudflowHistoryBrowser;
