import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, IconButton, Typography } from "@mui/material";

import { useUserContext } from "../../../Context/UserContext";
import { getDateTimeFromFirestoreTimestamp } from "../../../utils/common";

const GettingStartedAlert = () => {
  const { userModel } = useUserContext({ allowNull: false });
  const [open, setOpen] = useState<boolean>(false);

  if (!userModel) {
    return null;
  }

  const newUser = !userModel.prevLastLogin || (!!userModel.sessionCount && userModel.sessionCount < 3);

  // if prevLastLogin doesn't exist yet, it's because the user was already logged in and refreshed the page
  const infrequentUser = !userModel.prevLastLogin
    ? false
    : Math.abs(getDateTimeFromFirestoreTimestamp(userModel.prevLastLogin).toUTC().diffNow().as("days")) >= 30;

  return (
    <>
      {open && (newUser || infrequentUser) && (
        <Alert
          severity="info"
          sx={{ py: 0 }}
          action={
            <>
              <Button
                variant="text"
                color="inherit"
                sx={{ px: 0 }}
                onClick={() => window.open("https://app.livestorm.co/doit", "_blank")}
              >
                Join
              </Button>
              <IconButton
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        >
          <Typography variant="body2" component="span">
            Questions? Need help getting started? Join a live session on Navigator!
          </Typography>
        </Alert>
      )}
    </>
  );
};

export default GettingStartedAlert;
