import { type Dispatch, type SetStateAction, useMemo } from "react";

import { type Collaborators, type PublicAccess, Roles, type SlackChannel } from "@doitintl/cmp-models";
import { Autocomplete, Card, CardContent, CardHeader, Chip, Grid, TextField, Typography } from "@mui/material";
import { type AutocompleteChangeReason } from "@mui/material/useAutocomplete";
import uniq from "lodash/uniq";
import { string as YupString } from "yup";

import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { SlackChannelSelection } from "../../../Components/Slack/ChannelSelection";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type DraftBudget } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { checkAllowedSubdomains } from "../utilities";

type Props = {
  classes;
  setShareDialogOpen: Dispatch<SetStateAction<boolean>>;
  recipients: string[];
  recipientsSlackChannels: SlackChannel[];
  setRecipients: Dispatch<SetStateAction<string[]>>;
  setRecipientsSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>;
  budget: DraftBudget;
  isCurrentUserEditor: boolean;
  isCurrentUserUserManager: boolean;
  handleChangeSharing: (
    collaborators: Collaborators,
    publicAccess: PublicAccess,
    newRecipients?: string[],
    newRecipientsSlackChannels?: SlackChannel[]
  ) => void;
  shareLoading: boolean;
  draft: boolean;
};

const BudgetSharing = ({
  classes,
  setShareDialogOpen,
  budget,
  recipients,
  recipientsSlackChannels,
  setRecipients,
  setRecipientsSlackChannels,
  isCurrentUserEditor,
  handleChangeSharing,
  isCurrentUserUserManager,
  shareLoading,
  draft,
}: Props) => {
  const { customer } = useCustomerContext();
  const { invites, userEmails } = useAnalyticsUsers();
  const options = useMemo(
    () => uniq([...userEmails, ...invites, ...budget.data.collaborators.map((c) => c.email)]),
    [budget.data.collaborators, userEmails, invites]
  );

  return (
    <Card>
      <CardHeader title="Alerts" titleTypographyProps={{ variant: "subtitle2" }} />
      <CardContent className={classes.headerCardContent}>
        <Typography className={classes.header} color="inherit" variant="body2">
          Send email alerts to the following accounts when thresholds are met
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={options}
              freeSolo
              filterSelectedOptions
              value={recipients.map((r) => r)}
              onChange={async (event, value, reason: AutocompleteChangeReason) => {
                switch (reason) {
                  case "clear":
                  case "removeOption": {
                    if ((event as any).key === "Backspace") {
                      return;
                    }
                    setRecipients(value);
                    handleChangeSharing(budget.data.collaborators, budget.data.public, value);
                    break;
                  }
                  case "blur":
                  case "createOption": {
                    const addedValue = value[value.length - 1];
                    try {
                      await YupString().email().validate(addedValue);
                    } catch (error) {
                      // the added value is not a valid email address
                      return;
                    }
                    const domain = addedValue.split("@")[1];
                    const isAllowedDomain = checkAllowedSubdomains(addedValue);
                    /* Allow creating option:
                      1. doit email
                      2. A slack or teams subdomain (allowed subdomains)
                      3. The customer's domains/subdomains, and the user has Users manager permission
                    */
                    if (isAllowedDomain || (isCurrentUserUserManager && customer.domains.includes(domain))) {
                      if (isAllowedDomain) {
                        setRecipients(value);
                        try {
                          handleChangeSharing(budget.data.collaborators, budget.data.public, value);
                        } catch (e) {
                          consoleErrorWithSentry(e);
                        }
                      } else if (budget.data.collaborators.findIndex((c) => c.email === addedValue) === -1) {
                        setRecipients(value);
                        if (budget.data.public) {
                          const newCollaborator = {
                            email: addedValue,
                            role: Roles.VIEWER,
                          };
                          try {
                            handleChangeSharing(
                              [...budget.data.collaborators, newCollaborator],
                              budget.data.public,
                              value
                            );
                          } catch (e) {
                            consoleErrorWithSentry(e);
                          }
                        } else {
                          setShareDialogOpen(true);
                        }
                      }
                    }
                    break;
                  }
                  case "selectOption": {
                    setRecipients(value);
                    const newRecipients = value.filter((r) => !budget.data.collaborators.find((c) => c.email === r));
                    if (newRecipients.length && !budget.data.public) {
                      setShareDialogOpen(true);
                    } else {
                      const newCollaborators = newRecipients.map((r) => ({
                        email: r,
                        role: Roles.VIEWER,
                      }));
                      handleChangeSharing(
                        [...budget.data.collaborators, ...newCollaborators],
                        budget.data.public,
                        value
                      );
                    }
                    break;
                  }
                }
              }}
              disabled={draft || !isCurrentUserEditor || shareLoading}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={option}
                    color="primary"
                    {...getTagProps({ index })}
                    key={index}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Send alerts to" fullWidth size="small" />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <SlackChannelSelection
              customerId={customer.id}
              text="Send slack alerts to the following channels when thresholds are met"
              disabled={draft || !isCurrentUserEditor || shareLoading}
              channels={recipientsSlackChannels}
              setChannels={setRecipientsSlackChannels}
              updateSelection={(selection) => {
                handleChangeSharing(budget.data.collaborators, budget.data.public, recipients, selection);
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BudgetSharing;
