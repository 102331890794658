import { type DocumentData, type SnapshotListenOptions } from "firebase/firestore";

import { type FirebaseQueryModel, type GetSnapshotOptions } from "../core";

export type UseFirestoreHookOptions =
  | ({
      subscribe: true;
    } & SnapshotListenOptions)
  | ({
      subscribe?: false;
    } & GetSnapshotOptions);

export type NamedQueryPromise<TModel extends DocumentData> = () => Promise<FirebaseQueryModel<TModel> | null>;

export type QueryType<TModel extends DocumentData> = FirebaseQueryModel<TModel> | NamedQueryPromise<TModel>;

function isNamedQuery<TModel extends DocumentData>(query: QueryType<TModel>): query is NamedQueryPromise<TModel> {
  return typeof query === "function";
}

export async function resolveQuery<TModel extends DocumentData>(
  query: QueryType<TModel> | undefined
): Promise<FirebaseQueryModel<TModel> | undefined> {
  if (!query) {
    return;
  }

  if (isNamedQuery(query)) {
    if (typeof query === "function") {
      // Firebase throws an error if the query doesn't exist.
      const resolved = await query();
      return resolved!;
    }

    return query;
  }

  return query;
}

export * from "./useFirestoreCollection";
