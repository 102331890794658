import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";

import { treemapText } from "../../../../assets/texts";

type TreemapConfirmDialogTypes = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  cols: string[];
};

const TreemapConfirmDialog = ({ open, onClose, onConfirm, cols }: TreemapConfirmDialogTypes) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Show treemap</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {treemapText.TREEMAP_CONFIRM_DIALOG}
        <br />
        <br />
        &nbsp;- Revert the aggregation to a “Total”
        <br />
        &nbsp;- Hide Trends and Forecast from this report
        <br />
        {!!cols.length && (
          <>
            &nbsp;- Remove {cols.join(", ")} {cols.length > 1 ? "columns" : "column"}
            <br />
          </>
        )}
        &nbsp;- Remove comparative configuration
      </DialogContentText>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={onClose} variant="text">
        Cancel
      </Button>
      <Button onClick={onConfirm} color="primary" variant="contained">
        Switch to treemap
      </Button>
    </DialogActions>
  </Dialog>
);

export default TreemapConfirmDialog;
