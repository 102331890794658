import { useMemo } from "react";

import { createTheme, useMediaQuery } from "@mui/material";

import { useAuthContext } from "../../Context/AuthContext";
import { useFullScreen } from "../../utils/dialog";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1150,
      lg: 1350,
      xl: 1920,
    },
  },
});

/*
small - shows mobile side menu without additionalOptions
smallWithOptions - shows mobile side menu (without What's new & settings) and with additionalOptions
compact - shows style in full screen more dense
full - shows doit logo
*/
export type NavigationVariant = "small" | "smallWithOptions" | "compact" | "full";

export function useNavigationVariant(): NavigationVariant {
  const { isDoitEmployee } = useAuthContext();

  const { isMobile: isSmall } = useFullScreen();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallWithOptions = useMediaQuery(theme.breakpoints.between(769, 1115));
  return useMemo(() => {
    if (isSmall) {
      return "small";
    } else if (isSmallWithOptions) {
      return "smallWithOptions";
    } else if (isMediumScreen) {
      return isDoitEmployee ? "compact" : "full";
    } else {
      return "full";
    }
  }, [isDoitEmployee, isSmall, isMediumScreen, isSmallWithOptions]);
}
