import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";

import DoitLogo from "../../../../assets/doit-logo-hero-square.svg";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { domainConfig } from "../../../../domainConfig";
import { CSPCustomerID } from "../../../../utils/common";
import StartFreeTrialButton from "../../../StartTrialButton/StartTrialButton";

export const FreeTrialCard = ({ callback }: { callback?: () => void }) => {
  const { customer } = useCustomerContext();

  if (customer.id === CSPCustomerID) {
    return null;
  }

  return (
    <Card data-testid="start-free-trial-card">
      <Stack divider={<Divider />}>
        <Typography variant="h4" data-cy="title" sx={{ my: 2, ml: 3 }}>
          <Box component="img" src={DoitLogo} sx={{ height: "32px", width: "32px", mb: -1.23, mr: 1 }} />
          Start a free trial with your own data
        </Typography>
        <Typography mx={3} mt={3}>
          {`Connect your cloud and use the DoiT Console for free for ${customer.tiers?.navigator?.trialCustomLength || 45} days`}
        </Typography>
      </Stack>
      <Stack ml={3} my={3} justifyContent="flex-start" direction="row" spacing={2}>
        <StartFreeTrialButton onClick={callback} />
        <Button
          variant="text"
          href={domainConfig.urls.navigatorSubscriptionURL}
          startIcon={<OpenInNewIcon />}
          target="_blank"
        >
          Learn more
        </Button>
      </Stack>
    </Card>
  );
};
