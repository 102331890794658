import { Alert, AlertTitle, Stack, Typography } from "@mui/material";

const RequiredRolesAlert = () => (
  <Alert severity="info">
    <AlertTitle>Reminder of required roles</AlertTitle>
    <Stack ml={1}>
      <Typography>
        • On either the billing account directly or on the organization if billing account is in the same org:{" "}
      </Typography>
      <Typography ml={2}>• Billing Account Administrator (roles/billing.admin)</Typography>
      <Typography>• One of the following roles for the dataset containing billing:</Typography>
      <Typography ml={2}>• Owner (roles/owner)</Typography>
      <Typography ml={2}>• BigQuery Admin (roles/bigquery.admin)</Typography>
      <Typography ml={2}>• BigQuery Data Owner (roles/bigquery.dataOwner)</Typography>
    </Stack>
  </Alert>
);

export default RequiredRolesAlert;
