import { type ChangeEvent, useState } from "react";

import { AssetSettingModel, EntityModel } from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { globalText } from "../../../assets/texts";
import { useEntitiesOrPresentationModeEntities } from "../../../Components/hooks/useEntitiesOrPrenstationModeEntities";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Asset } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { getCurrencyByCode } from "../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";

type Props = {
  onClose: () => void;
  asset: Asset;
};

const AssignEntityDialog = ({ onClose, asset }: Props) => {
  const { entities } = useEntitiesOrPresentationModeEntities();
  const { isMobile: matches } = useFullScreen();
  const sharedSnackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [entity, setEntity] = useState(asset.data.entity ? asset.data.entity.id : "");

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEntity(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const entityRef = getCollection(EntityModel).doc(entity);
      const batch = getBatch();
      batch.update(getCollection(AssetSettingModel).doc(asset.id), {
        entity: entityRef,
        bucket: null,
        contract: null,
      });
      batch.update(asset.ref, {
        entity: entityRef,
        bucket: null,
        contract: null,
      });

      await batch.commit();
      sharedSnackbar.onOpen({
        message: "Billing profile assigned successfully",
        variant: "success",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      onClose();
    } catch (error) {
      sharedSnackbar.onOpen({
        message: "Failed to assign billing profile",
        variant: "error",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="assign-entity-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={matches}
      maxWidth="sm"
      fullWidth
      data-cy="assignEntity"
    >
      <DialogTitle id="assign-entity-dialog-title">Billing profile assignment</DialogTitle>

      <DialogContent>
        <TextField
          select
          label="Billing profile"
          value={entity}
          onChange={handleChange}
          disabled={loading}
          margin="normal"
          variant="outlined"
          fullWidth
          data-cy="entity-select"
        >
          {entities.map((e) => (
            <MenuItem key={e.id} value={e.id} disabled={!e.active}>
              {`${e.priorityId} (${getCurrencyByCode(e.currency)}) - ${e.name}`}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose} disabled={loading} data-cy="cancelBtn">
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          disabled={loading}
          loading={loading}
          data-cy="saveBtn"
          mixpanelEventId="assets.assign-entity.save"
        >
          {globalText.SAVE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignEntityDialog;
