import { useState } from "react";

import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Alert, AlertTitle, Stack, TextField, Typography } from "@mui/material";

import { useApiContext } from "../../../../api/context";
import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import LoadingButton from "../../../../Components/LoadingButton";
import { mixpanelTrack } from "../../../../utils/mixpanel";
import { NumberedList } from "../../../Flexsave/Common/NumberedList";
import { UsefulAzureLinks } from "./UsefulAzureLinks";
import { containerName, directoryName, isValidAzureSubscriptionId, resourceGroupName } from "./utils";

type Props = {
  storageAccountName: string;
  subscriptionId: string;
  setSubscriptionId: (subscriptionId: string) => void;
  wasTestSuccessful: boolean;
  setWasTestSuccessful: (wasTestSuccessful: boolean) => void;
};

export default function StepTestConnection({
  storageAccountName,
  subscriptionId,
  setSubscriptionId,
  wasTestSuccessful: wasTestSuccessful,
  setWasTestSuccessful: setWasTestSuccessful,
}: Props) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const api = useApiContext();
  const customerId = useCustomerId();

  const testConnection = async () => {
    setLoading(true);
    mixpanelTrack("saas.deploy.test.azure.connection.click", { cloud: SaaSConsoleType.AZURE });

    try {
      const response = await api.post(`v1/customers/${customerId}/azure/store-config`, {
        subscriptionId,
        account: storageAccountName,
        container: containerName,
        resourceGroup: resourceGroupName,
        directory: directoryName,
      });

      if (response.status === 200) {
        setError(true);
        setSuccess(true);
        setWasTestSuccessful(true);

        mixpanelTrack("saas.deploy.test.azure.connection.success", { cloud: SaaSConsoleType.AZURE });
      } else {
        setSuccess(false);
        setError(true);
      }
    } catch (e: any) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={2} data-cy="test-connection-step">
      <Typography variant="h1">Test your Azure connection with DoiT</Typography>
      <Stack spacing={2} pt={2} pb={2}>
        <NumberedList
          list={[
            <Stack key={0} mb={3}>
              <Typography mb={2}>Enter Azure subscription ID, where the deployment was created</Typography>
              <TextField
                disabled={wasTestSuccessful}
                data-cy="subscription-id-input"
                variant="outlined"
                label="Subscription ID"
                value={subscriptionId}
                onChange={(e) => {
                  setSubscriptionId(e.target.value.trim());
                }}
                required
                size="medium"
                error={subscriptionId.length !== 0 && !isValidAzureSubscriptionId(subscriptionId)}
                helperText="You can find this in the list of all the subscriptions in the Azure console"
              />
            </Stack>,

            <Stack key={1} alignItems="flex-start">
              <Typography mb={2}>
                To make sure the setup is successful, we need to test the connection. This should take a couple of
                seconds.
              </Typography>
              <LoadingButton
                data-cy="test-connection-button"
                mixpanelEventId="Test Azure Connection"
                loading={loading}
                disabled={!isValidAzureSubscriptionId(subscriptionId) || wasTestSuccessful}
                variant="outlined"
                onClick={testConnection}
              >
                Test connection
              </LoadingButton>
            </Stack>,
          ]}
        />
      </Stack>

      {success ? (
        <Stack alignItems="flex-start">
          <Alert severity="success" data-cy="test-connection-success" sx={{ width: "100%" }}>
            <AlertTitle>Test successful</AlertTitle>
            You have successfully connected your Azure account and we will start importing your billing data.
          </Alert>
        </Stack>
      ) : error ? (
        <Alert severity="warning" data-cy="test-connection-error">
          The export may need up to 5 minutes to be available. If you just completed the deployment, try again in a few
          minutes.
        </Alert>
      ) : null}

      <UsefulAzureLinks azureLink="https://learn.microsoft.com/en-us/azure/azure-resource-manager/templates/quickstart-create-templates-use-the-portal#edit-and-deploy-the-template" />
    </Stack>
  );
}
