import CloseIcon from "@mui/icons-material/Close";
import { Snackbar } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { SharedSnackbarConsumer } from "./SharedSnackbar.context";
import SharedSnackbarContent from "./SharedSnackbarContent";
import { type SharedSnackbarProps } from "./types";

export const snackbarCloseButton = (onClose) => (
  <IconButton key="close" aria-label="Close" size="small" color="inherit" onClick={onClose} sx={{ ml: 1 }}>
    <CloseIcon />
  </IconButton>
);

const SharedSnackbar = ({
  open,
  message,
  anchorOrigin,
  autoHideDuration,
  action,
  withClose,
  TransitionProps,
  variant,
  ...otherProps
}: SharedSnackbarProps) => (
  <SharedSnackbarConsumer>
    {({ onClose }) => {
      const closeAction = withClose ? snackbarCloseButton(onClose) : [];
      return (
        <Snackbar
          open={open}
          anchorOrigin={anchorOrigin}
          autoHideDuration={autoHideDuration}
          onClose={onClose}
          TransitionProps={{ ...TransitionProps, unmountOnExit: true }}
        >
          <div>
            <SharedSnackbarContent
              aria-describedby="client-snackbar"
              withIcon={!!variant}
              severity={variant}
              message={message}
              action={action ? [action, closeAction] : closeAction}
              {...otherProps}
            />
          </div>
        </Snackbar>
      );
    }}
  </SharedSnackbarConsumer>
);

export default SharedSnackbar;
