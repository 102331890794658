import { defaultHeaderStyle } from "../Components/FilterTable/Table/FilterTableHeader";
import { type NestedObjectLeaves } from "../utils/NestedKeyOf";

export type DataOption =
  | {
      value: any;
      label?: string;
      count?: number;
    }
  | {
      value: any;
      label?: string;
      count?: number;
    }[]
  | undefined;

export type DataColumn<T = any> = {
  label: string;
  path: NestedObjectLeaves<T>;
  type?: string;
  comparators?: readonly Comparator[];
  ignoreInGeneralSearch?: boolean;
  placeholder?: string;
  transform?: (value: any) => any;
  transformData?: (value: any) => string | string[];
  validate?: (value: any) => boolean;
  toOption?: (value: any) => DataOption;
  allowedOnlyOnce?: boolean;
};

export type TableFilters = Array<{
  label: string;
  value: string;
  complete: boolean;
  column: {
    path?: string;
  };
}>;

export type OnFilter<T> = (
  filterFunction?: (items: T[]) => T[]
) => void | ((filters: TableFilters, resetFilter: boolean) => void);

export type DataColumns<T> = readonly DataColumn<T>[];

export const Operators = ["AND", "OR"] as const;
export type LogicalOperator = (typeof Operators)[number];

export type HeaderColumnStyle = {
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
  width?: string;
};

type ScreenSize = "xsDown" | "smDown" | "mdDown" | "lgDown" | "xlDown";

type ColumnHiddenState = Partial<Record<ScreenSize, boolean>>;

export type HeaderColumn<T = any> = {
  value: NestedObjectLeaves<T> | "@";
  label?: string;
  tooltip?: string;
  hidden?: ColumnHiddenState;
  style?: HeaderColumnStyle;
  // hide column by default in reorder columns dialog
  hiddenByDefault?: boolean;
  disableSort?: boolean;
  // prevent selection in reorder columns dialog
  preventSelection?: boolean;
};

export type HeaderColumns<T> = readonly HeaderColumn<T>[];

/**
 * Comparators for filter tables.
 */
export enum Comparators {
  CONTAINS = "contains",
  EQUAL_TO = "==",
  GREATER_THAN = ">",
  GREATER_THAN_OR_EQUAL_TO = ">=",
  LESS_THAN = "<",
  LESS_THAN_OR_EQUAL_TO = "<=",
  NOT_EQUAL_TO = "!=",
}

export type Comparator = `${Comparators}`;

/**
 * Comparators that are useful for dealing with string fields.
 * It is also useful for comparing JSX fields with the right `toOption` conversion.
 */
export const stringComparators: Comparator[] = [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO, Comparators.CONTAINS];

/**
 * Comparators that are useful for dealing with numeric fields.
 * This can also be used for date and DateTime fields.
 */
export const numberComparators: Comparator[] = [
  Comparators.EQUAL_TO,
  Comparators.NOT_EQUAL_TO,
  Comparators.GREATER_THAN,
  Comparators.GREATER_THAN_OR_EQUAL_TO,
  Comparators.LESS_THAN,
  Comparators.LESS_THAN_OR_EQUAL_TO,
];

// map affected columns' "path" attribute to their hidden settings.
export type ColumnHeaderHiddenMap<T> = Partial<Record<NestedObjectLeaves<T>, ColumnHiddenState>>;

export const headerFromDataColumn = <T>(
  dataColumn: DataColumn<T>,
  headerStyle?: HeaderColumnStyle,
  hiddenMap?: ColumnHeaderHiddenMap<T>
): HeaderColumn<T> => ({
  label: dataColumn.label,
  value: dataColumn.path,
  style: headerStyle ?? defaultHeaderStyle,
  hidden: hiddenMap ? (hiddenMap[dataColumn.path] ?? undefined) : undefined,
});

export type DefaultFilterColumn<T> = {
  column?: {
    label: string;
    path?: NestedObjectLeaves<T>;
    toOption?: (d: any) => any;
    transformData?: (d: any) => string | string[];
  };
  comparator?: Comparator;
  value: any;
  label: string;
  complete?: boolean;
};

export type DefaultFilterColumns<T> = readonly (DefaultFilterColumn<T> | LogicalOperator)[];

export const getDefaultValue = <T>(defaultValue: DefaultFilterColumns<T>) =>
  (defaultValue ?? []).map((v) => (typeof v !== "string" ? { ...v, complete: true } : v));

export type SideFiltersHook<T> = {
  selectedByValue: Record<string, boolean>;
  checkBoxHandler: ({
    column,
    option,
  }: {
    column: DataColumn<T>;
    option: { value: string; label?: string; count?: number };
  }) => (_: any, isSelected: boolean) => void;
};

export type SideFiltersData<T> = {
  column: DataColumn<T>;
  title: string;
  items: { value: string; label?: string; count?: number }[];
  showInputFilter?: boolean;
};
