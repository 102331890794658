import { Button } from "@mui/material";

import contractHooks from "../../../../hooks";
import { useContractFormContext } from "../../../ContractsFormContext";

export const UndoCancellationButton = () => {
  const { state } = useContractFormContext();
  const updateContractStatus = contractHooks.useUpdateContractStatus(state);

  return (
    <Button
      data-cy="undo-cancel-contract-button"
      variant="outlined"
      color="primary"
      onClick={() => updateContractStatus(true, false)}
    >
      Undo cancellation
    </Button>
  );
};
