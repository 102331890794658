import { useMemo } from "react";

import MarketplaceGCPAccounts from "./Accounts/AccountsTable";
import MarketplaceGCPAdjustments from "./Adjustments/AdjustmentsTable";
import MarketplaceGCPEntitlements from "./Entitlements/EntitlementsTable";
import { useMarketplaceAccounts } from "./hooks/useMarketplaceAccounts";
import { useMarketplaceAdjustments } from "./hooks/useMarketplaceAdjustments";
import { useMarketplaceCustomers } from "./hooks/useMarketplaceCustomers";
import { useMarketplaceEntitlements } from "./hooks/useMarketplaceEntitlements";
import { useMarketplacePlanHistory } from "./hooks/useMarketplacePlanHistory";
import { MarketplaceContext } from "./MarketplaceContext";

const Marketplace = ({ pageId }) => {
  const [customersIdsMap, customersLoading] = useMarketplaceCustomers();
  const [accounts, accountsNameMap, accountsLoading] = useMarketplaceAccounts(customersIdsMap);
  const [entitlements, entitlementsLoading] = useMarketplaceEntitlements(customersIdsMap, accountsNameMap, accounts);
  const [planHistory] = useMarketplacePlanHistory(entitlements);
  const [adjustments, adjustmentsLoading] = useMarketplaceAdjustments(customersIdsMap);

  const context = useMemo(
    () => ({
      accounts,
      accountsNameMap,
      customersIdsMap,
      entitlements,
      adjustments,
      planHistory,
    }),
    [accounts, accountsNameMap, customersIdsMap, entitlements, adjustments, planHistory]
  );

  if (customersLoading || accountsLoading || entitlementsLoading || adjustmentsLoading) {
    return null;
  }

  return (
    <MarketplaceContext.Provider value={context}>
      {pageId === "marketplace:gcp:accounts" && <MarketplaceGCPAccounts />}
      {pageId === "marketplace:gcp:adjustments" && <MarketplaceGCPAdjustments />}
      {pageId === "marketplace:gcp:entitlements" && <MarketplaceGCPEntitlements />}
    </MarketplaceContext.Provider>
  );
};

export default Marketplace;
