import { useEffect, useState } from "react";

import { type BillingStandaloneOnboardingAWSModel, SaaSConsoleType } from "@doitintl/cmp-models";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { consoleErrorWithSentry } from "../../../../utils";
import { onboardingDocRef } from "../utils";

export function useSaaSConsoleAWSOnboarding(initCompleted?: boolean) {
  const customerId = useCustomerId();
  const [onboardingDoc, setOnboardingDoc] = useState<BillingStandaloneOnboardingAWSModel | undefined>(undefined);

  // fetch the existing data for the customer
  useEffect(() => {
    const readAWSOnboardingDoc = async () => {
      try {
        const docSnap = await onboardingDocRef(customerId, SaaSConsoleType.AWS).get();
        if (docSnap.exists()) {
          const data = docSnap.data() as BillingStandaloneOnboardingAWSModel;
          setOnboardingDoc(data);
        }
      } catch (e) {
        consoleErrorWithSentry(e);
      }
    };
    readAWSOnboardingDoc();
  }, [customerId]);

  useEffect(() => {
    if (initCompleted) {
      const onSnapshotListener = onboardingDocRef(customerId, SaaSConsoleType.AWS).onSnapshot((docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data() as BillingStandaloneOnboardingAWSModel;
          setOnboardingDoc(data);
        }
      });

      return () => {
        onSnapshotListener();
      };
    }
  }, [customerId, initCompleted]);

  return onboardingDoc;
}
