import { useCallback, useState } from "react";

import { useApiContext } from "../../../../api/context";
import { marketplaceText } from "../../../../assets/texts";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import ApproveOrRejectDialog, { type DialogMode } from "../components/ApproveOrRejectDialog";
import { type EntitlementConfig } from "../hooks/useMarketplaceEntitlements";
import { useMarketplaceContext } from "../MarketplaceContext";
import { filterColumns, headerColumns } from "./columns";
import { EntitlementRow } from "./EntitlementRow";

const entitlementsUrl = "/v1/marketplace/gcp/entitlements";

const MarketplaceGCPEntitlements = () => {
  const { entitlements } = useMarketplaceContext();

  const [clickedRow, setClickedRow] = useState<EntitlementConfig | null>(null);
  const [dialogMode, setDialogMode] = useState<DialogMode>(null);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const api = useApiContext();

  const handleApproveEntitlement = useCallback(
    (row: EntitlementConfig | null) => async () => {
      if (row === null) {
        return;
      }

      try {
        await api.request({
          method: "POST",
          url: `${entitlementsUrl}/${row.ref.id}/approve`,
        });
        successSnackbar(marketplaceText.ENTITLEMENT_APPROVED_SUCCESSFULLY);
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(marketplaceText.ENTITLEMENT_APPROVE_ERROR);
      }
    },
    [api, errorSnackbar, successSnackbar]
  );

  const handleRejectEntitlement = useCallback(
    (row: EntitlementConfig | null) => async () => {
      if (row === null) {
        return;
      }

      try {
        await api.request({
          method: "POST",
          url: `${entitlementsUrl}/${row.ref.id}/reject`,
        });
        successSnackbar(marketplaceText.ENTITLEMENT_REJECTED_SUCCESSFULLY);
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(marketplaceText.ENTITLEMENT_REJECT_ERROR);
      }
    },
    [api, errorSnackbar, successSnackbar]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogMode(null);
    setClickedRow(null);
  }, []);

  const entitlementRowWrapper = useCallback(
    ({ row }: { row: EntitlementConfig }) => (
      <EntitlementRow setDialogMode={setDialogMode} setClickedRow={setClickedRow} row={row} />
    ),
    []
  );

  return (
    <>
      <FilterTable<EntitlementConfig>
        defaultSortingColumnValue="transform.updateTime"
        filterBarPlaceholder={marketplaceText.FILTER_ENTITLEMENTS}
        filterColumns={filterColumns}
        headerColumns={headerColumns}
        itemUniqIdentifierField="ref.id"
        persistenceKey="entitlement-gcp-marketplace"
        rowComponent={entitlementRowWrapper}
        wrapRowComponent={false}
        tableItems={entitlements || []}
      />
      <ApproveOrRejectDialog
        resourceType="entitlement"
        dialogMode={dialogMode}
        handleApprove={handleApproveEntitlement(clickedRow)}
        handleReject={handleRejectEntitlement(clickedRow)}
        handleClose={handleCloseDialog}
      />
    </>
  );
};

export default MarketplaceGCPEntitlements;
