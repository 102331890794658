import { statusesTexts } from "../../../assets/texts";

export enum ReportStatusEnum {
  aws = "amazon-web-services",
  gcp = "google-cloud",
}

export const StatusOptions = {
  [ReportStatusEnum.aws]: statusesTexts.aws,
  [ReportStatusEnum.gcp]: statusesTexts.gcp,
};
