import { useCallback, useEffect } from "react";

import { useAuthContext } from "../../Context/AuthContext";
import { paths } from "../../Pages/Login/helpers";

export const DeveloperDocs = () => {
  const { tokenValue } = useAuthContext();
  const getDeveloperDocsUrl = useCallback(() => `${paths.authReadme}?token=${tokenValue}`, [tokenValue]);

  useEffect(() => {
    if (!tokenValue) {
      return;
    }

    window.location.replace(getDeveloperDocsUrl());
  }, [getDeveloperDocsUrl, tokenValue]);

  return null;
};
