import { type CurrencySymbol, type MonthSavings } from "@doitintl/cmp-models";
import { type Theme } from "@mui/system";

import {
  type ColumnsChartData,
  type ColumnsChartSeries,
  type ColumnValues,
} from "../../../../../Components/Charts/ColumnsChat/ColumnsChart";
import {
  generateDefaultOptions,
  groupDataToSeries,
} from "../../../../../Components/Charts/ColumnsChat/ColumnsChartUtils";
import { type ThemeMode } from "../../../../../muiThemeTypes";
import { type ActiveFlexsaveSavings, type FlexsaveData } from "../../../types";
import { displayConfigMapping } from "./common";

type RenderOptions = {
  startColumnsIndex;
  endColumnsIndex;
  singleColumn;
};

/**
 * Function that split the history map inside savings into two arrays one of gray out columns and another with highlighted columns
 * @param startAt - index of the month where the columns start to be highlighted, the months before this index are disabled columns
 * @param endAt - index of the month where the columns end being highlighted and after this index the columns will be disabled
 * @param monthsValues - the months that will be used in the chart (as x values)
 * @param savingsHistory - all the savings to generate data for
 */
function splitDataSeries(
  startAt: number,
  endAt: number,
  monthsValues: string[],
  savingsHistory: Map<string, MonthSavings>
): ColumnValues[] {
  const highlightData: ColumnValues[] = [];
  monthsValues.forEach((month, index) => {
    const monthData = savingsHistory.get(month);
    const currentMonth = [monthData?.totalOnDemandSpend || null, monthData?.onDemandSpend || null] as ColumnValues;
    if (index >= startAt && index < endAt) {
      highlightData.push(currentMonth);
    }
  });

  return highlightData;
}

/**
 * Structure the savings data into the chart format, add to data legends, colors ...
 * @param monthsValues - array of months
 * @param savings - the data of the columns
 * @param renderOptions - options to render the series
 * @param mode
 */
export function generateSingleChartSeries(
  monthsValues: string[],
  savings: ActiveFlexsaveSavings,
  renderOptions: RenderOptions,
  mode: ThemeMode
): ColumnsChartSeries {
  const { startColumnsIndex, endColumnsIndex, singleColumn } = renderOptions;

  const legends = displayConfigMapping.legends;
  const highlightedColors = [
    displayConfigMapping.colors[mode].totalOnDemandSpend,
    displayConfigMapping.colors[mode].onDemandSpend,
  ] as [string, string];

  const highlightData = splitDataSeries(startColumnsIndex, endColumnsIndex, monthsValues, savings.history);

  return {
    colors: highlightedColors,
    data: highlightData,
    legends: [legends.totalOnDemandSpend, legends.onDemandSpend],
    startIndex: startColumnsIndex,
    singleColumn,
  };
}

/**
 * Generates the ColumnsChartData chart format, add to data legends, colors ...
 * @param activeSavings - the savings that will have the nextMonth data and the type
 * @param renderOptions - The render options for the series
 * @param labels - labels of the graph
 * @param mode
 */
function generateColumnsChartData(
  activeSavings: ActiveFlexsaveSavings[],
  renderOptions: RenderOptions,
  labels: string[],
  mode: ThemeMode
) {
  const columnsData: ColumnsChartData = {
    labels,
    series: [],
  };

  if (activeSavings.length === 1) {
    const highlightedSeries = generateSingleChartSeries(labels, activeSavings[0], renderOptions, mode);
    columnsData.series.push([highlightedSeries]);
  }
  if (activeSavings.length === 2) {
    activeSavings.forEach((singleSavings) => {
      const highlightedSeries = generateSingleChartSeries(labels, singleSavings, renderOptions, mode);
      columnsData.series.push([highlightedSeries]);
    });
    columnsData.wideColumns = true;
  }
  return columnsData;
}

export function generateChartData(labels: string[], activeSavings: ActiveFlexsaveSavings[], mode: ThemeMode) {
  const renderOptions: RenderOptions = {
    startColumnsIndex: 0,
    endColumnsIndex: 0,
    singleColumn: false,
  };

  renderOptions.endColumnsIndex = labels.length;

  return generateColumnsChartData(activeSavings, renderOptions, labels, mode);
}

export const generateOptionsForChart = (flexSaveData: FlexsaveData, theme: Theme, currencySymbol: CurrencySymbol) => {
  if (!flexSaveData?.enabled) {
    return null;
  }

  const activeSavings = flexSaveData.enabled.savings;
  const { months } = flexSaveData.enabled;

  const chartData = generateChartData(months, activeSavings, theme.palette.mode);

  const fontWeight = "400";

  const groupedSeries = groupDataToSeries(chartData.series);

  return generateDefaultOptions(
    fontWeight,
    theme.palette.text.primary,
    chartData.labels,
    groupedSeries,
    currencySymbol,
    theme.palette.mode
  );
};
