import { useState } from "react";

import { Button, DialogContent, Tooltip, Typography } from "@mui/material";

import SimpleDialog from "../../../../../../Components/SimpleDialog";
import contractHooks from "../../../../hooks";
import { useContractFormContext } from "../../../ContractsFormContext";

export const TerminateContractButton = () => {
  const { state } = useContractFormContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const updateContractStatus = contractHooks.useUpdateContractStatus(state);

  return (
    <>
      <Tooltip title="To be used when a customer offboards from DoiT">
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setDialogOpen(true);
          }}
          data-cy="terminate-contract-button"
        >
          Terminate contract
        </Button>
      </Tooltip>
      <SimpleDialog
        open={dialogOpen}
        title="Terminate contract"
        onConfirm={() => updateContractStatus(false, true)}
        onCancel={() => {
          setDialogOpen(false);
        }}
        confirmButtonText="Terminate contract"
        confirmButtonColor="error"
      >
        <DialogContent>
          <Typography mt={3}>Are you sure you want to terminate this contract of offboarded customer?</Typography>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};
