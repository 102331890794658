import {
  type CloudFlowNodeType,
  ModelType,
  type NodeTransformationConcatenation,
  NodeTransformationType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";

import { getNodeOutputModel, type GetOutputModelForActionNodeFn } from "../model-descriptors";
import { type NodeModelWithId } from "../types";

export async function calculateTransformationNodeOutputModel(
  referencedNode: NodeModelWithId<CloudFlowNodeType.TRANSFORMATION>,
  nodes: NodeModelWithId[],
  getOutputModelForActionNode: GetOutputModelForActionNodeFn
) {
  const referencedNodeOutputModel = await getNodeOutputModel(
    getOutputModelForActionNode,
    nodes,
    referencedNode.parameters.referencedNodeId
  );
  if (referencedNodeOutputModel === null) {
    return null;
  }

  let outputModel = JSON.parse(JSON.stringify(referencedNodeOutputModel));
  for (const transformation of referencedNode.parameters.transformations) {
    switch (transformation.type) {
      case NodeTransformationType.CONCATENATION: {
        outputModel = concatenationModelExtension(outputModel, transformation);
        continue;
      }
      default:
        throw new Error(`Transformation ${JSON.stringify(transformation)} is not implemented yet!`);
    }
  }

  return outputModel;
}

function concatenationModelExtension(
  referencedNodeOutputModel: UnwrappedApiServiceModelDescriptor,
  transformation: NodeTransformationConcatenation
) {
  let outputModel = referencedNodeOutputModel;
  if (outputModel.type === ModelType.LIST) {
    outputModel = outputModel.member.model;
  }

  if (outputModel.type !== ModelType.STRUCTURE) {
    throw new Error("Concatenation transformation node can only reference structures or list of structures model");
  }

  outputModel.members[transformation.newFieldName] = {
    model: {
      type: ModelType.STRING,
    },
  };

  return outputModel;
}
