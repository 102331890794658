import { type JSX } from "react";

import { defaultHeaderStyle } from "../../../Components/FilterTable/Table/FilterTableHeader";
import { Comparators, type DataColumn, type DefaultFilterColumns, type HeaderColumn } from "../../../types/FilterTable";
import { never } from "../../../utils";
import { threeDotHeaderSection } from "../assetUtils";
import { type AWSAssetRowData } from "./components/AwsAssetRow";
import { NotApplicable, NotAvailable } from "./components/typographics";

const mayNotBeAvailable = (value: string | JSX.Element) =>
  typeof value === "string" ? { value, label: value } : { value: NotAvailable, label: "-" };
const mayNotBeApplicable = (value: string | JSX.Element) =>
  typeof value === "string" ? { value, label: value } : { value: NotApplicable, label: "Not applicable" };

const assetNameColumn: DataColumn = {
  label: "Asset\u00A0name",
  path: "assetName",
  comparators: ["==", "!=", "contains"],
  toOption: (value) => ({ value, label: value }),
} as const;
const assetSupportPlan: DataColumn = {
  label: "Support plan",
  path: "supportTier",
  comparators: ["==", "!=", "contains"],
  toOption: (value) => ({ value, label: value }),
} as const;

const accountIdColumn: DataColumn = {
  label: "Account\u00A0ID",
  path: "accountId",
  comparators: ["==", "!=", "contains"],
  toOption: (value) => ({ value, label: value }),
} as const;
const payerAccountNameColumn: DataColumn = {
  label: "Payer account name",
  path: "payerAccountName",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeAvailable,
} as const;
const awsAccountIdColumn: DataColumn = {
  label: "Payer account ID",
  path: "awsAccountId",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeAvailable,
} as const;
const rootEmailColumn: DataColumn = {
  label: "Root\u00A0email",
  path: "rootEmail",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeApplicable,
} as const;
const inviteStatusColumn: DataColumn = {
  label: "Invite\u00A0state",
  path: "inviteStatus",
  comparators: ["==", "!="],
  toOption: mayNotBeApplicable,
} as const;
const billingProfileColumn: DataColumn = {
  label: "Billing\u00A0profile",
  path: "billingProfile",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeApplicable,
} as const;
const assetTagColumn: DataColumn = {
  label: "Asset\u00A0tag",
  path: "assetTag",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeApplicable,
} as const;

const isMPA: DataColumn = {
  label: "MPA",
  path: "isMPA",
  comparators: [Comparators.EQUAL_TO],
  toOption: (value: AWSAssetRowData["isMPA"]) => {
    switch (value) {
      case true:
        return { value, label: "Show only MPA" };
      case false:
        return { value, label: "Show all except MPA" };
      case undefined:
        return { value, label: "Unknown" };
      default: {
        never(value, undefined);
      }
    }
  },
} as const;

const awsAssetFilterColumns: DataColumn[] = [
  assetNameColumn,
  assetSupportPlan,
  accountIdColumn,
  payerAccountNameColumn,
  awsAccountIdColumn,
  rootEmailColumn,
  inviteStatusColumn,
  billingProfileColumn,
  isMPA,
  assetTagColumn,
];

const assetNameHeader = {
  value: assetNameColumn.path,
  label: assetNameColumn.label,
  style: defaultHeaderStyle,
};
const accountIdHeader = {
  value: accountIdColumn.path,
  label: accountIdColumn.label,
  style: defaultHeaderStyle,
};
const payerAccountNameHeader = {
  value: payerAccountNameColumn.path,
  label: payerAccountNameColumn.label,
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};
const awsAccountIdHeader = {
  value: awsAccountIdColumn.path,
  label: awsAccountIdColumn.label,
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};
const rootEmailHeader = {
  value: rootEmailColumn.path,
  label: rootEmailColumn.label,
  style: defaultHeaderStyle,
  hidden: { lgDown: true },
};
const inviteStatusHeader = {
  value: inviteStatusColumn.path,
  label: inviteStatusColumn.label,
  style: defaultHeaderStyle,
  hidden: { xlDown: true },
};
const billingProfileHeader = {
  value: billingProfileColumn.path,
  label: billingProfileColumn.label,
  style: defaultHeaderStyle,
  hidden: { xlDown: true },
};
const assetTagHeader = {
  value: assetTagColumn.path,
  label: assetTagColumn.label,
  style: defaultHeaderStyle,
  hidden: { lgDown: true },
};

const awsAssetHeaders: HeaderColumn[] = [
  assetNameHeader,
  accountIdHeader,
  payerAccountNameHeader,
  awsAccountIdHeader,
  rootEmailHeader,
  inviteStatusHeader,
  billingProfileHeader,
  assetTagHeader,
  threeDotHeaderSection,
];

/**
 * Generates columns and headers for Assets table according to whether the viewing user is a doer.
 *
 * @param doer whether the user is an employee of DoiT
 */
export const awsHeadedFilterColumns = (doer: boolean): { columns: DataColumn[]; headers: HeaderColumn[] } => {
  const columns = [...awsAssetFilterColumns];
  const headers = [...awsAssetHeaders];

  if (!doer) {
    columns.splice(2, 2);
    headers.splice(2, 2);
  }

  return { columns, headers };
};

export const defaultFilters = [
  {
    column: inviteStatusColumn,
    comparator: "==",
    label: "N/A",
    value: NotApplicable,
  },
] as DefaultFilterColumns<any>;
