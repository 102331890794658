import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import { type DebtItem } from "./types";

export const useStyles = makeStyles((theme) => ({
  cardHeader: {
    paddingBottom: 0,
  },
  cardContent: {
    minWidth: 240,
    height: 124,
    paddingTop: theme.spacing(0.5),
  },
}));

type Props = {
  onClose: () => void;
  anchorEl: HTMLElement | null;
  entity: DebtItem["data"]["entityData"];
};

const ContactPopover = ({ onClose, anchorEl, entity }: Props) => {
  const classes = useStyles();
  return (
    <Popover
      open={true}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorReference="anchorEl"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Card>
        <CardHeader
          className={classes.cardHeader}
          title="Contact Info"
          titleTypographyProps={{ variant: "subtitle2" }}
        />
        <CardContent className={classes.cardContent}>
          {entity ? (
            <>
              {entity.contact ? (
                <>
                  <Typography variant="subtitle1">
                    {entity.contact.firstName
                      ? `${entity.contact.firstName} ${entity.contact.lastName}`
                      : entity.contact.name}
                  </Typography>
                  {Boolean(entity.contact.email) && <Typography variant="subtitle1">{entity.contact.email}</Typography>}
                  {Boolean(entity.contact.phone) && <Typography variant="subtitle1">{entity.contact.phone}</Typography>}
                  {Boolean(entity.country) && <Typography variant="subtitle1">{entity.country}</Typography>}
                </>
              ) : (
                <Typography variant="body1">No contact information</Typography>
              )}
            </>
          ) : null}
        </CardContent>
      </Card>
    </Popover>
  );
};

export default ContactPopover;
