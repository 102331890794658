import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

import { type PerksWData } from "../../../types";
import { toBeautifiedName } from "../helpers";
import { type FilterablePerkType, isFilterablePerkType } from "../types";
import { styles } from "./consts";

type FilterCheckboxesProps<T extends FilterablePerkType> = {
  entries: Record<T, PerksWData[]>;
  filter: Record<T, boolean>;
  filterTitle: string;
  handleCheckboxChange: (checked: boolean, key: FilterablePerkType) => void;
};

const FilterCheckboxes = <T extends FilterablePerkType>({
  entries,
  filter,
  filterTitle,
  handleCheckboxChange,
}: FilterCheckboxesProps<T>) => {
  const entryList = Object.entries(entries);

  const isPerksWDataEntry = (entry: [unknown, unknown]): entry is [T, PerksWData[]] => {
    const [key, value] = entry;
    return (
      isFilterablePerkType(key) &&
      Array.isArray(value) &&
      value.every((item) => typeof item === "object" && item !== null && "data" in item)
    );
  };

  const typedEntryList: [T, PerksWData[]][] = entryList.filter(isPerksWDataEntry);

  if (!typedEntryList.length) {
    return <></>;
  }

  return (
    <FormControl component="fieldset" variant="standard" data-testid="perk-solution-filter">
      <FormLabel component="legend" sx={styles.filterTitle}>
        {filterTitle}
      </FormLabel>
      <FormGroup sx={styles.formGroup}>
        {typedEntryList.map(([key, value]: [T, PerksWData[]]) => (
          <FormControlLabel
            sx={styles.formControlLabel}
            key={key}
            control={
              <Checkbox
                data-testid={`${key}-perk-checkbox`}
                checked={!!filter[key]}
                onChange={(e) => {
                  handleCheckboxChange(e.target.checked, key);
                }}
                sx={styles.checkbox}
              />
            }
            label={toBeautifiedName(key, value.length)}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

export default FilterCheckboxes;
