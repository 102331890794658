import { type ReactElement } from "react";

import { type GoogleCloudAssetModel } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { visuallyHiddenStyle } from "../../../../../constants/common";
import { type Asset } from "../../../../../types";
import { AssetPageAccordion } from "./AssetPageAccordion";
import { BillingAdminsAccordion } from "./BillingAdminsAccordion";

type GoogleAsset = Asset<GoogleCloudAssetModel>;

type Props = {
  shouldBillingAdminsShow: boolean;
  displayName: string;
  entityId: string | null;
  billingAccountId: string;
  admins: GoogleAsset["data"]["properties"]["admins"];
  projects: GoogleAsset["data"]["properties"]["projects"];
  projectRowContent: (item) => ReactElement;
};

const AccordionsArea = ({
  shouldBillingAdminsShow,
  displayName,
  entityId,
  billingAccountId,
  admins,
  projects,
  projectRowContent,
}: Props): ReactElement => (
  <>
    <Divider />
    {shouldBillingAdminsShow && (
      <Box component="section">
        <Typography variant="h4" sx={visuallyHiddenStyle}>
          Billing admins
        </Typography>
        <BillingAdminsAccordion
          admins={admins}
          entityId={entityId}
          billingAccountId={billingAccountId}
          displayName={displayName}
        />
      </Box>
    )}
    <Divider />
    <Box component="section">
      <Typography variant="h4" sx={visuallyHiddenStyle}>
        Projects
      </Typography>
      <AssetPageAccordion
        items={projects || []}
        admins={false}
        rowContent={projectRowContent}
        title="Projects"
        entityId={entityId}
        billingAccountId={billingAccountId}
        displayName={displayName}
      />
    </Box>
    <Divider />
  </>
);

export default AccordionsArea;
