export const getAreaChartOptions = () => ({
  chart: {
    type: "area",
    zoomType: "xy",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: true,
    maxHeight: 66,
    itemMarginTop: 4,
  },
  tooltip: {
    valueDecimals: 2,
  },
  yAxis: {
    title: {
      enabled: false,
    },
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  plotOptions: {
    area: {
      stacking: undefined,
      fillOpacity: 0.5,
      marker: {
        enabled: false,
      },
    },
  },
  series: [],
});
