export const attributionTxt = {
  CREATE_ATTRIBUTION_FAILED: "Failed to create attribution",
  DONE: "Done",
  DELETE_VALIDATION_DIALOG_TITLE: "Unable to delete some attributions",
  DELETE_VALIDATION_DIALOG_CONTENT:
    "Some attributions are used in other content types and can’t be deleted. Remove the attributions from where they are used in order to delete.",
  CANNOT_DELETE_SERVER_ERROR: "Cannot delete attribution used in the other entities",
  ADD_CONDITION: "Add at least one condition",
  NAME_BEFORE_SAVING: "Name before saving",
  FAILED_TO_SAVE: "Failed to save attribution",
  ADD_RULE: "Add rule",
  SELECT_PROPERTIES: "1. Select properties",
  DEFINE_LOGIC_TITLE: "2. Define attribution logic",
  DEFINE_LOGIC: "Define attribution logic",
  LOGIC_ALERT_TITLE: "How to write logic?",
  LOGIC_ALERT_RULES: [
    "Use AND, OR and NOT as operators",
    "AND is executed before OR, so A OR B AND C is equal to A OR (B AND C)",
    "If you want to change priorities, use parentheses, e.g. (A OR B) AND C",
  ],
  OPERATORS_BETWEEN_RULES: "Operators between rules are AND by default. Use this field for advanced logic.",
  LEARN_MORE: "Learn more",
};

export const analyticsResourcesValues = {
  reports: "Reports",
  budgets: "Budgets",
  metrics: "Metrics",
  attributions: "Attributions",
  attributionGroups: "Attribution groups",
  alerts: "Alerts",
  organizations: "Organizations",
  dailyDigests: "Daily digests",
};
