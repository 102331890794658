import { type IntegrationModelCloudhealthPricebooksModel } from "@doitintl/cmp-models";

import AccountBox from "./AccountBox/AccountBox";
import { costMode, type payerAccountObj, type SelectedPricebooks, type supportMapObj } from "./types";

type Props = {
  support: supportMapObj;
  payerList: payerAccountObj[];
  updateStepSupportObj: (updatedSupportObj: supportMapObj, index: number) => void;
  handleDelete: (n: number) => void;
  contractSupportErrorBool: boolean;
  onEditDone: () => void;
  inEdit: () => void;
  existsInSupport: (s: string) => boolean;
  onChangeSelectedPricebooks: (p: IntegrationModelCloudhealthPricebooksModel | null, payerAccount: string) => void;
  selectedPricebooks: SelectedPricebooks;
  disabled: boolean;
};
const AccountsList = (props: Props) => {
  const {
    support,
    payerList,
    updateStepSupportObj,
    handleDelete,
    contractSupportErrorBool,
    onEditDone,
    inEdit,
    existsInSupport,
    onChangeSelectedPricebooks,
    selectedPricebooks,
    disabled,
  } = props;
  return (
    <>
      {Object.entries(support).map(([mpaId, payerAccount], index) => {
        const payerAccountMode: costMode = payerAccount?.flatRate !== null ? costMode.flatRate : costMode.percentage;
        const contractSupportVal = String(payerAccount?.discount ?? payerAccount?.flatRate ?? "0");
        return (
          <AccountBox
            key={mpaId}
            index={index}
            mpaId={mpaId}
            payerList={payerList}
            updateSupportObj={updateStepSupportObj}
            contractSupportVal={contractSupportVal}
            contractSupportChargeMode={payerAccountMode}
            handleDelete={handleDelete}
            contractSupportErrorBool={contractSupportErrorBool}
            shouldDeleteBtnShow={Object.keys(support).length > 1}
            onEditDone={onEditDone}
            inEdit={inEdit}
            existsInSupport={existsInSupport}
            onChangeSelectedPricebooks={onChangeSelectedPricebooks}
            selectedPricebooks={selectedPricebooks}
            doesNotHaveEditPermission={disabled}
          />
        );
      })}
    </>
  );
};
export default AccountsList;
