import { Alert, Button } from "@mui/material";

import { alertTexts } from "../../../assets/texts";
import { intro } from "../strings";

export const HeaderAlert = () => (
  <Alert
    severity="info"
    action={
      <Button color="inherit" size="small" target="_blank" href={intro.url}>
        {alertTexts.VIEW_DOCS}
      </Button>
    }
  >
    {intro.text}
  </Alert>
);
