import { type JSX } from "react";

import { IconButton, Link, Stack } from "@mui/material";

import mixpanel from "../../../utils/mixpanel";

type Props = {
  icon: JSX.Element;
  text: string;
  url?: string;
  onClick?: () => void;
};

export const IconWithLink = ({ url, text, icon, onClick }: Props) => (
  <Stack
    direction="row"
    alignItems="center"
    ml={-1}
    onClick={() => {
      if (onClick) {
        onClick();
      } else {
        mixpanel.track("flexsaveSA.gethelpestimate", { title: text });
        window.open(url, "_blank");
      }
    }}
  >
    <IconButton color="primary">{icon}</IconButton>
    <Link underline="hover" color="primary" variant="body2" sx={{ cursor: "pointer" }}>
      {text}
    </Link>
  </Stack>
);
