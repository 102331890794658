import { type AxiosInstance } from "axios";

const getBaseURL = (customerID: string) => `/v1/customers/${customerID}/analytics/metrics`;

export const deleteMetrics = async ({
  metricIds,
  api,
  customerId,
}: {
  metricIds: string[];
  api: AxiosInstance;
  customerId: string;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.delete(baseURL, {
    data: {
      ids: metricIds,
    },
  });
};
