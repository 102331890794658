import { googleCloudBillingAccountAdminTexts } from "../../../assets/texts";

// These have to match the validation on the api side:
// https://github.com/doitintl/cmp-main/blob/783d13c034429c28bb589fea7c6e9462fc64dfb2/server/services/scheduled-tasks/googleclouddirect/validate.go#L1
// If they go out of sync it may be not possible to submit the form

export const isBillingAccountIdValid = (value?: string | null) =>
  !!(value && /^(?:[A-F0-9]{6}-){2}[A-F0-9]{6}$/g.test(value));
export const isProjectIdValid = (value?: string | null) => !!(value && /^[a-z][a-z0-9_-]{0,62}$/g.test(value));
export const isDatasetValid = (value?: string | null) => !!(value && /^\w{1,1024}$/g.test(value));

export enum FormField {
  ENTITY,
  PAYER_ACCOUNT,
  NAME,
  EMAIL,
  TARGET,
  NOTES,
}

export const helperText = (touched: any, errors: any, field: FormField): string => {
  switch (field) {
    case FormField.ENTITY:
      return (touched.entity && errors.entity) || "Select the invoiced billing profile";
    case FormField.PAYER_ACCOUNT:
      return (touched.payerAccountId && errors.payerAccountId) || "DoiT reseller payer account";
    case FormField.NAME:
      return (touched.name && errors.name) || "Account name may be changed later by the root user";
    case FormField.EMAIL:
      return (touched.email && errors.email) || "Account login root email address";
    case FormField.TARGET:
      return (touched.target && errors.target) || "12-digit Amazon Web Services Account ID";
    case FormField.NOTES:
      return (touched.notes && errors.notes) || "Notes that will appear on the invitation email";
  }
};

export const discernError = (touched: any, errors: any, field: FormField): boolean => {
  switch (field) {
    case FormField.ENTITY:
      return touched.entity && Boolean(errors.entity);
    case FormField.PAYER_ACCOUNT:
      return touched.payerAccountId && Boolean(errors.payerAccountId);
    case FormField.NAME:
      return touched.name && Boolean(errors.name);
    case FormField.EMAIL:
      return touched.email && Boolean(errors.email);
    case FormField.TARGET:
      return touched.target && Boolean(errors.target);
    case FormField.NOTES:
      return touched.notes && Boolean(errors.notes);
  }
};

export function constructPathToManageDomains(customerId: string) {
  return `/customers/${customerId}/settings/general`;
}

export function getDoitAdminUsers(admins: string[]) {
  return admins.filter((elem) => {
    const lowerCasedElem = elem.toLowerCase();
    return lowerCasedElem.endsWith("@doit.com") || lowerCasedElem.endsWith("@doit-intl.com");
  });
}

export function validateEmails({
  emails,
  isThereSignedContract,
  customerDomains,
}: {
  emails: string[];
  isThereSignedContract: boolean;
  customerDomains: string[];
}) {
  const retVal = {};
  for (const email of emails) {
    retVal[email] = {
      email,
    };
    if (isThereSignedContract) {
      if (!customerDomains.some((domain) => email.endsWith(`@${domain}`))) {
        retVal[email].error = googleCloudBillingAccountAdminTexts.CONTRACT_SIGNED_ADD_ADMIN_ERR;
      }
    } else {
      if (!email.endsWith("@doit.com") && !email.endsWith("@doit-intl.com")) {
        retVal[email].error = googleCloudBillingAccountAdminTexts.CONTRACT_NOT_SIGNED_ADD_ADMIN_ERR;
      }
    }
  }
  return retVal;
}
