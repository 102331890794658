import { type FC, useMemo, useState } from "react";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import type { MSTeamsChannel } from "@doitintl/cmp-models";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { useAuthContext } from "../../../Context/AuthContext";
import { ConnectTeamsChannelDialog } from "./ConnectTeamsChannelDialog";
import { MsTeamsTableRow, type TeamsChannelRow } from "./MsTeamsTableRow";
import { teamsChannelsFilterColumns, teamsChannelsHeaderColumns } from "./teamsChannelsColumns";
import type { ToolbarButton } from "../../../Components/FilterTable/types";

export const cyIds = {
  buttons: {
    addToTeam: "ms-teams-add-to-team-button",
    connectChannel: "ms-teams-connect-channel-button",
  },
  title: "ms-teams-channel-table-title",
};

export type TeamsChannelConnectionsProps = {
  channels: MSTeamsChannel[];
  openApp: () => void;
};

/**
 * Displays existing channel connections for MS Teams integration
 * as well as offering the ability to add new channels and teams.
 */
export const TeamsChannelConnections: FC<TeamsChannelConnectionsProps> = ({ channels, openApp }) => {
  const [connectTeamsDialogOpen, setConnectTeamsDialogOpen] = useState<boolean>(false);
  const { isDoitEmployee } = useAuthContext();

  const tableItems = useMemo<TeamsChannelRow[]>(() => {
    if (!channels?.length) {
      return [];
    }

    return channels.map(
      (channel: MSTeamsChannel): TeamsChannelRow => ({
        id: channel.conversationId,
        team: channel?.isDevBot ? `${channel.teamName} (dev)` : channel.teamName,
        channel: channel.channelName,
        dateConnected: channel?.addedAt
          ? DateTime.fromMillis(channel.addedAt)
          : DateTime.invalid("No date provided", "The field `addedAt` was absent from the channel object"),
      })
    );
  }, [channels]);

  const tableButtons = useMemo<ToolbarButton[]>(
    () => [
      {
        text: "Add to a new team",
        disabled: isDoitEmployee,
        startIcon: <OpenInNewIcon fontSize="small" />,
        onClick: openApp,
        "data-cy": cyIds.buttons.addToTeam,
        "aria-description": "Open DoiT MS Teams app",
      },
      {
        text: "Connect channel",
        disabled: isDoitEmployee,
        onClick: () => {
          setConnectTeamsDialogOpen(true);
        },
        "data-cy": cyIds.buttons.connectChannel,
        "aria-description": "Open dialog to connect teams channel",
      },
    ],
    [isDoitEmployee, openApp]
  );

  return (
    <>
      <ConnectTeamsChannelDialog
        open={connectTeamsDialogOpen}
        handleClose={() => {
          setConnectTeamsDialogOpen(false);
        }}
      />
      <FilterTable<TeamsChannelRow>
        showFilterBar={false}
        rowComponent={MsTeamsTableRow}
        tableItems={tableItems}
        headerColumns={teamsChannelsHeaderColumns}
        filterColumns={teamsChannelsFilterColumns}
        toolbarProps={{
          title: (
            <Typography variant="h4" data-cy={cyIds.title}>
              Connected channels
            </Typography>
          ),
          secondaryButtons: tableButtons,
        }}
        fillEmptySpace={false}
        defaultSortingColumnValue="team"
        defaultSortDirection="asc"
        persistenceKey="ms-teams-integrations-table"
        rowDataCyId
      />
    </>
  );
};
