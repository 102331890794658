import { useEffect, useMemo, useState } from "react";

import {
  type EntityModel,
  type EntityModelBucketModel,
  type EntityPayment,
  PaymentMethodType,
} from "@doitintl/cmp-models";
import { type ModelId, useCollectionDataOnce } from "@doitintl/models-firestore";

import { type Entity } from "../../Context/customer/EntitiesContext";
import { consoleErrorWithSentry } from "../../utils";
import { type EntityFromPriority, PaymentMethodCategory } from "./BillingForm/api";
import { usePriorityAPI } from "./BillingForm/hooks";
import {
  type AssetDescriptor,
  type BillingProfilePaymentMethodData,
  type EditBillingProfileData,
} from "./BillingProfileForm.models";
import { useEntityAllowedAssetsCollectionOnce } from "./useEntityAllowedAssetsCollection";

function normalizePaymentMethodData(entityPayment: EntityPayment | undefined): BillingProfilePaymentMethodData {
  switch (entityPayment?.type) {
    case PaymentMethodType.BillCom:
      return {
        paymentMethodCategory: PaymentMethodCategory.BILL_COM,
        paymentMethodType: null,
        paymentMethodId: null,
      };
    case PaymentMethodType.USBankAccount:
    case PaymentMethodType.BankAccount:
      return {
        paymentMethodCategory: PaymentMethodCategory.STRIPE,
        paymentMethodType: entityPayment.type,
        paymentMethodId: entityPayment.bankAccount?.id ?? null,
      };
    case PaymentMethodType.CreditCard:
      return {
        paymentMethodCategory: PaymentMethodCategory.STRIPE,
        paymentMethodType: entityPayment.type,
        paymentMethodId: entityPayment.card?.id ?? null,
      };
    case PaymentMethodType.SepaDebit:
      return {
        paymentMethodCategory: PaymentMethodCategory.STRIPE,
        paymentMethodType: entityPayment.type,
        paymentMethodId: entityPayment.sepaDebit?.id ?? null,
      };
    case PaymentMethodType.BacsDebit:
      return {
        paymentMethodCategory: PaymentMethodCategory.STRIPE,
        paymentMethodType: entityPayment.type,
        paymentMethodId: entityPayment.bacsDebit?.id ?? null,
      };
    case PaymentMethodType.AcssDebit:
      return {
        paymentMethodCategory: PaymentMethodCategory.STRIPE,
        paymentMethodType: entityPayment.type,
        paymentMethodId: entityPayment.acssDebit?.id ?? null,
      };
    case PaymentMethodType.WireTransfer:
    default:
      return {
        paymentMethodCategory: PaymentMethodCategory.WIRE_TRANSFER,
        paymentMethodType: null,
        paymentMethodId: null,
      };
  }
}

export const convertEntityToBillingProfile = (
  apiResponse: EntityFromPriority,
  entity: EntityModel,
  entityInvoiceBuckets: ModelId<EntityModelBucketModel>[] = [],
  alreadyAssignedAssets: AssetDescriptor[] = []
): EditBillingProfileData => {
  const { CUSTOMERS, FNCCUST } = apiResponse;
  const { FIRSTNAME, LASTNAME, EMAIL, PHONENUM } = CUSTOMERS.CUSTPERSONNEL_SUBFORM[0] ?? {};

  return {
    country: CUSTOMERS.COUNTRYNAME,
    companyName: CUSTOMERS.CUSTDES,
    taxId: CUSTOMERS.WTAXNUM,
    address: CUSTOMERS.ADDRESS,
    city: CUSTOMERS.STATEA,
    zip: CUSTOMERS.ZIP,
    stateCode: CUSTOMERS.STATECODE,
    state: CUSTOMERS.STATENAME,
    firstName: FIRSTNAME,
    lastName: LASTNAME,
    email: EMAIL,
    phone: PHONENUM,
    currency: FNCCUST.CODE,
    payCode: FNCCUST.PAYCODE,
    ...normalizePaymentMethodData(entity.payment),
    invoicingMode: entity.invoicing.mode,
    autoAssignGCP: entity.invoicing.autoAssignGCP ?? true,
    invoicePerService: entity.invoicing.marketplace?.invoicePerService ?? false,
    separateInvoice: entity.invoicing.marketplace?.separateInvoice ?? false,

    buckets: entityInvoiceBuckets.map(({ id, name, attribution }) => ({
      id,
      name,
      attribution,
      isDefault: entity.invoicing.default?.id === id,
      assets: alreadyAssignedAssets.filter(({ bucketId }) => bucketId === id),
    })),
  };
};

export function useFetchEntityFromPriority(
  entity: Entity
): [EditBillingProfileData | undefined, EntityFromPriority | undefined | null] {
  const [billingProfile, setBillingProfile] = useState<EditBillingProfileData>();
  const [entityFromPriority, setEntityFromPriority] = useState<EntityFromPriority | null>();

  const priorityAPI = usePriorityAPI();

  useEffect(() => {
    setBillingProfile(undefined);
    (async () => {
      try {
        const entityFromPriority = await priorityAPI.getEntity(entity.id);
        setEntityFromPriority(entityFromPriority);
      } catch (error) {
        consoleErrorWithSentry(error);
        setEntityFromPriority(null);
      }
    })();
  }, [entity, priorityAPI]);

  const [entityInvoiceBuckets, entityInvoiceBucketsLoading] = useCollectionDataOnce(
    useMemo(() => entity.ref.collection("buckets").orderBy("name"), [entity.ref]),
    {
      idField: "id",
    }
  );

  const [alreadyAssignedAssets, alreadyAssignedAssetsHaveBeenLoaded] = useEntityAllowedAssetsCollectionOnce(entity);

  useEffect(() => {
    if (
      entityFromPriority !== null &&
      entityFromPriority !== undefined &&
      !entityInvoiceBucketsLoading &&
      alreadyAssignedAssetsHaveBeenLoaded
    ) {
      setBillingProfile(
        convertEntityToBillingProfile(entityFromPriority, entity, entityInvoiceBuckets, alreadyAssignedAssets)
      );
    }
  }, [
    alreadyAssignedAssets,
    alreadyAssignedAssetsHaveBeenLoaded,
    entity,
    entityFromPriority,
    entityInvoiceBuckets,
    entityInvoiceBucketsLoading,
  ]);

  return [billingProfile, entityFromPriority];
}
