import { useMemo } from "react";

import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import CountUpCard from "../../../Components/CountUp/CountUpCard";
import { useFullScreen } from "../../../utils/dialog";
import { calcOrderSavings } from "./index";
import { type FlexsaveOrder } from "./types";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 8,
  },
}));

type Props = {
  orders: FlexsaveOrder[];
  filteredRows: Readonly<FlexsaveOrder[]>;
};

export default function AggregationsBar({ orders, filteredRows }: Props) {
  const classes = useStyles();
  const { isMobile: smDown } = useFullScreen();

  // use only active or retired orders in aggregations
  // "new", "pending", "canceled" or "failed" orders should not be taken into account
  const data = useMemo(
    () => filteredRows?.filter((order) => ["active", "retired"].includes(order.status)) ?? [],
    [filteredRows]
  );

  const totalExpectedSavings = useMemo(
    () =>
      data.reduce((acc, order) => {
        const onDemand = order.pricing.onDemandNormalized * (order.normalizedUnits?.total ?? 0);
        const flexibleNormalized = order.pricing.flexibleNormalized * (order.normalizedUnits?.total ?? 0);
        if (isNaN(onDemand - flexibleNormalized)) {
          return acc;
        }
        return acc + (onDemand - flexibleNormalized);
      }, 0),
    [data]
  );

  const actualSavings = useMemo(
    () =>
      data.reduce((acc, order) => {
        const savings = calcOrderSavings(order);
        return acc + savings;
      }, 0),
    [data]
  );

  const weightedReservationUtilization = useMemo(() => {
    const { numerator, denominator } = data.reduce(
      (memo, order) => {
        if (!order.normalizedUnits) {
          return memo;
        }
        const { underUtilized, unitsPerDay } = order.normalizedUnits;
        const { savingsPerHourNormalized } = order.pricing;
        const numDays = Object.keys(order.utilization ?? {}).length;
        const utilization = numDays > 0 ? 1 - underUtilized / (unitsPerDay * numDays) : 1;
        const weight = savingsPerHourNormalized * unitsPerDay;
        memo.numerator += utilization * weight;
        memo.denominator += weight;
        return memo;
      },
      { numerator: 0, denominator: 0 }
    );
    if (denominator === 0) {
      return 0;
    }
    return 100 * (numerator / denominator);
  }, [data]);

  const yearToDate = useMemo(
    () =>
      orders.reduce((acc, order) => {
        if (!["active", "retired"].includes(order.status)) {
          return acc;
        }
        if (!DateTime.utc().hasSame(DateTime.fromJSDate(order.config.startDate.toDate()), "year")) {
          return acc;
        }
        const savings = calcOrderSavings(order);
        return acc + savings;
      }, 0),
    [orders]
  );

  return (
    <Grid className={classes.root} spacing={smDown ? 1 : 2} container>
      <Grid item md={3} xs={6}>
        <CountUpCard
          number={totalExpectedSavings}
          prefix="$"
          duration={2}
          decimals={2}
          text={smDown ? "Expected Savings" : "Total Expected Savings"}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <CountUpCard number={actualSavings} prefix="$" duration={2} decimals={2} text="Actual Savings" />
      </Grid>
      <Grid item md={3} xs={6}>
        <CountUpCard
          number={weightedReservationUtilization}
          suffix="%"
          duration={2}
          decimals={0}
          text={smDown ? "Flexsave Utilization" : "Weighted Flexsave Utilization"}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <CountUpCard
          number={yearToDate}
          prefix="$"
          duration={2}
          decimals={2}
          text={smDown ? "YTD Savings" : "Year to Date Savings"}
        />
      </Grid>
    </Grid>
  );
}
