import { useMemo } from "react";

import * as Highcharts from "highcharts";
import patternFill from "highcharts/modules/pattern-fill";
import HighchartsReact from "highcharts-react-official";

patternFill(Highcharts);

export type ColumnsChartData = {
  labels: string[]; // the x axis values strings
  series: ColumnsChartSeries[][]; // array of columns data
  wideColumns?: boolean; // if true the columns will be wider that the default width
};

export type ColumnsChartSeries = {
  legends: [string, string]; // legends for the two data types
  data: ColumnValues[]; // data for the columns
  colors: [Highcharts.ColorString, Highcharts.ColorString]; // colors of the column, the top part and the bottom part
  alignColumns?: "left" | "right" | "center" | "auto"; // align the column position, default is "auto"
  applyLinesPattern?: boolean; // draw columns with lines
  startIndex?: number; // the start position of the data, for example the data can start from the middle of the x values
  singleColumn: boolean;
};

// holds single column value, the top part and the bottom part
export type ColumnValues = [number | null, number | null];

/**
 * Chart with columns, each column is split to two parts, with different color and value
 * @param chartData - data to draw
 * @constructor
 */
export const ColumnsChart = ({ options }: { options: Highcharts.Options | undefined | null }) =>
  useMemo(
    () => (
      <HighchartsReact
        containerProps={{ style: { height: "100%" } }}
        highcharts={Highcharts}
        options={options}
        immutable={true}
      />
    ),
    [options]
  );
