import { useState } from "react";

import { Button } from "@mui/material";

import { reportTxt } from "../../../../../assets/texts/CloudAnalytics";
import RejectDialog from "../../../templateLibrary/RejectDialog";

type Props = {
  disabled: boolean;
};

const Reject = ({ disabled }: Props) => {
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  return (
    <>
      <Button
        data-cy="reject-template"
        disabled={disabled}
        onClick={() => {
          setRejectDialogOpen(true);
        }}
        variant="outlined"
      >
        {reportTxt.REJECT}
      </Button>
      <RejectDialog
        open={rejectDialogOpen}
        onClose={() => {
          setRejectDialogOpen(false);
        }}
      />
    </>
  );
};

export default Reject;
