import {
  type ComponentType,
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Link as RouterLink } from "react-router-dom";
import {
  AssetModel,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudStandalone,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  AssetTypeMicrosoftAzureStandalone,
  AssetTypeOffice365,
  CustomerSecurityMode,
  IntegrationModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Button, IconButton } from "@mui/material";
import noop from "lodash/noop";
import { DateTime } from "luxon";
import { getDisplayName } from "recompose";

import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { createLocationDescriptorWithReferrer } from "../../Pages/Entity/hooks";
import { type AnyAsset, type Assets, type Customer } from "../../types";
import { type Handshake } from "../../types/Handshake";
import { mixpanelAssetMap } from "../../utils/MixpanelAssets";
import { arrayFromDocChange } from "./arrayFromDocChange";
import { useEntitiesContext } from "./EntitiesContext";

export const ProcurementAssetTypes = [AssetTypeAmazonWebServices, AssetTypeGoogleCloud, AssetTypeMicrosoftAzure];
export const StandaloneAssetTypes = [
  AssetTypeAwsStandalone,
  AssetTypeGoogleCloudStandalone,
  AssetTypeMicrosoftAzureStandalone,
];

type AssetsContextType = {
  assets: Assets;
  handshakes: Handshake[];
  assetsLoading: boolean;
  appendLoadedAssetTypes: (assetTypes: AssetType[]) => void;
};

export type FullAssetsContextType = AssetsContextType & {
  appendLoadedAssetTypes: (assetType: AssetType[]) => void;
  hasAWSAssets: boolean;
  hasAWSStandaloneAssets: boolean;
  hasGCPAssets: boolean;
  hasResoldAssets: boolean;
  hasStandaloneAssets: boolean;
};

const defaultAssetsContext = {
  assets: {},
  handshakes: [],
  assetsLoading: true,
  appendLoadedAssetTypes: noop,
  hasGCPAssets: false,
  hasAWSAssets: false,
  hasAWSStandaloneAssets: false,
  hasResoldAssets: false,
  hasStandaloneAssets: false,
};
const assetsContext = createContext<FullAssetsContextType>({ ...defaultAssetsContext });
const defaultLoadedAssetsType: AssetType[] = [
  AssetTypeGSuite,
  AssetTypeOffice365,
  AssetTypeGoogleCloud,
  AssetTypeAmazonWebServices,
  AssetTypeMicrosoftAzure,
  AssetTypeGoogleCloudStandalone,
  AssetTypeAwsStandalone,
  AssetTypeMicrosoftAzureStandalone,
];

export const convertAssetsToMixpanelType = (assets?: Assets) => {
  if (assets) {
    const prev = {};
    return Object.values(assets).flatMap((asset) =>
      asset.flatMap((a) => {
        const mixpanelData = mixpanelAssetMap(a.data.type);
        if (mixpanelData) {
          if (!(prev[mixpanelData.assetType] && prev[mixpanelData.assetType] === mixpanelData.status)) {
            prev[mixpanelData.assetType] = mixpanelData.status;
            return mixpanelData;
          }
          return [];
        }
        return [];
      })
    );
  }
  return [];
};

export const AssetsContextProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const { entities, entitiesLoading } = useEntitiesContext();

  const snackbar = useSnackbar();

  const [assets, setAssets] = useState<Assets>({});

  const [allAssets, setAllAssets] = useState<AnyAsset[]>();
  const [assignedAssetsLoading, setAssignedAssetsLoading] = useState<boolean>(true);
  const [assetsLoading, setAssetsLoading] = useState<boolean>(true);
  const [loadedAssetsTypes, setLoadedAssetTypes] = useState<AssetType[]>(defaultLoadedAssetsType);
  const [handshakes, setHandshakes] = useState<Handshake[]>([]);
  const [handshakesLoading, setHandshakesLoading] = useState<boolean>(true);

  useEffect(() => {
    const someAssetsLoading = [assignedAssetsLoading, handshakesLoading, entitiesLoading].some((loading) => loading);
    setAssetsLoading(someAssetsLoading);
  }, [assignedAssetsLoading, handshakesLoading, entitiesLoading]);

  useEffect(() => {
    if (!customer?.ref) {
      return;
    }

    const dateFilter = DateTime.utc().startOf("day").minus({ months: 1 }).toJSDate();

    return getCollection(IntegrationModel)
      .doc("amazon-web-services")
      .collection("handshakes")
      .where("customer", "==", customer.ref)
      .where("visible", "==", true)
      .where("requestedTimestamp", ">=", dateFilter)
      .onSnapshot(
        (querySnapshot) => {
          setHandshakes((prevShakes) => {
            const newHandshakes = [...prevShakes];
            arrayFromDocChange(newHandshakes, querySnapshot, (doc) => ({
              data: doc.asModelData(),
              snapshot: doc,
              ref: doc.modelRef,
            }));
            return newHandshakes;
          });
          setHandshakesLoading(false);
        },
        () => {
          setHandshakesLoading(false);
        }
      );
  }, [customer?.ref]);

  const appendLoadedAssetTypes = useCallback(
    (assetTypes: AssetType[]) => {
      setLoadedAssetTypes((prevLoadedAssets) => {
        const unionLoadedAndRequested = new Set([...prevLoadedAssets, ...assetTypes]);

        if (customer?.securityMode === CustomerSecurityMode.RESTRICTED) {
          unionLoadedAndRequested.delete(AssetTypeGoogleCloudProject);
        }

        // if we didn't add anything no point in returning new value
        if (unionLoadedAndRequested.size === prevLoadedAssets.length) {
          return prevLoadedAssets;
        }

        setAssets({});

        return [...unionLoadedAndRequested];
      });
    },
    [customer?.securityMode]
  );

  const { hasGCPAssets, hasAWSAssets, hasResoldAssets, hasAWSStandaloneAssets, hasStandaloneAssets } = useMemo(() => {
    let hasAWSAssets = false;
    let hasAWSStandaloneAssets = false;
    let hasGCPAssets = false;
    let hasResoldAssets = false;
    let hasStandaloneAssets = false;

    if (allAssets) {
      for (const asset of allAssets) {
        if (asset.data.type === AssetTypeAmazonWebServices) {
          hasAWSAssets = true;
        } else if (asset.data.type === AssetTypeAwsStandalone) {
          hasAWSStandaloneAssets = true;
        } else if (asset.data.type === AssetTypeGoogleCloud) {
          hasGCPAssets = true;
        } else if (ProcurementAssetTypes.includes(asset.data.type)) {
          hasResoldAssets = true;
        } else if (StandaloneAssetTypes.includes(asset.data.type)) {
          hasStandaloneAssets = true;
        }
      }
    }
    return { hasAWSAssets, hasAWSStandaloneAssets, hasGCPAssets, hasResoldAssets, hasStandaloneAssets };
  }, [allAssets]);

  useEffect(() => {
    setAssetsLoading(true);
    setAssignedAssetsLoading(true);
    setAllAssets([]);
    setAssets({});
    setHandshakes([]);

    if (!customer?.ref) {
      setAssignedAssetsLoading(false);
      setAssetsLoading(false);
      return;
    }

    return getCollection(AssetModel)
      .where("customer", "==", customer.ref)
      .where("type", "in", loadedAssetsTypes)
      .onSnapshot((querySnapshot) => {
        setAllAssets((prevAllAssets) => {
          const newAllAssets = [...(prevAllAssets ?? [])];
          arrayFromDocChange(newAllAssets, querySnapshot, (doc) => ({
            data: doc.asModelData(),
            id: doc.id,
            snapshot: doc,
            ref: doc.modelRef,
          }));
          return newAllAssets;
        });
      });
  }, [customer?.ref, loadedAssetsTypes]);

  useEffect(() => {
    if (!entities || !allAssets) {
      return;
    }
    const entitiesIds = new Set(entities.map((entity) => entity.id));

    const assets = allAssets.reduce<Assets>((acc, asset) => {
      if (!asset.data.entity) {
        return acc;
      }

      const entityId = asset.data.entity.id;

      if (entitiesIds.has(entityId)) {
        acc[entityId] = acc[entityId] ?? [];
        acc[entityId].push(asset);
      }
      return acc;
    }, {});

    const unassignedAssets = allAssets.reduce<AnyAsset[]>((acc, asset) => {
      if (!asset.data.entity) {
        acc.push(asset);
        return acc;
      }

      return acc;
    }, []);

    setAssets(
      unassignedAssets.length > 0
        ? {
            ...assets,
            _unassigned: unassignedAssets,
          }
        : assets
    );
    setAssetsLoading(false);
    setAssignedAssetsLoading(false);
  }, [allAssets, entities]);

  // verifyEntitiesInvoice
  useEffect(() => {
    if (!entities || !customer?.ref) {
      return;
    }

    const filteredAssetTypes = loadedAssetsTypes.filter(
      (assetType) => assetType !== AssetTypeAwsStandalone && assetType !== AssetTypeGoogleCloudStandalone
    );

    for (const entity of entities) {
      const entityCustomer = entity.customer;
      if (!entityCustomer || !entity.active || entity.invoicing.mode !== "CUSTOM") {
        continue;
      }

      void getCollection(AssetModel)
        .where("customer", "==", entityCustomer)
        .where("entity", "==", entity.ref)
        .where("bucket", "==", null)
        .where("type", "in", filteredAssetTypes)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            return;
          }

          snackbar.onOpen({
            message: "Some of your assets are not assigned to an invoice bucket",
            variant: "warning",
            autoHideDuration: 20000,
            action: [
              <Button
                key="link"
                component={RouterLink}
                to={createLocationDescriptorWithReferrer({
                  pathname: `/customers/${entityCustomer.id}/entities/${entity.id}/edit`,
                  hash: "invoice-settings",
                })}
                aria-label="Update invoice settings"
                variant="contained"
                onClick={() => {
                  snackbar.onClose();
                }}
                color="primary"
              >
                ASSIGN ASSETS
              </Button>,
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  snackbar.onClose();
                }}
                size="large"
              >
                <CloseIcon />
              </IconButton>,
            ],
          });
        });
    }
  }, [customer?.ref, entities, snackbar, loadedAssetsTypes]);

  const value = useMemo(
    () => ({
      assets: assets ?? {},
      assetsLoading,
      appendLoadedAssetTypes,
      hasAWSAssets,
      hasAWSStandaloneAssets,
      hasGCPAssets,
      hasResoldAssets,
      hasStandaloneAssets,
      handshakes,
    }),
    [
      assets,
      appendLoadedAssetTypes,
      assetsLoading,
      handshakes,
      hasAWSAssets,
      hasAWSStandaloneAssets,
      hasGCPAssets,
      hasResoldAssets,
      hasStandaloneAssets,
    ]
  );

  return <assetsContext.Provider value={value}>{children}</assetsContext.Provider>;
};

export const AssetsContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<FullAssetsContextType>;
}) => {
  const actualValue = value ?? {};

  if (!actualValue.appendLoadedAssetTypes) {
    actualValue.appendLoadedAssetTypes = noop;
  }

  return <assetsContext.Provider value={actualValue as FullAssetsContextType}>{children}</assetsContext.Provider>;
};

export function useAssetsContext(): FullAssetsContextType {
  return useContext(assetsContext);
}

const AssetsContextConsumer = assetsContext.Consumer;

type Props = AssetsContextType;

export function withAssets<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent = (props: P) => (
    <AssetsContextConsumer>{(context) => <Component {...context} {...props} />}</AssetsContextConsumer>
  );

  WrappedComponent.displayName = `withAssets(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}
