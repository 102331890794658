import { EntitlementModel, TierModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { type TierFormData } from "./types";

export async function createTier(tierPayload: TierModel) {
  return getCollection(TierModel).add(tierPayload);
}

export async function updateTier(tierPayload: TierFormData, tierId: string) {
  return getCollection(TierModel).doc(tierId).update(tierPayload);
}

// UpdateTierEntitlements updates the full list of entitlements in a tier
export const updateTierEntitlements = async (api: AxiosInstance, tierId: string, entitlementIds: string[]) => {
  await api.patch(`/v1/tiers/${tierId}`, {
    entitlements: entitlementIds,
  });
};

export async function createEntitlement(entitlementPayload: EntitlementModel) {
  return getCollection(EntitlementModel).add(entitlementPayload);
}

export async function updateEntitlement(entitlementPayload: EntitlementModel, entitlementId: string) {
  return getCollection(EntitlementModel).doc(entitlementId).update(entitlementPayload);
}
