import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource } from "@algolia/autocomplete-js";
import { getAlgoliaResults, parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";

import { Highlight } from "../../../../../Components/AlgoliaSearch/Highlight";
import { type AlgoliaIndexType } from "../../../../../Components/AlgoliaSearch/types";
import { ClouflowAlgoliaSearchResultItem } from "../CloudflowAlgoliaSearchResultItem";
import { type AlgoliaCloudflowContext, type BaseCloudflowHit, type CloudflowHit } from "../types";

const createCloudflowPlugin = (indexName: AlgoliaIndexType): AutocompletePlugin<CloudflowHit, undefined> => ({
  getSources({ query, state }) {
    const context = state.context as AlgoliaCloudflowContext;
    if (!query) {
      return [];
    }

    const callback = (item: BaseCloudflowHit) => () => {
      const focusedNodeId = context?.focusedNodeId;
      if (focusedNodeId) {
        context?.customHandler?.(focusedNodeId, item);
        context?.onSelectRow?.(item);
      }
    };

    const cloudflowSource: AutocompleteSource<CloudflowHit> = {
      sourceId: indexName,
      onSelect({ event, item }) {
        if (event.metaKey) {
          event.stopPropagation();
        }
        callback(item)();
      },
      templates: {
        item({ item }) {
          const [titleParts, serviceNameParts, versionParts] = ["operationName", "serviceName", "versionId"].map((a) =>
            parseAlgoliaHitHighlight({
              hit: item,
              attribute: [a],
            })
          );

          return (
            <ClouflowAlgoliaSearchResultItem
              key={item.objectID}
              highlightedTitle={
                <Highlight typographyProps={{ variant: "body1", color: "text.primary" }} parts={titleParts} />
              }
              highlightedServiceName={<Highlight typographyProps={{ variant: "caption" }} parts={serviceNameParts} />}
              highlightedVersion={<Highlight typographyProps={{ variant: "caption" }} parts={versionParts} />}
              provider={item.provider}
              onClick={callback(item)}
              data-testid={item.objectID}
              data-cy="search-result-item"
            />
          );
        },
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              indexName,
              params: {
                query,
                hitsPerPage: 5,
              },
            },
          ],
        });
      },
    };
    return [cloudflowSource];
  },
});

export const useCloudflowPlugin = (indexName: AlgoliaIndexType): AutocompletePlugin<CloudflowHit, undefined> | null =>
  useMemo(() => createCloudflowPlugin(indexName), [indexName]);
