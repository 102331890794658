import { useEffect, useState } from "react";

import { type IntegrationModelGKETablesModel } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { getCustomerGKEMetering } from "../../../Pages/Customers/db";

export const useGKEAdoption = (
  {
    allowNullCustomer,
  }: {
    allowNullCustomer: true | undefined;
  } = { allowNullCustomer: undefined }
): [IntegrationModelGKETablesModel | undefined | null, boolean] => {
  const [loading, setLoading] = useState(true);
  const { customer } = useCustomerContext({ allowNull: allowNullCustomer });
  const [gkeAdoption, setGkeAdoption] = useState<IntegrationModelGKETablesModel | null>();

  useEffect(() => {
    if (!customer?.id) {
      return;
    }
    setLoading(true);

    getCustomerGKEMetering(customer.id)
      .get()
      .then((gkeAdoptionDoc) => {
        const data = gkeAdoptionDoc?.asModelData() ?? null;
        setGkeAdoption(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customer?.id]);

  return [gkeAdoption, loading];
};
