import { type MouseEventHandler, useState } from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "@mui/styles";

import LoadingButton from "../../../Components/LoadingButton";

const useKnownIssueDialogFooterStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

export type KnownIssueDialogFooterProps = {
  onSubmit: () => Promise<void>;
  onClose: MouseEventHandler;
  submitButtonText: string;
  isSubmitAllowed?: boolean;
};

export const KnownIssueDialogFooter = ({
  onSubmit,
  onClose,
  submitButtonText,
  isSubmitAllowed,
}: KnownIssueDialogFooterProps) => {
  const classes = useKnownIssueDialogFooterStyle();

  const [loading, setLoading] = useState(false);

  const isSubmitDisabled = loading || !isSubmitAllowed;

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  };

  return (
    <DialogActions className={classes.root}>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <LoadingButton
        onClick={handleSubmit}
        loading={loading}
        disabled={isSubmitDisabled}
        variant="contained"
        color="primary"
        mixpanelEventId="known-issue.dialog.submit"
      >
        {submitButtonText}
      </LoadingButton>
    </DialogActions>
  );
};
