export const bulkPurchaseDataStudioReportBaseUrl =
  "https://lookerstudio.google.com/u/0/reporting/72291aa5-655b-4de4-ac3a-e0297429c755";

export const allWorkloadsReportUrl = `${bulkPurchaseDataStudioReportBaseUrl}/page/p_9rtlsinl5c`;

export const buildWorkloadsSkuDataStudioUrl = (data: { hardware: string; familyType: string; region: string }) => {
  const params = { region: data.region, family: data.familyType, hardware: data.hardware };
  return `${bulkPurchaseDataStudioReportBaseUrl}/page/Qg1FD?params=${encodeURIComponent(JSON.stringify(params))}`;
};

export function buildDetailsUrl(data: { billingAccountId: string; hardware: string; type: string; region: string }) {
  const params = { ba: data.billingAccountId, hardware: data.hardware, family: data.type, region: data.region };
  return `https://datastudio.google.com/u/0/reporting/7dad6b18-27c3-46ba-8a1b-ed84d7eb0ce0/page/p_l96kotx6rc?params=${encodeURIComponent(
    JSON.stringify(params)
  )}`;
}

export function buildBaselineUrl(data: { billingAccountId: string; hardware: string; type: string; region: string }) {
  const params = { bai: data.billingAccountId, hw: data.hardware, ft: data.type, rn: data.region };
  return `https://lookerstudio.google.com/u/0/reporting/7dad6b18-27c3-46ba-8a1b-ed84d7eb0ce0/page/p_tllgkrc55c?params=${encodeURIComponent(
    JSON.stringify(params)
  )}`;
}
