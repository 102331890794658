import { useHits } from "react-instantsearch";
import { Box, Grid } from "@mui/material";

import { type Hit } from "../utils/types";
import { PersonCard } from "./PersonCard";

type CustomHitsProps = {
  itemsToRender?: number;
};

export const CustomHits = ({ itemsToRender = Infinity }: CustomHitsProps) => {
  const { items } = useHits<Hit>();

  const limitedItems = items.slice(0, itemsToRender);

  return (
    <Box display="flex" justifyContent="center" width="100%">
      <Grid container spacing={2} justifyContent="center">
        {limitedItems.map((item) => (
          <Grid item key={item.objectID}>
            <PersonCard hit={item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
