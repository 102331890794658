import { CloudAnalyticsModel, type RunReportChannelStatus } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { serverTimestamp } from "../../../utils/firebase";

export const createRunReportChannel = async ({
  uid,
  email,
  status,
}: {
  uid: string;
  email: string;
  status: RunReportChannelStatus;
}) => {
  const docRef = await getCollection(CloudAnalyticsModel)
    .doc("reports")
    .collection("cloudAnalyticsRunReportChannels")
    .add({
      uid,
      email,
      status,
      timeUpdated: serverTimestamp(),
      expireBy: DateTime.utc().plus({ days: 1 }).toJSDate(),
    });

  return docRef.id;
};
