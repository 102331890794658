import { createContext, type ReactNode, useContext } from "react";

import isEqual from "lodash/isEqual";

import { useApiContext } from "../api/context";
import { useLocalStorage } from "../Components/hooks/storageHooks";
import useMountEffect from "../Components/hooks/useMountEffect";
import { consoleErrorWithSentry } from "../utils";
import { isPartnerDomain } from "../utils/domains";

export type PartnerData = {
  name: string;
  branding: {
    partnerLogoURL: string;
    partnerFavIcon: string;
    privacyPolicyURL: string;
    preferenceCenterURL: string;
  };
};

const partnerDataContext = createContext<PartnerData | null>(null);

export const PartnerDataContextProvider = ({ children }: { children?: ReactNode }) => {
  const [savedPartnerData, persistPartnersData] = useLocalStorage<PartnerData | null>("partnerData", null);
  const api = useApiContext();

  useMountEffect(() => {
    if (!isPartnerDomain(window.location.hostname)) {
      return;
    }

    const fetchDataOnce = async () => {
      try {
        const { data } = await api.get("/partners/data");
        if (!isEqual(savedPartnerData, data)) {
          persistPartnersData(data);
        }
      } catch (e) {
        consoleErrorWithSentry(e);
      }
    };

    fetchDataOnce();
  });

  return <partnerDataContext.Provider value={savedPartnerData}>{children}</partnerDataContext.Provider>;
};

export const usePartnerData = (): PartnerData | null => useContext(partnerDataContext);
