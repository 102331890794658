import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AnalyticsDataSource, IDs, Keys, Metadata, ReportOrgMetadataModel } from "@doitintl/cmp-models";
import { getCollectionGroup, type QueryDocumentSnapshotModel, useCollectionData } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useOrgsContext } from "../../../Context/customer/OrgsProvider";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { getAttributionGroupMetadata } from "../../../Pages/CloudAnalytics/attributionGroups/api";
import { getOrganizationRef } from "../../../Pages/CloudAnalytics/utilities";
import { type Customer } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { getCachingKeys } from "../../../utils/cachingKeys";
import { groupByDataSource } from "./useAnalyticsDimensions/analyticsDimensionsParser";

export type AnalyticsMetadata = QueryDocumentSnapshotModel<ReportOrgMetadataModel>[];

const useHasCustomerMetadataContext = (customerOrPresentationModeCustomer: Customer | undefined | null) => {
  const [hasMetadata, setHasMetadata] = useState<boolean>();
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization } = useOrgsContext();

  const query = useMemo(() => {
    if (!customerOrPresentationModeCustomer?.ref || isDoitEmployee === undefined) {
      return;
    }

    const orgRef = getOrganizationRef(isDoitEmployee, userOrganization, customerOrPresentationModeCustomer.ref.id);

    return getCollectionGroup(ReportOrgMetadataModel)
      .where("customer", "==", customerOrPresentationModeCustomer.ref)
      .where("organization", "==", orgRef)
      .limit(1);
  }, [customerOrPresentationModeCustomer?.ref, isDoitEmployee, userOrganization]);

  const [data] = useCollectionData(query, {
    idField: "id",
    caching: true,
    cachingKeys: getCachingKeys(customerOrPresentationModeCustomer?.ref.id),
  });

  useEffect(() => {
    if (!data) {
      setHasMetadata(undefined);
      return;
    }

    setHasMetadata(data.length > 0);
  }, [data]);

  return [hasMetadata];
};

export const useHasCustomerMetadata = () => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();
  return useHasCustomerMetadataContext(customerOrPresentationModeCustomer);
};

export const useAnalyticsMetadata = (
  customer: Customer | undefined | null,
  customerOrPresentationModeCustomer: Customer | undefined | null
): [AnalyticsMetadata, boolean, boolean | undefined, boolean, () => void, () => void, boolean] => {
  const { attributions } = useAttributionsContext();
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization } = useOrgsContext();
  const api = useApiContext();

  const [metadata, setMetadata] = useState<AnalyticsMetadata>([]);
  const [hasMetadata] = useHasCustomerMetadataContext(customerOrPresentationModeCustomer);
  const [attributionGroupsMetadata, setAttributionGroupsMetadata] = useState<AnalyticsMetadata>([]);
  const [firestoreLoading, setFirestoreLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(true);
  const [prevCustomer, setPrevCustomer] = useState<Customer | undefined | null>(null);
  const isFetchingMetadata = useRef(false);
  const isFetchingAGMetadata = useRef(false);
  const isDataHubEntitled = useIsFeatureEntitled("pdi:datahub");

  const clearMetadata = () => {
    setMetadata([]);
    setAttributionGroupsMetadata([]);
  };

  useEffect(() => {
    let isMounted = true;

    if (isDoitEmployee && prevCustomer?.id !== customer?.id) {
      clearMetadata();
      setPrevCustomer(customer);
    }

    return () => {
      isMounted = false;
      if (!isMounted) {
        isFetchingMetadata.current = false;
        isFetchingAGMetadata.current = false;
      }
    };
  }, [customer, isDoitEmployee, prevCustomer]);

  const fetchAttributionGroupsMetadata = useCallback(async () => {
    if (isFetchingAGMetadata.current) {
      return;
    }
    isFetchingAGMetadata.current = true;
    setApiLoading(true);

    const attributionGroupMetadata = await getAttributionGroupMetadata(api, customer?.id ?? "");

    setAttributionGroupsMetadata(attributionGroupMetadata);
    setApiLoading(false);
    isFetchingAGMetadata.current = false;
  }, [api, customer?.id]);

  const fetchMetadata = useCallback(
    async (withLabels?: boolean, withAttributionGroups?: boolean) => {
      if (isDoitEmployee === undefined) {
        return;
      }

      if (attributionGroupsMetadata.length === 0 && withAttributionGroups) {
        fetchAttributionGroupsMetadata();
      }

      if (metadata.length > 0 || isFetchingMetadata.current) {
        return;
      }

      setFirestoreLoading(true);
      isFetchingMetadata.current = true;

      const typesToLoad: Metadata[] = [Metadata.FIXED, Metadata.DATETIME, Metadata.ATTRIBUTION].concat(
        withLabels ? [Metadata.LABEL, Metadata.PROJECT_LABEL, Metadata.SYSTEM_LABEL] : [Metadata.OPTIONAL]
      );

      const orgRef = getOrganizationRef(isDoitEmployee, userOrganization, customerOrPresentationModeCustomer?.id ?? "");
      const query = getCollectionGroup(ReportOrgMetadataModel)
        .where("customer", "==", customerOrPresentationModeCustomer?.ref)
        .where("organization", "==", orgRef)
        .where("type", "in", typesToLoad);

      try {
        const querySnap = await query.get();
        setMetadata(querySnap.docs);
        isFetchingMetadata.current = false;
      } catch (error) {
        consoleErrorWithSentry(error);
      }

      setFirestoreLoading(false);
    },
    [
      attributionGroupsMetadata.length,
      customerOrPresentationModeCustomer?.id,
      customerOrPresentationModeCustomer?.ref,
      fetchAttributionGroupsMetadata,
      isDoitEmployee,
      metadata.length,
      userOrganization,
    ]
  );

  useEffect(() => {
    attributionGroupsMetadata.forEach((attributionGroup) => {
      if (attributions) {
        attributionGroup.data().values?.sort((a, b) => {
          const aName = attributions.find((attr) => attr.ref.id === a)?.data.name ?? "";
          const bName = attributions.find((attr) => attr.ref.id === b)?.data.name ?? "";
          return aName.localeCompare(bName);
        });
      }
    });
  }, [attributionGroupsMetadata, attributions]);

  const filteredMetadata = useMemo(
    () => [...metadata, ...attributionGroupsMetadata],
    [metadata, attributionGroupsMetadata]
  );

  const hasDataHub = useMemo(() => {
    if (!isDataHubEntitled) {
      return false;
    }
    const dataSourceQueries = groupByDataSource(metadata);
    const dataSourcesFromMd = Object.keys(dataSourceQueries);
    return dataSourcesFromMd.includes(AnalyticsDataSource.BILLING_DATAHUB);
  }, [isDataHubEntitled, metadata]);

  const hasEKSData = useMemo(() => {
    // search for eks:cluster-name label in metadata
    const labelKeys = metadata.find((md) => md.id === IDs.OptionalLabels)?.data();
    return !!labelKeys?.values?.includes(Keys.eksClusterName);
  }, [metadata]);

  const initialLoading = apiLoading || (firestoreLoading && metadata.length === 0);
  return [
    filteredMetadata,
    initialLoading,
    hasMetadata,
    hasDataHub,
    fetchMetadata,
    fetchAttributionGroupsMetadata,
    hasEKSData,
  ];
};
