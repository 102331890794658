import { useMemo } from "react";

import { CloudAnalyticsModel, type CloudAnalyticsModelBudgetModel, Roles } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";

import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useTier } from "../../../../Context/TierProvider";
import { getCachingKeys } from "../../../../utils/cachingKeys";
import type { Budget } from "../../../../types";

export const budgetsTransform = (
  data: WithFirebaseModel<CloudAnalyticsModelBudgetModel>,
  snapshot: DocumentSnapshotModel<CloudAnalyticsModelBudgetModel>
): Budget => {
  const transformedData = {
    ...data,
    owner: "",
    currentPercentage: 0,
    varianceToDate: 0,
    budgetAmountToDate: 0,
  };

  for (const collaborator of data.collaborators ?? []) {
    if (collaborator.role === Roles.OWNER) {
      transformedData.owner = collaborator.email;
      break;
    }
  }

  if (data.utilization && data.config.amount > 0) {
    transformedData.currentPercentage = (data.utilization.current / data.config.amount) * 100;
  }

  return {
    data: transformedData,
    snapshot,
    id: snapshot.id,
    ref: snapshot.modelRef,
  };
};

export const useBudgets = (): [Budget[], Budget[], boolean] => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const { getFeatureLimit } = useTier();
  const featureLimit = getFeatureLimit("governance:budgets") as number | null;
  const collectionRef = useMemo(
    () => getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets"),
    []
  );

  const publicBudgetsQuery = useMemo(() => {
    let query = collectionRef.where("customer", "==", customer.ref);
    if (!isDoitEmployee) {
      query = query.where("public", "in", [Roles.VIEWER, Roles.EDITOR]);
    }
    if (!isDoitEmployee && featureLimit) {
      query = query.limit(featureLimit).orderBy("timeCreated", "desc");
    }
    return query;
  }, [collectionRef, customer.ref, featureLimit, isDoitEmployee]);

  const sharedBudgetsQuery = useMemo(() => {
    let query = collectionRef.where("customer", "==", customer.ref).where("public", "==", null);
    if (!isDoitEmployee) {
      query = query.where("collaborators", "array-contains-any", [
        { email: currentUser.email, role: Roles.OWNER },
        { email: currentUser.email, role: Roles.VIEWER },
        { email: currentUser.email, role: Roles.EDITOR },
      ]);
    }
    if (!isDoitEmployee && featureLimit) {
      query = query.limit(featureLimit);
    }
    return query.orderBy("timeCreated", "desc");
  }, [collectionRef, customer.ref, isDoitEmployee, featureLimit, currentUser.email]);

  const [publicBudgetsData, loadingPublicBudgets] = useCollectionData(publicBudgetsQuery, {
    transform: budgetsTransform,
    caching: true,
    cachingKeys: getCachingKeys(customer.ref.id),
  });

  const [sharedBudgetsData, loadingSharedBudgets] = useCollectionData(sharedBudgetsQuery, {
    transform: budgetsTransform,
    caching: true,
    cachingKeys: getCachingKeys(customer.ref.id),
  });

  const budgets = useMemo(
    () => [...(publicBudgetsData ?? []), ...(sharedBudgetsData ?? [])],
    [publicBudgetsData, sharedBudgetsData]
  );

  const filteredBudgets = useMemo((): Budget[] => {
    const nonDraftBudgets = budgets.filter((budget) => !budget.data.draft);

    // this is needed since the doer queries can return the same budget multiple times
    return nonDraftBudgets.reduce<typeof nonDraftBudgets>(
      (unique, budget) => (unique.some((item) => item.id === budget.id) ? unique : [...unique, budget]),
      []
    );
  }, [budgets]);

  const sortedAndLimitedBudgets = useMemo(() => {
    const sortedBudgets = [...filteredBudgets].sort(
      (a, b) => (b.data.timeCreated?.toDate().getTime() || 0) - (a.data.timeCreated?.toDate().getTime() || 0)
    );

    if (featureLimit !== null) {
      return sortedBudgets.slice(0, featureLimit);
    }

    return sortedBudgets;
  }, [featureLimit, filteredBudgets]);

  const loading = loadingPublicBudgets || loadingSharedBudgets;

  return [budgets, sortedAndLimitedBudgets, loading];
};
