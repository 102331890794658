import {
  type FlexsaveCloudSavingsModel,
  type FlexsaveConfigurationModel,
  type FlexsaveType,
  FlexsaveTypes,
  type MonthSavings,
  type PaymentMethod,
  PaymentMethodType,
} from "@doitintl/cmp-models";
import sumBy from "lodash/sumBy";
import { DateTime } from "luxon";

import { type ActiveFlexsaveSavings, type EnabledSavings, type FlexsaveData, type ReasonCantEnableGCP } from "../types";
import {
  getDateByMM_YYYY,
  getMonthLabelFromMonthId,
  getPreviousHistoryMonthLabel,
  getPreviousMonthsStrings,
} from "./dateUtils";

const getCurrentMonth = (savings: FlexsaveCloudSavingsModel) => savings.savingsSummary?.currentMonth?.month;

/**
 * Prepare savings with needed format of savings
 * Need to make sure the to sync the data
 * @param type
 * @param savings
 * @param actualCurrentMonthId
 * @param timeEnabled
 */
function parseActiveSavings(
  type: FlexsaveType,
  savings: FlexsaveCloudSavingsModel,
  actualCurrentMonthId: string,
  timeEnabled: Date | undefined
): ActiveFlexsaveSavings {
  const currentMonthId = getCurrentMonth(savings);

  const nextMonth = savings.savingsSummary.nextMonth;
  const currentMonth = savings.savingsHistory[currentMonthId];

  const history = new Map<string, MonthSavings>();

  for (const [monthId, monthSavings] of Object.entries(savings.savingsHistory)) {
    const date = getDateByMM_YYYY(monthId);
    const monthLabel = getMonthLabelFromMonthId(monthId);

    // don't return savings if the months are before flexsave was enabled
    if (
      !timeEnabled ||
      (timeEnabled &&
        (date.getFullYear() > timeEnabled.getFullYear() ||
          (timeEnabled.getFullYear() === date.getFullYear() && date.getMonth() >= timeEnabled.getMonth())))
    ) {
      const redistributedData = redistributeSavingsData(monthSavings);
      history.set(monthLabel, redistributedData);
    } else {
      history.set(monthLabel, {
        totalOnDemandSpend: monthSavings.onDemandSpend,
        onDemandSpend: -1,
        savings: -1,
      });
    }
  }

  // in case where data is not synced between the two providers need to adjust months
  if (actualCurrentMonthId !== currentMonthId) {
    const currentMonthLabel = getMonthLabelFromMonthId(actualCurrentMonthId);
    // delete the current month, so it won't be displayed in the chart until full sync is done
    history.delete(currentMonthLabel);
  }

  const previousMonthLabel = getPreviousHistoryMonthLabel(actualCurrentMonthId, 1);
  const previousMonth = history.get(previousMonthLabel);

  return {
    type,
    nextMonth,
    currentMonth,
    previousMonth,
    history,
  };
}

function getMinCurrentMonth(configuration: FlexsaveConfigurationModel) {
  const currentMonths: string[] = [];
  for (const currentConfiguration of Object.values(configuration)) {
    if (currentConfiguration.enabled || currentConfiguration.disableNotified) {
      currentMonths.push(currentConfiguration.savingsSummary.currentMonth.month);
    }
  }
  if (currentMonths.length === 2) {
    return getDateByMM_YYYY(currentMonths[0]) < getDateByMM_YYYY(currentMonths[1])
      ? currentMonths[0]
      : currentMonths[1];
  } else {
    return currentMonths[0];
  }
}

function getTimeEnabled(configuration: FlexsaveConfigurationModel) {
  if (configuration.AWS?.enabled) {
    return configuration.AWS?.timeEnabled?.toDate();
  } else if (configuration.GCP?.enabled || configuration.GCP?.disableNotified) {
    return configuration.GCP?.operations?.optIn.timeOptIn.toDate();
  }
}

export function parseEnabledConfiguration(configuration: FlexsaveConfigurationModel): EnabledSavings | undefined {
  if (!configuration.AWS?.enabled && !configuration.GCP?.enabled && !configuration.GCP?.disableNotified) {
    return;
  }
  const timeEnabled = getTimeEnabled(configuration);

  const actualCurrentMonth = getMinCurrentMonth(configuration);
  const activeSavings: ActiveFlexsaveSavings[] = [];

  for (const [typeStr, currentSavings] of Object.entries(configuration)) {
    const type: FlexsaveType = FlexsaveTypes[typeStr];
    const hasSavingsHistory = currentSavings.savingsHistory && Object.keys(currentSavings.savingsHistory).length > 0;
    if ((currentSavings.enabled || currentSavings.disableNotified) && hasSavingsHistory) {
      activeSavings.push(parseActiveSavings(type, currentSavings, actualCurrentMonth, timeEnabled));
    }
  }

  // hold the months that will be used in the chart
  const months = getPreviousMonthsStrings(actualCurrentMonth, 7, activeSavings);

  const enabledSavings: EnabledSavings = {
    months,
    currentMonthId: actualCurrentMonth,
    savings: activeSavings,
    disableNotified: !!configuration.GCP?.disableNotified,
  };

  if (configuration.GCP?.enabled || configuration.GCP?.disableNotified) {
    enabledSavings.timeOptIn = DateTime.fromMillis(timeEnabled?.getTime() ?? 0);
  }

  return enabledSavings;
}

export function parseFlexsaveConfiguration(data: FlexsaveConfigurationModel, filterType: FlexsaveType): FlexsaveData {
  const configuration = data[filterType];

  if (!configuration) {
    return {};
  }

  const filteredConfiguration: FlexsaveConfigurationModel = {
    [filterType]: configuration,
  };

  if (configuration.enabled || data.GCP?.disableNotified) {
    return {
      enabled: parseEnabledConfiguration(filteredConfiguration),
    };
  }

  if (!configuration.reasonCantEnable && configuration?.savingsSummary?.nextMonth) {
    const monthSavings: MonthSavings[] = Object.values(configuration?.savingsHistory ?? {});

    const savings = Math.round(sumBy(monthSavings, "savings"));
    return {
      potentialFlexSaveSavings: {
        type: filterType,
        nextMonth: configuration?.savingsSummary.nextMonth,
        hasBeenDisabled: savings > 0,
      },
      flexsaveEligibleUsageDays: data?.GCP?.savingsSummary.flexsaveEligibleUsageDays,
    };
  }

  return {
    info: {
      type: filterType,
      reason: configuration.reasonCantEnable as ReasonCantEnableGCP,
    },
    flexsaveEligibleUsageDays: data?.GCP?.savingsSummary.flexsaveEligibleUsageDays,
  };
}

export const parsePaymentMethod = (paymentMethod: PaymentMethod): string => {
  switch (paymentMethod) {
    case PaymentMethodType.USBankAccount:
    case PaymentMethodType.BankAccount:
      return "Bank Account";
    case PaymentMethodType.BillCom:
      return "Bill.com";
    case PaymentMethodType.CreditCard:
      return "Credit Card";
    case PaymentMethodType.WireTransfer:
      return "Wire Transfer";
    case PaymentMethodType.SepaDebit:
      return "SEPA Debit";
    default:
      return "N/A";
  }
};

function redistributeSavingsData(monthSavingsAndSpend: MonthSavings): MonthSavings {
  const totalOnDemandSpend = monthSavingsAndSpend.onDemandSpend + monthSavingsAndSpend.savings;

  return {
    totalOnDemandSpend,
    onDemandSpend: monthSavingsAndSpend.onDemandSpend,
    savings: monthSavingsAndSpend.savings,
  };
}
