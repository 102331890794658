import { useParams } from "react-router-dom";
import { CurrencyCodes, TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { Box, Paper, Skeleton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../../Components/DefinitionList";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { formatValueWithCurrency } from "../../../Components/hooks/useFormatter";
import { MetadataCard } from "../../../Components/MetadataCard";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { sanitizeDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { BudgetTypes, type TimeRangeOption } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import PerformanceChart from "./PerformanceChart";
import { getAlertAmountFromPercentage } from "./shared";
import {
  buildRange,
  filterByCurrentDay,
  filterByCurrentMonth,
  filterByCurrentQuarter,
  filterByCurrentWeek,
  filterByCurrentYear,
  getStartAndEndOfDayUTC,
  getStartAndEndOfMonthUTC,
  getStartAndEndOfQuarterUTC,
  getStartAndEndOfWeekUTC,
  getStartAndEndOfYearUTC,
  makeDataCumulative,
  populateActualsYValues,
  populateForecastedYValues,
  previewAmountByInterval,
} from "./utils";
import { ViewBudgetHeader } from "./ViewBudgetHeader";

const previewTimeByInterval = {
  [TimeInterval.DAY]: TimeInterval.HOUR,
  [TimeInterval.WEEK]: TimeInterval.DAY,
  [TimeInterval.MONTH]: TimeInterval.DAY,
  [TimeInterval.QUARTER]: TimeInterval.WEEK,
  [TimeInterval.YEAR]: TimeInterval.MONTH,
};

const rangeByInterval = {
  [TimeInterval.DAY]: getStartAndEndOfDayUTC(),
  [TimeInterval.WEEK]: getStartAndEndOfWeekUTC(),
  [TimeInterval.MONTH]: getStartAndEndOfMonthUTC(),
  [TimeInterval.QUARTER]: getStartAndEndOfQuarterUTC(),
  [TimeInterval.YEAR]: getStartAndEndOfYearUTC(),
};

const filterForecastedByInterval = {
  [TimeInterval.DAY]: filterByCurrentDay,
  [TimeInterval.WEEK]: filterByCurrentWeek,
  [TimeInterval.MONTH]: filterByCurrentMonth,
  [TimeInterval.QUARTER]: filterByCurrentQuarter,
  [TimeInterval.YEAR]: filterByCurrentYear,
};

export const ViewBudget = () => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const [, budgets] = useBudgets();

  const { budgetId } = useParams<{ budgetId: string }>();
  const theme = useTheme();

  const variancePositiveColor = theme.palette.success.main;
  const varianceNegativeColor = theme.palette.error.main;

  const budget = budgets.find((b) => b.snapshot.id === budgetId);
  const amount = budget?.data.config.amount || 1000;
  const alerts = budget?.data.config.alerts.map((a) => ({
    ...a,
    amount: getAlertAmountFromPercentage(a.percentage, amount),
  }));

  const scope = budget && getAttributionScope(budget.data.config.scope, attributions);
  const timeInterval = budget?.data.config.timeInterval;
  const actual = budget?.data?.utilization?.current || 0;
  const forecasted = budget?.data?.utilization?.forecasted || 0;
  const type = budget?.data.config.type;
  const frequency = type === BudgetTypes.RECURRING ? "recurring" : "one-time";
  const currency = budget?.data.config.currency || CurrencyCodes.USD;
  const startPeriod = budget?.data.config.startPeriod
    ? DateTime.fromMillis(budget?.data.config.startPeriod.seconds * 1000).toUTC()
    : sanitizeDate(DateTime.utc());
  const endPeriod = budget?.data.config.endPeriod
    ? DateTime.fromMillis(budget?.data.config.endPeriod.seconds * 1000).toUTC()
    : sanitizeDate(DateTime.utc());
  const isBudgetEndDateExists = !!budget?.data?.config?.endPeriod;
  const budgetDateTerm = `Budget ${isBudgetEndDateExists ? "dates:" : "start date"}`;
  const budgetDates = `${startPeriod.toFormat("d MMM yyyy")}${isBudgetEndDateExists ? ` - ${endPeriod.toFormat("d MMM yyyy")}` : ""}`;
  const forecastedDateSeconds = budget?.data.utilization?.forecastedTotalAmountDate?.seconds;

  const previewTime = timeInterval && previewTimeByInterval[timeInterval];

  const actualDataPreviewAmount = timeInterval && previewAmountByInterval[timeInterval];

  const newTimeRangeOptions: TimeRangeOption = {
    mode:
      timeInterval === TimeInterval.DAY ||
      timeInterval === TimeInterval.WEEK ||
      timeInterval === TimeInterval.MONTH ||
      timeInterval === TimeInterval.QUARTER ||
      timeInterval === TimeInterval.YEAR
        ? TimeSettingsMode.Fixed
        : TimeSettingsMode.Last,
    time: previewTime,
    amount: actualDataPreviewAmount,
    includeCurrent: true,
  };

  const range = timeInterval && rangeByInterval[timeInterval];
  if (range) {
    newTimeRangeOptions.range = range;
  }

  const newTimeInterval =
    (range && type === BudgetTypes.RECURRING
      ? buildRange(range.start, range.end, timeInterval)
      : buildRange(
          budget?.data.config.startPeriod
            ? DateTime.fromMillis(budget.data.config.startPeriod.seconds * 1000)
            : DateTime.utc(),
          budget?.data.config.endPeriod
            ? DateTime.fromMillis(budget.data.config.endPeriod.seconds * 1000)
            : DateTime.utc(),
          timeInterval ?? TimeInterval.DAY
        )) || [];

  const isForecastedDateInBudgetRange = (forecastedDate: number): boolean => {
    const forecastedDateToUTC = DateTime.fromMillis(forecastedDate * 1000).toUTC();
    return forecastedDateToUTC >= startPeriod && forecastedDateToUTC <= endPeriod;
  };

  const { isMobile } = useFullScreen("lg");
  const formatBudgetValue = (value: number) => formatValueWithCurrency(value, 2, currency, false);

  // Budget amount total data
  const budgetAmountData = newTimeInterval.map((timestamp) => ({ x: timestamp, y: amount }));

  // Actual data
  const actualDataRaw = newTimeInterval
    .slice(0, actualDataPreviewAmount)
    .map((timestamp, index) => ({ x: timestamp, y: index === actualDataPreviewAmount - 1 ? actual : 0 }));
  const actualData = populateActualsYValues(actualDataRaw, actual);
  const actualDataDates = actualData.map((i) => i.x);
  const lastActualData = actualData[actualData.length - 1];

  // Forecast data
  const forecastDataPeriod = newTimeInterval.slice(actualDataPreviewAmount);
  const forecastDataRaw = forecastDataPeriod.map((timestamp, index) => ({
    x: timestamp,
    y: index === forecastDataPeriod.length - 1 ? forecasted : 0,
  }));
  const forecastData = populateForecastedYValues(forecastDataRaw, forecasted);
  const cumulativeForecast = makeDataCumulative(
    forecastData?.filter((forecastItem) => !actualDataDates.includes(forecastItem.x)),
    lastActualData?.y
  );
  const forecastForTimeInterval = timeInterval && filterForecastedByInterval[timeInterval](cumulativeForecast);
  const forecastWithoutDuplicates =
    forecastForTimeInterval?.filter((forecastItem: { x: any }) => !actualDataDates.includes(forecastItem.x)) || [];

  const lastAlert = alerts?.[alerts.length - 1];
  const maxBudget = lastAlert?.amount || 0;
  const maxBudgetByInterval = maxBudget / newTimeInterval.length;

  // Budget amount to date data
  const budgetData = newTimeInterval.slice(0, actualDataPreviewAmount).map((i, index) => ({
    x: i,
    y: maxBudgetByInterval * (index + 1),
  }));

  // Current period data
  const budgetAmountToDate = budgetData[budgetData.length - 1]?.y ?? null;
  const forecastAmountForEntirePeriod =
    forecastWithoutDuplicates.length && type === BudgetTypes.RECURRING
      ? forecastWithoutDuplicates[forecastWithoutDuplicates.length - 1]?.y
      : forecasted;
  const variance = budget ? budgetAmountToDate - actual : null;

  // logic to connect two lines on chart
  const lastDataPoint = actualData[actualData.length - 1];

  if (lastDataPoint) {
    forecastWithoutDuplicates?.splice(0, 0, lastDataPoint);
  }

  const formatTimeIntervalPhrase = (interval: TimeInterval) =>
    interval === TimeInterval.DAY ? "daily" : `${interval}ly`;

  const getFrequencyPhrase = (interval: TimeInterval, frequency: string) => {
    const intervalString = formatTimeIntervalPhrase(interval);
    return `${capitalize(intervalString)} ${frequency}`;
  };

  const showSkeleton = !budget && !newTimeInterval.length;
  const showEmptyInfo = !budget;

  return (
    <>
      <ViewBudgetHeader name={budget?.data.name || ""} />
      <Stack data-testid="ramp-page" flexDirection="column" flexWrap="nowrap" gap={2.4}>
        {showSkeleton && (
          <Skeleton animation="wave" variant="rectangular" width="100%" height="397px" sx={{ borderRadius: 0.5 }} />
        )}
        {showEmptyInfo && !showSkeleton && (
          <Stack
            component={Paper}
            variant="outlined"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={414}
          >
            <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>Data available soon</Typography>
            <Box sx={{ width: "397px", textAlign: "center" }}>
              {`${capitalize(timeInterval)}ly budgets are displayed at a ${formatTimeIntervalPhrase(previewTime)} granularity. Data will start being displayed after one ${previewTime}.`}
            </Box>
          </Stack>
        )}
        {!showSkeleton && !showEmptyInfo && (
          <PerformanceChart
            actual={actualData}
            budget={budgetData}
            forecast={forecastWithoutDuplicates}
            alerts={alerts || []}
            amount={budgetAmountData}
            currency={currency}
          />
        )}
        <Stack direction={isMobile ? "column" : "row"} justifyContent="stretch" spacing={2}>
          <MetadataCard title="Budget summary">
            <DefinitionList>
              <DefinitionListTerm>{budgetTxt.BUDGET_AMOUNT_TERM}</DefinitionListTerm>
              <DefinitionListDesc>{formatBudgetValue(amount)}</DefinitionListDesc>
              <DefinitionListTerm>{budgetTxt.TYPE_AND_FREQUENCY}</DefinitionListTerm>
              <DefinitionListDesc>{getFrequencyPhrase(timeInterval ?? TimeInterval.DAY, frequency)}</DefinitionListDesc>
              <DefinitionListTerm>{budgetDateTerm}</DefinitionListTerm>
              <DefinitionListDesc>{budgetDates}</DefinitionListDesc>
              <DefinitionListTerm>{budgetTxt.DIMENSION}</DefinitionListTerm>
              <DefinitionListDesc>{scope?.map((scopeItem) => scopeItem?.data?.name).join(", ")}</DefinitionListDesc>
              {/* <DefinitionListTerm>Budget configuration:</DefinitionListTerm>
              <DefinitionListDesc>Budget tracks against a single period budgeted amount</DefinitionListDesc>
              <DefinitionListTerm>Amortized cost:</DefinitionListTerm>
              <DefinitionListDesc>No</DefinitionListDesc> */}
            </DefinitionList>
          </MetadataCard>
          <MetadataCard title="Current period">
            <DefinitionList>
              <DefinitionListTerm>{budgetTxt.BUDGET_AMOUNT_TO_DATE}</DefinitionListTerm>
              <DefinitionListDesc>
                {budgetAmountToDate ? formatBudgetValue(budgetAmountToDate) : budgetTxt.DATA_LOADING}
              </DefinitionListDesc>
              <DefinitionListTerm>{budgetTxt.ACTUALS_TO_DATE}</DefinitionListTerm>
              <DefinitionListDesc>{formatBudgetValue(actual)}</DefinitionListDesc>
              <DefinitionListTerm>{budgetTxt.VARIANS_TO_DATE}</DefinitionListTerm>
              {variance ? (
                <DefinitionListDesc color={variance < 0 ? varianceNegativeColor : variancePositiveColor}>
                  {formatBudgetValue(variance)}
                </DefinitionListDesc>
              ) : (
                <DefinitionListDesc>{budgetTxt.DATA_LOADING}</DefinitionListDesc>
              )}
              <DefinitionListTerm>{budgetTxt.FORECASTED_AMOUNT_FOR_ENTIRE_PERIOD}</DefinitionListTerm>
              <DefinitionListDesc>{formatBudgetValue(forecastAmountForEntirePeriod)}</DefinitionListDesc>
              <>
                {forecastedDateSeconds ? (
                  isForecastedDateInBudgetRange(forecastedDateSeconds) && (
                    <>
                      <DefinitionListTerm>{budgetTxt.FORECASTED_TO_HIT_BUDGET_AMOUNT}</DefinitionListTerm>
                      <DefinitionListDesc>
                        {DateTime.fromSeconds(forecastedDateSeconds).toFormat("d MMMM yyyy")}
                      </DefinitionListDesc>
                    </>
                  )
                ) : (
                  <>
                    <DefinitionListTerm>{budgetTxt.FORECASTED_TO_HIT_BUDGET_AMOUNT}</DefinitionListTerm>
                    <DefinitionListDesc>{budgetTxt.NO_FORECASTED_DATE}</DefinitionListDesc>
                  </>
                )}
              </>
            </DefinitionList>
          </MetadataCard>
        </Stack>
      </Stack>
    </>
  );
};
