import { type InviteModel, type UserModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { Box, Typography } from "@mui/material";

import { type UserOrInvite } from "../../../types";
import { UserEmailNotifications } from "./UserEmailNotifications";

type UserNotificationsProps = {
  userRef: ModelReference<UserModel> | ModelReference<InviteModel> | undefined;
  userOrInvite: UserOrInvite;
  permissions: Set<string> | undefined;
};

export const UserNotifications = (props: UserNotificationsProps) => (
  <Box sx={{ maxWidth: "md" }}>
    <Typography variant="h2">Notifications</Typography>
    <Typography variant="body1" color="text.secondary" mt={1} mb={2}>
      {props.userOrInvite.email}
    </Typography>
    <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }} />
    {props.userRef !== undefined && <UserEmailNotifications {...props} userRef={props.userRef} />}
  </Box>
);
