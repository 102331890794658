import { type AssetModelBillingAnomalyModel, DashboardModel } from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { type CostAnomaliesItem } from "./types";
import { makeAnomalyAttributionNoValueLabel } from "./utils";

/**
 * class AlertTools
 * description:
 * Provides functions that may be re-used by anomaly detection features
 * in the UI.
 */

export type graphPoint = {
  y: number;
  y_hat: number;
  x: number;
};

export const getGraphLength = (chartData: WithFirebaseModel<AssetModelBillingAnomalyModel>["chart_data"]) =>
  Object.keys(chartData).length;

export const getMiniGraph = (alertData: WithFirebaseModel<AssetModelBillingAnomalyModel>) => {
  if (!alertData) {
    return [{ x: 0, y: 0, y_hat: 0 }];
  }
  return Object.keys(alertData.chart_data)
    .sort()
    .map((key, i) => {
      if (i > getGraphLength(alertData.chart_data) - 30) {
        const d = new Date(key.replaceAll("-", "/")).getTime();
        return {
          y:
            alertData.chart_data[key].updated_value ??
            alertData.chart_data[key].snapshot_value ??
            alertData.chart_data[key].actual_cost,
          y_hat: alertData.chart_data[key].high,
          x: d,
        };
      }
    })
    .filter((item): item is graphPoint => item !== undefined);
};

export const getCostOfAnomaly = (alertData: WithFirebaseModel<AssetModelBillingAnomalyModel>) => {
  if (alertData.metadata.excess) {
    return Math.max(alertData.metadata.excess, 0);
  }

  const miniGraph = getMiniGraph(alertData);
  const max_y = miniGraph[miniGraph.length - 1].y;
  const max_y_hat = miniGraph[miniGraph.length - 1].y_hat;
  const costOfAnomaly = Math.round((max_y - max_y_hat) * 100) / 100;

  return costOfAnomaly < 0 ? 0 : costOfAnomaly;
};

export const anomaliesWithAttrNames = async (anomalies: Omit<CostAnomaliesItem, "attribution_name">[]) => {
  const anomaliesPlatforms = Object.fromEntries(anomalies.map((anomaly) => [anomaly.id, anomaly.platform]));

  const attributionsUniqueIds = Array.from(
    anomalies.reduce((acc, anomaly) => {
      const attributionId = anomaly.attribution;

      if (!attributionId) {
        return acc;
      }

      acc.add(attributionId);

      return acc;
    }, new Set<string>())
  );

  const noNameStr = (anomalyId: string) => {
    const platform = anomaliesPlatforms[anomalyId];
    return platform ? makeAnomalyAttributionNoValueLabel(platform) : "...";
  };

  const attributionsNames = await Promise.all(
    attributionsUniqueIds.map(async (attributionId) => {
      const attribution = await getCollection(DashboardModel)
        .doc("google-cloud-reports")
        .collection("attributions")
        .doc(attributionId)
        .get();

      return attribution.data()?.name;
    })
  );

  const attributionsNameLookup = Object.fromEntries(attributionsUniqueIds.map((id, i) => [id, attributionsNames[i]]));

  return anomalies.map((anomaly) => {
    let attributionName = anomaly.attribution ? attributionsNameLookup[anomaly.attribution] : undefined;
    attributionName ??= noNameStr(anomaly.id);
    return { ...anomaly, attribution_name: attributionName };
  });
};

export const getElementTimestamp = (element: WithFirebaseModel<AssetModelBillingAnomalyModel>): DateTime => {
  if (element.metadata.timestamp !== undefined) {
    return DateTime.fromJSDate(element.metadata.timestamp.toDate(), { zone: "utc" });
  }

  return DateTime.fromFormat(element.metadata.usage_start_time!.slice(0, 16), "yyyy-LL-dd HH:mm", { zone: "utc" });
};

export const getAnomalyInactiveDate = (
  anomaly: WithFirebaseModel<AssetModelBillingAnomalyModel>
): DateTime | undefined => {
  const inactivePoint = Object.entries(anomaly.chart_data).find(([_, value]) => value.status === "INACTIVE");

  if (!inactivePoint) {
    return undefined;
  }

  const inactivePointDate = new Date(inactivePoint[0].replace(/-/g, "/"));

  return DateTime.fromJSDate(inactivePointDate);
};
