import { useCallback, useMemo, useState } from "react";

import { AccountManagerModel, CustomerModel, DoitRole, UserModel } from "@doitintl/cmp-models";
import { getCollection, type QueryModel, type QuerySnapshotModel } from "@doitintl/models-firestore";
import find from "lodash/find";

import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { AccountManagersHooks } from "../../../Context/customer/AccountManagers";
import { useImpersonateContext } from "../../../Context/impersonateContext";
import { type DataColumn, type HeaderColumn, headerFromDataColumn } from "../../../types/FilterTable";
import { getTenantId } from "../../../utils/Auth/tenant";
import { threeDotHeaderSection } from "../../Assets/assetUtils";
import AccountManagerForm from "../AccountManagerForm";
import { type AccountManagerWithId } from "../types";
import { accountManagersDefaultFilters, accountManagersFilterColumns } from "./AccountManagersFilterTableColumns";
import AccountManagersFilterTableRow, { type AccountManagersRowData } from "./AccountManagersFilterTableRow";

type Props = {
  accountManagers: AccountManagerWithId[];
};

const excludedColumns: readonly string[] = ["status"];

const AccountManagersFilterTable = ({ accountManagers }: Props) => {
  const { handleImpersonateUser } = useImpersonateContext();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [deleteAccountManagerId, setDeleteAccountManagerId] = useState<string | null>(null);

  const snackbar = useSnackbar();

  const [roles] = AccountManagersHooks.useAccountManagersRoles();

  const columnHeaderHiddenMap = {
    email: { smDown: true },
    role: { lgDown: true },
    company: { mdDown: true },
  };

  const filterColumns: readonly DataColumn[] = accountManagersFilterColumns(roles ?? []);
  const headerColumns: readonly HeaderColumn[] = [
    ...filterColumns
      .filter((fc) => !excludedColumns.includes(fc.path))
      .map((fc) => headerFromDataColumn(fc, undefined, columnHeaderHiddenMap)),
    threeDotHeaderSection,
  ];
  const isFsm = useDoitRoleCheck(DoitRole.FieldSalesManager);

  const calcAssociatedCustomers = useCallback((isHavingCustomersResults: QuerySnapshotModel<CustomerModel>[]) => {
    let associatedCustomersNumber = 0;
    isHavingCustomersResults.forEach((res) => {
      if (!res.empty) {
        associatedCustomersNumber += res.docs.length;
      }
    });

    return associatedCustomersNumber;
  }, []);

  const deleteAccountManager = useCallback(
    async (accountManagerId: string) => {
      const accountManager = find(accountManagers, { id: accountManagerId });

      if (!accountManager) {
        return;
      }
      const queries: QueryModel<CustomerModel>[] = [];
      const limit = 100;

      const { company } = accountManager;
      const accountManagerRef = getCollection(AccountManagerModel).doc(accountManager.id);
      queries.push(
        getCollection(CustomerModel).where(`accountManagers.${company}.account_manager.ref`, "==", accountManagerRef)
      );
      queries.push(
        getCollection(CustomerModel).where(`accountManagers.${company}.customer_engineer.ref`, "==", accountManagerRef)
      );
      queries.push(getCollection(CustomerModel).where("accountManager", "==", accountManagerRef));

      const isHavingCustomersResults = await Promise.all(queries.map((query) => query.limit(limit).get()));
      const associatedCustomersNumber = calcAssociatedCustomers(isHavingCustomersResults);

      // NOTE: refactor to use "useSnackbar"?
      if (associatedCustomersNumber > 0) {
        snackbar.onOpen({
          autoHideDuration: 3000,
          message: `Account Manager can't be deleted since it's associated with ${associatedCustomersNumber} customers.`,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          withClose: true,
          variant: "error",
        });
      } else {
        await getCollection(AccountManagerModel).doc(accountManager.id).delete();
      }
    },
    [accountManagers, calcAssociatedCustomers, snackbar]
  );

  const editManager = useCallback(
    (accountManagersIds: string) => {
      const results = find(accountManagers, { id: accountManagersIds }) ?? null;
      setOpen(true);
      setEditData(results);
    },
    [accountManagers]
  );

  const impersonateManager = useCallback(
    async (accountManagersId: string) => {
      const accountManager = find(accountManagers, { id: accountManagersId }) ?? null;
      if (accountManager) {
        const tenantId = await getTenantId(accountManager.company);
        return handleImpersonateUser(accountManagersId, tenantId);
      }
    },
    [accountManagers, handleImpersonateUser]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setEditData(null);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
    setEditData(null);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteAccountManagerId(null);
  }, []);

  const deleteManager = useCallback((accountManagersIds: string) => {
    setDeleteAccountManagerId(accountManagersIds);
  }, []);

  const deleteConfirmed = useCallback(async () => {
    if (!deleteAccountManagerId) {
      return;
    }
    await deleteAccountManager(deleteAccountManagerId);

    closeDeleteDialog();
  }, [deleteAccountManagerId, closeDeleteDialog, deleteAccountManager]);

  const submit = useCallback(
    async (data, edit) => {
      // new
      if (!edit) {
        const users = await getCollection(UserModel).where("email", "==", data.email).limit(1).get();
        const existingUserId = users.docs[0]?.id;
        const amModel = getCollection(AccountManagerModel);
        const newOrExistingDoc = existingUserId ? amModel.doc(existingUserId) : amModel.newDoc();
        await newOrExistingDoc.set(data);
        snackbar.onOpen({
          autoHideDuration: 3000,
          message: `Account Manager created.`,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          withClose: true,
          variant: "success",
        });
      } else {
        // edit
        await getCollection(AccountManagerModel).doc(edit.id).update(data);
        snackbar.onOpen({
          autoHideDuration: 3000,
          message: `Account manager updated.`,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          withClose: true,
          variant: "success",
        });
      }
      handleClose();
      setEditData(null);
    },
    [handleClose, snackbar]
  );

  const tableData = useMemo<AccountManagersRowData[]>(
    () =>
      accountManagers.map((accountManager) => ({
        ...accountManager,
        reportsTo: find(accountManagers, { id: accountManager.manager?.id }),
      })),
    [accountManagers]
  );

  const rowComponentAsCallback = ({ row }) => (
    <AccountManagersFilterTableRow
      rowData={row}
      roles={roles ?? []}
      onEditAccountManager={() => {
        editManager(row.id);
      }}
      onDeleteAccountManager={() => {
        deleteManager(row.id);
      }}
      onImpersonating={() => impersonateManager(row.id)}
      isFsm={isFsm}
    />
  );

  return (
    <FilterTable<AccountManagersRowData>
      toolbarProps={{
        title: "Account managers",
        primaryButton: { text: "Create new", onClick: handleOpen },
        allowToEditColumns: true,
      }}
      rowComponent={rowComponentAsCallback}
      headerColumns={headerColumns}
      filterColumns={filterColumns}
      tableItems={tableData}
      defaultFilters={accountManagersDefaultFilters}
      defaultSortDirection="desc"
      persistenceKey="account_managers_filter_table"
      data-cy="accountManagersFilterTable"
    >
      <AccountManagerForm edit={editData} submit={submit} handleClose={handleClose} open={open} />
      {!!deleteAccountManagerId && (
        <DeleteDialog
          open={true}
          title="Delete account manager?"
          message="This Account Manager will be permanently deleted and won't be able to be recovered."
          onDelete={deleteConfirmed}
          onClose={closeDeleteDialog}
        />
      )}
    </FilterTable>
  );
};

export default AccountManagersFilterTable;
