import { CloudAnalyticsModel, type CloudAnalyticsModelMetricModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type CustomerRef } from "../../../types";
import { type FirestoreTimestamp, serverTimestamp } from "../../../utils/firebase";

export const metricsCollectionRef = () =>
  getCollection(CloudAnalyticsModel).doc("metrics").collection("cloudAnalyticsMetrics");

export const saveMetricData = async (
  metricData: CloudAnalyticsModelMetricModel,
  metricId?: string
): Promise<string> => {
  const docRef = metricId ? metricsCollectionRef().doc(metricId) : metricsCollectionRef().newDoc();
  await docRef.set(metricData);
  return docRef.id;
};

export const copyMetric = async (
  customerRef: CustomerRef,
  userEmail: string,
  metricData: CloudAnalyticsModelMetricModel
) =>
  saveMetricData({
    ...metricData,
    customer: customerRef,
    name: `Copy of ${metricData.name}`,
    owner: userEmail,
    timeCreated: serverTimestamp() as FirestoreTimestamp,
    timeModified: serverTimestamp() as FirestoreTimestamp,
  });
