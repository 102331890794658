export const billingAccountTypes = {
  SAAS: "SaaS",
  RESOLD: "Resold",
};

export const marketplaceTxt = {
  CREATED_BY: "Created by",
  TIME_CREATED: "Time created",
  TIME_REPORTED: "Time reported",
  LAST_MODIFIED: "Last modified",
};
