import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button } from "@mui/material";

import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";

export const OpenAWSConsole = ({ region }: { region: string }) => (
  <Button
    startIcon={<OpenNewIcon />}
    variant="outlined"
    sx={{ width: "fit-content" }}
    href={`https://console.aws.amazon.com/console/home?region=${region}`}
    target="_blank"
  >
    {onboardingTxt.OPEN_AWS_CONSOLE}
  </Button>
);
