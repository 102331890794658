import { useMemo } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DateTime } from "luxon";

import { roundWithCommas } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import { computeTrueEndDate } from "./PeriodTables/Tables/getInitPeriodsDataFromContract";

interface DialogProps {
  open: boolean;
  onClose: () => void;
  actualBreakdowns: Record<string, number>[];
  periodBreakdown: Record<string, number>;
  startDate: FirestoreTimestamp;
  endDate: FirestoreTimestamp;
}

const breakdownRows = (
  actualBreakdowns: Record<string, number>[], // for backwards compatibility
  periodBreakdown: Record<string, number>
): [[string, number][], number, number] => {
  let sortedRows: [string, number][] = [];
  const { Credits: credits, ...rest } = periodBreakdown;

  if (rest && Object.keys(rest).length !== 0) {
    sortedRows = Object.entries(rest).sort((a, b) => {
      // "Other" should always be last
      if (a[0] === "Other") {
        return 1;
      } else if (b[0] === "Other") {
        return -1;
      }

      return b[1] - a[1];
    });
  } else {
    const totals: Record<string, number> = {};
    actualBreakdowns.forEach((periodBreakdown) => {
      Object.entries(periodBreakdown).forEach(([key, value]) => {
        if (totals[key]) {
          totals[key] += value;
        } else {
          totals[key] = value;
        }
      });
    });
    sortedRows = Object.entries(totals).sort((a, b) => b[1] - a[1]);
    if (sortedRows.length > 10) {
      const other = sortedRows.slice(10).reduce((a, b) => a + b[1], 0);
      sortedRows.splice(10, sortedRows.length - 10, ["Other", other]);
    }
  }

  const totalSpend = sortedRows.reduce((a, b) => a + b[1], 0);
  return [sortedRows, totalSpend, credits ?? 0];
};

const percentOfSpend = (spend: number, total: number) => Math.round((spend / total) * 100);

const PeriodSpendBreakdown = ({
  open,
  onClose,
  actualBreakdowns,
  periodBreakdown,
  startDate,
  endDate,
}: DialogProps) => {
  const [rows, totalSpend, credits] = useMemo(
    () => breakdownRows(actualBreakdowns, periodBreakdown),
    [periodBreakdown, actualBreakdowns]
  );
  const periodString = `${DateTime.fromSeconds(startDate.seconds)
    .toUTC()
    .toFormat("dd LLL yyyy")} - ${computeTrueEndDate(DateTime.fromSeconds(endDate.seconds)).toFormat("dd LLL yyyy")}`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>{`Total spend breakdown (${periodString})`}</DialogTitle>
      <DialogContent dividers style={{ maxHeight: "500px", overflow: "auto" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Spend</TableCell>
                <TableCell align="right">% of spend</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell align="right">${roundWithCommas(value)}</TableCell>
                  <TableCell align="right">{percentOfSpend(value, totalSpend)}%</TableCell>
                </TableRow>
              ))}
              {credits < 0 && (
                <TableRow sx={{ "& td": { borderBottom: "none", fontWeight: 500 } }}>
                  <TableCell>Promotional credits</TableCell>
                  <TableCell align="right">-${roundWithCommas(-credits)}</TableCell>
                  <TableCell />
                </TableRow>
              )}
              <TableRow sx={{ "& td": { borderBottom: "none", fontWeight: 500 } }}>
                <TableCell>Total</TableCell>
                <TableCell align="right">${roundWithCommas(totalSpend + credits)}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PeriodSpendBreakdown;
