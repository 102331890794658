import { CategoryStatus } from "@doitintl/cmp-models";
import { Chip, type ChipProps } from "@mui/material";
import { deepPurple, green, lightBlue, red, yellow } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";

import { CloudConnectStatusText } from "../../../assets/texts";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";

export const statusLabels: Record<CategoryStatus, string> = {
  [CategoryStatus.NotConfigured]: CloudConnectStatusText.NotConfigured,
  [CategoryStatus.Healthy]: CloudConnectStatusText.Healthy,
  [CategoryStatus.Unhealthy]: CloudConnectStatusText.Unhealthy,
  [CategoryStatus.Critical]: CloudConnectStatusText.Critical,
  [CategoryStatus.Partial]: CloudConnectStatusText.Partial,
};

const lightThemeColors: Record<CategoryStatus, string> = {
  [CategoryStatus.NotConfigured]: lightBlue[400],
  [CategoryStatus.Healthy]: green[400],
  [CategoryStatus.Unhealthy]: yellow[500],
  [CategoryStatus.Critical]: red[600],
  [CategoryStatus.Partial]: deepPurple[200],
};

const darkThemeColors: Record<CategoryStatus, string> = {
  [CategoryStatus.NotConfigured]: lightBlue[400],
  [CategoryStatus.Healthy]: green[400],
  [CategoryStatus.Unhealthy]: yellow[400],
  [CategoryStatus.Critical]: red[500],
  [CategoryStatus.Partial]: deepPurple[200],
};

export const ServiceAccountStatusChip = ({ status, size = "small" }: { status: number; size?: ChipProps["size"] }) => {
  const theme = useTheme();
  const isDarkTheme = useDarkThemeCheck();
  const colors = isDarkTheme ? darkThemeColors : lightThemeColors;
  const useInvertedTextColor =
    (isDarkTheme && status === CategoryStatus.Critical) || (!isDarkTheme && status === CategoryStatus.Unhealthy)
      ? -1
      : 1;
  const colorVariation = isDarkTheme ? 1 : -1;

  return (
    <Chip
      label={statusLabels[status]}
      size={size}
      sx={{
        backgroundColor: colors[status],
        color: colorVariation * useInvertedTextColor > 0 ? theme.palette.common.black : theme.palette.common.white,
      }}
    />
  );
};
