import { type ChangeEvent } from "react";

import { type DashboardRole, Roles } from "@doitintl/cmp-models";
import { MenuItem, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import capitalize from "lodash/capitalize";

import { globalText, reportText } from "../../../../assets/texts";

const useStyles = makeStyles((theme) => ({
  collaboratorsList: {
    paddingTop: 0,
    height: 270,
  },
  roleSelect: {
    width: 104,
    marginRight: theme.spacing(1),
  },
  roleSecondaryAction: {
    right: 0,
  },
  selectOutlined: {
    "&$outlined": {
      ...theme.typography.body2,
      padding: theme.spacing(0.75, 1.5),
    },
  },
  outlined: {},
}));

type RolesTextFieldProps = {
  value?: DashboardRole | boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  owner?: boolean;
  remove?: boolean;
  ownerText?: string;
  removeOwnerOption?: boolean;
  roles: { role: DashboardRole | undefined; text?: string }[];
  isCurrentUserOwner: boolean;
  isCurrentUserEditor: boolean;
  availableRoles?: Array<DashboardRole | undefined>;
};
const RolesTextField = ({
  value,
  onChange,
  owner,
  remove,
  roles,
  isCurrentUserOwner,
  isCurrentUserEditor,
  availableRoles,
}: RolesTextFieldProps) => {
  const classes = useStyles();
  roles = owner
    ? [{ role: Roles.OWNER, text: Roles.OWNER }]
    : roles?.filter((r) => isCurrentUserOwner || r.text !== reportText.MAKE_OWNER);

  let roleValue = owner ? roles.find((r) => r.role === Roles.OWNER) : roles.find((r) => r.role === value);

  if (value === true) {
    roleValue = roles.find((r) => r.role === Roles.VIEWER);
  }

  if (value === false || !value) {
    roleValue = roles.find((r) => !r.role);
  }
  return (
    <TextField
      select
      margin="dense"
      variant="outlined"
      label="Access"
      disabled={!isCurrentUserEditor && !isCurrentUserOwner}
      value={roleValue?.text || globalText.NONE}
      onChange={onChange}
      className={classes.roleSelect}
    >
      {roles
        .filter(
          (r) =>
            r.text &&
            (availableRoles?.includes(r.role) || owner || r.text === reportText.MAKE_OWNER) &&
            r.text !== Roles.MIXED
        )
        .map((r, i) => (
          <MenuItem key={i} value={roles.find((rr) => rr.role === r.role)?.text}>
            {capitalize(r.text)}
          </MenuItem>
        ))}
      {remove && (
        <MenuItem value="remove">
          <Typography color="error">Remove</Typography>
        </MenuItem>
      )}
      <MenuItem value={Roles.MIXED} sx={{ display: "none" }}>
        Mixed
      </MenuItem>
    </TextField>
  );
};

export default RolesTextField;
