import { type FC } from "react";

import { type MapApiServiceModelDescriptor, type Member } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack } from "@mui/material";
import { FieldArray, type FieldInputProps } from "formik";

import { GenericForm } from "../ApiActionParametersForm";
import { generateApiActionParametersSchema, getInitialValueForModel } from "../useApiActionParametersSchema";
import { Fieldset } from "./wrappers/Fieldset";

export const MapParam: FC<{
  fieldPath: string;
  fieldProps: FieldInputProps<{ key: unknown; value: unknown }[]>;
  label: string;
  onRemove?: () => void;
  inputModel: MapApiServiceModelDescriptor<Member>;
}> = ({ fieldPath, fieldProps, label, onRemove, inputModel }) => (
  <Fieldset label={label} onRemove={onRemove}>
    <FieldArray
      name={fieldProps.name}
      render={(arrayHelpers) => (
        <Stack spacing={2}>
          {fieldProps.value.map((_, index) => (
            <Fieldset
              key={fieldPath + index.toString()}
              label={`${label} ${index + 1}`}
              onRemove={() => arrayHelpers.remove(index)}
            >
              <Stack spacing={2}>
                <GenericForm
                  inputModel={inputModel.keyMember.model}
                  fieldPath={`${fieldPath}[${index}].${inputModel.keyMemberName}`}
                  label={inputModel.keyMemberName}
                  disallowReferencedField
                />
                <GenericForm
                  inputModel={inputModel.valueMember.model}
                  fieldPath={`${fieldPath}[${index}].${inputModel.valueMemberName}`}
                  label={inputModel.valueMemberName}
                />
              </Stack>
            </Fieldset>
          ))}
          <Button
            sx={{ alignSelf: "flex-start" }}
            size="small"
            onClick={() => {
              arrayHelpers.push({
                [inputModel.keyMemberName]: generateApiActionParametersSchema(inputModel.keyMember.model).cast(
                  getInitialValueForModel(inputModel.keyMember.model)
                ),
                [inputModel.valueMemberName]: generateApiActionParametersSchema(inputModel.valueMember.model).cast(
                  getInitialValueForModel(inputModel.valueMember.model)
                ),
              });
            }}
            startIcon={<AddIcon />}
          >
            Append {label}
          </Button>
        </Stack>
      )}
    />
  </Fieldset>
);
