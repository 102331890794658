import { useState } from "react";

import { type FlexsaveType } from "@doitintl/cmp-models";
import { Button } from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useFlexsaveApi } from "../../hooks";
import { DisableFlexsaveDialog } from "./DisableFlexsaveDialog";

type Props = {
  type: FlexsaveType;
};

const DisableFlexsave = ({ type }: Readonly<Props>) => {
  const [disableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);
  const { customer } = useCustomerContext();

  const api = useFlexsaveApi(type);

  return (
    <>
      {disableDialogOpen && (
        <DisableFlexsaveDialog
          onClose={() => {
            setDisableDialogOpen(false);
          }}
          handleDisable={() => api.disable()}
        />
      )}

      <Button
        color="error"
        variant="outlined"
        onClick={() => {
          setDisableDialogOpen(true);
        }}
        data-cy="disable-flexsave-button"
      >
        Disable Flexsave for {customer.name}
      </Button>
    </>
  );
};

export default DisableFlexsave;
