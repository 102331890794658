import {
  type BaseSyntheticEvent,
  type ComponentType,
  type Dispatch,
  type KeyboardEvent,
  type MouseEvent,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { AutoSizer } from "react-virtualized";
import { type ListChildComponentProps, VariableSizeList } from "react-window";
import { type AutocompleteApi, type AutocompleteState } from "@algolia/autocomplete-core";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import { common, grey } from "@mui/material/colors";
import { alpha, useTheme } from "@mui/material/styles";
import { type LiteClient as SearchClient } from "algoliasearch/lite";

import { useAuthContext } from "../../Context/AuthContext";
import { ThemeModes } from "../../muiThemeTypes";
import Hide from "../HideChildren/Hide";
import { useLocalStorage } from "../hooks/storageHooks";
import { AlgoliaFilterChips } from "./AlgoliaFilterChips";
import { AlgoliaResultList } from "./AlgoliaResultList";
import { useAlgoliaAutocomplete, useBindAlgoliaEventListeners } from "./hooks";
import { QuickLinks } from "./QuickLinks";
import {
  type AlgoliaFilters,
  type AlgoliaIndexType,
  type AlgoliaItem,
  type CustomerFromQuery,
  type CustomerHit,
} from "./types";
import { countCollections, shouldReturnResults } from "./utils";

type AlgoliaSearchModalBaseProps = {
  autocomplete: AutocompleteApi<AlgoliaItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>;
  autocompleteState: AutocompleteState<AlgoliaItem>;
  setSearchModalVisible: Dispatch<SetStateAction<boolean>>;
  restrictedIndices?: AlgoliaIndexType[];
  disableQuickLinks?: boolean;
  localstorageKey: string;
  customTitle?: string;
  CustomResultList?: ComponentType<ListChildComponentProps> | null;
  customerFromQuery?: CustomerFromQuery;
  setCustomerFromQuery?: (c: CustomerFromQuery) => void;
  rowHeight?: number;
  disableHelpText?: boolean;
};

const getIconColor = (theme: Theme) =>
  alpha(theme.palette.mode === ThemeModes.DARK ? common.white : common.black, 0.54);

export const AlgoliaSearchModalBase = ({
  setSearchModalVisible,
  restrictedIndices,
  disableQuickLinks,
  localstorageKey,
  customTitle,
  CustomResultList = null,
  autocomplete,
  autocompleteState,
  customerFromQuery = null,
  setCustomerFromQuery,
  rowHeight = 6.85,
  disableHelpText = false,
}: AlgoliaSearchModalBaseProps) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  const isSearchForCustomer = useMemo(
    () => isDoitEmployee && autocompleteState.query.startsWith("@") && !customerFromQuery,
    [customerFromQuery, isDoitEmployee, autocompleteState.query]
  );

  const placeholderText = useMemo(() => {
    if (customerFromQuery) {
      return "e.g. invoices, alerts or reports";
    }
    return "Search";
  }, [customerFromQuery]);

  const [filters, setFilters] = useLocalStorage(localstorageKey, { allResults: true });
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<VariableSizeList>(null);

  useBindAlgoliaEventListeners({ inputRef, formRef, panelRef, autocomplete, autocompleteState });

  const handleClose = useCallback(() => {
    setSearchModalVisible(false);
    autocomplete.setQuery("");
    autocomplete.setIsOpen(false);
  }, [autocomplete, setSearchModalVisible]);

  const filteredCollections = useMemo(
    () =>
      autocompleteState.collections.filter((collection) =>
        shouldReturnResults(filters, collection, autocompleteState.query, isSearchForCustomer)
      ),
    [autocompleteState.collections, autocompleteState.query, filters, isSearchForCustomer]
  );

  useEffect(() => {
    autocomplete.setIsOpen(Boolean(filteredCollections.length));
  }, [autocomplete, filteredCollections]);

  const emptyResultsText = useMemo(() => {
    if (isSearchForCustomer) {
      return "No customers found";
    }

    if (autocompleteState.query.trim() !== "") {
      return "No results found";
    }

    if (customerFromQuery) {
      return "";
    }

    return "No recent searches";
  }, [autocompleteState.query, isSearchForCustomer, customerFromQuery]);

  const filterChipsCount = useMemo(
    () => countCollections(autocompleteState.collections),
    [autocompleteState.collections]
  );

  const computeContainerHeight = useCallback(
    (theme) => {
      // no recent searches, no results found
      if (filteredCollections.length === 0) {
        // show nothing, reduce the window
        if (customerFromQuery) {
          return theme.spacing(1);
        }
        // regular search
        return theme.spacing(12);
      }
      // recent searches
      if (autocompleteState.query === "" && filteredCollections.length !== 0) {
        return theme.spacing(filteredCollections[0].items.length * rowHeight + 6);
      }
      // results found
      return theme.spacing(76);
    },
    [filteredCollections, autocompleteState.query, customerFromQuery, rowHeight]
  );

  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    listRef.current.resetAfterIndex(0);
  }, [filteredCollections]);

  const setFiltersAndFocusInput = useCallback(
    (filters: AlgoliaFilters) => {
      setFilters(filters);
      inputRef.current?.focus();

      autocomplete.setActiveItemId(0);
      autocomplete.setContext({ filters });
      autocomplete.refresh();
    },
    [autocomplete, setFilters]
  );

  const disableSearchWithinCustomer = useCallback(() => {
    setCustomerFromQuery?.(null);
  }, [setCustomerFromQuery]);

  const highlightedCustomer = useMemo(() => {
    const customerSearchResults = autocompleteState.collections.find((col) => col.source.sourceId === "customers");
    return customerSearchResults?.items[autocompleteState.activeItemId ?? 0] as unknown as CustomerHit;
  }, [autocompleteState.activeItemId, autocompleteState.collections]);

  const onKeyDown = useCallback(
    (event) => {
      if (isSearchForCustomer && (event.key === "Tab" || event.key === "Enter")) {
        if (highlightedCustomer) {
          setCustomerFromQuery?.({ id: highlightedCustomer.objectID, name: highlightedCustomer?.name || "" });
          autocomplete.setQuery("");
          autocomplete.refresh();
        }
        event.preventDefault();
        return;
      }
      if (customerFromQuery && autocompleteState.query === "" && event.key === "Backspace") {
        disableSearchWithinCustomer();
      }
    },
    [
      isSearchForCustomer,
      customerFromQuery,
      autocompleteState.query,
      highlightedCustomer,
      setCustomerFromQuery,
      autocomplete,
      disableSearchWithinCustomer,
    ]
  );

  const onChange = useCallback(
    (event) => {
      autocomplete.setQuery(event.target.value);
      autocomplete.refresh();
    },
    [autocomplete]
  );

  const clearSearchValue = useCallback(() => {
    autocomplete.setQuery("");
    autocomplete.refresh();
  }, [autocomplete]);

  const handleDialogEntered = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input when the dialog is fully opened
    }
  };

  return (
    <Dialog
      sx={{ pb: 5, "& .MuiDialog-container": { alignItems: "baseline" } }}
      transitionDuration={{ enter: 0, exit: 0 }}
      open={true}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
      TransitionProps={{ onEntered: handleDialogEntered }}
      {...autocomplete.getRootProps({})}
    >
      {!!customTitle && (
        <DialogTitle>
          <Typography variant="h6">{customTitle}</Typography>
        </DialogTitle>
      )}
      <DialogContent sx={{ padding: 2, overflow: "initial" }}>
        <form ref={formRef} {...autocomplete.getFormProps({ inputElement: inputRef.current })} onKeyDown={onKeyDown}>
          <TextField
            data-testid="algolia-modal-input"
            size="medium"
            variant="outlined"
            sx={{
              width: "100%",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={(th) => ({
                      color: getIconColor(th),
                    })}
                  />
                  {isSearchForCustomer && highlightedCustomer && (
                    <Box
                      data-testid="placeholder-hint"
                      sx={{ position: "absolute", left: 65 + 7.8 * autocompleteState.query.length, top: 18 }}
                    >
                      <Typography
                        variant="body2"
                        display="inline-block"
                        sx={{ background: theme.palette.mode === ThemeModes.DARK ? grey[700] : grey[200] }}
                        px="4px"
                      >
                        TAB
                      </Typography>
                      <Typography variant="body2" display="inline-block" color="textSecondary" pl={1}>
                        to select customer {highlightedCustomer.name}
                      </Typography>
                    </Box>
                  )}
                  {customerFromQuery && (
                    <Chip
                      data-testid="selected-customer-chip"
                      label={customerFromQuery.name}
                      sx={{ pr: 1 }}
                      color="default"
                      onDelete={disableSearchWithinCustomer}
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: autocompleteState.query && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={clearSearchValue}>
                    <CloseIcon fontSize="small" sx={{ color: "action.active" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...autocomplete.getInputProps({
              inputElement: inputRef.current,
              autoFocus: true,
              type: "text",
            })}
            inputRef={inputRef}
            value={autocompleteState.query}
            placeholder={placeholderText}
            onChange={onChange}
          />
        </form>
        {Boolean(autocompleteState.query) && !isSearchForCustomer && (
          <AlgoliaFilterChips
            restrictedIndices={restrictedIndices}
            collectionCount={filterChipsCount}
            filters={filters}
            setFilters={setFiltersAndFocusInput}
            isSearchWithinCustomer={Boolean(customerFromQuery)}
          />
        )}
        {!autocompleteState.query && !isSearchForCustomer && !customerFromQuery && !disableQuickLinks && (
          <QuickLinks handleClose={handleClose} />
        )}
      </DialogContent>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: computeContainerHeight,
          overflowY: "hidden",
          maxHeight: 610,
          borderTop: 0,
          px: 2,
          py: 0,
        }}
        dividers={true}
        ref={panelRef}
        {...autocomplete.getPanelProps({})}
        data-cy="search-results-container"
      >
        {filteredCollections.length === 0 && (
          <Typography textAlign="center" variant="body2" color="text.secondary" sx={{ mb: 5, mt: 4 }}>
            {emptyResultsText}
          </Typography>
        )}
        <AutoSizer>
          {({ width, height }) => (
            <VariableSizeList
              ref={listRef}
              itemData={{ collections: filteredCollections, autocomplete, autocompleteState }}
              itemCount={filteredCollections.length}
              itemKey={(index, data) => {
                const items = data.collections[index];
                return `${index}${items.items.length}`;
              }}
              itemSize={(index) => parseInt(theme.spacing(filteredCollections[index].items.length * rowHeight + 5))}
              height={height}
              width={width}
            >
              {CustomResultList ? CustomResultList : AlgoliaResultList}
            </VariableSizeList>
          )}
        </AutoSizer>
      </DialogContent>
      {!disableHelpText && (
        <Hide smDown>
          <DialogActions sx={{ justifyContent: "flex-start", pl: 2 }}>
            <Stack direction="row" gap={2}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  component="span"
                  sx={(th) => ({
                    color: getIconColor(th),
                    fontWeight: 700,
                  })}
                >
                  ENTER &nbsp;
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  to select
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ArrowUpwardIcon sx={(th) => ({ color: getIconColor(th), height: 16, width: 16 })} />
                <ArrowDownwardIcon sx={(th) => ({ color: getIconColor(th), height: 16, width: 16, mr: 0.83 })} />
                <Typography variant="caption" color="textSecondary">
                  to navigate
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  color="textSecondary"
                  variant="caption"
                  component="span"
                  sx={(th) => ({
                    color: getIconColor(th),
                    fontWeight: 700,
                  })}
                >
                  ESC &nbsp;
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  to close
                </Typography>
              </Box>
            </Stack>
          </DialogActions>
        </Hide>
      )}
    </Dialog>
  );
};

type AlgoliaSearchModalProps = {
  setSearchModalVisible: Dispatch<SetStateAction<boolean>>;
  searchClient: SearchClient;
  restrictedIndices?: AlgoliaIndexType[];
};

export const AlgoliaSearchModal = ({
  setSearchModalVisible,
  searchClient,
  restrictedIndices,
}: AlgoliaSearchModalProps) => {
  const [customerFromQuery, setCustomerFromQuery] = useState<CustomerFromQuery>(null);
  const [autocomplete, autocompleteState] = useAlgoliaAutocomplete(
    searchClient,
    setSearchModalVisible,
    customerFromQuery,
    restrictedIndices
  );

  return (
    <AlgoliaSearchModalBase
      autocomplete={autocomplete}
      autocompleteState={autocompleteState}
      setSearchModalVisible={setSearchModalVisible}
      localstorageKey="ALGOLIA_FILTERS"
      restrictedIndices={restrictedIndices}
      setCustomerFromQuery={setCustomerFromQuery}
      customerFromQuery={customerFromQuery}
    />
  );
};
