import { globalText, marketplaceText } from "../../../../assets/texts";
import { billingAccountTypes } from "../../../../assets/texts/Marketplace/marketplace";

export type AccountMenuOptionProps = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
};

export const accountOptions: AccountMenuOptionProps[] = [
  {
    label: marketplaceText.COPY_ACCOUNT_ID,
    value: marketplaceText.COPY_ACCOUNT_ID,
  },
  {
    label: marketplaceText.POPULATE_BILLING_ACCOUNT,
    value: marketplaceText.POPULATE_BILLING_ACCOUNT,
  },
  {
    label: globalText.APPROVE,
    value: globalText.APPROVE,
  },
  {
    label: globalText.REJECT,
    value: globalText.REJECT,
    color: "error",
  },
];

export const getBillingAccountType = (billingAccountType?: string) => {
  if (billingAccountType === "google-cloud-standalone") {
    return billingAccountTypes.SAAS;
  }

  if (billingAccountType === "google-cloud") {
    return billingAccountTypes.RESOLD;
  }

  return globalText.NA;
};
