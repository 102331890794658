import { EarlyAccessFeature } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../Context/CustomerContext";
import { FlexsaveAWS } from "./FlexsaveAWS";
import { FlexsaveStandaloneAWS } from "./FlexsaveStandaloneAWS";

export const FlexsaveAWSWrapper = () => {
  const { customer, isProductOnlyCustomer } = useCustomerContext();

  const isStandalone =
    isProductOnlyCustomer || customer.earlyAccessFeatures?.includes(EarlyAccessFeature.FLEXSAVE_AWS_STANDALONE);

  return isStandalone ? <FlexsaveStandaloneAWS /> : <FlexsaveAWS />;
};
