import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Warning";
import { Stack, SvgIcon, Typography } from "@mui/material";

type DonationQualifierProps = {
  qualifies: boolean;
};

const DonationQualifier = ({ qualifies }: DonationQualifierProps) => {
  const { t } = useTranslation("services");
  const checkbox = (
    <SvgIcon color="success" fontSize="small" sx={{ mr: "0.5ch" }}>
      <CheckCircleIcon />
    </SvgIcon>
  );
  const nope = (
    <SvgIcon fontSize="small" sx={{ mr: "0.5ch" }}>
      <CancelIcon color="warning" />
    </SvgIcon>
  );
  const icon = qualifies ? checkbox : nope;

  return (
    <Stack>
      <Stack direction="row">
        {icon}
        <Typography>{qualifies ? t("SurveyDialog.qualifies") : t("SurveyDialog.notQualifies")}</Typography>
      </Stack>
      <Typography variant="body2" color="textSecondary">
        {t("SurveyDialog.thanks")}
      </Typography>
    </Stack>
  );
};

export default DonationQualifier;
