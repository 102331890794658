import { useCallback } from "react";

import { CustomerModel, UserModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";

export const useDeleteCustomerNotification = () => {
  const showSnackbar = useSuccessSnackbar();

  return useCallback(
    async (customerId: string, notificationId?: string) => {
      if (!notificationId) {
        return;
      }
      const ref = getCollection(CustomerModel).doc(customerId).collection("notifications").doc(notificationId);
      await ref.delete();

      showSnackbar("Notification successfully removed");
    },
    [showSnackbar]
  );
};

export const useDeleteUserNotification = () => {
  const showSnackbar = useSuccessSnackbar();

  return useCallback(
    async (userId?: string, notificationId?: string) => {
      if (!notificationId || !userId) {
        return;
      }
      const ref = getCollection(UserModel).doc(userId).collection("userPersonalNotifications").doc(notificationId);
      await ref.delete();

      showSnackbar("Notification successfully removed");
    },
    [showSnackbar]
  );
};
