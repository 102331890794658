import { useMemo } from "react";

import useQueryString from "../../Components/hooks/useQueryString";
import { type UtmParams } from "./types";
import { formatUtm, setUtmParams } from "./utils";

export const useUtmParams = (): UtmParams => {
  const qs = useQueryString();

  return useMemo(() => {
    const utmParams = formatUtm(qs);
    setUtmParams(utmParams);
    return utmParams;
  }, [qs]);
};
