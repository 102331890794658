import { type CustomerModelSlackChannelModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

export { slackText, userNotificationTexts } from "../../../assets/texts";
export { useErrorSnackbar, useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
export { consoleErrorWithSentry } from "../../../utils";
export const emptyFunction = () => {
  // empty function
};

export type SlackDataProps = {
  data: CustomerModelSlackChannelModel;
  ref: ModelReference<CustomerModelSlackChannelModel>;
} | null;

export type SharedChannelProps = {
  title: string;
  invitationUrl?: string;
  name?: string;
  error?: any;
};
