import { createContext, type ReactNode, useCallback, useContext } from "react";

import noop from "lodash/noop";

import {
  type Comparator,
  type DataColumns,
  type DefaultFilterColumn,
  type DefaultFilterColumns,
  getDefaultValue,
  type LogicalOperator,
} from "../../types/FilterTable";
import { type NestedObjectLeaves } from "../../utils/NestedKeyOf";
import usePersistedState from "../hooks/usePersistedState";
import { type ColumnChange } from "./types";

type ColumnsOrderContextProps = [ColumnChange[], (change: ColumnChange[]) => void];

export const ColumnsOrderContext = createContext<ColumnsOrderContextProps>([[], noop]);

type FilterContextProps<T> = [DefaultFilterColumns<T>, (filters: DefaultFilterColumns<T>) => void];

const filtersContext = createContext<FilterContextProps<unknown> | undefined>(undefined);

type SavedFilterColumn<T> =
  | LogicalOperator
  | {
      column?: NestedObjectLeaves<T>;
      comparator?: Comparator;
      value: any;
      label?: string;
    };

type SavedFilterColumns<T> = readonly SavedFilterColumn<T>[];

type Props<T> = {
  children: ReactNode;
  columns: DataColumns<T>;
  persistenceKey?: string;
  persistInSession?: boolean;
  defaultValue?: DefaultFilterColumns<T>;
};

export function FilterContextProvider<T>({
  children,
  columns,
  persistenceKey,
  persistInSession,
  defaultValue = [],
}: Props<T>) {
  // serializes only the path of a column to save in local storage
  const serializer = (filters: DefaultFilterColumns<T>): SavedFilterColumns<T> =>
    filters.flatMap((filter): SavedFilterColumns<T> => {
      if (typeof filter === "string") {
        return [filter];
      }

      return [
        {
          column: filter.column?.path,
          comparator: filter.comparator ?? undefined,
          value: filter.value,
          label: filter.label,
        },
      ];
    });

  // restores the full column from local storage based on the path
  // including functions and their fields needed by the filter
  const deserialize = useCallback(
    (filters: SavedFilterColumns<T>): DefaultFilterColumns<T> => {
      if (!filters) {
        return [];
      }

      return filters.map((filter) => {
        if (typeof filter === "string") {
          return filter;
        }

        const column = columns.find((c) => c.path === (filter as DefaultFilterColumn<T>).column);

        return column
          ? {
              // DefaultFilterColumn for searching one column
              column: {
                label: column.label,
                path: filter.column,
                toOption: column.toOption,
                transformData: column.transformData,
              },
              comparator: filter.comparator,
              value: filter.value,
              label: filter.label ?? filter.value,
              complete: true,
            }
          : {
              // DefaultFilterColumn for searching all columns
              value: filter.value,
              label: filter.label ?? filter.value,
              complete: true,
            };
      });
    },
    [columns]
  );

  // retrieve and set filter from local storage with unique key for each use of filter
  const filtersState: FilterContextProps<T> = usePersistedState(
    persistenceKey ? `enhancedTableFilter_${persistenceKey}` : null,
    persistInSession ?? false,
    getDefaultValue(defaultValue),
    serializer,
    deserialize
  );

  return <filtersContext.Provider value={filtersState}>{children}</filtersContext.Provider>;
}

export const useFiltersContext = () => {
  const context = useContext(filtersContext);

  if (!context) {
    throw new Error("useFiltersContext must be used within a FilterContextProvider");
  }

  return context;
};
