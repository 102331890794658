import { useMemo } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const ActiveUsersChart = ({ data }: { data: any }) => {
  const theme = useTheme();

  const options = useMemo(
    () => ({
      chart: {
        height: 170,
        backgroundColor: "transparent",
        animation: true,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: null,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        x: 0,
        verticalAlign: "top",
        itemStyle: {
          fontSize: 10,
          color: theme.palette.text.primary,
        },
        floating: false,
        backgroundColor: "transparent",
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      xAxis: {
        categories: data?.data?.values ? Object.keys(data?.data?.values["customer.open"]) : [],
        crosshair: true,
        labels: {
          enabled: false,
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      series: [
        {
          name: "30-days Daily Active Users",
          type: "line",
          color: theme.palette.primary.main,
          animation: true,
          data: data?.data?.values ? Object.values(data?.data?.values["customer.open"]) : [],
        },
      ],
      tooltip: {
        valueDecimals: 0,
      },
    }),
    [data?.data?.values, theme.palette.primary.main, theme.palette.text.primary]
  );

  return (
    <Box ml={1} margin={1}>
      {!data ? (
        <Box mt={10} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={32} color="primary" />
        </Box>
      ) : (
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={options} />
      )}
    </Box>
  );
};
