import { type FilterTableItemDescriptor } from "../../../Components/FilterTable/filterTableUtils";
import { type AsgTableItem } from "../types";

export const AsgItemsDescriptions: FilterTableItemDescriptor<AsgTableItem>[] = [
  {
    headerText: "Auto scaling group name",
    value: "asgName",
    filterType: "string",
  },
  {
    headerText: "Optimization Status",
    value: "optimizationStatus",
    filterType: "string",
  },
  {
    headerText: "Desired/Min/Max",
    tooltip: "Asg Desired/Min/Max Capacity",
    value: "desiredMinMax",
    hidden: { mdDown: true },
  },
  {
    headerText: "OD/Spot/BC",
    tooltip: "Asg On-demand/Spot ratio and On-demand base capacity",
    value: "onDemandSpotBaseCapacity",
    hidden: { mdDown: true },
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
    hidden: { mdDown: true },
    hiddenByDefault: true,
  },
  {
    headerText: "Account Name",
    value: "accountName",
    filterType: "string",
    hidden: { mdDown: true },
  },
  {
    headerText: "Account Id",
    value: "accountId",
    filterType: "string",
    hidden: { mdDown: true },
    hiddenByDefault: true,
  },
  {
    headerText: `Spot Spending`,
    value: "spotSpending",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: `OD Spending`,
    value: "odSpending",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: `Savings`,
    value: "savings",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: "Refresh",
    value: "@",
    hidden: { mdDown: true },
  },
];

export const AsgAdditionalFilters: FilterTableItemDescriptor<AsgTableItem>[] = [
  {
    headerText: "Desired",
    value: "desiredCapacity",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: "Min",
    value: "minCapacity",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: "Max",
    value: "maxCapacity",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: "On Demand Ratio",
    value: "onDemandRatio",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: "Spot Ratio",
    value: "spotRatio",
    filterType: "number",
    hidden: { mdDown: true },
  },
  {
    headerText: "OD Base Capacity",
    value: "baseCapacity",
    filterType: "number",
    hidden: { mdDown: true },
  },
];
