import { ClusterConnectionStatus } from "@doitintl/cmp-models";
import { Chip } from "@mui/material";
import { type ChipOwnProps } from "@mui/material/Chip";

interface ClusterStatusProps {
  status: ClusterConnectionStatus;
}

const statusColor: Record<ClusterConnectionStatus, ChipOwnProps["color"]> = {
  [ClusterConnectionStatus.HEALTHY]: "success",
  [ClusterConnectionStatus.UNHEALTHY]: "error",
  [ClusterConnectionStatus.UNKNOWN]: "secondary",
};

const ClusterStatus = ({ status }: ClusterStatusProps) => (
  <Chip sx={{ textTransform: "capitalize" }} color={statusColor[status]} label={status} />
);

export default ClusterStatus;
