import { type JSX, useCallback } from "react";

import { Link } from "react-router-dom";
import { Link as MUILink, Typography } from "@mui/material";

import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { jsUcfirst } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { HEADING_VARIANT } from "./cloudCardStyle";

type Props = {
  action?: JSX.Element;
  name: string;
  reportPath: string;
  hasAccessToReport: boolean;
  description: string;
  hasFilter: boolean;
};

export const CardReportHeader = ({ action, hasAccessToReport, name, reportPath, description, hasFilter }: Props) => {
  const redirectToReport = useCallback(() => {
    if (name === "[GKE Lens] Unallocated Resources by Cluster") {
      mixpanel.track("dashboards.gke-lens.cost-allocation-clusters");
    }
  }, [name]);

  const title = hasAccessToReport ? (
    <MUILink
      variant={hasFilter ? "h4" : HEADING_VARIANT}
      component={Link}
      to={reportPath}
      underline="hover"
      color="inherit"
    >
      {name}
    </MUILink>
  ) : (
    <Typography variant={HEADING_VARIANT}>{name}</Typography>
  );

  return (
    <WidgetCardHeader onClick={redirectToReport} title={title} subheader={jsUcfirst(description)} action={action} />
  );
};
