import { DateTime } from "luxon";

import { type HeaderColumn, numberComparators, stringComparators } from "../../types/FilterTable";
import { levelText } from "./utils";

export const anomalyColumns: HeaderColumn[] = [
  {
    label: "Start Time",
    value: "_ts",
    hidden: { smDown: false },
    tooltip: "Start Time",
  },
  {
    label: "Status",
    value: "status",
    hidden: { mdDown: true },
    tooltip: "Status",
  },
  {
    label: "Project/Account",
    value: "project_id",
    hidden: { mdDown: true },
    tooltip: "Project/Account",
  },
  {
    label: "Service",
    value: "service_name",
    hidden: { mdDown: true },
    tooltip: "Service",
  },
  {
    label: "SKU",
    value: "sku_name",
    hidden: { mdDown: true },
    tooltip: "SKU",
  },
  {
    label: "Severity",
    value: "_severity",
    hidden: { mdDown: true },
    tooltip: "Severity",
  },
  {
    label: "Cost of Anomaly",
    value: "cost_of_anomaly",
    hidden: { mdDown: true },
    tooltip: "Cost of Anomaly",
  },
  {
    label: "Attribution",
    value: "attribution_name",
    hidden: { mdDown: true },
    hiddenByDefault: true,
    tooltip: "Attribution",
  },
  {
    label: "Anomaly",
    value: "anomaly",
    hidden: { mdDown: false },
    tooltip: "Anomaly",
  },
  {
    label: "Details",
    value: "action",
    hidden: { smDown: false },
    tooltip: "Details",
    preventSelection: true,
  },
] as const;

export const anomalyFilterColumns = [
  {
    label: "Severity",
    path: "_severity",
    comparators: numberComparators,
    toOption: (value) => ({ value, label: value === 1 ? "Info" : levelText[value] }),
  },
  {
    label: "Service",
    path: "service_name",
    comparators: stringComparators,
  },
  {
    label: "SKU",
    path: "sku_name",
    comparators: stringComparators,
  },
  {
    label: "Project/Account",
    path: "project_id",
    comparators: stringComparators,
  },
  {
    label: "Cost of Anomaly",
    path: "cost_of_anomaly",
    type: "Number",
    comparators: numberComparators,
  },
  {
    label: "Start Time",
    path: "_ts",
    type: "DateTime",
    comparators: numberComparators,
    placeholder: "YYYY-MM-DD hh:mm",
    transform: (value) => DateTime.fromFormat(value, "yyyy-LL-dd HH:mm", { zone: "utc" }),
    validate: (value) => DateTime.fromFormat(value, "yyyy-LL-dd HH:mm", { zone: "utc" })?.isValid,
    toOption: (value) => {
      const strValue = value.toFormat("yyyy-LL-dd HH:mm");
      return {
        value: strValue,
        label: strValue,
      };
    },
  },
  {
    label: "Status",
    path: "status",
    comparators: ["==", "!="],
    toOption: (value: string) => {
      const label = value ? value.charAt(0).toLocaleUpperCase() + value.slice(1).toLowerCase() : undefined;
      return {
        value,
        label,
      };
    },
  },
  {
    label: "Attribution",
    path: "attribution_name",
    comparators: stringComparators,
  },
] as const;
