import { EditBillingProfileForm } from "../../Components/CreateBillingProfile/BillingForm/EditBillingProfileForm";
import { Loader } from "../../Components/Loader";
import { useEntityData, useGoBackToReferrer } from "./hooks";

export const EditEntityPage = () => {
  const goBackToReferrer = useGoBackToReferrer("../../../billing-profiles");
  const entity = useEntityData();

  return (
    <Loader loading={entity === undefined}>
      {entity ? <EditBillingProfileForm entity={entity} onClose={goBackToReferrer} /> : <></>}
    </Loader>
  );
};
