import { type ReactNode } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

import { globalText } from "../../../assets/texts";
import { useFullScreen } from "../../../utils/dialog";

type Props = {
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
  title: string;
  text: string | ReactNode;
  cyDataPrefix?: string;
  acceptText?: string;
};

const AckDialog = ({ open, onCancel, onAccept, title, text, cyDataPrefix, acceptText }: Props) => {
  const { fullScreen } = useFullScreen();

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onAccept();
      event.preventDefault();
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="ack-order-dialog-title"
      aria-describedby="ack-order-dialog-description"
      fullScreen={fullScreen}
      onClose={(_) => {
        onCancel();
      }}
      onKeyDown={onKeyDown}
    >
      <DialogTitle id="ack-order-dialog-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="ack-order-dialog-description">{text}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color="primary" variant="text" onClick={onCancel} data-cy={`${cyDataPrefix}CancelButton`}>
          {globalText.CANCEL}
        </Button>
        <Button variant="contained" color="primary" onClick={onAccept} data-cy={`${cyDataPrefix}ConfirmButton`}>
          {acceptText || globalText.CONFIRM}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AckDialog;
