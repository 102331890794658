import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";

export const ContactSupportAlertAction = () => (
  <Link
    component={NavLink}
    to="./support/new"
    color="inherit"
    sx={{ textDecoration: "none", marginTop: 0.5, marginRight: 2 }}
  >
    Contact support
  </Link>
);
