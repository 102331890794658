import { createContext, type ReactNode, useContext, useState } from "react";

const KeyboardShortcutsContext = createContext<{
  open: boolean;
  setShortcutsOpen: (open: boolean) => void;
}>({
  open: false,
  setShortcutsOpen: () => {},
});

export const KeyboardShortcutsOpenProvider = ({ children }: { children?: ReactNode }) => {
  const [open, setShortcutsOpen] = useState(false);

  return (
    <KeyboardShortcutsContext.Provider value={{ open, setShortcutsOpen }}>{children}</KeyboardShortcutsContext.Provider>
  );
};

export const useKeyboardShortcutsOpen = () => {
  const context = useContext(KeyboardShortcutsContext);
  if (!context) {
    throw new Error("useKeyboardShortcuts must be used within a KeyboardShortcutsProvider");
  }

  return context;
};
