import { type KeyboardEventHandler } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Card, Divider, Radio, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { ssoTexts } from "../../../../assets/texts";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { type AuthState, type SSOProviders } from "../types";

type Props = {
  type: SSOProviders;
  description: string;
  selected: boolean;
  configured: boolean;
  ssoEnabled: boolean;
  openProviderDialog: () => void;
  state: AuthState;
};

export const SsoProviderConfigCard = ({
  configured,
  openProviderDialog,
  type,
  description,
  selected,
  ssoEnabled,
  state,
}: Props) => {
  const routeMatchURL = useRouteMatchURL();
  const theme = useTheme();

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      openProviderDialog();
    }
  };

  return (
    <Card sx={{ borderColor: selected ? "primary.main" : "", borderWidth: 2 }}>
      <Stack direction="row" sx={{ mb: 2 }}>
        <Box sx={{ mt: 1, ml: 1 }}>
          <Radio
            size="small"
            color={configured ? "primary" : "default"}
            onChange={openProviderDialog}
            checked={selected}
            disabled={!configured}
            sx={{ color: ssoEnabled && configured ? "text.primary" : "action.disabled" }}
            onKeyDown={handleKeyDown}
            data-cy={`${type}-radio`}
          />
        </Box>
        <Stack spacing={1.5} sx={{ margin: theme.spacing(2, 12, 0, 1), width: "100%" }}>
          <Typography variant="body1" sx={{ color: configured ? "text.primary" : "action.disabled" }}>
            <strong>{type.toUpperCase()}</strong>
          </Typography>
          <Typography variant="body1" sx={{ color: configured ? "text.primary" : "action.disabled" }}>
            {description}
          </Typography>
        </Stack>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: theme.spacing(2.25, 0, 0, 1) }} />
        <Box sx={{ margin: theme.spacing(4, 2, 0, 2) }}>
          <Typography
            sx={{ whiteSpace: "nowrap" }}
            component={RouterLink}
            to={state.loading ? "#" : `${routeMatchURL}/sso/${type}`}
            color={state.loading ? "action.disabled" : "primary"}
            variant="body1"
            data-cy={`${type}-configure`}
          >
            {configured ? ssoTexts.EDIT_CONFIGURATION : ssoTexts.CONFIGURE}
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
