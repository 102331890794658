import { collection, type Reference } from "@doitintl/models-types/src";

import { type CustomerModel } from "./Customer";
import { type UserModel } from "./User";

@collection("threads")
export class ThreadModel {
  customer?: Reference<CustomerModel> | null;

  reporter?: Reference<UserModel> | null;

  source!: "insight"; // In the future, this could also be "alert", "budget", etc.

  sourceData!: InsightThreadData; // As above, this could also be AlertThreadData (and others) in the future

  target!: "jira"; // As above, could also be "asana", "servicenow", etc.

  targetData!: JiraTargetThreadData;

  isDeleted!: boolean;
}

export type InsightThreadData = {
  insightKey: string;
  providerID: string;
};

export type JiraTargetThreadData = {
  issueID: string;
  issueKey: string;
  issueURL: string;
};
