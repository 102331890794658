import { createContext, useContext } from "react";

type PresetPromptsContext = {
  setSelectedPrompt: (prompt: string) => void;
};

const currentPresetPromptsContext = createContext<PresetPromptsContext | undefined>(undefined);

const useCurrentPresetPrompt = () => {
  const currentPresetPromptContext = useContext(currentPresetPromptsContext);

  if (!currentPresetPromptContext) {
    throw new Error("there is no context for the current preset prompt");
  }

  return currentPresetPromptContext;
};

export { currentPresetPromptsContext, useCurrentPresetPrompt };
