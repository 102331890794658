import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

import { isProduction } from "../../constants";
import mixpanel from "../../utils/mixpanel";
import {
  type ManualPurchaseRequestItem,
  type PurchasePlanDetails,
  type PurchasePriceRequestItem,
  type PurchasePriceResponseItem,
  type RiskyPlanDetailsForApprove,
} from "./Operations/GCP/types";

enum FlexsaveUrlPrefix {
  aws = "aws",
  gcp = "gcp",
}

export class FlexsaveApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    private readonly customerId: string,
    private readonly type: FlexsaveType
  ) {
    this.api = api;
    this.customerId = customerId;
    this.apiPrefix = `/v1/customers/${this.customerId}/flexsave/${
      type === FlexsaveTypes.AWS ? FlexsaveUrlPrefix.aws : FlexsaveUrlPrefix.gcp
    }`;
    this.type = type;
  }

  public async enable() {
    mixpanel.track(`flexsave-${this.type.toLowerCase()}.enable`);

    return this.api.post(`${this.apiPrefix}/enable`);
  }

  public disable() {
    mixpanel.track(`flexsave-${this.type.toLowerCase()}.disable`);

    return this.api.post(`${this.apiPrefix}/disable`);
  }

  public canDisable() {
    return this.api.get(`${this.apiPrefix}/can-disable`);
  }
}

export class FlexsaveOpsApi {
  private readonly apiPrefix: string;

  constructor(
    private readonly api: AxiosInstance,
    version?: string
  ) {
    this.api = api;
    this.apiPrefix = version ? `/v1/flexsave-gcp/purchase-plan/${version}` : `/v1/flexsave-gcp/purchase-plan`;
  }

  public async calculatePurchasePlanPrice(purchases: PurchasePriceRequestItem[]): Promise<PurchasePriceResponseItem> {
    const response = await this.api.post(`${this.apiPrefix}/prices`, {
      purchases,
    });
    return response.data;
  }

  public async manualPurchase(cuds: ManualPurchaseRequestItem[], dryRun = !isProduction): Promise<boolean> {
    return this.api.post(`${this.apiPrefix}/manual-purchase`, {
      cuds,
      dry_run: dryRun,
    });
  }

  public async approve(customerIds: string[], workloads?: PurchasePlanDetails[]): Promise<boolean> {
    return this.api.post(`${this.apiPrefix}/execute`, {
      customerIds,
      workloads,
    });
  }

  public async approveV2(
    customerIds: string[],
    workloads?: PurchasePlanDetails[],
    dryRun = !isProduction
  ): Promise<boolean> {
    return this.api.post(`${this.apiPrefix}/execute`, {
      customer_ids: customerIds,
      workloads,
      dry_run: dryRun,
    });
  }

  public async approveRisks(plansWithRisks: RiskyPlanDetailsForApprove[], dryRun = !isProduction): Promise<boolean> {
    return this.api.post(`${this.apiPrefix}/approve`, {
      approved: plansWithRisks,
      dry_run: dryRun,
    });
  }

  public async approveBulk(workloads?: Pick<PurchasePlanDetails, "region" | "hardware" | "type">[]): Promise<boolean> {
    return this.api.post(`${this.apiPrefix}/execute-bulk`, {
      workloads,
      dry_run: !isProduction,
    });
  }

  public async refresh(): Promise<void> {
    await this.api.get(`${this.apiPrefix}/refresh`);
  }

  public async refreshCustomer(customerId: string): Promise<void> {
    await this.api.get(`${this.apiPrefix}/refresh/${customerId}`);
  }

  public async refreshWorkloads(): Promise<void> {
    await this.api.get("/v1/flexsave-gcp/ops/agg-workloads");
  }
}
