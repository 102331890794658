import {
  AppModel,
  AppRoleNotificationModel,
  CustomerModel,
  DashboardModel,
  EntityModel,
  InviteModel,
  NotificationProviderType,
  type ProductEnum,
  UserModel,
  type UserNotification,
} from "@doitintl/cmp-models";
import { type FieldValueType, getBatch, getCollection } from "@doitintl/models-firestore";
import type * as axios from "axios";

import { type Customer } from "../../types";
import { serverTimestamp } from "../../utils/firebase";

export const getUserNotifications = async () => {
  const userNotificationsQuery = await getCollection(AppModel).doc("userNotifications").get();
  return userNotificationsQuery.asModelData()?.notifications ?? [];
};

export const getColumnsData = async () => {
  const usersCsvColumnsDataQuery = await getCollection(AppModel).doc("users-csv-columns").get();
  return usersCsvColumnsDataQuery.data();
};

export const getEntities = async (customer: Customer) => {
  const entitiesQuery = await getCollection(EntityModel).where("customer", "==", customer.ref).get();
  return entitiesQuery.docs.map((doc) => ({ id: doc.id, ref: doc.ref, ...doc.data() }));
};

export const addUsers = (users) => {
  const batch = getBatch();
  users.forEach((user) => {
    const userRef = getCollection(InviteModel).newDoc();
    batch.set(userRef, user);
  });
  return batch.commit();
};

export const updateUsers = async (users, customer) => {
  const batch = getBatch();
  await Promise.all(
    users.map(async (user) => {
      const userInviteQuery = await getCollection(InviteModel)
        .where("customer.ref", "==", customer.ref)
        .where("email", "==", user.email)
        .get();
      const userQuery = await getCollection(UserModel)
        .where("customer.ref", "==", customer.ref)
        .where("email", "==", user.email)
        .get();

      const userRef = userInviteQuery.docs[0]?.ref ?? userQuery.docs[0]?.ref;
      batch.update(userRef, user);
    })
  );

  return batch.commit();
};

export const createUser = async (newUser: FieldValueType<Omit<InviteModel, "subCollections">>) => {
  const response = await getCollection(InviteModel).add(newUser);
  return response.id;
};

export const updateUserInvitation = async (
  email: string,
  user: Partial<FieldValueType<InviteModel>>,
  customerId: string,
  defaultNotifications: UserNotification[]
) => {
  const customerRef = getCollection(CustomerModel).doc(customerId);
  const userQuery = await getCollection(InviteModel)
    .where("customer.ref", "==", customerRef)
    .where("email", "==", email)
    .get();

  const doc = userQuery.docs[0];

  doc.modelRef.collection("userPersonalNotifications").add({
    createdAt: serverTimestamp(),
    createdBy: email,
    name: "My email notifications",
    providerTarget: { [NotificationProviderType.EMAIL]: [email] },
    selectedNotifications: Object.fromEntries(defaultNotifications.map((notificationType) => [notificationType, {}])),
    selectedNotificationsKeys: defaultNotifications,
    customerRef,
  });

  if (doc) {
    const userInviteRef = doc.modelRef;
    await userInviteRef.update(user);

    return userInviteRef.id;
  }
};

export const getUserUidByEmail = async (email: string, api: axios.AxiosInstance, customerId: string) => {
  const response = await api.request({
    method: "get",
    params: { email },
    url: `/v1/customers/${customerId}/users/getUserId`,
  });
  return response?.data;
};

export const getNotificationsPerRole = async (rRefId: string): Promise<number[]> => {
  const notifsPerRole = await getCollection(AppRoleNotificationModel).doc("notifications-per-role").get();
  if (!notifsPerRole.exists()) {
    return [];
  }
  const perRoleData = notifsPerRole.asModelData();
  return perRoleData?.roleNotifications.find((r) => r?.roleRef.id === rRefId)?.notifications ?? [];
};

export const getBaseDailyDigestAttribution = (
  dailyDigestsAttributionIds: { assetId: ProductEnum; value: string }[],
  customer: Customer
) => {
  const res = dailyDigestsAttributionIds.filter((d) => customer?.assets?.includes(d.assetId));
  return res.map((attr) =>
    getCollection(DashboardModel).doc("google-cloud-reports").collection("attributions").doc(attr.value)
  );
};
