import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { type TableNotification } from "./types";
import { editCustomerNotification, getProviderIcon } from "./utils";

type Props = {
  row: TableNotification;
  onRemoveNotificationClicked: (notificationId: string, isCustomer: boolean) => void;
  viewOnly?: boolean;
};

export const CustomerNotificationRow = ({ row, onRemoveNotificationClicked, viewOnly }: Props) => {
  const customerId = useCustomerId();

  const options: ThreeDotsMenuOption[] = [
    {
      key: "1",
      label: (
        <Link
          color="inherit"
          sx={{ textDecoration: "none" }}
          to={editCustomerNotification(customerId, row.id)}
          component={RouterLink}
        >
          {viewOnly ? "View notification" : "Edit notification"}
        </Link>
      ),
    },
  ];

  if (!viewOnly) {
    options.push({
      key: "2",
      label: "Remove notification",
      color: "error.main",
      action: () => {
        onRemoveNotificationClicked(row.id, true);
      },
    });
  }

  return (
    <CellsWrapper>
      <TableCell>
        <Link color="inherit" to={editCustomerNotification(customerId, row.id)} component={RouterLink}>
          {row.name}
        </Link>
      </TableCell>
      <TableCell>{getProviderIcon(row.providerType)}</TableCell>
      <TableCell>{row.providerTarget}</TableCell>
      <TableCell>{row.createdBy}</TableCell>

      <TableCell padding="none" align="right">
        <ThreeDotsMenu closeAfterSelect options={options} />
      </TableCell>
    </CellsWrapper>
  );
};
