import React from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

interface ContractCurrencySelectorProps {
  value: CurrencyCode;
  options: string[];
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContractCurrencySelector: React.FC<ContractCurrencySelectorProps> = ({ value, options, disabled, onChange }) => (
  <TextField
    label="Currency"
    data-cy="currency"
    value={value}
    onChange={onChange}
    disabled={disabled}
    select
    sx={{ flexBasis: "100px", mr: 1 }}
    margin="normal"
    variant="outlined"
  >
    {!!options.length &&
      options.sort().map((currency) => (
        <MenuItem key={currency} value={currency}>
          {currency}
        </MenuItem>
      ))}
  </TextField>
);

export default ContractCurrencySelector;
