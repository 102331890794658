import { useCallback, useEffect, useState } from "react";

import { Alert } from "@mui/material";
import { DateTime, Interval } from "luxon";

type Props = {
  navigatorTrialStartDate?: DateTime;
  navigatorTrialEndDate?: DateTime;
  solveTrialStartDate?: DateTime;
  solveTrialEndDate?: DateTime;
};

const ALERT_STATE_KEY = "lastTrialAlertDismissedDate";
const formattedDate = (date?: DateTime) => date?.toFormat("dd MMM yyyy");

const getTrialBannerText = (navigatorTrialEndDate?: DateTime, solveTrialEndDate?: DateTime) => {
  if (navigatorTrialEndDate && solveTrialEndDate) {
    const navigatorFormattedDate = formattedDate(navigatorTrialEndDate);
    const solveFormattedDate = formattedDate(solveTrialEndDate);
    if (navigatorTrialEndDate.equals(solveTrialEndDate)) {
      return `Reminder! Your DoiT Cloud Navigator and DoiT Cloud Solve trial ends ${navigatorFormattedDate}`;
    } else {
      return `Reminder! Your DoiT Cloud Navigator trial ends on ${navigatorFormattedDate} and DoiT Cloud Solve trial ends ${solveFormattedDate}`;
    }
  } else if (navigatorTrialEndDate) {
    return `Reminder! Your free trial of DoiT Cloud Navigator ends on ${formattedDate(navigatorTrialEndDate)}`;
  } else if (solveTrialEndDate) {
    return `Reminder! Your free trial of DoiT Cloud Solve ends on ${formattedDate(solveTrialEndDate)}`;
  }
};

const TierTrialEndAlert = ({
  navigatorTrialStartDate,
  navigatorTrialEndDate,
  solveTrialStartDate,
  solveTrialEndDate,
}: Props) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const now = DateTime.now();
    const isNavigatorTrialActive = navigatorTrialStartDate && navigatorTrialEndDate;
    const isSolveTrialActive = solveTrialStartDate && solveTrialEndDate;
    const navigatorTrialInterval = isNavigatorTrialActive
      ? Interval.fromDateTimes(navigatorTrialStartDate, navigatorTrialEndDate)
      : undefined;
    const solveTrialInterval = isSolveTrialActive
      ? Interval.fromDateTimes(solveTrialStartDate, solveTrialEndDate)
      : undefined;

    const checkAndSetTrial = () => {
      if (!isNavigatorTrialActive && !isSolveTrialActive) {
        return;
      }
      if (navigatorTrialInterval?.contains(now) || solveTrialInterval?.contains(now)) {
        const lastAlertDismissedDate = localStorage.getItem(ALERT_STATE_KEY);
        const today = now.toISODate();
        if (lastAlertDismissedDate !== today) {
          setShowAlert(true);
        }
      } else {
        setShowAlert(false);
      }
    };
    checkAndSetTrial();
  }, [navigatorTrialStartDate, navigatorTrialEndDate, solveTrialStartDate, solveTrialEndDate]);

  const onAlertDismiss = useCallback(() => {
    setShowAlert(false);
    localStorage.setItem(ALERT_STATE_KEY, DateTime.now().toISODate());
  }, []);

  return (
    <>
      {showAlert && (navigatorTrialEndDate || solveTrialEndDate) && (
        <Alert sx={{ py: 0 }} onClose={onAlertDismiss} severity="info">
          {getTrialBannerText(navigatorTrialEndDate, solveTrialEndDate)}
        </Alert>
      )}
    </>
  );
};

export default TierTrialEndAlert;
