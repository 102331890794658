import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type ClusterInfo } from "../types";

function getDocumentReference(clusterInfo: ClusterInfo) {
  const docId = `${clusterInfo.accountId}_${clusterInfo.region}_${clusterInfo.clusterName}`;
  return getCollection(IntegrationModel).doc("k8s-metrics").collection("eks").doc(docId);
}

export const addClusterInfo = async (customerId: string, clusterInfo: ClusterInfo) => {
  await getDocumentReference(clusterInfo).set({
    ...clusterInfo,
    customerId,
    isManual: true,
  } as any);
};

export const deleteClusterInfo = async (customerId: string, clusterInfo: ClusterInfo) => {
  const docRef = getDocumentReference(clusterInfo);

  const collectionRef = docRef.collection("deployments");

  const snapshot = await collectionRef.get();
  if (!snapshot.empty) {
    snapshot.docs.forEach((doc) => {
      doc.modelRef.delete();
    });
  }

  await docRef.delete();
};

export const editClusterInfo = async (
  customerId: string,
  previousClusterInfo: ClusterInfo,
  currentClusterInfo: ClusterInfo
) => {
  await addClusterInfo(customerId, currentClusterInfo);
  await deleteClusterInfo(customerId, previousClusterInfo);
};
