import { AnalyticsResourceType } from "@doitintl/cmp-models";

import { type AttributionWRef } from "../../../types";

// sortAttributions sorts attribution by type (in order: custom, preset, managed) and then sorts each type
// by the name of the attribution.
export const sortAttributions = (attrs: AttributionWRef[]) =>
  attrs.sort((a, b) => {
    if (a.data.type !== b.data.type) {
      if (
        a.data.type === AnalyticsResourceType.CUSTOM ||
        (a.data.type === AnalyticsResourceType.PRESET && b.data.type === AnalyticsResourceType.MANAGED)
      ) {
        return -1;
      } else {
        return 1;
      }
    }

    // If same type, sort by name
    return a.data.name.localeCompare(b.data.name);
  });
