import { useCallback, useMemo } from "react";

import debounce from "lodash/debounce";

import mixpanel from "../../utils/mixpanel";

export const useDebouncedMixpanelEventHandler = ({
  mixpanelEventName,
  wait = 5000,
}: {
  mixpanelEventName?: string;
  wait?: number;
}) => {
  const debouncedSendMixpanelEvent = useMemo(() => {
    if (!mixpanelEventName) {
      return () => undefined;
    }

    const sendMixpanelEvent = (payload) => {
      mixpanel.track(mixpanelEventName, payload);
    };

    return debounce(sendMixpanelEvent, wait, { trailing: true, leading: false });
  }, [mixpanelEventName, wait]);

  return useCallback(debouncedSendMixpanelEvent, [debouncedSendMixpanelEvent]);
};
