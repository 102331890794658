import { type CurrencyCode } from "@doitintl/cmp-models";

import { getCurrencySymbol } from "./common";

const tenToPower = (power: number) => Math.pow(10, power);
const intlFormat = (n: number, d: number) =>
  new Intl.NumberFormat().format(Math.round(n * tenToPower(d)) / tenToPower(d));
const formatNumber = (value: number, d = 2) => {
  const suffixes = ["", "k", "M", "G", "T", "P", "E"];
  const absoluteValue = Math.abs(value);

  let matchedIndex = suffixes.findLastIndex((_, idx) => absoluteValue >= tenToPower(idx * 3));

  if (Math.round(value * tenToPower(d)) / tenToPower(matchedIndex * 3 + d) >= 1000 - tenToPower(-d)) {
    matchedIndex += 1;
  }

  return `${intlFormat(value / tenToPower(matchedIndex * 3), d)}${suffixes[matchedIndex] ?? ""}`;
};

export function formatUSDollars(value: number) {
  return `${value < 0 ? "-" : ""}$${formatNumber(Math.abs(value))}`;
}

export function formatGraphCurrencyValue(value: number, currency: CurrencyCode) {
  return `${value < 0 ? "-" : ""}${getCurrencySymbol(currency)}${formatNumber(Math.abs(value))}`;
}
