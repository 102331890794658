import { useCallback } from "react";

import { useApiContext } from "../../../../api/context";
import { marketplaceText } from "../../../../assets/texts";
import { useInfoSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";
import { useErrorSnackbar } from "../../../Integrations/Slack/utils";

const usePopulateBillingAccount = () => {
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const infoSnackbar = useInfoSnackbar();
  const errorSnackbar = useErrorSnackbar();

  return useCallback(
    async (accountID: string) => {
      try {
        const response = await api.request({
          method: "POST",
          url: `/v1/marketplace/gcp/accounts/${accountID}/populate-billing-account`,
        });
        if (response.status === 200) {
          successSnackbar(marketplaceText.POPULATE_BILLING_ACCOUNT_SUCCESSFULLY);
          return;
        }

        infoSnackbar(marketplaceText.POPULATE_BILLING_ACCOUNT_FAILED);
      } catch (error) {
        consoleErrorWithSentry(error);
        errorSnackbar(marketplaceText.POPULATE_BILLING_ACCOUNT_ERROR);
      }
    },
    [api, errorSnackbar, infoSnackbar, successSnackbar]
  );
};

export default usePopulateBillingAccount;
