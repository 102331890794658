import { useMemo } from "react";

import { TableFilterBar } from "../../../Components/FilterTable/Filterbar/TableFilterBar";
import { type KnownIssue } from "../types";
import { onlyOngoingKnownIssuesFilter, platformColumn, productColumn, statusColumn, titleColumn } from "./consts";

type Props = {
  knownIssues: KnownIssue[];
  onFilter: (filterFunction?: (issuesList: KnownIssue[]) => KnownIssue[]) => void;
};

export const KnownIssuesFilterbar = ({ knownIssues, onFilter }: Props) => {
  const columns = useMemo(() => [statusColumn, platformColumn, productColumn, titleColumn], []);
  return (
    <TableFilterBar
      items={knownIssues}
      filterColumn={columns}
      onFilter={onFilter}
      placeholder="Filter Cloud Incidents"
      defaultFilters={[onlyOngoingKnownIssuesFilter]}
    />
  );
};
