import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1),
  },
  // slackIcons: { // populte in case private channels are required
  //   marginRight: "-8px",
  //   height: "10px",
  //   // "&.svg $path": {
  //   //   fill: "red",
  //   // },
  //   // color: theme.palette.text.primary,
  // },
  link: {
    textAlign: "end",
    "& .MuiLink-underlineAlways": {
      cursor: "pointer",
    },
  },
}));
