import { type JSX } from "react";

import { Box, Grid } from "@mui/material";

import { RecipientSelector } from "../../../../Components/Selects/CloudAnalytics/RecipientSelector";

type NotificationsStepProps = {
  setAlertEdited: (alertEdited: boolean) => void;
  isCurrentUserEditor: boolean;
  infoPanel: JSX.Element;
  recipients: string[];
  setRecipients: (recipients: string[]) => void;
};

export const NotificationsStep = ({
  isCurrentUserEditor,
  setAlertEdited,
  infoPanel,
  recipients,
  setRecipients,
}: NotificationsStepProps) => (
  <Box key="stepNotifications">
    <Grid
      container
      key="grid-key"
      sx={{
        mt: "2.5rem",
      }}
    >
      <Grid item xs={12} sx={{ mb: "2.5rem" }}>
        {infoPanel}
        <RecipientSelector
          recipients={recipients}
          setRecipients={setRecipients}
          disabled={!isCurrentUserEditor}
          onChange={() => {
            setAlertEdited(true);
          }}
        />
      </Grid>
    </Grid>
  </Box>
);
