import { useEffect, useState } from "react";

import { CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

const useReportDocumentation = (documentationId: string) => {
  const [data, setData] = useState<{ html: string } | undefined>(undefined);

  useEffect(() => {
    const fetchDocumentation = async () => {
      const docSnap = await getCollection(CloudAnalyticsModel)
        .doc("reports")
        .collection("cloudAnalyticsReportsDocumentation")
        .doc(documentationId)
        .get();

      const data = docSnap.asModelData();
      if (!data) {
        return;
      }
      setData(data);
    };

    if (documentationId) {
      void fetchDocumentation();
    }
  }, [documentationId]);

  return data;
};

export default useReportDocumentation;
