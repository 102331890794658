export enum OnboardingStep {
  WELCOME,
  GIVE_ESTIMATION_PERMISSIONS,
  SHOW_SAVINGS,
  SHOW_NO_SAVINGS,
  GIVE_BILLING_PERMISSIONS,
  CREATE_BILLING_PROFILE,
  EDIT_BILLING_PROFILE,
  SUCCESS,
}

export type Company = {
  countries: string[];
  wireTransfer: WireTransfer[];
};

type WireTransfer = {
  key: string;
  value: string;
};

export type SavingsReport = {
  annualSavings: string;
  annualSavingsWithGrowth: string;
  lastMonthComputeSpend?: string;
  lastMonthWithFlexsave?: string;
};

export type CodeVariable = {
  [key: string]: {
    value: string;
    name: string;
  };
};

export type BillingAccountType = "My organization" | "Partner owned";
