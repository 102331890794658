import { transformToTemplateWithVersion } from "../templateLibrary/transformToTemplateWithVersion";
import { type ReportTemplateWithVersion, type TemplateWithLastVersionFromBackend } from "../templateLibrary/types";

export type ReportTemplatesAction = {
  type: string;
  payload: {
    reportTemplatesInitialList?: ReportTemplateWithVersion[];
    reportTemplateItem?: TemplateWithLastVersionFromBackend;
    reportTemplateId?: string;
  };
};

export const reportTemplatesReducer = (
  state: ReportTemplateWithVersion[] | null,
  action: ReportTemplatesAction
): ReportTemplateWithVersion[] | null => {
  switch (action.type) {
    case "INITIALIZE_REPORT_TEMPLATES":
      return [...(action.payload.reportTemplatesInitialList ?? [])];
    case "ADD_REPORT_TEMPLATE": {
      const reportTemplateItem = action.payload.reportTemplateItem;
      if (!reportTemplateItem) {
        return state;
      }
      const transformedTemplate = transformToTemplateWithVersion(
        reportTemplateItem.template,
        reportTemplateItem.lastVersion
      );
      return [...(state ?? []), { ...transformedTemplate }];
    }
    case "UPDATE_REPORT_TEMPLATE": {
      const reportTemplateItem = action.payload.reportTemplateItem;
      if (!state || !reportTemplateItem) {
        return state;
      }
      return state.map((reportTemplateWithVersion: ReportTemplateWithVersion) => {
        if (reportTemplateWithVersion.template.id === reportTemplateItem.template.id) {
          return transformToTemplateWithVersion(reportTemplateItem.template, reportTemplateItem.lastVersion);
        }
        return reportTemplateWithVersion;
      });
    }
    case "DELETE_REPORT_TEMPLATE": {
      if (!state || !action.payload.reportTemplateId) {
        return state;
      }
      return state.filter(
        (reportTemplateWithVersion: ReportTemplateWithVersion) =>
          reportTemplateWithVersion.template.id !== action.payload.reportTemplateId
      );
    }
    default:
      return state;
  }
};
