import { useMemo } from "react";

import { AnalyticsResourceType, CloudAnalyticsModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import intersection from "lodash/intersection";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useIsFeatureEntitled, useTier } from "../../../../Context/TierProvider";
import { type AttributionGroupWithRef } from "../../../../Pages/CloudAnalytics/attributionGroups/types";
import { attributionGroupsTransform } from "../../../../Pages/CloudAnalytics/attributionGroups/utils";
import { getCustomerRefList } from "../../../../utils/customers";

export const useAttributionGroups = (): [AttributionGroupWithRef[], boolean] => {
  const { getFeatureLimit } = useTier();
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext();

  const isEntitledAttributionGroups = useIsFeatureEntitled("analytics:attributionGroups");
  const isEntitledAttributionGroupsPresets = useIsFeatureEntitled("analytics:attributionGroups:presets");
  const featureLimit = getFeatureLimit("analytics:attributionGroups") as number | null;

  const collectionRef = useMemo(
    () => getCollection(CloudAnalyticsModel).doc("attribution-groups").collection("cloudAnalyticsAttributionGroups"),
    []
  );

  const customAttributionGroupsQuery = useMemo(() => {
    if (!customer.ref || !isEntitledAttributionGroups) {
      return;
    }

    const customerRefs = getCustomerRefList(customer, customerOrPresentationModeCustomer);

    let query = collectionRef.where("type", "==", AnalyticsResourceType.CUSTOM).where("customer", "in", customerRefs);

    if (featureLimit) {
      query = query.limit(featureLimit).orderBy("timeCreated", "desc");
    }

    return query;
  }, [customer, customerOrPresentationModeCustomer, collectionRef, featureLimit, isEntitledAttributionGroups]);

  const managedAttributionGroupsQuery = useMemo(() => {
    if (!customer.ref || !isEntitledAttributionGroups) {
      return;
    }

    return collectionRef.where("type", "==", AnalyticsResourceType.MANAGED).where("customer", "==", customer.ref);
  }, [isEntitledAttributionGroups, customer.ref, collectionRef]);

  const presetAttributionGroupsQuery = useMemo(() => {
    if (!isEntitledAttributionGroupsPresets) {
      return;
    }

    return collectionRef.where("type", "==", AnalyticsResourceType.PRESET).where("customer", "==", null);
  }, [isEntitledAttributionGroupsPresets, collectionRef]);

  const [customAttributionGroups, customAttributionGroupsLoading] = useCollectionData(customAttributionGroupsQuery, {
    transform: attributionGroupsTransform,
  });

  const [managedAttributionGroups, managedAttributionGroupsLoading] = useCollectionData(managedAttributionGroupsQuery, {
    transform: attributionGroupsTransform,
  });

  const [presetAttributionGroups, presetAttributionGroupsLoading] = useCollectionData(presetAttributionGroupsQuery, {
    transform: attributionGroupsTransform,
  });

  const isLoading =
    (customAttributionGroupsLoading && !!customAttributionGroupsQuery) ||
    (presetAttributionGroupsLoading && !!presetAttributionGroupsQuery) ||
    (managedAttributionGroupsLoading && !!managedAttributionGroupsQuery);

  // List of all attribution groups, where the preset attribution groups are filtered
  // by the available providers the customer uses.
  const attributionGroupList = useMemo(() => {
    if (isLoading) {
      return [];
    }

    const customerAssets = customerOrPresentationModeCustomer.assets ?? [];
    const presetAttributionGroupsFiltered = (presetAttributionGroups ?? []).filter((ag) =>
      ag.data.cloud?.length ? intersection(customerAssets, ag.data.cloud).length > 0 : true
    );

    return [
      ...(presetAttributionGroupsFiltered ?? []),
      ...(customAttributionGroups ?? []),
      ...(managedAttributionGroups ?? []),
    ];
  }, [
    isLoading,
    customerOrPresentationModeCustomer.assets,
    presetAttributionGroups,
    customAttributionGroups,
    managedAttributionGroups,
  ]);

  return [attributionGroupList, isLoading];
};
