import { collection, doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type AssetType } from "./AssetSetting";
import { type CloudAnalyticsModelMetricModel, type ForecastSettings, type LimitAggregation } from "./CloudAnalytics";
import { type InvoiceData, type ProductEnum } from "./Collection";
import { type CurrencyCode } from "./Currency";
import {
  type CustomerModel,
  type CustomerModelOrganizationModel,
  type DashboardRowHeight,
  type Widget,
} from "./Customer";
import { type EntityModel } from "./Entity";
import { type Label as ObjectLabel } from "./Label";
import { type PermissionModel } from "./Permission";

export enum Roles {
  OWNER = "owner",
  VIEWER = "viewer",
  EDITOR = "editor",
  MIXED = "mixed",
}

export type DashboardRole = `${Roles}`;

export enum Positions {
  UNUSED = "unused",
  ROW = "row",
  COL = "col",
  COUNT = "count",
}

export enum Relation {
  OR = "OR",
  AND = "AND",
}

export enum Metadata {
  FIXED = "fixed",
  OPTIONAL = "optional",
  DATETIME = "datetime",
  LABEL = "label",
  PROJECT_LABEL = "project_label",
  SYSTEM_LABEL = "system_label",
  ATTRIBUTION = "attribution",
  ATTRIBUTION_GROUP = "attribution_group",
  GKE = "gke",
  GKE_LABEL = "gke_label",
  EKS_LABEL = "eks_label",
  TAG = "tag",
  METRIC = "metric",
  LIMITS = "limits",
  GKE_COST_ALLOCATION_LABEL = "gke_cost_allocation_label",
  AWS_ORG_TAG = "organization_tag",
  POPULAR = "popular",
}

export type AttributionFilter = {
  allowNull: boolean;
  field: string;
  id: string;
  inverse: boolean;
  key: string;
  regexp?: string | null;
  type: Metadata | "";
  values: string[] | null;
};

export enum Aggregator {
  TOTAL = "total",
  TOTAL_OVER_TOTAL = "total_over_total",
  PERCENT_TOTAL = "percent_total",
  PERCENT_ROW = "percent_row",
  PERCENT_COL = "percent_col",
  COUNT = "count",
}

export enum Sort {
  A_TO_Z = "a_to_z",
  ASC = "asc",
  DESC = "desc",
}

export enum AnalyticsDataSource {
  BILLING = "billing",
  BQLENS = "bqlens",
  CUSTOMER_FEATURES = "customer_features",
  BILLING_DATAHUB = "billing-datahub",
}

export enum Metric {
  COST,
  USAGE,
  SAVINGS,
  MARGIN,
  CALCULATED,
  EXTENDED,
}

export type CustomTimeRange = {
  to: Timestamp;
  from: Timestamp;
};

export type ReportOptionalField = {
  key: string;
  type: Metadata | "";
};

export enum Renderer {
  TABLE = "table",
  HEATMAP = "table_heatmap",
  ROW_HEATMAP = "table_row_heatmap",
  COL_HEATMAP = "table_col_heatmap",
  COLUMN_CHART = "column_chart",
  STACKED_COLUMN_CHART = "stacked_column_chart",
  STACKED_COLUMN_CHART_LEGACY = "stacked_column_chart_2",
  BAR_CHART = "bar_chart",
  STACKED_BAR_CHART = "stacked_bar_chart",
  LINE_CHART = "line_chart",
  SPLINE_CHART = "spline_chart",
  AREA_CHART = "area_chart",
  AREA_SPLINE_CHART = "area_spline_chart",
  STACKED_AREA_CHART = "stacked_area_chart",
  SHEETS_EXPORT = "sheets_export",
  CSV_EXPORT = "csv_export",
  CSV_RAW_EXPORT = "csv_raw_export",
  CSV_CLIPBOARD = "csv_clipboard",
  TREEMAP = "treemap_chart",
  PDF_DOWNLOAD = "pdf_download",
  PNG_DOWNLOAD = "png_download",
}

export enum Feature {
  TREND_UP = "increasing",
  TREND_DOWN = "decreasing",
  TREND_NONE = "none",
  FORECAST = "forecast", // deprecated
}

export enum ComparativeFeature {
  NONE = "none",
  VALUES = "values",
  PERCENT = "percent",
  BOTH = "both",
}

export enum TimeInterval {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export type ReportFilter = {
  type?: Metadata | "";
  position?: Positions;
  id: string;
  field: string;
  key: string;

  // Filter options
  includeInFilter: boolean;
  allowNull: boolean;
  inverse: boolean;
  regexp: string | null;
  values: string[];
  limit: number;
  limitOrder?: string | null;
  limitMetric?: Metric | null;

  // attributions are composed of other fields
  composite: AttributionFilter[];
};

export type AttributionGroupFilter = ReportFilter & { attributions: ReportFilter[] };

export enum MetricFilterOperator {
  GREATER_THAN = ">",
  LESS_THAN = "<",
  GREATER_THAN_OR_EQUAL = ">=",
  LESS_THAN_OR_EQUAL = "<=",
  BETWEEN = "between",
  NOT_BETWEEN = "not_between",
  EQUALS = "=",
  NOT_EQUALS = "!=",
}

export type MetricFilter = {
  metric: number;
  operator: MetricFilterOperator;
  values: number[];
};

export enum TimeSettingsMode {
  Last = "last",
  Current = "current",
  Fixed = "custom",
}

export type TimeSettingsConfig = {
  amount: number;
  mode: TimeSettingsMode;
  unit: TimeInterval;
  includeCurrent: boolean;
};

export type EmptyReportFallback = {
  text: string;
  url: string;
};

export enum ReportMode {
  GKE = "gke",
  BILLING = "billing",
  ALL = "all",
}

export type ReportConfig = {
  aggregator: Aggregator;
  calculatedMetric: Reference<CloudAnalyticsModelMetricModel> | null;
  colOrder: Sort;
  cols: string[] | null;
  comparative: ComparativeFeature | null;
  count: string | null;
  currency?: CurrencyCode | null;
  customQuery?: string;
  customTimeRange: CustomTimeRange | null;
  emptyReportFallback?: EmptyReportFallback;
  excludePartialData?: boolean;
  includeCredits?: boolean;
  extendedMetric?: string | null;
  features: Feature[];
  filters: ReportFilter[];
  forecastSettings?: ForecastSettings | null;
  limitAggregation?: LimitAggregation;
  logScale?: boolean;
  metric: Metric;
  metricFilters: MetricFilter[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  splits?: any[]; // TODO: update this with Split[] or SplitForServer[]
  optional: ReportOptionalField[];
  renderer: Renderer;
  rowOrder: Sort;
  rows: string[] | null;
  timeInterval: TimeInterval;
  timezone?: string | null;
  timeSettings?: TimeSettingsConfig;
  includeSubtotals?: boolean;
  dataSource: AnalyticsDataSource;
  showValuesInFilterSearchResults?: boolean;
};

export type ReportSchedule = {
  to: string[];
  from: string;
  body: string;
  timezone: string;
  frequency: string;
  subject: string;
};

export enum DashboardType {
  Pulse = "pulse",
  AwsLens = "AWS",
  SaaSAwsLens = "saas-aws-lens",
  BqLens = "superquery",
  GcpLens = "gcp-lens",
  SaaSGcpLens = "saas-gcp-lens",
  GkeLensV2 = "gke-lens-v2",
  AzureLens = "azure-lens",
  EKSLens = "eks-lens",
  SnowflakeLens = "snowflake-lens",
}

export type DashboardTypes = `${DashboardType}`;

@subCollection("dashboards")
export class DashboardModelUserModelDashboardModel {
  allowToEdit?: boolean;

  customerId!: string;

  dashboardType?: DashboardType | null;

  defaultDashboard?: true;

  hasCloudReports?: boolean;

  isPublic?: boolean;

  name?: string;

  ownerId?: string;

  publicDashboardId?: string;

  requiredPermissions?: string[] | null;

  sortNumber?: number;

  heights?: DashboardRowHeight[];

  widgets?: Widget[] | null;

  hidden?: boolean;
}

export type UserPrivateDashboard = Omit<
  DashboardModelUserModelDashboardModel,
  "dashboardType" | "ownerId" | "publicDashboardId"
> & {
  isPublic?: false;
  widgets: Widget[];
  name: string;
  dashboardType: undefined;
};

export type UserPublicAttachedDashboard = Omit<DashboardModelUserModelDashboardModel, "widgets"> & {
  isPublic: true;
  publicDashboardId: string;
};

export class DashboardModelUserModelCustomersModel {
  subCollections?: {
    dashboards: DashboardModelUserModelDashboardModel;
  };
}

@subCollection("users")
export class DashboardModelUserModel {
  subCollections?: {
    duc: DashboardModelUserModelCustomersModel; // DashboardsUsersCustomers
  };
}

@subCollection("public-dashboards")
export class DashboardModelPublicDashboardModel {
  customerId!: string;

  dashboardType!: DashboardType | null;

  isPublic?: boolean;

  name!: string;

  publicDashboardId?: string;

  widgets?: Widget[];
}

export type PublicAccess = `${Roles}` | null;

export type Collaborator = {
  email: string;
  role: Roles;
};

export type Collaborators = Collaborator[];

export type Stat = {
  serverDurationMs?: number;
  totalDurationMs?: number;
  totalBytesProcessed?: number;
};

@subCollection("savedReports")
export class DashboardModelSavedReportsModel {
  collaborators!: Collaborators;

  config!: ReportConfig | null;

  customer!: Reference<CustomerModel> | null;

  description!: string;

  draft?: boolean;

  hidden?: boolean;

  name!: string;

  organization?: Reference<CustomerModelOrganizationModel> | null;

  public!: PublicAccess;

  schedule?: ReportSchedule | null;

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  type!: "custom" | "preset" | "managed";

  widgetEnabled?: boolean;

  cloud?: ProductEnum[] | null;

  labels?: Reference<ObjectLabel>[] | null;

  expireBy?: Timestamp | null;

  stats?: [string: Stat] | null;

  entitlements?: string[] | null;
}

@subCollection("attributions")
export class DashboardModelAttributionModel {
  timeModified!: Timestamp;

  timeCreated!: Timestamp;

  type!: "custom" | "preset" | "managed";

  classification?: "invoice";

  customer?: Reference<CustomerModel> | null;

  description?: string;

  name!: string;

  collaborators!: Collaborator[];

  hidden?: boolean;

  filters!: AttributionFilter[] | null;

  formula?: string;

  relation?: Relation;

  anomalyDetection?: boolean;

  public!: `${Roles}` | null;

  draft?: boolean;

  cloud?: ProductEnum[] | null;

  labels?: Reference<ObjectLabel>[] | null;

  expireBy?: Timestamp | null;

  entitlements?: string[] | null;
}

@subCollection("ids")
export class DashboardModelIdsModel {
  customer!: Reference<CustomerModel>;

  entity?: Reference<EntityModel> | null;

  timestamp!: Timestamp;

  endTime!: Timestamp;

  forecast!: number;

  data!: {
    x: string;
    y: number;
  }[];
}

class DashboardModelInvoices {
  customer!: Reference<CustomerModel>;

  entity!: Reference<EntityModel>;

  invoices!: InvoiceData[];
}

export enum AnalyticsResourceType {
  CUSTOM = "custom",
  PRESET = "preset",
  MANAGED = "managed",
  PRESET_DISPLAY_NAME = "Ramp plan preset",
  CUSTOM_DISPLAY_NAME = "Custom attributions groups",
}

@subCollection("invoicesLatest")
export class DashboardModelInvoicesLatest extends DashboardModelInvoices {}

@subCollection("invoicesOverdue")
export class DashboardModelInvoicesOverdue extends DashboardModelInvoices {}

@subCollection("widgetsEmptyState")
export class WidgetsEmptyState {
  emptyReportFallback!: EmptyReportFallback;
}

@subCollection("rampPlan")
export class RampPlanWidget {
  rampPlanId?: string;

  customer?: Reference<CustomerModel>;
}

@subCollection("ticketStatistics")
export class DashboardModelTicketStatistics {
  history!: Record<number, Record<number, number>>;

  satisfaction!: number;
}

export type SkuName = "g-suite-basic" | "google-vault";

export type CompanySku = Record<
  SkuName,
  {
    domain: string;
    platform: AssetType;
    quantity: Record<string, number>;
    skuName: string;
  }
>;

export type DashboardModelCompaniesModelDomain = {
  domain: string;
  platform: AssetType;
  quantity: Record<string, number>; // key e.g 2022-09-28
  skuName: string;
};

export type DashboardModelCompaniesModelInfo = {
  customer: Reference<CustomerModel>;
};

@subCollection("companies")
export class DashboardModelCompaniesModel {
  info!: {
    customer: Reference<CustomerModel>;
  };

  readonly [key: string]: Record<string, DashboardModelCompaniesModelDomain> | DashboardModelCompaniesModelInfo;
}

export class CloudHealthModel {
  customer!: Reference<CustomerModel>;
}

@subCollection("commitmentContracts")
export class DashboardModelCommitmentContracts {
  customer!: Reference<CustomerModel>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

export class CustomizationDoc {
  subCollections?: {
    users: DashboardModelUserModel;
    "public-dashboards": DashboardModelPublicDashboardModel;
  };
}

class LicenseChartDoc {
  subCollections?: {
    companies: DashboardModelCompaniesModel;
  };
}

class GoogleCloudReportsDoc {
  subCollections?: {
    attributions: DashboardModelAttributionModel;
    savedReports: DashboardModelSavedReportsModel;
  };
}

export type WidgetConfig = {
  key: string;
  cardSubheader: string;
  cardTitle: string;
  emptyState?: {
    buttonLink: string;
    buttonText: string;
    description: string;
    message: string;
  };
};

export type WidgetsConfig = {
  widgets: WidgetConfig[];
};

export class WidgetDoc {
  widgets!: WidgetConfig[];

  subCollections?: {
    widgetsEmptyState: WidgetsEmptyState;
    rampPlan: RampPlanWidget;
  };
}

type AssetsRenewalsIdsModel = {
  customer: Reference<CustomerModel>;
  endTime: Timestamp;
};

class AssetsRenewalsDoc {
  subCollections?: {
    ids: AssetsRenewalsIdsModel;
  };
}

class InvoicesLatestDoc {
  subCollections?: {
    invoicesLatest: DashboardModelInvoicesLatest;
  };
}

class InvoicesOverdueDoc {
  subCollections?: {
    invoicesOverdue: DashboardModelInvoicesOverdue;
  };
}

class AzureGraphDoc {
  subCollections?: {
    ids: DashboardModelIdsModel;
  };
}

class DashboardModelTicketStatisticsDoc {
  subCollections?: {
    ticketStatistics: DashboardModelTicketStatistics;
  };
}

type CommitmentPeriod = {
  current: boolean;
  endDate: Timestamp;
  ended: boolean;
  estimated: number;
  rollover: number;
  startDate: Timestamp;
  total: number;
  value: number;
};

export type CommitmentContract = {
  commitmentPeriods: CommitmentPeriod[] | null;
  commitmentRollover: boolean;
  customer: Reference<CustomerModel>;
  entity: null | Reference<EntityModel>;
  timestamp: Timestamp;
  type: "amazon-web-services" | "google-cloud";
};

@doc("commitment-contracts")
class CommitmentContractsDoc {
  subCollections?: {
    commitmentContracts: CommitmentContract;
  };
}

export enum QuickLinkWhenCollectionIsEmpty {
  SHOW = "show",
  HIDE = "hide",
}

type AssetQuickLinkCondition = {
  collectionPath: "assets";
  collectionCustomerRefFieldName: "customer";
  collectionFilters: string;
};

type CloudConnectQuickLinkCondition = {
  collectionPath: "/customers/:customerId:/cloudConnect";
  collectionCustomerRefFieldName: "customer";
  collectionFilters: string;
};

type RampPlansQuickLinkCondition = {
  collectionPath: "rampPlans";
  collectionCustomerRefFieldName: "customerRef";
};

type EksQuickLinkCondition = {
  collectionPath: "/integrations/k8s-metrics/eks";
  collectionCustomerRefFieldName: "customerId";
};

export type QuickLinkCondition = {
  whenCollectionIsEmpty: QuickLinkWhenCollectionIsEmpty;
} & (AssetQuickLinkCondition | CloudConnectQuickLinkCondition | RampPlansQuickLinkCondition | EksQuickLinkCondition);

@collection("quickLinks")
export class QuickLinkDescriptorModel {
  name!: string;

  title!: string;

  description!: string;

  icon!: string;

  target!: string;

  sortOrder!: number;

  permissions!: Reference<PermissionModel>[];

  userMaturityFrom?: number;

  userMaturityTo?: number;

  conditions?: QuickLinkCondition[];
}

export enum DiscoveryTileColor {
  RED = "red",
  BLUE = "blue",
  NAVY = "navy",
  PURPLE = "purple",
  TEAL = "teal",
}

@subCollection("dashboardsAccessMetadata")
export class DashboardsAccessMetadataModel {
  customerId!: string;

  organizationId!: string;

  dashboardId!: string;

  timeLastAccessed!: Timestamp;

  timeLastRefreshed?: null | Timestamp;
}

class DashboardAccessMetadataDoc {
  subCollections?: {
    dashboardsAccessMetadata: DashboardsAccessMetadataModel;
  };
}

@collection("discoveryTiles")
export class DiscoveryTileDescriptorModel {
  name!: string;

  title!: string;

  description!: string;

  color!: DiscoveryTileColor;

  linkLabel!: string;

  target!: string;

  sortOrder!: number;

  tileAge?: number;

  permissions?: Reference<PermissionModel>[];
}

@collection("discoveryTileCompletion")
export class DiscoveryTileCompletion {
  completedAt!: Timestamp;
}

@collection("discoveryTileCompletionByUser")
export class DiscoveryTileCompletionByUserModel {
  subCollections?: {
    discoveryTileCompletion: DiscoveryTileCompletion;
  };
}

@doc("home")
export class HomeDoc {
  subCollections?: {
    quickLinks: QuickLinkDescriptorModel;
    discoveryTiles: DiscoveryTileDescriptorModel;
    discoveryTileCompletionByUser: DiscoveryTileCompletionByUserModel;
  };
}

@collection("dashboards")
export class DashboardModel {
  docs?: {
    customization: CustomizationDoc;
    licenseChart: LicenseChartDoc;
    "google-cloud-reports": GoogleCloudReportsDoc;
    widgets: WidgetDoc;
    assetsRenewals: AssetsRenewalsDoc;
    "invoices-latest": InvoicesLatestDoc;
    "invoices-overdue": InvoicesOverdueDoc;
    azureGraph: AzureGraphDoc;
    "ticket-statistics": DashboardModelTicketStatisticsDoc;
    "commitment-contracts": CommitmentContractsDoc;
    home: HomeDoc;
    metadata: DashboardAccessMetadataDoc;
    users: DashboardModelUserModel;
  };
}
