export const onboardingTxt = {
  HELM_CHART_REPOSITORY: "Helm chart repository",
  KUBECTL_TAB: "Kubectl",
  HELM_TAB: "Helm",
  OPEN_AWS_CONSOLE: "Open AWS Console",
  HELM_CONNECT_STEP_1: "1. Run the following commands to deploy our Kubernetes components with Helm.",
  COMMON_DEPLOY_YAML: (deploymentFileName: string) =>
    `Used to deploy or update a Kubernetes application defined in the "${deploymentFileName}" file`,
  VALIDATE_CONNECTION: "Validate connection",
  CHECK: "Check",
  CHECKING_MSG: "Checking connection. This can take up to 1 minute.",
  CONNECTION_SUCCESSFUL: "Connection successful. It can take up to 24 hours to start seeing data from this cluster",
  CONNECTION_UNSUCCESSFUL: "Connection unsuccessful. Ensure all commands listed above have been run correctly.",
  SELECT_DEPLOYMENT_METHOD: "Select deployment method",
  CONFIGURE_AND_APPLY: "Configure Terraform and apply",
  TERRAFORM: "Terraform",
  CLOUD_FORMATION: "CloudFormation",
  DEPLOYMENT_METHOD_DISABLE: (deploymentMethod: string) =>
    `You already have a ${deploymentMethod} deployment in the same account or region. You can not mix deployment methods`,
  DEPLOYMENT_TERRAFORM: "Create the AWS bucket and roles, and deploy kubernetes services",
  DEPLOYMENT_CLOUD_FORMATION: "Create the AWS bucket and roles, and deploy kubernetes services with kubectl or Helm",

  DEPLOYMENT_TERRAFORM_REGION: (account_id: string, region: string) =>
    `Create base resources required for running EKS Lens in ${account_id} / ${region}.`,
  DEPLOYMENT_TERRAFORM_REGION_BUTTON: "Download region file",
  CONFIGURE_TERRAFORM_2: (cluster_name: string) => `Create required resources for the "${cluster_name}" cluster.`,
  CONFIGURE_TERRAFORM_2_OIDC: `On the AWS Console, go to the cluster details, copy the OpenId Connect provider url and paste it below to download your cluster file:`,
  CONFIGURE_TERRAFORM_2_LABEL: "OpenId Connect provider url",
  CONFIGURE_TERRAFORM_2_BUTTON: "Download cluster file",
  CONFIGURE_TERRAFORM_4: `Edit the downloaded cluster file and configure inside the connection of the kubernetes cluster.`,
  LEARN_MORE: "Learn more",
  CONFIGURE_TERRAFORM_4_CODE: (
    account_id: string,
    region: string,
    clusterName: string
  ) => `## Configure Your Cluster Provider
# With .kube/config
provider "kubernetes" {
  alias          = "doit-eks-lens-${account_id}-${region}-${clusterName}"
  config_path    = "<<Your kubernetes config>>"
  config_context = "<<Your Cluster Context>>"
}

# OR with Certificates
provider "kubernetes" {
  alias                  = "doit-eks-lens-${account_id}-${region}-${clusterName}"
  host                   = "https://<<Your Cluster Host>>"
  client_certificate     = file("<<cert.pem>>")
  client_key             = file("<<key.pem>>")
  cluster_ca_certificate = file("<<ca-cert.pem>>")
}`,
  CONFIGURE_TERRAFORM_5: "Set up your AWS provider with ENV variables.",
  CONFIGURE_TERRAFORM_5_CODE: `export AWS_ACCESS_KEY_ID="<<aws-access_key>>"
export AWS_SECRET_ACCESS_KEY="<<aws-secret_key>>"
export AWS_REGION="<<aws-region>>"
`,
  CONFIGURE_TERRAFORM_6: "Complete the setup by running each of the following commands individually:",
  CONFIGURE_TERRAFORM_6_COMPLETE: "We will be notified by Terraform once installation is finished",
  CONFIGURE_TERRAFORM_6_CODE_1: "terraform init",
  CONFIGURE_TERRAFORM_6_CODE_2: "terraform plan",
  CONFIGURE_TERRAFORM_6_CODE_3: "terraform apply",
  INVALID_OPENID_URL: "Invalid URL. Please try again.",
};
