import { useCallback } from "react";

import { useApiContext } from "../../../api/context";

const getUserLocationUrl = "/support/user-location";

export type UserLocationResponse = {
  city: string;
  country: string;
  region: string;
};

/**
 * get the user location using the location service
 *
 * @param email: the user email
 */
export default function useGetUserLocation() {
  const api = useApiContext();

  return useCallback(
    async (email: string) => {
      const response = await api.request<UserLocationResponse>({
        method: "post",
        url: getUserLocationUrl,
        data: {
          email,
        },
      });
      return response.data;
    },
    [api]
  );
}
