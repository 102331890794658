import { type CurrencySymbol, type MonthSavings } from "@doitintl/cmp-models";
import { type Theme } from "@mui/system";
import union from "lodash/union";
import type * as Highcharts from "highcharts";

import { type ColumnsChartSeries, type ColumnValues } from "../../../../../Components/Charts/ColumnsChat/ColumnsChart";
import {
  generateDefaultOptions,
  groupDataToSeries,
} from "../../../../../Components/Charts/ColumnsChat/ColumnsChartUtils";
import { type ThemeMode } from "../../../../../muiThemeTypes";
import { type OptionsSeriesColumn } from "../../../../../utils/Highcharts";
import {
  checkIfMonthIsAfterFlexsaveEnablement,
  getLatestDays,
  getLatestMonths,
  getMonthLabelFromMonthId,
  getWeekdayAndDate,
} from "../../../utils/dateUtils";
import { displayConfigMapping } from "./common";

const maxDataPointsForGraph = 7;

function createCostColumns(savings: Array<[string, MonthSavings]>, timeEnabled: Date | undefined): ColumnValues[] {
  const highlightData: ColumnValues[] = [];

  savings.forEach(([key, monthData]) => {
    const monthIsAfterEnablement = timeEnabled ? checkIfMonthIsAfterFlexsaveEnablement(key, timeEnabled) : true;
    const costAfterFlexsave = monthData.savings > 0 || monthIsAfterEnablement ? monthData.onDemandSpend : 0;
    const currentMonth: ColumnValues = [monthData.onDemandSpend + monthData.savings, costAfterFlexsave];
    highlightData.push(currentMonth);
  });

  return highlightData;
}

export function generateSingleChartSeries(
  savings: Array<[string, MonthSavings]>,
  mode: ThemeMode,
  timeEnabled: Date | undefined
): ColumnsChartSeries {
  const highlightedColors: [string, string] = [
    displayConfigMapping.colors[mode].totalOnDemandSpend,
    displayConfigMapping.colors[mode].onDemandSpend,
  ];

  const costColumns = createCostColumns(savings, timeEnabled);
  return {
    colors: highlightedColors,
    data: costColumns,
    legends: [displayConfigMapping.legends.totalOnDemandSpend, displayConfigMapping.legends.onDemandSpend],
    startIndex: 0,
    singleColumn: false,
  };
}

const generateOptionsForChart = (
  savings: Array<[string, MonthSavings]>,
  theme: Theme,
  currencySymbol: CurrencySymbol,
  timeEnabled: Date | undefined
): OptionsSeriesColumn => {
  const fontWeight = "400";
  const chartData = generateSingleChartSeries(savings, theme.palette.mode, timeEnabled);
  const groupedSeries = groupDataToSeries([[chartData]]);

  const categories = savings.map(([key]) => key);

  return generateDefaultOptions(
    fontWeight,
    theme.palette.text.primary,
    categories,
    groupedSeries,
    currencySymbol,
    theme.palette.mode
  );
};

export const generateDailyOptions = (
  savings: Record<string, MonthSavings>,
  theme: Theme,
  currencySymbol: CurrencySymbol
) => {
  const dailyHistory = convertDailyKeys(savings);

  return generateOptionsForChart(dailyHistory, theme, currencySymbol, undefined);
};

export const convertMonthlyKeys = (
  monthSavings: Record<string, MonthSavings>,
  currentMonth: string
): Array<[string, MonthSavings]> => {
  const savings: Array<[string, MonthSavings]> = [];
  const latestMonths = getLatestMonths(currentMonth, maxDataPointsForGraph);

  for (const key of latestMonths) {
    if (!monthSavings[key]) {
      continue;
    }

    savings.push([getMonthLabelFromMonthId(key), monthSavings[key]]);
  }

  return savings;
};

export const generateMonthlyOptions = (
  savingsHistory: Record<string, MonthSavings> | null,
  currentMonth: string,
  timeEnabled: Date | null,
  theme: Theme,
  currencySymbol: CurrencySymbol
) => {
  if (timeEnabled) {
    const monthlyHistory = savingsHistory && currentMonth ? convertMonthlyKeys(savingsHistory, currentMonth) : [];

    return generateOptionsForChart(monthlyHistory, theme, currencySymbol, timeEnabled);
  }

  return null;
};

export function convertDailyKeys(dailySavings: Record<string, MonthSavings>): Array<[string, MonthSavings]> {
  const savings: Array<[string, MonthSavings]> = [];
  const latestDays = getLatestDays(maxDataPointsForGraph);

  for (const key of latestDays) {
    if (!dailySavings[key]) {
      continue;
    }
    savings.push([getWeekdayAndDate(key), dailySavings[key]]);
  }

  return savings;
}

export function getUniqueValues(...arrays: string[][]): string[] {
  return union(...arrays);
}

export function sortMonths(months: string[]): string[] {
  return months.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
}

export function hasData(series: Highcharts.SeriesOptionsType): series is Highcharts.SeriesLineOptions {
  return (series as Highcharts.SeriesLineOptions).data !== undefined;
}

export const findSeriesByName = (
  options: Highcharts.Options | undefined,
  name: string
): Highcharts.SeriesOptionsType | undefined => options?.series?.find((series) => series.name === name);

export const findCategoryIndex = (options: Highcharts.Options | undefined, month: string): number | undefined => {
  if (!Array.isArray(options?.xAxis)) {
    return options?.xAxis?.categories?.indexOf(month);
  }

  for (const axis of options?.xAxis || []) {
    const index = axis.categories?.indexOf(month);
    if (index !== undefined) {
      return index;
    }
  }

  return undefined;
};

export const getSeriesData = (name: string, allMonths: string[], ...options: (Highcharts.Options | null)[]): number[] =>
  allMonths.map((month) => {
    let total = 0;

    options.forEach((option) => {
      if (!option) {
        return;
      }
      const series = findSeriesByName(option, name);
      const index = findCategoryIndex(option, month);

      if (index !== undefined && series && hasData(series) && series.data && series.data.length > index) {
        let value = series.data[index];

        if (value && typeof value === "object" && "y" in value) {
          value = value.y || 0;
        }

        total += Number(value) || 0;
      }
    });

    return total;
  });
