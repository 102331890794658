import { type Theme } from "@mui/material";

const getErrorBarSeries = (theme: Theme) => ({
  type: "custom",
  tooltip: {
    show: false,
  },
  legend: {
    show: false,
  },
  renderItem: (_params, api) => {
    const xValue = api.value(0);
    if (api.value(1) === null) {
      return;
    }
    const highPoint: number[] = api.coord([xValue, api.value(1)]);
    const lowPoint: number[] = api.coord([xValue, api.value(2)]);
    const halfWidth = api.size([1, 0])[0] * 0.1;
    const style = api.style({
      stroke: theme.palette.mode === "light" ? "rgba(0, 0, 0)" : "rgba(255, 255, 255)",
    });
    return {
      type: "group",
      children: [
        {
          type: "line",
          transition: ["shape"],
          shape: {
            x1: highPoint[0] - halfWidth,
            y1: highPoint[1],
            x2: highPoint[0] + halfWidth,
            y2: highPoint[1],
          },
          style,
        },
        {
          type: "line",
          transition: ["shape"],
          shape: {
            x1: highPoint[0],
            y1: highPoint[1],
            x2: lowPoint[0],
            y2: lowPoint[1],
          },
          style,
        },
        {
          type: "line",
          transition: ["shape"],
          shape: {
            x1: lowPoint[0] - halfWidth,
            y1: lowPoint[1],
            x2: lowPoint[0] + halfWidth,
            y2: lowPoint[1],
          },
          style,
        },
      ],
    };
  },
  encode: {
    x: 0,
    y: [1, 2],
  },
  z: 100,
  emphasis: {
    disabled: true,
  },
});

export default getErrorBarSeries;
