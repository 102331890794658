import {
  AssetSettingModel,
  AssetTypeGoogleCloud,
  CurrencyCodes,
  CustomerModel,
  type GcpMarketplaceAdjustmentModel,
  MarketplaceModel,
} from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";

import { globalText } from "../../../../assets/texts";
import { type FirestoreTimestamp, serverTimestamp, TimestampFromDate } from "../../../../utils/firebase";
import { type CustomerWithRef } from "../hooks/useMarketplaceCustomers";

export type AdjustmentMenuOptionProps = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
};

export const adjustmentOptions: AdjustmentMenuOptionProps[] = [
  {
    label: globalText.EDIT,
    value: globalText.EDIT,
  },
  {
    label: globalText.DELETE,
    value: globalText.DELETE,
    color: "error",
  },
];

const getInvoiceMonthAndTargetTime = (adjustment: GcpMarketplaceAdjustmentModel, month: number) => {
  const targetTimeAsDate = adjustment.targetTime.toDate();
  targetTimeAsDate.setMonth(targetTimeAsDate.getMonth() + month);
  const invoiceMonth = new Date(targetTimeAsDate);
  invoiceMonth.setDate(1);
  invoiceMonth.setHours(0, 0, 0, 0);
  return { invoiceMonth, targetTime: TimestampFromDate(targetTimeAsDate) };
};

const createInvoiceAdjustment = async (
  adjustment: GcpMarketplaceAdjustmentModel,
  customer: CustomerWithRef,
  currentUserID: string,
  batch: any,
  invoiceMonths: FirestoreTimestamp[],
  billingAccountID: string
) => {
  const customerInvoiceAdjustmentsCollection = getCollection(CustomerModel)
    .doc(customer.ref.id)
    .collection("customerInvoiceAdjustments");

  const docSnap = await getCollection(AssetSettingModel).doc(`${AssetTypeGoogleCloud}-${billingAccountID}`).get();
  const assetSettings = docSnap.asModelData();
  if (!assetSettings) {
    throw new Error("Asset setting not found");
  }
  if (!assetSettings.entity) {
    throw new Error("Entity not found");
  }
  batch.set(customerInvoiceAdjustmentsCollection.newDoc(), {
    description: "Marketplace adjustment",
    details: adjustment.description,
    amount: (adjustment.value * (adjustment.transactionFee / 100)) / 100,
    currency: CurrencyCodes.USD,
    invoiceMonths,
    type: AssetTypeGoogleCloud,
    customer: adjustment.customer,
    entity: assetSettings.entity,
    timestamp: serverTimestamp(),
    updatedBy: currentUserID,
    finalized: true,
    metadata: {
      customer: {
        primaryDomain: customer.data.primaryDomain,
        name: customer.data.name,
      },
    },
  });
};

export const createAdjustments = async (
  adjustment: GcpMarketplaceAdjustmentModel,
  periods: number,
  customer: CustomerWithRef,
  currentUserID: string,
  shouldCreateInvoiceAdjustment: boolean,
  billingAccountID: string
) => {
  const batch = getBatch();
  const adjustmentCollection = getCollection(MarketplaceModel)
    .doc("gcp-marketplace")
    .collection("gcpMarketplaceAdjustments");

  const invoiceMonths: FirestoreTimestamp[] = [];
  for (let i = 0; i < periods; i++) {
    const { invoiceMonth, targetTime } = getInvoiceMonthAndTargetTime(adjustment, i);
    invoiceMonths.push(TimestampFromDate(invoiceMonth));
    batch.set(adjustmentCollection.newDoc(), { ...adjustment, targetTime });
  }

  if (shouldCreateInvoiceAdjustment) {
    await createInvoiceAdjustment(adjustment, customer, currentUserID, batch, invoiceMonths, billingAccountID);
  }

  await batch.commit();
};
