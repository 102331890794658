import { EntitlementModel, TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { type PageId } from "../../Navigation/config/pages";
import EntitlementsTable from "./Entitlements/EntitlementsTable";
import EditTierEntitlements from "./Tiers/EditTier/EditTierEntitlements";
import TiersTable from "./Tiers/TiersTable";

type Props = {
  pageId: PageId;
};

const TierManagement = ({ pageId }: Props) => {
  const [tiers, loadingTiers] = useCollectionData(getCollection(TierModel), {
    idField: "id",
  });

  const [entitlements, loadingEntitlements] = useCollectionData(getCollection(EntitlementModel), {
    idField: "id",
  });

  if (pageId === "tier-management") {
    return <TiersTable tiers={tiers} loading={loadingTiers} />;
  }
  if (pageId === "entitlements-management") {
    return <EntitlementsTable entitlements={entitlements} loading={loadingEntitlements} />;
  }

  if (pageId === "tier-management:edit-tier") {
    return <EditTierEntitlements />;
  }
  return null;
};

export default TierManagement;
