import { useMemo, useState } from "react";

import { Card, Typography } from "@mui/material";
import { amber, blue, red } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";

import { type DebtGraphData } from "../Pages/Collection/DebtGraphData";
import { formatNumber } from "../utils/common";
import { useFullScreen } from "../utils/dialog";

const colors = [
  {
    active: blue[600],
    inactive: blue[100],
  },
  {
    active: red[600],
    inactive: red[100],
  },
  {
    active: amber[600],
    inactive: amber[100],
  },
];
const titles = ["Not yet due", "Overdue < 90 days", "Overdue 90+ days"] as const;

const useStyles = makeStyles(() => ({
  card: {
    height: 260,
  },
}));

type Props = {
  data: DebtGraphData | undefined;
};

const DebtGraph = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [seriesVisibility, setSeriesVisibility] = useState([true, true, true]);
  const { isMobile } = useFullScreen();

  const handleLegendClick = (index: number) => {
    setSeriesVisibility((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const tickTotal = Math.floor((props.data?.[0]?.length ?? 0) / (isMobile ? 7 : 35)) + 1;

  const chartOptions = useMemo(() => {
    const categories =
      props.data?.[0]?.map((item) => DateTime.fromFormat(item.x, "yyyy-LL-dd").toFormat("dd LLL")) || [];

    return {
      chart: {
        type: "column",
        backgroundColor: theme.palette.background.paper,
        height: 260,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories,
        tickInterval: Math.max(1, Math.floor(categories.length / tickTotal)),
        type: "datetime", // x-axis will be datetime based
        labels: {
          enabled: true, // Enable x-axis labels (default)
          format: "{value:%Y-%m-%d}", // Format date label as YYYY-MM-DD
          style: {
            color: theme.palette.text.primary,
          },
          rotation: -45, // Optional: Rotate labels for better readability
        },
      },
      tooltip: {
        formatter: function formatter(this: Highcharts.TooltipFormatterContextObject, _tooltip: Highcharts.Tooltip) {
          return (
            `<b>${this.series?.name}</b><br/>` +
            `${DateTime.fromFormat(this.x as string, "dd LLL").toFormat("dd LLL yyyy")}<br/>${formatNumber(this.y)}`
          );
        },
      },
      yAxis: {
        style: {
          color: theme.palette.text.primary,
        },
        title: {
          text: null,
        },
        labels: {
          formatter: function formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
            return `$${formatNumber(this.value)}`;
          },
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1, // Space between columns
          groupPadding: 0.0, // Space between groups of columns
          borderWidth: 0, // Remove column borders
          stacking: "normal",
        },
      },
      series:
        props.data?.map((platform, i) => ({
          name: titles[i],
          data: seriesVisibility[i] ? platform.map((item) => item.y) : [],
          color: seriesVisibility[i] ? colors[i].active : colors[i].inactive,
        })) || [],
    };
  }, [props.data, seriesVisibility, theme, tickTotal]);

  return (
    <Card className={classes.card}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <div>
        {titles.map((title, i) => (
          <div
            key={i}
            onClick={() => {
              handleLegendClick(i);
            }}
            style={{ cursor: "pointer", display: "inline-block", margin: "0 10px" }}
          >
            <Typography
              variant="caption"
              style={{ color: seriesVisibility[i] ? colors[i].active : colors[i].inactive }}
            >
              {title}
            </Typography>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default DebtGraph;
