import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopyToClipboard from "./CopyToClipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 30,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  bg: {
    backgroundColor: theme.palette.background.default,
    marginBottom: 16,
    padding: 6,
  },
}));

export default function GcloudExpansionPanel({ serviceAccount, showSnackbar }) {
  const classes = useStyles();
  const gcommand = `for org in $(gcloud organizations list --format="value(name)"); do gcloud organizations add-iam-policy-binding $org --member='serviceAccount:${serviceAccount}' --role='roles/billing.admin'; done`;

  return (
    <div className={classes.root}>
      <Accordion elevation={1}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography className={classes.heading}>
            Don’t have access to your Google Cloud Organization IAM? Email these instructions to your colleague!
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography variant="body1">To add the service account to your organization:</Typography>
            <Typography variant="body2" style={{ overflowWrap: "break-word" }} className={classes.bg}>
              {gcommand}
            </Typography>
            <CopyToClipboard type="button" spacer={true} showSnackbar={showSnackbar} textToCopy={gcommand} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
