import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { NotificationAudienceType, NotificationProviderType, UserPermissions } from "@doitintl/cmp-models";
import { Stack, type SxProps, type Theme, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { type Step, Stepper, type StepState } from "../../../../Components/Stepper";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useUserContext } from "../../../../Context/UserContext";
import { useCreateNotificationChannel } from "../hooks/useCreateNotificationChannel";
import { NotificationsFormStep } from "./NotificationsFormStep";
import { NotificationsSelectChannels } from "./NotificationsSelectChannels";
import { Provider } from "./provider";

export const NotificationWizard = () => {
  const theme = useTheme();
  const history = useHistory();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { stepId, customerId } = useParams<{ stepId: string; customerId: string }>();
  const isEditMode = !!history.location.pathname.match(/edit/);
  const { channelConfig, updateChannelConfig, createNotification, editNotification } = useCreateNotificationChannel();
  const isWindowSizeMD = useMediaQuery<boolean>(theme.breakpoints.down("md"));
  const [stepperState, setStepperState] = useState<StepState[]>(["incomplete", "incomplete", "incomplete"]);
  const [overrideStep, setOverrideStep] = useState<number>(-1);
  const [currentStep, getCurrentStep] = useState(0);
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const hasManageSettingsPermission = userRoles.permissions?.has(UserPermissions.Settings);
  const audience = useParams<{ audience: NotificationAudienceType }>()?.audience;

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    // If the user is creating a personal notification, pre-fill the form and skip to the last step.
    if (audience === NotificationAudienceType.USER && !isEditMode) {
      updateChannelConfig("name", "My email notifications");
      updateChannelConfig("providerType", NotificationProviderType.EMAIL);
      updateChannelConfig("providerTarget", { [NotificationProviderType.EMAIL]: [currentUser.email] });
      setOverrideStep(2);
    }
  }, [currentUser, audience, updateChannelConfig, isEditMode]);

  useEffect(() => {
    const stepNumber = parseInt(stepId);
    const isValidStepId = !isNaN(stepNumber);
    if (!isValidStepId) {
      return;
    }

    if (channelConfig.providerType !== "") {
      setOverrideStep(stepNumber);
    }
  }, [channelConfig.providerType, stepId]);

  useEffect(() => {
    const updateStepState = (stepIndex: number, condition: boolean) => {
      setStepperState((prev) => {
        const newState = [...prev];
        newState[stepIndex] = condition ? "complete" : "incomplete";
        return newState;
      });
    };

    // Step 1
    updateStepState(0, !!channelConfig?.name && !!channelConfig?.providerType);

    // Step 2
    updateStepState(1, !!channelConfig?.providerTarget?.[channelConfig.providerType]?.length);

    // Step 3
    updateStepState(2, Object.keys(channelConfig?.selectedNotifications || []).length > 0);
  }, [channelConfig, currentStep]);

  const stepComponents = [
    <Provider
      channelConfig={channelConfig}
      updateChannelConfig={updateChannelConfig}
      viewOnly={audience === NotificationAudienceType.USER || !hasManageSettingsPermission}
      key="provider"
    />,
    <NotificationsSelectChannels
      channelConfig={channelConfig}
      updateChannelConfig={updateChannelConfig}
      viewOnly={audience === NotificationAudienceType.USER || !hasManageSettingsPermission}
      key="step2"
    />,
    <NotificationsFormStep
      channelConfig={channelConfig}
      updateChannelConfig={updateChannelConfig}
      forAudience={audience}
      key="step3"
      handleValidationStatus={setIsFormValid}
    />,
  ];

  const steps: Step[] = [
    {
      children: stepComponents[0],
      label: "Select provider",
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: stepComponents[1],
      label: "Select channels",
      order: 1,
      required: true,
      state: stepperState[1],
    },
    {
      children: stepComponents[2],
      label: "Select notifications",
      order: 2,
      required: true,
      state: stepperState[2],
      hideBackButton: audience === NotificationAudienceType.USER,
    },
  ];

  const stepperPadding = useMemo(
    () => (isWindowSizeMD ? `${window.innerWidth / steps.length / 2 - 50}px` : 8),
    [isWindowSizeMD, steps.length]
  );

  const onClose = useCallback(() => {
    history.push(`/customers/${customerId}/notifications`);
  }, [customerId, history]);

  const contentSx: SxProps<Theme> = {
    px: stepperPadding,
    mt: 6,
    mb: 3,
    display: "flex",
    maxWidth: 1080,
    margin: "auto",
  };

  const createNotificationChannel = useCallback(
    () => (isEditMode ? editNotification(audience) : createNotification(audience)),
    [createNotification, editNotification, isEditMode, audience]
  );

  if (isEditMode && channelConfig.providerType === "") {
    return null;
  }

  return (
    <Stack mt={4} mb={16} justifyContent="center">
      <Stepper
        loading={false}
        onCancel={onClose}
        onSubmit={createNotificationChannel}
        steps={steps}
        footerMaxWidth={1080}
        relativeFooter={false}
        disableSubmit={(audience === NotificationAudienceType.COMPANY && !hasManageSettingsPermission) || !isFormValid}
        maxWidth={1280}
        contentSx={contentSx}
        overrideStep={overrideStep}
        getCurrentStep={getCurrentStep}
      />
    </Stack>
  );
};
