import { type ReactNode } from "react";

import { Box, Link } from "@mui/material";
import { type FormikErrors } from "formik";

import { type CreateBillingProfileData } from "../../BillingProfileForm.models";
import { CustomInput } from "../common/CustomInput";
import { PanelWrapper } from "../common/PanelWrapper";

type Props = {
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: CreateBillingProfileData;
  errors: FormikErrors<CreateBillingProfileData>;
  children?: ReactNode;
};

export const GeneralPanel = ({ values, errors, handleChange, children }: Props) => (
  <PanelWrapper title="Company details" subtitle="Company name and registration details" paddingTop={9}>
    {children}

    <CustomInput
      data-cy="companyName"
      name="companyName"
      label="Company name"
      helperText="Legal company name that will appear on invoices"
      value={values.companyName}
      onChange={handleChange}
      fullWidth
      error={Boolean(errors.companyName)}
      maxLength={48}
      required
    />

    <CustomInput
      data-cy="taxId"
      name="taxId"
      label={values.country !== "Israel" ? "Company Tax ID" : "Company number"}
      helperText={
        values.country !== "Israel" ? (
          `Company Tax ID that will appear on invoices`
        ) : (
          <Box>
            Company number registered under{" "}
            <Link
              href="https://ica.justice.gov.il/GenericCorporarionInfo/SearchCorporation?unit=8"
              target="_blank"
              color="inherit"
            >
              ICA
            </Link>
          </Box>
        )
      }
      value={values.taxId}
      onChange={handleChange}
      fullWidth
      error={Boolean(errors.taxId)}
      maxLength={48}
      required={values.country !== "United States"}
    />
  </PanelWrapper>
);
