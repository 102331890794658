import { useMemo } from "react";

import { type BillingStandaloneOnboardingGCPModel, SaaSConsoleType } from "@doitintl/cmp-models";
import { useDocumentData } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useCloudConnectContext } from "../../../Context/customer/CloudCOnnectContext";
import { SaaSConsoleApi } from "./api";
import { onboardingDocRef } from "./utils";

function useSaaSConsoleOnboarding(type: SaaSConsoleType) {
  const customerId = useCustomerId();
  return useMemo(() => onboardingDocRef(customerId, type), [customerId, type]);
}

export function useSaaSGCPConsoleOnboarding() {
  const docRef = useSaaSConsoleOnboarding(SaaSConsoleType.GCP).narrow<BillingStandaloneOnboardingGCPModel>();
  return useDocumentData(docRef);
}

export function useSaaSConsoleAPI(type: SaaSConsoleType) {
  const customerId = useCustomerId();
  const api = useApiContext();
  return useMemo(() => new SaaSConsoleApi(api, customerId, type), [api, customerId, type]);
}

export function useIsDataImportInProgress(cloudType: SaaSConsoleType) {
  const cloudConnect = useCloudConnectContext();
  if (!cloudConnect) {
    return false;
  }
  const assetType = cloudType === SaaSConsoleType.AWS ? "amazon-web-services-standalone" : "google-cloud-standalone";
  const connectAssets = cloudConnect.filter((c) => c.type === assetType);
  return connectAssets.length > 0 && connectAssets.every((asset) => asset.notified !== undefined && !asset.notified);
}
