import { useCallback, useMemo } from "react";

import { type CloudFlowProvider, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { getCloudIcon } from "../../../../utils/common";

export function stripControlledFields(
  inputModel: StructureApiServiceModelDescriptor<Member>,
  controlledInputs: string[]
) {
  const inputCopy = cloneDeep(inputModel);

  controlledInputs.forEach((field) => {
    if (field in inputCopy.members) {
      delete inputCopy.members[field];
    }
  });

  return inputCopy;
}

export function useApiProviderLogo() {
  const isDarkTheme = useDarkThemeCheck();
  const iconSet = useMemo(
    () => ({
      AWS: getCloudIcon("amazon-web-services", isDarkTheme),
      GCP: getCloudIcon("google-cloud", isDarkTheme),
      DoiT: "https://storage.googleapis.com/hello-static-assets/emoji/doit-mark.png",
    }),
    [isDarkTheme]
  );

  return useCallback((provider: CloudFlowProvider) => iconSet[provider], [iconSet]);
}
