import { useMemo, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import isEqual from "lodash/isEqual";

import { globalText } from "../../../../assets/texts";
import { usePackageSelection } from "../../../../Components/CreateCustomer/hooks";
import PackageSelector from "../../../../Components/CreateCustomer/PackageSelector";
import { isPackageOptionSelected } from "../../../../Components/CreateCustomer/utils";
import LoadingButton from "../../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { preventOnCloseWhile, useFullScreen } from "../../../../utils/dialog";
import { updateCustomerPackagePreferences } from "../db";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CustomerEditPackagePreferencesDialog = ({ open, onClose }: Props) => {
  const { customer } = useCustomerContext();
  const [loading, setLoading] = useState(false);
  const { fullScreen: xsDown } = useFullScreen("sm");
  const showError = useErrorSnackbar();

  const initialPackageOptions = useMemo(
    () => ({
      advantage: customer.onboarding?.advantage?.providers || [],
      navigator: customer.onboarding?.navigator?.providers || [],
      solve: customer.onboarding?.solve?.providers || [],
    }),
    [customer.onboarding]
  );

  const { packageOptions, handlePackageSelection } = usePackageSelection(initialPackageOptions);

  const isPackageOptionChanged = useMemo(
    () => !isEqual(packageOptions, initialPackageOptions),
    [packageOptions, initialPackageOptions]
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateCustomerPackagePreferences(customer, packageOptions);
      onClose();
    } catch (error) {
      showError("Failed to update customer package preferences");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={xsDown}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Edit customer</DialogTitle>
      <Divider />
      <DialogContent>
        <PackageSelector packageOptions={packageOptions} onSelect={handlePackageSelection} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!isPackageOptionSelected(packageOptions) || !isPackageOptionChanged}
          loading={loading}
          mixpanelEventId="customer.edit.package.preferences"
        >
          {globalText.SAVE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
