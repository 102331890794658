import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";

import { SideDrawer } from "../../../../Components/SideDrawer";
import { type HandleUpdateNodeFn, type NodeConfigs } from "../../types";
import { Header } from "./Header";
import { NodeConfigurationProvider } from "./NodeConfigurationContext";
import { useTabs } from "./Tabs/hooks";
import { TabContent } from "./Tabs/TabContent";
import { TabNavigation } from "./Tabs/TabNavigation";
import { TestNodeProvider } from "./TestNodeContext";

type Props = {
  open: boolean;
  onClose: () => void;
  nodeConfig: NodeConfigs;
  onUpdateNode: HandleUpdateNodeFn;
  onChangeTriggerType: (triggerType: CloudFlowNodeType) => void;
};

const NodeConfigurationPanel = ({ open, onClose, nodeConfig, onUpdateNode, onChangeTriggerType }: Props) => {
  const tabs = useTabs(nodeConfig);
  return (
    <NodeConfigurationProvider
      nodeConfig={nodeConfig}
      key={nodeConfig.id}
      updateNode={onUpdateNode}
      onChangeTriggerType={onChangeTriggerType}
    >
      <TestNodeProvider>
        <SideDrawer
          open={open}
          title=""
          onClose={onClose}
          variant="persistent"
          sideDrawerSx={{
            my: 7.1,
            borderRadius: 0,
            mx: 0,
            "& .MuiStack-root:nth-of-type(1) > .MuiStack-root:nth-of-type(1)": {
              py: 0.5,
            },
            "& .MuiDivider-root": {
              marginBottom: 0,
            },
          }}
          disableGutters={true}
          headerElement={<Header />}
        >
          <Stack height="100%">
            {tabs.length > 1 && <TabNavigation tabs={tabs} />}
            <Stack sx={{ flexGrow: 1, overflowY: "auto" }}>
              <TabContent />
            </Stack>
          </Stack>
        </SideDrawer>
      </TestNodeProvider>
    </NodeConfigurationProvider>
  );
};

export default NodeConfigurationPanel;
