import { useEffect, useMemo, useState } from "react";

import { FlexsaveTypes, type GcpMarketplaceEntitlementModel, MarketplaceModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { FlexsaveOpsApi } from "../api";
import { useFlexsaveData } from "../hooks";
import { getDateByMMMMSpaceYYYY } from "../utils/dateUtils";

export const useShouldShowWidget = () => {
  const { customer } = useCustomerContext();
  const [accounts, loading] = useCollectionData(
    getCollection(MarketplaceModel)
      .doc("gcp-marketplace")
      .collection("gcpMarketplaceAccounts")
      .where("customer", "==", customer.ref)
  );

  const [loadingEntitlements, setLoadingEntitlements] = useState<boolean>(true);
  const [entitlements, setEntitlements] = useState<GcpMarketplaceEntitlementModel[]>([]);

  const accountsNames = useMemo(
    () =>
      accounts?.flatMap((account) => (account?.procurementAccount?.name ? account?.procurementAccount?.name : [])) ??
      [],
    [accounts]
  );

  useEffect(() => {
    if (accountsNames.length > 0) {
      setLoadingEntitlements(true);
      const query = getCollection(MarketplaceModel)
        .doc("gcp-marketplace")
        .collection("gcpMarketplaceEntitlements")
        .where("procurementEntitlement.account", "in", accountsNames);

      const unsubscribe = query.onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setEntitlements(data);
        setLoadingEntitlements(false);
      });
      return () => {
        unsubscribe();
      };
    } else {
      setLoadingEntitlements(false);
    }
  }, [accountsNames]);

  if (loading || loadingEntitlements) {
    return false;
  }

  const found = entitlements.find(
    (entitlement) =>
      entitlement.procurementEntitlement.product === "doit-flexsave.endpoints.doit-intl-public.cloud.goog"
  );

  return !found;
};

export function useFlexsaveOpsApi(version?: string) {
  const api = useApiContext();

  return useMemo(() => new FlexsaveOpsApi(api, version), [api, version]);
}

export const useLastMonthSavings = (): [number, string] => {
  const [doc] = useFlexsaveData(FlexsaveTypes.GCP);

  return useMemo(() => {
    if (doc?.enabled?.savings && doc.enabled.disableNotified) {
      const history = new Map(doc?.enabled?.savings[0]?.history);

      for (const key of history.keys()) {
        const value = history.get(key);
        if (!value || value.savings <= 0) {
          history.delete(key);
        }
      }

      const lastMonth = Array.from(history.keys()).reduce((a, b) => {
        const dateA = getDateByMMMMSpaceYYYY(a);
        const dateB = getDateByMMMMSpaceYYYY(b);
        return dateA > dateB ? a : b;
      });

      const lastMonthOnly = lastMonth.split(" ")[0];
      return [history.get(lastMonth)?.savings ?? 0, lastMonthOnly];
    } else {
      return [0, ""];
    }
  }, [doc]);
};
