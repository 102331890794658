import { type Dispatch, useCallback } from "react";

import { type InputProps as StandardInputProps } from "@mui/material/Input/Input";

import { useEmailSchema } from "../helpers";
import { useCheckEmail } from "../hooks";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";
import EmailFormTemplate from "./EmailFormTemplate";

const CheckEmailForm = ({ state, dispatch }: { state: LoginState; dispatch: Dispatch<LoginHookAction> }) => {
  const checkEmail = useCheckEmail({ state, dispatch });
  const EmailSchema = useEmailSchema();

  const emailInputHandler: StandardInputProps["onChange"] = useCallback(
    (e) => {
      dispatch({ type: LoginHookActionType.setEmail, email: e.currentTarget.value });
    },
    [dispatch]
  );

  return (
    <EmailFormTemplate
      email={state.email}
      isLoading={state.isLoading}
      submitHandler={checkEmail}
      emailInputHandler={emailInputHandler}
      validationSchema={EmailSchema}
    />
  );
};

export default CheckEmailForm;
