import { useEffect } from "react";

const useBeforeUnload = (fn) => {
  useEffect(() => {
    const handler = (evt) => {
      const returnValue = fn(evt);
      if (returnValue) {
        evt.preventDefault();
        evt.returnValue = returnValue;
      }
      return returnValue;
    };

    window.addEventListener("beforeunload", handler);
    return () => window.removeEventListener("beforeunload", handler);
  }, [fn]);
};

export default useBeforeUnload;
