import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

type Props = {
  value: DateTime<boolean>;
  onChange: (value: DateTime<boolean> | null) => void;
  label: string;
  error: boolean;
  disabled: boolean;
  minDate?: DateTime<boolean>;
  helperText?: string;
};

export const DateSelectorComponent = ({ value, onChange, error, label, disabled, minDate, helperText }: Props) => (
  <DatePicker
    renderInput={(params) => (
      <TextField
        data-cy={`accelerator-${label.toLowerCase().replace(" ", "-")}`}
        fullWidth
        {...params}
        error={error}
        required
        margin="normal"
        helperText={helperText}
      />
    )}
    label={label}
    value={value}
    onChange={onChange}
    disabled={disabled}
    components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
    inputFormat="dd LLL, yyyy"
    disablePast
    minDate={minDate}
  />
);
