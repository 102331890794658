import Add from "@mui/icons-material/AddCircleOutlineOutlined";
import Remove from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { IconButton } from "@mui/material";

export type AttributionBuilderRowControlProps = {
  disabled: boolean;
  addMode: boolean;
  handleRowsUpdate: (update: string) => void;
};

const AttributionBuilderRowControl = ({ disabled, addMode, handleRowsUpdate }: AttributionBuilderRowControlProps) => {
  const addIconColor = disabled ? "disabled" : "primary";
  const removeIconColor = disabled ? "disabled" : "error";
  return (
    <>
      {addMode ? (
        <IconButton
          size="small"
          onClick={() => {
            handleRowsUpdate("add");
          }}
          disabled={disabled}
        >
          <Add color={addIconColor} />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          onClick={() => {
            handleRowsUpdate("remove");
          }}
          disabled={disabled}
        >
          <Remove color={removeIconColor} />
        </IconButton>
      )}
    </>
  );
};

export default AttributionBuilderRowControl;
