import { type TierModel } from "@doitintl/cmp-models";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";

const itemsDescriptions: FilterTableItemDescriptor<TierModel>[] = [
  {
    headerText: "Tier name",
    value: "displayName",
    filterType: "string",
  },
  {
    headerText: "SKU",
    value: "sku",
    filterType: "string",
  },
  {
    headerText: "Price",
    value: "price.USD",
  },
  {
    headerText: "Package type",
    value: "packageType",
    filterType: "string",
  },
  {
    value: "@",
    headerText: "",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
