import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { type LabelColor } from "./types";

type Props = {
  label: string;
  color: LabelColor;
  indicatorSize?: string | number;
};

const IndicatorLabel = ({ label, color, indicatorSize = "1em" }: Props) => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box
      sx={{
        width: indicatorSize,
        height: indicatorSize,
        borderRadius: "50%",
        bgcolor: color,
      }}
    />
    <Typography pl={1}>{label}</Typography>
  </Box>
);

export default IndicatorLabel;
