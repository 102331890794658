import { type ReactNode } from "react";

import { Card, CardContent, CardHeader, useTheme } from "@mui/material";

export const MetadataCard = ({ title, children }: { title: string; children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        flex: 1,
        "& .MuiCardHeader-root": {
          backgroundColor: theme.palette.general.backgroundDark,
          borderBottom: `1px solid ${theme.palette.general.divider}`,
        },
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: "body1",
          fontWeight: 500,
        }}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
