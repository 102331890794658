import { ProductEnum } from "@doitintl/cmp-models";

import { addIdPrefix } from "../../../utils/addIdPrefix";

export const text = {
  DIALOG_HEADER: "Invite users",
  CANCEL_BUTTON_TEXT: "Cancel",
  SUBMIT_BUTTON_TEXT: "Invite",
  REQUEST_NEW_BILLING_PROFILE_LABEL: "Require new billing profile registration",
  CONTINUE_EDITING_USER: "Continue to editing user profile",
};
export const tooltipText = {
  tooltip_header: "Inviting new client?",
  tooltip_description: `If you're inviting a new client, make sure you check "${text.REQUEST_NEW_BILLING_PROFILE_LABEL}". Typically, the first user would also have an Admin role (set on the next page)`,
};

export const errorMessages = {
  INVALID_EMAIL_ERROR: "Email addresses highlighted in red can’t be processed. Contact support to invite these users. ",
  USER_ALREADY_EXIST: "Following users are already logged in and will not receive an invite:",
  NO_EMAIL_RECORD: (email: string) => `There is an issue with the invited email ${email}. Please contact support `,
  USER_ALREADY_INVITED: "This user has already been invited",
};

const testIds = {
  CONTINUE_EDIT_USER_CHECKBOX: "continue_edit_user-checkbox",
  EMAILS_INPUT_TEXT_BAR: "emails_input-text_bar",
  EMAILS_INPUT_ERROR: "emails_input-error",
  CLOSE_BUTTON: "close_button",
  SUBMIT_BUTTON: "submit_button",
  HELPER_TOOLTIP_BOX: "helper_tooltip_box",
  HELPER_TOOLTIP_BOX_CHECKBOX: "helper_tooltip_box-checkbox",
};

export const inviteUserDialogTestIds = addIdPrefix(testIds, "invite_user_dialog");

export const baseDailyDigestsAttributionIds = [
  { assetId: ProductEnum.GoogleCloud, value: "7mJDP8HtXXlpFTb2pVz7" },
  { assetId: ProductEnum.AmazonWebServices, value: "PvqyGcdFcTHh7aLUdGdf" },
];
