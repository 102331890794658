import { collection, subCollection } from "@doitintl/models-types";

@subCollection("customerToTenant")
export class CustomerToTenantModel {
  tenantId!: string;
}

@subCollection("tenantToCustomer")
export class TenantToCustomerModel {
  customerId!: string;
}

@subCollection("emailToTenant")
export class EmailToTenantModel {
  tenantId!: string;
}

export class UidToUserModel {
  userId!: string;

  emailVerified!: boolean;

  uidEmail!: string;

  userIdEmail?: string;
}

@collection("tenants")
export class TenantModel {
  subCollections?: {
    customerToTenant: CustomerToTenantModel;
    tenantToCustomer: TenantToCustomerModel;
    emailToTenant: EmailToTenantModel;
    uidToUser: UidToUserModel;
  };
}
