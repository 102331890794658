import { stringify } from "qs";

import { isProduction } from "../../../../constants";
import { AWS_STACK_CREATION_URL, STACK_NAME } from "./consts";

const getAmazonTemplateUrlPrefix = () => {
  if (isProduction) {
    return "prod";
  }
  return "dev";
};

const AMAZON_TEMPLATE_URL_PREFIX = `https://doit-cmp-ops-pub.s3.amazonaws.com/${getAmazonTemplateUrlPrefix()}`;

const BILLING_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/saas_create_role_N_policies.yml`;

export function getBillingStackURL(customerId: string, bucketName: string) {
  const params = {
    templateUrl: BILLING_TEMPLATE_URL,
    stackName: STACK_NAME,
    param_CustomerId: customerId,
    param_S3Bucket: bucketName,
  };
  const paramsStr = stringify(params);
  return `${AWS_STACK_CREATION_URL}${paramsStr}`;
}
