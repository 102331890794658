import { useCallback, useMemo, useState } from "react";

import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Container } from "@mui/material";

import { Loader } from "../../../../Components/Loader/Loader";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepState } from "../../../../Components/Stepper";
import { trackConnectClick } from "../Common/mixpanelEvents";
import SuccessMessage from "../Common/SuccessMessage";
import { useSaaSConsoleAPI, useSaaSGCPConsoleOnboarding } from "../hooks";
import Overview from "./Overview";
import SaaSOnboardingGCPContent from "./SaaSOnboardingGCPContent";

type Props = {
  handleBack: () => void;
};

const GCPStepper = ({ handleBack }: Props) => {
  const saasConsoleAPI = useSaaSConsoleAPI(SaaSConsoleType.GCP);
  const showErrorSnackbar = useErrorSnackbar();
  const [onboarding, loading] = useSaaSGCPConsoleOnboarding();
  const [billingAccountId, setBillingAccountId] = useState<string>("");
  const [projectId, setProjectId] = useState<string>("");
  const [datasetId, setDatasetId] = useState<string>("");
  const [enabledDetailedCost, setEnabledDetailedCost] = useState(false);
  const [billingConnectionLoading, setBillingConnectionLoading] = useState(false);
  const [billingAccountValid, setBillingAccountValid] = useState(false);
  const disabledGCPBillingActivation = !projectId || !datasetId || !enabledDetailedCost || !billingAccountValid;

  const handleTestBillingConnection = useCallback(async () => {
    trackConnectClick(SaaSConsoleType.GCP);
    setBillingConnectionLoading(true);
    const response = await saasConsoleAPI.testBillingConnectionGCP(billingAccountId, projectId, datasetId);
    setBillingConnectionLoading(false);
    if (response !== true && response !== "timeout") {
      showErrorSnackbar("Sorry, an error occurred. Please review the steps or contact support");
    }
  }, [saasConsoleAPI, billingAccountId, datasetId, projectId, showErrorSnackbar]);

  const stepperState: StepState[] = useMemo(() => {
    if (!disabledGCPBillingActivation) {
      return ["complete", "complete"];
    } else {
      return ["complete", "incomplete"];
    }
  }, [disabledGCPBillingActivation]);

  const steps: Step[] = [
    {
      children: <Overview />,
      label: "Overview",
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: (
        <SaaSOnboardingGCPContent
          handleBack={handleBack}
          billingAccountId={billingAccountId}
          projectId={projectId}
          datasetId={datasetId}
          enabledDetailedCost={enabledDetailedCost}
          setBillingAccountId={setBillingAccountId}
          setProjectId={setProjectId}
          setDatasetId={setDatasetId}
          setEnabledDetailedCost={setEnabledDetailedCost}
          setBillingAccountValid={setBillingAccountValid}
        />
      ),
      label: "Connect",
      order: 1,
      required: true,
      state: stepperState[1],
    },
  ];

  return (
    <Loader loading={loading}>
      {onboarding?.accounts?.[billingAccountId]?.completed ? (
        <Container
          maxWidth="md"
          sx={{ pt: { xs: 3, sm: 6 }, alignItems: "center", display: "flex", flexDirection: "column" }}
        >
          <SuccessMessage handleClose={handleBack} type={SaaSConsoleType.GCP} />
        </Container>
      ) : (
        <Stepper
          backButtonLabel="Back"
          loading={billingConnectionLoading}
          onCancel={handleBack}
          onSubmit={handleTestBillingConnection}
          steps={steps}
          submitButtonLabel="Activate"
          footerMaxWidth={800}
          maxWidth={1600}
          contentSx={{
            mt: 3,
            mb: 3,
          }}
        />
      )}
    </Loader>
  );
};

export default GCPStepper;
