import { type JSX, useRef, useState } from "react";

import Tooltip from "@mui/material/Tooltip";

import useResizeEffect from "../hooks/useResizeEffect";

type Props = {
  children: JSX.Element;
};

export const OverflowToolTip = ({ children }: Props) => {
  const [hoverStatus, setHover] = useState(false);
  const textElementRef = useRef<HTMLDivElement>(null);

  const compareSize = () => {
    if (textElementRef?.current) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useResizeEffect(compareSize);

  return (
    <Tooltip title={children} disableHoverListener={!hoverStatus}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
