import { type Status } from "@doitintl/cmp-models";

import { type HeaderColumn } from "../../../../types/FilterTable";
import { toCapitalCase } from "../../../../utils/string";

export const headerColumns: readonly HeaderColumn[] = [
  {
    label: "Customer",
    value: "primaryDomain",
    tooltip: "Customer",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Account ID",
    value: "accountId",
    tooltip: "Account ID",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "status",
    label: "Compute",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "status",
    label: "RDS",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "status",
    label: "SageMaker",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Type",
    value: "type",
    tooltip: "Type",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Managed",
    value: "managed",
    tooltip: "Managed",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Compute Discount",
    value: "computeDiscount",
    tooltip: "Compute Discount",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Compute Effective Date",
    value: "computeEffectiveDate",
    tooltip: "Compute Effective Date",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "SageMaker Discount",
    value: "sagemakerDiscount",
    tooltip: "SageMaker Discount",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "SageMaker Effective Date",
    value: "sagemakerEffectiveDate",
    tooltip: "SageMaker Effective Date",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "RDS Discount",
    value: "rdsDiscount",
    tooltip: "RDS Discount",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "RDS Effective Date",
    value: "rdsEffectiveDate",
    tooltip: "RDS Effective Date",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Compute Target Coverage",
    value: "targetPercentage",
    tooltip: "Compute Target Coverage",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "RDS Target Coverage",
    value: "rdsTargetPercentage",
    tooltip: "RDS Target Coverage",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Min Spend",
    value: "minSpend",
    tooltip: "Min Spend",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Max Spend",
    value: "maxSpend",
    tooltip: "Max Spend",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "Time Disabled",
    value: "timeDisabled",
    tooltip: "Time Disabled",
    style: { align: "left", padding: "normal" },
  },
  {
    label: "",
    value: "editForm",
    tooltip: "Edit Form",
    style: { align: "left", padding: "normal" },
  },
];

const capitalizeLabel = (value: Status) => ({
  value,
  label: toCapitalCase(value),
});

export const filterColumns = [
  {
    label: "Customer",
    path: "primaryDomain",
    comparators: ["==", "contains"],
  },
  {
    label: "Account ID",
    path: "accountId",
    comparators: ["==", "contains"],
  },
  {
    label: "Type",
    path: "type",
    comparators: ["==", "contains"],
  },
  {
    label: "Compute Status",
    path: "status",
    comparators: ["=="],
    toOption: capitalizeLabel,
  },
  {
    label: "SageMaker Status",
    path: "sagemakerStatus",
    comparators: ["=="],
    toOption: capitalizeLabel,
  },
  {
    label: "RDS Status",
    path: "rdsStatus",
    comparators: ["=="],
    toOption: capitalizeLabel,
  },
] as const;
