import { type Dispatch, useEffect, useMemo, useState } from "react";

import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import isEqual from "lodash/isEqual";

import { authSettingsTexts } from "../../../../assets/texts";
import { BottomAppBar } from "../../../../Components/BottomAppBar";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useUnsavedChanges } from "../../../../Context/UnsavedChangesContext";
import { getDefaultRoleRefByCustomer } from "../../../../utils/permissions";
import { useAuthSettings } from "../hooks";
import { type AuthAction, type AuthSettingsType, type AuthState } from "../types";
import { AuthProvider } from "./AuthProvider";
import { AutoProvision } from "./AutoProvision";
import { useAuthSettingsReducer } from "./reducer";

type Props = {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
};

export function Settings({ state, dispatch }: Props) {
  const { customer } = useCustomerContext();
  const [authSettings, setNewAuthSettings]: [AuthSettingsType, (NewAuthSettings: AuthSettingsType) => Promise<void>] =
    useAuthSettings(state, dispatch, customer.id); // db auth settings
  const [uiAuthSettings, uiAuthSettingsDisaptch] = useAuthSettingsReducer({
    allowedProvider: authSettings.allowedProvider,
    autoProvision: authSettings.autoProvision,
  }); // UI auth settings
  const [defaultRoleName, setDefaultRoleName] = useState("-");
  const { activatePendingPrompt, clearPendingPrompt } = useUnsavedChanges();

  useEffect(() => {
    if (isEqual(authSettings, uiAuthSettings)) {
      clearPendingPrompt();
    } else {
      activatePendingPrompt();
    }
  }, [activatePendingPrompt, clearPendingPrompt, authSettings, uiAuthSettings]);

  async function save() {
    await setNewAuthSettings(uiAuthSettings);
  }

  useEffect(() => {
    getDefaultRoleRefByCustomer(customer)
      .get()
      .then((doc) => {
        const name = doc.exists() && doc.data()?.name;
        if (name) {
          setDefaultRoleName(name);
        }
      });
  }, [customer]);

  const isSaveDisabled = useMemo(() => {
    if (isEqual(authSettings, uiAuthSettings)) {
      return true;
    }

    return uiAuthSettings.autoProvision.enabled && uiAuthSettings.autoProvision.allowedDomains.length === 0;
  }, [authSettings, uiAuthSettings]);

  return (
    <>
      <Stack direction="column" spacing={4} sx={{ ml: 2, mb: 10 }}>
        <Typography variant="h1">{authSettingsTexts.TITLE}</Typography>
        <AuthProvider allowedProvider={uiAuthSettings.allowedProvider} dispatch={uiAuthSettingsDisaptch} />
        <AutoProvision
          allowedDomains={uiAuthSettings.autoProvision.allowedDomains}
          isEnabled={uiAuthSettings.autoProvision.enabled}
          dispatch={uiAuthSettingsDisaptch}
          customerId={customer.id}
          primaryDomain={customer.primaryDomain || ""}
          domains={customer.domains || []}
          defaultRoleName={defaultRoleName}
        />
      </Stack>
      <BottomAppBar
        maxWidth="xl"
        primaryButtonName={authSettingsTexts.SAVE_BUTTON}
        disabled={isSaveDisabled}
        disabledTooltipTitle={
          isSaveDisabled && uiAuthSettings.autoProvision.allowedDomains.length === 0
            ? "You must select at least one domain before enabling auto provisioning"
            : undefined
        }
        handlePrimaryButtonClicked={save}
      />
    </>
  );
}
