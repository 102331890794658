import { type Dispatch, type SetStateAction } from "react";

import {
  type ActionEntityModel,
  type AppKey,
  type ApprovalConfig,
  type CloudflowCreationStatus,
  type CloudflowEntityModel,
  type CloudFlowNodeType,
  type INodeModel,
  type NodeConfigApiParametersBase,
  type NodeParameters,
  type NodeStatus,
  type NodeTransitionList,
  type ObjectValues,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { type Reference } from "@doitintl/models-types";
import {
  type Edge,
  type EdgeMouseHandler,
  type Node,
  type NodeMouseHandler,
  type OnConnect,
  type OnEdgesChange,
  type OnNodesChange,
} from "@xyflow/react";

import { type useUpdateCloudflowNodes } from "./hooks";
import type { BaseCloudflowHit } from "./CloudflowBuilder/algolia/types";

export type CloudflowLink = {
  title: string;
  url: string;
};

export type CloudflowKindText = {
  subtitle: string;
  title: string;
};

export enum CloudflowKind {
  fromScratch = "fromScratch",
  template = "template",
}

export type CloudflowTemplate = {
  id: string;
  name: string;
  description: string;
  integrations: Readonly<Array<Integration>>;
  tags?: Record<string, string[]>;
  ref: ModelReference<CloudflowEntityModel>;
};

export type Cloud = Omit<AppKey, "internal">;
export type Integration = "slack" | "jira" | "email";

export type DeleteCloudflowInput = {
  id: string;
};

export type CreateCloudflowInput = {
  name: string;
  firstNodeId: string;
  description?: string;
  status?: CloudflowCreationStatus;
  nodes: BaseNodeData[];
  collaborators: {
    email: string;
    role: "owner";
  }[];
};

export type UpdateCloudflowInput = {
  name?: string;
  description?: string;
  status?: CloudflowCreationStatus;
};
export type TriggerCloudflowResponce = {
  flowId: string;
  customerId: string;
  message: string;
  triggeredBy: string;
};

// Response from test node when there was an error during the orchestrator execution
export type TestNodeErrorResponse = { error: string };
// TestNodeResponse can stil contain error depending on the status code
export type TestNodeResponse = { message: string; status: number };

export type CloudflowDTO = {
  id: string;
  name: string;
  description: string;
  status: string;
  customer: string;
  createdBy: string;
  firstNode: string | null;
  createdAt: Date;
  updatedAt: Date;
  nextRun?: Date | null;
};

export type TestNodeRequest = {
  cloudflowId: string;
  nodeId: string;
  input: {
    params: NodeConfigApiParametersBase;
  };
};

export type ConfigurationTab =
  | "Parameters"
  | "Permissions"
  | "Test"
  | "Schedule"
  | "FilterParameters"
  | "TransformParameters"
  | "ManualTrigger";

export interface NodeConfigs<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> {
  name: string;
  parameters: NodeParameters<TNodeType>;
  approval?: ApprovalConfig;
  type: TNodeType;
  id: string;
  flowId: string;
  status: NodeStatus;
  statusMessage?: string;
  transitions: NodeTransitionList;
  touched: boolean;
  errors: Record<string, string>;
}

export type CloudPermissions = {
  requiredPermissions: string[];
  updatePermissions: () => Promise<void>;
  command: string;
  loading: boolean;
  isValid: boolean;
};

export type AWSPermissions = {
  [serviceName: string]: string[];
};

export type PermissionMap = {
  [serviceName: string]: boolean;
};

export type Position = {
  x: number;
  y: number;
};

export const EDGE_TYPE = {
  CUSTOM: "custom",
  GHOST: "ghost",
  CONDITION: "condition",
} as const;

export type EdgeType = ObjectValues<typeof EDGE_TYPE>;

export type BaseNodeData<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  id: string;
  actionID?: null | Reference<ActionEntityModel>;
} & Omit<INodeModel<TNodeType>, "action" | "createdBy">;

export type CreateOrUpdateNode<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = {
  transition?: {
    parentNodeId: string;
    targetNodeId: string;
    label?: string;
    value?: any;
  };
  node: BaseNodeData<TNodeType>;
};

export type UpdateCloudflowNodes = {
  name?: string;
  flowId: string;
  addedNodes?: CreateOrUpdateNode[];
  updatedNodes?: CreateOrUpdateNode[];
  deletedNodes?: string[];
};

export interface RFNode<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> extends Record<string, unknown> {
  nodeData: INodeModel<TNodeType>;
  touched: boolean;
  stepNumber?: number;
  errors: Record<string, string>;
  onEditNode?: () => void;
  onDeleteNode?: () => void;
  onAddNode?: (nodeType: CloudFlowNodeType, id: string, position?: Position) => void;
}

export type NodeEdgeManagerConfig = {
  nodes: Node<RFNode>[];
  setNodes: Dispatch<SetStateAction<Node<RFNode>[]>>;
  edges: Edge[];
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  activeNode?: Node<RFNode>;
  focusedNodeId?: string;
  selectNode: (nodeId: string | null) => void;
  onNodesChange: OnNodesChange<Node<RFNode>>;
  onEdgesChange: OnEdgesChange;
  handleEditNode: (node: Node<RFNode>) => void;
  onConnect: OnConnect;
  onEdgeClick: EdgeMouseHandler<Edge>;
  showModal: boolean;
  closeModal: () => void;
  handleActionNodeInit: (nodeId: string, item: BaseCloudflowHit) => void;
  onConfirmDeleteIfNode: () => Promise<void>;
  deleteIfNodeId: string;
  setDeleteIfNodeId: Dispatch<SetStateAction<string>>;
  manageIfActionsId: string;
  setManageIfActionsId: Dispatch<SetStateAction<string>>;
  onSaveManageIfActionsDialog: (choice: string) => void;
  handleAddNode: (nodeType: CloudFlowNodeType, nodeId: string) => void;
  onChangeActiveNode: (nodeType: CloudFlowNodeType, nodeId: string) => void;
  onNodeClick: NodeMouseHandler<Node<RFNode>>;
  httpOperationLoading: boolean;
  updateNodes: ReturnType<typeof useUpdateCloudflowNodes>[0];
  interactionEnabled: boolean;
  applyLayoutAndSetState: (nodes: Node<RFNode>[], edges: Edge[]) => void;
};

export type Region = {
  Endpoint: string;
  OptInStatus: string;
  RegionName: string;
};

export type EdgeData = {
  label: string;
  target: string;
  setInteractionEnabled: (value: boolean) => void;
  handleAddNode: (nodeType: CloudFlowNodeType, targetNodeId: string) => void;
};

export type UpdateNodeData<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = Partial<
  Pick<
    NodeConfigs<TNodeType>,
    "name" | "status" | "statusMessage" | "transitions" | "approval" | "errors" | "parameters"
  >
>;

export type HandleUpdateNodeFn<TNodeType extends CloudFlowNodeType = CloudFlowNodeType> = (
  updateNodeAction: UpdateNodeData<TNodeType> | ((prevNode: UpdateNodeData<TNodeType>) => UpdateNodeData<TNodeType>)
) => void;
