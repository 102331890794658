import { useEffect } from "react";

import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";

import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import { sendStepMixpanelEvent } from "../utils";

const attributionGroupsDocs =
  "https://help.doit.com/docs/cloud-analytics/attribution-groups/attribution-group-in-reports#identify-unallocated-costs";

const STEP_NUMBER = 4;

type Props = {
  origin: string;
};

const Step4 = ({ origin }: Props) => {
  useEffect(() => {
    sendStepMixpanelEvent(STEP_NUMBER, "about-unallocated-costs", origin);
  }, [origin]);

  return (
    <Stack gap={3}>
      <Typography>{guidedExperienceText.step4.p1.INFO_CONTENT}</Typography>
      <List sx={{ listStyleType: "disc", pl: 1 }}>
        {guidedExperienceText.step4.p1.REASONS.map((reason, index) => (
          <ListItem key={index} sx={{ py: 0 }}>
            <ListItemText sx={{ display: "list-item" }} primary={reason} />
          </ListItem>
        ))}
      </List>
      <Typography>{guidedExperienceText.step4.p1.INFO_CONTENT_2}</Typography>
      <Typography>
        {guidedExperienceText.step4.p1.INFO_FOOTER.PART_1}{" "}
        <Typography
          component="a"
          href={attributionGroupsDocs}
          target="_blank"
          sx={{ color: "primary.main", textDecoration: "none" }}
        >
          {guidedExperienceText.step4.p1.INFO_FOOTER.PART_2}
        </Typography>
      </Typography>
      <Typography variant="caption">{guidedExperienceText.step4.p1.INFO_FOOTER.NOTE}</Typography>
    </Stack>
  );
};

export default Step4;
