import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Alert } from "@mui/material";

import { useIsDataImportInProgress } from "./hooks";

const DataImportBanner = ({ dashboardType }: { dashboardType: string }) => {
  const isAWSDataImportInProgress = useIsDataImportInProgress(SaaSConsoleType.AWS);
  const isGCPDataImportInProgress = useIsDataImportInProgress(SaaSConsoleType.GCP);
  const gcpDashboardTypes = ["gcp-lens", "saas-gcp-lens", "BQ Lens"];
  const awsDashboardTypes = ["AWS", "saas-aws-lens", "eks-lens"];

  let message = "";

  if (isAWSDataImportInProgress && awsDashboardTypes.includes(dashboardType)) {
    message = "AWS";
  } else if (isGCPDataImportInProgress && gcpDashboardTypes.includes(dashboardType)) {
    message = "Google Cloud";
  }

  if (message) {
    return (
      <Alert severity="info" sx={{ mx: 3, mb: 2 }}>
        {`We are currently importing your ${message} billing data. This can take up to 24 hours and we will notify you when this is complete.`}
      </Alert>
    );
  }

  return null;
};

export default DataImportBanner;
