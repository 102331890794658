import { type InputProps as StandardInputProps } from "@mui/material/Input/Input";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";

import { useCustomerContext } from "../../Context/CustomerContext";

const ALL_ENTITIES = "all-entities";

type Props = TextFieldProps & {
  value?: string;
  allOption?: boolean;
  onChange?: StandardInputProps["onChange"];
};

const EntitySelect = (props: Props) => {
  const { value, allOption, onChange, ...otherProps } = props;
  const { entities } = useCustomerContext();

  const filteredEntities = entities.filter((entity) => entity.active);

  return (
    <TextField
      select
      label="Billing Profile"
      variant="outlined"
      data-cy="entity-select"
      value={value ?? ""}
      onChange={onChange}
      SelectProps={{
        MenuProps: {
          MenuListProps: {
            dense: true,
          },
        },
      }}
      {...otherProps}
    >
      {allOption && (
        <MenuItem key={ALL_ENTITIES} value={ALL_ENTITIES}>
          All billing profiles
        </MenuItem>
      )}
      {filteredEntities.map((entity) => (
        <MenuItem key={entity.id} value={entity.id}>
          {entity.priorityId} - {entity.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default EntitySelect;
