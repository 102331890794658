import { useEffect } from "react";

import { useDataHubContext } from "../../Context/DataHubContext";
import DataHubSources from "./DataHubSources";
import DatasetDetails from "./DatasetDetails/DatasetDetails";

type DataHubPageId = "datasets" | "datasets:details";

const DataHubRouter = ({ pageId }: { pageId: DataHubPageId }) => {
  const { fetchDataHubItems } = useDataHubContext();

  useEffect(() => {
    fetchDataHubItems(false);
  }, [fetchDataHubItems]);

  switch (pageId) {
    case "datasets:details": {
      return <DatasetDetails />;
    }

    default: {
      return <DataHubSources />;
    }
  }
};

export default DataHubRouter;
