import { stringify } from "qs";

import { isProduction } from "../../../../constants";

const AWS_STACK_CREATION_URL =
  "https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?";

const AMAZON_TEMPLATE_URL_PREFIX = `https://doit-cmp-ops-pub.s3.amazonaws.com/${isProduction ? "prod" : "dev"}`;

const ESTIMATIONS_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/flexsave_create_role_N_policies.yml`;
const BILLING_AFTER_ESTIMATION_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/flexsave_create_policies.yml`;
const BILLING_TEMPLATE_URL = `${AMAZON_TEMPLATE_URL_PREFIX}/flexsave_create_role_N_billing_policy.yml`;

export function getEstimationCreationStackURL(customerId: string) {
  const params = {
    templateUrl: ESTIMATIONS_TEMPLATE_URL,
    stackName: "FlexsaveRecommendStack",
    param_CustomerId: customerId,
  };
  const paramsStr = stringify(params);
  return `${AWS_STACK_CREATION_URL}${paramsStr}`;
}

export function getBillingStackURL(customerId: string, bucketName: string, curPath: string, isMissingAWSRole: boolean) {
  const params = {
    templateUrl: isMissingAWSRole ? BILLING_TEMPLATE_URL : BILLING_AFTER_ESTIMATION_TEMPLATE_URL,
    stackName: "FlexsaveAddBillingOrg",
    param_CustomerId: customerId,
    param_S3Bucket: bucketName,
    param_CurPath: curPath,
  };
  const paramsStr = stringify(params);
  return `${AWS_STACK_CREATION_URL}${paramsStr}`;
}
