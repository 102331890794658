import { type Label } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

import { type CloudAnalyticsResourceType } from "../analyticsResources/types";

const getBaseURL = (customerID: string) => `/v1/customers/${customerID}/labels`;

export const createLabelHandler = async ({
  api,
  customerId,
  label,
}: {
  api: AxiosInstance;
  customerId: string;
  label: Label;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.post(baseURL, {
    name: label.name,
    color: label.color,
  });
};

export const updateLabelHandler = async ({
  api,
  customerId,
  labelId,
  label,
}: {
  api: AxiosInstance;
  customerId: string;
  labelId: string;
  label: Label;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.patch(`${baseURL}/${labelId}`, {
    name: label.name,
    color: label.color,
  });
};

export const deleteLabelHandler = async ({
  api,
  customerId,
  labelId,
}: {
  api: AxiosInstance;
  customerId: string;
  labelId: string;
}) => {
  const baseURL = getBaseURL(customerId);
  await api.delete(`${baseURL}/${labelId}`);
};

type AssignLabelsObject = {
  objectId: string;
  objectType: CloudAnalyticsResourceType;
};

export type AssignLabelsRequest = { objects: AssignLabelsObject[]; addLabels: string[]; removeLabels: string[] };

export const assignLabels = async (client: AxiosInstance, customerId: string, req: AssignLabelsRequest) =>
  client.put<void>(`/v1/customers/${customerId}/labels/assign`, req);
