import GoogleCloudIcon from "./google-cloud.png";
import AWSIcon from "./amazon-web-services.png";
import GSuiteIcon from "./g-suite.png";
import AzureIcon from "./microsoft-azure.png";
import Office365Icon from "./office-365.png";

const icons = {
  "amazon-web-services": AWSIcon,
  "g-suite": GSuiteIcon,
  "google-cloud": GoogleCloudIcon,
  "google-cloud-project": GoogleCloudIcon,
  "microsoft-azure": AzureIcon,
  "office-365": Office365Icon,
};

export default icons;
