import { useCallback } from "react";

import { CurrencyCodes } from "@doitintl/cmp-models";

import { asyncConvertCurrencyTo } from "../../Context/AsyncCurrencyConverterContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { getCurrencySymbol } from "../../utils/common";

export const useAsyncCurrency = () => {
  const { customer } = useCustomerContext();
  const customerCurrency = CurrencyCodes[customer.settings?.currency || CurrencyCodes.USD];

  const customerCurrencySymbol = getCurrencySymbol(customerCurrency);

  const asyncConvertCurrency = useCallback(
    (cost: number, date?: Date) =>
      asyncConvertCurrencyTo(cost, date || new Date(), CurrencyCodes.USD, customerCurrency),
    [customerCurrency]
  );

  return { customerCurrencySymbol, customerCurrency, asyncConvertCurrency } as const;
};

export const useCurrency = () => {
  const { customer } = useCustomerContext();
  const customerCurrency = CurrencyCodes[customer.settings?.currency || CurrencyCodes.USD];

  const customerCurrencySymbol = getCurrencySymbol(customerCurrency);

  return { customerCurrencySymbol, customerCurrency } as const;
};
