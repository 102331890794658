import { type ReactNode, useMemo } from "react";

import { type Breakpoint, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

type UpDownType<T extends string> = `${T}Up` | `${T}Down`;

type HideTypes = {
  [bp in UpDownType<Breakpoint>]?: boolean;
};

type Children = {
  children: ReactNode;
};

const Hide = (props: HideTypes & Children) => {
  const { xsDown, xsUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp } = props;

  const theme = useTheme();
  const memoizedQueries = useMemo(() => {
    const queries: string[] = [];
    const sizesToCheck = [
      { check: xsDown, mediaQuery: () => theme.breakpoints.down("xs") },
      { check: xsUp, mediaQuery: () => theme.breakpoints.up("xs") },
      { check: smDown, mediaQuery: () => theme.breakpoints.down("sm") },
      { check: smUp, mediaQuery: () => theme.breakpoints.up("sm") },
      { check: mdDown, mediaQuery: () => theme.breakpoints.down("md") },
      { check: mdUp, mediaQuery: () => theme.breakpoints.up("md") },
      { check: lgDown, mediaQuery: () => theme.breakpoints.down("lg") },
      { check: lgUp, mediaQuery: () => theme.breakpoints.up("lg") },
      { check: xlDown, mediaQuery: () => theme.breakpoints.down("xl") },
      { check: xlUp, mediaQuery: () => theme.breakpoints.up("xl") },
    ];
    sizesToCheck.forEach((query) => {
      if (query.check) {
        queries.push(query.mediaQuery().replace("@media ", ""));
      }
    });
    return queries;
  }, [xsDown, xsUp, smDown, smUp, mdDown, mdUp, lgDown, lgUp, xlDown, xlUp, theme.breakpoints]);

  const hidden = useMediaQuery(() =>
    memoizedQueries.length > 0 ? `@media ${memoizedQueries.join(" , ")}` : "@media not"
  );
  return !hidden ? <>{props.children}</> : null;
};

export default Hide;
