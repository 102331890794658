import { useMemo } from "react";

import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

export function useCheckFlexsaveEnabled(customerId: string): [{ AWS: boolean; GCP: boolean }, boolean] {
  const ref = useMemo(
    () => getCollection(IntegrationModel).doc("flexsave").collection("configuration").doc(customerId),
    [customerId]
  );

  const [doc] = useDocumentData(ref);

  return useMemo(() => {
    if (doc) {
      return [
        {
          AWS: Boolean(doc?.AWS?.enabled),
          GCP: Boolean(doc?.GCP?.enabled),
        },
        false,
      ];
    }
    return [{ AWS: false, GCP: false }, true];
  }, [doc]);
}
