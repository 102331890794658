import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { Box, Button, Container, Stack, Typography } from "@mui/material";

import emptyStateDark from "../../assets/entities/empty-state-darkmode.svg";
import emptyStateLight from "../../assets/entities/empty-state-lightmode.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useCustomerContext } from "../../Context/CustomerContext";

export const cyIds = {
  emptyState: "entitiesEmptyState",
  logo: {
    dark: "entitiesEmptyState-logoDark",
    light: "entitiesEmptyState-logoLight",
  },
  title: "entitiesEmptyState-title",
  bodyText: "entitiesEmptyState-caption",
  button: "entitiesEmptyState-button",
};

const logoInfo: Record<"dark" | "light", { src: string; cyId: string }> = {
  dark: {
    src: emptyStateDark,
    cyId: cyIds.logo.dark,
  },
  light: {
    src: emptyStateLight,
    cyId: cyIds.logo.light,
  },
};

const EntitiesEmptyState = () => {
  const dark = useDarkThemeCheck();
  const history = useHistory();
  const { customer } = useCustomerContext();

  const themeMode = dark ? "dark" : "light";

  const navigateToCreation = useCallback(() => {
    history.push(`/customers/${customer.id}/entities`);
  }, [customer.id, history]);

  return (
    <Container maxWidth="sm">
      <Stack gap={2} alignItems="center" pt={11} data-cy={cyIds.emptyState}>
        <Box
          component="img"
          src={logoInfo[themeMode].src}
          aria-hidden
          alt-text="Billing profiles empty state logo"
          sx={{ width: "105px", height: "105px" }}
          data-cy={logoInfo[themeMode].cyId}
        />
        <Typography variant="h1" data-cy={cyIds.title} sx={{ mt: 2 }}>
          Payment information
        </Typography>
        <Typography variant="body1" data-cy={cyIds.bodyText} align="center">
          To get started with our platform, you will need to create a billing profile and provide us with your payment
          information.
        </Typography>
        <Button variant="contained" onClick={navigateToCreation} data-cy={cyIds.button} sx={{ mt: 2 }}>
          Create billing profile
        </Button>
      </Stack>
    </Container>
  );
};

export default EntitiesEmptyState;
