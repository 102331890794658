import {
  type AnalyticsDataSource,
  type CalcMetricFormat,
  type CurrencyCode,
  type Feature,
  type ForecastSettings,
  type LimitAggregation,
  type Metric,
  type MetricFilter,
  type ReportFilter,
  type ReportMode,
} from "@doitintl/cmp-models";
import { type AxiosHeaders, type AxiosInstance, type GenericAbortSignal, type Method } from "axios";

import { httpMethods } from "../../../assets/texts";
import { type SplitForServer } from "../../../Components/SplitCostModal/types";
import { type AttributionGroupsPayload } from "../attributionGroups/attributionGroupsPayload";
import { type AttributionsPayload } from "../previewReport/utils";
import { type DataRecord } from "../ReportData";
import { type TimeSettings } from "../utilities";

export type QueryRequest = {
  accounts: string[];
  attributionGroups?: AttributionGroupsPayload[];
  attributions?: AttributionsPayload[];
  calculatedMetric: CalculatedMetricPayload | null;
  cloudProviders: string[] | null;
  cols: DataRecord[];
  comparative?: string | null;
  count?: string | null;
  currency: CurrencyCode | null;
  dataSource?: AnalyticsDataSource;
  extendedMetric?: string;
  excludePartialData?: boolean;
  filters: ReportFilter[];
  forecast?: boolean;
  forecastSettings?: ForecastSettings | null;
  id: string;
  includeCredits?: boolean;
  limitAggregation?: LimitAggregation;
  logScale?: boolean;
  metric?: Metric;
  metricFilters?: MetricFilter[] | null;
  mode?: ReportMode;
  noAggregate?: boolean;
  rows: DataRecord[];
  signal?: GenericAbortSignal;
  splits?: SplitForServer[];
  timeSettings?: TimeSettings;
  timezone?: string;
  trends?: Feature[] | null;
  type: QueryType;
  channelId?: string;
};

export type CalculatedMetricPayload = {
  formula: string;
  format?: CalcMetricFormat;
  variables: {
    metric: Metric;
    attribution: AttributionsPayload;
  }[];
  percentage?: boolean;
};

export enum QueryType {
  REPORT = "report",
  METRIC = "metric",
  ATTRIBUTION = "attribution",
  ATTRIBUTION_GROUP = "preview", // todo: refine type here inside useQueryRun
  TEMPLATE = "template",
}

export type QueryResponse = {
  config: any;
  data: QueryResponseData;
  status: number;
  statusText: string;
  request: XMLHttpRequest;
  headers: AxiosHeaders;
};

export type QueryResponseData = {
  details?: QueryResponseDetails;
  error: string | QueryResponseError | null;
  forecastRows?: any[][] | null;
  rows?: any[][] | null;
};

type QueryResponseError = {
  code: string;
  message: string;
  status: number;
};

export type QueryResponseDetails = {
  aggregation: string;
  biEngineMode: string;
  billingTier: number;
  cacheHit: boolean;
  duration: number;
  durationMs: number;
  serverDurationMs: number;
  endTime: string;
  jobId: string;
  onlineQuery: boolean;
  slotMillis: number;
  startTime: string;
  totalBytesBilled: number;
  totalBytesProcessed: number;
  totalRows: number;
  chartThresholdExceeded: boolean;
};

export function executeQueryRequest(
  api: AxiosInstance,
  customerId: string,
  request: QueryRequest
): Promise<QueryResponse> {
  let routeSuffix = "";

  switch (request.type) {
    case QueryType.REPORT:
      routeSuffix = `analytics/reports/${request.id}/query`;
      break;
    case QueryType.METRIC:
      routeSuffix = "analytics/metrics/preview";
      break;
    case QueryType.ATTRIBUTION:
      routeSuffix = "analytics/attributions/preview";
      break;
    case QueryType.ATTRIBUTION_GROUP:
      routeSuffix = "analytics/preview";
  }

  return api.request({
    method: httpMethods.POST as Method,
    url: `/v1/customers/${customerId}/${routeSuffix}`,
    data: request,
    signal: request.signal,
  });
}

export const cancelQuery = (api: AxiosInstance, customerId: string, reportId: string, channelId: string) =>
  api.request({
    method: httpMethods.POST as Method,
    url: `/v1/customers/${customerId}/analytics/reports/${reportId}/query/cancel`,
    data: { channelId },
  });
