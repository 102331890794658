import { Card, darken, lighten } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { ThemeModes } from "../../../muiThemeTypes";
import { type AggregatedDataObjType } from "../RampPlan/getRampDataArrays";

type performanceChartOverrideProps = {
  cardProps: any;
  options: any;
};

type Props = {
  actualDataForChart: AggregatedDataObjType[];
  planDataForChart: AggregatedDataObjType[];
  originalPlanDataForChart: AggregatedDataObjType[] | null;
  performanceChartProps: performanceChartOverrideProps;
  enableAnimations?: boolean;
};

const PerformanceChart = ({
  actualDataForChart,
  planDataForChart,
  originalPlanDataForChart,
  performanceChartProps,
  enableAnimations = true,
}: Props) => {
  const theme = useTheme();
  actualDataForChart.forEach((element) => {
    if (element.currentMonthActual === 0) {
      element.y = null;
    }
  });

  const hidePlanReachedLabel =
    actualDataForChart.filter((data) => data.id === "actualTargetReached").length > 0 ||
    planDataForChart[planDataForChart.length - 1]?.id === "planTargetReached";

  const { cardProps, options } = performanceChartProps;
  const optionsOverride = {
    chart: options.chart ?? {},
  };

  return (
    <Card {...cardProps} mt={2}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate={true}
        options={{
          chart: {
            height: 320,
            backgroundColor: theme?.palette?.background.paper,
            ...optionsOverride.chart,
          },
          exporting: {
            enabled: false,
          },
          title: {
            text: "",
          },
          xAxis: {
            left: 38,
            type: "datetime",
            labels: {
              style: {
                color: theme?.palette?.text?.primary,
              },
            },
            accessibility: {
              rangeDescription: "",
            },
          },
          yAxis: {
            title: {
              text: "",
            },
            labels: {
              style: {
                color: theme?.palette?.text?.primary,
              },
            },
          },
          tooltip: {
            shared: true,
            pointFormat: `<span style="color:{point.color}">\u25CF<b> {series.name}</b></span> : {point.y}<br/>`,
            valuePrefix: "$",
            valueDecimals: 0,
          },
          credits: {
            enabled: false,
          },
          plotOptions: {
            series: {
              animation: enableAnimations,
            },
            area: {
              fillOpacity: 0.35,
            },
          },
          annotations: [
            {
              visible: !hidePlanReachedLabel,
              labels: [
                {
                  point: "planTargetReached",
                  text: "Est. Ramp Complete",
                },
              ],
            },
            {
              labels: [
                {
                  point: "actualTargetReached",
                  text: "Ramp Target Reached",
                },
              ],
            },
          ],
          series: [
            {
              name: "Actual",
              data: actualDataForChart,
              color: theme.palette.info.light,
              type: "area",
            },
            {
              name: "Plan",
              data: planDataForChart,
              type: "line",
              dashStyle: "Dash",
              color: theme.palette.success.main,
              lineWidth: 1,
            },
            {
              name: "Contract Commitment",
              data: originalPlanDataForChart,
              type: "line",
              color:
                theme.palette.mode === ThemeModes.LIGHT
                  ? lighten(theme.palette.text.disabled, 0.2)
                  : darken(theme.palette.text.disabled, 0.4),
            },
          ],
          legend: {
            layout: "horizontal",
            enabled: true,
            itemStyle: {
              color: theme.palette.text.secondary,
              fontWeight: 400,
            },
          },
        }}
      />
    </Card>
  );
};

export default PerformanceChart;
