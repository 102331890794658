import { type PerkPlatform, type PerkSolutionType, type PerkType, PerkTypes } from "@doitintl/cmp-models";

export const platformDefaultFilter: Record<PerkPlatform, boolean> = {
  "amazon-web-services": false,
  "google-cloud": false,
  "g-suite": false,
  "microsoft-azure": false,
};

export const solutionDefaultFilter: Record<PerkSolutionType, boolean> = {
  analytics: false,
  "big-data": false,
  "developer-tools": false,
  "security-and-monitoring": false,
  networking: false,
  "ml-and-ai": false,
  database: false,
  compute: false,
  "blog-and-cms": false,
  "data-sources": false,
  "operating-systems": false,
  storage: false,
  maps: false,
  "advertising-and-gtm": false,
  other: false,
};

// Suppressing deprecation warning because type-safety requires that these fields be present.
export const perkTypeDefaultFilter: Record<PerkType, boolean> = {
  [PerkTypes.Accelerator]: false,
  [PerkTypes.Training]: false,
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.Promotion]: false,
  // eslint-disable-next-line deprecation/deprecation
  [PerkTypes.ProServ]: false,
  [PerkTypes.IsvSolution]: false,
  [PerkTypes.Workshop]: false,
};

export const styles = {
  filterTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: "text.primary",
    lineHeight: "24px",
    "&.Mui-focused": {
      color: "text.primary",
    },
    paddingBottom: 1,
  },
  checkbox: {
    "& .MuiSvgIcon-root": {
      fontSize: 24,
    },
  },
  formControlLabel: {
    whiteSpace: "nowrap",
  },
  formGroup: {
    whiteSpace: "nowrap",
  },
  chipBox: {
    display: "flex",
    width: "200px",
    justifyContent: "left",
    flexWrap: "wrap",
    gap: "5px",
    "& > *": {
      flex: "0 0 auto",
    },
  },
};
