import { useEffect, useRef, useState } from "react";

import { Box, TextField, useTheme } from "@mui/material";

const styles = {
  titleInput: {
    maxWidth: "100%",
    minWidth: 10,
    width: { xs: 1, md: "auto" },

    ".MuiOutlinedInput-root": {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "general.outlineBorder",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary.main",
      },
    },
  },
  inputText: {
    position: "absolute",
    opacity: 0,
    zIndex: "-100",
    whiteSpace: "pre",
    maxWidth: "100%",
  },
};

type Props = {
  title?: string;
  placeholder: string;
  disabled?: boolean;
  onUpdateTitle: (name: string) => void;
};

const Title = ({ title, placeholder, onUpdateTitle, disabled }: Props) => {
  const [inputWidth, setInputWidth] = useState(1);
  const inputTextRef = useRef<HTMLElement>();
  const theme = useTheme();

  useEffect(() => {
    const offsetWidth = inputTextRef.current?.offsetWidth;
    setInputWidth(!offsetWidth ? 5 : offsetWidth + 1);
  }, [title, inputTextRef.current?.offsetWidth]);

  return (
    <TextField
      name="title"
      variant="outlined"
      value={title}
      disabled={disabled}
      placeholder={placeholder}
      onChange={(e) => {
        onUpdateTitle(e.target.value);
      }}
      sx={{
        ...styles.titleInput,
        ".MuiOutlinedInput-input": {
          width: { xs: inputWidth },
          fontWeight: theme.typography.h4.fontWeight,
        },
      }}
      InputProps={{
        endAdornment: (
          <Box component="span" ref={inputTextRef} sx={styles.inputText}>
            {title || placeholder}
          </Box>
        ),
      }}
    />
  );
};

export default Title;
