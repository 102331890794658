import { type ReactElement, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAuthContext } from "../../../../../Context/AuthContext";
import AddBillingAccountAdmins, {
  type AddBillingAccountAdminsProps,
} from "../../../Forms/AddBillingAccountAdmins/AddBillingAccountAdmins";
import { useIsThereSignedContract } from "../../../hooks";

export const useStyles = makeStyles((theme) => ({
  stretch: { height: "100%" },
  accordionRoot: {
    width: "100%",
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummary: {
    display: "inline-flex",
    padding: theme.spacing(1, 2),
    width: "100%",
  },
  accordionDetails: {
    paddingLeft: theme.spacing(2),
  },
  iconButton: {
    padding: 6,
  },
}));
type Props = {
  items: string[];
  rowContent: (item) => ReactElement;
  title: string;
  admins: boolean; // if true, the accordion is used for the admins list
  entityId: string | null;
  billingAccountId: string;
  displayName: string;
  setAccordionAdmins?: (admins: string[]) => void;
  defaultExpanded?: boolean;
};

export const AssetPageAccordion = ({
  items,
  rowContent,
  title,
  admins,
  entityId,
  billingAccountId,
  displayName,
  setAccordionAdmins,
  defaultExpanded,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded ?? false);
  const [addAdminsOpen, setAddAdminsOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { currentUser, isDoitEmployee } = useAuthContext();
  const { isLoadingContracts, isThereSignedContract } = useIsThereSignedContract({
    entityId,
    typeFilter: ["google-cloud", "looker", "google-geolocation-services"],
  });
  const itemsList = items?.map((a) => a && <Box key={a}>{rowContent(a)}</Box>);

  const email = currentUser?.email ?? (null as unknown as string);
  const canAddNewAdmin = isDoitEmployee || items.includes(`user:${email}`);

  let tooltipTitle: string;
  if (canAddNewAdmin) {
    tooltipTitle = "";
  } else {
    tooltipTitle = !isThereSignedContract
      ? "Finalize your contract with DoiT to add admins"
      : "You must be an admin to add new admins";
  }

  const addNewButton = admins ? (
    <Stack direction="row" justifyContent="end">
      <Tooltip title={tooltipTitle} arrow placement="bottom">
        <span>
          <Button
            data-cy="addNewAdminBtn"
            sx={{ textTransform: "unset" }}
            disabled={!canAddNewAdmin}
            onClick={() => {
              setAddAdminsOpen(true);
            }}
            startIcon={<AddIcon fontSize="small" />}
          >
            <Typography variant="body2" color={canAddNewAdmin ? "primary" : "InactiveCaptionText"} fontWeight={500}>
              Add new admin
            </Typography>
          </Button>
        </span>
      </Tooltip>
    </Stack>
  ) : null;

  /**
   *
   * @param emailsToAdd - emails to add to the list of admins (if any). for example: test@doit.com
   * @param membersToRemove - members to remove from the list of admins (if any). for example: user:test@doit.com
   */
  function updateUiAdmins({ emailsToAdd, membersToRemove }: { emailsToAdd?: string[]; membersToRemove?: string[] }) {
    const updatedValues = (items || [])
      .concat(emailsToAdd?.map((elem) => `user:${elem}`) || [])
      .filter((elem) => !membersToRemove?.includes(elem));
    setAccordionAdmins?.(updatedValues);
  }

  const billingAccountAdminProps: AddBillingAccountAdminsProps = {
    onClose: () => {
      setAddAdminsOpen(false);
    },
    entityId,
    billingAccountId,
    displayName,
    updateUiAdmins,
    adminUsers: items,
    isLoadingContracts,
    isThereSignedContract,
  };

  return (
    <Accordion
      elevation={0}
      classes={{ root: classes.accordionRoot }}
      expanded={expanded}
      onChange={() => {
        setExpanded(!expanded);
      }}
      TransitionProps={{ unmountOnExit: true }}
      disableGutters
      defaultExpanded={defaultExpanded}
      data-cy="accordionAssets"
    >
      <AccordionSummary
        classes={{ root: classes.accordionSummary }}
        expandIcon={
          <IconButton classes={{ root: classes.iconButton }} aria-label="Show more" size="large">
            <ExpandMoreIcon />
          </IconButton>
        }
        aria-controls="advanced-content"
      >
        <Typography color="textPrimary" variant="body1">
          {title} ({items?.length})
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: 500, overflow: "auto" }} classes={{ root: classes.accordionDetails }}>
        {addAdminsOpen && <AddBillingAccountAdmins {...billingAccountAdminProps} />}
        {addNewButton}
        {itemsList}
      </AccordionDetails>
    </Accordion>
  );
};
