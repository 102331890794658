import { type JSX } from "react";

import { Box, Container, Grid } from "@mui/material";

type Props = {
  rightSideContent: JSX.Element;
  height?: string;
  children: JSX.Element;
};

export const SideContentLayout = ({ children, rightSideContent }: Props) => (
  <Container maxWidth="lg">
    <Grid
      px={1}
      container
      alignItems="center"
      sx={{
        justifyContent: { xs: "center", md: "space-between" },
        gap: { xs: 6 },
      }}
    >
      <Grid item xl={5} lg={6} md={6}>
        {children}
      </Grid>
      <Grid item ml={{ lg: 4, md: 4 }}>
        <Box width={{ sm: "100%" }} height={{ sm: "100%" }} p={{ lg: 5 }}>
          {rightSideContent}
        </Box>
      </Grid>
    </Grid>
  </Container>
);
