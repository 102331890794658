import TableCell from "@mui/material/TableCell";
import { DateTime } from "luxon";

type Props = {
  dateTime: DateTime;
};

export const DateTimeCell = ({ dateTime }: Props) => (
  <TableCell>{dateTime.toLocaleString(DateTime.DATETIME_MED)}</TableCell>
);
