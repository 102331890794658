import { useState } from "react";

import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import useMountEffect from "../../../../Components/hooks/useMountEffect";
import { consoleErrorWithSentry } from "../../../../utils";
import { useCredentials } from "../../../../utils/sauron";

type Props = {
  accountId: string;
  open: boolean;
  onClose: () => void;
};
export const SupportCLICredentials = ({ accountId, open, onClose }: Props) => {
  const [accessKeyId, setAccessKeyId] = useState<string>("");
  const [secretAccessKey, setSecretAccessKey] = useState<string>("");
  const [sessionToken, setSessionToken] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const getCredentials = useCredentials(accountId);

  useMountEffect(async () => {
    try {
      const credentials = await getCredentials();
      setAccessKeyId(credentials.sessionId);
      setSecretAccessKey(credentials.sessionKey);
      setSessionToken(credentials.sessionToken);
      setLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      consoleErrorWithSentry(e);
    }
  });

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle data-cy="title">Support CLI credentials</DialogTitle>
      <DialogContent>
        {loading ? (
          <Alert severity="info" data-cy="checkCredentials">
            <AlertTitle>
              Checking Sauron credentials
              <CircularProgress color="inherit" size={18} sx={{ ml: 1 }} />
            </AlertTitle>
            We are currently checking if the account is both present and configured in Sauron.
          </Alert>
        ) : (
          error && (
            <Alert severity="error" data-cy="error">
              <AlertTitle>Unable to retrieve Sauron credentials</AlertTitle>
            </Alert>
          )
        )}

        {!loading && !error && (
          <>
            <Typography color="textSecondary" data-cy="copy">
              You can now copy the credentials below for easy CLI access
            </Typography>
            <CopyCodeBlock
              base={`export AWS_ACCESS_KEY_ID=${accessKeyId}\nexport AWS_SECRET_ACCESS_KEY=${secretAccessKey}\nexport AWS_SESSION_TOKEN=${sessionToken}`}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            handleClose(e);
          }}
          data-cy="close"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            handleClose(e);
          }}
          color="primary"
          variant="contained"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
