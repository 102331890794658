import { useMemo } from "react";

import { type Renderer } from "@doitintl/cmp-models";
import { useTheme } from "@mui/material/styles";
import type Highcharts from "highcharts";

import { getColors } from "../../Pages/CloudAnalytics/utilities";

export type ChartCardType =
  | "column_and_line"
  | Exclude<
      `${Renderer}`,
      | "table_heatmap"
      | "table_row_heatmap"
      | "table_col_heatmap"
      | "sheets_export"
      | "csv_export"
      | "csv_clipboard"
      | "pdf_download"
      | "png_download"
    >;

export function useChartCardOptions(type: ChartCardType) {
  const {
    palette: { mode, background },
  } = useTheme();
  const colors = getColors(mode);

  const seriesMapping = useMemo(
    () => ({
      area_spline_chart: [
        {
          type: "area",
          data: [1, 2, 1, 3, 4, 5, 4, 2, 3, 4, 1, 3],
          stacking: "normal",
        },
        {
          type: "area",
          data: [3, 4, 5, 2, 4, 2, 5, 1, 2, 5, 6, 7],
          stacking: "normal",
        },
      ],
      stacked_area_chart: [
        {
          type: "area",
          data: [1, 2, 1, 3, 4, 5, 4, 2, 3, 4, 1, 3],
          stacking: "normal",
        },
        {
          type: "area",
          data: [3, 4, 5, 2, 4, 2, 5, 1, 2, 5, 6, 7],
          stacking: "normal",
        },
      ],
      stacked_column_chart: [
        {
          type: "column",
          data: [2, 2, 4, 5, 4, 5, 2, 1],
          borderWidth: 0,
          stacking: "normal",
        },
        {
          type: "column",
          data: [2, 3, 4, 1, 4, 3, 2, 4],
          borderWidth: 0,
          stacking: "normal",
        },
      ],
      stacked_bar_chart: [
        {
          type: "bar",
          data: [2, 2, 4, 5, 4],
          stacking: "normal",
        },
        {
          type: "bar",
          data: [2, 3, 4, 1, 4],
          stacking: "normal",
        },
      ],
      column_chart: [
        {
          type: "column",
          data: [2, 3, 4, 5, 4, 3, 2, 1],
          borderWidth: 0,
        },
      ],
      line_chart: [
        {
          type: "line",
          data: [3, 4, 1, 3, 2, 2, 3, 0],
          enableMouseTracking: false,
        },
        {
          type: "line",
          data: [3, 1, 5, 4, 4, 1, 7, 5],
          enableMouseTracking: false,
        },
      ],
      column_and_line: [
        {
          type: "column",
          data: [2, 3, 4, 5, 4, 3, 2, 1],
          borderWidth: 0,
        },
        {
          type: "line",
          data: [3, 2, 1, 3, 4, 2, 1, 0],
          dashStyle: "Dash",
          enableMouseTracking: false,
        },
      ],
      area_chart: [
        {
          type: "area",
          data: [1, 2, 1, 3, 4, 5, 4, 2, 3, 4, 1, 3],
        },
        {
          type: "area",
          data: [3, 4, 5, 2, 4, 2],
        },
      ],
      treemap_chart: [
        {
          type: "treemap",
          levels: [
            {
              level: 1,
              borderWidth: 1,
              borderColor: background.paper,
              dataLabels: {
                enabled: false,
              },
            },
          ],
          data: [6, 6, 4, 4, 3, 2, 2].map((v, i) => ({
            name: i,
            value: v,
            color: colors[i],
          })),
        },
      ],
    }),
    [background.paper, colors]
  );

  return useMemo(() => {
    const options: Highcharts.Options = {
      chart: {
        borderWidth: 0,
        backgroundColor: "transparent",
        height: 160,
        spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
      },
      plotOptions: {
        series: {
          enableMouseTracking: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      tooltip: {
        enabled: false,
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      colors,
      series: seriesMapping[type],
    };

    return options;
  }, [colors, seriesMapping, type]);
}
