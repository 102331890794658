import { useCallback, useEffect, useMemo, useReducer } from "react";

import StoryblokClient from "storyblok-js-client";

import { isPartnerDomain } from "../domains";
import { consoleErrorWithSentry } from "../errorHandling";
import { useUtmParams } from "../utmParams/hooks";
import { type StoryblokContentType, type StoryblokStory } from "./types";

type StoryblokHookState = {
  isLoading: boolean;
  isError: boolean;
  data: null | StoryblokStory;
};

type StoryblokHookAction =
  | {
      type: "initLoading" | "failLoading";
    }
  | {
      type: "successLoading";
      data: StoryblokStory;
    };

export const storyblokReducer = (state: StoryblokHookState, action: StoryblokHookAction): StoryblokHookState => {
  switch (action.type) {
    case "initLoading":
      return { ...state, isLoading: true };
    case "successLoading":
      return { ...state, isLoading: false, data: action.data };
    case "failLoading":
      return { ...state, isLoading: false, data: null, isError: true };
  }
};

const createStoryblokApiInstance = () =>
  new StoryblokClient({
    accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
    https: true,
  });

/**
 * Hook for fetching stories from Storyblok Headless CMS
 */
const useStoryblok = (type: StoryblokContentType, contentPath: string, preventFetching = false) => {
  const [state, dispatch] = useReducer(storyblokReducer, {
    isLoading: false,
    isError: false,
    data: null,
  });
  const { utm_campaign = "" } = useUtmParams();
  const instance = useMemo(createStoryblokApiInstance, []);
  let partnerDomain = "";
  const hostname = window.location.hostname;
  if (isPartnerDomain(hostname)) {
    partnerDomain = hostname;
  }
  const getEntities = useCallback(async () => {
    dispatch({ type: "initLoading" });

    try {
      const response = await instance.get("cdn/spaces/me");
      const storiesResponse = await instance.get("cdn/stories", {
        cv: response.data.space.version,
        version: "published",
        sort_by: "position:desc", // Fresh stories first
        starts_with: contentPath,
        filter_query: {
          env: {
            like: process.env.NODE_ENV,
          },
          type: {
            like: type,
          },
          utm_campaign: { like: utm_campaign },
          partner_domain: { like: partnerDomain },
        },
      });

      const story = storiesResponse.data.stories[0];

      if (story) {
        dispatch({ type: "successLoading", data: story });
      } else {
        dispatch({ type: "failLoading" });
      }
    } catch (e) {
      consoleErrorWithSentry(e);
      dispatch({ type: "failLoading" });
    }
  }, [contentPath, instance, type, utm_campaign, partnerDomain]);

  useEffect(() => {
    !state.data && !state.isError && !preventFetching && getEntities();
  }, [state.data, state.isError, getEntities, preventFetching]);

  return state;
};

export default useStoryblok;
