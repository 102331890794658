import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { cloudflowTexts } from "../../../assets/texts";

type DeleteCloudFlowDialogProps = {
  isDialogOpened: boolean;
  handleClose: () => void;
  handleDelete: () => void;
};

const DeleteCloudFlowDialog = ({ isDialogOpened, handleClose, handleDelete }: DeleteCloudFlowDialogProps) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));

  return (
    <Dialog open={isDialogOpened} onClose={handleClose} fullScreen={fullScreen} fullWidth>
      <DialogTitle>{cloudflowTexts.DELETE_CLOUDFLOW}</DialogTitle>
      <DialogContent>
        <DialogContentText>{cloudflowTexts.CLOUDFLOW_DELETE_DIALOG_TEXT}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          {cloudflowTexts.CANCEL}
        </Button>
        <Button onClick={handleDelete} variant="contained" color="error">
          {cloudflowTexts.DELETE_CLOUDFLOW}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCloudFlowDialog;
