import { useEffect, useState } from "react";

import { generatePath } from "react-router";
import { Roles } from "@doitintl/cmp-models";

import { attributionGroupsText, reportText } from "../../../assets/texts";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { useCustomMetrics } from "../../../Components/hooks/cloudAnalytics/useCustomMetrics";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useDeleteHandler, useShareHandler, useThreeDotsAlertsMenu } from "../alerts/hooks";
import {
  useAttributionGroupShareHandler,
  useAttributionGroupThreeDotsMenu,
  useDeleteAttributionGroupHandler,
} from "../attributionGroups/hooks";
import { useDeleteAttributionRowAdapter } from "../attributions/DeleteAttributionValidation";
import { useAttributionsThreeDotsMenu, useShareAttributionHandler } from "../attributions/hooks";
import { useBudgetThreeDotsMenu, useDeleteBudgetHandler, useShareBudgetHandler } from "../budgets/hooks";
import { useThreeDotsMetricsMenu } from "../metrics/hooks";
import { useDeleteReportsHandler, useReportsThreeDotsMenu, useShareReportsHandler } from "../reports/hooks";
import { CloudAnalyticsEntities } from "../utilities";
import { useDialogDispatchContext } from "./Context";
import {
  type AnalyticsResources,
  type AnalyticsResourcesAlerts,
  type AnalyticsResourcesAttributionGroups,
  type AnalyticsResourcesAttributions,
  type AnalyticsResourcesBrowserProps,
  type AnalyticsResourcesBudgets,
  type AnalyticsResourcesReports,
  type CloudAnalyticsResourceType,
  type ThreeDotsMenuByType,
} from "./types";
import {
  alertsToAnalyticsResources,
  attributionGroupsToAnalyticsResources,
  attributionsToAnalyticsResources,
  budgetsToAnalyticsResources,
  cloudAnalyticsResourceTypes,
  metricsToAnalyticsResources,
  reportsToAnalyticsResources,
} from "./utils";

export const useFormatLink = ({ type, id }: { type: CloudAnalyticsResourceType; id?: string }): string => {
  const customerId = useCustomerId();
  return generatePath(cloudAnalyticsResourceTypes[type].urlMask, {
    customerId,
    id,
  });
};

export const useCloudAnalyticsResources = ({
  reports,
  attributions,
  attributionGroups,
  alerts,
}: AnalyticsResourcesBrowserProps): AnalyticsResources[] => {
  const [analyticsResources, setAnalyticsResources] = useState<AnalyticsResources[]>([]);

  const [_allBudgets, budgets, _budgetsLoading] = useBudgets();
  const [metrics, _metricsLoading] = useCustomMetrics();

  useEffect(() => {
    setAnalyticsResources([
      ...alertsToAnalyticsResources(alerts),
      ...attributionGroupsToAnalyticsResources(attributionGroups),
      ...budgetsToAnalyticsResources(budgets),
      ...attributionsToAnalyticsResources(attributions),
      ...reportsToAnalyticsResources(reports),
      ...metricsToAnalyticsResources(metrics),
    ]);
  }, [alerts, attributionGroups, attributions, budgets, metrics, reports]);

  return analyticsResources;
};

export const useThreeDotsMenusByType = (
  { row }: { row: AnalyticsResources },
  userEmailNotification,
  updateUserEmailNotification,
  budgetsTierLimitReached,
  agTierLimitReached
): ThreeDotsMenuByType => {
  const dispatch = useDialogDispatchContext();
  const alertThreeDotMenu = useThreeDotsAlertsMenu({
    row,
    handleDelete: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "delete",
          dialogTitle: "Delete alert",
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.ALERT,
        },
      });
    },
    handleShare: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "share",
          dialogTitle: "Share alert",
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.ALERT,
        },
      });
    },
  });
  const metricsThreeDotMenu = useThreeDotsMetricsMenu({ row });
  const budgetThreeDotMenu = useBudgetThreeDotsMenu({
    row: row as AnalyticsResourcesBudgets,
    handleDelete: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "delete",
          dialogTitle: "Delete budget",
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.BUDGET,
        },
      });
    },
    handleShare: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "share",
          dialogTitle: "Share budget",
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.BUDGET,
        },
      });
    },
    tierLimitReached: budgetsTierLimitReached,
  });
  const attributionsThreeDotMenu = useAttributionsThreeDotsMenu({
    row: row as AnalyticsResourcesAttributions,
    handleShare: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "share",
          dialogTitle: "Share attribution",
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.ATTRIBUTION,
          additionalDialogProps: {
            availableRoles: [Roles.EDITOR, Roles.VIEWER],
            defaultRole: Roles.EDITOR,
          },
        },
      });
    },
    handleSettings: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "settings",
          dialogTitle: "Settings",
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.ATTRIBUTION,
          additionalDialogProps: {
            availableRoles: [Roles.EDITOR, Roles.VIEWER],
            defaultRole: Roles.EDITOR,
          },
        },
      });
    },
    userEmailNotification,
    updateUserEmailNotification,
  });
  const attributionsGroupThreeDotsMenu = useAttributionGroupThreeDotsMenu({
    row: row as AnalyticsResourcesAttributionGroups,
    handleShare: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "share",
          dialogTitle: attributionGroupsText.SHARE_ATTRIBUTION_GROUP,
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.ATTRIBUTION_GROUP,
        },
      });
    },
    handleDelete: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "delete",
          dialogTitle: attributionGroupsText.DELETE_SELECTED,
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.ATTRIBUTION_GROUP,
        },
      });
    },
    tierLimitReached: agTierLimitReached,
  });

  const reportsThreeDotMenu = useReportsThreeDotsMenu({
    row: row as AnalyticsResourcesReports,
    reportId: row.id,
    shareHandler: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "share",
          dialogTitle: reportText.SHARE_PERMISSIONS,
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.REPORT,
          additionalDialogProps: {
            organization: (row as AnalyticsResourcesReports).data.organization,
          },
        },
      });
    },
    deleteHandler: () => {
      dispatch({
        type: "open",
        payload: {
          dialogType: "delete",
          dialogTitle: reportText.DELETE_SELECTED,
          clickedRow: row,
          cloudResourceType: CloudAnalyticsEntities.REPORT,
        },
      });
    },
  });

  return {
    alert: alertThreeDotMenu,
    metric: metricsThreeDotMenu,
    attribution_group: attributionsGroupThreeDotsMenu,
    attribution: attributionsThreeDotMenu,
    budget: budgetThreeDotMenu,
    report: reportsThreeDotMenu,
  };
};

export const useShareHandlerByType = ({ row }: { row: AnalyticsResources | null }) => {
  const dispatch = useDialogDispatchContext();
  const shareAlerts = useShareHandler({
    clickedRow: row,
    selected: [],
    setLoading: (isLoading: boolean) => {
      dispatch({ type: "changeLoading", payload: { isLoading } });
    },
    closeDialog: () => {
      dispatch({ type: "close" });
    },
  });
  const shareBudgets = useShareBudgetHandler({
    selected: [row],
    closeDialog: () => {
      dispatch({ type: "close" });
    },
    setLoading: (isLoading: boolean) => {
      dispatch({ type: "changeLoading", payload: { isLoading } });
    },
  });
  const shareAttributions = useShareAttributionHandler({
    attributions: row
      ? [
          {
            collaborators: (row as AnalyticsResourcesAttributions).data.collaborators,
            public: (row as AnalyticsResourcesAttributions).data.public,
            id: row.id,
            name: row.data.name,
          },
        ]
      : [],
    closeDialog: () => {
      dispatch({ type: "close" });
    },
    setShareLoading: (isLoading: boolean) => {
      dispatch({ type: "changeLoading", payload: { isLoading } });
    },
  });
  const shareAttributionGroups = useAttributionGroupShareHandler({
    attributionGroups: row
      ? [
          {
            name: row.data.name,
            collaborators: (row as AnalyticsResourcesAttributionGroups).data.collaborators,
            public: (row as AnalyticsResourcesAttributionGroups).data.public,
            id: row.id,
            type: row.type,
          },
        ]
      : [],
    setShareLoading: (isLoading) => {
      dispatch({ type: "changeLoading", payload: { isLoading } });
    },
    onClose: () => {
      dispatch({ type: "close" });
    },
  });

  const shareReport = useShareReportsHandler({
    selected: [row] as AnalyticsResourcesReports[],
    closeDialog: () => {
      dispatch({ type: "close" });
    },
    setShareDialogLoading: (isLoading) => {
      dispatch({ type: "changeLoading", payload: { isLoading } });
    },
  });

  return {
    alert: shareAlerts,
    budget: shareBudgets,
    attribution: shareAttributions,
    attribution_group: shareAttributionGroups,
    report: shareReport,
  };
};

export const useDeleteByType = ({ row }: { row: AnalyticsResources | null }) => {
  const dispatch = useDialogDispatchContext();
  const deleteAlerts = useDeleteHandler({
    clickedRow: row as AnalyticsResourcesAlerts,
    selected: [],
    closeDialog: () => {
      dispatch({ type: "close" });
    },
  });
  const deleteBudgets = useDeleteBudgetHandler({
    selected: [row] as AnalyticsResourcesBudgets[],
    closeDialog: () => {
      dispatch({ type: "close" });
    },
  });
  const deleteAttributions = useDeleteAttributionRowAdapter({ row });
  const deleteAttributionGroups = useDeleteAttributionGroupHandler({
    row: row as AnalyticsResourcesAttributionGroups,
    selected: [],
    closeDialog: () => {
      dispatch({ type: "close" });
    },
  });
  const deleteReport = useDeleteReportsHandler({
    reports: [row] as AnalyticsResourcesReports[],
    setSelectedReports: () => {
      dispatch({ type: "close" });
    },
  });
  return {
    alert: deleteAlerts,
    budget: deleteBudgets,
    attribution: deleteAttributions,
    attribution_group: deleteAttributionGroups,
    report: deleteReport,
  };
};
