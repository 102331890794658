import { type JSX, useMemo } from "react";

import ProcessingIcon from "@mui/icons-material/AutorenewRounded";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import StarBorderIcon from "@mui/icons-material/StarBorderRounded";
import StarHalfIcon from "@mui/icons-material/StarHalfRounded";
import StarFullIcon from "@mui/icons-material/StarRounded";
import { blue, green, orange, purple, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { useAuthContext } from "../../../Context/AuthContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { type Invoice, type InvoiceStatus } from "../types";
import { getInvoiceStatus, getProcesssinPaymentsSum } from "../utils";

export type RowItem = Invoice & {
  _STATUS: InvoiceStatus;
  _STATUS_ID: number;
  _ICON: JSX.Element;
  _BALANCE: number;
  _SHOW_BADGE: boolean;
  statusClassName: string;
};

const useStyles = makeStyles((theme) => ({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  spinningIcon: {
    animation: `$spin 3s linear infinite`,
  },
  status: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  paidStatus: {
    color: green[500],
  },
  partialStatus: {
    color: purple[500],
  },
  processingStatus: {
    color: blue[500],
  },
  lateStatus: {
    color: red[500],
  },
  latePartialStatus: {
    color: orange[500],
  },
  proformaStatus: {
    color: theme.palette.mode === ThemeModes.DARK ? "#E4A3F5" : purple[500],
  },
  canceledStatus: {
    color: green[500],
  },
}));

export const useInvoiceRow = () => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const classes = useStyles();

  const enrichInvoice = (invoice: Invoice): RowItem => {
    const processingPaymentSum = getProcesssinPaymentsSum(invoice);

    const _BALANCE = Math.round((invoice.DEBIT ?? 0 - processingPaymentSum) * 100) / 100;
    const _SHOW_BADGE = isDoitEmployee && !invoice.PAID && processingPaymentSum > 0;

    const { _STATUS, _STATUS_ID } = getInvoiceStatus(invoice);

    let _ICON = <></>;
    switch (_STATUS_ID) {
      case 1:
        _ICON = <StarFullIcon fontSize="small" />;
        break;
      case 2:
        _ICON = <StarHalfIcon fontSize="small" />;
        break;
      case 3:
        _ICON = <StarBorderIcon fontSize="small" />;
        break;
      case 4:
      case 5:
        _ICON = <StarBorderIcon fontSize="small" />;
        break;
      case 6:
        _ICON = <ProcessingIcon fontSize="small" className={classes.spinningIcon} />;
        break;
      case 7:
        _ICON = <CircleOutlined fontSize="small" />;
        break;
      case 8:
        _ICON = <StarFullIcon fontSize="small" />;
        break;
    }

    const statusClassName = clsx(classes.status, {
      [classes.paidStatus]: _STATUS_ID === 1,
      [classes.partialStatus]: _STATUS_ID === 2,
      [classes.latePartialStatus]: _STATUS_ID === 4,
      [classes.lateStatus]: _STATUS_ID === 5,
      [classes.processingStatus]: _STATUS_ID === 6,
      [classes.proformaStatus]: _STATUS_ID === 7,
      [classes.canceledStatus]: _STATUS_ID === 8,
    });

    return {
      ...invoice,
      _STATUS,
      _STATUS_ID,
      _ICON,
      _BALANCE,
      _SHOW_BADGE,
      statusClassName,
    };
  };

  return { enrichInvoice };
};

export const useInvoiceRows = (invoices: Invoice[]) => {
  const { enrichInvoice } = useInvoiceRow();
  const rows = useMemo((): RowItem[] => invoices.map(enrichInvoice), [enrichInvoice, invoices]);

  return { rows };
};
