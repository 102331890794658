import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, Typography } from "@mui/material";

const cloudFormationURI = "https://go.doit.com/aws-ops-cfn";

type SauronProps = {
  accountId: string;
};

export default function SauronStep({ accountId }: SauronProps) {
  const deployRole = () => {
    window.open(cloudFormationURI, "_blank", "noopener");
  };
  const { t } = useTranslation("services");

  return (
    <Box>
      <Button color="primary" onClick={deployRole} variant="contained" size="small">
        <OpenInNewIcon sx={{ mr: 1 }} />
        {t("SauronStep.deploy")}
      </Button>

      <Typography sx={{ mt: 1 }} component="div" variant="caption">
        {t("SauronStep.grantPermission1")} <strong>{t("SauronStep.grantPermission2")}</strong>{" "}
        {t("SauronStep.grantPermission3")} {accountId}
        {t("SauronStep.grantPermission4")}
      </Typography>
    </Box>
  );
}
