import { useCallback, useMemo } from "react";

import { InviteModel, UserModel } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, type TransformMethod, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type InviteWithRole, type RoleWithPermissions, type UserWithRole } from "../../../types";
import { getCachingKeys } from "../../../utils/cachingKeys";

const prepareUser = (roles: RoleWithPermissions[], usersData: ModelIdRef<UserModel>): UserWithRole => {
  const { roles: userRoles, ...userProps } = usersData;
  return {
    ...userProps,
    roles: userRoles?.[0] ? (roles.find((role) => role.id === userRoles[0].id) ?? null) : null,
  };
};

const prepareInvite = (roles: RoleWithPermissions[], inviteData: ModelIdRef<InviteModel>): InviteWithRole => {
  const { roles: userRoles, ...inviteProps } = inviteData;
  return {
    ...inviteProps,
    roles: userRoles?.[0] ? (roles.find((role) => role.id === userRoles[0].id) ?? null) : null,
  };
};

export const useUsers = (roles: RoleWithPermissions[] | undefined) => {
  const { customer } = useCustomerContext();

  const query = useMemo(() => {
    if (!roles) {
      return;
    }

    return getCollection(UserModel).where("customer.ref", "==", customer.ref);
  }, [roles, customer.ref]);

  const transform: TransformMethod<UserModel, UserWithRole> = useCallback(
    (userData, snapshot) => prepareUser(roles!, { ...userData, id: snapshot.id, ref: snapshot.modelRef }),
    [roles]
  );

  const [users] = useCollectionData(query, { transform, caching: true, cachingKeys: getCachingKeys(customer.id) });

  return { users };
};

export const useInvites = (roles: RoleWithPermissions[] | undefined) => {
  const { customer } = useCustomerContext();

  const query = useMemo(() => {
    if (!roles) {
      return;
    }

    return getCollection(InviteModel).where("customer.ref", "==", customer.ref);
  }, [roles, customer.ref]);

  const transform: TransformMethod<InviteModel, InviteWithRole> = useCallback(
    (inviteData, snapshot) => prepareInvite(roles!, { ...inviteData, id: snapshot.id, ref: snapshot.modelRef }),
    [roles]
  );

  const [invites] = useCollectionData(query, { transform, caching: true, cachingKeys: getCachingKeys(customer.id) });

  return { invites };
};
