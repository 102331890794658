import { type MouseEvent, useCallback, useContext } from "react";

import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, IconButton, Typography } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { cloudReportCardText } from "../../../assets/texts";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { WidgetMenuWrapperContext } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/widgetMenuContext";
import { getDateTimeFromFirestoreTimestamp } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";

type Props = {
  anchorEl: HTMLElement | null;
  handleRefresh: () => void;
  hasAccessToReport: boolean;
  helpUrl: string;
  timeRefreshed?: FirestoreTimestamp;
  loading: boolean;
  open: boolean;
  reportPath: string;
  thisReportHasData: boolean;
  trackFullReport: () => void;
  handleCloseMenu: () => void;
  handleOpenMenu: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const CloudReportCardMenu = ({
  anchorEl,
  handleRefresh,
  hasAccessToReport,
  helpUrl,
  timeRefreshed,
  loading,
  open,
  reportPath,
  thisReportHasData,
  trackFullReport,
  handleCloseMenu,
  handleOpenMenu,
}: Props) => {
  const handleClickOpenHelp = useCallback(() => {
    mixpanel.track("dashboard.widget.help");
  }, []);

  const lastUpdated = getDateTimeFromFirestoreTimestamp(timeRefreshed);

  const [deleteWidgetOperation] = useContext(WidgetMenuWrapperContext);

  const { customer } = useCustomerContext();
  const presentationMode = isCustomerInPresentationMode(customer);

  const onClickMenuItem = useCallback(
    (e) => {
      e.stopPropagation();
      handleCloseMenu();
      handleRefresh();
    },
    [handleCloseMenu, handleRefresh]
  );

  return (
    <>
      <IconButton
        id="widget-menu-button"
        aria-controls="basic-menu"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          handleOpenMenu(e);
          e.stopPropagation();
        }}
        aria-haspopup="true"
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem disabled={loading || presentationMode} onClick={onClickMenuItem}>
          <Box>
            <Typography>{cloudReportCardText.REFRESH_DATA}</Typography>
            {lastUpdated?.isValid && (
              <Typography
                sx={{ fontWeight: 200 }}
                variant="subtitle2"
              >{`Last updated: ${lastUpdated.toRelative()}`}</Typography>
            )}
          </Box>
        </MenuItem>

        {hasAccessToReport &&
          thisReportHasData && ( // ""=== true" to prevent strange output in the menu
            <MenuItem onClick={trackFullReport} component={Link} to={reportPath}>
              {cloudReportCardText.OPEN_REPORT}
            </MenuItem>
          )}

        {Boolean(helpUrl) && thisReportHasData && (
          <MenuItem component="a" href={helpUrl} target="_blank" onClick={handleClickOpenHelp}>
            <Typography display="flex" alignItems="center">
              {cloudReportCardText.OPEN_HELP} <OpenNewIcon sx={{ fontSize: 16, marginLeft: 1 }} />
            </Typography>
          </MenuItem>
        )}

        {deleteWidgetOperation && (
          <MenuItem onClick={deleteWidgetOperation}>
            <Typography color="error.main">Remove widget</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
