import { type CurrencyCode, type Office365AssetModel } from "@doitintl/cmp-models";
import { Button, CardActions, CardContent, Stack } from "@mui/material";
import { type FormikProps } from "formik";
import { DateTime } from "luxon";

import { type LegacyDataFormat } from "../../../../Components/Catalog/Catalog.context";
import LoadingButton from "../../../../Components/LoadingButton";
import { type Entity } from "../../../../Context/customer/EntitiesContext";
import { type Asset, type Contract, type Contracts } from "../../../../types";
import DomainStep from "./DomainStep";
import ServiceStep from "./ServiceStep";
import SummaryStep from "./SummaryStep";
import { type Values } from "./types";

type formikUsedProp =
  | "values"
  | "touched"
  | "setFieldValue"
  | "errors"
  | "handleBlur"
  | "handleChange"
  | "setSubmitting"
  | "isSubmitting"
  | "isValid";

export type Props = Pick<FormikProps<Values>, formikUsedProp> & {
  activeStep: number;
  steps: string[];
  assets: Asset<Office365AssetModel>[];
  handleNext: () => void;
  rawServices: LegacyDataFormat[];
  handleBack: () => void;
  submitHandler: (values: Values, setSubmitting: (isSubmitting: boolean) => void, total: number) => () => Promise<void>;
  entities: Entity[];
  contracts: Contracts;
};

const Steps = ({
  activeStep,
  steps,
  assets,
  contracts,
  handleNext,
  values,
  rawServices,
  handleBack,
  submitHandler,
  entities,
  touched,
  errors,
  setFieldValue,
  handleBlur,
  handleChange,
  setSubmitting,
  isSubmitting,
  isValid,
}: Props) => {
  if (activeStep === steps.length) {
    return null;
  }

  switch (activeStep) {
    case 0:
      return (
        <>
          <CardContent>
            <DomainStep
              assets={assets}
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </CardContent>
          <CardActions>
            <Stack direction="row" width="100%" justifyContent="end">
              <Button disabled sx={{ mr: 1 }}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={isSubmitting || !values.tenant}
                sx={{ mr: 1 }}
              >
                Next step
              </Button>
            </Stack>
          </CardActions>
        </>
      );

    case 1: {
      let services: LegacyDataFormat[] = [];
      if (values.plan) {
        const previousOrders = assets.map((asset) => asset.data.properties.subscription?.offerId);
        services = rawServices.filter(
          (service) => service.data.plan === values.plan && !previousOrders.includes(service.data.skuId.toUpperCase())
        );
      }
      return (
        <>
          <CardContent>
            <ServiceStep
              services={services}
              setFieldValue={setFieldValue}
              values={values}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </CardContent>
          <CardActions>
            <Stack direction="row" width="100%" justifyContent="end">
              <Button onClick={handleBack} sx={{ mr: 1 }} disabled={isSubmitting}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={isSubmitting || !values.service || !!errors.quantity}
                sx={{ mr: 1 }}
              >
                Next step
              </Button>
            </Stack>
          </CardActions>
        </>
      );
    }
    case 2: {
      let customerDomain = "";
      let contract: Contract | null = null;
      let currency: CurrencyCode | undefined = "USD";
      if (values.tenant) {
        const asset = assets.find((a: any) => a.data.properties.customerId === values.tenant && a.data.entity);
        if (asset) {
          customerDomain = asset.data.properties.customerDomain;
          const now = DateTime.utc().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          const entityId = asset.data.entity?.id;
          if (!entityId) {
            return null;
          }
          contract = contracts?.[entityId]?.find((c) => {
            if (!c.data.entity || !asset.data.entity) {
              return false;
            }
            if (c.data.entity.id !== asset.data.entity.id) {
              return false;
            }
            if (!c.data.endDate) {
              return true;
            }
            const endDate = DateTime.fromJSDate(c.data.endDate.toDate()).set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            });
            return now <= endDate;
          });
          const entity = entities.find((e) => e.id === asset.data.entity?.id);
          if (entity?.currency && values?.service?.data?.price) {
            if (entity.currency in values.service.data.price) {
              currency = entity?.currency;
            }
          }
        }
      }
      let discount = 0;
      if (contract?.discount) {
        discount = contract.discount;
      }
      // const currency
      const price = values.service?.data.price[currency ?? "USD"] ?? values.service?.data.price.USD ?? 0;
      const total = values.quantity * price * (1 - discount * 0.01);

      return (
        <>
          <CardContent>
            <SummaryStep customerDomain={customerDomain} total={total} values={values} currency={currency} />
          </CardContent>

          <CardActions>
            <Stack direction="row" width="100%" justifyContent="end">
              <Button onClick={handleBack} sx={{ mr: 1 }} disabled={isSubmitting}>
                Back
              </Button>
              <LoadingButton
                key="submit-button"
                variant="contained"
                color="primary"
                onClick={submitHandler(values, setSubmitting, total)}
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                sx={{ mr: 1 }}
                mixpanelEventId="assets.office365Asset.subscribe"
              >
                Subscribe
              </LoadingButton>
            </Stack>
          </CardActions>
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export default Steps;
