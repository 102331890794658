import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { TableCell, Typography } from "@mui/material";
import { DateTime } from "luxon";

import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { type OrganizationWSnap } from "./Types";

type OrgRowProps = {
  row: OrganizationWSnap;
};

export const OrgRow = ({ row }: OrgRowProps) => {
  const routeMatchURL = useRouteMatchURL();

  const formattedTimeModified = useMemo(() => {
    const date = DateTime.fromJSDate(row.data?.timeModified?.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
    return "";
  }, [row.data.timeModified]);

  return (
    <>
      <TableCell style={{ textDecoration: "underline" }}>
        <Typography
          component={RouterLink}
          to={{
            pathname: `${routeMatchURL}/${row?.snapshot.id}`,
            state: {
              prevPage: routeMatchURL, // or any other value you want to pass as state
            },
          }}
          color="inherit"
          variant="body2"
        >
          {row?.data.name}
        </Typography>
      </TableCell>
      <Hide mdDown>
        <TableCell>{formattedTimeModified}</TableCell>
        <TableCell>{row?.users}</TableCell>
        <TableCell>{row?.data.scope?.length}</TableCell>
      </Hide>
    </>
  );
};
