import { type JSX } from "react";

import { Box, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Background from "../../../assets/login-content-background.svg";
import { colors } from "../../../muiTheme";
import MarkdownToReact from "../../../utils/storyblok/MarkdownToReact";
import DefaultPreviewContent from "./DefaultPreviewContent";

const Preview = ({ content, isLoading }: { content: string | null; isLoading: boolean }) => {
  const theme = useTheme();
  let finalContent: JSX.Element | null = null;

  if (!isLoading && !content) {
    finalContent = <DefaultPreviewContent />;
  }

  if (content) {
    finalContent = (
      <MarkdownToReact
        h1Props={{ fontSize: 36, lineHeight: 1.2, fontWeight: 600, color: theme.palette.common.white }}
        pProps={{ py: 2 }}
        aProps={{ color: colors.dark.primary.main }}
        typographyPropsOverrides={{ color: colors.dark.text.secondary }}
      >
        {content}
      </MarkdownToReact>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.navigation.background,
        minHeight: "100vh",
        textAlign: "center",
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Stack sx={{ height: "100vh", maxWidth: "576px", margin: "0 auto" }} justifyContent="center" alignItems="center">
        {finalContent}
      </Stack>
    </Box>
  );
};

export default Preview;
