import { TableCell } from "@mui/material";
import Link from "@mui/material/Link";
import { type Href } from "history";

type Props = {
  color?: string;
  linkText: string;
  to: Href;
};

export const LinkCell = ({ color = "inherit", linkText, to }: Props) => (
  <TableCell>
    <Link color={color} href={to} rel="noopener" target="_blank" variant="body2">
      {linkText}
    </Link>
  </TableCell>
);
