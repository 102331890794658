import { useHistory } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { type SideNavigationData } from "./SideNavigationLayout";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

type Props = {
  title: string;
  data: SideNavigationData;
};
export const NavigationSelect = ({ title, data }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <FormControl className={classes.root}>
      <InputLabel>{title}</InputLabel>
      <Select
        fullWidth
        labelId="simple-select-outlined-label"
        id="simple-select-outlined"
        value={data.value}
        onChange={data.onChange}
        label={title}
      >
        {data.items.map((item, index) => (
          <MenuItem
            onClick={() => {
              history.push(item.to);
            }}
            key={index}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
