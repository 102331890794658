import { type ChangeEventHandler, type KeyboardEvent, useEffect, useMemo, useState } from "react";

import { type CustomerModelPublicDashboardsModel, PermissionModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Checkbox, FormControl, FormHelperText, Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { green, red } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import { type SelectChangeEvent } from "@mui/material/Select/SelectInput";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";

import { dashboardsText } from "../../assets/texts";
import { useDashboardsContext } from "../../Context/DashboardContext";
import { useUserContext } from "../../Context/UserContext";
import LoadingButton from "../LoadingButton";
import { useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginTop: 0,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  privatePublic: {
    display: "flex",
    justifyContent: "space-between",
  },
  privateIcon: {
    marginTop: 6,
    color: theme.palette.grey[400],
  },
  nameNotValid: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export enum AddDashboardType {
  Create = 1,
  Attach = 2,
  Edit = 3,
}

type Props = {
  type: AddDashboardType;
  handleClose: (name?: string) => void;
  selectedDashboardId?: string;
};

export function CreateDashboardDialog({ type, handleClose, selectedDashboardId }: Readonly<Props>) {
  const classes = useStyles();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { dashboards, publicDashboards, attachDashboard, createDashboard, editDashboard, isDashboardNameValid } =
    useDashboardsContext();
  const [isPublic, setIsPublic] = useState("private");
  const [permissionsList, loadingPermissionsList] = useCollectionData(getCollection(PermissionModel), {
    idField: "id",
  });
  const [hasError, setHasError] = useState<string | undefined>();
  const [name, setName] = useState<string>();
  const [publicDashboardName, setPublicDashboardName] = useState<string>();
  const [allowToEdit, setAllowToEdit] = useState<boolean>(false);
  const successSnackbar = useSuccessSnackbar();

  const selectedDashboard = useMemo(
    () => find(dashboards, { id: selectedDashboardId }),
    [dashboards, selectedDashboardId]
  );

  useEffect(() => {
    if (type === AddDashboardType.Edit) {
      if (selectedDashboard) {
        setIsPublic(selectedDashboard.isPublic ? "public" : "private");
        setName(selectedDashboard.name);
        setAllowToEdit(selectedDashboard.allowToEdit ?? false);
      }
    } else {
      setIsPublic("private");
    }
  }, [type, selectedDashboard]);

  const isUserHasPermissionsToDashboard = (dashboard: CustomerModelPublicDashboardsModel) => {
    if (dashboard.requiredPermissions) {
      return dashboard.requiredPermissions.every((permission) => userRoles.permissions.has(permission));
    }
    return true;
  };

  const isNameValid = (name: string) => {
    const [, reason] = isDashboardNameValid(name.trim());
    if (reason === "exists" && selectedDashboard?.name.localeCompare(name, undefined, { sensitivity: "base" }) === 0) {
      setHasError(undefined);
      return;
    }
    setHasError(reason);
  };
  const handleChangeName: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    isNameValid(event.target.value);
    setName(event.target.value);
  };

  const handleChangePublicDashboardId = (event: SelectChangeEvent) => {
    const publicDashboard = find(publicDashboards, { name: event.target.value });
    if (publicDashboard) {
      setPublicDashboardName(publicDashboard.name);
      setAllowToEdit(publicDashboard.allowToEdit ?? true);
    }
  };

  const onAttachDashboard = async () => {
    if (!publicDashboardName) {
      return;
    }
    await attachDashboard(publicDashboardName);
    handleClose(publicDashboardName);
  };

  const create = async () => {
    if (!name) {
      return;
    }

    await createDashboard({
      name,
      isPublic: isPublic === "public",
      allowToEdit,
    });
    handleClose(name);
  };

  const onKeyDown = async (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      await create();
      event.preventDefault();
    }
  };

  const endAdornmentIcon = useMemo(() => {
    if (name) {
      return hasError ? <CloseIcon style={{ color: red[400] }} /> : <DoneIcon style={{ color: green[400] }} />;
    }

    return null;
  }, [hasError, name]);

  const title = useMemo(() => {
    if (type === AddDashboardType.Create) {
      return "Create new dashboard";
    }

    if (type === AddDashboardType.Edit) {
      return "Edit dashboard";
    }

    return "Attach dashboard";
  }, [type]);

  return (
    <Dialog
      open={true}
      onClose={() => {
        handleClose();
      }}
      onKeyDown={onKeyDown}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {(type === AddDashboardType.Create || type === AddDashboardType.Edit) && (
          <>
            <Grid container spacing={0} mt={1}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  className={classes.textField}
                  value={name ?? ""}
                  onChange={handleChangeName}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  inputProps={{ maxLength: 70, "data-cy": "dashboard-name-input" }}
                  InputProps={{
                    endAdornment: endAdornmentIcon,
                  }}
                  label="Dashboard name"
                  error={!!hasError}
                  helperText={!!hasError && dashboardsText[hasError]}
                  required
                />
                <FormHelperText>Enter a dashboard name. e.g Finance, Google Cloud, AWS</FormHelperText>
              </FormControl>
            </Grid>
            <Box mt={3}>
              <Typography variant="subtitle1" fontWeight="500">
                Dashboard visibility
              </Typography>
              <Stack pl={2}>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={isPublic}
                  onChange={(event) => {
                    setIsPublic(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="private"
                    control={<Radio color="primary" />}
                    label="Private"
                    labelPlacement="end"
                  />
                  <FormHelperText sx={{ pl: 4, mt: -1 }}>Only you will see the dashboard</FormHelperText>

                  <FormControlLabel
                    value="public"
                    control={<Radio color="primary" />}
                    label="Public"
                    labelPlacement="end"
                  />
                  <FormHelperText sx={{ pl: 4, mt: -1 }}>
                    Everyone in your organization will see the dashboard
                  </FormHelperText>
                </RadioGroup>
              </Stack>
            </Box>
            <Box mt={3} pl={2}>
              {isPublic === "public" && (
                <FormGroup>
                  <FormControlLabel
                    style={{ marginRight: 3 }}
                    control={
                      <Checkbox
                        data-cy="allow-edit-checkbox"
                        checked={allowToEdit}
                        onChange={(event) => {
                          setAllowToEdit(event.target.checked);
                        }}
                      />
                    }
                    label="Allow other users to edit this dashboard"
                  />
                </FormGroup>
              )}
            </Box>

            <div className={classes.privatePublic} />
          </>
        )}

        {type === AddDashboardType.Attach && (
          <>
            <Typography variant="subtitle1">Attach existing dashboard</Typography>
            <Select
              autoFocus
              value={publicDashboardName ?? ""}
              style={{ width: "100%" }}
              margin="dense"
              onChange={handleChangePublicDashboardId}
              input={<OutlinedInput name="public" id="outlined-age-simple" />}
            >
              {!loadingPermissionsList &&
                publicDashboards.map((publicDashboard) => {
                  const userHasDashboard = dashboards.some((dashboard) => dashboard.name === publicDashboard.name);
                  const permissionsTitle = publicDashboard.requiredPermissions?.map(
                    (permission) => find(permissionsList, { id: permission })?.title ?? permission
                  );

                  if (!userHasDashboard) {
                    return (
                      <MenuItem
                        disabled={!isUserHasPermissionsToDashboard(publicDashboard)}
                        key={publicDashboard.id}
                        value={publicDashboard.name}
                      >
                        {publicDashboard.name}{" "}
                        {!isUserHasPermissionsToDashboard(publicDashboard) &&
                          `(requires ${permissionsTitle?.join(",")})`}
                      </MenuItem>
                    );
                  } else {
                    return null;
                  }
                })}
            </Select>
            <br />
            <br />
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="primary"
        >
          Cancel
        </Button>
        {type === AddDashboardType.Create && (
          <LoadingButton
            color="primary"
            variant="contained"
            loading={false}
            onClick={async () => {
              await create();
              successSnackbar("Dashboard successfully created");
            }}
            disabled={!!hasError || !name}
            mixpanelEventId="dashboard.create"
          >
            Create new dashboard
          </LoadingButton>
        )}

        {type === AddDashboardType.Attach && (
          <LoadingButton
            color="primary"
            variant="contained"
            loading={false}
            onClick={onAttachDashboard}
            disabled={!publicDashboardName}
            mixpanelEventId="dashboard.attach"
          >
            Attach
          </LoadingButton>
        )}

        {type === AddDashboardType.Edit && (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={async () => {
              if (!name || !selectedDashboard) {
                return;
              }

              await editDashboard({
                dashboardId: selectedDashboard.id,
                name: name.trim(),
                isPublic: isPublic === "public",
                allowToEdit,
              });
              successSnackbar("Dashboard settings updated");
              handleClose();
            }}
            disabled={!!hasError}
            loading={false}
            mixpanelEventId="dashboard.save"
          >
            Save
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
