import { type JSX, type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { type SxProps } from "@mui/material";
import Grid from "@mui/material/Grid";
import { type Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";

import {
  type DataColumns,
  type DefaultFilterColumns,
  type HeaderColumns,
  type SideFiltersData,
} from "../../types/FilterTable";
import { useFullScreen } from "../../utils/dialog";
import { type NestedObjectLeaves } from "../../utils/NestedKeyOf";
import useTableState from "../hooks/useTableState";
import { ColumnsOrderContext, FilterContextProvider } from "./Context";
import { TableFilterBar } from "./Filterbar/TableFilterBar";
import { applyColumnsChangeToArray, getDefaultColumnsChanges } from "./filterTableUtils";
import { useLocalStorage } from "./hook";
import { SideFilter } from "./SideFilter/SideFilter";
import { FilterTableBody } from "./Table/FilterTableBody";
import { FilterTableFooter } from "./Table/FilterTableFooter";
import { FilterTableHeader } from "./Table/FilterTableHeader";
import { FilterTableToolbar, type FilterTableToolbarProps } from "./Toolbar/FilterTableToolbar";
import { type ColumnChange } from "./types";

const MIN_NUMBER_OF_ROWS = 10;
const DEFAULT_ROW_HEIGHT = 53;

type FilterTableProps<T> = {
  // react component that will be injected for each row (the component should have single prop named 'row')
  // in case of wrapRowComponent: true rowComponent should not contain <TableRow />
  rowComponent: (props: { row: T }) => JSX.Element;

  // default true. When custom row component needed (for example expandable rows) set false and pass single or multiple
  // <TableRow /> components to rowComponent
  wrapRowComponent?: boolean;

  // react component that will be shown when there are not row items to display
  emptyTableComponent?: JSX.Element;

  emptyTableCellBorder?: boolean;

  // items data to show in the table
  tableItems: Readonly<Array<T>> | undefined;

  // define the table header columns
  headerColumns: HeaderColumns<T>;

  // define the filters of the filter bar
  filterColumns: DataColumns<T>;

  // default filters for the filter bar
  defaultFilters?: DefaultFilterColumns<T>;

  // callback that will be called on every selection of the rows
  onRowsSelected?: (items: T[]) => void;

  // callback that will be called on every change to the filters
  onFilterApplied?: (items: readonly T[]) => void;

  // control if to show/hide the checkboxes for each row (the selection is done by the value of 'itemUniqIdentifierField' prop)
  showRowsSelection?: boolean;

  // string value that represent the uniq filed name of the item that can be used during selection ('id' by default)
  itemUniqIdentifierField?: NestedObjectLeaves<T> | "id";

  // array of string values to populate the selected rows on load
  defaultRowsSelected?: string[];

  // default value of the column to sort by
  defaultSortingColumnValue?: NestedObjectLeaves<T>;

  // default sort direction
  defaultSortDirection?: "asc" | "desc";

  // slot to add additional component on the right side of the filter bar (should have <Grid item> as root)
  // @deprecated use toolbarProps instead
  children?: ReactNode;

  // configure the toolbar of the filter table
  toolbarProps?: FilterTableToolbarProps;

  // uniq name that will be used to save persistence for the table
  persistenceKey?: string;

  // define custom empty row height in case the rows have none default value (like dense rows)
  emptyRowHeight?: number;

  // filter bar placeholder (default value is "Filter table")
  filterBarPlaceholder?: string;

  // filter bar style prop
  filterAreaClassName?: string;

  // filter bar sx prop
  filterAreaSXProp?: SxProps<Theme>;

  showFilterBar?: boolean;

  showHeader?: boolean;

  // expect fixed height of the table
  fillEmptySpace?: boolean;

  "data-cy"?: string;

  rowDataCyId?: boolean;

  sideFilters?: SideFiltersData<T>[];

  // define the filters of the side filters
  sideFilterColumns?: DataColumns<T>;

  mixpanelEventNames?: {
    sideFilter?: string;
    searchBarFilter?: string;
  };

  // changes filter bar OR and AND splitting behaviour
  reverseOperatorPrecedence?: boolean;

  // slot to add additional component on the left side of the filter bar (should have <Grid item> as root)
  searchBarFilters?: ReactNode;

  showPagination?: boolean;
  headerStyle?: SxProps<Theme>;
};

export function FilterTable<T>({
  rowComponent,
  emptyTableComponent,
  emptyTableCellBorder = true,
  toolbarProps,
  tableItems,
  headerColumns,
  filterColumns,
  defaultFilters,
  onRowsSelected,
  onFilterApplied,
  showRowsSelection = false,
  defaultSortingColumnValue,
  defaultSortDirection,
  children,
  persistenceKey,
  emptyRowHeight = DEFAULT_ROW_HEIGHT,
  itemUniqIdentifierField = "id",
  filterBarPlaceholder,
  filterAreaClassName = "",
  wrapRowComponent = true,
  showFilterBar = true,
  showHeader = true,
  fillEmptySpace = true,
  "data-cy": dataCy,
  rowDataCyId,
  filterAreaSXProp,
  sideFilters,
  sideFilterColumns,
  mixpanelEventNames,
  reverseOperatorPrecedence,
  searchBarFilters,
  defaultRowsSelected,
  showPagination = true,
  headerStyle,
}: FilterTableProps<T>) {
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>(defaultRowsSelected ?? []);
  const {
    tableState,
    filteredRows,
    rows,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    numEmptyRows,
    handleRequestFilter,
  } = useTableState<T>(tableItems ?? [], {
    persistenceKey,
    sort: defaultSortingColumnValue,
    direction: defaultSortDirection,
    rowsPerPage: showPagination ? undefined : -1,
  });

  const [savedColumnsOrder, setSavedColumnsOrder] = useLocalStorage<ColumnChange[]>(
    `${persistenceKey}_table_columns_order`,
    getDefaultColumnsChanges(headerColumns)
  );

  // check for changes in saved value that might have been changed, in this case return the default
  const columnsOrder = useMemo(() => {
    // check if the matching column in edit columns should have checkbox
    savedColumnsOrder.forEach((item) => {
      const header = headerColumns.find((column) => column.label === item.value);
      item.hideCheckbox = header?.preventSelection;
    });

    const currentHeaderLabels = headerColumns.map((header) => header.label ?? "");
    const savedHeaderLabels = savedColumnsOrder.map((column) => column.value);

    currentHeaderLabels.sort((a, b) => a.localeCompare(b));
    savedHeaderLabels.sort((a, b) => a.localeCompare(b));
    if (!isEqual(currentHeaderLabels, savedHeaderLabels)) {
      return getDefaultColumnsChanges(headerColumns);
    }
    return savedColumnsOrder;
  }, [headerColumns, savedColumnsOrder]);

  const columnsOrderContextValue: [ColumnChange[], (change: ColumnChange[]) => void] = useMemo(() => {
    const cleanColumns = (columns: ColumnChange[]) => {
      columns.forEach((element) => {
        delete element.hideCheckbox;
      });
      setSavedColumnsOrder(columns);
    };
    return [columnsOrder, cleanColumns];
  }, [columnsOrder, setSavedColumnsOrder]);

  const { isMobile } = useFullScreen();

  useEffect(() => {
    setSelectedItemsIds(defaultRowsSelected ?? []);
  }, [tableState.direction, tableState.filter, tableState.sort, defaultRowsSelected]);

  // clear items that no longer exist from the selectedItemsIds array
  useEffect(() => {
    const newSelectedIds = selectedItemsIds.filter(
      (id) => (tableItems ?? []).findIndex((item) => id === get(item, itemUniqIdentifierField as string)) !== -1
    );

    if (newSelectedIds.length !== selectedItemsIds.length) {
      setSelectedItemsIds(newSelectedIds);
    }
  }, [tableItems, selectedItemsIds, itemUniqIdentifierField]);

  useEffect(() => {
    if (onRowsSelected && tableItems) {
      // using tableItems here instead of items due to state update issues
      const selectedItems = tableItems.filter((item) =>
        selectedItemsIds.includes(get(item, itemUniqIdentifierField as string) as string)
      );
      onRowsSelected(selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemsIds, itemUniqIdentifierField]);

  useEffect(() => {
    onFilterApplied?.(filteredRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows]);

  useEffect(() => {
    if (!tableItems?.length) {
      // There's no point changing a page number if there aren't going to be any pages to change.
      return;
    }
    const isDataShorterThanPageRange = tableItems.length <= tableState.page * tableState.rowsPerPage;
    if (tableState.page > 0 && isDataShorterThanPageRange) {
      handleChangePage(null, 0);
    }
  }, [handleChangePage, tableItems?.length, tableState.page, tableState.rowsPerPage]);

  const handleAllItemsSelect = useCallback(
    (_: unknown, checked: boolean) => {
      setSelectedItemsIds((prevState) =>
        checked ? uniq([...prevState, ...rows.map((row) => get(row, itemUniqIdentifierField as string))]) : []
      );
    },
    [itemUniqIdentifierField, rows]
  );

  const handleItemSelect = useCallback((itemId: string, checked: boolean) => {
    setSelectedItemsIds((prevState) =>
      checked ? prevState.concat(itemId) : prevState.filter((selectedItemId) => selectedItemId !== itemId)
    );
  }, []);

  const isAllSelected = useMemo(
    () =>
      selectedItemsIds.length > 0 &&
      rows.every((row) => selectedItemsIds.includes(get(row, itemUniqIdentifierField as string))),
    [itemUniqIdentifierField, rows, selectedItemsIds]
  );

  const tableEmptyBody = () => {
    const colSpan = headerColumns.length + (showRowsSelection ? 1 : 0);

    if (!tableItems) {
      return;
    }

    if ((tableItems.length === 0 || filteredRows.length === 0) && emptyTableComponent) {
      // show empty table component
      return (
        <TableRow>
          <TableCell colSpan={colSpan} sx={{ borderBottomWidth: emptyTableCellBorder ? "1px" : "0" }}>
            {emptyTableComponent}
          </TableCell>
        </TableRow>
      );
    }

    if (numEmptyRows > 0 && numEmptyRows <= MIN_NUMBER_OF_ROWS && fillEmptySpace) {
      // fill height with blank space
      return (
        <TableRow style={{ height: emptyRowHeight * numEmptyRows }}>
          <TableCell colSpan={colSpan} />
        </TableRow>
      );
    }

    return null;
  };
  const isSideFiltersVisible = sideFilters !== undefined && sideFilterColumns !== undefined && !isMobile;

  return (
    <ColumnsOrderContext.Provider value={columnsOrderContextValue}>
      <FilterContextProvider columns={filterColumns} persistenceKey={persistenceKey} defaultValue={defaultFilters}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FilterTableToolbar {...toolbarProps} />
          </Grid>

          {isSideFiltersVisible ? (
            <Grid item md={3} lg={2}>
              <SideFilter
                filters={sideFilters}
                mixpanelEventName={mixpanelEventNames?.sideFilter}
                onFilter={handleRequestFilter}
                columns={sideFilterColumns}
                reverseOperatorPrecedence={reverseOperatorPrecedence}
              />
            </Grid>
          ) : null}

          <Grid item xs={12} md={isSideFiltersVisible ? 9 : 12} lg={isSideFiltersVisible ? 10 : 12}>
            {showFilterBar && (
              <Grid
                container
                spacing={1}
                alignItems="center"
                className={filterAreaClassName}
                data-cy={dataCy}
                sx={filterAreaSXProp}
              >
                {searchBarFilters}
                <Grid item xs>
                  <TableFilterBar<T>
                    items={tableItems ?? []}
                    onFilter={handleRequestFilter}
                    defaultFilters={defaultFilters}
                    filterColumn={filterColumns}
                    placeholder={filterBarPlaceholder}
                    mixpanelEventName={mixpanelEventNames?.searchBarFilter}
                    reverseOperatorPrecedence={reverseOperatorPrecedence}
                  />
                </Grid>
                {children}
              </Grid>
            )}

            <TableContainer>
              <Table>
                {showHeader && (
                  <FilterTableHeader<T>
                    columns={
                      toolbarProps?.allowToEditColumns
                        ? applyColumnsChangeToArray(headerColumns, columnsOrder)
                        : headerColumns
                    }
                    showRowsSelection={showRowsSelection}
                    selectedColumnValue={tableState.sort}
                    sortDirection={tableState.direction}
                    columnSortCreator={handleRequestSort}
                    handleAllItemsSelect={handleAllItemsSelect}
                    isAllSelected={isAllSelected}
                    isItemSelected={selectedItemsIds.length > 0}
                    tableName={persistenceKey}
                    headerStyle={headerStyle}
                  />
                )}

                <FilterTableBody<T>
                  rows={rows}
                  RowComponent={rowComponent}
                  showRowsSelection={showRowsSelection}
                  selectedItemsIds={selectedItemsIds}
                  itemUniqIdentifierField={itemUniqIdentifierField}
                  handleItemSelect={handleItemSelect}
                  wrapRowComponent={wrapRowComponent}
                  tableName={persistenceKey}
                  rowDataCyId={rowDataCyId}
                >
                  {tableEmptyBody()}
                </FilterTableBody>
              </Table>
            </TableContainer>
            {showPagination && filteredRows.length > MIN_NUMBER_OF_ROWS && (
              <FilterTableFooter
                rowsCount={filteredRows.length}
                rowsPerPage={tableState.rowsPerPage}
                page={tableState.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
      </FilterContextProvider>
    </ColumnsOrderContext.Provider>
  );
}
