import { useEffect } from "react";

import { type FlexsaveType, type StandaloneEstimationsGCP } from "@doitintl/cmp-models";

import { useApiContext } from "../../api/context";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { type SavingsEstimations } from "../../Pages/Flexsave/types";
import { SalesforceApi } from "./salesforce";
import { type SalesforceCustomer, type SalesforceUser } from "./updateSalesforceAfterEstimation";

export const UpdateSalesforce = ({
  updateFunction,
  platform,
  savings,
}: {
  updateFunction: (
    salesforceApi: SalesforceApi,
    customer: SalesforceCustomer,
    user: SalesforceUser,
    platform?: FlexsaveType,
    savings?: StandaloneEstimationsGCP | SavingsEstimations
  ) => void;
  platform?: FlexsaveType;
  savings?: StandaloneEstimationsGCP | SavingsEstimations;
}) => {
  const { customer, isProductOnlyCustomer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee, isDoitPartner, customerId } = useAuthContext();
  const { userModel } = useUserContext();
  const api = useApiContext();

  useEffect(() => {
    if (
      !customer?.id ||
      !isProductOnlyCustomer ||
      !userModel?.id ||
      customerId !== customer.id ||
      isDoitEmployee ||
      isDoitPartner
    ) {
      return;
    }
    const salesforceApi = new SalesforceApi(api);

    const salesforceUser: SalesforceUser = {
      id: userModel.id,
      email: userModel.email,
      firstName: userModel.firstName,
      lastName: userModel.lastName,
    };

    const salesforceCustomer: SalesforceCustomer = {
      id: customer.id,
      name: customer.name,
      salesforce: customer.salesforce,
    };
    updateFunction(salesforceApi, salesforceCustomer, salesforceUser, platform, savings);
  }, [
    api,
    customerId,
    platform,
    savings,
    isDoitEmployee,
    isDoitPartner,
    updateFunction,
    userModel?.id,
    userModel?.email,
    userModel?.firstName,
    userModel?.lastName,
    customer?.id,
    isProductOnlyCustomer,
    customer?.name,
    customer?.salesforce,
  ]);

  return null;
};
