import { AssetModel, AssetSettingModel, EntityInvoicingModes, type EntityModelBucketModel } from "@doitintl/cmp-models";
import { getBatch, getCollection, type ModelBatch, type ModelReference } from "@doitintl/models-firestore";

import { type Entity } from "../../Context/customer/EntitiesContext";
import mixpanel from "../../utils/mixpanel";
import {
  type AssetDescriptor,
  type BillingProfileInvoiceBucket,
  type EditBillingProfileData,
} from "./BillingProfileForm.models";

type BillingProfileInvoiceBucketWithRef = {
  id: string;
  ref: ModelReference<EntityModelBucketModel>;
  assets: BillingProfileInvoiceBucket["assets"];
  isDefault: BillingProfileInvoiceBucket["isDefault"];
  name: BillingProfileInvoiceBucket["name"];
  attribution?: BillingProfileInvoiceBucket["attribution"];
};

function createBucketWithRefFactory(entity: Entity) {
  return (bucket: BillingProfileInvoiceBucket): BillingProfileInvoiceBucketWithRef => {
    const bucketsRef = entity.ref.collection("buckets");
    const ref = bucket.id === undefined ? bucketsRef.newDoc() : bucketsRef.doc(bucket.id);
    return { ...bucket, ref, id: ref.id };
  };
}

function getAssetRef(asset: AssetDescriptor) {
  return getCollection(AssetModel).doc(asset.id);
}

const maxBatchOps = 300;
let batchOpCounter = 0;
const checkBatchOrCommit = async (batch: ModelBatch, increment: number) => {
  if (batchOpCounter >= maxBatchOps) {
    await batch.commit();
    batchOpCounter = 0;
    batch = getBatch();
  } else {
    batchOpCounter += increment;
  }
  return batch;
};
export async function updateInvoiceSettings(
  entity: Entity,
  initialValues: EditBillingProfileData,
  values: EditBillingProfileData
) {
  const bucketWithRefFactory = createBucketWithRefFactory(entity);

  const bucketsToDelete = initialValues.buckets
    .filter(({ id }) => !values.buckets.find(({ id: remainingBucketId }) => remainingBucketId === id))
    .map(bucketWithRefFactory);

  const updatedOrCreatedBuckets = values.buckets.map(bucketWithRefFactory);

  const assignedAssets = values.buckets.flatMap(({ assets }) => assets);
  const assetsToUnassign = initialValues.buckets
    .flatMap(({ assets }) => assets)
    .filter((maybeUnassigned) => !assignedAssets.find(({ id }) => id === maybeUnassigned.id));

  let batch = getBatch();
  for (const bucket of bucketsToDelete) {
    batch = await checkBatchOrCommit(batch, 1);
    batch = batch.delete(bucket.ref);
  }

  for (const bucket of updatedOrCreatedBuckets) {
    batch = await checkBatchOrCommit(batch, 1);
    batch = batch.set(bucket.ref, { name: bucket.name, attribution: bucket.attribution ?? null });

    for (const asset of bucket.assets) {
      batch = await checkBatchOrCommit(batch, 2);
      batch = batch
        .update(getAssetRef(asset), {
          bucket: bucket.ref,
        })
        .update(getCollection(AssetSettingModel).doc(asset.id), {
          bucket: bucket.ref,
        });
    }
  }

  const defaultBucketDocRef = updatedOrCreatedBuckets.find(({ isDefault }) => isDefault)?.ref ?? null;

  if (initialValues.invoicingMode !== values.invoicingMode) {
    mixpanel.track("entity.set.invoice", {
      mode: initialValues.invoicingMode,
    });
  }

  batch = batch.update(entity.ref, {
    invoicing: {
      ...entity.invoicing,
      mode: values.invoicingMode,
      default: values.invoicingMode !== EntityInvoicingModes.CUSTOM ? null : defaultBucketDocRef,
      autoAssignGCP: values.autoAssignGCP,
      marketplace: {
        invoicePerService: values.separateInvoice ? values.invoicePerService : false,
        separateInvoice: values.separateInvoice,
      },
    },
  });

  for (const assetToUnassign of assetsToUnassign) {
    batch = await checkBatchOrCommit(batch, 2);
    batch = batch
      .update(getAssetRef(assetToUnassign), {
        bucket: null,
      })
      .update(getCollection(AssetSettingModel).doc(assetToUnassign.id), {
        bucket: null,
      });
  }

  await batch.commit();
}
