import { useMemo } from "react";

import { useLocation } from "react-router-dom";
import { parse } from "qs";

const useQueryString = () => {
  const location = useLocation();
  const searchString = location.search || "";

  return useMemo(() => parse(searchString, { ignoreQueryPrefix: true }), [searchString]);
};

export default useQueryString;
