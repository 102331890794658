import { collection, type Reference } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

@collection("featureDemand")
export class FeatureDemandModel {
  feature!: string;

  customer!: Reference<CustomerModel>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>;
}
