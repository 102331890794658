export const generalTxt = {
  CREATE_A_CUSTOMER: "Create customer",
  DELETE_CUSTOMER_BTN_TITLE: "Delete customer",
  DELETE_CUSTOMER_CONFIRM_BTN_TITLE: "Delete",
  CUSTOMER_DELETE_SUCCESS: "Customer deleted successfully",
  CUSTOMER_DELETE_ERROR: "Failed delete customer",
  CUSTOMER_RETRIEVING_DATA: "Retrieving data",
  CUSTOMER_DELETE_DISABLED: "Cannot delete customer tenants with existing resources",
  CONFIRM_DELETE_CUSTOMER: (customer: string) => `Are you sure you want to delete ${customer}?`,
  OVERRIDE_CUSTOMER_SEGMENT: "Override Customer Segment",
  CUSTOMER_SEGMENT: "Customer Segment",
  SEGMENT: "Segment",
};
