export const getLineChartOptions = () => ({
  chart: {
    type: "line",
    zoomType: "xy",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: true,
    maxHeight: 66,
    itemMarginTop: 4,
  },
  tooltip: {
    valueDecimals: 2,
  },
  yAxis: {
    title: {
      enabled: false,
    },
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  series: [],
});
