import { type EntityInvoicesModelRow } from "@doitintl/cmp-models";

import { type HeaderColumn } from "../../../../../types/FilterTable";

export const invoiceViewRowColumns: HeaderColumn<EntityInvoicesModelRow>[] = [
  {
    value: "SKU",
    label: "SKU",
  },
  {
    value: "details",
    label: "Details",
  },
  {
    value: "rank",
    label: "Rank",
  },
  {
    value: "discount",
    label: "Discount",
  },
  {
    value: "unitPrice",
    label: "Unit price",
  },
  {
    value: "quantity",
    label: "Quantity",
  },
  {
    value: "total",
    label: "Total",
  },
];
