import { useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { type AxiosResponse } from "axios";

type Props = {
  onClose: () => void;
  handleDisable: () => Promise<AxiosResponse>;
};

export const DisableFlexsaveDialog = ({ onClose, handleDisable }: Props) => {
  const [duringDisabling, setDuringDisabling] = useState(false);

  const onDisableClicked = async () => {
    setDuringDisabling(true);
    await handleDisable();
    setDuringDisabling(false);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Disable Flexsave</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to disable Flexsave?</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>

        <Button
          sx={{
            ml: 1,
          }}
          onClick={onDisableClicked}
          color="primary"
          variant="contained"
          disabled={duringDisabling}
          startIcon={duringDisabling ? <CircularProgress size={20} color="inherit" /> : undefined}
          data-cy="disable"
        >
          Disable
        </Button>
      </DialogActions>
    </Dialog>
  );
};
