import { type ContractSupport, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";

export type supportMapObj = Record<string, ContractSupport>;

export type payerAccountObj = {
  data: WithFirebaseModel<MasterPayerAccountsModel>;
  ref: ModelReference<MasterPayerAccountsModel>;
};

export enum costMode {
  percentage = "percentage",
  flatRate = "flatRate",
}

export type SelectedPricebooks = Record</* master payer account id */ string, /* pricebook id */ number | null>;
