import { useState } from "react";

import { useHistory } from "react-router";
import { Box } from "@mui/material";

import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { updateStartTrialInitiated } from "../../Components/StartTrialButton/db";
import { type Step, Stepper, type StepState } from "../../Components/Stepper";
import { useCustomerContext } from "../../Context/CustomerContext";
import { customersRoute } from "../../Navigation/core/utils";
import { useFullScreen } from "../../utils/dialog";
import { updateCompanyProfile } from "./db";
import CompanyProfileStep from "./Steps/CompanyProfileStep";
import TrialOverviewStep from "./Steps/TrialOverview";
import { getOnboardingUrl } from "./utils";

const NavigatorTrialStepper = () => {
  const { customer } = useCustomerContext();
  const history = useHistory();
  const { isMobile } = useFullScreen();
  const showError = useErrorSnackbar();

  const [companySize, setCompanySize] = useState<string>("");
  const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
  const [updatingProfile, setUpdatingProfile] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const stepperState: StepState[] = currentStep > 0 ? ["complete", "complete"] : ["complete", "incomplete"];
  const successReturnUrl = getOnboardingUrl(customer);
  const customerPage = customersRoute(customer.id);

  const onSubmitProfile = async () => {
    try {
      setUpdatingProfile(true);

      await updateCompanyProfile(customer.id, companySize, selectedGoals);
      await updateStartTrialInitiated(customer);

      history.push(successReturnUrl);
    } catch (e) {
      showError("Failed to save details. Please try again.");
    } finally {
      setUpdatingProfile(false);
    }
  };

  const steps: Step[] = [
    {
      children: <TrialOverviewStep />,
      label: "Overview",
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: (
        <CompanyProfileStep
          companySize={companySize}
          selectedGoals={selectedGoals}
          setCompanySize={setCompanySize}
          setSelectedGoals={setSelectedGoals}
        />
      ),
      label: "Goals",
      order: 1,
      required: true,
      state: stepperState[1],
    },
  ];

  return (
    <Box sx={{ mt: 4 }}>
      <Stepper
        getCurrentStep={setCurrentStep}
        loading={updatingProfile}
        onCancel={() => {
          history.push(customerPage);
        }}
        onSubmit={onSubmitProfile}
        steps={steps}
        submitButtonLabel="Start free trial"
        footerMaxWidth={isMobile ? 500 : 800}
        maxWidth={1500}
        contentSx={{
          my: 4,
          mx: isMobile ? 0 : 5,
        }}
      />
    </Box>
  );
};

export default NavigatorTrialStepper;
