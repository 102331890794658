import { type CustomerModel } from "@doitintl/cmp-models";
import { type Model, type ModelIdRef, type ModelReference } from "@doitintl/models-firestore";

export enum RoleCustomerType {
  CONSOLIDATED = "consolidated",
  STANDALONE = "standalone",
}
export enum CustomerType {
  PROCUREMENT_ONLY = "procurement-only",
  PRODUCT_ONLY = "product-only",
  PROCUREMENT_AND_PRODUCT = "procurement-and-product",
  UNKNOWN = "unknown",
}

export type CustomerRef = ModelReference<CustomerModel>;
export type Customer = Model<ModelIdRef<CustomerModel>>;
