import { useCallback, useMemo, useState } from "react";

import { type AmazonWebServicesAssetModel, AssetTypeAmazonWebServices, SaaSConsoleType } from "@doitintl/cmp-models";
import { Container } from "@mui/material";

import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type Step, Stepper, type StepState } from "../../../../Components/Stepper";
import { useAssetsContext } from "../../../../Context/customer/AssetContext";
import { type Asset } from "../../../../types";
import { consoleErrorWithSentry } from "../../../../utils";
import SuccessMessage from "../Common/SuccessMessage";
import { useSaaSConsoleAPI } from "../hooks";
import AccountIdDetails from "./AccountIdDetails";
import { EXPECTED_ACCOUNT_ID_LENGTH } from "./consts";
import CurVerification from "./CurDiscovery/CurVerification";
import { useSaaSConsoleAWSOnboarding } from "./hooks";
import SaaSOnboardingAWSContent from "./SaaSOnboardingAWSContent";

type Props = {
  handleBack: () => void;
};

const AWSStepper = ({ handleBack }: Props) => {
  const saasConsoleAPI = useSaaSConsoleAPI(SaaSConsoleType.AWS);
  const showErrorSnackbar = useErrorSnackbar();
  const [currentStep, getCurrentStep] = useState<number>(0);
  const { assets } = useAssetsContext();

  // Overview step states
  const [accountId, setAccountId] = useState("");
  const [initCompleted, setInitCompleted] = useState<boolean>(false);

  // Connect step states
  const [bucket, setBucket] = useState("");

  // Cur step states
  const [selectedCur, setSelectedCur] = useState<string | undefined>();
  const [billingConnectionLoading, setBillingConnectionLoading] = useState(false);

  const onboarding = useSaaSConsoleAWSOnboarding(initCompleted);

  // Derived states
  const isAccountIdOnboardingInitiated = !!onboarding?.accounts?.[accountId];
  const isOnboardingCompleted = !!onboarding?.accounts?.[accountId]?.completed;
  const isAccountIdFormatValid = accountId.length === EXPECTED_ACCOUNT_ID_LENGTH;

  const isAccountIDValid = useMemo(() => {
    for (const entity of Object.values(assets)) {
      if (
        entity
          .filter((asset) => asset.data.type === AssetTypeAmazonWebServices)
          .some(
            (asset): asset is Asset<AmazonWebServicesAssetModel> =>
              asset.data.properties.organization?.payerAccount.id === accountId
          )
      ) {
        return false;
      }
    }

    return true;
  }, [accountId, assets]);

  // Steps states
  const enabledOnboardingStep = currentStep >= 0 && isAccountIdFormatValid && isAccountIDValid;
  const enabledCurVerificationStep =
    currentStep >= 1 &&
    isAccountIdOnboardingInitiated &&
    !!onboarding?.accounts?.[accountId]?.curPaths?.state &&
    !!bucket;
  const enabledConnectStep = currentStep >= 2 && !!selectedCur;

  const onOverviewStepNextClick = useCallback(async () => {
    const errorMessage = "Sorry, an error occurred. Please review the accountId or contact support";
    try {
      setInitCompleted(false);
      const res = await saasConsoleAPI.initAWSOnboardingWithAccountId(accountId);
      if (!res?.data?.success) {
        showErrorSnackbar(errorMessage);
        return false;
      }
      setInitCompleted(true);
      return true;
    } catch (error) {
      consoleErrorWithSentry(error);
      showErrorSnackbar(errorMessage);
      return false;
    }
  }, [accountId, saasConsoleAPI, showErrorSnackbar]);

  const onCurStepNextClick = useCallback(async () => {
    setBillingConnectionLoading(true);
    const selectedIndex = onboarding?.accounts?.[accountId]?.curPaths?.paths?.findIndex(
      (path) => path.reportName === selectedCur
    );
    const response = await saasConsoleAPI.activateAWSBilling(accountId, selectedIndex ?? 0);
    setBillingConnectionLoading(false);
    if (response !== true) {
      if (response !== "timeout") {
        showErrorSnackbar("Sorry, an error occurred. Please review the steps or contact support");
      }
      return false;
    }
    return true;
  }, [accountId, saasConsoleAPI, onboarding?.accounts, selectedCur, showErrorSnackbar]);

  const handleNextClick = useCallback(
    async (step: number) => {
      switch (step) {
        case 0:
          return onOverviewStepNextClick();
        default:
          return true;
      }
    },
    [onOverviewStepNextClick]
  );

  const onSubmitCurPath = useCallback(async () => {
    await onCurStepNextClick();
  }, [onCurStepNextClick]);

  const stepperState: StepState[] = useMemo(() => {
    if (enabledConnectStep) {
      return ["complete", "complete", "complete"];
    } else if (enabledCurVerificationStep) {
      return ["complete", "complete", "incomplete"];
    } else if (enabledOnboardingStep) {
      return ["complete", "incomplete", "incomplete"];
    } else {
      return ["editing", "incomplete", "incomplete"];
    }
  }, [enabledOnboardingStep, enabledCurVerificationStep, enabledConnectStep]);

  const steps: Step[] = [
    {
      children: (
        <AccountIdDetails
          accountId={accountId}
          setAccountId={setAccountId}
          onboarding={onboarding}
          isValidFormat={isAccountIdFormatValid}
          isValid={isAccountIDValid}
        />
      ),
      label: "Overview",
      order: 0,
      required: true,
      state: stepperState[0],
    },
    {
      children: (
        <SaaSOnboardingAWSContent accountId={accountId} bucket={bucket} setBucket={setBucket} onboarding={onboarding} />
      ),
      label: "Connect",
      order: 1,
      required: true,
      state: stepperState[1],
    },
    {
      children: (
        <CurVerification
          accountId={accountId}
          onboarding={onboarding}
          bucket={bucket}
          selectedCur={selectedCur}
          setSelectedCur={setSelectedCur}
        />
      ),
      label: "CUR",
      order: 2,
      required: true,
      state: stepperState[2],
    },
  ];

  return isOnboardingCompleted && currentStep >= 1 ? (
    <Container
      maxWidth="md"
      sx={{ pt: { xs: 3, sm: 6 }, alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      <SuccessMessage handleClose={handleBack} type={SaaSConsoleType.AWS} />
    </Container>
  ) : (
    <Stepper
      backButtonLabel="Back"
      loading={billingConnectionLoading}
      onCancel={handleBack}
      onSubmit={onSubmitCurPath}
      steps={steps}
      submitButtonLabel="Complete"
      footerMaxWidth={800}
      maxWidth={1000}
      getCurrentStep={getCurrentStep}
      nextButtonClickHandler={handleNextClick}
      contentSx={{
        mt: 3,
        mb: 3,
      }}
    />
  );
};

export default AWSStepper;
