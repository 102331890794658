import Chip from "@mui/material/Chip";
import omit from "lodash/omit";

import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { statusStyles } from "../../../utils/common";
import { type RampPlanStatus } from "../types";

type StatusChipColors = {
  backgroundColor: string;
  color: string;
};

type StatusChipConfig = Record<
  RampPlanStatus,
  {
    colors: StatusChipColors;
    label: string;
  }
>;

export const StatusChip = ({ status = "active", ...props }: { status: RampPlanStatus }) => {
  const isDarkMode = useDarkThemeCheck();
  const baseStatusStyles = statusStyles(isDarkMode);

  const statusChipConfig: StatusChipConfig = {
    active: {
      colors: baseStatusStyles.green,
      label: "Active",
    },
    inactive: {
      colors: baseStatusStyles.red,
      label: "Inactive",
    },
    notStarted: {
      colors: baseStatusStyles.grey,
      label: "Not Started",
    },
  };

  const chipProps = omit(props, "children");

  return (
    <Chip
      component="span"
      size="small"
      label={statusChipConfig[status].label}
      sx={statusChipConfig[status].colors}
      {...chipProps}
    />
  );
};
