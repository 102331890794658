import { useEffect, useMemo, useState } from "react";

import { type FlexsaveType, IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { useAsyncCurrency } from "../../Components/hooks/useCurrency";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useCustomerContext } from "../../Context/CustomerContext";
import { FlexsaveApi } from "./api";
import { type FlexsaveData } from "./types";
import { asyncConvertDataToCurrency } from "./utils/costUtils";
import { parseFlexsaveConfiguration } from "./utils/dataParsing";

export function useFlexsaveDoc(customerId: string) {
  const ref = useMemo(
    () => getCollection(IntegrationModel).doc("flexsave").collection("configuration").doc(customerId),
    [customerId]
  );

  return useDocumentData(ref);
}

export function useFlexsaveSageMaker(customerId: string) {
  const ref = useMemo(
    () => getCollection(IntegrationModel).doc("flexsave").collection("configuration-sagemaker").doc(customerId),
    [customerId]
  );
  return useDocumentData(ref);
}

export function useFlexsaveRDS(customerId: string) {
  const ref = useMemo(
    () => getCollection(IntegrationModel).doc("flexsave").collection("configuration-rds").doc(customerId),
    [customerId]
  );
  return useDocumentData(ref);
}

export function useFlexsaveEnableCheck(type: FlexsaveType) {
  const customerId = useCustomerId();
  const [doc, loading] = useFlexsaveDoc(customerId);

  return useMemo(() => {
    if (loading) {
      return [false, true];
    } else {
      const enabled = doc?.[type]?.enabled;
      return [Boolean(enabled), false];
    }
  }, [loading, doc, type]);
}

function useParsedFlexsaveData(filterType: FlexsaveType): [data: FlexsaveData | undefined, loading: boolean] {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [doc, loading] = useFlexsaveDoc(customer.id);
  return useMemo(
    () => [loading || !doc ? undefined : parseFlexsaveConfiguration(doc, filterType), loading],
    [loading, doc, filterType]
  );
}

export function useFlexsaveApi(type: FlexsaveType) {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  return useMemo(() => new FlexsaveApi(api, customer.id, type), [api, customer, type]);
}

export function useFlexsaveData(type: FlexsaveType): [data: FlexsaveData | undefined, loading: boolean] {
  const [data, loading] = useParsedFlexsaveData(type);
  const { asyncConvertCurrency } = useAsyncCurrency();
  const [convertedData, setConvertedData] = useState<FlexsaveData>();

  useEffect(() => {
    if (loading || !data) {
      setConvertedData(undefined);
      return;
    }

    asyncConvertDataToCurrency(data, asyncConvertCurrency).then((convertedData) => {
      setConvertedData(convertedData);
    });
  }, [asyncConvertCurrency, data, loading]);

  return [convertedData, loading];
}
