import { useEffect } from "react";

import { Alert, Snackbar } from "@mui/material";

import { useImpersonateContext } from "../../Context/impersonateContext";

export const ImpersonateAlert = () => {
  const { handleStopImpersonate, isActive, authTime, email } = useImpersonateContext();

  useEffect(() => {
    if (isActive) {
      const { seconds } = authTime.diffNow("second");
      // stop viewing user after an hour
      if (seconds <= -3600) {
        const t = setTimeout(handleStopImpersonate, 10000);

        return () => {
          clearTimeout(t);
        };
      }
    }
  }, [handleStopImpersonate, isActive, authTime]);

  if (!isActive) {
    return null;
  }

  return (
    <div style={{ height: 82 }}>
      <Snackbar open={true} autoHideDuration={null}>
        <Alert variant="standard" severity="info" onClose={handleStopImpersonate}>{`Viewing as "${email}"`}</Alert>
      </Snackbar>
    </div>
  );
};
