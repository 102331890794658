import MuiCircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export const CircularProgressLoader = styled(MuiCircularProgress)`
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
`;

export const Loader = ({ loading, children }) => (loading ? <CircularProgressLoader /> : children);
