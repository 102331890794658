import { useEffect, useState } from "react";

import { useMarketplaceContext } from "../../Context";
import { type accountsTableRowType } from "../../types";
import { constructTableData } from "./constructTableData";

export function useAccountsTableData() {
  const { accountsAndCustomers: data } = useMarketplaceContext();
  const [accountsTableData, setAccountsTableData] = useState<accountsTableRowType[]>([]);

  useEffect(() => {
    setAccountsTableData(constructTableData(data));
  }, [data]);

  return accountsTableData;
}
