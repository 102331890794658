import { useMemo } from "react";

import { type AutocompletePlugin } from "@algolia/autocomplete-js";
import {
  createLocalStorageRecentSearchesPlugin,
  type RecentSearchesPluginData,
  search,
} from "@algolia/autocomplete-plugin-recent-searches";

import { ClouflowAlgoliaSearchResultItem } from "../CloudflowAlgoliaSearchResultItem";
import { type AlgoliaCloudflowContext, type BaseCloudflowHit, type RecentCloudflowSearchedItem } from "../types";

export const ALGOLIA_MAX_RECENT_ITEMS = 10;
export const RECENT_CLOWDFLOW_SEARCH_KEY = (userId: string | undefined) =>
  `ALGOLIA_FILTER_CLOWDFLOW_${userId || "GUEST"}`;
export const createRecentCloudflowSearchesPlugin = (userId: string | undefined) =>
  createLocalStorageRecentSearchesPlugin<RecentCloudflowSearchedItem>({
    key: RECENT_CLOWDFLOW_SEARCH_KEY(userId),
    limit: ALGOLIA_MAX_RECENT_ITEMS,
    search(params) {
      if (params.query) {
        return [];
      }
      return search(params);
    },
    transformSource({ source, state }) {
      const context = state.context as AlgoliaCloudflowContext;
      const callback = (item: BaseCloudflowHit) => () => {
        const focusedNodeId = context?.focusedNodeId;
        if (focusedNodeId) {
          context?.customHandler?.(focusedNodeId, item);
        }
      };

      return {
        ...source,
        sourceId: "recentSearches",
        onSelect({ event, item }) {
          if (event.metaKey) {
            event.stopPropagation();
          }
          callback(item)();
        },
        templates: {
          item(params) {
            const { item } = params;
            return (
              <ClouflowAlgoliaSearchResultItem
                key={item.objectID}
                highlightedTitle={item.operationName}
                highlightedServiceName={item.serviceName}
                highlightedVersion={item.versionId}
                provider={item.provider}
                onClick={callback(item)}
              />
            );
          },
        },
      };
    },
  });

export const useRecentCloudflowSearchPlugin = (
  userId: string | undefined
): AutocompletePlugin<RecentCloudflowSearchedItem, RecentSearchesPluginData<RecentCloudflowSearchedItem>> | null =>
  useMemo(() => createRecentCloudflowSearchesPlugin(userId), [userId]);
