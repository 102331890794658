import { Link, useLocation } from "react-router-dom";
import { Button, TableCell } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { useEKSApi } from "../hooks";
import { type ClusterConfig } from "../types";
import { StatusBox } from "./StatusBox";

type Props = {
  row: ClusterConfig;
  onRemoveClusterClicked: (row: ClusterConfig) => void;
  onEditClusterClicked: (row: ClusterConfig) => void;
};

export const ClusterRow = ({ row, onRemoveClusterClicked }: Props) => {
  const location = useLocation();
  const eksApi = useEKSApi();

  const operationLabel = () => {
    if (row.status === "pending-update") {
      return "Update";
    } else if (row.status === "deleted") {
      return "Fix";
    } else {
      return "Connect";
    }
  };

  const optionsList = [
    row.status !== "not-connected" && {
      key: "3",
      label: "Download yaml",
      action: () => eksApi.downloadDeploymentFile(row),
    },
    row.isManual && {
      key: "2",
      label: "Remove cluster",
      action: () => {
        onRemoveClusterClicked(row);
        return Promise.resolve();
      },
    },
  ].filter(Boolean) as { key: string; label: string; action: () => void }[];

  const { clusterConfigRef, deploymentRef, ...rowWithoutRefs } = row;

  return (
    <CellsWrapper>
      <TableCell>{row.clusterName}</TableCell>
      <TableCell>{row.region}</TableCell>
      <TableCell>{row.accountId}</TableCell>

      <TableCell>
        <StatusBox
          cloudFormationStatus={row.status}
          bucketStatus={row?.bucketStatus}
          bucketLastMetricsData={row?.bucketLastMetricsData}
        />
      </TableCell>

      <TableCell component="th" scope="row">
        {row.status !== "active" && (
          <Link
            style={{ textDecoration: "none" }}
            to={{ pathname: `${location.pathname}/${row.id}`, state: { rowWithoutRefs } }}
          >
            <Button variant="text" color="primary" size="small">
              {operationLabel()}
            </Button>
          </Link>
        )}
      </TableCell>
      <TableCell padding="none">
        <ThreeDotsMenu closeAfterSelect options={optionsList} disabled={optionsList.length === 0} />
      </TableCell>
    </CellsWrapper>
  );
};
