import { type CSSProperties, type JSX, type ReactNode } from "react";

import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";

import { WidgetCardHeader } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";

type Props = {
  children: JSX.Element;
  headerAction?: ReactNode;
  style?: CSSProperties;
  subheader: string;
  title: string;
  widgetHeight: number;
};

export const FlexsaveWidgetCard = ({ title, subheader, headerAction, children, style, widgetHeight }: Props) => (
  <Card>
    <WidgetCardHeader title={title} subheader={subheader} action={headerAction} />
    <CardContent style={{ height: widgetHeight, ...style }}>{children}</CardContent>
  </Card>
);
