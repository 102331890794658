import { type DependencyList, useEffect, useRef } from "react";

export default function useUpdateEffect(effect: () => void, deps?: DependencyList) {
  const isMounted = useRef(false);
  // add a useEffect here
  useEffect(() => {
    isMounted.current = false;
  }, []);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
