import { type GSuiteAssetModel } from "@doitintl/cmp-models";

import { type LegacyDataFormat } from "../../../../Components/Catalog/Catalog.context";
import { type Asset, type Contract } from "../../../../types";
import LicenseChangeConfirmSummary from "../../components/LicenseChangeConfirmSummary";
import AckDialog from "../../Dialogs/AckDialog";

type Props = {
  open: boolean;
  onCancel: () => void;
  onAccept: () => void;
  currentMaxSeats: number;
  maxSeats: number;
  asset: Asset<GSuiteAssetModel>;
  contract?: Contract;
  catalogItem: LegacyDataFormat;
};

const cyIds = {
  text: {
    quantity: "quantity",
    skuName: "skuName",
    domain: "customerDomain",
    initial: "initialCost",
    recurring: "recurringCost",
    single: "singlePaymentAmount",
    discountInitial: "initialDiscount",
    discountRecurring: "recurringDiscount",
    discountSingle: "singleChargeDiscount",
    endDate: "singlePaymentEndDate",
  },
  section: {
    summary: "summaryParagraph",
    initial: "initialCostSection",
    recurring: "recurringCostSection",
    single: "singleCostSection",
  },
};
export const { text, section } = cyIds;

const GSuiteLicenseChangeConfirm = ({
  open,
  onCancel,
  onAccept,
  currentMaxSeats,
  maxSeats,
  asset,
  contract,
  catalogItem,
}: Props) => (
  <AckDialog
    open={open}
    onCancel={onCancel}
    onAccept={onAccept}
    title={currentMaxSeats < maxSeats ? "Add licenses" : "Remove licenses"}
    text={
      <LicenseChangeConfirmSummary
        asset={asset}
        maxSeats={maxSeats}
        currentMaxSeats={currentMaxSeats}
        catalogItem={catalogItem}
        contract={contract}
      />
    }
  />
);

export default GSuiteLicenseChangeConfirm;
