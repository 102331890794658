import { useCallback, useRef, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, CircularProgress, Grow, IconButton, Paper, Popper, Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useTheme } from "@mui/material/styles";

import { getChipConfigByPurchaseStatus } from "../../utils/purchaseStatusUtils";
import { StatusBreakdown } from "./StatusBreakdown";
import { type StatusTooltipProps } from "./types";

const RefreshingStatus = ({ statusText, textColor }: { statusText: string; textColor?: string }) => (
  <Box display="flex" alignItems="center" gap={1}>
    <Typography color={textColor}>{statusText}</Typography>
    <CircularProgress size={16} />
  </Box>
);

export const AggregatedPurchaseStatus = ({ status, workloads }: StatusTooltipProps) => {
  const theme = useTheme();
  const { bgColor, textColor, text } = getChipConfigByPurchaseStatus(theme, status);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback(
    (event) => {
      if (anchorRef.current?.contains(event.target)) {
        return;
      }
      setOpen(false);
    },
    [anchorRef]
  );

  if (status === "refreshing") {
    return <RefreshingStatus statusText={text} textColor={textColor} />;
  }

  return (
    <Stack direction="row" alignItems="center">
      <Chip
        label={text}
        sx={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      />
      <IconButton aria-label="info about status" onClick={handleToggle} ref={anchorRef}>
        <InfoOutlinedIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} transition placement="right">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper elevation={2}>
              <ClickAwayListener onClickAway={handleClose}>
                <Stack m={1} padding={1} maxWidth={300}>
                  <StatusBreakdown status={status} workloads={workloads} />
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Stack>
  );
};
