import { Stack, Typography } from "@mui/material";

import { formatDecimalNumber } from "../../../utils/common";

export const CustomProblemsPreview = ({
  value,
  unitLongSingular,
  unitLongPlural,
}: {
  value: number;
  unitLongSingular: string;
  unitLongPlural: string;
}) => (
  <Stack direction="row" spacing={1.5} alignItems="center">
    <Typography variant="h1" color="success.main" fontSize={36} lineHeight={44 / 36}>
      {formatDecimalNumber(value, 0)}
    </Typography>
    <Typography variant="subtitle2" maxWidth={130} fontSize={12} lineHeight={16 / 12}>
      {value === 1 ? unitLongSingular : unitLongPlural}
    </Typography>
  </Stack>
);
