import { useMemo } from "react";

import { ComparativeSign } from "../utilities";
import type ReportData from "../ReportData";

const isValidColKey = (colKeyStr: string, isComparative: boolean): boolean =>
  !isComparative || (isComparative && colKeyStr.includes(ComparativeSign));

type useHighchartsRendererProps = {
  data: ReportData | null;
  isComparative: boolean;
};

export type ChartSeriesRecord = {
  id?: string;
  type?: string;
  color?: string;
  name: string;
  data: number[];
  dashStyle?: string;
  lineWidth?: number;
  tooltip?: any;
  marker?: any;
};

export type useHighchartsRendererResult = {
  categories: string[];
  series: ChartSeriesRecord[];
  reversed: boolean;
};

function useHighchartsRenderer({
  data,
  isComparative = false,
}: useHighchartsRendererProps): useHighchartsRendererResult {
  return useMemo(() => {
    const categories: string[] = [];
    const series: ChartSeriesRecord[] = [];
    let reversed = true;
    if (!data) {
      return { categories, series, reversed };
    }

    const rowKeys = data.getRowKeys();
    const colKeys = data.getColKeys();

    if (rowKeys.length === 0) {
      rowKeys.push([]);
    }
    if (colKeys.length === 0) {
      colKeys.push([]);
    }
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      categories.push("Total");
    } else {
      for (const colKey of colKeys) {
        const colId = colKey.join("-");
        if (isValidColKey(colId, isComparative)) {
          categories.push(colId);
        }
      }
    }

    rowKeys.forEach((rowKey) => {
      let name;
      if (rowKeys.length === 1 && rowKeys[0].length === 0) {
        name = "Total";
      } else {
        name = rowKey.join(";");
      }
      const s: ChartSeriesRecord = {
        // boostThreshold: 100,
        name,
        data: [],
      };

      colKeys.forEach((colKey) => {
        const colId = colKey.join("-");
        if (isValidColKey(colId, isComparative)) {
          const value: number = data.getAggregator(rowKey, colKey).value();
          s.data.push(value);
          reversed = reversed && value <= 0;
        }
      });

      series.push(s);
    });

    return { categories, series, reversed };
  }, [data, isComparative]);
}

export default useHighchartsRenderer;
