import { useState } from "react";

import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { CircularProgress, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";

import mixpanel from "../../../utils/mixpanel";

type Props = {
  reportPath: string;
  helpUrl?: string;
  loading?: boolean;
  handleRefresh?: () => void;
};

export const MenuWidgetCard = ({ reportPath, helpUrl, loading, handleRefresh }: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpenHelp = () => {
    mixpanel.track("dashboard.widget.help");
  };

  return (
    <Stack alignItems="center">
      {loading && <CircularProgress disableShrink color="primary" size={24} thickness={4.8} />}
      <IconButton
        id="widget-menu-button"
        aria-controls="basic-menu"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenMenu}
        aria-haspopup="true"
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {handleRefresh && (
          <MenuItem disabled={loading} onClick={handleRefresh}>
            Refresh
          </MenuItem>
        )}
        <MenuItem component={Link} to={reportPath}>
          Open full report
        </MenuItem>
        {!!helpUrl && (
          <MenuItem component="a" href={helpUrl} target="_blank" onClick={handleClickOpenHelp}>
            <Typography display="flex" alignItems="center">
              Learn more about this widget <OpenNewIcon sx={{ fontSize: 16, marginLeft: 1 }} />
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Stack>
  );
};
