import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import MyCustomersEmptyStateIcon from "../../assets/my-customers-empty-state.svg";
import MyTeamsCustomersEmptyStateIcon from "../../assets/my-teams-customers-empty-state.svg";
import { myCustomersListTexts, myTeamsCustomersListTexts } from "../../assets/texts";
import { DoerType } from "./types";

type Props = {
  isMyCustomersPage: boolean;
  doerType: DoerType;
  onClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  subtitle: {
    maxWidth: 580,
    textAlign: "center",
    marginTop: theme.spacing(2.5),
  },
}));

export const EmptyState = ({ isMyCustomersPage, doerType, onClick }: Props) => {
  const classes = useStyles();

  const titleText = isMyCustomersPage
    ? myCustomersListTexts.EMPTY_STATE_TITLE
    : myTeamsCustomersListTexts.EMPTY_STATE_TITLE;

  const getSubtitleText = () => {
    const subtitle1 =
      doerType === DoerType.Partner ? myCustomersListTexts.PARTNER_SUBTITLE : myCustomersListTexts.SUBTITLE;
    const subtitle2 = doerType === DoerType.Fsr ? myCustomersListTexts.SUBTITLE2 : "";

    return isMyCustomersPage ? `${subtitle1}${subtitle2}` : myTeamsCustomersListTexts.SUBTITLE;
  };

  const getButton = () => {
    const buttonText = isMyCustomersPage
      ? myCustomersListTexts.CREATE_NEW_CUSTOMER
      : myTeamsCustomersListTexts.GO_TO_AM;
    const shouldShowButton = !isMyCustomersPage || doerType === DoerType.Fsr;

    return (
      shouldShowButton && (
        <Button size="large" variant="contained" color="primary" onClick={onClick} style={{ marginTop: 24 }}>
          {buttonText}
        </Button>
      )
    );
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: 8, alignItems: "center" }}
    >
      <img src={isMyCustomersPage ? MyCustomersEmptyStateIcon : MyTeamsCustomersEmptyStateIcon} />
      <Typography style={{ marginTop: 30 }} variant="h5">
        {titleText}
      </Typography>
      <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
        {getSubtitleText()}
      </Typography>
      {getButton()}
    </Box>
  );
};
