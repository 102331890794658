import { useEffect, useMemo, useState } from "react";

import {
  AccountManagerCompanies,
  AccountManagerModel,
  AccountManagerStatuses,
  type AccountTeam,
  AppModel,
} from "@doitintl/cmp-models";
import {
  type CollectionDataHook,
  getCollection,
  type ModelIdRef,
  useCollectionData,
  useDocumentData,
} from "@doitintl/models-firestore";

import { type AccountRole } from "../../utils/common";
import { doitDomain, doitPreviousDomain } from "../../utils/domains";
import { useAuthContext } from "../AuthContext";
import { useCustomerContext } from "../CustomerContext";

// if the given account manager is terminated - find replacement on his managers hierarchy. return null if cannot find a replacement
const replaceAccountManagerIfTerminated = async (
  accountManager: ModelIdRef<AccountManagerModel>
): Promise<ModelIdRef<AccountManagerModel> | null> => {
  if (accountManager.status === AccountManagerStatuses.TERMINATED) {
    if (!accountManager.manager) {
      return null;
    }

    const managerSnap = await accountManager.manager.get();

    const data = managerSnap.asModelData();

    if (data && accountManager.id !== managerSnap.id) {
      const newAccountManager = {
        ...data,
        id: managerSnap.id,
        ref: managerSnap.modelRef,
      };

      return replaceAccountManagerIfTerminated(newAccountManager);
    }

    return null;
  }

  return accountManager;
};

const getAccountManagersForAccountTeam = async (
  accountTeam?: AccountTeam,
  isDoitPartner?: boolean,
  partnerCompany?: AccountManagerCompanies
) => {
  if (!accountTeam) {
    return [];
  }

  const accountTeamIds = accountTeam
    .filter((am) =>
      isDoitPartner ? [partnerCompany, AccountManagerCompanies.DOIT as string].includes(am.company) : true
    )
    .map((am) => am.ref.id);

  if (accountTeamIds.length === 0) {
    return [];
  }

  const getDocs = async () => {
    const teamAccountManagers = await Promise.all(
      accountTeamIds.map(async (id): Promise<ModelIdRef<AccountManagerModel> | undefined> => {
        const doc = await getCollection(AccountManagerModel).doc(id).get();
        const data = doc.asModelData();
        if (!data) {
          return;
        }

        return { id: doc.id, ref: doc.modelRef, ...data };
      })
    );

    return teamAccountManagers.filter((am): am is ModelIdRef<AccountManagerModel> => !!am);
  };

  const docsData = await getDocs();
  const accountManagers = await Promise.all(
    docsData.map((accountManager) => {
      if (accountManager.company === "doit") {
        return replaceAccountManagerIfTerminated(accountManager);
      }

      return accountManager;
    })
  );

  const newAccountManagers: ModelIdRef<AccountManagerModel>[] = [];
  accountManagers.forEach((accountManager) => {
    if (!accountManager) {
      return;
    }

    if (accountManager.company === "doit") {
      newAccountManagers.unshift(accountManager);
    } else {
      newAccountManagers.push(accountManager);
    }
  });

  return newAccountManagers;
};

const useAccountManagersRoles = (): CollectionDataHook<AccountRole> => {
  const [data, loading, error] = useDocumentData(getCollection(AppModel).doc("account-manager-roles"), {
    caching: true,
  });

  return useMemo((): CollectionDataHook<AccountRole> => {
    if (loading || error) {
      return [[], loading, error] as CollectionDataHook<AccountRole>;
    }

    return [data?.roles ?? [], loading, error] as CollectionDataHook<AccountRole>;
  }, [data, error, loading]);
};

const useAccountManager = () => {
  const { currentUser, isDoitEmployee, isDoitPartner, userId } = useAuthContext({
    mustHaveUser: true,
  });

  const [accountManagerSearchByEmail, setAccountManagerSearchByEmail] = useState<string>();

  const accountManagerQuery = useMemo(() => {
    if (!isDoitPartner && !isDoitEmployee) {
      return;
    }

    setAccountManagerSearchByEmail(undefined);

    return getCollection(AccountManagerModel).doc(userId);
  }, [isDoitEmployee, isDoitPartner, userId]);

  const [accountManagerByUserId, accountManagerByUserIdLoading, accountManagerByUserIdLoadingError] = useDocumentData(
    accountManagerQuery,
    {
      idField: "id",
      refField: "ref",
      caching: true,
    }
  );

  const accountManageByEmailQuery = useMemo(() => {
    if (!accountManagerSearchByEmail) {
      return;
    }

    const emailParts = accountManagerSearchByEmail.split("@");
    const otherEmail =
      emailParts[1] === doitPreviousDomain
        ? `${emailParts[0]}@${doitDomain}`
        : `${emailParts[0]}@${doitPreviousDomain}`;

    return getCollection(AccountManagerModel).where("email", "in", [accountManagerSearchByEmail, otherEmail]).limit(1);
  }, [accountManagerSearchByEmail]);

  const [accountManagerByEmail, accountManagerByEmailLoading] = useCollectionData(accountManageByEmailQuery, {
    idField: "id",
    refField: "ref",
    caching: true,
  });

  useEffect(() => {
    if (accountManagerByUserIdLoading || accountManagerByUserIdLoadingError) {
      return;
    }

    if (!accountManagerByUserId) {
      setAccountManagerSearchByEmail(currentUser.email);
    }
  }, [accountManagerByUserId, accountManagerByUserIdLoading, accountManagerByUserIdLoadingError, currentUser.email]);

  const accountManager = useMemo(() => {
    if (accountManagerByUserIdLoading) {
      return undefined;
    }

    if (accountManagerByUserId) {
      return accountManagerByUserId;
    }

    if (accountManagerByEmail) {
      return accountManagerByEmail.length > 0 ? accountManagerByEmail[0] : null;
    }

    if (accountManagerByEmailLoading) {
      return undefined;
    }

    return null;
  }, [accountManagerByEmail, accountManagerByEmailLoading, accountManagerByUserId, accountManagerByUserIdLoading]);

  return { accountManager };
};

const useAllAccountManagers = () =>
  useCollectionData(getCollection(AccountManagerModel), {
    idField: "id",
    refField: "ref",
    caching: true,
  });

const useAllDoersAccountManagers = () =>
  useCollectionData(getCollection(AccountManagerModel).where("company", "==", "doit"), {
    idField: "id",
    refField: "ref",
    caching: true,
  });

const useAccountManagersForCustomer = () => {
  const { isDoitPartner, partnerCompany } = useAuthContext({
    mustHaveUser: true,
  });

  const { customer } = useCustomerContext();
  const [accountManagers, setAccountManagers] = useState<ModelIdRef<AccountManagerModel>[]>();

  useEffect(() => {
    getAccountManagersForAccountTeam(
      customer?.accountTeam,
      isDoitPartner,
      partnerCompany as AccountManagerCompanies
    ).then((accountManagers) => {
      setAccountManagers(accountManagers);
    });
  }, [customer?.accountTeam, isDoitPartner, partnerCompany]);

  return accountManagers;
};

export type SortedAccountManagersByCompany = { [key: string]: { [key: string]: string[] } };
export const sortAccountManagersByCompany = (
  data: ModelIdRef<AccountManagerModel>[] | undefined
): SortedAccountManagersByCompany => {
  const result = {};

  if (!data || !Array.isArray(data)) {
    return {};
  }

  data.forEach((item) => {
    const company = item.company;

    if (!result[company]) {
      result[company] = {};
    }

    if (item.role && item.email) {
      if (!result[company][item.role]) {
        result[company][item.role] = [];
      }
      result[company][item.role].push(item.email);
    }
  });

  return result;
};

export const AccountManagersHooks = {
  useAccountManagersRoles,
  useAccountManager,
  useAllAccountManagers,
  useAllDoersAccountManagers,
  useAccountManagersForCustomer,
} as const;
