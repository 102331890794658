import { Currencies, type CurrencyCode, CurrencyCodes } from "@doitintl/cmp-models";
import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material";

import { useLocalStorage } from "../../../../../Components/hooks/storageHooks";
import useMountEffect from "../../../../../Components/hooks/useMountEffect";

type Props = {
  onCurrencyChange: (currency: CurrencyCode) => void;
};

export const CurrencySelector = ({ onCurrencyChange }: Props) => {
  const [currency, setCurrency] = useLocalStorage<CurrencyCode>("explainer_currency", CurrencyCodes.USD);

  const handleCurrencyChange = (event: SelectChangeEvent<string>) => {
    setCurrency(event.target.value as CurrencyCode);
    onCurrencyChange(event.target.value as CurrencyCode);
  };

  useMountEffect(() => {
    onCurrencyChange(currency);
  });

  return (
    <FormControl fullWidth>
      <InputLabel id="currency-selector">Currency</InputLabel>
      <Select
        labelId="currency-selector"
        label="Currency"
        value={currency}
        onChange={handleCurrencyChange}
        size="small"
        fullWidth
      >
        {Currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.symbol} {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
