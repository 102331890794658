import { useRouteMatch } from "react-router-dom";

const useRouteMatchURL = (): string => {
  const routeMatch = useRouteMatch();
  let url = routeMatch?.url ?? "";
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }
  return url;
};

export default useRouteMatchURL;
