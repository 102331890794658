import { createContext, useContext } from "react";

import { type accountAndCustomerType } from "./types";

type MarketplaceContextProps = {
  accountsAndCustomers: accountAndCustomerType[];
};

export const MarketplaceContext = createContext<MarketplaceContextProps>({
  accountsAndCustomers: [],
});

export const useMarketplaceContext = () => useContext(MarketplaceContext);
