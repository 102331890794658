import { createContext, type Dispatch, type ReactNode, useContext, useMemo } from "react";

import { type ReportTemplatesAction } from "../reducers/ReportTemplatesReducer";
import { type ReportTemplateCard } from "../templateLibrary/types";

type ReportTemplateContextType = {
  reportTemplateId: string;
  isFavoriteReportTemplate: boolean;
  reportTemplateCards: ReportTemplateCard[];
  dispatchReportTemplates: Dispatch<ReportTemplatesAction>;
};

export const ReportTemplateContext = createContext<ReportTemplateContextType>({
  reportTemplateId: "",
  isFavoriteReportTemplate: false,
  reportTemplateCards: [],
  dispatchReportTemplates: () => {},
});

export const ReportTemplateContextProvider = ({
  children,
  reportTemplateId,
  isFavoriteReportTemplate,
  reportTemplateCards,
  dispatchReportTemplates,
}: {
  children?: ReactNode;
} & ReportTemplateContextType) => {
  const reportTemplateVal = useMemo(
    () => ({ reportTemplateId, isFavoriteReportTemplate, reportTemplateCards, dispatchReportTemplates }),
    [reportTemplateId, isFavoriteReportTemplate, reportTemplateCards, dispatchReportTemplates]
  );
  return <ReportTemplateContext.Provider value={reportTemplateVal}>{children}</ReportTemplateContext.Provider>;
};

export const useReportTemplateContext = (): ReportTemplateContextType => useContext(ReportTemplateContext);
