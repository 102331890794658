import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, Icon } from "@mui/material";

import { marketplaceMap } from "../helpers";

export const MarketplaceListingButton = ({ handler, platform }: { handler: () => void; platform: string }) => (
  <Button
    onClick={handler}
    variant="outlined"
    startIcon={
      <Icon sx={{ ml: 1 }}>
        <img src={marketplaceMap[platform]?.logo} alt="logo" width="100%" />
      </Icon>
    }
    size="large"
    endIcon={<OpenInNewIcon />}
  >
    View on {marketplaceMap[platform]?.title} marketplace
  </Button>
);
