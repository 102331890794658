import { Stack } from "@mui/material";

import Title from "../Common/Title";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

export const Header = () => {
  const { updateNode, nodeConfig } = useNodeConfigurationContext();

  const handleUpdateTitle = (title: string) => {
    updateNode({ name: title });
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Title title={nodeConfig.name} onUpdateTitle={handleUpdateTitle} placeholder="Add Node name" />
    </Stack>
  );
};
