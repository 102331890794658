import { useCallback } from "react";

import { Classification, type PointOfSaleType, type TierPackageType } from "@doitintl/cmp-models";

import { useApiContext } from "../../../api/context";
import { updateCustomerWidgets } from "../../Customer/db";

export function useSetCustomerTiers() {
  const api = useApiContext();

  return useCallback(
    async (
      customerId: string,
      payload: {
        navigatorTierId: string | undefined;
        solveTierId: string | undefined;
      }
    ) => {
      const response = await api.patch(`/v1/customers/${customerId}/tier`, payload);
      return response.data;
    },
    [api]
  );
}

export function useCreateTrialContract() {
  const api = useApiContext();

  return useCallback(
    async (payload: {
      customerID: string;
      type: TierPackageType;
      tier: string;
      startDate: string;
      endDate?: string;
      currency: string;
    }) => {
      const response = await api.post("/v1/contract/create", payload);
      return response.data;
    },
    [api]
  );
}

export function useCancelTrialContract() {
  const api = useApiContext();

  return useCallback(
    async (contractId: string) => {
      const response = await api.post(`/v1/contract/cancel/${contractId}`);
      return response.data;
    },
    [api]
  );
}

type UpdateContractRequest = Partial<{
  tier: string;
  startDate: string;
  endDate: string;
  entityID: string;
  type: string;
  discount: number;
  accountManager: string;
  estimatedValue: number;
  notes: string;
  purchaseOrder: string;
  commitmentMonths: number;
  pointOfSale: PointOfSaleType;
  paymentTerm: string;
  chargePerTerm: number;
  monthlyFlatRate: number;
  isCommitment: boolean;
}>;

export function useUpdateTrialContract() {
  const api = useApiContext();

  return useCallback(
    async (contractId: string, updates: UpdateContractRequest) => {
      const response = await api.post(`/v1/contract/update/${contractId}`, updates);
      return response.data;
    },
    [api]
  );
}

export function useChangePresentationMode() {
  const api = useApiContext();

  return useCallback(
    async (customerId: string) => {
      const response = await api.request({
        method: "POST",
        url: `/v1/customers/${customerId}/presentation-mode/toggle`,
      });
      return response.data;
    },
    [api]
  );
}

export function useEditCustomerNotifications() {
  const api = useApiContext();

  return useCallback(
    async ({
      oldClassification,
      newClassification,
      customerId,
    }: {
      oldClassification: Classification;
      newClassification: Classification;
      customerId: string;
    }) => {
      const shouldClearNotifications =
        [Classification.SuspendedForNonPayment, Classification.Terminated].includes(newClassification) &&
        [Classification.Business].includes(oldClassification) &&
        ![Classification.SuspendedForNonPayment, Classification.Terminated].includes(oldClassification);

      const shouldRestoreNotification =
        [Classification.Business].includes(newClassification) &&
        [Classification.SuspendedForNonPayment, Classification.Terminated].includes(oldClassification) &&
        ![Classification.Business].includes(oldClassification);

      if (shouldClearNotifications) {
        await api.put(`v1/customers/${customerId}/clear-users-notifications`);
      } else if (shouldRestoreNotification) {
        await api.put(`v1/customers/${customerId}/restore-users-notifications`);
      }
    },
    [api]
  );
}

export function useUpdateCustomerWidgets() {
  const api = useApiContext();

  return useCallback(
    async (customerId: string, isPrioritized: boolean) => updateCustomerWidgets(api, customerId, isPrioritized),
    [api]
  );
}

export type DeleteCustomerResponse = {
  executionPossible: boolean;
};

export function useDeleteCustomer() {
  const api = useApiContext();

  return useCallback(
    async (customerId: string, execute: boolean): Promise<DeleteCustomerResponse> => {
      const response = await api.request({
        method: "DELETE",
        url: `/v1/customers/${customerId}`,
        data: {
          execute,
        },
      });
      return response.data;
    },
    [api]
  );
}
