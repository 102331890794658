import keys from "lodash/keys";
import pickBy from "lodash/pickBy";
import some from "lodash/some";
import valuesIn from "lodash/valuesIn";
import { DateTime } from "luxon";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import LinearProgress from "@mui/material/LinearProgress";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";

import { useCallback, useEffect, useState } from "react";
import { getCollection } from "@doitintl/models-firestore";
import { BillingModel, ChannelModel } from "@doitintl/cmp-models";
import { consoleErrorWithSentry } from "../../../utils";
import { useApiContext } from "../../../api/context";
import { useAuthContext } from "../../../Context/AuthContext";
import RevenueCard from "./RevenueCard";

const useStyles = makeStyles((theme) => ({
  cardActions: {
    padding: theme.spacing(2, 1, 1),
  },
  select: {
    minWidth: 180,
  },
}));

const maxDate = DateTime.utc().endOf("month");
const minDate = DateTime.fromObject({ year: 2019, month: 3, day: 1 });

const Invoicing = ({ date, onChangeDate }) => {
  const classes = useStyles();
  const api = useApiContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const [isSubmitting, setSubmitting] = useState(true);
  const [channel, setChannel] = useState(null);
  const [cogs, setCogs] = useState([]);
  const [currRevenue, setCurrRevenue] = useState(null);
  const [prevRevenue, setPrevRevenue] = useState(null);
  const [readyInvoices, setReadyInvoices] = useState(0);
  const [includeTypes, setIncludeTypes] = useState({
    "g-suite": false,
    "office-365": false,
    "amazon-web-services": false,
    "amazon-web-services-standalone": false,
    "google-cloud": false,
    "google-cloud-standalone": false,
    looker: false,
    navigator: false,
    solve: false,
    "solve-accelerator": false,
    "doit-cloud-intelligence": false,
  });
  const [isOverride, setOverride] = useState(false);

  const generateInvoices = useCallback(async () => {
    try {
      setSubmitting(true);

      await api.request({
        method: "post",
        url: "/v1/invoicing/export",
        data: {
          year: date.year,
          month: date.month,
          types: keys(pickBy(includeTypes, (v) => v === true)),
          override: isOverride,
        },
      });
    } catch (error) {
      consoleErrorWithSentry(error);
      setSubmitting(false);
    }
  }, [api, date.year, date.month, includeTypes, isOverride]);

  useEffect(
    () =>
      getCollection(ChannelModel)
        .where("uid", "==", currentUser.uid)
        .where("complete", "==", false)
        .where("type", "==", "billing.invoicing")
        .orderBy("timestamp", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          if (querySnapshot.empty) {
            setSubmitting(false);
            setChannel(null);
          } else {
            const docSnapshot = querySnapshot.docs[0];
            const data = docSnapshot.data();
            // Make sure we are not listening on a dead channel
            const timestamp = DateTime.fromJSDate(data.timestamp.toDate());
            const diff = timestamp.diffNow("minute");
            if (diff.minutes < -5) {
              docSnapshot.ref.update({ complete: true });
            } else {
              setChannel({
                data,
                snapshot: docSnapshot,
              });
            }
          }
        }),
    [currentUser.uid]
  );

  useEffect(() => {
    (async () => {
      const querySnapshot = await getCollection(BillingModel)
        .doc("cogs")
        .collection("cogsLines")
        .where("date", "==", date.toJSDate())
        .get();

      setCogs(querySnapshot.docs.map((docSnap) => ({ data: docSnap.data(), snapshot: docSnap })));
    })();
  }, [date]);

  useEffect(() => {
    const monthsCollection = getCollection(BillingModel).doc("invoicing").collection("invoicingMonths");

    const listener1 = monthsCollection.doc(date.toFormat("yyyy-LL")).onSnapshot((docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data.numInvoices > 0) {
          setCurrRevenue(data.products);
          return;
        }
      }
      setCurrRevenue(null);
    });

    const listener2 = monthsCollection.doc(date.minus({ months: 1 }).toFormat("yyyy-LL")).onSnapshot((docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data.numInvoices > 0) {
          setPrevRevenue(data.products);
          return;
        }
      }
      setPrevRevenue(null);
    });

    return () => {
      if (listener1) {
        listener1();
      }
      if (listener2) {
        listener2();
      }
    };
  }, [date]);

  useEffect(() => {
    const monthsCollection = getCollection(BillingModel).doc("invoicing").collection("invoicingMonths");
    const listener3 = monthsCollection.doc(date.toFormat("yyyy-LL")).onSnapshot((docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data?.stats?.["amazon-web-services"]?.numCustomersReady !== undefined) {
          setReadyInvoices(data.stats["amazon-web-services"].numCustomersReady);
        }
      }
    });

    return () => {
      if (listener3) {
        listener3();
      }
    };
  }, [date]);

  const onChange = (type) => (event) => {
    setIncludeTypes((prevIncludeTypes) => ({
      ...prevIncludeTypes,
      [type]: event.target.checked,
    }));
  };

  // override switch is only shown for AWS
  const onChangeOverride = () => (event) => {
    setOverride(event.target.checked);
  };

  return (
    <>
      <CardActions className={classes.cardActions}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <DatePicker
              renderInput={(params) => <TextField margin="none" fullWidth {...params} />}
              label="Billing Month"
              disabled={isSubmitting}
              inputVariant="outlined"
              value={date}
              onChange={onChangeDate}
              format="LLLL, yyyy"
              views={["year", "month"]}
              openTo="month"
              minDate={minDate}
              maxDate={maxDate}
              autoOk
            />
          </Grid>
          <Grid item xs>
            {channel && channel.data.state === "progress" && (
              <LinearProgress variant="determinate" value={Math.min((channel.data.progress || 0) * 100, 100)} />
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={generateInvoices}
              disabled={!some(valuesIn(includeTypes), (v) => v) || isSubmitting || channel}
            >
              {channel ? channel.data.state : "Generate"}
            </Button>
          </Grid>
        </Grid>
      </CardActions>

      {currRevenue && (
        <Grid container spacing={1}>
          {Object.keys(currRevenue)
            .sort()
            .map((type) => (
              <Grid item xs={12} md={6} key={type}>
                <RevenueCard
                  type={type}
                  include={includeTypes[type]}
                  overrideActivated={isOverride}
                  onChangeOverride={onChangeOverride(type)}
                  onChange={onChange(type)}
                  currData={currRevenue[type]}
                  prevData={prevRevenue ? prevRevenue[type] : null}
                  cogs={cogs.filter((c) => c.data.type === type)}
                  readyInvoices={readyInvoices}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  );
};

export default Invoicing;
