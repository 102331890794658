import { useEffect } from "react";

import { type FlexsaveType } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { getSuccessOnboardingImage } from "../../../../utils/assetResolver";
import mixpanel from "../../../../utils/mixpanel";

type Props = {
  type: FlexsaveType;
};

export const OnboardingSuccess = ({ type }: Props) => {
  useEffect(() => {
    mixpanel.track("flexsaveSA.activated", { type });
  }, [type]);

  return (
    <Stack spacing={2} alignItems="center" maxWidth="md">
      <img src={getSuccessOnboardingImage()} width="100" height="auto" />
      <Typography variant="h1" sx={{ alignSelf: "center", fontWeight: "500", pt: 2 }}>
        We’re building a plan to optimize your savings
      </Typography>
      <Typography textAlign="center" variant="body1" color="text.secondary">
        We’re currently processing your information and building a plan to optimize your savings. This can take up to{" "}
        <b>24 hours.</b> We will let you know by email when your savings are ready.
      </Typography>
    </Stack>
  );
};
