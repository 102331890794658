import { FirestoreTimestamp } from "../../utils/firebase";
import { NOT_APPLICABLE } from "../KnownIssueView/consts";

export const formatDateToKnownIssueDate = (date: Date): FirestoreTimestamp => FirestoreTimestamp.fromDate(date);

export const getProducts = (knownIssueItem): string => {
  if (knownIssueItem?.products?.length > 0) {
    return knownIssueItem?.products.join(", ");
  }

  return knownIssueItem?.affectedProduct || NOT_APPLICABLE;
};
