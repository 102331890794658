import { Loader } from "../../../../Components/Loader";
import useGetRampPlans from "../../../../Pages/RampPlans/hooks/useGetRampPlans";
import mixpanel from "../../../../utils/mixpanel";
import RampListNew from "./RampListNew";
import RampsOnboard from "./RampsOnboard";

export const RampPlansNew = () => {
  const { rampPlans, takenNamesArr, loading } = useGetRampPlans();

  mixpanel.track("customers.ramps");

  if (rampPlans && rampPlans.length === 0) {
    return <RampsOnboard />;
  }

  return (
    <Loader loading={loading || rampPlans === undefined}>
      <RampListNew rampPlans={rampPlans || []} takenNamesArr={takenNamesArr ?? []} />
    </Loader>
  );
};

export default RampPlansNew;
