import { type ApiServiceDescriptor, CloudflowEngine, type CloudFlowProvider } from "@doitintl/cmp-models";
import { getCollection, type ModelData } from "@doitintl/models-firestore";

export async function getApiServiceDescriptor(
  provider: CloudFlowProvider,
  service: string
): Promise<ModelData<ApiServiceDescriptor>> {
  const operationDescriptor = await getCollection(CloudflowEngine)
    .doc("integrations")
    .collection("apis")
    .doc(provider)
    .collection("services")
    .doc(service)
    .get();

  const operationDescriptorData = operationDescriptor.asModelData();
  if (operationDescriptorData === undefined) {
    throw new Error();
  }

  return operationDescriptorData;
}
