import { useMemo } from "react";

import { FilterTableSkeleton } from "../../Components/FilterTable/FilterTableSkeleton";
import { useAuthContext } from "../../Context/AuthContext";
import { type Entity } from "../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import BillingProfilesFilterTable from "./BillingProfilesFilterTable";

const BillingProfiles = () => {
  const { entities, entitiesLoading } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();

  const entitiesFilteredByAuth = useMemo<Entity[]>(
    () => (isDoitEmployee ? entities : entities.filter((entity) => entity.active)),
    [isDoitEmployee, entities]
  );

  return entitiesLoading ? <FilterTableSkeleton /> : <BillingProfilesFilterTable entities={entitiesFilteredByAuth} />;
};

export default BillingProfiles;
