import { useTranslation } from "react-i18next";
import VpnIcon from "@mui/icons-material/VpnKey";
import { Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useGetSigninIconColors } from "../hooks";

type props = {
  handleLoginSso: () => void;
  disabled?: boolean;
};

function SsoLoginButton({ handleLoginSso, disabled }: props) {
  const theme = useTheme();
  const iconColor = useGetSigninIconColors();
  const { t } = useTranslation("login");

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" width="100%">
      <Button
        color="primary"
        data-cy="use-login-sso"
        onClick={handleLoginSso}
        variant="outlined"
        fullWidth
        disabled={disabled}
        startIcon={<VpnIcon style={{ fontSize: 28, color: iconColor }} />}
        sx={{
          color: theme.palette.text.primary,
          borderColor: theme.palette.grey.A400,
          textTransform: "none",
          height: 41,
        }}
      >
        {t("sso.signin")}
      </Button>
    </Stack>
  );
}

export default SsoLoginButton;
