import getLogo from "./utils/logos/logo";

const { logo } = getLogo();
const navigatorSubscriptionURL = "https://www.doit.com/pricing/#navigator";

type Theme = {
  navigation: string;
  primary: string;
  primaryGradient: string;
  secondaryGradient: string;
  primaryButtonTextColor: string;
  navigationTextColor: string;
  accent: string;
};

type Assets = {
  favIcon: string;
  logoURL: string;
};

type Urls = {
  preferenceCenterURL: string;
  privacyPolicyURL: string;
  serviceTermsURL: string;
  solutionBriefURL: string;
  contactSalesURL: string;
  navigatorSubscriptionURL: string;
};

type Config = {
  title: string;
  companyName: string;
  theme?: Theme;
  assets: Assets;
  urls: Urls;
};

type ConfigObj = Record<string, Config>;

const sConfig: ConfigObj = {
  "console.serverless.co.il": {
    title: "Your Company Name",
    companyName: "Your Company Name",
    theme: {
      navigation: "#D1D7FF",
      primary: "#0000FF",
      primaryGradient: "#FFFFFF",
      secondaryGradient: "#FFFFFF",
      primaryButtonTextColor: "#FFFFFF",
      navigationTextColor: "#000000",
      accent: "#0000FF",
    },
    assets: {
      favIcon: "https://app.doit.com/static/media/aws-logo.c3f584db595a62ed3d2ef99c973b94b4.svg",
      logoURL: "https://ravennaareachamber.com/wp-content/uploads/2017/03/your-company-lsiting.png",
    },
    urls: {
      preferenceCenterURL: "https://doit.com",
      privacyPolicyURL: "https://doit.com",
      serviceTermsURL: "https://www.doit.com/terms-of-service",
      solutionBriefURL: "https://www.doit.com/solution-brief/",
      contactSalesURL: "https://www.doit.com/contact-sales",
      navigatorSubscriptionURL,
    },
  },
  "flexsave.searce.com": {
    title: "Searce",
    companyName: "Searce",
    theme: {
      navigation: "#FFFFFF",
      primary: "#0063FF",
      primaryGradient: "#F5F5F5",
      secondaryGradient: "#F5F5F5",
      primaryButtonTextColor: "#FFFFFF",
      navigationTextColor: "#000000",
      accent: "#0063FF",
    },
    assets: {
      favIcon: "https://app.doit.com/static/media/aws-logo.c3f584db595a62ed3d2ef99c973b94b4.svg",
      logoURL: "https://www.searce.com/assets/images/logo.svg",
    },
    urls: {
      preferenceCenterURL: "https://doit.com",
      privacyPolicyURL: "https://www.searce.com/policy",
      serviceTermsURL: "https://www.doit.com/terms-of-service",
      solutionBriefURL: "https://www.doit.com/solution-brief/",
      contactSalesURL: "https://www.searce.com/#lets-connect-box",
      navigatorSubscriptionURL,
    },
  },
  default: {
    title: "DoiT Console",
    companyName: "DoiT International",
    assets: {
      favIcon: `${process.env.PUBLIC_URL}/favicon.svg`,
      logoURL: logo,
    },
    urls: {
      preferenceCenterURL: "https://resources.doit.com/subscription-preferences",
      privacyPolicyURL: "https://www.doit.com/privacy/",
      serviceTermsURL: "https://www.doit.com/terms-of-service",
      solutionBriefURL: "https://www.doit.com/solution-brief/",
      contactSalesURL: "https://www.doit.com/contact-sales",
      navigatorSubscriptionURL,
    },
  },
};

export const domainConfig = sConfig[window.location.hostname] ?? sConfig.default;
