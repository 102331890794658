import { type CSSProperties } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Alert, AlertTitle, Checkbox, FormControlLabel, Typography } from "@mui/material";

import { inviteUserDialogTestIds, text, tooltipText } from "./consts";

export type InviteUserDialogHelper = {
  style?: CSSProperties;
  requireBillingProfile?: boolean;
  onSelectRequireBillingProfile: () => void;
};

export const InviteUserDialogHelper = ({
  style,
  requireBillingProfile,
  onSelectRequireBillingProfile,
}: InviteUserDialogHelper) => (
  <div style={style} data-testid={inviteUserDialogTestIds.HELPER_TOOLTIP_BOX}>
    <FormControlLabel
      control={
        <Checkbox
          checked={requireBillingProfile}
          onChange={onSelectRequireBillingProfile}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          inputProps={{
            // @ts-expect-error
            "data-testid": inviteUserDialogTestIds.HELPER_TOOLTIP_BOX_CHECKBOX,
          }}
        />
      }
      label={<Typography variant="body2">{text.REQUEST_NEW_BILLING_PROFILE_LABEL}</Typography>}
    />
    <Alert severity="info" style={{ marginTop: 15 }}>
      <AlertTitle>{tooltipText.tooltip_header}</AlertTitle>
      <div>{tooltipText.tooltip_description}</div>
    </Alert>
  </div>
);
