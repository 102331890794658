import { useMemo, useState } from "react";

import { ReceiptModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { usePaginateQuery } from "../../Components/hooks/usePaginateQuery";
import CashGraph from "./CashGraph";
import TableServerPaging from "./TableServerPaging";
import { useCashGraphData } from "./useCashGraphData";

export const CollectionCash = () => {
  const cashGraphData = useCashGraphData();
  const [currentPage, setCurrentPage] = useState(0);

  const query = useMemo(() => getCollection(ReceiptModel).where("CANCELED", "==", false), []);
  const { items, handlePrevPage, handleNextPage, setOrderByFieldAndDirection, rowsPerPage, setRowsPerPage } =
    usePaginateQuery(query, { defaultOrderBy: "PAYDATE" });

  return (
    <>
      {cashGraphData && <CashGraph data={cashGraphData} />}
      <TableServerPaging
        changePage={(page: number) => {
          if (page > currentPage) {
            handleNextPage();
          } else if (page < currentPage) {
            handlePrevPage();
          }

          setCurrentPage(page);
        }}
        changeRowsPerPage={setRowsPerPage}
        rows={items}
        title="Customer Receipts"
        onSortBy={setOrderByFieldAndDirection}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
};
