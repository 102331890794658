import { type FlexsaveType, FlexsaveTypes } from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { useFlexsaveData } from "../../../Pages/Flexsave/hooks";
import { FlexsaveCardCanEnable } from "./FlexsaveCardCanEnable";
import { FlexsaveCardEnabled } from "./FlexsaveCardEnabled";
import { FlexsaveCardLoading } from "./FlexsaveCardLoading";
import { FlexsaveCardNotifyMe } from "./FlexsaveCardNotifyMe";
import { FlexsaveCardUnauthorized } from "./FlexsaveCardUnauthorized";

type Props = {
  type: FlexsaveType;
  widgetHeight: number;
};

const FlexsaveCard = ({ type, widgetHeight }: Props) => {
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ allowNull: true, requiredRoles: true });
  const authorized = userRoles.flexsaveAdmin || userRoles.doitEmployee;
  const flexSaveLink = `/customers/${customer.id}/flexsave-${type.toLocaleLowerCase()}`;

  const [data] = useFlexsaveData(type);

  if (!authorized) {
    return <FlexsaveCardUnauthorized widgetHeight={widgetHeight} />;
  } else if (!data) {
    return <FlexsaveCardLoading widgetHeight={widgetHeight} />;
  } else if (data?.enabled?.savings && data?.enabled?.savings?.length > 0) {
    return <FlexsaveCardEnabled data={data} widgetHeight={widgetHeight} flexSaveLink={flexSaveLink} type={type} />;
  } else if (data?.potentialFlexSaveSavings) {
    return (
      <FlexsaveCardCanEnable
        canEnable={data.potentialFlexSaveSavings}
        flexSaveLink={flexSaveLink}
        type={type}
        widgetHeight={widgetHeight}
      />
    );
  } else {
    return <FlexsaveCardNotifyMe flexsaveLink={flexSaveLink} type={type} widgetHeight={widgetHeight} />;
  }
};

export const FlexsaveCardGCP = ({ widgetHeight }: { widgetHeight: number }) =>
  FlexsaveCard({ type: FlexsaveTypes.GCP, widgetHeight });
export const FlexsaveCardAWS = ({ widgetHeight }: { widgetHeight: number }) =>
  FlexsaveCard({ type: FlexsaveTypes.AWS, widgetHeight });
