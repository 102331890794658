import { useCallback, Fragment } from "react";
import Snackbar from "@mui/material/Snackbar";
import { CircularProgress } from "@mui/material";
import Slide from "@mui/material/Slide";

export default function SnackbarLoader({ open, text }) {
  const slideTransition = useCallback((props) => <Slide {...props} direction="right" />, []);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={8000}
        message={text}
        TransitionComponent={slideTransition}
        action={
          <Fragment>
            <CircularProgress disableShrink={true} size={24} />
          </Fragment>
        }
      />
    </div>
  );
}
