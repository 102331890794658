import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

import Hide from "../../Components/HideChildren/Hide";
import { type Defaults } from "../../Components/hooks/useTableState";
import { type NestedObjectLeaves } from "../../utils/NestedKeyOf";

type Cell<T> = {
  id: NestedObjectLeaves<T>;
  showMobile?: boolean;
  numeric?: boolean;
  label: string;
};

type Props<T> = {
  isAllSelected: boolean;
  isSomeSelected: boolean;
  tableState: Defaults<T>;
  onRequestSort: (property: NestedObjectLeaves<T>) => () => void;
  headCells: readonly Cell<T>[];
};

export const EnhancedTableHead = <T,>({
  onRequestSort,
  headCells,
  tableState,
  isAllSelected,
  isSomeSelected,
}: Props<T>) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={isSomeSelected && !isAllSelected}
          checked={isAllSelected}
          inputProps={{ "aria-label": "select all roles" }}
          disabled
        />
      </TableCell>
      {headCells.map((headCell) => (
        <Hide key={headCell.id} mdDown={!headCell.showMobile}>
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={tableState.sort === headCell.id && tableState.direction}
          >
            <TableSortLabel
              active={tableState.sort === headCell.id}
              direction={tableState.direction}
              onClick={onRequestSort(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        </Hide>
      ))}
    </TableRow>
  </TableHead>
);
