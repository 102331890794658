import { useMemo } from "react";

import { useChartColors } from "../../../../Components/hooks/cloudAnalytics/useChartColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";

export function useColors(numSeries: number, reverse: boolean) {
  const darkMode = useDarkThemeCheck();
  const fontColor = darkMode ? "white" : "#2A2A2A";
  const toolboxColor = darkMode ? "#B5BFFA" : "#3C43AF";
  const gridLineColor = darkMode ? "#4E4E56" : "#E6E6E6";
  const pageIconColor = darkMode ? "white" : "black";

  const allColors = useChartColors(darkMode ? "dark" : "light");

  const colors = useMemo(() => {
    let colors = [...allColors];
    if (!reverse) {
      return colors;
    }
    if (numSeries < colors.length) {
      colors = colors.slice(0, numSeries);
    }
    colors.reverse();
    return colors;
  }, [allColors, numSeries, reverse]);

  return {
    fontColor,
    toolboxColor,
    gridLineColor,
    pageIconColor,
    colors,
    backgroundColor: darkMode ? "#35353F" : "white",
  };
}
