import { useCallback, useEffect, useMemo, useState } from "react";

import { type CloudOnHoldType, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";

import { globalText } from "../../../../assets/texts";
import { CustomerPicker } from "../../../../Components/CustomerPicker/CustomerPicker";
import LoadingButton from "../../../../Components/LoadingButton";
import { useAuthContext } from "../../../../Context/AuthContext";
import { assetTypeName } from "../../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../../utils/dialog";
import { firestoreTimestamp } from "../../../../utils/firebase";
import { consoleErrorWithSentry } from "../../../../utils/index";
import { useSnackbar } from "../../../Integrations/Slack/utils";
import { texts } from "./texts";
import { type CustomersOnHold } from "./types";

const cloudOptions: CloudOnHoldType[] = [
  "amazon-web-services",
  "g-suite",
  "google-cloud",
  "microsoft-azure",
  "office-365",
  "navigator",
  "solve",
];

type Props = {
  open: boolean;
  onClose: () => void;
  customersLoading: boolean;
  customersOnHold: CustomersOnHold;
};

const OnHoldAddForm = ({ open, onClose, customersLoading, customersOnHold }: Props) => {
  const { fullScreen } = useFullScreen();
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCloud, setSelectedCloud] = useState(cloudOptions[0]);
  const [note, setNote] = useState("");
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const snackbar = useSnackbar();

  const resetForm = () => {
    setSelectedCustomer("");
    setSelectedCloud(cloudOptions[0]);
    setNote("");
  };

  useEffect(() => {
    resetForm();
  }, [open]);

  const addHold = useCallback(async () => {
    const cloudOnHoldDetails = customersOnHold[selectedCustomer]?.invoicesOnHold ?? {};
    cloudOnHoldDetails[selectedCloud] = {
      note,
      timestamp: firestoreTimestamp(),
      email: currentUser.email,
    };
    setLoading(true);
    try {
      await getCollection(CustomerModel).doc(selectedCustomer).update("invoicesOnHold", cloudOnHoldDetails);
      snackbar.onOpen({
        message: "Success",
        variant: "success",
      });
      onClose();
    } catch (err: any) {
      snackbar.onOpen({
        message: err.message,
        variant: "error",
      });
      consoleErrorWithSentry(err);
    } finally {
      setLoading(false);
    }
  }, [currentUser.email, customersOnHold, note, onClose, selectedCloud, selectedCustomer, snackbar]);

  const holdButtonDisabled = useMemo(
    () =>
      customersLoading ||
      !selectedCustomer ||
      !selectedCloud ||
      !note ||
      !!customersOnHold[selectedCustomer]?.invoicesOnHold[selectedCloud],
    [customersLoading, customersOnHold, note, selectedCloud, selectedCustomer]
  );

  const holdButtonTooltipText = useMemo(() => {
    if (loading || customersLoading) {
      return;
    }
    if (customersOnHold[selectedCustomer]?.invoicesOnHold[selectedCloud]) {
      return texts.HOLD_EXISTS_TOOLTIP;
    }
    if (!selectedCustomer || !selectedCloud || !note) {
      return texts.ALL_FIELDS_SHOULD_BE_FILLED_TOOLTIP;
    }
  }, [customersLoading, customersOnHold, loading, note, selectedCloud, selectedCustomer]);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle data-cy="form-title">{texts.FORM_TITLE}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
          <Grid item xs={12}>
            {customersLoading && (
              <Box sx={{ position: "absolute", marginLeft: "5px", marginTop: "5px" }}>
                <CircularProgress size={30} color="primary" />
              </Box>
            )}
            <CustomerPicker
              value={selectedCustomer}
              onChange={(_event, newCustomer) => {
                setSelectedCustomer(newCustomer?.objectID || "");
              }}
              getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
                hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
              }
              getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
              TextFieldProps={{
                label: "Customer domain",
                variant: "outlined",
              }}
              data-cy="customer-select-textfield"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label="Cloud"
              margin="dense"
              variant="outlined"
              value={selectedCloud}
              onChange={(event) => {
                setSelectedCloud(event.target.value as CloudOnHoldType);
              }}
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    dense: true,
                  },
                },
              }}
            >
              {cloudOptions.map((cloud) => (
                <MenuItem key={cloud} value={cloud}>
                  {assetTypeName(cloud)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              data-cy="note-textfield"
              label="Note"
              margin="dense"
              variant="outlined"
              multiline
              rows={4}
              value={note}
              onChange={(event) => {
                setNote(event.target.value);
              }}
              inputProps={{ maxLength: 1000 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={loading} data-cy="close-form">
          {globalText.CLOSE}
        </Button>
        <Tooltip placement="top" title={holdButtonTooltipText} data-cy="add-hold-tooltip">
          <Box>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={addHold}
              data-cy="add-hold-button"
              disabled={holdButtonDisabled}
              loading={loading}
              mixpanelEventId="customer.invoices-on-hold.add"
            >
              {texts.HOLD_BUTTON_TEXT}
            </LoadingButton>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default OnHoldAddForm;
