import { useCallback, useMemo } from "react";

import { type ContractModel, type ContractSupport, type PricebooksAssignmentsModel } from "@doitintl/cmp-models";
import { Box, Divider, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import keys from "lodash/keys";
import values from "lodash/values";

import DataCouplet from "../../../Components/DataCouplet";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { formatNumber, humanBoolean } from "../../../utils/common";
import { NotApplicable, NotAvailable } from "../../Assets/Tabs/components/typographics";
import useSubscribedMasterPayerAccounts, {
  type MasterPayerAccountsModelSnapshot,
} from "../../MPA/hooks/useSubscribedMasterPayerAccounts";
import usePricebookAssignments from "../../Pricebooks/hooks/usePricebookAssignments";
import { usePricebooks } from "../ContractForm/Steps/AwsSupportStep/hooks";
import { getMPANumberFromName } from "../ContractForm/Steps/AwsSupportStep/utils";
import { buildCommitmentPeriodBlock, contractDate } from "../utils";
import { getCommitmentTypeText } from "./utils";

export const cyIds = {
  container: "awsContractInfo",
  sections: {
    general: "general-section",
    details: "details-section",
    pricing: "pricing-section",
    commitment: "commitment-section",
  },
  text: {
    title: "contract-title",
  },
};

export type ContractPageAwsProps = {
  contractId: string;
  contract: ContractModel;
  accountManagerName?: string;
};

const ContractPageAws = ({ contractId, contract, accountManagerName }: ContractPageAwsProps) => {
  const { entities, entitiesLoading } = useCustomerContext();
  const { masterPayerAccounts, masterPayerAccountsLoading } = useSubscribedMasterPayerAccounts();
  const { pricebookAssignments, pricebookAssignmentsLoading } = usePricebookAssignments({ contractId });
  const pricebooks = usePricebooks();

  const mpaDataset = useMemo<Record<string, ContractSupport>>(() => {
    const dataset: Record<string, ContractSupport> = {};
    values(contract.properties?.support).forEach((support) => {
      if (!support?.mpaRef?.id) {
        return;
      }
      dataset[support.mpaRef.id] = support;
    });
    return dataset;
  }, [contract.properties?.support]);

  const contractMpas = useMemo(
    () => masterPayerAccounts.filter((mpa) => keys(mpaDataset).includes(mpa.id)),
    [masterPayerAccounts, mpaDataset]
  );

  const getPricebookAssignment = useCallback<(mpaId: string) => PricebooksAssignmentsModel | undefined>(
    (mpaId) => pricebookAssignments.find((assignment) => assignment.payerId === mpaId),
    [pricebookAssignments]
  );

  const getAssignedPricebookName = useCallback<(assignment: PricebooksAssignmentsModel) => string>(
    (assignment) => pricebooks.find((pricebook) => pricebook.id === assignment.pricebookId)?.bookName ?? NotAvailable,
    [pricebooks]
  );

  const entity = useMemo<Entity | undefined>(
    () => entities.find((ent) => ent.id === contract.entity?.id),
    [contract.entity?.id, entities]
  );

  const buildPricingSet = useCallback(
    (index: number, support: ContractSupport, mpa?: MasterPayerAccountsModelSnapshot) => {
      let pricebookName = NotAvailable;
      if (mpa) {
        const assignment = getPricebookAssignment(mpa.id);
        pricebookName = assignment ? getAssignedPricebookName(assignment) : NotAvailable;
      }
      return (
        <Box component="section" key={`pricing-set-${index}`}>
          {index !== 0 && <Divider />}
          <Stack component="dl" spacing={2} data-cy={`pricingSection-${index}`}>
            <DataCouplet
              key={`mpa-domain-${index}`}
              field="Payer account"
              value={mpa ? `${mpa.domain ?? "SHARED"} (MPA #${getMPANumberFromName(mpa.name)})` : NotAvailable}
              loading={masterPayerAccountsLoading}
            />
            <DataCouplet
              key={`support-costs-${index}`}
              field="Support costs"
              value={support.flatRate ? `${formatNumber(support.flatRate, 0)}%` : NotAvailable}
              loading={masterPayerAccountsLoading}
            />
            <DataCouplet
              key={`discount-${index}`}
              field="Discount"
              value={support.discount ? `${support.discount}%` : NotAvailable}
              loading={masterPayerAccountsLoading}
            />
            <DataCouplet
              key={`special-pricing-${index}`}
              field="Special pricing"
              value={pricebookName}
              loading={pricebookAssignmentsLoading || masterPayerAccountsLoading}
            />
            <DataCouplet
              key={`flexsave-discount-${index}`}
              field="Flexsave discount override"
              value={mpa ? `${mpa.defaultAwsFlexSaveDiscountRate}%` : NotAvailable}
              loading={masterPayerAccountsLoading}
            />
          </Stack>
        </Box>
      );
    },
    [getAssignedPricebookName, getPricebookAssignment, masterPayerAccountsLoading, pricebookAssignmentsLoading]
  );

  const pricingSection = useMemo(
    () =>
      contractMpas.length
        ? contractMpas.map((mpa, index) => buildPricingSet(index, mpaDataset[mpa.id], mpa))
        : keys(mpaDataset).map((key, index) => buildPricingSet(index, mpaDataset[key])),
    [contractMpas, buildPricingSet, mpaDataset]
  );

  const awsFlexsaveOverride = contract.properties?.awsFlexSaveOverwrite
    ? `${contract.properties.awsFlexSaveOverwrite}%`
    : NotApplicable;
  const commitmentRollover = contract?.commitmentRollover ? humanBoolean(contract.commitmentRollover) : NotApplicable;

  return (
    <Stack data-cy={cyIds.container} spacing={3}>
      <Typography variant="h1" data-cy={cyIds.text.title}>
        Amazon Web Services
      </Typography>
      <Box component="section" data-cy={cyIds.sections.general}>
        <Typography variant="h2">General</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet
            key="billing-profile"
            field="Billing profile"
            value={entity ? `${entity.priorityId} - ${entity.name}` : "Unassigned"}
            loading={entitiesLoading}
          />
          <DataCouplet key="account-manager" field="Account manager" value={accountManagerName ?? NotAvailable} />
          <DataCouplet
            key="annual-value"
            field="Annualized value"
            value={`$${formatNumber(contract.estimatedValue, 0)}`}
            infoTooltip="The estimated yearly contract value in USD"
          />
        </Stack>
      </Box>
      <Box component="section" data-cy={cyIds.sections.details}>
        <Typography variant="h2">Contract details</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet key="commitment-deal" field="Commitment deal" value={getCommitmentTypeText(contract)} />
          <DataCouplet key="contract-start-date" field="Start date" value={contractDate(contract.startDate)} />
          <DataCouplet key="contract-end-date" field="End date" value={contractDate(contract?.endDate)} />
          <DataCouplet key="contract-discount" field="Discount" value={`${contract.discount}%`} />
          <DataCouplet
            key="flexsave-discount-override"
            field="Flexsave discount override"
            value={awsFlexsaveOverride}
            infoTooltip="Override of default percentage of 1-year RI discount passed to client"
          />
        </Stack>
      </Box>
      <Box component="section" data-cy={cyIds.sections.pricing}>
        <Typography variant="h2">Pricing</Typography>
        {pricingSection}
      </Box>
      {contract.isCommitment && (
        <Box component="section" data-cy={cyIds.sections.commitment}>
          <Typography variant="h2">Commitment</Typography>
          <Stack component="dl">
            <DataCouplet key="commitment-rollover" field="Commitment rollover" value={commitmentRollover} />
            {contract?.commitmentPeriods?.map((period, index) => buildCommitmentPeriodBlock(period, index))}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default ContractPageAws;
