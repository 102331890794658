import { useState } from "react";

import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Link as MUILink, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { DeleteThreadModal } from "./DeleteThreadModal";

interface LinkedThreadMenuProps {
  deleteOnly?: boolean;
  threadId: string;
  targetUrl: string;
}

const ThreadThreeDotsMenu: React.FunctionComponent<LinkedThreadMenuProps> = ({
  deleteOnly = false,
  threadId,
  targetUrl,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isDoitEmployee } = useAuthContext();

  const { customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(customer);

  const open = Boolean(anchorEl);

  const [isDeleteThreadModalVisible, setIsDeleteThreadModalVisible] = useState(false);

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    setIsDeleteThreadModalVisible(true);
  };

  const deleteThreadModalOnClose = () => {
    setIsDeleteThreadModalVisible(false);
    if (deleteOnly) {
      history.goBack();
    }
  };

  const menuLinkStyles = { display: "flex", alignItems: "center", textDecoration: "none", color: "inherit" };

  return (
    <>
      <div>
        <IconButton
          id={`thread-${threadId}-menu-button`}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          aria-label="actions"
          onClick={handleClick}
          sx={{ padding: 0, height: "fit-content" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": `thread-${threadId}-menu-button`,
          }}
        >
          {deleteOnly ? null : (
            <>
              <MenuItem onClick={handleClose}>
                <Link to={`/customers/${customer.id}/threads/${threadId}`} style={menuLinkStyles}>
                  View thread
                </Link>
              </MenuItem>
              <Tooltip title={isPresentationMode ? "Jira connection is not activated in Presentation mode." : ""}>
                <span>
                  <MenuItem onClick={handleClose} disabled={isPresentationMode}>
                    <MUILink href={targetUrl} target="_blank" rel="noopener noreferrer" style={menuLinkStyles}>
                      Manage in Jira
                      <LaunchIcon fontSize="small" sx={{ ml: 1 }} />
                    </MUILink>
                  </MenuItem>
                </span>
              </Tooltip>
            </>
          )}
          <Tooltip
            title={
              isPresentationMode
                ? "Threads cannot be deleted in Presentation mode."
                : isDoitEmployee
                  ? "DoiT employees cannot delete threads"
                  : ""
            }
          >
            <span>
              <MenuItem onClick={handleDelete} disabled={isPresentationMode}>
                <Typography color="error">Delete</Typography>
              </MenuItem>
            </span>
          </Tooltip>
        </Menu>
      </div>
      {isDeleteThreadModalVisible && <DeleteThreadModal onClose={deleteThreadModalOnClose} threadId={threadId} />}
    </>
  );
};

export default ThreadThreeDotsMenu;
