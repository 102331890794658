import { useMemo, useRef } from "react";

import { type GCPWorkloadModel } from "@doitintl/cmp-models";
import { type CollectionHook } from "@doitintl/models-firestore";

import { type GroupedWorkloads, type PurchaseType, type Workload } from "../types";
import { parseGCPWorkload } from "./workloadsHooks";

function getCacheMapId(groupType: PurchaseType, workload: Workload) {
  if (groupType === "BulkPurchase" && !workload.purchaseRisk) {
    return `${workload.region}#${workload.hardware}#${workload.type}`;
  }
  if (groupType === "CustomerPurchase" || groupType === "Approvals") {
    return workload.customerId;
  }
}

export function useGCPGroupedWorkloads(
  workloadModelCollection: CollectionHook<GCPWorkloadModel>,
  groupType: PurchaseType
): GroupedWorkloads | undefined {
  const [itemsSnapshot, loading] = workloadModelCollection;
  const cache = useRef(new Map<string, { workloadsMap: Map<string, Workload> }>());
  return useMemo(() => {
    if (!itemsSnapshot || loading) {
      return;
    }
    // update the cache with recent changes
    itemsSnapshot.docChanges().forEach((change) => {
      const workload = parseGCPWorkload(change.doc.asModelData());
      const cacheMapid = getCacheMapId(groupType, workload);
      if (cacheMapid) {
        workload.id = change.doc.id;
        const workloadsCache = cache.current.get(cacheMapid);
        if (change.type === "modified" || change.type === "added") {
          if (workloadsCache) {
            workloadsCache.workloadsMap.set(workload.id, workload);
          } else {
            cache.current.set(cacheMapid, { workloadsMap: new Map<string, Workload>() });
            cache.current.get(cacheMapid)?.workloadsMap.set(workload.id, workload);
          }
        } else if (change.type === "removed") {
          workloadsCache?.workloadsMap.delete(workload.id);
        }
      }
    });
    return new Map(cache.current);
  }, [itemsSnapshot, loading, groupType]);
}
