import { type JSX, useCallback, useMemo } from "react";

import { type EmptyReportFallback } from "@doitintl/cmp-models";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/DashboardContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { jsUcfirst } from "../../utils/common";
import { SafeHtml } from "../SafeHtml";

export type OptionalEmptyWidgetHeaderConfigs = {
  widgetTitle: string;
  widgetSubtitle: string;
};

export default function EmptyWidgetCard({
  name,
  onBeforeNavigate,
  customMessageSettings,
  widgetHeaderConfigs,
  action,
  noData,
  title,
  subheader,
  widgetHeight,
}: {
  name: string;
  onBeforeNavigate?: (url: string) => void;
  action?: JSX.Element;
  customMessageSettings?: EmptyReportFallback;
  widgetHeaderConfigs?: OptionalEmptyWidgetHeaderConfigs;
  noData?: boolean;
  title?: string;
  subheader?: string;
  widgetHeight: number;
}) {
  const { customer } = useCustomerContext();
  const { widgetsConfig } = useDashboardsContext();

  const widgetConfig = useMemo(() => widgetsConfig[name] ?? {}, [name, widgetsConfig]);

  const URL = useMemo((): string => {
    const buttonLink = customMessageSettings?.url ?? widgetConfig?.emptyState?.buttonLink ?? "";

    if (buttonLink.includes("{{")) {
      return buttonLink.replace("{{customerId}}", customer.id);
    }
    return buttonLink ?? "";
  }, [customMessageSettings?.url, widgetConfig?.emptyState?.buttonLink, customer.id]);

  const navigateToUrl = useCallback(
    (url: string) => {
      onBeforeNavigate?.(url);
      window.open(url, "_blank");
    },
    [onBeforeNavigate]
  );

  const learnMoreButton = useCallback(
    (fallbackText: string, customText?: string) => (
      <Box sx={{ mt: 1 }}>
        <Button
          onClick={() => {
            navigateToUrl(URL);
          }}
          endIcon={!URL.startsWith(window.location.origin) ? <OpenNewIcon /> : undefined}
        >
          {customText ?? fallbackText}
        </Button>
      </Box>
    ),
    [URL, navigateToUrl]
  );

  const standardEmptyWidgetOutput = (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography component="div" color="textSecondary">
        {widgetConfig?.emptyState?.message && !noData ? widgetConfig.emptyState.message : "No data available"}
      </Typography>
      {!!widgetConfig?.emptyState?.description && !noData && (
        <Typography component="span" variant="body2" color="textSecondary">
          {widgetConfig.emptyState.description}
        </Typography>
      )}
      {!!widgetConfig?.emptyState?.buttonLink &&
        !noData &&
        learnMoreButton("Learn more", widgetConfig?.emptyState?.buttonText)}
    </Stack>
  );

  const emptyWidgetWithCustomMessage = (
    <Stack alignItems="center" justifyContent="center" height="100%">
      <Typography component="span" sx={{ maxWidth: "559px" }} color="textSecondary">
        <SafeHtml html={customMessageSettings?.text} />
      </Typography>
      {!!customMessageSettings?.url && learnMoreButton("Learn more")}
    </Stack>
  );

  const subheaderContent = useMemo(
    () => subheader ?? jsUcfirst(widgetHeaderConfigs?.widgetSubtitle ?? widgetConfig?.cardSubheader),
    [subheader, widgetConfig?.cardSubheader, widgetHeaderConfigs?.widgetSubtitle]
  );

  return (
    <Card>
      <WidgetCardHeader
        title={title ?? widgetHeaderConfigs?.widgetTitle ?? widgetConfig?.cardTitle}
        subheader={subheaderContent}
        action={action}
      />
      <CardContent sx={{ height: widgetHeight }}>
        {customMessageSettings ? emptyWidgetWithCustomMessage : standardEmptyWidgetOutput}
      </CardContent>
    </Card>
  );
}
