import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { globalText } from "../../../../../assets/texts";
import { useToggle } from "../../../../../utils/useToggle";
import { type BillingProfileInvoiceBucket } from "../../../BillingProfileForm.models";

export const DeleteBucketButton = ({
  bucket,
  deleteBucket,
}: {
  bucket: BillingProfileInvoiceBucket;
  deleteBucket: (bucket: BillingProfileInvoiceBucket) => void;
}) => {
  const [isOpened, open, close] = useToggle(false);
  return (
    <>
      <Button
        variant="text"
        color="error"
        onClick={() => {
          bucket.assets.length === 0 ? deleteBucket(bucket) : open();
        }}
      >
        Delete bucket
      </Button>
      <Dialog aria-labelledby="delete-bucket-dialog-title" open={isOpened}>
        <DialogTitle id="delete-bucket-dialog-title">Delete invoice bucket</DialogTitle>
        <DialogContent>
          Assets currently assigned to this invoice bucket ({bucket.assets.length}) will need to be reassigned to a
          different invoice bucket.
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={close}>
            {globalText.CANCEL}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              close();
              deleteBucket(bucket);
            }}
          >
            Delete invoice bucket
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
