import { type FC } from "react";

import { Box, useTheme } from "@mui/material";
import { BaseEdge, type Edge, EdgeLabelRenderer, type EdgeProps, getBezierPath } from "@xyflow/react";

import { type EdgeData } from "../../types";
import AddNodePopover from "./AddNodePopover";

const GhostEdge: FC<EdgeProps<Edge<EdgeData>>> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  target,
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const {
    palette: { divider },
  } = useTheme();

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ stroke: divider }} />
      <EdgeLabelRenderer>
        <Box
          sx={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${targetX}px,${targetY}px)`,
          }}
        >
          <AddNodePopover data={data} target={target} />
        </Box>
      </EdgeLabelRenderer>
    </>
  );
};

export default GhostEdge;
