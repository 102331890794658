import get from "lodash/get";
import isArray from "lodash/isArray";

import { type DataColumns } from "../../types/FilterTable";
import { OPERATOR_AND, OPERATOR_OR } from "./constants";
import { isLogicalOperator } from "./filterTableUtils";

const evaluateSingleColumn = (row, expression) => {
  let rowValue = get(row, expression.column.path);
  let expressionValue = expression.column?.transform?.(expression.value) ?? expression.value;

  rowValue = expression.column?.transformData?.(rowValue) ?? rowValue;

  switch (expression.column.type) {
    case "FirestoreTimestamp":
      rowValue = rowValue ? rowValue.toMillis() : Infinity;
      expressionValue = expressionValue ? expressionValue.toMillis() : Infinity;
      break;
    case "DateTime":
      rowValue = rowValue ? +rowValue : Infinity;
      expressionValue = expressionValue ? +expressionValue : Infinity;
      break;
    default:
      break;
  }

  switch (expression?.comparator) {
    case "contains":
      if (
        rowValue === null ||
        expressionValue === null ||
        (typeof rowValue !== "string" && typeof rowValue !== "number") ||
        rowValue.toString().toLowerCase().indexOf(expressionValue.toString().toLowerCase()) === -1
      ) {
        return false;
      }
      break;
    case "<":
      if (rowValue >= expressionValue) {
        return false;
      }
      break;
    case "<=":
      if (rowValue > expressionValue) {
        return false;
      }
      break;
    case ">":
      if (rowValue <= expressionValue) {
        return false;
      }
      break;
    case ">=":
      if (rowValue < expressionValue) {
        return false;
      }
      break;
    case "==":
      if (isArray(rowValue)) {
        if (rowValue.every((r) => r !== expressionValue)) {
          return false;
        }
      } else {
        if (expression.column.type === "Number") {
          if (Math.abs(rowValue - expressionValue) > 0.01) {
            return false;
          }
        } else if (rowValue !== expressionValue) {
          return false;
        }
      }
      break;
    case "!=":
      if (isArray(rowValue)) {
        if (rowValue.some((r) => r === expressionValue)) {
          return false;
        }
      } else {
        if (expression.column.type === "Number") {
          if (Math.abs(rowValue - expressionValue) < 0.01) {
            return false;
          }
        } else if (rowValue === expressionValue) {
          return false;
        }
      }
      break;
    default:
      if (isArray(rowValue)) {
        if (rowValue.every((r) => r !== expressionValue)) {
          return false;
        }
      } else {
        if (rowValue !== expressionValue) {
          return false;
        }
      }
  }

  return true;
};

const evaluateMultiColumn = (row, expression) => {
  for (const column of expression.columns) {
    const rowValue = get(row, column.path);
    if (!rowValue) {
      continue;
    }
    let valueToSearch = column?.toOption?.(rowValue) ?? rowValue;
    const expressionValue = column?.transform?.(expression.value) ?? expression.value;

    if (isArray(valueToSearch)) {
      if (
        valueToSearch.some((v) =>
          column.requireExactMatch
            ? v.label === expressionValue
            : v.label?.toLowerCase().includes(expressionValue.toLowerCase())
        )
      ) {
        return true;
      }
    } else if (typeof valueToSearch === "object") {
      valueToSearch = valueToSearch.label;
    }

    const isStringOrNumber = typeof valueToSearch === "string" || typeof valueToSearch === "number";
    const match = column.requireExactMatch
      ? valueToSearch?.toString() === expressionValue?.toString()
      : valueToSearch?.toString().toLowerCase().indexOf(expressionValue?.toString().toLowerCase()) !== -1;

    // no need to continue execution after one match is found
    if (isStringOrNumber && match) {
      return true;
    }
  }

  return false;
};

export const createFilter = (logicalGroups, reverseOperatorPrecedence) => {
  if (reverseOperatorPrecedence) {
    const predicates = logicalGroups.map(
      (logicalGroup) => (row) =>
        logicalGroup.some((expression) =>
          // multiColumn is used with the 'contains' filter
          // singleColumn is used when choosing a single filter e.g owner name == john
          expression.columns ? evaluateMultiColumn(row, expression) : evaluateSingleColumn(row, expression)
        )
    );

    return (rows) => (rows ?? []).filter((row) => predicates.every((predicate) => predicate(row)));
  }

  const predicates = logicalGroups.map(
    (logicalGroup) => (row) =>
      logicalGroup.every((expression) =>
        // multiColumn is used with the 'contains' filter
        // singleColumn is used when choosing a single filter e.g owner name == john
        expression.columns ? evaluateMultiColumn(row, expression) : evaluateSingleColumn(row, expression)
      )
  );

  return (rows) => (rows ?? []).filter((row) => predicates.some((predicate) => predicate(row)));
};

export const createLogicalGroups = (
  newValue: Array<any>,
  columns: DataColumns<any>,
  reverseOperatorPrecedence?: boolean
) => {
  const logicalGroups: Array<any> = [];
  let flattenedFilters = newValue.slice();

  // search by multiple columns case, add all columns to search by
  for (const filter of flattenedFilters) {
    if (!isLogicalOperator(filter) && !filter.column) {
      filter.columns = [];
      columns.forEach((column, index) => {
        const unsearchableColumnType =
          (column?.type && ["FirestoreTimestamp", "DateTime", "Number"].includes(column.type)) ?? false;
        const explicitlyIgnoreInSearch = column.ignoreInGeneralSearch ?? false;
        if (!unsearchableColumnType && !explicitlyIgnoreInSearch) {
          // add all valid columns that we should search in
          filter.columns.push(columns[index]);
        }
      });
    }
  }

  // Remove logical AND filter, AND is implicitly done when no operator is present
  // between filters
  flattenedFilters = flattenedFilters.filter((f) => f !== (reverseOperatorPrecedence ? OPERATOR_OR : OPERATOR_AND));

  // Split value to logical groups (split by OPERATOR_OR)
  while (flattenedFilters.length > 0) {
    const index = flattenedFilters.indexOf(reverseOperatorPrecedence ? OPERATOR_AND : OPERATOR_OR);
    if (index !== -1) {
      logicalGroups.push(flattenedFilters.splice(0, index));
      flattenedFilters.shift();
    } else {
      logicalGroups.push(flattenedFilters.splice(0));
    }
  }

  return logicalGroups;
};
