import TextField from "@mui/material/TextField";
import { type TextFieldProps } from "@mui/material/TextField/TextField";
import { DatePicker, type DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

function getDateFormat() {
  const testDate = new Date(2000, 0, 16); // January 1, 2000
  const formattedParts = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).formatToParts(testDate);

  const formatParts = formattedParts.map((part) => part.type).filter((type) => type !== "literal");
  return formatParts.join("/");
}

function getPlaceholder(format: string) {
  const placeholderMap = {
    "day/month/year": "DD/MM/YYYY",
    "month/day/year": "MM/DD/YYYY",
    "year/month/day": "YYYY/MM/DD",
  };

  return placeholderMap[format] ?? "MM/DD/YYYY";
}

type Props<TInputDate, TDate> = Omit<DatePickerProps<TInputDate, TDate>, "renderInput"> & {
  TextFieldProps: TextFieldProps & { "data-cy"?: string };
};

class LuxonUtils2 extends AdapterLuxon {
  public getFormatHelperText = (format: string) => getPlaceholder(format);
}

const AutoLocaleDatePicker = <TInputDate, TDate>(props: Omit<Props<TInputDate, TDate>, "inputFormat">) => {
  const dateFormat = getDateFormat();
  const placeholder = getPlaceholder(dateFormat);

  const { TextFieldProps, ...rest } = props;

  return (
    <LocalizationProvider dateAdapter={LuxonUtils2} adapterLocale={navigator.language}>
      <DatePicker<TInputDate, TDate>
        {...rest}
        renderInput={(params) => <TextField {...params} {...TextFieldProps} placeholder={placeholder} />}
      />
    </LocalizationProvider>
  );
};

export default AutoLocaleDatePicker;
