const step1 = "Why allocate costs?";
const step2 = "How do you want to group your costs?";
const step3 = "Customize your attribution group";
const step4 = "How are unallocated costs handled?";
const step5 = "Summary";
const step6 = "Task completed";

export const guidedExperienceText = {
  titles: {
    main: "Start allocating costs",
    steps: [step1, step2, step3, step4, step5, step6],
  },
  step1: {
    p1: "By allocating costs, you can break down your cloud costs and usage in ways that are meaningful to your business. You can see how much you're spending on products and resources by grouping them in categories you decide.",
    p2: {
      part1: "You can achieve that by using DoiT's ",
      attributions: "attributions",
      and: " and ",
      attributionGroups: "attribution groups",
      part2:
        ". In this example, you can create an attribution group for your environments, with each environment being an attribution.",
    },
    p3: "In the next steps, we'll help you create an attribution group that represents a cost allocation based on your business needs.",
    attributions: "Attributions",
    attributionsExplainer: " are a way to allocate your cloud resources by applying filters and labels to your data.",
    attributionGroups: "Attribution groups",
    agExplainer: " are groupings of attributions allowing for bucketing of data.",
    learnMore: "Learn more",
  },
  step2: {
    description:
      "To create an attribution group, you first need to decide how to group your costs. Choose the option that best fits your needs, or create a custom grouping:",
    ENTER_GROUP_NAME: "Enter group name",
  },
  step3: {
    p1: {
      part1:
        "Attribution groups are made up of attributions, which are the resources you decide to include in a group. In this step, define what attributions to include, and click ",
      part2: " to configure them.",
    },
    rules: "Rules",
    setUp: "Set up",
    monthTooltip:
      "This column is based on your billing data and shows the costs associated with each of these attributions in the last 3 months.",
    enter: "Enter",
    nameHere: "name here",
    addMore: "Add more",
    rowDescription:
      "Set up the rules to define what resources will be part of this attribution. You can select the dimension values by keyword, exclusion, or regular expression.",
    totalCost: "Total cost",
  },
  step4: {
    p1: {
      INFO_CONTENT:
        'When billing items do not match any of the attributions in your cost allocation, they will fall under the "Unallocated" bucket. You may find unallocated costs in your report for several reasons:',
      REASONS: [
        "If a resource isn't tagged*, the costs associated with it can't be allocated to a specific tag",
        "If a resource was recently tagged, the data on that resource prior to tagging will not be visible under that tag",
        "Not all cloud resources can be tagged. For example, support or marketplace fees can't be allocated to specific tags",
      ],
      INFO_CONTENT_2:
        "Once your unallocated costs are identified, you can either adjust the configuration of your attributions or attribution groups to more accurately reflect your environment, or split those costs by a variety of dimensions to allocate them across different teams, projects, etc.",
      INFO_FOOTER: {
        PART_1: "For more information on unallocated costs, check out our ",
        PART_2: "documentation on Attribution Groups.",
        NOTE: '* "Tag" is the AWS term; in GCP it is "label" and other providers may have different terminology.',
      },
    },
  },
  summaryStep: {
    summary: "Here's the summary of the attribution group you’re about to create for this cost allocation. ",
    makeChangesOrClick: "Make any changes you need, or click ",
    confirm: "Confirm",
    toCreateIt: " to create it.",
    attributionGroup: "Attribution group",
    attributions: "Attributions",
    edit: "Edit",
  },
  finalStep: {
    title: "Task completed!",
    description: "Your costs have been successfully allocated",
    nextDescription: "Here's what you can do next:",
    allocateMore: "Allocate more costs",
    createReportText: "Create a report on",
  },
  nextBtnText: ["", "", "", "", "Confirm", "View details"],
};
