export function getFeatures() {
  try {
    const features = localStorage.getItem("features");
    if (!features) {
      return {};
    }
    return JSON.parse(features);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("failed to load features", e);
    return {};
  }
}

type Feature = "ssoAuth" | "firebaseAuth";

export function hasFeature(feature: Feature) {
  return getFeatures()[feature] === true;
}
