import { useMemo } from "react";

import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

export function usePricebooks() {
  const cloudHealthPricebookRef = getCollection(IntegrationModel)
    .doc("cloudhealth")
    .collection("cloudhealthPricebooks");
  const [pricebooks] = useCollectionData(cloudHealthPricebookRef);

  return useMemo(
    () => pricebooks?.sort((a, b) => a.bookName.localeCompare(b.bookName, undefined, { sensitivity: "base" })) ?? [],
    [pricebooks]
  );
}
