import { type ReactNode, type SyntheticEvent, useMemo, useState } from "react";

import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";
import { updateDeploymentMethodFirestore } from "../api";
import { useClusterDeployment } from "../hooks";
import { type CloudFormationDeploymentMethod, type ClusterConfig } from "../types";
import { Helm } from "./Tabs/Helm";
import { Kubectl } from "./Tabs/Kubectl";

export type ClusterConfigProps = {
  clusterConfig: ClusterConfig;
  tab?: string;
};

type TabData = {
  label: string;
  value: number;
  component: ReactNode;
};

const tabsMap: Record<number, CloudFormationDeploymentMethod> = {
  0: "kubectl",
  1: "helm",
};

const valuesToTabs: Record<CloudFormationDeploymentMethod, number> = {
  kubectl: 0,
  helm: 1,
};

export const ConnectionStep = ({ clusterConfig }: ClusterConfigProps) => {
  const [activeTab, setActiveTab] = useState(valuesToTabs[clusterConfig?.deploymentMethod || tabsMap[0]]);
  const [deploymentInfo] = useClusterDeployment(clusterConfig);

  const tabsList: TabData[] = useMemo(
    () => [
      {
        label: onboardingTxt.KUBECTL_TAB,
        value: 0,
        component: <Kubectl clusterConfig={clusterConfig} tab={onboardingTxt.KUBECTL_TAB} />,
      },
      {
        label: onboardingTxt.HELM_TAB,
        value: 1,
        component: (
          <Helm clusterConfig={clusterConfig} deploymentInfo={deploymentInfo ?? null} tab={onboardingTxt.HELM_TAB} />
        ),
      },
    ],
    [clusterConfig, deploymentInfo]
  );

  const onTabChange = (e: SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
    updateDeploymentMethodFirestore(clusterConfig, tabsMap[newTab]).then(void 0);
  };

  return (
    <Stack spacing={4}>
      <Typography variant="h1">
        {clusterConfig.status === "pending-update" ? "Update Cluster" : "Connect and validate"}
      </Typography>
      <Box>
        <Tabs
          data-cy="eks-onboarding-connection-tabs"
          value={activeTab}
          onChange={onTabChange}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            ".MuiTab-root": { textTransform: "capitalize" },
          }}
        >
          {tabsList.map((tab) => (
            <Tab key={`tab-${tab.value}`} label={tab.label} />
          ))}
        </Tabs>
        <Box sx={{ mt: 4 }}>{tabsList.map((tab) => tab.value === activeTab && tab.component)}</Box>
      </Box>
    </Stack>
  );
};
