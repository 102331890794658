import { Button } from "@mui/material";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { useChangePresentationMode } from "../api";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { text } from "../text";

export function SetPresentationModeBtn() {
  const changePresentationMode = useChangePresentationMode();
  const { customer } = useCustomerContext();
  const errSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const setPresentationMode = async (enabled: boolean) => {
    try {
      await changePresentationMode(customer.id);
      successSnackbar(`Presentation mode ${enabled ? "enabled" : "disabled"} successfully`);
    } catch (e) {
      consoleErrorWithSentry(e);
      errSnackbar(`Failed to ${enabled ? "enable" : "disable"} presentation mode`);
    }
  };

  return !customer.presentationMode?.isPredefined ? (
    <Button
      variant="text"
      onClick={() => setPresentationMode(!customer.presentationMode?.enabled)}
      data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.SET_PRESENTATION_MODE_BTN}
    >
      {customer.presentationMode?.enabled ? text.PRESENTATION_MODE_BTN_TURN_OFF : text.PRESENTATION_MODE_BTN_TURN_ON}
    </Button>
  ) : null;
}
