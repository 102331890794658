import { FlexsavePayerType, ManagedType, Status } from "@doitintl/cmp-models";

export const getChipColor = (status: Status) => {
  switch (status) {
    case Status.pending:
      return "primary";
    case Status.disabled:
      return "error";
    case Status.active:
      return "success";
  }
};

export const getFlexsavePayerType = (type: FlexsavePayerType) => {
  switch (type) {
    case FlexsavePayerType.resold:
      return "Resold";
    case FlexsavePayerType.standalone:
      return "Standalone";
    default:
      return "Unknown";
  }
};

export const getManagedType = (type?: ManagedType) => {
  switch (type) {
    case ManagedType.manual:
      return "Manual";
    case ManagedType.auto:
    default:
      return "Auto";
  }
};
