import { DateTime } from "luxon";

import { type SalesforceApi, salesforceBaseUrl, SalesforceRequestMethod } from "./salesforce";
import { UpdateSalesforce } from "./updateSalesforce";
import { type SalesforceCustomer } from "./updateSalesforceAfterEstimation";

const callSalesforceApi = async (salesforceApi: SalesforceApi, customer: SalesforceCustomer) => {
  await salesforceApi.compositeRequest(
    [
      {
        method: SalesforceRequestMethod.PATCH,
        url: `${salesforceBaseUrl}/sobjects/Account/Firestore_Id__c/${customer.id}`,
        referenceId: "AccountInfo",
        body: {
          Name: customer.name,
          Type: "Customer",
          RecordTypeId: "0128c000001JaS0AAK",
          Lifecycle_Stage__c: "Customer",
          OwnerId: "unknown",
        },
      },
      {
        method: SalesforceRequestMethod.GET,
        referenceId: "UpdatedAccountInfo",
        url: `${salesforceBaseUrl}/sobjects/Account/@{AccountInfo.id}`,
      },
      {
        method: SalesforceRequestMethod.GET,
        referenceId: "OpportunitiyQuery",
        url: `${salesforceBaseUrl}/query?q=select+Id+from+opportunity+where+accountid='@{UpdatedAccountInfo.Id}'+limit+1`,
      },
      {
        method: SalesforceRequestMethod.PATCH,
        referenceId: "UpdatedOpportunity",
        url: `${salesforceBaseUrl}/sobjects/Opportunity/@{OpportunitiyQuery.records[0].Id}`,
        body: {
          CloseDate: DateTime.now().toISODate(),
          StageName: "Consolidated",
          Won_Reason__c: "Product (Flexsave)",
          Closed_Won_Blurb__c: "Product Flexsave",
        },
      },
    ],
    true
  );
};

export const UpdateSalesforceAfterActivation = () => <UpdateSalesforce updateFunction={callSalesforceApi} />;
