import { useCallback } from "react";

import { type Collaborators, Roles, type UserNotification } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type SubscriptionParentByType } from "./types";

export async function getCurrentTime(timeZone: string) {
  return DateTime.now().setZone(timeZone);
}

export function useAddReportCollaborators() {
  const api = useApiContext();
  const { customer } = useCustomerContext();

  return useCallback(
    (emails: string[], report: SubscriptionParentByType<UserNotification.ReportSubscription>) => {
      const existingCollaborators = report.collaborators?.map((collaborator) => collaborator.email.toLowerCase()) || [];
      const emailsToAdd = emails.filter((email) => !existingCollaborators.includes(email.toLowerCase()));
      if (emailsToAdd.length === 0) {
        return;
      }
      const collaboratorsToAdd = emailsToAdd.map((email) => ({
        email,
        role: Roles.VIEWER,
      }));

      const collaborators: Collaborators = [...report.collaborators].concat(collaboratorsToAdd);

      return api.request({
        method: "PATCH",
        url: `/v1/customers/${customer.id}/analytics/reports/${report.id}/share`,
        data: {
          public: report.public,
          collaborators,
        },
      });
    },
    [api, customer]
  );
}
