import { createContext, type ReactNode, useCallback, useContext, useMemo } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import orderBy from "lodash/orderBy";

export type ContractTypesContext = {
  contractProductTypes: { label: string; value: string }[];
  loading: boolean;
  getContractTypeLabel: (value: string) => string;
};

const ContractTypesContext = createContext<ContractTypesContext>({
  contractProductTypes: [],
  loading: false,
  getContractTypeLabel: () => "",
});

export const ContractTypesContextProvider = ({ children }) => {
  const [contractsMetadata, loading] = useDocumentDataOnce(getCollection(AppModel).doc("contracts"));
  const contractProductTypes = orderBy(contractsMetadata?.options ?? [], (o) => o.label);

  const getContractTypeLabel = useCallback(
    (value: string) => {
      const contractType = contractProductTypes.find((type) => type.value === value);
      return contractType?.label ?? "";
    },
    [contractProductTypes]
  );

  const value = useMemo(
    () => ({ contractProductTypes, loading, getContractTypeLabel }),
    [contractProductTypes, getContractTypeLabel, loading]
  );

  return <ContractTypesContext.Provider value={value}>{children}</ContractTypesContext.Provider>;
};

export const useContractTypesContext = () => useContext(ContractTypesContext);

export const ContractTypesContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<ContractTypesContext>;
}) => {
  const actualValue = value ?? {};

  actualValue.contractProductTypes = value?.contractProductTypes ?? [];
  actualValue.loading = value?.loading ?? false;
  actualValue.getContractTypeLabel = useCallback(
    (value: string) => {
      const contractType = actualValue.contractProductTypes!.find((type) => type.value === value);
      return contractType?.label ?? "";
    },
    [actualValue.contractProductTypes]
  );

  return <ContractTypesContext.Provider value={actualValue as any}>{children}</ContractTypesContext.Provider>;
};
