import { type FC, type ReactNode } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { CardContent, FormControl, IconButton, InputLabel } from "@mui/material";

export const Fieldset: FC<{ children: ReactNode; label?: string; onRemove?: () => void }> = ({
  children,
  label,
  onRemove,
}) => {
  if (!label) {
    return <>{children}</>;
  }
  return (
    <FormControl
      sx={(theme) => ({
        border: `1px solid ${theme.palette.general.divider}`,
        borderRadius: theme.shape.borderRadius,
      })}
      variant="outlined"
    >
      <InputLabel
        shrink
        variant="outlined"
        size="small"
        sx={(theme) => ({ px: 0.5, background: theme.palette.background.default })}
      >
        {label}
      </InputLabel>
      {onRemove && (
        <IconButton
          onClick={onRemove}
          sx={(theme) => ({
            border: `1px solid ${theme.palette.general.divider}`,
            width: 24,
            height: 24,
            fontSize: 16,
            background: theme.palette.general.backgroundDark,
            position: "absolute",
            top: -12,
            right: -12,
          })}
        >
          <ClearIcon fontSize="inherit" />
        </IconButton>
      )}
      <CardContent sx={{ paddingTop: 4 }}>{children}</CardContent>
    </FormControl>
  );
};
