import { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import { useAuthContext } from "../../../Context/AuthContext";

declare global {
  interface Window {
    UserVoice: {
      set: (config: any) => void;
      addTrigger: (selector: string, options: { mode: string }) => void;
      removeTrigger: (selector: string) => void;
    };
  }
}

const useUserVoice = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const onScriptLoad = () => {
      setLoaded(true);
    };

    const uv = document.createElement("script");
    // Attach the load event listener
    uv.addEventListener("load", onScriptLoad);

    uv.type = "text/javascript";
    uv.async = true;
    uv.src = "https://doit.uservoice.com/widget_environment/qxi3LFZ7o9XYsKurPYg.js";
    const s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(uv, s);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      uv.removeEventListener("load", onScriptLoad);
    };
  }, []);

  return loaded;
};

export const UserVoiceFeedbackButtonWrapper = () => {
  const { uservoiceSSO, signInProvider } = useAuthContext();

  const loaded = useUserVoice();

  useEffect(() => {
    if (!loaded || !uservoiceSSO) {
      return;
    }
    // Configure UserVoice settings
    window.UserVoice.set({
      accent_color: "#2B78C5",
      trigger_color: "white",
      trigger_background_color: "rgba(46, 49, 51, 0.6)",
      sso: uservoiceSSO,
    });

    window.UserVoice.addTrigger("#feedback-button", { mode: "feedback" });

    return () => {
      try {
        window.UserVoice.removeTrigger("#feedback-button");
      } catch (e) {
        // Do nothing, UserVoice has issues with unloading under tests
      }
    };
  }, [uservoiceSSO, signInProvider, loaded]);

  return (
    <Typography
      id="feedback-button"
      data-cy="item-4"
      sx={{
        color: "navigation.subItemColor",
        my: 0.5,
        flex: 1,
        m: -2,
        p: 2,
      }}
      variant="body2"
    >
      Suggest an idea
    </Typography>
  );
};
