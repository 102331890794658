import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export async function updateTierBillingConfig(customerId: string, isBillingConfigured: boolean) {
  await getCollection(CustomerModel)
    .doc(customerId)
    .update("onboarding.navigator.isTierBillingConfigured", isBillingConfigured);
}

export async function updateCompanyProfile(customerId: string, companySize: string, companyGoals: string[]) {
  await getCollection(CustomerModel).doc(customerId).update("companyProfile", { companySize, goals: companyGoals });
}
