import { type ReactNode } from "react";

import { Currencies, CurrencyCodes } from "@doitintl/cmp-models";
import { Autocomplete } from "@mui/material/";
import { type FormikErrors } from "formik";

import { getCurrencyLabelByCode } from "../../../../utils/common";
import { type CreateBillingProfileData } from "../../BillingProfileForm.models";
import { CustomInput } from "../common/CustomInput";
import { PanelWrapper } from "../common/PanelWrapper";

export const PAYMENT_PANEL_ID = "payments";

const isValidCurrency = (currency, country) => !country || country === "Israel" || currency !== CurrencyCodes.ILS;

type Props = {
  setFieldValue: (field: string, value: string | null) => void;
  values: CreateBillingProfileData;
  errors: FormikErrors<CreateBillingProfileData>;
  inEditMode: boolean;
  children?: ReactNode;
};

export const PaymentPanel = ({ setFieldValue, values, errors, inEditMode, children }: Props) => {
  const validCurrencies = Currencies.filter((currency) => isValidCurrency(currency.value, values.country));
  const options = validCurrencies.map((currency) => currency.value);
  return (
    <PanelWrapper title="Payment method" subtitle="Payment details" id={PAYMENT_PANEL_ID} paddingTop={9}>
      <Autocomplete
        data-cy="currency"
        disabled={inEditMode}
        disableClearable
        options={options}
        value={values.currency || undefined}
        onChange={(_, value) => {
          if (value) {
            setFieldValue("currency", value);
          }
        }}
        getOptionLabel={getCurrencyLabelByCode}
        renderInput={(params) => (
          <CustomInput name="currency" label="Billing currency" required error={Boolean(errors.currency)} {...params} />
        )}
      />
      {children}
    </PanelWrapper>
  );
};
