import { useCallback, useEffect, useState } from "react";

import flatten from "lodash/flatten";
import get from "lodash/get";
import isArray from "lodash/isArray";
import sortBy from "lodash/sortBy";
import sortedUniqBy from "lodash/sortedUniqBy";

import { type AnalyticsResources } from "../../Pages/CloudAnalytics/analyticsResources/types";
import {
  type DataColumn,
  type DataColumns,
  type DefaultFilterColumn,
  type DefaultFilterColumns,
  type LogicalOperator,
  type SideFiltersData,
  type SideFiltersHook,
} from "../../types/FilterTable";
import { useDebouncedMixpanelEventHandler } from "../hooks/useDebouncedMixpanelEventHandler";
import { OPERATOR_AND, OPERATOR_OR } from "./constants";
import { useFiltersContext } from "./Context";
import { isLogicalOperator, keyCount } from "./filterTableUtils";
import { createFilter, createLogicalGroups } from "./utils";

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    window.localStorage.setItem(key, JSON.stringify(valueToStore));
  };
  return [storedValue, setValue] as const;
}

export function useSideFiltersData<T>({
  data,
  columns,
  columnsForFiltering,
}: {
  data: AnalyticsResources[];
  columns: DataColumns<T>;
  columnsForFiltering: string[];
}): SideFiltersData<T>[] {
  if (!data.length) {
    return [];
  }

  const counted: Record<string, number>[] = columns.map((column) => keyCount(data, column.path, column.transformData));
  return columns.map((column, index) => ({
    column,
    title: column.label,
    showInputFilter: columnsForFiltering.includes(column.label),
    items: sortedUniqBy(
      sortBy(
        flatten(
          data.reduce<any>((opts, curr) => {
            const value = get(curr, column.path);
            if (!value) {
              return opts;
            }

            const opt = column.toOption?.(value) ?? {
              value,
              label: value,
              count: 0,
            };

            if (isArray(opt)) {
              opt.forEach((o) => {
                o.count = counted[index]?.[`${o.value}`] ?? 0;
              });
            } else {
              opt.count = counted[index]?.[value as string] ?? 0;
            }

            opts.push(opt);

            return opts;
          }, [])
        ),
        ["value"]
      ),
      "value"
    ),
  }));
}

export const useTrackFilterEvent = ({ mixpanelEventName }: { mixpanelEventName?: string }) => {
  const trackSideFilter = useDebouncedMixpanelEventHandler({ mixpanelEventName });

  return useCallback(
    (selected) => {
      if (!mixpanelEventName) {
        return;
      }
      const filtersToTrack = selected.reduce((acc, val) => {
        if (typeof val !== "string" && val?.value && typeof val.column?.label === "string") {
          return {
            ...acc,
            [val.column.label]: acc[val.column.label] ? [...acc[val.column.label], val.value] : [val.value],
          };
        }
        return acc;
      }, {});
      trackSideFilter(filtersToTrack);
    },
    [mixpanelEventName, trackSideFilter]
  );
};

export function useSideFilters<T>({
  mixpanelEventName,
  onFilter,
  columns,
  reverseOperatorPrecedence,
}: {
  mixpanelEventName?: string;
  onFilter: (any) => void;
  columns: DataColumns<any>;
  reverseOperatorPrecedence?: boolean;
}): SideFiltersHook<T> {
  const [selected, setSelected] = useFiltersContext();
  const [previousColumn, setPreviousColumn] = useState<DataColumn<T>>();
  const [selectedByValue, setSelectedByValue] = useState<Record<string, boolean>>({});
  const trackSideFilter = useTrackFilterEvent({ mixpanelEventName });

  useEffect(() => {
    const newSelected = selected.reduce((acc, val) => {
      if (typeof val !== "string" && val?.value) {
        return { ...acc, [val?.value]: true };
      }
      return acc;
    }, {});
    setSelectedByValue(newSelected);
  }, [selected]);

  // gets the index to insert the filter at
  // note that the index value can be equal to the length of the array as this function is used with splice
  const getInsertIndex = (filter: DefaultFilterColumn<unknown>, values: DefaultFilterColumns<unknown>) => {
    // find last index of group
    let index = values.findLastIndex((v) => {
      if (typeof v === "string") {
        return false;
      }

      return v.column?.label === filter.column?.label;
    });

    // not found, always append, return early
    if (index === -1) {
      return index;
    }

    // increment index, check if has it has an operator after
    if (++index < values.length) {
      if (isLogicalOperator(values[index + 1])) {
        index += 1;
      }
    }

    return index;
  };

  const insertORFilter = useCallback(
    (filter: DefaultFilterColumn<unknown>, values: (LogicalOperator | DefaultFilterColumn<unknown>)[]) => {
      let index = getInsertIndex(filter, values);
      index = index === -1 ? values.length : index;
      values.splice(index, 0, OPERATOR_OR, filter);
    },
    []
  );

  // inserts an AND filter but if it is already part of a group use OR
  const insertANDFilter = useCallback(
    (filter: DefaultFilterColumn<unknown>, values: (LogicalOperator | DefaultFilterColumn<unknown>)[]) => {
      let op = OPERATOR_OR;
      let index = getInsertIndex(filter, values);

      if (index === -1) {
        // new group, append to end and use AND
        index = values.length;
        op = OPERATOR_AND;
      }

      values.splice(index, 0, op, filter);
    },
    []
  );

  const checkBoxHandler = useCallback(
    ({ column, option }: { column: DataColumn<T>; option: { value: string; label?: string; count?: number } }) =>
      (_, isSelected: boolean) => {
        const newSelected = selected.slice();
        const { value, label } = option;

        if (isSelected) {
          const newValue: DefaultFilterColumn<T> = {
            column: {
              label: column.label,
              path: column.path,
              toOption: column.toOption,
              transformData: column.transformData,
            },
            comparator: undefined, // show up in filter bar as : instead of ==
            value,
            label: label ?? "",
            complete: true,
          };

          if (!newSelected.length) {
            newSelected.push(newValue);
          } else if (previousColumn?.path !== column.path) {
            insertANDFilter(newValue, newSelected);
          } else {
            insertORFilter(newValue, newSelected);
          }
        } else {
          const itemToDeleteIndex = newSelected.findIndex((s) => (s as DefaultFilterColumn<T>)?.value === value);
          if (itemToDeleteIndex === -1) {
            return;
          }

          const isNeighborLogicalOperator =
            isLogicalOperator(newSelected[itemToDeleteIndex - 1]) ||
            isLogicalOperator(newSelected[itemToDeleteIndex + 1]);
          newSelected.splice(
            itemToDeleteIndex > 0 && isNeighborLogicalOperator ? itemToDeleteIndex - 1 : itemToDeleteIndex,
            isNeighborLogicalOperator ? 2 : 1
          );

          setPreviousColumn(undefined);
        }

        setSelected(newSelected);

        const logicalGroups = createLogicalGroups(newSelected, columns, reverseOperatorPrecedence);
        onFilter(createFilter(logicalGroups, reverseOperatorPrecedence));
        trackSideFilter?.(newSelected);
        if (previousColumn !== column) {
          setPreviousColumn(column);
        }
      },
    [
      selected,
      setSelected,
      columns,
      reverseOperatorPrecedence,
      onFilter,
      trackSideFilter,
      previousColumn,
      insertANDFilter,
      insertORFilter,
    ]
  );

  return { selectedByValue, checkBoxHandler };
}
