import { type Collaborators, type DashboardModelAttributionModel, type PublicAccess } from "@doitintl/cmp-models";
import { type AxiosInstance, type Method } from "axios";
import { type Dict } from "mixpanel-browser";

import { globalText, httpMethods } from "../../../assets/texts";
import mixpanel from "../../../utils/mixpanel";

export const deleteAttributions = async (
  api: AxiosInstance,
  customerId: string,
  attributionIds: string[],
  mixpanelFrom?: string
) => {
  const payload = {
    attributionIds,
  };
  mixpanel.track("analytics.attributions.delete", { attributionIds, ...(mixpanelFrom && { from: mixpanelFrom }) });
  return api.request({
    method: globalText.DELETE as Method,
    url: `/v1/customers/${customerId}/analytics/attributions`,
    data: payload,
  });
};

export const shareAttribution = async (
  api: AxiosInstance,
  customerId: string,
  attributionId: string,
  collaborators: Collaborators,
  role: PublicAccess
) => {
  await api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customerId}/analytics/attributions/share`,
    data: {
      attributionId,
      collaborators,
      role,
    },
  });
};

export const createAttribution = async (
  api: AxiosInstance,
  customerId: string,
  fields: Omit<Partial<DashboardModelAttributionModel>, "expireBy"> & { expireBy?: Date | null },
  attributionId?: string,
  mixpanelProperties?: Dict
) => {
  mixpanel.track("analytics.attributions.create", { attributionId, ...mixpanelProperties });
  return api.request({
    method: httpMethods.POST as Method,
    url: `/v1/customers/${customerId}/analytics/attributions`,
    data: { id: attributionId, ...fields },
  });
};

export const updateAttribution = async (
  api: AxiosInstance,
  customerId: string,
  attributionId: string,
  fields: Partial<DashboardModelAttributionModel>,
  mixpanelProperties?: Dict
) => {
  mixpanel.track("analytics.attributions.update", {
    attributionId,
    ...mixpanelProperties,
  });
  return api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customerId}/analytics/attributions/${attributionId}`,
    data: fields,
  });
};
