import { FormControl, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

import { jsUcfirst } from "../../../utils/common";
import { SlotGroupingMode } from "./constants";
import { useSelectStyles } from "./utils";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
}));

type Props = {
  selectedValue: SlotGroupingMode;
  onChange: (value: SlotGroupingMode) => void;
};

export default function TimeSelect({ onChange, selectedValue }: Props) {
  const classes = useStyles();
  const selectClasses = useSelectStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        size="small"
        value={selectedValue}
        onChange={(e) => {
          onChange(e.target.value as SlotGroupingMode);
        }}
        className={selectClasses.select}
      >
        {Object.values(SlotGroupingMode).map((item) => (
          <MenuItem value={item} key={item} className={selectClasses.select}>
            {jsUcfirst(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
