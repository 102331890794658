import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Container from "@mui/material/Container";
import { loadTickets, saveNewTicket, loadTicketTemplate, resetTicketTemplate } from "../Actions/TicketAction";
import CreateTicket from "../Components/CreateTicket";

const NewTicketContainer = (props) => {
  const { loadTickets, categories, resetTicketTemplate, loadTicketTemplate, isFetchingTicketList, errorMsg, ...other } =
    props;
  const { template } = useParams();
  const [createTicketDialogOpen, setCreateTicketDialogOpen] = useState(false);

  useEffect(() => {
    if (categories?.length === 0) {
      loadTickets({});
    }
  }, [categories?.length, loadTickets]);

  useEffect(() => {
    if (props.organization.organization_fields && props.organization.organization_fields.classification === "blocked") {
      props.history.push("../support");
    }
  }, [props.history, props.organization]);

  useEffect(() => {
    if (categories?.length > 0) {
      if (template) {
        loadTicketTemplate(template);
      }
    }
    return () => {
      if (template) {
        resetTicketTemplate();
      }
    };
  }, [template, categories?.length, loadTicketTemplate, resetTicketTemplate]);

  return (
    <Container style={{ padding: 0 }} maxWidth="lg">
      <CreateTicket
        open={createTicketDialogOpen}
        handleClose={() => {
          setCreateTicketDialogOpen(false);
        }}
        platforms={props.platforms}
        categories={props?.categories}
        clientProductsProperties={props.clientProductsProperties}
        saveNewTicket={props.saveNewTicket}
        userDetails={props.userDetails}
        isLoading={props.isLoading}
        organization={props.organization}
        slas={props.slas}
        contextUser={props.contextUser}
        customerUsers={props.customerUsers}
        knownIssues={props.knownIssues}
        isFetchingTicketList={isFetchingTicketList}
        concedefyReadOnlyEmail={props.concedefyReadOnlyEmail}
        defaultEmailsList={props.defaultEmailsList}
        errorMsg={errorMsg}
        {...other}
      />
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadTickets: () => dispatch(loadTickets({})),
  saveNewTicket: (data) => dispatch(saveNewTicket(data)),
  loadTicketTemplate: (data) => dispatch(loadTicketTemplate(data)),
  resetTicketTemplate: () => dispatch(resetTicketTemplate()),
});
const mapStateToProps = (state) => ({
  platforms: state.tickets.platforms,
  categories: state.tickets.categories,
  clientProductsProperties: state.tickets.clientProductsProperties,
  userDetails: state.tickets.userDetails,
  isLoading: state.tickets.isLoading,
  organization: state.tickets.organization,
  slas: state.tickets.slas,
  contextUser: state.tickets.contextUser,
  buttonMode: state.tickets.buttonMode,
  customerUsers: state.tickets.customerUsers,
  knownIssues: state.tickets.knownIssues,
  createTicket: state.tickets.createTicket,
  isFetchingTicketList: state.tickets.isFetchingTicketList,
  concedefyReadOnlyEmail: state.tickets.concedefyReadOnlyEmail,
  defaultEmailsList: state.tickets.defaultEmailsList,
  errorMsg: state.tickets.errorMsg,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTicketContainer);
