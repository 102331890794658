import { type EKSMetricsDeployments } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { onboardingTxt } from "../../../../assets/texts/EKS/onboarding";
import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { CheckConnection } from "../../Components/CheckConnection";
import { type ClusterConfigProps } from "../ConnectionStep";

type ExtendedProps = ClusterConfigProps & {
  deploymentInfo: EKSMetricsDeployments | null;
};

export const Helm = ({ clusterConfig, tab, deploymentInfo }: ExtendedProps) => {
  const helmCommandsText = () => {
    if (!deploymentInfo) {
      return "";
    }
    // Handle the conditional part for k8s_platform or role_arn
    const platformOrRoleArn = clusterConfig.isEc2Cluster
      ? "--set k8s_platform=ec2 \\"
      : `--set role_arn=${deploymentInfo.awsRoleName} \\`;

    return `helm repo add doit-eks-lens https://doitintl.github.io/doit-eks-lens-helm-chart/

helm template doit-eks-lens/doit-eks-lens

helm upgrade --install doit-eks-lens doit-eks-lens/doit-eks-lens \\
    --set region=${deploymentInfo.bucketRegion} \\
    --set metricsDeploymentId=${clusterConfig.desiredDeploymentId} \\
    --set s3_bucket=${deploymentInfo.bucketName} \\
    --set s3_prefix=eks-metrics/${clusterConfig.accountId}/${deploymentInfo.bucketRegion}/${clusterConfig.clusterName} \\
    ${platformOrRoleArn}
    --namespace doit-eks-metrics \\
    --create-namespace 

`;
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="body2">{onboardingTxt.HELM_CONNECT_STEP_1}</Typography>
        <CopyCodeBlock base={helmCommandsText()} />
      </Stack>
      {clusterConfig.isEc2Cluster && (
        <>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Used to create a secret containing your AWS Access Key ID in the 'doit-eks-metrics' namespace.
            </Typography>
            <CopyCodeBlock base="kubectl create secret generic aws-access-key-id --from-literal=AWS_ACCESS_KEY_ID='Your-AWS_ACCESS_KEY_ID-here' -n doit-eks-metrics" />
          </Stack>

          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              Used to create a secret containing your AWS Secret Access Key in the 'doit-eks-metrics' namespace.
            </Typography>
            <CopyCodeBlock base="kubectl create secret generic aws-secret-access-key --from-literal=AWS_SECRET_ACCESS_KEY='Your-AWS_SECRET_ACCESS_KEY-here' -n doit-eks-metrics" />
          </Stack>

          <Stack spacing={3}>
            <Typography variant="body2">
              3. Add a new tag with a Key="eks:cluster-name" and a Value="{clusterConfig.clusterName}" to all existing
              and new nodes in the cluster.
            </Typography>
            <Typography variant="body2">4. Add tag name "eks:cluster-name" to Cost Allocation Tags.</Typography>
          </Stack>
        </>
      )}
      <CheckConnection clusterConfig={clusterConfig} tab={tab} />
    </Stack>
  );
};
