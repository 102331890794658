import { useCallback } from "react";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type FlexsaveOperationsPageId } from "../../../../Navigation/config/pages";
import { useFlexsaveOpsApi } from "../../GCP/hooks";
import { BulkRefreshLoading } from "./Common/BulkLoading";
import { FlexsaveGCPApprovalPurchases } from "./FlexsaveGCPApprovalPurchases";
import { FlexsaveGCPBulkPurchases } from "./FlexsaveGCPBulkPurchases";
import { FlexsaveGCPCustomerPurchases } from "./FlexsaveGCPCustomerPurchases";
import { useGCPCustomerAggregation } from "./hooks/customerAggregationHooks";
import { useConfigurations } from "./hooks/customerConfigurationHooks";
import { useBillingProcessState, useGCPOpsState } from "./hooks/gcpOpsStateHooks";
import { useTotalAverageUsages } from "./hooks/totalAverageUsageHooks";
import { useGCPGroupedWorkloads } from "./hooks/useGroupedWorkloads";
import { useGCPWorkloads } from "./hooks/workloadsHooks";
import { ManualPurchaseStepper } from "./ManualPurchase/ManualPurchaseStepper";

type Props = {
  pageId: FlexsaveOperationsPageId;
};

export const FlexsaveGCPOperations = ({ pageId }: Props) => {
  const opsStates = useGCPOpsState();
  const billingProcessState = useBillingProcessState();
  const workloadModelCollection = useGCPWorkloads();
  const totalAverageUsagesMap = useTotalAverageUsages();
  const enabledCustomerConfigsMap = useConfigurations();
  const customersAggMap = useGCPCustomerAggregation();
  const groupedWorkloadsByCustomersMap = useGCPGroupedWorkloads(workloadModelCollection, "CustomerPurchase");
  const groupedWorkloadsBySkuMap = useGCPGroupedWorkloads(workloadModelCollection, "BulkPurchase");
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const flexSaveOpsApi = useFlexsaveOpsApi();

  const handleRefreshDataClick = useCallback(async () => {
    try {
      await flexSaveOpsApi.refreshWorkloads();
      successSnackbar("Data updated successfully");
    } catch (e) {
      errorSnackbar("Data could not be refreshed. Please try again.");
    }
  }, [flexSaveOpsApi, errorSnackbar, successSnackbar]);

  const isBulkUpdateRequired =
    opsStates?.excludeCustomersBulkChangedAt && opsStates?.latestBulkRefresh
      ? !opsStates.bulkRefreshing && opsStates.excludeCustomersBulkChangedAt > opsStates.latestBulkRefresh
      : false;

  if (opsStates?.bulkRefreshing) {
    return <BulkRefreshLoading />;
  }

  return (
    <>
      {billingProcessState?.googleExportDelay && (
        <Alert sx={{ mt: 1, mb: 2 }} severity="warning">
          Due to Google billing export delays, numbers in this page may be incorrect.
        </Alert>
      )}

      {isBulkUpdateRequired && (
        <Alert
          sx={{ mt: 1, mb: 2 }}
          severity="info"
          action={
            <Button color="inherit" size="small" onClick={handleRefreshDataClick}>
              Refresh data
            </Button>
          }
        >
          Some customer details have been updated. Please refresh to make purchases.
        </Alert>
      )}
      {(pageId === "flexsave-gcp-ops-customers" || pageId === "flexsave-gcp-ops-customers:details") && (
        <FlexsaveGCPCustomerPurchases
          configs={enabledCustomerConfigsMap}
          pageId={pageId}
          customersAgg={customersAggMap}
          workloads={groupedWorkloadsByCustomersMap}
          totalAverageUsages={totalAverageUsagesMap}
        />
      )}

      {pageId === "flexsave-gcp-ops-bulk-purchases" && (
        <FlexsaveGCPBulkPurchases
          workloads={groupedWorkloadsBySkuMap}
          totalAverageUsages={totalAverageUsagesMap}
          customersAgg={customersAggMap}
          isBulkUpdateRequired={isBulkUpdateRequired}
        />
      )}

      {pageId === "flexsave-gcp-ops-approval-purchases" && (
        <FlexsaveGCPApprovalPurchases
          workloads={groupedWorkloadsByCustomersMap}
          configs={enabledCustomerConfigsMap}
          isBulkUpdateRequired={isBulkUpdateRequired}
        />
      )}

      {pageId === "flexsave-gcp-ops-manual-purchase" && <ManualPurchaseStepper />}
    </>
  );
};
