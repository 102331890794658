import { useMemo } from "react";

import { useDocumentData } from "@doitintl/models-firestore";

import { getDashboardMetadataRef } from "./cloudAnalytics/useDashboardsMetadata";

const minHoursInMilliseconds = 1000 * 60 * 60 * 6;

export const useDashboardWidgetDataRefresh = (
  customerId: string,
  organizationId: string,
  currentDashboardId: string
) => {
  const dashboardMetadataRef = useMemo(
    () => getDashboardMetadataRef(customerId, organizationId, currentDashboardId),
    [customerId, organizationId, currentDashboardId]
  );
  const [dashboardMetadata, loading] = useDocumentData(dashboardMetadataRef);

  if (loading) {
    return false;
  }

  const timeLastRefreshed = dashboardMetadata?.timeLastRefreshed?.toDate().getTime() ?? 0;

  const shouldRefreshDashboardWidgets =
    !!customerId && !!organizationId && !!currentDashboardId && Date.now() > timeLastRefreshed + minHoursInMilliseconds;

  return shouldRefreshDashboardWidgets;
};
