import { type ChangeEvent } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const SearchTextField = ({ onChange }: Props) => {
  const classes = useStyles();
  return (
    <Box sx={{ pl: 2, pr: 4, flexGrow: 1 }}>
      <Toolbar classes={{ root: classes.toolbar }}>
        <TextField
          variant="standard"
          fullWidth
          placeholder="Search widget"
          autoFocus
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" component="div">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Toolbar>
    </Box>
  );
};
