import MailOutlineIcon from "@mui/icons-material/MailOutline";

import FormDialog from "../../../../Components/FormDialog/FormDialog";
import FlexsaveSupportForm from "../../../../Components/FormDialog/Forms/FlexsaveSupportForm";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { IconWithLink } from "../../Common/IconWithLink";

export const ContactSupport = () => {
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext();

  return (
    <FormDialog
      title="Contact support"
      subTitle="Let us know how we can help you with Flexsave"
      anchorContent={<IconWithLink onClick={() => undefined} text="Contact support" icon={<MailOutlineIcon />} />}
      FormComponent={FlexsaveSupportForm}
      formProps={{ customerName: customer.name, email: currentUser?.email }}
    />
  );
};
