// function to check if the report fs doc still exists:
import { DashboardModel, Renderer, type ReportConfig } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { updateReportConfigRequest } from "../../Pages/CloudAnalytics/api";
import { type createReportParams, type createReportPayload } from "../../Pages/CloudAnalytics/generateReport/types";

export async function verifyAndUpdateReportConfig(
  reportId: string,
  payload: createReportPayload | createReportParams
): Promise<boolean> {
  const docRef = await getCollection(DashboardModel)
    .doc("google-cloud-reports")
    .collection("savedReports")
    .doc(reportId)
    .get();
  if (docRef.exists() && payload.config) {
    const docData = docRef.data();
    if (docData?.config) {
      if (docData.config.renderer !== payload.config.renderer) {
        const docConfig = docData.config as ReportConfig;
        docConfig.renderer = payload.config?.renderer || Renderer.COLUMN_CHART;
        try {
          await updateReportConfigRequest(docConfig, docData.name, reportId, docData.description);
        } catch (e) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}
