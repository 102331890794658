import { useCallback, useState } from "react";

import { type AnalyticsResources } from "../../analyticsResources/types";
import { type LabelWithRef } from "../types";

type SelectedLabelsState = Record<
  string,
  { checked: boolean; indeterminate: boolean; state: "added" | "removed" | "initial" }
>;

const useSelectedLabels = (labels: LabelWithRef[], selectedResources: AnalyticsResources[]) => {
  const [initial, setInitial] = useState<SelectedLabelsState>();
  const [selected, setSelected] = useState<SelectedLabelsState>(() => {
    const labelCounts: Record<string, number> = {};

    labels.forEach((label) => {
      const count = selectedResources.reduce(
        (sum, val) => (val.data.labels?.some((resLabel) => resLabel.id === label.ref.id) ? sum + 1 : sum),
        0
      );

      labelCounts[label.ref.id] = count ?? 0;
    });

    const state: SelectedLabelsState = {};
    for (const [key, value] of Object.entries(labelCounts)) {
      // can only be indeterminate when there is more than 1 resource selected and it is checked
      const indeterminate = selectedResources.length >= 1 && value !== 0 && value !== selectedResources.length;
      state[key] = {
        checked: value !== 0,
        indeterminate,
        state: "initial",
      };
    }

    setInitial(state);
    return state;
  });

  const getStateChange = (checked: boolean, initial: boolean, initiallyIndeterminate: boolean) => {
    if (initiallyIndeterminate) {
      return checked ? "added" : "removed";
    } else if (initial) {
      return checked !== initial ? "removed" : "initial";
    } else {
      return checked !== initial ? "added" : "initial";
    }
  };

  const diffCheckBoxState = useCallback(() => {
    const initial: { add: string[]; remove: string[] } = {
      add: [],
      remove: [],
    };

    return Object.entries(selected).reduce((acc, [key, value]) => {
      if (value.state === "added") {
        acc.add.push(key);
      } else if (value.state === "removed") {
        acc.remove.push(key);
      }

      return acc;
    }, initial);
  }, [selected]);

  const setSelectedLabels = useCallback(
    (labelId: string, checked: boolean) => {
      const initialChecked = initial![labelId]?.checked ?? false;
      const initialIndeterminate = initial![labelId]?.indeterminate ?? false;

      setSelected((current) => ({
        ...current,
        [labelId]: {
          checked,
          indeterminate: false,
          state: getStateChange(checked, initialChecked, initialIndeterminate),
        },
      }));
    },
    [initial]
  );

  return {
    selectedLabels: selected,
    setSelectedLabels,
    getSelectedLabelsResult: diffCheckBoxState,
  };
};

export default useSelectedLabels;
