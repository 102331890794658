import { type PurchaseStatus } from "@doitintl/cmp-models";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { type ApprovalsItem } from "../types";

const itemsDescriptions: FilterTableItemDescriptor<ApprovalsItem>[] = [
  {
    headerText: "Customer",
    value: "primaryDomain",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Hardware",
    value: "hardware",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterOptions: {
      toOption: (value: PurchaseStatus) => ({
        value,
        label: value.toUpperCase(),
      }),
    },
  },
  {
    headerText: "CUDs count",
    value: "purchaseRecommendations",
  },
  {
    headerText: "CUDs share",
    value: "customerShareWithPurchasePlan",
  },
  {
    headerText: "Global coverage after purchase",
    value: "globalCoverageWithPurchasePlan",
  },
  {
    headerText: "Customer total cost",
    value: "customerTotalCost",
  },
  {
    headerText: "Workload cost",
    value: "purchasePlanPrice",
  },
  {
    value: "@",
    headerText: "",
  },
];

export const defaultFilters = [
  {
    column: {
      label: "Status",
      path: "status",
    },
    comparator: "==",
    value: "purchase-required",
    label: "Purchase required",
  },
] as const;

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
