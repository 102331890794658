import { type AssetType } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { type DateTime } from "luxon";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAction: (action: "recalculate" | "issue") => void;
  isSubmitting: boolean;
  action?: "recalculate" | "issue";
  selectedCustomer: {
    id: string;
    name: string;
  } | null;
  formData: {
    invoiceMonth: DateTime<true>;
    cloud?: AssetType | undefined;
    reason?: string | undefined;
  };
};

const InfoField = ({ label, value }: { label: string; value: string | undefined }) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="subtitle1" fontWeight="medium">
      {label}:
    </Typography>
    <Typography variant="body1">{value || "N/A"}</Typography>
  </Grid>
);

export const ConfirmationDialog = ({
  open,
  handleClose,
  handleAction,
  isSubmitting,
  action,
  selectedCustomer,
  formData,
}: Props) => (
  <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>{capitalize(action)} confirmation</DialogTitle>
    <DialogContent>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {action === "issue" && (
            <Alert severity="warning">
              Do not issue invoices on days when engineering are batch issuing invoices to customers
            </Alert>
          )}
          <InfoField label="Customer" value={selectedCustomer?.name} />
          <InfoField label="Month" value={formData.invoiceMonth?.toFormat("yyyy-MM")} />
          <InfoField label="Cloud" value={formData.cloud} />
          <InfoField label="Reason" value={formData.reason} />
          <InfoField label="Action" value={action} />
        </Grid>
      </Box>
    </DialogContent>
    <DialogActions sx={{ borderTop: 1, borderColor: "divider" }}>
      <Button onClick={handleClose}>Cancel</Button>
      {action && (
        <LoadingButton
          color="primary"
          variant="contained"
          loading={isSubmitting}
          onClick={() => {
            handleAction(action);
          }}
          autoFocus
          data-cy="confirm-btn"
        >
          Confirm
        </LoadingButton>
      )}
    </DialogActions>
  </Dialog>
);
