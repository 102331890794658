import { createContext, type ReactNode, useContext, useEffect, useState } from "react";

import { ContractModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { type Contracts, type Customer } from "../../types";
import { useUserContext } from "../UserContext";
import { arrayFromDocChange } from "./arrayFromDocChange";
import { useEntitiesContext } from "./EntitiesContext";

export type ContractsContextType = {
  contracts: Contracts;
  contractsLoading: boolean;
};

const defaultContractsContext = { contracts: [], contractsLoading: true };
const contractsContext = createContext<ContractsContextType>({ ...defaultContractsContext });

export const ContractsContextProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const { entities, entitiesLoading } = useEntitiesContext();
  const { userRoles } = useUserContext();

  const [contracts, setContracts] = useState<Contracts>([]);
  const [contractsLoading, setContractsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!customer?.ref || userRoles?.doitEmployee === undefined || entitiesLoading) {
      return;
    }

    setContracts((prevContracts) => {
      if (prevContracts.length > 0) {
        return [];
      }

      return prevContracts;
    });

    // Users who manage assets must have access to read the contracts from firestore
    // to be able to see correct pricing when updating subscription (discount is set on contract).
    if (!userRoles.assetsManager && !userRoles.contractsViewer && !userRoles.doitEmployee) {
      setContractsLoading(false);
      return;
    }

    const now = DateTime.utc().toJSDate();
    const query = getCollection(ContractModel)
      .where("customer", "==", customer.ref)
      .where("active", "==", true)
      .where("startDate", "<=", now);

    const entitiesIds = entities.map((entity) => entity.id);

    return query.onSnapshot((querySnapshot) => {
      setContracts((prevContracts) => {
        const updatedContracts = [...(prevContracts ?? [])];
        arrayFromDocChange(
          updatedContracts,
          querySnapshot,
          (doc) => ({
            ...doc.asModelData(),
            id: doc.id,
          }),
          (doc) => {
            const data = doc.data();

            if (!data.entity) {
              return false;
            }

            return entitiesIds.includes(data.entity.id);
          }
        );

        return updatedContracts;
      });

      setContractsLoading(false);
    });
  }, [
    entities,
    entitiesLoading,
    customer?.ref,
    userRoles?.assetsManager,
    userRoles?.contractsViewer,
    userRoles?.doitEmployee,
  ]);

  return (
    <contractsContext.Provider value={{ contracts: contracts ?? [], contractsLoading }}>
      {children}
    </contractsContext.Provider>
  );
};

export const ContractsContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<ContractsContextType>;
}) => {
  const actualValue = value ?? {};

  actualValue.contracts = value?.contracts ?? [];
  actualValue.contractsLoading = value?.contractsLoading ?? false;

  return <contractsContext.Provider value={actualValue as any}>{children}</contractsContext.Provider>;
};

export function useContractsContext(): ContractsContextType {
  return useContext(contractsContext);
}

export type WithContracts = {
  contracts: Contracts;
};
