import { type ReasonCantEnableAWS } from "@doitintl/cmp-models";
import { Alert, Box } from "@mui/material";
import Link from "@mui/material/Link";

import { flexsaveDisableMessage } from "./consts";
import { ContactSupportAlertAction } from "./ContactSupportAlertAction";
import { getMessage } from "./utils";

type Props = {
  isDisabled: boolean;
  reasonCantEnable: ReasonCantEnableAWS;
};
export default function Message({ isDisabled, reasonCantEnable }: Props) {
  if (isDisabled) {
    return (
      <Box py={1}>
        <Alert severity="warning" action={<ContactSupportAlertAction />}>
          {flexsaveDisableMessage}
        </Alert>
      </Box>
    );
  }

  const reasonMessage = getMessage(reasonCantEnable);

  if (!reasonMessage) {
    return null;
  }

  return (
    <Box py={1}>
      <Alert
        severity="info"
        action={
          reasonCantEnable === "low spend" ? (
            <Link
              target="_blank"
              href="https://help.doit.com/docs/flexsave/aws"
              sx={{ textDecoration: "none", marginTop: 0.5 }}
            >
              Learn more
            </Link>
          ) : null
        }
      >
        {reasonMessage}
      </Alert>
    </Box>
  );
}
