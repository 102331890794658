import { Stack, Typography } from "@mui/material";

export const NoAlertsMatchingFilters = () => (
  <Stack alignItems="center" my="70px" spacing={2}>
    <Typography fontWeight={500} fontSize="24px" lineHeight="32px">
      No cost anomalies found
    </Typography>
    <Typography fontSize="16px" lineHeight="24px">
      To find cost anomalies, try another time range or change the filter criteria
    </Typography>
  </Stack>
);
