import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@mui/material";

import { CARD_HEADER_HEIGHT } from "../../../../Components/Dashboard/Analytics/cloudCardStyle";
import { WidgetContentWrapper } from "./WidgetContentWrapper";

function always() {
  return true;
}

type Props = {
  id: string;
  width: number | string;
  height: number;
  disableEvents?: boolean;
};

export const SortableWidget = React.memo(function SortableWidget({ id, width, height, disableEvents = false }: Props) {
  const { attributes, listeners, isDragging, isSorting, setNodeRef, transform, transition } = useSortable({
    id,
    animateLayoutChanges: always,
  });

  return (
    <Box sx={{ pointerEvents: disableEvents ? "none" : undefined, userSelect: disableEvents ? "none" : undefined }}>
      <WidgetContentWrapper
        ref={setNodeRef}
        id={id}
        active={isDragging}
        style={{
          width,
          height,
          transition,
          transform: isSorting ? undefined : CSS.Translate.toString(transform),
        }}
        contentHeight={height - CARD_HEADER_HEIGHT}
        {...attributes}
        listeners={listeners}
      />
    </Box>
  );
});
