import { red } from "@mui/material/colors";

import { never } from "../../utils";
import { type MoveState, type OptimizerMove } from "./types";

const sharedPayers = ["561602220360", "279843869311"];

export const groupByDedicatedPayers = (plans: OptimizerMove[]): Record<string, OptimizerMove[]> =>
  plans.reduce((acc, plan) => {
    const { SourcePayerID, DestinationPayerID } = plan;
    const dedicatedPayer = [SourcePayerID, DestinationPayerID].find((payer) => !sharedPayers.includes(payer));

    if (!dedicatedPayer) {
      return acc;
    }

    if (acc[dedicatedPayer]) {
      return {
        ...acc,
        [dedicatedPayer]: [...acc[dedicatedPayer], plan],
      };
    }

    return {
      ...acc,
      [dedicatedPayer]: [plan],
    };
  }, {});

export const getChipBackgroundColor = (label: MoveState): string => {
  switch (label) {
    case "running":
      return "#3176B8";
    case "submitted":
      return "#9575CD";
    case "completed":
      return "#508B46";
    case "failed":
    case "leave_failed":
    case "join_failed":
    case "nuking_failed":
      return red[500];
    default:
      return never(label, "#3176B8");
  }
};
