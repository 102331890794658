import { type CachingEnabledOptions, type CachingOptions } from "./types";

const defaultDisableReactQueryValue = true;

let disableReactQueryValue: boolean | undefined = undefined;

export const disableReactQuery = () => {
  disableReactQueryValue = true;
};

export const shouldEnabledCaching = (caching: CachingOptions | undefined): caching is CachingEnabledOptions => {
  if (disableReactQueryValue) {
    return false;
  }

  if (!caching) {
    return !defaultDisableReactQueryValue;
  }

  return !!caching.caching;
};
