import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

import { CARD_HEADER_HEIGHT, HEADER_PADDING } from "./Analytics/cloudCardStyle";

const useStyles = makeStyles(() => ({
  cardHeader: {
    padding: HEADER_PADDING,
    height: CARD_HEADER_HEIGHT,
  },
  cardContent: {
    paddingBottom: 0,
  },
}));

export const SkeletonCard = ({ widgetHeight }: { widgetHeight: number }) => {
  const classes = useStyles();

  return (
    <Skeleton animation="wave" variant="rectangular" width="100%">
      <Box>
        <Card>
          <CardHeader className={classes.cardHeader} />
          <CardContent sx={{ height: widgetHeight }} />
        </Card>
      </Box>
    </Skeleton>
  );
};
