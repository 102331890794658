import { AssetTypeAmazonWebServices, AssetTypeDoiTCloudIntelligence, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { Box, TableCell, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import Hide from "../../../Components/HideChildren/Hide";
import { useAuthContext } from "../../../Context/AuthContext";
import { formatCurrency, formatDecimalNumber } from "../../../utils/common";
import ContractsListItemMenu from "./ContractsListItemMenu";
import { type FormattedContract, type FormattedVendorContract } from "./types";
import {
  getContractEndDate,
  getFlexsaveSpecificDiscount,
  getVendorCommitmentText,
  getVendorContractTypeText,
} from "./utils";

const VendorContractText = ({ children, show, dataCy }: { children: any; show?: boolean; dataCy?: string }) =>
  show ? (
    <Typography
      paragraph
      variant="caption"
      color="textSecondary"
      data-cy={dataCy}
      sx={{
        mb: 0,
        minHeight: "20px",
      }}
    >
      {children}
    </Typography>
  ) : null;

const ContractRow = ({
  contract,
  handleDownload,
}: {
  contract: FormattedContract;
  handleDownload: (contract: FormattedContract | FormattedVendorContract) => Promise<void>;
}) => {
  const { isDoitEmployee } = useAuthContext();
  const vendorCommitmentText = getVendorCommitmentText(contract);
  const vendorContractTypeText = getVendorContractTypeText(contract);
  const vendorContract = contract.data.vendorContractData;

  const ifShowVendorContractRow =
    isDoitEmployee &&
    contract.data.isCommitment &&
    [AssetTypeGoogleCloud, AssetTypeAmazonWebServices].includes(contract.data.type);

  return (
    <>
      <TableCell>
        <Box data-cy={`vendor-type-${contract.id}`}>
          {contract.data._product}
          {!!contract.data._tier && ` - ${contract.data._tier}`}
        </Box>
        <VendorContractText show={ifShowVendorContractRow} dataCy={`vendor-commitment-${contract.id}`}>
          {vendorCommitmentText}
        </VendorContractText>
      </TableCell>
      <Hide mdDown>
        <TableCell>
          <Box data-cy={`status-${contract.id}`}>{capitalize(contract.data._status)}</Box>
          <VendorContractText show={ifShowVendorContractRow} dataCy={`vendor-contract-status-${contract.id}`}>
            {capitalize(vendorContract?.data._status)}
          </VendorContractText>
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell>
          <Box data-cy={`commitment-${contract.id}`}>{contract.data._customerCommitmentType}</Box>
          <VendorContractText show={ifShowVendorContractRow} dataCy={`vendor-commitment-type-${contract.id}`}>
            {vendorContractTypeText}
          </VendorContractText>
        </TableCell>
      </Hide>
      <TableCell>
        <Box data-cy={`start-date-${contract.id}`}>{contract?.data._startDate.toLocaleString(DateTime.DATE_MED)}</Box>
        <VendorContractText show={ifShowVendorContractRow} dataCy={`start-date-vendor-contract-${contract.id}`}>
          {vendorContract?.data._startDate?.toLocaleString(DateTime.DATE_MED)}
        </VendorContractText>
      </TableCell>
      <Hide mdDown>
        <TableCell>
          <Box data-cy={`end-date-${contract.id}`}>{getContractEndDate(contract)}</Box>
          <VendorContractText show={ifShowVendorContractRow} dataCy={`end-date-vendor-contract-${contract.id}`}>
            {vendorContract?.data._endDate ? vendorContract?.data._endDate.toLocaleString(DateTime.DATE_MED) : ""}
          </VendorContractText>
        </TableCell>
      </Hide>
      {isDoitEmployee && (
        <TableCell>
          {contract.data.type !== AssetTypeDoiTCloudIntelligence && (
            <Box data-cy={`discount-${contract.id}`}>
              {getFlexsaveSpecificDiscount(contract) ?? formatDecimalNumber(contract.data.discount, 2, 0)}%
            </Box>
          )}
          <VendorContractText show={ifShowVendorContractRow} dataCy={`discount-vendor-contract-${contract.id}`}>
            {vendorContract && `${formatDecimalNumber(vendorContract?.data.discount, 2, 0)}%`}
          </VendorContractText>
        </TableCell>
      )}
      <Hide mdDown>
        <TableCell>
          <Box data-cy={`entity-${contract.id}`}>{contract.data._entity}</Box>
          <VendorContractText show={ifShowVendorContractRow} dataCy={`entity-vendor-contract-${contract.id}`}>
            {vendorContract?.data._entity}
          </VendorContractText>
        </TableCell>

        <TableCell>
          <Box data-cy={`commitment-value-${contract.id}`}>
            {contract.data._commitmentValue ? formatCurrency(contract.data._commitmentValue, "USD", 0) : ""}
          </Box>
          <VendorContractText show={ifShowVendorContractRow} dataCy={`commitment-value-vendor-contract-${contract.id}`}>
            {vendorContract ? formatCurrency(vendorContract.data._commitmentValue, "USD", 0) : ""}
          </VendorContractText>
        </TableCell>
      </Hide>
      <TableCell>
        <ContractsListItemMenu
          contract={contract.data}
          id={contract.id}
          handleDownload={() => handleDownload(contract)}
          handleDownloadVendorContract={vendorContract ? () => handleDownload(vendorContract) : undefined}
        />
      </TableCell>
    </>
  );
};

export default ContractRow;
