import { type ReactElement, type ReactNode } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { Box, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";

import Hide from "../HideChildren/Hide";

type Props = {
  illustration: ReactElement;
  title: string;
  listItems?: string[];
  children?: ReactNode;
};

export const LensEnticement = ({ title, listItems, illustration, children }: Props) => (
  <Box data-cy="lensEnticementContainer" sx={{ flex: 1, py: [2, 4, 6], px: [4, 8, 16] }}>
    <Stack direction="row" justifyContent="space-between">
      <Stack data-cy="bqEmptyStateDashboard_title" spacing={4} sx={{ maxWidth: "565px" }}>
        <Typography variant="h1">{title}</Typography>
        <List sx={{ pt: 0, pb: 0 }} data-cy="lensEnticement_CheckMarksList">
          {listItems?.map((item, index) => (
            <ListItem key={index} sx={{ p: 0, gap: 1 }}>
              <ListItemIcon sx={{ minWidth: "unset" }}>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
        {children}
      </Stack>
      <Hide mdDown>{illustration}</Hide>
    </Stack>
  </Box>
);
