import { type CloudFlowNodeType } from "@doitintl/cmp-models";

import {
  getModelByPath,
  getNodeOutputModel,
  type GetOutputModelForActionNodeFn,
  wrapModelWithListModel,
} from "../model-descriptors";
import { type NodeModelWithId } from "../types";

export async function calculateConditionalNodeOutputModel(
  referencedNode: NodeModelWithId<CloudFlowNodeType.CONDITION | CloudFlowNodeType.FILTER>,
  nodes: NodeModelWithId[],
  getOutputModelForActionNode: GetOutputModelForActionNodeFn
) {
  // FIXME: adjust FilterNode type to declare parameters as undefined or ensure they are always set
  if (!referencedNode.parameters?.referencedNodeId) {
    return null;
  }
  const referencedNodeOutputModel = await getNodeOutputModel(
    getOutputModelForActionNode,
    nodes,
    referencedNode.parameters.referencedNodeId
  );
  if (referencedNodeOutputModel === null) {
    return null;
  }
  return wrapModelWithListModel(getModelByPath(referencedNodeOutputModel, referencedNode.parameters.referencedField));
}
