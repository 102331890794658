import { type GCPSkuPricesModal, IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import memoizeOne from "memoize-one";

import { type GCPPricelistItem } from "./types";

function parseGCPPrices(gcpSkuPrice: GCPSkuPricesModal): GCPPricelistItem {
  const { cud3Yr, cud1Yr, description, familyType, hardware, price, region, skuId } = gcpSkuPrice;
  return {
    region,
    hardware,
    type: familyType,
    sku: skuId,
    description,
    flexsaveCharge: cud1Yr - cud3Yr,
    GCPPrice: cud3Yr,
    savingsRate: (cud1Yr / price) * 100,
  };
}

const skuPricesQuery = memoizeOne(() =>
  getCollection(IntegrationModel).doc("flexsave").collection("flexsave-gcp-sku-prices")
);

export const useGCPSkuPrices = () =>
  useCollectionData(skuPricesQuery(), {
    transform: parseGCPPrices,
  });
