import { type ReactNode } from "react";

import { type EarlyAccessFeature } from "@doitintl/cmp-models";

import { useAuthContext } from "../../Context/AuthContext";
import { type Customer } from "../../types";
import { Guard } from "./Guard";

export type EarlyAccessFeatureGuardProps = {
  customer: Customer;
  featureName: EarlyAccessFeature;
  children: ReactNode;
  fallbackToNoneExistPage?: boolean;
  allowDoitEmployees?: boolean;
};

/**
 * Use this guard to control access to experimental features. This guard reads settings that can be set using the
 * Config Dialog of the customer.
 * @param customer - Customer
 * @param featureName - Name of the feature at the customer config that will allow access
 * @param fallbackToNoneExistPage - if true, the NotFound component will be used in case the customer doesn't have access
 * @param children - the component to protect
 * @param allowDoitEmployees if true, the guard will allow doitemployee child component
 * @constructor
 */
export const EarlyAccessFeatureGuard = ({
  customer,
  featureName,
  children,
  allowDoitEmployees = true,
  fallbackToNoneExistPage = true,
}: EarlyAccessFeatureGuardProps) => {
  const { isDoitEmployee } = useAuthContext();

  return (
    <Guard
      isAllowed={(allowDoitEmployees && isDoitEmployee) || customer?.earlyAccessFeatures?.includes(featureName)}
      fallback={fallbackToNoneExistPage ? undefined : <></>}
    >
      {children}
    </Guard>
  );
};
