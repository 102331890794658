import { useCallback, useMemo } from "react";

import { Link as RouteLink } from "react-router-dom";
import { CurrencyCodes, GCPMarketplaceAdjustmentStatus } from "@doitintl/cmp-models";
import { Box, TableCell, Typography } from "@mui/material";

import { globalText } from "../../../../assets/texts";
import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { formatCurrency } from "../../../../utils/common";
import { type AdjustmentConfig } from "../hooks/useMarketplaceAdjustments";
import { adjustmentOptions } from "./utils";

type Props = {
  row: AdjustmentConfig;
  setOpenDeleteDialog: (open: boolean) => void;
  setEditDialogOpen: (open: boolean) => void;
  setClickedRow: (row: AdjustmentConfig) => void;
};

export const AdjustmentRow = ({ row, setOpenDeleteDialog, setEditDialogOpen, setClickedRow }: Props) => {
  const handleDelete = useCallback(() => {
    setOpenDeleteDialog(true);
    setClickedRow(row);
  }, [setOpenDeleteDialog, setClickedRow, row]);

  const handleEdit = useCallback(() => {
    setEditDialogOpen(true);
    setClickedRow(row);
  }, [setEditDialogOpen, setClickedRow, row]);

  const actionMenu = useMemo(
    () => ({
      [globalText.EDIT]: handleEdit,
      [globalText.DELETE]: handleDelete,
    }),
    [handleDelete, handleEdit]
  );

  const actionHandler = useCallback(
    (action: string) => () => {
      actionMenu[action]();
    },
    [actionMenu]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(() => {
    if (row.data.status === GCPMarketplaceAdjustmentStatus.DONE) {
      return [];
    }
    return adjustmentOptions
      .filter(() => row.data.status === GCPMarketplaceAdjustmentStatus.PENDING)
      .map((option) => ({
        label: <Typography color={option.color}>{option.value}</Typography>,
        action: actionHandler(option.value),
        key: option.value,
      }));
  }, [actionHandler, row.data.status]);

  return (
    <CellsWrapper>
      <TableCell>
        {row.data.customer && row.customer ? (
          <Typography color="inherit" variant="body2" component={RouteLink} to={`/customers/${row.data.customer.id}`}>
            {row.customer.data.primaryDomain}
          </Typography>
        ) : (
          <Typography color="inherit" variant="body2">
            {globalText.NA}
          </Typography>
        )}
      </TableCell>
      <TableCell>{formatCurrency(row.transform.value, CurrencyCodes.USD, 0)}</TableCell>
      <TableCell>{row.transform.targetTime}</TableCell>
      <TableCell>{row.transform.timeCreated}</TableCell>
      <TableCell>{row.transform.timeUpdated}</TableCell>
      <TableCell>{row.transform.timeReported || globalText.NA}</TableCell>
      <TableCell>{row.data.createdBy}</TableCell>
      <TableCell>{row.data.transactionFee ?? 0}%</TableCell>
      <TableCell>{row.data.status}</TableCell>
      <TableCell>
        {row.data.status === GCPMarketplaceAdjustmentStatus.PENDING && (
          <Box m={-1.5}>
            <ThreeDotsMenu options={threeDotsMenuOptions} />
          </Box>
        )}
      </TableCell>
    </CellsWrapper>
  );
};
