import { useRef } from "react";

import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Tooltip from "@mui/material/Tooltip";

export default function CopyToClipboard({ textToCopy, showSnackbar, type, spacer }) {
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    if (type !== "button") {
      e.preventDefault();
    }
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    showSnackbar({
      message: "Copied to Clipboard",
      variant: "info",
      autoHideDuration: 5000,
    });
  }

  return (
    <span>
      {
        /* Logical shortcut for only displaying the 
          button if the copy command exists */
        document.queryCommandSupported("copy") && (
          <div style={{ minWidth: spacer ? 63 : 0 }}>
            {type === "button" ? (
              <Tooltip title="Copy">
                <IconButton style={{ marginTop: 3 }} onClick={copyToClipboard} size="small" aria-label="Edit">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Link href="#" onClick={copyToClipboard}>
                Click here
              </Link>
            )}
          </div>
        )
      }
      <form>
        <textarea style={{ position: "absolute", top: -2050, zIndex: 0 }} ref={textAreaRef} defaultValue={textToCopy} />
      </form>
    </span>
  );
}
