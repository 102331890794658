import React, { useState } from "react";

import { type CurrencyCode } from "@doitintl/cmp-models";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { type DateTime } from "luxon";

import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";

interface YearsNextPeriodPullOverProps {
  startDate: DateTime;
  currency: CurrencyCode;
  disabled: boolean;
}

const YearsNextPeriodPullOver: React.FC<YearsNextPeriodPullOverProps> = ({ startDate, currency, disabled }) => {
  const startYear = startDate.year;
  const initialYears = Array.from({ length: 5 }, (_, i) => {
    const year = startYear + i;
    return { [year]: "" };
  }).reduce((acc, year) => ({ ...acc, ...year }), {});

  const [years, setYears] = useState<{ [key: string]: string }>(initialYears);

  const handleYearChange = (yearKey: string, value: string) => {
    setYears((prev) => ({ ...prev, [yearKey]: value }));
  };

  return (
    <Stack gap={3}>
      {Object.keys(years).map((yearKey, index) => (
        <TextField
          key={`YearsNextPeriodPullOver${index}`}
          variant="outlined"
          type="number"
          fullWidth
          required
          disabled={!disabled}
          label={yearKey}
          value={years[yearKey]}
          onChange={(e) => {
            handleYearChange(yearKey, e.target.value);
          }}
          onKeyUp={onKeyPressPreventNonNumeric}
          InputProps={{
            startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
          }}
        />
      ))}
    </Stack>
  );
};

export default YearsNextPeriodPullOver;
