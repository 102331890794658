import { globalText, pricebooksText } from "../../assets/texts";
import { type DataColumns, type HeaderColumn } from "../../types/FilterTable";
import { firestoreTimestampFilterProps } from "../EnhancedTableFilterUtils";
import { type AWSPricebook, type GCPPricebook } from "./Types";

export const AWSfilterColumns: DataColumns<AWSPricebook> = [
  {
    label: pricebooksText.TABLE_HEADERS.NAME,
    path: "bookName",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: pricebooksText.UPDATED_BY,
    path: "updatedBy",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: pricebooksText.ID,
    path: "id",
    comparators: ["==", "!=", "contains"],
    toOption: (value) => ({ value, label: `${value}` }),
  },
  {
    label: pricebooksText.COMMENT,
    path: "specification.comment",
    comparators: ["contains"],
  },
];

export const AWSHeaders: HeaderColumn<AWSPricebook>[] = [
  {
    value: "bookName",
    label: pricebooksText.TABLE_HEADERS.NAME,
    tooltip: pricebooksText.TABLE_HEADERS.NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "updatedAt",
    label: globalText.LAST_MODIFIED,
    tooltip: globalText.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "assignments",
    label: pricebooksText.TABLE_HEADERS.ASSIGNED_TO_CUSTOMER_TENANTS,
    tooltip: pricebooksText.TABLE_HEADERS.ASSIGNED_TO_CUSTOMER_TENANTS,
    style: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
  },
  {
    value: "@",
  },
];

export const GCPfilterColumns: DataColumns<GCPPricebook> = [
  {
    label: pricebooksText.TABLE_HEADERS.CUSTOMER,
    path: "metadata.customer.name",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: pricebooksText.TABLE_HEADERS.STARTDATE,
    path: "startDate",
    comparators: ["<", "<=", ">", ">=", "=="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: pricebooksText.TABLE_HEADERS.ENDDATE,
    path: "endDate",
    comparators: ["<", "<=", ">", ">=", "=="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    label: pricebooksText.TABLE_HEADERS.TABLE,
    path: "tableRef",
    comparators: ["==", "!=", "contains"],
  },
] as const;

export const getGCPHeaders = (isDoitDeveloper: boolean): HeaderColumn<GCPPricebook>[] => {
  const columns: HeaderColumn<GCPPricebook>[] = [
    {
      value: "metadata.customer.name",
      label: pricebooksText.TABLE_HEADERS.CUSTOMER,
      tooltip: pricebooksText.TABLE_HEADERS.CUSTOMER,
      style: { align: "left", padding: "normal" },
    },
    {
      value: "startDate",
      label: pricebooksText.TABLE_HEADERS.STARTDATE,
      tooltip: pricebooksText.TABLE_HEADERS.STARTDATE,
      style: { align: "left", padding: "normal" },
      hidden: { mdDown: true },
    },
    {
      value: "endDate",
      label: pricebooksText.TABLE_HEADERS.ENDDATE,
      tooltip: pricebooksText.TABLE_HEADERS.ENDDATE,
      style: { align: "left", padding: "normal" },
      hidden: { mdDown: true },
    },
    {
      value: "tableRef",
      label: pricebooksText.TABLE_HEADERS.TABLE,
      tooltip: pricebooksText.TABLE_HEADERS.TABLE,
      style: { align: "left", padding: "normal" },
    },
  ];

  if (isDoitDeveloper) {
    columns.push({
      value: "@",
      style: { align: "left", padding: "checkbox" },
      hidden: { smDown: true },
    });
  }

  return columns;
};
