import styled from "styled-components";

const CopyToClipboardLink = styled.span`
  cursor: pointer;
  font-weight: bold;
  margin: 0 8px;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: #20b0e8;
  }
`;

type Props = {
  usersToCreate?: any[];
  usersToUpdate?: any[];
  errors: string[];
  criticalFailure?: boolean;
};

export const UploadUsersSnackbarMessage = ({ usersToCreate, usersToUpdate, errors, criticalFailure }: Props) => {
  const createdUsersDetails = usersToCreate?.map((user) => `${user.email} - created`).join(",");
  const updatedUsersDetails = usersToUpdate?.map((user) => `${user.email} - updated`).join(",");
  const errorsDetails = errors.join(",");
  const copyToClipboardDetails = [createdUsersDetails, updatedUsersDetails, errorsDetails].filter(Boolean).join(", ");

  return (
    <>
      {criticalFailure ? (
        <span>Couldn't parse file.</span>
      ) : (
        <>
          {createdUsersDetails && usersToCreate && (
            <span>
              you have created
              <span style={{ margin: "0 4px" }}>{usersToCreate.length}</span>
              users.
            </span>
          )}
          {updatedUsersDetails && usersToUpdate && (
            <span>
              <span style={{ margin: "0 4px" }}>{usersToUpdate.length}</span>
              users have been updated.
            </span>
          )}
          {errorsDetails && (
            <span>
              <span style={{ margin: "0 4px" }}>{errors.length}</span>
              users couldn't be processed.
            </span>
          )}
        </>
      )}
      <CopyToClipboardLink onClick={() => navigator.clipboard.writeText(copyToClipboardDetails)}>
        COPY LOG
      </CopyToClipboardLink>
    </>
  );
};
