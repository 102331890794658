import { useCallback, useMemo } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { AccountManagersHooks } from "../../../../../../Context/customer/AccountManagers";
import { useContractFormContext } from "../../../ContractsFormContext";

export const AccountManagerSelector = () => {
  const { state, setState } = useContractFormContext();
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const accountManagersOptions = useMemo(
    () =>
      accountManagers
        ?.map((accountManager) => ({
          label: accountManager.name,
          value: accountManager.id,
        }))
        .sort((a, b) =>
          (a.label.split(" ")[1] ?? a.label.split(" ")[0]).localeCompare(b.label.split(" ")[1] ?? b.label.split(" ")[0])
        ),
    [accountManagers]
  );
  const value = useMemo(
    () => accountManagersOptions?.find((accountManager) => accountManager.value === state.accountManager) ?? null,
    [accountManagersOptions, state.accountManager]
  );

  const handleChange = useCallback(
    (_, selectedValue) => {
      setState((prevState) => ({
        ...prevState,
        accountManager: selectedValue?.value,
        errors: {
          ...state.errors,
          accountManager: false,
        },
      }));
    },
    [setState, state.errors]
  );

  if (!accountManagersOptions || !accountManagers) {
    return null;
  }

  return (
    <Autocomplete
      id="account-manager"
      blurOnSelect
      options={accountManagersOptions}
      value={value}
      onChange={handleChange}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Account Manager"
          helperText="Select account manager for this contract"
          margin="dense"
          error={state.errors.accountManager}
          fullWidth
        />
      )}
      disabled={state.isEditForbidden}
      fullWidth
    />
  );
};
