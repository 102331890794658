import { AppModel, type MSTeamsTarget, NotificationProviderType } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { type ChannelConfig } from "./types";

export const getTitleDescriptionStep = (channelConfig?: ChannelConfig) => {
  let title = "Select channels";
  let description = "Add the channels you want to receive notifications";
  switch (channelConfig?.providerType) {
    case NotificationProviderType.SLACK:
      break;
    case NotificationProviderType.EMAIL:
      title = "Add emails";
      description = "Add the emails you want to receive notifications";
      break;
    case NotificationProviderType.MSTEAMS:
      title = "Select Microsoft Teams channels";
      description = "Choose which channels the notification should be delivered to";
      break;
  }
  return { title, description };
};

export const useMSTeamsChannels = (): MSTeamsTarget[] => {
  const customerId = useCustomerId();
  const ref = getCollection(AppModel).doc("msteams-app").collection("channels").where("customerId", "==", customerId);

  const [channels] = useCollectionData(ref, { refField: "ref" });

  if (!channels) {
    return [];
  }

  const targets: MSTeamsTarget[] = channels.map((channel) => ({
    channelRef: channel.ref,
    channel: {
      name: channel.channelName,
      team_name: channel.teamName,
      conversation_id: channel.conversationId,
      tenant_id: channel.tenantId,
      service_url: channel.serviceUrl,
    },
  }));

  return targets;
};
