import { type FlexsaveType, type MonthSavings, type PaymentMethod } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

export type ReasonCantEnableGCP =
  | "dedicated payer account"
  | "no contract"
  | "no spend"
  | "low spend"
  | "no assets"
  | "no billing profile"
  | "disabled by feature flag"
  | "not enough eligible days"
  | "no contract attached to asset"
  | "other";

export type GCPInfoMessage = {
  type: FlexsaveType;
  reason: ReasonCantEnableGCP;
};

export type PotentialFlexsaveSavings = {
  type: FlexsaveType;
  nextMonth: MonthSavings;
  hasBeenDisabled?: boolean;
};

export type ActiveFlexsaveSavings = PotentialFlexsaveSavings & {
  currentMonth: MonthSavings;
  previousMonth?: MonthSavings;
  history: Map<string, MonthSavings>; // month string to savings mapping
  dailyHistory?: Record<string, MonthSavings>;
};

export type EnabledSavings = {
  currentMonthId: string;
  months: string[]; // holds the last 6 months and the next months that will be shown in the chart
  savings: ActiveFlexsaveSavings[];
  timeOptIn?: DateTime;
  disableNotified?: boolean;
};

export type FlexsaveData = {
  potentialFlexSaveSavings?: PotentialFlexsaveSavings;
  info?: GCPInfoMessage;
  enabled?: EnabledSavings;
  flexsaveEligibleUsageDays?: number;
};

export type SavingsEstimations = {
  annualSavings: number;
  annualSavingsWithGrowth: number;
};

export type PreviousComputeSpend = {
  lastMonthComputeSpend: number;
  lastMonthWithFlexsave: number;
};

export type BillingProfile = {
  id: string;
  priorityId: string;
  name: string;
  paymentMethod: PaymentMethod;
  currency: string;
};

export enum StandaloneType {
  AWS = "amazon-web-services-standalone",
  GCP = "google-cloud-standalone",
}

export type FlexsaveState =
  | "checking_savings"
  | "estimation_calculated"
  | "estimation_calculated_end_of_month"
  | "early_active"
  | "invoice_period"
  | "active"
  | "active_no_savings"
  | "disabled"
  | "error"
  | "insufficient_savings";

export type ActiveData = {
  savingsRate: string;
  savings: string;
  month: string;
};

export type ExtraInfo = {
  savings?: string;
  activeData?: ActiveData;
  error?: string;
};
