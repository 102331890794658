import { type ChangeEvent } from "react";

import { Stack, TextField } from "@mui/material";

import { INVALID_DATE_ERROR_MSG, isDateBefore, validateDate } from "./dateRangeUtils";

type DateRangeInputProps = {
  start: string;
  end: string;
  onStartDateChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onEndDateChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};
const DateRangeInput = ({ start, end, onStartDateChange, onEndDateChange }: DateRangeInputProps) => {
  const startDateValidation = validateDate(start);
  const endDateValidation = validateDate(end);
  const isDateInOrder = isDateBefore(startDateValidation.date, endDateValidation.date);
  const startErr = !startDateValidation.isValid || !isDateInOrder;
  const endErr = !endDateValidation.isValid || !isDateInOrder;
  return (
    <Stack direction="row" justifyContent="space-between" spacing={4} sx={{ px: "1rem", py: 3, height: "90px" }}>
      <TextField
        data-cy="start-date-input-container"
        error={startErr}
        fullWidth
        helperText={startErr ? INVALID_DATE_ERROR_MSG : ""}
        inputProps={{ "data-cy": "start-date-input" }}
        label="From"
        onChange={onStartDateChange}
        value={start}
      />
      <TextField
        data-cy="end-date-input-container"
        error={endErr}
        fullWidth
        helperText={endErr ? INVALID_DATE_ERROR_MSG : ""}
        inputProps={{ "data-cy": "end-date-input" }}
        label="To"
        onChange={onEndDateChange}
        value={end}
      />
    </Stack>
  );
};

export default DateRangeInput;
