import { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router";
import { type EKSMetrics, IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, type QueryModel, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { EKSApi } from "./api";
import { ConfigureStep } from "./Steps/ConfigureStep";
import { ConnectionStep } from "./Steps/ConnectionStep";
import { CONFIGURATION_STEP, CONNECTION_STEP, DEPLOYMENT_STEP, PERMISSION_STEP } from "./Steps/consts";
import { DeploymentStep } from "./Steps/DeploymentStep";
import { PermissionStep } from "./Steps/PermissionStep";
import {
  type ClusterConfig,
  type DeploymentMethod,
  DeploymentMethodCloudFormation,
  DeploymentMethodTerraform,
  type OnboardingStep,
} from "./types";
import { setDeploymentMethodForCluster, transformData } from "./utils";

export function useEKSClusters() {
  const { customer } = useCustomerContext({ allowNull: true });
  let query: QueryModel<EKSMetrics> | undefined;
  if (!customer?.id) {
    query = undefined;
  } else {
    query = getCollection(IntegrationModel).doc("k8s-metrics").collection("eks").where("customerId", "==", customer.id);
  }

  return useCollectionData(query, { transform: transformData });
}

export function useClusterDeployment(clusterConfig: ClusterConfig) {
  return useDocumentData(clusterConfig.deploymentRef);
}

export function useMultistep(clusterConfig: ClusterConfig) {
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState<OnboardingStep>(DEPLOYMENT_STEP);
  const [deploymentMode, setDeploymentMode] = useState<DeploymentMethod | "">("");

  const permissionStepCompleted =
    currentStep !== CONNECTION_STEP &&
    currentStep !== CONFIGURATION_STEP &&
    currentStep !== DEPLOYMENT_STEP &&
    clusterConfig.status === "in-progress";
  const configurationStepCompleted =
    currentStep === CONFIGURATION_STEP &&
    clusterConfig.cloudFormationStatus === "success" &&
    clusterConfig.deploymentMethod === DeploymentMethodTerraform;

  const handleDeploymentSelected = (deployment: DeploymentMethod | "") => {
    setDeploymentMode(deployment);
  };

  const steps = useMemo(() => {
    if (deploymentMode === DeploymentMethodCloudFormation) {
      return {
        DEPLOYMENT_STEP: (
          <DeploymentStep
            clusterConfig={clusterConfig}
            handleDeploymentSelected={handleDeploymentSelected}
            deploymentSelected={deploymentMode}
          />
        ),
        PERMISSION_STEP: <PermissionStep clusterConfig={clusterConfig} stepCompleted={permissionStepCompleted} />,
        CONNECTION_STEP: <ConnectionStep clusterConfig={clusterConfig} />,
      };
    }

    return {
      DEPLOYMENT_STEP: (
        <DeploymentStep
          clusterConfig={clusterConfig}
          handleDeploymentSelected={handleDeploymentSelected}
          deploymentSelected={deploymentMode}
        />
      ),
      CONFIGURATION_STEP: <ConfigureStep clusterConfig={clusterConfig} />,
    };
  }, [clusterConfig, deploymentMode, permissionStepCompleted]);

  const next = () => {
    const currentPathname = history.location.pathname;
    const newPathname = currentPathname.substring(0, currentPathname.lastIndexOf("/"));

    switch (currentStep) {
      case DEPLOYMENT_STEP:
        if (deploymentMode === DeploymentMethodTerraform) {
          setCurrentStep(CONFIGURATION_STEP);
          break;
        }
        setCurrentStep(PERMISSION_STEP);
        break;
      case PERMISSION_STEP:
        setCurrentStep(CONNECTION_STEP);
        break;
      case CONNECTION_STEP:
        history.push(newPathname);
        break;
      case CONFIGURATION_STEP:
        history.push(newPathname);
        break;
    }
  };

  const back = () => {
    switch (currentStep) {
      case DEPLOYMENT_STEP:
        history.goBack();
        break;
      case PERMISSION_STEP:
        setCurrentStep(DEPLOYMENT_STEP);
        break;
      case CONNECTION_STEP:
        if (clusterConfig.status === "pending-update") {
          history.goBack();
        }
        setCurrentStep(PERMISSION_STEP);
        break;
      case CONFIGURATION_STEP:
        setCurrentStep(DEPLOYMENT_STEP);
        break;
    }
  };

  return {
    currentStep,
    step: steps[currentStep],
    steps,
    isFirstStep: currentStep === DEPLOYMENT_STEP,
    isSecondStep: currentStep === PERMISSION_STEP,
    isLastStep: currentStep === CONNECTION_STEP || currentStep === CONFIGURATION_STEP,
    next,
    back,
    allowNext:
      permissionStepCompleted ||
      clusterConfig.status === "active" ||
      (currentStep === DEPLOYMENT_STEP && !!deploymentMode) ||
      configurationStepCompleted,
  };
}

export function useEKSApi() {
  const api = useApiContext();
  return useMemo(() => new EKSApi(api), [api]);
}

export const useFirstCloudFormationDeploy = (
  accountId: string,
  region: string
): { firstDeployInAccount: boolean; firstDeployInRegion: boolean } => {
  const [clusters] = useEKSClusters();
  if (!clusters) {
    return { firstDeployInAccount: false, firstDeployInRegion: false };
  }

  const clustersWithSuccessStatus = clusters.filter(
    (cluster) => cluster.accountId === accountId && cluster.cloudFormationStatus === "success"
  );

  const firstDeployInGeneral = clustersWithSuccessStatus.length === 0;
  const firstDeployInRegion = clustersWithSuccessStatus.filter((cluster) => cluster.region === region).length === 0;

  return { firstDeployInAccount: firstDeployInGeneral, firstDeployInRegion };
};

export const useIsFirst24Hours = (clusters: ClusterConfig[]): boolean =>
  useMemo(
    () =>
      clusters.some((cluster) => {
        if (cluster.deployedAt && cluster.status === "active") {
          const hours = (new Date().getTime() - cluster.deployedAt.getTime()) / (1000 * 60 * 60);
          return hours < 24;
        }
        return false;
      }),
    [clusters]
  );

export const useSetDeploymentMethod = (clusters: ClusterConfig[] | undefined): [ClusterConfig[], boolean] => {
  const [updatedClusters, setUpdatedClusters] = useState<ClusterConfig[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (clusters) {
      setIsLoading(true);
      const newClusters = clusters.map((cluster) => setDeploymentMethodForCluster(cluster, clusters));
      setUpdatedClusters(newClusters);
      setIsLoading(false);
    }
  }, [clusters]);

  return [updatedClusters, isLoading];
};
