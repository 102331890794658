import * as Sentry from "@sentry/react";
import get from "lodash/get";
import isArray from "lodash/isArray";
import { DateTime } from "luxon";

import {
  type Comparator,
  type DataColumn,
  type DataColumns,
  type DefaultFilterColumn,
  type HeaderColumn,
  type HeaderColumns,
  type HeaderColumnStyle,
  type LogicalOperator,
  Operators,
} from "../../types/FilterTable";
import { sanitizeDate } from "../../utils/common";
import { type ColumnChange } from "./types";

type FilterType = "number" | "string" | "date";

/**
 * Use this descriptor to define the columns and the filters of the FilterTable easily.
 * FilterTableItemDescriptor accepts Table Item as Generic type (T), which adds type safety.
 * Each descriptor should have a single value (one of the properties of the type T). This value will be used
 * to sort and filter the table rows.
 * For each column value, there is an option to add a filter in an easy way
 * with three pre-defined filter comparators (number, string, date).
 *
 * For Example:
 * If you have a type 'type TableItem = {value_a: string, value_b: number}';
 * And you need to show array of TableItems in a table, then this is how you will define the descriptors
 *
 * const itemsDescriptions: FilterTableItemDescriptor<TableItem>[] = [
 * {
 *   headerText: "Header_Text_A",
 *   value: "value_a",
 *   filterType: "string",
 * },
 * {
 *   headerText: "Header_Text_B",
 *   value: "value_b",
 *   filterType: "number",
 * }];
 */
export type FilterTableItemDescriptor<T> = {
  headerText: string;
  headerStyle?: HeaderColumnStyle; // additional styling for the header
  value: HeaderColumn<T>["value"]; // the item property
  tooltip?: string; // by default will get the same value as header text
  disableSorting?: boolean; // disable sorting if clicking on the header
  filterType?: FilterType; // filter type with default comparators
  filterOptions?: {
    // additional advanced filtering options
    comparators?: Comparator[];
    toOption?: (value: any) =>
      | {
          value: any;
          label: string;
        }
      | undefined;
    transform?: (value: any) => any;
  };
  hidden?: HeaderColumn<T>["hidden"]; // When to hide the header for this column
  hiddenByDefault?: boolean;
};

const getComparatorsConfigByFilterType = <T>(filterType?: FilterType): Partial<DataColumn<T>> | undefined => {
  if (!filterType) {
    return;
  }

  if (filterType === "number") {
    return {
      type: "Number",
      comparators: ["<", "<=", ">", ">=", "==", "!="],
    };
  } else if (filterType === "string") {
    return {
      comparators: ["==", "contains"],
    };
  } else if (filterType === "date") {
    return {
      type: "DateTime",
      comparators: ["<", "<=", ">", ">="],
      placeholder: "YYYY-MM-DD",
      transform: (value: string) => sanitizeDate(DateTime.fromFormat(value, "yyyy-LL-dd", { zone: "utc" })),
      validate: (value: string) => DateTime.fromFormat(value, "yyyy-LL-dd")?.isValid,
      toOption: (value: DateTime) => {
        if (!value) {
          return;
        }
        const formattedDate = value.toFormat("yyyy-LL-dd");
        return {
          value: formattedDate,
          label: formattedDate,
        };
      },
    };
  }
};

function generateHeaders<T>(definitions: FilterTableItemDescriptor<T>[]): HeaderColumns<T> {
  return definitions.map((definition) => {
    const headerColumn: HeaderColumn<T> = {
      value: definition.value,
      label: definition.headerText,
      tooltip: definition.tooltip ?? definition.headerText,
      style: definition.headerStyle,
      hidden: definition.hidden,
      disableSort: definition.disableSorting,
      hiddenByDefault: definition.hiddenByDefault,
    };
    return headerColumn;
  });
}

const generateFilters = <T>(definitions: FilterTableItemDescriptor<T>[]): DataColumns<T> =>
  definitions
    .filter((definition) => definition.filterType || definition.filterOptions)
    .flatMap((definition) => {
      if (definition.value === "@") {
        return [];
      }
      const defaultComparators = getComparatorsConfigByFilterType<T>(definition.filterType);
      return {
        label: definition.headerText,
        path: definition.value,
        ...defaultComparators,
        ...definition.filterOptions,
      };
    });

export function generateHeadersAndFilters<T>(definitions: FilterTableItemDescriptor<T>[]): {
  headerColumns: HeaderColumns<T>;
  filters: DataColumns<T>;
} {
  const headerColumns = generateHeaders(definitions);
  const filters = generateFilters(definitions);

  return {
    headerColumns,
    filters,
  };
}

export function applyColumnsChangeToArray<T = unknown>(
  arrayToReorder: readonly T[],
  columnsChange: ColumnChange[]
): readonly T[] {
  if (arrayToReorder.length > columnsChange.length) {
    if (process.env.NODE_ENV === "development" && process.env.CYPRESS !== "true") {
      throw new Error("rowComponent has more elements than headerColumns. Please check FilterTable configuration");
    }

    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(
        new Error("rowComponent has more elements than headerColumns. Please check FilterTable configuration")
      );
    }

    return arrayToReorder;
  }

  const result: T[] = [];

  arrayToReorder.forEach((value, i) => {
    if (columnsChange[i].visible) {
      result[columnsChange[i].newIndex] = value;
    }
  });

  return result;
}

export const getDefaultColumnsChanges = <T>(headerColumns: HeaderColumns<T>) =>
  headerColumns.map((header, i) => {
    const column: ColumnChange = {
      value: header.label ?? "",
      visible: !header.hiddenByDefault,
      newIndex: i,
    };
    return column;
  });

export function isLogicalOperator(op: unknown): op is LogicalOperator {
  return typeof op === "string" && Operators.includes(op as LogicalOperator);
}

export function toggleOperator(index: number, values: (LogicalOperator | DefaultFilterColumn<unknown>)[]) {
  return values.map((v, i) => {
    if (i === index) {
      return v === "AND" ? "OR" : "AND";
    } else {
      return v;
    }
  });
}

// keyCount is an alternative for lodash.countBy which will iterate
// over a field that is an array. It also supports transforming the key values
// in case they are objects or need to be changed for some reason.
export const keyCount = <T>(
  arr: T[],
  key: string,
  transform?: (d: any) => string | string[]
): Record<string, number> => {
  const count: Record<string, number> = {};

  arr.forEach((item) => {
    let keyVal = get(item, key);
    if (!keyVal) {
      return;
    }

    keyVal = transform?.(keyVal) ?? keyVal;
    const keys = isArray(keyVal) ? keyVal : [keyVal];

    keys.forEach((k) => {
      count[k] = (count[k] ?? 0) + 1;
    });
  });

  return count;
};
