import { type JSX } from "react";

import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  type PopperPlacementType,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { type HeaderDropdownMenuItem } from "../../types";
import { useHeaderMenuItem } from "./hooks";

const useStyles = makeStyles((theme: Theme) => ({
  innerMenuLink: {
    textTransform: "none",
    textDecoration: "none",
    minWidth: 0,
  },
  noHover: {
    "&:hover": {
      backgroundColor: theme.palette.navigation.text,
      color: theme.palette.navigation.background,
    },
  },
  contained: {
    padding: theme.spacing(0.75, 1),
    backgroundColor: theme.palette.navigation.text,
    color: theme.palette.navigation.background,
    border: 0,
  },
}));

type Props = {
  isSelected?: boolean;
  innerMenuItemComponent: JSX.Element | string;
  items: HeaderDropdownMenuItem[];
  id: string;
  subheader?: JSX.Element;
  customHandler?: () => void;
  menuLink?: string;
  placement?: PopperPlacementType;
};

const HeaderMenuItem = ({
  isSelected = false,
  innerMenuItemComponent,
  customHandler,
  items,
  id,
  subheader,
  menuLink,
  placement = "top-start",
}: Props) => {
  const classes = useStyles();
  const { handleClick, handleClose, handleCloseClickAway, handleListKeyDown, open, anchorRef } = useHeaderMenuItem(
    !!items.length,
    customHandler
  );
  const buttonVariant = isSelected ? "contained" : "text";

  const renderItem = (item: HeaderDropdownMenuItem) => {
    if (item.type === "divider") {
      return <Divider />;
    } else if (item.type === "component") {
      return item.component;
    } else if (item.route) {
      return (
        <MenuItem
          data-cy={item.dataCy}
          onClick={handleClose({ remainOpen: item.remainOpen })}
          component={NavLink}
          to={item.route}
        >
          {item.component}
        </MenuItem>
      );
    } else {
      return (
        <MenuItem data-cy={item.dataCy} onClick={handleClose({ remainOpen: item.remainOpen, handler: item.handler })}>
          {item.component}
        </MenuItem>
      );
    }
  };

  return (
    <Box>
      {menuLink ? (
        <Button
          component={NavLink}
          to={menuLink}
          className={clsx(classes.innerMenuLink, isSelected && classes.noHover)}
          variant={buttonVariant}
          classes={{
            contained: classes.contained,
          }}
          aria-label={id}
        >
          {innerMenuItemComponent}
        </Button>
      ) : (
        <Button
          ref={anchorRef}
          variant={buttonVariant}
          color="header"
          onClick={handleClick}
          disableFocusRipple
          className={clsx(isSelected && classes.noHover)}
          aria-label={id}
        >
          {innerMenuItemComponent}
        </Button>
      )}
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement={placement}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Box pt={1.8}>
              <Paper elevation={10}>
                <ClickAwayListener onClickAway={handleCloseClickAway({})}>
                  <MenuList autoFocusItem={open} id={id} onKeyDown={handleListKeyDown} subheader={subheader}>
                    {items.map((item, index) => (
                      <div key={index}>{renderItem(item)}</div>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Box>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default HeaderMenuItem;
