import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../Context/AuthContext";
import { convertHexToRGBA } from "../../../../utils/color";
import { type CustomerNote } from "../types";
import { NoteAvatar } from "./NoteAvatar";
import { NoteContent } from "./NoteContent";
import { NoteTitle } from "./NoteTitle";

type Props = {
  noteItem: CustomerNote;
  onEditNote: () => void;
  onPinNote: () => void;
};

const Note = ({ noteItem, onEditNote, onPinNote }: Props) => {
  const isDarkMode = useDarkThemeCheck();
  const bgColorLight = convertHexToRGBA("3B40B5", 15);
  const bgColorDark = convertHexToRGBA("B3BEFF", 15);
  const bgColor = isDarkMode ? bgColorDark : bgColorLight;

  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const menuOptions: ThreeDotsMenuOption[] = [
    {
      label: <Typography>{noteItem.pinned ? "Unpin" : "Pin"}</Typography>,
      action: onPinNote,
      key: "pinNote",
      dataCy: "pin-note",
    },
    {
      label: (
        <Typography sx={noteItem.email === currentUser.email ? {} : { opacity: 0.15, cursor: "not-allowed" }}>
          Edit
        </Typography>
      ),
      action: noteItem.email === currentUser.email ? onEditNote : () => void 0,
      key: "editNote",
      dataCy: "edit-note",
    },
  ];

  const timestamp = noteItem.timestamp ? noteItem.timestamp.toDate() : new Date();
  const subheader = DateTime.fromJSDate(timestamp).toLocaleString(DateTime.DATETIME_MED);
  return (
    <>
      <Card sx={{ background: noteItem.pinned ? bgColor : "none", border: "none", borderRadius: 0 }}>
        <CardHeader
          avatar={<NoteAvatar noteItem={noteItem} />}
          action={<ThreeDotsMenu options={menuOptions} closeAfterSelect={true} />}
          title={<NoteTitle noteItem={noteItem} />}
          variant="outlined"
          subheader={subheader}
          titleTypographyProps={{
            variant: "subtitle1",
            fontWeight: 500,
          }}
          subheaderTypographyProps={{
            variant: "caption",
          }}
          data-cy="note-card-header"
        />
        <CardContent sx={{ mt: -2 }} data-cy="note-card-content">
          <NoteContent noteItem={noteItem} />
        </CardContent>
      </Card>
    </>
  );
};

export default Note;
