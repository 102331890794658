import ReactMarkdown from "react-markdown";
import { Link, Typography } from "@mui/material";

const MarkdownToReact = ({ children, typographyPropsOverrides = {}, h1Props = {}, pProps = {}, aProps = {} }) => (
  <ReactMarkdown
    components={{
      h1: ({ ...props }) => (
        <Typography gutterBottom variant="h1" {...props} {...typographyPropsOverrides} {...h1Props} />
      ),
      h2: ({ ...props }) => <Typography gutterBottom variant="h2" {...props} {...typographyPropsOverrides} />,
      h3: ({ ...props }) => <Typography gutterBottom variant="h3" {...props} {...typographyPropsOverrides} />,
      h4: ({ ...props }) => <Typography gutterBottom variant="h4" {...props} {...typographyPropsOverrides} />,
      h5: ({ ...props }) => <Typography gutterBottom variant="h5" {...props} {...typographyPropsOverrides} />,
      p: ({ ...props }) => <Typography variant="body1" {...props} {...typographyPropsOverrides} {...pProps} />,
      a: ({ ...props }) => <Link target="_blank" {...props} {...aProps} />,
    }}
  >
    {children}
  </ReactMarkdown>
);

export default MarkdownToReact;
