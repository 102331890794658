import { type InviteModel, type RoleModel, UserNotification } from "@doitintl/cmp-models";
import { type FieldValueType, type ModelReference } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { type Entity } from "../../../Context/customer/EntitiesContext";
import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { type Customer, type Role } from "../../../types";
import { isDoitEmployeeEmail } from "../../../utils/email";
import { serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { getDefaultRoleRefByCustomer, getRoles, PresetRoles } from "../../../utils/permissions";
import { updateRoleInUse } from "../../UserView/db";
import { createUser, getBaseDailyDigestAttribution, getNotificationsPerRole, updateUserInvitation } from "../db";
import { baseDailyDigestsAttributionIds } from "./consts";
import { validateEmail } from "./validateEmail";

type handleInviteProps = {
  newEmails: string[];
  existingEmails?: string[];
  api: AxiosInstance;
  customer: Customer;
  requireBillingProfile?: boolean;
  currentUser: FirebaseUserWithEmail;
  selectedRoleId?: string;
  roleList?: Role[];
  entities: Entity[];
};

class Invites {
  static async handleInvite({
    newEmails,
    existingEmails,
    customer,
    requireBillingProfile = false,
    currentUser,
    selectedRoleId,
    roleList,
    entities,
    api,
  }: handleInviteProps): Promise<(string | undefined)[]> {
    const activeEntitiesRef = entities.filter((entity) => entity.active).map((entity) => entity.ref);
    const isOnlyOneBillingProfileActive = activeEntitiesRef.length === 1;

    // calculating actual role to set
    const selectedRoleRef = selectedRoleId ? getRoles(selectedRoleId) : null;
    const defaultRoleRef = getDefaultRoleRefByCustomer(customer);
    let actualRoleRef: ModelReference<RoleModel>;

    if (requireBillingProfile) {
      actualRoleRef = PresetRoles.ADMIN_REF;
    } else if (selectedRoleRef) {
      actualRoleRef = selectedRoleRef;
    } else {
      actualRoleRef = defaultRoleRef;
    }

    const newRole = roleList?.find((role) => role.id === actualRoleRef.id);
    const [, defaultNotifications] = await Promise.all([
      newRole && updateRoleInUse(null, { ...newRole, ref: actualRoleRef }),
      getNotificationsPerRole(actualRoleRef.id),
    ]);

    const dailyDigests = defaultNotifications?.includes(UserNotification.DailyDigest)
      ? getBaseDailyDigestAttribution(baseDailyDigestsAttributionIds, customer)
      : [];

    const newUsers: FieldValueType<Omit<InviteModel, "subCollections">>[] = newEmails
      .filter((email) => !isDoitEmployeeEmail(email))
      .map((email) => ({
        customer: {
          ref: customer.ref,
          name: customer.name,
          _name: customer._name,
        },
        timestamp: serverTimestamp(),
        invitationSentTimestamp: serverTimestamp(),
        email,
        entities: isOnlyOneBillingProfileActive ? activeEntitiesRef : [],
        enrichment: {},
        entityCreateTemplate: requireBillingProfile,
        sendEmail: true,
        invitedBy: { email: currentUser?.email, name: currentUser?.displayName },
        roles: [actualRoleRef],
        permissions: [],
        userNotifications: defaultNotifications,
        dailyDigests,
      }));

    newUsers.forEach(({ email }) => {
      mixpanel.track("users.add", {
        invitedEmail: email,
      });
    });

    return Promise.all([
      ...newUsers.map(async (newUser) => {
        await validateEmail(newUser.email as string, 0, { api, customerId: customer.id });
        return createUser(newUser);
      }),
      ...(existingEmails ?? []).map((email) =>
        updateUserInvitation(
          email,
          {
            invitationSentTimestamp: serverTimestamp(),
          },
          customer.id,
          defaultNotifications
        )
      ),
    ]);
  }
}

export default Invites;
