import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import { text } from "../../consts";

type Props = {
  isDeltaOptionChecked: boolean;
  setDeltaOptionChecked: (val: boolean) => void;
  disabled: boolean;
  updateDeltaNotif: (cb: boolean, val: number) => void;
  anomalyAbsoluteInput: number;
  onDeltaInputChange: (val: number) => void;
};

const DeltaOptions = (props: Props) => {
  const {
    disabled,
    setDeltaOptionChecked,
    isDeltaOptionChecked,
    updateDeltaNotif,
    anomalyAbsoluteInput,
    onDeltaInputChange,
  } = props;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection="row"
      alignItems="baseline"
      pt={1}
      width="100%"
      color={disabled ? "textSecondary" : "textPrimary"}
      data-cy="delta-notif"
      sx={{ fontSize: "inherit" }}
    >
      <Checkbox
        onClick={() => {
          setDeltaOptionChecked(!isDeltaOptionChecked);
          updateDeltaNotif(!isDeltaOptionChecked, anomalyAbsoluteInput);
        }}
        inputProps={{
          "aria-label": "anomalies checkbox",
        }}
        disabled={disabled}
        size="small"
        sx={{ alignSelf: "flex-start" }}
        checked={isDeltaOptionChecked}
        data-cy="delta-notif-cb"
      />
      <Box display="flex" justifyContent="flex-start" flexDirection="column" width="100%" sx={{ fontSize: "inherit" }}>
        <Typography color={disabled ? "textSecondary" : "textPrimary"} display="flex" sx={{ fontSize: "inherit" }}>
          Only send notifications with cost above
          <Tooltip title={text.ANOMALY_DELTA_NOTIFICATION_DESC} placement="bottom" sx={{ ml: 1 }}>
            <InfoOutlinedIcon fontSize="small" />
          </Tooltip>
        </Typography>
        <TextField
          variant="outlined"
          label="Anomaly threshold"
          value={anomalyAbsoluteInput}
          onChange={(e) => {
            onDeltaInputChange(Number(e.target.value.replace(/\D/g, "")));
          }}
          size="small"
          margin="dense"
          disabled={disabled}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          inputProps={{
            sx: { fontSize: "inherit" },
          }}
          data-cy="anomaly-delta-input"
        />
      </Box>
    </Box>
  );
};
export default DeltaOptions;
