import { type Contract, type Customer } from "../../../../../types";
import { type FirestoreTimestamp, serverTimestamp } from "../../../../../utils/firebase";
import { type AttributionGroupWithRef } from "../../../../CloudAnalytics/attributionGroups/types";
import { getInitPeriodsDataFromContract } from "../../../RampPlan/PeriodTables/Tables/getInitPeriodsDataFromContract";
import { type RampPlanData } from "../../../types";

export const createPlanFromContract = (
  contract: Contract,
  planName: string,
  customer: Customer,
  currentUserEmail: string,
  attributionGroup: AttributionGroupWithRef
): RampPlanData | undefined => {
  if (!contract.startDate || !contract.endDate || !contract.commitmentPeriods || !contract.entity) {
    return;
  }

  return {
    name: planName,
    customerRef: contract.customer || customer.ref,
    contractEntityRef: contract.entity,
    contractId: contract.id,
    createdBy: currentUserEmail || "",
    creationDate: serverTimestamp() as FirestoreTimestamp,
    startDate: contract.startDate,
    estEndDate: contract.endDate,
    origEstEndDate: contract.endDate,
    commitmentPeriods: getInitPeriodsDataFromContract(contract),
    origCommitmentPeriods: getInitPeriodsDataFromContract(contract),
    targetAmount: contract.commitmentPeriods.reduce((sum, x) => sum + x.value, 0),
    active: true,
    onTrack: false,
    platform: contract.type,
    attributionGroupRef: attributionGroup.ref,
  };
};
