import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { threeDotHeaderSection } from "../../../assetUtils";
import { type SnowflakeAssetRowData } from "./SnowflakeAssetRow";

const snowflakeItemDescriptors: FilterTableItemDescriptor<SnowflakeAssetRowData>[] = [
  {
    headerText: "Organization",
    value: "orgName",
    filterType: "string",
  },
  {
    headerText: "Account",
    value: "accountId",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterType: "string",
    hidden: { mdDown: true },
  },
];

const { filters, headerColumns: baseHeaderColumns } = generateHeadersAndFilters(snowflakeItemDescriptors);
const headerColumns = [...baseHeaderColumns, threeDotHeaderSection] as const;

export { filters, headerColumns };
