import { useHistory } from "react-router-dom";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { globalText } from "../../../../../assets/texts";
import { CopyToClipboardButton } from "../../../../../Components/CopyToClipboardButton";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useNotesContext } from "../../../../../Context/customer/NotesContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { ThemeModes } from "../../../../../muiThemeTypes";
import { doitCustomerId } from "../../../../../utils/customers";
import { isPartnerDomain } from "../../../../../utils/domains";
import { useThemeContext } from "../../../../../withRoot";
import { customersRoute } from "../../../../core/utils";
import { type HeaderDropdownMenuItem } from "../../../../types";
import { CustomerInfo } from "../CustomerInfo/CustomerInfo";
import { CustomerNotesRow } from "./CustomerNotesRow";
import { FollowCustomerButton } from "./FollowCustomerButton";
import { OpenFolderRow } from "./OpenFolderRow";
import { openInNewTabDrive } from "./utils";

export const useMenuItems = ({ onSignOut }: { onSignOut: () => void }) => {
  const history = useHistory();
  const theme = useTheme();

  const { onToggleTheme } = useThemeContext();
  const { customer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const notesContextValue = useNotesContext();
  const customerId = customer?.id;
  const primaryDomain = customer?.primaryDomain;
  const menuItems: HeaderDropdownMenuItem[] = [];

  if (customerId && isDoitEmployee && customer?.id !== doitCustomerId) {
    menuItems.push(
      {
        type: "divider",
      },
      {
        component: (
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" height="30px">
            <Typography color="textPrimary" variant="subtitle2">
              {primaryDomain}
            </Typography>
            <Box display="flex">
              <FollowCustomerButton />
              <Box sx={{ mr: -1, ml: 1 }}>
                <CopyToClipboardButton text={primaryDomain ?? ""} fontSize="20px" />
              </Box>
            </Box>
          </Stack>
        ),
        handler: () => {
          history.push(customersRoute(customerId));
        },
      }
    );

    if (customer?.sharedDriveFolderId) {
      menuItems.push({
        component: <OpenFolderRow />,
        handler: () => {
          openInNewTabDrive(customer?.sharedDriveFolderId);
        },
      });
    }

    menuItems.push({
      component: <CustomerNotesRow />,
      handler: notesContextValue.toggle,
    });
  }

  if (customer && customer?.id !== doitCustomerId) {
    menuItems.push(
      { type: "divider" },
      {
        component: (
          <Stack>
            <Typography variant="body2" component="div">
              <CustomerInfo saasEnabled={customer.enabledFlexsave} />
            </Typography>
          </Stack>
        ),
      }
    );
  }

  if (!isPartnerDomain()) {
    menuItems.push(
      {
        type: "divider",
      },
      {
        remainOpen: true,
        handler: onToggleTheme,
        component: (
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" py={0.4}>
            <Typography sx={{ color: "navigation.subItemColor" }} variant="body2">
              Dark mode
            </Typography>
            <Box pl={1} display="flex" alignItems="center">
              <Switch color="primary" checked={theme.palette.mode === ThemeModes.DARK} size="small" />
            </Box>
          </Box>
        ),
      }
    );
  }

  menuItems.push({
    component: (
      <Box py={0.4}>
        <Typography variant="body2" color="primary">
          {globalText.LOGOUT}
        </Typography>
      </Box>
    ),
    handler: onSignOut,
  });

  return menuItems;
};
