type AnyFunction = (...args: any[]) => any;

type AnalyticsFunctions = {
  handleNewReport: AnyFunction;
  handleNewMetric: AnyFunction;
  handleNewAttribution: AnyFunction;
  handleNewBudget: AnyFunction;
  setTitle: AnyFunction;
};

type AuthFunctions = {
  signOut: AnyFunction;
  signInWithEmailAndPassword: AnyFunction;
};

export function windowInit() {
  if (!globalThis.CMP) {
    globalThis.CMP = { Analytics: {}, Auth: {} };
  }
}

export function addAnalyticsWindowFunction(objectFunc: Partial<AnalyticsFunctions>) {
  if (globalThis.CMP) {
    globalThis.CMP.Analytics = Object.assign(globalThis.CMP.Analytics ?? {}, objectFunc);
  }
}

export function addAuthWindowFunction(objectFunc: Partial<AuthFunctions>) {
  if (globalThis.CMP) {
    globalThis.CMP.Auth = Object.assign(globalThis.CMP.Auth ?? {}, objectFunc);
  }
}
