import { useCallback, useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { SyncLoader } from "react-spinners";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import OpenInNew from "@mui/icons-material/OpenInNew";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useAsyncCurrency } from "../../../Components/hooks/useCurrency";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { formatDecimalNumber } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import { handleBackClick } from "../../../utils/navigation";
import { useInsights } from "../api";
import { useInsightsContext } from "../context";
import { useInsightsInvestigation, useMdxContent } from "../hooks";
import { NotFound } from "../NotFound/NotFound";
import { Preview } from "../Preview/Preview";
import { type BreakdownData } from "../types";
import { DetailedBreakdown } from "./DetailedBreakdown";
import { GetStarted } from "./GetStarted";

const Loader = ({ color }: { color?: string }) => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
    <SyncLoader size={10} color={color} loading />
  </Box>
);

type Parameters = {
  providerId: string;
  insightKey: string;
};

export function InsightsDetails() {
  const errSnackbar = useErrorSnackbar(10);
  const { customer } = useCustomerContext();
  const { savingsPeriod, calculateSavingsForSelectedPeriod } = useInsightsContext();
  const { providerId, insightKey } = useParams<Parameters>();
  const theme = useTheme();
  const history = useHistory();

  const mixpanelInsight = `${insightKey}#${providerId}`;

  useEffect(() => {
    mixpanel.track("insights.details.open", {
      insight: mixpanelInsight,
    });
  }, [mixpanelInsight]);

  // Fetch all insights for now - chances are we already have them cached, and we don't have that many
  const { insights, isFetching, error } = useInsights();

  useEffect(() => {
    if (!error) {
      return;
    }
    errSnackbar(error.message || "Failed to load insights");
  }, [error, errSnackbar]);

  // Then find the one matching the parameters we got from the url
  const matchingInsight = (insights || []).find(
    (insight) => insight.providerId === providerId && insight.key === insightKey
  );
  const getInvestigationReportUrl = useInsightsInvestigation({ insightName: matchingInsight?.title });

  const handleOpenReport = useCallback(async () => {
    const reportUrl = await getInvestigationReportUrl({ config: matchingInsight?.results?.reportConfig });
    window.open(reportUrl, "_blank");
  }, [getInvestigationReportUrl, matchingInsight?.results?.reportConfig]);

  const insightDescription = useMdxContent(matchingInsight?.detailedDescriptionMdx ?? "");

  const { asyncConvertCurrency, customerCurrencySymbol } = useAsyncCurrency();

  if (isFetching) {
    return <Loader color={theme.palette.primary.main} />;
  }

  if (!matchingInsight) {
    return <NotFound />;
  }

  let newSupportTicketUrl = `/customers/${customer.id}/support/new`;

  // To pre-fill the support template, we need to have exactly one cloud tag, and a category on the insight
  if (matchingInsight.cloudTags.length === 1 && matchingInsight.supportCategory) {
    const platform = matchingInsight.cloudTags.includes("gcp") ? "google_cloud_platform" : "amazon_web_services";

    // Step 1: Platform + Category (Product)
    newSupportTicketUrl += `/insights-support?platform=${platform}&category=${matchingInsight.supportCategory}`;

    // Step 2: Severity (from the Firestore template)
    // Step 3: Subject, Project ID + Message body parameters (body from the Firestore template)
    newSupportTicketUrl += `&subject=${encodeURIComponent(`Support for applying "${matchingInsight.title}" insight`)}`;

    let breakdownData: BreakdownData[] | null = null;

    // Add options here for other result types
    if (matchingInsight.results?.potentialDailySavings?.breakdown?.data?.length) {
      breakdownData = matchingInsight.results.potentialDailySavings.breakdown.data;
    } else if (matchingInsight.results?.customProblems?.breakdown?.data?.length) {
      breakdownData = matchingInsight.results.customProblems.breakdown.data;
    }

    if (breakdownData) {
      const sortedData = [...breakdownData];
      sortedData.sort((a, b) => b.value - a.value);

      // The first dimension is GCP project/AWS account
      newSupportTicketUrl += `&resource=${sortedData[0].dimensionValues[0]}`;
    }

    newSupportTicketUrl += `&insight_url=${encodeURIComponent(window.location.href)}`;
  }

  return (
    <Container maxWidth="lg">
      {/* Space to top should be 6, but 1 is already added to the main page area, and 2 is added by the CardHeader for the back arrow */}
      <Box mt={3} mb={4}>
        {/* Do not set `spacing` instead here - this will add a negative margin-top, which will collapse with the margin above */}
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ border: "none" }}>
              <CardHeader
                sx={{
                  ".MuiCardHeader-avatar": {
                    mt: -1,
                    alignSelf: "flex-start",
                  },
                }}
                title={
                  <Preview
                    key={`${matchingInsight.providerId}#${matchingInsight.key}`}
                    insight={matchingInsight}
                    isListView={false}
                  />
                }
                avatar={
                  <IconButton
                    aria-label="Back"
                    onClick={handleBackClick(history, `/customers/${customer.id}/insights`)}
                    size="large"
                  >
                    <BackIcon sx={{ color: "text.primary" }} />
                  </IconButton>
                }
              />

              <Box sx={{ pl: 10 }}>
                <Divider />
                {matchingInsight.isInternal ? (
                  <Alert severity="info" variant="filled" sx={{ mt: 2, backgroundColor: theme.palette.primary.main }}>
                    <AlertTitle>Internal insight</AlertTitle>
                    This insight is only visible to DoiT employees and should not be shared with customers (yet).
                  </Alert>
                ) : null}
              </Box>

              {insightDescription && (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography variant="h4" mb={2} mt={4.5}>
                    Description
                  </Typography>

                  {insightDescription}
                </CardContent>
              )}

              {matchingInsight.results?.potentialDailySavings?.breakdown ? (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography variant="h4" mt={3}>
                    Estimated potential {savingsPeriod} savings
                  </Typography>
                  {/* Skip any entries less than a cent */}
                  <DetailedBreakdown
                    breakdown={matchingInsight.results?.potentialDailySavings.breakdown}
                    dataFilterFunction={(row) => row.value >= 0.01}
                    allowCondensedBreakdown={false}
                    unit="Savings"
                    valueFormatter={async (value) => {
                      const valueConverted = await asyncConvertCurrency(calculateSavingsForSelectedPeriod(value));

                      return `${customerCurrencySymbol}${formatDecimalNumber(valueConverted, 2)}`;
                    }}
                  />
                </CardContent>
              ) : null}

              {matchingInsight.results?.customProblems?.breakdown ? (
                <CardContent sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography variant="h4" mt={3}>
                    {matchingInsight.results.customProblems.unitLongPlural}
                  </Typography>
                  <DetailedBreakdown
                    breakdown={matchingInsight.results.customProblems.breakdown}
                    dataFilterFunction={(row) => row.value > 0}
                    allowCondensedBreakdown={true}
                    unit={matchingInsight.results.customProblems.unitShortPlural}
                    valueFormatter={(value) => formatDecimalNumber(value, 0)}
                  />
                </CardContent>
              ) : null}
              {matchingInsight?.results?.reportConfig && (
                <Box sx={{ pl: 10, py: 0, mb: 2 }}>
                  <Typography variant="h4" mt={3}>
                    Further investigation
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2, mt: 2 }}>
                    For an in-depth analysis of the data behind these recommendations, you can check the report
                    generated by DoiT Cloud Intelligence™️.
                  </Typography>
                  <Button variant="outlined" endIcon={<OpenInNew />} onClick={() => handleOpenReport()}>
                    Open report
                  </Button>
                </Box>
              )}
            </Card>
          </Grid>
          {/* The CardHeader adds 2 spacing which we need to add also here if we have two columns */}
          <Grid item xs={12} md={4} mt={{ md: 2 }}>
            <GetStarted
              insight={matchingInsight}
              newSupportTicketUrl={newSupportTicketUrl}
              mixpanelInsight={mixpanelInsight}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
