import { useCallback, useEffect, useMemo, useState } from "react";

import { type EntitlementModel, TierPackageTypes } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";

import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { preventOnCloseOnBackdropClick } from "../../../utils/dialog";
import { defaultEntitlementFormData } from "../consts";
import { createEntitlement, updateEntitlement } from "../db";
import { type EntitlementWithId } from "../types";
import { getLimit, validateEntitlementForm, validateEntitlementkey } from "../utils";

type Props = {
  handleClose: () => void;
  selectedEntitlement?: EntitlementWithId | null;
  open: boolean;
};

export const CreateEntitlementsDialog = ({ handleClose, open, selectedEntitlement }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<EntitlementModel>(defaultEntitlementFormData);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidEntitlementKey, setInvalidEntitlementKey] = useState(false);
  const errorSnackbar = useErrorSnackbar();

  const initialFormData = useMemo(() => {
    if (selectedEntitlement) {
      const { id: _id, ...rest } = selectedEntitlement;
      return rest;
    }
    return defaultEntitlementFormData;
  }, [selectedEntitlement]);

  useEffect(() => {
    setFormData((prevState) => ({ ...prevState, ...initialFormData }));
  }, [initialFormData]);

  useEffect(() => {
    setIsFormValid(validateEntitlementForm(formData));
  }, [formData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "key") {
      setInvalidEntitlementKey(false);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "key") {
      setInvalidEntitlementKey(!validateEntitlementkey(value.trim()));
    }
  };

  const handleSave = useCallback(async () => {
    setLoading(true);
    const { limit, ...rest } = formData;
    const limitValue = getLimit(limit ? String(limit) : undefined);
    const formDataToSave = limitValue || selectedEntitlement?.limit ? { ...formData, limit: limitValue } : rest;
    try {
      if (selectedEntitlement?.id) {
        await updateEntitlement(formDataToSave, selectedEntitlement?.id);
      } else {
        await createEntitlement(formDataToSave);
      }
    } catch (e) {
      errorSnackbar("Failed to save entitlement details");
    }
    setLoading(false);
    handleClose();
  }, [formData, selectedEntitlement, handleClose, errorSnackbar]);

  const handleCancel = () => {
    setFormData(defaultEntitlementFormData);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={preventOnCloseOnBackdropClick(handleClose)} fullWidth>
      <DialogTitle>{selectedEntitlement ? "Edit entitlement" : "Create new entitlement"} </DialogTitle>
      <DialogContent>
        <TextField
          name="displayName"
          label="Name"
          value={formData.displayName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="description"
          label="Description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="type"
          label="Type"
          value={formData.type}
          onChange={handleChange}
          fullWidth
          margin="normal"
          select
        >
          {Object.values(TierPackageTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          name="key"
          label="Key"
          value={formData.key}
          onChange={handleChange}
          onBlur={handleBlur}
          error={invalidEntitlementKey}
          fullWidth
          margin="normal"
          required
          disabled={!!selectedEntitlement}
        />
        <TextField
          name="limit"
          label="Limit(s) (separate multiple limits with commas)"
          value={formData.limit}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSave}
          loading={loading}
          disabled={!isFormValid || invalidEntitlementKey}
          mixpanelEventId="tiers.entitlement-details.save"
        >
          {selectedEntitlement ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
