import { type FC, useCallback, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";

import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useTeamsBot } from "./hooks";

export const cyIds = {
  alert: "team-connection-alert",
  confirm: "team-connection-confirm-button",
};

export type TeamConnectionAlertProps = {
  payload: string;
};

/**
 * Alert to prompt the customer to confirm the connection of their Microsoft Teams team.
 * This is shown when the user navigates to the Teams page with a `payload` param in the URL
 * and constitutes step two of three in the Teams connection flow.
 */
export const TeamConnectionAlert: FC<TeamConnectionAlertProps> = ({ payload }) => {
  const history = useHistory();
  const { isDoitEmployee } = useAuthContext();

  const { completeTeamSetup } = useTeamsBot();
  const errorSnackbar = useErrorSnackbar();
  const [teamSetupLoading, setTeamSetupLoading] = useState<boolean>(false);

  const teamName = useMemo<string>(() => {
    const payloadData = JSON.parse(atob(payload.split(".")[1]));
    return payloadData.teamName;
  }, [payload]);

  const handleConfirm = useCallback<() => Promise<void>>(async () => {
    setTeamSetupLoading(true);
    const errorMessage = await completeTeamSetup(payload);
    setTeamSetupLoading(false);
    if (errorMessage) {
      errorSnackbar(errorMessage);
      return;
    }

    // navigate to the same url as current but without any params,
    // as if they are not stripped out then the page reloads with this alert still in place.
    history.push(history.location.pathname);
  }, [completeTeamSetup, errorSnackbar, history, payload]);

  const confirmButton = (
    <LoadingButton
      loading={teamSetupLoading}
      mixpanelEventId="integrations.teams.team-setup"
      variant="text"
      color="inherit"
      size="small"
      onClick={handleConfirm}
      disabled={isDoitEmployee}
      data-cy={cyIds.confirm}
    >
      Confirm
    </LoadingButton>
  );

  return (
    <Alert severity="info" action={confirmButton} data-cy={cyIds.alert}>
      {`Confirm Microsoft Teams connection to ${teamName}`}
    </Alert>
  );
};
