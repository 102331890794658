import { type PageId } from "../../config/pages";
import { type CategoryContext } from "../../types";
import { type SideNavigationData } from "./SideNavigationLayout";

export function thirdLevelItemsFromCategory(pageId: PageId, categoryContext: CategoryContext): SideNavigationData {
  return {
    value: pageId,
    items: categoryContext.items.map((item) => ({
      key: item.pageId,
      value: item.pageId,
      label: item.displayName,
      to: item.fullRoute,
    })),
  };
}
