import { Tooltip, Typography } from "@mui/material";

export const AccountMangersWrapper = ({ accountManagers }: { accountManagers: string[] }) => (
  <>
    {accountManagers.length ? accountManagers[0] : ""}
    <Tooltip placement="right" title={accountManagers.join(", ")} arrow followCursor>
      <Typography component="div" variant="subtitle2">
        {accountManagers && accountManagers.length > 1 && `+${accountManagers.length - 1} more`}
      </Typography>
    </Tooltip>
  </>
);

export default AccountMangersWrapper;
