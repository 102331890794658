import { DateTime } from "luxon";

import { assetTypeName } from "../../../utils/common";
import { type RampListItemData, type RampPlanModel } from "../types";

export const getTotalActuals = (itemData: Pick<RampPlanModel, "commitmentPeriods">): number => {
  if (!itemData.commitmentPeriods) {
    return 0;
  }
  const totalActualsArr: number[] = [0];
  itemData.commitmentPeriods.forEach((period) => {
    if (period.actuals?.length) {
      totalActualsArr.push(period.actuals.reduce((sum, x) => sum + x));
    } else {
      totalActualsArr.push(0);
    }
  });
  return totalActualsArr.reduce((sum, x) => sum + x);
};

export const sumPlannedUpToLastMonth = (itemData: Pick<RampPlanModel, "startDate" | "commitmentPeriods">) => {
  let total = 0;
  const startTime = DateTime.fromSeconds(itemData.startDate?.seconds);
  itemData.commitmentPeriods?.forEach((period, periodIndex) => {
    const periodPlanTotal = period?.planned.reduce((acc: number, val, index) => {
      const monthOutOfTotalNum = index + periodIndex * period.planned.length;
      const monthStartDate = startTime.plus({ months: monthOutOfTotalNum }).startOf("month");
      const priorMonthEnd = DateTime.now().minus({ months: 1 }).endOf("month");

      return monthStartDate < priorMonthEnd ? acc + (val ?? 0) : acc;
    }, 0);
    total += periodPlanTotal;
  });
  return total;
};

export const item = (itemData: RampPlanModel, customerId: string, isCustomerPath: boolean): RampListItemData => {
  const totalActuals = getTotalActuals(itemData);
  return {
    ...itemData,
    totalActuals: getTotalActuals(itemData),
    onTrack: totalActuals >= sumPlannedUpToLastMonth(itemData),

    starts: DateTime.fromSeconds(itemData.startDate.seconds).toUTC().toFormat("d MMM yyyy"),
    ends: DateTime.fromSeconds(itemData.origEstEndDate.seconds).toUTC().toFormat("d MMM yyyy"),
    platform: assetTypeName(itemData.platform),
    hideCustomerCol: { smDown: isCustomerPath, smUp: isCustomerPath },
    customerUrl: `/customers/${customerId}`,
    planUrl: `/customers/${customerId}/contracts/ramps/${itemData.id}`,
    status: itemData.active ? "Active" : "Inactive",
    monthDiff: Math.ceil(
      DateTime.fromSeconds(itemData.origEstEndDate?.seconds)
        .diff(DateTime.fromSeconds(itemData.startDate?.seconds), "months")
        .toObject().months as number
    ),
  };
};
