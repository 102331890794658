import { Link } from "@mui/material";

export const ContactSupportButton = ({ text = "Contact support" }: { text?: string }) => (
  <Link
    underline="hover"
    target="_blank"
    sx={{
      cursor: "pointer",
    }}
    onClick={() => window.open(`/support/new`, "_blank")}
  >
    {text}
  </Link>
);
