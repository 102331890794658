import { globalText, marketplaceText } from "../../../../assets/texts";
import { type HeaderColumn } from "../../../../types/FilterTable";

export const headerColumns: HeaderColumn[] = [
  {
    value: "@",
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.CUSTOMER,
    value: "customer.data.primaryDomain",
    tooltip: marketplaceText.CUSTOMER,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.ENTITLEMENT_ID,
    value: "ref.id",
    tooltip: marketplaceText.ENTITLEMENT_ID,
    style: { align: "left", padding: "normal" },
    hiddenByDefault: true,
  },
  {
    label: marketplaceText.BILLING_ACCOUNT_TYPE,
    value: "billingAccountType",
    tooltip: marketplaceText.BILLING_ACCOUNT_TYPE,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.PRODUCT,
    value: "transform.product",
    tooltip: marketplaceText.PRODUCT,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.CREATE_TIME,
    value: "data.procurementEntitlement.createTime",
    tooltip: marketplaceText.CREATE_TIME,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.LAST_UPDATE,
    value: "data.procurementEntitlement.updateTime",
    tooltip: marketplaceText.LAST_UPDATE,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.STATE,
    value: "transform.state",
    tooltip: marketplaceText.STATE,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "@",
    style: { align: "left", padding: "normal" },
  },
];

export const filterColumns = [
  {
    label: marketplaceText.CUSTOMER,
    path: "customer.data.primaryDomain",
    comparators: ["==", "!="],
    toOption: (value) => ({
      value,
      label: value ?? globalText.NA,
    }),
  },
  {
    label: marketplaceText.ENTITLEMENT_ID,
    path: "ref.id",
    requireExactMatch: true,
  },
  {
    label: marketplaceText.BILLING_ACCOUNT_TYPE,
    path: "billingAccountType",
  },
  {
    label: marketplaceText.PRODUCT,
    path: "transform.product",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.STATE,
    path: "transform.state",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.ACCOUNT_ID,
    path: "data.procurementEntitlement.account",
  },
] as const;
