import { useMemo } from "react";

import { type CloudConnectModelGoogleCloudStandalone, CustomerModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";

export const useStandaloneAccounts = () => {
  const customerId = useCustomerId();
  const ref = useMemo(
    () =>
      getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("type", "==", "google-cloud-standalone")
        .narrow<CloudConnectModelGoogleCloudStandalone>(),
    [customerId]
  );

  return useCollectionData(ref);
};
