import { type JSX, useMemo } from "react";

import { type ContractModel, type GSuiteAssetModel } from "@doitintl/cmp-models";
import { Box, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import values from "lodash/values";

import DataCouplet from "../../../Components/DataCouplet";
import { type Entity } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Asset } from "../../../types";
import { formatNumber, humanBoolean } from "../../../utils/common";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";
import { contractDate } from "../utils";
import { getCommitmentTypeText } from "./utils";

export type ContractPageGSuiteProps = { contract: ContractModel; accountManagerName?: string; contractId: string };

export const cyIds = {
  text: {
    title: "title",
  },
  sections: {
    general: "general-section",
    details: "details-section",
    commitment: "commitment-section",
  },
};

const ContractPageGsuite = ({ contract, accountManagerName, contractId }: ContractPageGSuiteProps) => {
  const { entities, entitiesLoading, assets, assetsLoading } = useCustomerContext();

  const entity = useMemo<Entity | undefined>(
    () => entities.find((ent) => ent.id === contract.entity?.id),
    [contract.entity?.id, entities]
  );

  const subscriptions = useMemo<string | JSX.Element>(() => {
    const isGsuite = (asset: Asset<any>): asset is Asset<GSuiteAssetModel> => asset.data.type === "g-suite";

    const subscriptions = values(assets)
      .flatMap((assetList) => assetList)
      .filter((asset) => isGsuite(asset))
      .filter((asset) => asset.data.entity?.id === entity?.id && asset.data.contract?.id === contractId)
      .map((asset) => asset.data.properties.subscription)
      .filter((subscriptions) => !!subscriptions)
      .flat();

    if (!subscriptions.length) {
      return NotAvailable;
    }

    return (
      <List>
        {subscriptions.map((subscription) => (
          <ListItem key={subscription.skuId}>
            <ListItemText>{subscription.skuName}</ListItemText>
          </ListItem>
        ))}
      </List>
    );
  }, [assets, contractId, entity?.id]);

  return (
    <Stack spacing={3}>
      <Typography variant="h1" data-cy={cyIds.text.title}>
        Google Workspace
      </Typography>
      <Box component="section" data-cy={cyIds.sections.general}>
        <Typography variant="h2">General</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet
            key="billing-profile"
            field="Billing profile"
            value={entity ? `${entity.priorityId} - ${entity.name}` : "Unassigned"}
            loading={entitiesLoading}
          />
          <DataCouplet key="account-manager" field="Account manager" value={accountManagerName ?? NotAvailable} />
          <DataCouplet
            key="annualized-value"
            field="Annualized value"
            value={`$${formatNumber(contract.estimatedValue, 0)}`}
            infoTooltip="The estimated yearly contract value in USD"
          />
        </Stack>
      </Box>
      <Box component="section" data-cy={cyIds.sections.details}>
        <Typography variant="h2">Contract Details</Typography>
        <Stack component="dl" spacing={2}>
          <DataCouplet key="commitment-deal" field="Commitment deal" value={getCommitmentTypeText(contract)} />
          <DataCouplet field="Renewal contract" value={humanBoolean(contract.isRenewal)} key="renewal-contract" />
          <DataCouplet field="Start date" value={contractDate(contract.startDate)} key="contract-start-date" />
          <DataCouplet field="End date" value={contractDate(contract?.endDate)} key="contract-end-date" />
          <DataCouplet
            field="Discount"
            value={contract.discount ? `${contract.discount}%` : NotAvailable}
            key="contract-discount"
          />
          <DataCouplet
            field="Subscriptions"
            value={subscriptions}
            key="contract-subscriptions"
            loading={assetsLoading || entitiesLoading}
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default ContractPageGsuite;
