import { useCallback, useState } from "react";

import { DialogContent, TextField, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { templateLibraryTxt } from "../../../assets/texts/CloudAnalytics";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import { consoleErrorWithSentry } from "../../../utils";
import { useReportTemplateContext } from "../Context";
import { rejectReportTemplate } from "./api";

type Props = {
  open: boolean;
  onClose: () => void;
};

const RejectDialog = ({ open, onClose }: Props) => {
  const api = useApiContext();
  const { onOpen: showSharedSnackbar } = useSnackbar();
  const { reportTemplateId, dispatchReportTemplates } = useReportTemplateContext();
  const [reasonsText, setReasonsText] = useState("");

  const onCancel = useCallback(() => {
    setReasonsText("");
    onClose();
  }, [onClose]);

  const onConfirm = useCallback(async () => {
    try {
      const response = await rejectReportTemplate(api, reportTemplateId, {
        comment: reasonsText,
      });
      dispatchReportTemplates({ type: "UPDATE_REPORT_TEMPLATE", payload: { reportTemplateItem: response.data } });
      showSharedSnackbar({
        message: templateLibraryTxt.TEMPLATE_REJECTED_SUCCESSFULLY,
        variant: "success",
      });
      onCancel();
    } catch (error) {
      showSharedSnackbar({
        message: templateLibraryTxt.TEMPLATE_REJECT_ERROR,
        variant: "error",
      });
      consoleErrorWithSentry(error);
    }
  }, [api, dispatchReportTemplates, onCancel, reasonsText, reportTemplateId, showSharedSnackbar]);

  return (
    <SimpleDialog
      open={open}
      title={templateLibraryTxt.REJECT_TEMPLATE}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText={templateLibraryTxt.REJECT}
      disableConfirm={!reasonsText}
      dialogProps={{ fullWidth: true }}
      titleCy="reject-dialog-title"
      confirmButtonCy="reject-dialog-confirm-button"
    >
      <DialogContent data-cy="reject-dialog-content">
        <Typography variant="body2" color="textSecondary">
          {templateLibraryTxt.REJECT_REASON}
        </Typography>
        <TextField
          sx={{ mt: 3 }}
          multiline
          required
          autoFocus
          fullWidth
          rows={6}
          value={reasonsText}
          onChange={(e) => {
            setReasonsText(e.target.value);
          }}
          label={templateLibraryTxt.REASON_FIELD_LABEL}
          inputProps={{ maxLength: 1000 }}
        />
      </DialogContent>
    </SimpleDialog>
  );
};

export default RejectDialog;
