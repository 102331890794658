import { type CustomerSavingsPlan } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import TableCell from "@mui/material/TableCell";
import { DateTime } from "luxon";

import { formatDecimalNumber } from "../../../../../../utils/common";

type Props = {
  row: WithFirebaseModel<CustomerSavingsPlan>;
};

export const CustomerSavingsPlansRow = ({ row }: Props) => (
  <>
    <TableCell component="th" scope="row">
      {row.savingsPlanID}
    </TableCell>
    <TableCell>{row.paymentOption}</TableCell>
    <TableCell>{row.type.replace(/SavingsPlans/g, "")}</TableCell>
    <TableCell>{row.term}</TableCell>
    <TableCell>${Number(row.commitment.toFixed(5))} per hour</TableCell>
    <TableCell>${formatDecimalNumber(row.mtdSavings, 2)}</TableCell>
    <TableCell>{DateTime.fromJSDate(row.expirationDate.toDate()).toLocaleString(DateTime.DATE_MED)}</TableCell>
  </>
);
