import TableCell from "@mui/material/TableCell";

import { getFormattedCoverage } from "../../Operations/GCP/utils/common";
import { formatAsDollars } from "../../utils/costUtils";
import { WarningCell } from "./WarningCell";

type Props = {
  fieldValue?: number;
  hasRisk: boolean;
  riskType: "coverage" | "cost";
};

export const RiskTableCell = ({ fieldValue, hasRisk, riskType }: Props) => {
  if (fieldValue === undefined || (riskType === "cost" && fieldValue === 0)) {
    return <TableCell> - </TableCell>;
  }
  const formattedFieldValue = riskType === "coverage" ? getFormattedCoverage(fieldValue) : formatAsDollars(fieldValue);
  return <>{hasRisk ? <WarningCell text={formattedFieldValue} /> : <TableCell>{formattedFieldValue}</TableCell>}</>;
};
