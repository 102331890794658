import { useEffect, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Customer } from "../types";

export function isCustomerInPresentationMode(
  customer: Customer
): customer is Customer & { presentationMode: { enabled: boolean; customerId: string } } {
  return customer.presentationMode?.enabled === true && customer.presentationMode.customerId !== undefined;
}

export function useCustomerOrPresentationModeCustomer(customer: Customer | null | undefined) {
  const [customerOrPresentationModeCustomer, setCustomerOrPresentationModeCustomer] = useState(customer);

  useEffect(() => {
    if (customer === undefined || customer === null) {
      setCustomerOrPresentationModeCustomer(customer);
      return;
    }
    if (isCustomerInPresentationMode(customer)) {
      getCollection(CustomerModel)
        .doc(customer.presentationMode.customerId)
        .onSnapshot((presentationModeCustomerSnap) => {
          const presentationModeCustomerData = presentationModeCustomerSnap.asModelData();
          setCustomerOrPresentationModeCustomer(
            presentationModeCustomerData
              ? {
                  ...presentationModeCustomerData,
                  id: presentationModeCustomerSnap.id,
                  ref: presentationModeCustomerSnap.modelRef,
                }
              : customer
          );
        });
    } else {
      setCustomerOrPresentationModeCustomer(customer);
    }
  }, [customer]);

  return customerOrPresentationModeCustomer;
}
