import { useCallback, useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { TableCell, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useAuthContext } from "../../../Context/AuthContext";
import Hide from "../../HideChildren/Hide";
import useRouteMatchURL from "../../hooks/useRouteMatchURL";
import { type AWSPricebookRowProps } from "../Types";
import AWSPricebook3DotMenu from "./AWSPricebook3DotMenu";

export const AWSPricebookRow = ({ row, onCopy, onDelete }: AWSPricebookRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const { isDoitOwner } = useAuthContext();
  const assignedToCustomers = useMemo<string>(
    () => row.assignments?.map((acc) => acc?.customerName).join(", ") || "",
    [row]
  );
  const disableMenu = !isDoitOwner;
  const onDeletion = useCallback(() => {
    onDelete(row.id);
  }, [onDelete, row.id]);
  const onDuplicate = useCallback(() => {
    onCopy(row);
  }, [onCopy, row]);

  return (
    <>
      <TableCell style={{ textDecoration: "underline" }}>
        <Typography component={RouterLink} to={`${routeMatchURL}/${row.ref.id}`} color="inherit" variant="body2">
          {row.bookName}
        </Typography>
      </TableCell>
      <Hide mdDown>
        <TableCell>{DateTime.fromISO(row.updatedAt).toLocaleString(DateTime.DATE_MED)}</TableCell>
        <TableCell>{assignedToCustomers}</TableCell>
      </Hide>
      <TableCell align="center">
        {!disableMenu && <AWSPricebook3DotMenu onCopy={onDuplicate} onDelete={onDeletion} />}
      </TableCell>
    </>
  );
};
