import UserAddIcon from "@mui/icons-material/PersonAddRounded";
import { Button, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ToolTipWrapper } from "../../Components/TooltipWrapper";
import { useFullScreen } from "../../utils/dialog";
import { text } from "../UserView/consts";

const useStyles = makeStyles(() => ({
  floatingButton: { position: "fixed", right: "5%", bottom: "10%", zIndex: 9999 },
}));

type Props = { onClick: () => Promise<void> | void; disabled?: boolean; toolTipText?: string };

export const InviteUserButton = ({ onClick, disabled, toolTipText }: Props) => {
  const { isMobile } = useFullScreen();
  const classes = useStyles();

  return (
    <ToolTipWrapper title={toolTipText}>
      {isMobile ? (
        <Fab
          className={classes.floatingButton}
          disabled={disabled}
          color="primary"
          aria-label="create user"
          onClick={onClick}
        >
          <UserAddIcon />
        </Fab>
      ) : (
        <Button
          variant="contained"
          color="primary"
          aria-label="create user"
          onClick={onClick}
          startIcon={<UserAddIcon />}
          disabled={disabled}
        >
          {text.INVITE_USER_BTN_TEXT}
        </Button>
      )}
    </ToolTipWrapper>
  );
};
