import { useEffect, useState } from "react";

import { AppModel, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

// useMasterPayerAccounts fetches AWS master payer accounts configs from Firestore.
// It will return all shared. If provided with a customer ID it will also return
// dedicated payers that belong to the customer, otherwise will return all dedicated payers.
// Fetching all dedicated payers only works for doitEmployees (customers will be blocked by rules).
export const useMasterPayerAccounts = (customerId?: string): MasterPayerAccountsModel[] | null => {
  const [data, setData] = useState<MasterPayerAccountsModel[] | null>(null);

  useEffect(() => {
    const getData = async () => {
      const masterPayerAccounts: MasterPayerAccountsModel[] = [];

      const coll = getCollection(AppModel).doc("master-payer-accounts").collection("mpaAccounts");

      const sharedPayersQuery = coll.where("tenancyType", "==", "shared");
      let dedicatedPayersQuery = coll.where("tenancyType", "==", "dedicated");
      if (customerId) {
        dedicatedPayersQuery = dedicatedPayersQuery.where("customerId", "==", customerId);
      }

      const [sharedDocSnaps, dedicatedDocSnaps] = await Promise.all([
        sharedPayersQuery.get(),
        dedicatedPayersQuery.get(),
      ]);

      [...sharedDocSnaps.docs, ...dedicatedDocSnaps.docs].forEach((docSnap) => {
        masterPayerAccounts.push(docSnap.data());
      });

      setData(masterPayerAccounts);
    };

    getData();
  }, [customerId]);

  return data;
};
