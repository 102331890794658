import { useCallback, useMemo } from "react";

import {
  type AmazonWebServicesAssetModel,
  type AssetType,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGoogleCloud,
  AssetTypeMicrosoftAzure,
  type CurrencyCode,
  CurrencyCodes,
  type GoogleCloudAssetModel,
  Metadata,
  type MicrosoftAzureAssetModel,
} from "@doitintl/cmp-models";
import uniq from "lodash/uniq";

import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAssetsContext } from "../../../Context/customer/AssetContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { type Asset } from "../../../types";
import { assetTypeName } from "../../../utils/common";

const ServiceAbbreviations = new Map<string, string>([
  // AWS
  ["Amazon Simple Storage Service", "S3"],
  ["Amazon Elastic Compute Cloud", "EC2"],
  ["Amazon Simple Queue Service", "SQS"],
  ["Amazon Simple Notification Service", "SNS"],
  ["Amazon Simple Email Service", "SES"],
  ["Amazon Simple Workflow Service", "SWS"],
  ["AWS Certificate Manager", "ACM"],
  ["AWS Key Management Service", "KMS"],
  ["Amazon Elastic Container Service", "ECS"],
  ["Amazon Elastic Container Service for Kubernetes", "EKS"],
  ["Amazon Elastic File System", "EFS"],
  ["Amazon Managed Streaming for Apache Kafka", "MSK"],
  ["Amazon Relational Database Service", "RDS"],
  ["Amazon Virtual Private Cloud", "VPC"],
  ["Elastic Load Balancing", "ELB"],

  // GCP
  ["Cloud Storage", "GCS"],
  ["Compute Engine", "GCE"],
  ["Kubernetes Engine", "GKE"],
  ["App Engine", "GAE"],
]);

const RegionNames = new Map<string, string>([
  // GCP
  ["asia-east1", "Changhua County, Taiwan, APAC"],
  ["asia-east2", "Hong Kong, APAC"],
  ["asia-northeast1", "Tokyo, Japan, APAC"],
  ["asia-northeast2", "Osaka, Japan, APAC"],
  ["asia-northeast3", "Seoul, South Korea"],
  ["asia-south1", "Mumbai, India, APAC"],
  ["asia-south2", "Delhi, India, APAC"],
  ["asia-southeast1", "Jurong West, Singapore, APAC"],
  ["asia-southeast2", "Jakarta, Indonesia, APAC"],
  ["australia-southeast1", "Sydney, Australia, APAC"],
  ["australia-southeast2", "Melbourne, Australia, APAC"],
  ["europe-north1", "Hamina, Finland, Europe"],
  ["europe-central2", "Warsaw, Poland, Europe"],
  ["europe-southwest1", "Madrid, Spain, Europe"],
  ["europe-west1", "St. Ghislain, Belgium, Europe"],
  ["europe-west2", "London, England, Europe"],
  ["europe-west3", "Frankfurt, Germany, Europe"],
  ["europe-west4", "Eemshaven, Netherlands, Europe"],
  ["europe-west6", "Zurich, Switzerland, Europe"],
  ["europe-west8", "Milan, Italy, Europe"],
  ["europe-west9", "Paris, France, Europe"],
  ["europe-west12", "Turin, Italy, Europe"],
  ["northamerica-northeast1", "Montréal, Québec, North America"],
  ["northamerica-northeast2", "Toronto, Ontario, North America"],
  ["southamerica-east1", "Osasco, São Paulo, Brazil, South America"],
  ["southamerica-west1", "Santiago, Chile, South America"],
  ["us-central1", "Council Bluffs, Iowa, North America"],
  ["us-east1", "Moncks Corner, South Carolina, North America"],
  ["us-east4", "Ashburn, Virginia, North America"],
  ["us-east5", "Columbus, Ohio, North America"],
  ["us-west1", "The Dalles, Oregon, North America"],
  ["us-west2", "Los Angeles, California, North America"],
  ["us-west3", "Salt Lake City, Utah, North America"],
  ["us-west4", "Las Vegas, Nevada, North America"],
  ["us-south1", "Dallas, Texas, North America"],
  ["me-central1", "Doha, Qatar, Middle East"],
  ["me-west1", "Tel Aviv, Israel, Middle East"],

  // AWS
  ["us-east-1", "N. Virginia, US East"],
  ["us-east-2", "Ohio, US East"],
  ["us-west-1", "N. California, US West"],
  ["us-west-2", "Oregon, US West"],
  ["af-south-1", "Cape Town, Africa"],
  ["ap-east-1", "Hong Kong, Asia Pacific"],
  ["ap-south-2", "Hyderabad, Asia Pacific"],
  ["ap-southeast-3", "Jakarta, Asia Pacific"],
  ["ap-southeast-4", "Melbourne, Asia Pacific"],
  ["ap-south-1", "Mumbai, Asia Pacific"],
  ["ap-northeast-3", "Osaka, Asia Pacific"],
  ["ap-northeast-2", "Seoul, Asia Pacific"],
  ["ap-southeast-1", "Singapore, Asia Pacific"],
  ["ap-southeast-2", "Sydney, Asia Pacific"],
  ["ap-northeast-1", "Tokyo, Asia Pacific"],
  ["ca-central-1", "Central, Canada"],
  ["cn-north-1", "Beijing, China"],
  ["cn-northwest-1", "Ningxia, China"],
  ["eu-central-1", "Frankfurt, Europe"],
  ["eu-west-1", "Ireland, Europe"],
  ["eu-west-2", "London, Europe"],
  ["eu-south-1", "Milan, Europe"],
  ["eu-west-3", "Paris, Europe"],
  ["eu-south-2", "Spain, Europe"],
  ["eu-north-1", "Stockholm, Europe"],
  ["eu-central-2", "Zurich, Europe"],
  ["me-south-1", "Bahrain, Middle East"],
  ["me-central-1", "UAE, Middle East"],
  ["sa-east-1", "São Paulo, South America"],
  ["us-gov-east-1", "US-East, AWS GovCloud"],
  ["us-gov-west-1", "US-West, AWS GovCloud"],
]);

const useTransforms = (): [Record<string, any>, CurrencyCode] => {
  const { entities } = useEntitiesContext();
  const { assets } = useAssetsContext();
  const { attributions } = useAttributionsContext();
  const [gcpAssets, awsAssets, azureAssets] = useMemo(() => {
    let gcp: Asset<GoogleCloudAssetModel>[] = [];
    let aws: Asset<AmazonWebServicesAssetModel>[] = [];
    let azure: Asset<MicrosoftAzureAssetModel>[] = [];

    for (const entityId in assets) {
      gcp = gcp.concat(
        assets[entityId].filter(
          (asset): asset is Asset<GoogleCloudAssetModel> => asset.data.type === AssetTypeGoogleCloud
        )
      );

      aws = aws.concat(
        assets[entityId].filter(
          (asset): asset is Asset<AmazonWebServicesAssetModel> =>
            asset.data.type === AssetTypeAmazonWebServices || asset.data.type === AssetTypeAwsStandalone
        )
      );

      azure = azure.concat(
        assets[entityId].filter(
          (asset): asset is Asset<MicrosoftAzureAssetModel> => asset.data.type === AssetTypeMicrosoftAzure
        )
      );
    }

    return [gcp, aws, azure];
  }, [assets]);

  const currency = useMemo<CurrencyCode>(() => {
    // Select default currency
    const getEntitiesId = (assets: Asset[]) => {
      const entitiesIds = new Set<string>();
      assets.forEach((asset) => {
        if (asset.data.entity?.id) {
          entitiesIds.add(asset.data.entity.id);
        }
      });

      return entitiesIds;
    };

    const combinedEntitiesIds = getEntitiesId([...gcpAssets, ...awsAssets, ...azureAssets]);

    const currencies = uniq(
      entities
        .filter((entity) => combinedEntitiesIds.has(entity.id))
        .map((entity) => entity.currency || CurrencyCodes.USD)
    );

    if (currencies.length === 1 && currencies[0] !== CurrencyCodes.ILS) {
      return currencies[0];
    }

    return CurrencyCodes.USD;
  }, [entities, gcpAssets, awsAssets, azureAssets]);

  const accountMapping = useMemo(() => {
    const accountMappingGCP = gcpAssets.reduce(
      (memo, asset) => ({
        ...memo,
        [asset.data.properties.billingAccountId]: asset.data.properties.displayName,
      }),
      {} as Record<string, string>
    );

    const accountMappingAWS = awsAssets.reduce(
      (memo, asset) => {
        if (asset.data.properties.cloudhealth?.customerId) {
          memo[asset.data.properties.cloudhealth.customerId] = asset.data.properties.cloudhealth.customerName;
        }

        return memo;
      },
      {} as Record<string, string>
    );

    const accountMappingAzure = azureAssets.reduce(
      (memo, asset) => ({
        ...memo,
        [asset.data.properties.subscription.subscriptionId]: asset.data.properties.subscription.displayName,
      }),
      {} as Record<string, string>
    );

    return {
      ...accountMappingGCP,
      ...accountMappingAWS,
      ...accountMappingAzure,
    };
  }, [gcpAssets, awsAssets, azureAssets]);

  const projectMapping = useMemo(
    () =>
      awsAssets.reduce(
        (memo, asset) => ({
          ...memo,
          [asset.data.properties.accountId]:
            asset.data.properties.friendlyName !== asset.data.properties.accountId
              ? asset.data.properties.friendlyName
              : asset.data.properties.name,
        }),
        {} as Record<string, string>
      ),
    [awsAssets]
  );

  const getAttributionNames = useCallback(
    (value: string, nullFallback: any) => {
      if (!value || value === nullFallback) {
        return null;
      }
      const attr = attributions?.find((ca) => ca.ref.id === value);
      if (attr) {
        return attr.data.name;
      }
      return null;
    },
    [attributions]
  );

  const baseTransforms = useMemo(
    () => ({
      [`${Metadata.FIXED}:cloud_provider`]: (value: AssetType) => assetTypeName(value),
      [`${Metadata.FIXED}:service_description`]: (value: string) => {
        if (ServiceAbbreviations.has(value)) {
          return `${value} (${ServiceAbbreviations.get(value)})`;
        }
        return value;
      },
      [`${Metadata.FIXED}:region`]: (value: string) => {
        if (RegionNames.has(value)) {
          return `${value} (${RegionNames.get(value)})`;
        }
        return value;
      },
      [`${Metadata.ATTRIBUTION}:${Metadata.ATTRIBUTION}`]: getAttributionNames,
      [Metadata.ATTRIBUTION_GROUP]: getAttributionNames,
    }),
    [getAttributionNames]
  );

  const transforms = useMemo(() => {
    const baseTransformsWithMapping = { ...baseTransforms };
    if (Object.keys(accountMapping).length > 0) {
      baseTransformsWithMapping[`${Metadata.FIXED}:billing_account_id`] = (value) => {
        if (accountMapping[value]) {
          return `${value} (${accountMapping[value]})`;
        }
        return null;
      };
    }

    if (Object.keys(projectMapping).length > 0) {
      baseTransformsWithMapping[`${Metadata.FIXED}:project_id`] = (value, nullFallback) => {
        if (!value || value === nullFallback) {
          return null;
        }
        if (projectMapping[value]) {
          return `${value} (${projectMapping[value]})`;
        }
        return null;
      };
    }
    return baseTransformsWithMapping;
  }, [accountMapping, projectMapping, baseTransforms]);

  return [transforms, currency];
};

export default useTransforms;
