import { Box } from "@mui/material";

const dotStyle = {
  width: 6,
  height: 6,
  borderRadius: "50%",
  backgroundColor: "divider",
  position: "absolute",
};

export const DotMarks = () => (
  <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
    <Box sx={{ ...dotStyle, left: "33%" }} />
    <Box sx={{ ...dotStyle, left: "49.8%" }} />
    <Box sx={{ ...dotStyle, left: "66.3%" }} />
  </Box>
);
