import { useCallback } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import TableCell from "@mui/material/TableCell";

import { CellsWrapper } from "../../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { formatDecimalNumber } from "../../../../../utils/common";
import { CoverageCell } from "../../../Common/TableCell/FormattedCoverageCell";
import { LinkCell } from "../../../Common/TableCell/LinkCell";
import { FamilyTypeTableCell } from "../Common/commonTableCells";
import { WorkloadStatus } from "../Common/Status/WorkloadStatus";
import { type WorkloadDetailsItem } from "../types";
import { getFormattedCoverage } from "../utils/common";
import { buildBaselineUrl, buildDetailsUrl } from "../utils/linkUtils";

type Props = {
  row: WorkloadDetailsItem;
  onEditClick: (workloadDetail: WorkloadDetailsItem) => void;
};

export const AllWorkloadDetailsRow = ({ row, onEditClick }: Props) => {
  const handleDialog = useCallback(() => {
    onEditClick(row);
  }, [row, onEditClick]);

  return (
    <CellsWrapper>
      <LinkCell linkText={row.billingAccountId} to={buildDetailsUrl(row)} />
      <TableCell>{row.region}</TableCell>
      <TableCell>{row.hardware}</TableCell>
      <FamilyTypeTableCell type={row.type} />
      <WorkloadStatus status={row.status} />
      <LinkCell linkText={formatDecimalNumber(row.usageOnDemand, 0)} to={buildBaselineUrl(row)} />
      <CoverageCell coverage={row.currentCudCoverage} />
      <CoverageCell coverage={row.currentDoitCoverage} />
      <CoverageCell coverage={row.currentCustomerCoverage} />
      <LinkCell linkText={formatDecimalNumber(row.optimizerBaseline, 0)} to={buildBaselineUrl(row)} />
      <CoverageCell coverage={row.optimizerCoverage} />
      <CoverageCell coverage={row.customerShare} />
      <TableCell>{getFormattedCoverage(row.displayTargetCoverage)}</TableCell>
      <TableCell>
        <IconButton onClick={handleDialog}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </CellsWrapper>
  );
};
