import { useEffect, useState } from "react";

import {
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudStandalone,
  type BillingStandaloneModel,
  CustomerModel,
  type GoogleCloudDirectAssetModel,
  type GoogleCloudStandaloneAssetModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { Box, Button } from "@mui/material";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../../../Components/FilterTable/FilterTableSkeleton";
import { type FilterTableToolbarProps } from "../../../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type AnyAsset } from "../../../../../types";
import { consoleErrorWithSentry } from "../../../../../utils";
import NoAssets from "../../../Tabs/components/NoAssets";
import { gcpDirectColumns, gcpDirectHeaders } from "./GcAssetColumnsDirect";
import GcAssetRowDirect, { type GcpDirectAssetRowData, populateRowFromAsset } from "./GcAssetRowDirect";

type Props = {
  items: AnyAsset<GoogleCloudDirectAssetModel | GoogleCloudStandaloneAssetModel>[];
  toolbar: FilterTableToolbarProps;
};

const GcAssetTableDirect = ({ items, toolbar }: Props) => {
  const { customer, assetsLoading } = useCustomerContext();
  const [tableData, setTableData] = useState<GcpDirectAssetRowData[]>([]);
  const [standaloneStatusMap, setStandaloneStatusMap] = useState<Record<string, BillingStandaloneModel>>({});
  const [standaloneStatusLoading, setStandaloneStatusLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(assetsLoading || standaloneStatusLoading);
  }, [assetsLoading, standaloneStatusLoading]);

  useEffect(() => {
    const standaloneAssets = items.filter((item) => item.data.type === AssetTypeGoogleCloudStandalone);

    if (!standaloneAssets.length) {
      // If the assets have loaded, we're not going to get anything extra; therefore we can consider the standalone status data loaded.
      !assetsLoading && setStandaloneStatusLoading(false);

      return;
    }

    const buildMap = async () => {
      try {
        const standaloneMap: Record<string, BillingStandaloneModel> = {};
        const saasBillingCollection = await getCollection(CustomerModel)
          .doc(customer.id)
          .collection("billingStandalone")
          .get();
        saasBillingCollection.forEach((billingStandalone) => {
          // NB - the `flexSaveStandalone.id` here is identical to the asset id.
          standaloneMap[billingStandalone.id] = billingStandalone.asModelData();
        });
        setStandaloneStatusMap(standaloneMap);
        setStandaloneStatusLoading(false);
      } catch (error) {
        consoleErrorWithSentry(error, {
          message: "Unable to fetch billingCollection for populating standalone asset status/reason.",
          customerId: customer.id,
        });
        setStandaloneStatusLoading(false);
      }
    };

    buildMap();
  }, [assetsLoading, customer.id, items]);

  useEffect(() => {
    const data = items.map((item) => populateRowFromAsset(item, standaloneStatusMap));
    setTableData(data);
  }, [items, standaloneStatusMap]);

  const tableLoading = (
    <Box p={1}>
      <FilterTableSkeleton />
    </Box>
  );

  toolbar.allowToEditColumns = items.length > 0;

  const content = (
    <>
      {isLoading ? (
        tableLoading
      ) : (
        <FilterTable<GcpDirectAssetRowData>
          tableItems={tableData}
          toolbarProps={toolbar}
          emptyTableComponent={
            <NoAssets assetType={AssetTypeGoogleCloudDirect}>
              <Button variant="contained" onClick={toolbar.primaryButton?.onClick}>
                New subscription
              </Button>
            </NoAssets>
          }
          rowComponent={GcAssetRowDirect}
          headerColumns={gcpDirectHeaders}
          filterColumns={gcpDirectColumns}
          wrapRowComponent={false}
          defaultSortingColumnValue="id"
          defaultSortDirection="asc"
          filterBarPlaceholder="Filter assets"
          persistenceKey="gcp_assets_direct"
          showFilterBar={items.length > 0}
          showHeader={items.length > 0}
        />
      )}
    </>
  );

  return (
    <Box mb={4} data-testid="gc-assets-table">
      {content}
    </Box>
  );
};

export default GcAssetTableDirect;
