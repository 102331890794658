import React, { useCallback, useState } from "react";

import { type PerkType, PerkTypes } from "@doitintl/cmp-models";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import LoadingButton from "../../../Components/LoadingButton";
import { perkTypeNames } from "../helpers";

export type RequestProfessionalServiceDialogProps = {
  onConfirm: () => Promise<void>;
  onClose: () => void;
  loading: boolean;
  perkType: PerkType;
  accountManagerName?: string;
};

export const cyIds = {
  dialog: "request-service-dialog",
  title: "title",
  body: "bodyContent",
  buttons: {
    cancel: "cancel-request-button",
    confirm: "proceed-with-request-button",
  },
};

/**
 * Dialog for requesting professional services.
 * Note that ISVs use {@link RegisterInterestDialog} instead.
 * @param onConfirm Callback to be executed when the user is ready to proceed with the request.
 * @param onClose Function to handle the closing of this dialog
 * @param perkType The type of perk being requested.
 * @param accountManagerName The name of the account manager to be assigned to this request.
 * @param loading Whether the request is currently being processed.
 */
export const RequestProfessionalServiceDialog = ({
  onConfirm,
  onClose,
  perkType,
  accountManagerName,
  loading,
}: RequestProfessionalServiceDialogProps) => {
  const [interestLoading, setInterestLoading] = useState<boolean>(false);

  const handleClick = useCallback<() => Promise<void>>(async () => {
    setInterestLoading(true);
    await onConfirm();
    setInterestLoading(false);
  }, [onConfirm]);

  const title = perkType === PerkTypes.Training ? "Register Interest" : `Request ${perkTypeNames[perkType]}`;

  const content =
    perkType === PerkTypes.Training
      ? "Your local DoiT Training Coordinator will contact you within three business days to better understand your training needs and connect you to the right resources."
      : `Your account manager${accountManagerName ? `, ${accountManagerName},` : ""} will reach out to you within two business days to evaluate the scope of this ${perkType === PerkTypes.Accelerator ? perkTypeNames[perkType] : perkType.toLowerCase()}.`;

  const buttonText = {
    [PerkTypes.Training]: "Register interest",
    [PerkTypes.Accelerator]: `Request ${perkType === PerkTypes.Accelerator ? perkTypeNames[perkType] : perkType.toLowerCase()}`,
    [PerkTypes.Workshop]: `Request ${perkType === PerkTypes.Accelerator ? perkTypeNames[perkType] : perkType.toLowerCase()}`,
  };

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} open={true} data-cy={cyIds.dialog}>
      <DialogTitle data-cy={cyIds.title}>{title}</DialogTitle>
      <DialogContent>
        <Typography data-cy={cyIds.body}>{content}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} data-cy={cyIds.buttons.cancel}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          disabled={interestLoading || loading}
          loading={interestLoading || loading}
          onClick={handleClick}
          data-cy={cyIds.buttons.confirm}
          mixpanelEventId="perks.request-training.request"
        >
          {buttonText[perkType]}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
