import { useMemo } from "react";

import { AnalyticsResourceType, CloudAnalyticsModel, type EntitlementKey } from "@doitintl/cmp-models";
import { type CollectionDataHook, getCollection, useCollectionData } from "@doitintl/models-firestore";

import { getCachingKeys } from "../utils/cachingKeys";
import { useAuthContext } from "./AuthContext";
import { useCustomerContext } from "./CustomerContext";
import { useTier } from "./TierProvider";

export const useTierDataProvider = (dataHook: CollectionDataHook<unknown>, entitlementKey: EntitlementKey) => {
  const { getFeatureLimit } = useTier();
  const { isDoitEmployee } = useAuthContext();

  const [data, loading, error] = dataHook;

  return useMemo(() => {
    if (loading) {
      return { limitReached: undefined, size: undefined, loading: true };
    }

    if (!data || error) {
      return { limitReached: true, size: 0, loading: false };
    }

    const featureLimit = isDoitEmployee ? null : (getFeatureLimit(entitlementKey) as number | null);

    return { limitReached: featureLimit ? data.length >= featureLimit : false, size: data.length, loading: false };
  }, [data, entitlementKey, error, getFeatureLimit, isDoitEmployee, loading]);
};

export const useTierLimitReachedAnalyticsAttributionGroups = () => {
  const { customer } = useCustomerContext();

  const query = useMemo(
    () =>
      getCollection(CloudAnalyticsModel)
        .doc("attribution-groups")
        .collection("cloudAnalyticsAttributionGroups")
        .where("customer", "==", customer.ref)
        .where("type", "==", AnalyticsResourceType.CUSTOM),
    [customer.ref]
  );

  const dataHook = useCollectionData(query, { caching: true, cachingKeys: getCachingKeys(customer.id) });

  return useTierDataProvider(dataHook, "analytics:attributionGroups");
};

export const useTierLimitReachedAnalyticsGovernanceBudgets = () => {
  const { customer } = useCustomerContext();

  const query = useMemo(
    () =>
      getCollection(CloudAnalyticsModel)
        .doc("budgets")
        .collection("cloudAnalyticsBudgets")
        .where("customer", "==", customer.ref)
        .where("enforcedByMetering", "==", true),
    [customer.ref]
  );

  const dataHook = useCollectionData(query, { caching: true, cachingKeys: getCachingKeys(customer.id) });

  return useTierDataProvider(dataHook, "governance:budgets");
};
