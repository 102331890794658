import { marketplaceText } from "../../../../assets/texts";
import { luxonDateTimeFilterProps } from "../../../../Components/EnhancedTableFilterUtils";
import { type HeaderColumn } from "../../../../types/FilterTable";

export const headerColumns: HeaderColumn[] = [
  {
    label: marketplaceText.CUSTOMER,
    value: "customerName",
    tooltip: marketplaceText.CUSTOMER,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.TIME_ENABLED,
    value: "createdAt",
    tooltip: marketplaceText.TIME_ENABLED,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.LAST_UPDATE,
    value: "updatedAt",
    tooltip: marketplaceText.LAST_UPDATE,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.STATE,
    value: "state",
    tooltip: marketplaceText.STATE,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.DOMAIN,
    value: "customerPrimaryDomain",
    tooltip: marketplaceText.DOMAIN,
    style: { align: "left", padding: "normal" },
  },
  {
    label: marketplaceText.AWS_ACCOUNT_ID,
    value: "awsAccountId",
    tooltip: marketplaceText.AWS_ACCOUNT_ID,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "@",
    style: { align: "left", padding: "normal" },
  },
];

export const filterColumns = [
  {
    label: marketplaceText.CUSTOMER,
    path: "customerName",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.STATE,
    path: "state",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.ACCOUNT_ID,
    path: "accountId",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.LAST_UPDATE,
    path: "updatedAtDate",
    comparators: ["<=", ">="],
    placeholder: "YYYY-MM-DD",
    ...luxonDateTimeFilterProps("yyyy-LL-dd"),
  },
  {
    label: marketplaceText.TIME_ENABLED,
    path: "createdAtDate",
    comparators: ["<=", ">="],
    placeholder: "YYYY-MM-DD",
    ...luxonDateTimeFilterProps("yyyy-LL-dd"),
  },
  {
    label: marketplaceText.DOMAIN,
    path: "customerPrimaryDomain",
    comparators: ["==", "!="],
  },
  {
    label: marketplaceText.AWS_ACCOUNT_ID,
    path: "awsAccountId",
    comparators: ["==", "!="],
  },
] as const;
