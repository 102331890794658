import { type JSX, useEffect } from "react";

import { Box } from "@mui/material";

import { type SubnetDetails } from "../../../types";
import { EditList } from "./EditList";

type Props = {
  allowEdit: boolean;
  selectedSubnets: string[];
  allSubnets: string[];
  subnetsDetails: Record<string, SubnetDetails>;
  onSelectionChange: (selectedItems?: string[]) => void;
  onHeightChange: () => void;
};

export const EditAzAndSubnets = ({
  selectedSubnets,
  allSubnets,
  subnetsDetails,
  allowEdit,
  onSelectionChange,
  onHeightChange,
}: Props) => {
  useEffect(() => {
    onHeightChange();
  }, [onHeightChange]);

  if (selectedSubnets.length === 1 && selectedSubnets[0] === "" && allowEdit) {
    return <Box>Missing subnet details</Box>;
  }

  const renderInstanceTypesOptionDetails = (subnet: string): JSX.Element | undefined => {
    const details = subnetsDetails?.[subnet];
    if (details) {
      return (
        <Box>
          <Box>{details.availabilityZone}</Box>
          <Box fontSize="body2.fontSize" color="text.secondary">
            {subnet}
          </Box>
          <Box display="flex" fontSize="body2.fontSize" color="text.secondary">
            <Box width={130}>{details.cidr}</Box>
            {details.isDefault && <Box>default</Box>}
          </Box>
        </Box>
      );
    }
  };

  const renderChipString = (subnet: string): string => {
    const details = subnetsDetails?.[subnet];
    return details ? `${details?.availabilityZone} | ${subnet}` : subnet;
  };

  return (
    <EditList
      title="Availability Zones"
      startEachItemInNewLine
      optionsItems={allSubnets}
      selectedItems={selectedSubnets}
      onEditModeChange={onSelectionChange}
      allowEdit={allowEdit}
      onChange={onHeightChange}
      renderOptionDetails={renderInstanceTypesOptionDetails}
      renderOptionString={renderChipString}
    />
  );
};
