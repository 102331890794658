import { type Dispatch, type SetStateAction, useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { AnalyticsResourceType } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack, TableCell, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { reportTxt } from "../../../assets/texts/CloudAnalytics";
import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { type ReportWSnap } from "../../../types";
import { type LabelWithRef } from "../labels/types";
import { useReportsThreeDotsMenu } from "./hooks";

const { report: reportIds } = cloudAnalytics;

type ReportRowProps = {
  row: ReportWSnap;
  labels: LabelWithRef[] | undefined;
  setClickedRow: Dispatch<SetStateAction<ReportWSnap>>;
  setShareDialogOpen: Dispatch<SetStateAction<boolean>>;
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
  setSelectedReports: Dispatch<SetStateAction<ReportWSnap[]>>;
};

export const ReportRow = ({
  row,
  labels,
  setClickedRow,
  setShareDialogOpen,
  setOpenDeleteDialog,
  setSelectedReports,
}: ReportRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  const formattedTimeModified = useMemo(() => {
    const date = DateTime.fromJSDate(row.data?.timeModified?.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
    return "";
  }, [row.data.timeModified]);

  const threeDotsMenu = useReportsThreeDotsMenu({
    row,
    reportId: row.snapshot.id,
    shareHandler: () => {
      setClickedRow(row);
      setShareDialogOpen(true);
    },
    deleteHandler: () => {
      setSelectedReports([row]);
      setOpenDeleteDialog(true);
    },
  });

  return (
    <>
      <TableCell data-cy={reportIds.browser.reportName}>
        <Stack spacing={1}>
          <Stack>
            <Typography
              component={RouterLink}
              to={{
                pathname: `${routeMatchURL}/${row.snapshot.id}`,
                state: {
                  prevPage: routeMatchURL, // or any other value you want to pass as state
                },
              }}
              color="inherit"
              variant="body2"
            >
              {row.data.name}
            </Typography>
            {!!row.data.description && (
              <Typography
                color="textSecondary"
                variant="body2"
                sx={{ whiteSpace: "noWrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "calc(100vw/4)" }}
                title={row.data.description}
              >
                {row.data.description}
              </Typography>
            )}
          </Stack>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy={reportIds.browser.reportOwner}>{row.data.owner}</TableCell>
        <TableCell data-cy={reportIds.browser.reportType}>
          {capitalize(row.data.type)}
          {row.data.type === AnalyticsResourceType.MANAGED && (
            <Tooltip
              title={reportTxt.MANAGED_REPORT_TOOLTIP}
              placement="top"
              slotProps={{
                tooltip: {
                  sx: { maxWidth: "175px" },
                },
              }}
            >
              <InfoOutlinedIcon
                htmlColor={grey[600]}
                sx={{ fontSize: "16px", position: "relative", top: "3px", left: "3px" }}
              />
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-cy={reportIds.browser.reportLastModified}>{formattedTimeModified}</TableCell>
      </Hide>
      <TableCell data-cy={reportIds.browser.reportOptions} padding="none">
        {threeDotsMenu}
      </TableCell>
    </>
  );
};
