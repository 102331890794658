import { type Dispatch, useEffect, useState } from "react";

import { TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { Box, Tab, Tabs } from "@mui/material";
import { DateTime } from "luxon";

import DateRange from "../../../../../../Components/DateRange";
import { type IDateRange } from "../../../../../../Components/DateRange/dateRangeUtils";
import useMountEffect from "../../../../../../Components/hooks/useMountEffect";
import {
  convertTimeSettingsModeToIndex,
  getTimeSettings,
  type TimeRangeOption,
  TimeRangeTabsLabel,
} from "../../../../utilities";
import Current from "./Current";
import Last from "./Last";

type TimeSelectionTabsProps = {
  timeRangeOption: TimeRangeOption;
  setTimeRangeOption: Dispatch<React.SetStateAction<TimeRangeOption>>;
};

const TimeSelectionTabs = ({ timeRangeOption, setTimeRangeOption }: TimeSelectionTabsProps) => {
  const [amountStr, setAmountStr] = useState(`${timeRangeOption.amount}`);
  const [dateRange, setDateRange] = useState<IDateRange>({ start: DateTime.now(), end: DateTime.now() });
  const isViewOnly = timeRangeOption.mode === TimeSettingsMode.Current;
  const disableEndDate = timeRangeOption.mode === TimeSettingsMode.Last;
  const showTextInput = timeRangeOption.mode === TimeSettingsMode.Fixed;

  const onTabChanged = (i: number) => {
    let tab = TimeSettingsMode.Last;
    let time = TimeInterval.DAY;
    let amount = 7;
    let includeCurrent = true;
    const range = {
      start: DateTime.utc().startOf("month"),
      end: DateTime.utc().startOf("day"),
    };
    if (i === 1) {
      time = TimeInterval.MONTH;
      tab = TimeSettingsMode.Current;
      amount = 0;
      includeCurrent = false;
    }
    if (i === 2) {
      tab = TimeSettingsMode.Fixed;
      amount = 0;
      includeCurrent = false;
    }
    setTimeRangeOption((prev) => ({ ...prev, time, mode: tab, range, amount, includeCurrent }));
  };

  const onPeriodChanged = (time: TimeInterval) => {
    setTimeRangeOption((prev) => ({ ...prev, time }));
  };

  const onRangeChanged = (start: DateTime | null, end: DateTime | null) => {
    if (timeRangeOption.mode === TimeSettingsMode.Last) {
      const delta =
        start &&
        end?.endOf(timeRangeOption.time)?.diff(start.startOf(timeRangeOption.time), [timeRangeOption.time]).toObject();
      setTimeRangeOption((prev) => ({
        ...prev,
        amount: Math.ceil(delta?.[`${timeRangeOption.time}s`] || 0.1),
      }));
    }
    if (timeRangeOption.mode === TimeSettingsMode.Fixed) {
      setTimeRangeOption((prev) => ({
        ...prev,
        range: { start, end },
      }));
    }
  };

  const onIncludeCurrentChanged = (checked: boolean) => {
    setTimeRangeOption((prev) => ({ ...prev, includeCurrent: checked }));
  };

  const onChangeAmount = (amount: string) => {
    setAmountStr(amount);
  };

  useEffect(() => {
    const amount = parseInt(amountStr, 10);
    if (amount) {
      setTimeRangeOption((prev) => ({ ...prev, amount }));
    }
  }, [amountStr, setTimeRangeOption]);

  useEffect(() => {
    onChangeAmount(`${timeRangeOption.amount}`);
  }, [timeRangeOption.amount]);

  useEffect(() => {
    let timeSettings;
    if (timeRangeOption.mode !== TimeSettingsMode.Fixed) {
      timeSettings = getTimeSettings(timeRangeOption);
    } else {
      // if mode is fixed, we need to handle option that "end" is null,
      // which "getTimeSettings" can't handle, therefore we use the "timeRangeOption".
      // "timeRangeOption" is for the temporary state
      timeSettings = {
        from: timeRangeOption.range?.start,
        to: timeRangeOption.range?.end,
      };
    }
    setDateRange({ start: timeSettings.from, end: timeSettings.to });
  }, [timeRangeOption]);

  useMountEffect(() => {
    const initRange = getTimeSettings(timeRangeOption);
    setDateRange({ start: initRange.from, end: initRange.to });
  });
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={(e, i) => {
            onTabChanged(i);
          }}
          value={convertTimeSettingsModeToIndex(timeRangeOption.mode)}
          onTransitionEnd={(e) => {
            e.stopPropagation();
          }}
        >
          <Tab label={TimeRangeTabsLabel.Last} sx={{ textTransform: "initial" }} data-cy="last-tab" />
          <Tab label={TimeRangeTabsLabel.Current} sx={{ textTransform: "initial" }} data-cy="current-tab" />
          <Tab label={TimeRangeTabsLabel.Fixed} sx={{ textTransform: "initial" }} data-cy="fixed-tab" />
        </Tabs>
      </Box>
      <Box>
        <Last
          amount={amountStr}
          onIncludeCurrentChanged={onIncludeCurrentChanged}
          onPeriodChanged={onPeriodChanged}
          onChangeAmount={onChangeAmount}
          timeRangeOption={timeRangeOption}
        />
        <Current mode={timeRangeOption.mode} onChangeTimeInterval={onPeriodChanged} time={timeRangeOption.time} />
        <DateRange
          dateRange={dateRange}
          disableEndDate={disableEndDate}
          isViewOnly={isViewOnly}
          onRangeChanged={onRangeChanged}
          showTextInput={showTextInput}
          disableCurrent={
            timeRangeOption.includeCurrent || timeRangeOption.mode !== TimeSettingsMode.Last
              ? undefined
              : timeRangeOption.time
          }
        />
      </Box>
    </>
  );
};

export default TimeSelectionTabs;
