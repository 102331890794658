import { useCallback } from "react";

import { type Office365AssetModel } from "@doitintl/cmp-models";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { type FormikErrors, type FormikHandlers, type FormikState } from "formik";
import uniqBy from "lodash/uniqBy";

import { type Asset } from "../../../../types";
import { type Values } from "./types";

type Props = Pick<FormikHandlers, "handleChange" | "handleBlur"> &
  Pick<FormikState<Values>, "touched"> & {
    values: Values;
    assets: Asset<Office365AssetModel>[];
    errors: FormikErrors<Values>;
  };

const DomainStep = ({ assets, touched, errors, values, handleChange, handleBlur }: Props) => {
  const getCustomerDomainMenuItems = useCallback(() => {
    const tenants = uniqBy(assets, (asset) => asset.data.properties.customerId).map((asset) => ({
      customerId: asset.data.properties.customerId,
      customerDomain: asset.data.properties.customerDomain,
      entity: asset.data.entity,
    }));
    if (!tenants.length) {
      return (
        <MenuItem data-cy="empty-menu-item" value="" disabled>
          No active Office 365 tenants
        </MenuItem>
      );
    }
    return tenants.map((tenant, i) => (
      <MenuItem key={`${tenant.customerId}-${i}`} data-cy={tenant.customerDomain} value={tenant.customerId}>
        {tenant.customerDomain}
      </MenuItem>
    ));
  }, [assets]);

  return (
    <Grid container>
      <Grid item xs />
      <Grid container item xs={12} md={6} lg={4} xl={3} direction="column" spacing={1}>
        <Grid item>
          <Typography data-cy="subtitle" variant="subtitle1">
            Select the domain for which you're adding a subscription
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            name="tenant"
            data-cy="select"
            helperText={(touched.tenant && errors.tenant) || "Your order will be added to the selected domain"}
            error={touched.tenant && Boolean(errors.tenant)}
            label="Office 365 Domain"
            fullWidth
            required
            select
            variant="outlined"
            margin="dense"
            SelectProps={{
              value: values.tenant,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
          >
            {getCustomerDomainMenuItems()}
          </TextField>
        </Grid>
      </Grid>
      <Grid item xs />
    </Grid>
  );
};

export default DomainStep;
