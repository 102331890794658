import { Loader } from "../../Loader";
import { FlexsaveWidgetCard } from "../Common/FlexsaveWidgetCard";

export const FlexsaveCardLoading = ({ widgetHeight }: { widgetHeight: number }) => (
  <FlexsaveWidgetCard
    title="Flexsave"
    subheader="Loading..."
    widgetHeight={widgetHeight}
    style={{ position: "relative" }}
  >
    <Loader loading={true}>
      <></>
    </Loader>
  </FlexsaveWidgetCard>
);
