import { useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";

import LoadingButton from "../../../Components/LoadingButton";
import { text } from "../consts";

type Props = {
  onSubmit: () => Promise<void>;
  onClose: () => void;
};

export const RoleChangeConfirmationDialog = ({ onSubmit, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await onSubmit();
    } finally {
      onClose();
      setLoading(false);
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>{text.ROLE_CONFIRMATION_DIALOG_Header}</DialogTitle>
      <DialogContent>{text.ROLE_CONFIRMATION_DIALOG_MESSAGE}</DialogContent>
      <Divider />
      <DialogActions>
        <Button data-cy="role-confirm-cancel" onClick={onClose}>
          {text.ROLE_CONFIRMATION_DIALOG_CANCEL}
        </Button>
        <LoadingButton
          data-cy="role-confirm-ok"
          color="primary"
          variant="contained"
          loading={loading}
          onClick={handleSubmit}
          disabled={loading}
          mixpanelEventId="user-view.role-change-confirmation.submit"
        >
          {text.ROLE_CONFIRMATION_DIALOG_SUBMIT}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
