import React from "react";

import { Alert, MenuItem, TextField } from "@mui/material";

import { type JiraField } from "../types";
import { LoadingBox } from "./LoadingBox";

type Props = {
  isLoading: boolean;
  isError: boolean;
  fieldValues: Record<string, string>;
  setFieldValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  fields?: JiraField[];
};

export const FieldsSelects = ({ isLoading, isError, fieldValues, setFieldValues, fields }: Props) => {
  const handleFieldChange = (fieldKey: string, value: string) => {
    setFieldValues((prev) => ({
      ...prev,
      [fieldKey]: value,
    }));
  };

  const nonStandardFields = fields
    ? fields.filter((field) => field.key !== "summary" && field.key !== "description")
    : [];

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        An error occurred while fetching fields from your Jira instance. Please try again later.
      </Alert>
    );
  }

  return (
    <>
      {/* Pre-filled Summary and Description fields: */}
      <TextField
        label="Summary"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={fieldValues.summary}
        onChange={(e) => {
          handleFieldChange("summary", e.target.value);
        }}
        error={!fieldValues.summary}
      />
      <TextField
        label="Description"
        variant="outlined"
        key="description"
        fullWidth
        multiline
        minRows={5}
        sx={{ mb: 2 }}
        value={fieldValues.description}
        onChange={(e) => {
          handleFieldChange("description", e.target.value);
        }}
        error={!fieldValues.description}
      />
      {/* Any other required fields: */}
      {fields!.map(
        (field) =>
          field.key !== "summary" &&
          field.key !== "description" &&
          (field.allowedValues ? (
            <TextField
              key={field.key}
              label={field.name}
              select
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={fieldValues[field.key] || ""}
              onChange={(e) => {
                handleFieldChange(field.key, e.target.value);
              }}
              error={!fieldValues[field.key]}
              helperText={!fieldValues[field.key] ? "This field is required" : ""}
            >
              {field.allowedValues.map((allowedValue) => {
                // Depending on the type of field, we should have either name or value, otherwise fall back to the ID
                const fieldName = allowedValue.name || allowedValue.value || allowedValue.id;
                return (
                  <MenuItem key={fieldName} value={allowedValue.id}>
                    {fieldName}
                  </MenuItem>
                );
              })}
            </TextField>
          ) : (
            <TextField
              key={field.key}
              label={field.name}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              value={fieldValues[field.key] || ""}
              onChange={(e) => {
                handleFieldChange(field.key, e.target.value);
              }}
              error={!fieldValues[field.key]}
              helperText={!fieldValues[field.key] ? "This field is required" : ""}
            />
          ))
      )}

      {nonStandardFields.length ? (
        <Alert severity="info" sx={{ mb: 2 }}>
          Your selected project and issue type contain non-standard required fields. Support for these fields in Threads
          is currently limited. Please open a support ticket if you encounter any issues.
        </Alert>
      ) : null}
    </>
  );
};
