import { useCallback, useMemo, useState } from "react";

import RefreshIcon from "@mui/icons-material/RefreshRounded";
import { Box, Card, CardContent, Typography } from "@mui/material";

import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { generateHeadersAndFilters } from "../../../Components/FilterTable/filterTableUtils";
import { type FilterTableToolbarProps } from "../../../Components/FilterTable/Toolbar/FilterTableToolbar";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { PageHeaderWithTimeFilter } from "../Common/PageHeaderWithTimeFilter";
import { asgsCollection } from "../db";
import { useAsgs, useConnectedAccounts, useGetLastUpdateDate, useShouldAutomaticallyRefresh } from "../hooks";
import { type SpotScalingApi } from "../spotScalingApi";
import {
  type AsgBasicInfo,
  type AsgTableItem,
  OptimizationStatus,
  OptimizationStatusColor,
  SavingsTimeFilter,
} from "../types";
import { getPotentialSavings } from "../Utils/costsUtils";
import { AsgsOverviewEmptyTableState } from "./AsgsOverviewEmptyTableState";
import { AsgsOverviewSkeleton } from "./AsgsOverviewSkeleton";
import { AsgsOverviewTableRow } from "./AsgsOverviewTableRow";
import { AsgAdditionalFilters, AsgItemsDescriptions } from "./columns";
import { HeaderAlert } from "./HeaderAlert";
import { SavingsStatisticsCards } from "./Savings/SavingsStatisticsCards";
import { calculateSavings, calculateSpending, verifyNumber } from "./Savings/utils";

type AsgsOverviewProps = {
  spotScalingApi: SpotScalingApi;
};

export const AsgsOverview = ({ spotScalingApi }: AsgsOverviewProps) => {
  const { customer } = useCustomerContext();

  const [saving, setSaving] = useState(0);
  const [savingRatio, setSavingRatio] = useState<number>(0);
  const [odSpending, setOdSpending] = useState(0);
  const [spotSpending, setSpotSpending] = useState(0);

  const [timeFilter, setTimeFilter] = useState<SavingsTimeFilter>(SavingsTimeFilter.CurrentMonth);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const [allAsgs, loading] = useAsgs(asgsCollection);
  const [connectAccounts] = useConnectedAccounts();

  const accountsWithSpotScaling = useMemo(
    () =>
      connectAccounts?.reduce(
        (accumulator, account) => {
          account.supportedFeatures?.forEach((feature) => {
            if (feature.name === "spot-scaling" && feature.hasRequiredPermissions) {
              accumulator[account.accountId] = true;
            }
          });
          return accumulator;
        },
        {} as Record<string, boolean>
      ) ?? {},
    [connectAccounts]
  );

  const asgs = useMemo(
    () => allAsgs?.filter((asg) => accountsWithSpotScaling[asg.accountId] === true),
    [accountsWithSpotScaling, allAsgs]
  );

  const asgTableItems: AsgTableItem[] = useMemo(
    () =>
      asgs
        ? asgs.map((asg) => ({
            id: asg.id,
            asgName: asg.asgName,
            optimizationStatus: asg.optimizationStatus.toString(),
            optimisationStatusColor:
              OptimizationStatusColor[
                Object.keys(OptimizationStatus)[Object.values(OptimizationStatus).indexOf(asg.optimizationStatus)]
              ],
            desiredCapacity: asg.configurations.current.desiredCapacity,
            minCapacity: asg.configurations.current.minCapacity,
            maxCapacity: asg.configurations.current.maxCapacity,
            desiredMinMax: `${asg.configurations.current.desiredCapacity} / ${asg.configurations.current.minCapacity} / ${asg.configurations.current.maxCapacity}`,
            onDemandRatio: asg.configurations.current.onDemandPercentageAboveBaseCapacity,
            spotRatio: 100 - asg.configurations.current.onDemandPercentageAboveBaseCapacity,
            baseCapacity: Math.max(asg.configurations.current.onDemandBaseCapacity, 0),
            onDemandSpotBaseCapacity: `${asg.configurations.current.onDemandPercentageAboveBaseCapacity}% / ${
              100 - asg.configurations.current.onDemandPercentageAboveBaseCapacity
            }% / ${Math.max(asg.configurations.current.onDemandBaseCapacity, 0)}`,
            region: asg.region,
            accountName: asg.accountName,
            accountId: asg.accountId,
            odSpending: verifyNumber(asg.odSpendingByTimeFilter[timeFilter]),
            spotSpending: verifyNumber(asg.spotSpendingByTimeFilter[timeFilter]),
            savings: [OptimizationStatus.Optimized, OptimizationStatus.NewRecommendations].includes(
              asg.optimizationStatus
            )
              ? verifyNumber(asg.costsSavingsHistoryByTimeFilter[timeFilter])
              : getPotentialSavings(asg),
            asgItem: asg,
          }))
        : [],

    [asgs, timeFilter]
  );

  const shouldAutomaticallyRefresh = useShouldAutomaticallyRefresh(asgs);

  const onFilterApplied = (items: readonly AsgTableItem[]) => {
    const optimizedAsgs = items.filter(
      (item) =>
        item.optimizationStatus === OptimizationStatus.Optimized ||
        item.optimizationStatus === OptimizationStatus.NewRecommendations
    );

    const allFilteredAsgs = [...items];

    const savings = calculateSavings(optimizedAsgs, timeFilter);
    setSaving(savings.savings);
    setSavingRatio(savings.savingsRatio);

    const { totalSpotSpending, totalOdSpending } = calculateSpending(allFilteredAsgs, timeFilter);
    setSpotSpending(totalSpotSpending);
    setOdSpending(totalOdSpending);
  };

  const refreshSingleAsg = async (params?: AsgBasicInfo) => spotScalingApi.refreshRecommendations(params);

  const executeRefresh = useCallback(async () => {
    setIsRefreshing(true);
    const result = await spotScalingApi.refreshRecommendations();
    setIsRefreshing(false);
    return result;
  }, [spotScalingApi]);

  if (shouldAutomaticallyRefresh) {
    executeRefresh().catch();
  }

  const lastUpdate = useGetLastUpdateDate(asgs);
  const toolbarProps: FilterTableToolbarProps = {
    allowToEditColumns: true,
    secondaryButtons: [{ startIcon: <RefreshIcon />, text: "Refresh ASG data", onClick: executeRefresh }],
  };

  const { headerColumns, filters: baseFilters } = useMemo(() => generateHeadersAndFilters(AsgItemsDescriptions), []);
  const { filters: additionalFilters } = useMemo(() => generateHeadersAndFilters(AsgAdditionalFilters), []);
  const filters = useMemo(() => [...baseFilters, ...additionalFilters], [baseFilters, additionalFilters]);
  return (
    <>
      <Box pb={1}>
        <HeaderAlert />
      </Box>
      <Card>
        <PageHeaderWithTimeFilter
          customer={customer}
          title="Spot Scaling"
          timeFilterProps={{ value: timeFilter, onTimeFilterChanged: setTimeFilter }}
        />
      </Card>

      <Box py={1}>
        <SavingsStatisticsCards
          savings={saving}
          savingsRatio={savingRatio}
          odSpending={odSpending}
          spotSpending={spotSpending}
          timeFilter={timeFilter}
        />
      </Box>

      <Card>
        <CardContent>
          {isRefreshing || loading ? (
            <AsgsOverviewSkeleton />
          ) : (
            <FilterTable<AsgTableItem>
              toolbarProps={toolbarProps}
              tableItems={asgTableItems}
              rowComponent={({ row }) => (
                <AsgsOverviewTableRow row={row} timeFilter={timeFilter} handleAsgRefresh={refreshSingleAsg} />
              )}
              emptyTableComponent={<AsgsOverviewEmptyTableState />}
              headerColumns={headerColumns}
              filterColumns={filters}
              onFilterApplied={onFilterApplied}
              defaultSortingColumnValue="optimizationStatus"
              persistenceKey="spot_scaling_all_asgs"
            />
          )}
        </CardContent>
      </Card>
      <Box mt={2} mb={2} sx={{ textAlign: "right" }}>
        <Typography variant="caption">Data last updated: {lastUpdate}</Typography>
      </Box>
    </>
  );
};
