import { useCallback, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import EditIcon from "@mui/icons-material/EditRounded";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";

import { luxonDateTimeFilterProps } from "../../Components/EnhancedTableFilterUtils";
import { OPERATOR_AND } from "../../Components/FilterTable/constants";
import { FilterContextProvider } from "../../Components/FilterTable/Context";
import { TableFilterBar } from "../../Components/FilterTable/Filterbar/TableFilterBar";
import Hide from "../../Components/HideChildren/Hide";
import { CircularProgressLoader } from "../../Components/Loader";
import { useAuthContext } from "../../Context/AuthContext";
import { type InvoiceAdjustmentType, useInvoiceAdjustmentsContext } from "../../Context/InvoiceAdjustmentsContext";
import { assetTypeName, formatCurrency } from "../../utils/common";
import { dateFormatMonthAndYear } from "../../utils/dateTimeFormats";
import AdjustmentFormDialog from "./AdjustmentFormDialog";

const startDateColumn = {
  label: "Start Date",
  path: "data.startDate",
  comparators: ["<", "<=", ">", ">=", "==", "!="],
  placeholder: "YYYY-MM",
  ...luxonDateTimeFilterProps("yyyy-LL"),
} as const;

const endDateColumn = {
  label: "End Date",
  path: "data.endDate",
  comparators: ["<", "<=", ">", ">=", "==", "!="],
  placeholder: "YYYY-MM",
  ...luxonDateTimeFilterProps("yyyy-LL"),
} as const;

const getFilterColumns = (withCustomer: boolean) => {
  const baseColumns = [
    {
      path: "data.description",
      label: "Description",
      comparators: ["==", "!=", "contains"],
    },
    {
      path: "data.details",
      label: "Details",
      comparators: ["==", "!=", "contains"],
    },
    {
      path: "data.type",
      label: "Type",
      toOption: (value: any) => ({ value, label: assetTypeName(value) }),
    },
    {
      path: "data.amount",
      label: "Amount",
      type: "Number",
      comparators: ["<", "<=", ">", ">=", "==", "!="],
      placeholder: "number",
      transform: (value: any) => parseFloat(value.replace(/,/g, "")),
      validate: (value: any) => !isNaN(parseFloat(value.replace(/,/g, ""))),
    },
    startDateColumn,
    endDateColumn,
  ] as const;

  if (!withCustomer) {
    return [
      {
        path: "data.metadata.customer.primaryDomain",
        label: "Customer",
      },
      ...baseColumns,
    ] as const;
  }

  return baseColumns;
};

const getColumns = (withCustomer: boolean) => {
  const baseColumns = [
    {
      id: "data.type",
      align: "left",
      label: "Type",
      tooltip: "Type",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.description",
      align: "left",
      label: "Description",
      tooltip: "Short Description (48 characters)",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.details",
      align: "left",
      label: "Details",
      tooltip: "Details (120 characters)",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.startDate",
      align: "left",
      label: "From",
      tooltip: "From invoice month",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.endDate",
      align: "left",
      label: "To",
      tooltip: "To invoice month",
      padding: "none",
      hidden: { xsDown: true },
    },
    {
      id: "data.amount",
      align: "right",
      label: "Amount",
      tooltip: "Adjustment amount per month",
      padding: "normal",
      hidden: {},
    },
  ] as const;

  if (!withCustomer) {
    return [
      {
        id: "data.metadata.customer.primaryDomain",
        align: "left",
        label: "Customer",
        tooltip: "Customer primary domain",
        padding: "none",
        hidden: {},
      },
      ...baseColumns,
    ];
  }

  return baseColumns;
};

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(0.5),
  },
  link: {
    textDecoration: "underline",
  },
}));

const AdjustmentsTab = ({ withCustomer }: { withCustomer: boolean }) => {
  const { adjustments, loading } = useInvoiceAdjustmentsContext();
  const classes = useStyles();
  const { isDoitDeveloper } = useAuthContext();

  const [selected, setSelected] = useState<InvoiceAdjustmentType | null>();
  const [adjustmentFormOpen, setAdjustmentFormOpen] = useState(false);
  const [tableState, setTableState] = useState<{
    sort: string;
    direction: "asc" | "desc";
    page: number;
    rowsPerPage: number;
    filter?: (adjustments: InvoiceAdjustmentType[]) => InvoiceAdjustmentType[];
  }>({
    sort: "data.startDate",
    direction: "desc",
    page: 0,
    rowsPerPage: 10,
  });

  const startOfMonth = DateTime.utc().startOf("month");

  const handleRequestFilter = useCallback((filter) => {
    setTableState((tableState) => ({ ...tableState, filter, page: 0 }));
  }, []);

  const filteredAdjustments = useMemo(() => tableState.filter?.(adjustments) ?? adjustments, [adjustments, tableState]);

  const handleRequestSort = (sort: string) => () => {
    let direction: "desc" | "asc" = "desc";
    if (tableState.sort === sort && tableState.direction === "desc") {
      direction = "asc";
    }
    setTableState((tableState) => ({ ...tableState, sort, direction }));
  };

  const isSelected = (creditId: string) => Boolean(selected && selected.ref.id === creditId);

  const filterColumns = useMemo(() => getFilterColumns(withCustomer), [withCustomer]);
  const columns = useMemo(() => getColumns(withCustomer), [withCustomer]);
  const defaultFilters = useMemo(
    () =>
      [
        // Currently active
        {
          column: startDateColumn,
          comparator: "<=",
          ...startDateColumn.toOption(startOfMonth),
        },
        OPERATOR_AND,
        {
          column: endDateColumn,
          comparator: ">=",
          ...endDateColumn.toOption(startOfMonth),
        },
      ] as const,
    [startOfMonth]
  );

  return (
    <FilterContextProvider defaultValue={defaultFilters} columns={filterColumns}>
      {isDoitDeveloper && adjustmentFormOpen && (
        <AdjustmentFormDialog
          adjustment={selected}
          onClose={() => {
            setAdjustmentFormOpen(false);
            setSelected(null);
          }}
        />
      )}

      <CardContent>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item xs>
            <TableFilterBar
              items={adjustments}
              filterColumn={filterColumns}
              onFilter={handleRequestFilter}
              defaultFilters={defaultFilters}
            />
          </Grid>

          {isDoitDeveloper && (
            <Grid item className={classes.actions}>
              <IconButton
                onClick={() => {
                  setAdjustmentFormOpen(true);
                }}
                disabled={!selected}
                size="large"
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setSelected(null);
                  setAdjustmentFormOpen(true);
                }}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </CardContent>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox" />
            {columns.map((column) => (
              <Hide {...column.hidden} key={column.id}>
                <TableCell
                  padding={column.padding}
                  align={column.align}
                  sortDirection={tableState.sort === column.id ? tableState.direction : false}
                >
                  <Tooltip title={column.tooltip}>
                    <TableSortLabel
                      active={tableState.sort === column.id}
                      direction={tableState.direction}
                      onClick={handleRequestSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              </Hide>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <CircularProgressLoader />}
          {!loading &&
            orderBy(filteredAdjustments, [tableState.sort], [tableState.direction])
              .slice(
                tableState.page * tableState.rowsPerPage,
                tableState.page * tableState.rowsPerPage + tableState.rowsPerPage
              )
              .map((adjustment, i) => {
                const isItemSelected = isSelected(adjustment.ref.id);
                const labelId = `table-checkbox-${i}`;

                return (
                  <TableRow key={adjustment.ref.id} hover>
                    <TableCell align="center" padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(_event) => {
                          setSelected(isSelected(adjustment.ref.id) ? null : adjustment);
                        }}
                      />
                    </TableCell>
                    {withCustomer ? null : (
                      <TableCell component="th" scope="row" padding="none">
                        <Link
                          component={RouterLink}
                          to={`/customers/${adjustment.data.customer.id}`}
                          variant="body2"
                          color="inherit"
                          className={classes.link}
                        >
                          {adjustment.data.metadata.customer.primaryDomain}
                        </Link>
                      </TableCell>
                    )}
                    <Hide smDown>
                      <TableCell padding="none">{assetTypeName(adjustment.data.type)}</TableCell>
                      <TableCell padding="none">{adjustment.data.description}</TableCell>
                      <TableCell padding="none">{adjustment.data.details}</TableCell>
                      <TableCell padding="none">{adjustment.data.startDate.toFormat(dateFormatMonthAndYear)}</TableCell>
                      <TableCell padding="none">{adjustment.data.endDate.toFormat(dateFormatMonthAndYear)}</TableCell>
                    </Hide>
                    <TableCell align="right" padding="normal">
                      {formatCurrency(adjustment.data.amount, adjustment.data.currency, 2)}
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
        <TableFooter>
          {filteredAdjustments.length > 0 && (
            <TableRow>
              <TablePagination
                count={filteredAdjustments.length}
                rowsPerPage={tableState.rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={tableState.page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={(_event, page) => {
                  setTableState((tableState) => ({
                    ...tableState,
                    page,
                  }));
                }}
                onRowsPerPageChange={(event) => {
                  setTableState((tableState) => ({
                    ...tableState,
                    page: 0,
                    rowsPerPage: parseInt(event.target.value),
                  }));
                }}
                labelRowsPerPage="per page"
              />
            </TableRow>
          )}
        </TableFooter>
      </Table>
      {filteredAdjustments.length <= 0 && !loading && (
        <Toolbar>
          <Typography variant="body2">No invoice adjustments were found.</Typography>
        </Toolbar>
      )}
    </FilterContextProvider>
  );
};

export default AdjustmentsTab;
