import { useCallback, useEffect, useMemo, useState } from "react";

export const useRowPerPage = ({ totalRows, widgetHeight }: { totalRows: number; widgetHeight: number }) => {
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [page, setPage] = useState(0);
  const emptyRows = useMemo(
    () => rowsPerPage - Math.min(rowsPerPage, totalRows - page * rowsPerPage),
    [page, rowsPerPage, totalRows]
  );

  const handleChangePage = useCallback((_, newPage: number) => {
    setPage(newPage);
  }, []);

  useEffect(() => {
    switch (widgetHeight) {
      case 2: {
        setRowsPerPage(9);
        break;
      }
      case 1.5: {
        setRowsPerPage(6);
        break;
      }
      case 1:
      default: {
        setRowsPerPage(3);
      }
    }
  }, [widgetHeight]);

  return { emptyRows, rowsPerPage, page, handleChangePage };
};
