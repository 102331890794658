import { useCallback, useEffect, useState } from "react";

import { useCustomerContext } from "../../../Context/CustomerContext";
import useGenerateReport from "../../../Pages/CloudAnalytics/generateReport";
import { type CardID } from "./types";
import { getCloudSummaryLink } from "./utils";

type CloudSummaryReportLinks = {
  doneLoading: boolean;
  loadLinks: (cardId: CardID) => Promise<{ [x: number]: string }>;
};

export const useCloudSummaryReportLinks = (): CloudSummaryReportLinks => {
  const [doneLoading, setDoneLoading] = useState<boolean>(false);
  const { customer } = useCustomerContext();
  const generateReport = useGenerateReport();

  const loadLinks = useCallback(
    async (cardId: CardID) => {
      const cardData = await getCloudSummaryLink(generateReport, customer.id, cardId);
      return {
        [cardId]: cardData,
      };
    },
    [customer.id, generateReport]
  );

  useEffect(() => {
    const t = setTimeout(() => {
      setDoneLoading(true);
    }, 1000);

    return () => {
      clearTimeout(t);
    };
  }, []);

  return { doneLoading, loadLinks };
};
