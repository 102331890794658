import { Chip } from "@mui/material";

import { getJiraStatusColors } from "./utils";

export const JiraStatusChip = ({ status, statusCategory }) => {
  const { backgroundColor, textColor } = getJiraStatusColors(statusCategory);

  return (
    <Chip
      sx={{
        borderRadius: 1,
        textAlign: "center",
        color: textColor,
        backgroundColor,
        fontWeight: 700,
        fontSize: 12,
        height: "auto",
        ".MuiChip-label": {
          padding: 0,
        },
        paddingY: 0.25,
        paddingX: 0.75,
      }}
      label={status.toUpperCase()}
    />
  );
};
