import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useClipboard } from "use-clipboard-copy";

type Props = {
  onClick?: () => void;
  codeToCopy: string;
  handleCopyAllCommandsClick: () => void;
  emptyVariables: string[];
  showError?: boolean;
};

export const RunGCPCommandsExplanation = ({
  onClick,
  codeToCopy,
  emptyVariables,
  handleCopyAllCommandsClick,
  showError,
}: Props) => {
  const clipboard = useClipboard({
    copiedTimeout: 1400,
  });

  const handleCopy = (event) => {
    event.stopPropagation();
    if (emptyVariables.length === 0) {
      clipboard.copy(codeToCopy);
    }
    handleCopyAllCommandsClick();
  };

  return (
    <Stack spacing={2} pb={1}>
      <Typography variant="subtitle1" fontWeight="500">
        3. Run these gcloud commands in Google Cloud Shell
      </Typography>
      <Stack direction="row" spacing={3} pb={2}>
        <Button
          variant="outlined"
          sx={{ alignSelf: "flex-start" }}
          startIcon={clipboard.copied ? <CheckIcon color="primary" sx={{ minWidth: 150 }} /> : <ContentCopyIcon />}
          onClick={handleCopy}
        >
          {clipboard.copied ? "" : "Copy all commands"}
        </Button>
        <Button
          variant="outlined"
          sx={{ alignSelf: "flex-start" }}
          startIcon={<OpenNewIcon />}
          onClick={onClick}
          href="https://shell.cloud.google.com/cloudshell/editor"
          target="_blank"
        >
          Open Google Cloud Shell
        </Button>
      </Stack>
      {showError && emptyVariables.length > 0 && (
        <Alert severity="error">Fill in your {emptyVariables.join(", ")}, before running the code</Alert>
      )}
    </Stack>
  );
};
