import { DateTime } from "luxon";

function remainDaysForTrial(trialEndDate: DateTime) {
  const trialEndDay = DateTime.fromObject({
    year: trialEndDate.year,
    month: trialEndDate.month,
    day: trialEndDate.day,
  });
  const today = DateTime.fromObject({
    year: DateTime.now().year,
    month: DateTime.now().month,
    day: DateTime.now().day,
  });
  const diff = trialEndDay.diff(today, ["days"]);
  return diff.days;
}

export function trialEndText(trialEndDate: DateTime, isShortText: boolean) {
  const remainingDaysInTrial = remainDaysForTrial(trialEndDate);

  if (remainingDaysInTrial > 1) {
    return isShortText ? `${remainingDaysInTrial} days left` : `Your trial will expire in ${remainingDaysInTrial} days`;
  }
  switch (remainingDaysInTrial) {
    case 0:
      return isShortText ? "Last day!" : "Your access to test-drive will expire today";
    case 1:
      return isShortText ? "One day left" : "Your access to test-drive will expire tomorrow";
    default:
      return isShortText ? "Trial expired" : "Your trial period has expired";
  }
}
