import { helpURLs } from "../../../../assets/urls";

export type UpdateAccountError = {
  message: string;
  learnMoreUrl?: string;
};

export const updateAccountAllErrors: Record<string, UpdateAccountError> = {
  already_exists: {
    message: "This account was already configured.",
  },
  invalid_external_id: {
    message: "Incorrect IAM Role External ID.",
  },
  not_in_assets: {
    message: "This account is not yet consolidated with DoiT International.",
    learnMoreUrl: helpURLs.INVITE_AWS_ACCOUNT,
  },
  invalid_arn: {
    message: "Invalid ARN.",
  },
  unauthorized: {
    message: "Unauthorized request.",
  },
};
