import { ComparativeFeature } from "@doitintl/cmp-models";

export const getStackedColumnChartOptions = (theme, reversed, formatter, comparative = ComparativeFeature.NONE) => ({
  chart: {
    type: "column",
    zoomType: "xy",
  },
  credits: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: true,
    maxHeight: 66,
    itemMarginTop: 4,
  },
  tooltip: {
    valueDecimals: 2,
  },
  yAxis: {
    title: {
      enabled: false,
    },
    stackLabels: {
      enabled: true,
      verticalAlign: reversed ? "top" : undefined,
      y: reversed ? -28 : 0,
      style: {
        ...theme.typography.caption,
        color: theme.palette.text.primary,
        textOutline: 0,
      },
      formatter() {
        return formatter(this.total, true, comparative);
      },
    },
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  plotOptions: {
    column: {
      stacking: "normal",
      borderWidth: 0,
      pointWidth: null,
      maxPointWidth: 50,
      groupPadding: 0.05,
      pointPadding: 0.025,
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [],
});
