import { Stack, Typography } from "@mui/material";

const Permission = ({ text }: { text: string }) => (
  <Typography fontFamily="monospace" pl={2} color="text.secondary">
    • {text}
  </Typography>
);

export default function SnowflakeStepPermissions() {
  return (
    <Stack spacing={4} pb={5} data-cy="step-permissions">
      <Stack spacing={4}>
        <Typography variant="h1">Requirements for connecting your Snowflake account</Typography>
        <Typography variant="body1">
          When you set up the connection, you'll run a script we'll provide for you in your Snowflake account. This
          script creates the following in your account:
        </Typography>
        <Stack spacing={2}>
          <Typography variant="body1">- A DoiT role with these permissions:</Typography>
          <Stack spacing={1}>
            <Permission text="ORGANIZATION_USAGE_VIEWER" />
            <Permission text="GOVERNANCE_VIEWER" />
            <Permission text="ORGANIZATION_BILLING_VIEWER" />
            <Permission text={"USAGE_VIEWER"} />
          </Stack>
          <Typography variant="body1">- A DoiT user with the DoiT role above</Typography>
          <Typography variant="body1">- A DoiT warehouse</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
