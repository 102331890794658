import { useCallback } from "react";

import { useHistory } from "react-router-dom";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { customersRoute } from "../../../Navigation/core/utils";
import AWSStepper from "./AWS/AWSStepper";

const SaaSOnboardingAWS = () => {
  const history = useHistory();
  const customerId = useCustomerId();

  const goBack = useCallback(() => {
    history.push(customersRoute(customerId));
  }, [history, customerId]);

  return <AWSStepper handleBack={goBack} />;
};

export default SaaSOnboardingAWS;
