import { AwsMarketplace } from "./AWS/AwsMarketplace";
import GcpMarketplace from "./GCP/GCPMarketplace";

const Marketplace = ({ pageId }) => (
  <>
    <GcpMarketplace pageId={pageId} />
    <AwsMarketplace pageId={pageId} />
  </>
);

export default Marketplace;
