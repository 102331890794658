import { useEffect, useState } from "react";

import { DoitRole, GCPMarketplaceAdjustmentStatus } from "@doitintl/cmp-models";
import { Checkbox, DialogContent, FormControlLabel, Grid, InputAdornment, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { marketplaceText } from "../../../../assets/texts";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import NumericTextField from "../../../../Components/NumericTextField/NumericTextField";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../../Components/SimpleDialog";
import { useAuthContext } from "../../../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { getDateTimeFromFirestoreTimestamp } from "../../../../utils/common";
import { type FirestoreTimestamp, serverTimestamp, TimestampFromDate } from "../../../../utils/firebase";
import { CustomerAutocomplete } from "../components/CustomerAutocomplete";
import { ProcurementBillingAccountAutocomplete } from "../components/ProcurementBillingAccountAutocomplete";
import { type AccountConfig } from "../hooks/useMarketplaceAccounts";
import { type AdjustmentConfig } from "../hooks/useMarketplaceAdjustments";
import { type CustomerWithRef } from "../hooks/useMarketplaceCustomers";
import { useMarketplaceContext } from "../MarketplaceContext";
import { createAdjustments } from "./utils";

export type Props = {
  onClose: () => void;
  clickedRow: AdjustmentConfig | null;
};

export const CreateEditDialog = ({ clickedRow, onClose }: Props) => {
  const dateTomorrow = DateTime.local().plus({ days: 1, hours: 1 }).startOf("hour");

  const [customer, setCustomer] = useState<CustomerWithRef | null>(clickedRow?.customer ?? null);
  const { accounts } = useMarketplaceContext();
  const accountConfig = accounts?.find((account) => account.ref.id === clickedRow?.data.procurementAccount?.id);
  const [procurementAccount, setProcurementAccount] = useState<AccountConfig | null>(accountConfig ?? null);
  const [disableConfirm, setDisableConfirm] = useState<boolean>();
  const [value, setValue] = useState<string>(clickedRow ? (Math.round(clickedRow.data.value) / 100).toString() : "");
  const [periods, setPeriods] = useState<string>("1");
  const [showTransactionFee, setShowTransactionFee] = useState<boolean>(
    clickedRow ? clickedRow.data.transactionFee > 0 : false
  );
  const [description, setDescription] = useState<string>(clickedRow?.data.description ?? "");
  const [targetTime, setTargetTime] = useState<DateTime | null>(
    clickedRow ? getDateTimeFromFirestoreTimestamp(clickedRow?.data.targetTime) : dateTomorrow
  );
  const [transactionFee, setTransactionFee] = useState<string>(
    clickedRow ? clickedRow.data.transactionFee?.toString() : "0"
  );
  const { currentUser } = useAuthContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const editMode = !!clickedRow;
  const isDoitDeveloper = useDoitRoleCheck(DoitRole.Developers);

  const intValue = Math.round(parseFloat(value) * 100);

  const handleChangeChecked = (event) => {
    setShowTransactionFee(event.target.checked);
  };

  useEffect(() => {
    setDisableConfirm(
      Number(value) === 0 ||
        periods === "" ||
        !targetTime ||
        isNaN(targetTime.millisecond) ||
        targetTime.toJSDate() < dateTomorrow.toJSDate() ||
        !currentUser ||
        !customer?.ref ||
        (!editMode && !procurementAccount) ||
        (showTransactionFee && (transactionFee === "." || Number(transactionFee) === 0))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    targetTime,
    currentUser,
    customer,
    periods,
    showTransactionFee,
    transactionFee,
    procurementAccount,
    editMode,
  ]);

  const handleSubmit = async () => {
    if (
      !targetTime ||
      !currentUser ||
      !customer?.ref ||
      !procurementAccount?.ref ||
      !accounts ||
      !procurementAccount.data.billingAccountId
    ) {
      return;
    }

    try {
      if (editMode) {
        await clickedRow?.ref.update({
          targetTime: targetTime.toJSDate(),
          value: intValue,
          timeUpdated: serverTimestamp(),
        });
      } else {
        const adjustment = {
          createdBy: currentUser.email,
          customer: customer.ref,
          value: intValue,
          timeCreated: serverTimestamp() as FirestoreTimestamp,
          timeUpdated: serverTimestamp() as FirestoreTimestamp,
          targetTime: TimestampFromDate(targetTime.toJSDate()),
          timeReported: null,
          operationId: null,
          description: showTransactionFee ? description : "",
          transactionFee: showTransactionFee ? Number(transactionFee) : 0,
          procurementAccount: procurementAccount.ref,
          status: GCPMarketplaceAdjustmentStatus.PENDING,
        };
        await createAdjustments(
          adjustment,
          Number(periods),
          customer,
          currentUser.uid,
          showTransactionFee,
          procurementAccount.data.billingAccountId
        );
      }
      successSnackbar(
        editMode ? marketplaceText.ADJUSTMENT_EDITED_SUCCESSFULLY : marketplaceText.ADJUSTMENT_CREATED_SUCCESSFULLY
      );
      onClose();
    } catch (e) {
      consoleErrorWithSentry(e);
      errorSnackbar(editMode ? marketplaceText.ADJUSTMENT_EDIT_FAILED : marketplaceText.ADJUSTMENT_CREATE_FAILED);
    }
  };

  const filterCostAnomalyCustomer = (customer: CustomerWithRef) => !!customer.costAnomalyEnabled;

  return (
    <SimpleDialog
      title={editMode ? marketplaceText.EDIT_ADJUSTMENT : marketplaceText.CREATE_ADJUSTMENT}
      onConfirm={handleSubmit}
      onCancel={() => {
        onClose();
      }}
      confirmButtonText={editMode ? marketplaceText.EDIT_ADJUSTMENT : marketplaceText.CREATE_ADJUSTMENT}
      disableConfirm={disableConfirm}
      open
    >
      <DialogContent sx={{ width: 600 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: "1rem", mt: "1rem" }}>
            <CustomerAutocomplete
              handleSelectOption={setCustomer}
              selectedCustomer={customer}
              disabled={editMode}
              optionFilter={filterCostAnomalyCustomer}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: "1.5rem" }}>
            <ProcurementBillingAccountAutocomplete
              customer={customer}
              setProcurementAccount={setProcurementAccount}
              procurementAccount={procurementAccount}
              disabled={editMode || !customer}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: "1.5rem" }}>
            <NumericTextField
              label={marketplaceText.AMOUNT}
              value={value}
              setValue={setValue}
              allowedInputs={{
                decimalLimit: 2,
                allowNegative: false,
                allowScientificNotation: false,
              }}
              textFieldProps={{
                size: "medium",
                helperText: Number(value) === 0 && value !== "" ? marketplaceText.MUST_BE_BIGGER_THAN_ZERO : "",
                required: true,
                InputProps: {
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: "1.5rem" }}>
            <DateTimePicker
              label={marketplaceText.TARGET_TIME}
              renderInput={(props) => <TextField size="medium" required fullWidth {...props} />}
              onChange={(date) => {
                setTargetTime(date);
              }}
              minDateTime={dateTomorrow}
              value={targetTime}
              views={["day", "hours"]}
            />
          </Grid>
          {!editMode && (
            <>
              <NumericTextField
                label={marketplaceText.PERIODS}
                value={periods}
                setValue={setPeriods}
                allowedInputs={{
                  minValue: 1,
                  maxValue: 12,
                  allowDecimal: false,
                  allowScientificNotation: false,
                }}
                textFieldProps={{
                  size: "medium",
                  helperText: marketplaceText.PERIODS_NUMBER,
                  required: true,
                }}
              />
              <Grid item xs={12} sx={{ mb: "1.5rem" }}>
                <Grid item>
                  <FormControlLabel
                    label={marketplaceText.CREATE_INVOICE_ADJUSTMENTS}
                    control={
                      <Checkbox
                        checked={showTransactionFee}
                        onChange={handleChangeChecked}
                        disabled={!isDoitDeveloper}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {showTransactionFee && (
                <>
                  <Grid item xs={12} sx={{ mb: "1.5rem" }}>
                    <TextField
                      size="medium"
                      required
                      label={marketplaceText.DESCRIPTION}
                      value={description}
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mb: "1.5rem" }}>
                    <NumericTextField
                      label={marketplaceText.TRANSACTION_FEE}
                      value={transactionFee}
                      setValue={setTransactionFee}
                      allowedInputs={{
                        minValue: 0,
                        maxValue: 100,
                        allowNegative: false,
                        allowScientificNotation: false,
                      }}
                      textFieldProps={{
                        size: "medium",
                        InputProps: {
                          startAdornment: <InputAdornment position="start">%</InputAdornment>,
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
    </SimpleDialog>
  );
};

export default CreateEditDialog;
