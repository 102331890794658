import { deepOrange } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: "48px",
  },
  iconAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    fontSize: "48px",
  },
  viewAs: {
    display: "flex",
    justifyContent: "center",
    marginTop: -20,
    marginBottom: 25,
  },
}));

export const ProfileHeader = styled.div`
  font-size: 1.5em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 1.5em;
  overflow: hidden;
  text-wrap: none;
  text-overflow: ellipsis;
`;
