import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

type usePricebooksProps = {
  contractId: string;
};

const usePricebookAssignments = ({ contractId }: usePricebooksProps) => {
  const query = getCollection(IntegrationModel)
    .doc("cloudhealth")
    .collection("pricebooksAssignments")
    .where("contractId", "==", contractId);

  const [pricebookAssignments, pricebookAssignmentsLoading] = useCollectionData(query);

  return { pricebookAssignments: pricebookAssignments ?? [], pricebookAssignmentsLoading };
};

export default usePricebookAssignments;
