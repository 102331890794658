export const metricSplittingTxt = {
  SPLIT: "Split",
  RECALCULATE_BTN_LABEL: "Recalculate values",
  ITEM_TO_SPLIT: {
    TITLE: "Item to split",
    DESCRIPTION: "Select an item to split among the others",
    PLACEHOLDER: "Select item",
  },
  SPLIT_COST_TARGETS: {
    TITLE: "Split between",
    DESCRIPTION: {
      SPLIT: "Split ",
      BETWEEN: " between",
    },
    INPUT_PLACEHOLDER: "Type to filter",
  },
  SPLIT_ITEM: " split item",
  SPLIT_ITEMS: " split items",
  SPLIT_MENU_SPLIT_NEW: "Split new",
  ORIGIN_BTN: {
    VIEW: "View",
    HIDE: "Hide",
    TITLE: " detailed breakdown",
  },
};

export const metricSplittingDataCy = {
  MODAL: "split-cost-modal",
  ITEMS_AUTOCOMPLETE: "split-cost-items-autocomplete",
  TITLE: "split-title",
  TARGET_CHECK_BTN: "split-target-check-btn",
  PERCENTAGE: "split-target-percentage",
  PERCENTAGE_INPUT: "split-target-percentage-input",
  SELECT_ALL: "split-target-select-all-btn",
  SPLIT_MODE: "split-mode",
};
