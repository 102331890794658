import { type ContractModel } from "@doitintl/cmp-models";

export const getCommitmentTypeText = (contract: ContractModel): string => {
  if (contract.isCommitment) {
    return "Hard commit";
  }
  if (contract.isSoftCommitment) {
    return "Soft commit";
  }
  return "No";
};
