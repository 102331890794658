import { useCallback, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { labelsText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useDeleteLabel } from "./hooks";

export interface LabelDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  labelId: string;
}

const LabelDeleteDialog = ({ open, onClose, labelId }: LabelDeleteDialogProps) => {
  const deleteLabel = useDeleteLabel();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const [loading, setLoading] = useState(false);

  const onDelete = useCallback(async () => {
    setLoading(true);
    await deleteLabel(
      labelId,
      () => {
        successSnackbar(labelsText.DELETE_LABEL_SUCCESS);
        onClose();
      },
      () => {
        errorSnackbar(labelsText.DELETE_LABEL_ERROR);
      }
    );
    setLoading(false);
  }, [deleteLabel, errorSnackbar, labelId, onClose, successSnackbar]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>Delete label</DialogTitle>
      <DialogContent>This label will be permanently deleted.</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={onDelete}
          loading={loading}
          mixpanelEventId="analytics.labels.delete"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default LabelDeleteDialog;
