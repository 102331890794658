import { useCallback } from "react";

import axios from "axios";

import { useApiContext } from "../../../api/context";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { type AwsAccountBatchCreationRequest } from "./aws-account-api";

export function useAwsAccountBatchRequest() {
  const { post } = useApiContext();
  const snackbar = useSnackbar();

  return useCallback(
    async (batchRequest: AwsAccountBatchCreationRequest) => {
      try {
        await post("/v1/amazon-web-services/generated-accounts/batch-create", batchRequest);

        snackbar.onOpen({
          message: "Batch successfully scheduled.",
          variant: "success",
          autoHideDuration: 15000,
          withClose: true,
        });
      } catch (error) {
        consoleErrorWithSentry(error);

        let message = "Unexpected error occurred.";
        if (axios.isAxiosError(error) && error.response?.data?.error) {
          message = error.response?.data?.error;
        } else if (error instanceof Error) {
          message = error.message;
        }

        snackbar.onOpen({
          message,
          variant: "error",
          autoHideDuration: 15000,
          withClose: true,
        });
      }
    },
    [post, snackbar]
  );
}
