import { useLocation } from "react-router-dom";
import { Box, Container, Grid, Stack } from "@mui/material";

import RandomLoadingMessage from "../../Components/RandomLoadingMessage";
import { useFullScreen } from "../../utils/dialog";
import { AllAuthForms } from "./components/AllAuthForms";
import FormSwitch from "./components/FormSwitch";
import LoginLogo from "./components/LoginLogo";
import { paths } from "./helpers";
import { useDynamicContent, useHandleEmailVerificationStatusFromQuery, useLoginForm } from "./hooks";
import { type LoginType } from "./types";

const Login = ({ loginType }: { loginType?: LoginType }) => {
  const location = useLocation();
  const { state, dispatch } = useLoginForm({ loginType });
  const content = useDynamicContent({ loginType });
  const { isMobile } = useFullScreen();

  useHandleEmailVerificationStatusFromQuery();

  if (location?.pathname === paths.loginImpersonate) {
    return <RandomLoadingMessage />;
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1} sx={{ height: "100vh" }}>
      <Grid item sm={12} md={4} sx={{ height: "100vh", minWidth: { xs: "100%", sm: "auto" } }}>
        <Stack justifyContent="space-between" alignItems="center" spacing={2} height="100%">
          <Box sx={{ pt: { xs: 2, sm: 3 }, pl: { xs: 2, sm: 3 }, alignSelf: "flex-start" }}>
            <LoginLogo />
          </Box>
          <Container maxWidth="xs">
            <AllAuthForms state={state} dispatch={dispatch} />
          </Container>
          <Container maxWidth="xs">
            <FormSwitch state={state} dispatch={dispatch} />
          </Container>
        </Stack>
      </Grid>
      {!isMobile && (
        <Grid item xs={8}>
          {content}
        </Grid>
      )}
    </Grid>
  );
};

export default Login;
