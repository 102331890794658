import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { consoleErrorWithSentry } from "../../utils";

// notify Segment analytics with page api call on route/path change
export function SegmentPager() {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    try {
      if (prevLocation !== location.pathname) {
        setPrevLocation(location.pathname);
        globalThis.analytics.page();
      }
    } catch (e) {
      consoleErrorWithSentry(e);
    }
  }, [location.pathname, prevLocation]);

  return null;
}
