import { type Onboarding } from "@doitintl/cmp-models";

import { type PackageOptions } from "./type";

export const isPackageOptionSelected = (packageOptions: PackageOptions) => {
  const { advantage, navigator, solve } = packageOptions;
  return advantage.length || navigator.length || solve.length;
};

export const transformOnboardingData = (packageOptions: PackageOptions) => {
  const { advantage, navigator, solve } = packageOptions;

  const transformedData: Onboarding = {};

  if (advantage.length) {
    transformedData.advantage = { providers: advantage };
  }

  if (navigator.length) {
    transformedData.navigator = { providers: navigator };
  }

  if (solve.length) {
    transformedData.solve = { providers: solve };
  }

  return transformedData;
};
