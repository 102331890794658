import { Fragment, useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Stack, TableCell, Typography } from "@mui/material";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import Hide from "../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { type MetricWSnap } from "../../../types";
import { type LabelWithRef } from "../labels/types";
import { useThreeDotsMetricsMenu } from "./hooks";

const { metrics: metricTestIds } = cloudAnalytics;

type MetricRowProps = {
  row: MetricWSnap;
  labels: LabelWithRef[] | undefined;
};

export const MetricRow = ({ row, labels }: MetricRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const threeDotsMenu = useThreeDotsMetricsMenu({ row });
  const rowLabels = useMemo(
    () => labels?.filter((l) => row.data.labels?.some((rowLabel) => l.ref.id === rowLabel.id)) ?? [],
    [labels, row.data.labels]
  );

  const formattedTimeModified = useMemo(() => {
    const date = DateTime.fromJSDate(row.data?.timeModified?.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
    return "";
  }, [row.data.timeModified]);

  return (
    <>
      <TableCell>
        <Stack spacing={1}>
          <Typography
            component={RouterLink}
            to={{
              pathname: `${routeMatchURL}/${row.snapshot.id}`,
              state: {
                prevPage: routeMatchURL, // or any other value you want to pass as state
              },
            }}
            color="inherit"
            variant="body2"
            data-cy={metricTestIds.browser.metricName}
          >
            {row.data.name}
          </Typography>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy={metricTestIds.browser.metricOwner}>{row.data.owner}</TableCell>
        <TableCell data-cy={metricTestIds.browser.metricDescription}>{row?.data?.description}</TableCell>
        <TableCell data-cy={metricTestIds.browser.metricType}>{capitalize(row.data.type)}</TableCell>
        <TableCell data-cy={metricTestIds.browser.metricLastModified}>{formattedTimeModified}</TableCell>
        <TableCell data-cy={metricTestIds.browser.metricThreeDotsMenu} padding="none">
          {threeDotsMenu}
        </TableCell>
      </Hide>
    </>
  );
};
