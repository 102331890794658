import { type HeaderColumn } from "../../../../../../types/FilterTable";

export const customerSavingsPlansColumns: HeaderColumn[] = [
  { value: "savingsPlanID", label: "Savings Plan ID" },
  { value: "payment", label: "Payment" },
  { value: "type", label: "Type" },
  { value: "term", label: "Term" },
  { value: "commitment", label: "Commitment" },
  { value: "mtdSavings", label: "MTD Savings" },
  { value: "expirationDate", label: "Expiration date" },
];
