export const cloudAnalytics = {
  report: {
    browser: {
      tableSkeleton: "report-table-skeleton",
      delete: "reports-delete",
      explore: "reports-explore",
      reportName: "report-name",
      reportOwner: "report-owner",
      reportType: "report-type",
      reportLastModified: "report-last-modified",
      reportOptions: "report-options",
    },
    gke: {
      alert: "report-gke-alert",
    },
  },
  attributions: {
    browser: {
      tableSkeleton: "attribution-table-skeleton",
      delete: "attributions-delete",
      newAttribution: "new-attribution",
      explore: "attributions-explore",
      attributionName: "attribution-name",
      attributionOwner: "attribution-owner",
      attributionDescription: "attribution-description",
      attributionType: "attribution-type",
      attributionLastModified: "attribution-last-modified",
      attributionOptions: "attribution-options",
    },
    gke: {
      alert: "attribution-gke-alert",
    },
  },
  metrics: {
    browser: {
      tableSkeleton: "metric-table-skeleton",
      delete: "metrics-delete",
      newMetric: "new-metric",
      explore: "metrics-explore",
      metricName: "metric-name",
      metricOwner: "metric-owner",
      metricDescription: "metric-description",
      metricType: "metric-type",
      metricLastModified: "metric-last-modified",
      metricOptions: "metric-options",
      metricThreeDotsMenu: "metric-three-dots-menu",
    },
    gke: {
      alert: "metric-gke-alert",
    },
    metric: {
      formula: "metric-formula",
      baseMetric: "base-metric",
      attribution: "metric-attribution",
    },
  },
  budgets: {
    browser: {
      tableSkeleton: "budget-table-skeleton",
      delete: "budget-delete",
      newBudget: "new-budget",
      explore: "budget-explore",
      budgetName: "budget-name",
      budgetOwner: "budget-owner",
      budgetDescription: "budget-description",
      budgetType: "budget-type",
      budgetLastModified: "budget-last-modified",
      budgetAmount: "budget-amount",
      budgetPeriod: "budget-period",
      budgetPercent: "budget-percent",
      budgetMaxUtilization: "budget-max-utilization",
      budgetOptions: "budget-options",
    },
    gke: {
      alert: "budget-gke-alert",
    },
  },
};

export const flexsave = {
  form: {
    cancelButton: "flexsave-form-cancel-button",
    disableButton: "flexsave-form-disable-button",
    approveButton: "flexsave-form-approve-button",
    discountAmount: "flexsave-form-discount-amount",
    discountDate: "flexsave-form-discount-date",
    minSpend: "flexsave-form-min-spend",
    maxSpend: "flexsave-form-max-spend",
  },
};

export const supportPlatformDropdown = {
  root: "supportPlatformDropdown",
  contents: {
    headers: {
      alsoSupported: "alsoSupportedSectionHeader",
      customerServices: "customerServicesSectionHeader",
    },
    items: {
      cloud_management_platform: "item_cloud_management_platform",
      google_cloud_platform: "item_google_cloud_platform",
      amazon_web_services: "item_amazon_web_services",
      microsoft_azure: "item_microsoft_azure",
      google_g_suite: "item_google_g_suite",
      microsoft_office_365: "item_microsoft_office_365",
      finance___billing: "item_finance___billing",
      credits___request: "item_credits___request",
    },
    icons: {
      cmp: "cmp-icon",
      gcp: "google-cloud-icon",
      aws: "aws-icon",
      azure: "microsoft-azure-icon",
      gSuite: "google-icon",
      ms365: "ms365-icon",
      finance: "finance-billing-icon",
      credits: "promo-credits-icon",
    },
  },
};

export const misc = {
  enhancedTableFilter: "table-filter",
  percentage: "percentage",
};

export const header = {
  avatar: "avatar",
  companyName: "company-name",
};
