import { useEffect, useState } from "react";

import { CollectionModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import orderBy from "lodash/orderBy";
import { DateTime } from "luxon";

import { type ChartGraphData } from "./types";

export const useCashGraphData = () => {
  const [cashGraphData, setCashGraphData] = useState<ChartGraphData>();

  useEffect(() => {
    const now = DateTime.utc();
    const nowString = now.toFormat("yyyy-MM-dd");
    const startDate = now.minus({ months: 6 });
    const endDate = now.plus({ months: 2 });
    getCollection(CollectionModel)
      .doc("account-receivables")
      .collection("dailyAccountReceivables")
      .where("month", ">=", startDate.toJSDate())
      .where("month", "<", endDate.toJSDate())
      .get()
      .then((cashAnalyticsGraph) => {
        const data: ChartGraphData = {
          receivables: [],
          expected: [],
        };

        cashAnalyticsGraph.forEach((item) => {
          const itemData = item.asModelData();
          const receivables = itemData.receivables;
          if (receivables) {
            Object.keys(receivables).forEach((keyDay) => {
              data.receivables.push({
                x: keyDay,
                y: receivables[keyDay],
              });
            });
          }
          const receivablesExpected = itemData.receivablesExpected;
          if (receivablesExpected) {
            Object.keys(receivablesExpected).forEach((keyDay) => {
              if (keyDay >= nowString) {
                data.expected.push({
                  x: keyDay,
                  y: receivablesExpected[keyDay],
                });
              }
            });
          }
        });

        for (const k in data) {
          data[k] = orderBy(data[k], ["x"], ["asc"]);
        }

        setCashGraphData(data);
      });
  }, []);

  return cashGraphData;
};
