import { useCallback, useEffect } from "react";

import { useApiContext } from "../../../api/context";

export function useCloudConnectHealthCheck(customerId: string) {
  const api = useApiContext();
  const checkHealth = useCallback(async () => {
    await api.request({
      method: "get",
      url: `/v1/customers/${customerId}/cloudconnect/health`,
    });
  }, [api, customerId]);

  useEffect(() => {
    checkHealth();
  }, [checkHealth]);

  return checkHealth;
}
