import { useCallback, useState } from "react";

import { EntityModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

import { type Entity } from "../Context/customer/EntitiesContext";
import { preventOnCloseWhile } from "../utils/dialog";
import LoadingButton from "./LoadingButton";

type Props = {
  entity: Entity;
  onClose: () => void;
};

const EntityDetachDialog = ({ entity, onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = useCallback(async () => {
    setLoading(true);
    await getCollection(EntityModel).doc(entity.id).delete();
    setLoading(false);
  }, [entity.id]);

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      onClose={preventOnCloseWhile(loading, onClose)}
      data-cy="detachEntityDialog"
    >
      <DialogTitle id="form-dialog-title">Are you sure you want to detach this entity?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Detaching the entity removes it from the customer but does not have any affect on Priority
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={handleRemove}
          disabled={loading}
          loading={loading}
          mixpanelEventId="entity.detach"
        >
          Detach
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EntityDetachDialog;
