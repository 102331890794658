import { useEffect, useState } from "react";

import PhoneInput from "react-phone-input-2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import startsWith from "lodash/startsWith";
import { string as YupString } from "yup";

import SuccessIcon from "../../../assets/SuccessIcon.svg";
import { trialContactUsDialogTexts as text } from "../../../assets/texts";
import { useUserContext } from "../../../Context/UserContext";
import { ThemeModes } from "../../../muiThemeTypes";
import { useFullScreen } from "../../../utils/dialog";
import { SegmentEvents, segmentTrack } from "../../../utils/segment";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    padding: theme.spacing(3, 3, 1, 3),
  },
  subtitle: {
    textAlign: "center",
    padding: theme.spacing(0, 3),
  },
  email: {
    marginTop: theme.spacing(4),
  },
  namesLine: {
    display: "flex",
    padding: theme.spacing(1, 0),
  },
  flex: {
    flexGrow: 1,
  },
  firstName: {
    width: theme.spacing(35),
  },
  lastName: {
    width: theme.spacing(35),
    marginLeft: theme.spacing(1),
  },
  name: {
    width: "100%",
  },
  actions: {
    margin: theme.spacing(1, 2, 2, 2),
  },
  noCancelActions: {
    textAlign: "center",
    display: "block",
    marginBottom: theme.spacing(2),
  },
  icon: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const TrialContactUsDialog = ({ open, onConfirm, onCancel }: Props) => {
  const { userModel: user } = useUserContext({ allowNull: false });
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValid, setFormValid] = useState(true);

  const theme = useTheme();
  const classes = useStyles();

  const { isMobile } = useFullScreen("sm");

  const handleSubmit = async () => {
    segmentTrack(SegmentEvents.TalkToSales, {
      email: user.email,
      firstName,
      lastName,
      phone,
    });
    setFormSubmitted(true);
  };

  useEffect(() => {
    if (open) {
      setFormSubmitted(false);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onCancel} fullScreen={isMobile}>
      {formSubmitted && (
        <div className={classes.icon}>
          <img src={SuccessIcon} alt="Success" />
        </div>
      )}
      <DialogTitle className={classes.title}>{!formSubmitted ? text.TITLE : text.CONFIRM_SUBMIT_TITLE}</DialogTitle>
      {!formSubmitted ? (
        <>
          {text.SUB_TITLE.map((st) => (
            <Typography key={st} variant="subtitle1" className={classes.subtitle}>
              {st}
            </Typography>
          ))}
        </>
      ) : (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {text.CONFIRM_SUBMIT_SUB_TITLE}
        </Typography>
      )}
      <DialogContent>
        {!formSubmitted && (
          <>
            <TextField
              name="email"
              variant="outlined"
              margin="dense"
              label={text.EMAIL}
              type="text"
              value={email}
              className={classes.email}
              onChange={async (event) => {
                const newValue = event.target.value;
                setEmail(newValue);
                try {
                  await YupString().required().email().validate(newValue);
                  setFormValid(true);
                } catch {
                  setFormValid(false);
                }
              }}
              fullWidth
            />
            <div className={isMobile ? "" : classes.namesLine}>
              <TextField
                name="firstlName"
                variant="outlined"
                margin="dense"
                label={text.FIRST_NAME_LABEL}
                type="text"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                className={isMobile ? classes.name : classes.firstName}
              />
              <TextField
                name="lastName"
                variant="outlined"
                margin="dense"
                label={text.LAST_NAME_LABEL}
                type="text"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                className={isMobile ? classes.name : classes.lastName}
              />
            </div>
            <PhoneInput
              value={phone ?? ""}
              onChange={(value) => {
                setPhone(value);
              }}
              isValid={(inputNumber: string, country: any, countries: any[]) => {
                const valid = countries.some(
                  (country) => startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber)
                );
                setFormValid(valid);
                return valid;
              }}
              containerStyle={{
                marginTop: 8,
                marginBottom: 4,
                width: theme.spacing(34),
              }}
              inputStyle={{
                height: 40,
                width: "100%",
                backgroundColor: "transparent",
                color: theme.palette.text.primary,
              }}
              searchStyle={{
                margin: 0,
                width: "calc(100% - 10px)",
              }}
              buttonStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.secondary,
              }}
              dropdownStyle={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.mode === ThemeModes.DARK ? theme.palette.primary.main : theme.palette.text.primary,
                top: "-174px",
                height: 160,
              }}
              country="us"
              enableSearch
              disableSearchIcon
              searchPlaceholder="Search..."
            />
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions className={!formSubmitted ? classes.actions : classes.noCancelActions}>
        {!formSubmitted && (
          <Button onClick={onCancel} variant="text">
            {text.CANCEL_BUTTON_TEXT}
          </Button>
        )}
        <Button
          color="primary"
          disabled={!formValid}
          onClick={!formSubmitted ? handleSubmit : onConfirm}
          variant="contained"
        >
          {!formSubmitted ? text.SUBMIT_BUTTON_TEXT : text.THANKS_BUTTON_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialContactUsDialog;
