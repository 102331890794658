import { type TotalAverageUsageModal } from "@doitintl/cmp-models";

import { Loader } from "../../../../Components/Loader";
import { type FlexsaveOperationsPageId } from "../../../../Navigation/config/pages";
import CustomerPurchases from "./CustomerPurchases/CustomerPurchases";
import { useCustomerPurchases } from "./hooks/customerPurchasesHooks";
import { PurchaseDetails } from "./PurchaseDetails/PurchaseDetails";
import { type CustomerAggregatedWorkloadInfo, type CustomerInfo, type GroupedWorkloads } from "./types";

type Props = {
  pageId: FlexsaveOperationsPageId;
  workloads?: GroupedWorkloads;
  totalAverageUsages?: Map<string, TotalAverageUsageModal>;
  configs?: Map<string, CustomerInfo>;
  customersAgg?: Map<string, CustomerAggregatedWorkloadInfo>;
};

export const FlexsaveGCPCustomerPurchases = ({
  configs,
  pageId,
  workloads,
  totalAverageUsages,
  customersAgg,
}: Props) => {
  const customerAggregatedPurchaseItems =
    useCustomerPurchases(workloads, totalAverageUsages, configs, customersAgg) || [];
  return (
    <Loader loading={customerAggregatedPurchaseItems.length === 0}>
      {pageId === "flexsave-gcp-ops-customers" && (
        <CustomerPurchases customerAggregatedPurchaseItems={customerAggregatedPurchaseItems} />
      )}
      {pageId === "flexsave-gcp-ops-customers:details" && <PurchaseDetails />}
    </Loader>
  );
};
