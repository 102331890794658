import { useParams } from "react-router";
import type { AssetModelBillingAnomalyModel } from "@doitintl/cmp-models";

import { useApiContext } from "../../api/context";
import { useCustomerId } from "../../Components/hooks/useCustomerId";

export const usePatchCostAnomaly = () => {
  const api = useApiContext();
  const customerId = useCustomerId();
  const { assetId, alertId } = useParams<{ assetId; alertId }>();

  return async ({
    anomaly,
    onSuccess,
    onError,
  }: {
    anomaly: Partial<AssetModelBillingAnomalyModel>;
    onSuccess: () => void;
    onError: () => void;
  }) => {
    try {
      await api.patch(`/v1/customers/${customerId}/assets/${assetId}/cost-anomalies/${alertId}`, anomaly);
      onSuccess();
    } catch {
      onError();
    }
  };
};
