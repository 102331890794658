import { type CurrencyCode } from "@doitintl/cmp-models";
import { Grid, Typography } from "@mui/material";

import { formatCurrency } from "../../../../utils/common";
import { type Values } from "./types";

type Props = {
  values: Values;
  customerDomain: string;
  total: number;
  currency: CurrencyCode | undefined;
};

const SummaryStep = ({ values, total, customerDomain, currency }: Props) => (
  <Grid container>
    <Grid item xs />
    <Grid item xs={12} lg={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" paragraph>
            Order summary
          </Typography>
        </Grid>

        <Grid container item justifyContent="flex-start" alignItems="center" xs={12}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Domain</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" data-cy="customer-domain">
              {customerDomain}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item justifyContent="flex-start" alignItems="center" xs={12}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">License</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1">{values.service?.data?.skuName}</Typography>
          </Grid>
        </Grid>

        <Grid container item justifyContent="flex-start" alignItems="center" xs={12}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Quantity</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1">{values.quantity}</Typography>
          </Grid>
        </Grid>

        <Grid container item justifyContent="flex-start" alignItems="center" xs={12}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Plan</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1">
              {values.service?.data?.payment === "MONTHLY" ? "Monthly Payment" : "Yearly"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item justifyContent="flex-start" alignItems="center" xs={12}>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Total</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1">{formatCurrency(total, currency)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs />
  </Grid>
);

export default SummaryStep;
