import { type PerkType } from "@doitintl/cmp-models";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";

import { type PerksWData } from "../../types";
import PerkCard from "./PerkCard";

export const cyIds = {
  title: "perks-section-title",
  subtitle: "perks-section-subtitle",
  perksContainer: "perks-section-perks-container",
};

export type PerksSectionProps = {
  perks: PerksWData[];
  types: PerkType[];
  title?: string;
  subtitle?: string;
};
const PerksSection = ({ perks, subtitle, title, types }: PerksSectionProps) => {
  const theme = useTheme();

  const myPerks = perks.filter((p) => types.includes(p.data.fields.type));

  return myPerks.length > 0 ? (
    <Box component="section" width="100%" data-cy={types.join("--")}>
      <Stack>
        {!!title && (
          <Typography variant="h4" data-cy={cyIds.title}>
            {title}
          </Typography>
        )}
        {!!subtitle && (
          <Typography variant="body2" data-cy={cyIds.subtitle} sx={{ color: "text.secondary" }}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" spacing={2} width="100%" mb={4}>
        <Grid
          container
          spacing={2}
          alignContent="flex-start"
          data-cy={cyIds.perksContainer}
          width={`calc(100% + ${theme.spacing(2)})`}
          sx={{ "&.MuiGrid-container": { ml: -2 } }}
        >
          {myPerks.map((perk) => (
            <Grid key={perk.id} item lg={4} md={6} sm={12} width="100%">
              <PerkCard perk={perk} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  ) : (
    <></>
  );
};

export default PerksSection;
