import { type CloudSelectorType, SaaSConsoleType } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { CloudLogo } from "./CloudLogo";

type Props = {
  type: CloudSelectorType;
};

export const CloudBoxContent = ({ type }: Props) => {
  let cloudBoxText = "Google Cloud";
  if (type === SaaSConsoleType.AWS) {
    cloudBoxText = "Amazon Web Services";
  } else if (type === SaaSConsoleType.AZURE) {
    cloudBoxText = "Microsoft Azure";
  }

  return (
    <Stack alignItems="center">
      <CloudLogo type={type} size={50} />
      <Typography>{cloudBoxText}</Typography>
    </Stack>
  );
};
