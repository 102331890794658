import { Alert, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const languages = [
  { value: "en", label: "English (American)" },
  { value: "ja", label: "Japanese" },
];

type Props = {
  value: string;
  onChange: (value: string) => void;
};

export const LanguageSelector = ({ value, onChange }: Props) => (
  <FormControl fullWidth>
    <InputLabel>Language</InputLabel>
    <Select
      value={value}
      label="Language"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      size="small"
    >
      {languages.map((lang) => (
        <MenuItem key={lang.value} value={lang.value}>
          {lang.label}
        </MenuItem>
      ))}
    </Select>
    {value === "ja" && (
      <Alert severity="info" sx={{ mt: 2 }}>
        Only navigation and the Sign in and Consulting and support pages are available in Japanese.
      </Alert>
    )}
  </FormControl>
);
