import { type JSX } from "react";

import { Stack } from "@mui/material";

type Props = {
  children: JSX.Element;
};
export const FooterBar = ({ children }: Props) => (
  <Stack
    sx={{
      borderTopColor: "divider",
      borderTopWidth: 1,
      borderTopStyle: "solid",
      width: "100%",
      alignItems: "center",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      p: 2,
      justifyContent: "center",
      backgroundColor: "background.default",
      zIndex: 1000,
    }}
  >
    {children}
  </Stack>
);
