import { useMemo } from "react";

import { DoitRole, type SubscriptionPaymentTerm } from "@doitintl/cmp-models";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Alert, Container, Grid, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { useDoitRoleCheck } from "../../../../../Components/hooks/useDoitRoles";
import { sanitizeKeepingLocalDate } from "../../../../../utils/common";
import { useContractTypesContext } from "../../../../ContractsTabs/ContractsTypesContext";
import { onDemandTermOptions } from "../../const";
import { useContractFormContext } from "../../ContractsFormContext";
import { calculateTotalSalesPrice, isMonthlyFlatRateApplicable } from "../../utils";
import { ChargePerTermInput } from "./components/ChargePerTermInput";
import { CommitmentMonthsSelector } from "./components/CommitmentMonthsSelector";
import { CurrencySelector } from "./components/CurrencySelector";
import { PaymentTermSelector } from "./components/PaymentTermSelector";
import { SubscriptionTypeSelector } from "./components/SubscriptionTypeSelector";
import { TierSelector } from "./components/TierSelector";

const dateYearAgo = DateTime.now().minus({ year: 1 });

export const SubscriptionStep = () => {
  const { getContractTypeLabel } = useContractTypesContext();
  const { state, handleChange, handleChangeDate, setState } = useContractFormContext();
  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);

  const monthlyFlatRateText = useMemo(
    () => `+${state.monthlyFlatRate}% of the monthly cloud spend`,
    [state.monthlyFlatRate]
  );

  const handleBlur = ({ target: { value } }) => {
    setState({
      ...state,
      errors: { ...state.errors, monthlyFlatRate: value === "" || value === undefined || value === null },
    });
  };

  return (
    <Container maxWidth="sm" data-cy="subscription-step">
      {state.overlappingContractExists && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          A contract with the selected type already exists for the selected dates. You must cancel any concurrent
          contract or adjust the dates
        </Alert>
      )}
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {`${getContractTypeLabel(state.type)} details`}
      </Typography>
      <Grid>
        <SubscriptionTypeSelector />
      </Grid>
      <Grid>
        {state.isCommitment ? (
          <CommitmentMonthsSelector />
        ) : (
          <TextField
            label="Subscription term"
            data-cy="subscription-term"
            value={onDemandTermOptions[0]}
            onChange={() => undefined}
            fullWidth
            margin="normal"
            variant="outlined"
            select
          >
            {onDemandTermOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          renderInput={(params) => (
            <TextField
              data-cy="subscription-start-date"
              margin="normal"
              fullWidth
              {...params}
              error={state.errors?.startDate}
            />
          )}
          minDate={!isDoitContractAdmin || !isDoitContractOwner ? sanitizeKeepingLocalDate(dateYearAgo) : undefined}
          label="Subscription start date"
          value={state.startDate}
          onChange={handleChangeDate("startDate")}
          disabled={state.isEditForbidden}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLL, yyyy"
        />
      </Grid>
      {state.isCommitment && (
        <Grid item xs={12} md={6}>
          <DatePicker
            renderInput={(params) => (
              <TextField data-cy="subscription-end-date" margin="normal" fullWidth {...params} />
            )}
            label="Subscription end date"
            value={state.endDate}
            minDate={state.startDate}
            onChange={handleChangeDate("endDate")}
            disabled={state.commitmentMonths !== -1 || state.isEditForbidden}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat="dd LLL, yyyy"
          />
        </Grid>
      )}
      <Grid>
        <TierSelector />
      </Grid>
      <Grid display="flex">
        <CurrencySelector />
        <ChargePerTermInput />
      </Grid>
      <Grid>
        <TextField
          label="Discount"
          value={state.discount}
          data-cy="discount"
          onChange={handleChange("discount", true)}
          error={state.errors.discount}
          disabled={state.isEditForbidden}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          fullWidth
          margin="normal"
          sx={{ mb: 0 }}
          variant="outlined"
        />
        <Typography variant="caption" color="textSecondary">
          {" "}
          Discounts are used to change the total monthly sales price{" "}
        </Typography>
      </Grid>

      {isMonthlyFlatRateApplicable(state.tier) && (
        <Grid>
          <TextField
            label="Monthly flat rate based on the cloud spend"
            data-cy="monthly-flat-rate"
            value={state.monthlyFlatRate}
            onChange={handleChange("monthlyFlatRate", true)}
            onBlur={handleBlur}
            error={state.errors.monthlyFlatRate}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            margin="normal"
            variant="outlined"
          />
        </Grid>
      )}
      <Grid>
        <PaymentTermSelector />
      </Grid>

      {Boolean(state.chargePerTerm && state.paymentTerm) && (
        <Grid mt={2}>
          <Typography display="div" variant="body2" sx={{ fontWeight: 500 }}>
            Total sales price:
          </Typography>
          <Typography display="div" variant="body2">
            {calculateTotalSalesPrice(
              state.chargePerTerm as number,
              state.paymentTerm as SubscriptionPaymentTerm,
              state.discount,
              state.currency
            )}
          </Typography>
          {isMonthlyFlatRateApplicable(state.tier) && Number(state.monthlyFlatRate) > 0 && (
            <Typography display="div" variant="body2" color={grey[500]}>
              {monthlyFlatRateText}
            </Typography>
          )}
        </Grid>
      )}
    </Container>
  );
};
