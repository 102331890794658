import { formatFirestoreDate } from "../../../CloudAnalytics/utilities";
import { type Job, type JobTableRow } from "./types";

export const formatJob = (job: Job): JobTableRow => ({
  ...job,
  transform: {
    createdAt: formatFirestoreDate(job.createdAt),
    updatedAt: formatFirestoreDate(job.updatedAt),
  },
});
