import {
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  AssetTypeGoogleCloud,
  AssetTypeGoogleCloudDirect,
  AssetTypeGoogleCloudProject,
  AssetTypeGoogleCloudStandalone,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
  Classification,
  type SupportServiceModel,
  type SupportStatisticsModel,
} from "@doitintl/cmp-models";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import get from "lodash/get";

import { avaAgentIdDev, avaAgentIdProd } from "../constants";
import { jsUcfirst } from "../utils/common";
import { platformsData } from "./consts";
import { countries } from "./country-by-continent";
import { type Ticket } from "./types";

const creditsPlatform = "credits";

const geographicalGrouping = {
  EMEA: ["Europe", "Middle East", "Africa"],
  APAC: ["Asia", "Pacific"],
  NorthAmerica: ["North America"],
  SouthAmerica: ["South America"],
};

export const platformName = (platform) => {
  switch (platform) {
    case "google_cloud_platform":
      return AssetTypeGoogleCloud;
    case "amazon_web_services":
      return AssetTypeAmazonWebServices;
    case "google_g_suite":
      return AssetTypeGSuite;
    case "finance___billing":
      return "finance";
    case "microsoft_office_365":
      return AssetTypeOffice365;
    case "microsoft_azure":
      return AssetTypeMicrosoftAzure;
    case "cloud_management_platform":
      return "cloud-management-platform";
    case "credits___request":
      return creditsPlatform;
  }
  return platform;
};

/**
 * Converts an {@link AssetModel.type} to a {@link ZendeskPlatformModel.value}.
 * @param platform platform asset type (e.g. amazon-web-services), although also accepts "cloud-management-platform"
 */
export const platformValue = (platform: string) => {
  switch (platform) {
    case AssetTypeGoogleCloudDirect:
    case AssetTypeGoogleCloudProject:
    case AssetTypeGoogleCloud:
      return "google_cloud_platform";
    case AssetTypeAmazonWebServices:
      return "amazon_web_services";
    case AssetTypeGSuite:
      return "google_g_suite";
    case AssetTypeOffice365:
      return "microsoft_office_365";
    case AssetTypeMicrosoftAzure:
      return "microsoft_azure";
    case AssetTypeAwsStandalone:
    case AssetTypeGoogleCloudStandalone:
    case "cloud-management-platform":
    case "zendesk":
    case "bettercloud":
      return "cloud_management_platform";
    case "promotional-credits":
      return creditsPlatform;
  }
  return platform;
};

export const getSlaPolicyTime = (classification, platform, priority, slas, t) => {
  const fallbackSLA = 240; // minutes
  let target = fallbackSLA;
  let slaObject;

  if (platform !== "finance___billing") {
    switch (classification) {
      case Classification.Business:
        // "Business SLA"
        slaObject = find(slas, { id: 360000007406 });
        break;
      default:
        break;
    }
  } else {
    // "Finance SLA"
    slaObject = find(slas, { id: 360000374071 });
  }

  if (!slaObject) {
    // "Other SLA"
    slaObject = find(slas, { id: 360000288411 });
  }

  if (slaObject) {
    target = get(
      slaObject.policy_metrics?.find((pm) => pm.priority === priority && pm.metric === "first_reply_time") ?? {},
      "target",
      fallbackSLA
    );
  }

  return target / 60 >= 1
    ? `${Math.round(target / 60)} ${t("AccessStep.hours")}`
    : `${target} ${t("AccessStep.minutes")}`;
};

const sortByField = (a: SupportServiceModel, b: SupportServiceModel, sortBy: string) => {
  const nameA = a?.[sortBy];
  const nameB = b?.[sortBy];
  if (nameA === "" || nameB === "") {
    return 0;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const otherProductTitle = "- other";

export const isOtherProduct = (productName: string) => productName.includes(otherProductTitle);

export const addOtherCategory = (categories: SupportServiceModel[]) => {
  const otherTitle = (n: string) => `${jsUcfirst(n)} ${otherProductTitle}`;
  categories.sort((a: SupportServiceModel, b: SupportServiceModel) => sortByField(a, b, "name"));
  categories.sort((a: SupportServiceModel, b: SupportServiceModel) => sortByField(a, b, "platform"));

  return categories.reduce((acc, product) => {
    if (
      product.categories?.length > 0 &&
      product.platform !== "cloud-management-platform" &&
      product.categories.findIndex((c) => c.id === "popular") === -1
    ) {
      const hasOtherCategory = acc.find(
        (c) => c.name === otherTitle(product.categories[0].name) && c.platform === product.platform
      );

      if (!hasOtherCategory && product.categories?.length > 0) {
        const tags = product?.tags?.filter((t: string) => !t.includes("/service/"));
        acc.push({
          ...product,
          tags,
          id: product.categories[0].name,
          name: otherTitle(product.categories[0].name),
        });
      }
    }

    acc.push(product);

    return acc;
  }, [] as SupportServiceModel[]);
};

export const buildTopServices = (services: SupportStatisticsModel, categories: SupportServiceModel[]) =>
  categories.reduce((acc, product) => {
    const top = services.distribution_products_30d?.find((p) => p.label === product.name);
    if (top) {
      acc.push(cloneDeep(product));
      product.categories.unshift({ name: " Popular requests", id: "popular" });
    }
    acc.push(product);
    return acc;
  }, [] as SupportServiceModel[]);

// Get country and return true if it's in EMEA or North America
export const isEMEAorNorthAmerica = (country: string) => {
  if (!country || country === "") {
    return false;
  }
  const continent = countries.find((c) => c.country === country)?.continent ?? "";
  return geographicalGrouping.EMEA.includes(continent) || geographicalGrouping.NorthAmerica.includes(continent);
};

const idField = 360000148226;

const getProductFromTag = (tags: string[] = []) => platformsData.find((item) => tags.includes(item.value));

export const getProductId = (ticket: Ticket) => {
  const product = ticket.fields?.find((field) => field.id === idField)?.value;
  if (product) {
    return product;
  }
  return getProductFromTag(ticket.tags)?.value;
};

export const isAvaTheAuthor = (author: number) => [avaAgentIdProd, avaAgentIdDev].includes(author);

export const isAvaFeedbackRequestComment = (body: string, author: number) =>
  body.includes("Was Ava's response helpful") && isAvaTheAuthor(author);

// identify platform options with unique considerations
export const isDoitConsoleBaseService = (platform: string) =>
  ["cloud_management_platform", "finance-billing", "promotional-credits"].includes(platform);

export const isStandaloneAppPlatform = (platform: string) => ["office-365"].includes(platform);
