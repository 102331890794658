import "reflect-metadata";

const NAME_KEY = Symbol("@coll");

export function collection(className: string) {
  return (Reflect as any).metadata(NAME_KEY, className);
}

export function doc(className: string) {
  return (Reflect as any).metadata(NAME_KEY, className);
}

export const subCollection = collection;

export function getCollectionName(type: any): string {
  return (Reflect as any).getMetadata(NAME_KEY, type);
}

export type OmitDeclFields<TModel> = Omit<TModel, "docs" | "subCollections">;

type HaveSubCollections = {
  subCollections?: Record<string, any>;
};

type SubCollectionOfTModel<TModel> = TModel extends HaveSubCollections ? Required<TModel>["subCollections"] : never;

export type SubCollectionIdOfTModel<TModel> = keyof SubCollectionOfTModel<TModel>;

export type SubCollectionOfTMModel<TModel, TSubCollectionId> = TModel extends HaveSubCollections
  ? TSubCollectionId extends keyof SubCollectionOfTModel<TModel>
    ? SubCollectionOfTModel<TModel>[TSubCollectionId]
    : never
  : never;

type HaveDocs = {
  docs?: Record<string, any>;
};

type DocOfTModel<TModel> = TModel extends HaveDocs ? Required<TModel>["docs"] : string;

export type DocIdOfTModel<TModel> = TModel extends HaveDocs ? keyof DocOfTModel<TModel> : string;

export type DocModelOfTModel<TModel, TDocId extends string> = TModel extends HaveDocs
  ? TDocId extends keyof DocOfTModel<TModel>
    ? DocOfTModel<TModel>[TDocId]
    : never
  : TModel;
