import { ProductEnum, SaaSConsoleType } from "@doitintl/cmp-models";

import { customersRoute } from "../../Navigation/core/utils";
import { type Customer } from "../../types";
import { getSaaSConsoleOnboardingRoute } from "../../utils/common";

export const getOnboardingUrl = (customer: Customer, providers?: ProductEnum[] | undefined): string => {
  const customerPage = customersRoute(customer.id);
  const { onboarding } = customer;

  const cloudProviders =
    providers ?? Array.from(new Set(onboarding?.navigator?.providers?.concat(onboarding?.solve?.providers ?? [])));

  // If no cloud providers are configured or more than one is configured, return customer page URL
  if (!cloudProviders?.length || cloudProviders?.length > 1) {
    return customerPage;
  }

  // If AWS is configured, return AWS console onboarding route
  if (cloudProviders?.includes(ProductEnum.AmazonWebServices)) {
    return getSaaSConsoleOnboardingRoute(customer, SaaSConsoleType.AWS);
  }

  // If GCP is configured, return GCP console onboarding route
  if (cloudProviders?.includes(ProductEnum.GoogleCloud)) {
    return getSaaSConsoleOnboardingRoute(customer, SaaSConsoleType.GCP);
  }

  // If Azure is configured, return Azure console onboarding route
  if (cloudProviders?.includes(ProductEnum.MicrosoftAzure)) {
    return getSaaSConsoleOnboardingRoute(customer, SaaSConsoleType.AZURE);
  }

  return customerPage;
};
