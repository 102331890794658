import { globalText, marketplaceText } from "../../../../assets/texts";

export type EntitlementMenuOptionProps = {
  label: string;
  value: string;
  disabled?: boolean;
  color?: string;
};

export const entitlementOptions: EntitlementMenuOptionProps[] = [
  {
    label: marketplaceText.COPY_ENTITLEMENT_ID,
    value: marketplaceText.COPY_ENTITLEMENT_ID,
  },
  {
    label: marketplaceText.COPY_ACCOUNT_ID,
    value: marketplaceText.COPY_ACCOUNT_ID,
  },
  {
    label: globalText.APPROVE,
    value: globalText.APPROVE,
  },
  {
    label: globalText.REJECT,
    value: globalText.REJECT,
    color: "error",
  },
];
