import { type AxiosInstance } from "axios";
import type { INodeModel } from "@doitintl/cmp-models";

import {
  type CloudflowDTO,
  type CreateCloudflowInput,
  type CreateOrUpdateNode,
  type DeleteCloudflowInput,
  type Region,
  type TestNodeRequest,
  type TestNodeResponse,
  type TriggerCloudflowResponce,
  type UpdateCloudflowInput,
  type UpdateCloudflowNodes,
} from "./types";

export const createCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  createCloudflowInput: CreateCloudflowInput
) => api.post<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow`, createCloudflowInput);

export const createCloudflowFromTemplate = async (api: AxiosInstance, customerId: string, templateID: string) =>
  api.post<{ id: string }>(`/v1/customers/${customerId}/cloudflow/create-from-template/${templateID}`);

export const createNode = async (api: AxiosInstance, customerId: string, flowId: string, data: CreateOrUpdateNode) =>
  api.put<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}/node`, data);

export const deleteNode = async (api: AxiosInstance, customerId: string, flowId: string, nodeId: any) =>
  api.delete<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}/node/${nodeId}`);

export const deleteCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  deleteCloudflowInput: DeleteCloudflowInput
) => api.delete<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${deleteCloudflowInput.id}`);

export const updateCloudflow = async (
  api: AxiosInstance,
  customerId: string,
  flowId: string,
  updateCloudflowInput: UpdateCloudflowInput
) => api.put<CloudflowDTO>(`/v1/customers/${customerId}/cloudflow/${flowId}`, updateCloudflowInput);

// Add/Update/Delete Cloudflow Nodes
export const updateCloudflowNodes = async (api: AxiosInstance, customerId: string, nodes: UpdateCloudflowNodes) =>
  api.post<INodeModel>(`/v1/customers/${customerId}/cloudflow/update-nodes`, nodes);

export const triggerCloudflow = async (api: AxiosInstance, customerId: string, flowId: string) =>
  api.post<TriggerCloudflowResponce>(`/orchestrator/customers/${customerId}/trigger-cloudflow/${flowId}`);

export const testNode = async (api: AxiosInstance, customerId: string, data: TestNodeRequest) =>
  api.post<TestNodeResponse>(`/orchestrator/customers/${customerId}/test-node`, data);

export const getAwsRegions = async (api: AxiosInstance, customerId: string, accountId: string) =>
  api.get<Region[]>(`/v1/customers/${customerId}/cloudflow/get-aws-regions/${accountId}`);
