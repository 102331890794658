import { Fragment } from "react";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxIcon from "@mui/icons-material/CheckBoxRounded";
import DeleteIcon from "@mui/icons-material/ClearRounded";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Form, withFormik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import * as yup from "yup";

import { globalText } from "../../assets/texts";
import { consoleErrorWithSentry } from "../../utils";
import { useFullScreen } from "../../utils/dialog";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  autocomplete: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

type BillingRuleFormValues = {
  name: string;
  basicBillingRule: {
    billingAdjustment: number | string;
    billingRuleType: string;
  };
  lineItemDescriptionField?: {
    type: string;
    value: string;
  }[];
  product: {
    productName: string;
    operation?: { name: string }[];
    region?: { name: string }[];
    usageType?: { name: string }[];
    lineItemDescription?: {
      [key: string]: string;
    }[];
  };
};

type BillingRuleFormProps = {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (result) => void;
  billingRule;
  options;
};

const BillingRuleForm = (props) => {
  const { open, onClose, onDelete, touched, errors, values, isSubmitting, handleChange, handleBlur, setFieldValue } =
    props;
  const classes = useStyles();
  const { isMobile: mediaDownXS } = useFullScreen("sm");
  const operations = props.options?.operations ?? [];
  const regions = props.options?.regions ?? [];
  const products = props.options?.products ?? [];

  const usageTypes = products.find((p) => p.productName === values.product.productName)?.usageTypes ?? [];

  return (
    <Dialog
      open={open}
      aria-labelledby="billing-rule-form"
      onClose={onClose}
      fullScreen={mediaDownXS}
      maxWidth="sm"
      fullWidth
    >
      <Form className={classes.dialogForm}>
        <DialogTitle id="billing-rule-form">{props.billingRule ? "Edit" : "New"} billing rule</DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Billing Rule Name"
                variant="outlined"
                margin="dense"
                disabled={isSubmitting}
                helperText={(touched.name && errors.name) || ""}
                error={touched.name && Boolean(errors.name)}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="basicBillingRule.billingRuleType"
                label="Billing Rule Type"
                variant="outlined"
                margin="dense"
                disabled={isSubmitting}
                value={values.basicBillingRule.billingRuleType}
                select
                SelectProps={{
                  value: values.basicBillingRule.billingRuleType,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                fullWidth
              >
                <MenuItem value="fixedRate" dense>
                  Fixed Rate
                </MenuItem>
                <MenuItem value="percentDiscount" dense>
                  Percent Discount
                </MenuItem>
                <MenuItem value="percentIncrease" dense>
                  Percent Increase
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                name="basicBillingRule.billingAdjustment"
                label="Billing Adjustment"
                variant="outlined"
                margin="dense"
                type="number"
                disabled={isSubmitting}
                helperText={
                  (touched.basicBillingRule?.billingAdjustment && errors.basicBillingRule?.billingAdjustment) || ""
                }
                error={
                  touched.basicBillingRule?.billingAdjustment &&
                  errors.basicBillingRule &&
                  Boolean(errors.basicBillingRule.billingAdjustment)
                }
                value={values.basicBillingRule.billingAdjustment}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  startAdornment: values.basicBillingRule.billingRuleType === "fixedRate" && (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  endAdornment: values.basicBillingRule.billingRuleType !== "fixedRate" && (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="product.productName"
                label="Product"
                variant="outlined"
                margin="dense"
                disabled={isSubmitting}
                helperText={(touched.product?.productName && errors.product?.productName) || ""}
                error={touched.product?.productName && errors.product && Boolean(errors.product.productName)}
                select
                SelectProps={{
                  value: values.product.productName,
                  onChange: handleChange,
                  onBlur: handleBlur,
                  MenuProps: {
                    MenuListProps: {
                      dense: true,
                    },
                  },
                }}
                fullWidth
              >
                {products.map((product) => (
                  <MenuItem key={product.productName} value={product.productName} dense>
                    {product.productName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {values.product.productName === "ANY" ? (
              <>
                {(values.lineItemDescriptionField ?? []).map((lineItemDescriptionField, i) => {
                  const name = `lineItemDescriptionField[${i}]`;
                  return (
                    <Fragment key={`lineItemDescription-${i}-type`}>
                      <Grid item xs={6}>
                        <TextField
                          name={`${name}.type`}
                          label="Item Description"
                          variant="outlined"
                          margin="dense"
                          disabled={isSubmitting}
                          value={lineItemDescriptionField.type}
                          select
                          SelectProps={{
                            value: lineItemDescriptionField.type,
                            onChange: handleChange,
                            onBlur: handleBlur,
                          }}
                          fullWidth
                        >
                          <MenuItem value="startsWith" dense>
                            Starts with
                          </MenuItem>
                          <MenuItem value="contains" dense>
                            Contains
                          </MenuItem>
                          <MenuItem value="matchesRegex" dense>
                            Matches regex
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name={`${name}.value`}
                          label="Text"
                          variant="outlined"
                          margin="dense"
                          disabled={isSubmitting}
                          helperText={
                            (touched.lineItemDescriptionField?.[i]?.value &&
                              errors.lineItemDescriptionField?.[i]?.value) ||
                            ""
                          }
                          error={
                            touched.lineItemDescriptionField?.[i]?.value &&
                            Boolean(errors.lineItemDescriptionField?.[i]?.value)
                          }
                          value={lineItemDescriptionField.value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    const value = values.lineItemDescriptionField.slice();
                                    value.splice(i, 1);
                                    setFieldValue("lineItemDescriptionField", value);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => {
                      const value = (values.lineItemDescriptionField ?? []).slice();
                      value.push({
                        type: "startsWith",
                        value: "",
                      });
                      setFieldValue("lineItemDescriptionField", value);
                    }}
                  >
                    Add Condition
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    freeSolo
                    disableCloseOnSelect
                    options={usageTypes}
                    limitTags={2}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    value={values.product?.usageType ?? []}
                    onChange={(event, newValue, reason) => {
                      switch (reason) {
                        case "createOption": {
                          const addedValue = newValue[newValue.length - 1];
                          const res = newValue.slice();
                          res.splice(res.length - 1, 1, {
                            name: addedValue,
                          });
                          setFieldValue("product.usageType", res);
                          break;
                        }
                        default:
                          setFieldValue("product.usageType", newValue);
                      }
                    }}
                    onBlur={handleBlur("product.usageType")}
                    size="small"
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          size="small"
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Usage Types"
                        helperText={(touched.product?.usageType && errors.product?.usageType) || ""}
                        error={touched.product?.usageType && errors.product && Boolean(errors.product.usageType)}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    freeSolo
                    disableCloseOnSelect
                    options={operations}
                    limitTags={4}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    value={values.product?.operation ?? []}
                    onChange={(event, newValue, reason) => {
                      switch (reason) {
                        case "createOption": {
                          const addedValue = newValue[newValue.length - 1];
                          const res = newValue.slice();
                          res.splice(res.length - 1, 1, {
                            name: addedValue,
                          });
                          setFieldValue("product.operation", res);
                          break;
                        }
                        default:
                          setFieldValue("product.operation", newValue);
                      }
                    }}
                    onBlur={handleBlur("product.operation")}
                    size="small"
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Operations"
                        helperText={(touched.product?.operation && errors.product?.operation) || ""}
                        error={touched.product?.operation && errors.product && Boolean(errors.product.operation)}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    className={classes.autocomplete}
                    multiple
                    freeSolo
                    disableCloseOnSelect
                    options={regions}
                    limitTags={4}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    value={values.product?.region ?? []}
                    onChange={(event, newValue, reason) => {
                      switch (reason) {
                        case "createOption": {
                          const addedValue = newValue[newValue.length - 1];
                          const res = newValue.slice();
                          res.splice(res.length - 1, 1, {
                            name: addedValue,
                          });
                          setFieldValue("product.region", res);
                          break;
                        }
                        default:
                          setFieldValue("product.region", newValue);
                      }
                    }}
                    onBlur={handleBlur("product.region")}
                    size="small"
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Regions"
                        helperText={(touched.product?.region && errors.product?.region) || ""}
                        error={touched.product?.region && errors.product && Boolean(errors.product.region)}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>

        <Divider />

        <Grid container justifyContent="space-between" wrap="nowrap">
          <DialogActions>
            {props.billingRule && (
              <Button color="error" variant="text" onClick={onDelete}>
                DELETE
              </Button>
            )}
          </DialogActions>

          <DialogActions>
            <Button variant="text" onClick={onClose}>
              {globalText.CANCEL}
            </Button>
            <Button color="primary" variant="contained" type="submit">
              {globalText.DONE}
            </Button>
          </DialogActions>
        </Grid>
      </Form>
    </Dialog>
  );
};

const schema = yup.object().shape({
  name: yup.string().required().label("Billing rule name").max(64, "Billing rule name is too long"),
  basicBillingRule: yup.object().shape({
    billingAdjustment: yup
      .number()
      .typeError("Billing adjustment must be a number")
      .min(0, "Billing adjustment must be higher or equal to 0")
      .max(100, "Billing adjustment must be lower or equal to 100")
      .required()
      .label("Billing adjustment"),
    billingRuleType: yup
      .string()
      .oneOf(["fixedRate", "percentDiscount", "percentIncrease"])
      .required()
      .label("Billing rule type"),
  }),
  product: yup.object().shape({
    productName: yup.string(),
    usageType: yup.array().of(
      yup.object({
        name: yup.string(),
      })
    ),
    operation: yup.array().of(
      yup.object({
        name: yup.string(),
      })
    ),
    region: yup.array().of(
      yup.object({
        name: yup.string(),
      })
    ),
  }),
  lineItemDescriptionField: yup
    .array()
    .of(
      yup.object({
        type: yup.string().oneOf(["startsWith", "contains", "matchesRegex"]).required(),
        value: yup.string().required(),
      })
    )
    .nullable(),
});

export default withFormik<BillingRuleFormProps, BillingRuleFormValues>({
  mapPropsToValues: (props) => {
    if (props.billingRule) {
      const values = {
        lineItemDescriptionField: undefined,
        name: props.billingRule.name,
        basicBillingRule: props.billingRule.basicBillingRule,
        product: props.billingRule.product,
      };
      if (values.product?.lineItemDescription?.length > 0) {
        values.lineItemDescriptionField = values.product.lineItemDescription.map((v) => {
          const k = Object.keys(v)[0];
          return {
            type: k,
            value: v[k],
          };
        });
      }
      return values;
    }
    return {
      name: "",
      lineItemDescriptionField: [],
      basicBillingRule: {
        billingRuleType: "fixedRate",
        billingAdjustment: "",
      },
      product: {
        productName: "ANY",
        usageType: [],
        operation: [],
        region: [],
      },
    };
  },

  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: true,
  validationSchema: schema,
  displayName: "BillingRuleForm",

  handleSubmit: async (values, { props: { onSubmit, onClose }, setSubmitting, resetForm }) => {
    try {
      const result = cloneDeep(values);
      if (result.product.productName === "ANY") {
        result.product.lineItemDescription = (result.lineItemDescriptionField ?? []).map((v) => ({
          [v.type]: v.value,
        }));
        delete result.product.usageType;
        delete result.product.operation;
        delete result.product.region;
      } else {
        delete result.product.lineItemDescription;
      }
      delete result.lineItemDescriptionField;
      onSubmit(result);
      resetForm();
      onClose();
    } catch (error) {
      consoleErrorWithSentry(error);
    }

    setSubmitting(false);
  },
})(BillingRuleForm);
