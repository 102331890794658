import {
  type CloudAnalyticsModelBudgetModel,
  type Collaborator,
  type PublicAccess,
  type SlackChannel,
} from "@doitintl/cmp-models";
import { lighten } from "@mui/material";
import { amber, green, orange, red } from "@mui/material/colors";
import { type AxiosInstance, type Method } from "axios";
import cloneDeep from "lodash/cloneDeep";

import { httpMethods } from "../../../assets/texts";
import { type Budget, type Customer } from "../../../types";

export const NoReplyEmail = "no-reply@doit.com";
export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: "left" as const,
  },
  transformOrigin: {
    vertical: "top" as const,
    horizontal: "left" as const,
  },
};
export const textFieldBaseProps = {
  variant: "outlined" as const,
  margin: "dense" as const,
  fullWidth: true,
};
export const textFieldSelectProps = {
  select: true,
  SelectProps: { MenuProps },
};

export const getAttributionRefIdByName = (name, attributions) =>
  attributions.find((attribution) => attribution.data.name === name)?.ref.id;

export const shareBudget = async ({
  api,
  customer,
  budget,
  publicAccess,
  collaborators,
  recipients,
  recipientsSlackChannels,
}: {
  customer: Customer;
  api: AxiosInstance;
  budget: Budget;
  publicAccess: PublicAccess;
  collaborators: Collaborator[];
  recipients: string[];
  recipientsSlackChannels: SlackChannel[];
}) => {
  await api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customer.id}/analytics/budgets/${budget.id}/share`,
    data: {
      public: publicAccess,
      collaborators,
      recipients,
      recipientsSlackChannels,
    },
  });
};

export const getUtilizationProgressBarColor = (
  budget: Pick<CloudAnalyticsModelBudgetModel, "utilization" | "config">
) => {
  const currentUtilization = budget.utilization?.current || 0;
  const budgetAmount = budget.config.amount;
  const sortedAlerts = cloneDeep(budget.config.alerts).sort((a, b) => a.percentage - b.percentage);
  const getColors = (color) => ({ bar: color, root: lighten(color, 0.5) });
  if (currentUtilization > (sortedAlerts[2].percentage * budgetAmount) / 100) {
    return getColors(red[500]);
  }
  if (currentUtilization > (sortedAlerts[1].percentage * budgetAmount) / 100) {
    return getColors(orange[500]);
  }
  if (currentUtilization > (sortedAlerts[0].percentage * budgetAmount) / 100) {
    return getColors(amber[500]);
  }
  return getColors(green[500]);
};

export const getAlertAmountFromPercentage = (percentage: number, amount: number): number => {
  if (!percentage) {
    return 0;
  }
  return Math.round(amount * percentage) / 100;
};
