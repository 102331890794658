import { Chip, Grid, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { reportTxt } from "../../../../assets/texts/CloudAnalytics";

type Props = {
  reportCacheRefreshedAt: Date | undefined;
};

const CacheLastUpdated = ({ reportCacheRefreshedAt }: Props) => {
  const lastUpdated = reportCacheRefreshedAt ? DateTime.fromJSDate(reportCacheRefreshedAt) : undefined;

  return (
    <Grid item container spacing={1} wrap="nowrap" columnGap={1} mt={0.2}>
      <Chip size="small" label={reportTxt.CACHED} sx={{ backgroundColor: "#4CAF50", color: "white" }} />
      {lastUpdated?.isValid && (
        <>
          <Typography
            color="text.secondary"
            noWrap
            sx={{
              fontWeight: 400,
              overflow: "initial",
              display: "flex",
              alignItems: "center",
              columnGap: 0.5,
            }}
            variant="body2"
          >
            {`Last updated: `}
            <Tooltip title={lastUpdated.toFormat("dd LLL yyyy, HH:mm")} arrow>
              <Typography
                sx={{
                  textDecoration: "underline",
                  textDecorationStyle: "dotted",
                }}
              >
                {lastUpdated.toRelative()}
              </Typography>
            </Tooltip>
          </Typography>
        </>
      )}
    </Grid>
  );
};

export default CacheLastUpdated;
