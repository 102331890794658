export const organizationsTxt = {
  LIMIT_ATTRIBUTION_WARNING: `Organizations cannot have more than three attributions for its scope`,
  HINT_TEXT: `Limited to three attributions`,
  SELECT_ATTRIBUTIONS: "Select attributions",
  ORGANIZATION_NAME: "Organization name",
  COPY_ORGANIZATION_ID: "Copy organization ID",
  DELETE_ORGANIZATION: "Delete organization",
  ORGANIZATION_SAVE_SUCCESS: "Organization saved successfully",
  DELETE_ORGANIZATION_ERROR: "Error deleting organization",
  SUCCESSFULLY_DELETED_ORGANIZATION: "Successfully deleted organization",
  NAME_BEFORE_SAVING: "Name before saving",
  ORGANIZATION_SAVE_ERROR: "Error saving organization",
  SAVE_ORGANIZATION: "Save organization",
  MISSING_ATTRIBUTION_OR_USER: "Organization missing attribution or user",
};
