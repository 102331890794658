import { useState } from "react";

import DeleteIcon from "@mui/icons-material/DeleteRounded";
import { Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";

import LoadingButton from "../LoadingButton";
import { text } from "./consts";

const useStyles = makeStyles(() => ({
  floatingButton: { position: "fixed", right: "5%", bottom: "10%", zIndex: 9999 },
}));

export type DeleteButtonProps = {
  onDelete: (() => Promise<void>) | (() => void);
  onConfirmPrompt?: () => void;
  disabled?: boolean;
  floating?: boolean;
  color?: any;
  deleteText?: any;
  variantBtn?: "text" | "outlined" | "contained";
  confirmText?: any;
  disableIcon?: boolean;
};

export const DeleteButton = ({
  onDelete,
  onConfirmPrompt,
  disabled,
  floating,
  color,
  deleteText,
  confirmText,
  disableIcon,
  variantBtn = "contained",
}: DeleteButtonProps) => {
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = () => {
    if (confirmDelete) {
      setLoading(true);
      return onDelete();
    }
    if (onConfirmPrompt) {
      onConfirmPrompt();
    }
    setConfirmDelete(true);
  };

  const btnColor = color || "error";
  const fabVariant = confirmDelete ? "extended" : "circular";
  const btnVariant = confirmDelete ? "contained" : variantBtn;
  const loadingBtnText = confirmDelete ? (confirmText ?? text.CONFIRM_TEXT) : (deleteText ?? text.DELETE_TEXT);

  return (
    <>
      {floating ? (
        <Fab className={classes.floatingButton} variant={fabVariant} color={btnColor} onClick={handleDelete}>
          <DeleteIcon />
          {confirmDelete && <span style={{ marginLeft: 4 }}>{text.CONFIRM_TEXT}</span>}
        </Fab>
      ) : (
        <LoadingButton
          variant={btnVariant}
          color={btnColor}
          onClick={handleDelete}
          startIcon={!disableIcon && <DeleteIcon />}
          loading={loading}
          disabled={disabled || loading}
          mixpanelEventId={`delete.${loadingBtnText}`}
        >
          {loadingBtnText}
        </LoadingButton>
      )}
    </>
  );
};
