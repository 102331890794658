import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { type SxProps, type Theme } from "@mui/system";

import { AwsAccountCreationDialogButton } from "./components/AwsAccountCreationDialog/AwsAccountCreationDialogButton";
import { AwsAccountList } from "./components/AwsAccountList/AwsAccountList";
import { AwsAccountContextProvider } from "./data/AwsAccountContextProvider";

const flexRow: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
};

const flex1: SxProps<Theme> = { flex: 1 };

export const AwsAccountGenerator = () => (
  <AwsAccountContextProvider>
    <Box sx={flexRow} mt={2} mb={2}>
      <Typography variant="h3" sx={flex1}>
        AWS account creation
      </Typography>
      <AwsAccountCreationDialogButton />
    </Box>
    <AwsAccountList />
  </AwsAccountContextProvider>
);
