import { useMemo } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select, Typography } from "@mui/material";

type Props = {
  value?: string;
  onChange: (event) => void;
  error: boolean;
};

const productTypeLabels = {
  "amazon-web-services": "Amazon Web Services",
  "google-cloud": "Google Cloud",
};

export const AcceleratorTypeSelector = ({ value, onChange, error }: Props) => {
  const [acceleratorTypeOptions, loading] = useCollectionDataOnce(
    useMemo(() => getCollection(AppModel).doc("contracts").collection("acceleratorTypes"), []),
    {
      idField: "id",
    }
  );

  const acceleratorTypeOptionsByCloud = useMemo(() => {
    if (loading || !acceleratorTypeOptions) {
      return {};
    }

    return acceleratorTypeOptions.reduce((acc, curr) => {
      if (!acc[curr.cloud]) {
        acc[curr.cloud] = [];
      }

      acc[curr.cloud].push(curr);
      return acc;
    }, {});
  }, [acceleratorTypeOptions, loading]);

  return (
    <FormControl size="small" fullWidth margin="normal" error={error}>
      <InputLabel id="accelerator-type-selector-label">Accelerator type</InputLabel>
      <Select
        labelId="accelerator-type-selector-label"
        data-cy="accelerator-type-selector"
        onChange={onChange}
        value={value ?? ""}
        fullWidth
        variant="outlined"
        label="Accelerator type"
      >
        {Object.keys(acceleratorTypeOptionsByCloud).map((key) => [
          <ListSubheader key={key}>
            <Typography fontSize={12} color="text.primary">
              {productTypeLabels[key]}
            </Typography>
          </ListSubheader>,
          acceleratorTypeOptionsByCloud[key].map(({ id, label }) => (
            <MenuItem key={id} value={id} sx={{ pl: 4 }}>
              {label}
            </MenuItem>
          )),
        ])}
      </Select>
      <FormHelperText>Select the accelerator type</FormHelperText>
    </FormControl>
  );
};
