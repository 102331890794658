import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";

import { useCustomerContext } from "./CustomerContext";

export const useTicketsStatistics = () => {
  const { customer } = useCustomerContext();

  const [data, loading] = useDocumentData(
    getCollection(DashboardModel).doc("ticket-statistics").collection("ticketStatistics").doc(customer.id),
    { caching: true, cachingKeys: [customer.id] }
  );

  return { data, loading };
};
