import { useEffect, useState } from "react";

import {
  AuditLogModel,
  type AuditLogModelGSuiteApiModel,
  type AuditLogModelOffice365ApiModel,
  DashboardModel,
  type DashboardModelCompaniesModel,
  type DashboardModelCompaniesModelDomain,
} from "@doitintl/cmp-models";
import { type DocumentSnapshotModel, getCollection, type QueryDocumentSnapshotModel } from "@doitintl/models-firestore";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../Context/CustomerContext";
import { consoleErrorWithSentry, getDiffWeek } from "../../utils";
import OrderHistoryTable from "./OrderHistoryTable";
import { type GraphData, type GraphDataItems, type Orders } from "./types";

const OrderHistory = () => {
  const { customer } = useCustomerContext();
  const [rows, setRows] = useState<Orders>([]);
  const [graphData, setGraphData] = useState<GraphData>([]);
  const [showDomain, setShowDomain] = useState<boolean | null>(null);
  const [noHistory, setNoHistory] = useState(false);

  const buildDataForLicenseChart = (docSnap: DocumentSnapshotModel<DashboardModelCompaniesModel>) => {
    const data = docSnap.asModelData();
    if (!data) {
      return null;
    }
    const dataChart = Object.entries(data).filter(([key]) => key !== "info") as [
      string,
      Record<string, DashboardModelCompaniesModelDomain>,
    ][];
    let chartMap: GraphData = [];

    dataChart.forEach(([domain, domainData]) => {
      Object.entries(domainData).forEach(([, skuData]) => {
        const dataArr: GraphDataItems = [];
        let countLic = 0;
        Object.entries(skuData.quantity).forEach(([weekItem, weekItemData]) => {
          const obj = {
            x: weekItem,
            y: weekItemData,
          };
          countLic += weekItemData;
          dataArr.push(obj);
        });
        const diff = getDiffWeek(dataArr[dataArr.length - 1].x);
        diff.forEach((element) => {
          dataArr.push({
            x: element,
            y: 0,
          });
        });
        const tmp = {
          name: `${skuData.skuName} (${domain[0]})`,
          data: sortBy(dataArr, ["x"]),
          quantity: countLic,
        };
        if (countLic > 0) {
          chartMap.push(tmp);
          chartMap = reverse(sortBy(chartMap, ["quantity"]));
        }
      });
    });

    setGraphData(chartMap);
  };

  useEffect(() => {
    const getData = async () => {
      const minDate = DateTime.utc().minus({ years: 2 }).toJSDate();
      try {
        const [gsuiteQuerySnap, officeQuerySnap, graphDataDocSnap] = await Promise.all([
          getCollection(AuditLogModel)
            .doc("g-suite")
            .collection("api")
            .where("response.customer", "==", customer.ref)
            .where("success", "==", true)
            .where("timestamp", ">", minDate)
            .orderBy("timestamp")
            .get(),
          getCollection(AuditLogModel)
            .doc("office-365")
            .collection("api")
            .where("response.customer", "==", customer.ref)
            .where("success", "==", true)
            .where("timestamp", ">", minDate)
            .orderBy("timestamp")
            .get(),
          getCollection(DashboardModel).doc("licenseChart").collection("companies").doc(customer.id).get(),
        ]);

        let domains: string[] = [];

        const rowsFromSnaps =
          [gsuiteQuerySnap, officeQuerySnap].flatMap((querySnap) =>
            querySnap.docs.flatMap(
              (
                docSnap:
                  | QueryDocumentSnapshotModel<AuditLogModelGSuiteApiModel>
                  | QueryDocumentSnapshotModel<AuditLogModelOffice365ApiModel>
              ) => {
                const data = docSnap.asModelData();
                if (!data?.logLine) {
                  return [];
                }

                const row = {
                  id: docSnap.id,
                  email: data.email,
                  timestamp: DateTime.fromJSDate(data.timestamp.toDate()),
                  skuName: data.logLine.skuName,
                  cost: data.request.body?.total,
                  quantity: data.logLine.quantity,
                  domain: data.logLine.domain,
                };
                domains = uniq([...domains, row.domain]);

                return [row];
              }
            )
          ) ?? [];

        setRows(rowsFromSnaps);
        buildDataForLicenseChart(graphDataDocSnap);
        if (domains.length <= 1) {
          setShowDomain(false);
        } else {
          setShowDomain(true);
        }
        setNoHistory(rowsFromSnaps.length === 0);
      } catch (err) {
        consoleErrorWithSentry(err);
      }
    };
    getData().catch(consoleErrorWithSentry);
  }, [customer]);

  return (
    <OrderHistoryTable
      graphData={graphData}
      data={rows}
      showDomain={showDomain}
      loading={showDomain === null}
      noHistory={noHistory}
    />
  );
};

export default OrderHistory;
