import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";

import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { SettingsAdminItemWrapper } from "../SettingsAdminItemWrapper";
import { type SettingsValuesType } from "../types";

export function SkipRemedyBreach({
  value,
  onChange,
  errMsg,
}: {
  value: SettingsValuesType["skipRemedyBreach"];
  onChange: (newVal: SettingsValuesType["skipRemedyBreach"]) => void;
  errMsg: string | undefined;
}) {
  return (
    <SettingsAdminItemWrapper disabledForTrial={true}>
      {(isDisabled) => (
        <FormControl disabled={isDisabled}>
          <FormControlLabel
            sx={{ ml: -1 }}
            control={
              <Checkbox
                sx={{ padding: 0.75 }}
                name="skipRemedyBreach"
                checked={value}
                data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.SKIP_REMEDY_BREACH}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                size="small"
              />
            }
            label="Skip Notice to Remedy Breaches of Contract"
          />
          {!!errMsg && <FormHelperText sx={{ display: "flex", color: "error.main" }}>{errMsg}</FormHelperText>}
        </FormControl>
      )}
    </SettingsAdminItemWrapper>
  );
}
