import { defaultHeaderStyle } from "../../../../../Components/FilterTable/Table/FilterTableHeader";
import {
  type DataColumn,
  type HeaderColumn,
  numberComparators,
  stringComparators,
} from "../../../../../types/FilterTable";
import { mayNotBeApplicable, mayNotBeAvailable, threeDotHeaderSection } from "../../../assetUtils";

const displayNameColumn = {
  label: "Billing account\u00A0name",
  path: "displayName",
  comparators: stringComparators,
  toOption: mayNotBeApplicable,
} as const;
const billingAccountIdColumn = {
  label: "ID",
  path: "billingAccountId",
  comparators: stringComparators,
  toOption: (value) => ({ value, label: value }),
} as const;
const billingProfileColumn = {
  label: "Billing\u00A0profile",
  path: "billingProfileText",
  comparators: stringComparators,
  toOption: mayNotBeAvailable,
} as const;
const adminsColumn = {
  label: "Admins",
  path: "admins",
  comparators: numberComparators,
  toOption: (value) => ({ value, label: `${value}` }),
} as const;
const plpsPercentColumn = {
  label: "Customer\u00A0charge",
  path: "plpsPercent",
  comparators: numberComparators,
  toOption: mayNotBeApplicable,
} as const;
const projectsColumn = {
  label: "Projects",
  path: "numProjects",
  comparators: numberComparators,
  toOption: (value) => ({ value, label: `${value}` }),
} as const;
const assetTagColumn: DataColumn = {
  label: "Asset\u00A0tag",
  path: "assetTag",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeApplicable,
} as const;

const displayNameHeader = {
  value: displayNameColumn.path,
  label: displayNameColumn.label,
  style: defaultHeaderStyle,
};
const billingAccountIdHeader = {
  value: billingAccountIdColumn.path,
  label: billingAccountIdColumn.label,
  style: defaultHeaderStyle,
};
const billingProfileHeader = {
  value: billingProfileColumn.path,
  label: billingProfileColumn.label,
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};
const adminsHeader = {
  value: adminsColumn.path,
  label: adminsColumn.label,
  style: defaultHeaderStyle,
  hidden: { smDown: true },
};
const plpsPercentHeader = {
  value: plpsPercentColumn.path,
  label: plpsPercentColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};
const projectsHeader = {
  value: projectsColumn.path,
  label: projectsColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};
const assetTagHeader = {
  value: assetTagColumn.path,
  label: assetTagColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};

export const gcpPartnerHeaders: HeaderColumn[] = [
  displayNameHeader,
  billingAccountIdHeader,
  billingProfileHeader,
  adminsHeader,
  plpsPercentHeader,
  projectsHeader,
  assetTagHeader,
  threeDotHeaderSection,
];

export const gcpPartnerColumns: readonly DataColumn[] = [
  displayNameColumn,
  billingAccountIdColumn,
  billingProfileColumn,
  adminsColumn,
  plpsPercentColumn,
  projectsColumn,
  assetTagColumn,
];
