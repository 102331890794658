import { useState } from "react";

import { type CustomerSegment, CustomerSegmentValues } from "@doitintl/cmp-models";
import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { type SelectChangeEvent } from "@mui/material/Select";

import { generalTxt } from "../../../../assets/texts/Settings/general";
import { getCustomerSegmentOverride } from "../../../../Navigation/Components/Header/AvatarMenu/CustomerInfo/CustomerInfo";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { SettingsAdminItemWrapper } from "../SettingsAdminItemWrapper";

export const OverrideCustomerSegment = ({
  value,
  onChange,
}: {
  value: CustomerSegment;
  onChange: (newVal: CustomerSegmentValues | null) => void;
}) => {
  const [disabled, setDisabled] = useState(!value.overrideSegment);
  const [overrideSegment, setOverrideSegment] = useState<CustomerSegmentValues>(
    getCustomerSegmentOverride(value) ?? CustomerSegmentValues.NA
  );

  const handleChange = (event: SelectChangeEvent) => {
    setOverrideSegment(event.target.value as CustomerSegmentValues);
    onChange(event.target.value as CustomerSegmentValues);
  };
  return (
    <SettingsAdminItemWrapper disabledForTrial={false}>
      {() => (
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="start"
          pt={1}
          width="100%"
          sx={{ fontSize: "inherit" }}
        >
          <Checkbox
            sx={{ padding: 0, paddingRight: 0.75 }}
            name="overrideCustomerSegment"
            checked={!disabled}
            data-cy={DATA_CY_GENERAL_SETTINGS_SELECTORS.OVERRIDE_CUSTOMER_SEGMENT}
            onChange={(e) => {
              setDisabled(!e.target.checked);
              if (!e.target.checked) {
                onChange(null);
                setOverrideSegment(value.currentSegment || CustomerSegmentValues.NA);
              }
            }}
            size="small"
          />
          <Box
            display="flex"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
            sx={{ fontSize: "inherit" }}
          >
            <Box mb={2}>
              <Typography display="inline">{generalTxt.OVERRIDE_CUSTOMER_SEGMENT}</Typography>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="customer-segment-select-label">{generalTxt.CUSTOMER_SEGMENT}</InputLabel>
                <Select
                  disabled={disabled}
                  labelId="customer-segment-select-label"
                  id="customer-segment-select"
                  value={overrideSegment}
                  label={generalTxt.CUSTOMER_SEGMENT}
                  onChange={handleChange}
                >
                  {Object.keys(CustomerSegmentValues).map((key, i) => {
                    if (Object.values(CustomerSegmentValues)[i] === CustomerSegmentValues.NA) {
                      return null;
                    }
                    return (
                      <MenuItem key={key} value={key}>
                        {Object.values(CustomerSegmentValues)[i]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}
    </SettingsAdminItemWrapper>
  );
};
