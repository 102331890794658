import { useMemo } from "react";

import { Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";

import { formatNumber } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import { type ChartGraphData } from "./types";

const titles = ["Account Receivables", "Expected"] as const;

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flex: 1,
  },
  card: {
    height: "100%",
  },
  hintDiv: {
    background: "rgba(0,0,0,0.8)",
    padding: 5,
    borderRadius: 7,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 7,
    marginRight: 15,
  },
}));

type Props = { data: ChartGraphData };

const CashGraph = ({ data }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { isMobile } = useFullScreen();

  const categories = useMemo(
    () => Array.from(new Set([...data.receivables.map((point) => point.x), ...data.expected.map((point) => point.x)])),
    [data.expected, data.receivables]
  );

  // Map the data to the categories, filling with null where no value exists
  const receivablesData = useMemo(
    () =>
      categories.map((category) => {
        const point = data.receivables.find((point) => point.x === category);
        return point ? point.y : null;
      }),
    [categories, data.receivables]
  );

  const expectedData = useMemo(
    () =>
      categories.map((category) => {
        const point = data.expected.find((point) => point.x === category);
        return point ? point.y : null;
      }),
    [categories, data.expected]
  );

  const tickTotal = Math.floor((categories.length ?? 0) / (isMobile ? 7 : 35)) + 1;

  const categoriesDateTime = categories.map((item) => DateTime.fromFormat(item, "yyyy-LL-dd").toFormat("dd LLL")) || [];

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "column",
      backgroundColor: theme.palette.background.default,
    },
    credits: {
      enabled: false,
    },
    title: undefined,
    xAxis: {
      categories: categoriesDateTime,
      tickInterval: Math.max(1, Math.floor(categories.length / tickTotal)),
      type: "datetime", // x-axis will be datetime based
      labels: {
        enabled: true, // Enable x-axis labels (default)
        format: "{value:%Y-%m-%d}", // Format date label as YYYY-MM-DD
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Amount",
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          color: theme.palette.text.primary,
        },
      },
    },
    tooltip: {
      formatter: function formatter(this: Highcharts.TooltipFormatterContextObject) {
        return (
          `<strong>${DateTime.fromFormat(this.key!, "dd LLL").toFormat("dd LLL yyyy")}</strong><br/>` +
          `<strong>${this.series.name}:</strong> $${formatNumber(this.y)}`
        );
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        pointPadding: 0.1, // Space between columns
        groupPadding: 0.0, // Space between groups of columns
        borderWidth: 0, // Remove column borders
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "column",
        name: titles[0],
        data: receivablesData,
      },
      {
        type: "column",
        name: titles[1],
        data: expectedData,
      },
    ],
  };

  return (
    <Card className={classes.card}>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Card>
  );
};

export default CashGraph;
