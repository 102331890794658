import { NavLink } from "react-router-dom";
import { type FlexsaveType } from "@doitintl/cmp-models";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FlexsaveWidgetCard } from "../Common/FlexsaveWidgetCard";
import { FlexsaveAdvantages } from "./consts";
import { getFlexsaveWidgetTitle } from "./utils";

const useStyles = makeStyles(() => ({
  listIcon: {
    minWidth: "30px",
  },
}));

type Props = {
  flexsaveLink: string;
  type: FlexsaveType;
  widgetHeight: number;
};

export const FlexsaveCardNotifyMe = ({ flexsaveLink, type, widgetHeight }: Props) => {
  const classes = useStyles();

  return (
    <FlexsaveWidgetCard
      title={getFlexsaveWidgetTitle(type)}
      subheader="Maximize your monthly cloud savings with no commitment!"
      widgetHeight={widgetHeight}
    >
      <Box display="flex" justifyContent="space-around" flexDirection="column" height="100%">
        <List>
          {FlexsaveAdvantages.map((advantage) => (
            <ListItem key={advantage.title} dense>
              <ListItemIcon className={classes.listIcon}>
                <CheckIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="subtitle2">{advantage.title}</Typography>
              <Typography variant="body2">&nbsp;-&nbsp;{advantage.text}</Typography>
            </ListItem>
          ))}
        </List>

        <Box display="flex" mt={1} justifyContent="center">
          <Button component={NavLink} variant="contained" color="primary" to={flexsaveLink}>
            Discover Flexsave
          </Button>
        </Box>
      </Box>
    </FlexsaveWidgetCard>
  );
};
