import { Box, TextField } from "@mui/material";

import { useAuthContext } from "../../../../Context/AuthContext";
import { DATA_CY_GENERAL_SETTINGS_SELECTORS } from "../helpers";
import { text } from "../text";

export function CustomerName({
  value,
  onChange,
  errMsg,
}: {
  value?: string;
  onChange: (newName: string) => void;
  errMsg?: string;
}) {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  if (!isDoitEmployee) {
    return null;
  }

  return (
    <TextField
      fullWidth
      label={
        <Box sx={{ display: "flex" }}>
          {text.CUSTOMER_NAME_LABEL} &nbsp;
          <Box sx={{ display: "flex", color: "error.main" }}>*</Box>
        </Box>
      }
      value={value ?? ""}
      onChange={(e) => {
        onChange(e.target.value.trimStart());
      }}
      helperText={!!errMsg && <Box sx={{ display: "flex", color: "error.main" }}>{errMsg}</Box>}
      inputProps={{
        "data-cy": DATA_CY_GENERAL_SETTINGS_SELECTORS.CUSTOMER_NAME,
        "aria-label": text.CUSTOMER_NAME_LABEL,
      }}
    />
  );
}
