import { DateTime } from "luxon";

import { type Invoice, type InvoiceStatus } from "./types";

// getProcesssinPaymentsInformation returns pending payments information if the invoice was not in PAID status yet.
// and the sum of the pending payments.
export const getProcesssinPaymentsSum = (invoice: Invoice): number => {
  if (invoice.PAID) {
    return 0;
  }

  // processing payments are payments that are processing or succeeded, but not yet reflected in the invoice.DEBIT
  const processingPayments = invoice.stripePaymentIntents?.filter(
    (pi) =>
      pi.status === "processing" ||
      pi.status === "requires_capture" ||
      (pi.status === "succeeded" && pi.debit === invoice.DEBIT) // pi.debit is the invoice.DEBIT at the time of the payment
  );
  const processingPaymentSum = processingPayments
    ? processingPayments.reduce((sum, pi) => sum + pi.amount, 0) / 100
    : 0;

  return processingPaymentSum;
};

export const getInvoiceStatus = (invoice: Invoice): { _STATUS: InvoiceStatus; _STATUS_ID: number } => {
  const processingPaymentSum = getProcesssinPaymentsSum(invoice);
  const _BALANCE = Math.round((invoice.DEBIT ?? 0 - processingPaymentSum) * 100) / 100;
  const today = DateTime.utc().startOf("day");

  if (invoice.CANCELED) {
    return {
      _STATUS: "Canceled",
      _STATUS_ID: 8,
    };
  }

  if (invoice.isDraft) {
    return {
      _STATUS: "Proforma",
      _STATUS_ID: 7,
    };
  }
  if (processingPaymentSum > 0) {
    return {
      _STATUS: "Processing",
      _STATUS_ID: 6,
    };
  }
  if (invoice.PAID || _BALANCE <= 0) {
    return {
      _STATUS: "Paid",
      _STATUS_ID: 1,
    };
  }

  if (today > DateTime.fromJSDate(invoice.PAYDATE.toDate())) {
    if (_BALANCE < invoice.TOTPRICE) {
      return {
        _STATUS: "Past Due",
        _STATUS_ID: 4,
      };
    }
    return {
      _STATUS: "Past Due",
      _STATUS_ID: 5,
    };
  }

  if (_BALANCE < invoice.TOTPRICE) {
    return {
      _STATUS: "Partially Paid",
      _STATUS_ID: 2,
    };
  }

  return {
    _STATUS: "Open",
    _STATUS_ID: 3,
  };
};

export const fnv1aHash = (str: string): number => {
  const FNV_PRIME = 0x01000193; // 16777619
  const OFFSET_BASIS = 0x811c9dc5; // 2166136261

  let hash = OFFSET_BASIS;

  for (let i = 0; i < str.length; i++) {
    hash ^= str.charCodeAt(i); // XOR with the current character code
    hash = Math.imul(hash, FNV_PRIME); // Multiply by the FNV prime (using imul for 32-bit overflow)
  }

  // Return the result as a 32-bit unsigned integer
  return hash >>> 0;
};
