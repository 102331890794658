import { Box } from "@mui/material";

import { CloudSpendSummaryCards } from "../../../../Components/Dashboard/CloudSpendSummaryCards";
import { WidgetsGrid } from "./WidgetsGrid";
import type { Widget } from "../../../../Context/CurrentDashboardContext";

type Props = {
  widgets: Widget[];
  disableEditing: boolean;
  showSummaryCards: boolean;
};

export function WidgetsGridPageWrapper({ widgets, showSummaryCards, disableEditing }: Props) {
  return (
    <>
      <Box width="100%" mb={{ xs: 2, lg: 0 }}>
        {showSummaryCards && <CloudSpendSummaryCards />}
      </Box>

      <WidgetsGrid widgets={widgets} disableEditing={disableEditing} />
    </>
  );
}
