import { useEffect, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Customer } from "../../types/Customer";

export const useCustomerData = (customerId: string): Customer | undefined => {
  const [customerData, setCustomerData] = useState<Customer | undefined>(undefined);

  useEffect(() => {
    const customerDoc = getCollection(CustomerModel).doc(customerId);
    customerDoc.get().then((customer) => {
      const customerData = customer.asModelData();
      if (customerData) {
        setCustomerData({
          ...customerData,
          ref: customerDoc,
          id: customerId,
        });
      }
    });
  }, [customerId]);
  return customerData;
};
