import { Paper, Radio, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { type BillingProfile } from "../../../types";
import { parsePaymentMethod } from "../../../utils/dataParsing";

type Props = {
  checked: boolean;
  onClick: () => void;
  billingProfile: BillingProfile;
};

export const BillingItem = ({ checked, onClick, billingProfile }: Props) => {
  const theme = useTheme();
  return (
    <Paper
      variant="outlined"
      sx={{
        borderColor: checked ? theme.palette.primary.main : theme.palette.divider,
        borderWidth: checked ? 2 : 1,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Stack direction="row">
        <Radio checked={checked} sx={{ alignSelf: "flex-start", p: 2 }} />
        <Stack py={2} spacing={1}>
          <Stack direction="row">
            <Typography fontWeight="500" mr={1}>
              Name:
            </Typography>
            {billingProfile.name}
          </Stack>
          <Stack direction="row">
            <Typography fontWeight="500" mr={1}>
              ID:
            </Typography>
            {billingProfile.priorityId}
          </Stack>
          <Stack direction="row">
            <Typography fontWeight="500" mr={1}>
              Payment method:
            </Typography>
            {billingProfile.currency} | {parsePaymentMethod(billingProfile.paymentMethod)}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};
