import { TableCell, TableRow } from "@mui/material";
import clsx from "clsx";

import { type FirestoreTimestamp } from "../../../../../utils/firebase";
import { formatUSDollars } from "../../../../../utils/formatUSDollars";

type ActualsRowProps = {
  planStart: FirestoreTimestamp;
  periodActualData: number[];
  periodMonthsArr: Array<Array<number>>;
  tableStartPos: number;
  periodStart: FirestoreTimestamp;
  periodNum: number;
  tableEndPos: number;
  classes: Record<string, string>;
  fillerLength: number;
};

const ActualsRow = (props: ActualsRowProps) => {
  const { periodActualData, periodMonthsArr, tableStartPos, periodNum, tableEndPos, classes, fillerLength } = props;

  const actualCellsRow = periodMonthsArr[periodNum - 1].slice(tableStartPos, tableEndPos).map((_item, index) => (
    <TableCell
      key={`idx-${index.toString()}`}
      padding="none"
      size="small"
      className={clsx(classes.borderedCell, classes.rowCellInput)}
    >
      {!periodActualData[index + tableStartPos] ? "-" : formatUSDollars(periodActualData[index + tableStartPos])}
    </TableCell>
  ));

  const fillerActuals = new Array(fillerLength - actualCellsRow.length).fill(
    <TableCell className={clsx(classes.borderedCell, classes.rowCellInput)} />
  );

  return (
    <TableRow>
      <TableCell padding="none" size="small" className={clsx(classes.borderedCell, classes.menuCell)}>
        Actual spend
      </TableCell>
      {[...actualCellsRow, ...fillerActuals]}
    </TableRow>
  );
};
export default ActualsRow;
