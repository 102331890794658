import { SaaSConsoleType } from "@doitintl/cmp-models";
import { Button, Stack, Typography } from "@mui/material";

import SuccessLogo from "../../../../assets/complete-profile-no-permissions.svg";
import SuccessDarkLogo from "../../../../assets/complete-profile-no-permissions-dark.svg";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";

type Props = {
  handleClose: () => void;
  type: SaaSConsoleType;
};

const SuccessMessage = ({ handleClose, type }: Props) => {
  const darkMode = useDarkThemeCheck();
  const connectToText =
    type === SaaSConsoleType.GCP ? "Google Cloud" : type === SaaSConsoleType.AZURE ? "Azure" : "AWS account";

  return (
    <Stack spacing={2} alignItems="center" maxWidth="md">
      <img src={darkMode ? SuccessDarkLogo : SuccessLogo} alt="onboarding-success" width="100" height="auto" />
      <Typography variant="h1" sx={{ alignSelf: "center", fontWeight: "500", pt: 2 }}>
        Your {connectToText} was successfully connected
      </Typography>
      <Typography textAlign="center" variant="body1" color="text.secondary">
        Please note that we are currently processing your data, which might take up to 24 hours.
      </Typography>
      <Button variant="contained" onClick={handleClose}>
        Return to Dashboard
      </Button>
    </Stack>
  );
};

export default SuccessMessage;
