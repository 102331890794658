import React from "react";

import { useHistory } from "react-router-dom";
import { AssetTypeSnowflake, type DataConnectorSnowflakeModel } from "@doitintl/cmp-models";
import { Button } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import NoAssets from "./components/NoAssets";
import SnowflakeAssetsTabHeader from "./components/snowflake/SnowflakeAssetsTabHeader";
import SnowflakeAssetTable from "./components/snowflake/SnowflakeAssetTable";

const SnowflakeAssetsTab = ({ sfConfigs }: { sfConfigs: DataConnectorSnowflakeModel[] }) => {
  const { customer } = useCustomerContext();
  const history = useHistory();

  if (sfConfigs && sfConfigs.length === 0) {
    return (
      <NoAssets assetType={AssetTypeSnowflake}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(`/customers/${customer.id}/cloud-onboarding-snowflake`);
          }}
        >
          Connect new account
        </Button>
      </NoAssets>
    );
  }

  return (
    <>
      <SnowflakeAssetsTabHeader />
      <SnowflakeAssetTable configs={sfConfigs} />
    </>
  );
};

export default SnowflakeAssetsTab;
