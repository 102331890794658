import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { pricebooksText } from "../../../assets/texts";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../ThreeDotsMenu";

type Props = {
  onCopy: () => void;
  onDelete: () => void;
};

const AWSPricebook3DotMenu = (props: Props) => {
  const { onCopy, onDelete } = props;
  const theme = useTheme();
  const actionOptions: ThreeDotsMenuOption[] = [
    {
      label: pricebooksText.DUPLICATE_PRICEBOOK,
      action: () => {
        onCopy();
      },
      tooltip: pricebooksText.DUPLICATE_PRICEBOOK,
      tooltipPlacement: "left",
      key: "duplicate",
      dataCy: "duplicate-pricebook",
    },
    {
      label: <Typography color={theme.palette.error.dark}>{pricebooksText.DELETE_PRICEBOOK}</Typography>,
      action: () => {
        onDelete();
      },
      tooltip: pricebooksText.DELETE_PRICEBOOK,
      tooltipPlacement: "left",
      key: "delete",
      dataCy: "delete-pricebook",
    },
  ];

  return <ThreeDotsMenu options={actionOptions} size="small" dense={true} data-cy="aws-pricebook-3dots-menu" />;
};
export default AWSPricebook3DotMenu;
