import { IntegrationModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type FirebaseUserWithEmail } from "../../../../Context/UserContext";
import { deleteField, serverTimestamp } from "../../../../utils/firebase";
import { addNote, generateNotePayloadForCustomer } from "../../../Customer/CustomerNotes/db";
import { type TagKeys } from "../../../Customer/CustomerNotes/types";

const workloadsDocRef = (workloadId: string) =>
  getCollection(IntegrationModel).doc("flexsave").collection("gcp-ops-workloads").doc(workloadId);

const opsPageDocRef = () =>
  getCollection(IntegrationModel).doc("flexsave").collection("gcp-operations").doc("opsPageState");

const customersDocRef = (customerId: string) =>
  getCollection(IntegrationModel).doc("flexsave").collection("gcp-ops-customers-aggr").doc(customerId);

export async function updateMaxTargetCoverageForCustomer(customerId: string, targetCoverage: number) {
  await customersDocRef(customerId).update({ targetCoverage });
}

export async function updateForceTargetCoverageForCustomer(customerId: string, forceTargetCoverage: boolean) {
  await customersDocRef(customerId).update({ forceTargetCoverage });
}

export async function updateWorkloadTargetCoverage(workloadId: string, targetCoverage: number) {
  await workloadsDocRef(workloadId).update({ targetCoverage });
}

export async function deleteWorkloadTargetCoverage(workloadId: string) {
  await workloadsDocRef(workloadId).update({
    targetCoverage: deleteField(),
  });
}

export async function toggleBulkExclusion(customerId: string, excludeCustomerFromBulk: boolean) {
  await customersDocRef(customerId).update({
    "bulkPurchase.excludeCustomerFromBulk": excludeCustomerFromBulk,
    "bulkPurchase.timeUpdated": serverTimestamp(),
  });
  await opsPageDocRef().update({
    excludeCustomersBulkChangedAt: serverTimestamp(),
  });
}

export async function approvePurchasePlan(workloadId: string, adminEmail: string) {
  await workloadsDocRef(workloadId).update({
    "purchasePlan.risks.approvedAt": serverTimestamp(),
    "purchasePlan.risks.approvedBy": adminEmail,
  });
}

export async function rejectPurchasePlan(workloadId: string, adminEmail: string) {
  await workloadsDocRef(workloadId).update({
    "purchasePlan.risks.rejectedAt": serverTimestamp(),
    "purchasePlan.risks.rejectedBy": adminEmail,
  });
}

export async function saveNote(
  customerId: string,
  message: string,
  tags: TagKeys[],
  currentUser: FirebaseUserWithEmail
) {
  const note = await generateNotePayloadForCustomer(customerId, message, tags, currentUser);
  await addNote(note);
}
