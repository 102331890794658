export const templateLibraryTxt = {
  EXPAND_INSIGHTS: "Expand your insights on the cloud with reports published and updated by DoiT.",
  REPORT_TEMPLATES: "Report templates",
  PENDING_APPROVAL: "Pending approval",
  CHANGES_PENDING: "Changes pending",
  CHANGES_REJECTED: "Changes rejected",
  NEW_TEMPLATE: "New template",
  CREATE_NEW_TEMPLATE: "Create new template",
  SEARCH_TEMPLATES: "Search templates",
  ALL: "All",
  CATEGORY: "Category",
  METRIC: "Metric",
  CLOUD: "Cloud",
  NO_REPORT_TEMPLATES_FOUND: "No report templates found",
  PLEASE_REMOVE_FILTERS: "Please try again by removing some of the filters",
  TEMPLATE: "Template",
  TEMPLATE_SAVED_SUCCESSFULLY: "Template saved successfully",
  TEMPLATE_SUBMITTED_FOR_APPROVAL: "Template submitted for approval",
  TEMPLATE_SUBMIT_ERROR: "Error submitting template",
  TEMPLATE_SAVE_ERROR: "Error saving template",
  TEMPLATE_APPROVED_SUCCESSFULLY: "Template approved and published successfully",
  TEMPLATE_APPROVE_ERROR: "Error approving template",
  REJECT_TEMPLATE: "Reject template",
  REJECT_REASON: "Provide the reason(s) why this template is being rejected.",
  REASON_FIELD_LABEL: "Reason(s)",
  REJECT: "Reject",
  TEMPLATE_REJECTED_SUCCESSFULLY: "Template rejected and user notified",
  TEMPLATE_REJECT_ERROR: "Error rejecting template",
  DELETE_TEMPLATE: "Delete template",
  DELETE_TEMPLATE_CONFIRMATION: "Are you sure you want to delete this template? This action cannot be undone.",
  TEMPLATE_DELETED_SUCCESSFULLY: "Template deleted successfully",
  TEMPLATE_DELETE_ERROR: "Error deleting template",
  RESET_FILTERS: "Reset filters",
};

export const templateCreateText = {
  TITLE: "Template details",
  HEADER_DESCRIPTION:
    "Provide the info below to save this report as a template, and make it easy for users to search and find it:",
  VISIBILITY: "Visibility",
  CATEGORIES: "Categories",
  CLOUDS: "Cloud",
  VISIBILITY_DESCRIPTIONS: {
    PRIVATE: "This template will be only visible to you.",
    DOIT: "This template will be visible to all Doers. Subjected to approval.",
    PUBLIC: "This template will be visible to all Doers and customers. Subjected to approval.",
  },
  PENDING_APPROVAL: "Pending approval",
  CREATE_TEMPLATE: "Create template",
  SUBMIT_FOR_APPROVAL: "Submit for approval",
  SAVE_TEMPLATE: "Save template",
  FILL_REQUIRED_FIELDS: "Please fill in the required fields",
  DOER_PENDING_ALERT_DESC: "You'll get an email letting you know if your template was approved",
  DOER_REJECTED_ALERT_TITLE: "Fix issues and re-submit",
  REVIEWER_PENDING_ALERT_DESC: (createdBy: string, date: string) => `Submitted by ${createdBy} on ${date}`,
  REVIEWER_REJECTED_ALERT_TITLE: "Waiting for re-submission",
};

export const templateDialog = {
  UPDATE_ON: "Updated on ",
  CONFIGURATION: "Configuration",
  PREVIEW: "Preview",
  GROUPING: "Grouping",
  DIMENSIONS: "Dimensions",
  EXPLORE_TEMPLATE: "Explore template",
  EXCLUDE_PARTIAL_INTERVAL: "excluding partial intervals",
  TIME_INTERVAL: "Time Interval",
  TIME_RANGE: "Time Range",
};
