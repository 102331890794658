import { useCallback, useMemo } from "react";

import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";

import SuperqueryTableTypeCard from "../../Components/Dashboard/BigQueryLens/TableTypeCard";
import RecommenderCard from "../../Components/Dashboard/RecommenderCard";
import { type DashboardsContextType } from "../../Context/DashboardContext";
import { GSuiteAssetCard } from "../../Pages/Assets/Cards/GSuiteAssetCard";
import { Office365AssetCard } from "../../Pages/Assets/Cards/Office365AssetCard";
import AccountManagersCard from "./AccountManagersCard";
import BudgetsCard from "./Analytics/BudgetsCard";
import CloudReportCard from "./Analytics/CloudReportCard";
import RecommendationsCard from "./BigQueryLens/RecommendationsCard";
import RollUpsCard from "./BigQueryLens/RollUpsCard";
import { SlotExplorer } from "./BigQueryLens/SlotExplorer";
import CloudIncidentsCard from "./CloudIncidentsCard";
import { CloudSpendSummaryCards } from "./CloudSpendSummaryCards";
import CommitmentContractsCard from "./CommitmentContractsCard";
import CostAnomaliesWidgetCard from "./CostAnomaliesWidgetCard";
import CreditsCard from "./CreditsCard";
import EmptyWidgetCard from "./EmptyWidgetCard";
import EntitiesCard from "./EntitiesCard";
import ErrorCard from "./ErrorCard";
import { FlexsaveCardAWS, FlexsaveCardGCP } from "./Flexsave/FlexsaveCard";
import { FlexsaveWidgetKey } from "./Flexsave/types";
import InvoicesCard from "./InvoicesCard";
import LicensesCard from "./LicensesCard";
import RampPlansWidgetCard from "./RampPlansWidgetCard";
import RenewalsCard from "./RenewalsCard";
import { ServiceLimitsAWS, ServiceLimitsGCP } from "./ServiceLimitsCard";
import SupportCard from "./SupportCard";

const componentsTypesMapping = {
  accountManagers: AccountManagersCard,
  entities: EntitiesCard,
  invoices: InvoicesCard,
  commitmentContracts: CommitmentContractsCard,
  credits: CreditsCard,
  renewals: RenewalsCard,
  licenseGraph: LicensesCard,
  supportGraph: SupportCard,
  serviceLimits: ServiceLimitsAWS,
  serviceLimitsGoogleCloud: ServiceLimitsGCP,
  recommender: RecommenderCard,
  superqueryTableType: SuperqueryTableTypeCard,
  rollUps: RollUpsCard,
  bigQueryrecommendationsTable: RecommendationsCard,
  gsuiteAssetCard: GSuiteAssetCard,
  office365AssetCard: Office365AssetCard,
  cloudReports: CloudReportCard,
  budgets: BudgetsCard,
  costAnomalies: CostAnomaliesWidgetCard,
  slotExplorer: SlotExplorer,
  [FlexsaveWidgetKey.AWS]: FlexsaveCardAWS,
  [FlexsaveWidgetKey.GCP]: FlexsaveCardGCP,
  cloudSpendSummary: CloudSpendSummaryCards,
  activeCloudIncidents: CloudIncidentsCard,
  rampPlans: RampPlansWidgetCard,
};

export function WidgetContent({
  widget,
  widgetHeight,
}: {
  widget: DashboardsContextType["dashboards"][number]["widgets"][number];
  widgetHeight: number;
}) {
  const childWithProps = useMemo(() => {
    const isTemplateWidget = widget.name.includes("::");

    const widgetBaseName = isTemplateWidget ? widget.name.substring(0, widget.name.indexOf("::")) : widget.name;
    const widgetId = isTemplateWidget ? widget.name.substring(widget.name.lastIndexOf(":") + 1) : widget.name;

    const Component = componentsTypesMapping[widgetBaseName];
    const fallbackComponent = (
      <EmptyWidgetCard
        widgetHeight={widgetHeight}
        name={widgetBaseName}
        customMessageSettings={{
          text: "No data available",
          url: "",
        }}
      />
    );

    if (!Component) {
      return fallbackComponent;
    }

    return (
      <Component
        fallbackComponent={fallbackComponent}
        widgetId={widgetId}
        customEmptyReportMessage={""}
        widgetHeight={widgetHeight}
      />
    );
  }, [widgetHeight, widget.name]);

  const fallback = useCallback(({ error }) => <ErrorCard error={error} widget={widget} />, [widget]);

  const getWidgetContent = () => (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Sentry.ErrorBoundary
        fallback={fallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        {childWithProps}
      </Sentry.ErrorBoundary>
    </div>
  );

  return (
    <Box width="100%" height="100%">
      {getWidgetContent()}
    </Box>
  );
}
