import { useEffect, useState } from "react";

import {
  AppModel,
  AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY,
  AWS_ACCOUNT_COMMANDS_COLLECTION_KEY,
  AWS_ACCOUNTS_COLLECTION_KEY,
  type AwsAccountCommand,
  AwsAccountCommandStatus,
  AwsAccountCommandType,
  AwsAccountCompletionStep,
  type AwsAccountModel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { serverTimestamp } from "../../../utils/firebase";

export type AwsAccountBatchCreationRequest = {
  accountNamePrefix: string;
  emailPrefix: string;
  zeroPadding: number;
  fromIndex: number;
  toIndex: number;
};

export type AwsAccountBasicData = {
  accountName: string;
  email: string;
};

export type ActiveAwsAccountCommand = ModelIdRef<AwsAccountCommand>;

export enum AwsAccountStatusType {
  SCHEDULED = "Scheduled",
  IN_PROGRESS = "In progress",
  INCOMPLETE = "Incomplete",
  COMPLETE = "Complete",
  FAILED = "Failed",
}

export type AwsAccountStatusDescriptor = {
  status: AwsAccountStatusType;
  completion: number;
};

export type AwsAccount = ModelIdRef<AwsAccountModel> & {
  activeCommand: ActiveAwsAccountCommand | null;
  statusDescriptor: AwsAccountStatusDescriptor;
};
export function useAwsAccountsSubscription(): AwsAccount[] {
  const [awsAccounts, setAwsAccounts] = useState<AwsAccount[]>([]);

  useEffect(
    () =>
      getCollection(AppModel)
        .doc(AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY)
        .collection(AWS_ACCOUNTS_COLLECTION_KEY)
        .orderBy("accountName", "desc")
        .onSnapshot((querySnapshot) => {
          setAwsAccounts((previousAwsAccounts) => {
            const updatedAwsAccounts = Array.from(previousAwsAccounts);

            querySnapshot.docChanges().forEach((change) => {
              const activeCommand = previousAwsAccounts[change.oldIndex]?.activeCommand ?? null;
              if (["removed", "modified"].includes(change.type)) {
                updatedAwsAccounts.splice(change.oldIndex, 1);
              }
              if (["added", "modified"].includes(change.type)) {
                const awsAccount = change.doc.asModelData();
                updatedAwsAccounts.splice(change.newIndex, 0, {
                  ...awsAccount,
                  id: change.doc.id,
                  ref: change.doc.modelRef,
                  activeCommand,
                  statusDescriptor: generateStatusDescriptor(awsAccount, activeCommand),
                });
              }
            });

            return updatedAwsAccounts;
          });
        }),
    []
  );

  useEffect(
    () =>
      getCollection(AppModel)
        .doc(AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY)
        .collection(AWS_ACCOUNT_COMMANDS_COLLECTION_KEY)
        .onSnapshot((querySnapshot) => {
          setAwsAccounts((previousAwsAccounts) => {
            const updatedAwsAccounts = Array.from(previousAwsAccounts);

            querySnapshot.docChanges().forEach((change) => {
              const activeCommandData = change.doc.data();
              const awsAccountToUpdateIdx = updatedAwsAccounts.findIndex(
                ({ id }) => id === activeCommandData.accountId
              );
              const awsAccountToUpdate = updatedAwsAccounts[awsAccountToUpdateIdx];
              if (awsAccountToUpdate) {
                let activeCommand: ActiveAwsAccountCommand | null = null;
                switch (change.type) {
                  case "added":
                  case "modified":
                    activeCommand = {
                      ...activeCommandData,
                      id: change.doc.id,
                      ref: change.doc.modelRef,
                    };
                    break;
                }
                updatedAwsAccounts.splice(awsAccountToUpdateIdx, 1, {
                  ...awsAccountToUpdate,
                  activeCommand,
                  statusDescriptor: generateStatusDescriptor(awsAccountToUpdate, activeCommand),
                });
              }
            });
            return updatedAwsAccounts;
          });
        }),
    []
  );

  return awsAccounts;
}

export async function createAwsAccount(awsAccount: AwsAccountBasicData): Promise<boolean> {
  const awsAccountRef = await getCollection(AppModel)
    .doc(AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY)
    .collection(AWS_ACCOUNTS_COLLECTION_KEY)
    .add({
      ...awsAccount,
      genuineAwsAccountId: null,
      completeSteps: [],
      phoneNumber: null,
      creditCard: null,
    });
  await runCreateAwsAccountCommand(awsAccountRef.id);
  return true;
}

export async function runCreateAwsAccountCommand(awsAccountId: string): Promise<void> {
  await getCollection(AppModel)
    .doc(AUTO_GENERATED_AWS_ACCOUNTS_DOC_KEY)
    .collection(AWS_ACCOUNT_COMMANDS_COLLECTION_KEY)
    .add({
      accountId: awsAccountId,
      status: AwsAccountCommandStatus.SCHEDULED,
      type: AwsAccountCommandType.CREATE_AWS_ACCOUNT,
      captcha: null,
      errorMessage: null,
      errorOccurredAt: null,
      processingStartedAt: null,
      retryCount: 0,
      timeCreated: serverTimestamp(),
      parameters: {},
    });
}

export async function runTerminateActiveCommand(awsAccount: AwsAccount): Promise<void> {
  if (awsAccount.activeCommand === null) {
    return undefined;
  }
  return awsAccount.activeCommand.ref.delete();
}

export async function submitCaptchaSolution(
  awsAccount: AwsAccount,
  { captchaSolution }: { captchaSolution: string }
): Promise<void> {
  if (awsAccount.activeCommand?.captcha?.captchaImageURL) {
    return awsAccount.activeCommand.ref.update({
      captcha: {
        captchaImageURL: awsAccount.activeCommand.captcha?.captchaImageURL,
        captchaSolution,
      },
    });
  }
}

const COMPLETION_STEPS_COUNTER = Object.keys(AwsAccountCompletionStep).length;

function isFailedCommand(awsAccountCommand: AwsAccountCommand): boolean {
  return (
    awsAccountCommand.retryCount >= 29 ||
    DateTime.fromJSDate(awsAccountCommand.timeCreated?.toDate()).diffNow("seconds").seconds > 86400
  );
}

function generateStatusDescriptor(
  awsAccount: AwsAccountModel,
  awsAccountCommand: AwsAccountCommand | null
): AwsAccountStatusDescriptor {
  const completion = awsAccount.completeSteps.length / COMPLETION_STEPS_COUNTER;
  if (awsAccountCommand === null) {
    if (completion >= 1) {
      return {
        status: AwsAccountStatusType.COMPLETE,
        completion,
      };
    }
    return {
      status: awsAccount.completeSteps.length === 0 ? AwsAccountStatusType.SCHEDULED : AwsAccountStatusType.INCOMPLETE,
      completion,
    };
  }

  return {
    status: isFailedCommand(awsAccountCommand) ? AwsAccountStatusType.FAILED : AwsAccountStatusType.IN_PROGRESS,
    completion,
  };
}
