import { useMemo } from "react";

import { type CustomerModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from "@mui/material";
import { Formik } from "formik";
import { object, string } from "yup";

import { useFullScreen } from "../../../../utils/dialog";
import { isPlanNameUnique } from "../../utils";

export type RampPlanFormData = {
  name: string;
};

export type RampPlanNameEditorProps = {
  open: boolean;
  rampPlanFormData: RampPlanFormData;
  rampPlanId: string;
  customerRef: ModelReference<CustomerModel>;
  onClose: () => void;
  onSubmit: (mpaActivationData: RampPlanFormData) => Promise<void>;
};

const cypressFieldsIds: Record<keyof RampPlanFormData, string> = {
  name: "name-field",
};

export const cypressIds = {
  cancelButton: "mpa-cancel-button",
  submitButton: "submit-button",
  fields: cypressFieldsIds,
};

const FIELD_LABELS: Record<keyof RampPlanFormData, string> = {
  name: "Ramp plan name",
};

export const RampPlanNameEditor = ({
  open,
  rampPlanFormData,
  rampPlanId,
  customerRef,
  onClose,
  onSubmit,
}: RampPlanNameEditorProps) => {
  const { fullScreen } = useFullScreen();
  const validationSchema = useMemo(
    () =>
      object({
        name: string()
          .trim()
          .required("Ramp plan name cannot be empty")
          .label(FIELD_LABELS.name)
          .test("uniqueness", "This ramp plan name already exists", async (value) =>
            value ? !(await isPlanNameUnique(value, rampPlanId, customerRef)) : true
          ),
      }),
    [customerRef, rampPlanId]
  );

  const { name } = rampPlanFormData;
  return (
    <Dialog fullScreen={fullScreen} aria-labelledby="ramp-plan-name-editor" open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Edit ramp plan name</DialogTitle>
      <Formik<RampPlanFormData> initialValues={{ name }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => {
          const hasError = (fieldName: keyof RampPlanFormData) => touched[fieldName] && errors[fieldName] !== undefined;
          return (
            <>
              <DialogContent>
                <Stack gap={2} mt={1}>
                  <TextField
                    name="name"
                    disabled={isSubmitting}
                    helperText={hasError("name") ? errors.name : undefined}
                    error={hasError("name")}
                    value={values.name}
                    onChange={handleChange}
                    variant="outlined"
                    label={FIELD_LABELS.name}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    data-cy={cypressFieldsIds.name}
                  />
                </Stack>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={onClose} color="primary" data-cy={cypressIds.cancelButton}>
                  Cancel
                </Button>
                <LoadingButton
                  onClick={() => {
                    handleSubmit();
                  }}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  data-cy={cypressIds.submitButton}
                >
                  Edit name
                </LoadingButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
