import { type Cluster, ClusterDiscoveryStatus } from "@doitintl/cmp-models";
import { type SxProps, TableCell, type Theme } from "@mui/material";

import ClusterStatus from "./ClusterStatus";
import ClusterType from "./ClusterType";

interface ClusterTableRowProps {
  row: Cluster;
}

const ClusterTableRow = ({ row }: ClusterTableRowProps) => {
  const clusterNameStyle: SxProps<Theme> = {
    textDecoration: row.discoveryStatus === ClusterDiscoveryStatus.DELETED ? "line-through" : "none",
  };

  return (
    <>
      <TableCell sx={clusterNameStyle}>{row.name}</TableCell>
      <TableCell>
        <ClusterType type={row.type} />
      </TableCell>
      <TableCell>{row.region}</TableCell>
      <TableCell>{row.projectId}</TableCell>
      <TableCell>{row.kubernetesControlPlaneVersion}</TableCell>
      <TableCell>
        <ClusterStatus status={row.connectionStatus} />
      </TableCell>
    </>
  );
};

export default ClusterTableRow;
