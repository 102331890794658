import { type JSX } from "react";

import { Box, Button, Paper, Stack, Typography } from "@mui/material";

type Props = {
  onClick: () => void;
  icon: React.ReactNode;
  title: string;
  subtitle: JSX.Element;
  textButton: string;
  buttonVariant?: "text" | "contained" | "outlined";
  disabled?: boolean;
  dataCy?: string;
};

export const HeaderCard = ({ onClick, icon, title, subtitle, textButton, buttonVariant, dataCy, disabled }: Props) => (
  <Paper variant="outlined" sx={{ p: 3 }}>
    <Stack direction="row" spacing={2} maxWidth={446}>
      <Stack
        sx={{
          p: 1,
          borderColor: "divider",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 15,
          alignSelf: "start",
        }}
      >
        {icon}
      </Stack>
      <Stack height="100%" justifyContent="space-between">
        <Stack>
          <Typography variant="subtitle1" fontWeight={500} pb={1}>
            {title}
          </Typography>
          <Typography component={Box} pb={2} variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </Stack>
        <Button
          variant={buttonVariant}
          onClick={onClick}
          sx={{ width: "fit-content" }}
          data-cy={dataCy}
          disabled={disabled}
        >
          {textButton}
        </Button>
      </Stack>
    </Stack>
  </Paper>
);
