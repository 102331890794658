import { useMemo } from "react";

import { IntegrationModel, type TotalAverageUsageModal } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import memoizeOne from "memoize-one";

const totalAverageUsagesQuery = memoizeOne(() =>
  getCollection(IntegrationModel).doc("flexsave").collection("gcp-total-avg-usage")
);

export function useTotalAverageUsages() {
  const [items] = useCollectionData(totalAverageUsagesQuery(), {
    idField: "id",
  });

  return useMemo(() => {
    if (!items) {
      return;
    }

    const map = new Map<string, TotalAverageUsageModal>();
    for (const item of items) {
      map.set(item.id, item);
    }
    return map;
  }, [items]);
}
