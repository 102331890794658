import { type CustomerSavingsPlan } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { Box, CircularProgress } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { FilterTable } from "../../../../../../Components/FilterTable/FilterTable";
import BorderedBox from "../../../../BorderedBox";
import { customerSavingsPlansColumns } from "./columns";
import { CustomerSavingsPlansRow } from "./CustomerSavingsPlansRow";

type Props = {
  customerName: string;
  data: WithFirebaseModel<CustomerSavingsPlan>[] | null;
};

export const CustomerSavingsPlansTable = ({ customerName, data }: Props) => {
  const theme = useTheme();

  return (
    <Box pt={5}>
      <BorderedBox>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: `1px solid ${theme.palette.general.divider}` }}
          pr={4}
        >
          <CardHeader
            titleTypographyProps={{ variant: "h4" }}
            title={`${customerName} Savings Plans overview`}
            subheaderTypographyProps={{ variant: "body2" }}
          />
        </Box>

        <Box px={2} pb={4} paddingBottom="0px">
          {!data ? (
            <Box display="flex" justifyContent="center" alignItems="center" p={6}>
              <CircularProgress />
            </Box>
          ) : data.length === 0 ? (
            <Typography
              variant="body2"
              component="p"
              textAlign="center"
              sx={{ color: theme.palette.text.secondary }}
              padding={2}
            >
              You don't have any Savings Plans to display
            </Typography>
          ) : (
            <Box overflow="auto">
              <FilterTable
                tableItems={data}
                rowComponent={CustomerSavingsPlansRow}
                headerColumns={customerSavingsPlansColumns}
                filterColumns={[]}
                showFilterBar={false}
                defaultSortingColumnValue="expirationDate"
                defaultSortDirection="asc"
                fillEmptySpace={false}
              />
            </Box>
          )}
        </Box>
      </BorderedBox>
    </Box>
  );
};
