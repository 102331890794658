import { useCallback, useMemo, useState } from "react";

import { type CloudConnectAmazonWebServices, CustomerModel, type Spot0ModelAsgsModel } from "@doitintl/cmp-models";
import { getCollection, type QueryModel, type TransformMethod, useCollectionData } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { useAsyncCurrency } from "../../Components/hooks/useCurrency";
import { useCustomerContext } from "../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../utils";
import { documentId } from "../../utils/firebase";
import { AWSFeatureName } from "../Settings/AWS/types";
import { groupCostsSavingsHistoryByTimeFilter, groupSpendingHistoryByTimeFilter } from "./AsgsOverview/Savings/utils";
import { asgsCollection } from "./db";
import { type AsgInstancesCosts, type AsgItem, SpotScalingMode } from "./types";
import { calculateCurrentMonthSavingDays, parseAsgConfiguration } from "./Utils/asgConfigurationUtils";
import { getOptimizationStatus } from "./Utils/costsUtils";
import {
  asyncGetSavingsHistory,
  asyncGetSpendingHistory,
  asyncGetUsageHistory,
  formatDate,
  getUsageKeyByIndex,
} from "./Utils/datesUtils";

/**
 * Get ASGs by query for specific customer
 * @param query - additional query to apply
 */
export const useAsgs = (query: QueryModel<Spot0ModelAsgsModel>) => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();

  const { asyncConvertCurrency } = useAsyncCurrency();

  const convertInstancesCosts = useCallback(
    async (instancesCosts: AsgInstancesCosts): Promise<AsgInstancesCosts> => ({
      spotHourCost: await asyncConvertCurrency(instancesCosts.spotHourCost),
      onDemandHourCost: await asyncConvertCurrency(instancesCosts.onDemandHourCost),
    }),
    [asyncConvertCurrency]
  );

  const transform: TransformMethod<Spot0ModelAsgsModel, Promise<AsgItem>> = useCallback(
    async (data, snapshot): Promise<AsgItem> => {
      const currentConfiguration = parseAsgConfiguration(
        data.spotisize.curAsg,
        data.spotisize.costs.averageDesiredCapacity,
        await convertInstancesCosts(data.spotisize.costs.cur),
        data.config,
        data.instanceTypesDetails,
        data.subnetsDetails,
        data.spotisize.curAsgDetails
      );

      const recommendedConfiguration = data.spotisizeNotSupported
        ? undefined
        : parseAsgConfiguration(
            data.spotisize.recAsg,
            data.spotisize.costs.averageDesiredCapacity,
            await convertInstancesCosts(data.spotisize.costs.rec),
            data.config,
            data.instanceTypesDetails,
            data.subnetsDetails
          );

      const recommendationMonthlySaving = recommendedConfiguration
        ? currentConfiguration.monthlyCost - recommendedConfiguration.monthlyCost
        : 0;

      const keepUpToDate = data.mode === SpotScalingMode.Autopilot;

      const timeStartedUsingSpots = data?.timeStartedUsingSpots?.toDate();
      const currentMonthActualSavingsDays = calculateCurrentMonthSavingDays(timeStartedUsingSpots);

      const startDate = DateTime.local();
      const lastMonthUsage = await asyncGetUsageHistory(
        data.usage,
        getUsageKeyByIndex(startDate, 1),
        asyncConvertCurrency
      );
      const currentMonthUsage = await asyncGetUsageHistory(
        data.usage,
        getUsageKeyByIndex(startDate, 0),
        asyncConvertCurrency
      );

      const costsSavingsHistory = await asyncGetSavingsHistory(data.usage, asyncConvertCurrency);
      const { odSpendingHistory, spotSpendingHistory } = await asyncGetSpendingHistory(
        data.usage,
        asyncConvertCurrency
      );

      const costsSavingsHistoryByTimeFilter = groupCostsSavingsHistoryByTimeFilter(costsSavingsHistory);

      const { spotSpendingByTimeFilter, odSpendingByTimeFilter } = groupSpendingHistoryByTimeFilter(
        spotSpendingHistory,
        odSpendingHistory
      );

      // TODO: [CMP-6034] remove this code after cleaning backend errors
      if (!data.error?.includes("update error:") || data.error?.includes("in Dev env")) {
        data.error = undefined;
      }

      const optimizationStatus = getOptimizationStatus(data);

      return {
        id: snapshot.id,
        accountId: data.accountId,
        region: data.region,
        asgName: data.asgName,
        accountName: data.accountName,
        config: {
          fallbackOnDemand: data.config.fallbackOnDemand,
          keepUpToDate,
        },
        optimizationStatus,

        configurations: {
          current: currentConfiguration,
          recommended: recommendedConfiguration,
        },
        costsSavingsHistory,
        costsSavingsHistoryByTimeFilter,

        odSpendingHistory,
        odSpendingByTimeFilter,

        spotSpendingHistory,
        spotSpendingByTimeFilter,

        spotisizeError: data.spotisizeErrorDesc,
        updateError: data.error?.split("update error:").pop()?.trim(),
        lastMonthUsage,
        currentMonthUsage,
        recommendationMonthlySaving,
        timeStartedUsingSpots,
        currentMonthActualSavingsDays,
        isApplyChangesSupported: !data.spotisizeNotSupported && !data.error,
        timeModified: data.timeModified,
      };
    },
    [asyncConvertCurrency, convertInstancesCosts]
  );

  return useCollectionData(query.where("customer", "==", customerOrPresentationModeCustomer.ref), {
    snapshotOptions: { serverTimestamps: "estimate" },
    transform,
  });
};

/**
 * Get single asg by name and customer
 * @param asgId
 */
export function useSingleAsg(asgId: string) {
  const [asgs, loading] = useAsgs(asgsCollection.where(documentId(), "==", asgId));

  return useMemo(() => {
    if (!loading && asgs && asgs.length === 1) {
      return asgs[0];
    } else {
      return undefined;
    }
  }, [asgs, loading]);
}

/**
 * Custom hook to return number of connected accounts and number of accounts with spot scaling feature enabled
 */

export function useConnectedAccounts() {
  const { customerOrPresentationModeCustomer } = useCustomerContext();
  const queryCloudConnect = getCollection(CustomerModel)
    .doc(customerOrPresentationModeCustomer.id)
    .collection("cloudConnect")
    .where("cloudPlatform", "==", "amazon-web-services")
    .narrow<CloudConnectAmazonWebServices>();

  return useCollectionData(queryCloudConnect);
}

export function useCountConnectedAccounts() {
  const [connectAccounts] = useConnectedAccounts();

  return useMemo<[number, number]>(() => {
    const accounts = connectAccounts ?? [];
    let numberOfAccountsWithSpotScaling = 0;
    accounts.forEach((account) => {
      account.supportedFeatures?.forEach((feature) => {
        if (feature.name === AWSFeatureName.spotScaling && feature.hasRequiredPermissions) {
          numberOfAccountsWithSpotScaling++;
        }
      });
    });

    return [accounts.length, numberOfAccountsWithSpotScaling];
  }, [connectAccounts]);
}

/**
 * Decide if refresh is needed once, if the number of ASGs is 0
 * @param asgs
 */
export function useShouldAutomaticallyRefresh(asgs: AsgItem[] | undefined) {
  const [sentRefresh, setSentRefresh] = useState<boolean>(false);

  if (asgs?.length === 0 && !sentRefresh) {
    setSentRefresh(true);
    return true;
  }
  return false;
}

/**
 * Returns last modified date from the list of asgs
 * @param asgs
 */
export function useGetLastUpdateDate(asgs: AsgItem[] | undefined): string {
  return useMemo<string>(() => {
    if (!asgs?.length) {
      return "";
    }
    try {
      const last: AsgItem = asgs.reduce((acc, current) => (acc.timeModified > current.timeModified ? acc : current));
      return formatDate(new Date(last.timeModified.toDate()));
    } catch (e) {
      consoleErrorWithSentry(e);
      return "";
    }
  }, [asgs]);
}
