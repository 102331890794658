import { type ProductEnum } from "@doitintl/cmp-models";
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";

import { type Option, type PackageOptions } from "./type";

type Props = {
  groupName: keyof PackageOptions;
  title: string;
  options: Option[];
  selectedOptions: ProductEnum[];
  onSelect: (group: keyof PackageOptions, option: Option) => void;
};

export const PackageSelectorGroup = ({ groupName, title, options, selectedOptions, onSelect }: Props) => (
  <Stack spacing={1}>
    <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
      {title}
    </Typography>
    <FormGroup sx={{ pl: 1 }}>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={selectedOptions.includes(option.value)}
              onChange={() => {
                onSelect(groupName, option);
              }}
            />
          }
          label={option.label}
          data-cy={`${groupName}-${option.value}`}
        />
      ))}
    </FormGroup>
  </Stack>
);
