import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Typography } from "@mui/material";

import AgsImg from "../../../assets/spotScaling/asgs.svg";

export const AsgsOverviewEmptyTableState = () => (
  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" py={3}>
    <Box pb={2}>
      <img src={AgsImg} alt="ASG" width={110} />
    </Box>
    <Typography variant="h6" gutterBottom align="center">
      No Auto Scaling Groups found in the linked AWS accounts
    </Typography>
    <Box pt={1}>
      <Typography variant="subtitle1" gutterBottom align="center">
        Spot Scaling proactively monitors your AWS Auto-Scaling Groups (ASGs) and intelligently replacing all or some of
        an ASG’s on-demand EC2 instances with Spot instances using sophisticated AI assisted analysis.
      </Typography>
      <Typography variant="subtitle1" gutterBottom align="center">
        Get started with EC2 Auto Scaling by creating an auto scaling group.
      </Typography>
    </Box>

    <Box pt={4}>
      <Button
        variant="contained"
        color="primary"
        href="https://console.aws.amazon.com/ec2autoscaling/home?region=us-east-1#/create"
        target="_blank"
        rel="noopener noreferrer"
        startIcon={<OpenNewIcon />}
      >
        Create an auto-scaling group
      </Button>
    </Box>
  </Box>
);
