import { Redirect } from "react-router-dom";
import { Box } from "@mui/material";

import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import { type ContractsPageId } from "../../Navigation/config/pages";
import ContractForm from "../Contracts/ContractForm/ContractForm";
import ContractPage from "../Contracts/ContractView/ContractPage";
import VendorContractPage from "../Contracts/VendorContractView/VendorContractPage";
import RampPlan from "../RampPlans/RampPlan/RampPlan";
import { ContractsListTab } from "./Contracts";
import { ContractTypesContextProvider } from "./ContractsTypesContext";
import { RampsListTab } from "./Ramps";

type Props = {
  pageId: ContractsPageId;
};

export const ContractsTabs = (props: Props) => {
  const { pageId } = props;
  const routeMatchURL = useRouteMatchURL();

  if (pageId === "contracts") {
    return <Redirect to={`${routeMatchURL}/contracts-list`} />;
  }

  return (
    <ContractTypesContextProvider>
      <Box ml={-1}>
        {pageId === "contracts:list" && <ContractsListTab {...props} />}
        {(pageId === "contracts:create" || pageId === "contracts:edit") && <ContractForm {...props} />}

        {pageId === "contracts:view" && <ContractPage />}

        {pageId === "contracts:view-vendor-contract" && <VendorContractPage />}

        {pageId === "ramp-plans" && <RampsListTab />}

        {pageId === "ramp-plans:edit" && <RampPlan />}
      </Box>
    </ContractTypesContextProvider>
  );
};
