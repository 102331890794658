import { collection, doc, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";
import { type NavigatorSKUs, type SolveSKUs } from "./Tier";
import { type UserModel } from "./User";

export type GcpMarketplaceAccountApprovalState = "PENDING" | "APPROVED" | "REJECTED";

export type GcpMarketplaceAccountApproval = {
  name: "signup";
  state: GcpMarketplaceAccountApprovalState;
  updateTime: Timestamp;
};

export type GcpMarketplaceAccountState = "ACCOUNT_STATE_UNSPECIFIED" | "ACCOUNT_ACTIVE" | "ACCOUNT_DELETED";

export type GcpMarketplaceAccount = {
  approvals: GcpMarketplaceAccountApproval[];
  createTime: Timestamp;
  name: string;
  provider: string;
  state: GcpMarketplaceAccountState;
  updateTime: Timestamp;
};

@subCollection("gcpMarketplaceAccounts")
export class GcpMarketplaceAccountModel {
  billingAccountId?: string;

  billingAccountType?: string;

  customer?: Reference<CustomerModel>;

  procurementAccount?: GcpMarketplaceAccount;

  user?: {
    email: string;
    uid: string;
  };
}

export type GcpMarketplaceEntitlementState =
  | "ENTITLEMENT_STATE_UNSPECIFIED"
  | "ENTITLEMENT_ACTIVATION_REQUESTED"
  | "ENTITLEMENT_ACTIVE"
  | "ENTITLEMENT_PENDING_CANCELLATION"
  | "ENTITLEMENT_CANCELLED"
  | "ENTITLEMENT_PENDING_PLAN_CHANGE"
  | "ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL"
  | "ENTITLEMENT_SUSPENDED"
  | "ENTITLEMENT_DELETED";

export enum PlanChangeAction {
  Initialized = "initialized",
  Requested = "requested",
  Changed = "changed",
  Cancelled = "cancelled",
}

export type GcpMarketplacePlanHistory = {
  action: PlanChangeAction;
  offer: string;
  updateTime: Timestamp;
};

export type GcpMarketplaceEntitlement = {
  account: string;
  createTime: Timestamp;
  name: string;
  plan: string;
  product: string;
  productExternalName: string;
  provider: string;
  state: GcpMarketplaceEntitlementState;
  updateTime: Timestamp;
  usageReportingId: string;
};

@subCollection("gcpMarketplaceEntitlements")
export class GcpMarketplaceEntitlementModel {
  procurementEntitlement!: GcpMarketplaceEntitlement;
}

@subCollection("gcpMarketplacePlanHistory")
export class GcpMarketplacePlanHistoryModel {
  history!: GcpMarketplacePlanHistory[];
}

export enum GCPMarketplaceAdjustmentStatus {
  PENDING = "PENDING",
  DONE = "DONE",
}

@subCollection("gcpMarketplaceAdjustments")
export class GcpMarketplaceAdjustmentModel {
  createdBy!: string;

  customer!: Reference<CustomerModel>;

  operationId!: string | null;

  status!: GCPMarketplaceAdjustmentStatus;

  targetTime!: Timestamp;

  timeCreated!: Timestamp;

  timeReported!: Timestamp | null;

  timeUpdated!: Timestamp;

  value!: number;

  description!: string;

  transactionFee!: number;

  procurementAccount!: Reference<GcpMarketplaceAccountModel>;
}

@doc("gcp-marketplace")
export class GcpMarketplaceDoc {
  subCollections?: {
    gcpMarketplaceAccounts: GcpMarketplaceAccountModel;
    gcpMarketplaceAdjustments: GcpMarketplaceAdjustmentModel;
    gcpMarketplaceEntitlements: GcpMarketplaceEntitlementModel;
    gcpMarketplacePlanHistory: GcpMarketplacePlanHistoryModel;
  };
}

export enum AwsMarketplaceAccountStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type AwsMarketplaceAccountState = `${AwsMarketplaceAccountStatus}`;

export type AwsMarketplaceAccountData = {
  CustomerAWSAccountId: string;
  CustomerIdentifier: string;
  ProductCode: string;
  Dimension: string;
};

type EntitlementData = {
  CustomerIdentifier: string;
  Dimension: string;
  ExpirationDate: Timestamp;
  ProductCode: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Value: any;
};

@subCollection("awsMarketplaceAccounts")
export class AwsMarketplaceAccountModel {
  createdAt!: Timestamp;

  createdBy!: Reference<UserModel>;

  updatedAt!: Timestamp;

  status!: AwsMarketplaceAccountState;

  customer!: Reference<CustomerModel>;

  awsToken!: string;

  disabledAt?: Timestamp;

  tiersSKU?: Array<NavigatorSKUs | SolveSKUs>;

  awsAccountData?: AwsMarketplaceAccountData;

  entitlementsData?: EntitlementData[];

  skuToContract?: Record<NavigatorSKUs | SolveSKUs, string>;
}

@doc("aws-marketplace")
export class AwsMarketplaceDoc {
  subCollections?: {
    awsMarketplaceAccounts: AwsMarketplaceAccountModel;
  };
}

@collection("marketplace")
export class MarketplaceModel {
  docs?: {
    "gcp-marketplace": GcpMarketplaceDoc;
    "aws-marketplace": AwsMarketplaceDoc;
  };
}
