import { type FlexsaveAdvantage } from "../types";

export const FlexsaveAdvantages: FlexsaveAdvantage[] = [
  {
    title: "Zero overhead",
    text: "no fees, ever",
  },
  {
    title: "Zero risk",
    text: "guaranteed to save money",
  },
  {
    title: "Zero commitment",
    text: "turn on and off at any time",
  },
];
