import {
  Aggregator,
  type AnalyticsDataSource,
  ComparativeFeature,
  type CurrencyCode,
  CurrencyCodes,
  LimitAggregation,
  Metadata,
  Metric,
  type Renderer,
  type ReportFilter,
  Sort,
  TimeInterval,
} from "@doitintl/cmp-models";
import { isAxiosError } from "axios";
import intersection from "lodash/intersection";

import { type MetadataOption } from "../../../types";
import { getReportRowSort, Positions } from "../utilities";

export const buildConfig = (cfg, md: MetadataOption) => {
  if (md._visible) {
    switch (md._position) {
      case Positions.ROW:
        cfg.rows.push(md.id);
        break;
      case Positions.COL:
        cfg.cols.push(md.id);
        break;
      case Positions.COUNT:
        cfg.count = md.id;
        break;
      default:
    }
  }

  if (
    md.data.type &&
    ![
      Metadata.FIXED,
      Metadata.GKE,
      Metadata.DATETIME,
      Metadata.ATTRIBUTION,
      Metadata.ATTRIBUTION_GROUP,
      Metadata.METRIC,
      Metadata.LIMITS,
    ].includes(md.data.type) &&
    md._visible
  ) {
    cfg.optional.push({
      type: md.data.type,
      key: md.data.key,
    });
  }
  const limit = md._position === Positions.ROW ? md._limit : null;
  if ((md._filter || [])?.length > 0 || (md._regexp || [])?.length > 0 || (limit && limit > 0)) {
    cfg.filters.push({
      id: md.id,
      values: md._filter ?? null,
      regexp: md._regexp ?? null,
      inverse: md._inverse ?? false,
      limit: md._limit ?? null,
      limitOrder: md._limitOrder ?? null,
      limitMetric: md._limitMetric ?? null,
    });
  }

  return cfg;
};

export const removeIrrelevantFilters = (filters: ReportFilter[], attributions: string[]) =>
  filters.filter((f) => f.id !== "attribution:attribution" || intersection(f.values, attributions).length);

type PopulateConfigNullsProps = {
  metric?: Metric;
  renderer: Renderer;
  timeInterval?: TimeInterval;
  limitAggregation?: LimitAggregation;
  rowOrder?: Sort;
  currency?: CurrencyCode | null;
  aggregator?: Aggregator;
  colOrder?: Sort;
  comparative?: ComparativeFeature | null;
  count?: string | null;
  dataSource: AnalyticsDataSource;
};

export const setDefaultConfig = ({
  metric,
  renderer,
  timeInterval,
  rowOrder,
  colOrder,
  comparative,
  limitAggregation,
  currency,
  aggregator,
  count,
  dataSource,
}: PopulateConfigNullsProps) => ({
  limitAggregation: limitAggregation ?? LimitAggregation.TOP,
  currency: currency ?? CurrencyCodes.USD,
  aggregator: aggregator ?? Aggregator.TOTAL,
  metric: metric ?? Metric.COST,
  timeInterval: timeInterval ?? TimeInterval.DAY,
  rowOrder: rowOrder ?? getReportRowSort(renderer),
  colOrder: colOrder ?? Sort.A_TO_Z,
  comparative: comparative ?? ComparativeFeature.NONE,
  count: count ?? null,
  dataSource,
});

export const getErrorMessage = (err: unknown) => {
  if (isAxiosError(err) && err.response?.status === 400 && err.response.data?.error) {
    return err.response.data.error;
  }

  return "";
};
