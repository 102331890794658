import { useState } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import type { AxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useFullScreen } from "../../../utils/dialog";
import type { DisconnectError } from "../../Threads/types";

type Props = {
  jiraInstance: string;
  onClose: () => void;
};

export const DeleteInstanceConfigModal = ({ jiraInstance, onClose }: Props) => {
  const { isMobile: isSmallScreen } = useFullScreen("sm");

  const [isDeleting, setIsDeleting] = useState(false);
  const [deletionOption, setDeletionOption] = useState("");
  const [disconnectErrorMessage, setDisconnectErrorMessage] = useState("");

  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();

  const successSnackbar = useSuccessSnackbar();
  const api = useApiContext();

  const handleDisconnectInstanceConfig = async () => {
    setIsDeleting(true);

    const deleteIssues = deletionOption === "threads-and-issues";

    let isDisconnectError = false;

    // Make call to Threads API to delete the Jira instance configuration and the web-trigger secret
    // We'll handle all the deletion of threads and Jira issues there as well
    try {
      const url = `threads/jira/instance/disconnect?customerID=${customer.id}`;
      await api.post(url, {
        jiraInstance,
        deleteIssues,
      });
    } catch (e) {
      const axiosError = e as AxiosError;

      // Check what exactly went wrong, so we can relay it to the user
      // We'll get a JSON structure with the fields:
      // error: "<error string>"
      // erroredIssueKeys: ["<key-1>", "<key-2>", etc.]

      const errorString = axiosError.response?.data as string;

      try {
        const disconnectError = JSON.parse(errorString) as DisconnectError;

        if (disconnectError.error === "") {
          // Disconnecting was successful (as far as the customer is concerned), but one or more issues couldn't be deleted
          setDisconnectErrorMessage(
            `Your Jira instance has been successfully disconnected but we were unable to delete the following Jira issues: ${disconnectError.erroredIssueKeys.join(", ")}.`
          );
        } else {
          // We couldn't do anything successfully -- instance has not been disconnected
          setDisconnectErrorMessage(
            "Something went wrong and we weren't able to disconnect your Jira instance. Please try again later."
          );
        }
      } catch (e) {
        setDisconnectErrorMessage(
          "Something went wrong and we weren't able to disconnect your Jira instance. Please try again later."
        );
      }

      isDisconnectError = true;
    }

    setIsDeleting(false);

    // Update the feedback to the user
    if (!isDisconnectError) {
      successSnackbar("Jira instance successfully disconnected.");
      onClose();
    }
  };

  return (
    <Dialog open={true} fullScreen={isSmallScreen} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>Disconnect Jira instance</DialogTitle>
      <DialogContent>
        {isDoitEmployee ? (
          <Alert severity="warning" sx={{ mb: 2 }}>
            <strong>You're logged in as a DoiT employee</strong> - you cannot disconnect a customer's Jira instance.
          </Alert>
        ) : null}
        <Typography sx={{ mb: 2 }}>
          By disconnecting Jira from DoiT Navigator, all threads from your organization will be automatically deleted
          and you will no longer be able to create new threads.
        </Typography>
        <Typography sx={{ mb: 2 }}>Choose an option to proceed:</Typography>
        <RadioGroup
          sx={{ pl: 2, mb: 2 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDeletionOption(e.target.value);
          }}
        >
          <FormControlLabel
            disabled={disconnectErrorMessage !== ""}
            value="threads-only"
            control={<Radio />}
            label="Delete all threads connected to Jira"
          />
          <FormControlLabel
            disabled={disconnectErrorMessage !== ""}
            value="threads-and-issues"
            control={<Radio />}
            label="Delete all threads connected to Jira and the associated Jira issues"
          />
        </RadioGroup>
        <Typography>This action cannot be undone.</Typography>
        {disconnectErrorMessage !== "" ? (
          <Alert severity="error" sx={{ my: 2 }}>
            {disconnectErrorMessage}
          </Alert>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          loading={isDeleting}
          variant="contained"
          disabled={isDoitEmployee || deletionOption === "" || disconnectErrorMessage !== ""}
          onClick={handleDisconnectInstanceConfig}
          mixpanelEventId={`integrations.jira.disconnect${deletionOption === "threads-and-issues" ? `.with-issues` : ``}`}
        >
          Disconnect Jira instance
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
