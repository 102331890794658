import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import SavingsIcon from "@mui/icons-material/Savings";
import SearchIcon from "@mui/icons-material/Search";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { common } from "@mui/material/colors";
import { Stack } from "@mui/system";

import { type InfoBoxStatus } from "./types";

type Props = {
  currentState: InfoBoxStatus;
};

export const StatusIcon = ({ currentState }: Props) => {
  let Icon = SearchIcon;
  let backgroundColor = "#0288D1";
  switch (currentState) {
    case "active":
      Icon = SavingsIcon;
      backgroundColor = "success.light";
      break;
    case "checking":
      Icon = SearchIcon;
      break;
    case "disabled":
      Icon = InfoIcon;
      backgroundColor = "#DDDDDD";
      break;
    case "error":
      Icon = ErrorIcon;
      backgroundColor = "error.main";
      break;
    case "working":
      Icon = SettingsSuggestIcon;
      break;
  }

  return (
    <Stack sx={{ backgroundColor, alignItems: "center", justifyContent: "center", px: 0.7 }}>
      <Icon sx={{ fontSize: 20, color: common.white }} />
    </Stack>
  );
};
