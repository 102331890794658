import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { type FormikErrors, type FormikHandlers, type FormikHelpers, type FormikState } from "formik";

import { type LegacyDataFormat } from "../../../../Components/Catalog/Catalog.context";
import { type Values } from "./types";

type Props = Pick<FormikHandlers, "handleChange" | "handleBlur"> &
  Pick<FormikState<Values>, "touched"> &
  Pick<FormikHelpers<Values>, "setFieldValue"> & {
    services: LegacyDataFormat[];
    values: Values;
    errors: FormikErrors<Values>;
  };

const ServiceStep = ({ services, setFieldValue, values, touched, errors, handleChange, handleBlur }: Props) => {
  const getLicenseTypeOptions = () => {
    const d = services
      .map((service) => ({
        key: service.data.skuId,
        label: service.data.skuName,
        value: service.data.skuId,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return [...new Map(d.map((item) => [item.key, item])).values()];
  };

  const handleChangeLicenseType = (_, selectedValue) => {
    const newValue = services.find((v) => v.data?.skuId === selectedValue.value);
    setFieldValue("service", newValue);
  };

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid item xs />
        <Grid container item xs={12} md={6} lg={4} direction="column" spacing={1}>
          <Grid item>
            <Typography variant="subtitle1">Select a license and quantity</Typography>
          </Grid>

          <Grid item>
            <Autocomplete
              data-cy="license-type"
              id="license-type"
              disableClearable
              options={getLicenseTypeOptions()}
              onChange={handleChangeLicenseType}
              isOptionEqualToValue={(o: any, v) => o.value === v.value}
              // value={values?.service?.data?.skuName || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="License Type"
                  helperText={(touched.service && errors.service) || "Select the subscription you would like to order"}
                  margin="dense"
                  error={touched.service && Boolean(errors.service)}
                  fullWidth
                  required
                />
              )}
              renderOption={(props, option: any) => (
                <li {...props} key={option.key}>
                  {option.label}
                </li>
              )}
            />
          </Grid>

          <Grid item>
            <TextField
              data-cy="quantity"
              name="quantity"
              helperText={
                (touched.quantity && errors.quantity) || `Enter the number of seats for the selected subscription`
              }
              error={touched.quantity && Boolean(errors.quantity)}
              label="Quantity"
              value={values.quantity}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              required
              variant="outlined"
              margin="dense"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
            />
          </Grid>
        </Grid>
        <Grid item xs />
      </Grid>
    </Grid>
  );
};

export default ServiceStep;
