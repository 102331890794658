import { type ChangeEvent, useEffect, useState } from "react";

import { type TimeInterval } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import { DateTime } from "luxon";

import CustomStaticDateRangePicker from "./CustomStaticDateRangePicker";
import DateRangeInput from "./DateRangeInput";
import {
  DATE_RANGE_FORMAT,
  DateRangePhase,
  handleDateRangeChange,
  type IDateRange,
  isDateBefore,
  validateDate,
} from "./dateRangeUtils";

export type DateRangeProps = {
  dateRange: IDateRange;
  isViewOnly: boolean;
  onRangeChanged: (start: DateTime | null, end: DateTime | null) => void;
  showTextInput: boolean;
  disableEndDate: boolean;
  disableCurrent?: TimeInterval;
};

const DateRange = ({
  dateRange,
  isViewOnly,
  onRangeChanged,
  showTextInput,
  disableEndDate,
  disableCurrent,
}: DateRangeProps) => {
  const today = DateTime.utc();
  // control the current month view - not clickable
  const [calendar1CurrentMonth, setCalendar1CurrentMonth] = useState<DateTime>(today.minus({ month: 1 }));
  const [calendar2CurrentMonth, setCalendar2CurrentMonth] = useState<DateTime>(today);
  // control the textField inputs
  const [startDateStr, setStartDateStr] = useState("");
  const [endDateStr, setEndDateStr] = useState("");
  const [dateRangePhase, setDateRangePhase] = useState<DateRangePhase>(DateRangePhase.NONE);

  useEffect(() => {
    const { start, end } = dateRange;
    if (!start || !end) {
      return;
    }
    if (start?.hasSame(end, "month")) {
      setCalendar1CurrentMonth(start.minus({ month: 1 }));
      setCalendar2CurrentMonth(end);
    } else {
      setCalendar1CurrentMonth(start);
      setCalendar2CurrentMonth(end);
    }
  }, [dateRange]);

  useEffect(() => {
    const { start, end } = dateRange;
    setStartDateStr(start?.toFormat(DATE_RANGE_FORMAT) || "");
    setEndDateStr(end?.toFormat(DATE_RANGE_FORMAT) || "");
  }, [dateRange]);

  const onChange = (date: DateTime | null) => {
    if (!date || isViewOnly) {
      return;
    }

    if (disableEndDate) {
      onRangeChanged(date, dateRange.end);
      return;
    }

    const { updatedState, phase } = handleDateRangeChange(dateRange, date, dateRangePhase);
    setDateRangePhase(phase);
    onRangeChanged(updatedState.start, updatedState.end);
  };

  const onStartDateChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setStartDateStr(value);
    const { date, isValid } = validateDate(value);
    if (isValid && isDateBefore(date, dateRange.end)) {
      onRangeChanged(date, dateRange.end);
    }
  };

  const onEndDateChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    setEndDateStr(value);
    const { date, isValid } = validateDate(value);
    if (isValid && isDateBefore(dateRange.start, date)) {
      onRangeChanged(dateRange.start, date);
    }
  };

  return (
    <Stack data-cy="date-range" direction="row">
      <Stack direction="column">
        {showTextInput && (
          <DateRangeInput
            end={endDateStr}
            onEndDateChange={onEndDateChanged}
            onStartDateChange={onStartDateChanged}
            start={startDateStr}
          />
        )}
        <CustomStaticDateRangePicker
          calendar1CurrentMonth={calendar1CurrentMonth}
          calendar2CurrentMonth={calendar2CurrentMonth}
          dateRange={dateRange}
          isViewOnly={isViewOnly}
          onChange={onChange}
          disableCurrent={disableCurrent}
          disableFuture={false}
        />
      </Stack>
    </Stack>
  );
};

export default DateRange;
