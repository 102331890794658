import { type CurrencyCode, type PaymentMethod } from "@doitintl/cmp-models";
import { type StripeError } from "@stripe/stripe-js";
import { type AxiosInstance } from "axios";

import { type Customer } from "../../../types";
import { type StripeFromHandle } from "./Stripe";

const submitStripePayment = async (stripeRef: React.RefObject<StripeFromHandle>): Promise<StripeError | undefined> => {
  if (!stripeRef.current) {
    throw new Error("Stripe ref is missing");
  }
  const error = await stripeRef.current.handleSubmit();
  return error;
};

const submitPaymentMethodUpdate = async (
  api: AxiosInstance,
  customer: Customer,
  entityId: string,
  paymentMethod: string
) =>
  api.request({
    method: "patch",
    url: `/v1/customers/${customer.id}/entities/${entityId}/paymentMethods`,
    data: {
      type: paymentMethod,
    },
  });

export const handlePaymentMethodUpdate = async (
  stripeRef: React.RefObject<StripeFromHandle>,
  api: AxiosInstance,
  customer: Customer,
  entityId: string,
  paymentMethod: string
) => {
  if (paymentMethod === "stripe") {
    await submitStripePayment(stripeRef);
  } else {
    await submitPaymentMethodUpdate(api, customer, entityId, paymentMethod);
  }
};

export const isPaymentMethodSupported = (paymentMethod: PaymentMethod, currency: CurrencyCode) => {
  switch (paymentMethod) {
    case "wire_transfer":
    case "bill_com":
      return !!currency && currency !== "EGP";
    case "stripe":
      return !!currency;
    case "bacs_debit":
      return !!currency && currency === "GBP";
    case "acss_debit":
      return !!currency && ["CAD", "USD"].includes(currency);
    default:
      return false;
  }
};

export const getPaymentMethodModalButtonText = (currency: CurrencyCode) => {
  if (["EUR", "USD"].includes(currency)) {
    return "Credit card or Bank account";
  }
  return "Credit card";
};
