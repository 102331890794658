import { type ReactNode } from "react";

import { type SaaSConsoleType } from "@doitintl/cmp-models";
import { Grid, Stack, Typography } from "@mui/material";

import { type Customer } from "../../../../types";
import { SaasConsoleTypes } from "../consts";
import { SaaSCloudConnectCard } from "../NavigatorOnlyDashboard/CloudConnectCard";
import { SolveOnlyCloudConnectCard } from "../SolveOnlyDashboard/CloudConnectCard";
import { type ProductOnlyCustomerProspects } from "../type";
import { shouldShowCard } from "../utils";

export const renderCloudConnectCard = (
  customer: Customer,
  cloudType: SaaSConsoleType,
  prospectType: ProductOnlyCustomerProspects,
  skipProvidersCheck = false,
  showSnowflakeOnboardingCard = false
) => {
  const isSnowflake = cloudType === "SNOWFLAKE";
  if (
    (skipProvidersCheck && !isSnowflake) ||
    (shouldShowCard(cloudType, customer, prospectType) && !isSnowflake) ||
    (isSnowflake && showSnowflakeOnboardingCard)
  ) {
    const CardComponent = prospectType === "navigator" ? SaaSCloudConnectCard : SolveOnlyCloudConnectCard;
    return (
      <Grid item xs={12} sm={12} md={6} key={cloudType}>
        <CardComponent cloudType={cloudType} />
      </Grid>
    );
  }
  return null;
};

export const renderPackageCloudConnectCards = (
  customer: Customer,
  prospect: ProductOnlyCustomerProspects,
  skipProvidersCheck = false,
  showSnowflakeOnboardingCard = false
) =>
  SaasConsoleTypes.map((cloudType) =>
    renderCloudConnectCard(customer, cloudType, prospect, skipProvidersCheck, showSnowflakeOnboardingCard)
  );

const WelcomeBlockWrapper = ({ children }: { children: ReactNode }) => (
  <Stack spacing={1} ml={-1}>
    <Typography variant="h1" pl={1}>
      Welcome to the DoiT Console
    </Typography>
    <Grid container justifyContent="space-between" spacing={1} height="100%">
      {children}
    </Grid>
  </Stack>
);

export default WelcomeBlockWrapper;
