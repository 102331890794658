import { type ComponentType, createContext, type ReactNode, useCallback, useContext, useEffect } from "react";

import axios, { type AxiosInstance } from "axios";
import { getDisplayName } from "recompose";

import { globalText } from "../assets/texts";
import { useSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../Context/AuthContext";
import { useThreads } from "../Pages/Threads/api";
import { getMockApi } from "../Pages/Threads/mockApi";
import { initAxiosClient } from "./axiosClient";

const instance = axios.create({
  baseURL: "/api",
});

const apiContext = createContext(instance);

export function useApiContext(): AxiosInstance {
  return useContext(apiContext);
}

export const ApiContextProvider = ({ children }: { children?: ReactNode }) => {
  const { currentUser } = useAuthContext();
  const snackbar = useSnackbar();

  const getToken = useCallback(
    async (forceRefresh?: boolean) => currentUser?.getIdTokenResult(forceRefresh),
    [currentUser]
  );

  const onTokenExpired = useCallback(async () => {
    snackbar.onOpen({
      message: globalText.AUTH_TOKEN_EXPIRED,
      variant: "info",
      autoHideDuration: 30000,
      withClose: true,
    });
  }, [snackbar]);

  useEffect(
    () =>
      initAxiosClient(instance, {
        getToken,
        onTokenExpired,
      }),
    [getToken, onTokenExpired]
  );

  return <apiContext.Provider value={instance}>{children}</apiContext.Provider>;
};

export const ApiContextConsumer = apiContext.Consumer;

export const ApiContextProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<AxiosInstance>;
}) => (
  // the Provider gives access to the context to its children

  <apiContext.Provider value={(value || instance) as AxiosInstance}>{children}</apiContext.Provider>
);

type Props = {
  api: AxiosInstance;
};

export type WithApi = Props;

export function withApi<P extends object>(Component: ComponentType<P & Props>) {
  const WrappedComponent = (props: P) => (
    <ApiContextConsumer>{(context) => <Component api={context} {...props} />}</ApiContextConsumer>
  );

  WrappedComponent.displayName = `withApi(${getDisplayName(WrappedComponent)})`;

  return WrappedComponent;
}

export const useApiContextOrMockAPIContext = (isPresentationMode: boolean) => {
  const apiContext = useApiContext();
  const { threads } = useThreads();

  if (isPresentationMode) {
    return getMockApi(threads || []);
  }

  return apiContext;
};
