import { type ReactNode, useCallback, useMemo, useState } from "react";

import { type PerkType, PerkTypes } from "@doitintl/cmp-models";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";

import LoadingButton from "../../../Components/LoadingButton";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Ticket } from "../../../Support/types";
import { perkTypeNames } from "../helpers";
import { useInfoText, useProfessionalServicesEntitlements } from "../hooks";
import { expandDciDisplayName } from "../utils";

export type RegisterInterestButtonProps = {
  disabledPerk: boolean;
  registerInterest: () => Promise<void>;
  perkType: PerkType;
  ticket?: Ticket;
};

export const RegisterInterestButton = ({
  registerInterest,
  disabledPerk,
  perkType,
  ticket,
}: RegisterInterestButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { infoText } = useInfoText(perkType);
  const { trainingEntitlement } = useProfessionalServicesEntitlements();

  const onRegisterInterest = useCallback(async () => {
    setLoading(true);
    await registerInterest();
    setLoading(false);
  }, [registerInterest]);

  const message = useMemo<string>(() => {
    const activeButtonMessage = {
      [PerkTypes.IsvSolution]: "Register interest",
      [PerkTypes.Training]: "Register interest",
      [PerkTypes.Accelerator]: `Request ${perkType === PerkTypes.Accelerator ? perkTypeNames[perkType] : perkType.toLowerCase()}`,
      [PerkTypes.Workshop]: `Request ${perkType === PerkTypes.Accelerator ? perkTypeNames[perkType] : perkType.toLowerCase()}`,
    };

    if (ticket && disabledPerk) {
      const createdDate = DateTime.fromISO(ticket.created_at);
      return `Requested ${createdDate.toLocaleString(DateTime.DATE_MED)}`;
    }

    if (disabledPerk) {
      return "Already requested";
    }

    return activeButtonMessage[perkType];
  }, [disabledPerk, perkType, ticket]);

  const dci = useMemo<boolean>(() => Boolean(infoText?.includes("DCI")), [infoText]);

  const tooltipContent = useMemo<ReactNode | undefined>(() => {
    if (isDoitEmployee && !ticket) {
      return (
        <Typography variant="body2">
          {`To request this ${perkType === PerkTypes.IsvSolution ? "solution" : perkType}, use GetCRE in Slack. See how to do it in `}
          <Link
            href="https://doitintl.atlassian.net/wiki/spaces/CRE/pages/132120587/GetCRE+Workflow"
            target="_blank"
            color="inherit"
          >
            confluence
          </Link>
          .
        </Typography>
      );
    }

    if (perkType === PerkTypes.Training && !trainingEntitlement && dci) {
      return <Typography variant="body2">{expandDciDisplayName(infoText!)}</Typography>;
    }
  }, [dci, infoText, isDoitEmployee, perkType, ticket, trainingEntitlement]);

  const Button = (
    <LoadingButton
      loading={loading}
      disabled={disabledPerk || isDoitEmployee || dci}
      onClick={onRegisterInterest}
      variant="contained"
      data-cy="register-interest-button"
      mixpanelEventId="perks.register-interest-button"
    >
      {message}
    </LoadingButton>
  );

  return tooltipContent ? (
    <Tooltip arrow placement="right" title={tooltipContent}>
      <Box>{Button}</Box>
    </Tooltip>
  ) : (
    Button
  );
};
