import { type ChangeEvent } from "react";

import { useTranslation } from "react-i18next";
import { Stack, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { object as YupObject, type ObjectSchema } from "yup";

import LoadingButton from "../../../Components/LoadingButton";

type Values = {
  username: string;
};

type OutValues = {
  email: string;
};

type Helpers = {
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldError: (field: string, message: string) => void;
};

type props = {
  email: string | null;
  isLoading: boolean;
  submitHandler: (values: OutValues, formikHelpers: Helpers) => void | Promise<any>;
  emailInputHandler: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  submitButtonText?: string;
  submitMixpanelEventId?: string;
  validationSchema: ObjectSchema<{
    email: string;
  }>;
};

function EmailFormTemplate({
  email = "",
  isLoading,
  submitHandler,
  emailInputHandler,
  validationSchema,
  submitButtonText,
  submitMixpanelEventId = "login.email-form-template.continue",
}: props) {
  const { t } = useTranslation("login");
  const submitButton = submitButtonText || t("login.continue");
  return (
    <Formik
      initialValues={{ username: email || "" }}
      validationSchema={() =>
        YupObject().shape({
          username: validationSchema.fields.email,
        })
      }
      onSubmit={(values: Values, helpers: Helpers) => {
        if (!values.username) {
          return;
        }
        return submitHandler({ email: values.username }, helpers);
      }}
    >
      {({ errors, isSubmitting, values, handleChange, setFieldTouched, touched }) => (
        <Form>
          <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
            <TextField
              data-cy="email-input"
              autoFocus
              value={values.username || ""}
              onChange={(e) => {
                handleChange(e);
                emailInputHandler(e);
              }}
              onBlur={() => {
                if (!touched.username && values.username) {
                  setFieldTouched("username", true);
                }
              }}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username ? errors.username : " "}
              label={t("login.email.label")}
              type="email"
              name="username"
              autoComplete="email"
              margin="none"
              variant="outlined"
              fullWidth
              disabled={isLoading}
            />
            <LoadingButton
              data-cy="login-cta"
              variant="contained"
              fullWidth
              type="submit"
              loading={isLoading}
              disabled={isSubmitting || isLoading || !values.username || Boolean(errors.username)}
              mixpanelEventId={submitMixpanelEventId}
            >
              {submitButton}
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

export default EmailFormTemplate;
