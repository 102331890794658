import { type RefObject, useCallback, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useFilter } from "../FilterContext";
import { CustomRefinementList } from "./CustomRefinementList";

type AttributeOptions = "timezone" | "all_skills" | "languages";

type ExpandableFiltersProps = {
  renderExpandedContent: boolean;
};

type CustomRefinementListReset = () => void;

export const ExpandableFilters = ({ renderExpandedContent }: ExpandableFiltersProps) => {
  const { isFiltersExpanded, setIsFiltersExpanded } = useFilter();
  const theme = useTheme();

  const filterCategories = useMemo<AttributeOptions[]>(() => ["all_skills", "timezone", "languages"], []);

  const filterRefs = useState<Partial<Record<AttributeOptions, RefObject<CustomRefinementListReset>>>>({});

  const toggleFilters = () => {
    setIsFiltersExpanded(!isFiltersExpanded);
  };

  const resetFilters = useCallback(() => {
    filterCategories.forEach((category) => {
      filterRefs[category]?.();
    });
  }, [filterCategories, filterRefs]);

  if (!renderExpandedContent) {
    return (
      <Button
        variant="outlined"
        startIcon={<FilterListIcon fontSize="small" />}
        onClick={toggleFilters}
        color="inherit"
        sx={{
          backgroundColor: "transparent",
          color: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        Filters
      </Button>
    );
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "100%", margin: 0 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} sx={{ flex: 1 }}>
          {filterCategories.map((category) => (
            <Grid item xs={12} sm={6} md={4} key={category}>
              <CustomRefinementList
                attribute={category}
                ref={(ref) => {
                  filterRefs[category] = ref;
                }}
              />
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            marginLeft: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={resetFilters}
            color="primary"
            sx={{
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.action.hover,
              },
              padding: theme.spacing(1, 2),
            }}
          >
            Reset Filters
          </Button>

          <Button
            variant="contained"
            startIcon={<CloseIcon fontSize="small" />}
            onClick={toggleFilters}
            color="primary"
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              padding: theme.spacing(1, 2),
            }}
          >
            Close Filters
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
