import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";

export const validateSubscriptionStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  const errors: Record<string, boolean> = {};

  const requiredFields = ["tierId", "paymentTerm", "chargePerTerm"];
  requiredFields.forEach((field) => {
    if (!state[field]) {
      errors[field] = true;
    }
  });

  if (state.overlappingContractExists) {
    errors.startDate = true;
    errors.endDate = true;
  }

  setState({ ...state, errors });

  return !Object.values(errors).some((error) => error);
};
