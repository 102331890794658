import { type CloudAnalyticsModelWidgetModel, Renderer } from "@doitintl/cmp-models";
import { MenuItem, TextField } from "@mui/material";

import { MenuProps, RendererOptions } from "../../../Pages/CloudAnalytics/utilities";

type Props = {
  widgetKey: string;
  currentWidget: CloudAnalyticsModelWidgetModel;
  currentWidgetIndex: string;
  onWidgetRendererChange: (rendererOption: Renderer, currentWidgetIndex: string) => void;
};

export const WidgetViewSelect = ({ currentWidget, currentWidgetIndex, onWidgetRendererChange }: Props) => (
  <TextField
    size="small"
    label="View As"
    value={currentWidget.config.renderer}
    onChange={(event) => {
      onWidgetRendererChange(event.target.value as Renderer, currentWidgetIndex);
    }}
    variant="outlined"
    margin="normal"
    select
    fullWidth
    SelectProps={{ MenuProps }}
    data-cy="widgetViewSelect"
  >
    {RendererOptions.filter((rendererOption) => rendererOption.value !== Renderer.TREEMAP).map((rendererOption) => (
      <MenuItem key={rendererOption.value} value={rendererOption.value} dense>
        {rendererOption.label}
      </MenuItem>
    ))}
  </TextField>
);
