import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  copy: {
    marginRight: theme.spacing(1),
  },
  cardContent: {
    paddingBottom: 0,
  },
  autocomplete: {
    "& .MuiInputAdornment-root": {
      "& button": {
        visibility: "hidden",
      },
    },
    "&:hover": {
      "& .MuiInputAdornment-root button": {
        visibility: "visible",
      },
    },
    "&.Mui-focused": {
      "& .MuiInputAdornment-root button": {
        visibility: "visible",
      },
    },
  },
  selectMetric: {
    margin: theme.spacing(0, 1.5),
    padding: theme.spacing(0),
    minWidth: 60,
    textAlign: "center",
  },
  skeleton: {
    position: "relative",
    padding: theme.spacing(2),
    marginTop: -30,
  },
  skeletonText: {
    height: 32,
    width: 172,
    position: "absolute",
    top: "50%",
    marginTop: -16,
    left: "50%",
    marginLeft: -86,
  },
  headerCardContent: {
    padding: theme.spacing(0, 2, 1),
    marginTop: -70,
  },
  headerCardContentSmall: {
    padding: theme.spacing(0, 2, 1),
  },
  toggleRenderer: {
    marginBottom: 15,
  },
  tableCardContent: {
    padding: theme.spacing(0, 2, 1),
  },
  chartCardContent: {
    padding: theme.spacing(0, 1, 0),
  },
  attribution: {
    margin: theme.spacing(1),
  },
  attributionText: {
    paddingRight: theme.spacing(2),
  },
  floatRight: {
    marginLeft: "auto",
  },
  floatLeft: {
    marginRight: "auto",
  },
  flex: {
    display: "flex",
  },
  formula: {
    margin: theme.spacing(1, 0),
  },
  metricRowText: {
    whiteSpace: "nowrap",
    paddingTop: 10,
  },
  variableName: {
    paddingTop: 14,
  },
  formulaText: {
    margin: theme.spacing(0, 2, 2, 0),
    paddingTop: 16,
  },
  format: {
    marginBottom: 28,
    marginLeft: theme.spacing(2),
    width: theme.spacing(12),
  },
  formatSmall: {
    marginBottom: 28,
    marginLeft: theme.spacing(1),
    width: theme.spacing(12),
  },
  formulaRow: {
    marginTop: theme.spacing(1),
  },
  hidden: {
    visibility: "hidden",
  },
  attributionInput: {
    marginTop: "3px",
  },
}));

export const inputLabelsStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    right: 22,
    bottom: 0,
    fontSize: 14,
  },
  shrink: {
    right: "unset",
  },
}));
