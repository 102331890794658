import { defaultHeaderStyle } from "../../../../../Components/FilterTable/Table/FilterTableHeader";
import { type DataColumn, type HeaderColumn } from "../../../../../types/FilterTable";
import { mayNotBeApplicable, mayNotBeAvailable, threeDotHeaderSection } from "../../../assetUtils";

const projectIdColumn = {
  label: "Project\u00A0ID",
  path: "projectId",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeAvailable,
} as const;
const billingAccountColumn = {
  label: "Billing\u00A0account\u00A0name",
  path: "billingAccount",
  comparators: ["==", "!="],
  toOption: mayNotBeAvailable,
} as const;
const billingIdColumn = {
  label: "Billing\u00A0account\u00A0ID",
  path: "billingId",
  comparators: ["==", "!="],
  toOption: (value) => ({ value, label: value }),
} as const;
const billingProfileColumn = {
  label: "Billing\u00A0profile",
  path: "billingProfile",
  comparators: ["==", "!="],
  toOption: (value) => ({ value, label: value }),
} as const;
const assetTagColumn: DataColumn = {
  label: "Asset\u00A0tag",
  path: "assetTag",
  comparators: ["==", "!=", "contains"],
  toOption: mayNotBeApplicable,
} as const;

const projectIdHeader = {
  value: projectIdColumn.path,
  label: projectIdColumn.label,
  style: defaultHeaderStyle,
};
const billingAccountHeader = {
  value: billingAccountColumn.path,
  label: billingAccountColumn.label,
  style: defaultHeaderStyle,
};
const billingIdHeader = {
  value: billingIdColumn.path,
  label: billingIdColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};
const billingProfileHeader = {
  value: billingProfileColumn.path,
  label: billingProfileColumn.label,
  style: defaultHeaderStyle,
  hidden: { lgDown: true },
};
const assetTagHeader = {
  value: assetTagColumn.path,
  label: assetTagColumn.label,
  style: defaultHeaderStyle,
  hidden: { lgDown: true },
};

export const gcpProjectsColumns: DataColumn[] = [
  projectIdColumn,
  billingAccountColumn,
  billingIdColumn,
  billingProfileColumn,
  assetTagColumn,
];

export const gcpProjectsHeaders: HeaderColumn[] = [
  projectIdHeader,
  billingAccountHeader,
  billingIdHeader,
  billingProfileHeader,
  assetTagHeader,
  threeDotHeaderSection,
];
