export const typeMapping = {
  ACCELERATOR_OPTIMIZED: "A2",
  COMPUTE_OPTIMIZED: "C2",
  COMPUTE_OPTIMIZED_C2D: "C2D",
  COMPUTE_OPTIMIZED_C3: "C3",
  MEMORY_OPTIMIZED_M3: "M3",
  MEMORY_OPTIMIZED: "M1",
  GENERAL_PURPOSE: "N1",
  GENERAL_PURPOSE_E2: "E2",
  GENERAL_PURPOSE_N2: "N2",
  GENERAL_PURPOSE_N2D: "N2D",
  GENERAL_PURPOSE_T2D: "T2D",
};
