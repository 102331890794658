import { type MouseEventHandler } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export type DeleteKnownIssuesButtonProps = {
  onClick: MouseEventHandler;
};

export const DeleteKnownIssuesButton = ({ onClick }: DeleteKnownIssuesButtonProps) => (
  <Tooltip title="Delete Issues">
    <IconButton onClick={onClick} aria-label="Delete Issues" size="large">
      <DeleteIcon />
    </IconButton>
  </Tooltip>
);
