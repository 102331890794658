import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";

import Hide from "../HideChildren/Hide";

export type CountUpTextProps = {
  text: string;
  tooltipText?: string;
};

export const CountUpText = ({ text, tooltipText }: CountUpTextProps) => (
  <Box display="flex" alignItems="center" justifyContent="center" fontSize="caption.fontSize">
    <Box fontSize={{ xs: "caption.fontSize" }} color="text.secondary">
      <Box pr={{ xs: 0, md: 0.5 }}>{text}</Box>
    </Box>
    {!!tooltipText && (
      <Hide lgDown>
        <Tooltip title={tooltipText} placement="top" arrow>
          <InfoOutlinedIcon htmlColor={grey[600]} fontSize="inherit" />
        </Tooltip>
      </Hide>
    )}
  </Box>
);
