import { useCallback, useMemo, useState } from "react";

import { Link as RouteLink } from "react-router-dom";
import { DoitRole, type GcpMarketplacePlanHistory } from "@doitintl/cmp-models";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useClipboard } from "use-clipboard-copy";

import { globalText, marketplaceText } from "../../../../assets/texts";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDoitRoleCheck } from "../../../../Components/hooks/useDoitRoles";
import { useInfoSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { dateFormatCompleteDateWithTimeLocalized } from "../../../../utils/dateTimeFormats";
import { headers } from "../../../Customers/columns";
import { type DialogMode } from "../components/ApproveOrRejectDialog";
import { type EntitlementConfig } from "../hooks/useMarketplaceEntitlements";
import { useMarketplaceContext } from "../MarketplaceContext";
import { entitlementOptions } from "./utils";

type Props = {
  row: EntitlementConfig;
  setDialogMode: (value: DialogMode) => void;
  setClickedRow: (row: EntitlementConfig) => void;
};

export const EntitlementRow = ({ row, setDialogMode, setClickedRow }: Props) => {
  const infoSnackbar = useInfoSnackbar();
  const isMarketplaceAdmin = useDoitRoleCheck(DoitRole.MarketplaceAdmin);
  const [open, setOpen] = useState<boolean>(false);
  const { planHistory } = useMarketplaceContext();

  const clipboard = useClipboard({
    copiedTimeout: 1400,
    onSuccess: () => {
      infoSnackbar(globalText.COPIED_TO_CLIPBOARD);
    },
  });

  const handleApprove = useCallback(() => {
    setDialogMode("approve");
    setClickedRow(row);
  }, [row, setClickedRow, setDialogMode]);

  const handleReject = useCallback(() => {
    setDialogMode("reject");
    setClickedRow(row);
  }, [row, setClickedRow, setDialogMode]);

  const handleCopyId = useCallback(() => {
    clipboard.copy(row.ref.id);
  }, [clipboard, row.ref?.id]);

  const handleCopyAccountId = useCallback(() => {
    clipboard.copy(row.transform.procurementAccountId);
  }, [clipboard, row.transform.procurementAccountId]);

  const actionMenu = useMemo(
    () => ({
      [marketplaceText.COPY_ENTITLEMENT_ID]: handleCopyId,
      [marketplaceText.COPY_ACCOUNT_ID]: handleCopyAccountId,
      [globalText.APPROVE]: handleApprove,
      [globalText.REJECT]: handleReject,
    }),
    [handleApprove, handleCopyAccountId, handleCopyId, handleReject]
  );

  const actionHandler = useCallback(
    (action: string) => () => {
      actionMenu[action]();
    },
    [actionMenu]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () =>
      entitlementOptions
        .filter((option) => {
          switch (option.value) {
            case globalText.APPROVE:
            case globalText.REJECT:
              return (
                isMarketplaceAdmin &&
                !(
                  row.data.procurementEntitlement.state !== "ENTITLEMENT_ACTIVATION_REQUESTED" ||
                  (!row?.customer && option.value === globalText.APPROVE)
                )
              );
            default:
              return true;
          }
        })
        .map((option) => ({
          label: <Typography color={option.color}>{option.value}</Typography>,
          action: actionHandler(option.value),
          key: option.value,
        })),
    [actionHandler, isMarketplaceAdmin, row.data.procurementEntitlement.state, row?.customer]
  );

  const history = planHistory.get(row.ref.id);

  const planHistoryRowWrapper = useCallback(
    ({ row }: { row: GcpMarketplacePlanHistory }) => (
      <CellsWrapper>
        <TableCell>{row.action}</TableCell>
        <TableCell>{row.offer}</TableCell>
        <TableCell>
          {DateTime.fromSeconds(row.updateTime.seconds).toFormat(dateFormatCompleteDateWithTimeLocalized)}
        </TableCell>
      </CellsWrapper>
    ),
    []
  );

  return (
    <>
      <TableRow>
        <TableCell padding="none" style={{ width: 30, paddingLeft: 10 }}>
          {!!history?.length && (
            <IconButton
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          {row.customer ? (
            <Typography color="inherit" variant="body2" component={RouteLink} to={`/customers/${row.customer.ref.id}`}>
              {row.customer.data.primaryDomain}
            </Typography>
          ) : (
            <Typography color="inherit" variant="body2">
              {globalText.NA}
            </Typography>
          )}
        </TableCell>
        <TableCell>{row.ref.id}</TableCell>
        <TableCell>{row.billingAccountType}</TableCell>
        <TableCell>{row.transform.product}</TableCell>
        <TableCell>{row.transform.createTime}</TableCell>
        <TableCell>{row.transform.updateTime}</TableCell>
        <TableCell>{row.transform.state}</TableCell>
        <TableCell>
          <Box m={-1.5}>
            <ThreeDotsMenu options={threeDotsMenuOptions} />
          </Box>
        </TableCell>
      </TableRow>
      {!!history?.length && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={headers.length}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <FilterTable<GcpMarketplacePlanHistory>
                defaultSortingColumnValue="updateTime.seconds"
                showFilterBar={false}
                itemUniqIdentifierField="updateTime.seconds"
                rowComponent={planHistoryRowWrapper}
                fillEmptySpace={false}
                tableItems={history}
                filterColumns={[]}
                headerColumns={[
                  { value: "@", label: marketplaceText.ACTION },
                  { value: "@", label: marketplaceText.OFFER },
                  { value: "@", label: marketplaceText.UPDATE_TIME },
                ]}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
