import axios from "axios";

import { billingProfileHeadingId, navigationId } from "../../../constants";
import { consoleErrorWithSentry } from "../../../utils";
import { scrollToId } from "../../../utils/common";
import { type CreateBillingProfileData } from "../BillingProfileForm.models";

const verifyIsraeliNumber = (WTAXNUM: string) => {
  const matches = /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/.exec(WTAXNUM);
  if (!matches || matches[0] !== WTAXNUM) {
    return false;
  }
  const digits = matches.slice(1, 10).map((d) => parseInt(d, 10));
  const checksum = digits.reduce((memo, d, i) => {
    const current = d * ((i % 2) + 1);
    return memo + (current > 9 ? (current % 10) + 1 : current);
  }, 0);
  return checksum % 10 === 0;
};

export const verifyTaxId = (values: CreateBillingProfileData): boolean => {
  const taxId = values.taxId ?? "";
  switch (values.country) {
    case "United States":
      return /^[0-9A-Za-z-]*$/.test(taxId);
    case "Israel":
      return verifyIsraeliNumber(taxId);
    default:
      return /^[0-9A-Za-z-./]+$/.test(taxId);
  }
};

export function extractErrorMessage(error: unknown, defaultMessage?: string): string {
  if (axios.isAxiosError(error) && error.response?.data) {
    const errorDetails = error.response.data.message || error.response.data.error || error.response.data;
    return typeof errorDetails === "string" ? errorDetails : JSON.stringify(errorDetails);
  } else if (error instanceof Error) {
    return error.message;
  }

  return defaultMessage || "Unexpected error occurred.";
}

export const scrollToFormElement = (id: string) => {
  try {
    const nav = document.getElementById(navigationId);
    const navOffset = nav?.offsetHeight ?? 0;

    const pageHeading = document.getElementById(billingProfileHeadingId);
    const pageHeadingOffset = pageHeading?.offsetHeight ?? 0;
    const paddingCompensation = -16;

    scrollToId(id, navOffset + pageHeadingOffset + paddingCompensation);
  } catch (error) {
    consoleErrorWithSentry(error);
  }
};
