import { type Dispatch, type SetStateAction, useMemo } from "react";

import { DateTime } from "luxon";

import { sandboxText } from "../../assets/texts";
import { OPERATOR_AND } from "../../Components/FilterTable/constants";
import { FilterTable } from "../../Components/FilterTable/FilterTable";
import { useAuthContext } from "../../Context/AuthContext";
import { filterColumns, headers } from "./SandboxColumns";
import { SandboxRowWrapper } from "./SandboxRow";
import { type SandboxAccountWRef, type SandboxPolicyWRef, type SandboxTableRow } from "./types";

const currentMonth = DateTime.utc().startOf("month").toFormat("yyyy-MM");

const defaultFilters = (email: string, isDoitEmployee: boolean) => {
  const filters = [
    {
      column: filterColumns.find((c) => c.label === sandboxText.TABLE_HEADERS.STATUS),
      comparator: "==",
      value: "active",
      label: "Active",
    },
  ] as const;

  if (!isDoitEmployee) {
    return [
      ...filters,
      OPERATOR_AND,
      {
        column: filterColumns.find((c) => c.label === sandboxText.TABLE_HEADERS.OWNER),
        comparator: "==",
        value: email,
        label: email,
      },
    ] as const;
  }

  return filters;
};

export const calcSpend = ({
  utilization,
  interval,
}: {
  utilization: Record<string, number>;
  interval: string | undefined;
}) => {
  let spend = 0;
  if (interval === "one_time") {
    for (const m in utilization) {
      spend += utilization[m];
    }
  } else {
    spend = utilization?.[currentMonth] ?? 0;
  }

  return spend;
};
type Props = {
  policies: SandboxPolicyWRef[];
  sandboxes: SandboxAccountWRef[];
  setSelectedSandboxes: Dispatch<SetStateAction<SandboxAccountWRef[]>>;
  children: any;
};
const SandboxList = ({ policies, sandboxes, children, setSelectedSandboxes }: Props) => {
  const { isDoitEmployee, currentUser } = useAuthContext({ mustHaveUser: true });

  const rows = useMemo(() => {
    if (!sandboxes || !policies) {
      return null;
    }
    return sandboxes.map((sandbox) => {
      const _policy = policies.find((policy) => policy.ref.id === sandbox.data.policy.id);

      return {
        ...sandbox,
        spend: calcSpend({ utilization: sandbox.data.utilization, interval: _policy?.data.interval }),
        _policy,
      };
    });
  }, [sandboxes, policies]);

  if (rows === null) {
    return null;
  }

  return (
    <FilterTable<SandboxTableRow>
      showRowsSelection={true}
      onRowsSelected={setSelectedSandboxes}
      tableItems={rows}
      rowComponent={SandboxRowWrapper}
      headerColumns={headers}
      filterColumns={filterColumns}
      defaultFilters={defaultFilters(currentUser.email, isDoitEmployee)}
      filterBarPlaceholder={sandboxText.FILTER_SANDBOXES}
      persistenceKey="sandbox_tab"
      itemUniqIdentifierField="ref.id"
      defaultSortingColumnValue="data.updatedAt"
      toolbarProps={{
        title: "Sandbox accounts",
      }}
    >
      {children}
    </FilterTable>
  );
};

export default SandboxList;
