export const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (const index in array[i]) {
      if (line !== "") {
        line += ",";
      }

      line += array[i][index];
    }

    str += `${line}\r\n`;
  }
  return str;
};

export const exportCSVFile = (headers, items, fileTitle, convertToCSVFn = convertToCSV) => {
  if (headers) {
    items.unshift(headers);
  }
  if (convertToCSVFn !== null) {
    const jsonObject = JSON.stringify(items);
    const csv = convertToCSVFn(jsonObject);
    const exportedFilename = `${fileTitle}.csv`;
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
};

export const csvToJson = (csv) => {
  const lines = csv.split(/\r\n|\n|\r/);
  const result = [];
  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentline = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j]?.trim();
    }

    result.push(obj);
  }

  return result;
};

export const readCSV = async (filePathInPublicFolder) => {
  const response = await fetch(filePathInPublicFolder);
  const dataBlob = await response.blob();
  if (dataBlob.type !== "text/csv") {
    return new Error("couldn't find a CSV file");
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(csvToJson(reader.result));
    };
    reader.readAsText(dataBlob);
    reader.onerror = (error) => reject(error);
  });
};
