import { AvaOpeningSource, useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import { Divider, Link, Stack, type SxProps, type Theme, Typography } from "@mui/material";

import { useIsDCIEssentialsTier } from "../hooks/useIsDCIEssentialsTier";

type Props = {
  sx?: SxProps<Theme>;
};

const UnlockSupportOptionsDropdownBox = ({ sx }: Props) => {
  const { handleOpenAvaDialog } = useAvaDialogContext();
  const isDCIEssentialsTier = useIsDCIEssentialsTier();
  return (
    <>
      <Divider />
      <Stack sx={sx} display="flex" justifyContent="center">
        <Typography textAlign="center" fontWeight={500} variant="subtitle1">
          Unlock all support options
        </Typography>
        {isDCIEssentialsTier ? (
          <Stack direction="row" justifyContent="center" spacing={0.5}>
            <Typography>Contact your Account Manager to upgrade or</Typography>
            <Link
              variant="body1"
              textAlign="center"
              underline="none"
              sx={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={() => {
                handleOpenAvaDialog(AvaOpeningSource.PLATFORM_DROPDOWN);
              }}
            >
              Ask Ava
            </Link>
          </Stack>
        ) : (
          <Typography textAlign="center">Contact your Account Manager to upgrade your Solve plan</Typography>
        )}
      </Stack>
    </>
  );
};

export default UnlockSupportOptionsDropdownBox;
