import { Alert, AlertTitle, Stack } from "@mui/material";

import { marketplaceText } from "../../../assets/texts";
import useQueryString from "../../../Components/hooks/useQueryString";

const ErrorCodeCustomerIsNotEligibleFlexsave = "100";

const MarketplaceErrorMessage = ({ message }: { message: string }) => (
  <Stack alignItems="center" spacing={1} pt={16}>
    <Alert variant="standard" severity="error">
      <AlertTitle>Error</AlertTitle>
      {message}
    </Alert>
  </Stack>
);

export const MarketplaceError = () => {
  const { code: errorCode } = useQueryString();

  const ErrorMessage = () => {
    switch (errorCode) {
      case ErrorCodeCustomerIsNotEligibleFlexsave:
        return <MarketplaceErrorMessage message={marketplaceText.NOT_ELIGIBLE_FLEXSAVE_GCP_ERROR} />;
      default:
        return <MarketplaceErrorMessage message={marketplaceText.SUBSCRIBE_GENERAL_GCP_ERROR} />;
    }
  };

  return <ErrorMessage />;
};
