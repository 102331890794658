import React, { useContext } from "react";

import { Grid } from "@mui/material";

import { SortableWidget } from "../SortableWidget";
import { WidgetsContext } from "../WidgetsGrid";

type Props = {
  singleWidgetInRow: boolean;
};

export function StaticWidgetsGrid({ singleWidgetInRow }: Props) {
  const [widgets] = useContext(WidgetsContext);

  return (
    <Grid container spacing={2}>
      {widgets.map((item) => (
        <Grid item xs={singleWidgetInRow ? 12 : 6} mb={3} key={item.name} height={"380px"}>
          <SortableWidget key={item.name} id={item.name} width={"calc(100% - 10px)"} height={380} />
        </Grid>
      ))}
    </Grid>
  );
}
