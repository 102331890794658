import { cloudflowTexts } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type CloudflowExecutionSnap } from "../../../types/Cloudflow";

export const itemsDescriptions: FilterTableItemDescriptor<CloudflowExecutionSnap>[] = [
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.INSTANCE,
    value: "data.cloudflow.data.name",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.STATUS,
    value: "data.status",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.TRIGGERED_BY,
    value: "data.triggeredBy",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.TIME_COMPLETED,
    value: "data.endTime",
    filterType: "date",
    filterOptions: firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
