import { useEffect, useState } from "react";

import { AssetSettingModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";

import { globalText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { type Asset } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import { deleteField } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";

export const automationDialogCyId = "automationDialog";

type Props = {
  onClose: () => void;
  asset: Asset;
};

const minMaxValues = { min: 1, max: 20, step: 1 } as const;

type AutomationDisplay = {
  enabled: boolean;
  quantity: number | null;
  email: string;
};
const AutomationDialog = ({ onClose, asset }: Props) => {
  const sharedSnackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [automation, setAutomation] = useState<AutomationDisplay>();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { fullScreen } = useFullScreen();

  useEffect(
    () =>
      getCollection(AssetSettingModel)
        .doc(asset.id)
        .onSnapshot((docSnap) => {
          const val = docSnap.get("automation");
          if (val) {
            setAutomation(val);
          } else {
            setAutomation({
              quantity: 1,
              enabled: false,
              email: currentUser.email,
            });
          }
        }),
    [asset.id, currentUser.email]
  );

  const handleSave = async () => {
    setLoading(true);
    try {
      if (automation?.enabled && automation.quantity !== null) {
        if (automation.quantity >= 1 && automation.quantity <= 20) {
          await getCollection(AssetSettingModel)
            .doc(asset.id)
            .update({
              automation: {
                ...automation,
                quantity: automation.quantity,
              },
            });
          mixpanel.track("assets.auto-increase.enable", {
            quantity: automation.quantity,
            skuId: asset.data.properties.subscription.skuId,
            skuName: asset.data.properties.subscription.skuName,
            customerDomain: asset.data.properties.customerDomain,
            customerId: asset.data.properties.customerId,
            subscriptionId: asset.data.properties.subscription.subscriptionId,
            reseller: asset.data.properties.reseller,
          });

          sharedSnackbar.onOpen({
            message: "Auto-Increase enabled successfully",
            variant: "success",
            autoHideDuration: 5000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
        } else {
          sharedSnackbar.onOpen({
            message: `Quantity must be between ${minMaxValues.min} and ${minMaxValues.max}`,
            variant: "error",
            autoHideDuration: 5000,
            action: [
              <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
                <CloseIcon />
              </IconButton>,
            ],
          });
        }
      } else {
        await getCollection(AssetSettingModel).doc(asset.id).update({
          automation: deleteField(),
        });
        mixpanel.track("assets.auto-increase.disable", {
          skuId: asset.data.properties.subscription.skuId,
          skuName: asset.data.properties.subscription.skuName,
          customerDomain: asset.data.properties.customerDomain,
          customerId: asset.data.properties.customerId,
          subscriptionId: asset.data.properties.subscription.subscriptionId,
          reseller: asset.data.properties.reseller,
        });
        sharedSnackbar.onOpen({
          message: "Auto-Increase disabled",
          variant: "info",
          autoHideDuration: 5000,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
              <CloseIcon />
            </IconButton>,
          ],
        });
      }
      onClose();
    } catch (error) {
      sharedSnackbar.onOpen({
        message: "Failed to enable auto-increase",
        variant: "error",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="automation-dialog-title"
      // aria-describedby="automation-dialog-description"
      onClose={preventOnCloseWhile(loading, onClose)}
      fullScreen={fullScreen}
      data-cy={automationDialogCyId}
    >
      {automation && (
        <>
          <DialogTitle id="automation-dialog-title">Configure auto-increase</DialogTitle>
          <DialogContent>
            <DialogContentText id="automation-dialog-description">
              By configuring auto-increase,{" "}
              <b>
                {asset.data.properties.subscription.skuName} ({asset.data.properties.customerDomain})
              </b>{" "}
              subscription will maintain the configured below minimum amount of available licenses. You will get email
              notifications when new licenses are purchased.
            </DialogContentText>

            <Grid container direction="column" spacing={2}>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Switch
                      checked={automation?.enabled}
                      onChange={(event) => {
                        setAutomation({
                          ...automation,
                          enabled: event.target.checked,
                          quantity: event.target.checked ? automation.quantity : 1,
                          email: currentUser.email,
                        });
                      }}
                      color="primary"
                      data-cy="automation-switch"
                    />
                  }
                  label={`Enable Auto-Increase for ${asset.data.properties.subscription.skuName}`}
                />
              </Grid>

              <Grid item xs={10} md={8} lg={6}>
                <TextField
                  label="Keep available licenses"
                  variant="outlined"
                  type="number"
                  value={automation.quantity === null ? "" : automation.quantity.toString()}
                  disabled={!automation.enabled}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value !== "") {
                      setAutomation({
                        ...automation,
                        quantity: parseInt(value, 10),
                        email: currentUser.email,
                      });
                    } else {
                      setAutomation({
                        ...automation,
                        quantity: null,
                        email: currentUser.email,
                      });
                    }
                  }}
                  InputProps={{ inputProps: { "data-cy": "automation-quantity", ...minMaxValues } }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <DialogContentText>
                  {automation.enabled ? `Last modified by ${automation.email}` : <>&nbsp;</>}
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="text" onClick={onClose} disabled={loading}>
              {globalText.CANCEL}
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={
                loading ||
                !automation.quantity ||
                automation.quantity < minMaxValues.min ||
                automation.quantity > minMaxValues.max
              }
              loading={loading}
              mixpanelEventId="assets.automation.save"
              data-cy="saveBtn"
            >
              {globalText.SAVE}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default AutomationDialog;
