import { useEffect, useState } from "react";

import { type PerkField, type PerkModule, PerkTypes } from "@doitintl/cmp-models";
import { string as YupString } from "yup";

export const usePerkFormValidator = ({
  fields,
  agenda,
  modules,
  logoUrl,
  isDoitCustomer,
}: {
  fields: PerkField;
  agenda: string;
  modules: PerkModule[];
  logoUrl: string;
  isDoitCustomer: boolean;
}) => {
  /* DEVNOTE: Talk to Kate Koppel about how to get this to work
   *  Suspect it has something to do with fields.title. The DomainInput is also gooshed, causing tests to fail.
   */
  const [isFormValid, setIsFormValid] = useState(true);
  useEffect(() => {
    switch (true) {
      case fields.name.replace(/\s{2,}/g, " ").trim() === "" ||
        fields?.shortDescription?.replace(/\s{2,}/g, " ").trim() === "":
        setIsFormValid(false);
        break;
      case fields.type === PerkTypes.IsvSolution &&
        (!fields.title || fields?.title?.replace(/\s{2,}/g, " ").trim() === ""):
        setIsFormValid(false);
        break;
      case fields.platform.length > 0 &&
        fields?.platformUrl &&
        Object.values(fields.platformUrl).some((url) => !YupString().url().isValidSync(url)):
        setIsFormValid(false);
        break;
      case fields.type === PerkTypes.IsvSolution && fields.solutionType?.length === 0:
        setIsFormValid(false);
        break;
      case fields.videoUrl && !YupString().url().isValidSync(fields.videoUrl):
        setIsFormValid(false);
        break;
      case fields.type === PerkTypes.IsvSolution &&
        !isDoitCustomer &&
        (!logoUrl || logoUrl.replace(/\s{2,}/g, " ").trim() === "" || !YupString().url().isValidSync(logoUrl)):
        setIsFormValid(false);
        break;
      default:
        setIsFormValid(true);
    }
  }, [agenda, fields, isDoitCustomer, logoUrl, modules]);

  return [isFormValid];
};
