import { type CustomerAuth, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { isAuthProviderType } from "../helpers";
import { type AuthSettingsType, type AutoProvisionType } from "../types";

export async function setAuthSettings({
  customerId,
  newAuthSettings,
}: {
  customerId: string;
  newAuthSettings: AuthSettingsType;
}) {
  // using type-safed key and value cause the update function is not type-safe for more than single key-value pair
  const autoProvisionKey: `${keyof Pick<CustomerModel, "auth">}.${keyof Pick<CustomerAuth, "autoProvision">}` =
    "auth.autoProvision";
  const autoProvisioVal: AutoProvisionType = newAuthSettings.autoProvision;

  await getCollection(CustomerModel)
    .doc(customerId)
    .update(
      "allowAuthProvider",
      isAuthProviderType(newAuthSettings.allowedProvider) ? newAuthSettings.allowedProvider : null,
      autoProvisionKey,
      autoProvisioVal
    );
}
