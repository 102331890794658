import { Link } from "react-router-dom";

import { ChartCard } from "../../Components/ChartCard/ChartCard";
import { type ChartCardType } from "../../Components/ChartCard/useChartCardOptions";
import { customersRoute } from "../../Navigation/core/utils";
import mixpanel from "../../utils/mixpanel";

export type ReportCardProps = {
  type: ChartCardType;
  title: string;
  reportId: string;
  mixpanelTitle: string;
};

export const ReportChartCard = ({ customerId, card }: { customerId: string; card: ReportCardProps }) => (
  <Link
    to={customersRoute(customerId, `analytics/reports/${card.reportId}`)}
    style={{ textDecoration: "none" }}
    onClick={() => {
      mixpanel.track(`home.reports.${card.mixpanelTitle}.click`, { $referrer: location.href });
    }}
  >
    <ChartCard type={card.type} title={card.title} />
  </Link>
);
