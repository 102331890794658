import { type FC, useCallback, useMemo } from "react";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import isString from "lodash/isString";

import useQueryString from "../../../Components/hooks/useQueryString";
import { Loader } from "../../../Components/Loader";
import NoEntitlement from "../../../Components/NoEntitlement/NoEntitlement";
import { isLocalhost } from "../../../constants";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { useCustomerTeamsChannels } from "./hooks";
import { MsTeamsInstructions } from "./MsTeamsInstructions";
import { TeamConnectionAlert } from "./TeamConnectionAlert";
import { TeamsChannelConnections } from "./TeamsChannelConnections";

export const cyIds = {
  integration: "ms-teams-integration",
};

export const MsTeams: FC = () => {
  const msTeamsAppLink = "https://www.example.com"; // TODO: replace this with the real app link when published

  const isEntitled = useIsFeatureEntitled("notification:msteams");
  const { userRoles } = useUserContext();
  const theme = useTheme();
  const { channels, channelsLoading, channelsError } = useCustomerTeamsChannels();
  const qs = useQueryString();
  const payload = useMemo<string | undefined>(() => {
    if (!isString(qs.payload)) {
      return undefined;
    }

    return qs.payload;
  }, [qs.payload]);

  const showChannelConnections = useMemo<boolean>(() => {
    if (channelsError) {
      return false;
    }

    if (!channelsLoading && !channels?.length) {
      // noting to see here - either we've loaded and there are no channels, or channels is undefined.
      return false;
    }

    return true;
  }, [channels, channelsLoading, channelsError]);

  const openTeamsApp = useCallback<() => void>(() => {
    window.open(msTeamsAppLink, "_blank", "noopener");
  }, []);

  return (
    <>
      {!isEntitled ? (
        <NoEntitlement feature="MSTeams" />
      ) : !userRoles?.manageSettings ? (
        <Alert severity="info">You do not have permission to manage settings</Alert>
      ) : (
        <Loader loading={channelsLoading}>
          <Stack data-cy={cyIds.integration} gap={2} sx={{ maxWidth: theme.breakpoints.values.lg }}>
            <Typography variant="h1" mb={2}>
              Microsoft Teams
            </Typography>
            {!!channelsError && isLocalhost && (
              <Alert severity="error">
                Error fetching channels: {channelsError.message} (This message is visible to dev / localhost only)
              </Alert>
            )}
            {!!payload && <TeamConnectionAlert payload={payload} />}
            {showChannelConnections && <TeamsChannelConnections channels={channels!} openApp={openTeamsApp} />}
            {/* Using noop as a placeholder until we have the real link */}
            <MsTeamsInstructions openApp={openTeamsApp} />
          </Stack>
        </Loader>
      )}
    </>
  );
};
