import { useState } from "react";

import { Button } from "@mui/material";

import { reportTxt } from "../../../../../assets/texts/CloudAnalytics";
import DeleteDialog from "../../../templateLibrary/DeleteDialog";

type Props = {
  disabled?: boolean;
  outlined?: boolean;
};

const DeleteTemplate = ({ disabled, outlined }: Props) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  return (
    <>
      <Button
        color="error"
        data-cy="delete-template"
        disabled={disabled}
        onClick={() => {
          setDeleteDialogOpen(true);
        }}
        variant={outlined ? "outlined" : "text"}
      >
        {reportTxt.DELETE}
      </Button>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

export default DeleteTemplate;
