import { useRef, useState } from "react";

import { DraggableCore } from "react-draggable";
import { Box } from "@mui/material";

import { DropLine } from "./DropLine";
import { useHover } from "./hooks/useHover";

type Props = {
  disabled: boolean;
  onResizeStart: () => void;
  onResize: (delta: number) => void;
  onResizeEnd: () => void;
  vertical?: boolean;
};

export function ResizingHandle({ disabled, onResize, onResizeStart, onResizeEnd, vertical = false }: Props) {
  const [x, setX] = useState(-1);
  const [y, setY] = useState(-1);

  const mouseOverRef = useRef<HTMLDivElement>(null);
  const mouseOver = useHover(mouseOverRef);
  const [resizing, setResizing] = useState(false);

  return (
    <DraggableCore
      handle=".handle"
      disabled={disabled}
      onStart={(_e, data) => {
        onResizeStart();
        setX(data.x);
        setY(data.y);
        setResizing(true);
      }}
      onDrag={(_e, data) => {
        if (vertical) {
          onResize(data.x - x);
        } else {
          onResize(data.y - y);
        }
      }}
      onStop={() => {
        onResizeEnd();
        setResizing(false);
      }}
    >
      <Box
        ref={mouseOverRef}
        className="handle"
        sx={
          disabled
            ? {}
            : {
                pt: !vertical ? 0.4 : undefined,
                "&:hover": {
                  cursor: vertical ? "ew-resize" : "ns-resize",
                },
              }
        }
        width={"100%"}
        height={"100%"}
      >
        {!disabled && (mouseOver || resizing) && <DropLine vertical={vertical} leftSpacing />}
      </Box>
    </DraggableCore>
  );
}
