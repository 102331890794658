import { useEffect, useState } from "react";

import { RampModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { TimestampFromDateTime } from "../../../utils/common";
import { type RampPlanModel } from "../types";

const useGetRampPlan = (rampId: string | null | undefined) => {
  const [rampPlanData, setRampPlanData] = useState<RampPlanModel | null>();
  useEffect(() => {
    if (!rampId) {
      setRampPlanData(undefined);
      return;
    }
    getCollection(RampModel)
      .doc(rampId)
      .onSnapshot((snap) => {
        const data = snap.asModelData();

        if (!data) {
          setRampPlanData(null);
          return;
        }

        const startDateTime = DateTime.fromSeconds(data.startDate?.seconds).toUTC();
        const a: RampPlanModel = {
          ...data,
          startDate: TimestampFromDateTime(startDateTime),
          id: rampId,
          ref: snap.modelRef,
        };
        setRampPlanData(a);
      });
  }, [rampId]);

  return { rampPlanData };
};
export default useGetRampPlan;
