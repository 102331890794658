import { useMemo } from "react";

import { type ReportsValues, useReports } from "./reports/useReports";
import { type ReportTemplateFavoriteValues, useReportTemplateFavorites } from "./useReportTemplateFavorites";
import { type ReportTemplatesValues, useReportTemplates } from "./useReportTemplates";

export type ReportsData = {
  reportTemplates: ReportTemplatesValues;
  reportTemplateFavorites: ReportTemplateFavoriteValues;
  reports: ReportsValues;
  loading: boolean;
};

export const useReportsData = (): ReportsData => {
  const [reportTemplatesWithVersions, _dispatchReportTemplates, managedReportsIds, reportsTemplatesLoading] =
    useReportTemplates();

  const reportTemplateFavorites = useReportTemplateFavorites(reportTemplatesWithVersions);

  const [filteredReportTemplateFavorites] = reportTemplateFavorites;

  const reports = useReports(reportTemplatesWithVersions, managedReportsIds, filteredReportTemplateFavorites);

  const [_reportsList, _filteredReports, reportsLoading] = reports;

  return useMemo(
    () => ({
      reportTemplates: [
        reportTemplatesWithVersions,
        _dispatchReportTemplates,
        managedReportsIds,
        reportsTemplatesLoading,
      ],
      reportTemplateFavorites,
      reports,
      loading: reportsLoading,
    }),
    [
      _dispatchReportTemplates,
      managedReportsIds,
      reportTemplateFavorites,
      reportTemplatesWithVersions,
      reports,
      reportsLoading,
      reportsTemplatesLoading,
    ]
  );
};
