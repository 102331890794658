import { domainConfig } from "../domainConfig";
import useMountEffect from "./hooks/useMountEffect";

const setFavicon = (favicons) => {
  favicons.forEach((favicon) => {
    if (favicon !== null && favicon instanceof HTMLLinkElement) {
      favicon.href = domainConfig.assets.favIcon;
    }
  });
};

const PartnerBranding = () => {
  useMountEffect(() => {
    const favicons = [...document.getElementsByClassName("favicon")];
    document.title = domainConfig.title;

    if (favicons.length > 0) {
      setFavicon(favicons);
    } else {
      const observer = new MutationObserver(() => {
        const favicons = [...document.getElementsByClassName("favicon")];
        if (favicons.length > 0) {
          setFavicon(favicons);
          observer.disconnect();
        }
      });

      observer.observe(document.head, { childList: true });
    }
  });
  return null;
};
export default PartnerBranding;
