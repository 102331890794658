import { useCallback } from "react";

import { MenuItem, TextField } from "@mui/material";

import { dciCommitmentTermOptions, subscriptionTypeOptions } from "../../../const";
import { useContractFormContext } from "../../../ContractsFormContext";

type Props = {
  disabled: boolean;
};

export const DciSubscriptionTypeSelector = ({ disabled }: Props) => {
  const { state, setState } = useContractFormContext();

  const onCommitmentChange = useCallback(
    ({ target }) => {
      const isCommitment = target.value === "commitment";
      setState((prevState) => ({
        ...prevState,
        isCommitment,
        commitmentMonths: !isCommitment ? undefined : dciCommitmentTermOptions[0],
        endDate: !isCommitment ? null : state.startDate.plus({ months: dciCommitmentTermOptions[0] }),
      }));
    },
    [setState, state.startDate]
  );

  return (
    <TextField
      value={state.isCommitment ? "commitment" : "on-demand"}
      onChange={onCommitmentChange}
      label="Subscription types"
      disabled={disabled}
      error={state.errors.isCommitment}
      fullWidth
      select
    >
      {subscriptionTypeOptions.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};
