import { collection, doc, type Reference, subCollection } from "@doitintl/models-types";

@collection("cloudflowEngine")
export class CloudflowEngine {
  docs?: {
    integrations: Integrations;
  };
}

@doc("integrations")
export class Integrations {
  subCollections?: {
    apis: ApiDescriptor;
  };
}

@subCollection("apis")
export class ApiDescriptor {
  provider!: string;

  subCollections?: {
    services: ApiServiceDescriptor;
  };
}

@subCollection("services")
export class ApiServiceDescriptor {
  serviceName!: string;

  permissionsNamespace?: string;

  iconURL?: string;

  documentation?: string;

  documentationLink?: string;

  latestVersion!: string;

  subCollections?: {
    versions: ApiServiceVersionDescriptor;
  };
}

@subCollection("versions")
export class ApiServiceVersionDescriptor {
  versionId!: string;

  name?: string;

  subCollections?: {
    operations: ApiServiceOperationDescriptor;
    models: ApiServiceModelDescriptor;
  };
}

export enum ApiServiceOperationType {
  RPC = "rpc",
  HTTP = "http",
}

export class ApiServiceBaseOperationDescriptor<
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  OperationType extends ApiServiceOperationType = ApiServiceOperationType,
> {
  type!: OperationType;

  versionRef!: Reference<ApiServiceVersionDescriptor>;

  operationName!: string;

  documentation?: string;

  inputModel?: string;

  outputModel?: string;

  parameters?: StructureApiServiceModelDescriptor<Member>;

  pagination?: {
    inputMember?: string;
    outputMember?: string;
    nextPageFlag?: string;
    pageSize?: string;
  };
}

@subCollection("operations")
export class ApiServiceRpcOperationDescriptor extends ApiServiceBaseOperationDescriptor<ApiServiceOperationType.RPC> {
  commandName!: string;

  moduleName!: string;
}

@subCollection("operations")
export class ApiServiceHttpOperationDescriptor extends ApiServiceBaseOperationDescriptor<ApiServiceOperationType.HTTP> {
  headers?: Headers;

  payload?: unknown;

  resource?: string;

  scopes?: string[];

  pathParameters?: Record<string, HttpPathParameterModelDescriptor>;

  httpRequestType?: HttpRequestType;
}

export type ApiServiceOperationDescriptor = ApiServiceRpcOperationDescriptor | ApiServiceHttpOperationDescriptor;

export type MemberReference = {
  modelId: string;
  documentation?: string;
};

export type Member = {
  model: UnwrappedApiServiceModelDescriptor;
  documentation?: string;
};

export enum HttpRequestType {
  GET = "get",
  PUT = "put",
  POST = "post",
  DELETE = "delete",
  OPTIONS = "options",
  HEAD = "head",
  PATCH = "patch",
}

export enum ModelType {
  INTEGER = "integer",
  BOOLEAN = "boolean",
  FLOAT = "float",
  STRING = "string",
  LIST = "list",
  STRUCTURE = "structure",
  TIMESTAMP = "timestamp",
  MAP = "map",
  UNION = "union",
}

export class BaseApiServiceModelDescriptor {
  documentation?: string;

  example?: string;
}

@subCollection("models")
export class IntegerApiServiceModelDescriptor extends BaseApiServiceModelDescriptor {
  type!: ModelType.INTEGER;

  min?: number;

  max?: number;
}

@subCollection("models")
export class FloatApiServiceModelDescriptor extends BaseApiServiceModelDescriptor {
  type!: ModelType.FLOAT;

  min?: number;

  max?: number;
}

@subCollection("models")
export class BooleanApiServiceModelDescriptor extends BaseApiServiceModelDescriptor {
  type!: ModelType.BOOLEAN;
}

@subCollection("models")
export class StringApiServiceModelDescriptor extends BaseApiServiceModelDescriptor {
  type!: ModelType.STRING;

  minLength?: number;

  maxLength?: number;

  pattern?: string;

  enum?: string[];

  enumDescriptions?: string[];

  sensitive?: true;

  multiline?: true;
}

@subCollection("models")
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export class ListApiServiceModelDescriptor<MemberType> extends BaseApiServiceModelDescriptor {
  type!: ModelType.LIST;

  member!: MemberType;

  memberName?: string;

  min?: number;

  max?: number;
}

@subCollection("models")
export class StructureApiServiceModelDescriptor<MemberType> extends BaseApiServiceModelDescriptor {
  type!: ModelType.STRUCTURE;

  members!: Record<string, MemberType>;

  requiredMembers?: string[];
}

@subCollection("models")
export class TimestampApiServiceModelDescriptor extends BaseApiServiceModelDescriptor {
  type!: ModelType.TIMESTAMP;

  timestampFormat?: string;
}

@subCollection("models")
export class MapApiServiceModelDescriptor<
  MemberType extends Member | MemberReference,
> extends BaseApiServiceModelDescriptor {
  type!: ModelType.MAP;

  keyMember!: MemberType;

  keyMemberName!: string;

  valueMember!: MemberType;

  valueMemberName!: string;

  min?: number;

  max?: number;

  sensitive?: true;
}

@subCollection("models")
export class UnionApiServiceModelDescriptor extends BaseApiServiceModelDescriptor {
  type!: ModelType.UNION;

  members!: UnwrappedApiServiceModelDescriptor[];
}

export type ApiServiceModelDescriptor<MemberType extends Member | MemberReference = Member | MemberReference> =
  | IntegerApiServiceModelDescriptor
  | FloatApiServiceModelDescriptor
  | BooleanApiServiceModelDescriptor
  | StringApiServiceModelDescriptor
  | ListApiServiceModelDescriptor<MemberType>
  | StructureApiServiceModelDescriptor<MemberType>
  | TimestampApiServiceModelDescriptor
  | MapApiServiceModelDescriptor<MemberType>
  | UnionApiServiceModelDescriptor;

export type HttpPathParameterModelDescriptor =
  | IntegerApiServiceModelDescriptor
  | FloatApiServiceModelDescriptor
  | StringApiServiceModelDescriptor;

export type UnwrappedApiServiceModelDescriptor = ApiServiceModelDescriptor<Member>;
