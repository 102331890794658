import { useCallback, useMemo } from "react";

import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

import { useErrorSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useFullScreen } from "../../../../../utils/dialog";
import { toCapitalCase } from "../../../../../utils/string";
import { useUpdatePayer } from "../hooks";
import { AWSPayerConfigForm } from "./AWSPayerConfigForm";
import { type EditFormValues, type ExtendedFlexsavePayerConfigs } from "./types";
import { convertToConfigRequest } from "./utils";

type Props = {
  onClose: () => void;
  payerConfig: ExtendedFlexsavePayerConfigs;
};

export const EditAWSPayerConfigModal = ({ onClose, payerConfig }: Props) => {
  const { isMobile: isSmallScreen } = useFullScreen("sm");
  const payerUpdateRequest = useUpdatePayer();
  const initialValues = useMemo(
    () => ({
      ...payerConfig,
      statusChangeReason: "",
    }),
    [payerConfig]
  );
  const showError = useErrorSnackbar();

  const onSubmit = useCallback(
    async (formValues: EditFormValues) => {
      const payerConfigs = convertToConfigRequest(formValues, initialValues);

      try {
        await payerUpdateRequest(payerConfig.accountId, payerConfigs);

        onClose();
      } catch (e: any) {
        if (typeof e?.response?.data?.error === "string") {
          showError(toCapitalCase(e.response.data.error));
        } else {
          showError(`Could not update configuration.`);
        }
      }
    },
    [payerConfig.accountId, onClose, payerUpdateRequest, showError, initialValues]
  );

  return (
    <Dialog open={true} fullScreen={isSmallScreen} fullWidth maxWidth="sm" onClose={onClose}>
      {payerConfig && initialValues ? (
        <>
          <DialogTitle mb={-1}>Edit payer</DialogTitle>
          <Typography mx={3} color="textSecondary">
            {[payerConfig.friendlyName, payerConfig.accountId].filter(Boolean).join(" - ")}
          </Typography>

          <AWSPayerConfigForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onClose} />
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" p={6}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};
