import PersonIcon from "@mui/icons-material/PersonRounded";
import AutoMessageIcon from "@mui/icons-material/RecordVoiceOverRounded";
import { Avatar, styled } from "@mui/material";

import { type CustomerNote } from "../types";

type Props = {
  noteItem: CustomerNote;
};

const StyledAvatar = styled(Avatar)({
  width: 48,
  height: 48,
});

export const NoteAvatar = ({ noteItem }: Props) => {
  const { email, name, photoURL } = noteItem;
  if (email) {
    if (photoURL) {
      return <StyledAvatar alt={name ?? ""} src={photoURL} data-cy="note-avatar" />;
    } else if (name && name.trim() !== "") {
      const userName = name.match(/\b\w/g) ?? [];
      const initials = ((userName.shift() ?? "") + (userName.pop() ?? "")).toUpperCase();
      return (
        <StyledAvatar alt={name} data-cy="note-avatar">
          {initials}
        </StyledAvatar>
      );
    } else {
      return (
        <StyledAvatar data-cy="note-avatar">
          <PersonIcon />
        </StyledAvatar>
      );
    }
  } else {
    return (
      <StyledAvatar sx={{ background: (theme) => theme.palette.secondary.main }} data-cy="note-avatar">
        <AutoMessageIcon />
      </StyledAvatar>
    );
  }
};
