import { type SyntheticEvent, useState } from "react";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";

import { reportText } from "../../../assets/texts";
import { useCreateAlertHandler } from "../alerts/hooks";
import { useCreateAttributionGroupHandler } from "../attributionGroups/hooks";
import { useCreateAttributionHandler } from "../attributions/hooks";
import { useCreateBudgetHandler } from "../budgets/hooks";
import { useCreateMetricHandler } from "../metrics/hooks";
import { useNewReportHandler } from "../report/hooks";
import { useFormatLink } from "./hooks";

type NewAnalyticsResourceButtonProps = {
  agCreateDisabled?: boolean;
  budgetCreateDisabled?: boolean;
};

export const NewAnalyticsResourceButton = ({
  agCreateDisabled,
  budgetCreateDisabled,
}: NewAnalyticsResourceButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const alertLink = useFormatLink({ type: "alert" });
  const attributionLink = useFormatLink({ type: "attribution" });
  const attributionGroupLink = useFormatLink({ type: "attribution_group" });
  const reportLink = useFormatLink({ type: "report" });
  const budgetLink = useFormatLink({ type: "budget" });
  const metricLink = useFormatLink({ type: "metric" });

  const handleNewAlert = useCreateAlertHandler({
    mixpanelEventName: "analytics.resources.alerts.new",
    baseUrl: alertLink,
  });
  const handleNewAttributionGroups = useCreateAttributionGroupHandler({
    mixpanelEventName: "analytics.resources.attribution-groups.create",
    baseUrl: attributionGroupLink,
  });
  const handleNewAttribution = useCreateAttributionHandler({
    baseUrl: attributionLink,
    mixpanelEventName: "analytics.resources.attributions.new",
  });
  const handleNewReport = useNewReportHandler({
    baseUrl: reportLink,
    mixpanelEventName: "analytics.resources.reports.new",
  });
  const handleNewBudget = useCreateBudgetHandler({
    baseUrl: budgetLink,
    mixpanelEventName: "analytics.resources.budgets.new",
  });
  const handleNewMetric = useCreateMetricHandler({
    baseUrl: metricLink,
    mixpanelEventName: "analytics.resources.metrics.new",
  });
  const menuItems = [
    { label: "Attribution", handler: handleNewAttribution },
    { label: "Attribution group", handler: handleNewAttributionGroups, disabled: agCreateDisabled },
    { label: "Alert", handler: handleNewAlert },
    { label: "Budget", handler: handleNewBudget, disabled: budgetCreateDisabled },
    { label: "Metric", handler: handleNewMetric },
  ];
  return (
    <>
      <ButtonGroup variant="contained">
        <Button onClick={handleNewReport}>{reportText.CREATE_NEW_REPORT}</Button>
        <Button
          sx={{ px: 1 }}
          onClick={(e: SyntheticEvent) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItems.map(({ label, handler, disabled }) => (
          <MenuItem key={label} onClick={handler} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
