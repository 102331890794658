import { type ReactNode } from "react";

import { Stack, Typography } from "@mui/material";

const SectionHeader = ({ link = null, text }: { link?: ReactNode | null; text: ReactNode }) => (
  <Stack
    direction={{ xs: "column", sm: "row" }}
    justifyContent={{ xs: "flex-start", sm: "space-between" }}
    alignItems={{ xs: "flex-start", sm: "center" }}
    spacing={1}
  >
    <Typography variant="h3">{text}</Typography>
    {link}
  </Stack>
);

export default SectionHeader;
