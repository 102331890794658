import { type ThreadsPageId } from "../../Navigation/config/pages";
import { ThreadDetails } from "./ThreadDetails/ThreadDetails";
import { ThreadsOverview } from "./ThreadsOverview/ThreadsOverview";

export function ThreadsRouter({ pageId }: { pageId: ThreadsPageId }) {
  switch (pageId) {
    case "threads:details": {
      return <ThreadDetails />;
    }

    default: {
      return <ThreadsOverview />;
    }
  }
}
