import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Box, Button, Stack, Tooltip } from "@mui/material";

import { datahubTxt } from "../../assets/texts/DataHub/datahub";
import NoEntitlement, { CardsComponent } from "../../Components/BlankStateUpsell/NoEntitlement";
import DeleteDialog from "../../Components/DeleteDialog";
import { FilterTable } from "../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../Components/FilterTable/FilterTableSkeleton";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import useGenerateReport from "../../Components/hooks/useGenerateReport";
import { CircularProgressLoader } from "../../Components/Loader";
import LoadingButton from "../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDataHubContext } from "../../Context/DataHubContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { TimestampFromDate } from "../../utils/firebase";
import CreateDatahubDialog from "./CreateDatahubDialog";
import ImportCSVStepper from "./CSVImport/ImportCSVStepper";
import DataHubSourceRow from "./DataHubSourceRow";
import { filterColumns, headers } from "./DataHubSourcesColumns";
import ImportDataDialog from "./ImportDataDialog";
import {
  buildConfig,
  deleteProcessingMinutes,
  isAnySelectedUpdatedWithinLastXMinutes,
  metadataProcessingMinutes,
} from "./utils";

export type DatasetItem = {
  dataset: string;
  updatedBy: string;
  records: number;
  lastUpdated: string;
  processing?: number; // holds the number of records that are currently being processed
};

const DataHubSources = () => {
  const generateReport = useGenerateReport();
  const history = useHistory();
  const customerId = useCustomerId();
  const { customer } = useCustomerContext();
  const { datahubItems, loading, fetchDataHubItems, deleteDataHubDatasets } = useDataHubContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const [selected, setSelected] = useState<DatasetItem[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [loadingExplore, setLoadingExplore] = useState(false);
  const [openImportDataDialog, setOpenImportDataDialog] = useState(false);
  const [datasetsRefreshed, setDatasetsRefreshed] = useState(false);
  const [showImportStepper, setShowImportStepper] = useState(false);
  const [currentDatasetName, setCurrentDatasetName] = useState("");
  const [accountManagerMail, setAccountManagerMail] = useState("");
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const isEntitled = useIsFeatureEntitled("pdi:datahub");
  const init = useRef(false);

  useEffect(() => {
    if (!init.current && !loading) {
      init.current = true;
    }
  }, [loading]);

  useEffect(() => {
    const accountManagerId = customer.accountManager?.id;
    if (!accountManagerId || !accountManagers) {
      setAccountManagerMail("");
      return;
    }

    const customerAccountManager = accountManagers.find((am) => am.id === accountManagerId);

    setAccountManagerMail(customerAccountManager?.email ?? "");
  }, [accountManagers, customer.accountManager]);

  const handleDeleteDataSources = useCallback(async () => {
    const selectedWithinLast90Minutes = isAnySelectedUpdatedWithinLastXMinutes(
      selected,
      "lastUpdated",
      deleteProcessingMinutes
    );
    if (selectedWithinLast90Minutes) {
      errorSnackbar(datahubTxt.DATASET_RECENTLY_INGESTED);
      return;
    }

    const res = await deleteDataHubDatasets(selected.map((item) => item.dataset));
    if (res.status === 200) {
      successSnackbar(datahubTxt.DATASETS_DELETED_SUCCESSFULLY);
    } else if (res?.response?.data?.error === datahubTxt.DATASET_RECENTLY_INGESTED) {
      errorSnackbar(datahubTxt.DATASET_RECENTLY_INGESTED);
    } else {
      errorSnackbar(datahubTxt.DATASET_FAILED_DELETE);
    }
  }, [deleteDataHubDatasets, errorSnackbar, selected, successSnackbar]);

  const deleteDataSourcesDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes(selected, "lastUpdated", deleteProcessingMinutes),
    [selected]
  );

  const openInReportsDisabled = useMemo(
    () => isAnySelectedUpdatedWithinLastXMinutes(selected, "lastUpdated", metadataProcessingMinutes),
    [selected]
  );

  const handleExplore = useCallback(
    async (dataset?: string) => {
      setLoadingExplore(true);
      const values = dataset ? [dataset] : selected.map((item) => item.dataset);
      const name = dataset ? `${datahubTxt.EXPLORE} ${dataset}` : datahubTxt.EXPLORE_DATASETS_NAME;
      const config = buildConfig(values);
      const newReportId = await generateReport(
        {
          name,
          config,
          draft: true,
          expireBy: TimestampFromDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)),
        },
        true
      );
      setLoadingExplore(false);
      history.push(`/customers/${customerId}/analytics/reports/${newReportId}?run-on-open=true`);
    },
    [customerId, generateReport, history, selected]
  );

  const onImportData = useCallback((datasetName: string) => {
    setOpenImportDataDialog(true);
    setCurrentDatasetName(datasetName);
  }, []);

  const handleOpenDeleteDialog = useCallback((dataset: DatasetItem) => {
    setSelected([dataset]);
    setOpenDeleteDialog(true);
  }, []);

  const handleOnClickRefresh = useCallback(async () => {
    setIsRefreshLoading(true);
    await fetchDataHubItems(true);
    setIsRefreshLoading(false);
    setDatasetsRefreshed(true);
  }, [fetchDataHubItems]);

  const DataHubSourceRowWrapper = useCallback(
    ({ row }: { row: DatasetItem }) => (
      <DataHubSourceRow
        data={row}
        handleExplore={handleExplore}
        handleDelete={handleOpenDeleteDialog}
        onImportData={onImportData}
        datasetsRefreshed={datasetsRefreshed}
      />
    ),
    [datasetsRefreshed, handleExplore, handleOpenDeleteDialog, onImportData]
  );

  const deleteTooltipTitle = useMemo(() => {
    if (selected.length === 0) {
      return datahubTxt.DELETE_DISABLED_NO_SELECT;
    } else if (deleteDataSourcesDisabled) {
      return datahubTxt.DATASET_RECENTLY_INGESTED;
    } else {
      return datahubTxt.DELETE_TITLE;
    }
  }, [deleteDataSourcesDisabled, selected.length]);

  const openInReportsTooltipTitle = useMemo(() => {
    if (selected.length === 0) {
      return datahubTxt.EXPLORE_DISABLED_NO_SELECT;
    } else if (openInReportsDisabled) {
      return datahubTxt.DATASET_REPORT_DATA_UNAVAILABLE;
    } else {
      return datahubTxt.EXPLORE_DATASETS_REPORT;
    }
  }, [openInReportsDisabled, selected.length]);

  if (isEntitled === false) {
    return (
      <NoEntitlement
        {...{
          cards: (
            <CardsComponent
              cards={datahubTxt.NO_ENTITLEMENT_CARDS}
              title={datahubTxt.HOW_DATAHUB_CAN_HELP}
              bgcolor="background.paper"
            />
          ),
          accountManagerMail,
          title: datahubTxt.NO_ENTITLEMENT_TITLE,
          description: datahubTxt.NO_ENTITLEMENT_TEXT_TEXT_BEFORE_LIST,
          listItems: datahubTxt.NO_ENTITLEMENT_LIST_ITEMS,
        }}
      />
    );
  }

  if (!isRefreshLoading && !showImportStepper && (isEntitled === undefined || loading)) {
    return init.current ? <CircularProgressLoader /> : <FilterTableSkeleton />;
  }

  return (
    <Box>
      {showImportStepper ? (
        <ImportCSVStepper
          onClose={() => {
            setShowImportStepper(false);
          }}
          datasetName={currentDatasetName}
        />
      ) : (
        <FilterTable<DatasetItem>
          tableItems={datahubItems}
          showRowsSelection={true}
          onRowsSelected={setSelected}
          rowComponent={DataHubSourceRowWrapper}
          headerColumns={headers}
          filterColumns={filterColumns}
          filterBarPlaceholder={datahubTxt.FILTER_PLACEHOLDER}
          persistenceKey="dataset_tab"
          itemUniqIdentifierField="dataset"
          defaultSortingColumnValue="dataset"
          toolbarProps={{
            title: datahubTxt.TITLE,
            primaryButton: {
              text: datahubTxt.CREATE_DATASET,
              onClick: () => {
                setIsCreateDialogOpen(true);
              },
              variant: "outlined",
            },
            customSlot: (
              <Tooltip title={datahubTxt.REFRESH_DATASET}>
                <Box>
                  <LoadingButton
                    variant="outlined"
                    onClick={handleOnClickRefresh}
                    loading={isRefreshLoading}
                    endIcon={<AutorenewIcon />}
                    mixpanelEventId="datahub.datasets.refresh"
                    sx={{ ml: 0, mr: -1, pl: 0 }}
                  >
                    <Box my={0} mr={0.75} p={0} />
                  </LoadingButton>
                </Box>
              </Tooltip>
            ),
          }}
        >
          <Stack pl={1} pt={1} direction="row" spacing={1}>
            <Tooltip title={deleteTooltipTitle}>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setOpenDeleteDialog(true);
                  }}
                  disabled={selected.length === 0 || deleteDataSourcesDisabled}
                >
                  {datahubTxt.DELETE}
                </Button>
              </Box>
            </Tooltip>

            <Tooltip title={openInReportsTooltipTitle}>
              <Box>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={loadingExplore}
                  onClick={() => handleExplore()}
                  mixpanelEventId="datahub.datasets.explore"
                  disabled={selected.length === 0 || openInReportsDisabled}
                >
                  {datahubTxt.OPEN_IN_REPORTS}
                </LoadingButton>
              </Box>
            </Tooltip>
          </Stack>
        </FilterTable>
      )}
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={datahubTxt.DELETE_TITLE}
          message={datahubTxt.DELETE_CONFIRM}
          onDelete={handleDeleteDataSources}
          onClose={() => {
            setOpenDeleteDialog(false);
          }}
          mixpanelEventId="datahub.datasets.delete"
        />
      )}

      {openImportDataDialog && (
        <ImportDataDialog
          onClose={() => {
            setOpenImportDataDialog(false);
          }}
          onOpenImportStepper={() => {
            setShowImportStepper(true);
          }}
        />
      )}

      {isCreateDialogOpen && (
        <CreateDatahubDialog
          onClose={() => {
            setIsCreateDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default DataHubSources;
