import { useMemo } from "react";

import { Autocomplete, type AutocompleteRenderInputParams, TextField } from "@mui/material";

import { marketplaceText } from "../../../../assets/texts";
import { type CustomerWithRef } from "../hooks/useMarketplaceCustomers";
import { useMarketplaceContext } from "../MarketplaceContext";

type CustomerAutocompleteProps = {
  handleSelectOption: (option: CustomerWithRef | null) => void;
  selectedCustomer: CustomerWithRef | null;
  disabled?: boolean;
  onChange?: () => void;
  optionFilter?: (option: CustomerWithRef) => boolean;
};

export const CustomerAutocomplete = ({
  handleSelectOption,
  selectedCustomer,
  disabled,
  onChange,
  optionFilter,
}: CustomerAutocompleteProps) => {
  const { customersIdsMap } = useMarketplaceContext();

  const customers: CustomerWithRef[] = useMemo(
    () => Array.from(customersIdsMap.values()).filter(optionFilter ?? (() => true)),
    [customersIdsMap, optionFilter]
  );

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      options={customers}
      filterSelectedOptions={true}
      getOptionLabel={(option) => option.data.primaryDomain}
      isOptionEqualToValue={(option, value) => option.ref.id === value.ref.id}
      size="medium"
      onChange={(_, newValue, reason) => {
        switch (reason) {
          case "clear":
            handleSelectOption(null);
            break;
          case "selectOption":
            handleSelectOption(newValue);
            break;
        }
        onChange?.();
      }}
      openOnFocus
      disableClearable={!selectedCustomer?.ref.id}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={marketplaceText.CUSTOMER}
          variant="outlined"
          margin="dense"
          size="medium"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      value={selectedCustomer}
    />
  );
};
