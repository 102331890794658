import { EarlyAccessFeature } from "@doitintl/cmp-models";

import { useFeatureFlag } from "../../Components/hooks/useFeatureFlag";
import { CircularProgressLoader } from "../../Components/Loader";
import { useCustomerContext } from "../../Context/CustomerContext";
import DisableFlexsave from "./AWS/DisableFlexsave";
import {
  useCustomerSavingsPlans,
  useFlexsaveComputeData,
  useFlexsaveRDSData,
  useFlexsaveSageMakerData,
  useIsFlexsaveStandaloneCustomer,
} from "./AWS/hooks";
import Message from "./AWS/Message";
import { OverviewCharts } from "./AWS/OverviewCharts";
import { AccountsTable } from "./Resold/Overview/AWS/AccountsTable/AccountsTable";
import { CustomerSavingsPlansTable } from "./Resold/Overview/AWS/CustomerSavingsPlansTable/CustomerSavingsPlansTable";
import { OverviewChartsPlaceholder } from "./Resold/Overview/Placeholder/OverviewChartsPlaceholder";

export const StandaloneAWS = () => {
  const { customer } = useCustomerContext();
  const isStandalone = useIsFlexsaveStandaloneCustomer();
  const { data: computeData, loading: computeLoading } = useFlexsaveComputeData();
  const { sageMakerData, loading: sageMakerLoading } = useFlexsaveSageMakerData();
  const { rdsData, loading: rdsLoading } = useFlexsaveRDSData();
  const disableFSFeatureFlag = useFeatureFlag(EarlyAccessFeature.FSAWS_DISABLED);
  const customerSavingsPlans = useCustomerSavingsPlans(customer);

  if (!computeData || !customer || computeLoading || sageMakerLoading || rdsLoading) {
    return <CircularProgressLoader />;
  }

  const isDisabled = disableFSFeatureFlag || Boolean(computeData.timeDisabled) || !computeData.enabled;
  const doesNotHaveSavingsHistory = !computeData.savingsHistory || Object.keys(computeData.savingsHistory).length === 0;

  if (isDisabled || computeData.reasonCantEnable || doesNotHaveSavingsHistory) {
    return (
      <>
        <Message
          isDisabled={Boolean(computeData.timeDisabled) || disableFSFeatureFlag}
          reasonCantEnable={computeData.reasonCantEnable}
        />
        <OverviewChartsPlaceholder />
        {!isDisabled && <DisableFlexsave />}
      </>
    );
  }

  return (
    <>
      <OverviewCharts computeData={computeData} rdsData={rdsData} sageMakerData={sageMakerData} />

      <CustomerSavingsPlansTable customerName={customer.name} data={customerSavingsPlans} />

      {isStandalone ? <AccountsTable /> : null}

      <DisableFlexsave />
    </>
  );
};
