import { defaultHeaderStyle } from "../../../Components/FilterTable/Table/FilterTableHeader";
import { type DataColumn, type HeaderColumn, numberComparators, stringComparators } from "../../../types/FilterTable";

const nameColumn = {
  label: "Name",
  path: "name",
  comparators: stringComparators,
} as const;

const sourceColumn = {
  label: "Source",
  path: "source",
  comparators: stringComparators,
} as const;

const impactColumn = {
  label: "Impact",
  path: "impact",
  type: "Number",
  comparators: numberComparators,
} as const;

const assigneeColumn = {
  label: "Assignee",
  path: "assignee",
  comparators: stringComparators,
} as const;

const priorityColumn = {
  label: "Priority",
  path: "priority",
  comparators: stringComparators,
} as const;

const statusColumn = {
  label: "Status",
  path: "status",
  comparators: stringComparators,
} as const;

const sourceKeyColumn = {
  label: "Source key",
  path: "sourceKey",
} as const;

const nameHeader = {
  value: nameColumn.path,
  label: nameColumn.label,
  style: defaultHeaderStyle,
};

const sourceHeader = {
  value: sourceColumn.path,
  label: sourceColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};

const impactHeader = {
  value: impactColumn.path,
  label: impactColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};

const assigneeHeader = {
  value: assigneeColumn.path,
  label: assigneeColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};

const priorityHeader = {
  value: priorityColumn.path,
  label: priorityColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};

const statusHeader = {
  value: statusColumn.path,
  label: statusColumn.label,
  style: defaultHeaderStyle,
  hidden: { mdDown: true },
};

const threeDotsMenuHeader: HeaderColumn = {
  value: "@",
  style: { align: "right", padding: "none" },
  disableSort: true,
};

export const threadsOverviewHeaders: readonly HeaderColumn[] = [
  nameHeader,
  sourceHeader,
  impactHeader,
  assigneeHeader,
  priorityHeader,
  statusHeader,
  threeDotsMenuHeader,
];

export const threadsOverviewColumns: readonly DataColumn[] = [
  nameColumn,
  sourceColumn,
  impactColumn,
  assigneeColumn,
  priorityColumn,
  statusColumn,
  sourceKeyColumn,
];
