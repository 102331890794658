import { CustomerModel, IntegrationModel, KnownIssueModel, ReportOrgMetadataModel } from "@doitintl/cmp-models";
import { getBatch, getCollection, getCollectionGroup, type WithFirebaseModel } from "@doitintl/models-firestore";

import { type KnownIssue, type Product } from "./types";

export const getAllProducts = async (): Promise<Product[]> => {
  const categoriesSnapshots = await getCollection(IntegrationModel)
    .doc("zendesk")
    .collection("ticketFields")
    .doc("categories")
    .get();
  return categoriesSnapshots.asModelData()?.values as Product[];
};

export const addKnownIssueDocument = (knownIssueData: KnownIssue) => getCollection(KnownIssueModel).add(knownIssueData);

const getKnownIssueRefById = async (knownIssueId: KnownIssue["id"]) => getCollection(KnownIssueModel).doc(knownIssueId);

export const updateKnownIssuesDocuments = async (knownIssueData: KnownIssue, knownIssuesIds: KnownIssue["id"][]) => {
  const batch = getBatch();

  const knownIssuesRefs = await Promise.all(knownIssuesIds.map((knownIssueId) => getKnownIssueRefById(knownIssueId)));

  knownIssuesRefs.forEach((knownIssueRef) => {
    batch.update(knownIssueRef, knownIssueData);
  });

  await batch.commit();
};

export const removeKnownIssuesDocuments = async (knownIssuesIds: KnownIssue["id"][]) => {
  const batch = getBatch();

  const knownIssuesRefs = await Promise.all(knownIssuesIds.map((knownIssueId) => getKnownIssueRefById(knownIssueId)));

  knownIssuesRefs.forEach((knownIssueRef) => {
    batch.delete(knownIssueRef);
  });

  await batch.commit();
};

export const canonicalRegion = (region: string) => region.replace(/[^0-9a-zA-Z]/g, "").toLowerCase();
export const reduceDocData = (issues: KnownIssue[], regionCheck: (issue: KnownIssue) => boolean) =>
  issues.reduce((memo, issue) => {
    if (regionCheck(issue)) {
      memo.push(issue);
    }
    return memo;
  }, [] as WithFirebaseModel<KnownIssue>[]);

export const getCustomerRegionsCall = async (customerId: string, cloud: string): Promise<string[]> => {
  const customerModelRef = getCollection(CustomerModel).doc(customerId);
  const customerRootOrgModelRef = customerModelRef.collection("customerOrgs").doc("root");
  const res = await getCollectionGroup(ReportOrgMetadataModel)
    .where("customer", "==", customerModelRef)
    .where("organization", "==", customerRootOrgModelRef)
    .where("key", "==", "region")
    .where("cloud", "==", cloud)
    .get();

  const regions = res.docs.reduce((acc, docSnap) => {
    const data = docSnap.asModelData();
    data.values?.forEach((unstrippedRegion) => {
      const region = canonicalRegion(unstrippedRegion);
      acc.add(region);
    });

    return acc;
  }, new Set<string>());

  return Array.from(regions);
};

export const getCustomerRegions = (customerId: string): Promise<string[]> =>
  getCustomerRegionsCall(customerId, "amazon-web-services");
export const getCustomerLocations = (customerId: string): Promise<string[]> =>
  getCustomerRegionsCall(customerId, "google-cloud");

export const checkRegion = (issue: KnownIssue, regions: string[], locations: string[], isCustomerPage: boolean) => {
  if (!isCustomerPage) {
    return true;
  }

  if (issue.locations) {
    if (issue.locations.length === 0) {
      return true;
    }
    const locationsStripped = issue.locations.map((loca) => {
      // Google locations often (but not always) have a generic prefix before a slash
      loca = loca.split("/").length > 1 ? loca.split("/")[1] : loca;
      return loca.replace(/[^0-9a-zA-Z]/g, "").toLowerCase();
    });

    const intersection = locations.filter((location) => locationsStripped.includes(location));
    return intersection.length !== 0;
  } else if (issue.region) {
    const strippedRegion = issue.region.replace(/[^0-9a-zA-Z]/g, "").toLowerCase();
    return strippedRegion === "global" || regions.includes(strippedRegion);
  }

  return true;
};
