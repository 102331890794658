import { TextField, type TextFieldProps } from "@mui/material";

export type AllowedInput = {
  allowDecimal?: boolean;
  allowEmpty?: boolean;
  allowNegative?: boolean;
  allowScientificNotation?: boolean;
  decimalLimit?: number;
  maxValue?: number;
  minValue?: number;
};

type Props = {
  textFieldProps?: TextFieldProps;
  allowedInputs?: AllowedInput;
  label?: string;
  setValue: (value: string) => void;
  value: string;
};

const NumericTextField = ({ value, setValue, label, allowedInputs, textFieldProps }: Props) => {
  const { allowDecimal, decimalLimit, allowEmpty, allowNegative, allowScientificNotation, minValue, maxValue } = {
    allowDecimal: true,
    allowEmpty: true,
    allowNegative: true,
    allowScientificNotation: true,
    ...allowedInputs,
  };

  const removeExtraDecimals = (base: string) => {
    if (decimalLimit !== undefined) {
      const decimalIndex = base.indexOf(".");
      if (decimalIndex !== -1) {
        // if decimal limit is exceeded, remove the extra decimal places
        if (base.length - decimalIndex > decimalLimit + 1) {
          base = base.substring(0, decimalIndex + decimalLimit + 1);
        }
      }
    }
    return base;
  };

  const isValidScientificNotation = (
    exponent: string,
    base: string,
    exponentNumber: number,
    input: string
  ): boolean => {
    const isExponentInvalid =
      (isNaN(exponentNumber) && exponent !== "-" && exponent !== "+") ||
      exponent.includes(".") ||
      (!allowDecimal && exponent.includes("-")) ||
      input.split("e").length > 2;

    const isBaseInvalid = (base.includes(".") && base.split(".")[1].length === 0) || base.length === 0;

    if (exponent !== undefined && (!allowScientificNotation || isExponentInvalid || isBaseInvalid)) {
      return false;
    }
    return true;
  };

  const setValueInRange = (inputNumber: number, input: string) => {
    if (minValue !== undefined) {
      if (inputNumber < minValue) {
        setValue(minValue.toString());
        return;
      }
    }
    if (maxValue !== undefined) {
      if (inputNumber > maxValue) {
        setValue(maxValue.toString());
        return;
      }
    }
    setValue(input);
  };

  const handleValueChange = (input: string) => {
    if (input === "") {
      if (allowEmpty) {
        setValue("");
      }
      return;
    }

    // remove commas and spaces from input
    input = input.replace(/,|\s/g, "");

    const [base, exponent] = input.split("e");
    const baseNumber = Number(base);
    const exponentNumber = Number(exponent);
    const fullNumber = Number(input);

    if (
      (isNaN(baseNumber) && input !== "-") ||
      (!allowDecimal && base.includes(".")) ||
      (!allowNegative && base.includes("-"))
    ) {
      return;
    }

    if (!isValidScientificNotation(exponent, base, exponentNumber, input)) {
      return;
    }

    const baseNoExtraDecimals = removeExtraDecimals(base);

    input = baseNoExtraDecimals + (exponent !== undefined ? `e${exponent}` : "");

    setValueInRange(fullNumber, input);
  };

  return (
    <TextField
      label={label}
      value={value}
      onChange={(event) => {
        handleValueChange(event.target.value);
      }}
      fullWidth
      {...textFieldProps}
    />
  );
};

export default NumericTextField;
