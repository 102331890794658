import { useEffect } from "react";

import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";

import { type GCPCloudConnectData } from "./useCloudConnectData";

type ServiceAccountSelectorProps = {
  selectedGCPCloudConnectionIndex: number;
  gcpCloudConnectData: GCPCloudConnectData[];
  onSelectionChange: (gcpCloudConnectionIndex: number) => void;
};

const SELECTOR_LABEL_ID = "service-account-selector-label";
const SELECTOR_LABEL = "Service account";
const CONNECT_NEW_ORG_LABEL = "Connect a new organization";

export const ServiceAccountSelector = ({
  gcpCloudConnectData,
  onSelectionChange,
  selectedGCPCloudConnectionIndex,
}: ServiceAccountSelectorProps) => {
  useEffect(() => {
    if (selectedGCPCloudConnectionIndex >= gcpCloudConnectData.length) {
      onSelectionChange(-1);
    }
  }, [gcpCloudConnectData.length, onSelectionChange, selectedGCPCloudConnectionIndex]);

  return (
    <Stack direction="row" spacing={1} mb={3}>
      <FormControl size="small">
        <InputLabel id={SELECTOR_LABEL_ID}>{SELECTOR_LABEL}</InputLabel>
        <Select
          labelId={SELECTOR_LABEL_ID}
          value={selectedGCPCloudConnectionIndex}
          label={SELECTOR_LABEL}
          onChange={({ target: { value } }) => {
            onSelectionChange(value as number);
          }}
        >
          {gcpCloudConnectData.map(({ data: { clientEmail }, id }, index) => (
            <MenuItem key={id} value={index}>
              {clientEmail}
            </MenuItem>
          ))}
          {gcpCloudConnectData.length > 0 && <Divider />}
          <MenuItem value={-1}>{CONNECT_NEW_ORG_LABEL}</MenuItem>
        </Select>
      </FormControl>
      {selectedGCPCloudConnectionIndex !== -1 && (
        <Button
          variant="text"
          onClick={() => {
            onSelectionChange(-1);
          }}
        >
          {CONNECT_NEW_ORG_LABEL}
        </Button>
      )}
    </Stack>
  );
};
