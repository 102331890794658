import { collection, subCollection, type Timestamp } from "@doitintl/models-types";

type KnownIssueStatus = "ongoing" | "archived";

export enum KnownIssuePlatforms {
  AWS = "amazon-web-services",
  GCP = "google-cloud-project",
  GSuite = "g-suite",
  Office365 = "office-365",
  GoogleCloud = "google-cloud",
  OpenAI = "open-ai",
}

export type KnownIssuePlatform = `${KnownIssuePlatforms}`;

@subCollection("slackRecipients")
export class KnownIssueModelSlackRecipientsModel {
  requestId!: string;
}

@collection("knownIssues")
export class KnownIssueModel {
  issueId?: string;

  platform!: KnownIssuePlatform;

  affectedProduct!: string;

  products!: string[];

  region?: string;

  status!: KnownIssueStatus;

  title!: string;

  dateTime!: Timestamp;

  incidentEndDate?: Timestamp;

  outageDescription!: string;

  threadTs?: string;

  nextUpdateTime?: Timestamp;

  workaround?: string;

  locations?: string[];

  summary?: string;

  exposureLevel?: string;

  subCollections?: {
    slackRecipients: KnownIssueModelSlackRecipientsModel;
  };
}

export class GcpKnownIssueModel extends KnownIssueModel {
  declare platform: KnownIssuePlatforms.GCP;

  declare exposureLevel: string;

  declare summary: string;

  symptoms!: string;

  declare workaround: string;
}

export class AwsKnownIssueModel extends KnownIssueModel {
  declare platform: KnownIssuePlatforms.AWS;

  lastUpdatedTime!: Timestamp;

  declare region: string;

  endTime!: Timestamp;

  availabilityZone?: string;
}
