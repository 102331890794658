import { type ReactNode, useMemo, useState } from "react";

import { Link } from "react-router-dom";
import { CurrencyCodes, CustomersColumnsID } from "@doitintl/cmp-models";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

import Hide from "../../Components/HideChildren/Hide";
import { useAuthContext } from "../../Context/AuthContext";
import { formatNumber, getCurrencySymbol } from "../../utils/common";
import AccountManagersWrapper from "./AccountManagersWrapper";
import { getFlexsaveStatus } from "./consts";
import { CustomerRowContent } from "./CustomerRowContent";
import RampPlanBar from "./RampPlanBar";
import TrendWrapper from "./TrendWrapper";
import { type BaseCustomerRowListItem, type Column, Status } from "./types";

export const cyId = {
  row: "row",
  domain: "domain",
  expand: "expandButton",
  thisMo: "thisMonth",
  trend: "trend",
  bql: "bigqueryLens",
  gke: "gkeMetering",
  gkeCostAllocation: "gkeCostAllocation",
  fs: {
    aws: "flexsaveAWS",
  },
  ramp: {
    aws: "awsRampPlanAttainment",
    gcp: "gcpRampPlanAttainment",
  },
  am: "accountManagers",
  ts: "trialStatus",
};

type TableRowProps = {
  row: Readonly<BaseCustomerRowListItem>;
};

type CustomTableCellProps = {
  id: string;
  children: ReactNode;
  columnsConfig: Column[];
  cypressId: string;
};

const CustomTableCell = ({ id, children, columnsConfig, cypressId }: CustomTableCellProps) => {
  if (columnsConfig.find((c) => c.id === id)) {
    const addFixedWidth = columnsConfig.length === 2 && columnsConfig[0].id === id;
    return (
      <TableCell style={{ width: addFixedWidth ? 150 : "auto" }} align="left" data-cy={cypressId}>
        {children}
      </TableCell>
    );
  }
  return null;
};

export const CustomerRow = ({
  row: {
    id,
    primaryDomain,
    thisMonth,
    trend,
    trendDirection,
    enrichment,
    columnsConfig,
    settings,
    accountManagers,
    flexsaveAWS,
    bigqueryLens,
    gkeCostAllocation,
    awsRampPlanAttainment,
    gcpRampPlanAttainment,
    awsContracts,
    gcpContracts,
    trialStatus,
  },
}: TableRowProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { isDoitPartner } = useAuthContext({ mustHaveUser: true });

  const additionalColumns = useMemo(
    () => columnsConfig.filter((c) => (c.customizable && !c.hidden) || c.id === CustomersColumnsID.AccountManagers),
    // Account Managers column can have "customizable" value set both true or false, but should be accessible regardless. depending on the current customer table view (My Customers | My Team's customers)
    [columnsConfig]
  );

  const trendColumnWidth = additionalColumns.length > 2 || additionalColumns.length === 0 ? "auto" : 150;
  const currencyCode = settings?.currency || CurrencyCodes.USD;

  return (
    <>
      <TableRow
        hover
        onClick={() => {
          if (!isDoitPartner) {
            setOpen(!open);
          }
        }}
        key={id}
        data-cy={cyId.row}
      >
        {!isDoitPartner && (
          <Hide smDown>
            <TableCell padding="none" style={{ width: 30, paddingLeft: 10 }} data-cy={cyId.expand}>
              <IconButton
                size="small"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </Hide>
        )}
        <TableCell style={{ width: 230 }} align="left" data-cy={cyId.domain}>
          <Typography component={Link} variant="body2" to={`/customers/${id}`} color="inherit">
            {primaryDomain}
          </Typography>
        </TableCell>

        <TableCell style={{ width: additionalColumns.length > 2 ? "auto" : 150 }} align="left" data-cy={cyId.thisMo}>
          <Typography variant="body2" color="inherit">
            {isNaN(thisMonth ?? Number.NaN) ? "N/A" : `${getCurrencySymbol(currencyCode)}${formatNumber(thisMonth, 2)}`}
          </Typography>
        </TableCell>
        <TableCell style={{ width: trendColumnWidth }} align="left" padding="none" data-cy={cyId.trend}>
          <TrendWrapper
            trendDirection={trendDirection}
            amount={trend ? formatNumber(Math.abs(trend), 1) : "N/A"}
            customerId={id}
            sign={trend ? "%" : ""}
          />
        </TableCell>
        {!isDoitPartner && (
          <>
            <Hide mdDown>
              <CustomTableCell
                id={CustomersColumnsID.AccountManagers}
                columnsConfig={additionalColumns}
                cypressId={cyId.am}
              >
                <AccountManagersWrapper accountManagers={accountManagers ?? []} />
              </CustomTableCell>
              <CustomTableCell
                id={CustomersColumnsID.BigqueryLens}
                columnsConfig={additionalColumns}
                cypressId={cyId.bql}
              >
                {bigqueryLens}
              </CustomTableCell>
              <CustomTableCell
                id={CustomersColumnsID.GkeCostAllocation}
                columnsConfig={additionalColumns}
                cypressId={cyId.gkeCostAllocation}
              >
                {gkeCostAllocation}
              </CustomTableCell>
              <CustomTableCell
                id={CustomersColumnsID.FlexsaveAWS}
                columnsConfig={additionalColumns}
                cypressId={cyId.fs.aws}
              >
                {getFlexsaveStatus(flexsaveAWS)}
              </CustomTableCell>
            </Hide>
          </>
        )}
        <Hide mdDown>
          <CustomTableCell
            id={CustomersColumnsID.AwsRampPlanAttainment}
            columnsConfig={additionalColumns}
            cypressId={cyId.ramp.aws}
          >
            <RampPlanBar
              customerId={id}
              rampPlanAttainment={awsRampPlanAttainment ?? null}
              contracts={awsContracts ?? null}
            />
          </CustomTableCell>
        </Hide>
        <Hide mdDown>
          <CustomTableCell
            id={CustomersColumnsID.GcpRampPlanAttainment}
            columnsConfig={additionalColumns}
            cypressId={cyId.ramp.gcp}
          >
            <RampPlanBar
              customerId={id}
              rampPlanAttainment={gcpRampPlanAttainment ?? null}
              contracts={gcpContracts ?? null}
            />
          </CustomTableCell>
        </Hide>
        <Hide mdDown>
          <CustomTableCell id={CustomersColumnsID.TrialStatus} columnsConfig={additionalColumns} cypressId={cyId.ts}>
            <Tooltip placement="right" arrow title={trialStatus?.date}>
              <span>{trialStatus?.status}</span>
            </Tooltip>
          </CustomTableCell>
        </Hide>
      </TableRow>

      <CustomerRowContent
        customerId={id}
        open={open}
        enrichment={enrichment}
        canHaveAwsFlexsave={!flexsaveAWS?.toString().includes(Status.NotEligible)}
      />
    </>
  );
};
