import { type KnownIssueModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";

export type Product = {
  platforms: string[];
  title: string;
  value: string;
};

export enum KnownIssueStatus {
  Ongoing = "ongoing",
  Archived = "archived",
}

export enum KnownIssueStatusLabel {
  Ongoing = "Active",
  Archived = "Archived",
}

export enum KnownIssuePlatform {
  AWS = "amazon-web-services",
  GCP = "google-cloud-project",
  GSuite = "g-suite",
  Office365 = "office-365",
}

export enum KnownIssuePlatformLabel {
  AWS = "Amazon Web Services",
  GCP = "Google Cloud Platform",
  GSuite = "Google Workspace",
  Office365 = "Office 365",
}

export type KnownIssue = ModelId<KnownIssueModel>;

export type IssueWidget = {
  title: string;
  docId: string;
  platform?: any;
};
