import { useCallback } from "react";

import { type EntityModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";

import { type BillingAddressForm } from "../../types";
import { consoleErrorWithSentry } from "../../utils";
import { useAsyncLoadingFunction } from "../hooks/useAsyncLoadingFunction";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import { usePriorityAPI } from "./BillingForm/hooks";
import { extractErrorMessage } from "./BillingForm/utils";
import { type CreateBillingProfileData } from "./BillingProfileForm.models";

export type IsSubmittingFlag = boolean;
export type CreateBillingProfileFn = (values: CreateBillingProfileData) => Promise<void>;

export function useCreateBillingProfileSubmitFunction(
  onBillingProfileCreate?: (entity: ModelId<EntityModel>) => void
): [IsSubmittingFlag, CreateBillingProfileFn] {
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const priorityApi = usePriorityAPI();

  const onSubmit = useCallback(
    async (formValues: CreateBillingProfileData) => {
      try {
        const entity = await priorityApi.createNewEntity(formValues as BillingAddressForm);

        successSnackbar("New billing profile successfully created");
        onBillingProfileCreate?.(entity);
      } catch (error: unknown) {
        consoleErrorWithSentry(error);
        errorSnackbar(extractErrorMessage(error));
      }
    },
    [priorityApi, successSnackbar, onBillingProfileCreate, errorSnackbar]
  );

  return useAsyncLoadingFunction(onSubmit);
}
