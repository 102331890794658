import { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";

import { usageMeterTxt } from "../../assets/texts/Tiering/usageMeter";
import { useAuthContext } from "../../Context/AuthContext";
import { ContactSalesButton } from "../NoEntitlement/NoEntitlement";
import { UsageMeterBar } from "./UsageMeterBar";

export type UsageMeterProps = {
  value: number;
  limit: number;
  featureName: string;
};

export const UsageMeter = ({ value, limit, featureName }: UsageMeterProps) => {
  const { isDoitEmployee } = useAuthContext();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Tooltip title={isDoitEmployee ? usageMeterTxt.DOIT_EMPLOYEE_TOOLTIP : ""} placement="right">
      <Box width="140px">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography display="inline" variant="subtitle2" color="text.secondary" fontWeight={500}>
            {value}
            <Typography display="inline" variant="body2">
              {" "}
              of{" "}
            </Typography>
            {limit}
          </Typography>
          <Link
            variant="body2"
            onClick={() => {
              setOpen(true);
            }}
            sx={{ cursor: "pointer", textDecoration: "none" }}
          >
            Upgrade
          </Link>
        </Stack>
        <UsageMeterBar value={value} limit={limit} />
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <DialogTitle>Upgrade your plan to create more {featureName}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Your organization has a limit of {limit} {featureName} that can be created with your current plan. This
              limit includes {featureName} created by other users from your organization that may not be visible to you.
            </Typography>
            <Typography variant="body1">Upgrade your plan today to create more {featureName}.</Typography>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <ContactSalesButton />
          </DialogActions>
        </Dialog>
      </Box>
    </Tooltip>
  );
};
