import { type ChangeEvent, type Dispatch, useRef, useState } from "react";

import { type Key } from "@doitintl/cmp-models";
import { Box, Button, Card, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import MuiCircularProgress from "@mui/material/CircularProgress";
import Papa from "papaparse";

import { datahubTxt } from "../../../assets/texts/DataHub/datahub";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { type CSVError, transformCSV, validateCSV } from "../utils";
import ErrorsAlert from "./ErrorsAlert";

const MAX_FILE_SIZE_MB = 100;
const BYTES_IN_MB = 1024 * 1024;

type Label = `label.${string}` | `project_label.${string}`;

type Metric = `metric.${string}`;

type RequiredHeaders = {
  usage_date: string;
};

type OptionalHeaders = {
  event_id?: string;
};

export type CSVDataType = RequiredHeaders &
  OptionalHeaders & {
    [K in Key | Label | Metric]?: string;
  };

type Props = {
  datasetName: string;
  handleCSVData: (data: CSVDataType[], fileName: string) => void;
  errors: CSVError[];
  setErrors: Dispatch<CSVError[]>;
  fileName: string;
  setFileName: Dispatch<string>;
  onUploading: (isLoading: boolean) => void;
};

const ImportCSV = ({ datasetName, handleCSVData, errors, setErrors, fileName, setFileName, onUploading }: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const successSnackbar = useSuccessSnackbar();
  const [loadingFile, setLoadingFile] = useState(false);

  const handleLoading = (isLoading: boolean) => {
    onUploading(isLoading);
    setLoadingFile(isLoading);
  };

  const handleBatchUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const filesList = event.target.files;

    if (!filesList) {
      return;
    }

    const files = Array.from(filesList);

    if (files.length !== 1) {
      return;
    }

    const file = files[0];
    const fileSizeInMb = file.size / BYTES_IN_MB;

    if (file.type !== "text/csv" && !file.name.endsWith(".csv")) {
      setErrors([datahubTxt.INVALID_FILE_TYPE]);
      return;
    }

    setFileName(file.name);
    event.target.value = "";

    if (fileSizeInMb > MAX_FILE_SIZE_MB) {
      setErrors([datahubTxt.FILE_SIZE_EXCEEDED]);
      return;
    }

    handleLoading(true);

    Papa.parse<any>(file, {
      header: true,
      worker: true,
      complete: (results) => {
        try {
          transformCSV(results);

          validateCSV(results);
          handleCSVData(results.data, file.name);
          successSnackbar(datahubTxt.CSV_UPLOADED_SUCCESSFULLY);
          setErrors([]);
          handleLoading(false);
        } catch (error: any) {
          setErrors(error);
          handleLoading(false);
        }
      },
    });
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box>
      <Typography sx={{ py: 6 }} variant="h1">
        {datahubTxt.UPLOAD_CSV}
      </Typography>
      <Stack gap={3}>
        <Typography variant="body1" color="text.primary">
          {datahubTxt.UPLOAD_CSV_DESC_1}
          <br />
          {datahubTxt.UPLOAD_CSV_DESC_2}
        </Typography>
        <Card sx={{ bgcolor: "general.backgroundDark", p: 2 }}>
          <Stack direction="row" gap={0.5}>
            <Typography variant="subtitle1" fontWeight={500}>
              {datahubTxt.DATASET}:
            </Typography>
            <Typography variant="body1">{datasetName}</Typography>
          </Stack>
        </Card>

        <Typography fontWeight={500}>{datahubTxt.SELECT_FILE}</Typography>

        <input type="file" accept=".csv" ref={fileInputRef} style={{ display: "none" }} onChange={handleBatchUpload} />

        <FormControl>
          <Stack direction="row" gap={1} width="100%">
            <TextField value={fileName} label={datahubTxt.CSV_FILE} fullWidth />
            <Button variant="contained" color="primary" onClick={handleButtonClick} disabled={loadingFile}>
              {datahubTxt.BROWSE}
            </Button>
          </Stack>
          <FormHelperText>{`Maximum file size: ${MAX_FILE_SIZE_MB}MB`}</FormHelperText>
        </FormControl>
        {loadingFile && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "25vh" }}>
            <MuiCircularProgress />
          </Box>
        )}
        <ErrorsAlert errors={errors} />
      </Stack>
    </Box>
  );
};

export default ImportCSV;
