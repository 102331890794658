import { useRef, useState } from "react";

import { useHistory } from "react-router";
import { type CurrencyCode, type EntityModel, type PaymentMethod } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { useCreateBillingProfileSubmitFunction } from "../../Components/CreateBillingProfile/useCreateBillingProfileSubmitFunction";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useErrorSnackbar, useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { handlePaymentMethodUpdate } from "../Entity/PaymentMethods/paymentHelpers";
import { type StripeFromHandle } from "../Entity/PaymentMethods/Stripe";
import { updateTierBillingConfig } from "../Subscription/db";
import { BILLING_INFO_STEP, CONTRACT_STEP, PACKAGE_TYPE_STEP, PAYMENT_METHOD_STEP, SERVICE_TYPE_STEP } from "./const";
import { BillingInfoStep } from "./Steps/BillingInfoStep";
import { ContractStep } from "./Steps/ContractStep";
import { PackageTypeStep } from "./Steps/PackageTypeStep";
import { PaymentMethodStep } from "./Steps/PaymentMethodStep";
import { ServiceTypeStep } from "./Steps/ServiceTypeStep";
import { type PackageType, type ServiceType, type SolveOnboardingStep } from "./types";
import { getCurrentStepIndex } from "./utils";

export const usePaymentStep = (entityId, paymentMethod) => {
  const stripeRef = useRef<StripeFromHandle>(null);
  const { customer } = useCustomerContext();
  const [settingPaymentMethod, setSettingPaymentMethod] = useState(false);
  const showErrorSnackbar = useErrorSnackbar();
  const api = useApiContext();

  const handleSetPayment = async () => {
    setSettingPaymentMethod(true);
    try {
      await handlePaymentMethodUpdate(stripeRef, api, customer, entityId, paymentMethod);
      await updateTierBillingConfig(customer.id, true);
    } catch (error) {
      showErrorSnackbar("Your billing profile was not created. Please try again.");
    } finally {
      setSettingPaymentMethod(false);
    }
  };
  return { handleSetPayment, settingPaymentMethod, stripeRef };
};

const useNextAndBack = (
  currentStep: SolveOnboardingStep,
  setCurrentStep: (step: SolveOnboardingStep) => void,
  hasBillingInfo: boolean | undefined,
  serviceType: ServiceType,
  packageType: PackageType
) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const api = useApiContext();
  const customerId = useCustomerId();
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const next = async () => {
    switch (currentStep) {
      case SERVICE_TYPE_STEP:
        setCurrentStep(PACKAGE_TYPE_STEP);
        break;
      case PACKAGE_TYPE_STEP:
        setCurrentStep(CONTRACT_STEP);
        break;
      case CONTRACT_STEP: {
        if (hasBillingInfo) {
          history.push("./support");
          snackbar.onOpen({
            message: "You have successfully unlocked DoiT's expert guidance",
            variant: "success",
            withClose: true,
            autoHideDuration: 5000,
          });
          await api.patch(`v1/customers/${customerId}/customerTier`, {
            serviceType,
            packageType,
            email: currentUser.email,
          });
        } else {
          setCurrentStep(BILLING_INFO_STEP);
        }
        break;
      }
      case BILLING_INFO_STEP:
        setCurrentStep(PAYMENT_METHOD_STEP);
        break;
    }
  };

  const back = () => {
    switch (currentStep) {
      case SERVICE_TYPE_STEP:
        history.goBack();
        break;
      case PACKAGE_TYPE_STEP:
        setCurrentStep(SERVICE_TYPE_STEP);
        break;
      case CONTRACT_STEP:
        setCurrentStep(PACKAGE_TYPE_STEP);
        break;
      case BILLING_INFO_STEP:
        setCurrentStep(CONTRACT_STEP);
        break;
      case PAYMENT_METHOD_STEP: {
        if (hasBillingInfo) {
          setCurrentStep(CONTRACT_STEP);
        } else {
          setCurrentStep(BILLING_INFO_STEP);
        }
        break;
      }
    }
  };
  return { next, back };
};

export const useHasBillingProfile = () => {
  const { entities, entitiesLoading } = useCustomerContext();
  if (entitiesLoading) {
    return;
  }

  return entities.length > 0;
};

export const useMultistep = () => {
  const initialStep = SERVICE_TYPE_STEP;

  const [currentStep, setCurrentStep] = useState<SolveOnboardingStep>(initialStep);
  const [selectedServiceType, setSelectedServiceType] = useState<ServiceType>("subscription");
  const [selectedPackageType, setSelectedPackageType] = useState<PackageType>("standard");

  const [entityId, setEntityId] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState<CurrencyCode>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("wire_transfer");
  const [stripeElementsLoaded, setStripeElementsLoaded] = useState(false);

  const [contractAgreed, setContractAgreed] = useState(false);

  const stepsTitles = ["Select service type", "Select package type"];

  const hasBillingProfile = useHasBillingProfile();

  const { handleSetPayment, settingPaymentMethod, stripeRef } = usePaymentStep(entityId, paymentMethod);

  const steps = {
    SERVICE_TYPE_STEP: (
      <ServiceTypeStep selectedServiceType={selectedServiceType} setSelectedServiceType={setSelectedServiceType} />
    ),
    PACKAGE_TYPE_STEP: (
      <PackageTypeStep
        selectedPackageType={selectedPackageType}
        setSelectedPackageType={setSelectedPackageType}
        selectedServiceType={selectedServiceType}
      />
    ),
    CONTRACT_STEP: (
      <ContractStep
        packageType={selectedPackageType}
        setContractAgreed={setContractAgreed}
        contractAgreed={contractAgreed}
      />
    ),
  };

  const onBillingProfileCreate = (entity: ModelId<EntityModel>) => {
    if (!entity.id || !entity.country || !entity.currency) {
      throw new Error("Entity missing data");
    }
    setEntityId(entity.id);
    setCountry(entity.country);
    setCurrency(entity.currency);
    if (entity.currency === "EGP") {
      setPaymentMethod("stripe");
    }
    setCurrentStep(PAYMENT_METHOD_STEP);
  };

  const [isSubmitting, createBillingProfile] = useCreateBillingProfileSubmitFunction(onBillingProfileCreate);

  // if has no billing profile we add steps to create it
  if (!hasBillingProfile) {
    const billingTitles = ["Contract", "Billing profile", "Payment method"];
    stepsTitles.push(...billingTitles);
    steps[BILLING_INFO_STEP] = <BillingInfoStep onSubmit={createBillingProfile} />;
    steps[PAYMENT_METHOD_STEP] = (
      <PaymentMethodStep
        country={country}
        entityId={entityId}
        currency={currency ?? "USD"}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        setStripeElementsLoaded={setStripeElementsLoaded}
        handleSetPayment={handleSetPayment}
        settingPaymentMethod={settingPaymentMethod}
        stripeRef={stripeRef}
      />
    );
  } else {
    stepsTitles.push("Contract and confirmation");
  }

  let allowNext = true;
  switch (currentStep) {
    case PAYMENT_METHOD_STEP:
      allowNext = !((paymentMethod === "stripe" && !stripeElementsLoaded) || ["bacs", "acss"].includes(paymentMethod));
      break;
    case CONTRACT_STEP:
      allowNext = contractAgreed;
      break;
  }

  const { next, back } = useNextAndBack(
    currentStep,
    setCurrentStep,
    hasBillingProfile,
    selectedServiceType,
    selectedPackageType
  );

  let formId = "";
  if (currentStep === PAYMENT_METHOD_STEP) {
    formId = "paymentForm";
  } else if (currentStep === BILLING_INFO_STEP) {
    formId = "billingForm";
  }

  return {
    activeIndex: getCurrentStepIndex(currentStep, hasBillingProfile ?? false),
    stepsTitles,
    step: steps[currentStep],
    isLastStep: hasBillingProfile ? currentStep === CONTRACT_STEP : currentStep === PAYMENT_METHOD_STEP,
    next,
    back,
    allowNext,
    loading: isSubmitting || settingPaymentMethod,
    formId,
  };
};
