import { type AxiosInstance } from "axios";

import mixpanel from "../../../utils/mixpanel";

export async function patchGCBillingAccountAdminMembers({
  api,
  emailsToAdd,
  mixpanelAddDisplayName,
  membersToRemove,
  mixpanelRemoveDisplayName,
  customerId,
  entityId,
  billingAccountId,
}: {
  api: AxiosInstance;
  emailsToAdd?: string[];
  mixpanelAddDisplayName?: string;
  membersToRemove?: string[];
  mixpanelRemoveDisplayName?: string;
  customerId: string;
  entityId: string;
  billingAccountId: string;
}) {
  const data: { add_members?: string[]; remove_members?: string[] } = {};

  if (emailsToAdd && emailsToAdd.length > 0) {
    data.add_members = emailsToAdd;
  }
  if (membersToRemove && membersToRemove.length > 0) {
    data.remove_members = membersToRemove;
  }

  const retVal = await api.request({
    method: "patch",
    url: `/v1/customers/${customerId}/entities/${entityId}/google-cloud/${billingAccountId}`,
    data,
  });

  if (data.add_members) {
    mixpanel.track("assets.google-cloud.billingaccount.admins.add", {
      id: billingAccountId,
      displayName: mixpanelAddDisplayName,
      members: emailsToAdd,
    });
  }

  if (data.remove_members) {
    for (const elem of data.remove_members) {
      mixpanel.track("assets.google-cloud.billingaccount.admins.remove", {
        id: billingAccountId,
        displayName: mixpanelRemoveDisplayName,
        target: elem,
      });
    }
  }

  return retVal;
}
