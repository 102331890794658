import { useCallback, useMemo, useRef } from "react";

export const useInterval = () => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const setIntervalRef = useCallback((handler, timeout) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(handler, timeout);
  }, []);

  const clearIntervalRef = useCallback(() => {
    if (!intervalRef.current) {
      return;
    }
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  }, []);

  return useMemo(
    () => ({ setInterval: setIntervalRef, clearInterval: clearIntervalRef }),
    [clearIntervalRef, setIntervalRef]
  );
};
