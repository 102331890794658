import { createContext, type ReactNode, useCallback, useContext, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { signInWithCustomToken as firebaseSignInWithCustomToken } from "firebase/auth";
import noop from "lodash/noop";
import { DateTime } from "luxon";

import { useApiContext } from "../api/context";
import { paths } from "../Pages/Login/helpers";
import { consoleErrorWithSentry } from "../utils";
import { auth } from "../utils/firebase";
import { useAuthContext } from "./AuthContext";

type IsActive =
  | {
      isActive: true;
      authTime: DateTime;
      email: string;
    }
  | {
      isActive: false;
      authTime: undefined;
      email: undefined;
    };

type ImpersonateContextProps = {
  handleImpersonateUser: (userId: string, tenantId: string | null) => Promise<boolean>;
  handleStopImpersonate: () => Promise<void>;
} & IsActive;

const impersonateContext = createContext<ImpersonateContextProps>({
  handleImpersonateUser: noop as any,
  handleStopImpersonate: noop as any,
  isActive: false,
  authTime: undefined,
  email: undefined,
});

export const useImpersonateContext = () => useContext(impersonateContext);

export const ImpersonateContextProvider = ({ children }: { children?: ReactNode }) => {
  const api = useApiContext();
  const history = useHistory();
  const { impersonate, authTime, currentUser } = useAuthContext();

  const handleImpersonateUser = useCallback(
    async (userId: string, tenantId: string | null) => {
      try {
        const response = await api.request({
          method: "post",
          url: `/v1/users/impersonate/start`,
          data: {
            userId,
            tenantId,
          },
        });

        if (response.status === 200 && response.data) {
          await auth.signOut();
          history.push(paths.loginImpersonate);
          auth.tenantId = tenantId;
          await firebaseSignInWithCustomToken(auth, response.data);
          window.location.reload();
          return true;
        }

        return false;
      } catch (error) {
        consoleErrorWithSentry(error);
        return false;
      }
    },
    [api, history]
  );

  const handleStopImpersonate = useCallback(async () => {
    try {
      const response = await api.request({
        method: "get",
        url: `/v1/users/impersonate/stop`,
      });
      await auth.signOut();
      if (response.status === 200) {
        const { token: impersonateToken, requesterTenantId } = response.data;
        if (impersonateToken) {
          history.push(paths.loginImpersonate);
          auth.tenantId = requesterTenantId;
          await firebaseSignInWithCustomToken(auth, impersonateToken);
        }
      }
    } catch (error) {
      await auth.signOut();
    }
  }, [api, history]);

  const isActiveProps = useMemo((): IsActive => {
    if (!impersonate?.active || !authTime || !currentUser?.email) {
      return { isActive: false, authTime: undefined, email: undefined };
    }

    const authTimeDT = DateTime.fromSeconds(authTime);
    return { isActive: true, authTime: authTimeDT, email: currentUser.email };
  }, [authTime, impersonate, currentUser]);

  const value = useMemo(
    () => ({ ...isActiveProps, handleImpersonateUser, handleStopImpersonate }),
    [handleImpersonateUser, handleStopImpersonate, isActiveProps]
  );

  return <impersonateContext.Provider value={value}>{children}</impersonateContext.Provider>;
};
