import { type EntityInvoicesModelRow } from "@doitintl/cmp-models";
import { TableCell } from "@mui/material";

import { formatCurrency } from "../../../../../utils/common";

const InvoiceViewRow = ({ row }: { row: EntityInvoicesModelRow }) => (
  <>
    <TableCell>{row.SKU}</TableCell>
    <TableCell>{row.details}</TableCell>
    <TableCell>{row.rank}</TableCell>
    <TableCell>{row.discount}%</TableCell>
    <TableCell>{formatCurrency(row.unitPrice, row.currency)}</TableCell>
    <TableCell>{row.quantity}</TableCell>
    <TableCell>{formatCurrency(row.total, row.currency)}</TableCell>
  </>
);
export default InvoiceViewRow;
