import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import { UserPermissions } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";

import DeleteDialog from "../../../Components/DeleteDialog";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { Loader } from "../../../Components/Loader";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { filters as customerFilters, headerColumns as customerHeaderColumns } from "./customerColumns";
import { CustomerNotificationRow } from "./CustomerNotificationRow";
import { useDeleteCustomerNotification, useDeleteUserNotification } from "./db";
import { useCustomerNotificationSettings, useNotificationsData, useUserNotificationSettings } from "./hooks";
import { type TableNotification } from "./types";
import UserNotificationsTable from "./UserNotificationsTable";
import { parseNotifications } from "./utils";

export const NotificationSettings = () => {
  const { userNotifications, userNotificationsLoading } = useUserNotificationSettings();
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [isCustomerNotificationDelete, setIsCustomerNotificationDelete] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState<string | undefined>(undefined);
  const { customer } = useCustomerContext();
  const history = useHistory();
  const deleteCustomerNotification = useDeleteCustomerNotification();
  const deleteUserNotification = useDeleteUserNotification();

  const customerId = useCustomerId();
  const { userId } = useAuthContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const hasManageSettingsPermission = userRoles.permissions?.has(UserPermissions.Settings);
  const [customerNotifications, loadingCustomer] = useCustomerNotificationSettings(hasManageSettingsPermission);

  const notificationsData = useNotificationsData();

  const userNotificationsData = parseNotifications(userNotifications, notificationsData);
  const customerNotificationsData = parseNotifications(customerNotifications, notificationsData);

  const handleDeleteNotification = useCallback(
    () =>
      isCustomerNotificationDelete
        ? deleteCustomerNotification(customerId, notificationToDelete)
        : deleteUserNotification(userId, notificationToDelete),
    [
      isCustomerNotificationDelete,
      deleteCustomerNotification,
      customerId,
      notificationToDelete,
      deleteUserNotification,
      userId,
    ]
  );

  const handleNotificationClicked = (notificationId: string, isCustomer: boolean) => {
    setNotificationToDelete(notificationId);
    setConfirmDeleteDialogOpen(true);
    setIsCustomerNotificationDelete(isCustomer);
  };

  const RowCustomerWrapper = useCallback(
    ({ row }: { row: TableNotification }) => (
      <CustomerNotificationRow
        row={row}
        onRemoveNotificationClicked={handleNotificationClicked}
        viewOnly={!hasManageSettingsPermission}
      />
    ),
    [hasManageSettingsPermission]
  );

  return (
    <Container sx={{ pt: 2, pb: 8 }}>
      <DeleteDialog
        open={confirmDeleteDialogOpen}
        title="Remove notification"
        message="Are you sure you want to remove the notification?"
        deleteButtonText="Remove"
        onDelete={handleDeleteNotification}
        onClose={() => {
          setConfirmDeleteDialogOpen(false);
        }}
      />

      <Stack spacing={5}>
        <Typography variant="h1" pl={1}>
          Notifications
        </Typography>
        {!userNotificationsLoading && <UserNotificationsTable data={userNotificationsData} />}

        <Loader loading={loadingCustomer}>
          <FilterTable<TableNotification>
            toolbarProps={{
              title: <Typography variant="h4">{customer.name} notifications</Typography>,
              primaryButton: hasManageSettingsPermission
                ? {
                    text: "Create new notification",
                    onClick: () => {
                      history.push(`/customers/${customerId}/company/notifications/create`);
                    },
                  }
                : undefined,
            }}
            rowComponent={RowCustomerWrapper}
            headerColumns={customerHeaderColumns}
            filterColumns={customerFilters}
            tableItems={customerNotificationsData}
            fillEmptySpace={false}
          />
        </Loader>
      </Stack>
    </Container>
  );
};
