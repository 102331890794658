import { useEffect, useMemo, useState } from "react";

import { type CustomersColumnId, CustomersColumnsID } from "@doitintl/cmp-models";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { createStyles, makeStyles } from "@mui/styles";

import { customizeTableViewTexts } from "../../assets/texts";
import PopoverDialog from "../../Components/PopoverDialog";
import { type Column } from "./types";

type CustomizeTableViewProps = {
  openEl: HTMLButtonElement | null;
  onClose: () => void;
  handleCustomizeConfirm: (c: CustomersColumnId[]) => Promise<void>;
  userColumnsConfig?: Column[];
  columnsConfig?: Column[];
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    listItem: {
      border: "1px solid #e0e0e0",
      borderRadius: 8,
      marginTop: 6,
    },
  })
);

export const CustomizeTableViewDialog = ({
  openEl,
  onClose,
  handleCustomizeConfirm,
  userColumnsConfig,
  columnsConfig,
}: CustomizeTableViewProps) => {
  const classes = useStyles();
  const [checked, setChecked] = useState<CustomersColumnId[]>([]);

  useEffect(() => {
    if (userColumnsConfig && openEl) {
      setChecked(userColumnsConfig?.map((c) => c.id));
    }
  }, [openEl, userColumnsConfig]);

  const handleToggle = (value: CustomersColumnId) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      if (value === CustomersColumnsID.AwsRampPlanAttainment) {
        newChecked.push(CustomersColumnsID.AwsRampPlanStatus, CustomersColumnsID.AwsContracts);
      }
      if (value === CustomersColumnsID.GcpRampPlanAttainment) {
        newChecked.push(CustomersColumnsID.GcpRampPlanStatus, CustomersColumnsID.GcpContracts);
      }
    } else {
      if (
        (
          [CustomersColumnsID.AwsRampPlanAttainment, CustomersColumnsID.GcpRampPlanAttainment] as CustomersColumnId[]
        ).includes(value)
      ) {
        newChecked.splice(currentIndex, 3);
      } else {
        newChecked.splice(currentIndex, 1);
      }
    }

    // sort by columnsConfig
    newChecked.sort((a, b) => {
      const aIndex = columnsConfig?.findIndex((c) => c.id === a) || 0;
      const bIndex = columnsConfig?.findIndex((c) => c.id === b) || 0;
      return aIndex - bIndex;
    });

    setChecked(newChecked);
  };
  const onCloseDialog = () => {
    onClose();
    setTimeout(() => {
      setChecked([]);
    }, 300);
  };

  const onUpdateColumns = () => {
    onCloseDialog();
    handleCustomizeConfirm(checked);
  };

  const disableUpdateButton = useMemo(
    () => JSON.stringify(checked) === JSON.stringify(userColumnsConfig?.map((c) => c.id)),
    [checked, userColumnsConfig]
  );

  return (
    <PopoverDialog
      anchorEl={openEl}
      onClose={onCloseDialog}
      onConfirm={onUpdateColumns}
      disabled={disableUpdateButton}
      buttonText={customizeTableViewTexts.UPDATE}
    >
      <List data-testid="customize-table-view-list" className={classes.root}>
        {columnsConfig
          ?.filter((c) => !c?.hidden && c.id && c?.customizable)
          ?.map((column, index) => {
            const labelId = `checkbox-list-label-${index}`;
            return (
              <ListItem key={column.id} role={undefined} dense button onClick={handleToggle(column.id)}>
                <Checkbox
                  edge="start"
                  checked={checked.includes(column.id)}
                  tabIndex={-1}
                  color="primary"
                  disableRipple
                  inputProps={{
                    // @ts-expect-error
                    "data-testid": `customize-table-view-list-item-${column.id}`,
                  }}
                />
                <ListItemText id={labelId} primary={column.title} />
              </ListItem>
            );
          })}
      </List>
    </PopoverDialog>
  );
};
