import { useCustomerRouteNavigation } from "../../Components/hooks/useCustomerRouteNavigation";
import { useCustomerContext } from "../../Context/CustomerContext";
import withDashboardProviders from "../../Context/withDashboardProviders";
import { CustomerDashboards } from "./CustomerDashboards";

const CustomerViewForPartner = () => {
  const { customer } = useCustomerContext({ allowNull: true });
  const navigateToCustomerRoute = useCustomerRouteNavigation();

  if (customer?.partnerOfRecord && !customer?.enabledFlexsave?.GCP) {
    navigateToCustomerRoute("flexsave-gcp");
    return <></>;
  }

  return <CustomerDashboards />;
};

export default withDashboardProviders(CustomerViewForPartner);
