import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import * as yup from "yup";

import { useApiContext } from "../../../api/context";
import { globalText, googleCloudBillingAccountAdminTexts } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { chipSeparators, MultiEmailsInputText } from "../../../Components/MultiEmailsInputText";
import EntitySelect from "../../../Components/Selects/EntitySelect";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { autoHideDuration } from "../../../constants";
import { googleAccountNameRegex } from "../../../constants/account";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { preventEnterEvent } from "../../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { useIsThereSignedContract } from "../hooks";
import { type ValidatedEmailType } from "../types";
import { constructPathToManageDomains, validateEmails } from "./utils";

type Values = {
  entity: string;
  name: string;
  admins: string[];
  validatedEmails: ValidatedEmailType;
};

function getErrors(values: Values) {
  let errors: object | undefined = undefined;
  const emailsWithError = Object.keys(values.validatedEmails).filter((email) => values.validatedEmails[email].error);
  if (emailsWithError.length > 0) {
    errors = {
      validatedEmails: emailsWithError.join(", "),
    };
  }
  return errors;
}

const useStyles = makeStyles((theme) => ({
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  spacer: {
    flex: "1 1 100%",
  },
  startAdornment: {
    paddingTop: theme.spacing(0.25),
  },
  endAdornment: {
    flex: "1 1 50%",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(0.25),
  },
}));

const schema = yup.object().shape({
  entity: yup.string().required().label("Paying entity"),
  name: yup
    .string()
    .strict()
    .min(0)
    .max(24)
    .lowercase()
    .matches(googleAccountNameRegex, { message: "Invalid name", excludeEmptyString: true }),
  admins: yup
    .array()
    .of(yup.string().email(({ value }) => `'${value}' is not a valid email address`))
    .min(0),
});

const CreateGoogleCloudAsset = ({ onClose, entities }) => {
  const classes = useStyles();
  const api = useApiContext();
  const history = useHistory();
  const { customer } = useCustomerContext();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const [newEmailValue, setNewEmailValue] = useState("");
  const [selectedEntity, setSelectedEntity] = useState(entities.length > 0 ? entities[0].id : "");
  const { isMobile: matches } = useFullScreen("sm");

  const { isLoadingContracts, isThereSignedContract } = useIsThereSignedContract({
    entityId: selectedEntity,
    typeFilter: ["google-cloud", "looker", "google-geolocation-services"],
  });

  const handleEmailValueChange = useCallback(
    (email, values, setFieldValue) => {
      setNewEmailValue(email);
      if (!email.match(new RegExp(chipSeparators.join("|"))) && email !== "") {
        setFieldValue(
          "validatedEmails",
          validateEmails({
            emails: [...values.admins, email],
            isThereSignedContract,
            customerDomains: customer.domains,
          })
        );
      }
    },
    [customer.domains, isThereSignedContract]
  );

  if (!customer) {
    return null;
  }

  const adornments = (values) =>
    values.name !== ""
      ? {
          startAdornment: (
            <InputAdornment position="start" classes={{ root: classes.startAdornment }}>
              doit.
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" classes={{ root: classes.endAdornment }}>
              {`.${customer.primaryDomain}`}
            </InputAdornment>
          ),
        }
      : {};

  return (
    <Formik
      initialValues={{
        entity: entities.length > 0 ? entities[0].id : "",
        name: "",
        admins: [],
        validatedEmails: {},
      }}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={schema}
      validate={getErrors}
      onSubmit={async (values: Values, { resetForm, setSubmitting }) => {
        try {
          const response = await api.request({
            method: "post",
            url: `/v1/customers/${customer.id}/entities/${values.entity}/google-cloud`,
            data: {
              name: values.name,
              admins: values.admins,
            },
          });

          mixpanel.track("assets.google-cloud.billingaccount.new", response.data);

          resetForm();
          onClose();
        } catch (error: any) {
          if (error.response) {
            showSnackbar({
              message: error.response.data?.error || "Billing account creation failed",
              variant: "error",
              autoHideDuration,
              action: [
                <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
                  <CloseIcon />
                </IconButton>,
              ],
            });
          }
        }

        setSubmitting(false);
      }}
    >
      {({ isValid, isSubmitting, values, touched, errors, handleChange, handleBlur, setFieldValue, handleSubmit }) => (
        <Dialog
          open={true}
          aria-labelledby="credit-google-cloud-asset-title"
          onClose={preventOnCloseWhile(isSubmitting, onClose)}
          fullScreen={matches}
          fullWidth
          maxWidth="sm"
          data-cy="gcCreateDialog"
        >
          <DialogTitle id="credit-google-cloud-asset-title" data-cy="dialogTitle">
            Create Google Cloud billing account
          </DialogTitle>
          <form
            onKeyDown={preventEnterEvent}
            className={classes.dialogForm}
            onSubmit={handleSubmit}
            data-cy="gcCreateAssetDialog"
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Google billing account name"
                    variant="outlined"
                    placeholder={values.name === "" ? `doit.${customer.primaryDomain}` : undefined}
                    disabled={isSubmitting}
                    helperText={(touched.name && errors.name) || "Optional"}
                    error={touched.name && Boolean(errors.name)}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputProps={{
                      inputProps: {
                        maxLength: 24,
                        "data-cy": "billingAccountInput",
                      },
                      ...adornments(values),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                {entities.length !== 1 && (
                  <Grid item xs={12}>
                    <EntitySelect
                      name="entity"
                      label="Billing profile"
                      value={values.entity}
                      onChange={(e) => {
                        setSelectedEntity(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      helperText={(touched.entity && errors.entity) || "Select the invoiced billing profile"}
                      error={touched.entity && Boolean(errors.entity)}
                      fullWidth
                      data-cy="entitySelect"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MultiEmailsInputText
                    inputLabel="Billing account administrators"
                    emails={values.admins}
                    validatedEmails={values.validatedEmails}
                    onEmailsChange={(newEmails) => {
                      setFieldValue("admins", newEmails);
                      setFieldValue(
                        "validatedEmails",
                        validateEmails({
                          emails: newEmails,
                          isThereSignedContract,
                          customerDomains: customer.domains,
                        })
                      );
                    }}
                    emailValue={newEmailValue}
                    onEmailValueChange={(email) => {
                      handleEmailValueChange(email, values, setFieldValue);
                    }}
                    invalidEmailsHelperComponent={
                      isThereSignedContract ? (
                        <span>
                          <Typography display="inline" variant="caption">
                            {googleCloudBillingAccountAdminTexts.CONTRACT_SIGNED_ADD_ADMIN_ERR}
                          </Typography>
                          <Link
                            underline="always"
                            sx={{ cursor: "pointer" }}
                            color="error.main"
                            onClick={() => {
                              history.push(constructPathToManageDomains(customer.ref.id));
                            }}
                          >
                            {googleCloudBillingAccountAdminTexts.MANAGE_DOMAINS}
                          </Link>
                        </span>
                      ) : (
                        <span>{googleCloudBillingAccountAdminTexts.CONTRACT_NOT_SIGNED_ADD_ADMIN_ERR}</span>
                      )
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="text" onClick={onClose} disabled={isSubmitting} data-cy="cancelBtn">
                {globalText.CANCEL}
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                type="submit"
                loading={isSubmitting || isLoadingContracts}
                disabled={isSubmitting || !isValid}
                data-cy="createBtn"
                mixpanelEventId="assets.gc-asset.create"
              >
                {globalText.CREATE}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateGoogleCloudAsset;
