import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { DialogContent, Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { templateLibraryTxt } from "../../../assets/texts/CloudAnalytics";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { useReportTemplateContext } from "../Context";
import { deleteReportTemplate } from "./api";

type Props = {
  open: boolean;
  onClose: () => void;
};

const DeleteDialog = ({ open, onClose }: Props) => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { reportTemplateId, dispatchReportTemplates } = useReportTemplateContext();

  const onConfirm = useCallback(async () => {
    try {
      await deleteReportTemplate(api, reportTemplateId);
      dispatchReportTemplates({ type: "DELETE_REPORT_TEMPLATE", payload: { reportTemplateId } });
      successSnackbar(templateLibraryTxt.TEMPLATE_DELETED_SUCCESSFULLY);
      onClose();
      history.push(`/customers/${customer.id}/analytics/report-templates`);
    } catch (error) {
      errorSnackbar(templateLibraryTxt.TEMPLATE_DELETE_ERROR);
      onClose();
      consoleErrorWithSentry(error);
    }
  }, [api, customer.id, dispatchReportTemplates, errorSnackbar, history, onClose, reportTemplateId, successSnackbar]);

  return (
    <SimpleDialog
      open={open}
      title={templateLibraryTxt.DELETE_TEMPLATE}
      onConfirm={onConfirm}
      onCancel={onClose}
      confirmButtonText={templateLibraryTxt.DELETE_TEMPLATE}
      confirmButtonColor="error"
      dialogProps={{
        fullWidth: true,
        maxWidth: "xs",
      }}
      titleCy="delete-template-dialog-title"
      confirmButtonCy="delete-template-dialog-confirm-button"
    >
      <DialogContent data-cy="delete-template-dialog-content" sx={{ mt: 3 }}>
        <Typography variant="body2" color="textSecondary">
          {templateLibraryTxt.DELETE_TEMPLATE_CONFIRMATION}
        </Typography>
      </DialogContent>
    </SimpleDialog>
  );
};

export default DeleteDialog;
