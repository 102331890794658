import { useMemo } from "react";

import { type AWSFlexsaveConfigurationModel, EarlyAccessFeature } from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";

import { useCurrency } from "../../../Components/hooks/useCurrency";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import { useAuthContext } from "../../../Context/AuthContext";
import { useFlexsaveRDSData, useFlexsaveSageMakerData } from "../AWS/hooks";
import { type StateInfoData } from "../Common/Onboarding/types";
import { type JourneyStep } from "./constants";
import {
  computeSavingsOverview,
  generateServiceSavingsReport,
  getStateHeader,
  type SavingsOverview,
  unifiedSavingsOverview,
} from "./stateHeaderUtils";
import { getCustomerState } from "./utils";

export const useJourneyStep = (data: WithFirebaseModel<AWSFlexsaveConfigurationModel> | undefined): JourneyStep => {
  const isDisabledByFeatureFlag = useFeatureFlag(EarlyAccessFeature.FSAWS_DISABLED);
  return useMemo(() => getCustomerState(data, isDisabledByFeatureFlag), [data, isDisabledByFeatureFlag]);
};

const useSavingsOverview = (computeData: AWSFlexsaveConfigurationModel | undefined): SavingsOverview | undefined => {
  const { sageMakerData } = useFlexsaveSageMakerData();
  const { rdsData } = useFlexsaveRDSData();

  const computeOverview = computeData ? computeSavingsOverview(computeData) : undefined;

  const sageMakerOverview =
    sageMakerData?.timeEnabled && sageMakerData.savingsSummary.currentMonth
      ? generateServiceSavingsReport(sageMakerData)
      : undefined;

  const rdsOverview =
    rdsData?.timeEnabled && rdsData.savingsSummary.currentMonth ? generateServiceSavingsReport(rdsData) : undefined;

  const validOverviews = [computeOverview, sageMakerOverview, rdsOverview].filter(
    (overview): overview is SavingsOverview => !!overview
  );

  if (!validOverviews.length) {
    return undefined;
  }

  return unifiedSavingsOverview(validOverviews);
};

export const useJourneyStepToGetHeader = (
  journeyStep: JourneyStep,
  data: AWSFlexsaveConfigurationModel | undefined
): StateInfoData => {
  const customerId = useCustomerId();
  const { isDoitEmployee } = useAuthContext();
  const { customerCurrencySymbol } = useCurrency();
  const savingsOverview = useSavingsOverview(data);

  return useMemo(
    () => getStateHeader(savingsOverview, customerId, journeyStep, !!isDoitEmployee, customerCurrencySymbol),
    [customerId, journeyStep, isDoitEmployee, customerCurrencySymbol, savingsOverview]
  );
};
