import { type JSX } from "react";

import { AlertCondition, type CurrencyCode, type ExtendedMetric, Metric } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";

import { type AttributionMetadataOption, type MetricWSnap } from "../../../types";
import { getCurrencySymbol } from "../../../utils/common";
import { AlertConditionOptions, AlertsOpertatorOptions, MetricOptions, timeIntervalOptions } from "../utilities";

type AnalyticsAlertConditionProps = {
  condition: string;
  calculatedMetric: MetricWSnap | null;
  currency: CurrencyCode;
  selectedExtendedMetric: string | null;
  extendedMetricOptions: ExtendedMetric[];
  metric: number;
  operator: string;
  timeInterval: string;
  alertValue: number | "";
  evaluateByDimension: AttributionMetadataOption | null;
};

const getMetricName = (
  metric: number,
  calculatedMetric: MetricWSnap | null,
  selectedExtendedMetric: string | null,
  extendedMetricOptions: ExtendedMetric[]
) => {
  let metricName = "";

  if (metric === Metric.CALCULATED) {
    metricName = calculatedMetric?.data.name ?? "";
  } else if (metric === Metric.EXTENDED) {
    metricName = selectedExtendedMetric ?? "";
  } else {
    MetricOptions.forEach((option) => {
      if (option.value === metric) {
        metricName = option.label.toLowerCase();
      }
    });
  }

  if (selectedExtendedMetric) {
    const extendedMetric = extendedMetricOptions.find((m) => m.key === selectedExtendedMetric);
    metricName = extendedMetric?.label ?? selectedExtendedMetric;
  }

  return metricName;
};

const getValueString = (metric: number, currency: CurrencyCode, alertValue: number | "", condition: string) => {
  let valueString = "";

  if (condition === AlertCondition.PERCENTAGE) {
    valueString = `${alertValue}%`;
  } else if (metric === Metric.USAGE || metric === Metric.CALCULATED) {
    valueString = alertValue.toString();
  } else {
    valueString = `${getCurrencySymbol(currency)}${alertValue}`;
  }

  return valueString;
};

export const AnalyticsAlertCondition = ({
  condition,
  currency,
  calculatedMetric,
  selectedExtendedMetric,
  extendedMetricOptions,
  metric,
  operator,
  timeInterval,
  alertValue,
  evaluateByDimension,
}: AnalyticsAlertConditionProps): [JSX.Element, string] => {
  let operatorLabel = "";
  let timeIntervalLabel = "";
  let evaluateLabel = "";

  const metricName = getMetricName(metric, calculatedMetric, selectedExtendedMetric, extendedMetricOptions);

  const valueString = getValueString(metric, currency, alertValue, condition);

  AlertsOpertatorOptions.forEach((option) => {
    if (option.value === operator) {
      operatorLabel = option.label;
    }
  });

  timeIntervalOptions.forEach((option) => {
    if (option.value === timeInterval) {
      timeIntervalLabel = option.alternateLabel;
    }
  });

  const conditionType = AlertConditionOptions.find((option) => {
    if (option.value === condition) {
      return option.label;
    }
  })?.label;

  const conditionString = [timeIntervalLabel, metricName, conditionType, operatorLabel, valueString].join(" ");

  if (evaluateByDimension?.id) {
    evaluateLabel = `Evaluate for each ${evaluateByDimension.data.label}`;
  }

  const rowConditionString = conditionString.concat(evaluateLabel);

  return [
    <Typography variant="body2" key={conditionString.concat(evaluateLabel ?? "")}>
      {conditionString}
      {!!evaluateLabel && (
        <>
          &nbsp;
          <Typography variant="body2">{evaluateLabel}</Typography>
        </>
      )}
    </Typography>,
    rowConditionString,
  ];
};
