import { useState } from "react";

import { DeleteDialog } from "../../../Components/DeleteDialog";
import { DeleteKnownIssuesDialogMessage, DeleteKnownIssuesDialogTitle } from "./consts";
import { DeleteKnownIssuesButton } from "./DeleteKnownIssuesButton";

export type DeleteKnownIssuesProps = {
  removeKnownIssues: () => Promise<void>;
};

export const DeleteKnownIssues = ({ removeKnownIssues }: DeleteKnownIssuesProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };
  return (
    <>
      <DeleteKnownIssuesButton onClick={toggleDialog} />
      <DeleteDialog
        open={openDialog}
        title={DeleteKnownIssuesDialogTitle}
        message={DeleteKnownIssuesDialogMessage}
        onDelete={removeKnownIssues}
        onClose={toggleDialog}
      />
    </>
  );
};
