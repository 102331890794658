import { TimeInterval, TimeSettingsMode } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { BudgetTypes, type TimeRangeOption } from "../utilities";

export const fullPreviewAmountByInterval = {
  [TimeInterval.DAY]: 24,
  [TimeInterval.WEEK]: 7,
  [TimeInterval.MONTH]: daysInCurrentMonth(),
  [TimeInterval.QUARTER]: weeksInCurrentQuarter(),
  [TimeInterval.YEAR]: 12,
};

export const previewAmountByInterval = {
  [TimeInterval.DAY]: hoursElapsedSinceStartOfDay(),
  [TimeInterval.WEEK]: daysElapsedSinceStartOfWeek(),
  [TimeInterval.MONTH]: daysElapsedSinceStartOfMonth(),
  [TimeInterval.QUARTER]: weeksElapsedSinceStartOfQuarter(),
  [TimeInterval.YEAR]: monthsElapsedSinceStartOfYear(),
};

const getRecurringTimeUnitAmount = (timeInterval: TimeInterval) => {
  switch (timeInterval) {
    case TimeInterval.DAY:
      return 30;
    case TimeInterval.WEEK:
      return 16;
    case TimeInterval.MONTH:
      return 6;
    case TimeInterval.QUARTER:
      return 4;
    case TimeInterval.YEAR:
      return 2;
    default:
      return 1;
  }
};

export const getTimeRangeOption = (
  budgetType: BudgetTypes,
  timeInterval: TimeInterval,
  startPeriod: DateTime,
  endPeriod: DateTime
): TimeRangeOption => {
  const amount = budgetType === BudgetTypes.RECURRING ? getRecurringTimeUnitAmount(timeInterval) : undefined;

  const range =
    budgetType === BudgetTypes.FIXED
      ? {
          start: startPeriod,
          end: endPeriod,
        }
      : undefined;

  const mode = budgetType === BudgetTypes.FIXED ? TimeSettingsMode.Fixed : TimeSettingsMode.Last;

  const includeCurrent = budgetType === BudgetTypes.RECURRING;

  return {
    mode,
    time: timeInterval,
    amount,
    range,
    includeCurrent,
  };
};

export const getFixedTimeInterval = (startPeriod: DateTime, endPeriod: DateTime): TimeInterval => {
  const diff = endPeriod.diff(startPeriod, "months").months;
  if (diff >= 12) {
    return TimeInterval.YEAR;
  } else if (diff >= 6) {
    return TimeInterval.QUARTER;
  } else if (diff >= 2) {
    return TimeInterval.MONTH;
  } else if (diff >= 1) {
    return TimeInterval.WEEK;
  }
  return TimeInterval.DAY;
};

type budgetState = {
  shouldRefreshData: boolean;
  shouldRefreshPreview: boolean;
  refreshPreview: boolean;
};

type budgetStateAction = {
  type:
    | "shouldRefreshData"
    | "startedRefreshPreview"
    | "finishedRefreshPreview"
    | "shouldRefreshPreview"
    | "startedRefreshData";
  payload?: boolean;
};

export const budgetStateReducer = (state: budgetState, action: budgetStateAction): budgetState => {
  switch (action.type) {
    case "shouldRefreshPreview":
      return { ...state, shouldRefreshPreview: true };
    case "startedRefreshPreview":
      return { ...state, refreshPreview: true, shouldRefreshPreview: false };
    case "finishedRefreshPreview":
      return { ...state, refreshPreview: action.payload ?? false };
    case "shouldRefreshData":
      return { ...state, shouldRefreshData: true };
    case "startedRefreshData":
      return { ...state, shouldRefreshData: false };
  }
};

export function weeksInCurrentQuarter() {
  const now = new Date();
  const quarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
  const quarterStart: any = new Date(now.getFullYear(), quarterStartMonth, 1);
  const quarterEnd: any = new Date(now.getFullYear(), quarterStartMonth + 3, 0);
  const weekMilliseconds = 7 * 24 * 60 * 60 * 1000;
  return Math.ceil((quarterEnd - quarterStart + 1) / weekMilliseconds);
}

export function getStartAndEndOfDayUTC() {
  const now = DateTime.utc();
  const startOfDay = now.startOf("day").toUTC();
  const endOfDay = now.endOf("day").toUTC();

  return { start: startOfDay, end: endOfDay };
}

export function getStartAndEndOfWeekUTC() {
  const now = DateTime.utc();
  const startOfWeek = now.startOf("week");
  const endOfWeek = startOfWeek.plus({ days: 6 }).endOf("day");

  return { start: startOfWeek, end: endOfWeek };
}

export function daysInCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  return new Date(year, month, 0).getDate();
}

export function makeDataCumulative(
  data: Array<{ y: number; [key: string]: any }>,
  startPoint = 0
): Array<{ y: number; [key: string]: any }> {
  let cumulativeSum = startPoint;
  return (
    data?.map((point) => {
      cumulativeSum += point.y;
      return {
        ...point,
        y: cumulativeSum,
      };
    }) || []
  );
}

export const buildRange = (startPeriod: DateTime, endPeriod: DateTime, timeInterval: TimeInterval): number[] => {
  const intervals = {
    [TimeInterval.DAY]: { hours: 1 },
    [TimeInterval.WEEK]: { days: 1 },
    [TimeInterval.MONTH]: { days: 1 },
    [TimeInterval.QUARTER]: { weeks: 1 },
    [TimeInterval.YEAR]: { months: 1 },
  };

  const range: number[] = [];
  let current: DateTime = startPeriod;

  while (current <= endPeriod) {
    range.push(current.toMillis());
    if (!intervals[timeInterval]) {
      throw new Error("Invalid time interval");
    }
    current = current.plus(intervals[timeInterval]);
  }

  return range;
};

export const populateActualsYValues = (
  data: Array<{ y: number; [key: string]: number }>,
  actual: number
): Array<{ y: number; [key: string]: number }> => {
  const periods = data.length;
  const period = periods - 1;

  return data.map((point, index) => ({
    ...point,
    y: (actual / periods) * (index + 1) + period - 1,
  }));
};

export const populateForecastedYValues = (
  data: Array<{ y: number; [key: string]: number }>,
  forecasted: number
): Array<{ y: number; [key: string]: number }> => {
  const periods = data.length;
  return data.map((point, index) => ({
    ...point,
    y: (forecasted / periods) * (index + 1),
  }));
};

export function getFirstDayOfISOWeek(year, weekNumber) {
  const jan4 = new Date(Date.UTC(year, 0, 4));
  const dayOfWeek = jan4.getUTCDay() || 7;
  const firstMonday = new Date(jan4);
  firstMonday.setUTCDate(jan4.getUTCDate() + 1 - dayOfWeek);

  const startOfWeek = new Date(firstMonday);
  startOfWeek.setUTCDate(firstMonday.getUTCDate() + (weekNumber - 1) * 7);

  return startOfWeek;
}

export function convertToUTCTimestamp(dateString) {
  const weekYearWithMonthDayMatch = dateString.match(/^(\d{4})-W(\d{2}) \((\w{3}) (\d{2})\)$/);
  const weekYearMatch = dateString.match(/^(\d{4}) W(\d{2}) \((\w{3}) (\d{2})\)$/);

  if (weekYearWithMonthDayMatch) {
    const year = parseInt(weekYearWithMonthDayMatch[1], 10);
    const weekNumber = parseInt(weekYearWithMonthDayMatch[2], 10);
    const startOfWeek = DateTime.utc().set({ year }).set({ weekNumber, weekday: 1 }).startOf("day");
    return startOfWeek.toMillis();
  } else if (weekYearMatch) {
    const year = parseInt(weekYearMatch[1], 10);
    const weekNumber = parseInt(weekYearMatch[2], 10);
    const startOfWeek = DateTime.utc().set({ year }).set({ weekNumber, weekday: 1 }).startOf("day");
    return startOfWeek.toMillis();
  } else if (dateString.length === 16) {
    return DateTime.fromFormat(dateString, "yyyy-MM-dd-HH:mm", { zone: "utc" }).toMillis();
  } else if (dateString.length === 10) {
    return DateTime.fromFormat(dateString, "yyyy-MM-dd", { zone: "utc" }).toMillis();
  } else if (dateString.length === 7) {
    return DateTime.fromFormat(dateString, "yyyy-MM", { zone: "utc" }).toMillis();
  } else {
    throw new Error("Unsupported date format");
  }
}

export function hoursElapsedSinceStartOfDay() {
  const now = new Date();
  return now.getHours();
}

export function daysElapsedSinceStartOfWeek() {
  const now = new Date();
  const dayOfWeek = now.getUTCDay();
  return dayOfWeek + 1;
}

export function daysElapsedSinceStartOfMonth() {
  const today = DateTime.local();

  const startOfMonth = today.startOf("month");

  const daysUntilToday = today.diff(startOfMonth, "days").days + 1;

  return Math.floor(daysUntilToday);
}

export function weeksElapsedSinceStartOfQuarter() {
  const now = DateTime.utc();
  const quarterStartMonth = Math.floor(now.month / 3) * 3 + 1;
  const quarterStart = DateTime.utc(now.year, quarterStartMonth, 1);
  const elapsedWeeks = Math.floor(now.diff(quarterStart, "weeks").weeks);
  return elapsedWeeks;
}

export function monthsElapsedSinceStartOfYear() {
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  return currentMonth;
}

export function filterByCurrentDay(data) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getUTCDate();

  return data.filter((item) => {
    const itemDate = new Date(item.x);
    return !(
      itemDate.getFullYear() === currentYear &&
      itemDate.getMonth() === currentMonth &&
      itemDate.getUTCDate() === currentDate
    );
  });
}

export function filterByCurrentWeek(data) {
  const now = DateTime.utc();

  const startOfWeek = now.startOf("week");
  const endOfWeek = startOfWeek.plus({ days: 6 }).endOf("day");

  return data.filter((item) => {
    const itemDate = DateTime.fromMillis(item.x, { zone: "utc" });
    return itemDate >= startOfWeek && itemDate <= endOfWeek;
  });
}

export function filterByCurrentMonth(data) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  return data.filter((item) => {
    const itemDate = new Date(item.x);
    return itemDate.getFullYear() === currentYear && itemDate.getMonth() === currentMonth;
  });
}

export function filterByCurrentQuarter(data) {
  const { start, end } = getStartAndEndOfQuarterUTC();

  return data.filter((item) => {
    const itemDate = DateTime.fromMillis(item.x, { zone: "utc" });

    return itemDate >= start && itemDate <= end;
  });
}

export function filterByCurrentYear(data) {
  const currentYear = DateTime.utc().year;

  return data.filter((item) => {
    const itemDate = DateTime.fromMillis(item.x, { zone: "utc" });
    return itemDate.year === currentYear;
  });
}

export function getStartAndEndOfQuarterUTC() {
  const now = DateTime.utc();
  const currentMonth = now.month;

  const startMonth = Math.floor((currentMonth - 1) / 3) * 3 + 1;

  const start = DateTime.utc(now.year, startMonth, 1).startOf("day");

  const end = start.plus({ months: 3 }).minus({ days: 1 }).endOf("day");

  return { start, end };
}

export function getStartAndEndOfMonthUTC() {
  const now = DateTime.utc();

  const start = now.startOf("month");

  const end = now.endOf("month");

  return { start, end };
}

export function getStartAndEndOfYearUTC() {
  const start = DateTime.utc().startOf("year");
  const end = DateTime.utc().endOf("year");
  return { start, end };
}

export const nearestValue = (arr, val) =>
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  arr.reduce((p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val;
