class GSuiteSKUs {
  readonly GSuiteLite: string;

  readonly GSuiteBasic: string;

  readonly GSuiteBusiness: string;

  readonly GSuiteEnterprise: string;

  readonly WorkspaceEnterprisePlus: string;

  readonly WorkspaceEnterpriseStandard: string;

  readonly WorkspaceEnterpriseEssentials: string;

  readonly WorkspaceBusinessPlus: string;

  readonly WorkspaceBusinessStandard: string;

  readonly WorkspaceBusinessStarter: string;

  readonly WorkspaceEssentials: string;

  readonly CloudIdentityFree: string;

  readonly CloudIdentityPremium: string;

  readonly GoogleVault: string;

  readonly GoogleVaultFormerEmployee: string;

  readonly GoogleChromeEnterprise: string;

  readonly GSuiteBusinessArchivedUser: string;

  readonly GSuiteEnterpriseArchivedUser: string;

  readonly WorkspaceEnterprisePlusArchivedUser: string;

  readonly WorkspaceEnterpriseStandardArchivedUser: string;

  readonly WorkspaceBusinessPlusArchivedUser: string;

  readonly GoogleVoiceStarter: string;

  readonly GoogleVoiceStandard: string;

  readonly GoogleVoicePremier: string;

  readonly GoogleMeetGlobalDialing: string;

  constructor() {
    // Legacy G Suite
    this.GSuiteLite = "Google-Apps-Lite";
    this.GSuiteBasic = "Google-Apps-For-Business";
    this.GSuiteBusiness = "Google-Apps-Unlimited";
    this.GSuiteEnterprise = "1010020020";

    // Google Workspace Enterprise
    this.WorkspaceEnterprisePlus = "1010020020";
    this.WorkspaceEnterpriseStandard = "1010020026";
    this.WorkspaceEnterpriseEssentials = "1010060003";

    // Google Workspace Business
    this.WorkspaceBusinessPlus = "1010020025";
    this.WorkspaceBusinessStandard = "1010020028";
    this.WorkspaceBusinessStarter = "1010020027";

    // Essentials (AKA Drive Enterprise?)
    this.WorkspaceEssentials = "1010060001";

    this.CloudIdentityFree = "1010010001";
    this.CloudIdentityPremium = "1010050001";

    this.GoogleVault = "Google-Vault";
    this.GoogleVaultFormerEmployee = "Google-Vault-Former-Employee";

    this.GoogleChromeEnterprise = "Google-Chrome-Device-Management";

    this.GSuiteBusinessArchivedUser = "1010340002";
    this.GSuiteEnterpriseArchivedUser = "1010340001";
    this.WorkspaceEnterprisePlusArchivedUser = "1010340001";
    this.WorkspaceEnterpriseStandardArchivedUser = "1010340004";
    this.WorkspaceBusinessPlusArchivedUser = "1010340003";

    this.GoogleVoiceStarter = "1010330003";
    this.GoogleVoiceStandard = "1010330004";
    this.GoogleVoicePremier = "1010330002";

    this.GoogleMeetGlobalDialing = "1010360001";
  }

  isMainSKU = (skuId) =>
    [
      this.WorkspaceEnterprisePlus,
      this.WorkspaceEnterpriseStandard,
      this.WorkspaceEnterpriseEssentials,
      this.WorkspaceBusinessPlus,
      this.WorkspaceBusinessStandard,
      this.WorkspaceBusinessStarter,
      this.GSuiteLite,
      this.GSuiteBasic,
      this.GSuiteBusiness,
      this.GSuiteEnterprise,
    ].includes(skuId);

  includesDrive = (skuId) => [this.GSuiteBusiness, this.GSuiteEnterprise].includes(skuId);

  isDrive = (skuId) => skuId.startsWith("Google-Drive-storage-");

  isVoice = (skuId) => [this.GoogleVoiceStarter, this.GoogleVoiceStandard, this.GoogleVoicePremier].includes(skuId);
}

const sku = new GSuiteSKUs();

export const googleVoiceEditions = [
  {
    skuId: sku.GoogleVoiceStandard,
    skuName: "Google Voice Standard",
  },
  {
    skuId: sku.GoogleVoicePremier,
    skuName: "Google Voice Premier",
  },
];

export default sku;

export const transformWorkspacePlanName = (planName: string) => {
  switch (planName) {
    case "ANNUAL_MONTHLY_PAY":
      return "ANNUAL";
    case "ANNUAL_YEARLY_PAY":
      return "ANNUAL";
    case "ANNUAL":
      return "ANNUAL";
    case "FLEXIBLE":
      return "FLEXIBLE";
    case "TRIAL":
      return "TRIAL";
    case "FREE":
      return "";
    default:
      return planName;
  }
};

export const GetResellerLabel = (reseller) => {
  switch (reseller) {
    case "vadim@doit-g.co.il":
      return "doit-g.co.il";
    case "admin@premier.doit-intl.com":
      return "premier.doit-intl.com";
    case "admin@msp.doit-intl.com":
      return "msp.doit-intl.com";
    case "admin@na.doit-intl.com":
      return "na.doit-intl.com";
    default:
      return reseller;
  }
};
