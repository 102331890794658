import { type Dispatch, type SetStateAction } from "react";

import { AnalyticsDataSource, type CurrencyCode, type DatahubMetrics } from "@doitintl/cmp-models";

import { useAttributionsContext } from "../../Context/AttributionsContext";
import { type MetricWSnap, type ReportWSnap } from "../../types";
import { type AttributionGroupWithRef } from "./attributionGroups/types";
import {
  CreateTemplateContextProvider,
  MetricSplitsContextProvider,
  ReportConfigContextProvider,
  ReportContextProvider,
  ReportDimensionsContextProvider,
  ReportExportContextProvider,
  ReportSaverContextProvider,
  ReportTemplateContextProvider,
} from "./Context";
import { type ReportTemplatesAction } from "./reducers/ReportTemplatesReducer";
import Report from "./Report";
import { type ReportTemplateCard } from "./templateLibrary/types";

type Props = {
  report: ReportWSnap;
  calculatedMetrics: MetricWSnap[];
  filteredExtendedMetrics;
  defaultCurrency?: CurrencyCode | null;
  excludeSelectMetadataIds: Set<string>;
  handleRevertReport: () => void;
  attributionGroups: AttributionGroupWithRef[];
  reportTemplateId: string;
  isFavoriteReportTemplate: boolean;
  reportTemplateCards: ReportTemplateCard[];
  dispatchReportTemplates: Dispatch<ReportTemplatesAction>;
  hasDataHub: boolean;
  datahubMetrics: DatahubMetrics[];
  rawReportData: any[][];
  setRawReportData: Dispatch<SetStateAction<any[][]>>;
};

const ReportContextWrapper = ({
  report,
  calculatedMetrics,
  filteredExtendedMetrics,
  defaultCurrency,
  excludeSelectMetadataIds,
  handleRevertReport,
  attributionGroups,
  reportTemplateId,
  isFavoriteReportTemplate,
  reportTemplateCards,
  dispatchReportTemplates,
  hasDataHub,
  datahubMetrics,
  rawReportData,
  setRawReportData,
}: Props) => {
  const { attributions } = useAttributionsContext();

  let dataSource = report?.data?.config?.dataSource;

  if (!dataSource) {
    dataSource = hasDataHub ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING;
  } else if (dataSource === AnalyticsDataSource.BILLING_DATAHUB && !hasDataHub) {
    dataSource = AnalyticsDataSource.BILLING;
  }

  return (
    <ReportContextProvider report={report} rawReportData={rawReportData}>
      <ReportConfigContextProvider
        props={{
          calcMetrics: calculatedMetrics,
          extendedMetrics: filteredExtendedMetrics,
          datahubMetrics,
          currency: defaultCurrency,
          excludeSelectMetadataIds,
          dataSource,
        }}
      >
        <ReportDimensionsContextProvider attributions={attributions}>
          <MetricSplitsContextProvider attributions={attributions}>
            <ReportSaverContextProvider>
              <ReportTemplateContextProvider
                reportTemplateId={reportTemplateId}
                isFavoriteReportTemplate={isFavoriteReportTemplate}
                reportTemplateCards={reportTemplateCards}
                dispatchReportTemplates={dispatchReportTemplates}
              >
                <CreateTemplateContextProvider>
                  <ReportExportContextProvider>
                    <Report
                      attributions={attributions}
                      handleRevertReport={handleRevertReport}
                      attributionGroups={attributionGroups}
                      setRawReportData={setRawReportData}
                    />
                  </ReportExportContextProvider>
                </CreateTemplateContextProvider>
              </ReportTemplateContextProvider>
            </ReportSaverContextProvider>
          </MetricSplitsContextProvider>
        </ReportDimensionsContextProvider>
      </ReportConfigContextProvider>
    </ReportContextProvider>
  );
};

export default ReportContextWrapper;
