import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";

import { ThemeModes } from "../../../../muiThemeTypes";
import { FlexsaveJourneyIcon } from "./FlexsaveJourneyIcon";
import { type Step } from "./types";

type Props = {
  currentStep: number | null;
  stepList: Step[];
};

const JourneyStackStyledBackground = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === ThemeModes.DARK ? "#2D2D38" : "#FAFAFA",
}));

export const FlexsaveJourney = ({ currentStep, stepList }: Props) => (
  <JourneyStackStyledBackground sx={{ p: 3, borderRadius: 0.5 }}>
    {stepList.map(({ title, subtitle }, index) => (
      <Stack key={title} direction="row">
        <FlexsaveJourneyIcon
          active={currentStep === index}
          completed={currentStep === null ? false : currentStep > index}
          last={stepList.length - 1 === index}
        />

        <Stack pl={2}>
          <Typography variant="body1" color="text.primary" fontWeight={currentStep === index ? "500" : "400"}>
            {title}
          </Typography>
          <Typography pt={1} pb={3} variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    ))}
  </JourneyStackStyledBackground>
);
