import { Box, Typography } from "@mui/material";

import { FlexsaveWidgetCard } from "../Common/FlexsaveWidgetCard";

export const FlexsaveCardUnauthorized = ({ widgetHeight }: { widgetHeight: number }) => (
  <FlexsaveWidgetCard title="Flexsave" subheader="Missing Permissions" widgetHeight={widgetHeight}>
    <Box display="flex" alignItems="center" height="100%" justifyContent="center">
      <Typography variant="subtitle1">You are not authorized to view this page.</Typography>
    </Box>
  </FlexsaveWidgetCard>
);
