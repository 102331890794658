import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material";

import LoadingButton from "../../../../Components/LoadingButton";
import { preventOnCloseWhile, useFullScreen } from "../../../../utils/dialog";

type Props = {
  open: boolean;
  onConfirm: () => Promise<void>;
  onClose: () => void;
  loading: boolean;
  title: string;
  description: string;
};

export const SsoDialog = ({ title, description, open, onConfirm, loading, onClose }: Props) => {
  const { isMobile: xsDown } = useFullScreen("sm");

  return (
    <Dialog fullScreen={xsDown} open={open} onClose={preventOnCloseWhile(loading, onClose)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: "pre-wrap" }}>{description}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="text">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={onConfirm}
          disabled={loading}
          loading={loading}
          data-cy="confirm-btn"
          mixpanelEventId="settings.sso.confirm"
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SsoDialog;
