import { type CustomersColumnId, CustomersColumnsID } from "@doitintl/cmp-models";

import { customizeTableViewTexts } from "../../assets/texts";
import { type HeaderColumn } from "../../types/FilterTable";
import { formatNumber } from "../../utils/common";
import {
  getAccountManagers,
  getCustomersAwsContracts,
  getCustomersAwsRampPlanAttainment,
  getCustomersCloudConnectStatus,
  getCustomersFlexsaveSavings,
  getCustomersGCPContracts,
  getCustomersGcpRampPlanAttainment,
  getCustomersGkeCostAllocationStatus,
  getCustomersMonthVsLastReport,
} from "./db";
import {
  type Column,
  type ColumnPromise,
  type FlexsavePlatformItem,
  FlexsaveStatus,
  RampPlanStatus,
  Status,
} from "./types";

export const thisMonthVsLast = "Anl2FHDAgyxR4GFellrA";

export const baseColumns: CustomersColumnId[] = [
  CustomersColumnsID.Checkbox,
  CustomersColumnsID.PrimaryDomain,
  CustomersColumnsID.ThisMonth,
  CustomersColumnsID.Trend,
  CustomersColumnsID.Assets,
] as const;

const basePartnerHeaders = [
  CustomersColumnsID.PrimaryDomain,
  CustomersColumnsID.ThisMonth,
  CustomersColumnsID.Trend,
] as const;

export const AwsPartnerAccessHeaders: CustomersColumnId[] = [
  ...basePartnerHeaders,
  CustomersColumnsID.AwsRampPlanStatus,
  CustomersColumnsID.AwsRampPlanAttainment,
  CustomersColumnsID.AwsContracts,
] as const;

export const GCPPartnerAccessHeaders: CustomersColumnId[] = [
  ...basePartnerHeaders,
  CustomersColumnsID.GcpRampPlanStatus,
  CustomersColumnsID.GcpRampPlanAttainment,
  CustomersColumnsID.GcpContracts,
] as const;

export const columnsPromises = {
  [CustomersColumnsID.AccountManagers]: getAccountManagers,
  [CustomersColumnsID.ThisMonth]: getCustomersMonthVsLastReport,
  [CustomersColumnsID.AwsRampPlanAttainment]: getCustomersAwsRampPlanAttainment,
  [CustomersColumnsID.GcpRampPlanAttainment]: getCustomersGcpRampPlanAttainment,
  [CustomersColumnsID.FlexsaveAWS]: getCustomersFlexsaveSavings,
  [CustomersColumnsID.BigqueryLens]: getCustomersCloudConnectStatus,
  [CustomersColumnsID.AwsContracts]: getCustomersAwsContracts,
  [CustomersColumnsID.GcpContracts]: getCustomersGCPContracts,
  [CustomersColumnsID.GkeCostAllocation]: getCustomersGkeCostAllocationStatus,
} as const satisfies ColumnPromise;

export const columnsConfig: Column[] = [
  { id: CustomersColumnsID.Checkbox, customizable: false },
  { id: CustomersColumnsID.PrimaryDomain, customizable: false },
  { id: CustomersColumnsID.ThisMonth, customizable: false },
  { id: CustomersColumnsID.Trend, customizable: false },
  {
    id: CustomersColumnsID.AccountManagers,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.ACCOUNT_MANAGER,
  },
  {
    id: CustomersColumnsID.BigqueryLens,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.BQ_LENS,
  },
  {
    id: CustomersColumnsID.GkeCostAllocation,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.GKE_COST_ALLOCATION,
  },
  {
    id: CustomersColumnsID.FlexsaveAWS,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.FLEXSAVE_AWS,
  },
  { id: CustomersColumnsID.Assets, customizable: false, hidden: true },
  {
    id: CustomersColumnsID.AwsRampPlanAttainment,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.AWS_RAMP_PLAN,
  },
  { id: CustomersColumnsID.AwsRampPlanStatus, hidden: true, customizable: true },
  { id: CustomersColumnsID.AwsContracts, customizable: true, hidden: true },
  {
    id: CustomersColumnsID.GcpRampPlanAttainment,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.GCP_RAMP_PLAN,
  },
  { id: CustomersColumnsID.GcpRampPlanStatus, hidden: true, customizable: true },
  { id: CustomersColumnsID.GcpContracts, customizable: true, hidden: true },
  {
    id: CustomersColumnsID.TrialStatus,
    customizable: true,
    title: customizeTableViewTexts.COLUMNS_TITLE.TRIAL_STATUS,
  },
];

export const bqLens = {
  label: "BQ Lens",
  value: CustomersColumnsID.BigqueryLens,
} as const;

export const gkeCostAllocation = {
  label: "GKE Cost Allocation",
  value: CustomersColumnsID.GkeCostAllocation,
};

export const awsRampPlanAttainment = {
  label: "AWS Ramp",
  value: CustomersColumnsID.AwsRampPlanAttainment,
  statusValue: CustomersColumnsID.AwsRampPlanStatus,
} as const;

export const gcpRampPlanAttainment = {
  label: "GCP Ramp",
  value: CustomersColumnsID.GcpRampPlanAttainment,
  statusValue: CustomersColumnsID.GcpRampPlanStatus,
} as const;

export const trialStatus = {
  label: "Trial Status",
  value: CustomersColumnsID.TrialStatus,
} as const;

export const flexsave = (type: "AWS" | "GCP") =>
  ({
    label: `FS${type} Savings`,
    value: `flexsave${type}`,
  }) as const;

export const getFlexsaveStatus = (flexsavePlatform: FlexsavePlatformItem) => {
  if (typeof flexsavePlatform === "string") {
    return flexsavePlatform;
  }
  switch (true) {
    case flexsavePlatform === FlexsaveStatus.InActive:
      return Status.InActive;
    case flexsavePlatform === FlexsaveStatus.Na ||
      flexsavePlatform === null ||
      flexsavePlatform === undefined ||
      isNaN(flexsavePlatform):
      return Status.Na;
    default:
      return `$${formatNumber(flexsavePlatform, 0)}/mo`;
  }
};

export const getRampPlanStatus = (rampStatus: RampPlanStatus) => {
  switch (rampStatus) {
    case RampPlanStatus.InActive:
      return Status.InActive;
    case RampPlanStatus.Na:
      return Status.Na;
    case RampPlanStatus.OnTime:
      return Status.OnTime;
    case RampPlanStatus.Lagging:
      return Status.Lagging;
  }
};

export const headers: HeaderColumn[] = [
  {
    value: "",
    label: "",
    tooltip: "",
    hidden: { smDown: true },
  },
  {
    value: CustomersColumnsID.PrimaryDomain,
    label: "Domain",
    tooltip: "Primary Domain",
  },
  {
    value: CustomersColumnsID.ThisMonth,
    label: "This Mo.",
    tooltip: "This Month",
  },
  {
    value: CustomersColumnsID.Trend,
    label: "Trend",
    tooltip: "Trend",
  },
  {
    value: CustomersColumnsID.AccountManagers,
    label: "Account Managers",
    tooltip: "Assigned Account Managers",
    hidden: { smDown: true },
  },
  {
    value: bqLens.value,
    label: bqLens.label,
    tooltip: bqLens.label,
    hidden: { smDown: true },
  },
  {
    value: gkeCostAllocation.value,
    label: gkeCostAllocation.label,
    tooltip: gkeCostAllocation.label,
    hidden: { smDown: true },
  },
  {
    value: flexsave("AWS").value,
    label: flexsave("AWS").label,
    tooltip: "Flexsave AWS",
    style: { width: "15%" },
    hidden: { smDown: true },
  },
  {
    value: flexsave("GCP").value,
    label: flexsave("GCP").label,
    tooltip: "Flexsave GCP",
    style: { width: "15%" },
    hidden: { smDown: true },
  },
  {
    value: awsRampPlanAttainment.statusValue,
    label: awsRampPlanAttainment.label,
    tooltip: awsRampPlanAttainment.label,
    hidden: { smDown: true },
  },
  {
    value: gcpRampPlanAttainment.statusValue,
    label: gcpRampPlanAttainment.label,
    tooltip: gcpRampPlanAttainment.label,
    hidden: { smDown: true },
  },
  {
    value: trialStatus.value,
    label: trialStatus.label,
    tooltip: trialStatus.label,
    hidden: { smDown: true },
  },
];
