import { useCallback } from "react";

import { useOnePassword } from "../../../Components/hooks/useOnePassword";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { mpaVaultId } from "../consts";
import useGoogleGroup from "../hooks/useGoogleGroup";
import { type MasterPayerAccountsModelSnapshot } from "../hooks/useSubscribedMasterPayerAccounts";
import getIsMPAAssignedToContract from "../isAssigendToContract";

export function useMPADeletionRequest() {
  const { deleteLoginItem } = useOnePassword();
  const { onOpen } = useSnackbar();
  const { deleteGoogleGroup } = useGoogleGroup();

  return useCallback(
    async (masterPayerAccount: MasterPayerAccountsModelSnapshot) => {
      const isMPAAssignedToContract = await getIsMPAAssignedToContract(masterPayerAccount);
      if (isMPAAssignedToContract) {
        onOpen({
          message: "Unable to delete this MPA as it's already connected to a contract",
          variant: "error",
          autoHideDuration: 5000,
          withClose: true,
        });
      } else {
        await masterPayerAccount.ref.delete();
        if (masterPayerAccount.onePasswordItemId) {
          try {
            await deleteLoginItem({
              vaultId: mpaVaultId,
              itemId: masterPayerAccount.onePasswordItemId,
            });
          } catch (exception) {
            consoleErrorWithSentry(exception);
          }
        }
        if (masterPayerAccount.rootEmail && masterPayerAccount.domain) {
          try {
            await deleteGoogleGroup({
              rootEmail: masterPayerAccount.rootEmail,
              domain: masterPayerAccount.domain,
            });
          } catch (exception) {
            consoleErrorWithSentry(exception);
          }
        }
        onOpen({
          message: "MPA successfully deleted",
          variant: "success",
          autoHideDuration: 5000,
          withClose: true,
        });
      }
    },
    [deleteLoginItem, onOpen, deleteGoogleGroup]
  );
}
