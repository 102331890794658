import CloseIcon from "@mui/icons-material/CloseRounded";
import { type AlertProps as MuiAlertProps } from "@mui/lab";
import { Alert as MuiAlert, AlertTitle, Button, type ButtonProps, IconButton } from "@mui/material";

export type AlertProps = Partial<MuiAlertProps> & {
  title?: string;
  buttonProps?: ButtonProps & { text: string; target?: string; rel?: string };
  onClose?: () => void;
};

export const Alert = ({ title, buttonProps, onClose, children, ...alertProps }: AlertProps) => {
  const action = buttonProps && (
    <>
      <Button {...buttonProps} aria-label={buttonProps.text}>
        {buttonProps.text}
      </Button>

      {onClose && (
        <IconButton key="dismiss" aria-label="Dismiss" size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
  return (
    <MuiAlert {...alertProps} action={action}>
      {!!title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </MuiAlert>
  );
};
