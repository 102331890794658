import { MenuItem, TextField } from "@mui/material";

import { sanitizeKeepingLocalDate } from "../../../../../../utils/common";
import { commitmentTermOptions, subscriptionTypeOptions } from "../../../const";
import { useContractFormContext } from "../../../ContractsFormContext";
import { calculateChargePerTerm } from "../../../utils";

export const SubscriptionTypeSelector = () => {
  const { state, setState } = useContractFormContext();

  const handleChange = ({ target: { value } }) => {
    const isCommitment = value === "commitment";
    const commitmentMonths = isCommitment ? (state.commitmentMonths ?? commitmentTermOptions[0]) : undefined;
    const endDate = isCommitment
      ? sanitizeKeepingLocalDate(state.startDate.plus({ months: commitmentMonths }))
      : state.endDate;
    const paymentTerm = isCommitment ? state.paymentTerm : "monthly";
    const chargePerTerm = calculateChargePerTerm(
      paymentTerm,
      commitmentMonths,
      state.tier?.name === "enterprise" ? state.chargePerTerm : state.tier?.price?.[state.currency]
    );

    setState({
      ...state,
      isCommitment,
      commitmentMonths,
      endDate,
      paymentTerm,
      chargePerTerm,
    });
  };

  return (
    <TextField
      label="Subscription type"
      data-cy="subscription-type"
      value={state.isCommitment ? "commitment" : "on-demand"}
      onChange={handleChange}
      disabled={state.isEditForbidden}
      select
      fullWidth
      margin="normal"
      variant="outlined"
    >
      {subscriptionTypeOptions?.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};
