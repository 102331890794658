import { DateTime } from "luxon";

const today = DateTime.utc().startOf("day");

export const extendCredit = (credit) => {
  credit.data._remaining = credit.data.amount;
  for (const monthKey in credit.data.utilization) {
    for (const key in credit.data.utilization[monthKey]) {
      credit.data._remaining -= credit.data.utilization[monthKey][key];
    }
  }

  credit.data._status = "active";
  if (credit.data._remaining <= 1e-2 && credit.data.depletionDate) {
    // set remaining to absolute zero to avoid $-0.00 because of floating points
    credit.data._remaining = 0;
    credit.data._status = "depleted";
  } else if (DateTime.fromJSDate(credit.data.endDate.toDate()) < today) {
    credit.data._status = "expired";
  }

  return credit;
};
