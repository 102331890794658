import { Container, Stack, Typography } from "@mui/material";

import {
  CreateBillingProfileForm,
  type CreateBillingProfileFormProps,
} from "../../../Components/CreateBillingProfile/BillingForm/CreateBillingProfileForm";

export const BillingInfoStep = ({ onSubmit }: CreateBillingProfileFormProps) => (
  <Container maxWidth="sm">
    <Stack pb={12} pt={4}>
      <Typography variant="h1" pb={4}>
        Billing information
      </Typography>
      <CreateBillingProfileForm onSubmit={onSubmit} />
    </Stack>
  </Container>
);
