import DoitLogo from "../../assets/doit-logo.svg";
import DoitLogoWhite from "../../assets/logo-white.svg";
import DoitLogoXmas from "../../assets/xmas-logo-darkmode.svg";
import DoitLogoWhiteXmas from "../../assets/xmas-logo-lightmode.svg";

export const getLogo = () => {
  const isXmas = new Date().getMonth() === 11;
  const logo = isXmas ? DoitLogoXmas : DoitLogo;
  const logoWhite = isXmas ? DoitLogoWhiteXmas : DoitLogoWhite;
  return { logo, logoWhite };
};

export default getLogo;
