import { useCallback, useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  CustomerModel,
  getProviderTypes,
  NotificationAudienceType,
  UserModel,
  type UserPersonalNotificationModel,
} from "@doitintl/cmp-models";
import { asFirestoreModelFromDocument, type CollectionReferenceModel, getCollection } from "@doitintl/models-firestore";

import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useUserContext } from "../../../../Context/UserContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { serverTimestamp } from "../../../../utils/firebase";
import { type ChannelConfig } from "../CreateNotificationWizard/types";

export const useCreateNotificationChannel = () => {
  const { userModel } = useUserContext({ allowNull: false });
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { notificationId, audience } = useParams<{ notificationId: string; audience: NotificationAudienceType }>();
  const history = useHistory();
  const [channelConfig, setChannelConfig] = useState<ChannelConfig>({
    name: "",
    providerType: "",
    providerTarget: {},
  });
  const [userNotificationsRef, setUserNotificationsRef] = useState<
    CollectionReferenceModel<UserPersonalNotificationModel> | undefined
  >();

  const customerNotificationsRef = getCollection(CustomerModel).doc(customer.id).collection("notifications");

  // Fetch notification data by id
  useEffect(() => {
    const fetchNotification = async () => {
      const notificationConfigRef =
        audience === NotificationAudienceType.USER ? userNotificationsRef : customerNotificationsRef;
      if (!notificationConfigRef) {
        return;
      }

      const notification = (await notificationConfigRef.doc(notificationId).get()).asModelData();
      if (!notification) {
        return;
      }
      const { name, providerTarget, selectedNotifications, disableTargetUpdate } = notification;

      const providerTypes = getProviderTypes(notification);
      // currently only one provider type is supported
      if (providerTypes.length !== 1 || !providerTypes[0]) {
        consoleErrorWithSentry("Invalid provider type");
        return;
      }
      const providerType = providerTypes[0];

      setChannelConfig({
        name,
        providerType,
        providerTarget,
        disableTargetUpdate: disableTargetUpdate || false,
        selectedNotifications: selectedNotifications || {},
        audienceType: audience,
      });
    };

    if (notificationId && channelConfig.providerType === "") {
      fetchNotification();
    }
  }, [channelConfig.providerType, customerNotificationsRef, notificationId, audience, userNotificationsRef]);

  // Fetch user ref
  useEffect(() => {
    const fetchUser = async () => {
      const userRef = await getCollection(UserModel).doc(userModel.ref.id).get();
      const notificationRefFromModel = asFirestoreModelFromDocument(userRef.snapshot, UserModel, {
        idField: "id",
        refField: "ref",
      })?.ref.collection("userPersonalNotifications");
      setUserNotificationsRef(notificationRefFromModel);
    };
    fetchUser();
  }, [userModel.ref.id]);

  const updateChannelConfig = useCallback(
    <K extends keyof ChannelConfig>(key: K, value: ChannelConfig[K]) => {
      setChannelConfig((prev) => ({ ...prev, [key]: value }));
    },
    [setChannelConfig]
  );

  const createNotification = useCallback(
    async (audience: NotificationAudienceType) => {
      const { name, selectedNotifications, providerTarget } = channelConfig;

      const notificationRef =
        audience === NotificationAudienceType.COMPANY ? customerNotificationsRef : userNotificationsRef;

      try {
        await notificationRef?.add({
          name,
          providerTarget,
          selectedNotifications: selectedNotifications || {},
          selectedNotificationsKeys: Object.keys(selectedNotifications || {}).map((key) => parseInt(key, 10)),
          createdAt: serverTimestamp(),
          createdBy: currentUser?.email || "",
          customerRef: customer.ref,
        });
        successSnackbar("Notification successfully configured");
        history.push(`/customers/${customer.id}/notifications`);
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Error while configuring notification");
      }
    },
    [
      channelConfig,
      currentUser?.email,
      customer.id,
      customer.ref,
      customerNotificationsRef,
      errorSnackbar,
      history,
      successSnackbar,
      userNotificationsRef,
    ]
  );

  const editNotification = useCallback(
    async (audience: NotificationAudienceType) => {
      const { name, selectedNotifications, providerTarget } = channelConfig;

      const notificationRef =
        audience === NotificationAudienceType.COMPANY ? customerNotificationsRef : userNotificationsRef;

      try {
        await notificationRef?.doc(notificationId).update({
          name,
          providerTarget,
          selectedNotifications: selectedNotifications || {},
          selectedNotificationsKeys: Object.keys(selectedNotifications || {}).map((key) => parseInt(key, 10)),
        });
        successSnackbar("Notification successfully updated");
        history.push(`/customers/${customer.id}/notifications`);
      } catch (e) {
        consoleErrorWithSentry(e);
        errorSnackbar("Error while configuring notification");
      }
    },
    [
      channelConfig,
      customer.id,
      customerNotificationsRef,
      errorSnackbar,
      history,
      notificationId,
      successSnackbar,
      userNotificationsRef,
    ]
  );

  return { channelConfig, updateChannelConfig, createNotification, editNotification };
};
