import { useCallback, useMemo } from "react";

import get from "lodash/get";
import orderBy from "lodash/orderBy";

import { type NestedObjectLeaves } from "../../utils/NestedKeyOf";
import usePersistedState from "./usePersistedState";

// Table state management (page, rowsPerPage, sort/sort direction and filter func)
export type Defaults<T> = {
  direction?: "asc" | "desc";
  rowsPerPage: number;
  page: number;
  sort?: NestedObjectLeaves<T>;
  persistenceKey?: string;
  rowsPerPageOptions?: any;
  filter?: ((rows: Readonly<Array<T>>) => Readonly<Array<T>>) | null;
  persistInSession: boolean;
};

export default function useTableState<T>(data: Readonly<Array<T>>, defaults: Partial<Defaults<T>>) {
  // take rows per page from local storage and setup listener to change local storage.
  const [tableState, setTableState] = usePersistedState(
    defaults.persistenceKey ? `useTableState_${defaults.persistenceKey}` : null,
    defaults.persistInSession ?? false,
    {
      rowsPerPage: defaults.rowsPerPage ?? 10,
      sort: defaults.sort,
      page: 0,
      direction: defaults.direction ?? "desc",
      rowsPerPageOptions: defaults.rowsPerPageOptions ?? [10, 25, { value: -1, label: "All" }],
    } as Defaults<T>
  );

  // Sort table by column
  const handleRequestSort = useCallback(
    (sort: NestedObjectLeaves<T>) => () => {
      const direction = tableState.sort === sort && tableState.direction === "desc" ? "asc" : "desc";

      setTableState((prevTableState) => ({ ...prevTableState, sort, direction }));
    },
    [setTableState, tableState.direction, tableState.sort]
  );

  // Change table page
  const handleChangePage = useCallback(
    (_event, page: number) => {
      setTableState((tableState) => ({
        ...tableState,
        page,
      }));
    },
    [setTableState]
  );

  // Change table rows per page
  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    setTableState((tableState) => ({
      ...tableState,
      rowsPerPage,
      page: 0,
    }));
  };

  // Change table filter
  const handleRequestFilter = useCallback(
    (filter, resetPage = true) => {
      setTableState((tableState) => ({ ...tableState, filter, page: resetPage ? 0 : tableState.page }));
    },
    [setTableState]
  );

  // Filter rows based on the filter
  const filteredRows = useMemo(() => tableState.filter?.(data) ?? data, [data, tableState]);

  // Sort and slice to page
  const rows = useMemo(() => {
    const sort = tableState.sort;
    const ordered = sort
      ? orderBy(
          filteredRows ?? [],
          [
            (row: T) => {
              const value = get(row, sort as string);
              if (typeof value === "string") {
                return value.toLowerCase();
              }
              return value;
            },
          ],
          [tableState.direction ?? "asc"]
        )
      : (filteredRows ?? []);

    const rowsPerPage = tableState.rowsPerPage;
    const page = tableState.page;
    if (rowsPerPage > -1) {
      return ordered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
    return ordered;
  }, [filteredRows, tableState]);

  // Calculate the number of empty rows that should be added at the bottom of the table to
  // keep the table footer at the same position
  const numEmptyRows = useMemo(() => {
    const rowsPerPage = tableState.rowsPerPage;
    const page = tableState.page;
    return rowsPerPage - Math.min(rowsPerPage, filteredRows.length - page * rowsPerPage);
  }, [filteredRows.length, tableState.page, tableState.rowsPerPage]);

  return {
    tableState,
    filteredRows,
    rows,
    numEmptyRows,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestFilter,
  };
}
