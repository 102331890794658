import { type Dispatch, type SetStateAction, useEffect, useState } from "react";

import { AnalyticsDataSource } from "@doitintl/cmp-models";
import { Divider, Link, Stack } from "@mui/material";

import { attributionText, budgetText } from "../../../../assets/texts";
import DataHubCheckbox from "../../../../Components/DataHubCheckbox/DataHubCheckbox";
import { AttributionSelectMulti } from "../../../../Components/Selects/CloudAnalytics/AttributionSelectMulti";
import { useAttributionsContext } from "../../../../Context/AttributionsContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import AttributionCreateDialog from "../../../../Pages/CloudAnalytics/attributions/attributionBuilder/AttributionCreateDialog";
import { useAnalyticsContext } from "../../../../Pages/CloudAnalytics/CloudAnalyticsContext";
import { type BudgetInfo } from "../../../../types";

type Props = {
  budgetInfo: BudgetInfo;
  setBudgetInfo: Dispatch<SetStateAction<BudgetInfo>>;
  isCurrentUserEditor: boolean;
};

const ScopeSelector = ({ budgetInfo, setBudgetInfo, isCurrentUserEditor }: Props) => {
  const { filteredAttributions: attributions } = useAttributionsContext();
  const { customer: genuineCustomer } = useCustomerContext();
  const { hasDataHub } = useAnalyticsContext();
  const [newAttributionDialogOpen, setNewAttributionDialogOpen] = useState(false);
  const [newAttributionId, setNewAttributionId] = useState("");

  useEffect(() => {
    if (newAttributionId) {
      const attribution = attributions.find((a) => a.ref.id === newAttributionId);
      if (attribution) {
        setBudgetInfo((prev) => ({ ...prev, scope: prev.scope ? [...prev.scope, attribution] : [attribution] }));
      }
      setNewAttributionId("");
    }
  }, [attributions, newAttributionId, setBudgetInfo]);

  if (budgetInfo.scope.length === 0) {
    return null;
  }
  return (
    <Stack>
      <AttributionSelectMulti
        attributions={attributions}
        onChange={(newScope) => {
          setBudgetInfo((prev) => ({ ...prev, scope: newScope }));
        }}
        scope={budgetInfo.scope}
        sx={{ width: "100%" }}
        disabled={!isCurrentUserEditor}
        textFieldProps={{
          required: false,
          label: budgetText.BUDGET_SCOPE,
          placeholder: budgetText.SELECT_ATTRIBUTION,
          // InputLabelProps - needed to override the default shrink behavior of the label
          InputLabelProps: {},
        }}
      />
      <Stack direction="row" mt={1} mb={1} divider={<Divider orientation="vertical" sx={{ height: "auto", mr: 2 }} />}>
        <DataHubCheckbox
          checked={budgetInfo.dataSource === AnalyticsDataSource.BILLING_DATAHUB}
          customerId={genuineCustomer.id}
          dataSource={budgetInfo.dataSource}
          disabled={!isCurrentUserEditor || !hasDataHub}
          onChange={(_, checked) => {
            setBudgetInfo((prev) => ({
              ...prev,
              dataSource: checked ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING,
            }));
          }}
          showClouds={false}
        />
        <AttributionCreateDialog
          onCancel={() => {
            setNewAttributionDialogOpen(false);
          }}
          onSuccess={setNewAttributionId}
          open={newAttributionDialogOpen}
        >
          <Link
            color="inherit"
            variant="caption"
            underline="always"
            href="#"
            onClick={() => {
              setNewAttributionDialogOpen(true);
            }}
          >
            {attributionText.CREATE_NEW_ATTRIBUTION}
          </Link>
        </AttributionCreateDialog>
      </Stack>
    </Stack>
  );
};

export default ScopeSelector;
