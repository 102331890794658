import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { domainConfig } from "../../../domainConfig";
import { isPartnerDomain } from "../../../utils/domains";
import getLogo from "../../../utils/logos/logo";

const LoginLogo = () => {
  const { logo, logoWhite } = getLogo();
  const isDarkMode = useDarkThemeCheck();
  let src = isDarkMode ? logo : logoWhite;

  if (isPartnerDomain()) {
    src = domainConfig.assets.logoURL;
  }
  return <img src={src} alt={domainConfig.companyName} width="100px" height="100%" />;
};

export default LoginLogo;
