import { useMemo } from "react";

import { AccountManagerCompanies } from "@doitintl/cmp-models";
import { Avatar, Card, Link } from "@mui/material";

import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { getAccountTeamRole } from "../../utils/common";
import { SkeletonCard } from "./SkeletonCard";
import { type WidgetItemProps } from "./types";
import { WidgetCardContentWithTable } from "./WidgetCards/Common/WidgetCardContentWithTable";

const AccountManagerCompaniesEnum = {
  [AccountManagerCompanies.GCP]: "Google",
  [AccountManagerCompanies.AWS]: "Amazon",
};

export default function AccountManagersCard({ widgetHeight = 200 }: WidgetItemProps) {
  const [roles] = AccountManagersHooks.useAccountManagersRoles();
  const accountManagers = AccountManagersHooks.useAccountManagersForCustomer();

  const rows = useMemo(
    () =>
      accountManagers?.map((row) => ({
        id: row.id,
        name: row.name,
        email: row.email,
        role: getAccountTeamRole(roles ?? [], row),
        company: AccountManagerCompaniesEnum[row.company] || "",
        photoURL: row.photoURL,
      })) || [],
    [accountManagers, roles]
  );

  const columns = useMemo(
    () => [
      {
        field: "photoURL",
        headerName: "",
        renderCell: (params) =>
          params.value ? (
            <Avatar
              alt={params.row.name}
              src={params.value}
              sx={{
                width: 28,
                height: 28,
                fontSize: 14,
              }}
            />
          ) : (
            <Avatar
              alt={params.row.name}
              sx={{
                width: 28,
                height: 28,
                fontSize: 14,
                backgroundColor: params.row.company === "Google" ? "#4285f4" : "#ff9900",
              }}
            >
              {params.row.name
                .split(" ")
                .map((item, idx) => (idx < 2 ? item[0] : ""))
                .join("")}
            </Avatar>
          ),
      },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        renderCell: (params) =>
          params.row.email ? (
            <Link href={`mailto:${params.row.email}`} color="inherit" sx={{ textDecoration: "none" }}>
              {params.value}
            </Link>
          ) : (
            params.value
          ),
      },
      {
        field: "role",
        headerName: "Role",
      },
    ],
    []
  );

  if (accountManagers === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  return (
    <Card>
      <WidgetCardHeader title="Essential Contacts" subheader="List of essential contacts for your company" />
      <WidgetCardContentWithTable height={widgetHeight} rows={rows} columns={columns} />
    </Card>
  );
}
