export const getCachingKeys = (customerId: string | undefined | null) => {
  if (!customerId) {
    return [];
  }

  return [customerId];
};

export const getUserCachingKeys = (userId: string | undefined | null) => {
  if (!userId) {
    return [];
  }

  return [`userId:${userId}`];
};
