import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import AnnounceKit from "announcekit-react";
import axios from "axios";
import noop from "lodash/noop";

import { useAuthContext } from "../Context/AuthContext";
import { paths } from "../Pages/Login/helpers";
import mixpanel from "./mixpanel";
import { useNotificationsPermission } from "./useNotificationsPermission";

type AnnounceKitContextType = {
  openAnnounceKit: () => void;
  unread?: number;
};

const announceKitContext = createContext<AnnounceKitContextType>({ openAnnounceKit: noop });

type AnnounceKitMethods = {
  open(): void;
  unread(): Promise<number>;
};

export const useAnnounceKit = () => useContext(announceKitContext);

export const useAnnounceKitToken = () => {
  const { tokenValue } = useAuthContext();
  const [announcekitToken, setAnnouncekitToken] = useState<string | null>(null);

  useEffect(() => {
    if (!tokenValue) {
      return;
    }

    axios
      .request({
        method: "get",
        url: paths.authAnnounceKit,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      })
      .then((response) => {
        setAnnouncekitToken(response.data.token);
      });
  }, [tokenValue]);

  return { announcekitToken };
};

export const AnnounceKitProvider = ({ children }: { children?: ReactNode }) => {
  const { currentUser } = useAuthContext();
  const { requestNotificationPermission } = useNotificationsPermission();

  const announceKitRef = useRef<AnnounceKitMethods>();
  const { announcekitToken } = useAnnounceKitToken();
  const [unread, setUnread] = useState<number>();

  useEffect(() => {
    if (!announcekitToken) {
      return;
    }

    announceKitRef.current?.unread().then((unread) => {
      setUnread(unread);
    });
  }, [announcekitToken]);

  const openAnnounceKit = useCallback(async () => {
    await requestNotificationPermission();

    if (announceKitRef.current) {
      announceKitRef.current.open();

      mixpanel.track("app.whatsnew");
    }
  }, [requestNotificationPermission]);

  const memoValue = useMemo(() => ({ openAnnounceKit, unread }), [openAnnounceKit, unread]);
  const onWidgetUnread = useCallback((widget: any, unread: number) => {
    setUnread(unread);
  }, []);

  return (
    <announceKitContext.Provider value={memoValue}>
      <AnnounceKit
        ref={announceKitRef}
        widget="https://announcekit.app/widgets/v2/2CpiTe"
        name="notificationTrigger"
        customConfig={{
          user_token: announcekitToken,
        }}
        boosters={false}
        onWidgetUnread={onWidgetUnread}
        user={
          currentUser
            ? {
                id: currentUser.uid,
                email: currentUser.email,
                name: currentUser.displayName,
              }
            : undefined
        }
      >
        {children}
      </AnnounceKit>
    </announceKitContext.Provider>
  );
};
