/**
 * EC2 instance types follow the format {family}{generation}{attribute}.{size}
 * https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/instance-types.html#instance-hardware-specs
 * for example: m5.2xlarge -> family: m
 */
const instanceFamily = {
  mac: "General Purpose",
  t: "General Purpose",
  m: "General Purpose",
  a: "General Purpose",
  c: "Compute Optimized",
  Hpc: "Compute Optimized",
  r: "Memory Optimized",
  x: "Memory Optimized",
  z: "Memory Optimized",
  "u-": "Memory Optimized",
  p: "Accelerated",
  dl: "Accelerated",
  inf: "Accelerated",
  g: "Accelerated",
  f: "Accelerated",
  vt: "Accelerated",
  i: "Storage Optimized",
  Im: "Storage Optimized",
  Is: "Storage Optimized",
  d: "Storage Optimized",
  h: "Storage Optimized",
};

/**
 * Returns the family of the AWS EC2 instance type, e.g. General Purpose, Compute Optimized, etc.
 * https://aws.amazon.com/ec2/instance-types/
 * @param instanceType AWS EC2 instace type, e.g. m5.2xlarge
 */
export function getInstanceTypeFamily(instanceType: string): string {
  return instanceFamily[instanceType?.split(/\d/)[0]] || "Other";
}
