import { useShouldShowOnboardingCards } from "../../../Components/Dashboard/CloudConnection/hooks";
import WelcomeBlock from "../../../Components/Dashboard/CloudConnection/SaaSDashboard/WelcomeBlock";
import { CloudSelector } from "./CloudSelector";
import { StandaloneCloudSelector } from "./StandaloneCloudSelector";
import { WelcomeBlockWrapper } from "./WelcomeBlockWrapper";

export const WelcomeSection = () => {
  const { shouldShowStandaloneCloudSelector, shouldShowSaasCloudSelector } = useShouldShowOnboardingCards();
  if (shouldShowSaasCloudSelector) {
    return <CloudSelector />;
  }

  if (shouldShowStandaloneCloudSelector) {
    return <StandaloneCloudSelector />;
  }

  return <WelcomeBlock wrapper={WelcomeBlockWrapper} />;
};
