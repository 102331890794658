import { useMemo } from "react";

import { type AutocompletePlugin, type AutocompleteSource, getAlgoliaResults } from "@algolia/autocomplete-js";
import { parseAlgoliaHitHighlight } from "@algolia/autocomplete-preset-algolia";
import { type Hit } from "@algolia/client-search";

import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { type AlgoliaCustomContext, type AlgoliaIndexType, type CustomerHit } from "../types";
import { getAlgoliaAnalyticsProps } from "../utils";

const createCustomersPlugin = (): AutocompletePlugin<CustomerHit, undefined> => ({
  getSources({ query, state }) {
    const context = state.context as AlgoliaCustomContext;

    if (!query || context.customerFromQuery) {
      return [];
    }

    const customerSource: AutocompleteSource<CustomerHit> = {
      sourceId: "customers",
      onSelect({ item, event }) {
        if (query.startsWith("@") && event.key === "Enter" && context.isDoitEmployee) {
          return;
        }
        const url = `/customers/${item.objectID}`;
        context.onSelectRow({
          id: item.objectID,
          label: item.name || "",
          avatarLogo: item.logo,
          avatarFallback: item.name?.substring(0, 2).toUpperCase(),
          highlightedTitle: [{ value: item.name || "", isHighlighted: false }],
          highlightedSubtitle: [{ value: item.primaryDomain, isHighlighted: false }],
          url,
          withMetaKey: event.metaKey,
        });
      },
      getItems({ query }) {
        return getAlgoliaResults({
          searchClient: context.searchClient,
          queries: [
            {
              indexName: "customers",
              params: {
                query,
                hitsPerPage: 10,
                ...getAlgoliaAnalyticsProps(context),
              },
            },
          ],
          transformResponse({ hits }: { hits: Hit<CustomerHit>[][] }) {
            return hits;
          },
        });
      },
      getItemUrl({ item }) {
        return `/customers/${item.objectID}`;
      },
      templates: {
        item({ item }) {
          const highlightedTitle = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["name"],
          });
          const highlightedSubtitle = parseAlgoliaHitHighlight({
            hit: item,
            attribute: ["primaryDomain"],
          });
          return (
            <AlgoliaResultListItem
              highlightedTitle={highlightedTitle}
              highlightedSubtitle={highlightedSubtitle}
              avatarLogo={item.logo}
              avatarFallback={item.name?.substring(0, 2).toUpperCase()}
            />
          );
        },
      },
    };
    return [customerSource];
  },
});

export const useCustomersPlugin = (
  restrictedIndices?: AlgoliaIndexType[]
): AutocompletePlugin<CustomerHit, undefined> | null =>
  useMemo(() => {
    if (restrictedIndices?.includes("customers")) {
      return null;
    }
    return createCustomersPlugin();
  }, [restrictedIndices]);
