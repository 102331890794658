import React, { useState } from "react";

import { Link as InternalLink } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Button, Card, CardMedia, Link, Stack, type StackTypeMap, TextField, Typography } from "@mui/material";
import { type OverridableComponent } from "@mui/material/OverridableComponent";
import { useClipboard } from "use-clipboard-copy";

import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { Loader } from "../../../Components/Loader";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { DeleteInstanceConfigModal } from "./DeleteInstanceConfigModal";
import JiraLogoBlue from "./logos/mark-gradient-blue-jira-software.svg";
import JiraLogoWhite from "./logos/mark-gradient-white-jira-software.svg";
import { useJiraApi } from "./useJiraApi";

const IconHighlightBox: OverridableComponent<
  StackTypeMap<
    {
      icon: React.ReactNode;
      text: string;
      isEditable?: boolean;
      editAction?: () => void;
    },
    "div"
  >
> = ({ icon, text, isEditable = false, editAction = () => {}, ...props }) => {
  const { sx, ...otherProps } = props;
  const isDarkMode = useDarkThemeCheck();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.04)" : "rgba(0, 0, 0, 0.04)",
        maxWidth: "800px",
        maxHeight: "56px",
        borderRadius: 1,
        p: 2,
        ...sx,
      }}
      {...otherProps}
    >
      {icon}
      <Typography
        variant="body1"
        sx={{
          font: "500 90%/1 Roboto Mono,monospace",
          flex: 1,
          whiteSpace: "normal",
          wordWrap: "break-word",
          wordBreak: "break-all",
        }}
      >
        {text}
      </Typography>
      {isEditable && <Button onClick={editAction}>Edit</Button>}
    </Stack>
  );
};

export const Jira = () => {
  const isDarkMode = useDarkThemeCheck();

  const clipboard = useClipboard({
    copiedTimeout: 2000,
  });

  const { jiraInstanceData, createJiraInstance } = useJiraApi();
  const errorSnackbar = useErrorSnackbar();

  const [url, setUrl] = useState("");
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const { customer } = useCustomerContext();

  const [isEditing, setIsEditing] = useState(false);
  const [editingURL, setEditingURL] = useState("");

  const [isDeleteInstanceConfigModalVisible, setIsDeleteInstanceConfigModalVisible] = useState(false);

  const submit = async () => {
    try {
      setIsCreateLoading(true);

      await createJiraInstance(url);

      setIsCreateLoading(false);
      setIsEditing(false);
    } catch (error: any) {
      consoleErrorWithSentry(error);
      setIsCreateLoading(false);
      errorSnackbar(`Error encountered while saving: ${error?.response?.data?.error || "unknown error"}`);
    }
  };

  const edit = async () => {
    try {
      setIsEditing(true);
      setEditingURL(jiraInstanceData?.url ?? "");
    } catch (error: any) {
      consoleErrorWithSentry(error);
      errorSnackbar(`Error encountered: ${error?.response?.data?.error || "unknown error"}`);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h1" sx={{ mb: 1 }}>
          Jira
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
          Connect your Jira instance to use DoiT Threads
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, maxWidth: "800px" }}>
          The Jira integration allows you to link DoiT Threads - such as Insights - to Jira issues. As issues in Jira
          are updated, their statuses are reflected as threads in DoiT Cloud Navigator and vice-versa. This helps your
          organization keep track of the work related to your cloud cost management.
        </Typography>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Connection settings
        </Typography>
        <Loader loading={jiraInstanceData === undefined}>
          {!jiraInstanceData || isEditing ? (
            <>
              <Typography variant="body1" sx={{ mb: 3 }}>
                To get started, enter the URL of your Jira Cloud instance:
              </Typography>

              <Stack gap={2} direction="row" alignItems="flex-start" sx={{ maxWidth: "880px" }}>
                <TextField
                  variant="outlined"
                  label="URL of Jira Cloud instance"
                  helperText="If you don't know the URL of your Jira instance, contact your Jira Administrator."
                  onChange={(event) => {
                    setUrl(event.target.value);
                  }}
                  sx={{ flexGrow: 1 }}
                  disabled={isCreateLoading}
                  placeholder="https://your-site.atlassian.net"
                  defaultValue={editingURL}
                />

                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={isCreateLoading}
                  disabled={!url || url === editingURL}
                  mixpanelEventId="integrations.jira.create"
                  onClick={submit}
                  sx={{ height: "40px" }}
                >
                  Connect to Jira
                </LoadingButton>
              </Stack>
            </>
          ) : jiraInstanceData.status === "connected" ? (
            <>
              <Card sx={{ display: "flex", p: 2, gap: 2, mb: 3, maxWidth: "840px", alignItems: "center" }}>
                <CardMedia component="img" image={isDarkMode ? JiraLogoWhite : JiraLogoBlue} sx={{ width: "48px" }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="body1" color="text.secondary" sx={{ mb: 0.5 }}>
                    Authorized Jira instance
                  </Typography>
                  <Typography variant="subtitle2">{jiraInstanceData.url}</Typography>
                </Box>
                <Button
                  variant="outlined"
                  component={Button}
                  onClick={() => {
                    setIsDeleteInstanceConfigModalVisible(true);
                  }}
                  sx={{ ml: "auto" }}
                >
                  Disconnect
                </Button>
              </Card>
              <Stack direction="row" gap={1} color="success.main" sx={{ mb: 3 }}>
                <CheckIcon sx={{ fontSize: "16px", mt: 0.5 }} />
                <Typography variant="body1">
                  Your Jira instance is successfully connected to DoiT Cloud Navigator.
                </Typography>
              </Stack>
              <Button variant="contained" component={InternalLink} to={`/customers/${customer.id}/insights`}>
                Continue to Insights
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 3 }}>
                To get started, enter the URL of your Jira Cloud instance:
              </Typography>
              <IconHighlightBox
                icon={<img src={isDarkMode ? JiraLogoWhite : JiraLogoBlue} alt="Jira logo" />}
                text={jiraInstanceData.url}
                sx={{ mb: 3 }}
                isEditable
                editAction={edit}
              />
              <Typography variant="body1" sx={{ mb: 3, maxWidth: "800px" }}>
                To complete the integration, install the{" "}
                <Link
                  href="https://marketplace.atlassian.com/apps/1234787/doit-cloud-navigator"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DoiT Cloud Navigator Jira app
                </Link>{" "}
                in your Jira instance. After the app has been added to your Jira instance, select "Get started" and
                enter the following installation code:
              </Typography>
              <IconHighlightBox
                icon={
                  clipboard.copied ? (
                    <CheckIcon color="success" />
                  ) : (
                    <ContentCopyIcon
                      onClick={() => {
                        clipboard.copy(jiraInstanceData.installationCode);
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  )
                }
                text={jiraInstanceData.installationCode}
                sx={{ mb: 3 }}
              />
              <Typography variant="body1">
                If you do not have permission to do so, contact your Jira Administrator.
              </Typography>
            </>
          )}
        </Loader>
      </Box>
      {isDeleteInstanceConfigModalVisible && (
        <DeleteInstanceConfigModal
          jiraInstance={jiraInstanceData?.url || ""}
          onClose={() => {
            setIsDeleteInstanceConfigModalVisible(false);
          }}
        />
      )}
    </>
  );
};
