import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Box, ListSubheader, Typography } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { header } from "../../../../constants/cypressIds";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import HeaderMenuItem from "../HeaderMenuItem";
import { useAvatar } from "../hooks";
import { useMenuItems } from "./MenuItems/useMenuItems";
import { useAvatarLink } from "./useAvatarLink";

const useStyles = makeStyles((theme: Theme) => ({
  listSubHeader: {
    textDecoration: "none",
  },
  avatarImage: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `1px solid ${theme.palette.text.primary}`,
  },
  menuAvatarImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    border: `1px solid ${theme.palette.text.secondary}`,
  },
}));

type AvatarMenuProps = {
  onSignOut: () => void;
  hideDomainName?: boolean;
  disableProfileLink?: boolean;
};
const AvatarMenu = ({ onSignOut, hideDomainName = false, disableProfileLink = false }: AvatarMenuProps) => {
  const classes = useStyles();
  const { customer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee, currentUser, userId } = useAuthContext({ mustHaveUser: true });
  const { avatarSrc, initials } = useAvatar(currentUser);
  const displayName = currentUser.displayName || "";
  const customerId = customer?.id;
  const primaryDomain = customer?.primaryDomain;
  const menuItems = useMenuItems({ onSignOut });
  const avatarLink = useAvatarLink({ isDoitEmployee, customerId, userId });

  return (
    <HeaderMenuItem
      placement="top-end"
      innerMenuItemComponent={
        <Box display="flex" alignItems="center">
          <Box pr={1}>
            <Avatar alt={displayName} src={avatarSrc} className={classes.avatarImage} data-cy={header.avatar}>
              {initials}
            </Avatar>
          </Box>
          {!hideDomainName && (
            <Typography variant="subtitle2" data-cy={header.companyName}>
              {primaryDomain}
            </Typography>
          )}
          <Box pl={0.5} display="flex" alignItems="center">
            <ExpandMoreIcon />
          </Box>
        </Box>
      }
      subheader={
        <ListSubheader
          className={classes.listSubHeader}
          disableGutters
          disableSticky
          {...(disableProfileLink
            ? {}
            : {
                component: Link,
                to: avatarLink,
              })}
        >
          <Box display="flex" py={1} px={2} alignItems="center">
            <Avatar alt={displayName} src={avatarSrc} className={classes.menuAvatarImage}>
              {initials}
            </Avatar>
            <Box pl={1} display="flex" flexDirection="column">
              <Typography variant="body2" noWrap color="textPrimary">
                {displayName}
              </Typography>
              <Typography variant="caption" noWrap gutterBottom color="textSecondary">
                {currentUser.email}
              </Typography>
            </Box>
          </Box>
        </ListSubheader>
      }
      items={menuItems}
      id="avatar-menu"
    />
  );
};

export default AvatarMenu;
