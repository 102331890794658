export const helpURLs = {
  ACH_PAYMENTS: "https://help.doit.com/invoices-and-payments/setting-up-ach-payments",
  ADD_AWS_IAM_ROLE: "https://help.doit.com/amazon-web-services/link-account",
  CONNECT_BILL_COM: "https://help.bill.com/hc/en-us/articles/360000024826-Connecting-by-Payment-Network-ID",
  CONNECT_GCP_SA: "https://help.doit.com/google-cloud/connect-google-cloud-service-account",
  GENERAL_HELP: "https://help.doit.com",
  GRANT_AWS_ACCESS: "https://help.doit.com/tickets/aws-access",
  GRANT_GCP_ACCESS: "https://help.doit.com/tickets/gcp-access",
  INVITE_AWS_ACCOUNT: "https://help.doit.com/amazon-web-services/invite-existing-aws-account",
  GKE_HELP: "https://help.doit.com/cloud-analytics/gke-cost-analytics",
  PUBLIC_DASHBOARD: "https://help.doit.com/dashboards/accessing-a-public-dashboard",
  SPOT_SCALING: "https://help.doit.com/docs/spot-scaling/overview",
  SQ_TEAM_CACHING: "https://help.superquery.io/team-management/team-caching-firebase",
  TRANSFER_PROJECTS:
    "https://help.doit.com/docs/google-cloud/transfer-google-cloud-projects/bulk-transfer-using-wizard#troubleshooting",
  CLOUD_ANALYTICS_ATTRIBUTIONS: "https://help.doit.com/docs/cloud-analytics/attributing-cloud-spend",
  CLOUD_ANALYTICS_METRICS: "https://help.doit.com/docs/cloud-analytics/custom-metrics",
  CLOUD_ANALYTICS_ALERTS_CREATE: "https://help.doit.com/docs/governance/alerts/create-alert",
};

export const globalURLs = {
  CMP_HOME: "https://console.doit.com",
  CMP_STATUS_PAGE: "https://status.doit.com",
  CRONTAB_GURU: "https://crontab.guru",
  BILLING_CHANGE_PROJECT:
    "https://cloud.google.com/marketplace/docs/understanding-billing#changing_a_projects_billing_account",
  REGEXP_HELP: "https://github.com/google/re2/wiki/Syntax",
};

export const reportsHelpURLs = {
  ViSpueEq1iXNMcL1n6Un: "https://help.doit.com/docs/dashboards/pulse#historical-costs-and-forecast",
  Anl2FHDAgyxR4GFellrA: "https://help.doit.com/docs/dashboards/pulse#this-month-vs-last",
  KKi6lAX4N66XYCVAdqRD: "https://help.doit.com/docs/dashboards/pulse#services-breakdown",
  pj0ijucXgFTXfdV8QIWY: "https://help.doit.com/docs/dashboards/pulse#cost-by-service",
  mrV8lKa7USq4NFVsPIhd: "https://help.doit.com/docs/dashboards/pulse#cost-by-scope",
  V0CF0boimYYMogypi0CO: "https://help.doit.com/docs/dashboards/pulse#trending-services",
  ss2m7rGY0OjuPDyJub6g: "https://help.doit.com/docs/dashboards/aws-lens",
  FLEXSAVE_AWS: "https://help.doit.com/docs/dashboards/aws-lens#flexsave-aws",
  PkFeSHlhADRTDKeDcLnM: "https://help.doit.com/docs/dashboards/aws-lens#cost-by-service",
  mfeCSTBRGjhUoArSx2mg: "https://help.doit.com/docs/dashboards/aws-lens#cost-by-top-aws-accounts",
  ttwTNnZNnKfUb1SZvRY8: "https://help.doit.com/docs/dashboards/aws-lens#cost-by-region",
  "0nA7QVOTrdfzQlR0gUaS": "https://help.doit.com/docs/dashboards/aws-lens#s3-cost-by-bucket",
  hUCSrxdd14OhhMvyprHT: "https://help.doit.com/docs/dashboards/aws-lens#s3-by-operation-type",
  V9k8oNiDMF4iy4uCb6JY: "https://help.doit.com/docs/dashboards/aws-lens#ebs-by-volume-type",
};
