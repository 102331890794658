import { Box, Grid, Typography } from "@mui/material";
import round from "lodash/round";

import CountUpCard from "../../../../Components/CountUp/CountUpCard";
import { useCurrency } from "../../../../Components/hooks/useCurrency";
import { type MonthlyAsgUsage } from "../../types";

type Props = {
  usage: MonthlyAsgUsage | undefined;
  textPrefix: string;
};

function generateHoursDetails(hours: number) {
  return (
    <Box pl={0.8}>
      <Typography variant="h5">{`/ ${round(hours, 0)} hrs`}</Typography>
    </Box>
  );
}

export const MonthUsage = ({ usage, textPrefix }: Props) => {
  const { customerCurrencySymbol } = useCurrency();

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <CountUpCard
          number={usage?.onDemandInstances.totalCost ?? 0}
          prefix={customerCurrencySymbol}
          duration={1}
          decimals={2}
          text="On-demand cost / hours used"
          childRightToText={generateHoursDetails(usage?.onDemandInstances.totalHours ?? 0)}
        />
      </Grid>
      <Grid item xs={4}>
        <CountUpCard
          number={usage?.totalSavings ?? 0}
          prefix={customerCurrencySymbol}
          duration={1}
          decimals={2}
          text={`${textPrefix} Savings`}
        />
      </Grid>
      <Grid item xs={4}>
        <CountUpCard
          number={usage?.spotInstances.totalCost ?? 0}
          prefix={customerCurrencySymbol}
          duration={1}
          decimals={2}
          text="Spot cost / hours used"
          childRightToText={generateHoursDetails(usage?.spotInstances.totalHours ?? 0)}
        />
      </Grid>
    </Grid>
  );
};
