import React, { type ChangeEvent } from "react";

import { Alert, MenuItem, TextField } from "@mui/material";

import { type JiraIssueType, type JiraProject } from "../types";
import { LoadingBox } from "./LoadingBox";

type Props = {
  isLoading: boolean;
  isError: boolean;
  issueTypes?: JiraIssueType[];
  selectedProject: JiraProject | null;
  selectedIssueType: JiraIssueType | null;
  setSelectedIssueType: React.Dispatch<React.SetStateAction<JiraIssueType | null>>;
};

export const IssueTypeSelect = ({
  isLoading,
  isError,
  issueTypes,
  selectedProject,
  selectedIssueType,
  setSelectedIssueType,
}: Props) => {
  const handleIssueTypeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // This must exist, as we're rendering the select based on the issue type list
    const changedIssueType = issueTypes!.find((issueType) => issueType.id === event.target.value)!;
    setSelectedIssueType(changedIssueType);
  };

  if (isLoading) {
    return <LoadingBox />;
  }

  if (isError) {
    return (
      <Alert severity="error">
        An error occurred while fetching issue types from your Jira instance. Please try again later.
      </Alert>
    );
  }

  if (issueTypes!.length === 0) {
    return (
      <Alert severity="warning">
        We didn't find any usable issue types in the "{selectedProject?.name}" project in your Jira instance. Please
        select a different project, or associate a common issue type to this project (e.g. "Task").
      </Alert>
    );
  }

  return (
    <TextField
      label="Issue type"
      select
      variant="outlined"
      fullWidth
      onChange={handleIssueTypeChange}
      sx={{ mb: 2 }}
      value={selectedIssueType?.id || ""}
    >
      {issueTypes!.map((issueType) => (
        <MenuItem key={issueType.id} value={issueType.id}>
          {issueType.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
