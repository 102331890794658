import { useState } from "react";

import { isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import {
  type ComparisonOperator,
  ConditionExpressionType,
  type ReferencedNodeValue,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { Dialog, DialogTitle } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

import { cloudflowTexts } from "../../../../../assets/texts";
import { type NodeWitOutputModel } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { type AddActionParams, type EditActionParams } from "../types";
import { FilterDialogForm } from "./FilterDialogForm";

export type FilterDialogBaseProps = {
  open: boolean;
  groupIndex: number;
  selectedNode: NodeWitOutputModel | undefined;
  referenceableNodes: NodeWitOutputModel[];
  handleClose: () => void;
  initialValue?: any;
  initialFieldReference?: ReferencedNodeValue;
  initialOperator?: ComparisonOperator | null;
};

interface AddModeProps extends FilterDialogBaseProps {
  mode: "add";
  handleAction: (params: AddActionParams) => void;
}

interface EditModeProps extends FilterDialogBaseProps {
  mode: "edit";
  conditionIndex: number;
  handleAction: (params: EditActionParams) => void;
}

type FilterDialogProps = AddModeProps | EditModeProps;

interface FilterDialogFormValues {
  fieldReference: ReferencedNodeValue | null;
  selectedOperator: ComparisonOperator | null;
  value: string;
}
export const defaultSchema = Yup.object().shape({
  fieldReference: Yup.object().required(cloudflowTexts.FIELD_REFERENCE_REQUIRED),
  selectedOperator: Yup.string().nullable().required(cloudflowTexts.OPERATOR_REQUIRED),
});
export const FilterDialog: React.FC<FilterDialogProps> = (props) => {
  const {
    open,
    groupIndex,
    handleClose,
    handleAction,
    selectedNode,
    referenceableNodes,
    initialValue = null,
    initialOperator = null,
    initialFieldReference = null,
    mode,
  } = props;
  const [nestedModel, setNestedModel] = useState<UnwrappedApiServiceModelDescriptor | undefined>(undefined);

  const [validationSchema, setValidationSchema] = useState<Yup.AnyObjectSchema>(defaultSchema);

  const initialValues: FilterDialogFormValues = {
    fieldReference: initialFieldReference,
    selectedOperator: initialOperator,
    value: initialValue,
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{mode === "add" ? cloudflowTexts.ADD_NEW_FILTER : cloudflowTexts.EDIT}</DialogTitle>
      <Formik<FilterDialogFormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          if (!nestedModel || !values.fieldReference) return;
          const fieldReference: ReferencedNodeValue = {
            ...values.fieldReference,
          };

          const commonParams = {
            groupIndex,
            fieldReference,
            conditionType: isReferencedNodeValue(values.value)
              ? ConditionExpressionType.REFERENCED
              : ConditionExpressionType.STATIC,
            operator: values.selectedOperator as ComparisonOperator,
            value: values.value,
          };

          if (mode === "edit") {
            const { conditionIndex } = props as { conditionIndex: number };

            const actionParams = {
              ...commonParams,
              conditionIndex,
            };

            handleAction(actionParams);
            handleClose();
          } else {
            handleAction(commonParams);

            handleClose();

            resetForm();
          }
        }}
      >
        <FilterDialogForm
          selectedNode={selectedNode}
          nestedModel={nestedModel}
          setNestedModel={setNestedModel}
          referenceableNodes={referenceableNodes}
          mode={mode}
          setValidationSchema={setValidationSchema}
          handleClose={handleClose}
        />
      </Formik>
    </Dialog>
  );
};
