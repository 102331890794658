import { Card, CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Preview } from "../Preview/Preview";
import { type Insight } from "../types";

export function InsightsListRow({ insight }: { readonly insight: Insight }) {
  const {
    palette: { primary },
  } = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{
        "&:hover": {
          borderColor: primary.main,
        },
      }}
    >
      <CardContent sx={{ p: 3 }}>
        <Preview key={`${insight.providerId}#${insight.key}`} insight={insight} isListView={true} />
      </CardContent>
    </Card>
  );
}
