import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { type Entity } from "../../../../../Context/customer/EntitiesContext";
import { entText } from "../GCAssetPage";

export const cypressIds = {
  BILLING_PROFILE_FIELD: "billing-profile-field",
  BILLING_PROFILE_FIELD_INPUT: "billing-profile-field-input",
  BILLING_PROFILE_FIELD_OPTION: "billing-profile-field-option",
};

function isEntityActiveOrSelectedPredicate(selectedEntityId: string): (entity: Entity) => boolean {
  return (entity: Entity) => entity.active === true || entity.id === selectedEntityId;
}

export type BillingProfileFieldProps = {
  entities: Entity[];
  selectedEntityId: string;
  onSelectedEntityChange: (entId: string) => void;
};

export const BillingProfileField = (props: BillingProfileFieldProps) => {
  const { entities, selectedEntityId, onSelectedEntityChange } = props;
  const onSelection = (e) => {
    const selected = entities.filter((ent) => ent.id === e.target.value)[0];
    selected?.id && onSelectedEntityChange(selected.id);
  };
  const entitiesOptions = entities.filter(isEntityActiveOrSelectedPredicate(selectedEntityId)).map(
    (ent) =>
      ent?.id &&
      ent?.priorityId &&
      ent?.currency && (
        <MenuItem
          key={ent.id}
          value={ent.id}
          disabled={ent.active !== true}
          data-cy={`${cypressIds.BILLING_PROFILE_FIELD_OPTION}-${ent.id}`}
        >
          {entText(ent.priorityId, ent.currency, ent.name)}
        </MenuItem>
      )
  );

  return (
    <TextField
      select
      fullWidth
      name="selectedEntity"
      label="Billing Profile"
      value={selectedEntityId}
      onChange={onSelection}
      variant="outlined"
      size="medium"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        "data-cy": cypressIds.BILLING_PROFILE_FIELD_INPUT,
      }}
      data-cy={cypressIds.BILLING_PROFILE_FIELD}
    >
      {entitiesOptions}
    </TextField>
  );
};
