import {
  type BaseSyntheticEvent,
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  type AutocompleteApi,
  type AutocompleteReshapeSource,
  type AutocompleteState,
  createAutocomplete,
  type Reshape,
} from "@algolia/autocomplete-core";
import { type AutocompletePlugin } from "@algolia/autocomplete-js";
import { type Highlighted, type RecentSearchesItem } from "@algolia/autocomplete-plugin-recent-searches/dist/esm/types";
import { type LiteClient as SearchClient } from "algoliasearch/lite";
import isEqualWith from "lodash/isEqualWith";

import { CloudflowSearchIndex } from "../../../../../Components/AlgoliaSearch/consts";
import { type AlgoliaFilters, type AlgoliaItem } from "../../../../../Components/AlgoliaSearch/types";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCloudflowPlugin } from "../plugins/cloudflow";
import { useRecentCloudflowSearchPlugin } from "../plugins/recentCloudflowSearches";
import { type BaseCloudflowHit, type RecentCloudflowSearchedItem } from "../types";

const shouldUpdateState = (state: AutocompleteState<AlgoliaItem>, prevState: AutocompleteState<AlgoliaItem>) => {
  if (state.collections.length !== prevState.collections.length || state.query !== prevState.query) {
    return true;
  }

  return !isEqualWith(prevState.collections, state.collections, (curr, prev, key) =>
    key === "source" ? true : undefined
  );
};

export const useCloudflowAlgoliaAutocomplete = ({
  searchClient,
  customHandler,
  focusedNodeId,
}: {
  searchClient?: SearchClient;
  customHandler?: (nodeId: string, item: BaseCloudflowHit) => void;
  focusedNodeId?: string;
}): [AutocompleteApi<AlgoliaItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>, AutocompleteState<AlgoliaItem>] => {
  const { userId } = useAuthContext();
  const [autocompleteState, setAutocompleteState] = useState<AutocompleteState<AlgoliaItem>>({
    collections: [],
    completion: null,
    context: {},
    isOpen: false,
    query: "",
    activeItemId: 0,
    status: "idle",
  });

  const cloudflowPluginAwsApi = useCloudflowPlugin("cloudflow_aws_api");
  const cloudflowPluginGcpApi = useCloudflowPlugin("cloudflow_gcp_api");
  const cloudflowPluginDoitApi = useCloudflowPlugin("cloudflow_doit_api");
  const recentCloudflowSearchPlugin = useRecentCloudflowSearchPlugin(userId);

  const reshape = useCallback<Reshape<AlgoliaItem>>(({ state, sourcesBySourceId }) => {
    const filters = state.context.filters as AlgoliaFilters;
    const activeShapes: AutocompleteReshapeSource<AlgoliaItem>[] = [];
    const inactiveShapes: AutocompleteReshapeSource<AlgoliaItem>[] = [];
    if (!filters || filters.allResults) {
      return Object.values(sourcesBySourceId);
    }

    CloudflowSearchIndex.forEach((index) => {
      if (filters[index]) {
        activeShapes.push(sourcesBySourceId[index]);
      }
    });

    Object.entries(sourcesBySourceId).forEach(([index, source]) => {
      if (!filters[index]) {
        inactiveShapes.push(source);
      }
    });
    return [...activeShapes, ...inactiveShapes];
  }, []);

  const onSelectRow = useCallback(
    (item: RecentCloudflowSearchedItem) => {
      recentCloudflowSearchPlugin?.data?.addItem({
        id: item.objectID,
        label: item.operationName,
        objectID: item.objectID,
        provider: item.provider,
        serviceName: item.serviceName,
        serviceNameShort: item.serviceNameShort,
        versionId: item.versionId,
        operationName: item.operationName,
      });
    },
    [recentCloudflowSearchPlugin]
  );

  const plugins = useMemo(
    () =>
      [recentCloudflowSearchPlugin, cloudflowPluginAwsApi, cloudflowPluginGcpApi, cloudflowPluginDoitApi].filter(
        (p) => p
      ) as Array<AutocompletePlugin<AlgoliaItem, BaseSyntheticEvent>>,
    [cloudflowPluginAwsApi, cloudflowPluginGcpApi, cloudflowPluginDoitApi, recentCloudflowSearchPlugin]
  );

  const autocomplete = useMemo(
    () =>
      createAutocomplete<AlgoliaItem, BaseSyntheticEvent, MouseEvent, KeyboardEvent>({
        onStateChange({ prevState, state }) {
          if (shouldUpdateState(state, prevState)) {
            setAutocompleteState(state);
          }
        },
        insights: true,
        plugins,
        defaultActiveItemId: 0,
        placeholder: "Search",
        openOnFocus: true,
        initialState: {
          context: {
            searchClient,
            onSelectRow,
            customHandler,
            focusedNodeId,
          },
        },
        reshape,
      }),
    [plugins, searchClient, onSelectRow, reshape, customHandler, focusedNodeId]
  );

  useEffect(() => {
    const recentSearched = recentCloudflowSearchPlugin?.data?.getAll() as Highlighted<RecentSearchesItem>[];
    if (
      autocompleteState.query === "" &&
      recentSearched?.length >= 0 &&
      !autocompleteState.collections.find((c) => c.source.sourceId === "recentSearches")
    ) {
      autocomplete.refresh();
    }
  }, [autocomplete, autocompleteState.collections, autocompleteState.query, recentCloudflowSearchPlugin?.data]);

  return [autocomplete, autocompleteState];
};
