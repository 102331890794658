export const metricTxt = {
  CREATE_METRIC_ERROR: "Failed to create metric",
  METRIC_NAME: "Metric name",
  METRIC_SAVE_SUCCESS: "Metric successfully saved",
  METRIC_SAVE_ERROR: "Error saving metric",
  CLONE_METRIC: "Clone Metric",
  METRIC_CLONE_SUCCESS: "Metric successfully cloned",
  METRIC_CLONE_ERROR: "Error cloning metric",
  NAME_BEFORE_SAVING: "Name before saving",
  DUPLICATE_SUCCESSFUL: "Metric duplicated successfully",
};
