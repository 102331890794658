import { TableCell } from "@mui/material";
import capitalize from "lodash/capitalize";

import { assetTypeName } from "../../../../utils/common";
import { type JobTableRow } from "./types";

const JobListRow = ({ row }: { row: JobTableRow }) => (
  <>
    <TableCell data-cy={`${row.id}-customer`}>{row.customerName}</TableCell>
    <TableCell data-cy={`${row.id}-action`}>{capitalize(row.action)}</TableCell>
    <TableCell data-cy={`${row.id}-cloud`}>{assetTypeName(row.type)}</TableCell>
    <TableCell data-cy={`${row.id}-invoice-month`}>{row.invoiceMonth}</TableCell>
    <TableCell data-cy={`${row.id}-reason`}>{row.reason}</TableCell>
    <TableCell data-cy={`${row.id}-email`}>{row.email}</TableCell>
    <TableCell data-cy={`${row.id}-cloud-task-id`}>{row.cloudTaskID}</TableCell>
    <TableCell data-cy={`${row.id}-status`}>{row.status}</TableCell>
    <TableCell data-cy={`${row.id}-comment`}>{row.comment}</TableCell>
    <TableCell data-cy={`${row.id}-created-at`}>{row.transform.createdAt}</TableCell>
    <TableCell data-cy={`${row.id}-updated-at`}>{row.transform.updatedAt}</TableCell>
  </>
);

export default JobListRow;
