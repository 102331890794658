import { type PurchaseStatus } from "@doitintl/cmp-models";
import { Chip, TableCell } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { getChipConfigByPurchaseStatus } from "../../utils/purchaseStatusUtils";

type Props = {
  status: PurchaseStatus;
};
export const WorkloadStatus = ({ status }: Props) => {
  const theme = useTheme();

  const { bgColor, textColor, text } = getChipConfigByPurchaseStatus(theme, status);
  return (
    <TableCell>
      <Chip
        label={text}
        sx={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      />
    </TableCell>
  );
};
