import React, { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, type ModelReference } from "@doitintl/models-firestore";

import { loadDocumentsByQueriesIfPossible } from "../utils/firebase";

type ContextProps = {
  customerCache: Record<string, ModelIdRef<CustomerModel>>;
  loadCustomerData: (customerIds: string[]) => Promise<void>;
};

const CustomerCacheContext = createContext<ContextProps>({
  customerCache: {},
  loadCustomerData: () => Promise.reject(new Error("CustomerCacheContext not initialized")),
});

export const useCustomerCache = (items: undefined | null | ModelReference<CustomerModel>[]) => {
  const { customerCache, loadCustomerData } = useContext(CustomerCacheContext);
  const [customers, setCustomers] = useState({});

  useEffect(() => {
    const customerIds = [...new Set(items?.map((item) => item.id))];
    loadCustomerData(customerIds).then(void 0);
  }, [items, loadCustomerData]);

  useEffect(() => {
    const relevantCustomers = {};
    items?.forEach((item) => {
      if (customerCache[item.id]) {
        relevantCustomers[item.id] = customerCache[item.id];
      }
    });
    setCustomers(relevantCustomers);
  }, [items, customerCache]);

  return customers;
};

export const CustomerCacheProvider = ({ children }: { children: ReactNode }) => {
  const [customerCache, setCustomerCache] = useState<Record<string, ModelIdRef<CustomerModel>>>({});

  const loadCustomerData = useCallback(async (customerIds: string[]) => {
    const customersDoc = await loadDocumentsByQueriesIfPossible(
      customerIds.map((id) => getCollection(CustomerModel).doc(id)),
      {
        useQueryToRetrieveDocs: true,
        idField: "id",
        refField: "ref",
      }
    );

    const newCustomerData = customersDoc.reduce(
      (memo, doc) => {
        if (!doc) {
          return memo;
        }
        memo[doc.id] = doc;
        return memo;
      },
      {} as typeof customerCache
    );

    setCustomerCache((prevCache) => ({ ...prevCache, ...newCustomerData }));
  }, []);

  return (
    <CustomerCacheContext.Provider value={{ customerCache, loadCustomerData }}>
      {children}
    </CustomerCacheContext.Provider>
  );
};
