import { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { Card, Link, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { useCustomerContext } from "../../Context/CustomerContext";
import { WidgetCardHeader } from "../../Pages/Customer/NewDashboards/WidgetsGrid/Header/WidgetCardHeader";
import { getProducts } from "../../Pages/KnownIssues/utils";
import { useGetActiveCloudIncidents } from "./hooks/useGetActiveCloudIncidents";
import { SkeletonCard } from "./SkeletonCard";
import { WidgetCardContentWithTable } from "./WidgetCards/Common/WidgetCardContentWithTable";
import type { WidgetItemProps } from "./types";

export default function CloudIncidentsCard({ fallbackComponent, widgetHeight = 200 }: WidgetItemProps) {
  const { loading, cloudIncidents } = useGetActiveCloudIncidents();
  const { customer } = useCustomerContext();
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        field: "title",
        headerName: "Title",
        flex: 1,
        renderCell: (params: any) => (
          <Link
            onClick={() => {
              history.push(`/customers/${customer.id}/known-issues/${params.row.id}`);
            }}
            sx={{ cursor: "pointer" }}
            color="inherit"
            noWrap
          >
            <Typography noWrap sx={{ fontSize: "14px" }}>
              {params.row.title}
            </Typography>
          </Link>
        ),
      },
      {
        field: "product",
        headerName: "Product",
        renderCell: (params) => getProducts(params.row),
      },
      {
        field: "date",
        headerName: "Date",
        renderCell: (params) => DateTime.fromJSDate(params.row.dateTime.toDate()).toLocaleString(DateTime.DATE_MED),
      },
    ],
    [customer, history]
  );

  const rows = useMemo(
    () =>
      cloudIncidents?.map((incident) => ({
        id: incident.id,
        title: incident.title,
        product: getProducts(incident),
        dateTime: incident.dateTime,
      })),
    [cloudIncidents]
  );

  if (loading) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (!cloudIncidents || cloudIncidents.length === 0) {
    return fallbackComponent;
  }

  return (
    <Card>
      <WidgetCardHeader title="Active cloud incidents" subheader="List of active cloud incidents" />
      {rows && columns && <WidgetCardContentWithTable height={widgetHeight} rows={rows} columns={columns} />}
    </Card>
  );
}
