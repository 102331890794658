import { type MouseEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { type CloudAnalyticsModelWidgetModel, type EmptyReportFallback, type Key } from "@doitintl/cmp-models";
import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useApiContext } from "../../../api/context";
import { reportsHelpURLs } from "../../../assets/urls";
import { useAuthContext } from "../../../Context/AuthContext";
import { useDashboardProcessingContext } from "../../../Context/DashboardProcessingContext";
import { refreshWidget } from "../../../Pages/CloudAnalytics/handlers/updateWidgets";
import { WidgetsContext } from "../../../Pages/Customer/NewDashboards/WidgetsGrid/WidgetsGrid";
import { consoleErrorWithSentry } from "../../../utils";
import { jsUcfirst } from "../../../utils/common";
import { type FirestoreTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { useCustomerId } from "../../hooks/useCustomerId";
import useUnmountEffect from "../../hooks/useUnmountEffect";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import EmptyWidgetCard from "../EmptyWidgetCard";
import { SkeletonCard } from "../SkeletonCard";
import { type WidgetItemWithWidgetIdProps } from "../types";
import { CardReportHeader } from "./CardReportHeader";
import { CloudReportCardContent } from "./CloudReportCardContent";
import { CloudReportCardMenu } from "./CloudReportCardMenu";
import { CloudReportHeaderAction } from "./CloudReportHeaderAction";
import { useEnhancedWidgetData } from "./hooks";

type Props = WidgetItemWithWidgetIdProps & {
  customEmptyReportMessage: EmptyReportFallback;
  cloudAnalyticsKey?: Key;
  enableWidgetLegend?: boolean;
  reportWithData?: CloudAnalyticsModelWidgetModel;
};

export default function CloudReportCard({
  isCustomizeMode,
  widgetId,
  customEmptyReportMessage,
  cloudAnalyticsKey,
  enableWidgetLegend,
  reportWithData = {} as CloudAnalyticsModelWidgetModel,
  widgetHeight = 500,
}: Props) {
  const api = useApiContext();
  const [, , disableAutoRefresh] = useContext(WidgetsContext);

  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const customerId = useCustomerId();
  const theme = useTheme();
  const [selectedFilter, setSelectedFilter] = useState<string>("All");
  const timeoutId = useRef<any>(-1);
  const { data, loading: dataLoading, showHeader } = useEnhancedWidgetData(widgetId, reportWithData);
  const rawReport = data?.rawReport;
  const reportHasData = Boolean(rawReport?.rawRows?.length);
  const { collaborators, description, name, isPublic, reportId, timeRefreshed } = useMemo(
    () => ({
      collaborators: rawReport?.collaborators ?? [],
      description: rawReport?.description ?? "",
      name: rawReport?.name ?? "",
      isPublic: rawReport?.isPublic ?? false,
      reportId: rawReport?.reportId ?? "",
      timeRefreshed: rawReport?.timeRefreshed,
    }),
    [rawReport]
  );
  const [refreshing, setRefreshing] = useState(false);
  const [performedAutoRefresh, setPerformedAutoRefresh] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const { refreshTime, setDashboardWidgetStates } = useDashboardProcessingContext();

  useUnmountEffect(() => {
    clearTimeout(timeoutId.current);
  });

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const hasAccessToReport = useMemo(
    () => isDoitEmployee || isPublic || collaborators?.some((collaborator) => collaborator.email === currentUser.email),
    [isDoitEmployee, isPublic, collaborators, currentUser.email]
  );

  const trackFullReport = useCallback(() => {
    if (name === "[GKE Lens] Unallocated Resources by Cluster") {
      mixpanel.track("dashboards.gke-lens.cost-allocation-clusters");
    }
    handleCloseMenu();
    mixpanel.track("dashboard.widget.launch", { name });
  }, [name]);

  const handleRefresh = useCallback(async () => {
    if (refreshing) {
      return;
    }
    setRefreshing(true);
    const [customerId, reportId] = widgetId.split("_");
    try {
      const response = await refreshWidget(api, customerId, reportId);
      if (response.status === 200) {
        setForceRender(true);
      } else {
        consoleErrorWithSentry(response);
        setForceRender(false);
      }
    } finally {
      setRefreshing(false);
    }
  }, [api, refreshing, widgetId]);

  useEffect(() => {
    if (refreshTime && Date.now() - refreshTime < 1000) {
      handleRefresh();
    }
  }, [handleRefresh, refreshTime]);

  useEffect(() => {
    if (!data && !dataLoading) {
      if (disableAutoRefresh) {
        setDashboardWidgetStates(widgetId, false);
      } else if (!performedAutoRefresh) {
        handleRefresh();
        setPerformedAutoRefresh(true);
        timeoutId.current = setTimeout(
          () => {
            setDashboardWidgetStates(widgetId, false);
          },
          2 * 60 * 1000
        );
      }
    }
  }, [handleRefresh, dataLoading, performedAutoRefresh, data, setDashboardWidgetStates, widgetId, disableAutoRefresh]);

  useEffect(() => {
    if (data) {
      setDashboardWidgetStates(widgetId, true);
      clearTimeout(timeoutId.current);
    }
  }, [data, setDashboardWidgetStates, widgetId]);

  useUpdateEffect(() => {
    setForceRender(true);
  }, [theme.palette.mode, data?.updateTime]);

  useUpdateEffect(() => {
    const t = setTimeout(() => {
      setForceRender(false);
    }, 500);

    return () => {
      clearTimeout(t);
    };
  }, [rawReport, theme.palette.mode, data?.updateTime]);

  const reportPath = `/customers/${customerId}/analytics/reports/${reportId}`;
  const helpUrl = reportsHelpURLs[reportId];

  const menu = useMemo(
    () =>
      !isCustomizeMode && showHeader ? (
        <Stack direction="row" alignItems="center">
          {refreshing ? (
            <CircularProgress disableShrink sx={{ mt: 1 }} color="primary" size={24} thickness={4.8} />
          ) : (
            <CloudReportCardMenu
              anchorEl={anchorEl}
              handleRefresh={handleRefresh}
              hasAccessToReport={hasAccessToReport}
              helpUrl={helpUrl}
              timeRefreshed={timeRefreshed as FirestoreTimestamp}
              loading={refreshing}
              open={open}
              reportPath={reportPath}
              thisReportHasData={reportHasData}
              trackFullReport={trackFullReport}
              handleCloseMenu={handleCloseMenu}
              handleOpenMenu={handleOpenMenu}
            />
          )}
        </Stack>
      ) : undefined,
    [
      isCustomizeMode,
      showHeader,
      refreshing,
      anchorEl,
      handleRefresh,
      hasAccessToReport,
      helpUrl,
      timeRefreshed,
      open,
      reportPath,
      reportHasData,
      trackFullReport,
    ]
  );

  const onChangeFilter = useCallback(
    (e) => {
      e.stopPropagation();
      setForceRender(true);
      setSelectedFilter(e.target.value);
    },
    [setForceRender, setSelectedFilter]
  );

  if (rawReport === undefined) {
    return <SkeletonCard widgetHeight={widgetHeight} />;
  }

  if (performedAutoRefresh && ((!dataLoading && data === null) || !reportHasData)) {
    return (
      <EmptyWidgetCard
        name="cloudReports"
        widgetHeight={widgetHeight}
        customMessageSettings={customEmptyReportMessage}
        widgetHeaderConfigs={{ widgetTitle: name, widgetSubtitle: jsUcfirst(description) }}
        action={menu}
      />
    );
  }

  return (
    <Card>
      {showHeader && rawReport && (
        <CardReportHeader
          action={
            <CloudReportHeaderAction
              onChangeFilter={onChangeFilter}
              selectedFilter={selectedFilter}
              rawReport={rawReport}
              cloudAnalyticsKey={cloudAnalyticsKey}
              menu={menu}
            />
          }
          name={name}
          reportPath={reportPath}
          hasAccessToReport={hasAccessToReport}
          description={description}
          hasFilter={Boolean(cloudAnalyticsKey)}
        />
      )}

      <Box py={0.5} height={widgetHeight}>
        <CloudReportCardContent
          rawReport={rawReport}
          hasAccessToReport={hasAccessToReport}
          trackFullReport={trackFullReport}
          forceRender={forceRender}
          reportPath={reportPath}
          selectedFilter={selectedFilter}
          cloudAnalyticsKey={cloudAnalyticsKey}
          enableWidgetLegend={enableWidgetLegend}
        />
      </Box>
    </Card>
  );
}
