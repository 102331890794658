import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Collapse from "@mui/material/Collapse";
import KnownIssuesWidget from "../../Components/KnownIssues/KnownIssuesWidget";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: -30,
    marginBottom: 10,
  },
}));

export default function KnownIssuesList({ issues, platform }) {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  useEffect(() => {
    let platformName = platform;
    if (platform === "google_cloud_platform") {
      platformName = "google-cloud-project";
    }
    if (issues && issues.length > 0) {
      setItems(issues?.filter((issue) => issue.platform === platformName));
    }
  }, [platform, issues]);

  if (items?.length === 0) {
    return null;
  }

  return (
    <Collapse className={classes.root} in={items?.length > 0}>
      <KnownIssuesWidget customerServices={[platform]} issues={items} />
    </Collapse>
  );
}
