import { useEffect, useState } from "react";

import { NotificationCommonFilterType } from "@doitintl/cmp-models";
import * as yup from "yup";

import { type NotificationWithGroupDescriptor } from "./useNotificationDescriptors";

export function useNotificationsFormValidation(notificationDescriptors: NotificationWithGroupDescriptor[] | undefined) {
  const [notificationSchema, setNotificationSchema] = useState<yup.ObjectSchema<object>>(yup.object());

  useEffect(() => {
    if (notificationDescriptors !== undefined) {
      setNotificationSchema(
        yup.object(
          notificationDescriptors
            .filter(({ commonFilters }) => Array.isArray(commonFilters) && commonFilters.length > 0)
            .reduce((schema, notification) => {
              const notificationFiltersSchema = notification.commonFilters?.reduce((schema, { type, required }) => {
                if (type === NotificationCommonFilterType.ATTRIBUTIONS && required) {
                  schema.attributions = yup.array(yup.object()).min(1, "At least one attribution is required");
                }
                return schema;
              }, {} as yup.ObjectShape);
              schema[notification.value] = yup.object(notificationFiltersSchema).notRequired();
              return schema;
            }, {} as yup.ObjectShape)
        )
      );
    }
  }, [notificationDescriptors]);

  return notificationSchema;
}
