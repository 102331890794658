import { DoitRole } from "@doitintl/cmp-models";
import { TableCell, Typography } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import { type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { ThreeDotsMenuCell } from "../../Flexsave/Common/TableCell/ThreeDotsMenuCell";
import { type EntitlementWithId } from "../types";

type Props = {
  row: EntitlementWithId;
  onEditEntitlementDetails: (tier: EntitlementWithId) => void;
};

const EntitlementRowItem = ({ row, onEditEntitlementDetails }: Props) => {
  const isDoitOwnerRole = useDoitRoleCheck(DoitRole.Owners);
  const actionOptions: ThreeDotsMenuOption[] = [
    {
      label: <Typography>Edit entitlement details</Typography>,
      action: () => {
        onEditEntitlementDetails(row);
      },
      key: "editEntitlementDetails",
      dataCy: "edit-entitlement-details",
    },
  ];

  return (
    <CellsWrapper>
      <TableCell>
        <Typography
          variant="body2"
          onClick={() => {
            isDoitOwnerRole ? onEditEntitlementDetails(row) : undefined;
          }}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {row.displayName}
        </Typography>
      </TableCell>
      <TableCell>{row.type}</TableCell>
      <TableCell>{row.key}</TableCell>
      <TableCell>{Array.isArray(row.limit) ? row.limit.join(", ") : row.limit || "-"}</TableCell>
      <ThreeDotsMenuCell options={actionOptions} style={{ padding: 0, textAlign: "end" }} disabled={!isDoitOwnerRole} />
    </CellsWrapper>
  );
};

export default EntitlementRowItem;
