import { useCallback, useMemo, useState } from "react";

import { Positions } from "@doitintl/cmp-models";
import { Box, List, ListItem, ListItemButton, ListItemText, Popover } from "@mui/material";

import { appcuesText, reportText } from "../../../../assets/texts";
import { type MetadataOption } from "../../../../types";
import { useReportDimensionsContext } from "../../Context";
import { limitResultsIds, type ReportAddDimension, type ReportItemRemove } from "../../utilities";
import ReportItem from "./ReportItem";
import ReportsAccordion from "./ReportsAccordion";

export type LimitResultsProps = {
  handleAddDimension: ReportAddDimension;
  handleDelete: ReportItemRemove;
  getItemTooltip: (itemDisabled: boolean) => string;
};

const type = Positions.UNUSED;

const LimitResults = ({ handleAddDimension, handleDelete, getItemTooltip }: LimitResultsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { dimensions = [] } = useReportDimensionsContext();

  const options = useMemo(() => dimensions.filter((md) => limitResultsIds.includes(md.id)), [dimensions]);

  const open = Boolean(anchorEl);
  const id = open ? "limit-results-popover" : undefined;

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onOptionClicked = useCallback(
    (option: MetadataOption) => () => {
      setAnchorEl(null);
      handleAddDimension([option], true, true)(null);
    },
    [setAnchorEl, handleAddDimension]
  );

  if (options.length <= 0) {
    return null;
  }

  const popoverComponent = (
    <Popover
      data-cy="limit-results-popover"
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
    >
      <List>
        {options.map((option) => (
          <ListItem key={`limit-results-${option.id}`} data-cy={option.id} disablePadding>
            <ListItemButton
              disabled={option._disabled || (option._visible && option._position === type)}
              onClick={onOptionClicked(option)}
            >
              <ListItemText primary={option.data.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Popover>
  );

  return (
    <ReportsAccordion
      defaultExpanded
      title={reportText.LIMIT_RESULTS_SECTION_LABEL}
      numOfItems={options.filter((option) => option._visible).length}
      appCues={appcuesText.LIMIT_RESULTS}
      popoverComponent={popoverComponent}
      popoverAnchorEl={anchorEl}
      setPopoverAnchorEl={setAnchorEl}
      disableHotKeys={true}
    >
      <Box data-cy="limit-results">
        {options.map((option) => {
          if (!option._visible || option._position !== type) {
            return null;
          }
          return (
            <ReportItem
              key={option.id}
              item={option}
              id={option.id}
              onItemRemove={handleDelete}
              handleAddDimension={handleAddDimension}
              tooltip={getItemTooltip(option._disabled)}
            />
          );
        })}
      </Box>
    </ReportsAccordion>
  );
};

export default LimitResults;
