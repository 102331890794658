import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button, Stack, Typography } from "@mui/material";

import { onboardingTxt } from "../../../../assets/texts/EKS/onboarding";
import { CopyCodeBlock } from "../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { CheckConnection } from "../../Components/CheckConnection";
import { OpenAWSConsole } from "../../Components/OpenAWSConsole";
import { useEKSApi } from "../../hooks";
import { type ClusterConfigProps } from "../ConnectionStep";

export const Kubectl = ({ clusterConfig, tab }: ClusterConfigProps) => {
  const eksApi = useEKSApi();

  return (
    <Stack spacing={4}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Typography variant="body2">
            1. Download the YAML file to your local device. You will need this to complete step 2
          </Typography>
          <Button
            startIcon={<FileDownloadIcon />}
            variant="outlined"
            onClick={() => eksApi.downloadDeploymentFile(clusterConfig)}
            sx={{ width: "fit-content" }}
          >
            Download
          </Button>
        </Stack>

        <Stack spacing={2}>
          <Typography variant="body2">
            2. After downloading the YAML file. Run the following commands on the AWS Console or a method of your choice
          </Typography>
          <OpenAWSConsole region={clusterConfig.region} />
          <Stack spacing={1}>
            <Typography variant="body2" color="text.secondary">
              {onboardingTxt.COMMON_DEPLOY_YAML(clusterConfig.deploymentFileName)}
            </Typography>
            <CopyCodeBlock base={`kubectl apply -f ${clusterConfig.deploymentFileName}`} />
          </Stack>

          {clusterConfig.isEc2Cluster && (
            <>
              <Stack spacing={1}>
                <Typography variant="body2" color="text.secondary">
                  Used to create a secret containing your AWS Access Key ID in the 'doit-eks-metrics' namespace.
                </Typography>
                <CopyCodeBlock base="kubectl create secret generic aws-access-key-id --from-literal=AWS_ACCESS_KEY_ID='Your-AWS_ACCESS_KEY_ID-here' -n doit-eks-metrics" />
              </Stack>

              <Stack spacing={1}>
                <Typography variant="body2" color="text.secondary">
                  Used to create a secret containing your AWS Secret Access Key in the 'doit-eks-metrics' namespace.
                </Typography>
                <CopyCodeBlock base="kubectl create secret generic aws-secret-access-key --from-literal=AWS_SECRET_ACCESS_KEY='Your-AWS_SECRET_ACCESS_KEY-here' -n doit-eks-metrics" />
              </Stack>

              <Stack spacing={3}>
                <Typography variant="body2">
                  3. Add a new tag with a Key="eks:cluster-name" and a Value="{clusterConfig.clusterName}" to all
                  existing and new nodes in the cluster.
                </Typography>
                <Typography variant="body2">4. Add tag name "eks:cluster-name" to Cost Allocation Tags.</Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <CheckConnection clusterConfig={clusterConfig} tab={tab} />
    </Stack>
  );
};
