import { type UserNotificationModel } from "@doitintl/cmp-models";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { type Role } from "../../../types";
import { jobFunctionsOptions, testIds, text } from "./consts";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1.5),
  },
}));

export type BulkUpdateUsersBodyProps = {
  selectedJobFunction: number;
  onJobFunctionSelect: (jobFunction: number) => void;
  selectedRole: string;
  onRoleSelect: (role: string) => void;
  selectedDashboard: string;
  onDashboardSelect: (dashboard: string) => void;
  selectedNotificationsValues: number[];
  notificationsNotToOverride: number[];
  userNotificationsOptions: UserNotificationModel[];
  onNotificationSelect: (notificationsValues: number) => void;
  roles: Role[];
  dashboards: { id: string; name: string }[];
};

export const BulkUpdateUsersBody = ({
  selectedJobFunction,
  onJobFunctionSelect,
  selectedRole,
  onRoleSelect,
  selectedDashboard,
  onDashboardSelect,
  selectedNotificationsValues,
  notificationsNotToOverride,
  userNotificationsOptions,
  onNotificationSelect,
  roles,
  dashboards,
}: BulkUpdateUsersBodyProps) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            label={text.JOB_FUNCTION_LABEL}
            select
            variant="outlined"
            fullWidth
            value={selectedJobFunction}
            onChange={(e) => {
              onJobFunctionSelect(Number(e.target.value));
            }}
            margin="dense"
            data-testid={testIds.JOB_FUNCTION}
          >
            {jobFunctionsOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-select-role"
            label={text.ROLE_LABEL}
            select
            variant="outlined"
            fullWidth
            value={selectedRole}
            onChange={(e) => {
              onRoleSelect(e.target.value);
            }}
            margin="dense"
          >
            {roles.map((option) => (
              <MenuItem key={option.id} value={option.name} data-id={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={text.DEFAULT_DASHBOARD_LABEL}
            select
            variant="outlined"
            fullWidth
            value={selectedDashboard}
            onChange={(e) => {
              onDashboardSelect(e.target.value);
            }}
            margin="dense"
          >
            {dashboards.map((option) => (
              <MenuItem key={option.id} value={option.name} data-id={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">{text.NOTIFICATIONS_SETTINGS_LABEL}</FormLabel>
        <Divider />
        <Grid container>
          {userNotificationsOptions.map((notification, index) => (
            <Tooltip key={index} title={notification.name}>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={notification.value.toString()}
                      checked={selectedNotificationsValues.includes(notification.value)}
                      indeterminate={notificationsNotToOverride.includes(notification.value)}
                      onChange={() => {
                        onNotificationSelect(notification.value);
                      }}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">{notification.name}</Typography>}
                />
              </Grid>
            </Tooltip>
          ))}
        </Grid>
      </FormControl>
    </div>
  );
};
