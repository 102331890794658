import { useCallback, useState } from "react";

import { DialogContent } from "@mui/material";

import { globalText, marketplaceText } from "../../../../assets/texts";
import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../../Components/SimpleDialog";
import { consoleErrorWithSentry } from "../../../../utils";
import { type entitlementsTableRowType } from "../types";
import { AWSEntitlementRow } from "./AWSEntitlementRow";
import { filterColumns, headerColumns } from "./columns";
import { useAWSEntitlementsTableData } from "./hooks/useAWSEntitlementsTableData";

export function AWSEntitlementsTable() {
  const AWSEntitlementsTableData = useAWSEntitlementsTableData();
  const [clickedRow, setClickedRow] = useState<entitlementsTableRowType | null>(null);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const closeDialog = useCallback(() => {
    setOpenApproveDialog(false);
    setOpenRejectDialog(false);
    setClickedRow(null);
  }, []);

  const handleRejectEntitlement = (_row: entitlementsTableRowType | null) => {
    // TODO: connect API
    consoleErrorWithSentry(marketplaceText.ENTITLEMENT_REJECT_ERROR);
    errorSnackbar(marketplaceText.ENTITLEMENT_REJECT_ERROR);
    closeDialog();
  };

  const handleApproveEntitlement = (_row: entitlementsTableRowType | null) => {
    // TODO: connect API
    successSnackbar(marketplaceText.ENTITLEMENT_APPROVED_SUCCESSFULLY);
    closeDialog();
  };

  const entitlementRowWrapper = useCallback(
    ({ row }: { row: entitlementsTableRowType }) => (
      <AWSEntitlementRow
        setOpenRejectDialog={setOpenRejectDialog}
        setOpenApproveDialog={setOpenApproveDialog}
        setClickedRow={setClickedRow}
        row={row}
      />
    ),
    []
  );

  return (
    <>
      <FilterTable<entitlementsTableRowType>
        tableItems={AWSEntitlementsTableData}
        rowComponent={entitlementRowWrapper}
        headerColumns={headerColumns}
        filterColumns={filterColumns}
        itemUniqIdentifierField="accountId"
        persistenceKey="entitlement-aws-marketplace"
        defaultSortingColumnValue="updatedAt"
        defaultSortDirection="desc"
      />
      <SimpleDialog
        open={openApproveDialog || openRejectDialog}
        title={openApproveDialog ? marketplaceText.APPROVE_AWS_ENTITLEMENT : marketplaceText.REJECT_AWS_ENTITLEMENT}
        onConfirm={() => {
          openApproveDialog ? handleApproveEntitlement(clickedRow) : handleRejectEntitlement(clickedRow);
        }}
        onCancel={closeDialog}
        confirmButtonText={openApproveDialog ? globalText.APPROVE : globalText.REJECT}
        confirmButtonCy="confirm-button"
      >
        <DialogContent sx={{ mt: "25px" }}>Are you sure?</DialogContent>
      </SimpleDialog>
    </>
  );
}
