import React, { useMemo } from "react";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { useContractFormContext } from "../../../ContractsFormContext";
import { calculateChargePerTerm } from "../../../utils";

export const CurrencySelector = () => {
  const { state, setState } = useContractFormContext();
  const options = useMemo(() => (state.tier?.price ? Object.keys(state.tier.price) : []), [state.tier?.price]);

  const onChange = ({ target: { value } }) => {
    const currency = value;
    const tierPrice = state.tier?.name === "enterprise" ? 0 : state.tier?.price?.[currency];
    const chargePerTerm = calculateChargePerTerm(state.paymentTerm, state.commitmentMonths, tierPrice);

    setState((prevState) => ({
      ...prevState,
      currency,
      chargePerTerm,
    }));
  };

  return (
    <TextField
      label="Currency"
      data-cy="currency"
      value={state.currency}
      onChange={onChange}
      disabled={state.isEditForbidden || !state.tierId}
      select
      sx={{ flexBasis: "100px", mr: 1 }}
      margin="normal"
      variant="outlined"
    >
      {!!options.length &&
        options.sort().map((currency) => (
          <MenuItem key={currency} value={currency}>
            {currency}
          </MenuItem>
        ))}
    </TextField>
  );
};
