import { type JSX, useContext } from "react";

import { Hidden, TableCell, Typography } from "@mui/material";

import { FilterTable } from "../../../../Components/FilterTable/FilterTable";
import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { type AwsAccount } from "../../data/aws-account-api";
import { AwsAccountContext } from "../../data/AwsAccountContext";
import {
  accountNameHeader,
  awsGeneratedAccountsColumns,
  awsGeneratedAccountsHeaders,
  emailHeader,
  genuineAwsAccountIdHeader,
  statusDescriptorHeader,
} from "./AwsAccountListColumns";
import { ActionsCell } from "./cells/ActionsCell";
import { StatusCell } from "./cells/StatusCell";

const AccountRow: (props: { row: AwsAccount }) => JSX.Element = ({ row }) => (
  <CellsWrapper>
    <Hidden {...accountNameHeader.hidden}>
      <TableCell>
        <Typography>{row.accountName}</Typography>
      </TableCell>
    </Hidden>
    <Hidden {...emailHeader.hidden}>
      <TableCell>
        <Typography>{row.email}</Typography>
      </TableCell>
    </Hidden>
    <Hidden {...genuineAwsAccountIdHeader.hidden}>
      <TableCell>
        <Typography>{row.genuineAwsAccountId}</Typography>
      </TableCell>
    </Hidden>
    <Hidden {...statusDescriptorHeader.hidden}>
      <TableCell>
        <StatusCell row={row} />
      </TableCell>
    </Hidden>
    <TableCell padding="checkbox">
      <ActionsCell row={row} />
    </TableCell>
  </CellsWrapper>
);

export const AwsAccountList = () => {
  const { awsAccounts } = useContext(AwsAccountContext);

  return (
    <FilterTable<AwsAccount>
      rowComponent={AccountRow}
      headerColumns={awsGeneratedAccountsHeaders}
      filterColumns={awsGeneratedAccountsColumns}
      tableItems={awsAccounts}
      defaultSortDirection="asc"
      defaultSortingColumnValue="accountName"
      data-cy="awsAccountsList"
    />
  );
};
