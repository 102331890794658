import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { DoitRole } from "@doitintl/cmp-models";
import { TableCell, Typography } from "@mui/material";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { ThreeDotsMenuCell } from "../../../Pages/Flexsave/Common/TableCell/ThreeDotsMenuCell";
import { type TierModelWithId } from "../types";

type Props = {
  row: TierModelWithId;
  onEditTierDetails: (tier: TierModelWithId) => void;
};

const TierRowItem = ({ row, onEditTierDetails }: Props) => {
  const routeMatch = useRouteMatchURL();
  const isDoitOwnerRole = useDoitRoleCheck(DoitRole.Owners);
  const pathToTier = generatePath(`${routeMatch}/:tierId`, {
    tierId: row.id ?? "",
  });

  const actionOptions: ThreeDotsMenuOption[] = [
    {
      label: <Typography>Edit tier details</Typography>,
      action: () => {
        onEditTierDetails(row);
      },
      key: "editTierDetails",
      dataCy: "edit-tier-details",
    },
    {
      label: (
        <Typography
          component={Link}
          to={{
            pathname: pathToTier,
          }}
          sx={{ textDecoration: "none", color: "inherit" }}
        >
          Edit tier entitlements
        </Typography>
      ),
      key: "editEntitlements",
      dataCy: "edit-entitlements",
    },
  ];

  return (
    <CellsWrapper>
      <TableCell>
        <Typography
          component={Link}
          to={{
            pathname: pathToTier,
          }}
          variant="body2"
          sx={{ color: "inherit" }}
        >
          {row.displayName}
        </Typography>
      </TableCell>
      <TableCell>{row.sku}</TableCell>
      <TableCell>{`$${row.price?.USD || 0}`}</TableCell>
      <TableCell>{row.packageType}</TableCell>
      <ThreeDotsMenuCell options={actionOptions} style={{ padding: 0, textAlign: "end" }} disabled={!isDoitOwnerRole} />
    </CellsWrapper>
  );
};

export default TierRowItem;
