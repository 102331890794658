import { useEffect, useState } from "react";

import { DashboardModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Cloud } from "../../constants/common";
import { makeAnomalyAttributionNoValueLabel } from "./utils";

type Props = {
  id?: string;
  platform: Cloud;
};

const getAttrName = async (attributionId: string | undefined, platform: string): Promise<string> => {
  const noNameStr = platform && typeof platform !== "undefined" ? makeAnomalyAttributionNoValueLabel(platform) : "...";
  return attributionId
    ? getCollection(DashboardModel)
        .doc("google-cloud-reports")
        .collection("attributions")
        .doc(attributionId)
        .get()
        .then((docSnap) => (docSnap.exists() && docSnap?.data()?.name) || noNameStr)
    : noNameStr;
};

export const AttributionName = ({ id, platform }: Props) => {
  const [attName, setAttName] = useState<string | null>(null);

  useEffect(() => {
    getAttrName(id, platform).then((name) => {
      setAttName(name);
    });
  }, [id, platform]);

  return <>{attName ?? "..."}</>;
};
