import { useCallback, useMemo } from "react";
import Highcharts from "highcharts";
import setWith from "lodash/setWith";
import uniq from "lodash/uniq";
import get from "lodash/get";
import { getWidgetChartOptions } from "./WidgetChartRenderer";

export default function useTreeMapChartRenderer(data, colors, theme, formatter, isWidget, isExact) {
  const isRowTotalChildOfPath = (path, rowTotal) => {
    for (let i = 0; i < path.length; i++) {
      if (path[i] !== rowTotal[i]) {
        return false;
      }
    }
    return true;
  };

  // add each node it's value if a node is parent it's value will be the sum of it's child nodes
  const getTreeStructureWithValues = useCallback(
    (treeStructure, treePathes) => {
      const treeStructureWithValues = Object.assign({}, treeStructure);
      const rowTotals = data.getRowTotals();

      treePathes.forEach((path) => {
        Object.keys(rowTotals).forEach((rowTotalKey) => {
          const key = path.split("\u0000");
          if (isRowTotalChildOfPath(key, rowTotalKey.split("\u0000"))) {
            const keyWithValue = [...key, "value"];
            setWith(
              treeStructureWithValues,
              keyWithValue,
              get(treeStructureWithValues, keyWithValue, 0) + rowTotals[rowTotalKey].sum
            );
          }
        });
      });

      return treeStructureWithValues;
    },
    [data]
  );
  // Convert row data to a tree sturcture
  const getTree = useCallback(() => {
    try {
      const treeStructure = {};
      let treePathes = [];
      for (let i = 0; i < data.rows.length; i++) {
        data.rowKeys.forEach((row) => {
          if (!row[0]) {
            return;
          }
          const key = row.slice(0, i + 1).join(".");
          const id = row.slice(0, i + 1).join("_");
          const name = row.slice(i, i + 1).join();
          treePathes.push(row.slice(0, i + 1).join("\u0000"));
          const parent = row.slice(0, i).join("_") || undefined;
          if (!get(treeStructure, key)) {
            setWith(treeStructure, row.slice(0, i + 1), { id, parent, name });
          }
        });
      }
      treePathes = uniq(treePathes);
      return getTreeStructureWithValues(treeStructure, treePathes);
    } catch (e) {
      return {};
    }
  }, [data, getTreeStructureWithValues]);

  return useMemo(() => {
    const tree = getTree();
    const seriesData = [];
    let totalValue = 0;
    Object.keys(tree).forEach((leaf) => {
      if (typeof tree[leaf] === "object") {
        totalValue += tree[leaf].value;
      }
    });
    // Turn the tree structure into a one dimension array with a recursive approach
    const appendTreeLeafToSeries = (leaf, colorIndex) => {
      seriesData.push({
        id: leaf.id,
        value: isExact ? Math.abs(leaf.value) : Math.sqrt(Math.abs(leaf.value)),
        realValue: leaf.value,
        parent: leaf.parent,
        name: leaf.name,
        drilldown: true,
        color: colors[colorIndex],
        totalSeriesValue: totalValue,
      });
      if (!Object.keys(leaf).length) {
        return;
      }
      Object.keys(leaf).forEach((key) => {
        if (typeof leaf[key] === "object") {
          appendTreeLeafToSeries(leaf[key]);
        }
      });
    };
    Object.keys(tree).forEach((leaf, i) => {
      if (typeof tree[leaf] === "object") {
        appendTreeLeafToSeries(tree[leaf], i);
      }
    });
    let options = {
      chart: {
        type: "treemap",
        backgroundColor: theme?.palette.background.paper,
        events: {
          drilldown(e) {
            Highcharts.fireEvent(this.series[0], "click", {
              point: this.get(e.point.drillId),
            });
          },
        },
      },
      drilldown: {
        activeDataLabelStyle: {
          textDecoration: "none",
          fontWeight: "400",
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      colors,
      colorByPoint: false,
      tooltip: {
        formatter() {
          const percent = Math.round((this.point.realValue / this.point.totalSeriesValue) * 10000) / 100;
          return `${this.point.name}: ${formatter(this.point.realValue, true)} (${percent}%)`;
        },
      },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          allowTraversingTree: true,
          animationLimit: 1000,
          dataLabels: {
            enabled: false,
            useHTML: true,
            formatter() {
              if (this.point.shapeArgs?.width < 100) {
                return;
              }
              let value;
              if (this.point.shapeArgs?.height > 32) {
                value = formatter(this.point.realValue, true);
              }
              return `<div style="color: ${theme.palette.getContrastText(this.point.color)}">${this.point.name}<br/>
              ${value ?? ""}</div>`;
            },
            style: {
              textAlign: "center",
              textDecoration: "none",
              textOutline: "none",
              textOverflow: "ellipsis",
              fontWeight: "400",
            },
          },
          traverseUpButton: {
            position: {
              align: "left",
              x: 10,
            },
          },
          turboThreshold: 2000,
          opacity: 0.8,
          levelIsConstant: false,
          tooltip: {
            enabled: false,
          },
          levels: [
            {
              level: 1,
              colorByPoint: true,
              dataLabels: {
                color: theme.palette.text.primary,
                enabled: true,
              },
              borderWidth: 2,
              borderColor: theme.palette.background.paper,
            },
            {
              level: 2,
              colorByPoint: false,
              dataLabels: {
                enabled: false,
              },
              borderWidth: 1,
              borderColor: theme.palette.background.paper,
            },
          ],
          animation: !isWidget,
          data: seriesData,
        },
      ],
    };
    if (isWidget) {
      options = getWidgetChartOptions(theme, options, false);
    }
    return options;
  }, [getTree, theme, colors, isWidget, isExact, formatter]);
}
