import { CloudFlowNodeType, Frequencies, type NodeConfigScheduleTriggerParameters } from "@doitintl/cmp-models";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import noop from "lodash/noop";
import { DateTime } from "luxon";

import { type RFNode } from "../../types";
import { SourceHandle } from "./Handle";
import NodeWrapper from "./NodeWrapper";

type Props = {
  id: string;
  data: RFNode<CloudFlowNodeType.TRIGGER>;
  selected?: boolean;
};

export const getDisplayName = (params: NodeConfigScheduleTriggerParameters) => {
  const { frequency, customFrequency, customFrequencyAmount, time, timeZone } = params;
  // TODO: check and update the time type as it's being saved as string in fs
  const zonedTime = DateTime.fromISO(time as unknown as string, { zone: timeZone });
  const formattedTime = zonedTime.toFormat("h:mm a");
  if ([Frequencies.Daily, Frequencies.Weekly, Frequencies.Monthly].includes(frequency)) {
    return `${frequency} at ${formattedTime}`;
  }
  if (frequency === Frequencies.Custom && customFrequency && customFrequencyAmount) {
    const customFreqDisplay = `${customFrequencyAmount} ${customFrequency}${customFrequencyAmount > 1 ? "s" : ""}`;
    return `Every ${customFreqDisplay} at ${formattedTime}`;
  }
  return `Scheduled at ${formattedTime}`;
};

export const TriggerNode = ({ id, data, selected }: Props) => {
  const title = data.nodeData.type === CloudFlowNodeType.TRIGGER ? "Trigger: Scheduled" : "Trigger: Manual";
  const getName = () => {
    if (data.nodeData.type === CloudFlowNodeType.TRIGGER) {
      return data.nodeData.parameters ? getDisplayName(data.nodeData.parameters) || "Trigger" : "Trigger";
    } else {
      return "Manual Trigger";
    }
  };

  return (
    <>
      <NodeWrapper
        title={title}
        status={data.nodeData.status || "validated"}
        onEdit={data.onEditNode}
        isActive={!!selected}
        onClick={noop}
        name={`${data.stepNumber}. ${getName()}`}
        icon={<TimerOutlinedIcon color="primary" fontSize="small" />}
      />
      <SourceHandle id={id} />
    </>
  );
};
