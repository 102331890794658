import { Feature, type MetadataCloud, type Metric, Renderer } from "@doitintl/cmp-models";

import { ResponseErrorCode } from "../../../../api/axiosClient";
import { useReportConfig, useReportContext } from "../../../../Pages/CloudAnalytics/Context";
import { type QueryResponseDetails } from "../../../../Pages/CloudAnalytics/report/ReportQuery";
import { getMetricLabel } from "../../../../Pages/CloudAnalytics/utilities";
import { type MetricWSnap } from "../../../../types";
import mixpanel from "../../../../utils/mixpanel";

const useReportMixpanel = () => {
  const { report } = useReportContext();
  const { reportConfig } = useReportConfig();

  const run = (totalDurationMs: number, cloudProviders: MetadataCloud[], details?: QueryResponseDetails) => {
    const { features, timeInterval, extendedMetric, forecastSettings } = reportConfig;

    mixpanel.track("analytics.reports.run", {
      totalDurationMs,
      reportId: report?.snapshot.id,
      reportType: report?.data.type,
      trendUp: features.includes(Feature.TREND_UP) ?? false,
      trendDown: features.includes(Feature.TREND_DOWN) ?? false,
      trendNone: features.includes(Feature.TREND_NONE) ?? false,
      forecast: !!forecastSettings,
      timeInterval,
      ...details,
      extendedMetric,
      providers: cloudProviders,
    });
  };

  const runCancel = (totalDurationMs: number) => {
    mixpanel.track("analytics.reports.run.cancel", {
      reportId: report?.snapshot.id,
      totalDurationMs,
    });
  };

  const runError = (errorCode: ResponseErrorCode, details: QueryResponseDetails) => {
    if (errorCode !== ResponseErrorCode.NETWORK_ERROR) {
      mixpanel.track("analytics.reports.error", {
        reportId: report?.snapshot.id,
        errorCode,
        ...details,
      });
    }
  };

  const open = () => {
    mixpanel.track("analytics.reports.open", {
      reportId: report?.snapshot.id,
      reportType: report?.data.type,
    });
  };

  const viewChanged = () => {
    mixpanel.track("analytics.reports.view-changed", { view: Renderer.TREEMAP });
  };

  const calculatedMetricSelect = (selectedCalcMetricId?: string) => {
    if (selectedCalcMetricId) {
      const { calcMetrics } = reportConfig;
      const cMetric = calcMetrics?.find((m) => m.snapshot.id === selectedCalcMetricId);
      mixpanel.track("analytics.reports.metrics-select", {
        isCalculatedMetric: true,
        metricName: cMetric?.data.name,
      });
    }
  };

  // TODO: refactor this function
  const metricSelect = (metricVal: Metric, value: string | MetricWSnap) => {
    const label = typeof value === "string" ? getMetricLabel(metricVal, value, "") : value.data.name;
    mixpanel.track("analytics.reports.metrics-select", {
      isCalculatedMetric: false,
      metricName: label,
    });
  };

  const toggleView = (newView: Renderer) => {
    mixpanel.track("analytics.report.toggle-view", {
      view: newView,
      prevView: reportConfig.renderer,
    });
  };

  const comparativeOn = () => {
    mixpanel.track("analytics.reports.comparative-on", {
      reportId: report?.snapshot.id,
      reportName: report?.data.name,
    });
  };

  return {
    run,
    runError,
    runCancel,
    open,
    viewChanged,
    calculatedMetricSelect,
    metricSelect,
    toggleView,
    comparativeOn,
  };
};

export default useReportMixpanel;
