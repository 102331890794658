import { createTheme } from "@mui/material/styles";
import { type Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { configureTheme } from "../muiTheme";
import { ThemeModes } from "../muiThemeTypes";

export const defaultTheme = createTheme(configureTheme(ThemeModes.LIGHT));

export const AppBarStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.navigation.background,
  "& .MuiInputBase-input": {
    "@media (min-width: 1115px) and (max-width: 1400px)": {
      width: "0px",
    },
  },
  "& .MuiBox-root .MuiTypography-subtitle2": {
    lg: {
      maxWidth: "112px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    xl: {
      maxWidth: "200px",
      width: "auto",
    },
  },
});

export const SecondaryMenuStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.navigation.backgroundSecondRow,
  borderColor: theme.palette.general.divider,
  borderBottomStyle: "solid",
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 1,
  boxSizing: "content-box",
});

export const ToolbarStyles = (theme: Theme) => ({
  boxSizing: "content-box",
  borderBottomWidth: theme.spacing(0.5),
  borderBottomStyle: "solid",
  borderImageSlice: 1,
  minHeight: "initial",
  position: "relative",
});

export const transparentPhoneInput2 = (theme: Theme) => ({
  color: theme.palette.text.primary,
  "&.react-tel-input": {
    "&:active, &:focus, &:hover": {
      "& .flag-dropdown": {
        borderColor: theme.palette.text.primary,
      },
    },
    "& .flag-dropdown": {
      backgroundColor: theme.palette.general.divider,
      borderColor: theme.palette.general.divider,
      "&.open": {
        "& .selected-flag": {
          backgroundColor: theme.palette.general.backgroundDefault,
        },
      },
      "& .selected-flag": {
        backgroundColor: theme.palette.general.backgroundDefault,
      },
    },
    "& .country-list": {
      backgroundColor: theme.palette.general.backgroundDefault,
      "& .search": {
        backgroundColor: theme.palette.general.backgroundDefault,
        "& input": {
          backgroundColor: theme.palette.general.backgroundDefault,
          color: theme.palette.text.primary,
        },
      },
      "& .country": {
        "&.highlight, &:hover": {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.action.hover,
        },
      },
    },
    "& .form-control": {
      backgroundColor: theme.palette.general.backgroundDefault,
      color: theme.palette.text.primary,
      borderColor: theme.palette.general.divider,
      "&:active, &:focus, &:hover": {
        borderColor: theme.palette.text.primary,
      },
    },
  },
});

export const useNoDialStyle = makeStyles(() => ({
  noDial: {
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));
