import { DashboardModel, type DashboardModelAttributionModel } from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";

import { type AttributionWRef } from "../../../types";
import { defaultHardCodedAGs } from "./utils";

export const initialNumberOfAttributions = 3;
export const numberOfSteps = 7;

const generateAttributionDocRef = () =>
  getCollection(DashboardModel).doc("google-cloud-reports").collection("attributions").newDoc();

export type EmptyAttribution = {
  data: null;
  ref: ModelReference<DashboardModelAttributionModel>;
  transform: null;
  hasUnsavedChanges: boolean;
};

export type ExtendedAttribution = (AttributionWRef & { hasUnsavedChanges: boolean }) | EmptyAttribution;

export type CostAllocationState = {
  selectedAG: { displayName: string; name: string; options: string[] };
  newAttributions: ExtendedAttribution[];
  unallocatedName: string;
  nextButtonsDisabled: boolean[];
  AGId: string;
};

export type CostAllocationAction = {
  type: string;
  payload: {
    selectedAG?: { displayName: string; name: string; options: string[] };
    newAttribution?: AttributionWRef | null;
    attrHasUnsavedChanges?: boolean;
    attrId?: string;
    unallocatedName?: string;
    nextButtonDisabled?: { stepIdx: number; disabled: boolean };
    AGId?: string;
  };
};

const createEmptyAttribution = (): EmptyAttribution => ({
  data: null,
  ref: generateAttributionDocRef(),
  transform: null,
  hasUnsavedChanges: false,
});

export const initialCostAllocationState: CostAllocationState = {
  selectedAG: defaultHardCodedAGs[0],
  newAttributions: Array.from({ length: initialNumberOfAttributions }, () => createEmptyAttribution()),
  unallocatedName: "",
  nextButtonsDisabled: Array.from({ length: numberOfSteps - 1 }, () => false),
  AGId: "",
};

export const costAllocationReducer = (
  state: CostAllocationState,
  action: CostAllocationAction
): CostAllocationState => {
  switch (action.type) {
    case "RESET": {
      return initialCostAllocationState;
    }
    case "UPDATE_NEXT_BUTTON_DISABLED": {
      if (!action.payload.nextButtonDisabled) {
        return state;
      }
      const { stepIdx, disabled } = action.payload.nextButtonDisabled;
      return {
        ...state,
        nextButtonsDisabled: state.nextButtonsDisabled.map((val, idx) => (idx === stepIdx ? disabled : val)),
      };
    }
    case "SET_SELECTED_AG":
      return { ...state, selectedAG: action.payload.selectedAG ?? defaultHardCodedAGs[0] };
    case "SET_AG_ID":
      return { ...state, AGId: action.payload.AGId ?? "" };
    case "SET_UNALLOCATED_NAME":
      return { ...state, unallocatedName: action.payload.unallocatedName ?? "" };
    case "ADD_EMPTY_ATTRIBUTION":
      return {
        ...state,
        newAttributions: [...state.newAttributions, createEmptyAttribution()],
      };
    case "UPDATE_ATTRIBUTION": {
      const { attrId, attrHasUnsavedChanges, newAttribution } = action.payload;
      const id = attrId || newAttribution?.ref.id;
      if (!id) {
        return state;
      }
      return {
        ...state,
        newAttributions: state.newAttributions.map((attr) =>
          attr.ref.id === id ? { ...(newAttribution ?? attr), hasUnsavedChanges: attrHasUnsavedChanges ?? false } : attr
        ),
      };
    }
    case "DELETE_ATTRIBUTION": {
      const filteredAttr = state.newAttributions.filter((attr) => attr.ref.id !== action.payload.attrId);
      return {
        ...state,
        newAttributions: filteredAttr,
      };
    }
    default:
      return state;
  }
};
