import { useCallback, useState } from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { type AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete/Autocomplete";
import isEqual from "lodash/isEqual";
import uniq from "lodash/uniq";

import { useFullScreen } from "../utils/dialog";
import { getPropsWrapper } from "../utils/muiUtils";

type MultiEmailsInputTextProps = {
  emails: string[];
  validatedEmails: { [key: string]: { email: string; error?: string | false } };
  onEmailsChange: (emails: string[]) => void;
  emailValue: string;
  onEmailValueChange: (email: string) => void;
  invalidEmailsHelperComponent: React.ReactNode;
  autoCompleteComponentDataTestId?: string;
  inputLabel?: string;
  chipsVariant?: "outlined" | "filled";
  chipsColor?: "primary" | "secondary" | "default" | "error" | "info" | "success" | "warning" | undefined;
};

// additional separators that are used to separate the emails besides the enter/tab key press event and the blur event
export const chipSeparators = [" ", ",", "\n", "\t"];

export const MultiEmailsInputText = ({
  emails,
  validatedEmails,
  onEmailsChange,
  emailValue,
  onEmailValueChange,
  invalidEmailsHelperComponent,
  autoCompleteComponentDataTestId,
  inputLabel,
  chipsVariant,
  chipsColor,
}: MultiEmailsInputTextProps) => {
  const [emailsInputValue, setEmailsInputValue] = useState<string[]>(emails);
  const { isMobile } = useFullScreen();

  const setEmails = useCallback(
    (emails: string[], clearEmailValue?: boolean) => {
      const emailsToSet = uniq(emails.map((x) => x.toLowerCase().trim()));
      if (isEqual(emailsToSet, emailsInputValue)) {
        return;
      }
      setEmailsInputValue(emailsToSet);
      onEmailsChange(emailsToSet);
      if (clearEmailValue) {
        onEmailValueChange("");
      }
    },
    [onEmailsChange, emailsInputValue, onEmailValueChange]
  );

  // a callback that is called when the input value changes (the current typed value of the input)
  const onInputChange = useCallback(
    (_event: any, newInputValue: string) => {
      if (newInputValue.match(new RegExp(chipSeparators.join("|")))) {
        // in case the input value with separators, we want to remove the separators and add the emails
        const parsedEmails = newInputValue.split(new RegExp(chipSeparators.join("|"))).filter((x) => x !== "");
        setEmails([...emailsInputValue, ...parsedEmails], true);
      } else {
        // in case the input value doesn't end with a separator, we just update the email value
        onEmailValueChange(newInputValue);
      }
    },
    [emailsInputValue, onEmailValueChange, setEmails]
  );

  const invalidEmails = useCallback(
    () =>
      Object.values(validatedEmails)
        .filter((x) => x.email !== emailValue)
        .some((x) => x.error),
    [emailValue, validatedEmails]
  );

  const wrapGetProps: (
    func: AutocompleteRenderGetTagProps,
    index: number
  ) => ReturnType<AutocompleteRenderGetTagProps> = useCallback(getPropsWrapper, []);

  return (
    <Autocomplete
      data-testid={autoCompleteComponentDataTestId}
      data-cy="multi-emails-input-text"
      multiple
      freeSolo
      filterSelectedOptions
      fullWidth
      autoSelect
      size="small"
      limitTags={isMobile ? 1 : -1}
      options={emailsInputValue}
      inputValue={emailValue}
      onInputChange={onInputChange}
      value={emailsInputValue}
      onChange={(_, emailsAfterChange, reason) => {
        /**  this callback is called when:
          1. the user presses enter
          2. the user presses tab
          3. the user removes an option
          4. the input loses focus
          5. the user clears the input

          when the user is typing " " or "," this is not triggered and the onInputChange callback is called instead
         */

        switch (reason) {
          case "removeOption": {
            setEmails(emailsAfterChange, false);
            break;
          }
          default: {
            setEmails(emailsAfterChange, true);
          }
        }
      }}
      renderTags={(tags, getTagProps) =>
        tags.map((email, index) => (
          <div key={`${email}${index}`} data-testid={email}>
            {!!validatedEmails[email]?.error && (
              <Chip
                variant="outlined"
                size="small"
                icon={<WarningIcon />}
                color="error"
                label={email}
                {...wrapGetProps(getTagProps, index)}
                sx={{
                  "& .MuiChip-label": {
                    whiteSpace: "normal",
                    overflow: "visible",
                  },
                }}
              />
            )}
            {!validatedEmails[email]?.error && (
              <Chip
                variant={chipsVariant ?? "outlined"}
                size="small"
                color={chipsColor ?? "primary"}
                label={email}
                {...wrapGetProps(getTagProps, index)}
                key={`${email}${index}`}
                sx={{
                  "& .MuiChip-label": {
                    whiteSpace: "normal",
                    overflow: "visible",
                  },
                }}
              />
            )}
          </div>
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          margin="dense"
          variant="outlined"
          placeholder="Add emails"
          label={inputLabel}
          error={invalidEmails()}
          helperText={invalidEmails() && invalidEmailsHelperComponent}
        />
      )}
    />
  );
};
