import { collection, type Reference, subCollection } from "@doitintl/models-types";

import { type CloudAnalyticsModelAttributionGroupsModel } from "./CloudAnalytics";
import { type CurrencyCode } from "./Currency";
import { type CustomerModel } from "./Customer";
import { type DashboardModelAttributionModel } from "./Dashboard";

@subCollection("buckets")
export class EntityModelBucketModel {
  name!: string;

  attribution?: Reference<DashboardModelAttributionModel> | null;
}

export enum PaymentMethodType {
  WireTransfer = "wire_transfer",
  BillCom = "bill_com",
  BankAccount = "bank_account",
  USBankAccount = "us_bank_account",
  CreditCard = "credit_card",
  SepaDebit = "sepa_debit",
  BacsDebit = "bacs_debit",
  AcssDebit = "acss_debit",
  Stripe = "stripe",
}

export type PaymentMethod = `${PaymentMethodType}`;

export type BankAccountPayment = {
  type: PaymentMethodType.BankAccount;
  bankAccount?: {
    bankName: string;
    id: string;
    last4: string;
  };
};

export type BillComPayment = {
  type: PaymentMethodType.BillCom;
};

export type CreditCardPayment = {
  type: PaymentMethodType.CreditCard;
  card?: {
    expMonth: number;
    expYear: number;
    id: string;
    last4: string;
  };
};

export type SepaDebitPayment = {
  type: PaymentMethodType.SepaDebit;
  sepaDebit?: {
    bankCode: string;
    email: string;
    id: string;
    last4: string;
    name: string;
  };
};

export type BacsDebitPayment = {
  type: PaymentMethodType.BacsDebit;
  bacsDebit?: {
    email: string;
    id: string;
    last4: string;
    name: string;
  };
};

export type ACSSDebitPayment = {
  type: PaymentMethodType.AcssDebit;
  acssDebit?: {
    email: string;
    id: string;
    last4: string;
    name: string;
  };
};

export type USBankAccountPayment = {
  type: PaymentMethodType.USBankAccount;
  bankAccount?: {
    bankName: string;
    id: string;
    last4: string;
  };
};

export type WireTransferPayment = {
  type: "wire_transfer";
};

export type EntityPayment =
  | BankAccountPayment
  | BillComPayment
  | CreditCardPayment
  | SepaDebitPayment
  | USBankAccountPayment
  | WireTransferPayment
  | BacsDebitPayment
  | ACSSDebitPayment;

export enum EntityInvoicingModes {
  SINGLE = "SINGLE",
  GROUP = "GROUP",
  CUSTOM = "CUSTOM",
}

export type EntityInvoicingMode = `${EntityInvoicingModes}`;

@collection("entities")
export class EntityModel {
  _name!: string;

  active?: boolean;

  billingAddress?: {
    countryname: string | null;
    address?: string | null;
    address2?: string | null;
    address3?: string | null;
    state?: string | null;
    statea?: string | null;
    statecode?: string | null;
    statename?: string | null;
    zip?: string | null;
  };

  contact?: {
    email?: string;
    firstName?: string;
    lastName?: string;
    name?: string;
    phone?: string;
  } | null;

  country?: string;

  currency?: CurrencyCode | "";

  customer!: Reference<CustomerModel> | null;

  invoicing!: {
    mode: EntityInvoicingMode;
    default?: Reference<EntityModelBucketModel> | null;
    autoAssignGCP?: boolean;
    attributionGroup?: Reference<CloudAnalyticsModelAttributionGroupsModel>;
    marketplace?: {
      invoicePerService: boolean;
      separateInvoice: boolean;
    };
  };

  name!: string;

  payment?: EntityPayment;

  priorityCompany?: string;

  priorityId?: string;

  subCollections?: {
    buckets: EntityModelBucketModel;
  };
}
