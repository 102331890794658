import { useCallback } from "react";

import noop from "lodash/noop";

import { type Entity } from "../../Context/customer/EntitiesContext";
import { consoleErrorWithSentry } from "../../utils";
import { useAsyncLoadingFunction } from "../hooks/useAsyncLoadingFunction";
import { useErrorSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import { type EntityFromPriority } from "./BillingForm/api";
import { usePriorityAPI, useV1Api } from "./BillingForm/hooks";
import { extractErrorMessage } from "./BillingForm/utils";
import { type EditBillingProfileData } from "./BillingProfileForm.models";
import { updateInvoiceSettings } from "./updateInvoiceSettings";

export type IsSubmittingFlag = boolean;
export type EditBillingProfileFn = (
  entity: Entity,
  initialValues: EditBillingProfileData,
  values: EditBillingProfileData
) => Promise<unknown>;

type BillingFormFieldName = keyof EditBillingProfileData;

function areFieldsDifferentComparator<Subject extends EditBillingProfileData>(filedNamesToCompare: (keyof Subject)[]) {
  return (initialValues: Subject, values: Subject) =>
    Object.entries(initialValues)
      .filter(([fieldName]) => filedNamesToCompare.includes(fieldName as BillingFormFieldName))
      .some(([fieldName, fieldValue]) => values[fieldName] !== fieldValue);
}

const shouldUpdateAddress = areFieldsDifferentComparator(["address", "city", "country", "state", "stateCode", "zip"]);

const shouldUpdateGeneralDetails = areFieldsDifferentComparator(["companyName", "taxId"]);

const shouldUpdateContactDetails = areFieldsDifferentComparator(["firstName", "lastName", "email", "phone"]);

const shouldUpdateBillingDetails = areFieldsDifferentComparator(["payCode"]);

const shouldUpdatePaymentMethodDetails = areFieldsDifferentComparator([
  "paymentMethodCategory",
  "paymentMethodType",
  "paymentMethodId",
]);

const shouldUpdateInvoiceSettings = areFieldsDifferentComparator([
  "invoicingMode",
  "autoAssignGCP",
  "buckets",
  "invoicePerService",
  "separateInvoice",
]);

function useEntityUpdate(entityFromPriority: EntityFromPriority | undefined | null): EditBillingProfileFn {
  const priorityApi = usePriorityAPI();
  const v1Api = useV1Api();

  return useCallback(
    async (entity: Entity, initialValues: EditBillingProfileData, values: EditBillingProfileData) =>
      entityFromPriority
        ? Promise.all(
            [
              {
                shouldBeUpdated: shouldUpdateAddress,
                doUpdate: priorityApi.updateAddress.bind(priorityApi, entity.id, values),
              },
              {
                shouldBeUpdated: shouldUpdateGeneralDetails,
                doUpdate: priorityApi.updateGeneralDetails.bind(priorityApi, entity.id, values),
              },
              {
                shouldBeUpdated: shouldUpdateContactDetails,
                doUpdate: priorityApi.updateContactDetails.bind(priorityApi, entity.id, values, entityFromPriority),
              },
              {
                shouldBeUpdated: shouldUpdateBillingDetails,
                doUpdate: priorityApi.updateBillingDetails.bind(priorityApi, entity.id, values),
              },
              {
                shouldBeUpdated: shouldUpdatePaymentMethodDetails,
                doUpdate: v1Api.updatePaymentMethod.bind(v1Api, entity.id, values),
              },
              {
                shouldBeUpdated: shouldUpdateInvoiceSettings,
                doUpdate: () => updateInvoiceSettings(entity, initialValues, values),
              },
            ]
              .filter(({ shouldBeUpdated }) => shouldBeUpdated(initialValues, values))
              .map(({ doUpdate }) => doUpdate())
          )
        : noop,
    [entityFromPriority, priorityApi, v1Api]
  );
}

export function useEditBillingProfileSubmitFunction(
  entity: Entity,
  entityFromPriority: EntityFromPriority | undefined | null,
  initialValues: EditBillingProfileData | undefined,
  onBillingProfileEdit: () => unknown
): [IsSubmittingFlag, (value: EditBillingProfileData) => Promise<void>] {
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const updateEntity = useEntityUpdate(entityFromPriority);

  const onSubmit = useCallback(
    async (values: EditBillingProfileData) => {
      try {
        initialValues && (await updateEntity(entity, initialValues, values));

        successSnackbar("Billing profile successfully updated");
        onBillingProfileEdit();
      } catch (error: unknown) {
        consoleErrorWithSentry(error);
        errorSnackbar(extractErrorMessage(error));
      }
    },
    [updateEntity, entity, initialValues, successSnackbar, onBillingProfileEdit, errorSnackbar]
  );

  return useAsyncLoadingFunction(onSubmit);
}
