import { Link } from "react-router-dom";
import { TableCell, Typography } from "@mui/material";
import { type LocationDescriptor } from "history";

type Props = {
  color?: string;
  linkText: string;
  target?: React.HTMLAttributeAnchorTarget;
  to: LocationDescriptor;
};

export const RouterLinkCell = ({ color = "inherit", linkText, to, target }: Props) => (
  <TableCell>
    <Typography component={Link} color={color} target={target} to={to} variant="body2">
      {linkText}
    </Typography>
  </TableCell>
);
