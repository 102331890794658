import { type ChangeEvent, type Dispatch, useEffect } from "react";

import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  type Theme,
  Typography,
} from "@mui/material";
import { type TableCellProps } from "@mui/material/TableCell";

import { guidedExperienceText } from "../../../../assets/texts/CloudAnalytics";
import { type CostAllocationAction } from "../costAllocationReducer";
import { CUSTOM, CUSTOM_OPTION, defaultCustomAG, defaultHardCodedAGs, sendStepMixpanelEvent } from "../utils";

const STEP_NUMBER = 2;

type CellProps = {
  text?: string;
  align?: TableCellProps["align"];
  sx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
};

const TitleCell = ({ text, align, sx, typographySx }: CellProps) => (
  <TableCell align={align} sx={sx}>
    <Typography variant="subtitle2" color="text.secondary" sx={typographySx}>
      {text}
    </Typography>
  </TableCell>
);

const ContentCell = ({ text, align, sx, typographySx }: CellProps) => (
  <TableCell align={align} sx={sx}>
    <Typography variant="body2" color="text.secondary" sx={typographySx}>
      {text}
    </Typography>
  </TableCell>
);

const DollarCell = ({ sx, typographySx }: CellProps) => (
  <ContentCell text="$" align="center" sx={sx} typographySx={typographySx} />
);

const rightBorder = { borderRight: "1px solid rgba(0, 0, 0, 0.12)" };

type Props = {
  selectedAG: { displayName: string; name: string; options: string[] };
  dispatchCostAllocationState: Dispatch<CostAllocationAction>;
  origin: string;
};

const Step2 = ({ selectedAG, dispatchCostAllocationState, origin }: Props) => {
  useEffect(() => {
    sendStepMixpanelEvent(STEP_NUMBER, "name-attribution-group", origin);
  }, [origin]);

  useEffect(() => {
    dispatchCostAllocationState({
      type: "UPDATE_NEXT_BUTTON_DISABLED",
      payload: { nextButtonDisabled: { stepIdx: STEP_NUMBER - 1, disabled: !selectedAG.name } },
    });
  }, [dispatchCostAllocationState, selectedAG.name]);

  const onRadioBtnChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === CUSTOM_OPTION) {
      dispatchCostAllocationState({ type: "SET_SELECTED_AG", payload: { selectedAG: defaultCustomAG } });
    } else {
      const attrGroup = defaultHardCodedAGs.find((a) => a.name === value);
      if (attrGroup) {
        dispatchCostAllocationState({ type: "SET_SELECTED_AG", payload: { selectedAG: attrGroup } });
      }
    }
  };

  const onCustomInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatchCostAllocationState({
      type: "SET_SELECTED_AG",
      payload: { selectedAG: { ...defaultCustomAG, name: e.target.value } },
    });
  };

  return (
    <Stack gap={3}>
      <Typography>{guidedExperienceText.step2.description}</Typography>
      <Grid container>
        <Grid item xs={3}>
          <FormControl>
            <RadioGroup value={selectedAG.displayName} onChange={onRadioBtnChange}>
              {defaultHardCodedAGs.map((ag) => (
                <FormControlLabel
                  key={`${ag.name}-radio`}
                  value={ag.displayName}
                  control={<Radio />}
                  label={ag.displayName}
                />
              ))}
              {selectedAG.displayName === CUSTOM_OPTION && (
                <TextField
                  autoFocus
                  placeholder={guidedExperienceText.step2.ENTER_GROUP_NAME}
                  onChange={onCustomInputChange}
                  value={selectedAG.name}
                />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={9} sx={{ pr: 3 }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.hoverBackground" }}>
                <TitleCell
                  text={selectedAG.name || CUSTOM}
                  align="left"
                  sx={rightBorder}
                  typographySx={{ color: "text.primary" }}
                />
                <ContentCell text="Jan" sx={rightBorder} align="center" />
                <ContentCell text="Feb" sx={rightBorder} align="center" />
                <ContentCell text="Mar" sx={rightBorder} align="center" />

                <TitleCell text="Total" align="center" />
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedAG.options.map((op) => (
                <TableRow key={op}>
                  <ContentCell text={op} align="left" sx={rightBorder} />
                  <DollarCell sx={rightBorder} align="center" />
                  <DollarCell sx={rightBorder} align="center" />
                  <DollarCell sx={rightBorder} align="center" />
                  <DollarCell align="center" />
                </TableRow>
              ))}
              <TableRow sx={{ backgroundColor: "primary.hoverBackground" }}>
                <TitleCell text="Total cost" align="left" sx={rightBorder} />
                <TitleCell text="$" align="center" sx={rightBorder} />
                <TitleCell text="$" align="center" sx={rightBorder} />
                <TitleCell text="$" align="center" sx={rightBorder} />
                <TitleCell text="$" align="center" />
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Step2;
