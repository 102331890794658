import {
  type ApiServiceModelDescriptor,
  type CloudFlowNodeType,
  type Member,
  type MemberReference,
  ModelType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-admin";

import { calculateConditionalNodeOutputModel } from "./calculated-node-output-models/conditional-node";
import { calculateTransformationNodeOutputModel } from "./calculated-node-output-models/transformation-node";
import { isActionNode, isConditionNode, isFilterNode, isTransformationNode } from "./pattern-matchers";
import { type NodeModelWithId } from "./types";

export type ModelPath = string[];

export type GetOutputModelForActionNodeFn = (
  referencedNode: NodeModelWithId<CloudFlowNodeType.ACTION>
) => Promise<UnwrappedApiServiceModelDescriptor | null>;

export async function getNodeOutputModel(
  getOutputModelForActionNode: GetOutputModelForActionNodeFn,
  nodes: NodeModelWithId[],
  nodeId: string
): Promise<UnwrappedApiServiceModelDescriptor | null> {
  const referencedNode = nodes.find(({ id }) => nodeId === id);
  if (referencedNode === undefined) {
    throw new Error(`Could not find referenced node id: ${nodeId}`);
  }

  switch (true) {
    case isActionNode(referencedNode):
      return getOutputModelForActionNode(referencedNode);
    case isConditionNode(referencedNode):
    case isFilterNode(referencedNode): {
      return calculateConditionalNodeOutputModel(referencedNode, nodes, getOutputModelForActionNode);
    }
    case isTransformationNode(referencedNode): {
      return calculateTransformationNodeOutputModel(referencedNode, nodes, getOutputModelForActionNode);
    }
  }

  throw new Error(`Unable to provide an output model for node ${referencedNode.id} with type ${referencedNode.type}`);
}

export function getModelByPath(model: UnwrappedApiServiceModelDescriptor, path: ModelPath) {
  if (path.length === 0) {
    return model;
  }

  switch (model.type) {
    case ModelType.LIST:
      return getModelByPath(model.member.model, path);
    case ModelType.STRUCTURE: {
      const [memberName, ...pathRest] = path;
      const memberModel = model.members[memberName];
      if (memberModel === undefined) {
        throw new Error(`Could not get model for path token ${memberName}`);
      }

      return getModelByPath(memberModel.model, pathRest);
    }
    case ModelType.MAP: {
      const [memberName, ...pathRest] = path;
      let member: Member | undefined;
      if (model.keyMemberName === memberName) {
        member = model.keyMember;
      }
      if (model.valueMemberName === memberName) {
        member = model.valueMember;
      }
      if (member === undefined) {
        throw new Error(`Could not get model for path token ${memberName}`);
      }

      return getModelByPath(member.model, pathRest);
    }
    default:
      throw new Error("Model path out of bounds!");
  }
}

export function wrapModelWithListModel(model: UnwrappedApiServiceModelDescriptor): UnwrappedApiServiceModelDescriptor {
  if (model.type === ModelType.LIST) {
    return model;
  }
  return {
    type: ModelType.LIST,
    member: {
      model,
    },
  };
}

export function isUnwrappedMember(member: Member | MemberReference): member is Member {
  return Object.hasOwn(member, "model");
}

export async function unwrapModel(
  getReferencedModelById: (modelId: string) => Promise<WithFirebaseModel<ApiServiceModelDescriptor>>,
  modelToUnwrap: ApiServiceModelDescriptor
): Promise<WithFirebaseModel<UnwrappedApiServiceModelDescriptor>> {
  switch (modelToUnwrap.type) {
    case ModelType.LIST: {
      const memberModel = isUnwrappedMember(modelToUnwrap.member)
        ? modelToUnwrap.member.model
        : await getReferencedModelById(modelToUnwrap.member.modelId);
      return {
        ...modelToUnwrap,
        member: {
          documentation: modelToUnwrap.member.documentation,
          model: await unwrapModel(getReferencedModelById, memberModel),
        },
      };
    }
    case ModelType.STRUCTURE: {
      const membersEntries = await Promise.all(
        Object.entries(modelToUnwrap.members).map(async ([memberName, member]) => {
          const memberModel = isUnwrappedMember(member) ? member.model : await getReferencedModelById(member.modelId);
          return [
            memberName,
            {
              model: await unwrapModel(getReferencedModelById, memberModel),
              documentation: member.documentation,
            },
          ] as const;
        })
      );
      return {
        ...modelToUnwrap,
        members: Object.fromEntries(membersEntries),
      };
    }
    case ModelType.MAP: {
      const keyMemberModel = isUnwrappedMember(modelToUnwrap.keyMember)
        ? modelToUnwrap.keyMember.model
        : await getReferencedModelById(modelToUnwrap.keyMember.modelId);
      const valueMemberModel = isUnwrappedMember(modelToUnwrap.valueMember)
        ? modelToUnwrap.valueMember.model
        : await getReferencedModelById(modelToUnwrap.valueMember.modelId);

      return {
        ...modelToUnwrap,
        keyMember: {
          model: await unwrapModel(getReferencedModelById, keyMemberModel),
          documentation: modelToUnwrap.keyMember.documentation,
        },
        valueMember: {
          model: await unwrapModel(getReferencedModelById, valueMemberModel),
          documentation: modelToUnwrap.valueMember.documentation,
        },
      };
    }
    default:
      return modelToUnwrap as WithFirebaseModel<UnwrappedApiServiceModelDescriptor>;
  }
}

export function getRelativePath(basePath: ModelPath, path: ModelPath) {
  if (!basePath.every((token, idx) => path[idx] === token)) {
    throw new Error(`Cannot extract relative path from [${path.join(",")}] with base [${basePath.join(",")}]`);
  }
  return path.slice(basePath.length);
}
