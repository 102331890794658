import { Box } from "@mui/material";
import { PickersDay, type PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { type DateTime } from "luxon";

import { isDateBetween, isSameDate } from "./dateRangeUtils";
import { containerStyle, pickersDayStyle } from "./styles";

type WeekPickerDayProps = {
  date: DateTime | undefined;
  selectedDates: Array<any | null>;
  pickersDayProps: PickersDayProps<any>;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  onClick: (date: DateTime) => void;
  disabled?: boolean;
};
const WeekPickerDay = ({
  date,
  selectedDates,
  pickersDayProps,
  startDate,
  endDate,
  onClick,
  disabled,
}: WeekPickerDayProps) => {
  if (!date) {
    return <PickersDay {...pickersDayProps} />;
  }
  const dayIsBetween = endDate && startDate ? isDateBetween(startDate, endDate, date) : false;
  const isFirstDay = isSameDate(date, startDate);
  const isLastDay = isSameDate(date, endDate);
  const isFirstDayOfMonth = date.startOf("month").day === date.day;
  const isLastDayOfMonth = date.endOf("month").day === date.day;
  if (selectedDates[0]?.hasSame(date, "day")) {
    // removes the default selected day
    pickersDayProps.selected = false;
  }
  const dataCy = !pickersDayProps.outsideCurrentMonth && !pickersDayProps.disabled ? date.toISO() : undefined;
  const isStartDay = (isFirstDay && !!endDate) || (isFirstDayOfMonth && dayIsBetween);
  const isEndDay = isLastDay || (isLastDayOfMonth && dayIsBetween);

  return (
    <Box data-cy={dataCy} sx={containerStyle(isStartDay, isEndDay, dayIsBetween)}>
      <PickersDay
        {...pickersDayProps}
        onMouseDown={(e) => {
          e.preventDefault();
          onClick(date);
        }}
        sx={pickersDayStyle(isFirstDay, isLastDay, dayIsBetween)}
        disabled={pickersDayProps.disabled || disabled}
      />
    </Box>
  );
};

export default WeekPickerDay;
