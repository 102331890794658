import { Tab, Tabs } from "@mui/material";

import { type ConfigurationTab } from "../../../types";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

export const TabNavigation = ({ tabs }: { tabs: ConfigurationTab[] }) => {
  const { activeTab, setActiveTab } = useNodeConfigurationContext();

  const handleTabChange = (event: React.SyntheticEvent, newValue: ConfigurationTab) => {
    setActiveTab(newValue);
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="fullWidth"
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        ".MuiTab-root": {
          textTransform: "none",
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab key={tab} label={tab} value={tab} />
      ))}
    </Tabs>
  );
};
