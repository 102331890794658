import { useShouldShowOnboardingCards } from "../../Components/Dashboard/CloudConnection/hooks";
import { useCustomerContext } from "../../Context/CustomerContext";
import { type PageId } from "../../Navigation/config/pages";
import { SelectCloud } from "../Flexsave/Standalone/Common/SelectCloud";
import HomePageOrDashboards from "../Home/HomePageOrDashboard";
import CloudSelector from "./SaaSConsoleOnboarding/Common/CloudSelector/CloudSelector";

export const CustomerView = ({ pageId }: { pageId: Extract<PageId, "home-page" | "customer-dashboards"> }) => {
  const { shouldShowStandaloneCloudSelector, shouldShowSaasCloudSelector } = useShouldShowOnboardingCards();

  const { customerOrPresentationModeCustomer: customer, isProductOnlyCustomer } = useCustomerContext({
    allowNull: true,
  });

  if (!customer) {
    return null;
  }

  if (isProductOnlyCustomer === undefined) {
    return null;
  }

  if (shouldShowSaasCloudSelector) {
    return <CloudSelector />;
  }

  if (shouldShowStandaloneCloudSelector) {
    return <SelectCloud />;
  }

  return <HomePageOrDashboards pageId={pageId} />;
};
