import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Box, Chip, Hidden, Link, TableCell, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import ZendeskIcon from "../../../assets/zendeskIcon.png";
import { AvaIcon } from "../../../Components/Ava/Messenger/AvaIcon";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { avaAgentIdDev, avaAgentIdProd } from "../../../constants";
import { type Ticket } from "../../../Support/types";
import { type HeaderColumn } from "../../../types/FilterTable";
import { transformPriority, transformStatus, truncate } from "../../../utils/common";
import { getProductId } from "../../utils";
import SupportItemIcon from "../SupportItemIcon";
import { getStatusColor } from "./TicketsTableColumns";

type Props = {
  isDoitEmployee: boolean;
  ticket: Ticket;
  headerConfigs: HeaderColumn[];
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: "0px solid #e0e0e0",
    width: 32,
    height: 32,
    marginTop: 2,
    marginRight: 5,
  },
  zendeskIcon: {
    width: 25,
    marginRight: 4,
  },
  subject: {
    maxWidth: 320,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xl")]: {
      maxWidth: 250,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 250,
    },
  },
}));

const TicketsTableRow = ({ isDoitEmployee, ticket, headerConfigs }: Props) => {
  const theme = useTheme();
  const isAvaAgent = (author): boolean => !!author && [avaAgentIdDev, avaAgentIdProd].includes(author.id);
  const { t } = useTranslation("services");
  const classes = useStyles();

  const zendeskURL = (id) => `https://doitintl.zendesk.com/agent/tickets/${id}`;
  const getTicketLink = (id) => {
    window.open(zendeskURL(id), "_blank");
  };

  const productId = getProductId(ticket);

  const rateLink = (
    <Typography
      to={`support/tickets/${ticket.id}/rate`}
      component={RouterLink}
      color="inherit"
      variant="body2"
      data-cy={`ticketRow-${ticket.id}-subject`}
    >
      Rate
    </Typography>
  );

  const getSatisfactionText = () =>
    ticket.status !== "solved" && ticket.status !== "closed"
      ? "N/A"
      : ticket.satisfaction_rating?.score.toUpperCase() || "N/A";

  return (
    <CellsWrapper>
      <TableCell data-cy={`ticketRow-${ticket.id}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isDoitEmployee && (
            <Link
              href="#"
              onClick={() => {
                getTicketLink(ticket.id);
              }}
              style={{ marginTop: 4 }}
              data-cy={`ticketRow-${ticket.id}-zendDesk`}
            >
              <img alt="zendesk icon" className={classes.zendeskIcon} src={ZendeskIcon} />
            </Link>
          )}
          <Typography
            to={`support/tickets/${ticket.id}`}
            component={RouterLink}
            color="inherit"
            variant="body2"
            data-cy={`ticketRow-${ticket.id}-id`}
          >
            #{ticket.id}
          </Typography>
        </div>
      </TableCell>
      <Hidden {...headerConfigs[1].hidden}>
        <TableCell>
          <Typography
            to={`support/tickets/${ticket.id}`}
            component={RouterLink}
            color="inherit"
            variant="body2"
            data-cy={`ticketRow-${ticket.id}-subject`}
          >
            {truncate(ticket.subject, 50, false)}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden {...headerConfigs[2].hidden}>
        <TableCell>{!!productId && <SupportItemIcon platformValue={productId} />}</TableCell>
      </Hidden>
      <Hidden {...headerConfigs[3].hidden}>
        <TableCell>
          <Tooltip title={ticket.assignee?.name}>
            {isAvaAgent(ticket.assignee) ? (
              <Box data-cy={`ava-assignee-icon-${ticket.id}`}>
                <AvaIcon />
              </Box>
            ) : (
              <Avatar alt="Agent" src={ticket.assignee?.photo?.content_url} className={classes.avatar} />
            )}
          </Tooltip>
        </TableCell>
      </Hidden>
      <Hidden {...headerConfigs[4].hidden}>
        <TableCell>
          <Typography variant="body2" component="span" data-cy={`ticketRow-${ticket.id}-priority`}>
            {transformPriority(ticket.priority, t)}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden {...headerConfigs[5].hidden}>
        <TableCell>
          <Typography variant="body2" component="span" noWrap data-cy={`ticketRow-${ticket.id}-ticketRequester`}>
            {ticket.requester}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden {...headerConfigs[6].hidden}>
        <TableCell>
          <Typography variant="body2" component="span" noWrap data-cy={`ticketRow-${ticket.id}-satisfaction`}>
            {ticket.status === "solved" && ticket.satisfaction_rating?.score === "offered"
              ? rateLink
              : getSatisfactionText()}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden {...headerConfigs[7].hidden}>
        <TableCell>
          <Typography variant="body2" component="span" data-cy={`ticketRow-${ticket.id}-ticketLastUpdated`}>
            {DateTime.fromJSDate(new Date(ticket.updated_at))?.toLocaleString(DateTime.DATETIME_MED) ?? ""}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden {...headerConfigs[8].hidden}>
        <TableCell>
          <Typography variant="body2" component="span" noWrap data-cy={`ticketRow-${ticket.id}-ticketStatus`}>
            <Chip
              size="small"
              style={{ backgroundColor: getStatusColor(ticket.status), color: theme.palette.common.white }}
              color="primary"
              label={transformStatus(ticket.status, t)}
            />
          </Typography>
        </TableCell>
      </Hidden>
    </CellsWrapper>
  );
};

export default TicketsTableRow;
