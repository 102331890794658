import {
  AnomalousOther,
  ExpectedCostSpikes,
  FaultyAnomalyDetectionModel,
  IncorrectData,
  LowImpactAnomalies,
  Misconfiguration,
  NonAnomalousOther,
  SecurityBreach,
  UnintentionalProvisioning,
} from "@doitintl/cmp-models";

export const dataSeriesLabels = {
  costAtTimeOfDetection: "Cost at time of detection",
  costSinceTimeOfDetection: "Cost since time of detection",
  adjustmentSinceTimeOfDetection: "Cost adjustment since time of detection",
  normalRange: "Normal range",
};

export const anomalyFeedbackReasonsLabels = {
  [FaultyAnomalyDetectionModel]: "Faulty anomaly detection model",
  [IncorrectData]: "Incorrect data",
  [ExpectedCostSpikes]: "Expected costs spikes",
  [LowImpactAnomalies]: "Low impact anomalies or low priority anomalies",
  [NonAnomalousOther]: "Other",
  [SecurityBreach]: "Security breach",
  [Misconfiguration]: "Misconfiguration",
  [UnintentionalProvisioning]: "Unintentional provisioning of services",
  [AnomalousOther]: "Other",
};
