import { type JSX } from "react";

import { Box, List, ListItem, ListSubheader, Skeleton } from "@mui/material";

import { CardsSkeleton } from "../../Common/CardsSkeleton";
import { AsgsConfigurationTitles } from "./AsgsConfigurationTitles";
import { useCommonHeights } from "./hooks";

export const AsgsSkeleton = () => {
  const [instanceTypeHeights] = useCommonHeights();
  const [azHeights] = useCommonHeights();

  const list: JSX.Element[] = [];
  for (let i = 0; i < 10; ++i) {
    list.push(
      <ListItem key={i} style={{ height: 36 }}>
        <Skeleton variant="rectangular" width={250} height={20} />
      </ListItem>
    );
  }

  return (
    <Box display="flex">
      <CardsSkeleton />
      <Box display="flex">
        <AsgsConfigurationTitles instanceTypeHeights={instanceTypeHeights} azHeights={azHeights} />
        <List dense subheader={<ListSubheader component="div">Current Values</ListSubheader>}>
          {list}
        </List>
      </Box>
    </Box>
  );
};
