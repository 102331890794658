import { useEffect, useRef, useState } from "react";

export function useResizeObserverSignal(element: HTMLElement | null) {
  const [resizeObserverSignal, setResizeObserverSignal] = useState<ResizeObserverEntry[]>([]);

  const resizeObserverRef = useRef(new ResizeObserver(setResizeObserverSignal));

  useEffect(() => {
    if (!element) {
      return;
    }
    const resizeObserver = resizeObserverRef.current;
    resizeObserver.observe(element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [element]);

  return resizeObserverSignal;
}
