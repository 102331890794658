import { type FlexsavePayerConfigs, type ManagedType, type Status } from "@doitintl/cmp-models";

export type EditFormValues = Pick<
  FlexsavePayerConfigs,
  | "status"
  | "managed"
  | "maxSpend"
  | "minSpend"
  | "seasonal"
  | "targetPercentage"
  | "sagemakerStatus"
  | "rdsStatus"
  | "keepActiveEvenWhenOnCredits"
  | "rdsTargetPercentage"
> & {
  computeDiscount?: string;
  computeEffectiveDate?: string;
  sagemakerDiscount?: string;
  sagemakerEffectiveDate?: string;
  rdsDiscount?: string;
  rdsEffectiveDate?: string;
  statusChangeReason: string;
};

export type DiscountValues = {
  discount?: string;
  effectiveDate?: string;
};

export type DiscountDTO = {
  discount: number | null;
  effectiveDate: string | null;
  criteria: string;
};

export type PayerConfigDTO = {
  status: Status;
  rdsStatus: Status | undefined;
  sagemakerStatus: Status | undefined;
  managed: ManagedType;
  targetPercentage?: number;
  minSpend?: number;
  maxSpend?: number;
  discount?: DiscountDTO[];
  seasonal?: boolean;
  statusChangeReason: string;
  keepActiveEvenWhenOnCredits?: boolean;
  rdsTargetPercentage?: number;
};

export type ExtendedFlexsavePayerConfigs = FlexsavePayerConfigs & {
  computeDiscount: string | undefined;
  computeEffectiveDate: string | undefined;
  sagemakerDiscount: string | undefined;
  sagemakerEffectiveDate: string | undefined;
  rdsDiscount: string | undefined;
  rdsEffectiveDate: string | undefined;
};

export enum AWSFlexsaveType {
  compute = "compute",
  sagemaker = "sagemaker",
  rds = "rds",
}
