import capitalize from "lodash/capitalize";

import { attributionGroupsText, globalText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import { type HeaderColumn } from "../../../types/FilterTable";

export const filterColumns = [
  {
    label: attributionGroupsText.TABLE_HEADERS.ID,
    path: "ref.id",
    requireExactMatch: true,
  },
  {
    label: attributionGroupsText.TABLE_HEADERS.ATTRIBUTION_GROUP_NAME,
    path: "data.name",
  },
  {
    label: attributionGroupsText.TABLE_HEADERS.OWNER,
    path: "transform.owner",
  },
  {
    label: attributionGroupsText.TABLE_HEADERS.TYPE,
    path: "data.type",
    toOption: (value: string) => ({ value, label: capitalize(value) }),
  },
  {
    label: globalText.LAST_MODIFIED,
    path: "data.timeModified",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
] as const;

export const headers: HeaderColumn[] = [
  {
    value: "data.name",
    label: attributionGroupsText.TABLE_HEADERS.ATTRIBUTION_GROUP_NAME,
    tooltip: attributionGroupsText.TABLE_HEADERS.ATTRIBUTION_GROUP_NAME,
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.attributions",
    label: attributionGroupsText.TABLE_HEADERS.ATTRIBUTIONS,
    tooltip: attributionGroupsText.TABLE_HEADERS.ATTRIBUTIONS,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "transform.owner",
    label: attributionGroupsText.TABLE_HEADERS.OWNER,
    tooltip: attributionGroupsText.TABLE_HEADERS.OWNER,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.type",
    label: attributionGroupsText.TABLE_HEADERS.TYPE,
    tooltip: attributionGroupsText.TABLE_HEADERS.TYPE,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "data.timeModified",
    label: attributionGroupsText.TABLE_HEADERS.LAST_MODIFIED,
    tooltip: attributionGroupsText.TABLE_HEADERS.LAST_MODIFIED,
    style: { align: "left", padding: "normal" },
    hidden: { smDown: true },
  },
  {
    value: "@",
    style: { align: "left", padding: "normal" },
  },
];
