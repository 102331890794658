import { useEffect, useState } from "react";

import { DoitRole } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import { useAuthContext } from "../../Context/AuthContext";
import { getCustomerAccountAssumptionSettings } from "../../Pages/Customers/db";
import { type Customer } from "../../types";

export const useAccountAssumption = (customer: Customer) => {
  const { isDoitEmployee } = useAuthContext();
  const isUserImpersonator = useDoitRoleCheck(DoitRole.UserImpersonator);
  const [accountAssumptionAllowed, setAccountAssumptionAllowed] = useState(false);

  useEffect(() => {
    if (!isDoitEmployee) {
      setAccountAssumptionAllowed(false);
      return;
    }

    // If Doer has the UserImpersonator role, skip the assumption settings check and allow
    if (isUserImpersonator) {
      setAccountAssumptionAllowed(true);
      return;
    }

    return getCustomerAccountAssumptionSettings(customer.id).onSnapshot((accountAssumptionSettings) => {
      const data = accountAssumptionSettings.asModelData();
      if (data) {
        // by default enabled set to true - in case indefinite assumption is enabled
        let enabled = true;
        if (data.accountAssumptionUntil) {
          const timestamp = DateTime.fromJSDate(data?.accountAssumptionUntil.toDate()).toMillis();
          const curr = DateTime.fromJSDate(DateTime.now().toJSDate()).toMillis();
          enabled = timestamp - curr > 0;
        }
        setAccountAssumptionAllowed(enabled);
      } else {
        setAccountAssumptionAllowed(false);
      }
    });
  }, [isDoitEmployee, isUserImpersonator, customer]);

  return accountAssumptionAllowed;
};
