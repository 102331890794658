import { useCallback } from "react";

import {
  AssetModel,
  IntegrationModel,
  type QuickLinkCondition,
  type QuickLinkDescriptorModel,
  QuickLinkWhenCollectionIsEmpty,
  RampModel,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";

export type CheckQuickLinkConditionFn = (
  quickLink: QuickLinkDescriptorModel
) => Promise<QuickLinkDescriptorModel | null>;

type QuickLinkDescriptorWithConditionModel = QuickLinkDescriptorModel & {
  conditions: QuickLinkCondition[];
};

function isQuickLinkWithConditions(
  quickLink: QuickLinkDescriptorModel
): quickLink is QuickLinkDescriptorWithConditionModel {
  return quickLink.conditions !== undefined;
}

function isTrue(value: boolean): boolean {
  return value === true;
}

export function useCheckQuickLinkConditionFn(): CheckQuickLinkConditionFn {
  const { customer } = useCustomerContext();

  return useCallback(
    async (quickLink) => {
      if (!isQuickLinkWithConditions(quickLink)) {
        return quickLink;
      }

      const collectionPathToQuery = (quickLinkCondition: QuickLinkCondition) => {
        if (quickLinkCondition.collectionPath === "assets") {
          const query = getCollection(AssetModel).where(
            quickLinkCondition.collectionCustomerRefFieldName,
            "==",
            customer.ref
          );

          const filters = JSON.parse(quickLinkCondition.collectionFilters);

          const value = filters.type;

          return query.where("type", Array.isArray(value) ? "in" : "==", value);
        } else if (quickLinkCondition.collectionPath === "/customers/:customerId:/cloudConnect") {
          const query = customer.ref.collection("cloudConnect");

          const filters = JSON.parse(quickLinkCondition.collectionFilters);

          const value = filters.cloudPlatform;

          return query.where("cloudPlatform", Array.isArray(value) ? "in" : "==", value);
        } else if (quickLinkCondition.collectionPath === "rampPlans") {
          return getCollection(RampModel).where(quickLinkCondition.collectionCustomerRefFieldName, "==", customer.ref);
        } else if (quickLinkCondition.collectionPath === "/integrations/k8s-metrics/eks") {
          return getCollection(IntegrationModel)
            .doc("k8s-metrics")
            .collection("eks")
            .where(quickLinkCondition.collectionCustomerRefFieldName, "==", customer.ref.id);
        }

        throw new Error(`Unknown collection path: ${(quickLinkCondition as any).collectionPath}`);
      };

      const conditionChecks = await Promise.all(
        quickLink.conditions.map(async (quickLinkCondition) => {
          const query = collectionPathToQuery(quickLinkCondition);

          const snapshot = await query.limit(1).get();

          switch (quickLinkCondition.whenCollectionIsEmpty) {
            case QuickLinkWhenCollectionIsEmpty.HIDE:
              return !snapshot.empty;
            case QuickLinkWhenCollectionIsEmpty.SHOW:
              return snapshot.empty;
            default:
              return false;
          }
        })
      );

      return conditionChecks.every(isTrue) ? quickLink : null;
    },
    [customer.ref]
  );
}
