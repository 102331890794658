import { type AssetType } from "@doitintl/cmp-models";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { maxAssetTagLength } from "../../../assetUtils";
import { type SuggestedTags } from "../types";

type Props = {
  assetTag: string;
  onTagInputChange: (str: string) => void;
  suggestedTags: SuggestedTags;
  assetType?: AssetType;
};

export const AssetTagsField = (props: Props) => {
  const { assetTag, onTagInputChange, suggestedTags, assetType } = props;

  return (
    <Autocomplete
      id="tags-input"
      freeSolo
      autoSelect
      filterSelectedOptions
      options={(assetType && suggestedTags[assetType]) ?? []}
      value={assetTag}
      onChange={(_event, newValue) => {
        onTagInputChange(newValue || "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Asset tag"
          helperText="A maximum of one tag per asset is allowed. They appear on invoices, e.g. cost-centers or team names"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ ...params.inputProps, maxLength: maxAssetTagLength }}
          fullWidth
          size="medium"
          onChange={(e) => {
            onTagInputChange(e.target.value || "");
          }}
        />
      )}
    />
  );
};
