import { Card, CardContent, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const SkeletonLinkedThread = () => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent style={{ padding: theme.spacing(2) }}>
        <Skeleton variant="text" width="85%" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
      </CardContent>
    </Card>
  );
};
