import { type ReactElement, type ReactNode } from "react";

import {
  type MSTeamsTarget,
  type NotificationAudienceType,
  NotificationProviderType,
  type NotificationSettings,
  type ProviderTarget,
  type SlackChannel,
} from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";

export type RequiredActions = {
  title?: string;
  description?: string;
  content?: ReactNode;
};

export type ProviderOptionProps = {
  title: string;
  renderIcon?: (color: string) => ReactElement<any, any>;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
};

export type ProviderOptionItem = ProviderOptionProps & {
  provider: NotificationProviderType;
  requiredActions?: RequiredActions;
};

export type ChannelConfig = {
  name: string;
  providerType: NotificationProviderType | "";
  selectedNotifications?: ModelData<NotificationSettings>;
  providerTarget?: ProviderTarget;
  disableTargetUpdate?: boolean;
  audienceType?: NotificationAudienceType;
};

export type ChannelType = SlackChannel[] | MSTeamsTarget[] | string[];

export const isSlackChannelType = (
  providerType: NotificationProviderType,
  channels: ChannelType
): channels is SlackChannel[] => providerType === NotificationProviderType.SLACK;
export const isMSTeamsChannelType = (
  providerType: NotificationProviderType,
  channels: ChannelType
): channels is MSTeamsTarget[] => providerType === NotificationProviderType.MSTEAMS;
