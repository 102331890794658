import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import { type DataColumns, type HeaderColumns } from "../../../types/FilterTable";
import { type LabelRowItem } from "./types";

export const headers: HeaderColumns<LabelRowItem> = [
  {
    value: "data.name",
    label: "Label name",
    tooltip: "Label Name",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "timesApplied",
    label: "Times applied",
    tooltip: "Times applied",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.timeCreated",
    label: "Date created",
    tooltip: "Date created",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "data.createdBy",
    label: "Created by",
    tooltip: "Created by",
    style: { align: "left", padding: "normal" },
  },
  {
    value: "@",
    style: { align: "left", padding: "checkbox" },
  },
] as const;

export const filters: DataColumns<LabelRowItem> = [
  {
    label: "Label name",
    path: "data.name",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Created by",
    path: "data.createdBy",
    comparators: ["==", "!=", "contains"],
  },
  {
    label: "Times applied",
    path: "timesApplied",
    comparators: ["<", "<=", ">", ">="],
    type: "Number",
  },
  {
    label: "Date created",
    path: "data.timeCreated",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    ...firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
] as const;
