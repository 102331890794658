import { useCallback } from "react";

import { useApiContext } from "../../api/context";
import { consoleErrorWithSentry } from "../../utils";

export function useOnePassword(): {
  createLoginItem: ({ vaultId, title, email, url }) => Promise<string>;
  deleteLoginItem: ({ vaultId, itemId }) => Promise<void>;
  updateTitle: ({ vaultId, itemId, title }) => Promise<void>;
  updateUsername: ({ vaultId, itemId, username }) => Promise<void>;
} {
  const api = useApiContext();
  const createLoginItem = useCallback(
    async ({ vaultId, title, email, url }) => {
      try {
        const response = await api.request({
          method: "post",
          url: `/v1/onepassword/${vaultId}`,
          data: {
            title,
            category: "LOGIN",
            urls: [
              {
                primary: true,
                label: "website",
                href: url,
              },
            ],
            fields: [
              {
                purpose: "USERNAME",
                type: "EMAIL",
                value: email,
              },
              {
                purpose: "PASSWORD",
                type: "CONCEALED",
                generate: true,
                recipe: {
                  length: 16,
                },
              },
            ],
          },
        });
        return response.data;
      } catch (exception) {
        consoleErrorWithSentry(exception);
      }
    },
    [api]
  );

  const deleteLoginItem = useCallback(
    async ({ vaultId, itemId }) => {
      try {
        await api.request({
          method: "delete",
          url: `/v1/onepassword/${vaultId}/${itemId}`,
        });
      } catch (exception) {
        consoleErrorWithSentry(exception);
      }
    },
    [api]
  );

  const updateTitle = useCallback(
    async ({ vaultId, itemId, title }) => {
      try {
        await api.request({
          method: "patch",
          url: `/v1/onepassword/${vaultId}/${itemId}/title`,
          data: {
            title,
          },
        });
      } catch (exception) {
        consoleErrorWithSentry(exception);
      }
    },
    [api]
  );

  const updateUsername = useCallback(
    async ({ vaultId, itemId, username }) => {
      try {
        await api.request({
          method: "patch",
          url: `/v1/onepassword/${vaultId}/${itemId}/username`,
          data: {
            username,
          },
        });
      } catch (exception) {
        consoleErrorWithSentry(exception);
      }
    },
    [api]
  );
  return { createLoginItem, deleteLoginItem, updateTitle, updateUsername };
}

export function useUpdateLogin() {
  const onePassword = useOnePassword();
  return useCallback(
    async ({ vaultId, itemId, username, title }) => {
      await onePassword.updateTitle({
        vaultId,
        itemId,
        title,
      });
      await onePassword.updateUsername({
        vaultId,
        itemId,
        username,
      });
    },
    [onePassword]
  );
}
