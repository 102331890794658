import { MenuItem, TextField } from "@mui/material";

import { type Role } from "../../../types";

type RoleSelectionProps = {
  roles: Role[];
  defaultRoleId: string;
  handleRoleChange: (val: string) => void;
};
export const RoleSelection = (props: RoleSelectionProps) => {
  const { defaultRoleId, roles, handleRoleChange } = props;
  const roleList = roles
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }))
    .map((role) => (
      <MenuItem key={role.id} value={role.id}>
        {role.name}
      </MenuItem>
    ));

  return roleList ? (
    <TextField
      id="userRolesSelection"
      name="roleSelection"
      fullWidth
      select
      label="Select role"
      required
      defaultValue={defaultRoleId}
      onChange={(e) => {
        handleRoleChange(e.target.value);
      }}
      variant="outlined"
      margin="normal"
      data-testid="new-user-roles-menu"
      size="small"
    >
      {roleList}
    </TextField>
  ) : (
    <></>
  );
};
export default RoleSelection;
