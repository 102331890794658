import { type PurchaseStatus } from "@doitintl/cmp-models";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { type ManualPurchaseItem, type WorkloadDetailsItem } from "../types";

const commonItemsDescriptions: FilterTableItemDescriptor<WorkloadDetailsItem>[] = [
  {
    headerText: "Billing account",
    value: "billingAccountId",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Hardware",
    value: "hardware",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Status",
    value: "status",
    filterOptions: {
      toOption: (value: PurchaseStatus) => ({
        value,
        label: value.toUpperCase(),
      }),
    },
    disableSorting: true,
  },
  {
    headerText: "30d baseline",
    value: "usageOnDemand",
    tooltip: "On demand usage baseline (30 days data)",
  },
  {
    headerText: "30d coverage",
    value: "currentCudCoverage",
    tooltip: "Last 30 days demand baseline with current inventory",
    filterType: "number",
  },
  {
    headerText: "30d DoiT coverage",
    value: "currentDoitCoverage",
    tooltip: "DoiT cud coverage - 30 days usage data and latest inventory",
    filterType: "number",
  },
];

const allWorkloadsItemsDescriptions: FilterTableItemDescriptor<WorkloadDetailsItem>[] = [
  ...commonItemsDescriptions,
  {
    headerText: "30d customer coverage",
    value: "currentCustomerCoverage",
    tooltip: "Customer cud coverage - 30 days usage data and latest inventory",
  },
  {
    headerText: "24h baseline",
    value: "optimizerBaseline",
    tooltip: "On demand usage baseline (24-48 hour data)",
  },
  {
    headerText: "24h coverage",
    value: "optimizerCoverage",
    tooltip: "24-48 hour demand baseline with current inventory",
  },
  {
    headerText: "Customer share",
    value: "customerShare",
    tooltip: "Customer share in global coverage",
  },
  {
    headerText: "Target coverage",
    value: "displayTargetCoverage",
    filterType: "number",
  },
  { value: "@", headerText: "" },
];

const pendingWorkloadItemsDescriptions: FilterTableItemDescriptor<WorkloadDetailsItem>[] = [
  ...commonItemsDescriptions,
  {
    headerText: "CUDs to purchase",
    value: "purchaseRecommendations",
    tooltip: "Recommendations of CUDs to purchase",
  },
  {
    headerText: "Global DoiT CUDs",
    value: "doitCuds",
    tooltip: "DoiT CUDs for the global workload (all customers)",
  },
  {
    headerText: "Global total CUDs",
    value: "totalCuds",
    tooltip: "Total CUDs for the global workload (all customers)",
  },
  {
    headerText: "24h global coverage",
    value: "coverage",
    tooltip: "Global coverage (24-48 hours)",
  },
  {
    headerText: "Global coverage after purchase",
    value: "globalCoverageWithPurchasePlan",
  },
  {
    headerText: "Customer share",
    value: "customerShare",
    tooltip: "Customer share in global coverage",
  },
  {
    headerText: "Commitment cost",
    value: "purchasePlanPrice",
    filterType: "number",
  },
  {
    headerText: "Target coverage",
    value: "displayTargetCoverage",
    filterType: "number",
  },
  { value: "@", headerText: "" },
];

export const purchaseSummaryDescription: FilterTableItemDescriptor<ManualPurchaseItem>[] = [
  {
    headerText: "Billing account",
    value: "billingAccountId",
    filterType: "string",
  },
  {
    headerText: "Region",
    value: "region",
    filterType: "string",
  },
  {
    headerText: "Type",
    value: "type",
    filterType: "string",
  },
  {
    headerText: "Hardware",
    value: "hardware",
    filterType: "string",
  },

  { headerText: "Amount", value: "amount", filterType: "number" },
  { headerText: "Cost", value: "cost", filterType: "number" },
];

export const { headerColumns: pendingWorkloadsHeaderColumns, filters: pendingWorkloadsFilters } =
  generateHeadersAndFilters(pendingWorkloadItemsDescriptions);

export const { headerColumns: allWorkloadsHeaderColumns, filters: allWorkloadsFilters } =
  generateHeadersAndFilters(allWorkloadsItemsDescriptions);

export const { headerColumns: purchaseSummaryColumns } = generateHeadersAndFilters(purchaseSummaryDescription);
