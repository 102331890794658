import { useMemo, useState } from "react";

import { type Collaborators, type PublicAccess } from "@doitintl/cmp-models";

import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import { CloudAnalyticsEntities } from "../utilities";
import { useAttributionGroupShareHandler } from "./hooks";

type Props = {
  attributionGroups: {
    name: string;
    collaborators: Collaborators;
    public: PublicAccess;
    id: string;
    type: string;
  }[];
  shareDialogOpen: boolean;
  onClose: () => void;
  title: string;
};

export const AttributionGroupShareDialog = ({ attributionGroups, shareDialogOpen, onClose, title }: Props) => {
  const [shareLoading, setShareLoading] = useState(false);

  const attributionGroupsWithoutPresets = useMemo(
    () => attributionGroups.filter((x) => x.type !== "preset"),
    [attributionGroups]
  );
  const handleChangeSharing = useAttributionGroupShareHandler({ attributionGroups, setShareLoading, onClose });

  return (
    <ShareDialog
      entity={CloudAnalyticsEntities.ATTRIBUTION_GROUP}
      handleChangeSharing={handleChangeSharing}
      loading={shareLoading}
      onClose={onClose}
      open={shareDialogOpen}
      title={title}
      shareEntities={attributionGroupsWithoutPresets}
      showPresetExcludedAlert={attributionGroups.length !== attributionGroupsWithoutPresets.length}
    />
  );
};
