import { type FAQ } from "../../../Components/FAQ/FAQAccordion";
import { type Step } from "../Common/Onboarding/types";

export type JourneyStep =
  | "unknown"
  | "disabled"
  | "checkingForOpportunities"
  | "applyingSavingsPlans"
  | "generatingSavings"
  | "invoicing"
  | "onboarded"
  | "noSavingsOpportunities"
  | "hasActivateCredits"
  | "noContract";

export const daysBeforeEndOfMonthWhenWeShowInvoicingState = 7;
export const daysAfterEndOfMonthWhenWeShowInvoicingState = 10;

export const steps: Step[] = [
  {
    title: "Checking for savings opportunities",
    subtitle:
      "Flexsave performs an analysis on your billing account to see if there are any eligible workloads. If savings opportunities are identified, we will start applying Flexsave to your account.",
  },
  {
    title: "Applying Flexsave to your account",
    subtitle:
      "Say goodbye to reservation management and workload forecasting. Flexsave is now managing coverage of your on-demand compute workloads for optimized cost and agility.",
  },
  {
    title: "Monthly savings",
    subtitle:
      "Flexsave helps to ensure that your stable on-demand compute workloads are covered by resource commitments. We continuously monitor workload usage and automatically apply and remove discount mechanisms.",
  },
  {
    title: "Invoicing",
    subtitle:
      "Your invoice will contain a line for DoiT Flexsave Savings. This line item, which contains all of the savings generated by Flexsave over on-demand, are converted to credits and deducted from your monthly invoice.",
  },
];

export const noSavingsOpportunitiesSteps: FAQ[] = [
  {
    question: "What is Flexsave?",
    answer:
      "DoiT Flexsave™ helps businesses manage and optimize cloud-compute discounts for Amazon Web Services (AWS) without the commitment management or forecasting challenges.",
  },
  {
    question: "Why are there no savings opportunities?",
    answer:
      "Flexsave requires at least $1.00/hr in stable Compute spend in order for coverage to begin. We will continue to monitor your workloads and apply coverage as soon as your workloads meet this criteria.",
  },
];

export const checkingForOpportunitiesSteps: FAQ[] = [
  {
    question: "What is Flexsave?",
    answer:
      "DoiT Flexsave™ helps businesses manage and optimize cloud-compute discounts for Amazon Web Services (AWS) without the commitment management or forecasting challenges.",
  },
  {
    question: "How does Flexsave work?",
    answer:
      "Flexsave provides simplified, automated management of cost optimization strategies to decrease cost and increase agility. Flexsave enables you to automatically attach the ideal blend of discount mechanisms to your organization. It continually re-adjusts the mix of discount mechanisms as your resource utilization changes to maximize your savings",
  },
  {
    question: "Is Flexsave compatible with cloud security auditing?",
    answer:
      "Yes. However, you can't interact with any AWS account operated by DoiT International. Except the commitments we have made available to you for discount sharing, the accounts we attach to your organization contain no resources.",
  },
  {
    question: "Why is using Flexsave better than purchasing discounts directly from AWS?",
    answer:
      "When purchasing directly from AWS, you must commit to at least one year of consistent usage to receive a discount. Flexsave does not require any commitments, allowing you to adapt your workload and machine types to suit your changing needs without the hassle of capacity planning and the risk of under or over-provisioning.",
  },
];

export const applyingSavingsPlansSteps: FAQ[] = [
  {
    question: "What is Flexsave?",
    answer:
      "DoiT Flexsave™ helps businesses manage and optimize cloud-compute discounts for Amazon Web Services (AWS) without the commitment management or forecasting challenges.",
  },
  {
    question: "How does Flexsave work?",
    answer:
      "Flexsave provides simplified, automated management of cost optimization strategies to decrease cost and increase agility. Flexsave enables you to automatically attach the ideal blend of discount mechanisms to your organization. It continually re-adjusts the mix of discount mechanisms as your resource utilization changes to maximize your savings",
  },
  {
    question: "Can I still buy my own Savings Plans and Reserved Instances?",
    answer:
      "Flexsave works with existing and new Savings Plans and Reserved Instances that you purchase. Flexsave provides the same savings as a 1-year Savings Plan, without any commitment.",
  },
];

export const generatingSavingsSteps: FAQ[] = [
  {
    question: "What is Flexsave?",
    answer:
      "DoiT Flexsave™ helps businesses manage and optimize cloud-compute discounts for Amazon Web Services (AWS) without the commitment management or forecasting challenges.",
  },
  {
    question: "Why do my Compute costs look higher in DoiT Console reports than AWS Cost Explorer?",
    answer:
      "The differences are because Flexsave uses 3-year No Upfront Savings Plans to cover your workloads. Within DoiT’s console, you are seeing the 1-year No Upfront rate for those workloads covered by Flexsave’s 3-year Savings Plans.",
  },
  {
    question: "Can I still buy my own Savings Plans and Reserved Instances?",
    answer:
      "Flexsave works with existing and new Savings Plans and Reserved Instances that you purchase. Flexsave provides the same savings as a 1-year Savings Plan, without any commitment.",
  },
  {
    question: "What should I do if I have expiring Savings Plans or Reserved Instances that I want Flexsave to cover?",
    answer:
      "When your active discount mechanisms expire, Flexsave will react to the increase in on-demand spend by applying additional Flexsave discounts.",
  },
];

export const invoicingSteps: FAQ[] = [
  {
    question: "What is Flexsave?",
    answer:
      "DoiT Flexsave™ helps businesses manage and optimize cloud-compute discounts for Amazon Web Services (AWS) without the commitment management or forecasting challenges.",
  },
  {
    question: "What will my invoice look like?",
    answer:
      "Your invoice will feature line items for each account that represent the cost of each account without Flexsave. A new line will appear in your invoice called “Flexsave Savings” that represents how much Flexsave saved you over on-demand.",
  },
  {
    question: "What if I run security tools like Amazon GuardDuty?",
    answer:
      "Costs associated with security tools or other items that generate costs within Flexsave projects in your organization will result in a charge on your invoice. These are represented by the “Flexsave / Customer incurred costs on Flexsave accounts” line item.",
  },
];
