export const ratingText = {
  DESCRIPTION: "Was this anomaly detected correctly?",
};

export const anomalySidePanelText = {
  BTN: "Anomaly details",
  TOOLTIP_DISABLE_TEXT: "Anomaly details are only available for anomalies detected after ",
};

export const anomalyDetailsText = {
  OPEN_IN_REPORT: "Open in Reports",
};
