import { Typography } from "@mui/material";

export const ThreadsOverviewHeader = () => (
  <>
    <Typography variant="h1" color="inherit" sx={{ mt: 2 }}>
      DoiT Threads
    </Typography>
    <Typography variant="body1" color="inherit" sx={{ mt: 1 }}>
      Integrate your insights into your Jira workflow
    </Typography>
  </>
);
