import { type ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { AssetModel, type AssetType, AssetTypeGoogleCloud, AssetTypeGoogleCloudDirect } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import keys from "lodash/keys";

import { Guard } from "../../../../Components/Guard";
import { useEntitiesOrPresentationModeEntities } from "../../../../Components/hooks/useEntitiesOrPrenstationModeEntities";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { StickyFooter } from "../../../../Components/StickyFooter";
import { visuallyHiddenStyle } from "../../../../constants/common";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type Contract } from "../../../../types";
import { getCurrencyByCode } from "../../../../utils/common";
import { permitUserToViewAsset } from "../../assetUtils";
import TransferGCProjects from "../Table/Partner/TransferGCProjects";
import { contractSupportMap, saveGcAsset } from "../utils";
import AccordionsArea from "./Accordions/AccordionsArea";
import { AssetFieldsArea } from "./Fields/AssetFieldsArea";
import GoogleAssetSupportDetails from "./Fields/GoogleAssetSupportDetails";
import GcAssetPageLoading from "./GCAssetPageLoading";
import { AssetHeader } from "./Header/AssetHeader";
import { type SuggestedTags } from "./types";

type Props = {
  suggestedTags: SuggestedTags;
};

const projectRowContent = (item: string | ReactElement): ReactElement => (
  <Typography variant="body2">{item}</Typography>
);
export const entText = (entPriorityId: string, entCur: string, entName: string): string =>
  `${entPriorityId} (${getCurrencyByCode(entCur)}) - ${entName}`;

function determineAllowProceed({ assetData, customer, isDoitEmployee, assetLoading, dataLoadError }) {
  const isPermittedAssetType = assetData?.type !== (AssetTypeGoogleCloudDirect as AssetType);
  const permitUser = permitUserToViewAsset(customer.id, isDoitEmployee, assetData?.customer.id);
  const failedData = !assetLoading && !assetData;
  return isPermittedAssetType && !dataLoadError && !failedData && permitUser;
}

const GCAssetPage = (props: Props) => {
  const { suggestedTags } = props;
  const history = useHistory();
  const { customerOrPresentationModeCustomer: customer, contracts, isProductOnlyCustomer } = useCustomerContext();
  const { entities } = useEntitiesOrPresentationModeEntities();
  const { isDoitOwner, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { onOpen: onSnackbarOpen, onClose: onSnackbarClose } = useSnackbar();
  const { customerId, assetId } = useParams<{ customerId: string; assetId: string }>();
  const [tagInputText, setTagInputText] = useState<string | null>(null);
  const backTo = `/customers/${customerId}/assets/google-cloud`;
  const [assetData, assetLoading, dataLoadError] = useDocumentDataOnce(getCollection(AssetModel).doc(assetId));
  const [selectedEntityId, setSelectedEntityId] = useState<string | null | undefined>(null);
  const [transferDialogsOpen, setTransferDialogsOpen] = useState<boolean>(false);
  const [didTagChange, setDidTagChange] = useState<boolean>(false);
  const [didEntityChange, setDidEntityChange] = useState<boolean>(false);

  const proceed = useMemo<boolean>(
    () => determineAllowProceed({ assetData, customer, isDoitEmployee, assetLoading, dataLoadError }),
    [assetData, assetLoading, customer, dataLoadError, isDoitEmployee]
  );

  const contract = useMemo<Contract | undefined>(
    () => contracts.find((contract) => contract.id === assetData?.contract?.id),
    [assetData?.contract?.id, contracts]
  );

  const hasSupport = useMemo<boolean>(() => !!contract && keys(contractSupportMap).includes(contract.type), [contract]);

  useEffect(() => {
    setSelectedEntityId(assetData?.entity?.id);
  }, [assetData, dataLoadError]);

  useEffect(() => {
    if (assetData?.tags) {
      setTagInputText(assetData.tags[0]);
    }
  }, [assetData?.tags]);

  useEffect(() => {
    const isTagDifferent = ![null, assetData?.tags?.[0]].includes(tagInputText);
    setDidTagChange(isTagDifferent);
  }, [assetData?.tags, tagInputText]);

  useEffect(() => {
    const isSelectedEntityDifferent = ![null, assetData?.entity?.id].includes(selectedEntityId);
    setDidEntityChange(isSelectedEntityDifferent);
  }, [assetData?.entity?.id, selectedEntityId]);

  const onSelectedEntityChange = (entId: string) => {
    setSelectedEntityId(entId);
  };

  const onTransferProjectsClick = () => {
    setTransferDialogsOpen(true);
  };

  const onTransferProjectsCancel = () => {
    setTransferDialogsOpen(false);
  };

  const onManageAccountClick = () =>
    assetData?.properties.billingAccountId &&
    window.open(`https://console.cloud.google.com/billing/${assetData.properties.billingAccountId}`);

  const shouldBillingAdminsShow =
    (typeof assetData !== "undefined" &&
      assetData !== null &&
      assetData?.entity?.id !== null &&
      assetData?.properties.billingAccountId?.length > 0 &&
      assetData?.properties.displayName?.length > 0) ||
    isDoitOwner;

  const onSave = useCallback(async () => {
    await saveGcAsset({
      didTagChange,
      didEntityChange,
      tagInputText,
      assetId,
      selectedEntityId,
      onSnackbarOpen,
      onSnackbarClose,
      history,
      backTo,
      standaloneCustomer: isProductOnlyCustomer,
    });
  }, [
    didTagChange,
    didEntityChange,
    assetId,
    tagInputText,
    onSnackbarOpen,
    onSnackbarClose,
    history,
    backTo,
    selectedEntityId,
    isProductOnlyCustomer,
  ]);

  const onCancel = () => {
    history.push(backTo);
  };

  const onTagInputChange = (text: string) => {
    if (tagInputText !== text) {
      setTagInputText(text);
    }
  };

  const defaultTags = assetData?.tags?.[0] || "";

  const guardedAssetScreen = (
    <Guard isAllowed={proceed}>
      <Box sx={{ ml: 2, maxWidth: "600px" }}>
        <AssetHeader
          transferProjectsAction={() => {
            setTransferDialogsOpen(true);
          }}
          manageAccountAction={onManageAccountClick}
          backTo={backTo}
          showTransfer={assetData?.type === (AssetTypeGoogleCloudDirect as AssetType)}
        />
        {assetData && (
          <>
            <Box pb={9}>
              <Box component="section">
                <Typography variant="h4" sx={visuallyHiddenStyle}>
                  Asset information
                </Typography>
                <AssetFieldsArea
                  suggestedTags={suggestedTags}
                  assetType={assetData.type}
                  displayName={assetData.properties.displayName}
                  entities={entities}
                  selectedEntityId={selectedEntityId || ""}
                  onSelectedEntityChange={onSelectedEntityChange}
                  assetTag={tagInputText ?? defaultTags}
                  onTagInputChange={onTagInputChange}
                  loading={false}
                />
              </Box>
              {hasSupport && contract && (
                /* We can get away with ! on contract here because `hasSupport` asserts that it exists in order to be truthy */
                <GoogleAssetSupportDetails contract={contract} />
              )}
              {assetData.type === AssetTypeGoogleCloud && (
                <AccordionsArea
                  shouldBillingAdminsShow={shouldBillingAdminsShow}
                  displayName={assetData.properties.displayName}
                  entityId={assetData?.entity?.id || null}
                  billingAccountId={assetData.properties.billingAccountId}
                  admins={assetData.properties.admins}
                  projects={assetData.properties.projects}
                  projectRowContent={projectRowContent}
                />
              )}
            </Box>
            <StickyFooter
              disabled={!didEntityChange && !didTagChange}
              onClick={onSave}
              buttonText="Save and close"
              onCancel={onCancel}
              buttonTextLoading="Saving..."
              cancelButtonStyle={{ color: "inherit" }}
              sx={{ pr: 3.81 }}
            />
          </>
        )}
        {transferDialogsOpen && assetData && (
          <TransferGCProjects
            onTransferProjectsClick={onTransferProjectsClick}
            onTransferProjectsCancel={onTransferProjectsCancel}
            assetId={assetId}
            firstDialogOpen={transferDialogsOpen}
          />
        )}
      </Box>
    </Guard>
  );

  return assetLoading ? <GcAssetPageLoading backTo={backTo} onCancel={onCancel} /> : guardedAssetScreen;
};
export default GCAssetPage;
