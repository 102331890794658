import { useMemo } from "react";

import { Link, Stack, TableCell } from "@mui/material";
import Typography from "@mui/material/Typography";
import camelCase from "lodash/camelCase";
import { DateTime } from "luxon";

import { cloudflowTexts } from "../../../assets/texts";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { type CloudflowWSnap } from "../../../types/Cloudflow";
import { StatusChip } from "../CloudflowBuilder/Topbar/StatusChip";

export type CloudflowRowProps = {
  row: CloudflowWSnap;
  actions: {
    onViewRunHistory: (row?: CloudflowWSnap) => void;
    onEdit: (id: string) => void;
    onUnpublish: (id: string) => void;
    onDelete: (id: string) => void;
    onNameClick: (link: string) => void;
  };
};

const CloudflowRow = ({ row, actions: { onEdit, onViewRunHistory, onDelete, onNameClick } }: CloudflowRowProps) => {
  const routeMatchURL = useRouteMatchURL();

  const formattedLastCompleted = useMemo(() => {
    if (!row.data?.updatedAt) {
      return "";
    }
    const date = DateTime.fromJSDate(row.data.updatedAt.toDate());
    if (date?.isValid) {
      return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
    }
    return "";
  }, [row.data?.updatedAt]);

  const menuOptions: ThreeDotsMenuOption[] = [
    {
      label: cloudflowTexts.VIEW_RUN_HISTORY,
      key: camelCase(cloudflowTexts.VIEW_RUN_HISTORY),
      action: () => {
        onViewRunHistory(row);
      },
      dataCy: "viewRunHistory",
    },
    {
      label: cloudflowTexts.EDIT_CLOUDFLOW,
      key: camelCase(cloudflowTexts.EDIT_CLOUDFLOW),
      action: () => {
        onEdit(row.ref.id);
      },
      dataCy: "editCloudflow",
    },
    {
      label: <Typography color="error">{cloudflowTexts.DELETE_CLOUDFLOW}</Typography>,
      key: camelCase(cloudflowTexts.DELETE_CLOUDFLOW),
      action: () => {
        onDelete(row.ref.id);
      },
      dataCy: "deleteCloudflow",
    },
  ];

  const linkDestination = `${routeMatchURL}/edit/${row.ref.id}`;

  return (
    <>
      <TableCell>
        <Link
          style={{ cursor: "pointer" }}
          onClick={() => {
            onNameClick(linkDestination);
          }}
          color="inherit"
          variant="body2"
        >
          {row.data.name}
        </Link>
      </TableCell>
      <TableCell>{row.data.owner}</TableCell>
      <TableCell>
        <StatusChip status={row.data.status} />
      </TableCell>
      <TableCell>{formattedLastCompleted}</TableCell>
      <TableCell padding="none">
        <Stack alignItems="end">
          <ThreeDotsMenu options={menuOptions} />
        </Stack>
      </TableCell>
    </>
  );
};
export default CloudflowRow;
