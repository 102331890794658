import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type TableNotification } from "./types";

const itemsDescriptions: FilterTableItemDescriptor<TableNotification>[] = [
  {
    headerText: "Name",
    value: "name",
    filterType: "string",
  },
  {
    headerText: "Provider",
    value: "providerType",
    filterType: "string",
  },
  {
    headerText: "Channels",
    value: "providerTarget",
  },
  {
    headerText: "Created by",
    value: "createdBy",
    filterType: "string",
  },
  {
    headerText: "",
    value: "@",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
