import { Stack, TextField, type TextFieldProps } from "@mui/material";
import { Form, useFormikContext } from "formik";

import { ssoTexts } from "../../../../assets/texts";
import { type OIDCForm, type SAMLForm, type SSOForm, type SSOProviders } from "../types";
import { SsoInfoBox } from "./SsoInfoBox";

type Props = {
  fieldProps: Partial<Record<keyof SAMLForm | keyof OIDCForm, TextFieldProps>>;
  ssoType: SSOProviders;
  disabled?: boolean;
};

const SsoProviderForm = ({ fieldProps, disabled, ssoType }: Props) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<SSOForm>();

  const keyMapping: { saml: keyof SAMLForm; oidc: keyof OIDCForm } = {
    saml: "idpEntityId",
    oidc: "clientId",
  };
  const idpUrlKey = keyMapping[ssoType];

  return (
    <Form>
      <Stack spacing={2}>
        {Object.entries(values).map(([key, value]) => (
          <TextField
            {...(fieldProps[key] && { ...fieldProps[key] })}
            key={key}
            required
            value={value}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!touched[key] && Boolean(errors[key])}
            helperText={touched[key] && errors[key] ? errors[key] : ssoTexts[key]?.HELPER_TEXT}
            label={ssoTexts[key]?.LABEL}
            type="text"
            name={key}
            data-cy={key}
            margin="none"
            variant="outlined"
            rows="8"
            size="medium"
            fullWidth
          />
        ))}
        <SsoInfoBox idpUrl={values?.[idpUrlKey]} />
      </Stack>
    </Form>
  );
};

export default SsoProviderForm;
