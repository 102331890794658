import { Grid, Typography } from "@mui/material";

type Props = {
  label: string;
  value: string;
};

export const ThreadDetailsField = ({ label, value }: Props) => (
  <>
    <Grid container item xs={12} md={3}>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {label}
      </Typography>
    </Grid>
    <Grid container item xs={12} md={9}>
      <Typography variant="body1">{value}</Typography>
    </Grid>
  </>
);
