import { useMemo } from "react";

import { type TotalAverageUsageModal } from "@doitintl/cmp-models";

import {
  type BulkPurchaseItem,
  type CustomerAggregatedWorkloadInfo,
  type GroupedWorkloads,
  type Workload,
} from "../types";
import { createAggregatedBulkPurchase } from "../utils/workloadUtils";
import { useDebounce } from "./useDebounce";

export function useBulkPurchases(
  customersAgg?: Map<string, CustomerAggregatedWorkloadInfo>,
  groupedWorkloadsMap?: GroupedWorkloads,
  totalAverageUsagesMap?: Map<string, TotalAverageUsageModal>
) {
  const debouncedWorkloads = useDebounce(groupedWorkloadsMap);
  const debouncedCustomers = useDebounce(customersAgg);
  return useMemo<BulkPurchaseItem[] | undefined>(() => {
    if (debouncedCustomers && debouncedWorkloads && totalAverageUsagesMap) {
      const bulkPurchaseItems: BulkPurchaseItem[] = [];
      for (const [workloadSku, workloadsMap] of debouncedWorkloads.entries()) {
        const workloads = Array.from(workloadsMap.workloadsMap.values() ?? ([] as Workload[]));
        const bulkIncludedWorkloads = workloads.filter(
          (workload) => !debouncedCustomers.get(workload.customerId)?.excludeCustomerFromBulk
        );
        if (bulkIncludedWorkloads.length) {
          bulkPurchaseItems.push(
            createAggregatedBulkPurchase(workloadSku, bulkIncludedWorkloads || [], totalAverageUsagesMap)
          );
        }
      }
      return bulkPurchaseItems;
    }
  }, [debouncedCustomers, debouncedWorkloads, totalAverageUsagesMap]);
}
