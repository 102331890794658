import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import InfrastructureIconDark from "../../../assets/infrastructure-dark-mode.svg";
import InfrastructureIconLight from "../../../assets/infrastructure-light-mode.svg";
import { helpURLs } from "../../../assets/urls";
import { CopyCodeBlock } from "../../../Components/CopyCodeBlock/CopyCodeBlock";
import { type AccessPlatform, Platform, type Severity } from "../../types";
import { getSlaPolicyTime } from "../../utils";
import SauronStep from "./SauronStep";

const defaultConcedefyRole = "roles/viewer";

type props = {
  seveirty?: Severity | null;
  classification: string;
  platform: string;
  slas: any[];
  gcp: AccessPlatform;
  aws: AccessPlatform;
  concedefyDisabled: boolean;
};

export const AccessStep = ({ classification, platform, seveirty, slas, gcp, aws, concedefyDisabled }: props) => {
  const { t } = useTranslation("services");
  const time = getSlaPolicyTime(classification, platform, seveirty?.value, slas, t);
  const theme = useTheme();

  const concedefyRole = useMemo(
    () => (gcp?.concedefyRole && gcp?.concedefyRole !== "" ? gcp?.concedefyRole : defaultConcedefyRole),
    [gcp?.concedefyRole]
  );

  const goToPermissionsDocs = useCallback(() => {
    window.open(helpURLs.GRANT_GCP_ACCESS);
  }, []);

  const slaText = useCallback(() => {
    if (time === null) {
      return "";
    }
    return `${t("AccessStep.sla1")} ${time} ${seveirty?.value !== "urgent" ? t("AccessStep.sla2") : ""}`;
  }, [seveirty?.value, time, t]);

  const sauronRoleAvailable = useCallback(
    () => (
      <Box display="flex" sx={{ mt: 5, justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <img
          style={{ width: 200 }}
          src={theme.palette.mode === "dark" ? InfrastructureIconDark : InfrastructureIconLight}
        />
        <Typography sx={{ mt: 4 }} variant="h2">
          {t("AccessStep.success")}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {slaText()}
        </Typography>
      </Box>
    ),
    [slaText, theme.palette.mode, t]
  );

  if (aws.sauronRole) {
    return sauronRoleAvailable();
  }

  return (
    <Box sx={{ p: 1 }}>
      <Typography sx={{ mb: 1, mt: 6 }} variant="h4" color="text.primary">
        {slaText()}
      </Typography>
      <Typography sx={{ mt: 2 }} variant="body2" color="text.secondary">
        {t("AccessStep.readAccesss1")} <strong>{t("AccessStep.readAccesss2")}</strong> {t("AccessStep.readAccesss3")}
        <br />
        <Link sx={{ cursor: "pointer" }} onClick={goToPermissionsDocs}>
          {t("AccessStep.learnMore")} <OpenInNewIcon sx={{ mb: -0.5 }} fontSize="small" />
        </Link>
      </Typography>
      {Platform.gcp === platform && !concedefyDisabled && (
        <Box sx={{ mt: 5 }}>
          <Typography variant="body2">{t("AccessStep.runCode")}</Typography>
          <Stack direction="row" alignItems="flex-start">
            <CopyCodeBlock
              base="gcloud projects add-iam-policy-binding $GCProjectName --member=group:$concedefyReadOnlyEmail --role=$concedefyRole --condition=None"
              variables={{
                $GCProjectName: { value: gcp.projectName, name: "Project Name" },
                $concedefyReadOnlyEmail: { value: gcp.concedefyEmail, name: "Group" },
                $concedefyRole: { value: concedefyRole, name: "Role" },
              }}
              callbackClick={() => {}}
            />
          </Stack>
        </Box>
      )}
      {Platform.aws === platform && (
        <Box sx={{ mt: 5 }}>
          <SauronStep accountId={aws.projectName} />
        </Box>
      )}
    </Box>
  );
};
