import { type ReactNode } from "react";

import { type EntitlementKey } from "@doitintl/cmp-models";
import { type Theme } from "@mui/material/styles";
import { type SxProps } from "@mui/system";

import { ThirdLevelNav } from "./ThirdLevelNav";

export type SideNavigationItem = {
  value: string;
  label: string;
  to: string;
  children?: ReactNode;
  entitlement?: EntitlementKey;
};

export type SideNavigationData = {
  value: string | number;
  onChange?(value: unknown): void;
  items: SideNavigationItem[];
  extraWidth?: boolean;
};

type Props = {
  title?: string;
  backButtonRoute?: string;
  data?: SideNavigationData;
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export const SideNavigationLayout = ({ title, backButtonRoute, data, children, sx }: Props) => (
  <ThirdLevelNav title={title} backButtonRoute={backButtonRoute} data={data} sx={sx}>
    {children}
  </ThirdLevelNav>
);
