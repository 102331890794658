import { useHistory } from "react-router";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { IconWithLink } from "../../Common/IconWithLink";

export const InviteCollegeLink = () => {
  const customerId = useCustomerId();
  const history = useHistory();

  return (
    <IconWithLink
      onClick={() => {
        history.push(`/customers/${customerId}/iam/users`);
      }}
      text="Invite a colleague to assist"
      icon={<PersonAddIcon />}
    />
  );
};
