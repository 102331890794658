import AddchartIcon from "@mui/icons-material/Addchart";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Stack, Typography } from "@mui/material";

import { LinkCard } from "../../../Components/LinkCard";

type Props = {
  onNewWidgetClicked: () => void;
  onNewReportClicked: () => void;
  onAddExistingReportClicked: () => void;
  onAvaClicked: () => void;
  showActions: boolean;
  showAva: boolean;
};

export function EmptyDashboard({
  onNewWidgetClicked,
  onNewReportClicked,
  onAddExistingReportClicked,
  onAvaClicked,
  showActions,
  showAva,
}: Props) {
  if (!showActions) {
    return (
      <Stack alignItems="center" width="100%">
        <Stack height="70vh" justifyContent="center">
          <Typography variant="h3" align="center" pt={5}>
            You are unable to add content to this dashboard
          </Typography>
          <Typography variant="body1" align="center" pt={2} color="text.secondary">
            This dashboard is public but not editable or customizable
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" width="100%">
      <Stack height="70vh" justifyContent="center">
        <Typography variant="h3" align="center" pt={5}>
          Start adding content to your dashboard
        </Typography>
        <Stack alignItems="center" p={3}>
          <Box
            sx={{ display: "grid", gridTemplateColumns: { xs: "repeat(1, 100%)", md: "repeat(2, 49%)" }, gap: 2 }}
            width={{
              xs: "100%",
              md: 600,
            }}
          >
            <LinkCard
              icon={<AddchartIcon />}
              title="Add existing reports"
              description="Browse existing reports including our list of preset reports."
              onClick={onAddExistingReportClicked}
            />
            <LinkCard
              icon={<AddchartIcon />}
              title="Create and add a new report"
              description="Create a new report and add it to your dashboard."
              onClick={onNewReportClicked}
            />
            <LinkCard
              icon={<AddchartIcon />}
              title="Add a preset widget"
              description="Browse our extensive list of preset widgets."
              onClick={onNewWidgetClicked}
            />
            {showAva && (
              <LinkCard
                icon={<AutoAwesomeIcon />}
                title="Create a report using Ava"
                description="Utilize our AI tool AVA to create reports using common language."
                onClick={onAvaClicked}
              />
            )}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
