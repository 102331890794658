import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";

export function getModelByFieldReference(
  model: UnwrappedApiServiceModelDescriptor,
  fieldPath: string[]
): UnwrappedApiServiceModelDescriptor {
  const [currentField, ...remainingFields] = fieldPath;

  switch (model.type) {
    case ModelType.STRUCTURE: {
      if (!currentField) {
        return model;
      }
      const member = model.members[currentField];
      if (member) {
        return getModelByFieldReference(member.model, remainingFields);
      } else {
        return model;
      }
    }
    case ModelType.LIST: {
      if (model.member.model.type === ModelType.STRUCTURE || model.member.model.type === ModelType.LIST) {
        return getModelByFieldReference(model.member.model, fieldPath);
      } else {
        return model.member.model;
      }
    }
    default:
      return model;
  }
}
