import { useCallback, useMemo, useState } from "react";

import { type PerkModel } from "@doitintl/cmp-models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/material";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { SafeHtml } from "../../../Components/SafeHtml";
import { useFullScreen } from "../../../utils/dialog";
import { type RegisterInterestFromType, type SectionTitles } from "../types";
import { MarketplaceListings } from "./MarketplaceListings";
import { PerkVideo } from "./PerkVideo";

export const Section = styled("div")({
  marginTop: "8px",
});

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:before": {
      display: "none",
    },
  })
);

type Props = {
  perk: PerkModel;
  sectionTitles: SectionTitles;
  stickyHeaderActive: boolean;
  registerInterest: (interestFrom: RegisterInterestFromType) => Promise<void>;
};

export const PerkContent = ({ perk, sectionTitles, stickyHeaderActive, registerInterest }: Props) => {
  const { isMobile } = useFullScreen();
  const [expandedModule, setExpandedModule] = useState<string>("");

  const transform = useCallback((node: Element, data) => {
    if (data.tagName === "span") {
      node.setAttribute("style", "color: inherit");
    }
    return node;
  }, []);

  const scrollMarginTop = useMemo<string>(() => (stickyHeaderActive ? "380px" : "238px"), [stickyHeaderActive]);

  return (
    <Container disableGutters={isMobile}>
      <PerkVideo perk={perk} setExpandedModule={setExpandedModule} sectionTitles={sectionTitles} />

      <Section id={sectionTitles?.overview} sx={{ scrollMarginTop }}>
        <Typography variant="h4">{sectionTitles.overview}</Typography>
        <Typography component="div">
          <SafeHtml html={perk.fields?.description} transform={transform} />
        </Typography>
      </Section>

      <Section id={sectionTitles.checklist} sx={{ scrollMarginTop }}>
        <Typography variant="h4">{sectionTitles.checklist}</Typography>
        <Typography component="div">
          <SafeHtml html={perk.fields?.checklist} transform={transform} />
        </Typography>
      </Section>

      <Section id={sectionTitles.agenda} sx={{ scrollMarginTop }}>
        <Typography variant="h4">{sectionTitles.agenda}</Typography>
        <Typography component="div">
          <SafeHtml html={perk.agenda} transform={transform} />
        </Typography>
      </Section>

      {Array.isArray(perk.modules) && !!perk.modules.length && (
        <Section
          id={sectionTitles.modules}
          sx={{
            scrollMarginTop,
            overflowAnchor: "auto",
          }}
        >
          <Typography variant="h4">{sectionTitles.modules}</Typography>

          {perk.modules.map((module) => (
            <Accordion
              expanded={expandedModule === module.title}
              key={module.title}
              onChange={(_, expanded) => {
                if (expanded) {
                  setExpandedModule(module.title);
                } else {
                  setExpandedModule("");
                }
              }}
            >
              <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {module.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "0px 0px 20px 0px" }}>
                <Typography component="div">
                  <SafeHtml html={module.description} transform={transform} />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Section>
      )}

      <MarketplaceListings perk={perk} registerInterest={registerInterest} />
    </Container>
  );
};
