import { Box, LinearProgress, Typography } from "@mui/material";

type Props = {
  value: number;
};

export const LinearProgressWithLabel = ({ value }: Props) => (
  <Box display="flex" alignItems="center" width="100%">
    <Box width="100%" mr={1}>
      <LinearProgress variant="determinate" value={value} color="primary" />
    </Box>
    <Box minWidth={35}>
      <Typography variant="caption" color="textSecondary">
        {value}%
      </Typography>
    </Box>
  </Box>
);
