import { useCallback, useEffect, useMemo, useState } from "react";

import { CustomerModel, KnownIssueModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { DateTime, type DurationUnits } from "luxon";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type KnownIssue } from "../../../Pages/KnownIssues/types";
import { getProducts } from "../../../Pages/KnownIssues/utils";
import {
  compareProductsFilterMultiSelectProductOption,
  computeAvailability,
  getIncidentClassifications,
  getIncidentEndDate,
  isServiceUsedByCustomer,
} from "./funcs";
import {
  type CategoriesTitlesItem,
  ClassificationEnum,
  type CustomerServicesWithId,
  type DowntimeItem,
  type highchartsDataParams,
  type IntervalMap,
  type ProductsFilterMultiSelectProductOption,
  type TimeIntervalName,
} from "./types";

export const useGetCustomerServices = () => {
  const [customerServices, setCustomerServices] = useState<CustomerServicesWithId[] | undefined>();
  const { customer } = useCustomerContext({ allowNull: true });

  useEffect(() => {
    if (!customer?.id) {
      setCustomerServices([]);
      return;
    }
    getCollection(CustomerModel)
      .doc(customer.id)
      .collection("cloudServices")
      .onSnapshot((services) => {
        setCustomerServices(services.docs.map((service) => ({ ...service.asModelData(), id: service.id })));
      });
  }, [customer?.id]);

  return { customerServices };
};

export const useCalculateKnownIssuesDownTime = (
  startTime: DateTime,
  endTime: DateTime,
  interval: TimeIntervalName,
  productFilters: string[],
  applyRegionCheck: null | ((issues: KnownIssue[]) => KnownIssue[])
) => {
  const [allIssuesInTimeRange, setAllIssuesInTimeRange] = useState<KnownIssue[] | undefined>();
  const [data, setData] = useState<highchartsDataParams>();
  const [allAffectedProducts, setAllAffectedProducts] = useState<ProductsFilterMultiSelectProductOption[]>([]);
  const [startTimeMemo, setStartTimeMemo] = useState<DateTime>(startTime);
  const [endTimeMemo, setEndTimeMemo] = useState<DateTime>(endTime);
  const { customerServices } = useGetCustomerServices();

  const intervalMemo = useMemo(() => interval, [interval]);
  const productFiltersMemo = useMemo(() => productFilters, [productFilters]);

  const updateStartEndTimes = useCallback((startTime: DateTime, endTime: DateTime) => {
    setEndTimeMemo(endTime);
    setStartTimeMemo(startTime);
  }, []);

  useEffect(
    () =>
      getCollection(KnownIssueModel)
        .where("dateTime", ">=", new Date(startTimeMemo.toJSDate()))
        .where("dateTime", "<=", new Date(endTimeMemo.toJSDate()))
        .orderBy("dateTime", "asc")
        .onSnapshot((knownIssuesSnap) => {
          const allIssues = knownIssuesSnap.docs.map((doc) => ({
            ...doc.asModelData(),
            id: doc.id,
          }));
          const issuesRegionFiltered = applyRegionCheck ? applyRegionCheck(allIssues) : allIssues;
          const issues = issuesRegionFiltered.map((issueDocument) => ({
            ...issueDocument,
            id: issueDocument.id,
          }));
          setAllIssuesInTimeRange(issues);
          setAllAffectedProducts(
            uniqWith(
              issues
                .reduce((acc, issue) => {
                  const products = getProducts(issue).split(", ");
                  acc.push(
                    ...products.map((product) => ({
                      product,
                      platform: issue.platform,
                    }))
                  );
                  return acc;
                }, [] as ProductsFilterMultiSelectProductOption[])
                .sort((a, b) => compareProductsFilterMultiSelectProductOption(a, b)),
              isEqual
            )
          );
        }),
    [startTimeMemo, endTimeMemo, applyRegionCheck]
  );

  const isIncidentInInterval = (incident: KnownIssue, dateIndex: number, interval: TimeIntervalName) => {
    const incidentStart = DateTime.fromJSDate(incident.dateTime.toDate()).toUTC();
    const incidentEnd = DateTime.fromJSDate(getIncidentEndDate(incident)).toUTC();
    const range = interval.toLowerCase() as "day" | "hour" | "week" | "month";
    const timestamp = DateTime.fromMillis(dateIndex).startOf(range);

    const diff = incidentStart.startOf(range).diff(timestamp);
    if (diff.milliseconds < 0) {
      return false;
    }

    const timeBetween = incidentEnd?.diff(incidentStart);
    const validInterval = timeBetween.as(range) < 1 ? 1 : timeBetween.as(range);

    switch (interval) {
      case "Hour":
        return Math.abs(diff.as("hour")) < validInterval;
      case "Day":
        return Math.abs(diff.as("day")) < validInterval;
      case "Week":
        return Math.abs(diff.as("week")) < validInterval;
      case "Month":
        return Math.abs(diff.as("month")) < validInterval;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!allIssuesInTimeRange) {
      return;
    }

    const relevantIssues = (
      customerServices ? isServiceUsedByCustomer(allIssuesInTimeRange, customerServices) : allIssuesInTimeRange
    ).filter((issue) =>
      getProducts(issue)
        .split(", ")
        .some((service) => productFiltersMemo.includes(service))
    );

    // post-process the data into something that can be applied to Highcharts
    // prepare interval as object keys
    const durationUnits = `${intervalMemo}s`.toLowerCase();
    const numPeriods = Math.ceil(endTimeMemo?.diff(startTimeMemo, durationUnits as DurationUnits)[durationUnits]);
    const xAxisTimestamps: DateTime[] = [];
    for (let v = 0; v <= numPeriods; v++) {
      xAxisTimestamps.push(startTimeMemo.plus({ [durationUnits]: v }));
    }

    // for each interval (xAxis value, above), find incidents included inside that interal (there may be incidents that bridge two intervals)
    // start OR end is between interval's start+end OR (start is before interval starts, and end is after interval ends -- especially for hourly)

    // init
    const intervalMap: IntervalMap = {};
    xAxisTimestamps.forEach((startTime) => {
      intervalMap[startTime.toMillis()] = {
        startTime,
        endTime: startTime.plus({ [durationUnits]: 1 }),
        incidents: [],
      };
    });

    relevantIssues.forEach((incident) => {
      const intervalStartTimes = Object.keys(intervalMap); // because maps give O(1)
      intervalStartTimes.forEach((ist, i) => {
        const intervalStartTime = parseInt(intervalStartTimes[i]);
        if (isIncidentInInterval(incident, intervalStartTime, intervalMemo) || incident.status === "ongoing") {
          intervalMap[intervalStartTime].incidents.push(incident);
        }
      });
    });

    // create arrays of "categories" and series, to be used by Highcharts
    // for each interval, compute percentage up/down time.
    // remember that incidents should already be sorted by start time, from the original query

    const categoriesTitles: CategoriesTitlesItem[] = [];
    const availableSeries: number[] = [];
    const partialDownSeries: DowntimeItem[] = [];
    const fullDownSeries: DowntimeItem[] = [];

    Object.keys(intervalMap).forEach((key) => {
      const { availablePercent, partialDownPercent, fullDownPercent } = computeAvailability(intervalMap[key]);
      const incidents = intervalMap[key].incidents;

      availableSeries.push(availablePercent);

      const partialIncidents = incidents.filter(
        (incident) => ClassificationEnum.PartialDowntime === getIncidentClassifications(incident)
      );

      partialDownSeries.push({
        val: partialDownPercent,
        incidents: partialIncidents,
      });

      const percent = fullDownPercent > 100 ? 100 : fullDownPercent;

      fullDownSeries.push({
        val: percent,
        incidents: incidents.filter(
          (incident) => ClassificationEnum.FullDowntime === getIncidentClassifications(incident)
        ),
      });
      // format date strings differently, depending on interval
      const formattedDate = intervalMap[key].startTime.toUTC().toFormat("dd LLL");
      const formattedTime = intervalMap[key].startTime.toUTC().startOf("hour").toFormat("HH:mm");
      const xTick = { formattedDate, formattedTime };
      categoriesTitles.push(xTick);
    });

    // all done
    setData({ categoriesTitles, availableSeries, partialDownSeries, fullDownSeries });
  }, [allIssuesInTimeRange, intervalMemo, endTimeMemo, startTimeMemo, productFiltersMemo, customerServices]);
  return { data, allAffectedProducts, updateStartEndTimes };
};
