import { useMemo } from "react";

import { AppModel, CustomerModel, UserModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";

export function useCustomerNotificationSettings(hasManageSettingsPermission: boolean) {
  const customerId = useCustomerId();
  const ref = getCollection(CustomerModel).doc(customerId).collection("notifications");
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  const query =
    hasManageSettingsPermission || isDoitEmployee
      ? ref
      : ref.where("providerTarget.EMAIL", "array-contains", currentUser.email);

  return useCollectionData(query, { idField: "id" });
}

export function useUserNotificationSettings() {
  const {
    customer: { ref: customerRef },
  } = useCustomerContext();
  const { userId } = useAuthContext({ mustHaveUser: true });

  const ref = useMemo(() => getCollection(UserModel).doc(userId).collection("userPersonalNotifications"), [userId]);

  const [userNotifications, userNotificationsLoading] = useCollectionData(ref.where("customerRef", "==", customerRef), {
    idField: "id",
  });

  return { userNotifications, userNotificationsLoading };
}

export function useNotificationsData() {
  const ref = getCollection(AppModel).doc("userNotifications");
  return useDocumentData(ref)[0]?.notifications;
}
