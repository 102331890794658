import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useApiContext } from "../../api/context";
import { globalText } from "../../assets/texts";
import LoadingButton from "../../Components/LoadingButton";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { consoleErrorWithSentry } from "../../utils";
import { formatCurrency } from "../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import mixpanel from "../../utils/mixpanel";
import { type SandboxPolicyWRef } from "./types";

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: 500,
  },
}));

type Props = {
  onClose: () => void;
  policy: SandboxPolicyWRef | null;
};

const SandboxCreateDialog = ({ onClose, policy }: Props) => {
  const api = useApiContext();
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const classes = useStyles();
  const { isMobile: matches } = useFullScreen();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createSandbox = async () => {
    try {
      setIsSubmitting(true);
      mixpanel.track("assets.google-cloud.sandbox.create");
      const response = await api.request({
        method: "post",
        url: `/v1/customers/${customer.id}/google-cloud/sandbox`,
      });

      if (response.status === 200) {
        showSnackbar({
          message: (
            <span>
              {"Sandbox project "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://console.cloud.google.com/home/dashboard?project=${response.data}`}
                color="inherit"
                underline="always"
                className={classes.link}
              >
                {response.data}
              </Link>
              {" was created"}
            </span>
          ),
          variant: "success",
          autoHideDuration: null,
          action: [
            <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
              <CloseIcon />
            </IconButton>,
          ],
        });
        onClose();
      }
    } catch (error) {
      consoleErrorWithSentry(error);
      onClose();
    }
    setIsSubmitting(false);
  };

  if (!policy?.data?.active) {
    return null;
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="sandbox-policy-dialog-title"
      onClose={preventOnCloseWhile(isSubmitting, onClose)}
      fullScreen={matches}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="sandbox-policy-dialog-title">Create sandbox project</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item>
            <Alert variant="standard" severity="info">
              <AlertTitle>Notice</AlertTitle>A sandbox is a standard Google Cloud project that is controlled using
              budgets according to the policies set by your organization, with a spending threshold of{" "}
              {formatCurrency(policy.data.amount, "USD", 2)}
              {policy.data.interval === "monthly" && ", every month"}. Once you reach this threshold,{" "}
              {policy.data.action === "disable_billing"
                ? "your sandbox will be disconnected from billing."
                : "you will get an email alert."}
            </Alert>
          </Grid>
          <Grid item>
            <Alert variant="standard" severity="warning">
              <AlertTitle>Warning</AlertTitle>
              Sandbox projects are NOT intended for production use as they may be disconnected from billing without
              warning according to the organization's sandbox policy.
            </Alert>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="text" onClick={onClose} disabled={isSubmitting}>
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={createSandbox}
          disabled={!(userRoles.sandboxAdmin || userRoles.sandboxUser) || isSubmitting}
          loading={isSubmitting}
          mixpanelEventId="sandbox.create"
        >
          {globalText.CREATE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SandboxCreateDialog;
