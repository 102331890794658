import { CustomerModel, type FlexsaveType, FlexsaveTypes, type StandaloneEstimationsGCP } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type AxiosResponse } from "axios";
import { DateTime } from "luxon";

import { type SavingsEstimations } from "../../Pages/Flexsave/types";
import { consoleErrorWithSentry } from "../errorHandling";
import { type CompositeResponse, type SalesforceApi, salesforceBaseUrl, SalesforceRequestMethod } from "./salesforce";
import { UpdateSalesforce } from "./updateSalesforce";

export type SalesforceUser = { id: string; email: string; firstName: string; lastName: string };
export type SalesforceCustomer = { id: string; name: string; salesforce?: { opportunityId?: string; id?: string } };

const callSalesforceApi = async (
  salesforceApi: SalesforceApi,
  customer: SalesforceCustomer,
  user: SalesforceUser,
  platform?: FlexsaveType,
  savings?: StandaloneEstimationsGCP | SavingsEstimations
): Promise<AxiosResponse<CompositeResponse>> =>
  salesforceApi.compositeRequest(
    [
      {
        method: SalesforceRequestMethod.GET,
        referenceId: "AccountInfo",
        url: `${salesforceBaseUrl}/sobjects/Account/Firestore_Id__c/${customer.id}`,
      },
      {
        method: SalesforceRequestMethod.PATCH,
        referenceId: "Contact",
        url: `${salesforceBaseUrl}/sobjects/Contact/Firestore_Id__c/${user.id}`,
        body: {
          FirstName: user.firstName,
          LastName: user.lastName,
          Email: user.email,
          AccountId: "@{AccountInfo.Id}",
          RecordTypeId: "0128c000001Jam0AAC",
          Lifecycle_Stage__c: "Opportunity",
          Hubspot_Contact_Type__c: "Prospect",
        },
      },
      {
        method: SalesforceRequestMethod.GET,
        referenceId: "NewContact",
        url: `${salesforceBaseUrl}/sobjects/Contact/@{Contact.id}`,
      },
      {
        method: SalesforceRequestMethod.POST,
        referenceId: "Opportunity",
        url: `${salesforceBaseUrl}/sobjects/Opportunity`,
        body: {
          Name: `${customer.name} - ${
            platform === FlexsaveTypes.GCP ? "Flexsave Standalone - GCP" : "Flexsave Standalone - AWS"
          }`,
          CloseDate: DateTime.now().toISODate(),
          StageName: "Contract Signed",
          Type: "On-Demand",
          Platform__c: platform === FlexsaveTypes.GCP ? "Flexsave Standalone - GCP" : "Flexsave Standalone - AWS",
          Contract_Type__c: "New Logo",
          Amount: savings?.annualSavings,
          OwnerId: "0058c00000AnNcLAAV",
          AccountId: "@{AccountInfo.Id}",
          RecordTypeId: "0128c000001dszIAAQ",
        },
      },
      {
        method: SalesforceRequestMethod.GET,
        referenceId: "NewOpportunity",
        url: `${salesforceBaseUrl}/sobjects/Opportunity/@{Opportunity.id}`,
      },
      {
        method: SalesforceRequestMethod.POST,
        referenceId: "OpportunityContactRole",
        url: `${salesforceBaseUrl}/sobjects/OpportunityContactRole`,
        body: {
          OpportunityId: "@{NewOpportunity.Id}",
          ContactId: "@{NewContact.Id}",
        },
      },
    ],
    true
  );

const saveOpportunityIdInFirestore = async (customerId: string, opportunityId: string) => {
  try {
    await getCollection(CustomerModel).doc(customerId).update("salesforce.opportunityId", opportunityId);
  } catch (error) {
    consoleErrorWithSentry(error);
  }
};

const callSalesforceAndUpdateFirestore = async (
  salesforceApi: SalesforceApi,
  customer: SalesforceCustomer,
  user: SalesforceUser,
  platform?: FlexsaveType,
  savings?: StandaloneEstimationsGCP | SavingsEstimations
) => {
  if (customer.salesforce?.opportunityId) {
    return;
  }

  const response = await callSalesforceApi(salesforceApi, customer, user, platform, savings);
  const responseStatus = response?.data.compositeResponse[0].httpStatusCode;
  if (responseStatus === 201 || responseStatus === 200) {
    saveOpportunityIdInFirestore(customer.id, response.data.compositeResponse[3].body.id);
  }
};

export const UpdateSalesforceAfterEstimation = (props: {
  platform: FlexsaveType;
  savings: StandaloneEstimationsGCP | SavingsEstimations;
}) => <UpdateSalesforce updateFunction={callSalesforceAndUpdateFirestore} {...props} />;
