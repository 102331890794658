import { type JSX } from "react";

import { Button, Icon, Stack, Typography } from "@mui/material";
import { type Theme } from "@mui/material/styles";
import { type SxProps } from "@mui/system";

import LoadingButton from "../LoadingButton";
import { stickyFooterStyles } from "./StickyFooter.styles";

type Props = {
  disabled: boolean;
  onClick: () => void;
  loading?: boolean;
  buttonText?: string;
  buttonTextLoading?: string;
  text?: string;
  icon?: JSX.Element;
  primaryButtonStyle?: Record<string, any>;
  cancelButtonText?: string;
  cancelButtonStyle?: Record<string, any>;
  onCancel?: () => void;
  sx?: SxProps<Theme>;
};

const useStyles = stickyFooterStyles;

/**
 * Use whenever footer with button is required, visible above all other components.
 *
 * @note The last visible component above StickyFooter should have 56-62px margin bottom to avoid content hiding (theme.spacing(7/8))
 *
 * @param disabled - disabled
 * @param onClick - onClick function to invoke using the given button
 * @param loading - (optional) set button to loading state
 * @param buttonText - (optional) Text for primary button to display. Default value is `"UPDATE"`.
 * @param buttonTextLoading - (optional) Text for the primary button to show when `loading` is true. Default value is `"UPDATING"`.
 * @param text - (optional) text to show beside the button when its active. Requires `text` to be present in order to show.
 * @param icon - (optional) will be added to the text beside the button (undefined by default, pass any icon component --> <IconOfYourChoice />. It is recommended that the icon have the attribute `fontSize="small"` in order to make it the same size as surrounding text.)
 * @param primaryButtonStyle - (optional) styling for primary button
 * @param cancelButtonText - (optional) Text for cancel button
 * @param cancelButtonStyle - (optional) Styling for cancel button
 * @param onCancel - (optional) method for cancel button click. The presence of this method dictates whether there will _be_ a cancel button
 * @param sx - (optional) Style to apply to the StickyFooter. Receives an object in exactly the same way as a MUI `sx` prop.
 */
export const StickyFooter = ({
  text,
  buttonText = "UPDATE",
  buttonTextLoading = "UPDATING",
  onClick,
  loading = false,
  disabled,
  icon,
  primaryButtonStyle,
  cancelButtonText = "CANCEL",
  cancelButtonStyle,
  onCancel,
  sx,
}: Props) => {
  const classes = useStyles();

  return (
    <Stack
      className={classes.root}
      sx={{ p: 1, ...sx }}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      spacing={2}
      data-cy="stickyFooter"
    >
      {!!text && !disabled && (
        <Typography className={classes.text} variant="inherit" data-cy="textPanel">
          {icon && (
            <Icon data-cy="icon" fontSize="small" className={classes.icon}>
              {icon}
            </Icon>
          )}
          {text}
        </Typography>
      )}
      {onCancel && (
        <Button
          variant="text"
          onClick={onCancel}
          className={classes.button}
          sx={{ mt: 0, ...cancelButtonStyle }}
          data-cy="cancelButton"
          disabled={loading}
        >
          {cancelButtonText}
        </Button>
      )}
      <LoadingButton
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={onClick}
        disabled={disabled || loading}
        loading={loading}
        sx={{ mt: 0, ...primaryButtonStyle }}
        data-cy="mainButton"
        mixpanelEventId={`sticky-footer.${buttonText}`}
      >
        {loading ? buttonTextLoading : buttonText}
      </LoadingButton>
    </Stack>
  );
};
