import {
  ComparisonOperator,
  type ConditionExpressionType,
  ModelType,
  type ReferencedNodeValue,
} from "@doitintl/cmp-models";

export type AddActionParams = {
  groupIndex: number;
  fieldReference: ReferencedNodeValue;
  conditionType: ConditionExpressionType;
  operator: ComparisonOperator;
  value: any;
};

export type EditActionParams = {
  groupIndex: number;
  conditionIndex: number;
  fieldReference: ReferencedNodeValue;
  conditionType: ConditionExpressionType;
  operator: ComparisonOperator;
  value: any;
};

export const operatorMap: Record<ModelType, ComparisonOperator[]> = {
  [ModelType.INTEGER]: [
    ComparisonOperator.EQUALS,
    ComparisonOperator.NOT_EQUALS,
    ComparisonOperator.GREATER_THAN,
    ComparisonOperator.LESS_THAN,
    ComparisonOperator.GREATER_THAN_OR_EQUAL,
    ComparisonOperator.LESS_THAN_OR_EQUAL,
  ],
  [ModelType.FLOAT]: [
    ComparisonOperator.EQUALS,
    ComparisonOperator.NOT_EQUALS,
    ComparisonOperator.GREATER_THAN,
    ComparisonOperator.LESS_THAN,
    ComparisonOperator.GREATER_THAN_OR_EQUAL,
    ComparisonOperator.LESS_THAN_OR_EQUAL,
  ],
  [ModelType.STRING]: [
    ComparisonOperator.EQUALS,
    ComparisonOperator.NOT_EQUALS,
    ComparisonOperator.CONTAINS,
    ComparisonOperator.NOT_CONTAINS,
  ],
  [ModelType.BOOLEAN]: [ComparisonOperator.EQUALS, ComparisonOperator.NOT_EQUALS],
  [ModelType.LIST]: [ComparisonOperator.CONTAINS, ComparisonOperator.NOT_CONTAINS],
  [ModelType.TIMESTAMP]: [
    ComparisonOperator.EQUALS,
    ComparisonOperator.NOT_EQUALS,
    ComparisonOperator.GREATER_THAN,
    ComparisonOperator.LESS_THAN,
    ComparisonOperator.GREATER_THAN_OR_EQUAL,
    ComparisonOperator.LESS_THAN_OR_EQUAL,
  ],
  [ModelType.STRUCTURE]: [],
  [ModelType.MAP]: [],
  [ModelType.UNION]: [],
};
