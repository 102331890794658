import { forwardRef, type PropsWithChildren, type Ref, useImperativeHandle, useState } from "react";

import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { type AxiosError } from "axios";

import { useSauronStaffApi } from "../../../../api/endpoints";

interface SauronStaffDialogProps {
  accountId: string;
}

export interface SauronStaffDialogHandle {
  openDialog: () => Promise<void>;
}

/**
 * SauronStaffDialog is a component that returns a dialog used for onboarding
 * an AWS account into Sauron. This mimics the step that the customer CfN
 * template does.
 *
 * It is written as a functional component using hooks. The downside of this
 * approach is that React rules of hooks mean we have to use a forwardRef
 * so that we can put the dialog outside the menu. This stops the state
 * getting messed up.
 *
 */
export const SauronStaffDialog = forwardRef<SauronStaffDialogHandle, SauronStaffDialogProps>(
  (props: PropsWithChildren<SauronStaffDialogProps>, ref: Ref<SauronStaffDialogHandle>) => {
    const [open, setOpen] = useState(false);
    const [working, setWorking] = useState(true);
    const [orthanc, setOrthanc] = useState(true);
    const [lastOrthanc, setLastOrthanc] = useState("");
    const [supportHatch, setSupportHatch] = useState(true);
    const [currentSauronArn, setCurrentSauronArn] = useState("unknown");
    const [currentSupportRole, setCurrentSupportRole] = useState("unknown");
    const [error, setError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { accountId } = props;

    const sauronApi = useSauronStaffApi(accountId);

    /**
     * refreshButtons goes through all the buttons and sets
     * the correct status based on what Sauron thinks
     * the status is.
     */
    const refreshButtons = async () => {
      try {
        const response = await sauronApi.orthanc({ create: false });
        setOrthanc(true);
        setCurrentSauronArn(response.data.managementArn);
      } catch {
        setOrthanc(false);
      }

      setSupportHatch(false);
      setCurrentSupportRole("unknown");

      if (orthanc) {
        try {
          const response = await sauronApi.getSupportHatch();
          setSupportHatch(true);
          setCurrentSupportRole(response.data);
        } catch {
          setSupportHatch(false);
        }
      }

      setWorking(false);
    };

    useImperativeHandle(ref, () => ({
      /**
       * openDialog provides a way for a the host component
       * to open the dialog.
       */
      openDialog: async () => {
        setOpen(true);
        await refreshButtons();
      },
    }));

    /**
     * handleClose allows for handling the closure of the dialog
     */
    const handleClose = () => {
      setOpen(false);
      setError(false);
      setErrorMessage("");
    };

    /**
     * handleOrthanc handles the onboarding into Orthanc. This is
     * a pre-requisite to on-boarding a support hatch.
     *
     * @returns {Promise<void>}
     */
    const handleOrthanc = async () => {
      setWorking(true);

      try {
        await sauronApi.orthanc({ create: true });
      } catch (reason) {
        const aReason = reason as AxiosError;
        setLastOrthanc(`(${aReason?.response?.status})`);
        setError(true);
        setErrorMessage(aReason?.message);
      } finally {
        await refreshButtons();
      }
    };

    /**
     * handleSupport set up a support hatch. Either by creating one
     * or by updating it.
     *
     * @returns {Promise<void>}
     */
    const handleSupport = async () => {
      setWorking(true);

      try {
        await sauronApi.getSupportHatch();
        await sauronApi.updateSupportHatch();
      } catch (reason) {
        const aReason = reason as AxiosError;
        setError(true);
        setErrorMessage(aReason?.message);
        await sauronApi.installSupportHatch();
      } finally {
        await refreshButtons();
      }
    };

    /**
     * handleDeleteSupport handles the deletion of a support hatch
     *
     * @returns {Promise<void>}
     */
    const handleDeleteSupport = async () => {
      setWorking(true);

      try {
        await sauronApi.deleteSupportHatch();
      } catch (reason) {
        const aReason = reason as AxiosError;
        setError(true);
        setErrorMessage(aReason?.message);
      } finally {
        await refreshButtons();
      }
    };

    return (
      <>
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">STAFF ONLY: Sauron onboarding ({accountId})</DialogTitle>
          <DialogContent>
            {error ? (
              <Alert severity="error" data-cy="error">
                <AlertTitle>
                  Could not access AWS Account using DoiT AWS Ops - please check if the role in AWS Account is still
                  installed.
                </AlertTitle>
                {errorMessage}
              </Alert>
            ) : (
              <>
                <Alert severity="warning">
                  <strong>
                    This dialog is internal only, be mindful when using it and be sure you understand what Sauron does
                    before clicking away!
                  </strong>
                  <p>
                    To force the onboarding, without errors, to Sauron, make sure the customer has run the
                    CloudFormation template.
                  </p>
                </Alert>
                <p data-cy="sauron-role">Current Sauron Role: {currentSauronArn}</p>
                <p data-cy="support-role">Current Support Role: {currentSupportRole}</p>
              </>
            )}
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button aria-label="close" onClick={handleClose} color="primary">
              Cancel
            </Button>
            {!error && (
              <>
                <Button aria-label="onboard" onClick={handleOrthanc} color="primary" disabled={orthanc || working}>
                  Orthanc Onboard {lastOrthanc}
                </Button>
                <Button
                  aria-label="hatch-create"
                  onClick={handleSupport}
                  color="primary"
                  disabled={!orthanc || working}
                >
                  Hatch Creation / Update
                </Button>
                <Button
                  aria-label="hatch-delete"
                  onClick={handleDeleteSupport}
                  color="primary"
                  disabled={!supportHatch || working}
                >
                  Hatch Deletion
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
);

SauronStaffDialog.displayName = "orthancOnboardDialog";
