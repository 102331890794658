import { type Dispatch, useCallback, useEffect, useState } from "react";

import { type Entity } from "../../Context/customer/EntitiesContext";
import { consoleErrorWithSentry } from "../../utils";
import { useAsyncLoadingFunction } from "../hooks/useAsyncLoadingFunction";
import { type SavedPaymentMethod } from "./BillingForm/api";
import { useV1Api } from "./BillingForm/hooks";
import { extractErrorMessage } from "./BillingForm/utils";

export function useFetchSavedPaymentMethods(
  entity: Entity,
  setErrorMessage: Dispatch<string>,
  refresh?: number
): [boolean, SavedPaymentMethod[]] {
  const [paymentMethods, setPaymentMethods] = useState<SavedPaymentMethod[]>([]);

  const v1Api = useV1Api();

  const [isLoading, getSavedPaymentMethods] = useAsyncLoadingFunction(
    useCallback(() => v1Api.getSavedPaymentMethods(entity.id), [entity.id, v1Api])
  );

  useEffect(() => {
    (async () => {
      try {
        setPaymentMethods(await getSavedPaymentMethods());
      } catch (error) {
        consoleErrorWithSentry(error);
        setErrorMessage(extractErrorMessage(error, "Unable to load saved payment methods"));
      }
    })();
  }, [getSavedPaymentMethods, setErrorMessage, refresh]);

  return [isLoading, paymentMethods];
}
