import { type StandaloneEstimationsAWS } from "@doitintl/cmp-models";

import { type PreviousComputeSpend, type SavingsEstimations } from "../../types";
import { ANNUAL_POSSIBLE_GROWTH } from "../const";

const FLEXSAVE_DISCOUNT_RATE = 0.2;

export function calculateSavings(raw: StandaloneEstimationsAWS): [SavingsEstimations, PreviousComputeSpend] {
  const lastMonthWithFlexsave = raw?.lastMonthComputeSpend - raw?.estimatedSavings * (1 - FLEXSAVE_DISCOUNT_RATE);
  const annualSavings = (raw?.lastMonthComputeSpend - lastMonthWithFlexsave) * 12;
  const annualSavingsWithGrowth = annualSavings * (1 + ANNUAL_POSSIBLE_GROWTH);

  const savingsEstimations: SavingsEstimations = {
    annualSavings,
    annualSavingsWithGrowth,
  };

  const previousComputeSpend: PreviousComputeSpend = {
    lastMonthWithFlexsave,
    lastMonthComputeSpend: raw.lastMonthComputeSpend,
  };

  return [savingsEstimations, previousComputeSpend];
}
