import { type KeyboardEvent, type MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from "react";

import { type ChannelPartnerModel, PartnerModel, TierPackageTypes } from "@doitintl/cmp-models";
import { getCollection, type ModelReference, useDocumentDataOnce } from "@doitintl/models-firestore";

import { firebaseProjectId } from "../../../constants";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useTier } from "../../../Context/TierProvider";
import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { getGravatar } from "../../../Layout/GravatarApi";
import { isPaidTier } from "../../../Pages/Settings/General/utils";
import { type Customer } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";

export const useHeaderMenuItem = (shouldOpen: boolean, customHandler?: () => void) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (shouldOpen) {
      setOpen((prevOpenState) => !prevOpenState);
    }

    customHandler?.();
  };

  const handleClose =
    ({ remainOpen, handler }: { remainOpen?: boolean; handler?: () => void }) =>
    (event: ReactMouseEvent<EventTarget>) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
        return;
      }

      !remainOpen && setOpen(false);
      handler?.();
    };

  const handleCloseClickAway =
    ({ remainOpen, handler }: { remainOpen?: boolean; handler?: () => void }) =>
    (event: MouseEvent | TouchEvent) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
        return;
      }

      !remainOpen && setOpen(false);
      handler?.();
    };

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return { handleClick, handleClose, handleCloseClickAway, handleListKeyDown, open, anchorRef };
};

export const useAvatar = (user: FirebaseUserWithEmail) => {
  const { email, photoURL, displayName } = user;
  const [avatarSrc, setAvatarSrc] = useState<string>();
  const [initials, setInitials] = useState<string>();

  useEffect(() => {
    async function getGravatarData() {
      const gravatarResponse = await getGravatar(email);
      gravatarResponse && setAvatarSrc(gravatarResponse);
    }

    if (photoURL) {
      setAvatarSrc(photoURL);
    }

    if (email) {
      getGravatarData().catch(consoleErrorWithSentry);
    }

    if (displayName) {
      const parsedDisplayName = displayName.match(/\b\w/g) || [];
      setInitials(((parsedDisplayName.shift() || "") + (parsedDisplayName.pop() || "")).toUpperCase());
    }
  }, [displayName, email, photoURL]);

  return { avatarSrc, initials };
};

export const usePartnerDataOfCustomer = (customer: Customer) => {
  const [query, setQuery] = useState<ModelReference<ChannelPartnerModel> | undefined>();
  const [partnerData] = useDocumentDataOnce(query);
  const partnerRecordId = customer.partnerOfRecord?.id;

  useEffect(() => {
    if (!partnerRecordId) {
      setQuery(undefined);
      return;
    }
    const query = getCollection(PartnerModel).doc(firebaseProjectId).collection("channelPartners").doc(partnerRecordId);
    setQuery(query);
  }, [partnerRecordId]);

  return partnerData;
};

export const useShowFreeTrialCard = () => {
  const { getCustomerTier } = useTier();
  const { customer, isProductOnlyCustomer } = useCustomerContext({ allowNull: true });

  if (
    !isProductOnlyCustomer ||
    !customer?.onboarding?.navigator?.providers?.length ||
    customer.onboarding?.startTrialInitiated
  ) {
    return false;
  }

  const navigatorTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const solveTier = getCustomerTier(TierPackageTypes.SOLVE);

  const navigatorTierTrialStartDate = customer.tiers?.navigator?.trialStartDate;
  const navigatorTierTrialEndDate = customer.tiers?.navigator?.trialEndDate;
  const solveTierTrialStartDate = customer.tiers?.solve?.trialStartDate;
  const solveTierTrialEndDate = customer.tiers?.solve?.trialEndDate;

  const hasNavigatorTierTrial = Boolean(navigatorTierTrialStartDate && navigatorTierTrialEndDate);
  const hasSolveTierTrial = Boolean(solveTierTrialStartDate && solveTierTrialEndDate);

  /* Show free trial card when customer:
    - does not have a current or past trial for Navigator or Solve &
    - is not on a paid tier for Navigator or Solve &
   */

  if (hasNavigatorTierTrial || hasSolveTierTrial) {
    return false;
  }

  if (isPaidTier(navigatorTier) || isPaidTier(solveTier)) {
    return false;
  }

  return true;
};
