import { useState } from "react";

import { Box, Button, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { SideDrawer } from "../../../Components/SideDrawer";
import { anomalySidePanelText } from "../texts";
import { type AnomalySidePanelProps } from "../types";
import { AnomalySidePanelHeaders } from "../utils";

const START_DATE = DateTime.fromFormat("05/25/2023", "MM/dd/yyyy");

const ExplainerHeader = ({ value }: { value: string }) => (
  <Typography mb={1.25} variant="subtitle1" fontWeight={500}>
    {value}
  </Typography>
);

const ExplainerContent = ({ value }: { value: string | undefined }) => (
  <Box mb={3}>
    {value?.split("\n")?.map((v) => (
      <Typography key={v.substring(0, 10)} mb={2}>
        {v}
      </Typography>
    ))}
  </Box>
);

const AnomalySidePanel = ({ date, explainer }: AnomalySidePanelProps) => {
  const disabled = !date || DateTime.fromJSDate(date.toDate()) < START_DATE || !explainer;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const dateStr = START_DATE.toFormat("dd MMM yyyy");
  const content = [
    ...(explainer?.summary
      ? [
          {
            header: AnomalySidePanelHeaders.summary,
            description: explainer?.summary,
          },
        ]
      : []),
    ...(explainer?.skus_explanation
      ? [
          {
            header: AnomalySidePanelHeaders.skus_explanation,
            description: explainer?.skus_explanation,
          },
        ]
      : []),
    ...(explainer?.optimization
      ? [
          {
            header: AnomalySidePanelHeaders.optimization,
            description: explainer?.optimization,
          },
        ]
      : []),
  ];
  return (
    <>
      <Tooltip
        disableHoverListener={!disabled}
        title={anomalySidePanelText.TOOLTIP_DISABLE_TEXT + dateStr}
        componentsProps={{
          tooltip: {
            style: {
              maxWidth: "220px",
            },
          },
        }}
      >
        <span>
          <Button
            sx={{ height: "100%" }}
            variant="outlined"
            disabled={disabled}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            {anomalySidePanelText.BTN}
          </Button>
        </span>
      </Tooltip>
      <SideDrawer open={isDrawerOpen} title={anomalySidePanelText.BTN} onClose={onClose}>
        <>
          {content.map(({ header, description }) => (
            <Box key={header}>
              <ExplainerHeader value={header} />
              <ExplainerContent value={description} />
            </Box>
          ))}
        </>
      </SideDrawer>
    </>
  );
};

export default AnomalySidePanel;
