import { useCallback, useEffect, useState } from "react";

import { InvoiceModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";
import debounce from "lodash/debounce";

import { type Entity } from "../../../Context/customer/EntitiesContext";
import { type Customer } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { type Invoice } from "../types";
import { getDraftInvoices } from "../useInvoices";

export type CreditCardFees = {
  feesAllowed: boolean;
  percentage: number;
  totalFees: number;
  feesTaxAmount: number;
  feesTaxPercentage: number;
};

export const useCreditCardSurchargeCheck = (
  api: AxiosInstance,
  customerID: string,
  entityID: string,
  amount: number
): [CreditCardFees, boolean] => {
  const [creditCardFees, setCreditCardFees] = useState<CreditCardFees>({
    feesAllowed: false,
    percentage: 0,
    totalFees: 0,
    feesTaxAmount: 0,
    feesTaxPercentage: 0,
  });
  const [fetchingCCFees, setFetchingCCFees] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCreditCardFeeData = useCallback(
    debounce(
      async (amount) => {
        try {
          const { data } = await api.request({
            method: "post",
            url: `/v1/customers/${customerID}/entities/${entityID}/credit-card-fees`,
            data: { amount: Math.round(amount * 100) },
          });
          setCreditCardFees(data);
          setFetchingCCFees(false);
        } catch (error) {
          consoleErrorWithSentry(error);
        }
      },
      3000,
      { leading: false, trailing: true }
    ),
    [api, customerID, entityID]
  );

  useEffect(() => {
    getCreditCardFeeData(amount);
  }, [amount, getCreditCardFeeData]);

  useEffect(() => {
    setFetchingCCFees(true);
  }, [amount]);

  return [creditCardFees, fetchingCCFees];
};

export const findIssuedInvoice = async (entity: Entity, ivnum: string): Promise<Invoice | undefined> => {
  const docId = `${entity.priorityCompany}-${entity.priorityId}-${ivnum}`;
  const docSnap = await getCollection(InvoiceModel).doc(docId).get();

  const data = docSnap.asModelData();
  if (!data) {
    return;
  }

  return {
    id: docSnap.id,
    ...data,
    isDraft: false,
  };
};

export const findDraftInvoice = async (customer: Customer, IVNUM: string): Promise<Invoice | undefined> => {
  const invoices = await getDraftInvoices(customer);

  return invoices.find((currentInvoice) => currentInvoice.IVNUM === IVNUM);
};
