import { useMemo } from "react";

import { type TotalAverageUsageModal } from "@doitintl/cmp-models";
import { DateTime } from "luxon";

import {
  type CustomerAggregatedPurchaseItem,
  type CustomerAggregatedWorkloadInfo,
  type CustomerInfo,
  type GroupedWorkloads,
  type Workload,
} from "../types";
import { createAggregatedPurchase } from "../utils/workloadUtils";
import { useCustomerPurchaseAggregation } from "./customerAggregationHooks";
import { useCustomerInfoById } from "./customerConfigurationHooks";
import { useTotalAverageUsages } from "./totalAverageUsageHooks";
import { useDebounce } from "./useDebounce";
import { useCustomerWorkloads } from "./workloadsHooks";

export function useCustomerPurchases(
  groupedWorkloadsMap?: GroupedWorkloads,
  totalAverageUsagesMap?: Map<string, TotalAverageUsageModal>,
  enabledCustomerConfigsMap?: Map<string, CustomerInfo>,
  customerAgg?: Map<string, CustomerAggregatedWorkloadInfo>
) {
  const debouncedWorkloads = useDebounce(groupedWorkloadsMap);
  return useMemo<CustomerAggregatedPurchaseItem[] | undefined>(() => {
    if (customerAgg && debouncedWorkloads && enabledCustomerConfigsMap && totalAverageUsagesMap) {
      const customerAggItems: CustomerAggregatedPurchaseItem[] = [];
      for (const [customerId, customerPurchaseAgg] of customerAgg.entries()) {
        if (enabledCustomerConfigsMap.has(customerId)) {
          const customerWorkloads = Array.from(
            debouncedWorkloads.get(customerId)?.workloadsMap.values() ?? ([] as Workload[])
          );
          const customerInfo = enabledCustomerConfigsMap.get(customerId);
          customerAggItems.push(
            createAggregatedPurchase(customerPurchaseAgg, customerWorkloads, totalAverageUsagesMap, customerInfo)
          );
        }
      }
      return customerAggItems;
    }
  }, [customerAgg, debouncedWorkloads, enabledCustomerConfigsMap, totalAverageUsagesMap]);
}

export function useSingleCustomerPurchases(customerId: string) {
  const customerPurchase = useCustomerPurchaseAggregation(customerId);
  const [customerWorkloads] = useCustomerWorkloads(customerId);
  const customerInfo = useCustomerInfoById(customerId);
  const totalAverageUsagesMap = useTotalAverageUsages();
  return useMemo(() => {
    if (customerPurchase && customerWorkloads && customerInfo && totalAverageUsagesMap) {
      const validWorkloads = customerWorkloads.filter(
        (workload) =>
          workload.statusTimeUpdated &&
          workload.statusTimeUpdated > DateTime.fromJSDate(new Date(Date.now() - 24 * 60 * 60 * 1000))
      );
      return createAggregatedPurchase(customerPurchase, validWorkloads, totalAverageUsagesMap, customerInfo);
    }
  }, [customerPurchase, customerWorkloads, customerInfo, totalAverageUsagesMap]);
}
