import mixpanel from "../../../utils/mixpanel";

export const GUIDED_EXPERIENCE = "guidedExperience";
export const CUSTOM = "Custom";
export const CUSTOM_OPTION = "Custom...";
export const NUM_OF_MONTHS = 3;

export const defaultCustomAG = {
  displayName: CUSTOM_OPTION,
  name: "",
  options: ["Resources A", "Resources B", "Resources C", "Resources D"],
};

export const defaultHardCodedAGs = [
  {
    displayName: "Teams",
    name: "Teams",
    options: ["Team Alpha", "Team Beta", "Team Gamma", "Team Delta"],
  },
  {
    displayName: "Products",
    name: "Products",
    options: ["iPhone", "iPad", "MacBook", "etc."],
  },
  {
    displayName: "Projects",
    name: "Projects",
    options: ["Project A", "Project B", "Project C", "Project D"],
  },
  {
    displayName: "Customers",
    name: "Customers",
    options: ["Customer A", "Customer B", "Customer C", "Customer D"],
  },
  {
    displayName: "Environments",
    name: "Environments",
    options: ["Environment A", "Environment B", "Environment C", "Environment D"],
  },
  defaultCustomAG,
];

export const sendStepMixpanelEvent = (stepNum: number, stepInfo: string, origin: string) => {
  mixpanel.track(`${GUIDED_EXPERIENCE}.step${stepNum}`, { type: "costAllocation", origin, stepInfo });
};
