import { useMemo } from "react";

import {
  type AmazonWebServicesAssetModel,
  AssetTypeAmazonWebServices,
  AssetTypeAwsStandalone,
  type Member,
  type StructureApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { Autocomplete, FormControl, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { type FormikProps } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type Asset } from "../../../../types";
import { useAwsData } from "../../hooks";
import { useFieldValidationProps } from "./useFieldValidationProps";

export const ALL_REGIONS = "All regions";

const AWSParameterForm = ({
  inputModel,
  formikProps,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  formikProps: FormikProps<any>;
}) => {
  const { assets } = useCustomerContext();
  const accountIdProps = formikProps.getFieldProps("accountId");
  const regionsProps = formikProps.getFieldProps("regions");
  const { showError, errorMessage } = useFieldValidationProps(formikProps, accountIdProps);
  const { showError: showRegionsError, errorMessage: regionsErrorMessage } = useFieldValidationProps(
    formikProps,
    regionsProps
  );

  const { regions } = useAwsData(accountIdProps.value, "regions" in inputModel.members);

  const awsAccountIds = useMemo(
    () =>
      Object.values(assets).flatMap((assets) =>
        assets
          .filter((asset): asset is Asset<AmazonWebServicesAssetModel> =>
            [AssetTypeAmazonWebServices, AssetTypeAwsStandalone].includes(asset.data.type)
          )
          .map((asset) => asset.data.properties.accountId)
      ),
    [assets]
  );

  return (
    <Stack gap={2}>
      <FormControl>
        <VirtualizedAutocomplete<string, false, true, false>
          options={awsAccountIds}
          onChange={(_, value) => formikProps.setFieldValue(accountIdProps.name, value)}
          renderOption={(props, option) => (
            <MenuItem key={option} {...props} value={option}>
              {option}
            </MenuItem>
          )}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              type="text"
              label="Account"
              name={accountIdProps.name}
              onBlur={accountIdProps.onBlur}
              required
              fullWidth
              error={showError}
              helperText={errorMessage}
              variant="outlined"
            />
          )}
          size="small"
          fullWidth
          value={accountIdProps.value ?? null}
        />
      </FormControl>
      {"regions" in inputModel.members && (
        <FormControl>
          <Autocomplete
            multiple
            options={regions}
            onChange={async (_, value) => formikProps.setFieldValue(regionsProps.name, value)}
            renderOption={(props, option) => (
              <MenuItem key={option} {...props} value={option}>
                {option}
              </MenuItem>
            )}
            disableCloseOnSelect
            getOptionDisabled={(option) => formikProps.values?.regions?.includes(ALL_REGIONS) && option !== ALL_REGIONS}
            disabled={regions.length === 0}
            renderInput={(params) => {
              const { InputProps, ...rest } = params;
              if (
                (!Array.isArray(regionsProps.value) || regionsProps.value.length === 0) &&
                !rest.inputProps["aria-expanded"]
              ) {
                InputProps.startAdornment = <Typography pl={1}>{ALL_REGIONS}</Typography>;
              }
              return (
                <TextField
                  {...rest}
                  type="text"
                  label="Regions"
                  name={regionsProps.name}
                  onBlur={regionsProps.onBlur}
                  fullWidth
                  error={showRegionsError}
                  helperText={regionsErrorMessage}
                  variant="outlined"
                  InputProps={InputProps}
                />
              );
            }}
            size="small"
            fullWidth
            value={formikProps.values?.regions ?? []}
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default AWSParameterForm;
