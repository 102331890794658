import { useApiContext } from "../../../../api/context";
import { formatCurrency } from "../../../../utils/common";
import { type Values } from "./types";

type props = {
  customerId: string;
  values: Values;
  customerDomain: string;
  entity;
  reseller;
  total: number;
};

export const useSubmitOrder = () => {
  const api = useApiContext();
  return ({ customerId, values, customerDomain, total, reseller, entity }: props) =>
    api.post(`v1/customers/${customerId}/licenses/microsoft/${values.tenant}/orders`, {
      item: values.service?.snapshot.ref.path,
      quantity: Number(values.quantity),
      customer: values.tenant,
      domain: customerDomain,
      entity,
      reseller,
      total: formatCurrency(total, "USD"),
      payment: values.service?.data.payment,
    });
};
