import { type JSX } from "react";

import { Link } from "react-router-dom";
import { Link as MUILink, Typography } from "@mui/material";

export type CountUpNumberProps = {
  children: JSX.Element;
  numberOnClick?: () => void;
  numberColor?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
};

export const CountUpNumber = ({ children, numberOnClick, numberColor }: CountUpNumberProps) =>
  numberOnClick ? (
    <MUILink
      component={Link}
      to="#"
      onClick={numberOnClick}
      variant="h5"
      color={numberColor}
      sx={{ textDecoration: "none" }}
    >
      {children}
    </MUILink>
  ) : (
    <Typography variant="h2" color={numberColor}>
      {children}
    </Typography>
  );
