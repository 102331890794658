import { PremiumFeatureCard } from "../../Components/Trial/TrialPremiumFeatureCard";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { getPremiumFeatureCardText } from "../CloudAnalytics/utilities";
import AlertList from "./AlertList";

const premiumFeatureCardText = getPremiumFeatureCardText("costAnomalies");

const AnomalyDetection = () => {
  const { customer } = useCustomerContext();

  return (
    <>
      {isCustomerInPresentationMode(customer) && (
        <PremiumFeatureCard
          title={premiumFeatureCardText.title}
          detail={premiumFeatureCardText.details}
          tryFor={premiumFeatureCardText.tryFor}
        />
      )}
      <AlertList />
    </>
  );
};

export default AnomalyDetection;
