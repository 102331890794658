import { useCallback, useMemo } from "react";

import {
  AlertCondition,
  type AttributionFilter,
  ComparativeFeature,
  Feature,
  Metadata,
  type MetricFilter,
  MetricFilterOperator,
  Renderer,
  type ReportOptionalField,
  TimeInterval,
  type TimeSettingsConfig,
  TimeSettingsMode,
} from "@doitintl/cmp-models";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { type AnalyticsAlertWithRef } from "../../../../Pages/CloudAnalytics/alerts";
import {
  type ConfigField,
  type createReportParams,
  type createReportPayload,
} from "../../../../Pages/CloudAnalytics/generateReport/types";
import { optionalMetadataTypes } from "../../../../Pages/CloudAnalytics/utilities";
import { type MetadataOption } from "../../../../types/Report";
import useGenerateReport from "../../useGenerateReport";

const metricFilterAbsoluteValue = 1;

const getTimeSettingsConfig = (interval: TimeInterval): TimeSettingsConfig => {
  switch (interval) {
    case TimeInterval.DAY:
      return {
        mode: TimeSettingsMode.Last,
        unit: TimeInterval.DAY,
        amount: 30,
        includeCurrent: true,
      };
    case TimeInterval.WEEK:
      return {
        mode: TimeSettingsMode.Last,
        unit: TimeInterval.MONTH,
        amount: 3,
        includeCurrent: true,
      };

    case TimeInterval.MONTH:
      return {
        mode: TimeSettingsMode.Last,
        unit: TimeInterval.MONTH,
        amount: 6,
        includeCurrent: true,
      };
    case TimeInterval.QUARTER:
    case TimeInterval.YEAR:
      return {
        mode: TimeSettingsMode.Last,
        unit: TimeInterval.MONTH,
        amount: 18,
        includeCurrent: true,
      };
    default:
      return {
        mode: TimeSettingsMode.Current,
        unit: TimeInterval.MONTH,
        amount: 0,
        includeCurrent: false,
      };
  }
};

const useAlertInvestigation = (alert: AnalyticsAlertWithRef, dimensions: MetadataOption[]) => {
  const { customer } = useCustomerContext();
  const generateReport = useGenerateReport();

  const rowFields = useMemo(() => {
    const {
      config: { rows },
    } = alert.data;
    return (rows ?? []).flatMap((row) => {
      const [type, id] = row.split(":");
      const dimension = dimensions.find((d) => d.id === row);
      if (!dimension) {
        return [];
      }
      const key = dimension.data.key;
      return [
        {
          type: type as Metadata,
          id,
          groupBy: true,
          values: [] as string[],
          key,
        },
      ];
    });
  }, [alert.data, dimensions]);

  const filterFields: ConfigField[] = useMemo(() => {
    const {
      config: { filters = [], scope = [] },
    } = alert.data;

    if (scope?.length) {
      return [
        {
          type: Metadata.ATTRIBUTION,
          id: Metadata.ATTRIBUTION,
          values: scope.map((a) => a.id),
        },
      ];
    }

    return filters.flatMap((filter: AttributionFilter) => {
      const [, id] = filter.id.split(":");
      const dimension = dimensions.find((d) => d.id === id);
      const key = dimension?.data.key || filter.key;

      return {
        type: filter.type,
        id,
        groupBy: false,
        values: filter.values,
        key,
      };
    });
  }, [alert.data, dimensions]);

  return useCallback(async () => {
    const {
      name,
      config: {
        condition,
        timeInterval: configTimeInterval,
        currency,
        metric,
        calculatedMetric,
        extendedMetric,
        ignoreValuesRange,
        operator,
        values,
      },
    } = alert.data;

    let timeInterval = configTimeInterval;
    let comparative = ComparativeFeature.NONE;
    let metricFilters: MetricFilter[] | undefined;
    let features: Feature[] | undefined;
    const timeSettings = getTimeSettingsConfig(timeInterval);

    switch (condition) {
      case AlertCondition.VALUE:
        metricFilters = [
          {
            metric,
            operator,
            values,
          },
        ];
        break;
      case AlertCondition.PERCENTAGE:
        metricFilters = [
          {
            metric,
            operator: MetricFilterOperator.NOT_BETWEEN,
            values: [
              ignoreValuesRange?.lowerBound ?? -metricFilterAbsoluteValue,
              ignoreValuesRange?.upperBound ?? metricFilterAbsoluteValue,
            ],
          },
        ];
        comparative = ComparativeFeature.PERCENT;
        break;
      case AlertCondition.FORECAST:
        features = [Feature.FORECAST];
        timeInterval = TimeInterval.DAY;
        break;
      default:
    }

    const fields: ConfigField[] = [...rowFields, ...filterFields];

    const optionalFields = fields.filter((f) => f.type && optionalMetadataTypes.includes(f.type));
    const optional: ReportOptionalField[] | null = optionalFields.length
      ? optionalFields.map(({ key, type }) => ({ key: `${key}`, type }))
      : null;

    const params: createReportPayload | createReportParams = {
      name: `Explore Cloud Analytics report: ${name}`,
      description: "",
      config: {
        calculatedMetric,
        extendedMetric,
        currency,
        metric,
        comparative,
        fields,
        renderer: Renderer.STACKED_COLUMN_CHART,
        timeInterval,
        features,
        metricFilters,
        timeSettings,
        optional,
      },
    };

    const reportId = await generateReport(params, false);
    return `/customers/${customer.id}/analytics/reports/${reportId}?run-on-open=true`;
  }, [alert.data, rowFields, generateReport, customer.id, filterFields]);
};

export default useAlertInvestigation;
