import {
  type AwsMarketplaceAccountModel,
  ContractModel,
  type CustomerModel,
  MarketplaceModel,
  type NavigatorSKUs,
  type SolveSKUs,
  TierModel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";

import { type TierWithRef } from "../../../Context/TierProvider";

export const getAWSMarketplaceData = async (
  awsMpSubscriptionDocId: string
): Promise<AwsMarketplaceAccountModel | undefined> => {
  const awsData = await getCollection(MarketplaceModel)
    .doc("aws-marketplace")
    .collection("awsMarketplaceAccounts")
    .doc(awsMpSubscriptionDocId)
    .get();

  return awsData.data();
};

export const getAWSMarketplaceTiersData = async (
  skuList: Array<NavigatorSKUs | SolveSKUs>
): Promise<TierWithRef[] | undefined> => {
  const tiersQuerySnapshot = await getCollection(TierModel).where("sku", "in", skuList).get();
  if (tiersQuerySnapshot.empty) {
    return;
  }

  return tiersQuerySnapshot.docs.map((t) => ({
    ...t.asModelData(),
    ref: t.ref,
  }));
};

export const updateAwsMarketplaceAccount = async ({
  customerRef,
  tiers,
}: {
  customerRef: ModelReference<CustomerModel>;
  tiers: TierWithRef[];
}): Promise<void> => {
  const customerTiersMap = tiers.reduce(
    (acc, t) => ({
      ...acc,
      [`tiers.${t.packageType}.tier`]: t.ref,
    }),
    {}
  );
  await customerRef.update({
    marketplace: {
      AWS: {
        accountExists: true,
      },
    },
    ...customerTiersMap,
  });
};

export const enrichAwsMarketplaceEntitlementsWithContracts = async ({
  awsMpSubscriptionDocId,
  customerRef,
}: {
  awsMpSubscriptionDocId: string;
  customerRef: ModelReference<CustomerModel>;
}) => {
  const contractRefs = await getCollection(ContractModel)
    .where("pointOfSale", "==", "aws-marketplace")
    .where("customer", "==", customerRef)
    .get();

  const skuToContract = {};
  for (const t of contractRefs.docs) {
    const d = t.data();
    const tier = await d.tier?.get();
    const sku = tier?.data()?.sku;

    if (sku) {
      skuToContract[sku] = t.ref.id;
    }
  }

  await getCollection(MarketplaceModel)
    .doc("aws-marketplace")
    .collection("awsMarketplaceAccounts")
    .doc(awsMpSubscriptionDocId)
    .update({
      skuToContract: skuToContract as Record<NavigatorSKUs | SolveSKUs, string>,
    });
};
