import { BILLING_INFO_STEP, CONTRACT_STEP, PACKAGE_TYPE_STEP, PAYMENT_METHOD_STEP, SERVICE_TYPE_STEP } from "./const";
import { type SolveOnboardingStep } from "./types";

export const getCurrentStepIndex = (step: SolveOnboardingStep, hasBillingInfo: boolean): number => {
  switch (step) {
    case SERVICE_TYPE_STEP:
      return 0;
    case PACKAGE_TYPE_STEP:
      return 1;
    case CONTRACT_STEP:
      return 2;
    case BILLING_INFO_STEP:
      return 3;
    case PAYMENT_METHOD_STEP: {
      if (hasBillingInfo) {
        return 3;
      }
      return 4;
    }
  }
};
