/** *************************** Constant ************************************/
export const LOAD_TICKETS = "ticketsReducer/LOAD_TICKETS";
export const DOWNLOAD_TICKETS = "ticketsReducer/DOWNLOAD_TICKETS";
export const INIT_TICKETS_LIST = "ticketsReducer/INIT_TICKETS_LIST";
export const UPDATE_TICKETS_LIST = "ticketsReducer/UPDATE_TICKETS_LIST";
export const UPDATE_USERS_LIST = "ticketsReducer/UPDATE_USERS_LIST";
export const LOAD_TICKET_DETAILS = "ticketsReducer/LOAD_TICKET_DETAILS";
export const UPDATE_TICKET_DETAILS = "ticketsReducer/UPDATE_TICKET_DETAILS";
export const UPDATE_TIMELINE_EVENTS = "ticketsReducer/UPDATE_TIMELINE_EVENTS";
export const INIT_TICKET_DETAILS = "ticketsReducer/INIT_TICKET_DETAILS";
export const CHANGE_SEVERITY = "ticketsReducer/CHANGE_SEVERITY";
export const SAVE_PRIORITY = "ticketsReducer/SAVE_PRIORITY";
export const UPDATE_CATEGORIES = "ticketsReducer/UPDATE_CATEGORIES";
export const SAVE_CATEGORY = "ticketsReducer/SAVE_CATEGORY";
export const CHANGE_CATEGORY = "ticketsReducer/CHANGE_CATEGORY";
export const UPDATE_CATEGORY_OBJECT = "ticketsReducer/UPDATE_CATEGORY_OBJECT";
export const UPDATE_PRODUCT_PROPS = "ticketsReducer/UPDATE_PRODUCT_PROPS";
export const CHANGE_CATEGORY_INFO = "ticketsReducer/CHANGE_CATEGORY_INFO";
export const SAVE_NEW_TICKET = "ticketsReducer/SAVE_NEW_TICKET";
export const UPDATE_USER_DETAILS = "ticketsReducer/UPDATE_USER_DETAILS";
export const UPDATE_ORGANIZATION = "ticketsReducer/UPDATE_ORGANIZATION";
export const DONE_LOADING = "ticketsReducer/DONE_LOADING";
export const GET_COMMENTS = "ticketsReducer/GET_COMMENTS";
export const SUBSCRIBE_COMMENTS = "ticketsReducer/SUBSCRIBE_COMMENTS";
export const UNSUBSCRIBE_COMMENTS = "ticketsReducer/UNSUBSCRIBE_COMMENTS";
export const UPDATE_COMMENTS = "ticketsReducer/UPDATE_COMMENTS";
export const SEND_COMMENT = "ticketsReducer/SEND_COMMENT";
export const SEND_TAG_UPDATE = "ticketsReducer/SEND_TAG_UPDATE";
export const DONE_TAG_UPDATE = "ticketsReducer/DONE_TAG_UPDATE";
export const SEND_ESCALATE = "ticketsReducer/SEND_ESCALATE";
export const SEND_SURVEY = "ticketsReducer/SEND_SURVEY";
export const DONE_SURVEY = "ticketsReducer/DONE_SURVEY";
export const UPDATE_USER_CONTEXT = "ticketsReducer/UPDATE_USER_CONTEXT";
export const UPDATE_KNOWN_ISSUE = "ticketsReducer/UPDATE_KNOWN_ISSUE";
export const UPDATE_BUTTON_MODE = "ticketsReducer/UPDATE_BUTTON_MODE";
export const UPDATE_CUSTOMER_USERS = "ticketsReducer/UPDATE_CUSTOMER_USERS";
export const UPDATE_USER_PHONE = "ticketsReducer/UPDATE_USER_PHONE";
export const TOGGLE_ORGANIZATION_SHARING = "ticketsReducer/TOGGLE_ORGANIZATION_SHARING";
export const LOAD_TEMPLATE = "ticketsReducer/LOAD_TEMPLATE";
export const UPDATE_TEMPLATE = "ticketsReducer/UPDATE_TEMPLATE";
export const UPDATE_ERROR = "ticketsReducer/UPDATE_ERROR";
export const TOGGLE_DOWNLOAD_CSV_ALERT = "ticketsReducer/TOGGLE_DOWNLOAD_CSV_ALERT";
export const CONCEDEFY_READ_ONLY_ACCESS_EMAIL = "ticketsReducer/CONCEDEFY_READ_ONLY_ACCESS_EMAIL";
export const GET_DEFAULT_EMAILS_LIST = "ticketsReducer/GET_DEFAULT_EMAILS_LIST";
export const UPDATE_DEFAULT_EMAILS_LIST = "ticketsReducer/UPDATE_DEFAULT_EMAILS_LIST";
export const SEND_NEW_DEFAULT_EMAILS = "ticketsReducer/SEND_NEW_DEFAULT_EMAIL";

export function initTicketList() {
  return {
    type: INIT_TICKETS_LIST,
  };
}
export function toggleLoading(isDone) {
  return {
    type: DONE_LOADING,
    payload: isDone,
  };
}
export function loadTickets({ isDoitEmployee, userId }) {
  return {
    type: LOAD_TICKETS,
    payload: true,
    isDoitEmployee,
    userId,
  };
}
export function downloadTickets() {
  return {
    type: DOWNLOAD_TICKETS,
  };
}
export function getTicketDetails(id) {
  return {
    type: LOAD_TICKET_DETAILS,
    payload: id,
  };
}
export function initTicketDetails() {
  return {
    type: INIT_TICKET_DETAILS,
  };
}
export function handleChangeSeverity(priority) {
  return {
    type: CHANGE_SEVERITY,
    payload: priority,
  };
}
export function savePriority(id) {
  return {
    type: SAVE_PRIORITY,
    payload: id,
  };
}
export function saveCategory(id) {
  return {
    type: SAVE_CATEGORY,
    payload: id,
  };
}
export function changeTicketCategory(obj) {
  return {
    type: CHANGE_CATEGORY,
    payload: obj,
  };
}
export function changeCategoryInfo(obj) {
  return {
    type: CHANGE_CATEGORY_INFO,
    payload: obj,
  };
}
export function saveNewTicket(obj) {
  return {
    type: SAVE_NEW_TICKET,
    payload: obj,
  };
}
export function updateUserDetails(user) {
  return {
    type: UPDATE_USER_DETAILS,
    payload: user,
  };
}
export function getComments(id) {
  return {
    type: GET_COMMENTS,
    payload: id,
  };
}
export function subscribeComments(id, isDoitEmployee, customerId) {
  return {
    type: SUBSCRIBE_COMMENTS,
    payload: { id, isDoitEmployee, customerId },
  };
}
export function unsubscribeComments(id) {
  return {
    type: UNSUBSCRIBE_COMMENTS,
    payload: { id },
  };
}
export function sendComment(comment) {
  return {
    type: SEND_COMMENT,
    payload: comment,
  };
}

/**
 * @param obj.id {string}
 * @param obj.tags {string[]}
 */
export function sendTagUpdate(obj) {
  return {
    type: SEND_TAG_UPDATE,
    payload: obj,
  };
}

export function sendEscalate(obj) {
  return {
    type: SEND_ESCALATE,
    payload: obj,
  };
}
export function sendSurvey(obj) {
  return {
    type: SEND_SURVEY,
    payload: obj,
  };
}
export function doneSurvey(isDone) {
  return {
    type: DONE_SURVEY,
    payload: isDone,
  };
}
export function updateButtonMode(mode) {
  return {
    type: UPDATE_BUTTON_MODE,
    payload: mode,
  };
}
export function toggleOrganizationSharing(org) {
  return {
    type: TOGGLE_ORGANIZATION_SHARING,
    payload: org,
  };
}
export function updateOrganization(org) {
  return {
    type: UPDATE_ORGANIZATION,
    payload: org,
  };
}
export function loadTicketTemplate(templateId) {
  return {
    type: LOAD_TEMPLATE,
    payload: templateId,
  };
}
export function resetTicketTemplate() {
  return {
    type: UPDATE_TEMPLATE,
    payload: {},
  };
}
export function toggleDownloadCsvSnackbar() {
  return {
    type: TOGGLE_DOWNLOAD_CSV_ALERT,
  };
}
export function loadKnownIssue(data) {
  return {
    type: UPDATE_KNOWN_ISSUE,
    payload: data,
  };
}

export function saveConcedefyCommand(commandString) {
  return {
    type: CONCEDEFY_READ_ONLY_ACCESS_EMAIL,
    payload: commandString,
  };
}

export function getDefaultEmailsList() {
  return {
    type: GET_DEFAULT_EMAILS_LIST,
  };
}

export function updateDefaultEmailsList(data) {
  return {
    type: UPDATE_DEFAULT_EMAILS_LIST,
    payload: data,
  };
}

export function sendNewDefaultEmailCC(data) {
  return {
    type: SEND_NEW_DEFAULT_EMAILS,
    payload: data,
  };
}
