import { MenuItem, TextField } from "@mui/material";

import { type UniqueCustomerValues } from "../types";
import { purchaseFormFieldLabels } from "../utils/manualPurchaseUtils";
import { typeMappingConverter } from "../utils/workloadUtils";

type Props = {
  customerSku: UniqueCustomerValues;
  onChange: (value: string, fieldName: string) => void;
  fieldName: keyof UniqueCustomerValues;
  value: string;
  loading: boolean;
};

export const SkuDropdown = ({ customerSku, onChange, fieldName, value, loading }: Props) => {
  const addMenuItem = (field: keyof UniqueCustomerValues) =>
    customerSku[field].map((option) => (
      <MenuItem key={option + field} value={option}>
        {field === "type" ? typeMappingConverter(option) : option}
      </MenuItem>
    ));

  return (
    <TextField
      label={purchaseFormFieldLabels[fieldName]}
      fullWidth
      required
      value={value}
      select
      disabled={loading}
      onChange={(e) => {
        onChange(e.target.value, fieldName);
      }}
    >
      {addMenuItem(fieldName)}
    </TextField>
  );
};
