import { useState } from "react";

import { Box, Button } from "@mui/material";

type Props = {
  onEditClick: () => void;
  onUpdateClick: () => void;
  onCancelClick: () => void;
  showCancel: boolean;
};

export const OperationButton = ({ onEditClick, onUpdateClick, onCancelClick, showCancel }: Props) => {
  const [duringUpdate, setDuringUpdate] = useState<boolean>(false);

  const handleClick = () => {
    if (duringUpdate) {
      showCancel ? onCancelClick() : onUpdateClick();
      setDuringUpdate(false);
    } else {
      onEditClick();
      setDuringUpdate(true);
    }
  };

  const getButtonText = () => {
    if (duringUpdate) {
      return showCancel ? "Cancel" : "Update";
    }
    return "Edit";
  };

  return (
    <Box color="text.secondary" alignSelf="flex-end">
      <Button onClick={handleClick} variant="text" color="inherit" size="small">
        {getButtonText()}
      </Button>
    </Box>
  );
};
