import { DateTime } from "luxon";

import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { numberComparators } from "../../../../../types/FilterTable";
import { threeDotHeaderSection } from "../../../assetUtils";
import { type LookerAssetRowData } from "./LookerAssetRow";

const lookerItemDescriptors: FilterTableItemDescriptor<LookerAssetRowData>[] = [
  {
    headerText: "SKU",
    value: "sku",
    filterType: "string",
  },
  {
    headerText: "Service start date",
    value: "serviceStartDate",
    filterOptions: {
      comparators: numberComparators,
      toOption: (value: DateTime) => ({
        value,
        label: value.equals(DateTime.fromMillis(0)) ? "Never" : value.toLocaleString(),
      }),
    },
  },
  {
    headerText: "Quantity",
    value: "quantity",
    filterType: "number",
    hidden: { smDown: true },
  },
  {
    headerText: "Status",
    value: "status",
    filterType: "string",
    hidden: { mdDown: true },
  },
];

const { filters, headerColumns: baseHeaderColumns } = generateHeadersAndFilters(lookerItemDescriptors);
const headerColumns = [...baseHeaderColumns, threeDotHeaderSection] as const;

export { filters, headerColumns };
