import { type Dispatch, type SetStateAction, useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { type AutocompletePlugin } from "@algolia/autocomplete-js";
import {
  createLocalStorageRecentSearchesPlugin,
  type RecentSearchesPluginData,
  search,
} from "@algolia/autocomplete-plugin-recent-searches";
import { type RecentSearchesItem } from "@algolia/autocomplete-plugin-recent-searches/dist/esm/types";

import SearchIcon from "../../../assets//algolia/static-links-search.svg";
import SearchIconDark from "../../../assets/algolia/search-darkmode.svg";
import { AlgoliaResultListItem } from "../AlgoliaResultListItem";
import { type AlgoliaRowResult, type CustomerFromQuery } from "../types";
import { searchItemLogo } from "../utils";

type RecentSearchedItem = AlgoliaRowResult & RecentSearchesItem;
export const ALGOLIA_MAX_RECENT_ITEMS = 10;
export const RECENT_SEARCH_KEY = (userId: string | undefined) => `ALGOLIA_${userId || "GUEST"}`;
export const createRecentSearchesPlugin = (
  redirectTo: (url: string) => void,
  customerFromQuery: CustomerFromQuery,
  userId: string | undefined
) =>
  createLocalStorageRecentSearchesPlugin<RecentSearchedItem>({
    key: RECENT_SEARCH_KEY(userId),
    limit: ALGOLIA_MAX_RECENT_ITEMS,
    search(params) {
      if (params.query || customerFromQuery) {
        return [];
      }
      return search(params);
    },
    transformSource({ source }) {
      return {
        ...source,
        sourceId: "recentSearches",
        onSelect({ item, event }) {
          if (event.metaKey) {
            event.stopPropagation();
            return;
          }

          redirectTo(item.url);
        },
        getItemUrl({ item }) {
          return item.url;
        },
        templates: {
          item(params) {
            const { item, state } = params;
            let avatarLogo = item.avatarLogo;
            // if there is no logo attached to a recent search, use the default logo
            if (!avatarLogo && item.category) {
              avatarLogo = state.context.isDarkMode
                ? searchItemLogo[item.category].dark
                : searchItemLogo[item.category].light;
            }
            if (avatarLogo && !item.category && !item.avatarFallback) {
              avatarLogo = state.context.isDarkMode ? SearchIconDark : SearchIcon;
            }
            return (
              <AlgoliaResultListItem
                avatarLogo={avatarLogo}
                avatarFallback={item.avatarFallback}
                highlightedTitle={item.highlightedTitle}
                highlightedSubtitle={item.highlightedSubtitle}
              />
            );
          },
        },
      };
    },
  });

export const useRecentSearchPlugin = (
  setSearchModalVisible: Dispatch<SetStateAction<boolean>>,
  customerFromQuery: CustomerFromQuery,
  userId: string | undefined
): AutocompletePlugin<RecentSearchedItem, RecentSearchesPluginData<RecentSearchedItem>> => {
  const history = useHistory();

  const redirectTo = useCallback(
    (url: string) => {
      history.push(url);
      setSearchModalVisible(false);
    },
    [history, setSearchModalVisible]
  );

  return useMemo(
    () => createRecentSearchesPlugin(redirectTo, customerFromQuery, userId),
    [customerFromQuery, redirectTo, userId]
  );
};
