import { type JSX } from "react";

import { Card, CardHeader } from "@mui/material";

type Props = {
  icon: JSX.Element;
  title: string;
  description: string;
  onClick: () => void;
};

export function LinkCard({ icon, title, description, onClick }: Props) {
  return (
    <Card onClick={onClick} sx={{ height: "100%", "&:hover": { borderColor: "primary.main", cursor: "pointer" } }}>
      <CardHeader
        avatar={icon}
        title={title}
        titleTypographyProps={{ variant: "body1", fontWeight: 500 }}
        subheader={description}
        subheaderTypographyProps={{ variant: "body2" }}
        sx={{
          ".MuiCardHeader-avatar": {
            alignSelf: "flex-start",
            color: "primary.main",
          },
        }}
      />
    </Card>
  );
}
