import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Link, ListItem, Tooltip, Typography } from "@mui/material";

import { helpMenuTxt } from "../../../assets/texts/Header/helpMenu";
import { routes } from "../../../constants/navigation";
import { useKeyboardShortcutsOpen } from "../../../Context/keyboardShortcutsOpenProvider";
import { isMac } from "../../../utils/common";
import { type HeaderDropdownMenuItem } from "../../types";
import HeaderMenuItem from "./HeaderMenuItem";
import { UserVoiceFeedbackButtonWrapper } from "./UserVoiceFeedbackButton";

const HelpMenu = () => {
  const { setShortcutsOpen } = useKeyboardShortcutsOpen();

  const suggestAnIdea = {
    component: <UserVoiceFeedbackButtonWrapper />,
  };
  const links = [
    {
      title: helpMenuTxt.CONSOLE_DOCUMENTATION,
      href: "https://help.doit.com",
      to: routes.developerDocs,
      target: "_blank",
    },
    {
      title: helpMenuTxt.DEVELOPER_DOCUMENTATION,
      href: routes.developerDocs,
      rel: "noopener noreferrer",
      target: "_blank",
    },
    {
      title: helpMenuTxt.TRUST_AND_SECURITY,
      href: "https://trust.doit.com",
      to: routes.developerDocs,
      target: "_blank",
    },
  ].map(
    ({ title, href, rel, target }, index): HeaderDropdownMenuItem => ({
      component: (
        <ListItem
          data-cy={`item-${index + 1}`}
          component={Link}
          sx={{ color: "navigation.subItemColor" }}
          dense
          disableGutters
          href={href}
          underline="none"
          rel={rel}
          target={target}
        >
          <Typography variant="body2">{title}</Typography>
        </ListItem>
      ),
    })
  );

  const items: HeaderDropdownMenuItem[] = [...links, suggestAnIdea];

  // if we are on Mac put the keyboard shortcuts as first element
  if (isMac()) {
    const shortcuts = {
      component: (
        <Typography data-cy="item-0" sx={{ color: "navigation.subItemColor", my: 0.5 }} variant="body2">
          Keyboard shortcuts
        </Typography>
      ),
      handler: () => {
        setShortcutsOpen(true);
      },
    };

    items.splice(0, 0, shortcuts);
  }
  return (
    <HeaderMenuItem
      placement="top-end"
      innerMenuItemComponent={
        <Tooltip title="Help" placement="bottom">
          <HelpOutlineIcon />
        </Tooltip>
      }
      items={items}
      id="helpMenu"
    />
  );
};

export default HelpMenu;
