import {
  CustomerModel,
  type InAppNotificationKnownIssueModel,
  type KnownIssuePlatform,
  KnownIssuePlatforms,
} from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { InAppNotificationType, type Mapper } from "../types";

const cloudFromPlatform = (platform: KnownIssuePlatform) => {
  const aws = "Amazon Web Services";
  const gcp = "Google Cloud";
  switch (platform) {
    case KnownIssuePlatforms.AWS:
      return aws;
    case KnownIssuePlatforms.GoogleCloud:
      return gcp;
    case KnownIssuePlatforms.GCP:
      return gcp;
    case KnownIssuePlatforms.GSuite:
      return gcp;
    default:
      throw new Error(`Unknown platform ${platform}`);
  }
};
export class KnownIssues implements Mapper<InAppNotificationKnownIssueModel> {
  readonly type = InAppNotificationType.CloudIncident;

  static color = "#d5fdf8";

  getCollection: Mapper<InAppNotificationKnownIssueModel>["getCollection"] = (customerRef, startTime) =>
    getCollection(CustomerModel)
      .doc(customerRef.id)
      .collection("affectedByKnownIssues")
      .where("created", ">=", startTime)
      .orderBy("created", "desc");

  userHasPermission: Mapper<InAppNotificationKnownIssueModel>["userHasPermission"] = (userRoles) =>
    userRoles?.issuesViewer ?? false;

  toNotification: Mapper<InAppNotificationKnownIssueModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.CloudIncident,
      created: docData.created,
      updated: docData.updated,
      title: `Cloud incident detected on ${cloudFromPlatform(docData.platform as `${KnownIssuePlatforms}`)}`,
      description: `Detected for service${docData.products.length > 1 ? "s" : ""} ${docData.products.join(", ")}.`,
      urlPath: `/customers/${docData.customerRef.id}/known-issues/${docData.issueDocId}`,
      color: KnownIssues.color,
    };
  };
}
