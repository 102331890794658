import { useCallback, useEffect, useMemo, useState } from "react";

import {
  type AWSFlexsaveConfigurationModel,
  type FlexsaveRDS,
  type FlexsaveSageMaker,
  type FlexsaveType,
  FlexsaveTypes,
  IntegrationModel,
} from "@doitintl/cmp-models";
import { getCollection, useDocumentData, type WithFirebaseModel } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Customer } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { useAsyncCurrencyConverter } from "../../../utils/common";
import { useFlexsaveApi, useFlexsaveDoc, useFlexsaveRDS, useFlexsaveSageMaker } from "../hooks";
import { asyncConvertData, asyncConvertDataToCurrencyAWS } from "../utils/costUtils";

export function useFlexsaveComputeData(): {
  data: WithFirebaseModel<AWSFlexsaveConfigurationModel> | undefined;
  loading: boolean;
} {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [doc, loading] = useFlexsaveDoc(customer.id);
  const [data, setData] = useState<WithFirebaseModel<AWSFlexsaveConfigurationModel>>();

  const { asyncConvert } = useAsyncCurrencyConverter();

  useEffect(() => {
    if (loading || !doc?.AWS) {
      setData(undefined);
      return;
    }

    asyncConvertDataToCurrencyAWS(doc.AWS, asyncConvert).then((convertedData) => {
      setData(convertedData);
    });
  }, [asyncConvert, doc, loading]);

  return {
    data,
    loading,
  };
}

export function useFlexsaveSageMakerData(): {
  sageMakerData: FlexsaveSageMaker | undefined;
  loading: boolean;
} {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [doc, loading] = useFlexsaveSageMaker(customer.id);
  const [data, setData] = useState<WithFirebaseModel<FlexsaveSageMaker>>();

  const { asyncConvert } = useAsyncCurrencyConverter();

  useEffect(() => {
    if (loading || !doc) {
      setData(undefined);
      return;
    }

    asyncConvertData(doc, asyncConvert).then((convertedData) => {
      setData(convertedData);
    });
  }, [asyncConvert, doc, loading]);

  return {
    sageMakerData: data,
    loading,
  };
}

export function useFlexsaveRDSData(): {
  rdsData: FlexsaveRDS | undefined;
  loading: boolean;
} {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const [doc, loading] = useFlexsaveRDS(customer.id);
  const [data, setData] = useState<WithFirebaseModel<FlexsaveRDS>>();

  const { asyncConvert } = useAsyncCurrencyConverter();

  useEffect(() => {
    if (loading || !doc) {
      setData(undefined);
      return;
    }

    asyncConvertData(doc, asyncConvert).then((convertedData) => {
      setData(convertedData);
    });
  }, [asyncConvert, doc, loading]);

  return {
    rdsData: data,
    loading,
  };
}

export function useFlexsaveAWSEnabled(): [data: boolean | undefined, loading: boolean] {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext({ allowNull: true });
  const [value, setValue] = useState<boolean | undefined>();

  useEffect(() => {
    if (!customer?.id) {
      setValue(false);
      return;
    }

    getCollection(IntegrationModel)
      .doc("flexsave")
      .collection("configuration")
      .doc(customer.id)
      .get()
      .then((snap) => {
        const data = snap.data();
        setValue(!!data?.[FlexsaveTypes.AWS]?.enabled);
      })
      .catch((e: any) => {
        if (e.name === "FirebaseError" && e.code === "permission-denied") {
          setValue(false);
          return;
        }

        throw e;
      });
  }, [customer?.id]);

  return [value, value === undefined];
}

export const useCanDisable = (type: FlexsaveType): boolean => {
  const api = useFlexsaveApi(type);
  const { data } = useFlexsaveComputeData();

  const [canDisable, setCanDisable] = useState<boolean>(false);

  useEffect(() => {
    const getManage = async () => {
      try {
        const result = await api.canDisable();
        if (result?.data === true) {
          setCanDisable(true);
        }
      } catch (e) {
        consoleErrorWithSentry(e);
      }
    };

    if (data?.enabled) {
      getManage();
    } else {
      setCanDisable(false);
    }
  }, [api, data]);

  return canDisable;
};

export function useFlexsaveEnableCheckWithExistingSavingsData(type: FlexsaveType) {
  const customerId = useCustomerId();
  const [doc, loading] = useFlexsaveDoc(customerId);

  return useMemo(() => {
    if (loading) {
      return [false, true];
    } else {
      const enabled = doc?.[type]?.enabled;
      const hasHistory = Object.keys(doc?.[type]?.savingsHistory || {}).length > 0;
      return [Boolean(enabled) && Boolean(hasHistory), false];
    }
  }, [loading, doc, type]);
}

export const useIsFlexsaveStandaloneCustomer = () => {
  const { customer } = useCustomerContext();

  return customer.enabledFlexsave?.AWS;
};

export const useCustomerSavingsPlans = (customer: Customer) => {
  const [data] = useDocumentData(
    getCollection(IntegrationModel).doc("flexsave").collection("customer-savings-plans").doc(customer.id)
  );

  return data?.savingsPlans ?? [];
};

export const useEnableFlexsaveSageMaker = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const showError = useErrorSnackbar();
  const showSuccess = useSuccessSnackbar();

  return useCallback(async () => {
    try {
      await api.post(`/v1/customers/${customer.id}/flexsave-sagemaker/enable`);
    } catch (e) {
      showError("Failed to enable Flexsave SageMaker");
      return;
    }

    showSuccess("Flexsave SageMaker enabled successfully");
  }, [showError, showSuccess, api, customer.id]);
};

export const useEnableFlexsaveRDS = () => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const showError = useErrorSnackbar();
  const showSuccess = useSuccessSnackbar();

  return useCallback(async () => {
    try {
      await api.post(`/v1/customers/${customer.id}/flexsave-rds/enable`);
    } catch (e) {
      showError("Failed to enable Flexsave RDS");
      return;
    }

    showSuccess("Flexsave RDS enabled successfully");
  }, [showError, showSuccess, api, customer.id]);
};
