import { AccountManagerStatuses } from "@doitintl/cmp-models";
import find from "lodash/find";

import { Comparators, type DataColumn, stringComparators } from "../../../types/FilterTable";
import { type AccountRole, companiesLookup } from "../../../utils/common";
import { NotAvailable } from "../../Assets/Tabs/components/typographics";

export const accountManagersFilterColumns = (roles: AccountRole[]): DataColumn[] => [
  {
    path: "name",
    label: "Name",
    comparators: stringComparators,
  },
  {
    path: "email",
    label: "Email",
    comparators: [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO],
  },
  {
    path: "role",
    label: "Role",
    comparators: [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO],
    toOption: (value) => {
      value = value ? value : "";
      return {
        value,
        label: find(roles, { value })?.name ?? value,
      };
    },
  },
  {
    path: "company",
    label: "Company",
    comparators: [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO],
    toOption: (value) => {
      value = value ? value : "";
      return {
        value,
        label: companiesLookup[value]?.name,
      };
    },
  },
  {
    path: "status",
    label: "Status",
    comparators: [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO],
  },
  {
    path: "reportsTo",
    label: "Manager",
    comparators: stringComparators,
    toOption: (value) => ({
      label: value?.name ?? value?.email ?? NotAvailable,
      value,
    }),
  },
];

export const accountManagersDefaultFilters = [
  {
    column: {
      label: "Status",
      path: "status",
    },
    comparator: Comparators.NOT_EQUAL_TO,
    value: AccountManagerStatuses.TERMINATED,
    label: AccountManagerStatuses.TERMINATED,
  },
] as const;
