import { Sort } from "@doitintl/cmp-models";
import orderBy from "lodash/orderBy";

const replaceWithSubtotal = `${String.fromCharCode(0)}Subtotal`;
export const nullCell = "nullCell";
export const subtotal = "Subtotal";

// combine the metrics of 2 rows into the first row
// example:
// row=["google-cloud","Flexsave","AU","/taboola.com/","2023","06",-361.31590165025113,119332.94179284833,2,"none","none","none"]
// rowToAdd=["google-cloud","Flexsave","AU","/taboola.com/","2023","06",-361.31590165025113,119332.94179284833,2,"none","none","none"]
// result=["google-cloud","Flexsave","AU","/taboola.com/","2023","06",-722.6318033005023,238665.88358569666,4,"none","none","none"]
export const combineRowsValues = (row: any, rowToAdd: any, rowsColsLen: number, metricsAmount: number) => {
  for (let j = rowsColsLen; j < rowsColsLen + metricsAmount; j++) {
    row[j] = Number(rowToAdd[j]) + Number(row[j]);
  }
};

// generate key for the row, based on the groupings and the column values
// i.e. row=["google-cloud","Flexsave","AU","/taboola.com/","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// if the lastGroupIndex=2 then key="google-cloud|Flexsave"
export const generateRowKey = (row: any, lastGroupIndex: number) => {
  let key = "";
  for (let r = 0; r < lastGroupIndex; r++) {
    key += row[r] as string;
    key += String.fromCharCode(0);
  }

  return key;
};

// generate key for the column values
// i.e. row=["google-cloud","Flexsave","AU","/taboola.com/","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// key="2023|06"
export const generateColKey = (row: any, rowsLen: number, colsLen: number) => {
  let key = "";
  for (let c = rowsLen; c < rowsLen + colsLen; c++) {
    key += row[c] as string;
    key += String.fromCharCode(0);
  }

  return key;
};

// generate the subtotal rows map
// example of subtotalMap:
// { "google-cloud|regular|AU": { "2023|06": [row1, row2], "2023/07": [row3] } }
// { "google-cloud|Flexsave|AU": { "2023|06": [row4, row5], "2023/07": [row6] } }
export const getSubtotalMap = (rowsData: readonly any[][], rowsLen: number, colsLen: number) => {
  const subtotalMap = new Map<string, Map<string, any[][]>>();
  rowsData.forEach((row: string[]) => {
    for (let i = 1; i < rowsLen; i++) {
      const rowKey = generateRowKey(row, i);
      const colKey = generateColKey(row, rowsLen, colsLen);

      const newRow = [...row];
      // replace the extra grouping values with empty string
      for (let r = i + 1; r < rowsLen; r++) {
        newRow[r] = nullCell;
      }

      newRow[i] += replaceWithSubtotal;

      const rows = subtotalMap.get(rowKey);
      if (rows) {
        const cols = rows.get(colKey);
        if (cols) {
          cols.push(newRow);
        } else {
          rows.set(colKey, [newRow]);
        }
      } else {
        const colMap = new Map([[colKey, [newRow]]]);
        subtotalMap.set(rowKey, colMap);
      }
    }
  });

  return subtotalMap;
};

// merge the rows into one row with the subtotal values
// example:
// newRows=[
//   ["google-cloud","Flexsave","AU","Subtotal","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"],
//   ["google-cloud","Flexsave","AU","Subtotal","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// ]
// result: mergedRow=["google-cloud","Flexsave","AU","Subtotal","2023","06",-722.6318033005023,238665.88358569666,0,"none","none","none"]
export const mergeRows = (newRows: any, rowsLen: number, colsLen: number, metricsAmount: number) => {
  const mergedRow = [...newRows[0]];
  for (let i = 1; i < newRows.length; i++) {
    combineRowsValues(mergedRow, newRows[i], rowsLen + colsLen, metricsAmount);
  }
  for (let i = 1; i < rowsLen; i++) {
    // keep only Subtotal in the string
    if (typeof mergedRow[i] === "string" && mergedRow[i].endsWith(replaceWithSubtotal)) {
      mergedRow[i] = subtotal;
    }
  }
  return mergedRow;
};

// checks if there are more than one subtotal group, for example:
// { "google-cloud|regular|AU": { "2023|06": [row1, row2], "2023/07": [row3] } }
// row1=["google-cloud","regular","AUSubtotal","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// row2=["google-cloud","regular","AUSubtotal","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// row3=["google-cloud","regular","ILSubtotal","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// we have 2 subtotal groups: "google-cloud|regular|AU" and "google-cloud|regular|IL"
export const shouldAddSubtotalRows = (subtotalColMap: Map<string, any[][]>, rowsLen: number) => {
  const combinedRows: any[][] = [];
  subtotalColMap.forEach((rows) => {
    combinedRows.push(...rows);
  });
  const subtotalGroups: string[] = [];
  combinedRows.forEach((row) => {
    for (let i = 1; i < rowsLen; i++) {
      if (typeof row[i] === "string" && row[i].includes(replaceWithSubtotal)) {
        if (!subtotalGroups.includes(row[i])) {
          subtotalGroups.push(row[i]);
        }
      }
    }
  });

  return subtotalGroups.length > 1;
};

// generate the subtotal rows from the subtotal map
export const getSubtotalRowsFromMap = (
  subtotalMap: Map<string, Map<string, any[][]>>,
  rowsLen: number,
  colsLen: number,
  metricsAmount: number
) => {
  const result: any[][] = [];
  subtotalMap.forEach((subtotalColMap) => {
    const shouldAddRows = shouldAddSubtotalRows(subtotalColMap, rowsLen);
    // we add subtotal row only if there are more than 1 rows in the group
    if (shouldAddRows) {
      subtotalColMap.forEach((rows) => {
        const mergedRow = mergeRows(rows, rowsLen, colsLen, metricsAmount);
        result.push(mergedRow);
      });
    }
  });

  return result;
};

// generate a comparative row from the current row and the previous row
// example:
// currentRow=["google-cloud","Flexsave","AU","Subtotal","2023","07",15,15,15,"none","none","none"]
// previousRow=["google-cloud","Flexsave","AU","Subtotal","2023","06",10,10,10,"none","none","none"]
// result=["google-cloud","Flexsave","AU","Subtotal","2023","07",15,15,15,{pct:50,val:5},{pct:50,val:5},{pct:50,val:5}]
export const generateComparativeRow = (
  currentRow: any[],
  previousRow: any[],
  rowsLen: number,
  colsLen: number,
  metricAmount: number
) => {
  const newRow = [...currentRow];
  const firstMetricIndex = rowsLen + colsLen;
  for (let i = firstMetricIndex; i < firstMetricIndex + metricAmount; i++) {
    const currentValue = currentRow[i];
    const previousValue = previousRow[i];
    const comparativeValuePercent = 100 * ((currentValue - previousValue) / previousValue);
    const comparativeValue = currentValue - previousValue;
    const comparativeObject = {
      pct: comparativeValuePercent,
      val: comparativeValue,
    };
    newRow[i + metricAmount] = comparativeObject;
  }
  return newRow;
};

// generate the comparative rows from the subtotal rows
// example:
// subtotalRows=[
//   ["google-cloud","Flexsave","AU","Subtotal","2023","06",10,10,10,"none","none","none"],
//   ["google-cloud","Flexsave","AU","Subtotal","2023","07",15,15,15,"none","none","none"],
// ]
// result:
//   ["google-cloud","Flexsave","AU","Subtotal","2023","06",10,10,10,"none","none","none"],
//   ["google-cloud","Flexsave","AU","Subtotal","2023","07",15,15,15,{pct:50,val:5},{pct:50,val:5},{pct:50,val:5}],
// ]
export const generateSubtotalsComparative = (
  subtotalRows: any[][],
  rowsLen: number,
  colsLen: number,
  metricAmount: number
) => {
  let previousValue: any[] = [];
  let previousRowKey = "";

  for (let i = 0; i < subtotalRows.length; i++) {
    const currentRow = subtotalRows[i];
    const rowKey = generateRowKey(currentRow, rowsLen);
    if (previousValue.length > 0 && previousRowKey === rowKey) {
      const comparativeRow = generateComparativeRow(currentRow, previousValue, rowsLen, colsLen, metricAmount);
      subtotalRows.splice(i, 1, comparativeRow);
    }
    previousRowKey = rowKey;
    previousValue = currentRow;
  }

  return subtotalRows;
};

// generate the subtotal rows
// example:
// rowData=[
//   ["google-cloud","Flexsave","AU","/taboola.com/","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"],
//   ["google-cloud","Flexsave","AU","/taboola.com/Vision","2023","06",-361.31590165025113,119332.94179284833,0,"none","none","none"]
// ]
// result: newRows=[
//   ["google-cloud","Flexsave","AU","Subtotal","2023","06",-722.6318033005023,238665.88358569666,0,"none","none","none"]
// ]
export const generateSubtotals = (
  rowsData: any[][],
  rowsLen: number,
  colsLen: number,
  metricsAmount: number,
  comparative?: boolean
): any[][] => {
  if (rowsLen < 2) {
    return [];
  }

  const subtotalMap = getSubtotalMap(rowsData, rowsLen, colsLen);

  let result = getSubtotalRowsFromMap(subtotalMap, rowsLen, colsLen, metricsAmount);

  if (comparative) {
    result = generateSubtotalsComparative(result, rowsLen, colsLen, metricsAmount);
  }

  return result ?? [];
};

export const isSubtotalRow = (row: any[]): boolean => row.some((value) => value === subtotal);

// make an array with 2 elements, the first element is if the group is a subtotal, the second element is the group key
// we call this for each index
const defaultSorters = (index: number) => [
  (item: string[]) => (item[index] === subtotal ? 1 : 0),
  (item: string[]) => item[index],
];

// sort the rows by the group keys, and put the subtotal rows at the end
// example:
// rowKeys = [
//   ["google-cloud","Flexsave", "AU","Subtotal"],
//   ["google-cloud","Flexsave", "AU","Regular"],
//   ["google-cloud","Flexsave", "AU","Flexsave"],
// ]
// result:
// rowKeys = [
//   ["google-cloud","Flexsave", "AU","Flexsave"],
//   ["google-cloud","Flexsave", "AU","Regular"],
//   ["google-cloud","Flexsave", "AU","Subtotal"],
// ]
export const sortRowsBySubtotalsATOZ = (rowKeys: string[][], numOfGroups: number) => {
  for (let i = numOfGroups - 1; i >= 0; i--) {
    rowKeys = orderBy(rowKeys, defaultSorters(i), ["asc", "asc"]);
  }
  return rowKeys;
};

type GetValue = (row: string[]) => number;

// sort the group rows recursively or sort the rows by value if we are in the last group in the hierarchy
const sortRowsBySubtotalOrEnd = (
  rows: string[][],
  sort: Sort,
  getValue: GetValue,
  numOfGroups: number,
  groupIndex: number
) => {
  groupIndex++;
  if (groupIndex > numOfGroups) {
    return rows;
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return sortRowsBySubtotalsRec(rows, sort, getValue, numOfGroups, groupIndex);
};

// sort the groups by the subtotal value
const sortGroupsRows = (sort: Sort, rowGroups: string[][][], valuesArray: number[]) => {
  const sortBy = sort === Sort.ASC ? "asc" : "desc";

  const paired = rowGroups.map((rows, index) => ({
    rows,
    value: valuesArray[index],
  }));

  const sorted = orderBy(paired, (x) => x.value, [sortBy]);
  return sorted.flatMap((x) => x.rows);
};

// sort each group by the subtotal value, called recursively for each group
export const sortRowsBySubtotalsRec = (
  rowKeys: string[][],
  sort: Sort,
  getValue: GetValue,
  numOfGroups: number,
  groupIndex: number
) => {
  const rowGroups: string[][][] = [];
  const valuesArray: number[] = [];
  const rowKeysLen = rowKeys.length;
  let rows: string[][] = [];
  let resetGroup = false;

  for (let rowIndex = 0; rowIndex < rowKeysLen; rowIndex++) {
    const currentRow = rowKeys[rowIndex];
    if (groupIndex + 1 <= numOfGroups && currentRow[groupIndex + 1] === subtotal) {
      rows = sortRowsBySubtotalOrEnd(rows, sort, getValue, numOfGroups, groupIndex);
      rows.push(currentRow);
      resetGroup = true;
    } else if (rowIndex === rowKeysLen - 1) {
      rows.push(currentRow);
      rows = sortRowsBySubtotalOrEnd(rows, sort, getValue, numOfGroups, groupIndex);
      resetGroup = true;
    } else {
      rows.push(currentRow);
      const rowKey = generateRowKey(currentRow, groupIndex + 1);
      const nextRowKey = generateRowKey(rowKeys[rowIndex + 1], groupIndex + 1);
      if (rowKey !== nextRowKey) {
        rows = sortRowsBySubtotalsRec(rows, sort, getValue, numOfGroups, groupIndex);
        resetGroup = true;
      }
    }
    if (resetGroup) {
      rowGroups.push([...rows]);
      rows = [];
      valuesArray.push(getValue(currentRow));
      resetGroup = false;
    }
  }

  return sortGroupsRows(sort, rowGroups, valuesArray);
};

// sort the rows by the subtotal value
export const sortRowsBySubtotals = (
  rowKeys: string[][],
  numOfGroups: number,
  sort: Sort,
  getValue: (rowKeys: string[]) => number
) => {
  // sortRowsBySubtotalsATOZ add subtotal rows at the end of each group
  rowKeys = sortRowsBySubtotalsATOZ(rowKeys, numOfGroups);
  return sortRowsBySubtotalsRec(rowKeys, sort, getValue, numOfGroups, 0);
};
