import TableCell from "@mui/material/TableCell";

import { formatDecimalNumber } from "../../../utils/common";
import { type GCPPricelistItem } from "./types";

type Props = {
  row: GCPPricelistItem;
};

export const GCPPriceListRow = ({ row }: Props) => {
  const { description, region, hardware, type, flexsaveCharge, GCPPrice, savingsRate, sku } = row;
  return (
    <>
      <TableCell>{region}</TableCell>
      <TableCell>{hardware}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{sku}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>${formatDecimalNumber(flexsaveCharge, 6)}</TableCell>
      <TableCell>${formatDecimalNumber(GCPPrice, 6)}</TableCell>
      <TableCell>{formatDecimalNumber(savingsRate, 0)}%</TableCell>
    </>
  );
};
