import { useEffect, useState } from "react";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useHasAdminOrSaasAdminRole } from "../../utils/permissionHooks";

/** @description: in case customer enabled SSO some IAM operations might be restricted. this hook calculate if restriction should be applied */
export const useIsRestrictedIamDueToSso = () => {
  const [isRestrictedIamDueToSso, setIsRestrictedIamDueToSso] = useState(false);
  const {
    customer: { auth },
  } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const isAdminOrSaasAdmin = useHasAdminOrSaasAdminRole();

  useEffect(() => {
    const isSsoEnabled = auth?.sso?.saml === "enabled" || auth?.sso?.oidc === "enabled";
    setIsRestrictedIamDueToSso(isSsoEnabled && !isDoitEmployee && !isAdminOrSaasAdmin);
  }, [isAdminOrSaasAdmin, isDoitEmployee, auth?.sso]);

  return { isRestrictedIamDueToSso };
};
