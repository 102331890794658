import { type JSX } from "react";

import { type StandaloneOnboardingErrorType } from "@doitintl/cmp-models";

import { OnBoardingAlert } from "../OnBoardingAlert";

export enum ErrorStepLocation {
  AWS_SAVINGS,
  AWS_ACTIVATION,
  GCP_SAVINGS,
  GCP_ACTIVATION,
}

type ErrorDetails = {
  title: string;
  message: string | JSX.Element;
};

function getShortGeneralMessageByStep(errorStep: ErrorStepLocation): string {
  switch (errorStep) {
    case ErrorStepLocation.GCP_SAVINGS:
    case ErrorStepLocation.AWS_SAVINGS:
      return "Sorry, something went wrong with savings estimate.";

    case ErrorStepLocation.GCP_ACTIVATION:
    case ErrorStepLocation.AWS_ACTIVATION:
      return "Sorry, something went wrong with activation.";
  }
}

function getDetailedGeneralMessageByStep(errorStep: ErrorStepLocation): ErrorDetails {
  switch (errorStep) {
    case ErrorStepLocation.AWS_SAVINGS:
      return {
        title: "Failed to access AWS Cost Explorer",
        message:
          "We were unable to access your recommendations, please try again. If you are still experiencing issues please contact support.",
      };
    case ErrorStepLocation.AWS_ACTIVATION:
      return {
        title: "Failed to access your organization",
        message:
          "We were unable to access your organization, please try again. If you are still experiencing issues please contact support.",
      };
    case ErrorStepLocation.GCP_SAVINGS:
      return {
        title: "Error with your estimate",
        message:
          "Please retry the steps outlined to get your estimate. If you are still experiencing issues please contact support.",
      };
    case ErrorStepLocation.GCP_ACTIVATION:
      return {
        title: "Error with activation",
        message:
          "Please retry the steps outlined to activate. If you are still experiencing issues please contact support",
      };
  }
}

function getErrorMessage(error: StandaloneOnboardingErrorType, errorStep: ErrorStepLocation): ErrorDetails | undefined {
  switch (error) {
    case "general":
      return getDetailedGeneralMessageByStep(errorStep);

    case "incorrect_cur_file":
      return {
        title: "CUR path not found",
        message:
          "The CUR path provided could not be found, please correct this before activating Flexsave. If you are still experiencing issues please contact support.",
      };
    case "linked_account":
      return {
        title: "Linked account used for estimate",
        message:
          "Please use a management account rather than a linked account to get your estimate. If you are still experiencing issues please contact support.",
      };

    case "insufficient_permissions":
      return {
        title: "Incorrect permissions",
        message: "You do not have the required permissions.",
      };
  }
}

/**
 * The short description that will be shown at the tasks view of the standalone
 */
export function getShortErrorDescription(
  error: StandaloneOnboardingErrorType | undefined,
  errorStep: ErrorStepLocation
): JSX.Element | string {
  if (!error) {
    return <></>;
  }
  switch (error) {
    case "general":
      return getShortGeneralMessageByStep(errorStep);

    case "empty_savings":
      return "Sorry, we can’t provide you with any savings.";

    case "insufficient_permissions":
      return "Sorry, the permissions were incorrect.";

    case "incorrect_cur_file":
      return "The CUR path provided could not be found. Please provide a valid CUR path.";

    case "linked_account":
      return (
        <>
          Please use a <b>management account</b> and not a <b>linked account</b> to get your estimate.
        </>
      );
  }

  return <></>;
}

/**
 * A detailed Alert with explanation about an error, will be used to show error inside a specific page
 */
export function getErrorAlert(
  error: StandaloneOnboardingErrorType | undefined,
  errorStep: ErrorStepLocation
): JSX.Element | undefined {
  if (!error) {
    return;
  }
  const errorDetails = getErrorMessage(error, errorStep);
  return errorDetails && <OnBoardingAlert type="error" title={errorDetails.title} body={errorDetails.message} />;
}
