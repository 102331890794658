import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Link, List, ListItem, Typography } from "@mui/material";
import styled from "styled-components";

export const S3ReportDetails = {
  granularity: { label: "Time granularity", value: "Hourly" },
  format: { label: "Format", value: "CSV or Parquet" },
  compression: { label: "Compression type", value: "GZIP" },
  reportContent: { label: "Report content contains", value: "Resource IDs" },
};

export const step1Billing = (
  <>
    <Typography display="inline">
      In the AWS Console, create a new Cost and Usage Report in Billing and Cost Management with the following details:
    </Typography>
    <Typography variant="body1" ml={2}>
      <List sx={{ listStyleType: "disc", pl: 2 }}>
        {Object.values(S3ReportDetails).map((item) => (
          <ListItem key={item.label} sx={{ display: "list-item", mt: -2, pl: 0 }}>
            <Box>
              <Typography sx={{ display: "inline" }}>{item.label}: </Typography>
              <Typography fontWeight={500} sx={{ display: "inline", whiteSpace: "normal" }}>
                {item.value}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </Typography>
  </>
);

export const step2Billing = "Enter your S3 Bucket where you have created the Cost and Usage Report from step 1";

export const step3Billing = "Select Open AWS CloudFormation, then select CREATE STACK.";

const step1Estimation = "Select Open AWS CloudFormation Console.";

const selectCheckboxStep =
  "Within AWS Console, select the checkbox “I acknowledge that AWS CloudFormation might create IAM resources with custom names.“";

const finalStep = `Select CREATE STACK.`;

export const estimationSteps = [step1Estimation, selectCheckboxStep, finalStep];

const Li = styled(ListItem)`
  display: list-item;
  padding: 0;
`;

const Strong = styled(Typography)`
  font-weight: 500;
  display: inline;
`;

export const csvEstimationSteps = [
  <Box display="inline-block" key="1">
    <Typography variant="body1" display="flex" flexDirection="row" alignItems="center">
      Log in to your
      <Link
        target="_blank"
        href="https://us-east-1.console.aws.amazon.com/cost-management/home?region=us-east-1#/savings-plans/recommendations?lookbackPeriodInDays=THIRTY_DAYS&paymentOption=NO_UPFRONT&scope=PAYER&spType=COMPUTE_SP&termInYears=THREE_YEAR&tokens=%5B%5D"
        underline="none"
        display="flex"
        flexDirection="row"
        alignItems="center"
        ml={0.4}
      >
        Cost Management Console <OpenNewIcon sx={{ fontSize: 18, marginLeft: 0.5 }} />
      </Link>
    </Typography>
  </Box>,
  <Typography variant="body1" display="inline" key="2">
    Verify the following values are selected:
    <List sx={{ listStyleType: "disc", padding: 0, marginLeft: 4 }}>
      <Li>
        Recommendation Level: <Strong>Payer</Strong>
      </Li>
      <Li>
        Savings plan term: <Strong>3 years</Strong>
      </Li>
      <Li>
        Payment option: <Strong>No upfront</Strong>
      </Li>
      <Li>
        Based on the past: <Strong>30 days</Strong>
      </Li>
    </List>
  </Typography>,
  "Download the CSV",
  <Typography variant="body1" display="inline" key="4">
    Click the <Strong>Upload</Strong> button below and select your CSV
  </Typography>,
];
