import { useMemo } from "react";

import { type DefaultFilterColumn } from "../../types/FilterTable";
import useQueryString from "./useQueryString";

/**
 * Accesses the "&filter" parameter from the current page's URL query string,
 * parses it as a comma-separated array of strings,
 * and then builds an array of { label: string, value: string, column: null } objects.
 *
 * Intended to be used as a parameter for the "defaultFilters" prop of the FilterTable component.
 *
 * @returns FilterTableDefaultFilter[] | undefined
 */
const useFiltersFromUrl = <T>(): DefaultFilterColumn<T>[] | undefined => {
  const qs = useQueryString();
  return useMemo<DefaultFilterColumn<T>[] | undefined>(
    () =>
      qs.filter
        ?.toString()
        .split(",")
        .map((filter) => ({ label: filter, value: filter })),
    [qs.filter]
  );
};

export default useFiltersFromUrl;
