import React, { useCallback, useEffect, useMemo } from "react";

import {
  AssetTypeBetterCloud,
  AssetTypeGcpPartnerLedPremiumSupport,
  AssetTypeLooker,
  AssetTypeZendesk,
  DoitRole,
} from "@doitintl/cmp-models";
import { Autocomplete, TextField } from "@mui/material";

import { useDoitRoleCheck } from "../../../../../../Components/hooks/useDoitRoles";
import { useContractsContext } from "../../../../../../Context/customer/ContractsContext";
import { useContractTypesContext } from "../../../../../ContractsTabs/ContractsTypesContext";
import { fieldRange } from "../../../const";
import { stateDefaultValues, useContractFormContext } from "../../../ContractsFormContext";
import { type ContractStateType, type ContractType } from "../../../types";
import { ifProductWithoutEntity } from "../../../utils";

export const ContractTypeSelector = () => {
  const { state, setState } = useContractFormContext();
  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);
  const { contractProductTypes } = useContractTypesContext();

  const handleChangeContractType = useCallback(
    (_, selectedValue) => {
      setState((prevState) => {
        const update: ContractStateType = {
          ...stateDefaultValues,
          type: selectedValue?.value,
          entity: prevState.entity,
          accountManager: prevState.accountManager,
          errors: {
            ...prevState.errors,
            type: false,
            discountEndDate: false,
            chargePerTerm: false,
          },
        };

        if ([AssetTypeBetterCloud, AssetTypeZendesk].includes(selectedValue?.value)) {
          update.isCommitment = true;
        }

        if (AssetTypeGcpPartnerLedPremiumSupport === selectedValue?.value) {
          update.plpsPercent = fieldRange.plpsPercent.default;
        }

        if (ifProductWithoutEntity(selectedValue?.value, state.pointOfSale)) {
          update.entity = "";
          update.errors = { ...update.errors, entity: false };
        }

        return { ...prevState, ...update };
      });
    },
    [setState, state.pointOfSale]
  );

  const { contracts } = useContractsContext();

  const hasValidLookerContracts = useMemo(() => {
    const filteredLookerContracts = contracts.filter((contract) => contract.type === AssetTypeLooker);

    return filteredLookerContracts.some((contract) => {
      const currentDate = new Date();
      const contractStartDate = contract?.startDate?.toDate();

      if (contractStartDate) {
        const longestSkuMonths = contract.properties?.skus?.reduce(
          (longest, sku) => (sku.months > longest ? sku.months : longest),
          0
        );

        if (longestSkuMonths === undefined) {
          return false;
        }
        const contractEndDate = new Date(contractStartDate);
        contractEndDate.setMonth(contractEndDate.getMonth() + longestSkuMonths);

        currentDate.setHours(0, 0, 0, 0);
        contractEndDate.setHours(0, 0, 0, 0);

        return contractEndDate > currentDate;
      }

      return false;
    });
  }, [contracts]);

  useEffect(() => {
    if (!state.type) {
      setState({ ...state, type: contractProductTypes[0].value as ContractType });
    }
  }, [contractProductTypes, setState, state, state.type]);

  return (
    <Autocomplete
      id="contract-type"
      data-cy="contract-type"
      blurOnSelect
      getOptionDisabled={(option) =>
        option.value === AssetTypeLooker && hasValidLookerContracts && !isDoitContractAdmin && !isDoitContractOwner
      }
      disableClearable
      options={contractProductTypes}
      value={contractProductTypes.find((o) => o.value === state.type) ?? contractProductTypes[0]}
      onChange={handleChangeContractType}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      disabled={state.editMode}
      getOptionLabel={(option) =>
        option.value === AssetTypeLooker && hasValidLookerContracts
          ? `${option.label} (Contract already exists, Add SKUs to existing contract)`
          : option.label
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Product type"
          helperText="Select the contract's product type"
          error={state.errors.type}
          fullWidth
        />
      )}
    />
  );
};
