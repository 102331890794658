import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Customer } from "../../types";

export async function updateStartTrialInitiated(customer: Customer) {
  await getCollection(CustomerModel)
    .doc(customer.id)
    .update({
      onboarding: {
        ...customer.onboarding,
        startTrialInitiated: true,
      },
    });
}
