import { useState } from "react";

import { Alert, Button, CircularProgress } from "@mui/material";

import { randomLoadingMessage } from "../../../../utils/common";
import { FallbackToOnDemandState } from "./FallbackToOnDemand";

type FallbackToOnDemandAlertsProps = {
  state: FallbackToOnDemandState;
  detailedMessage: string;
  addConfigurationCallback: () => void;
};

export const FallbackToOnDemandAlerts = ({
  state,
  detailedMessage,
  addConfigurationCallback,
}: FallbackToOnDemandAlertsProps) => {
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(true);

  if (state === FallbackToOnDemandState.WaitingForAddConfigResponse) {
    return (
      <Alert severity="info" action={<CircularProgress size="2rem" />}>
        {randomLoadingMessage()}
      </Alert>
    );
  } else if (showSuccessAlert && state === FallbackToOnDemandState.SuccessfullyAddedConfig) {
    return (
      <Alert
        severity="success"
        onClose={() => {
          setShowSuccessAlert(false);
        }}
      >
        Well done, your new configuration was successfully applied!
      </Alert>
    );
  } else if (state === FallbackToOnDemandState.FailedToAddConfig) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={addConfigurationCallback}>
            Retry
          </Button>
        }
      >
        Could not apply Fallback to On-Demand. The following error occurred: {detailedMessage}
      </Alert>
    );
  } else if (state === FallbackToOnDemandState.InconsistencyWithConfig) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={addConfigurationCallback}>
            Fix AWS Configuration
          </Button>
        }
      >
        We detected a problem with the Fallback to On-Demand configuration in your AWS account
      </Alert>
    );
  } else {
    return <></>;
  }
};
