import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CloudflowEngineModel, type NodeModel } from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  type QueryDocumentSnapshotModel,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type Edge, type Node } from "@xyflow/react";
import isEqual from "lodash/isEqual";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCloudflow } from "../../hooks";
import { type RFNode } from "../../types";
import { getPreviousNodesPath } from "../utils/getPreviousNodesPath";
import {
  type CloudFlowNode,
  mapCloudFlowNodes,
  mapLeafNodesWithGhosts,
  mapTransitionsToEdges,
  sortEdgesByHandle,
  transformNodeData,
} from "../utils/nodeTransformUtils";

export const useCloudflowNodes = (flowId: string) => {
  const { customer } = useCustomerContext();

  const [nodes, setNodes] = useState<Node<RFNode>[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const previousNodesRef = useRef<CloudFlowNode[]>([]);

  const transformCloudflowNode = useCallback(
    (
      data: WithFirebaseModel<NodeModel>,
      snapshot: QueryDocumentSnapshotModel<NodeModel> | DocumentSnapshotModel<NodeModel>
    ) => transformNodeData(data, snapshot, customer.ref),
    [customer.ref]
  );
  const { cloudflow } = useCloudflow(flowId);

  const cloudflowNodesCollectionRef = useMemo(
    () =>
      getCollection(CloudflowEngineModel)
        .doc("cloudflows")
        .collection("cloudflowEntities")
        .doc(flowId)
        .collection("nodes"),
    [flowId]
  );

  const [fetchedNodes, cloudflowNodesLoading] = useCollectionData(cloudflowNodesCollectionRef, {
    transform: transformCloudflowNode,
  });

  const firstNodeId = useMemo(() => cloudflow?.data?.firstNode?.id, [cloudflow?.data?.firstNode?.id]);

  const nodesWithGhosts = useMemo(() => {
    if (!cloudflowNodesLoading && fetchedNodes && firstNodeId) {
      return mapLeafNodesWithGhosts(fetchedNodes as CloudFlowNode[], firstNodeId);
    }
    return [];
  }, [cloudflowNodesLoading, fetchedNodes, firstNodeId]);

  useEffect(() => {
    if (!cloudflowNodesLoading && !isEqual(nodesWithGhosts, previousNodesRef.current)) {
      previousNodesRef.current = nodesWithGhosts;

      // Map nodes to React Flow format
      const flowNodes = mapCloudFlowNodes(nodesWithGhosts);
      // Map edges based on node transitions
      const flowEdges = mapTransitionsToEdges(nodesWithGhosts);
      const sortedEdges = sortEdgesByHandle(flowEdges || []);

      if (flowNodes.length && sortedEdges?.length) {
        setNodes(flowNodes);
        setEdges(sortedEdges);
      } else {
        setNodes([]);
        setEdges([]);
      }
    }
  }, [nodesWithGhosts, cloudflowNodesLoading]);

  const getPreviousNodes = useCallback(
    (currentNodeId: string) =>
      cloudflowNodesLoading || !fetchedNodes ? [] : getPreviousNodesPath(currentNodeId, fetchedNodes),
    [cloudflowNodesLoading, fetchedNodes]
  );

  return { cloudflowNodes: nodes, cloudflowEdges: edges, cloudflowNodesLoading, getPreviousNodes };
};
