import { createContext, type ReactNode, useCallback, useContext, useMemo } from "react";

import { CustomerInvoiceAdjustmentModel } from "@doitintl/cmp-models";
import {
  getCollectionGroup,
  type ModelReference,
  type TransformMethod,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import toLower from "lodash/toLower";
import { DateTime } from "luxon";

import { getCachingKeys } from "../utils/cachingKeys";
import { assetTypeName } from "../utils/common";
import { useCustomerContext } from "./CustomerContext";

type StartEndDate = {
  startDate: DateTime;
  endDate: DateTime;
};

export type InvoiceAdjustmentType = {
  data: WithFirebaseModel<CustomerInvoiceAdjustmentModel> & StartEndDate;
  ref: ModelReference<CustomerInvoiceAdjustmentModel>;
  _filter?: string;
};

const invoiceAdjustmentsContext = createContext<InvoiceAdjustmentsType | null>(null);

const useInvoiceAdjustments = (withCustomer: boolean) => {
  const { customer } = useCustomerContext({ allowNull: withCustomer ? undefined : true });

  const transformInvoiceAdjustments: TransformMethod<CustomerInvoiceAdjustmentModel, InvoiceAdjustmentType> =
    useCallback((docData, snapshot) => {
      const _filter = toLower(
        `${docData.metadata.customer.primaryDomain}#${docData.metadata.customer.name}` +
          `#${assetTypeName(docData.type)}` +
          `#${docData.type}` +
          `#${docData.description}` +
          `#${docData.details}`
      );
      const totalMonths = docData.invoiceMonths.length;

      const data: InvoiceAdjustmentType["data"] = {
        ...docData,
        startDate: DateTime.fromJSDate(docData.invoiceMonths[0].toDate()).toUTC().startOf("month"),
        endDate: DateTime.fromJSDate(docData.invoiceMonths[totalMonths - 1].toDate())
          .toUTC()
          .startOf("month"),
      };

      return {
        data,
        ref: snapshot.modelRef,
        _filter,
      };
    }, []);

  const adjustmentsQuery = useMemo(() => {
    if (withCustomer) {
      if (!customer?.ref) {
        return;
      }
      return customer.ref.collection("customerInvoiceAdjustments");
    } else {
      return getCollectionGroup(CustomerInvoiceAdjustmentModel);
    }
  }, [withCustomer, customer?.ref]);

  const [adjustments, loading] = useCollectionData(adjustmentsQuery, {
    transform: transformInvoiceAdjustments,
    caching: true,
    cachingKeys: getCachingKeys(withCustomer ? customer!.id : undefined),
  });

  return {
    adjustments: adjustments ?? [],
    loading,
  };
};

type InvoiceAdjustmentsType = {
  adjustments: InvoiceAdjustmentType[];
  loading: boolean;
};

export const InvoiceAdjustmentsContextProvider = ({
  children,
  withCustomer,
}: {
  children: ReactNode;
  withCustomer: boolean;
}) => {
  const { adjustments, loading } = useInvoiceAdjustments(withCustomer);
  const providerValue = useMemo<InvoiceAdjustmentsType>(
    () => ({
      adjustments,
      loading,
    }),
    [adjustments, loading]
  );
  return <invoiceAdjustmentsContext.Provider value={providerValue}>{children}</invoiceAdjustmentsContext.Provider>;
};

export const useInvoiceAdjustmentsContext = () => {
  const context = useContext(invoiceAdjustmentsContext);
  if (!context) {
    throw new Error("useInvoiceAdjustmentsContext must be used within a InvoiceAdjustmentsContextProvider");
  }
  return context;
};
