import { useCallback } from "react";

import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";

import { metricTxt } from "../../../assets/texts/CloudAnalytics";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useErrorSnackbar, useInfoSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import { copyMetric } from "./db";
import { MetricsMenuOptions } from "./types";

export const useCreateMetricHandler = ({
  mixpanelEventName,
  baseUrl,
}: {
  mixpanelEventName: string;
  baseUrl: string;
}) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  return useCallback(() => {
    mixpanel.track(mixpanelEventName);
    history.push(`${baseUrl}/create`, { prevPage: routeMatchURL });
  }, [baseUrl, history, mixpanelEventName, routeMatchURL]);
};

export const useDuplicateMetric = ({ row }) => {
  const infoSnackbar = useInfoSnackbar(3);
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const errorSnackbar = useErrorSnackbar();

  return useCallback(async () => {
    try {
      await copyMetric(customer.ref, currentUser.email, row.data);
      infoSnackbar(metricTxt.DUPLICATE_SUCCESSFUL);
    } catch (e) {
      errorSnackbar(metricTxt.CREATE_METRIC_ERROR);
      consoleErrorWithSentry(e);
    }
  }, [currentUser.email, customer.ref, errorSnackbar, infoSnackbar, row.data]);
};

export const useThreeDotsMetricsMenu = ({ row }) => {
  const isEntitledCalculatedMetrics = useIsFeatureEntitled("analytics:calculatedMetrics");
  const duplicateMetric = useDuplicateMetric({ row });
  const threeDotsMenuOptions = [
    {
      label: <Typography>{MetricsMenuOptions.DUPLICATE}</Typography>,
      action: duplicateMetric,
      key: MetricsMenuOptions.DUPLICATE,
    },
  ];
  return <ThreeDotsMenu closeAfterSelect options={threeDotsMenuOptions} disabled={!isEntitledCalculatedMetrics} />;
};
