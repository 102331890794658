import { type UsefulLink } from "../Common/Onboarding/types";

export const usefulLinksAWS: UsefulLink[] = [
  {
    title: "Understanding Flexsave coverage",
    url: "https://help.doit.com/docs/flexsave/aws/flexsave-aws-coverage",
  },
];
export const flexsaveDisableMessage = "Flexsave is currently disabled. Please contact support to re-enable it.";

export const computeReportElementId = "compute-report";
export const sageMakerReportElementId = "sagemaker-report";
export const rdsReportElementId = "rds-report";
