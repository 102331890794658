import { type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

import { useAsyncLoadingFunction } from "../../../Components/hooks/useAsyncLoadingFunction";

export const cypressIds = {
  MPA_DELETION_DIALOG_TITLE: "mpa-deletion-dialog-title",
  MPA_DELETION_DIALOG_DESCRIPTION: "mpa-deletion-dialog-description",
};

type MPADeletionDialogProps = {
  onCancel: () => void;
  onDelete: () => Promise<void>;
  masterPayerAccount: MasterPayerAccountsModel;
};

export const MPADeletionDialog = ({ onCancel, onDelete, masterPayerAccount }: MPADeletionDialogProps) => {
  const [isDeleting, onDeleteHandler] = useAsyncLoadingFunction(() => onDelete().then(onCancel));

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      aria-labelledby={cypressIds.MPA_DELETION_DIALOG_TITLE}
      aria-describedby={cypressIds.MPA_DELETION_DIALOG_DESCRIPTION}
    >
      <DialogTitle id={cypressIds.MPA_DELETION_DIALOG_TITLE}>Delete MPA</DialogTitle>
      <DialogContent>
        <DialogContentText id={cypressIds.MPA_DELETION_DIALOG_DESCRIPTION}>
          Are you sure you'd like to delete {masterPayerAccount.name} MPA for the customer {masterPayerAccount.domain}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <LoadingButton onClick={onDeleteHandler} autoFocus loading={isDeleting} color="error">
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
