import { type ChangeEvent, useState } from "react";

import { type FlexsaveType } from "@doitintl/cmp-models";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

import { Contract } from "./Contract";

type Props = {
  open: boolean;
  type: FlexsaveType;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const ContractDialog = ({ open, type, handleClose, handleConfirm }: Props) => {
  const [authCheck, setAuthCheck] = useState<boolean>(false);
  const [agreementCheck, setAgreementCheck] = useState<boolean>(false);

  const handleAuthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthCheck(e.target.checked);
  };

  const handleAgreementChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreementCheck(e.target.checked);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography variant="h3" color="text.primary" fontWeight="500" pb={1}>
          DoiT International Flexsave Agreement
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Before configuring Flexsave, please read and agree to the following:
        </Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ overflow: "hidden" }}>
        <Contract type={type} />
      </DialogContent>

      <DialogContent dividers={true} sx={{ overflow: "hidden" }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={authCheck} onChange={handleAuthChange} data-cy="i-am-authorized" />}
            label={<Typography color="text.secondary">I am authorized to sign on behalf of Customer.</Typography>}
          />
          <FormControlLabel
            control={<Checkbox checked={agreementCheck} onChange={handleAgreementChange} data-cy="i-agree" />}
            label={
              <Typography color="text.secondary">
                On behalf of Customer, I agree to the terms of the Agreement.
              </Typography>
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!authCheck || !agreementCheck}
          onClick={handleConfirm}
          data-cy="confirm-contact"
        >
          Confirm agreement
        </Button>
      </DialogActions>
    </Dialog>
  );
};
