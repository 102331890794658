import { Typography } from "@mui/material";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { type LabelRowItem } from "./types";

export interface LabelThreeDotsMenuProps {
  row: LabelRowItem;
  handleEdit: () => void;
  handleDelete: () => void;
}

export const LabelThreeDotsMenu = ({ handleEdit, handleDelete }: LabelThreeDotsMenuProps) => {
  const options: ThreeDotsMenuOption[] = [
    {
      label: <Typography>Edit</Typography>,
      action: handleEdit,
      key: "edit",
    },
    {
      label: <Typography color="error">Delete</Typography>,
      action: handleDelete,
      key: "delete",
    },
  ];

  return <ThreeDotsMenu options={options} size="medium" closeAfterSelect />;
};
