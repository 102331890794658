import { useEffect, useState } from "react";

import { type FieldInputProps, type FormikProps, getIn } from "formik";

export function useFieldValidationProps(formikProps: FormikProps<any>, fieldProps: FieldInputProps<any>) {
  const [isTouched, setIsTouched] = useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    setIsTouched(getIn(formikProps.touched, fieldProps.name) === true);
  }, [fieldProps.name, formikProps.touched]);

  useEffect(() => {
    if (!isTouched) {
      setErrorMessage(undefined);
    } else {
      const errorMessage = getIn(formikProps.errors, fieldProps.name);
      setErrorMessage(typeof errorMessage === "string" ? errorMessage : undefined);
    }
  }, [fieldProps.name, formikProps.errors, isTouched]);

  useEffect(() => {
    setShowError(errorMessage !== undefined && isTouched);
  }, [errorMessage, isTouched]);

  return {
    showError,
    errorMessage,
  };
}
