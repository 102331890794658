import { Box, Typography } from "@mui/material";

import lookerLogo from "../../../../../assets/looker-logo.svg";

export const cyIds = {
  header: "looker-header",
  logo: "looker-logo",
};

const LookerAssetsTabHeader = () => (
  <Typography variant="h1" data-cy="looker-header" sx={{ pt: 2, pb: 2.5 }}>
    <Box
      component="img"
      src={lookerLogo}
      sx={{ height: "0.8em", width: "auto", mr: 1 }}
      data-cy="looker-logo"
      aria-hidden
      alt-text="Looker logo"
    />
    Looker
  </Typography>
);

export default LookerAssetsTabHeader;
