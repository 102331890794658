import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import copy from "copy-to-clipboard";

type CommandLineDialogProps = {
  open: boolean;
  setOpen: (boolean) => void;
  cliCommands: string;
  onCopy: () => void;
};

/**
 * Show CLI commands for stack creation
 * @param open
 * @param setOpen
 * @param cliCommands
 * @param onCopy - callback that will be called once use copied the commands
 * @constructor
 */
export const CommandLineDialog = ({ open, setOpen, cliCommands, onCopy }: CommandLineDialogProps) => {
  const handleCopyOfCommands = () => {
    copy(cliCommands);
    onCopy();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="md"
    >
      <DialogTitle>AWS CLI instructions</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          You can run the following commands in AWS CloudShell, just open your AWS console and type CloudShell in the
          search box.
        </Alert>
      </DialogContent>
      <DialogContent>
        <Box bgcolor="background.default" p={1} display="flex" onCopy={handleCopyOfCommands}>
          <Box pr={1}>
            <IconButton onClick={handleCopyOfCommands} size="large">
              <FileCopyOutlinedIcon />
            </IconButton>
          </Box>
          <Typography sx={{ wordBreak: "break-word" }} variant="body1">
            {cliCommands}
          </Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
