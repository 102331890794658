import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, type TooltipProps } from "@mui/material/Tooltip";

type Props = {
  title: string;
  children?: React.ReactNode;
};

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function TooltipArrow({ children, title }: Props) {
  return (
    <ArrowTooltip title={title}>
      <span>{children}</span>
    </ArrowTooltip>
  );
}
