import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { type HeaderColumn } from "../../../types/FilterTable";
import { type AsgTag } from "../types";
import { AwsTagTableRow } from "./AwsTagTableRow";

type Props = {
  tags: AsgTag[];
};

const headerColumns: HeaderColumn[] = [
  { label: "Key", value: "Key", tooltip: "", style: { align: "left", padding: "normal" } },
  { label: "Value", value: "Value", tooltip: "", style: { align: "left", padding: "normal" } },
];
const filterColumns = [
  {
    label: "Key",
    path: "Key",
    comparators: ["==", "contains"],
  },
  {
    label: "Value",
    path: "Value",
    comparators: ["==", "contains"],
  },
] as const;

const defaultFilters = [
  {
    column: filterColumns[0],
    comparator: "contains",
    label: "doit",
    value: "doit",
  },
] as const;

export const AwsTags = ({ tags }: Props) => (
  <FilterTable<AsgTag>
    tableItems={tags}
    headerColumns={headerColumns}
    filterColumns={filterColumns}
    rowComponent={AwsTagTableRow}
    defaultFilters={defaultFilters}
    emptyRowHeight={1}
  />
);
