import { useState } from "react";

import { Status } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Tab, Tabs } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { flexsave } from "../../../../../constants/cypressIds";
import ComputeTab from "./ComputeTab";
import { fieldNames } from "./constants";
import RDSTab from "./RDSTab";
import SagemakerTab from "./SagemakerTab";
import { AWSFlexsaveType, type EditFormValues } from "./types";

const { form } = flexsave;

const schema = yup.object().shape(
  {
    [fieldNames.status]: yup.mixed().oneOf(Object.values(Status)).required(),
    [fieldNames.computeDiscount]: yup
      .number()
      .nullable()
      .when(fieldNames.status, {
        is: (value) => value !== Status.disabled,
        then: (schema) => schema.lessThan(101).moreThan(-1),
      }),
    [fieldNames.computeEffectiveDate]: yup.date().when(fieldNames.computeDiscount, {
      is: (value) => value && value > 0,
      then: (schema) =>
        schema.required().when(fieldNames.status, {
          is: (value) => value === Status.disabled,
          then: (schema) => schema.nullable(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
    [fieldNames.sagemakerDiscount]: yup
      .number()
      .nullable()
      .when(fieldNames.sagemakerStatus, {
        is: (value) => value !== Status.disabled,
        then: (schema) => schema.lessThan(101).moreThan(-1),
      }),
    [fieldNames.sagemakerEffectiveDate]: yup.date().when(fieldNames.sagemakerDiscount, {
      is: (value) => value && value > 0,
      then: (schema) =>
        schema.required().when(fieldNames.sagemakerStatus, {
          is: (value) => value === Status.disabled,
          then: (schema) => schema.nullable(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),
    [fieldNames.rdsDiscount]: yup
      .number()
      .nullable()
      .when(fieldNames.rdsStatus, {
        is: (value) => value !== Status.disabled,
        then: (schema) => schema.lessThan(101).moreThan(-1),
      }),
    [fieldNames.rdsEffectiveDate]: yup.date().when(fieldNames.rdsDiscount, {
      is: (value) => value && value > 0,
      then: (schema) =>
        schema.required().when(fieldNames.rdsStatus, {
          is: (value) => value === Status.disabled,
          then: (schema) => schema.nullable(),
        }),
      otherwise: (schema) => schema.nullable(),
    }),

    [fieldNames.minSpend]: yup.number().nullable(),
    [fieldNames.maxSpend]: yup
      .number()
      .nullable()
      .when(fieldNames.status, {
        is: (value) => value !== Status.disabled,
        then: (schema) =>
          schema.when(fieldNames.minSpend, {
            is: (value) => value !== undefined && value !== 0,
            then: (schema) => schema.moreThan(yup.ref(fieldNames.minSpend)),
          }),
      }),
    [fieldNames.targetPercentage]: yup.number().nullable().min(0).max(100),
    [fieldNames.rdsTargetPercentage]: yup.number().nullable().min(0).max(100),
  },
  [
    [fieldNames.status, fieldNames.computeDiscount],
    [fieldNames.computeEffectiveDate, fieldNames.computeDiscount],
    [fieldNames.status, fieldNames.computeDiscount],

    [fieldNames.sagemakerStatus, fieldNames.sagemakerDiscount],
    [fieldNames.sagemakerEffectiveDate, fieldNames.sagemakerDiscount],
    [fieldNames.sagemakerStatus, fieldNames.sagemakerDiscount],

    [fieldNames.rdsStatus, fieldNames.rdsDiscount],
    [fieldNames.rdsEffectiveDate, fieldNames.rdsDiscount],
    [fieldNames.rdsStatus, fieldNames.rdsDiscount],

    [fieldNames.status, fieldNames.maxSpend],
  ]
);

const validateForm = (initialValues: EditFormValues, values: EditFormValues) => {
  const errors: Partial<Record<keyof EditFormValues, boolean>> = {};

  const flexsaveTypes = Object.values(AWSFlexsaveType);

  flexsaveTypes.forEach((type) => {
    const discountField = `${type}Discount`;
    const effectiveDateField = `${type}EffectiveDate`;

    if (initialValues[discountField] && values[discountField] === "") {
      errors[discountField] = true;
    }

    if (initialValues[effectiveDateField] && !values[effectiveDateField]) {
      errors[effectiveDateField] = true;
    }

    if (values[effectiveDateField] && values[discountField] === "") {
      errors[discountField] = true;
    }

    if (values[discountField] && values[effectiveDateField] === "") {
      errors[effectiveDateField] = true;
    }
  });

  return errors;
};

type Props = {
  onCancel: () => void;
  onSubmit: (data: EditFormValues) => void;
  initialValues: EditFormValues;
};

export const AWSPayerConfigForm = ({ onCancel, initialValues, onSubmit }: Props) => {
  const [tab, setTab] = useState(0);

  return (
    <Formik<EditFormValues>
      validationSchema={schema}
      validate={(values) => {
        const errors: Partial<Record<keyof EditFormValues, boolean>> = {};

        Object.assign(errors, validateForm(initialValues, values));

        if (
          values.status !== initialValues.status &&
          values.status === "disabled" &&
          values.statusChangeReason === ""
        ) {
          errors.statusChangeReason = true;
        }

        return errors;
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form>
          <Box paddingX={3} paddingTop={3} paddingBottom={2}>
            <Tabs
              value={tab}
              onChange={(_ignored, tab) => {
                setTab(tab);
              }}
            >
              <Tab label="Compute" sx={{ textTransform: "capitalize" }} />
              <Tab label="Sagemaker" sx={{ textTransform: "capitalize" }} />
              <Tab label="RDS" sx={{ textTransform: "capitalize" }} />
            </Tabs>
            <Divider />

            <Box m={4} />

            {tab === 0 && <ComputeTab />}
            {tab === 1 && <SagemakerTab />}
            {tab === 2 && <RDSTab />}
          </Box>

          <Divider />
          <Box m={1} />

          <Box display="flex" alignItems="flex-end" justifyContent="flex-end" paddingBottom={1} paddingX={1}>
            <Button variant="text" onClick={onCancel} disabled={isSubmitting} data-testid={form.cancelButton}>
              Cancel
            </Button>

            <LoadingButton
              data-testid={form.approveButton}
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Save
            </LoadingButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
