export const getJiraStatusColors = (statusCategory): { backgroundColor: string; textColor: string } => {
  if (statusCategory === "To Do") {
    return { backgroundColor: "#DFE1E6", textColor: "#172B4D" };
  } else if (statusCategory === "In Progress") {
    return { backgroundColor: "#DEEBFF", textColor: "#0747A6" };
  }
  return { backgroundColor: "#E3FCEF", textColor: "#006644" };
};

export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}...`;
};
