import {
  type AsgAdditionalDetails,
  type AsgConfigurationSummary,
  type AsgInstancesCosts,
  type AsgItemConfig,
  type InstanceTypeDetails,
  type SubnetDetails,
} from "../types";
import { calculateHourlyCost } from "./costsUtils";

/**
 * Calculate savings from the beginning of the month, or from the start date
 * @param startDate
 */
export function calculateCurrentMonthSavingDays(startDate?: Date) {
  const currentDate = new Date();
  let numberOfDaysToCalculateSavings = currentDate.getDate();

  if (
    startDate &&
    startDate.getFullYear() === currentDate.getFullYear() &&
    startDate.getMonth() === currentDate.getMonth()
  ) {
    numberOfDaysToCalculateSavings -= startDate.getDate();
  }

  return numberOfDaysToCalculateSavings;
}

/**
 * Calculate the destitution of the on-demand instances from the total capacity
 * @param onDemandBaseCapacity
 * @param onDemandPercentageAboveBaseCapacity
 * @param averageDesiredCapacity
 */
export function calculateAverageOnDemandCapacity(
  onDemandBaseCapacity: number,
  onDemandPercentageAboveBaseCapacity,
  averageDesiredCapacity: number
) {
  const actualOnDemandBaseCapacity = onDemandBaseCapacity < 0 ? 0 : onDemandBaseCapacity;
  return (
    onDemandBaseCapacity +
    Math.ceil(((averageDesiredCapacity - actualOnDemandBaseCapacity) * onDemandPercentageAboveBaseCapacity) / 100)
  );
}

/**
 * Parse the raw configuration of the ASG and add ready to use calculations
 * @param asgJson - the raw AWS ASG json
 * @param averageDesiredCapacity
 * @param instancesCosts - prices of the spot and on-demand instances
 * @param instanceTypesDetails - details
 * @param asgAdditionalDetails - additional data that is missing in the raw json
 * @param config - the general config of the ASG
 */
export function parseAsgConfiguration(
  asgJson: any,
  averageDesiredCapacity: number,
  instancesCosts: AsgInstancesCosts,
  config: AsgItemConfig,
  instanceTypesDetails: Record<string, InstanceTypeDetails>,
  subnetsDetails: Record<string, SubnetDetails>,
  asgAdditionalDetails?: AsgAdditionalDetails
): AsgConfigurationSummary {
  const subnets = asgJson?.VPCZoneIdentifier.split(",");
  const desiredCapacity = parseInt(asgJson?.DesiredCapacity);
  const minCapacity = parseInt(asgJson?.MinSize);
  const maxCapacity = parseInt(asgJson?.MaxSize);

  let onDemandBaseCapacity = -1;
  let onDemandPercentageAboveBaseCapacity = 0;
  let instanceTypes: string[] = [];
  let averageOnDemandCapacity = 0;
  let isMixedTypesInstances = false;

  if (asgJson?.MixedInstancesPolicy) {
    isMixedTypesInstances = true;
    onDemandBaseCapacity = asgJson.MixedInstancesPolicy?.InstancesDistribution?.OnDemandBaseCapacity;
    onDemandPercentageAboveBaseCapacity =
      asgJson.MixedInstancesPolicy?.InstancesDistribution?.OnDemandPercentageAboveBaseCapacity;

    const overrides = asgJson.MixedInstancesPolicy?.LaunchTemplate?.Overrides ?? [];
    instanceTypes = overrides.map((override) => override.InstanceType);

    averageOnDemandCapacity = calculateAverageOnDemandCapacity(
      onDemandBaseCapacity,
      onDemandPercentageAboveBaseCapacity,
      averageDesiredCapacity
    );
  } else if (asgAdditionalDetails) {
    instanceTypes = asgAdditionalDetails.instances;

    if (asgAdditionalDetails.allOnDemand) {
      onDemandPercentageAboveBaseCapacity = 100;
      averageOnDemandCapacity = averageDesiredCapacity;
    } else {
      onDemandPercentageAboveBaseCapacity = 0;
    }
  }

  const isLaunchConfiguration = asgJson?.LaunchConfigurationName !== null;

  const hourlyCost = calculateHourlyCost(
    averageDesiredCapacity - averageOnDemandCapacity,
    averageOnDemandCapacity,
    instancesCosts
  );

  const dailyCostIfUsingOnlyOnDemand =
    24 * (averageDesiredCapacity - averageOnDemandCapacity + averageOnDemandCapacity) * instancesCosts.onDemandHourCost;

  const asgTags = asgJson?.Tags ?? [];

  return {
    minCapacity,
    maxCapacity,
    desiredCapacity,
    onDemandBaseCapacity,
    onDemandPercentageAboveBaseCapacity,
    averageDesiredCapacity,
    instanceTypes,
    instanceTypesDetails,
    // availabilityZones,
    subnets,
    subnetsDetails,
    instancesCosts,
    excludedInstanceTypes: config.excludedInstanceTypes,
    excludedSubnets: config.excludedSubnets,
    averageOnDemandCapacity,
    isMixedTypesInstances,
    isLaunchConfiguration,
    dailyCost: hourlyCost * 24,
    monthlyCost: hourlyCost * 24 * 30,
    dailyCostIfUsingOnlyOnDemand,
    monthlyCostIFusingOnlyOnDemand: dailyCostIfUsingOnlyOnDemand * 30,
    tags: asgTags,
  };
}
