import { type SaaSConsoleType } from "@doitintl/cmp-models";

import { saasToProductCloudType } from "../../../Pages/Customer/SaaSConsoleOnboarding/Common/CloudSelector/utils";
import { type Customer } from "../../../types";
import { type CustomerProspect, type ProductOnlyCustomerProspects } from "./type";

export const getCustomerProspect = (customer: Customer): CustomerProspect => {
  const { onboarding } = customer;
  const hasAdvantage = Boolean(onboarding?.advantage?.providers?.length);
  const hasNavigator = Boolean(onboarding?.navigator?.providers?.length);
  const hasSolve = Boolean(onboarding?.solve?.providers?.length);

  if (hasAdvantage && hasNavigator && hasSolve) {
    return "advantageNavigatorSolve";
  } else if (hasAdvantage && hasNavigator && !hasSolve) {
    return "advantageNavigator";
  } else if (hasAdvantage && !hasNavigator && hasSolve) {
    return "advantageSolve";
  } else if (!hasAdvantage && hasNavigator && hasSolve) {
    return "navigatorSolve";
  } else if (hasAdvantage && !hasNavigator && !hasSolve) {
    return "advantage";
  } else if (!hasAdvantage && hasNavigator && !hasSolve) {
    return "navigator";
  } else if (!hasAdvantage && !hasNavigator && hasSolve) {
    return "solve";
  }
  return "unknown";
};

export const shouldShowCard = (
  cloudType: SaaSConsoleType,
  customer: Customer,
  prospect: ProductOnlyCustomerProspects
) => {
  const { onboarding, enabledSaaSConsole } = customer;
  const productCloudType = saasToProductCloudType(cloudType);
  const isInAdvantageProviders = onboarding?.advantage?.providers?.includes(productCloudType);
  const isInNavigatorProviders = onboarding?.navigator?.providers?.includes(productCloudType);
  const isInProspectProviders = onboarding?.[prospect]?.providers?.includes(productCloudType);
  const shouldShowForProspect = isInProspectProviders && !enabledSaaSConsole?.[cloudType];

  if (prospect === "navigator") {
    // Show card if selected cloud is not in advantage providers & not connected already
    return !isInAdvantageProviders && shouldShowForProspect;
  }

  // Show card if selected cloud is not in advantage and navigator providers & not connected already
  return !isInAdvantageProviders && !isInNavigatorProviders && shouldShowForProspect;
};
