import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { AnalyticsResourceType, RampModel } from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";

import { useApiContext } from "../../../../../api/context";
import { useAttributionGroups } from "../../../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { useAuthContext } from "../../../../../Context/AuthContext";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type Contract } from "../../../../../types";
import { consoleErrorWithSentry } from "../../../../../utils";
import { useFullScreen } from "../../../../../utils/dialog";
import mixpanel from "../../../../../utils/mixpanel";
import { type AttributionGroupWithRef } from "../../../../CloudAnalytics/attributionGroups/types";
import { useExtendedCustomerContracts } from "../../../../Contracts/hooks";
import { type RampPlanData } from "../../../types";
import { getEligibleContracts } from "../../../utils";
import { createPlanFromContract } from "./createPlanFromContract";
import { RampDialogContent } from "./RampPlanDialogContent";
import { RampPlanDialogFooter } from "./RampPlanDialogFooter";

type RampDialog = {
  open: boolean;
  takenNamesArr: string[];
  onClose: () => void;
};

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const NewRampPlanDialog = (props: RampDialog) => {
  const [attributionGroups] = useAttributionGroups();
  const [attributionGroup, setAttributionGroup] = useState<AttributionGroupWithRef | null>(null);
  const [defaultContractTS, setDefaultContractTS] = useState<number | null>(null);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [dialogLoader, setDialogLoader] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [contractList, setContractList] = useState<Contract[]>([]);
  const [planName, setPlanName] = useState("");

  const { fullScreen } = useFullScreen();
  const { currentUser } = useAuthContext();
  const { customer } = useCustomerContext();
  const { extendedContracts, extendedContractsLoading } = useExtendedCustomerContracts(customer.ref);

  const api = useApiContext();
  const history = useHistory();

  const classes = useStyle();

  const { open, takenNamesArr, onClose } = props;

  const saveSelectedContract = (contractTimestamp: number) => {
    const cTimestamp: number | null = contractTimestamp || defaultContractTS;
    const contractFound: Contract = contractList.filter((x: Contract) => x?.startDate?.seconds === cTimestamp)[0];
    setSelectedContract(contractFound);
  };

  const onDialogClose = useCallback(() => {
    setPlanName("");
    setDialogLoader(false);
    onClose();
  }, [onClose]);

  const nameErrorMessage = "Plan name is already taken";

  const isSubmitAllowed =
    planName.length > 0 && planName.trim().length > 0 && !showNameError && attributionGroup !== null;

  const updateReady = useCallback(() => {
    if (planName && (selectedContract || defaultContractTS)) {
      setIsReady(true);
    }
  }, [defaultContractTS, planName, selectedContract]);

  const updateAttainment = useCallback(
    async (cId: string, planId: string) => {
      try {
        await api.request({
          method: "post",
          url: `/v1/customers/${cId}/ramp-plan`,
          data: {
            planId,
          },
        });
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [api]
  );

  const sortContracts = useCallback(
    (contractsArr: Contract[]): Contract[] =>
      [...contractsArr].sort((a, b) => {
        const checkValidStartDate = (contract: Contract) => contract?.startDate !== null && contract?.startDate;

        if (checkValidStartDate(a) && checkValidStartDate(b)) {
          return a.startDate > b.startDate ? -1 : 1;
        }
        return 1;
      }),
    []
  );

  const onSubmitPlan = useCallback(
    async (docRef: ModelReference<RampModel>) => {
      await updateAttainment(customer.ref?.id, docRef.id);
      setIsReady(true);
      setDialogLoader(false);
      onDialogClose();
      mixpanel.track("customers.ramps.create-ramp-plan");
      docRef.id && history.push(`/customers/${customer.id}/contracts/ramps/${docRef.id}`);
    },
    [customer.id, customer.ref?.id, history, onDialogClose, updateAttainment]
  );

  const servePlan = useCallback(
    async (plan: RampPlanData) => {
      const docRef = await getCollection(RampModel).add(plan);
      await onSubmitPlan(docRef);
    },
    [onSubmitPlan]
  );
  const onPlanNameChange = (name: string) => {
    setShowNameError(false);
    const isUnique = !takenNamesArr.filter((x) => x === name.trim()).length;
    !isUnique && setShowNameError(true);
    setPlanName(name);
  };

  const handleSubmitRampPlan = useCallback(async () => {
    const rampContract = selectedContract ?? contractList.filter((x) => x?.startDate?.seconds === defaultContractTS)[0];
    if (rampContract && planName.length && currentUser?.email?.length && attributionGroup) {
      const newPlan = createPlanFromContract(rampContract, planName, customer, currentUser.email, attributionGroup);
      if (newPlan) {
        setDialogLoader(true);
        await servePlan(newPlan);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract, planName, currentUser?.email, customer, attributionGroup]);

  useEffect(() => {
    if (extendedContractsLoading) {
      return;
    }

    if (open && customer && !contractList.length && extendedContracts.length) {
      const relevantContracts = getEligibleContracts(extendedContracts);
      // newest contract first
      const sortedContracts = sortContracts(relevantContracts);
      setContractList(sortedContracts);
      // default - 1st contract in sorted - the newest
      if (sortedContracts.length && sortedContracts[0]?.startDate?.seconds) {
        setDefaultContractTS(sortedContracts[0]?.startDate?.seconds);
      }
    }
  }, [open, customer, contractList.length, extendedContracts, sortContracts, extendedContractsLoading]);

  useEffect(() => {
    if ((selectedContract || defaultContractTS) && customer.id && isReady) {
      handleSubmitRampPlan();
    }
  }, [selectedContract, customer.id, isReady, handleSubmitRampPlan, defaultContractTS]);

  useEffect(() => {
    const defaultAG = attributionGroups.find((ag) => ag.data.name === "Ramp plan eligible spend");
    if (defaultAG) {
      setAttributionGroup(defaultAG);
    }
  }, [attributionGroups]);

  const attributionGroupsFiltered = useMemo(() => {
    const presets: AttributionGroupWithRef[] = [];
    const preset = attributionGroups.find((ag) => ag.ref.id === "ioucn9k5qX16YTZMv3L2");
    if (preset !== undefined) {
      const newPreset: AttributionGroupWithRef = { ...preset };
      newPreset.data.type = AnalyticsResourceType.PRESET_DISPLAY_NAME;
      presets.push(newPreset);
    }

    const custom = attributionGroups
      .filter((ag) => ag.data.type === AnalyticsResourceType.CUSTOM)
      .map((ag) => {
        const newAg: AttributionGroupWithRef = { ...ag };
        newAg.data.type = AnalyticsResourceType.CUSTOM_DISPLAY_NAME;
        return newAg;
      });
    return [...presets, ...custom];
  }, [attributionGroups]);

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="new-ramp-plan-dialog-box"
      open={open}
      maxWidth="sm"
      fullWidth
      data-cy="new-ramp-plan-dialog"
    >
      <DialogTitle className={classes.root} data-cy="new-ramp-plan-dialog-title">
        Create new ramp plan
      </DialogTitle>
      <RampDialogContent
        contractList={contractList}
        selectedContract={selectedContract ?? contractList[0]}
        saveSelectedContract={saveSelectedContract}
        name={planName}
        onPlanNameChange={onPlanNameChange}
        attributionGroupsList={attributionGroupsFiltered}
        onAttributionGroupChange={(ag: AttributionGroupWithRef) => {
          setAttributionGroup(ag);
        }}
        selectedAttributionGroup={attributionGroup}
        showNameError={showNameError}
        nameErrorMessage={nameErrorMessage}
        customerId={customer.id}
      />
      <Divider sx={{ mt: 2 }} />
      <RampPlanDialogFooter
        onSubmit={updateReady}
        onClose={onClose}
        submitButtonText="Create ramp plan"
        isSubmitAllowed={isSubmitAllowed}
        loading={dialogLoader}
      />
    </Dialog>
  );
};
