import { useCallback, useState } from "react";

import { GCPCustomerPricebooksModel } from "@doitintl/cmp-models";
import { getCollectionGroup, useCollectionData } from "@doitintl/models-firestore";
import AddIcon from "@mui/icons-material/AddBoxRounded";
import { Button, Grid } from "@mui/material";

import { pricebooksText } from "../../../assets/texts";
import { useAuthContext } from "../../../Context/AuthContext";
import GoogleCloudFormDialog from "../../../Pages/Pricebooks/GoogleCloudFormDialog";
import { FilterTable } from "../../FilterTable/FilterTable";
import { GCPfilterColumns, getGCPHeaders } from "../Columns";
import { type GCPPricebook } from "../Types";
import { GCPPricebookRow } from "./GCPPricebookRow";

export default function GCPPricebooks() {
  const [pricebooks] = useCollectionData(
    getCollectionGroup(GCPCustomerPricebooksModel).where("type", "==", "google-cloud"),
    { refField: "ref" }
  );

  const { isDoitDeveloper, isDoitOwner } = useAuthContext({ mustHaveUser: true });
  const [pricebookForDialog, setPricebookForDialog] = useState<GCPPricebook | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = useCallback(
    (row: GCPPricebook | null) => () => {
      setPricebookForDialog(row);
      setDialogOpen(true);
    },
    []
  );

  const GCPPricebooksRowWrapper = useCallback(
    (props: { row: GCPPricebook }) => <GCPPricebookRow row={props.row} handleOpenDialog={handleOpenDialog} />,
    [handleOpenDialog]
  );

  return (
    <>
      {isDoitDeveloper && dialogOpen && (
        <GoogleCloudFormDialog
          onClose={() => {
            setDialogOpen(false);
          }}
          pricebook={pricebookForDialog}
        />
      )}
      <FilterTable<GCPPricebook>
        defaultSortingColumnValue="metadata.customer.name"
        filterBarPlaceholder={pricebooksText.FILTER_PRICEBOOKS}
        filterColumns={GCPfilterColumns}
        headerColumns={getGCPHeaders(isDoitDeveloper)}
        itemUniqIdentifierField="ref.id"
        persistenceKey="pricebooks_gcp_tab"
        rowComponent={GCPPricebooksRowWrapper}
        showRowsSelection={false}
        tableItems={pricebooks}
      >
        <Grid item>
          {isDoitDeveloper && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleOpenDialog(null)}
              disabled={!isDoitOwner}
              size="large"
            >
              {pricebooksText.NEW_PRICEBOOK}
            </Button>
          )}
        </Grid>
      </FilterTable>
    </>
  );
}
