import { type Dispatch } from "react";

import { Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";

import { globalText, loginTexts } from "../../../assets/texts";
import { useEmailSchema } from "../helpers";
import { useResetEmailForm } from "../hooks";
import { type LoginHookAction, LoginHookActionType, type LoginState } from "../types";

export default function ForgotPassword({
  state,
  dispatch,
}: {
  state: LoginState;
  dispatch: Dispatch<LoginHookAction>;
}) {
  const resetPassword = useResetEmailForm({ dispatch, state });
  const EmailSchema = useEmailSchema();

  return (
    <Formik onSubmit={resetPassword} initialValues={{ email: state.email || "" }} validationSchema={EmailSchema}>
      {({ errors, isSubmitting, values, handleBlur, handleChange, touched }) => (
        <Form>
          <Stack spacing={2} sx={{ pt: 2 }}>
            <Typography variant="body2">{loginTexts.FORGOT_PASSWORD_ENTER_EMAIL}</Typography>

            <TextField
              data-cy="email-input"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="none"
              variant="outlined"
              fullWidth
              disabled={state.isLoading}
            />
            <Stack direction="row" spacing={1}>
              <Button
                onClick={() => {
                  dispatch({ type: LoginHookActionType.back });
                }}
              >
                {globalText.CANCEL}
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || state.isLoading || Boolean(errors.email)}
                variant="contained"
                autoFocus
              >
                {globalText.RESET}
              </Button>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
